import moment from 'moment-timezone';
import React, { ReactNode, useContext, useState } from 'react';
import { ThemeContext } from 'styled-components/native';
import { IThemePart } from '../../../../theme';
import { NonForwardedRefAnimatedView } from '../../../../presentational/atoms/list2/virtualized-flat-list';
import { View, Animated } from 'react-native';
import Cookies from 'js-cookie';
import {
  PanGestureHandler,
  PanGestureHandlerGestureEvent,
  State,
} from 'react-native-gesture-handler';

interface IResizableColumnProps {
  cookieName: string;
  minWidth?: number;
  maxWidth?: number;
  defaultWidth?: number;
  renderChild: (width: number) => ReactNode;
}

const ResizableColumn = (props: IResizableColumnProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [columnWidth, setColumnWidth] = useState(
    Cookies.get(props.cookieName)
      ? Math.max(50, Math.min(800, Number(Cookies.get(props.cookieName))))
      : props.defaultWidth ?? 300
  );
  const [columnWidthhTranslation, setColumnWidthTranslation] = useState(
    new Animated.Value(columnWidth)
  );

  return (
    <Animated.View
      style={{
        width: columnWidthhTranslation,
        minWidth: props.minWidth ?? 50,
        maxWidth: props.maxWidth ?? 800,
        flexDirection: 'row',
      }}>
      <View style={{ flexDirection: 'column', width: 'calc(100% - 3px)' }}>
        {props.renderChild(columnWidth)}
      </View>
      <PanGestureHandler
        maxPointers={1}
        onGestureEvent={Animated.event([], {
          listener: (event: PanGestureHandlerGestureEvent) => {
            if (event.nativeEvent.state === State.BEGAN) {
            }
            if (event.nativeEvent.state === State.ACTIVE) {
              columnWidthhTranslation.setValue(columnWidth + event.nativeEvent.translationX);
            }
            if (event.nativeEvent.state === State.END) {
              const value = columnWidth + event.nativeEvent.translationX;
              setColumnWidth(value);
              Cookies.set(props.cookieName, value.toString(), {
                expires: moment().add(10, 'years').toDate(),
              });
            }
          },
          useNativeDriver: true,
        })}>
        <NonForwardedRefAnimatedView
          style={{
            width: 3,
            borderLeftWidth: 1,
            borderRightWidth: 1,
            borderColor: themeContext.colors.separator,
            cursor: 'col-resize',
          }}>
          <View style={{ width: 5, backgroundColor: 'red' }}></View>
        </NonForwardedRefAnimatedView>
      </PanGestureHandler>
    </Animated.View>
  );
};

export default ResizableColumn;
