import React, { useContext, useState, useEffect, useRef } from 'react';
import {
  TextInputProps,
  TextStyle,
  View,
  ViewStyle,
  TouchableOpacity,
  Text,
  TouchableWithoutFeedback,
} from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import { ValidationRules } from 'react-hook-form';
import { IStyleTheme, IThemePart } from '../../../theme';
import FormContext from '../form/context';
import Typography, { TypographyType } from '../typography';
import moment from 'moment-timezone';
import useHover from '../editable-text/use-hover';
import Icon from '../icon';
import Button from '../button';
import LeftIcon from '../../molecules/image-icon/left';
import RightIcon from '../../molecules/image-icon/right';

interface ILabelProps extends IStyleTheme {
  isFloat: boolean;
}

const Container = styled.View<IStyleTheme>`
  margin: 1rem 0;
  width: 100%;
`;

const Label = styled.Text<ILabelProps>`
  position: absolute;
  left: ${(props: ILabelProps) => (props.isFloat ? 0 : 0)};
  top: ${(props: ILabelProps) => (props.isFloat ? '-1.5rem' : 0)};
  font-size: ${(props: ILabelProps) => (props.isFloat ? '0.7rem' : '1rem')};
  color: ${(props: ILabelProps) => props.theme.colors.label};
  transition: all 0.2s;
`;

const ErrorMessage = styled.Text<IStyleTheme>`
  font-size: 0.9rem;
  color: ${(props: IStyleTheme) => props.theme.colors.error};
  text-align: left;
`;

const Description = styled.Text<IStyleTheme>`
  font-size: 0.9rem;
  color: ${(props: IStyleTheme) => props.theme.colors.description};
  text-align: left;
`;

const InputText = styled.TextInput<IStyleTheme>`
  height: 1rem,
  fontSize: 1rem,
  color: ${(props: IStyleTheme) => props.theme.colors.input};
  border-bottom-color: '#555';
  outlineWidth: 0;
  border-bottom-width: 1px;
  padding: 0.3rem;
  width: 100%;
`;

interface ICalendarCellProps {
  cellSize: number;
  date: moment.Moment;
  displayDate: moment.Moment;
  selectedDate: IDateRange | null;
  selectDate: (date: moment.Moment) => void;
  isDisable?: boolean;
  displaySelectedMarkStart: boolean;
  displaySelectedMarkEnd: boolean;
}

const CalendarCell = (props: ICalendarCellProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [hoverRef, isHover] = useHover();
  const isWeekEnd = props.date.isoWeekday() === 6 || props.date.isoWeekday() === 7;
  let isSelectedStartOrEnd = false;
  if (props.displaySelectedMarkStart) {
    isSelectedStartOrEnd =
      isSelectedStartOrEnd || props.selectedDate
        ? props.date.format('YYYYMMDD') === props.selectedDate!.start.format('YYYYMMDD')
        : false;
  }
  if (props.displaySelectedMarkEnd) {
    isSelectedStartOrEnd =
      isSelectedStartOrEnd || props.selectedDate
        ? props.date.format('YYYYMMDD') === props.selectedDate!.end.format('YYYYMMDD')
        : false;
  }

  const isSelectedTerm = props.selectedDate
    ? props.date >= moment(props.selectedDate.start).startOf('day') &&
      props.date <= moment(props.selectedDate.end).endOf('day')
    : false;

  return (
    <View
      ref={hoverRef}
      style={{
        width: props.cellSize,
        height: props.cellSize,
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <TouchableOpacity
        onPress={() => props.selectDate(props.date)}
        style={
          {
            width: props.cellSize - 4,
            height: props.cellSize - 4,
            borderRadius: 13,
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            opacity: props.isDisable
              ? 0.2
              : props.date.month() === props.displayDate.month()
              ? 1
              : 0.2,
            backgroundColor:
              isHover || isSelectedStartOrEnd
                ? isWeekEnd
                  ? '#f33563'
                  : themeContext.colors.primary
                : 'transparent',
          } as any
        }>
        <Typography
          variant={TypographyType.Normal}
          style={{
            color: props.isDisable
              ? themeContext.colors.description
              : isWeekEnd
              ? isHover || isSelectedStartOrEnd
                ? '#FFFFFF'
                : '#f33563'
              : isHover || isSelectedStartOrEnd
              ? '#FFFFFF'
              : themeContext.colors.textColor,
          }}>
          {props.date.format('D')}
        </Typography>
      </TouchableOpacity>
    </View>
  );
};

interface IDateRange {
  start: moment.Moment;
  end: moment.Moment;
}

interface IDateRangePickerProps extends TextInputProps {
  ref?: any;
  name: string;
  label?: string;
  placeholder?: string;
  description?: string;
  containerStyle?: ViewStyle;
  inputContainerStyle?: ViewStyle;
  inputstyle?: TextStyle;
  validate?: ValidationRules;
  initialValue?: IDateRange;
  selectableStartDate?: moment.Moment | null;
  selectableEndDate?: moment.Moment | null;
  onChange?: (value: IDateRange | null) => void;
  onBlur?: (value: IDateRange | null, textInputElement: any) => void;
  onPressEnter?: (value: IDateRange | null) => void;
  onFocus?: () => void;
  focus?: boolean;
  additionalErrorMessage?: string | null;
  readonly?: boolean;
  disableClear?: boolean;
}

const DateRangePicker: React.FC<IDateRangePickerProps> = (props: IDateRangePickerProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [showCalendar, setShowCalendar] = useState(false);
  const [value, setValue] = React.useState<IDateRange | null>(
    props.initialValue
      ? {
          start: moment(props.initialValue.start).startOf('day'),
          end: moment(props.initialValue.end).startOf('day'),
        }
      : { start: moment().startOf('day'), end: moment().startOf('day') }
  );
  const [selectableStartDate, setSelectableStartDate] = React.useState<moment.Moment | null>(
    props.selectableStartDate || null
  );
  const [selectableEndDate, setSelectableEndDate] = React.useState<moment.Moment | null>(
    props.selectableEndDate || null
  );

  const [displayDate, setDisplayDate] = React.useState<IDateRange>({
    start: moment().startOf('day'),
    end: moment().startOf('day'),
  });
  const [dirty, setDirty] = useState(false);
  const [isFocused, setFocused] = useState(false);
  const [isOverX, setOverX] = useState(false);
  const calendarRef = useRef();
  const form = useContext(FormContext);
  const cellSize = 30;

  // バリデーションを使えるように設定
  const { mode } = props as any;
  let textInputRef: any = null;
  let textInputElement: any = null;
  const setTextInputRef = (element: any) => {
    textInputElement = element;
    textInputRef = form.register({ name: props.name }, props.validate || {});
  };

  // 初期値の指定がある場合には、初期値を設定
  useEffect(() => {
    if (props.initialValue || props.initialValue === '') {
      setValue(
        props.initialValue
          ? {
              start: moment(props.initialValue.start).startOf('day'),
              end: moment(props.initialValue.end).startOf('day'),
            }
          : { start: moment().startOf('day'), end: moment().startOf('day') }
      );
      setDisplayDate(
        props.initialValue
          ? {
              start: moment(props.initialValue.start).startOf('day'),
              end: moment(props.initialValue.end).startOf('day'),
            }
          : { start: moment().startOf('day'), end: moment().startOf('day') }
      );
      form.setValue(
        props.name,
        props.initialValue
          ? {
              start: moment(props.initialValue.start).startOf('day'),
              end: moment(props.initialValue.end).startOf('day'),
            }
          : { start: moment().startOf('day'), end: moment().startOf('day') },
        {
          shouldDirty: true,
          shouldValidate: true,
        }
      );
    }
  }, [props.initialValue]);

  // 追加のエラーメッセージがある場合にはセットする
  // TODO ここでエラーメッセージをセットしても、Submitはできてしまう。エラーがあったらSubmitもできないように改善したい
  useEffect(() => {
    if (props.additionalErrorMessage) {
      form.setError(props.name, {
        type: 'manual',
        message: props.additionalErrorMessage,
      });
    }
  }, [props.additionalErrorMessage]);

  useEffect(() => {
    if (textInputElement && props.focus === true) {
      setFocused(true);
      textInputElement.focus();
      setShowCalendar(true);
    }
  }, [textInputElement, props.focus]);

  useEffect(() => {
    setSelectableStartDate(props.selectableStartDate || null);
  }, [props.selectableStartDate]);

  useEffect(() => {
    setSelectableEndDate(props.selectableEndDate || null);
  }, [props.selectableEndDate]);

  useEffect(() => {
    setOverX(
      (calendarRef.current as any)?.getBoundingClientRect().x + (cellSize * 7 + 80 + 10 * 2) >
        window.innerWidth
    );
    textInputElement.click(); //TODO ここで一度クリックしてあげないと、VirtulizedList側でのz-index調整ロジックが発動せず、リストの下の行にかぶって表示されてしまう。このあたりだいぶ微妙なので、余裕ができたら仕組みから考えたいな
  }, []);

  const clickDocument = (e: any) => {
    if ((calendarRef?.current as any)?.contains(e.target)) {
      (calendarRef?.current as any)?.click();
      return;
    }
    if (props.onBlur) {
      props.onBlur(value, textInputElement);
    }
  };
  useEffect(() => {
    window.addEventListener('click', clickDocument);
    return () => {
      window.removeEventListener('click', clickDocument);
    };
  }, [clickDocument]);

  const startOfCalendarWithStart = moment(displayDate.start)
    .startOf('month')
    .add(-1 * moment(displayDate.start).startOf('month').isoWeekday() + 1, 'days'); // 月曜はじまりの場合
  const endOfCalendarWithStart = moment(displayDate.start)
    .endOf('month')
    .add(1, 'days') // 月曜はじまりの場合
    .add(7 - moment(displayDate.start).endOf('month').isoWeekday(), 'days');

  const startOfCalendarWithEnd = moment(displayDate.end)
    .startOf('month')
    .add(-1 * moment(displayDate.end).startOf('month').isoWeekday() + 1, 'days'); // 月曜はじまりの場合
  const endOfCalendarWithEnd = moment(displayDate.end)
    .endOf('month')
    .add(1, 'days') // 月曜はじまりの場合
    .add(7 - moment(displayDate.end).endOf('month').isoWeekday(), 'days');

  return (
    <View>
      {showCalendar && (
        <TouchableWithoutFeedback
          onPress={() => {
            if (props.onBlur) {
              props.onBlur(value, textInputElement);
            }
            setShowCalendar(false);
          }}>
          <View
            style={
              {
                position: 'fixed',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                opacity: 0,
              } as any
            }></View>
        </TouchableWithoutFeedback>
      )}
      <InputText
        multiline={props.multiline}
        ref={setTextInputRef}
        style={props.inputstyle as any}
        autoCompleteType={'off'}
        value={
          value ? `${value.start.format('YYYY/MM/DD')} 〜 ${value.end.format('YYYY/MM/DD')}` : ''
        }
        onFocus={() => setShowCalendar(true)}
        onKeyPress={(event: any) => {
          switch (event.nativeEvent.key) {
            case 'Enter':
              if (props.onChange) {
                props.onChange(value);
              }
              if (props.onPressEnter) {
                props.onPressEnter(value);
              }
              break;
            case 'Escape':
              setValue(
                props.initialValue
                  ? {
                      start: moment(props.initialValue.start).startOf('day'),
                      end: moment(props.initialValue.end).startOf('day'),
                    }
                  : { start: moment().startOf('day'), end: moment().startOf('day') }
              );
              setFocused(false);
              setDirty(false);
              if (props.onChange) {
                props.onChange(
                  props.initialValue
                    ? {
                        start: moment(props.initialValue.start).startOf('day'),
                        end: moment(props.initialValue.end).startOf('day'),
                      }
                    : null
                );
              }
              textInputElement.blur();
              break;
            default:
              break;
          }
        }}
      />
      {showCalendar && (
        <Container
          ref={calendarRef as any}
          style={[
            {
              backgroundColor: '#FFFFFF',
              position: 'absolute',
              top: 20,
              right: isOverX ? 0 : 'auto',
              zIndex: 2,
              borderWidth: 1,
              borderColor: themeContext.colors.separator,
              width: (cellSize * 7 + 10 * 2) * 2 + 30,
              paddingHorizontal: 10,
              paddingVertical: 10,
              shadowOffset: {
                width: 1,
                height: 1,
              },
              shadowOpacity: 0.1,
              elevation: 2,
            },
            props.containerStyle as any,
          ]}>
          {props.label !== null && props.label !== undefined && (
            <Label
              isFloat={isFocused || String(displayDate).length > 0}
              onPress={() => {
                setFocused(true);
                textInputElement.focus();
              }}>
              {props.label}
            </Label>
          )}

          <View style={{ flexDirection: 'row' }}>
            <>
              <View style={{ flexDirection: 'column' }}>
                <Typography variant={TypographyType.Normal} style={{ textAlign: 'center' }}>
                  開始日
                </Typography>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}>
                  <LeftIcon
                    size={26}
                    onPress={() =>
                      setDisplayDate({
                        start: moment(displayDate.start).add(-1, 'month'),
                        end: displayDate.end,
                      })
                    }
                  />
                  <Typography variant={TypographyType.Normal}>
                    {displayDate.start.format('YYYY年 M月')}
                  </Typography>
                  <RightIcon
                    size={26}
                    onPress={() =>
                      setDisplayDate({
                        start: moment(displayDate.start).add(1, 'month'),
                        end: displayDate.end,
                      })
                    }
                  />
                </View>
                <View style={{ flexDirection: 'row' }}>
                  <View
                    style={[
                      {
                        marginTop: 10,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        width: cellSize * 7,
                        height:
                          cellSize *
                            (endOfCalendarWithStart.diff(startOfCalendarWithStart, 'days') / 7) +
                          cellSize,
                      },
                      props.inputContainerStyle,
                    ]}>
                    {[...Array(7)].map((_, i) => {
                      const targetDate = moment(startOfCalendarWithStart).add(i, 'days');
                      const isWeekEnd =
                        targetDate.isoWeekday() === 6 || targetDate.isoWeekday() === 7;
                      return (
                        <View
                          style={{
                            width: cellSize,
                            height: cellSize,
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                          key={i}>
                          <Typography
                            variant={TypographyType.Normal}
                            style={{
                              color: isWeekEnd ? '#f33563' : themeContext.colors.textColor,
                              opacity: 0.5,
                            }}>
                            {targetDate.format('ddd')}
                          </Typography>
                        </View>
                      );
                    })}
                    {[...Array(endOfCalendarWithStart.diff(startOfCalendarWithStart, 'days'))].map(
                      (_, i) => {
                        const targetDate = moment(startOfCalendarWithStart).add(i, 'days');
                        const isDisable =
                          (selectableStartDate &&
                            moment(selectableStartDate!).startOf('day') >
                              targetDate.startOf('day')) ||
                          (selectableEndDate &&
                            moment(selectableEndDate!).startOf('day') < targetDate.startOf('day'));
                        return (
                          <CalendarCell
                            cellSize={cellSize}
                            date={targetDate}
                            displayDate={displayDate.start}
                            selectedDate={value || null}
                            selectDate={(newValue) => {
                              if (isDisable) {
                                return;
                              }

                              setDisplayDate({ start: newValue.startOf('day'), end: value!.end });
                              setValue({ start: newValue.startOf('day'), end: value!.end });
                            }}
                            displaySelectedMarkStart={true}
                            displaySelectedMarkEnd={false}
                            isDisable={isDisable || false}
                            key={i}
                          />
                        );
                      }
                    )}
                  </View>
                </View>
              </View>
            </>
            <View style={{ width: 30 }}></View>
            <>
              <View style={{ flexDirection: 'column' }}>
                <Typography variant={TypographyType.Normal} style={{ textAlign: 'center' }}>
                  終了日
                </Typography>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}>
                  <LeftIcon
                    size={26}
                    onPress={() =>
                      setDisplayDate({
                        start: displayDate.start,
                        end: moment(displayDate.end).add(-1, 'month'),
                      })
                    }
                  />
                  <Typography variant={TypographyType.Normal}>
                    {displayDate.end.format('YYYY年 M月')}
                  </Typography>
                  <RightIcon
                    size={26}
                    onPress={() =>
                      setDisplayDate({
                        start: displayDate.start,
                        end: moment(displayDate.end).add(1, 'month'),
                      })
                    }
                  />
                </View>
                <View style={{ flexDirection: 'row' }}>
                  <View
                    style={[
                      {
                        marginTop: 10,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        width: cellSize * 7,
                        height:
                          cellSize *
                            (endOfCalendarWithEnd.diff(startOfCalendarWithEnd, 'days') / 7) +
                          cellSize,
                      },
                      props.inputContainerStyle,
                    ]}>
                    {[...Array(7)].map((_, i) => {
                      const targetDate = moment(startOfCalendarWithEnd).add(i, 'days');
                      const isWeekEnd =
                        targetDate.isoWeekday() === 6 || targetDate.isoWeekday() === 7;
                      return (
                        <View
                          style={{
                            width: cellSize,
                            height: cellSize,
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                          key={i}>
                          <Typography
                            variant={TypographyType.Normal}
                            style={{
                              color: isWeekEnd ? '#f33563' : themeContext.colors.textColor,
                              opacity: 0.5,
                            }}>
                            {targetDate.format('ddd')}
                          </Typography>
                        </View>
                      );
                    })}
                    {[...Array(endOfCalendarWithEnd.diff(startOfCalendarWithEnd, 'days'))].map(
                      (_, i) => {
                        const targetDate = moment(startOfCalendarWithEnd).add(i, 'days');
                        const isDisable =
                          (selectableStartDate &&
                            moment(selectableStartDate!).startOf('day') >
                              targetDate.startOf('day')) ||
                          (selectableEndDate &&
                            moment(selectableEndDate!).startOf('day') < targetDate.startOf('day'));
                        return (
                          <CalendarCell
                            cellSize={cellSize}
                            date={targetDate}
                            displayDate={displayDate.end}
                            selectedDate={value || null}
                            selectDate={(newValue) => {
                              if (isDisable) {
                                return;
                              }

                              setDisplayDate({ start: value!.start, end: newValue.startOf('day') });
                              setValue({ start: value!.start, end: newValue.endOf('day') });
                            }}
                            displaySelectedMarkStart={false}
                            displaySelectedMarkEnd={true}
                            isDisable={isDisable || false}
                            key={i}
                          />
                        );
                      }
                    )}
                  </View>
                </View>
              </View>
              {/* {form.errors[props.name] && (
                <ErrorMessage>{form.errors[props.name].message}</ErrorMessage>
              )}
              {(form.errors[props.name] === null || form.errors[props.name] === undefined) &&
                props.description && <Description>{props.description}</Description>} */}
            </>
          </View>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingTop: 10,
              marginTop: 10,
              borderTopWidth: 1,
              borderColor: themeContext.colors.separator,
            }}>
            <View>
              <Typography
                variant={TypographyType.Normal}
                style={{ color: themeContext.colors.description }}>
                {value
                  ? `${value.start.format('YYYY/MM/DD')} 〜 ${value.end.format('YYYY/MM/DD')}`
                  : ''}
              </Typography>
            </View>
            <View style={{ flexDirection: 'row' }}>
              <Button
                text={'決定'}
                style={{ minWidth: 30, paddingVertical: 2, paddingHorizontal: 10 }}
                textStyle={{ fontSize: 14 }}
                disableValidate={true}
                onPress={() => {
                  setShowCalendar(false);
                  if (props.onChange) {
                    props.onChange(value);
                  }
                }}
              />
              {props.disableClear !== true && (
                <TouchableOpacity onPress={() => setValue(null)} style={{ marginLeft: 10 }}>
                  <Typography variant={TypographyType.Normal} style={{}}>
                    クリア
                  </Typography>
                </TouchableOpacity>
              )}
            </View>
          </View>
        </Container>
      )}
    </View>
  );
};

export default DateRangePicker;
