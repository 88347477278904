import React, { useContext, useMemo, useState } from 'react';
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../../theme';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import Input from '../../../../presentational/atoms/input';
import Form from '../../../../presentational/atoms/form';
import { useHistory } from 'react-router';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import {
  Organization,
  ProjectTemplate,
  ProjectTemplateSortKey,
  SortOrder,
  useOrganizationQuery,
  useProjectTemplatesQuery,
  useUpdateProjectTemplateSortNoInListMutation,
} from '../../../../../graphql/api/API';
import ProjectTemplateCreateDialog from '../project-template-create-dialog';
import Modal from '../../../../presentational/molecules/modal';
import VirtualizedFlatList, {
  GlobalDragContextProvider,
} from '../../../../presentational/atoms/list2/virtualized-flat-list';
import { TouchableOpacity, View } from 'react-native';
import PlusIcon from '../../../../presentational/molecules/image-icon/plus';
import SearchIcon from '../../../../presentational/molecules/image-icon/search';
import CaretSetIcon from '../../../../presentational/molecules/image-icon/caret-set';
import CaretDownIcon from '../../../../presentational/molecules/image-icon/caret-down';
import CaretUpIcon from '../../../../presentational/molecules/image-icon/caret-up';
import when from '../../../../../lang-extention/When';

const Container = styled.View`
  z-index: 1;
`;
// 本当はoverflow-xはvisibleにしたいのだが、CSSの仕様で、overflow-yを指定した場合には、xの挙動もそちらで上書きされるため実現できない

const ProjectItem = styled.View`
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  min-width: 200px;
  border-bottom-width: 1px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
`;

const ProjectAddButton = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  padding-right: 5px;
  padding-left: 5px;
`;

const Search = styled.View``;

interface IListProps {
  organization: Organization;
  setShowDialog: (value: boolean) => void;
}

const List = React.memo((props: IListProps) => {
  const [searchProjectName, setSearchProjectName] = useState('');
  const [searchProjectNameLive, setSearchProjectNameLive] = useState('');
  const [sortKey, setSortKey] = useState<ProjectTemplateSortKey>(ProjectTemplateSortKey.SortNo);
  const [sortOrder, setSortOrder] = useState<SortOrder | null>(null);
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const { push } = useHistory();

  const projectsResult = useProjectTemplatesQuery({
    fetchPolicy: 'network-only',
    variables: {
      input: {
        name: searchProjectName,
        sortKey: sortKey,
        sortOrder: sortOrder,
      },
    },
  });
  const [updateProjectSortNo, _] = useUpdateProjectTemplateSortNoInListMutation();

  // Memo化しておかないと、データに変更がなくても、このコンポーネントが描画される度に、全てのTaskSummary再描画がされてしまう。
  const projects = useMemo(() => {
    if (projectsResult.loading || !projectsResult.data?.projectTemplates) {
      return [];
    }

    if (sortKey === ProjectTemplateSortKey.SortNo) {
      return projectsResult
        .data!.projectTemplates!.slice()
        .filter((project) => project!.name.indexOf(searchProjectName) !== -1)
        .sort((a, b) => {
          return b!.sortNoInList - a!.sortNoInList; // ソート番号は昇順
        });
    }
    return projectsResult
      .data!.projectTemplates!.slice()
      .filter((project) => project!.name.indexOf(searchProjectName) !== -1);
  }, [projectsResult.loading, projectsResult.data?.projectTemplates, searchProjectName, sortKey]);

  return (
    <>
      <View
        style={{
          backgroundColor: themeContext.colors.header,
          paddingVertical: 7,
          paddingHorizontal: 3,
        }}>
        <Typography
          variant={TypographyType.SubTitle}
          ellipsis={true}
          style={{ color: '#FFFFFF', fontSize: 16, fontWeight: '600' }}>
          プロジェクトテンプレート一覧
        </Typography>
      </View>
      <Search style={{ borderBottomWidth: 1, borderColor: themeContext.colors.separator }}>
        <Form>
          <Input
            name={'searchProject'}
            label={''}
            icon={<SearchIcon size={20} containerStyle={{ marginLeft: 10 }} />}
            containerStyle={{ marginVertical: 10 }}
            value={searchProjectName}
            inputstyle={{ borderWidth: 0, fontSize: 14 }}
            inputContainerStyle={{
              marginHorizontal: 10,
              backgroundColor: themeContext.colors.baseColor,
              shadowOffset: {
                width: -1,
                height: -1,
              },
              shadowOpacity: 0.1,
              elevation: 2,
              borderWidth: 1,
              borderRadius: 20,
              borderColor: themeContext.colors.separator,
            }}
            onChange={(value: string) => {
              setSearchProjectNameLive(value);
              if (value?.trim() === '') {
                setSearchProjectName('');
              }
            }}
            onPressEnterIncludeIMEConvert={(value: string) => setSearchProjectName(value)}
            onBlur={(value: string) => setSearchProjectName(value)}
          />
          {searchProjectNameLive?.trim().length > 0 && (
            <Typography
              variant={TypographyType.Description}
              style={{ marginLeft: 10, fontSize: 12, textAlign: 'center', marginBottom: 10 }}>
              Enterキーを押すと絞り込みが行われます
            </Typography>
          )}
        </Form>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', overflow: 'hidden' }}>
          <ProjectAddButton
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: 10,
              paddingBottom: 10,
              paddingHorizontal: 5,
            }}
            onPress={async () => {
              props.setShowDialog(true);
            }}>
            <PlusIcon
              size={12}
              containerStyle={{ marginLeft: 10 }}
              onPress={async () => {
                props.setShowDialog(true);
              }}
            />
            <Typography
              variant={TypographyType.Normal}
              style={{ fontSize: 12, color: themeContext.colors.description }}
              ellipsis={true}>
              テンプレートを追加する
            </Typography>
          </ProjectAddButton>
          <TouchableOpacity
            style={{ flexDirection: 'row', alignItems: 'center' }}
            onPress={() => {
              if (sortOrder === null || sortOrder === undefined) {
                setSortKey(ProjectTemplateSortKey.Name);
                setSortOrder(SortOrder.Asc);
                return;
              }
              if (sortOrder === SortOrder.Asc) {
                setSortKey(ProjectTemplateSortKey.Name);
                setSortOrder(SortOrder.Desc);
                return;
              }
              setSortKey(ProjectTemplateSortKey.SortNo);
              setSortOrder(null);
            }}>
            {when(sortOrder) //
              .on(
                (v) => v === null || v === undefined,
                () => (
                  <CaretSetIcon
                    size={12}
                    containerStyle={{
                      opacity: 0.2,
                      marginLeft: 3,
                      width: 10,
                      justifyContent: 'flex-end',
                    }}
                    onPress={() => {
                      setSortKey(ProjectTemplateSortKey.Name);
                      setSortOrder(SortOrder.Asc);
                    }}
                  />
                )
              )
              .on(
                (v) => v === SortOrder.Asc,
                () => (
                  <CaretUpIcon
                    size={10}
                    containerStyle={{ marginLeft: 3, width: 10, justifyContent: 'flex-end' }}
                    onPress={() => {
                      setSortKey(ProjectTemplateSortKey.Name);
                      setSortOrder(SortOrder.Desc);
                    }}
                  />
                )
              )
              .otherwise(() => (
                <CaretDownIcon
                  size={10}
                  containerStyle={{ marginLeft: 3, width: 10, justifyContent: 'flex-end' }}
                  onPress={() => {
                    setSortKey(ProjectTemplateSortKey.SortNo);
                    setSortOrder(null);
                  }}
                />
              ))}
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontSize: 12,
                color: themeContext.colors.description,
                textAlign: 'left',
                paddingHorizontal: 5,
                width: 70,
              }}>
              並び替え
            </Typography>
          </TouchableOpacity>
        </View>
      </Search>

      <GlobalDragContextProvider>
        <VirtualizedFlatList
          style={{ height: 'calc(100vh - 220px)' }}
          items={projects}
          renderItem={(item, index) => {
            return (
              <ProjectItem>
                <View style={{ flexDirection: 'column', maxWidth: '100%' }}>
                  {(item as ProjectTemplate).client && (
                    <Typography
                      variant={TypographyType.Description}
                      style={{
                        fontSize: 10,
                        color: themeContext.colors.description,
                        textAlign: 'left',
                        lineHeight: 11,
                      }}
                      ellipsis={true}>
                      {(item as ProjectTemplate).client!.name}
                    </Typography>
                  )}
                  <Typography
                    variant={TypographyType.Normal}
                    ellipsis={true}
                    tooltipId={`project-template-${(item as ProjectTemplate).id}`}
                    tooltipWhenEllipsis
                    style={{
                      fontSize: 13,
                      color: themeContext.colors.textColor,
                      textAlign: 'left',
                    }}>
                    {(item as ProjectTemplate).name}
                  </Typography>
                </View>
              </ProjectItem>
            );
          }}
          getKey={(project) => (project as ProjectTemplate).id!.toString()}
          itemHeight={40}
          onPress={(project) => {
            push(
              `/app/${loginUser!.organizationId}/project-templates/${
                (project as ProjectTemplate).id
              }/list/`
            );
          }}
          virticalDraggable={sortKey === ProjectTemplateSortKey.SortNo}
          onDrop={async (info) => {
            const isMoveToFirst = info.endRowIndex === 0;
            const isMoveToLast = info.endRowIndex === projects.length - 1;
            const isMoveToDown = info.endRowIndex - info.startRowIndex > 0;

            let sortNo;
            if (isMoveToFirst) {
              sortNo = new Date().getTime();
            } else if (isMoveToLast) {
              sortNo = projects[info.endRowIndex]!.sortNoInList - 1000;
            } else {
              if (isMoveToDown) {
                const beforeTask = projects[info.endRowIndex];
                const afterTask = projects[info.endRowIndex + 1];
                sortNo = Math.floor((beforeTask!.sortNoInList + afterTask!.sortNoInList) / 2);
              } else {
                const beforeTask = projects[info.endRowIndex - 1];
                const afterTask = projects[info.endRowIndex];
                sortNo = Math.floor((beforeTask!.sortNoInList + afterTask!.sortNoInList) / 2);
              }
            }

            await updateProjectSortNo({
              variables: {
                id: (info.item as ProjectTemplate).id!,
                input: {
                  sortNoInList: sortNo,
                  versionNo: (info.item as ProjectTemplate).versionNo,
                },
              },
              optimisticResponse: {
                __typename: 'Mutation',
                updateProjectTemplateSortNoInList: Object.assign(
                  {
                    __typename: 'ProjectTemplate',
                  },
                  info.item as ProjectTemplate,
                  { sortNoInList: sortNo }
                ),
              },
            });
          }}
        />
      </GlobalDragContextProvider>
    </>
  );
});

const ProjectList = () => {
  const { push } = useHistory();
  const [showDialog, setShowDialog] = useState(false);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const { loading, data } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId,
    },
  });

  if (loading) {
    return <></>;
  }

  return (
    <Container>
      <List organization={data!.organization!} setShowDialog={setShowDialog} />
      <Modal
        isShow={showDialog}
        title="テンプレートを作成する"
        onClose={() => {
          setShowDialog(false);
        }}>
        <ProjectTemplateCreateDialog
          onComplete={(newProject) => {
            push(`/app/${loginUser!.organizationId}/project-templates/${newProject.id}/list/`);
            setShowDialog(false);
          }}
          onCancel={() => {
            setShowDialog(false);
          }}
        />
      </Modal>
    </Container>
  );
};

export default ProjectList;
