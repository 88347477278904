import React, { useContext, useState } from 'react';
import styled, { ThemeContext } from 'styled-components/native';
import { View, Text } from 'react-native';
import { IThemePart } from '../../../../../theme';
import Calendar, { CALENDAR_DISPLAY_DATE_RANGE } from '../../../../share/organisms/calenar';
import moment from 'moment-timezone';
import * as Cookies from 'js-cookie';
import { LoginUserContext } from '../../../../../../modules/auth/LoginUserContext';

const AppMyCalendarPage = () => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const initialDisplayDateRange = Cookies.get(CALENDAR_DISPLAY_DATE_RANGE)
    ? Number(Cookies.get(CALENDAR_DISPLAY_DATE_RANGE))
    : 7;
  const [startDateTime, setStartDateTime] = useState(
    initialDisplayDateRange === 7
      ? moment().startOf('week').startOf('date')
      : moment().startOf('date')
  );
  const [endDateTime, setEndDateTime] = useState(
    initialDisplayDateRange === 7
      ? moment()
          .startOf('week')
          .startOf('date')
          .add(initialDisplayDateRange - 1, 'days')
          .endOf('date')
      : moment()
          .startOf('date')
          .add(initialDisplayDateRange - 1, 'days')
          .endOf('date')
  );

  return (
    <View>
      <Calendar
        initialDisplayDateRange={initialDisplayDateRange}
        memberId={loginUser!.id}
        startDateTime={startDateTime}
        endDateTime={endDateTime}
        horizontalDragEnable={true}
        isRightSideBar={false}
      />
    </View>
  );
};

export default React.memo(AppMyCalendarPage);
