import React, { useContext, useState } from 'react';
import { View } from 'react-native';
import { useHistory } from 'react-router';
import styled, { ThemeContext } from 'styled-components/native';
import Typography, { TypographyType } from '../../../../../presentational/atoms/typography';
import CloseIcon from '../../../../../presentational/molecules/image-icon/close';
import MenuIcon from '../../../../../presentational/molecules/image-icon/menu';
import PlayIcon from '../../../../../presentational/molecules/image-icon/play';
import { IStyleTheme, IThemePart } from '../../../../../theme';
import HorizontalMenu from '../../horizontal-menu';

const HeaderMenu = styled.View`
  display: flex;
  flex-direction: row;
  padding: 10px 15px;
  justify-content: space-between;
  align-items: center;
  border-bottom-width: 1px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
`;

const CommonArea = styled.View`
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  align-items: flex-start;
`;

interface ITaskDetailCommonProps {
  children: React.ReactNode;
}

const TaskDetailCommonLoading = (props: ITaskDetailCommonProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const { push } = useHistory();
  return (
    <>
      <HeaderMenu>
        <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          <MenuIcon size={28} containerStyle={{ marginLeft: 5 }} />
          <CloseIcon
            size={18}
            containerStyle={{ marginLeft: 5 }}
            onPress={() => push(location.hash)}
          />
        </View>
      </HeaderMenu>
      {props.children}
    </>
  );
};

export default TaskDetailCommonLoading;
