import React from 'react';
import { Image, Platform } from 'react-native';
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';
import iconImage from '../../../../../base64Images/edit';

interface IEditIconProps {
  size: number;
  onPress?: () => void;
  disabled?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  children?: React.ReactNode;
}

const EditIcon = (props: IEditIconProps) => {
  return (
    <TouchableOpacity
      disabled={props.disabled}
      onPress={props.onPress}
      style={[
        { justifyContent: 'center', alignItems: 'center', flexDirection: 'row' },
        props.containerStyle,
      ]}>
      <Image
        source={{ uri: iconImage }}
        resizeMode="contain"
        style={[
          {
            height: props.size,
            width: props.size,
            opacity: 0.3,
            shadowOffset: {
              width: 1,
              height: 1,
            },
            shadowOpacity: 0.1,
          } as any,
          Platform.OS === 'web' ? { elevation: 2, cursor: 'pointer' } : {},
        ]}
      />
      {props.children}
    </TouchableOpacity>
  );
};

export default EditIcon;
