import React, { useContext, useEffect } from 'react';
import { TouchableOpacity, View } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import {
  CalendarEvent,
  Plan,
  Project,
  useTeamMembersQuery,
  useTeamQuery,
  useUpdateCalendarEventMutation,
} from '../../../../../../graphql/api/API';
import Avatar from '../../../../../presentational/atoms/avatar';
import EditableText from '../../../../../presentational/atoms/editable-text';
import OpenLinkIcon from '../../../../../presentational/molecules/image-icon/open-in-new';
import Link from '../../../../../presentational/atoms/link';
import Typography, { TypographyType } from '../../../../../presentational/atoms/typography';
import { IStyleTheme, IThemePart } from '../../../../../theme';
import TaskInfoElement from '../task-info-element';
import moment from 'moment-timezone';
import { LoginUserContext } from '../../../../../../modules/auth/LoginUserContext';
import TimeUtil from '../../../../../../util/TimeUtil';
import DateUtil from '../../../../../../util/DateUtil';
import { useHistory } from 'react-router';
import UrlUtil from '../../../../../../util/UrlUtil';

const Conatiner = styled.View`
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  align-items: flex-start;
  z-index: 2; //DateTimePickerを使用する際には、外側の要素のz-indexを指定しないと正しく表示されないケースがあるので、ここで指定している
`;

const Row = styled.View`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: space-between;
`;

interface ITaskDetailStandardInfoProps {
  organizationId: string;
  calendarEvent: CalendarEvent;
  project: Project;
  parentScrollViewRef: any;
}

const TaskDetailStandardInfo = (props: ITaskDetailStandardInfoProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, _] = useContext(LoginUserContext);
  const history = useHistory();
  const fetchTeamResult = useTeamQuery({
    variables: {
      id: props.project.team.id!,
    },
  });
  const fetchTeamMembers = useTeamMembersQuery({
    variables: {
      teamId: props.project.team.id!,
    },
  });
  const scrollToTop = () => {
    (props.parentScrollViewRef.current as any)?.scrollTo(0);
  };

  useEffect(() => {
    scrollToTop();
  }, [props.parentScrollViewRef]);

  if (
    fetchTeamResult.loading ||
    !fetchTeamResult.data?.team ||
    fetchTeamMembers.loading ||
    !fetchTeamMembers.data?.teamMembers
  ) {
    return <></>;
  }

  const calendarInfo = props.calendarEvent.thirdPartyCalendarInfoList.find(
    (info) => info!.member.id === loginUser!.id
  );

  return (
    <>
      <Conatiner>
        <Row>
          <TaskInfoElement title={'プロジェクト'} style={{ flex: 1, maxWidth: 400 }}>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Typography variant={TypographyType.Normal} style={{ marginLeft: 5 }}>
                {props.calendarEvent.projectName}
              </Typography>
            </View>
          </TaskInfoElement>
        </Row>
        {props.calendarEvent.taskId && (
          <Row>
            <TaskInfoElement title={'タスク'} style={{ flex: 1, maxWidth: 400 }}>
              <TouchableOpacity
                style={{ display: 'flex', flexDirection: 'row' }}
                onPress={() => {
                  history.push(UrlUtil.createTaskDetailUrlById(props.calendarEvent.taskId!));
                }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{ marginLeft: 5, color: themeContext.colors.link }}>
                  {props.calendarEvent.taskTitle}
                </Typography>
              </TouchableOpacity>
            </TaskInfoElement>
          </Row>
        )}
        <Row style={{ zIndex: 2, justifyContent: 'flex-start' }}>
          <TaskInfoElement title={'開始日時'} style={{ flex: 1 }}>
            <Typography variant={TypographyType.Normal} style={{ fontSize: '1rem' }}>
              {DateUtil.formatDateTime(props.calendarEvent.startDateTime)}
            </Typography>
          </TaskInfoElement>
        </Row>
        <Row style={{ zIndex: 2, justifyContent: 'flex-start' }}>
          <TaskInfoElement title={'終了日時'} style={{ flex: 1 }}>
            <Typography variant={TypographyType.Normal} style={{ fontSize: '1rem' }}>
              {DateUtil.formatDateTime(props.calendarEvent.endDateTime)}
            </Typography>
          </TaskInfoElement>
        </Row>
        <Row style={{ zIndex: 2, justifyContent: 'flex-start' }}>
          <TaskInfoElement title={'作業時間'} style={{ flex: 1 }}>
            <Typography variant={TypographyType.Normal} style={{ fontSize: '1rem' }}>
              {TimeUtil.formatForTask(props.calendarEvent.workingTimeSec)}
            </Typography>
          </TaskInfoElement>
        </Row>
        {calendarInfo && (
          <Row style={{ zIndex: 2, justifyContent: 'flex-start' }}>
            <TaskInfoElement title={'Googleカレンダー'} style={{ flex: 1 }}>
              <OpenLinkIcon size={14} containerStyle={{ marginLeft: 10 }}>
                <Link
                  style={{ marginLeft: 3 }}
                  path={`https://www.google.com/calendar/event?eid=${btoa(
                    props.calendarEvent.thirdPartyCalendarEventId +
                      ' ' +
                      calendarInfo.thirdPartyCalendarId
                  ).replace('==', '')}`}
                  isExternalLink={true}>
                  <Typography
                    variant={TypographyType.Normal}
                    style={{ fontSize: '1rem', color: themeContext.colors.link }}>
                    Googleカレンダーで開く
                  </Typography>
                </Link>
              </OpenLinkIcon>
              <Typography
                variant={TypographyType.Description}
                style={{ fontSize: '0.8rem', marginLeft: 5, marginTop: 5 }}>
                ※Googleカレンダー上で、この予定の参照権限がある方しか閲覧できません。
              </Typography>
            </TaskInfoElement>
          </Row>
        )}
      </Conatiner>
    </>
  );
};

export default TaskDetailStandardInfo;
