import React, { useContext, useEffect, useRef, useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { useHistory, useLocation } from 'react-router';
import styled, { ThemeContext } from 'styled-components/native';
import {
  ProjectTemplate,
  useDeleteProjectTemplateMutation,
  useProjectTemplateQuery,
  useUpdateProjectTemplateMutation,
} from '../../../../../../graphql/api/API';
import { LoginUserContext } from '../../../../../../modules/auth/LoginUserContext';
import Button from '../../../../../presentational/atoms/button';
import EditableText from '../../../../../presentational/atoms/editable-text';
import Typography, { TypographyType } from '../../../../../presentational/atoms/typography';
import Modal from '../../../../../presentational/molecules/modal';
import { IStyleTheme, IThemePart } from '../../../../../theme';
import { useHotkeys } from 'react-hotkeys-hook';
import UrlUtil from '../../../../../../util/UrlUtil';
import DeleteIcon from '../../../../../presentational/molecules/image-icon/delete';
import CloseIcon from '../../../../../presentational/molecules/image-icon/close';
import MenuIcon from '../../../../../presentational/molecules/image-icon/menu';

const HeaderMenu = styled.View`
  display: flex;
  flex-direction: row;
  padding: 10px 15px;
  justify-content: space-between;
  align-items: center;
  border-bottom-width: 1px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
`;

const TitleArea = styled.View`
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  align-items: flex-start;
`;

const CommonArea = styled.View`
  display: flex;
  flex-direction: column;
  padding: 5px 15px;
  align-items: flex-start;
`;

const Menu = styled.View`
  position: absolute;
  top: 40px;
  right: 0;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
  border-width: 1px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 160px;
`;

interface IDeleteProjectModalProps {
  project: ProjectTemplate;
  showModal: boolean;
  onPressYes: () => Promise<void>;
  onCloseModal: () => void;
}
const DeleteProjectConfirmModal = (props: IDeleteProjectModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  return (
    <Modal
      title={'このプロジェクトを削除しますか？'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <View style={{ flexDirection: 'column' }}>
        <View style={{ marginTop: 10 }}>
          <Typography variant={TypographyType.Normal} style={{ textAlign: 'center' }}>
            {props.project.name}
          </Typography>
        </View>
        <View style={{ marginTop: 10 }}>
          <Typography
            variant={TypographyType.Description}
            style={{ textAlign: 'center', color: themeContext.colors.error }}>
            {`プロジェクトのタスクや作業履歴等のデータも全て削除されます。${'\n\n'}この操作はやり直しが出来ません。${'\n'}本当に削除してよろしいですか？`}
          </Typography>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
          <Button
            text={'キャンセル'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.primary }}
            disableValidate={true}
            onPress={() => {
              props.onCloseModal();
            }}
          />
          <Button
            text={'削除する'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              borderColor: themeContext.colors.error,
              borderRadius: 3,
              borderWidth: 1,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.error }}
            onPress={async () => {
              await props.onPressYes();
            }}
          />
        </View>
      </View>
    </Modal>
  );
};

interface ICompleteProjectModalProps {
  project: ProjectTemplate;
  showModal: boolean;
  onPressYes: () => Promise<void>;
  onCloseModal: () => void;
}
const CompleteProjectConfirmModal = (props: ICompleteProjectModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  return (
    <Modal
      title={'このプロジェクトを完了しますか？'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <View style={{ flexDirection: 'column' }}>
        <View style={{ marginTop: 10 }}>
          <Typography variant={TypographyType.Normal} style={{ textAlign: 'center' }}>
            {props.project.name}
          </Typography>
        </View>
        <View style={{ marginTop: 10 }}>
          <Typography variant={TypographyType.Description} style={{ textAlign: 'center' }}>
            {`作業中のタスクがある場合、自動的に作業停止します。`}
          </Typography>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
          <Button
            text={'完了する'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
            }}
            onPress={async () => {
              await props.onPressYes();
            }}
          />
          <Button
            text={'キャンセル'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.primary }}
            disableValidate={true}
            onPress={() => {
              props.onCloseModal();
            }}
          />
        </View>
      </View>
    </Modal>
  );
};

interface IIncompleteProjectModalProps {
  project: ProjectTemplate;
  showModal: boolean;
  onPressYes: () => Promise<void>;
  onCloseModal: () => void;
}
const IncompleteProjectConfirmModal = (props: IIncompleteProjectModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  return (
    <Modal
      title={'このプロジェクトを未完了に戻しますか？'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <View style={{ flexDirection: 'column' }}>
        <View style={{ marginTop: 10 }}>
          <Typography variant={TypographyType.Normal} style={{ textAlign: 'center' }}>
            {props.project.name}
          </Typography>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
          <Button
            text={'未完了に戻す'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
            }}
            onPress={async () => {
              await props.onPressYes();
            }}
          />
          <Button
            text={'キャンセル'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.primary }}
            disableValidate={true}
            onPress={() => {
              props.onCloseModal();
            }}
          />
        </View>
      </View>
    </Modal>
  );
};

interface IProjectDetailCommonProps {
  organizationId: string;
  project: ProjectTemplate;
  projectDetailMode: string;
  children: React.ReactNode;
}

const ProjectDetailCommon = (props: IProjectDetailCommonProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const history = useHistory();
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const { pathname } = useLocation();
  const attachmentFileInputRef = useRef();
  const fetchProjectResult = useProjectTemplateQuery({
    variables: {
      id: props.project.id!,
    },
  });

  const [showModal, setShowModal] = useState(false);
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [showIncompleteModal, setShowIncompleteModal] = useState(false);
  const [projectName, setProjectName] = useState(props.project.name);
  const [clientId, setClientId] = useState(props.project.client?.id || null);
  const menuRef = useRef();
  const [openMenu, setOpenMenu] = useState(false);
  useHotkeys('Esc', () => setOpenMenu(false));
  const clickDocument = (e: any) => {
    if ((menuRef?.current as any)?.contains(e.target)) {
      (menuRef?.current as any)?.click();
      return;
    }
    setOpenMenu(false);
  };
  useEffect(() => {
    window.addEventListener('click', clickDocument);
    return () => {
      window.removeEventListener('click', clickDocument);
    };
  }, [clickDocument]);

  // const [completeProject] = useCompleteProjectMutation({
  //   variables: {
  //     id: props.project.id!,
  //     input: {
  //       versionNo: props.project.versionNo,
  //     },
  //   },
  // });
  // const [incompleteProject] = useIncompleteProjectMutation({
  //   variables: {
  //     id: props.project.id!,
  //     input: {
  //       versionNo: props.project.versionNo,
  //     },
  //   },
  // });
  const [deleteProject] = useDeleteProjectTemplateMutation({
    variables: {
      id: props.project.id!,
      input: {
        versionNo: props.project.versionNo,
      },
    },
  });
  const [updateProject, _] = useUpdateProjectTemplateMutation({
    variables: {
      id: props.project.id!,
      input: {
        name: projectName,
        clientId: clientId || null,
        versionNo: props.project.versionNo,
      },
    },
  });

  if (fetchProjectResult.loading) {
    return <></>;
  }

  const isCurrentProject =
    pathname.indexOf(`/app/${props.organizationId}/project-templates/${props.project.id!}/`) !== -1;

  return (
    <View>
      <View
        style={
          {
            position: 'sticky',
            top: 0,
            zIndex: 5,
            backgroundColor: themeContext.colors.baseColor,
          } as any
        }>
        <HeaderMenu style={{ zIndex: 3 }}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          />
          <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <MenuIcon
              size={28}
              containerStyle={{ marginLeft: 5 }}
              onPress={() => setOpenMenu(true)}
            />
            <CloseIcon
              size={18}
              containerStyle={{ marginLeft: 5 }}
              onPress={() => {
                history.push(
                  UrlUtil.removeSearchs(['projectTemplateDetailId', 'projectDetailMode'])
                );
              }}
            />
          </View>
          {openMenu && (
            <Menu ref={menuRef as any}>
              <View style={{ paddingHorizontal: 5, marginVertical: 5 }}>
                <DeleteIcon
                  size={23}
                  containerStyle={{ marginRight: 5 }}
                  onPress={() => {
                    setOpenMenu(false);
                    setShowModal(true);
                  }}>
                  <Typography variant={TypographyType.SubTitle}>削除する</Typography>
                </DeleteIcon>
              </View>
            </Menu>
          )}
          <DeleteProjectConfirmModal
            project={props.project}
            showModal={showModal}
            onPressYes={async () => {
              setShowModal(false);
              // 先にURLを書き換えて、プロジェクト詳細画面を閉じた後に、削除リクエストをする。
              // 順序を逆にすると、削除後にプロジェクト情報を取得しようとして、サーバーサイドでEntityAlreadyDeletedException発生してしまうので注意すること。
              history.push(`/app/${loginUser!.organizationId}/project-templates`);
              await deleteProject();
            }}
            onCloseModal={() => setShowModal(false)}
          />
        </HeaderMenu>

        <TitleArea>
          <EditableText
            style={{ width: '100%' }}
            ellipsis={false}
            value={props.project.name}
            validate={{
              required: {
                value: true,
                message: 'タイトルを入力してください',
              },
              maxLength: {
                value: 100,
                message: '100文字以内で入力してください',
              },
            }}
            onChange={(value) => {
              updateProject({
                variables: {
                  id: props.project.id!,
                  input: {
                    name: (value as string) || props.project.name,
                    description: props.project.description,
                    clientId: clientId || null,
                    versionNo: props.project.versionNo,
                  },
                },
              });
            }}
            textStyle={{ fontSize: 18 }}
          />
        </TitleArea>
      </View>
      {props.children}
    </View>
  );
};

export default ProjectDetailCommon;
