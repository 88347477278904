import { Reference } from '@apollo/client';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Animated, type NativeSyntheticEvent, View } from 'react-native';
import {
  type GestureHandlerStateChangeNativeEvent,
  PanGestureHandler,
  type PanGestureHandlerEventPayload,
  State,
} from 'react-native-gesture-handler';
import { useHistory, useParams } from 'react-router';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import { type TaskFilter, TaskFilterContext, matchExpireFilter, matchTaskAssigner } from '..';
import {
  type Member,
  type Organization,
  Plan,
  type Project,
  type Task,
  type TaskStatus,
  useMeQuery,
  useOrganizationQuery,
  useProjectQuery,
  useProjectTaskStatusQuery,
  useUpdateStatusOfTaskMutation,
  useUpdateTaskStatusMutation,
} from '../../../../../../../graphql/api/API';
import { useCustomBoardTasksQuery } from '../../../../../../../graphql/api/CustomApi';
import EditableText from '../../../../../../presentational/atoms/editable-text';
import VirtualizedFlatList, {
  GlobalDragColumnContextProvider,
  GlobalDragContextProvider,
  type IListRefInfo,
  NonForwardedRefAnimatedView,
} from '../../../../../../presentational/atoms/list2/virtualized-flat-list';
import Spinner from '../../../../../../presentational/atoms/spinner';
import Typography, { TypographyType } from '../../../../../../presentational/atoms/typography';
import CheckIcon from '../../../../../../presentational/molecules/image-icon/check';
import DeleteIcon from '../../../../../../presentational/molecules/image-icon/delete';
import MenuIcon from '../../../../../../presentational/molecules/image-icon/menu';
import PlusIcon from '../../../../../../presentational/molecules/image-icon/plus';
import Modal from '../../../../../../presentational/molecules/modal';
import type { IStyleTheme, IThemePart } from '../../../../../../theme';
import PlanNotAllowedView from '../../../../organisms/plan-not-allowed-view';
import TaskArchiveOnBoardDialog from '../../../../organisms/task-archive-on-board-dialog';
import TaskStatusCreateDialog from '../../../../organisms/task-status-create-dialog';
import TaskStatusDeleteDialog from '../../../../organisms/task-status-delete-dialog';
import TaskSummaryForBoard from '../../../../organisms/task-summary-for-board';
import TaskSummaryForBoardNewTask from '../../../../organisms/task-summary-for-board-new-task';

const Container = styled.View`
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 0 20px;
  height: calc(100vh - 57px - 120px);
  width: 100%;
`;

const Menu = styled.View`
  position: absolute;
  top: 25px;
  right: 0;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
  border-width: 1px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

interface IColumnHeaderMenuProps {
  teamId: string;
  project: Project;
  taskStatus: TaskStatus;
  openMenu: boolean;
  setOpenMenu: (value: boolean) => void;
  onClose: () => void;
}

const ColumnHeaderMenu = (props: IColumnHeaderMenuProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [showStatusDeleteModal, setShowStatusDeleteModal] = useState(false);
  const [showTaskArchiveModal, setShowTaskArchiveModal] = useState(false);

  const menuRef = useRef();
  const clickDocument = (e: any) => {
    if ((menuRef?.current as any)?.contains(e.target)) {
      (menuRef?.current as any)?.click();
      return;
    }
    props.onClose();
  };
  useEffect(() => {
    window.addEventListener('click', clickDocument);
    return () => {
      window.removeEventListener('click', clickDocument);
    };
  }, [clickDocument]);

  return (
    <>
      {props.openMenu && (
        <Menu ref={menuRef as any}>
          {!props.taskStatus.endStatus && (
            <>
              <DeleteIcon
                size={23}
                onPress={() => {
                  setShowStatusDeleteModal(true);
                  props.setOpenMenu(false);
                }}>
                <Typography variant={TypographyType.Normal}>列を削除する</Typography>
              </DeleteIcon>
            </>
          )}

          {/* {props.taskStatus.endStatus && (
            <>
              <TouchableOpacity
                onPress={() => {
                  setShowTaskArchiveModal(true);
                  props.setOpenMenu(false);
                }}
                style={{ flexDirection: 'row' }}>
                <Icon
                  iconType={'material-community-icons'}
                  name={'archive'}
                  iconStyle={{
                    fontSize: 20,
                    color: themeContext.colors.description,
                    marginRight: 5,
                  }}
                  onPress={() => {
                    setShowTaskArchiveModal(true);
                    props.setOpenMenu(false);
                  }}
                />
                <Typography variant={TypographyType.Normal}>完了したタスクをクリアする</Typography>
              </TouchableOpacity>
            </>
          )} */}
        </Menu>
      )}
      <Modal
        isShow={showStatusDeleteModal}
        title="ステータスを削除する"
        onClose={() => setShowStatusDeleteModal(false)}>
        <TaskStatusDeleteDialog
          taskStatus={props.taskStatus}
          onCancel={() => setShowStatusDeleteModal(false)}
          onComplete={() => setShowStatusDeleteModal(false)}
        />
      </Modal>
      <Modal
        isShow={showTaskArchiveModal}
        title="完了したタスクをクリアする"
        onClose={() => setShowTaskArchiveModal(false)}>
        <TaskArchiveOnBoardDialog
          taskStatus={props.taskStatus}
          onCancel={() => setShowTaskArchiveModal(false)}
          onComplete={() => setShowTaskArchiveModal(false)}
        />
      </Modal>
    </>
  );
};

interface IColumnHeaderProps {
  teamId: string;
  project: Project;
  taskStatus: TaskStatus;
  isDragColumn: boolean;
}

const ColumnHeader = React.memo((props: IColumnHeaderProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [openMenu, setOpenMenu] = useState(false);
  const [updateTaskStatus, _] = useUpdateTaskStatusMutation();

  return (
    <View
      style={
        {
          marginHorizontal: 10,
          paddingBottom: 15,
          flexDirection: 'row',
          cursor: props.isDragColumn ? 'grabbing' : 'grab',
        } as any
      }>
      {props.taskStatus.endStatus && (
        <CheckIcon size={18} on={true} containerStyle={{ marginRight: 10 }} />
      )}
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
        <View>
          <EditableText
            style={{ width: '100%' }}
            value={props.taskStatus.name}
            validate={{
              required: {
                value: true,
                message: 'ステータス名を入力してください',
              },
              maxLength: {
                value: 20,
                message: '20文字以内で入力してください',
              },
            }}
            onChange={(value) => {
              updateTaskStatus({
                variables: {
                  id: props.taskStatus.id!,
                  input: {
                    name: (value as string) || props.taskStatus.name,
                    sortNo: props.taskStatus.sortNo,
                    versionNo: props.taskStatus.versionNo,
                  },
                },
              });
            }}
            textStyle={{ fontSize: 16, maxWidth: 200 }}
          />
        </View>
        {!props.taskStatus.endStatus && (
          <View>
            <MenuIcon size={28} onPress={() => setOpenMenu(true)} />
          </View>
        )}
      </View>
      <ColumnHeaderMenu
        teamId={props.project.team?.id ?? props.teamId}
        project={props.project}
        taskStatus={props.taskStatus}
        openMenu={openMenu}
        setOpenMenu={setOpenMenu}
        onClose={() => setOpenMenu(false)}
      />
    </View>
  );
});

interface IColumnAddButtonProps {
  project: Project;
}

const ColumnAddButton = (props: IColumnAddButtonProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [showDialog, setShowDialog] = useState(false);
  return (
    <View
      style={{
        marginLeft: 5,
        width: 40,
        height: 40,
        borderRadius: 5,
        backgroundColor: themeContext.colors.separator,
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <PlusIcon size={28} onPress={() => setShowDialog(true)} />
      <Modal
        isShow={showDialog}
        title="新しいステータスを追加する"
        onClose={() => {
          setShowDialog(false);
        }}>
        <TaskStatusCreateDialog
          project={props.project}
          onComplete={(taskStatus) => setShowDialog(false)}
          onCancel={() => setShowDialog(false)}
        />
      </Modal>
    </View>
  );
};

interface TaskStatusGroup {
  taskStatus: TaskStatus;
  tasks: Array<Task>;
}

interface IListProps {
  organization: Organization;
  teamId: string;
  project: Project;
  me: Member;
  group: TaskStatusGroup;
  column: number;
  listRefs: Array<IListRefInfo>;
  width: number;
  getTaskStatusGroup: (statusColumnIndex: number) => TaskStatusGroup;
  getAllTaskStatusGroup: () => TaskStatusGroup[];
  setListRefs: (value: Array<IListRefInfo>) => void;
  setDragItem: (value: boolean) => void;
  onEndReached: () => void;
  listOuterRef: any;
}

const List = React.memo((props: IListProps) => {
  const history = useHistory();
  const [showTasksCreateLoading, setShowTasksCreateLoading] = useState(false);
  const [updateTaskSortNo, _] = useUpdateStatusOfTaskMutation();

  const onDrop = useCallback(
    async (info) => {
      const isMoveToFirst = info.endRowIndex === 0;
      const isMoveToLast =
        info.startColumnIndex === info.endColumnIndex
          ? info.endRowIndex === props.getTaskStatusGroup(info.endColumnIndex).tasks.length - 1
          : info.endRowIndex === props.getTaskStatusGroup(info.endColumnIndex).tasks.length;
      const isMoveToDown = info.endRowIndex - info.startRowIndex > 0;
      let sortNo;
      if (isMoveToFirst) {
        sortNo = new Date().getTime();
      } else if (isMoveToLast) {
        sortNo =
          info.startColumnIndex === info.endColumnIndex
            ? props.getTaskStatusGroup(info.endColumnIndex).tasks[info.endRowIndex]!
                .sortNoInTaskStatus - 1000
            : props.getTaskStatusGroup(info.endColumnIndex).tasks[info.endRowIndex - 1]!
                .sortNoInTaskStatus - 1000;
      } else {
        if (isMoveToDown && info.startColumnIndex === info.endColumnIndex) {
          const beforeTask = props.getTaskStatusGroup(info.endColumnIndex).tasks[info.endRowIndex];
          const afterTask = props.getTaskStatusGroup(info.endColumnIndex).tasks[
            info.endRowIndex + 1
          ];
          sortNo = Math.floor((beforeTask!.sortNoInTaskStatus + afterTask!.sortNoInTaskStatus) / 2);
        } else {
          const beforeTask = props.getTaskStatusGroup(info.endColumnIndex).tasks[
            info.endRowIndex - 1
          ];
          const afterTask = props.getTaskStatusGroup(info.endColumnIndex).tasks[info.endRowIndex];
          sortNo = Math.floor((beforeTask!.sortNoInTaskStatus + afterTask!.sortNoInTaskStatus) / 2);
        }
      }
      await updateTaskSortNo({
        variables: {
          id: info.item.id!,
          input: {
            taskStatusId: props.getTaskStatusGroup(info.endColumnIndex).taskStatus.id!,
            sortNoInTaskStatus: sortNo,
            versionNo: info.item!.versionNo,
          },
        },
        update: (cache, result) => {
          cache.modify({
            fields: {
              boardTasks(existingTaskRef, { readField }) {
                // 別のステータスに移動した場合には、移動元の配列から削除して、移動先の配列に追加する必要がある。
                // refetchだと一瞬描画が追いつかず、だいぶ違和感のある動きになってしまうため、キャッシュを直接編集する
                if (info.startColumnIndex === info.endColumnIndex) {
                  return existingTaskRef;
                }

                const srcTaskStatusId = props.getTaskStatusGroup(info.startColumnIndex).taskStatus
                  .id;
                const destTaskStatusId = props.getTaskStatusGroup(info.endColumnIndex).taskStatus
                  .id;

                const isSrcTaskStatus =
                  existingTaskRef.taskStatus.__ref === `TaskStatus:${srcTaskStatusId}`;
                const isDestTaskStatus =
                  existingTaskRef.taskStatus.__ref === `TaskStatus:${destTaskStatusId}`;

                if (isSrcTaskStatus) {
                  return {
                    taskStatus: existingTaskRef.taskStatus,
                    tasks: existingTaskRef.tasks.filter(
                      (ref: any) =>
                        readField('id', ref) !== `Task:${result.data?.updateStatusOfTask?.id}`
                    ),
                  };
                }

                if (isDestTaskStatus) {
                  return {
                    taskStatus: existingTaskRef.taskStatus,
                    tasks: [
                      ...existingTaskRef.tasks,
                      { __ref: `Task:${result.data?.updateStatusOfTask?.id}` },
                    ],
                  };
                }

                return existingTaskRef;
              },
            },
          });
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateStatusOfTask: Object.assign(
            {
              __typename: 'Task',
            },
            info.item,
            { sortNoInTaskStatus: sortNo }
          ) as any,
        },
      });
    },
    [props.teamId, props.getTaskStatusGroup]
  );

  const renderItem = useCallback(
    (item, index) => {
      return (
        <TaskSummaryForBoard
          organization={props.organization}
          teamId={props.project.team.id ?? props.teamId}
          task={item as Task}
        />
      );
    },
    [props.teamId, props.project]
  );

  const getKey = useCallback((task) => task!.id!.toString(), []);

  const addListRefs = useCallback(
    (info) => {
      props.listRefs.push(info);
      props.setListRefs(props.listRefs);
    },
    [props.listRefs]
  );

  const updateListRefs = useCallback(
    (info) => {
      const findIndex = props.listRefs.findIndex((ref) => ref.columnKey === info.columnKey);
      props.listRefs[findIndex] = info;
      props.setListRefs(props.listRefs);
    },
    [props.listRefs, props.setListRefs]
  );

  const onPress = useCallback(
    (task) => {
      if (props.teamId) {
        history.push(
          `/app/${props.organization.id!}/${props.teamId}/projects/${props.project
            .id!}/board/task/${task!.id}/detail/`
        );
      } else {
        history.push(
          `/app/${props.organization.id!}/my/favorite-project/${props.project.id!}/board/task/${
            task!.id
          }/detail/`
        );
      }
    },
    [props.organization.id!, props.teamId, props.project]
  );

  const onDragStart = useCallback(() => props.setDragItem(true), [props.setDragItem]);
  const onDropEnd = useCallback(() => props.setDragItem(false), [props.setDragItem]);
  return (
    <>
      <Spinner loading={showTasksCreateLoading} />
      <VirtualizedFlatList
        style={{
          height: 'calc(100% - 60px)',
        }}
        listOuterRef={props.listOuterRef}
        items={props.group!.tasks}
        column={props.column}
        columnKey={props.group!.taskStatus.id!}
        allColumns={props.getAllTaskStatusGroup().map((group) => group.tasks)}
        renderItem={renderItem}
        getKey={getKey}
        listRefs={props.listRefs}
        addListRefs={addListRefs}
        updateListRefs={updateListRefs}
        itemHeight={76}
        itemWidth={props.width}
        onPress={onPress}
        virticalDraggable={true}
        horizontalDraggable={true}
        onDragStart={onDragStart}
        onDropEnd={onDropEnd}
        onDrop={onDrop}
        listHeaderComponent={
          <TaskSummaryForBoardNewTask
            teamId={props.project.team?.id ?? props.teamId}
            project={props.project}
            taskStatus={props.group!.taskStatus}
            lastTask={null}
            me={props.me}
            setShowCreateTasksLoading={setShowTasksCreateLoading}
          />
        }
        listFooterComponent={
          props.group!.tasks.length === 0 ? (
            <></>
          ) : (
            <TaskSummaryForBoardNewTask
              teamId={props.project.team?.id ?? props.teamId}
              project={props.project}
              taskStatus={props.group!.taskStatus}
              lastTask={
                props.group!.tasks.length > 0
                  ? props.group!.tasks[props.group!.tasks.length - 1]
                  : null
              }
              me={props.me}
              setShowCreateTasksLoading={setShowTasksCreateLoading}
            />
          )
        }
        onEndReached={props.onEndReached}
        key={props.group!.taskStatus.id}
      />
    </>
  );
});

interface IColumnProps {
  organization: Organization;
  teamId: string;
  project: Project;
  me: Member;
  taskStatus: TaskStatus;
  registerTasks: (tasks: Task[]) => void;
  getTaskStatusGroup: (statusColumnIndex: number) => TaskStatusGroup;
  getAllTaskStatusGroup: () => TaskStatusGroup[];
  column: number;
  listRefs: Array<IListRefInfo>;
  width: number;
  isNotAllowedPlan: boolean;
  setListRefs: (value: Array<IListRefInfo>) => void;
}

const Column = (props: IColumnProps) => {
  const pageSize = 30;
  const [taskFilter] = useContext(TaskFilterContext);
  const [translationX, setTranslationX] = useState(new Animated.Value(0));
  const [isDragItem, setDragItem] = useState(false);
  const [isDragColumn, setDragColumn] = useState(false);
  const [dragColumnInfo, setDragColumnInfo] = useContext(ColumnDragContext);
  const listOuterRef = useRef();
  const [updateTaskStatus, _] = useUpdateTaskStatusMutation();
  const { loading, data, fetchMore } = useCustomBoardTasksQuery({
    variables: {
      projectId: props.project.id,
      taskStatusId: props.taskStatus.id,
      offset: 0,
      limit: pageSize,
    },
    fetchPolicy: 'network-only',
  });

  // Memo化しておかないと、データに変更がなくても、このコンポーネントが描画される度に、全てのTaskSummary再描画がされてしまう。
  const tasks = useMemo(() => {
    if (!data?.boardTasks) {
      return [];
    }
    return data!
      .boardTasks!.tasks!.filter((task) => {
        return (
          matchExpireFilter(task as Task, taskFilter as TaskFilter) &&
          matchTaskAssigner(task as Task, taskFilter as TaskFilter)
        );
      })
      .map((task) => {
        if (!props.isNotAllowedPlan) {
          return task;
        }
        // カンバンボードを利用不可能なプランの場合には、ダミー情報に書き換えて使えないようにする
        return Object.assign({}, task, {
          id: (-1 * Math.random()).toString(),
          title: '-',
        });
      })
      .sort((a, b) => {
        return b!.sortNoInTaskStatus - a!.sortNoInTaskStatus; // ソート番号は降順
      });
  }, [loading, data?.boardTasks, taskFilter, props.isNotAllowedPlan]) as Task[];

  props.registerTasks(tasks);

  const dudListener = useCallback(
    (
      event: NativeSyntheticEvent<
        PanGestureHandlerEventPayload & GestureHandlerStateChangeNativeEvent
      >
    ) => {
      if (event.nativeEvent.state === State.BEGAN) {
        setDragColumn(true);
        //@ts-ignore
        setDragColumnInfo({
          startColumn: props.column,
          endColumn: props.column,
          dragging: true,
        });
      }
      if (event.nativeEvent.state === State.ACTIVE) {
        const moveColumn = event.nativeEvent.translationX / props.width;
        let afterColumn = Math.round(props.column + moveColumn);
        afterColumn = Math.max(afterColumn, 0);
        afterColumn = Math.min(afterColumn, props.getAllTaskStatusGroup().length - 1);

        if ((dragColumnInfo as DragColumnInfo).endColumn != afterColumn) {
          //@ts-ignore
          setDragColumnInfo({
            startColumn: props.column,
            endColumn: afterColumn,
            dragging: true,
          });
        }
      }
      if (event.nativeEvent.state === State.END) {
        const moveColumn = event.nativeEvent.translationX / props.width;
        let afterColumn = Math.round(props.column + moveColumn);
        afterColumn = Math.max(afterColumn, 0);
        afterColumn = Math.min(afterColumn, props.getAllTaskStatusGroup().length - 1);

        setDragColumn(false);
        //@ts-ignore
        setDragColumnInfo({
          startColumn: props.column,
          endColumn: afterColumn,
          dragging: false,
        });

        if (props.column !== afterColumn) {
          const isMoveToFirst = afterColumn === 0;
          const isMoveToLast = afterColumn === props.getAllTaskStatusGroup().length - 1;
          const isMoveToDown = afterColumn - props.column > 0;

          let sortNo;
          if (isMoveToFirst) {
            sortNo = props.getTaskStatusGroup(afterColumn).taskStatus.sortNo - 1000;
          } else if (isMoveToLast) {
            sortNo = new Date().getTime();
          } else {
            if (isMoveToDown) {
              const beforeTaskStatus = props.getTaskStatusGroup(afterColumn).taskStatus;
              const afterTaskStatus = props.getTaskStatusGroup(afterColumn + 1).taskStatus;
              sortNo = Math.floor((beforeTaskStatus!.sortNo + afterTaskStatus!.sortNo) / 2);
            } else {
              const beforeTaskStatus = props.getTaskStatusGroup(afterColumn - 1).taskStatus;
              const afterTaskStatus = props.getTaskStatusGroup(afterColumn).taskStatus;
              sortNo = Math.floor((beforeTaskStatus!.sortNo + afterTaskStatus!.sortNo) / 2);
            }
          }

          updateTaskStatus({
            variables: {
              id: props.taskStatus.id!,
              input: {
                name: props.taskStatus.name,
                sortNo: sortNo,
                versionNo: props.taskStatus.versionNo,
              },
            },
            optimisticResponse: {
              __typename: 'Mutation',
              updateTaskStatus: Object.assign(
                {
                  __typename: 'TaskStatus',
                },
                props.taskStatus,
                { sortNo: sortNo }
              ),
            },
          });
        }
      }
    },
    [
      setDragColumn,
      setDragColumnInfo,
      dragColumnInfo,
      props.getAllTaskStatusGroup,
      props.getTaskStatusGroup,
    ]
  );

  useEffect(() => {
    if ((dragColumnInfo as DragColumnInfo).dragging) {
      if (
        (dragColumnInfo as DragColumnInfo).endColumn !==
        (dragColumnInfo as DragColumnInfo).startColumn
      ) {
        if (
          (dragColumnInfo as DragColumnInfo).endColumn >
            (dragColumnInfo as DragColumnInfo).startColumn &&
          (dragColumnInfo as DragColumnInfo).endColumn >= props.column &&
          (dragColumnInfo as DragColumnInfo).startColumn < props.column
        ) {
          Animated.timing(translationX, {
            toValue: -props.width,
            duration: 200,
            useNativeDriver: true,
          }).start();
        } else if (
          (dragColumnInfo as DragColumnInfo).endColumn <
            (dragColumnInfo as DragColumnInfo).startColumn &&
          (dragColumnInfo as DragColumnInfo).endColumn <= props.column &&
          (dragColumnInfo as DragColumnInfo).startColumn > props.column
        ) {
          Animated.timing(translationX, {
            toValue: props.width,
            duration: 200,
            useNativeDriver: true,
          }).start();
        } else {
          Animated.timing(translationX, {
            toValue: 0,
            duration: 200,
            useNativeDriver: true,
          }).start();
        }
      } else {
        Animated.timing(translationX, {
          toValue: 0,
          duration: 200,
          useNativeDriver: true,
        }).start();
      }
    } else {
      Animated.timing(translationX, {
        toValue: 0,
        duration: 0,
        useNativeDriver: true,
      }).start();
    }
  }, [dragColumnInfo]);

  return (
    <Animated.View
      ref={listOuterRef as any}
      style={
        {
          zIndex: isDragColumn ? 3 : 0,
          transform:
            !isDragColumn && isDragItem
              ? []
              : [
                  {
                    translateX: translationX,
                  },
                ],
        } as any
      }>
      <View
        style={{
          width: props.width,
          height:
            location.hash.indexOf('/my/favorite-project/') !== -1 ||
            location.hash.indexOf('/my/assigned-project/') !== -1
              ? 'calc(100vh - 300px)'
              : 'calc(100vh - 260px)',
          backgroundColor: '#f4f5f7',
          paddingVertical: 10,
          paddingHorizontal: 2,
          marginHorizontal: 5,
          borderRadius: 5,
        }}>
        <View
          style={{
            zIndex: 5,
          }}>
          <PanGestureHandler
            maxPointers={1}
            onGestureEvent={Animated.event([{ nativeEvent: { translationX: translationX } }], {
              listener: dudListener,
              useNativeDriver: true,
            })}
            onHandlerStateChange={(event) => {
              if (event.nativeEvent.state === State.BEGAN) {
                setTranslationX(new Animated.Value(0));
              }
              if (event.nativeEvent.state === State.ACTIVE) {
              }
              if (event.nativeEvent.state === State.END) {
                setTranslationX(new Animated.Value(0));
              }
            }}>
            <NonForwardedRefAnimatedView>
              <ColumnHeader
                taskStatus={props.taskStatus}
                project={props.project}
                teamId={props.teamId}
                isDragColumn={isDragColumn}
              />
            </NonForwardedRefAnimatedView>
          </PanGestureHandler>
        </View>
        <List
          organization={props.organization}
          teamId={props.teamId}
          project={props.project}
          me={props.me}
          group={{
            taskStatus: props.taskStatus,
            tasks: tasks,
          }}
          getTaskStatusGroup={props.getTaskStatusGroup}
          getAllTaskStatusGroup={props.getAllTaskStatusGroup}
          column={props.column}
          listRefs={props.listRefs}
          setListRefs={props.setListRefs}
          width={props.width}
          setDragItem={setDragItem}
          listOuterRef={listOuterRef}
          onEndReached={() => {
            if ((data?.boardTasks?.tasks?.length ?? 0) < pageSize) {
              return;
            }
            fetchMore({
              variables: {
                offset: data!.boardTasks!.tasks.length,
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return Object.assign({}, prev, {
                  boardTasks: {
                    taskStatus: prev.boardTasks!.taskStatus,
                    tasks: [
                      ...(prev.boardTasks?.tasks || []),
                      ...(fetchMoreResult.boardTasks?.tasks || []),
                    ],
                  },
                });
              },
            });
          }}
        />
      </View>
    </Animated.View>
  );
};

interface IProjectBoardInnerProps {
  teamId: string;
  project: Project;
  organization: Organization;
  me: Member;
}

const ProjectBoardInner = (props: IProjectBoardInnerProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [notAllowedPlan, setNotAllowedPlan] = useState(false);
  const taskStatusGroups: TaskStatusGroup[] = []; // この変数はReactのレンダリング管理対象とはしたくないため、あえてstateではなく通常の変数として扱っている
  const { loading, data } = useProjectTaskStatusQuery({
    variables: {
      projectId: props.project.id!,
    },
    fetchPolicy: 'network-only',
  });
  const [listRefs, setListRefs] = useState<Array<IListRefInfo>>([]);

  const taskStatuses = useMemo(() => {
    if (loading || !data?.projectTaskStatus) {
      return [];
    }
    return data!.projectTaskStatus!.slice().sort((a, b) => {
      return a!.sortNo - b!.sortNo; // ソート番号は昇順
    });
  }, [loading, data?.projectTaskStatus]) as TaskStatus[];

  useEffect(() => {
    try {
      setInterval(() => {
        if (props.organization.plan.code === Plan.Basic) {
          setNotAllowedPlan(true);
        }
      }, 1000);
    } catch (_e) {
      // NOP
    }
  }, [props.organization]);

  if (loading) {
    return <></>;
  }

  return (
    <PlanNotAllowedView isNotAllowedPlan={notAllowedPlan}>
      <Container>
        {/* <View
        style={{
          flexDirection: 'row',
          justifyContent: 'flex-end',
          position: 'fixed',
          right: 65,
          height: 85,
        }}>
        <Form style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Typography variant={TypographyType.Description} style={{ marginRight: 10 }}>
            グループ分け
          </Typography>
          <Input
            name={'group'}
            type={'picker'}
            containerStyle={{ width: 120 }}
            pickerItems={[
              {
                label: 'なし',
                value: '',
              },
              {
                label: '担当者',
                value: '',
              },
            ]}
            onChange={(value) => {}}
          />
        </Form>
      </View> */}
        <View
          style={{
            flexDirection: 'row',
            position: 'absolute',
            top: 40,
            // position: 'absolute', top: 85
          }}>
          <ColumnDragContextProvider>
            <GlobalDragContextProvider>
              <GlobalDragColumnContextProvider>
                {taskStatuses.map((taskStatus, i) => {
                  return (
                    <Column
                      organization={props.organization}
                      teamId={props.teamId}
                      project={props.project}
                      me={props.me}
                      taskStatus={taskStatus}
                      registerTasks={(tasks) => {
                        const group = taskStatusGroups.find(
                          (group) => group.taskStatus.id === taskStatus.id
                        );
                        if (group) {
                          const findIndex = taskStatusGroups.findIndex(
                            (g) => g.taskStatus.id === taskStatus.id
                          );
                          taskStatusGroups[findIndex].tasks = tasks;
                        } else {
                          taskStatusGroups.push({
                            taskStatus: taskStatus,
                            tasks: tasks,
                          });
                        }
                      }}
                      getAllTaskStatusGroup={() => taskStatusGroups}
                      getTaskStatusGroup={(statusColumnIndex) => {
                        const specifiedTaskStatus = taskStatuses[statusColumnIndex];
                        return taskStatusGroups.find(
                          (group) => group.taskStatus.id === specifiedTaskStatus.id
                        )!;
                      }}
                      column={i}
                      listRefs={listRefs}
                      setListRefs={setListRefs}
                      width={290}
                      isNotAllowedPlan={notAllowedPlan}
                      key={i}
                    />
                  );
                })}
              </GlobalDragColumnContextProvider>
            </GlobalDragContextProvider>
          </ColumnDragContextProvider>
          <ColumnAddButton project={props.project} />
        </View>
      </Container>
    </PlanNotAllowedView>
  );
};

interface IProjectBoardParam {
  teamId: string;
  projectId: string;
  organizationId: string;
}

const ProjectBoard = () => {
  const params = useParams<IProjectBoardParam>();
  const { loading, data, error } = useProjectQuery({
    variables: {
      id: params.projectId,
    },
  });
  const fetchOrganization = useOrganizationQuery({
    variables: {
      id: params.organizationId,
    },
  });
  const { loading: meLoading, data: meData } = useMeQuery();

  if (
    loading ||
    !data?.project ||
    fetchOrganization.loading ||
    !fetchOrganization.data ||
    meLoading ||
    !meData?.me
  ) {
    return <></>;
  }

  return (
    <ProjectBoardInner
      organization={fetchOrganization.data!.organization!}
      teamId={params.teamId}
      project={data!.project!}
      me={meData.me}
    />
  );
};

interface DragColumnInfo {
  startColumn: number;
  endColumn: number;
  dragging: boolean;
}

export const ColumnDragContext = createContext([
  { startColumn: 0, endColumn: 0, dragging: false },
  (value: DragColumnInfo) => {},
]);

export const ColumnDragContextProvider = (props: any) => {
  const [draggingPosIndex, setDraggingPosIndex] = useState<DragColumnInfo>({
    startColumn: 0,
    endColumn: 0,
    dragging: false,
  });
  return (
    <ColumnDragContext.Provider value={[draggingPosIndex as any, setDraggingPosIndex]}>
      {props.children}
    </ColumnDragContext.Provider>
  );
};

export default React.memo(ProjectBoard);
