import React, { useCallback, useContext, useEffect, useState } from 'react';
import { View, Text, ActivityIndicator } from 'react-native';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../../../../../theme';
import Typography, { TypographyType } from '../../../../../../../presentational/atoms/typography';
import {
  GraphDataTask,
  GraphDataTaskItemType,
  GraphDataTaskSortKey,
  Organization,
  ReportType,
  SortOrder,
  useGraphDataTaskListQuery,
} from '../../../../../../../../graphql/api/API';
import { LoginUserContext } from '../../../../../../../../modules/auth/LoginUserContext';
import OrganizationUtil from '../../../../../../../../util/OrganizationUtil';
import TimeUtil from '../../../../../../../../util/TimeUtil';
import EditIcon from '../../../../../../../presentational/molecules/image-icon/edit';
import WorkingHistoryIcon from '../../../../../../../presentational/molecules/image-icon/working-history';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router';
import UrlUtil from '../../../../../../../../util/UrlUtil';
import SortHeaderItem from '../../../../../../../presentational/molecules/sort-header-item';
import VirtualizedFlatList, {
  GlobalDragContextProvider,
} from '../../../../../../../presentational/atoms/list2/virtualized-flat-list';
import moment from 'moment-timezone';
import useDimensions from 'use-dimensions';
import queryString from 'query-string';
import TaskDateGraph from '../../../../../organisms/bar-graph/task-date-graph';
import TaskBarGraph from '../../../../../organisms/bar-graph/task-bar-graph';
import TaskSunburstGraph from '../../../../../organisms/sunburst-graph/task';
import WorkingHistoriesModal from './working-historis-modal';

const Container = styled.View`
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  margin-right: 10px;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.4s;
  z-index: 100;
  border-width: 1px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
`;

const Main = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  padding-bottom: 3px;
  overflow: hidden;
`;

interface IProps {
  task: GraphDataTask;
  organization: Organization;
  memberId?: string | null;
  teamId?: string | null;
  start: moment.Moment
  end: moment.Moment
}

export const GraphTaskSummary = (props: IProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser] = useContext(LoginUserContext);
  const history = useHistory();
  const [showModal, setShowModal] = useState(false)

  const onPress = useCallback(() => {
    const data = props.task;
    if (data.itemType === GraphDataTaskItemType.Task) {
      history.push(UrlUtil.createTaskDetailUrlById(props.task.taskId));
    } else if (data.itemType === GraphDataTaskItemType.CalendarEvent) {
      history.push(UrlUtil.createCalendarEventDetailUrlById(data.taskId));
    }
  }, [loginUser, history, props.task]);

  const onShowWorkingHistoryModal = useCallback(() => {
    setShowModal(true)
  }, []);

  useEffect(() => {
    setShowModal(false)
  }, [])

  return (
    <Container>
      <Main>
        <View style={{ flexDirection: 'row', flex: 1 }}>
          {OrganizationUtil.isTeamPlan(props.organization) && (
            <View
              style={{
                flex: 1,
                minWidth: 140,
                marginRight: 10,
                justifyContent: 'flex-start',
              }}>
              <Typography
                variant={TypographyType.Normal}
                ellipsis={true}
                style={{
                  fontSize: 12,
                  paddingHorizontal: 5,
                  textAlign: 'left',
                }}>
                {props.task.teamName}
              </Typography>
            </View>
          )}
          <View
            style={{
              flex: 1,
              minWidth: 140,
              marginRight: 10,
              justifyContent: 'flex-start',
            }}>
            <Typography
              variant={TypographyType.Normal}
              ellipsis={true}
              style={{
                fontSize: 12,
                paddingHorizontal: 5,
                textAlign: 'left',
              }}>
              {props.task.projectName}
            </Typography>
          </View>
          <View
            style={{
              flex: 1,
              minWidth: 140,
              marginRight: 10,
              justifyContent: 'flex-start',
            }}>
            <Typography
              variant={TypographyType.Normal}
              ellipsis={true}
              style={{
                fontSize: 12,
                paddingHorizontal: 5,
                textAlign: 'left',
              }}>
              {props.task.clientName ?? '-'}
            </Typography>
          </View>
          <View
            style={{
              flex: 1,
              minWidth: 140,
              marginRight: 10,
              justifyContent: 'flex-start',
            }}>
            <Typography
              variant={TypographyType.Normal}
              ellipsis={true}
              tooltipWhenEllipsis={true}
              tooltipId={`task-${props.task.taskId}`}
              style={{
                fontSize: 12,
                textAlign: 'left',
              }}>
              {props.task.taskTitle}
            </Typography>
          </View>
          <View
            style={{
              width: 80,
              marginRight: 10,
              justifyContent: 'flex-end',
            }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontSize: 12,
                textAlign: 'right',
              }}>
              {TimeUtil.formatForTask(props.task.workingTimeSec)}
            </Typography>
          </View>
          {OrganizationUtil.isTeamPlan(props.organization) && (
            <View
              style={{
                width: 80,
                marginRight: 10,
                justifyContent: 'flex-end',
              }}>
              <Typography
                variant={TypographyType.Normal}
                ellipsis={true}
                style={{
                  fontSize: 12,
                  textAlign: 'right',
                }}>
                {props.task.laborCost
                  ? `${new Intl.NumberFormat('ja-JP', {
                      style: 'currency',
                      currency: 'JPY',
                    }).format(props.task.laborCost)}`
                  : '-'}
              </Typography>
            </View>
          )}
          <View
            style={{
              width: 50,
              marginRight: 10,
              justifyContent: 'flex-end',
            }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontSize: 12,
                textAlign: 'right',
              }}>
              {props.task.percent}%
            </Typography>
          </View>
          <View
            style={{
              width: 30,
              marginRight: 5,
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <WorkingHistoryIcon size={24} onPress={onShowWorkingHistoryModal} />
          </View>
          <View
            style={{
              width: 30,
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <EditIcon size={24} onPress={onPress} />
          </View>
        </View>
      </Main>
      <WorkingHistoriesModal graphTask={props.task} start={props.start} end={props.end} targetTeamId={props.teamId} targetMemberId={props.memberId} showModal={showModal} onCloseModal={() => setShowModal(false)} />
    </Container>
  );
};

interface ITaskHeaderProps {
  organization: Organization;
  graphType: 'date' | 'bar' | 'sunburst';
  searchCondition: any;
  url: string;
}

export const TaskHeader = (props: ITaskHeaderProps) => {
  const history = useHistory();
  const themeContext: IThemePart = useContext(ThemeContext);
  return (
    <View
      style={{
        flexDirection: 'row',
        marginHorizontal: 10,
        paddingHorizontal: 10,
        backgroundColor: themeContext.colors.subHeader,
      }}>
      {OrganizationUtil.isTeamPlan(props.organization) && (
        <SortHeaderItem
          organization={props.organization}
          searchCondition={props.searchCondition}
          sortKey={GraphDataTaskSortKey.TeamName}
          style={{
            flex: 1,
            minWidth: 140,
            marginRight: 10,
            justifyContent: 'flex-start',
          }}
          caretStyle={'white'}
          url={props.url}>
          <Typography
            variant={TypographyType.Normal}
            style={{
              fontSize: 12,
              paddingHorizontal: 5,
              textAlign: 'left',
              color: themeContext.colors.baseColor,
            }}>
            チーム名
          </Typography>
        </SortHeaderItem>
      )}
      <SortHeaderItem
        organization={props.organization}
        searchCondition={props.searchCondition}
        sortKey={GraphDataTaskSortKey.ProjectName}
        style={{
          flex: 1,
          minWidth: 140,
          marginRight: 10,
          justifyContent: 'flex-start',
        }}
        caretStyle={'white'}
        url={props.url}>
        <Typography
          variant={TypographyType.Normal}
          style={{
            fontSize: 12,
            paddingHorizontal: 5,
            textAlign: 'left',
            color: themeContext.colors.baseColor,
          }}>
          プロジェクト名
        </Typography>
      </SortHeaderItem>
      <SortHeaderItem
        organization={props.organization}
        searchCondition={props.searchCondition}
        sortKey={GraphDataTaskSortKey.ClientName}
        style={{
          flex: 1,
          minWidth: 140,
          marginRight: 10,
          justifyContent: 'flex-start',
        }}
        caretStyle={'white'}
        url={props.url}>
        <Typography
          variant={TypographyType.Normal}
          style={{
            fontSize: 12,
            paddingHorizontal: 5,
            textAlign: 'left',
            color: themeContext.colors.baseColor,
          }}>
          取引先名
        </Typography>
      </SortHeaderItem>
      <SortHeaderItem
        organization={props.organization}
        searchCondition={props.searchCondition}
        sortKey={GraphDataTaskSortKey.TaskTitle}
        style={{
          flex: 1,
          minWidth: 140,
          marginRight: 10,
          justifyContent: 'flex-start',
        }}
        caretStyle={'white'}
        url={props.url}>
        <Typography
          variant={TypographyType.Normal}
          style={{
            fontSize: 12,
            paddingHorizontal: 5,
            textAlign: 'left',
            color: themeContext.colors.baseColor,
          }}>
          タスク
        </Typography>
      </SortHeaderItem>
      <SortHeaderItem
        organization={props.organization}
        searchCondition={props.searchCondition}
        sortKey={GraphDataTaskSortKey.WorkingTimeSec}
        style={{
          width: 80,
          marginRight: 10,
          justifyContent: 'flex-end',
        }}
        caretStyle={'white'}
        url={props.url}>
        <Typography
          variant={TypographyType.Normal}
          style={{
            fontSize: 12,
            textAlign: 'right',
            color: themeContext.colors.baseColor,
          }}>
          作業時間
        </Typography>
      </SortHeaderItem>
      {OrganizationUtil.isTeamPlan(props.organization) && (
        <SortHeaderItem
          organization={props.organization}
          searchCondition={props.searchCondition}
          sortKey={GraphDataTaskSortKey.LaborCost}
          style={{
            width: 80,
            marginRight: 10,
            justifyContent: 'flex-end',
          }}
          caretStyle={'white'}
          url={props.url}>
          <Typography
            variant={TypographyType.Normal}
            style={{
              fontSize: 12,
              textAlign: 'right',
              color: themeContext.colors.baseColor,
            }}>
            人件費
          </Typography>
        </SortHeaderItem>
      )}
      <View
        style={{
          width: 50,
          marginRight: 10,
          justifyContent: 'flex-end',
        }}>
        <Typography
          variant={TypographyType.Normal}
          style={{
            fontSize: 12,
            textAlign: 'right',
            color: themeContext.colors.baseColor,
          }}>
          割合
        </Typography>
      </View>
      <View
        style={{
          width: 30,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      />
      <View
        style={{
          width: 30,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      />
    </View>
  );
};

interface ITaskListProps {
  reportType: ReportType;
  organization: Organization;
  startDateTime: moment.Moment;
  endDateTime: moment.Moment;
  memberId?: string | null;
  teamId?: string | null;
  teamIds: string[];
  projectIds: string[];
  clientIds: string[];
  memberIds: string[];
  graphPath: string;
  graphType: 'date' | 'bar' | 'sunburst';
  url: string;
  setLoadData: (value: boolean) => void;
}

export const TaskList = (props: ITaskListProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const pageSize = 50;
  const [loginUser] = useContext(LoginUserContext);
  const { window: win } = useDimensions();
  const { search } = useLocation();
  const searchCondition = queryString.parse(search);
  const [loadingMore, setLoadingMore] = useState(false);
  const [isLoadListData, setLoadListData] = useState(false);

  const { data, loading, fetchMore } = useGraphDataTaskListQuery({
    variables: {
      input: {
        reportType: props.reportType,
        organizationId: loginUser!.organizationId,
        memberId: props.memberId,
        teamId: props.teamId,
        start: moment(props.startDateTime).startOf('day').toISOString(),
        end: moment(props.endDateTime).endOf('day').toISOString(),
        teamIds: props.teamIds,
        projectIds: props.projectIds,
        clientIds: props.clientIds,
        memberIds: props.memberIds,
        timeZoneOffset: moment().tz(moment.tz.guess()).format('Z'),
        sortKey:
          (searchCondition?.sortKey as GraphDataTaskSortKey) ?? GraphDataTaskSortKey.TeamName,
        sortOrder: (searchCondition?.sortOrder as SortOrder) ?? SortOrder.Desc,
      },
      offset: 0,
      limit: pageSize,
    },
    fetchPolicy: 'network-only',
  });

  const renderItem = useCallback(
    (item, index) => <GraphTaskSummary organization={props.organization} task={item} teamId={props.teamId} memberId={props.memberId} start={props.startDateTime} end={props.endDateTime} />,
    [props.organization, props.startDateTime, props.endDateTime, props.teamId, props.memberId]
  );

  const getKey = useCallback((data: any) => data!.taskId!.toString(), []);

  const onEndReached = async () => {
    if ((data?.graphDataTaskList?.length ?? 0) < pageSize) {
      return;
    }
    setLoadingMore(true);
    await fetchMore({
      variables: {
        offset: data!.graphDataTaskList!.length || 0,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          graphDataTaskList: [
            ...(prev.graphDataTaskList || []),
            ...(fetchMoreResult.graphDataTaskList || []),
          ],
        });
      },
    });
    setTimeout(() => {
      setLoadingMore(false);
    }, 1000);
  };

  const graphHeight = Math.max(Math.min(win.height / 3, 500), 200);

  useEffect(() => {
    if (loading) {
      setLoadListData(false);
    } else {
      if (data?.graphDataTaskList) {
        setLoadListData(true);
      }
    }
  }, [data?.graphDataTaskList, loading]);

  return (
    <>
      <View
        style={{
          minHeight: Math.max(Math.min(win.height / 3, 500), 200),
          paddingTop: 10,
          paddingBottom: 10,
        }}>
        {isLoadListData && (
          <Switch>
            <Route
              path={`${props.graphPath}/graph-task/date/`}
              render={(routeProps) => (
                <TaskDateGraph
                  reportType={props.reportType}
                  organization={props.organization}
                  startDateTime={props.startDateTime}
                  endDateTime={props.endDateTime}
                  teamIds={props.teamIds}
                  projectIds={props.projectIds}
                  clientIds={props.clientIds}
                  memberIds={props.memberIds}
                  targetMemberId={props.memberId}
                  teamId={props.teamId}
                  setLoadData={props.setLoadData}
                />
              )}
            />
            <Route
              path={`${props.graphPath}/graph-task/bar/`}
              render={(routeProps) => (
                <TaskBarGraph
                  reportType={props.reportType}
                  organization={props.organization}
                  startDateTime={props.startDateTime}
                  endDateTime={props.endDateTime}
                  teamIds={props.teamIds}
                  projectIds={props.projectIds}
                  clientIds={props.clientIds}
                  memberIds={props.memberIds}
                  targetMemberId={props.memberId}
                  teamId={props.teamId}
                  setLoadData={props.setLoadData}
                />
              )}
            />
            <Route
              path={`${props.graphPath}/graph-task/sunburst/`}
              render={(routeProps) => (
                <TaskSunburstGraph
                  reportType={props.reportType}
                  organization={props.organization}
                  startDateTime={props.startDateTime}
                  endDateTime={props.endDateTime}
                  teamIds={props.teamIds}
                  projectIds={props.projectIds}
                  clientIds={props.clientIds}
                  memberIds={props.memberIds}
                  targetMemberId={props.memberId}
                  teamId={props.teamId}
                  setLoadData={props.setLoadData}
                />
              )}
            />
            <Redirect to={props.url} />
          </Switch>
        )}
      </View>
      <View style={{ overflowX: 'scroll' } as any}>
        <GlobalDragContextProvider>
          <VirtualizedFlatList
            style={{
              height: `calc(100vh - ${graphHeight}px - 240px`,
              minWidth: 800,
            }}
            loading={loading}
            items={data?.graphDataTaskList ?? []}
            renderItem={renderItem}
            headerElement={
              <TaskHeader
                organization={props.organization}
                graphType={props.graphType}
                searchCondition={searchCondition}
                url={props.url}
              />
            }
            afterElement={
              <>
                {loadingMore && (
                  <ActivityIndicator size={'small'} color={themeContext.colors.primary} />
                )}
              </>
            }
            getKey={getKey}
            itemHeight={40}
            isDraggable={false}
            onEndReached={onEndReached}
          />
        </GlobalDragContextProvider>
      </View>
    </>
  );
};
