import React, { useContext, useEffect, useState } from 'react';
import { View } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import {
  CalendarWorkingScheduleDocument,
  LatestWorkingHistoryDocument,
  Plan,
  type Project,
  ProjectCompleteFilter,
  ProjectSortKey,
  ProjectTasksDocument,
  ProjectWorkingTimeSecDocument,
  SortOrder,
  type Task,
  TaskDocument,
  TaskSortKey,
  TaskWorkingScheduleSummaryGroupByAssineeDocument,
  type Team,
  WorkingHistory,
  type WorkingSchedule,
  WorkingScheduleForSummaryDocument,
  useMoveWorkingHistoryMutation,
  useMoveWorkingScheduleMutation,
  useOrganizationQuery,
  useOrganizationTeamsQuery,
  useProjectTasksForSelectorQuery,
  useProjectTasksQuery,
  useTeamProjectsForSelectorQuery,
  useTeamProjectsQuery,
} from '../../../../../graphql/api/API';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import Button from '../../../../presentational/atoms/button';
import Form from '../../../../presentational/atoms/form';
import Input from '../../../../presentational/atoms/input';
import Modal from '../../../../presentational/molecules/modal';
import { IStyleTheme, type IThemePart } from '../../../../theme';

interface IMoveWorkingScheduleModalProps {
  workingSchedule: WorkingSchedule;
  showModal: boolean;
  onMove?: () => void
  onCloseModal: () => void;
}

const MoveWorkingScheduleModal = (props: IMoveWorkingScheduleModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, __] = useContext(LoginUserContext);
  const [team, setTeam] = useState<Team | null>(null);
  const [teamId, setTeamId] = useState<string | null>(null);
  const [project, setProject] = useState<Project | null>(null);
  const [projectId, setProjectId] = useState<string | null>(null);
  const [task, setTask] = useState<Task | null>(null);
  const [taskId, setTaskId] = useState<string | null>(null);
  const { data: organizationData, loading: organizationLoading } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId,
    },
  });

  const { data: teamsData, loading: teamsLoading } = useOrganizationTeamsQuery({
    variables: {
      organizationId: loginUser!.organizationId,
    },
  });
  const { data: projectsData, loading: projectsLoading } = useTeamProjectsForSelectorQuery({
    variables: {
      teamId:
        organizationData?.organization?.plan.code === Plan.Basic ||
        organizationData?.organization?.plan.code === Plan.Professional
          ? teamsData?.organizationTeams?.[0]?.id || ''
          : teamId || '',
      input: {
        sortKey: ProjectSortKey.ProjectName,
        sortOrder: SortOrder.Asc,
        completeCondition: ProjectCompleteFilter.Incomplete,
      },
    },
    skip: !teamId,
    fetchPolicy: 'network-only',
  });
  const { data: tasksData, loading: tasksLoading } = useProjectTasksForSelectorQuery({
    variables: {
      projectId: projectId || '',
      input: {
        sortKey: TaskSortKey.Title,
        sortOrder: SortOrder.Asc,
      },
    },
    skip: !projectId,
    fetchPolicy: 'network-only',
  });
  const [moveWokingSchedule, _] = useMoveWorkingScheduleMutation({
    variables: {
      id: props.workingSchedule.id!,
      input: {
        taskId: taskId || '',
        versionNo: props.workingSchedule.versionNo,
      },
    },
    refetchQueries: props.workingSchedule.task
      ? [
          {
            query: TaskWorkingScheduleSummaryGroupByAssineeDocument,
            variables: {
              teamId: props.workingSchedule.task.project.team.id,
              taskId: props.workingSchedule.task.id,
            },
          },
          {
            query: TaskDocument,
            variables: {
              id: props.workingSchedule.task.id,
            },
          },
          {
            query: CalendarWorkingScheduleDocument,
            variables: {
              id: props.workingSchedule.id,
            },
          },
          {
            query: WorkingScheduleForSummaryDocument,
            variables: {
              id: props.workingSchedule.id!,
            },
          },
        ]
      : [
        {
          query: CalendarWorkingScheduleDocument,
          variables: {
            id: props.workingSchedule.id,
          },
        },
        {
          query: WorkingScheduleForSummaryDocument,
          variables: {
            id: props.workingSchedule.id!,
          },
        },
      ],
  });

  useEffect(() => {
    if (teamsData?.organizationTeams) {
      setTeam(teamsData!.organizationTeams[0]);
      setTeamId(teamsData!.organizationTeams[0]?.id!);
    }
  }, [teamsData?.organizationTeams]);

  if (organizationLoading) {
    return <></>;
  }

  return (
    <Modal
      title={'作業予定を他のタスクに移動する'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <Form>
        <>
          {(organizationData!.organization!.plan.code === Plan.Business ||
            organizationData!.organization!.plan.code === Plan.Enterprise) && (
            <Input
              name={'team'}
              label={'チーム'}
              type={'picker'}
              isSearchable={true}
              pickerItems={teamsData?.organizationTeams?.map((team) => {
                return {
                  label: team!.name,
                  value: team!.id!,
                };
              })}
              initialValue={
                (teamsData?.organizationTeams as Team[])
                  ? ((teamsData?.organizationTeams as Team[])[0].id as string)
                  : ''
              }
              onChange={(value) => {
                const targets = teamsData?.organizationTeams?.filter((team) => team!.id! === value);
                if (targets && targets.length > 0) {
                  setTeam(targets[0]);
                  setTeamId(targets[0]?.id!);
                }
              }}
              validate={{
                required: {
                  value: true,
                  message: 'チームを選択してください',
                },
              }}
            />
          )}
          <Input
            name={'project'}
            label={'プロジェクト'}
            type={'picker'}
            isSearchable={true}
            pickerItems={projectsData?.teamProjectsForSelector
              ?.filter((project) => project!.complete === false)
              .map((project) => {
                return {
                  label: project!.name,
                  value: project!.id!,
                };
              })}
            onChange={(value) => {
              const targets = projectsData?.teamProjectsForSelector?.filter(
                (project) => project!.id! === value
              );
              if (targets && targets.length > 0) {
                setProjectId(targets[0]?.id!);
              }
            }}
            validate={{
              required: {
                value: true,
                message: 'プロジェクトを選択してください',
              },
            }}
          />
          <Input
            name={'task'}
            label={'タスク'}
            type={'picker'}
            isSearchable={true}
            pickerItems={tasksData?.projectTasksForSelector
              ?.filter((task) => task!.completeDateTime === null)
              .map((task) => {
                return {
                  label: task!.title,
                  value: task!.id!,
                };
              })}
            onChange={(value) => {
              const targets = tasksData?.projectTasksForSelector?.filter((task) => task!.id! === value);
              if (targets && targets.length > 0) {
                setTaskId(targets[0]?.id!);
              }
            }}
            validate={{
              required: {
                value: true,
                message: 'タスクを選択してください',
              },
            }}
          />
        </>
        <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
          <Button
            text={'移動する'}
            style={{ minWidth: 100, marginRight: 10, marginVertical: 10 }}
            onPress={async () => {
              await moveWokingSchedule();
              if(props.onMove){
                props.onMove();
              }
            }}
          />
          <Button
            text={'キャンセル'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.primary }}
            disableValidate={true}
            onPress={() => {
              props.onCloseModal();
            }}
          />
        </View>
      </Form>
    </Modal>
  );
};

export default React.memo(MoveWorkingScheduleModal);
