import React from 'react';
import { Image, Platform } from 'react-native';
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';
import iconImage from '../../../../../base64Images/working-schedule';

interface IWorkingScheduleIconProps {
  size: number;
  onPress?: () => void;
  disabled?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
}

const WorkingScheduleIcon = (props: IWorkingScheduleIconProps) => {
  return (
    <TouchableOpacity
      disabled={props.disabled}
      onPress={props.onPress}
      style={[{ justifyContent: 'center', alignItems: 'center' }, props.containerStyle]}>
      <Image
        source={{ uri: iconImage }}
        resizeMode="contain"
        style={[
          {
            height: props.size,
            width: props.size,
            opacity: 0.5,
            shadowOffset: {
              width: 1,
              height: 1,
            },
            shadowOpacity: 0.1,
          } as any,
          Platform.OS === 'web' ? { elevation: 2, cursor: 'pointer' } : {},
        ]}
      />
    </TouchableOpacity>
  );
};

export default WorkingScheduleIcon;
