import queryString from 'query-string';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ScrollView } from 'react-native-gesture-handler';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';
import styled, { ThemeContext } from 'styled-components/native';
import useDimensions from 'use-dimensions';
import {
  type Task,
  TaskAttachmentFilesDocument,
  useCreateAttachementFileForTaskMutation,
  useCreateAttachementFileUploadUrlMutation,
  useMyGoogleAccountQuery,
  useTaskQuery,
} from '../../../../../graphql/api/API';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import CustomScrollView from '../../../../presentational/atoms/custom-scroll-view';
import Icon from '../../../../presentational/atoms/icon';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import UploadIcon from '../../../../presentational/molecules/image-icon/upload';
import type { IStyleTheme, IThemePart } from '../../../../theme';
import TaskDetailCalendarInfo from './calendar';
import TaskDetailCommon from './common';
import TaskDetailCommonLoading from './common-loading';
import TaskHistoryView from './history';
import TaskDetailLoading from './loading';
import TaskRemainderView from './remainder';
import TaskDetailStandardInfo from './standard-info';
import TaskDetailWorkingHistory from './working-history';
import TaskDetailWorkingSchedule from './working-schedule';

interface IContainerProps extends IStyleTheme {
  isOpen: boolean;
  windowWidth: number;
  isOpenRightSideBar: boolean;
}

const Container = styled.View<IContainerProps>`
  height: calc(100vh - 57px);
  right: ${(props: IContainerProps) =>
    props.windowWidth < 1260 ? '-490px' : `-${Math.max(props.windowWidth / 2.2, 540) + 10}px`};
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.4s;
  width: ${(props: IContainerProps) =>
    props.isOpen
      ? `${Math.max((props.windowWidth - (props.isOpenRightSideBar ? 500 : 0)) / 2.2, 540)}px`
      : '1px'}; // Note: 閉じている時に幅を 1px ではなく、0pxにすると、なぜかSafariは幅を300pxぐらい取る挙動になってしまう。それによって画面右側300pxぐらいに透明な状態のエリアがかぶさってしまい、画面操作を阻害してしまう。Safari対応のために幅を1pxに指定するようにしている。
  transform: ${(props: IContainerProps) =>
    `translateX(${props.isOpen ? `-${Math.max(props.windowWidth / 2.2, 540)}px` : '0'})`};
  background-color: ${(props: IContainerProps) => props.theme.colors.baseColor};
  overflow: hidden;
`;

interface IContainerInnerProps extends IStyleTheme {
  isShow: boolean;
}

const ContainerInner = styled.View<IContainerInnerProps>``;

interface ITaskDetailInnerProps {
  task: Task | null;
  commentId: string | null;
  loading: boolean;
  showCalendarMenu: boolean;
  isOpenRightSideBar: boolean;
}

const TaskDetailInner = (props: ITaskDetailInnerProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const { pathname } = useLocation();
  const [loginUser, _] = useContext(LoginUserContext);
  const dimensions = useDimensions();
  const windowWidth = dimensions.window.width;
  const ref = useRef();
  const [isDragOver, setDragOver] = useState(false);

  const [createAttachmentFileUploadUrl, __] = useCreateAttachementFileUploadUrlMutation();
  const [createAttachemntFile, ___] = useCreateAttachementFileForTaskMutation();

  const changeColorWhenFocusComment = window.location.hash.indexOf('forcusComment=true') !== -1;
  if (props.loading) {
    return (
      <Container
        isOpen={true}
        windowWidth={windowWidth}
        isOpenRightSideBar={props.isOpenRightSideBar}>
        <CustomScrollView scrollViewRef={ref} style={{ height: 'calc(100vh - 57px)' }}>
          <ContainerInner isShow={true}>
            <TaskDetailCommonLoading>
              <TaskDetailLoading />
            </TaskDetailCommonLoading>
          </ContainerInner>
        </CustomScrollView>
      </Container>
    );
  }
  if (props.task) {
    if (/.*\/task\/(.+)\/detail\//.test(pathname)) {
      return (
        <Container
          isOpen={true}
          windowWidth={windowWidth}
          isOpenRightSideBar={props.isOpenRightSideBar}>
          <div
            onDragOver={(e) => {
              e.stopPropagation();
              e.preventDefault();
              if (!isDragOver) {
                setDragOver(true);
              }
            }}>
            <CustomScrollView scrollViewRef={ref} style={{ height: 'calc(100vh - 57px)' }}>
              <ContainerInner isShow={true}>
                <TaskDetailCommon
                  organizationId={loginUser?.organizationId!}
                  task={props.task}
                  showCalendarMenu={props.showCalendarMenu}>
                  <TaskDetailStandardInfo
                    organizationId={loginUser?.organizationId!}
                    task={props.task}
                    commentId={props.commentId}
                    changeColor={changeColorWhenFocusComment}
                    parentScrollViewRef={ref}
                  />
                </TaskDetailCommon>
              </ContainerInner>
            </CustomScrollView>
            {isDragOver && (
              <div
                onDragLeave={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  if (isDragOver) {
                    setDragOver(false);
                  }
                }}
                onDrop={async (e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setDragOver(false);

                  let isSuccess = true;
                  if (Array.from(e.dataTransfer.files).length === 0) {
                    return;
                  }
                  await Promise.all(
                    Array.from(e.dataTransfer.files).map(async (file) => {
                      const uploadUrlResult = await createAttachmentFileUploadUrl({
                        variables: {
                          input: {
                            fileName: file.name,
                            sizeByte: file.size,
                            type: file.type,
                          },
                        },
                      });

                      await fetch(uploadUrlResult.data!.createAttachementFileUploadUrl!.uploadUrl, {
                        method: 'PUT',
                        body: file,
                      }).then((response) => {
                        if (!response.ok) {
                          isSuccess = false;
                        }
                      });

                      const attachmentFileResult = await createAttachemntFile({
                        variables: {
                          input: {
                            taskId: props.task?.id || '',
                            fileName:
                              uploadUrlResult.data!.createAttachementFileUploadUrl!.fileName,
                            sizeByte:
                              uploadUrlResult.data!.createAttachementFileUploadUrl!.sizeByte,
                            type: uploadUrlResult.data!.createAttachementFileUploadUrl!.type,
                            key: uploadUrlResult.data!.createAttachementFileUploadUrl!.key,
                          },
                        },
                        refetchQueries: [
                          {
                            query: TaskAttachmentFilesDocument,
                            variables: {
                              taskId: props.task?.id || '',
                            },
                          },
                        ],
                      });
                      if (attachmentFileResult.errors) {
                        isSuccess = false;
                      }
                    })
                  );

                  if (isSuccess) {
                    toast('ファイルアップロードが完了しました', {
                      type: 'success',
                      position: 'bottom-right',
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  } else {
                    toast('ファイルアップロードに失敗しました', {
                      type: 'error',
                      position: 'bottom-right',
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  }
                }}
                style={{
                  position: 'absolute',
                  display: isDragOver ? 'flex' : 'none',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: '#FFFFFF',
                  opacity: isDragOver ? 0.9 : 0,
                }}>
                <div
                  style={{
                    position: 'absolute',
                    top: 10,
                    left: 10,
                    right: 10,
                    bottom: 10,
                    borderWidth: 5,
                    borderColor: themeContext.colors.primary,
                    borderStyle: 'dotted',
                    display: 'flex',
                  }}>
                  <UploadIcon
                    size={130}
                    containerStyle={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
                    onPress={() => {
                      if (isDragOver) {
                        setDragOver(false);
                      }
                    }}>
                    <Typography
                      variant={TypographyType.Description}
                      style={{
                        fontSize: 26,
                        textAlign: 'center',
                        marginTop: 20,
                        color: themeContext.colors.primary,
                      }}>
                      ファイルを添付する
                    </Typography>
                  </UploadIcon>
                </div>
              </div>
            )}
          </div>
        </Container>
      );
    }
    if (/.*\/task\/(.+)\/working-history\//.test(pathname)) {
      return (
        <Container
          isOpen={true}
          windowWidth={windowWidth}
          isOpenRightSideBar={props.isOpenRightSideBar}>
          <CustomScrollView scrollViewRef={ref} style={{ height: 'calc(100vh - 57px)' }}>
            <ContainerInner isShow={true}>
              <TaskDetailCommon
                organizationId={loginUser?.organizationId!}
                task={props.task}
                showCalendarMenu={props.showCalendarMenu}>
                <TaskDetailWorkingHistory task={props.task} />
              </TaskDetailCommon>
            </ContainerInner>
          </CustomScrollView>
        </Container>
      );
    }
    if (/.*\/task\/(.+)\/working-schedule\//.test(pathname)) {
      return (
        <Container
          isOpen={true}
          windowWidth={windowWidth}
          isOpenRightSideBar={props.isOpenRightSideBar}>
          <CustomScrollView scrollViewRef={ref} style={{ height: 'calc(100vh - 57px)' }}>
            <ContainerInner isShow={true}>
              <TaskDetailCommon
                organizationId={loginUser?.organizationId!}
                task={props.task}
                showCalendarMenu={props.showCalendarMenu}>
                <TaskDetailWorkingSchedule task={props.task} />
              </TaskDetailCommon>
            </ContainerInner>
          </CustomScrollView>
        </Container>
      );
    }
    if (/.*\/task\/(.+)\/remainder\//.test(pathname)) {
      return (
        <Container
          isOpen={true}
          windowWidth={windowWidth}
          isOpenRightSideBar={props.isOpenRightSideBar}>
          <CustomScrollView scrollViewRef={ref} style={{ height: 'calc(100vh - 57px)' }}>
            <ContainerInner isShow={true}>
              <TaskDetailCommon
                organizationId={loginUser?.organizationId!}
                task={props.task}
                showCalendarMenu={props.showCalendarMenu}>
                <TaskRemainderView task={props.task} />
              </TaskDetailCommon>
            </ContainerInner>
          </CustomScrollView>
        </Container>
      );
    }
    if (props.showCalendarMenu && /.*\/task\/(.+)\/calendar\//.test(pathname)) {
      return (
        <Container
          isOpen={true}
          windowWidth={windowWidth}
          isOpenRightSideBar={props.isOpenRightSideBar}>
          <CustomScrollView scrollViewRef={ref} style={{ height: 'calc(100vh - 57px)' }}>
            <ContainerInner isShow={true}>
              <TaskDetailCommon
                organizationId={loginUser?.organizationId!}
                task={props.task}
                showCalendarMenu={props.showCalendarMenu}>
                <TaskDetailCalendarInfo task={props.task} />
              </TaskDetailCommon>
            </ContainerInner>
          </CustomScrollView>
        </Container>
      );
    }
    if (/.*\/task\/(.+)\/history\//.test(pathname)) {
      return (
        <Container
          isOpen={true}
          windowWidth={windowWidth}
          isOpenRightSideBar={props.isOpenRightSideBar}>
          <CustomScrollView scrollViewRef={ref} style={{ height: 'calc(100vh - 57px)' }}>
            <ContainerInner isShow={true}>
              <TaskDetailCommon
                organizationId={loginUser?.organizationId!}
                task={props.task}
                showCalendarMenu={props.showCalendarMenu}>
                <TaskHistoryView task={props.task} />
              </TaskDetailCommon>
            </ContainerInner>
          </CustomScrollView>
        </Container>
      );
    }
  }
  return (
    <Container
      isOpen={false}
      windowWidth={windowWidth}
      isOpenRightSideBar={props.isOpenRightSideBar}>
      <ContainerInner isShow={false}>
        <TaskDetailCommonLoading>
          <TaskDetailLoading />
        </TaskDetailCommonLoading>
      </ContainerInner>
    </Container>
  );
};

interface ITaskDetailWithFetchDataProps {
  taskId: string | null;
  commentId: string | null;
  isOpenRightSideBar: boolean;
}

const TaskDetailWithFetchData = (props: ITaskDetailWithFetchDataProps) => {
  const { loading, data, error } = useTaskQuery({
    variables: {
      id: props.taskId,
    },
    skip: props.taskId === null,
    fetchPolicy: 'network-only',
  });
  const { loading: loadingGoogleAccount, data: dataGoogleAccount } = useMyGoogleAccountQuery();

  return (
    <TaskDetailInner
      loading={loading}
      task={(data?.task as Task) || null}
      showCalendarMenu={dataGoogleAccount?.myGoogleAccount?.calendarPermission ?? false}
      commentId={props.commentId}
      isOpenRightSideBar={props.isOpenRightSideBar}
    />
  );
};

const TaskDetail = () => {
  const { pathname, search } = useLocation();
  const taskMatches = pathname.match(/.*\/task\/(.+)\/[^\/]+/);
  let taskId: string | null = null;
  if (taskMatches && taskMatches.length > 1) {
    taskId = taskMatches[1].split('/')[0];
  }

  const commentMatches = pathname.match(/.*\/comment\/(.+)\//);
  let commentId: string | null = null;
  if (commentMatches && commentMatches.length > 1) {
    commentId = commentMatches[1].split('/')[0];
  }

  const isOpenRightSideBar = !!queryString.parse(search).right;
  return (
    <TaskDetailWithFetchData
      taskId={taskId}
      commentId={commentId}
      isOpenRightSideBar={isOpenRightSideBar}
    />
  );
};

export default TaskDetail;
