import React, { useContext } from 'react';
import { Text, View } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../../theme';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import TimeUtil from '../../../../../util/TimeUtil';
import { Organization, ProjectTemplateTask } from '../../../../../graphql/api/API';
import { TaskProgressBar } from '../task-template-progress-bar';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import PlayIcon from '../../../../presentational/molecules/image-icon/play';

const Container = styled.View`
  display: flex;
  flex-direction: column;
  margin: 3px 5px;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.4s;
  z-index: 100;
  border-width: 1px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
`;

const Main = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 3px 10px 3px 5px;
`;

interface IProps {
  organization: Organization;
  task: ProjectTemplateTask;
}

const TaskSummaryForBoard = React.memo((props: IProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, _] = useContext(LoginUserContext);

  return (
    <Container>
      <Main>
        <View
          style={{
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
          }}>
          <View style={{ justifyContent: 'center', alignItems: 'center' }}>
            <PlayIcon size={42} />
          </View>
          <View style={{ flexDirection: 'column', marginLeft: 5 }}>
            <View style={{ flexDirection: 'row', flex: 1 }}>
              <View
                style={{
                  flexDirection: 'row',
                  flex: 1,
                  justifyContent: 'space-between',
                }}>
                <Typography
                  variant={TypographyType.Normal}
                  ellipsis={true}
                  style={{ fontSize: 14, minWidth: 30, maxWidth: 180, textAlign: 'left' }}>
                  {props.task.title}
                </Typography>
                <View
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    maxWidth: 15,
                  }}
                />
              </View>
            </View>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <View style={{ flexDirection: 'row', flex: 1, paddingLeft: 5 }}>
                <Text>-</Text>
                <Typography
                  variant={TypographyType.Normal}
                  style={{ fontSize: 14, textAlign: 'center', marginHorizontal: 3 }}>
                  /
                </Typography>
                <Typography
                  variant={TypographyType.Normal}
                  style={{ fontSize: 14, textAlign: 'left', minWidth: 65, overflow: 'hidden' }}>
                  {TimeUtil.formatForTask(props.task.estimateTimeSec)}
                </Typography>
                <Typography
                  variant={TypographyType.Normal}
                  style={{ fontSize: 14, textAlign: 'right', marginHorizontal: 3, minWidth: 40 }}>
                  {`- %`}
                </Typography>
              </View>
            </View>
          </View>
        </View>
      </Main>
      <TaskProgressBar />
    </Container>
  );
});

export default TaskSummaryForBoard;
