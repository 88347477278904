import React, { useContext, useState } from 'react';
import styled, { ThemeContext } from 'styled-components/native';
import { View } from 'react-native';
import { IStyleTheme, IThemePart } from '../../../../../../theme';
import Table from '../../../../../../presentational/molecules/table';
import Typography, { TypographyType } from '../../../../../../presentational/atoms/typography';
import moment from 'moment-timezone';
import {
  ExportProjectExcelsDocument,
  JobProgressStatus,
  useCreateExportProjectExcelDownloadUrlMutation,
  useExportProjectExcelsQuery,
  useRequestExportOrganizationProjectExcelMutation,
} from '../../../../../../../graphql/api/API';
import Button from '../../../../../../presentational/atoms/button';
import when from '../../../../../../../lang-extention/When';
import Modal from '../../../../../../presentational/molecules/modal';
import DownloadUtil from '../../../../../../../util/DownloadUtil';
import DownloadIcon from '../../../../../../presentational/molecules/image-icon/download';
import Form from '../../../../../../presentational/atoms/form';
import Input from '../../../../../../presentational/atoms/input';
import LoginUtil from '../../../../../../../modules/auth/LoginUtil';

const Container = styled.View`
  display: flex;
  flex: 1;
  height: calc(100vh - 57px - 48px);
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
  z-index: 0;
`;

const DataExportPage = () => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [showModal, setShowModal] = useState(false);
  const [includeCompleted, setIncludeCompleted] = useState(false);

  const { loading, data } = useExportProjectExcelsQuery({
    fetchPolicy: 'network-only',
    variables: {
      input: {
        teamId: null,
      },
    },
  });
  const [requestReport, __] = useRequestExportOrganizationProjectExcelMutation({
    variables: {
      input: {
        teamId: null,
        includeCompleteProject: includeCompleted,
        timeZoneOffset: moment().tz(moment.tz.guess()).format('Z'),
      },
    },
    fetchPolicy: 'no-cache',
    refetchQueries: [
      {
        query: ExportProjectExcelsDocument,
        variables: {
          input: {
            teamId: null,
          },
        },
      },
    ],
  });

  const [createDownloadUrl] = useCreateExportProjectExcelDownloadUrlMutation();

  if (loading) {
    return <></>;
  }

  return (
    <Container style={{ paddingHorizontal: 30 }}>
      <Typography
        variant={TypographyType.SubTitle}
        style={{ fontSize: 14, lineHeight: 22, marginTop: 20, marginLeft: 17 }}>
        プロジェクトの情報をExcelファイルに出力して、ダウンロードすることが出来ます。
      </Typography>
      <View style={{ flexDirection: 'row', marginTop: 20 }}>
        <View style={{ width: 200, justifyContent: 'center', alignItems: 'flex-end' }}>
          <Typography variant={TypographyType.Normal} style={{ fontSize: 18 }}>
            完了したプロジェクト
          </Typography>
        </View>
        <View>
          <Form style={{ marginLeft: 20, width: 400 }}>
            <Input
              name={'includeCompleted'}
              type={'picker'}
              initialValue={includeCompleted + ''}
              pickerItems={[
                {
                  label: '含める',
                  value: 'true',
                },
                {
                  label: '含めない',
                  value: 'false',
                },
              ]}
              onChange={(value) => {
                setIncludeCompleted(value === 'true');
              }}
            />
          </Form>
        </View>
      </View>
      <View style={{ flexDirection: 'row', marginTop: 10 }}>
        <View style={{ width: 100 }}></View>
        <View style={{ marginLeft: 30 }}>
          <Button
            text={'Excelファイルを出力する'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
            }}
            onPress={async () => {
              await requestReport();
              setShowModal(true);
            }}
          />
        </View>
        <Modal
          title={'ファイルの作成を開始します'}
          isShow={showModal}
          onClose={() => {
            setShowModal(false);
          }}>
          <Typography
            variant={TypographyType.Normal}
            style={{ textAlign: 'center', marginVertical: 10 }}>
            ファイルを作成します。少々お待ち下さい。
          </Typography>
          <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
            <Button
              text={'OK'}
              style={{ minWidth: 100, marginRight: 10, marginVertical: 20 }}
              onPress={async () => {
                setShowModal(false);
              }}
            />
          </View>
        </Modal>
      </View>
      <View style={{ marginTop: 30, paddingLeft: 10 }}>
        {(data?.exportProjectExcels?.length ?? 0) > 0 && (
          <Typography variant={TypographyType.Normal} style={{ fontSize: 18 }}>
            ファイル出力結果一覧
          </Typography>
        )}
        <Table
          height={54}
          style={{ width: '100%' }}
          rowData={(data?.exportProjectExcels ?? [])
            .slice()
            .sort((a, b) => {
              return (
                new Date(b!.requestDateTime).getTime() - new Date(a!.requestDateTime).getTime()
              );
            })
            .map((report) => {
              return {
                出力状況: (
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}>
                    <View
                      style={{
                        flexDirection: 'column',
                        justifyContent: 'center',
                      }}>
                      <Typography variant={TypographyType.Normal} style={{ fontSize: 14 }}>
                        {`${when(report!.status)
                          .on(
                            (v) => v === JobProgressStatus.Accepted,
                            () => 'ファイル作成中'
                          )
                          .on(
                            (v) => v === JobProgressStatus.InProgress,
                            () => 'ファイル作成中'
                          )
                          .on(
                            (v) => v === JobProgressStatus.Success,
                            () => 'ファイル作成済み'
                          )
                          .on(
                            (v) => v === JobProgressStatus.Failure,
                            () => 'ファイル作成失敗'
                          )
                          .otherwise(() => '-')}`}
                      </Typography>
                    </View>
                  </View>
                ),
                完了したプロジェクト: (
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}>
                    <View
                      style={{
                        flexDirection: 'column',
                        justifyContent: 'center',
                      }}>
                      <Typography variant={TypographyType.Normal} style={{ fontSize: 14 }}>
                        {report?.includeCompleted ? '含める' : '含めない'}
                      </Typography>
                    </View>
                  </View>
                ),
                出力依頼日時: (
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}>
                    <View
                      style={{
                        flexDirection: 'column',
                        justifyContent: 'center',
                      }}>
                      <Typography variant={TypographyType.Normal} style={{ fontSize: 14 }}>
                        {`${moment(report!.requestDateTime).format('YYYY/MM/DD HH:mm')}`}
                      </Typography>
                    </View>
                  </View>
                ),
                ファイル: (
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}>
                    {report!.status === JobProgressStatus.Success ? (
                      <DownloadIcon
                        size={20}
                        containerStyle={{ marginRight: 5 }}
                        onPress={async () => {
                          const result = await createDownloadUrl({
                            variables: {
                              id: report?.id!
                            }
                          });
                          if (result.data?.createExportProjectExcelDownloadUrl) {
                            const splited = report!.url!.split('/');
                            const fileName = splited[splited.length - 1];  
                            DownloadUtil.forceDownload(
                              result.data!.createExportProjectExcelDownloadUrl.downloadUrl,
                              fileName
                            );
                          }
                        }}>
                        <Typography variant={TypographyType.Normal}>ダウンロードする</Typography>
                      </DownloadIcon>
                    ) : (
                      <Typography variant={TypographyType.Normal} style={{ textAlign: 'center' }}>
                        -
                      </Typography>
                    )}
                  </View>
                ),
              };
            })}
          rowDataStyle={{
            出力依頼日時: {
              width: 'auto',
            },
            完了したプロジェクト: {
              width: 'auto',
            },
            ファイル: {
              width: 200,
            },
          }}
        />
      </View>
    </Container>
  );
};

export default React.memo(DataExportPage);
