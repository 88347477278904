import { LinearGradient } from 'expo-linear-gradient';
import * as Cookies from 'js-cookie';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Animated,
  type NativeScrollEvent,
  type NativeSyntheticEvent,
  Text,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from 'react-native';
import {
  PanGestureHandler,
  type PanGestureHandlerGestureEvent,
  State,
} from 'react-native-gesture-handler';
import { useHistory } from 'react-router';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import {
  type Member,
  type Organization,
  Plan,
  Priority,
  type Project,
  ProjectTasksDocument,
  SortKey,
  SortOrder,
  type Task,
  TaskCompleteFilter,
  TaskDocument,
  useCreateTaskMutation,
  useCreateTasksMutation,
  useMeQuery,
  useOrganizationQuery,
  useProjectQuery,
  useSearchTasksQuery,
  useUpdateTaskMutation,
  useUpdateTaskSortNoInGanttChartMutation,
} from '../../../../../graphql/api/API';
import when from '../../../../../lang-extention/When';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import ColorUtil from '../../../../../util/ColorUtil';
import DateUtil from '../../../../../util/DateUtil';
import TimeUtil from '../../../../../util/TimeUtil';
import Avatar from '../../../../presentational/atoms/avatar';
import Button from '../../../../presentational/atoms/button';
import CustomScrollView from '../../../../presentational/atoms/custom-scroll-view';
import Form from '../../../../presentational/atoms/form';
import Input from '../../../../presentational/atoms/input';
import useChunk from '../../../../presentational/atoms/list2/use-chunk';
import { NonForwardedRefAnimatedView } from '../../../../presentational/atoms/list2/virtualized-flat-list';
import Spinner from '../../../../presentational/atoms/spinner';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import CaretDownIcon from '../../../../presentational/molecules/image-icon/caret-down';
import CaretSetIcon from '../../../../presentational/molecules/image-icon/caret-set';
import CaretUpIcon from '../../../../presentational/molecules/image-icon/caret-up';
import DeleteIcon from '../../../../presentational/molecules/image-icon/delete';
import DoubleLeftIcon from '../../../../presentational/molecules/image-icon/double-left';
import DoubleRightIcon from '../../../../presentational/molecules/image-icon/double-right';
import PlusIcon from '../../../../presentational/molecules/image-icon/plus';
import SelectButton from '../../../../presentational/molecules/select-button';
import type { IStyleTheme, IThemePart } from '../../../../theme';
import {
  type TaskFilter,
  TaskFilterContext,
  matchExpireFilter,
} from '../../templates/app/team-projects';
import ErrorMessageModal from '../error-message-modal';
import PlanNotAllowedView from '../plan-not-allowed-view';
import { TaskProgressBar } from '../task-progress-bar';
import { TaskWorkingTime } from '../task-summary';

const columnWidth = 60;
const dateScaleSet = [1, 7];
const itemHeight = 55;
const headerHeight = 70;

const HeaderWrapper = styled.View`
  display: flex;
  flex-direction: column;
  height: ${headerHeight}px;
  z-index: 2;
`;

const TaskAddButton = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  padding-right: 5px;
  padding-left: 5px;
`;

const Main = styled.View`
  display: flex;
  flex-direction: row;
`;

const TaskTitleRowContainer = styled.TouchableOpacity`
  height: ${itemHeight}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
  padding: 5px 15px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
  border-width: 1px;
`;

const LeftContainerInner = styled.View`
  display: flex;
  min-width: 540px;
`;

const RightContainerWrapper = styled.View`
  flex: 2;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  z-index: 1;
`;

interface IRightContainerInnerProps extends IStyleTheme {
  dateColumnCount: number;
}

const RightContainerInner = styled.View`
  display: flex;
  width: ${(props: IRightContainerInnerProps) => `${props.dateColumnCount * columnWidth}px`};
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const HeaderMenu = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
  color: #ffffff;
  font-size: 1.5rem;
  padding: 5px 15px;
`;

const TitleHeader = styled.View`
  height: ${headerHeight}px;
  min-width: 500px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${(props: IStyleTheme) => props.theme.colors.header};
  color: #ffffff;
  font-size: 1.5rem;
  padding: 5px 15px;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
`;

const Header = styled.View`
  height: ${headerHeight}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${(props: IStyleTheme) => props.theme.colors.subHeader};
  color: #ffffff;
  font-size: 1.5rem;
  padding: 5px 15px;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
`;

const DateHeader = styled.View`
  height: ${headerHeight}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
  border-width: 1px;
`;

const DateLineWrapper = styled.View`
  position: absolute;
  display: flex;
  background: transparent;
  width: ${(props: IRightContainerInnerProps) => `${props.dateColumnCount * columnWidth}px`};
`;

const DateLineHeader = styled.View`
  height: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: transparent;
`;

const Overlay = styled.TouchableOpacity`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  z-index: 99;
  transition: all 0.4s;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
`;

interface IDateCellProps extends IStyleTheme {
  isStartOfDayColumn: boolean;
  isWeekEnd: boolean;
  isToday: boolean;
}

const DateCell = styled.View<IDateCellProps>`
  height: ${itemHeight}px;
  width: ${columnWidth}px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-color: ${(props: IDateCellProps) => props.theme.colors.separator};
  border-left-width: ${(props: IDateCellProps) => (props.isStartOfDayColumn ? '1px' : '0')};
  background-color: ${(props: IDateCellProps) =>
    props.isToday ? '#dbeeff' : props.isWeekEnd ? '#f8f8f8' : '#FFFFFF'};
`;

interface IDateLineProps extends IStyleTheme {
  isStartOfDayColumn: boolean;
}

const DateLine = styled.View<IDateLineProps>`
  height: ${itemHeight}px;
  width: ${columnWidth}px;
  display: flex;
  flex-direction: row;
  background: transparent;
`;

const TaskRowContainer = styled.TouchableOpacity`
  width: 100%;
  height: ${itemHeight}px;
  display: flex;
  flex-direction: row;
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
  padding: 5px 15px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
  border-width: 1px;
`;

const GanttRowContainer = styled.View`
  width: 100%;
  height: ${itemHeight}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: transparent;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
  border-width: 1px;
`;

interface IGanttItemProps extends IStyleTheme {
  left: number;
  width: number;
  color: string;
  existStartAndEndDateTime: boolean;
}

const GanttItem = styled.View`
  position: absolute;
  left: ${(props: IGanttItemProps) => props.left}px;
  width: ${(props: IGanttItemProps) => props.width}px;
  top: 0;
  height: 35px;
  display: flex;
  flex-direction: row;
  background-color: ${(props: IGanttItemProps) =>
    props.existStartAndEndDateTime ? ColorUtil.lignten(props.color, 20) : 'none'};
  border-color: ${(props: IGanttItemProps) => ColorUtil.darken(props.color, 20)};
  border-right-width: 1px;
`;

interface IGanttItemInnerProps extends IStyleTheme {
  color: string;
  widthPercent: number;
}

const GanttItemInner = styled.View`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: ${(props: IGanttItemInnerProps) => props.widthPercent}%;
  display: flex;
  flex-direction: row;
  background-color: ${(props: IGanttItemInnerProps) => props.color};
`;

const GanttItemLeftHandle = styled.View`
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 1px;
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  opacity: 0.3;
  cursor: col-resize;
`;

const GanttItemRightHandle = styled.View`
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  width: 2px;
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  opacity: 0.3;
  cursor: col-resize;
`;

const colors = [
  '#00b894', // 緑。予定より順調
  '#00cec9',
  '#0984e3', // 青。スタンダート
  '#6c5ce7',
  '#ffeaa7', // 黄色。ちょっと危ないかも
  '#fab1a0',
  '#ff7675', // 赤。完全に遅れている
  '#fd79a8',
];

const colorGreen = '#00b894';
const colorBlue = '#0984e3';
const colorYellow = '#ffeaa7';
const colorRed = '#ff7675';

interface ITaskTitleRowProps {
  organization: Organization;
  task: Task;
  index: number;
  allTaskCount: number;
  sortKey: SortKey | null;
  setDraggingTaskIndex: (value: number | null) => void;
  onDrop: (targetTask: Task, index: number) => void;
}

const TaskTitleRow = React.memo((props: ITaskTitleRowProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const history = useHistory();
  const ref = useRef();

  useEffect(() => {
    (ref as any).current.style.position = `absolute`;
    (ref as any).current.style.width = `100%`;
    (ref as any).current.style.top = `${props.index * itemHeight}px`;
  }, [props.index]);

  const onPress = useCallback(() => {
    if (history.location.pathname.indexOf('/my/favorite-project') !== -1) {
      history.push(
        `/app/${props.organization.id!}/my/favorite-project/${
          props.task.project.id
        }/schedule/task/${props.task.id}/detail/`
      );
    } else if (history.location.pathname.indexOf('/my/assigned-project') !== -1) {
      history.push(
        `/app/${props.organization.id!}/my/assigned-project/${
          props.task.project.id
        }/schedule/task/${props.task.id}/detail/`
      );
    } else {
      history.push(
        `/app/${props.organization.id!}/${props.task.project.team.id}/projects/${
          props.task.project.id
        }/schedule/task/${props.task.id}/detail/`
      );
    }
  }, [props.organization.id!, props.task]);

  const onGestureEvent = useCallback(
    (event) => {
      if (props.sortKey !== null) {
        return;
      }
      if (event.nativeEvent.state === State.BEGAN) {
        props.setDraggingTaskIndex(props.index);
      }
      if (event.nativeEvent.state === State.ACTIVE) {
        const newIndex =
          props.index + Math.round((event.nativeEvent.translationY - itemHeight / 2) / itemHeight);
        props.setDraggingTaskIndex(Math.max(-1, Math.min(newIndex, props.allTaskCount - 1)));
      }
      if (event.nativeEvent.state === State.END) {
        props.setDraggingTaskIndex(null);

        const newIndex =
          props.index + Math.round((event.nativeEvent.translationY - itemHeight / 2) / itemHeight);
        props.onDrop(props.task, Math.max(-1, Math.min(newIndex, props.allTaskCount - 1)));
      }
    },
    [props.task, props.index, props.setDraggingTaskIndex, props.onDrop, props.sortKey]
  );

  return (
    <PanGestureHandler
      maxPointers={1}
      onGestureEvent={Animated.event([], {
        listener: onGestureEvent,
        useNativeDriver: true,
      })}>
      <NonForwardedRefAnimatedView style={{}}>
        <TaskTitleRowContainer ref={ref as any} onPress={onPress}>
          <Typography
            variant={TypographyType.Normal}
            style={{ fontSize: 12, textAlign: 'left', maxWidth: 500 }}
            ellipsis={true}>
            {props.task.title}
          </Typography>
        </TaskTitleRowContainer>
      </NonForwardedRefAnimatedView>
    </PanGestureHandler>
  );
});

interface ITaskRowProps {
  organization: Organization;
  task: Task;
  index: number;
  sortKey: SortKey | null;
  setDraggingTaskIndex: (value: number | null) => void;
  onDrop: (targetTask: Task, index: number) => void;
}

const TaskRow = React.memo((props: ITaskRowProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const ref = useRef();
  const history = useHistory();
  const onPress = useCallback(() => {
    if (history.location.pathname.indexOf('/my/favorite-project') !== -1) {
      history.push(
        `/app/${props.organization.id!}/my/favorite-project/${
          props.task.project.id
        }/schedule/task/${props.task.id}/detail/`
      );
    } else if (history.location.pathname.indexOf('/my/assigned-project') !== -1) {
      history.push(
        `/app/${props.organization.id!}/my/assigned-project/${
          props.task.project.id
        }/schedule/task/${props.task.id}/detail/`
      );
    } else {
      history.push(
        `/app/${props.organization.id!}/${props.task.project.team.id}/projects/${
          props.task.project.id
        }/schedule/task/${props.task.id}/detail/`
      );
    }
  }, [props.organization.id!, props.task]);

  useEffect(() => {
    (ref as any).current.style.position = `absolute`;
    (ref as any).current.style.width = `100%`;
    (ref as any).current.style.top = `${props.index * itemHeight}px`;
  }, [props.index]);

  const onGestureEvent = useCallback(
    (event) => {
      if (props.sortKey !== null) {
        return;
      }
      if (event.nativeEvent.state === State.BEGAN) {
        props.setDraggingTaskIndex(props.index);
      }
      if (event.nativeEvent.state === State.ACTIVE) {
        const newIndex =
          props.index + Math.round((event.nativeEvent.translationY - itemHeight / 2) / itemHeight);
        props.setDraggingTaskIndex(newIndex);
      }
      if (event.nativeEvent.state === State.END) {
        props.setDraggingTaskIndex(null);

        const newIndex =
          props.index + Math.round((event.nativeEvent.translationY - itemHeight / 2) / itemHeight);
        props.onDrop(props.task, newIndex);
      }
    },
    [props.task, props.index, props.setDraggingTaskIndex, props.onDrop, props.sortKey]
  );

  return (
    <PanGestureHandler
      maxPointers={1}
      onGestureEvent={Animated.event([], {
        listener: onGestureEvent,
        useNativeDriver: true,
      })}>
      <NonForwardedRefAnimatedView style={{}}>
        <TaskRowContainer ref={ref as any} onPress={onPress}>
          {(props.organization.plan.code === 'Business' ||
            props.organization.plan.code === 'Enterprise') && (
            <View
              style={{
                width: 60,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              {props.task.assignees.length >= 2 ? (
                <Avatar
                  size={24}
                  textStyle={{ fontSize: 10 }}
                  name={`${props.task.assignees.length}名`}
                />
              ) : (
                props.task.assignees.map((info, i) => {
                  return (
                    <Avatar
                      size={24}
                      name={info.member.name!}
                      imageUrl={info.member.profileImageUrl}
                    />
                  );
                })
              )}
            </View>
          )}
          <View
            style={{
              width: 60,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Typography
              variant={TypographyType.Normal}
              style={{ fontSize: 12, textAlign: 'center' }}>
              {when(props.task.priority)
                .on(
                  (v) => v === Priority.High,
                  () => '高'
                )
                .on(
                  (v) => v === Priority.Normal,
                  () => '中'
                )
                .on(
                  (v) => v === Priority.Low,
                  () => '低'
                )
                .otherwise(() => '-')}
            </Typography>
          </View>
          <View
            style={{
              width: 80,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <TaskWorkingTime task={props.task} style={{ fontSize: 12, textAlign: 'center' }} />
          </View>
          <View
            style={{
              width: 80,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Typography
              variant={TypographyType.Normal}
              style={{ fontSize: 12, textAlign: 'center' }}>
              {TimeUtil.formatForTask(props.task.estimateTimeSec)}
            </Typography>
          </View>
          <View
            style={{
              width: 90,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Typography
              variant={TypographyType.Normal}
              style={{ fontSize: 12, textAlign: 'center' }}>
              {DateUtil.formatDate(props.task.scheduledStartDateTime)}
            </Typography>
          </View>
          <View
            style={{
              width: 90,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Typography
              variant={TypographyType.Normal}
              style={{ fontSize: 12, textAlign: 'center' }}>
              {DateUtil.formatDate(props.task.scheduledEndDateTime)}
            </Typography>
          </View>
          <View
            style={{
              width: 60,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Typography
              variant={TypographyType.Normal}
              style={{ fontSize: 12, width: 60, textAlign: 'center' }}>
              {`${props.task.progressRate}%`}
            </Typography>
          </View>
        </TaskRowContainer>
      </NonForwardedRefAnimatedView>
    </PanGestureHandler>
  );
});

class GanttRowRightHandle extends React.Component<{}> {
  render() {
    return <GanttItemRightHandle />;
  }
}

class GanttRowLeftHandle extends React.Component<{}> {
  render() {
    return <GanttItemLeftHandle />;
  }
}

interface IGanttRowInnerProps {
  organization: Organization;
  task: Task;
  startDateTime: Date | null;
  endDateTime: Date | null;
  progressPercent: number;
  ganttStartDate: moment.Moment;
  dateScale: number;
  translationX: number;
  ghostStartDateTime: moment.Moment | null;
  ghostEndDateTime: moment.Moment | null;
  updateGhostDate: (start: moment.Moment | null, end: moment.Moment | null) => void;
  setDragHandle: (value: boolean) => void;
  onChangeDate: (startDate: Date | null, endDate: Date) => void;
  onChangeProgressPercent: (value: number) => void;
  onDropProgressPercent: (value: number) => void;
}

const GanttInnerRow = React.memo((props: IGanttRowInnerProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const displayStartDate = props.startDateTime
    ? moment(props.startDateTime)
    : moment(props.endDateTime).add(-2, 'day').startOf('day');
  const displayEndDate = props.endDateTime
    ? moment(props.endDateTime)
    : moment(displayStartDate).add(2, 'day').endOf('day');

  const [rightTranslationX, setRightTranslationX] = useState(0);
  const [progressTranslationX, setProgressTranslationX] = useState(0);
  const [progressPercent, setProgressPercent] = useState(props.progressPercent || 0);
  const [itemWidth, setItemWidth] = useState(
    (displayEndDate.diff(displayStartDate, 'hours') / 24 / props.dateScale) * columnWidth
  );
  const [minProgressTranslationX, setMinProgressTranslationX] = useState(0);
  const [maxProgressTranslationX, setMaxProgressTranslationX] = useState(0);

  useEffect(() => {
    const newItemWidth =
      (displayEndDate.diff(displayStartDate, 'hours') / 24 / props.dateScale) * columnWidth;
    setItemWidth(newItemWidth);
    setProgressPercent(props.progressPercent || 0);
    setMinProgressTranslationX((newItemWidth / 100) * props.progressPercent * -1);
    setMaxProgressTranslationX((newItemWidth / 100) * (100 - props.progressPercent));
  }, [
    props.startDateTime,
    props.endDateTime,
    props.dateScale,
    props.progressPercent,
    setItemWidth,
    columnWidth,
    // displayStartDate, //依存変数に含めると、useEffectの実行回数が多くなり、進捗率ドラッグ操作が反映されなくなる
    // displayEndDate, // 同上
    setProgressPercent,
    setMinProgressTranslationX,
    setMaxProgressTranslationX,
  ]);

  const onGestureEventProgressRate = useCallback(
    (event) => {
      if (event.nativeEvent.state === State.BEGAN) {
        props.setDragHandle(true);
      }
      if (event.nativeEvent.state === State.ACTIVE) {
        const translationX = Math.max(
          minProgressTranslationX,
          Math.min(maxProgressTranslationX, event.nativeEvent.translationX)
        );
        const newProgressPercent = Math.round(
          ((translationX - minProgressTranslationX) / itemWidth) * 100
        );
        setProgressPercent(newProgressPercent);
      }
      if (event.nativeEvent.state === State.END) {
        const translationX = Math.max(
          minProgressTranslationX,
          Math.min(maxProgressTranslationX, event.nativeEvent.translationX)
        );
        const newProgressPercent = Math.round(
          ((translationX - minProgressTranslationX) / itemWidth) * 100
        );
        setProgressPercent(newProgressPercent);

        props.setDragHandle(false);
        props.onChangeProgressPercent(newProgressPercent);
        props.onDropProgressPercent(newProgressPercent);
      }
    },
    [
      props.setDragHandle,
      props.onChangeProgressPercent,
      props.onDropProgressPercent,
      props.dateScale,
      props.startDateTime,
      props.endDateTime,
      setProgressPercent,
      minProgressTranslationX,
      maxProgressTranslationX,
      itemWidth,
      progressPercent,
    ]
  );

  const onGestureEventEndDate = useCallback(
    (event) => {
      if (event.nativeEvent.state === State.BEGAN) {
        props.setDragHandle(true);
        setRightTranslationX(0);
      }
      if (event.nativeEvent.state === State.ACTIVE) {
        const newEndDate = moment(displayEndDate)
          .add((event.nativeEvent.translationX / columnWidth) * props.dateScale, 'days')
          .startOf('day')
          .add(1, 'day')
          .add(-1, 'second');

        if (newEndDate.isAfter(displayStartDate)) {
          const transX =
            (newEndDate.diff(moment(displayEndDate), 'seconds') /
              (24 * 60 * 60) /
              props.dateScale) *
            columnWidth;
          setRightTranslationX(transX);
        } else {
          const transX =
            (moment(displayStartDate)
              .startOf('day')
              .add(1, 'day')
              .add(-1, 'second')
              .diff(moment(displayEndDate), 'seconds') /
              (24 * 60 * 60) /
              props.dateScale) *
            columnWidth;
          setRightTranslationX(transX);
        }

        props.updateGhostDate(moment(displayStartDate), newEndDate);
      }
      if (event.nativeEvent.state === State.END) {
        const moveDay = rightTranslationX / (columnWidth / props.dateScale);
        props.onChangeDate(
          displayStartDate.toDate(),
          moment(displayEndDate)
            .add(moveDay * 24, 'hours')
            .toDate()
        );

        props.setDragHandle(false);
        setRightTranslationX(0);
        props.updateGhostDate(null, null);
      }
    },
    [
      props.setDragHandle,
      setRightTranslationX,
      rightTranslationX,
      columnWidth,
      props.dateScale,
      props.onChangeDate,
      props.startDateTime,
      props.endDateTime,
      setRightTranslationX,
    ]
  );

  return (
    <GanttItem
      left={
        (displayStartDate.diff(props.ganttStartDate, 'hours') / 24 / props.dateScale) *
          columnWidth +
        props.translationX
      }
      width={itemWidth + rightTranslationX}
      color={colorBlue}
      existStartAndEndDateTime={
        !!props.task.scheduledStartDateTime && !!props.task.scheduledEndDateTime
      }>
      {(!props.task.scheduledStartDateTime || !props.task.scheduledEndDateTime) && (
        <LinearGradient
          colors={[
            props.task.scheduledStartDateTime ? 'rgba(9, 132, 227, 1)' : 'transparent',
            !(props.task.scheduledStartDateTime && props.task.scheduledEndDateTime)
              ? 'rgba(9, 132, 227, 0.1)'
              : 'rgba(9, 132, 227, 1)',
            props.task.scheduledEndDateTime ? 'rgba(9, 132, 227, 1)' : 'transparent',
          ]}
          end={{ x: 0, y: 0 }}
          style={{ width: '100%', height: '100%' }}
        />
      )}
      <GanttItemInner widthPercent={progressPercent} color={colorBlue} />

      <TaskProgressBar
        task={props.task}
        showProgress={false}
        barHeight={5}
        darken={true}
        style={{ width: itemWidth + rightTranslationX }}
        containerStyle={{ position: 'absolute', bottom: 0 }}
      />

      <PanGestureHandler maxPointers={1} onGestureEvent={onGestureEventProgressRate}>
        <NonForwardedRefAnimatedView
          style={{
            position: 'absolute',
            left: `calc(${progressPercent}% - 6px)`,
            top: itemHeight - 19,
            bottom: -7,
            width: 10,
            borderTopWidth: 0,
            borderRightWidth: 7,
            borderBottomWidth: 10,
            borderLeftWidth: 7,
            borderTopColor: 'transparent',
            borderRightColor: 'transparent',
            borderBottomColor: colorBlue,
            borderLeftColor: 'transparent',
            cursor: 'col-resize',
          }}>
          <Typography
            variant={TypographyType.Normal}
            style={{
              fontSize: 12,
              position: 'absolute',
              top: -7,
              left: 12,
              color: colorBlue,
            }}>
            {progressPercent}%
          </Typography>
        </NonForwardedRefAnimatedView>
      </PanGestureHandler>
      <View
        style={
          {
            position: 'sticky',
            left: 3,
          } as any
        }>
        {props.task.assignees &&
          (props.organization.plan.code === 'Business' ||
            props.organization.plan.code === 'Enterprise') && (
            <View
              style={{
                position: 'absolute',
                top: 2,
                left: 3,
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              {props.task.assignees.length >= 2 ? (
                <Avatar
                  size={24}
                  textStyle={{ fontSize: 10 }}
                  name={`${props.task.assignees.length}名`}
                />
              ) : (
                props.task.assignees.map((info, i) => {
                  return (
                    <Avatar
                      size={24}
                      name={info.member.name!}
                      imageUrl={info.member.profileImageUrl}
                    />
                  );
                })
              )}
            </View>
          )}
        <Typography
          variant={TypographyType.Normal}
          style={{
            color: '#FFFFFF',
            fontSize: 14,
            position: 'absolute',
            left:
              props.task.assignees.length > 0 &&
              (props.organization.plan.code === 'Business' ||
                props.organization.plan.code === 'Enterprise')
                ? 30
                : 10,
            maxWidth:
              itemWidth +
              rightTranslationX -
              20 -
              (props.task.assignees.length > 0 &&
              (props.organization.plan.code === 'Business' ||
                props.organization.plan.code === 'Enterprise')
                ? 20
                : 0),
          }}
          ellipsis={true}>
          {props.task.title}
        </Typography>
      </View>

      <PanGestureHandler maxPointers={1} onGestureEvent={onGestureEventEndDate}>
        <GanttRowRightHandle />
      </PanGestureHandler>

      {props.ghostStartDateTime && props.task.scheduledStartDateTime && (
        <View style={{ position: 'absolute', left: -5 }}>
          <Text
            style={{
              position: 'absolute',
              right: 0,
              top: 7,
              textAlign: 'right',
              color: themeContext.colors.description,
            }}>
            {props.ghostStartDateTime.format('YYYY/MM/DD')}
          </Text>
        </View>
      )}
      {props.ghostEndDateTime && props.task.scheduledEndDateTime && (
        <View style={{ position: 'absolute', right: -5 }}>
          <Text
            style={{
              position: 'absolute',
              left: 0,
              top: 7,
              textAlign: 'left',
              color: themeContext.colors.description,
            }}>
            {props.ghostEndDateTime.format('YYYY/MM/DD')}
          </Text>
        </View>
      )}
    </GanttItem>
  );
});

interface IGrantItemDragInfo {
  startDateTime: moment.Moment | null;
  endDateTime: moment.Moment | null;
}

interface IGanttRowProps {
  organization: Organization;
  task: Task;
  adjustedEstimateStartDate: moment.Moment;
  adjustedEstimateEndDate: moment.Moment;
  ganttStartDate: moment.Moment;
  dateScale: number;
  onDrop: (info: IGrantItemDragInfo) => void;
  onChangeProgressRate: (progressRate: number) => void;
}

const GanttRow = React.memo((props: IGanttRowProps) => {
  const [startDateTime, setStartDateTime] = useState<Date | null>(
    props.task.scheduledStartDateTime
  );
  const [endDateTime, setEndDateTime] = useState(props.task.scheduledEndDateTime);
  const [progressPercent, setProgressPercent] = useState(props.task.progressRate || 0);
  const [translationX, setTranslationX] = useState(0);
  const [dragHandle, setDragHandle] = useState(false);
  const [dragItem, setDragItem] = useState(false);
  const [ghostStartDateTime, setGhostStartDateTime] = useState<moment.Moment | null>(null);
  const [ghostEndDateTime, setGhostEndDateTime] = useState<moment.Moment | null>(null);

  const updateGhostDate = _.debounce((start, end) => {
    setGhostStartDateTime(start);
    setGhostEndDateTime(end);
  }, 200);

  useEffect(() => {
    setStartDateTime(props.task.scheduledStartDateTime);
  }, [props.task.scheduledStartDateTime]);
  useEffect(() => {
    setEndDateTime(props.task.scheduledEndDateTime);
  }, [props.task.scheduledEndDateTime]);
  useEffect(() => {
    setProgressPercent(props.task.progressRate || 0);
  }, [props.task.progressRate]);

  const onGestureEvent = useCallback(
    (event) => {
      if (dragHandle) {
        return;
      }
      if (event.nativeEvent.state === State.BEGAN) {
        setDragItem(true);
        setTranslationX(0);
      }
      if (event.nativeEvent.state === State.ACTIVE) {
        const newStartDate = moment(props.adjustedEstimateStartDate)
          .add((event.nativeEvent.translationX / columnWidth) * props.dateScale, 'days')
          .startOf('day');

        const transX =
          (newStartDate.diff(moment(props.adjustedEstimateStartDate), 'seconds') /
            (24 * 60 * 60) /
            props.dateScale) *
          columnWidth;
        setTranslationX(transX);

        const moveSeconds = newStartDate.diff(moment(props.adjustedEstimateStartDate), 'seconds');
        const newEndDateTime = moment(props.adjustedEstimateEndDate).add(moveSeconds, 'seconds');

        updateGhostDate(newStartDate, newEndDateTime);
      }
      if (event.nativeEvent.state === State.END) {
        const newStartDateTime = moment(props.adjustedEstimateStartDate)
          .add((event.nativeEvent.translationX / columnWidth) * props.dateScale, 'days')
          .startOf('day');

        const moveSeconds = newStartDateTime.diff(
          moment(props.adjustedEstimateStartDate),
          'seconds'
        );
        const newEndDateTime = moment(props.adjustedEstimateEndDate).add(moveSeconds, 'seconds');

        setDragItem(false);
        setTranslationX(0);
        updateGhostDate(null, null);
        setStartDateTime(props.task.scheduledStartDateTime ? newStartDateTime.toDate() : null);
        setEndDateTime(newEndDateTime);

        props.onDrop({
          startDateTime: props.task.scheduledStartDateTime ? moment(newStartDateTime) : null,
          endDateTime: props.task.scheduledEndDateTime ? moment(newEndDateTime) : null,
        });
      }
    },
    [
      props.onDrop,
      setTranslationX,
      setStartDateTime,
      setEndDateTime,
      setDragItem,
      dragHandle,
      translationX,
      columnWidth,
      props.dateScale,
      startDateTime,
      endDateTime,
      props.adjustedEstimateStartDate,
      props.adjustedEstimateEndDate,
    ]
  );

  const setDragHandleFunc = useCallback(
    (value) => {
      if (value === true) {
        setDragHandle(value);
      } else {
        //進捗率の変更を完了する場合には、少し遅らせないと、ガントチャートのアイテム自体のドロップイベントが発生してしまって、2回連続してタスクの更新が発生して排他エラーになってしまう
        setTimeout(() => setDragHandle(value), 100);
      }
    },
    [setDragHandle]
  );

  const onChangeDate = useCallback(
    (start, end) => {
      if (dragItem) {
        return;
      }
      setStartDateTime(start);
      setEndDateTime(end);

      props.onDrop({
        startDateTime: start ? moment(start) : null,
        endDateTime: moment(end),
      });
    },
    [setStartDateTime, setEndDateTime, dragItem, props.onDrop]
  );

  const onChangeProgressPercent = useCallback(
    (value) => {
      setProgressPercent(value);
    },
    [setProgressPercent]
  );

  return (
    <PanGestureHandler maxPointers={1} onGestureEvent={onGestureEvent}>
      <NonForwardedRefAnimatedView
        style={{
          position: 'absolute',
          left: 0,
          top: 5,
          height: 45,
          cursor: dragItem ? 'grabbing' : 'grab',
        }}>
        <GanttInnerRow
          organization={props.organization}
          task={props.task}
          startDateTime={startDateTime}
          endDateTime={endDateTime}
          progressPercent={progressPercent}
          ganttStartDate={props.ganttStartDate}
          dateScale={props.dateScale}
          translationX={translationX}
          setDragHandle={setDragHandleFunc}
          onChangeDate={onChangeDate}
          onChangeProgressPercent={onChangeProgressPercent}
          onDropProgressPercent={props.onChangeProgressRate}
          ghostStartDateTime={ghostStartDateTime}
          ghostEndDateTime={ghostEndDateTime}
          updateGhostDate={updateGhostDate}
        />
      </NonForwardedRefAnimatedView>
    </PanGestureHandler>
  );
});

interface IDateLineContainerProps {
  dateScale: number;
  startDate: moment.Moment;
  index: number;
}

const DateLineContainer = React.memo((props: IDateLineContainerProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const startOfDayColumn = props.dateScale >= 1 || props.index % (1 / props.dateScale) === 0;
  const currentDate = moment(props.startDate).add(props.index * props.dateScale, 'days');
  const isWeekEnd = currentDate.weekday() === 6 || currentDate.weekday() === 0;
  const isToday =
    (props.dateScale === 1 && currentDate.format('YYYY/MM/DD') === moment().format('YYYY/MM/DD')) ||
    (props.dateScale === 7 &&
      currentDate.format('YYYY') === moment().format('YYYY') &&
      currentDate.format('w') === moment().format('w'));
  return (
    <DateLine isStartOfDayColumn={startOfDayColumn}>
      {startOfDayColumn && (
        <>
          <View
            style={{
              position: 'absolute',
              top: 0,
              height: 10000,
              width: 1,
              backgroundColor: themeContext.colors.separator,
              zIndex: 100,
            }}
          />
          {props.dateScale === 1 && !isToday && isWeekEnd && (
            <View
              style={{
                position: 'absolute',
                top: 0,
                height: 10000,
                width: 60,
                backgroundColor: '#f8f8f8',
                zIndex: 99,
              }}
            />
          )}
          {isToday && (
            <View
              style={{
                position: 'absolute',
                top: 0,
                height: 10000,
                width: 60,
                backgroundColor: '#dbeeff',
                zIndex: 99,
              }}
            />
          )}
        </>
      )}
    </DateLine>
  );
});

interface IGhostItem {
  show: boolean;
  task: Task;
  posX: number;
  dateScale: number;
  ganttContainerWrapperPosX: number;
  startDate: moment.Moment;
}

const GhostItem = React.memo((props: IGhostItem) => {
  const [dragging, setDragging] = useState(false);
  const [translationX, setTranslationX] = useState(0);
  const [dragStartPosX, setDragStartPosX] = useState(0);
  const [ghostStartDateTime, setGhostStartDateTime] = useState<moment.Moment | null>(null);
  const [ghostEndDateTime, setGhostEndDateTime] = useState<moment.Moment | null>(null);
  const [updateTask, updateTaskResult] = useUpdateTaskMutation();

  useEffect(() => {
    if (!props.show) {
      setGhostStartDateTime(null);
      setGhostEndDateTime(null);
    }
  }, [props.show]);

  const updateGhostDate = _.debounce((event: any) => {
    if (event.nativeEvent.translationX >= 0) {
      const start = moment(props.startDate)
        .add((dragStartPosX / columnWidth) * props.dateScale, 'days')
        .startOf('day');
      const end = moment(start)
        .add(Math.floor(event.nativeEvent.translationX / (columnWidth / props.dateScale)), 'days')
        .add(1, 'day')
        .add(-1, 'second');

      setGhostStartDateTime(start);
      setGhostEndDateTime(end);
    } else {
      const end = moment(props.startDate)
        .add((dragStartPosX / columnWidth) * props.dateScale, 'days')
        .add(-1, 'second');
      const start = moment(end)
        .add(Math.ceil(event.nativeEvent.translationX / (columnWidth / props.dateScale)), 'days')
        .add(1, 'days')
        .startOf('day');

      setGhostStartDateTime(start);
      setGhostEndDateTime(end);
    }
  }, 200);

  if (
    (props.show || dragging) &&
    !(props.task.scheduledStartDateTime || props.task.scheduledEndDateTime)
  ) {
    return (
      <PanGestureHandler
        maxPointers={1}
        onGestureEvent={(event) => {
          if (event.nativeEvent.state === State.BEGAN) {
            const startPosX =
              Math.floor(props.posX / (columnWidth / props.dateScale)) *
              (columnWidth / props.dateScale);
            setDragStartPosX(startPosX);
            setTranslationX(0);
            setDragging(true);
            const start = moment(props.startDate)
              .add((startPosX / columnWidth) * props.dateScale, 'days')
              .startOf('day');
            setGhostStartDateTime(start);
            setGhostEndDateTime(moment(start).add(1, 'day').add(-1, 'second'));
          }
          if (event.nativeEvent.state === State.ACTIVE) {
            setTranslationX(
              Math.ceil(event.nativeEvent.translationX / (columnWidth / props.dateScale)) *
                (columnWidth / props.dateScale)
            );
            updateGhostDate(event);
          }
          if (event.nativeEvent.state === State.END) {
            const tmpStartDateTime = moment(props.startDate).add(
              (dragStartPosX / columnWidth) * props.dateScale,
              'days'
            );
            const tmpEndDateTime = moment(tmpStartDateTime).add(
              Math.ceil(event.nativeEvent.translationX / (columnWidth / props.dateScale)),
              'days'
            );
            const startDateTime = tmpStartDateTime.isBefore(tmpEndDateTime)
              ? tmpStartDateTime
              : tmpEndDateTime;
            const endDateTime = tmpStartDateTime.isBefore(tmpEndDateTime)
              ? tmpEndDateTime
              : tmpStartDateTime;
            setDragging(false);
            setTranslationX(0);
            setDragStartPosX(0);
            setGhostStartDateTime(null);
            setGhostEndDateTime(null);
            updateTask({
              variables: {
                id: props.task.id!,
                input: {
                  title: props.task.title,
                  description: props.task.description,
                  assigneeIds: props.task.assignees.map((info) => info.member!.id!),
                  estimateTimeSec: props.task.estimateTimeSec,
                  priority: props.task.priority,
                  progressRate: props.task.progressRate,
                  scheduledStartDateTime: startDateTime.startOf('day').toISOString(),
                  scheduledEndDateTime: endDateTime.add(-1, 'second').toISOString(),
                  versionNo: props.task.versionNo,
                },
              },
            });
          }
        }}>
        <NonForwardedRefAnimatedView
          style={{
            height: 35,
            width: dragging ? (translationX >= 0 ? translationX : -1 * translationX) : columnWidth,
            backgroundColor: colorBlue,
            opacity: 0.2,
            position: 'absolute',
            left:
              (dragging
                ? translationX >= 0
                  ? dragStartPosX
                  : dragStartPosX + translationX
                : props.posX) - (dragging ? 0 : 10), // 最後に少し左にずらさないと、Chrome以外ではドラッグ範囲がギリギリになり、ドラッグ開始が判定されない。そのためドラッグ開始していない場合だけ、10px左にずらしている
          }}>
          {ghostStartDateTime && (
            <View style={{ position: 'absolute', left: -5 }}>
              <Text style={{ position: 'absolute', right: 0, top: 7, textAlign: 'right' }}>
                {ghostStartDateTime.format('YYYY/MM/DD')}
              </Text>
            </View>
          )}
          {ghostEndDateTime && (
            <View style={{ position: 'absolute', right: -5 }}>
              <Text style={{ position: 'absolute', left: 0, top: 7, textAlign: 'left' }}>
                {ghostEndDateTime.format('YYYY/MM/DD')}
              </Text>
            </View>
          )}
        </NonForwardedRefAnimatedView>
      </PanGestureHandler>
    );
  }
  return <></>;
});

interface IGanttRowGuideLinkProps {
  task: Task;
  startDate: moment.Moment;
  endDate: moment.Moment;
  dateScale: number;
  ganttContainerWrapperWidth: number;
  moveToDate: (value: moment.Moment) => void;
}

const GanttRowGuideLink = React.memo((props: IGanttRowGuideLinkProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [displayStartDate, setDisplayStartDate] = useState(props.startDate);
  const [displayEndDate, setDisplayEndDate] = useState(props.endDate);
  const adjustedScheduledStartDate = props.task.scheduledStartDateTime
    ? moment(props.task.scheduledStartDateTime)
    : moment(props.task.scheduledEndDateTime).add(-2, 'day').startOf('day');

  const dispatchGanntChartScrollAndDisplayStartDateChanged = _.debounce(
    (displayStartDate: moment.Moment) => {
      window.dispatchEvent(
        new CustomEvent('GanntChartScrollAndDisplayStartDateChanged', {
          detail: {
            displayStartDate: displayStartDate,
          },
        } as any)
      );
    },
    500
  );

  const onScrollHorizontalEnd = useCallback(
    (e: any) => {
      const displayStartDate = moment(props.startDate).add(
        Math.floor(e.detail.contentOffsetX / columnWidth) * props.dateScale,
        'days'
      );
      setDisplayStartDate(displayStartDate);
      setDisplayEndDate(
        moment(displayStartDate).add(
          Math.ceil(props.ganttContainerWrapperWidth / columnWidth) * props.dateScale,
          'days'
        )
      );
      dispatchGanntChartScrollAndDisplayStartDateChanged(displayStartDate);
    },
    [
      props.startDate,
      props.ganttContainerWrapperWidth,
      setDisplayStartDate,
      setDisplayEndDate,
      displayStartDate,
    ]
  );

  useEffect(() => {
    window.addEventListener('ganttChartHorizontalScrollFinished', onScrollHorizontalEnd);
    return () => {
      window.removeEventListener('ganttChartHorizontalScrollFinished', onScrollHorizontalEnd);
    };
  }, [
    props.startDate,
    props.dateScale,
    props.ganttContainerWrapperWidth,
    setDisplayStartDate,
    setDisplayEndDate,
  ]);

  return (
    <>
      {adjustedScheduledStartDate &&
        props.task.scheduledEndDateTime &&
        (displayStartDate || props.startDate).isAfter(props.task.scheduledEndDateTime) && (
          <View
            style={
              {
                position: 'sticky',
                left: 10,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 10,
              } as any
            }>
            <DoubleLeftIcon
              size={16}
              containerStyle={{ marginLeft: 5 }}
              onPress={() => {
                props.moveToDate(adjustedScheduledStartDate);
              }}
            />
            <Typography
              variant={TypographyType.Normal}
              style={{
                color: themeContext.colors.description,
                fontSize: 12,
                lineHeight: 20,
                textAlign: 'left',
                maxWidth: 150,
              }}
              ellipsis={true}>
              {props.task.title}
            </Typography>
          </View>
        )}
      {adjustedScheduledStartDate &&
        props.task.scheduledEndDateTime &&
        (displayEndDate || props.endDate).isBefore(adjustedScheduledStartDate) && (
          <View
            style={
              {
                position: 'sticky',
                left: props.ganttContainerWrapperWidth - 30,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              } as any
            }>
            <Typography
              variant={TypographyType.Normal}
              style={{
                color: themeContext.colors.description,
                fontSize: 12,
                lineHeight: 20,
                textAlign: 'right',
                maxWidth: 150,
                position: 'absolute',
                right: 20,
              }}
              ellipsis={true}>
              {props.task.title}
            </Typography>
            <DoubleRightIcon
              size={16}
              containerStyle={{ marginLeft: 5 }}
              onPress={() => {
                props.moveToDate(adjustedScheduledStartDate);
              }}
            />
          </View>
        )}
    </>
  );
});

interface GanttChartItemContextMenuInfo {
  x: number;
  y: number;
}

interface IRightRowProps {
  organization: Organization;
  task: Task;
  dateScale: number;
  ganttContainerWrapperPosX: number;
  ganttContainerWrapperWidth: number;
  startDate: moment.Moment;
  endDate: moment.Moment;
  rightBodyScrollViewRef: any;
  drawStartDate: moment.Moment;
  drawEndDate: moment.Moment;
  dateCellIndexes: Array<number>;
  index: number;
  moveToDate: (value: moment.Moment) => void;
}

const RightRow = React.memo((props: IRightRowProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const ref = useRef();
  const [loginUser, _] = useContext(LoginUserContext);
  const displayStartDate = props.task.scheduledStartDateTime
    ? moment(props.task.scheduledStartDateTime)
    : moment(props.task.scheduledEndDateTime).add(-2, 'day').startOf('day');
  const displayEndDate = props.task.scheduledEndDateTime
    ? moment(props.task.scheduledEndDateTime)
    : moment(displayStartDate).add(2, 'day').endOf('day');
  const [posX, setPosX] = useState(0);
  const [showGhost, setShowGhost] = useState(false);
  const [contextMenu, setContextMenu] = useState<GanttChartItemContextMenuInfo | null>(null);
  const [updateTask, updateTaskResult] = useUpdateTaskMutation();

  useEffect(() => {
    (ref as any).current.style.position = `absolute`;
    (ref as any).current.style.width = `100%`;
    (ref as any).current.style.top = `${props.index * itemHeight}px`;
  }, [props.index]);

  return (
    <>
      {contextMenu && (
        <Overlay onPress={() => setContextMenu(null)}>
          <DeleteIcon
            size={23}
            containerStyle={{
              height: 30,
              backgroundColor: '#FFFFFF',
              position: 'absolute',
              top: contextMenu?.y + 10,
              left: contextMenu?.x - 10,
              opacity: 1,
              flexDirection: 'row',
              borderWidth: 1,
              borderColor: themeContext.colors.separator,
              paddingHorizontal: 5,
              paddingVertical: 5,
            }}
            onPress={(e) => {
              e.preventDefault();
              setContextMenu(null);
              updateTask({
                variables: {
                  id: props.task.id!,
                  input: {
                    title: props.task.title,
                    description: props.task.description,
                    assigneeIds: props.task.assignees.map((info) => info.member!.id!),
                    estimateTimeSec: props.task.estimateTimeSec,
                    priority: props.task.priority,
                    progressRate: props.task.progressRate,
                    scheduledStartDateTime: null,
                    scheduledEndDateTime: null,
                    versionNo: props.task.versionNo,
                  },
                },
              });
            }}>
            <Text style={{ fontSize: 12 }}>開始・終了日を削除する</Text>
          </DeleteIcon>
        </Overlay>
      )}

      <div
        onMouseEnter={(e) => {
          if ((props.rightBodyScrollViewRef.current as any).getNativeScrollRef()) {
            setPosX(
              (props.rightBodyScrollViewRef.current as any).getNativeScrollRef().scrollLeft +
                e.clientX -
                props.ganttContainerWrapperPosX
            );
          }
          setShowGhost(false);
        }}
        onMouseMove={(e) => {
          if ((props.rightBodyScrollViewRef.current as any).getNativeScrollRef()) {
            setPosX(
              (props.rightBodyScrollViewRef.current as any).getNativeScrollRef().scrollLeft +
                e.clientX -
                props.ganttContainerWrapperPosX
            );
            setTimeout(() => {
              if (posX > 0) {
                setShowGhost(true);
              }
            }, 100);
          }
        }}
        onMouseLeave={(e) => {
          setPosX(-10000);
          setShowGhost(false);
        }}>
        <GanttRowContainer ref={ref as any}>
          <GanttRowGuideLink
            task={props.task}
            dateScale={props.dateScale}
            startDate={props.startDate}
            endDate={props.endDate}
            ganttContainerWrapperWidth={props.ganttContainerWrapperWidth}
            moveToDate={props.moveToDate}
          />
          {(props.task.scheduledStartDateTime || props.task.scheduledEndDateTime) &&
            //描画範囲内でなければ描画しないようにする
            (displayStartDate.isBetween(props.drawStartDate, props.drawEndDate) ||
              moment(props.task.scheduledEndDateTime).isBetween(
                props.drawStartDate,
                props.drawEndDate
              )) && (
              <div
                style={{ opacity: updateTaskResult.loading ? 0.2 : 1 }}
                onContextMenu={(e) => {
                  e.nativeEvent.preventDefault();
                  setContextMenu({
                    x:
                      (props.rightBodyScrollViewRef.current as any).getNativeScrollRef()
                        .scrollLeft +
                      e.clientX -
                      props.ganttContainerWrapperPosX,
                    y: props.index * itemHeight,
                  } as GanttChartItemContextMenuInfo);
                }}>
                <GanttRow
                  dateScale={props.dateScale}
                  ganttStartDate={props.drawStartDate}
                  organization={props.organization}
                  task={props.task}
                  adjustedEstimateStartDate={displayStartDate}
                  adjustedEstimateEndDate={displayEndDate}
                  onDrop={(info) => {
                    updateTask({
                      variables: {
                        id: props.task.id!,
                        input: {
                          title: props.task.title,
                          description: props.task.description,
                          assigneeIds: props.task.assignees.map((info) => info.member!.id!),
                          estimateTimeSec: props.task.estimateTimeSec,
                          priority: props.task.priority,
                          progressRate: props.task.progressRate,
                          scheduledStartDateTime: info.startDateTime
                            ? info.startDateTime.toISOString()
                            : null,
                          scheduledEndDateTime: info.endDateTime
                            ? info.endDateTime.toISOString()
                            : null,
                          versionNo: props.task.versionNo,
                        },
                      },
                    });
                  }}
                  onChangeProgressRate={(value) => {
                    updateTask({
                      variables: {
                        id: props.task.id!,
                        input: {
                          title: props.task.title,
                          description: props.task.description,
                          assigneeIds: props.task.assignees.map((info) => info.member!.id!),
                          estimateTimeSec: props.task.estimateTimeSec,
                          priority: props.task.priority,
                          progressRate: value,
                          scheduledStartDateTime: props.task.scheduledStartDateTime,
                          scheduledEndDateTime: props.task.scheduledEndDateTime,
                          versionNo: props.task.versionNo,
                        },
                      },
                    });
                  }}
                />
              </div>
            )}
          <GhostItem
            show={showGhost}
            task={props.task}
            posX={posX}
            dateScale={props.dateScale}
            startDate={props.startDate}
            ganttContainerWrapperPosX={props.ganttContainerWrapperPosX}
          />
        </GanttRowContainer>
      </div>
    </>
  );
});

interface IDateCellContainerProps {
  dateScale: number;
  startDate: moment.Moment;
  endDate: moment.Moment;
  index: number;
}

const DateCellContainer = React.memo((props: IDateCellContainerProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const targetDate = moment(props.startDate).add(props.index * props.dateScale, 'days');
  const startOfDayColumn = props.dateScale >= 1 || props.index % (1 / props.dateScale) === 0;
  const currentDate = moment(props.startDate).add(props.index * props.dateScale, 'days');
  const isWeekEnd = currentDate.weekday() === 6 || currentDate.weekday() === 0;
  const isToday =
    (props.dateScale === 1 && currentDate.format('YYYY/MM/DD') === moment().format('YYYY/MM/DD')) ||
    (props.dateScale === 7 &&
      currentDate.format('YYYY') === moment().format('YYYY') &&
      currentDate.format('w') === moment().format('w'));
  return (
    <DateCell
      isStartOfDayColumn={startOfDayColumn}
      isWeekEnd={props.dateScale === 1 && isWeekEnd}
      isToday={isToday}>
      {startOfDayColumn && (
        <View
          style={{
            flexDirection: 'column',
          }}>
          <Text style={{ textAlign: 'center', fontSize: 12 }}>{targetDate.format('M/D')}</Text>
          <Text
            style={{
              textAlign: 'center',
              fontSize: 12,
            }}>
            {targetDate.format(props.dateScale === 7 ? 'w[w]' : '(ddd)')}
          </Text>
        </View>
      )}
    </DateCell>
  );
});

interface IHeaderContainerProps {
  organization: Organization;
  titleHeaderScrollViewRef: any;
  titleBodyScrollViewRef: any;
  rightBodyContainerRef: any;
  leftHeaderScrollViewRef: any;
  rightHeaderScrollViewRef: any;
  leftBodyScrollViewRef: any;
  rightBodyScrollViewRef: any;
  onScrollHead: (event: any) => void;
  dateCellIndexes: Array<number>;
  startDate: moment.Moment;
  endDate: moment.Moment;
  dateScale: number;
  sortKey: SortKey | null;
  setSortKey: (value: SortKey | null) => void;
  sortOrder: SortOrder | null;
  setSortOrder: (value: SortOrder | null) => void;
  taskTitleWidthTranslation: Animated.Value;
  taskInfoWidthTranslation: Animated.Value;
}

const HeaderContainer = React.memo((props: IHeaderContainerProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const renderItem = useCallback(
    (_, i) => {
      return (
        <DateCellContainer
          dateScale={props.dateScale}
          startDate={props.startDate}
          endDate={props.endDate}
          index={i}
          key={i}
        />
      );
    },
    [props.dateScale, props.startDate]
  );

  const setSortInfo = (sortKey: SortKey) => {
    if (props.sortKey !== sortKey) {
      props.setSortKey(sortKey);
      props.setSortOrder(SortOrder.Asc);
    } else {
      if (props.sortOrder === SortOrder.Asc) {
        props.setSortKey(sortKey);
        props.setSortOrder(SortOrder.Desc);
      } else {
        props.setSortKey(null);
        props.setSortOrder(null);
      }
    }
  };

  return (
    <HeaderWrapper>
      <Main>
        <Animated.View
          style={{
            width: props.taskTitleWidthTranslation,
            minWidth: 50,
            maxWidth: 500,
          }}>
          <CustomScrollView
            style={{
              shadowOffset: {
                width: -1,
                height: -1,
              },
              shadowOpacity: 0.1,
            }}
            scrollViewRef={props.titleHeaderScrollViewRef}
            horizontal={true}
            scrollEventThrottle={1}
            additionalOnScroll={({ nativeEvent }: NativeSyntheticEvent<NativeScrollEvent>) => {
              if (props.titleBodyScrollViewRef.current?.getNativeScrollRef()) {
                (props.titleBodyScrollViewRef.current.getNativeScrollRef() as any).scrollLeft =
                  nativeEvent.contentOffset.x;
              }
            }}>
            <TitleHeader>
              <TouchableOpacity
                style={{ flexDirection: 'row' }}
                onPress={() => setSortInfo(SortKey.Title)}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    color: '#FFFFFF',
                    fontSize: 12,
                    textAlign: 'left',
                    flex: 1,
                  }}>
                  タスク名
                </Typography>
                {props.sortKey === SortKey.Title ? (
                  <>
                    {props.sortOrder === SortOrder.Asc ? (
                      <CaretUpIcon
                        size={12}
                        reverse={true}
                        containerStyle={{ marginLeft: 5 }}
                        onPress={() => setSortInfo(SortKey.Title)}
                      />
                    ) : (
                      <CaretDownIcon
                        size={12}
                        reverse={true}
                        containerStyle={{ marginLeft: 5 }}
                        onPress={() => setSortInfo(SortKey.Title)}
                      />
                    )}
                  </>
                ) : (
                  <CaretSetIcon
                    size={16}
                    reverse={true}
                    containerStyle={{
                      opacity: 0.2,
                      marginLeft: 5,
                    }}
                    onPress={() => setSortInfo(SortKey.Title)}
                  />
                )}
              </TouchableOpacity>
            </TitleHeader>
          </CustomScrollView>
        </Animated.View>
        <View style={{ width: 3, backgroundColor: themeContext.colors.header }} />
        <Animated.View
          style={{
            width: props.taskInfoWidthTranslation,
            minWidth: 50,
            maxWidth: 540,
            overflow: 'hidden',
          }}>
          <CustomScrollView
            style={{
              shadowOffset: {
                width: -1,
                height: -1,
              },
              shadowOpacity: 0.1,
            }}
            scrollViewRef={props.leftHeaderScrollViewRef}
            horizontal={true}
            scrollEventThrottle={1}
            additionalOnScroll={({ nativeEvent }: NativeSyntheticEvent<NativeScrollEvent>) => {
              if (props.leftBodyScrollViewRef.current?.getNativeScrollRef()) {
                (props.leftBodyScrollViewRef.current.getNativeScrollRef() as any).scrollLeft =
                  nativeEvent.contentOffset.x;
              }
            }}>
            <LeftContainerInner>
              <Header>
                {(props.organization.plan.code === 'Business' ||
                  props.organization.plan.code === 'Enterprise') && (
                  <TouchableOpacity
                    style={{
                      flexDirection: 'row',
                      width: 60,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    onPress={() => setSortInfo(SortKey.Assigner)}>
                    <Typography
                      variant={TypographyType.Normal}
                      style={{
                        color: '#FFFFFF',
                        fontSize: 12,
                        textAlign: 'center',
                      }}>
                      担当者
                    </Typography>
                    {props.sortKey === SortKey.Assigner ? (
                      <>
                        {props.sortOrder === SortOrder.Asc ? (
                          <CaretUpIcon
                            size={10}
                            reverse={true}
                            containerStyle={{ marginLeft: 3 }}
                            onPress={() => setSortInfo(SortKey.Assigner)}
                          />
                        ) : (
                          <CaretDownIcon
                            size={10}
                            reverse={true}
                            containerStyle={{ marginLeft: 3 }}
                            onPress={() => setSortInfo(SortKey.Assigner)}
                          />
                        )}
                      </>
                    ) : (
                      <CaretSetIcon
                        size={12}
                        reverse={true}
                        containerStyle={{
                          opacity: 0.2,
                          marginLeft: 3,
                        }}
                        onPress={() => setSortInfo(SortKey.Assigner)}
                      />
                    )}
                  </TouchableOpacity>
                )}
                <TouchableOpacity
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingLeft: 5,
                    width: 60,
                  }}
                  onPress={() => setSortInfo(SortKey.Priority)}>
                  <Typography
                    variant={TypographyType.Normal}
                    style={{
                      color: '#FFFFFF',
                      fontSize: 12,
                      textAlign: 'center',
                    }}>
                    優先度
                  </Typography>
                  {props.sortKey === SortKey.Priority ? (
                    <>
                      {props.sortOrder === SortOrder.Asc ? (
                        <CaretUpIcon
                          size={10}
                          reverse={true}
                          containerStyle={{ marginLeft: 3 }}
                          onPress={() => setSortInfo(SortKey.Priority)}
                        />
                      ) : (
                        <CaretDownIcon
                          size={10}
                          reverse={true}
                          containerStyle={{ marginLeft: 3 }}
                          onPress={() => setSortInfo(SortKey.Priority)}
                        />
                      )}
                    </>
                  ) : (
                    <CaretSetIcon
                      size={12}
                      reverse={true}
                      containerStyle={{
                        opacity: 0.2,
                        marginLeft: 3,
                      }}
                      onPress={() => setSortInfo(SortKey.Priority)}
                    />
                  )}
                </TouchableOpacity>
                <TouchableOpacity
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingLeft: 5,
                    width: 80,
                  }}
                  onPress={() => setSortInfo(SortKey.WorkingTimeSec)}>
                  <Typography
                    variant={TypographyType.Normal}
                    style={{
                      color: '#FFFFFF',
                      fontSize: 12,
                      textAlign: 'center',
                    }}>
                    作業時間
                  </Typography>
                  {props.sortKey === SortKey.WorkingTimeSec ? (
                    <>
                      {props.sortOrder === SortOrder.Asc ? (
                        <CaretUpIcon
                          size={10}
                          reverse={true}
                          containerStyle={{ marginLeft: 3 }}
                          onPress={() => setSortInfo(SortKey.WorkingTimeSec)}
                        />
                      ) : (
                        <CaretDownIcon
                          size={10}
                          reverse={true}
                          containerStyle={{ marginLeft: 3 }}
                          onPress={() => setSortInfo(SortKey.WorkingTimeSec)}
                        />
                      )}
                    </>
                  ) : (
                    <CaretSetIcon
                      size={12}
                      reverse={true}
                      containerStyle={{
                        opacity: 0.2,
                        marginLeft: 3,
                      }}
                      onPress={() => setSortInfo(SortKey.WorkingTimeSec)}
                    />
                  )}
                </TouchableOpacity>
                <TouchableOpacity
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingLeft: 5,
                    width: 80,
                  }}
                  onPress={() => setSortInfo(SortKey.EstimateWorkingTimeSec)}>
                  <Typography
                    variant={TypographyType.Normal}
                    style={{
                      color: '#FFFFFF',
                      fontSize: 12,
                      textAlign: 'center',
                    }}>
                    見積時間
                  </Typography>
                  {props.sortKey === SortKey.EstimateWorkingTimeSec ? (
                    <>
                      {props.sortOrder === SortOrder.Asc ? (
                        <CaretUpIcon
                          size={10}
                          reverse={true}
                          containerStyle={{ marginLeft: 3 }}
                          onPress={() => setSortInfo(SortKey.EstimateWorkingTimeSec)}
                        />
                      ) : (
                        <CaretDownIcon
                          size={10}
                          reverse={true}
                          containerStyle={{ marginLeft: 3 }}
                          onPress={() => setSortInfo(SortKey.EstimateWorkingTimeSec)}
                        />
                      )}
                    </>
                  ) : (
                    <CaretSetIcon
                      size={12}
                      reverse={true}
                      containerStyle={{
                        opacity: 0.2,
                        marginLeft: 3,
                      }}
                      onPress={() => setSortInfo(SortKey.EstimateWorkingTimeSec)}
                    />
                  )}
                </TouchableOpacity>
                <TouchableOpacity
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingLeft: 5,
                    width: 90,
                  }}
                  onPress={() => setSortInfo(SortKey.ScheduledStartDate)}>
                  <Typography
                    variant={TypographyType.Normal}
                    style={{
                      color: '#FFFFFF',
                      fontSize: 12,
                      textAlign: 'center',
                    }}>
                    開始予定日
                  </Typography>
                  {props.sortKey === SortKey.ScheduledStartDate ? (
                    <>
                      {props.sortOrder === SortOrder.Asc ? (
                        <CaretUpIcon
                          size={10}
                          reverse={true}
                          containerStyle={{ marginLeft: 3 }}
                          onPress={() => setSortInfo(SortKey.ScheduledStartDate)}
                        />
                      ) : (
                        <CaretDownIcon
                          size={10}
                          reverse={true}
                          containerStyle={{ marginLeft: 3 }}
                          onPress={() => setSortInfo(SortKey.ScheduledStartDate)}
                        />
                      )}
                    </>
                  ) : (
                    <CaretSetIcon
                      size={12}
                      reverse={true}
                      containerStyle={{
                        opacity: 0.2,
                        marginLeft: 3,
                      }}
                      onPress={() => setSortInfo(SortKey.ScheduledStartDate)}
                    />
                  )}
                </TouchableOpacity>
                <TouchableOpacity
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingLeft: 5,
                    width: 90,
                  }}
                  onPress={() => setSortInfo(SortKey.ScheduledEndDate)}>
                  <Typography
                    variant={TypographyType.Normal}
                    style={{
                      color: '#FFFFFF',
                      fontSize: 12,
                      textAlign: 'center',
                    }}>
                    〆切日
                  </Typography>
                  {props.sortKey === SortKey.ScheduledEndDate ? (
                    <>
                      {props.sortOrder === SortOrder.Asc ? (
                        <CaretUpIcon
                          size={10}
                          reverse={true}
                          containerStyle={{ marginLeft: 3 }}
                          onPress={() => setSortInfo(SortKey.ScheduledEndDate)}
                        />
                      ) : (
                        <CaretDownIcon
                          size={10}
                          reverse={true}
                          containerStyle={{ marginLeft: 3 }}
                          onPress={() => setSortInfo(SortKey.ScheduledEndDate)}
                        />
                      )}
                    </>
                  ) : (
                    <CaretSetIcon
                      size={12}
                      reverse={true}
                      containerStyle={{
                        opacity: 0.2,
                        marginLeft: 3,
                      }}
                      onPress={() => setSortInfo(SortKey.ScheduledEndDate)}
                    />
                  )}
                </TouchableOpacity>
                <TouchableOpacity
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingLeft: 5,
                    width: 60,
                  }}
                  onPress={() => setSortInfo(SortKey.ProgressRate)}>
                  <Typography
                    variant={TypographyType.Normal}
                    style={{
                      color: '#FFFFFF',
                      fontSize: 12,
                      textAlign: 'center',
                    }}>
                    進捗率
                  </Typography>
                  {props.sortKey === SortKey.ProgressRate ? (
                    <>
                      {props.sortOrder === SortOrder.Asc ? (
                        <CaretUpIcon
                          size={10}
                          reverse={true}
                          containerStyle={{ marginLeft: 3 }}
                          onPress={() => setSortInfo(SortKey.ProgressRate)}
                        />
                      ) : (
                        <CaretDownIcon
                          size={10}
                          reverse={true}
                          containerStyle={{ marginLeft: 3 }}
                          onPress={() => setSortInfo(SortKey.ProgressRate)}
                        />
                      )}
                    </>
                  ) : (
                    <CaretSetIcon
                      size={12}
                      reverse={true}
                      containerStyle={{
                        opacity: 0.2,
                        marginLeft: 3,
                      }}
                      onPress={() => setSortInfo(SortKey.ProgressRate)}
                    />
                  )}
                </TouchableOpacity>
              </Header>
            </LeftContainerInner>
          </CustomScrollView>
        </Animated.View>
        <View style={{ width: 3, backgroundColor: themeContext.colors.subHeader }} />
        <RightContainerWrapper ref={props.rightBodyContainerRef}>
          <CustomScrollView
            style={{
              flex: 2,
              zIndex: 1,
            }}
            scrollViewRef={props.rightHeaderScrollViewRef}
            horizontal={true}
            scrollEventThrottle={1}
            additionalOnScroll={props.onScrollHead}>
            <RightContainerInner dateColumnCount={props.dateCellIndexes.length}>
              <DateHeader>
                <View
                  style={{
                    flexDirection: 'column',
                  }}>
                  <View
                    style={{
                      flexDirection: 'row',
                      width: (props.endDate.diff(props.startDate) / props.dateScale) * columnWidth,
                    }}>
                    <View
                      style={
                        {
                          position: 'sticky',
                          left: 0,
                          height: 20,
                          paddingLeft: 10,
                          width:
                            (moment(
                              moment(props.startDate).add(1, 'year').format('YYYY/01/01')
                            ).diff(props.startDate, 'days') /
                              props.dateScale) *
                            columnWidth,
                          borderTopWidth: 3,
                          borderTopColor: themeContext.colors.separator,
                          backgroundColor: '#FFFFFF',
                        } as any
                      }>
                      <Text style={{ textAlign: 'left', fontSize: 12, height: 15 }}>
                        {props.startDate.format('YYYY年')}
                      </Text>
                    </View>
                    <View
                      style={
                        {
                          position: 'sticky',
                          left: 0,
                          height: 20,
                          paddingLeft: 10,
                          width:
                            (moment(
                              moment(props.startDate).add(2, 'year').format('YYYY/01/01')
                            ).diff(
                              moment(moment(props.startDate).add(1, 'year').format('YYYY/01/01')),
                              'days'
                            ) /
                              props.dateScale) *
                            columnWidth,
                          borderTopWidth: 3,
                          borderTopColor: themeContext.colors.separator,
                          backgroundColor: '#FFFFFF',
                        } as any
                      }>
                      <Text
                        style={{
                          textAlign: 'left',
                          fontSize: 12,
                          height: 15,
                        }}>
                        {moment(props.startDate).add(1, 'year').format('YYYY年')}
                      </Text>
                    </View>
                    <View
                      style={
                        {
                          position: 'sticky',
                          left: 0,
                          height: 20,
                          paddingLeft: 10,
                          width:
                            (props.endDate.diff(
                              moment(moment(props.startDate).add(2, 'year').format('YYYY/01/01')),
                              'days'
                            ) /
                              props.dateScale) *
                            columnWidth,
                          borderTopWidth: 3,
                          borderTopColor: themeContext.colors.separator,
                          backgroundColor: '#FFFFFF',
                        } as any
                      }>
                      <Text style={{ textAlign: 'left', fontSize: 12, height: 15 }}>
                        {moment(props.startDate).add(2, 'year').format('YYYY年')}
                      </Text>
                    </View>
                  </View>

                  <View style={{ flexDirection: 'row' }}>
                    {props.dateCellIndexes.map(renderItem)}
                  </View>
                </View>
              </DateHeader>
            </RightContainerInner>
          </CustomScrollView>
        </RightContainerWrapper>
      </Main>
    </HeaderWrapper>
  );
});

interface ITaskTitleColumnBodyProps {
  organization: Organization;
  tasks: Array<Task>;
  allTaskCount: number;
  titleHeaderScrollViewRef: any;
  titleBodyScrollViewRef: any;
  leftHeaderScrollViewRef: any;
  leftBodyScrollViewRef: any;
  chunkStartIndex: number;
  sortKey: SortKey | null;
  setDraggingTaskIndex: (value: number | null) => void;
  taskTitleWidth: number;
  setTaskTitleWidth: (value: number) => void;
  taskTitleWidthTranslation: Animated.Value;
}

const TaskTitleColumnBody = (props: ITaskTitleColumnBodyProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [updateTaskSortNo, _] = useUpdateTaskSortNoInGanttChartMutation();
  const renderTitleHeader = useCallback(
    (task, i) => (
      <TaskTitleRow
        organization={props.organization}
        task={task!}
        allTaskCount={props.allTaskCount}
        sortKey={props.sortKey}
        setDraggingTaskIndex={props.setDraggingTaskIndex}
        onDrop={(targetTask: Task, index) => {
          const adjustedIndex = index - props.chunkStartIndex;
          let aboveTask;
          if (adjustedIndex <= 0) {
            aboveTask = props.tasks[0];
          } else {
            aboveTask = props.tasks[adjustedIndex];
          }

          let bottomTask;
          if (adjustedIndex + 1 >= props.tasks.length - 1) {
            bottomTask = props.tasks[props.tasks.length - 1];
          } else {
            bottomTask = props.tasks[adjustedIndex + 1];
          }

          let sortNo;
          const isMoveToFirst = adjustedIndex < 0;
          const isMoveToLast = adjustedIndex + 1 >= props.tasks.length - 1;
          if (isMoveToFirst) {
            sortNo = new Date().getTime();
          } else if (isMoveToLast) {
            sortNo = props.tasks[props.tasks.length - 1]!.sortNoInGanttChart - 1000;
          } else {
            sortNo = Math.floor(
              (aboveTask!.sortNoInGanttChart + bottomTask!.sortNoInGanttChart) / 2
            );
          }

          updateTaskSortNo({
            variables: {
              id: targetTask.id!,
              input: {
                sortNoInGanttChart: sortNo,
                versionNo: targetTask.versionNo,
              },
            },
          });
        }}
        index={i + props.chunkStartIndex}
        key={i}
      />
    ),
    [props.organization, props.chunkStartIndex, props.tasks, updateTaskSortNo]
  );

  return (
    <>
      <Animated.View
        style={{
          width: props.taskTitleWidthTranslation,
          minWidth: 50,
          maxWidth: 500,
          height: props.allTaskCount * itemHeight,
        }}>
        <CustomScrollView
          style={{
            shadowOffset: {
              width: -1,
              height: -1,
            },
            shadowOpacity: 0.1,
          }}
          scrollViewRef={props.titleBodyScrollViewRef}
          horizontal={true}
          scrollEventThrottle={1}
          additionalOnScroll={({ nativeEvent }: NativeSyntheticEvent<NativeScrollEvent>) => {
            if (props.titleHeaderScrollViewRef.current?.getNativeScrollRef()) {
              (props.titleHeaderScrollViewRef.current.getNativeScrollRef() as any).scrollLeft =
                nativeEvent.contentOffset.x;
            }
          }}>
          <View style={{ minWidth: 500 }}>{props.tasks.map(renderTitleHeader)}</View>
        </CustomScrollView>
      </Animated.View>
      <PanGestureHandler
        maxPointers={1}
        onGestureEvent={Animated.event([], {
          listener: (event: PanGestureHandlerGestureEvent) => {
            if (event.nativeEvent.state === State.BEGAN) {
            }
            if (event.nativeEvent.state === State.ACTIVE) {
              props.taskTitleWidthTranslation.setValue(
                props.taskTitleWidth + event.nativeEvent.translationX
              );
            }
            if (event.nativeEvent.state === State.END) {
              const value = props.taskTitleWidth + event.nativeEvent.translationX;
              props.setTaskTitleWidth(value);
              Cookies.set('GANTT_TASK_TITLE_WIDTH', value.toString(), {
                expires: moment().add(10, 'years').toDate(),
              });
            }
          },
          useNativeDriver: true,
        })}>
        <NonForwardedRefAnimatedView
          style={{
            width: 3,
            borderLeftWidth: 1,
            borderRightWidth: 1,
            borderColor: themeContext.colors.separator,
            cursor: 'col-resize',
          }}>
          <View style={{ width: 5 }}></View>
        </NonForwardedRefAnimatedView>
      </PanGestureHandler>
    </>
  );
};

interface ITaskInfoColumnBodyProps {
  organization: Organization;
  tasks: Array<Task>;
  allTaskCount: number;
  leftHeaderScrollViewRef: any;
  leftBodyScrollViewRef: any;
  chunkStartIndex: number;
  sortKey: SortKey | null;
  setDraggingTaskIndex: (value: number | null) => void;
  taskInfoWidth: number;
  setTaskInfoWidth: (value: number) => void;
  taskInfoWidthTranslation: Animated.Value;
}

const TaskInfoColumnBody = (props: ITaskInfoColumnBodyProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [updateTaskSortNo, _] = useUpdateTaskSortNoInGanttChartMutation();

  const renderTaskInfoHeader = useCallback(
    (task, i) => (
      <TaskRow
        organization={props.organization}
        task={task!}
        index={i + props.chunkStartIndex}
        sortKey={props.sortKey}
        setDraggingTaskIndex={props.setDraggingTaskIndex}
        onDrop={(targetTask: Task, index) => {
          const adjustedIndex = index - props.chunkStartIndex;
          let aboveTask;
          if (adjustedIndex <= 0) {
            aboveTask = props.tasks[0];
          } else {
            aboveTask = props.tasks[adjustedIndex];
          }

          let bottomTask;
          if (adjustedIndex + 1 >= props.tasks.length - 1) {
            bottomTask = props.tasks[props.tasks.length - 1];
          } else {
            bottomTask = props.tasks[adjustedIndex + 1];
          }

          let sortNo;
          const isMoveToFirst = adjustedIndex < 0;
          const isMoveToLast = adjustedIndex + 1 >= props.tasks.length - 1;
          if (isMoveToFirst) {
            sortNo = new Date().getTime();
          } else if (isMoveToLast) {
            sortNo = props.tasks[props.tasks.length - 1]!.sortNoInGanttChart - 1000;
          } else {
            sortNo = Math.floor(
              (aboveTask!.sortNoInGanttChart + bottomTask!.sortNoInGanttChart) / 2
            );
          }

          updateTaskSortNo({
            variables: {
              id: targetTask.id!,
              input: {
                sortNoInGanttChart: sortNo,
                versionNo: targetTask.versionNo,
              },
            },
          });
        }}
        key={i}
      />
    ),
    [props.organization, props.chunkStartIndex, props.tasks, updateTaskSortNo]
  );

  return (
    <>
      <Animated.View
        style={{
          width: props.taskInfoWidthTranslation,
          minWidth: 50,
          maxWidth: 540,
          overflow: 'hidden',
        }}>
        <CustomScrollView
          style={{
            shadowOffset: {
              width: -1,
              height: -1,
            },
            shadowOpacity: 0.1,
            borderRightWidth: 1,
            borderColor: themeContext.colors.separator,
          }}
          scrollViewRef={props.leftBodyScrollViewRef as any}
          horizontal={true}
          scrollEventThrottle={1}
          additionalOnScroll={({ nativeEvent }: NativeSyntheticEvent<NativeScrollEvent>) => {
            if (props.leftHeaderScrollViewRef.current?.getNativeScrollRef()) {
              (props.leftHeaderScrollViewRef.current.getNativeScrollRef() as any).scrollLeft =
                nativeEvent.contentOffset.x;
            }
          }}>
          <LeftContainerInner style={{ height: props.allTaskCount * itemHeight }}>
            {props.tasks.map(renderTaskInfoHeader)}
          </LeftContainerInner>
        </CustomScrollView>
      </Animated.View>
      <PanGestureHandler
        maxPointers={1}
        onGestureEvent={Animated.event([], {
          listener: (event: PanGestureHandlerGestureEvent) => {
            if (event.nativeEvent.state === State.BEGAN) {
            }
            if (event.nativeEvent.state === State.ACTIVE) {
              props.taskInfoWidthTranslation.setValue(
                props.taskInfoWidth + event.nativeEvent.translationX
              );
            }
            if (event.nativeEvent.state === State.END) {
              const value = props.taskInfoWidth + event.nativeEvent.translationX;
              props.setTaskInfoWidth(value);
              Cookies.set('GANTT_TASK_INFO_WIDTH', value.toString(), {
                expires: moment().add(10, 'years').toDate(),
              });
            }
          },
          useNativeDriver: true,
        })}>
        <NonForwardedRefAnimatedView
          style={{
            width: 3,
            borderLeftWidth: 1,
            borderRightWidth: 1,
            borderColor: themeContext.colors.separator,
            cursor: 'col-resize',
          }}>
          <View style={{ width: 5 }}></View>
        </NonForwardedRefAnimatedView>
      </PanGestureHandler>
    </>
  );
};

interface IBodyContainerProps {
  organization: Organization;
  titleHeaderScrollViewRef: any;
  titleBodyScrollViewRef: any;
  leftHeaderScrollViewRef: any;
  rightHeaderScrollViewRef: any;
  leftBodyScrollViewRef: any;
  rightBodyScrollViewRef: any;
  tasks: Array<Task>;
  allTaskCount: number;
  dateCellIndexes: Array<number>;
  startDate: moment.Moment;
  endDate: moment.Moment;
  dateScale: number;
  ganttContainerWrapperPosX: number;
  ganttContainerWrapperWidth: number;
  chunkStartIndex: number;
  sortKey: SortKey | null;
  moveToDate: (value: moment.Moment) => void;
  onScroll: (event: any) => void;
  onScrollBody: (event: any) => void;
  taskTitleWidth: number;
  setTaskTitleWidth: (value: number) => void;
  taskTitleWidthTranslation: Animated.Value;
  taskInfoWidth: number;
  setTaskInfoWidth: (value: number) => void;
  taskInfoWidthTranslation: Animated.Value;
  onEndReached: () => void;
}

const BodyContainer = React.memo((props: IBodyContainerProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [drawStartDate, setDrawStartDate] = useState(
    moment(props.startDate).add(props.dateCellIndexes[0] * props.dateScale, 'days')
  );

  const [drawEndDate, setDrawEndDate] = useState(
    moment(props.startDate).add(
      props.dateCellIndexes[props.dateCellIndexes.length - 1] * props.dateScale,
      'days'
    )
  );
  const [draggingTaskIndex, setDraggingTaskIndex] = useState<number | null>(null);

  useEffect(() => {
    setDrawStartDate(
      moment(props.startDate).add(props.dateCellIndexes[0] * props.dateScale, 'days')
    );
    setDrawEndDate(
      moment(props.startDate).add(
        props.dateCellIndexes[props.dateCellIndexes.length - 1] * props.dateScale,
        'days'
      )
    );
  }, [props.startDate, props.dateCellIndexes, props.dateScale]);

  const renderRightRow = useCallback(
    (task, i) => {
      return (
        <RightRow
          organization={props.organization}
          task={task!}
          ganttContainerWrapperPosX={props.ganttContainerWrapperPosX}
          ganttContainerWrapperWidth={props.ganttContainerWrapperWidth}
          startDate={props.startDate}
          endDate={props.endDate}
          rightBodyScrollViewRef={props.rightBodyScrollViewRef}
          dateCellIndexes={props.dateCellIndexes}
          dateScale={props.dateScale}
          drawStartDate={drawStartDate}
          drawEndDate={drawEndDate}
          moveToDate={props.moveToDate}
          index={i + props.chunkStartIndex}
          key={i}
        />
      );
    },
    [
      props.startDate,
      props.endDate,
      props.ganttContainerWrapperPosX,
      props.dateScale,
      props.chunkStartIndex,
      props.dateCellIndexes,
      props.rightBodyScrollViewRef,
      props.moveToDate,
      props.ganttContainerWrapperWidth,
      props.organization,
      drawStartDate,
      drawEndDate,
    ]
  );

  const renderDateLine = useCallback(
    (_, i) => {
      return (
        <DateLineContainer
          dateScale={props.dateScale}
          startDate={props.startDate}
          index={i}
          key={i}
        />
      );
    },
    [props.dateScale, props.startDate]
  );

  return (
    <CustomScrollView
      style={{ height: 'calc(100vh - 300px)', flexDirection: 'column', zIndex: 1 }}
      additionalOnScroll={props.onScroll}
      scrollEventThrottle={10}
      onEndReached={props.onEndReached}>
      <Main>
        <View style={{ zIndex: 4 }}>
          <View
            style={{
              width: 10000,
              height: 3,
              backgroundColor: themeContext.colors.primary,
              position: 'absolute',
              opacity: draggingTaskIndex !== null ? 1 : 0,
              left: 0,
              top: draggingTaskIndex !== null ? ((draggingTaskIndex || 0) + 1) * itemHeight : 0,
            }}
          />
        </View>
        <TaskTitleColumnBody
          organization={props.organization}
          tasks={props.tasks}
          allTaskCount={props.allTaskCount}
          chunkStartIndex={props.chunkStartIndex}
          titleHeaderScrollViewRef={props.titleHeaderScrollViewRef}
          titleBodyScrollViewRef={props.titleBodyScrollViewRef}
          leftBodyScrollViewRef={props.leftBodyScrollViewRef}
          leftHeaderScrollViewRef={props.leftHeaderScrollViewRef}
          setDraggingTaskIndex={setDraggingTaskIndex}
          sortKey={props.sortKey}
          taskTitleWidth={props.taskTitleWidth}
          setTaskTitleWidth={props.setTaskTitleWidth}
          taskTitleWidthTranslation={props.taskTitleWidthTranslation}
        />
        <TaskInfoColumnBody
          organization={props.organization}
          tasks={props.tasks}
          allTaskCount={props.allTaskCount}
          chunkStartIndex={props.chunkStartIndex}
          leftBodyScrollViewRef={props.leftBodyScrollViewRef}
          leftHeaderScrollViewRef={props.leftHeaderScrollViewRef}
          setDraggingTaskIndex={setDraggingTaskIndex}
          sortKey={props.sortKey}
          taskInfoWidth={props.taskInfoWidth}
          setTaskInfoWidth={props.setTaskInfoWidth}
          taskInfoWidthTranslation={props.taskInfoWidthTranslation}
        />
        <CustomScrollView
          style={{
            flex: 2,
            zIndex: 1,
          }}
          scrollViewRef={props.rightBodyScrollViewRef}
          horizontal={true}
          showsHorizontalScrollIndicator={false}
          additionalOnScroll={props.onScrollBody}
          scrollEventThrottle={1}>
          <RightContainerInner
            style={{ height: props.allTaskCount * itemHeight }}
            dateColumnCount={props.dateCellIndexes.length}>
            <DateLineWrapper dateColumnCount={props.dateCellIndexes.length}>
              <DateLineHeader>{props.dateCellIndexes.map(renderDateLine)}</DateLineHeader>
            </DateLineWrapper>
            {props.tasks.map(renderRightRow)}
          </RightContainerInner>
        </CustomScrollView>
      </Main>
    </CustomScrollView>
  );
});

interface ITaskAddProps {
  project: Project;
  me: Member;
}

const TaskAdd = (props: ITaskAddProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [showNewTaskInput, setShowNewTaskInput] = useState(false);
  const [title, setTitle] = useState('');
  const [showCreateTaskErrorModal, setShowCreateTaskErrorModal] = useState(false);
  const [requestCreateTask, requestCreateTaskResult] = useCreateTaskMutation({
    variables: {
      projectId: props.project.id!,
      input: {
        title: title,
      },
    },
    update: (cache, result) => {
      const newTask = cache.writeQuery({
        data: result.data?.createTask,
        query: ProjectTasksDocument,
      });

      cache.modify({
        fields: {
          searchTasks(existing = []) {
            return [newTask, ...existing];
          },
          projectTasks(existing = []) {
            return [newTask, ...existing];
          },
          boardTasks(existingRef, { readField }) {
            const taskStatusId = `TaskStatus:${result.data?.createTask?.taskStatus?.id}`; // Pusherからemptyデータが来るケースがあるので、taskStatusが無い場合がある
            if (
              taskStatusId === existingRef.taskStatus.__ref &&
              !existingRef.tasks.includes(taskStatusId)
            ) {
              return {
                taskStatus: existingRef.taskStatus,
                tasks: [
                  {
                    __ref: `Task:${result.data?.createTask!.id}`,
                  },
                  ...existingRef.tasks,
                ],
              };
            }
            return existingRef;
          },
        },
      });
    },
  });

  const [requestCreateTasks] = useCreateTasksMutation({
    update: (cache, result) => {
      // 自動的にはRefetchされないので。
      cache.modify({
        fields: {
          projectTasks(existing = []) {
            const newTasks = result.data?.createTasks?.map((task) => {
              const newTask = cache.writeQuery({
                data: task,
                query: ProjectTasksDocument,
              });
              return newTask;
            });
            return [newTasks, ...existing];
          },
        },
      });
    },
  });

  const onPressEnter = useCallback(
    async (value) => {
      setTitle('');
      if (value.trim().length > 0) {
        await requestCreateTask({
          variables: {
            projectId: props.project.id!,
            input: {
              title: value,
            },
          },
        });
      } else {
        setShowNewTaskInput(false);
      }
    },
    [setShowNewTaskInput, setTitle, requestCreateTask, props.project.id]
  );

  const onPaste = useCallback(
    async (value: string) => {
      setShowNewTaskInput(false);
      const titles = value
        .split(/\r\n|\n/)
        .map((v) => v.trim())
        .filter((v) => v.length > 0);

      if (titles.length === 0) {
        return;
      }
      await requestCreateTasks({
        variables: {
          projectId: props.project.id!,
          input: {
            tasks: titles.map((title) => {
              return {
                title: title.trim().substring(0, 100),
              };
            }),
          },
        },
      });
    },
    [setShowNewTaskInput, requestCreateTasks, props.project.id]
  );

  return (
    <View>
      <TaskAddButton
        style={{
          display: 'flex',
          flexDirection: 'row',
          paddingHorizontal: 5,
          marginTop: 10,
        }}
        onPress={() => {
          if (!props.me.taskCreatePermissionFlg) {
            setShowCreateTaskErrorModal(true);
            return;
          }
          setShowNewTaskInput(true);
        }}>
        <PlusIcon
          size={14}
          containerStyle={{ marginLeft: 10 }}
          onPress={() => {
            if (!props.me.taskCreatePermissionFlg) {
              setShowCreateTaskErrorModal(true);
              return;
            }
            setShowNewTaskInput(true);
          }}>
          <Typography
            variant={TypographyType.Normal}
            style={{ fontSize: 14, color: themeContext.colors.description }}>
            タスクを追加する
          </Typography>
        </PlusIcon>
      </TaskAddButton>
      {showNewTaskInput && (
        <Form style={{ minWidth: 500, marginBottom: 10, marginLeft: 10 }}>
          <Input
            name={'newTaskName'}
            focus={true}
            placeholder={'タスク名を入力してください'}
            containerStyle={{ margin: 0 }}
            validate={{
              maxLength: {
                value: 100,
                message: '100文字以内で入力してください',
              },
            }}
            clearValueOnBlur={true}
            allowContinuousInput={true}
            onChange={(value) => setTitle(value)}
            onPressEnter={onPressEnter}
            onPaste={onPaste}
            onBlur={() => {
              setTitle('');
              setShowNewTaskInput(false);
            }}
          />
        </Form>
      )}
      <ErrorMessageModal
        showModal={showCreateTaskErrorModal}
        title={'タスクを編集できません'}
        message={`タスクを編集する権限がありません${'\n'}権限が必要な場合、管理権限を持っているメンバーに問い合わせてください`}
        onCloseModal={() => setShowCreateTaskErrorModal(false)}
      />
    </View>
  );
};

interface IDisplayStartDateInfoForScaleChange {
  displayStartDateForDateScaleChanged: moment.Moment;
  dateScale: number;
}

interface Props {
  teamId: string;
  projectId: string;
  organizationId: string;
}

const GanttChart = React.memo((props: Props) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [taskFilter, __] = useContext(TaskFilterContext);
  const history = useHistory();
  const { width: windowWidth } = useWindowDimensions();
  const [notAllowedPlan, setNotAllowedPlan] = useState(false);
  const [sortKey, setSortKey] = useState<SortKey | null>(null);
  const [sortOrder, setSortOrder] = useState<SortOrder | null>(SortOrder.Desc);
  const [startDate, setStartDate] = useState(moment().startOf('day').add(-1, 'years'));
  const [endDate, setEndDate] = useState(moment().startOf('day').add(1, 'years'));
  const [dateScale, setDateScale] = useState(dateScaleSet[0]);
  const [displayStartDate, setDisplayStartDate] = useState(moment().startOf('day').add(-7, 'days'));
  const [displayStartDateForDateScaleChanged, setDisplayStartDateForDateScaleChanged] =
    useState<IDisplayStartDateInfoForScaleChange>({
      displayStartDateForDateScaleChanged: displayStartDate,
      dateScale: dateScale,
    });
  const [ganttContainerWrapperPosX, setGanttContainerWrapperPosX] = useState(0);
  const [ganttContainerWrapperWidth, setGanttContainerWrapperWidth] = useState(0);
  const [taskTitleWidth, setTaskTitleWidth] = useState(
    Cookies.get('GANTT_TASK_TITLE_WIDTH')
      ? Math.max(50, Math.min(500, Number(Cookies.get('GANTT_TASK_TITLE_WIDTH'))))
      : 200
  );
  const [taskTitleWidthTranslation, setTaskTitleWidthTranslation] = useState(
    new Animated.Value(taskTitleWidth)
  );
  const [taskInfoWidth, setTaskInfoWidth] = useState(
    Cookies.get('GANTT_TASK_INFO_WIDTH')
      ? Math.max(50, Math.min(540, Number(Cookies.get('GANTT_TASK_INFO_WIDTH'))))
      : 350
  );
  const [taskInfoWidthTranslation, setTaskInfoWidthTranslation] = useState(
    new Animated.Value(taskInfoWidth)
  );
  const titleHeaderScrollViewRef = useRef();
  const leftHeaderScrollViewRef = useRef();
  const leftBodyScrollViewRef = useRef();
  const rightHeaderScrollViewRef = useRef();
  const titleBodyScrollViewRef = useRef();
  const rightBodyScrollViewRef = useRef();
  const rightBodyContainerRef = useRef();

  const dateCellIndexes = useMemo(() => {
    return [...Array(Math.ceil(endDate.diff(startDate, 'days') / dateScale))].map((_, i) => i);
  }, [dateScale, startDate, endDate]);

  useEffect(() => {
    // 日付スケール変更後に、ガントチャートアイテムリンクの表示を最新化する
    const scrollLeft = (rightHeaderScrollViewRef.current as any)?.getNativeScrollRef()?.scrollLeft;
    if (scrollLeft) {
      window.dispatchEvent(
        new CustomEvent('ganttChartHorizontalScrollFinished', {
          detail: {
            contentOffsetX: (rightHeaderScrollViewRef.current as any)?.getNativeScrollRef()
              ?.scrollLeft,
          },
        } as any)
      );
    }
  }, [rightHeaderScrollViewRef?.current, displayStartDateForDateScaleChanged]);

  const fetchOrganization = useOrganizationQuery({
    variables: {
      id: props.organizationId,
    },
    fetchPolicy: 'network-only',
  });

  const { data: projectData, loading: projectLoading } = useProjectQuery({
    variables: {
      id: props.projectId,
    },
    fetchPolicy: 'network-only',
  });

  const { data: meData, loading: meLoading } = useMeQuery({
    fetchPolicy: 'network-only',
  });

  const { data, loading, fetchMore } = useSearchTasksQuery({
    variables: {
      input: {
        projectIdList: [props.projectId],
        taskStatusIdList: (taskFilter as TaskFilter).taskStatusIds || [],
        assignedMemberIdList: (taskFilter as TaskFilter).assignerIds || [],
        scheduledEndDateTime: {
          to: when((taskFilter as TaskFilter)?.expire)
            .on(
              (v) => v === 'today',
              () => moment().endOf('day').toISOString()
            )
            .on(
              (v) => v === '3days',
              () => moment().add(3, 'days').endOf('day').toISOString()
            )
            .on(
              (v) => v === 'week',
              () => moment().add(1, 'week').endOf('day').toISOString()
            )
            .otherwise(() => null),
        },
        completeCondition: TaskCompleteFilter.Both,
        sortKey: sortKey || SortKey.SortNoInGanttChart,
        sortOrder: sortOrder || SortOrder.Desc,
      },
      offset: 0,
      limit: 50,
    },
    fetchPolicy: 'network-only',
    skip:
      props.projectId === 'task' ||
      (taskFilter as TaskFilter)?.taskStatusIds?.length === 0 ||
      (taskFilter as TaskFilter)?.taskStatusIds?.length === undefined,
  });

  useLayoutEffect(() => {
    setTimeout(() => {
      moveToDate(moment().startOf('day').add(-7, 'days'));
    }, 500);
  }, [rightHeaderScrollViewRef?.current, rightBodyScrollViewRef?.current]);

  // Memo化しておかないと、デーGタに変更がなくても、このコンポーネントが描画される度に、全てのTaskSummary再描画がされてしまう。
  const tasks = useMemo(() => {
    if (loading || !data?.searchTasks) {
      return [];
    }
    return data!.searchTasks!.map((task) => {
      if (!notAllowedPlan) {
        return task;
      }

      // ガントチャートを利用不可能なプランの場合には、ダミー情報に書き換えて使えないようにする
      return Object.assign({}, task, {
        id: (-1 * Math.random()).toString(),
        title: '-',
      });
    });
  }, [loading, data?.searchTasks, taskFilter, sortKey, sortOrder, notAllowedPlan]);

  const [chunk, nextChunk, prevChunk, pointer, chunkStartIndex, chunkEndIndex] = useChunk(
    tasks,
    50,
    0
  );

  const onScroll = useCallback(
    (event) => {
      if (event.nativeEvent.contentOffset.y < itemHeight * chunkStartIndex + itemHeight * 3) {
        prevChunk();
      }
      if (event.nativeEvent.contentOffset.y > itemHeight * pointer) {
        nextChunk();
      }
    },
    [chunkStartIndex, pointer, itemHeight, prevChunk, nextChunk]
  );

  const dispatchGanttChartScrollFinishedEvent = _.debounce((x: number) => {
    window.dispatchEvent(
      new CustomEvent('ganttChartHorizontalScrollFinished', {
        detail: {
          contentOffsetX: x,
        },
      } as any)
    );
  }, 500);

  const calcDisplayStartDate = () => {
    if ((rightBodyScrollViewRef.current as any)?.getNativeScrollRef()) {
      return moment(startDate).add(
        Math.floor(
          (rightBodyScrollViewRef.current as any).getNativeScrollRef().scrollLeft / columnWidth
        ) * dateScale,
        'days'
      );
    }
    return moment();
  };

  const clearSuppressBodyScrollEvent = _.debounce(() => {
    if (
      rightBodyScrollViewRef &&
      rightBodyScrollViewRef.current &&
      (rightBodyScrollViewRef.current as any).getNativeScrollRef()
    ) {
      (rightBodyScrollViewRef.current as any).getNativeScrollRef().suppressScrollEvent = false;
    }
  }, 500);

  const onScrollHead = useCallback(
    async (event) => {
      if (
        (rightHeaderScrollViewRef.current as any) &&
        (rightHeaderScrollViewRef.current as any).getNativeScrollRef()?.suppressScrollEvent !== true
      ) {
        if ((rightBodyScrollViewRef.current as any)?.getNativeScrollRef()) {
          (rightBodyScrollViewRef.current as any).getNativeScrollRef().suppressScrollEvent = true;
          (rightBodyScrollViewRef.current as any).getNativeScrollRef().scrollLeft =
            event.nativeEvent.contentOffset.x;
          clearSuppressBodyScrollEvent();
          dispatchGanttChartScrollFinishedEvent(event.nativeEvent.contentOffset.x);
        }
      }
    },
    [rightBodyScrollViewRef.current, rightHeaderScrollViewRef.current, clearSuppressBodyScrollEvent]
  );

  const clearSuppressHeaderScrollEvent = _.debounce(() => {
    (rightHeaderScrollViewRef.current as any).getNativeScrollRef().suppressScrollEvent = false;
  }, 500);

  const onScrollBody = useCallback(
    (event) => {
      if (
        (rightBodyScrollViewRef?.current as any)?.getNativeScrollRef()?.suppressScrollEvent !== true
      ) {
        if ((rightHeaderScrollViewRef.current as any)?.getNativeScrollRef()) {
          (rightHeaderScrollViewRef.current as any).getNativeScrollRef().suppressScrollEvent = true;
          (rightHeaderScrollViewRef.current as any).getNativeScrollRef().scrollLeft =
            event.nativeEvent.contentOffset.x;
          clearSuppressHeaderScrollEvent();
          dispatchGanttChartScrollFinishedEvent(event.nativeEvent.contentOffset.x);
        }
      }
    },
    [
      rightHeaderScrollViewRef.current,
      rightBodyScrollViewRef.current,
      clearSuppressHeaderScrollEvent,
    ]
  );

  const moveToDate = (value: moment.Moment) => {
    if ((rightHeaderScrollViewRef?.current as any)?.getNativeScrollRef()) {
      (rightHeaderScrollViewRef?.current as any).getNativeScrollRef().scrollLeft =
        (moment(value).startOf('day').diff(startDate, 'days') / dateScale) * columnWidth;
    }
  };

  useLayoutEffect(() => {
    if (rightBodyContainerRef.current) {
      const clientRect = (rightBodyContainerRef.current as any).getBoundingClientRect();
      setGanttContainerWrapperPosX(clientRect.x);

      const width = clientRect.width;
      setGanttContainerWrapperWidth(width);
    }
  }, [rightBodyContainerRef.current, taskTitleWidth, taskInfoWidth]);

  const onResize = () => {
    if (rightBodyContainerRef.current) {
      const clientRect = (rightBodyContainerRef.current as any).getBoundingClientRect();
      setGanttContainerWrapperPosX(clientRect.x);

      const width = clientRect.width;
      setGanttContainerWrapperWidth(width);
    }
  };
  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize, rightBodyContainerRef.current, taskTitleWidth, taskInfoWidth]);

  const refrectDisplayStartDate = _.debounce((e: any) => {
    setDisplayStartDate(e.detail.displayStartDate);
  }, 1000);
  useEffect(() => {
    window.addEventListener('GanntChartScrollAndDisplayStartDateChanged', refrectDisplayStartDate);
    return () => {
      window.removeEventListener(
        'GanntChartScrollAndDisplayStartDateChanged',
        refrectDisplayStartDate
      );
    };
  }, [setDisplayStartDate]);

  useEffect(() => {
    setInterval(() => {
      try {
        if (fetchOrganization.data?.organization?.plan.code === Plan.Basic) {
          setNotAllowedPlan(true);
        }
      } catch (_e) {
        // NOP
      }
    }, 1000);
  }, [fetchOrganization.data]);

  if (fetchOrganization.loading || projectLoading || meLoading || !meData?.me) {
    return <></>;
  }

  return (
    <Spinner loading={fetchOrganization.loading}>
      <PlanNotAllowedView
        isNotAllowedPlan={notAllowedPlan}
        contentsStyle={{ position: 'absolute', left: windowWidth / 8 }}>
        <View style={{ flexDirection: 'column', width: '100%' }}>
          <View
            style={{
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <TaskAdd project={projectData!.project!} me={meData.me} />
            <HeaderMenu>
              <View style={{ flexDirection: 'row', marginRight: 20 }}>
                <DoubleLeftIcon
                  size={22}
                  onPress={() => {
                    if (dateScale === 1) {
                      moveToDate(calcDisplayStartDate().add(-7, 'days'));
                    } else {
                      moveToDate(calcDisplayStartDate().add(-4, 'week'));
                    }
                  }}
                />
                <Button
                  text={'今日へ移動'}
                  textStyle={{ fontSize: 12 }}
                  style={{ paddingVertical: 5, paddingHorizontal: 5, marginHorizontal: 5 }}
                  onPress={async () => {
                    moveToDate(moment());
                  }}
                />
                <DoubleRightIcon
                  size={22}
                  onPress={() => {
                    if (dateScale === 1) {
                      moveToDate(calcDisplayStartDate().add(7, 'days'));
                    } else {
                      moveToDate(calcDisplayStartDate().add(4, 'week'));
                    }
                  }}
                />
              </View>

              <Typography variant={TypographyType.Normal} style={{ fontSize: 12, marginRight: 5 }}>
                表示単位：
              </Typography>
              <SelectButton
                multiSelect={false}
                values={[dateScale.toString()]}
                contents={[
                  {
                    key: '1',
                    content: '日',
                  },
                  {
                    key: '7',
                    content: '週',
                  },
                ]}
                onChange={(value) => {
                  if (value.length > 0) {
                    if (value[0] === '1') {
                      if ((rightHeaderScrollViewRef?.current as any)?.getNativeScrollRef()) {
                        (rightHeaderScrollViewRef?.current as any).getNativeScrollRef().scrollLeft =
                          (calcDisplayStartDate().startOf('day').diff(startDate, 'days') / 1) *
                          columnWidth;
                      }
                      setDateScale(1);
                      return;
                    }
                    if (value[0] === '7') {
                      if ((rightHeaderScrollViewRef?.current as any)?.getNativeScrollRef()) {
                        (rightHeaderScrollViewRef?.current as any).getNativeScrollRef().scrollLeft =
                          (calcDisplayStartDate().startOf('day').diff(startDate, 'days') / 7) *
                          columnWidth;
                      }
                      setDateScale(7);
                      return;
                    }
                  }
                }}
              />
            </HeaderMenu>
          </View>
          <HeaderContainer
            organization={fetchOrganization.data!.organization!}
            dateCellIndexes={dateCellIndexes}
            startDate={startDate}
            endDate={endDate}
            dateScale={dateScale}
            sortKey={sortKey}
            setSortKey={setSortKey}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            titleHeaderScrollViewRef={titleHeaderScrollViewRef}
            titleBodyScrollViewRef={titleBodyScrollViewRef}
            rightBodyContainerRef={rightBodyContainerRef}
            leftHeaderScrollViewRef={leftHeaderScrollViewRef}
            rightHeaderScrollViewRef={rightHeaderScrollViewRef}
            leftBodyScrollViewRef={leftBodyScrollViewRef}
            rightBodyScrollViewRef={rightBodyScrollViewRef}
            onScrollHead={onScrollHead}
            taskTitleWidthTranslation={taskTitleWidthTranslation}
            taskInfoWidthTranslation={taskInfoWidthTranslation}
          />
          <BodyContainer
            organization={fetchOrganization.data!.organization!}
            tasks={chunk as Array<Task>}
            allTaskCount={tasks.length}
            dateCellIndexes={dateCellIndexes}
            startDate={startDate}
            endDate={endDate}
            dateScale={dateScale}
            ganttContainerWrapperPosX={ganttContainerWrapperPosX}
            ganttContainerWrapperWidth={ganttContainerWrapperWidth}
            titleHeaderScrollViewRef={titleHeaderScrollViewRef}
            titleBodyScrollViewRef={titleBodyScrollViewRef}
            leftHeaderScrollViewRef={leftHeaderScrollViewRef}
            rightHeaderScrollViewRef={rightHeaderScrollViewRef}
            leftBodyScrollViewRef={leftBodyScrollViewRef}
            rightBodyScrollViewRef={rightBodyScrollViewRef}
            onScroll={onScroll}
            onScrollBody={onScrollBody}
            chunkStartIndex={chunkStartIndex}
            sortKey={sortKey}
            moveToDate={moveToDate}
            taskTitleWidth={taskTitleWidth}
            setTaskTitleWidth={setTaskTitleWidth}
            taskTitleWidthTranslation={taskTitleWidthTranslation}
            taskInfoWidth={taskInfoWidth}
            setTaskInfoWidth={setTaskInfoWidth}
            taskInfoWidthTranslation={taskInfoWidthTranslation}
            onEndReached={() =>
              fetchMore({
                variables: {
                  offset: data!.searchTasks!.length,
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev;

                  const previousTasksIds = (prev.searchTasks || []).map((task) => task!.id);
                  return Object.assign({}, prev, {
                    searchTasks: [
                      ...(prev.searchTasks || []),
                      ...(fetchMoreResult.searchTasks || []).filter(
                        (task) => !previousTasksIds.includes(task!.id) // なぜかデータが重複して登録されてしまうので、こちらで手動で重複を排除している
                      ),
                    ],
                  });
                },
              })
            }
          />
        </View>
      </PlanNotAllowedView>
    </Spinner>
  );
});

export default GanttChart;
