import React, { useContext, useEffect, useState } from 'react';
import { View, Image, TouchableOpacity } from 'react-native';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import Ripple from 'react-native-material-ripple';
import { useHistory } from 'react-router';
import styled, { ThemeContext } from 'styled-components/native';
import { useOrganizationQuery, useOrganizationsLazyQuery } from '../../../../../graphql/api/API';
import { LoginUser, LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import LoginUtil from '../../../../../modules/auth/LoginUtil';
import Avatar from '../../../../presentational/atoms/avatar';
import Button from '../../../../presentational/atoms/button';
import Icon from '../../../../presentational/atoms/icon';
import Modal from '../../../../presentational/molecules/modal';
import { IThemePart } from '../../../../theme';
import ReloadIcon from '../../../../presentational/molecules/image-icon/reload';
import PlusIcon from '../../../../presentational/molecules/image-icon/plus';

const Container = styled.View`
  width: 38px;
  align-items: center;
  height: 100vh;
  padding-top: 4px;
  background-color: #151a25;
  z-index: 1;
`;

interface IProps {
  onSelectOrganization: (user: LoginUser) => void;
}

const OrganizationList: React.FC<IProps> = (props: IProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [allUser, setAllUser] = useState<LoginUser[]>([]);
  const [showCreateAccountModal, setShowCreateAccountModal] = useState(false);
  const [fetchOrganizations, fetchOrganizationsResult] = useOrganizationsLazyQuery();
  const { loading, data, error } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId!,
    },
  });

  useEffect(() => {
    LoginUtil.getAllJwtTokens().then(async (allToken) => {
      if (!allToken) {
        setLoginUser(null);
        window.location.reload();
      } else {
        await fetchOrganizations({
          variables: {
            tokens: Object.values(allToken || {}),
          },
        });
      }
    });
    LoginUtil.getAllUserFromJwtTokens().then((allUser) => {
      setAllUser(allUser);
    });
  }, []);

  if (loading || !fetchOrganizationsResult.data?.organizations || allUser.length === 0) {
    return <></>;
  }

  return (
    <Container>
      {fetchOrganizationsResult.data!.organizations!.map((organization, i) => {
        return (
          <Ripple
            key={i}
            rippleSize={150}
            rippleDuration={400}
            rippleColor={'#FFFFFF'}
            style={{
              cursor: 'pointer',
              opacity: organization!.id! === loginUser!.organizationId ? 1 : 0.5,
            }}
            onPress={() =>
              props.onSelectOrganization(
                allUser.filter((user) => user.organizationId === organization!.id!)[0]
              )
            }>
            <Avatar
              size={30}
              square={true}
              name={organization!.name}
              imageUrl={organization!.imageUrl}
              containerStyle={{ width: 30, height: 30, margin: 6, borderRadius: 6 }}
            />
          </Ripple>
        );
      })}
      <PlusIcon size={38} reverse={true} onPress={() => setShowCreateAccountModal(true)} />
      <ConfirmModal
        showModal={showCreateAccountModal}
        onCloseModal={() => setShowCreateAccountModal(false)}
      />
      <ReloadIcon
        size={27}
        reverse={true}
        containerStyle={{
          position: 'absolute',
          bottom: 0,
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: 10,
        }}
        onPress={() => window.location.reload()}
      />
    </Container>
  );
};

interface IModalProps {
  showModal: boolean;
  onCloseModal: () => void;
}
const ConfirmModal = (props: IModalProps) => {
  const history = useHistory();

  return (
    <Modal
      title={'新しく組織・アカウントを追加しますか？'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <Typography
        variant={TypographyType.Description}
        style={{ textAlign: 'center', marginVertical: 10 }}>
        新しくアカウント・組織を作成するか、{'\n'}別の作成済みのアカウント・組織にログイン出来ます。
        {'\n\n'}
        複数の組織で作業をされる方や、{'\n'}
        仕事用とプライベート用で複数アカウントを使いたい場合等にオススメです。
      </Typography>
      <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
        <Button
          text={'追加する'}
          style={{ minWidth: 100, marginRight: 10, marginVertical: 10 }}
          onPress={() => {
            props.onCloseModal();
            history.push('/signin-other/');
          }}
        />
        <Button
          text={'キャンセル'}
          style={{ minWidth: 100, marginLeft: 10, marginVertical: 10 }}
          onPress={() => {
            props.onCloseModal();
          }}
        />
      </View>
    </Modal>
  );
};

export default React.memo(OrganizationList);
