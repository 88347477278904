import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment-timezone';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import { IThemePart } from '../../../../../theme';
import {
  SettlementRangeContract,
  ProjectContractDocument,
  Team,
  useTeamMembersQuery,
  useCreateSettlementRangeContractMutation,
} from '../../../../../../graphql/api/API';
import Form from '../../../../../presentational/atoms/form';
import Input, { ErrorMessage } from '../../../../../presentational/atoms/input';
import { View } from 'react-native';
import Button from '../../../../../presentational/atoms/button';
import TaskInfoElement from '../../task-detail/task-info-element';
import { ProjectContract } from '../../../../../../graphql/api/API';
import Modal from '../../../../../presentational/molecules/modal';
import Typography, { TypographyType } from '../../../../../presentational/atoms/typography';

const Container = styled.View`
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  align-items: flex-start;
`;

const Row = styled.View`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: space-between;
`;

interface IProps {
  showModal: boolean;
  team: Team;
  projectContract: ProjectContract;
  onPressYes: (settlementRangeContract: SettlementRangeContract) => void;
  onCloseModal: () => void;
}

const RegisterSettlementRangeContractModal = (props: IProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [memberId, setMemberId] = useState<string | null>(null);
  const [monthlyUnitPrice, setMonthlyUnitPrice] = useState(0);
  const [deductionHourlyUnitPrice, setDeductionHourlyUnitPrice] = useState<number>(0);
  const [excessHourlyUnitPrice, setExcessHourlyUnitPrice] = useState<number>(0);
  const [lowerTimeHour, setLowerTimeHour] = useState<number>(0);
  const [upperimeHour, setUpperTimeHour] = useState<number>(0);

  const fetchTeamMembers = useTeamMembersQuery({
    variables: {
      teamId: props.team.id!,
    },
    fetchPolicy: 'network-only',
  });
  const [createData] = useCreateSettlementRangeContractMutation({
    variables: {
      input: {
        monthlyUnitPrice: monthlyUnitPrice,
        upperTimeHour: upperimeHour,
        excessHourlyUnitPrice: excessHourlyUnitPrice,
        lowerTimeHour: lowerTimeHour,
        deductionHourlyUnitPrice: deductionHourlyUnitPrice,
        projectContractId: props.projectContract.id!,
        memberId: memberId,
      },
    },
    refetchQueries: [
      {
        query: ProjectContractDocument,
        variables: {
          id: props.projectContract.id!,
        },
      },
    ],
  });

  useEffect(() => {
    setMonthlyUnitPrice(0);
    setUpperTimeHour(0);
    setExcessHourlyUnitPrice(0);
    setLowerTimeHour(0);
    setDeductionHourlyUnitPrice(0);
    setMemberId(null);
  }, [props.showModal]);

  if (fetchTeamMembers.loading || fetchTeamMembers.data?.teamMembers === undefined) {
    return <></>;
  }

  return (
    <Modal
      title={'メンバーの単価の登録'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <Container>
        <Form style={{ minWidth: 700 }}>
          <Row style={{ flexDirection: 'column' }}>
            <TaskInfoElement
              title={'メンバー'}
              style={{ flex: 1 }}
              titleContainerStyle={{ width: 180 }}>
              <Input
                name={'member'}
                type={'picker'}
                isSearchable={true}
                pickerItems={(fetchTeamMembers?.data?.teamMembers ?? [])
                  .filter(
                    (member) =>
                      !props.projectContract.membersSettlementRangeContract
                        ?.map((m) => m!.member!.memberId!)
                        .includes(member!.id!)
                  )
                  .map((member) => {
                    return {
                      label: member!.name || '-',
                      value: member!.id,
                    };
                  })}
                onChange={(value) => setMemberId(value)}
                validate={{
                  required: {
                    value: true,
                    message: 'メンバーを選択してください',
                  },
                }}
              />
            </TaskInfoElement>
          </Row>
          <Row style={{ flexDirection: 'column' }}>
            <TaskInfoElement
              title={'基本料金（税抜）'}
              style={{ flex: 1 }}
              titleContainerStyle={{ width: 180 }}>
              <Input
                name={'monthlyUnitPrice'}
                placeholder={'値を入力してください'}
                ghostSuffix="円"
                onChange={(value) => {
                  if (value.trim().length === 0 || isNaN(value as any)) {
                    setMonthlyUnitPrice(0);
                  } else {
                    setMonthlyUnitPrice(Number(value));
                  }
                }}
                validate={{
                  required: {
                    value: true,
                    message: '値を入力してください',
                  },
                  pattern: {
                    value: /^[0-9]+$/,
                    message: '半角数字を入力してください。',
                  },
                  min: {
                    value: 0,
                    message: '0以上の金額を入力してください',
                  },
                }}
              />
            </TaskInfoElement>
          </Row>
          <Row>
            <TaskInfoElement title={'基本時間'} titleContainerStyle={{ width: 180 }}>
              <View style={{ flexDirection: 'row' }}>
                <Input
                  name={'lowerTimmeHour'}
                  placeholder={'値を入力してください'}
                  containerStyle={{ flex: 1 }}
                  onChange={(value) => {
                    if (value.trim().length === 0 || isNaN(value as any)) {
                      setLowerTimeHour(0);
                    } else {
                      setLowerTimeHour(Number(value));
                    }
                  }}
                  validate={{
                    required: {
                      value: true,
                      message: '値を入力してください',
                    },
                    pattern: {
                      value: /^[0-9]+$/,
                      message: '半角数字を入力してください。',
                    },
                    min: {
                      value: 0,
                      message: '0以上の金額を入力してください',
                    },
                  }}
                />
                <Typography
                  variant={TypographyType.Normal}
                  style={{ color: '#565955', paddingHorizontal: 10 }}>
                  {`〜`}
                </Typography>
                <Input
                  name={'uppertimeHour'}
                  placeholder={'値を入力してください'}
                  containerStyle={{ flex: 1 }}
                  onChange={(value) => {
                    if (value.trim().length === 0 || isNaN(value as any)) {
                      setUpperTimeHour(0);
                    } else {
                      setUpperTimeHour(Number(value));
                    }
                  }}
                  validate={{
                    required: {
                      value: true,
                      message: '値を入力してください',
                    },
                    pattern: {
                      value: /^[0-9]+$/,
                      message: '半角数字を入力してください。',
                    },
                    min: {
                      value: 0,
                      message: '0以上の金額を入力してください',
                    },
                  }}
                />
                <Typography variant={TypographyType.Normal} style={{ color: '#565955' }}>
                  {`時間`}
                </Typography>
              </View>
            </TaskInfoElement>
          </Row>
          <Row>
            <TaskInfoElement
              title={`超過分・時間単価（税抜）${'\n'}※基本時間を上回った分`}
              titleContainerStyle={{ width: 180 }}>
              <Input
                name={'excessHourlyUnitPrice'}
                placeholder={'値を入力してください'}
                ghostSuffix="円"
                onChange={(value) => {
                  if (value.trim().length === 0 || isNaN(value as any)) {
                    setExcessHourlyUnitPrice(0);
                  } else {
                    setExcessHourlyUnitPrice(Number(value));
                  }
                }}
                validate={{
                  required: {
                    value: true,
                    message: '値を入力してください',
                  },
                  pattern: {
                    value: /^[0-9]+$/,
                    message: '半角数字を入力してください。',
                  },
                  min: {
                    value: 0,
                    message: '0以上の金額を入力してください',
                  },
                }}
              />
            </TaskInfoElement>
          </Row>

          <Row>
            <TaskInfoElement
              title={`控除分・時間単価（税抜）${'\n'}※基本時間を下回った分`}
              titleContainerStyle={{ width: 180 }}>
              <Input
                name={'deductionHourlyUnitPrice'}
                placeholder={'値を入力してください'}
                ghostSuffix="円"
                onChange={(value) => {
                  if (value.trim().length === 0 || isNaN(value as any)) {
                    setDeductionHourlyUnitPrice(0);
                  } else {
                    setDeductionHourlyUnitPrice(Number(value));
                  }
                }}
                validate={{
                  required: {
                    value: true,
                    message: '値を入力してください',
                  },
                  pattern: {
                    value: /^[0-9]+$/,
                    message: '半角数字を入力してください。',
                  },
                  min: {
                    value: 0,
                    message: '0以上の金額を入力してください',
                  },
                }}
              />
            </TaskInfoElement>
          </Row>
          <View
            style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 20 }}>
            <Button
              text={'作成する'}
              completeText={'作成しました'}
              style={{
                minWidth: 100,
                marginRight: 10,
              }}
              onPress={async () => {
                const result = await createData();
                props.onPressYes(result.data!.createSettlementRangeContract!);
              }}
            />
            <Button
              text={'キャンセル'}
              style={{
                minWidth: 100,
                marginRight: 10,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.primary }}
              disableValidate={true}
              onPress={async () => {
                props.onCloseModal();
              }}
            />
          </View>
        </Form>
      </Container>
    </Modal>
  );
};

export default RegisterSettlementRangeContractModal;
