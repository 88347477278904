import React, { useCallback, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components/native';
import { View, TouchableOpacity } from 'react-native';
import { IStyleTheme, IThemePart } from '../../../../theme';
import Avatar from '../../../../presentational/atoms/avatar';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import TimeUtil from '../../../../../util/TimeUtil';
import DateUtil from '../../../../../util/DateUtil';
import { TaskProgressBar } from '../../organisms/task-progress-bar';
import {
  CalendarWorkingHistoryDocument,
  MemberWithLatestTask,
  Priority,
  Task,
  WorkingHistoryForSummaryDocument,
  useStopTaskMutation,
} from '../../../../../graphql/api/API';
import { TaskWorkingTime } from '../../organisms/task-summary';
import { useHistory } from 'react-router';
import Link from '../../../../presentational/atoms/link';
import when from '../../../../../lang-extention/When';
import moment from 'moment-timezone';
import UrlUtil from '../../../../../util/UrlUtil';

interface IMainContainerProps extends IStyleTheme {
  isLoginMember: boolean;
  widthFull: boolean;
  isTracking: boolean;
}

const MainContainer = styled.View<IMainContainerProps>`
  border: 1px solid #c7c7c7;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  padding: 5px;
  height: ${(props: IMainContainerProps) => (props.isLoginMember ? '148px' : '148px')};
  width: ${(props: IMainContainerProps) => (props.widthFull ? '100%' : '400px')};
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: ${(props: IMainContainerProps) =>
    props.isTracking ? '#FFFFF0' : props.theme.colors.baseColor};
`;

const StatusContents = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Profile = styled.View`
  flex: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TaskContainer = styled.View`
  margin-top: 3px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  width: 100%;
  border-radius: 0px;
`;

const TaskDetails = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  margin-top: 1px;
  margin-bottom: 2px;
`;

interface IMemberStatusProps {
  organizationId: string;
  isLoginMember: boolean;
  isMeAdmin: boolean;
  memberStatus: MemberWithLatestTask;
  widthFull?: boolean;
  teamId?: string;
}

const MemberStatus = (props: IMemberStatusProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const history = useHistory();
  const [stopTask] = useStopTaskMutation({
    variables: {
      id: props.memberStatus.latestWorkingHistory?.task?.id || '',
      input: {
        workingMemberId: props.memberStatus.member?.id,
        versionNo: props.memberStatus?.latestWorkingHistory?.task?.versionNo || 0,
      },
    },
  });

  const onPress = useCallback(() => {
    if (props.memberStatus?.latestWorkingHistory?.task) {
      history.push(
        UrlUtil.createTaskDetailUrlById(props.memberStatus!.latestWorkingHistory!.task!.id!)
      );
    } else if (props.memberStatus.latestWorkingHistory?.calendarEvent) {
      history.push(
        UrlUtil.createCalendarEventDetailUrlById(
          props.memberStatus.latestWorkingHistory!.calendarEvent.id
        )
      );
    }
  }, [props.organizationId, props.memberStatus]);

  return (
    <MainContainer
      isLoginMember={props.isLoginMember}
      widthFull={props.widthFull || false}
      isTracking={
        !!props.memberStatus.latestWorkingHistory && !props.memberStatus.latestWorkingHistory?.end
      }>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <StatusContents>
          <View style={{ flexDirection: 'column', paddingTop: 10 }}>
            <Profile>
              <Avatar
                size={28}
                name={props.memberStatus.member.name}
                imageUrl={props.memberStatus.member.profileImageUrl}
              />
              <Typography
                variant={TypographyType.Normal}
                style={{ marginLeft: 7, fontSize: 16, maxWidth: 200 }}
                ellipsis={true}>
                {props!.memberStatus.member.name}
              </Typography>
            </Profile>
          </View>
        </StatusContents>
        <View style={{ flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'center' }}>
          <View>
            <Link
              path={
                props.teamId
                  ? `/app/${props.organizationId}/${props.teamId}/team-members-status-detail/${props.memberStatus.member.id}/`
                  : `/app/${props.organizationId}/all-members-status-detail/${props.memberStatus.member.id}/`
              }>
              <Typography
                variant={TypographyType.Normal}
                style={{
                  color: themeContext.colors.link,
                  fontSize: 12,
                  lineHeight: 15,
                  marginTop: 5,
                }}>
                詳細情報を見る
              </Typography>
            </Link>
          </View>
          {!props.isLoginMember &&
            props.isMeAdmin &&
            !!props.memberStatus.latestWorkingHistory &&
            !props.memberStatus.latestWorkingHistory?.end && (
              <View>
                <TouchableOpacity onPress={() => stopTask()}>
                  <Typography
                    variant={TypographyType.Normal}
                    style={{
                      color: themeContext.colors.link,
                      fontSize: 12,
                      lineHeight: 15,
                      marginTop: 5,
                    }}>
                    代理で作業停止する
                  </Typography>
                </TouchableOpacity>
              </View>
            )}
        </View>
      </View>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 8 }}>
        <View style={{ flexDirection: 'row' }}>
          {props!.memberStatus.member.department && (
            <>
              <Typography
                variant={TypographyType.Normal}
                style={{
                  color: themeContext.colors.description,
                  fontSize: 12,
                  lineHeight: 15,
                  marginTop: 5,
                }}
                ellipsis={true}>
                (
              </Typography>
              <Typography
                variant={TypographyType.Normal}
                style={{
                  color: themeContext.colors.description,
                  fontSize: 12,
                  lineHeight: 15,
                  marginTop: 5,
                  maxWidth: 200,
                }}
                ellipsis={true}>
                {props!.memberStatus.member.department}
              </Typography>
              <Typography
                variant={TypographyType.Normal}
                style={{
                  color: themeContext.colors.description,
                  fontSize: 12,
                  lineHeight: 15,
                  marginTop: 5,
                }}
                ellipsis={true}>
                )
              </Typography>
            </>
          )}
        </View>
        <View
          style={{
            flexDirection: 'column',
            alignItems: 'flex-end',
            justifyContent: 'center',
          }}>
          <Typography
            variant={TypographyType.Normal}
            style={{
              color: themeContext.colors.description,
              fontSize: 12,
              lineHeight: 15,
              marginTop: 5,
            }}>
            {!props.memberStatus.latestWorkingHistory?.end
              ? '現在作業中'
              : props.memberStatus.latestWorkingHistory?.end
              ? `${moment(props.memberStatus.latestWorkingHistory?.end).format(
                  'YYYY/MM/DD HH:mm'
                )}に作業停止`
              : '-'}
          </Typography>
        </View>
      </View>
      {props.memberStatus.latestWorkingHistory?.task && (
        <TouchableOpacity onPress={onPress}>
          <TaskContainer>
            <View>
              <Typography
                variant={TypographyType.Description}
                style={{ marginLeft: 14, marginTop: 4, fontSize: 10, lineHeight: 14 }}
                ellipsis={true}>
                {props.memberStatus.latestWorkingHistory!.project.projectName}
              </Typography>
              <Typography
                variant={TypographyType.Normal}
                style={{ marginLeft: 14, fontSize: 14, lineHeight: 16, marginVertical: 4 }}
                ellipsis={true}>
                {props.memberStatus.latestWorkingHistory!.task!.title}
              </Typography>
            </View>
            <TaskDetails>
              <View>
                <Typography variant={TypographyType.Description} style={{ fontSize: 10 }}>
                  優先度
                </Typography>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontSize: 12,
                    lineHeight: 14,
                    textAlign: 'center',
                    color: themeContext.colors.textColor,
                  }}>
                  {when(props.memberStatus.latestWorkingHistory!.task!.priority)
                    .on(
                      (v) => v === Priority.High,
                      () => '高'
                    )
                    .on(
                      (v) => v === Priority.Normal,
                      () => '中'
                    )
                    .on(
                      (v) => v === Priority.Low,
                      () => '低'
                    )
                    .otherwise(() => '-')}
                </Typography>
              </View>
              <View>
                <Typography variant={TypographyType.Description} style={{ fontSize: 10 }}>
                  作業時間
                </Typography>
                <TaskWorkingTime
                  task={props.memberStatus.latestWorkingHistory!.task! as Task}
                  style={{
                    fontSize: 12,
                    lineHeight: 14,
                    textAlign: 'center',
                    color: themeContext.colors.textColor,
                  }}
                />
              </View>
              <View>
                <Typography variant={TypographyType.Description} style={{ fontSize: 10 }}>
                  見積時間
                </Typography>
                <Typography
                  variant={TypographyType.Description}
                  style={{
                    fontSize: 12,
                    lineHeight: 14,
                    textAlign: 'center',
                    color: themeContext.colors.textColor,
                  }}>
                  {TimeUtil.formatForTask(
                    props.memberStatus.latestWorkingHistory!.task!.estimateTimeSec
                  )}
                </Typography>
              </View>
              <View>
                <Typography variant={TypographyType.Description} style={{ fontSize: 10 }}>
                  〆切日
                </Typography>
                <Typography
                  variant={TypographyType.Description}
                  style={{
                    fontSize: 12,
                    lineHeight: 14,
                    textAlign: 'center',
                    color: themeContext.colors.textColor,
                  }}>
                  {DateUtil.formatDateTime(
                    props.memberStatus.latestWorkingHistory!.task!.scheduledEndDateTime
                  )}
                </Typography>
              </View>
              <View>
                <Typography variant={TypographyType.Description} style={{ fontSize: 10 }}>
                  進捗率
                </Typography>
                <Typography
                  variant={TypographyType.Description}
                  style={{
                    fontSize: 12,
                    lineHeight: 14,
                    textAlign: 'center',
                    color: themeContext.colors.textColor,
                  }}>
                  {`${props.memberStatus.latestWorkingHistory!.task!.progressRate}%`}
                </Typography>
              </View>
            </TaskDetails>
            <TaskProgressBar task={props.memberStatus.latestWorkingHistory!.task! as Task} />
          </TaskContainer>
        </TouchableOpacity>
      )}
      {!props.memberStatus.latestWorkingHistory?.task &&
        props.memberStatus.latestWorkingHistory?.calendarEvent && (
          <TouchableOpacity onPress={onPress}>
            <TaskContainer>
              <View>
                <Typography
                  variant={TypographyType.Description}
                  style={{ marginLeft: 14, marginTop: 4, fontSize: 10, lineHeight: 14 }}
                  ellipsis={true}>
                  {props.memberStatus.latestWorkingHistory!.project!.projectName}
                </Typography>
                <Typography
                  variant={TypographyType.Normal}
                  style={{ marginLeft: 14, fontSize: 14, lineHeight: 16, marginVertical: 4 }}
                  ellipsis={true}>
                  {props.memberStatus.latestWorkingHistory!.calendarEvent!.eventName}
                </Typography>
              </View>
              <TaskDetails>
                <View>
                  <Typography variant={TypographyType.Description} style={{ fontSize: 10 }}>
                    優先度
                  </Typography>
                  <Typography
                    variant={TypographyType.Normal}
                    style={{
                      fontSize: 12,
                      lineHeight: 14,
                      textAlign: 'center',
                      color: themeContext.colors.textColor,
                    }}>
                    {'-'}
                  </Typography>
                </View>
                <View>
                  <Typography variant={TypographyType.Description} style={{ fontSize: 10 }}>
                    作業時間
                  </Typography>
                  <Typography variant={TypographyType.Normal}>
                    {TimeUtil.formatForTask(
                      props.memberStatus.latestWorkingHistory.calendarEvent!.workingTimeSec
                    )}
                  </Typography>
                </View>
                <View>
                  <Typography variant={TypographyType.Description} style={{ fontSize: 10 }}>
                    見積時間
                  </Typography>
                  <Typography
                    variant={TypographyType.Description}
                    style={{
                      fontSize: 12,
                      lineHeight: 14,
                      textAlign: 'center',
                      color: themeContext.colors.textColor,
                    }}>
                    {'-'}
                  </Typography>
                </View>
                <View>
                  <Typography variant={TypographyType.Description} style={{ fontSize: 10 }}>
                    〆切日
                  </Typography>
                  <Typography
                    variant={TypographyType.Description}
                    style={{
                      fontSize: 12,
                      lineHeight: 14,
                      textAlign: 'center',
                      color: themeContext.colors.textColor,
                    }}>
                    {'-'}
                  </Typography>
                </View>
                <View>
                  <Typography variant={TypographyType.Description} style={{ fontSize: 10 }}>
                    進捗率
                  </Typography>
                  <Typography
                    variant={TypographyType.Description}
                    style={{
                      fontSize: 12,
                      lineHeight: 14,
                      textAlign: 'center',
                      color: themeContext.colors.textColor,
                    }}>
                    {`-`}
                  </Typography>
                </View>
              </TaskDetails>
            </TaskContainer>
          </TouchableOpacity>
        )}
      {!props.memberStatus.latestWorkingHistory?.task &&
        !props.memberStatus.latestWorkingHistory?.calendarEvent && (
          <TaskContainer>
            <View style={{ justifyContent: 'center', alignItems: 'center', height: 80 }}>
              <Typography variant={TypographyType.Normal} style={{ fontSize: 14 }}>
                まだ作業開始していません
              </Typography>
            </View>
          </TaskContainer>
        )}
    </MainContainer>
  );
};

export default React.memo(MemberStatus);
