import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { StyleProp, Switch as OriginalSwitch, ViewStyle } from 'react-native';
import { ThemeContext } from 'styled-components/native';
import ColorUtil from '../../../../util/ColorUtil';
import { IThemePart } from '../../../theme';

interface IProps {
  value: boolean;
  onValueChange: (value: boolean) => void;
  style?: StyleProp<ViewStyle>;
  disabled?: boolean;
}

const Switch: React.FC<IProps> = React.memo((props: IProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const ref = useRef();
  const [value, setValue] = useState(props.value);
  const onValueChange = useCallback(
    (value) => {
      setValue(value);
      (ref.current as any)?.blur();
      props.onValueChange(value);
    },
    [props.onValueChange]
  );

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <OriginalSwitch
      ref={ref as any}
      style={props.style}
      value={value}
      onValueChange={onValueChange}
      disabled={props.disabled}
      trackColor={{
        true: ColorUtil.lignten(themeContext.colors.primary, 20),
        false: themeContext.colors.description,
      }}
      thumbColor={themeContext.colors.description}
      activeThumbColor={themeContext.colors.primary}
    />
  );
});

export default Switch;
