import Checkbox from '../checkbox';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { StyleProp, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';
import Select, { components, OptionProps } from 'react-select';
import styled, { ThemeContext } from 'styled-components/native';
import Typography, { TypographyType } from '../typography';
import Avatar from '../avatar';
import { IThemePart, IStyleTheme } from '../../../theme';
import Icon from '../icon';
import { v4 as uuidv4 } from 'uuid';
import CaretDownIcon from '../../molecules/image-icon/caret-down';
import { times } from 'lodash';

const Container = styled.View`
  min-height: 20px;
`;

const Filter = styled.View`
  position: absolute;
  top: 100%;
  width: 300px;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  border-radius: 5px;
  transition: all 0.4s;
  padding: 5px;
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
`;

const isClickFooterElement = (
  footerId: string,
  elem: HTMLElement | null | undefined,
  depth?: number
): boolean => {
  if (depth === 0) {
    return false;
  }
  if (!elem) {
    return false;
  }
  if (!elem.parentElement) {
    return false;
  }
  if (elem.parentElement?.id === footerId) {
    return true;
  }
  return isClickFooterElement(footerId, elem.parentElement, (depth ?? 5) - 1);
};

interface IOptionItemProps {
  optionProps: OptionProps<any, true>;
}

const OptionItem = React.memo((props: IOptionItemProps) => {
  return (
    <components.Option {...props.optionProps}>
      <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <View style={{ marginRight: 5 }}>
          <Checkbox value={props.optionProps.isSelected} />
        </View>
        {props.optionProps.data.imageUrl && (
          <Avatar
            size={25}
            name={props.optionProps.data.label}
            imageUrl={props.optionProps.data.imageUrl}
            containerStyle={{ marginRight: 10 }}
          />
        )}
        <Typography variant={TypographyType.Normal} ellipsis={true}>
          {props.optionProps.children}
        </Typography>
        {/* {props.optionProps.data.subLabel && (
          <Typography
            variant={TypographyType.Description}
            ellipsis={true}
            style={{ marginLeft: '0.3rem' }}>
            - {props.optionProps.data.subLabel}
          </Typography>
        )} */}
      </View>
    </components.Option>
  );
});

export interface ListValueMap {
  label: string;
  value: string;
  imageUrl?: string | null;
  subLabel?: string | null;
}

export interface IProps {
  pickerItems?: ListValueMap[];
  value?: string[];
  onChange?: (values: ListValueMap[]) => void;
  onFocus?: () => void;
  onBlur?: (values: ListValueMap[]) => void;
  emptyPickerItem?: ListValueMap;
  containerStyle?: StyleProp<ViewStyle>;
  listStyle?: StyleProp<ViewStyle>;
  textStyle?: TextStyle;
  placeHolder?: string;
  renderLabel?: (values: ListValueMap[]) => React.ReactElement;
  footerElement?: React.ReactElement;
}

const MultiPickerFilter: React.FC<IProps> = (props: IProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [selectedItems, setSelectedItems] = useState<ListValueMap[]>([]);
  const [showFilter, setShowFilter] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [filterId, setFilterId] = useState('');

  const renderOption = useCallback((optionProps) => <OptionItem optionProps={optionProps} />, []);

  useEffect(() => {
    if (props.value && Array.isArray(props.value)) {
      setSelectedItems(
        props.pickerItems!.filter((item) => props.value!.findIndex((v) => v === item.value) !== -1)
      );
    }
  }, [props.value]);

  useEffect(() => setFilterId(uuidv4()), []);

  return (
    <Container>
      {props.renderLabel ? (
        <TouchableOpacity onPress={() => setShowFilter(!showFilter)}>
          {props.renderLabel(selectedItems)}
        </TouchableOpacity>
      ) : (
        <TouchableOpacity
          style={[
            {
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            },
            props.containerStyle,
          ]}
          onPress={() => setShowFilter(!showFilter)}>
          <Typography
            variant={TypographyType.Description}
            ellipsis
            style={{
              fontSize: 14,
              lineHeight: 16,
              ...props.textStyle,
            }}>
            {selectedItems.length === 0
              ? 'すべて'
              : selectedItems.length === 1
              ? [props.emptyPickerItem, ...(props.pickerItems || [])]?.find(
                  (item) => item?.value === selectedItems[0].value
                )?.label
              : `${selectedItems.length}件選択中`}
          </Typography>
          <CaretDownIcon
            size={14}
            containerStyle={{ marginRight: 5 }}
            onPress={() => setShowFilter(!showFilter)}
          />
        </TouchableOpacity>
      )}

      {showFilter ? (
        <Filter style={[props.listStyle]}>
          <Select
            isMulti
            isSearchable
            defaultMenuIsOpen
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            menuIsOpen
            autoFocus
            value={selectedItems}
            noOptionsMessage={() => '選択可能なものがありません'}
            placeholder={props.placeHolder || 'フィルターを検索'}
            filterOption={
              ({ label, value, data }, inputString) =>
                !inputString || // 入力がなければすべて表示
                (value !== props.emptyPickerItem?.value && // 入力がある場合、空を表す選択肢は表示しない
                  label.includes(inputString)) ||
                data.subLabel?.includes(inputString) // ラベル・サブラベルで絞り込む
            }
            onChange={(items) => {
              setIsEditing(false);
              setSelectedItems((items as ListValueMap[]) || []);
              if (props.onChange) {
                props.onChange((items as ListValueMap[]) || []);
              }
            }}
            onFocus={props.onFocus}
            onBlur={(e) => {
              if (isClickFooterElement(filterId, e.relatedTarget?.parentElement)) {
                return;
              }
              setShowFilter(false);
              if (props.onBlur) {
                props.onBlur(selectedItems || []);
              }
            }}
            onKeyDown={(event) => {
              switch (event.nativeEvent.key) {
                case 'Escape':
                  setShowFilter(false);
                  setSelectedItems([]);
                  if (props.onBlur) {
                    props.onBlur([]);
                  }
                  break;
                case 'Enter':
                  if (!isEditing) {
                    event.preventDefault();
                    setShowFilter(false);
                    if (props.onBlur) {
                      props.onBlur(selectedItems || []);
                    }
                  }
                  break;
                default:
                  setIsEditing(true);
                  break;
              }
            }}
            options={
              props.emptyPickerItem
                ? [props.emptyPickerItem, ...(props.pickerItems || [])]
                : props.pickerItems
            }
            styles={{
              option: (styles, { isSelected, isFocused }) => {
                return {
                  ...styles,
                  backgroundColor:
                    isSelected && !isFocused
                      ? themeContext.colors.baseColor
                      : styles.backgroundColor,
                  color: isSelected ? themeContext.colors.textColor : styles.color,
                  cursor: 'pointer',
                  padding: '7px 12px',
                };
              },
              menu: (styles) => {
                return {
                  ...styles,
                  boxShadow: 'none',
                  top: 'auto',
                };
              },
              menuList: (styles) => {
                return {
                  ...styles,
                };
              },
              container: (styles, containerProps) => {
                return {
                  ...styles,
                  paddingBottom:
                    containerProps.options.length > 7
                      ? 310
                      : containerProps.options.length === 0
                      ? 50
                      : 10 + containerProps.options.length * 40,
                };
              },
            }}
            components={{
              Option: renderOption,
              DropdownIndicator: null,
            }}
          />
          <View nativeID={filterId}>{props.footerElement}</View>
        </Filter>
      ) : (
        <></>
      )}
    </Container>
  );
};

export default MultiPickerFilter;
