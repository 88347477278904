import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled, { ThemeContext } from 'styled-components/native';
import moment from 'moment-timezone';
import {
  ClosingSetting,
  WorkingHistory,
  useAllMemberWorkingHistoriesSpecifyTermQuery,
  useCloseTermMutation,
  useClosingSettingsQuery,
  useMeQuery,
  useTimeZonesQuery,
  useWorkingHistoriesSpecifyTermQuery,
} from '../../../../../../../graphql/api/API';
import { LoginUserContext } from '../../../../../../../modules/auth/LoginUserContext';
import Button from '../../../../../../presentational/atoms/button';
import Form from '../../../../../../presentational/atoms/form';
import Typography, { TypographyType } from '../../../../../../presentational/atoms/typography';
import { IStyleTheme, IThemePart } from '../../../../../../theme';
import SettingsElement from '../../../../organisms/settings-element';
import { View } from 'react-native';
import DateTimePicker from '../../../../../../presentational/atoms/date-time-picker/index.web';
import VirtualizedFlatList from '../../../../../../presentational/atoms/list2/virtualized-flat-list';
import Avatar from '../../../../../../presentational/atoms/avatar';
import { useHistory } from 'react-router';
import UrlUtil from '../../../../../../../util/UrlUtil';

const Container = styled.View`
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
`;

const Item = styled.View`
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  min-width: 200px;
  border-bottom-width: 1px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
`;

interface IClosingSettingsModalProps {
  closeModal: () => void;
  closingSettings: ClosingSetting;
}

const ClosingSettingsModal = (props: IClosingSettingsModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const history = useHistory();
  const [step, setStep] = useState<'input' | 'confirm'>('input');
  const [timeZoneId, setTimeZoneId] = useState<string | null>(null);
  const [targetDate, setTargetDate] = useState<moment.Moment>(
    props.closingSettings.targetDateTime ? moment(props.closingSettings.targetDateTime) : moment()
  );
  const { loading: loadingTimeZone, data: dataTimeZone } = useTimeZonesQuery();
  const [closeTerm] = useCloseTermMutation();
  const { loading, data, fetchMore } = useAllMemberWorkingHistoriesSpecifyTermQuery({ //TODO TDV1-112
    variables: {
      input: {
        end: targetDate.endOf('day').toISOString(),
      },
      offset: 0,
      limit: 100,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    const japanTimeZone = (dataTimeZone?.timeZones ?? []).find(
      (zone) => zone!.code === 'Asia/Tokyo'
    );
    if (japanTimeZone) {
      setTimeZoneId(japanTimeZone.id);
    }
  }, [dataTimeZone]);

  useEffect(() => {
    setTargetDate(
      props.closingSettings.targetDateTime ? moment(props.closingSettings.targetDateTime) : moment()
    );
  }, [props.closingSettings.targetDateTime]);

  useEffect(() => {
    setStep('input');
  }, []);

  const renderItem = useCallback((workingHistory: WorkingHistory, index: number) => {
    return (
      <Item>
        <View style={{ width: 150, flexDirection: 'row', alignItems: 'center' }}>
          <Avatar
            size={30}
            imageUrl={workingHistory.workingMember.profileImageUrl}
            name={workingHistory.workingMember.name ?? ''}
            containerStyle={{ marginRight: 6 }}
          />
          <Typography
            variant={TypographyType.Normal}
            ellipsis={true}
            style={{
              width: 120,
              fontSize: 13,
              color: themeContext.colors.textColor,
              textAlign: 'left',
            }}>
            {workingHistory.workingMember.name}
          </Typography>
        </View>
        <Typography
          variant={TypographyType.Normal}
          ellipsis={true}
          style={{
            width: 130,
            fontSize: 13,
            color: themeContext.colors.textColor,
            textAlign: 'left',
          }}>
          {workingHistory.task?.project.team.name}
        </Typography>
        <Typography
          variant={TypographyType.Normal}
          ellipsis={true}
          style={{
            width: 250,
            fontSize: 13,
            color: themeContext.colors.textColor,
            textAlign: 'left',
          }}>
          {workingHistory.task?.project.name}
        </Typography>
        <Typography
          variant={TypographyType.Normal}
          ellipsis={true}
          style={{
            width: 250,
            fontSize: 13,
            color: themeContext.colors.textColor,
            textAlign: 'left',
          }}>
          {workingHistory.task?.title}
        </Typography>
        <Typography
          variant={TypographyType.Normal}
          ellipsis={true}
          style={{
            width: 150,
            fontSize: 13,
            color: themeContext.colors.textColor,
            textAlign: 'left',
          }}>
          {`${moment(workingHistory.start).format('YYYY/MM/DD HH:mm')}〜`}
        </Typography>
      </Item>
    );
  }, []);

  if (!data?.allMemberWorkingHistoriesSpecifyTerm) {
    return <></>;
  }

  return (
    <Form style={{ paddingVertical: 20 }}>
      {step === 'input' && (
        <View style={{ zIndex: 2 }}>
          <View>
            <Typography
              variant={TypographyType.Normal}
              style={{
                color: themeContext.colors.description,
                textAlign: 'center',
              }}>{`作業履歴データを登録・編集・削除を${'\n'}出来ないようにする期間を指定してください`}</Typography>
          </View>
          <View
            style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 2, marginTop: 13 }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                textAlign: 'center',
              }}>{`〜`}</Typography>
            <View style={{ width: 100, marginLeft: 10 }}>
              <DateTimePicker
                mode={'date'}
                name={'datetimeValue'}
                initialValue={targetDate}
                onChange={async (date) => {
                  if (date === null) {
                    return;
                  }
                  setTargetDate(date);
                }}
                validate={{
                  required: {
                    value: true,
                    message: '値を入力してください',
                  },
                }}
              />
            </View>
            <Typography
              variant={TypographyType.Normal}
              style={{
                textAlign: 'center',
              }}>{`迄`}</Typography>
          </View>
          <View
            style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
            <Button
              text={'次へ'}
              style={{ minWidth: 100, marginRight: 10, marginVertical: 10 }}
              onPress={async () => {
                setStep('confirm');
              }}
            />
            <Button
              text={'キャンセル'}
              style={{
                minWidth: 100,
                marginRight: 10,
                marginVertical: 10,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.primary }}
              disableValidate={true}
              onPress={() => {
                props.closeModal();
              }}
            />
          </View>
        </View>
      )}
      {step === 'confirm' && (
        <View style={{ zIndex: 2 }}>
          <View>
            <Typography
              variant={TypographyType.Normal}
              style={{
                color: themeContext.colors.description,
                textAlign: 'center',
              }}>{`以下の期間を締め処理してもよろしいですか？`}</Typography>
          </View>
          <View
            style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 2, marginTop: 13 }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                textAlign: 'center',
              }}>{`〜 ${targetDate.format('YYYY/MM/DD')} 迄`}</Typography>
          </View>
          <View
            style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
            <Button
              text={'締め処理を実行する'}
              completeText="実行しました"
              style={{
                minWidth: 100,
                marginRight: 10,
                marginVertical: 10,
                borderColor: themeContext.colors.error,
                borderRadius: 3,
                borderWidth: 1,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.error }}
              onPress={async () => {
                await closeTerm({
                  variables: {
                    input: {
                      timeZoneId: props.closingSettings.timeZone.id,
                      targetDateTime: moment(targetDate).endOf('day').toISOString(),
                      versionNo: props.closingSettings.versionNo,
                    },
                  },
                });
                props.closeModal();
              }}
            />
            <Button
              text={'キャンセル'}
              style={{
                minWidth: 100,
                marginRight: 10,
                marginVertical: 10,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.primary }}
              disableValidate={true}
              onPress={() => {
                props.closeModal();
              }}
            />
          </View>
        </View>
      )}
      {data.allMemberWorkingHistoriesSpecifyTerm.length > 0 && (
        <View style={{ marginTop: 20, maxWidth: 900, width: '100%' }}>
          <View>
            <Typography
              variant={TypographyType.Normal}
              style={{
                color: themeContext.colors.error,
                textAlign: 'center',
              }}>{`対象期間に作業中のデータがあります。${'\n'}締め処理を行うと、強制的に作業が停止されますのでご注意ください`}</Typography>
          </View>
          <View
            style={{
              backgroundColor: themeContext.colors.subHeader,
              paddingVertical: 10,
              paddingHorizontal: 10,
            }}>
            <Typography
              variant={TypographyType.SubTitle}
              ellipsis={true}
              style={{ color: '#FFFFFF', fontSize: 14, fontWeight: '600' }}>
              対象期間の作業中のデータ
            </Typography>
          </View>
          <VirtualizedFlatList
            style={{
              maxHeight: 250,
              height: 'auto',
              borderWidth: 1,
              borderColor: themeContext.colors.separator,
            }}
            items={data.allMemberWorkingHistoriesSpecifyTerm}
            onPress={(his) => {
              if ((his as WorkingHistory)?.task?.id) {
                history.push(UrlUtil.createTaskDetailUrlById((his as WorkingHistory).task!.id!));
              }
            }}
            renderItem={renderItem as any}
            headerElement={
              <Item>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    width: 164,
                    fontSize: 13,
                    color: themeContext.colors.textColor,
                    textAlign: 'left',
                    fontWeight: '600',
                  }}>
                  作業者
                </Typography>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    width: 130,
                    fontSize: 13,
                    color: themeContext.colors.textColor,
                    textAlign: 'left',
                    fontWeight: '600',
                  }}>
                  チーム名
                </Typography>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    width: 250,
                    fontSize: 13,
                    color: themeContext.colors.textColor,
                    textAlign: 'left',
                    fontWeight: '600',
                  }}>
                  プロジェクト名
                </Typography>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    width: 250,
                    fontSize: 13,
                    color: themeContext.colors.textColor,
                    textAlign: 'left',
                    fontWeight: '600',
                  }}>
                  タスク名
                </Typography>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    width: 150,
                    fontSize: 13,
                    color: themeContext.colors.textColor,
                    textAlign: 'left',
                    fontWeight: '600',
                  }}>
                  作業開始日時
                </Typography>
              </Item>
            }
            getKey={(item) => (item as any).id}
            itemHeight={43}
          />
        </View>
      )}
    </Form>
  );
};

interface IClosingSettingsClearModalProps {
  closeModal: () => void;
  closingSettings: ClosingSetting;
}

const ClosingSettingsClearModal = (props: IClosingSettingsClearModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [closeTerm] = useCloseTermMutation({
    variables: {
      input: {
        timeZoneId: props.closingSettings.timeZone.id,
        targetDateTime: null,
        versionNo: props.closingSettings.versionNo,
      },
    },
  });

  return (
    <Form style={{ paddingVertical: 20 }}>
      <Typography
        variant={TypographyType.Normal}
        style={{
          textAlign: 'center',
        }}>{`締め処理を解除しますか？`}</Typography>
      <View style={{ marginTop: 20 }}>
        <Typography
          variant={TypographyType.Normal}
          style={{
            color: themeContext.colors.description,
            textAlign: 'center',
          }}>{`締め処理を解除すると、全ての期間の作業履歴データを${'\n'}登録・編集できるようになります。`}</Typography>
      </View>
      <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 20 }}>
        <Button
          text={'締め処理を解除する'}
          completeText="変更しました"
          style={{ minWidth: 100, marginRight: 10, marginVertical: 10 }}
          onPress={async () => {
            await closeTerm();
            props.closeModal();
          }}
        />

        <Button
          text={'キャンセル'}
          style={{
            minWidth: 100,
            marginRight: 10,
            marginVertical: 10,
            backgroundColor: 'transparent',
          }}
          textStyle={{ color: themeContext.colors.primary }}
          disableValidate={true}
          onPress={() => {
            props.closeModal();
          }}
        />
      </View>
    </Form>
  );
};

const ClosingSettingsPage = () => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const { loading, data } = useClosingSettingsQuery({
    fetchPolicy: 'network-only',
  });
  const { loading: loadingMe, data: dataMe } = useMeQuery({
    fetchPolicy: 'network-only',
  });

  if (loading || loadingMe || !data?.closingSettings) {
    return <></>;
  }

  return (
    <Container>
      <View style={{ marginBottom: 30 }}>
        <Typography
          variant={
            TypographyType.Normal
          }>{`締め処理を行うと、指定した期間の作業履歴データについて、${'\n'}編集・追加・削除を行えないようにすることができます。`}</Typography>
      </View>
      <View style={{ marginTop: 30 }}>
        {!dataMe!.me!.adminRole ? (
          <>
            <View>
              <Typography
                variant={TypographyType.Description}
                style={{
                  color: themeContext.colors.error,
                  fontSize: 18,
                }}>{`変更するには管理者権限が必要です。`}</Typography>
            </View>
            <SettingsElement title={'締め処理対象期間'}>
              <Typography variant={TypographyType.Normal}>
                {data?.closingSettings?.targetDateTime
                  ? moment(data.closingSettings.targetDateTime).format('ll')
                  : 'なし'}
              </Typography>
            </SettingsElement>
          </>
        ) : (
          <>
            <SettingsElement
              title={'締め処理対象期間'}
              changeText="締め処理対象期間を変更する"
              containerStyle={{ marginBottom: 0 }}
              modal={(closeModal) => (
                <ClosingSettingsModal
                  closeModal={closeModal}
                  closingSettings={data.closingSettings as ClosingSetting}
                />
              )}>
              <Typography variant={TypographyType.Normal}>
                {data?.closingSettings?.targetDateTime
                  ? moment(data.closingSettings.targetDateTime).format('ll')
                  : 'なし'}
              </Typography>
            </SettingsElement>
            {data.closingSettings.targetDateTime && (
              <SettingsElement
                title=""
                changeText="締め処理を解除する"
                modal={(closeModal) => (
                  <ClosingSettingsClearModal
                    closeModal={closeModal}
                    closingSettings={data.closingSettings as ClosingSetting}
                  />
                )}>
                <></>
              </SettingsElement>
            )}
          </>
        )}
      </View>
    </Container>
  );
};

export default ClosingSettingsPage;
