import React, { useCallback, useContext, useMemo, useState } from 'react';
import { View, Image, Text } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../../theme';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import Icon from '../../../../presentational/atoms/icon';
import { useHistory } from 'react-router';
import {
  MentionType,
  NotificationType,
  NotificationV2,
  TaskRemainderType,
  UnreadNotificationCountDocument,
  useNotificationLazyQuery,
  useNotificationsV2Query,
  useUpdateNotificationToReadV2Mutation,
} from '../../../../../graphql/api/API';
import VirtualizedFlatList from '../../../../presentational/atoms/list2/virtualized-flat-list';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import moment from 'moment-timezone';
import { convertFromRaw, EditorState } from 'draft-js';
import Avatar from '../../../../presentational/atoms/avatar';
import noData from '../../../../../base64Images/no-data/no-data-2';
import Spinner from '../../../../presentational/atoms/spinner';
import Input from '../../../../presentational/atoms/input';
import Form from '../../../../presentational/atoms/form';
import UrlUtil from '../../../../../util/UrlUtil';
import TimeUtil from '../../../../../util/TimeUtil';
import EmailOpenIcon from '../../../../presentational/molecules/image-icon/email-open';
import EmailAlertIcon from '../../../../presentational/molecules/image-icon/email-alert';

const TaskListWrapper = styled.View`
  padding: 10px;
  height: calc(100vh - 57px - 20px);
`;

const MessageContainer = styled.View`
  display: flex;
  flex-direction: row;
  height: 100px;
  padding: 5px 10px;
  margin: 5px 0;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 6px;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.4s;
  z-index: 100;
  border-width: 1px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
`;

interface IMessageItemProps {
  message: NotificationV2;
}

const MessageItem = (props: IMessageItemProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  return (
    <MessageContainer>
      <View style={{ flexDirection: 'column', height: '100%' }}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <View style={{ marginLeft: 3, marginTop: 5 }}>
            {props.message.readDateTime !== null ? (
              <EmailOpenIcon size={20} />
            ) : (
              <EmailAlertIcon size={20} />
            )}
          </View>
          <View style={{ flexDirection: 'column' }}>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Typography
                variant={TypographyType.Description}
                style={{ fontSize: 10, lineHeight: 15, marginLeft: 10 }}
                ellipsis={true}>
                プロジェクト「
              </Typography>
              <Typography
                variant={TypographyType.Description}
                style={{ fontSize: 13, lineHeight: 15, marginLeft: 2, maxWidth: 300 }}
                ellipsis={true}>
                {props.message.mention!.projectName}
              </Typography>
              <Typography
                variant={TypographyType.Description}
                style={{ fontSize: 10, lineHeight: 15, marginLeft: 2 }}
                ellipsis={true}>
                」
              </Typography>
            </View>
            {(props.message.mention?.taskId) && (
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Typography
                  variant={TypographyType.Description}
                  style={{ fontSize: 10, lineHeight: 15, marginLeft: 10 }}
                  ellipsis={true}>
                  タスク「
                </Typography>
                <Typography
                  variant={TypographyType.Description}
                  style={{ fontSize: 13, lineHeight: 15, marginLeft: 2, maxWidth: 300 }}
                  ellipsis={true}>
                  {props.message.mention!.taskTitle}
                </Typography>
                <Typography
                  variant={TypographyType.Description}
                  style={{ fontSize: 10, lineHeight: 15, marginLeft: 2 }}
                  ellipsis={true}>
                  」
                </Typography>
              </View>
            )}
          </View>
        </View>
        <View
          style={{
            flex: 1,
            alignItems: 'center',
            flexDirection: 'row',
            maxWidth: 400,
          }}>
          <Avatar
            containerStyle={{ marginLeft: 3 }}
            size={28}
            name={props.message.mention!.sendMember!.name!}
            imageUrl={props.message.mention!.sendMember.profileImageUrl}
          />
          <Typography
            variant={TypographyType.Normal}
            style={{ fontSize: 14, marginLeft: 10 }}
            ellipsis={false}>
            {props.message.mention!.sendMember!.name!}さんがコメントしました
          </Typography>
        </View>
        <Typography
          variant={TypographyType.Description}
          style={{ fontSize: 11, lineHeight: 13, marginTop: 5, marginLeft: 3 }}>
          {moment(props.message.sendDateTime).format('YYYY/MM/DD HH:mm')}
        </Typography>
      </View>
      <View
        style={{
          flex: 1,
          height: '100%',
          marginLeft: 20,
          paddingHorizontal: 5,
          paddingVertical: 10,
          overflow: 'hidden',
          borderWidth: 1,
          borderColor: themeContext.colors.separator,
        }}>
        <Form>
          <Input
            initialValue={
             props.message.mention?.message
            }
            name={'notification'}
            type={'rich-text-editor'}
            showToolBarAlways={false}
            multiline={true}
            readonly={true}
          />
          <View
            style={
              {
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                cursor: 'pointer',
              } as any
            }
          />
        </Form>
      </View>
    </MessageContainer>
  );
};

interface IProjectAssignedItemProps {
  message: NotificationV2;
}

const ProjectAssignedItem = (props: IProjectAssignedItemProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  return (
    <MessageContainer>
      <View style={{ flexDirection: 'column', height: '100%' }}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <View style={{ marginLeft: 3, marginTop: 5 }}>
            {props.message.readDateTime !== null ? (
              <EmailOpenIcon size={20} />
            ) : (
              <EmailAlertIcon size={20} />
            )}
          </View>
          <View style={{ flexDirection: 'column' }}>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Typography
                variant={TypographyType.Description}
                style={{ fontSize: 10, lineHeight: 15, marginLeft: 10 }}
                ellipsis={true}>
                プロジェクト「
              </Typography>
              <Typography
                variant={TypographyType.Description}
                style={{ fontSize: 13, lineHeight: 15, marginLeft: 2, maxWidth: 300 }}
                ellipsis={true}>
                {props.message.projectAssignNotification!.projectName}
              </Typography>
              <Typography
                variant={TypographyType.Description}
                style={{ fontSize: 10, lineHeight: 15, marginLeft: 2 }}
                ellipsis={true}>
                」
              </Typography>
            </View>
          </View>
        </View>
        <View
          style={{
            flex: 1,
            alignItems: 'center',
            flexDirection: 'row',
          }}>
          <Avatar
            containerStyle={{ marginLeft: 3 }}
            size={28}
            name={props.message.projectAssignNotification!.sendMember!.name!}
            imageUrl={props.message.projectAssignNotification!.sendMember.profileImageUrl}
          />
          <Typography
            variant={TypographyType.Normal}
            style={{
              fontSize: 14,
              marginLeft: 10,
              width: '100%',
            }}
            ellipsis={true}>
            {props.message.projectAssignNotification!.sendMember!.name!}
            {`さんから「${
              props.message.projectAssignNotification!.projectName
            }」が割り当てられました`}
          </Typography>
        </View>
        <Typography
          variant={TypographyType.Description}
          style={{ fontSize: 11, lineHeight: 13, marginTop: 5, marginLeft: 3 }}>
          {moment(props.message.sendDateTime).format('YYYY/MM/DD HH:mm')}
        </Typography>
      </View>
    </MessageContainer>
  );
};

interface ITaskAssignedItemProps {
  message: NotificationV2;
}

const TaskAssignedItem = (props: ITaskAssignedItemProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  return (
    <MessageContainer>
      <View style={{ flexDirection: 'column', height: '100%' }}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <View style={{ marginLeft: 3, marginTop: 5 }}>
            {props.message.readDateTime !== null ? (
              <EmailOpenIcon size={20} />
            ) : (
              <EmailAlertIcon size={20} />
            )}
          </View>
          <View style={{ flexDirection: 'column' }}>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Typography
                variant={TypographyType.Description}
                style={{ fontSize: 10, lineHeight: 15, marginLeft: 10 }}
                ellipsis={true}>
                プロジェクト「
              </Typography>
              <Typography
                variant={TypographyType.Description}
                style={{ fontSize: 13, lineHeight: 15, marginLeft: 2, maxWidth: 300 }}
                ellipsis={true}>
                {props.message.taskAssignNotification?.projectName}
              </Typography>
              <Typography
                variant={TypographyType.Description}
                style={{ fontSize: 10, lineHeight: 15, marginLeft: 2 }}
                ellipsis={true}>
                」
              </Typography>
            </View>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Typography
                variant={TypographyType.Description}
                style={{ fontSize: 10, lineHeight: 15, marginLeft: 10 }}
                ellipsis={true}>
                タスク「
              </Typography>
              <Typography
                variant={TypographyType.Description}
                style={{ fontSize: 13, lineHeight: 15, marginLeft: 2, maxWidth: 300 }}
                ellipsis={true}>
                {props.message.taskAssignNotification!.taskTitle}
              </Typography>
              <Typography
                variant={TypographyType.Description}
                style={{ fontSize: 10, lineHeight: 15, marginLeft: 2 }}
                ellipsis={true}>
                」
              </Typography>
            </View>
          </View>
        </View>
        <View
          style={{
            flex: 1,
            alignItems: 'center',
            flexDirection: 'row',
          }}>
          <Avatar
            containerStyle={{ marginLeft: 3 }}
            size={28}
            name={props.message.taskAssignNotification!.sendMember!.name!}
            imageUrl={props.message.taskAssignNotification!.sendMember.profileImageUrl}
          />
          <Typography
            variant={TypographyType.Normal}
            style={{
              fontSize: 14,
              marginLeft: 10,
              width: '100%',
            }}
            ellipsis={true}>
            {props.message.taskAssignNotification!.sendMember!.name!}
            {`さんから「${props.message.taskAssignNotification!.taskTitle}」が割り当てられました`}
          </Typography>
        </View>
        <Typography
          variant={TypographyType.Description}
          style={{ fontSize: 11, lineHeight: 13, marginTop: 5, marginLeft: 3 }}>
          {moment(props.message.sendDateTime).format('YYYY/MM/DD HH:mm')}
        </Typography>
      </View>
    </MessageContainer>
  );
};

interface ITaskRemainderItemProps {
  message: NotificationV2;
}

const TaskRemainderItem = (props: ITaskRemainderItemProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  return (
    <MessageContainer>
      <View style={{ flexDirection: 'column', height: '100%' }}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <View style={{ marginLeft: 3, marginTop: 5 }}>
            {props.message.readDateTime !== null ? (
              <EmailOpenIcon size={20} />
            ) : (
              <EmailAlertIcon size={20} />
            )}
          </View>
          <View style={{ flexDirection: 'column' }}>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Typography
                variant={TypographyType.Description}
                style={{ fontSize: 10, lineHeight: 15, marginLeft: 10 }}
                ellipsis={true}>
                プロジェクト「
              </Typography>
              <Typography
                variant={TypographyType.Description}
                style={{ fontSize: 13, lineHeight: 15, marginLeft: 2, maxWidth: 300 }}
                ellipsis={true}>
                {props.message.taskRemainderNotification?.projectName}
              </Typography>
              <Typography
                variant={TypographyType.Description}
                style={{ fontSize: 10, lineHeight: 15, marginLeft: 2 }}
                ellipsis={true}>
                」
              </Typography>
            </View>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Typography
                variant={TypographyType.Description}
                style={{ fontSize: 10, lineHeight: 15, marginLeft: 10 }}
                ellipsis={true}>
                タスク「
              </Typography>
              <Typography
                variant={TypographyType.Description}
                style={{ fontSize: 13, lineHeight: 15, marginLeft: 2, maxWidth: 300 }}
                ellipsis={true}>
                {props.message.taskRemainderNotification!.taskTitle}
              </Typography>
              <Typography
                variant={TypographyType.Description}
                style={{ fontSize: 10, lineHeight: 15, marginLeft: 2 }}
                ellipsis={true}>
                」
              </Typography>
            </View>
          </View>
        </View>
        <View
          style={{
            flex: 1,
            alignItems: 'center',
            flexDirection: 'row',
          }}>
          {props.message.taskRemainderNotification!.taskRemainderType ===
          TaskRemainderType.DeadlineNear ? (
            <View style={{ flexDirection: 'column' }}>
              <Typography
                variant={TypographyType.Normal}
                style={{
                  fontSize: 14,
                  marginLeft: 10,
                  width: '100%',
                }}
                ellipsis={true}>
                {`「${
                  props.message.taskRemainderNotification!.taskTitle
                }」の〆切が近づいて来ています。`}
              </Typography>
              <Typography
                variant={TypographyType.Normal}
                style={{
                  fontSize: 14,
                  marginLeft: 10,
                  width: '100%',
                }}
                ellipsis={true}>
                {`〆切日:${moment(
                  props.message.taskRemainderNotification!.scheduledEndDateTime
                ).format('lll')}`}
              </Typography>
            </View>
          ) : (
            <View style={{ flexDirection: 'column' }}>
              <Typography
                variant={TypographyType.Normal}
                style={{
                  fontSize: 14,
                  marginLeft: 10,
                  width: '100%',
                }}
                ellipsis={true}>
                {`「${props.message.taskRemainderNotification!.taskTitle}」の〆切が過ぎました。`}
              </Typography>
              <Typography
                variant={TypographyType.Normal}
                style={{
                  fontSize: 14,
                  marginLeft: 10,
                  width: '100%',
                }}
                ellipsis={true}>
                {`〆切日:${moment(
                  props.message.taskRemainderNotification!.scheduledEndDateTime
                ).format('lll')}`}
              </Typography>
            </View>
          )}
        </View>
        <Typography
          variant={TypographyType.Description}
          style={{ fontSize: 11, lineHeight: 13, marginTop: 5, marginLeft: 3 }}>
          {moment(props.message.sendDateTime).format('YYYY/MM/DD HH:mm')}
        </Typography>
      </View>
    </MessageContainer>
  );
};

interface IMentionListProps {
  filter: Array<'read' | 'unread'>;
}

const MentionList = React.memo((props: IMentionListProps) => {
  const pageSize = 20;
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, __] = useContext(LoginUserContext);
  const history = useHistory();
  const { loading, data, fetchMore } = useNotificationsV2Query({
    variables: {
      offset: 0,
      limit: pageSize,
      read: props.filter.includes('read'),
      unread: props.filter.includes('unread')
    },
    fetchPolicy: 'network-only',
  });
  const [updateNotificationToRead, _] = useUpdateNotificationToReadV2Mutation({
    refetchQueries: [
      {
        query: UnreadNotificationCountDocument,
      },
    ],
  });
  const [fetchNotification] = useNotificationLazyQuery();

  const onPress = useCallback(
    (message: any) => {
      const data = message as NotificationV2;
      if (data.notificationType === NotificationType.Mention) {
        if (data.mention?.mentionType === MentionType.TaskDescription) {
          // 対象のタスク・コメントへ移動する
          const taskId = (data.mention!.taskId)!;
          history.push(
            UrlUtil.createTaskDetailUrlById(
              taskId
            )
          );
        } else if (data.mention?.mentionType === MentionType.Comment) {
            // 対象のタスク・コメントへ移動する
            const taskId = (data.mention!.taskId)!;
            history.push(
              UrlUtil.createTaskDetailUrlById(
                taskId,
                `comment/${data.mention!.commentId!}/?forcusComment=true`
              )
            );
        } else {
          history.push(UrlUtil.moveProjectDetailUrlById(data.mention!.projectId!));
        }
      }
      if (data.notificationType === NotificationType.ProjectAssigned) {
        // 対象のプロジェクトへ移動する
        history.push(UrlUtil.moveProjectDetailUrlById(data.projectAssignNotification!.projectId));
      }
      if (data.notificationType === NotificationType.TaskAssigned) {
        // 対象のタスク・コメントへ移動する
        history.push(UrlUtil.createTaskDetailUrlById(data.taskAssignNotification!.taskId!));
      }
      if (
        data.notificationType === NotificationType.TaskDeadlineNear ||
        data.notificationType === NotificationType.TaskDeadlinePassed
      ) {
        // 対象のタスク・コメントへ移動する
        history.push(UrlUtil.createTaskDetailUrlById(data.taskRemainderNotification!.taskId));
      }

      if (!data.readDateTime) {
        updateNotificationToRead({
          variables: {
            id: data.id,
          },
          refetchQueries: [
            {
              query: UnreadNotificationCountDocument,
            },
          ],
        });
        fetchNotification({
          variables: {
            id: data.id!,
          },
        });
      }
    },
    [updateNotificationToRead, fetchNotification, history]
  );

  if (loading) {
    return <></>;
  }

  return (
    <TaskListWrapper>
      <Spinner loading={loading} />
      <VirtualizedFlatList
        items={data?.notificationsV2 ?? []}
        style={{
          height: 'calc(100vh - 145px)',
        }}
        renderItem={(item, index) => {
          if ((item as NotificationV2).notificationType === NotificationType.Mention) {
            return <MessageItem message={item as NotificationV2} />;
          }
          if ((item as NotificationV2).notificationType === NotificationType.ProjectAssigned) {
            return <ProjectAssignedItem message={item as NotificationV2} />;
          }
          if ((item as NotificationV2).notificationType === NotificationType.TaskAssigned) {
            return <TaskAssignedItem message={item as NotificationV2} />;
          }
          return <TaskRemainderItem message={item as NotificationV2} />;
        }}
        getKey={(message) => (message as any).id}
        itemHeight={110}
        onPress={onPress}
        listEmptyElment={
          <View
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              height: 'calc(100vh - 57px - 40px)',
            }}>
            <Image
              source={{ uri: noData }}
              resizeMode="contain"
              style={{
                width: 350,
                height: 200,
                opacity: 0.7,
              }}
            />
            <Typography
              variant={TypographyType.Normal}
              style={{ color: themeContext.colors.description, textAlign: 'center' }}>
              {`あなたへの通知はありません`}
            </Typography>
          </View>
        }
        onEndReached={() => {
          if ((data?.notificationsV2?.length ?? 0) < pageSize) {
            return;
          }
          fetchMore({
            variables: {
              offset: data!.notificationsV2!.length,
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prev;
              return Object.assign({}, prev, {
                notificationsV2: [
                  ...(prev.notificationsV2 || []),
                  ...(fetchMoreResult.notificationsV2 || []),
                ],
              });
            },
          });
        }}
      />
    </TaskListWrapper>
  );
});

export default MentionList;
