import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { View } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import {
  Organization,
  ProjectTemplateTask,
  ProjectTemplateTaskRemainder,
  ProjectTemplateTaskRemainderDocument,
  TaskRemainderDestType,
  TaskRemainderType,
  useCreateProjectTemplateTaskRemainderMutation,
  useDeleteProjectTemplateTaskRemainderMutation,
  useOrganizationMembersQuery,
  useOrganizationQuery,
  useProjectTemplateTaskRemaindersQuery,
  useUpdateProjectTemplateTaskRemainderMutation,
} from '../../../../../../graphql/api/API';
import TimeUtil from '../../../../../../util/TimeUtil';
import OrganizationUtil from '../../../../../../util/OrganizationUtil';
import Avatar from '../../../../../presentational/atoms/avatar';
import Typography, { TypographyType } from '../../../../../presentational/atoms/typography';
import { IStyleTheme, IThemePart } from '../../../../../theme';
import Modal from '../../../../../presentational/molecules/modal';
import Button from '../../../../../presentational/atoms/button';
import EditableText from '../../../../../presentational/atoms/editable-text';
import Form from '../../../../../presentational/atoms/form';
import List, { ListItemData } from '../../../../../presentational/atoms/list';
import { useHotkeys } from 'react-hotkeys-hook';
import { ListValueMap } from '../../../../../presentational/atoms/input';
import DeleteIcon from '../../../../../presentational/molecules/image-icon/delete';
import MenuIcon from '../../../../../presentational/molecules/image-icon/menu';
import PlusIcon from '../../../../../presentational/molecules/image-icon/plus';
import EditIcon from '../../../../../presentational/molecules/image-icon/edit';
import when from '../../../../../../lang-extention/When';
import { LoginUserContext } from '../../../../../../modules/auth/LoginUserContext';

const AttachmentFileRow = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
  color: #ffffff;
  font-size: 1.5rem;
  padding: 5px 10px;
  border-bottom-width: 1px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
`;

const AddButton = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  padding-right: 5px;
  padding-left: 5px;
`;

const Menu = styled.View`
  position: absolute;
  top: 40px;
  right: 0;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
  border-width: 1px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 160px;
`;

interface IModalProps {
  showModal: boolean;
  organization: Organization;
  remainder: ProjectTemplateTaskRemainder;
  onPressYes: () => Promise<void>;
  onCloseModal: () => void;
}

const ConfirmModal = (props: IModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  return (
    <Modal
      title={'このリマインダーを削除しますか？'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
          marginVertical: 20,
        }}>
        {OrganizationUtil.isTeamPlan(props.organization) && (
          <>
            {when(props.remainder.taskRemainderDestType) //
              .on(
                (value) => value === TaskRemainderDestType.AssignedMember,
                () => (
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Typography
                      variant={TypographyType.Description}
                      style={{ fontSize: 14, textAlign: 'left', marginLeft: 5 }}>
                      {'タスクの担当者'}
                    </Typography>
                  </View>
                )
              )
              .on(
                (value) => value === TaskRemainderDestType.TeamMember,
                () => (
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Avatar size={24} name={'T'} />
                    <Typography
                      variant={TypographyType.Description}
                      style={{ fontSize: 14, textAlign: 'left', marginLeft: 5 }}>
                      {'チームメンバー全員'}
                    </Typography>
                  </View>
                )
              )
              .otherwise(() => (
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <Avatar
                    size={24}
                    name={props.remainder.targetMember?.name || ''}
                    imageUrl={props.remainder.targetMember?.profileImageUrl}
                  />
                  <Typography
                    variant={TypographyType.Description}
                    style={{ fontSize: 14, textAlign: 'left', marginLeft: 5 }}>
                    {props.remainder.targetMember?.name}
                  </Typography>
                </View>
              ))}
          </>
        )}
        <Typography
          variant={TypographyType.Description}
          style={{ fontSize: 14, textAlign: 'left', marginLeft: 5 }}>
          {props.remainder.taskRemainderType === TaskRemainderType.DeadlinePassed
            ? '〆切が過ぎた時'
            : `〆切の${TimeUtil.format(props.remainder.beforeDeadlineMinutes)}前`}
        </Typography>
      </View>
      <View style={{ marginTop: 10 }}>
        <Typography
          variant={TypographyType.Description}
          style={{ textAlign: 'center', color: themeContext.colors.error }}>
          {`この操作はやり直しが出来ません`}
        </Typography>
      </View>
      <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1 }}>
        <Button
          text={'キャンセル'}
          style={{
            minWidth: 100,
            marginRight: 10,
            marginVertical: 10,
            backgroundColor: 'transparent',
          }}
          textStyle={{ color: themeContext.colors.primary }}
          disableValidate={true}
          onPress={() => {
            props.onCloseModal();
          }}
        />
        <Button
          text={'削除する'}
          style={{
            minWidth: 100,
            marginRight: 10,
            marginVertical: 10,
            borderColor: themeContext.colors.error,
            borderRadius: 3,
            borderWidth: 1,
            backgroundColor: 'transparent',
          }}
          textStyle={{ color: themeContext.colors.error }}
          onPress={async () => {
            await props.onPressYes();
          }}
        />
      </View>
    </Modal>
  );
};

interface IRemainderItemProps {
  organization: Organization;
  remainder: ProjectTemplateTaskRemainder;
  moveToFront: (value: boolean) => void;
}

const RemainderItem = (props: IRemainderItemProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const menuRef = useRef();
  const [openMenu, setOpenMenu] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [deleteTaskRemainder, __] = useDeleteProjectTemplateTaskRemainderMutation({
    variables: {
      id: props.remainder.id!,
    },
    update: (cache, result) => {
      cache.evict({
        id: cache.identify(props.remainder),
      });
    },
  });

  useHotkeys('Esc', () => setOpenMenu(false));
  const clickDocument = (e: any) => {
    if ((menuRef?.current as any)?.contains(e.target)) {
      (menuRef?.current as any)?.click();
      return;
    }
    setOpenMenu(false);
    props.moveToFront(false);
  };
  useEffect(() => {
    window.addEventListener('click', clickDocument);
    return () => {
      window.removeEventListener('click', clickDocument);
    };
  }, [clickDocument]);

  return (
    <AttachmentFileRow style={{ borderTopWidth: 1, zIndex: 1 }}>
      {OrganizationUtil.isTeamPlan(props.organization) && (
        <View style={{ width: 200 }}>
          {when(props.remainder.taskRemainderDestType) //
            .on(
              (value) => value === TaskRemainderDestType.AssignedMember,
              () => (
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <Typography
                    variant={TypographyType.Description}
                    style={{ fontSize: 14, textAlign: 'left', marginLeft: 5 }}>
                    {'タスクの担当者'}
                  </Typography>
                </View>
              )
            )
            .on(
              (value) => value === TaskRemainderDestType.TeamMember,
              () => (
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <Avatar size={24} name={'T'} />
                  <Typography
                    variant={TypographyType.Description}
                    style={{ fontSize: 14, textAlign: 'left', marginLeft: 5 }}>
                    {'チームメンバー全員'}
                  </Typography>
                </View>
              )
            )
            .otherwise(() => (
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Avatar
                  size={24}
                  name={props.remainder.targetMember?.name || ''}
                  imageUrl={props.remainder.targetMember?.profileImageUrl}
                />
                <Typography
                  variant={TypographyType.Description}
                  style={{ fontSize: 14, textAlign: 'left', marginLeft: 5 }}>
                  {props.remainder.targetMember?.name}
                </Typography>
              </View>
            ))}
        </View>
      )}
      <View style={{ flexDirection: 'row', alignItems: 'center', flex: 1 }}>
        <View>
          <Typography
            variant={TypographyType.Description}
            style={{ fontSize: 14, textAlign: 'left', marginLeft: 5 }}>
            {props.remainder.taskRemainderType === TaskRemainderType.DeadlinePassed
              ? '〆切が過ぎた時'
              : `〆切の${TimeUtil.formatWithDay(props.remainder.beforeDeadlineMinutes)}前`}
          </Typography>
        </View>
      </View>
      <View style={{ alignItems: 'center', justifyContent: 'center', paddingRight: 10 }}>
        <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          <MenuIcon
            size={28}
            onPress={() => {
              props.moveToFront(true);
              setOpenMenu(true);
            }}
          />
        </View>
        {openMenu && (
          <Menu ref={menuRef as any}>
            <View style={{ paddingHorizontal: 5, marginVertical: 5 }}>
              <EditIcon
                size={21}
                containerStyle={{ marginRight: 5 }}
                onPress={() => {
                  props.moveToFront(false);
                  setShowUpdateModal(true);
                  setOpenMenu(false);
                }}>
                <Typography variant={TypographyType.SubTitle} style={{ paddingLeft: 3 }}>
                  編集する
                </Typography>
              </EditIcon>
            </View>
            <View style={{ paddingHorizontal: 5 }}>
              <DeleteIcon
                size={23}
                containerStyle={{ marginRight: 5 }}
                onPress={() => {
                  props.moveToFront(false);
                  setShowDeleteModal(true);
                  setOpenMenu(false);
                }}>
                <Typography variant={TypographyType.SubTitle}>削除する</Typography>
              </DeleteIcon>
            </View>
          </Menu>
        )}
      </View>
      <ConfirmModal
        showModal={showDeleteModal}
        organization={props.organization}
        remainder={props.remainder}
        onPressYes={async () => {
          setShowDeleteModal(false);
          await deleteTaskRemainder();
        }}
        onCloseModal={() => setShowDeleteModal(false)}
      />
      <UpdateRemainderModal
        organization={props.organization}
        remainder={props.remainder}
        showModal={showUpdateModal}
        onCloseModal={() => setShowUpdateModal(false)}
      />
    </AttachmentFileRow>
  );
};

interface IAddRemainderModalProps {
  organization: Organization;
  task: ProjectTemplateTask;
  showModal: boolean;
  onCloseModal: () => void;
}

const AddRemainderModal = (props: IAddRemainderModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, _] = useContext(LoginUserContext);
  const [beforeDeadlineMinutes, setBeforeDeadlineMinutes] = useState(0);
  const [taskRemainderDestType, setTaskRemainderDestType] = useState(
    TaskRemainderDestType.AssignedMember
  );
  const [targetMemberId, setTargetMemberId] = useState<string | null>(null);
  const { loading: organizationMembersLoading, data: organizationMembersData } =
    useOrganizationMembersQuery({
      variables: {
        organizationId: loginUser!.organizationId!,
      },
    });
  const [createTaskRemainder] = useCreateProjectTemplateTaskRemainderMutation({
    variables: {
      projectTemplateTaskId: props.task.id!,
      input: {
        beforeDeadlineMinutes: beforeDeadlineMinutes,
        taskRemainderDestType: taskRemainderDestType,
        taskRemainderType:
          beforeDeadlineMinutes === 0
            ? TaskRemainderType.DeadlinePassed
            : TaskRemainderType.DeadlineNear,
        targetMemberId: targetMemberId || null,
      },
    },
    update: (cache, result) => {
      cache.modify({
        fields: {
          projectTemplateTaskRemainders(existing = [], { storeFieldName }) {
            const newRemainders = cache.writeQuery({
              data: result.data!.createProjectTemplateTaskRemainder,
              query: ProjectTemplateTaskRemainderDocument,
            });
            return [...existing, newRemainders];
          },
        },
      });
    },
  });

  useEffect(() => {
    setBeforeDeadlineMinutes(0);
    setTargetMemberId(null);
    setTaskRemainderDestType(TaskRemainderDestType.AssignedMember);
  }, [props.showModal]);

  if (organizationMembersLoading) {
    return <></>;
  }

  return (
    <Modal
      title={'リマインダーを追加する'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <View style={{ flexDirection: 'column', justifyContent: 'center', paddingTop: 20 }}>
        <Form>
          {OrganizationUtil.isTeamPlan(props.organization) && (
            <View
              style={{ flexDirection: 'row', alignItems: 'center', zIndex: 3, marginBottom: 20 }}>
              <View style={{ minWidth: 110 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{ fontSize: 18, lineHeight: 22 }}>
                  送信先
                </Typography>
              </View>
              <EditableText
                value={targetMemberId}
                type={'picker'}
                isSearchable={true}
                style={{ minWidth: 200 }}
                renderComponent={() => {
                  const targetMember = organizationMembersData!.organizationMembers!.filter(
                    (member) => member!.id === targetMemberId
                  )[0];
                  return when(taskRemainderDestType) //
                    .on(
                      (value) => value === TaskRemainderDestType.AssignedMember,
                      () => (
                        <View
                          style={{ flexDirection: 'row', alignItems: 'center', marginRight: 30 }}>
                          <Typography
                            variant={TypographyType.Description}
                            style={{ fontSize: 14, textAlign: 'left', marginLeft: 5 }}>
                            {'タスクの担当者'}
                          </Typography>
                        </View>
                      )
                    )
                    .on(
                      (value) => value === TaskRemainderDestType.TeamMember,
                      () => (
                        <View
                          style={{ flexDirection: 'row', alignItems: 'center', marginRight: 30 }}>
                          <Avatar size={24} name={'T'} />
                          <Typography
                            variant={TypographyType.Description}
                            style={{ fontSize: 14, textAlign: 'left', marginLeft: 5 }}>
                            {'チームメンバー全員'}
                          </Typography>
                        </View>
                      )
                    )
                    .otherwise(() => (
                      <View style={{ flexDirection: 'row', alignItems: 'center', marginRight: 30 }}>
                        <Avatar
                          size={24}
                          name={targetMember?.name || ''}
                          imageUrl={targetMember?.profileImageUrl}
                        />
                        <Typography
                          variant={TypographyType.Description}
                          style={{ fontSize: 14, textAlign: 'left', marginLeft: 5 }}>
                          {targetMember?.name}
                        </Typography>
                      </View>
                    ));
                }}
                pickerItems={[
                  {
                    label: 'タスクの担当者',
                    value: 'assigner',
                  },
                  {
                    label: 'チームメンバー全員',
                    value: 'teamMembers',
                  },
                ].concat(
                  organizationMembersData!.organizationMembers!.map((member) => {
                    return {
                      label: member!.name,
                      value: member!.id!,
                      imageUrl: member!.profileImageUrl,
                    } as ListValueMap;
                  })
                )}
                onChange={(value) => {
                  if (value === 'teamMembers') {
                    setTaskRemainderDestType(TaskRemainderDestType.TeamMember);
                    setTargetMemberId(null);
                  } else if (value === 'assigner') {
                    setTaskRemainderDestType(TaskRemainderDestType.AssignedMember);
                    setTargetMemberId(null);
                  } else {
                    setTaskRemainderDestType(TaskRemainderDestType.SpecifiedMember);
                    setTargetMemberId(value as string);
                  }
                }}
                textStyle={{ fontSize: 18 }}
              />
            </View>
          )}
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              zIndex: 2,
            }}>
            <View style={{ minWidth: 110 }}>
              <Typography variant={TypographyType.Normal} style={{ fontSize: 18, lineHeight: 22 }}>
                タイミング
              </Typography>
            </View>
            <EditableText
              value={beforeDeadlineMinutes * 60}
              type={'remainder-picker'}
              disableClear={true}
              renderComponent={() => {
                return (
                  <Typography
                    variant={TypographyType.Description}
                    style={{ fontSize: 16, textAlign: 'left', marginLeft: 5 }}>
                    {beforeDeadlineMinutes === 0
                      ? '〆切が過ぎた時'
                      : `〆切の${TimeUtil.formatWithDay(beforeDeadlineMinutes)}前`}
                  </Typography>
                );
              }}
              onChange={(value) => {
                setBeforeDeadlineMinutes((value as number) / 60);
              }}
            />
          </View>
          <View
            style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
            <Button
              text={'追加する'}
              style={{ minWidth: 100, marginRight: 10, marginVertical: 20 }}
              onPress={async () => {
                await createTaskRemainder();
                props.onCloseModal();
              }}
            />
            <Button
              text={'キャンセル'}
              style={{
                minWidth: 100,
                marginLeft: 10,
                marginVertical: 20,
                backgroundColor: '#FFFFFF',
                borderColor: themeContext.colors.primary,
                borderWidth: 1,
                borderRadius: 5,
                height: 40,
              }}
              textStyle={{ color: themeContext.colors.primary }}
              disableValidate={true}
              onPress={() => {
                props.onCloseModal();
              }}
            />
          </View>
        </Form>
      </View>
    </Modal>
  );
};

interface IUpdateRemainderModalProps {
  organization: Organization;
  remainder: ProjectTemplateTaskRemainder;
  showModal: boolean;
  onCloseModal: () => void;
}

const UpdateRemainderModal = (props: IUpdateRemainderModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser] = useContext(LoginUserContext);
  const [beforeDeadlineMinutes, setBeforeDeadlineMinutes] = useState(
    props.remainder.beforeDeadlineMinutes
  );
  const [taskRemainderDestType, setTaskRemainderDestType] = useState(
    TaskRemainderDestType.AssignedMember
  );
  const [targetMemberId, setTargetMemberId] = useState<string | null>(
    props.remainder.targetMember?.id || null
  );
  const [existSameConditionRemainder, setExistSameConditionRemainder] = useState(false);
  const fetchOrganizationMembers = useOrganizationMembersQuery({
    variables: {
      organizationId: loginUser!.organizationId,
    },
  });
  const [updateTaskRemainder, _] = useUpdateProjectTemplateTaskRemainderMutation({
    variables: {
      id: props.remainder.id!,
      input: {
        taskRemainderDestType: taskRemainderDestType,
        beforeDeadlineMinutes: beforeDeadlineMinutes,
        taskRemainderType:
          beforeDeadlineMinutes === 0
            ? TaskRemainderType.DeadlinePassed
            : TaskRemainderType.DeadlineNear,
        targetMemberId: targetMemberId || null,
      },
    },
  });
  const { loading: taskRemaindersLoading, data: taskRemaindersData } =
    useProjectTemplateTaskRemaindersQuery({
      variables: {
        projectTemplateTaskId: props.remainder.projectTemplateTask.id!,
      },
      fetchPolicy: 'network-only',
    });

  useEffect(() => {
    setBeforeDeadlineMinutes(props.remainder.beforeDeadlineMinutes);
    setTargetMemberId(props.remainder.targetMember?.id || null);
    setTaskRemainderDestType(props.remainder.taskRemainderDestType);
  }, [props.showModal]);

  useEffect(() => {
    if (taskRemaindersData?.projectTemplateTaskRemainders) {
      setExistSameConditionRemainder(
        taskRemaindersData!.projectTemplateTaskRemainders!.filter((remainder) => {
          return (
            remainder?.beforeDeadlineMinutes === beforeDeadlineMinutes &&
            remainder?.taskRemainderDestType === taskRemainderDestType &&
            (remainder?.targetMember?.id || null) === (targetMemberId || null) &&
            remainder?.id !== props.remainder.id
          );
        }).length > 0
      );
    }
  }, [
    beforeDeadlineMinutes,
    taskRemainderDestType,
    targetMemberId,
    taskRemaindersData?.projectTemplateTaskRemainders,
  ]);

  if (fetchOrganizationMembers.loading || taskRemaindersLoading) {
    return <></>;
  }

  return (
    <Modal
      title={'リマインダーを編集する'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <View
        style={{
          flexDirection: 'column',
          justifyContent: 'center',
          paddingTop: 20,
          marginBottom: 20,
        }}>
        <Form>
          {OrganizationUtil.isTeamPlan(props.organization) && (
            <View
              style={{ flexDirection: 'row', alignItems: 'center', zIndex: 3, marginBottom: 20 }}>
              <View style={{ minWidth: 110 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{ fontSize: 18, lineHeight: 22 }}>
                  送信先
                </Typography>
              </View>
              <EditableText
                value={targetMemberId}
                type={'picker'}
                isSearchable={true}
                style={{ minWidth: 200 }}
                renderComponent={() => {
                  const targetMember = fetchOrganizationMembers.data!.organizationMembers!.filter(
                    (member) => member!.id === targetMemberId
                  )[0];
                  return when(taskRemainderDestType) //
                    .on(
                      (value) => value === TaskRemainderDestType.AssignedMember,
                      () => (
                        <View
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginRight: 30,
                          }}>
                          <Typography
                            variant={TypographyType.Description}
                            style={{ fontSize: 14, textAlign: 'left', marginLeft: 5 }}>
                            {'タスクの担当者'}
                          </Typography>
                        </View>
                      )
                    )
                    .on(
                      (value) => value === TaskRemainderDestType.TeamMember,
                      () => (
                        <View
                          style={{ flexDirection: 'row', alignItems: 'center', marginRight: 30 }}>
                          <Avatar size={24} name={'T'} />
                          <Typography
                            variant={TypographyType.Description}
                            style={{ fontSize: 14, textAlign: 'left', marginLeft: 5 }}>
                            {'チームメンバー全員'}
                          </Typography>
                        </View>
                      )
                    )
                    .otherwise(() => (
                      <View style={{ flexDirection: 'row', alignItems: 'center', marginRight: 30 }}>
                        <Avatar
                          size={24}
                          name={targetMember?.name || ''}
                          imageUrl={targetMember?.profileImageUrl}
                        />
                        <Typography
                          variant={TypographyType.Description}
                          style={{ fontSize: 14, textAlign: 'left', marginLeft: 5 }}>
                          {targetMember?.name}
                        </Typography>
                      </View>
                    ));
                }}
                pickerItems={[
                  {
                    label: 'タスクの担当者',
                    value: 'assigner',
                  },
                  {
                    label: 'チームメンバー全員',
                    value: 'teamMembers',
                  },
                ].concat(
                  fetchOrganizationMembers.data!.organizationMembers!.map((member) => {
                    return {
                      label: member!.name,
                      value: member!.id!,
                      imageUrl: member!.profileImageUrl,
                    } as ListValueMap;
                  })
                )}
                onChange={(value) => {
                  if (value === 'teamMembers') {
                    setTaskRemainderDestType(TaskRemainderDestType.TeamMember);
                    setTargetMemberId(null);
                  } else if (value === 'assigner') {
                    setTaskRemainderDestType(TaskRemainderDestType.AssignedMember);
                    setTargetMemberId(null);
                  } else {
                    setTaskRemainderDestType(TaskRemainderDestType.SpecifiedMember);
                    setTargetMemberId(value as string);
                  }
                }}
                textStyle={{ fontSize: 18 }}
              />
            </View>
          )}
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              zIndex: 2,
            }}>
            <View style={{ minWidth: 110 }}>
              <Typography variant={TypographyType.Normal} style={{ fontSize: 18, lineHeight: 22 }}>
                タイミング
              </Typography>
            </View>
            <EditableText
              value={beforeDeadlineMinutes * 60}
              type={'remainder-picker'}
              disableClear={true}
              renderComponent={() => {
                return (
                  <Typography
                    variant={TypographyType.Description}
                    style={{ fontSize: 16, textAlign: 'left', marginLeft: 5 }}>
                    {beforeDeadlineMinutes === 0
                      ? '〆切が過ぎた時'
                      : `〆切の${TimeUtil.formatWithDay(beforeDeadlineMinutes)}前`}
                  </Typography>
                );
              }}
              onChange={(value) => {
                setBeforeDeadlineMinutes((value as number) / 60);
              }}
            />
          </View>
          {existSameConditionRemainder && (
            <View style={{ marginTop: 10 }}>
              <Typography
                variant={TypographyType.Description}
                style={{ color: themeContext.colors.error }}>
                同じ条件のリマインダーがすでに登録されています
              </Typography>
            </View>
          )}
          <View
            style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
            <Button
              text={'変更する'}
              style={{ minWidth: 100, marginRight: 10, marginVertical: 20 }}
              isDisabled={existSameConditionRemainder}
              onPress={async () => {
                await updateTaskRemainder();
                props.onCloseModal();
              }}
            />
            <Button
              text={'キャンセル'}
              style={{
                minWidth: 100,
                marginLeft: 10,
                marginVertical: 20,
                backgroundColor: '#FFFFFF',
                borderColor: themeContext.colors.primary,
                borderWidth: 1,
                borderRadius: 5,
                height: 40,
              }}
              textStyle={{ color: themeContext.colors.primary }}
              disableValidate={true}
              onPress={() => {
                props.onCloseModal();
              }}
            />
          </View>
        </Form>
      </View>
    </Modal>
  );
};

interface IRemainderistProps {
  organization: Organization;
  taskId: string;
}

const Remainderist = React.memo((props: IRemainderistProps) => {
  const { loading, data } = useProjectTemplateTaskRemaindersQuery({
    variables: {
      projectTemplateTaskId: props.taskId,
    },
    fetchPolicy: 'network-only',
  });

  const renderItem = useCallback(
    (item, dragging, index, isGroupFirstRow, moveToFront) => (
      <RemainderItem
        organization={props.organization}
        remainder={item.data}
        moveToFront={moveToFront}
      />
    ),
    [props.organization]
  );

  const remainders = (data?.projectTemplateTaskRemainders || [])
    .slice()
    .map((remainder) => {
      return {
        data: remainder,
        group: remainder!.targetMember?.id,
      } as ListItemData<ProjectTemplateTaskRemainder>;
    })
    .sort((a, b) => a!.data!.beforeDeadlineMinutes - b!.data!.beforeDeadlineMinutes);

  return (
    <List
      style={{ height: 'calc(100vh - 110px)' }}
      items={remainders}
      getKey={(workingSchedule) => (workingSchedule.data as any).id}
      showGroupHeader={true}
      renderItem={renderItem}
    />
  );
});

interface IProjectTemplateTaskRemainderViewProps {
  task: ProjectTemplateTask;
}

const ProjectTemplateTaskRemainderView = (props: IProjectTemplateTaskRemainderViewProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, _] = useContext(LoginUserContext);
  const [showModal, setShowModal] = useState(false);
  const { loading, data } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId!,
    },
  });

  if (loading) {
    return <></>;
  }

  return (
    <>
      <AddButton
        onPress={() => setShowModal(true)}
        style={{
          display: 'flex',
          flexDirection: 'row',
          paddingLeft: 10,
        }}>
        <PlusIcon
          size={13}
          containerStyle={{ marginLeft: 10 }}
          onPress={() => setShowModal(true)}
        />
        <Typography
          variant={TypographyType.Normal}
          style={{ fontSize: 13, color: themeContext.colors.description }}>
          リマインダーを追加する
        </Typography>
      </AddButton>
      <Remainderist organization={data!.organization!} taskId={props.task.id!} />
      <AddRemainderModal
        organization={data!.organization!}
        task={props.task}
        showModal={showModal}
        onCloseModal={() => setShowModal(false)}
      />
    </>
  );
};

export default ProjectTemplateTaskRemainderView;
