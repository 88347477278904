import React, { useContext, useState } from 'react';
import styled, { ThemeContext } from 'styled-components/native';
import { IThemePart } from '../../../../theme';
import {
  JoinedTeamsWithoutPersonalTeamDocument,
  OrganizationTeamsDocument,
  Team,
  TeamMemberRelationsDocument,
  useCreateTeamMutation,
} from '../../../../../graphql/api/API';
import Form from '../../../../presentational/atoms/form';
import Input from '../../../../presentational/atoms/input';
import { View } from 'react-native';
import Button from '../../../../presentational/atoms/button';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';

const Container = styled.View``;

interface IProps {
  onComplete: (team: Team) => void;
  onCancel: () => void;
}

const TeamCreateDialog = (props: IProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser] = useContext(LoginUserContext);
  const [teamName, setTeamName] = useState('');
  const [createTeam, _] = useCreateTeamMutation({
    variables: {
      organizationId: loginUser!.organizationId,
      input: {
        name: teamName,
      },
    },
    update: (cache, result) => {
      cache.modify({
        fields: {
          organizationTeams(existing = []) {
            const newTeam = cache.writeQuery({
              data: result.data?.createTeam,
              query: OrganizationTeamsDocument,
            });
            return [...existing, newTeam];
          },
          joinedTeamsWithoutPersonalTeam(existing = []) {
            const newTeam = cache.writeQuery({
              data: result.data?.createTeam,
              query: JoinedTeamsWithoutPersonalTeamDocument,
            });
            return [...existing, newTeam];
          },
        },
      });
    },
    refetchQueries: [
      {
        query: TeamMemberRelationsDocument,
      },
    ],
  });

  return (
    <Container>
      <Form style={{ minWidth: 400 }}>
        <Input
          name={'teamName'}
          label={'チーム名'}
          focus={true}
          onChange={(value) => setTeamName(value)}
          onPressEnter={async () => {
            const result = await createTeam();
            props.onComplete(result.data!.createTeam!);
          }}
          validate={{
            required: {
              value: true,
              message: '新しいチームの名前を入力してください',
            },
            maxLength: {
              value: 100,
              message: '100文字以内で入力してください',
            },
          }}
        />
        <View style={{ flexDirection: 'row' }}>
          <Button
            text="作成する"
            completeText="作成しました"
            style={{ height: 30, marginRight: 5 }}
            textStyle={{ fontSize: 14 }}
            onPress={async () => {
              const result = await createTeam();
              props.onComplete(result.data!.createTeam!);
            }}
          />
          <Button
            text="キャンセル"
            style={{ height: 30, backgroundColor: 'transparent' }}
            textStyle={{ fontSize: 14, color: themeContext.colors.primary }}
            onPress={async () => {
              props.onCancel();
            }}
            disableValidate={true}
          />
        </View>
      </Form>
    </Container>
  );
};

export default TeamCreateDialog;
