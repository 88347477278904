export default class base64Util {
  private constructor() {}

  // バイト文字列をbase64文字列に変換
  public static convertImgDataURL(url: string) {
    const binary_file = this.convertBinaryFile(url);
    if (binary_file == null) {
      return '';
    }
    const base64 = btoa(binary_file);
    const head = binary_file.substring(0, 9);
    const exe = this.checkExe(head);
    return 'data:image/' + exe + ';base64,' + base64;
  }
  // URLからファイルを取得
  private static loadBinaryResource(url: string) {
    try {
      const req = new XMLHttpRequest();
      req.open('GET', url, false);
      req.overrideMimeType('text/plain; charset=x-user-defined');
      req.send(null);
      if (req.status != 200) return '';
      return req.responseText;
    } catch (e) {
      return null;
    }
  }

  // バイナリデータを文字列に変換
  private static convertBinaryFile(url: string) {
    const filestream = this.loadBinaryResource(url);
    if (filestream == null) {
      return null;
    }
    let bytes = [];
    for (var i = 0; i < filestream.length; i++) {
      bytes[i] = filestream.charCodeAt(i) & 0xff;
    }

    const applyMax = 1024;
    let fileStr = '';
    // 一気にStringに変換しようとするとスタックオーバーフローになってしまうので、小分けにしている
    for (let i = 0; i < bytes.length; i += applyMax) {
      fileStr += String.fromCharCode.apply(String, bytes.slice(i, i + applyMax));
    }
    return fileStr;
  }

  // バイナリの拡張子をチェック
  private static checkExe(head: string) {
    if (head.match(/^\x89PNG/)) {
      return 'png';
    } else if (head.match(/^BM/)) {
      return 'bmp';
    } else if (head.match(/^GIF87a/) || head.match(/^GIF89a/)) {
      return 'gif';
    } else if (head.match(/^\xff\xd8/)) {
      return 'jpeg';
    } else {
      return false;
    }
  }
}
