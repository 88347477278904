import React, { useContext, useEffect, useState } from 'react';
import styled, { ThemeContext } from 'styled-components/native';
import { Image, TouchableOpacity, View } from 'react-native';
import { useScreenDimensions } from 'use-dimensions';
import { useHistory } from 'react-router';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import Button from '../../../../presentational/atoms/button';
import Separator from '../../../../presentational/atoms/separator';
import Form from '../../../../presentational/atoms/form';
import Input from '../../../../presentational/atoms/input';
import Link from '../../../../presentational/atoms/link';
import { IThemePart } from '../../../../theme';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import LoginUtil from '../../../../../modules/auth/LoginUtil';
import logoLargeBlack from '../../../../../base64Images/logo/logo-large-black';
import registerImage from '../../../../../base64Images/register/register';
import GoogleSignupDescriptionModal from '../../../../container/web/organisms/google-signup-description-modal';
import Constants from 'expo-constants';
import {
  useParseGoogleAuthCodeMutation,
  useParseAppleAuthCodeMutation,
  useRequestMemberRegisterMutation,
} from '../../../../../graphql/api/API';
import Spinner from '../../../../presentational/atoms/spinner';
//@ts-ignore
import AppleSignin from 'react-apple-signin-auth';
import GoogleConnectDummyButton from '../../../../presentational/molecules/google-connect-dummy-button';
import ErrorMessageModal from '../../organisms/error-message-modal';

const Header = styled.View`
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  height: 100px;
  padding-left: 20px;
  padding-top: 20px;
`;

const Container = styled.View`
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  height: calc(100vh - 100px);
`;

const LeftContainer = styled.View`
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 0 50px;
`;

const RightContainer = styled.View`
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 0 50px;
`;

const RightContainerInner = styled.View`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LinkContainer = styled.View`
  margin-top: 1.5rem;
`;

const DescriptionContainerTop = styled.View`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
`;

const DescriptionContainerBottom = styled.View`
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
`;

const SignupPage = () => {
  const [mailAddress, setMailAddress] = useState('');
  const { height, width } = useScreenDimensions();
  const history = useHistory();
  const themeContext: IThemePart = useContext(ThemeContext);
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [showGoogleSignupDescriptionModal, setShowGoogleSignupDescriptionModal] = useState(false);
  const [parsingGoogleAuthCode, setParsingGoogleAuthCode] = useState(false);
  const [parsingAppleAuthCode, setParsingAppleAuthCode] = useState(false);
  const [parseGoogleAuthCode, _] = useParseGoogleAuthCodeMutation({
    onError: (e) => {
      setParsingGoogleAuthCode(false);
      if (e.graphQLErrors.find((ge) => ge.extensions?.code === 'google-account-already-used')) {
        setErrorMessage('このGoogleアカウントは、すでに他のユーザーで登録されています。');
        setShowErrorModal(true);
      }
      if (e.graphQLErrors.find((ge) => ge.extensions?.code === 'mail-address-already-used')) {
        setErrorMessage('このメールアドレスは、すでに他のユーザーで登録されています');
        setShowErrorModal(true);
      }
    },
  });
  const [parseAppleAuthCode] = useParseAppleAuthCodeMutation({
    onError: (e) => {
      setParsingAppleAuthCode(false);
      if (e.graphQLErrors.find((ge) => ge.extensions?.code === 'apple-account-already-used')) {
        setErrorMessage('このApple IDは、すでに他のユーザーで登録されています');
        setShowErrorModal(true);
      }
      if (e.graphQLErrors.find((ge) => ge.extensions?.code === 'mail-address-already-used')) {
        setErrorMessage('このメールアドレスは、すでに他のユーザーで登録されています');
        setShowErrorModal(true);
      }
    },
  });
  const [requestRegister, __] = useRequestMemberRegisterMutation({
    variables: {
      input: {
        mailAddress: mailAddress,
      },
    },
    onCompleted: () => {
      history.push({
        pathname: '/signup/request-complete',
      });
    },
  });

  useEffect(() => {
    if (loginUser) {
      LoginUtil.getAllUserFromJwtTokens().then((allUser) => {
        history.push({
          pathname: `/app/`,
          state: {
            users: allUser,
          },
        });
      });
    }
  }, [loginUser]);

  // すでにログイン済みの場合には、次の画面に遷移させるまでの間、ローディング表示する
  if (loginUser) {
    return <></>;
  }

  return (
    <Spinner loading={parsingGoogleAuthCode}>
      <View style={{ paddingBottom: 50 }}>
        <Header>
          <Image
            source={{ uri: logoLargeBlack }}
            resizeMode="contain"
            style={{
              width: 300,
              height: 100,
            }}
          />
        </Header>
        <Container>
          <LeftContainer>
            <Image
              source={{ uri: registerImage }}
              resizeMode="contain"
              style={{
                width: Math.max(width / 4, 500),
                height: Math.max(width / 4, 500),
              }}
            />
          </LeftContainer>
          <RightContainer>
            <RightContainerInner>
              <Typography variant={TypographyType.Title} style={{ marginVertical: 10 }}>
                アカウントを作成する
              </Typography>
              <DescriptionContainerTop>
                <Typography variant={TypographyType.Description}>Time Designerの</Typography>
                <Link path="https://timedesigner.com/terms.html" isExternalLink={true}>
                  <Typography
                    variant={TypographyType.Description}
                    style={{ color: themeContext.colors.link, fontWeight: '600' }}>
                    利用規約
                  </Typography>
                </Link>
                <Typography variant={TypographyType.Description}>・</Typography>
                <Link path="https://timedesigner.com/privacy-policy.html" isExternalLink={true}>
                  <Typography
                    variant={TypographyType.Description}
                    style={{ color: themeContext.colors.link, fontWeight: '600' }}>
                    プライバシーポリシー
                  </Typography>
                </Link>
                <Typography variant={TypographyType.Description}>に</Typography>
              </DescriptionContainerTop>
              <DescriptionContainerBottom>
                <Typography variant={TypographyType.Description}>
                  同意してアカウントを作成します。
                </Typography>
              </DescriptionContainerBottom>
              <TouchableOpacity onPress={() => setShowGoogleSignupDescriptionModal(true)}>
                <GoogleConnectDummyButton text="Googleアカウントで会員登録する" />
              </TouchableOpacity>
              <View style={{ width: 280, marginVertical: 20 }}>
                <AppleSignin
                  uiType="light"
                  style={{
                    borderColor: themeContext.colors.separator,
                    shadowOffset: {
                      width: 2,
                      height: 2,
                    },
                    shadowOpacity: 0.1,
                    borderWidth: 2,
                    color: themeContext.colors.description,
                  }}
                  buttonExtraChildren={'Apple IDで会員登録'}
                  authOptions={{
                    clientId: Constants.manifest!.extra!.appleAuthClientId,
                    redirectURI: Constants.manifest!.extra!.appleAuthRedirectUrl,
                    scope: 'email name',
                    usePopup: true,
                  }}
                  onSuccess={async (response: any) => {
                    setParsingAppleAuthCode(true);
                    const result = await parseAppleAuthCode({
                      variables: {
                        input: {
                          code: response.authorization.code,
                          token: response.authorization.id_token,
                        },
                      },
                    });
                    if (!result?.data?.parseAppleAuthCode) {
                      return;
                    }
                    if (result.data!.parseAppleAuthCode!.registerd) {
                      // すでに会員登録済みの場合には、ログインする
                      await LoginUtil.saveToken(result.data!.parseAppleAuthCode!.token!);
                      const user = await LoginUtil.getLoginUser();
                      setLoginUser(user);
                      return;
                    }
                    setParsingAppleAuthCode(false);
                    history.push({
                      pathname: '/signup/select-plan',
                      state: {
                        mailAddress: result.data!.parseAppleAuthCode!.mailAddress!,
                        appleAuthCode: response.authorization.code,
                        appleAuthToken: response.authorization.id_token,
                        appleUserId: result.data!.parseAppleAuthCode!.appleUserId,
                        name: result.data!.parseAppleAuthCode!.name!,
                        profileImageUrl: null,
                      },
                    });
                  }}
                  onError={(error: any) => {}}
                />
              </View>
              <Separator text={'または'} outerStyle={{ marginVertical: 10 }} />
              <Form style={{ marginBottom: 30 }}>
                <Input
                  name={'mailAddress'}
                  label={'メールアドレス'}
                  validate={{
                    required: {
                      value: true,
                      message: 'メールアドレスを入力してください',
                    },
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: '正しいメールアドレスを入力してください',
                    },
                  }}
                  onChange={(value: string) => setMailAddress(value)}
                />
                <Button
                  text="メールアドレスで登録する"
                  style={{ marginTop: 20, minWidth: 300 }}
                  onPress={async () => {
                    await requestRegister();
                  }}
                />
              </Form>
              <LinkContainer>
                <Link path={'/signin'}>
                  <Typography
                    variant={TypographyType.MenuItemActive}
                    style={{ color: themeContext.colors.link }}>
                    ログインする
                  </Typography>
                </Link>
              </LinkContainer>
            </RightContainerInner>
          </RightContainer>
        </Container>
      </View>
      <GoogleSignupDescriptionModal
        showModal={showGoogleSignupDescriptionModal}
        onCloseModal={() => setShowGoogleSignupDescriptionModal(false)}
        onSuccess={async (response) => {
          setParsingGoogleAuthCode(true);
          const result = await parseGoogleAuthCode({
            variables: {
              code: response.code!,
            },
          });
          if (!result?.data?.parseGoogleAuthCode) {
            return;
          }
          if (result.data!.parseGoogleAuthCode!.registerd) {
            // すでに会員登録済みの場合には、ログインする
            await LoginUtil.saveToken(result.data!.parseGoogleAuthCode!.token!);
            const user = await LoginUtil.getLoginUser();
            setLoginUser(user);
            return;
          }
          setShowGoogleSignupDescriptionModal(false);
          setParsingGoogleAuthCode(false);
          history.push({
            pathname: '/signup/select-plan',
            state: {
              mailAddress: result.data!.parseGoogleAuthCode!.mailAddress!,
              googleAuthCode: response.code!,
              name: result.data!.parseGoogleAuthCode!.name!,
              profileImageUrl: result.data!.parseGoogleAuthCode!.profileImageUrl,
            },
          });
        }}
      />
      <ErrorMessageModal
        title="登録済のアカウントです"
        showModal={showErrorModal}
        message={errorMessage}
        onCloseModal={() => {
          setShowErrorModal(false);
          setErrorMessage('');
          window.location.reload();
        }}
      />
    </Spinner>
  );
};

export default React.memo(SignupPage);
