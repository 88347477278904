import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../../../theme';
import TaskInfoElement from '../task-info-element';

const Conatiner = styled.View`
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  align-items: flex-start;
`;

const Row = styled.View`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: space-between;
`;

const TaskDetailLoading = () => {
  const themeContext: IThemePart = useContext(ThemeContext);
  return (
    <>
      <Conatiner>
        <Row>
          <TaskInfoElement title={'プロジェクト'} style={{ flex: 1 }}></TaskInfoElement>
        </Row>
        <Row>
          <TaskInfoElement title={'作業時間'} style={{ flex: 1 }}></TaskInfoElement>
        </Row>
      </Conatiner>
    </>
  );
};

export default TaskDetailLoading;
