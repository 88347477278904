import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment-timezone';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import { IThemePart } from '../../../../../theme';
import {
  IsExistsDuplicateTermProjectContractDocument,
  IsExistsDuplicateTermProjectContractQuery,
  IsExistsDuplicateTermProjectContractQueryVariables,
  Organization,
  Project,
  ProjectContractRoundTimeType,
  ProjectContractUnitPriceType,
  ProjectDocument,
  TimeZone,
  useTimeZonesQuery,
} from '../../../../../../graphql/api/API';
import Form from '../../../../../presentational/atoms/form';
import Input, { ErrorMessage } from '../../../../../presentational/atoms/input';
import { TouchableOpacity, View } from 'react-native';
import Button from '../../../../../presentational/atoms/button';
import TaskInfoElement from '../../task-detail/task-info-element';
import {
  ProjectContract,
  ProjectContractsByProjectIdDocument,
  ProjectContractType,
  ProjectContractTermType,
  useCreateProjectContractMutation,
} from '../../../../../../graphql/api/API';
import DateTimePicker from '../../../../../presentational/atoms/date-time-picker/index.web';
import Modal from '../../../../../presentational/molecules/modal';
import Checkbox from '../../../../../presentational/atoms/checkbox';
import OrganizationUtil from '../../../../../../util/OrganizationUtil';
import Typography, { TypographyType } from '../../../../../presentational/atoms/typography';
import { useLazyQueryPromise } from '../../../../../../graphql/extention/useLazyQueryPromise';
import ErrorMessageModal from '../../error-message-modal';

const Container = styled.View`
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  align-items: flex-start;
`;

const Row = styled.View`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: space-between;
`;

export type SettlementRangeAllMemberInput = {
  monthlyUnitPrice: number;
  lowerTimeHour?: number;
  deductionHourlyUnitPrice?: number;
  upperTimeHour?: number;
  excessHourlyUnitPrice?: number;
};

interface IProps {
  showModal: boolean;
  organization: Organization;
  project: Project;
  timeZones: TimeZone[];
  existsProjectContracts: ProjectContract[];
  onPressYes: (projectContract: ProjectContract) => void;
  onCloseModal: () => void;
}

const RegisterProjectContractModal = (props: IProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [contractType, setContractType] = useState<ProjectContractType>(
    ProjectContractType.FixAmount
  );
  const [termType, setTermType] = useState<ProjectContractTermType>(ProjectContractTermType.Total);
  const [unitPriceType, setUnitPriceType] = useState<ProjectContractUnitPriceType>(
    ProjectContractUnitPriceType.Anyone
  );
  const [roundTimeType, setRoundTimeType] = useState<ProjectContractRoundTimeType>(
    ProjectContractRoundTimeType.None
  );
  const [timeZone, setTimeZone] = useState<TimeZone | null>(null);
  const [roundMinutes, setRoundMinutes] = useState<number | null>(null);
  const [taxRate, setTaxRate] = useState<number>(10);
  const [fixSalesAmount, setFixSalesAmount] = useState<number>(0);
  const [costBudget, setCostBudget] = useState<number | null>(null);
  const [meterdRateAllMemberHourlyUnitPrice, setMeterdRateAllMemberHourlyUnitPrice] =
    useState<number>(0);
  const [settlementRangeMonthlyUnitPrice, setSettlementRangeMonthlyUnitPrice] = useState<number>(0);
  const [settlementRangeDeductionHourlyUnitPrice, setSettlementRangeDeductionHourlyUnitPrice] =
    useState<number>(0);
  const [settlementRangeExcessHourlyUnitPrice, setSettlementRangeExcessHourlyUnitPrice] =
    useState<number>(0);
  const [settlementRangeLowerTimeHour, setSettlementRangeLowerTimeHour] = useState<number>(0);
  const [settlementRangeUpperimeHour, setSettlementRangeUpperTimeHour] = useState<number>(0);
  const [year, setYear] = useState(moment().get('year'));
  const [month, setMonth] = useState(moment().get('month') + 1);
  const [isCopyMemberSettings, setCopyMemberSettings] = useState(false);
  const [showDuplicateError, setShowDuplicateError] = useState(false)

  const [createProjectContract] = useCreateProjectContractMutation({
    variables: {
      input: {
        projectId: props.project.id!,
        contractTermType: termType,
        contractType: contractType,
        unitPriceType: OrganizationUtil.isTeamPlan(props.organization)
          ? unitPriceType
          : ProjectContractUnitPriceType.Anyone,
        timeZoneId: timeZone?.id ?? '',
        roundTimeType: roundTimeType,
        fixSalesAmountWithoutTax:
          contractType === ProjectContractType.FixAmount ? fixSalesAmount : null,
        salesRoundMinutes: roundMinutes,
        taxRate: taxRate,
        costBudgetWithoutTax: costBudget,
        year: termType === ProjectContractTermType.Monthly ? year : null,
        month: termType === ProjectContractTermType.Monthly ? month : null,
        meterdRateAllMemberHourlyUnitPrice:
          contractType === ProjectContractType.MeteredRate
            ? meterdRateAllMemberHourlyUnitPrice
            : null,
        settlementRangeAllMemberUnitPrice:
          contractType === ProjectContractType.SettlementRange
            ? {
                monthlyUnitPrice: settlementRangeMonthlyUnitPrice ?? 0,
                deductionHourlyUnitPrice: settlementRangeDeductionHourlyUnitPrice ?? 0,
                excessHourlyUnitPrice: settlementRangeExcessHourlyUnitPrice ?? 0,
                lowerTimeHour: settlementRangeLowerTimeHour ?? 0,
                upperTimeHour: settlementRangeUpperimeHour ?? 0,
              }
            : null,
        copyPreviousContractMemberUnitPrice: isCopyMemberSettings,
      },
    },
    refetchQueries: [
      {
        query: ProjectContractsByProjectIdDocument,
        variables: {
          projectId: props.project.id!,
        },
      },
      {
        query: ProjectDocument,
        variables: {
          id: props.project.id!,
        },
      },
    ],
  });

  const checkExistDuplicateTermProjectContract = useLazyQueryPromise<IsExistsDuplicateTermProjectContractQuery, IsExistsDuplicateTermProjectContractQueryVariables>(
    IsExistsDuplicateTermProjectContractDocument
  );

  useEffect(() => {
    if (!timeZone) {
      const japanTimeZone = props.timeZones.find((zone) => {
        return zone.code === 'Asia/Tokyo';
      });
      if (japanTimeZone) {
        setTimeZone(japanTimeZone);
      }
    }
  }, [props.timeZones]);

  useEffect(() => {
    if (
      roundTimeType !== ProjectContractRoundTimeType.None &&
      (roundMinutes === null || roundMinutes === undefined)
    ) {
      setRoundMinutes(15);
    }
  }, [roundTimeType, roundMinutes]);

  useEffect(() => {
    if (
      props.existsProjectContracts.filter(
        (data) => data.termType === ProjectContractTermType.Monthly
      ).length > 0
    ) {
      setTermType(ProjectContractTermType.Monthly);
    } else {
      setTermType(ProjectContractTermType.Total);
    }

    setContractType(ProjectContractType.FixAmount);
    setUnitPriceType(ProjectContractUnitPriceType.Anyone);
    setRoundTimeType(ProjectContractRoundTimeType.None);
    setRoundMinutes(15);
    setTaxRate(10);
    setFixSalesAmount(0);
    setMeterdRateAllMemberHourlyUnitPrice(0);
    setSettlementRangeMonthlyUnitPrice(0);
    setSettlementRangeUpperTimeHour(0);
    setSettlementRangeExcessHourlyUnitPrice(0);
    setSettlementRangeLowerTimeHour(0);
    setSettlementRangeDeductionHourlyUnitPrice(0);
    setCostBudget(null);
  }, [props.showModal]);

  return (
    <Modal
      title={'契約情報の登録'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <Container>
        <Form style={{ minWidth: 800 }}>
          <Row>
            <TaskInfoElement
              title={'契約期間'}
              style={{ flex: 1 }}
              contentContainerStyle={{ flexDirection: 'row', flex: 1 }}
              titleContainerStyle={{ width: 180 }}>
              <View
                style={{
                  width: termType === ProjectContractTermType.Monthly ? 220 : undefined,
                  flex: termType === ProjectContractTermType.Monthly ? undefined : 1,
                }}>
                <Input
                  name={'termType'}
                  placeholder={'売上計算期間を選択する'}
                  type={'picker'}
                  value={termType}
                  pickerItems={[
                    {
                      label: '契約期間を指定しない',
                      value: ProjectContractTermType.Total,
                    },
                    {
                      label: '契約期間を指定する',
                      value: ProjectContractTermType.Monthly,
                    },
                  ]}
                  validate={{
                    validate: (value: string) => {
                      if (
                        value === ProjectContractTermType.Total &&
                        props.existsProjectContracts.filter(
                          (data) => data.termType === ProjectContractTermType.Monthly
                        ).length > 0
                      ) {
                        return `他に期間を指定した契約情報が登録されているため、${'\n'}期間を指定しない契約情報は登録できません${'\n'}(契約期間が重複するため)`;
                      }
                      if (
                        value === ProjectContractTermType.Monthly &&
                        props.existsProjectContracts.filter(
                          (data) => data.termType === ProjectContractTermType.Total
                        ).length > 0
                      ) {
                        return `他に期間指定しない契約情報が登録されているため、${'\n'}期間を指定する契約情報は登録できません${'\n'}(契約期間が重複するため)`;
                      }
                      return true;
                    },
                  }}
                  onChange={(value) => setTermType(value as ProjectContractTermType)}
                />
              </View>
              {termType === ProjectContractTermType.Monthly && (
                <>
                  <View style={{ width: 120, marginLeft: 10 }}>
                    <Input
                      name={'year'}
                      type={'picker'}
                      initialValue={moment().get('year')}
                      isSearchable
                      pickerItems={[...Array(4)].map((_, i) => {
                        const year = moment().get('year') - 1 + i;
                        return {
                          label: `${year}年`,
                          value: year,
                        };
                      })}
                      onChange={(value) => {
                        setYear(Number(value));
                      }}
                    />
                  </View>
                  <View style={{ width: 120, marginLeft: 10 }}>
                    <Input
                      name={'month'}
                      type={'picker'}
                      initialValue={moment().get('month') + 1}
                      isSearchable
                      pickerItems={[...Array(12)].map((_, i) => {
                        const month = 1 + i;
                        return {
                          label: `${month}月`,
                          value: month,
                        };
                      })}
                      onChange={(value) => {
                        setMonth(Number(value));
                      }}
                    />
                  </View>
                </>
              )}
            </TaskInfoElement>
          </Row>
          {termType === ProjectContractTermType.Monthly && (
            <Row>
              <TaskInfoElement
                title={'タイムゾーン'}
                style={{ flex: 1 }}
                titleContainerStyle={{ width: 180 }}>
                <Input
                  name={'timeZone'}
                  placeholder={'タイムゾーンを選択する'}
                  type={'picker'}
                  value={timeZone?.id ?? ''}
                  pickerItems={props.timeZones.map((timeZone) => {
                    return {
                      label: `${timeZone!.name} (${timeZone!.offsetTime})`,
                      value: timeZone!.id,
                    };
                  })}
                  onChange={(value) => {
                    const japanTimeZone = props.timeZones.find((timeZone) => timeZone.id === value);
                    if (japanTimeZone) {
                      setTimeZone(japanTimeZone);
                    }
                  }}
                />
              </TaskInfoElement>
            </Row>
          )}
          <Row>
            <TaskInfoElement
              title={'契約形態'}
              style={{ flex: 1 }}
              titleContainerStyle={{ width: 180 }}>
              <Input
                name={'contractType'}
                placeholder={'契約形態を選択する'}
                type={'picker'}
                value={contractType}
                pickerItems={[
                  {
                    label: '固定料金',
                    value: ProjectContractType.FixAmount,
                  },
                  {
                    label: '従量制料金(時給制)',
                    value: ProjectContractType.MeteredRate,
                  },
                  {
                    label: '精算幅付き料金',
                    value: ProjectContractType.SettlementRange,
                  },
                ]}
                onChange={(value) => setContractType(value as ProjectContractType)}
              />
            </TaskInfoElement>
          </Row>
          {contractType === ProjectContractType.FixAmount && (
            <Row style={{ flexDirection: 'column' }}>
              <TaskInfoElement
                title={'売上金額(税抜)'}
                style={{ flex: 1 }}
                titleContainerStyle={{ width: 180 }}>
                <Input
                  name={'fixSalesAmount'}
                  placeholder={'値を入力してください'}
                  ghostSuffix="円"
                  initialValue={0}
                  onChange={(value) => {
                    if (value.trim().length === 0 || isNaN(value as any)) {
                      setFixSalesAmount(0);
                    } else {
                      setFixSalesAmount(Number(value));
                    }
                  }}
                  validate={{
                    required: {
                      value: true,
                      message: '値を入力してください',
                    },
                    pattern: {
                      value: /^[0-9]+$/,
                      message: '半角数字を入力してください。',
                    },
                    min: {
                      value: 0,
                      message: '0以上の金額を入力してください',
                    },
                  }}
                />
              </TaskInfoElement>
            </Row>
          )}
          {OrganizationUtil.isTeamPlan(props.organization) &&
            (contractType === ProjectContractType.MeteredRate ||
              contractType === ProjectContractType.SettlementRange) && (
              <Row>
                <TaskInfoElement
                  title={'メンバー毎の料金'}
                  style={{ flex: 1 }}
                  titleContainerStyle={{ width: 180 }}>
                  <Input
                    name={'unitPriceType'}
                    placeholder={'選択する'}
                    type={'picker'}
                    value={unitPriceType}
                    pickerItems={[
                      {
                        label: '全メンバー共通の料金',
                        value: ProjectContractUnitPriceType.Anyone,
                      },
                      {
                        label: 'メンバー毎に料金が異なる',
                        value: ProjectContractUnitPriceType.PerMember,
                      },
                    ]}
                    onChange={(value) => setUnitPriceType(value as ProjectContractUnitPriceType)}
                  />
                  {unitPriceType === ProjectContractUnitPriceType.PerMember &&
                    termType === ProjectContractTermType.Monthly &&
                    !!year &&
                    !!month &&
                    props.existsProjectContracts
                      .filter((data) => data.termType == ProjectContractTermType.Monthly)
                      .filter((data) => data.contractType == contractType)
                      .filter((data) =>
                        moment(data.startDateTime).isBefore(
                          moment()
                            .year(year)
                            .month(month - 1)
                            .startOf('month')
                        )
                      ).length > 0 && (
                      <View style={{ flexDirection: 'row', marginVertical: 5 }}>
                        <Checkbox
                          size={24}
                          value={isCopyMemberSettings}
                          onValueChange={(value) => {
                            setCopyMemberSettings(!isCopyMemberSettings);
                          }}
                          color={
                            isCopyMemberSettings
                              ? themeContext.colors.primary
                              : themeContext.colors.description
                          }></Checkbox>
                        <TouchableOpacity
                          onPress={() => setCopyMemberSettings(!isCopyMemberSettings)}>
                          <Typography
                            variant={TypographyType.Normal}
                            style={{
                              color: isCopyMemberSettings
                                ? themeContext.colors.primary
                                : themeContext.colors.description,
                              marginLeft: 10,
                            }}>
                            前回の期間のメンバー単価をコピーする
                          </Typography>
                        </TouchableOpacity>
                      </View>
                    )}
                </TaskInfoElement>
              </Row>
            )}
          {contractType === ProjectContractType.MeteredRate &&
            unitPriceType === ProjectContractUnitPriceType.Anyone && (
              <Row style={{ flexDirection: 'column' }}>
                <TaskInfoElement
                  title={'時間単価（税抜）'}
                  style={{ flex: 1 }}
                  titleContainerStyle={{ width: 180 }}>
                  <Input
                    name={'meterdRateAllMemberHourlyUnitPrice'}
                    placeholder={'値を入力してください'}
                    initialValue={meterdRateAllMemberHourlyUnitPrice}
                    ghostSuffix="円"
                    onChange={(value) => {
                      if (value.trim().length === 0 || isNaN(value as any)) {
                        setMeterdRateAllMemberHourlyUnitPrice(0);
                      } else {
                        setMeterdRateAllMemberHourlyUnitPrice(Number(value));
                      }
                    }}
                    validate={{
                      required: {
                        value: true,
                        message: '値を入力してください',
                      },
                      pattern: {
                        value: /^[0-9]+$/,
                        message: '半角数字を入力してください。',
                      },
                      min: {
                        value: 0,
                        message: '0以上の金額を入力してください',
                      },
                    }}
                  />
                </TaskInfoElement>
              </Row>
            )}
          {contractType === ProjectContractType.SettlementRange &&
            unitPriceType === ProjectContractUnitPriceType.Anyone && (
              <>
                <Row style={{ flexDirection: 'column' }}>
                  <TaskInfoElement
                    title={'基本料金(税抜)'}
                    style={{ flex: 1 }}
                    titleContainerStyle={{ width: 180 }}>
                    <Input
                      name={'monthlyUnitPrice'}
                      placeholder={'値を入力してください'}
                      ghostSuffix="円"
                      initialValue={0}
                      onChange={(value) => {
                        if (value.trim().length === 0 || isNaN(value as any)) {
                          setSettlementRangeMonthlyUnitPrice(0);
                        } else {
                          setSettlementRangeMonthlyUnitPrice(Number(value));
                        }
                      }}
                      validate={{
                        required: {
                          value: true,
                          message: '値を入力してください',
                        },
                        pattern: {
                          value: /^[0-9]+$/,
                          message: '半角数字を入力してください。',
                        },
                        min: {
                          value: 0,
                          message: '0以上の金額を入力してください',
                        },
                      }}
                    />
                  </TaskInfoElement>
                </Row>
                <Row style={{ flexDirection: 'column' }}>
                  <TaskInfoElement title={'基本時間'} titleContainerStyle={{ width: 180 }}>
                    <View style={{ flexDirection: 'row' }}>
                      <Input
                        name={'lowerTimmeHour'}
                        placeholder={'値を入力してください'}
                        initialValue={settlementRangeLowerTimeHour}
                        containerStyle={{ flex: 1 }}
                        onChange={(value) => {
                          if (value.trim().length === 0 || isNaN(value as any)) {
                            setSettlementRangeLowerTimeHour(0);
                          } else {
                            setSettlementRangeLowerTimeHour(Number(value));
                          }
                        }}
                        validate={{
                          required: {
                            value: true,
                            message: '値を入力してください',
                          },
                          pattern: {
                            value: /^[0-9]+$/,
                            message: '半角数字を入力してください。',
                          },
                          min: {
                            value: 0,
                            message: '0以上の金額を入力してください',
                          },
                        }}
                      />
                      <Typography
                        variant={TypographyType.Normal}
                        style={{ color: '#565955', paddingHorizontal: 10 }}>
                        {`〜`}
                      </Typography>
                      <Input
                        name={'uppertimeHour'}
                        placeholder={'値を入力してください'}
                        initialValue={settlementRangeUpperimeHour}
                        value={settlementRangeUpperimeHour}
                        containerStyle={{ flex: 1 }}
                        onChange={(value) => {
                          if (value.trim().length === 0 || isNaN(value as any)) {
                            setSettlementRangeUpperTimeHour(0);
                          } else {
                            setSettlementRangeUpperTimeHour(Number(value));
                          }
                        }}
                        validate={{
                          required: {
                            value: true,
                            message: '値を入力してください',
                          },
                          pattern: {
                            value: /^[0-9]+$/,
                            message: '半角数字を入力してください。',
                          },
                        }}
                      />
                      <Typography variant={TypographyType.Normal} style={{ color: '#565955' }}>
                        {`時間`}
                      </Typography>
                    </View>
                  </TaskInfoElement>
                  {settlementRangeLowerTimeHour > settlementRangeUpperimeHour && (
                    <ErrorMessage style={{ marginLeft: 182 }}>
                      左の時間の方が小さくなるように入力してください
                    </ErrorMessage>
                  )}
                </Row>
                <Row>
                  <TaskInfoElement
                    title={`超過分・時間単価（税抜）${'\n'}※基本時間を上回った分`}
                    titleContainerStyle={{ width: 180 }}>
                    <Input
                      name={'excessHourlyUnitPrice'}
                      placeholder={'値を入力してください'}
                      initialValue={settlementRangeExcessHourlyUnitPrice}
                      ghostSuffix="円"
                      onChange={(value) => {
                        if (value.trim().length === 0 || isNaN(value as any)) {
                          setSettlementRangeExcessHourlyUnitPrice(0);
                        } else {
                          setSettlementRangeExcessHourlyUnitPrice(Number(value));
                        }
                      }}
                      validate={{
                        required: {
                          value: true,
                          message: '値を入力してください',
                        },
                        pattern: {
                          value: /^[0-9]+$/,
                          message: '半角数字を入力してください。',
                        },
                        min: {
                          value: 0,
                          message: '0以上の金額を入力してください',
                        },
                      }}
                    />
                  </TaskInfoElement>
                </Row>

                <Row>
                  <TaskInfoElement
                    title={`控除分・時間単価（税抜）${'\n'}※基本時間を下回った分`}
                    titleContainerStyle={{ width: 180 }}>
                    <Input
                      name={'deductionHourlyUnitPrice'}
                      placeholder={'値を入力してください'}
                      initialValue={settlementRangeDeductionHourlyUnitPrice}
                      ghostSuffix="円"
                      onChange={(value) => {
                        if (value.trim().length === 0 || isNaN(value as any)) {
                          setSettlementRangeDeductionHourlyUnitPrice(0);
                        } else {
                          setSettlementRangeDeductionHourlyUnitPrice(Number(value));
                        }
                      }}
                      validate={{
                        required: {
                          value: true,
                          message: '値を入力してください',
                        },
                        pattern: {
                          value: /^[0-9]+$/,
                          message: '半角数字を入力してください。',
                        },
                        min: {
                          value: 0,
                          message: '0以上の金額を入力してください',
                        },
                      }}
                    />
                  </TaskInfoElement>
                </Row>
              </>
            )}
          <Row>
            <TaskInfoElement
              title={'税率'}
              style={{ flex: 1 }}
              titleContainerStyle={{ width: 180 }}>
              <Input
                name={'taxRate'}
                placeholder={'値を入力してください'}
                initialValue={taxRate}
                ghostSuffix="%"
                onChange={(value) => {
                  if (value.trim().length === 0 || isNaN(value as any)) {
                    setTaxRate(0);
                  } else {
                    setFixSalesAmount(Number(value));
                  }
                }}
                validate={{
                  required: {
                    value: true,
                    message: '値を入力してください',
                  },
                  pattern: {
                    value: /^[0-9]+$/,
                    message: '半角数字を入力してください。',
                  },
                  min: {
                    value: 0,
                    message: '0以上の金額を入力してください',
                  },
                }}
              />
            </TaskInfoElement>
          </Row>
          {OrganizationUtil.isTeamPlan(props.organization) && (
            <Row>
              <TaskInfoElement
                title={'予算'}
                style={{ flex: 1 }}
                titleContainerStyle={{ width: 180 }}>
                <Input
                  name={'costBudget'}
                  placeholder={'値を入力してください'}
                  ghostSuffix="円"
                  onChange={(value) => {
                    if (value.trim().length === 0 || isNaN(value as any)) {
                      setCostBudget(null);
                    } else {
                      setCostBudget(Number(value));
                    }
                  }}
                  validate={{
                    pattern: {
                      value: /^[0-9]+$/,
                      message: '半角数字を入力してください。',
                    },
                    min: {
                      value: 0,
                      message: '0以上の金額を入力してください',
                    },
                  }}
                />
              </TaskInfoElement>
            </Row>
          )}
          <Row>
            <TaskInfoElement
              title={'時間の丸め処理'}
              style={{ flex: 1 }}
              titleContainerStyle={{ width: 180 }}
              contentFlexDirection="row">
              <Input
                name={'roundTime'}
                placeholder={'丸め方法を選択する'}
                containerStyle={{ flex: 1 }}
                type={'picker'}
                value={roundTimeType}
                pickerItems={[
                  {
                    label: 'なし',
                    value: ProjectContractRoundTimeType.None,
                  },
                  {
                    label: '切り捨て',
                    value: ProjectContractRoundTimeType.Floor,
                  },
                  {
                    label: '切り上げ',
                    value: ProjectContractRoundTimeType.Ceil,
                  },
                  {
                    label: '四捨五入',
                    value: ProjectContractRoundTimeType.Round,
                  },
                ]}
                onChange={(value) => setRoundTimeType(value as ProjectContractRoundTimeType)}
              />
              {roundTimeType !== ProjectContractRoundTimeType.None && (
                <Input
                  name={'roundMinutes'}
                  placeholder={'丸める単位を選択する'}
                  containerStyle={{ flex: 1, marginLeft: 20 }}
                  type={'picker'}
                  initialValue={roundMinutes}
                  value={roundMinutes}
                  pickerItems={[
                    {
                      label: '5分単位',
                      value: 5,
                    },
                    {
                      label: '10分単位',
                      value: 10,
                    },
                    {
                      label: '15分単位',
                      value: 15,
                    },
                    {
                      label: '30分単位',
                      value: 30,
                    },
                  ]}
                  validate={{
                    required: {
                      value: true,
                      message: '値を入力してください',
                    },
                  }}
                  onChange={(value) =>
                    setRoundMinutes(isNaN(value as any) ? 0 : (value as any) ?? 5)
                  }
                />
              )}
            </TaskInfoElement>
          </Row>
          <View
            style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 20 }}>
            <Button
              text={'作成する'}
              completeText={'作成しました'}
              style={{
                minWidth: 100,
                marginRight: 10,
              }}
              onPress={async () => {
                if (
                  contractType === ProjectContractType.SettlementRange &&
                  settlementRangeLowerTimeHour > settlementRangeUpperimeHour
                ) {
                  return;
                }

                const res = await checkExistDuplicateTermProjectContract({
                  input: {
                    projectId: props.project.id!,
                    timeZoneId: timeZone!.id,
                    year: year,
                    month: month,
                  }
                });

                if (res.data.isExistsDuplicateTermProjectContract === true) {
                  setShowDuplicateError(true)
                  return;
                }

                const result = await createProjectContract();
                props.onPressYes(result.data!.createProjectContract!);
              }}
            />
            <Button
              text={'キャンセル'}
              style={{
                minWidth: 100,
                marginRight: 10,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.primary }}
              disableValidate={true}
              onPress={async () => {
                props.onCloseModal();
              }}
            />
          </View>
        </Form>
      </Container>
      <ErrorMessageModal
        showModal={showDuplicateError}
        title={'期間が重複した契約が他にあるため、登録できません'}
        message={`期間を選択し直してください`}
        onCloseModal={() => setShowDuplicateError(false)}
      />
    </Modal>
  );
};

export default RegisterProjectContractModal;
