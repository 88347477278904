import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import { View, Image, Text } from 'react-native';
import Typography, { TypographyType } from '../../../../../../presentational/atoms/typography';
import Modal from '../../../../../../presentational/molecules/modal';
import { IStyleTheme, IThemePart } from '../../../../../../theme';
import {
  useOrganizationQuery,
  Plan,
  useCsvReportItemDefinitionsQuery,
  CsvReportItemDefinition,
  useCsvReportItemDefinitionQuery,
  useUpdateCsvReportItemDefinitionMutation,
  useDeleteCsvReportItemDefinitionMutation,
  CsvReportItemDefinitionsDocument,
  Organization,
  CsvReportItemDefinitionDetail,
  useDefaultCsvReportItemDefinitionQuery,
  useUpdateCsvReportItemSortNoMutation,
  useDeleteCsvReportItemMutation,
  useAddCsvReportItemMutation,
} from '../../../../../../../graphql/api/API';
import { LoginUserContext } from '../../../../../../../modules/auth/LoginUserContext';
import Form from '../../../../../../presentational/atoms/form';
import Input from '../../../../../../presentational/atoms/input';
import Button from '../../../../../../presentational/atoms/button';
import { Route, Switch, useHistory, useParams } from 'react-router';
import CreateCsvReportItemDefinitionDialog from '../../../../organisms/create-csv-report-item-definition-dialog';
import noData01 from '../../../../../../../base64Images/no-data/no-data-1';
import noData03 from '../../../../../../../base64Images/no-data/no-data-3';
import useDimensions from 'use-dimensions';
import SettingsElement from '../../../../organisms/settings-element';
import CustomScrollView from '../../../../../../presentational/atoms/custom-scroll-view';
import DeleteIcon from '../../../../../../presentational/molecules/image-icon/delete';
import PlusIcon from '../../../../../../presentational/molecules/image-icon/plus';
import SearchIcon from '../../../../../../presentational/molecules/image-icon/search';
import PlanNotAllowedView from '../../../../organisms/plan-not-allowed-view';
import OrganizationUtil from '../../../../../../../util/OrganizationUtil';
import VirtualizedFlatList, {
  GlobalDragColumnContextProvider,
  GlobalDragContextProvider,
  IListRefInfo,
} from '../../../../../../presentational/atoms/list2/virtualized-flat-list';

const Container = styled.View`
  display: flex;
  flex-direction: row;
`;

const MainAreaContainer = styled.View`
  display: flex;
  flex: 1;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.4s;
  z-index: 100;
`;

const CsvReportItemDefinitionItem = styled.View`
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  border-bottom-width: 1px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
`;

const CsvReportItemDefinitionAddButton = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  padding-right: 5px;
  padding-left: 5px;
`;

interface IDeleteCsvReportItemDefinitionDialogProps {
  organization: Organization;
  csvReportItemDefinition: CsvReportItemDefinition;
}

const DeleteCsvReportItemDefinitionDialog = (props: IDeleteCsvReportItemDefinitionDialogProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const history = useHistory();
  const [loginUser, __] = useContext(LoginUserContext);
  const [showModal, setShowModal] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [deleteReportItemDefinition, _] = useDeleteCsvReportItemDefinitionMutation({
    variables: {
      id: props.csvReportItemDefinition.id!,
      input: {
        versionNo: props.csvReportItemDefinition.versionNo,
      },
    },
    refetchQueries: [
      {
        query: CsvReportItemDefinitionsDocument,
      },
    ],
  });

  return (
    <>
      <DeleteIcon
        size={23}
        onPress={() => {
          setShowModal(true);
        }}>
        <Typography variant={TypographyType.Normal} style={{ fontSize: 16 }}>
          削除する
        </Typography>
      </DeleteIcon>
      <Modal
        title={`「${props.csvReportItemDefinition.name}」を削除しますか？`}
        isShow={showModal}
        onClose={() => {
          setShowModal(false);
          setShowAlertModal(false);
        }}>
        <Form>
          <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
            <Button
              text={'キャンセル'}
              style={{
                minWidth: 100,
                marginRight: 10,
                marginVertical: 10,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.primary }}
              disableValidate={true}
              onPress={() => {
                setShowModal(false);
                setShowAlertModal(false);
              }}
            />
            <Button
              text={'削除する'}
              style={{
                minWidth: 100,
                marginRight: 10,
                marginVertical: 10,
                borderColor: themeContext.colors.error,
                borderRadius: 3,
                borderWidth: 1,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.error }}
              onPress={async () => {
                setShowModal(false);
                setShowAlertModal(true);
              }}
            />
          </View>
        </Form>
      </Modal>
      <Modal
        title={`本当に削除してよろしいですか？`}
        isShow={showAlertModal}
        onClose={() => {
          setShowModal(false);
          setShowAlertModal(false);
        }}>
        <Typography
          variant={TypographyType.Description}
          style={{ textAlign: 'center', color: themeContext.colors.error }}>
          {`この操作はやり直しが出来ません`}
        </Typography>
        <Form>
          <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
            <Button
              text={'キャンセル'}
              style={{
                minWidth: 100,
                marginRight: 10,
                marginVertical: 10,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.primary }}
              disableValidate={true}
              onPress={() => {
                setShowModal(false);
                setShowAlertModal(false);
              }}
            />
            <Button
              text={'削除する'}
              style={{
                minWidth: 100,
                marginRight: 10,
                marginVertical: 10,
                borderColor: themeContext.colors.error,
                borderRadius: 3,
                borderWidth: 1,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.error }}
              onPress={async () => {
                await deleteReportItemDefinition();
                setShowModal(false);
                setShowAlertModal(false);
                if (OrganizationUtil.isPersonalPlan(props.organization)) {
                  history.push(
                    `/app/${loginUser!.organizationId}/my-analytics/csv-export-settings/`
                  );
                } else {
                  history.push(
                    `/app/${loginUser!.organizationId}/organization-analytics/csv-export-settings/`
                  );
                }
              }}
            />
          </View>
        </Form>
      </Modal>
    </>
  );
};

interface IReportItemDefinitionListProps {
  organization: Organization;
  setShowDialog: (value: boolean) => void;
}

const ReportItemDefinitionList = React.memo((props: IReportItemDefinitionListProps) => {
  const [searchDefinitionName, setSearchDefinitionName] = useState('');
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const history = useHistory();
  const { loading, data } = useCsvReportItemDefinitionsQuery({
    fetchPolicy: 'network-only',
  });

  // Memo化しておかないと、データに変更がなくても、このコンポーネントが描画される度に、全てのTaskSummary再描画がされてしまう。
  const definitions = useMemo(() => {
    if (loading || !data?.csvReportItemDefinitions) {
      return [];
    }
    return data!
      .csvReportItemDefinitions!.slice()
      .filter((definition) => definition!.name.indexOf(searchDefinitionName) !== -1);
  }, [loading, data?.csvReportItemDefinitions, searchDefinitionName]);

  if (loading) {
    return <></>;
  }

  return (
    <View style={{ zIndex: 1 }}>
      <View style={{ borderBottomWidth: 1, borderColor: themeContext.colors.separator }}>
        <Form>
          <Input
            name={'searchDefinition'}
            label={''}
            icon={<SearchIcon size={20} containerStyle={{ marginLeft: 10 }} />}
            containerStyle={{ marginVertical: 10 }}
            inputstyle={{ borderWidth: 0 }}
            inputContainerStyle={{
              marginHorizontal: 10,
              backgroundColor: themeContext.colors.baseColor,
              shadowOffset: {
                width: -1,
                height: -1,
              },
              shadowOpacity: 0.1,
              elevation: 2,
              borderWidth: 1,
              borderRadius: 20,
              borderColor: themeContext.colors.separator,
            }}
            onChange={(value: string) => setSearchDefinitionName(value)}
          />
        </Form>
        <CsvReportItemDefinitionAddButton
          style={{
            display: 'flex',
            flexDirection: 'row',
            paddingBottom: 10,
            paddingHorizontal: 5,
          }}
          onPress={async () => {
            props.setShowDialog(true);
          }}>
          <PlusIcon
            size={12}
            containerStyle={{ marginLeft: 10 }}
            onPress={async () => {
              props.setShowDialog(true);
            }}>
            <Typography
              variant={TypographyType.Normal}
              style={{ fontSize: 12, color: themeContext.colors.description }}>
              CSV出力設定を追加する
            </Typography>
          </PlusIcon>
        </CsvReportItemDefinitionAddButton>
      </View>

      <GlobalDragContextProvider>
        <VirtualizedFlatList
          style={{ maxWidth: 208, height: 'calc(100vh -190px)' }}
          items={definitions}
          renderItem={(item, index) => {
            return (
              <CsvReportItemDefinitionItem>
                <Typography
                  variant={TypographyType.Normal}
                  ellipsis={true}
                  style={{
                    fontSize: 13,
                    color: themeContext.colors.textColor,
                    textAlign: 'left',
                    lineHeight: 28,
                  }}>
                  {(item as CsvReportItemDefinition).name}
                </Typography>
              </CsvReportItemDefinitionItem>
            );
          }}
          getKey={(definition: any) => (definition as CsvReportItemDefinition).id!.toString()}
          itemHeight={40}
          onPress={(definition: any) => {
            if (OrganizationUtil.isPersonalPlan(props.organization!)) {
              history.push(
                `/app/${loginUser!.organizationId}/my-analytics/csv-export-settings/${
                  (definition as CsvReportItemDefinition).id
                }/`
              );
            } else {
              history.push(
                `/app/${loginUser!.organizationId}/organization-analytics/csv-export-settings/${
                  (definition as CsvReportItemDefinition).id
                }/`
              );
            }
          }}
        />
      </GlobalDragContextProvider>
    </View>
  );
});

interface ICsvReportItemDefinitionNameModalProps {
  closeModal: () => void;
  csvReportItemDefinition: CsvReportItemDefinition;
}

const CsvReportItemDefinitionNameModal = (props: ICsvReportItemDefinitionNameModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [name, setName] = useState(props.csvReportItemDefinition.name);

  const [updateCsvReportItemDefinition] = useUpdateCsvReportItemDefinitionMutation({
    variables: {
      id: props.csvReportItemDefinition.id!,
      input: {
        name: name,
        versionNo: props.csvReportItemDefinition.versionNo,
      },
    },
  });

  return (
    <Form style={{ minWidth: 400 }}>
      <Input
        name={'name'}
        label={'名称'}
        initialValue={name}
        onChange={setName}
        validate={{
          required: {
            value: true,
            message: '名称を入力してください',
          },
          maxLength: {
            value: 100,
            message: '100文字以内で入力してください',
          },
        }}
      />
      <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1 }}>
        <Button
          text={'変更する'}
          completeText="変更しました"
          style={{ minWidth: 100, marginRight: 10, marginVertical: 10 }}
          onPress={async () => {
            await updateCsvReportItemDefinition();
            props.closeModal();
          }}
        />
        <Button
          text={'キャンセル'}
          style={{
            minWidth: 100,
            marginRight: 10,
            marginVertical: 10,
            backgroundColor: 'transparent',
          }}
          textStyle={{ color: themeContext.colors.primary }}
          disableValidate={true}
          onPress={() => {
            props.closeModal();
          }}
        />
      </View>
    </Form>
  );
};

interface ReportItemGroup {
  isPrint: boolean;
  definition: CsvReportItemDefinition;
  reportItems: CsvReportItemDefinitionDetail[];
}

interface IListProps {
  organization: Organization;
  group: ReportItemGroup;
  groups: Array<ReportItemGroup>;
  column: number;
  width: number;
  isDragItem: boolean;
  setDragItem: (value: boolean) => void;
  listOuterRef: any;
  listRefs: Array<IListRefInfo>;
  setListRefs: (value: Array<IListRefInfo>) => void;
}

const List = React.memo((props: IListProps) => {
  const [draggingItem, setDraggingItem] = useState<CsvReportItemDefinitionDetail | null>(null);
  const [updateSortNo] = useUpdateCsvReportItemSortNoMutation();
  const [deleteItem] = useDeleteCsvReportItemMutation();
  const [addItem] = useAddCsvReportItemMutation();

  const onDrop = useCallback(
    async (info) => {
      if (
        !props.groups![info.startColumnIndex].isPrint &&
        !props.groups![info.endColumnIndex].isPrint
      ) {
        return;
      }
      if (
        props.groups![info.startColumnIndex].isPrint &&
        !props.groups![info.endColumnIndex].isPrint
      ) {
        await deleteItem({
          variables: {
            id: props.group.definition.id,
            input: {
              detailId: info.item.id!,
              versionNo: props.group.definition.versionNo,
            },
          },
        });
        return;
      }

      const isMoveToFirst = info.endRowIndex === 0;
      const isMoveToLast =
        info.startColumnIndex === info.endColumnIndex
          ? info.endRowIndex === props.groups![info.endColumnIndex].reportItems.length - 1
          : info.endRowIndex === props.groups![info.endColumnIndex].reportItems.length;
      const isMoveToDown = info.endRowIndex - info.startRowIndex > 0;
      let sortNo;
      if (isMoveToFirst) {
        sortNo = props.groups![info.endColumnIndex].reportItems[0].sortNo - 1000;
      } else if (isMoveToLast) {
        sortNo =
          info.startColumnIndex === info.endColumnIndex
            ? props.groups![info.endColumnIndex].reportItems[info.endRowIndex]!.sortNo + 1000
            : props.groups![info.endColumnIndex].reportItems[info.endRowIndex - 1]!.sortNo + 1000;
      } else {
        if (isMoveToDown && info.startColumnIndex === info.endColumnIndex) {
          const beforeTask = props.groups[info.endColumnIndex].reportItems[info.endRowIndex];
          const afterTask = props.groups[info.endColumnIndex].reportItems[info.endRowIndex + 1];
          sortNo = Math.floor((beforeTask!.sortNo + afterTask!.sortNo) / 2);
        } else {
          const beforeTask = props.groups[info.endColumnIndex].reportItems[info.endRowIndex - 1];
          const afterTask = props.groups[info.endColumnIndex].reportItems[info.endRowIndex];
          sortNo = Math.floor((beforeTask!.sortNo + afterTask!.sortNo) / 2);
        }
      }

      if (
        !props.groups![info.startColumnIndex].isPrint &&
        props.groups![info.endColumnIndex].isPrint
      ) {
        await addItem({
          variables: {
            id: props.groups![info.endColumnIndex].definition.id,
            input: {
              reportItemCode: info.item.reportItemCode,
              sortNo: sortNo,
              versionNo: props.group.definition.versionNo,
            },
          },
        });
        return;
      }

      await updateSortNo({
        variables: {
          id: props.group.definition.id,
          input: {
            detailId: info.item.id!,
            sortNo: sortNo,
            versionNo: props.group.definition.versionNo,
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateCsvReportItemSortNo: Object.assign(
            {
              __typename: 'CsvReportItemDefinitionDetail',
            },
            info.item,
            { sortNo: sortNo }
          ) as any,
        },
      });
    },
    [props.group.definition, props.group.isPrint, props.group.reportItems, props.groups]
  );

  const renderItem = useCallback(
    (item, index) => {
      return (
        <View
          style={{
            flexDirection: 'row',
            backgroundColor: '#FFFFFF',
            borderRadius: 5,
            height: 30,
            marginVertical: 5,
            marginHorizontal: 5,
            paddingHorizontal: 10,
            alignItems: 'center',
          }}>
          {draggingItem?.id !== item.id && props.group.isPrint && (
            <Typography variant={TypographyType.Description} style={{ fontSize: 12 }}>{`${
              index + 1
            }列目 `}</Typography>
          )}
          <Typography variant={TypographyType.Normal} style={{ fontSize: 14 }}>
            {(item as CsvReportItemDefinitionDetail).reportItemName}
          </Typography>
        </View>
      );
    },
    [props.isDragItem, draggingItem]
  );

  const getKey = useCallback((task) => task!.id!.toString(), []);

  const addListRefs = useCallback(
    (info) => {
      props.listRefs.push(info);
      props.setListRefs(props.listRefs);
    },
    [props.listRefs]
  );

  const updateListRefs = useCallback(
    (info) => {
      const findIndex = props.listRefs.findIndex((ref) => ref.columnKey === info.columnKey);
      props.listRefs[findIndex] = info;
      props.setListRefs(props.listRefs);
    },
    [props.listRefs, props.setListRefs]
  );

  const onDragStart = useCallback(
    (item) => {
      setDraggingItem(item);
      props.setDragItem(true);
    },
    [props.setDragItem, setDraggingItem]
  );

  const onDropEnd = useCallback(() => {
    setDraggingItem(null);
    props.setDragItem(false);
  }, [props.setDragItem, setDraggingItem]);

  return (
    <>
      <VirtualizedFlatList
        style={{
          height: '100%',
          backgroundColor: '#CCC',
        }}
        listOuterRef={props.listOuterRef}
        items={props.group!.reportItems}
        column={props.column}
        columnKey={props.group.definition.id}
        allColumns={props.groups.map((group) => group.reportItems)}
        renderItem={renderItem}
        getKey={getKey}
        listRefs={props.listRefs}
        addListRefs={addListRefs}
        updateListRefs={updateListRefs}
        itemHeight={40}
        itemWidth={props.width}
        virticalDraggable={true}
        horizontalDraggable={true}
        onDragStart={onDragStart}
        onDropEnd={onDropEnd}
        onDrop={onDrop}
      />
    </>
  );
});

interface IColumnProps {
  organization: Organization;
  group: ReportItemGroup;
  groups: Array<ReportItemGroup>;
  column: number;
  width: number;
  listRefs: Array<IListRefInfo>;
  setListRefs: (value: Array<IListRefInfo>) => void;
}

const Column = (props: IColumnProps) => {
  const [isDragItem, setDragItem] = useState(false);
  const listOuterRef = useRef();

  return (
    <View style={{ zIndex: isDragItem ? 3 : 1 }}>
      <Typography
        variant={TypographyType.ElementiTitle}
        style={{ fontSize: 14, textAlign: 'center' }}>
        {props.group.isPrint ? 'CSVファイルに記載する項目' : '記載しない項目'}
      </Typography>
      <View
        ref={listOuterRef as any}
        style={{
          width: props.width,
          height: 'calc(100vh - 300px)',
          backgroundColor: '#f4f5f7',
          paddingVertical: 10,
          paddingHorizontal: 2,
          marginHorizontal: 5,
          marginTop: 5,
          borderRadius: 5,
        }}>
        <List
          organization={props.organization}
          group={props.group as ReportItemGroup}
          groups={props.groups as Array<ReportItemGroup>}
          column={props.column}
          width={props.width}
          isDragItem={isDragItem}
          setDragItem={setDragItem}
          listOuterRef={listOuterRef}
          listRefs={props.listRefs}
          setListRefs={props.setListRefs}
        />
      </View>
    </View>
  );
};

interface ICsvItemReportDefinitionContainerInnerProps {
  organization: Organization;
  csvReportItemDefinitionId: string;
}

const CsvItemReportDefinitionContainerInner = React.memo(
  (props: ICsvItemReportDefinitionContainerInnerProps) => {
    const themeContext: IThemePart = useContext(ThemeContext);
    const { loading, data } = useCsvReportItemDefinitionQuery({
      variables: {
        id: props.csvReportItemDefinitionId,
      },
      fetchPolicy: 'network-only',
    });
    const { loading: defaultItemLoading, data: defaultData } =
      useDefaultCsvReportItemDefinitionQuery({
        fetchPolicy: 'network-only',
      });

    const groups = useMemo(() => {
      if (loading || defaultItemLoading) {
        return [];
      }

      return [
        {
          isPrint: true,
          definition: data!.csvReportItemDefinition!,
          reportItems: data!.csvReportItemDefinition!.items.slice().sort((a, b) => {
            return a!.sortNo - b!.sortNo; // ソート番号は昇順
          }) as CsvReportItemDefinitionDetail[],
        },
        {
          isPrint: false,
          definition: defaultData!.defaultCsvReportItemDefinition!,
          reportItems: defaultData!
            .defaultCsvReportItemDefinition!.items.slice()
            .sort((a, b) => {
              return a!.sortNo - b!.sortNo; // ソート番号は昇順
            })
            .filter(
              (item) =>
                data!.csvReportItemDefinition!.items.findIndex(
                  (printItem) => printItem!.reportItemCode === item!.reportItemCode
                ) === -1
            ),
        },
      ] as ReportItemGroup[];
    }, [loading, data?.csvReportItemDefinition, defaultData?.defaultCsvReportItemDefinition]);

    const [listRefs, setListRefs] = useState<Array<IListRefInfo>>([]);

    if (loading || defaultItemLoading) {
      return <></>;
    }

    return (
      <CustomScrollView>
        <View
          style={{
            flexDirection: 'column',
            paddingTop: 20,
            paddingHorizontal: 30,
            height: 'calc(100vh - 110px)',
          }}>
          <SettingsElement
            title={'名称'}
            titleWidth={100}
            changeText={'名称を変更する'}
            modal={(closeModal) => (
              <CsvReportItemDefinitionNameModal
                closeModal={closeModal}
                csvReportItemDefinition={data!.csvReportItemDefinition!}
              />
            )}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: 490 }}>
              <Typography variant={TypographyType.Normal}>
                {data!.csvReportItemDefinition!.name}
              </Typography>
              <DeleteCsvReportItemDefinitionDialog
                organization={props.organization}
                csvReportItemDefinition={data!.csvReportItemDefinition!}
              />
            </View>
          </SettingsElement>
          <View
            style={{
              flexDirection: 'row',
            }}>
            <ColumnDragContextProvider>
              <GlobalDragContextProvider>
                <GlobalDragColumnContextProvider>
                  {groups.map((group, i) => {
                    return (
                      <Column
                        organization={props.organization}
                        group={group}
                        groups={groups}
                        column={i}
                        width={320}
                        listRefs={listRefs}
                        setListRefs={setListRefs}
                        key={i}
                      />
                    );
                  })}
                </GlobalDragColumnContextProvider>
              </GlobalDragContextProvider>
            </ColumnDragContextProvider>
          </View>
        </View>
      </CustomScrollView>
    );
  }
);

const CsvItemReportDefinitionContainer = React.memo(() => {
  const params = useParams();
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const { data, loading } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId,
    },
  });

  if (loading) {
    return <></>;
  }
  return (
    <CsvItemReportDefinitionContainerInner
      organization={data!.organization!}
      csvReportItemDefinitionId={(params as any).csvReportItemDefinitionId}
    />
  );
});

interface IMainAreaProps {
  organization: Organization;
}

const MainArea = React.memo((props: IMainAreaProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const { window } = useDimensions();
  const { loading, data } = useCsvReportItemDefinitionsQuery({
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <></>;
  }

  return (
    <MainAreaContainer>
      <Switch>
        <Route
          path={`/app/:organizationId/${
            OrganizationUtil.isPersonalPlan(props.organization)
              ? 'my-analytics'
              : 'organization-analytics'
          }/csv-export-settings/:csvReportItemDefinitionId/`}>
          <CsvItemReportDefinitionContainer />
        </Route>
        <Route>
          <View
            style={{
              height: 'calc(100vh - 57px)',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            {data?.csvReportItemDefinitions && (data?.csvReportItemDefinitions || []).length > 0 ? (
              <>
                <Image
                  source={{ uri: noData01 }}
                  resizeMode="contain"
                  style={{
                    width: Math.min(window.width / 4, 300),
                    height: 200,
                    opacity: 0.7,
                  }}
                />
                <Typography
                  variant={TypographyType.Normal}
                  style={{ color: themeContext.colors.description }}>
                  CSV出力設定を選択してください
                </Typography>
              </>
            ) : (
              <>
                <Image
                  source={{ uri: noData03 }}
                  resizeMode="contain"
                  style={{
                    width: Math.min(window.width / 4, 300),
                    height: 200,
                    opacity: 0.7,
                  }}
                />
                <Typography
                  variant={TypographyType.Normal}
                  style={{ color: themeContext.colors.description }}>
                  CSV出力設定を登録してください
                </Typography>
              </>
            )}
          </View>
        </Route>
      </Switch>
    </MainAreaContainer>
  );
});

const OrganizationCsvExportSettingsPage = () => {
  const history = useHistory();
  const [showDialog, setShowDialog] = useState(false);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [notAllowedPlan, setNotAllowedPlan] = useState(false);
  const { loading: organizationLoading, data: organizationData } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId,
    },
    fetchPolicy: 'network-only',
  });

  // Basicプランの場合には利用できないようにする
  useEffect(() => {
    setTimeout(() => {
      if (organizationData?.organization?.plan.code === Plan.Basic) {
        setNotAllowedPlan(true);
      }
    }, 1000);
  }, [organizationData]);

  if (organizationLoading) {
    return <></>;
  }

  return (
    <PlanNotAllowedView isNotAllowedPlan={notAllowedPlan}>
      <Container>
        <ReportItemDefinitionList
          organization={organizationData!.organization!}
          setShowDialog={setShowDialog}
        />
        <MainArea organization={organizationData!.organization!} />
        <Modal
          isShow={showDialog}
          title="CSV出力設定を登録する"
          onClose={() => {
            setShowDialog(false);
          }}>
          <CreateCsvReportItemDefinitionDialog
            onComplete={(definition) => {
              setShowDialog(false);
              if (OrganizationUtil.isPersonalPlan(organizationData!.organization!)) {
                history.push(
                  `/app/${loginUser!.organizationId}/my-analytics/csv-export-settings/${
                    definition.id
                  }/`
                );
              } else {
                history.push(
                  `/app/${loginUser!.organizationId}/organization-analytics/csv-export-settings/${
                    definition.id
                  }/`
                );
              }
            }}
            onCancel={() => {
              setShowDialog(false);
            }}
          />
        </Modal>
      </Container>
    </PlanNotAllowedView>
  );
};

interface DragColumnInfo {
  startColumn: number;
  endColumn: number;
  dragging: boolean;
}

const ColumnDragContext = createContext([
  { startColumn: 0, endColumn: 0, dragging: false },
  (value: DragColumnInfo) => {},
]);

const ColumnDragContextProvider = (props: any) => {
  const [draggingPosIndex, setDraggingPosIndex] = useState<DragColumnInfo>({
    startColumn: 0,
    endColumn: 0,
    dragging: false,
  });
  return (
    <ColumnDragContext.Provider value={[draggingPosIndex as any, setDraggingPosIndex]}>
      {props.children}
    </ColumnDragContext.Provider>
  );
};

export default React.memo(OrganizationCsvExportSettingsPage);
