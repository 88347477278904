import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Text, View } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../../theme';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import Icon from '../../../../presentational/atoms/icon';
import Form from '../../../../presentational/atoms/form';
import Input from '../../../../presentational/atoms/input';
import { useParams } from 'react-router';
import {
  Member,
  Organization,
  Plan,
  ProjectTasksDocument,
  TaskDocument,
  useCreateTaskMutation,
  useCreateTasksMutation,
} from '../../../../../graphql/api/API';
import Spinner from '../../../../presentational/atoms/spinner';
import PlayIcon from '../../../../presentational/molecules/image-icon/play';
import CheckIcon from '../../../../presentational/molecules/image-icon/check';

const Container = styled.View`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 6px;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.4s;
  z-index: 100;
  border-width: 1px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
`;

const Main = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
`;

interface IProps {
  setShowNewTask: (value: boolean) => void;
  setShowCreateTasksLoading: (value: boolean) => void;
  loginMember: Member;
  showTeamName: boolean;
  showProjectName: boolean;
  organization: Organization;
  createWithBottom?: boolean;
}

interface IParam {
  teamId: string;
  projectId: string;
}

const TaskSummaryForNewTask = (props: IProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const params = useParams<IParam>();
  const [title, setTitle] = useState('');
  const [requestCreateTask, requestCreateTaskResult] = useCreateTaskMutation({
    // TODO optimistic-uiを使うと、以下の課題にぶちあたってしまって、リスト全体が再レンダリングされてしまう
    //      https://github.com/apollographql/apollo-client/issues/4141
    //
    // optimisticResponse: {
    //   __typename: 'Mutation',
    //   createTask: {
    //     __typename: 'Task',
    //     projectId: params.projectId,
    //     sortNoInGanttChart: 0,
    //     sortNoInGanttChartUpdateDateTime: new Date().toISOString(),
    //     sortNoInList: 0,
    //     sortNoInTaskStatus: 0,
    //     sortNoUpdateDateTimeInList: new Date().toISOString(),
    //     sortNoUpdateDateTimeInTaskStatus: new Date().toISOString(),
    //     taskStatusId: props.taskStatus.id!,
    //     createdMember: {
    //       memberRegistrationDateTime: new Date().toISOString(),
    //       memberStatus: MemberStatus.Active,
    //       name: '',
    //       organizationMemberRole: OrganizationMemberRole.Admin,
    //       versionNo: 0,
    //     },
    //     title: title,
    //     tracking: false,
    //     versionNo: 0,
    //     workingTimeSec: 0,
    //   },
    // },
    update: (cache, result) => {
      // 自動的にはRefetchされないので。
      cache.modify({
        fields: {
          projectTasks(existing = []) {
            const newTask = cache.writeQuery({
              data: result.data?.createTask,
              query: TaskDocument,
            });
            return [newTask, ...existing];
          },
          searchTasks(existing = []) {
            const newTask = cache.writeQuery({
              data: result.data?.createTask,
              query: TaskDocument,
            });
            return [newTask, ...existing];
          },
          boardTasks(existingRef, { readField }) {
            const taskStatusId = `TaskStatus:${result.data?.createTask?.taskStatus?.id}`; // Pusherからemptyデータが来るケースがあるので、taskStatusが無い場合がある
            if (
              taskStatusId === existingRef.taskStatus.__ref &&
              !existingRef.tasks.includes(taskStatusId)
            ) {
              return {
                taskStatus: existingRef.taskStatus,
                tasks: [
                  {
                    __ref: `Task:${result.data?.createTask!.id}`,
                  },
                  ...existingRef.tasks,
                ],
              };
            }
            return existingRef;
          },
        },
      });
    },
  });
  const [requestCreateTasks, resultCreateTasks] = useCreateTasksMutation({
    update: (cache, result) => {
      // 自動的にはRefetchされないので。
      cache.modify({
        fields: {
          projectTasks(existing = []) {
            const newTasks = result.data?.createTasks?.map((task) => {
              const newTask = cache.writeQuery({
                data: task,
                query: TaskDocument,
              });
              return newTask;
            });
            return [newTasks, ...existing];
          },
          searchTasks(existing = []) {
            const newTasks = result.data?.createTasks?.map((task) => {
              const newTask = cache.writeQuery({
                data: task,
                query: TaskDocument,
              });
              return newTask;
            });
            return [newTasks, ...existing];
          },
        },
      });
    },
  });

  const onPressEnter = useCallback(
    async (value) => {
      if (value.trim().length > 0) {
        await requestCreateTask({
          variables: {
            projectId: params.projectId,
            input: {
              title: value,
              sortNoInList: props.createWithBottom === true ? 0 : null,
              sortNoInTaskStatus: props.createWithBottom === true ? 0 : null,
              sortNoInGanttChart: props.createWithBottom === true ? 0 : null,
            },
          },
        });
      } else {
        props.setShowNewTask(false);
      }
    },
    [params, props.setShowNewTask]
  );

  const onPaste = useCallback(
    async (value: string) => {
      props.setShowCreateTasksLoading(true);
      const titles = value
        .split(/\r\n|\n/)
        .map((v) => v.trim())
        .filter((v) => v.length > 0);

      if (titles.length === 0) {
        props.setShowCreateTasksLoading(false);
        props.setShowNewTask(false);
        return;
      }
      await requestCreateTasks({
        variables: {
          projectId: params.projectId,
          input: {
            tasks: titles.map((title) => {
              return {
                title: title.trim().substring(0, 100),
                sortNoInList: props.createWithBottom === true ? 0 : null,
                sortNoInTaskStatus: props.createWithBottom === true ? 0 : null,
                sortNoInGanttChart: props.createWithBottom === true ? 0 : null,
              };
            }),
          },
        },
      });
      props.setShowCreateTasksLoading(false);
      props.setShowNewTask(false);
    },
    [params, props.setShowNewTask, requestCreateTasks, props.setShowCreateTasksLoading]
  );

  return (
    <Container>
      <Main>
        <View style={{ flexDirection: 'row' }}>
          <PlayIcon
            size={23}
            containerStyle={
              {
                marginTop: 3,
                marginRight: 10,
              } as any
            }
          />
          <Form style={{ minWidth: 300 }}>
            <Input
              name={'newTaskName'}
              focus={true}
              placeholder={'タスク名を入力してください'}
              inputstyle={{ borderWidth: 0, paddingHorizontal: 0, width: '100%' }}
              inputContainerStyle={{ width: '100%' }}
              containerStyle={{ margin: 0 }}
              validate={{
                maxLength: {
                  value: 100,
                  message: '100文字以内で入力してください',
                },
              }}
              clearValueOnBlur={true}
              allowContinuousInput={true}
              onBlur={() => props.setShowNewTask(false)}
              onChange={(value) => setTitle(value)}
              onPaste={onPaste}
              onPressEnter={onPressEnter}
            />
          </Form>
        </View>
        <View style={{ flexDirection: 'row' }}>
          {props.showTeamName && (
            <Typography
              variant={TypographyType.Normal}
              style={{ fontSize: 12, marginHorizontal: 10, minWidth: 40, textAlign: 'center' }}>
              -
            </Typography>
          )}
          {props.showProjectName && (
            <Typography
              variant={TypographyType.Normal}
              style={{ fontSize: 12, marginHorizontal: 10, minWidth: 40, textAlign: 'center' }}>
              -
            </Typography>
          )}
          {(props.organization.plan.code === Plan.Business ||
            props.organization.plan.code === Plan.Enterprise) && (
            <Typography
              variant={TypographyType.Normal}
              style={{ fontSize: 12, marginHorizontal: 10, minWidth: 40, textAlign: 'center' }}>
              -
            </Typography>
          )}
          <Typography
            variant={TypographyType.Normal}
            style={{ fontSize: 12, marginHorizontal: 10, minWidth: 30, textAlign: 'center' }}>
            -
          </Typography>
          <Typography
            variant={TypographyType.Normal}
            style={{ fontSize: 12, marginHorizontal: 10, minWidth: 70, textAlign: 'center' }}>
            -
          </Typography>
          <Typography
            variant={TypographyType.Normal}
            style={{ fontSize: 12, marginHorizontal: 10, minWidth: 70, textAlign: 'center' }}>
            -
          </Typography>
          <Typography
            variant={TypographyType.Normal}
            style={{ fontSize: 12, marginHorizontal: 10, minWidth: 90, textAlign: 'center' }}>
            -
          </Typography>
          <Typography
            variant={TypographyType.Normal}
            style={{ fontSize: 12, marginHorizontal: 10, minWidth: 90, textAlign: 'center' }}>
            -
          </Typography>
          <Typography
            variant={TypographyType.Normal}
            style={{ fontSize: 12, marginHorizontal: 10, minWidth: 40, textAlign: 'center' }}>
            -
          </Typography>
          <CheckIcon size={22} on={false} />
        </View>
      </Main>
      <View
        style={{
          backgroundColor: '#F8F8F8',
          width: '100%',
          height: 4,
          borderBottomWidth: 1,
          borderColor: themeContext.colors.separator,
        }}>
        <View
          style={{
            backgroundColor: '#FBD7B2',
            height: 4,
            position: 'absolute',
            left: 0,
          }}
        />
        <View
          style={{
            backgroundColor: '#EEADC3',
            height: 4,
            position: 'absolute',
            right: 0,
          }}
        />
      </View>
      <View style={{ backgroundColor: '#F8F8F8', width: '100%', height: 4 }}>
        <View
          style={{
            backgroundColor: '#99dcff',
            height: 4,
            position: 'absolute',
            left: 0,
          }}
        />
      </View>
    </Container>
  );
};

export default TaskSummaryForNewTask;
