import React from 'react';
import { LoginUserContextProvider } from '../../modules/auth/LoginUserContext';
import WebAppRoute from './web/WebAppRoot';

export default function Root() {
  return (
    <LoginUserContextProvider>
      <WebAppRoute />
    </LoginUserContextProvider>
  );
}
