import moment from 'moment-timezone';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { type TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';
import ToolTip from 'react-portal-tooltip';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import useInterval from 'use-interval';
import {
  CalendarWorkingHistoryDocument,
  type ClosingSetting,
  LatestWorkingHistoryDocument,
  type Member,
  MemberStatus,
  type Organization,
  ProjectWorkingTimeSecDocument,
  type Task,
  TaskDocument,
  TaskWorkingHistorySummaryGroupByAssineeDocument,
  Team,
  type WorkingHistory,
  WorkingHistoryDocument,
  type WorkingHistoryForSummary,
  WorkingHistoryForSummaryDocument,
  type WorkingHistorySummaryGroupByAssinee,
  useClosingSettingsQuery,
  useCreateWorkingHistoryMutation,
  useDeleteWorkingHistoryMutation,
  useMeQuery,
  useOrganizationQuery,
  useTaskWorkingHistoriesForSummaryQuery,
  useTaskWorkingHistorySummaryGroupByAssineeQuery,
  useTeamMembersQuery,
  useUpdateWorkingHistoryMutation,
} from '../../../../../../graphql/api/API';
import { LoginUserContext } from '../../../../../../modules/auth/LoginUserContext';
import OrganizationUtil from '../../../../../../util/OrganizationUtil';
import TimeUtil from '../../../../../../util/TimeUtil';
import Avatar from '../../../../../presentational/atoms/avatar';
import Button from '../../../../../presentational/atoms/button';
import EditableText from '../../../../../presentational/atoms/editable-text';
import useHover from '../../../../../presentational/atoms/editable-text/use-hover';
import Form from '../../../../../presentational/atoms/form';
import Icon from '../../../../../presentational/atoms/icon';
import Input, { type ListValueMap } from '../../../../../presentational/atoms/input';
import List, { ListItemData } from '../../../../../presentational/atoms/list';
import VirtualizedFlatList from '../../../../../presentational/atoms/list2/virtualized-flat-list';
import ToggleButton from '../../../../../presentational/atoms/toggle-button';
import Typography, { TypographyType } from '../../../../../presentational/atoms/typography';
import DeleteIcon from '../../../../../presentational/molecules/image-icon/delete';
import EditIcon from '../../../../../presentational/molecules/image-icon/edit';
import FileMoveIcon from '../../../../../presentational/molecules/image-icon/file-move';
import MenuIcon from '../../../../../presentational/molecules/image-icon/menu';
import PlusIcon from '../../../../../presentational/molecules/image-icon/plus';
import Modal from '../../../../../presentational/molecules/modal';
import SelectButton from '../../../../../presentational/molecules/select-button';
import type { IStyleTheme, IThemePart } from '../../../../../theme';
import MoveWorkingHistoryModal from '../../../../web/organisms/move-working-history-modal';
import ErrorMessageModal from '../../error-message-modal';
import TaskInfoElement from '../task-info-element';

const Conatiner = styled.View`
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  align-items: flex-start;
`;

const Row = styled.View`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: space-between;
`;

const AttachmentFileRow = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
  color: #ffffff;
  font-size: 1.5rem;
  padding: 5px 10px;
  border-bottom-width: 1px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
`;

const AddButton = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  padding-right: 5px;
  padding-left: 5px;
`;

const Menu = styled.View`
  position: absolute;
  top: 40px;
  right: 0;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
  border-width: 1px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 160px;
`;

interface IWorkingHistoryTimeProps {
  workingHistory: WorkingHistoryForSummary;
  style?: TextStyle;
}

export const WorkingHistoryTime = (props: IWorkingHistoryTimeProps) => {
  const [workingTimeSec, setWorkingTimeSec] = useState(0);
  useInterval(() => {
    if (!props.workingHistory.end) {
      setWorkingTimeSec(moment(new Date()).diff(moment(props.workingHistory.start), 'seconds'));
    }
  }, 1000);

  return (
    <Typography variant={TypographyType.Normal} style={props.style}>
      {props.workingHistory.end
        ? TimeUtil.formatForTask(
            moment(props.workingHistory.end).diff(moment(props.workingHistory.start), 'seconds')
          )
        : TimeUtil.formatForTask(workingTimeSec)}
    </Typography>
  );
};

interface IModalProps {
  showModal: boolean;
  workingHistory: WorkingHistoryForSummary;
  organization: Organization
  onPressYes: () => Promise<void>;
  onCloseModal: () => void;
}
const ConfirmModal = (props: IModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  return (
    <Modal
      title={'この作業履歴を削除しますか？'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      {OrganizationUtil.isTeamPlan(props.organization!) && (
        <View style={{ flexDirection: 'row', alignItems: 'center', zIndex: 4, marginBottom: 20 , flex: 1, marginTop: 10}}>
          <View style={{ minWidth: 130, marginLeft: 11 }}>
              <Typography variant={TypographyType.Normal} style={{ fontSize: 18, lineHeight: 22 }}>
                作業者
              </Typography>
          </View>
          <View style={{flex: 1}}>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Avatar
                size={28}
                name={props.workingHistory.workingMember?.name!}
                imageUrl={props.workingHistory.workingMember?.profileImageUrl}
              />
              <Typography variant={TypographyType.Normal} style={{ marginLeft: 5 }}>
                {props.workingHistory.workingMember?.name}
              </Typography>
            </View>
          </View>
        </View>
      )}
      <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 20, flex: 1 }}>
        <Typography variant={TypographyType.Normal} style={{ textAlign: 'center' }}>
          {`${moment(props.workingHistory.start).format('YYYY/MM/DD HH:mm')} 〜 ${
            props.workingHistory.end
              ? moment(props.workingHistory.end).format('YYYY/MM/DD HH:mm')
              : '-'
          }`}
        </Typography>
      </View>
      <View style={{ marginTop: 10 }}>
        <Typography
          variant={TypographyType.Description}
          style={{ textAlign: 'center', color: themeContext.colors.error }}>
          {`この操作はやり直しが出来ません`}
        </Typography>
      </View>
      <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1 }}>
        <Button
          text={'キャンセル'}
          style={{
            minWidth: 100,
            marginRight: 10,
            marginVertical: 10,
            backgroundColor: 'transparent',
          }}
          textStyle={{ color: themeContext.colors.primary }}
          disableValidate={true}
          onPress={() => {
            props.onCloseModal();
          }}
        />
        <Button
          text={'削除する'}
          style={{
            minWidth: 100,
            marginRight: 10,
            marginVertical: 10,
            borderColor: themeContext.colors.error,
            borderRadius: 3,
            borderWidth: 1,
            backgroundColor: 'transparent',
          }}
          textStyle={{ color: themeContext.colors.error }}
          onPress={async () => {
            await props.onPressYes();
          }}
        />
      </View>
    </Modal>
  );
};

interface IWorkingHistoryItemProps {
  workingHistory: WorkingHistoryForSummary;
  me: Member;
  organization: Organization
  moveToFront: (value: boolean) => void;
  closingSettings: ClosingSetting;
  teamMembers: Member[]
}

const WorkingHistoryItem = (props: IWorkingHistoryItemProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const menuRef = useRef();
  const refOuter = useRef<HTMLSpanElement>(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showTooltipClosed, setShowTooltipClosed] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showMoveModal, setShowMoveModal] = useState(false);
  const [deleteWorkingHistory, __] = useDeleteWorkingHistoryMutation({
    variables: {
      id: props.workingHistory.id!,
      input: {
        versionNo: props.workingHistory.versionNo,
      },
    },
    update: (cache, result) => {
      cache.evict({
        id: cache.identify(props.workingHistory),
      });
      cache.evict({ id: `WorkingHistoryForSummary:${props.workingHistory.id}` });
      cache.evict({ id: `CalendarWorkingHistory:${props.workingHistory.id}` });
    },
    refetchQueries: props.workingHistory.task
      ? [
          {
            query: TaskWorkingHistorySummaryGroupByAssineeDocument,
            variables: {
              teamId: props.workingHistory.task.project.team.id,
              taskId: props.workingHistory.task.id,
            },
          },
          {
            query: TaskDocument,
            variables: {
              id: props.workingHistory.task.id!,
            },
          },
          {
            query: LatestWorkingHistoryDocument,
          },
          {
            query: ProjectWorkingTimeSecDocument,
            variables: {
              id: props.workingHistory.task.project.id,
            },
          },
        ]
      : [
          {
            query: ProjectWorkingTimeSecDocument,
            variables: {
              id: props.workingHistory.calendarEvent!.projectId,
            },
          },
        ],
  });

  useHotkeys('Esc', () => setOpenMenu(false));
  const clickDocument = (e: any) => {
    if ((menuRef?.current as any)?.contains(e.target)) {
      (menuRef?.current as any)?.click();
      return;
    }
    setOpenMenu(false);
    props.moveToFront(false);
  };
  useEffect(() => {
    window.addEventListener('click', clickDocument);
    return () => {
      window.removeEventListener('click', clickDocument);
    };
  }, [clickDocument]);

  const memoSummary = props.workingHistory.memo
    ? JSON.parse(props.workingHistory.memo)
        .blocks.map((b: any) => b.text)
        .join(' ')
    : undefined;

  const memo = props.workingHistory.memo
    ? JSON.parse(props.workingHistory.memo).blocks.map((b: any) => b.text)
    : undefined;

  const isClosedDate = false
    // !!props.closingSettings.targetDateTime &&
    // moment(props.workingHistory.start).isBefore(moment(props.closingSettings.targetDateTime));

  return (
    <AttachmentFileRow
      style={{
        borderTopWidth: 1,
        zIndex: 1,
        backgroundColor: isClosedDate
          ? themeContext.colors.separator
          : themeContext.colors.baseColor,
      }}
      onPress={() => {
        if (
          props.workingHistory.workingMember.id !== props.me.id &&
          !props.me.proxyEditWorkingDataRole
        ) {
          return;
        }
        if (isClosedDate) {
          return;
        }
        setShowUpdateModal(true);
      }}>
      {/* <div
        style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
        id={`working-history-closed-tooltip-${props.workingHistory.id}`}
        onMouseEnter={() => setShowTooltipClosed(true)}
        onMouseLeave={() => setShowTooltipClosed(false)}> */}
        {props.workingHistory.task && !props.workingHistory.task.project.team.personalTeam && (
          <View style={{ minWidth: 40, alignItems: 'flex-start', justifyContent: 'center' }}>
            <Avatar
              size={24}
              name={props.workingHistory.workingMember.name || ''}
              imageUrl={props.workingHistory.workingMember.profileImageUrl}
            />
          </View>
        )}
        <View style={{ minWidth: 80, alignItems: 'center', justifyContent: 'center' }}>
          <Typography
            variant={TypographyType.Normal}
            style={{ fontSize: 13, textAlign: 'center', lineHeight: 17 }}>
            {`${moment(props.workingHistory.start).format('YYYY/MM/DD')}\n${moment(
              props.workingHistory.start
            ).format('HH:mm:ss')}`}
          </Typography>
        </View>
        <View style={{ minWidth: 30, alignItems: 'center', justifyContent: 'center' }}>
          <Typography variant={TypographyType.Normal} style={{ fontSize: 13, textAlign: 'center' }}>
            〜
          </Typography>
        </View>
        <View style={{ minWidth: 80, alignItems: 'center', justifyContent: 'center' }}>
          <Typography
            variant={TypographyType.Normal}
            style={{ fontSize: 13, textAlign: 'center', lineHeight: 17 }}>
            {props.workingHistory.end
              ? `${moment(props.workingHistory.end).format('YYYY/MM/DD')}\n${moment(
                  props.workingHistory.end
                ).format('HH:mm:ss')}`
              : '-'}
          </Typography>
        </View>
        <View
          style={{ minWidth: 100, alignItems: 'center', justifyContent: 'center', marginLeft: 5 }}>
          <WorkingHistoryTime
            workingHistory={props.workingHistory}
            style={{ fontSize: 13, textAlign: 'center' }}
          />
        </View>
        {props.workingHistory.memo ? (
          <div
            id={`working-history-memo-tooltip-${props.workingHistory.id}`}
            ref={refOuter as any}
            style={{
              flex: 1,
              alignItems: 'flex-start',
              justifyContent: 'center',
              marginLeft: 5,
              overflow: 'hidden',
            }}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}>
            <Typography
              variant={TypographyType.Normal}
              ellipsis
              style={{ fontSize: 13, textAlign: 'left', lineHeight: 17, display: 'block', width: 280}}>
              {memoSummary ?? '-'}
            </Typography>
            <ToolTip
              active={showTooltip && memoSummary.length > 7}
              position="bottom"
              arrow="center"
              tooltipTimeout={1}
              parent={`#working-history-memo-tooltip-${props.workingHistory.id}`}>
              {memo.map((line: string) => (
                <div>{line}</div>
              ))}
            </ToolTip>
          </div>
        ) : (
          <View
            style={{
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: 5,
            }}>
            <Typography
              variant={TypographyType.Normal}
              ellipsis
              style={{ fontSize: 13, textAlign: 'center', lineHeight: 17, display: 'block', width: 280 }}>
              {memoSummary ?? '-'}
            </Typography>
          </View>
        )}
        <View style={{ minWidth: 50, alignItems: 'center', justifyContent: 'center' }}>
          <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            {(props.workingHistory.workingMember.id === props.me.id ||
              props.me.proxyEditWorkingDataRole) &&
              !isClosedDate && (
                <MenuIcon
                  size={28}
                  onPress={() => {
                    props.moveToFront(true);
                    setOpenMenu(true);
                  }}
                />
              )}
          </View>
          {openMenu && (
            <Menu ref={menuRef as any}>
              <View style={{ paddingHorizontal: 5, marginVertical: 5 }}>
                <EditIcon
                  size={20}
                  containerStyle={{ marginRight: 5 }}
                  onPress={() => {
                    props.moveToFront(false);
                    setShowUpdateModal(true);
                    setOpenMenu(false);
                  }}>
                  <Typography variant={TypographyType.SubTitle}>編集する</Typography>
                </EditIcon>
              </View>
              <View style={{ paddingHorizontal: 5 }}>
                <FileMoveIcon
                  size={20}
                  containerStyle={{ marginRight: 5 }}
                  onPress={() => {
                    props.moveToFront(false);
                    setShowMoveModal(true);
                    setOpenMenu(false);
                  }}>
                  <Typography variant={TypographyType.SubTitle}>移動する</Typography>
                </FileMoveIcon>
              </View>
              <View style={{ paddingHorizontal: 5 }}>
                <DeleteIcon
                  size={23}
                  onPress={() => {
                    props.moveToFront(false);
                    setShowDeleteModal(true);
                    setOpenMenu(false);
                  }}>
                  <Typography variant={TypographyType.SubTitle}>削除する</Typography>
                </DeleteIcon>
              </View>
            </Menu>
          )}
          <ToolTip
            active={showTooltipClosed && isClosedDate}
            position="bottom"
            arrow="center"
            tooltipTimeout={1}
            parent={`#working-history-closed-tooltip-${props.workingHistory.id}`}>
            {isClosedDate && (
              <div>
                {moment(props.closingSettings.targetDateTime).format('ll')}
                までのデータは締め処理済みのため編集できません。
              </div>
            )}
          </ToolTip>
        </View>
        <ConfirmModal
          showModal={showDeleteModal}
          workingHistory={props.workingHistory}
          organization={props.organization}
          onPressYes={async () => {
            setShowDeleteModal(false);
            await deleteWorkingHistory();
          }}
          onCloseModal={() => setShowDeleteModal(false)}
        />
        <UpdateWorkingHistoryModal
          workingHistory={props.workingHistory}
          showModal={showUpdateModal}
          onCloseModal={() => setShowUpdateModal(false)}
          me={props.me}
        />
        <MoveWorkingHistoryModal
          workingHistory={props.workingHistory}
          showModal={showMoveModal}
          onCloseModal={() => setShowMoveModal(false)}
        />
      {/* </div> */}
    </AttachmentFileRow>
  );
};

interface IWorkingHistorySummaryTimeProps {
  workingHistorySummary: WorkingHistorySummaryGroupByAssinee;
  style?: TextStyle;
}

export const WorkingHistorySummaryTime = (props: IWorkingHistorySummaryTimeProps) => {
  const [workingTimeSec, setWorkingTimeSec] = useState(0);
  useInterval(() => {
    if (props.workingHistorySummary.tracking) {
      setWorkingTimeSec(
        props.workingHistorySummary.totalWorkingTimeSec +
          moment().diff(moment(props.workingHistorySummary.lastWorkingStartDateTime), 'seconds')
      );
    }
  }, 1000);

  return (
    <Typography variant={TypographyType.Normal} style={props.style}>
      {props.workingHistorySummary.tracking
        ? TimeUtil.formatForTask(workingTimeSec)
        : TimeUtil.formatForTask(props.workingHistorySummary.totalWorkingTimeSec)}
    </Typography>
  );
};

interface IAddWorkingHistoryModalProps {
  teamId: string;
  projectId: string;
  taskId: string;
  teamMembers: Member[]
  me: Member
  showModal: boolean;
  onCloseModal: () => void;
  onRegisterOtherMemberWorkingHistory: () => void
}

const AddWorkingHistoryModal = (props: IAddWorkingHistoryModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [start, setStart] = useState<moment.Moment>(moment().seconds(0).milliseconds(0));
  const [end, setEnd] = useState<moment.Moment>(moment().seconds(0).milliseconds(0));
  const [memo, setMemo] = useState('');
  const [focusEndDateTimeInput, setFocusEndDateTimeInput] = useState(false);
  const [targetMemberId, setTargetMemberId] = useState(loginUser?.id)
  const [createWokingHistory, _] = useCreateWorkingHistoryMutation({
    variables: {
      taskId: props.taskId,
      input: {
        start: start?.toISOString(),
        end: end?.toISOString(),
        memo: memo ?? null,
        targetMemberId: targetMemberId
      },
    },
    update: (cache, result) => {
      cache.modify({
        fields: {
          taskWorkingHistoriesForSummary(existing = [], { storeFieldName }) {
            const newWorkingHistory = cache.writeQuery({
              data: result.data!.createWorkingHistory,
              query: WorkingHistoryForSummaryDocument,
            });
            return [...existing, newWorkingHistory];
          },
          workingHistoriesSpecifyTermForCalendar(existing = [], { storeFieldName }) {
            const newWorkingHistory = cache.writeQuery({
              data: result.data!.createWorkingHistory,
              query: WorkingHistoryDocument,
            });
            const targetMemberData = existing.filter((d: any) => d.memberId === targetMemberId)[0];
            if((targetMemberData?.length ?? 0) === 0){
              return [...existing, {
                memberId: result.data!.createWorkingHistory?.workingMember.id,
                workingHistories: [newWorkingHistory]
              }];
            }              
            return existing.map((data: any) => {
              if(data.memberId !== targetMemberId){
                return data;
              }
              return {
                memberId: data.memberId,
                workingHistories: [...targetMemberData.workingHistories, newWorkingHistory]
              }
            })
          },
        },
      });
    },
    refetchQueries: [
      {
        query: TaskWorkingHistorySummaryGroupByAssineeDocument,
        variables: {
          teamId: props.teamId,
          taskId: props.taskId,
        },
      },
      {
        query: LatestWorkingHistoryDocument,
      },
      {
        query: ProjectWorkingTimeSecDocument,
        variables: {
          id: props.projectId,
        },
      },
    ],
  });

  useEffect(() => {
    setStart(moment().seconds(0).milliseconds(0));
    setEnd(moment().seconds(0).milliseconds(0));
    setMemo('');
    setTargetMemberId(loginUser?.id)
  }, [props.showModal]);

  return (
    <Modal
      title={'作業履歴を追加する'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <View style={{ flexDirection: 'column', justifyContent: 'center', paddingTop: 20 }}>
        <Form style={{ minWidth: 400 }}>
       
        {props.me.proxyEditWorkingDataRole && (
        <View style={{ flexDirection: 'row', alignItems: 'center', zIndex: 4, marginBottom: 20 }}>
          <View style={{ minWidth: 130 }}>
              <Typography variant={TypographyType.Normal} style={{ fontSize: 18, lineHeight: 22 }}>
                作業者
              </Typography>
          </View>
          <View style={{flex: 1}}>
            <EditableText
              value={targetMemberId}
              type={'picker'}
              isSearchable={true}
              pickerItems={
                props.teamMembers.filter((member) => member?.memberStatus === MemberStatus.Active)
                  .map((member) => {
                    return {
                      label: member!.name,
                      value: member!.id!,
                      imageUrl: member!.profileImageUrl,
                    } as ListValueMap;
                  })
                  .sort((a, b) => (a.value === loginUser?.id ? -1 : 1)) // 自分を先頭に表示
              }
              renderComponent={() => {
                const member = props.teamMembers.filter(mem => mem.id === targetMemberId)?.[0]
                if(!member){
                  return <></>
                }
                return (
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <Avatar
                      size={28}
                      name={member?.name!}
                      imageUrl={member?.profileImageUrl}
                    />
                    <Typography variant={TypographyType.Normal} style={{ marginLeft: 5 }}>
                      {member?.name}
                    </Typography>
                  </View>
                );
              }}
              onChange={(items) => {
                setTargetMemberId(items as string)
              }}
              onBlur={(items) => {
                setTargetMemberId(items as string)
              }}
              textStyle={{ fontSize: 18 }}
            />
          </View>
          </View>
        )}

          <View style={{ flexDirection: 'row', alignItems: 'center', zIndex: 3 }}>
            <View style={{ minWidth: 130 }}>
              <Typography variant={TypographyType.Normal} style={{ fontSize: 18, lineHeight: 22 }}>
                作業開始日時
              </Typography>
            </View>
            <EditableText
              value={start}
              type={'date-time-picker'}
              textStyle={{ fontSize: 18, lineHeight: 22 }}
              containerStyle={{
                borderBottomWidth: 1,
                borderBottomColor: themeContext.colors.textColor,
              }}
              disableClear={true}
              onChange={(value) => {
                setStart(value as moment.Moment);
              }}
            />
          </View>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 20,
              zIndex: focusEndDateTimeInput ? 4 : 2,
            }}>
            <View style={{ minWidth: 130 }}>
              <Typography variant={TypographyType.Normal} style={{ fontSize: 18, lineHeight: 22 }}>
                作業終了日時
              </Typography>
            </View>
            <EditableText
              value={end}
              type={'date-time-picker'}
              textStyle={{ fontSize: 18, lineHeight: 22 }}
              containerStyle={{
                borderBottomWidth: 1,
                borderBottomColor: themeContext.colors.textColor,
              }}
              disableClear={true}
              onChange={(value) => {
                setEnd(value as moment.Moment);
              }}
              onFocus={() => setFocusEndDateTimeInput(true)}
              onBlur={() => setFocusEndDateTimeInput(false)}
            />
          </View>
          {start?.isAfter(end) && (
            <View style={{ marginTop: 10 }}>
              <Typography
                variant={TypographyType.Description}
                style={{ color: themeContext.colors.error }}>
                開始日時は、終了日時よりも前にしてください
              </Typography>
            </View>
          )}
          {(!start || !end) && (
            <View style={{ marginTop: 10 }}>
              <Typography
                variant={TypographyType.Description}
                style={{ color: themeContext.colors.error }}>
                開始日時・終了日時を入力してください
              </Typography>
            </View>
          )}
          <View
            style={
              {
                flexDirection: 'row',
                marginTop: 20,
                zIndex: 2,
                alignItems: 'flex-start',
              } as any
            }>
            <View style={{ minWidth: 88 }}>
              <Typography variant={TypographyType.Normal} style={{ fontSize: 18, lineHeight: 22 }}>
                作業メモ
              </Typography>
            </View>
            <View style={{ minWidth: 'calc(100% - 90px)' }}>
              <Form style={{ zIndex: 2 }}>
                <Input
                  initialValue={''}
                  name={'description'}
                  type={'rich-text-editor'}
                  showToolBarAlways={true}
                  onChange={(value) => {
                    setMemo(value);
                  }}
                />
              </Form>
            </View>
          </View>
          <View
            style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
            <Button
              text={'追加する'}
              style={{ minWidth: 100, marginRight: 10, marginVertical: 20 }}
              isDisabled={start?.isAfter(end) || !start || !end}
              onPress={async () => {
                await createWokingHistory();
                if(targetMemberId !== loginUser?.id){
                  props.onRegisterOtherMemberWorkingHistory();
                }
                props.onCloseModal();
              }}
            />
            <Button
              text={'キャンセル'}
              style={{
                minWidth: 100,
                marginLeft: 10,
                marginVertical: 20,
                backgroundColor: '#FFFFFF',
                borderColor: themeContext.colors.primary,
                borderWidth: 1,
                borderRadius: 5,
                height: 40,
              }}
              textStyle={{ color: themeContext.colors.primary }}
              disableValidate={true}
              onPress={() => {
                props.onCloseModal();
              }}
            />
          </View>
        </Form>
      </View>
    </Modal>
  );
};

interface IUpdateWorkingHistoryModalProps {
  workingHistory: WorkingHistoryForSummary;
  showModal: boolean;
  onCloseModal: () => void;
  targetMember?: Member;
  me: Member
}

export const UpdateWorkingHistoryModal = (props: IUpdateWorkingHistoryModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, __] = useContext(LoginUserContext);
  const [start, setStart] = useState<moment.Moment>(moment(props.workingHistory.start));
  const [end, setEnd] = useState<moment.Moment | null>(
    props.workingHistory.end ? moment(props.workingHistory.end) : null
  );
  const [memo, setMemo] = useState<string | null>(props.workingHistory.memo || null);
  const [targetMemberId, setTargetMemberId] = useState(props.workingHistory.workingMember.id)
  const [hoverRef, isHover] = useHover();
  const [showEditor, setShowEditor] = useState(false);
  const [focusEndDateTimeInput, setFocusEndDateTimeInput] = useState(false);
  const { data: organizationData, loading: organizationLoading } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId,
    },
  });
  const fetchTeamMembers = useTeamMembersQuery({
    variables: {
      teamId: props.workingHistory.task?.project.team.id ?? props.workingHistory.calendarEvent?.teamId
    }
  })
  const [updateWokingHistory, _] = useUpdateWorkingHistoryMutation({
    variables: {
      id: props.workingHistory.id!,
      input: {
        start: start?.toISOString(),
        end: end ? end.toISOString() : null,
        memo: memo,
        versionNo: props.workingHistory.versionNo,
        targetMemberId: targetMemberId
      },
    },
    refetchQueries: props.workingHistory.task
      ? [
          {
            query: TaskWorkingHistorySummaryGroupByAssineeDocument,
            variables: {
              teamId: props.workingHistory.task.project.team.id,
              taskId: props.workingHistory.task.id,
            },
          },
          {
            query: TaskDocument,
            variables: {
              id: props.workingHistory.task.id!,
            },
          },
          {
            query: WorkingHistoryForSummaryDocument,
            variables: {
              id: props.workingHistory.id!,
            },
          },
          {
            query: CalendarWorkingHistoryDocument,
            variables: {
              id: props.workingHistory.id!,
            },
          },
          {
            query: LatestWorkingHistoryDocument,
          },
          {
            query: ProjectWorkingTimeSecDocument,
            variables: {
              id: props.workingHistory.task.project.id,
            },
          },
        ]
      : [
          {
            query: ProjectWorkingTimeSecDocument,
            variables: {
              id: props.workingHistory.calendarEvent!.projectId,
            },
          },
          {
            query: WorkingHistoryForSummaryDocument,
            variables: {
              id: props.workingHistory.id!,
            },
          },
          {
            query: CalendarWorkingHistoryDocument,
            variables: {
              id: props.workingHistory.id!,
            },
          },
        ],
  });

  useEffect(() => {
    setStart(moment(props.workingHistory.start));
    setEnd(props.workingHistory.end ? moment(props.workingHistory.end) : null);
    setMemo(props.workingHistory.memo || null);
    setTargetMemberId(props.workingHistory.workingMember.id)
  }, [props.workingHistory, props.showModal]);

  const isContentEmpty =
    props.workingHistory.memo === null ||
    (props.workingHistory.memo &&
      JSON.parse(props.workingHistory.memo).blocks &&
      JSON.parse(props.workingHistory.memo).blocks.length > 0 &&
      JSON.parse(props.workingHistory.memo).blocks[0]?.text === '');

  if (organizationLoading || fetchTeamMembers.loading || !fetchTeamMembers.data) {
    return <></>;
  }
    
  return (
    <Modal
      title={'作業履歴を編集する'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
        setShowEditor(false);
      }}>
      <View style={{ flexDirection: 'column', justifyContent: 'center', paddingTop: 20 }}>
        <Form>
        {props.me.proxyEditWorkingDataRole && fetchTeamMembers.data.teamMembers!.length > 0 && (
          <View style={{ flexDirection: 'row', alignItems: 'center', zIndex: 4, marginBottom: 20 }}>
            <View style={{ minWidth: 130 }}>
                <Typography variant={TypographyType.Normal} style={{ fontSize: 18, lineHeight: 22 }}>
                  作業者
                </Typography>
            </View>
            <View style={{flex: 1}}>
              <EditableText
                value={targetMemberId}
                type={'picker'}
                isSearchable={true}
                pickerItems={
                  fetchTeamMembers.data.teamMembers!.filter((member) => member?.memberStatus === MemberStatus.Active)
                    .map((member) => {
                      return {
                        label: member!.name,
                        value: member!.id!,
                        imageUrl: member!.profileImageUrl,
                      } as ListValueMap;
                    })
                    .sort((a, b) => (a.value === loginUser?.id ? -1 : 1)) // 自分を先頭に表示
                }
                renderComponent={() => {
                  const member = fetchTeamMembers.data!.teamMembers!.filter(mem => mem.id === targetMemberId)?.[0]
                  if(!member){
                    return <></>
                  }
                  return (
                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                      <Avatar
                        size={28}
                        name={member?.name!}
                        imageUrl={member?.profileImageUrl}
                      />
                      <Typography variant={TypographyType.Normal} style={{ marginLeft: 5 }}>
                        {member?.name}
                      </Typography>
                    </View>
                  );
                }}
                onChange={(items) => {
                  setTargetMemberId(items as string)
                }}
                onBlur={(items) => {
                  setTargetMemberId(items as string)
                }}
                textStyle={{ fontSize: 18 }}
              />
            </View>
            </View>
          )}
          {!(props.me.proxyEditWorkingDataRole && fetchTeamMembers.data.teamMembers!.length > 0) && OrganizationUtil.isTeamPlan(organizationData?.organization!) && (
            <View style={{ flexDirection: 'row', alignItems: 'center', zIndex: 4, marginBottom: 20 }}>
              <View style={{ minWidth: 130 }}>
                  <Typography variant={TypographyType.Normal} style={{ fontSize: 18, lineHeight: 22 }}>
                    作業者
                  </Typography>
              </View>
              <View style={{flex: 1}}>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <Avatar
                    size={28}
                    name={props.workingHistory.workingMember?.name!}
                    imageUrl={props.workingHistory.workingMember?.profileImageUrl}
                  />
                  <Typography variant={TypographyType.Normal} style={{ marginLeft: 5 }}>
                    {props.workingHistory.workingMember?.name}
                  </Typography>
                </View>
              </View>
            </View>
          )}
          <View style={{ flexDirection: 'row', alignItems: 'center', zIndex: 4 }}>
            <View style={{ minWidth: 130 }}>
              <Typography variant={TypographyType.Normal} style={{ fontSize: 18, lineHeight: 22 }}>
                作業開始日時
              </Typography>
            </View>
            <EditableText
              value={start}
              type={'date-time-picker'}
              textStyle={{ fontSize: 18, lineHeight: 22 }}
              containerStyle={{
                borderBottomWidth: 1,
                borderBottomColor: themeContext.colors.textColor,
              }}
              disableClear={true}
              onChange={(value) => {
                setStart(value as moment.Moment);
              }}
            />
          </View>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 20,
              zIndex: focusEndDateTimeInput ? 5 : 3,
            }}>
            <View style={{ minWidth: 130 }}>
              <Typography variant={TypographyType.Normal} style={{ fontSize: 18, lineHeight: 22 }}>
                作業終了日時
              </Typography>
            </View>
            {props.workingHistory.end ? (
              <EditableText
                value={end}
                type={'date-time-picker'}
                textStyle={{ fontSize: 18, lineHeight: 22 }}
                containerStyle={{
                  borderBottomWidth: 1,
                  borderBottomColor: themeContext.colors.textColor,
                }}
                disableClear={true}
                onChange={(value) => {
                  setEnd(value as moment.Moment);
                }}
                onFocus={() => setFocusEndDateTimeInput(true)}
                onBlur={() => setFocusEndDateTimeInput(false)}
              />
            ) : (
              <Typography
                variant={TypographyType.Normal}
                style={{ fontSize: 13, textAlign: 'center', lineHeight: 17 }}>
                -
              </Typography>
            )}
          </View>
          {start?.isAfter(end || moment()) && (
            <View style={{ marginTop: 10 }}>
              <Typography
                variant={TypographyType.Description}
                style={{ color: themeContext.colors.error }}>
                開始日時は、終了日時よりも前にしてください
              </Typography>
            </View>
          )}
          {props.workingHistory.end === null && !start && (
            <View style={{ marginTop: 10 }}>
              <Typography
                variant={TypographyType.Description}
                style={{ color: themeContext.colors.error }}>
                開始日時を入力してください
              </Typography>
            </View>
          )}
          {props.workingHistory.end !== null && (!start || !end) && (
            <View style={{ marginTop: 10 }}>
              <Typography
                variant={TypographyType.Description}
                style={{ color: themeContext.colors.error }}>
                開始日時・終了日時を入力してください
              </Typography>
            </View>
          )}
          <View
            style={
              {
                flexDirection: 'row',
                marginTop: 20,
                zIndex: 2,
                alignItems: 'flex-start',
              } as any
            }>
            <View style={{ minWidth: 130 }}>
              <Typography variant={TypographyType.Normal} style={{ fontSize: 18, lineHeight: 22 }}>
                作業メモ
              </Typography>
            </View>
            <View ref={hoverRef as any} style={{ minWidth: 300 }}>
              {showEditor ? (
                <Form style={{ zIndex: 2 }}>
                  <Input
                    initialValue={props.workingHistory.memo || ''}
                    name={'description'}
                    type={'rich-text-editor'}
                    showToolBarAlways={true}
                    autoFocus={true}
                    onChange={(value) => {
                      setMemo(value);
                    }}
                  />
                </Form>
              ) : (
                <TouchableOpacity onPress={() => setShowEditor(true)} style={{ zIndex: 2 }}>
                  <Form>
                    <div
                      style={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        backgroundColor: themeContext.colors.separator,
                        zIndex: 2,
                        opacity: isHover ? 0.5 : 0,
                        cursor: 'pointer',
                      }}
                    />
                    <View
                      style={{
                        opacity: isContentEmpty ? 0.2 : 1,
                        minHeight: 50,
                        paddingHorizontal: 10,
                      }}>
                      <Input
                        initialValue={
                          isContentEmpty
                            ? `{
                  "blocks": [
                    {
                      "key": "dummy",
                      "text": "作業メモを追加...",
                      "type": "unstyled",
                      "depth": 0,
                      "inlineStyleRanges": [],
                      "entityRanges": [],
                      "data": {}
                    }
                  ],
                  "entityMap": {}
                }
                `
                            : props.workingHistory.memo!
                        }
                        name={'description'}
                        type={'rich-text-editor'}
                        showToolBarAlways={false}
                        multiline={true}
                        readonly={true}
                      />
                    </View>
                  </Form>
                </TouchableOpacity>
              )}
            </View>
          </View>
          <View
            style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
            <Button
              text={'更新する'}
              style={{ minWidth: 100, marginRight: 10, marginVertical: 20 }}
              isDisabled={
                props.workingHistory.end !== null ? start?.isAfter(end) || !start || !end : !start
              }
              onPress={async () => {
                await updateWokingHistory();
                props.onCloseModal();
                setShowEditor(false);
              }}
            />
            <Button
              text={'キャンセル'}
              style={{
                minWidth: 100,
                marginLeft: 10,
                marginVertical: 20,
                backgroundColor: '#FFFFFF',
                borderColor: themeContext.colors.primary,
                borderWidth: 1,
                borderRadius: 5,
                height: 40,
              }}
              textStyle={{ color: themeContext.colors.primary }}
              disableValidate={true}
              onPress={() => {
                props.onCloseModal();
                setShowEditor(false);
              }}
            />
          </View>
        </Form>
      </View>
    </Modal>
  );
};

interface IWorkingHistoryListProps {
  taskId: string;
  me: Member;
  organization: Organization
  showOtherMembersData: boolean;
  closingSettings: ClosingSetting;
  teamMembers: Member[]
}

const WorkingHistoryList = React.memo((props: IWorkingHistoryListProps) => {
  const pageSize = 20;
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const fetchWorkingHistories = useTaskWorkingHistoriesForSummaryQuery({
    variables: {
      taskId: props.taskId,
      onlySelfData: !props.showOtherMembersData,
      offset: 0,
      limit: pageSize,
    },
    fetchPolicy: 'network-only',
  });
  const renderItem = useCallback(
    (item, dragging, index, isGroupFirstRow, moveToFront) => (
      <WorkingHistoryItem
        workingHistory={item.data}
        me={props.me}
        organization={props.organization}
        teamMembers={props.teamMembers}
        moveToFront={moveToFront}
        closingSettings={props.closingSettings}
        key={index}
      />
    ),
    [props.me, props.closingSettings, props.organization, props.teamMembers]
  );

  const workingHistories = (fetchWorkingHistories.data?.taskWorkingHistoriesForSummary || [])
    .slice()
    .map((history) => {
      return {
        data: {
          id: history!.id!,
          start: moment(history!.start),
          end: history!.end ? moment(history!.end) : null,
          memo: history!.memo,
          task: history!.task,
          workingMember: history!.workingMember,
          workingTimeSec: history?.workingTimeSec,
          versionNo: history!.versionNo,
          closingProcessInfo: {
            closed: history!.closingProcessInfo.closed,
            targetDateTime: history!.closingProcessInfo.targetDateTime,
          },
        } as WorkingHistory,
        group: moment(history!.start).format('ll'),
      };
    })
    .sort(
      (a, b) =>
        (b.data.start as moment.Moment).toDate().getTime() -
        (a.data.start as moment.Moment).toDate().getTime()
    );

  if (fetchWorkingHistories.loading) {
    return <></>;
  }

  return (
    <List
      style={{ height: 'calc(100vh - 310px)', overflow: 'scroll' }}
      items={workingHistories}
      getKey={(workingHistory) => (workingHistory.data as any).id}
      showGroupHeader={true}
      renderItem={renderItem}
      onEndReached={() => {
        if ((fetchWorkingHistories?.data?.taskWorkingHistoriesForSummary?.length ?? 0) < pageSize) {
          return;
        }
        fetchWorkingHistories.fetchMore({
          variables: {
            offset: fetchWorkingHistories.data!.taskWorkingHistoriesForSummary!.length,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return Object.assign({}, prev, {
              taskWorkingHistoriesForSummary: [
                ...(prev.taskWorkingHistoriesForSummary || []),
                ...(fetchMoreResult.taskWorkingHistoriesForSummary || []),
              ],
            });
          },
        });
      }}
    />
  );
});

interface ITaskDetailStandardInfoProps {
  task: Task;
}

const TaskDetailWorkingHistory = (props: ITaskDetailStandardInfoProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [showModal, setShowModal] = useState(false);
  const [showOtherMembersData, setShowOtherMembersData] = useState(false);
  const [showCompleteTaskStartErrorModal, setShowCompleteTaskStartErrorModal] = useState(false);
  const summaryResult = useTaskWorkingHistorySummaryGroupByAssineeQuery({
    variables: {
      teamId: props.task.project.team.id,
      taskId: props.task.id!,
    },
    fetchPolicy: 'network-only',
  });
  const { loading, data } = useMeQuery();
  const { loading: organizationLoading, data: organizationData } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId,
    },
  });
  const { loading: loadingClosingSetting, data: dataClosingSetting } = useClosingSettingsQuery({
    fetchPolicy: 'network-only',
  });
  const fetchTeamMembers = useTeamMembersQuery({
    variables: {
      teamId: props.task.project.team.id!,
    },
  });
  if (
    loading ||
    !data?.me ||
    organizationLoading ||
    !organizationData?.organization ||
    loadingClosingSetting ||
    !dataClosingSetting?.closingSettings
  ) {
    return <></>;
  }
  return (
    <>
      <Conatiner>
        <Row>
          {!summaryResult.loading &&
            (summaryResult.data?.taskWorkingHistorySummaryGroupByAssinee?.length ?? 0) > 0 && (
              <TaskInfoElement
                title={'合計作業時間'}
                style={{ flex: 1 }}
                contentFlexDirection={'row'}
                contentFlexWrap={'wrap'}>
                {summaryResult.data!.taskWorkingHistorySummaryGroupByAssinee!.map((summary, i) => {
                  return (
                    <View
                      style={{ flexDirection: 'row', marginBottom: 10, marginRight: 20 }}
                      key={i}>
                      {!props.task.project.team.personalTeam && (
                        <Avatar
                          size={28}
                          name={summary!.member!.name!}
                          imageUrl={summary!.member!.profileImageUrl}
                          containerStyle={{ marginRight: 5 }}
                        />
                      )}
                      <WorkingHistorySummaryTime workingHistorySummary={summary!} />
                    </View>
                  );
                })}
              </TaskInfoElement>
            )}
        </Row>
      </Conatiner>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingRight: 20,
        }}>
        <AddButton
          onPress={() => {
            if (!!props.task.completeDateTime || props.task.project.complete) {
              setShowCompleteTaskStartErrorModal(true);
              return;
            }
            setShowModal(true);
          }}
          style={{
            display: 'flex',
            flexDirection: 'row',
            paddingLeft: 10,
          }}>
          <PlusIcon
            size={13}
            onPress={() => {
              if (!!props.task.completeDateTime || props.task.project.complete) {
                setShowCompleteTaskStartErrorModal(true);
                return;
              }
              setShowModal(true);
            }}>
            <Typography
              variant={TypographyType.Normal}
              style={{ fontSize: 13, color: themeContext.colors.description }}>
              作業履歴を追加する
            </Typography>
          </PlusIcon>
        </AddButton>
        {OrganizationUtil.isTeamPlan(organizationData.organization) && (
          <Button
            text={
              showOtherMembersData
                ? '他のメンバーのデータも表示しない'
                : '他のメンバーのデータも表示する'
            }
            textStyle={{ fontSize: 12, color: showOtherMembersData ? '#FFFFFF' : '#8eafce' }}
            style={{
              paddingHorizontal: 6,
              paddingVertical: 2,
              borderRadius: 4,
              backgroundColor: showOtherMembersData ? '#8eafce' : themeContext.colors.baseColor,
              borderColor: showOtherMembersData ? themeContext.colors.baseColor : '#8eafce',
              borderWidth: 1,
            }}
            onPress={() => setShowOtherMembersData(!showOtherMembersData)}
          />
        )}
      </View>
      <WorkingHistoryList
        taskId={props.task.id!}
        me={data.me!}
        organization={organizationData.organization}
        showOtherMembersData={showOtherMembersData}
        closingSettings={dataClosingSetting.closingSettings}
        teamMembers={fetchTeamMembers.data?.teamMembers as Member[] ?? []}
      />
      <AddWorkingHistoryModal
        teamId={props.task.project.team.id!}
        projectId={props.task.project.id!}
        taskId={props.task.id!}
        teamMembers={fetchTeamMembers.data?.teamMembers as Member[] ?? []}
        me={data.me!}
        showModal={showModal}
        onCloseModal={() => setShowModal(false)}
        onRegisterOtherMemberWorkingHistory={() => setShowOtherMembersData(true)}
      />
      <ErrorMessageModal
        showModal={showCompleteTaskStartErrorModal}
        title={'作業開始することができません'}
        message={`完了しているタスク・完了しているプロジェクトは${'\n'}作業開始することが出来ません。`}
        onCloseModal={() => setShowCompleteTaskStartErrorModal(false)}
      />
    </>
  );
};

export default TaskDetailWorkingHistory;
