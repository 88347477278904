import React, { useContext, useState } from 'react';
import styled, { ThemeContext } from 'styled-components/native';
import { TextStyle, ViewStyle, View, Text, StyleProp, ImageSourcePropType } from 'react-native';
import Ripple from 'react-native-material-ripple';
import ColorUtil from '../../../../util/ColorUtil';
import { IStyleTheme, IThemePart } from '../../../theme';
import initials from 'initials';
import ToolTip from 'react-portal-tooltip';

interface IContainerProps {
  color: string;
  size: number;
  existImage: boolean;
  square: boolean;
}

const Container = styled.View<IContainerProps>`
  background-color: ${(props: IContainerProps) => props.color};
  border-color: ${(props: IContainerProps) =>
    props.existImage ? '#CCC' : ColorUtil.darken(props.color, 10)};
  border-width: 1px;
  height: ${(props: IContainerProps) => `${props.size}px`};
  width: ${(props: IContainerProps) => `${props.size}px`};
  border-radius: ${(props: IContainerProps) => `${props.square ? '5px' : '9999px'}`};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

interface IProfileImageProps {
  size: number;
}

const ProfileImage = styled.Image<IProfileImageProps>`
  height: ${(props: IProfileImageProps) => `${props.size}px`};
  width: ${(props: IProfileImageProps) => `${props.size}px`};
`;

const OrganizationIcon = styled.Image`
  height: ${(props: IProfileImageProps) => `${props.size}px`};
  width: ${(props: IProfileImageProps) => `${props.size}px`};
`;

// from https://flatuicolors.com/palette/us
const colors = [
  '#00b894',
  '#00cec9',
  '#0984e3',
  '#6c5ce7',
  '#ffeaa7',
  '#fab1a0',
  '#ff7675',
  '#fd79a8',
];

interface IProps {
  name?: string;
  imageUrl?: string | null;
  size?: number;
  containerStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  onPress?: () => void;
  showToolTip?: boolean;
  toolTipKey?: string;
  square?: boolean;
}

const Avatar: React.FC<IProps> = (props: IProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [isHover, setHover] = useState(false);
  const initial = initials(props.name || '？');
  const fontSize = props.size || 34;

  const charactorCodeSum = (props.name || '？')
    .split('')
    .map((charactor) => charactor.charCodeAt(0))
    .reduce(function (prev, current, i, arr) {
      return prev + current;
    });
  const backgroundColor = props.imageUrl
    ? themeContext.colors.separator
    : colors[charactorCodeSum % colors.length];

  const Inner = props.imageUrl ? (
    <ProfileImage size={fontSize} source={{ uri: `${props.imageUrl}` }} />
  ) : (
    <Text style={[{ color: '#FFFFFF', fontSize: fontSize / 2.6 }, props.textStyle]}>{initial}</Text>
  );

  return props.showToolTip ? (
    <>
      <div
        id={`avatar-${props.toolTipKey}`}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}>
        <Container
          style={props.containerStyle as any}
          color={backgroundColor}
          size={fontSize}
          existImage={!!props.imageUrl}
          square={props.square === true}>
          {props.onPress ? (
            <Ripple
              style={{ cursor: 'pointer' }}
              rippleColor={'#FFFFFF'}
              rippleSize={100}
              rippleDuration={400}
              onPress={props.onPress ? props.onPress : () => {}}>
              {Inner}
            </Ripple>
          ) : (
            Inner
          )}
        </Container>
      </div>
      <ToolTip
        active={isHover}
        position="top"
        arrow="center"
        parent={`#avatar-${props.toolTipKey}`}>
        <Text>{props.name}</Text>
      </ToolTip>
    </>
  ) : (
    <Container
      style={props.containerStyle as any}
      color={backgroundColor}
      size={fontSize}
      existImage={!!props.imageUrl}
      square={props.square === true}>
      {props.onPress ? (
        <Ripple
          style={{ cursor: 'pointer' }}
          rippleColor={'#FFFFFF'}
          rippleSize={100}
          rippleDuration={400}
          onPress={props.onPress ? props.onPress : () => {}}>
          {Inner}
        </Ripple>
      ) : (
        Inner
      )}
    </Container>
  );
};

export default React.memo(Avatar);
