import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../../theme';
import LoginUtil from '../../../../../modules/auth/LoginUtil';
import {
  Member,
  Plan,
  Project,
  ProjectByKeyDocument,
  ProjectByKeyQuery,
  ProjectByKeyQueryVariables,
  ProjectTemplate,
  ProjectTemplateTask,
  TeamProjectsDocument,
  useCreateProjectMutation,
  useOrganizationClientsQuery,
  useOrganizationQuery,
  useProjectTemplatesQuery,
  useProjectTemplateTasksQuery,
  useTeamMembersQuery,
  useTeamQuery,
} from '../../../../../graphql/api/API';
import Form from '../../../../presentational/atoms/form';
import Input, { ListValueMap } from '../../../../presentational/atoms/input';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import { View, Text, TouchableOpacity } from 'react-native';
import Button from '../../../../presentational/atoms/button';
import VirtualizedFlatList from '../../../../presentational/atoms/list2/virtualized-flat-list';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import { useLazyQueryPromise } from '../../../../../graphql/extention/useLazyQueryPromise';
import { ColorPicker } from '../../../../presentational/atoms/color-picker/index.web';
import Mutiselect from '../../../../presentational/atoms/multiselect';
import EditableText from '../../../../presentational/atoms/editable-text';
import TaskInfoElement from '../task-detail/task-info-element';
import Cookies from 'js-cookie';
import StarIcon from '../../../../presentational/molecules/image-icon/star';

const Container = styled.View`
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  align-items: flex-start;
`;

const Item = styled.View`
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  min-width: 200px;
  border-bottom-width: 1px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
`;

const Row = styled.View`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: space-between;
`;

interface IAssigneesSettingsProps {
  teamMembers: Member[];
  onChange: (members: Member[]) => void;
}

const AssigneesSettings = (props: IAssigneesSettingsProps) => {
  return (
    <Form style={{ width: '100%' }}>
      <Mutiselect
        name={'assignees'}
        validate={(item: string) => item !== '' && item.length <= 100}
        initialValues={[]}
        pickerItems={props.teamMembers.map(
          (member) =>
            ({
              value: member!.id!,
              label: member!.name,
              imageUrl: member!.profileImageUrl,
            } as ListValueMap)
        )}
        placeholder={'担当者を選択する'}
        isSearchable={true}
        onBlur={(values) => {
          const members = props.teamMembers!.filter((member) =>
            (values as string[]).includes(member!.id!)
          );
          props.onChange(members);
        }}
        onPressEnter={(values) => {
          const members = props.teamMembers!.filter((member) =>
            (values as string[]).includes(member!.id!)
          );
          props.onChange(members);
        }}
      />
    </Form>
  );
};

interface IProps {
  onComplete: (project: Project) => void;
  onCancel: () => void;
}

const ProjectCreateDialog = (props: IProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const teamId = LoginUtil.getTeamIdFromURl();
  const [projectName, setProjectName] = useState('');
  const [projectKey, setProjectKey] = useState('');
  const [color, setColor] = useState<any>(undefined);
  const [clientId, setClientId] = useState<string | null>(null);
  const [isFavorite, setFavorite] = useState(false);
  const [estimateTimeSec, setEstimateTimeSec] = useState<number | null>(null);
  const [scheduleStartDate, setScheduleStartDate] = useState<moment.Moment | null>(null);
  const [scheduleEndDate, setScheduleEndDate] = useState<moment.Moment | null>(null);
  const [assignees, setAssignees] = useState<Member[]>([]);
  const [projectTemplate, setProjectTemplate] = useState<ProjectTemplate | null>(null);
  const [projectKeyErrorMessage, setProjectKeyErrorMessage] = useState<string | null>(null);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [colorPickerZIndex, setColorPickerZIndex] = useState(3);
  const [showDetail, setShowDetail] = useState(
    Cookies.get(`SHOW_PROJECT_REGISTER_DETAIL`) === 'true'
  );
  const { loading, data } = useOrganizationClientsQuery({
    variables: {
      organizationId: loginUser!.organizationId,
    },
    fetchPolicy: 'network-only',
  });
  const { loading: organizationLoading, data: organizationData } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId,
    },
    fetchPolicy: 'network-only',
  });
  const { loading: projectTemplatesLoading, data: projectTemplatesData } = useProjectTemplatesQuery(
    {
      fetchPolicy: 'network-only',
    }
  );
  const { loading: projectTemplateTasksLoading, data: projectTemplateTasksData } =
    useProjectTemplateTasksQuery({
      variables: {
        projectTemplateId: projectTemplate?.id || '',
      },
      skip: !projectTemplate,
      fetchPolicy: 'network-only',
    });
  const fetchProjectByKey = useLazyQueryPromise<ProjectByKeyQuery, ProjectByKeyQueryVariables>(
    ProjectByKeyDocument
  );
  const fetchTeamMembers = useTeamMembersQuery({
    variables: {
      teamId: teamId!,
    },
    skip: !teamId,
  });
  const [createProject, _] = useCreateProjectMutation({
    variables: {
      teamId: teamId!,
      input: {
        name: projectName,
        key: projectKey || null,
        color: color ?? null,
        clientId: clientId || null,
        estimateTimeSec: estimateTimeSec ?? null,
        scheduledStartDateTime: scheduleStartDate ?? null,
        scheduledEndDateTime: scheduleEndDate ?? null,
        assigneeIds: assignees ? assignees.map((member) => member!.id!) : [],
        projectTemplateId: projectTemplate?.id || null,
        favorite: isFavorite,
      },
    },
    update: (cache, result) => {
      cache.modify({
        fields: {
          teamProjects(existing = []) {
            const newProject = cache.writeQuery({
              data: result.data?.createProject,
              query: TeamProjectsDocument,
            });
            return [...existing, newProject];
          },
        },
      });
    },
  });

  const tasks = useMemo(() => {
    if (projectTemplateTasksLoading || !projectTemplateTasksData?.projectTemplateTasks) {
      return [];
    }
    return projectTemplateTasksData!.projectTemplateTasks!.slice().sort((a, b) => {
      return b!.sortNoInList - a!.sortNoInList; // ソート番号は昇順
    });
  }, [projectTemplateTasksLoading, projectTemplateTasksData?.projectTemplateTasks]);

  const renderItem = useCallback((task: ProjectTemplateTask, index: number) => {
    return (
      <Item>
        <View style={{ flexDirection: 'column' }}>
          <Typography
            variant={TypographyType.Normal}
            ellipsis={true}
            style={{
              fontSize: 13,
              color: themeContext.colors.textColor,
              textAlign: 'left',
              maxWidth: 290,
            }}>
            {task.title}
          </Typography>
        </View>
      </Item>
    );
  }, []);

  if (loading || projectTemplatesLoading || organizationLoading) {
    return <></>;
  }

  return (
    <Container>
      <Form style={{ minWidth: 600 }}>
        <Row>
          <TaskInfoElement title={'プロジェクト名'} style={{ flex: 1 }}>
            <Input
              name={'projectName'}
              placeholder={'プロジェクト名'}
              value={projectName}
              focus={true}
              onChange={(value) => setProjectName(value)}
              onPressEnter={async () => {
                if (projectName.trim().length === 0) {
                  return;
                }
                if (projectKey) {
                  const existProject = await fetchProjectByKey({
                    key: projectKey,
                  });

                  if (existProject.data.projectByKey?.id) {
                    setProjectKeyErrorMessage('すでに同じ管理コードのプロジェクトが存在しています');
                    return;
                  }
                }
                setProjectKeyErrorMessage(null);

                const result = await createProject();
                props.onComplete(result.data!.createProject!);
              }}
              validate={{
                // TODO テンプレートを選択した後に作成ボタンを押すと必須バリデーションに引っかかってしまうので一旦OFFに設定。Button側でタイトル未入力時にはDisableにするようにした。
                // required: {
                //   value: true,
                //   message: '新しいプロジェクトの名前を入力してください',
                // },
                maxLength: {
                  value: 100,
                  message: '100文字以内で入力してください',
                },
              }}
            />
          </TaskInfoElement>
        </Row>
        <Row>
          <TaskInfoElement title={'取引先'} style={{ flex: 1 }}>
            <Input
              name={'client'}
              placeholder={'取引先を選択する'}
              type={'picker'}
              isSearchable={true}
              value={clientId?.toString()}
              pickerItems={[
                {
                  label: 'なし',
                  value: null,
                } as ListValueMap,
              ].concat(
                (data?.organizationClients || []).slice().map((client) => {
                  return {
                    label: `${client!.name}` + (client!.code ? ` (${client!.code})` : ''),
                    value: client!.id,
                  };
                })
              )}
              onChange={(value) => setClientId(value)}
            />
          </TaskInfoElement>
        </Row>
        <Row>
          <TaskInfoElement title={'お気に入り'} style={{ flex: 1 }}>
            <Input
              name={'favorite'}
              type={'picker'}
              value={isFavorite.toString()}
              pickerItems={[
                {
                  label: '登録しない',
                  value: 'false',
                } as ListValueMap,
                {
                  label: '登録する',
                  value: 'true',
                } as ListValueMap,
              ]}
              onChange={(value) => setFavorite(value == 'true')}
            />
          </TaskInfoElement>
        </Row>
        {organizationData!.organization!.plan.code !== Plan.Basic && (
          <Row style={{ zIndex: 2 }}>
            <TaskInfoElement title={`テンプレートを${'\n'}選択する`} style={{ flex: 1 }}>
              <Input
                name={'template'}
                placeholder={'テンプレートを選択する'}
                type={'picker'}
                isSearchable={true}
                pickerItems={[
                  {
                    label: 'なし',
                    value: null,
                  } as ListValueMap,
                ].concat(
                  (projectTemplatesData?.projectTemplates || []).slice().map((template) => {
                    return {
                      label: template!.name,
                      value: template!.id,
                    };
                  })
                )}
                onChange={(value) => {
                  const template = projectTemplatesData!.projectTemplates!.find(
                    (t) => t!.id === value
                  );
                  setProjectTemplate(template || null);

                  if (template) {
                    setProjectName(template.name);
                    setClientId(template.client?.id || null);
                    setEstimateTimeSec(template.estimateTimeSec ?? null);
                  }
                }}
              />
            </TaskInfoElement>
          </Row>
        )}
        {showDetail ? (
          <TouchableOpacity
            style={{ paddingVertical: 10 }}
            onPress={() => {
              setShowDetail(false);
              Cookies.set(`SHOW_PROJECT_REGISTER_DETAIL`, 'false');
            }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontSize: 13,
                color: themeContext.colors.link,
                textAlign: 'center',
                lineHeight: 15,
              }}
              ellipsis={true}>
              {`詳細情報を非表示にする`}
            </Typography>
          </TouchableOpacity>
        ) : (
          <TouchableOpacity
            style={{ paddingVertical: 10 }}
            onPress={() => {
              setShowDetail(true);
              Cookies.set(`SHOW_PROJECT_REGISTER_DETAIL`, 'true');
            }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontSize: 13,
                color: themeContext.colors.link,
                textAlign: 'center',
                lineHeight: 15,
              }}
              ellipsis={true}>
              {`詳細情報を表示する`}
            </Typography>
          </TouchableOpacity>
        )}
        {showDetail && (
          <>
            {organizationData?.organization?.plan.code === Plan.Business && (
              <Row>
                <TaskInfoElement title={'管理コード'} style={{ flex: 1 }}>
                  <Input
                    name={'projectKey'}
                    placeholder={'管理コード'}
                    value={projectKey}
                    onChange={(value) => setProjectKey(value)}
                    validate={{
                      maxLength: {
                        value: 20,
                        message: '20文字以内で入力してください',
                      },
                      pattern: {
                        value: /^[a-zA-Z0-9_-]*$/i,
                        message: '英数字・半角ハイフン・アンダースコアのみ入力可能です。',
                      },
                    }}
                    additionalErrorMessage={projectKeyErrorMessage}
                  />
                </TaskInfoElement>
              </Row>
            )}
            {(organizationData?.organization?.plan.code === Plan.Business ||
              organizationData?.organization?.plan.code === Plan.Enterprise) && (
              <Row style={{ zIndex: 7 }}>
                <TaskInfoElement title={'担当者'} style={{ flex: 1 }}>
                  <AssigneesSettings
                    teamMembers={
                      (fetchTeamMembers?.data?.teamMembers as Member[]) ?? ([] as Member[])
                    }
                    onChange={(newValues) => setAssignees(newValues)}
                  />
                </TaskInfoElement>
              </Row>
            )}
            <Row style={{ zIndex: 6 }}>
              <TaskInfoElement title={'開始予定日'} style={{ flex: 1 }}>
                <EditableText
                  value={scheduleStartDate}
                  type={'date-picker'}
                  style={{
                    flex: 1,
                    minWidth: 600,
                    borderWidth: 1,
                    marginTop: 5,
                    borderColor: themeContext.colors.separator,
                  }}
                  containerStyle={{ paddingHorizontal: 20, paddingVertical: 5 }}
                  onChange={(value) => {
                    setScheduleStartDate(value as moment.Moment);
                  }}
                />
              </TaskInfoElement>
            </Row>
            <Row style={{ zIndex: 5 }}>
              <TaskInfoElement title={'〆切日'} style={{ flex: 1 }}>
                <EditableText
                  value={scheduleEndDate}
                  type={'date-time-picker'}
                  style={{
                    flex: 1,
                    minWidth: 600,
                    borderWidth: 1,
                    marginTop: 5,
                    borderColor: themeContext.colors.separator,
                  }}
                  containerStyle={{ paddingHorizontal: 20, paddingVertical: 5 }}
                  onChange={(value) => {
                    setScheduleEndDate(value as moment.Moment);
                  }}
                />
              </TaskInfoElement>
            </Row>
            <Row style={{ zIndex: 4 }}>
              <TaskInfoElement title={'見積時間'} style={{ flex: 1 }}>
                <EditableText
                  value={estimateTimeSec}
                  type={'time-picker'}
                  style={{
                    flex: 1,
                    minWidth: 600,
                    borderWidth: 1,
                    marginTop: 5,
                    borderColor: themeContext.colors.separator,
                  }}
                  containerStyle={{ paddingHorizontal: 20, paddingVertical: 5 }}
                  onChange={(value) => {
                    setEstimateTimeSec((value as number) || null);
                  }}
                />
              </TaskInfoElement>
            </Row>
            <Row style={{ zIndex: colorPickerZIndex }}>
              <TaskInfoElement title={`カレンダーや${'\n'}グラフ上での色`} style={{ flex: 1 }}>
                <ColorPicker
                  color={color}
                  onChange={(pickedColor) => setColor(pickedColor)}
                  onShowPicker={() => setColorPickerZIndex(10)}
                  onClosePicker={() => setColorPickerZIndex(3)}
                />
              </TaskInfoElement>
            </Row>
          </>
        )}
        <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 20 }}>
          <Button
            text={'作成する'}
            completeText={'作成しました'}
            style={{
              minWidth: 100,
              marginRight: 10,
            }}
            isDisabled={projectName.trim().length === 0}
            onPress={async () => {
              if (projectKey) {
                const existProject = await fetchProjectByKey({
                  key: projectKey,
                });

                if (existProject.data.projectByKey?.id) {
                  setProjectKeyErrorMessage('すでに同じ管理コードのプロジェクトが存在しています');
                  return;
                }
              }
              setProjectKeyErrorMessage(null);

              const result = await createProject();
              props.onComplete(result.data!.createProject!);
            }}
          />
          <Button
            text={'キャンセル'}
            style={{
              minWidth: 100,
              marginRight: 10,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.primary }}
            disableValidate={true}
            onPress={async () => {
              props.onCancel();
            }}
          />
        </View>
      </Form>
      {projectTemplate && (
        <View style={{ marginTop: 20, maxWidth: 700, width: '100%' }}>
          <View
            style={{
              backgroundColor: themeContext.colors.subHeader,
              paddingVertical: 10,
              paddingHorizontal: 10,
            }}>
            <Typography
              variant={TypographyType.SubTitle}
              ellipsis={true}
              style={{ color: '#FFFFFF', fontSize: 14, fontWeight: '600' }}>
              タスク一覧プレビュー
            </Typography>
          </View>
          {tasks.length > 0 ? (
            <VirtualizedFlatList
              style={{
                maxHeight: 250,
                height: 'auto',
                borderWidth: 1,
                borderColor: themeContext.colors.separator,
              }}
              items={tasks}
              renderItem={renderItem as any}
              getKey={(item) => (item as any).id}
              itemHeight={43}
            />
          ) : (
            <Item>
              <View style={{ flexDirection: 'column' }}>
                <Typography
                  variant={TypographyType.Normal}
                  ellipsis={true}
                  style={{
                    fontSize: 13,
                    color: themeContext.colors.textColor,
                    textAlign: 'left',
                    maxWidth: 290,
                  }}>
                  このテンプレートにはタスクがありません
                </Typography>
              </View>
            </Item>
          )}
        </View>
      )}
    </Container>
  );
};

export default ProjectCreateDialog;
