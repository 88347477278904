import React, { useCallback, useContext, useMemo, useState } from 'react';
import styled, { ThemeContext } from 'styled-components/native';
import {
  MentionType,
  NotificationType,
  NotificationV2,
  TaskRemainderType,
  UnreadNotificationCountDocument,
  useNotificationLazyQuery,
  useNotificationsV2Query,
  useUpdateNotificationToReadV2Mutation,
} from '../../../../../../graphql/api/API';
import { LoginUserContext } from '../../../../../../modules/auth/LoginUserContext';
import { IStyleTheme, IThemePart } from '../../../../../theme';
import { useHistory } from 'react-router';
import VirtualizedFlatList from '../../../../../presentational/atoms/list2/virtualized-flat-list';
import { View, Image, Text } from 'react-native';
import { convertFromRaw, EditorState } from 'draft-js';
import Typography, { TypographyType } from '../../../../../presentational/atoms/typography';
import Icon from '../../../../../presentational/atoms/icon';
import Avatar from '../../../../../presentational/atoms/avatar';
import moment from 'moment-timezone';
import noData from '../../../../../../base64Images/no-data/no-data-2';
import Spinner from '../../../../../presentational/atoms/spinner';
import Form from '../../../../../presentational/atoms/form';
import Input from '../../../../../presentational/atoms/input';
import SelectButton from '../../../../../presentational/molecules/select-button';
import UrlUtil from '../../../../../../util/UrlUtil';
import TimeUtil from '../../../../../../util/TimeUtil';
import EmailOpenIcon from '../../../../../presentational/molecules/image-icon/email-open';
import EmailAlertIcon from '../../../../../presentational/molecules/image-icon/email-alert';

const MessageContainer = styled.View`
  display: flex;
  flex-direction: row;
  height: 150px;
  margin: 5px;
  padding: 5px 10px;
  justify-content: flex-start;
  align-items: stretch;
  margin-bottom: 6px;
  transition: all 0.4s;
  z-index: 100;
  border-width: 1px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
  overflow: hidden;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
`;

const FilterElementsContainer = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 0.2rem 0;
  margin: 0 0.2rem;
`;

const FilterElements = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

interface IMessageItemProps {
  message: NotificationV2;
}

const MessageItem = (props: IMessageItemProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  return (
    <MessageContainer>
      <View style={{ flexDirection: 'column' }}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          {props.message.readDateTime !== null ? (
            <EmailOpenIcon size={20} />
          ) : (
            <EmailAlertIcon size={20} />
          )}
          <View
            style={{
              marginLeft: 5,
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Avatar
              containerStyle={{ marginLeft: 3 }}
              size={24}
              name={props.message.mention?.sendMember!.name!}
              imageUrl={props.message.mention?.sendMember.profileImageUrl}
            />
            <Typography
              variant={TypographyType.Normal}
              style={{ fontSize: 14, marginLeft: 10 }}
              ellipsis={false}>
              {props.message.mention!.sendMember!.name!}さんがコメントしました
            </Typography>
          </View>
        </View>
        <View
          style={{
            flex: 1,
            maxHeight: 80,
            height: 80,
            maxWidth: 370,
            width: 370,
            marginLeft: 40,
            padding: 5,
            marginTop: 10,
            overflow: 'hidden',
            borderWidth: 1,
            borderColor: themeContext.colors.separator,
          }}>
          <Form>
            <View>
              <Input
                initialValue={
                  props.message.mention?.message
                }
                name={'notification'}
                type={'rich-text-editor'}
                showToolBarAlways={false}
                multiline={true}
                readonly={true}
              />
            </View>
            <View
              style={
                {
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  cursor: 'pointer',
                } as any
              }
            />
          </Form>
        </View>
        <View style={{ flexDirection: 'column' }}>
          <Typography
            variant={TypographyType.Description}
            style={{ fontSize: 11, lineHeight: 13, marginTop: 5 }}>
            {moment(props.message.sendDateTime).format('YYYY/MM/DD HH:mm')}
          </Typography>
        </View>
      </View>
    </MessageContainer>
  );
};

interface IProjectAssignedItemProps {
  message: NotificationV2;
}

const ProjectAssignedItem = (props: IProjectAssignedItemProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  return (
    <MessageContainer>
      <View style={{ flexDirection: 'column', height: '100%' }}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <View style={{ marginLeft: 3, marginTop: 5 }}>
            {props.message.readDateTime !== null ? (
              <EmailOpenIcon size={20} />
            ) : (
              <EmailAlertIcon size={20} />
            )}
          </View>
          <View style={{ flexDirection: 'column' }}>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Typography
                variant={TypographyType.Description}
                style={{ fontSize: 10, lineHeight: 15, marginLeft: 10 }}
                ellipsis={true}>
                プロジェクト「
              </Typography>
              <Typography
                variant={TypographyType.Description}
                style={{ fontSize: 13, lineHeight: 15, marginLeft: 2, maxWidth: 300 }}
                ellipsis={true}>
                {props.message.projectAssignNotification!.projectName}
              </Typography>
              <Typography
                variant={TypographyType.Description}
                style={{ fontSize: 10, lineHeight: 15, marginLeft: 2 }}
                ellipsis={true}>
                」
              </Typography>
            </View>
          </View>
        </View>
        <View
          style={{
            flex: 1,
            alignItems: 'center',
            flexDirection: 'row',
          }}>
          <Avatar
            containerStyle={{ marginLeft: 3 }}
            size={28}
            name={props.message.projectAssignNotification!.sendMember!.name!}
            imageUrl={props.message.projectAssignNotification!.sendMember.profileImageUrl}
          />
          <Typography
            variant={TypographyType.Normal}
            style={{
              fontSize: 14,
              marginLeft: 10,
              width: '100%',
            }}
            ellipsis={true}>
            {props.message.projectAssignNotification!.sendMember!.name!}
            {`さんから「${
              props.message.projectAssignNotification!.projectName
            }」が割り当てられました`}
          </Typography>
        </View>
        <Typography
          variant={TypographyType.Description}
          style={{ fontSize: 11, lineHeight: 13, marginTop: 5, marginLeft: 3 }}>
          {moment(props.message.sendDateTime).format('YYYY/MM/DD HH:mm')}
        </Typography>
      </View>
    </MessageContainer>
  );
};

interface ITaskAssignedItemProps {
  message: NotificationV2;
}

const TaskAssignedItem = (props: ITaskAssignedItemProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  return (
    <MessageContainer>
      <View style={{ flexDirection: 'column', height: '100%' }}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <View style={{ marginLeft: 3, marginTop: 5 }}>
            {props.message.readDateTime !== null ? (
              <EmailOpenIcon size={20} />
            ) : (
              <EmailAlertIcon size={20} />
            )}
          </View>
          <View style={{ flexDirection: 'column' }}>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Typography
                variant={TypographyType.Description}
                style={{ fontSize: 10, lineHeight: 15, marginLeft: 10 }}
                ellipsis={true}>
                プロジェクト「
              </Typography>
              <Typography
                variant={TypographyType.Description}
                style={{ fontSize: 13, lineHeight: 15, marginLeft: 2, maxWidth: 300 }}
                ellipsis={true}>
                {props.message.taskAssignNotification?.taskTitle}
              </Typography>
              <Typography
                variant={TypographyType.Description}
                style={{ fontSize: 10, lineHeight: 15, marginLeft: 2 }}
                ellipsis={true}>
                」
              </Typography>
            </View>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Typography
                variant={TypographyType.Description}
                style={{ fontSize: 10, lineHeight: 15, marginLeft: 10 }}
                ellipsis={true}>
                タスク「
              </Typography>
              <Typography
                variant={TypographyType.Description}
                style={{ fontSize: 13, lineHeight: 15, marginLeft: 2, maxWidth: 300 }}
                ellipsis={true}>
                {props.message.taskAssignNotification!.taskTitle}
              </Typography>
              <Typography
                variant={TypographyType.Description}
                style={{ fontSize: 10, lineHeight: 15, marginLeft: 2 }}
                ellipsis={true}>
                」
              </Typography>
            </View>
          </View>
        </View>
        <View
          style={{
            flex: 1,
            alignItems: 'center',
            flexDirection: 'row',
          }}>
          <Avatar
            containerStyle={{ marginLeft: 3 }}
            size={28}
            name={props.message.taskAssignNotification!.sendMember!.name!}
            imageUrl={props.message.taskAssignNotification!.sendMember.profileImageUrl}
          />
          <Typography
            variant={TypographyType.Normal}
            style={{
              fontSize: 14,
              marginLeft: 10,
              width: '100%',
            }}
            ellipsis={true}>
            {props.message.taskAssignNotification!.sendMember!.name!}
            {`さんから「${props.message.taskAssignNotification!.taskTitle}」が割り当てられました`}
          </Typography>
        </View>
        <Typography
          variant={TypographyType.Description}
          style={{ fontSize: 11, lineHeight: 13, marginTop: 5, marginLeft: 3 }}>
          {moment(props.message.sendDateTime).format('YYYY/MM/DD HH:mm')}
        </Typography>
      </View>
    </MessageContainer>
  );
};

interface ITaskRemainderItemProps {
  message: NotificationV2;
}

const TaskRemainderItem = (props: ITaskRemainderItemProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  return (
    <MessageContainer>
      <View style={{ flexDirection: 'column', height: '100%' }}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <View style={{ marginLeft: 3, marginTop: 5 }}>
            {props.message.readDateTime !== null ? (
              <EmailOpenIcon size={20} />
            ) : (
              <EmailAlertIcon size={20} />
            )}
          </View>
          <View style={{ flexDirection: 'column' }}>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Typography
                variant={TypographyType.Description}
                style={{ fontSize: 10, lineHeight: 15, marginLeft: 10 }}
                ellipsis={true}>
                プロジェクト「
              </Typography>
              <Typography
                variant={TypographyType.Description}
                style={{ fontSize: 13, lineHeight: 15, marginLeft: 2, maxWidth: 300 }}
                ellipsis={true}>
                {props.message.taskRemainderNotification?.projectName}
              </Typography>
              <Typography
                variant={TypographyType.Description}
                style={{ fontSize: 10, lineHeight: 15, marginLeft: 2 }}
                ellipsis={true}>
                」
              </Typography>
            </View>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Typography
                variant={TypographyType.Description}
                style={{ fontSize: 10, lineHeight: 15, marginLeft: 10 }}
                ellipsis={true}>
                タスク「
              </Typography>
              <Typography
                variant={TypographyType.Description}
                style={{ fontSize: 13, lineHeight: 15, marginLeft: 2, maxWidth: 300 }}
                ellipsis={true}>
                {props.message.taskRemainderNotification!.taskTitle}
              </Typography>
              <Typography
                variant={TypographyType.Description}
                style={{ fontSize: 10, lineHeight: 15, marginLeft: 2 }}
                ellipsis={true}>
                」
              </Typography>
            </View>
          </View>
        </View>
        <View
          style={{
            flex: 1,
            alignItems: 'center',
            flexDirection: 'row',
          }}>
          {props.message.taskRemainderNotification!.taskRemainderType ===
          TaskRemainderType.DeadlineNear ? (
            <View style={{ flexDirection: 'column' }}>
              <Typography
                variant={TypographyType.Normal}
                style={{
                  fontSize: 14,
                  marginLeft: 10,
                  width: '100%',
                }}
                ellipsis={true}>
                {`「${
                  props.message.taskRemainderNotification!.taskTitle
                }」の〆切が近づいて来ています。`}
              </Typography>
              <Typography
                variant={TypographyType.Normal}
                style={{
                  fontSize: 14,
                  marginLeft: 10,
                  width: '100%',
                }}
                ellipsis={true}>
                {`〆切日:${moment(
                  props.message.taskRemainderNotification!.scheduledEndDateTime
                ).format('lll')}`}
              </Typography>
            </View>
          ) : (
            <View style={{ flexDirection: 'column' }}>
              <Typography
                variant={TypographyType.Normal}
                style={{
                  fontSize: 14,
                  marginLeft: 10,
                  width: '100%',
                }}
                ellipsis={true}>
                {`「${props.message.taskRemainderNotification!.taskTitle}」の〆切が過ぎました。`}
              </Typography>
              <Typography
                variant={TypographyType.Normal}
                style={{
                  fontSize: 14,
                  marginLeft: 10,
                  width: '100%',
                }}
                ellipsis={true}>
                {`〆切日:${moment(
                  props.message.taskRemainderNotification!.scheduledEndDateTime
                ).format('lll')}`}
              </Typography>
            </View>
          )}
        </View>
        <Typography
          variant={TypographyType.Description}
          style={{ fontSize: 11, lineHeight: 13, marginTop: 5, marginLeft: 3 }}>
          {moment(props.message.sendDateTime).format('YYYY/MM/DD HH:mm')}
        </Typography>
      </View>
    </MessageContainer>
  );
};

const RightSideBarMentionList = () => {
  const pageSize = 20;
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, __] = useContext(LoginUserContext);
  const [filter, setFilter] = useState<Array<'read' | 'unread'>>(['unread']);
  const history = useHistory();
  const { loading, data, fetchMore } = useNotificationsV2Query({
    variables: {
      offset: 0,
      limit: pageSize,
      read: filter.includes('read'),
      unread: filter.includes('unread')
    },
    fetchPolicy: 'network-only',
  });
  const [updateNotificationToRead, _] = useUpdateNotificationToReadV2Mutation({
    refetchQueries: [
      {
        query: UnreadNotificationCountDocument,
      },
    ],
  });
  const [fetchNotification] = useNotificationLazyQuery();

  const onPress = useCallback(
    (message: any) => {
      const data = message as NotificationV2;
      if (data.notificationType === NotificationType.Mention) {
        if (data.mention?.mentionType === MentionType.TaskDescription) {
          // 対象のタスク・コメントへ移動する
          const taskId = (data.mention!.taskId)!;
          history.push(
            UrlUtil.createTaskDetailUrlById(
              taskId
            )
          );
        } else if (data.mention?.mentionType === MentionType.Comment) {
            // 対象のタスク・コメントへ移動する
            const taskId = (data.mention!.taskId)!;
            history.push(
              UrlUtil.createTaskDetailUrlById(
                taskId,
                `comment/${data.mention!.commentId!}/?forcusComment=true`
              )
            );
        } else {
          history.push(UrlUtil.moveProjectDetailUrlById(data.mention!.projectId!));
        }
      }
      if (data.notificationType === NotificationType.ProjectAssigned) {
        // 対象のプロジェクトへ移動する
        history.push(UrlUtil.moveProjectDetailUrlById(data.projectAssignNotification!.projectId));
      }
      if (data.notificationType === NotificationType.TaskAssigned) {
        // 対象のタスク・コメントへ移動する
        history.push(UrlUtil.createTaskDetailUrlById(data.taskAssignNotification!.taskId!));
      }
      if (
        data.notificationType === NotificationType.TaskDeadlineNear ||
        data.notificationType === NotificationType.TaskDeadlinePassed
      ) {
        // 対象のタスク・コメントへ移動する
        history.push(UrlUtil.createTaskDetailUrlById(data.taskRemainderNotification!.taskId));
      }

      if (!data.readDateTime) {
        updateNotificationToRead({
          variables: {
            id: data.id,
          },
          refetchQueries: [
            {
              query: UnreadNotificationCountDocument,
            },
          ],
        });
        fetchNotification({
          variables: {
            id: data.id!,
          },
        });
      }
    },
    [updateNotificationToRead, fetchNotification, history]
  );

  if (loading) {
    return <></>;
  }

  return (
    <>
      <Spinner loading={loading} />
      <FilterElementsContainer>
        <FilterElements>
          <Text
            style={{
              fontSize: 7,
              color: themeContext.colors.description,
              textAlign: 'center',
              marginRight: 10,
            }}>
            絞り込み
          </Text>
          <SelectButton
            multiSelect={true}
            values={filter}
            contents={[
              {
                key: 'unread',
                content: '未読',
              },
              {
                key: 'read',
                content: '既読',
              },
            ]}
            onChange={(value) => {
              setFilter(value as any);
            }}
          />
        </FilterElements>
      </FilterElementsContainer>
      <VirtualizedFlatList
        items={data?.notificationsV2 ?? []}
        style={{
          height: 'calc(100vh - 57px - 80px)',
        }}
        renderItem={(item, index) => {
          if ((item as NotificationV2).notificationType === NotificationType.Mention) {
            return <MessageItem message={item as NotificationV2} />;
          }
          if ((item as NotificationV2).notificationType === NotificationType.ProjectAssigned) {
            return <ProjectAssignedItem message={item as NotificationV2} />;
          }
          if ((item as NotificationV2).notificationType === NotificationType.TaskAssigned) {
            return <TaskAssignedItem message={item as NotificationV2} />;
          }
          return <TaskRemainderItem message={item as NotificationV2} />;
        }}
        getKey={(message) => (message as any).id}
        itemHeight={160}
        onPress={onPress}
        listEmptyElment={
          <View
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              height: 'calc(100vh - 57px - 80px)',
            }}>
            <Image
              source={{ uri: noData }}
              resizeMode="contain"
              style={{
                width: 350,
                height: 200,
                opacity: 0.7,
              }}
            />
            <Typography
              variant={TypographyType.Normal}
              style={{ color: themeContext.colors.description, textAlign: 'center' }}>
              {`あなたへの通知はまだありません`}
            </Typography>
          </View>
        }
        onEndReached={() => {
          if ((data?.notificationsV2?.length ?? 0) < pageSize) {
            return;
          }
          fetchMore({
            variables: {
              offset: data!.notificationsV2!.length,
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prev;
              return Object.assign({}, prev, {
                notificationsV2: [
                  ...(prev.notificationsV2 || []),
                  ...(fetchMoreResult.notificationsV2 || []),
                ],
              });
            },
          });
        }}
      />
    </>
  );
};

export default React.memo(RightSideBarMentionList);
