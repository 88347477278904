import React, { useContext, useState } from 'react';
import styled, { ThemeContext } from 'styled-components/native';
import { Image } from 'react-native';
import { useScreenDimensions } from 'use-dimensions';
import { Redirect, useHistory, useLocation } from 'react-router';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import Button from '../../../../presentational/atoms/button';
import Form from '../../../../presentational/atoms/form';
import Input from '../../../../presentational/atoms/input';
import login from '../../../../../base64Images/login/login';
import logoLargeBlack from '../../../../../base64Images/logo/logo-large-black';
import { Plan } from '../../../../../graphql/api/API';

const Header = styled.View`
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  height: 100px;
  padding-left: 20px;
  padding-top: 20px;
`;

const Container = styled.View`
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  height: calc(100vh - 100px);
`;

const LeftContainer = styled.View`
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 0 50px;
`;

const RightContainer = styled.View`
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 0 50px;
`;

const RightContainerInner = styled.View`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const SignupLicenceCount = () => {
  const { height, width } = useScreenDimensions();
  const history = useHistory();
  const { state } = useLocation();
  const [licenceCount, setLicenceCount] = useState(1);
  if (state === null || state === undefined) {
    return <Redirect to={'/signup'} />;
  }

  return (
    <>
      <Header>
        <Image
          source={{ uri: logoLargeBlack }}
          resizeMode="contain"
          style={{
            width: 300,
            height: 100,
          }}
        />
      </Header>
      <Container>
        <LeftContainer>
          <Image
            source={{ uri: login }}
            resizeMode="contain"
            style={{
              width: Math.max(width / 4, 500),
              height: Math.max(width / 4, 500),
            }}
          />
        </LeftContainer>
        <RightContainer>
          <RightContainerInner>
            <Typography variant={TypographyType.Title} style={{ marginVertical: 10 }}>
              {`ライセンス数を入力してください`}
            </Typography>
            <Typography variant={TypographyType.Normal} style={{ marginBottom: 50 }}>
              {`ライセンスの数だけ、メンバーを追加出来ます。${'\n'}ライセンス数はあとから変更できます。${'\n'}トライアル期間終了後は、ライセンス数単位でのご利用料となります。`}
            </Typography>
            <Form style={{ marginBottom: 30 }}>
              <Input
                name={'licenceCount'}
                initialValue={1}
                ghostSuffix={'名分'}
                ghostSuffixStyle={{ marginLeft: 10, minWidth: 35 }}
                validate={{
                  required: {
                    value: true,
                    message: 'ライセンス数を入力してください',
                  },
                  min: {
                    value: 1,
                    message: '1以上で入力してください',
                  },
                  max: {
                    value: 1000,
                    message: '1000以下の数字を入力してください',
                  },
                  pattern: {
                    value: /^[0-9]+$/,
                    message: '使用中のライセンス数以上の数字を入力してください。',
                  },
                }}
                onChange={(value: string) =>
                  setLicenceCount(isNaN(Number(value)) ? 1 : Number(value))
                }
              />
              <Button
                text="次へ"
                style={{ marginTop: 20, minWidth: 300 }}
                onPress={async () => {
                  history.push({
                    pathname: '/signup/confirm-beta',
                    state: Object.assign(state, {
                      licenceCount:
                        (state as any).plan === Plan.Basic ||
                        (state as any).plan === Plan.Professional
                          ? 1
                          : licenceCount,
                    }),
                  });
                }}
              />
            </Form>
          </RightContainerInner>
        </RightContainer>
      </Container>
    </>
  );
};

export default React.memo(SignupLicenceCount);
