import React from 'react';
import { Image, Platform } from 'react-native';
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';
import playImage from '../../../../../base64Images/play';

interface IPlayIconProps {
  size: number;
  onPress?: () => void;
  disabled?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  children?: React.ReactNode;
}

const PlayIcon = (props: IPlayIconProps) => {
  return (
    <TouchableOpacity
      disabled={props.disabled}
      onPress={props.onPress}
      style={[
        { flexDirection: 'row', justifyContent: 'center', alignItems: 'center' },
        props.containerStyle,
      ]}>
      <Image
        source={{ uri: playImage }}
        resizeMode="contain"
        style={[
          {
            height: props.size,
            width: props.size + 2,
            shadowOffset: {
              width: 1,
              height: 1,
            },
            shadowOpacity: 0.1,
          } as any,
          Platform.OS === 'web' ? { elevation: 2, cursor: 'pointer' } : {},
        ]}
      />
      {props.children}
    </TouchableOpacity>
  );
};

export default PlayIcon;
