import React, { useContext, useState } from 'react';
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../../theme';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import { StyleProp, TextStyle, ViewStyle } from 'react-native';
import { useHistory, useLocation } from 'react-router';
import UrlUtil from '../../../../../util/UrlUtil';

const MenuContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

interface IMenuItemProps extends IStyleTheme {
  isActive: boolean;
}

const MenuItem = styled.TouchableOpacity<IMenuItemProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom-width: ${(props: IMenuItemProps) => (props.isActive ? '2px' : '0')};
  border-color: #ffbebe;
  margin-right: 24px;
`;

export interface HorizontalMenuItem {
  title: string;
  path: string;
  linkPath?: string;
  rightElement?: React.ReactElement;
  onPress?: () => void;
}

interface IHorizontalMenuProps {
  menus: HorizontalMenuItem[];
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
}

const HorizontalMenu = (props: IHorizontalMenuProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const { pathname, search } = useLocation();
  const history = useHistory();
  return (
    <MenuContainer style={props.style as any}>
      {props.menus.map((menu, i) => {
        return (
          <MenuItem
            isActive={new RegExp(`^${menu.path}.*`).test(pathname + search)}
            onPress={() => {
              if (menu.onPress) {
                menu.onPress();
              }
              if (menu.linkPath && menu.linkPath.indexOf('?') !== -1) {
                history.push(menu.linkPath);
              } else {
                history.push(UrlUtil.createPathWithCurrentSearch(menu.linkPath ?? menu.path));
              }
            }}
            key={i}>
            <Typography
              variant={TypographyType.Normal}
              style={
                [{ fontSize: 12, color: themeContext.colors.textColor }, props.textStyle] as any
              }>
              {menu.title}
            </Typography>
            {menu.rightElement && menu.rightElement}
          </MenuItem>
        );
      })}
    </MenuContainer>
  );
};

export default HorizontalMenu;
