import React, { useContext, useEffect, useState } from 'react';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import { View, Text } from 'react-native';
import { IStyleTheme, IThemePart } from '../../../../../../theme';
import HorizontalMenu from '../../../../organisms/horizontal-menu';
import { LoginUserContext } from '../../../../../../../modules/auth/LoginUserContext';
import { Redirect, Route, Switch, useHistory } from 'react-router';
import moment from 'moment-timezone';
import { ReportType, useOrganizationQuery } from '../../../../../../../graphql/api/API';
import TimeUtil from '../../../../../../../util/TimeUtil';
import EditableText, { IDateRange } from '../../../../../../presentational/atoms/editable-text';
import Spinner from '../../../../../../presentational/atoms/spinner';
import LeftIcon from '../../../../../../presentational/molecules/image-icon/left';
import RightIcon from '../../../../../../presentational/molecules/image-icon/right';
import ReloadIcon from '../../../../../../presentational/molecules/image-icon/reload';
import SelectButton from '../../../../../../presentational/molecules/select-button';
import { MemberList } from '../../my-analytics/graph/graph-member';
import { TeamList } from '../../my-analytics/graph/graph-team';
import { ClientList } from '../../my-analytics/graph/graph-client';
import { ProjectList } from '../../my-analytics/graph/graph-project';
import { TaskList } from '../../my-analytics/graph/graph-task';
import GraphSearchForm from '../../my-analytics/graph/graph-seach-form';
import LoginUtil from '../../../../../../../modules/auth/LoginUtil';
import GraphTotalWorkingTime from '../../my-analytics/graph/graph-total-working-time';
import OrganizationUtil from '../../../../../../../util/OrganizationUtil';
import * as Cookies from 'js-cookie';

const Container = styled.View`
  display: flex;
  flex: 1;
  height: calc(100vh - 57px);
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
  z-index: 0;
`;

const Header = styled.View`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
  padding: 0 1rem;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.4s;
  z-index: 2;
`;

interface IDateRangeHeaderProps {
  startDateTime: moment.Moment;
  endDateTime: moment.Moment;
  setStartDateTime: (value: moment.Moment) => void;
  setEndDateTime: (value: moment.Moment) => void;
}

const DateRangeHeader = (props: IDateRangeHeaderProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [startDateTime, setStartDateTime] = useState(props.startDateTime);
  const [endDateTime, setEndDateTime] = useState(props.endDateTime);

  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
      <LeftIcon
        size={24}
        onPress={() => {
          const dateRange = moment(endDateTime).diff(moment(startDateTime), 'days') + 1;
          const newStartDateTime = moment(startDateTime).add(-1 * dateRange, 'days');
          const newEndDateTime = moment(endDateTime).add(-1 * dateRange, 'days');
          setStartDateTime(newStartDateTime);
          setEndDateTime(newEndDateTime);
          props.setStartDateTime(newStartDateTime);
          props.setEndDateTime(newEndDateTime);
        }}
      />
      <EditableText
        value={{
          start: startDateTime,
          end: endDateTime,
        }}
        type={'date-range-picker'}
        disableClear={true}
        containerStyle={{ paddingHorizontal: 10 }}
        textStyle={{ minWidth: 180, fontSize: 14 }}
        onChange={(value) => {
          setStartDateTime((value as IDateRange)!.start);
          setEndDateTime((value as IDateRange)!.end);
          props.setStartDateTime((value as IDateRange)!.start);
          props.setEndDateTime((value as IDateRange)!.end);
        }}
        onBlur={(value) => {
          setStartDateTime((value as IDateRange)!.start);
          setEndDateTime((value as IDateRange)!.end);
          props.setStartDateTime((value as IDateRange)!.start);
          props.setEndDateTime((value as IDateRange)!.end);
        }}
      />
      <RightIcon
        size={24}
        onPress={() => {
          const dateRange = moment(endDateTime).diff(moment(startDateTime), 'days') + 1;
          const newStartDateTime = moment(startDateTime).add(dateRange, 'days');
          const newEndDateTime = moment(endDateTime).add(dateRange, 'days');
          setStartDateTime(newStartDateTime);
          setEndDateTime(newEndDateTime);
          props.setStartDateTime(newStartDateTime);
          props.setEndDateTime(newEndDateTime);
        }}
      />
    </View>
  );
};

const parseAsNumber = (value: string | null | undefined) => {
  if(value === null || value === undefined){
    return null;
  }
  if(Number.isNaN(value)){
    return null;
  }
  return Number(value);
}

const AppTeamAnalyticsGraph = () => {
  const ORG_ANALYTICS_DISPLAY_DATE_RANGE_BEFORE = 'ORG_ANALYTICS_DISPLAY_DATE_RANGE_BEFORE';
  const ORG_ANALYTICS_DISPLAY_DATE_RANGE_AFTER = 'ORG_ANALYTICS_DISPLAY_DATE_RANGE_AFTER';

  const themeContext: IThemePart = useContext(ThemeContext);
  const teamId = LoginUtil.getTeamIdFromURl();
  const [loginUser, _] = useContext(LoginUserContext);
  const history = useHistory();
  const [reportType, setReportType] = useState(ReportType.WorkingHistory);
  const [analysysType, setAnalysysType] = useState<
    'task' | 'project' | 'client' | 'team' | 'member'
  >('member');
  const [graphType, setGraphType] = useState<'date' | 'bar' | 'sunburst'>('bar');
  const [startDateTime, setStartDateTime] = useState(moment().startOf('day').add(parseAsNumber(Cookies.get(ORG_ANALYTICS_DISPLAY_DATE_RANGE_BEFORE)) ?? -3, 'days').startOf('day'));
  const [endDateTime, setEndDateTime] = useState(
    moment()
      .startOf('day')
      .add(parseAsNumber(Cookies.get(ORG_ANALYTICS_DISPLAY_DATE_RANGE_AFTER)) ?? +3, 'days')
      .endOf('day')
  );
  const [dummyLoading, setDummyLoading] = useState(false);

  const [teamIds, setTeamIds] = useState<string[]>([]);
  const [projectIds, setProjectIds] = useState<string[]>([]);
  const [clientIds, setClientIds] = useState<string[]>([]);
  const [memberIds, setMemberIds] = useState<string[]>([]);
  const [isLoadData, setLoadData] = useState<boolean>(false);

  const fetchOrganization = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId,
    },
  });

  useEffect(() => {
    Cookies.set(ORG_ANALYTICS_DISPLAY_DATE_RANGE_BEFORE, startDateTime.diff(moment(), 'day').toString())
    Cookies.set(ORG_ANALYTICS_DISPLAY_DATE_RANGE_AFTER, endDateTime.diff(moment(), 'day').toString())
  }, [startDateTime, endDateTime])

  if (!fetchOrganization?.data?.organization) {
    return <></>;
  }

  return (
    <Container>
      <Header>
        <View style={{ flexDirection: 'row', paddingVertical: 10 }}>
          <DateRangeHeader
            startDateTime={startDateTime}
            endDateTime={endDateTime}
            setStartDateTime={setStartDateTime}
            setEndDateTime={setEndDateTime}
          />
          <View style={{ alignItems: 'flex-start' }}>
            <SelectButton
              containerStyle={{ marginLeft: 10 }}
              values={[reportType]}
              contents={[
                {
                  key: ReportType.WorkingHistory,
                  content: '作業履歴',
                },
                {
                  key: ReportType.WorkingSchedule,
                  content: '作業予定',
                },
              ]}
              onChange={(value) => {
                setLoadData(false);
                const valueReportType = (value?.[0] as ReportType) ?? ReportType.WorkingHistory;
                setReportType(valueReportType);
                history.push(
                  `/app/${loginUser!.organizationId}/${teamId}/team-analytics/graph/${
                    valueReportType === ReportType.WorkingHistory
                      ? 'time-entries'
                      : 'work-schedules'
                  }/graph-${analysysType}/${graphType}/`
                );
              }}
            />
            <SelectButton
              containerStyle={{ marginLeft: 10, marginTop: 5 }}
              values={[analysysType]}
              contents={[
                {
                  key: 'member',
                  content: 'メンバー単位',
                },
                {
                  key: 'task',
                  content: 'タスク単位',
                },
                {
                  key: 'project',
                  content: 'プロジェクト単位',
                },
                {
                  key: 'client',
                  content: '取引先単位',
                },
              ]}
              onChange={(value) => {
                setLoadData(false);
                const valueGraphType = value?.[0] as any;
                setAnalysysType(valueGraphType ?? 'task');
                history.push(
                  `/app/${loginUser!.organizationId}/${teamId}/team-analytics/graph/${
                    reportType === ReportType.WorkingHistory ? 'time-entries' : 'work-schedules'
                  }/graph-${valueGraphType ?? 'task'}/${graphType}/`
                );
              }}
            />
          </View>
        </View>
        <View>
          <ReloadIcon
            size={24}
            onPress={async () => {
              setLoadData(false);
              setDummyLoading(true);
              // await refetch();
              await TimeUtil.sleep(1000); // 普通にrefetchすると早すぎてリロードされたかどうかがユーザーに分からないため、あえて１秒ロード中状態を擬似してあげて、ロードしているんだよって伝わるようにしている。
              setDummyLoading(false);
            }}
          />
        </View>
      </Header>
      <View style={{ flexDirection: 'row', zIndex: 1, justifyContent: 'space-between' }}>
        <View style={{ flexDirection: 'row' }}>
          <HorizontalMenu
            style={{ marginLeft: 90, paddingVertical: 10 }}
            menus={[
              {
                title: '日単位グラフ',
                path: `/app/${loginUser!.organizationId}/${teamId}/team-analytics/graph/.*/date/`,
                linkPath: `/app/${loginUser!.organizationId}/${teamId}/team-analytics/graph/${
                  reportType === ReportType.WorkingHistory ? 'time-entries' : 'work-schedules'
                }/graph-${analysysType}/date/`,
                onPress: () => setGraphType('date'),
              },
              {
                title: '棒グラフ',
                path: `/app/${loginUser!.organizationId}/${teamId}/team-analytics/graph/.*/bar/`,
                linkPath: `/app/${loginUser!.organizationId}/${teamId}/team-analytics/graph/${
                  reportType === ReportType.WorkingHistory ? 'time-entries' : 'work-schedules'
                }/graph-${analysysType}/bar/`,
                onPress: () => setGraphType('bar'),
              },
              {
                title: '円グラフ',
                path: `/app/${
                  loginUser!.organizationId
                }/${teamId}/team-analytics/graph/.*/sunburst/`,
                linkPath: `/app/${loginUser!.organizationId}/${teamId}/team-analytics/graph/${
                  reportType === ReportType.WorkingHistory ? 'time-entries' : 'work-schedules'
                }/graph-${analysysType}/sunburst/`,
                onPress: () => setGraphType('sunburst'),
              },
            ]}
          />
          <GraphSearchForm
            reportType={reportType}
            organization={fetchOrganization.data!.organization!}
            teamId={teamId}
            memberId={null}
            start={startDateTime}
            end={endDateTime}
            teamIds={teamIds}
            setTeamIds={setTeamIds}
            projectIds={projectIds}
            setProjectIds={setProjectIds}
            clientIds={clientIds}
            setClientIds={setClientIds}
            memberIds={memberIds}
            setMemberIds={setMemberIds}
            showTeam={false}
            isLoadData={isLoadData}
            setLoadData={setLoadData}
          />
        </View>
        {OrganizationUtil.isPersonalPlan(fetchOrganization.data!.organization!) && (
          <GraphTotalWorkingTime
            reportType={reportType}
            organization={fetchOrganization.data!.organization!}
            teamId={teamId}
            memberId={null}
            start={startDateTime}
            end={endDateTime}
            teamIds={teamIds}
            setTeamIds={setTeamIds}
            projectIds={projectIds}
            setProjectIds={setProjectIds}
            clientIds={clientIds}
            setClientIds={setClientIds}
            memberIds={memberIds}
            setMemberIds={setMemberIds}
          />
        )}
      </View>
      <Spinner loading={dummyLoading}>
        <View style={{ paddingHorizontal: 20, marginBottom: 30 }}>
          <Switch>
            <Route
              path={`/app/:organiationId/${teamId}/team-analytics/graph/:reportType/graph-task/`}
              render={(routeProps) => (
                <TaskList
                  reportType={reportType}
                  organization={fetchOrganization.data!.organization!}
                  startDateTime={startDateTime}
                  endDateTime={endDateTime}
                  teamId={teamId}
                  teamIds={teamIds}
                  projectIds={projectIds}
                  clientIds={clientIds}
                  memberIds={memberIds}
                  graphPath={`/app/:organiationId/${teamId}/team-analytics/graph/:reportType`}
                  graphType={graphType}
                  url={`/app/${loginUser?.organizationId}/${teamId}/team-analytics/graph/:reportType/graph-task/${graphType}/`}
                  setLoadData={setLoadData}
                />
              )}
            />
            <Route
              path={`/app/:organiationId/${teamId}/team-analytics/graph/:reportType/graph-project/`}
              render={(routeProps) => (
                <ProjectList
                  reportType={reportType}
                  organization={fetchOrganization.data!.organization!}
                  startDateTime={startDateTime}
                  endDateTime={endDateTime}
                  teamId={teamId}
                  teamIds={teamIds}
                  projectIds={projectIds}
                  clientIds={clientIds}
                  memberIds={memberIds}
                  graphPath={`/app/:organiationId/${teamId}/team-analytics/graph/:reportType`}
                  graphType={graphType}
                  url={`/app/${loginUser?.organizationId}/${teamId}/team-analytics/graph/:reportType/graph-project/${graphType}/`}
                  setLoadData={setLoadData}
                />
              )}
            />
            <Route
              path={`/app/:organiationId/${teamId}/team-analytics/graph/:reportType/graph-client/`}
              render={(routeProps) => (
                <ClientList
                  reportType={reportType}
                  organization={fetchOrganization.data!.organization!}
                  startDateTime={startDateTime}
                  endDateTime={endDateTime}
                  teamId={teamId}
                  teamIds={teamIds}
                  projectIds={projectIds}
                  clientIds={clientIds}
                  memberIds={memberIds}
                  graphPath={`/app/:organiationId/${teamId}/team-analytics/graph/:reportType`}
                  graphType={graphType}
                  url={`/app/${loginUser?.organizationId}/${teamId}/team-analytics/graph/:reportType/graph-client/${graphType}/`}
                  setLoadData={setLoadData}
                />
              )}
            />
            <Route
              path={`/app/:organiationId/${teamId}/team-analytics/graph/:reportType/graph-team/`}
              render={(routeProps) => (
                <TeamList
                  reportType={reportType}
                  organization={fetchOrganization.data!.organization!}
                  startDateTime={startDateTime}
                  endDateTime={endDateTime}
                  teamId={teamId}
                  teamIds={teamIds}
                  projectIds={projectIds}
                  clientIds={clientIds}
                  memberIds={memberIds}
                  graphPath={`/app/:organiationId/${teamId}/team-analytics/graph/:reportType`}
                  graphType={graphType}
                  url={`/app/${loginUser?.organizationId}/${teamId}/team-analytics/graph/:reportType/graph-team/${graphType}/`}
                  setLoadData={setLoadData}
                />
              )}
            />
            <Route
              path={`/app/:organiationId/${teamId}/team-analytics/graph/:reportType/graph-member/`}
              render={(routeProps) => (
                <MemberList
                  reportType={reportType}
                  organization={fetchOrganization.data!.organization!}
                  startDateTime={startDateTime}
                  endDateTime={endDateTime}
                  teamId={teamId}
                  teamIds={teamIds}
                  projectIds={projectIds}
                  clientIds={clientIds}
                  memberIds={memberIds}
                  graphPath={`/app/:organiationId/${teamId}/team-analytics/graph/:reportType`}
                  graphType={graphType}
                  url={`/app/${loginUser?.organizationId}/${teamId}/team-analytics/graph/:reportType/graph-member/${graphType}/`}
                  setLoadData={setLoadData}
                />
              )}
            />
            <Redirect
              to={`/app/${loginUser?.organizationId}/${teamId}/team-analytics/graph/time-entries/graph-member/bar/`}
            />
          </Switch>
        </View>
      </Spinner>
    </Container>
  );
};

const AppTeamAnalyticsGraphPage = () => {
  return <AppTeamAnalyticsGraph />;
};

export default React.memo(AppTeamAnalyticsGraphPage);
