import React, { useContext } from 'react';
import { View } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import {
  JoinedTeamsWithoutPersonalTeamDocument,
  OrganizationTeamsWithoutPersonalTeamDocument,
  Team,
  TeamMemberRelationsDocument,
  TeamStatus,
  useArchiveTeamMutation,
  useTeamQuery,
  useUnarchiveTeamMutation,
} from '../../../../../../../graphql/api/API';
import when from '../../../../../../../lang-extention/When';
import { LoginUserContext } from '../../../../../../../modules/auth/LoginUserContext';
import LoginUtil from '../../../../../../../modules/auth/LoginUtil';
import Button from '../../../../../../presentational/atoms/button';
import Form from '../../../../../../presentational/atoms/form';
import Typography, { TypographyType } from '../../../../../../presentational/atoms/typography';
import { IThemePart } from '../../../../../../theme';
import SettingsElement from '../../../../organisms/settings-element';

const Container = styled.View`
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
`;

interface ITeamArchiveProps {
  team: Team;
  onComplete: () => void;
  onCancel: () => void;
}

const TeamArchiveDialog = (props: ITeamArchiveProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, _] = useContext(LoginUserContext);
  const [archiveTeam] = useArchiveTeamMutation({
    variables: {
      id: props.team.id!,
      input: {
        versionNo: props.team.versionNo,
      },
    },
    refetchQueries: [
      {
        query: OrganizationTeamsWithoutPersonalTeamDocument,
        variables: {
          organizationId: loginUser!.organizationId,
        },
      },
      {
        query: JoinedTeamsWithoutPersonalTeamDocument,
        variables: {
          organizationId: loginUser!.organizationId,
          withArchivedTeam: true,
        },
      },
      {
        query: JoinedTeamsWithoutPersonalTeamDocument,
        variables: {
          organizationId: loginUser!.organizationId,
          withArchivedTeam: false,
        },
      },
      {
        query: TeamMemberRelationsDocument,
      },
    ],
  });
  const [unarchiveTeam] = useUnarchiveTeamMutation({
    variables: {
      id: props.team.id!,
      input: {
        versionNo: props.team.versionNo,
      },
    },
    refetchQueries: [
      {
        query: OrganizationTeamsWithoutPersonalTeamDocument,
        variables: {
          organizationId: loginUser!.organizationId,
        },
      },
      {
        query: JoinedTeamsWithoutPersonalTeamDocument,
        variables: {
          organizationId: loginUser!.organizationId,
          withArchivedTeam: true,
        },
      },
      {
        query: JoinedTeamsWithoutPersonalTeamDocument,
        variables: {
          organizationId: loginUser!.organizationId,
          withArchivedTeam: false,
        },
      },
      {
        query: TeamMemberRelationsDocument,
      },
    ],
  });

  return (
    <Container>
      <Form style={{ minWidth: 300 }}>
        {props.team.status === TeamStatus.Active ? (
          <>
            <Typography
              variant={TypographyType.Description}
              style={{ fontSize: 16, lineHeight: 22, marginBottom: 20, textAlign: 'center' }}>
              このチームをアーカイブしますか？
            </Typography>
          </>
        ) : (
          <>
            <Typography
              variant={TypographyType.Description}
              style={{ fontSize: 16, lineHeight: 22, marginBottom: 20, textAlign: 'center' }}>
              このチームをアーカイブ解除しますか？
            </Typography>
          </>
        )}
        <View style={{ flexDirection: 'row' }}>
          <Button
            text={props.team.status === 'Active' ? `アーカイブする` : 'アーカイブ解除する'}
            completeText={
              props.team.status === 'Active' ? `アーカイブしました` : 'アーカイブ解除しました'
            }
            style={{ height: 30, marginRight: 5 }}
            textStyle={{ fontSize: 16 }}
            onPress={async () => {
              if (props.team.status === 'Active') {
                await archiveTeam();
              } else {
                await unarchiveTeam();
              }
              props.onComplete();
            }}
          />
          <Button
            text="キャンセル"
            style={{ height: 30, backgroundColor: 'transparent' }}
            textStyle={{ fontSize: 14, color: themeContext.colors.primary }}
            onPress={async () => {
              props.onCancel();
            }}
            disableValidate={true}
          />
        </View>
      </Form>
    </Container>
  );
};

const TeamSettingsArchivePage = () => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const teamId = LoginUtil.getTeamIdFromURl();
  const { loading, data } = useTeamQuery({
    variables: {
      id: teamId!,
    },
    skip: teamId === null,
  });

  if (loading) {
    return <></>;
  }

  return (
    <Container>
      <SettingsElement
        title={'チームのアーカイブ'}
        changeText={data!.team?.status === 'Active' ? 'アーカイブする' : 'アーカイブ解除する'}
        modal={(closeModal) =>
          data!.team?.status === 'Active' ? (
            <TeamArchiveDialog team={data!.team!} onCancel={closeModal} onComplete={closeModal} />
          ) : (
            <TeamArchiveDialog team={data!.team!} onCancel={closeModal} onComplete={closeModal} />
          )
        }>
        {data!.team?.status === 'Active' ? (
          <View>
            <Typography variant={TypographyType.Normal}>有効(アーカイブされていません)</Typography>
            <Typography
              variant={TypographyType.Description}
              style={{ fontSize: 14, lineHeight: 22, marginTop: 20 }}>
              このチームが不要になった場合には、アーカイブすることができます。{'\n'}
              {'\n'}
              アーカイブすると、チームやそのプロジェクト・タスクは変更できなくなり、{'\n'}
              サイドメニューには表示されなくなります。
              {'\n'}
              データは残りますので、あとからいつでも元に戻すことができます。{'\n'}
              {'\n'}
              アーカイブしたチームは「チームを探す」画面から検索することが出来ます。{'\n'}
            </Typography>
          </View>
        ) : (
          <View>
            <Typography variant={TypographyType.Normal}>アーカイブ済み</Typography>
            <Typography
              variant={TypographyType.Description}
              style={{ fontSize: 14, lineHeight: 22, marginTop: 20 }}>
              アーカイブ解除すると、チームやそのプロジェクト・タスクは変更できるようになり、{'\n'}
              チームに参加している場合は、サイドメニューにも表示されるようになります。
            </Typography>
          </View>
        )}
      </SettingsElement>
    </Container>
  );
};

export default TeamSettingsArchivePage;
