import React, { useCallback, useContext } from 'react';
import { View, Image } from 'react-native';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../../theme';
import VirtualizedFlatList from '../../../../presentational/atoms/list2/virtualized-flat-list';
import OrganizationUtil from '../../../../../util/OrganizationUtil';
import { useHistory, useParams } from 'react-router';
import Avatar from '../../../../presentational/atoms/avatar';
import {
  CalendarEvent,
  useOrganizationQuery,
  useProjectCalendarEventsQuery,
} from '../../../../../graphql/api/API';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import noData from '../../../../../base64Images/no-data/no-data-4';
import UrlUtil from '../../../../../util/UrlUtil';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import TimeUtil from '../../../../../util/TimeUtil';
import DateUtil from '../../../../../util/DateUtil';

const Container = styled.View`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 6px;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.4s;
  z-index: 100;
  border-width: 1px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
`;

const Main = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  padding-bottom: 3px;
  overflow: hidden;
`;

interface IListWrapperProps extends IStyleTheme {
  isFavoritePage: boolean;
  isAssignedPage: boolean;
}

const ListWrapper = styled.View<IListWrapperProps>`
  height: ${(props: IListWrapperProps) =>
    props.isFavoritePage || props.isAssignedPage
      ? 'calc(100vh - 57px - 90px - 45px - 70px)'
      : 'calc(100vh - 57px - 90px - 45px)'};
`;

const Header = () => {
  const themeContext: IThemePart = useContext(ThemeContext);
  return (
    <View
      style={{
        marginTop: 10,
        marginRight: 10,
        paddingRight: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}>
      <View style={{ flexDirection: 'row', alignItems: 'flex-start', marginLeft: 21, flex: 1 }}>
        <Typography
          variant={TypographyType.Normal}
          style={{
            fontSize: 12,
            marginLeft: 0,
            color: themeContext.colors.description,
            textAlign: 'center',
          }}
          ellipsis>
          作業時間を記録したカレンダー予定名
        </Typography>
      </View>
      <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
        <Typography
          variant={TypographyType.Normal}
          style={{
            fontSize: 12,
            marginLeft: 0,
            color: themeContext.colors.description,
            textAlign: 'center',
            minWidth: 120,
          }}>
          開始日時
        </Typography>
        <View style={{ minWidth: 50 }} />
        <Typography
          variant={TypographyType.Normal}
          style={{
            fontSize: 12,
            marginLeft: 0,
            color: themeContext.colors.description,
            textAlign: 'center',
            minWidth: 120,
          }}>
          終了日時
        </Typography>
        <Typography
          variant={TypographyType.Normal}
          style={{
            fontSize: 12,
            marginLeft: 0,
            color: themeContext.colors.description,
            textAlign: 'center',
            paddingHorizontal: 5,
            minWidth: 80,
          }}>
          作業時間
        </Typography>
      </View>
      <View style={{ flexDirection: 'row' }}></View>
    </View>
  );
};

interface ICalendarEventListParam {
  teamId: string;
  projectId: string;
  organizationId: string;
}

const CalendarEventList = () => {
  const pageSize = 50;
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser] = useContext(LoginUserContext);
  const params = useParams<ICalendarEventListParam>();
  const history = useHistory();
  const { loading, data, fetchMore } = useProjectCalendarEventsQuery({
    variables: {
      projectId: params.projectId,
      offset: 0,
      limit: pageSize,
    },
    fetchPolicy: 'network-only',
  });
  const { loading: organizationLoading, data: organizationData } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId,
    },
  });

  const renderItem = useCallback(
    (item: CalendarEvent, index) => {
      if (!organizationData?.organization) {
        return <></>;
      }
      return (
        <Container>
          <Main>
            <View
              style={{
                flexDirection: 'row',
                flex: 1,
                minWidth: 67,
                height: 30,
                justifyContent: 'flex-start',
                marginRight: 20,
              }}>
              <Typography
                variant={TypographyType.Normal}
                ellipsis={true}
                tooltipWhenEllipsis={true}
                tooltipId={`calendar-event-${item.id}`}
                style={{
                  fontSize: 14,
                  color: themeContext.colors.textColor,
                }}>
                {item.eventName}
              </Typography>
              {item.taskTitle && (
                <Typography
                  variant={TypographyType.Description}
                  ellipsis={true}
                  tooltipWhenEllipsis={true}
                  tooltipId={`calendar-event-task-${item.taskId}`}
                  style={{
                    fontSize: 12,
                    color: themeContext.colors.description,
                    maxWidth: 300,
                    marginLeft: 10,
                  }}>
                  {`(${item.taskTitle})`}
                </Typography>
              )}
            </View>
            <View style={{ flexDirection: 'row' }}>
              <Typography
                variant={TypographyType.Normal}
                style={{ fontSize: 12, paddingHorizontal: 5, minWidth: 120, textAlign: 'center' }}>
                {DateUtil.formatDateTime(item.startDateTime)}
              </Typography>
              <View style={{ alignItems: 'center', justifyContent: 'center', minWidth: 50 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{ fontSize: 13, textAlign: 'center' }}>
                  〜
                </Typography>
              </View>
              <Typography
                variant={TypographyType.Normal}
                style={{ fontSize: 12, paddingHorizontal: 5, minWidth: 120, textAlign: 'center' }}>
                {DateUtil.formatDateTime(item.endDateTime)}
              </Typography>
              <Typography
                variant={TypographyType.Normal}
                style={{ fontSize: 12, paddingHorizontal: 5, minWidth: 80, textAlign: 'center' }}>
                {TimeUtil.formatForTask(item.workingTimeSec)}
              </Typography>
            </View>
          </Main>
        </Container>
      );
    },
    [organizationData?.organization]
  );

  const onPress = useCallback(
    (calendarEvent: CalendarEvent) => {
      history.push(UrlUtil.createCalendarEventDetailUrl(calendarEvent));
    },
    [params.organizationId, params.teamId, params.projectId]
  );

  if (loading) {
    return <></>;
  }

  return (
    <ListWrapper
      isFavoritePage={location.hash.indexOf('/my/favorite-project/') !== -1}
      isAssignedPage={location.hash.indexOf('/my/assigned-project/') !== -1}>
      <Header />
      {data!.projectCalendarEvents!.length === 0 ? (
        <View
          style={{
            height: 'calc(100% - 150px)',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Image
            source={{ uri: noData }}
            resizeMode="contain"
            style={{
              width: 300,
              height: 200,
              opacity: 0.7,
            }}
          />
          <Typography
            variant={TypographyType.Normal}
            style={{ color: themeContext.colors.description }}>
            作業記録されているカレンダー予定はありません
          </Typography>
        </View>
      ) : (
        <VirtualizedFlatList
          items={(data!.projectCalendarEvents || []) as CalendarEvent[]}
          style={{
            height:
              location.hash.indexOf('/my/favorite-project/') !== -1 ||
              location.hash.indexOf('/my/assigned-project/') !== -1
                ? 'calc(100vh - 280px - 70px)'
                : 'calc(100vh - 280px)',
          }}
          renderItem={renderItem as any}
          getKey={(calendarEvent: any) => calendarEvent!.id!.toString()}
          itemHeight={43}
          onPress={onPress as any}
          onEndReached={() => {
            if ((data?.projectCalendarEvents?.length ?? 0) < pageSize) {
              return;
            }
            fetchMore({
              variables: {
                offset: data!.projectCalendarEvents!.length,
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return Object.assign({}, prev, {
                  projectCalendarEvents: [
                    ...(prev.projectCalendarEvents || []),
                    ...(fetchMoreResult.projectCalendarEvents || []),
                  ],
                });
              },
            });
          }}
        />
      )}
    </ListWrapper>
  );
};

export default React.memo(CalendarEventList);
