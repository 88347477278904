import moment from 'moment-timezone';
import workingHistory from '../components/container/web/organisms/right-side-bar/working-history';
import { Task } from '../graphql/api/API';
import { LoginUser } from '../modules/auth/LoginUserContext';

export default class TaskUtil {
  private constructor() {}

  public static prepareTotalWorkingTimeSec(task: Task): number {
    const trackingHistoryWorkingTimeSec = task.workingMembers
      .map((workingMmeberInfo) => {
        return moment().diff(moment(workingMmeberInfo!.lastWorkingStartDateTime), 'seconds');
      })
      .reduce((sum, seconds) => {
        return sum + seconds;
      }, 0);
    return task.workingTimeSec + trackingHistoryWorkingTimeSec;
  }

  public static prepareRemainingWorkingTimeSec(task: Task): number | null {
    if (task.estimateTimeSec === null || task.estimateTimeSec === undefined) {
      return null;
    }
    if (task.completeDateTime) {
      return 0;
    }
    return task.estimateTimeSec - this.prepareTotalWorkingTimeSec(task);
  }

  public static prepareEstimatedRemainingWorkingTimeSec(task: Task): number | null {
    if (task.estimateTimeSec === null || task.estimateTimeSec === undefined) {
      return null;
    }
    if (task.progressRate === 0) {
      return null;
    }
    if (task.completeDateTime) {
      return 0;
    }
    return Math.ceil(
      (this.prepareTotalWorkingTimeSec(task) / task.progressRate) * (100 - task.progressRate)
    );
  }

  public static preparePredictWorkingTimeSec(task: Task): number | null {
    const estimatedRemainingWorkingTimeSec = this.prepareEstimatedRemainingWorkingTimeSec(task);
    if (estimatedRemainingWorkingTimeSec === null) {
      return null;
    }
    return estimatedRemainingWorkingTimeSec + task.workingTimeSec;
  }

  public static isTracking(task: Task, loginUser: LoginUser): boolean {
    return (
      task.workingMembers.findIndex(
        (workingMemberInfo) => workingMemberInfo?.member.id === loginUser.id
      ) !== -1
    );
  }
}
