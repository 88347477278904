import React, { useContext, useState } from 'react';
import { View } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../../theme';
import TextList from '../../../../presentational/atoms/text-list';
import { IRouteItem } from '../../../../../routes/RouteItem';
import { Redirect, Route, Switch } from 'react-router';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import LoginUtil from '../../../../../modules/auth/LoginUtil';
import ResizableColumn from '../../organisms/resizable-column';

const Container = styled.View`
  display: flex;
  flex-direction: row;
`;

const MainAreaContainer = styled.View`
  display: flex;
  flex: 1;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.4s;
  z-index: 100;
  height: calc(100vh - 57px);
`;

const MainAreaHeader = styled.View`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: ${(props: IStyleTheme) => props.theme.colors.subHeader};
  height: 3rem;
  padding: 1rem 1.5rem;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.4s;
`;

interface Props {
  headerTitle: string;
  routes: IRouteItem[];
  resizeCookeName?: string;
}

const ListMenuApp = (props: Props) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const teamId = LoginUtil.getTeamIdFromURl();

  const regexRoutes = props.routes.map((route) => {
    return {
      name: route.name,
      path: route.path
        ?.replace(':organizationId', loginUser!.organizationId)
        .replace(':teamId', teamId!),
    };
  });
  const currentRoute = regexRoutes.find(
    (route) => route.path && window.location.hash.match(route.path)
  );

  if (props.resizeCookeName) {
    return (
      <Container>
        <ResizableColumn
          cookieName={props.resizeCookeName}
          defaultWidth={200}
          renderChild={(width) => {
            return (
              <>
                <View
                  style={{
                    flexDirection: 'column',
                    backgroundColor: themeContext.colors.header,
                    paddingVertical: 10,
                    paddingHorizontal: 3,
                  }}>
                  <Typography
                    variant={TypographyType.SubTitle}
                    style={{ color: '#FFFFFF', fontSize: 16, fontWeight: '600' }}>
                    {props.headerTitle}
                  </Typography>
                </View>
                <TextList
                  items={props.routes.map((route) => {
                    return {
                      data: route.name,
                      path: route.path
                        ?.replace(':organizationId', loginUser!.organizationId)
                        .replace(':teamId', teamId!),
                    };
                  })}
                  textStyle={{ fontSize: 14 }}
                  onPress={(item) => {}}
                />
              </>
            );
          }}
        />

        <MainAreaContainer>
          <MainAreaHeader style={{} as any}>
            <Typography
              variant={TypographyType.Normal}
              style={[{ color: '#FFFFFF', fontWeight: '600' }, {}] as any}>
              {currentRoute ? currentRoute.name : ''}
            </Typography>
          </MainAreaHeader>
          <Switch>
            <View>
              {props.routes.map((route: IRouteItem, key: number) => {
                return <Route path={route.path} component={route.component} key={key} />;
              })}
              {props.routes[0].path && (
                <Redirect
                  to={props.routes[0]
                    .path!.replace(':organizationId', loginUser!.organizationId)
                    .replace(':teamId', teamId || '')}
                />
              )}
            </View>
          </Switch>
        </MainAreaContainer>
      </Container>
    );
  }

  return (
    <Container>
      <View>
        <View
          style={{
            flexDirection: 'column',
            backgroundColor: themeContext.colors.header,
            paddingVertical: 10,
            paddingHorizontal: 3,
          }}>
          <Typography
            variant={TypographyType.SubTitle}
            style={{ color: '#FFFFFF', fontSize: 16, fontWeight: '600' }}>
            {props.headerTitle}
          </Typography>
        </View>
        <TextList
          items={props.routes.map((route) => {
            return {
              data: route.name,
              path: route.path
                ?.replace(':organizationId', loginUser!.organizationId)
                .replace(':teamId', teamId!),
            };
          })}
          style={{ width: 200 }}
          textStyle={{ fontSize: 14 }}
          onPress={(item) => {}}
        />
      </View>
      <MainAreaContainer>
        <MainAreaHeader style={{} as any}>
          <Typography
            variant={TypographyType.Normal}
            style={[{ color: '#FFFFFF', fontWeight: '600' }, {}] as any}>
            {currentRoute ? currentRoute.name : ''}
          </Typography>
        </MainAreaHeader>
        <Switch>
          <View>
            {props.routes.map((route: IRouteItem, key: number) => {
              return <Route path={route.path} component={route.component} key={key} />;
            })}
            {props.routes[0].path && (
              <Redirect
                to={props.routes[0]
                  .path!.replace(':organizationId', loginUser!.organizationId)
                  .replace(':teamId', teamId || '')}
              />
            )}
          </View>
        </Switch>
      </MainAreaContainer>
    </Container>
  );
};

export default ListMenuApp;
