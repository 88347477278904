import React, { useContext } from 'react';
//@ts-ignore
import { ThemeContext } from 'styled-components/native';
import { View, Text } from 'react-native';
import MultiPickerFilter from '../../../../../../../presentational/atoms/multi-picker-filter';
import { IThemePart } from '../../../../../../../theme';
import {
  Organization,
  ReportType,
  useGraphSearchTargetQuery,
} from '../../../../../../../../graphql/api/API';
import { LoginUserContext } from '../../../../../../../../modules/auth/LoginUserContext';
import moment from 'moment-timezone';
import OrganizationUtil from '../../../../../../../../util/OrganizationUtil';

interface IGraphSearchFormProps {
  reportType: ReportType;
  organization: Organization;
  teamIds: string[];
  setTeamIds: (value: string[]) => void;
  projectIds: string[];
  setProjectIds: (value: string[]) => void;
  clientIds: string[];
  setClientIds: (value: string[]) => void;
  memberIds: string[];
  setMemberIds: (value: string[]) => void;
  teamId: string | null;
  memberId: string | null;
  start: moment.Moment;
  end: moment.Moment;
  showMember?: boolean;
  showTeam?: boolean;
  isLoadData: boolean;
  setLoadData: (value: boolean) => void;
}

const GraphSearchForm = (props: IGraphSearchFormProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser] = useContext(LoginUserContext);

  const { loading, data } = useGraphSearchTargetQuery({
    variables: {
      input: {
        reportType: props.reportType,
        teamIds: [],
        projectIds: [],
        clientIds: [],
        memberIds: [],
        organizationId: loginUser!.organizationId,
        teamId: props.teamId,
        memberId: props.memberId,
        start: moment(props.start).startOf('day').toISOString(),
        end: moment(props.end).endOf('day').toISOString(),
        timeZoneOffset: moment().tz(moment.tz.guess()).format('Z'),
      },
    },
    fetchPolicy: 'network-only',
    skip: !props.isLoadData,
    onCompleted: () => props.setLoadData(true),
  });

  if (loading) {
    return <></>;
  }

  return (
    <View style={{ flexDirection: 'row' }}>
      {props.showTeam !== false && (
        <>
          {OrganizationUtil.isTeamPlan(props.organization) && (
            <View style={{ marginTop: 10, marginLeft: 20 }}>
              <Text
                style={{
                  fontSize: 7,
                  color: themeContext.colors.description,
                  textAlign: 'center',
                  marginBottom: 2,
                  paddingRight: 20,
                }}>
                チーム
              </Text>
              <MultiPickerFilter
                placeHolder="チームを絞り込む"
                containerStyle={{ marginRight: 10 }}
                value={props.teamIds}
                pickerItems={(data?.graphSearchTarget?.teams?.slice() ?? [])
                  .sort((a, b) => (a!.name as any) - (b!.name as any))
                  .map((team) => {
                    return {
                      label: team!.name!,
                      value: team!.id!,
                    };
                  })}
                onChange={(items) => {}}
                onBlur={(items) => {
                  props.setTeamIds(items.map((item) => item.value));
                }}
              />
            </View>
          )}
        </>
      )}
      <View style={{ marginTop: 10 }}>
        <Text
          style={{
            fontSize: 7,
            color: themeContext.colors.description,
            textAlign: 'center',
            marginBottom: 2,
            paddingRight: 20,
          }}>
          プロジェクト
        </Text>
        <MultiPickerFilter
          placeHolder="プロジェクトを絞り込む"
          containerStyle={{ marginRight: 10 }}
          value={props.projectIds}
          pickerItems={(data?.graphSearchTarget?.projects?.slice() ?? [])
            .sort((a, b) => (a!.name as any) - (b!.name as any))
            .map((project) => {
              return {
                label: project!.name!,
                value: project!.id!,
              };
            })}
          onChange={(items) => {}}
          onBlur={(items) => {
            props.setProjectIds(items.map((item) => item.value));
          }}
        />
      </View>
      <View style={{ marginTop: 10 }}>
        <Text
          style={{
            fontSize: 7,
            color: themeContext.colors.description,
            textAlign: 'center',
            marginBottom: 2,
            paddingRight: 20,
          }}>
          取引先
        </Text>
        <MultiPickerFilter
          placeHolder="取引先を絞り込む"
          containerStyle={{ marginRight: 10 }}
          value={props.clientIds}
          pickerItems={(data?.graphSearchTarget?.clients?.slice() ?? [])
            .sort((a, b) => (a!.name as any) - (b!.name as any))
            .map((client) => {
              return {
                label: client!.name!,
                value: client!.id!,
              };
            })}
          onChange={(items) => {}}
          onBlur={(items) => {
            props.setClientIds(items.map((item) => item.value));
          }}
        />
      </View>
      {props.showMember !== false && (
        <View style={{ marginTop: 10 }}>
          <Text
            style={{
              fontSize: 7,
              color: themeContext.colors.description,
              textAlign: 'center',
              marginBottom: 2,
              paddingRight: 20,
            }}>
            メンバー
          </Text>
          <MultiPickerFilter
            placeHolder="メンバーを絞り込む"
            containerStyle={{ marginRight: 10 }}
            value={props.memberIds}
            pickerItems={(data?.graphSearchTarget?.members?.slice() ?? [])
              .sort((a, b) => (a!.name as any) - (b!.name as any))
              .map((member) => {
                return {
                  label: member!.name!,
                  value: member!.id!,
                };
              })}
            onChange={(items) => {}}
            onBlur={(items) => {
              props.setMemberIds(items.map((item) => item.value));
            }}
          />
        </View>
      )}
    </View>
  );
};

export default GraphSearchForm;
