import React, { useContext, useState, useEffect, useRef } from 'react';
import { TextInputProps, TextStyle, View, ViewStyle, TouchableOpacity } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import { ValidationRules } from 'react-hook-form';
import { IStyleTheme, IThemePart } from '../../../theme';
import FormContext from '../form/context';
import Typography, { TypographyType } from '../typography';
import moment from 'moment-timezone';
import useHover from '../editable-text/use-hover';
import Button from '../../../presentational/atoms/button';
import LeftIcon from '../../molecules/image-icon/left';
import DoubleLeftIcon from '../../molecules/image-icon/double-left';
import RightIcon from '../../molecules/image-icon/right';
import DoubleRightIcon from '../../molecules/image-icon/double-right';
import { ListValueMap } from '../input';
import Form from '../form';
import ColorUtil from '../../../../util/ColorUtil';
import { useHotkeys } from 'react-hotkeys-hook';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import TimeUtil from '../../../../util/TimeUtil';

interface ILabelProps extends IStyleTheme {
  isFloat: boolean;
}

const Container = styled.View<IStyleTheme>`
  margin: 1rem 0;
  width: 100%;
`;

const Label = styled.Text<ILabelProps>`
  position: absolute;
  left: ${(props: ILabelProps) => (props.isFloat ? 0 : 0)};
  top: ${(props: ILabelProps) => (props.isFloat ? '-1.5rem' : 0)};
  font-size: ${(props: ILabelProps) => (props.isFloat ? '0.7rem' : '1rem')};
  color: ${(props: ILabelProps) => props.theme.colors.label};
  transition: all 0.2s;
`;

const ErrorMessage = styled.Text<IStyleTheme>`
  font-size: 0.9rem;
  color: ${(props: IStyleTheme) => props.theme.colors.error};
  text-align: left;
`;

const Description = styled.Text<IStyleTheme>`
  font-size: 0.9rem;
  color: ${(props: IStyleTheme) => props.theme.colors.description};
  text-align: left;
`;

const InputText = styled.TextInput<IStyleTheme>`
  height: 1rem,
  fontSize: 1rem,
  color: ${(props: IStyleTheme) => props.theme.colors.input};
  border-bottom-color: '#555';
  outlineWidth: 0;
  border-bottom-width: 1px;
  padding: 0.3rem;
  width: 100%;
`;

const daysOptions = [...Array(100)].map((_, i) => {
  return {
    label: i.toString(),
    value: i,
  } as ListValueMap;
});

const hourOptions = (unlimitedHour: boolean) =>
  [...Array(unlimitedHour ? 100 : 24)].map((_, i) => {
    return {
      label: i.toString(),
      value: i,
    } as ListValueMap;
  });

const minutesOptions = [...Array(60 / 5)].map((_, i) => {
  const minutes = i * 5;
  return {
    label: minutes.toString(),
    value: minutes,
  } as ListValueMap;
});

const adjustNumber = (value: string, max: number): number => {
  const adjustedValue = value
    .replaceAll('０', '0')
    .replaceAll('１', '1')
    .replaceAll('２', '2')
    .replaceAll('３', '3')
    .replaceAll('４', '4')
    .replaceAll('５', '5')
    .replaceAll('６', '6')
    .replaceAll('７', '7')
    .replaceAll('８', '8')
    .replaceAll('９', '9');

  if (adjustedValue.trim().length === 0) {
    return 0;
  }
  // 数字のみ許可
  if (/^[0-9]*$$/.test(adjustedValue)) {
    const temp = Number(adjustedValue);
    if (temp < 0) {
      return 0;
    }
    if (temp > max) {
      return max;
    }
    return Number(adjustedValue);
  }
  return 0;
};

interface ITimeSelectorProps {
  minutes: number;
  unlimitedHour?: boolean;
  enableDays?: boolean;
  onChange?: (minutes: number) => void;
  onFocus?: () => void;
  onBlur?: () => void;
}

export const TimeSelector = React.memo((props: ITimeSelectorProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [daysValue, setDaysValue] = useState(
    !!props.enableDays ? Math.floor(props.minutes / (60 * 60 * 24)) : 0
  );
  const [hourValue, setHourValue] = useState(
    !!props.enableDays ? Math.floor(props.minutes / 60) % 24 : Math.floor(props.minutes / 60)
  );
  const [minutesValue, setMinutesValue] = useState(props.minutes % 60);
  const maxHour = props.unlimitedHour === true && !props.enableDays ? 100000 : 23;

  useEffect(() => {
    setDaysValue(!!props.enableDays ? Math.floor(Math.floor(props.minutes / 60) / 24) : 0);
    setHourValue(
      !!props.enableDays ? Math.floor(props.minutes / 60) % 24 : Math.floor(props.minutes / 60)
    );
    setMinutesValue(props.minutes % 60);
  }, [props.minutes]);

  return (
    //@ts-ignore
    <View style={{ zIndex: 11000, flexDirection: 'row', gap: 10, flex: 1 }}>
      {!!props.enableDays && (
        <View style={{ flex: 1 }}>
          <CreatableSelect
            styles={{
              input: (styles) => {
                return styles;
              },
              option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                return {
                  ...styles,
                  backgroundColor: isSelected
                    ? ColorUtil.lignten(themeContext.colors.primary, 20)
                    : isFocused
                    ? ColorUtil.lignten(themeContext.colors.primary, 10)
                    : themeContext.colors.baseColor,
                  color: isSelected || isFocused ? '#FFFFFF' : themeContext.colors.textColor,
                  cursor: isDisabled ? 'not-allowed' : 'default',
                };
              },
              menuPortal: (provided) => ({ ...provided, zIndex: 12000 }),
              menu: (provided) => ({ ...provided, zIndex: 12000 }),
            }}
            components={{
              Option: (props) => {
                return (
                  <View
                    ref={props.innerRef}
                    {...props.innerProps}
                    style={
                      {
                        backgroundColor: props.isFocused
                          ? themeContext.colors.subHeader
                          : props.isSelected
                          ? themeContext.colors.header
                          : themeContext.colors.baseColor,
                        height: 34,
                        paddingVertical: 10,
                        paddingHorizontal: 10,
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        flexDirection: 'row',
                        cursor: 'pointer',
                      } as any
                    }>
                    <Typography
                      variant={TypographyType.Normal}
                      ellipsis={true}
                      style={{
                        fontSize: 16,
                        color: props.isFocused
                          ? themeContext.colors.baseColor
                          : props.isSelected
                          ? themeContext.colors.baseColor
                          : themeContext.colors.textColor,
                      }}>
                      {props.children}
                    </Typography>
                  </View>
                );
              },
            }}
            isMulti={false}
            menuPortalTarget={document.body}
            menuPosition={'fixed'}
            options={daysOptions}
            noOptionsMessage={() => '選択可能なものがありません'}
            isSearchable
            defaultValue={
              {
                label: '0',
                value: 0,
              } as ListValueMap
            }
            value={
              {
                label: daysValue.toString(),
                value: daysValue,
              } as ListValueMap
            }
            formatCreateLabel={(value) => (value ? `「${adjustNumber(value, 10000)}」` : '')}
            onChange={(item) => {
              const newDays = adjustNumber(`${item!.value}`, 10000);
              setDaysValue(newDays);
              if (props.onChange) {
                props.onChange((newDays * 24 + hourValue) * 60 + minutesValue);
              }
            }}
            onFocus={() => {
              if (props.onFocus) {
                props.onFocus();
              }
            }}
            onBlur={() => {
              if (props.onBlur) {
                props.onBlur();
              }
            }}
            onKeyDown={(event) => {
              switch (event.nativeEvent.key) {
                case 'Escape':
                  break;
                case 'Enter':
                  if (props.onChange) {
                    setDaysValue(daysValue);
                    props.onChange((daysValue * 24 + hourValue) * 60 + minutesValue);
                  }
                  break;
                case 'ArrowUp':
                  event.preventDefault();
                  if (daysValue >= 10000) {
                    return;
                  }
                  setDaysValue(daysValue + 1);
                  if (props.onChange) {
                    props.onChange(((daysValue + 1) * 24 + hourValue) * 60 + minutesValue);
                  }
                  break;
                case 'ArrowDown':
                  event.preventDefault();
                  if (daysValue === 0) {
                    return;
                  }
                  setDaysValue(daysValue - 1);
                  if (props.onChange) {
                    props.onChange(((daysValue - 1) * 24 + hourValue) * 60 + minutesValue);
                  }
                  break;
                default:
                  break;
              }
            }}
          />
        </View>
      )}
      <View style={{ flex: 1 }}>
        <CreatableSelect
          styles={{
            input: (styles) => {
              return styles;
            },
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
              return {
                ...styles,
                backgroundColor: isSelected
                  ? ColorUtil.lignten(themeContext.colors.primary, 20)
                  : isFocused
                  ? ColorUtil.lignten(themeContext.colors.primary, 10)
                  : themeContext.colors.baseColor,
                color: isSelected || isFocused ? '#FFFFFF' : themeContext.colors.textColor,
                cursor: isDisabled ? 'not-allowed' : 'default',
              };
            },
            menuPortal: (provided) => ({ ...provided, zIndex: 12000 }),
            menu: (provided) => ({ ...provided, zIndex: 12000 }),
          }}
          components={{
            Option: (props) => {
              return (
                <View
                  ref={props.innerRef}
                  {...props.innerProps}
                  style={
                    {
                      backgroundColor: props.isFocused
                        ? themeContext.colors.subHeader
                        : props.isSelected
                        ? themeContext.colors.header
                        : themeContext.colors.baseColor,
                      height: 34,
                      paddingVertical: 10,
                      paddingHorizontal: 10,
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      flexDirection: 'row',
                      cursor: 'pointer',
                    } as any
                  }>
                  <Typography
                    variant={TypographyType.Normal}
                    ellipsis={true}
                    style={{
                      fontSize: 16,
                      color: props.isFocused
                        ? themeContext.colors.baseColor
                        : props.isSelected
                        ? themeContext.colors.baseColor
                        : themeContext.colors.textColor,
                    }}>
                    {props.children}
                  </Typography>
                </View>
              );
            },
          }}
          isMulti={false}
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          options={hourOptions(!!props.unlimitedHour && !props.enableDays)}
          noOptionsMessage={() => '選択可能なものがありません'}
          isSearchable
          defaultValue={
            {
              label: '0',
              value: 0,
            } as ListValueMap
          }
          value={
            {
              label: hourValue.toString(),
              value: hourValue,
            } as ListValueMap
          }
          formatCreateLabel={(value) => (value ? `「${adjustNumber(value, maxHour)}」` : '')}
          onChange={(item) => {
            const newHour = adjustNumber(`${item!.value}`, maxHour);
            setHourValue(newHour);
            if (props.onChange) {
              props.onChange((daysValue * 24 + newHour) * 60 + minutesValue);
            }
          }}
          onFocus={() => {
            if (props.onFocus) {
              props.onFocus();
            }
          }}
          onBlur={() => {
            if (props.onBlur) {
              props.onBlur();
            }
          }}
          onKeyDown={(event) => {
            switch (event.nativeEvent.key) {
              case 'Escape':
                break;
              case 'Enter':
                if (props.onChange) {
                  setHourValue(hourValue);
                  props.onChange((daysValue * 24 + hourValue) * 60 + minutesValue);
                }
                break;
              case 'ArrowUp':
                event.preventDefault();
                if (hourValue >= maxHour) {
                  return;
                }
                setHourValue(hourValue + 1);
                if (props.onChange) {
                  props.onChange((daysValue * 24 + hourValue + 1) * 60 + minutesValue);
                }
                break;
              case 'ArrowDown':
                event.preventDefault();
                if (hourValue === 0) {
                  return;
                }
                setHourValue(hourValue - 1);
                if (props.onChange) {
                  props.onChange((daysValue * 24 + hourValue - 1) * 60 + minutesValue);
                }
                break;
              default:
                break;
            }
          }}
        />
      </View>
      <View style={{ justifyContent: 'center', alignItems: 'center' }}>
        <Typography variant={TypographyType.Normal} style={{ fontSize: 14, fontWeight: '600' }}>
          :
        </Typography>
      </View>
      <View style={{ flex: 1 }}>
        <CreatableSelect
          styles={{
            input: (styles) => {
              return styles;
            },
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
              return {
                ...styles,
                backgroundColor: isSelected
                  ? ColorUtil.lignten(themeContext.colors.primary, 20)
                  : isFocused
                  ? ColorUtil.lignten(themeContext.colors.primary, 10)
                  : themeContext.colors.baseColor,
                color: isSelected || isFocused ? '#FFFFFF' : themeContext.colors.textColor,
                cursor: isDisabled ? 'not-allowed' : 'default',
              };
            },
            menuPortal: (provided) => ({ ...provided, zIndex: 12000 }),
            menu: (provided) => ({ ...provided, zIndex: 12000 }),
          }}
          components={{
            Option: (props) => {
              return (
                <View
                  ref={props.innerRef}
                  {...props.innerProps}
                  style={
                    {
                      backgroundColor: props.isFocused
                        ? themeContext.colors.subHeader
                        : props.isSelected
                        ? themeContext.colors.header
                        : themeContext.colors.baseColor,
                      height: 34,
                      paddingVertical: 10,
                      paddingHorizontal: 10,
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      flexDirection: 'row',
                      cursor: 'pointer',
                    } as any
                  }>
                  <Typography
                    variant={TypographyType.Normal}
                    ellipsis={true}
                    style={{
                      fontSize: 16,
                      color: props.isFocused
                        ? themeContext.colors.baseColor
                        : props.isSelected
                        ? themeContext.colors.baseColor
                        : themeContext.colors.textColor,
                    }}>
                    {props.children}
                  </Typography>
                </View>
              );
            },
          }}
          isMulti={false}
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          options={minutesOptions}
          noOptionsMessage={() => '選択可能なものがありません'}
          isSearchable
          defaultValue={
            {
              label: '0',
              value: 0,
            } as ListValueMap
          }
          value={
            {
              label: minutesValue.toString(),
              value: minutesValue,
            } as ListValueMap
          }
          formatCreateLabel={(value) => (value ? `「${adjustNumber(value, 59)}」` : '')}
          onChange={(item) => {
            const newMinutes = adjustNumber(`${item!.value}`, 59);
            setMinutesValue(newMinutes);
            if (props.onChange) {
              props.onChange((daysValue * 24 + hourValue) * 60 + newMinutes);
            }
          }}
          onFocus={() => {
            if (props.onFocus) {
              props.onFocus();
            }
          }}
          onKeyDown={(event) => {
            switch (event.nativeEvent.key) {
              case 'Escape':
                break;
              case 'Enter':
                if (props.onChange) {
                  setMinutesValue(minutesValue);
                  props.onChange((daysValue * 24 + hourValue) * 60 + minutesValue);
                }
                break;
              case 'ArrowUp':
                event.preventDefault();
                if (minutesValue >= 59) {
                  return;
                }
                setMinutesValue(minutesValue + 1);
                if (props.onChange) {
                  props.onChange((daysValue * 24 + hourValue) * 60 + (minutesValue + 1));
                }
                break;
              case 'ArrowDown':
                event.preventDefault();
                if (minutesValue === 0) {
                  return;
                }
                setMinutesValue(minutesValue - 1);
                if (props.onChange) {
                  props.onChange((daysValue * 24 + hourValue) * 60 + (minutesValue - 1));
                }
                break;
              default:
                break;
            }
          }}
        />
      </View>
    </View>
  );
});

interface ICalendarCellProps {
  cellSize: number;
  date: moment.Moment;
  displayDate: moment.Moment;
  selectedDate: moment.Moment | null;
  selectDate: (date: moment.Moment) => void;
  isDisable?: boolean;
}

const CalendarCell = (props: ICalendarCellProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [hoverRef, isHover] = useHover();
  const isWeekEnd = props.date.isoWeekday() === 6 || props.date.isoWeekday() === 7;
  const isSelected = props.selectedDate
    ? props.date.format('YYYYMMDD') === props.selectedDate.format('YYYYMMDD')
    : false;

  return (
    <View
      ref={hoverRef}
      style={{
        width: props.cellSize,
        height: props.cellSize,
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <TouchableOpacity
        onPress={() => props.selectDate(props.date)}
        style={
          {
            width: props.cellSize - 4,
            height: props.cellSize - 4,
            borderRadius: 13,
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            opacity: props.isDisable
              ? 0.2
              : props.date.month() === props.displayDate.month()
              ? 1
              : 0.2,
            backgroundColor:
              isHover || isSelected
                ? isWeekEnd
                  ? '#f33563'
                  : themeContext.colors.primary
                : 'transparent',
          } as any
        }>
        <Typography
          variant={TypographyType.Normal}
          style={{
            color: props.isDisable
              ? themeContext.colors.description
              : isWeekEnd
              ? isHover || isSelected
                ? '#FFFFFF'
                : '#f33563'
              : isHover || isSelected
              ? '#FFFFFF'
              : themeContext.colors.textColor,
          }}>
          {props.date.format('D')}
        </Typography>
      </TouchableOpacity>
    </View>
  );
};

interface IProps extends TextInputProps {
  ref?: any;
  name: string;
  mode?: 'datetime' | 'date';
  label?: string;
  placeholder?: string;
  description?: string;
  containerStyle?: ViewStyle;
  inputContainerStyle?: ViewStyle;
  inputstyle?: TextStyle;
  validate?: ValidationRules;
  initialValue?: moment.Moment;
  selectableStartDate?: moment.Moment | null;
  selectableEndDate?: moment.Moment | null;
  onChange?: (value: moment.Moment | null) => void;
  onBlur?: (value: moment.Moment | null, textInputElement: any) => void;
  onPressEnter?: (value: moment.Moment | null) => void;
  onFocus?: () => void;
  focus?: boolean;
  additionalErrorMessage?: string | null;
  readonly?: boolean;
  hideClearButton?: boolean;
  fromEditableText?: boolean;
  required?: boolean;
}

const DateTimePicker: React.FC<IProps> = (props: IProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [showCalendar, setShowCalendar] = useState(false);
  const [value, setValue] = React.useState<moment.Moment | null>(
    props.initialValue || moment().startOf('day')
  );
  const [inputValue, setInputValue] = React.useState<string>('');
  const [selectableStartDate, setSelectableStartDate] = React.useState<moment.Moment | null>(
    props.selectableStartDate || null
  );
  const [selectableEndDate, setSelectableEndDate] = React.useState<moment.Moment | null>(
    props.selectableEndDate || null
  );

  const [displayDate, setDisplayDate] = React.useState<moment.Moment>(moment());
  const [dirty, setDirty] = useState(false);
  const [isFocused, setFocused] = useState(false);
  const [isOverX, setOverX] = useState(false);
  const [isOverY, setOverY] = useState(false);
  const [overYValue, setOverYValue] = useState(0);
  const calendarRef = useRef();
  const form = useContext(FormContext);
  const cellSize = 30;
  const [selectInputValue, setSelectInputValue] = useState('');

  // バリデーションを使えるように設定
  const { mode } = props as any;
  let textInputRef: any = useRef();
  let textInputElement: any = null;
  const setTextInputRef = (element: any) => {
    textInputElement = element;
    form.register({ name: props.name }, props.validate || {});
    textInputRef.current = element;
  };
  const [focusTimeSelect, setFocusTimeSelect] = useState(false);
  const [isOpenTimeSelect, setOpenTimeSelect] = useState(false);
  const timeSelectRef = useRef();
  const [hourTime, setHourTime] = useState(0);
  const [minTime, setMinTime] = useState(0);

  // 初期値の指定がある場合には、初期値を設定
  useEffect(() => {
    if (props.initialValue || props.initialValue === '') {
      setDisplayDate(props.initialValue ? moment(props.initialValue) : moment());
      form.setValue(props.name, props.initialValue, { shouldDirty: true, shouldValidate: true });
      setValue(
        props.initialValue
          ? moment(props.initialValue)
          : moment(moment().format('YYYY/MM/DD 00:00'))
      );
    }
  }, [props.initialValue]);

  useEffect(() => {
    form.setValue(props.name, value);
    if (value && !isFocused) {
      setInputValue(value!.format(props.mode !== 'date' ? 'YYYY/MM/DD HH:mm' : 'YYYY/MM/DD'));
    }
  }, [value]);

  useEffect(() => {
    setFocusTimeSelect(false);
    setOpenTimeSelect(false);
  }, []);

  // 追加のエラーメッセージがある場合にはセットする
  // TODO ここでエラーメッセージをセットしても、Submitはできてしまう。エラーがあったらSubmitもできないように改善したい
  useEffect(() => {
    if (props.additionalErrorMessage) {
      form.setError(props.name, {
        type: 'manual',
        message: props.additionalErrorMessage,
      });
    }
  }, [props.additionalErrorMessage]);

  useEffect(() => {
    if (textInputElement && props.focus === true) {
      setFocused(true);
      textInputElement.focus();
      setShowCalendar(true);
    }
  }, [textInputElement, props.focus]);

  useEffect(() => {
    setSelectableStartDate(props.selectableStartDate || null);
  }, [props.selectableStartDate]);

  useEffect(() => {
    setSelectableEndDate(props.selectableEndDate || null);
  }, [props.selectableEndDate]);

  useEffect(
    () => {
      setTimeout(() => {
        // 少し遅らせてから、画面位置判定をしないと、正しく判定されない
        setOverX(
          (calendarRef.current as any)?.getBoundingClientRect().x + (cellSize * 7 + 80 + 10 * 2) >
            window.innerWidth
        );
        setOverY((calendarRef.current as any)?.getBoundingClientRect().bottom > window.innerHeight);
        setOverYValue(
          (calendarRef.current as any)?.getBoundingClientRect().bottom - window.innerHeight
        );
      }, 400);
      textInputElement.click(); //TODO ここで一度クリックしてあげないと、VirtulizedList側でのz-index調整ロジックが発動せず、リストの下の行にかぶって表示されてしまう。このあたりだいぶ微妙なので、余裕ができたら仕組みから考えたいな
    },
    props.fromEditableText ? [] : [showCalendar]
  );

  const clickDocument = (e: any) => {
    if (focusTimeSelect) {
      return;
    }
    if ((calendarRef?.current as any)?.contains(e.target)) {
      (calendarRef?.current as any)?.click();
      return;
    }
    if ((textInputRef?.current as any)?.contains(e.target)) {
      return;
    }
    if (props.onBlur) {
      props.onBlur(value, textInputElement);
    }
    setShowCalendar(false);
  };
  useEffect(() => {
    window.addEventListener('click', clickDocument);

    // モーダル表示中で、Overlayがかかっている場合、それが邪魔をして、window.addEventListnerではクリックイベントを取得できない。
    // そのため、Overlayの要素にイベントを貼っている。
    // TODO ただし、この実装はOverlayのライブラリの実装に依存してしまっている
    const modalOverlayElement = window.document.getElementById('subtree-container');
    if (modalOverlayElement) {
      modalOverlayElement.addEventListener('click', clickDocument);
    }
    return () => {
      window.removeEventListener('click', clickDocument);
      if (modalOverlayElement) {
        modalOverlayElement.removeEventListener('click', clickDocument);
      }
    };
  }, [clickDocument]);

  useHotkeys('Esc', () => {
    setValue(props.initialValue || null);
    setFocused(false);
    setDirty(false);
    if (props.onChange) {
      props.onChange(props.initialValue || null);
    }
    textInputElement?.blur();
  });

  const startOfCalendar = moment(displayDate)
    .startOf('month')
    .add(-1 * moment(displayDate).startOf('month').isoWeekday() + 1, 'days'); // 月曜はじまりの場合
  const endOfCalendar = moment(displayDate)
    .endOf('month')
    .add(1, 'days') // 月曜はじまりの場合
    .add(7 - moment(displayDate).endOf('month').isoWeekday(), 'days');

  return (
    <>
      <InputText
        ref={setTextInputRef}
        style={[props.inputstyle as any]}
        autoCompleteType={'off'}
        value={inputValue}
        onChangeText={(value: string) => {
          setInputValue(value);
          if (moment(value).isValid()) {
            setValue(moment(value));
          }
        }}
        onFocus={() => {
          setShowCalendar(true);
          if (props.onFocus) {
            props.onFocus();
          }
        }}
        onKeyPress={(event: any) => {
          switch (event.nativeEvent.key) {
            case 'Enter':
              if (props.onPressEnter) {
                if (inputValue.trim().length === 0) {
                  props.onPressEnter(null);
                } else {
                  props.onPressEnter(value);
                }
              }
              break;
            case 'Escape':
              setValue(props.initialValue || moment().startOf('day'));
              setFocused(false);
              setDirty(false);
              if (props.onChange) {
                props.onChange(props.initialValue || null);
              }
              textInputElement.blur();
              break;
            case 'ArrowUp':
              event.preventDefault();
              if (value) {
                const newValue = moment(value).add(5, 'minutes');
                setValue(newValue);
                setInputValue(
                  newValue.format(props.mode !== 'date' ? 'YYYY/MM/DD HH:mm' : 'YYYY/MM/DD')
                );
              }
              break;
            case 'ArrowDown':
              event.preventDefault();
              if (value) {
                const newValue = moment(value).add(-5, 'minutes');
                setValue(newValue);
                setInputValue(
                  newValue.format(props.mode !== 'date' ? 'YYYY/MM/DD HH:mm' : 'YYYY/MM/DD')
                );
              }
              break;
            default:
              break;
          }
        }}
      />
      {form.errors[props.name] && <ErrorMessage>{form.errors[props.name].message}</ErrorMessage>}
      <Container
        ref={calendarRef as any}
        style={[
          {
            display: showCalendar ? 'flex' : 'none',
            backgroundColor: '#FFFFFF',
            position: 'absolute',
            top: 20 - (isOverY ? overYValue : 0),
            right: isOverX ? 0 : 'auto',
            zIndex: 2,
            borderWidth: 1,
            borderColor: themeContext.colors.separator,
            width: cellSize * 7 + 50 + 10 * 2,
            paddingHorizontal: 10,
            paddingVertical: 10,
            shadowOffset: {
              width: 1,
              height: 1,
            },
            shadowOpacity: 0.1,
            elevation: 2,
          },
          props.containerStyle as any,
        ]}>
        {props.label !== null && props.label !== undefined && (
          <Label
            isFloat={isFocused || String(displayDate).length > 0}
            onPress={() => {
              setFocused(true);
              textInputElement.focus();
            }}>
            {props.label}
          </Label>
        )}
        <View
          style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          <DoubleLeftIcon
            size={26}
            onPress={() => setDisplayDate(moment(displayDate).add(-1, 'year'))}
          />
          <LeftIcon
            size={26}
            onPress={() => setDisplayDate(moment(displayDate).add(-1, 'month'))}
          />
          <Typography variant={TypographyType.Normal}>
            {displayDate.format('YYYY年 M月')}
          </Typography>
          <RightIcon
            size={26}
            onPress={() => setDisplayDate(moment(displayDate).add(1, 'month'))}
          />
          <DoubleRightIcon
            size={26}
            onPress={() => setDisplayDate(moment(displayDate).add(1, 'year'))}
          />
        </View>

        <View style={{ flexDirection: 'column' }}>
          <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <View
              style={[
                {
                  marginTop: 10,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  width: cellSize * 7,
                  height: cellSize * (endOfCalendar.diff(startOfCalendar, 'days') / 7) + cellSize,
                },
                props.inputContainerStyle,
              ]}>
              {[...Array(7)].map((_, i) => {
                const targetDate = moment(startOfCalendar).add(i, 'days');
                const isWeekEnd = targetDate.isoWeekday() === 6 || targetDate.isoWeekday() === 7;
                return (
                  <View
                    style={{
                      width: cellSize,
                      height: cellSize,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    key={i}>
                    <Typography
                      variant={TypographyType.Normal}
                      style={{
                        color: isWeekEnd ? '#f33563' : themeContext.colors.textColor,
                        opacity: 0.5,
                      }}>
                      {targetDate.format('ddd')}
                    </Typography>
                  </View>
                );
              })}
              {[...Array(endOfCalendar.diff(startOfCalendar, 'days'))].map((_, i) => {
                const targetDate = moment(startOfCalendar).add(i, 'days');
                return (
                  <CalendarCell
                    cellSize={cellSize}
                    date={targetDate}
                    displayDate={displayDate}
                    selectedDate={value}
                    selectDate={(newValue) => {
                      const newDate = newValue
                        .hours(value?.hours() || 0)
                        .minutes(value?.minutes() || 0)
                        .seconds(value?.seconds() || 0);
                      setDisplayDate(newValue);
                      setValue(newDate);
                      setInputValue(
                        newDate
                          ? newDate.format(
                              props.mode !== 'date' ? 'YYYY/MM/DD HH:mm' : 'YYYY/MM/DD'
                            )
                          : ''
                      );
                      // (timeSelectRef as any).current?.focus();
                    }}
                    key={i}
                  />
                );
              })}
            </View>
          </View>
          {props.mode !== 'date' && (
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: 10,
                borderTopWidth: 1,
                borderColor: themeContext.colors.separator,
              }}>
              <Form style={{ minWidth: 250 }}>
                <TimeSelector
                  minutes={value ? value.get('hour') * 60 + value.get('minute') : 0}
                  onFocus={() => setFocusTimeSelect(true)}
                  onChange={(minutes) => {
                    const newValue = value ? moment(value) : moment();
                    newValue.set('hour', Math.floor(minutes / 60));
                    newValue.set('minute', minutes % 60);
                    newValue.set('second', 0);
                    setValue(newValue);
                    setInputValue(newValue.format('YYYY/MM/DD HH:mm'));
                  }}
                />
                {/* <CreatableSelect
                  ref={timeSelectRef as any}
                  styles={{
                    input: (styles) => {
                      return styles;
                    },
                    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                      return {
                        ...styles,
                        backgroundColor: isSelected
                          ? ColorUtil.lignten(themeContext.colors.primary, 20)
                          : isFocused
                          ? ColorUtil.lignten(themeContext.colors.primary, 10)
                          : themeContext.colors.baseColor,
                        color: isSelected || isFocused ? '#FFFFFF' : themeContext.colors.textColor,
                        cursor: isDisabled ? 'not-allowed' : 'default',
                      };
                    },
                    menuPortal: (provided) => ({ ...provided, zIndex: 12000 }),
                    menu: (provided) => ({ ...provided, zIndex: 12000 }),
                  }}
                  openMenuOnFocus
                  components={{
                    Option: (props) => {
                      return (
                        <View
                          ref={props.innerRef}
                          {...props.innerProps}
                          style={
                            {
                              backgroundColor: props.isFocused
                                ? themeContext.colors.subHeader
                                : props.isSelected
                                ? themeContext.colors.header
                                : themeContext.colors.baseColor,
                              height: 34,
                              paddingVertical: 10,
                              paddingHorizontal: 10,
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                              flexDirection: 'row',
                              cursor: 'pointer',
                            } as any
                          }>
                          <Typography
                            variant={TypographyType.Normal}
                            ellipsis={true}
                            style={{
                              fontSize: 16,
                              color: props.isFocused
                                ? themeContext.colors.baseColor
                                : props.isSelected
                                ? themeContext.colors.baseColor
                                : themeContext.colors.textColor,
                            }}>
                            {props.children}
                          </Typography>
                        </View>
                      );
                    },
                  }}
                  isMulti={false}
                  menuPortalTarget={document.body}
                  menuPosition={'fixed'}
                  placeholder={isFocused ? '' : props.label}
                  options={times}
                  noOptionsMessage={() => '選択可能なものがありません'}
                  isSearchable
                  defaultValue={{
                    label: '0:00',
                    value: '0:00',
                  }}
                  formatCreateLabel={(value) =>
                    value ? `「${TimeUtil.formatStringToTime(value)}」` : ''
                  }
                  onFocus={() => setFocusTimeSelect(true)}
                  onBlur={() => setFocusTimeSelect(false)}
                  onMenuOpen={() => setOpenTimeSelect(true)}
                  onMenuClose={() => setOpenTimeSelect(false)}
                  onChange={(item) => {
                    const dateStr = moment(value).format('YYYY/MM/DD');
                    const newValue = moment(
                      `${dateStr} ${TimeUtil.formatStringToTime(item?.value)}`
                    );
                    setValue(newValue);
                    setInputValue(
                      newValue.format(props.mode !== 'date' ? 'YYYY/MM/DD HH:mm' : 'YYYY/MM/DD')
                    );
                  }}
                  value={{
                    label: value ? moment(value).format('HH:mm') : '00:00',
                    value: value ? moment(value).format('HH:mm') : '00:00',
                  }}
                  inputValue={selectInputValue}
                  onInputChange={(newValue) => {
                    if (TimeUtil.isValidInputTime(newValue)) {
                      setSelectInputValue(TimeUtil.stringToTimeWithoutFormat(newValue));
                    }
                  }}
                  onKeyDown={(event) => {
                    switch (event.nativeEvent.key) {
                      case 'Enter':
                        if (!isOpenTimeSelect) {
                          setShowCalendar(false);
                          if (props.onChange) {
                            props.onChange(value);
                          }
                        }
                        break;
                      case 'Escape':
                        setValue(props.initialValue || null);
                        setFocused(false);
                        setDirty(false);
                        if (props.onChange) {
                          props.onChange(props.initialValue || null);
                        }
                        textInputElement.blur();
                        break;
                      case 'ArrowUp':
                        (timeSelectRef as any).current?.focus();
                        break;
                      case 'ArrowDown':
                        (timeSelectRef as any).current?.focus();
                        break;
                      default:
                        break;
                    }
                  }}
                /> */}
              </Form>
            </View>
          )}
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingTop: 10,
              borderTopWidth: 1,
              borderColor: themeContext.colors.separator,
            }}>
            <View>
              <Button
                text={'本日'}
                style={{
                  minWidth: 30,
                  paddingVertical: 2,
                  paddingHorizontal: 5,
                  marginLeft: 10,
                  backgroundColor: 'transparent',
                  borderWidth: 1,
                  borderColor: themeContext.colors.primary,
                  borderRadius: 3,
                }}
                textStyle={{ color: themeContext.colors.primary, fontSize: 14 }}
                disableValidate={true}
                onPress={() => {
                  const newValue = moment(
                    `${moment().format('YYYY/MM/DD')} ${value?.format('HH:mm')}`
                  );
                  setValue(newValue);
                  setDisplayDate(newValue);
                  setInputValue(
                    newValue.format(props.mode !== 'date' ? 'YYYY/MM/DD HH:mm' : 'YYYY/MM/DD')
                  );
                }}
              />
            </View>
            {/* <View style={{ flexDirection: 'row' }}>
              <View style={{ flexDirection: 'column' }}>
                <CaretUpIcon
                  size={12}
                  containerStyle={{ paddingVertical: 0, justifyContent: 'center' }}
                  onPress={() => {
                    const newValue = moment(value).add(1, 'year');
                    setValue(newValue);
                    setDisplayDate(newValue);
                    setInputValue(newValue.format('YYYY/MM/DD HH:mm'));
                  }}
                />
                <Typography
                  variant={TypographyType.Normal}
                  style={{ color: themeContext.colors.description }}>
                  {value ? value.format('YYYY') : ''}
                </Typography>
                <CaretDownIcon
                  size={12}
                  containerStyle={{ paddingVertical: 0, justifyContent: 'center' }}
                  onPress={() => {
                    const newValue = moment(value).add(-1, 'year');
                    setValue(newValue);
                    setDisplayDate(newValue);
                    setInputValue(newValue.format('YYYY/MM/DD HH:mm'));
                  }}
                />
              </View>
              <View style={{ flexDirection: 'column' }}>
                <CaretUpIcon
                  size={12}
                  containerStyle={{ paddingVertical: 0, justifyContent: 'center' }}
                  onPress={() => {
                    const newValue = moment(value).add(1, 'month');
                    setValue(newValue);
                    setDisplayDate(newValue);
                    setInputValue(newValue.format('YYYY/MM/DD HH:mm'));
                  }}
                />
                <Typography
                  variant={TypographyType.Normal}
                  style={{ color: themeContext.colors.description }}>
                  {value ? value.format('/MM') : ''}
                </Typography>
                <CaretDownIcon
                  size={12}
                  containerStyle={{ paddingVertical: 0, justifyContent: 'center' }}
                  onPress={() => {
                    const newValue = moment(value).add(-1, 'month');
                    setValue(newValue);
                    setDisplayDate(newValue);
                    setInputValue(newValue.format('YYYY/MM/DD HH:mm'));
                  }}
                />
              </View>
              <View style={{ flexDirection: 'column' }}>
                <CaretUpIcon
                  size={12}
                  containerStyle={{ paddingVertical: 0, justifyContent: 'center' }}
                  onPress={() => {
                    const newValue = moment(value).add(1, 'day');
                    setValue(newValue);
                    setDisplayDate(newValue);
                    setInputValue(newValue.format('YYYY/MM/DD HH:mm'));
                  }}
                />
                <Typography
                  variant={TypographyType.Normal}
                  style={{ color: themeContext.colors.description }}>
                  {value ? value.format('/DD') : ''}
                </Typography>
                <CaretDownIcon
                  size={12}
                  containerStyle={{ paddingVertical: 0, justifyContent: 'center' }}
                  onPress={() => {
                    const newValue = moment(value).add(-1, 'day');
                    setValue(newValue);
                    setDisplayDate(newValue);
                    setInputValue(newValue.format('YYYY/MM/DD HH:mm'));
                  }}
                />
              </View>
              <View style={{ flexDirection: 'column' }}>
                <CaretUpIcon
                  size={12}
                  containerStyle={{ paddingVertical: 0, justifyContent: 'center' }}
                  onPress={() => {
                    const newValue = moment(value).add(1, 'hour');
                    setValue(newValue);
                    setDisplayDate(newValue);
                    setInputValue(newValue.format('YYYY/MM/DD HH:mm'));
                  }}
                />
                <Typography
                  variant={TypographyType.Normal}
                  style={{ color: themeContext.colors.description }}>
                  {value ? value.format(' HH') : ''}
                </Typography>
                <CaretDownIcon
                  size={12}
                  containerStyle={{ paddingVertical: 0, justifyContent: 'center' }}
                  onPress={() => {
                    const newValue = moment(value).add(-1, 'hour');
                    setValue(newValue);
                    setDisplayDate(newValue);
                    setInputValue(newValue.format('YYYY/MM/DD HH:mm'));
                  }}
                />
              </View>
              <View style={{ flexDirection: 'column' }}>
                <CaretUpIcon
                  size={12}
                  containerStyle={{ paddingVertical: 0, justifyContent: 'center' }}
                  onPress={() => {
                    const newValue = moment(value).add(1, 'minute');
                    setValue(newValue);
                    setDisplayDate(newValue);
                    setInputValue(newValue.format('YYYY/MM/DD HH:mm'));
                  }}
                />
                <Typography
                  variant={TypographyType.Normal}
                  style={{ color: themeContext.colors.description }}>
                  {value ? value.format(':mm') : ''}
                </Typography>
                <CaretDownIcon
                  size={12}
                  containerStyle={{ paddingVertical: 0, justifyContent: 'center' }}
                  onPress={() => {
                    const newValue = moment(value).add(-1, 'minute');
                    setValue(newValue);
                    setDisplayDate(newValue);
                    setInputValue(newValue.format('YYYY/MM/DD HH:mm'));
                  }}
                />
              </View>
            </View> */}
            <View style={{ flexDirection: 'row' }}>
              <Button
                text={'決定'}
                style={{ minWidth: 30, paddingVertical: 2, paddingHorizontal: 5 }}
                textStyle={{ fontSize: 14 }}
                disableValidate={true}
                onPress={() => {
                  setShowCalendar(false);
                  if (props.onChange) {
                    props.onChange(value);
                  }
                }}
              />
              {props.hideClearButton !== true && (
                <Button
                  text={'クリア'}
                  style={{
                    minWidth: 30,
                    paddingVertical: 2,
                    paddingHorizontal: 5,
                    marginLeft: 10,
                    backgroundColor: 'transparent',
                  }}
                  textStyle={{ color: themeContext.colors.primary, fontSize: 14 }}
                  disableValidate={true}
                  onPress={() => {
                    setValue(null);
                    setInputValue('');
                    setShowCalendar(false);
                    if (props.onChange) {
                      props.onChange(null);
                    }
                  }}
                />
              )}
            </View>
          </View>
        </View>
      </Container>
    </>
  );
};

export default DateTimePicker;
