import React, { useContext, useEffect, useState } from 'react';
import { View, ViewStyle } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../../theme';
import { Task } from '../../../../../graphql/api/API';
import TaskUtil from '../../../../../util/TaskUtil';
import useInterval from 'use-interval';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import TimeUtil from '../../../../../util/TimeUtil';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';

interface IEstimateRemindProps {
  task: Task;
  workingTimeSec: number;
  estimatedRemainingWorkingTimeSec: number | undefined;
}

const EstimateRemind = (props: IEstimateRemindProps) => {
  if (props.estimatedRemainingWorkingTimeSec === undefined) {
    return <></>;
  }
  if (props.workingTimeSec === 0) {
    return <></>;
  }
  if (props.task.progressRate === 100) {
    return <></>;
  }
  if (props.task.progressRate === 0) {
    return (
      <Typography
        variant={TypographyType.Normal}
        style={{ alignItems: 'center', justifyContent: 'flex-start', fontSize: 14 }}>
        現在の進捗率を設定してみましょう
      </Typography>
    );
  }
  return (
    <Typography
      variant={TypographyType.Normal}
      style={{ alignItems: 'center', justifyContent: 'flex-start', fontSize: 14 }}>
      あと {TimeUtil.format(Math.ceil(props.estimatedRemainingWorkingTimeSec / 60))}程
      かかると予想されます。
    </Typography>
  );
};

interface IAdviceProps {
  task: Task;
  ideaProgressRate: number | undefined;
  isDelay: boolean;
  isEarlier: boolean;
  isNormal: boolean;
}

const Advice = (props: IAdviceProps) => {
  if (props.task.progressRate === 0) {
    return <></>;
  }
  if (props.ideaProgressRate === undefined || props.ideaProgressRate === 0) {
    return <></>;
  }
  if (props.isDelay) {
    return (
      <Typography
        variant={TypographyType.Normal}
        style={{ alignItems: 'center', justifyContent: 'flex-start', fontSize: 14 }}>
        予定より遅れています
      </Typography>
    );
  }
  if (props.isNormal) {
    return (
      <Typography
        variant={TypographyType.Normal}
        style={{ alignItems: 'center', justifyContent: 'flex-start', fontSize: 14 }}>
        ほぼ予定通りです
      </Typography>
    );
  }
  if (props.isEarlier) {
    return (
      <Typography
        variant={TypographyType.Normal}
        style={{ alignItems: 'center', justifyContent: 'flex-start', fontSize: 14 }}>
        予定より順調です
      </Typography>
    );
  }
  return <></>;
};

interface ITaskProgressBarProps {
  task: Task;
  style?: ViewStyle;
  containerStyle?: ViewStyle;
  showProgress?: boolean;
  showProgressText?: boolean;
  showAdvice?: boolean;
  barHeight?: number;
  darken?: boolean;
}

export const TaskProgressBar = (props: ITaskProgressBarProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [workingTimeSec, setWorkingTimeSec] = useState(0);
  useEffect(() => {
    setWorkingTimeSec(TaskUtil.prepareTotalWorkingTimeSec(props.task));
  }, [props.task]);

  useInterval(() => {
    if (props.task.workingMembers.length > 0) {
      setWorkingTimeSec(TaskUtil.prepareTotalWorkingTimeSec(props.task));
    }
  }, 1000);

  const ideaProgressRate = props.task.estimateTimeSec
    ? Math.floor((workingTimeSec / props.task.estimateTimeSec) * 100)
    : undefined;
  const actualProgressRate = props.task.progressRate;

  const isDelay = ideaProgressRate && ideaProgressRate * 0.8 > actualProgressRate;
  const isEarlier = ideaProgressRate && ideaProgressRate * 1.2 < actualProgressRate;
  const isNormal = !isDelay && !isEarlier;

  // 予想残作業時間を取得
  const estimatedRemainingWorkingTimeSec = props.task.estimateTimeSec
    ? Math.ceil((workingTimeSec / props.task.progressRate) * (100 - props.task.progressRate))
    : undefined;

  return (
    <View
      style={[
        {
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        },
        props.containerStyle,
      ]}>
      {props.showProgressText === true && (
        <Typography
          variant={TypographyType.Normal}
          style={{
            width: 64,
            alignItems: 'center',
            justifyContent: 'center',
            // marginTop: props.showAdvice ? 18 : 0,
          }}>
          {props.task.estimateTimeSec
            ? `${Math.min(Math.floor((workingTimeSec / props.task.estimateTimeSec) * 100), 100)}%`
            : '-'}
        </Typography>
      )}
      <View
        style={{
          flexDirection: 'column',
          width: props.showProgressText === true ? 'calc(100% - 55px)' : '100%',
        }}>
        <View
          style={[
            props.style,
            { flexDirection: 'column', justifyContent: 'center', alignItems: 'center' },
          ]}>
          <View
            style={{
              backgroundColor: props.darken ? '#d4d4d4' : '#F8F8F8',
              width: '100%',
              height: props.barHeight || 4,
              borderBottomWidth: 1,
              borderColor: themeContext.colors.separator,
            }}>
            <View
              style={{
                backgroundColor: props.darken ? '#f1ac65' : '#FBD7B2',
                width: props.task.estimateTimeSec
                  ? `${Math.min(
                      Math.floor((workingTimeSec / props.task.estimateTimeSec) * 100),
                      100
                    )}%`
                  : '0',
                height: props.barHeight || 4,
                position: 'absolute',
                left: 0,
              }}
            />
            <View
              style={{
                backgroundColor: props.darken ? '#e77ea1' : '#EEADC3',
                width:
                  props.task.estimateTimeSec !== undefined &&
                  props.task.estimateTimeSec !== null &&
                  props.task.estimateTimeSec < workingTimeSec
                    ? `${Math.floor(
                        ((workingTimeSec - props.task.estimateTimeSec) / workingTimeSec) * 100
                      )}%`
                    : '0',
                height: props.barHeight || 4,
                position: 'absolute',
                right: 0,
              }}
            />
          </View>
          {props.showProgress !== false && (
            <View style={{ backgroundColor: '#F8F8F8', width: '100%', height: 4 }}>
              <View
                style={{
                  backgroundColor: props.darken ? '#59bcaf' : '#99dcff',
                  width: `${props.task.progressRate}%`,
                  height: props.barHeight || 4,
                  position: 'absolute',
                  left: 0,
                }}
              />
            </View>
          )}
        </View>
        {props.showAdvice && props.task.estimateTimeSec && (
          <>
            <Advice
              task={props.task}
              isDelay={!!isDelay}
              isNormal={isNormal}
              isEarlier={!!isEarlier}
              ideaProgressRate={ideaProgressRate}
            />
            <EstimateRemind
              task={props.task}
              workingTimeSec={workingTimeSec}
              estimatedRemainingWorkingTimeSec={estimatedRemainingWorkingTimeSec}
            />
          </>
        )}
      </View>
    </View>
  );
};

export default React.memo(TaskProgressBar);
