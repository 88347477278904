import React, { useContext, useEffect, useState } from 'react';
import styled, { ThemeContext } from 'styled-components/native';
import { View } from 'react-native';
import { IStyleTheme, IThemePart } from '../../../../../../theme';
import Table from '../../../../../../presentational/molecules/table';
import Typography, { TypographyType } from '../../../../../../presentational/atoms/typography';
import { LoginUserContext } from '../../../../../../../modules/auth/LoginUserContext';
import { useHistory } from 'react-router';
import moment from 'moment-timezone';
import {
  CsvReportItemDefinition,
  JobProgressStatus,
  MyCsvReportsDocument,
  Plan,
  ReportType,
  useCreateMyCsvReportDownloadUrlMutation,
  useCsvReportItemDefinitionsQuery,
  useMyCsvReportsQuery,
  useOrganizationQuery,
  useRequestMyCsvReportMutation,
} from '../../../../../../../graphql/api/API';
import Button from '../../../../../../presentational/atoms/button';
import when from '../../../../../../../lang-extention/When';
import Modal from '../../../../../../presentational/molecules/modal';
import EditableText, { IDateRange } from '../../../../../../presentational/atoms/editable-text';
import DownloadUtil from '../../../../../../../util/DownloadUtil';
import LeftIcon from '../../../../../../presentational/molecules/image-icon/left';
import RightIcon from '../../../../../../presentational/molecules/image-icon/right';
import DownloadIcon from '../../../../../../presentational/molecules/image-icon/download';
import PlanNotAllowedView from '../../../../organisms/plan-not-allowed-view';
import Form from '../../../../../../presentational/atoms/form';
import Input from '../../../../../../presentational/atoms/input';
import Switch from '../../../../../../presentational/atoms/switch';

const Container = styled.View`
  display: flex;
  flex: 1;
  height: calc(100vh - 57px - 48px);
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
  z-index: 0;
`;

interface IDateRangeHeaderProps {
  startDateTime: moment.Moment;
  endDateTime: moment.Moment;
  setStartDateTime: (value: moment.Moment) => void;
  setEndDateTime: (value: moment.Moment) => void;
}

const DateRangeHeader = (props: IDateRangeHeaderProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [startDateTime, setStartDateTime] = useState(props.startDateTime);
  const [endDateTime, setEndDateTime] = useState(props.endDateTime);

  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: 10,
      }}>
      <LeftIcon
        size={30}
        onPress={() => {
          const newStartDateTime = moment(startDateTime).add(-1, 'month').startOf('month');
          const newEndDateTime = moment(endDateTime).add(-1, 'month').endOf('month');
          setStartDateTime(newStartDateTime);
          setEndDateTime(newEndDateTime);
          props.setStartDateTime(newStartDateTime);
          props.setEndDateTime(newEndDateTime);
        }}
      />
      <EditableText
        value={{
          start: startDateTime,
          end: endDateTime,
        }}
        type={'date-range-picker'}
        disableClear={true}
        containerStyle={{ paddingHorizontal: 10 }}
        textStyle={{ minWidth: 200 }}
        onChange={(value) => {
          setStartDateTime((value as IDateRange)!.start);
          setEndDateTime((value as IDateRange)!.end);
          props.setStartDateTime((value as IDateRange)!.start);
          props.setEndDateTime((value as IDateRange)!.end);
        }}
        onBlur={(value) => {
          setStartDateTime((value as IDateRange)!.start);
          setEndDateTime((value as IDateRange)!.end);
          props.setStartDateTime((value as IDateRange)!.start);
          props.setEndDateTime((value as IDateRange)!.end);
        }}
      />
      <RightIcon
        size={30}
        onPress={() => {
          const newStartDateTime = moment(startDateTime).add(1, 'month').startOf('month');
          const newEndDateTime = moment(endDateTime).add(1, 'month').endOf('month');
          setStartDateTime(newStartDateTime);
          setEndDateTime(newEndDateTime);
          props.setStartDateTime(newStartDateTime);
          props.setEndDateTime(newEndDateTime);
        }}
      />
    </View>
  );
};

const MyCsvExportPage = () => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const history = useHistory();
  const [loginUser, _] = useContext(LoginUserContext);
  const [notAllowedPlan, setNotAllowedPlan] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [startDateTime, setStartDateTime] = useState(moment().startOf('month'));
  const [endDateTime, setEndDateTime] = useState(moment().endOf('month'));
  const [includeWorkingData, setIncludeWorkingData] = useState(false);
  const [csvReportItemDefinition, setCsvReportItemDefinition] =
    useState<CsvReportItemDefinition | null>(null);
  const [reportType, setReportType] = useState<ReportType>(ReportType.WorkingHistory);

  const { loading, data } = useMyCsvReportsQuery({
    fetchPolicy: 'network-only',
  });
  const { loading: organizationLoading, data: organizationData } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId,
    },
    fetchPolicy: 'network-only',
  });

  const { loading: csvReportItemDefinitionsLoading, data: csvReportItemDefinitionsData } =
    useCsvReportItemDefinitionsQuery({
      fetchPolicy: 'network-only',
    });

  const [requestReport, __] = useRequestMyCsvReportMutation({
    variables: {
      input: {
        reportType: reportType,
        start: moment(startDateTime).startOf('day').toISOString(),
        end: moment(endDateTime).endOf('day').toISOString(),
        includeWorkingData: includeWorkingData,
        timeZoneOffset: moment().tz(moment.tz.guess()).format('Z'),
        reportItemPrintDefinitionId: csvReportItemDefinition?.id,
      },
    },
    refetchQueries: [
      {
        query: MyCsvReportsDocument,
      },
    ],
  });

  // Basicプランの場合にはCSV出力できないようにする
  useEffect(() => {
    setTimeout(() => {
      if (organizationData?.organization?.plan.code === Plan.Basic) {
        setNotAllowedPlan(true);
      }
    }, 1000);
  }, [organizationData]);

  const [createDownloadUrl] = useCreateMyCsvReportDownloadUrlMutation();

  if (loading || csvReportItemDefinitionsLoading) {
    return <></>;
  }

  return (
    <PlanNotAllowedView isNotAllowedPlan={notAllowedPlan} style={{ height: 'calc(100vh - 105px)' }}>
      <Container style={{ paddingHorizontal: 30 }}>
        <Typography
          variant={TypographyType.Description}
          style={{ fontSize: 14, lineHeight: 22, marginTop: 20, marginLeft: 17 }}>
          作業履歴データをCSVファイルに出力して、ダウンロードすることが出来ます。
        </Typography>
        <View style={{ flexDirection: 'row', marginTop: 30, zIndex: 2 }}>
          <View style={{ width: 150, justifyContent: 'center', alignItems: 'flex-end' }}>
            <Typography variant={TypographyType.Normal} style={{ fontSize: 18 }}>
              対象期間
            </Typography>
          </View>
          <View>
            <DateRangeHeader
              startDateTime={startDateTime}
              endDateTime={endDateTime}
              setStartDateTime={setStartDateTime}
              setEndDateTime={setEndDateTime}
            />
          </View>
        </View>
        <View style={{ flexDirection: 'row', marginTop: 10 }}>
          <View style={{ width: 150, justifyContent: 'center', alignItems: 'flex-end' }}>
            <Typography variant={TypographyType.Normal} style={{ fontSize: 18 }}>
              CSV出力設定
            </Typography>
          </View>
          <View>
            <Form style={{ marginLeft: 20, width: 400 }}>
              <Input
                name={'csvReportItemDefinition'}
                type={'picker'}
                isSearchable={true}
                initialValue={'default'}
                pickerItems={[
                  {
                    label: 'デフォルトの設定',
                    value: 'default',
                  },
                ].concat(
                  csvReportItemDefinitionsData!.csvReportItemDefinitions!.map((definition) => {
                    return {
                      label: definition!.name,
                      value: definition!.id!,
                    };
                  })
                )}
                onChange={(value) => {
                  const targets = csvReportItemDefinitionsData?.csvReportItemDefinitions?.filter(
                    (definition) => definition!.id! === value
                  );
                  if (targets && targets.length > 0) {
                    setCsvReportItemDefinition(targets[0]);
                  } else {
                    setCsvReportItemDefinition(null);
                  }
                }}
              />
            </Form>
          </View>
        </View>
        <View style={{ flexDirection: 'row', marginTop: 20 }}>
          <View style={{ width: 150, justifyContent: 'center', alignItems: 'flex-end' }}>
            <Typography variant={TypographyType.Normal} style={{ fontSize: 18 }}>
              出力対象
            </Typography>
          </View>
          <View>
            <Form style={{ marginLeft: 20, width: 400 }}>
              <Input
                name={'reportType'}
                type={'picker'}
                initialValue={ReportType.WorkingHistory}
                pickerItems={[
                  {
                    label: '作業履歴',
                    value: ReportType.WorkingHistory,
                  },
                  {
                    label: '作業予定',
                    value: ReportType.WorkingSchedule,
                  },
                ]}
                onChange={(value) => {
                  setReportType(value as ReportType);
                }}
              />
            </Form>
          </View>
        </View>
        {reportType === ReportType.WorkingHistory && (
          <View style={{ flexDirection: 'row', marginTop: 10 }}>
            <View style={{ width: 130, justifyContent: 'center', alignItems: 'flex-end' }}>
              <Typography
                variant={TypographyType.Normal}
                style={{ fontSize: 18, textAlign: 'right' }}>
                作業中のデータ
              </Typography>
            </View>
            <View>
              <Form
                style={{
                  marginLeft: 20,
                  width: 400,
                  paddingTop: 20,
                  alignItems: 'flex-start',
                  flexDirection: 'row',
                  gap: 10,
                }}>
                <Switch value={includeWorkingData} onValueChange={setIncludeWorkingData} />
                {includeWorkingData ? (
                  <Typography
                    variant={TypographyType.Normal}
                    style={{ fontSize: 18, textAlign: 'right' }}>
                    含める
                  </Typography>
                ) : (
                  <Typography
                    variant={TypographyType.Normal}
                    style={{ fontSize: 18, textAlign: 'right' }}>
                    含めない
                  </Typography>
                )}
              </Form>
            </View>
          </View>
        )}
        <View style={{ flexDirection: 'row', marginTop: 10 }}>
          <View style={{ width: 150 }}></View>
          <View style={{ marginLeft: 30 }}>
            <Button
              text={'CSVファイルを出力する'}
              style={{
                minWidth: 100,
                marginRight: 10,
                marginVertical: 10,
              }}
              onPress={async () => {
                if (organizationData?.organization?.plan.code === Plan.Basic) {
                  return;
                }
                await requestReport();
                setShowModal(true);
              }}
            />
          </View>
          <Modal
            title={'ファイルの作成を開始します'}
            isShow={showModal}
            onClose={() => {
              setShowModal(false);
            }}>
            <Typography
              variant={TypographyType.Normal}
              style={{ textAlign: 'center', marginVertical: 10 }}>
              ファイルを作成します。少々お待ち下さい。
            </Typography>
            <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
              <Button
                text={'OK'}
                style={{ minWidth: 100, marginRight: 10, marginVertical: 20 }}
                onPress={async () => {
                  setShowModal(false);
                }}
              />
            </View>
          </Modal>
        </View>
        <View style={{ marginTop: 30, paddingLeft: 10 }}>
          {data!.myCsvReports!.length > 0 && (
            <Typography variant={TypographyType.Normal} style={{ fontSize: 18 }}>
              ファイル出力結果一覧
            </Typography>
          )}
          <Table
            height={100}
            style={{ width: '100%' }}
            rowData={data!
              .myCsvReports!.slice()
              .sort((a, b) => {
                return (
                  new Date(b!.requestDateTime).getTime() - new Date(a!.requestDateTime).getTime()
                );
              })
              .map((report) => {
                return {
                  集計期間: (
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}>
                      <View
                        style={{
                          flexDirection: 'column',
                          justifyContent: 'center',
                        }}>
                        <Typography variant={TypographyType.Normal} style={{ fontSize: 14 }}>
                          {`${moment(report!.start).format('YYYY/MM/DD HH:mm')} 〜 ${moment(
                            report!.end
                          ).format('YYYY/MM/DD HH:mm')}`}
                        </Typography>
                      </View>
                    </View>
                  ),
                  出力設定: (
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}>
                      <View
                        style={{
                          flexDirection: 'column',
                          justifyContent: 'center',
                        }}>
                        <Typography variant={TypographyType.Normal} style={{ fontSize: 14 }}>
                          {report!.csvReportItemDefinition?.name || 'デフォルト'}
                        </Typography>
                      </View>
                    </View>
                  ),
                  出力対象: (
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}>
                      <View
                        style={{
                          flexDirection: 'column',
                          justifyContent: 'center',
                        }}>
                        <Typography variant={TypographyType.Normal} style={{ fontSize: 14 }}>
                          {report!.reportType === ReportType.WorkingHistory
                            ? '作業履歴'
                            : '作業予定'}
                        </Typography>
                      </View>
                    </View>
                  ),
                  作業中のデータ: (
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}>
                      <View
                        style={{
                          flexDirection: 'column',
                          justifyContent: 'center',
                        }}>
                        <Typography variant={TypographyType.Normal} style={{ fontSize: 14 }}>
                          {report!.reportType === ReportType.WorkingHistory
                            ? report!.includeWorkingData
                              ? '含める'
                              : '含めない'
                            : '-'}
                        </Typography>
                      </View>
                    </View>
                  ),
                  出力状況: (
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}>
                      <View
                        style={{
                          flexDirection: 'column',
                          justifyContent: 'center',
                        }}>
                        <Typography variant={TypographyType.Normal} style={{ fontSize: 14 }}>
                          {`${when(report!.status)
                            .on(
                              (v) => v === JobProgressStatus.Accepted,
                              () => 'ファイル作成中'
                            )
                            .on(
                              (v) => v === JobProgressStatus.InProgress,
                              () => 'ファイル作成中'
                            )
                            .on(
                              (v) => v === JobProgressStatus.Success,
                              () => 'ファイル作成済み'
                            )
                            .on(
                              (v) => v === JobProgressStatus.Failure,
                              () => 'ファイル作成失敗'
                            )
                            .otherwise(() => '-')}`}
                        </Typography>
                      </View>
                    </View>
                  ),
                  出力依頼日時: (
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}>
                      <View
                        style={{
                          flexDirection: 'column',
                          justifyContent: 'center',
                        }}>
                        <Typography variant={TypographyType.Normal} style={{ fontSize: 14 }}>
                          {`${moment(report!.requestDateTime).format('YYYY/MM/DD HH:mm')}`}
                        </Typography>
                      </View>
                    </View>
                  ),
                  ファイル: (
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}>
                      {report!.status === JobProgressStatus.Success ? (
                        <View
                          style={{
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                          }}>
                          <DownloadIcon
                            size={20}
                            containerStyle={{ marginRight: 5 }}
                            onPress={async () => {
                              const result = await createDownloadUrl({
                                variables: {
                                  id: report?.id!,
                                  isUtf8: true
                                }
                              });
                              if (result.data?.createMyCsvReportDownloadUrl) {
                                const splited = report!.utf8FileUrl!.split('/');
                                const fileName = splited[splited.length - 1];
                                  DownloadUtil.forceDownload(
                                  result.data!.createMyCsvReportDownloadUrl.downloadUrl,
                                  fileName
                                );
                              }
                            }}>
                            <Typography variant={TypographyType.Normal}>
                              ダウンロードする(UTF-8)
                            </Typography>
                          </DownloadIcon>
                          <DownloadIcon
                            size={20}
                            containerStyle={{ marginRight: 5 }}
                            onPress={async () => {
                              const result = await createDownloadUrl({
                                variables: {
                                  id: report?.id!,
                                  isUtf8: false
                                }
                              });
                              if (result.data?.createMyCsvReportDownloadUrl) {
                                const splited = report!.sjisFileUrl!.split('/');
                                const fileName = splited[splited.length - 1];
                                  DownloadUtil.forceDownload(
                                  result.data!.createMyCsvReportDownloadUrl.downloadUrl,
                                  fileName
                                );
                              }
                            }}>
                            <Typography variant={TypographyType.Normal}>
                              ダウンロードする(Shift-JIS)
                            </Typography>
                          </DownloadIcon>
                        </View>
                      ) : (
                        <Typography variant={TypographyType.Normal} style={{ textAlign: 'center' }}>
                          -
                        </Typography>
                      )}
                    </View>
                  ),
                };
              })}
            rowDataStyle={{
              集計期間: {
                width: 'auto',
              },
              出力設定: {
                width: 'auto',
              },
              出力対象: {
                width: 'auto',
              },
              出力状況: {
                width: 'auto',
              },
              出力依頼日時: {
                width: 'auto',
              },
              ファイル: {
                width: 280,
              },
            }}
          />
        </View>
      </Container>
    </PlanNotAllowedView>
  );
};

export default React.memo(MyCsvExportPage);
