import React, { useContext, useMemo, useState } from 'react';
import { View, Image, useWindowDimensions, TouchableOpacity } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../../theme';
import TaskSummary from '../task-summary';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import { useHistory, useLocation, useParams } from 'react-router';
import queryString from 'query-string';
import {
  Organization,
  Plan,
  SortKey,
  SortOrder,
  Task,
  TaskCompleteFilter,
  useMeQuery,
  useOrganizationQuery,
  useSearchTasksQuery,
} from '../../../../../graphql/api/API';
import TaskSummaryForNewTask from '../task-summary-for-new-task';
import VirtualizedFlatList from '../../../../presentational/atoms/list2/virtualized-flat-list';
import useDimensions from 'use-dimensions';
import noData02 from '../../../../../base64Images/no-data/no-data-2';
import noData05 from '../../../../../base64Images/no-data/no-data-5';
import Spinner from '../../../../presentational/atoms/spinner';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import CaretUpIcon from '../../../../presentational/molecules/image-icon/caret-up';
import CaretDownIcon from '../../../../presentational/molecules/image-icon/caret-down';
import CaretSetIcon from '../../../../presentational/molecules/image-icon/caret-set';

const Container = styled.View``;

const TaskListWrapper = styled.View`
  height: calc(100vh - 57px - 90px - 45px);
`;
// 本当はoverflow-xはvisibleにしたいのだが、CSSの仕様で、overflow-yを指定した場合には、xの挙動もそちらで上書きされるため実現できない

const TaskAddButton = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  padding-right: 5px;
  padding-left: 5px;
`;

interface IHeaderProps {
  organization: Organization;
  searchCondition: any;
}

const Header = (props: IHeaderProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const { window } = useDimensions();
  const history = useHistory();
  const windowWidth = window.width;
  return (
    <View
      style={{
        marginTop: 10,
        marginRight: 10,
        paddingRight: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}>
      <View style={{ flexDirection: 'row', marginRight: 10, marginLeft: 50 }}>
        {(props.organization.plan.code === Plan.Business ||
          props.organization.plan.code === Plan.Enterprise) &&
          windowWidth >= 1550 && (
            <TouchableOpacity
              style={{ flexDirection: 'row', width: 150 }}
              onPress={() => {
                history.push(
                  queryString.stringifyUrl({
                    url: `/app/${loginUser!.organizationId}/search-task/list/`,
                    query: Object.assign(props.searchCondition, {
                      sortKey: SortKey.TeamName,
                      sortOrder:
                        props.searchCondition.sortOrder === SortOrder.Asc
                          ? SortOrder.Desc
                          : SortOrder.Asc,
                    }),
                  })
                );
              }}>
              {props.searchCondition.sortKey === SortKey.TeamName ? (
                <>
                  {props.searchCondition.sortOrder === SortOrder.Asc ? (
                    <CaretUpIcon
                      size={10}
                      containerStyle={{ marginLeft: 3 }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: `/app/${loginUser!.organizationId}/search-task/list/`,
                            query: Object.assign(props.searchCondition, {
                              sortKey: SortKey.TeamName,
                              sortOrder: SortOrder.Desc,
                            }),
                          })
                        );
                      }}
                    />
                  ) : (
                    <CaretDownIcon
                      size={10}
                      containerStyle={{ marginLeft: 3 }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: `/app/${loginUser!.organizationId}/search-task/list/`,
                            query: Object.assign(props.searchCondition, {
                              sortKey: SortKey.TeamName,
                              sortOrder: SortOrder.Asc,
                            }),
                          })
                        );
                      }}
                    />
                  )}
                </>
              ) : (
                <CaretSetIcon
                  size={12}
                  containerStyle={{
                    opacity: 0.2,
                    marginLeft: 3,
                  }}
                  onPress={() => {
                    history.push(
                      queryString.stringifyUrl({
                        url: `/app/${loginUser!.organizationId}/search-task/list/`,
                        query: Object.assign(props.searchCondition, {
                          sortKey: SortKey.TeamName,
                          sortOrder: SortOrder.Asc,
                        }),
                      })
                    );
                  }}
                />
              )}
              <Typography
                variant={TypographyType.Normal}
                style={{
                  fontSize: 12,
                  color: themeContext.colors.description,
                  textAlign: 'left',
                }}>
                チーム名
              </Typography>
            </TouchableOpacity>
          )}
        {/* <TouchableOpacity
          style={{ flexDirection: 'row', width: 200 }}
          onPress={() => {
            history.push(
              queryString.stringifyUrl({
                url: `/app/${loginUser!.organizationId}/search-task/list/`,
                query: Object.assign(props.searchCondition, {
                  sortKey: SortKey.ClientName,
                  sortOrder:
                    props.searchCondition.sortOrder === SortOrder.Asc
                      ? SortOrder.Desc
                      : SortOrder.Asc,
                }),
              })
            );
          }}>
          {props.searchCondition.sortKey === SortKey.ClientName ? (
            <>
              {props.searchCondition.sortOrder === SortOrder.Asc ? (
                <CaretUpIcon
                  size={10}
                  containerStyle={{ marginLeft: 3 }}
                  onPress={() => {
                    history.push(
                      queryString.stringifyUrl({
                        url: `/app/${loginUser!.organizationId}/search-task/list/`,
                        query: Object.assign(props.searchCondition, {
                          sortKey: SortKey.ClientName,
                          sortOrder: SortOrder.Desc,
                        }),
                      })
                    );
                  }}
                />
              ) : (
                <CaretDownIcon
                  size={10}
                  containerStyle={{ marginLeft: 3 }}
                  onPress={() => {
                    history.push(
                      queryString.stringifyUrl({
                        url: `/app/${loginUser!.organizationId}/search-task/list/`,
                        query: Object.assign(props.searchCondition, {
                          sortKey: SortKey.ClientName,
                          sortOrder: SortOrder.Asc,
                        }),
                      })
                    );
                  }}
                />
              )}
            </>
          ) : (
            <CaretSetIcon
              size={12}
              containerStyle={{
                opacity: 0.2,
                marginLeft: 3,
              }}
              onPress={() => {
                history.push(
                  queryString.stringifyUrl({
                    url: `/app/${loginUser!.organizationId}/search-task/list/`,
                    query: Object.assign(props.searchCondition, {
                      sortKey: SortKey.ClientName,
                      sortOrder: SortOrder.Asc,
                    }),
                  })
                );
              }}
            />
          )}
          <Typography
            variant={TypographyType.Normal}
            style={{
              fontSize: 12,
              color: themeContext.colors.description,
              textAlign: 'left',
            }}>
            取引先名
          </Typography>
        </TouchableOpacity> */}
        <TouchableOpacity
          style={{ flexDirection: 'row', width: 200 }}
          onPress={() => {
            history.push(
              queryString.stringifyUrl({
                url: `/app/${loginUser!.organizationId}/search-task/list/`,
                query: Object.assign(props.searchCondition, {
                  sortKey: SortKey.ProjectName,
                  sortOrder:
                    props.searchCondition.sortOrder === SortOrder.Asc
                      ? SortOrder.Desc
                      : SortOrder.Asc,
                }),
              })
            );
          }}>
          {props.searchCondition.sortKey === SortKey.ProjectName ? (
            <>
              {props.searchCondition.sortOrder === SortOrder.Asc ? (
                <CaretUpIcon
                  size={10}
                  containerStyle={{ marginLeft: 3 }}
                  onPress={() => {
                    history.push(
                      queryString.stringifyUrl({
                        url: `/app/${loginUser!.organizationId}/search-task/list/`,
                        query: Object.assign(props.searchCondition, {
                          sortKey: SortKey.ProjectName,
                          sortOrder: SortOrder.Desc,
                        }),
                      })
                    );
                  }}
                />
              ) : (
                <CaretDownIcon
                  size={10}
                  containerStyle={{ marginLeft: 3 }}
                  onPress={() => {
                    history.push(
                      queryString.stringifyUrl({
                        url: `/app/${loginUser!.organizationId}/search-task/list/`,
                        query: Object.assign(props.searchCondition, {
                          sortKey: SortKey.ProjectName,
                          sortOrder: SortOrder.Asc,
                        }),
                      })
                    );
                  }}
                />
              )}
            </>
          ) : (
            <CaretSetIcon
              size={12}
              containerStyle={{
                opacity: 0.2,
                marginLeft: 3,
              }}
              onPress={() => {
                history.push(
                  queryString.stringifyUrl({
                    url: `/app/${loginUser!.organizationId}/search-task/list/`,
                    query: Object.assign(props.searchCondition, {
                      sortKey: SortKey.ProjectName,
                      sortOrder: SortOrder.Asc,
                    }),
                  })
                );
              }}
            />
          )}
          <Typography
            variant={TypographyType.Normal}
            style={{
              fontSize: 12,
              color: themeContext.colors.description,
              textAlign: 'left',
            }}>
            プロジェクト名
          </Typography>
        </TouchableOpacity>
        <TouchableOpacity
          style={{ flexDirection: 'row' }}
          onPress={() => {
            history.push(
              queryString.stringifyUrl({
                url: `/app/${loginUser!.organizationId}/search-task/list/`,
                query: Object.assign(props.searchCondition, {
                  sortKey: SortKey.Title,
                  sortOrder:
                    props.searchCondition.sortOrder === SortOrder.Asc
                      ? SortOrder.Desc
                      : SortOrder.Asc,
                }),
              })
            );
          }}>
          {props.searchCondition.sortKey === SortKey.Title ? (
            <>
              {props.searchCondition.sortOrder === SortOrder.Asc ? (
                <CaretUpIcon
                  size={10}
                  containerStyle={{ marginLeft: 3 }}
                  onPress={() => {
                    history.push(
                      queryString.stringifyUrl({
                        url: `/app/${loginUser!.organizationId}/search-task/list/`,
                        query: Object.assign(props.searchCondition, {
                          sortKey: SortKey.Title,
                          sortOrder: SortOrder.Desc,
                        }),
                      })
                    );
                  }}
                />
              ) : (
                <CaretDownIcon
                  size={10}
                  containerStyle={{ marginLeft: 3 }}
                  onPress={() => {
                    history.push(
                      queryString.stringifyUrl({
                        url: `/app/${loginUser!.organizationId}/search-task/list/`,
                        query: Object.assign(props.searchCondition, {
                          sortKey: SortKey.Title,
                          sortOrder: SortOrder.Asc,
                        }),
                      })
                    );
                  }}
                />
              )}
            </>
          ) : (
            <CaretSetIcon
              size={12}
              containerStyle={{
                opacity: 0.2,
                marginLeft: 3,
              }}
              onPress={() => {
                history.push(
                  queryString.stringifyUrl({
                    url: `/app/${loginUser!.organizationId}/search-task/list/`,
                    query: Object.assign(props.searchCondition, {
                      sortKey: SortKey.Title,
                      sortOrder: SortOrder.Asc,
                    }),
                  })
                );
              }}
            />
          )}
          <Typography
            variant={TypographyType.Normal}
            style={{ fontSize: 12, color: themeContext.colors.description }}>
            タスク名
          </Typography>
        </TouchableOpacity>
      </View>
      <View style={{ flexDirection: 'row' }}>
        {(props.organization.plan.code === Plan.Business ||
          props.organization.plan.code === Plan.Enterprise) && (
          <TouchableOpacity
            style={{ flexDirection: 'row', width: 55 }}
            onPress={() => {
              history.push(
                queryString.stringifyUrl({
                  url: `/app/${loginUser!.organizationId}/search-task/list/`,
                  query: Object.assign(props.searchCondition, {
                    sortKey: SortKey.Assigner,
                    sortOrder:
                      props.searchCondition.sortOrder === SortOrder.Asc
                        ? SortOrder.Desc
                        : SortOrder.Asc,
                  }),
                })
              );
            }}>
            {props.searchCondition.sortKey === SortKey.Assigner ? (
              <>
                {props.searchCondition.sortOrder === SortOrder.Asc ? (
                  <CaretUpIcon
                    size={10}
                    containerStyle={{ marginLeft: 3 }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: `/app/${loginUser!.organizationId}/search-task/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: SortKey.Assigner,
                            sortOrder: SortOrder.Desc,
                          }),
                        })
                      );
                    }}
                  />
                ) : (
                  <CaretDownIcon
                    size={10}
                    containerStyle={{ marginLeft: 3 }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: `/app/${loginUser!.organizationId}/search-task/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: SortKey.Assigner,
                            sortOrder: SortOrder.Asc,
                          }),
                        })
                      );
                    }}
                  />
                )}
              </>
            ) : (
              <CaretSetIcon
                size={12}
                containerStyle={{
                  opacity: 0.2,
                  marginLeft: 3,
                }}
                onPress={() => {
                  history.push(
                    queryString.stringifyUrl({
                      url: `/app/${loginUser!.organizationId}/search-task/list/`,
                      query: Object.assign(props.searchCondition, {
                        sortKey: SortKey.Assigner,
                        sortOrder: SortOrder.Asc,
                      }),
                    })
                  );
                }}
              />
            )}
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontSize: 12,
                color: themeContext.colors.description,
                textAlign: 'center',
              }}>
              担当者
            </Typography>
          </TouchableOpacity>
        )}
        <TouchableOpacity
          style={{ flexDirection: 'row', width: 70 }}
          onPress={() => {
            history.push(
              queryString.stringifyUrl({
                url: `/app/${loginUser!.organizationId}/search-task/list/`,
                query: Object.assign(props.searchCondition, {
                  sortKey: SortKey.Priority,
                  sortOrder:
                    props.searchCondition.sortOrder === SortOrder.Asc
                      ? SortOrder.Desc
                      : SortOrder.Asc,
                }),
              })
            );
          }}>
          {props.searchCondition.sortKey === SortKey.Priority ? (
            <>
              {props.searchCondition.sortOrder === SortOrder.Asc ? (
                <CaretUpIcon
                  size={10}
                  containerStyle={{ marginLeft: 3 }}
                  onPress={() => {
                    history.push(
                      queryString.stringifyUrl({
                        url: `/app/${loginUser!.organizationId}/search-task/list/`,
                        query: Object.assign(props.searchCondition, {
                          sortKey: SortKey.Priority,
                          sortOrder: SortOrder.Desc,
                        }),
                      })
                    );
                  }}
                />
              ) : (
                <CaretDownIcon
                  size={10}
                  containerStyle={{ marginLeft: 3 }}
                  onPress={() => {
                    history.push(
                      queryString.stringifyUrl({
                        url: `/app/${loginUser!.organizationId}/search-task/list/`,
                        query: Object.assign(props.searchCondition, {
                          sortKey: SortKey.Priority,
                          sortOrder: SortOrder.Asc,
                        }),
                      })
                    );
                  }}
                />
              )}
            </>
          ) : (
            <CaretSetIcon
              size={12}
              containerStyle={{
                opacity: 0.2,
                marginLeft: 3,
              }}
              onPress={() => {
                history.push(
                  queryString.stringifyUrl({
                    url: `/app/${loginUser!.organizationId}/search-task/list/`,
                    query: Object.assign(props.searchCondition, {
                      sortKey: SortKey.Priority,
                      sortOrder: SortOrder.Asc,
                    }),
                  })
                );
              }}
            />
          )}
          <Typography
            variant={TypographyType.Normal}
            style={{
              fontSize: 12,
              color: themeContext.colors.description,
              textAlign: 'center',
            }}>
            優先度
          </Typography>
        </TouchableOpacity>
        {windowWidth > 1500 && (
          <TouchableOpacity
            style={{ flexDirection: 'row', width: 90 }}
            onPress={() => {
              history.push(
                queryString.stringifyUrl({
                  url: `/app/${loginUser!.organizationId}/search-task/list/`,
                  query: Object.assign(props.searchCondition, {
                    sortKey: SortKey.ScheduledStartDate,
                    sortOrder:
                      props.searchCondition.sortOrder === SortOrder.Asc
                        ? SortOrder.Desc
                        : SortOrder.Asc,
                  }),
                })
              );
            }}>
            {props.searchCondition.sortKey === SortKey.ScheduledStartDate ? (
              <>
                {props.searchCondition.sortOrder === SortOrder.Asc ? (
                  <CaretUpIcon
                    size={10}
                    containerStyle={{ marginLeft: 3 }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: `/app/${loginUser!.organizationId}/search-task/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: SortKey.ScheduledStartDate,
                            sortOrder: SortOrder.Desc,
                          }),
                        })
                      );
                    }}
                  />
                ) : (
                  <CaretDownIcon
                    size={10}
                    containerStyle={{ marginLeft: 3 }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: `/app/${loginUser!.organizationId}/search-task/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: SortKey.ScheduledStartDate,
                            sortOrder: SortOrder.Asc,
                          }),
                        })
                      );
                    }}
                  />
                )}
              </>
            ) : (
              <CaretSetIcon
                size={12}
                containerStyle={{
                  opacity: 0.2,
                  marginLeft: 3,
                }}
                onPress={() => {
                  history.push(
                    queryString.stringifyUrl({
                      url: `/app/${loginUser!.organizationId}/search-task/list/`,
                      query: Object.assign(props.searchCondition, {
                        sortKey: SortKey.ScheduledStartDate,
                        sortOrder: SortOrder.Asc,
                      }),
                    })
                  );
                }}
              />
            )}
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontSize: 12,
                color: themeContext.colors.description,
                textAlign: 'center',
              }}>
              開始予定日
            </Typography>
          </TouchableOpacity>
        )}
        {windowWidth > 1400 && (
          <TouchableOpacity
            style={{ flexDirection: 'row', width: 75 }}
            onPress={() => {
              history.push(
                queryString.stringifyUrl({
                  url: `/app/${loginUser!.organizationId}/search-task/list/`,
                  query: Object.assign(props.searchCondition, {
                    sortKey: SortKey.ScheduledEndDate,
                    sortOrder:
                      props.searchCondition.sortOrder === SortOrder.Asc
                        ? SortOrder.Desc
                        : SortOrder.Asc,
                  }),
                })
              );
            }}>
            {props.searchCondition.sortKey === SortKey.ScheduledEndDate ? (
              <>
                {props.searchCondition.sortOrder === SortOrder.Asc ? (
                  <CaretUpIcon
                    size={10}
                    containerStyle={{ marginLeft: 3 }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: `/app/${loginUser!.organizationId}/search-task/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: SortKey.ScheduledEndDate,
                            sortOrder: SortOrder.Desc,
                          }),
                        })
                      );
                    }}
                  />
                ) : (
                  <CaretDownIcon
                    size={10}
                    containerStyle={{ marginLeft: 3 }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: `/app/${loginUser!.organizationId}/search-task/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: SortKey.ScheduledEndDate,
                            sortOrder: SortOrder.Asc,
                          }),
                        })
                      );
                    }}
                  />
                )}
              </>
            ) : (
              <CaretSetIcon
                size={12}
                containerStyle={{
                  opacity: 0.2,
                  marginLeft: 3,
                }}
                onPress={() => {
                  history.push(
                    queryString.stringifyUrl({
                      url: `/app/${loginUser!.organizationId}/search-task/list/`,
                      query: Object.assign(props.searchCondition, {
                        sortKey: SortKey.ScheduledEndDate,
                        sortOrder: SortOrder.Asc,
                      }),
                    })
                  );
                }}
              />
            )}
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontSize: 12,
                color: themeContext.colors.description,
                textAlign: 'center',
              }}>
              〆切日
            </Typography>
          </TouchableOpacity>
        )}
        <TouchableOpacity
          style={{ flexDirection: 'row', width: 60 }}
          onPress={() => {
            history.push(
              queryString.stringifyUrl({
                url: `/app/${loginUser!.organizationId}/search-task/list/`,
                query: Object.assign(props.searchCondition, {
                  sortKey: SortKey.ProgressRate,
                  sortOrder:
                    props.searchCondition.sortOrder === SortOrder.Asc
                      ? SortOrder.Desc
                      : SortOrder.Asc,
                }),
              })
            );
          }}>
          {props.searchCondition.sortKey === SortKey.ProgressRate ? (
            <>
              {props.searchCondition.sortOrder === SortOrder.Asc ? (
                <CaretUpIcon
                  size={10}
                  containerStyle={{ marginLeft: 3 }}
                  onPress={() => {
                    history.push(
                      queryString.stringifyUrl({
                        url: `/app/${loginUser!.organizationId}/search-task/list/`,
                        query: Object.assign(props.searchCondition, {
                          sortKey: SortKey.ProgressRate,
                          sortOrder: SortOrder.Desc,
                        }),
                      })
                    );
                  }}
                />
              ) : (
                <CaretDownIcon
                  size={10}
                  containerStyle={{ marginLeft: 3 }}
                  onPress={() => {
                    history.push(
                      queryString.stringifyUrl({
                        url: `/app/${loginUser!.organizationId}/search-task/list/`,
                        query: Object.assign(props.searchCondition, {
                          sortKey: SortKey.ProgressRate,
                          sortOrder: SortOrder.Asc,
                        }),
                      })
                    );
                  }}
                />
              )}
            </>
          ) : (
            <CaretSetIcon
              size={12}
              containerStyle={{
                opacity: 0.2,
                marginLeft: 3,
              }}
              onPress={() => {
                history.push(
                  queryString.stringifyUrl({
                    url: `/app/${loginUser!.organizationId}/search-task/list/`,
                    query: Object.assign(props.searchCondition, {
                      sortKey: SortKey.ProgressRate,
                      sortOrder: SortOrder.Asc,
                    }),
                  })
                );
              }}
            />
          )}
          <Typography
            variant={TypographyType.Normal}
            style={{
              fontSize: 12,
              color: themeContext.colors.description,
              textAlign: 'center',
            }}>
            進捗率
          </Typography>
        </TouchableOpacity>
        <TouchableOpacity
          style={{ flexDirection: 'row', width: 95 }}
          onPress={() => {
            history.push(
              queryString.stringifyUrl({
                url: `/app/${loginUser!.organizationId}/search-task/list/`,
                query: Object.assign(props.searchCondition, {
                  sortKey: SortKey.WorkingTimeSec,
                  sortOrder:
                    props.searchCondition.sortOrder === SortOrder.Asc
                      ? SortOrder.Desc
                      : SortOrder.Asc,
                }),
              })
            );
          }}>
          {props.searchCondition.sortKey === SortKey.WorkingTimeSec ? (
            <>
              {props.searchCondition.sortOrder === SortOrder.Asc ? (
                <CaretUpIcon
                  size={10}
                  containerStyle={{ marginLeft: 3 }}
                  onPress={() => {
                    history.push(
                      queryString.stringifyUrl({
                        url: `/app/${loginUser!.organizationId}/search-task/list/`,
                        query: Object.assign(props.searchCondition, {
                          sortKey: SortKey.WorkingTimeSec,
                          sortOrder: SortOrder.Desc,
                        }),
                      })
                    );
                  }}
                />
              ) : (
                <CaretDownIcon
                  size={10}
                  containerStyle={{ marginLeft: 3 }}
                  onPress={() => {
                    history.push(
                      queryString.stringifyUrl({
                        url: `/app/${loginUser!.organizationId}/search-task/list/`,
                        query: Object.assign(props.searchCondition, {
                          sortKey: SortKey.WorkingTimeSec,
                          sortOrder: SortOrder.Asc,
                        }),
                      })
                    );
                  }}
                />
              )}
            </>
          ) : (
            <CaretSetIcon
              size={12}
              containerStyle={{
                opacity: 0.2,
                marginLeft: 3,
              }}
              onPress={() => {
                history.push(
                  queryString.stringifyUrl({
                    url: `/app/${loginUser!.organizationId}/search-task/list/`,
                    query: Object.assign(props.searchCondition, {
                      sortKey: SortKey.WorkingTimeSec,
                      sortOrder: SortOrder.Asc,
                    }),
                  })
                );
              }}
            />
          )}
          <Typography
            variant={TypographyType.Normal}
            style={{
              fontSize: 12,
              color: themeContext.colors.description,
              textAlign: 'center',
            }}>
            作業時間
          </Typography>
        </TouchableOpacity>
        {windowWidth > 1400 && (
          <TouchableOpacity
            style={{ flexDirection: 'row', width: 80 }}
            onPress={() => {
              history.push(
                queryString.stringifyUrl({
                  url: `/app/${loginUser!.organizationId}/search-task/list/`,
                  query: Object.assign(props.searchCondition, {
                    sortKey: SortKey.EstimateWorkingTimeSec,
                    sortOrder:
                      props.searchCondition.sortOrder === SortOrder.Asc
                        ? SortOrder.Desc
                        : SortOrder.Asc,
                  }),
                })
              );
            }}>
            {props.searchCondition.sortKey === SortKey.EstimateWorkingTimeSec ? (
              <>
                {props.searchCondition.sortOrder === SortOrder.Asc ? (
                  <CaretUpIcon
                    size={10}
                    containerStyle={{ marginLeft: 3 }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: `/app/${loginUser!.organizationId}/search-task/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: SortKey.EstimateWorkingTimeSec,
                            sortOrder: SortOrder.Desc,
                          }),
                        })
                      );
                    }}
                  />
                ) : (
                  <CaretDownIcon
                    size={10}
                    containerStyle={{ marginLeft: 3 }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: `/app/${loginUser!.organizationId}/search-task/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: SortKey.EstimateWorkingTimeSec,
                            sortOrder: SortOrder.Asc,
                          }),
                        })
                      );
                    }}
                  />
                )}
              </>
            ) : (
              <CaretSetIcon
                size={12}
                containerStyle={{
                  opacity: 0.2,
                  marginLeft: 3,
                }}
                onPress={() => {
                  history.push(
                    queryString.stringifyUrl({
                      url: `/app/${loginUser!.organizationId}/search-task/list/`,
                      query: Object.assign(props.searchCondition, {
                        sortKey: SortKey.EstimateWorkingTimeSec,
                        sortOrder: SortOrder.Asc,
                      }),
                    })
                  );
                }}
              />
            )}
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontSize: 12,
                color: themeContext.colors.description,
                textAlign: 'center',
              }}>
              見積時間
            </Typography>
          </TouchableOpacity>
        )}
        {windowWidth > 1600 && (
          <Typography
            variant={TypographyType.Normal}
            style={{
              fontSize: 12,
              color: themeContext.colors.description,
              textAlign: 'center',
              paddingHorizontal: 5,
              width: 100,
            }}>
            予想見積時間
          </Typography>
        )}
        {windowWidth > 1600 && (
          <TouchableOpacity
            style={{ flexDirection: 'row', width: 100 }}
            onPress={() => {
              history.push(
                queryString.stringifyUrl({
                  url: `/app/${loginUser!.organizationId}/search-task/list/`,
                  query: Object.assign(props.searchCondition, {
                    sortKey: SortKey.DeviationRate,
                    sortOrder:
                      props.searchCondition.sortOrder === SortOrder.Asc
                        ? SortOrder.Desc
                        : SortOrder.Asc,
                  }),
                })
              );
            }}>
            {props.searchCondition.sortKey === SortKey.DeviationRate ? (
              <>
                {props.searchCondition.sortOrder === SortOrder.Asc ? (
                  <CaretUpIcon
                    size={10}
                    containerStyle={{ marginLeft: 3 }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: `/app/${loginUser!.organizationId}/search-task/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: SortKey.DeviationRate,
                            sortOrder: SortOrder.Desc,
                          }),
                        })
                      );
                    }}
                  />
                ) : (
                  <CaretDownIcon
                    size={10}
                    containerStyle={{ marginLeft: 3 }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: `/app/${loginUser!.organizationId}/search-task/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: SortKey.DeviationRate,
                            sortOrder: SortOrder.Asc,
                          }),
                        })
                      );
                    }}
                  />
                )}
              </>
            ) : (
              <CaretSetIcon
                size={12}
                containerStyle={{
                  opacity: 0.2,
                  marginLeft: 3,
                }}
                onPress={() => {
                  history.push(
                    queryString.stringifyUrl({
                      url: `/app/${loginUser!.organizationId}/search-task/list/`,
                      query: Object.assign(props.searchCondition, {
                        sortKey: SortKey.DeviationRate,
                        sortOrder: SortOrder.Asc,
                      }),
                    })
                  );
                }}
              />
            )}
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontSize: 12,
                color: themeContext.colors.description,
                textAlign: 'center',
              }}>
              見積の乖離率
            </Typography>
          </TouchableOpacity>
        )}
        <Typography
          variant={TypographyType.Normal}
          style={{
            fontSize: 12,
            color: themeContext.colors.description,
            paddingHorizontal: 5,
            width: 40,
            textAlign: 'center',
          }}>
          完了
        </Typography>
      </View>
    </View>
  );
};

interface ITaskListInnerProps {
  organizationId: string;
  teamId: string;
  projectId: string;
  searchCondition: any;
}

const TaskListInner = React.memo((props: ITaskListInnerProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const history = useHistory();
  const { search } = useLocation();
  const [showNewTaskTop, setShowNewTaskTop] = useState(false);
  const [showCreateTasksLoading, setShowCreateTasksLoading] = useState(false);
  const { width: windowWidth } = useWindowDimensions();
  const { loading, data, fetchMore } = useSearchTasksQuery({
    variables: {
      input: {
        title: props.searchCondition?.text || props.searchCondition?.title,
        teamIdList: !props.searchCondition?.teamIdList
          ? []
          : Array.isArray(props.searchCondition!.teamIdList)
          ? props.searchCondition!.teamIdList
          : [props.searchCondition!.teamIdList],
        clientIdList: !props.searchCondition?.clientIdList
          ? []
          : Array.isArray(props.searchCondition!.clientIdList)
          ? props.searchCondition!.clientIdList
          : [props.searchCondition!.clientIdList],
        projectIdList: !props.searchCondition?.projectIdList
          ? []
          : Array.isArray(props.searchCondition!.projectIdList)
          ? props.searchCondition!.projectIdList
          : [props.searchCondition!.projectIdList],
        taskStatusIdList: props.searchCondition?.taskStatusId || [],
        assignedMemberIdList: props.searchCondition?.assignedMemberIdList
          ? props.searchCondition!.assignedMemberIdList
          : [],
        scheduledStartDateTime: {
          from: props.searchCondition?.scheduledStartDateTimeFrom || null,
          to: props.searchCondition?.scheduledStartDateTimeTo || null,
        },
        scheduledEndDateTime: {
          from: props.searchCondition?.scheduledEndDateTimeFrom || null,
          to: props.searchCondition?.scheduledEndDateTimeTo || null,
        },
        workingTimeSec: {
          from: props.searchCondition?.workingTimeSecFrom || null,
          to: props.searchCondition?.workingTimeSecTo || null,
        },
        estimateWorkingTimeSec: {
          from: props.searchCondition?.estimateWorkingTimeSecFrom || null,
          to: props.searchCondition?.estimateWorkingTimeSecTo || null,
        },
        deviationRate: {
          from: props.searchCondition?.deviationRateFrom || null,
          to: props.searchCondition?.deviationRateTo || null,
        },
        completeCondition:
          props.searchCondition?.completeCondition || TaskCompleteFilter.Incomplete,
        sortKey: props.searchCondition?.sortKey,
        sortOrder: props.searchCondition?.sortOrder,
      },
      offset: 0,
      limit: 50,
    },
    fetchPolicy: 'network-only',
  });
  const logimMemberResult = useMeQuery();
  const fetchOrganization = useOrganizationQuery({
    variables: {
      id: props.organizationId,
    },
  });

  if (fetchOrganization.loading) {
    return <></>;
  }

  if (
    Object.keys(props.searchCondition).length === 0 ||
    (Object.keys(props.searchCondition).length === 1 &&
      Object.keys(props.searchCondition)[0] === 'text' &&
      props.searchCondition.text.trim().length === 0) ||
    (Object.keys(props.searchCondition).length === 1 &&
      Object.keys(props.searchCondition)[0] === 'title' &&
      props.searchCondition.title.trim().length === 0)
  ) {
    return (
      <TaskListWrapper>
        <Header
          organization={fetchOrganization.data!.organization!}
          searchCondition={props.searchCondition}
        />
        <View
          style={{
            height: 'calc(100% - 150px)',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Image
            source={{ uri: noData02 }}
            resizeMode="contain"
            style={{
              width: 350,
              height: 200,
              opacity: 0.7,
            }}
          />
          <Typography
            variant={TypographyType.Normal}
            style={{ color: themeContext.colors.description, textAlign: 'center' }}>
            {`タスクをお探しですか？${'\n'}検索条件を指定してみてください。`}
          </Typography>
        </View>
      </TaskListWrapper>
    );
  }

  return (
    <Spinner
      loading={logimMemberResult.loading || !logimMemberResult.data || fetchOrganization.loading}
      lazyMilliSeconds={500}>
      <TaskListWrapper>
        <Header
          organization={fetchOrganization.data!.organization!}
          searchCondition={props.searchCondition}
        />
        {showNewTaskTop && (
          <TaskSummaryForNewTask
            organization={fetchOrganization.data!.organization!}
            setShowNewTask={setShowNewTaskTop}
            loginMember={logimMemberResult.data!.me!}
            showTeamName={
              fetchOrganization.data!.organization!.plan.code === Plan.Business ||
              fetchOrganization.data!.organization!.plan.code === Plan.Enterprise
            }
            showProjectName={true}
            setShowCreateTasksLoading={setShowCreateTasksLoading}
          />
        )}
        {!loading && (data?.searchTasks || []).length > 0 ? (
          <>
            <Spinner loading={showCreateTasksLoading} />
            <VirtualizedFlatList
              style={{
                height:
                  windowWidth >= 2400
                    ? 'calc(100vh - 57px - 150px)'
                    : 'calc(100vh - 57px - 150px - 85px)',
              }}
              items={data?.searchTasks || []}
              renderItem={(item, index) => {
                if (!logimMemberResult?.data?.me) {
                  return <></>;
                }
                return (
                  <TaskSummary
                    task={item! as Task}
                    organization={fetchOrganization.data!.organization!}
                    showTeamName={
                      fetchOrganization.data!.organization!.plan.code === Plan.Business ||
                      fetchOrganization.data!.organization!.plan.code === Plan.Enterprise
                    }
                    showProjectName={true}
                    showClientName
                    showProgressDeviationRate={true}
                    me={logimMemberResult.data.me}
                  />
                );
              }}
              getKey={(task) => (task as Task).id!.toString()}
              itemHeight={60}
              onPress={(task) => {
                if (location.hash.indexOf('/board/') !== -1) {
                  history.push(
                    `/app/${props.organizationId}/search-task/board/task/${(task as Task)
                      .id!}/detail/` + search
                  );
                } else if (location.hash.indexOf('/schedule/') !== -1) {
                  history.push(
                    `/app/${props.organizationId}/search-task/schedule/task/${(task as Task)
                      .id!}/detail/` + search
                  );
                } else {
                  history.push(
                    `/app/${props.organizationId}/search-task/list/task/${(task as Task)
                      .id!}/detail/` + search
                  );
                }
              }}
              virticalDraggable={false}
              onEndReached={() =>
                fetchMore({
                  variables: {
                    offset: data!.searchTasks!.length,
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;
                    return Object.assign({}, prev, {
                      searchTasks: [
                        ...(prev.searchTasks || []),
                        ...(fetchMoreResult.searchTasks || []),
                      ],
                    });
                  },
                })
              }
            />
          </>
        ) : loading ? (
          <></>
        ) : (
          <View
            style={{
              height:
                windowWidth >= 2400
                  ? 'calc(100vh - 57px - 150px)'
                  : 'calc(100vh - 57px - 150px - 85px)',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Image
              source={{ uri: noData05 }}
              resizeMode="contain"
              style={{
                width: 350,
                height: 200,
                opacity: 0.7,
              }}
            />
            <Typography
              variant={TypographyType.Normal}
              style={{ color: themeContext.colors.description, textAlign: 'center' }}>
              {`ご指定の検索条件に一致するタスクが見つかりませんでした。${'\n'}他の条件を指定してみてください。`}
            </Typography>
          </View>
        )}
      </TaskListWrapper>
    </Spinner>
  );
});

interface ITaskListParam {
  teamId: string;
  projectId: string;
  organizationId: string;
}

const SearchTaskList = () => {
  const params = useParams<ITaskListParam>();
  const { search } = useLocation();
  const searchCondition = queryString.parse(search);
  return (
    <Container>
      <TaskListInner
        organizationId={params.organizationId}
        teamId={params.teamId}
        projectId={params.projectId}
        searchCondition={searchCondition}
      />
    </Container>
  );
};

export default React.memo(SearchTaskList);
