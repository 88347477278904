import React, { useContext, useState } from 'react';
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../../theme';
import LoginUtil from '../../../../../modules/auth/LoginUtil';
import {
  Client,
  ClientByCodeDocument,
  ClientByCodeQuery,
  ClientByCodeQueryVariables,
  OrganizationClientsDocument,
  useRegisterClientMutation,
} from '../../../../../graphql/api/API';
import Form from '../../../../presentational/atoms/form';
import Input from '../../../../presentational/atoms/input';
import { View } from 'react-native';
import Button from '../../../../presentational/atoms/button';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import { useLazyQueryPromise } from '../../../../../graphql/extention/useLazyQueryPromise';

const Container = styled.View``;

interface IProps {
  onComplete: (client: Client) => void;
  onCancel: () => void;
}

const ClientCreateDialog = (props: IProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [name, setName] = useState('');
  const [code, setCode] = useState('');

  const [clientCodeErrorMessage, setClientCodeErrorMessage] = useState<string | null>(null);
  const fetchClientByCode = useLazyQueryPromise<ClientByCodeQuery, ClientByCodeQueryVariables>(
    ClientByCodeDocument
  );
  const [registerClient, _] = useRegisterClientMutation({
    variables: {
      organizationId: loginUser!.organizationId!,
      input: {
        name: name,
        code: code,
      },
    },
    update: (cache, result) => {
      cache.modify({
        fields: {
          organizationClients(existing = []) {
            const newClient = cache.writeQuery({
              data: result.data?.registerClient,
              query: OrganizationClientsDocument,
            });
            return [...existing, newClient];
          },
        },
      });
    },
  });

  return (
    <Container>
      <Form style={{ minWidth: 400 }}>
        <View>
          <Typography
            variant={TypographyType.Description}
            style={{
              fontSize: 16,
              lineHeight: 30,
            }}>
            取引先名称
          </Typography>
          <Input
            name={'clientName'}
            focus={true}
            onChange={(value) => setName(value)}
            onPressEnter={async () => {
              const result = await registerClient();
              props.onComplete(result.data!.registerClient!);
            }}
            validate={{
              required: {
                value: true,
                message: '新しい取引先の名前を入力してください',
              },
              maxLength: {
                value: 100,
                message: '100文字以内で入力してください',
              },
            }}
          />
        </View>
        <View>
          <Typography
            variant={TypographyType.Description}
            style={{
              fontSize: 16,
              lineHeight: 30,
            }}>
            取引先管理コード
          </Typography>
          <Input
            type={'text'}
            name={'clientCode'}
            value={code || ''}
            onChange={setCode}
            validate={{
              maxLength: {
                value: 50,
                message: '50文字以内で入力してください',
              },
              pattern: {
                value: /^[a-zA-Z0-9_-]*$/i,
                message: '英数字・半角ハイフン・アンダースコアのみ入力可能です。',
              },
            }}
            additionalErrorMessage={clientCodeErrorMessage}
            containerStyle={{ marginTop: 2 }}
          />
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 20 }}>
          <Button
            text={'登録する'}
            completeText={'登録しました'}
            style={{
              minWidth: 100,
              marginRight: 10,
            }}
            onPress={async () => {
              setClientCodeErrorMessage(null);
              if (code && code.trim().length > 0) {
                const existClient = await fetchClientByCode({
                  code: code,
                });

                if (existClient.data.clientByCode?.id) {
                  setClientCodeErrorMessage('すでに同じ管理コードの取引先が存在しています');
                  return;
                }
              }
              setClientCodeErrorMessage(null);

              const result = await registerClient();
              props.onComplete(result.data!.registerClient!);
            }}
          />
          <Button
            text={'キャンセル'}
            style={{
              minWidth: 100,
              marginRight: 10,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.primary }}
            disableValidate={true}
            onPress={async () => {
              props.onCancel();
            }}
          />
        </View>
      </Form>
    </Container>
  );
};

export default ClientCreateDialog;
