import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import { View, Image, TouchableOpacity } from 'react-native';
import Typography, { TypographyType } from '../../../../../presentational/atoms/typography';
import Modal from '../../../../../presentational/molecules/modal';
import { IStyleTheme, IThemePart } from '../../../../../theme';
import {
  Tag,
  useOrganizationTagsQuery,
  OrganizationTagsDocument,
  useUpdateTagMutation,
  useDeleteTagMutation,
  useTagQuery,
  useCreateTagMutation,
  useTagTasksQuery,
  Task,
  useOrganizationQuery,
  Plan,
  TagSortKey,
  SortOrder,
  useUpdateTagSortNoMutation,
} from '../../../../../../graphql/api/API';
import { LoginUserContext } from '../../../../../../modules/auth/LoginUserContext';
import Form from '../../../../../presentational/atoms/form';
import Input from '../../../../../presentational/atoms/input';
import Button from '../../../../../presentational/atoms/button';
import { Route, Switch, useHistory, useParams } from 'react-router';
import VirtualizedFlatList, {
  GlobalDragContextProvider,
} from '../../../../../presentational/atoms/list2/virtualized-flat-list';
import noData01 from '../../../../../../base64Images/no-data/no-data-1';
import noData03 from '../../../../../../base64Images/no-data/no-data-3';
import useDimensions from 'use-dimensions';
import SettingsElement from '../../../organisms/settings-element';
import Table from '../../../../../presentational/molecules/table';
import CustomScrollView from '../../../../../presentational/atoms/custom-scroll-view';
import TimeUtil from '../../../../../../util/TimeUtil';
import Spinner from '../../../../../presentational/atoms/spinner';
import DeleteIcon from '../../../../../presentational/molecules/image-icon/delete';
import ReloadIcon from '../../../../../presentational/molecules/image-icon/reload';
import PlusIcon from '../../../../../presentational/molecules/image-icon/plus';
import SearchIcon from '../../../../../presentational/molecules/image-icon/search';
import PlanNotAllowedView from '../../../organisms/plan-not-allowed-view';
import ResizableColumn from '../../../organisms/resizable-column';
import CaretSetIcon from '../../../../../presentational/molecules/image-icon/caret-set';
import CaretDownIcon from '../../../../../presentational/molecules/image-icon/caret-down';
import CaretUpIcon from '../../../../../presentational/molecules/image-icon/caret-up';
import when from '../../../../../../lang-extention/When';

const Container = styled.View`
  height: calc(100vh - 57px);
  display: flex;
  flex-direction: row;
`;

const MainAreaContainer = styled.View`
  display: flex;
  flex: 1;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.4s;
  z-index: 100;
  height: calc(100vh);
`;

const MainAreaHeader = styled.View`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: ${(props: IStyleTheme) => props.theme.colors.subHeader};
  padding: 5px 10px;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.4s;
  z-index: 2;
  height: 42px;
`;

const TagItem = styled.View`
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  border-bottom-width: 1px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
`;

const TagAddButton = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  padding-right: 5px;
  padding-left: 5px;
`;

interface IRegisterMemberDialogProps {
  onComplete: () => void;
  onCancel: () => void;
  existsingTags: Array<Tag>;
}

const RegiserTagDialog = (props: IRegisterMemberDialogProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, __] = useContext(LoginUserContext);
  const [name, setName] = useState<string | ''>('');
  const [note, setNote] = useState<string | ''>('');
  const [createTag, _] = useCreateTagMutation({
    variables: {
      input: {
        name: name,
        note: note,
      },
    },
    refetchQueries: [
      {
        query: OrganizationTagsDocument,
        variables: {
          organizationId: loginUser!.organizationId,
        },
      },
    ],
  });

  return (
    <Form>
      <View style={{ marginTop: 10, minWidth: 400 }}>
        <Input
          name={'name'}
          label={'タグの名前'}
          type={'text'}
          focus
          onChange={(value) => setName(value)}
          validate={{
            required: {
              value: true,
              message: 'タグの名前を入力してください',
            },
            maxLength: {
              value: 100,
              message: '100文字以内で入力してください',
            },
            validate: {
              uniqueName: (value: string) =>
                !props.existsingTags.map((tag) => tag.name).includes(value) ||
                'すでに登録されているタグの名前です。',
            },
          }}
        />
        <Input
          name={'note'}
          label={'  備考'}
          type={'text'}
          multiline
          inputstyle={{
            borderRadius: 1,
            borderColor: themeContext.colors.separator,
            borderStyle: 'solid',
            borderWidth: 0.5,
          }}
          onChange={(value) => setNote(value)}
        />
      </View>
      <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
        <Button
          text="作成する"
          completeText="作成しました"
          style={{ height: 30, marginRight: 5 }}
          textStyle={{ fontSize: 14 }}
          isDisabled={name === null}
          onPress={async () => {
            await createTag();
            props.onComplete();
          }}
        />
        <Button
          text="キャンセル"
          style={{ height: 30, backgroundColor: 'transparent' }}
          textStyle={{ fontSize: 14, color: themeContext.colors.primary }}
          onPress={async () => {
            props.onCancel();
          }}
          disableValidate={true}
        />
      </View>
    </Form>
  );
};

interface IDeleteTagDialogProps {
  tag: Tag;
}

const DeleteTagDialog = (props: IDeleteTagDialogProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const history = useHistory();
  const [loginUser, __] = useContext(LoginUserContext);
  const [showModal, setShowModal] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [deleteTag, _] = useDeleteTagMutation({
    variables: {
      id: props.tag.id!,
      input: {
        versionNo: props.tag.versionNo,
      },
    },
    update(cache) {
      cache.evict({
        id: cache.identify(props.tag),
      });
    },
  });

  return (
    <>
      <DeleteIcon
        size={23}
        onPress={() => {
          setShowModal(true);
        }}>
        <Typography variant={TypographyType.Normal} style={{ fontSize: 16 }}>
          削除する
        </Typography>
      </DeleteIcon>
      <Modal
        title={`「${props.tag.name}」を削除しますか？`}
        isShow={showModal}
        onClose={() => {
          setShowModal(false);
          setShowAlertModal(false);
        }}>
        <Typography
          variant={TypographyType.Normal}
          style={{ textAlign: 'center', marginVertical: 10 }}>
          {`このタグが紐付いているタスクは、紐付きが解除されます`}
        </Typography>
        <Form>
          <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
            <Button
              text={'キャンセル'}
              style={{
                minWidth: 100,
                marginRight: 10,
                marginVertical: 10,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.primary }}
              disableValidate={true}
              onPress={() => {
                setShowModal(false);
                setShowAlertModal(false);
              }}
            />
            <Button
              text={'削除する'}
              style={{
                minWidth: 100,
                marginRight: 10,
                marginVertical: 10,
                borderColor: themeContext.colors.error,
                borderRadius: 3,
                borderWidth: 1,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.error }}
              onPress={async () => {
                setShowModal(false);
                setShowAlertModal(true);
              }}
            />
          </View>
        </Form>
      </Modal>
      <Modal
        title={`本当に削除してよろしいですか？`}
        isShow={showAlertModal}
        onClose={() => {
          setShowModal(false);
          setShowAlertModal(false);
        }}>
        <Typography
          variant={TypographyType.Description}
          style={{ textAlign: 'center', color: themeContext.colors.error }}>
          {`この操作はやり直しが出来ません`}
        </Typography>
        <Form>
          <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
            <Button
              text={'キャンセル'}
              style={{
                minWidth: 100,
                marginRight: 10,
                marginVertical: 10,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.primary }}
              disableValidate={true}
              onPress={() => {
                setShowModal(false);
                setShowAlertModal(false);
              }}
            />
            <Button
              text={'削除する'}
              style={{
                minWidth: 100,
                marginRight: 10,
                marginVertical: 10,
                borderColor: themeContext.colors.error,
                borderRadius: 3,
                borderWidth: 1,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.error }}
              onPress={async () => {
                await deleteTag();
                setShowModal(false);
                setShowAlertModal(false);
                history.push(`/app/${loginUser!.organizationId}/tags/`);
              }}
            />
          </View>
        </Form>
      </Modal>
    </>
  );
};

interface IListProps {
  setShowDialog: (value: boolean) => void;
  existsingTags: Array<Tag>;
  searchTagName: string;
  setSearchTagName: (value: string) => void;
  sortKey: TagSortKey;
  setSortKey: (value: TagSortKey) => void;
  sortOrder: SortOrder | null;
  setSortOrder: (value: SortOrder | null) => void;
}

const List = React.memo((props: IListProps) => {
  const [searchTagNameLive, setSearchTagNameLive] = useState('');
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const history = useHistory();
  const [updateTagSortNo] = useUpdateTagSortNoMutation();

  // Memo化しておかないと、データに変更がなくても、このコンポーネントが描画される度に、全てのTaskSummary再描画がされてしまう。
  const tags = useMemo(() => {
    if (!props.existsingTags) {
      return [];
    }
    return props.existsingTags
      .slice()
      .filter((tag) => tag!.name.indexOf(props.searchTagName) !== -1);
  }, [props.existsingTags, props.searchTagName]);

  return (
    <View style={{ zIndex: 1 }}>
      <View
        style={{
          backgroundColor: themeContext.colors.header,
          paddingVertical: 7,
          paddingHorizontal: 3,
        }}>
        <Typography
          variant={TypographyType.SubTitle}
          ellipsis={true}
          style={{ color: '#FFFFFF', fontSize: 16, fontWeight: '600' }}>
          タグ管理
        </Typography>
      </View>
      <View style={{ borderBottomWidth: 1, borderColor: themeContext.colors.separator }}>
        <Form>
          <Input
            name={'searchTag'}
            label={''}
            icon={<SearchIcon size={20} containerStyle={{ marginLeft: 10 }} />}
            containerStyle={{ marginVertical: 10 }}
            value={props.searchTagName}
            inputstyle={{ borderWidth: 0 }}
            inputContainerStyle={{
              marginHorizontal: 10,
              backgroundColor: themeContext.colors.baseColor,
              shadowOffset: {
                width: -1,
                height: -1,
              },
              shadowOpacity: 0.1,
              elevation: 2,
              borderWidth: 1,
              borderRadius: 20,
              borderColor: themeContext.colors.separator,
            }}
            onChange={(value: string) => {
              setSearchTagNameLive(value);
              if (value?.trim() === '') {
                props.setSearchTagName('');
              }
            }}
            onPressEnterIncludeIMEConvert={(value: string) => props.setSearchTagName(value)}
            onBlur={(value: string) => props.setSearchTagName(value)}
          />
          {searchTagNameLive?.trim().length > 0 && (
            <Typography
              variant={TypographyType.Description}
              style={{ marginLeft: 10, fontSize: 12, textAlign: 'center', marginBottom: 10 }}>
              Enterキーを押すと絞り込みが行われます
            </Typography>
          )}
        </Form>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', overflow: 'hidden' }}>
          <TagAddButton
            style={{
              display: 'flex',
              flexDirection: 'row',
              paddingHorizontal: 5,
            }}
            onPress={() => {
              props.setShowDialog(true);
            }}>
            <PlusIcon
              size={12}
              containerStyle={{ marginLeft: 10 }}
              onPress={() => {
                props.setShowDialog(true);
              }}>
              <Typography
                variant={TypographyType.Normal}
                style={{ fontSize: 12, color: themeContext.colors.description }}>
                タグを追加する
              </Typography>
            </PlusIcon>
          </TagAddButton>
          <TouchableOpacity
            style={{ flexDirection: 'row', alignItems: 'center' }}
            onPress={() => {
              if (props.sortOrder === null || props.sortOrder === undefined) {
                props.setSortKey(TagSortKey.Name);
                props.setSortOrder(SortOrder.Asc);
                return;
              }
              if (props.sortOrder === SortOrder.Asc) {
                props.setSortKey(TagSortKey.Name);
                props.setSortOrder(SortOrder.Desc);
                return;
              }
              props.setSortKey(TagSortKey.SortNo);
              props.setSortOrder(null);
            }}>
            {when(props.sortOrder) //
              .on(
                (v) => v === null || v === undefined,
                () => (
                  <CaretSetIcon
                    size={12}
                    containerStyle={{
                      opacity: 0.2,
                      marginLeft: 3,
                      width: 10,
                      justifyContent: 'flex-end',
                    }}
                    onPress={() => {
                      props.setSortKey(TagSortKey.Name);
                      props.setSortOrder(SortOrder.Asc);
                    }}
                  />
                )
              )
              .on(
                (v) => v === SortOrder.Asc,
                () => (
                  <CaretUpIcon
                    size={10}
                    containerStyle={{ marginLeft: 3, width: 10, justifyContent: 'flex-end' }}
                    onPress={() => {
                      props.setSortKey(TagSortKey.Name);
                      props.setSortOrder(SortOrder.Desc);
                    }}
                  />
                )
              )
              .otherwise(() => (
                <CaretDownIcon
                  size={10}
                  containerStyle={{ marginLeft: 3, width: 10, justifyContent: 'flex-end' }}
                  onPress={() => {
                    props.setSortKey(TagSortKey.SortNo);
                    props.setSortOrder(null);
                  }}
                />
              ))}
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontSize: 12,
                color: themeContext.colors.description,
                textAlign: 'left',
                paddingHorizontal: 5,
                width: 70,
              }}>
              並び替え
            </Typography>
          </TouchableOpacity>
        </View>
      </View>

      <GlobalDragContextProvider>
        <VirtualizedFlatList
          style={{ height: 'calc(100vh - 210px)' }}
          items={tags}
          renderItem={(item, index) => {
            return (
              <TagItem>
                <Typography
                  variant={TypographyType.Normal}
                  ellipsis={true}
                  tooltipId={`tag-${(item as Tag).id}`}
                  tooltipWhenEllipsis
                  style={{
                    fontSize: 13,
                    color: themeContext.colors.textColor,
                    textAlign: 'left',
                    lineHeight: 28,
                  }}>
                  {(item as Tag).name}
                </Typography>
              </TagItem>
            );
          }}
          getKey={(tag: any) => (tag as Tag).id!.toString()}
          itemHeight={40}
          onPress={(tag: any) => {
            history.push(`/app/${loginUser!.organizationId}/tags/${(tag as Tag).id}/`);
          }}
          virticalDraggable={props.sortKey === TagSortKey.SortNo}
          onDrop={async (info) => {
            const isMoveToFirst = info.endRowIndex === 0;
            const isMoveToLast = info.endRowIndex === tags.length - 1;
            const isMoveToDown = info.endRowIndex - info.startRowIndex > 0;

            let sortNo;
            if (isMoveToFirst) {
              sortNo = new Date().getTime();
            } else if (isMoveToLast) {
              sortNo = tags[info.endRowIndex]!.sortNo - 1000;
            } else {
              if (isMoveToDown) {
                const beforeTask = tags[info.endRowIndex];
                const afterTask = tags[info.endRowIndex + 1];
                sortNo = Math.floor((beforeTask!.sortNo + afterTask!.sortNo) / 2);
              } else {
                const beforeTask = tags[info.endRowIndex - 1];
                const afterTask = tags[info.endRowIndex];
                sortNo = Math.floor((beforeTask!.sortNo + afterTask!.sortNo) / 2);
              }
            }

            await updateTagSortNo({
              variables: {
                id: (info.item as Tag).id!,
                input: {
                  sortNo: sortNo,
                  versionNo: (info.item as Tag).versionNo,
                },
              },
              optimisticResponse: {
                __typename: 'Mutation',
                updateTagSortNo: Object.assign(
                  {
                    __typename: 'Client',
                  },
                  info.item as Tag,
                  { sortNo: sortNo }
                ),
              },
            });
          }}
        />
      </GlobalDragContextProvider>
    </View>
  );
});

interface ITagNameModalProps {
  closeModal: () => void;
  tag: Tag;
  existsingTags: Array<Tag>;
}

const TagNameModal = (props: ITagNameModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [name, setName] = useState(props.tag.name);

  const [updateTag] = useUpdateTagMutation({
    variables: {
      id: props.tag.id!,
      input: {
        name: name,
        note: props.tag.note,
        versionNo: props.tag.versionNo,
      },
    },
  });

  return (
    <Form style={{ minWidth: 400 }}>
      <Input
        name={'tagName'}
        label={'タグ名'}
        initialValue={name}
        onChange={setName}
        validate={{
          required: {
            value: true,
            message: 'タグ名を入力してください',
          },
          maxLength: {
            value: 100,
            message: '100文字以内で入力してください',
          },
          validate: {
            uniqueName: (value: string) =>
              !props.existsingTags
                .filter((tag) => tag.id !== props.tag.id)
                .map((tag) => tag.name)
                .includes(value) || 'すでに登録されているタグの名前です。',
          },
        }}
      />
      <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1 }}>
        <Button
          text={'変更する'}
          completeText="変更しました"
          style={{ minWidth: 100, marginRight: 10, marginVertical: 10 }}
          onPress={async () => {
            await updateTag();
            props.closeModal();
          }}
        />
        <Button
          text={'キャンセル'}
          style={{
            minWidth: 100,
            marginRight: 10,
            marginVertical: 10,
            backgroundColor: 'transparent',
          }}
          textStyle={{ color: themeContext.colors.primary }}
          disableValidate={true}
          onPress={() => {
            props.closeModal();
          }}
        />
      </View>
    </Form>
  );
};

interface ITagNoteModalProps {
  closeModal: () => void;
  tag: Tag;
}

const TagNoteModal = (props: ITagNoteModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [note, setNote] = useState(props.tag.note);

  const [updateTag] = useUpdateTagMutation({
    variables: {
      id: props.tag.id!,
      input: {
        name: props.tag.name,
        note: note,
        versionNo: props.tag.versionNo,
      },
    },
  });

  return (
    <Form style={{ minWidth: 400 }}>
      <Input
        name={'tagNote'}
        label={'  備考'}
        type={'text'}
        initialValue={note}
        multiline
        inputstyle={{
          borderRadius: 1,
          borderColor: themeContext.colors.separator,
          borderStyle: 'solid',
          borderWidth: 0.5,
        }}
        onChange={setNote}
        validate={{
          maxLength: {
            value: 250,
            message: '250文字以内で入力してください',
          },
        }}
      />
      <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1 }}>
        <Button
          text={'変更する'}
          completeText="変更しました"
          style={{ minWidth: 100, marginRight: 10, marginVertical: 10 }}
          onPress={async () => {
            await updateTag();
            props.closeModal();
          }}
        />
        <Button
          text={'キャンセル'}
          style={{
            minWidth: 100,
            marginRight: 10,
            marginVertical: 10,
            backgroundColor: 'transparent',
          }}
          textStyle={{ color: themeContext.colors.primary }}
          disableValidate={true}
          onPress={() => {
            props.closeModal();
          }}
        />
      </View>
    </Form>
  );
};

interface ITaskTableProps {
  tag: Tag;
  relatedTasks: Task[];
}

const TaskTable = (props: ITaskTableProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const history = useHistory();
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const { loading, data } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId,
    },
  });

  if (loading) {
    return <></>;
  }

  return (
    <View style={{ paddingHorizontal: 20, marginBottom: 30 }}>
      {props.relatedTasks && (
        <Table
          style={{ width: '100%' }}
          rowData={props.relatedTasks!.map((task) => {
            return {
              タスク: task!.title,
              チーム: task!.project!.team.name,
              プロジェクト: task!.project.name,
              取引先: task!.project!.client?.name || '-',
              taskId: task?.id,
            };
          })}
          rowDataStyle={{
            タスク: {
              width: 'auto',
            },
            チーム: {
              width: 'auto',
            },
            プロジェクト: {
              width: 'auto',
            },
            取引先: {
              width: 'auto',
            },
          }}
          filter={
            data?.organization?.plan.code === Plan.Business ||
            data?.organization?.plan.code === Plan.Enterprise
              ? ['タスク', 'チーム', 'プロジェクト', '取引先']
              : ['タスク', 'プロジェクト', '取引先']
          }
          onPress={(rowData) => {
            history.push(
              `/app/${loginUser!.organizationId}/tags/${props.tag.id}/task/${
                rowData.taskId
              }/detail/`
            );
          }}
        />
      )}
    </View>
  );
};

interface ITagContainerInnerProps {
  tagId: string;
  existsingTags: Array<Tag>;
}

const TagContainerInner = React.memo((props: ITagContainerInnerProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [dummyLoading, setDummyLoading] = useState(false);
  const { loading: tagLoading, data: tagData } = useTagQuery({
    variables: {
      id: props.tagId,
    },
    skip: props.tagId === 'task',
    fetchPolicy: 'network-only',
  });
  const {
    loading: taskLoading,
    data: taskData,
    refetch: taskRefetch,
  } = useTagTasksQuery({ //TODO TDV1-112
    variables: {
      tagId: props.tagId,
    },
    skip: props.tagId === 'task',
    fetchPolicy: 'network-only',
  });

  if (tagLoading || taskLoading) {
    return <></>;
  }
  return (
    <CustomScrollView style={{ paddingBottom: 50 }}>
      <MainAreaHeader style={{} as any}>
        <Typography
          variant={TypographyType.Normal}
          style={[{ color: '#FFFFFF', fontWeight: '600' }, {}] as any}>
          {tagData!.tag!.name}
        </Typography>
      </MainAreaHeader>
      <View
        style={{
          flexDirection: 'column',
          paddingVertical: 20,
          paddingHorizontal: 30,
        }}>
        <SettingsElement
          title={'タグ名'}
          titleWidth={110}
          changeText={'タグ名を変更する'}
          modal={(closeModal) => (
            <TagNameModal
              closeModal={closeModal}
              tag={tagData!.tag!}
              existsingTags={props.existsingTags}
            />
          )}>
          <Typography ellipsis variant={TypographyType.Normal} style={{ flex: 1 }}>
            {tagData!.tag!.name}
          </Typography>
        </SettingsElement>
        <SettingsElement
          title={'備考'}
          titleWidth={110}
          changeText={'備考を変更する'}
          modal={(closeModal) => <TagNoteModal closeModal={closeModal} tag={tagData!.tag!} />}>
          <Typography
            numberOfLines={250}
            style={{ maxWidth: '40vw' }}
            variant={TypographyType.Normal}>
            {tagData!.tag!.note ? tagData!.tag!.note : '-'}
          </Typography>
        </SettingsElement>
        <SettingsElement titleWidth={110} title={'タグの削除'}>
          <DeleteTagDialog tag={tagData!.tag!} />
        </SettingsElement>
        <Spinner loading={dummyLoading}>
          <View>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Typography
                variant={TypographyType.ElementiTitle}
                style={{ marginBottom: 10, lineHeight: 30 }}>
                紐づくタスク一覧（総数: {tagData!.tag!.relatedTaskCount}）
              </Typography>
              <ReloadIcon
                size={30}
                onPress={async () => {
                  setDummyLoading(true);
                  await taskRefetch();
                  await TimeUtil.sleep(1000); // 普通にrefetchすると早すぎてリロードされたかどうかがユーザーに分からないため、あえて１秒ロード中状態を擬似してあげて、ロードしているんだよって伝わるようにしている。
                  setDummyLoading(false);
                }}
              />
            </View>
            <TaskTable tag={tagData!.tag!} relatedTasks={taskData!.tagTasks! as Task[]} />
          </View>
        </Spinner>
      </View>
    </CustomScrollView>
  );
});

const TagContainer = React.memo((props: { existingTags: Tag[] }) => {
  const params = useParams();
  if ((params as any).tagId === 'task') {
    return <></>;
  }
  return <TagContainerInner tagId={(params as any).tagId} existsingTags={props.existingTags} />;
});

const MainArea = React.memo((props: { existingTags: Tag[] }) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const { window } = useDimensions();

  return (
    <MainAreaContainer>
      <Switch>
        <Route path="/app/:organizationId/tags/:tagId/">
          <TagContainer existingTags={props.existingTags} />
        </Route>
        <Route>
          <View
            style={{
              height: 'calc(100vh - 57px)',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            {props.existingTags && (props.existingTags || []).length > 0 ? (
              <>
                <Image
                  source={{ uri: noData01 }}
                  resizeMode="contain"
                  style={{
                    width: Math.min(window.width / 4, 300),
                    height: 200,
                    opacity: 0.7,
                  }}
                />
                <Typography
                  variant={TypographyType.Normal}
                  style={{ color: themeContext.colors.description }}>
                  タグを選択してください
                </Typography>
              </>
            ) : (
              <>
                <Image
                  source={{ uri: noData03 }}
                  resizeMode="contain"
                  style={{
                    width: Math.min(window.width / 4, 300),
                    height: 200,
                    opacity: 0.7,
                  }}
                />
                <Typography
                  variant={TypographyType.Normal}
                  style={{ color: themeContext.colors.description }}>
                  タグ情報を登録してください
                </Typography>
              </>
            )}
          </View>
        </Route>
      </Switch>
    </MainAreaContainer>
  );
});

const AppOrganizationTagsPage = () => {
  const history = useHistory();
  const [searchTagName, setSearchTagName] = useState('');
  const [sortKey, setSortKey] = useState<TagSortKey>(TagSortKey.SortNo);
  const [sortOrder, setSortOrder] = useState<SortOrder | null>(null);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [notAllowedPlan, setNotAllowedPlan] = useState(false);
  const { loading, data } = useOrganizationTagsQuery({
    variables: {
      organizationId: loginUser!.organizationId,
      input: {
        name: searchTagName,
        sortKey: sortKey,
        sortOrder: sortOrder,
      },
    },
    fetchPolicy: 'network-only',
  });
  const { loading: organizationLoading, data: organizationData } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId,
    },
    fetchPolicy: 'network-only',
  });

  // Basicプランの場合にはタグ管理できないようにする
  useEffect(() => {
    setTimeout(() => {
      if (organizationData?.organization?.plan.code === Plan.Basic) {
        setNotAllowedPlan(true);
      }
    }, 1000);
  }, [organizationData]);

  if (loading || organizationLoading) {
    return <></>;
  }

  return (
    <PlanNotAllowedView isNotAllowedPlan={notAllowedPlan} style={{ height: 'calc(100vh)' }}>
      <Container>
        <ResizableColumn
          cookieName="TAG_LIST_WIDTH"
          renderChild={(width) => {
            return (
              <List
                setShowDialog={setShowRegisterModal}
                existsingTags={data!.organizationTags! as Array<Tag>}
                searchTagName={searchTagName}
                setSearchTagName={setSearchTagName}
                sortKey={sortKey}
                setSortKey={setSortKey}
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
              />
            );
          }}
        />
        <MainArea existingTags={data!.organizationTags! as Tag[]} />
        <Modal
          isShow={showRegisterModal}
          title="タグを登録する"
          onClose={() => {
            setShowRegisterModal(false);
          }}>
          <RegiserTagDialog
            onComplete={() => {
              setShowRegisterModal(false);
            }}
            onCancel={() => {
              setShowRegisterModal(false);
            }}
            existsingTags={data!.organizationTags! as Array<Tag>}
          />
        </Modal>
      </Container>
    </PlanNotAllowedView>
  );
};

export default React.memo(AppOrganizationTagsPage);
