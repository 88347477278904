import React, { useContext, useState, useEffect } from 'react';
import styled, { ThemeContext } from 'styled-components/native';
import Ripple from 'react-native-material-ripple';
import {
  ActivityIndicator,
  TextStyle,
  ViewStyle,
  Image,
  ImageSourcePropType,
  Platform,
} from 'react-native';
import ColorUtil from '../../../../util/ColorUtil';
import { IStyleTheme, IThemePart } from '../../../theme';
import TimeUtil from '../../../../util/TimeUtil';
import Typography, { TypographyType } from '../../../presentational/atoms/typography';
import FormContext from '../form/context';

const StyledButton =
  Platform.OS === 'web'
    ? styled.View`
        align-self: center;
        padding: 1rem 1.5rem;
        border-color: ${(props: IStyleTheme) => ColorUtil.darken(props.theme.colors.primary, 50)};
        border-radius: 0.3rem;
        background-color: ${(props: IStyleTheme) => props.theme.colors.primary};
        align-items: center;
        justify-content: center;
        flex-direction: row;
        cursor: pointer;
      `
    : styled.View`
        align-self: center;
        padding: 10px;
        border-color: ${(props: IStyleTheme) => ColorUtil.darken(props.theme.colors.primary, 50)};
        border-radius: 5px;
        background-color: ${(props: IStyleTheme) => props.theme.colors.primary};
        align-items: center;
        justify-content: center;
        flex-direction: row;
      `;

const StyledDisabled = styled(StyledButton)`
  border-color: ${(props: IStyleTheme) => ColorUtil.darken(props.theme.colors.primary, 0)};
  background-color: ${(props: IStyleTheme) => ColorUtil.darken(props.theme.colors.primary, 10)};
`;

const StyledText = styled.Text`
  font-size: 16px;
  color: ${(props: IStyleTheme) => props.theme.colors.butttonTextColor};
`;

const StyledDisabledText = styled(StyledText)`
  color: ${(props: IStyleTheme) => ColorUtil.darken(props.theme.colors.butttonTextColor, 50)};
`;

interface IProps {
  text?: string;
  completeText?: string;
  onPress?: (setErrorMessage?: (message: string) => void) => Promise<any> | void;
  disableValidate?: boolean;
  isDisabled?: boolean;
  style?: ViewStyle;
  textStyle?: TextStyle;
  disabledStyle?: ViewStyle;
  disabledTextStyle?: TextStyle;
  rippleColor?: string;
  indicatorColor?: string;
  leftElement?: React.ReactElement;
  rightElement?: React.ReactElement;
  leftInnerElement?: React.ReactElement;
  base64Image?: string;
}

const Button: React.FC<IProps> = (props: IProps) => {
  const {
    isDisabled,
    style,
    textStyle,
    disabledStyle,
    disabledTextStyle,
    rippleColor,
    indicatorColor,
    leftElement,
    rightElement,
    leftInnerElement,
    base64Image,
  } = props;

  const [loading, setLoading] = useState(false);
  const [completeTextShowing, setCompleteTextShowing] = useState(false);
  const [buttonText, setButtonText] = useState(props.text);
  const themeContext: IThemePart = useContext(ThemeContext);
  const form = useContext(FormContext);

  useEffect(() => {
    setButtonText(props.text);
  }, [props.text]);

  const onPress = async (e: any) => {
    if (props.disableValidate !== true && form && form.trigger) {
      const isValid = await form.trigger();
      if (isValid && props.onPress) {
        setLoading(true);
        await props.onPress(e);
        setLoading(false);
        if (props.completeText) {
          setCompleteTextShowing(true);
          setButtonText(props.completeText);
          await TimeUtil.sleep(1000);
          setButtonText(props.text);
          setCompleteTextShowing(false);
        }
      }
    } else {
      if (props.onPress) {
        setLoading(true);
        await props.onPress(e);
        setLoading(false);
        if (props.completeText) {
          setCompleteTextShowing(true);
          setButtonText(props.completeText);
          await TimeUtil.sleep(1000);
          setButtonText(props.text);
          setCompleteTextShowing(false);
        }
      }
    }
  };

  if (isDisabled === true) {
    return (
      //@ts-ignore
      <StyledDisabled
        style={[
          { cursor: 'not-allowed' },
          style,
          disabledStyle,
          {
            margin: 0,
            marginBottom: 0,
            marginTop: 0,
            marginLeft: 0,
            marginRight: 0,
            marginVertical: 0,
            marginHorizontal: 0,
          },
        ]}>
        <StyledDisabledText style={[textStyle, disabledTextStyle] as any}>
          {buttonText}
        </StyledDisabledText>
      </StyledDisabled>
    );
  }

  return (
    <Ripple
      rippleColor={rippleColor || ColorUtil.lignten(themeContext.colors.primary, 20)}
      rippleSize={style?.width ? (style.width as number) * 2 : 600}
      rippleDuration={400}
      disabled={loading}
      onPress={loading ? null : onPress}
      style={[
        {
          alignSelf: 'center',
          alignItems: 'center',
          justifyContent: 'center',
        },
        { cursor: loading || completeTextShowing ? 'not-allowed' : 'pointer' },
        style,
        {
          padding: 0,
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
          paddingVertical: 0,
          paddingHorizontal: 0,
        },
      ]}>
      {leftElement || null}
      {base64Image ? (
        <Image
          source={{ uri: `${base64Image}` }}
          resizeMode="contain"
          style={{
            width: style?.width,
            height: style?.height,
          }}
        />
      ) : (
        <StyledButton
          style={[
            style,
            {
              margin: 0,
              marginBottom: 0,
              marginTop: 0,
              marginLeft: 0,
              marginRight: 0,
              marginVertical: 0,
              marginHorizontal: 0,
            },
          ]}>
          {loading ? (
            <ActivityIndicator
              size="small"
              color={indicatorColor || themeContext.colors.butttonTextColor}
            />
          ) : (
            <>
              {leftInnerElement || null}
              <Typography
                variant={TypographyType.ElementiTitle}
                style={
                  Array.isArray(textStyle)
                    ? [{ color: themeContext.colors.butttonTextColor, fontSize: 16 }].concat(
                        textStyle
                      )
                    : ([
                        { color: themeContext.colors.butttonTextColor, fontSize: 16 },
                        textStyle,
                      ] as any)
                }>
                {buttonText}
              </Typography>
            </>
          )}
        </StyledButton>
      )}
      {rightElement || null}
    </Ripple>
  );
};

export default Button;
