import React, { useContext, useState } from 'react';
import { View } from 'react-native';
import { useHistory } from 'react-router';
import styled, { ThemeContext } from 'styled-components/native';
import Icon from '../../../../../presentational/atoms/icon';
import Typography, { TypographyType } from '../../../../../presentational/atoms/typography';
import CloseIcon from '../../../../../presentational/molecules/image-icon/close';
import MenuIcon from '../../../../../presentational/molecules/image-icon/menu';
import { IStyleTheme, IThemePart } from '../../../../../theme';
import HorizontalMenu from '../../horizontal-menu';

const HeaderMenu = styled.View`
  display: flex;
  flex-direction: row;
  padding: 10px 15px;
  justify-content: space-between;
  align-items: center;
  border-bottom-width: 1px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
`;

const CommonArea = styled.View`
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  align-items: flex-start;
`;

interface IProjectDetailCommonProps {}

const ProjectDetailCommonLoading = (props: IProjectDetailCommonProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const { push } = useHistory();
  return (
    <>
      <HeaderMenu>
        <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          <Typography variant={TypographyType.Description} style={{ fontSize: 14, marginLeft: 5 }}>
            ステータス
          </Typography>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          <MenuIcon size={28} containerStyle={{ marginLeft: 5 }} />
          <CloseIcon
            size={18}
            containerStyle={{ marginLeft: 5 }}
            onPress={() => push(location.hash)}
          />
        </View>
      </HeaderMenu>
      <CommonArea>
        <Typography variant={TypographyType.Normal}>{'_'}</Typography>
        <HorizontalMenu
          style={{ marginTop: 15, marginLeft: 10 }}
          menus={[
            {
              title: '基本情報',
              path: `${location.hash}`,
            },
          ]}
        />
      </CommonArea>
    </>
  );
};

export default ProjectDetailCommonLoading;
