import React, { ReactNode } from 'react';
import { Image, Platform } from 'react-native';
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';
import iconImage from '../../../../../base64Images/dots-horizontal';
import whiteIconImage from '../../../../../base64Images/dots-horizontal/white';

interface IMenuIconProps {
  size: number;
  onPress?: () => void;
  disabled?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  iconStyle?: StyleProp<ViewStyle>;
  reverse?: boolean;
  children?: ReactNode;
}

const MenuIcon = (props: IMenuIconProps) => {
  return (
    <TouchableOpacity
      disabled={props.disabled}
      onPress={props.onPress}
      style={[{ justifyContent: 'center', alignItems: 'center' }, props.containerStyle]}>
      <Image
        source={{ uri: props.reverse === true ? whiteIconImage : iconImage }}
        resizeMode="contain"
        style={[
          {
            height: props.size,
            width: props.size,
            opacity: 0.3,
            shadowOffset: {
              width: 1,
              height: 1,
            },
            shadowOpacity: 0.1,
          } as any,
          props.iconStyle,
          Platform.OS === 'web' ? { elevation: 2, cursor: 'pointer' } : {},
        ]}
      />
      {props.children && props.children}
    </TouchableOpacity>
  );
};

export default MenuIcon;
