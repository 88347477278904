import React, { useContext, useState } from 'react';
import styled, { ThemeContext } from 'styled-components/native';
import {
  Organization,
  OrganizationDocument,
  useOrganizationQuery,
  useUpdateOrganizationImageMutation,
  useUpdateOrganizationMutation,
} from '../../../../../../../graphql/api/API';
import { LoginUserContext } from '../../../../../../../modules/auth/LoginUserContext';
import Button from '../../../../../../presentational/atoms/button';
import Form from '../../../../../../presentational/atoms/form';
import Input from '../../../../../../presentational/atoms/input';
import Typography, { TypographyType } from '../../../../../../presentational/atoms/typography';
import { IThemePart } from '../../../../../../theme';
import SettingsElement from '../../../../organisms/settings-element';
import Avatar from '../../../../../../presentational/atoms/avatar';
import AvatarEdit from 'react-avatar-edit';
import { View } from 'react-native';
import base64Util from '../../../../../../../util/Base64Util';

const Container = styled.View`
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
`;

interface IOrganizationNameModalProps {
  closeModal: () => void;
  organization: Organization;
}

const OrganizationNameModal = (props: IOrganizationNameModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [organizationName, setOrganizationName] = useState(props.organization.name);

  const [updateOrganization] = useUpdateOrganizationMutation({
    variables: {
      id: props.organization.id!,
      input: {
        name: organizationName,
        versionNo: props.organization.versionNo,
      },
    },
  });

  return (
    <Form style={{ paddingVertical: 20 }}>
      <Input
        name={'organizationName'}
        label={'組織名'}
        initialValue={organizationName}
        onChange={setOrganizationName}
        validate={{
          required: {
            value: true,
            message: '組織名を入力してください',
          },
          maxLength: {
            value: 100,
            message: '100文字以内で入力してください',
          },
        }}
      />
      <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1 }}>
        <Button
          text={'変更する'}
          completeText="変更しました"
          style={{ minWidth: 100, marginRight: 10, marginVertical: 10 }}
          onPress={async () => {
            await updateOrganization();
            props.closeModal();
          }}
        />
        <Button
          text={'キャンセル'}
          style={{
            minWidth: 100,
            marginRight: 10,
            marginVertical: 10,
            backgroundColor: 'transparent',
          }}
          textStyle={{ color: themeContext.colors.primary }}
          disableValidate={true}
          onPress={() => {
            props.closeModal();
          }}
        />
      </View>
    </Form>
  );
};

interface IIconImageModalProps {
  organization: Organization;
  closeModal: () => void;
}

const IconImageModal = (props: IIconImageModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [image, setImage] = useState(
    props.organization.imageUrl
      ? base64Util.convertImgDataURL(props.organization.imageUrl)
      : undefined
  );
  const [updateOrganizationImage] = useUpdateOrganizationImageMutation({
    variables: {
      id: props.organization.id!,
      input: {
        imageBase64: image || null,
        versionNo: props.organization.versionNo,
      },
    },
    refetchQueries: [
      {
        query: OrganizationDocument,
        variables: {
          id: props.organization.id,
        },
      },
    ],
  });

  return (
    <>
      <AvatarEdit
        width={600}
        height={300}
        src={image}
        exportAsSquare={true}
        label={`クリックして画像を選択してください`}
        labelStyle={{
          color: themeContext.colors.description,
          cursor: 'pointer',
          paddingLeft: 150,
          paddingRight: 150,
          paddingTop: 150,
          paddingBottom: 150,
        }}
        onCrop={setImage}
        onBeforeFileLoad={(elem: React.ChangeEvent<HTMLInputElement>) => {
          if (elem.target.files && elem.target.files[0].size > 10 * 1024 * 1024) {
            alert('ファイルサイズは10MB以下にしてください');
            elem.target.value = '';
          }
        }}
      />
      <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
        <Button
          text="変更する"
          completeText="変更しました"
          style={{ marginTop: 20, height: 40, width: 200 }}
          onPress={async () => {
            await updateOrganizationImage();
            props.closeModal();
          }}
        />
        <Button
          text="アイコンを削除する"
          style={{ marginTop: 20, height: 40, width: 200, backgroundColor: 'transparent' }}
          textStyle={{ color: themeContext.colors.error }}
          onPress={async () => {
            await updateOrganizationImage({
              variables: {
                id: props.organization.id!,
                input: {
                  imageBase64: null,
                  versionNo: props.organization.versionNo,
                },
              },
            });
            props.closeModal();
          }}
        />
        <Button
          text="キャンセル"
          style={{ marginTop: 20, height: 40, width: 200, backgroundColor: 'transparent' }}
          textStyle={{ color: themeContext.colors.primary }}
          onPress={async () => {
            props.closeModal();
          }}
        />
      </View>
    </>
  );
};

const OrganizationSettingsInfoPage = () => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const { loading, data } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId!,
    },
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <></>;
  }

  return (
    <Container>
      <SettingsElement
        title={'組織名'}
        changeText={'組織名を変更する'}
        modal={(closeModal) => (
          <OrganizationNameModal closeModal={closeModal} organization={data!.organization!} />
        )}>
        <Typography variant={TypographyType.Normal}>{data!.organization!.name}</Typography>
      </SettingsElement>
      <SettingsElement
        title={'組織のアイコン'}
        changeText={'アイコンを変更する'}
        modal={(closeModal) => (
          <IconImageModal organization={data!.organization!} closeModal={closeModal} />
        )}>
        <Avatar
          size={50}
          square={true}
          imageUrl={
            data!.organization!.imageUrl
              ? base64Util.convertImgDataURL(data!.organization!.imageUrl)
              : null
          }
          name={data!.organization!.name}
          containerStyle={{ margin: 6 }}
        />
      </SettingsElement>
    </Container>
  );
};

export default OrganizationSettingsInfoPage;
