import React, { useContext, useState } from 'react';
import { Text, TouchableOpacity } from 'react-native';
import { View } from 'react-native';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import {
  MyGoogleAccountDocument,
  useConnectGoogleAccountMutation,
  useDisconnectGoogleAccountMutation,
  useIsExistLoginPasswordQuery,
  useMyGoogleAccountQuery,
  useOrganizationQuery,
} from '../../../../../../../graphql/api/API';
import { LoginUserContext } from '../../../../../../../modules/auth/LoginUserContext';
import Button from '../../../../../../presentational/atoms/button';
import Form from '../../../../../../presentational/atoms/form';
import Link from '../../../../../../presentational/atoms/link';
import Spinner from '../../../../../../presentational/atoms/spinner';
import Typography, { TypographyType } from '../../../../../../presentational/atoms/typography';
import GoogleConnectDummyButton from '../../../../../../presentational/molecules/google-connect-dummy-button';
import Modal from '../../../../../../presentational/molecules/modal';
import type { IThemePart } from '../../../../../../theme';
import GoogleConnectionDescriptionModal from '../../../../organisms/google-signup-description-modal';

const Container = styled.View`
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
`;

interface IDisconnectModalProps {
  showModal: boolean;
  closeModal: () => void;
}

const DisconnectModal = (props: IDisconnectModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [disconnectGoogleAccount, __] = useDisconnectGoogleAccountMutation({
    refetchQueries: [
      {
        query: MyGoogleAccountDocument,
      },
    ],
  });
  return (
    <Modal
      title={'Googleアカウントを連携解除する'}
      isShow={props.showModal}
      onClose={() => {
        props.closeModal();
      }}>
      <View style={{ marginTop: 10 }}>
        <Typography
          variant={TypographyType.Description}
          style={{ textAlign: 'center', fontSize: 14, lineHeight: 18 }}>
          {`Googleアカウントを連携解除しますか？${'\n'}あとで連携し直すことも出来ます。`}
        </Typography>
      </View>
      <Form style={{ marginTop: 20 }}>
        <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1 }}>
          <Button
            text={'連携解除する'}
            completeText={'連携解除しました'}
            style={{
              minWidth: 100,
              marginRight: 10,
            }}
            onPress={async () => {
              await disconnectGoogleAccount();
              props.closeModal();
            }}
          />
          <Button
            text={'キャンセル'}
            style={{
              minWidth: 100,
              marginRight: 10,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.primary }}
            disableValidate={true}
            onPress={async () => {
              props.closeModal();
            }}
          />
        </View>
      </Form>
    </Modal>
  );
};

const AppMySettingsGoogleAccountPage = () => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [showModal, setShowModal] = useState(false);
  const [showGoogleSignupDescriptionModal, setShowGoogleSignupDescriptionModal] = useState(false);
  const { loading, data } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId,
    },
    fetchPolicy: 'network-only',
  });
  const fetchIsExistPasword = useIsExistLoginPasswordQuery({
    fetchPolicy: 'network-only',
  });
  const fetchGoogleAccount = useMyGoogleAccountQuery({
    fetchPolicy: 'network-only',
  });

  const [connectGoogleAccount, _] = useConnectGoogleAccountMutation({
    onError: (e) => {
      if (e.graphQLErrors.find((ge) => ge.extensions?.code === 'google-account-already-used')) {
        window.alert('このGoogleアカウントは、すでに他のユーザーに紐付けされています');
      }
    },
    refetchQueries: [
      {
        query: MyGoogleAccountDocument,
      },
    ],
  });

  if (loading) {
    return <></>;
  }

  return (
    <Container>
      <Spinner loading={fetchIsExistPasword.loading || fetchGoogleAccount.loading}>
        {fetchGoogleAccount.data?.myGoogleAccount ? (
          <View>
            <Typography variant={TypographyType.Normal} style={{ fontWeight: '600' }}>
              連携中のGoogleアカウント
            </Typography>
            <Typography variant={TypographyType.Normal}>
              {fetchGoogleAccount.data!.myGoogleAccount!.mailAddress}
            </Typography>
            <Typography
              variant={TypographyType.Normal}
              style={{ fontSize: 16, marginTop: 5, marginBottom: 10 }}>
              (Googleカレンダー連携：
              {!!fetchGoogleAccount.data!.myGoogleAccount!.calendarPermission ? '有効' : '無効'})
            </Typography>
            <View style={{ maxWidth: 300 }}>
              <TouchableOpacity onPress={() => setShowGoogleSignupDescriptionModal(true)}>
                <GoogleConnectDummyButton text="Googleアカウントを再連携する" />
              </TouchableOpacity>
            </View>
            {fetchIsExistPasword.data!.isExistLoginPassword ? (
              <>
                <Typography
                  variant={TypographyType.Description}
                  style={{ fontSize: 14, lineHeight: 22, marginTop: 20 }}>
                  Googleアカウントの連携を解除すると、{'\n'}
                  Googleアカウントでのログインや、カレンダー画面でのGoogleカレンダーの確認が出来なくなります。
                </Typography>
                <TouchableOpacity
                  onPress={() => {
                    setShowModal(true);
                  }}
                  style={{ paddingTop: 20, paddingBottom: 20 }}>
                  <Typography
                    variant={TypographyType.Normal}
                    style={{ color: themeContext.colors.link }}>
                    {'Googleアカウント連携を解除する'}
                  </Typography>
                </TouchableOpacity>
                <DisconnectModal showModal={showModal} closeModal={() => setShowModal(false)} />
              </>
            ) : (
              <>
                <Typography
                  variant={TypographyType.Description}
                  style={{ fontSize: 14, lineHeight: 22, marginTop: 20 }}>
                  Googleアカウントの連携を解除したい場合には、ログインパスワードを設定してください。
                </Typography>
                <View style={{ paddingVertical: 13 }}>
                  <Link path={`/app/${loginUser!.organizationId}/my-settings/password/`}>
                    パスワードを設定する
                  </Link>
                </View>
              </>
            )}
          </View>
        ) : (
          <View>
            <View style={{ maxWidth: 300 }}>
              <TouchableOpacity onPress={() => setShowGoogleSignupDescriptionModal(true)}>
                <GoogleConnectDummyButton text="Googleアカウントを連携する" />
              </TouchableOpacity>
            </View>
            <Typography
              variant={TypographyType.Description}
              style={{ fontSize: 14, lineHeight: 22, marginTop: 20 }}>
              Googleアカウントを連携すると、カレンダー画面でGoogleカレンダーのデータを閲覧できます。
            </Typography>
          </View>
        )}
        <GoogleConnectionDescriptionModal
          showModal={showGoogleSignupDescriptionModal}
          onCloseModal={() => setShowGoogleSignupDescriptionModal(false)}
          onSuccess={async (response) => {
            await connectGoogleAccount({
              variables: {
                code: response.code!,
              },
            });
            setShowGoogleSignupDescriptionModal(false);
          }}
        />
      </Spinner>
    </Container>
  );
};

export default AppMySettingsGoogleAccountPage;
