import React, { useContext } from 'react';
import ListMenuApp from '../../../organisms/list-menu-app';
import AppMyAnalyticsGraphPage from './graph';
import MyDataExportPage from './export';
import MyCsvExportPage from './csv-export';
import OrganizationCsvExportSettingsPage from '../organization-analytics/csv-export-settings';
import { LoginUserContext } from '../../../../../../modules/auth/LoginUserContext';
import { useOrganizationQuery } from '../../../../../../graphql/api/API';
import CustomScrollView from '../../../../../presentational/atoms/custom-scroll-view';
import OrganizationUtil from '../../../../../../util/OrganizationUtil';

const AppMyAnalyticsPage = () => {
  const [loginUser, _] = useContext(LoginUserContext);
  const { loading, data, error } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId,
    },
  });

  if (loading) {
    return <></>;
  }

  return (
    <CustomScrollView
      style={{
        height: 'calc(100vh - 57px)',
      }}>
      <ListMenuApp
        headerTitle={'自分の時間分析'}
        resizeCookeName="MY_ANALYTICS_LIST_WIDTH"
        routes={
          OrganizationUtil.isPersonalPlan(data!.organization!)
            ? [
                {
                  name: 'グラフ分析',
                  path: `/app/:organizationId/my-analytics/graph/`,
                  component: AppMyAnalyticsGraphPage,
                },
                {
                  name: 'Excelファイル出力',
                  path: `/app/:organizationId/my-analytics/export/`,
                  component: MyDataExportPage,
                },
                {
                  name: 'CSVファイル出力',
                  path: `/app/:organizationId/my-analytics/csv-export/`,
                  component: MyCsvExportPage,
                },
                {
                  name: 'CSVファイル出力設定',
                  path: `/app/:organizationId/my-analytics/csv-export-settings/`,
                  component: OrganizationCsvExportSettingsPage,
                },
              ]
            : [
                {
                  name: 'グラフ分析',
                  path: `/app/:organizationId/my-analytics/graph/`,
                  component: AppMyAnalyticsGraphPage,
                },
                {
                  name: 'Excelファイル出力',
                  path: `/app/:organizationId/my-analytics/export/`,
                  component: MyDataExportPage,
                },
                {
                  name: 'CSVファイル出力',
                  path: `/app/:organizationId/my-analytics/csv-export/`,
                  component: MyCsvExportPage,
                },
              ]
        }
      />
    </CustomScrollView>
  );
};

export default React.memo(AppMyAnalyticsPage);
