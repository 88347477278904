import React, { useCallback, useContext, useMemo, useState } from 'react';
import { View, Image, TouchableOpacity } from 'react-native';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import { IThemePart } from '../../../../theme';
import TaskSummary from '../task-summary';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import { useHistory, useLocation, useParams } from 'react-router';
import {
  FavoriteTaskCompleteFilter,
  FavoriteTaskSortKey,
  Organization,
  Plan,
  SortOrder,
  Task,
  useMeQuery,
  useOrganizationQuery,
  useSearchFavoriteTasksQuery,
  useUpdateTaskSortNoInFavoriteTaskMutation,
} from '../../../../../graphql/api/API';
import VirtualizedFlatList, {
  GlobalDragContextProvider,
} from '../../../../presentational/atoms/list2/virtualized-flat-list';
import useDimensions from 'use-dimensions';
import noData04 from '../../../../../base64Images/no-data/no-data-4';
import { TaskFilter, TaskFilterContext } from '../../templates/app/my-tasks';
import UrlUtil from '../../../../../util/UrlUtil';
import Spinner from '../../../../presentational/atoms/spinner';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import when from '../../../../../lang-extention/When';
import moment from 'moment-timezone';
import queryString from 'query-string';
import CaretSetIcon from '../../../../presentational/molecules/image-icon/caret-set';
import CaretUpIcon from '../../../../presentational/molecules/image-icon/caret-up';
import CaretDownIcon from '../../../../presentational/molecules/image-icon/caret-down';
import Button from '../../../../presentational/atoms/button';
import Modal from '../../../../presentational/molecules/modal';

const Container = styled.View``;

const TaskListWrapper = styled.View`
  height: calc(100vh - 57px - 90px);
  overflow-y: scroll;
  scrollbar-width: none;
`;
// 本当はoverflow-xはvisibleにしたいのだが、CSSの仕様で、overflow-yを指定した場合には、xの挙動もそちらで上書きされるため実現できない

interface IAlertCanNotDragModalProps {
  showModal: boolean;
  onCloseModal: () => void;
}

const AlertCanNotDragModal = (props: IAlertCanNotDragModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  return (
    <Modal
      title={'並び替えができません'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <View style={{ flexDirection: 'column' }}>
        <View style={{ marginTop: 10 }}>
          <Typography
            variant={TypographyType.Description}
            style={{ textAlign: 'center', fontSize: 16, lineHeight: 22 }}>
            {`ドラッグ操作で並び順を変更したい場合、${'\n'}表示順の指定を解除してください`}
          </Typography>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
          <Button
            text={'閉じる'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
            }}
            onPress={() => props.onCloseModal()}
          />
        </View>
      </View>
    </Modal>
  );
};

interface IHeaderProps {
  organization: Organization;
  searchCondition: any;
}

const Header = (props: IHeaderProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const { window } = useDimensions();
  const windowWidth = window.width;
  const history = useHistory();
  return (
    <View
      style={{
        marginTop: 10,
        marginRight: 10,
        paddingRight: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}>
      <View style={{ flexDirection: 'row', marginRight: 10, marginLeft: 50 }}>
        {(props.organization.plan.code === Plan.Business ||
          props.organization.plan.code === Plan.Enterprise) &&
          windowWidth >= 1550 && (
            <TouchableOpacity
              style={{ flexDirection: 'row', alignItems: 'flex-start' }}
              onPress={() => {
                history.push(
                  queryString.stringifyUrl({
                    url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                    query: Object.assign(props.searchCondition, {
                      sortKey: when(props.searchCondition?.sortOrder) //
                        .on(
                          (v) => v === null || v === undefined,
                          () => FavoriteTaskSortKey.TeamName
                        )
                        .on(
                          (v) => v === SortOrder.Asc,
                          () => FavoriteTaskSortKey.TeamName
                        )
                        .otherwise(() => null),
                      sortOrder: when(props.searchCondition?.sortOrder) //
                        .on(
                          (v) => v === null || v === undefined,
                          () => SortOrder.Asc
                        )
                        .on(
                          (v) => v === SortOrder.Asc,
                          () => SortOrder.Desc
                        )
                        .otherwise(() => null),
                    }),
                  })
                );
              }}>
              {props.searchCondition?.sortKey === FavoriteTaskSortKey.TeamName ? (
                when(props.searchCondition?.sortOrder) //
                  .on(
                    (v) => v === null || v === undefined,
                    () => (
                      <CaretSetIcon
                        size={12}
                        containerStyle={{
                          opacity: 0.2,
                          marginLeft: 3,
                        }}
                        onPress={() => {
                          history.push(
                            queryString.stringifyUrl({
                              url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                              query: Object.assign(props.searchCondition, {
                                sortKey: FavoriteTaskSortKey.TeamName,
                                sortOrder: SortOrder.Asc,
                              }),
                            })
                          );
                        }}
                      />
                    )
                  )
                  .on(
                    (v) => v === SortOrder.Asc,
                    () => (
                      <CaretUpIcon
                        size={10}
                        containerStyle={{ marginLeft: 3 }}
                        onPress={() => {
                          history.push(
                            queryString.stringifyUrl({
                              url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                              query: Object.assign(props.searchCondition, {
                                sortKey: FavoriteTaskSortKey.TeamName,
                                sortOrder: SortOrder.Desc,
                              }),
                            })
                          );
                        }}
                      />
                    )
                  )
                  .otherwise(() => (
                    <CaretDownIcon
                      size={10}
                      containerStyle={{ marginLeft: 3 }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                            query: Object.assign(props.searchCondition, {
                              sortKey: null,
                              sortOrder: null,
                            }),
                          })
                        );
                      }}
                    />
                  ))
              ) : (
                <CaretSetIcon
                  size={12}
                  containerStyle={{
                    opacity: 0.2,
                    marginLeft: 3,
                  }}
                  onPress={() => {
                    history.push(
                      queryString.stringifyUrl({
                        url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                        query: Object.assign(props.searchCondition, {
                          sortKey: FavoriteTaskSortKey.TeamName,
                          sortOrder: SortOrder.Asc,
                        }),
                      })
                    );
                  }}
                />
              )}
              <Typography
                variant={TypographyType.Normal}
                style={{
                  fontSize: 12,
                  color: themeContext.colors.description,
                  textAlign: 'left',
                  paddingHorizontal: 5,
                  width: 140,
                }}>
                チーム名
              </Typography>
            </TouchableOpacity>
          )}
        <TouchableOpacity
          style={{ flexDirection: 'row', alignItems: 'flex-start' }}
          onPress={() => {
            history.push(
              queryString.stringifyUrl({
                url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                query: Object.assign(props.searchCondition, {
                  sortKey: when(props.searchCondition?.sortOrder) //
                    .on(
                      (v) => v === null || v === undefined,
                      () => FavoriteTaskSortKey.ProjectName
                    )
                    .on(
                      (v) => v === SortOrder.Asc,
                      () => FavoriteTaskSortKey.ProjectName
                    )
                    .otherwise(() => null),
                  sortOrder: when(props.searchCondition?.sortOrder) //
                    .on(
                      (v) => v === null || v === undefined,
                      () => SortOrder.Asc
                    )
                    .on(
                      (v) => v === SortOrder.Asc,
                      () => SortOrder.Desc
                    )
                    .otherwise(() => null),
                }),
              })
            );
          }}>
          {props.searchCondition?.sortKey === FavoriteTaskSortKey.ProjectName ? (
            when(props.searchCondition?.sortOrder) //
              .on(
                (v) => v === null || v === undefined,
                () => (
                  <CaretSetIcon
                    size={12}
                    containerStyle={{
                      opacity: 0.2,
                      marginLeft: 3,
                    }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: FavoriteTaskSortKey.ProjectName,
                            sortOrder: SortOrder.Asc,
                          }),
                        })
                      );
                    }}
                  />
                )
              )
              .on(
                (v) => v === SortOrder.Asc,
                () => (
                  <CaretUpIcon
                    size={10}
                    containerStyle={{ marginLeft: 3 }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: FavoriteTaskSortKey.ProjectName,
                            sortOrder: SortOrder.Desc,
                          }),
                        })
                      );
                    }}
                  />
                )
              )
              .otherwise(() => (
                <CaretDownIcon
                  size={10}
                  containerStyle={{ marginLeft: 3 }}
                  onPress={() => {
                    history.push(
                      queryString.stringifyUrl({
                        url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                        query: Object.assign(props.searchCondition, {
                          sortKey: null,
                          sortOrder: null,
                        }),
                      })
                    );
                  }}
                />
              ))
          ) : (
            <CaretSetIcon
              size={12}
              containerStyle={{
                opacity: 0.2,
                marginLeft: 3,
              }}
              onPress={() => {
                history.push(
                  queryString.stringifyUrl({
                    url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                    query: Object.assign(props.searchCondition, {
                      sortKey: FavoriteTaskSortKey.ProjectName,
                      sortOrder: SortOrder.Asc,
                    }),
                  })
                );
              }}
            />
          )}
          <Typography
            variant={TypographyType.Normal}
            style={{
              fontSize: 12,
              color: themeContext.colors.description,
              textAlign: 'left',
              paddingHorizontal: 5,
              width: 170,
            }}>
            プロジェクト名
          </Typography>
        </TouchableOpacity>
        <TouchableOpacity
          style={{ flexDirection: 'row', alignItems: 'flex-start' }}
          onPress={() => {
            history.push(
              queryString.stringifyUrl({
                url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                query: Object.assign(props.searchCondition, {
                  sortKey: when(props.searchCondition?.sortOrder) //
                    .on(
                      (v) => v === null || v === undefined,
                      () => FavoriteTaskSortKey.Title
                    )
                    .on(
                      (v) => v === SortOrder.Asc,
                      () => FavoriteTaskSortKey.Title
                    )
                    .otherwise(() => null),
                  sortOrder: when(props.searchCondition?.sortOrder) //
                    .on(
                      (v) => v === null || v === undefined,
                      () => SortOrder.Asc
                    )
                    .on(
                      (v) => v === SortOrder.Asc,
                      () => SortOrder.Desc
                    )
                    .otherwise(() => null),
                }),
              })
            );
          }}>
          {props.searchCondition?.sortKey === FavoriteTaskSortKey.Title ? (
            when(props.searchCondition?.sortOrder) //
              .on(
                (v) => v === null || v === undefined,
                () => (
                  <CaretSetIcon
                    size={12}
                    containerStyle={{
                      opacity: 0.2,
                      marginLeft: 3,
                    }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: FavoriteTaskSortKey.Title,
                            sortOrder: SortOrder.Asc,
                          }),
                        })
                      );
                    }}
                  />
                )
              )
              .on(
                (v) => v === SortOrder.Asc,
                () => (
                  <CaretUpIcon
                    size={10}
                    containerStyle={{ marginLeft: 3 }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: FavoriteTaskSortKey.Title,
                            sortOrder: SortOrder.Desc,
                          }),
                        })
                      );
                    }}
                  />
                )
              )
              .otherwise(() => (
                <CaretDownIcon
                  size={10}
                  containerStyle={{ marginLeft: 3 }}
                  onPress={() => {
                    history.push(
                      queryString.stringifyUrl({
                        url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                        query: Object.assign(props.searchCondition, {
                          sortKey: null,
                          sortOrder: null,
                        }),
                      })
                    );
                  }}
                />
              ))
          ) : (
            <CaretSetIcon
              size={12}
              containerStyle={{
                opacity: 0.2,
                marginLeft: 3,
              }}
              onPress={() => {
                history.push(
                  queryString.stringifyUrl({
                    url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                    query: Object.assign(props.searchCondition, {
                      sortKey: FavoriteTaskSortKey.Title,
                      sortOrder: SortOrder.Asc,
                    }),
                  })
                );
              }}
            />
          )}
          <Typography
            variant={TypographyType.Normal}
            style={{ fontSize: 12, color: themeContext.colors.description }}>
            タスク名
          </Typography>
        </TouchableOpacity>
      </View>

      <View style={{ flexDirection: 'row' }}>
        {(props.organization.plan.code === Plan.Business ||
          props.organization.plan.code === Plan.Enterprise) && (
          <TouchableOpacity
            style={{
              flexDirection: 'row',
              alignItems: 'flex-start',
              paddingHorizontal: 5,
              width: 70,
            }}
            onPress={() => {
              history.push(
                queryString.stringifyUrl({
                  url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                  query: Object.assign(props.searchCondition, {
                    sortKey: when(props.searchCondition?.sortOrder) //
                      .on(
                        (v) => v === null || v === undefined,
                        () => FavoriteTaskSortKey.Assigner
                      )
                      .on(
                        (v) => v === SortOrder.Asc,
                        () => FavoriteTaskSortKey.Assigner
                      )
                      .otherwise(() => null),
                    sortOrder: when(props.searchCondition?.sortOrder) //
                      .on(
                        (v) => v === null || v === undefined,
                        () => SortOrder.Asc
                      )
                      .on(
                        (v) => v === SortOrder.Asc,
                        () => SortOrder.Desc
                      )
                      .otherwise(() => null),
                  }),
                })
              );
            }}>
            {props.searchCondition?.sortKey === FavoriteTaskSortKey.Assigner ? (
              when(props.searchCondition?.sortOrder) //
                .on(
                  (v) => v === null || v === undefined,
                  () => (
                    <CaretSetIcon
                      size={12}
                      containerStyle={{
                        opacity: 0.2,
                        marginLeft: 3,
                      }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                            query: Object.assign(props.searchCondition, {
                              sortKey: FavoriteTaskSortKey.Assigner,
                              sortOrder: SortOrder.Asc,
                            }),
                          })
                        );
                      }}
                    />
                  )
                )
                .on(
                  (v) => v === SortOrder.Asc,
                  () => (
                    <CaretUpIcon
                      size={10}
                      containerStyle={{ marginLeft: 3 }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                            query: Object.assign(props.searchCondition, {
                              sortKey: FavoriteTaskSortKey.Assigner,
                              sortOrder: SortOrder.Desc,
                            }),
                          })
                        );
                      }}
                    />
                  )
                )
                .otherwise(() => (
                  <CaretDownIcon
                    size={10}
                    containerStyle={{ marginLeft: 3 }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: null,
                            sortOrder: null,
                          }),
                        })
                      );
                    }}
                  />
                ))
            ) : (
              <CaretSetIcon
                size={12}
                containerStyle={{
                  opacity: 0.2,
                  marginLeft: 3,
                }}
                onPress={() => {
                  history.push(
                    queryString.stringifyUrl({
                      url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                      query: Object.assign(props.searchCondition, {
                        sortKey: FavoriteTaskSortKey.Assigner,
                        sortOrder: SortOrder.Asc,
                      }),
                    })
                  );
                }}
              />
            )}
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontSize: 12,
                color: themeContext.colors.description,
                textAlign: 'center',
              }}>
              担当者
            </Typography>
          </TouchableOpacity>
        )}
        <TouchableOpacity
          style={{
            flexDirection: 'row',
            alignItems: 'flex-start',
            paddingHorizontal: 5,
            width: 70,
          }}
          onPress={() => {
            history.push(
              queryString.stringifyUrl({
                url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                query: Object.assign(props.searchCondition, {
                  sortKey: when(props.searchCondition?.sortOrder) //
                    .on(
                      (v) => v === null || v === undefined,
                      () => FavoriteTaskSortKey.Priority
                    )
                    .on(
                      (v) => v === SortOrder.Asc,
                      () => FavoriteTaskSortKey.Priority
                    )
                    .otherwise(() => null),
                  sortOrder: when(props.searchCondition?.sortOrder) //
                    .on(
                      (v) => v === null || v === undefined,
                      () => SortOrder.Asc
                    )
                    .on(
                      (v) => v === SortOrder.Asc,
                      () => SortOrder.Desc
                    )
                    .otherwise(() => null),
                }),
              })
            );
          }}>
          {props.searchCondition?.sortKey === FavoriteTaskSortKey.Priority ? (
            when(props.searchCondition?.sortOrder) //
              .on(
                (v) => v === null || v === undefined,
                () => (
                  <CaretSetIcon
                    size={12}
                    containerStyle={{
                      opacity: 0.2,
                      marginLeft: 3,
                    }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: FavoriteTaskSortKey.Priority,
                            sortOrder: SortOrder.Asc,
                          }),
                        })
                      );
                    }}
                  />
                )
              )
              .on(
                (v) => v === SortOrder.Asc,
                () => (
                  <CaretUpIcon
                    size={10}
                    containerStyle={{ marginLeft: 3 }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: FavoriteTaskSortKey.Priority,
                            sortOrder: SortOrder.Desc,
                          }),
                        })
                      );
                    }}
                  />
                )
              )
              .otherwise(() => (
                <CaretDownIcon
                  size={10}
                  containerStyle={{ marginLeft: 3 }}
                  onPress={() => {
                    history.push(
                      queryString.stringifyUrl({
                        url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                        query: Object.assign(props.searchCondition, {
                          sortKey: null,
                          sortOrder: null,
                        }),
                      })
                    );
                  }}
                />
              ))
          ) : (
            <CaretSetIcon
              size={12}
              containerStyle={{
                opacity: 0.2,
                marginLeft: 3,
              }}
              onPress={() => {
                history.push(
                  queryString.stringifyUrl({
                    url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                    query: Object.assign(props.searchCondition, {
                      sortKey: FavoriteTaskSortKey.Priority,
                      sortOrder: SortOrder.Asc,
                    }),
                  })
                );
              }}
            />
          )}
          <Typography
            variant={TypographyType.Normal}
            style={{
              fontSize: 12,
              color: themeContext.colors.description,
              textAlign: 'center',
            }}>
            優先度
          </Typography>
        </TouchableOpacity>

        {windowWidth > 1500 && (
          <TouchableOpacity
            style={{
              flexDirection: 'row',
              alignItems: 'flex-start',
              paddingHorizontal: 5,
              width: 100,
            }}
            onPress={() => {
              history.push(
                queryString.stringifyUrl({
                  url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                  query: Object.assign(props.searchCondition, {
                    sortKey: when(props.searchCondition?.sortOrder) //
                      .on(
                        (v) => v === null || v === undefined,
                        () => FavoriteTaskSortKey.ScheduledStartDate
                      )
                      .on(
                        (v) => v === SortOrder.Asc,
                        () => FavoriteTaskSortKey.ScheduledStartDate
                      )
                      .otherwise(() => null),
                    sortOrder: when(props.searchCondition?.sortOrder) //
                      .on(
                        (v) => v === null || v === undefined,
                        () => SortOrder.Asc
                      )
                      .on(
                        (v) => v === SortOrder.Asc,
                        () => SortOrder.Desc
                      )
                      .otherwise(() => null),
                  }),
                })
              );
            }}>
            {props.searchCondition?.sortKey === FavoriteTaskSortKey.ScheduledStartDate ? (
              when(props.searchCondition?.sortOrder) //
                .on(
                  (v) => v === null || v === undefined,
                  () => (
                    <CaretSetIcon
                      size={12}
                      containerStyle={{
                        opacity: 0.2,
                        marginLeft: 3,
                      }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                            query: Object.assign(props.searchCondition, {
                              sortKey: FavoriteTaskSortKey.ScheduledStartDate,
                              sortOrder: SortOrder.Asc,
                            }),
                          })
                        );
                      }}
                    />
                  )
                )
                .on(
                  (v) => v === SortOrder.Asc,
                  () => (
                    <CaretUpIcon
                      size={10}
                      containerStyle={{ marginLeft: 3 }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                            query: Object.assign(props.searchCondition, {
                              sortKey: FavoriteTaskSortKey.ScheduledStartDate,
                              sortOrder: SortOrder.Desc,
                            }),
                          })
                        );
                      }}
                    />
                  )
                )
                .otherwise(() => (
                  <CaretDownIcon
                    size={10}
                    containerStyle={{ marginLeft: 3 }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: null,
                            sortOrder: null,
                          }),
                        })
                      );
                    }}
                  />
                ))
            ) : (
              <CaretSetIcon
                size={12}
                containerStyle={{
                  opacity: 0.2,
                  marginLeft: 3,
                }}
                onPress={() => {
                  history.push(
                    queryString.stringifyUrl({
                      url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                      query: Object.assign(props.searchCondition, {
                        sortKey: FavoriteTaskSortKey.ScheduledStartDate,
                        sortOrder: SortOrder.Asc,
                      }),
                    })
                  );
                }}
              />
            )}
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontSize: 12,
                color: themeContext.colors.description,
                textAlign: 'center',
              }}>
              開始予定日
            </Typography>
          </TouchableOpacity>
        )}
        {windowWidth > 1400 && (
          <TouchableOpacity
            style={{
              flexDirection: 'row',
              alignItems: 'flex-start',
              paddingHorizontal: 5,
              width: 70,
            }}
            onPress={() => {
              history.push(
                queryString.stringifyUrl({
                  url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                  query: Object.assign(props.searchCondition, {
                    sortKey: when(props.searchCondition?.sortOrder) //
                      .on(
                        (v) => v === null || v === undefined,
                        () => FavoriteTaskSortKey.ScheduledEndDate
                      )
                      .on(
                        (v) => v === SortOrder.Asc,
                        () => FavoriteTaskSortKey.ScheduledEndDate
                      )
                      .otherwise(() => null),
                    sortOrder: when(props.searchCondition?.sortOrder) //
                      .on(
                        (v) => v === null || v === undefined,
                        () => SortOrder.Asc
                      )
                      .on(
                        (v) => v === SortOrder.Asc,
                        () => SortOrder.Desc
                      )
                      .otherwise(() => null),
                  }),
                })
              );
            }}>
            {props.searchCondition?.sortKey === FavoriteTaskSortKey.ScheduledEndDate ? (
              when(props.searchCondition?.sortOrder) //
                .on(
                  (v) => v === null || v === undefined,
                  () => (
                    <CaretSetIcon
                      size={12}
                      containerStyle={{
                        opacity: 0.2,
                        marginLeft: 3,
                      }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                            query: Object.assign(props.searchCondition, {
                              sortKey: FavoriteTaskSortKey.ScheduledEndDate,
                              sortOrder: SortOrder.Asc,
                            }),
                          })
                        );
                      }}
                    />
                  )
                )
                .on(
                  (v) => v === SortOrder.Asc,
                  () => (
                    <CaretUpIcon
                      size={10}
                      containerStyle={{ marginLeft: 3 }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                            query: Object.assign(props.searchCondition, {
                              sortKey: FavoriteTaskSortKey.ScheduledEndDate,
                              sortOrder: SortOrder.Desc,
                            }),
                          })
                        );
                      }}
                    />
                  )
                )
                .otherwise(() => (
                  <CaretDownIcon
                    size={10}
                    containerStyle={{ marginLeft: 3 }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: null,
                            sortOrder: null,
                          }),
                        })
                      );
                    }}
                  />
                ))
            ) : (
              <CaretSetIcon
                size={12}
                containerStyle={{
                  opacity: 0.2,
                  marginLeft: 3,
                }}
                onPress={() => {
                  history.push(
                    queryString.stringifyUrl({
                      url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                      query: Object.assign(props.searchCondition, {
                        sortKey: FavoriteTaskSortKey.ScheduledEndDate,
                        sortOrder: SortOrder.Asc,
                      }),
                    })
                  );
                }}
              />
            )}
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontSize: 12,
                color: themeContext.colors.description,
                textAlign: 'center',
              }}>
              〆切日
            </Typography>
          </TouchableOpacity>
        )}
        <TouchableOpacity
          style={{
            flexDirection: 'row',
            alignItems: 'flex-start',
            width: 70,
            paddingHorizontal: 5,
          }}
          onPress={() => {
            history.push(
              queryString.stringifyUrl({
                url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                query: Object.assign(props.searchCondition, {
                  sortKey: when(props.searchCondition?.sortOrder) //
                    .on(
                      (v) => v === null || v === undefined,
                      () => FavoriteTaskSortKey.ProgressRate
                    )
                    .on(
                      (v) => v === SortOrder.Asc,
                      () => FavoriteTaskSortKey.ProgressRate
                    )
                    .otherwise(() => null),
                  sortOrder: when(props.searchCondition?.sortOrder) //
                    .on(
                      (v) => v === null || v === undefined,
                      () => SortOrder.Asc
                    )
                    .on(
                      (v) => v === SortOrder.Asc,
                      () => SortOrder.Desc
                    )
                    .otherwise(() => null),
                }),
              })
            );
          }}>
          {props.searchCondition?.sortKey === FavoriteTaskSortKey.ProgressRate ? (
            when(props.searchCondition?.sortOrder) //
              .on(
                (v) => v === null || v === undefined,
                () => (
                  <CaretSetIcon
                    size={12}
                    containerStyle={{
                      opacity: 0.2,
                      marginLeft: 3,
                    }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: FavoriteTaskSortKey.ProgressRate,
                            sortOrder: SortOrder.Asc,
                          }),
                        })
                      );
                    }}
                  />
                )
              )
              .on(
                (v) => v === SortOrder.Asc,
                () => (
                  <CaretUpIcon
                    size={10}
                    containerStyle={{ marginLeft: 3 }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: FavoriteTaskSortKey.ProgressRate,
                            sortOrder: SortOrder.Desc,
                          }),
                        })
                      );
                    }}
                  />
                )
              )
              .otherwise(() => (
                <CaretDownIcon
                  size={10}
                  containerStyle={{ marginLeft: 3 }}
                  onPress={() => {
                    history.push(
                      queryString.stringifyUrl({
                        url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                        query: Object.assign(props.searchCondition, {
                          sortKey: null,
                          sortOrder: null,
                        }),
                      })
                    );
                  }}
                />
              ))
          ) : (
            <CaretSetIcon
              size={12}
              containerStyle={{
                opacity: 0.2,
                marginLeft: 3,
              }}
              onPress={() => {
                history.push(
                  queryString.stringifyUrl({
                    url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                    query: Object.assign(props.searchCondition, {
                      sortKey: FavoriteTaskSortKey.ProgressRate,
                      sortOrder: SortOrder.Asc,
                    }),
                  })
                );
              }}
            />
          )}
          <Typography
            variant={TypographyType.Normal}
            style={{
              fontSize: 12,
              color: themeContext.colors.description,
              textAlign: 'center',
            }}>
            進捗率
          </Typography>
        </TouchableOpacity>
        <TouchableOpacity
          style={{
            flexDirection: 'row',
            alignItems: 'flex-start',
            paddingHorizontal: 5,
            width: 90,
          }}
          onPress={() => {
            history.push(
              queryString.stringifyUrl({
                url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                query: Object.assign(props.searchCondition, {
                  sortKey: when(props.searchCondition?.sortOrder) //
                    .on(
                      (v) => v === null || v === undefined,
                      () => FavoriteTaskSortKey.WorkingTimeSec
                    )
                    .on(
                      (v) => v === SortOrder.Asc,
                      () => FavoriteTaskSortKey.WorkingTimeSec
                    )
                    .otherwise(() => null),
                  sortOrder: when(props.searchCondition?.sortOrder) //
                    .on(
                      (v) => v === null || v === undefined,
                      () => SortOrder.Asc
                    )
                    .on(
                      (v) => v === SortOrder.Asc,
                      () => SortOrder.Desc
                    )
                    .otherwise(() => null),
                }),
              })
            );
          }}>
          {props.searchCondition?.sortKey === FavoriteTaskSortKey.WorkingTimeSec ? (
            when(props.searchCondition?.sortOrder) //
              .on(
                (v) => v === null || v === undefined,
                () => (
                  <CaretSetIcon
                    size={12}
                    containerStyle={{
                      opacity: 0.2,
                      marginLeft: 3,
                    }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: FavoriteTaskSortKey.WorkingTimeSec,
                            sortOrder: SortOrder.Asc,
                          }),
                        })
                      );
                    }}
                  />
                )
              )
              .on(
                (v) => v === SortOrder.Asc,
                () => (
                  <CaretUpIcon
                    size={10}
                    containerStyle={{ marginLeft: 3 }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: FavoriteTaskSortKey.WorkingTimeSec,
                            sortOrder: SortOrder.Desc,
                          }),
                        })
                      );
                    }}
                  />
                )
              )
              .otherwise(() => (
                <CaretDownIcon
                  size={10}
                  containerStyle={{ marginLeft: 3 }}
                  onPress={() => {
                    history.push(
                      queryString.stringifyUrl({
                        url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                        query: Object.assign(props.searchCondition, {
                          sortKey: null,
                          sortOrder: null,
                        }),
                      })
                    );
                  }}
                />
              ))
          ) : (
            <CaretSetIcon
              size={12}
              containerStyle={{
                opacity: 0.2,
                marginLeft: 3,
              }}
              onPress={() => {
                history.push(
                  queryString.stringifyUrl({
                    url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                    query: Object.assign(props.searchCondition, {
                      sortKey: FavoriteTaskSortKey.WorkingTimeSec,
                      sortOrder: SortOrder.Asc,
                    }),
                  })
                );
              }}
            />
          )}
          <Typography
            variant={TypographyType.Normal}
            style={{
              fontSize: 12,
              color: themeContext.colors.description,
              textAlign: 'center',
            }}>
            作業時間
          </Typography>
        </TouchableOpacity>
        <TouchableOpacity
          style={{
            flexDirection: 'row',
            alignItems: 'flex-start',
            paddingHorizontal: 5,
            width: 90,
          }}
          onPress={() => {
            history.push(
              queryString.stringifyUrl({
                url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                query: Object.assign(props.searchCondition, {
                  sortKey: when(props.searchCondition?.sortOrder) //
                    .on(
                      (v) => v === null || v === undefined,
                      () => FavoriteTaskSortKey.EstimateWorkingTimeSec
                    )
                    .on(
                      (v) => v === SortOrder.Asc,
                      () => FavoriteTaskSortKey.EstimateWorkingTimeSec
                    )
                    .otherwise(() => null),
                  sortOrder: when(props.searchCondition?.sortOrder) //
                    .on(
                      (v) => v === null || v === undefined,
                      () => SortOrder.Asc
                    )
                    .on(
                      (v) => v === SortOrder.Asc,
                      () => SortOrder.Desc
                    )
                    .otherwise(() => null),
                }),
              })
            );
          }}>
          {props.searchCondition?.sortKey === FavoriteTaskSortKey.EstimateWorkingTimeSec ? (
            when(props.searchCondition?.sortOrder) //
              .on(
                (v) => v === null || v === undefined,
                () => (
                  <CaretSetIcon
                    size={12}
                    containerStyle={{
                      opacity: 0.2,
                      marginLeft: 3,
                    }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: FavoriteTaskSortKey.EstimateWorkingTimeSec,
                            sortOrder: SortOrder.Asc,
                          }),
                        })
                      );
                    }}
                  />
                )
              )
              .on(
                (v) => v === SortOrder.Asc,
                () => (
                  <CaretUpIcon
                    size={10}
                    containerStyle={{ marginLeft: 3 }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: FavoriteTaskSortKey.EstimateWorkingTimeSec,
                            sortOrder: SortOrder.Desc,
                          }),
                        })
                      );
                    }}
                  />
                )
              )
              .otherwise(() => (
                <CaretDownIcon
                  size={10}
                  containerStyle={{ marginLeft: 3 }}
                  onPress={() => {
                    history.push(
                      queryString.stringifyUrl({
                        url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                        query: Object.assign(props.searchCondition, {
                          sortKey: null,
                          sortOrder: null,
                        }),
                      })
                    );
                  }}
                />
              ))
          ) : (
            <CaretSetIcon
              size={12}
              containerStyle={{
                opacity: 0.2,
                marginLeft: 3,
              }}
              onPress={() => {
                history.push(
                  queryString.stringifyUrl({
                    url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                    query: Object.assign(props.searchCondition, {
                      sortKey: FavoriteTaskSortKey.EstimateWorkingTimeSec,
                      sortOrder: SortOrder.Asc,
                    }),
                  })
                );
              }}
            />
          )}
          <Typography
            variant={TypographyType.Normal}
            style={{
              fontSize: 12,
              color: themeContext.colors.description,
              textAlign: 'center',
            }}>
            見積時間
          </Typography>
        </TouchableOpacity>

        {windowWidth > 1600 && (
          <TouchableOpacity
            style={{
              flexDirection: 'row',
              alignItems: 'flex-start',
              paddingHorizontal: 5,
              width: 100,
            }}
            onPress={() => {
              history.push(
                queryString.stringifyUrl({
                  url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                  query: Object.assign(props.searchCondition, {
                    sortKey: when(props.searchCondition?.sortOrder) //
                      .on(
                        (v) => v === null || v === undefined,
                        () => FavoriteTaskSortKey.DeviationRate
                      )
                      .on(
                        (v) => v === SortOrder.Asc,
                        () => FavoriteTaskSortKey.DeviationRate
                      )
                      .otherwise(() => null),
                    sortOrder: when(props.searchCondition?.sortOrder) //
                      .on(
                        (v) => v === null || v === undefined,
                        () => SortOrder.Asc
                      )
                      .on(
                        (v) => v === SortOrder.Asc,
                        () => SortOrder.Desc
                      )
                      .otherwise(() => null),
                  }),
                })
              );
            }}>
            {props.searchCondition?.sortKey === FavoriteTaskSortKey.DeviationRate ? (
              when(props.searchCondition?.sortOrder) //
                .on(
                  (v) => v === null || v === undefined,
                  () => (
                    <CaretSetIcon
                      size={12}
                      containerStyle={{
                        opacity: 0.2,
                        marginLeft: 3,
                      }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                            query: Object.assign(props.searchCondition, {
                              sortKey: FavoriteTaskSortKey.DeviationRate,
                              sortOrder: SortOrder.Asc,
                            }),
                          })
                        );
                      }}
                    />
                  )
                )
                .on(
                  (v) => v === SortOrder.Asc,
                  () => (
                    <CaretUpIcon
                      size={10}
                      containerStyle={{ marginLeft: 3 }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                            query: Object.assign(props.searchCondition, {
                              sortKey: FavoriteTaskSortKey.DeviationRate,
                              sortOrder: SortOrder.Desc,
                            }),
                          })
                        );
                      }}
                    />
                  )
                )
                .otherwise(() => (
                  <CaretDownIcon
                    size={10}
                    containerStyle={{ marginLeft: 3 }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: null,
                            sortOrder: null,
                          }),
                        })
                      );
                    }}
                  />
                ))
            ) : (
              <CaretSetIcon
                size={12}
                containerStyle={{
                  opacity: 0.2,
                  marginLeft: 3,
                }}
                onPress={() => {
                  history.push(
                    queryString.stringifyUrl({
                      url: `/app/${props.organization.id!}/my/favorite-task/list/`,
                      query: Object.assign(props.searchCondition, {
                        sortKey: FavoriteTaskSortKey.DeviationRate,
                        sortOrder: SortOrder.Asc,
                      }),
                    })
                  );
                }}
              />
            )}
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontSize: 12,
                color: themeContext.colors.description,
                textAlign: 'center',
              }}>
              予想見積時間
            </Typography>
          </TouchableOpacity>
        )}
        <Typography
          variant={TypographyType.Normal}
          style={{
            fontSize: 12,
            color: themeContext.colors.description,
            paddingHorizontal: 5,
            width: 40,
            textAlign: 'center',
          }}>
          完了
        </Typography>
      </View>
    </View>
  );
};

interface ITaskListInnerProps {
  organization: Organization;
  tasks: Task[];
  searchCondition: any;
  onEndReached: () => void;
}

const TaskListInner = React.memo((props: ITaskListInnerProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const history = useHistory();
  const [showCanNotDragModal, setShowCanNotDragModal] = useState(false);
  const [updateTaskSortNo, _] = useUpdateTaskSortNoInFavoriteTaskMutation();
  const { loading: meLoading, data: meData } = useMeQuery();

  const renderItem = useCallback((item, index) => {
    if (!meData?.me) {
      return <></>;
    }
    return (
      <TaskSummary
        task={item as Task}
        organization={props.organization}
        me={meData.me}
        showTeamName={
          props.organization.plan.code === Plan.Business ||
          props.organization.plan.code === Plan.Enterprise
        }
        showProjectName={true}
        showFavorite={false}
        showClientName
        key={index}
      />
    );
  }, []);

  const onPress = useCallback(
    (task) => {
      history.push(UrlUtil.createTaskDetailUrl(task));
    },
    [props.organization.id!]
  );

  const onDrop = useCallback(
    async (info) => {
      if (props.searchCondition?.sortKey) {
        setShowCanNotDragModal(true);
        return;
      }
      const isMoveToFirst = info.endRowIndex === 0;
      const isMoveToLast = info.endRowIndex === props.tasks.length - 1;
      const isMoveToDown = info.endRowIndex - info.startRowIndex > 0;
      let sortNo;
      if (isMoveToFirst) {
        sortNo = new Date().getTime();
      } else if (isMoveToLast) {
        sortNo =
          props.tasks[info.endRowIndex]!.favoriteMembersInfo!.filter(
            (info) => info!.member.id === loginUser!.id
          )?.[0]?.sortNo - 1000;
      } else {
        if (isMoveToDown) {
          const beforeTask = props.tasks[info.endRowIndex];
          const afterTask = props.tasks[info.endRowIndex + 1];
          sortNo = Math.floor(
            (beforeTask!.favoriteMembersInfo!.filter(
              (info) => info!.member.id === loginUser!.id
            )?.[0]?.sortNo +
              afterTask!.favoriteMembersInfo!.filter(
                (info) => info!.member.id === loginUser!.id
              )?.[0]?.sortNo) /
              2
          );
        } else {
          const beforeTask = props.tasks[info.endRowIndex - 1];
          const afterTask = props.tasks[info.endRowIndex];
          sortNo = Math.floor(
            (beforeTask!.favoriteMembersInfo!.filter(
              (info) => info!.member.id === loginUser!.id
            )?.[0]?.sortNo +
              afterTask!.favoriteMembersInfo!.filter(
                (info) => info!.member.id === loginUser!.id
              )?.[0]?.sortNo) /
              2
          );
        }
      }

      await updateTaskSortNo({
        variables: {
          id: (info.item! as Task).id!,
          input: {
            sortNo: sortNo,
            versionNo: (info.item as Task).versionNo,
          },
        },
      });
    },
    [props.tasks, updateTaskSortNo]
  );

  const getKey = useCallback((task) => task!.id!.toString(), []);

  return (
    <>
      <GlobalDragContextProvider>
        <VirtualizedFlatList
          style={{
            height: 'calc(100vh - 190px)',
          }}
          items={props.tasks}
          renderItem={renderItem}
          getKey={getKey}
          itemHeight={60}
          onPress={onPress}
          virticalDraggable={true}
          onDrop={onDrop}
          onEndReached={props.onEndReached}
        />
      </GlobalDragContextProvider>
      <AlertCanNotDragModal
        showModal={showCanNotDragModal}
        onCloseModal={() => setShowCanNotDragModal(false)}
      />
    </>
  );
});

interface ITaskListInnerWrapperProps {
  organization: Organization;
  searchCondition: any;
}

const TaskListInnerWrapper = React.memo((props: ITaskListInnerWrapperProps) => {
  const pageSize = 50;
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [taskFilter] = useContext(TaskFilterContext);
  const { loading, data, fetchMore } = useSearchFavoriteTasksQuery({
    variables: {
      input: {
        projectIdList: [],
        taskStatusIdList: [],
        completeCondition:
          (taskFilter as TaskFilter).showCompleteTask.length === 0 ||
          (taskFilter as TaskFilter).showCompleteTask.length === 2
            ? FavoriteTaskCompleteFilter.Both
            : (taskFilter as TaskFilter).showCompleteTask?.[0] === 'incomplete'
            ? FavoriteTaskCompleteFilter.Incomplete
            : FavoriteTaskCompleteFilter.Complete,
        assignedMemberIdList: [],
        scheduledEndDateTime: {
          to: when((taskFilter as TaskFilter)?.expire)
            .on(
              (v) => v === 'today',
              () => moment().endOf('day').toISOString()
            )
            .on(
              (v) => v === '3days',
              () => moment().add(3, 'days').endOf('day').toISOString()
            )
            .on(
              (v) => v === 'week',
              () => moment().add(1, 'week').endOf('day').toISOString()
            )
            .otherwise(() => null),
        },
        sortKey: props.searchCondition?.sortKey || FavoriteTaskSortKey.SortNoInFavoriteList,
        sortOrder: props.searchCondition?.sortOrder || SortOrder.Desc,
      },
      offset: 0,
      limit: pageSize,
    },
    fetchPolicy: 'network-only',
  });

  return (
    <TaskListWrapper>
      <Header organization={props.organization} searchCondition={props.searchCondition} />
      <Spinner loading={loading} />
      {(data?.searchFavoriteTasks?.length ?? 0) > 0 ? (
        <TaskListInner
          organization={props.organization}
          tasks={data!.searchFavoriteTasks! as Task[]}
          searchCondition={props.searchCondition}
          onEndReached={() => {
            if ((data?.searchFavoriteTasks?.length ?? 0) < pageSize) {
              return;
            }
            fetchMore({
              variables: {
                offset: data!.searchFavoriteTasks!.length || 0,
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return Object.assign({}, prev, {
                  searchFavoriteTasks: [
                    ...(prev.searchFavoriteTasks || []),
                    ...(fetchMoreResult.searchFavoriteTasks || []),
                  ],
                });
              },
            });
          }}
        />
      ) : (
        <View
          style={{
            height: 'calc(100% - 150px)',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Image
            source={{ uri: noData04 }}
            resizeMode="contain"
            style={{
              width: 350,
              height: 200,
              opacity: 0.7,
            }}
          />
          <Typography
            variant={TypographyType.Normal}
            style={{ color: themeContext.colors.description, textAlign: 'center' }}>
            {`該当するタスクはありません`}
          </Typography>
        </View>
      )}
    </TaskListWrapper>
  );
});

interface ITaskListParam {
  teamId: string;
  projectId: string;
  organizationId: string;
}

const FavoriteTaskList = () => {
  const params = useParams<ITaskListParam>();
  const { search } = useLocation();
  const searchCondition = queryString.parse(search);
  const fetchOrganization = useOrganizationQuery({
    variables: {
      id: params.organizationId,
    },
  });

  if (fetchOrganization.loading) {
    return <></>;
  }

  return (
    <Container>
      <TaskListInnerWrapper
        organization={fetchOrganization.data!.organization!}
        searchCondition={searchCondition}
      />
    </Container>
  );
};

export default React.memo(FavoriteTaskList);
