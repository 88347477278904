import moment from 'moment-timezone';
import React, { useContext, useEffect, useMemo, useState } from 'react';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../../theme';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import Input from '../../../../presentational/atoms/input';
import Form from '../../../../presentational/atoms/form';
import { useHistory } from 'react-router';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import {
  Organization,
  Project,
  ProjectCompleteFilter,
  ProjectFavoriteFilter,
  ProjectSortKey,
  SortOrder,
  useAssignedProjectsQuery,
  useMySettingsQuery,
  useOrganizationClientsQuery,
  useOrganizationMembersQuery,
  useOrganizationQuery,
  useUpdateProjectSortNoInAssignedProjectMutation,
} from '../../../../../graphql/api/API';
import VirtualizedFlatList, {
  GlobalDragContextProvider,
} from '../../../../presentational/atoms/list2/virtualized-flat-list';
import { View, TouchableOpacity, Text } from 'react-native';
import ColorUtil from '../../../../../util/ColorUtil';
import SearchIcon from '../../../../presentational/molecules/image-icon/search';
import ResizableColumn from '../resizable-column';
import MultiPickerFilter from '../../../../presentational/atoms/multi-picker-filter';
import Checkbox from '../../../../presentational/atoms/checkbox';
import CaretDownIcon from '../../../../presentational/molecules/image-icon/caret-down';
import CaretUpIcon from '../../../../presentational/molecules/image-icon/caret-up';
import CaretSetIcon from '../../../../presentational/molecules/image-icon/caret-set';
import when from '../../../../../lang-extention/When';
import EnumUtil from '../../../../../util/EnumUtil';
import Cookies from 'js-cookie';

const Container = styled.View`
  z-index: 1;
`;
// 本当はoverflow-xはvisibleにしたいのだが、CSSの仕様で、overflow-yを指定した場合には、xの挙動もそちらで上書きされるため実現できない

interface IProjectItemProps extends IStyleTheme {
  isComplete: boolean;
}

const ProjectItem = styled.View<IProjectItemProps>`
  height: 54px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  min-width: 200px;
  border-bottom-width: 1px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
  background-color: ${(props: IProjectItemProps) =>
    props.isComplete
      ? ColorUtil.lignten(props.theme.colors.separator, 3)
      : props.theme.colors.baseColor};
`;

const ProjectAddButton = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  padding-right: 5px;
  padding-left: 5px;
`;

const Search = styled.View``;

interface IListProps {
  organization: Organization;
  isWidthOver230: boolean;
}

const List = React.memo((props: IListProps) => {
  const pageSize = 50;
  const [searchProjectName, setSearchProjectName] = useState('');
  const [searchProjectNameLive, setSearchProjectNameLive] = useState('');
  const [showSearchCondition, setShowSearchCondition] = useState(false);
  const [focusSearchMember, setForcusSearchMember] = useState(false);
  const [memberIds, setMemberIds] = useState<string[]>([]);
  const [sortKey, setSortKey] = useState<ProjectSortKey>(
    EnumUtil.getEnumValue(ProjectSortKey, Cookies.get(`PROJECT_LIST_SORT_KEY`)) ??
      ProjectSortKey.SortNo
  );
  const [sortOrder, setSortOrder] = useState<SortOrder | null>(
    EnumUtil.getEnumValue(SortOrder, Cookies.get(`PROJECT_LIST_SORT_ORDER`)) ?? null
  );
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const { push } = useHistory();
  const [showCompleteProject, setShowCompleteProject] = useState(false);
  const [focusSearchClient, setForcusSearchClient] = useState(false);
  const [clientIds, setClientIds] = useState<string[]>([]);
  const [showFavoriteProjectOnly, setShowFavoriteProjectOnly] = useState(false);
  const { loading: mySettingsLoading, data: mySettingsData } = useMySettingsQuery();
  const projectsResult = useAssignedProjectsQuery({
    variables: {
      offset: 0,
      limit: pageSize,
      input: {
        completeCondition: showCompleteProject
          ? ProjectCompleteFilter.Both
          : ProjectCompleteFilter.Incomplete,
        favoriteCondition: showFavoriteProjectOnly ? ProjectFavoriteFilter.Favorite : null,
        projectName: searchProjectName,
        clientIds: clientIds,
        assineeMemberIds: memberIds,
        sortKey: sortKey,
        sortOrder: sortOrder,
      },
    },
    fetchPolicy: 'network-only',
  });
  const [updateProjectSortNo, _] = useUpdateProjectSortNoInAssignedProjectMutation();

  const {
    loading: clientLoading,
    data: clientData,
    error: clientError,
  } = useOrganizationClientsQuery({
    variables: {
      organizationId: loginUser!.organizationId,
    },
    fetchPolicy: 'network-only',
  });
  const {
    loading: memberLoading,
    data: memberData,
    error: memberError,
  } = useOrganizationMembersQuery({
    variables: {
      organizationId: loginUser!.organizationId,
    },
    fetchPolicy: 'network-only',
  });

  // Memo化しておかないと、データに変更がなくても、このコンポーネントが描画される度に、全てのTaskSummary再描画がされてしまう。
  const projects = useMemo(() => {
    if (projectsResult.loading || !projectsResult.data?.assignedProjects) {
      return [];
    }
    return projectsResult
      .data!.assignedProjects!.slice()
      .filter((project) => project!.name.indexOf(searchProjectName) !== -1)
      .filter((project) => {
        if (showCompleteProject) {
          return true;
        }
        return !project!.complete;
      });
    // .sort((a, b) => {
    //   return (
    //     b!.assignees!.filter((info) => info.member.id === loginUser!.id)?.[0]?.sortNo -
    //     a!.assignees!.filter((info) => info.member.id === loginUser!.id)?.[0]?.sortNo
    //   ); // ソート番号は昇順
    // });
  }, [
    projectsResult.loading,
    projectsResult.data?.assignedProjects,
    searchProjectName,
    showCompleteProject,
  ]);

  useEffect(() => {
    Cookies.set(`PROJECT_LIST_SORT_KEY`, sortKey, {
      expires: moment().add(10, 'years').toDate(),
    });
  }, [sortKey]);
  useEffect(() => {
    Cookies.set(`PROJECT_LIST_SORT_ORDER`, sortOrder ?? '', {
      expires: moment().add(10, 'years').toDate(),
    });
  }, [sortOrder]);

  if (
    mySettingsLoading ||
    projectsResult.loading ||
    projectsResult.error ||
    clientLoading ||
    clientError
  ) {
    return <></>;
  }

  return (
    <View style={{ overflow: 'hidden' }}>
      <View
        style={{
          backgroundColor: themeContext.colors.header,
          paddingVertical: 7,
          paddingHorizontal: 3,
        }}>
        <Typography
          variant={TypographyType.SubTitle}
          ellipsis={true}
          style={{ color: '#FFFFFF', fontSize: 16, fontWeight: '600' }}>
          担当しているプロジェクト
        </Typography>
      </View>
      <Search
        style={{ borderBottomWidth: 1, borderColor: themeContext.colors.separator, zIndex: 2 }}>
        <Form style={{ zIndex: 2 }}>
          <View style={{ justifyContent: 'flex-start', flex: 1 }}>
            <Input
              name={'searchProject'}
              label={''}
              value={searchProjectName}
              placeholder={props.isWidthOver230 ? 'プロジェクト名で絞り込む' : ''}
              icon={<SearchIcon size={20} containerStyle={{ marginLeft: 10 }} />}
              containerStyle={{ marginVertical: 10 }}
              inputstyle={{ borderWidth: 0, fontSize: 14, minWidth: 200 }}
              inputContainerStyle={{
                marginHorizontal: 10,
                backgroundColor: themeContext.colors.baseColor,
                shadowOffset: {
                  width: -1,
                  height: -1,
                },
                shadowOpacity: 0.1,
                elevation: 2,
                borderWidth: 1,
                borderRadius: 20,
                borderColor: themeContext.colors.separator,
              }}
              onChange={(value: string) => {
                setSearchProjectNameLive(value);
                if (value?.trim() === '') {
                  setSearchProjectName('');
                }
              }}
              onPressEnterIncludeIMEConvert={(value: string) => setSearchProjectName(value)}
              onBlur={(value: string) => setSearchProjectName(value)}
            />
            {searchProjectNameLive?.trim().length > 0 && (
              <Typography
                variant={TypographyType.Description}
                style={{ marginLeft: 10, fontSize: 12, textAlign: 'center', marginBottom: 10 }}>
                Enterキーを押すと絞り込みが行われます
              </Typography>
            )}
          </View>
        </Form>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            zIndex: 2,
          }}>
          <View>
            <TouchableOpacity
              onPress={() => setShowSearchCondition(!showSearchCondition)}
              style={{ marginLeft: 20, marginBottom: 5, flexDirection: 'row' }}>
              <Text style={{ color: '#909090' }}>{'検索条件を追加する'}</Text>
              <Text style={{ color: '#909090', marginLeft: 10 }}>
                {showSearchCondition ? '▼' : '▲'}
              </Text>
            </TouchableOpacity>
            {showSearchCondition && (
              <View style={{ marginBottom: 5, zIndex: 2 }}>
                <View
                  style={{
                    flexDirection: focusSearchClient ? 'column' : 'row',
                    //@ts-ignore
                    overflowX: focusSearchClient ? undefined : 'hidden',
                    marginBottom: 5,
                    zIndex: 2,
                  }}>
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}>
                    <MultiPickerFilter
                      placeHolder="取引先を絞り込む"
                      value={clientIds}
                      containerStyle={{ marginRight: 10 }}
                      listStyle={{ maxWidth: 280 }}
                      pickerItems={(clientData?.organizationClients?.slice() ?? [])
                        .sort((a, b) => (a!.name as any) - (b!.name as any))
                        .map((client) => {
                          return {
                            label: client!.name!,
                            value: client!.id!,
                          };
                        })}
                      onFocus={() => setForcusSearchClient(true)}
                      onBlur={(items) => {
                        setClientIds(items.map((item) => item.value));
                        setForcusSearchClient(false);
                      }}
                      renderLabel={(items) => {
                        if (items.length === 0) {
                          return (
                            <View
                              style={{
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                marginLeft: 20,
                              }}>
                              <SearchIcon size={16} />
                              <Typography
                                variant={TypographyType.Normal}
                                style={{ marginLeft: 10, fontSize: 12 }}
                                ellipsis={true}>
                                取引先で絞り込む
                              </Typography>
                            </View>
                          );
                        }
                        return (
                          <View>
                            <View
                              style={{
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                marginLeft: 20,
                              }}>
                              <SearchIcon size={16} />
                              <Typography
                                variant={TypographyType.Normal}
                                style={{ marginLeft: 10, fontSize: 12 }}
                                ellipsis={true}>
                                取引先で絞り込む
                              </Typography>
                            </View>
                            {items.map((item) => {
                              return (
                                <View style={{ marginLeft: 38 }}>
                                  <Typography
                                    variant={TypographyType.Description}
                                    ellipsis
                                    style={{
                                      fontSize: 14,
                                      lineHeight: 16,
                                    }}>
                                    {item.label}
                                  </Typography>
                                </View>
                              );
                            })}
                          </View>
                        );
                      }}
                    />
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: focusSearchMember ? 'column' : 'row',
                    //@ts-ignore
                    overflowX: focusSearchMember ? undefined : 'hidden',
                  }}>
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}>
                    <MultiPickerFilter
                      placeHolder="担当者を絞り込む"
                      value={memberIds}
                      containerStyle={{ marginRight: 10 }}
                      listStyle={{ maxWidth: 280 }}
                      pickerItems={(memberData?.organizationMembers?.slice() ?? [])
                        .sort((a, b) => (a!.name as any) - (b!.name as any))
                        .map((member) => {
                          return {
                            label: member!.name!,
                            value: member!.id!,
                          };
                        })}
                      onFocus={() => setForcusSearchMember(true)}
                      onBlur={(items) => {
                        setMemberIds(items.map((item) => item.value));
                        setForcusSearchMember(false);
                      }}
                      renderLabel={(items) => {
                        if (items.length === 0) {
                          return (
                            <View
                              style={{
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                marginLeft: 20,
                              }}>
                              <SearchIcon size={16} />
                              <Typography
                                variant={TypographyType.Normal}
                                style={{ marginLeft: 10, fontSize: 12 }}
                                ellipsis={true}>
                                担当者で絞り込む
                              </Typography>
                            </View>
                          );
                        }
                        return (
                          <View>
                            <View
                              style={{
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                marginLeft: 20,
                              }}>
                              <SearchIcon size={16} />
                              <Typography
                                variant={TypographyType.Normal}
                                style={{ marginLeft: 10, fontSize: 12 }}
                                ellipsis={true}>
                                担当者で絞り込む
                              </Typography>
                            </View>
                            {items.map((item) => {
                              return (
                                <View style={{ marginLeft: 38 }}>
                                  <Typography
                                    variant={TypographyType.Description}
                                    ellipsis
                                    style={{
                                      fontSize: 14,
                                      lineHeight: 16,
                                    }}>
                                    {item.label}
                                  </Typography>
                                </View>
                              );
                            })}
                          </View>
                        );
                      }}
                    />
                  </View>
                </View>
              </View>
            )}
          </View>
          <TouchableOpacity
            style={{ flexDirection: 'row' }}
            onPress={() => {
              if (sortOrder === null || sortOrder === undefined) {
                setSortKey(ProjectSortKey.ProjectName);
                setSortOrder(SortOrder.Asc);
                return;
              }
              if (sortKey === ProjectSortKey.ProjectName && sortOrder === SortOrder.Asc) {
                setSortKey(ProjectSortKey.ProjectName);
                setSortOrder(SortOrder.Desc);
                return;
              }
              if (sortKey === ProjectSortKey.ProjectName && sortOrder === SortOrder.Desc) {
                setSortKey(ProjectSortKey.ClientName);
                setSortOrder(SortOrder.Asc);
                return;
              }
              if (sortKey === ProjectSortKey.ClientName && sortOrder === SortOrder.Asc) {
                setSortKey(ProjectSortKey.ClientName);
                setSortOrder(SortOrder.Desc);
                return;
              }
              setSortKey(ProjectSortKey.SortNo);
              setSortOrder(null);
            }}>
            {when(sortOrder) //
              .on(
                (v) => v === null || v === undefined,
                () => (
                  <CaretSetIcon
                    size={12}
                    containerStyle={{
                      opacity: 0.2,
                      marginLeft: 3,
                      width: 10,
                      justifyContent: 'flex-end',
                    }}
                  />
                )
              )
              .on(
                (v) => v === SortOrder.Asc,
                () => (
                  <CaretUpIcon
                    size={10}
                    containerStyle={{ marginLeft: 3, width: 10, justifyContent: 'flex-end' }}
                  />
                )
              )
              .otherwise(() => (
                <CaretDownIcon
                  size={10}
                  containerStyle={{ marginLeft: 3, width: 10, justifyContent: 'flex-end' }}
                />
              ))}
            {when(sortKey) //
              .on(
                (v) => v === null || v === undefined,
                () => (
                  <Typography
                    variant={TypographyType.Normal}
                    style={{
                      fontSize: 12,
                      color: themeContext.colors.description,
                      textAlign: 'left',
                      paddingHorizontal: 5,
                    }}>
                    並び替え
                  </Typography>
                )
              )
              .on(
                (v) => v === ProjectSortKey.ProjectName,
                () => (
                  <Typography
                    variant={TypographyType.Normal}
                    style={{
                      fontSize: 12,
                      color: themeContext.colors.description,
                      textAlign: 'left',
                      paddingHorizontal: 5,
                      lineHeight: 14,
                    }}>
                    {`並び替え${'\n'}(プロジェクト名)`}
                  </Typography>
                )
              )
              .on(
                (v) => v === ProjectSortKey.ClientName,
                () => (
                  <Typography
                    variant={TypographyType.Normal}
                    style={{
                      fontSize: 12,
                      color: themeContext.colors.description,
                      textAlign: 'left',
                      paddingHorizontal: 5,
                      lineHeight: 14,
                    }}>
                    {`並び替え${'\n'}(取引先)`}
                  </Typography>
                )
              )
              .otherwise(() => (
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontSize: 12,
                    color: themeContext.colors.description,
                    textAlign: 'left',
                    paddingHorizontal: 5,
                  }}>
                  並び替え
                </Typography>
              ))}
          </TouchableOpacity>
        </View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            marginLeft: 15,
          }}>
          {showCompleteProject ? (
            <TouchableOpacity onPress={() => setShowCompleteProject(false)}>
              <Typography
                variant={TypographyType.Normal}
                style={{
                  fontSize: 12,
                  color: themeContext.colors.link,
                  textAlign: 'left',
                  paddingHorizontal: 5,
                }}>
                完了したプロジェクトを隠す
              </Typography>
            </TouchableOpacity>
          ) : (
            <TouchableOpacity onPress={() => setShowCompleteProject(true)}>
              <Typography
                variant={TypographyType.Normal}
                style={{
                  fontSize: 12,
                  color: themeContext.colors.link,
                  textAlign: 'left',
                  paddingHorizontal: 5,
                }}>
                {`完了したプロジェクトを表示する`}
              </Typography>
            </TouchableOpacity>
          )}
        </View>
      </Search>

      <GlobalDragContextProvider>
        <VirtualizedFlatList
          style={{ height: 'calc(100vh - 340px)' }}
          items={projects}
          renderItem={(item, index) => {
            return (
              <ProjectItem isComplete={(item as Project).complete}>
                <View style={{ flexDirection: 'column', width: '100%' }}>
                  {(item as Project).team && (
                    <Typography
                      variant={TypographyType.Description}
                      style={{
                        fontSize: 10,
                        color: themeContext.colors.description,
                        textAlign: 'left',
                        lineHeight: 11,
                      }}
                      ellipsis={true}>
                      {(item as Project).team!.name}
                    </Typography>
                  )}
                  {(item as Project).client && (
                    <Typography
                      variant={TypographyType.Description}
                      style={{
                        fontSize: 10,
                        color: themeContext.colors.description,
                        textAlign: 'left',
                        lineHeight: 11,
                      }}
                      ellipsis={true}>
                      {(item as Project).client!.name}
                    </Typography>
                  )}
                  <Typography
                    variant={TypographyType.Normal}
                    ellipsis={true}
                    style={{
                      fontSize: 13,
                      color: themeContext.colors.textColor,
                      textAlign: 'left',
                    }}>
                    {(item as Project).name}
                  </Typography>
                </View>
              </ProjectItem>
            );
          }}
          getKey={(project) => (project as Project).id!.toString()}
          itemHeight={54}
          onPress={(project) => {
            push(
              `/app/${loginUser!.organizationId}/my/assigned-project/${
                (project as Project).id
              }/list/`
            );
          }}
          virticalDraggable={true}
          onDrop={async (info) => {
            if (sortKey !== ProjectSortKey.SortNo) {
              return;
            }
            const isMoveToFirst = info.endRowIndex === 0;
            const isMoveToLast = info.endRowIndex === projects.length - 1;
            const isMoveToDown = info.endRowIndex - info.startRowIndex > 0;
            let sortNo;
            if (isMoveToFirst) {
              sortNo = new Date().getTime();
            } else if (isMoveToLast) {
              sortNo =
                projects[info.endRowIndex]!.assignees!.filter(
                  (info) => info!.member.id === loginUser!.id
                )?.[0]?.sortNo - 1000;
            } else {
              if (isMoveToDown) {
                const beforeTask = projects[info.endRowIndex];
                const afterTask = projects[info.endRowIndex + 1];
                sortNo = Math.floor(
                  (beforeTask!.assignees!.filter((info) => info!.member.id === loginUser!.id)?.[0]
                    ?.sortNo +
                    afterTask!.assignees!.filter((info) => info!.member.id === loginUser!.id)?.[0]
                      ?.sortNo) /
                    2
                );
              } else {
                const beforeTask = projects[info.endRowIndex - 1];
                const afterTask = projects[info.endRowIndex];
                sortNo = Math.floor(
                  (beforeTask!.assignees!.filter((info) => info!.member.id === loginUser!.id)?.[0]
                    ?.sortNo +
                    afterTask!.assignees!.filter((info) => info!.member.id === loginUser!.id)?.[0]
                      ?.sortNo) /
                    2
                );
              }
            }
            await updateProjectSortNo({
              variables: {
                id: (info.item as Project).id!,
                input: {
                  sortNo: sortNo,
                  versionNo: (info.item as Project).versionNo,
                },
              },
            });
          }}
        />
      </GlobalDragContextProvider>
    </View>
  );
});

const AssignedProjectList = () => {
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const { loading, data } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId,
    },
  });

  if (loading) {
    return <></>;
  }

  return (
    <Container>
      <ResizableColumn
        cookieName="ASSIGNED_PROJECT_LIST_WIDTH"
        renderChild={(width) => {
          return <List organization={data!.organization!} isWidthOver230={width > 230} />;
        }}
      />
    </Container>
  );
};

export default AssignedProjectList;
