import React, { useContext, useState } from 'react';
import styled, { ThemeContext } from 'styled-components/native';
import { TextStyle, ViewStyle, Text, StyleProp, View } from 'react-native';
import Ripple from 'react-native-material-ripple';
import ColorUtil from '../../../../util/ColorUtil';
import { IStyleTheme, IThemePart } from '../../../theme';

interface IProps {
  text?: string;
  children?: React.ReactElement<any, any>;
  containerStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  onChange?: (selected: boolean) => void;
  initialValue?: boolean;
  value?: boolean;
}

const ToggleButton: React.FC<IProps> = (props: IProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [selected, setSelected] = useState(props.initialValue || false);
  const adjustedValue = props.value !== undefined ? props.value : selected;
  return (
    <Ripple
      style={[
        {
          cursor: 'pointer',
          backgroundColor: props.children
            ? 'transparent'
            : adjustedValue
            ? ColorUtil.lignten(themeContext.colors.primary, 14)
            : '#FFFFFF',
          marginHorizontal: 2,
          borderRadius: 5,
        },
        props.containerStyle,
      ]}
      rippleColor={adjustedValue || props.children ? '#FFFFFF' : themeContext.colors.primary}
      rippleSize={100}
      rippleDuration={400}
      onPress={() => {
        const newValue = !adjustedValue;
        setSelected(newValue);
        if (props.onChange) {
          props.onChange(newValue);
        }
      }}>
      {props.children ? (
        <View
          style={{
            opacity: adjustedValue ? 1 : 0.3,
          }}>
          {props.children}
        </View>
      ) : (
        <Text
          style={[
            {
              fontSize: 14,
              lineHeight: 16,
              color: adjustedValue ? '#FFFFFF' : '#CCCCCC',
              textAlign: 'center',
              padding: 4,
              paddingHorizontal: 5,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            } as any,
            props.textStyle,
          ]}>
          {props.text}
        </Text>
      )}
    </Ripple>
  );
};

export default ToggleButton;
