import React, { useContext, useEffect, useState } from 'react';
import { View } from 'react-native';
import CreatableSelect from 'react-select/creatable';
import ColorUtil from '../../../../util/ColorUtil';
import { IThemePart } from '../../../theme';
import { ThemeContext } from 'styled-components/native';
import Avatar from '../avatar';
import Typography, { TypographyType } from '../typography';

export interface ListValueMap {
  label: string;
  value: any;
  imageUrl?: string;
}

interface IProps {
  name: string;
  placeholder?: string;
  icon?: React.ReactElement<any, any>;
  initialValues?: string[];
  pickerItems?: ListValueMap[];
  isSearchable?: boolean;
  onChange?: (values: string[]) => void;
  onBlur?: (values: string[], textInputElement: any) => void;
  onFocus?: () => void;
  focus?: boolean;
  defaultMenuIsOpen?: boolean;
  onPressEnter?: (values: string[]) => void;
  description?: string;
  validate?: (value: string) => boolean;
  noOptionsMessage?: string;
}

const CreatableMutiselect = React.memo((props: IProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [isFocused, setFocused] = useState(false);
  const [inputs, setInputs] = React.useState<ListValueMap[]>(
    props.pickerItems?.filter((item) => props.initialValues?.includes(item.label)) || []
  );
  const [isEditing, setIsEditing] = useState(false);
  let textInputElement: any = null;
  const setTextInputRef = (element: any) => {
    textInputElement = element;
  };
  useEffect(() => {
    if (textInputElement && props.focus === true) {
      setFocused(true);
      textInputElement.focus();
    }
  }, [textInputElement, props.focus]);

  return (
    <View style={{ zIndex: 11000 }}>
      <CreatableSelect
        ref={setTextInputRef}
        isValidNewOption={(inputValue) => {
          if (props.validate) {
            return props.validate(inputValue); // TODO もうちょっといい感じにバリデーションしたいが、react-hooks-formとの相性が悪く、かんたんにできなかったので最低限の実装
          }
          return true;
        }}
        onChange={(items) => {
          setIsEditing(false);
          setInputs((items as ListValueMap[]) || []);
          if (props.onChange) {
            props.onChange(((items as ListValueMap[]) || []).map((item) => item.value));
          }
        }}
        onFocus={() => {
          setFocused(true);
          if (props.onFocus) {
            props.onFocus();
          }
        }}
        onBlur={() => {
          setFocused(false);
          if (props.onBlur) {
            props.onBlur(
              inputs.map((input) => input.value),
              textInputElement
            );
          }
        }}
        onKeyDown={(event) => {
          switch (event.nativeEvent.key) {
            case 'Escape':
              setFocused(false);
              textInputElement.blur();
              break;
            case 'Enter':
              if (!isEditing) {
                setFocused(false);
                if (props.onPressEnter) {
                  props.onPressEnter(inputs.map((input) => input.value));
                }
              }
              break;
            default:
              setIsEditing(true);
              break;
          }
        }}
        isMulti={true}
        value={inputs}
        menuPortalTarget={document.body} //TODO スマホアプリだと動かないかも
        menuPosition={'fixed'}
        noOptionsMessage={() => props.noOptionsMessage || '入力して新規作成する'}
        options={props.pickerItems || []}
        formatCreateLabel={(inputValue) =>
          props.noOptionsMessage || `${inputValue} を新規作成します`
        }
        placeholder={props.placeholder ? props.placeholder : '選択する'}
        defaultMenuIsOpen={props.defaultMenuIsOpen || false}
        styles={{
          input: (styles) => {
            return styles;
          },
          option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
              ...styles,
              backgroundColor: isSelected
                ? ColorUtil.lignten(themeContext.colors.primary, 20)
                : isFocused
                ? ColorUtil.lignten(themeContext.colors.primary, 10)
                : themeContext.colors.baseColor,
              color: isSelected || isFocused ? '#FFFFFF' : themeContext.colors.textColor,
              cursor: isDisabled ? 'not-allowed' : 'default',
            };
          },
          menuPortal: (provided) => ({ ...provided, zIndex: 12000 }),
          menu: (provided) => ({ ...provided, zIndex: 12000 }),
        }}
        components={{
          Option: (props) => {
            return (
              <View
                ref={props.innerRef}
                {...props.innerProps}
                style={
                  {
                    backgroundColor: props.isFocused
                      ? themeContext.colors.subHeader
                      : props.isSelected
                      ? themeContext.colors.header
                      : themeContext.colors.baseColor,
                    height: 34,
                    paddingVertical: 10,
                    paddingHorizontal: 10,
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    flexDirection: 'row',
                    cursor: 'pointer',
                  } as any
                }>
                {props.data.imageUrl && (
                  <Avatar
                    size={28}
                    name={props.data.label}
                    imageUrl={props.data.imageUrl}
                    containerStyle={{ marginRight: 10 }}
                  />
                )}
                <Typography
                  variant={TypographyType.Normal}
                  ellipsis={true}
                  style={{
                    fontSize: 16,
                    color: props.isFocused
                      ? themeContext.colors.baseColor
                      : props.isSelected
                      ? themeContext.colors.baseColor
                      : themeContext.colors.textColor,
                  }}>
                  {props.children}
                </Typography>
              </View>
            );
          },
        }}
      />
    </View>
  );
});

export default CreatableMutiselect;
