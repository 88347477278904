import React, { useContext, useEffect, useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import {
  Project,
  ProjectContract,
  ProjectContractType,
  ProjectContractTermType,
  useCreateProjectContractMutation,
  useUpdateProjectContractMutation,
  useProjectContractsByProjectIdLazyQuery,
  useProjectContractsByProjectIdQuery,
  useTimeZonesQuery,
  TimeZone,
  ProjectContractUnitPriceType,
  ProjectContractRoundTimeType,
  useDeleteProjectContractMutation,
  ProjectContractsByProjectIdDocument,
  ProjectDocument,
  useMeQuery,
  MeteredRateContract,
  useUpdateMeteredRateContractMutation,
  useDeleteMeteredRateContractMutation,
  SettlementRangeContract,
  useUpdateSettlementRangeContractMutation,
  useDeleteSettlementRangeContractMutation,
  ProjectContractDocument,
  Plan,
  useOrganizationQuery,
  Organization,
} from '../../../../../../graphql/api/API';
import TaskInfoElement from '../task-info-element';
import moment from 'moment-timezone';
import Typography, { TypographyType } from '../../../../../presentational/atoms/typography';
import EditableText, { IDateRange } from '../../../../../presentational/atoms/editable-text';
import when from '../../../../../../lang-extention/When';
import RegisterProjectContractModal from './registerProjectContractModal';
import { IThemePart } from '../../../../../theme';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import PlusIcon from '../../../../../presentational/molecules/image-icon/plus';
import TimeUtil from '../../../../../../util/TimeUtil';
import DateRangePicker from '../../../../../presentational/atoms/date-range-picker';
import Button from '../../../../../presentational/atoms/button';
import Modal from '../../../../../presentational/molecules/modal';
import RegisterMeteredRateContractModal from './registerMeteredRateContractMembrerModal';
import Avatar from '../../../../../presentational/atoms/avatar';
import DeleteIcon from '../../../../../presentational/molecules/image-icon/delete';
import RegisterSettlementRangeContractModal from './registerSettlementRangeContractMemberModal';
import PlanNotAllowedView from '../../plan-not-allowed-view';
import { LoginUserContext } from '../../../../../../modules/auth/LoginUserContext';
import OrganizationUtil from '../../../../../../util/OrganizationUtil';
import UpdateProjectContractTermModal from './updateProjectContractTerm';
import useHover from '../../../../../presentational/atoms/editable-text/use-hover';
import UrlUtil from '../../../../../../util/UrlUtil';
import { useHistory } from 'react-router';

const Conatiner = styled.View`
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  align-items: flex-start;
  z-index: 2; //DateTimePickerを使用する際には、外側の要素のz-indexを指定しないと正しく表示されないケースがあるので、ここで指定している
`;

const Row = styled.View`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: space-between;
`;

const AddButton = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
`;

interface IDeleteProjectModalProps {
  projectContract: ProjectContract;
  showModal: boolean;
  onPressYes: () => Promise<void>;
  onCloseModal: () => void;
}
const DeleteProjectConfirmModal = (props: IDeleteProjectModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const { loading, data } = useMeQuery({
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <></>;
  }

  return (
    <Modal
      title={'この売上・契約情報を削除しますか？'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <View style={{ flexDirection: 'column' }}>
        <View style={{ marginTop: 10 }}>
          <Typography
            variant={TypographyType.Description}
            style={{ textAlign: 'center', color: themeContext.colors.error }}>
            {`この操作はやり直しが出来ません。${'\n'}本当に削除してよろしいですか？`}
          </Typography>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
          <Button
            text={'キャンセル'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.primary }}
            disableValidate={true}
            onPress={() => {
              props.onCloseModal();
            }}
          />
          <Button
            text={'削除する'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              borderColor: themeContext.colors.error,
              borderRadius: 3,
              borderWidth: 1,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.error }}
            onPress={async () => {
              await props.onPressYes();
            }}
          />
        </View>
      </View>
    </Modal>
  );
};

interface IDeleteMeteredRateContractModalProps {
  projectContract: ProjectContract;
  meterdRateContract: MeteredRateContract;
  showModal: boolean;
  onPressYes: () => Promise<void>;
  onCloseModal: () => void;
}
const DeleteMeteredRateContractModal = (props: IDeleteMeteredRateContractModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [deleteData] = useDeleteMeteredRateContractMutation({
    variables: {
      id: props.meterdRateContract.id!,
      input: {
        versionNo: props.meterdRateContract.versionNo,
      },
    },
    refetchQueries: [
      {
        query: ProjectContractDocument,
        variables: {
          id: props.projectContract.id,
        },
      },
    ],
  });

  return (
    <Modal
      title={'このメンバーの売上単価情報を削除しますか？'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <View style={{ flexDirection: 'column' }}>
        <View style={{ marginTop: 10 }}>
          <Typography
            variant={TypographyType.Description}
            style={{ textAlign: 'center', color: themeContext.colors.error }}>
            {`この操作はやり直しが出来ません。${'\n'}本当に削除してよろしいですか？`}
          </Typography>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
          <Button
            text={'キャンセル'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.primary }}
            disableValidate={true}
            onPress={() => {
              props.onCloseModal();
            }}
          />
          <Button
            text={'削除する'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              borderColor: themeContext.colors.error,
              borderRadius: 3,
              borderWidth: 1,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.error }}
            onPress={async () => {
              await deleteData();
              await props.onPressYes();
            }}
          />
        </View>
      </View>
    </Modal>
  );
};

interface IMeteredRateContractRowProps {
  projectContract: ProjectContract;
  meteredRateContract: MeteredRateContract | undefined;
}

const MeteredRateContractRow = (props: IMeteredRateContractRowProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [updateData] = useUpdateMeteredRateContractMutation();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  return (
    <View
      style={{
        width: 700,
        borderWidth: 1,
        borderColor: themeContext.colors.separator,
        borderRadius: 3,
        padding: 10,
        marginBottom: 10,
      }}>
      <Row>
        {props.meteredRateContract?.member && (
          <TaskInfoElement
            title={'メンバー'}
            style={{ flex: 1 }}
            titleContainerStyle={{ minWidth: 100 }}>
            <View
              style={{
                flexDirection: 'row',
                marginBottom: 5,
              }}>
              <Avatar
                size={28}
                name={props.meteredRateContract.member.name ?? ''}
                imageUrl={props.meteredRateContract.member.profileImageUrl}
                containerStyle={{ marginRight: 10 }}
              />
              <Typography variant={TypographyType.Normal} style={{ fontSize: 16, lineHeight: 22 }}>
                {props.meteredRateContract.member.name}
              </Typography>
            </View>
          </TaskInfoElement>
        )}
        <TaskInfoElement
          title={'時間単価(税抜)'}
          style={{ flex: 1 }}
          titleContainerStyle={{ minWidth: 100 }}>
          <EditableText
            value={props.meteredRateContract?.hourUnitPrice ?? 0}
            inputStyle={{ maxWidth: 200 }}
            type={'currency'}
            validate={{
              required: {
                value: true,
                message: '値を入力してください',
              },
              pattern: {
                value: /^[0-9]+$/,
                message: '半角数字を入力してください。',
              },
              min: {
                value: 0,
                message: '0以上で入力してください',
              },
            }}
            onChange={async (value) => {
              updateData({
                variables: {
                  id: props.meteredRateContract?.id!,
                  input: {
                    hourlyUnitPrice: isNaN(value as any) ? 0 : Number(value),
                    versionNo: props.meteredRateContract!.versionNo!,
                  },
                },
              });
            }}
            textStyle={{ fontSize: 16 }}
            emptyText="-"
          />
        </TaskInfoElement>
      </Row>
      {props.meteredRateContract?.member && (
        <>
          <Row>
            <TaskInfoElement
              title={'売上（税抜）'}
              style={{ flex: 1, maxWidth: 430 }}
              titleContainerStyle={{ minWidth: 100 }}
              contentFlexDirection={'row'}>
              <Typography variant={TypographyType.Normal}>
                {new Intl.NumberFormat('ja-JP', {
                  style: 'currency',
                  currency: 'JPY',
                }).format(props.meteredRateContract?.member?.salesAmountWithoutTax ?? '-')}
              </Typography>
            </TaskInfoElement>
            <TaskInfoElement
              title={'人件費（税抜）'}
              style={{ flex: 1, maxWidth: 430 }}
              titleContainerStyle={{ minWidth: 100 }}
              contentFlexDirection={'row'}>
              <Typography variant={TypographyType.Normal}>
                {new Intl.NumberFormat('ja-JP', {
                  style: 'currency',
                  currency: 'JPY',
                }).format(props.meteredRateContract?.member?.laborCostWithoutTax ?? '-')}
              </Typography>
            </TaskInfoElement>
          </Row>
          <Row>
            <TaskInfoElement
              title={'作業時間'}
              style={{ flex: 1, maxWidth: 430 }}
              titleContainerStyle={{ minWidth: 100 }}
              contentFlexDirection={'row'}>
              <Typography variant={TypographyType.Normal}>
                {TimeUtil.formatForTask(
                  props.meteredRateContract?.member?.totalWorkingTimeSec ?? 0
                )}
              </Typography>
            </TaskInfoElement>
            {props.projectContract.roundTimeType !== ProjectContractRoundTimeType.None && (
              <TaskInfoElement
                title={'作業時間（丸め処理後）'}
                style={{ flex: 1, maxWidth: 430 }}
                titleContainerStyle={{ minWidth: 130 }}
                contentFlexDirection={'row'}>
                <Typography variant={TypographyType.Normal}>
                  {TimeUtil.formatForTask(
                    props.meteredRateContract.member.roundedTotalWorkingTimeSec ?? 0
                  )}
                </Typography>
              </TaskInfoElement>
            )}
          </Row>
        </>
      )}
      {props.meteredRateContract?.member && (
        <Row style={{ justifyContent: 'flex-end' }}>
          <DeleteIcon
            size={23}
            onPress={() => {
              setShowDeleteModal(true);
            }}>
            <Typography variant={TypographyType.Normal} style={{ fontSize: 16 }}>
              削除する
            </Typography>
          </DeleteIcon>
        </Row>
      )}
      {props.meteredRateContract && (
        <DeleteMeteredRateContractModal
          projectContract={props.projectContract}
          meterdRateContract={props.meteredRateContract!}
          showModal={showDeleteModal}
          onCloseModal={() => setShowDeleteModal(false)}
          onPressYes={async () => setShowDeleteModal(false)}
        />
      )}
    </View>
  );
};

interface IDeleteSettlementRangeContractModalProps {
  projectContract: ProjectContract;
  settlementRangeContract: SettlementRangeContract;
  showModal: boolean;
  onPressYes: () => Promise<void>;
  onCloseModal: () => void;
}
const DeleteSettlementRangeContractModal = (props: IDeleteSettlementRangeContractModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [deleteData] = useDeleteSettlementRangeContractMutation({
    variables: {
      id: props.settlementRangeContract.id!,
      input: {
        versionNo: props.settlementRangeContract.versionNo,
      },
    },
    refetchQueries: [
      {
        query: ProjectContractDocument,
        variables: {
          id: props.projectContract.id,
        },
      },
    ],
  });

  return (
    <Modal
      title={'このメンバーの売上単価情報を削除しますか？'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <View style={{ flexDirection: 'column' }}>
        <View style={{ marginTop: 10 }}>
          <Typography
            variant={TypographyType.Description}
            style={{ textAlign: 'center', color: themeContext.colors.error }}>
            {`この操作はやり直しが出来ません。${'\n'}本当に削除してよろしいですか？`}
          </Typography>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
          <Button
            text={'キャンセル'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.primary }}
            disableValidate={true}
            onPress={() => {
              props.onCloseModal();
            }}
          />
          <Button
            text={'削除する'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              borderColor: themeContext.colors.error,
              borderRadius: 3,
              borderWidth: 1,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.error }}
            onPress={async () => {
              await deleteData();
              await props.onPressYes();
            }}
          />
        </View>
      </View>
    </Modal>
  );
};

interface ISettlementRaongeContractRowProps {
  projectContract: ProjectContract;
  settlementRangeContract: SettlementRangeContract | null;
}

const SettlementRangeContractRow = (props: ISettlementRaongeContractRowProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [updateData] = useUpdateSettlementRangeContractMutation();

  return (
    <View
      style={{
        width: 700,
        borderWidth: 1,
        borderColor: themeContext.colors.separator,
        borderRadius: 3,
        padding: 10,
        marginBottom: 10,
      }}>
      {props.settlementRangeContract?.member && (
        <Row style={{ flexDirection: 'row' }}>
          <TaskInfoElement
            title={'メンバー'}
            style={{ flex: 1 }}
            titleContainerStyle={{ minWidth: 130 }}>
            <View
              style={{
                flexDirection: 'row',
              }}>
              <Avatar
                size={28}
                name={props.settlementRangeContract.member.name ?? ''}
                imageUrl={props.settlementRangeContract.member.profileImageUrl}
                containerStyle={{ marginRight: 10 }}
              />
              <Typography variant={TypographyType.Normal} style={{ fontSize: 16, lineHeight: 22 }}>
                {props.settlementRangeContract.member.name}
              </Typography>
            </View>
          </TaskInfoElement>
        </Row>
      )}
      <Row style={{ flexDirection: 'row' }}>
        <TaskInfoElement
          title={'基本時間'}
          style={{ flex: 1 }}
          elementStyle={{ flexDirection: 'row' }}
          titleContainerStyle={{ minWidth: 130 }}>
          <View style={{ flexDirection: 'row' }}>
            <EditableText
              value={props.settlementRangeContract?.lowerHourPart?.lowerHour ?? ''}
              type={'number'}
              inputStyle={{ maxWidth: 80 }}
              validate={{
                required: {
                  value: true,
                  message: '値を入力してください',
                },
                pattern: {
                  value: /^[0-9]+$/,
                  message: '半角数字を入力してください。',
                },
                min: {
                  value: 0,
                  message: '0以上で入力してください',
                },
              }}
              onChange={async (value) => {
                updateData({
                  variables: {
                    id: props.settlementRangeContract?.id!,
                    input: {
                      monthlyUnitPrice: props.settlementRangeContract?.monthlyUnitPrice ?? 0,
                      upperTimeHour: props.settlementRangeContract?.upperHourPart?.upperHour ?? 0,
                      excessHourlyUnitPrice:
                        props.settlementRangeContract?.upperHourPart?.excessHourlyUnitPrice ?? 0,
                      lowerTimeHour: isNaN(value as any) || value == 0 ? 0 : Number(value),
                      deductionHourlyUnitPrice:
                        props.settlementRangeContract?.lowerHourPart?.deducationHourlyUnitPrice ??
                        0,
                      versionNo: props.settlementRangeContract!.versionNo!,
                    },
                  },
                });
              }}
              textStyle={{ fontSize: 16 }}
              renderComponent={(value) => {
                return (
                  <Typography variant={TypographyType.Normal} style={{ color: '#565955' }}>
                    {`${value}`}
                  </Typography>
                );
              }}
              emptyText="-"
            />
            <Typography variant={TypographyType.Normal} style={{ color: '#565955' }}>
              {`〜`}
            </Typography>
            <EditableText
              value={props.settlementRangeContract?.upperHourPart?.upperHour ?? ''}
              type={'number'}
              inputStyle={{ maxWidth: 80 }}
              validate={{
                required: {
                  value: true,
                  message: '値を入力してください',
                },
                pattern: {
                  value: /^[0-9]+$/,
                  message: '半角数字を入力してください。',
                },
                min: {
                  value: 0,
                  message: '0以上で入力してください',
                },
              }}
              onChange={async (value) => {
                updateData({
                  variables: {
                    id: props.settlementRangeContract?.id!,
                    input: {
                      monthlyUnitPrice: props.settlementRangeContract?.monthlyUnitPrice ?? 0,
                      upperTimeHour: isNaN(value as any) || value == 0 ? 0 : Number(value),
                      excessHourlyUnitPrice:
                        props.settlementRangeContract?.upperHourPart?.excessHourlyUnitPrice ?? 0,
                      lowerTimeHour: props.settlementRangeContract?.lowerHourPart?.lowerHour ?? 0,
                      deductionHourlyUnitPrice:
                        props.settlementRangeContract?.lowerHourPart?.deducationHourlyUnitPrice ??
                        0,
                      versionNo: props.settlementRangeContract!.versionNo!,
                    },
                  },
                });
              }}
              textStyle={{ fontSize: 16 }}
              renderComponent={(value) => {
                return (
                  <Typography variant={TypographyType.Normal} style={{ color: '#565955' }}>
                    {`${value} 時間`}
                  </Typography>
                );
              }}
              emptyText="-"
            />
          </View>
        </TaskInfoElement>
        <TaskInfoElement
          title={'基本料金（税抜）'}
          style={{ flex: 1 }}
          titleContainerStyle={{ minWidth: 130 }}>
          <EditableText
            value={props.settlementRangeContract?.monthlyUnitPrice ?? 0}
            type={'currency'}
            validate={{
              required: {
                value: true,
                message: '値を入力してください',
              },
              pattern: {
                value: /^[0-9]+$/,
                message: '半角数字を入力してください。',
              },
              min: {
                value: 0,
                message: '0以上で入力してください',
              },
            }}
            onChange={async (value) => {
              updateData({
                variables: {
                  id: props.settlementRangeContract?.id!,
                  input: {
                    monthlyUnitPrice: isNaN(value as any) ? 0 : Number(value),
                    upperTimeHour: props.settlementRangeContract?.upperHourPart.upperHour ?? 0,
                    excessHourlyUnitPrice:
                      props.settlementRangeContract?.upperHourPart.excessHourlyUnitPrice ?? 0,
                    lowerTimeHour: props.settlementRangeContract?.lowerHourPart.lowerHour ?? 0,
                    deductionHourlyUnitPrice:
                      props.settlementRangeContract?.lowerHourPart.deducationHourlyUnitPrice ?? 0,
                    versionNo: props.settlementRangeContract!.versionNo!,
                  },
                },
              });
            }}
            textStyle={{ fontSize: 16 }}
            emptyText="-"
          />
        </TaskInfoElement>
      </Row>
      <Row>
        <View style={{ flexDirection: 'row', flex: 1 }}>
          <TaskInfoElement
            title={'超過分・時間単価（税抜）'}
            titleContainerStyle={{ minWidth: 130 }}>
            <EditableText
              value={props.settlementRangeContract?.upperHourPart?.excessHourlyUnitPrice ?? ''}
              type={'currency'}
              validate={{
                required: {
                  value: true,
                  message: '値を入力してください',
                },
                pattern: {
                  value: /^[0-9]+$/,
                  message: '半角数字を入力してください。',
                },
                min: {
                  value: 0,
                  message: '0以上で入力してください',
                },
              }}
              onChange={async (value) => {
                updateData({
                  variables: {
                    id: props.settlementRangeContract?.id!,
                    input: {
                      monthlyUnitPrice: props.settlementRangeContract?.monthlyUnitPrice ?? 0,
                      upperTimeHour: props.settlementRangeContract?.upperHourPart?.upperHour ?? 0,
                      excessHourlyUnitPrice: isNaN(value as any) || value == 0 ? 0 : Number(value),
                      lowerTimeHour: props.settlementRangeContract?.lowerHourPart?.lowerHour ?? 0,
                      deductionHourlyUnitPrice:
                        props.settlementRangeContract?.lowerHourPart?.deducationHourlyUnitPrice ??
                        0,
                      versionNo: props.settlementRangeContract!.versionNo!,
                    },
                  },
                });
              }}
              textStyle={{ fontSize: 16 }}
              emptyText="-"
            />
          </TaskInfoElement>
        </View>
        <View style={{ flexDirection: 'row', flex: 1 }}>
          <TaskInfoElement
            title={'控除分・時間単価（税抜）'}
            style={{ flex: 1, maxWidth: 430 }}
            titleContainerStyle={{ minWidth: 130 }}>
            <EditableText
              value={props.settlementRangeContract?.lowerHourPart?.deducationHourlyUnitPrice ?? ''}
              type={'currency'}
              validate={{
                required: {
                  value: true,
                  message: '値を入力してください',
                },
                pattern: {
                  value: /^[0-9]+$/,
                  message: '半角数字を入力してください。',
                },
                min: {
                  value: 0,
                  message: '0以上で入力してください',
                },
              }}
              onChange={async (value) => {
                updateData({
                  variables: {
                    id: props.settlementRangeContract?.id!,
                    input: {
                      monthlyUnitPrice: props.settlementRangeContract?.monthlyUnitPrice ?? 0,
                      upperTimeHour: props.settlementRangeContract?.upperHourPart?.upperHour ?? 0,
                      excessHourlyUnitPrice:
                        props.settlementRangeContract?.upperHourPart?.excessHourlyUnitPrice ?? 0,
                      lowerTimeHour: props.settlementRangeContract?.lowerHourPart?.lowerHour ?? 0,
                      deductionHourlyUnitPrice:
                        isNaN(value as any) || value == 0 ? 0 : Number(value),
                      versionNo: props.settlementRangeContract!.versionNo!,
                    },
                  },
                });
              }}
              textStyle={{ fontSize: 16 }}
              emptyText="-"
            />
          </TaskInfoElement>
        </View>
      </Row>
      {props.settlementRangeContract?.member && (
        <>
          <Row>
            <TaskInfoElement
              title={'売上（税抜）'}
              style={{ flex: 1, maxWidth: 430 }}
              titleContainerStyle={{ minWidth: 130 }}
              contentFlexDirection={'row'}>
              <Typography variant={TypographyType.Normal}>
                {new Intl.NumberFormat('ja-JP', {
                  style: 'currency',
                  currency: 'JPY',
                }).format(props.settlementRangeContract?.member?.salesAmountWithoutTax ?? '-')}
              </Typography>
            </TaskInfoElement>
            <TaskInfoElement
              title={'人件費（税抜）'}
              style={{ flex: 1, maxWidth: 430 }}
              titleContainerStyle={{ minWidth: 130 }}
              contentFlexDirection={'row'}>
              <Typography variant={TypographyType.Normal}>
                {new Intl.NumberFormat('ja-JP', {
                  style: 'currency',
                  currency: 'JPY',
                }).format(props.settlementRangeContract?.member?.laborCostWithoutTax ?? '-')}
              </Typography>
            </TaskInfoElement>
          </Row>
          <Row>
            <TaskInfoElement
              title={'作業時間'}
              style={{ flex: 1, maxWidth: 430 }}
              titleContainerStyle={{ minWidth: 130 }}
              contentFlexDirection={'row'}>
              <Typography variant={TypographyType.Normal}>
                {TimeUtil.formatForTask(
                  props.settlementRangeContract?.member?.totalWorkingTimeSec ?? 0
                )}
              </Typography>
            </TaskInfoElement>
            {props.projectContract.roundTimeType !== ProjectContractRoundTimeType.None && (
              <TaskInfoElement
                title={'作業時間（丸め処理後）'}
                style={{ flex: 1, maxWidth: 430 }}
                titleContainerStyle={{ minWidth: 130 }}
                contentFlexDirection={'row'}>
                <Typography variant={TypographyType.Normal}>
                  {TimeUtil.formatForTask(
                    props.settlementRangeContract.member.roundedTotalWorkingTimeSec ?? 0
                  )}
                </Typography>
              </TaskInfoElement>
            )}
          </Row>
        </>
      )}

      {props.settlementRangeContract?.member && (
        <Row style={{ justifyContent: 'flex-end' }}>
          <DeleteIcon
            size={23}
            onPress={() => {
              setShowDeleteModal(true);
            }}>
            <Typography variant={TypographyType.Normal} style={{ fontSize: 16 }}>
              削除する
            </Typography>
          </DeleteIcon>
        </Row>
      )}

      {props.settlementRangeContract && (
        <DeleteSettlementRangeContractModal
          projectContract={props.projectContract}
          settlementRangeContract={props.settlementRangeContract!}
          showModal={showDeleteModal}
          onCloseModal={() => setShowDeleteModal(false)}
          onPressYes={async () => setShowDeleteModal(false)}
        />
      )}
    </View>
  );
};

interface IProjectContractInfoProps {
  organization: Organization;
  project: Project;
  projectContract: ProjectContract;
  existsProjectContracts: ProjectContract[];
  timeZones: TimeZone[];
  onDelete: () => void;
}

const ProjectContractInfo = (props: IProjectContractInfoProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [hoverRef, isHover] = useHover();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUpdateTermModal, setShowUpdateTermModal] = useState(false);
  const [showRegisterMeteredRate, setShowRegisterMeteredRate] = useState(false);
  const [showRegisterSettlementRange, setShowRegisterSettlementRange] = useState(false);
  const [roundTimeType, setRoundTimeType] = useState<ProjectContractRoundTimeType>(
    props.projectContract.roundTimeType
  );
  const [updateProjectSales] = useUpdateProjectContractMutation({
    refetchQueries: [
      {
        query: ProjectDocument,
        variables: {
          id: props.project.id!,
        },
      },
      {
        query: ProjectContractDocument,
        variables: {
          id: props.projectContract.id!,
        },
      },
    ],
  });

  const [deleteProjectContract] = useDeleteProjectContractMutation({
    variables: {
      id: props.projectContract.id!,
      input: {
        versionNo: props.projectContract.versionNo,
      },
    },
    refetchQueries: [
      {
        query: ProjectContractsByProjectIdDocument,
        variables: {
          projectId: props.project.id!,
        },
      },
      {
        query: ProjectDocument,
        variables: {
          id: props.project.id!,
        },
      },
    ],
  });

  return (
    <>
      <Conatiner>
        <Row>
          <TaskInfoElement
            title={'契約期間'}
            style={{ flex: 1 }}
            titleContainerStyle={{ width: 130 }}>
            <TouchableOpacity onPress={() => setShowUpdateTermModal(true)} ref={hoverRef}>
              {props.projectContract.termType === ProjectContractTermType.Total && (
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    backgroundColor: isHover ? themeContext.colors.separator : 'transparent',
                  }}>
                  期間指定なし
                </Typography>
              )}
              {props.projectContract.termType === ProjectContractTermType.Monthly && (
                <>
                  <Typography
                    variant={TypographyType.Normal}
                    style={{
                      backgroundColor: isHover ? themeContext.colors.separator : 'transparent',
                    }}>
                    {`${props.projectContract.year}年${props.projectContract.month}月`}
                  </Typography>
                  <Typography variant={TypographyType.Description}>{`${
                    props.projectContract.timeZone!.name
                  } (${props.projectContract.timeZone!.offsetTime})`}</Typography>
                </>
              )}
            </TouchableOpacity>
          </TaskInfoElement>
        </Row>
        <Row>
          <TaskInfoElement
            title={'契約形態'}
            titleContainerStyle={{ width: 130 }}
            style={{ flex: 1 }}>
            <EditableText
              value={props.projectContract.contractType}
              type={'picker'}
              emptyText={'-'}
              pickerItems={[
                {
                  label: '固定料金',
                  value: ProjectContractType.FixAmount,
                },
                {
                  label: '従量制料金(時給制)',
                  value: ProjectContractType.MeteredRate,
                },
                {
                  label: '精算幅付き料金',
                  value: ProjectContractType.SettlementRange,
                },
              ]}
              onChange={(value) => {
                const updatedContractType = value as ProjectContractType;
                updateProjectSales({
                  variables: {
                    id: props.projectContract!.id!,
                    input: {
                      contractType: updatedContractType,
                      contractTermType: props.projectContract.termType,
                      unitPriceType: props.projectContract.unitPriceType,
                      timeZoneId: props.projectContract.timeZone!.id,
                      roundTimeType: props.projectContract.roundTimeType,
                      fixSalesAmountWithoutTax: props.projectContract.fixSalesAmountWithoutTax,
                      salesRoundMinutes: props.projectContract.salesRoundMinutes,
                      taxRate: props.projectContract.taxRate,
                      costBudgetWithoutTax: props.projectContract.costBudgetWithoutTax,
                      year: props.projectContract.year,
                      month: props.projectContract.month,
                      versionNo: props.projectContract.versionNo,
                    },
                  },
                });
              }}
              textStyle={{ fontSize: 18 }}
            />
          </TaskInfoElement>
        </Row>
        {props.projectContract.contractType === ProjectContractType.FixAmount ? (
          <Row>
            <TaskInfoElement
              title={'売上金額(税抜)'}
              style={{ flex: 1, maxWidth: 430 }}
              titleContainerStyle={{ minWidth: 130 }}>
              <EditableText
                value={props.projectContract.fixSalesAmountWithoutTax ?? 0}
                style={{ minWidth: 200 }}
                type={'currency'}
                validate={{
                  required: {
                    value: true,
                    message: '値を入力してください',
                  },
                  pattern: {
                    value: /^[0-9]+$/,
                    message: '半角数字を入力してください。',
                  },
                  min: {
                    value: 0,
                    message: '0以上で入力してください',
                  },
                }}
                onChange={async (value) => {
                  updateProjectSales({
                    variables: {
                      id: props.projectContract!.id!,
                      input: {
                        contractType: props.projectContract.contractType,
                        contractTermType: props.projectContract.termType,
                        unitPriceType: props.projectContract.unitPriceType,
                        timeZoneId: props.projectContract.timeZone!.id,
                        roundTimeType: props.projectContract.roundTimeType,
                        fixSalesAmountWithoutTax: isNaN(value as any) ? 0 : (value as number) ?? 0,
                        salesRoundMinutes: props.projectContract.salesRoundMinutes,
                        taxRate: props.projectContract.taxRate,
                        costBudgetWithoutTax: props.projectContract.costBudgetWithoutTax,
                        year: props.projectContract.year,
                        month: props.projectContract.month,
                        versionNo: props.projectContract.versionNo,
                      },
                    },
                  });
                }}
                textStyle={{ fontSize: 16 }}
                emptyText="-"
              />
            </TaskInfoElement>
          </Row>
        ) : (
          <Row>
            <TaskInfoElement
              title={'売上金額(税抜)'}
              style={{ flex: 1, maxWidth: 430 }}
              titleContainerStyle={{ minWidth: 130 }}
              contentFlexDirection={'row'}>
              <Typography variant={TypographyType.Normal}>
                {new Intl.NumberFormat('ja-JP', {
                  style: 'currency',
                  currency: 'JPY',
                }).format(props.projectContract.salesAmountWithoutTax ?? 0)}
              </Typography>
            </TaskInfoElement>
          </Row>
        )}
        <Row>
          <TaskInfoElement
            title={'税率'}
            style={{ flex: 1, maxWidth: 430 }}
            titleContainerStyle={{ minWidth: 130 }}>
            <EditableText
              value={props.projectContract.taxRate ?? '0'}
              style={{ minWidth: 200 }}
              type={'percent'}
              validate={{
                required: {
                  value: true,
                  message: '値を入力してください',
                },
                pattern: {
                  value: /^[0-9]+$/,
                  message: '半角数字を入力してください。',
                },
                min: {
                  value: 0,
                  message: '0以上で入力してください',
                },
              }}
              onChange={async (value) => {
                updateProjectSales({
                  variables: {
                    id: props.projectContract!.id!,
                    input: {
                      contractType: props.projectContract.contractType,
                      contractTermType: props.projectContract.termType,
                      unitPriceType: props.projectContract.unitPriceType,
                      timeZoneId: props.projectContract.timeZone!.id,
                      roundTimeType: props.projectContract.roundTimeType,
                      fixSalesAmountWithoutTax: props.projectContract.fixSalesAmountWithoutTax,
                      salesRoundMinutes: props.projectContract.salesRoundMinutes,
                      taxRate: isNaN(value as any) ? 0 : (value as number) ?? 0,
                      costBudgetWithoutTax: props.projectContract.costBudgetWithoutTax,
                      year: props.projectContract.year,
                      month: props.projectContract.month,
                      versionNo: props.projectContract.versionNo,
                    },
                  },
                });
              }}
              textStyle={{ fontSize: 16 }}
              emptyText="-"
            />
          </TaskInfoElement>
        </Row>
        {OrganizationUtil.isTeamPlan(props.organization) && (
          <>
            <Row>
              <TaskInfoElement
                title={'予算'}
                style={{ flex: 1, maxWidth: 430 }}
                titleContainerStyle={{ minWidth: 130 }}>
                <EditableText
                  value={props.projectContract.costBudgetWithoutTax ?? ''}
                  style={{ minWidth: 200 }}
                  type={'currency'}
                  validate={{
                    // required: {
                    //   value: true,
                    //   message: '値を入力してください',
                    // },
                    pattern: {
                      value: /^[0-9]+$/,
                      message: '半角数字を入力してください。',
                    },
                    // min: {
                    //   value: 0,
                    //   message: '0以上で入力してください',
                    // },
                  }}
                  onChange={async (value) => {
                    updateProjectSales({
                      variables: {
                        id: props.projectContract!.id!,
                        input: {
                          contractType: props.projectContract.contractType,
                          contractTermType: props.projectContract.termType,
                          unitPriceType: props.projectContract.unitPriceType,
                          timeZoneId: props.projectContract.timeZone!.id,
                          roundTimeType: props.projectContract.roundTimeType,
                          fixSalesAmountWithoutTax: props.projectContract.fixSalesAmountWithoutTax,
                          salesRoundMinutes: props.projectContract.salesRoundMinutes,
                          taxRate: props.projectContract.taxRate,
                          costBudgetWithoutTax:
                            isNaN(value as any) || value == 0 ? null : Number(value),
                          year: props.projectContract.year,
                          month: props.projectContract.month,
                          versionNo: props.projectContract.versionNo,
                        },
                      },
                    });
                  }}
                  textStyle={{ fontSize: 16 }}
                  emptyText="-"
                />
              </TaskInfoElement>
            </Row>
            <Row>
              <TaskInfoElement
                title={'人件費'}
                style={{ flex: 1, maxWidth: 430 }}
                titleContainerStyle={{ minWidth: 130 }}
                contentFlexDirection={'row'}>
                <Typography variant={TypographyType.Normal}
                 style={[
                  (!!props.projectContract!.costBudgetWithoutTax && !!props.projectContract!.laborCostWithoutTax && (props.projectContract!.laborCostWithoutTax ?? 0) > (props.projectContract!.costBudgetWithoutTax ?? 0)) ? {color:  themeContext.colors.error, fontWeight: '600' } : {}
                ]}
                >
                  {new Intl.NumberFormat('ja-JP', {
                    style: 'currency',
                    currency: 'JPY',
                  }).format(props.projectContract.laborCostWithoutTax ?? '-')}
                </Typography>
              </TaskInfoElement>
            </Row>
            <Row>
              <TaskInfoElement
                title={`粗利${'\n'}（売上 - 人件費)`}
                style={{ flex: 1, maxWidth: 430 }}
                titleContainerStyle={{ minWidth: 130 }}
                contentFlexDirection={'row'}>
                <Typography variant={TypographyType.Normal}>
                  {new Intl.NumberFormat('ja-JP', {
                    style: 'currency',
                    currency: 'JPY',
                  }).format(props.projectContract.grossProfit ?? '-')}
                </Typography>
              </TaskInfoElement>
            </Row>
            <Row>
              <TaskInfoElement
                title={`粗利率${'\n'}（粗利 ÷ 売上)`}
                style={{ flex: 1, maxWidth: 430 }}
                titleContainerStyle={{ minWidth: 130 }}
                contentFlexDirection={'row'}>
                <Typography variant={TypographyType.Normal}>
                  {props.projectContract.grossProfitRatePercent
                    ? `${props.projectContract.grossProfitRatePercent}%`
                    : '-'}
                </Typography>
              </TaskInfoElement>
            </Row>
            <Row>
              <TaskInfoElement
                title={`労働分配率${'\n'}（人件費 ÷ 売上)`}
                style={{ flex: 1, maxWidth: 460 }}
                titleContainerStyle={{ minWidth: 130 }}
                contentFlexDirection={'row'}>
                <Typography variant={TypographyType.Normal}>
                  {props.projectContract.laborShare
                    ? `${props.projectContract.laborShare}%`
                    : '-'}
                </Typography>
              </TaskInfoElement>
            </Row>          </>
        )}
        <Row>
          <TaskInfoElement
            title={'合計作業時間'}
            style={{ flex: 1, maxWidth: 430 }}
            titleContainerStyle={{ minWidth: 130 }}
            contentFlexDirection={'row'}>
            <Typography variant={TypographyType.Normal}>
              {TimeUtil.formatForTask(props.projectContract.totalWorkingTimeSec ?? 0)}
            </Typography>
          </TaskInfoElement>
          {props.projectContract.roundTimeType !== ProjectContractRoundTimeType.None && (
            <TaskInfoElement
              title={'合計作業時間（丸め処理後）'}
              style={{ flex: 1, maxWidth: 430 }}
              titleContainerStyle={{ minWidth: 130 }}
              contentFlexDirection={'row'}>
              <Typography variant={TypographyType.Normal}>
                {TimeUtil.formatForTask(props.projectContract.roundedTotalWorkingTimeSec ?? 0)}
              </Typography>
            </TaskInfoElement>
          )}
        </Row>
        <Row>
          <TaskInfoElement
            title={'時間の丸め処理'}
            titleContainerStyle={{ width: 150 }}
            style={{ flex: 1 }}
            contentFlexDirection="row">
            <EditableText
              style={{ width: 100, marginRight: 20 }}
              value={props.projectContract.roundTimeType}
              type={'picker'}
              emptyText={'-'}
              pickerItems={[
                {
                  label: 'なし',
                  value: ProjectContractRoundTimeType.None,
                },
                {
                  label: '切り捨て',
                  value: ProjectContractRoundTimeType.Floor,
                },
                {
                  label: '切り上げ',
                  value: ProjectContractRoundTimeType.Ceil,
                },
                {
                  label: '四捨五入',
                  value: ProjectContractRoundTimeType.Round,
                },
              ]}
              onChange={(value) => {
                const roundTimeType = value as ProjectContractRoundTimeType;
                updateProjectSales({
                  variables: {
                    id: props.projectContract!.id!,
                    input: {
                      contractType: props.projectContract.contractType,
                      contractTermType: props.projectContract.termType,
                      unitPriceType: props.projectContract.unitPriceType,
                      timeZoneId: props.projectContract.timeZone!.id,
                      roundTimeType: roundTimeType,
                      fixSalesAmountWithoutTax: props.projectContract.fixSalesAmountWithoutTax,
                      salesRoundMinutes: props.projectContract.salesRoundMinutes,
                      taxRate: props.projectContract.taxRate,
                      costBudgetWithoutTax: props.projectContract.costBudgetWithoutTax,
                      year: props.projectContract.year,
                      month: props.projectContract.month,
                      versionNo: props.projectContract.versionNo,
                    },
                  },
                });
                setRoundTimeType(roundTimeType);
              }}
              textStyle={{ fontSize: 18 }}
            />
            {roundTimeType !== ProjectContractRoundTimeType.None && (
              <EditableText
                style={{ width: 100 }}
                value={props.projectContract.salesRoundMinutes ?? 15}
                type={'picker'}
                emptyText={'-'}
                pickerItems={[
                  {
                    label: '5分単位',
                    value: 5,
                  },
                  {
                    label: '10分単位',
                    value: 10,
                  },
                  {
                    label: '15分単位',
                    value: 15,
                  },
                  {
                    label: '30分単位',
                    value: 30,
                  },
                ]}
                onChange={(value) => {
                  updateProjectSales({
                    variables: {
                      id: props.projectContract!.id!,
                      input: {
                        contractType: props.projectContract.contractType,
                        contractTermType: props.projectContract.termType,
                        unitPriceType: props.projectContract.unitPriceType,
                        timeZoneId: props.projectContract.timeZone!.id,
                        roundTimeType: props.projectContract.roundTimeType,
                        fixSalesAmountWithoutTax: props.projectContract.fixSalesAmountWithoutTax,
                        salesRoundMinutes: isNaN(value as any) ? 0 : (value as number) ?? 5,
                        taxRate: props.projectContract.taxRate,
                        costBudgetWithoutTax: props.projectContract.costBudgetWithoutTax,
                        year: props.projectContract.year,
                        month: props.projectContract.month,
                        versionNo: props.projectContract.versionNo,
                      },
                    },
                  });
                }}
                textStyle={{ fontSize: 18 }}
              />
            )}
          </TaskInfoElement>
        </Row>
        {OrganizationUtil.isTeamPlan(props.organization) &&
          (props.projectContract.contractType === ProjectContractType.MeteredRate ||
            props.projectContract.contractType === ProjectContractType.SettlementRange) && (
            <Row>
              <TaskInfoElement
                title={'メンバー毎の料金'}
                titleContainerStyle={{ width: 150 }}
                style={{ flex: 1 }}>
                <EditableText
                  value={props.projectContract.unitPriceType}
                  type={'picker'}
                  emptyText={'-'}
                  style={{ minWidth: 300 }}
                  pickerItems={[
                    {
                      label: '全メンバー共通の料金',
                      value: ProjectContractUnitPriceType.Anyone,
                    },
                    {
                      label: 'メンバー毎に料金が異なる',
                      value: ProjectContractUnitPriceType.PerMember,
                    },
                  ]}
                  onChange={(value) => {
                    const unitPriceType = value as ProjectContractUnitPriceType;
                    updateProjectSales({
                      variables: {
                        id: props.projectContract!.id!,
                        input: {
                          contractType: props.projectContract.contractType,
                          contractTermType: props.projectContract.termType,
                          unitPriceType: unitPriceType,
                          timeZoneId: props.projectContract.timeZone!.id,
                          roundTimeType: props.projectContract.roundTimeType,
                          fixSalesAmountWithoutTax: props.projectContract.fixSalesAmountWithoutTax,
                          salesRoundMinutes: props.projectContract.salesRoundMinutes,
                          taxRate: props.projectContract.taxRate,
                          costBudgetWithoutTax: props.projectContract.costBudgetWithoutTax,
                          year: props.projectContract.year,
                          month: props.projectContract.month,
                          versionNo: props.projectContract.versionNo,
                        },
                      },
                    });
                  }}
                  textStyle={{ fontSize: 18 }}
                />
              </TaskInfoElement>
            </Row>
          )}
        {props.projectContract.contractType === ProjectContractType.MeteredRate &&
          props.projectContract.unitPriceType === ProjectContractUnitPriceType.Anyone && (
            <MeteredRateContractRow
              projectContract={props.projectContract}
              meteredRateContract={
                props.projectContract.allMemberMeteredRateContract as MeteredRateContract
              }
            />
          )}
        {props.projectContract.contractType === ProjectContractType.SettlementRange &&
          props.projectContract.unitPriceType === ProjectContractUnitPriceType.Anyone && (
            <SettlementRangeContractRow
              projectContract={props.projectContract}
              settlementRangeContract={
                props.projectContract.allMemberSettlementRangeContract as SettlementRangeContract
              }
            />
          )}
        {props.projectContract.contractType === ProjectContractType.MeteredRate &&
          props.projectContract.unitPriceType === ProjectContractUnitPriceType.PerMember && (
            <>
              <AddButton
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: 5,
                  paddingBottom: 10,
                }}
                onPress={async () => {
                  setShowRegisterMeteredRate(true);
                }}>
                <PlusIcon
                  size={12}
                  onPress={async () => {
                    setShowRegisterMeteredRate(true);
                  }}
                />
                <Typography
                  variant={TypographyType.Normal}
                  style={{ fontSize: 12, color: themeContext.colors.description }}
                  ellipsis={true}>
                  メンバーの単価を追加する
                </Typography>
              </AddButton>
              <RegisterMeteredRateContractModal
                projectContract={props.projectContract}
                team={props.project.team}
                showModal={showRegisterMeteredRate}
                onCloseModal={() => setShowRegisterMeteredRate(false)}
                onPressYes={() => setShowRegisterMeteredRate(false)}
              />
              {props.projectContract.membersMeteredRateContract?.map((data) => {
                return (
                  <MeteredRateContractRow
                    projectContract={props.projectContract}
                    meteredRateContract={data!}
                    key={data!.id}
                  />
                );
              })}
            </>
          )}
        {props.projectContract.contractType === ProjectContractType.SettlementRange &&
          props.projectContract.unitPriceType === ProjectContractUnitPriceType.PerMember && (
            <>
              <AddButton
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: 5,
                  paddingBottom: 10,
                }}
                onPress={async () => {
                  setShowRegisterMeteredRate(true);
                }}>
                <PlusIcon
                  size={12}
                  onPress={async () => {
                    setShowRegisterMeteredRate(true);
                  }}
                />
                <Typography
                  variant={TypographyType.Normal}
                  style={{ fontSize: 12, color: themeContext.colors.description }}
                  ellipsis={true}>
                  メンバーの単価を追加する
                </Typography>
              </AddButton>
              <RegisterSettlementRangeContractModal
                projectContract={props.projectContract}
                team={props.project.team}
                showModal={showRegisterMeteredRate}
                onCloseModal={() => setShowRegisterMeteredRate(false)}
                onPressYes={() => setShowRegisterMeteredRate(false)}
              />
              {props.projectContract.membersSettlementRangeContract?.map((data) => {
                return (
                  <SettlementRangeContractRow
                    projectContract={props.projectContract}
                    settlementRangeContract={data!}
                    key={data!.id}
                  />
                );
              })}
            </>
          )}
        {/* TODO 全員・もしくはメンバーごとの合計作業時間の表示 */}
        <Button
          text={'削除する'}
          style={{
            minWidth: 100,
            marginRight: 10,
            marginVertical: 10,
            borderColor: themeContext.colors.error,
            borderRadius: 3,
            borderWidth: 1,
            backgroundColor: 'transparent',
          }}
          textStyle={{ color: themeContext.colors.error }}
          onPress={async () => {
            setShowDeleteModal(true);
          }}
        />
      </Conatiner>
      <UpdateProjectContractTermModal
        projectContract={props.projectContract}
        timeZones={props.timeZones}
        existsProjectContracts={props.existsProjectContracts}
        showModal={showUpdateTermModal}
        onPressYes={() => setShowUpdateTermModal(false)}
        onCloseModal={() => setShowUpdateTermModal(false)}
      />
      <DeleteProjectConfirmModal
        projectContract={props.projectContract}
        showModal={showDeleteModal}
        onPressYes={async () => {
          deleteProjectContract();
          props.onDelete();
        }}
        onCloseModal={() => setShowDeleteModal(false)}
      />
    </>
  );
};

interface IProjectSalesViewProps {
  project: Project;
  year?: number
  month?: number
}

const ProjectSalesView = (props: IProjectSalesViewProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const history = useHistory();
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [projectContractId, setProjectContractId] = useState<string | null>(null);
  const [notAllowedPlan, setNotAllowedPlan] = useState(false);
  const { data, loading } = useProjectContractsByProjectIdQuery({
    variables: {
      projectId: props.project.id!,
    },
    fetchPolicy: 'network-only',
  });
  const { loading: loadingTimeZone, data: dataTimeZone } = useTimeZonesQuery();
  const { loading: organizationLoading, data: organizationData } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId,
    },
    fetchPolicy: 'network-only',
  });

  // Basicプランの場合には取引先管理できないようにする
  useEffect(() => {
    setTimeout(() => {
      if (organizationData?.organization?.plan.code === Plan.Basic) {
        setNotAllowedPlan(true);
      }
    }, 1000);
  }, [organizationData]);

  useEffect(() => {
    if(props.year && props.month){
      const findId = data?.projectContractsByProjectId?.filter(con => {
        return con!.year == props.year && con!.month == props.month
      })?.[0]?.id;

      if(!!findId){
        setProjectContractId(findId);
      } else {
        const id = data?.projectContractsByProjectId?.slice().sort((a, b) => {
          if (a!.termType === ProjectContractTermType.Total) {
            return 1;
          }
          if (b!.termType === ProjectContractTermType.Total) {
            return 1;
          }
          return new Date(b!.startDateTime).getTime() - new Date(a!.startDateTime).getTime();
        })[0]?.id;
        setProjectContractId(id ?? null);
      }
    } else {
      if (!projectContractId) {
        const id = data?.projectContractsByProjectId?.slice().sort((a, b) => {
          if (a!.termType === ProjectContractTermType.Total) {
            return 1;
          }
          if (b!.termType === ProjectContractTermType.Total) {
            return 1;
          }
          return new Date(b!.startDateTime).getTime() - new Date(a!.startDateTime).getTime();
        })[0]?.id;
        setProjectContractId(id ?? null);
      }
    }
  }, [data?.projectContractsByProjectId, props.year, props.month]);

  if (
    loading ||
    !data?.projectContractsByProjectId ||
    loadingTimeZone ||
    !dataTimeZone?.timeZones ||
    organizationLoading ||
    !organizationData?.organization
  ) {
    return <></>;
  }

  return (
    <PlanNotAllowedView isNotAllowedPlan={notAllowedPlan} style={{ height: 'calc(100vh)' }}>
      <Conatiner>
        <AddButton
          style={{
            display: 'flex',
            flexDirection: 'row',
            paddingHorizontal: 5,
            marginTop: 10,
          }}
          onPress={() => {
            // if (!props.me.taskCreatePermissionFlg) {
            //   setShowErrorModal(true);
            //   return;
            // }
            // props.setShowNewTask(true);
            setShowCreateModal(true);
          }}>
          <PlusIcon
            size={14}
            containerStyle={{ marginLeft: 10 }}
            onPress={() => {
              // if (!props.me.taskCreatePermissionFlg) {
              //   setShowErrorModal(true);
              //   return;
              // }
              // props.setShowNewTask(true);
              setShowCreateModal(true);
            }}>
            <Typography
              variant={TypographyType.Normal}
              style={{ fontSize: 14, color: themeContext.colors.description }}>
              契約情報を登録する
            </Typography>
          </PlusIcon>
        </AddButton>
        <RegisterProjectContractModal
          showModal={showCreateModal}
          organization={organizationData.organization}
          project={props.project}
          timeZones={dataTimeZone.timeZones! as TimeZone[]}
          existsProjectContracts={data.projectContractsByProjectId as ProjectContract[]}
          onPressYes={(value) => {
            setShowCreateModal(false);
            setProjectContractId(value.id!);
            history.push(UrlUtil.removeSearchs(['contractYear', 'contractMonth']))
          }}
          onCloseModal={() => setShowCreateModal(false)}
        />
        {(data?.projectContractsByProjectId.length > 1 ||
          (data?.projectContractsByProjectId.length === 1 && !projectContractId)) && (
          <>
            <View
              style={{
                width: '100%',
                borderBottomColor: themeContext.colors.separator,
                borderBottomWidth: 2,
              }}></View>
            <TaskInfoElement
              title={'契約情報を選択：'}
              style={{ flex: 1 }}
              titleContainerStyle={{ minWidth: 120 }}>
              <EditableText
                value={projectContractId}
                type={'picker'}
                isSearchable
                emptyText={'-'}
                pickerItems={data?.projectContractsByProjectId?.map((projectContract) => {
                  return {
                    label: projectContract!.startDateTime
                      ? moment(projectContract!.startDateTime).format('YYYY年MM月')
                      : '期間指定なし',
                    value: projectContract!.id,
                  };
                })}
                onChange={(value) => {
                  setProjectContractId(value as string);
                  history.push(UrlUtil.removeSearchs(['contractYear', 'contractMonth']))
                }}
                textStyle={{ fontSize: 18 }}
                style={{ minWidth: 400 }}
              />
            </TaskInfoElement>
            <View
              style={{
                width: '100%',
                borderBottomColor: themeContext.colors.separator,
                borderBottomWidth: 2,
              }}></View>
          </>
        )}
        {data?.projectContractsByProjectId
          ?.filter((contract) => contract!.id === projectContractId)
          .map((projectContract) => {
            return (
              <ProjectContractInfo
                organization={organizationData!.organization!}
                project={props.project}
                projectContract={projectContract!}
                timeZones={(dataTimeZone.timeZones as TimeZone[]) ?? []}
                existsProjectContracts={data.projectContractsByProjectId as ProjectContract[]}
                onDelete={() => {
                  if (projectContractId === projectContract?.id) {
                    setProjectContractId(null);
                    history.push(UrlUtil.removeSearchs(['contractYear', 'contractMonth']))
                  }
                }}
              />
            );
          })}
      </Conatiner>
    </PlanNotAllowedView>
  );
};

export default ProjectSalesView;
