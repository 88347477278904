import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import styled, { ThemeContext } from 'styled-components/native';
import useDimensions from 'use-dimensions';
import {
  CalendarEvent,
  useCalendarEventQuery,
  useProjectQuery,
} from '../../../../../graphql/api/API';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import { IStyleTheme, IThemePart } from '../../../../theme';
import CalendarEventDetailCommon from './common';
import CalendarEventDetailCommonLoading from './common-loading';
import CalendarEventDetailLoading from './loading';
import CalendarEventDetailStandardInfo from './standard-info';
import CustomScrollView from '../../../../presentational/atoms/custom-scroll-view';
import queryString from 'query-string';
import CalendarEventDetailWorkingHistory from './working-history';
import CalendarEventDetailWorkingSchedule from './working-schedule';

interface IContainerProps extends IStyleTheme {
  isOpen: boolean;
  windowWidth: number;
  isOpenRightSideBar: boolean;
}

const Container = styled.View<IContainerProps>`
  height: calc(100vh - 57px);
  right: ${(props: IContainerProps) =>
    props.windowWidth < 1260 ? '-490px' : `-${Math.max(props.windowWidth / 2.2, 540) + 10}px`};
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.4s;
  width: ${(props: IContainerProps) =>
    props.isOpen
      ? `${Math.max((props.windowWidth - (props.isOpenRightSideBar ? 500 : 0)) / 2.2, 540)}px`
      : '1px'}; // Note: 閉じている時に幅を 1px ではなく、0pxにすると、なぜかSafariは幅を300pxぐらい取る挙動になってしまう。それによって画面右側300pxぐらいに透明な状態のエリアがかぶさってしまい、画面操作を阻害してしまう。Safari対応のために幅を1pxに指定するようにしている。
  transform: ${(props: IContainerProps) =>
    `translateX(${props.isOpen ? `-${Math.max(props.windowWidth / 2.2, 540)}px` : '0'})`};
  background-color: ${(props: IContainerProps) => props.theme.colors.baseColor};
  overflow: hidden;
`;

interface IContainerInnerProps extends IStyleTheme {
  isShow: boolean;
}

const ContainerInner = styled.View<IContainerInnerProps>``;

interface ICalendarEventDetailInnerProps {
  calendarEvent: CalendarEvent | null;
  loading: boolean;
  isOpenRightSideBar: boolean;
}

const CalendarEventDetailInner = (props: ICalendarEventDetailInnerProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const { pathname } = useLocation();
  const [loginUser, _] = useContext(LoginUserContext);
  const dimensions = useDimensions();
  const windowWidth = dimensions.window.width;
  const ref = useRef();

  const { loading, data } = useProjectQuery({
    variables: {
      id: props.calendarEvent?.projectId,
    },
    skip: !props.calendarEvent,
  });

  if (props.loading || loading) {
    return (
      <Container
        isOpen={true}
        windowWidth={windowWidth}
        isOpenRightSideBar={props.isOpenRightSideBar}>
        <CustomScrollView scrollViewRef={ref} style={{ height: 'calc(100vh - 57px)' }}>
          <ContainerInner isShow={true}>
            <CalendarEventDetailCommonLoading>
              <CalendarEventDetailLoading />
            </CalendarEventDetailCommonLoading>
          </ContainerInner>
        </CustomScrollView>
      </Container>
    );
  }
  if (props.calendarEvent) {
    if (/.*\/calendar-event\/(.+)\/detail\//.test(pathname)) {
      return (
        <Container
          isOpen={true}
          windowWidth={windowWidth}
          isOpenRightSideBar={props.isOpenRightSideBar}>
          <CustomScrollView scrollViewRef={ref} style={{ height: 'calc(100vh - 57px)' }}>
            <ContainerInner isShow={true}>
              <CalendarEventDetailCommon
                organizationId={loginUser?.organizationId!}
                calendarEvent={props.calendarEvent}>
                <CalendarEventDetailStandardInfo
                  organizationId={loginUser?.organizationId!}
                  project={data!.project!}
                  calendarEvent={props.calendarEvent}
                  parentScrollViewRef={ref}
                />
              </CalendarEventDetailCommon>
            </ContainerInner>
          </CustomScrollView>
        </Container>
      );
    }
    if (/.*\/calendar-event\/(.+)\/working-history\//.test(pathname)) {
      return (
        <Container
          isOpen={true}
          windowWidth={windowWidth}
          isOpenRightSideBar={props.isOpenRightSideBar}>
          <CustomScrollView scrollViewRef={ref} style={{ height: 'calc(100vh - 57px)' }}>
            <ContainerInner isShow={true}>
              <CalendarEventDetailCommon
                organizationId={loginUser?.organizationId!}
                calendarEvent={props.calendarEvent}>
                <CalendarEventDetailWorkingHistory calendarEvent={props.calendarEvent} />
              </CalendarEventDetailCommon>
            </ContainerInner>
          </CustomScrollView>
        </Container>
      );
    }
    if (/.*\/calendar-event\/(.+)\/working-shedule\//.test(pathname)) {
      return (
        <Container
          isOpen={true}
          windowWidth={windowWidth}
          isOpenRightSideBar={props.isOpenRightSideBar}>
          <CustomScrollView scrollViewRef={ref} style={{ height: 'calc(100vh - 57px)' }}>
            <ContainerInner isShow={true}>
              <CalendarEventDetailCommon
                organizationId={loginUser?.organizationId!}
                calendarEvent={props.calendarEvent}>
                <CalendarEventDetailWorkingSchedule calendarEvent={props.calendarEvent} />
              </CalendarEventDetailCommon>
            </ContainerInner>
          </CustomScrollView>
        </Container>
      );
    }
  }
  return (
    <Container
      isOpen={false}
      windowWidth={windowWidth}
      isOpenRightSideBar={props.isOpenRightSideBar}
    />
  );
};

interface ICalendarEventDetailWithFetchDataProps {
  calendarEventId: string | null;
  isOpenRightSideBar: boolean;
}

const CalendarEventDetailWithFetchData = (props: ICalendarEventDetailWithFetchDataProps) => {
  const { loading, data } = useCalendarEventQuery({
    variables: {
      id: props.calendarEventId,
    },
    fetchPolicy: 'network-only',
    skip: props.calendarEventId === null,
  });

  return (
    <CalendarEventDetailInner
      loading={loading}
      calendarEvent={(data?.calendarEvent as CalendarEvent) || null}
      isOpenRightSideBar={props.isOpenRightSideBar}
    />
  );
};

const CalendarEventDetail = () => {
  const { pathname, search } = useLocation();
  const calendarEventMatches = pathname.match(/.*\/calendar-event\/(.+)\/[^\/]+/);
  let calendarEventId: string | null = null;
  if (calendarEventMatches && calendarEventMatches.length > 1) {
    calendarEventId = calendarEventMatches[1].split('/')[0];
  }

  const isOpenRightSideBar = !!queryString.parse(search).right;
  return (
    <CalendarEventDetailWithFetchData
      calendarEventId={calendarEventId}
      isOpenRightSideBar={isOpenRightSideBar}
    />
  );
};

export default CalendarEventDetail;
