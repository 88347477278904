import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { View, Image, TouchableOpacity, Text } from 'react-native';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../../theme';
import TaskSummary from '../task-summary';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import { useHistory, useLocation, useParams } from 'react-router';
import {
  Member,
  Organization,
  Plan,
  Project,
  ProjectCompleteFilter,
  ProjectContract,
  ProjectContractSortKey,
  ProjectFavoriteFilter,
  ProjectInitialViewType,
  ProjectSortKey,
  SortOrder,
  useMeQuery,
  useMySettingsQuery,
  useOrganizationQuery,
  useTeamProjectContractsQuery,
  useTeamProjectsQuery,
  useTeamQuery,
  useUpdateProjectSortNoInListMutation,
} from '../../../../../graphql/api/API';
import VirtualizedFlatList, {
  GlobalDragContextProvider,
} from '../../../../presentational/atoms/list2/virtualized-flat-list';
import { ProjectFilter, ProjectFilterContext } from '../../templates/app/team-projects';
import moment from 'moment-timezone';
import useDimensions from 'use-dimensions';
import noData02 from '../../../../../base64Images/no-data/no-data-2';
import PlusIcon from '../../../../presentational/molecules/image-icon/plus';
import queryString from 'query-string';
import CaretUpIcon from '../../../../presentational/molecules/image-icon/caret-up';
import CaretDownIcon from '../../../../presentational/molecules/image-icon/caret-down';
import CaretSetIcon from '../../../../presentational/molecules/image-icon/caret-set';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import when from '../../../../../lang-extention/When';
import Modal from '../../../../presentational/molecules/modal';
import Button from '../../../../presentational/atoms/button';
import ErrorMessageModal from '../error-message-modal';
import ProjectCreateDialog from '../project-create-dialog';
import ProjectSummary from '../project-contract-summary';
import LoginUtil from '../../../../../modules/auth/LoginUtil';
import ColorUtil from '../../../../../util/ColorUtil';
import Form from '../../../../presentational/atoms/form';
import Input from '../../../../presentational/atoms/input';
import SearchIcon from '../../../../presentational/molecules/image-icon/search';
import OrganizationUtil from '../../../../../util/OrganizationUtil';
import SelectButton from '../../../../presentational/molecules/select-button';

const Container = styled.View``;

interface IProjectItemProps extends IStyleTheme {
  isComplete: boolean;
}

const ProjectItem = styled.View<IProjectItemProps>`
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  min-width: 200px;
  border-bottom-width: 1px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
  background-color: ${(props: IProjectItemProps) =>
    props.isComplete
      ? ColorUtil.lignten(props.theme.colors.separator, 3)
      : props.theme.colors.baseColor};
`;

const ProjectAddButton = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  padding-right: 5px;
  padding-left: 5px;
`;

const TopMenu = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
`;

interface ITaskListWrapperProps extends IStyleTheme {
  isFavoritePage: boolean;
  isAssignedPage: boolean;
}

const TaskListWrapper = styled.View<ITaskListWrapperProps>`
  height: ${(props: ITaskListWrapperProps) =>
    props.isFavoritePage || props.isAssignedPage
      ? 'calc(100vh - 57px - 90px - 45px - 70px)'
      : 'calc(100vh - 57px - 90px - 45px)'};
`;
// 本当はoverflow-xはvisibleにしたいのだが、CSSの仕様で、overflow-yを指定した場合には、xの挙動もそちらで上書きされるため実現できない

const TaskAddButton = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  padding-right: 5px;
  padding-left: 5px;
`;

const TaskRegisterConatiner = styled.View`
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  align-items: flex-start;
  z-index: 2; //DateTimePickerを使用する際には、外側の要素のz-indexを指定しないと正しく表示されないケースがあるので、ここで指定している
`;

const Row = styled.View`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: space-between;
`;

interface IHeaderProps {
  organization: Organization;
  teamId: string;
  sortKey: ProjectContractSortKey;
  sortOrder: SortOrder | null;
  isFavoritePage: boolean;
  isAssignedPage: boolean;
  showTeamName: boolean;
}

const Header = (props: IHeaderProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const { window } = useDimensions();
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const history = useHistory();
  const windowWidth = window.width;

  return (
    <View
      style={{
        marginTop: 10,
        marginRight: 10,
        paddingLeft: 10,
        paddingRight: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}>
      <View
        style={{
          flexDirection: 'row',
          flex: 1,
          minWidth: 67,
          alignItems: 'center',
        }}>
        {props.showTeamName &&
          (props.organization.plan.code === Plan.Business ||
            props.organization.plan.code === Plan.Enterprise) &&
          windowWidth >= 1550 && (
            <TouchableOpacity
              style={{ flexDirection: 'row', alignItems: 'flex-start', marginLeft: 50 }}
              onPress={() => {
                history.push(
                  queryString.stringifyUrl({
                    url: props.isFavoritePage
                      ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                      : props.isAssignedPage
                      ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                      : `/app/${props.organization.id!}/${props.teamId}/projects/contracts/list/`,
                    query: {
                      sortKey: when(props.sortOrder) //
                        .on(
                          (v) => v === null || v === undefined,
                          () => ProjectContractSortKey.TeamName
                        )
                        .on(
                          (v) => v === SortOrder.Asc,
                          () => ProjectContractSortKey.TeamName
                        )
                        .otherwise(() => null),
                      sortOrder: when(props.sortOrder) //
                        .on(
                          (v) => v === null || v === undefined,
                          () => SortOrder.Asc
                        )
                        .on(
                          (v) => v === SortOrder.Asc,
                          () => SortOrder.Desc
                        )
                        .otherwise(() => null),
                    },
                  })
                );
              }}>
              {props.sortKey === ProjectContractSortKey.TeamName ? (
                when(props.sortOrder) //
                  .on(
                    (v) => v === null || v === undefined,
                    () => (
                      <CaretSetIcon
                        size={12}
                        containerStyle={{
                          opacity: 0.2,
                          marginLeft: 3,
                        }}
                        onPress={() => {
                          history.push(
                            queryString.stringifyUrl({
                              url: props.isFavoritePage
                                ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                                : props.isAssignedPage
                                ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                                : `/app/${props.organization.id!}/${
                                    props.teamId
                                  }/projects/contracts/list/`,
                              query: {
                                sortKey: ProjectContractSortKey.TeamName,
                                sortOrder: SortOrder.Asc,
                              },
                            })
                          );
                        }}
                      />
                    )
                  )
                  .on(
                    (v) => v === SortOrder.Asc,
                    () => (
                      <CaretUpIcon
                        size={10}
                        containerStyle={{ marginLeft: 3 }}
                        onPress={() => {
                          history.push(
                            queryString.stringifyUrl({
                              url: props.isFavoritePage
                                ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                                : props.isAssignedPage
                                ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                                : `/app/${props.organization.id!}/${
                                    props.teamId
                                  }/projects/contracts/list/`,
                              query: {
                                sortKey: ProjectContractSortKey.TeamName,
                                sortOrder: SortOrder.Desc,
                              },
                            })
                          );
                        }}
                      />
                    )
                  )
                  .otherwise(() => (
                    <CaretDownIcon
                      size={10}
                      containerStyle={{ marginLeft: 3 }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: props.isFavoritePage
                              ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                              : props.isAssignedPage
                              ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                              : `/app/${props.organization.id!}/${
                                  props.teamId
                                }/projects/contracts/list/`,
                            query: {
                              sortKey: null,
                              sortOrder: null,
                            },
                          })
                        );
                      }}
                    />
                  ))
              ) : (
                <CaretSetIcon
                  size={12}
                  containerStyle={{
                    opacity: 0.2,
                    marginLeft: 3,
                  }}
                  onPress={() => {
                    history.push(
                      queryString.stringifyUrl({
                        url: props.isFavoritePage
                          ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                          : props.isAssignedPage
                          ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                          : `/app/${props.organization.id!}/${props.teamId}/projects/contracts/list/`,
                        query: {
                          sortKey: ProjectContractSortKey.TeamName,
                          sortOrder: SortOrder.Asc,
                        },
                      })
                    );
                  }}
                />
              )}
              <Typography
                variant={TypographyType.Normal}
                style={{
                  fontSize: 12,
                  marginLeft: 0,
                  color: themeContext.colors.description,
                  textAlign: 'left',
                }}>
                チーム名
              </Typography>
            </TouchableOpacity>
          )}
        <TouchableOpacity
          style={{ flexDirection: 'row', flex: 1 }}
          onPress={() => {
            history.push(
              queryString.stringifyUrl({
                url: props.isFavoritePage
                  ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                  : props.isAssignedPage
                  ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                  : `/app/${props.organization.id!}/${props.teamId}/projects/contracts/list/`,
                query: {
                  sortKey: when(props.sortOrder) //
                    .on(
                      (v) => v === null || v === undefined,
                      () => ProjectContractSortKey.ProjectName
                    )
                    .on(
                      (v) => v === SortOrder.Asc,
                      () => ProjectContractSortKey.ProjectName
                    )
                    .otherwise(() => null),
                  sortOrder: when(props.sortOrder) //
                    .on(
                      (v) => v === null || v === undefined,
                      () => SortOrder.Asc
                    )
                    .on(
                      (v) => v === SortOrder.Asc,
                      () => SortOrder.Desc
                    )
                    .otherwise(() => null),
                },
              })
            );
          }}>
          {props.sortKey === ProjectContractSortKey.ProjectName ? (
            when(props.sortOrder) //
              .on(
                (v) => v === null || v === undefined,
                () => (
                  <CaretSetIcon
                    size={12}
                    containerStyle={{
                      opacity: 0.2,
                      marginLeft: 3,
                    }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: props.isFavoritePage
                            ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                            : props.isAssignedPage
                            ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                            : `/app/${props.organization.id!}/${props.teamId}/projects/contracts/list/`,
                          query: {
                            sortKey: ProjectContractSortKey.ProjectName,
                            sortOrder: SortOrder.Asc,
                          },
                        })
                      );
                    }}
                  />
                )
              )
              .on(
                (v) => v === SortOrder.Asc,
                () => (
                  <CaretUpIcon
                    size={10}
                    containerStyle={{ marginLeft: 3 }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: props.isFavoritePage
                            ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                            : props.isAssignedPage
                            ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                            : `/app/${props.organization.id!}/${props.teamId}/projects/contracts/list/`,
                          query: {
                            sortKey: ProjectContractSortKey.ProjectName,
                            sortOrder: SortOrder.Desc,
                          },
                        })
                      );
                    }}
                  />
                )
              )
              .otherwise(() => (
                <CaretDownIcon
                  size={10}
                  containerStyle={{ marginLeft: 3 }}
                  onPress={() => {
                    history.push(
                      queryString.stringifyUrl({
                        url: props.isFavoritePage
                          ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                          : props.isAssignedPage
                          ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                          : `/app/${props.organization.id!}/${props.teamId}/projects/contracts/list/`,
                        query: {
                          sortKey: null,
                          sortOrder: null,
                        },
                      })
                    );
                  }}
                />
              ))
          ) : (
            <CaretSetIcon
              size={12}
              containerStyle={{
                opacity: 0.2,
                marginLeft: 3,
              }}
              onPress={() => {
                history.push(
                  queryString.stringifyUrl({
                    url: props.isFavoritePage
                      ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                      : props.isAssignedPage
                      ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                      : `/app/${props.organization.id!}/${props.teamId}/projects/contracts/list/`,
                    query: {
                      sortKey: ProjectContractSortKey.ProjectName,
                      sortOrder: SortOrder.Asc,
                    },
                  })
                );
              }}
            />
          )}
          <Typography
            variant={TypographyType.Normal}
            style={{
              fontSize: 12,
              marginLeft: 0,
              color: themeContext.colors.description,
              textAlign: 'left',
            }}>
            プロジェクト名
          </Typography>
        </TouchableOpacity>
      </View>
    
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      {windowWidth > 1700 && (
        <TouchableOpacity
          style={{ flexDirection: 'row', width: 105 }}
          onPress={() => {
            history.push(
              queryString.stringifyUrl({
                url: props.isFavoritePage
                  ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                  : props.isAssignedPage
                  ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                  : `/app/${props.organization.id!}/${props.teamId}/projects/contracts/list/`,
                query: {
                  sortKey: when(props.sortOrder) //
                    .on(
                      (v) => v === null || v === undefined,
                      () => ProjectContractSortKey.ProjectKey
                    )
                    .on(
                      (v) => v === SortOrder.Asc,
                      () => ProjectContractSortKey.ProjectKey
                    )
                    .otherwise(() => null),
                  sortOrder: when(props.sortOrder) //
                    .on(
                      (v) => v === null || v === undefined,
                      () => SortOrder.Asc
                    )
                    .on(
                      (v) => v === SortOrder.Asc,
                      () => SortOrder.Desc
                    )
                    .otherwise(() => null),
                },
              })
            );
          }}>
          {props.sortKey === ProjectContractSortKey.ProjectKey ? (
            when(props.sortOrder) //
              .on(
                (v) => v === null || v === undefined,
                () => (
                  <CaretSetIcon
                    size={12}
                    containerStyle={{
                      opacity: 0.2,
                      marginLeft: 3,
                    }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: props.isFavoritePage
                            ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                            : props.isAssignedPage
                            ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                            : `/app/${props.organization.id!}/${props.teamId}/projects/contracts/list/`,
                          query: {
                            sortKey: ProjectContractSortKey.ProjectKey,
                            sortOrder: SortOrder.Asc,
                          },
                        })
                      );
                    }}
                  />
                )
              )
              .on(
                (v) => v === SortOrder.Asc,
                () => (
                  <CaretUpIcon
                    size={10}
                    containerStyle={{ marginLeft: 3 }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: props.isFavoritePage
                            ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                            : props.isAssignedPage
                            ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                            : `/app/${props.organization.id!}/${props.teamId}/projects/contracts/list/`,
                          query: {
                            sortKey: ProjectContractSortKey.ProjectKey,
                            sortOrder: SortOrder.Desc,
                          },
                        })
                      );
                    }}
                  />
                )
              )
              .otherwise(() => (
                <CaretDownIcon
                  size={10}
                  containerStyle={{ marginLeft: 3 }}
                  onPress={() => {
                    history.push(
                      queryString.stringifyUrl({
                        url: props.isFavoritePage
                          ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                          : props.isAssignedPage
                          ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                          : `/app/${props.organization.id!}/${props.teamId}/projects/contracts/list/`,
                        query: {
                          sortKey: null,
                          sortOrder: null,
                        },
                      })
                    );
                  }}
                />
              ))
          ) : (
            <CaretSetIcon
              size={12}
              containerStyle={{
                opacity: 0.2,
                marginLeft: 3,
              }}
              onPress={() => {
                history.push(
                  queryString.stringifyUrl({
                    url: props.isFavoritePage
                      ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                      : props.isAssignedPage
                      ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                      : `/app/${props.organization.id!}/${props.teamId}/projects/contracts/list/`,
                    query: {
                      sortKey: ProjectContractSortKey.ProjectKey,
                      sortOrder: SortOrder.Asc,
                    },
                  })
                );
              }}
            />
          )}
          <Typography
            variant={TypographyType.Normal}
            style={{
              fontSize: 12,
              marginLeft: 0,
              color: themeContext.colors.description,
              textAlign: 'left',
            }}>
            管理番号
          </Typography>
        </TouchableOpacity>
        )}
      
        {windowWidth > 1600 && (
          <TouchableOpacity
            style={{ flexDirection: 'row', width: 160 }}
            onPress={() => {
              history.push(
                queryString.stringifyUrl({
                  url: props.isFavoritePage
                    ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                    : props.isAssignedPage
                    ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                    : `/app/${props.organization.id!}/${props.teamId}/projects/contracts/list/`,
                  query: {
                    sortKey: when(props.sortOrder) //
                      .on(
                        (v) => v === null || v === undefined,
                        () => ProjectContractSortKey.ClientName
                      )
                      .on(
                        (v) => v === SortOrder.Asc,
                        () => ProjectContractSortKey.ClientName
                      )
                      .otherwise(() => null),
                    sortOrder: when(props.sortOrder) //
                      .on(
                        (v) => v === null || v === undefined,
                        () => SortOrder.Asc
                      )
                      .on(
                        (v) => v === SortOrder.Asc,
                        () => SortOrder.Desc
                      )
                      .otherwise(() => null),
                  },
                })
              );
            }}>
            {props.sortKey === ProjectContractSortKey.ClientName ? (
              when(props.sortOrder) //
                .on(
                  (v) => v === null || v === undefined,
                  () => (
                    <CaretSetIcon
                      size={12}
                      containerStyle={{
                        opacity: 0.2,
                        marginLeft: 3,
                      }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: props.isFavoritePage
                              ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                              : props.isAssignedPage
                              ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                              : `/app/${props.organization.id!}/${props.teamId}/projects/contracts/list/`,
                            query: {
                              sortKey: ProjectContractSortKey.ClientName,
                              sortOrder: SortOrder.Asc,
                            },
                          })
                        );
                      }}
                    />
                  )
                )
                .on(
                  (v) => v === SortOrder.Asc,
                  () => (
                    <CaretUpIcon
                      size={10}
                      containerStyle={{ marginLeft: 3 }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: props.isFavoritePage
                              ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                              : props.isAssignedPage
                              ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                              : `/app/${props.organization.id!}/${props.teamId}/projects/contracts/list/`,
                            query: {
                              sortKey: ProjectContractSortKey.ClientName,
                              sortOrder: SortOrder.Desc,
                            },
                          })
                        );
                      }}
                    />
                  )
                )
                .otherwise(() => (
                  <CaretDownIcon
                    size={10}
                    containerStyle={{ marginLeft: 3 }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: props.isFavoritePage
                            ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                            : props.isAssignedPage
                            ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                            : `/app/${props.organization.id!}/${props.teamId}/projects/contracts/list/`,
                          query: {
                            sortKey: null,
                            sortOrder: null,
                          },
                        })
                      );
                    }}
                  />
                ))
            ) : (
              <CaretSetIcon
                size={12}
                containerStyle={{
                  opacity: 0.2,
                  marginLeft: 3,
                }}
                onPress={() => {
                  history.push(
                    queryString.stringifyUrl({
                      url: props.isFavoritePage
                        ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                        : props.isAssignedPage
                        ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                        : `/app/${props.organization.id!}/${props.teamId}/projects/contracts/list/`,
                      query: {
                        sortKey: ProjectContractSortKey.ClientName,
                        sortOrder: SortOrder.Asc,
                      },
                    })
                  );
                }}
              />
            )}
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontSize: 12,
                marginLeft: 0,
                color: themeContext.colors.description,
                textAlign: 'left',
              }}>
              取引先
            </Typography>
          </TouchableOpacity>
        )}
        
        {windowWidth > 200 && (
          <TouchableOpacity
            style={{ flexDirection: 'row', width: 100, paddingLeft: 10 }}
            onPress={() => {
              history.push(
                queryString.stringifyUrl({
                  url: props.isFavoritePage
                    ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                    : props.isAssignedPage
                    ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                    : `/app/${props.organization.id!}/${props.teamId}/projects/contracts/list/`,
                  query: {
                    sortKey: when(props.sortOrder) //
                      .on(
                        (v) => v === null || v === undefined,
                        () => ProjectContractSortKey.ContractTerm
                      )
                      .on(
                        (v) => v === SortOrder.Asc,
                        () => ProjectContractSortKey.ContractTerm
                      )
                      .otherwise(() => null),
                    sortOrder: when(props.sortOrder) //
                      .on(
                        (v) => v === null || v === undefined,
                        () => SortOrder.Asc
                      )
                      .on(
                        (v) => v === SortOrder.Asc,
                        () => SortOrder.Desc
                      )
                      .otherwise(() => null),
                  },
                })
              );
            }}>
            {props.sortKey === ProjectContractSortKey.ContractTerm ? (
              when(props.sortOrder) //
                .on(
                  (v) => v === null || v === undefined,
                  () => (
                    <CaretSetIcon
                      size={12}
                      containerStyle={{
                        opacity: 0.2,
                        marginLeft: 3,
                        width: 10,
                        justifyContent: 'flex-end',
                      }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: props.isFavoritePage
                              ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                              : props.isAssignedPage
                              ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                              : `/app/${props.organization.id!}/${
                                  props.teamId
                                }/projects/contracts/list/`,
                            query: {
                              sortKey: ProjectContractSortKey.ContractTerm,
                              sortOrder: SortOrder.Asc,
                            },
                          })
                        );
                      }}
                    />
                  )
                )
                .on(
                  (v) => v === SortOrder.Asc,
                  () => (
                    <CaretUpIcon
                      size={10}
                      containerStyle={{ marginLeft: 3, width: 10, justifyContent: 'flex-end' }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: props.isFavoritePage
                              ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                              : props.isAssignedPage
                              ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                              : `/app/${props.organization.id!}/${
                                  props.teamId
                                }/projects/contracts/list/`,
                            query: {
                              sortKey: ProjectContractSortKey.ContractTerm,
                              sortOrder: SortOrder.Desc,
                            },
                          })
                        );
                      }}
                    />
                  )
                )
                .otherwise(() => (
                  <CaretDownIcon
                    size={10}
                    containerStyle={{ marginLeft: 3, width: 10, justifyContent: 'flex-end' }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: props.isFavoritePage
                            ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                            : props.isAssignedPage
                            ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                            : `/app/${props.organization.id!}/${props.teamId}/projects/contracts/list/`,
                          query: {
                            sortKey: null,
                            sortOrder: null,
                          },
                        })
                      );
                    }}
                  />
                ))
            ) : (
              <CaretSetIcon
                size={12}
                containerStyle={{
                  opacity: 0.2,
                  marginLeft: 3,
                  width: 10,
                  justifyContent: 'flex-end',
                }}
                onPress={() => {
                  history.push(
                    queryString.stringifyUrl({
                      url: props.isFavoritePage
                        ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                        : props.isAssignedPage
                        ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                        : `/app/${props.organization.id!}/${props.teamId}/projects/contracts/list/`,
                      query: {
                        sortKey: ProjectContractSortKey.ContractTerm,
                        sortOrder: SortOrder.Asc,
                      },
                    })
                  );
                }}
              />
            )}
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontSize: 12,
                color: themeContext.colors.description,
                textAlign: 'center',
                paddingHorizontal: 5,
              }}>
              契約期間
            </Typography>
          </TouchableOpacity>
        )}
         {windowWidth > 2000 && (
          <TouchableOpacity
            style={{ flexDirection: 'row', width: 110, paddingLeft: 10 }}
            onPress={() => {
              history.push(
                queryString.stringifyUrl({
                  url: props.isFavoritePage
                    ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                    : props.isAssignedPage
                    ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                    : `/app/${props.organization.id!}/${props.teamId}/projects/contracts/list/`,
                  query: {
                    sortKey: when(props.sortOrder) //
                      .on(
                        (v) => v === null || v === undefined,
                        () => ProjectContractSortKey.ContractType
                      )
                      .on(
                        (v) => v === SortOrder.Asc,
                        () => ProjectContractSortKey.ContractType
                      )
                      .otherwise(() => null),
                    sortOrder: when(props.sortOrder) //
                      .on(
                        (v) => v === null || v === undefined,
                        () => SortOrder.Asc
                      )
                      .on(
                        (v) => v === SortOrder.Asc,
                        () => SortOrder.Desc
                      )
                      .otherwise(() => null),
                  },
                })
              );
            }}>
            {props.sortKey ===ProjectContractSortKey.ContractType ? (
              when(props.sortOrder) //
                .on(
                  (v) => v === null || v === undefined,
                  () => (
                    <CaretSetIcon
                      size={12}
                      containerStyle={{
                        opacity: 0.2,
                        marginLeft: 3,
                        width: 10,
                        justifyContent: 'flex-end',
                      }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: props.isFavoritePage
                              ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                              : props.isAssignedPage
                              ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                              : `/app/${props.organization.id!}/${
                                  props.teamId
                                }/projects/contracts/list/`,
                            query: {
                              sortKey:ProjectContractSortKey.ContractType,
                              sortOrder: SortOrder.Asc,
                            },
                          })
                        );
                      }}
                    />
                  )
                )
                .on(
                  (v) => v === SortOrder.Asc,
                  () => (
                    <CaretUpIcon
                      size={10}
                      containerStyle={{ marginLeft: 3, width: 10, justifyContent: 'flex-end' }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: props.isFavoritePage
                              ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                              : props.isAssignedPage
                              ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                              : `/app/${props.organization.id!}/${
                                  props.teamId
                                }/projects/contracts/list/`,
                            query: {
                              sortKey: ProjectContractSortKey.ContractType,
                              sortOrder: SortOrder.Desc,
                            },
                          })
                        );
                      }}
                    />
                  )
                )
                .otherwise(() => (
                  <CaretDownIcon
                    size={10}
                    containerStyle={{ marginLeft: 3, width: 10, justifyContent: 'flex-end' }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: props.isFavoritePage
                            ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                            : props.isAssignedPage
                            ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                            : `/app/${props.organization.id!}/${props.teamId}/projects/contracts/list/`,
                          query: {
                            sortKey: null,
                            sortOrder: null,
                          },
                        })
                      );
                    }}
                  />
                ))
            ) : (
              <CaretSetIcon
                size={12}
                containerStyle={{
                  opacity: 0.2,
                  marginLeft: 3,
                  width: 10,
                  justifyContent: 'flex-end',
                }}
                onPress={() => {
                  history.push(
                    queryString.stringifyUrl({
                      url: props.isFavoritePage
                        ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                        : props.isAssignedPage
                        ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                        : `/app/${props.organization.id!}/${props.teamId}/projects/contracts/list/`,
                      query: {
                        sortKey: ProjectContractSortKey.ContractType,
                        sortOrder: SortOrder.Asc,
                      },
                    })
                  );
                }}
              />
            )}
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontSize: 12,
                color: themeContext.colors.description,
                textAlign: 'center',
                paddingHorizontal: 5,
              }}>
              契約形態
            </Typography>
          </TouchableOpacity>
        )}
       
        {windowWidth > 2000 && (
          <TouchableOpacity
            style={{ flexDirection: 'row', width: 80, paddingLeft: 10 }}
            onPress={() => {
              history.push(
                queryString.stringifyUrl({
                  url: props.isFavoritePage
                    ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                    : props.isAssignedPage
                    ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                    : `/app/${props.organization.id!}/${props.teamId}/projects/contracts/list/`,
                  query: {
                    sortKey: when(props.sortOrder) //
                      .on(
                        (v) => v === null || v === undefined,
                        () => ProjectContractSortKey.TaxRate,
                      )
                      .on(
                        (v) => v === SortOrder.Asc,
                        () => ProjectContractSortKey.TaxRate,
                      )
                      .otherwise(() => null),
                    sortOrder: when(props.sortOrder) //
                      .on(
                        (v) => v === null || v === undefined,
                        () => SortOrder.Asc
                      )
                      .on(
                        (v) => v === SortOrder.Asc,
                        () => SortOrder.Desc
                      )
                      .otherwise(() => null),
                  },
                })
              );
            }}>
            {props.sortKey === ProjectContractSortKey.TaxRate ? (
              when(props.sortOrder) //
                .on(
                  (v) => v === null || v === undefined,
                  () => (
                    <CaretSetIcon
                      size={12}
                      containerStyle={{
                        opacity: 0.2,
                        marginLeft: 3,
                        width: 10,
                        justifyContent: 'flex-end',
                      }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: props.isFavoritePage
                              ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                              : props.isAssignedPage
                              ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                              : `/app/${props.organization.id!}/${
                                  props.teamId
                                }/projects/contracts/list/`,
                            query: {
                              sortKey: ProjectContractSortKey.TaxRate,
                              sortOrder: SortOrder.Asc,
                            },
                          })
                        );
                      }}
                    />
                  )
                )
                .on(
                  (v) => v === SortOrder.Asc,
                  () => (
                    <CaretUpIcon
                      size={10}
                      containerStyle={{ marginLeft: 3, width: 10, justifyContent: 'flex-end' }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: props.isFavoritePage
                              ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                              : props.isAssignedPage
                              ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                              : `/app/${props.organization.id!}/${
                                  props.teamId
                                }/projects/contracts/list/`,
                            query: {
                              sortKey: ProjectContractSortKey.TaxRate,
                              sortOrder: SortOrder.Desc,
                            },
                          })
                        );
                      }}
                    />
                  )
                )
                .otherwise(() => (
                  <CaretDownIcon
                    size={10}
                    containerStyle={{ marginLeft: 3, width: 10, justifyContent: 'flex-end' }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: props.isFavoritePage
                            ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                            : props.isAssignedPage
                            ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                            : `/app/${props.organization.id!}/${props.teamId}/projects/contracts/list/`,
                          query: {
                            sortKey: null,
                            sortOrder: null,
                          },
                        })
                      );
                    }}
                  />
                ))
            ) : (
              <CaretSetIcon
                size={12}
                containerStyle={{
                  opacity: 0.2,
                  marginLeft: 3,
                  width: 10,
                  justifyContent: 'flex-end',
                }}
                onPress={() => {
                  history.push(
                    queryString.stringifyUrl({
                      url: props.isFavoritePage
                        ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                        : props.isAssignedPage
                        ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                        : `/app/${props.organization.id!}/${props.teamId}/projects/contracts/list/`,
                      query: {
                        sortKey: ProjectContractSortKey.TaxRate,
                        sortOrder: SortOrder.Asc,
                      },
                    })
                  );
                }}
              />
            )}
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontSize: 12,
                color: themeContext.colors.description,
                textAlign: 'center',
                paddingHorizontal: 5,
              }}>
              税率
            </Typography>
          </TouchableOpacity>
        )}
         {windowWidth > 200 && (
          <TouchableOpacity
            style={{ flexDirection: 'row', width: 120, paddingLeft: 10 }}
            onPress={() => {
              history.push(
                queryString.stringifyUrl({
                  url: props.isFavoritePage
                    ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                    : props.isAssignedPage
                    ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                    : `/app/${props.organization.id!}/${props.teamId}/projects/contracts/list/`,
                  query: {
                    sortKey: when(props.sortOrder) //
                      .on(
                        (v) => v === null || v === undefined,
                        () => ProjectContractSortKey.SalesAmount,
                      )
                      .on(
                        (v) => v === SortOrder.Asc,
                        () => ProjectContractSortKey.SalesAmount,
                      )
                      .otherwise(() => null),
                    sortOrder: when(props.sortOrder) //
                      .on(
                        (v) => v === null || v === undefined,
                        () => SortOrder.Asc
                      )
                      .on(
                        (v) => v === SortOrder.Asc,
                        () => SortOrder.Desc
                      )
                      .otherwise(() => null),
                  },
                })
              );
            }}>
            {props.sortKey === ProjectContractSortKey.SalesAmount ? (
              when(props.sortOrder) //
                .on(
                  (v) => v === null || v === undefined,
                  () => (
                    <CaretSetIcon
                      size={12}
                      containerStyle={{
                        opacity: 0.2,
                        marginLeft: 3,
                        width: 10,
                        justifyContent: 'flex-end',
                      }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: props.isFavoritePage
                              ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                              : props.isAssignedPage
                              ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                              : `/app/${props.organization.id!}/${
                                  props.teamId
                                }/projects/contracts/list/`,
                            query: {
                              sortKey: ProjectContractSortKey.SalesAmount,
                              sortOrder: SortOrder.Asc,
                            },
                          })
                        );
                      }}
                    />
                  )
                )
                .on(
                  (v) => v === SortOrder.Asc,
                  () => (
                    <CaretUpIcon
                      size={10}
                      containerStyle={{ marginLeft: 3, width: 10, justifyContent: 'flex-end' }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: props.isFavoritePage
                              ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                              : props.isAssignedPage
                              ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                              : `/app/${props.organization.id!}/${
                                  props.teamId
                                }/projects/contracts/list/`,
                            query: {
                              sortKey: ProjectContractSortKey.SalesAmount,
                              sortOrder: SortOrder.Desc,
                            },
                          })
                        );
                      }}
                    />
                  )
                )
                .otherwise(() => (
                  <CaretDownIcon
                    size={10}
                    containerStyle={{ marginLeft: 3, width: 10, justifyContent: 'flex-end' }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: props.isFavoritePage
                            ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                            : props.isAssignedPage
                            ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                            : `/app/${props.organization.id!}/${props.teamId}/projects/contracts/list/`,
                          query: {
                            sortKey: null,
                            sortOrder: null,
                          },
                        })
                      );
                    }}
                  />
                ))
            ) : (
              <CaretSetIcon
                size={12}
                containerStyle={{
                  opacity: 0.2,
                  marginLeft: 3,
                  width: 10,
                  justifyContent: 'flex-end',
                }}
                onPress={() => {
                  history.push(
                    queryString.stringifyUrl({
                      url: props.isFavoritePage
                        ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                        : props.isAssignedPage
                        ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                        : `/app/${props.organization.id!}/${props.teamId}/projects/contracts/list/`,
                      query: {
                        sortKey: ProjectContractSortKey.SalesAmount,
                        sortOrder: SortOrder.Asc,
                      },
                    })
                  );
                }}
              />
            )}
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontSize: 12,
                color: themeContext.colors.description,
                textAlign: 'center',
                paddingHorizontal: 5,
              }}>
              売上
            </Typography>
          </TouchableOpacity>
        )}
        {OrganizationUtil.isTeamPlan(props.organization) && windowWidth > 200 && (
          <TouchableOpacity
            style={{ flexDirection: 'row', width: 120, paddingLeft: 10 }}
            onPress={() => {
              history.push(
                queryString.stringifyUrl({
                  url: props.isFavoritePage
                    ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                    : props.isAssignedPage
                    ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                    : `/app/${props.organization.id!}/${props.teamId}/projects/contracts/list/`,
                  query: {
                    sortKey: when(props.sortOrder) //
                      .on(
                        (v) => v === null || v === undefined,
                        () => ProjectContractSortKey.CostBudget,
                      )
                      .on(
                        (v) => v === SortOrder.Asc,
                        () => ProjectContractSortKey.CostBudget,
                      )
                      .otherwise(() => null),
                    sortOrder: when(props.sortOrder) //
                      .on(
                        (v) => v === null || v === undefined,
                        () => SortOrder.Asc
                      )
                      .on(
                        (v) => v === SortOrder.Asc,
                        () => SortOrder.Desc
                      )
                      .otherwise(() => null),
                  },
                })
              );
            }}>
            {props.sortKey === ProjectContractSortKey.CostBudget ? (
              when(props.sortOrder) //
                .on(
                  (v) => v === null || v === undefined,
                  () => (
                    <CaretSetIcon
                      size={12}
                      containerStyle={{
                        opacity: 0.2,
                        marginLeft: 3,
                        width: 10,
                        justifyContent: 'flex-end',
                      }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: props.isFavoritePage
                              ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                              : props.isAssignedPage
                              ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                              : `/app/${props.organization.id!}/${
                                  props.teamId
                                }/projects/contracts/list/`,
                            query: {
                              sortKey:ProjectContractSortKey.CostBudget,
                              sortOrder: SortOrder.Asc,
                            },
                          })
                        );
                      }}
                    />
                  )
                )
                .on(
                  (v) => v === SortOrder.Asc,
                  () => (
                    <CaretUpIcon
                      size={10}
                      containerStyle={{ marginLeft: 3, width: 10, justifyContent: 'flex-end' }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: props.isFavoritePage
                              ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                              : props.isAssignedPage
                              ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                              : `/app/${props.organization.id!}/${
                                  props.teamId
                                }/projects/contracts/list/`,
                            query: {
                              sortKey: ProjectContractSortKey.CostBudget,
                              sortOrder: SortOrder.Desc,
                            },
                          })
                        );
                      }}
                    />
                  )
                )
                .otherwise(() => (
                  <CaretDownIcon
                    size={10}
                    containerStyle={{ marginLeft: 3, width: 10, justifyContent: 'flex-end' }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: props.isFavoritePage
                            ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                            : props.isAssignedPage
                            ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                            : `/app/${props.organization.id!}/${props.teamId}/projects/contracts/list/`,
                          query: {
                            sortKey: null,
                            sortOrder: null,
                          },
                        })
                      );
                    }}
                  />
                ))
            ) : (
              <CaretSetIcon
                size={12}
                containerStyle={{
                  opacity: 0.2,
                  marginLeft: 3,
                  width: 10,
                  justifyContent: 'flex-end',
                }}
                onPress={() => {
                  history.push(
                    queryString.stringifyUrl({
                      url: props.isFavoritePage
                        ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                        : props.isAssignedPage
                        ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                        : `/app/${props.organization.id!}/${props.teamId}/projects/contracts/list/`,
                      query: {
                        sortKey: ProjectContractSortKey.CostBudget,
                        sortOrder: SortOrder.Asc,
                      },
                    })
                  );
                }}
              />
            )}
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontSize: 12,
                color: themeContext.colors.description,
                textAlign: 'center',
                paddingHorizontal: 5,
              }}>
              予算
            </Typography>
          </TouchableOpacity>
        )}
        {OrganizationUtil.isTeamPlan(props.organization) && windowWidth > 200 && (
          <TouchableOpacity
            style={{ flexDirection: 'row', width: 120, paddingLeft: 10 }}
            onPress={() => {
              history.push(
                queryString.stringifyUrl({
                  url: props.isFavoritePage
                    ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                    : props.isAssignedPage
                    ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                    : `/app/${props.organization.id!}/${props.teamId}/projects/contracts/list/`,
                  query: {
                    sortKey: when(props.sortOrder) //
                      .on(
                        (v) => v === null || v === undefined,
                        () => ProjectContractSortKey.LaborCost,
                      )
                      .on(
                        (v) => v === SortOrder.Asc,
                        () => ProjectContractSortKey.LaborCost,
                      )
                      .otherwise(() => null),
                    sortOrder: when(props.sortOrder) //
                      .on(
                        (v) => v === null || v === undefined,
                        () => SortOrder.Asc
                      )
                      .on(
                        (v) => v === SortOrder.Asc,
                        () => SortOrder.Desc
                      )
                      .otherwise(() => null),
                  },
                })
              );
            }}>
            {props.sortKey === ProjectContractSortKey.LaborCost ? (
              when(props.sortOrder) //
                .on(
                  (v) => v === null || v === undefined,
                  () => (
                    <CaretSetIcon
                      size={12}
                      containerStyle={{
                        opacity: 0.2,
                        marginLeft: 3,
                        width: 10,
                        justifyContent: 'flex-end',
                      }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: props.isFavoritePage
                              ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                              : props.isAssignedPage
                              ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                              : `/app/${props.organization.id!}/${
                                  props.teamId
                                }/projects/contracts/list/`,
                            query: {
                              sortKey: ProjectContractSortKey.LaborCost,
                              sortOrder: SortOrder.Asc,
                            },
                          })
                        );
                      }}
                    />
                  )
                )
                .on(
                  (v) => v === SortOrder.Asc,
                  () => (
                    <CaretUpIcon
                      size={10}
                      containerStyle={{ marginLeft: 3, width: 10, justifyContent: 'flex-end' }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: props.isFavoritePage
                              ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                              : props.isAssignedPage
                              ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                              : `/app/${props.organization.id!}/${
                                  props.teamId
                                }/projects/contracts/list/`,
                            query: {
                              sortKey: ProjectContractSortKey.LaborCost,
                              sortOrder: SortOrder.Desc,
                            },
                          })
                        );
                      }}
                    />
                  )
                )
                .otherwise(() => (
                  <CaretDownIcon
                    size={10}
                    containerStyle={{ marginLeft: 3, width: 10, justifyContent: 'flex-end' }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: props.isFavoritePage
                            ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                            : props.isAssignedPage
                            ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                            : `/app/${props.organization.id!}/${props.teamId}/projects/contracts/list/`,
                          query: {
                            sortKey: null,
                            sortOrder: null,
                          },
                        })
                      );
                    }}
                  />
                ))
            ) : (
              <CaretSetIcon
                size={12}
                containerStyle={{
                  opacity: 0.2,
                  marginLeft: 3,
                  width: 10,
                  justifyContent: 'flex-end',
                }}
                onPress={() => {
                  history.push(
                    queryString.stringifyUrl({
                      url: props.isFavoritePage
                        ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                        : props.isAssignedPage
                        ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                        : `/app/${props.organization.id!}/${props.teamId}/projects/contracts/list/`,
                      query: {
                        sortKey: ProjectContractSortKey.LaborCost,
                        sortOrder: SortOrder.Asc,
                      },
                    })
                  );
                }}
              />
            )}
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontSize: 12,
                color: themeContext.colors.description,
                textAlign: 'center',
                paddingHorizontal: 5,
                width: 70,
              }}>
              人件費
            </Typography>
          </TouchableOpacity>
        )}
        {OrganizationUtil.isTeamPlan(props.organization) && windowWidth > 1500 && (
          <TouchableOpacity
            style={{ flexDirection: 'row', width: 110, paddingLeft: 10 }}
            onPress={() => {
              history.push(
                queryString.stringifyUrl({
                  url: props.isFavoritePage
                    ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                    : props.isAssignedPage
                    ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                    : `/app/${props.organization.id!}/${props.teamId}/projects/contracts/list/`,
                  query: {
                    sortKey: when(props.sortOrder) //
                      .on(
                        (v) => v === null || v === undefined,
                        () => ProjectContractSortKey.GrossProfit,
                      )
                      .on(
                        (v) => v === SortOrder.Asc,
                        () =>  ProjectContractSortKey.GrossProfit,
                      )
                      .otherwise(() => null),
                    sortOrder: when(props.sortOrder) //
                      .on(
                        (v) => v === null || v === undefined,
                        () => SortOrder.Asc
                      )
                      .on(
                        (v) => v === SortOrder.Asc,
                        () => SortOrder.Desc
                      )
                      .otherwise(() => null),
                  },
                })
              );
            }}>
            {props.sortKey ===  ProjectContractSortKey.GrossProfit ? (
              when(props.sortOrder) //
                .on(
                  (v) => v === null || v === undefined,
                  () => (
                    <CaretSetIcon
                      size={12}
                      containerStyle={{
                        opacity: 0.2,
                        marginLeft: 3,
                        width: 10,
                        justifyContent: 'flex-end',
                      }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: props.isFavoritePage
                              ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                              : props.isAssignedPage
                              ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                              : `/app/${props.organization.id!}/${
                                  props.teamId
                                }/projects/contracts/list/`,
                            query: {
                              sortKey:  ProjectContractSortKey.GrossProfit,
                              sortOrder: SortOrder.Asc,
                            },
                          })
                        );
                      }}
                    />
                  )
                )
                .on(
                  (v) => v === SortOrder.Asc,
                  () => (
                    <CaretUpIcon
                      size={10}
                      containerStyle={{ marginLeft: 3, width: 10, justifyContent: 'flex-end' }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: props.isFavoritePage
                              ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                              : props.isAssignedPage
                              ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                              : `/app/${props.organization.id!}/${
                                  props.teamId
                                }/projects/contracts/list/`,
                            query: {
                              sortKey:  ProjectContractSortKey.GrossProfit,
                              sortOrder: SortOrder.Desc,
                            },
                          })
                        );
                      }}
                    />
                  )
                )
                .otherwise(() => (
                  <CaretDownIcon
                    size={10}
                    containerStyle={{ marginLeft: 3, width: 10, justifyContent: 'flex-end' }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: props.isFavoritePage
                            ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                            : props.isAssignedPage
                            ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                            : `/app/${props.organization.id!}/${props.teamId}/projects/contracts/list/`,
                          query: {
                            sortKey: null,
                            sortOrder: null,
                          },
                        })
                      );
                    }}
                  />
                ))
            ) : (
              <CaretSetIcon
                size={12}
                containerStyle={{
                  opacity: 0.2,
                  marginLeft: 3,
                  width: 10,
                  justifyContent: 'flex-end',
                }}
                onPress={() => {
                  history.push(
                    queryString.stringifyUrl({
                      url: props.isFavoritePage
                        ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                        : props.isAssignedPage
                        ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                        : `/app/${props.organization.id!}/${props.teamId}/projects/contracts/list/`,
                      query: {
                        sortKey:  ProjectContractSortKey.GrossProfit,
                        sortOrder: SortOrder.Asc,
                      },
                    })
                  );
                }}
              />
            )}
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontSize: 12,
                color: themeContext.colors.description,
                textAlign: 'center',
                paddingHorizontal: 5,
              }}>
              粗利
            </Typography>
          </TouchableOpacity>
        )}
        {OrganizationUtil.isTeamPlan(props.organization) && windowWidth > 2000 && (
          <TouchableOpacity
            style={{ flexDirection: 'row', width: 80, paddingLeft: 10 }}
            onPress={() => {
              history.push(
                queryString.stringifyUrl({
                  url: props.isFavoritePage
                    ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                    : props.isAssignedPage
                    ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                    : `/app/${props.organization.id!}/${props.teamId}/projects/contracts/list/`,
                  query: {
                    sortKey: when(props.sortOrder) //
                      .on(
                        (v) => v === null || v === undefined,
                        () => ProjectContractSortKey.GrossProfitRate,
                      )
                      .on(
                        (v) => v === SortOrder.Asc,
                        () =>  ProjectContractSortKey.GrossProfitRate,
                      )
                      .otherwise(() => null),
                    sortOrder: when(props.sortOrder) //
                      .on(
                        (v) => v === null || v === undefined,
                        () => SortOrder.Asc
                      )
                      .on(
                        (v) => v === SortOrder.Asc,
                        () => SortOrder.Desc
                      )
                      .otherwise(() => null),
                  },
                })
              );
            }}>
            {props.sortKey ===  ProjectContractSortKey.GrossProfitRate ? (
              when(props.sortOrder) //
                .on(
                  (v) => v === null || v === undefined,
                  () => (
                    <CaretSetIcon
                      size={12}
                      containerStyle={{
                        opacity: 0.2,
                        marginLeft: 3,
                        width: 10,
                        justifyContent: 'flex-end',
                      }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: props.isFavoritePage
                              ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                              : props.isAssignedPage
                              ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                              : `/app/${props.organization.id!}/${
                                  props.teamId
                                }/projects/contracts/list/`,
                            query: {
                              sortKey:  ProjectContractSortKey.GrossProfitRate,
                              sortOrder: SortOrder.Asc,
                            },
                          })
                        );
                      }}
                    />
                  )
                )
                .on(
                  (v) => v === SortOrder.Asc,
                  () => (
                    <CaretUpIcon
                      size={10}
                      containerStyle={{ marginLeft: 3, width: 10, justifyContent: 'flex-end' }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: props.isFavoritePage
                              ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                              : props.isAssignedPage
                              ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                              : `/app/${props.organization.id!}/${
                                  props.teamId
                                }/projects/contracts/list/`,
                            query: {
                              sortKey:  ProjectContractSortKey.GrossProfitRate,
                              sortOrder: SortOrder.Desc,
                            },
                          })
                        );
                      }}
                    />
                  )
                )
                .otherwise(() => (
                  <CaretDownIcon
                    size={10}
                    containerStyle={{ marginLeft: 3, width: 10, justifyContent: 'flex-end' }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: props.isFavoritePage
                            ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                            : props.isAssignedPage
                            ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                            : `/app/${props.organization.id!}/${props.teamId}/projects/contracts/list/`,
                          query: {
                            sortKey: null,
                            sortOrder: null,
                          },
                        })
                      );
                    }}
                  />
                ))
            ) : (
              <CaretSetIcon
                size={12}
                containerStyle={{
                  opacity: 0.2,
                  marginLeft: 3,
                  width: 10,
                  justifyContent: 'flex-end',
                }}
                onPress={() => {
                  history.push(
                    queryString.stringifyUrl({
                      url: props.isFavoritePage
                        ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                        : props.isAssignedPage
                        ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                        : `/app/${props.organization.id!}/${props.teamId}/projects/contracts/list/`,
                      query: {
                        sortKey:  ProjectContractSortKey.GrossProfitRate,
                        sortOrder: SortOrder.Asc,
                      },
                    })
                  );
                }}
              />
            )}
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontSize: 12,
                color: themeContext.colors.description,
                textAlign: 'center',
                paddingHorizontal: 5,
              }}>
              粗利率
            </Typography>
          </TouchableOpacity>
        )}
        {OrganizationUtil.isTeamPlan(props.organization) && windowWidth > 2000 && (
          <TouchableOpacity
            style={{ flexDirection: 'row', width: 120, paddingLeft: 10 }}
            onPress={() => {
              history.push(
                queryString.stringifyUrl({
                  url: props.isFavoritePage
                    ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                    : props.isAssignedPage
                    ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                    : `/app/${props.organization.id!}/${props.teamId}/projects/contracts/list/`,
                  query: {
                    sortKey: when(props.sortOrder) //
                      .on(
                        (v) => v === null || v === undefined,
                        () => ProjectContractSortKey.LaborShare,
                      )
                      .on(
                        (v) => v === SortOrder.Asc,
                        () =>  ProjectContractSortKey.LaborShare,
                      )
                      .otherwise(() => null),
                    sortOrder: when(props.sortOrder) //
                      .on(
                        (v) => v === null || v === undefined,
                        () => SortOrder.Asc
                      )
                      .on(
                        (v) => v === SortOrder.Asc,
                        () => SortOrder.Desc
                      )
                      .otherwise(() => null),
                  },
                })
              );
            }}>
            {props.sortKey ===  ProjectContractSortKey.LaborShare ? (
              when(props.sortOrder) //
                .on(
                  (v) => v === null || v === undefined,
                  () => (
                    <CaretSetIcon
                      size={12}
                      containerStyle={{
                        opacity: 0.2,
                        marginLeft: 3,
                        width: 10,
                        justifyContent: 'flex-end',
                      }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: props.isFavoritePage
                              ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                              : props.isAssignedPage
                              ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                              : `/app/${props.organization.id!}/${
                                  props.teamId
                                }/projects/contracts/list/`,
                            query: {
                              sortKey:  ProjectContractSortKey.LaborShare,
                              sortOrder: SortOrder.Asc,
                            },
                          })
                        );
                      }}
                    />
                  )
                )
                .on(
                  (v) => v === SortOrder.Asc,
                  () => (
                    <CaretUpIcon
                      size={10}
                      containerStyle={{ marginLeft: 3, width: 10, justifyContent: 'flex-end' }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: props.isFavoritePage
                              ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                              : props.isAssignedPage
                              ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                              : `/app/${props.organization.id!}/${
                                  props.teamId
                                }/projects/contracts/list/`,
                            query: {
                              sortKey:  ProjectContractSortKey.LaborShare,
                              sortOrder: SortOrder.Desc,
                            },
                          })
                        );
                      }}
                    />
                  )
                )
                .otherwise(() => (
                  <CaretDownIcon
                    size={10}
                    containerStyle={{ marginLeft: 3, width: 10, justifyContent: 'flex-end' }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: props.isFavoritePage
                            ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                            : props.isAssignedPage
                            ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                            : `/app/${props.organization.id!}/${props.teamId}/projects/contracts/list/`,
                          query: {
                            sortKey: null,
                            sortOrder: null,
                          },
                        })
                      );
                    }}
                  />
                ))
            ) : (
              <CaretSetIcon
                size={12}
                containerStyle={{
                  opacity: 0.2,
                  marginLeft: 3,
                  width: 10,
                  justifyContent: 'flex-end',
                }}
                onPress={() => {
                  history.push(
                    queryString.stringifyUrl({
                      url: props.isFavoritePage
                        ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                        : props.isAssignedPage
                        ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                        : `/app/${props.organization.id!}/${props.teamId}/projects/contracts/list/`,
                      query: {
                        sortKey:  ProjectContractSortKey.LaborShare,
                        sortOrder: SortOrder.Asc,
                      },
                    })
                  );
                }}
              />
            )}
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontSize: 12,
                color: themeContext.colors.description,
                textAlign: 'center',
                paddingHorizontal: 5,
              }}>
              労働分配率
            </Typography>
          </TouchableOpacity>
        )}
        <TouchableOpacity
          style={{ flexDirection: 'row', width: 95, marginRight: 10 }}
          onPress={() => {
            history.push(
              queryString.stringifyUrl({
                url: props.isFavoritePage
                  ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                  : props.isAssignedPage
                  ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                  : `/app/${props.organization.id!}/${props.teamId}/projects/contracts/list/`,
                query: {
                  sortKey: when(props.sortOrder) //
                    .on(
                      (v) => v === null || v === undefined,
                      () => ProjectContractSortKey.WorkingTimeSec,
                    )
                    .on(
                      (v) => v === SortOrder.Asc,
                      () => ProjectContractSortKey.WorkingTimeSec,
                    )
                    .otherwise(() => null),
                  sortOrder: when(props.sortOrder) //
                    .on(
                      (v) => v === null || v === undefined,
                      () => SortOrder.Asc
                    )
                    .on(
                      (v) => v === SortOrder.Asc,
                      () => SortOrder.Desc
                    )
                    .otherwise(() => null),
                },
              })
            );
          }}>
          {props.sortKey === ProjectContractSortKey.WorkingTimeSec ? (
            when(props.sortOrder) //
              .on(
                (v) => v === null || v === undefined,
                () => (
                  <CaretSetIcon
                    size={12}
                    containerStyle={{
                      opacity: 0.2,
                      marginLeft: 3,
                      width: 20,
                      justifyContent: 'flex-end',
                    }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: props.isFavoritePage
                            ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                            : props.isAssignedPage
                            ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                            : `/app/${props.organization.id!}/${props.teamId}/projects/contracts/list/`,
                          query: {
                            sortKey: ProjectContractSortKey.WorkingTimeSec,
                            sortOrder: SortOrder.Asc,
                          },
                        })
                      );
                    }}
                  />
                )
              )
              .on(
                (v) => v === SortOrder.Asc,
                () => (
                  <CaretUpIcon
                    size={10}
                    containerStyle={{ marginLeft: 3, width: 20, justifyContent: 'flex-end' }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: props.isFavoritePage
                            ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                            : props.isAssignedPage
                            ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                            : `/app/${props.organization.id!}/${props.teamId}/projects/contracts/list/`,
                          query: {
                            sortKey: ProjectContractSortKey.WorkingTimeSec,
                            sortOrder: SortOrder.Desc,
                          },
                        })
                      );
                    }}
                  />
                )
              )
              .otherwise(() => (
                <CaretDownIcon
                  size={10}
                  containerStyle={{ marginLeft: 3, width: 20, justifyContent: 'flex-end' }}
                  onPress={() => {
                    history.push(
                      queryString.stringifyUrl({
                        url: props.isFavoritePage
                          ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                          : props.isAssignedPage
                          ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                          : `/app/${props.organization.id!}/${props.teamId}/projects/contracts/list/`,
                        query: {
                          sortKey: null,
                          sortOrder: null,
                        },
                      })
                    );
                  }}
                />
              ))
          ) : (
            <CaretSetIcon
              size={12}
              containerStyle={{
                opacity: 0.2,
                marginLeft: 3,
                width: 20,
                justifyContent: 'flex-end',
              }}
              onPress={() => {
                history.push(
                  queryString.stringifyUrl({
                    url: props.isFavoritePage
                      ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                      : props.isAssignedPage
                      ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                      : `/app/${props.organization.id!}/${props.teamId}/projects/contracts/list/`,
                    query: {
                      sortKey: ProjectContractSortKey.WorkingTimeSec,
                      sortOrder: SortOrder.Asc,
                    },
                  })
                );
              }}
            />
          )}
          <Typography
            variant={TypographyType.Normal}
            style={{
              fontSize: 12,
              color: themeContext.colors.description,
              textAlign: 'center',
              paddingHorizontal: 5,
              width: 80,
            }}>
            作業時間
          </Typography>
        </TouchableOpacity>
      </View>
    </View>
  );
};

interface IAlertCanNotDragModalProps {
  showModal: boolean;
  onCloseModal: () => void;
}

const AlertCanNotDragModal = (props: IAlertCanNotDragModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  return (
    <Modal
      title={'並び替えができません'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <View style={{ flexDirection: 'column' }}>
        <View style={{ marginTop: 10 }}>
          <Typography
            variant={TypographyType.Description}
            style={{ textAlign: 'center', fontSize: 16, lineHeight: 22 }}>
            {`ドラッグ操作で並び順を変更したい場合、${'\n'}表示順の指定を解除してください`}
          </Typography>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
          <Button
            text={'閉じる'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
            }}
            onPress={() => props.onCloseModal()}
          />
        </View>
      </View>
    </Modal>
  );
};

interface IProjectListFullInnerProps {
  organization: Organization;
  teamId: string;
  me: Member;
  showTeamName: boolean;
  searchCondition: any;
  setShowDialog: (value: boolean) => void;
}

const ProjectListFullInner = React.memo((props: IProjectListFullInnerProps) => {
  const pageSize = 50;
  const { search } = useLocation();
  const [projectFilter, setProjectFilter] = useContext(ProjectFilterContext);
  const [searchProjectName, setSearchProjectName] = useState('');
  const [searchProjectNameLive, setSearchProjectNameLive] = useState('');
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const { push } = useHistory();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showCanNotDragModal, setShowCanNotDragModal] = useState(false);
  const [sortKey, setSortKey] = useState<ProjectContractSortKey>(ProjectContractSortKey.ContractTerm);
  const [sortOrder, setSortOrder] = useState<SortOrder | null>(SortOrder.Desc);

  const { loading: mySettingsLoading, data: mySettingsData } = useMySettingsQuery();

  const teamResult = useTeamQuery({
    variables: {
      id: props.teamId,
    },
    skip: props.teamId === null,
  });
  const projectsResult = useTeamProjectContractsQuery({
    variables: {
      teamId: props.teamId,
      offset: 0,
      limit: pageSize,
      input: {
        projectStatusIds: (projectFilter as ProjectFilter).projectStatusIds || [],
        completeCondition: ProjectCompleteFilter.Both,
        favoriteCondition: (projectFilter as ProjectFilter).favoriteOnly
          ? ProjectFavoriteFilter.Favorite
          : null,
        projectName: (projectFilter as ProjectFilter).projectName ?? null,
        clientIds: (projectFilter as ProjectFilter).clientIds || [],
        assineeMemberIds: (projectFilter as ProjectFilter).assignerIds || [],
        sortKey: sortKey,
        sortOrder: sortOrder,
      },
    },
    skip:
      props.teamId === null ||
      (projectFilter as ProjectFilter)?.projectStatusIds?.length === 0 ||
      (projectFilter as ProjectFilter)?.projectStatusIds?.length === undefined,
    fetchPolicy: 'network-only',
  });

  const contracts = useMemo(() => {
    if (projectsResult.loading || !projectsResult.data?.teamProjectContracts) {
      return [];
    }
    return projectsResult.data!.teamProjectContracts!;
  }, [projectsResult.loading, projectsResult.data?.teamProjectContracts, projectFilter]);

  useEffect(() => {
    setSortKey(props.searchCondition?.sortKey || ProjectContractSortKey.ContractTerm);
  }, [props.searchCondition.sortKey]);

  useEffect(() => {
    setSortOrder(props.searchCondition?.sortOrder ?? null);
  }, [props.searchCondition.sortOrder]);

  if (
    mySettingsLoading ||
    teamResult.loading ||
    teamResult.error ||
    projectsResult.loading ||
    projectsResult.error
  ) {
    return <></>;
  }

  return (
    <TaskListWrapper
      isFavoritePage={location.hash.indexOf('/my/favorite-project/') !== -1}
      isAssignedPage={location.hash.indexOf('/my/assigned-project/') !== -1}>
      <TopMenu>
        <Form style={{ width: 'auto', flexDirection: 'row' }}>
          <Input
            name={'searchProject'}
            label={''}
            value={searchProjectName}
            placeholder={'プロジェクト名で絞り込む(Enterキーで検索)'}
            icon={<SearchIcon size={20} containerStyle={{ marginLeft: 10 }} />}
            containerStyle={{ marginVertical: 10, width: 'auto' }}
            inputstyle={{ borderWidth: 0, fontSize: 14, minWidth: 300 }}
            inputContainerStyle={{
              marginHorizontal: 10,
              backgroundColor: themeContext.colors.baseColor,
              shadowOffset: {
                width: -1,
                height: -1,
              },
              shadowOpacity: 0.1,
              elevation: 2,
              borderWidth: 1,
              borderRadius: 20,
              borderColor: themeContext.colors.separator,
            }}
            onChange={(value: string) => {
              setSearchProjectNameLive(value);
              if (value?.trim() === '') {
                setSearchProjectName('');
                //@ts-ignore
                setProjectFilter({
                  ...(projectFilter as ProjectFilter),
                  projectName: null,
                });
              }
            }}
            onPressEnterIncludeIMEConvert={(value: string) => {
              setSearchProjectName(value);
              //@ts-ignore
              setProjectFilter({
                ...(projectFilter as ProjectFilter),
                projectName: value,
              });
            }}
            onBlur={(value: string) => {
              setSearchProjectName(value);
              //@ts-ignore
              setProjectFilter({
                ...(projectFilter as ProjectFilter),
                projectName: value,
              });
            }}
          />
        </Form>
      </TopMenu>
      <Header
        organization={props.organization}
        teamId={props.teamId}
        sortKey={sortKey}
        sortOrder={sortOrder}
        isFavoritePage={location.hash.indexOf('/my/favorite-project/') !== -1}
        isAssignedPage={location.hash.indexOf('/my/assigned-project/') !== -1}
        showTeamName={props.showTeamName}
      />
      {contracts.length === 0 ? (
        <View
          style={{
            height: 'calc(100% - 150px)',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Image
            source={{ uri: noData02 }}
            resizeMode="contain"
            style={{
              width: 300,
              height: 200,
              opacity: 0.7,
            }}
          />
          <Typography
            variant={TypographyType.Normal}
            style={{ color: themeContext.colors.description }}>
            さあ、プロジェクトを作成してみましょう
          </Typography>
        </View>
      ) : (
        <GlobalDragContextProvider>
          <VirtualizedFlatList
            style={{ height: 'calc(100vh - 310px)' }}
            items={contracts}
            renderItem={(item, index) => {
              return (
                <ProjectSummary
                  showTeamName={false}
                  organization={props.organization}
                  projectContract={item as ProjectContract}
                  me={props.me}
                  showAssigner
                  showClientName
                  showFavorite
                />
              );
            }}
            getKey={(projectContract) => (projectContract as ProjectContract).id!.toString()}
            itemHeight={50}
            onPress={(projectContract) => {
              push(
                `/app/${loginUser!.organizationId}/${props.teamId}/projects/contracts/list/?` +
                  queryString.stringify(
                    Object.assign(queryString.parse(search), {
                      projectDetailId: (projectContract as ProjectContract).project!.id,
                      projectDetailMode: 'contract',
                      contractYear: (projectContract as ProjectContract).year,
                      contractMonth: (projectContract as ProjectContract).month,
                    })
                  )
              );
            }}
            onEndReached={() => {
              if ((projectsResult?.data?.teamProjectContracts?.length ?? 0) < pageSize) {
                return;
              }
              projectsResult.fetchMore({
                variables: {
                  offset: projectsResult.data!.teamProjectContracts!.length,
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev;
                  return Object.assign({}, prev, {
                    teamProjectContracts: [
                      ...(prev.teamProjectContracts || []),
                      ...(fetchMoreResult.teamProjectContracts || []),
                    ],
                  });
                },
              });
            }}
          />
        </GlobalDragContextProvider>
      )}
    </TaskListWrapper>
  );
});

const ProjectContractListFull = () => {
  const { push } = useHistory();
  const [showDialog, setShowDialog] = useState(false);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const teamId = LoginUtil.getTeamIdFromURl();
  const { search } = useLocation();
  const searchCondition = queryString.parse(search);
  const { loading, data } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId,
    },
  });
  const { loading: mySettingsLoading, data: mySettingsData } = useMySettingsQuery();
  const { loading: meLoading, data: meData } = useMeQuery({
    fetchPolicy: 'network-only',
  });

  if (loading || mySettingsLoading || meLoading || !meData?.me) {
    return <></>;
  }

  return (
    <Container>
      <ProjectListFullInner
        organization={data!.organization!}
        teamId={teamId!}
        me={meData.me}
        setShowDialog={setShowDialog}
        showTeamName={false}
        searchCondition={searchCondition}
      />
    </Container>
  );
};

export default React.memo(ProjectContractListFull);
