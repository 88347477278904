import React, { useContext, useEffect, useState } from 'react';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../../theme';
import {
  CalendarEvent,
  Plan,
  Project,
  ProjectCalendarEventsDocument,
  ProjectCompleteFilter,
  ProjectSortKey,
  SortOrder,
  Team,
  useJoinedTeamsQuery,
  useMoveCalendarEventMutation,
  useOrganizationQuery,
  useTeamProjectsForSelectorQuery,
  useTeamProjectsQuery,
} from '../../../../../graphql/api/API';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import Modal from '../../../../presentational/molecules/modal';
import { View } from 'react-native';
import Button from '../../../../presentational/atoms/button';
import Form from '../../../../presentational/atoms/form';
import Input from '../../../../presentational/atoms/input';

interface IMoveClendarEventModalProps {
  calendarEvent: CalendarEvent;
  showModal: boolean;
  onCloseModal: () => void;
}

const MoveCalendarEventModal = (props: IMoveClendarEventModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser] = useContext(LoginUserContext);
  const [team, setTeam] = useState<Team | null>(null);
  const [teamId, setTeamId] = useState<string | null>(null);
  const [project, setProject] = useState<Project | null>(null);
  const [projectId, setProjectId] = useState<string | null>(null);
  const { data: organizationData, loading: organizationLoading } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId,
    },
  });

  const { data: teamsData, loading: teamsLoading } = useJoinedTeamsQuery({
    variables: {
      organizationId: loginUser!.organizationId,
    },
  });
  const { data: projectsData, loading: projectsLoading } = useTeamProjectsForSelectorQuery({
    variables: {
      teamId:
        organizationData?.organization?.plan.code === Plan.Basic ||
        organizationData?.organization?.plan.code === Plan.Professional
          ? teamsData?.joinedTeams?.[0]?.id || ''
          : teamId || '',
      input: {
        sortKey: ProjectSortKey.ProjectName,
        sortOrder: SortOrder.Asc,
        completeCondition: ProjectCompleteFilter.Incomplete,
      },
    },
    skip: !teamId,
    fetchPolicy: 'network-only',
  });
  const [moveCalendarEvent] = useMoveCalendarEventMutation({
    variables: {
      id: props.calendarEvent.id!,
      input: {
        projectId: projectId || '',
        versionNo: props.calendarEvent.versionNo,
      },
    },
    refetchQueries: [
      {
        query: ProjectCalendarEventsDocument,
        variables: {
          projectId: props.calendarEvent.projectId,
        },
      },
    ],
  });

  useEffect(() => {
    if (teamsData?.joinedTeams) {
      setTeam(teamsData!.joinedTeams[0]);
      setTeamId(teamsData!.joinedTeams[0]?.id!);
    }
  }, [teamsData?.joinedTeams]);

  if (organizationLoading) {
    return <></>;
  }

  return (
    <Modal
      title={'他のプロジェクトに移動する'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <Form>
        <>
          {(organizationData!.organization!.plan.code === Plan.Business ||
            organizationData!.organization!.plan.code === Plan.Enterprise) && (
            <Input
              name={'team'}
              label={'チーム'}
              type={'picker'}
              isSearchable={true}
              pickerItems={teamsData?.joinedTeams?.map((team) => {
                return {
                  label: team!.name,
                  value: team!.id!,
                };
              })}
              initialValue={
                (teamsData?.joinedTeams as Team[])
                  ? ((teamsData?.joinedTeams as Team[])?.[0]?.id as string)
                  : ''
              }
              onChange={(value) => {
                const targets = teamsData?.joinedTeams?.filter((team) => team!.id! === value);
                if (targets && targets.length > 0) {
                  setTeam(targets[0]);
                  setTeamId(targets[0]!.id!);
                }
              }}
              validate={{
                required: {
                  value: true,
                  message: 'チームを選択してください',
                },
              }}
            />
          )}
          <Input
            name={'project'}
            label={'プロジェクト'}
            type={'picker'}
            isSearchable={true}
            pickerItems={projectsData?.teamProjectsForSelector
              ?.filter((project) => project!.complete === false)
              .map((project) => {
                return {
                  label: project!.name,
                  value: project!.id!,
                };
              })}
            onChange={(value) => {
              const targets = projectsData?.teamProjectsForSelector?.filter(
                (project) => project!.id! === value
              );
              if (targets && targets.length > 0) {
                setProjectId(targets[0]?.id!);
              }
            }}
            validate={{
              required: {
                value: true,
                message: 'プロジェクトを選択してください',
              },
            }}
          />
        </>
        <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
          <Button
            text={'移動する'}
            style={{ minWidth: 100, marginRight: 10, marginVertical: 10 }}
            onPress={async () => {
              await moveCalendarEvent();
              props.onCloseModal();
            }}
          />
          <Button
            text={'キャンセル'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.primary }}
            disableValidate={true}
            onPress={() => {
              props.onCloseModal();
            }}
          />
        </View>
      </Form>
    </Modal>
  );
};

export default React.memo(MoveCalendarEventModal);
