import React, { createContext, useState } from 'react';

export interface LoginUser {
  id: string;
  mailAddress: string;
  name: string;
  organizationId: string;
  myTeamId: string | null;
  jwtToken: string;
}

const LoginUserContext = createContext<[LoginUser | null, (user: LoginUser | null) => void]>([
  {} as LoginUser,
  () => {},
]);

const LoginUserContextProvider = (props: any) => {
  const [loginUser, setLoginUser] = useState<LoginUser | null>(null);

  return (
    <LoginUserContext.Provider value={[loginUser, setLoginUser]}>
      {props.children}
    </LoginUserContext.Provider>
  );
};

export { LoginUserContext, LoginUserContextProvider };
