import React, { useContext, useEffect } from 'react';
//@ts-ignore
import styled from 'styled-components/native';
import { useTwoFactorAuthSettingQuery } from '../../../../../graphql/api/API';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import Link from '../../../../presentational/atoms/link';
import Spinner from '../../../../presentational/atoms/spinner';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import TwoFactorAuthSetting from '../../organisms/two-factor-auth-setting';

const Container = styled.View`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  paddingtop: 5%;
  height: 100%;
`;

const RequiredTwoFactorAuthSettingPage = () => {
  const [loginUser] = useContext(LoginUserContext);
  const twoFactorAuthSetting = useTwoFactorAuthSettingQuery();

  useEffect(() => {
    if (!loginUser) {
      location.href = '/#/app/signin';
    }
  }, [loginUser]);

  useEffect(() => {
    if (twoFactorAuthSetting.data?.twoFactorAuthSetting?.enabled) {
      location.href = `/#/app/${loginUser?.organizationId}/my-settings/two-factor-auth/`;
    }
  }, [twoFactorAuthSetting]);

  if (twoFactorAuthSetting.loading) {
    return <Spinner loading={twoFactorAuthSetting.loading} />;
  }

  return (
    <Container>
      <Typography variant={TypographyType.Title} style={{ paddingBottom: 20 }}>
        2要素認証の設定が必要です
      </Typography>
      <Typography variant={TypographyType.Normal} style={{ paddingVertical: 10 }}>
        組織の設定で、2要素認証の設定が必須になっています。
        {`${'\n'}`}引き続きご利用になるには、2要素認証の設定が必要になります。
        {`${'\n'}`}設定方法については、
        <Link
          path="https://manual.timedesigner.com/v1/2-4"
          isExternalLink={true}>
          ヘルプページ
        </Link>
        をご参照ください。
      </Typography>
      <TwoFactorAuthSetting />
    </Container>
  );
};

export default RequiredTwoFactorAuthSettingPage;
