import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Text, View } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../../theme';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import Icon from '../../../../presentational/atoms/icon';
import {
  Member,
  Project,
  ProjectTasksDocument,
  Task,
  TaskStatus,
  useCreateTaskMutation,
  useCreateTasksMutation,
} from '../../../../../graphql/api/API';
import ColorUtil from '../../../../../util/ColorUtil';
import Form from '../../../../presentational/atoms/form';
import Input from '../../../../presentational/atoms/input';
import PlayIcon from '../../../../presentational/molecules/image-icon/play';
import PlusIcon from '../../../../presentational/molecules/image-icon/plus';
import { Reference } from '@apollo/client';
import ErrorMessageModal from '../error-message-modal';

interface IContainerProps extends IStyleTheme {
  isComplete: boolean;
  isTracking: boolean;
}

const Container = styled.View<IContainerProps>`
  display: flex;
  flex-direction: column;
  margin: 3px 5px;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.4s;
  z-index: 100;
  border-width: 1px;
  border-color: ${(props: IContainerProps) => props.theme.colors.separator};
  background-color: ${(props: IContainerProps) =>
    props.isTracking
      ? '#FFFFF0'
      : props.isComplete
      ? ColorUtil.lignten(props.theme.colors.separator, 3)
      : props.theme.colors.baseColor};
`;

const Main = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 3px 10px;
`;

interface IProps {
  teamId: string;
  project: Project;
  taskStatus: TaskStatus;
  lastTask: Task | null;
  me: Member;
  setShowCreateTasksLoading: (value: boolean) => void;
}

const TaskSummaryForBoardNewTask = (props: IProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [showNewTaskInput, setShowNewTaskInput] = useState(false);
  const [title, setTitle] = useState('');
  const ref = useRef();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [requestCreateTask, requestCreateTaskResult] = useCreateTaskMutation({
    variables: {
      projectId: props.project.id!,
      input: {
        title: title,
      },
    },
    update: (cache, result) => {
      cache.modify({
        fields: {
          boardTasks(existingRef, { readField }) {
            const taskStatusId = `TaskStatus:${result.data?.createTask?.taskStatus.id}`;
            if (taskStatusId === `TaskStatus:${props.taskStatus.id}`) {
              return {
                taskStatus: existingRef.taskStatus,
                tasks: [...existingRef.tasks, { __ref: `Task:${result.data?.createTask?.id}` }],
              };
            }
            return existingRef;
          },
        },
      });
    },
    refetchQueries: [
      {
        query: ProjectTasksDocument,
        variables: {
          teamId: props.teamId,
          projectId: props.project.id!,
        },
      },
    ],
  });
  const [requestCreateTasks] = useCreateTasksMutation({
    update: (cache, result) => {
      cache.modify({
        fields: {
          boardTasks(existingRef, { readField }) {
            const taskStatusId = `TaskStatus:${result.data?.createTasks?.[0]?.taskStatus.id}`;
            if (taskStatusId === `TaskStatus:${props.taskStatus.id}`) {
              return {
                taskStatus: existingRef.taskStatus,
                tasks: [
                  ...existingRef.tasks,
                  ...result.data!.createTasks!.map((task) => {
                    return {
                      __ref: `Task:${task!.id}`,
                    };
                  }),
                ],
              };
            }
            return existingRef;
          },
        },
      });
    },
    refetchQueries: [
      {
        query: ProjectTasksDocument,
        variables: {
          teamId: props.teamId,
          projectId: props.project.id!,
        },
      },
    ],
  });

  const clickDocument = (e: any) => {
    if ((ref?.current as any)?.contains(e.target)) {
      (ref?.current as any)?.click();
      return;
    }
    setShowNewTaskInput(false);
  };
  useEffect(() => {
    window.addEventListener('click', clickDocument);
    return () => {
      window.removeEventListener('click', clickDocument);
    };
  }, [clickDocument]);

  const onPressEnter = useCallback(
    async (value) => {
      if (value.trim().length > 0) {
        await requestCreateTask({
          variables: {
            projectId: props.project.id!,
            input: {
              title: value,
              taskStatusId: props.taskStatus.id!,
              sortNoInTaskStatus: props.lastTask ? props.lastTask.sortNoInTaskStatus - 1000 : null,
            },
          },
        });
      } else {
        setShowNewTaskInput(false);
      }
    },
    [
      props.project,
      props.taskStatus.id,
      props.lastTask,
      props.lastTask?.sortNoInTaskStatus,
      setShowNewTaskInput,
      requestCreateTask,
    ]
  );

  const onPaste = useCallback(
    async (value: string) => {
      props.setShowCreateTasksLoading(true);
      const titles = value
        .split(/\r\n|\n/)
        .map((v) => v.trim())
        .filter((v) => v.length > 0);

      if (titles.length === 0) {
        props.setShowCreateTasksLoading(false);
        setShowNewTaskInput(false);
        return;
      }
      await requestCreateTasks({
        variables: {
          projectId: props.project.id!,
          input: {
            tasks: titles.map((title) => {
              return {
                title: title.trim().substring(0, 100),
                taskStatusId: props.taskStatus.id!,
                sortNoInTaskStatus: props.lastTask
                  ? props.lastTask.sortNoInTaskStatus - 1000
                  : null,
              };
            }),
          },
        },
      });
      props.setShowCreateTasksLoading(false);
      setShowNewTaskInput(false);
    },
    [
      props.project,
      props.taskStatus.id,
      props.lastTask,
      props.lastTask?.sortNoInTaskStatus,
      props.setShowCreateTasksLoading,
      setShowNewTaskInput,
      requestCreateTasks,
    ]
  );

  return (
    <View ref={ref as any}>
      {!showNewTaskInput ? (
        <PlusIcon
          size={20}
          containerStyle={{ paddingLeft: 10 }}
          onPress={() => {
            if (!props.me.taskCreatePermissionFlg) {
              setShowErrorModal(true);
              return;
            }
            setShowNewTaskInput(true);
          }}>
          <Typography variant={TypographyType.Normal} style={{ fontSize: 14, lineHeight: 20 }}>
            タスクを追加
          </Typography>
        </PlusIcon>
      ) : (
        <Container isComplete={false} isTracking={false}>
          <Main>
            <View style={{ flexDirection: 'row', width: '100%' }}>
              <View style={{ flexDirection: 'column' }}>
                <View style={{ flexDirection: 'row' }}>
                  <View style={{ flexDirection: 'row' }}>
                    <Form style={{ minWidth: 200 }}>
                      <Input
                        name={'newTaskName'}
                        focus={true}
                        placeholder={'タスク名を入力してください'}
                        inputstyle={{ borderWidth: 0, paddingHorizontal: 0, width: '100%' }}
                        inputContainerStyle={{ width: '100%' }}
                        containerStyle={{ margin: 0 }}
                        validate={{
                          maxLength: {
                            value: 100,
                            message: '100文字以内で入力してください',
                          },
                        }}
                        clearValueOnBlur={true}
                        allowContinuousInput={true}
                        onChange={(value) => setTitle(value)}
                        onBlur={() => setShowNewTaskInput(false)}
                        onPressEnter={onPressEnter}
                        onPaste={onPaste}
                      />
                    </Form>
                  </View>
                </View>
              </View>
              <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                <PlayIcon size={42} />
              </View>
            </View>
          </Main>
          <View
            style={{
              backgroundColor: '#F8F8F8',
              width: '100%',
              height: 4,
              borderBottomWidth: 1,
              borderColor: themeContext.colors.separator,
            }}>
            <View
              style={{
                backgroundColor: '#FBD7B2',
                height: 4,
                position: 'absolute',
                left: 0,
              }}
            />
            <View
              style={{
                backgroundColor: '#EEADC3',
                height: 4,
                position: 'absolute',
                right: 0,
              }}
            />
          </View>
          <View style={{ backgroundColor: '#F8F8F8', width: '100%', height: 4 }}>
            <View
              style={{
                backgroundColor: '#99dcff',
                height: 4,
                position: 'absolute',
                left: 0,
              }}
            />
          </View>
        </Container>
      )}
      <ErrorMessageModal
        showModal={showErrorModal}
        title={'タスクを作成できません'}
        message={`タスクを作成する権限がありません${'\n'}権限が必要な場合、管理権限を持っているメンバーに問い合わせてください`}
        onCloseModal={() => setShowErrorModal(false)}
      />
    </View>
  );
};

export default TaskSummaryForBoardNewTask;
