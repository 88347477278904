import Constants from 'expo-constants';
import React from 'react';
import { Helmet } from 'react-helmet';

const GaScript = () => {
  return (
    <Helmet>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${
          Constants.manifest!.extra!.googleAnalyticsId
        }`}></script>
      <script>
        {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${Constants.manifest!.extra!.googleAnalyticsId}');
        `}
      </script>
    </Helmet>
  );
};

export default GaScript;
