import React, { useContext, useState } from 'react';
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../../theme';
import LoginUtil from '../../../../../modules/auth/LoginUtil';
import {
  ProjectTemplateDocument,
  ProjectTemplateTasksDocument,
  ProjectTemplateTasksGroupByStatusDocument,
  ProjectTemplateTaskStatus,
  useDeleteProjectTemplateTaskStatusMutation,
  useProjectTemplateTaskStatusesQuery,
  useProjectTemplateTaskStatusQuery,
} from '../../../../../graphql/api/API';
import Form from '../../../../presentational/atoms/form';
import Input from '../../../../presentational/atoms/input';
import { View } from 'react-native';
import Button from '../../../../presentational/atoms/button';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';

const Container = styled.View``;

interface IProps {
  taskStatus: ProjectTemplateTaskStatus;
  onComplete: () => void;
  onCancel: () => void;
}

const TaskStatusDeleteDialog = (props: IProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [moveTargetStatusId, setMoveTargetStatusId] = useState<string | null>(null);
  const { loading, data, error } = useProjectTemplateTaskStatusesQuery({
    variables: {
      projectTemplateId: props.taskStatus.projectTemplate.id!,
    },
  });
  const [deleteTaskStatus, _] = useDeleteProjectTemplateTaskStatusMutation({
    variables: {
      id: props.taskStatus.id!,
      input: {
        taskMoveDestStatusId: moveTargetStatusId,
        versionNo: props.taskStatus.versionNo,
      },
    },
    refetchQueries: [
      {
        query: ProjectTemplateDocument,
        variables: {
          id: props.taskStatus.projectTemplate.id!,
        },
      },
      {
        query: ProjectTemplateTasksGroupByStatusDocument,
        variables: {
          projectTemplateId: props.taskStatus.projectTemplate.id!,
        },
      },
      {
        query: ProjectTemplateTasksDocument,
        variables: {
          projectTemplateId: props.taskStatus.projectTemplate.id!,
        },
      },
    ],
  });

  if (loading || !data?.projectTemplateTaskStatuses) {
    return <></>;
  }

  return (
    <Container>
      <Form style={{ minWidth: 300 }}>
        <Typography
          variant={TypographyType.Description}
          style={{ fontSize: 16, lineHeight: 22, marginBottom: 20 }}>
          {`このステータスにあるタスクを、${'\n'}どのステータスに移動させるかを選択してください。`}
        </Typography>
        <Input
          name={'taskStatus'}
          label={'移動先のステータス'}
          type={'picker'}
          initialValue={props.taskStatus.id!}
          pickerItems={data.projectTemplateTaskStatuses
            .slice()
            .filter((status) => status!.id! !== props.taskStatus.id! && status!.endStatus === false)
            .map((status) => {
              return {
                label: status!.name,
                value: status!.id,
              };
            })}
          onChange={(value) => setMoveTargetStatusId(value)}
          validate={{
            required: {
              value: true,
              message: '移動先のステータスを選択してください',
            },
          }}
        />
        <View style={{ marginVertical: 10 }}>
          <Typography
            variant={TypographyType.Description}
            style={{ textAlign: 'center', color: themeContext.colors.error }}>
            {`この操作はやり直しが出来ません`}
          </Typography>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
          <Button
            text="キャンセル"
            style={{ height: 30, backgroundColor: 'transparent' }}
            textStyle={{ fontSize: 14, color: themeContext.colors.primary }}
            onPress={async () => {
              props.onCancel();
            }}
            disableValidate={true}
          />
          <Button
            text="削除する"
            completeText="削除しました"
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              borderColor: themeContext.colors.error,
              borderRadius: 3,
              borderWidth: 1,
              backgroundColor: 'transparent',
            }}
            textStyle={{ fontSize: 14, color: themeContext.colors.error }}
            isDisabled={!moveTargetStatusId}
            onPress={async () => {
              await deleteTaskStatus();
              props.onComplete();
            }}
          />
        </View>
      </Form>
    </Container>
  );
};

export default TaskStatusDeleteDialog;
