import _ from 'lodash';
import moment from 'moment-timezone';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { Image, type TextStyle, TouchableOpacity, View } from 'react-native';
import ToolTip from 'react-portal-tooltip';
import { useHistory } from 'react-router';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import noData04 from '../../../../../../base64Images/no-data/no-data-4';
import {
  type ClosingSetting,
  LatestWorkingHistoryDocument,
  type Member,
  ProjectWorkingTimeSecDocument,
  TaskDocument,
  type WorkingHistory,
  type WorkingHistoryForSummary,
  useClosingSettingsQuery,
  useDeleteWorkingHistoryMutation,
  useMeQuery,
  useOrganizationMembersQuery,
  useTeamMembersQuery,
  useWorkingHistoriesSpecifyTermForSummaryQuery,
  useWorkingHistoriesSpecifyTermQuery,
} from '../../../../../../graphql/api/API';
import { LoginUserContext } from '../../../../../../modules/auth/LoginUserContext';
import TimeUtil from '../../../../../../util/TimeUtil';
import UrlUtil from '../../../../../../util/UrlUtil';
import Button from '../../../../../presentational/atoms/button';
import List from '../../../../../presentational/atoms/list';
import Spinner from '../../../../../presentational/atoms/spinner';
import Typography, { TypographyType } from '../../../../../presentational/atoms/typography';
import DeleteIcon from '../../../../../presentational/molecules/image-icon/delete';
import EditIcon from '../../../../../presentational/molecules/image-icon/edit';
import FileMoveIcon from '../../../../../presentational/molecules/image-icon/file-move';
import LeftIcon from '../../../../../presentational/molecules/image-icon/left';
import MenuIcon from '../../../../../presentational/molecules/image-icon/menu';
import PlusIcon from '../../../../../presentational/molecules/image-icon/plus';
import RightIcon from '../../../../../presentational/molecules/image-icon/right';
import Modal from '../../../../../presentational/molecules/modal';
import type { IStyleTheme, IThemePart } from '../../../../../theme';
import CreateWorkingHistoryModal from '../../../../web/organisms/create-working-history-modal';
import MoveWorkingHistoryModal from '../../../../web/organisms/move-working-history-modal';
import EditWorkingHistoryModal from '../../edit-working-history-modal';

const AddButton = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  padding-right: 5px;
  padding-left: 5px;
`;

const DateHeader = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${(props: IStyleTheme) => props.theme.colors.header};
  color: #ffffff;
  font-size: 1.5rem;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
`;

const Header = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${(props: IStyleTheme) => props.theme.colors.subHeader};
  color: #ffffff;
  font-size: 1.5rem;
  padding: 5px 15px;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
`;

const SubHeader = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
  font-size: 1.5rem;
  padding: 5px 15px;
`;

const Row = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
  color: #ffffff;
  font-size: 1.5rem;
  padding: 10px 15px;
  border-bottom-width: 1px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
`;

const Menu = styled.View`
  position: absolute;
  top: 40px;
  right: 0;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
  border-width: 1px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 160px;
`;

const ListContainer = styled.View`
  height: calc(100vh - 150px);
  overflow-y: scroll;
`;

interface IDeleteModalProps {
  workingHistory: WorkingHistoryForSummary;
  showModal: boolean;
  onPressYes: () => Promise<void>;
  onCloseModal: () => void;
  endDateTime: moment.Moment;
}

const DeleteModal = React.memo((props: IDeleteModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [deleteWorkingHistory, __] = useDeleteWorkingHistoryMutation({
    variables: {
      id: props.workingHistory.id!,
      input: {
        versionNo: props.workingHistory.versionNo,
      },
    },
    update: (cache, result) => {
      cache.evict({
        id: cache.identify(props.workingHistory),
      });
      cache.evict({ id: `WorkingHistoryForSummary:${props.workingHistory.id}` });
      cache.evict({ id: `CalendarWorkingHistory:${props.workingHistory.id}` });
    },
    refetchQueries: props.workingHistory.task
      ? [
          {
            query: TaskDocument,
            variables: {
              id: props.workingHistory.task.id!,
            },
          },
          {
            query: LatestWorkingHistoryDocument,
          },
          {
            query: ProjectWorkingTimeSecDocument,
            variables: {
              id: props.workingHistory.task.project.id,
            },
          },
        ]
      : [],
  });

  return (
    <Modal
      title={'この作業履歴を削除しますか？'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <Typography variant={TypographyType.Normal} style={{ textAlign: 'center' }}>
        {`${moment(props.workingHistory.start).format('YYYY/MM/DD HH:mm')} 〜 ${
          props.workingHistory.end
            ? moment(props.workingHistory.end).format('YYYY/MM/DD HH:mm')
            : '-'
        }`}
      </Typography>
      <View style={{ marginTop: 10 }}>
        <Typography
          variant={TypographyType.Description}
          style={{ textAlign: 'center', color: themeContext.colors.error }}>
          {`この操作はやり直しが出来ません`}
        </Typography>
      </View>
      <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1 }}>
        <Button
          text={'キャンセル'}
          style={{
            minWidth: 100,
            marginRight: 10,
            marginVertical: 10,
            backgroundColor: 'transparent',
          }}
          textStyle={{ color: themeContext.colors.primary }}
          disableValidate={true}
          onPress={() => {
            props.onCloseModal();
          }}
        />
        <Button
          text={'削除する'}
          style={{
            minWidth: 100,
            marginRight: 10,
            marginVertical: 10,
            borderColor: themeContext.colors.error,
            borderRadius: 3,
            borderWidth: 1,
            backgroundColor: 'transparent',
          }}
          textStyle={{ color: themeContext.colors.error }}
          onPress={async () => {
            await deleteWorkingHistory();
            await props.onPressYes();
          }}
        />
      </View>
    </Modal>
  );
});

interface IWorkingHistoryTimeProps {
  workingHistory: WorkingHistoryForSummary;
  style?: TextStyle;
}

export const WorkingHistoryTime = (props: IWorkingHistoryTimeProps) => {
  const [workingTimeSec, setWorkingTimeSec] = useState(0);
  useEffect(() => {
    const trackingHistoryWorkingTimeSec = moment(props.workingHistory.end || new Date()).diff(
      moment(props.workingHistory.start),
      'seconds'
    );
    setWorkingTimeSec(trackingHistoryWorkingTimeSec);
  }, [props.workingHistory]);

  setInterval(() => {
    try {
      if (!props.workingHistory.end) {
        const trackingHistoryWorkingTimeSec = moment().diff(
          moment(props.workingHistory.start),
          'seconds'
        );
        setWorkingTimeSec(trackingHistoryWorkingTimeSec);
      }
    } catch (_e) {
      // NOP
    }
  }, 1000);

  return (
    <Typography variant={TypographyType.Normal} style={props.style}>
      {TimeUtil.formatForTask(workingTimeSec)}
    </Typography>
  );
};

interface ICalendarWindowHeaderProps {
  endDateTime: moment.Moment;
  setEndDateTime: (value: moment.Moment) => void;
}

const CalendarWindowHeader = (props: ICalendarWindowHeaderProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);

  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 10,
        width: '100%',
      }}>
      <LeftIcon
        size={30}
        reverse={true}
        onPress={() => props.setEndDateTime(moment(props.endDateTime).add(-7, 'day'))}
      />
      <Typography variant={TypographyType.Normal} style={{ color: '#FFFFFF' }}>
        {moment(props.endDateTime).add(-6, 'days').startOf('day').format('ll')}
      </Typography>
      <Typography variant={TypographyType.Normal} style={{ color: '#FFFFFF' }}>
        〜
      </Typography>
      <Typography variant={TypographyType.Normal} style={{ color: '#FFFFFF' }}>
        {props.endDateTime.format('ll')}
      </Typography>
      <RightIcon
        size={30}
        reverse={true}
        onPress={() => {
          props.setEndDateTime(moment(props.endDateTime).add(7, 'day'));
        }}
      />
    </View>
  );
};
const MemorizedCalendarWindowHeader = React.memo(CalendarWindowHeader);

interface IWorkingHistoryItemProps {
  workingHistory: WorkingHistoryForSummary;
  me: Member;
  isGroupFirstRow: boolean;
  endDateTime: moment.Moment;
  moveToFront: (value: boolean) => void;
  closingSettings: ClosingSetting;
}

const WorkingHistoryItem = React.memo((props: IWorkingHistoryItemProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, _] = useContext(LoginUserContext);
  const menuRef = useRef();
  const refOuter = useRef<HTMLSpanElement>(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showTooltipClosed, setShowTooltipClosed] = useState(false);
  const history = useHistory();
  const [openMenu, setOpenMenu] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showMoveModal, setShowMoveModal] = useState(false);
  useHotkeys('Esc', () => setOpenMenu(false));
  const clickDocument = (e: any) => {
    if ((menuRef?.current as any)?.contains(e.target)) {
      (menuRef?.current as any)?.click();
      return;
    }
    setOpenMenu(false);
  };
  useEffect(() => {
    window.addEventListener('click', clickDocument);
    return () => {
      window.removeEventListener('click', clickDocument);
    };
  }, [clickDocument]);

  const memoSummary = props.workingHistory.memo
    ? JSON.parse(props.workingHistory.memo)
        .blocks.map((b: any) => b.text)
        .join(' ')
    : undefined;

  const memo = props.workingHistory.memo
    ? JSON.parse(props.workingHistory.memo).blocks.map((b: any) => b.text)
    : undefined;

  const isClosedDate = false;
    // !!props.closingSettings.targetDateTime &&
    // moment(props.workingHistory.start).isBefore(moment(props.closingSettings.targetDateTime));

  return (
    <View style={{ flexDirection: 'column' }}>
      <Row
        style={{
          borderTopWidth: props.isGroupFirstRow ? 1 : 0,
          backgroundColor: isClosedDate
            ? themeContext.colors.separator
            : themeContext.colors.baseColor,
        }}
        onPress={() => {
          if (openMenu) {
            setOpenMenu(false);
            props.moveToFront(false);
          } else {
            if (props.workingHistory.task) {
              history.push(UrlUtil.createTaskDetailUrlById(props.workingHistory!.task.id));
            }
            if (props.workingHistory.calendarEvent) {
              history.push(
                UrlUtil.createCalendarEventDetailUrlById(props.workingHistory!.calendarEvent.id)
              );
            }
          }
        }}>
        {/* <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
          id={`working-history-closed-tooltip-${props.workingHistory.id}`}
          onMouseEnter={() => setShowTooltipClosed(true)}
          onMouseLeave={() => setShowTooltipClosed(false)}> */}
          <View style={{ width: 144, alignItems: 'flex-start', justifyContent: 'center' }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontSize: 11,
                textAlign: 'left',
                maxWidth: '100%',
                lineHeight: 13,
                color: themeContext.colors.description,
              }}
              ellipsis={true}>
              {props.workingHistory.task?.project.client?.name ||
                props.workingHistory.calendarEvent?.clientName}
            </Typography>
            <Typography
              variant={TypographyType.Normal}
              style={{ fontSize: 11, textAlign: 'left', maxWidth: '100%', lineHeight: 13 }}
              ellipsis={true}>
              {props.workingHistory.task?.project.name ||
                props.workingHistory.calendarEvent?.projectName}
            </Typography>
            <Typography
              variant={TypographyType.Normal}
              style={{ fontSize: 13, textAlign: 'left', maxWidth: '100%', lineHeight: 16 }}
              ellipsis={true}>
              {props.workingHistory.task?.title || props.workingHistory.calendarEvent?.eventName}
            </Typography>
          </View>
          <View style={{ alignItems: 'center', justifyContent: 'center', minWidth: 80 }}>
            <Typography
              variant={TypographyType.Normal}
              style={{ fontSize: 13, lineHeight: 18, textAlign: 'center' }}>
              {`${props.workingHistory.start.format('YYYY/MM/DD')}${'\n'}${moment(
                props.workingHistory.start
              ).format('HH:mm')}`}
            </Typography>
          </View>
          <View style={{ minWidth: 30, alignItems: 'center', justifyContent: 'center' }}>
            <Typography
              variant={TypographyType.Normal}
              style={{ fontSize: 13, textAlign: 'center' }}>
              〜
            </Typography>
          </View>
          <View style={{ alignItems: 'center', justifyContent: 'center', minWidth: 80 }}>
            {props.workingHistory.end ? (
              <Typography
                variant={TypographyType.Normal}
                style={{ fontSize: 13, lineHeight: 18, textAlign: 'center' }}>
                {`${props.workingHistory.end.format('YYYY/MM/DD')}${'\n'}${moment(
                  props.workingHistory.end
                ).format('HH:mm')}`}
              </Typography>
            ) : (
              <Typography
                variant={TypographyType.Normal}
                style={{ fontSize: 13, lineHeight: 18, textAlign: 'center' }}>
                -
              </Typography>
            )}
          </View>
          <View style={{ minWidth: 100, alignItems: 'center', justifyContent: 'center' }}>
            <WorkingHistoryTime
              workingHistory={props.workingHistory}
              style={{ fontSize: 13, textAlign: 'center' }}
            />
          </View>
          {props.workingHistory.memo ? (
            <div
              id={`right-side-bar-working-history-memo-tooltip-${props.workingHistory.id}`}
              ref={refOuter as any}
              style={{
                flex: 1,
                alignItems: 'flex-start',
                justifyContent: 'center',
                marginLeft: 5,
                overflow: 'hidden',
              }}
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}>
              <Typography
                variant={TypographyType.Normal}
                ellipsis
                style={{ fontSize: 13, textAlign: 'left', lineHeight: 17, display: 'block', width: 80 }}>
                {memoSummary ?? '-'}
              </Typography>
              <ToolTip
                active={showTooltip && memoSummary.length > 7}
                position="bottom"
                arrow="center"
                tooltipTimeout={1}
                parent={`#right-side-bar-working-history-memo-tooltip-${props.workingHistory.id}`}>
                {memo.map((line: string) => (
                  <div>{line}</div>
                ))}
              </ToolTip>
            </div>
          ) : (
            <View
              style={{
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: 5,
              }}>
              <Typography
                variant={TypographyType.Normal}
                ellipsis
                style={{ fontSize: 13, textAlign: 'center', lineHeight: 17, display: 'block', width: 80 }}>
                {memoSummary ?? '-'}
              </Typography>
            </View>
          )}
          <View style={{ minWidth: 50, alignItems: 'center', justifyContent: 'center' }}>
            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              {!isClosedDate && (
                <MenuIcon
                  size={28}
                  onPress={() => {
                    props.moveToFront(true);
                    setOpenMenu(true);
                  }}
                />
              )}
            </View>
          </View>
          <ToolTip
            active={showTooltipClosed && isClosedDate}
            position="bottom"
            arrow="center"
            tooltipTimeout={1}
            parent={`#working-history-closed-tooltip-${props.workingHistory.id}`}>
            {isClosedDate && (
              <div>
                {moment(props.closingSettings.targetDateTime).format('ll')}
                までのデータは締め処理済みのため編集できません。
              </div>
            )}
          </ToolTip>
          <DeleteModal
            workingHistory={props.workingHistory}
            showModal={showDeleteModal}
            onPressYes={async () => {
              setShowDeleteModal(false);
            }}
            onCloseModal={() => setShowDeleteModal(false)}
            endDateTime={props.endDateTime}
          />
          <EditWorkingHistoryModal
            workingHistory={props.workingHistory}
            showModal={showEditModal}
            onPressYes={async () => {
              setShowEditModal(false);
            }}
            onCloseModal={() => setShowEditModal(false)}
            me={props.me}
            />
          <MoveWorkingHistoryModal
            workingHistory={props.workingHistory}
            showModal={showMoveModal}
            onCloseModal={() => setShowMoveModal(false)}
          />
        {/* </div> */}
      </Row>
      {openMenu && (
        <Menu ref={menuRef as any}>
          <View style={{ paddingHorizontal: 5, marginVertical: 5 }}>
            <EditIcon
              size={21}
              containerStyle={{ marginRight: 5 }}
              onPress={() => {
                props.moveToFront(false);
                setShowEditModal(true);
                setOpenMenu(false);
              }}>
              <Typography variant={TypographyType.SubTitle} style={{ paddingLeft: 3 }}>
                編集する
              </Typography>
            </EditIcon>
          </View>
          <View style={{ paddingHorizontal: 5 }}>
            <FileMoveIcon
              size={20}
              containerStyle={{ marginRight: 5 }}
              onPress={() => {
                props.moveToFront(false);
                setShowMoveModal(true);
                setOpenMenu(false);
              }}>
              <Typography variant={TypographyType.SubTitle}>移動する</Typography>
            </FileMoveIcon>
          </View>
          <View style={{ paddingHorizontal: 5 }}>
            <DeleteIcon
              size={23}
              containerStyle={{ marginRight: 5 }}
              onPress={() => {
                props.moveToFront(false);
                setShowDeleteModal(true);
                setOpenMenu(false);
              }}>
              <Typography variant={TypographyType.SubTitle}>削除する</Typography>
            </DeleteIcon>
          </View>
        </Menu>
      )}
      {/* <View>
        {!isContentEmpty && (
          <Form>
            <Input
              initialValue={props.workingHistory.memo!}
              name={'description'}
              type={'rich-text-editor'}
              showToolBarAlways={false}
              multiline={true}
              readonly={true}
            />
          </Form>
        )}
      </View> */}
    </View>
  );
});

interface IWorkingHistoryListProps {
  endDateTime: moment.Moment;
  me: Member;
  closingSettings: ClosingSetting;
  setShowRegisterModal: (value: boolean) => void;
}

const WorkingHistoryList = React.memo((props: IWorkingHistoryListProps) => {
  const pageSize = 30;
  const themeContext: IThemePart = useContext(ThemeContext);
  const { loading, data, fetchMore } = useWorkingHistoriesSpecifyTermForSummaryQuery({
    variables: {
      input: {
        start: moment(props.endDateTime).add(-6, 'days').startOf('day').toISOString(),
        end: props.endDateTime.endOf('day').toISOString(),
      },
      offset: 0,
      limit: pageSize,
    },
    fetchPolicy: 'network-only',
  });

  const renderItem = useCallback(
    (item, dragging, index, isGroupFirstRow, moveToFront) => (
      <WorkingHistoryItem
        isGroupFirstRow={isGroupFirstRow}
        workingHistory={item.data}
        endDateTime={props.endDateTime}
        moveToFront={moveToFront}
        closingSettings={props.closingSettings}
        me={props.me}
        key={index}
      />
    ),
    [props.endDateTime, props.closingSettings, props.me]
  );

  const workingHistories = (data?.workingHistoriesSpecifyTermForSummary || [])
    .slice()
    .map((history) => {
      return {
        data: {
          id: history!.id!,
          start: moment(history!.start),
          end: history!.end ? moment(history!.end) : null,
          memo: history!.memo,
          task: history!.task,
          calendarEvent: history!.calendarEvent,
          workingMember: history!.workingMember,
          workingTimeSec: history?.workingTimeSec,
          versionNo: history!.versionNo,
          closingProcessInfo: {
            closed: history!.closingProcessInfo.closed,
            targetDateTime: history!.closingProcessInfo.targetDateTime,
          },
        } as WorkingHistory,
        group: moment(history!.start).format('ll'),
      };
    })
    .sort(
      (a, b) =>
        (b.data.start as moment.Moment).toDate().getTime() -
        (a.data.start as moment.Moment).toDate().getTime()
    );

  return (
    <ListContainer>
      <Spinner loading={loading || !data?.workingHistoriesSpecifyTermForSummary}>
        {workingHistories.length > 0 ? (
          <List
            style={{ height: 'calc(100vh - 160px)', overflow: 'scroll' }}
            items={workingHistories}
            getKey={(workingHistory) => (workingHistory.data as any).id}
            renderGroupHeader={(group) => (
              <>
                <Header>
                  <Typography
                    variant={TypographyType.Normal}
                    style={{ color: '#FFFFFF', fontSize: 14 }}>
                    {group}
                  </Typography>
                  <Typography
                    variant={TypographyType.Normal}
                    style={{ color: '#FFFFFF', fontSize: 14 }}>
                    {`合計作業時間：${TimeUtil.formatForTask(
                      workingHistories
                        .filter((history) => history.group === group)
                        .map(
                          (history) =>
                            ((history.data.end || moment()).toDate().getTime() -
                              history.data.start.toDate().getTime()) /
                            1000
                        )
                        .reduce((prev, current) => prev + current, 0)
                    )}`}
                  </Typography>
                </Header>
                <SubHeader style={{ backgroundColor: themeContext.colors.baseColor }}>
                  <View style={{ width: 144, alignItems: 'flex-start', justifyContent: 'center' }}>
                    <Typography variant={TypographyType.Description} style={{ fontSize: 12 }}>
                      プロジェクト名
                    </Typography>
                    <Typography variant={TypographyType.Description} style={{ fontSize: 12 }}>
                      タスク名
                    </Typography>
                  </View>
                  <View style={{ minWidth: 80, alignItems: 'center', justifyContent: 'center' }}>
                    <Typography variant={TypographyType.Description} style={{ fontSize: 12 }}>
                      開始日時
                    </Typography>
                  </View>
                  <View
                    style={{ minWidth: 30, alignItems: 'center', justifyContent: 'center' }}></View>
                  <View style={{ minWidth: 80, alignItems: 'center', justifyContent: 'center' }}>
                    <Typography variant={TypographyType.Description} style={{ fontSize: 12 }}>
                      終了日時
                    </Typography>
                  </View>
                  <View style={{ minWidth: 100, alignItems: 'center', justifyContent: 'center' }}>
                    <Typography variant={TypographyType.Description} style={{ fontSize: 13 }}>
                      作業時間
                    </Typography>
                  </View>
                  <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                    <Typography variant={TypographyType.Description} style={{ fontSize: 13 }}>
                      メモ
                    </Typography>
                  </View>
                  <View style={{ minWidth: 50 }} />
                </SubHeader>
              </>
            )}
            showGroupHeader={true}
            renderItem={renderItem}
            onEndReached={() => {
              if ((data?.workingHistoriesSpecifyTermForSummary?.length ?? 0) < pageSize) {
                return;
              }
              fetchMore({
                variables: {
                  offset: data!.workingHistoriesSpecifyTermForSummary!.length,
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev;
                  return Object.assign({}, prev, {
                    workingHistoriesSpecifyTermForSummary: [
                      ...(prev.workingHistoriesSpecifyTermForSummary || []),
                      ...(fetchMoreResult.workingHistoriesSpecifyTermForSummary || []),
                    ],
                  });
                },
              });
            }}
          />
        ) : (
          <View
            style={{
              height: 'calc(100% - 150px)',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Image
              source={{ uri: noData04 }}
              resizeMode="contain"
              style={{
                width: 350,
                height: 200,
                opacity: 0.7,
              }}
            />
            <Typography
              variant={TypographyType.Normal}
              style={{ color: themeContext.colors.description, textAlign: 'center' }}>
              {`この期間の作業履歴は見つかりませんでした`}
            </Typography>
          </View>
        )}
      </Spinner>
    </ListContainer>
  );
});

const RightSidebarWorkingHistory = () => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, _] = useContext(LoginUserContext);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [endDateTime, setEndDateTime] = useState(moment().endOf('day'));
  const { loading, data } = useClosingSettingsQuery({
    fetchPolicy: 'network-only',
  });
  const { loading: meLoading, data: meData } = useMeQuery();

  if (loading || !data?.closingSettings || meLoading || !meData?.me) {
    return <></>;
  }

  return (
    <>
      <DateHeader>
        <MemorizedCalendarWindowHeader endDateTime={endDateTime} setEndDateTime={setEndDateTime} />
      </DateHeader>
      <AddButton
        onPress={() => setShowModal(true)}
        style={{
          display: 'flex',
          flexDirection: 'row',
          paddingLeft: 10,
        }}>
        <PlusIcon size={13} onPress={() => setShowModal(true)}>
          <Typography
            variant={TypographyType.Normal}
            style={{ fontSize: 13, color: themeContext.colors.description }}>
            作業履歴を追加する
          </Typography>
        </PlusIcon>
      </AddButton>
      <WorkingHistoryList
        endDateTime={endDateTime}
        setShowRegisterModal={setShowRegisterModal}
        closingSettings={data.closingSettings}
        me={meData.me!}
      />
      <CreateWorkingHistoryModal
        startDateTime={moment().seconds(0).milliseconds(0)}
        endDateTime={moment().seconds(0).milliseconds(0)}
        showModal={showModal}
        onPressYes={() => setShowModal(false)}
        onCloseModal={() => setShowModal(false)}
      />
    </>
  );
};

export default React.memo(RightSidebarWorkingHistory);
