import moment from 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { Image, type TextStyle, TouchableOpacity, View } from 'react-native';
import { useHistory } from 'react-router';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import useInterval from 'use-interval';
import {
  CalendarWorkingHistoryDocument,
  LatestWorkingHistoryDocument,
  type Member,
  ProjectInitialViewType,
  ProjectWorkingTimeSecDocument,
  type Task,
  TaskDocument,
  TaskWorkingHistorySummaryGroupByAssineeDocument,
  type WorkingHistory,
  WorkingHistoryForSummaryDocument,
  useMySettingsQuery,
  useStartTaskMutation,
  useStopTaskMutation,
} from '../../../../../graphql/api/API';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import DateUtil from '../../../../../util/DateUtil';
import TaskUtil from '../../../../../util/TaskUtil';
import TimeUtil from '../../../../../util/TimeUtil';
import { UpdateWorkingHistoryModal } from '../../../../container/web/organisms/task-detail/working-history';
import Icon from '../../../../presentational/atoms/icon';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import PlayIcon from '../../../../presentational/molecules/image-icon/play';
import StopIcon from '../../../../presentational/molecules/image-icon/stop';
import { IStyleTheme, type IThemePart } from '../../../../theme';
import ErrorMessageModal from '../error-message-modal';
import { TaskProgressBar } from '../task-progress-bar';

const Container = styled.View`
  display: flex;
  flex-direction: column;
  transition: all 0.4s;
  height: 57px;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: center;
  align-items: flex-start;
`;

const Main = styled.TouchableOpacity`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
`;

interface ITaskWorkingTimeOnlyCountUpLoginUserProps {
  task: Task;
  style?: TextStyle;
}

export const TaskWorkingTimeOnlyCountUpLoginUser = (
  props: ITaskWorkingTimeOnlyCountUpLoginUserProps
) => {
  const [loginUser, _] = useContext(LoginUserContext);
  const [workingTimeSec, setWorkingTimeSec] = useState(
    props.task.workingMembers
      .filter((workingMemberInfo) => workingMemberInfo!.member.id === loginUser!.id)
      .map((workingMemberInfo) => {
        return moment().diff(moment(workingMemberInfo!.lastWorkingStartDateTime), 'seconds');
      })
      .reduce((sum, seconds) => {
        return sum + seconds;
      }, 0)
  );
  useEffect(() => {
    setWorkingTimeSec(TaskUtil.prepareTotalWorkingTimeSec(props.task));
  }, [props.task]);

  useInterval(() => {
    // 自分が作業している時だけカウントアップ表示する。
    // 他人が作業している時には、情報としてはノイズになるのでここではカウントアップしない
    if (
      props.task.workingMembers.findIndex(
        (workingMemberInfo) => workingMemberInfo!.member.id === loginUser!.id
      ) !== -1
    ) {
      setWorkingTimeSec(workingTimeSec + 1);
    }
  }, 1000);

  return (
    <Typography variant={TypographyType.Normal} style={props.style}>
      {TimeUtil.formatForTask(workingTimeSec)}
    </Typography>
  );
};

interface ITaskCurrentWorkingTimeProps {
  workingHistory: WorkingHistory | null;
  style?: TextStyle;
}

export const TaskCurrentWorkingTime = (props: ITaskCurrentWorkingTimeProps) => {
  const [workingTimeSec, setWorkingTimeSec] = useState(0);
  const [loginUser, _] = useContext(LoginUserContext);

  useEffect(() => {
    if (props.workingHistory) {
      if (props.workingHistory.end) {
        setWorkingTimeSec(
          moment(props.workingHistory.end).diff(moment(props.workingHistory.start), 'seconds')
        );
      } else {
        setWorkingTimeSec(moment().diff(moment(props.workingHistory.start), 'seconds'));
      }
    } else {
      setWorkingTimeSec(0);
    }
  }, [props.workingHistory]);

  useInterval(() => {
    if (props.workingHistory && !props.workingHistory.end) {
      setWorkingTimeSec(moment().diff(moment(props.workingHistory.start), 'seconds'));
    }
  }, 1000);

  return (
    <Typography variant={TypographyType.Normal} style={props.style}>
      {TimeUtil.formatForTask(workingTimeSec)}
    </Typography>
  );
};

interface IProps {
  organizationId: string;
  workingHistory: WorkingHistory;
  me: Member;
}

const TaskSummaryForHeader = (props: IProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const history = useHistory();
  const [loginUser, _] = useContext(LoginUserContext);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showCompleteTaskStartErrorModal, setShowCompleteTaskStartErrorModal] = useState(false);
  const [isTracking, setTracking] = useState(
    TaskUtil.isTracking(props.workingHistory.task!, loginUser!)
  );
  const { loading: mySettingsLoading, data: mySettingsData } = useMySettingsQuery();

  useEffect(() => {
    setTracking(TaskUtil.isTracking(props.workingHistory.task!, loginUser!));
  }, [props.workingHistory.task!.workingMembers]);

  const [startTask, startTaskResult] = useStartTaskMutation({
    update: (cache, result) => {
      // レスポンスの中のネストされたデータは、自動的にはキャッシュ更新されないので、自前でキャッシュ更新している
      cache.writeQuery({
        query: TaskDocument,
        variables: { id: result.data!.startTask!.startTask.id! },
        data: { task: result.data!.startTask!.startTask },
      });
      cache.writeQuery({
        query: LatestWorkingHistoryDocument,
        data: { latestWorkingHistory: result.data!.startTask!.startWorkingHistory },
      });
      
      if (result.data?.startTask?.stopTask) {
        cache.writeQuery({
          query: TaskDocument,
          variables: { id: result.data!.startTask!.stopTask!.id! },
          data: { task: result.data!.startTask!.stopTask },
        });
      }

      cache.modify({
        fields: {
          workingHistoriesSpecifyTermForCalendar(existing = [], { storeFieldName }) {
            const newWorkingHistories = cache.writeQuery({
              query: CalendarWorkingHistoryDocument,
              data: result.data?.startTask?.stopWorkingHistory,
            });
            const targetMemberData = existing.filter((d: any) => d.memberId === loginUser?.id)[0];
            if((targetMemberData?.length ?? 0) === 0){
              return [...existing, {
                memberId: loginUser?.id,
                workingHistories: newWorkingHistories
              }];
            }
            return existing.map((data: any) => {
              if(data.memberId !== loginUser?.id){
                return data;
              }
              return {
                memberId: data.memberId,
                workingHistories: [...targetMemberData.workingHistories, newWorkingHistories]
              }
            })
          },
        },
      });
    },
    onError: (error) => {
      setTracking(TaskUtil.isTracking(props.workingHistory.task!, loginUser!));
    },
    // refetchQueries: [
    //   // TODO なぜかteamが取得できない。サーバーサイドで削られている
    //   // {
    //   //   query: TaskWorkingHistorySummaryGroupByAssineeDocument,
    //   //   variables: {
    //   //     teamId: props.workingHistory.task!.project.team.id,
    //   //     taskId: props.workingHistory.task!.id!,
    //   //   },
    //   // },
    //   {
    //     query: TaskWorkingHistoriesDocument,
    //     variables: {
    //       taskId: props.workingHistory.task!.id!,
    //     },
    //   },
    // ],
  });
  const [stopTask, stopTaskResult] = useStopTaskMutation({
    update: (cache, result) => {
      // レスポンスの中のネストされたデータは、自動的にはキャッシュ更新されないので、自前でキャッシュ更新している
      cache.writeQuery({
        query: TaskDocument,
        variables: { id: result.data!.stopTask!.stopTask.id! },
        data: { task: result.data!.stopTask!.stopTask },
      });
    },
    onError: (error) => {
      setTracking(TaskUtil.isTracking(props.workingHistory.task!, loginUser!));
    },
    refetchQueries: [
      // TODO なぜかteamが取得できない。サーバーサイドで削られている
      // {
      //   query: TaskWorkingHistorySummaryGroupByAssineeDocument,
      //   variables: {
      //     teamId: props.workingHistory.task.project.team.id!,
      //     taskId: props.workingHistory.task.id!,
      //   },
      // },
      {
        query: LatestWorkingHistoryDocument,
      },
      {
        query: ProjectWorkingTimeSecDocument,
        variables: {
          id: props.workingHistory.task!.project.id,
        },
      },
    ],
  });

  return (
    <Container>
      <Main
        onPress={() => {
          if (
            mySettingsData!.mySettings!.projectInitialViewTypeCode ===
            ProjectInitialViewType.KanbanBoard
          ) {
            history.push(
              `/app/${loginUser!.organizationId}/${props.workingHistory!.task!.project.team
                .id!}/projects/${props.workingHistory.task!.project
                .id!}/board/task/${props.workingHistory!.task!.id!}/detail/`
            );
          } else if (
            mySettingsData!.mySettings!.projectInitialViewTypeCode ===
            ProjectInitialViewType.GanttChart
          ) {
            history.push(
              `/app/${loginUser!.organizationId}/${props.workingHistory!.task!.project.team
                .id!}/projects/${props.workingHistory.task!.project
                .id!}/schedule/task/${props.workingHistory!.task!.id!}/detail/`
            );
          } else {
            history.push(
              `/app/${loginUser!.organizationId}/${props.workingHistory!.task!.project.team
                .id!}/projects/${props.workingHistory.task!.project
                .id!}/list/task/${props.workingHistory!.task!.id!}/detail/`
            );
          }
        }}>
        {isTracking ? (
          <StopIcon
            size={38}
            containerStyle={{ marginRight: 10 }}
            disabled={startTaskResult.loading || stopTaskResult.loading}
            onPress={() => {
              if (isTracking !== TaskUtil.isTracking(props.workingHistory.task!, loginUser!)) {
                return;
              }
              setTracking(false);
              stopTask({
                variables: {
                  id: props.workingHistory.task!.id!,
                  input: {
                    versionNo: props.workingHistory.task!.versionNo,
                  },
                },
              });
            }}
          />
        ) : (
          <PlayIcon
            size={38}
            containerStyle={{ marginRight: 10 }}
            disabled={startTaskResult.loading || stopTaskResult.loading}
            onPress={() => {
              if (isTracking !== TaskUtil.isTracking(props.workingHistory.task!, loginUser!)) {
                return;
              }
              if (
                !!props.workingHistory.task!.completeDateTime ||
                props.workingHistory.task!.project.complete
              ) {
                setShowCompleteTaskStartErrorModal(true);
                return;
              }
              setTracking(true);
              startTask({
                variables: {
                  id: props.workingHistory.task!.id!,
                  input: {
                    versionNo: props.workingHistory.task!.versionNo,
                  },
                },
              });
            }}
          />
        )}
        <View style={{ flexDirection: 'column', flex: 1 }}>
          <View style={{ flexDirection: 'row' }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontSize: 12,
                minWidth: 30,
                textAlign: 'left',
                color: themeContext.colors.description,
                lineHeight: 14,
              }}
              ellipsis={true}>
              {props.workingHistory.task!.project.name}
            </Typography>
            {props.workingHistory.task!.project.client && (
              <Typography
                variant={TypographyType.Normal}
                style={{
                  fontSize: 12,
                  minWidth: 30,
                  textAlign: 'left',
                  color: themeContext.colors.description,
                  lineHeight: 14,
                  marginLeft: 5,
                }}
                ellipsis={true}>
                {`(${props.workingHistory.task!.project.client?.name})`}
              </Typography>
            )}
          </View>
          <Typography
            variant={TypographyType.Normal}
            style={{ fontSize: 16, minWidth: 30, textAlign: 'left', lineHeight: 20 }}
            ellipsis={true}>
            {props.workingHistory.task!.title}
          </Typography>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <View style={{ flexDirection: 'column', marginHorizontal: 10, minWidth: 70 }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontSize: 8,
                lineHeight: 16,
                textAlign: 'center',
                color: themeContext.colors.description,
              }}>
              今回の作業時間
            </Typography>
            <TaskCurrentWorkingTime
              workingHistory={props.workingHistory}
              style={{ fontSize: 16, textAlign: 'center', lineHeight: 22 }}
            />
          </View>
          <View style={{ flexDirection: 'column', marginHorizontal: 10, minWidth: 70 }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontSize: 8,
                lineHeight: 16,
                textAlign: 'center',
                color: themeContext.colors.description,
              }}>
              合計作業時間
            </Typography>
            <TaskWorkingTimeOnlyCountUpLoginUser
              task={props.workingHistory.task!}
              style={{ fontSize: 16, textAlign: 'center', lineHeight: 22 }}
            />
          </View>
          <View style={{ flexDirection: 'column', marginHorizontal: 10, minWidth: 70 }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontSize: 8,
                lineHeight: 16,
                textAlign: 'center',
                color: themeContext.colors.description,
              }}>
              見積時間
            </Typography>
            <Typography
              variant={TypographyType.Normal}
              style={{ fontSize: 16, textAlign: 'center', lineHeight: 22 }}>
              {TimeUtil.formatForTask(props.workingHistory.task!.estimateTimeSec)}
            </Typography>
          </View>
          <View style={{ flexDirection: 'column', marginHorizontal: 10, minWidth: 40 }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontSize: 8,
                lineHeight: 16,
                textAlign: 'center',
                color: themeContext.colors.description,
              }}>
              進捗率
            </Typography>
            <Typography
              variant={TypographyType.Normal}
              style={{ fontSize: 16, textAlign: 'center', lineHeight: 22 }}>
              {`${props.workingHistory.task!.progressRate}%`}
            </Typography>
          </View>
          <TouchableOpacity
            style={{
              justifyContent: 'center',
              backgroundColor: 'transparent',
              marginVertical: 3,
              marginLeft: 3,
              paddingVertical: 1,
              paddingHorizontal: 10,
              borderRadius: 5,
              borderWidth: 1,
              borderColor: themeContext.colors.link,
            }}
            onPress={(e) => {
              e.preventDefault();
              setShowUpdateModal(true);
            }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontSize: 8,
                lineHeight: 11,
                textAlign: 'center',
                color: themeContext.colors.link,
              }}>
              {`詳細を${'\n'}表示する`}
            </Typography>
          </TouchableOpacity>
        </View>
      </Main>
      <TaskProgressBar task={props.workingHistory.task!} />
      <UpdateWorkingHistoryModal
        workingHistory={props.workingHistory}
        showModal={showUpdateModal}
        onCloseModal={() => setShowUpdateModal(false)}
        me={props.me}
      />
      <ErrorMessageModal
        showModal={showCompleteTaskStartErrorModal}
        title={'作業開始することができません'}
        message={`完了しているタスク・完了しているプロジェクトは${'\n'}作業開始することが出来ません。`}
        onCloseModal={() => setShowCompleteTaskStartErrorModal(false)}
      />
    </Container>
  );
};

export default TaskSummaryForHeader;
