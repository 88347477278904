import React, { useContext, useState } from 'react';
import { View, Text } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import useDimensions from 'use-dimensions';
import {
  Plan,
  useMeQuery,
  useOrganizationQuery,
  useUnreadNotificationCountQuery,
} from '../../../../../graphql/api/API';
import when from '../../../../../lang-extention/When';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import Icon from '../../../../presentational/atoms/icon';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import { IStyleTheme, IThemePart } from '../../../../theme';
import RightSideBarCalendar from './calendar';
import RightSideBarMembersStatus from './members-status';
import RightSideBarWorkingHistory from './working-history';
import RightSideBarWorkingSchedule from './working-schedule';
import RightSideBarMentionList from './mention-list';
import { useHistory } from 'react-router';
import queryString from 'query-string';
import CloseIcon from '../../../../presentational/molecules/image-icon/close';
import CalendarIcon from '../../../../presentational/molecules/image-icon/calendar';
import WorkingHistoryIcon from '../../../../presentational/molecules/image-icon/working-history';
import WorkingScheduleIcon from '../../../../presentational/molecules/image-icon/working-schedule';
import PeopleIcon from '../../../../presentational/molecules/image-icon/people';
import InboxIcon from '../../../../presentational/molecules/image-icon/inbox';
import ToolTip from 'react-portal-tooltip';

interface IContainerProps extends IStyleTheme {
  windowWidth: number;
}

const Container = styled.View<IContainerProps>`
  position: ${(props: IContainerProps) => (props.windowWidth < 1260 ? 'absolute' : 'relative')};
  right: 0;
  display: flex;
  flex-direction: row;
  height: calc(100vh - 57px);
  background-color: ${(props: IContainerProps) => props.theme.colors.baseColor};
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.4s;
  z-index: 100;
`;

const MenuContainer = styled.View`
  display: flex;
  flex-direction: column;
  width: 50px;
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
  padding-top: 20px;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.4s;
  z-index: 100;
`;

interface IMainContainerProps extends IStyleTheme {
  isOpen: boolean;
}

const MainContainer = styled.View<IMainContainerProps>`
  display: flex;
  width: ${(props: IMainContainerProps) => (props.isOpen ? '600px' : '0')};
  background-color: ${(props: IMainContainerProps) => props.theme.colors.baseColor};
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.2s;
`;

const MainContainerInner = styled.View<IMainContainerProps>`
  display: flex;
  opacity: ${(props: IMainContainerProps) => (props.isOpen ? '1' : '0')};
  transition: all 0.4s;
  transition-delay: 0.2s;
`;

const Header = styled.View`
  display: flex;
  height: 35px;
  background-color: ${(props: IStyleTheme) => props.theme.colors.header};
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  align-item: center;
  justify-content: center;
  text-align: center;
  transition: all 0.2s;
`;

const MainArea = styled.ScrollView`
  display: flex;
  height: calc(100vh - 57px - 40px);
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
  transition: all 0.2s;
  scrollbar-width: none;
`;

enum RightWindowType {
  Calendar,
  History,
  Schedule,
  Member,
  Notification,
}

const RightSideBar: React.FC<{}> = (props: {}) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, _] = useContext(LoginUserContext);
  const [isOpen, setOpen] = useState(false);
  const [windowType, setWindowType] = useState<RightWindowType | null>(null);
  const { window: dimensionWindow } = useDimensions();
  const history = useHistory();
  const [hoveringMenu, setHoveringMenu] = useState<RightWindowType | null>(null);
  const windowWidth = dimensionWindow.width;

  const { data, loading, error } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId,
    },
  });

  const { loading: meLoading, data: meData } = useMeQuery();
  const { loading: loadingNotificationCount, data: dataNotificationCount } =
    useUnreadNotificationCountQuery();

  if (loading || !data?.organization || meLoading || loadingNotificationCount) {
    return <></>;
  }

  return (
    <Container windowWidth={windowWidth}>
      <MenuContainer>
        <>
          <div
            id={`tooltip-right-sidemenu-calendar`}
            onMouseEnter={() => setHoveringMenu(RightWindowType.Calendar)}
            onMouseLeave={() => setHoveringMenu(null)}>
            <CalendarIcon
              size={30}
              containerStyle={{
                marginBottom: 7,
                opacity: windowType === RightWindowType.Calendar || windowType === null ? 1 : 0.5,
              }}
              onPress={() => {
                setOpen(true);
                setWindowType(RightWindowType.Calendar);
                const search = queryString.parse(history.location.search);
                const newSearch = Object.assign(search, { right: 'calender' });
                history.push(history.location.pathname + '?' + queryString.stringify(newSearch));
              }}
            />
          </div>
          <ToolTip
            active={!isOpen && hoveringMenu === RightWindowType.Calendar}
            position="left"
            arrow="center"
            parent={`#tooltip-right-sidemenu-calendar`}>
            <Text>{'カレンダー'}</Text>
          </ToolTip>
        </>
        <>
          <div
            id={`tooltip-right-sidemenu-working-history`}
            onMouseEnter={() => setHoveringMenu(RightWindowType.History)}
            onMouseLeave={() => setHoveringMenu(null)}>
            <WorkingHistoryIcon
              size={30}
              containerStyle={
                {
                  marginBottom: 10,
                  opacity: windowType === RightWindowType.History || windowType === null ? 1 : 0.5,
                } as any
              }
              onPress={() => {
                setOpen(true);
                setWindowType(RightWindowType.History);
                const search = queryString.parse(history.location.search);
                const newSearch = Object.assign(search, { right: 'schedule' });
                history.push(history.location.pathname + '?' + queryString.stringify(newSearch));
              }}
            />
          </div>
          <ToolTip
            active={!isOpen && hoveringMenu === RightWindowType.History}
            position="left"
            arrow="center"
            parent={`#tooltip-right-sidemenu-working-history`}>
            <Text>{'作業履歴'}</Text>
          </ToolTip>
        </>
        <>
          <div
            id={`tooltip-right-sidemenu-working-schedule`}
            onMouseEnter={() => setHoveringMenu(RightWindowType.Schedule)}
            onMouseLeave={() => setHoveringMenu(null)}>
            <WorkingScheduleIcon
              size={30}
              containerStyle={
                {
                  marginBottom: 6,
                  opacity: windowType === RightWindowType.Schedule || windowType === null ? 1 : 0.5,
                } as any
              }
              onPress={() => {
                setOpen(true);
                setWindowType(RightWindowType.Schedule);
                const search = queryString.parse(history.location.search);
                const newSearch = Object.assign(search, { right: 'schedule' });
                history.push(history.location.pathname + '?' + queryString.stringify(newSearch));
              }}
            />
          </div>
          <ToolTip
            active={!isOpen && hoveringMenu === RightWindowType.Schedule}
            position="left"
            arrow="center"
            parent={`#tooltip-right-sidemenu-working-schedule`}>
            <Text>{'作業予定'}</Text>
          </ToolTip>
        </>
        {(data!.organization!.plan.code === Plan.Business || // チーム利用のプランであり、かつ、チームメンバーか組織メンバーの状況閲覧権限を持っている場合のみ、
          data!.organization!.plan.code === Plan.Enterprise) && // 右サイドバーのメンバー状況メニューを表示する
          (meData!.me!.teamMemberViewRole || meData!.me!.organizationMemberViewRole) && (
            <>
              <div
                id={`tooltip-right-sidemenu-members`}
                onMouseEnter={() => setHoveringMenu(RightWindowType.Member)}
                onMouseLeave={() => setHoveringMenu(null)}>
                <PeopleIcon
                  size={30}
                  containerStyle={
                    {
                      marginBottom: 1,
                      opacity:
                        windowType === RightWindowType.Member || windowType === null ? 1 : 0.5,
                    } as any
                  }
                  onPress={() => {
                    setOpen(true);
                    setWindowType(RightWindowType.Member);
                    const search = queryString.parse(history.location.search);
                    const newSearch = Object.assign(search, { right: 'member' });
                    history.push(
                      history.location.pathname + '?' + queryString.stringify(newSearch)
                    );
                  }}
                />
              </div>
              <ToolTip
                active={!isOpen && hoveringMenu === RightWindowType.Member}
                position="left"
                arrow="center"
                parent={`#tooltip-right-sidemenu-members`}>
                <Text>{'メンバーの状況'}</Text>
              </ToolTip>
            </>
          )}
        {(data!.organization!.plan.code === Plan.Business || // チーム利用のプランのみ、
          data!.organization!.plan.code === Plan.Enterprise) && ( // 右サイドバーのメンションメニューを表示する
          <>
            <div
              id={`tooltip-right-sidemenu-notifications`}
              onMouseEnter={() => setHoveringMenu(RightWindowType.Notification)}
              onMouseLeave={() => setHoveringMenu(null)}>
              <View>
                <InboxIcon
                  size={30}
                  containerStyle={
                    {
                      marginBottom: 10,
                      opacity:
                        windowType === RightWindowType.Notification || windowType === null
                          ? 1
                          : 0.5,
                    } as any
                  }
                  onPress={() => {
                    setOpen(true);
                    setWindowType(RightWindowType.Notification);
                    const search = queryString.parse(history.location.search);
                    const newSearch = Object.assign(search, { right: 'notification' });
                    history.push(
                      history.location.pathname + '?' + queryString.stringify(newSearch)
                    );
                  }}
                />
                {(dataNotificationCount?.unreadNotificationCount || 0) > 0 && (
                  <Typography
                    variant={TypographyType.Normal}
                    ellipsis={true}
                    style={
                      {
                        position: 'absolute',
                        right: 3,
                        top: 0,
                        color: '#EFEFEF',
                        fontSize: 14,
                        backgroundColor: '#c45e5e',
                        textAlign: 'center',
                        textAlignVertical: 'center',
                        width: 24,
                        height: 24,
                        borderRadius: 12,
                        pointerEvents: 'none',
                      } as any
                    }>
                    {(dataNotificationCount!.unreadNotificationCount || 0) >= 10
                      ? '+9'
                      : dataNotificationCount!.unreadNotificationCount}
                  </Typography>
                )}
              </View>
            </div>
            <ToolTip
              active={!isOpen && hoveringMenu === RightWindowType.Notification}
              position="left"
              arrow="center"
              parent={`#tooltip-right-sidemenu-notifications`}>
              <Text>{'あなたへのお知らせ'}</Text>
            </ToolTip>
          </>
        )}
      </MenuContainer>
      <MainContainer isOpen={isOpen}>
        <MainContainerInner isOpen={isOpen}>
          <Header>
            <Typography variant={TypographyType.SubTitle} style={{ color: '#FFFFFF' }}>
              {when(windowType)
                .on(
                  (t) => t === RightWindowType.Calendar,
                  () => 'カレンダー'
                )
                .on(
                  (t) => t === RightWindowType.History,
                  () => '作業履歴'
                )
                .on(
                  (t) => t === RightWindowType.Schedule,
                  () => '作業予定'
                )
                .on(
                  (t) => t === RightWindowType.Member,
                  () => 'チームメンバーの状況'
                )
                .on(
                  (t) => t === RightWindowType.Notification,
                  () => 'あなたへの通知'
                )
                .otherwise(() => '')}
            </Typography>
            <CloseIcon
              size={18}
              reverse={true}
              containerStyle={{ position: 'absolute', right: 10 }}
              onPress={() => {
                setOpen(false);
                setWindowType(null);
                const search = queryString.parse(history.location.search);
                const newSearch = Object.assign(search, { right: 'notification' });
                delete (newSearch as any).right;
                history.push(history.location.pathname + '?' + queryString.stringify(newSearch));
              }}
            />
          </Header>

          <MainArea>
            {when(windowType)
              .on(
                (t) => t === RightWindowType.Calendar,
                () => <RightSideBarCalendar />
              )
              .on(
                (t) => t === RightWindowType.History,
                () => <RightSideBarWorkingHistory />
              )
              .on(
                (t) => t === RightWindowType.Schedule,
                () => <RightSideBarWorkingSchedule />
              )
              .on(
                (t) => t === RightWindowType.Member,
                () => <RightSideBarMembersStatus />
              )
              .on(
                (t) => t === RightWindowType.Notification,
                () => <RightSideBarMentionList />
              )
              .otherwise(() => (
                <></>
              ))}
          </MainArea>
        </MainContainerInner>
      </MainContainer>
    </Container>
  );
};

export default React.memo(RightSideBar);
