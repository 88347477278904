import React, { ReactNode, useContext, useState } from 'react';
import { StyleProp, TouchableOpacity, View, ViewStyle } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import Modal from '../../../../presentational/molecules/modal';
import { IThemePart } from '../../../../theme';

const ElementContainer = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 30px;
`;

const Element = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

const ElementTitle = styled.View`
  min-width: 180px;
`;

const ElementContent = styled.View``;

interface IProps {
  title?: string;
  titleWidth?: number;
  changeText?: string;
  beforeChangeText?: ReactNode;
  modalTitle?: string;
  modal?: ReactNode | ((closeModal: () => void) => ReactNode);
  children: ReactNode;
  containerStyle?: StyleProp<ViewStyle>;
  disabled?: boolean;
}

const SettingsElement = (props: IProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [isOpenDialog, setOpenDialog] = useState(false);
  return (
    <ElementContainer style={props.containerStyle}>
      <Element>
        <ElementTitle style={props.titleWidth ? { minWidth: props.titleWidth } : {}}>
          {props.title && (
            <Typography variant={TypographyType.ElementiTitle}>{props.title}</Typography>
          )}
        </ElementTitle>
        <ElementContent>{props.children}</ElementContent>
      </Element>
      {props.changeText && (
        <Element>
          <ElementTitle
            style={props.titleWidth ? { minWidth: props.titleWidth } : {}}></ElementTitle>
          <ElementContent>
            <TouchableOpacity
              disabled={props.disabled}
              onPress={() => setOpenDialog(true)}
              style={
                { paddingVertical: 5, cursor: props.disabled ? 'not-allowed' : 'pointer' } as any
              }>
              <View style={{ flexDirection: 'row' }}>
                {props.beforeChangeText && props.beforeChangeText}
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    color: props.disabled
                      ? themeContext.colors.description
                      : themeContext.colors.link,
                  }}>
                  {props.changeText}
                </Typography>
              </View>
            </TouchableOpacity>
          </ElementContent>
        </Element>
      )}
      {props.modal && (
        <Modal
          title={props.modalTitle || props.changeText}
          isShow={isOpenDialog}
          onClose={() => {
            setOpenDialog(false);
          }}>
          {typeof props.modal === 'function'
            ? props.modal(() => {
                setOpenDialog(false);
              })
            : props.modal}
        </Modal>
      )}
    </ElementContainer>
  );
};

export default SettingsElement;
