import React, { ReactElement, useContext, useRef } from 'react';
import {
  Animated,
  NativeSyntheticEvent,
  TouchableOpacity,
  NativeScrollEvent,
  LayoutChangeEvent,
  Platform,
  ScrollView,
  ScrollViewProps,
  View,
} from 'react-native';
import { PanGestureHandler, State } from 'react-native-gesture-handler';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../theme';
import { throttle } from 'lodash';

export class NonForwardedRefAnimatedView extends React.Component<any> {
  render() {
    return (
      <Animated.View style={this.props.style} {...this.props}>
        {this.props.children}
      </Animated.View>
    );
  }
}

interface IWebScrollViewProps extends ScrollViewProps {
  scrollViewRef?: any;
  additionalOnScroll?: (event: NativeSyntheticEvent<NativeScrollEvent>) => void;
  children: React.ReactNode;
  forceHiddenScrollIndicator?: boolean;
  onEndReached?: () => void;
}

const WebScrollView = (props: IWebScrollViewProps) => {
  const ref = props.scrollViewRef || useRef();
  const [indicator, setIndicator] = React.useState(new Animated.Value(0));
  const [contentSize, setContentSize] = React.useState(0);
  const [scrollViewHeight, setScrollViewHeight] = React.useState(0);
  const [scrollViewTop, setScrollViewTop] = React.useState(0);
  const [scrollInitialPos, setScrollInitialPos] = React.useState(0);
  const indicatorSize =
    contentSize > scrollViewHeight ? (scrollViewHeight * scrollViewHeight) / contentSize : 0;
  const difference = scrollViewHeight > indicatorSize ? scrollViewHeight - indicatorSize : 1;
  const scrollHeight = contentSize - scrollViewHeight;

  const onEndReached = props.onEndReached ? throttle(props.onEndReached, 2000) : () => {};

  return (
    <>
      <ScrollView
        ref={ref as any}
        onScroll={(event: NativeSyntheticEvent<NativeScrollEvent>) => {
          if (props.horizontal) {
            Animated.timing(indicator, {
              toValue: event.nativeEvent.contentOffset.x,
              duration: 0,
              useNativeDriver: true,
            }).start();
            if (props.additionalOnScroll) {
              props.additionalOnScroll(event);
            }
          } else {
            Animated.timing(indicator, {
              toValue: event.nativeEvent.contentOffset.y,
              duration: 0,
              useNativeDriver: true,
            }).start();
            if (props.additionalOnScroll) {
              props.additionalOnScroll(event);
            }
            if (scrollHeight * 0.9 < event.nativeEvent.contentOffset.y) {
              onEndReached();
            }
          }
        }}
        onContentSizeChange={(w: number, h: number) => {
          if (props.horizontal) {
            setContentSize(w);
          } else {
            setContentSize(h);
          }
        }}
        onLayout={(event: LayoutChangeEvent) => {
          if (props.horizontal) {
            setScrollViewTop(event.nativeEvent.layout.x);
            setScrollViewHeight(event.nativeEvent.layout.width);
          } else {
            setScrollViewTop(event.nativeEvent.layout.y);
            setScrollViewHeight(event.nativeEvent.layout.height);
          }
        }}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        scrollEventThrottle={5}
        {...props}>
        {props.children}
      </ScrollView>
      {!props.forceHiddenScrollIndicator && indicatorSize > 0 && (
        <TouchableOpacity
          activeOpacity={1}
          onPress={(event) => {
            event.preventDefault();
            if (props.horizontal) {
              const clickPos = (contentSize / scrollViewHeight) * (event.nativeEvent as any).layerX;

              if ((indicator as any)?.__getValue() > clickPos) {
                // スクロールバーのバーよりも上側の枠をクリックした場合には、少し上にスクロールさせる
                (ref.current as any).scrollTo({
                  x: (indicator as any).__getValue() - 50,
                  y: 0,
                  animated: false,
                });
              } else if (
                (indicator as any).__getValue() + indicatorSize * (contentSize / scrollViewHeight) <
                clickPos
              ) {
                // スクロールバーのバーよりも下側の枠をクリックした場合には、少し下にスクロールさせる
                (ref.current as any).scrollTo({
                  x: (indicator as any).__getValue() + 50,
                  y: 0,
                  animated: false,
                });
              }
            } else {
              const clickPos = (contentSize / scrollViewHeight) * (event.nativeEvent as any).layerY;
              if ((indicator as any)?.__getValue() > clickPos) {
                // スクロールバーのバーよりも上側の枠をクリックした場合には、少し上にスクロールさせる
                (ref.current as any).scrollTo({
                  y: (indicator as any).__getValue() - 50,
                  x: 0,
                  animated: false,
                });
              } else if (
                (indicator as any).__getValue() + indicatorSize * (contentSize / scrollViewHeight) <
                clickPos
              ) {
                // スクロールバーのバーよりも下側の枠をクリックした場合には、少し下にスクロールさせる
                (ref.current as any).scrollTo({
                  y: (indicator as any).__getValue() + 50,
                  x: 0,
                  animated: false,
                });
              }
            }
          }}
          style={
            props.horizontal
              ? ({
                  position: 'absolute',
                  bottom: 0,
                  left: scrollViewTop,
                  height: 10,
                  width: `calc(100% - ${scrollViewTop}px)`,
                  backgroundColor: '#eeeeee',
                  zIndex: 1,
                  overflow: 'hidden',
                  borderRadius: 4,
                  cursor: 'grab',
                  opacity: 0.5,
                } as any)
              : ({
                  position: 'absolute',
                  right: 0,
                  top: scrollViewTop,
                  width: 10,
                  height: `calc(100% - ${scrollViewTop}px)`,
                  backgroundColor: '#eeeeee',
                  zIndex: 1,
                  overflow: 'hidden',
                  borderRadius: 4,
                  cursor: 'grab',
                  opacity: 0.5,
                } as any)
          }>
          <PanGestureHandler
            maxPointers={1}
            onGestureEvent={(event) => {
              if (event.nativeEvent.state === State.BEGAN) {
                if ((ref.current as any).getNativeScrollRef()) {
                  if (props.horizontal) {
                    setScrollInitialPos((ref.current as any).getNativeScrollRef().scrollLeft);
                  } else {
                    setScrollInitialPos((ref.current as any).getNativeScrollRef().scrollTop);
                  }
                }
              }
              if (event.nativeEvent.state === State.ACTIVE) {
                if (props.horizontal) {
                  (ref.current as any).getNativeScrollRef().scrollLeft =
                    scrollInitialPos +
                    event.nativeEvent.translationX * (contentSize / scrollViewHeight);
                } else {
                  (ref.current as any).getNativeScrollRef().scrollTop =
                    scrollInitialPos +
                    event.nativeEvent.translationY * (contentSize / scrollViewHeight);
                }
              }
              if (event.nativeEvent.state === State.END) {
              }
            }}>
            <NonForwardedRefAnimatedView
              style={[
                { backgroundColor: '#cccccc', borderRadius: 4, opacity: 0.8 },
                props.horizontal
                  ? {
                      width: indicatorSize,
                      height: 10,
                      transform: [
                        {
                          translateX: Animated.multiply(
                            indicator,
                            scrollViewHeight / contentSize
                          ).interpolate({
                            inputRange: [0, difference],
                            outputRange: [0, difference],
                            extrapolate: 'clamp',
                          }),
                        },
                      ],
                    }
                  : {
                      height: indicatorSize,
                      transform: [
                        {
                          translateY: Animated.multiply(
                            indicator,
                            scrollViewHeight / contentSize
                          ).interpolate({
                            inputRange: [0, difference],
                            outputRange: [0, difference],
                            extrapolate: 'clamp',
                          }),
                        },
                      ],
                    },
              ]}
            />
          </PanGestureHandler>
        </TouchableOpacity>
      )}
    </>
  );
};

interface ICustomScrollViewProps extends ScrollViewProps {
  scrollViewRef?: any;
  children: React.ReactNode;
  additionalOnScroll?: (event: NativeSyntheticEvent<NativeScrollEvent>) => void;
  forceHiddenScrollIndicator?: boolean;
  onEndReached?: () => void;
}

const CustomScrollView = (props: ICustomScrollViewProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  if (Platform.OS === 'web') {
    return (
      <WebScrollView
        scrollViewRef={props.scrollViewRef}
        additionalOnScroll={props.additionalOnScroll}
        forceHiddenScrollIndicator={props.forceHiddenScrollIndicator}
        onEndReached={props.onEndReached}
        {...props}>
        {props.children}
      </WebScrollView>
    );
  }
  return <ScrollView {...props}>{props.children}</ScrollView>;
};

export default React.memo(CustomScrollView);
