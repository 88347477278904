export default class EnumUtil {
  private constructor() {}

  public static getEnumValue(enumType: any, value: string | null | undefined): any | null {
    if (!value) {
      return null;
    }
    return Object.values(enumType as any).includes(value) ? value : null;
  }
}
