import React, { useContext, useState } from 'react';
import { Platform, TouchableOpacity } from 'react-native';
import Button from '../../../../presentational/atoms/button';
import Form from '../../../../presentational/atoms/form';
import Input from '../../../../presentational/atoms/input';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import Modal from '../../../../presentational/molecules/modal';
import { IStyleTheme, IThemePart } from '../../../../theme';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import {
  useTwoFactorLoginMutation,
  useTwoFactorRecoveryCodeLoginMutation,
} from '../../../../../graphql/api/API';

const ErrorMessage = styled.Text<IStyleTheme>`
  font-size: ${() => (Platform.OS === 'web' ? '0.9rem' : '18px')};
  color: ${(props: any) => props.theme.colors.error};
  text-align: left;
  padding-bottom: 20px;
`;

interface Props {
  show: boolean;
  onClose: () => void;
  oneTimeToken: string;
  onSuccess: (token: string) => Promise<void>;
}

const TwoFactorLoginModal = (props: Props) => {
  const [type, setType] = useState<'code' | 'recoveryCode'>('code');

  const [twoFactorAuthCode, setTwoFactorAuthCode] = useState('');
  const [twoFactorRecoveryCode, setTwoFactorRecoveryCode] = useState('');
  const [showTwoFactorLoginErrorMessage, setShowTwoFactorLoginErrorMessage] = useState(false);
  const [
    showTwoFactorRecoveryCodeLoginErrorMessage,
    setShowTwoFactorRecoveryCodeLoginErrorMessage,
  ] = useState(false);
  const [showOneTimeTokenExpiredErrorMessage, setShowOneTimeTokenExpiredErrorMessage] =
    useState(false);
  const themeContext: IThemePart = useContext(ThemeContext);

  // どうしてもキーボードに合わせて表示が崩れるので、heightを固定している
  const getModalHeight = () => {
    if (type === 'code') {
      if (showOneTimeTokenExpiredErrorMessage) {
        return 420;
      } else if (showTwoFactorLoginErrorMessage) {
        return 380;
      } else {
        return 380;
      }
    } else {
      return 'auto';
    }
  };

  const resetErrorMessages = () => {
    setShowOneTimeTokenExpiredErrorMessage(false);
    setShowTwoFactorLoginErrorMessage(false);
    setShowTwoFactorRecoveryCodeLoginErrorMessage(false);
  };

  const onTwoFactorCodeInput = (code: string) => {
    resetErrorMessages();
    setTwoFactorAuthCode(code);
  };

  const onRecoveryCodeInput = (code: string) => {
    resetErrorMessages();
    setTwoFactorRecoveryCode(code);
  };

  const [requestTwoFactorLogin] = useTwoFactorLoginMutation({
    onError: (e) => {
      if (e.graphQLErrors.find((ge) => ge.extensions?.code === 'authentication-failed')) {
        setShowTwoFactorLoginErrorMessage(true);
      }
      if (
        e.graphQLErrors.find(
          (ge) => ge.extensions?.code === 'two-factor-auth-one-time-token-expired'
        )
      ) {
        setShowOneTimeTokenExpiredErrorMessage(true);
      }
    },
  });
  const [requestTwoFactorRecoveryCodeLogin] = useTwoFactorRecoveryCodeLoginMutation({
    onError: (e) => {
      if (e.graphQLErrors.find((ge) => ge.extensions?.code === 'authentication-failed')) {
        setShowTwoFactorRecoveryCodeLoginErrorMessage(true);
      }
      if (
        e.graphQLErrors.find(
          (ge) => ge.extensions?.code === 'two-factor-auth-one-time-token-expired'
        )
      ) {
        setShowOneTimeTokenExpiredErrorMessage(true);
      }
    },
  });

  return (
    <Modal
      title={type === 'code' ? '2要素認証' : 'バックアップコードを使用してログインする'}
      isShow={props.show}
      onClose={() => {
        resetErrorMessages();
        setType('code');
        props.onClose();
      }}
      avoidKeyboard={true}
      containerStyle={{
        height: getModalHeight(),
      }}>
      {type === 'code' && (
        <>
          <Typography variant={TypographyType.Normal}>2要素認証が有効になっています。</Typography>
          <Typography variant={TypographyType.Normal}>
            アプリに表示された6桁の数字を入力してください。
          </Typography>
          <TouchableOpacity onPress={() => setType('recoveryCode')} style={{ marginVertical: 4 }}>
            <Typography
              variant={TypographyType.Description}
              style={{
                color: themeContext.colors.link,
              }}>
              バックアップコードを使用してログインする
            </Typography>
          </TouchableOpacity>
          <Form
            style={{
              marginVertical: 10,
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <Input
              name={'twoFactorAuthLogin'}
              type={'text'}
              label={'6桁の数字'}
              onChange={onTwoFactorCodeInput}
              focus
              validate={{
                required: {
                  value: true,
                  message: '6桁の数字を入力してください',
                },
                pattern: {
                  value: /^[0-9]{6}$/,
                  message: '6桁の数字を入力してください',
                },
              }}
            />
            {showTwoFactorLoginErrorMessage && (
              <ErrorMessage>認証コードが間違っています。</ErrorMessage>
            )}
            {showOneTimeTokenExpiredErrorMessage && (
              <ErrorMessage>
                ログインできませんでした。{'\n'}お手数ですが最初からやり直してください。
              </ErrorMessage>
            )}
            <Button
              text="ログインする"
              style={{ paddingVertical: 10, width: 200 }}
              onPress={async () => {
                resetErrorMessages();
                const result = await requestTwoFactorLogin({
                  variables: {
                    input: {
                      oneTimeToken: props.oneTimeToken,
                      code: twoFactorAuthCode,
                    },
                  },
                });
                if (!!result?.data?.twoFactorLogin) {
                  await props.onSuccess(result.data!.twoFactorLogin!);
                }
              }}
            />
          </Form>
        </>
      )}
      {type === 'recoveryCode' && (
        <>
          <Form
            style={{
              marginVertical: 10,
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <Input
              name={'twoFactorRecoveryCodeLogin'}
              type={'text'}
              label={'バックアップコード'}
              onChange={onRecoveryCodeInput}
              focus
            />
            {showTwoFactorRecoveryCodeLoginErrorMessage && (
              <ErrorMessage>入力されたコードは間違っているかすでに使用済です。</ErrorMessage>
            )}
            {showOneTimeTokenExpiredErrorMessage && (
              <ErrorMessage>
                ログインできませんでした。{'\n'}お手数ですが最初からやり直してください。
              </ErrorMessage>
            )}
            <Button
              text="ログインする"
              style={{ marginTop: 10, width: 200 }}
              onPress={async () => {
                resetErrorMessages();
                const result = await requestTwoFactorRecoveryCodeLogin({
                  variables: {
                    input: {
                      oneTimeToken: props.oneTimeToken,
                      recoveryCode: twoFactorRecoveryCode,
                    },
                  },
                });
                if (!!result?.data?.twoFactorRecoveryCodeLogin) {
                  await props.onSuccess(result.data!.twoFactorRecoveryCodeLogin!);
                }
              }}
            />
          </Form>
        </>
      )}
    </Modal>
  );
};

export default TwoFactorLoginModal;
