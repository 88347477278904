import Clipboard from '@react-native-clipboard/clipboard';
import moment from 'moment-timezone';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Image, TouchableOpacity, View } from 'react-native';
import { Redirect, Route, Switch as RouterSwitch, useHistory, useParams } from 'react-router';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import useDimensions from 'use-dimensions';
import noData01 from '../../../../../../base64Images/no-data/no-data-1';
import noData03 from '../../../../../../base64Images/no-data/no-data-3';
import {
  type LaborCost,
  LaborCostsDocument,
  type Member,
  type MemberInvitation,
  type MemberInvitationLink,
  MemberInvitationLinksDocument,
  MemberInvitationsDocument,
  MemberStatus,
  type Organization,
  OrganizationMembersDocument,
  TwoFactorAuthEnabledDocument,
  useContractQuery,
  useCreateInvitationLinkMutation,
  useCreateLaborCostMutation,
  useDeactivateMemberTwoFactorAuthMutation,
  useDeleteInvitationLinkMutation,
  useDeleteInvitationMutation,
  useDeleteLaborCostMutation,
  useExistAdminMemberQuery,
  useInviteMemberMutation,
  useJoinedTeamsWithoutPersonalTeamQuery,
  useLaborCostsQuery,
  useMailAddressAvailableLazyQuery,
  useMeQuery,
  useMemberInvitationLinkQuery,
  useMemberInvitationLinksQuery,
  useMemberInvitationQuery,
  useMemberInvitationsQuery,
  useMemberQuery,
  useOrganizationMembersQuery,
  useOrganizationQuery,
  useResendInvitationMutation,
  useTwoFactorAuthEnabledQuery,
  useUpdateInvitationLinkMutation,
  useUpdateInvitationMutation,
  useUpdateLaborCostMutation,
  useUpdateMemberProfileMutation,
  useUpdateMemberRoleMutation,
  useUpdateToActiveMutation,
  useUpdateToInactiveMutation,
} from '../../../../../../graphql/api/API';
import { LoginUserContext } from '../../../../../../modules/auth/LoginUserContext';
import ColorUtil from '../../../../../../util/ColorUtil';
import Avatar from '../../../../../presentational/atoms/avatar';
import Button from '../../../../../presentational/atoms/button';
import Checkbox from '../../../../../presentational/atoms/checkbox';
import CustomScrollView from '../../../../../presentational/atoms/custom-scroll-view';
import EditableText from '../../../../../presentational/atoms/editable-text';
import Form from '../../../../../presentational/atoms/form';
import Icon from '../../../../../presentational/atoms/icon';
import Input, { type ListValueMap } from '../../../../../presentational/atoms/input';
import Link from '../../../../../presentational/atoms/link';
import VirtualizedFlatList, {
  GlobalDragContextProvider,
} from '../../../../../presentational/atoms/list2/virtualized-flat-list';
import MultiSelect from '../../../../../presentational/atoms/multiselect';
import Switch from '../../../../../presentational/atoms/switch';
import TextList from '../../../../../presentational/atoms/text-list';
import Typography, { TypographyType } from '../../../../../presentational/atoms/typography';
import CaretDownIcon from '../../../../../presentational/molecules/image-icon/caret-down';
import CaretUpIcon from '../../../../../presentational/molecules/image-icon/caret-up';
import LinkIcon from '../../../../../presentational/molecules/image-icon/link';
import OpenLinkIcon from '../../../../../presentational/molecules/image-icon/open-in-new';
import PlusIcon from '../../../../../presentational/molecules/image-icon/plus';
import SearchIcon from '../../../../../presentational/molecules/image-icon/search';
import Modal from '../../../../../presentational/molecules/modal';
import type { IStyleTheme, IThemePart } from '../../../../../theme';
import HorizontalMenu from '../../../organisms/horizontal-menu';
import ResizableColumn from '../../../organisms/resizable-column';
import SettingsElement from '../../../organisms/settings-element';

const Container = styled.View`
  height: calc(100vh - 57px);
  display: flex;
  flex-direction: row;
`;

const MainAreaContainer = styled.View`
  display: flex;
  flex: 1;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.4s;
  z-index: 100;
  height: calc(100vh);
`;

const MainAreaHeader = styled.View`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: ${(props: IStyleTheme) => props.theme.colors.subHeader};
  padding: 5px 10px;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.4s;
  z-index: 2;
  height: 42px;
`;

const MemberItem = styled.View`
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  border-bottom-width: 1px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
`;

const MemberAddButton = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  padding-right: 5px;
  padding-left: 5px;
`;

interface IPermissionSearchAreaProps extends IStyleTheme {
  show: boolean;
}

const PermissionSearchArea = styled.View<IPermissionSearchAreaProps>`
  display: flex;
  transition: all 0.4s;
  height: ${(props: IPermissionSearchAreaProps) => (props.show ? 'auto' : '0')};
  overflow: hidden;
`;

interface IStatusSearchAreaProps extends IStyleTheme {
  show: boolean;
}

const StatusSearchArea = styled.View<IStatusSearchAreaProps>`
  display: flex;
  transition: all 0.4s;
  height: ${(props: IStatusSearchAreaProps) => (props.show ? 'auto' : '0')};
  overflow: hidden;
`;

const TwoFactorAuthSearchArea = styled.View<IStatusSearchAreaProps>`
  display: flex;
  transition: all 0.4s;
  height: ${(props: IStatusSearchAreaProps) => (props.show ? 'auto' : '0')};
  overflow: hidden;
`;

const LaborCostAddButton = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  padding-right: 5px;
  padding-left: 5px;
`;

interface ILicenceLimitModalProps {
  closeModal: () => void;
}

const LicenceLimitModal = (props: ILicenceLimitModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  return (
    <Form style={{ marginTop: 10 }}>
      <View style={{ justifyContent: 'center', zIndex: 1 }}>
        <View>
          <Typography
            variant={TypographyType.Normal}
            style={{
              fontSize: 16,
            }}>
            {`ライセンス数が上限に達しています。${'\n\n'}新しくメンバーを招待するには、ライセンスを追加するか、${'\n'}メンバーを無効に変更してライセンス数の枠を空けてください。${'\n\n'}ライセンス数を追加するには${'\n'}「各種設定」→「ご契約内容」画面から追加できます。`}
          </Typography>
          <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 20 }}>
            <OpenLinkIcon size={24} containerStyle={{ marginRight: 5 }} />
            <Link
              path={
                'https://manual.timedesigner.com/v1/-147'
              }
              isExternalLink={true}>
              <Typography
                variant={TypographyType.Normal}
                style={{ fontSize: 16, color: themeContext.colors.link }}>
                {`ライセンス数の確認・変更方法について確認する`}
              </Typography>
            </Link>
          </View>
        </View>
        <Button
          text={'閉じる'}
          style={{
            minWidth: 100,
            marginRight: 10,
            backgroundColor: 'transparent',
          }}
          textStyle={{ color: themeContext.colors.primary }}
          disableValidate={true}
          onPress={async () => {
            props.closeModal();
          }}
        />
      </View>
    </Form>
  );
};

interface IRegisterMemberDialogProps {
  organization: Organization;
  members: Member[];
  invitations: MemberInvitation[];
  onComplete: (invitation: MemberInvitation) => void;
  onCancel: () => void;
}

const RegiserMemberDialog = (props: IRegisterMemberDialogProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const history = useHistory();
  const [loginUser, __] = useContext(LoginUserContext);
  const [mailAddress, setMailAddress] = useState('');
  const [name, setName] = useState('');
  const [department, setDepartment] = useState('');
  const [employeeNumber, setEmployeeNumber] = useState('');
  const [teamIds, setTeamIds] = useState<string[]>([]);
  const [isAdminRole, setAdminRole] = useState(false);
  const [isLaborCostManagementRole, setLaborCostManagementRole] = useState(false);
  const [isSalesManagementRole, setSalesManagementRole] = useState(false);
  const [isMemberManagementRole, setMemberManagementRole] = useState(false);
  const [isClientManagementRole, setClientManagementRole] = useState(false);
  const [isProxyEditWorkDataRole, setProxyEditWorkDataRole] = useState(false);
  const [isOrganizationReportRole, setOrganizationReportRole] = useState(true);
  const [isTeamReportRole, setTeamReportRole] = useState(true);
  const [isOrganizationMemberViewRole, setOrganizationMemberViewRole] = useState(true);
  const [isTeamMemberViewRole, setTeamMemberViewRole] = useState(true);
  const [isProjectCreatePermissionFlg, setProjectCreatePermissionFlg] = useState(true);
  const [isProjectUpdatePermissionFlg, setProjectUpdatePermissionFlg] = useState(true);
  const [isProjectDeletePermissionFlg, setProjectDeletePermissionFlg] = useState(true);
  const [isProjectCustomAttributePermissionFlg, setProjectCustomAttributePermissionFlg] =
    useState(false);
  const [isTaskCreatePermissionFlg, setTaskCreatePermissionFlg] = useState(true);
  const [isTaskUpdatePermissionFlg, setTaskUpdatePermissionFlg] = useState(true);
  const [isTaskDeletePermissionFlg, setTaskDeletePermissionFlg] = useState(true);
  const [isTaskCustomAttributePermissionFlg, setTaskCustomAttributePermissionFlg] = useState(false);
  const { loading, data, error } = useJoinedTeamsWithoutPersonalTeamQuery({
    variables: {
      organizationId: loginUser!.organizationId,
      withArchivedTeam: false,
    },
  });
  const [inviteMember, _] = useInviteMemberMutation({
    variables: {
      input: {
        mailAddress: mailAddress,
        name: name,
        department: department,
        employeeNumber: employeeNumber,
        teamIds: teamIds,
        adminRole: isAdminRole,
        laborCostManagementRole: isLaborCostManagementRole,
        salesManagementRole: isSalesManagementRole,
        memberManagementRole: isMemberManagementRole,
        clientManagementRole: isClientManagementRole,
        proxyEditWorkingDataRole: isProxyEditWorkDataRole,
        organizationReportRole: isOrganizationReportRole,
        teamReportRole: isTeamReportRole,
        organizationMemberViewRole: isOrganizationMemberViewRole,
        teamMemberViewRole: isTeamMemberViewRole,
        projectCreatePermissionFlg: isProjectCreatePermissionFlg,
        projectUpdatePermissionFlg: isProjectUpdatePermissionFlg,
        projectDeletePermissionFlg: isProjectDeletePermissionFlg,
        projectCustomAttributePermissionFlg: isProjectCustomAttributePermissionFlg,
        taskCreatePermissionFlg: isTaskCreatePermissionFlg,
        taskUpdatePermissionFlg: isTaskUpdatePermissionFlg,
        taskDeletePermissionFlg: isTaskDeletePermissionFlg,
        taskCustomAttributePermissionFlg: isTaskCustomAttributePermissionFlg,
      },
    },
    refetchQueries: [
      {
        query: OrganizationMembersDocument,
        variables: {
          organizationId: props.organization.id!,
        },
      },
      {
        query: MemberInvitationsDocument,
      },
    ],
  });
  const [mailAddressAvailableQuery, { data: mailAddressAvailableResult }] =
    useMailAddressAvailableLazyQuery({
      fetchPolicy: 'network-only',
    });

  const { loading: meLoading, data: meData } = useMeQuery({
    fetchPolicy: 'network-only',
  });

  if (loading || meLoading) {
    return <></>;
  }

  return (
    <CustomScrollView style={{ maxHeight: window.innerHeight - 100 }}>
      <Form>
        <View style={{ minWidth: 500, marginTop: 10 }}>
          <Input
            name={'mailAddress'}
            label={'招待するメールアドレス'}
            autoFocus
            onChange={setMailAddress}
            onBlur={async () => {
              await mailAddressAvailableQuery({
                variables: {
                  mailAddress: mailAddress,
                },
              });
            }}
            validate={{
              required: {
                value: true,
                message: 'メールアドレスを入力してください',
              },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: '正しいメールアドレスを入力してください',
              },
              validate: (value: string) => {
                if (props.members.filter((member) => member.mailAddress === value).length > 0) {
                  return 'このメールアドレスはすでに登録済みです';
                }
                if (
                  props.invitations.filter((invitation) => invitation.mailAddress === value)
                    .length > 0
                ) {
                  return 'このメールアドレスはすでに招待済みです';
                }
                return true;
              },
            }}
            additionalErrorMessage={
              mailAddressAvailableResult?.mailAddressAvailable === false
                ? `このメールアドレスは${'\n'}すでに他の組織や個人で登録済みです`
                : null
            }
          />
          <Input
            name={'name'}
            label={'氏名（任意入力）'}
            onChange={setName}
            validate={{
              maxLength: {
                value: 50,
                message: '氏名は50文字以内で入力してください',
              },
            }}
          />
          <Input
            name={'department'}
            label={'所属部署（任意入力）'}
            onChange={setDepartment}
            validate={{
              maxLength: {
                value: 50,
                message: '50文字以内で入力してください',
              },
            }}
          />
          <Input
            name={'employeeNumber'}
            label={'従業員番号（任意入力）'}
            onChange={setEmployeeNumber}
            validate={{
              maxLength: {
                value: 50,
                message: '50文字以内で入力してください',
              },
            }}
          />
        </View>
        <View>
          <MultiSelect
            name={'joinTeam'}
            placeholder={'参加するチームを選択'}
            pickerItems={data!.joinedTeamsWithoutPersonalTeam!.map((team) => {
              return {
                label: team!.name,
                value: team!.id!,
              } as ListValueMap;
            })}
            isSearchable
            onChange={(value) => {
              setTeamIds(value);
            }}
          />
        </View>
        <View style={{ flexDirection: 'row' }}>
          <View>
            <View
              style={{
                marginVertical: 10,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 130 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: isAdminRole
                      ? themeContext.colors.primary
                      : themeContext.colors.textColor,
                  }}>
                  管理者
                </Typography>
              </View>
              <View>
                <Switch
                  value={isAdminRole}
                  onValueChange={(value) => {
                    setAdminRole(value);
                    setLaborCostManagementRole(value);
                    setSalesManagementRole(value);
                    setMemberManagementRole(value);
                    setClientManagementRole(value);
                    setOrganizationReportRole(value);
                    setTeamReportRole(value);
                    setOrganizationMemberViewRole(value);
                    setTeamMemberViewRole(value);
                    setProjectCreatePermissionFlg(value);
                    setProjectUpdatePermissionFlg(value);
                    setProjectDeletePermissionFlg(value);
                    setTaskCreatePermissionFlg(value);
                    setTaskUpdatePermissionFlg(value);
                    setTaskDeletePermissionFlg(value);
                    setProjectCustomAttributePermissionFlg(value);
                    setTaskCustomAttributePermissionFlg(value);
                  }}
                  disabled={!meData!.me!.adminRole}
                />
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={
                    TypographyType.Description
                  }>{`全ての操作が可能です。${'\n'}(作業データの代理編集以外)`}</Typography>
              </View>
              {!meData!.me!.adminRole && (
                <View style={{ paddingLeft: 10 }}>
                  <Typography
                    variant={TypographyType.Description}
                    style={{
                      color: themeContext.colors.error,
                    }}>{`変更するには管理者権限が必要です。`}</Typography>
                </View>
              )}
            </View>
            <View
              style={{
                marginVertical: 10,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 130 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: isProxyEditWorkDataRole
                      ? themeContext.colors.primary
                      : themeContext.colors.textColor,
                  }}>
                  {`作業データの${'\n'}代理編集`}
                </Typography>
              </View>
              <View>
                <Switch
                  value={isProxyEditWorkDataRole}
                  onValueChange={(value) => setProxyEditWorkDataRole(value)}
                />
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={
                    TypographyType.Description
                  }>{`他のメンバーの作業履歴・作業予定データの${'\n'}編集を行えます`}</Typography>
              </View>
            </View>
            <View
              style={{
                marginVertical: 10,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 130 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: isMemberManagementRole
                      ? themeContext.colors.primary
                      : themeContext.colors.textColor,
                  }}>
                  メンバー管理
                </Typography>
              </View>
              <View>
                <Switch
                  value={isMemberManagementRole}
                  onValueChange={(value) => setMemberManagementRole(value)}
                  disabled={isAdminRole}
                />
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={
                    TypographyType.Description
                  }>{`メンバーの追加・削除ができます。`}</Typography>
              </View>
            </View>
            <View
              style={{
                marginVertical: 10,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 130 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: isClientManagementRole
                      ? themeContext.colors.primary
                      : themeContext.colors.textColor,
                  }}>
                  取引先管理
                </Typography>
              </View>
              <View>
                <Switch
                  value={isClientManagementRole}
                  onValueChange={(value) => setClientManagementRole(value)}
                  disabled={isAdminRole}
                />
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={
                    TypographyType.Description
                  }>{`取引先の追加・削除ができます。`}</Typography>
              </View>
            </View>
            <View
              style={{
                marginVertical: 10,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 130 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: isLaborCostManagementRole
                      ? themeContext.colors.primary
                      : themeContext.colors.textColor,
                  }}>
                  人件費・売上管理
                </Typography>
              </View>
              <View>
                <Switch
                  value={isLaborCostManagementRole}
                  onValueChange={(value) => setLaborCostManagementRole(value)}
                  disabled={isAdminRole}
                />
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={
                    TypographyType.Description
                  }>{`人件費・契約情報の登録・編集・閲覧ができます。`}</Typography>
              </View>
            </View>
            {/* <View
              style={{
                marginVertical: 10,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 150 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: isSalesManagementRole
                      ? themeContext.colors.primary
                      : themeContext.colors.textColor,
                  }}>
                  売上管理
                </Typography>
              </View>
              <View>
                <Switch
                  value={isSalesManagementRole}
                  onValueChange={(value) => setSalesManagementRole(value)}
                  disabled={isAdminRole}
                />
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={
                    TypographyType.Description
                  }>{`売上の登録・編集・閲覧ができます。`}</Typography>
              </View>
            </View> */}
            <View
              style={{
                marginVertical: 10,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 130 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: isOrganizationReportRole
                      ? themeContext.colors.primary
                      : themeContext.colors.textColor,
                  }}>
                  {`組織の${'\n'}時間分析閲覧`}
                </Typography>
              </View>
              <View>
                <Switch
                  value={isOrganizationReportRole}
                  onValueChange={(value) => setOrganizationReportRole(value)}
                  disabled={isAdminRole}
                />
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={
                    TypographyType.Description
                  }>{`組織の時間分析画面を閲覧できます。`}</Typography>
              </View>
            </View>
            <View
              style={{
                marginVertical: 10,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 130 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: isOrganizationMemberViewRole
                      ? themeContext.colors.primary
                      : themeContext.colors.textColor,
                  }}>
                  {`組織全員の${'\n'}作業履歴の閲覧`}
                </Typography>
              </View>
              <View>
                <Switch
                  value={isOrganizationMemberViewRole}
                  onValueChange={(value) => setOrganizationMemberViewRole(value)}
                  disabled={isAdminRole}
                />
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={
                    TypographyType.Description
                  }>{`組織のメンバーの作業履歴や作業予定を${'\n'}メンバーの状況画面やカレンダーで閲覧できます。`}</Typography>
              </View>
            </View>
            <View
              style={{
                marginVertical: 10,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 130 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: isTeamReportRole
                      ? themeContext.colors.primary
                      : themeContext.colors.textColor,
                  }}>
                  {`チームの${'\n'}時間分析閲覧`}
                </Typography>
              </View>
              <View>
                <Switch
                  value={isTeamReportRole}
                  onValueChange={(value) => setTeamReportRole(value)}
                  disabled={isAdminRole}
                />
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={
                    TypographyType.Description
                  }>{`所属しているチームのみ${'\n'}時間分析画面を閲覧できます。`}</Typography>
              </View>
            </View>

            <View
              style={{
                marginVertical: 10,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 130 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: isTeamMemberViewRole
                      ? themeContext.colors.primary
                      : themeContext.colors.textColor,
                  }}>
                  {`チームメンバーの${'\n'}作業履歴の閲覧`}
                </Typography>
              </View>
              <View>
                <Switch
                  value={isTeamMemberViewRole}
                  onValueChange={(value) => setTeamMemberViewRole(value)}
                  disabled={isAdminRole}
                />
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={
                    TypographyType.Description
                  }>{`所属しているチームのメンバーのみ${'\n'}作業履歴や作業予定を${'\n'}メンバーの状況画面やカレンダーで閲覧できます。`}</Typography>
              </View>
            </View>
          </View>
          <View>
            <View
              style={{
                marginVertical: 10,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 150 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: isProjectCreatePermissionFlg
                      ? themeContext.colors.primary
                      : themeContext.colors.textColor,
                  }}>
                  {`プロジェクトの作成`}
                </Typography>
              </View>
              <View>
                <Switch
                  value={isProjectCreatePermissionFlg}
                  onValueChange={(value) => setProjectCreatePermissionFlg(value)}
                  disabled={isAdminRole}
                />
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={
                    TypographyType.Description
                  }>{`プロジェクトを作成することができます`}</Typography>
              </View>
            </View>
            <View
              style={{
                marginVertical: 10,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 150 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: isProjectUpdatePermissionFlg
                      ? themeContext.colors.primary
                      : themeContext.colors.textColor,
                  }}>
                  {`プロジェクトの更新`}
                </Typography>
              </View>
              <View>
                <Switch
                  value={isProjectUpdatePermissionFlg}
                  onValueChange={(value) => setProjectUpdatePermissionFlg(value)}
                  disabled={isAdminRole}
                />
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={
                    TypographyType.Description
                  }>{`プロジェクトを更新することができます`}</Typography>
              </View>
            </View>
            <View
              style={{
                marginVertical: 10,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 150 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: isProjectDeletePermissionFlg
                      ? themeContext.colors.primary
                      : themeContext.colors.textColor,
                  }}>
                  {`プロジェクトの削除`}
                </Typography>
              </View>
              <View>
                <Switch
                  value={isProjectDeletePermissionFlg}
                  onValueChange={(value) => setProjectDeletePermissionFlg(value)}
                  disabled={isAdminRole}
                />
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={
                    TypographyType.Description
                  }>{`プロジェクトを削除することができます`}</Typography>
              </View>
            </View>
            <View
              style={{
                marginVertical: 10,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 150 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: isProjectCustomAttributePermissionFlg
                      ? themeContext.colors.primary
                      : themeContext.colors.textColor,
                  }}>
                  {`プロジェクトの${'\n'}カスタム項目の編集`}
                </Typography>
              </View>
              <View>
                <Switch
                  value={isProjectCustomAttributePermissionFlg}
                  onValueChange={(value) => setProjectCustomAttributePermissionFlg(value)}
                  disabled={isAdminRole}
                />
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={
                    TypographyType.Description
                  }>{`プロジェクトのカスタム項目を編集することができます`}</Typography>
              </View>
            </View>
            <View
              style={{
                marginVertical: 10,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 150 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: isTaskCreatePermissionFlg
                      ? themeContext.colors.primary
                      : themeContext.colors.textColor,
                  }}>
                  {`タスクの作成`}
                </Typography>
              </View>
              <View>
                <Switch
                  value={isTaskCreatePermissionFlg}
                  onValueChange={(value) => setTaskCreatePermissionFlg(value)}
                  disabled={isAdminRole}
                />
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={
                    TypographyType.Description
                  }>{`タスクを作成することができます`}</Typography>
              </View>
            </View>
            <View
              style={{
                marginVertical: 10,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 150 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: isTaskUpdatePermissionFlg
                      ? themeContext.colors.primary
                      : themeContext.colors.textColor,
                  }}>
                  {`タスクの更新`}
                </Typography>
              </View>
              <View>
                <Switch
                  value={isTaskUpdatePermissionFlg}
                  onValueChange={(value) => setTaskUpdatePermissionFlg(value)}
                  disabled={isAdminRole}
                />
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={
                    TypographyType.Description
                  }>{`タスクを更新することができます`}</Typography>
              </View>
            </View>
            <View
              style={{
                marginVertical: 10,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 150 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: isTaskDeletePermissionFlg
                      ? themeContext.colors.primary
                      : themeContext.colors.textColor,
                  }}>
                  {`タスクの削除`}
                </Typography>
              </View>
              <View>
                <Switch
                  value={isTaskDeletePermissionFlg}
                  onValueChange={(value) => setTaskDeletePermissionFlg(value)}
                  disabled={isAdminRole}
                />
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={
                    TypographyType.Description
                  }>{`タスクを削除することができます`}</Typography>
              </View>
            </View>
            <View
              style={{
                marginVertical: 10,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 150 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: isTaskCustomAttributePermissionFlg
                      ? themeContext.colors.primary
                      : themeContext.colors.textColor,
                  }}>
                  {`タスクの${'\n'}カスタム項目の編集`}
                </Typography>
              </View>
              <View>
                <Switch
                  value={isTaskCustomAttributePermissionFlg}
                  onValueChange={(value) => setTaskCustomAttributePermissionFlg(value)}
                  disabled={isAdminRole}
                />
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={
                    TypographyType.Description
                  }>{`タスクのカスタム項目を編集することができます`}</Typography>
              </View>
            </View>
          </View>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 10 }}>
          <Button
            text="招待する"
            completeText="招待しました"
            style={{ marginRight: 5, paddingVertical: 10, paddingHorizontal: 20 }}
            textStyle={{ fontSize: 14 }}
            isDisabled={mailAddressAvailableResult?.mailAddressAvailable === false}
            onPress={async () => {
              const result = await inviteMember();
              props.onComplete(result.data!.inviteMember!);
              history.push(
                `/app/${loginUser!.organizationId}/members/invitation/${
                  result.data!.inviteMember?.invitationKey
                }/`
              );
            }}
          />
          <Button
            text="キャンセル"
            style={{ paddingVertical: 10, paddingHorizontal: 20, backgroundColor: 'transparent' }}
            textStyle={{ fontSize: 14, color: themeContext.colors.primary }}
            onPress={async () => {
              props.onCancel();
            }}
            disableValidate={true}
          />
        </View>
      </Form>
    </CustomScrollView>
  );
};

interface IRegisterInvitationLinkDialogProps {
  organization: Organization;
  onComplete: (invitationLink: MemberInvitationLink) => void;
  onCancel: () => void;
}

const RegisterInvitationLinkDialog = (props: IRegisterInvitationLinkDialogProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const history = useHistory();
  const [loginUser, __] = useContext(LoginUserContext);
  const [memo, setMemo] = useState('');
  const [isClientManagementRole, setClientManagementRole] = useState(true);
  const [isOrganizationReportRole, setOrganizationReportRole] = useState(true);
  const [isTeamReportRole, setTeamReportRole] = useState(true);
  const [isOrganizationMemberViewRole, setOrganizationMemberViewRole] = useState(true);
  const [isTeamMemberViewRole, setTeamMemberViewRole] = useState(true);
  const [isProjectCreatePermissionFlg, setProjectCreatePermissionFlg] = useState(true);
  const [isProjectUpdatePermissionFlg, setProjectUpdatePermissionFlg] = useState(true);
  const [isProjectDeletePermissionFlg, setProjectDeletePermissionFlg] = useState(true);
  const [isProjectCustomAttributePermissionFlg, setProjectCustomAttributePermissionFlg] =
    useState(false);
  const [isTaskCreatePermissionFlg, setTaskCreatePermissionFlg] = useState(true);
  const [isTaskUpdatePermissionFlg, setTaskUpdatePermissionFlg] = useState(true);
  const [isTaskDeletePermissionFlg, setTaskDeletePermissionFlg] = useState(true);
  const [isTaskCustomAttributePermissionFlg, setTaskCustomAttributePermissionFlg] = useState(false);
  const [createInvitationLink, _] = useCreateInvitationLinkMutation({
    variables: {
      input: {
        teamIds: [],
        memo: memo || null,
        clientManagementRole: isClientManagementRole,
        proxyEditWorkingDataRole: false,
        organizationReportRole: isOrganizationReportRole,
        teamReportRole: isTeamReportRole,
        organizationMemberViewRole: isOrganizationMemberViewRole,
        teamMemberViewRole: isTeamMemberViewRole,
        projectCreatePermissionFlg: isProjectCreatePermissionFlg,
        projectUpdatePermissionFlg: isProjectUpdatePermissionFlg,
        projectDeletePermissionFlg: isProjectDeletePermissionFlg,
        projectCustomAttributePermissionFlg: isProjectCustomAttributePermissionFlg,
        taskCreatePermissionFlg: isTaskCreatePermissionFlg,
        taskUpdatePermissionFlg: isTaskUpdatePermissionFlg,
        taskDeletePermissionFlg: isTaskDeletePermissionFlg,
        taskCustomAttributePermissionFlg: isTaskCustomAttributePermissionFlg,
      },
    },
    refetchQueries: [
      {
        query: MemberInvitationLinksDocument,
        variables: {
          organizationId: props.organization.id!,
        },
      },
    ],
  });

  const { loading: meLoading, data: meData } = useMeQuery({
    fetchPolicy: 'network-only',
  });

  if (meLoading) {
    return <></>;
  }

  return (
    <CustomScrollView style={{ maxHeight: window.innerHeight - 100 }}>
      <Form>
        <View
          style={{
            marginVertical: 10,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}>
          <View style={{ minWidth: 150 }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontWeight: '500',
                textAlign: 'left',
                color: themeContext.colors.textColor,
              }}>
              メモ
            </Typography>
          </View>
          <Input
            name={'memo'}
            focus={true}
            containerStyle={{ flex: 1 }}
            placeholder={'リンクを分かりやすく管理するためにメモを入力してください'}
            onChange={(value) => setMemo(value)}
            validate={{
              maxLength: {
                value: 100,
                message: '100文字以内で入力してください',
              },
            }}
          />
        </View>
        <View>
          <View
            style={{
              marginVertical: 10,
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}>
            <View
              style={{
                minWidth: 440,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 150 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: themeContext.colors.textColor,
                  }}>
                  管理者
                </Typography>
              </View>
              <View>
                <Switch value={false} onValueChange={(value) => {}} disabled={true} />
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={
                    TypographyType.Description
                  }>{`全ての操作が可能です。${'\n'}(作業データの代理編集以外)`}</Typography>
              </View>
            </View>
            <View style={{ paddingLeft: 10 }}>
              <Typography
                variant={TypographyType.Description}
                style={{
                  color: themeContext.colors.error,
                }}>{`リンクによる招待では、管理者権限は付与できません。${'\n'}メールアドレスで招待するか、組織に参加した後に権限変更してください。`}</Typography>
            </View>
          </View>
          <View
            style={{
              marginVertical: 10,
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}>
            <View
              style={{
                minWidth: 440,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 150 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: themeContext.colors.textColor,
                  }}>
                  {`作業データの${'\n'}代理編集`}
                </Typography>
              </View>
              <View>
                <Switch value={false} onValueChange={(value) => {}} disabled={true} />
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={
                    TypographyType.Description
                  }>{`他のメンバーの作業履歴・${'\n'}作業予定データの編集を行えます`}</Typography>
              </View>
            </View>
            <View style={{ paddingLeft: 10 }}>
              <Typography
                variant={TypographyType.Description}
                style={{
                  color: themeContext.colors.error,
                }}>{`リンクによる招待では、作業データの代理編集は付与できません。${'\n'}メールアドレスで招待するか、組織に参加した後に権限変更してください。`}</Typography>
            </View>
          </View>
          <View
            style={{
              marginVertical: 10,
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}>
            <View
              style={{
                minWidth: 440,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 150 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: themeContext.colors.textColor,
                  }}>
                  メンバー管理
                </Typography>
              </View>
              <View>
                <Switch value={false} onValueChange={(value) => {}} disabled={true} />
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={
                    TypographyType.Description
                  }>{`メンバーの追加・削除ができます。`}</Typography>
              </View>
            </View>
            <View style={{ paddingLeft: 10 }}>
              <Typography
                variant={TypographyType.Description}
                style={{
                  color: themeContext.colors.error,
                }}>{`リンクによる招待では、メンバー管理権限は付与できません。${'\n'}メールアドレスで招待するか、組織に参加した後に権限変更してください。`}</Typography>
            </View>
          </View>
          <View
            style={{
              marginVertical: 10,
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}>
            <View
              style={{
                minWidth: 440,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 150 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: themeContext.colors.textColor,
                  }}>
                  人件費・売上管理
                </Typography>
              </View>
              <View>
                <Switch
                  value={false}
                  onValueChange={(value) => {
                    //NOP
                  }}
                  disabled={true}
                />
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={
                    TypographyType.Description
                  }>{`人件費・契約情報の登録・編集・閲覧ができます。`}</Typography>
              </View>
            </View>
            <View style={{ paddingLeft: 10 }}>
              <Typography
                variant={TypographyType.Description}
                style={{
                  color: themeContext.colors.error,
                }}>{`リンクによる招待では、人件費・売上管理権限は付与できません。${'\n'}メールアドレスで招待するか、組織に参加した後に権限変更してください。`}</Typography>
            </View>
          </View>
          {/* <View
            style={{
              marginVertical: 10,
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}>
            <View style={{ minWidth: 150 }}>
              <Typography
                variant={TypographyType.Normal}
                style={{
                  fontWeight: '500',
                  textAlign: 'left',
                  color: themeContext.colors.textColor,
                }}>
                売上管理
              </Typography>
            </View>
            <View>
              <Switch
                value={false}
                onValueChange={(value) => {
                  //NOP
                }}
                disabled={true}
              />
            </View>
            <View style={{ paddingLeft: 10 }}>
              <Typography
                variant={
                  TypographyType.Description
                }>{`売上の登録・編集・閲覧ができます。`}</Typography>
            </View>
            <View style={{ paddingLeft: 10 }}>
              <Typography
                variant={TypographyType.Description}
                style={{
                  color: themeContext.colors.error,
                }}>{`リンクによる招待では、売上管理権限は付与できません。${'\n'}メールアドレスで招待するか、組織に参加した後に権限変更してください。`}</Typography>
            </View>
          </View> */}
        </View>
        <View style={{ flexDirection: 'row' }}>
          <View>
            <View
              style={{
                marginVertical: 10,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 150 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: isClientManagementRole
                      ? themeContext.colors.primary
                      : themeContext.colors.textColor,
                  }}>
                  取引先管理
                </Typography>
              </View>
              <View>
                <Switch
                  value={isClientManagementRole}
                  onValueChange={(value) => setClientManagementRole(value)}
                />
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={
                    TypographyType.Description
                  }>{`取引先の追加・削除ができます。`}</Typography>
              </View>
            </View>
            <View
              style={{
                marginVertical: 10,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 150 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: isOrganizationReportRole
                      ? themeContext.colors.primary
                      : themeContext.colors.textColor,
                  }}>
                  {`組織の${'\n'}時間分析閲覧`}
                </Typography>
              </View>
              <View>
                <Switch
                  value={isOrganizationReportRole}
                  onValueChange={(value) => setOrganizationReportRole(value)}
                />
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={
                    TypographyType.Description
                  }>{`組織の時間分析画面を閲覧できます。`}</Typography>
              </View>
            </View>
            <View
              style={{
                marginVertical: 10,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 150 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: isOrganizationMemberViewRole
                      ? themeContext.colors.primary
                      : themeContext.colors.textColor,
                  }}>
                  {`組織全員の${'\n'}作業履歴の閲覧`}
                </Typography>
              </View>
              <View>
                <Switch
                  value={isOrganizationMemberViewRole}
                  onValueChange={(value) => setOrganizationMemberViewRole(value)}
                />
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={
                    TypographyType.Description
                  }>{`組織のメンバーの作業履歴や作業予定を${'\n'}メンバーの状況画面やカレンダーで閲覧できます。`}</Typography>
              </View>
            </View>
            <View
              style={{
                marginVertical: 10,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 150 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: isTeamReportRole
                      ? themeContext.colors.primary
                      : themeContext.colors.textColor,
                  }}>
                  {`チームの${'\n'}時間分析閲覧`}
                </Typography>
              </View>
              <View>
                <Switch
                  value={isTeamReportRole}
                  onValueChange={(value) => setTeamReportRole(value)}
                />
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={
                    TypographyType.Description
                  }>{`所属しているチームのみ${'\n'}時間分析画面を閲覧できます。`}</Typography>
              </View>
            </View>

            <View
              style={{
                marginVertical: 10,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 150 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: isTeamMemberViewRole
                      ? themeContext.colors.primary
                      : themeContext.colors.textColor,
                  }}>
                  {`チームメンバーの${'\n'}作業履歴の閲覧`}
                </Typography>
              </View>
              <View>
                <Switch
                  value={isTeamMemberViewRole}
                  onValueChange={(value) => setTeamMemberViewRole(value)}
                />
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={
                    TypographyType.Description
                  }>{`所属しているチームのメンバーのみ${'\n'}作業履歴や作業予定を${'\n'}メンバーの状況画面やカレンダーで閲覧できます。`}</Typography>
              </View>
            </View>
          </View>
          <View style={{ marginLeft: 10 }}>
            <View
              style={{
                marginVertical: 10,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 150 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: isProjectCreatePermissionFlg
                      ? themeContext.colors.primary
                      : themeContext.colors.textColor,
                  }}>
                  {`プロジェクトの作成`}
                </Typography>
              </View>
              <View>
                <Switch
                  value={isProjectCreatePermissionFlg}
                  onValueChange={(value) => setProjectCreatePermissionFlg(value)}
                />
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={
                    TypographyType.Description
                  }>{`プロジェクトを作成することができます。`}</Typography>
              </View>
            </View>
            <View
              style={{
                marginVertical: 10,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 150 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: isProjectUpdatePermissionFlg
                      ? themeContext.colors.primary
                      : themeContext.colors.textColor,
                  }}>
                  {`プロジェクトの更新`}
                </Typography>
              </View>
              <View>
                <Switch
                  value={isProjectUpdatePermissionFlg}
                  onValueChange={(value) => setProjectUpdatePermissionFlg(value)}
                />
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={
                    TypographyType.Description
                  }>{`プロジェクトを更新することができます。`}</Typography>
              </View>
            </View>
            <View
              style={{
                marginVertical: 10,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 150 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: isProjectDeletePermissionFlg
                      ? themeContext.colors.primary
                      : themeContext.colors.textColor,
                  }}>
                  {`プロジェクトの削除`}
                </Typography>
              </View>
              <View>
                <Switch
                  value={isProjectDeletePermissionFlg}
                  onValueChange={(value) => setProjectDeletePermissionFlg(value)}
                />
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={
                    TypographyType.Description
                  }>{`プロジェクトを削除することができます。`}</Typography>
              </View>
            </View>
            <View
              style={{
                marginVertical: 10,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 150 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: isProjectCustomAttributePermissionFlg
                      ? themeContext.colors.primary
                      : themeContext.colors.textColor,
                  }}>
                  {`プロジェクトの${'\n'}カスタム項目の編集`}
                </Typography>
              </View>
              <View>
                <Switch
                  value={isProjectCustomAttributePermissionFlg}
                  onValueChange={(value) => setProjectCustomAttributePermissionFlg(value)}
                />
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={
                    TypographyType.Description
                  }>{`プロジェクトのカスタム項目を編集することができます。`}</Typography>
              </View>
            </View>
            <View
              style={{
                marginVertical: 10,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 150 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: isTaskCreatePermissionFlg
                      ? themeContext.colors.primary
                      : themeContext.colors.textColor,
                  }}>
                  {`タスクの作成`}
                </Typography>
              </View>
              <View>
                <Switch
                  value={isTaskCreatePermissionFlg}
                  onValueChange={(value) => setTaskCreatePermissionFlg(value)}
                />
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={
                    TypographyType.Description
                  }>{`タスクを作成することができます。`}</Typography>
              </View>
            </View>
            <View
              style={{
                marginVertical: 10,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 150 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: isTaskUpdatePermissionFlg
                      ? themeContext.colors.primary
                      : themeContext.colors.textColor,
                  }}>
                  {`タスクの更新`}
                </Typography>
              </View>
              <View>
                <Switch
                  value={isTaskUpdatePermissionFlg}
                  onValueChange={(value) => setTaskUpdatePermissionFlg(value)}
                />
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={
                    TypographyType.Description
                  }>{`タスクを更新することができます。`}</Typography>
              </View>
            </View>
            <View
              style={{
                marginVertical: 10,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 150 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: isTaskDeletePermissionFlg
                      ? themeContext.colors.primary
                      : themeContext.colors.textColor,
                  }}>
                  {`タスクの削除`}
                </Typography>
              </View>
              <View>
                <Switch
                  value={isTaskDeletePermissionFlg}
                  onValueChange={(value) => setTaskDeletePermissionFlg(value)}
                />
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={
                    TypographyType.Description
                  }>{`タスクを削除することができます。`}</Typography>
              </View>
            </View>
            <View
              style={{
                marginVertical: 10,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 150 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: isTaskCustomAttributePermissionFlg
                      ? themeContext.colors.primary
                      : themeContext.colors.textColor,
                  }}>
                  {`タスクの${'\n'}カスタム項目の編集`}
                </Typography>
              </View>
              <View>
                <Switch
                  value={isTaskCustomAttributePermissionFlg}
                  onValueChange={(value) => setTaskCustomAttributePermissionFlg(value)}
                />
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={
                    TypographyType.Description
                  }>{`タスクのカスタム項目を編集することができます。`}</Typography>
              </View>
            </View>
          </View>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 10 }}>
          <Button
            text="招待リンクを作成する"
            completeText="作成しました"
            style={{ marginRight: 5, paddingVertical: 10, paddingHorizontal: 20 }}
            textStyle={{ fontSize: 14 }}
            onPress={async () => {
              const result = await createInvitationLink();
              props.onComplete(result.data!.createInvitationLink!);
            }}
          />
          <Button
            text="キャンセル"
            style={{
              paddingVertical: 10,
              paddingHorizontal: 20,
              backgroundColor: 'transparent',
            }}
            textStyle={{ fontSize: 14, color: themeContext.colors.primary }}
            onPress={async () => {
              props.onCancel();
            }}
            disableValidate={true}
          />
        </View>
      </Form>
    </CustomScrollView>
  );
};

interface PermissionSearchCondition {
  isAdminRole: boolean;
  isLaborCostManagementRole: boolean;
  isSalesManagementRole: boolean;
  isMemberManagementRole: boolean;
  isClientManagementRole: boolean;
  isProxyEditWorkDataRole: boolean;
  isOrganizationReportRole: boolean;
  isTeamReportRole: boolean;
  isOrganizationMemberViewRole: boolean;
  isTeamMemberViewRole: boolean;
  isProjectCreatePermissionFlg: boolean;
  isProjectUpdatePermissionFlg: boolean;
  isProjectDeletePermissionFlg: boolean;
  isProjectCustomAttributePermissionFlg: boolean;
  isTaskCreatePermissionFlg: boolean;
  isTaskUpdatePermissionFlg: boolean;
  isTaskDeletePermissionFlg: boolean;
  isTaskCustomAttributePermissionFlg: boolean;
  isActiveStatus: boolean;
  isInactiveStatus: boolean;
  isInvitingStatus: boolean;
  enabledTwoFactorAuth: boolean;
  disabledTwoFactorAuth: boolean;
}

interface ISearchContainerProps {
  searchCondition: PermissionSearchCondition;
  setSearchCondition: (value: PermissionSearchCondition) => void;
  showMemberStatus: boolean;
  showTwoFactorAuth: boolean;
  members: Member[];
  memberInvitations: MemberInvitation[];
}

const SearchContainer = (props: ISearchContainerProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showTwoFactorSettingModal, setShowTwoFactorSettingModal] = useState(false);
  const [isAdminRole, setAdminRole] = useState(props.searchCondition.isAdminRole);
  const [isLaborCostManagementRole, setLaborCostManagementRole] = useState(
    props.searchCondition.isLaborCostManagementRole
  );
  const [isSalesManagementRole, setSalesManagementRole] = useState(
    props.searchCondition.isSalesManagementRole
  );
  const [isMemberManagementRole, setMemberManagementRole] = useState(
    props.searchCondition.isMemberManagementRole
  );
  const [isClientManagementRole, setClientManagementRole] = useState(
    props.searchCondition.isClientManagementRole
  );
  const [isProxyEditWorkDataRole, setProxyEditWorkDataRole] = useState(
    props.searchCondition.isProxyEditWorkDataRole
  );
  const [isOrganizationReportRole, setOrganizationReportRole] = useState(
    props.searchCondition.isOrganizationReportRole
  );
  const [isTeamReportRole, setTeamReportRole] = useState(props.searchCondition.isTeamReportRole);
  const [isOrganizationMemberViewRole, setOrganizationMemberViewRole] = useState(
    props.searchCondition.isOrganizationMemberViewRole
  );
  const [isTeamMemberViewRole, setTeamMemberViewRole] = useState(
    props.searchCondition.isTeamMemberViewRole
  );
  const [isProjectCreatePermissionFlg, setProjectCreatePermissionFlg] = useState(
    props.searchCondition.isProjectCreatePermissionFlg
  );
  const [isProjectUpdatePermissionFlg, setProjectUpdatePermissionFlg] = useState(
    props.searchCondition.isProjectUpdatePermissionFlg
  );
  const [isProjectDeletePermissionFlg, setProjectDeletePermissionFlg] = useState(
    props.searchCondition.isProjectDeletePermissionFlg
  );
  const [isProjectCustomAttributePermissionFlg, setProjectCustomAttributePermissionFlg] = useState(
    props.searchCondition.isProjectCustomAttributePermissionFlg
  );
  const [isTaskCreatePermissionFlg, setTaskCreatePermissionFlg] = useState(
    props.searchCondition.isTaskCreatePermissionFlg
  );
  const [isTaskUpdatePermissionFlg, setTaskUpdatePermissionFlg] = useState(
    props.searchCondition.isTaskUpdatePermissionFlg
  );
  const [isTaskDeletePermissionFlg, setTaskDeletePermissionFlg] = useState(
    props.searchCondition.isTaskDeletePermissionFlg
  );
  const [isTaskCustomAttributePermissionFlg, setTaskCustomAttributePermissionFlg] = useState(
    props.searchCondition.isTaskCustomAttributePermissionFlg
  );
  const [isActiveStatus, setActiveStatus] = useState(props.searchCondition.isActiveStatus);
  const [isInactiveStatus, setInactiveStatus] = useState(props.searchCondition.isInactiveStatus);
  const [isInvitingStatus, setInvitingStatus] = useState(props.searchCondition.isInvitingStatus);
  const [enabledTwoFactorAuth, setEnabledTwoFactorAuth] = useState(
    props.searchCondition.isInactiveStatus
  );
  const [disabledTwoFactorAuth, setDisabledTwoFactorAuth] = useState(
    props.searchCondition.isInvitingStatus
  );

  const isAdmin = true;

  useEffect(() => {
    props.setSearchCondition({
      isAdminRole,
      isLaborCostManagementRole,
      isSalesManagementRole,
      isMemberManagementRole,
      isClientManagementRole,
      isProxyEditWorkDataRole,
      isOrganizationReportRole,
      isOrganizationMemberViewRole,
      isTeamReportRole,
      isTeamMemberViewRole,
      isProjectCreatePermissionFlg,
      isProjectUpdatePermissionFlg,
      isProjectDeletePermissionFlg,
      isProjectCustomAttributePermissionFlg,
      isTaskCreatePermissionFlg,
      isTaskUpdatePermissionFlg,
      isTaskDeletePermissionFlg,
      isTaskCustomAttributePermissionFlg,
      isActiveStatus,
      isInactiveStatus,
      isInvitingStatus,
      enabledTwoFactorAuth,
      disabledTwoFactorAuth,
    });
  }, [
    isAdminRole,
    isLaborCostManagementRole,
    isSalesManagementRole,
    isMemberManagementRole,
    isClientManagementRole,
    isProxyEditWorkDataRole,
    isOrganizationReportRole,
    isOrganizationMemberViewRole,
    isTeamReportRole,
    isTeamMemberViewRole,
    isProjectCreatePermissionFlg,
    isProjectUpdatePermissionFlg,
    isProjectDeletePermissionFlg,
    isProjectCustomAttributePermissionFlg,
    isTaskCreatePermissionFlg,
    isTaskUpdatePermissionFlg,
    isTaskDeletePermissionFlg,
    isTaskCustomAttributePermissionFlg,
    isActiveStatus,
    isInactiveStatus,
    isInvitingStatus,
    enabledTwoFactorAuth,
    disabledTwoFactorAuth,
  ]);
  return (
    <View
      style={{
        justifyContent: 'center',
        alignItems: 'flex-start',
        marginLeft: 20,
        marginBottom: 10,
      }}>
      <>
        <TouchableOpacity
          onPress={() => setShowPermissionModal(!showPermissionModal)}
          style={{ flexDirection: 'row', maxWidth: '100%' }}>
          <Typography
            variant={TypographyType.Normal}
            style={{ color: themeContext.colors.description, fontSize: 13 }}
            ellipsis>
            権限で絞り込み
          </Typography>
          {showPermissionModal ? (
            <CaretUpIcon size={14} onPress={() => setShowPermissionModal(!showPermissionModal)} />
          ) : (
            <CaretDownIcon size={14} onPress={() => setShowPermissionModal(!showPermissionModal)} />
          )}
        </TouchableOpacity>
        <PermissionSearchArea show={showPermissionModal}>
          <View style={{ paddingHorizontal: 10, marginTop: 5 }}>
            <TouchableOpacity
              style={{ flexDirection: 'row' }}
              onPress={() => setAdminRole(!isAdminRole)}>
              <Checkbox
                size={18}
                color={themeContext.colors.primary}
                value={isAdminRole}
                onValueChange={(value) => setAdminRole(value)}
              />
              <Typography
                variant={TypographyType.Normal}
                style={{
                  fontSize: 13,
                  lineHeight: 18,
                  marginLeft: 5,
                  color: themeContext.colors.description,
                }}>
                管理者
              </Typography>
            </TouchableOpacity>
          </View>
          <View style={{ paddingHorizontal: 10, marginTop: 5 }}>
            <TouchableOpacity
              style={{ flexDirection: 'row' }}
              onPress={() => setProxyEditWorkDataRole(!isProxyEditWorkDataRole)}>
              <Checkbox
                size={18}
                color={themeContext.colors.primary}
                value={isProxyEditWorkDataRole}
                onValueChange={(value) => setProxyEditWorkDataRole(value)}
              />
              <Typography
                variant={TypographyType.Normal}
                style={{
                  fontSize: 13,
                  lineHeight: 18,
                  marginLeft: 5,
                  color: themeContext.colors.description,
                }}>
                作業データの代理編集
              </Typography>
            </TouchableOpacity>
          </View>
          <View style={{ paddingHorizontal: 10, marginTop: 5 }}>
            <TouchableOpacity
              style={{ flexDirection: 'row' }}
              onPress={() => setLaborCostManagementRole(!isLaborCostManagementRole)}>
              <Checkbox
                size={18}
                color={themeContext.colors.primary}
                value={isLaborCostManagementRole}
                onValueChange={(value) => setLaborCostManagementRole(value)}
              />
              <Typography
                variant={TypographyType.Normal}
                style={{
                  fontSize: 13,
                  lineHeight: 18,
                  marginLeft: 5,
                  color: themeContext.colors.description,
                }}>
                人件費・売上管理
              </Typography>
            </TouchableOpacity>
          </View>
          {/* <View style={{ paddingHorizontal: 10, marginTop: 5 }}>
            <TouchableOpacity
              style={{ flexDirection: 'row' }}
              onPress={() => setSalesManagementRole(!isSalesManagementRole)}>
              <Checkbox
                size={18}
                color={themeContext.colors.primary}
                value={isSalesManagementRole}
                onValueChange={(value) => setSalesManagementRole(value)}
              />
              <Typography
                variant={TypographyType.Normal}
                style={{
                  fontSize: 13,
                  lineHeight: 18,
                  marginLeft: 5,
                  color: themeContext.colors.description,
                }}>
                売上管理
              </Typography>
            </TouchableOpacity>
          </View> */}
          <View style={{ paddingHorizontal: 10, marginTop: 5 }}>
            <TouchableOpacity
              style={{ flexDirection: 'row' }}
              onPress={() => setMemberManagementRole(!isMemberManagementRole)}>
              <Checkbox
                size={18}
                color={themeContext.colors.primary}
                value={isMemberManagementRole}
                onValueChange={(value) => setMemberManagementRole(value)}
              />
              <Typography
                variant={TypographyType.Normal}
                style={{
                  fontSize: 13,
                  lineHeight: 18,
                  marginLeft: 5,
                  color: themeContext.colors.description,
                }}>
                メンバー管理
              </Typography>
            </TouchableOpacity>
          </View>
          <View style={{ paddingHorizontal: 10, marginTop: 5 }}>
            <TouchableOpacity
              style={{ flexDirection: 'row' }}
              onPress={() => setClientManagementRole(!isClientManagementRole)}>
              <Checkbox
                size={18}
                color={themeContext.colors.primary}
                value={isClientManagementRole}
                onValueChange={(value) => setClientManagementRole(value)}
              />
              <Typography
                variant={TypographyType.Normal}
                style={{
                  fontSize: 13,
                  lineHeight: 18,
                  marginLeft: 5,
                  color: themeContext.colors.description,
                }}>
                取引先管理
              </Typography>
            </TouchableOpacity>
          </View>
          <View style={{ paddingHorizontal: 10, marginTop: 5 }}>
            <TouchableOpacity
              style={{ flexDirection: 'row' }}
              onPress={() => setOrganizationReportRole(!isOrganizationReportRole)}>
              <Checkbox
                size={18}
                color={themeContext.colors.primary}
                value={isOrganizationReportRole}
                onValueChange={(value) => setOrganizationReportRole(value)}
              />
              <Typography
                variant={TypographyType.Normal}
                style={{
                  fontSize: 13,
                  lineHeight: 18,
                  marginLeft: 5,
                  color: themeContext.colors.description,
                }}>
                組織の時間分析閲覧
              </Typography>
            </TouchableOpacity>
          </View>
          <View style={{ paddingHorizontal: 10, marginTop: 5 }}>
            <TouchableOpacity
              style={{ flexDirection: 'row' }}
              onPress={() => setOrganizationMemberViewRole(!isOrganizationMemberViewRole)}>
              <Checkbox
                size={18}
                color={themeContext.colors.primary}
                value={isOrganizationMemberViewRole}
                onValueChange={(value) => setOrganizationMemberViewRole(value)}
              />
              <Typography
                variant={TypographyType.Normal}
                style={{
                  fontSize: 13,
                  lineHeight: 18,
                  marginLeft: 5,
                  color: themeContext.colors.description,
                }}>
                組織全員の作業履歴閲覧
              </Typography>
            </TouchableOpacity>
          </View>
          <View style={{ paddingHorizontal: 10, marginTop: 5 }}>
            <TouchableOpacity
              style={{ flexDirection: 'row' }}
              onPress={() => setTeamReportRole(!isTeamReportRole)}>
              <Checkbox
                size={18}
                color={themeContext.colors.primary}
                value={isTeamReportRole}
                onValueChange={(value) => setTeamReportRole(value)}
              />
              <Typography
                variant={TypographyType.Normal}
                style={{
                  fontSize: 13,
                  lineHeight: 18,
                  marginLeft: 5,
                  color: themeContext.colors.description,
                }}>
                チームの時間分析閲覧
              </Typography>
            </TouchableOpacity>
          </View>
          <View style={{ paddingHorizontal: 10, marginTop: 5 }}>
            <TouchableOpacity
              style={{ flexDirection: 'row' }}
              onPress={() => setTeamMemberViewRole(!isTeamMemberViewRole)}>
              <Checkbox
                size={18}
                color={themeContext.colors.primary}
                value={isTeamMemberViewRole}
                onValueChange={(value) => setTeamMemberViewRole(value)}
              />
              <Typography
                variant={TypographyType.Normal}
                style={{
                  fontSize: 13,
                  lineHeight: 18,
                  marginLeft: 5,
                  color: themeContext.colors.description,
                }}>
                チームメンバーの作業履歴閲覧
              </Typography>
            </TouchableOpacity>
          </View>
          <View style={{ paddingHorizontal: 10, marginTop: 5 }}>
            <TouchableOpacity
              style={{ flexDirection: 'row' }}
              onPress={() => setProjectCreatePermissionFlg(!isProjectCreatePermissionFlg)}>
              <Checkbox
                size={18}
                color={themeContext.colors.primary}
                value={isProjectCreatePermissionFlg}
                onValueChange={(value) => setProjectCreatePermissionFlg(value)}
              />
              <Typography
                variant={TypographyType.Normal}
                style={{
                  fontSize: 13,
                  lineHeight: 18,
                  marginLeft: 5,
                  color: themeContext.colors.description,
                }}>
                プロジェクトの作成
              </Typography>
            </TouchableOpacity>
          </View>
          <View style={{ paddingHorizontal: 10, marginTop: 5 }}>
            <TouchableOpacity
              style={{ flexDirection: 'row' }}
              onPress={() => setProjectUpdatePermissionFlg(!isProjectUpdatePermissionFlg)}>
              <Checkbox
                size={18}
                color={themeContext.colors.primary}
                value={isProjectUpdatePermissionFlg}
                onValueChange={(value) => setProjectUpdatePermissionFlg(value)}
              />
              <Typography
                variant={TypographyType.Normal}
                style={{
                  fontSize: 13,
                  lineHeight: 18,
                  marginLeft: 5,
                  color: themeContext.colors.description,
                }}>
                プロジェクトの更新
              </Typography>
            </TouchableOpacity>
          </View>
          <View style={{ paddingHorizontal: 10, marginTop: 5 }}>
            <TouchableOpacity
              style={{ flexDirection: 'row' }}
              onPress={() => setProjectDeletePermissionFlg(!isProjectDeletePermissionFlg)}>
              <Checkbox
                size={18}
                color={themeContext.colors.primary}
                value={isProjectDeletePermissionFlg}
                onValueChange={(value) => setProjectDeletePermissionFlg(value)}
              />
              <Typography
                variant={TypographyType.Normal}
                style={{
                  fontSize: 13,
                  lineHeight: 18,
                  marginLeft: 5,
                  color: themeContext.colors.description,
                }}>
                プロジェクトの削除
              </Typography>
            </TouchableOpacity>
          </View>
          <View style={{ paddingHorizontal: 10, marginTop: 5 }}>
            <TouchableOpacity
              style={{ flexDirection: 'row' }}
              onPress={() =>
                setProjectCustomAttributePermissionFlg(!isProjectCustomAttributePermissionFlg)
              }>
              <Checkbox
                size={18}
                color={themeContext.colors.primary}
                value={isProjectCustomAttributePermissionFlg}
                onValueChange={(value) => setProjectCustomAttributePermissionFlg(value)}
              />
              <Typography
                variant={TypographyType.Normal}
                style={{
                  fontSize: 13,
                  lineHeight: 18,
                  marginLeft: 5,
                  color: themeContext.colors.description,
                }}>
                プロジェクトのカスタム項目編集
              </Typography>
            </TouchableOpacity>
          </View>
          <View style={{ paddingHorizontal: 10, marginTop: 5 }}>
            <TouchableOpacity
              style={{ flexDirection: 'row' }}
              onPress={() => setTaskCreatePermissionFlg(!isTaskCreatePermissionFlg)}>
              <Checkbox
                size={18}
                color={themeContext.colors.primary}
                value={isTaskCreatePermissionFlg}
                onValueChange={(value) => setTaskCreatePermissionFlg(value)}
              />
              <Typography
                variant={TypographyType.Normal}
                style={{
                  fontSize: 13,
                  lineHeight: 18,
                  marginLeft: 5,
                  color: themeContext.colors.description,
                }}>
                タスクの作成
              </Typography>
            </TouchableOpacity>
          </View>
          <View style={{ paddingHorizontal: 10, marginTop: 5 }}>
            <TouchableOpacity
              style={{ flexDirection: 'row' }}
              onPress={() => setTaskUpdatePermissionFlg(!isTaskUpdatePermissionFlg)}>
              <Checkbox
                size={18}
                color={themeContext.colors.primary}
                value={isTaskUpdatePermissionFlg}
                onValueChange={(value) => setTaskUpdatePermissionFlg(value)}
              />
              <Typography
                variant={TypographyType.Normal}
                style={{
                  fontSize: 13,
                  lineHeight: 18,
                  marginLeft: 5,
                  color: themeContext.colors.description,
                }}>
                タスクの更新
              </Typography>
            </TouchableOpacity>
          </View>
          <View style={{ paddingHorizontal: 10, marginTop: 5 }}>
            <TouchableOpacity
              style={{ flexDirection: 'row' }}
              onPress={() => setTaskDeletePermissionFlg(!isTaskDeletePermissionFlg)}>
              <Checkbox
                size={18}
                color={themeContext.colors.primary}
                value={isTaskDeletePermissionFlg}
                onValueChange={(value) => setTaskDeletePermissionFlg(value)}
              />
              <Typography
                variant={TypographyType.Normal}
                style={{
                  fontSize: 13,
                  lineHeight: 18,
                  marginLeft: 5,
                  color: themeContext.colors.description,
                }}>
                タスクの削除
              </Typography>
            </TouchableOpacity>
          </View>
          <View style={{ paddingHorizontal: 10, marginTop: 5 }}>
            <TouchableOpacity
              style={{ flexDirection: 'row' }}
              onPress={() =>
                setTaskCustomAttributePermissionFlg(!isTaskCustomAttributePermissionFlg)
              }>
              <Checkbox
                size={18}
                color={themeContext.colors.primary}
                value={isTaskCustomAttributePermissionFlg}
                onValueChange={(value) => setTaskCustomAttributePermissionFlg(value)}
              />
              <Typography
                variant={TypographyType.Normal}
                style={{
                  fontSize: 13,
                  lineHeight: 18,
                  marginLeft: 5,
                  color: themeContext.colors.description,
                }}>
                タスクのカスタム項目編集
              </Typography>
            </TouchableOpacity>
          </View>
        </PermissionSearchArea>
        {props.showMemberStatus && (
          <>
            <TouchableOpacity
              onPress={() => setShowStatusModal(!showStatusModal)}
              style={{ flexDirection: 'row', marginTop: 5 }}>
              <Typography
                variant={TypographyType.Normal}
                style={{ color: themeContext.colors.description, fontSize: 13 }}>
                ステータスで絞り込み
              </Typography>
              {showStatusModal ? (
                <CaretUpIcon size={14} onPress={() => setShowStatusModal(!showStatusModal)} />
              ) : (
                <CaretDownIcon size={14} onPress={() => setShowStatusModal(!showStatusModal)} />
              )}
            </TouchableOpacity>
            <StatusSearchArea show={showStatusModal}>
              <View style={{ paddingHorizontal: 10, marginTop: 5 }}>
                <TouchableOpacity
                  style={{ flexDirection: 'row' }}
                  onPress={() => setActiveStatus(!isActiveStatus)}>
                  <Checkbox
                    size={18}
                    color={themeContext.colors.primary}
                    value={isActiveStatus}
                    onValueChange={(value) => setActiveStatus(value)}
                  />
                  <Typography
                    variant={TypographyType.Normal}
                    style={{
                      fontSize: 13,
                      lineHeight: 18,
                      marginLeft: 5,
                      color: themeContext.colors.description,
                    }}>
                    有効:{' '}
                    {
                      props.members.filter((member) => member.memberStatus === MemberStatus.Active)
                        .length
                    }
                    名
                  </Typography>
                </TouchableOpacity>
              </View>
              <View style={{ paddingHorizontal: 10, marginTop: 5 }}>
                <TouchableOpacity
                  style={{ flexDirection: 'row' }}
                  onPress={() => setInactiveStatus(!isInactiveStatus)}>
                  <Checkbox
                    size={18}
                    color={themeContext.colors.primary}
                    value={isInactiveStatus}
                    onValueChange={(value) => setInactiveStatus(value)}
                  />
                  <Typography
                    variant={TypographyType.Normal}
                    style={{
                      fontSize: 13,
                      lineHeight: 18,
                      marginLeft: 5,
                      color: themeContext.colors.description,
                    }}>
                    無効:{' '}
                    {
                      props.members.filter(
                        (member) => member.memberStatus === MemberStatus.Inactive
                      ).length
                    }
                    名
                  </Typography>
                </TouchableOpacity>
              </View>
              <View style={{ paddingHorizontal: 10, marginTop: 5 }}>
                <TouchableOpacity
                  style={{ flexDirection: 'row' }}
                  onPress={() => setInvitingStatus(!isInvitingStatus)}>
                  <Checkbox
                    size={18}
                    color={themeContext.colors.primary}
                    value={isInvitingStatus}
                    onValueChange={(value) => setInvitingStatus(value)}
                  />
                  <Typography
                    variant={TypographyType.Normal}
                    style={{
                      fontSize: 13,
                      lineHeight: 18,
                      marginLeft: 5,
                      color: themeContext.colors.description,
                    }}>
                    招待中: {props.memberInvitations.length}名
                  </Typography>
                </TouchableOpacity>
              </View>
            </StatusSearchArea>
          </>
        )}
      </>
    </View>
  );
};

interface MemberOrInvitation {
  member?: Member;
  invitation?: MemberInvitation;
}

const MemberListWrapper = () => {
  return (
    <ResizableColumn
      cookieName="ORGANIZATION_MEMBER_LIST_WIDTH"
      defaultWidth={240}
      maxWidth={500}
      renderChild={(width) => {
        return <List />;
      }}
    />
  );
};

const InvitationListWrapper = () => {
  return (
    <ResizableColumn
      cookieName="ORGANIZATION_MEMBER_LIST_WIDTH"
      defaultWidth={240}
      maxWidth={500}
      renderChild={(width) => {
        return <InvitationLinkList />;
      }}
    />
  );
};

const List = React.memo(() => {
  const [searchMemberName, setSearchMemberName] = useState('');
  const [searchMemberNameLive, setSearchMemberNameLive] = useState('');
  const themeContext: IThemePart = useContext(ThemeContext);
  const history = useHistory();
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [showDialog, setShowDialog] = useState(false);
  const [showCannotOperateDialog, setShowCannotOperateDialog] = useState(false);
  const [permissionSearchCondition, setPermissionSearchCondition] = useState({
    isAdminRole: false,
    isLaborCostManagementRole: false,
    isSalesManagementRole: false,
    isMemberManagementRole: false,
    isClientManagementRole: false,
    isProxyWorkEditRole: false,
    isOrganizationReportRole: false,
    isOrganizationMemberViewRole: false,
    isTeamReportRole: false,
    isTeamMemberViewRole: false,
    isProjectCreatePermissionFlg: false,
    isProjectUpdatePermissionFlg: false,
    isProjectDeletePermissionFlg: false,
    isProjectCustomAttributePermissionFlg: false,
    isTaskCreatePermissionFlg: false,
    isTaskUpdatePermissionFlg: false,
    isTaskDeletePermissionFlg: false,
    isTaskCustomAttributePermissionFlg: false,
    isActiveStatus: false,
    isInactiveStatus: false,
    isInvitingStatus: false,
    enabledTwoFactorAuth: false,
    disabledTwoFactorAuth: false,
  });
  const { loading, data, error } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId!,
    },
  });
  const {
    loading: membersLoading,
    data: membersData,
    error: membersError,
  } = useOrganizationMembersQuery({
    variables: {
      organizationId: loginUser!.organizationId!,
    },
    fetchPolicy: 'network-only',
  });
  const { loading: invitationsLoagind, data: invitationsData } = useMemberInvitationsQuery({
    fetchPolicy: 'network-only',
  });

  const { loading: meLoading, data: meData } = useMeQuery({
    fetchPolicy: 'network-only',
  });

  const { loading: contractLoading, data: contractData } = useContractQuery({
    fetchPolicy: 'network-only',
  });
  const remainLicence = contractData?.contract?.licenceCount
    ? contractData.contract.licenceCount - contractData.contract.usingLicenceCount <= 0
    : false;

  const memberOrInviationList = useMemo(() => {
    if (membersLoading || invitationsLoagind) {
      return [];
    }
    return membersData!
      .organizationMembers!.map((member) => {
        return { member: member } as MemberOrInvitation;
      })
      .concat(
        invitationsData!.memberInvitations!.map((invitation) => {
          return { invitation: invitation } as MemberOrInvitation;
        })
      )
      .slice()
      .filter(
        (memberOrInvitation) =>
          (memberOrInvitation.member?.name &&
            memberOrInvitation.member!.name!.indexOf(searchMemberName) !== -1) ||
          (memberOrInvitation.member?.mailAddress &&
            memberOrInvitation.member!.mailAddress!.indexOf(searchMemberName) !== -1) ||
          (memberOrInvitation.invitation?.mailAddress &&
            memberOrInvitation.invitation!.mailAddress!.indexOf(searchMemberName) !== -1)
      )
      .filter((memberOrInvitation) => {
        let matchPermission = false;
        let matchStatus = false;
        if (
          permissionSearchCondition.isAdminRole ||
          permissionSearchCondition.isLaborCostManagementRole ||
          permissionSearchCondition.isSalesManagementRole ||
          permissionSearchCondition.isMemberManagementRole ||
          permissionSearchCondition.isClientManagementRole ||
          permissionSearchCondition.isProxyWorkEditRole ||
          permissionSearchCondition.isOrganizationReportRole ||
          permissionSearchCondition.isOrganizationMemberViewRole ||
          permissionSearchCondition.isTeamReportRole ||
          permissionSearchCondition.isTeamMemberViewRole ||
          permissionSearchCondition.isProjectCreatePermissionFlg ||
          permissionSearchCondition.isProjectUpdatePermissionFlg ||
          permissionSearchCondition.isProjectDeletePermissionFlg ||
          permissionSearchCondition.isProjectCustomAttributePermissionFlg ||
          permissionSearchCondition.isTaskCreatePermissionFlg ||
          permissionSearchCondition.isTaskUpdatePermissionFlg ||
          permissionSearchCondition.isTaskDeletePermissionFlg ||
          permissionSearchCondition.isTaskCustomAttributePermissionFlg
        ) {
          if (permissionSearchCondition.isAdminRole) {
            matchPermission =
              matchPermission ||
              !!(memberOrInvitation.member?.adminRole || memberOrInvitation.invitation?.adminRole);
          }
          if (permissionSearchCondition.isLaborCostManagementRole) {
            matchPermission =
              matchPermission ||
              !!(
                memberOrInvitation.member?.laborCostManagementRole ||
                memberOrInvitation.invitation?.laborCostManagementRole
              );
          }
          if (permissionSearchCondition.isSalesManagementRole) {
            matchPermission =
              matchPermission ||
              !!(
                memberOrInvitation.member?.salesManagementRole ||
                memberOrInvitation.invitation?.salesManagementRole
              );
          }
          if (permissionSearchCondition.isMemberManagementRole) {
            matchPermission =
              matchPermission ||
              !!(
                memberOrInvitation.member?.memberManagementRole ||
                memberOrInvitation.invitation?.memberManagementRole
              );
          }
          if (permissionSearchCondition.isClientManagementRole) {
            matchPermission =
              matchPermission ||
              !!(
                memberOrInvitation.member?.clientManagementRole ||
                memberOrInvitation.invitation?.clientManagementRole
              );
          }
          if (permissionSearchCondition.isProxyWorkEditRole) {
            matchPermission =
              matchPermission ||
              !!(
                memberOrInvitation.member?.proxyEditWorkingDataRole ||
                memberOrInvitation.invitation?.proxyEditWorkingDataRole
              );
          }
          if (permissionSearchCondition.isOrganizationReportRole) {
            matchPermission =
              matchPermission ||
              !!(
                memberOrInvitation.member?.organizationReportRole ||
                memberOrInvitation.invitation?.organizationReportRole
              );
          }
          if (permissionSearchCondition.isTeamReportRole) {
            matchPermission =
              matchPermission ||
              !!(
                memberOrInvitation.member?.teamReportRole ||
                memberOrInvitation.invitation?.teamReportRole
              );
          }
          if (permissionSearchCondition.isOrganizationMemberViewRole) {
            matchPermission =
              matchPermission ||
              !!(
                memberOrInvitation.member?.organizationMemberViewRole ||
                memberOrInvitation.invitation?.organizationMemberViewRole
              );
          }
          if (permissionSearchCondition.isTeamMemberViewRole) {
            matchPermission =
              matchPermission ||
              !!(
                memberOrInvitation.member?.teamMemberViewRole ||
                memberOrInvitation.invitation?.teamMemberViewRole
              );
          }
          if (permissionSearchCondition.isProjectCreatePermissionFlg) {
            matchPermission =
              matchPermission ||
              !!(
                memberOrInvitation.member?.projectCreatePermissionFlg ||
                memberOrInvitation.invitation?.projectCreatePermissionFlg
              );
          }
          if (permissionSearchCondition.isProjectUpdatePermissionFlg) {
            matchPermission =
              matchPermission ||
              !!(
                memberOrInvitation.member?.projectUpdatePermissionFlg ||
                memberOrInvitation.invitation?.projectUpdatePermissionFlg
              );
          }
          if (permissionSearchCondition.isProjectDeletePermissionFlg) {
            matchPermission =
              matchPermission ||
              !!(
                memberOrInvitation.member?.projectDeletePermissionFlg ||
                memberOrInvitation.invitation?.projectDeletePermissionFlg
              );
          }
          if (permissionSearchCondition.isProjectCustomAttributePermissionFlg) {
            matchPermission =
              matchPermission ||
              !!(
                memberOrInvitation.member?.projectCustomAttributePermissionFlg ||
                memberOrInvitation.invitation?.projectCustomAttributePermissionFlg
              );
          }
          if (permissionSearchCondition.isTaskCreatePermissionFlg) {
            matchPermission =
              matchPermission ||
              !!(
                memberOrInvitation.member?.taskCreatePermissionFlg ||
                memberOrInvitation.invitation?.taskCreatePermissionFlg
              );
          }
          if (permissionSearchCondition.isTaskUpdatePermissionFlg) {
            matchPermission =
              matchPermission ||
              !!(
                memberOrInvitation.member?.taskUpdatePermissionFlg ||
                memberOrInvitation.invitation?.taskUpdatePermissionFlg
              );
          }
          if (permissionSearchCondition.isTaskDeletePermissionFlg) {
            matchPermission =
              matchPermission ||
              !!(
                memberOrInvitation.member?.taskDeletePermissionFlg ||
                memberOrInvitation.invitation?.taskDeletePermissionFlg
              );
          }
          if (permissionSearchCondition.isTaskCustomAttributePermissionFlg) {
            matchPermission =
              matchPermission ||
              !!(
                memberOrInvitation.member?.taskCustomAttributePermissionFlg ||
                memberOrInvitation.invitation?.taskCustomAttributePermissionFlg
              );
          }
        } else {
          matchPermission = true;
        }

        if (
          permissionSearchCondition.isActiveStatus ||
          permissionSearchCondition.isInactiveStatus ||
          permissionSearchCondition.isInvitingStatus
        ) {
          matchStatus =
            matchStatus ||
            !!(
              (permissionSearchCondition.isActiveStatus &&
                memberOrInvitation.member?.memberStatus === MemberStatus.Active) ||
              (permissionSearchCondition.isInactiveStatus &&
                memberOrInvitation.member?.memberStatus === MemberStatus.Inactive) ||
              (permissionSearchCondition.isInvitingStatus && !!memberOrInvitation.invitation)
            );
        } else {
          matchStatus = true;
        }
        return matchPermission && matchStatus;
      })
      .sort((a, b) => {
        const aDisplayName = a.member?.name || a.member?.mailAddress || a!.invitation?.mailAddress;
        const bDisplayName = a.member?.name || a.member?.mailAddress || a!.invitation?.mailAddress;
        return (aDisplayName as any) - (bDisplayName as any);
      });
  }, [
    membersLoading,
    invitationsLoagind,
    membersData?.organizationMembers,
    invitationsData?.memberInvitations,
    searchMemberName,
    permissionSearchCondition,
  ]);

  if (loading || membersLoading || invitationsLoagind || meLoading) {
    return <></>;
  }

  return (
    <View
      style={{
        zIndex: 1,
        borderLeftWidth: 1,
        borderRadius: 20,
        borderColor: themeContext.colors.separator,
      }}>
      <View
        style={{
          backgroundColor: ColorUtil.lignten(themeContext.colors.header, 5),
          paddingVertical: 7,
          paddingHorizontal: 3,
        }}>
        <Typography
          variant={TypographyType.SubTitle}
          ellipsis={true}
          style={{ color: '#FFFFFF', fontSize: 16, fontWeight: '600' }}>
          メンバー一覧
        </Typography>
      </View>
      <View style={{ borderBottomWidth: 1, borderColor: themeContext.colors.separator }}>
        <Form>
          <View style={{ flexDirection: 'column' }}>
            <Input
              name={'searchMember'}
              label={''}
              icon={<SearchIcon size={20} containerStyle={{ marginLeft: 10 }} />}
              containerStyle={{ marginVertical: 10 }}
              inputstyle={{ borderWidth: 0 }}
              inputContainerStyle={{
                marginHorizontal: 10,
                backgroundColor: themeContext.colors.baseColor,
                shadowOffset: {
                  width: -1,
                  height: -1,
                },
                shadowOpacity: 0.1,
                elevation: 2,
                borderWidth: 1,
                borderRadius: 20,
                borderColor: themeContext.colors.separator,
              }}
              onChange={(value: string) => {
                setSearchMemberNameLive(value);
                if (value?.trim() === '') {
                  setSearchMemberName('');
                }
              }}
              onPressEnterIncludeIMEConvert={(value: string) => setSearchMemberName(value)}
              onBlur={(value: string) => setSearchMemberName(value)}
            />
            {searchMemberNameLive?.trim().length > 0 && (
              <Typography
                variant={TypographyType.Description}
                style={{ marginLeft: 10, fontSize: 12, textAlign: 'center', marginBottom: 10 }}>
                Enterキーを押すと絞り込みが行われます
              </Typography>
            )}
            <SearchContainer
              searchCondition={permissionSearchCondition}
              setSearchCondition={setPermissionSearchCondition}
              showMemberStatus={true}
              showTwoFactorAuth={true}
              members={membersData!.organizationMembers as Member[]}
              memberInvitations={invitationsData!.memberInvitations as MemberInvitation[]}
            />
          </View>
        </Form>
        <MemberAddButton
          style={{
            display: 'flex',
            flexDirection: 'row',
            paddingBottom: 10,
            paddingHorizontal: 5,
          }}
          onPress={async () => {
            if (!meData!.me!.adminRole && !meData?.me!.memberManagementRole) {
              setShowCannotOperateDialog(true);
              return;
            }
            setShowDialog(true);
          }}>
          <PlusIcon
            size={12}
            containerStyle={{ marginLeft: 10 }}
            onPress={async () => {
              if (!meData!.me!.adminRole && !meData?.me!.memberManagementRole) {
                setShowCannotOperateDialog(true);
                return;
              }
              setShowDialog(true);
            }}>
            <Typography
              variant={TypographyType.Normal}
              style={{ fontSize: 12, color: themeContext.colors.description }}>
              新しいメンバーを招待する
            </Typography>
          </PlusIcon>
        </MemberAddButton>
        <Modal
          title={'メンバーを招待できません'}
          isShow={showCannotOperateDialog}
          onClose={() => {
            setShowCannotOperateDialog(false);
          }}>
          <Typography
            variant={TypographyType.Normal}
            style={{ textAlign: 'center', marginVertical: 10 }}>
            メンバーを招待するには、管理者権限かメンバー管理権限が必要です。
          </Typography>
          <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
            <Button
              text={'OK'}
              style={{ minWidth: 100, marginRight: 10, marginVertical: 20 }}
              onPress={async () => {
                setShowCannotOperateDialog(false);
              }}
            />
          </View>
        </Modal>
      </View>

      <GlobalDragContextProvider>
        <VirtualizedFlatList
          style={{ height: 'calc(100vh - 280px)' }}
          items={memberOrInviationList}
          renderItem={(item, index) => {
            return (
              <MemberItem>
                <View style={{ flexDirection: 'column' }}>
                  <Typography
                    variant={TypographyType.Normal}
                    ellipsis={true}
                    style={{
                      fontSize: 13,
                      color: themeContext.colors.textColor,
                      textAlign: 'left',
                      lineHeight: 17,
                    }}>
                    {(item as MemberOrInvitation).member?.name ||
                      (item as MemberOrInvitation).member?.mailAddress ||
                      (item as MemberOrInvitation).invitation?.mailAddress}
                  </Typography>
                  {(item as MemberOrInvitation).member && (
                    <Typography
                      variant={TypographyType.Normal}
                      ellipsis={true}
                      style={{
                        fontSize: 10,
                        color: themeContext.colors.description,
                        textAlign: 'left',
                        lineHeight: 12,
                      }}>
                      {(item as MemberOrInvitation).member?.mailAddress}
                    </Typography>
                  )}
                </View>
              </MemberItem>
            );
          }}
          getKey={(memberOrInviation: any) =>
            ((memberOrInviation as MemberOrInvitation).member?.id ||
              (memberOrInviation as MemberOrInvitation).invitation?.invitationKey)!.toString()
          }
          itemHeight={40}
          onPress={(memberOrInviation: any) => {
            if ((memberOrInviation as MemberOrInvitation).member) {
              if (meData!.me!.memberManagementRole) {
                history.push(
                  `/app/${loginUser!.organizationId}/members/list/member/${
                    (memberOrInviation as MemberOrInvitation).member!.id
                  }/permission/`
                );
                return;
              }
              if (meData!.me!.laborCostManagementRole) {
                history.push(
                  `/app/${loginUser!.organizationId}/members/list/member/${
                    (memberOrInviation as MemberOrInvitation).member!.id
                  }/labor-cost/`
                );
                return;
              }
            } else {
              if (meData!.me!.memberManagementRole) {
                history.push(
                  `/app/${loginUser!.organizationId}/members/list/invitation/${
                    (memberOrInviation as MemberOrInvitation).invitation?.invitationKey
                  }/permission/`
                );
                return;
              }
              if (meData!.me!.laborCostManagementRole) {
                history.push(
                  `/app/${loginUser!.organizationId}/members/list/invitation/${
                    (memberOrInviation as MemberOrInvitation).invitation?.invitationKey
                  }/labor-cost/`
                );
                return;
              }
            }
          }}
        />
      </GlobalDragContextProvider>
      <Modal
        title={'新しくメンバーを招待する'}
        isShow={showDialog}
        onClose={() => {
          setShowDialog(false);
        }}>
        {remainLicence ? (
          <LicenceLimitModal closeModal={() => setShowDialog(false)} />
        ) : (
          <RegiserMemberDialog
            organization={data!.organization!}
            members={membersData!.organizationMembers! as Member[]}
            invitations={invitationsData!.memberInvitations! as MemberInvitation[]}
            onComplete={(memberInvitation) => {
              setShowDialog(false);
              history.push(
                `/app/${loginUser!.organizationId}/members/list/invitation/${
                  memberInvitation.invitationKey
                }/`
              );
            }}
            onCancel={() => {
              setShowDialog(false);
            }}
          />
        )}
      </Modal>
    </View>
  );
});

const InvitationLinkList = React.memo(() => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const history = useHistory();
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [showDialog, setShowDialog] = useState(false);
  const [showCannotOperateDialog, setShowCannotOperateDialog] = useState(false);
  const [permissionSearchCondition, setPermissionSearchCondition] =
    useState<PermissionSearchCondition>({
      isAdminRole: false,
      isLaborCostManagementRole: false,
      isSalesManagementRole: false,
      isMemberManagementRole: false,
      isClientManagementRole: false,
      isProxyEditWorkDataRole: false,
      isOrganizationReportRole: false,
      isOrganizationMemberViewRole: false,
      isTeamReportRole: false,
      isTeamMemberViewRole: false,
      isProjectCreatePermissionFlg: false,
      isProjectUpdatePermissionFlg: false,
      isProjectDeletePermissionFlg: false,
      isProjectCustomAttributePermissionFlg: false,
      isTaskCreatePermissionFlg: false,
      isTaskUpdatePermissionFlg: false,
      isTaskDeletePermissionFlg: false,
      isTaskCustomAttributePermissionFlg: false,
      isActiveStatus: false,
      isInactiveStatus: false,
      isInvitingStatus: false,
      enabledTwoFactorAuth: false,
      disabledTwoFactorAuth: false,
    });
  const { loading, data, error } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId!,
    },
  });
  const {
    loading: inviteLinksLoading,
    data: inviteLinksData,
    error: inviteLinksError,
  } = useMemberInvitationLinksQuery({
    fetchPolicy: 'network-only',
  });

  const { loading: meLoading, data: meData } = useMeQuery({
    fetchPolicy: 'network-only',
  });

  const { loading: contractLoading, data: contractData } = useContractQuery({
    fetchPolicy: 'network-only',
  });
  const remainLicence = contractData?.contract?.licenceCount
    ? contractData.contract.licenceCount - contractData.contract.usingLicenceCount <= 0
    : false;

  const inviationLinkList = useMemo(() => {
    if (inviteLinksLoading) {
      return [];
    }
    return inviteLinksData!
      .memberInvitationLinks!.slice()
      .filter((invitationLink) => {
        let result = false;
        if (
          permissionSearchCondition.isAdminRole ||
          permissionSearchCondition.isLaborCostManagementRole ||
          permissionSearchCondition.isSalesManagementRole ||
          permissionSearchCondition.isMemberManagementRole ||
          permissionSearchCondition.isClientManagementRole ||
          permissionSearchCondition.isProxyEditWorkDataRole ||
          permissionSearchCondition.isOrganizationReportRole ||
          permissionSearchCondition.isOrganizationMemberViewRole ||
          permissionSearchCondition.isTeamReportRole ||
          permissionSearchCondition.isTeamMemberViewRole ||
          permissionSearchCondition.isProjectCreatePermissionFlg ||
          permissionSearchCondition.isProjectUpdatePermissionFlg ||
          permissionSearchCondition.isProjectDeletePermissionFlg ||
          permissionSearchCondition.isProjectCustomAttributePermissionFlg ||
          permissionSearchCondition.isTaskCreatePermissionFlg ||
          permissionSearchCondition.isTaskUpdatePermissionFlg ||
          permissionSearchCondition.isTaskDeletePermissionFlg ||
          permissionSearchCondition.isTaskCustomAttributePermissionFlg
        ) {
          if (permissionSearchCondition.isAdminRole) {
            result = false;
          }
          if (permissionSearchCondition.isLaborCostManagementRole) {
            result = false;
          }
          if (permissionSearchCondition.isSalesManagementRole) {
            result = result || false;
          }
          if (permissionSearchCondition.isMemberManagementRole) {
            result = false;
          }
          if (permissionSearchCondition.isClientManagementRole) {
            result = result || invitationLink!.clientManagementRole;
          }
          if (permissionSearchCondition.isProxyEditWorkDataRole) {
            result = result || invitationLink!.proxyEditWorkingDataRole;
          }
          if (permissionSearchCondition.isOrganizationReportRole) {
            result = result || invitationLink!.organizationReportRole;
          }
          if (permissionSearchCondition.isTeamReportRole) {
            result = result || invitationLink!.teamReportRole;
          }
          if (permissionSearchCondition.isOrganizationMemberViewRole) {
            result = result || invitationLink!.organizationMemberViewRole;
          }
          if (permissionSearchCondition.isTeamMemberViewRole) {
            result = result || invitationLink!.teamMemberViewRole;
          }
          if (permissionSearchCondition.isProjectCreatePermissionFlg) {
            result = result || invitationLink!.projectCreatePermissionFlg;
          }
          if (permissionSearchCondition.isProjectUpdatePermissionFlg) {
            result = result || invitationLink!.projectUpdatePermissionFlg;
          }
          if (permissionSearchCondition.isProjectDeletePermissionFlg) {
            result = result || invitationLink!.projectDeletePermissionFlg;
          }
          if (permissionSearchCondition.isProjectCustomAttributePermissionFlg) {
            result = result || invitationLink!.projectCustomAttributePermissionFlg;
          }
          if (permissionSearchCondition.isTaskCreatePermissionFlg) {
            result = result || invitationLink!.taskCreatePermissionFlg;
          }
          if (permissionSearchCondition.isTaskUpdatePermissionFlg) {
            result = result || invitationLink!.taskUpdatePermissionFlg;
          }
          if (permissionSearchCondition.isTaskDeletePermissionFlg) {
            result = result || invitationLink!.taskDeletePermissionFlg;
          }
          if (permissionSearchCondition.isTaskCustomAttributePermissionFlg) {
            result = result || invitationLink!.taskCustomAttributePermissionFlg;
          }
        } else {
          result = true;
        }
        return result;
      })
      .sort((a, b) => {
        return new Date(b!.createDateTime).getTime() - new Date(a!.createDateTime).getTime();
      });
  }, [inviteLinksLoading, inviteLinksData?.memberInvitationLinks, permissionSearchCondition]);

  if (loading || inviteLinksLoading || meLoading) {
    return <></>;
  }

  return (
    <View
      style={{
        zIndex: 1,
        borderLeftWidth: 1,
        borderRadius: 20,
        borderColor: themeContext.colors.separator,
      }}>
      <View
        style={{
          backgroundColor: themeContext.colors.header,
          paddingVertical: 7,
          paddingHorizontal: 3,
        }}>
        <Typography
          variant={TypographyType.SubTitle}
          ellipsis={true}
          style={{ color: '#FFFFFF', fontSize: 16, fontWeight: '600' }}>
          招待リンク一覧
        </Typography>
      </View>
      <View
        style={{ borderBottomWidth: 1, borderColor: themeContext.colors.separator, marginTop: 10 }}>
        <Form>
          <View style={{ flexDirection: 'column' }}>
            <SearchContainer
              searchCondition={permissionSearchCondition}
              setSearchCondition={setPermissionSearchCondition}
              showMemberStatus={false}
              showTwoFactorAuth={false}
              members={[]}
              memberInvitations={[]}
            />
          </View>
        </Form>
        <MemberAddButton
          style={{
            display: 'flex',
            flexDirection: 'row',
            paddingBottom: 10,
            paddingHorizontal: 5,
          }}
          onPress={async () => {
            if (!meData!.me!.adminRole && !meData?.me!.memberManagementRole) {
              setShowCannotOperateDialog(true);
              return;
            }
            setShowDialog(true);
          }}>
          <PlusIcon
            size={12}
            containerStyle={{ marginLeft: 10, maxWidth: '100%' }}
            onPress={async () => {
              if (!meData!.me!.adminRole && !meData?.me!.memberManagementRole) {
                setShowCannotOperateDialog(true);
                return;
              }
              setShowDialog(true);
            }}>
            <Typography
              variant={TypographyType.Normal}
              style={{ fontSize: 12, color: themeContext.colors.description }}
              ellipsis>
              新しい招待リンクを作成する
            </Typography>
          </PlusIcon>
        </MemberAddButton>
        <Modal
          title={'メンバーを招待できません'}
          isShow={showCannotOperateDialog}
          onClose={() => {
            setShowCannotOperateDialog(false);
          }}>
          <Typography
            variant={TypographyType.Normal}
            style={{ textAlign: 'center', marginVertical: 10 }}>
            メンバーを招待するには、管理者権限かメンバー管理権限が必要です。
          </Typography>
          <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
            <Button
              text={'OK'}
              style={{ minWidth: 100, marginRight: 10, marginVertical: 20 }}
              onPress={async () => {
                setShowCannotOperateDialog(false);
              }}
            />
          </View>
        </Modal>
      </View>

      <GlobalDragContextProvider>
        <VirtualizedFlatList
          style={{ height: 'calc(100vh - 190px)' }}
          items={inviationLinkList}
          renderItem={(item, index) => {
            return (
              <MemberItem>
                <View style={{ flexDirection: 'column', maxWidth: '100%' }}>
                  <Typography
                    variant={TypographyType.Normal}
                    ellipsis={true}
                    style={{
                      fontSize: 13,
                      color: themeContext.colors.textColor,
                      textAlign: 'left',
                      lineHeight: 17,
                    }}>
                    {(item as MemberInvitationLink).memo || '（メモなし）'}
                  </Typography>
                </View>
              </MemberItem>
            );
          }}
          getKey={(item: any) => (item as MemberInvitationLink).invitationKey}
          itemHeight={40}
          onPress={(item: any) => {
            history.push(
              `/app/${loginUser!.organizationId}/members/invitation-links/${
                (item as MemberInvitationLink).invitationKey
              }/`
            );
          }}
        />
      </GlobalDragContextProvider>

      {remainLicence ? (
        <Modal
          title={'新しくメンバーを招待する'}
          isShow={showDialog}
          onClose={() => {
            setShowDialog(false);
          }}>
          <LicenceLimitModal closeModal={() => setShowDialog(false)} />
        </Modal>
      ) : (
        <Modal
          title={'新しく招待リンクを作成する'}
          isShow={showDialog}
          onClose={() => {
            setShowDialog(false);
          }}>
          <RegisterInvitationLinkDialog
            organization={data!.organization!}
            onComplete={(invitationLink) => {
              setShowDialog(false);
              history.push(
                `/app/${loginUser!.organizationId}/members/invitation-links/${
                  invitationLink.invitationKey
                }/`
              );
            }}
            onCancel={() => {
              setShowDialog(false);
            }}
          />
        </Modal>
      )}
    </View>
  );
});

interface IUpdateToActiveMemberDialogProps {
  member: Member;
  onComplete: () => void;
  onCancel: () => void;
}

const UpdateToActiveMemberDialog = (props: IUpdateToActiveMemberDialogProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, __] = useContext(LoginUserContext);
  const [updateToActive, _] = useUpdateToActiveMutation({
    variables: {
      id: props.member.id!,
      input: {
        versionNo: props.member.versionNo,
      },
    },
    refetchQueries: [
      {
        query: OrganizationMembersDocument,
        variables: {
          organizationId: loginUser!.organizationId,
        },
      },
    ],
  });

  return (
    <Form>
      <View style={{ paddingTop: 10, paddingBottom: 30, paddingHorizontal: 20 }}>
        <Typography variant={TypographyType.Normal} style={{ textAlign: 'center' }}>
          {`${props.member.name}さんのアカウントを${'\n'}有効にしますか？`}
        </Typography>
      </View>
      <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
        <Button
          text="有効にする"
          completeText="有効にする"
          style={{ height: 30, marginRight: 5 }}
          textStyle={{ fontSize: 14 }}
          onPress={async () => {
            await updateToActive();
            props.onComplete();
          }}
        />
        <Button
          text="キャンセル"
          style={{ height: 30, backgroundColor: 'transparent' }}
          textStyle={{ fontSize: 14, color: themeContext.colors.primary }}
          onPress={async () => {
            props.onCancel();
          }}
          disableValidate={true}
        />
      </View>
    </Form>
  );
};

interface IUpdateToInactiveMemberDialogProps {
  member: Member;
  onComplete: () => void;
  onCancel: () => void;
}

const UpdateToInactiveMemberDialog = (props: IUpdateToInactiveMemberDialogProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, __] = useContext(LoginUserContext);
  const [updateToInactive, _] = useUpdateToInactiveMutation({
    variables: {
      id: props.member.id!,
      input: {
        versionNo: props.member.versionNo,
      },
    },
    refetchQueries: [
      {
        query: OrganizationMembersDocument,
        variables: {
          organizationId: loginUser!.organizationId,
        },
      },
    ],
  });
  const { loading, data } = useExistAdminMemberQuery({
    variables: {
      excludeMemberId: props.member.id!,
    },
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <></>;
  }

  return (
    <Form>
      <View style={{ paddingTop: 10, paddingHorizontal: 20 }}>
        <Typography variant={TypographyType.Normal} style={{ textAlign: 'center' }}>
          {`${props.member.name}さんのアカウントを${'\n'}無効にしますか？`}
        </Typography>
      </View>
      <View style={{ paddingTop: 10, paddingBottom: 20, paddingHorizontal: 20 }}>
        <Typography variant={TypographyType.Description} style={{ textAlign: 'center' }}>
          {`アカウントを無効にすると、このユーザーはログインが出来なくなります。${'\n'}あとから有効に復活させることも出来ます。`}
        </Typography>
      </View>
      <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
        <Button
          text="無効にする"
          completeText="無効にする"
          style={{ height: 30, marginRight: 5 }}
          textStyle={{ fontSize: 14 }}
          onPress={async () => {
            if (!data!.existAdminMember! && props.member.adminRole) {
              window.alert('管理者がいなくなってしまうため、このユーザーは無効に出来ません。');
              return;
            }
            await updateToInactive();
            props.onComplete();
          }}
        />
        <Button
          text="キャンセル"
          style={{ height: 30, backgroundColor: 'transparent' }}
          textStyle={{ fontSize: 14, color: themeContext.colors.primary }}
          onPress={async () => {
            props.onCancel();
          }}
          disableValidate={true}
        />
      </View>
    </Form>
  );
};

interface IActiveMenuProps {
  member: Member;
  me: Member;
}

const ActiveMenu = (props: IActiveMenuProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [showActiveModal, setShowActiveModal] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);

  return (
    <>
      <TouchableOpacity style={{ marginTop: 20 }} onPress={() => setShowActiveModal(true)}>
        <Typography
          variant={TypographyType.Normal}
          style={{ color: themeContext.colors.link, fontSize: 13 }}>
          アカウントを有効にする
        </Typography>
      </TouchableOpacity>
      <Modal
        title={'アカウントを有効にする'}
        isShow={showActiveModal}
        onClose={() => {
          if (!props.me.adminRole && !props.me.memberManagementRole) {
            setShowAlertModal(true);
            return;
          }
          setShowActiveModal(false);
        }}>
        <UpdateToActiveMemberDialog
          member={props.member!}
          onComplete={() => {
            setShowActiveModal(false);
          }}
          onCancel={() => {
            setShowActiveModal(false);
          }}
        />
      </Modal>
      <Modal
        title={'アカウントを有効に出来ません'}
        isShow={showAlertModal}
        onClose={() => {
          setShowAlertModal(false);
        }}>
        <Typography
          variant={TypographyType.Normal}
          style={{ textAlign: 'center', marginVertical: 10 }}>
          アカウントを有効にする操作は、管理者かメンバー管理権限が必要です。
        </Typography>
        <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
          <Button
            text={'OK'}
            style={{ minWidth: 100, marginRight: 10, marginVertical: 20 }}
            onPress={async () => {
              setShowAlertModal(false);
            }}
          />
        </View>
      </Modal>
    </>
  );
};

interface IInactiveMenuProps {
  member: Member;
  me: Member;
}

const InactiveMenu = (props: IInactiveMenuProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [showInactiveModal, setShowInactiveModal] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [showCanNotInactiveAdminModal, setShowCanNotInactiveAdminModal] = useState(false);

  return (
    <>
      <TouchableOpacity
        style={{ marginTop: 20 }}
        onPress={() => {
          if (!props.me.adminRole && !props.me.memberManagementRole) {
            setShowAlertModal(true);
            return;
          }

          // 管理者以外は、管理者を無効にはできない
          if (props.member.adminRole && !props.me.adminRole) {
            setShowCanNotInactiveAdminModal(true);
            return;
          }
          setShowInactiveModal(true);
        }}>
        <Typography
          variant={TypographyType.Normal}
          style={{ color: themeContext.colors.link, fontSize: 13 }}>
          アカウントを無効にする
        </Typography>
      </TouchableOpacity>
      <Modal
        title={'アカウントを無効にする'}
        isShow={showInactiveModal}
        onClose={() => {
          setShowInactiveModal(false);
        }}>
        <UpdateToInactiveMemberDialog
          member={props.member!}
          onComplete={() => {
            setShowInactiveModal(false);
          }}
          onCancel={() => {
            setShowInactiveModal(false);
          }}
        />
      </Modal>
      <Modal
        title={'アカウントを無効に出来ません'}
        isShow={showAlertModal}
        onClose={() => {
          setShowAlertModal(false);
        }}>
        <Typography
          variant={TypographyType.Normal}
          style={{ textAlign: 'center', marginVertical: 10 }}>
          アカウントを無効にする操作は、管理者かメンバー管理権限が必要です。
        </Typography>
        <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
          <Button
            text={'OK'}
            style={{ minWidth: 100, marginRight: 10, marginVertical: 20 }}
            onPress={async () => {
              setShowAlertModal(false);
            }}
          />
        </View>
      </Modal>
      <Modal
        title={'アカウントを無効に出来ません'}
        isShow={showCanNotInactiveAdminModal}
        onClose={() => {
          setShowCanNotInactiveAdminModal(false);
        }}>
        <Typography
          variant={TypographyType.Normal}
          style={{ textAlign: 'center', marginVertical: 10 }}>
          管理者のアカウントを無効にするには、管理者権限が必要です。
        </Typography>
        <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
          <Button
            text={'OK'}
            style={{ minWidth: 100, marginRight: 10, marginVertical: 20 }}
            onPress={async () => {
              setShowCanNotInactiveAdminModal(false);
            }}
          />
        </View>
      </Modal>
    </>
  );
};

interface IDepartmentModalProps {
  closeModal: () => void;
  member: Member;
}

const DepartmentModal = (props: IDepartmentModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [department, setDepartment] = useState(props!.member!.department);

  const [updateMemberProfile] = useUpdateMemberProfileMutation({
    variables: {
      input: {
        id: props.member.id!,
        name: props.member.name!,
        department: department,
        employeeNumber: props.member.employeeNumber,
        mailAddress: props.member!.mailAddress,
        versionNo: props.member.versionNo,
      },
    },
  });

  return (
    <Form style={{ marginTop: 10 }}>
      <Input
        name={'department'}
        label={'所属部署名・チーム名'}
        initialValue={department ?? ''}
        onChange={setDepartment}
        validate={{
          maxLength: {
            value: 50,
            message: '50文字以内で入力してください',
          },
        }}
      />
      <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1 }}>
        <Button
          text={'変更する'}
          style={{
            minWidth: 100,
            marginRight: 10,
          }}
          onPress={async () => {
            await updateMemberProfile();
            props.closeModal();
          }}
        />
        <Button
          text={'キャンセル'}
          style={{
            minWidth: 100,
            marginRight: 10,
            backgroundColor: 'transparent',
          }}
          textStyle={{ color: themeContext.colors.primary }}
          disableValidate={true}
          onPress={async () => {
            props.closeModal();
          }}
        />
      </View>
    </Form>
  );
};

interface IInvitationDepartmentModalProps {
  closeModal: () => void;
  invitation: MemberInvitation;
}

const InvitationDepartmentModal = (props: IInvitationDepartmentModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [department, setDepartment] = useState(props!.invitation!.department);

  const [updateMemberProfile] = useUpdateInvitationMutation({
    variables: {
      id: props.invitation.id!,
      input: {
        name: props.invitation.name,
        department: department,
        employeeNumber: props.invitation.employeeNumber,
        adminRole: props.invitation.adminRole,
        laborCostManagementRole: props.invitation.laborCostManagementRole,
        salesManagementRole: props.invitation.salesManagementRole,
        memberManagementRole: props.invitation.memberManagementRole,
        clientManagementRole: props.invitation.clientManagementRole,
        organizationReportRole: props.invitation.organizationReportRole,
        teamReportRole: props.invitation.teamReportRole,
        organizationMemberViewRole: props.invitation.organizationMemberViewRole,
        teamMemberViewRole: props.invitation.teamMemberViewRole,
        projectCreatePermissionFlg: props.invitation.projectCreatePermissionFlg,
        projectUpdatePermissionFlg: props.invitation.projectUpdatePermissionFlg,
        projectDeletePermissionFlg: props.invitation.projectDeletePermissionFlg,
        projectCustomAttributePermissionFlg: props.invitation.projectCustomAttributePermissionFlg,
        taskCreatePermissionFlg: props.invitation.taskCreatePermissionFlg,
        taskUpdatePermissionFlg: props.invitation.taskUpdatePermissionFlg,
        taskDeletePermissionFlg: props.invitation.taskDeletePermissionFlg,
        taskCustomAttributePermissionFlg: props.invitation.taskCustomAttributePermissionFlg,
        proxyEditWorkingDataRole: props.invitation.proxyEditWorkingDataRole,
        versionNo: props.invitation.versionNo,
      },
    },
  });

  return (
    <Form style={{ marginTop: 10 }}>
      <Input
        name={'department'}
        label={'所属部署名・チーム名'}
        initialValue={department ?? ''}
        onChange={setDepartment}
        validate={{
          maxLength: {
            value: 50,
            message: '50文字以内で入力してください',
          },
        }}
      />
      <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1 }}>
        <Button
          text={'変更する'}
          style={{
            minWidth: 100,
            marginRight: 10,
          }}
          onPress={async () => {
            await updateMemberProfile();
            props.closeModal();
          }}
        />
        <Button
          text={'キャンセル'}
          style={{
            minWidth: 100,
            marginRight: 10,
            backgroundColor: 'transparent',
          }}
          textStyle={{ color: themeContext.colors.primary }}
          disableValidate={true}
          onPress={async () => {
            props.closeModal();
          }}
        />
      </View>
    </Form>
  );
};

interface IEmployeeNumberModalProps {
  closeModal: () => void;
  member: Member;
}

const EmployeeModal = (props: IEmployeeNumberModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [employeeNumber, setEmployeeNumber] = useState(props!.member!.employeeNumber);

  const [updateMemberProfile] = useUpdateMemberProfileMutation({
    variables: {
      input: {
        id: props.member.id!,
        name: props.member.name!,
        department: props.member.department,
        employeeNumber: employeeNumber,
        mailAddress: props.member!.mailAddress,
        versionNo: props.member.versionNo,
      },
    },
  });

  return (
    <Form style={{ marginTop: 10 }}>
      <Input
        name={'employeeNumber'}
        label={'従業員番号'}
        initialValue={employeeNumber ?? ''}
        onChange={setEmployeeNumber}
        validate={{
          maxLength: {
            value: 20,
            message: '20文字以内で入力してください',
          },
        }}
      />
      <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1 }}>
        <Button
          text={'変更する'}
          style={{
            minWidth: 100,
            marginRight: 10,
          }}
          onPress={async () => {
            await updateMemberProfile();
            props.closeModal();
          }}
        />
        <Button
          text={'キャンセル'}
          style={{
            minWidth: 100,
            marginRight: 10,
            backgroundColor: 'transparent',
          }}
          textStyle={{ color: themeContext.colors.primary }}
          disableValidate={true}
          onPress={async () => {
            props.closeModal();
          }}
        />
      </View>
    </Form>
  );
};

interface IInvitationEmployeeNumberModalProps {
  closeModal: () => void;
  invitation: MemberInvitation;
}

const InvitationEmployeeModal = (props: IInvitationEmployeeNumberModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [employeeNumber, setEmployeeNumber] = useState(props!.invitation!.employeeNumber);

  const [updateMemberProfile] = useUpdateInvitationMutation({
    variables: {
      id: props.invitation.id!,
      input: {
        name: props.invitation.name,
        department: props.invitation.department,
        employeeNumber: employeeNumber,
        adminRole: props.invitation.adminRole,
        laborCostManagementRole: props.invitation.laborCostManagementRole,
        salesManagementRole: props.invitation.salesManagementRole,
        memberManagementRole: props.invitation.memberManagementRole,
        clientManagementRole: props.invitation.clientManagementRole,
        organizationReportRole: props.invitation.organizationReportRole,
        teamReportRole: props.invitation.teamReportRole,
        organizationMemberViewRole: props.invitation.organizationMemberViewRole,
        teamMemberViewRole: props.invitation.teamMemberViewRole,
        projectCreatePermissionFlg: props.invitation.projectCreatePermissionFlg,
        projectUpdatePermissionFlg: props.invitation.projectUpdatePermissionFlg,
        projectDeletePermissionFlg: props.invitation.projectDeletePermissionFlg,
        projectCustomAttributePermissionFlg: props.invitation.projectCustomAttributePermissionFlg,
        taskCreatePermissionFlg: props.invitation.taskCreatePermissionFlg,
        taskUpdatePermissionFlg: props.invitation.taskUpdatePermissionFlg,
        taskDeletePermissionFlg: props.invitation.taskDeletePermissionFlg,
        taskCustomAttributePermissionFlg: props.invitation.taskCustomAttributePermissionFlg,
        proxyEditWorkingDataRole: props.invitation.proxyEditWorkingDataRole,
        versionNo: props.invitation.versionNo,
      },
    },
  });

  return (
    <Form style={{ marginTop: 10 }}>
      <Input
        name={'employeeNumber'}
        label={'従業員番号'}
        initialValue={employeeNumber ?? ''}
        onChange={setEmployeeNumber}
        validate={{
          maxLength: {
            value: 20,
            message: '20文字以内で入力してください',
          },
        }}
      />
      <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1 }}>
        <Button
          text={'変更する'}
          style={{
            minWidth: 100,
            marginRight: 10,
          }}
          onPress={async () => {
            await updateMemberProfile();
            props.closeModal();
          }}
        />
        <Button
          text={'キャンセル'}
          style={{
            minWidth: 100,
            marginRight: 10,
            backgroundColor: 'transparent',
          }}
          textStyle={{ color: themeContext.colors.primary }}
          disableValidate={true}
          onPress={async () => {
            props.closeModal();
          }}
        />
      </View>
    </Form>
  );
};

interface INameModalProps {
  closeModal: () => void;
  member: Member;
}

const NameModal = (props: INameModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [name, setName] = useState(props!.member!.name!);

  const [updateMemberProfile] = useUpdateMemberProfileMutation({
    variables: {
      input: {
        id: props.member.id!,
        name: name,
        department: props.member.department,
        employeeNumber: props.member.employeeNumber,
        mailAddress: props.member!.mailAddress,
        versionNo: props.member.versionNo,
      },
    },
  });

  return (
    <Form style={{ marginTop: 10 }}>
      <Input
        name={'employeeNumber'}
        label={'名前'}
        initialValue={name ?? ''}
        onChange={setName}
        validate={{
          maxLength: {
            value: 50,
            message: '50文字以内で入力してください',
          },
        }}
      />
      <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1 }}>
        <Button
          text={'変更する'}
          style={{
            minWidth: 100,
            marginRight: 10,
          }}
          onPress={async () => {
            await updateMemberProfile();
            props.closeModal();
          }}
        />
        <Button
          text={'キャンセル'}
          style={{
            minWidth: 100,
            marginRight: 10,
            backgroundColor: 'transparent',
          }}
          textStyle={{ color: themeContext.colors.primary }}
          disableValidate={true}
          onPress={async () => {
            props.closeModal();
          }}
        />
      </View>
    </Form>
  );
};

interface IInvitationNameModalProps {
  closeModal: () => void;
  invitation: MemberInvitation;
}

const InvitationNameModal = (props: IInvitationNameModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [name, setName] = useState(props!.invitation!.name!);

  const [updateMemberProfile] = useUpdateInvitationMutation({
    variables: {
      id: props.invitation.id!,
      input: {
        name: name,
        department: props.invitation.department,
        employeeNumber: props.invitation.employeeNumber,
        adminRole: props.invitation.adminRole,
        laborCostManagementRole: props.invitation.laborCostManagementRole,
        salesManagementRole: props.invitation.salesManagementRole,
        memberManagementRole: props.invitation.memberManagementRole,
        clientManagementRole: props.invitation.clientManagementRole,
        organizationReportRole: props.invitation.organizationReportRole,
        teamReportRole: props.invitation.teamReportRole,
        organizationMemberViewRole: props.invitation.organizationMemberViewRole,
        teamMemberViewRole: props.invitation.teamMemberViewRole,
        projectCreatePermissionFlg: props.invitation.projectCreatePermissionFlg,
        projectUpdatePermissionFlg: props.invitation.projectUpdatePermissionFlg,
        projectDeletePermissionFlg: props.invitation.projectDeletePermissionFlg,
        projectCustomAttributePermissionFlg: props.invitation.projectCustomAttributePermissionFlg,
        taskCreatePermissionFlg: props.invitation.taskCreatePermissionFlg,
        taskUpdatePermissionFlg: props.invitation.taskUpdatePermissionFlg,
        taskDeletePermissionFlg: props.invitation.taskDeletePermissionFlg,
        taskCustomAttributePermissionFlg: props.invitation.taskCustomAttributePermissionFlg,
        proxyEditWorkingDataRole: props.invitation.proxyEditWorkingDataRole,
        versionNo: props.invitation.versionNo,
      },
    },
  });

  return (
    <Form style={{ marginTop: 10 }}>
      <Input
        name={'employeeNumber'}
        label={'名前'}
        initialValue={name ?? ''}
        onChange={setName}
        validate={{
          maxLength: {
            value: 50,
            message: '50文字以内で入力してください',
          },
        }}
      />
      <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1 }}>
        <Button
          text={'変更する'}
          style={{
            minWidth: 100,
            marginRight: 10,
          }}
          onPress={async () => {
            await updateMemberProfile();
            props.closeModal();
          }}
        />
        <Button
          text={'キャンセル'}
          style={{
            minWidth: 100,
            marginRight: 10,
            backgroundColor: 'transparent',
          }}
          textStyle={{ color: themeContext.colors.primary }}
          disableValidate={true}
          onPress={async () => {
            props.closeModal();
          }}
        />
      </View>
    </Form>
  );
};

interface ITwoFactorAuthDeactivationModalProps {
  closeModal: () => void;
  member: Member;
}

const TwoFactorAuthDeactivationModal = (props: ITwoFactorAuthDeactivationModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [deactivateMemberTwoFactorAuth] = useDeactivateMemberTwoFactorAuthMutation({
    variables: {
      memberId: props.member.id!,
    },
    refetchQueries: [
      {
        query: TwoFactorAuthEnabledDocument,
        variables: { memberId: props.member.id! },
      },
    ],
  });

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <Typography variant={TypographyType.Normal} style={{ paddingVertical: 16 }}>
        {props.member.name}さんの2要素認証の設定を解除します。{'\n'}
        この設定は、{props.member.name}さん本人によって{'\n'}
        あとでもう一度設定し直すことも可能です。
        {'\n'}
        解除しますか？
      </Typography>
      <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1 }}>
        <Button
          text={'解除する'}
          style={{
            minWidth: 100,
            marginRight: 10,
          }}
          onPress={async () => {
            await deactivateMemberTwoFactorAuth();
            props.closeModal();
          }}
        />
        <Button
          text={'キャンセル'}
          style={{
            minWidth: 100,
            marginRight: 10,
            backgroundColor: 'transparent',
          }}
          textStyle={{ color: themeContext.colors.primary }}
          disableValidate={true}
          onPress={async () => {
            props.closeModal();
          }}
        />
      </View>
    </View>
  );
};

interface ICreateLaborCostModalProps {
  onPressYes: (laborCost: LaborCost) => void;
  onCloseModal: () => void;
  member: Member;
  showModal: boolean;
  laborCosts: LaborCost[];
}

const CreateLaborCostModal = React.memo((props: ICreateLaborCostModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, _] = useContext(LoginUserContext);
  const [hourlyWage, setHourlyWage] = useState(0);
  const [startDateTime, setStartDateTime] = useState<moment.Moment>(moment().startOf('day'));
  const [createLaborCost] = useCreateLaborCostMutation({
    variables: {
      input: {
        memberId: props.member.id!,
        hourlyWage: hourlyWage,
        startDateTime: startDateTime.startOf('day').toISOString(),
      },
    },
    refetchQueries: [
      {
        query: LaborCostsDocument,
        variables: {
          memberId: props.member.id!,
        },
      },
    ],
  });

  useEffect(() => {
    if (props.laborCosts.length === 0) {
      setStartDateTime(moment('2000/01/01'));
    } else {
      setStartDateTime(moment().startOf('day'));
    }
  }, [props.laborCosts]);

  const isExistsSameStartDateTimeData =
    props.laborCosts.filter(
      (cost) => moment(cost!.startDateTime).format('YYYYMMDD') === startDateTime.format('YYYYMMDD')
    ).length > 0;

  return (
    <Modal
      title={'時給(標準単価)の追加'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <Form>
        <View style={{ flexDirection: 'row', alignItems: 'center', zIndex: 3 }}>
          <View style={{ minWidth: 90 }}>
            <Typography variant={TypographyType.Normal} style={{ fontSize: 16, lineHeight: 22 }}>
              金額
            </Typography>
          </View>
          <EditableText
            value={hourlyWage}
            type={'text'}
            textStyle={{ fontSize: 18, lineHeight: 22, width: 140, textAlign: 'center' }}
            containerStyle={{
              borderBottomWidth: 1,
              borderBottomColor: themeContext.colors.textColor,
            }}
            validate={{
              required: {
                value: true,
                message: '金額を入力してください',
              },
              min: {
                value: 0,
                message: '0円以上で入力してください',
              },
            }}
            disableClear={false}
            onChange={(value) => {
              setHourlyWage(Number(value));
            }}
          />
        </View>
        <View style={{ flexDirection: 'row', alignItems: 'center', zIndex: 3, marginTop: 20 }}>
          <View style={{ minWidth: 90 }}>
            <Typography variant={TypographyType.Normal} style={{ fontSize: 16, lineHeight: 22 }}>
              適用開始日
            </Typography>
          </View>
          <EditableText
            value={startDateTime}
            type={'date-picker'}
            textStyle={{ fontSize: 18, lineHeight: 22, width: 140, textAlign: 'center' }}
            containerStyle={{
              borderBottomWidth: 1,
              borderBottomColor: themeContext.colors.textColor,
            }}
            disableClear={true}
            onChange={(value) => setStartDateTime(value as moment.Moment)}
            validate={{
              required: {
                value: true,
                message: '適用開始日を入力してください',
              },
            }}
          />
        </View>
        {isExistsSameStartDateTimeData && (
          <View style={{ marginTop: 20 }}>
            <Typography
              variant={TypographyType.Description}
              style={{
                color: themeContext.colors.error,
              }}>{`同じ適用開始日の標準単価がすでに登録済みです`}</Typography>
          </View>
        )}
        <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 20 }}>
          <Button
            text={'追加する'}
            style={{ minWidth: 100, marginRight: 10, marginVertical: 10 }}
            isDisabled={isExistsSameStartDateTimeData}
            onPress={async () => {
              if (isExistsSameStartDateTimeData) {
                return;
              }
              const { data } = await createLaborCost();
              props.onPressYes(data!.createLaborCost!);
            }}
          />
          <Button
            text={'キャンセル'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.primary }}
            disableValidate={true}
            onPress={() => {
              props.onCloseModal();
            }}
          />
        </View>
      </Form>
    </Modal>
  );
});

interface IEditLaborCostModalProps {
  onPressYes: (laborCost: LaborCost) => void;
  onCloseModal: () => void;
  laborCost: LaborCost;
  laborCosts: LaborCost[];
  showModal: boolean;
}

const EditLaborCostModal = React.memo((props: IEditLaborCostModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, _] = useContext(LoginUserContext);
  const [hourlyWage, setHourlyWage] = useState(props.laborCost.hourlyWage);
  const [startDateTime, setStartDateTime] = useState<moment.Moment>(
    moment(props.laborCost.startDateTime)
  );
  const [updateLaborCost] = useUpdateLaborCostMutation({
    variables: {
      id: props.laborCost.id!,
      input: {
        hourlyWage: hourlyWage,
        startDateTime: startDateTime.startOf('day').toISOString(),
        versionNo: props.laborCost!.versionNo,
      },
    },
  });

  useEffect(() => {
    setHourlyWage(props.laborCost.hourlyWage);
    setStartDateTime(moment(props.laborCost.startDateTime));
  }, [props.laborCost]);

  const isExistsSameStartDateTimeData =
    props.laborCosts.filter(
      (cost) =>
        cost.id !== props.laborCost.id &&
        moment(cost!.startDateTime).format('YYYYMMDD') === startDateTime.format('YYYYMMDD')
    ).length > 0;

  return (
    <Modal
      title={'時給(標準単価)の変更'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <Form>
        <View style={{ flexDirection: 'row', alignItems: 'center', zIndex: 3, marginTop: 20 }}>
          <View style={{ minWidth: 90 }}>
            <Typography variant={TypographyType.Normal} style={{ fontSize: 16, lineHeight: 22 }}>
              金額
            </Typography>
          </View>
          <EditableText
            value={hourlyWage.toString()}
            type={'text'}
            textStyle={{ fontSize: 18, lineHeight: 22, width: 140, textAlign: 'center' }}
            containerStyle={{
              borderBottomWidth: 1,
              borderBottomColor: themeContext.colors.textColor,
            }}
            validate={{
              required: {
                value: true,
                message: '金額を入力してください',
              },
              min: {
                value: 0,
                message: '0円以上で入力してください',
              },
            }}
            disableClear={false}
            onChange={(value) => {
              setHourlyWage(Number(value));
            }}
          />
        </View>
        <View style={{ flexDirection: 'row', alignItems: 'center', zIndex: 3, marginTop: 20 }}>
          <View style={{ minWidth: 90 }}>
            <Typography variant={TypographyType.Normal} style={{ fontSize: 16, lineHeight: 22 }}>
              適用開始日
            </Typography>
          </View>
          <EditableText
            value={startDateTime}
            type={'date-picker'}
            textStyle={{ fontSize: 18, lineHeight: 22, width: 140, textAlign: 'center' }}
            containerStyle={{
              borderBottomWidth: 1,
              borderBottomColor: themeContext.colors.textColor,
            }}
            disableClear={true}
            onChange={(value) => setStartDateTime(value as moment.Moment)}
            validate={{
              required: {
                value: true,
                message: '適用開始日を入力してください',
              },
            }}
          />
        </View>
        {isExistsSameStartDateTimeData && (
          <View style={{ marginTop: 20 }}>
            <Typography
              variant={TypographyType.Description}
              style={{
                color: themeContext.colors.error,
              }}>{`同じ適用開始日の標準単価がすでに登録済みです`}</Typography>
          </View>
        )}
        <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 20 }}>
          <Button
            text={'変更する'}
            style={{ minWidth: 100, marginRight: 10, marginVertical: 10 }}
            isDisabled={isExistsSameStartDateTimeData}
            onPress={async () => {
              if (isExistsSameStartDateTimeData) {
                return;
              }
              const { data } = await updateLaborCost();
              props.onPressYes(data!.updateLaborCost!);
            }}
          />
          <Button
            text={'キャンセル'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.primary }}
            disableValidate={true}
            onPress={() => {
              props.onCloseModal();
            }}
          />
        </View>
      </Form>
    </Modal>
  );
});

interface IDeleteLaborCostModalProps {
  onPressYes: () => void;
  onCloseModal: () => void;
  laborCost: LaborCost;
  member: Member;
  showModal: boolean;
}

const DeleteLaborCostModal = React.memo((props: IDeleteLaborCostModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, _] = useContext(LoginUserContext);
  const [deleteLaborCost] = useDeleteLaborCostMutation({
    variables: {
      id: props.laborCost.id!,
      input: {
        versionNo: props.laborCost!.versionNo,
      },
    },
    refetchQueries: [
      {
        query: LaborCostsDocument,
        variables: {
          memberId: props.member.id!,
        },
      },
    ],
  });

  return (
    <Modal
      title={'時給(標準単価)の削除'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <Form>
        <Typography
          variant={TypographyType.Normal}
          style={{ textAlign: 'center', marginVertical: 10 }}>
          {`本当に削除しますか？`}
        </Typography>
        <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1 }}>
          <Button
            text={'削除する'}
            style={{ minWidth: 100, marginRight: 10, marginVertical: 10 }}
            onPress={async () => {
              await deleteLaborCost();
              props.onPressYes();
            }}
          />
          <Button
            text={'キャンセル'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.primary }}
            disableValidate={true}
            onPress={() => {
              props.onCloseModal();
            }}
          />
        </View>
      </Form>
    </Modal>
  );
});

interface ILaborCostRowProps {
  member: Member;
  laborCost: LaborCost;
  laborCosts: LaborCost[];
  isFirstRow: boolean;
}

const LaborCostRow = (props: ILaborCostRowProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [showEditNewLaborCost, setShowEditNewLaborCost] = useState(false);
  const [showDeleteNewLaborCost, setShowDeleteNewLaborCost] = useState(false);
  return (
    <View style={{ flexDirection: 'row', marginBottom: 10 }}>
      <Typography
        variant={TypographyType.Normal}
        style={{
          textAlign: 'center',
          color: themeContext.colors.textColor,
          width: 130,
          marginLeft: 10,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        {`${new Intl.NumberFormat('ja-JP', {
          style: 'currency',
          currency: 'JPY',
        }).format(props.laborCost.hourlyWage)}`}
      </Typography>
      <Typography
        variant={TypographyType.Normal}
        style={{
          textAlign: 'center',
          color: themeContext.colors.textColor,
          width: 130,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        {props.laborCost.startDateTime
          ? `${moment(props.laborCost.startDateTime).format('YYYY/MM/DD')}`
          : '-'}
      </Typography>
      <Button
        text={'変更する'}
        style={{
          marginLeft: 10,
          minWidth: 100,
        }}
        disableValidate={true}
        onPress={() => {
          setShowEditNewLaborCost(true);
        }}
      />
      <Button
        text={'削除する'}
        style={{
          marginLeft: 10,
          minWidth: 100,
          marginRight: 10,
          marginVertical: 10,
          borderColor: themeContext.colors.primary,
          borderRadius: 3,
          borderWidth: 1,
          backgroundColor: 'transparent',
        }}
        textStyle={{ color: themeContext.colors.primary }}
        onPress={async () => {
          setShowDeleteNewLaborCost(true);
        }}
      />
      <EditLaborCostModal
        showModal={showEditNewLaborCost}
        laborCost={props.laborCost}
        laborCosts={props.laborCosts}
        onPressYes={() => {
          setShowEditNewLaborCost(false);
        }}
        onCloseModal={() => {
          setShowEditNewLaborCost(false);
        }}
      />
      <DeleteLaborCostModal
        showModal={showDeleteNewLaborCost}
        laborCost={props.laborCost}
        member={props.member}
        onPressYes={() => {
          setShowDeleteNewLaborCost(false);
        }}
        onCloseModal={() => {
          setShowDeleteNewLaborCost(false);
        }}
      />
    </View>
  );
};

interface ILaborCostListProps {
  member: Member;
  loginMember: Member;
}

const LaborCostList = (props: ILaborCostListProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [showInputNewLaborCost, setShowInputNewLaborCost] = useState(false);
  const { loading, data } = useLaborCostsQuery({
    variables: {
      memberId: props.member.id!,
    },
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <></>;
  }

  return (
    <SettingsElement title={' '} titleWidth={10}>
      <View style={{ flexDirection: 'row', marginTop: 20 }}>
        <Typography
          variant={TypographyType.Description}
          style={{ marginLeft: 10, width: 130, textAlign: 'center' }}>
          金額
        </Typography>
        <Typography
          variant={TypographyType.Description}
          style={{ width: 130, textAlign: 'center' }}>
          適用開始日
        </Typography>
      </View>
      <Form>
        <View
          style={{
            marginVertical: 10,
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}>
          {data!
            .laborCosts!.slice()
            .sort((a, b) => {
              return new Date(a!.startDateTime).getTime() - new Date(b!.startDateTime).getTime();
            })
            .map((laborCost, i) => {
              return (
                <LaborCostRow
                  member={props.member}
                  laborCost={laborCost!}
                  laborCosts={data!.laborCosts! as LaborCost[]}
                  isFirstRow={i === 0}
                />
              );
            })}
        </View>
        <LaborCostAddButton
          style={{
            display: 'flex',
            flexDirection: 'row',
            paddingBottom: 10,
            paddingHorizontal: 5,
            marginLeft: 200,
            marginTop: 10,
          }}
          onPress={async () => {
            setShowInputNewLaborCost(true);
          }}>
          <PlusIcon
            size={14}
            containerStyle={{ marginLeft: 10 }}
            onPress={async () => {
              setShowInputNewLaborCost(true);
            }}>
            <Typography
              variant={TypographyType.Normal}
              style={{ fontSize: 14, color: themeContext.colors.description }}>
              標準単価を追加する
            </Typography>
          </PlusIcon>
        </LaborCostAddButton>
      </Form>
      <CreateLaborCostModal
        showModal={showInputNewLaborCost}
        member={props.member}
        laborCosts={data!.laborCosts! as LaborCost[]}
        onPressYes={() => {
          setShowInputNewLaborCost(false);
        }}
        onCloseModal={() => {
          setShowInputNewLaborCost(false);
        }}
      />
    </SettingsElement>
  );
};

interface IMemberPermissionProps {
  member: Member;
  loginMember: Member;
}

const MemberPermission = (props: IMemberPermissionProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [
    showCanNotChangePermissionNoExistAdminModal,
    setShowCanNotChangePermissionNoExistAdminModal,
  ] = useState(false);
  const [showCanNotChangePermissionNotAdminModal, setShowCanNotChangePermissionNotAdminModal] =
    useState(false);
  const [showCanNotChangePermissioModal, setShowCanNotChangePermissionModal] = useState(false);
  const [showCanNotChangeAdminPermissioModal, setShowCanNotChangeAdminPermissionModal] =
    useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [isAdminRole, setAdminRole] = useState(props.member.adminRole);
  const [isLaborCostManagementRole, setLaborCostManagementRole] = useState(
    props.member.laborCostManagementRole
  );
  const [isSalesManagementRole, setSalesManagementRole] = useState(
    props.member.salesManagementRole
  );
  const [isMemberManagementRole, setMemberManagementRole] = useState(
    props.member.memberManagementRole
  );
  const [isClientManagementRole, setClientManagementRole] = useState(
    props.member.clientManagementRole
  );
  const [isProxyEditWorkDataRole, setProxyEditWorkDataRole] = useState(
    props.member.proxyEditWorkingDataRole
  );
  const [isOrganizationReportRole, setOrganizationReportRole] = useState(
    props.member.organizationReportRole
  );
  const [isTeamReportRole, setTeamReportRole] = useState(props.member.teamReportRole);
  const [isOrganizationMemberViewRole, setOrganizationMemberViewRole] = useState(
    props.member.organizationMemberViewRole
  );
  const [isTeamMemberViewRole, setTeamMemberViewRole] = useState(props.member.teamMemberViewRole);
  const [isProjectCreatePermissionFlg, setProjectCreatePermissionFlg] = useState(
    props.member.projectCreatePermissionFlg
  );
  const [isProjectUpdatePermissionFlg, setProjectUpdatePermissionFlg] = useState(
    props.member.projectUpdatePermissionFlg
  );
  const [isProjectDeletePermissionFlg, setProjectDeletePermissionFlg] = useState(
    props.member.projectDeletePermissionFlg
  );
  const [isProjectCustomAttributePermissionFlg, setProjectCustomAttributePermissionFlg] = useState(
    props.member.projectCustomAttributePermissionFlg
  );
  const [isTaskCreatePermissionFlg, setTaskCreatePermissionFlg] = useState(
    props.member.taskCreatePermissionFlg
  );
  const [isTaskUpdatePermissionFlg, setTaskUpdatePermissionFlg] = useState(
    props.member.taskUpdatePermissionFlg
  );
  const [isTaskDeletePermissionFlg, setTaskDeletePermissionFlg] = useState(
    props.member.taskDeletePermissionFlg
  );
  const [isTaskCustomAttributePermissionFlg, setTaskCustomAttributePermissionFlg] = useState(
    props.member.taskCustomAttributePermissionFlg
  );
  const [upateRole, _] = useUpdateMemberRoleMutation({
    variables: {
      id: props.member.id!,
      input: {
        adminRole: isAdminRole,
        laborCostManagementRole: isLaborCostManagementRole,
        salesManagementRole: isSalesManagementRole,
        memberManagementRole: isMemberManagementRole,
        clientManagementRole: isClientManagementRole,
        proxyEditWorkingDataRole: isProxyEditWorkDataRole,
        organizationReportRole: isOrganizationReportRole,
        teamReportRole: isTeamReportRole,
        organizationMemberViewRole: isOrganizationMemberViewRole,
        teamMemberViewRole: isTeamMemberViewRole,
        projectCreatePermission: isProjectCreatePermissionFlg,
        projectUpdatePermission: isProjectUpdatePermissionFlg,
        projectDeletePermission: isProjectDeletePermissionFlg,
        projectCustomAttributePermission: isProjectCustomAttributePermissionFlg,
        taskCreatePermission: isTaskCreatePermissionFlg,
        taskUpdatePermission: isTaskUpdatePermissionFlg,
        taskDeletePermission: isTaskDeletePermissionFlg,
        taskCustomAttributePermission: isTaskCustomAttributePermissionFlg,
        versionNo: props.member.versionNo,
      },
    },
  });
  const { loading, data } = useExistAdminMemberQuery({
    variables: {
      excludeMemberId: props.member.id!,
    },
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <></>;
  }
  return (
    <SettingsElement title={' '} titleWidth={10}>
      <Form>
        <View
          style={{
            marginVertical: 10,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}>
          <View style={{ minWidth: 150 }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontWeight: '500',
                textAlign: 'left',
                color: isAdminRole ? themeContext.colors.primary : themeContext.colors.textColor,
              }}>
              管理者
            </Typography>
          </View>
          <View>
            <Switch
              value={isAdminRole}
              onValueChange={(value) => {
                setAdminRole(value);
                setLaborCostManagementRole(value);
                setSalesManagementRole(value);
                setMemberManagementRole(value);
                setClientManagementRole(value);
                setOrganizationReportRole(value);
                setTeamReportRole(value);
                setOrganizationMemberViewRole(value);
                setTeamMemberViewRole(value);
                setProjectCreatePermissionFlg(value);
                setProjectUpdatePermissionFlg(value);
                setProjectDeletePermissionFlg(value);
                setProjectCustomAttributePermissionFlg(value);
                setTaskCreatePermissionFlg(value);
                setTaskUpdatePermissionFlg(value);
                setTaskDeletePermissionFlg(value);
                setTaskCustomAttributePermissionFlg(value);
              }}
              disabled={!props.loginMember.adminRole}
            />
          </View>
          <View style={{ paddingLeft: 10 }}>
            <Typography
              variant={
                TypographyType.Description
              }>{`全ての操作が可能です。${'\n'}(作業データの代理編集以外)`}</Typography>
          </View>
          {!props.loginMember.adminRole && (
            <View style={{ paddingLeft: 10 }}>
              <Typography
                variant={TypographyType.Description}
                style={{
                  color: themeContext.colors.error,
                }}>{`変更するには管理者権限が必要です。`}</Typography>
            </View>
          )}
        </View>
        <View
          style={{
            marginVertical: 10,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}>
          <View style={{ minWidth: 150 }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontWeight: '500',
                textAlign: 'left',
                color: isProxyEditWorkDataRole
                  ? themeContext.colors.primary
                  : themeContext.colors.textColor,
              }}>
              {`作業データの${'\n'}代理編集`}
            </Typography>
          </View>
          <View>
            <Switch
              value={isProxyEditWorkDataRole}
              onValueChange={(value) => setProxyEditWorkDataRole(value)}
            />
          </View>
          <View style={{ paddingLeft: 10 }}>
            <Typography
              variant={
                TypographyType.Description
              }>{`他のメンバーの作業履歴・作業予定データの編集を行えます`}</Typography>
          </View>
        </View>
        <View
          style={{
            marginVertical: 10,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}>
          <View style={{ minWidth: 150 }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontWeight: '500',
                textAlign: 'left',
                color: isMemberManagementRole
                  ? themeContext.colors.primary
                  : themeContext.colors.textColor,
              }}>
              メンバー管理
            </Typography>
          </View>
          <View>
            <Switch
              value={isMemberManagementRole}
              onValueChange={(value) => setMemberManagementRole(value)}
              disabled={isAdminRole}
            />
          </View>
          <View style={{ paddingLeft: 10 }}>
            <Typography
              variant={TypographyType.Description}>{`メンバーの追加・削除ができます。`}</Typography>
          </View>
        </View>
        <View
          style={{
            marginVertical: 10,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}>
          <View style={{ minWidth: 150 }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontWeight: '500',
                textAlign: 'left',
                color: isClientManagementRole
                  ? themeContext.colors.primary
                  : themeContext.colors.textColor,
              }}>
              取引先管理
            </Typography>
          </View>
          <View>
            <Switch
              value={isClientManagementRole}
              onValueChange={(value) => setClientManagementRole(value)}
              disabled={isAdminRole}
            />
          </View>
          <View style={{ paddingLeft: 10 }}>
            <Typography
              variant={TypographyType.Description}>{`取引先の追加・削除ができます。`}</Typography>
          </View>
        </View>
        <View
          style={{
            marginVertical: 10,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}>
          <View style={{ minWidth: 150 }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontWeight: '500',
                textAlign: 'left',
                color: isLaborCostManagementRole
                  ? themeContext.colors.primary
                  : themeContext.colors.textColor,
              }}>
              人件費・売上管理
            </Typography>
          </View>
          <View>
            <Switch
              value={isLaborCostManagementRole}
              onValueChange={(value) => setLaborCostManagementRole(value)}
              disabled={isAdminRole}
            />
          </View>
          <View style={{ paddingLeft: 10 }}>
            <Typography
              variant={
                TypographyType.Description
              }>{`人件費・契約情報の登録・編集・閲覧ができます。`}</Typography>
          </View>
        </View>
        {/* <View
          style={{
            marginVertical: 10,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}>
          <View style={{ minWidth: 150 }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontWeight: '500',
                textAlign: 'left',
                color: isSalesManagementRole
                  ? themeContext.colors.primary
                  : themeContext.colors.textColor,
              }}>
              売上管理
            </Typography>
          </View>
          <View>
            <Switch
              value={isSalesManagementRole}
              onValueChange={(value) => setSalesManagementRole(value)}
              disabled={isAdminRole}
            />
          </View>
          <View style={{ paddingLeft: 10 }}>
            <Typography
              variant={
                TypographyType.Description
              }>{`売上の登録・編集・閲覧ができます。`}</Typography>
          </View>
        </View> */}
        <View
          style={{
            marginVertical: 10,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}>
          <View style={{ minWidth: 150 }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontWeight: '500',
                textAlign: 'left',
                color: isOrganizationReportRole
                  ? themeContext.colors.primary
                  : themeContext.colors.textColor,
              }}>
              {`組織の${'\n'}時間分析閲覧`}
            </Typography>
          </View>
          <View>
            <Switch
              value={isOrganizationReportRole}
              onValueChange={(value) => setOrganizationReportRole(value)}
              disabled={isAdminRole}
            />
          </View>
          <View style={{ paddingLeft: 10 }}>
            <Typography
              variant={
                TypographyType.Description
              }>{`組織の時間分析画面を閲覧できます。`}</Typography>
          </View>
        </View>
        <View
          style={{
            marginVertical: 10,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}>
          <View style={{ minWidth: 150 }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontWeight: '500',
                textAlign: 'left',
                color: isOrganizationMemberViewRole
                  ? themeContext.colors.primary
                  : themeContext.colors.textColor,
              }}>
              {`組織全員の${'\n'}作業履歴の閲覧`}
            </Typography>
          </View>
          <View>
            <Switch
              value={isOrganizationMemberViewRole}
              onValueChange={(value) => setOrganizationMemberViewRole(value)}
              disabled={isAdminRole}
            />
          </View>
          <View style={{ paddingLeft: 10 }}>
            <Typography
              variant={
                TypographyType.Description
              }>{`組織のメンバーの作業履歴や作業予定を${'\n'}メンバーの状況画面やカレンダーで閲覧できます。`}</Typography>
          </View>
        </View>
        <View
          style={{
            marginVertical: 10,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}>
          <View style={{ minWidth: 150 }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontWeight: '500',
                textAlign: 'left',
                color: isTeamReportRole
                  ? themeContext.colors.primary
                  : themeContext.colors.textColor,
              }}>
              {`チームの${'\n'}時間分析閲覧`}
            </Typography>
          </View>
          <View>
            <Switch
              value={isTeamReportRole}
              onValueChange={(value) => setTeamReportRole(value)}
              disabled={isAdminRole}
            />
          </View>
          <View style={{ paddingLeft: 10 }}>
            <Typography
              variant={
                TypographyType.Description
              }>{`所属しているチームのみ${'\n'}時間分析画面を閲覧できます。`}</Typography>
          </View>
        </View>

        <View
          style={{
            marginVertical: 10,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}>
          <View style={{ minWidth: 150 }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontWeight: '500',
                textAlign: 'left',
                color: isTeamMemberViewRole
                  ? themeContext.colors.primary
                  : themeContext.colors.textColor,
              }}>
              {`チームメンバーの${'\n'}作業履歴の閲覧`}
            </Typography>
          </View>
          <View>
            <Switch
              value={isTeamMemberViewRole}
              onValueChange={(value) => setTeamMemberViewRole(value)}
              disabled={isAdminRole}
            />
          </View>
          <View style={{ paddingLeft: 10 }}>
            <Typography
              variant={
                TypographyType.Description
              }>{`所属しているチームのメンバーのみ${'\n'}作業履歴や作業予定を${'\n'}メンバーの状況画面やカレンダーで閲覧できます。`}</Typography>
          </View>
        </View>
        <View
          style={{
            marginVertical: 10,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}>
          <View style={{ minWidth: 150 }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontWeight: '500',
                textAlign: 'left',
                color: isProjectCreatePermissionFlg
                  ? themeContext.colors.primary
                  : themeContext.colors.textColor,
              }}>
              {`プロジェクトの作成`}
            </Typography>
          </View>
          <View>
            <Switch
              value={isProjectCreatePermissionFlg}
              onValueChange={(value) => setProjectCreatePermissionFlg(value)}
              disabled={isAdminRole}
            />
          </View>
          <View style={{ paddingLeft: 10 }}>
            <Typography
              variant={TypographyType.Description}>{`プロジェクトを作成できます。`}</Typography>
          </View>
        </View>
        <View
          style={{
            marginVertical: 10,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}>
          <View style={{ minWidth: 150 }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontWeight: '500',
                textAlign: 'left',
                color: isProjectUpdatePermissionFlg
                  ? themeContext.colors.primary
                  : themeContext.colors.textColor,
              }}>
              {`プロジェクトの更新`}
            </Typography>
          </View>
          <View>
            <Switch
              value={isProjectUpdatePermissionFlg}
              onValueChange={(value) => setProjectUpdatePermissionFlg(value)}
              disabled={isAdminRole}
            />
          </View>
          <View style={{ paddingLeft: 10 }}>
            <Typography
              variant={TypographyType.Description}>{`プロジェクトを更新できます。`}</Typography>
          </View>
        </View>
        <View
          style={{
            marginVertical: 10,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}>
          <View style={{ minWidth: 150 }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontWeight: '500',
                textAlign: 'left',
                color: isProjectDeletePermissionFlg
                  ? themeContext.colors.primary
                  : themeContext.colors.textColor,
              }}>
              {`プロジェクトの削除`}
            </Typography>
          </View>
          <View>
            <Switch
              value={isProjectDeletePermissionFlg}
              onValueChange={(value) => setProjectDeletePermissionFlg(value)}
              disabled={isAdminRole}
            />
          </View>
          <View style={{ paddingLeft: 10 }}>
            <Typography
              variant={TypographyType.Description}>{`プロジェクトを削除できます。`}</Typography>
          </View>
        </View>
        <View
          style={{
            marginVertical: 10,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}>
          <View style={{ minWidth: 150 }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontWeight: '500',
                textAlign: 'left',
                color: isProjectCustomAttributePermissionFlg
                  ? themeContext.colors.primary
                  : themeContext.colors.textColor,
              }}>
              {`プロジェクトの${'\n'}カスタム項目の編集`}
            </Typography>
          </View>
          <View>
            <Switch
              value={isProjectCustomAttributePermissionFlg}
              onValueChange={(value) => setProjectCustomAttributePermissionFlg(value)}
              disabled={isAdminRole}
            />
          </View>
          <View style={{ paddingLeft: 10 }}>
            <Typography
              variant={
                TypographyType.Description
              }>{`プロジェクトのカスタム項目を編集できます。`}</Typography>
          </View>
        </View>
        <View
          style={{
            marginVertical: 10,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}>
          <View style={{ minWidth: 150 }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontWeight: '500',
                textAlign: 'left',
                color: isTaskCreatePermissionFlg
                  ? themeContext.colors.primary
                  : themeContext.colors.textColor,
              }}>
              {`タスクの作成`}
            </Typography>
          </View>
          <View>
            <Switch
              value={isTaskCreatePermissionFlg}
              onValueChange={(value) => setTaskCreatePermissionFlg(value)}
              disabled={isAdminRole}
            />
          </View>
          <View style={{ paddingLeft: 10 }}>
            <Typography variant={TypographyType.Description}>{`タスクを作成できます。`}</Typography>
          </View>
        </View>
        <View
          style={{
            marginVertical: 10,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}>
          <View style={{ minWidth: 150 }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontWeight: '500',
                textAlign: 'left',
                color: isTaskUpdatePermissionFlg
                  ? themeContext.colors.primary
                  : themeContext.colors.textColor,
              }}>
              {`タスクの更新`}
            </Typography>
          </View>
          <View>
            <Switch
              value={isTaskUpdatePermissionFlg}
              onValueChange={(value) => setTaskUpdatePermissionFlg(value)}
              disabled={isAdminRole}
            />
          </View>
          <View style={{ paddingLeft: 10 }}>
            <Typography variant={TypographyType.Description}>{`タスクを更新できます。`}</Typography>
          </View>
        </View>
        <View
          style={{
            marginVertical: 10,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}>
          <View style={{ minWidth: 150 }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontWeight: '500',
                textAlign: 'left',
                color: isTaskDeletePermissionFlg
                  ? themeContext.colors.primary
                  : themeContext.colors.textColor,
              }}>
              {`タスクの削除`}
            </Typography>
          </View>
          <View>
            <Switch
              value={isTaskDeletePermissionFlg}
              onValueChange={(value) => setTaskDeletePermissionFlg(value)}
              disabled={isAdminRole}
            />
          </View>
          <View style={{ paddingLeft: 10 }}>
            <Typography variant={TypographyType.Description}>{`タスクを削除できます。`}</Typography>
          </View>
        </View>
        <View
          style={{
            marginVertical: 10,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}>
          <View style={{ minWidth: 150 }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontWeight: '500',
                textAlign: 'left',
                color: isTaskCustomAttributePermissionFlg
                  ? themeContext.colors.primary
                  : themeContext.colors.textColor,
              }}>
              {`タスクの${'\n'}カスタム項目の編集`}
            </Typography>
          </View>
          <View>
            <Switch
              value={isTaskCustomAttributePermissionFlg}
              onValueChange={(value) => setTaskCustomAttributePermissionFlg(value)}
              disabled={isAdminRole}
            />
          </View>
          <View style={{ paddingLeft: 10 }}>
            <Typography
              variant={
                TypographyType.Description
              }>{`タスクのカスタム項目を編集できます。`}</Typography>
          </View>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 10 }}>
          <Button
            text="権限を変更する"
            completeText="権限を変更する"
            style={{ marginRight: 5, paddingVertical: 10, paddingHorizontal: 20 }}
            textStyle={{ fontSize: 14 }}
            onPress={async () => {
              if (!props.loginMember.adminRole && !props.loginMember.memberManagementRole) {
                setShowCanNotChangePermissionModal(true);
                return;
              }

              //管理者の権限変更は、管理者しかできない
              if (props.member.adminRole && !props.loginMember.adminRole) {
                setShowCanNotChangePermissionNotAdminModal(true);
                return;
              }
              if (!data!.existAdminMember! && !isAdminRole) {
                setShowCanNotChangePermissionNoExistAdminModal(true);
                return;
              }
              if (!props.loginMember.adminRole && props.member.adminRole != isAdminRole) {
                setShowCanNotChangePermissionNotAdminModal(true);
                return;
              }
              await upateRole();
              setShowAlertModal(true);
            }}
          />
        </View>
      </Form>
      <Modal
        title={'権限を変更できません'}
        isShow={showCanNotChangePermissionNoExistAdminModal}
        onClose={() => {
          setShowCanNotChangePermissionNoExistAdminModal(false);
        }}>
        <Typography variant={TypographyType.Normal} style={{ textAlign: 'center', marginTop: 10 }}>
          管理者がいなくなってしまうため、この権限変更は出来ません。
        </Typography>
        <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
          <Button
            text={'OK'}
            style={{ minWidth: 100, marginRight: 10, marginVertical: 20 }}
            onPress={async () => {
              setShowCanNotChangePermissionNoExistAdminModal(false);
            }}
          />
        </View>
      </Modal>
      <Modal
        title={'権限を変更できません'}
        isShow={showCanNotChangePermissionNotAdminModal}
        onClose={() => {
          setShowCanNotChangePermissionNotAdminModal(false);
        }}>
        <Typography variant={TypographyType.Normal} style={{ textAlign: 'center', marginTop: 10 }}>
          管理者権限の変更を行うには、管理者権限が必要です。
        </Typography>
        <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
          <Button
            text={'OK'}
            style={{ minWidth: 100, marginRight: 10, marginVertical: 20 }}
            onPress={async () => {
              setShowCanNotChangePermissionNotAdminModal(false);
            }}
          />
        </View>
      </Modal>
      <Modal
        title={'権限を変更しました'}
        isShow={showAlertModal}
        onClose={() => {
          setShowAlertModal(false);
        }}>
        <Typography variant={TypographyType.Normal} style={{ textAlign: 'center', marginTop: 10 }}>
          権限設定の変更が完了しました
        </Typography>
        <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
          <Button
            text={'OK'}
            style={{ minWidth: 100, marginRight: 10, marginVertical: 20 }}
            onPress={async () => {
              setShowAlertModal(false);
            }}
          />
        </View>
      </Modal>
      <Modal
        title={'権限を変更できません'}
        isShow={showCanNotChangePermissioModal}
        onClose={() => {
          setShowCanNotChangePermissionModal(false);
        }}>
        <Typography
          variant={TypographyType.Normal}
          style={{ textAlign: 'center', marginVertical: 10 }}>
          権限を変更するには、管理者権限かメンバー管理権限が必要です。
        </Typography>
        <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
          <Button
            text={'OK'}
            style={{ minWidth: 100, marginRight: 10, marginVertical: 20 }}
            onPress={async () => {
              setShowCanNotChangePermissionModal(false);
            }}
          />
        </View>
      </Modal>
      <Modal
        title={'権限を変更できません'}
        isShow={showCanNotChangePermissioModal}
        onClose={() => {
          setShowCanNotChangePermissionModal(false);
        }}>
        <Typography
          variant={TypographyType.Normal}
          style={{ textAlign: 'center', marginVertical: 10 }}>
          管理者メンバーの権限を変更するには、管理者権限が必要です。
        </Typography>
        <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
          <Button
            text={'OK'}
            style={{ minWidth: 100, marginRight: 10, marginVertical: 20 }}
            onPress={async () => {
              setShowCanNotChangePermissionModal(false);
            }}
          />
        </View>
      </Modal>
    </SettingsElement>
  );
};

interface IMemberInvitationPermissionProps {
  invitation: MemberInvitation;
  loginMember: Member;
}

const MemberInvitationPermission = (props: IMemberInvitationPermissionProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [
    showCanNotChangePermissionNoExistAdminModal,
    setShowCanNotChangePermissionNoExistAdminModal,
  ] = useState(false);
  const [showCanNotChangePermissionNotAdminModal, setShowCanNotChangePermissionNotAdminModal] =
    useState(false);
  const [showCanNotChangePermissioModal, setShowCanNotChangePermissionModal] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [isAdminRole, setAdminRole] = useState(props.invitation.adminRole);
  const [isLaborCostManagementRole, setLaborCostManagementRole] = useState(
    props.invitation.laborCostManagementRole
  );
  const [isSalesManagementRole, setSalesManagementRole] = useState(
    props.invitation.salesManagementRole
  );
  const [isMemberManagementRole, setMemberManagementRole] = useState(
    props.invitation.memberManagementRole
  );
  const [isClientManagementRole, setClientManagementRole] = useState(
    props.invitation.clientManagementRole
  );
  const [isProxyEditWorkDataRole, setProxyEditWorkDataRole] = useState(
    props.invitation.proxyEditWorkingDataRole
  );
  const [isOrganizationReportRole, setOrganizationReportRole] = useState(
    props.invitation.organizationReportRole
  );
  const [isTeamReportRole, setTeamReportRole] = useState(props.invitation.teamReportRole);
  const [isOrganizationMemberViewRole, setOrganizationMemberViewRole] = useState(
    props.invitation.organizationMemberViewRole
  );
  const [isTeamMemberViewRole, setTeamMemberViewRole] = useState(
    props.invitation.teamMemberViewRole
  );
  const [isProjectCreatePermissionFlg, setProjectCreatePermissionFlg] = useState(
    props.invitation.projectCreatePermissionFlg
  );
  const [isProjectUpdatePermissionFlg, setProjectUpdatePermissionFlg] = useState(
    props.invitation.projectUpdatePermissionFlg
  );
  const [isProjectDeletePermissionFlg, setProjectDeletePermissionFlg] = useState(
    props.invitation.projectDeletePermissionFlg
  );
  const [isProjectCustomAttributePermissionFlg, setProjectCustomAttributePermissionFlg] = useState(
    props.invitation.projectCustomAttributePermissionFlg
  );
  const [isTaskCreatePermissionFlg, setTaskCreatePermissionFlg] = useState(
    props.invitation.taskCreatePermissionFlg
  );
  const [isTaskUpdatePermissionFlg, setTaskUpdatePermissionFlg] = useState(
    props.invitation.taskUpdatePermissionFlg
  );
  const [isTaskDeletePermissionFlg, setTaskDeletePermissionFlg] = useState(
    props.invitation.taskDeletePermissionFlg
  );
  const [isTaskCustomAttributePermissionFlg, setTaskCustomAttributePermissionFlg] = useState(
    props.invitation.taskCustomAttributePermissionFlg
  );
  const [upateRole, _] = useUpdateInvitationMutation({
    variables: {
      id: props.invitation.id!,
      input: {
        name: props.invitation.name,
        department: props.invitation.department,
        employeeNumber: props.invitation.employeeNumber,
        adminRole: isAdminRole,
        laborCostManagementRole: isLaborCostManagementRole,
        salesManagementRole: isSalesManagementRole,
        memberManagementRole: isMemberManagementRole,
        clientManagementRole: isClientManagementRole,
        proxyEditWorkingDataRole: isProxyEditWorkDataRole,
        organizationReportRole: isOrganizationReportRole,
        teamReportRole: isTeamReportRole,
        organizationMemberViewRole: isOrganizationMemberViewRole,
        teamMemberViewRole: isTeamMemberViewRole,
        projectCreatePermissionFlg: isProjectCreatePermissionFlg,
        projectUpdatePermissionFlg: isProjectUpdatePermissionFlg,
        projectDeletePermissionFlg: isProjectDeletePermissionFlg,
        projectCustomAttributePermissionFlg: isProjectCustomAttributePermissionFlg,
        taskCreatePermissionFlg: isTaskCreatePermissionFlg,
        taskUpdatePermissionFlg: isTaskUpdatePermissionFlg,
        taskDeletePermissionFlg: isTaskDeletePermissionFlg,
        taskCustomAttributePermissionFlg: isTaskCustomAttributePermissionFlg,
        versionNo: props.invitation.versionNo,
      },
    },
  });
  return (
    <SettingsElement title={' '} titleWidth={10}>
      <Form>
        <View
          style={{
            marginVertical: 10,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}>
          <View style={{ minWidth: 150 }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontWeight: '500',
                textAlign: 'left',
                color: isAdminRole ? themeContext.colors.primary : themeContext.colors.textColor,
              }}>
              管理者
            </Typography>
          </View>
          <View>
            <Switch
              value={isAdminRole}
              onValueChange={(value) => {
                setAdminRole(value);
                setLaborCostManagementRole(value);
                setSalesManagementRole(value);
                setMemberManagementRole(value);
                setClientManagementRole(value);
                setOrganizationReportRole(value);
                setTeamReportRole(value);
                setOrganizationMemberViewRole(value);
                setTeamMemberViewRole(value);
                setProjectCreatePermissionFlg(value);
                setProjectUpdatePermissionFlg(value);
                setProjectDeletePermissionFlg(value);
                setProjectCustomAttributePermissionFlg(value);
                setTaskCreatePermissionFlg(value);
                setTaskUpdatePermissionFlg(value);
                setTaskDeletePermissionFlg(value);
                setTaskCustomAttributePermissionFlg(value);
              }}
              disabled={!props.loginMember.adminRole}
            />
          </View>
          <View style={{ paddingLeft: 10 }}>
            <Typography
              variant={
                TypographyType.Description
              }>{`全ての操作が可能です。${'\n'}(作業データの代理編集以外)`}</Typography>
          </View>
          {!props.loginMember.adminRole && (
            <View style={{ paddingLeft: 10 }}>
              <Typography
                variant={TypographyType.Description}
                style={{
                  color: themeContext.colors.error,
                }}>{`変更するには管理者権限が必要です。`}</Typography>
            </View>
          )}
        </View>
        <View
          style={{
            marginVertical: 10,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}>
          <View style={{ minWidth: 150 }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontWeight: '500',
                textAlign: 'left',
                color: isProxyEditWorkDataRole
                  ? themeContext.colors.primary
                  : themeContext.colors.textColor,
              }}>
              {`作業データの${'\n'}代理編集`}
            </Typography>
          </View>
          <View>
            <Switch
              value={isProxyEditWorkDataRole}
              onValueChange={(value) => setProxyEditWorkDataRole(value)}
            />
          </View>
          <View style={{ paddingLeft: 10 }}>
            <Typography
              variant={
                TypographyType.Description
              }>{`他のメンバーの作業履歴・作業予定データの編集を行えます`}</Typography>
          </View>
        </View>
        <View
          style={{
            marginVertical: 10,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}>
          <View style={{ minWidth: 150 }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontWeight: '500',
                textAlign: 'left',
                color: isMemberManagementRole
                  ? themeContext.colors.primary
                  : themeContext.colors.textColor,
              }}>
              メンバー管理
            </Typography>
          </View>
          <View>
            <Switch
              value={isMemberManagementRole}
              onValueChange={(value) => setMemberManagementRole(value)}
              disabled={isAdminRole}
            />
          </View>
          <View style={{ paddingLeft: 10 }}>
            <Typography
              variant={TypographyType.Description}>{`メンバーの追加・削除ができます。`}</Typography>
          </View>
        </View>
        <View
          style={{
            marginVertical: 10,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}>
          <View style={{ minWidth: 150 }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontWeight: '500',
                textAlign: 'left',
                color: isClientManagementRole
                  ? themeContext.colors.primary
                  : themeContext.colors.textColor,
              }}>
              取引先管理
            </Typography>
          </View>
          <View>
            <Switch
              value={isClientManagementRole}
              onValueChange={(value) => setClientManagementRole(value)}
              disabled={isAdminRole}
            />
          </View>
          <View style={{ paddingLeft: 10 }}>
            <Typography
              variant={TypographyType.Description}>{`取引先の追加・削除ができます。`}</Typography>
          </View>
        </View>
        <View
          style={{
            marginVertical: 10,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}>
          <View style={{ minWidth: 150 }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontWeight: '500',
                textAlign: 'left',
                color: isLaborCostManagementRole
                  ? themeContext.colors.primary
                  : themeContext.colors.textColor,
              }}>
              人件費・売上管理
            </Typography>
          </View>
          <View>
            <Switch
              value={isLaborCostManagementRole}
              onValueChange={(value) => setLaborCostManagementRole(value)}
              disabled={isAdminRole}
            />
          </View>
          <View style={{ paddingLeft: 10 }}>
            <Typography
              variant={
                TypographyType.Description
              }>{`人件費・契約情報の登録・編集・閲覧ができます。`}</Typography>
          </View>
        </View>
        {/* <View
          style={{
            marginVertical: 10,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}>
          <View style={{ minWidth: 150 }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontWeight: '500',
                textAlign: 'left',
                color: isSalesManagementRole
                  ? themeContext.colors.primary
                  : themeContext.colors.textColor,
              }}>
              売上管理
            </Typography>
          </View>
          <View>
            <Switch
              value={isSalesManagementRole}
              onValueChange={(value) => setSalesManagementRole(value)}
              disabled={isAdminRole}
            />
          </View>
          <View style={{ paddingLeft: 10 }}>
            <Typography
              variant={
                TypographyType.Description
              }>{`売上の登録・編集・閲覧ができます。`}</Typography>
          </View>
        </View> */}
        <View
          style={{
            marginVertical: 10,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}>
          <View style={{ minWidth: 150 }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontWeight: '500',
                textAlign: 'left',
                color: isOrganizationReportRole
                  ? themeContext.colors.primary
                  : themeContext.colors.textColor,
              }}>
              {`組織の${'\n'}時間分析閲覧`}
            </Typography>
          </View>
          <View>
            <Switch
              value={isOrganizationReportRole}
              onValueChange={(value) => setOrganizationReportRole(value)}
              disabled={isAdminRole}
            />
          </View>
          <View style={{ paddingLeft: 10 }}>
            <Typography
              variant={
                TypographyType.Description
              }>{`組織の時間分析画面を閲覧できます。`}</Typography>
          </View>
        </View>
        <View
          style={{
            marginVertical: 10,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}>
          <View style={{ minWidth: 150 }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontWeight: '500',
                textAlign: 'left',
                color: isOrganizationMemberViewRole
                  ? themeContext.colors.primary
                  : themeContext.colors.textColor,
              }}>
              {`組織全員の${'\n'}作業履歴の閲覧`}
            </Typography>
          </View>
          <View>
            <Switch
              value={isOrganizationMemberViewRole}
              onValueChange={(value) => setOrganizationMemberViewRole(value)}
              disabled={isAdminRole}
            />
          </View>
          <View style={{ paddingLeft: 10 }}>
            <Typography
              variant={
                TypographyType.Description
              }>{`組織のメンバーの作業履歴や作業予定を${'\n'}メンバーの状況画面やカレンダーで閲覧できます。`}</Typography>
          </View>
        </View>
        <View
          style={{
            marginVertical: 10,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}>
          <View style={{ minWidth: 150 }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontWeight: '500',
                textAlign: 'left',
                color: isTeamReportRole
                  ? themeContext.colors.primary
                  : themeContext.colors.textColor,
              }}>
              {`チームの${'\n'}時間分析閲覧`}
            </Typography>
          </View>
          <View>
            <Switch
              value={isTeamReportRole}
              onValueChange={(value) => setTeamReportRole(value)}
              disabled={isAdminRole}
            />
          </View>
          <View style={{ paddingLeft: 10 }}>
            <Typography
              variant={
                TypographyType.Description
              }>{`所属しているチームのみ${'\n'}時間分析画面を閲覧できます。`}</Typography>
          </View>
        </View>

        <View
          style={{
            marginVertical: 10,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}>
          <View style={{ minWidth: 150 }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontWeight: '500',
                textAlign: 'left',
                color: isTeamMemberViewRole
                  ? themeContext.colors.primary
                  : themeContext.colors.textColor,
              }}>
              {`チームメンバーの${'\n'}作業履歴の閲覧`}
            </Typography>
          </View>
          <View>
            <Switch
              value={isTeamMemberViewRole}
              onValueChange={(value) => setTeamMemberViewRole(value)}
              disabled={isAdminRole}
            />
          </View>
          <View style={{ paddingLeft: 10 }}>
            <Typography
              variant={
                TypographyType.Description
              }>{`所属しているチームのメンバーのみ${'\n'}作業履歴や作業予定を${'\n'}メンバーの状況画面やカレンダーで閲覧できます。`}</Typography>
          </View>
        </View>
        <View
          style={{
            marginVertical: 10,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}>
          <View style={{ minWidth: 150 }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontWeight: '500',
                textAlign: 'left',
                color: isProjectCreatePermissionFlg
                  ? themeContext.colors.primary
                  : themeContext.colors.textColor,
              }}>
              {`プロジェクトの作成`}
            </Typography>
          </View>
          <View>
            <Switch
              value={isProjectCreatePermissionFlg}
              onValueChange={(value) => setProjectCreatePermissionFlg(value)}
              disabled={isAdminRole}
            />
          </View>
          <View style={{ paddingLeft: 10 }}>
            <Typography
              variant={TypographyType.Description}>{`プロジェクトを作成できます。`}</Typography>
          </View>
        </View>
        <View
          style={{
            marginVertical: 10,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}>
          <View style={{ minWidth: 150 }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontWeight: '500',
                textAlign: 'left',
                color: isProjectUpdatePermissionFlg
                  ? themeContext.colors.primary
                  : themeContext.colors.textColor,
              }}>
              {`プロジェクトの更新`}
            </Typography>
          </View>
          <View>
            <Switch
              value={isProjectUpdatePermissionFlg}
              onValueChange={(value) => setProjectUpdatePermissionFlg(value)}
              disabled={isAdminRole}
            />
          </View>
          <View style={{ paddingLeft: 10 }}>
            <Typography
              variant={TypographyType.Description}>{`プロジェクトを更新できます。`}</Typography>
          </View>
        </View>
        <View
          style={{
            marginVertical: 10,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}>
          <View style={{ minWidth: 150 }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontWeight: '500',
                textAlign: 'left',
                color: isProjectDeletePermissionFlg
                  ? themeContext.colors.primary
                  : themeContext.colors.textColor,
              }}>
              {`プロジェクトの削除`}
            </Typography>
          </View>
          <View>
            <Switch
              value={isProjectDeletePermissionFlg}
              onValueChange={(value) => setProjectDeletePermissionFlg(value)}
              disabled={isAdminRole}
            />
          </View>
          <View style={{ paddingLeft: 10 }}>
            <Typography
              variant={TypographyType.Description}>{`プロジェクトを削除できます。`}</Typography>
          </View>
        </View>
        <View
          style={{
            marginVertical: 10,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}>
          <View style={{ minWidth: 150 }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontWeight: '500',
                textAlign: 'left',
                color: isProjectCustomAttributePermissionFlg
                  ? themeContext.colors.primary
                  : themeContext.colors.textColor,
              }}>
              {`プロジェクトの${'\n'}カスタム項目の編集`}
            </Typography>
          </View>
          <View>
            <Switch
              value={isProjectCustomAttributePermissionFlg}
              onValueChange={(value) => setProjectCustomAttributePermissionFlg(value)}
              disabled={isAdminRole}
            />
          </View>
          <View style={{ paddingLeft: 10 }}>
            <Typography
              variant={
                TypographyType.Description
              }>{`プロジェクトのカスタム項目を編集できます。`}</Typography>
          </View>
        </View>
        <View
          style={{
            marginVertical: 10,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}>
          <View style={{ minWidth: 150 }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontWeight: '500',
                textAlign: 'left',
                color: isTaskCreatePermissionFlg
                  ? themeContext.colors.primary
                  : themeContext.colors.textColor,
              }}>
              {`タスクの作成`}
            </Typography>
          </View>
          <View>
            <Switch
              value={isTaskCreatePermissionFlg}
              onValueChange={(value) => setTaskCreatePermissionFlg(value)}
              disabled={isAdminRole}
            />
          </View>
          <View style={{ paddingLeft: 10 }}>
            <Typography variant={TypographyType.Description}>{`タスクを作成できます。`}</Typography>
          </View>
        </View>
        <View
          style={{
            marginVertical: 10,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}>
          <View style={{ minWidth: 150 }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontWeight: '500',
                textAlign: 'left',
                color: isTaskUpdatePermissionFlg
                  ? themeContext.colors.primary
                  : themeContext.colors.textColor,
              }}>
              {`タスクの更新`}
            </Typography>
          </View>
          <View>
            <Switch
              value={isTaskUpdatePermissionFlg}
              onValueChange={(value) => setTaskUpdatePermissionFlg(value)}
              disabled={isAdminRole}
            />
          </View>
          <View style={{ paddingLeft: 10 }}>
            <Typography variant={TypographyType.Description}>{`タスクを更新できます。`}</Typography>
          </View>
        </View>
        <View
          style={{
            marginVertical: 10,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}>
          <View style={{ minWidth: 150 }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontWeight: '500',
                textAlign: 'left',
                color: isTaskDeletePermissionFlg
                  ? themeContext.colors.primary
                  : themeContext.colors.textColor,
              }}>
              {`タスクの削除`}
            </Typography>
          </View>
          <View>
            <Switch
              value={isTaskDeletePermissionFlg}
              onValueChange={(value) => setTaskDeletePermissionFlg(value)}
              disabled={isAdminRole}
            />
          </View>
          <View style={{ paddingLeft: 10 }}>
            <Typography variant={TypographyType.Description}>{`タスクを削除できます。`}</Typography>
          </View>
        </View>
        <View
          style={{
            marginVertical: 10,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}>
          <View style={{ minWidth: 150 }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontWeight: '500',
                textAlign: 'left',
                color: isTaskCustomAttributePermissionFlg
                  ? themeContext.colors.primary
                  : themeContext.colors.textColor,
              }}>
              {`タスクの${'\n'}カスタム項目の編集`}
            </Typography>
          </View>
          <View>
            <Switch
              value={isTaskCustomAttributePermissionFlg}
              onValueChange={(value) => setTaskCustomAttributePermissionFlg(value)}
              disabled={isAdminRole}
            />
          </View>
          <View style={{ paddingLeft: 10 }}>
            <Typography
              variant={
                TypographyType.Description
              }>{`タスクのカスタム項目を編集できます。`}</Typography>
          </View>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 10 }}>
          <Button
            text="権限を変更する"
            completeText="権限を変更する"
            style={{ marginRight: 5, paddingVertical: 10, paddingHorizontal: 20 }}
            textStyle={{ fontSize: 14 }}
            onPress={async () => {
              if (!props.loginMember.adminRole && !props.loginMember.memberManagementRole) {
                setShowCanNotChangePermissionModal(true);
                return;
              }

              //管理者の権限変更は、管理者しかできない
              if (props.invitation.adminRole && !props.loginMember.adminRole) {
                setShowCanNotChangePermissionNotAdminModal(true);
                return;
              }
              if (!props.loginMember.adminRole && props.invitation.adminRole != isAdminRole) {
                setShowCanNotChangePermissionNotAdminModal(true);
                return;
              }
              await upateRole();
              setShowAlertModal(true);
            }}
          />
        </View>
      </Form>
      <Modal
        title={'権限を変更できません'}
        isShow={showCanNotChangePermissionNoExistAdminModal}
        onClose={() => {
          setShowCanNotChangePermissionNoExistAdminModal(false);
        }}>
        <Typography variant={TypographyType.Normal} style={{ textAlign: 'center', marginTop: 10 }}>
          管理者がいなくなってしまうため、この権限変更は出来ません。
        </Typography>
        <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
          <Button
            text={'OK'}
            style={{ minWidth: 100, marginRight: 10, marginVertical: 20 }}
            onPress={async () => {
              setShowCanNotChangePermissionNoExistAdminModal(false);
            }}
          />
        </View>
      </Modal>
      <Modal
        title={'権限を変更できません'}
        isShow={showCanNotChangePermissionNotAdminModal}
        onClose={() => {
          setShowCanNotChangePermissionNotAdminModal(false);
        }}>
        <Typography variant={TypographyType.Normal} style={{ textAlign: 'center', marginTop: 10 }}>
          管理者権限の変更を行うには、管理者権限が必要です。
        </Typography>
        <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
          <Button
            text={'OK'}
            style={{ minWidth: 100, marginRight: 10, marginVertical: 20 }}
            onPress={async () => {
              setShowCanNotChangePermissionNotAdminModal(false);
            }}
          />
        </View>
      </Modal>
      <Modal
        title={'権限を変更しました'}
        isShow={showAlertModal}
        onClose={() => {
          setShowAlertModal(false);
        }}>
        <Typography variant={TypographyType.Normal} style={{ textAlign: 'center', marginTop: 10 }}>
          権限設定の変更が完了しました
        </Typography>
        <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
          <Button
            text={'OK'}
            style={{ minWidth: 100, marginRight: 10, marginVertical: 20 }}
            onPress={async () => {
              setShowAlertModal(false);
            }}
          />
        </View>
      </Modal>
      <Modal
        title={'権限を変更できません'}
        isShow={showCanNotChangePermissioModal}
        onClose={() => {
          setShowCanNotChangePermissionModal(false);
        }}>
        <Typography
          variant={TypographyType.Normal}
          style={{ textAlign: 'center', marginVertical: 10 }}>
          権限を変更するには、管理者権限かメンバー管理権限が必要です。
        </Typography>
        <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
          <Button
            text={'OK'}
            style={{ minWidth: 100, marginRight: 10, marginVertical: 20 }}
            onPress={async () => {
              setShowCanNotChangePermissionModal(false);
            }}
          />
        </View>
      </Modal>
      <Modal
        title={'権限を変更できません'}
        isShow={showCanNotChangePermissioModal}
        onClose={() => {
          setShowCanNotChangePermissionModal(false);
        }}>
        <Typography
          variant={TypographyType.Normal}
          style={{ textAlign: 'center', marginVertical: 10 }}>
          管理者メンバーの権限を変更するには、管理者権限が必要です。
        </Typography>
        <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
          <Button
            text={'OK'}
            style={{ minWidth: 100, marginRight: 10, marginVertical: 20 }}
            onPress={async () => {
              setShowCanNotChangePermissionModal(false);
            }}
          />
        </View>
      </Modal>
    </SettingsElement>
  );
};

interface IMemberContainerInnerProps {
  member: Member;
}

const MemberContainerInner = React.memo((props: IMemberContainerInnerProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, __] = useContext(LoginUserContext);
  const { loading: meLoading, data: meData } = useMeQuery({
    fetchPolicy: 'network-only',
  });
  const { loading: twoFactorAuthEnablesdLoading, data: twoFactorAuthEnablesdData } =
    useTwoFactorAuthEnabledQuery({
      variables: {
        memberId: props.member.id!,
      },
      fetchPolicy: 'network-only',
    });

  if (meLoading || twoFactorAuthEnablesdLoading) {
    return <></>;
  }

  return (
    <CustomScrollView style={{ paddingBottom: 20 }}>
      <MainAreaHeader style={{} as any}>
        <Typography
          variant={TypographyType.Normal}
          style={[{ color: '#FFFFFF', fontWeight: '600' }, {}] as any}>
          {props.member.name}
        </Typography>
      </MainAreaHeader>
      <View
        style={{
          flexDirection: 'column',
          paddingVertical: 20,
          paddingHorizontal: 30,
        }}>
        <SettingsElement
          title={'名前'}
          titleWidth={170}
          changeText={'名前を変更する'}
          modal={(closeModal) => <NameModal closeModal={closeModal} member={props.member} />}>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Avatar
              size={30}
              name={props.member!.name || ''}
              imageUrl={props.member!.profileImageUrl}
            />
            <Typography variant={TypographyType.Normal} style={{ marginLeft: 5 }}>
              {props.member.name}
            </Typography>
          </View>
        </SettingsElement>
        <SettingsElement title={'メールアドレス'} titleWidth={170}>
          <Typography variant={TypographyType.Normal}>{props.member.mailAddress}</Typography>
        </SettingsElement>
        <SettingsElement
          title={'所属部署'}
          titleWidth={170}
          changeText={'所属部署を変更する'}
          modal={(closeModal) => <DepartmentModal closeModal={closeModal} member={props.member} />}>
          <Typography variant={TypographyType.Normal}>
            {props.member.department ?? '未登録'}
          </Typography>
        </SettingsElement>
        <SettingsElement
          title={'従業員番号'}
          titleWidth={170}
          changeText={'従業員番号を変更する'}
          modal={(closeModal) => <EmployeeModal closeModal={closeModal} member={props.member} />}>
          <Typography variant={TypographyType.Normal}>
            {props.member.employeeNumber ?? '未登録'}
          </Typography>
        </SettingsElement>
        <SettingsElement
          title={'2要素認証'}
          titleWidth={170}
          changeText={
            twoFactorAuthEnablesdData?.twoFactorAuthEnabled && meData?.me?.adminRole
              ? '2要素認証の設定を解除する'
              : ''
          }
          modal={(closeModal) =>
            meData?.me?.adminRole && (
              <TwoFactorAuthDeactivationModal closeModal={closeModal} member={props.member} />
            )
          }>
          <Typography variant={TypographyType.Normal}>
            {twoFactorAuthEnablesdData?.twoFactorAuthEnabled ? '設定済' : '未設定'}
          </Typography>
        </SettingsElement>
        <SettingsElement title={'ステータス'} titleWidth={170}>
          {props.member!.memberStatus === 'Active' && (
            <View>
              <Typography variant={TypographyType.Normal}>有効</Typography>
              <InactiveMenu member={props.member!} me={meData!.me!} />
            </View>
          )}
          {props.member!.memberStatus === 'Inactive' && (
            <View>
              <Typography variant={TypographyType.Normal}>無効</Typography>
              <ActiveMenu member={props.member!} me={meData!.me!} />
            </View>
          )}
        </SettingsElement>
        {(meData!.me!.adminRole ||
          meData!.me!.memberManagementRole ||
          meData!.me!.laborCostManagementRole) && (
          <HorizontalMenu
            style={{ justifyContent: 'flex-start', marginBottom: 20 }}
            textStyle={{ fontSize: 16, fontWeight: '600' }}
            menus={[
              {
                title: '権限',
                path: `/app/${loginUser!.organizationId}/members/list/member/${props.member
                  .id!}/permission`,
              },
              {
                title: '人件費(時給・標準単価)',
                path: `/app/${loginUser!.organizationId}/members/list/member/${props.member
                  .id!}/labor-cost`,
              },
            ]}
          />
        )}
        <RouterSwitch>
          <Route path="/app/:organizationId/members/list/member/:memberId/labor-cost">
            {meData!.me!.adminRole || meData!.me!.laborCostManagementRole ? (
              <LaborCostList member={props.member} loginMember={meData!.me!} />
            ) : (
              <Typography
                variant={TypographyType.Normal}
                style={{
                  fontWeight: '500',
                  textAlign: 'left',
                  color: themeContext.colors.textColor,
                }}>
                人件費・売上管理権限が必要です
              </Typography>
            )}
          </Route>
          <Route path="/app/:organizationId/members/list/member/:memberId/permission">
            {meData!.me!.adminRole || meData!.me!.memberManagementRole ? (
              <MemberPermission member={props.member} loginMember={meData!.me!} />
            ) : (
              <Typography
                variant={TypographyType.Normal}
                style={{
                  fontWeight: '500',
                  textAlign: 'left',
                  color: themeContext.colors.textColor,
                }}>
                メンバー管理権限が必要です
              </Typography>
            )}
          </Route>
        </RouterSwitch>
      </View>
    </CustomScrollView>
  );
});

interface IResendInviteMemberDialogProps {
  invitation: MemberInvitation;
  onComplete: () => void;
  onCancel: () => void;
}

const ResendInviteMemberDialog = (props: IResendInviteMemberDialogProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, __] = useContext(LoginUserContext);
  const [resendInvite, _] = useResendInvitationMutation({
    variables: {
      key: props.invitation.invitationKey,
    },
  });

  return (
    <Form>
      <View style={{ paddingTop: 10, paddingBottom: 30, paddingHorizontal: 20 }}>
        <Typography variant={TypographyType.Normal} style={{ textAlign: 'center' }}>
          {`${props.invitation.mailAddress}への${'\n'}招待メールを再送しますか？`}
        </Typography>
      </View>
      <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
        <Button
          text="招待メールを再送する"
          completeText="招待メールを再送しました"
          style={{ height: 30, marginRight: 5 }}
          textStyle={{ fontSize: 14 }}
          onPress={async () => {
            await resendInvite();
            props.onComplete();
          }}
        />
        <Button
          text="キャンセル"
          style={{ height: 30, backgroundColor: 'transparent' }}
          textStyle={{ fontSize: 14, color: themeContext.colors.primary }}
          onPress={async () => {
            props.onCancel();
          }}
          disableValidate={true}
        />
      </View>
    </Form>
  );
};

interface ICancelInviteMemberDialogProps {
  invitation: MemberInvitation;
  onComplete: () => void;
  onCancel: () => void;
}

const CancelInviteMemberDialog = (props: ICancelInviteMemberDialogProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const history = useHistory();
  const [loginUser, __] = useContext(LoginUserContext);
  const [deleteInvite, _] = useDeleteInvitationMutation({
    variables: {
      key: props.invitation.invitationKey,
    },
    refetchQueries: [
      {
        query: OrganizationMembersDocument,
        variables: {
          organizationId: loginUser!.organizationId,
        },
      },
      {
        query: MemberInvitationsDocument,
      },
    ],
  });

  return (
    <Form>
      <View style={{ paddingTop: 10, paddingBottom: 30, paddingHorizontal: 20 }}>
        <Typography variant={TypographyType.Normal} style={{ textAlign: 'center' }}>
          {`${props.invitation.mailAddress}への${'\n'}招待を取り消しますか？`}
        </Typography>
      </View>
      <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
        <Button
          text="招待を取り消す"
          completeText="招待を取り消しました"
          style={{ height: 30, marginRight: 5 }}
          textStyle={{ fontSize: 14 }}
          onPress={async () => {
            await deleteInvite();
            props.onComplete();
            history.push(`/app/${loginUser!.organizationId}/members/list/`);
          }}
        />
        <Button
          text="キャンセル"
          style={{ height: 30, backgroundColor: 'transparent' }}
          textStyle={{ fontSize: 14, color: themeContext.colors.primary }}
          onPress={async () => {
            props.onCancel();
          }}
          disableValidate={true}
        />
      </View>
    </Form>
  );
};

interface IDeleteInviteLinkDialogProps {
  invitationLink: MemberInvitationLink;
  onComplete: () => void;
  onCancel: () => void;
}

const DeleteInviteLinkDialog = (props: IDeleteInviteLinkDialogProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const history = useHistory();
  const [loginUser, __] = useContext(LoginUserContext);
  const [deleteInvitationLink, _] = useDeleteInvitationLinkMutation({
    variables: {
      id: props.invitationLink.id,
    },
    update: (cache, result) => {
      cache.evict({
        id: cache.identify(props.invitationLink),
      });
    },
  });

  return (
    <Form>
      <View style={{ paddingTop: 10, paddingBottom: 30, paddingHorizontal: 20 }}>
        <Typography variant={TypographyType.Normal} style={{ textAlign: 'center' }}>
          {`${props.invitationLink.memo || '(メモなし)'}の${'\n'}招待リンクを削除しますか？`}
        </Typography>
      </View>
      <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
        <Button
          text="招待リンクを削除する"
          completeText="招待リンクを削除しました"
          style={{ height: 30, marginRight: 5 }}
          textStyle={{ fontSize: 14 }}
          onPress={async () => {
            await deleteInvitationLink();
            props.onComplete();
            history.push(`/app/${loginUser!.organizationId}/members/invitation-links/`);
          }}
        />
        <Button
          text="キャンセル"
          style={{ height: 30, backgroundColor: 'transparent' }}
          textStyle={{ fontSize: 14, color: themeContext.colors.primary }}
          onPress={async () => {
            props.onCancel();
          }}
          disableValidate={true}
        />
      </View>
    </Form>
  );
};

interface IInvitationLinkContainerInnerProps {
  invitationLink: MemberInvitationLink;
}

const InvitationLinkContainerInner = React.memo((props: IInvitationLinkContainerInnerProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCopyUrlModal, setShowCopyUrlModal] = useState(false);
  const [showCanNotDeleteInviteModal, setShowCanNotDeleteInviteModal] = useState(false);
  const { loading: meLoading, data: meData } = useMeQuery({
    fetchPolicy: 'network-only',
  });
  const [updateInvitationLink, _] = useUpdateInvitationLinkMutation();

  if (meLoading) {
    return <></>;
  }

  return (
    <>
      <MainAreaHeader style={{} as any}>
        <Typography
          variant={TypographyType.Normal}
          style={[{ color: '#FFFFFF', fontWeight: '600' }, {}] as any}>
          {props.invitationLink.memo || '（メモなし）'}
        </Typography>
      </MainAreaHeader>
      <View
        style={{
          flexDirection: 'column',
          paddingVertical: 20,
          paddingHorizontal: 30,
        }}>
        <SettingsElement title={'メモ'} titleWidth={170} containerStyle={{ marginBottom: 10 }}>
          <EditableText
            style={{ width: '100%', margin: 0, padding: 0 }}
            value={props.invitationLink.memo || ''}
            emptyText={'（なし）'}
            validate={{
              maxLength: {
                value: 100,
                message: '100文字以内で入力してください',
              },
            }}
            onChange={(value) => {
              updateInvitationLink({
                variables: {
                  id: props.invitationLink.id,
                  input: {
                    memo: (value as string).trim() || null,
                    versionNo: props.invitationLink.versionNo,
                  },
                },
              });
            }}
            textStyle={{ fontSize: 18 }}
          />
          <Typography variant={TypographyType.Description} style={{ marginTop: 5, fontSize: 13 }}>
            {`このメモは招待リンクを管理する際に分かりやすくするためだけの情報です。${'\n'}招待された人には、このメモは見えません。`}
          </Typography>
        </SettingsElement>
        <SettingsElement
          title={'招待リンク'}
          titleWidth={170}
          containerStyle={{ marginBottom: 10, marginTop: 20 }}>
          <Typography variant={TypographyType.Normal} style={{ fontSize: 11 }}>
            {props.invitationLink.invitationUrl}
          </Typography>
        </SettingsElement>
        <View style={{ marginLeft: 170 }}>
          <LinkIcon
            size={13}
            onPress={() => {
              Clipboard.setString(props.invitationLink.invitationUrl);
              setShowCopyUrlModal(true);
            }}>
            <Typography
              variant={TypographyType.Normal}
              style={{ color: themeContext.colors.link, fontSize: 13 }}>
              招待リンクURLをコピーする
            </Typography>
          </LinkIcon>
        </View>
        <SettingsElement title={'権限'} titleWidth={170} containerStyle={{ marginTop: 20 }}>
          <Form>
            <View
              style={{
                marginVertical: 10,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 150 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: themeContext.colors.textColor,
                  }}>
                  管理者
                </Typography>
              </View>
              <View>
                <Typography
                  variant={TypographyType.Description}
                  style={{ fontSize: 20, width: 40 }}>
                  {'OFF'}
                </Typography>
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={
                    TypographyType.Description
                  }>{`全ての操作が可能です。${'\n'}(作業データの代理編集以外)`}</Typography>
              </View>
            </View>
            <View
              style={{
                marginVertical: 10,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 150 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: themeContext.colors.textColor,
                  }}>
                  {`作業データの${'\n'}代理編集`}
                </Typography>
              </View>
              <View>
                <Typography
                  variant={TypographyType.Description}
                  style={{ fontSize: 20, width: 40 }}>
                  {'OFF'}
                </Typography>
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={
                    TypographyType.Description
                  }>{`他のメンバーの作業履歴・作業予定データの編集を行えます`}</Typography>
              </View>
            </View>
            <View
              style={{
                marginVertical: 10,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 150 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: themeContext.colors.textColor,
                  }}>
                  メンバー管理
                </Typography>
              </View>
              <View>
                <Typography
                  variant={TypographyType.Description}
                  style={{ fontSize: 20, width: 40 }}>
                  {'OFF'}
                </Typography>
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={
                    TypographyType.Description
                  }>{`メンバーの追加・削除ができます。`}</Typography>
              </View>
            </View>
            <View
              style={{
                marginVertical: 10,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 150 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: props.invitationLink.clientManagementRole
                      ? themeContext.colors.primary
                      : themeContext.colors.textColor,
                  }}>
                  取引先管理
                </Typography>
              </View>
              <View>
                <Typography
                  variant={TypographyType.Description}
                  style={{
                    fontSize: 20,
                    width: 40,
                    color: props.invitationLink.clientManagementRole
                      ? themeContext.colors.primary
                      : themeContext.colors.description,
                  }}>
                  {props.invitationLink.clientManagementRole ? 'ON' : 'OFF'}
                </Typography>
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={
                    TypographyType.Description
                  }>{`取引先の追加・削除ができます。`}</Typography>
              </View>
            </View>
            <View
              style={{
                marginVertical: 10,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 150 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: themeContext.colors.textColor,
                  }}>
                  人件費・売上管理
                </Typography>
              </View>
              <View>
                <Typography
                  variant={TypographyType.Description}
                  style={{
                    fontSize: 20,
                    width: 40,
                    color: themeContext.colors.description,
                  }}>
                  {'OFF'}
                </Typography>
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={
                    TypographyType.Description
                  }>{`人件費・契約情報の登録・編集・閲覧ができます。`}</Typography>
              </View>
            </View>
            {/* <View
              style={{
                marginVertical: 10,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 150 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: themeContext.colors.textColor,
                  }}>
                  売上管理
                </Typography>
              </View>
              <View>
                <Typography
                  variant={TypographyType.Description}
                  style={{
                    fontSize: 20,
                    width: 40,
                    color: themeContext.colors.description,
                  }}>
                  {'OFF'}
                </Typography>
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={
                    TypographyType.Description
                  }>{`売上の登録・編集・閲覧ができます。`}</Typography>
              </View>
            </View> */}
            <View
              style={{
                marginVertical: 10,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 150 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: props.invitationLink.organizationReportRole
                      ? themeContext.colors.primary
                      : themeContext.colors.textColor,
                  }}>
                  {`組織の${'\n'}レポート閲覧`}
                </Typography>
              </View>
              <View>
                <Typography
                  variant={TypographyType.Description}
                  style={{
                    fontSize: 20,
                    width: 40,
                    color: props.invitationLink.organizationReportRole
                      ? themeContext.colors.primary
                      : themeContext.colors.description,
                  }}>
                  {props.invitationLink.organizationReportRole ? 'ON' : 'OFF'}
                </Typography>
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={
                    TypographyType.Description
                  }>{`組織の時間分析画面を閲覧できます。`}</Typography>
              </View>
            </View>
            <View
              style={{
                marginVertical: 10,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 150 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: props.invitationLink.organizationMemberViewRole
                      ? themeContext.colors.primary
                      : themeContext.colors.textColor,
                  }}>
                  {`組織全員の${'\n'}作業履歴の閲覧`}
                </Typography>
              </View>
              <View>
                <Typography
                  variant={TypographyType.Description}
                  style={{
                    fontSize: 20,
                    width: 40,
                    color: props.invitationLink.organizationMemberViewRole
                      ? themeContext.colors.primary
                      : themeContext.colors.description,
                  }}>
                  {props.invitationLink.organizationMemberViewRole ? 'ON' : 'OFF'}
                </Typography>
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={
                    TypographyType.Description
                  }>{`組織全員の作業履歴や作業予定を${'\n'}メンバーの状況画面やカレンダーで閲覧できます。`}</Typography>
              </View>
            </View>
            <View
              style={{
                marginVertical: 10,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 150 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: props.invitationLink.teamReportRole
                      ? themeContext.colors.primary
                      : themeContext.colors.textColor,
                  }}>
                  {`チームの${'\n'}レポート閲覧`}
                </Typography>
              </View>
              <View>
                <Typography
                  variant={TypographyType.Description}
                  style={{
                    fontSize: 20,
                    width: 40,
                    color: props.invitationLink.teamReportRole
                      ? themeContext.colors.primary
                      : themeContext.colors.description,
                  }}>
                  {props.invitationLink.teamReportRole ? 'ON' : 'OFF'}
                </Typography>
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={
                    TypographyType.Description
                  }>{`所属しているチームの${'\n'}時間分析画面を閲覧できます。`}</Typography>
              </View>
            </View>

            <View
              style={{
                marginVertical: 10,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 150 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: props.invitationLink.teamMemberViewRole
                      ? themeContext.colors.primary
                      : themeContext.colors.textColor,
                  }}>
                  {`チームメンバーの${'\n'}作業履歴の閲覧`}
                </Typography>
              </View>
              <View>
                <Typography
                  variant={TypographyType.Description}
                  style={{
                    fontSize: 20,
                    width: 40,
                    color: props.invitationLink.teamMemberViewRole
                      ? themeContext.colors.primary
                      : themeContext.colors.description,
                  }}>
                  {props.invitationLink.teamMemberViewRole ? 'ON' : 'OFF'}
                </Typography>
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={
                    TypographyType.Description
                  }>{`所属しているチームのメンバーの${'\n'}作業履歴や作業予定を${'\n'}メンバーの状況画面やカレンダーで閲覧できます。`}</Typography>
              </View>
            </View>
            <View
              style={{
                marginVertical: 10,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 150 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: props.invitationLink.projectCreatePermissionFlg
                      ? themeContext.colors.primary
                      : themeContext.colors.textColor,
                  }}>
                  {`プロジェクトの作成`}
                </Typography>
              </View>
              <View>
                <Typography
                  variant={TypographyType.Description}
                  style={{
                    fontSize: 20,
                    width: 40,
                    color: props.invitationLink.projectCreatePermissionFlg
                      ? themeContext.colors.primary
                      : themeContext.colors.description,
                  }}>
                  {props.invitationLink.projectCreatePermissionFlg ? 'ON' : 'OFF'}
                </Typography>
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={TypographyType.Description}>{`プロジェクトを作成できます。`}</Typography>
              </View>
            </View>
            <View
              style={{
                marginVertical: 10,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 150 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: props.invitationLink.projectUpdatePermissionFlg
                      ? themeContext.colors.primary
                      : themeContext.colors.textColor,
                  }}>
                  {`プロジェクトの更新`}
                </Typography>
              </View>
              <View>
                <Typography
                  variant={TypographyType.Description}
                  style={{
                    fontSize: 20,
                    width: 40,
                    color: props.invitationLink.projectUpdatePermissionFlg
                      ? themeContext.colors.primary
                      : themeContext.colors.description,
                  }}>
                  {props.invitationLink.projectUpdatePermissionFlg ? 'ON' : 'OFF'}
                </Typography>
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={TypographyType.Description}>{`プロジェクトを更新できます。`}</Typography>
              </View>
            </View>
            <View
              style={{
                marginVertical: 10,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 150 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: props.invitationLink.projectDeletePermissionFlg
                      ? themeContext.colors.primary
                      : themeContext.colors.textColor,
                  }}>
                  {`プロジェクトの削除`}
                </Typography>
              </View>
              <View>
                <Typography
                  variant={TypographyType.Description}
                  style={{
                    fontSize: 20,
                    width: 40,
                    color: props.invitationLink.projectDeletePermissionFlg
                      ? themeContext.colors.primary
                      : themeContext.colors.description,
                  }}>
                  {props.invitationLink.projectDeletePermissionFlg ? 'ON' : 'OFF'}
                </Typography>
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={TypographyType.Description}>{`プロジェクトを削除できます。`}</Typography>
              </View>
            </View>
            <View
              style={{
                marginVertical: 10,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 150 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: props.invitationLink.projectCustomAttributePermissionFlg
                      ? themeContext.colors.primary
                      : themeContext.colors.textColor,
                  }}>
                  {`プロジェクトのカスタム項目の編集`}
                </Typography>
              </View>
              <View>
                <Typography
                  variant={TypographyType.Description}
                  style={{
                    fontSize: 20,
                    width: 40,
                    color: props.invitationLink.projectCustomAttributePermissionFlg
                      ? themeContext.colors.primary
                      : themeContext.colors.description,
                  }}>
                  {props.invitationLink.projectCustomAttributePermissionFlg ? 'ON' : 'OFF'}
                </Typography>
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={
                    TypographyType.Description
                  }>{`プロジェクトのカスタム項目を編集できます。`}</Typography>
              </View>
            </View>
            <View
              style={{
                marginVertical: 10,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 150 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: props.invitationLink.taskCreatePermissionFlg
                      ? themeContext.colors.primary
                      : themeContext.colors.textColor,
                  }}>
                  {`タスクの作成`}
                </Typography>
              </View>
              <View>
                <Typography
                  variant={TypographyType.Description}
                  style={{
                    fontSize: 20,
                    width: 40,
                    color: props.invitationLink.taskCreatePermissionFlg
                      ? themeContext.colors.primary
                      : themeContext.colors.description,
                  }}>
                  {props.invitationLink.taskCreatePermissionFlg ? 'ON' : 'OFF'}
                </Typography>
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={TypographyType.Description}>{`タスクを作成できます。`}</Typography>
              </View>
            </View>
            <View
              style={{
                marginVertical: 10,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 150 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: props.invitationLink.taskUpdatePermissionFlg
                      ? themeContext.colors.primary
                      : themeContext.colors.textColor,
                  }}>
                  {`タスクの更新`}
                </Typography>
              </View>
              <View>
                <Typography
                  variant={TypographyType.Description}
                  style={{
                    fontSize: 20,
                    width: 40,
                    color: props.invitationLink.taskUpdatePermissionFlg
                      ? themeContext.colors.primary
                      : themeContext.colors.description,
                  }}>
                  {props.invitationLink.taskUpdatePermissionFlg ? 'ON' : 'OFF'}
                </Typography>
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={TypographyType.Description}>{`タスクを更新できます。`}</Typography>
              </View>
            </View>
            <View
              style={{
                marginVertical: 10,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 150 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: props.invitationLink.taskDeletePermissionFlg
                      ? themeContext.colors.primary
                      : themeContext.colors.textColor,
                  }}>
                  {`タスクの削除`}
                </Typography>
              </View>
              <View>
                <Typography
                  variant={TypographyType.Description}
                  style={{
                    fontSize: 20,
                    width: 40,
                    color: props.invitationLink.taskDeletePermissionFlg
                      ? themeContext.colors.primary
                      : themeContext.colors.description,
                  }}>
                  {props.invitationLink.taskDeletePermissionFlg ? 'ON' : 'OFF'}
                </Typography>
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={TypographyType.Description}>{`タスクを削除できます。`}</Typography>
              </View>
            </View>
            <View
              style={{
                marginVertical: 10,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <View style={{ minWidth: 150 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    fontWeight: '500',
                    textAlign: 'left',
                    color: props.invitationLink.taskCustomAttributePermissionFlg
                      ? themeContext.colors.primary
                      : themeContext.colors.textColor,
                  }}>
                  {`タスクのカスタム項目の編集`}
                </Typography>
              </View>
              <View>
                <Typography
                  variant={TypographyType.Description}
                  style={{
                    fontSize: 20,
                    width: 40,
                    color: props.invitationLink.taskCustomAttributePermissionFlg
                      ? themeContext.colors.primary
                      : themeContext.colors.description,
                  }}>
                  {props.invitationLink.taskCustomAttributePermissionFlg ? 'ON' : 'OFF'}
                </Typography>
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={
                    TypographyType.Description
                  }>{`タスクのカスタム項目を編集できます。`}</Typography>
              </View>
            </View>
            <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 10 }}>
              <Button
                text="招待リンクを削除する"
                style={{ marginRight: 5, paddingVertical: 10, paddingHorizontal: 20 }}
                textStyle={{ fontSize: 14 }}
                onPress={async () => {
                  if (!meData!.me!.adminRole && !meData!.me!.memberManagementRole) {
                    setShowCanNotDeleteInviteModal(true);
                    return;
                  }
                  setShowDeleteModal(true);
                }}
              />
            </View>
          </Form>
          <Modal
            title={'招待リンクを削除できません'}
            isShow={showCanNotDeleteInviteModal}
            onClose={() => {
              setShowCanNotDeleteInviteModal(false);
            }}>
            <Typography
              variant={TypographyType.Normal}
              style={{ textAlign: 'center', marginVertical: 10 }}>
              招待リンクを削除するには、管理者権限かメンバー管理権限が必要です。
            </Typography>
            <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
              <Button
                text={'OK'}
                style={{ minWidth: 100, marginRight: 10, marginVertical: 20 }}
                onPress={async () => {
                  setShowCanNotDeleteInviteModal(false);
                }}
              />
            </View>
          </Modal>
          <Modal
            title={'招待リンクURLをコピーしました'}
            isShow={showCopyUrlModal}
            onClose={() => {
              setShowCopyUrlModal(false);
            }}>
            <Typography
              variant={TypographyType.Normal}
              style={{ textAlign: 'center', marginVertical: 10 }}>
              {`招待リンクURLをコピーしました。${'\n'}招待したい方々にこのURLを伝えてください。`}
            </Typography>
            <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
              <Button
                text={'OK'}
                style={{ minWidth: 100, marginRight: 10, marginVertical: 20 }}
                onPress={async () => {
                  setShowCopyUrlModal(false);
                }}
              />
            </View>
          </Modal>
        </SettingsElement>
        <Modal
          title={'招待リンクを削除する'}
          isShow={showDeleteModal}
          onClose={() => {
            setShowDeleteModal(false);
          }}>
          <DeleteInviteLinkDialog
            invitationLink={props.invitationLink}
            onComplete={() => {
              setShowDeleteModal(false);
            }}
            onCancel={() => {
              setShowDeleteModal(false);
            }}
          />
        </Modal>
      </View>
    </>
  );
});

const MemberContainer = React.memo(() => {
  const params = useParams();
  const { loading, data } = useMemberQuery({
    variables: {
      memberId: (params as any).memberId,
    },
    fetchPolicy: 'network-only',
  });
  if (loading || !data?.member) {
    return <></>;
  }
  return <MemberContainerInner member={data!.member} />;
});

interface IInvitationContainerInnerProps {
  invitation: MemberInvitation;
}

const InvitationContainerInner = React.memo((props: IInvitationContainerInnerProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [showCancelInviteModal, setShowCancelInviteModal] = useState(false);
  const [showResendInviteModal, setShowResendInviteModal] = useState(false);
  const [showCanNotResentInviteModal, setShowCanNotResentInviteModal] = useState(false);
  const [showCanNotCancelInviteModal, setShowCanNotCancelInviteModal] = useState(false);
  const [isAdminRole, setAdminRole] = useState(props.invitation.adminRole);
  const { loading: meLoading, data: meData } = useMeQuery({
    fetchPolicy: 'network-only',
  });

  if (meLoading) {
    return <></>;
  }

  return (
    <CustomScrollView style={{ paddingBottom: 50 }}>
      <MainAreaHeader style={{} as any}>
        <Typography
          variant={TypographyType.Normal}
          style={[{ color: '#FFFFFF', fontWeight: '600' }, {}] as any}>
          {props.invitation.mailAddress}
        </Typography>
      </MainAreaHeader>
      <View
        style={{
          flexDirection: 'column',
          paddingVertical: 20,
          paddingHorizontal: 30,
        }}>
        <SettingsElement
          title={'招待メールアドレス'}
          titleWidth={170}
          containerStyle={{ marginBottom: 10 }}>
          <Typography variant={TypographyType.Normal}>{props.invitation.mailAddress}</Typography>
        </SettingsElement>
        <View style={{ marginLeft: 170, marginBottom: 15 }}>
          <TouchableOpacity
            onPress={() => {
              if (!meData!.me!.adminRole && !meData!.me!.memberManagementRole) {
                setShowCanNotResentInviteModal(true);
                return;
              }
              setShowResendInviteModal(true);
            }}>
            <Typography
              variant={TypographyType.Normal}
              style={{ color: themeContext.colors.link, fontSize: 13 }}>
              招待メールを再送する
            </Typography>
          </TouchableOpacity>
        </View>
        <View style={{ marginLeft: 170, marginBottom: 30 }}>
          <TouchableOpacity
            onPress={() => {
              if (!meData!.me!.adminRole && !meData!.me!.memberManagementRole) {
                setShowCanNotCancelInviteModal(true);
                return;
              }
              setShowCancelInviteModal(true);
            }}>
            <Typography
              variant={TypographyType.Normal}
              style={{ color: themeContext.colors.link, fontSize: 13 }}>
              招待を取り消す
            </Typography>
          </TouchableOpacity>
        </View>
        <SettingsElement
          title={'名前'}
          titleWidth={170}
          changeText={'名前を変更する'}
          modal={(closeModal) => (
            <InvitationNameModal closeModal={closeModal} invitation={props.invitation} />
          )}>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Avatar size={30} name={props.invitation!.name || ''} />
            <Typography variant={TypographyType.Normal} style={{ marginLeft: 5 }}>
              {props.invitation.name}
            </Typography>
          </View>
        </SettingsElement>
        <SettingsElement
          title={'所属部署'}
          titleWidth={170}
          changeText={'所属部署を変更する'}
          modal={(closeModal) => (
            <InvitationDepartmentModal closeModal={closeModal} invitation={props.invitation} />
          )}>
          <Typography variant={TypographyType.Normal}>
            {props.invitation.department ?? '未登録'}
          </Typography>
        </SettingsElement>
        <SettingsElement
          title={'従業員番号'}
          titleWidth={170}
          changeText={'従業員番号を変更する'}
          modal={(closeModal) => (
            <InvitationEmployeeModal closeModal={closeModal} invitation={props.invitation} />
          )}>
          <Typography variant={TypographyType.Normal}>
            {props.invitation.employeeNumber ?? '未登録'}
          </Typography>
        </SettingsElement>
      </View>
      <View
        style={{
          flexDirection: 'column',
          paddingVertical: 20,
          paddingHorizontal: 30,
        }}>
        {(meData!.me!.adminRole ||
          meData!.me!.memberManagementRole ||
          meData!.me!.laborCostManagementRole) && (
          <HorizontalMenu
            style={{ justifyContent: 'flex-start', marginBottom: 20 }}
            textStyle={{ fontSize: 16, fontWeight: '600' }}
            menus={[
              {
                title: '権限',
                path: `/app/${loginUser!.organizationId}/members/list/invitation/${props.invitation
                  .id!}/permission`,
              },
              {
                title: '人件費(時給・標準単価)',
                path: `/app/${loginUser!.organizationId}/members/list/invitation/${props.invitation
                  .id!}/labor-cost`,
              },
            ]}
          />
        )}
        <RouterSwitch>
          <Route path="/app/:organizationId/members/list/invitation/:invitationId/labor-cost">
            {meData!.me!.adminRole || meData!.me!.laborCostManagementRole ? (
              <Typography
                variant={TypographyType.Normal}
                style={{
                  fontWeight: '500',
                  textAlign: 'left',
                  color: isAdminRole ? themeContext.colors.primary : themeContext.colors.textColor,
                }}>
                人件費の設定は、メンバーが組織に参加した後にしか行なえません。
              </Typography>
            ) : (
              <Typography
                variant={TypographyType.Normal}
                style={{
                  fontWeight: '500',
                  textAlign: 'left',
                  color: themeContext.colors.textColor,
                }}>
                人件費・売上管理権限が必要です
              </Typography>
            )}
          </Route>
          <Route path="/app/:organizationId/members/list/invitation/:invitationId/permission">
            {meData!.me!.adminRole || meData!.me!.memberManagementRole ? (
              <MemberInvitationPermission invitation={props.invitation} loginMember={meData!.me!} />
            ) : (
              <Typography
                variant={TypographyType.Normal}
                style={{
                  fontWeight: '500',
                  textAlign: 'left',
                  color: themeContext.colors.textColor,
                }}>
                メンバー管理権限が必要です
              </Typography>
            )}
          </Route>
        </RouterSwitch>
        <Modal
          title={'招待メールを再送する'}
          isShow={showResendInviteModal}
          onClose={() => {
            setShowResendInviteModal(false);
          }}>
          <ResendInviteMemberDialog
            invitation={props.invitation}
            onComplete={() => {
              setShowResendInviteModal(false);
            }}
            onCancel={() => {
              setShowResendInviteModal(false);
            }}
          />
        </Modal>
        <Modal
          title={'招待を取り消しする'}
          isShow={showCancelInviteModal}
          onClose={() => {
            setShowCancelInviteModal(false);
          }}>
          <CancelInviteMemberDialog
            invitation={props.invitation}
            onComplete={() => {
              setShowCancelInviteModal(false);
            }}
            onCancel={() => {
              setShowCancelInviteModal(false);
            }}
          />
        </Modal>
      </View>
    </CustomScrollView>
  );
});

const InvitationLinkContainer = React.memo(() => {
  const params = useParams();
  const { loading, data } = useMemberInvitationLinkQuery({
    variables: {
      key: (params as any).invitationKey,
    },
    fetchPolicy: 'network-only',
  });
  if (loading || !data?.memberInvitationLink) {
    return <></>;
  }
  return <InvitationLinkContainerInner invitationLink={data!.memberInvitationLink!} />;
});

const InvitationContainer = React.memo(() => {
  const params = useParams();
  const { loading, data } = useMemberInvitationQuery({
    variables: {
      key: (params as any).invitationKey,
    },
    fetchPolicy: 'network-only',
  });
  if (loading || !data?.memberInvitation) {
    return <></>;
  }
  return <InvitationContainerInner invitation={data!.memberInvitation!} />;
});

const MemberMainArea = React.memo(() => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const { window } = useDimensions();
  const { loading, data } = useOrganizationMembersQuery({
    variables: {
      organizationId: loginUser!.organizationId,
    },
  });

  if (loading) {
    return <></>;
  }

  return (
    <MainAreaContainer>
      <RouterSwitch>
        <Route path="/app/:organizationId/members/list/member/:memberId/">
          <MemberContainer />
        </Route>
        <Route path="/app/:organizationId/members/list/invitation/:invitationKey/">
          <InvitationContainer />
        </Route>
        <Route>
          <View
            style={{
              height: 'calc(100vh - 57px)',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            {data?.organizationMembers && (data?.organizationMembers || []).length > 0 ? (
              <>
                <Image
                  source={{ uri: noData01 }}
                  resizeMode="contain"
                  style={{
                    width: Math.min(window.width / 4, 300),
                    height: 200,
                    opacity: 0.7,
                  }}
                />
                <Typography
                  variant={TypographyType.Normal}
                  style={{ color: themeContext.colors.description }}>
                  メンバーを選択してください
                </Typography>
              </>
            ) : (
              <>
                <Image
                  source={{ uri: noData03 }}
                  resizeMode="contain"
                  style={{
                    width: Math.min(window.width / 4, 300),
                    height: 200,
                    opacity: 0.7,
                  }}
                />
                <Typography
                  variant={TypographyType.Normal}
                  style={{ color: themeContext.colors.description }}>
                  メンバーを登録してください
                </Typography>
              </>
            )}
          </View>
        </Route>
      </RouterSwitch>
    </MainAreaContainer>
  );
});

const InvitationLinkMainArea = React.memo(() => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const { window } = useDimensions();
  const { loading, data } = useMemberInvitationLinksQuery();

  if (loading) {
    return <></>;
  }

  return (
    <MainAreaContainer>
      <CustomScrollView style={{ paddingBottom: 50 }}>
        <RouterSwitch>
          <Route path="/app/:organizationId/members/invitation-links/:invitationKey/">
            <InvitationLinkContainer />
          </Route>
          <Route>
            <View
              style={{
                height: 'calc(100vh - 57px)',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              {data?.memberInvitationLinks && (data?.memberInvitationLinks || []).length > 0 ? (
                <>
                  <Image
                    source={{ uri: noData01 }}
                    resizeMode="contain"
                    style={{
                      width: Math.min(window.width / 4, 300),
                      height: 200,
                      opacity: 0.7,
                    }}
                  />
                  <Typography
                    variant={TypographyType.Normal}
                    style={{ color: themeContext.colors.description }}>
                    招待リンクを選択してください
                  </Typography>
                </>
              ) : (
                <>
                  <Image
                    source={{ uri: noData03 }}
                    resizeMode="contain"
                    style={{
                      width: Math.min(window.width / 4, 300),
                      height: 200,
                      opacity: 0.7,
                    }}
                  />
                  <Typography
                    variant={TypographyType.Normal}
                    style={{ color: themeContext.colors.description }}>
                    招待リンクを登録してください
                  </Typography>
                </>
              )}
            </View>
          </Route>
        </RouterSwitch>
      </CustomScrollView>
    </MainAreaContainer>
  );
});

const AppOrganizationClientsPage = () => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  return (
    <Container>
      <CustomScrollView
        style={{
          maxWidth: 200,
          height: 'calc(100vh - 57px)',
        }}>
        <View
          style={{
            backgroundColor: themeContext.colors.header,
            paddingVertical: 7,
            paddingHorizontal: 3,
          }}>
          <Typography
            variant={TypographyType.SubTitle}
            ellipsis={true}
            style={{ color: '#FFFFFF', fontSize: 16, fontWeight: '600', maxWidth: 200 }}>
            組織のメンバー管理
          </Typography>
        </View>
        <TextList
          items={[
            {
              data: 'メンバー一覧',
              path: `/app/${loginUser!.organizationId}/members/list`,
            },
            {
              data: '招待リンク一覧',
              path: `/app/${loginUser!.organizationId}/members/invitation-links`,
            },
          ]}
          style={{ width: 200 }}
          textStyle={{ fontSize: 14 }}
          onPress={(item) => {}}
        />
      </CustomScrollView>
      <RouterSwitch>
        <Route path={`/app/:organizationId/members/list`} component={MemberListWrapper} />
        <Route
          path={`/app/:organizationId/members/invitation-links`}
          component={InvitationListWrapper}
        />
        <Redirect to={`/app/:organizationId/members/list`} />
      </RouterSwitch>
      <RouterSwitch>
        <Route path={`/app/:organizationId/members/list`} component={MemberMainArea} />
        <Route
          path={`/app/:organizationId/members/invitation-links`}
          component={InvitationLinkMainArea}
        />
        <Redirect to={`/app/:organizationId/members/list`} />
      </RouterSwitch>
    </Container>
  );
};

export default React.memo(AppOrganizationClientsPage);
