//@ts-ignore
import Clipboard from '@react-native-clipboard/clipboard';
import moment from 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { ActivityIndicator, View } from 'react-native';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import {
  ICalExportStatus,
  ICalFileType,
  type MemberSettings,
  Plan,
  useCreateICalFileDownloadUrlMutation,
  useExportICalFileMutation,
  useICalExportQuery,
  useICalKeyQuery,
  useMySettingsQuery,
  useOrganizationQuery,
  useUpdateMySettingsMutation,
} from '../../../../../../../graphql/api/API';
import { LoginUserContext } from '../../../../../../../modules/auth/LoginUserContext';
import DownloadUtil from '../../../../../../../util/DownloadUtil';
import Button from '../../../../../../presentational/atoms/button';
import CustomScrollView from '../../../../../../presentational/atoms/custom-scroll-view';
import EditableText, { type IDateRange } from '../../../../../../presentational/atoms/editable-text';
import Form from '../../../../../../presentational/atoms/form';
import Input from '../../../../../../presentational/atoms/input';
import Link from '../../../../../../presentational/atoms/link';
import Typography, { TypographyType } from '../../../../../../presentational/atoms/typography';
import CopyIcon from '../../../../../../presentational/molecules/image-icon/copy';
import OpenLinkIcon from '../../../../../../presentational/molecules/image-icon/open-in-new';
import Modal from '../../../../../../presentational/molecules/modal';
import type { IThemePart } from '../../../../../../theme';
import PlanNotAllowedView from '../../../../organisms/plan-not-allowed-view';
import SettingsElement from '../../../../organisms/settings-element';

const Container = styled.View`
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
`;

interface ISettingsModalProps {
  mySettings: MemberSettings;
  closeModal: () => void;
}

const SettingsModal = (props: ISettingsModalProps) => {
  const [allowExportCalendar, setAllowExportCalendar] = useState(
    props.mySettings.allowExportCalendar
  );
  const [updateMySettings] = useUpdateMySettingsMutation({
    variables: {
      input: {
        id: props.mySettings.id!,
        calendarStartDayOfWeek: props.mySettings.calendarStartDayOfWeek!,
        initialViewType: props.mySettings.initialViewTypeCode!,
        projectInitialViewTypeCode: props.mySettings.projectInitialViewTypeCode!,
        autoAssignWhenTaskStart: props.mySettings.autoAssignWhenTaskStart!,
        longTimeWorkingAlertMinutes: props.mySettings.longTimeWorkingAlertMinutes!,
        allowExportCalendar: allowExportCalendar,
        copyWorkingScheduleMemoToHistory: props.mySettings.copyWorkingScheduleMemoToHistory,
        versionNo: props.mySettings.versionNo!,
      },
    },
  });
  return (
    <Form style={{ paddingVertical: 20 }}>
      <Input
        name={'initialViewType'}
        label={'カレンダー情報の出力'}
        type={'picker'}
        pickerItems={[
          {
            label: '有効',
            value: 'true',
          },
          {
            label: '無効',
            value: 'false',
          },
        ]}
        initialValue={props.mySettings.allowExportCalendar ? 'true' : 'false'}
        validate={{
          required: {
            value: true,
            message: '選択してください',
          },
        }}
        onChange={(value) => setAllowExportCalendar(value === 'true')}
      />
      <Button
        text="変更する"
        completeText="変更しました"
        style={{ marginTop: 20, height: 40, width: 300 }}
        onPress={async () => {
          await updateMySettings();
          props.closeModal();
        }}
      />
    </Form>
  );
};

interface IExportICalModalProps {
  type: ICalFileType;
  showModal: boolean;
  onClose: () => void;
}

const ExportICalModal = (props: IExportICalModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [startDateTime, setStartDateTime] = useState(moment());
  const [endDateTime, setEndDateTime] = useState(moment());
  const [showFirstView, setShowFirstView] = useState(true);

  useEffect(() => {
    setShowFirstView(true);
  }, [props.showModal]);

  const [exportICal, exportICalResult] = useExportICalFileMutation({
    variables: {
      input: {
        type: props.type,
        start: startDateTime.startOf('day').toISOString(),
        end: endDateTime.endOf('day').toISOString(),
      },
    },
  });
  const { data, refetch } = useICalExportQuery({
    variables: {
      id: exportICalResult.data?.exportICalFile!.id,
    },
    fetchPolicy: 'network-only',
    skip: !exportICalResult.data?.exportICalFile?.id,
  });
  const [createDownloadUrl] = useCreateICalFileDownloadUrlMutation({
    variables: {
      input: {
        iCalExportId: data?.iCalExport!.id!,
      },
    },
  });

  return (
    <Modal
      title={`${
        props.type === ICalFileType.WorkingHistory ? '作業履歴' : '作業予定'
      }をICSファイルに出力する`}
      isShow={props.showModal}
      isPositionTop={true}
      onClose={props.onClose}>
      <View style={{ flexDirection: 'column' }}>
        <View style={{ justifyContent: 'center', marginTop: 10 }}>
          <Form style={{ margin: 0, padding: 0, width: '100%' }}>
            {showFirstView ? (
              <>
                <Typography variant={TypographyType.Normal} style={{ fontSize: 14 }}>
                  {`${
                    props.type === ICalFileType.WorkingHistory ? '作業履歴' : '作業予定'
                  }データを出力する期間を選択してください。`}
                </Typography>
                <View style={{ zIndex: 1, flexDirection: 'row', marginVertical: 20 }}>
                  <Typography
                    variant={TypographyType.SubTitle}
                    style={{ fontWeight: '600', marginRight: 10 }}>
                    対象期間
                  </Typography>
                  <EditableText
                    value={{
                      start: startDateTime,
                      end: endDateTime,
                    }}
                    type={'date-range-picker'}
                    disableClear={true}
                    containerStyle={{ paddingHorizontal: 10 }}
                    textStyle={{ minWidth: 200 }}
                    onChange={(value) => {
                      setStartDateTime((value as IDateRange)!.start);
                      setEndDateTime((value as IDateRange)!.end);
                    }}
                    onBlur={(value) => {
                      setStartDateTime((value as IDateRange)!.start);
                      setEndDateTime((value as IDateRange)!.end);
                    }}
                  />
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                  <Button
                    text={'データ出力する'}
                    style={{
                      flex: 1,
                      width: '100%',
                      minWidth: 200,
                      marginVertical: 10,
                    }}
                    textStyle={{
                      backgroundColor: themeContext.colors.primary,
                      color: '#FFFFFF',
                      padding: 5,
                    }}
                    onPress={async () => {
                      await exportICal();
                      refetch();
                      setShowFirstView(false);
                    }}
                  />
                  <Button
                    text={'キャンセル'}
                    style={{
                      flex: 1,
                      width: '100%',
                      marginVertical: 10,
                      backgroundColor: 'transparent',
                    }}
                    textStyle={{ color: themeContext.colors.primary }}
                    disableValidate={true}
                    onPress={() => {
                      props.onClose();
                    }}
                  />
                </View>
              </>
            ) : (
              <>
                {(data?.iCalExport?.status === ICalExportStatus.Accepted ||
                  data?.iCalExport?.status === ICalExportStatus.InProgress) && (
                  <View style={{ flexDirection: 'row', paddingVertical: 40 }}>
                    <ActivityIndicator size={'small'} color={themeContext.colors.primary} />
                    <Typography
                      variant={TypographyType.Normal}
                      style={{ fontSize: 16, marginLeft: 10 }}>
                      {`${
                        props.type === ICalFileType.WorkingHistory ? '作業履歴' : '作業予定'
                      }データを出力中です。しばらくお待ち下さい。`}
                    </Typography>
                  </View>
                )}
                {data?.iCalExport?.status === ICalExportStatus.Success && (
                  <>
                    <Typography
                      variant={TypographyType.Normal}
                      style={{ fontSize: 16, marginLeft: 10, marginVertical: 20 }}>
                      {`データ出力が完了しました。`}
                    </Typography>
                    <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                      <Button
                        text={'ダウンロードする'}
                        style={{
                          flex: 1,
                          width: '100%',
                          minWidth: 200,
                          marginVertical: 10,
                        }}
                        textStyle={{
                          backgroundColor: themeContext.colors.primary,
                          color: '#FFFFFF',
                          padding: 5,
                        }}
                        onPress={async () => {
                          const result = await createDownloadUrl();
                          DownloadUtil.forceDownload(
                            result.data!.createICalFileDownloadUrl?.downloadUrl!,
                            props.type === ICalFileType.WorkingHistory
                              ? '作業履歴.ics'
                              : '作業予定.ics'
                          );
                        }}
                      />
                      <Button
                        text={'閉じる'}
                        style={{
                          flex: 1,
                          width: '100%',
                          marginVertical: 10,
                          backgroundColor: 'transparent',
                        }}
                        textStyle={{ color: themeContext.colors.primary }}
                        disableValidate={true}
                        onPress={() => {
                          props.onClose();
                        }}
                      />
                    </View>
                  </>
                )}
                {data?.iCalExport?.status === ICalExportStatus.Failure && (
                  <>
                    <Typography
                      variant={TypographyType.Normal}
                      style={{ fontSize: 16, marginLeft: 10 }}>
                      {`データを出力に失敗しました。${'\n'}申し訳ございませんが、もう一度最初からお試しください。`}
                    </Typography>
                    <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                      <Button
                        text={'閉じる'}
                        style={{
                          flex: 1,
                          width: '100%',
                          marginVertical: 10,
                          backgroundColor: 'transparent',
                        }}
                        textStyle={{ color: themeContext.colors.primary }}
                        disableValidate={true}
                        onPress={() => {
                          props.onClose();
                        }}
                      />
                    </View>
                  </>
                )}
              </>
            )}
          </Form>
        </View>
      </View>
    </Modal>
  );
};

const AppMySettingsCalendarExportPage = () => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, _] = useContext(LoginUserContext);
  const [notAllowedPlan, setNotAllowedPlan] = useState(false);
  const [workHistoryUrlCopied, setWorkHistoryUrlCopied] = useState(false);
  const [workScheduleUrlCopied, setWorkScheduleUrlCopied] = useState(false);
  const [showExportWorkingHistoryICal, setShowExportWorkingHistoryICal] = useState(false);
  const [showExportWorkingScheduleICal, setShowExportWorkingScheduleICal] = useState(false);
  const { loading: organizationLoading, data: organizationData } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId,
    },
    fetchPolicy: 'network-only',
  });
  const { loading, data } = useICalKeyQuery({
    skip:
      !organizationData?.organization?.plan ||
      organizationData.organization.plan.code === Plan.Basic,
  });
  const { loading: mySettingsLoding, data: mySettingsData } = useMySettingsQuery({
    fetchPolicy: 'network-only',
  });

  // Basicプランの場合にはカレンダー出力できないようにする
  useEffect(() => {
    setTimeout(() => {
      if (organizationData?.organization?.plan.code === Plan.Basic) {
        setNotAllowedPlan(true);
      }
    }, 1000);
  }, [organizationData]);

  useEffect(() => {
    if (workHistoryUrlCopied) {
      setTimeout(() => setWorkHistoryUrlCopied(false), 1000);
    }
  }, [workHistoryUrlCopied, setWorkHistoryUrlCopied]);

  useEffect(() => {
    if (workScheduleUrlCopied) {
      setTimeout(() => setWorkScheduleUrlCopied(false), 1000);
    }
  }, [workScheduleUrlCopied, setWorkScheduleUrlCopied]);

  if (loading || mySettingsLoding) {
    return <></>;
  }

  return (
    <PlanNotAllowedView isNotAllowedPlan={notAllowedPlan} style={{ height: 'calc(100vh - 105px)' }}>
      <Container>
        <CustomScrollView
          style={{
            height: 'calc(100vh - 157px)',
            paddingBottom: 30,
          }}>
          <Typography variant={TypographyType.Normal} style={{ fontSize: 14, marginTop: 5 }}>
            {`自分の作業履歴・作業予定のデータ出力し、Googleカレンダー等でカレンダーとしてインポートすることが出来ます。`}
          </Typography>

          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <OpenLinkIcon size={24} containerStyle={{ marginRight: 5 }} />
            <Link
              path={'https://manual.timedesigner.com/v1/-104'}
              isExternalLink={true}>
              <Typography
                variant={TypographyType.Normal}
                style={{ fontSize: 14, color: themeContext.colors.link }}>
                {`使い方について確認する`}
              </Typography>
            </Link>
          </View>

          <View
            style={{
              borderColor: themeContext.colors.description,
              borderRadius: 10,
              borderWidth: 1,
              padding: 15,
              marginTop: 30,
            }}>
            <SettingsElement
              title={`カレンダーデータ出力`}
              changeText={'変更する'}
              modalTitle={'カレンダーデータ出力を変更する'}
              titleWidth={200}
              containerStyle={{ marginBottom: 10 }}
              modal={(closeModal) => (
                <SettingsModal mySettings={mySettingsData!.mySettings!} closeModal={closeModal} />
              )}>
              <Typography variant={TypographyType.Normal}>
                {mySettingsData!.mySettings!.allowExportCalendar ? '有効' : '無効'}
              </Typography>
            </SettingsElement>
          </View>

          {mySettingsData!.mySettings?.allowExportCalendar && (
            <>
              <View
                style={{
                  borderColor: themeContext.colors.description,
                  borderRadius: 10,
                  borderWidth: 1,
                  padding: 15,
                  marginTop: 30,
                }}>
                <View>
                  <Typography
                    variant={TypographyType.ElementiTitle}
                    style={{ fontSize: 16, marginTop: 5, marginBottom: 10 }}>
                    {`カレンダー共有用のURL`}
                  </Typography>
                  <Typography variant={TypographyType.Normal} style={{ fontSize: 14 }}>
                    {`以下のURLをGoogleカレンダー等でインポートすることが出来ます。`}
                  </Typography>
                  <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 20 }}>
                    <OpenLinkIcon size={24} containerStyle={{ marginRight: 5 }} />
                    <Link
                      path={
                        'https://manual.timedesigner.com/v1/google-8'
                      }
                      isExternalLink={true}>
                      <Typography
                        variant={TypographyType.Normal}
                        style={{ fontSize: 14, color: themeContext.colors.link }}>
                        {`使い方について確認する`}
                      </Typography>
                    </Link>
                  </View>
                  <View>
                    <Typography
                      variant={TypographyType.Normal}
                      style={{ fontSize: 14, marginTop: 5, fontWeight: '600' }}>
                      {`作業履歴`}
                    </Typography>
                    <View style={{ flexDirection: 'column' }}>
                      <Typography
                        variant={TypographyType.Normal}
                        style={{
                          marginLeft: 10,
                          marginTop: 5,
                          color: themeContext.colors.link,
                        }}>
                        {data?.iCalKey?.timeEntriesUrl ??
                          'https://ical.timedesigner.com/time-entries'}
                      </Typography>
                      <View style={{ flexDirection: 'row' }}>
                        <CopyIcon
                          size={20}
                          containerStyle={{ marginLeft: 5 }}
                          onPress={async () => {
                            Clipboard.setString(data?.iCalKey?.timeEntriesUrl ?? '');
                            setWorkHistoryUrlCopied(true);
                          }}>
                          <Typography variant={TypographyType.Normal} style={{ fontSize: 14 }}>
                            {workHistoryUrlCopied ? 'コピーしました' : 'URLをコピーする'}
                          </Typography>
                        </CopyIcon>
                      </View>
                    </View>
                  </View>
                  <View style={{ marginTop: 30 }}>
                    <Typography
                      variant={TypographyType.Normal}
                      style={{ fontSize: 14, marginTop: 5, fontWeight: '600' }}>
                      {`作業予定`}
                    </Typography>
                    <View style={{ flexDirection: 'column' }}>
                      <Typography
                        variant={TypographyType.Normal}
                        style={{
                          marginLeft: 10,
                          marginTop: 5,
                          color: themeContext.colors.link,
                        }}>
                        {data?.iCalKey?.workSchedulesUrl ??
                          'https://ical.timedesigner.com/work-schedules'}
                      </Typography>
                      <View style={{ flexDirection: 'row' }}>
                        <CopyIcon
                          size={20}
                          containerStyle={{ marginLeft: 5 }}
                          onPress={async () => {
                            Clipboard.setString(data?.iCalKey?.workSchedulesUrl ?? '');
                            setWorkScheduleUrlCopied(true);
                          }}>
                          <Typography variant={TypographyType.Normal} style={{ fontSize: 14 }}>
                            {workScheduleUrlCopied ? 'コピーしました' : 'URLをコピーする'}
                          </Typography>
                        </CopyIcon>
                      </View>
                    </View>
                  </View>
                  <Typography
                    variant={TypographyType.Description}
                    style={{ fontSize: 14, marginTop: 30 }}>
                    {`※出力されるのは、過去３ヶ月以内・最大2000件までの最近の作業履歴・作業予定のデータとなります。${'\n'}※インポート後の最新情報の反映はリアルタイムではありません。最新情報の反映タイミングは、インポート先のシステムによって決まります。`}
                  </Typography>
                </View>
              </View>
              <View
                style={{
                  borderColor: themeContext.colors.description,
                  borderRadius: 10,
                  borderWidth: 1,
                  padding: 15,
                  marginTop: 30,
                }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{ fontSize: 16, marginTop: 5, fontWeight: '600', marginBottom: 10 }}>
                  {`ICS形式ファイルでダウンロード`}
                </Typography>
                <Typography variant={TypographyType.Normal} style={{ fontSize: 14 }}>
                  {`作業履歴・作業予定のデータをICSファイル（iCalファイル）でダウンロードすることが出来ます。${'\n'}ISCファイルをカレンダーアプリで読み込むことが出来ます。`}
                </Typography>
                <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 20 }}>
                  <OpenLinkIcon size={24} containerStyle={{ marginRight: 5 }} />
                  <Link
                    path={'https://manual.timedesigner.com/v1/ics-1'}
                    isExternalLink={true}>
                    <Typography
                      variant={TypographyType.Normal}
                      style={{ fontSize: 14, color: themeContext.colors.link }}>
                      {`使い方について確認する`}
                    </Typography>
                  </Link>
                </View>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <Button
                    text="作業履歴データをダウンロード"
                    disableValidate={true}
                    onPress={() => setShowExportWorkingHistoryICal(true)}
                  />
                  <Button
                    text="作業予定データをダウンロード"
                    disableValidate={true}
                    onPress={() => setShowExportWorkingScheduleICal(true)}
                    style={{ marginLeft: 20 }}
                  />
                </View>
                <ExportICalModal
                  type={ICalFileType.WorkingHistory}
                  showModal={showExportWorkingHistoryICal}
                  onClose={() => setShowExportWorkingHistoryICal(false)}
                />
                <ExportICalModal
                  type={ICalFileType.WorkingSchedule}
                  showModal={showExportWorkingScheduleICal}
                  onClose={() => setShowExportWorkingScheduleICal(false)}
                />
              </View>
            </>
          )}
        </CustomScrollView>
      </Container>
    </PlanNotAllowedView>
  );
};

export default AppMySettingsCalendarExportPage;
