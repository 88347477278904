import { Reference, useApolloClient } from '@apollo/client';
import type Pusher from 'pusher-js/react-native';
import React, { useContext, useEffect } from 'react';
import { Platform } from 'react-native';
import { useHistory } from 'react-router';
import * as Sentry from 'sentry-expo';
import {
  type AttachmentFile,
  AttachmentFileDocument,
  CalendarWorkingHistory,
  CalendarWorkingHistoryDocument,
  type ExportClientExcel,
  ExportClientExcelDocument,
  type ExportClientExcelQuery,
  type ExportClientExcelQueryVariables,
  type ExportMemberExcel,
  ExportMemberExcelDocument,
  type ExportMemberExcelQuery,
  type ExportMemberExcelQueryVariables,
  type ExportProjectExcel,
  ExportProjectExcelDocument,
  type ExportProjectExcelQuery,
  type ExportProjectExcelQueryVariables,
  type ExportTaskExcel,
  ExportTaskExcelDocument,
  type ExportTaskExcelQuery,
  type ExportTaskExcelQueryVariables,
  type Member,
  type MentionV2,
  NotificationType,
  NotificationV2Document,
  type NotificationV2Query,
  type NotificationV2QueryVariables,
  type Organization,
  OrganizationDocument,
  OrganizationTagsDocument,
  type Project,
  type ProjectAssignNotificationV2,
  ProjectDocument,
  type ProjectTemplate,
  ProjectTemplateDocument,
  type ProjectTemplateSubTask,
  ProjectTemplateSubTaskDocument,
  type ProjectTemplateTask,
  ProjectTemplateTaskDocument,
  type ProjectTemplateTaskStatus,
  ProjectTemplateTaskStatusDocument,
  type SubTask,
  SubTaskDocument,
  type Tag,
  TagDocument,
  type Task,
  type TaskAssignNotificationV2,
  type TaskComment,
  TaskCommentDocument,
  TaskDocument,
  type TaskStatus,
  TaskStatusDocument,
  TaskTagsDocument,
  type Team,
  TeamDocument,
  type TeamMemberRelation,
  UnreadNotificationCountDocument,
  type WorkingHistory,
  WorkingHistoryDocument,
  type WorkingSchedule,
  WorkingScheduleDocument,
  useEnvironmentQuery,
  useFetchTeamMembersLazyQuery,
  useICalExportLazyQuery,
  useJoinedTeamsQuery,
  useJoinedTeamsWithoutPersonalTeamQuery,
  useLatestWorkingHistoryLazyQuery,
  useMemberLazyQuery,
  useMyCsvReportLazyQuery,
  useMyReportLazyQuery,
  useOrganizationCsvReportLazyQuery,
  useOrganizationReportLazyQuery,
  useProjectTemplatesLazyQuery,
  useTeamCsvReportLazyQuery,
  useTeamMemberRelationsQuery,
  useTeamProjectsLazyQuery,
  useTeamReportLazyQuery,
  useUnreadNotificationCountLazyQuery,
  useUpdateNotificationToReadMutation,
  useUpdateNotificationToReceivedMutation,
} from '../graphql/api/API';
import { useLazyQueryPromise } from '../graphql/extention/useLazyQueryPromise';
import { LoginUserContext } from '../modules/auth/LoginUserContext';
import PusherClientFactory from '../pusher/PusherClientFactory';
import TimeUtil from '../util/TimeUtil';

interface IUseWebSocketSyncProps {
  moveToMentionTask: (mention: MentionV2) => void;
  notifyMention: (mention: MentionV2, updateNotificationToRead: () => void) => void;
  notifyProjectAssigned: (
    projectssigned: ProjectAssignNotificationV2,
    updateNotificationToRead: () => void
  ) => void;
  notifyTaskAssigned: (
    taskAssigned: TaskAssignNotificationV2,
    updateNotificationToRead: () => void
  ) => void;
  notifyLongTimeWorkingTask: (workingInfo: any) => void;
  notifyTaskRemainder: (remainderInfo: any) => void;
  notifyUpdateMemberReportSuccess: (reportId: string) => void;
  notifyUpdateMemberReportFailure: (reportId: string) => void;
  notifyUpdateMemberCsvReportSuccess: (reportId: string) => void;
  notifyUpdateMemberCsvReportFailure: (reportId: string) => void;
  notifyUpdateTeamReportSuccess: (reportId: string, teamId: string) => void;
  notifyUpdateTeamReportFailure: (reportId: string, teamId: string) => void;
  notifyUpdateTeamCsvReportSuccess: (reportId: string, teamId: string) => void;
  notifyUpdateTeamCsvReportFailure: (reportId: string, teamId: string) => void;
  notifyUpdateOrganizationReportSuccess: (reportId: string) => void;
  notifyUpdateOrganizationReportFailure: (reportId: string) => void;
  notifyUpdateOrganizationCsvReportSuccess: (reportId: string) => void;
  notifyUpdateOrganizationCsvReportFailure: (reportId: string) => void;
  notifyExportICalFileSuccess: (id: string) => void;
  notifyExportICalFileFailure: (id: string) => void;
  notifyExportProjectExcelSuccess: (data: ExportProjectExcel | undefined) => void;
  notifyExportProjectExcelFailure: (data: ExportProjectExcel | undefined) => void;
  notifyExportTaskExcelSuccess: (data: ExportTaskExcel | undefined) => void;
  notifyExportTaskExcelFailure: (data: ExportTaskExcel | undefined) => void;
  notifyExportClientExcelSuccess: (data: ExportClientExcel | undefined) => void;
  notifyExportClientExcelFailure: (data: ExportClientExcel | undefined) => void;
  notifyExportMemberExcelSuccess: (data: ExportMemberExcel | undefined) => void;
  notifyExportMemberExcelFailure: (data: ExportMemberExcel | undefined) => void;
}

const useWebSocketSync = (props: IUseWebSocketSyncProps): void => {
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const history = useHistory();
  const apolloClient = useApolloClient();

  const { loading: loadingEnv, data: dataEnv } = useEnvironmentQuery({
    fetchPolicy: 'network-only',
    skip: !loginUser
  });
  const [fetchTeamProjects] = useTeamProjectsLazyQuery({
    fetchPolicy: 'network-only',
  });
  const [fetchMember] = useMemberLazyQuery({
    fetchPolicy: 'network-only',
  });
  const [fetchTeamMembers] = useFetchTeamMembersLazyQuery({
    fetchPolicy: 'network-only',
  });
  const [fetchTemplateProjects] = useProjectTemplatesLazyQuery({
    fetchPolicy: 'network-only',
  });
  const {
    loading,
    data: teamDatas,
    refetch,
  } = useJoinedTeamsQuery({
    variables: {
      organizationId: loginUser?.organizationId ?? '',
    },
    fetchPolicy: 'network-only',
    skip: !loginUser
  });
  const { refetch: refetchJoinedTeamsWithoutPersonalTeam } = useJoinedTeamsWithoutPersonalTeamQuery(
    {
      variables: {
        organizationId: loginUser?.organizationId ?? '',
        withArchivedTeam: false,
      },
      fetchPolicy: 'network-only',
      skip: !loginUser
    }
  );
  const { refetch: refetchTeamMemberRelations } = useTeamMemberRelationsQuery({
    fetchPolicy: 'network-only',
    skip: !loginUser
  });
  const [fetchMyReport] = useMyReportLazyQuery();
  const [fetchMyCsvReport] = useMyCsvReportLazyQuery();
  const [fetchTeamReport] = useTeamReportLazyQuery();
  const [fetchTeamCsvReport] = useTeamCsvReportLazyQuery();
  const [fetchOrganizationReport] = useOrganizationReportLazyQuery();
  const [fetchOrganizationCsvReport] = useOrganizationCsvReportLazyQuery();
  const fetchExportProjectExcel = useLazyQueryPromise<
    ExportProjectExcelQuery,
    ExportProjectExcelQueryVariables
  >(ExportProjectExcelDocument);
  const fetchExportTaskExcel = useLazyQueryPromise<
    ExportTaskExcelQuery,
    ExportTaskExcelQueryVariables
  >(ExportTaskExcelDocument);
  const fetchExportClientExcel = useLazyQueryPromise<
    ExportClientExcelQuery,
    ExportClientExcelQueryVariables
  >(ExportClientExcelDocument);
  const fetchExportMemberExcel = useLazyQueryPromise<
    ExportMemberExcelQuery,
    ExportMemberExcelQueryVariables
  >(ExportMemberExcelDocument);
  const fetchNotification = useLazyQueryPromise<NotificationV2Query, NotificationV2QueryVariables>(
    NotificationV2Document
  );
  const [fetchUnreadNotificationCount] = useUnreadNotificationCountLazyQuery({
    fetchPolicy: 'network-only',
  });
  const [updateNotificationToReceived, _] = useUpdateNotificationToReceivedMutation();
  const [updateNotificationToRead, __] = useUpdateNotificationToReadMutation();
  const [fetchLatestWorkingHistory] = useLatestWorkingHistoryLazyQuery({
    fetchPolicy: 'network-only',
  });
  const [fetchICalExport] = useICalExportLazyQuery({
    fetchPolicy: 'network-only',
  });
  useEffect(() => {
    if (loadingEnv || !loginUser) {
      return;
    }
    if (!dataEnv?.environment) {
      try {
        if (Sentry.Native) {
          Sentry.Native.captureMessage('dataEnv?.environmentが取得できませんでした。');
        } else {
          (Sentry as any).Browser.captureMessage('dataEnv?.environmentが取得できませんでした。');
        }
      } catch (e) {
        //NOP
      }
      return;
    }

    let pusher: Pusher | null = null;

    // WebSocket同期でバグ等があってエラー発生した場合、ユーザーはどうしようもなくなってしまうので、例外を握りつぶして、Sentryにだけ送信する
    try {
      // 【超重要】
      // Pusherへの接続は、このuseEffectの中（mount時）に行い、
      // その切断はuseEffectで返却している関数(unmount時の処理)で必ず行う。
      // ※PusherClientFactory#createで、Pusherへの接続を開始している。
      // useEffectの外で接続を開始したり、unmount時に切断を行わないと、
      // 画面の再描画が行われた際に、pusherへの接続が放置されたまま、新たな接続を無限に増やしていってしまい、
      // あっという間にPusherの接続上限数を超えて、サービス継続不可な状況となってしまうので注意すること。
      pusher = PusherClientFactory.create(loginUser!);

      const memberChannel = pusher!.subscribe(
        `private-member_${loginUser!.id!}-${dataEnv!.environment}`
      );
      memberChannel.unbind_all();
      memberChannel.bind('startOrStopTask', (task: Task) => {
        try {
          fetchLatestWorkingHistory();
        } catch (_e) {
          //NOP
        }
      });

      memberChannel.bind('addNotification', async (data: any) => {
        try {
          await TimeUtil.sleep(3000);
          const response = await fetchNotification({
            id: data.id,
          });
          // メンションを受信した旨を更新
          fetchUnreadNotificationCount();
          updateNotificationToReceived({
            variables: {
              id: data!.id,
            },
            refetchQueries: [
              {
                query: UnreadNotificationCountDocument,
              },
            ],
          });

          if (
            data.notificationType === NotificationType.Mention &&
            response.data.notificationV2?.mention
          ) {
            props.notifyMention(response.data!.notificationV2!.mention! as MentionV2, () => {
              if (!data.readDateTime) {
                updateNotificationToRead({
                  variables: {
                    id: data.id,
                  },
                  refetchQueries: [
                    {
                      query: UnreadNotificationCountDocument,
                    },
                  ],
                });
              }
            });
          }
          if (
            data.notificationType === NotificationType.ProjectAssigned &&
            response.data.notificationV2?.projectAssignNotification
          ) {
            props.notifyProjectAssigned(
              response.data.notificationV2.projectAssignNotification as ProjectAssignNotificationV2,
              () => {
                if (!data.readDateTime) {
                  updateNotificationToRead({
                    variables: {
                      id: data.id,
                    },
                    refetchQueries: [
                      {
                        query: UnreadNotificationCountDocument,
                      },
                    ],
                  });
                }
              }
            );
          }
          if (
            data.notificationType === NotificationType.TaskAssigned &&
            response.data.notificationV2?.taskAssignNotification
          ) {
            props.notifyTaskAssigned(
              response.data.notificationV2.taskAssignNotification as TaskAssignNotificationV2,
              () => {
                if (!data.readDateTime) {
                  updateNotificationToRead({
                    variables: {
                      id: data.id,
                    },
                    refetchQueries: [
                      {
                        query: UnreadNotificationCountDocument,
                      },
                    ],
                  });
                }
              }
            );
          }
        } catch (e) {
          //NOP
        }
      });
      memberChannel.bind('deleteNotification', (notificationId: string) => {
        try {
          apolloClient.cache.evict({
            id: `Notification:${notificationId}`,
          });
          fetchUnreadNotificationCount();
        } catch (_e) {
          //NOP
        }
      });
      memberChannel.bind('notifyUpdateMemberReport', (data: any) => {
        try {
          fetchMyReport({
            variables: {
              id: data.memberReportId,
            },
          });
          if (data.success) {
            props.notifyUpdateMemberReportSuccess(data.memberReportId);
          } else {
            props.notifyUpdateMemberReportFailure(data.memberReportId);
          }
        } catch (_e) {
          //NOP
        }
      });
      memberChannel.bind('notifyUpdateMemberCsvReport', (data: any) => {
        try {
          fetchMyCsvReport({
            variables: {
              id: data.memberCsvReportId,
            },
          });
          if (data.success) {
            props.notifyUpdateMemberCsvReportSuccess(data.memberCsvReportId);
          } else {
            props.notifyUpdateMemberCsvReportFailure(data.memberCsvReportId);
          }
        } catch (_e) {
          //NOP
        }
      });
      memberChannel.bind('notifyUpdateTeamReport', async (data: any) => {
        try {
          fetchTeamReport({
            variables: {
              id: data.teamReportId,
            },
          });
          if (data.success) {
            props.notifyUpdateTeamReportSuccess(data.teamReportId, data.teamId);
          } else {
            props.notifyUpdateTeamReportFailure(data.teamReportId, data.teamId);
          }
        } catch (_e) {
          //NOP
        }
      });
      memberChannel.bind('notifyUpdateTeamCsvReport', async (data: any) => {
        try {
          fetchTeamCsvReport({
            variables: {
              id: data.teamCsvReportId,
            },
          });
          if (data.success) {
            props.notifyUpdateTeamCsvReportSuccess(data.teamCsvReportId, data.teamId);
          } else {
            props.notifyUpdateTeamCsvReportFailure(data.teamCsvReportId, data.teamId);
          }
        } catch (_e) {
          //NOP
        }
    });
      memberChannel.bind('notifyUpdateOrganizationReport', (data: any) => {
        try {
          fetchOrganizationReport({
            variables: {
              id: data.organizationReportId,
            },
          });
          if (data.success) {
            props.notifyUpdateOrganizationReportSuccess(data.memberReportId);
          } else {
            props.notifyUpdateOrganizationReportFailure(data.memberReportId);
          }
        } catch (_e) {
          //NOP
        }
      });
      memberChannel.bind('notifyUpdateOrganizationCsvReport', (data: any) => {
        try {
          fetchOrganizationCsvReport({
            variables: {
              id: data.organizationCsvReportId,
            },
          });
          if (data.success) {
            props.notifyUpdateOrganizationCsvReportSuccess(data.organizationCsvReportId);
          } else {
            props.notifyUpdateOrganizationCsvReportFailure(data.organizationCsvReportId);
          }
        } catch (_e) {
          //NOP
        }
      });
      memberChannel.bind('notifyUpdateExportProjectExcel', async (data: any) => {
        try {
          const response = await fetchExportProjectExcel({
            id: data.exportProjectExcelId,
          });
          if (data.success) {
            props.notifyExportProjectExcelSuccess(
              response.data.exportProjectExcel as ExportProjectExcel
            );
          } else {
            props.notifyExportProjectExcelFailure(
              response.data.exportProjectExcel as ExportProjectExcel
            );
          }
        } catch (_e) {
          //NOP
        }
      });
      memberChannel.bind('notifyUpdateExportTaskExcel', async (data: any) => {
        try {
          const response = await fetchExportTaskExcel({
            id: data.exportTaskExcelId,
          });
          if (data.success) {
            props.notifyExportTaskExcelSuccess(response.data.exportTaskExcel as ExportTaskExcel);
          } else {
            props.notifyExportTaskExcelFailure(response.data.exportTaskExcel as ExportTaskExcel);
          }
        } catch (_e) {
          //NOP
        }
      });
      memberChannel.bind('notifyUpdateExportClientExcel', async (data: any) => {
        try {
          const response = await fetchExportClientExcel({
            id: data.exportClientExcelId,
          });
          if (data.success) {
            props.notifyExportClientExcelSuccess(
              response.data.exportClientExcel as ExportClientExcel
            );
          } else {
            props.notifyExportClientExcelFailure(
              response.data.exportClientExcel as ExportClientExcel
            );
          }
        } catch (_e) {
          //NOP
        }
      });
      memberChannel.bind('notifyUpdateExportMemberExcel', async (data: any) => {
        try {
          const response = await fetchExportMemberExcel({
            id: data.exportMemberExcelId,
          });
          if (data.success) {
            props.notifyExportMemberExcelSuccess(
              response.data.exportMemberExcel as ExportMemberExcel
            );
          } else {
            props.notifyExportMemberExcelFailure(
              response.data.exportMemberExcel as ExportMemberExcel
            );
          }
        } catch (_e) {
          //NOP
        }
      });
      memberChannel.bind('notifyExportICalFile', (data: any) => {
        try {
          fetchICalExport({
            variables: {
              id: data.exportICalFileId,
            },
          });
          if (data.success) {
            props.notifyExportICalFileSuccess(data.exportICalFileId);
          } else {
            props.notifyExportICalFileFailure(data.exportICalFileId);
          }
        } catch (_e) {
          //NOP
        }
      });

      memberChannel.bind('notifyLongTimeWorkingAlert', (workingInfo: any) => {
        try {
          fetchUnreadNotificationCount();
          props.notifyLongTimeWorkingTask(workingInfo);
        } catch (_e) {
          //NOP
        }
      });

      memberChannel.bind('notifyTaskRemainder', (info: any) => {
        try {
          fetchUnreadNotificationCount();
          props.notifyTaskRemainder(info);
        } catch (_e) {
          //NOP
        }
      });

      memberChannel.bind('addTeamMemberRelation', (teamMemberRelation: TeamMemberRelation) => {
        try {
          refetchTeamMemberRelations(); // サイドメニューのチーム一覧を更新するためにフェッチする
        } catch (_e) {
          //NOP
        }
      });
      memberChannel.bind('updateTeamMemberRelation', (teamMemberRelation: TeamMemberRelation) => {
        try {
          refetchTeamMemberRelations(); // サイドメニューのチーム一覧を更新するためにフェッチする
        } catch (_e) {
          //NOP
        }
      });
      memberChannel.bind('deleteTeamMemberRelation', () => {
        try {
          refetchTeamMemberRelations(); // サイドメニューのチーム一覧を更新するためにフェッチする
        } catch (_e) {
          //NOP
        }
      });

      const organizationChannel = pusher!.subscribe(
        `private-organization_${loginUser!.organizationId!}-${dataEnv!.environment}`
      );
      organizationChannel.unbind_all();

      organizationChannel.bind('updateOrganization', (organization: Organization) => {
        try {
          apolloClient.cache.modify({
            id: `Organization:${organization.id}`,
            fields() {
              const newOrganization = apolloClient.cache.writeQuery({
                data: organization,
                query: OrganizationDocument,
              });
              return newOrganization;
            },
          });
        } catch (_e) {
          //NOP
        }
      });

      organizationChannel.bind('updateMember', (member: Member) => {
        try {
          // メンバーを更新すると、他のログインしている組織の人たちがエラー発生して画面真っ白になるので、コメントアウトしている。
          // 下記で別途、チームメンバー情報はFetchしているので、メンバーの情報は同期される。
          // apolloClient.cache.modify({
          //   id: `Member:${member.id}`,
          //   fields() {
          //     const newMember = apolloClient.cache.writeQuery({
          //       data: member,
          //       query: MemberDocument,
          //     });
          //     return newMember;
          //   },
          // });
          // ここはFetchし直すしかない
          fetchMember({
            variables: {
              memberId: member!.id!,
            },
          });
        } catch (_e) {
          //NOP
        }
      });

      organizationChannel.bind('addTag', (tag: Tag) => {
        try {
          const newTag = apolloClient.cache.writeQuery({
            data: tag,
            query: TagDocument,
          });
          apolloClient.cache.modify({
            fields: {
              organizationTags(existingOrganizationTagRefs = []) {
                return [...existingOrganizationTagRefs, newTag];
              },
            },
          });
        } catch (_e) {
          //NOP
        }
      });

      organizationChannel.bind('updateTaskTagsRelation', (tags: Tag[]) => {
        try {
          apolloClient.cache.modify({
            fields: {
              organizationTags(existingOrganizationTagRefs = []) {
                const newTags = apolloClient.cache.writeQuery({
                  data: tags,
                  query: OrganizationTagsDocument,
                });
                return [...existingOrganizationTagRefs, newTags];
              },
              taskTags(existingTaskTagRefs = []) {
                const newTags = apolloClient.cache.writeQuery({
                  data: tags,
                  query: TaskTagsDocument,
                });
                return newTags;
              },
            },
          });
        } catch (_e) {
          //NOP
        }
      });

      organizationChannel.bind('updateProjectTemplateTaskTagsRelation', (tags: Tag[]) => {
        try {
          apolloClient.cache.modify({
            fields: {
              organizationTags(existingOrganizationTagRefs = []) {
                const newTags = apolloClient.cache.writeQuery({
                  data: tags,
                  query: OrganizationTagsDocument,
                });
                return [...existingOrganizationTagRefs, newTags];
              },
              taskTags(existingTaskTagRefs = []) {
                const newTags = apolloClient.cache.writeQuery({
                  data: tags,
                  query: TaskTagsDocument,
                });
                return newTags;
              },
            },
          });
        } catch (_e) {
          //NOP
        }
      });

      organizationChannel.bind('updateTag', (tag: Tag) => {
        try {
          apolloClient.cache.modify({
            id: `Tag:${tag.id}`,
            fields() {
              const newTag = apolloClient.cache.writeQuery({
                data: tag,
                query: TagDocument,
              });
              return newTag;
            },
          });
        } catch (_e) {
          //NOP
        }
      });

      organizationChannel.bind('deleteTag', (tagId: string) => {
        try {
          apolloClient.cache.evict({
            id: `Tag:${tagId}`,
          });
        } catch (_e) {
          //NOP
        }
      });

      organizationChannel.bind('addProjectTemplate', (project: ProjectTemplate) => {
        try {
          const newProject = apolloClient.cache.writeQuery({
            data: project,
            query: ProjectTemplateDocument,
          });
          apolloClient.cache.modify({
            fields: {
              teamProjects(existing = []) {
                return [...existing, newProject];
              },
            },
          });
          fetchTemplateProjects();
        } catch (_e) {
          //NOP
        }
      });
      organizationChannel.bind('updateProjectTemplate', (project: ProjectTemplate) => {
        try {
          apolloClient.cache.modify({
            id: `ProjectTemplate:${project.id}`,
            fields() {
              const newProject = apolloClient.cache.writeQuery({
                data: project,
                query: ProjectTemplateDocument,
              });
              return newProject;
            },
          });
          fetchTemplateProjects();
        } catch (_e) {
          //NOP
        }
      });
      organizationChannel.bind('deleteProjectTemplate', (id: ProjectTemplate) => {
        try {
          apolloClient.cache.evict({
            id: `ProjectTemplate:${id}`,
          });
        } catch (_e) {
          //NOP
        }
      });
      organizationChannel.bind(
        'addProjectTemplateTaskStatus',
        (status: ProjectTemplateTaskStatus) => {
          try {
            const newStatus = apolloClient.cache.writeQuery({
              data: status,
              query: ProjectTemplateTaskStatusDocument,
            });
            apolloClient.cache.modify({
              fields: {
                projectTaskStatus(existingTaskStatusRefs = []) {
                  return [...existingTaskStatusRefs, newStatus];
                },
              },
            });
          } catch (_e) {
            //NOP
          }
        }
      );
      organizationChannel.bind('updateProjectTemplateTaskStatus', (status: TaskStatus) => {
        try {
          apolloClient.cache.modify({
            id: `ProjectTemplateTaskStatus:${status.id}`,
            fields() {
              const newTaskStatus = apolloClient.cache.writeQuery({
                data: status,
                query: ProjectTemplateTaskStatusDocument,
              });
              return newTaskStatus;
            },
          });
        } catch (_e) {
          //NOP
        }
      });
      organizationChannel.bind('deleteProjectTemplateTaskStatus', (id: string) => {
        try {
          apolloClient.cache.evict({
            id: `ProjectTemplateTaskStatus:${id}`,
          });
        } catch (_e) {
          //NOP
        }
      });
      organizationChannel.bind('addProjectTemplateTask', (task: ProjectTemplateTask) => {
        try {
          const newTask = apolloClient.cache.writeQuery({
            data: task,
            query: ProjectTemplateTaskDocument,
          });
          apolloClient.cache.modify({
            fields: {
              projectTemplateTasks(existingProjectTaskRefs = []) {
                return [...existingProjectTaskRefs, newTask];
              },
            },
          });
        } catch (_e) {
          //NOP
        }
      });
      organizationChannel.bind('updateProjectTemplateTask', (task: ProjectTemplateTask) => {
        try {
          apolloClient.cache.modify({
            id: `ProjectTemplateTask:${task.id}`,
            fields() {
              const newTask = apolloClient.cache.writeQuery({
                data: task,
                query: ProjectTemplateTaskDocument,
              });
              return newTask;
            },
          });
        } catch (_e) {
          //NOP
        }
      });
      organizationChannel.bind('deleteProjectTemplateTask', (taskId: string) => {
        try {
          apolloClient.cache.evict({
            id: `ProjectTemplateTask:${taskId}`,
          });
        } catch (_e) {
          //NOP
        }
      });
      organizationChannel.bind('addProjectTemplateSubTask', (subTask: ProjectTemplateSubTask) => {
        try {
          const newSubTask = apolloClient.cache.writeQuery({
            data: subTask,
            query: ProjectTemplateSubTaskDocument,
          });
          apolloClient.cache.modify({
            fields: {
              projectTemplateSubTasks(existingSubTaskRefs = []) {
                return [...existingSubTaskRefs, newSubTask];
              },
            },
          });
        } catch (_e) {
          //NOP
        }
      });
      organizationChannel.bind('updateProjectTemplateSubTask', (subTask: SubTask) => {
        try {
          apolloClient.cache.modify({
            id: `ProjectTemplateSubTask:${subTask.id}`,
            fields() {
              const newSubTask = apolloClient.cache.writeQuery({
                data: subTask,
                query: SubTaskDocument,
              });
              return newSubTask;
            },
          });
        } catch (_e) {
          //NOP
        }
      });
      organizationChannel.bind('deleteProjectTemplateSubTask', (subTaskId: string) => {
        try {
          apolloClient.cache.evict({
            id: `ProjectTemplateSubTask:${subTaskId}`,
          });
        } catch (_e) {
          //NOP
        }
      });

      organizationChannel.bind('addTeam', (team: Team) => {
        try {
          refetch(); // Pusherのイベントをバインドさせるためにフェッチする
          refetchJoinedTeamsWithoutPersonalTeam(); // サイドメニューのチーム一覧を更新するためにフェッチする
        } catch (_e) {
          //NOP
        }
      });

      for(const team of teamDatas?.joinedTeams ?? []) {
        try {
          const teamChannel = pusher!.subscribe(`private-team_${team!.id!}-${dataEnv!.environment}`);

          // 何回もバインドしないように、一度全てアンバインドしてから、改めてバインドしていくようにしている
          teamChannel.unbind_all();

          teamChannel.bind('updateTeam', (pushedTeam: Team) => {
            try {
              apolloClient.cache.modify({
                id: `Team:${pushedTeam.id}`,
                fields() {
                  const newTeam = apolloClient.cache.writeQuery({
                    data: pushedTeam,
                    query: TeamDocument,
                  });
                  return newTeam;
                },
              });

              refetch();
            } catch (_e) {
              //NOP
            }
          });

          teamChannel.bind('deleteTeam', (teamId: string) => {
            try {
              apolloClient.cache.evict({
                id: `Team:${teamId}`,
              });
            } catch (_e) {
              //NOP
            }
          });

          teamChannel.bind('addTask', (task: Task) => {
            try {
              const newTask = apolloClient.cache.writeQuery({
                data: task,
                query: TaskDocument,
              });
              apolloClient.cache.modify({
                fields: {
                  projectTasks(existingProjectTaskRefs = []) {
                    return [...existingProjectTaskRefs, newTask];
                  },
                  searchTasks(existing = []) {
                    return [newTask, ...existing];
                  },
                  boardTasks(existingRef, { readField }) {
                    const taskStatusId = `TaskStatus:${task?.taskStatus?.id}`; // Pusherからemptyデータが来るケースがあるので、taskStatusが無い場合がある
                    if (
                      taskStatusId === existingRef.taskStatus.__ref &&
                      !existingRef.tasks.includes(taskStatusId)
                    ) {
                      return {
                        taskStatus: existingRef.taskStatus,
                        tasks: [
                          {
                            __ref: `Task:${task!.id}`,
                          },
                          ...existingRef.tasks,
                        ],
                      };
                    }
                    return existingRef;
                  },
                  myTasks(existing = []) {
                    if (task.asssignee?.id !== loginUser.id) {
                      return existing;
                    }
                    const newTask = apolloClient.cache.writeQuery({
                      data: task,
                      query: TaskDocument,
                    });
                    return [...existing, newTask];
                  },
                },
              });
            } catch (_e) {
              //NOP
            }
          });
          teamChannel.bind('updateTask', (task: Task) => {
            try {
              apolloClient.cache.modify({
                id: `Task:${task.id}`,
                fields() {
                  const newTask = apolloClient.cache.writeQuery({
                    data: task,
                    query: TaskDocument,
                  });
                  return newTask;
                },
              });
            } catch (_e) {
              //NOP
            }
          });
          teamChannel.bind('updateTask', (task: Task) => {
            try {
              apolloClient.cache.modify({
                fields: {
                  myTasks(existing = []) {
                    if (task.asssignee?.id !== loginUser.id) {
                      return existing;
                    }
                    const newTask = apolloClient.cache.writeQuery({
                      data: task,
                      query: TaskDocument,
                    });
                    return [...existing, newTask];
                  },
                  boardTasks(existingRef, { readField }) {
                    const taskStatusId = `TaskStatus:${task?.taskStatus?.id}`; // Pusherからemptyデータが来るケースがあるので、taskStatusが無い場合がある
                    if (
                      taskStatusId === existingRef.taskStatus.__ref &&
                      !existingRef.tasks.includes(taskStatusId)
                    ) {
                      return {
                        taskStatus: existingRef.taskStatus,
                        tasks: [
                          {
                            __ref: `Task:${task!.id}`,
                          },
                          ...existingRef.tasks,
                        ],
                      };
                    }
                    
                    if (
                      taskStatusId !== existingRef.taskStatus.__ref &&
                      existingRef.tasks.includes(taskStatusId)
                    ) {
                      return {
                        taskStatus: existingRef.taskStatus,
                        tasks: existingRef.tasks.filter(
                          (taskRef: any) => taskRef !== `Task:${task!.id}`
                        ),
                      };
                    }

                    return existingRef;
                  },
                },
              });
            } catch (_e) {
              //NOP
            }
          });
          teamChannel.bind('deleteTask', (taskId: string) => {
            try {
              apolloClient.cache.evict({
                id: `Task:${taskId}`,
              });
            } catch (_e) {
              //NOP
            }
          });
          teamChannel.bind('addSubTask', (subTask: SubTask) => {
            try {
              const newSubTask = apolloClient.cache.writeQuery({
                data: subTask,
                query: SubTaskDocument,
              });
              apolloClient.cache.modify({
                fields: {
                  subTasks(existingSubTaskRefs = []) {
                    return [...existingSubTaskRefs, newSubTask];
                  },
                },
              });
            } catch (_e) {
              //NOP
            }
          });
          teamChannel.bind('updateSubTask', (subTask: SubTask) => {
            try {
              apolloClient.cache.modify({
                id: `SubTask:${subTask.id}`,
                fields() {
                  const newSubTask = apolloClient.cache.writeQuery({
                    data: subTask,
                    query: SubTaskDocument,
                  });
                  return newSubTask;
                },
              });
            } catch (_e) {
              //NOP
            }
          });
          teamChannel.bind('deleteSubTask', (subTaskId: string) => {
            try {
              apolloClient.cache.evict({
                id: `SubTask:${subTaskId}`,
              });
            } catch (_e) {
              //NOP
            }
          });
          teamChannel.bind('addWorkingHistory', (history: WorkingHistory) => {
            try {
              const newWorkingHistory = apolloClient.cache.writeQuery({
                data: history,
                query: WorkingHistoryDocument,
              });
              apolloClient.cache.modify({
                fields: {
                  taskWorkingHistories(existingTaskWorkingHistoriesRefs = []) {
                    return [...existingTaskWorkingHistoriesRefs, newWorkingHistory];
                  },
                },
              });
            } catch (_e) {
              //NOP
            }
          });
          teamChannel.bind('updateWorkingHistory', (history: WorkingHistory) => {
            try {
              apolloClient.cache.modify({
                id: `WorkingHistory:${history.id}`,
                fields() {
                  const newWorkingHistory = apolloClient.cache.writeQuery({
                    data: history,
                    query: WorkingHistoryDocument,
                  });
                  return newWorkingHistory;
                },
              });
              apolloClient.cache.modify({
                id: `CalendarWorkingHistory:${history.id}`,
                fields() {
                  const newWorkingHistory = apolloClient.cache.writeQuery({
                    data: history,
                    query: CalendarWorkingHistoryDocument,
                  });
                  return newWorkingHistory;
                },
              });
            } catch (_e) {
              //NOP
            }
          });
          teamChannel.bind('deleteWorkingHistory', (id: string) => {
            try {
              apolloClient.cache.evict({
                id: `WorkingHistory:${id}`,
              });
              apolloClient.cache.evict({
                id: `CalendarWorkingHistory:${id}`,
              });
            } catch (_e) {
              //NOP
            }
          });
          teamChannel.bind('addWorkingScedule', (history: WorkingSchedule) => {
            try {
              const newWorkingHistory = apolloClient.cache.writeQuery({
                data: history,
                query: WorkingScheduleDocument,
              });
              apolloClient.cache.modify({
                fields: {
                  taskWorkingSchedules(existingTaskWorkingSchedulesRefs = []) {
                    return [...existingTaskWorkingSchedulesRefs, newWorkingHistory];
                  },
                },
              });
            } catch (_e) {
              //NOP
            }
          });
          teamChannel.bind('updateWorkingSchedule', (history: WorkingSchedule) => {
            try {
              apolloClient.cache.modify({
                id: `WorkingSchedule:${history.id}`,
                fields() {
                  const newWorkingHistory = apolloClient.cache.writeQuery({
                    data: history,
                    query: WorkingScheduleDocument,
                  });
                  return newWorkingHistory;
                },
              });
              apolloClient.cache.modify({
                id: `CalendarWorkingSchedule:${history.id}`,
                fields() {
                  const newWorkingHistory = apolloClient.cache.writeQuery({
                    data: history,
                    query: WorkingScheduleDocument,
                  });
                  return newWorkingHistory;
                },
              });
            } catch (_e) {
              //NOP
            }
          });
          teamChannel.bind('deleteWorkingSchedule', (id: string) => {
            try {
              apolloClient.cache.evict({
                id: `WorkingSchedule:${id}`,
              });
              apolloClient.cache.evict({
                id: `CalendarWorkingSchedule:${id}`,
              });
            } catch (_e) {
              //NOP
            }
          });
          teamChannel.bind('addComment', (comment: TaskComment) => {
            try {
              const newComment = apolloClient.cache.writeQuery({
                data: comment,
                query: TaskCommentDocument,
              });
              apolloClient.cache.modify({
                fields: {
                  taskComments(existingCommentRefs = []) {
                    return [...existingCommentRefs, newComment];
                  },
                },
              });
            } catch (_e) {
              //NOP
            }
          });
          teamChannel.bind('updateComment', (comment: TaskComment) => {
            try {
              apolloClient.cache.modify({
                id: `TaskComment:${comment.id}`,
                fields() {
                  const newTaskComment = apolloClient.cache.writeQuery({
                    data: comment,
                    query: TaskCommentDocument,
                  });
                  return newTaskComment;
                },
              });
            } catch (_e) {
              //NOP
            }
          });
          teamChannel.bind('deleteComment', (id: string) => {
            try {
              apolloClient.cache.evict({
                id: `TaskComment:${id}`,
              });
            } catch (_e) {
              //NOP
            }
          });
          teamChannel.bind('addAttachment', (attachmentFile: AttachmentFile) => {
            try {
              const newAttachmentFile = apolloClient.cache.writeQuery({
                data: attachmentFile,
                query: AttachmentFileDocument,
              });
              apolloClient.cache.modify({
                fields: {
                  taskAttachmentFiles(existing = []) {
                    return [...existing, newAttachmentFile];
                  },
                },
              });
            } catch (_e) {
              //NOP
            }
          });
          teamChannel.bind('updateAttachment', (attachmentFile: AttachmentFile) => {
            try {
              apolloClient.cache.modify({
                id: `AttachmentFile:${attachmentFile.id}`,
                fields() {
                  const newAttachmentFile = apolloClient.cache.writeQuery({
                    data: attachmentFile,
                    query: AttachmentFileDocument,
                  });
                  return newAttachmentFile;
                },
              });
            } catch (_e) {
              //NOP
            }
          });
          teamChannel.bind('deleteAttachment', (id: string) => {
            try {
              apolloClient.cache.evict({
                id: `AttachmentFile:${id}`,
              });
            } catch (_e) {
              //NOP
            }
          });
          teamChannel.bind('addTaskStatus', (status: TaskStatus) => {
            try {
              const newStatus = apolloClient.cache.writeQuery({
                data: status,
                query: TaskStatusDocument,
              });
              apolloClient.cache.modify({
                fields: {
                  projectTaskStatus(existingTaskStatusRefs = []) {
                    return [...existingTaskStatusRefs, newStatus];
                  },
                },
              });
            } catch (_e) {
              //NOP
            }
          });
          teamChannel.bind('updateTaskStatus', (status: TaskStatus) => {
            try {
              apolloClient.cache.modify({
                id: `TaskStatus:${status.id}`,
                fields() {
                  const newTaskStatus = apolloClient.cache.writeQuery({
                    data: status,
                    query: TaskStatusDocument,
                  });
                  return newTaskStatus;
                },
              });
            } catch (_e) {
              //NOP
            }
          });
          teamChannel.bind('deleteTaskStatus', (id: string) => {
            try {
              apolloClient.cache.evict({
                id: `TaskStatus:${id}`,
              });
            } catch (_e) {
              //NOP
            }
          });
          teamChannel.bind('addMember', (member: Member) => {
            try {
              // ここはFetchし直すしかない
              fetchTeamMembers({
                variables: {
                  teamId: team!.id!,
                },
              });
            } catch (_e) {
              //NOP
            }
          });
          teamChannel.bind('updateMember', (member: Member) => {
            try {
              // メンバーを更新すると、他のログインしている組織の人たちがエラー発生して画面真っ白になるので、コメントアウトしている。
              // 下記で別途、チームメンバー情報はFetchしているので、メンバーの情報は同期される。
              // apolloClient.cache.modify({
              //   id: `Member:${member.id}`,
              //   fields() {
              //     const newMember = apolloClient.cache.writeQuery({
              //       data: member,
              //       query: MemberDocument,
              //     });
              //     return newMember;
              //   },
              // });
              // ここはFetchし直すしかない
              fetchTeamMembers({
                variables: {
                  teamId: team!.id!,
                },
              });
            } catch (_e) {
              //NOP
            }
          });
          teamChannel.bind('addProject', (project: Project) => {
            try {
              const newProject = apolloClient.cache.writeQuery({
                data: project,
                query: ProjectDocument,
              });
              apolloClient.cache.modify({
                fields: {
                  teamProjects(existing = []) {
                    return [...existing, newProject];
                  },
                },
              });
              // TODO うまく反映されないので、仕方ないので一旦Fetchし直して対応している
              fetchTeamProjects({
                variables: {
                  teamId: project.team.id!,
                },
              });
            } catch (_e) {
              //NOP
            }
          });
          teamChannel.bind('updateProject', (project: Project) => {
            try {
              //TODO 以下でキャッシュは更新されるのだが、画面のレンダリングが2回目以降発生しない。なぜなのか。。
              apolloClient.cache.modify({
                id: `Project:${project.id}`,
                fields() {
                  const newProject = apolloClient.cache.writeQuery({
                    data: project,
                    query: ProjectDocument,
                  });
                  return newProject;
                },
              });
              // TODO 仕方ないので一旦Fetchし直して対応している
              fetchTeamProjects({
                variables: {
                  teamId: team!.id!,
                },
              });
            } catch (_e) {
              //NOP
            }
          });
          teamChannel.bind('deleteProject', (id: string) => {
            try {
              apolloClient.cache.evict({
                id: `Project:${id}`,
              });
            } catch (_e) {
              //NOP
            }
          });
        } catch (_e) {
          //NOP
        }
      }
    } catch (e) {
      if (Sentry.Native) {
        Sentry.Native.captureException(e);
      } else {
        (Sentry as any).Browser.captureException(e);
      }
    }

    return () => {
      // 【超重要】
      // unmount時に必ずpusherへの接続を切断するようにしている。
      // この処理を入れないと前回の接続を放置して、mountされる度に新規に接続を行ってしまい、
      // pusherへの接続が増えていってしまい、あっという間に接続上限を超えてしまうので注意。
      try {
        pusher!.disconnect();
      } catch (e) {
        if (Sentry.Native) {
          Sentry.Native.captureException(e);
        } else {
          (Sentry as any).Browser.captureException(e);
        }
      }
    };
  }, [teamDatas, loadingEnv, dataEnv]);
};

export default useWebSocketSync;
