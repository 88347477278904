import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import { View, TouchableOpacity, Image, Text } from 'react-native';
import Typography, { TypographyType } from '../../../../../presentational/atoms/typography';
import Modal from '../../../../../presentational/molecules/modal';
import { IStyleTheme, IThemePart } from '../../../../../theme';
import {
  Client,
  useOrganizationClientsQuery,
  OrganizationClientsDocument,
  useUpdateClientMutation,
  useDeleteClientMutation,
  useClientQuery,
  useClientProjectsQuery,
  useOrganizationQuery,
  Plan,
  useUpdateClientSortNoMutation,
  SortOrder,
  ClientSortKey,
  Organization,
  ClientByCodeQuery,
  ClientByCodeQueryVariables,
  ClientByCodeDocument,
} from '../../../../../../graphql/api/API';
import { LoginUserContext } from '../../../../../../modules/auth/LoginUserContext';
import Form from '../../../../../presentational/atoms/form';
import Input from '../../../../../presentational/atoms/input';
import Button from '../../../../../presentational/atoms/button';
import { Route, Switch, useHistory, useParams } from 'react-router';
import VirtualizedFlatList, {
  GlobalDragContextProvider,
} from '../../../../../presentational/atoms/list2/virtualized-flat-list';
import ClientCreateDialog from '../../../organisms/client-create-dialog';
import noData01 from '../../../../../../base64Images/no-data/no-data-1';
import noData03 from '../../../../../../base64Images/no-data/no-data-3';
import useDimensions from 'use-dimensions';
import SettingsElement from '../../../organisms/settings-element';
import Table from '../../../../../presentational/molecules/table';
import CustomScrollView from '../../../../../presentational/atoms/custom-scroll-view';
import DeleteIcon from '../../../../../presentational/molecules/image-icon/delete';
import PlusIcon from '../../../../../presentational/molecules/image-icon/plus';
import SearchIcon from '../../../../../presentational/molecules/image-icon/search';
import PlanNotAllowedView from '../../../organisms/plan-not-allowed-view';
import ResizableColumn from '../../../organisms/resizable-column';
import CaretSetIcon from '../../../../../presentational/molecules/image-icon/caret-set';
import CaretDownIcon from '../../../../../presentational/molecules/image-icon/caret-down';
import CaretUpIcon from '../../../../../presentational/molecules/image-icon/caret-up';
import when from '../../../../../../lang-extention/When';
import { useLazyQueryPromise } from '../../../../../../graphql/extention/useLazyQueryPromise';

const ErrorMessage = styled.Text<IStyleTheme>`
  color: ${(props: IStyleTheme) => props.theme.colors.error};
`;

const Container = styled.View`
  height: calc(100vh - 57px);
  display: flex;
  flex-direction: row;
`;

const MainAreaContainer = styled.View`
  display: flex;
  flex: 1;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.4s;
  z-index: 100;
  height: calc(100vh);
`;

const MainAreaHeader = styled.View`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: ${(props: IStyleTheme) => props.theme.colors.subHeader};
  padding: 5px 10px;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.4s;
  z-index: 2;
  height: 42px;
`;

const ClientItem = styled.View`
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  border-bottom-width: 1px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
`;

const ClientAddButton = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  padding-right: 5px;
  padding-left: 5px;
`;

interface IDeleteClientDialogProps {
  client: Client;
}

const DeleteClientDialog = (props: IDeleteClientDialogProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const history = useHistory();
  const [loginUser, __] = useContext(LoginUserContext);
  const [showModal, setShowModal] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [deleteClient, _] = useDeleteClientMutation({
    variables: {
      id: props.client.id!,
      input: {
        versionNo: props.client.versionNo,
      },
    },
    refetchQueries: [
      {
        query: OrganizationClientsDocument,
        variables: {
          organizationId: loginUser!.organizationId!,
        },
      },
    ],
  });

  return (
    <>
      <DeleteIcon
        size={23}
        onPress={() => {
          setShowModal(true);
        }}>
        <Typography variant={TypographyType.Normal} style={{ fontSize: 16 }}>
          削除する
        </Typography>
      </DeleteIcon>
      <Modal
        title={`「${props.client.name}」を削除しますか？`}
        isShow={showModal}
        onClose={() => {
          setShowModal(false);
          setShowAlertModal(false);
        }}>
        <Typography
          variant={TypographyType.Normal}
          style={{ textAlign: 'center', marginVertical: 10 }}>
          {`この取引先が紐付いているプロジェクトは、紐付きが解除されます`}
        </Typography>
        <Form>
          <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
            <Button
              text={'キャンセル'}
              style={{
                minWidth: 100,
                marginRight: 10,
                marginVertical: 10,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.primary }}
              disableValidate={true}
              onPress={() => {
                setShowModal(false);
                setShowAlertModal(false);
              }}
            />
            <Button
              text={'削除する'}
              style={{
                minWidth: 100,
                marginRight: 10,
                marginVertical: 10,
                borderColor: themeContext.colors.error,
                borderRadius: 3,
                borderWidth: 1,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.error }}
              onPress={async () => {
                setShowModal(false);
                setShowAlertModal(true);
              }}
            />
          </View>
        </Form>
      </Modal>
      <Modal
        title={`本当に削除してよろしいですか？`}
        isShow={showAlertModal}
        onClose={() => {
          setShowModal(false);
          setShowAlertModal(false);
        }}>
        <Typography
          variant={TypographyType.Description}
          style={{ textAlign: 'center', color: themeContext.colors.error }}>
          {`この操作はやり直しが出来ません`}
        </Typography>
        <Form>
          <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
            <Button
              text={'キャンセル'}
              style={{
                minWidth: 100,
                marginRight: 10,
                marginVertical: 10,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.primary }}
              disableValidate={true}
              onPress={() => {
                setShowModal(false);
                setShowAlertModal(false);
              }}
            />
            <Button
              text={'削除する'}
              style={{
                minWidth: 100,
                marginRight: 10,
                marginVertical: 10,
                borderColor: themeContext.colors.error,
                borderRadius: 3,
                borderWidth: 1,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.error }}
              onPress={async () => {
                await deleteClient();
                setShowModal(false);
                setShowAlertModal(false);
                history.push(`/app/${loginUser!.organizationId}/clients/`);
              }}
            />
          </View>
        </Form>
      </Modal>
    </>
  );
};

interface IListProps {
  setShowDialog: (value: boolean) => void;
}

const List = React.memo((props: IListProps) => {
  const [searchClientName, setSearchClientName] = useState('');
  const [searchClientNameLive, setSearchClientNameLive] = useState('');
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const history = useHistory();
  const [sortKey, setSortKey] = useState<ClientSortKey>(ClientSortKey.SortNo);
  const [sortOrder, setSortOrder] = useState<SortOrder | null>(null);
  const { loading, data } = useOrganizationClientsQuery({
    variables: {
      organizationId: loginUser!.organizationId,
      input: {
        clientName: searchClientName,
        sortKey: sortKey,
        sortOrder: sortOrder,
      },
    },
    fetchPolicy: 'network-only',
  });
  const [updateClientSortNo] = useUpdateClientSortNoMutation();

  // Memo化しておかないと、データに変更がなくても、このコンポーネントが描画される度に、全てのTaskSummary再描画がされてしまう。
  const clients = useMemo(() => {
    if (loading || !data?.organizationClients) {
      return [];
    }
    if (sortKey === ClientSortKey.SortNo) {
      return data!
        .organizationClients!.slice()
        .filter((client) => client!.name.indexOf(searchClientName) !== -1)
        .sort((a, b) => b!.sortNo - a!.sortNo);
    }
    return data!
      .organizationClients!.slice()
      .filter((client) => client!.name.indexOf(searchClientName) !== -1);
  }, [loading, data?.organizationClients, searchClientName, sortKey]);

  return (
    <View style={{ zIndex: 1 }}>
      <View
        style={{
          backgroundColor: themeContext.colors.header,
          paddingVertical: 7,
          paddingHorizontal: 3,
        }}>
        <Typography
          variant={TypographyType.SubTitle}
          ellipsis={true}
          style={{ color: '#FFFFFF', fontSize: 16, fontWeight: '600' }}>
          取引先管理
        </Typography>
      </View>
      <View style={{ borderBottomWidth: 1, borderColor: themeContext.colors.separator }}>
        <Form>
          <Input
            name={'searchClient'}
            label={''}
            icon={<SearchIcon size={20} containerStyle={{ marginLeft: 10 }} />}
            containerStyle={{ marginVertical: 10 }}
            value={searchClientName}
            inputstyle={{ borderWidth: 0 }}
            inputContainerStyle={{
              marginHorizontal: 10,
              backgroundColor: themeContext.colors.baseColor,
              shadowOffset: {
                width: -1,
                height: -1,
              },
              shadowOpacity: 0.1,
              elevation: 2,
              borderWidth: 1,
              borderRadius: 20,
              borderColor: themeContext.colors.separator,
            }}
            onChange={(value: string) => {
              setSearchClientNameLive(value);
              if (value?.trim() === '') {
                setSearchClientName('');
              }
            }}
            onPressEnterIncludeIMEConvert={(value: string) => setSearchClientName(value)}
            onBlur={(value: string) => setSearchClientName(value)}
          />
          {searchClientNameLive?.trim().length > 0 && (
            <Typography
              variant={TypographyType.Description}
              style={{ marginLeft: 10, fontSize: 12, textAlign: 'center', marginBottom: 10 }}>
              Enterキーを押すと絞り込みが行われます
            </Typography>
          )}
        </Form>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', overflow: 'hidden' }}>
          <ClientAddButton
            style={{
              display: 'flex',
              flexDirection: 'row',
              paddingHorizontal: 5,
            }}
            onPress={async () => {
              props.setShowDialog(true);
            }}>
            <PlusIcon
              size={12}
              containerStyle={{ marginLeft: 10, maxWidth: '100%' }}
              onPress={async () => {
                props.setShowDialog(true);
              }}>
              <Typography
                variant={TypographyType.Normal}
                style={{ fontSize: 12, color: themeContext.colors.description }}
                ellipsis={true}>
                取引先を追加する
              </Typography>
            </PlusIcon>
          </ClientAddButton>
          <TouchableOpacity
            style={{ flexDirection: 'row', alignItems: 'center' }}
            onPress={() => {
              if (sortOrder === null || sortOrder === undefined) {
                setSortKey(ClientSortKey.Name);
                setSortOrder(SortOrder.Asc);
                return;
              }
              if (sortOrder === SortOrder.Asc) {
                setSortKey(ClientSortKey.Name);
                setSortOrder(SortOrder.Desc);
                return;
              }
              setSortKey(ClientSortKey.SortNo);
              setSortOrder(null);
            }}>
            {when(sortOrder) //
              .on(
                (v) => v === null || v === undefined,
                () => (
                  <CaretSetIcon
                    size={12}
                    containerStyle={{
                      opacity: 0.2,
                      marginLeft: 3,
                      width: 10,
                      justifyContent: 'flex-end',
                    }}
                    onPress={() => {
                      setSortKey(ClientSortKey.Name);
                      setSortOrder(SortOrder.Asc);
                    }}
                  />
                )
              )
              .on(
                (v) => v === SortOrder.Asc,
                () => (
                  <CaretUpIcon
                    size={10}
                    containerStyle={{ marginLeft: 3, width: 10, justifyContent: 'flex-end' }}
                    onPress={() => {
                      setSortKey(ClientSortKey.Name);
                      setSortOrder(SortOrder.Desc);
                    }}
                  />
                )
              )
              .otherwise(() => (
                <CaretDownIcon
                  size={10}
                  containerStyle={{ marginLeft: 3, width: 10, justifyContent: 'flex-end' }}
                  onPress={() => {
                    setSortKey(ClientSortKey.SortNo);
                    setSortOrder(null);
                  }}
                />
              ))}
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontSize: 12,
                color: themeContext.colors.description,
                textAlign: 'left',
                paddingHorizontal: 5,
                width: 70,
              }}>
              並び替え
            </Typography>
          </TouchableOpacity>
        </View>
      </View>

      <GlobalDragContextProvider>
        <VirtualizedFlatList
          style={{ height: 'calc(100vh - 210px)' }}
          items={clients}
          renderItem={(item, index) => {
            return (
              <ClientItem>
                <Typography
                  variant={TypographyType.Normal}
                  ellipsis={true}
                  tooltipId={`client-${(item as Client).id}`}
                  tooltipWhenEllipsis
                  style={{
                    fontSize: 13,
                    color: themeContext.colors.textColor,
                    textAlign: 'left',
                    lineHeight: 28,
                  }}>
                  {(item as Client).name}
                </Typography>
              </ClientItem>
            );
          }}
          getKey={(client: any) => (client as Client).id!.toString()}
          itemHeight={40}
          onPress={(client: any) => {
            history.push(`/app/${loginUser!.organizationId}/clients/${(client as Client).id}/`);
          }}
          virticalDraggable={sortKey === ClientSortKey.SortNo}
          onDrop={async (info) => {
            const isMoveToFirst = info.endRowIndex === 0;
            const isMoveToLast = info.endRowIndex === clients.length - 1;
            const isMoveToDown = info.endRowIndex - info.startRowIndex > 0;

            let sortNo;
            if (isMoveToFirst) {
              sortNo = new Date().getTime();
            } else if (isMoveToLast) {
              sortNo = clients[info.endRowIndex]!.sortNo - 1000;
            } else {
              if (isMoveToDown) {
                const beforeTask = clients[info.endRowIndex];
                const afterTask = clients[info.endRowIndex + 1];
                sortNo = Math.floor((beforeTask!.sortNo + afterTask!.sortNo) / 2);
              } else {
                const beforeTask = clients[info.endRowIndex - 1];
                const afterTask = clients[info.endRowIndex];
                sortNo = Math.floor((beforeTask!.sortNo + afterTask!.sortNo) / 2);
              }
            }

            await updateClientSortNo({
              variables: {
                id: (info.item as Client).id!,
                input: {
                  sortNo: sortNo,
                  versionNo: (info.item as Client).versionNo,
                },
              },
              optimisticResponse: {
                __typename: 'Mutation',
                updateClientSortNo: Object.assign(
                  {
                    __typename: 'Client',
                  },
                  info.item as Client,
                  { sortNo: sortNo }
                ),
              },
            });
          }}
        />
      </GlobalDragContextProvider>
    </View>
  );
});

interface IClientNameModalProps {
  closeModal: () => void;
  client: Client;
}

const ClientNameModal = (props: IClientNameModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [name, setName] = useState(props.client.name);

  const [updateClient] = useUpdateClientMutation({
    variables: {
      id: props.client.id!,
      input: {
        name: name,
        code: props.client.code,
        versionNo: props.client.versionNo,
      },
    },
  });

  return (
    <Form style={{ minWidth: 400 }}>
      <Input
        name={'organizationName'}
        label={'取引先名'}
        initialValue={name}
        onChange={setName}
        validate={{
          required: {
            value: true,
            message: '取引先名を入力してください',
          },
          maxLength: {
            value: 100,
            message: '100文字以内で入力してください',
          },
        }}
      />
      <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1 }}>
        <Button
          text={'変更する'}
          completeText="変更しました"
          style={{ minWidth: 100, marginRight: 10, marginVertical: 10 }}
          onPress={async () => {
            await updateClient();
            props.closeModal();
          }}
        />
        <Button
          text={'キャンセル'}
          style={{
            minWidth: 100,
            marginRight: 10,
            marginVertical: 10,
            backgroundColor: 'transparent',
          }}
          textStyle={{ color: themeContext.colors.primary }}
          disableValidate={true}
          onPress={() => {
            props.closeModal();
          }}
        />
      </View>
    </Form>
  );
};

interface IClienCodeModalProps {
  closeModal: () => void;
  client: Client;
}

const ClientCodeModal = (props: IClienCodeModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [code, setCode] = useState(props.client.code);
  const [clientCodeErrorMessage, setClientCodeErrorMessage] = useState<string | null>(null);

  const fetchClientByCode = useLazyQueryPromise<ClientByCodeQuery, ClientByCodeQueryVariables>(
    ClientByCodeDocument
  );
  const [updateClient] = useUpdateClientMutation({
    variables: {
      id: props.client.id!,
      input: {
        name: props.client.name,
        code: code,
        versionNo: props.client.versionNo,
      },
    },
  });

  return (
    <Form style={{ minWidth: 400 }}>
      <Input
        name={'clientCode'}
        label={'取引先管理コード'}
        initialValue={code ?? ''}
        onChange={setCode}
        validate={{
          maxLength: {
            value: 50,
            message: '50文字以内で入力してください',
          },
          pattern: {
            value: /^[a-zA-Z0-9_-]*$/i,
            message: '英数字・半角ハイフン・アンダースコアのみ入力可能です。',
          },
        }}
      />
      {clientCodeErrorMessage && <ErrorMessage>{clientCodeErrorMessage}</ErrorMessage>}
      <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1 }}>
        <Button
          text={'変更する'}
          completeText="変更しました"
          style={{ minWidth: 100, marginRight: 10, marginVertical: 10 }}
          onPress={async () => {
            if (code) {
              const existClient = await fetchClientByCode({
                code: code,
              });
              if (code.trim() === props.client.code?.trim()) {
                props.closeModal();
                return;
              }

              if (existClient.data.clientByCode?.id) {
                setClientCodeErrorMessage('すでに同じ管理コードの取引先が存在しています');
                return;
              }
            }
            setClientCodeErrorMessage(null);

            await updateClient();
            props.closeModal();
          }}
        />
        <Button
          text={'キャンセル'}
          style={{
            minWidth: 100,
            marginRight: 10,
            marginVertical: 10,
            backgroundColor: 'transparent',
          }}
          textStyle={{ color: themeContext.colors.primary }}
          disableValidate={true}
          onPress={() => {
            props.closeModal();
          }}
        />
      </View>
    </Form>
  );
};

interface IProjectTableProps {
  client: Client;
}

const ProjectTable = (props: IProjectTableProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const history = useHistory();
  const [loginUser, setLoginUser] = useContext(LoginUserContext);

  const { loading, data } = useClientProjectsQuery({ //TODO TDV1-112
    variables: {
      clientId: props.client.id!,
    },
    fetchPolicy: 'network-only',
    skip: props.client.id === 'task',
  });

  if (loading) {
    return <></>;
  }

  return (
    <Table
      height={54}
      style={{ width: '100%' }}
      rowData={
        data!.clientProjects!.length > 0
          ? data!.clientProjects!.map((project) => {
              return {
                プロジェクト名: (
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}>
                    <View
                      style={{ flexDirection: 'column', justifyContent: 'center', marginLeft: 10 }}>
                      <Typography variant={TypographyType.Normal} style={{ fontSize: 14 }}>
                        {project!.name}
                      </Typography>
                    </View>
                  </View>
                ),
                操作: (
                  <View style={{ flexDirection: 'row' }}>
                    <TouchableOpacity
                      onPress={() =>
                        history.push(
                          `/app/${loginUser!.organizationId}/${project!.team.id}/projects/${
                            project!.id
                          }/list/`
                        )
                      }>
                      <Typography
                        variant={TypographyType.Normal}
                        style={{ fontSize: 13, color: themeContext.colors.link }}>
                        プロジェクトへ移動する
                      </Typography>
                    </TouchableOpacity>
                  </View>
                ),
              };
            })
          : [
              {
                プロジェクト名: (
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}>
                    <View
                      style={{ flexDirection: 'column', justifyContent: 'center', marginLeft: 10 }}>
                      <Typography variant={TypographyType.Normal} style={{ fontSize: 13 }}>
                        データがありません
                      </Typography>
                    </View>
                  </View>
                ),
                操作: null,
              },
            ]
      }
      rowDataStyle={{
        プロジェクト名: {
          width: 'auto',
          minWidth: 400,
        },
        操作: {
          maxWidth: 200,
        },
      }}
    />
  );
};

interface IClientContainerInnerProps {
  clientId: string;
  organization: Organization;
}

const ClientContainerInner = React.memo((props: IClientContainerInnerProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const { loading, data, error } = useClientQuery({
    variables: {
      id: props.clientId,
    },
    skip: props.clientId === 'task',
  });

  if (loading || !data?.client) {
    return <></>;
  }

  return (
    <CustomScrollView style={{ paddingBottom: 50 }}>
      <MainAreaHeader style={{} as any}>
        <Typography
          variant={TypographyType.Normal}
          style={[{ color: '#FFFFFF', fontWeight: '600' }, {}] as any}>
          {data.client.name}
        </Typography>
      </MainAreaHeader>
      <View
        style={{
          flexDirection: 'column',
          paddingVertical: 20,
          paddingHorizontal: 30,
        }}>
        <SettingsElement
          title={'取引先名'}
          titleWidth={140}
          changeText={'取引先名を変更する'}
          modal={(closeModal) => (
            <ClientNameModal closeModal={closeModal} client={data!.client!} />
          )}>
          <Typography variant={TypographyType.Normal}>{data!.client!.name}</Typography>
        </SettingsElement>

        {(props.organization.plan.code === Plan.Business ||
          props.organization.plan.code === Plan.Enterprise ||
          props.organization.plan.code === Plan.Professional) && (
          <SettingsElement
            title={'取引先管理コード'}
            titleWidth={140}
            changeText={'取引先管理コードを変更する'}
            modal={(closeModal) => (
              <ClientCodeModal closeModal={closeModal} client={data!.client!} />
            )}>
            <Typography variant={TypographyType.Normal}>
              {data!.client!.code ?? '(未登録)'}
            </Typography>
          </SettingsElement>
        )}

        <SettingsElement titleWidth={120}>
          <DeleteClientDialog client={data.client} />
        </SettingsElement>
        <View>
          <Typography variant={TypographyType.ElementiTitle} style={{ marginBottom: 10 }}>
            紐づくプロジェクト一覧
          </Typography>
          <ProjectTable client={data.client} />
        </View>
      </View>
    </CustomScrollView>
  );
});

interface IClientContainerProps {
  organization: Organization;
}

const ClientContainer = React.memo((props: IClientContainerProps) => {
  const params = useParams();
  return (
    <ClientContainerInner clientId={(params as any).clientId} organization={props.organization} />
  );
});

const MainArea = React.memo(() => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const { window } = useDimensions();
  const { loading, data } = useOrganizationClientsQuery({
    variables: {
      organizationId: loginUser!.organizationId,
    },
    fetchPolicy: 'network-only',
  });
  const { loading: organizationLoading, data: organizationData } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId,
    },
    fetchPolicy: 'network-only',
  });

  if (loading || organizationLoading || !organizationData?.organization) {
    return <></>;
  }

  return (
    <MainAreaContainer>
      <Switch>
        <Route path="/app/:organizationId/clients/:clientId/">
          <ClientContainer organization={organizationData.organization} />
        </Route>
        <Route>
          <View
            style={{
              height: 'calc(100vh - 57px)',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            {data?.organizationClients && (data?.organizationClients || []).length > 0 ? (
              <>
                <Image
                  source={{ uri: noData01 }}
                  resizeMode="contain"
                  style={{
                    width: Math.min(window.width / 4, 300),
                    height: 200,
                    opacity: 0.7,
                  }}
                />
                <Typography
                  variant={TypographyType.Normal}
                  style={{ color: themeContext.colors.description }}>
                  取引先を選択してください
                </Typography>
              </>
            ) : (
              <>
                <Image
                  source={{ uri: noData03 }}
                  resizeMode="contain"
                  style={{
                    width: Math.min(window.width / 4, 300),
                    height: 200,
                    opacity: 0.7,
                  }}
                />
                <Typography
                  variant={TypographyType.Normal}
                  style={{ color: themeContext.colors.description }}>
                  取引先情報を登録してください
                </Typography>
              </>
            )}
          </View>
        </Route>
      </Switch>
    </MainAreaContainer>
  );
});

const AppOrganizationClientsPage = () => {
  const history = useHistory();
  const [showDialog, setShowDialog] = useState(false);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [notAllowedPlan, setNotAllowedPlan] = useState(false);
  const { loading: organizationLoading, data: organizationData } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId,
    },
    fetchPolicy: 'network-only',
  });

  // Basicプランの場合には取引先管理できないようにする
  useEffect(() => {
    setTimeout(() => {
      if (organizationData?.organization?.plan.code === Plan.Basic) {
        setNotAllowedPlan(true);
      }
    }, 1000);
  }, [organizationData]);

  if (organizationLoading) {
    return <></>;
  }

  return (
    <PlanNotAllowedView isNotAllowedPlan={notAllowedPlan} style={{ height: 'calc(100vh)' }}>
      <Container>
        <ResizableColumn
          cookieName="CLIENT_LIST_WIDTH"
          renderChild={(width) => {
            return <List setShowDialog={setShowDialog} />;
          }}
        />
        <MainArea />
        <Modal
          isShow={showDialog}
          title="取引先を登録する"
          onClose={() => {
            setShowDialog(false);
          }}>
          <ClientCreateDialog
            onComplete={(newClient) => {
              setShowDialog(false);
              history.push(`/app/${loginUser!.organizationId}/clients/${newClient.id}/`);
            }}
            onCancel={() => {
              setShowDialog(false);
            }}
          />
        </Modal>
      </Container>
    </PlanNotAllowedView>
  );
};

export default React.memo(AppOrganizationClientsPage);
