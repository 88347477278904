import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { Text, TouchableOpacity, View, Image } from 'react-native';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../../theme';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import FavoriteProjectList from '../../organisms/favorite-project-list';
import Icon from '../../../../presentational/atoms/icon';
import Avatar from '../../../../presentational/atoms/avatar';
import SelectButton from '../../../../presentational/molecules/select-button';
import { Redirect, Route, Switch, useHistory, useLocation, useParams } from 'react-router';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import {
  FavoriteProjectsDocument,
  Organization,
  Project,
  ProjectCompleteFilter,
  Task,
  TaskStatus,
  useCancelFavoriteProjectMutation,
  useCompleteProjectMutation,
  useFavoriteProjectMutation,
  useFavoriteProjectsQuery,
  useIncompleteProjectMutation,
  useMeQuery,
  useMySettingsQuery,
  useOrganizationClientsQuery,
  useOrganizationMembersQuery,
  useOrganizationQuery,
  useProjectMembersQuery,
  useProjectQuery,
  useProjectTasksEstimateTimeSecQuery,
  useProjectTaskStatusQuery,
  useProjectWorkingTimeSecQuery,
} from '../../../../../graphql/api/API';
import {
  matchExpireFilter,
  matchTaskAssigner,
  matchTaskStatus,
  ProjectFilterContextProvider,
  ProjectFilter,
  ProjectFilterContext,
  TaskFilter,
  TaskFilterContext,
  TaskFilterContextProvider,
} from '../../templates/app/team-projects';
import moment from 'moment-timezone';
import Modal from '../../../../presentational/molecules/modal';
import LoginUtil from '../../../../../modules/auth/LoginUtil';
import Button from '../../../../presentational/atoms/button';
import ColorUtil from '../../../../../util/ColorUtil';
import Form from '../../../../presentational/atoms/form';
import noData01 from '../../../../../base64Images/no-data/no-data-1';
import noData03 from '../../../../../base64Images/no-data/no-data-3';
import useDimensions from 'use-dimensions';
import Spinner from '../../../../presentational/atoms/spinner';
import TaskList from '../../organisms/task-list';
import ProjectBoard from '../../templates/app/team-projects/board';
import ProjectGanttChart from '../../templates/app/team-projects/gantt-chart';
import CalendarEventList from '../../organisms/calendar-event-list';
import TimeUtil from '../../../../../util/TimeUtil';
import MultiPickerFilter from '../../../../presentational/atoms/multi-picker-filter';
import HorizontalMenu from '../horizontal-menu';
import CheckIcon from '../../../../presentational/molecules/image-icon/check';
import StarIcon from '../../../../presentational/molecules/image-icon/star';
import EditIcon from '../../../../presentational/molecules/image-icon/edit';
import ErrorMessageModal from '../error-message-modal';
import OrganizationUtil from '../../../../../util/OrganizationUtil';
import ProjectListFull from '../project-list-full/favorite';
import Input from '../../../../presentational/atoms/input';
import SearchIcon from '../../../../presentational/molecules/image-icon/search';

const Container = styled.View`
  height: calc(100vh - 57px - 70px);
  display: flex;
  flex-direction: column;
`;

const MenuContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 5px;
  background-color: #879eb3;
`;

interface IMenuItemProps extends IStyleTheme {
  isActive: boolean;
}

const MenuItem = styled.TouchableOpacity<IMenuItemProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom-width: ${(props: IMenuItemProps) => (props.isActive ? '2px' : '0')};
  border-color: #ffffff;
  margin-right: 24px;
`;

const MainAreaHeaderForProjectList = styled.View`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.4s;
  z-index: 2;
`;

const MainAreaHeaderBottomForProject = styled.View`
  display: flex;
  width: 100%;
  height: 56px;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 5px 20px;
  z-index: 10;
`;

const MainAreaContainer = styled.View`
  flex: 1;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.4s;
  marginright: 50px;
  z-index: 1;
`;
// 本当はoverflow-xはvisibleにしたいのだが、CSSの仕様で、overflow-yを指定した場合には、xの挙動もそちらで上書きされるため実現できない

const MainAreaHeader = styled.View`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
  padding: 5px 10px;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.4s;
  z-index: 2;
`;

const MainAreaHeaderTop = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  z-index: 1;
  width: 100%;
`;

const MainAreaHeaderBottom = styled.View`
  display: flex;
  width: 100%;
  height: 40px;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0.2rem 0;
`;

const FilterArea = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
`;

const FavoriteFilterArea = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  flex: 1;
`;

const FilterElementsContainer = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.2rem 0;
  margin: 0 0.2rem;
`;

const FilterElements = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

interface IFilterProps {
  project: Project;
  taskStatuses: TaskStatus[];
}

const Filter = (props: IFilterProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const location = useLocation();
  const { window: win } = useDimensions();
  const windowWidth = win.width;
  const [loginUser, _] = useContext(LoginUserContext);
  const [taskFilter, setTaskFilter] = useContext(TaskFilterContext);
  const [selectedStatusIds, setSelectedStatusIds] = useState<string[]>([]);
  const { loading, data, error } = useProjectMembersQuery({
    variables: {
      projectId: props.project.id!,
    },
  });

  useEffect(() => {
    setSelectedStatusIds(
      props.taskStatuses?.filter((status) => !status!.endStatus).map((status) => status!.id!) || []
    );
    setTaskFilter({
      expire: (taskFilter as TaskFilter).expire,
      taskStatusIds:
        props.taskStatuses?.filter((status) => !status!.endStatus).map((status) => status!.id!) ||
        [],
      // 初期表示では完了ステータスだけ非表示にフィルター設定する
      assignerIds: (taskFilter as TaskFilter).assignerIds,
      showCompleteTask: (taskFilter as TaskFilter).showCompleteTask,
      isInitialView: false,
    });
  }, [props.project]);

  if (loading || !data?.projectMembers) {
    return <></>;
  }

  return (
    <FilterArea>
      {!props.project.team.personalTeam && (
        <FilterElementsContainer>
          <Text
            style={{
              fontSize: 7,
              color: themeContext.colors.description,
              textAlign: 'center',
              marginBottom: 2,
            }}>
            担当者
          </Text>
          <FilterElements>
            {data.projectMembers.length <= 5 ? (
              <SelectButton
                contents={data
                  .projectMembers!.slice()
                  .sort((a, b) => {
                    if (a!.id === loginUser!.id) {
                      // ログインしているユーザー自身を先頭に表示する
                      return 1;
                    }
                    return (a!.name as any) - (b!.name as any);
                  })
                  .map((member: any) => {
                    return {
                      key: member.id.toString(),
                      content: (
                        <Avatar
                          size={22}
                          name={member.name}
                          imageUrl={member.profileImageUrl}
                          showToolTip={true}
                          toolTipKey={member.id.toString()}
                        />
                      ),
                    };
                  })}
                multiSelect={true}
                values={(taskFilter as TaskFilter).assignerIds}
                onChange={(value) => {
                  setTaskFilter({
                    ...(taskFilter as TaskFilter),
                    assignerIds: value,
                  });
                }}
              />
            ) : (
              <MultiPickerFilter
                placeHolder="担当者を絞り込む"
                containerStyle={{ width: '6rem' }}
                emptyPickerItem={{
                  label: '未割り当て',
                  value: 'empty',
                }}
                pickerItems={data.projectMembers
                  .slice()
                  .sort((a, b) => {
                    if (a!.id === loginUser!.id) {
                      // ログインしているユーザー自身を先頭に表示する
                      return 1;
                    }
                    return (a!.name as any) - (b!.name as any);
                  })
                  .map((member) => {
                    return {
                      label: member!.name!,
                      subLabel: member!.mailAddress!,
                      value: member!.id!,
                      imageUrl: member!.profileImageUrl,
                    };
                  })}
                onChange={(items) => {
                  setTaskFilter({
                    ...(taskFilter as TaskFilter),
                    assignerIds: items.map((item) => item.value),
                  });
                }}
                onBlur={(items) => {
                  setTaskFilter({
                    ...(taskFilter as TaskFilter),
                    assignerIds: items.map((item) => item.value),
                  });
                }}
              />
            )}
          </FilterElements>
        </FilterElementsContainer>
      )}
      {windowWidth > 1400 && (
        <FilterElementsContainer>
          <Text
            style={{
              fontSize: 7,
              color: themeContext.colors.description,
              textAlign: 'center',
              marginBottom: 2,
            }}>
            〆切日
          </Text>
          <FilterElements>
            <SelectButton
              contents={[
                {
                  key: 'today',
                  content: '今日',
                },
                {
                  key: '3days',
                  content: '3日以内',
                },
                {
                  key: 'week',
                  content: '1週間以内',
                },
              ]}
              onChange={(value) => {
                setTaskFilter({
                  expire: value[0] || null,
                  taskStatusIds: (taskFilter as TaskFilter).taskStatusIds,
                  assignerIds: (taskFilter as TaskFilter).assignerIds,
                  showCompleteTask: (taskFilter as TaskFilter).showCompleteTask,
                });
              }}
            />
          </FilterElements>
        </FilterElementsContainer>
      )}
      {location.pathname.indexOf('/board/') === -1 && (
        <FilterElementsContainer>
          <Text
            style={{
              fontSize: 7,
              color: themeContext.colors.description,
              textAlign: 'center',
              marginBottom: 2,
            }}>
            ステータス
          </Text>
          <FilterElements>
            <SelectButton
              textStyle={{ maxWidth: 70 }}
              contents={props.taskStatuses!.map((status) => {
                return {
                  key: status!.id!.toString(),
                  content: status!.name,
                };
              })}
              values={selectedStatusIds}
              multiSelect={true}
              onChange={(value) => {
                setSelectedStatusIds(value);
                setTaskFilter({
                  expire: (taskFilter as TaskFilter).expire,
                  taskStatusIds: value,
                  assignerIds: (taskFilter as TaskFilter).assignerIds,
                  showCompleteTask: (taskFilter as TaskFilter).showCompleteTask,
                });
              }}
            />
          </FilterElements>
        </FilterElementsContainer>
      )}
    </FilterArea>
  );
};

interface IProjectFilterProps {
  organization: Organization;
}

const FavoriteProjectFilter = (props: IProjectFilterProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, _] = useContext(LoginUserContext);
  const [searchProjectName, setSearchProjectName] = useState('');
  const [searchProjectNameLive, setSearchProjectNameLive] = useState('');
  const [projectFilter, setProjectFilter] = useContext(ProjectFilterContext);
  const [selectedClientIds, setSelectedClientIds] = useState<string[]>([]);
  const { loading, data, error } = useOrganizationMembersQuery({
    variables: {
      organizationId: props.organization.id!,
    },
  });
  const {
    loading: clientLoading,
    data: clientData,
    error: clientError,
  } = useOrganizationClientsQuery({
    variables: {
      organizationId: loginUser!.organizationId,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    //@ts-ignore
    setProjectFilter({
      ...(projectFilter as ProjectFilter),
      isInitialView: false,
      projectStatusIds: [],
    });
  }, []);

  if (loading || clientLoading || !clientData?.organizationClients) {
    return <></>;
  }

  return (
    <FavoriteFilterArea>
      {OrganizationUtil.isTeamPlan(props.organization) && (
        <FilterElementsContainer>
          <Text
            style={{
              fontSize: 10,
              color: themeContext.colors.description,
              textAlign: 'center',
              marginBottom: 2,
            }}>
            担当者
          </Text>
          <FilterElements>
            {(data?.organizationMembers?.length ?? 0) <= 5 ? (
              <SelectButton
                contents={(data?.organizationMembers ?? [])
                  .slice()
                  .sort((a, b) => {
                    if (a!.id === loginUser!.id) {
                      // ログインしているユーザー自身を先頭に表示する
                      return 1;
                    }
                    return (a!.name as any) - (b!.name as any);
                  })
                  .map((member: any) => {
                    return {
                      key: member.id.toString(),
                      content: (
                        <Avatar
                          size={22}
                          name={member.name}
                          imageUrl={member.profileImageUrl}
                          showToolTip={true}
                          toolTipKey={member.id.toString()}
                        />
                      ),
                    };
                  })}
                multiSelect={true}
                values={(projectFilter as ProjectFilter).assignerIds}
                onChange={(value) => {
                  //@ts-ignore
                  setProjectFilter({
                    ...(projectFilter as ProjectFilter),
                    assignerIds: value,
                  });
                }}
              />
            ) : (
              <MultiPickerFilter
                placeHolder="担当者を絞り込む"
                containerStyle={{ width: '6rem' }}
                emptyPickerItem={{
                  label: '未割り当て',
                  value: 'empty',
                }}
                pickerItems={(data?.organizationMembers ?? [])
                  .slice()
                  .sort((a, b) => {
                    if (a!.id === loginUser!.id) {
                      // ログインしているユーザー自身を先頭に表示する
                      return 1;
                    }
                    return (a!.name as any) - (b!.name as any);
                  })
                  .map((member) => {
                    return {
                      label: member!.name!,
                      subLabel: member!.mailAddress!,
                      value: member!.id!,
                      imageUrl: member!.profileImageUrl,
                    };
                  })}
                onChange={(items) => {
                  //@ts-ignore
                  setProjectFilter({
                    ...(projectFilter as ProjectFilter),
                    assignerIds: items.map((item) => item.value),
                  });
                }}
                onBlur={(items) => {
                  //@ts-ignore
                  setProjectFilter({
                    ...(projectFilter as ProjectFilter),
                    assignerIds: items.map((item) => item.value),
                  });
                }}
              />
            )}
          </FilterElements>
        </FilterElementsContainer>
      )}
      <FilterElementsContainer>
        <View style={{ marginTop: 10, paddingLeft: 10 }}>
          <Text
            style={{
              fontSize: 10,
              color: themeContext.colors.description,
              textAlign: 'center',
              marginBottom: 2,
            }}>
            取引先
          </Text>
          <FilterElements>
            <MultiPickerFilter
              placeHolder="取引先を絞り込む"
              value={selectedClientIds}
              pickerItems={(clientData?.organizationClients?.slice() ?? [])
                .sort((a, b) => (a!.name as any) - (b!.name as any))
                .map((client) => {
                  return {
                    label: client!.name!,
                    value: client!.id!,
                  };
                })}
              onChange={(items) => {}}
              onBlur={(items) => {
                setSelectedClientIds(items.map((item) => item.value));
                //@ts-ignore
                setProjectFilter({
                  ...(projectFilter as ProjectFilter),
                  clientIds: items.map((item) => item.value),
                });
              }}
            />
          </FilterElements>
        </View>
      </FilterElementsContainer>
      <FilterElementsContainer>
        <Text
          style={{
            fontSize: 10,
            color: themeContext.colors.description,
            textAlign: 'center',
            marginBottom: 2,
          }}>
          完了ステータス
        </Text>
        <FilterElements>
          <SelectButton
            contents={[
              {
                key: 'include',
                content: '完了を含む',
              },
            ]}
            onChange={(value) => {
              if (value.includes('include')) {
                //@ts-ignore
                setProjectFilter({
                  ...(projectFilter as ProjectFilter),
                  completeCondition: ProjectCompleteFilter.Both,
                });
              } else {
                //@ts-ignore
                setProjectFilter({
                  ...(projectFilter as ProjectFilter),
                  completeCondition: ProjectCompleteFilter.Incomplete,
                });
              }
            }}
          />
        </FilterElements>
      </FilterElementsContainer>
      <Form style={{ width: 'auto', flexDirection: 'row' }}>
        <Input
          name={'searchProject'}
          label={''}
          value={searchProjectName}
          placeholder={'プロジェクト名で絞り込む(Enterキーで検索)'}
          icon={<SearchIcon size={20} containerStyle={{ marginLeft: 10 }} />}
          containerStyle={{ marginVertical: 10, width: 'auto' }}
          inputstyle={{ borderWidth: 0, fontSize: 14, minWidth: 300 }}
          inputContainerStyle={{
            marginHorizontal: 10,
            backgroundColor: themeContext.colors.baseColor,
            shadowOffset: {
              width: -1,
              height: -1,
            },
            shadowOpacity: 0.1,
            elevation: 2,
            borderWidth: 1,
            borderRadius: 20,
            borderColor: themeContext.colors.separator,
          }}
          onChange={(value: string) => {
            setSearchProjectNameLive(value);
            if (value?.trim() === '') {
              setSearchProjectName('');
              //@ts-ignore
              setProjectFilter({
                ...(projectFilter as ProjectFilter),
                projectName: null,
              });
            }
          }}
          onPressEnterIncludeIMEConvert={(value: string) => {
            setSearchProjectName(value);
            //@ts-ignore
            setProjectFilter({
              ...(projectFilter as ProjectFilter),
              projectName: value,
            });
          }}
          onBlur={(value: string) => {
            setSearchProjectName(value);
            //@ts-ignore
            setProjectFilter({
              ...(projectFilter as ProjectFilter),
              projectName: value,
            });
          }}
        />
      </Form>
    </FavoriteFilterArea>
  );
};

interface ICompleteProjectModalProps {
  project: Project;
  showModal: boolean;
  onPressYes: () => Promise<void>;
  onCloseModal: () => void;
}
const CompleteProjectConfirmModal = (props: ICompleteProjectModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  return (
    <Modal
      title={'このプロジェクトを完了しますか？'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <View style={{ flexDirection: 'column' }}>
        <View style={{ marginTop: 10 }}>
          <Typography variant={TypographyType.Normal} style={{ textAlign: 'center' }}>
            {props.project.name}
          </Typography>
        </View>
        <View style={{ marginTop: 10 }}>
          <Typography variant={TypographyType.Description} style={{ textAlign: 'center' }}>
            {`作業中のタスクがある場合、自動的に作業停止します。`}
          </Typography>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
          <Button
            text={'完了する'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
            }}
            onPress={async () => {
              await props.onPressYes();
            }}
          />
          <Button
            text={'キャンセル'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.primary }}
            disableValidate={true}
            onPress={() => {
              props.onCloseModal();
            }}
          />
        </View>
      </View>
    </Modal>
  );
};

interface IIncompleteProjectModalProps {
  project: Project;
  showModal: boolean;
  onPressYes: () => Promise<void>;
  onCloseModal: () => void;
}
const IncompleteProjectConfirmModal = (props: IIncompleteProjectModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  return (
    <Modal
      title={'このプロジェクトを未完了に戻しますか？'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <View style={{ flexDirection: 'column' }}>
        <View style={{ marginTop: 10 }}>
          <Typography variant={TypographyType.Normal} style={{ textAlign: 'center' }}>
            {props.project.name}
          </Typography>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
          <Button
            text={'未完了に戻す'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
            }}
            onPress={async () => {
              await props.onPressYes();
            }}
          />
          <Button
            text={'キャンセル'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.primary }}
            disableValidate={true}
            onPress={() => {
              props.onCloseModal();
            }}
          />
        </View>
      </View>
    </Modal>
  );
};

interface IProjectContainerInnerProps {
  organization: Organization;
  projectId: string;
}

const ProjectContainerInner = React.memo((props: IProjectContainerInnerProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [showIncompleteModal, setShowIncompleteModal] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [showErrorModal, setShowErrorModal] = useState(false);

  useEffect(() => {
    setDeleted(false);
  }, [props.projectId]);

  const { loading: mySettingsLoading, data: mySettingsData } = useMySettingsQuery();
  const { loading: meLoading, data: meData } = useMeQuery();

  const { loading, data, error } = useProjectQuery({
    variables: {
      id: props.projectId,
    },
    skip: deleted || props.projectId === 'task',
    fetchPolicy: 'network-only',
  });

  const { loading: taskStatusLoading, data: taskStatusData } = useProjectTaskStatusQuery({
    variables: {
      projectId: props.projectId,
    },
    skip: deleted || props.projectId === 'task',
    fetchPolicy: 'network-only',
  });

  const { loading: totalWorkingSecLoading, data: totalWorkingSecData } =
    useProjectWorkingTimeSecQuery({
      variables: {
        id: props.projectId,
      },
      fetchPolicy: 'network-only',
      skip: props.projectId === 'task',
    });

  const { loading: totalTaskEstimateWorkingSecLoading, data: totalTaskEstimateWorkingSecData } =
    useProjectTasksEstimateTimeSecQuery({
      variables: {
        id: props.projectId,
      },
      fetchPolicy: 'network-only',
      skip: props.projectId === 'task',
    });

  const [completeProject] = useCompleteProjectMutation({
    variables: {
      id: props.projectId,
      input: {
        versionNo: data?.project?.versionNo || 0,
      },
    },
  });
  const [incompleteProject] = useIncompleteProjectMutation({
    variables: {
      id: props.projectId,
      input: {
        versionNo: data?.project?.versionNo || 0,
      },
    },
  });
  const [favoriteProject] = useFavoriteProjectMutation({
    variables: {
      id: props.projectId,
      input: {
        versionNo: data?.project?.versionNo || 0,
      },
    },
    refetchQueries: [
      {
        query: FavoriteProjectsDocument,
      },
    ],
  });
  const [cancelFavoriteProject] = useCancelFavoriteProjectMutation({
    variables: {
      id: props.projectId,
      input: {
        versionNo: data?.project?.versionNo || 0,
      },
    },
    refetchQueries: [
      {
        query: FavoriteProjectsDocument,
      },
    ],
  });

  if (
    loading ||
    !data?.project ||
    taskStatusLoading ||
    mySettingsLoading ||
    meLoading ||
    !meData?.me
  ) {
    return <></>;
  }

  return (
    <>
      <TaskFilterContextProvider>
        <MainAreaHeader>
          <MainAreaHeaderTop>
            <View
              style={{
                flexDirection: 'row',
              }}>
              <View style={{ flexDirection: 'column' }}>
                <View style={{ flexDirection: 'row' }}>
                  {OrganizationUtil.isTeamPlan(data!.project.team.organization) &&
                    data!.project!.team && (
                      <Typography
                        variant={TypographyType.Description}
                        style={{ marginLeft: 3, marginRight: 15, maxWidth: 600, fontSize: 11 }}
                        ellipsis={true}>
                        チーム：{data!.project!.team!.name}
                      </Typography>
                    )}
                  {data!.project!.client && (
                    <Typography
                      variant={TypographyType.Description}
                      style={{ marginLeft: 3, marginRight: 10, maxWidth: 600, fontSize: 11 }}
                      ellipsis={true}>
                      取引先：{data!.project!.client!.name}
                    </Typography>
                  )}
                </View>
                <Typography
                  variant={TypographyType.SubTitle}
                  style={{ marginLeft: 3, marginRight: 10, maxWidth: 600 }}
                  ellipsis={true}>
                  {data!.project!.name}
                </Typography>
              </View>
              <EditIcon
                size={22}
                containerStyle={{ marginRight: 8, marginLeft: 5 }}
                onPress={() => {
                  history.push(
                    location.pathname +
                      `?projectDetailId=${props.projectId}&projectDetailMode=standard`
                  );
                }}
              />
              {data.project.favoriteMembers.filter((member) => member!.id === loginUser!.id)
                .length > 0 ? (
                <StarIcon
                  size={22}
                  on={true}
                  containerStyle={{ marginLeft: 3, marginRight: 10 }}
                  onPress={() => {
                    cancelFavoriteProject();
                  }}
                />
              ) : (
                <StarIcon
                  size={22}
                  on={false}
                  containerStyle={{ marginLeft: 3, marginRight: 10 }}
                  onPress={() => {
                    favoriteProject();
                  }}
                />
              )}
              {data.project?.completeDateTime ? (
                <>
                  <CheckIcon
                    size={22}
                    on={true}
                    containerStyle={{ marginRight: 10 }}
                    onPress={() => {
                      if (!meData.me?.projectUpdatePermissionFlg) {
                        setShowErrorModal(true);
                        return;
                      }
                      setShowIncompleteModal(true);
                    }}
                  />
                  <IncompleteProjectConfirmModal
                    project={data!.project!}
                    showModal={showIncompleteModal}
                    onPressYes={async () => {
                      setShowIncompleteModal(false);
                      await incompleteProject();
                    }}
                    onCloseModal={() => setShowIncompleteModal(false)}
                  />
                </>
              ) : (
                <>
                  <CheckIcon
                    size={22}
                    on={false}
                    containerStyle={{ marginRight: 10 }}
                    onPress={() => {
                      if (!meData.me?.projectUpdatePermissionFlg) {
                        setShowErrorModal(true);
                        return;
                      }
                      setShowCompleteModal(true);
                    }}
                  />
                  <CompleteProjectConfirmModal
                    project={data!.project!}
                    showModal={showCompleteModal}
                    onPressYes={async () => {
                      setShowCompleteModal(false);
                      await completeProject();
                    }}
                    onCloseModal={() => setShowCompleteModal(false)}
                  />
                </>
              )}
            </View>
            <View>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <View>
                  {(props.organization.plan.code === 'Business' ||
                    props.organization.plan.code === 'Enterprise') && (
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        //@ts-ignore
                        gap: 5,
                        marginRight: 10,
                      }}>
                      <Typography
                        variant={TypographyType.Description}
                        style={{ textAlign: 'center' }}>
                        担当者:
                      </Typography>
                      {data.project?.assignees.length === 0 ? (
                        <Typography
                          variant={TypographyType.Description}
                          style={{ textAlign: 'center' }}>
                          未設定
                        </Typography>
                      ) : data.project?.assignees.length > 10 ? (
                        <Avatar
                          size={24}
                          textStyle={{ fontSize: 10 }}
                          name={`${data.project.assignees.length}名`}
                        />
                      ) : (
                        data.project.assignees.map((info, i) => {
                          return (
                            <Avatar
                              size={24}
                              name={info.member.name!}
                              imageUrl={info.member.profileImageUrl}
                              showToolTip
                              key={i}
                            />
                          );
                        })
                      )}
                    </View>
                  )}
                </View>
                <Typography
                  variant={TypographyType.Description}
                  style={{ textAlign: 'right', marginRight: 10 }}>
                  {`プロジェクトの見積時間: ${
                    data?.project.estimateTimeSec
                      ? TimeUtil.formatSecToShortTime(data?.project.estimateTimeSec ?? 0)
                      : '-'
                  }`}
                </Typography>
                <Typography
                  variant={TypographyType.Description}
                  style={{ textAlign: 'right', marginRight: 10 }}>
                  {`タスクの合計見積時間: ${
                    totalTaskEstimateWorkingSecData
                      ? TimeUtil.formatSecToShortTime(
                          totalTaskEstimateWorkingSecData?.projectTasksEstimateTimeSec ?? 0
                        )
                      : '-'
                  }`}
                </Typography>
                <Typography
                  variant={TypographyType.Description}
                  style={{ textAlign: 'right', marginRight: 10 }}>
                  {`合計作業時間: ${TimeUtil.formatForTask(
                    totalWorkingSecData?.projectWorkingTimeSec ?? 0
                  )}`}
                </Typography>
              </View>
            </View>
          </MainAreaHeaderTop>
          <MainAreaHeaderBottom>
            <HorizontalMenu
              style={{ marginLeft: 10 }}
              menus={[
                {
                  title: 'リスト',
                  path: `/app/${loginUser!.organizationId}/my/favorite-project/${
                    data!.project!.id
                  }/list/`,
                },
                {
                  title: 'ボード',
                  path: `/app/${loginUser!.organizationId}/my/favorite-project/${
                    data!.project!.id
                  }/board/`,
                },
                {
                  title: 'スケジュール',
                  path: `/app/${loginUser!.organizationId}/my/favorite-project/${
                    data!.project!.id
                  }/schedule/`,
                },
                {
                  title: 'カレンダー予定',
                  path: `/app/${loginUser!.organizationId}/my/favorite-project/${
                    data!.project!.id
                  }/calendar-events/`,
                },
              ]}
            />
            <Filter
              project={data!.project!}
              taskStatuses={taskStatusData!.projectTaskStatus as TaskStatus[]}
            />
          </MainAreaHeaderBottom>
        </MainAreaHeader>
        <Switch>
          <Route
            path={'/app/:organizationId/my/favorite-project/:projectId/list'}
            component={TaskList}
          />
          <Route
            path={'/app/:organizationId/my/favorite-project/:projectId/board/'}
            component={ProjectBoard}
          />
          <Route
            path={'/app/:organizationId/my/favorite-project/:projectId/schedule/'}
            component={ProjectGanttChart}
          />
          <Route
            path={'/app/:organizationId/my/favorite-project/:projectId/calendar-events/'}
            component={CalendarEventList}
          />
        </Switch>
      </TaskFilterContextProvider>
      <ErrorMessageModal
        showModal={showErrorModal}
        title={'プロジェクトを編集できません'}
        message={`プロジェクトを編集する権限がありません${'\n'}権限が必要な場合、管理権限を持っているメンバーに問い合わせてください`}
        onCloseModal={() => setShowErrorModal(false)}
      />
    </>
  );
});

const ProjectContainer = React.memo(() => {
  const params = useParams();
  const [loginUser, _] = useContext(LoginUserContext);
  const { loading, data } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId,
    },
  });

  if (loading) {
    return <></>;
  }

  if (!(params as any).projectId || (params as any).projectId === 'view' || (params as any).projectId === 'contract') {
    return <></>;
  }

  return (
    <ProjectContainerInner
      organization={data!.organization!}
      projectId={(params as any).projectId}
    />
  );
});

interface IProjectListContainerInnerProps {
  organization: Organization;
}

const ProjectListContainerInner = React.memo((props: IProjectListContainerInnerProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  return (
    <ProjectFilterContextProvider>
      <MainAreaHeaderForProjectList>
        <View
          style={{
            backgroundColor: themeContext.colors.header,
            width: '100%',
            padding: 5,
          }}>
          <Typography
            variant={TypographyType.SubTitle}
            ellipsis={true}
            style={{ color: '#FFFFFF', fontSize: 16, fontWeight: '600' }}>
            お気に入りプロジェクト
          </Typography>
        </View>
      </MainAreaHeaderForProjectList>
      <MainAreaHeaderBottomForProject>
        <FavoriteProjectFilter organization={props.organization} />
      </MainAreaHeaderBottomForProject>
      <ProjectListFull />
    </ProjectFilterContextProvider>
  );
});

const ProjectListContainer = React.memo(() => {
  const params = useParams();
  const [loginUser, _] = useContext(LoginUserContext);
  const { loading, data } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId,
    },
  });

  if (loading) {
    return <></>;
  }

  return <ProjectListContainerInner organization={data!.organization!} />;
});

const FavoriteProject = () => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, _] = useContext(LoginUserContext);
  const history = useHistory();
  const location = useLocation();
  const [projectView, setProjectView] = useState(false);

  useEffect(() => {
    setProjectView(location.pathname.indexOf('project/view') !== -1);
  }, [location.pathname]);

  return (
    <Container>
      <MenuContainer>
        <MenuItem
          isActive={!projectView}
          onPress={() => {
            setProjectView(false);
            history.push(`/app/${loginUser!.organizationId}/my/favorite-project/`);
          }}>
          <Typography
            variant={TypographyType.Normal}
            style={{ fontSize: 12, color: themeContext.colors.baseColor }}>
            タスク一覧
          </Typography>
        </MenuItem>
        <MenuItem
          isActive={projectView}
          onPress={() => {
            setProjectView(true);
            history.push(`/app/${loginUser!.organizationId}/my/favorite-project/view/list/`);
          }}>
          <Typography
            variant={TypographyType.Normal}
            style={{ fontSize: 12, color: themeContext.colors.baseColor }}>
            プロジェクト一覧
          </Typography>
        </MenuItem>
      </MenuContainer>
      <View style={{ flexDirection: 'row' }}>
        {!projectView && <FavoriteProjectList />}
        <MainAreaContainer>
          <Switch>
            <Route path="/app/:organizationId/my/favorite-project/view/">
              <ProjectListContainer />
            </Route>
            <Route path="/app/:organizationId/my/favorite-project/:projectId/">
              <ProjectContainer />
            </Route>
            <Route>
              <View
                style={{
                  height: 'calc(100vh - 57px - 70px)',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
              </View>
            </Route>
          </Switch>
        </MainAreaContainer>
      </View>
    </Container>
  );
};

export default React.memo(FavoriteProject);
