import React, { useContext } from 'react';
import { GestureResponderEvent, Image, Platform, View } from 'react-native';
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';
//@ts-ignore
import { ThemeContext } from 'styled-components/native';
import onImage from '../../../../../base64Images/check/on';
import offImage from '../../../../../base64Images/check/off';
import { IThemePart } from '../../../../theme';

interface ICheckIconProps {
  size: number;
  onPress?: (event: GestureResponderEvent) => void;
  disabled?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  children?: React.ReactNode;
  on: boolean;
}

const CheckIcon = (props: ICheckIconProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  if (props.onPress) {
    return (
      <TouchableOpacity
        disabled={props.disabled}
        onPress={props.onPress}
        style={[
          {
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingVertical: 5,
          },
          props.containerStyle,
        ]}>
        <Image
          source={{ uri: props.on ? onImage : offImage }}
          resizeMode="contain"
          style={
            [
              {
                height: props.size,
                width: props.size + 2,
                opacity: props.on ? 1 : 0.3,
                shadowOffset: {
                  width: 1,
                  height: 1,
                },
                shadowOpacity: 0.1,
              },
              Platform.OS === 'web' ? { elevation: 2, cursor: 'pointer' } : {},
            ] as any
          }
        />
        {props.children}
      </TouchableOpacity>
    );
  }
  return (
    <View
      style={[
        {
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          paddingVertical: 5,
        },
        props.containerStyle,
      ]}>
      <Image
        source={{ uri: props.on ? onImage : offImage }}
        resizeMode="contain"
        style={
          [
            {
              height: props.size,
              width: props.size + 2,
              opacity: props.on ? 1 : 0.3,
              shadowOffset: {
                width: 1,
                height: 1,
              },
              shadowOpacity: 0.1,
            },
            Platform.OS === 'web' ? { elevation: 2, cursor: 'pointer' } : {},
          ] as any
        }
      />
      {props.children}
    </View>
  );
};

export default CheckIcon;
