import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { View, TouchableOpacity } from 'react-native';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import {
  AttachmentFile,
  CustomerAttributeType,
  Member,
  MemberStatus,
  Organization,
  Plan,
  Priority,
  SortOrder,
  SubTask,
  SubTaskDocument,
  Tag,
  Task,
  TaskAttachmentFilesDocument,
  TaskCommentMin,
  TaskCommentsMinDocument,
  TaskCustomAttribute,
  TaskCustomAttributeMaster,
  TaskCustomAttributesByTaskDocument,
  TaskTagsDocument,
  useAddTaskCommentMutation,
  useAddTaskCustomAttributeMutation,
  useCompleteSubTaskMutation,
  useCreateAttachementFileDownloadUrlMutation,
  useCreateAttachementFileForTaskMutation,
  useCreateAttachementFileUploadUrlMutation,
  useCreateSubTaskMutation,
  useDeleteAttachementFileMutation,
  useDeleteSubTaskMutation,
  useDeleteTaskCommentMutation,
  useDeleteTaskCustomAttributeMutation,
  useIncompleteSubTaskMutation,
  useMeQuery,
  useOrganizationMembersQuery,
  useOrganizationQuery,
  useOrganizationTagsQuery,
  useRelateTaskTagsMutation,
  useSubTasksQuery,
  useTaskAttachmentFilesQuery,
  useTaskCommentsMinQuery,
  useTaskCommentsQuery,
  useTaskCustomAttributeMastersQuery,
  useTaskCustomAttributesByTaskQuery,
  useTaskTagsQuery,
  useTeamMembersQuery,
  useTeamQuery,
  useUpdateSubTaskMutation,
  useUpdateSubTaskSortNoMutation,
  useUpdateTaskCommentMutation,
  useUpdateTaskCustomAttributeMutation,
  useUpdateTaskMutation,
} from '../../../../../../graphql/api/API';
import Avatar from '../../../../../presentational/atoms/avatar';
import EditableText from '../../../../../presentational/atoms/editable-text';
import Input, {
  //@ts-ignore
  generateEmptyRichTextEditorContent,
  ListValueMap,
} from '../../../../../presentational/atoms/input';
import Typography, { TypographyType } from '../../../../../presentational/atoms/typography';
import { IStyleTheme, IThemePart } from '../../../../../theme';
import TaskInfoElement from '../task-info-element';
import Form from '../../../../../presentational/atoms/form';
import { TaskPredictTotalWorkingTime, TaskWorkingTime } from '../../task-summary';
import moment from 'moment-timezone';
import ProgressSlider from '../../../../../presentational/atoms/progress-slider';
import { TaskProgressBar } from '../../task-progress-bar';
import { LoginUserContext } from '../../../../../../modules/auth/LoginUserContext';
import Button from '../../../../../presentational/atoms/button';
import DateUtil from '../../../../../../util/DateUtil';
import Modal from '../../../../../presentational/molecules/modal';
import VirtualizedFlatList, {
  GlobalDragContextProvider,
} from '../../../../../presentational/atoms/list2/virtualized-flat-list';
import ColorUtil from '../../../../../../util/ColorUtil';
import useHover from '../../../../../presentational/atoms/editable-text/use-hover';
import { useHotkeys } from 'react-hotkeys-hook';
import { toast } from 'react-toastify';
import FileIcon from '@crownie/file-icons';
import MenuIcon from '../../../../../presentational/molecules/image-icon/menu';
import CustomScrollView from '../../../../../presentational/atoms/custom-scroll-view';
import DownloadUtil from '../../../../../../util/DownloadUtil';
import { MentionData } from '@draft-js-plugins/mention';
import CustomAttributeIcon from '../../../../../presentational/molecules/image-icon/custom-attribute';
import Link from '../../../../../presentational/atoms/link';
import UrlUtil from '../../../../../../util/UrlUtil';
import DeleteIcon from '../../../../../presentational/molecules/image-icon/delete';
import CheckIcon from '../../../../../presentational/molecules/image-icon/check';
import PlusIcon from '../../../../../presentational/molecules/image-icon/plus';
import EditIcon from '../../../../../presentational/molecules/image-icon/edit';
import DownloadIcon from '../../../../../presentational/molecules/image-icon/download';
import ErrorMessageModal from '../../error-message-modal';
import when from '../../../../../../lang-extention/When';
import DateTimePicker from '../../../../../presentational/atoms/date-time-picker/index.web';
import * as Cookies from 'js-cookie';
import EnumUtil from '../../../../../../util/EnumUtil';
import CaretUpIcon from '../../../../../presentational/molecules/image-icon/caret-up';
import CaretDownIcon from '../../../../../presentational/molecules/image-icon/caret-down';

const Conatiner = styled.View`
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  align-items: flex-start;
  z-index: 2; //DateTimePickerを使用する際には、外側の要素のz-indexを指定しないと正しく表示されないケースがあるので、ここで指定している
`;

const Row = styled.View`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: space-between;
`;

interface ICommentRowProps extends IStyleTheme {
  changeColor: boolean;
}

const CommentRow = styled.View<ICommentRowProps>`
  display: flex;
  flex-direction: column;
  background-color: ${(props: ICommentRowProps) =>
    props.changeColor ? '#fff8c2' : props.theme.colors.baseColor};
  padding: 10px 15px;
  border-bottom-width: 1px;
  border-color: ${(props: ICommentRowProps) => props.theme.colors.separator};
  transition: all 1s;
  z-index: 3;
  width: 100%;
`;

interface IAttachmentFileItemMenuProps extends IStyleTheme {
  isHover: boolean;
}

const AttachmentFileItemMenu = styled.View<IAttachmentFileItemMenuProps>`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.4s;
  z-index: 2;
  position: absolute;
  right: 5;
  top: 5;
  opacity: ${(props: IAttachmentFileItemMenuProps) => (props.isHover ? '1' : '0')};
`;

interface IModalProps {
  comment: TaskCommentMin;
  showModal: boolean;
  onPressYes: () => Promise<void>;
  onCloseModal: () => void;
}
const ConfirmModal = (props: IModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  // useHotkeys(
  //   'Enter',
  //   () => {
  //     props.onPressYes();
  //   },
  //   {
  //     enabled: props.showModal,
  //   }
  // );

  return (
    <Modal
      title={'このコメントを削除しますか？'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
        <Button
          text={'キャンセル'}
          style={{
            minWidth: 100,
            marginRight: 10,
            marginVertical: 10,
            backgroundColor: 'transparent',
          }}
          textStyle={{ color: themeContext.colors.primary }}
          disableValidate={true}
          onPress={() => {
            props.onCloseModal();
          }}
        />
        <Button
          text={'削除する'}
          style={{
            minWidth: 100,
            marginRight: 10,
            marginVertical: 10,
            borderColor: themeContext.colors.error,
            borderRadius: 3,
            borderWidth: 1,
            backgroundColor: 'transparent',
          }}
          textStyle={{ color: themeContext.colors.error }}
          onPress={async () => {
            await props.onPressYes();
          }}
        />
      </View>
    </Modal>
  );
};

interface ITaskCommentProps {
  comment: TaskCommentMin;
  task: Task;
  organizationMembers: Member[];
  isScrollTo: boolean;
  changeColor: boolean;
  parentScrollViewRef: any;
}

const TaskCommentRow = (props: ITaskCommentProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const ref = useRef();
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [hoverRef, isHover] = useHover();
  const [changeColor, setChangeColor] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [comment, setComment] = useState(generateEmptyRichTextEditorContent());
  const [showEditor, setShowEditor] = useState(false);
  const [updateTaskComment, __] = useUpdateTaskCommentMutation({
    variables: {
      id: props.comment.id!,
      input: {
        comment: comment,
        versionNo: props.comment.versionNo,
      },
    },
  });
  const [deleteTaskComment, _] = useDeleteTaskCommentMutation({
    variables: {
      id: props.comment.id!,
      input: {
        versionNo: props.comment.versionNo,
      },
    },
    update: (cache, result) => {
      cache.evict({
        id: cache.identify(props.comment),
      });
    },
  });

  useEffect(() => {
    if (props.isScrollTo) {
      // コメントの位置へのスクロールが求められている場合には、スクロールする
      setTimeout(() => {
        if (ref.current) {
          (props.parentScrollViewRef.current as any)?.scrollTo((ref.current as any).offsetTop || 0);
        }
        if (props.changeColor) {
          setTimeout(() => {
            setChangeColor(true);
          }, 500);
        }
      }, 1000);
    }
  }, [props.isScrollTo, ref, setChangeColor]);

  useEffect(() => {
    if (changeColor) {
      setTimeout(() => {
        setChangeColor(false);
      }, 300);
    }
  }, [setChangeColor, changeColor]);

  const isContentEmpty =
    props.comment.comment === null ||
    (props.comment.comment &&
      JSON.parse(props.comment.comment).blocks &&
      JSON.parse(props.comment.comment).blocks.length > 0 &&
      JSON.parse(props.comment.comment).blocks[0]?.text === '');

  return (
    <CommentRow ref={ref} style={{ borderTopWidth: 1 }} changeColor={changeColor}>
      <View style={{ flexDirection: 'row', zIndex: 1 }}>
        <View style={{ marginRight: 10, justifyContent: 'flex-start' }}>
          <Avatar
            size={28}
            name={props.comment.createdMember.name!}
            imageUrl={props.comment.createdMember.profileImageUrl}
            containerStyle={{ marginRight: 5 }}
          />
        </View>
        <View style={{ flexDirection: 'column', flex: 1 }}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}>
            <Typography
              variant={TypographyType.Description}
              style={{ fontSize: 11, textAlign: 'left' }}>
              {props.comment.createdMember.name}
            </Typography>
            <Link
              style={{ flexDirection: 'row' }}
              path={UrlUtil.createTaskDetailUrl(props.task, `comment/${props.comment.id!}/`)}>
              <Typography
                variant={TypographyType.Description}
                style={{ fontSize: 11, flex: 1, textAlign: 'right' }}>
                {DateUtil.formatDateTime(props.comment.commentedDateTime)}
              </Typography>
            </Link>
          </View>
          <View ref={hoverRef} style={{ flexDirection: 'row' }}>
            {showEditor ? (
              // リッチテキストエディタの値をクリアする場合、その後にToolbarでの操作が効かなくなってしまう問題がある。
              // それを回避するために仕方なく、
              // リッチテキストエディタのコンポーネントは毎回作成し直すように、ここで切り替え表示をするようにしている
              <Form style={{ zIndex: 2, flex: 1 }}>
                <Input
                  type={'rich-text-editor'}
                  name={'comment'}
                  zIndexAdjustRef={ref}
                  initialValue={props.comment.comment}
                  showToolBarAlways={true}
                  multiline={true}
                  placeholder={'コメントを編集する'}
                  autoFocus={true}
                  containerStyle={{ marginVertical: 10 }}
                  inputstyle={{ borderWidth: 0 }}
                  inputContainerStyle={{
                    borderRadius: 5,
                    borderWidth: 1,
                    borderColor: themeContext.colors.separator,
                  }}
                  onChange={(value) => {
                    setComment(value);
                  }}
                  //@ts-ignore
                  mentions={props.organizationMembers!.map((member) => {
                    return {
                      id: member!.id!,
                      name: `${member!.name || '?'}`,
                      avatar: member!.profileImageUrl,
                    } as MentionData;
                  })}
                />
              </Form>
            ) : loginUser!.id === props.comment.createdMember.id ? (
              <TouchableOpacity onPress={() => setShowEditor(true)} style={{ zIndex: 2, flex: 1 }}>
                <Form>
                  <div
                    style={{
                      position: 'absolute',
                      left: 0,
                      right: 0,
                      top: 0,
                      bottom: 0,
                      backgroundColor: themeContext.colors.separator,
                      zIndex: 2,
                      opacity: isHover ? 0.5 : 0,
                      cursor: 'pointer',
                    }}
                  />
                  <View
                    style={{
                      opacity: isContentEmpty ? 0.2 : 1,
                      minHeight: 50,
                      paddingHorizontal: 10,
                    }}>
                    <Input
                      initialValue={
                        isContentEmpty
                          ? `{
                    "blocks": [
                      {
                        "key": "dummy",
                        "text": "コメントを編集...",
                        "type": "unstyled",
                        "depth": 0,
                        "inlineStyleRanges": [],
                        "entityRanges": [],
                        "data": {}
                      }
                    ],
                    "entityMap": {}
                  }
                  `
                          : props.comment.comment
                      }
                      name={'comment'}
                      type={'rich-text-editor'}
                      containerStyle={{ paddingTop: 10 }}
                      zIndexAdjustRef={ref}
                      showToolBarAlways={false}
                      multiline={true}
                      readonly={true}
                      //@ts-ignore
                      mentions={props.organizationMembers!.map((member) => {
                        return {
                          id: member!.id!,
                          name: `${member!.name || '?'}`,
                          avatar: member!.profileImageUrl,
                        } as MentionData;
                      })}
                    />
                  </View>
                </Form>
              </TouchableOpacity>
            ) : (
              <Form>
                <View
                  style={{
                    opacity: isContentEmpty ? 0.2 : 1,
                    minHeight: 50,
                    paddingHorizontal: 10,
                  }}>
                  <Input
                    initialValue={
                      isContentEmpty
                        ? `{
                "blocks": [
                  {
                    "key": "dummy",
                    "text": "コメントを編集...",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }
                ],
                "entityMap": {}
              }
              `
                        : props.comment.comment
                    }
                    name={'comment'}
                    type={'rich-text-editor'}
                    containerStyle={{ paddingTop: 10 }}
                    zIndexAdjustRef={ref}
                    showToolBarAlways={false}
                    multiline={true}
                    readonly={true}
                    //@ts-ignore
                    mentions={props.organizationMembers!.map((member) => {
                      return {
                        id: member!.id!,
                        name: `${member!.name || '?'}`,
                        avatar: member!.profileImageUrl,
                      } as MentionData;
                    })}
                  />
                </View>
              </Form>
            )}
          </View>
        </View>
      </View>
      {showEditor ? (
        <View style={{ flexDirection: 'row', marginTop: 3 }}>
          <View style={{ width: 40 }}></View>
          <Button
            text="保存"
            style={{ height: 30, marginRight: 5 }}
            textStyle={{ fontSize: 14 }}
            onPress={async () => {
              if (comment.trim().length > 0) {
                await updateTaskComment();
                setShowEditor(false);
              }
            }}
          />
          <Button
            text="キャンセル"
            style={{ height: 30, backgroundColor: 'transparent' }}
            textStyle={{ fontSize: 14, color: themeContext.colors.primary }}
            onPress={() => {
              setComment(props.comment.comment);
              setShowEditor(false);
            }}
          />
        </View>
      ) : loginUser!.id === props.comment.createdMember.id ? (
        <View style={{ flexDirection: 'row', marginTop: 3 }}>
          <View style={{ width: 40 }}></View>
          <EditIcon size={14} onPress={() => setShowEditor(true)}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontSize: 13,
                color: themeContext.colors.description,
                paddingHorizontal: 5,
                marginRight: 10,
              }}>
              編集
            </Typography>
          </EditIcon>
          <DeleteIcon
            size={15}
            containerStyle={{ marginRight: 5 }}
            onPress={() => {
              setShowModal(true);
            }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontSize: 13,
                color: themeContext.colors.description,
                paddingHorizontal: 5,
              }}>
              削除
            </Typography>
          </DeleteIcon>
        </View>
      ) : (
        <></>
      )}

      <ConfirmModal
        comment={props.comment}
        showModal={showModal}
        onPressYes={async () => {
          setShowModal(false);
          await deleteTaskComment();
        }}
        onCloseModal={() => {
          setShowModal(false);
        }}
      />
    </CommentRow>
  );
};

interface ICommentFormProps {
  me: Member;
  task: Task;
  organizationMembers: Member[];
  scrollToEnd: () => void;
}

const CommentForm = (props: ICommentFormProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const ref = useRef();
  const [hoverRef, isHover] = useHover();
  const [initialComment, setInitialComment] = useState(generateEmptyRichTextEditorContent());
  const [comment, setComment] = useState(generateEmptyRichTextEditorContent());
  const [showEditor, setShowEditor] = useState(false);
  const [addTaskComment, _] = useAddTaskCommentMutation({
    variables: {
      taskId: props.task.id!,
      input: {
        comment: comment,
        versionNo: props.task.versionNo,
      },
    },
    update: (cache, result) => {
      cache.modify({
        fields: {
          taskCommentsMin(existing = [], { storeFieldName }) {
            const newComment = cache.writeQuery({
              data: result.data?.addTaskComment!,
              query: TaskCommentsMinDocument,
            });
            return [...existing, newComment];
          },
        },
      });
    },
    onCompleted: () => {
      setComment('');
    },
  });

  useEffect(() => {
    if (showEditor) {
      props.scrollToEnd();
    }
  }, [showEditor]);

  return (
    <View ref={ref as any} style={{ flexDirection: 'row', marginVertical: 20, width: '100%' }}>
      <View style={{ justifyContent: 'flex-start', alignItems: 'center', margin: 10 }}>
        {!props.task.project.team.personalTeam && (
          <Avatar
            size={24}
            name={props.me.name!}
            imageUrl={props.me.profileImageUrl}
            containerStyle={{
              marginRight: 5,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          />
        )}
      </View>
      <View ref={hoverRef} style={{ flexDirection: 'column', flex: 10, marginRight: 10 }}>
        {showEditor ? (
          // リッチテキストエディタの値をクリアする場合、その後にToolbarでの操作が効かなくなってしまう問題がある。
          // それを回避するために仕方なく、
          // リッチテキストエディタのコンポーネントは毎回作成し直すように、ここで切り替え表示をするようにしている
          <Form style={{ zIndex: 2 }}>
            <Input
              type={'rich-text-editor'}
              name={'comment'}
              zIndexAdjustRef={ref}
              initialValue={initialComment}
              showToolBarAlways={true}
              multiline={true}
              placeholder={'コメントを追加する'}
              autoFocus={true}
              containerStyle={{ marginVertical: 10 }}
              inputstyle={{ borderWidth: 0 }}
              inputContainerStyle={{
                borderRadius: 5,
                borderWidth: 1,
                borderColor: themeContext.colors.separator,
              }}
              onChange={(value) => {
                setComment(value);
              }}
              //@ts-ignore
              mentions={props.organizationMembers!.map((member) => {
                return {
                  id: member!.id!,
                  name: `${member!.name || '?'}`,
                  avatar: member!.profileImageUrl,
                } as MentionData;
              })}
            />
          </Form>
        ) : (
          <TouchableOpacity onPress={() => setShowEditor(true)} style={{ zIndex: 2 }}>
            <Form>
              <div
                style={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  backgroundColor: themeContext.colors.separator,
                  zIndex: 2,
                  opacity: isHover ? 0.5 : 0,
                  cursor: 'pointer',
                }}
              />
              <View style={{ opacity: 0.2, minHeight: 50, paddingHorizontal: 10 }}>
                <Input
                  initialValue={`{
                  "blocks": [
                    {
                      "key": "dummy",
                      "text": "コメントを追加...",
                      "type": "unstyled",
                      "depth": 0,
                      "inlineStyleRanges": [],
                      "entityRanges": [],
                      "data": {}
                    }
                  ],
                  "entityMap": {}
                }
                `}
                  name={'description'}
                  type={'rich-text-editor'}
                  showToolBarAlways={false}
                  multiline={true}
                  readonly={true}
                  //@ts-ignore
                  mentions={props.organizationMembers!.map((member) => {
                    return {
                      id: member!.id!,
                      name: `${member!.name || '?'}`,
                      avatar: member!.profileImageUrl,
                    } as MentionData;
                  })}
                />
              </View>
            </Form>
          </TouchableOpacity>
        )}
        {showEditor && (
          <View style={{ flexDirection: 'row', marginTop: 10, zIndex: 1 }}>
            <Button
              text="保存"
              style={{ height: 30, marginRight: 5 }}
              textStyle={{ fontSize: 14 }}
              onPress={async () => {
                if (comment.trim().length > 0) {
                  await addTaskComment();
                  setInitialComment(generateEmptyRichTextEditorContent());
                  setTimeout(() => {
                    props.scrollToEnd();
                    setShowEditor(false);
                  }, 200);
                }
              }}
            />
            <Button
              text="キャンセル"
              style={{ height: 30, backgroundColor: 'transparent' }}
              textStyle={{ fontSize: 14, color: themeContext.colors.primary }}
              onPress={() => {
                setComment(generateEmptyRichTextEditorContent());
                setInitialComment(generateEmptyRichTextEditorContent());
                setTimeout(() => {
                  // 少しタイミングを遅らせないと、リッチテキストエディタのblurイベントが発生せず、
                  // 画面をリロードする際の警告表示処理が残ってしまうため。
                  setShowEditor(false);
                }, 200);
              }}
            />
          </View>
        )}
      </View>
    </View>
  );
};

interface IDeleteSubTaskModalProps {
  subTask: SubTask;
  showModal: boolean;
  onPressYes: () => Promise<void>;
  onCloseModal: () => void;
}
const DeleteSubTaskConfirmModal = (props: IDeleteSubTaskModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  useHotkeys(
    'Enter',
    () => {
      props.onPressYes();
    },
    {
      enabled: props.showModal,
    }
  );
  return (
    <Modal
      title={'このチェックリストのアイテムを削除しますか？'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <View style={{ flexDirection: 'column' }}>
        <View style={{ marginTop: 10 }}>
          <Typography variant={TypographyType.Normal} style={{ textAlign: 'center' }}>
            {props.subTask.title}
          </Typography>
        </View>
        <View style={{ marginTop: 10 }}>
          <Typography
            variant={TypographyType.Description}
            style={{ textAlign: 'center', color: themeContext.colors.error }}>
            {`この操作はやり直しが出来ません`}
          </Typography>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
          <Button
            text={'キャンセル'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.primary }}
            disableValidate={true}
            onPress={() => {
              props.onCloseModal();
            }}
          />
          <Button
            text={'削除する'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              borderColor: themeContext.colors.error,
              borderRadius: 3,
              borderWidth: 1,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.error }}
            onPress={async () => {
              await props.onPressYes();
            }}
          />
        </View>
      </View>
    </Modal>
  );
};

interface ISubTaskItemProps {
  subTask: SubTask;
  setEditing: (value: boolean) => void;
}

const SubTaskItem = (props: ISubTaskItemProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [hoverRef, isHover] = useHover();
  const [editMode, setEditMode] = useState(false);
  const [title, setTitle] = useState(props.subTask.title);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [updateSubTask, _] = useUpdateSubTaskMutation({
    variables: {
      id: props.subTask.id!,
      input: {
        title: title,
        versionNo: props.subTask.versionNo,
      },
    },
  });
  const [completeSubTask, __] = useCompleteSubTaskMutation({
    variables: {
      id: props.subTask.id!,
      input: {
        versionNo: props.subTask.versionNo,
      },
    },
  });
  const [incompleteSubTask, ___] = useIncompleteSubTaskMutation({
    variables: {
      id: props.subTask.id!,
      input: {
        versionNo: props.subTask.versionNo,
      },
    },
  });

  const [deleteSubTask, _____] = useDeleteSubTaskMutation({
    variables: {
      id: props.subTask.id!,
      input: {
        versionNo: props.subTask.versionNo,
      },
    },
    update: (cache, result) => {
      cache.evict({
        id: cache.identify(props.subTask),
      });
    },
  });

  return (
    <View
      style={{
        flexDirection: 'row',
        height: 24,
        marginVertical: 3,
        marginHorizontal: 14,
      }}>
      {editMode ? (
        <Form style={{ margin: 0, padding: 0, width: '100%' }}>
          <Input
            type={'text'}
            name={'subTaskTitle'}
            value={title}
            onChange={setTitle}
            validate={{
              required: {
                value: true,
                message: 'タイトルを入力してください',
              },
              maxLength: {
                value: 100,
                message: '100文字以内で入力してください',
              },
            }}
            focus={true}
            containerStyle={{ marginTop: 2 }}
            inputContainerStyle={{
              height: 20,
            }}
            onBlur={async (value) => {
              props.setEditing(false);
              setEditMode(false);
              if (value.length > 0) {
                await updateSubTask();
              }
            }}
          />
        </Form>
      ) : (
        <View
          style={{
            flexDirection: 'row',
            width: '100%',
          }}>
          {props.subTask.complete ? (
            <CheckIcon
              size={22}
              on={true}
              containerStyle={{ marginRight: 10 }}
              onPress={() => incompleteSubTask()}
            />
          ) : (
            <CheckIcon
              size={22}
              on={false}
              containerStyle={{ marginRight: 10 }}
              onPress={() => completeSubTask()}
            />
          )}
          <TouchableOpacity
            ref={hoverRef}
            onPress={() => {
              setEditMode(true);
              props.setEditing(true);
            }}
            style={{
              flex: 1,
              backgroundColor: isHover
                ? themeContext.colors.separator
                : themeContext.colors.baseColor,
            }}>
            <Typography
              variant={TypographyType.Normal}
              style={
                [
                  { fontSize: 13, flex: 4, width: '100%' },
                  props.subTask.complete
                    ? {
                        color: themeContext.colors.description,
                        textDecorationLine: 'line-through',
                        textDecorationColor: themeContext.colors.textColor,
                      }
                    : {},
                ] as any
              }
              ellipsis={true}>
              {props.subTask.complete ? `\u00A0${props.subTask.title}\u00A0` : props.subTask.title}
            </Typography>
          </TouchableOpacity>
          <DeleteIcon
            size={18}
            containerStyle={{ marginRight: 5 }}
            onPress={() => {
              setShowDeleteModal(true);
            }}
          />
        </View>
      )}
      <DeleteSubTaskConfirmModal
        subTask={props.subTask}
        showModal={showDeleteModal}
        onPressYes={async () => {
          setShowDeleteModal(false);
          await deleteSubTask();
        }}
        onCloseModal={() => setShowDeleteModal(false)}
      />
    </View>
  );
};

interface ISubTaskListProps {
  task: Task;
  me: Member;
  onTaskUpdatePermissionError: () => void;
}

const SubTaskList = React.memo((props: ISubTaskListProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [showNewSubTaskInput, setShowSubTaskInput] = useState(false);
  const [showNewSubTaskInputBottom, setShowSubTaskInputBottom] = useState(false);
  const [editing, setEditing] = useState(false);
  const [newSubTaskTitle, setNewSubTaskTitle] = useState('');
  const { loading: subTasksLoading, data: subTasksData } = useSubTasksQuery({
    variables: {
      taskId: props.task.id!,
    },
    fetchPolicy: 'network-only'
  });

  const [createSubTask, _] = useCreateSubTaskMutation({
    variables: {
      taskId: props.task.id!,
      input: {
        title: newSubTaskTitle,
      },
    },
    update: (cache, result) => {
      cache.modify({
        fields: {
          subTasks(existing = []) {
            const newSubTask = cache.writeQuery({
              data: result.data!.createSubTask,
              query: SubTaskDocument,
            });
            return [...existing, newSubTask];
          },
        },
      });
    },
  });
  const [updateSubTaskSortNo, ____] = useUpdateSubTaskSortNoMutation();

  const renderSubTaskItem = useCallback(
    (subTask) => {
      return <SubTaskItem subTask={subTask as SubTask} setEditing={setEditing} />;
    },
    [setEditing]
  );

  const subTasks = useMemo(() => {
    return (subTasksData?.subTasks || []).slice().sort((a, b) => {
      return b!.sortNo - a!.sortNo; // ソート番号は昇順
    });
  }, [subTasksData?.subTasks]);

  const onDrop = useCallback(
    async (info) => {
      const isMoveToFirst = info.endRowIndex === 0;
      const isMoveToLast = info.endRowIndex === subTasks.length - 1;
      const isMoveToDown = info.endRowIndex - info.startRowIndex > 0;

      let sortNo;
      if (isMoveToFirst) {
        sortNo = new Date().getTime();
      } else if (isMoveToLast) {
        sortNo = subTasks[info.endRowIndex]!.sortNo - 1000;
      } else {
        if (isMoveToDown) {
          const beforeTask = subTasks[info.endRowIndex];
          const afterTask = subTasks[info.endRowIndex + 1];
          sortNo = Math.floor((beforeTask!.sortNo + afterTask!.sortNo) / 2);
        } else {
          const beforeTask = subTasks[info.endRowIndex - 1];
          const afterTask = subTasks[info.endRowIndex];
          sortNo = Math.floor((beforeTask!.sortNo + afterTask!.sortNo) / 2);
        }
      }

      await updateSubTaskSortNo({
        variables: {
          id: (info.item! as SubTask).id!,
          input: {
            sortNo: sortNo,
            versionNo: (info.item as SubTask).versionNo,
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateSubTaskSortNo: Object.assign(
            {
              __typename: 'SubTask',
            },
            info.item as SubTask,
            { sortNo: sortNo }
          ),
        },
      });
    },
    [subTasks, updateSubTaskSortNo]
  );

  return (
    <>
      <View style={{ marginTop: 10, width: '100%' }}>
        <GlobalDragContextProvider>
          <VirtualizedFlatList
            style={{ height: 'auto' }}
            items={subTasks}
            renderItem={renderSubTaskItem}
            getKey={(subTask) => (subTask as SubTask).id!}
            itemHeight={30}
            isDraggable={!editing}
            virticalDraggable={!editing}
            onDrop={onDrop}
            afterElement={
              <>
                {showNewSubTaskInputBottom && (
                  <View
                    style={{
                      flexDirection: 'row',
                      height: 24,
                      marginVertical: 3,
                      paddingHorizontal: 20,
                    }}>
                    <Form style={{ margin: 0, padding: 0, width: '100%' }}>
                      <Input
                        type={'text'}
                        name={'subTaskTitle'}
                        value={newSubTaskTitle}
                        editable={props.me.taskUpdatePermissionFlg}
                        onChange={setNewSubTaskTitle}
                        validate={{
                          required: {
                            value: true,
                            message: 'タイトルを入力してください',
                          },
                          maxLength: {
                            value: 100,
                            message: '100文字以内で入力してください',
                          },
                        }}
                        focus={true}
                        containerStyle={{ marginTop: 2 }}
                        inputContainerStyle={{
                          height: 20,
                        }}
                        onPressEnter={async (value) => {
                          setTimeout(() => {
                            setShowSubTaskInputBottom(true);
                          }, 200);
                        }}
                        onBlur={async (value) => {
                          setNewSubTaskTitle('');
                          setShowSubTaskInputBottom(false);
                          if (value.trim().length === 0) {
                            return;
                          }
                          await createSubTask({
                            variables: {
                              taskId: props.task.id!,
                              input: {
                                title: newSubTaskTitle,
                                sortNo: 0,
                              },
                            },
                          });
                        }}
                      />
                    </Form>
                  </View>
                )}
                <PlusIcon
                  size={14}
                  containerStyle={{ marginLeft: 10 }}
                  onPress={() => {
                    if (!props.me.taskUpdatePermissionFlg) {
                      props.onTaskUpdatePermissionError();
                      return;
                    }
                    setShowSubTaskInputBottom(true);
                  }}>
                  <Typography
                    variant={TypographyType.Normal}
                    style={{ fontSize: 14, color: themeContext.colors.description }}>
                    チェックリストを追加する
                  </Typography>
                </PlusIcon>
              </>
            }
          />
        </GlobalDragContextProvider>
      </View>
    </>
  );
});

interface ITaskDescriptionProps {
  task: Task;
  organizationMembers: Member[];
  me: Member;
  onTaskUpdatePermissionError: () => void;
  descriptionRowRef: any;
}

const TaskDescription = (props: ITaskDescriptionProps) => {
  const [loginUser, _] = useContext(LoginUserContext);
  const [hoverRef, isHover, setHover] = useHover();
  const [showEditor, setShowEditor] = useState(false);
  const themeContext: IThemePart = useContext(ThemeContext);
  const [description, setDescription] = useState(props.task.description || '');
  const [updateTask, __] = useUpdateTaskMutation();

  const isContentEmpty =
    props.task.description === null ||
    (props.task.description &&
      JSON.parse(props.task.description).blocks &&
      JSON.parse(props.task.description).blocks.length > 0 &&
      JSON.parse(props.task.description).blocks[0]?.text === '');

  return (
    <TaskInfoElement title={'説明'} style={{ flex: 1 }} elementStyle={{ alignItems: 'flex-start' }}>
      <View ref={hoverRef as any}>
        {showEditor ? (
          <>
            <Form style={{ zIndex: 2 }}>
              <Input
                initialValue={props.task.description || ''}
                name={'description'}
                type={'rich-text-editor'}
                zIndexAdjustRef={props.descriptionRowRef}
                showToolBarAlways={true}
                autoFocus={true}
                //@ts-ignore
                mentions={props.organizationMembers!.map((member) => {
                  return {
                    id: member!.id!,
                    name: `${member!.name || '?'}`,
                    avatar: member!.profileImageUrl,
                  } as MentionData;
                })}
                onChange={(value) => {
                  setDescription(value);
                }}
              />
            </Form>
            <View style={{ flexDirection: 'row', marginTop: 10, zIndex: 1 }}>
              <Button
                text="保存"
                style={{ height: 30, marginRight: 5 }}
                textStyle={{ fontSize: 14 }}
                onPress={async () => {
                  if (description === props.task.description) {
                    return;
                  }
                  setDescription(description);
                  setShowEditor(false);
                  setHover(false);
                  updateTask({
                    variables: {
                      id: props.task.id!,
                      input: {
                        title: props.task.title,
                        description: description,
                        assigneeIds: props.task.assignees.map((info) => info.member!.id!),
                        estimateTimeSec: props.task.estimateTimeSec,
                        priority: props.task.priority,
                        progressRate: props.task.progressRate,
                        scheduledStartDateTime: props.task.scheduledStartDateTime,
                        scheduledEndDateTime: props.task.scheduledEndDateTime,
                        versionNo: props.task.versionNo,
                      },
                    },
                  });
                }}
              />
              <Button
                text="キャンセル"
                style={{ height: 30, backgroundColor: 'transparent' }}
                textStyle={{ fontSize: 14, color: themeContext.colors.primary }}
                onPress={() => {
                  setDescription(props.task.description || '');
                  setShowEditor(false);
                  setHover(false);
                }}
              />
            </View>
          </>
        ) : (
          <TouchableOpacity
            onPress={() => {
              if (!props.me.taskUpdatePermissionFlg) {
                props.onTaskUpdatePermissionError();
                return;
              }
              setShowEditor(true);
            }}
            style={{ zIndex: 2 }}>
            <Form>
              <div
                style={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  backgroundColor: themeContext.colors.separator,
                  zIndex: 2,
                  opacity: isHover ? 0.5 : 0,
                  cursor: 'pointer',
                }}
              />
              <View
                style={{ opacity: isContentEmpty ? 0.2 : 1, minHeight: 50, paddingHorizontal: 10 }}>
                <Input
                  initialValue={
                    isContentEmpty
                      ? `{
                  "blocks": [
                    {
                      "key": "dummy",
                      "text": "説明を追加...",
                      "type": "unstyled",
                      "depth": 0,
                      "inlineStyleRanges": [],
                      "entityRanges": [],
                      "data": {}
                    }
                  ],
                  "entityMap": {}
                }
                `
                      : props.task.description!
                  }
                  name={'description'}
                  type={'rich-text-editor'}
                  zIndexAdjustRef={props.descriptionRowRef}
                  showToolBarAlways={false}
                  multiline={true}
                  readonly={true}
                  //@ts-ignore
                  mentions={props.organizationMembers!.map((member) => {
                    return {
                      id: member!.id!,
                      name: `${member!.name || '?'}`,
                      avatar: member!.profileImageUrl,
                    } as MentionData;
                  })}
                />
              </View>
            </Form>
          </TouchableOpacity>
        )}
      </View>
    </TaskInfoElement>
  );
};

interface IConfirmAllTeamMemberAssignModalProps {
  teamMembers: Member[];
  showModal: boolean;
  onPressYes: () => Promise<void>;
  onCloseModal: () => void;
}
const ConfirmAllTeamMemberAssignModal = (props: IConfirmAllTeamMemberAssignModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);

  return (
    <Modal
      title={'チームメンバー全員を担当者に割り当てますか？'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <View style={{ flexDirection: 'column' }}>
        <GlobalDragContextProvider>
          <VirtualizedFlatList
            style={{ maxHeight: Math.min(props.teamMembers.length * 30, 400) }}
            items={props.teamMembers}
            renderItem={(item, index) => {
              const member = item as Member;
              return (
                <View style={{ marginTop: 10, flexDirection: 'row' }} key={member.id}>
                  <Avatar size={20} name={member?.name || ''} imageUrl={member.profileImageUrl} />
                  <Typography
                    variant={TypographyType.Normal}
                    style={{ marginLeft: 5, fontSize: 18 }}>
                    {member!.name}
                  </Typography>
                </View>
              );
            }}
            getKey={(member: any) => member.id}
            itemHeight={30}
          />
        </GlobalDragContextProvider>
        <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
          <Button
            text={'担当者を割り当てる'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
            }}
            textStyle={{
              backgroundColor: themeContext.colors.primary,
              color: '#FFFFFF',
              padding: 5,
            }}
            disableValidate={true}
            onPress={async () => {
              await props.onPressYes();
            }}
          />
          <Button
            text={'キャンセル'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.primary }}
            disableValidate={true}
            onPress={() => {
              props.onCloseModal();
            }}
          />
        </View>
      </View>
    </Modal>
  );
};

interface IAssigneeSettingsProps {
  task: Task;
  me: Member;
  onTaskUpdatePermissionError: () => void;
}

const AssigneeSettings = (props: IAssigneeSettingsProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, _] = useContext(LoginUserContext);
  const [assigneeMemberIds, setAssigneeMemberIds] = useState([] as string[]);
  const [showMenu, setShowMenu] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const fetchTeamMembers = useTeamMembersQuery({
    variables: {
      teamId: props.task.project.team.id!,
    },
  });
  const [updateTask, __] = useUpdateTaskMutation();

  useEffect(() => {
    setShowMenu(false);
    setShowConfirmModal(false);
  }, []);

  useEffect(() => {
    setAssigneeMemberIds(props.task.assignees.map((info) => info.member.id!));
  }, [props.task.assignees]);

  if (fetchTeamMembers.loading || fetchTeamMembers.data?.teamMembers === undefined) {
    return <></>;
  }

  return (
    <View>
      <EditableText
        value={assigneeMemberIds}
        type={'multi-picker'}
        editable={props.me.taskUpdatePermissionFlg}
        onClickWhenNotEditable={() => props.onTaskUpdatePermissionError()}
        isSearchable={true}
        disallowCreateNewPickerItem={false}
        renderComponent={() => {
          if (props.task.assignees.length === 0) {
            return <Typography variant={TypographyType.Normal}>-</Typography>;
          }
          return (
            //@ts-ignore
            <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 10 }}>
              {props.task.assignees.map((info) => {
                return (
                  <View style={{ display: 'flex', flexDirection: 'row' }} key={info.member.id}>
                    <Avatar
                      size={28}
                      name={info.member!.name!}
                      imageUrl={info.member!.profileImageUrl}
                    />
                    <Typography variant={TypographyType.Normal} style={{ marginLeft: 5 }}>
                      {info.member!.name}
                    </Typography>
                  </View>
                );
              })}
            </View>
          );
        }}
        pickerItems={
          fetchTeamMembers
            .data!.teamMembers!.filter((member) => member?.memberStatus === MemberStatus.Active)
            .map((member) => {
              return {
                label: member!.name,
                value: member!.id!,
                imageUrl: member!.profileImageUrl,
              } as ListValueMap;
            })
            .sort((a, b) => (a.value === loginUser?.id ? -1 : 1)) // 自分を先頭に表示
        }
        onChange={(items) => {
          setAssigneeMemberIds((items as string[]).filter((value) => value.trim().length > 0));
        }}
        onBlur={(items) => {
          updateTask({
            variables: {
              id: props.task.id!,
              input: {
                title: props.task.title,
                description: props.task.description,
                assigneeIds: (items as string[]) ?? [],
                estimateTimeSec: props.task.estimateTimeSec,
                priority: props.task.priority,
                progressRate: props.task.progressRate,
                scheduledStartDateTime: props.task.scheduledStartDateTime,
                scheduledEndDateTime: props.task.scheduledEndDateTime,
                versionNo: props.task.versionNo,
              },
            },
          });
        }}
        onPressEnter={() => {
          updateTask({
            variables: {
              id: props.task.id!,
              input: {
                title: props.task.title,
                description: props.task.description,
                assigneeIds: assigneeMemberIds ?? [],
                estimateTimeSec: props.task.estimateTimeSec,
                priority: props.task.priority,
                progressRate: props.task.progressRate,
                scheduledStartDateTime: props.task.scheduledStartDateTime,
                scheduledEndDateTime: props.task.scheduledEndDateTime,
                versionNo: props.task.versionNo,
              },
            },
          });
        }}
        textStyle={{ fontSize: 18 }}
      />
      <View style={{ marginTop: 15, flexDirection: 'row' }}>
        <TouchableOpacity
          onPress={() => {
            updateTask({
              variables: {
                id: props.task.id!,
                input: {
                  title: props.task.title,
                  description: props.task.description,
                  assigneeIds: assigneeMemberIds
                    .filter((id) => id !== loginUser?.id)
                    .concat(loginUser!.id),
                  estimateTimeSec: props.task.estimateTimeSec,
                  priority: props.task.priority,
                  progressRate: props.task.progressRate,
                  scheduledStartDateTime: props.task.scheduledStartDateTime,
                  scheduledEndDateTime: props.task.scheduledEndDateTime,
                  versionNo: props.task.versionNo,
                },
              },
            });
          }}>
          <Typography
            variant={TypographyType.Description}
            style={{ color: themeContext.colors.link }}>
            担当者に自分を追加する
          </Typography>
        </TouchableOpacity>
        {!showMenu ? (
          <MenuIcon size={20} iconStyle={{ marginLeft: 10 }} onPress={() => setShowMenu(true)} />
        ) : (
          <View style={{ flexDirection: 'row', gap: 20, marginLeft: 20 }}>
            <TouchableOpacity
              onPress={() => {
                updateTask({
                  variables: {
                    id: props.task.id!,
                    input: {
                      title: props.task.title,
                      description: props.task.description,
                      assigneeIds: [loginUser!.id],
                      estimateTimeSec: props.task.estimateTimeSec,
                      priority: props.task.priority,
                      progressRate: props.task.progressRate,
                      scheduledStartDateTime: props.task.scheduledStartDateTime,
                      scheduledEndDateTime: props.task.scheduledEndDateTime,
                      versionNo: props.task.versionNo,
                    },
                  },
                });
              }}>
              <Typography
                variant={TypographyType.Description}
                style={{ color: themeContext.colors.link }}>
                自分だけに割り当てる
              </Typography>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => setShowConfirmModal(true)}>
              <Typography
                variant={TypographyType.Description}
                style={{ color: themeContext.colors.link }}>
                チーム全員を追加する
              </Typography>
            </TouchableOpacity>
          </View>
        )}
      </View>
      <ConfirmAllTeamMemberAssignModal
        showModal={showConfirmModal}
        teamMembers={(fetchTeamMembers.data.teamMembers ?? []) as Member[]}
        onPressYes={async () => {
          await updateTask({
            variables: {
              id: props.task.id!,
              input: {
                title: props.task.title,
                description: props.task.description,
                assigneeIds: fetchTeamMembers.data!.teamMembers!.map((member) => member!.id!),
                estimateTimeSec: props.task.estimateTimeSec,
                priority: props.task.priority,
                progressRate: props.task.progressRate,
                scheduledStartDateTime: props.task.scheduledStartDateTime,
                scheduledEndDateTime: props.task.scheduledEndDateTime,
                versionNo: props.task.versionNo,
              },
            },
          });
          setShowConfirmModal(false);
        }}
        onCloseModal={() => setShowConfirmModal(false)}
      />
    </View>
  );
};

interface ITagSettingsProps {
  taskTags: Tag[];
  task: Task;
  organizationTags: Tag[];
  organization: Organization;
  me: Member;
  onTaskUpdatePermissionError: () => void;
}

const TagSettings = (props: ITagSettingsProps) => {
  const [tags, setTags] = useState([] as string[]);
  const [relateTaskTags] = useRelateTaskTagsMutation({
    update: (cache, result) => {
      cache.modify({
        fields: {
          taskTags(existing = []) {
            const newTags = cache.writeQuery({
              data: result.data?.relateTaskTags,
              query: TaskTagsDocument,
            });
            return newTags;
          },
        },
      });
    },
  });

  const isBasicPlan = props.organization.plan.code === Plan.Basic;

  return (
    <EditableText
      value={props.taskTags!.map((tag) => tag!.name)}
      type={'multi-picker'}
      editable={props.me.taskUpdatePermissionFlg}
      onClickWhenNotEditable={() => props.onTaskUpdatePermissionError()}
      isSearchable={true}
      noOptionsMessage={
        isBasicPlan ? '新しいタグを付与するには、アップグレードが必要です' : undefined
      }
      validate={{
        validate: (item: string) => item !== '' && item.length <= 100,
      }}
      onBlur={(items) => {
        relateTaskTags({
          variables: {
            taskId: props.task.id!,
            input: {
              tagIds: props
                .organizationTags!.filter((tag) => (items as string[]).includes(tag!.name))
                .map((tag) => tag!.id!),
              newTagNames: isBasicPlan
                ? [] //Basicプランの場合には新しいタグを登録できないようにしている
                : (items as string[]).filter(
                    (item) => !props.organizationTags!.map((tag) => tag?.name).includes(item)
                  ),
            },
          },
        });
      }}
      onChange={(items) => {
        setTags(items as string[]);
      }}
      onPressEnter={() => {
        relateTaskTags({
          variables: {
            taskId: props.task.id!,
            input: {
              tagIds: props
                .organizationTags!.filter((tag) => tags.includes(tag!.name))
                .map((tag) => tag!.id!),
              newTagNames: isBasicPlan
                ? [] //Basicプランの場合には新しいタグを登録できないようにしている
                : tags.filter(
                    (item) => !props.organizationTags!.map((tag) => tag?.name).includes(item)
                  ),
            },
          },
        });
      }}
      pickerItems={props.organizationTags!.map((tag) => ({
        value: tag!.name,
        label: tag!.name,
      }))}
    />
  );
};

interface IAttachmentFileItemProps {
  attachment: AttachmentFile;
}

const AttachmentFileItem = (props: IAttachmentFileItemProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [hoverRef, isHover] = useHover();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [createDownloadUrl, _] = useCreateAttachementFileDownloadUrlMutation({
    variables: {
      input: {
        attachmentFileId: props.attachment.id,
      },
    },
  });

  return (
    <View ref={hoverRef} style={{ flexDirection: 'row' }}>
      <TouchableOpacity
        style={{ flexDirection: 'row', cursor: 'pointer' } as any}
        onPress={async () => {
          const result = await createDownloadUrl();
          if (result.data?.createAttachementFileDownloadUrl) {
            DownloadUtil.forceDownload(
              result.data!.createAttachementFileDownloadUrl.downloadUrl,
              props.attachment.fileName
            );
          }
        }}>
        <View
          style={{
            width: 156,
            height: 125,
            backgroundColor: isHover
              ? themeContext.colors.separator
              : ColorUtil.lignten(themeContext.colors.separator, 3),
            borderColor: themeContext.colors.separator,
            marginVertical: 3,
            marginHorizontal: 5,
            justifyContent: 'center',
            alignItems: 'center',
            shadowOffset: {
              width: 1,
              height: 1,
            },
            shadowOpacity: 0.1,
            elevation: 2,
          }}>
          <View style={{ width: 30, height: 30, marginBottom: 20 }}>
            <FileIcon type={props.attachment.type || props.attachment.fileName} />
          </View>
          <View
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              width: 156,
              height: 36,
              backgroundColor: '#FFFFFF',
              opacity: 0.8,
              position: 'absolute',
              bottom: 0,
            }}>
            <Typography
              variant={TypographyType.Description}
              ellipsis={true}
              style={{
                fontSize: 12,
                width: 130,
                color: themeContext.colors.textColor,
                textAlign: 'center',
              }}>
              {props.attachment.fileName}
            </Typography>
          </View>
          <AttachmentFileItemMenu isHover={isHover}>
            <DownloadIcon
              size={20}
              containerStyle={{
                marginLeft: 5,
                padding: 5,
                backgroundColor: themeContext.colors.description,
              }}
              reverse={true}
              onPress={async () => {
                const result = await createDownloadUrl();
                if (result.data?.createAttachementFileDownloadUrl) {
                  DownloadUtil.forceDownload(
                    result.data!.createAttachementFileDownloadUrl.downloadUrl,
                    props.attachment.fileName
                  );
                }
              }}
            />
            <DeleteIcon
              size={21}
              containerStyle={{ marginLeft: 5, padding: 5 }}
              reverse={true}
              onPress={() => {
                setShowDeleteModal(true);
              }}
            />
          </AttachmentFileItemMenu>
        </View>
      </TouchableOpacity>
      <DeleteAttachmentFileModal
        attachmentFile={props.attachment}
        showModal={showDeleteModal}
        onPressYes={async () => {
          setShowDeleteModal(false);
        }}
        onCloseModal={() => {
          setShowDeleteModal(false);
        }}
      />
    </View>
  );
};

interface IDeleteAttachmentFileModalProps {
  attachmentFile: AttachmentFile;
  showModal: boolean;
  onPressYes: () => Promise<void>;
  onCloseModal: () => void;
}
const DeleteAttachmentFileModal = (props: IDeleteAttachmentFileModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [deleteAttachmentFile, _] = useDeleteAttachementFileMutation({
    variables: {
      id: props.attachmentFile.id,
    },
    update: (cache, result) => {
      cache.evict({
        id: cache.identify(props.attachmentFile),
      });
    },
  });

  return (
    <Modal
      title={'この添付ファイルを削除しますか？'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <View style={{ flexDirection: 'column' }}>
        <View style={{ marginTop: 10 }}>
          <Typography
            variant={TypographyType.Normal}
            style={{ textAlign: 'center' }}
            ellipsis={true}>
            {props.attachmentFile.fileName}
          </Typography>
        </View>
        <View style={{ marginTop: 10 }}>
          <Typography
            variant={TypographyType.Description}
            style={{ textAlign: 'center', color: themeContext.colors.error }}>
            {`この操作はやり直しが出来ません`}
          </Typography>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
          <Button
            text={'キャンセル'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.primary }}
            disableValidate={true}
            onPress={() => {
              props.onCloseModal();
            }}
          />
          <Button
            text={'削除する'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              borderColor: themeContext.colors.error,
              borderRadius: 3,
              borderWidth: 1,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.error }}
            onPress={async () => {
              await deleteAttachmentFile();
              await props.onPressYes();
            }}
          />
        </View>
      </View>
    </Modal>
  );
};

interface IDeleteCustomAttributeModalProps {
  customAttribute: TaskCustomAttribute;
  showModal: boolean;
  onPressYes: () => Promise<void>;
  onCloseModal: () => void;
}
const DeleteCustomAttributeConfirmModal = (props: IDeleteCustomAttributeModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  useHotkeys(
    'Enter',
    () => {
      props.onPressYes();
    },
    {
      enabled: props.showModal,
    }
  );

  return (
    <Modal
      title={'このカスタム項目を削除しますか？'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <View style={{ flexDirection: 'column' }}>
        <View style={{ marginTop: 10 }}>
          <Typography variant={TypographyType.Normal} style={{ textAlign: 'center' }}>
            {props.customAttribute.master.name}
          </Typography>
        </View>
        <View style={{ marginTop: 10 }}>
          <Typography
            variant={TypographyType.Description}
            style={{ textAlign: 'center', color: themeContext.colors.error }}>
            {`この操作はやり直しが出来ません`}
          </Typography>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
          <Button
            text={'キャンセル'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.primary }}
            disableValidate={true}
            onPress={() => {
              props.onCloseModal();
            }}
          />
          <Button
            text={'削除する'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              borderColor: themeContext.colors.error,
              borderRadius: 3,
              borderWidth: 1,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.error }}
            onPress={async () => {
              await props.onPressYes();
            }}
          />
        </View>
      </View>
    </Modal>
  );
};

interface ICustomAttributeRowProps {
  task: Task;
  customAttribute: TaskCustomAttribute;
  onClickWhenNotEditable: () => void;
  zIndex: number;
}

const CustomAttributeRow = (props: ICustomAttributeRowProps) => {
  const ref = useRef();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [updateCustomAttribute] = useUpdateTaskCustomAttributeMutation();
  const [deleteCustomAttribute] = useDeleteTaskCustomAttributeMutation({
    refetchQueries: [
      {
        query: TaskCustomAttributesByTaskDocument,
        variables: {
          taskId: props.task.id!,
        },
      },
    ],
  });

  useEffect(() => {
    (
      ref as any
    ).current.parentElement.parentElement.parentElement.parentElement.parentElement.style.zIndex =
      props.zIndex;
  }, [props.zIndex]);

  return (
    <View key={props.customAttribute.id} ref={ref as any} style={{ minWidth: 400 }}>
      <TaskInfoElement
        title={props.customAttribute.master.name}
        style={{ flex: 1 }}
        contentFlexDirection={'row'}
        contentContainerStyle={{ alignItems: 'center' }}
        elementStyle={{ alignItems: 'center' }}
        key={props.customAttribute.id}>
        {when(props.customAttribute.master.type) //
          .on(
            (value) => value === CustomerAttributeType.FreeText,
            () => (
              <EditableText
                value={props.customAttribute.value ?? ''}
                style={{ minWidth: 100 }}
                type={'text'}
                onClickWhenNotEditable={props.onClickWhenNotEditable}
                validate={{
                  required: {
                    value: props.customAttribute.master.required,
                    message: '値を入力してください',
                  },
                  maxLength: {
                    value: 50,
                    message: '50文字以内で入力してください',
                  },
                }}
                onChange={async (value) => {
                  await updateCustomAttribute({
                    variables: {
                      id: props.customAttribute.id!,
                      input: {
                        value: value as string,
                        sortNo: props.customAttribute.sortNo,
                      },
                    },
                  });
                }}
                textStyle={{ fontSize: 16 }}
              />
            )
          )
          .on(
            (value) => value === CustomerAttributeType.Number,
            () => (
              <EditableText
                value={props.customAttribute.value ?? ''}
                style={{ minWidth: 100 }}
                type={'text'}
                onClickWhenNotEditable={props.onClickWhenNotEditable}
                validate={{
                  required: {
                    value: props.customAttribute.master.required,
                    message: '値を入力してください',
                  },
                  pattern: {
                    value: /^[0-9]+$/,
                    message: '半角数字を入力してください。',
                  },
                }}
                onChange={async (value) => {
                  await updateCustomAttribute({
                    variables: {
                      id: props.customAttribute.id!,
                      input: {
                        value: value as string,
                        sortNo: props.customAttribute.sortNo,
                      },
                    },
                  });
                }}
                textStyle={{ fontSize: 16 }}
              />
            )
          )
          .on(
            (value) => value === CustomerAttributeType.Date,
            () => (
              <EditableText
                value={props.customAttribute.value ? moment(props.customAttribute.value) : null}
                style={{ minWidth: 100 }}
                type={'date-picker'}
                disableClear={props.customAttribute.master.required}
                onClickWhenNotEditable={props.onClickWhenNotEditable}
                validate={{
                  required: {
                    value: props.customAttribute.master.required,
                    message: '値を入力してください',
                  },
                }}
                onChange={async (value) => {
                  await updateCustomAttribute({
                    variables: {
                      id: props.customAttribute.id!,
                      input: {
                        value: value ? (value as moment.Moment).toISOString() : null,
                        sortNo: props.customAttribute.sortNo,
                      },
                    },
                  });
                }}
                textStyle={{ fontSize: 16 }}
              />
            )
          )
          .on(
            (value) => value === CustomerAttributeType.DateTime,
            () => (
              <EditableText
                value={props.customAttribute.value ? moment(props.customAttribute.value) : null}
                style={{ minWidth: 100 }}
                type={'date-time-picker'}
                disableClear={props.customAttribute.master.required}
                onClickWhenNotEditable={props.onClickWhenNotEditable}
                validate={{
                  required: {
                    value: props.customAttribute.master.required,
                    message: '値を入力してください',
                  },
                }}
                onChange={async (value) => {
                  await updateCustomAttribute({
                    variables: {
                      id: props.customAttribute.id!,
                      input: {
                        value: value ? (value as moment.Moment).toISOString() : null,
                        sortNo: props.customAttribute.sortNo,
                      },
                    },
                  });
                }}
                textStyle={{ fontSize: 16 }}
              />
            )
          )
          .on(
            (value) => value === CustomerAttributeType.List,
            () => (
              <EditableText
                value={props.customAttribute.listItem?.id ?? ''}
                type={'picker'}
                isSearchable={true}
                style={{ minWidth: 100 }}
                emptyText={'-'}
                pickerItems={
                  props.customAttribute.master!.required
                    ? props.customAttribute
                        .master!.listItem.slice()
                        .sort((a, b) => b!.sortNo - a!.sortNo)
                        .map((item) => {
                          return {
                            label: item!.value,
                            value: item!.id,
                          };
                        })
                    : [
                        {
                          label: '-',
                          value: null,
                        },
                      ].concat(
                        props.customAttribute
                          .master!.listItem.slice()
                          .sort((a, b) => b!.sortNo - a!.sortNo)
                          .map((item) => {
                            return {
                              label: item!.value,
                              value: item!.id,
                            } as ListValueMap;
                          })
                      )
                }
                onChange={async (value) => {
                  await updateCustomAttribute({
                    variables: {
                      id: props.customAttribute.id!,
                      input: {
                        taskCustomAttributeMasterListItemId:
                          !!value && (value as string).trim().length > 0 ? (value as string) : null,
                        sortNo: props.customAttribute.sortNo,
                      },
                    },
                  });
                }}
                textStyle={{ fontSize: 18 }}
              />
            )
          )
          .otherwise(() => (
            <Input name={'value'} placeholder={'-'} readonly />
          ))}
        <DeleteIcon
          size={21}
          containerStyle={{ marginLeft: 5, padding: 5 }}
          onPress={() => setShowDeleteModal(true)}
        />
      </TaskInfoElement>
      <DeleteCustomAttributeConfirmModal
        customAttribute={props.customAttribute}
        showModal={showDeleteModal}
        onPressYes={async () => {
          setShowDeleteModal(false);
          await deleteCustomAttribute({
            variables: {
              id: props.customAttribute.id!,
            },
          });
        }}
        onCloseModal={() => setShowDeleteModal(false)}
      />
    </View>
  );
};

interface IAddCustomAttributeModalProps {
  task: Task;
  alreadyExistProjectCustomAttributes: TaskCustomAttribute[];
  showModal: boolean;
  onPressYes: () => Promise<void>;
  onCloseModal: () => void;
}

const AddCustomAttributeModal = (props: IAddCustomAttributeModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [attribute, setAttribute] = useState<TaskCustomAttributeMaster | null>(null);
  const [value, setValue] = useState<string | null>(null);
  const { data, loading } = useTaskCustomAttributeMastersQuery({
    fetchPolicy: 'network-only',
  });
  const [addCustomAttribute] = useAddTaskCustomAttributeMutation({
    variables: {
      taskId: props.task.id!,
      input: {
        taskCustomAttributeMasterId: attribute?.id!,
        taskCustomAttributeMasterListItemId:
          attribute?.type === CustomerAttributeType.List ? value : null,
        value: attribute?.type === CustomerAttributeType.List ? null : value!,
      },
    },
    refetchQueries: [
      {
        query: TaskCustomAttributesByTaskDocument,
        variables: {
          taskId: props.task.id!,
        },
      },
    ],
  });

  useEffect(() => {
    setAttribute(null);
    setValue(null);
  }, [props.showModal]);

  const attributes = data?.taskCustomAttributeMasters?.filter(
    (master) =>
      !props.alreadyExistProjectCustomAttributes.find((exist) => exist.master.id === master!.id)
  );

  if (loading) {
    return <></>;
  }

  return (
    <Modal
      title={'カスタム項目を追加する'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <View style={{ flexDirection: 'column' }}>
        <Form style={{ minWidth: 400, zIndex: 2 }}>
          <Row>
            <TaskInfoElement title={`カスタム項目`} style={{ flex: 1 }}>
              <Input
                name={'attribute'}
                type={'picker'}
                isSearchable={true}
                placeholder={'選択してください'}
                pickerItems={(attributes ?? []).map((master) => {
                  return {
                    label: master!.name,
                    value: master!.id!,
                  };
                })}
                onChange={(value) => {
                  const targets = (attributes || []).filter((master) => master!.id! === value);
                  if (targets && targets.length > 0) {
                    setAttribute(targets[0] ?? null);
                  }
                }}
                validate={{
                  required: {
                    value: true,
                    message: '選択肢を入力してください',
                  },
                }}
              />
            </TaskInfoElement>
          </Row>
          <Row style={{ zIndex: 2 }}>
            <TaskInfoElement title={`値`} style={{ flex: 1 }}>
              {when(attribute?.type) //
                .on(
                  (value) => value === CustomerAttributeType.FreeText,
                  () => (
                    <Input
                      name={'textValue'}
                      placeholder={'値を入力してください'}
                      onChange={(value) => setValue(value)}
                      validate={{
                        required: {
                          value: attribute?.required,
                          message: '値を入力してください',
                        },
                        maxLength: {
                          value: 50,
                          message: '50文字以内で入力してください',
                        },
                      }}
                    />
                  )
                )
                .on(
                  (value) => value === CustomerAttributeType.Number,
                  () => (
                    <Input
                      name={'numberValue'}
                      placeholder={'値を入力してください'}
                      onChange={(value) => setValue(value)}
                      validate={{
                        required: {
                          value: attribute?.required,
                          message: '値を入力してください',
                        },
                        pattern: {
                          value: /^[0-9]+$/,
                          message: '半角数字を入力してください。',
                        },
                      }}
                    />
                  )
                )
                .on(
                  (value) => value === CustomerAttributeType.Date,
                  () => (
                    <DateTimePicker
                      mode={'date'}
                      name={'dateValue'}
                      onChange={(value) => {
                        if (value) {
                          setValue(value!.toISOString());
                        } else {
                          setValue(null);
                        }
                      }}
                      onBlur={(value, element) => {
                        if (value) {
                          setValue(value!.toISOString());
                        } else {
                          setValue(null);
                        }
                      }}
                      onPressEnter={(value) => {
                        if (value) {
                          setValue(value!.toISOString());
                        } else {
                          setValue(null);
                        }
                      }}
                      validate={{
                        required: {
                          value: attribute!.required,
                          message: '値を入力してください',
                        },
                      }}
                    />
                  )
                )
                .on(
                  (value) => value === CustomerAttributeType.DateTime,
                  () => (
                    <DateTimePicker
                      mode={'datetime'}
                      name={'datetimeValue'}
                      onChange={(value) => {
                        if (value) {
                          setValue(value!.toISOString());
                        } else {
                          setValue(null);
                        }
                      }}
                      onBlur={(value, element) => {
                        if (value) {
                          setValue(value!.toISOString());
                        } else {
                          setValue(null);
                        }
                      }}
                      onPressEnter={(value) => {
                        if (value) {
                          setValue(value!.toISOString());
                        } else {
                          setValue(null);
                        }
                      }}
                      validate={{
                        required: {
                          value: attribute!.required,
                          message: '値を入力してください',
                        },
                      }}
                    />
                  )
                )
                .on(
                  (value) => value === CustomerAttributeType.List,
                  () => (
                    <Input
                      name="attr"
                      value={''}
                      type={'picker'}
                      inputContainerStyle={{ minWidth: 400 }}
                      placeholder={'選択してください'}
                      pickerItems={
                        attribute!.required
                          ? attribute!.listItem
                              .slice()
                              .sort((a, b) => b!.sortNo - a!.sortNo)
                              .map((item) => {
                                return {
                                  label: item!.value,
                                  value: item!.id,
                                };
                              })
                          : [
                              {
                                label: '-',
                                value: null,
                              },
                            ].concat(
                              attribute!.listItem
                                .slice()
                                .sort((a, b) => b!.sortNo - a!.sortNo)
                                .map((item) => {
                                  return {
                                    label: item!.value,
                                    value: item!.id,
                                  } as ListValueMap;
                                })
                            )
                      }
                      onChange={(value) => setValue((value as string) || null)}
                      validate={{
                        required: {
                          value: attribute!.required,
                          message: '選択肢を入力してください',
                        },
                      }}
                    />
                  )
                )
                .otherwise(() => (
                  <Input name={'value'} placeholder={'-'} readonly />
                ))}
            </TaskInfoElement>
          </Row>
          <View
            style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
            <Button
              text={'登録する'}
              style={{
                flex: 1,
                width: '100%',
                minHeight: 50,
                marginVertical: 10,
              }}
              textStyle={{
                backgroundColor: themeContext.colors.primary,
                color: '#FFFFFF',
                padding: 5,
              }}
              onPress={async () => {
                await addCustomAttribute();
                await props.onPressYes();
              }}
            />
            <Button
              text={'キャンセル'}
              style={{
                flex: 1,
                width: '100%',
                minHeight: 50,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.primary }}
              disableValidate={true}
              onPress={() => {
                props.onCloseModal();
              }}
            />
          </View>
        </Form>
      </View>
    </Modal>
  );
};

interface ITaskDetailStandardInfoProps {
  organizationId: string;
  task: Task;
  commentId: string | null;
  changeColor: boolean | null;
  parentScrollViewRef: any;
}

const TaskDetailStandardInfo = (props: ITaskDetailStandardInfoProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const attachmentFileInputRef = useRef();
  const pageSize = 20;
  const [loginUser, _] = useContext(LoginUserContext);
  const [commentSortOrder, setCommentSortOrder] = useState(
    EnumUtil.getEnumValue(SortOrder, Cookies.get(`TASK_COMMENT_SORT_ORDER`)) ?? SortOrder.Desc
  );
  const [showMoreComment, setShowMoreComment] = useState(false);
  const [showUpdateTaskErrorModal, setShowUpdateTaskErrorModal] = useState(false);
  const [showAddCustomAttributeModal, setShowAddCustomAttributeModal] = useState(false);
  const descriptionRowRef = useRef();
  const fetchTeamResult = useTeamQuery({
    variables: {
      id: props.task.project.team.id!,
    },
  });

  const [updateTask, __] = useUpdateTaskMutation();
  const fetchTaskComments = useTaskCommentsMinQuery({ //TODO TDV1-112
    variables: {
      taskId: props.task.id!,
      limit: pageSize,
      offset: 0,
      sortOrder: commentSortOrder
    },
    fetchPolicy: 'network-only'
  });
  const { loading: attachmentsLoading, data: attachmentsData } = useTaskAttachmentFilesQuery({
    variables: {
      taskId: props.task.id!,
    },
  });

  const { loading: meLoading, data: meData } = useMeQuery();

  const { loading: taskTagsLoading, data: taskTagsData } = useTaskTagsQuery({
    variables: {
      taskId: props.task.id!,
    },
    fetchPolicy: 'network-only',
  });
  const { loading: organizationTagsLoading, data: organizationTagsData } = useOrganizationTagsQuery(
    {
      variables: {
        organizationId: props.organizationId,
      },
      fetchPolicy: 'network-only',
    }
  );
  const { loading: organizationMembersLoading, data: organizationMembersData } =
    useOrganizationMembersQuery({
      variables: {
        organizationId: loginUser!.organizationId!,
      },
    });

  const { loading: organizationLoading, data: organizationData } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId!,
    },
  });

  const { loading: customAttributeLoading, data: customAttributeData } =
    useTaskCustomAttributesByTaskQuery({
      variables: {
        taskId: props.task.id!,
      },
      fetchPolicy: 'network-only',
    });

  const [createAttachmentFileUploadUrl, ____] = useCreateAttachementFileUploadUrlMutation();
  const [createAttachemntFile, ___] = useCreateAttachementFileForTaskMutation();

  const [updateTaskCustomAttribute] = useUpdateTaskCustomAttributeMutation();

  const scrollToTop = () => {
    (props.parentScrollViewRef.current as any)?.scrollTo(0);
  };

  const scrollToEnd = () => {
    (props.parentScrollViewRef.current as any)?.scrollToEnd();
  };

  useEffect(() => {
    if (!props.commentId) {
      scrollToTop();
    }
  }, [props.commentId, props.parentScrollViewRef]);

  useEffect(() => {
    Cookies.set(`COMMENT_SORT_ORDER`, commentSortOrder ?? SortOrder.Desc);
  }, [commentSortOrder]);

  useEffect(() => {
    if((fetchTaskComments.data?.taskCommentsMin?.length ?? 0) < pageSize){
      setShowMoreComment(false)
    } else {
      setShowMoreComment(true)
    }
  }, [fetchTaskComments.data?.taskCommentsMin])

  if (
    fetchTeamResult.loading ||
    !fetchTeamResult.data?.team ||
    meLoading ||
    !meData?.me ||
    taskTagsLoading ||
    organizationTagsLoading ||
    attachmentsLoading ||
    organizationMembersLoading ||
    organizationLoading ||
    customAttributeLoading
  ) {
    return <></>;
  }
  return (
    <>
      <Conatiner>
        {(fetchTeamResult.data!.team!.organization.plan.code === Plan.Business ||
          fetchTeamResult.data!.team!.organization.plan.code === Plan.Enterprise) && (
          <Row>
            <TaskInfoElement title={'担当者'} style={{ flex: 1 }}>
              <AssigneeSettings
                task={props.task}
                me={meData.me}
                onTaskUpdatePermissionError={() => setShowUpdateTaskErrorModal(true)}
              />
            </TaskInfoElement>
          </Row>
        )}
        <Row>
          <TaskInfoElement title={'優先度'} style={{ flex: 1, maxWidth: 400 }}>
            <EditableText
              value={props.task.priority?.toString() || ''}
              type={'picker'}
              editable={meData.me?.projectUpdatePermissionFlg}
              onClickWhenNotEditable={() => setShowUpdateTaskErrorModal(true)}
              emptyText={'-'}
              pickerItems={[
                {
                  label: 'なし',
                  value: null,
                },
                {
                  label: '高',
                  value: Priority.High,
                },
                {
                  label: '中',
                  value: Priority.Normal,
                },
                {
                  label: '低',
                  value: Priority.Low,
                },
              ]}
              onChange={(value) => {
                updateTask({
                  variables: {
                    id: props.task.id!,
                    input: {
                      title: props.task.title,
                      description: props.task.description,
                      assigneeIds: props.task.assignees.map((info) => info.member!.id!),
                      estimateTimeSec: props.task.estimateTimeSec,
                      priority: (value as Priority) || null,
                      progressRate: props.task.progressRate,
                      scheduledStartDateTime: props.task.scheduledStartDateTime,
                      scheduledEndDateTime: props.task.scheduledEndDateTime,
                      versionNo: props.task.versionNo,
                    },
                  },
                });
              }}
              textStyle={{ fontSize: 18 }}
            />
          </TaskInfoElement>
        </Row>
        <Row>
          <TaskInfoElement title={'タグ'} style={{ flex: 1 }}>
            <TagSettings
              organizationTags={organizationTagsData!.organizationTags as Tag[]}
              task={props.task}
              taskTags={taskTagsData?.taskTags as Tag[]}
              organization={organizationData!.organization!}
              me={meData.me}
              onTaskUpdatePermissionError={() => setShowUpdateTaskErrorModal(true)}
            />
          </TaskInfoElement>
        </Row>
        <Row style={{ zIndex: 4, justifyContent: 'flex-start' }}>
          <TaskInfoElement title={'開始予定日'} style={{ flex: 1, maxWidth: 300 }}>
            <EditableText
              value={
                props.task.scheduledStartDateTime ? moment(props.task.scheduledStartDateTime) : null
              }
              type={'date-time-picker'}
              editable={meData.me.taskUpdatePermissionFlg}
              onClickWhenNotEditable={() => setShowUpdateTaskErrorModal(true)}
              selectableEndDate={
                props.task.scheduledEndDateTime ? moment(props.task.scheduledEndDateTime) : null
              }
              onChange={(value) => {
                updateTask({
                  variables: {
                    id: props.task.id!,
                    input: {
                      title: props.task.title,
                      description: props.task.description,
                      assigneeIds: props.task.assignees.map((info) => info.member!.id!),
                      estimateTimeSec: props.task.estimateTimeSec,
                      priority: props.task.priority,
                      progressRate: props.task.progressRate,
                      scheduledStartDateTime: value ? (value as moment.Moment).toISOString() : null,
                      scheduledEndDateTime: props.task.scheduledEndDateTime,
                      versionNo: props.task.versionNo,
                    },
                  },
                });
              }}
            />
          </TaskInfoElement>
          <TaskInfoElement title={'〆切日'} style={{ flex: 1, maxWidth: 300 }}>
            <EditableText
              value={
                props.task.scheduledEndDateTime ? moment(props.task.scheduledEndDateTime) : null
              }
              type={'date-time-picker'}
              editable={meData.me.taskUpdatePermissionFlg}
              onClickWhenNotEditable={() => setShowUpdateTaskErrorModal(true)}
              onChange={(value) => {
                updateTask({
                  variables: {
                    id: props.task.id!,
                    input: {
                      title: props.task.title,
                      description: props.task.description,
                      assigneeIds: props.task.assignees.map((info) => info.member!.id!),
                      estimateTimeSec: props.task.estimateTimeSec,
                      priority: props.task.priority,
                      progressRate: props.task.progressRate,
                      scheduledStartDateTime: props.task.scheduledStartDateTime,
                      scheduledEndDateTime: value ? (value as moment.Moment).toISOString() : null,
                      versionNo: props.task.versionNo,
                    },
                  },
                });
              }}
            />
          </TaskInfoElement>
        </Row>
        <Row style={{ zIndex: 3, justifyContent: 'flex-start' }}>
          <TaskInfoElement title={'作業時間'} style={{ flex: 1, maxWidth: 300 }}>
            <TaskWorkingTime task={props.task} />
          </TaskInfoElement>
          <TaskInfoElement
            title={'見積時間'}
            style={{ flex: 1, maxWidth: 300 }}
            contentFlexDirection={'row'}>
            <EditableText
              value={props.task.estimateTimeSec || null}
              type={'time-picker'}
              editable={meData.me.taskUpdatePermissionFlg}
              onClickWhenNotEditable={() => setShowUpdateTaskErrorModal(true)}
              style={{ minWidth: 50 }}
              onChange={(value) => {
                updateTask({
                  variables: {
                    id: props.task.id!,
                    input: {
                      title: props.task.title,
                      description: props.task.description,
                      assigneeIds: props.task.assignees.map((info) => info.member!.id!),
                      estimateTimeSec: (value as number) || null,
                      priority: props.task.priority,
                      progressRate: props.task.progressRate,
                      scheduledStartDateTime: props.task.scheduledStartDateTime,
                      scheduledEndDateTime: props.task.scheduledEndDateTime,
                      versionNo: props.task.versionNo,
                    },
                  },
                });
              }}
            />
          </TaskInfoElement>
        </Row>
        <Row style={{ zIndex: 2, justifyContent: 'flex-start' }}>
          <TaskInfoElement
            title={''}
            style={{ flex: 1, maxWidth: 300 }}
            contentFlexDirection={'row'}></TaskInfoElement>
          <TaskInfoElement
            title={'予想見積時間'}
            style={{ flex: 1, maxWidth: 300 }}
            contentFlexDirection={'row'}>
            <TaskPredictTotalWorkingTime task={props.task} />
          </TaskInfoElement>
        </Row>
        <Row style={{ maxWidth: 600 }}>
          <TaskInfoElement title={'実際の進捗率'} style={{ flex: 1, width: '100%' }}>
            <ProgressSlider
              value={props.task.progressRate || 0}
              readonly={!meData.me.taskUpdatePermissionFlg}
              onChange={(value) => {
                updateTask({
                  variables: {
                    id: props.task.id!,
                    input: {
                      title: props.task.title,
                      description: props.task.description,
                      assigneeIds: props.task.assignees.map((info) => info.member!.id!),
                      estimateTimeSec: props.task.estimateTimeSec,
                      priority: props.task.priority,
                      progressRate: Number(value || 0),
                      scheduledStartDateTime: props.task.scheduledStartDateTime,
                      scheduledEndDateTime: props.task.scheduledEndDateTime,
                      versionNo: props.task.versionNo,
                    },
                  },
                });
              }}
            />
          </TaskInfoElement>
        </Row>
        {!!props.task.estimateTimeSec && (
          <Row style={{ maxWidth: 600 }}>
            <TaskInfoElement title={'理想の進捗率'} style={{ flex: 1, width: '100%' }}>
              <TaskProgressBar
                task={props.task}
                showProgress={false}
                showProgressText={true}
                showAdvice={true}
              />
            </TaskInfoElement>
          </Row>
        )}
        <Row>
          <TaskInfoElement title={` `} contentContainerStyle={{ alignItems: 'flex-start' }}>
            <CustomAttributeIcon
              size={14}
              onPress={() => {
                if (!meData.me?.taskUpdatePermissionFlg) {
                  setShowUpdateTaskErrorModal(true);
                  return;
                }
                setShowAddCustomAttributeModal(true);
              }}>
              <Typography
                variant={TypographyType.Description}
                style={{ marginLeft: 10, color: themeContext.colors.description }}>
                カスタム項目を追加
              </Typography>
            </CustomAttributeIcon>
          </TaskInfoElement>
        </Row>
        <GlobalDragContextProvider>
          <VirtualizedFlatList
            style={{ height: 'auto', zIndex: 1 }}
            additionalListStyle={{ overflow: 'visible' }}
            items={(customAttributeData?.taskCustomAttributesByTask ?? [])
              .slice()
              .sort((a, b) => a!.sortNo - b!.sortNo)}
            renderItem={(customAttribute, i) => {
              return (
                <Row
                  style={{
                    cursor: 'grab',
                  }}>
                  <CustomAttributeRow
                    task={props.task}
                    customAttribute={customAttribute as TaskCustomAttribute}
                    onClickWhenNotEditable={() => setShowUpdateTaskErrorModal(true)}
                    zIndex={customAttributeData!.taskCustomAttributesByTask!.length + 10 - i}
                    key={(customAttribute as TaskCustomAttribute).id}
                  />
                </Row>
              );
            }}
            getKey={(item) => (item as any).id}
            itemHeight={50}
            virticalDraggable={true}
            onDrop={async (info) => {
              const sortedItems = (customAttributeData?.taskCustomAttributesByTask ?? [])
                .slice()
                .sort((a, b) => a!.sortNo - b!.sortNo);

              const isMoveToFirst = info.endRowIndex === 0;
              const isMoveToLast = info.endRowIndex === sortedItems.length - 1;
              const isMoveToDown = info.endRowIndex - info.startRowIndex > 0;
              let sortNo;
              if (isMoveToLast) {
                sortNo = new Date().getTime();
              } else if (isMoveToFirst) {
                sortNo = sortedItems[info.endRowIndex]!.sortNo - 1000;
              } else {
                if (isMoveToDown) {
                  const beforeTask = sortedItems[info.endRowIndex];
                  const afterTask = sortedItems[info.endRowIndex + 1];
                  sortNo = Math.floor((beforeTask!.sortNo + afterTask!.sortNo) / 2);
                } else {
                  const beforeTask = sortedItems[info.endRowIndex - 1];
                  const afterTask = sortedItems[info.endRowIndex];
                  sortNo = Math.floor((beforeTask!.sortNo + afterTask!.sortNo) / 2);
                }
              }
              await updateTaskCustomAttribute({
                variables: {
                  id: (info.item as TaskCustomAttribute).id!,
                  input: {
                    sortNo: sortNo,
                    value: (info.item as TaskCustomAttribute).value,
                    taskCustomAttributeMasterListItemId: (info.item as TaskCustomAttribute).listItem
                      ?.id,
                  },
                },
              });
            }}
          />
        </GlobalDragContextProvider>
        <Row ref={descriptionRowRef}>
          <TaskDescription
            task={props.task}
            organizationMembers={organizationMembersData!.organizationMembers as Member[]}
            descriptionRowRef={descriptionRowRef}
            me={meData.me}
            onTaskUpdatePermissionError={() => setShowUpdateTaskErrorModal(true)}
          />
        </Row>
        <SubTaskList
          task={props.task}
          me={meData.me}
          onTaskUpdatePermissionError={() => setShowUpdateTaskErrorModal(true)}
        />
        <Row>
          <View style={{ flexDirection: 'column', paddingHorizontal: 20 }}>
            {attachmentsData!.taskAttachmentFiles!.length > 0 && (
              <View
                style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 20 }}>
                <Typography
                  variant={TypographyType.Description}
                  style={{
                    fontSize: 12,
                    fontWeight: '600',
                  }}>
                  {`添付ファイル(${attachmentsData!.taskAttachmentFiles!.length}件)`}
                </Typography>

                <PlusIcon size={20} containerStyle={{ marginRight: 8 }}>
                  <input
                    type="file"
                    ref={attachmentFileInputRef as any}
                    style={{ opacity: 0, cursor: 'pointer', position: 'absolute' }}
                    onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                      let isSuccess = true;
                      await Promise.all(
                        Array.from(e.target.files!).map(async (file) => {
                          const uploadUrlResult = await createAttachmentFileUploadUrl({
                            variables: {
                              input: {
                                fileName: file.name,
                                sizeByte: file.size,
                                type: file.type,
                              },
                            },
                          });

                          await fetch(
                            uploadUrlResult.data!.createAttachementFileUploadUrl!.uploadUrl,
                            {
                              method: 'PUT',
                              body: file,
                            }
                          ).then((response) => {
                            if (!response.ok) {
                              isSuccess = false;
                            }
                          });

                          const attachmentFileResult = await createAttachemntFile({
                            variables: {
                              input: {
                                taskId: props.task.id!,
                                fileName:
                                  uploadUrlResult.data!.createAttachementFileUploadUrl!.fileName,
                                sizeByte:
                                  uploadUrlResult.data!.createAttachementFileUploadUrl!.sizeByte,
                                type: uploadUrlResult.data!.createAttachementFileUploadUrl!.type,
                                key: uploadUrlResult.data!.createAttachementFileUploadUrl!.key,
                              },
                            },
                            refetchQueries: [
                              {
                                query: TaskAttachmentFilesDocument,
                                variables: {
                                  taskId: props.task.id,
                                },
                              },
                            ],
                          });
                          if (attachmentFileResult.errors) {
                            isSuccess = false;
                          }
                        })
                      );

                      if (isSuccess) {
                        toast('ファイルアップロードが完了しました', {
                          type: 'success',
                          position: 'bottom-right',
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        });
                      } else {
                        toast('ファイルアップロードに失敗しました', {
                          type: 'error',
                          position: 'bottom-right',
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        });
                      }
                    }}
                  />
                </PlusIcon>
              </View>
            )}
            <CustomScrollView horizontal={true} style={{ width: 480 }}>
              {attachmentsData!.taskAttachmentFiles!.map((attachment) => {
                return <AttachmentFileItem attachment={attachment!} />;
              })}
            </CustomScrollView>
          </View>
        </Row>
        <View style={{ alignItems: 'flex-end', width: '100%' }}>
          <TouchableOpacity
            style={{ flexDirection: 'row' }}
            onPress={() => {
              if (commentSortOrder === SortOrder.Asc) {
                setCommentSortOrder(SortOrder.Desc);
              } else {
                setCommentSortOrder(SortOrder.Asc);
              }
            }}>
            {when(commentSortOrder) //
              .on(
                (v) => v === SortOrder.Asc,
                () => (
                  <>
                    <CaretUpIcon
                      size={10}
                      containerStyle={{ marginLeft: 3, width: 10, justifyContent: 'flex-end' }}
                    />
                    <Typography
                      variant={TypographyType.Normal}
                      style={{
                        fontSize: 12,
                        color: themeContext.colors.description,
                        textAlign: 'left',
                        paddingHorizontal: 5,
                      }}>
                      古い順
                    </Typography>
                  </>
                )
              )
              .otherwise(() => (
                <>
                  <CaretDownIcon
                    size={10}
                    containerStyle={{ marginLeft: 3, width: 10, justifyContent: 'flex-end' }}
                  />
                  <Typography
                    variant={TypographyType.Normal}
                    style={{
                      fontSize: 12,
                      color: themeContext.colors.description,
                      textAlign: 'left',
                      paddingHorizontal: 5,
                    }}>
                    新しい順
                  </Typography>
                </>
              ))}
          </TouchableOpacity>
        </View>
        {commentSortOrder == SortOrder.Asc ? (
          <>
            {(fetchTaskComments
              .data?.taskCommentsMin ?? []).slice()
              .map((comment, i) => {
                return (
                  <TaskCommentRow
                    comment={comment as TaskCommentMin}
                    task={props.task}
                    isScrollTo={props.commentId === comment!.id!}
                    changeColor={props.changeColor || false}
                    organizationMembers={organizationMembersData!.organizationMembers as Member[]}
                    parentScrollViewRef={props.parentScrollViewRef}
                    key={i}
                  />
                );
              })}
            {showMoreComment && (
              <View
                style={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: 30,
                  flex: 1,
                  width: '100%',
                  marginTop: 10
                }}>
                  <TouchableOpacity disabled={fetchTaskComments.loading} onPress={() => {
                    if ((fetchTaskComments?.data?.taskCommentsMin?.length ?? 0) < pageSize) {
                      return;
                    }
                    fetchTaskComments.fetchMore({
                      variables: {
                        offset: fetchTaskComments?.data?.taskCommentsMin!.length,
                      },
                      updateQuery: (prev, { fetchMoreResult }) => {
                        if((fetchMoreResult?.taskCommentsMin?.length) ?? 0 === 0){
                          setShowMoreComment(false);
                        }

                        if (!fetchMoreResult) return prev;
                        return Object.assign({}, prev, {
                          taskCommentsMin: [
                            ...(prev.taskCommentsMin || []),
                            ...(fetchMoreResult?.taskCommentsMin || []),
                          ],
                        });
                      },
                    });
                  }}>
                    <Typography
                      variant={TypographyType.Normal}
                      style={{
                        fontSize: 12,
                        color: themeContext.colors.link,
                        textAlign: 'center',
                        paddingHorizontal: 5,
                      }}>
                      {`コメントをさらに表示する`}
                    </Typography>
                  </TouchableOpacity>
              </View>
            )}
            <CommentForm
              me={meData!.me!}
              task={props.task}
              organizationMembers={organizationMembersData!.organizationMembers as Member[]}
              scrollToEnd={scrollToEnd}
            />
          </>
        ) : (
          <>
            <CommentForm
              me={meData!.me}
              task={props.task}
              organizationMembers={organizationMembersData!.organizationMembers as Member[]}
              scrollToEnd={scrollToEnd}
            />
            {(fetchTaskComments
              .data?.taskCommentsMin ?? []).slice()
              .map((comment, i) => {
                return (
                  <TaskCommentRow
                    comment={comment as TaskCommentMin}
                    task={props.task}
                    isScrollTo={props.commentId === comment!.id!}
                    changeColor={props.changeColor || false}
                    organizationMembers={organizationMembersData!.organizationMembers as Member[]}
                    parentScrollViewRef={props.parentScrollViewRef}
                    key={i}
                  />
                );
              })}
              {showMoreComment && (
                <View
                style={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: 30,
                  flex: 1,
                  width: '100%',
                  marginTop: 10
                }}>
                  <TouchableOpacity disabled={fetchTaskComments.loading} onPress={() => {
                    if ((fetchTaskComments?.data?.taskCommentsMin?.length ?? 0) < pageSize) {
                      return;
                    }
                    fetchTaskComments.fetchMore({
                      variables: {
                        offset: fetchTaskComments.data!.taskCommentsMin!.length,
                      },
                      updateQuery: (prev, { fetchMoreResult }) => {
                        if((fetchMoreResult?.taskCommentsMin?.length) ?? 0 === 0){
                          setShowMoreComment(false);
                        }

                        if (!fetchMoreResult) return prev;
                        return Object.assign({}, prev, {
                          taskCommentsMin: [
                            ...(prev.taskCommentsMin || []),
                            ...(fetchMoreResult?.taskCommentsMin || []),
                          ],
                        });
                      },
                    });
                  }}>
                    <Typography
                      variant={TypographyType.Normal}
                      style={{
                        fontSize: 12,
                        color: themeContext.colors.link,
                        textAlign: 'center',
                        paddingHorizontal: 5,
                      }}>
                      {`コメントをさらに表示する`}
                    </Typography>
                  </TouchableOpacity>
                </View>
              )}
            </>
        )}
      </Conatiner>
      <ErrorMessageModal
        showModal={showUpdateTaskErrorModal}
        title={'タスクを編集できません'}
        message={`タスクを編集する権限がありません${'\n'}権限が必要な場合、管理権限を持っているメンバーに問い合わせてください`}
        onCloseModal={() => setShowUpdateTaskErrorModal(false)}
      />
      <AddCustomAttributeModal
        task={props.task}
        alreadyExistProjectCustomAttributes={
          (customAttributeData?.taskCustomAttributesByTask as TaskCustomAttribute[]) ?? []
        }
        showModal={showAddCustomAttributeModal}
        onCloseModal={() => setShowAddCustomAttributeModal(false)}
        onPressYes={async () => setShowAddCustomAttributeModal(false)}
      />
    </>
  );
};

export default TaskDetailStandardInfo;
