import React, { useContext, useEffect, useState } from 'react';
import styled, { ThemeContext } from 'styled-components/native';
import { TextStyle, ViewStyle, StyleProp, View } from 'react-native';
import { IStyleTheme, IThemePart } from '../../../theme';
import ToggleButton from '../../atoms/toggle-button';

export interface RadioButtonData<DATA> {
  key: string;
  content: DATA;
}

interface IProps {
  contents: RadioButtonData<string | React.ReactElement<any, any>>[];
  multiSelect?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  values?: string[];
  onChange?: (selectedKey: string[]) => void;
}

const SelectButton = (props: IProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [selectedKeys, setSelectedKeys] = useState<string[]>(props.values || []);

  useEffect(() => {
    setSelectedKeys(props.values || []);
  }, [props.values]);

  return (
    <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
      {props.contents.map((content, i) => {
        if (typeof content.content === 'string') {
          return (
            <ToggleButton
              text={content.content}
              containerStyle={props.containerStyle}
              textStyle={props.textStyle}
              onChange={(value) => {
                let changedSelectedKeys: string[] = [];
                if (props.multiSelect) {
                  if (value === true && selectedKeys.indexOf(content.key) === -1) {
                    changedSelectedKeys = selectedKeys.concat([content.key]);
                    setSelectedKeys(changedSelectedKeys);
                  }
                  if (value === false && selectedKeys.indexOf(content.key) !== -1) {
                    changedSelectedKeys = selectedKeys.filter((key) => key !== content.key);
                    setSelectedKeys(changedSelectedKeys);
                  }
                } else {
                  if (value === true && selectedKeys.indexOf(content.key) === -1) {
                    changedSelectedKeys = [content.key];
                    setSelectedKeys(changedSelectedKeys);
                  }
                  if (value === false && selectedKeys.indexOf(content.key) !== -1) {
                    changedSelectedKeys = [];
                    setSelectedKeys(changedSelectedKeys);
                  }
                }
                if (props.onChange) {
                  props.onChange(changedSelectedKeys);
                }
              }}
              value={selectedKeys.indexOf(content.key) !== -1}
              key={i}
            />
          );
        }
        return (
          <ToggleButton
            containerStyle={props.containerStyle}
            textStyle={props.textStyle}
            onChange={(value) => {
              let changedSelectedKeys: string[] = [];
              if (props.multiSelect) {
                if (value === true && selectedKeys.indexOf(content.key) === -1) {
                  changedSelectedKeys = selectedKeys.concat([content.key]);
                  setSelectedKeys(changedSelectedKeys);
                }
                if (value === false && selectedKeys.indexOf(content.key) !== -1) {
                  changedSelectedKeys = selectedKeys.filter((key) => key !== content.key);
                  setSelectedKeys(changedSelectedKeys);
                }
              } else {
                if (value === true && selectedKeys.indexOf(content.key) === -1) {
                  changedSelectedKeys = [content.key];
                  setSelectedKeys(changedSelectedKeys);
                }
                if (value === false && selectedKeys.indexOf(content.key) !== -1) {
                  changedSelectedKeys = [];
                  setSelectedKeys(changedSelectedKeys);
                }
              }
              if (props.onChange) {
                props.onChange(changedSelectedKeys);
              }
            }}
            value={selectedKeys.indexOf(content.key) !== -1}
            key={i}>
            {content.content}
          </ToggleButton>
        );
      })}
    </View>
  );
};

export default SelectButton;
