import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { View, Animated, NativeSyntheticEvent } from 'react-native';
import { useHistory, useParams } from 'react-router';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import {
  Organization,
  ProjectTemplate,
  ProjectTemplateTask,
  ProjectTemplateTaskStatus,
  useOrganizationQuery,
  useProjectTemplateQuery,
  useProjectTemplateTasksGroupByStatusQuery,
  useUpdateProjectTemplateTaskSortNoInTaskStatusMutation,
  useUpdateProjectTemplateTaskStatusMutation,
} from '../../../../../../../graphql/api/API';
import VirtualizedFlatList, {
  GlobalDragColumnContextProvider,
  GlobalDragContextProvider,
  IListRefInfo,
  NonForwardedRefAnimatedView,
} from '../../../../../../presentational/atoms/list2/virtualized-flat-list';
import Typography, { TypographyType } from '../../../../../../presentational/atoms/typography';
import { IStyleTheme, IThemePart } from '../../../../../../theme';
import TaskSummaryForBoard from '../../../../organisms/project-template-task-summary-for-board';
import TaskStatusCreateDialog from '../../../../organisms/project-template-task-status-create-dialog';
import TaskStatusDeleteDialog from '../../../../organisms/project-template-task-status-delete-dialog';
import Modal from '../../../../../../presentational/molecules/modal';
import EditableText from '../../../../../../presentational/atoms/editable-text';
import {
  GestureHandlerStateChangeNativeEvent,
  PanGestureHandler,
  PanGestureHandlerEventPayload,
  State,
} from 'react-native-gesture-handler';
import TaskSummaryForBoardNewTask from '../../../../organisms/project-template-task-summary-for-board-new-task';
import DeleteIcon from '../../../../../../presentational/molecules/image-icon/delete';
import CheckIcon from '../../../../../../presentational/molecules/image-icon/check';
import MenuIcon from '../../../../../../presentational/molecules/image-icon/menu';
import PlusIcon from '../../../../../../presentational/molecules/image-icon/plus';
import Spinner from '../../../../../../presentational/atoms/spinner';
import UrlUtil from '../../../../../../../util/UrlUtil';

const Container = styled.View`
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 0 20px;
  height: calc(100vh - 57px - 120px);
  width: 100%;
`;

const Menu = styled.View`
  position: absolute;
  top: 25px;
  right: 0;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
  border-width: 1px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

interface IColumnHeaderMenuProps {
  project: ProjectTemplate;
  taskStatus: ProjectTemplateTaskStatus;
  openMenu: boolean;
  setOpenMenu: (value: boolean) => void;
  onClose: () => void;
}

const ColumnHeaderMenu = (props: IColumnHeaderMenuProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [showStatusDeleteModal, setShowStatusDeleteModal] = useState(false);

  const menuRef = useRef();
  const clickDocument = (e: any) => {
    if ((menuRef?.current as any)?.contains(e.target)) {
      (menuRef?.current as any)?.click();
      return;
    }
    props.onClose();
  };
  useEffect(() => {
    window.addEventListener('click', clickDocument);
    return () => {
      window.removeEventListener('click', clickDocument);
    };
  }, [clickDocument]);

  return (
    <>
      {props.openMenu && (
        <Menu ref={menuRef as any}>
          {!props.taskStatus.endStatus && (
            <>
              <DeleteIcon
                size={23}
                onPress={() => {
                  setShowStatusDeleteModal(true);
                  props.setOpenMenu(false);
                }}>
                <Typography variant={TypographyType.Normal}>列を削除する</Typography>
              </DeleteIcon>
            </>
          )}
        </Menu>
      )}
      <Modal
        isShow={showStatusDeleteModal}
        title="ステータスを削除する"
        onClose={() => setShowStatusDeleteModal(false)}>
        <TaskStatusDeleteDialog
          taskStatus={props.taskStatus}
          onCancel={() => setShowStatusDeleteModal(false)}
          onComplete={() => setShowStatusDeleteModal(false)}
        />
      </Modal>
    </>
  );
};

interface IColumnHeaderProps {
  project: ProjectTemplate;
  taskStatus: ProjectTemplateTaskStatus;
  isDragColumn: boolean;
  isFirstColumn: boolean;
}

const ColumnHeader = React.memo((props: IColumnHeaderProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [openMenu, setOpenMenu] = useState(false);
  const [updateTaskStatus, _] = useUpdateProjectTemplateTaskStatusMutation();

  return (
    <View
      style={
        {
          marginHorizontal: 10,
          paddingBottom: 15,
          flexDirection: 'row',
          cursor: props.isDragColumn ? 'grabbing' : 'grab',
        } as any
      }>
      {props.taskStatus.endStatus && (
        <CheckIcon size={18} on={true} containerStyle={{ marginRight: 10 }} />
      )}
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
        <View>
          <EditableText
            style={{ width: '100%' }}
            value={props.taskStatus.name}
            validate={{
              required: {
                value: true,
                message: 'ステータス名を入力してください',
              },
              maxLength: {
                value: 20,
                message: '20文字以内で入力してください',
              },
            }}
            onChange={(value) => {
              updateTaskStatus({
                variables: {
                  id: props.taskStatus.id!,
                  input: {
                    name: (value as string) || props.taskStatus.name,
                    sortNo: props.taskStatus.sortNo,
                    versionNo: props.taskStatus.versionNo,
                  },
                },
              });
            }}
            textStyle={{ fontSize: 16, maxWidth: 200 }}
          />
        </View>
        {!props.taskStatus.endStatus && (
          <View>
            <MenuIcon size={28} onPress={() => setOpenMenu(true)} />
          </View>
        )}
      </View>
      {!props.isFirstColumn && (
        <ColumnHeaderMenu
          project={props.project}
          taskStatus={props.taskStatus}
          openMenu={openMenu}
          setOpenMenu={setOpenMenu}
          onClose={() => setOpenMenu(false)}
        />
      )}
    </View>
  );
});

interface IColumnAddButtonProps {
  project: ProjectTemplate;
}

const ColumnAddButton = (props: IColumnAddButtonProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [showDialog, setShowDialog] = useState(false);
  return (
    <View
      style={{
        marginLeft: 5,
        width: 40,
        height: 40,
        borderRadius: 5,
        backgroundColor: themeContext.colors.separator,
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <PlusIcon size={28} onPress={() => setShowDialog(true)} />
      <Modal
        isShow={showDialog}
        title="新しいステータスを追加する"
        onClose={() => {
          setShowDialog(false);
        }}>
        <TaskStatusCreateDialog
          project={props.project}
          onComplete={(taskStatus) => setShowDialog(false)}
          onCancel={() => setShowDialog(false)}
        />
      </Modal>
    </View>
  );
};

interface TaskStatusGroup {
  taskStatus: ProjectTemplateTaskStatus;
  tasks: Array<ProjectTemplateTask>;
}

interface IListProps {
  organization: Organization;
  project: ProjectTemplate;
  group: TaskStatusGroup;
  groups: Array<TaskStatusGroup>;
  column: number;
  listRefs: Array<IListRefInfo>;
  width: number;
  setListRefs: (value: Array<IListRefInfo>) => void;
  setDragItem: (value: boolean) => void;
  listOuterRef: any;
}

const List = React.memo((props: IListProps) => {
  const history = useHistory();
  const [showTasksCreateLoading, setShowTasksCreateLoading] = useState(false);
  const [updateTaskSortNo, _] = useUpdateProjectTemplateTaskSortNoInTaskStatusMutation();

  const onDrop = useCallback(
    async (info) => {
      const isMoveToFirst = info.endRowIndex === 0;
      const isMoveToLast =
        info.startColumnIndex === info.endColumnIndex
          ? info.endRowIndex === props.groups![info.endColumnIndex].tasks.length - 1
          : info.endRowIndex === props.groups![info.endColumnIndex].tasks.length;
      const isMoveToDown = info.endRowIndex - info.startRowIndex > 0;
      let sortNo;
      if (isMoveToFirst) {
        sortNo = new Date().getTime();
      } else if (isMoveToLast) {
        sortNo =
          info.startColumnIndex === info.endColumnIndex
            ? props.groups![info.endColumnIndex].tasks[info.endRowIndex]!.sortNoInTaskStatus - 1000
            : props.groups![info.endColumnIndex].tasks[info.endRowIndex - 1]!.sortNoInTaskStatus -
              1000;
      } else {
        if (isMoveToDown && info.startColumnIndex === info.endColumnIndex) {
          const beforeTask = props.groups[info.endColumnIndex].tasks[info.endRowIndex];
          const afterTask = props.groups[info.endColumnIndex].tasks[info.endRowIndex + 1];
          sortNo = Math.floor((beforeTask!.sortNoInTaskStatus + afterTask!.sortNoInTaskStatus) / 2);
        } else {
          const beforeTask = props.groups[info.endColumnIndex].tasks[info.endRowIndex - 1];
          const afterTask = props.groups[info.endColumnIndex].tasks[info.endRowIndex];
          sortNo = Math.floor((beforeTask!.sortNoInTaskStatus + afterTask!.sortNoInTaskStatus) / 2);
        }
      }
      await updateTaskSortNo({
        variables: {
          id: info.item.id!,
          input: {
            sortNoInTaskStatus: sortNo,
            versionNo: info.item!.versionNo,
          },
        },
        update: (cache, result) => {
          cache.modify({
            fields: {
              projectTasksGroupByStatus(existing = []) {
                // 別のステータスに移動した場合には、移動元の配列から削除して、移動先の配列に追加する必要がある。
                // refetchだと一瞬描画が追いつかず、だいぶ違和感のある動きになってしまうため、キャッシュを直接編集する
                if (info.startColumnIndex === info.endColumnIndex) {
                  return existing;
                }

                return existing.map((groupRef: any) => {
                  const srcTaskStatusId = props.groups[info.startColumnIndex].taskStatus.id;
                  const destTaskStatusId = props.groups[info.endColumnIndex].taskStatus.id;
                  // 移動元の配列から該当タスクを削除
                  if (
                    groupRef.taskStatus.__ref === `ProjectTemplateTaskStatus:${srcTaskStatusId}`
                  ) {
                    const cacheColumn = existing.find(
                      (group: any) =>
                        group.taskStatus.__ref ===
                        `ProjectTemplateTaskStatus:${
                          props.groups[info.startColumnIndex].taskStatus.id
                        }`
                    );
                    return {
                      taskStatus: groupRef.taskStatus,
                      tasks: [
                        ...cacheColumn.tasks.slice().filter(
                          (task: any) =>
                            task.__ref !==
                            `ProjectTemplateTask:${result.data?.updateProjectTemplateTaskSortNoInTaskStatus?.id}` // ApolloClientの内部実装に依存してしまっていると思うので、やや危険。仕方ないけど、ApolloClientのバージョンアップ時に注意が必要そう。
                        ),
                      ],
                    };
                  }
                  // 移動先の配列に該当タスクを追加
                  if (
                    groupRef.taskStatus.__ref === `ProjectTemplateTaskStatus:${destTaskStatusId}`
                  ) {
                    const cacheColumn = existing.find(
                      (group: any) =>
                        group.taskStatus.__ref ===
                        `ProjectTemplateTaskStatus:${
                          props.groups[info.endColumnIndex].taskStatus.id
                        }`
                    );
                    return {
                      taskStatus: groupRef.taskStatus,
                      tasks: [
                        ...cacheColumn.tasks,
                        {
                          __ref: `ProjectTemplateTask:${result.data?.updateProjectTemplateTaskSortNoInTaskStatus?.id}`,
                        },
                      ],
                    };
                  }
                  return {
                    taskStatus: groupRef.taskStatus,
                    tasks: groupRef.tasks,
                  };
                });
              },
            },
          });
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateProjectTemplateTaskSortNoInTaskStatus: Object.assign(
            {
              __typename: 'ProjectTemplateTask',
            },
            info.item,
            { sortNoInTaskStatus: sortNo }
          ) as any,
        },
      });
    },
    [props.groups]
  );

  const renderItem = useCallback(
    (item, index) => {
      return (
        <TaskSummaryForBoard organization={props.organization} task={item as ProjectTemplateTask} />
      );
    },
    [props.organization]
  );

  const getKey = useCallback((task) => task!.id!.toString(), []);

  const addListRefs = useCallback(
    (info) => {
      props.listRefs.push(info);
      props.setListRefs(props.listRefs);
    },
    [props.listRefs]
  );

  const updateListRefs = useCallback(
    (info) => {
      const findIndex = props.listRefs.findIndex((ref) => ref.columnKey === info.columnKey);
      props.listRefs[findIndex] = info;
      props.setListRefs(props.listRefs);
    },
    [props.listRefs, props.setListRefs]
  );

  const onPress = useCallback(
    (task) => {
      history.push(UrlUtil.createTaskTemplateDetailUrl(task));
    },
    [props.organization.id!, props.project]
  );

  const onDragStart = useCallback(() => props.setDragItem(true), [props.setDragItem]);

  const onDropEnd = useCallback(() => props.setDragItem(false), [props.setDragItem]);

  return (
    <>
      <Spinner loading={showTasksCreateLoading} />
      <VirtualizedFlatList
        style={{
          height: 'calc(100% - 60px)',
        }}
        listOuterRef={props.listOuterRef}
        items={props.group!.tasks}
        column={props.column}
        columnKey={props.group!.taskStatus.id!}
        allColumns={props.groups.map((group) => group.tasks)}
        renderItem={renderItem}
        getKey={getKey}
        listRefs={props.listRefs}
        addListRefs={addListRefs}
        updateListRefs={updateListRefs}
        itemHeight={76}
        itemWidth={props.width}
        onPress={onPress}
        virticalDraggable={true}
        horizontalDraggable={false}
        onDragStart={onDragStart}
        onDropEnd={onDropEnd}
        onDrop={onDrop}
        listHeaderComponent={
          props.column === 0 && (
            <TaskSummaryForBoardNewTask
              project={props.project}
              taskStatus={props.group!.taskStatus}
              lastTask={null}
              setShowCreateTasksLoading={setShowTasksCreateLoading}
            />
          )
        }
        listFooterComponent={
          props.group!.tasks.length === 0 || props.column !== 0 ? (
            <></>
          ) : (
            <TaskSummaryForBoardNewTask
              project={props.project}
              taskStatus={props.group!.taskStatus}
              lastTask={
                props.group!.tasks.length > 0
                  ? props.group!.tasks[props.group!.tasks.length - 1]
                  : null
              }
              setShowCreateTasksLoading={setShowTasksCreateLoading}
            />
          )
        }
        key={props.group!.taskStatus.id}
      />
    </>
  );
});

interface IColumnProps {
  organization: Organization;
  project: ProjectTemplate;
  group: TaskStatusGroup;
  groups: Array<TaskStatusGroup>;
  column: number;
  listRefs: Array<IListRefInfo>;
  width: number;
  setListRefs: (value: Array<IListRefInfo>) => void;
}

const Column = (props: IColumnProps) => {
  const [translationX, setTranslationX] = useState(new Animated.Value(0));
  const [isDragItem, setDragItem] = useState(false);
  const [isDragColumn, setDragColumn] = useState(false);
  const [dragColumnInfo, setDragColumnInfo] = useContext(ColumnDragContext);
  const listOuterRef = useRef();
  const [updateTaskStatus, _] = useUpdateProjectTemplateTaskStatusMutation();

  const dudListener = useCallback(
    (
      event: NativeSyntheticEvent<
        PanGestureHandlerEventPayload & GestureHandlerStateChangeNativeEvent
      >
    ) => {
      if (event.nativeEvent.state === State.BEGAN) {
        setDragColumn(true);
        //@ts-ignore
        setDragColumnInfo({
          startColumn: props.column,
          endColumn: props.column,
          dragging: true,
        });
      }
      if (event.nativeEvent.state === State.ACTIVE) {
        const moveColumn = event.nativeEvent.translationX / props.width;
        let afterColumn = Math.round(props.column + moveColumn);
        afterColumn = Math.max(afterColumn, 0);
        afterColumn = Math.min(afterColumn, props.groups.length - 1);

        if ((dragColumnInfo as DragColumnInfo).endColumn != afterColumn) {
          //@ts-ignore
          setDragColumnInfo({
            startColumn: props.column,
            endColumn: afterColumn,
            dragging: true,
          });
        }
      }
      if (event.nativeEvent.state === State.END) {
        const moveColumn = event.nativeEvent.translationX / props.width;
        let afterColumn = Math.round(props.column + moveColumn);
        afterColumn = Math.max(afterColumn, 0);
        afterColumn = Math.min(afterColumn, props.groups.length - 1);

        setDragColumn(false);
        //@ts-ignore
        setDragColumnInfo({
          startColumn: props.column,
          endColumn: afterColumn,
          dragging: false,
        });

        if (props.column !== afterColumn) {
          const isMoveToFirst = afterColumn === 0;
          const isMoveToLast = afterColumn === props.groups.length - 1;
          const isMoveToDown = afterColumn - props.column > 0;

          let sortNo;
          if (isMoveToFirst) {
            sortNo = props.groups[afterColumn]!.taskStatus.sortNo - 1000;
          } else if (isMoveToLast) {
            sortNo = new Date().getTime();
          } else {
            if (isMoveToDown) {
              const beforeTaskStatus = props.groups[afterColumn].taskStatus;
              const afterTaskStatus = props.groups[afterColumn + 1].taskStatus;
              sortNo = Math.floor((beforeTaskStatus!.sortNo + afterTaskStatus!.sortNo) / 2);
            } else {
              const beforeTaskStatus = props.groups[afterColumn - 1].taskStatus;
              const afterTaskStatus = props.groups[afterColumn].taskStatus;
              sortNo = Math.floor((beforeTaskStatus!.sortNo + afterTaskStatus!.sortNo) / 2);
            }
          }

          if (props.column === 0 || afterColumn === 0) {
            //一番左の列は動かさないようにする
            return;
          }
          updateTaskStatus({
            variables: {
              id: props.group.taskStatus.id!,
              input: {
                name: props.group.taskStatus.name,
                sortNo: sortNo,
                versionNo: props.group.taskStatus.versionNo,
              },
            },
            optimisticResponse: {
              __typename: 'Mutation',
              updateProjectTemplateTaskStatus: Object.assign(
                {
                  __typename: 'ProjectTemplateTaskStatus',
                },
                props.group.taskStatus,
                { sortNo: sortNo }
              ),
            },
          });
        }
      }
    },
    [setDragColumn, setDragColumnInfo, dragColumnInfo]
  );

  useEffect(() => {
    if ((dragColumnInfo as DragColumnInfo).dragging) {
      if (
        (dragColumnInfo as DragColumnInfo).endColumn !==
        (dragColumnInfo as DragColumnInfo).startColumn
      ) {
        if (
          (dragColumnInfo as DragColumnInfo).endColumn >
            (dragColumnInfo as DragColumnInfo).startColumn &&
          (dragColumnInfo as DragColumnInfo).endColumn >= props.column &&
          (dragColumnInfo as DragColumnInfo).startColumn < props.column
        ) {
          Animated.timing(translationX, {
            toValue: -props.width,
            duration: 200,
            useNativeDriver: true,
          }).start();
        } else if (
          (dragColumnInfo as DragColumnInfo).endColumn <
            (dragColumnInfo as DragColumnInfo).startColumn &&
          (dragColumnInfo as DragColumnInfo).endColumn <= props.column &&
          (dragColumnInfo as DragColumnInfo).startColumn > props.column
        ) {
          Animated.timing(translationX, {
            toValue: props.width,
            duration: 200,
            useNativeDriver: true,
          }).start();
        } else {
          Animated.timing(translationX, {
            toValue: 0,
            duration: 200,
            useNativeDriver: true,
          }).start();
        }
      } else {
        Animated.timing(translationX, {
          toValue: 0,
          duration: 200,
          useNativeDriver: true,
        }).start();
      }
    } else {
      Animated.timing(translationX, {
        toValue: 0,
        duration: 0,
        useNativeDriver: true,
      }).start();
    }
  }, [dragColumnInfo]);

  return (
    <Animated.View
      ref={listOuterRef as any}
      style={
        {
          zIndex: isDragColumn ? 3 : 0,
          transform:
            !isDragColumn && isDragItem
              ? []
              : [
                  {
                    translateX: translationX,
                  },
                ],
        } as any
      }>
      <View
        style={{
          width: props.width,
          height: 'calc(100vh - 220px)',
          backgroundColor: '#f4f5f7',
          paddingVertical: 10,
          paddingHorizontal: 2,
          marginHorizontal: 5,
          borderRadius: 5,
        }}>
        <View
          style={{
            zIndex: 5,
          }}>
          <PanGestureHandler
            maxPointers={1}
            onGestureEvent={Animated.event([{ nativeEvent: { translationX: translationX } }], {
              listener: dudListener,
              useNativeDriver: true,
            })}
            onHandlerStateChange={(event) => {
              if (event.nativeEvent.state === State.BEGAN) {
                setTranslationX(new Animated.Value(0));
              }
              if (event.nativeEvent.state === State.ACTIVE) {
              }
              if (event.nativeEvent.state === State.END) {
                setTranslationX(new Animated.Value(0));
              }
            }}>
            <NonForwardedRefAnimatedView>
              <ColumnHeader
                taskStatus={props.group.taskStatus}
                project={props.project}
                isDragColumn={isDragColumn}
                isFirstColumn={props.column === 0}
              />
            </NonForwardedRefAnimatedView>
          </PanGestureHandler>
        </View>
        <List
          organization={props.organization}
          project={props.project}
          group={props.group as TaskStatusGroup}
          groups={props.groups as Array<TaskStatusGroup>}
          column={props.column}
          listRefs={props.listRefs}
          setListRefs={props.setListRefs}
          width={props.width}
          setDragItem={setDragItem}
          listOuterRef={listOuterRef}
        />
      </View>
    </Animated.View>
  );
};

interface IProjectBoardInnerProps {
  project: ProjectTemplate;
  organization: Organization;
}

const ProjectBoardInner = (props: IProjectBoardInnerProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const { loading, data, error } = useProjectTemplateTasksGroupByStatusQuery({
    variables: {
      projectTemplateId: props.project.id!,
    },
    fetchPolicy: 'network-only',
  });
  const [listRefs, setListRefs] = useState<Array<IListRefInfo>>([]);

  // Memo化しておかないと、データに変更がなくても、このコンポーネントが描画される度に、全てのTaskSummary再描画がされてしまう。
  const groups = useMemo(() => {
    if (loading || !data?.projectTemplateTasksGroupByStatus) {
      return [];
    }
    return data!
      .projectTemplateTasksGroupByStatus!.slice()
      .sort((a, b) => {
        return a!.projectTemplateTaskStatus.sortNo - b!.projectTemplateTaskStatus.sortNo; // ソート番号は昇順
      })
      .map((group) => {
        return {
          taskStatus: group!.projectTemplateTaskStatus,
          tasks: group!.projectTemplateTasks.slice().sort((a, b) => {
            return b!.sortNoInTaskStatus - a!.sortNoInTaskStatus; // ソート番号は降順
          }),
        };
      });
  }, [loading, data?.projectTemplateTasksGroupByStatus]);

  if (loading || !data?.projectTemplateTasksGroupByStatus) {
    return <></>;
  }

  return (
    <Container>
      <View
        style={{
          flexDirection: 'row',
          position: 'absolute',
          top: 40,
        }}>
        <ColumnDragContextProvider>
          <GlobalDragContextProvider>
            <GlobalDragColumnContextProvider>
              {groups.map((group, i) => {
                return (
                  <Column
                    organization={props.organization}
                    project={props.project}
                    group={group as TaskStatusGroup}
                    groups={groups as Array<TaskStatusGroup>}
                    column={i}
                    listRefs={listRefs}
                    setListRefs={setListRefs}
                    width={290}
                    key={i}
                  />
                );
              })}
            </GlobalDragColumnContextProvider>
          </GlobalDragContextProvider>
        </ColumnDragContextProvider>
        <ColumnAddButton project={props.project} />
      </View>
    </Container>
  );
};

interface IProjectBoardParam {
  projectId: string;
  organizationId: string;
}

const ProjectBoard = () => {
  const params = useParams<IProjectBoardParam>();
  const { loading, data, error } = useProjectTemplateQuery({
    variables: {
      id: params.projectId,
    },
  });
  const fetchOrganization = useOrganizationQuery({
    variables: {
      id: params.organizationId,
    },
  });
  if (loading || !data?.projectTemplate || fetchOrganization.loading || !fetchOrganization.data) {
    return <></>;
  }

  return (
    <ProjectBoardInner
      organization={fetchOrganization.data!.organization!}
      project={data!.projectTemplate!}
    />
  );
};

interface DragColumnInfo {
  startColumn: number;
  endColumn: number;
  dragging: boolean;
}

export const ColumnDragContext = createContext([
  { startColumn: 0, endColumn: 0, dragging: false },
  (value: DragColumnInfo) => {},
]);

export const ColumnDragContextProvider = (props: any) => {
  const [draggingPosIndex, setDraggingPosIndex] = useState<DragColumnInfo>({
    startColumn: 0,
    endColumn: 0,
    dragging: false,
  });
  return (
    <ColumnDragContext.Provider value={[draggingPosIndex as any, setDraggingPosIndex]}>
      {props.children}
    </ColumnDragContext.Provider>
  );
};

export default React.memo(ProjectBoard);
