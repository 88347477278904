import React, { useCallback, useContext, useState } from 'react';
import { View, Image } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import { Invoice, Organization, useInvoicesQuery } from '../../../../../../../graphql/api/API';
import when from '../../../../../../../lang-extention/When';
import { LoginUserContext } from '../../../../../../../modules/auth/LoginUserContext';
import Table from '../../../../../../presentational/molecules/table';
import { IThemePart } from '../../../../../../theme';
import Typography, { TypographyType } from '../../../../../../presentational/atoms/typography';
import noData05 from '../../../../../../../base64Images/no-data/no-data-5';
import Link from '../../../../../../presentational/atoms/link';
import Modal from '../../../../../../presentational/molecules/modal';
import Button from '../../../../../../presentational/atoms/button';
import Separator from '../../../../../../presentational/atoms/separator';
import SettingsElement from '../../../../organisms/settings-element';
import moment from 'moment';
import DownloadIcon from '../../../../../../presentational/molecules/image-icon/download';
import OpenInNewIcon from '../../../../../../presentational/molecules/image-icon/open-in-new';
import { TouchableOpacity } from '@gorhom/bottom-sheet';

const Container = styled.View`
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
`;

const adjustDisplayDescription = (
  invoice?: Invoice // 請求書を表示用に加工する
) => invoice?.title.replace(/^\d*\s×\s/, '').replace(/\(at.*$/, '');

const InvoiceDetailModal = (props: {
  isShown: boolean;
  onClose: () => void;
  invoice?: Invoice;
}) => {
  const getDisplayPlanNameCallBack = useCallback(
    (invoice: Invoice) => adjustDisplayDescription(invoice),
    [props.invoice]
  );

  return (
    <Modal isShow={props.isShown} onClose={props.onClose} title="請求詳細">
      <View style={{ paddingVertical: 30 }}>
        <SettingsElement title={'請求番号'}>
          <Typography variant={TypographyType.Normal}>
            {props.invoice?.billingNumber || '-'}
          </Typography>
        </SettingsElement>
        <SettingsElement title={'請求日'}>
          <Typography variant={TypographyType.Normal}>
            {moment(props.invoice?.billingDateTime).format('YYYY年MM月DD日')}
          </Typography>
        </SettingsElement>
        <SettingsElement title={'ご利用期間'}>
          <Typography variant={TypographyType.Normal}>{`${moment(
            props.invoice?.periodStartDateTime
          ).format('YYYY年MM月DD日')} 〜 ${moment(props.invoice?.periodEndDateTime).format(
            'YYYY年MM月DD日'
          )}`}</Typography>
        </SettingsElement>
        <SettingsElement title={'お支払い状況'}>
          <Typography variant={TypographyType.Normal}>
            {props.invoice?.paid ? 'お支払い済' : '未払い'}
          </Typography>
          {props.invoice?.receiptUrl && (
            <Typography variant={TypographyType.Description}>
              <Link
                path={props.invoice?.receiptUrl!}
                isExternalLink
                style={{ display: 'inline-flex', alignItems: 'center' }}>
                領収書を表示する
                <OpenInNewIcon size={12} />
              </Link>
            </Typography>
          )}
        </SettingsElement>
        <SettingsElement title={'請求書'}>
          <Typography variant={TypographyType.Normal}>
            {props.invoice?.invoicePdfUrl ? (
              <Link
                path={props.invoice?.invoicePdfUrl!}
                isExternalLink
                style={{ display: 'inline-flex', alignItems: 'center' }}>
                請求書をダウンロードする
                <DownloadIcon size={20} />
              </Link>
            ) : (
              '請求書が作成されていません。'
            )}
          </Typography>
        </SettingsElement>
        <Table
          rowData={[
            {
              項目: getDisplayPlanNameCallBack(props.invoice!),
              数量: props.invoice?.quantity,
              単価: `${props.invoice?.unitPrice.toLocaleString('ja-JP')}円`,
              金額: `${props.invoice?.subtotal.toLocaleString('ja-JP')}円`,
            },
          ]}
          rowDataStyle={{
            項目: { width: 300 },
            数量: { width: 80 },
            単価: { width: 100, textAlign: 'center' },
            金額: { width: 100, textAlign: 'center' },
          }}
          style={{ minWidth: 500 }}
          hideFooter
        />
        <View
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            paddingTop: 20,
          }}>
          <View style={{ width: 200, paddingHorizontal: 20 }}>
            <SettingsElement title="小計" titleWidth={100} containerStyle={{ marginVertical: 5 }}>
              <Typography variant={TypographyType.Normal} style={{ lineHeight: 18 }}>
                {props.invoice?.subtotal.toLocaleString('ja-JP')}円
              </Typography>
            </SettingsElement>
            <SettingsElement title="消費税" titleWidth={100} containerStyle={{ marginVertical: 5 }}>
              <Typography variant={TypographyType.Normal} style={{ lineHeight: 18 }}>
                {props.invoice?.tax.toLocaleString('ja-JP')}円
              </Typography>
            </SettingsElement>
            {props.invoice?.discount !== undefined && props.invoice?.discount > 0 && (
              <SettingsElement
                title="割引金額"
                titleWidth={100}
                containerStyle={{ marginVertical: 5 }}>
                <Typography variant={TypographyType.Normal} style={{ lineHeight: 18 }}>
                  -{props.invoice?.discount.toLocaleString('ja-JP')}円
                </Typography>
              </SettingsElement>
            )}
            <Separator outerStyle={{ marginVertical: 5 }} lineStyle={{ margin: 0 }} />
            <SettingsElement
              title="ご請求金額"
              titleWidth={100}
              containerStyle={{ marginVertical: 5 }}>
              <Typography
                variant={TypographyType.Normal}
                style={{ fontWeight: '600', lineHeight: 18 }}>
                {props.invoice?.total.toLocaleString('ja-JP')}円
              </Typography>
            </SettingsElement>
          </View>
        </View>
      </View>
    </Modal>
  );
};

const OrganizationInvoicePage = () => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [isDetailShown, setIsDetailShown] = useState(false);
  const [invoiceForDetail, setInvoiceForDetail] = useState<Invoice>();

  const { data, loading } = useInvoicesQuery({
    fetchPolicy: 'network-only',
  });

  const getDisplayPlanNameCallBack = useCallback(
    (invoice: Invoice) => adjustDisplayDescription(invoice),
    [data]
  );

  if (loading) {
    return <></>;
  }

  return (
    <Container>
      <InvoiceDetailModal
        isShown={isDetailShown}
        onClose={() => setIsDetailShown(false)}
        invoice={invoiceForDetail}
      />
      {data?.invoices && data.invoices.length > 0 ? (
        <Table
          rowData={data!.invoices!.map((invoice) => ({
            請求日: moment(invoice?.billingDateTime).format('YYYY年MM月DD日'),
            請求番号: invoice?.billingNumber || '-',
            項目: getDisplayPlanNameCallBack(invoice!),
            金額: `${invoice?.total.toLocaleString('ja-JP')}円`,
            '': (
              <Typography
                variant={TypographyType.Normal}
                style={{ color: themeContext.colors.link, fontSize: 13 }}>
                詳細を表示する
              </Typography>
            ),
          }))}
          rowDataStyle={{
            請求日: { minWidth: 130 },
            請求番号: { minWidth: 130 },
            項目: { minWidth: 200 },
            金額: { minWidth: 120 },
            '': { minWidth: 150 },
          }}
          onPress={(rowData) => {
            setInvoiceForDetail(
              data?.invoices?.find((invoice) => invoice?.billingNumber === rowData['請求番号']) ||
                undefined
            );
            setIsDetailShown(true);
          }}
        />
      ) : (
        <View
          style={{
            height: 'calc(100vh - 57px - 112px - 47px)',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Image
            source={{ uri: noData05 }}
            resizeMode="contain"
            style={{
              width: 350,
              height: 200,
              opacity: 0.7,
            }}
          />
          <Typography
            variant={TypographyType.Normal}
            style={{ color: themeContext.colors.description, textAlign: 'center' }}>
            {`請求履歴が存在しません。`}
          </Typography>
        </View>
      )}
    </Container>
  );
};

export default OrganizationInvoicePage;
