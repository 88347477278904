import React, { useState, useRef, useEffect, useContext } from 'react';
import Input, { ListValueMap } from '../input';
import Form from '../form';
import Typography, { TypographyType } from '../typography';
import { TextStyle, ViewStyle, View } from 'react-native';
import { TouchableOpacity } from 'react-native';
import useHover from './use-hover';
import { IThemePart } from '../../../theme';
import { ThemeContext } from 'styled-components/native';
import { ValidationRules } from 'react-hook-form';
import DateTimePicker from '../date-time-picker';
import TimePicker from '../time-picker';
import TimeUtil from '../../../../util/TimeUtil';
import moment from 'moment-timezone';
import DateRangePicker from '../date-range-picker';
import ColorUtil from '../../../../util/ColorUtil';
import CreatableMutiselect from '../creatable-mutiselect';
import MultiSelect from '../multiselect';

export interface IDateRange {
  start: moment.Moment;
  end: moment.Moment;
}

interface ILabelProps {
  value: string | number | moment.Moment | IDateRange | string[] | null;
  editMode: boolean;
  setEditMode: (value: boolean) => void;
  type?:
    | 'text'
    | 'picker'
    | 'rich-text-editor'
    | 'date-time-picker'
    | 'date-picker'
    | 'date-range-picker'
    | 'multi-picker'
    | 'time-picker'
    | 'number'
    | 'currency'
    | 'percent'
    | 'remainder-picker';
  emptyText?: string;
  textStyle?: TextStyle;
  ellipsis?: boolean;
  pickerItems?: ListValueMap[];
  renderComponent?: (value: string | number, empty: boolean) => React.ReactNode | null;
  format?: (value: any) => string;
  containerStyle?: ViewStyle;
  showSeconds?: boolean;
  editable?: boolean;
  onClickWhenNotEditable?: () => void;
}

const Label = (props: ILabelProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [hoverRef, isHover] = useHover();
  const prepareLabelText = () => {
    if (props.format) {
      return props.format(props.value);
    }
    if (props.type === 'picker') {
      return props.value === null || props.value === undefined || props.value === ''
        ? null
        : props.pickerItems!.find((item) => item.value === props.value)?.label;
    }
    if (props.type === 'date-time-picker') {
      if (props.value) {
        return (props.value as moment.Moment).format(
          props.showSeconds ? 'YYYY/MM/DD HH:mm:ss' : 'YYYY/MM/DD HH:mm'
        );
      }
      return '-';
    }
    if (props.type === 'date-range-picker') {
      if (props.value) {
        return `${(props.value as IDateRange).start.format('YYYY/MM/DD')} 〜 ${(
          props.value as IDateRange
        ).end.format('YYYY/MM/DD')}`;
      }
      return '-';
    }
    if (props.type === 'date-picker') {
      if (props.value !== null && props.value !== undefined) {
        return (props.value as moment.Moment).format('YYYY/MM/DD');
      }
      return '-';
    }
    if (props.type === 'time-picker') {
      if (props.value !== null && props.value !== undefined) {
        return TimeUtil.formatForTask(props.value as number);
      }
      return '-';
    }
    if (props.type === 'remainder-picker') {
      if (props.value) {
        return `${TimeUtil.formatWithDay(props.value as number)}前`;
      }
      return '〆切が過ぎた時';
    }
    if (props.type === 'multi-picker') {
      if (props.value && (props.value as string[]).length) {
        return (
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}>
            {(props.value as string[]).map((value, i) => (
              <TouchableOpacity
                key={i}
                style={{
                  backgroundColor: ColorUtil.lignten(themeContext.colors.label, 14),
                  borderRadius: 5,
                  paddingHorizontal: '0.5rem',
                  margin: '1.5px',
                }}>
                <Typography
                  variant={TypographyType.Button}
                  ellipsis={props.ellipsis === false ? false : true}>
                  {value}
                </Typography>
              </TouchableOpacity>
            ))}
          </View>
        );
      }
      return '-';
    }
    if (props.type === 'number') {
      if (
        props.value !== null &&
        props.value !== undefined &&
        `${props.value}`.trim().length !== 0
      ) {
        return `${props.value}`;
      }
      return '-';
    }
    if (props.type === 'currency') {
      if (
        props.value !== null &&
        props.value !== undefined &&
        `${props.value}`.trim().length !== 0
      ) {
        return `${new Intl.NumberFormat('ja-JP', {
          style: 'currency',
          currency: 'JPY',
        }).format(props.value as number)}`;
      }
      return '-';
    }
    if (props.type === 'percent') {
      if (
        props.value !== null &&
        props.value !== undefined &&
        `${props.value}`.trim().length !== 0
      ) {
        return `${props.value}%`;
      }
      return '-';
    }

    return props.value;
  };

  return (
    <TouchableOpacity
      ref={hoverRef}
      onPress={() => {
        if (props.editable === false) {
          if (props.onClickWhenNotEditable) {
            props.onClickWhenNotEditable();
          }
          return;
        }
        props.setEditMode(true);
      }}
      style={[
        {
          minHeight: 20,
          width: '100%',
          cursor: 'pointer',
          backgroundColor:
            isHover && !props.editMode ? themeContext.colors.separator : 'transparent',
        },
        props.containerStyle,
      ]}>
      {props.renderComponent ? (
        props.renderComponent(
          prepareLabelText() !== null ? prepareLabelText() : (props.emptyText as any),
          prepareLabelText() === null
        )
      ) : (
        <Typography
          variant={TypographyType.Normal}
          style={
            [
              props.textStyle,
              prepareLabelText() ? {} : { color: themeContext.colors.description },
            ] as any
          }
          ellipsis={props.ellipsis === false ? false : true}>
          {prepareLabelText() || props.emptyText}
        </Typography>
      )}
    </TouchableOpacity>
  );
};

interface IEditFormProps {
  value: string | number | moment.Moment | string[] | IDateRange;
  setValue: (value: string | number | moment.Moment | string[] | IDateRange | null) => void;
  setEditMode: (value: boolean) => void;
  textStyle?: TextStyle;
  inputStyle?: any;
  type:
    | 'text'
    | 'picker'
    | 'rich-text-editor'
    | 'date-time-picker'
    | 'date-picker'
    | 'date-range-picker'
    | 'multi-picker'
    | 'time-picker'
    | 'number'
    | 'currency'
    | 'percent'
    | 'remainder-picker';
  pickerItems?: ListValueMap[];
  isSearchable?: boolean;
  onChange?: (value: string | number | moment.Moment | IDateRange | string[] | null) => void;
  onBlur?: (value: string | string[], textInputElement: any) => void;
  onPressEnter?: () => void;
  disableClear?: boolean;
  validate?: ValidationRules;
  asyncValidate?: (value: string) => Promise<string | null>;
  selectableStartDate?: moment.Moment | null;
  selectableEndDate?: moment.Moment | null;
  noOptionsMessage?: string;
  disallowCreateNewPickerItem?: boolean;
  onFocus?: () => void;
}

const EditForm = (props: IEditFormProps) => {
  if (props.type === 'date-time-picker' || props.type === 'date-picker') {
    return (
      <Form style={{ width: '100%' }}>
        <DateTimePicker
          name={'dummy'}
          mode={props.type === 'date-picker' ? 'date' : 'datetime'}
          initialValue={props.value as moment.Moment}
          inputstyle={props.textStyle}
          hideClearButton={props.disableClear}
          focus={true}
          onFocus={props.onFocus}
          fromEditableText={true}
          onChange={(value) => {
            props.setValue(value);
            props.setEditMode(false);
            if (props.onChange) {
              props.onChange(value);
            }
          }}
          onBlur={(value, element) => {
            props.setValue(value);
            props.setEditMode(false);
            if (props.onChange) {
              props.onChange(value);
            }
            if (props.onBlur) {
              props.onBlur(value);
            }
          }}
          onPressEnter={(value) => {
            props.setValue(value);
            props.setEditMode(false);
            if (props.onChange) {
              props.onChange(value);
            }
          }}
        />
      </Form>
    );
  }
  if (props.type === 'date-range-picker') {
    return (
      <Form style={{ width: '100%' }}>
        <DateRangePicker
          name={'dummy'}
          initialValue={{
            start: (props.value as IDateRange).start,
            end: (props.value as IDateRange).end,
          }}
          inputstyle={props.textStyle}
          selectableStartDate={props.selectableStartDate}
          selectableEndDate={props.selectableEndDate}
          focus={true}
          disableClear={props.disableClear}
          onChange={(value) => {
            props.setValue(value);
            props.setEditMode(false);
            if (props.onChange) {
              props.onChange(value);
            }
          }}
          onBlur={(value, element) => {
            props.setValue(value);
            props.setEditMode(false);
            if (props.onChange) {
              props.onChange(value);
            }
          }}
          onPressEnter={(value) => {
            props.setValue(value);
            props.setEditMode(false);
            if (props.onChange) {
              props.onChange(value);
            }
          }}
        />
      </Form>
    );
  }
  if (props.type === 'time-picker') {
    return (
      <Form style={{ width: '100%' }}>
        <TimePicker
          name={'dummy'}
          focus={true}
          initialValue={props.value as number}
          onChange={(value) => {
            props.setValue(value);
            props.setEditMode(false);
            if (props.onChange) {
              props.onChange(value);
            }
          }}
          onBlur={(value, element) => {
            props.setValue(value);
            props.setEditMode(false);
            if (props.onChange) {
              props.onChange(value);
            }
          }}
          onPressEnter={(value) => {
            props.setValue(value);
            props.setEditMode(false);
            if (props.onChange) {
              props.onChange(value);
            }
          }}
        />
      </Form>
    );
  }
  if (props.type === 'remainder-picker') {
    return (
      <Form style={{ width: '100%' }}>
        <TimePicker
          name={'dummy'}
          focus={true}
          disableClear={props.disableClear}
          enableDays={true}
          disableSeconds={true}
          initialValue={props.value as number}
          onChange={(value) => {
            props.setValue(value);
            props.setEditMode(false);
            if (props.onChange) {
              props.onChange(value);
            }
          }}
          onBlur={(value, element) => {
            props.setValue(value);
            props.setEditMode(false);
            if (props.onChange) {
              props.onChange(value);
            }
          }}
          onPressEnter={(value) => {
            props.setValue(value);
            props.setEditMode(false);
            if (props.onChange) {
              props.onChange(value);
            }
          }}
        />
      </Form>
    );
  }
  if (props.type === 'multi-picker') {
    if (props.disallowCreateNewPickerItem === false) {
      return (
        <Form style={{ width: '100%' }}>
          <MultiSelect
            focus={true}
            name={'dummy'}
            noOptionsMessage={props.noOptionsMessage}
            validate={props.validate && props.validate.validate ? props.validate.validate : null}
            initialValues={props.value as string[]}
            pickerItems={props.pickerItems}
            defaultMenuIsOpen={true}
            isSearchable={props.isSearchable}
            onChange={(value) => {
              props.setValue(value);
              if (props.onChange) {
                props.onChange(value);
              }
            }}
            onBlur={(values, element) => {
              if (props.validate?.required ? values.length > 0 : values.length >= 0) {
                props.setValue(values);
              } else {
                props.setValue(props.value);
              }
              if (props.onBlur) {
                props.onBlur(values, element);
              }
              props.setEditMode(false);
            }}
            onPressEnter={(value) => {
              props.setValue(value);
              props.setEditMode(false);
              if (props.onPressEnter) {
                props.onPressEnter();
              }
            }}
          />
        </Form>
      );
    }
    return (
      <Form style={{ width: '100%' }}>
        <CreatableMutiselect
          focus={true}
          name={'dummy'}
          noOptionsMessage={props.noOptionsMessage}
          validate={props.validate && props.validate.validate ? props.validate.validate : null}
          initialValues={props.value as string[]}
          pickerItems={props.pickerItems}
          defaultMenuIsOpen={true}
          isSearchable={props.isSearchable}
          onChange={(value) => {
            props.setValue(value);
            if (props.onChange) {
              props.onChange(value);
            }
          }}
          onBlur={(values, element) => {
            if (props.validate?.required ? values.length > 0 : values.length >= 0) {
              props.setValue(values);
            } else {
              props.setValue(props.value);
            }
            if (props.onBlur) {
              props.onBlur(values, element);
            }
            props.setEditMode(false);
          }}
          onPressEnter={(value) => {
            props.setValue(value);
            props.setEditMode(false);
            if (props.onPressEnter) {
              props.onPressEnter();
            }
          }}
        />
      </Form>
    );
  }
  if (props.type === 'number' || props.type === 'currency' || props.type === 'percent') {
    return (
      <Form style={{ width: '100%' }}>
        <Input
          focus={true}
          inputstyle={[props.textStyle, props.inputStyle] as any}
          containerStyle={{ margin: 0, padding: 0, minHeight: 20, width: '100%' }}
          name={'dummy'}
          type={'number'}
          initialValue={props.value === 0 ? '0' : props.value}
          pickerItems={props.pickerItems}
          defaultMenuIsOpen={true}
          isSearchable={props.isSearchable}
          onChange={(value) => {
            if (props.type === 'picker') {
              const numberValue = isNaN(value as any) ? null : Number(value);
              props.setValue(numberValue);
              props.setEditMode(false);
              if (props.onChange) {
                props.onChange(numberValue);
              }
            }
          }}
          onBlur={(value, element) => {
            const numberValue = isNaN(value as any) ? null : Number(value);
            if (props.validate?.required ? value.length > 0 : value.length >= 0) {
              props.setValue(numberValue);
            } else {
              props.setValue(props.value);
            }
            if (props.onChange) {
              props.onChange(numberValue);
            }
            props.setEditMode(false);
          }}
          // onPressEnter={(value) => {
          //   if (props.validate?.required ? value.length > 0 : value.length >= 0) {
          //     props.setValue(value);
          //   } else {
          //     props.setValue(props.value);
          //   }
          //   if (props.onChange) {
          //     props.onChange(value);
          //   }
          //   props.setEditMode(false);
          // }}
          validate={props.validate}
          asyncValidate={props.asyncValidate}
        />
      </Form>
    );
  }
  return (
    <Form style={{ width: '100%' }}>
      <Input
        focus={true}
        inputstyle={[props.textStyle] as any}
        containerStyle={{ margin: 0, padding: 0, minHeight: 20, width: '100%' }}
        name={'dummy'}
        type={props.type}
        initialValue={props.value}
        pickerItems={props.pickerItems}
        defaultMenuIsOpen={true}
        isSearchable={props.isSearchable}
        onChange={(value) => {
          if (props.type === 'picker') {
            props.setValue(value);
            props.setEditMode(false);
            if (props.onChange) {
              props.onChange(value);
            }
          }
        }}
        onBlur={(value, element) => {
          if (props.validate?.required ? value.length > 0 : value.length >= 0) {
            props.setValue(value);
          } else {
            props.setValue(props.value);
          }
          if (props.onChange) {
            props.onChange(value);
          }
          props.setEditMode(false);
        }}
        // onPressEnter={(value) => {
        //   if (props.validate?.required ? value.length > 0 : value.length >= 0) {
        //     props.setValue(value);
        //   } else {
        //     props.setValue(props.value);
        //   }
        //   if (props.onChange) {
        //     props.onChange(value);
        //   }
        //   props.setEditMode(false);
        // }}
        validate={props.validate}
        asyncValidate={props.asyncValidate}
      />
    </Form>
  );
};

interface IProps {
  value: string | number | moment.Moment | IDateRange | string[] | null;
  style?: ViewStyle;
  textStyle?: TextStyle;
  containerStyle?: ViewStyle;
  inputStyle?: any;
  type?:
    | 'text'
    | 'picker'
    | 'rich-text-editor'
    | 'date-time-picker'
    | 'date-picker'
    | 'date-range-picker'
    | 'multi-picker'
    | 'time-picker'
    | 'number'
    | 'currency'
    | 'percent'
    | 'remainder-picker';
  pickerItems?: ListValueMap[];
  isSearchable?: boolean;
  emptyText?: string;
  renderComponent?: (value: string | number, empty: boolean) => React.ReactNode | null;
  onChange?: (value: string | number | moment.Moment | IDateRange | string[] | null) => void;
  onBlur?: (
    value: string | number | moment.Moment | IDateRange | string[] | null,
    textInputElement: any
  ) => void;
  onFocus?: () => void;
  onPressEnter?: () => void;
  format?: (value: any) => string;
  disableClear?: boolean;
  readonly?: boolean;
  validate?: ValidationRules;
  asyncValidate?: (value: string) => Promise<string | null>;
  selectableStartDate?: moment.Moment | null;
  selectableEndDate?: moment.Moment | null;
  showSeconds?: boolean;
  editable?: boolean;
  onClickWhenNotEditable?: () => void;
  ellipsis?: boolean;
  disallowCreateNewPickerItem?: boolean;
  noOptionsMessage?: string;
}

const EditableText = React.memo((props: IProps) => {
  const [editMode, setEditMode] = useState(false);
  const [value, setValue] = useState<
    string | number | moment.Moment | IDateRange | string[] | null
  >();

  //(重要) 当初は useState(props.value) で初期値を設定していたのだが、そうするとタスク一覧画面でタスクを選択しまくると、
  //       なぜかタイトルの表示が切り替わらず、前回のタスクの情報が表示されてしまう問題が発生した。
  //       Reactのバグな気がするが、EditableTextが様々な箇所で呼ばれた結果、タイミングで古い情報での更新が発生するように見える。
  //       useEffectでレンダリング終了後に初期化するようにしてみたところ、正常に動作するようになった。
  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  if (props.editable === false) {
    return (
      <View style={[props.style]}>
        <Label
          value={value ?? null}
          editMode={editMode}
          setEditMode={setEditMode}
          type={props.type}
          pickerItems={props.pickerItems}
          textStyle={props.textStyle}
          containerStyle={props.containerStyle}
          emptyText={props.emptyText}
          renderComponent={props.renderComponent}
          showSeconds={props.showSeconds}
          editable={props.editable}
          onClickWhenNotEditable={props.onClickWhenNotEditable}
          format={props.format}
          ellipsis={props.ellipsis}
        />
      </View>
    );
  }

  return (
    <View style={[props.style]}>
      {!editMode ? (
        <Label
          value={value ?? null}
          editMode={editMode}
          setEditMode={setEditMode}
          type={props.type}
          pickerItems={props.pickerItems}
          textStyle={props.textStyle}
          containerStyle={props.containerStyle}
          emptyText={props.emptyText}
          renderComponent={props.renderComponent}
          showSeconds={props.showSeconds}
          editable={props.editable}
          format={props.format}
          ellipsis={props.ellipsis}
        />
      ) : (
        <EditForm
          value={value ?? ''}
          setValue={setValue}
          setEditMode={setEditMode}
          textStyle={props.textStyle}
          inputStyle={props.inputStyle}
          type={props.type || 'text'}
          pickerItems={props.pickerItems}
          isSearchable={props.isSearchable}
          onChange={props.onChange}
          onBlur={props.onBlur}
          onPressEnter={props.onPressEnter}
          validate={props.validate}
          asyncValidate={props.asyncValidate}
          selectableStartDate={props.selectableStartDate}
          selectableEndDate={props.selectableEndDate}
          disableClear={props.disableClear}
          noOptionsMessage={props.noOptionsMessage}
          disallowCreateNewPickerItem={props.disallowCreateNewPickerItem}
          onFocus={props.onFocus}
        />
      )}
    </View>
  );
});

export default EditableText;
