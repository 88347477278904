export default class TimeUtil {
  private constructor() {}

  public static sleep(msec: number): Promise<void> {
    return new Promise<void>((resolve) => {
      setTimeout(() => resolve(), msec);
    });
  }

  public static format(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    const modMinutes = minutes % 60;
    if (hours === 0) {
      return `${modMinutes}分`;
    }
    if (hours > 0 && modMinutes === 0) {
      return `${hours}時間`;
    }
    return `${hours}時間 ${modMinutes}分`;
  }

  public static formatWithDay(minutes: number): string {
    const days = Math.floor(minutes / 60 / 24);
    const hours = Math.floor(minutes / 60) - days * 24;
    const modMinutes = minutes % 60;
    let result = '';
    if (days > 0) {
      result = result + `${days}日`;
    }
    if (hours > 0) {
      if (result.length > 0) {
        result = result + ' ';
      }
      result = result + `${hours}時間`;
    }
    if (modMinutes > 0) {
      if (result.length > 0) {
        result = result + ' ';
      }
      result = result + `${modMinutes}分`;
    }
    return result;
  }

  public static formatShort(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    const modMinutes = minutes % 60 >= 10 ? minutes % 60 : `0${minutes % 60}`;
    if (hours === 0) {
      return `0:${modMinutes}`;
    }
    if (hours > 0 && modMinutes === 0) {
      return `${hours}:00`;
    }
    return `${hours}:${modMinutes}`;
  }

  public static formatSecToShortTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const modMinutes = minutes % 60 >= 10 ? minutes % 60 : `0${minutes % 60}`;
    if (hours === 0) {
      return `0:${modMinutes}`;
    }
    if (hours > 0 && modMinutes === 0) {
      return `${hours}:00`;
    }
    return `${hours}:${modMinutes}`;
  }

  public static formatForTask(sec: number | null | undefined): string {
    if (sec !== null && sec !== undefined) {
      if (sec >= 0) {
        const hours = Math.floor(sec / 3600);
        const modMinutes = Math.floor(sec / 60) % 60;
        const modSec = Math.floor(sec % 60);
        const adjustedMinutes = modMinutes < 10 ? `0${modMinutes}` : modMinutes;
        const adjustedSec = modSec < 10 ? `0${modSec}` : modSec;
        return `${hours}:${adjustedMinutes}:${adjustedSec}`;
      } else {
        const absSec = Math.abs(sec);
        const hours = Math.floor(absSec / 3600);
        const modMinutes = Math.floor(absSec / 60) % 60;
        const modSec = Math.floor(absSec % 60);
        const adjustedMinutes = modMinutes < 10 ? `0${modMinutes}` : modMinutes;
        const adjustedSec = modSec < 10 ? `0${modSec}` : modSec;
        return `-${hours}:${adjustedMinutes}:${adjustedSec}`;
      }
    }
    return '-';
  }

  public static stringToTimeWithoutFormat(
    value: string | null | undefined,
    unlimitHour?: boolean
  ): string {
    if (value === null || value === undefined || value.length === 0) {
      return '';
    }

    const adjustedValue = value
      .replace(/[^0-9:：０-９]/g, '')
      .replaceAll('０', '0')
      .replaceAll('１', '1')
      .replaceAll('２', '2')
      .replaceAll('３', '3')
      .replaceAll('４', '4')
      .replaceAll('５', '5')
      .replaceAll('６', '6')
      .replaceAll('７', '7')
      .replaceAll('８', '8')
      .replaceAll('９', '9')
      .replaceAll('：', ':');

    if (unlimitHour === true) {
      if (/^[0-9０-９]*(:[0-5０-５]?[0-9０-９]?)?$/.test(adjustedValue)) {
        return adjustedValue;
      }
    } else {
      if (
        /^([2２][0-3０-３]|[1１][0-9０-９]|[0０]?[0-9０-９])?(:[0-5０-５]?[0-9０-９]?)?$/.test(
          adjustedValue
        )
      ) {
        return adjustedValue;
      }
    }

    return '';
  }

  public static isValidInputTime(value: string, unlimitHour?: boolean): boolean {
    if (value === null || value === undefined || value.length === 0) {
      return true;
    }

    const adjustedValue = value
      .replace(/[^0-9:：０-９]/g, '')
      .replaceAll('０', '0')
      .replaceAll('１', '1')
      .replaceAll('２', '2')
      .replaceAll('３', '3')
      .replaceAll('４', '4')
      .replaceAll('５', '5')
      .replaceAll('６', '6')
      .replaceAll('７', '7')
      .replaceAll('８', '8')
      .replaceAll('９', '9')
      .replaceAll('：', ':');

    if (unlimitHour === true) {
      if (/^[0-9０-９]*(:[0-5０-５]?[0-9０-９]?)?$/.test(adjustedValue)) {
        return true;
      }
    } else {
      if (
        /^([2２][0-3０-３]|[1１][0-9０-９]|[0０]?[0-9０-９])?(:[0-5０-５]?[0-9０-９]?)?$/.test(
          adjustedValue
        )
      ) {
        return true;
      }
    }

    return false;
  }

  public static formatStringToTime(
    value: string | null | undefined,
    unlimitHour?: boolean
  ): string {
    const adjustedValue = this.stringToTimeWithoutFormat(value, unlimitHour);
    if (adjustedValue === '') {
      return '';
    }

    const splited = adjustedValue.split(':');
    if (splited.length === 1) {
      if (Number(splited[0]) < 10) {
        return `00:0${Number(splited[0])}`;
      }
      return `00:${Number(splited[0])}`;
    }
    if (splited.length === 2) {
      if (Number(splited[0]) < 10) {
        if (Number(splited[1]) < 10) {
          return `0${Number(splited[0])}:0${Number(splited[1])}`;
        }
        return `0${Number(splited[0])}:${Number(splited[1])}`;
      }
      if (Number(splited[1]) < 10) {
        return `${Number(splited[0])}:0${Number(splited[1])}`;
      }
      return `${Number(splited[0])}:${Number(splited[1])}`;
    }
    return '';
  }

  public static parseTimeToSec(value: string | null | undefined, unlimitHour?: boolean): number {
    const adjustedValue = this.stringToTimeWithoutFormat(value, unlimitHour);
    if (adjustedValue === '') {
      return 0;
    }

    const splited = adjustedValue.split(':');
    if (splited.length === 1) {
      return Number(splited[0]) * 60;
    }
    if (splited.length === 2) {
      return Number(splited[0]) * 60 * 60 + Number(splited[1]) * 60;
    }
    return 0;
  }
}
