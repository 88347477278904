import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import styled, { ThemeContext } from 'styled-components/native';
import moment from 'moment-timezone';
import {
  GraphDataTask,
  GraphDataTaskItemType,
  WorkingHistory,
  useCalendarEventWorkingHistoriesQuery,
  useTaskWorkingHistoriesForSummaryQuery,
} from '../../../../../../../../graphql/api/API';
import { LoginUserContext } from '../../../../../../../../modules/auth/LoginUserContext';
import Form from '../../../../../../..//presentational/atoms/form';
import Typography, { TypographyType } from '../../../../../../../presentational/atoms/typography';
import { IStyleTheme, IThemePart } from '../../../../../../../theme';
import { View } from 'react-native';
import VirtualizedFlatList from '../../../../../../../presentational/atoms/list2/virtualized-flat-list';
import Avatar from '../../../../../../../presentational/atoms/avatar';
import { useHistory } from 'react-router';
import UrlUtil from '../../../../../../../../util/UrlUtil';
import Modal from '../../../../../../../presentational/molecules/modal';
import ToolTip from 'react-portal-tooltip';
import Clipboard from '@react-native-clipboard/clipboard';
import CopyIcon from '../../../../../../../presentational/molecules/image-icon/copy';
import TimeUtil from '../../../../../../../../util/TimeUtil';

const Item = styled.View`
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  min-width: 200px;
  border-bottom-width: 1px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
`;

interface IWorkingHistoriesModalProps {
  graphTask: GraphDataTask
  targetTeamId?: string | null
  targetMemberId?: string | null
  start: moment.Moment
  end: moment.Moment
  showModal: boolean;
  onCloseModal: () => void;
}

const WorkingHistoriesModal = (props: IWorkingHistoriesModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const history = useHistory();
  const { data: taskData } = useTaskWorkingHistoriesForSummaryQuery({
    variables: {
      taskId: props.graphTask.taskId,
      start: props.start.startOf('day').toISOString(),
      end:  props.end.endOf('day').toISOString(),
      teamId: props.targetTeamId,
      memberId: props.targetMemberId,
      offset: 0,
      limit: 100,
    },
    fetchPolicy: 'network-only',
    skip: props.graphTask.itemType !== GraphDataTaskItemType.Task || props.showModal !== true
  });
  const { data: calendarEventData } = useCalendarEventWorkingHistoriesQuery({
    variables: {
      calendarEventId: props.graphTask.taskId,
      start: props.start.startOf('day').toISOString(),
      end:  props.end.endOf('day').toISOString(),
    },
    fetchPolicy: 'network-only',
    skip: props.graphTask.itemType !== GraphDataTaskItemType.CalendarEvent || props.showModal !== true
  });

  const renderItem = useCallback((workingHistory: WorkingHistory, index: number) => {
    const refOuter = useRef<HTMLSpanElement>(null);
    const [showTooltip, setShowTooltip] = useState(false);
    const [workHistoryUrlCopied, setWorkHistoryMemoCopied] = useState(false);

    const memoSummary = workingHistory.memo
    ? JSON.parse(workingHistory.memo)
        .blocks.map((b: any) => b.text)
        .join(' ')
    : undefined;

  const memo = workingHistory.memo
    ? JSON.parse(workingHistory.memo).blocks.map((b: any) => b.text)
    : undefined;

    useEffect(() => {
      if (workHistoryUrlCopied) {
        setTimeout(() => setWorkHistoryMemoCopied(false), 1000);
      }
    }, [workHistoryUrlCopied, setWorkHistoryMemoCopied]);
  
    const isMemoEmpty = ((memo?.length ?? 0) === 0 || (memo?.length === 1 && memo[0]?.length === 0))

    return (
      <Item>
        <View style={{ width: 200, flexDirection: 'row', alignItems: 'center' }}>
          <Avatar
            size={30}
            imageUrl={workingHistory.workingMember.profileImageUrl}
            name={workingHistory.workingMember.name ?? ''}
            containerStyle={{ marginRight: 6 }}
          />
          <Typography
            variant={TypographyType.Normal}
            ellipsis={true}
            style={{
              width: 160,
              fontSize: 13,
              color: themeContext.colors.textColor,
              textAlign: 'left',
            }}>
            {workingHistory.workingMember.name}
          </Typography>
        </View>
        <Typography
          variant={TypographyType.Normal}
          ellipsis={true}
          style={{
            width: 150,
            fontSize: 13,
            color: themeContext.colors.textColor,
            textAlign: 'left',
          }}>
          {`${moment(workingHistory.start).format('YYYY/MM/DD HH:mm')}`}
        </Typography>
        <Typography
          variant={TypographyType.Normal}
          ellipsis={true}
          style={{
            width: 150,
            fontSize: 13,
            color: themeContext.colors.textColor,
            textAlign: 'left',
          }}>
          {workingHistory.end ? `${moment(workingHistory.end).format('YYYY/MM/DD HH:mm')}` : '-'}
        </Typography>     
        <Typography
          variant={TypographyType.Normal}
          ellipsis={true}
          style={{
            width: 150,
            fontSize: 13,
            color: themeContext.colors.textColor,
            textAlign: 'left',
          }}>
          {workingHistory.end
        ? TimeUtil.formatForTask(
            moment(workingHistory.end).diff(moment(workingHistory.start), 'seconds')
          )
        : '-'}
        </Typography>     
        {!isMemoEmpty ? (
          <div
            id={`working-history-list-memo-tooltip-${workingHistory.id}`}
            ref={refOuter as any}
            style={{
              flex: 1,
              alignItems: 'flex-start',
              justifyContent: 'center',
              marginLeft: 5,
              overflow: 'hidden',
            }}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}>
            <Typography
              variant={TypographyType.Normal}
              ellipsis={true}
              style={{ fontSize: 13, textAlign: 'left', lineHeight: 17, display: 'block', width: 280}}
            >
              {memoSummary ?? '-'}
            </Typography>
            <ToolTip
              active={showTooltip && memoSummary.length > 30}
              style={{
                style: {
                  zIndex: 10000
                },
                arrowStyle: {
                }
              }}
              position="bottom"
              arrow="center"
              tooltipTimeout={1}
              parent={`#working-history-list-memo-tooltip-${workingHistory.id}`}>
              {memo.map((line: string) => (
                <div>{line}</div>
              ))}
            </ToolTip>
          </div>
        ) : (
          <Typography
            variant={TypographyType.Normal}
            ellipsis
            style={{ fontSize: 13, textAlign: 'center', lineHeight: 17, display: 'block', width: 280 }}>
            {'-'}
          </Typography>
        )}
        {!isMemoEmpty && 
          <View style={{ flexDirection: 'row' }}>
            <CopyIcon
              size={20}
              containerStyle={{ marginLeft: 5 }}
              onPress={async () => {
                Clipboard.setString(memo.join('\n'));
                setWorkHistoryMemoCopied(true);
              }}>
              <Typography variant={TypographyType.Normal} style={{ fontSize: 14 }}>
                {workHistoryUrlCopied ? 'コピーしました' : 'コピーする'}
              </Typography>
            </CopyIcon>
          </View>
        }
      </Item>
    );
  }, []);

  return (
    <Modal
      title={'作業履歴一覧'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <Form style={{ paddingVertical: 20 }}>
        <View style={{ marginTop: 20, maxWidth: 1200, width: '100%' }}>
          <VirtualizedFlatList
            style={{
              maxHeight: 250,
              height: 'auto',
              borderWidth: 1,
              borderColor: themeContext.colors.separator,
              overflow: 'scroll'
            }}
            showVirticalScrollBar
            items={taskData?.taskWorkingHistoriesForSummary ?? calendarEventData?.calendarEventWorkingHistories ?? []}
            onPress={(his) => {
              if ((his as WorkingHistory)?.task?.id) {
                history.push(UrlUtil.createTaskDetailUrlById((his as WorkingHistory).task!.id!));
              } else if ((his as WorkingHistory)?.calendarEvent?.id) {
                history.push(UrlUtil.createCalendarEventDetailUrlById((his as WorkingHistory).calendarEvent!.id!));
              }
            }}
            renderItem={renderItem as any}
            headerElement={
              <Item>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    width: 200,
                    fontSize: 13,
                    color: themeContext.colors.textColor,
                    textAlign: 'left',
                    fontWeight: '600',
                  }}>
                  作業者
                </Typography>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    width: 150,
                    fontSize: 13,
                    color: themeContext.colors.textColor,
                    textAlign: 'left',
                    fontWeight: '600',
                  }}>
                  作業開始日時
                </Typography>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    width: 150,
                    fontSize: 13,
                    color: themeContext.colors.textColor,
                    textAlign: 'left',
                    fontWeight: '600',
                  }}>
                  作業終了日時
                </Typography>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    width: 150,
                    fontSize: 13,
                    color: themeContext.colors.textColor,
                    textAlign: 'left',
                    fontWeight: '600',
                  }}>
                  作業時間
                </Typography>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    width: 300,
                    fontSize: 13,
                    color: themeContext.colors.textColor,
                    textAlign: 'left',
                    fontWeight: '600',
                  }}>
                  作業メモ
                </Typography>
              </Item>
            }
            getKey={(item) => (item as any).id}
            itemHeight={43}
          />
        </View>
      </Form>
    </Modal>
  );
};

export default WorkingHistoriesModal;
