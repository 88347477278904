import moment from 'moment-timezone';
import React, { useContext, useEffect, useState, useMemo, useCallback } from 'react';
import { TouchableOpacity, View } from 'react-native';
//@ts-ignore
import { ThemeContext } from 'styled-components/native';
import {
  type CalendarWorkingHistory,
  CalendarWorkingHistoryDocument,
  LatestWorkingHistoryDocument,
  type Member,
  MemberStatus,
  ProjectWorkingTimeSecDocument,
  TaskWorkingHistorySummaryGroupByAssineeDocument,
  type WorkingHistory,
  WorkingHistoryDocument,
  WorkingHistoryForSummaryDocument,
  useCreateWorkingHistoryMutation,
  useMeQuery,
  useOrganizationQuery,
  useTeamMembersQuery,
} from '../../../../../graphql/api/API';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import OrganizationUtil from '../../../../../util/OrganizationUtil';
import Avatar from '../../../../presentational/atoms/avatar';
import Button from '../../../../presentational/atoms/button';
import EditableText from '../../../../presentational/atoms/editable-text';
import useHover from '../../../../presentational/atoms/editable-text/use-hover';
import Form from '../../../../presentational/atoms/form';
import Input, { type ListValueMap } from '../../../../presentational/atoms/input';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import Modal from '../../../../presentational/molecules/modal';
import type { IThemePart } from '../../../../theme';

interface ICopyWorkingHistoryModalProps {
  workingHistory: CalendarWorkingHistory | null;
  showModal: boolean;
  onPressYes: () => Promise<void> | void;
  onCloseModal: () => void;
}

const CopyWorkingHistoryModal = React.memo((props: ICopyWorkingHistoryModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, __] = useContext(LoginUserContext);
  const [start, setStart] = useState<moment.Moment>(moment(props.workingHistory?.start));
  const [end, setEnd] = useState<moment.Moment | null>(
    props.workingHistory?.end ? moment(props.workingHistory?.end) : null
  );
  const [memo, setMemo] = useState<string | null>(props.workingHistory?.memo || null);
  const [targetMemberId, setTargetMemberId] = useState(props.workingHistory?.workingMember.id)
  const [hoverRef, isHover] = useHover();
  const [showEditor, setShowEditor] = useState(false);
  const [focusEndDateTimeInput, setFocusEndDateTimeInput] = useState(false);
  const { data: organizationData, loading: organizationLoading } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId,
    },
    skip: !props.workingHistory || !props.workingHistory?.task
  });
  const {data: meData, loading: meLoading} = useMeQuery({
    skip: !props.workingHistory || !props.workingHistory?.task
  })
  const fetchTeamMembers = useTeamMembersQuery({
    variables: {
      teamId: props.workingHistory?.task?.project.team.id ?? props.workingHistory?.calendarEvent?.teamId
    },
    skip: !props.workingHistory || !props.workingHistory?.task
  })
  const [updateWokingHistory, _] = useCreateWorkingHistoryMutation({
    variables: {
      taskId: props.workingHistory?.task?.id!,
      input: {
        start: start?.toISOString(),
        end: end?.toISOString(),
        memo: memo,
        targetMemberId: targetMemberId
      },
    },
    update: (cache, result) => {
      cache.modify({
        fields: {
          workingHistoriesSpecifyTermForCalendar(existing = [], { storeFieldName }) {
            const newWorkingHistory = cache.writeQuery({
              data: result.data!.createWorkingHistory,
              query: WorkingHistoryDocument,
            });
            const targetMemberData = existing.filter((d: any) => d.memberId === (targetMemberId?.id ?? loginUser?.id))[0];
            if((targetMemberData?.length ?? 0) === 0){
              return [...existing, {
                memberId: result.data!.createWorkingHistory?.workingMember.id,
                workingHistories: [newWorkingHistory]
              }];
            }
            return existing.map((data: any) => {
              if(data.memberId !== (targetMemberId?.id ?? loginUser?.id)){
                return data;
              }
              return {
                memberId: data.memberId,
                workingHistories: [...targetMemberData.workingHistories, newWorkingHistory]
              }
            })
          },
          workingHistoriesSpecifyTermForSummary(existing = [], { storeFieldName }) {
            const newWorkingSchedule = cache.writeQuery({
              data: result.data!.createWorkingHistory,
              query: WorkingHistoryForSummaryDocument,
            });
            return [...existing, newWorkingSchedule];
          },
        },
      });
    },
    refetchQueries: props.workingHistory?.task
      ? [
          {
            query: LatestWorkingHistoryDocument,
          },
        ]
      : [
        {
          query: LatestWorkingHistoryDocument,
        },
      ],
  });

  useEffect(() => {
    setStart(moment(props.workingHistory?.start));
    setEnd(props.workingHistory?.end ? moment(props.workingHistory.end) : null);
    setMemo(props.workingHistory?.memo || null);
    setTargetMemberId(props.workingHistory?.workingMember.id)
  }, [props.workingHistory, props.showModal]);

  const isContentEmpty =
    props.workingHistory?.memo === null ||
    (props.workingHistory?.memo &&
      JSON.parse(props.workingHistory?.memo).blocks &&
      JSON.parse(props.workingHistory?.memo).blocks.length > 0 &&
      JSON.parse(props.workingHistory?.memo).blocks[0]?.text === '');

  if(!props.workingHistory || !props.workingHistory.task){
    return <></>
  }

  if (organizationLoading || fetchTeamMembers.loading || !fetchTeamMembers.data || !meData?.me) {
    return <></>;
  }
    
  return (
    <Modal
      title={'作業履歴をコピーする'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <View style={{ flexDirection: 'column', justifyContent: 'center', paddingTop: 20 }}>
        <Form>
        {meData.me.proxyEditWorkingDataRole && fetchTeamMembers.data.teamMembers!.length > 0 && (
          <View style={{ flexDirection: 'row', alignItems: 'center', zIndex: 4, marginBottom: 20 }}>
            <View style={{ minWidth: 130 }}>
                <Typography variant={TypographyType.Normal} style={{ fontSize: 18, lineHeight: 22 }}>
                  作業者
                </Typography>
            </View>
            <View style={{flex: 1}}>
              <EditableText
                value={targetMemberId}
                type={'picker'}
                isSearchable={true}
                pickerItems={
                  fetchTeamMembers.data.teamMembers!.filter((member) => member?.memberStatus === MemberStatus.Active)
                    .map((member) => {
                      return {
                        label: member!.name,
                        value: member!.id!,
                        imageUrl: member!.profileImageUrl,
                      } as ListValueMap;
                    })
                    .sort((a, b) => (a.value === loginUser?.id ? -1 : 1)) // 自分を先頭に表示
                }
                renderComponent={() => {
                  const member = fetchTeamMembers.data!.teamMembers!.filter(mem => mem!.id === targetMemberId)?.[0]
                  if(!member){
                    return <></>
                  }
                  return (
                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                      <Avatar
                        size={28}
                        name={member?.name!}
                        imageUrl={member?.profileImageUrl}
                      />
                      <Typography variant={TypographyType.Normal} style={{ marginLeft: 5 }}>
                        {member?.name}
                      </Typography>
                    </View>
                  );
                }}
                onChange={(items) => {
                  setTargetMemberId(items as string)
                }}
                onBlur={(items) => {
                  setTargetMemberId(items as string)
                }}
                textStyle={{ fontSize: 18 }}
              />
            </View>
            </View>
          )}
          {!(meData.me.proxyEditWorkingDataRole && fetchTeamMembers.data.teamMembers!.length > 0) && OrganizationUtil.isTeamPlan(organizationData?.organization!) && (
            <View style={{ flexDirection: 'row', alignItems: 'center', zIndex: 4, marginBottom: 20 }}>
              <View style={{ minWidth: 130 }}>
                  <Typography variant={TypographyType.Normal} style={{ fontSize: 18, lineHeight: 22 }}>
                    作業者
                  </Typography>
              </View>
              <View style={{flex: 1}}>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <Avatar
                    size={28}
                    name={props.workingHistory.workingMember?.name!}
                    imageUrl={props.workingHistory.workingMember?.profileImageUrl}
                  />
                  <Typography variant={TypographyType.Normal} style={{ marginLeft: 5 }}>
                    {props.workingHistory.workingMember?.name}
                  </Typography>
                </View>
              </View>
            </View>
          )}
          <View style={{ flexDirection: 'row', alignItems: 'center', zIndex: 3 }}>
            <View style={{ minWidth: 130 }}>
              <Typography variant={TypographyType.Normal} style={{ fontSize: 16, lineHeight: 22 }}>
                作業開始日時
              </Typography>
            </View>
            <EditableText
              value={start}
              type={'date-time-picker'}
              textStyle={{ fontSize: 18, lineHeight: 22 }}
              containerStyle={{
                borderBottomWidth: 1,
                borderBottomColor: themeContext.colors.textColor,
              }}
              disableClear={true}
              onChange={(value) => {
                setStart(value as moment.Moment);
              }}
            />
          </View>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 20,
              zIndex: focusEndDateTimeInput ? 4 : 2,
            }}>
            <View style={{ minWidth: 130 }}>
              <Typography variant={TypographyType.Normal} style={{ fontSize: 18, lineHeight: 22 }}>
                作業終了日時
              </Typography>
            </View>
            {props.workingHistory.end ? (
              <EditableText
                value={end}
                type={'date-time-picker'}
                textStyle={{ fontSize: 18, lineHeight: 22 }}
                containerStyle={{
                  borderBottomWidth: 1,
                  borderBottomColor: themeContext.colors.textColor,
                }}
                disableClear={true}
                onChange={(value) => {
                  setEnd(value as moment.Moment);
                }}
                onFocus={() => setFocusEndDateTimeInput(true)}
                onBlur={() => setFocusEndDateTimeInput(false)}
              />
            ) : (
              <Typography
                variant={TypographyType.Normal}
                style={{ fontSize: 13, textAlign: 'center', lineHeight: 17 }}>
                -
              </Typography>
            )}
          </View>
          {start?.isAfter(end || moment()) && (
            <View style={{ marginTop: 10 }}>
              <Typography
                variant={TypographyType.Description}
                style={{ color: themeContext.colors.error }}>
                開始日時は、終了日時よりも前にしてください
              </Typography>
            </View>
          )}
          {props.workingHistory.end === null && !start && (
            <View style={{ marginTop: 10 }}>
              <Typography
                variant={TypographyType.Description}
                style={{ color: themeContext.colors.error }}>
                開始日時を入力してください
              </Typography>
            </View>
          )}
          {props.workingHistory.end !== null && (!start || !end) && (
            <View style={{ marginTop: 10 }}>
              <Typography
                variant={TypographyType.Description}
                style={{ color: themeContext.colors.error }}>
                開始日時・終了日時を入力してください
              </Typography>
            </View>
          )}
          <View
            style={
              {
                flexDirection: 'row',
                marginTop: 20,
                zIndex: 2,
                alignItems: 'flex-start',
              } as any
            }>
            <View style={{ minWidth: 130 }}>
              <Typography variant={TypographyType.Normal} style={{ fontSize: 18, lineHeight: 22 }}>
                作業メモ
              </Typography>
            </View>
            <View ref={hoverRef as any} style={{ minWidth: 300 }}>
              {showEditor ? (
                <Form style={{ zIndex: 2 }}>
                  <Input
                    initialValue={props.workingHistory.memo || ''}
                    name={'description'}
                    type={'rich-text-editor'}
                    showToolBarAlways={true}
                    autoFocus={true}
                    onChange={(value) => {
                      setMemo(value);
                    }}
                  />
                </Form>
              ) : (
                <TouchableOpacity onPress={() => setShowEditor(true)} style={{ zIndex: 2 }}>
                  <Form>
                    <div
                      style={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        backgroundColor: themeContext.colors.separator,
                        zIndex: 2,
                        opacity: isHover ? 0.5 : 0,
                        cursor: 'pointer',
                      }}
                    />
                    <View
                      style={{
                        opacity: isContentEmpty ? 0.2 : 1,
                        minHeight: 50,
                        paddingHorizontal: 10,
                      }}>
                      <Input
                        initialValue={
                          isContentEmpty
                            ? `{
                  "blocks": [
                    {
                      "key": "dummy",
                      "text": "作業メモを追加...",
                      "type": "unstyled",
                      "depth": 0,
                      "inlineStyleRanges": [],
                      "entityRanges": [],
                      "data": {}
                    }
                  ],
                  "entityMap": {}
                }
                `
                            : props.workingHistory.memo!
                        }
                        name={'description'}
                        type={'rich-text-editor'}
                        showToolBarAlways={false}
                        multiline={true}
                        readonly={true}
                      />
                    </View>
                  </Form>
                </TouchableOpacity>
              )}
            </View>
          </View>
          <View
            style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
            <Button
              text={'コピーする'}
              style={{ minWidth: 100, marginRight: 10, marginVertical: 20 }}
              isDisabled={
                props.workingHistory.end !== null ? start?.isAfter(end) || !start || !end : !start
              }
              onPress={async () => {
                await updateWokingHistory();
                props.onPressYes();
              }}
            />
            <Button
              text={'キャンセル'}
              style={{
                minWidth: 100,
                marginLeft: 10,
                marginVertical: 20,
                backgroundColor: '#FFFFFF',
                borderColor: themeContext.colors.primary,
                borderWidth: 1,
                borderRadius: 5,
                height: 40,
              }}
              textStyle={{ color: themeContext.colors.primary }}
              disableValidate={true}
              onPress={() => {
                props.onCloseModal();
              }}
            />
          </View>
        </Form>
      </View>
    </Modal>
  );
});

export default CopyWorkingHistoryModal;
