import moment from 'moment-timezone';
import React, { useContext, useEffect, useMemo, useState } from 'react';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../../theme';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import Input from '../../../../presentational/atoms/input';
import Form from '../../../../presentational/atoms/form';
import { useHistory } from 'react-router';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import LoginUtil from '../../../../../modules/auth/LoginUtil';
import {
  Member,
  Organization,
  Plan,
  Project,
  ProjectCompleteFilter,
  ProjectFavoriteFilter,
  ProjectInitialViewType,
  ProjectSortKey,
  SortKey,
  SortOrder,
  useMeQuery,
  useMySettingsQuery,
  useOrganizationClientsQuery,
  useOrganizationQuery,
  useTeamMembersQuery,
  useTeamProjectsQuery,
  useTeamQuery,
  useUpdateProjectSortNoInListMutation,
} from '../../../../../graphql/api/API';
import ProjectCreateDialog from '../project-create-dialog';
import Modal from '../../../../presentational/molecules/modal';
import VirtualizedFlatList, {
  GlobalDragContextProvider,
} from '../../../../presentational/atoms/list2/virtualized-flat-list';
import { View, TouchableOpacity, Text } from 'react-native';
import ColorUtil from '../../../../../util/ColorUtil';
import EnumUtil from '../../../../../util/EnumUtil';
import Checkbox from '../../../../presentational/atoms/checkbox';
import * as Cookies from 'js-cookie';
import PlusIcon from '../../../../presentational/molecules/image-icon/plus';
import SearchIcon from '../../../../presentational/molecules/image-icon/search';
import * as _ from 'lodash';
import ResizableColumn from '../resizable-column';
import MultiPickerFilter from '../../../../presentational/atoms/multi-picker-filter';
import ErrorMessageModal from '../../../../container/web/organisms/error-message-modal';
import CaretDownIcon from '../../../../presentational/molecules/image-icon/caret-down';
import CaretUpIcon from '../../../../presentational/molecules/image-icon/caret-up';
import when from '../../../../../lang-extention/When';
import CaretSetIcon from '../../../../presentational/molecules/image-icon/caret-set';

const Container = styled.View`
  z-index: 1;
`;
// 本当はoverflow-xはvisibleにしたいのだが、CSSの仕様で、overflow-yを指定した場合には、xの挙動もそちらで上書きされるため実現できない

interface IProjectItemProps extends IStyleTheme {
  isComplete: boolean;
}

const ProjectItem = styled.View<IProjectItemProps>`
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  min-width: 200px;
  border-bottom-width: 1px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
  background-color: ${(props: IProjectItemProps) =>
    props.isComplete
      ? ColorUtil.lignten(props.theme.colors.separator, 3)
      : props.theme.colors.baseColor};
`;

const ProjectAddButton = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  padding-right: 5px;
  padding-left: 5px;
`;

const Search = styled.View``;

interface IListProps {
  organization: Organization;
  teamId: string;
  me: Member;
  setShowDialog: (value: boolean) => void;
  isWidthOver230: boolean;
}

const List = React.memo((props: IListProps) => {
  const pageSize = 50;
  const [searchProjectName, setSearchProjectName] = useState('');
  const [searchProjectNameLive, setSearchProjectNameLive] = useState('');
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const { push } = useHistory();
  const [showCompleteProject, setShowCompleteProject] = useState(false);
  const [showFavoriteProjectOnly, setShowFavoriteProjectOnly] = useState(
    Cookies.get(`PROJECT_FILTER_FAVORITE`) === 'true'
  );
  const [focusSearchClient, setForcusSearchClient] = useState(false);
  const [clientIds, setClientIds] = useState<string[]>([]);
  const [focusSearchMember, setForcusSearchMember] = useState(false);
  const [memberIds, setMemberIds] = useState<string[]>([]);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSearchCondition, setShowSearchCondition] = useState(false);
  const [sortKey, setSortKey] = useState<ProjectSortKey>(
    EnumUtil.getEnumValue(ProjectSortKey, Cookies.get(`PROJECT_LIST_SORT_KEY`)) ??
      ProjectSortKey.SortNo
  );
  const [sortOrder, setSortOrder] = useState<SortOrder | null>(
    EnumUtil.getEnumValue(SortOrder, Cookies.get(`PROJECT_LIST_SORT_ORDER`)) ?? null
  );

  const { loading: mySettingsLoading, data: mySettingsData } = useMySettingsQuery();

  const teamResult = useTeamQuery({
    variables: {
      id: props.teamId,
    },
    skip: props.teamId === null,
  });
  const projectsResult = useTeamProjectsQuery({
    variables: {
      teamId: props.teamId,
      offset: 0,
      limit: pageSize,
      input: {
        completeCondition: showCompleteProject
          ? ProjectCompleteFilter.Both
          : ProjectCompleteFilter.Incomplete,
        favoriteCondition: showFavoriteProjectOnly ? ProjectFavoriteFilter.Favorite : null,
        projectName: searchProjectName,
        clientIds: clientIds,
        assineeMemberIds: memberIds,
        sortKey: sortKey,
        sortOrder: sortOrder,
      },
    },
    skip: props.teamId === null,
    fetchPolicy: 'network-only',
  });
  const {
    loading: clientLoading,
    data: clientData,
    error: clientError,
  } = useOrganizationClientsQuery({
    variables: {
      organizationId: loginUser!.organizationId,
    },
    fetchPolicy: 'network-only',
  });
  const {
    loading: memberLoading,
    data: memberData,
    error: memberError,
  } = useTeamMembersQuery({
    variables: {
      teamId: props.teamId,
    },
    fetchPolicy: 'network-only',
  });

  const [updateProjectSortNo, _] = useUpdateProjectSortNoInListMutation();

  const projects = projectsResult.data?.teamProjects ?? [];

  useEffect(() => {
    Cookies.set(`PROJECT_LIST_SORT_KEY`, sortKey, {
      expires: moment().add(10, 'years').toDate(),
    });
  }, [sortKey]);
  useEffect(() => {
    Cookies.set(`PROJECT_LIST_SORT_ORDER`, sortOrder ?? '', {
      expires: moment().add(10, 'years').toDate(),
    });
  }, [sortOrder]);

  if (
    mySettingsLoading ||
    teamResult.loading ||
    teamResult.error ||
    projectsResult.loading ||
    projectsResult.error ||
    clientLoading ||
    clientError
  ) {
    return <></>;
  }

  return (
    <>
      <View
        style={{
          backgroundColor: themeContext.colors.header,
          paddingVertical: 7,
          paddingHorizontal: 3,
        }}>
        <Typography
          variant={TypographyType.SubTitle}
          ellipsis={true}
          style={{ color: '#FFFFFF', fontSize: 16, fontWeight: '600' }}>
          {teamResult.data!.team!.personalTeam
            ? teamResult.data!.team!.organization.plan.code === Plan.Basic ||
              teamResult.data!.team!.organization.plan.code === Plan.Professional
              ? 'プロジェクト一覧'
              : '自分専用プロジェクト'
            : teamResult.data!.team!.name}
        </Typography>
      </View>
      <Search
        style={{
          borderBottomWidth: 1,
          borderColor: themeContext.colors.separator,
          zIndex: 2,
        }}>
        <Form style={{ zIndex: 2, overflow: 'hidden' }}>
          <Input
            name={'searchProject'}
            label={''}
            value={searchProjectName}
            placeholder={props.isWidthOver230 ? 'プロジェクト名で絞り込む' : ''}
            icon={<SearchIcon size={20} containerStyle={{ marginLeft: 10 }} />}
            containerStyle={{ marginVertical: 10 }}
            inputstyle={{ borderWidth: 0, fontSize: 14, minWidth: 200 }}
            inputContainerStyle={{
              marginHorizontal: 10,
              backgroundColor: themeContext.colors.baseColor,
              shadowOffset: {
                width: -1,
                height: -1,
              },
              shadowOpacity: 0.1,
              elevation: 2,
              borderWidth: 1,
              borderRadius: 20,
              borderColor: themeContext.colors.separator,
            }}
            onChange={(value: string) => {
              setSearchProjectNameLive(value);
              if (value?.trim() === '') {
                setSearchProjectName('');
              }
            }}
            onPressEnterIncludeIMEConvert={(value: string) => setSearchProjectName(value)}
            onBlur={(value: string) => setSearchProjectName(value)}
          />
          {searchProjectNameLive?.trim().length > 0 && (
            <Typography
              variant={TypographyType.Description}
              style={{ marginLeft: 10, fontSize: 12, textAlign: 'center', marginBottom: 10 }}>
              Enterキーを押すと絞り込みが行われます
            </Typography>
          )}
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
            }}>
            <View style={{ flex: 1 }}>
              <View style={{ marginBottom: 5 }}>
                <TouchableOpacity
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    marginLeft: 20,
                  }}
                  onPress={() => {
                    setShowFavoriteProjectOnly(!showFavoriteProjectOnly);
                    Cookies.set(
                      `PROJECT_FILTER_FAVORITE`,
                      !showFavoriteProjectOnly ? 'true' : 'false',
                      {
                        expires: moment().add(10, 'years').toDate(),
                      }
                    );
                  }}>
                  <Checkbox
                    size={16}
                    value={showFavoriteProjectOnly}
                    onValueChange={(value) => {
                      setShowFavoriteProjectOnly(!showFavoriteProjectOnly);
                      Cookies.set(
                        `PROJECT_FILTER_FAVORITE`,
                        !showFavoriteProjectOnly ? 'true' : 'false',
                        {
                          expires: moment().add(10, 'years').toDate(),
                        }
                      );
                    }}></Checkbox>
                  <Typography
                    variant={TypographyType.Normal}
                    style={{ marginLeft: 10, fontSize: 12 }}
                    ellipsis={true}>
                    お気に入りのみに絞り込む
                  </Typography>
                </TouchableOpacity>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                }}>
                <TouchableOpacity
                  onPress={() => setShowSearchCondition(!showSearchCondition)}
                  style={{ marginLeft: 20, marginBottom: 5, marginTop: 5, flexDirection: 'row' }}>
                  <Text style={{ color: '#909090' }}>{'検索条件を追加する'}</Text>
                  <Text style={{ color: '#909090', marginLeft: 10 }}>
                    {showSearchCondition ? '▼' : '▲'}
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={{ flexDirection: 'row' }}
                  onPress={() => {
                    if (sortOrder === null || sortOrder === undefined) {
                      setSortKey(ProjectSortKey.ProjectName);
                      setSortOrder(SortOrder.Asc);
                      return;
                    }
                    if (sortKey === ProjectSortKey.ProjectName && sortOrder === SortOrder.Asc) {
                      setSortKey(ProjectSortKey.ProjectName);
                      setSortOrder(SortOrder.Desc);
                      return;
                    }
                    if (sortKey === ProjectSortKey.ProjectName && sortOrder === SortOrder.Desc) {
                      setSortKey(ProjectSortKey.ClientName);
                      setSortOrder(SortOrder.Asc);
                      return;
                    }
                    if (sortKey === ProjectSortKey.ClientName && sortOrder === SortOrder.Asc) {
                      setSortKey(ProjectSortKey.ClientName);
                      setSortOrder(SortOrder.Desc);
                      return;
                    }
                    setSortKey(ProjectSortKey.SortNo);
                    setSortOrder(null);
                  }}>
                  {when(sortOrder) //
                    .on(
                      (v) => v === null || v === undefined,
                      () => (
                        <CaretSetIcon
                          size={12}
                          containerStyle={{
                            opacity: 0.2,
                            marginLeft: 3,
                            width: 10,
                            justifyContent: 'flex-end',
                          }}
                        />
                      )
                    )
                    .on(
                      (v) => v === SortOrder.Asc,
                      () => (
                        <CaretUpIcon
                          size={10}
                          containerStyle={{ marginLeft: 3, width: 10, justifyContent: 'flex-end' }}
                        />
                      )
                    )
                    .otherwise(() => (
                      <CaretDownIcon
                        size={10}
                        containerStyle={{ marginLeft: 3, width: 10, justifyContent: 'flex-end' }}
                      />
                    ))}
                  {when(sortKey) //
                    .on(
                      (v) => v === null || v === undefined,
                      () => (
                        <Typography
                          variant={TypographyType.Normal}
                          style={{
                            fontSize: 12,
                            color: themeContext.colors.description,
                            textAlign: 'left',
                            paddingHorizontal: 5,
                          }}>
                          並び替え
                        </Typography>
                      )
                    )
                    .on(
                      (v) => v === ProjectSortKey.ProjectName,
                      () => (
                        <Typography
                          variant={TypographyType.Normal}
                          style={{
                            fontSize: 12,
                            color: themeContext.colors.description,
                            textAlign: 'left',
                            paddingHorizontal: 5,
                            lineHeight: 14,
                          }}>
                          {`並び替え${'\n'}(プロジェクト名)`}
                        </Typography>
                      )
                    )
                    .on(
                      (v) => v === ProjectSortKey.ClientName,
                      () => (
                        <Typography
                          variant={TypographyType.Normal}
                          style={{
                            fontSize: 12,
                            color: themeContext.colors.description,
                            textAlign: 'left',
                            paddingHorizontal: 5,
                            lineHeight: 14,
                          }}>
                          {`並び替え${'\n'}(取引先)`}
                        </Typography>
                      )
                    )
                    .otherwise(() => (
                      <Typography
                        variant={TypographyType.Normal}
                        style={{
                          fontSize: 12,
                          color: themeContext.colors.description,
                          textAlign: 'left',
                          paddingHorizontal: 5,
                        }}>
                        並び替え
                      </Typography>
                    ))}
                </TouchableOpacity>
              </View>

              {showSearchCondition && (
                <View style={{ marginBottom: 5, zIndex: 2 }}>
                  <View
                    style={{
                      flexDirection: focusSearchClient ? 'column' : 'row',
                      //@ts-ignore
                      overflowX: focusSearchClient ? undefined : 'hidden',
                      zIndex: 2,
                    }}>
                    <View
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                      }}>
                      <MultiPickerFilter
                        placeHolder="取引先を絞り込む"
                        value={clientIds}
                        containerStyle={{ marginRight: 10 }}
                        listStyle={{ maxWidth: 280 }}
                        pickerItems={(clientData?.organizationClients?.slice() ?? [])
                          .sort((a, b) => (a!.name as any) - (b!.name as any))
                          .map((client) => {
                            return {
                              label: client!.name!,
                              value: client!.id!,
                            };
                          })}
                        onFocus={() => setForcusSearchClient(true)}
                        onBlur={(items) => {
                          setClientIds(items.map((item) => item.value));
                          setForcusSearchClient(false);
                        }}
                        renderLabel={(items) => {
                          if (items.length === 0) {
                            return (
                              <View
                                style={{
                                  flexDirection: 'row',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center',
                                  marginLeft: 20,
                                }}>
                                <SearchIcon size={16} />
                                <Typography
                                  variant={TypographyType.Normal}
                                  style={{ marginLeft: 10, fontSize: 12 }}
                                  ellipsis={true}>
                                  取引先で絞り込む
                                </Typography>
                              </View>
                            );
                          }
                          return (
                            <View>
                              <View
                                style={{
                                  flexDirection: 'row',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center',
                                  marginLeft: 20,
                                }}>
                                <SearchIcon size={16} />
                                <Typography
                                  variant={TypographyType.Normal}
                                  style={{ marginLeft: 10, fontSize: 12 }}
                                  ellipsis={true}>
                                  取引先で絞り込む
                                </Typography>
                              </View>
                              {items.map((item) => {
                                return (
                                  <View style={{ marginLeft: 38 }}>
                                    <Typography
                                      variant={TypographyType.Description}
                                      ellipsis
                                      style={{
                                        fontSize: 14,
                                        lineHeight: 16,
                                      }}>
                                      {item.label}
                                    </Typography>
                                  </View>
                                );
                              })}
                            </View>
                          );
                        }}
                      />
                    </View>
                  </View>
                  <View
                    style={{
                      flexDirection: focusSearchMember ? 'column' : 'row',
                      //@ts-ignore
                      overflowX: focusSearchMember ? undefined : 'hidden',
                    }}>
                    <View
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                      }}>
                      <MultiPickerFilter
                        placeHolder="担当者を絞り込む"
                        value={memberIds}
                        containerStyle={{ marginRight: 10 }}
                        listStyle={{ maxWidth: 280 }}
                        pickerItems={(memberData?.teamMembers?.slice() ?? [])
                          .sort((a, b) => (a!.name as any) - (b!.name as any))
                          .map((member) => {
                            return {
                              label: member!.name!,
                              value: member!.id!,
                            };
                          })}
                        onFocus={() => setForcusSearchMember(true)}
                        onBlur={(items) => {
                          setMemberIds(items.map((item) => item.value));
                          setForcusSearchMember(false);
                        }}
                        renderLabel={(items) => {
                          if (items.length === 0) {
                            return (
                              <View
                                style={{
                                  flexDirection: 'row',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center',
                                  marginLeft: 20,
                                }}>
                                <SearchIcon size={16} />
                                <Typography
                                  variant={TypographyType.Normal}
                                  style={{ marginLeft: 10, fontSize: 12 }}
                                  ellipsis={true}>
                                  担当者で絞り込む
                                </Typography>
                              </View>
                            );
                          }
                          return (
                            <View>
                              <View
                                style={{
                                  flexDirection: 'row',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center',
                                  marginLeft: 20,
                                }}>
                                <SearchIcon size={16} />
                                <Typography
                                  variant={TypographyType.Normal}
                                  style={{ marginLeft: 10, fontSize: 12 }}
                                  ellipsis={true}>
                                  担当者で絞り込む
                                </Typography>
                              </View>
                              {items.map((item) => {
                                return (
                                  <View style={{ marginLeft: 38 }}>
                                    <Typography
                                      variant={TypographyType.Description}
                                      ellipsis
                                      style={{
                                        fontSize: 14,
                                        lineHeight: 16,
                                      }}>
                                      {item.label}
                                    </Typography>
                                  </View>
                                );
                              })}
                            </View>
                          );
                        }}
                      />
                    </View>
                  </View>
                </View>
              )}
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  marginLeft: 15,
                }}>
                {showCompleteProject ? (
                  <TouchableOpacity onPress={() => setShowCompleteProject(false)}>
                    <Typography
                      variant={TypographyType.Normal}
                      style={{
                        fontSize: 12,
                        color: themeContext.colors.link,
                        textAlign: 'left',
                        paddingHorizontal: 5,
                      }}>
                      完了したプロジェクトを隠す
                    </Typography>
                  </TouchableOpacity>
                ) : (
                  <TouchableOpacity onPress={() => setShowCompleteProject(true)}>
                    <Typography
                      variant={TypographyType.Normal}
                      style={{
                        fontSize: 12,
                        color: themeContext.colors.link,
                        textAlign: 'left',
                        paddingHorizontal: 5,
                      }}>
                      {`完了したプロジェクトを表示する`}
                    </Typography>
                  </TouchableOpacity>
                )}
              </View>
            </View>
          </View>
        </Form>

        <ProjectAddButton
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 5,
            paddingBottom: 10,
            paddingHorizontal: 5,
          }}
          onPress={async () => {
            if (!props.me.projectCreatePermissionFlg) {
              setShowErrorModal(true);
              return;
            }
            props.setShowDialog(true);
          }}>
          <PlusIcon
            size={12}
            containerStyle={{ marginLeft: 10 }}
            onPress={async () => {
              props.setShowDialog(true);
            }}
          />
          <Typography
            variant={TypographyType.Normal}
            style={{ fontSize: 12, color: themeContext.colors.description }}
            ellipsis={true}>
            プロジェクトを追加する
          </Typography>
        </ProjectAddButton>
      </Search>

      <GlobalDragContextProvider>
        <VirtualizedFlatList
          style={{ height: 'calc(100vh - 340px)' }}
          items={projects}
          renderItem={(item, index) => {
            return (
              <ProjectItem isComplete={(item as Project).complete} key={index}>
                <View style={{ flexDirection: 'column', width: '100%' }}>
                  {(item as Project).client && (
                    <Typography
                      variant={TypographyType.Description}
                      style={{
                        fontSize: 10,
                        color: themeContext.colors.description,
                        textAlign: 'left',
                        lineHeight: 11,
                      }}
                      ellipsis={true}>
                      {(item as Project).client!.name}
                    </Typography>
                  )}
                  <Typography
                    variant={TypographyType.Normal}
                    ellipsis={true}
                    // tooltipWhenEllipsis={true}
                    // tooltipId={`project-${(item as Project).id}`}
                    style={{
                      fontSize: 13,
                      color: themeContext.colors.textColor,
                      textAlign: 'left',
                    }}>
                    {(item as Project).name}
                  </Typography>
                </View>
              </ProjectItem>
            );
          }}
          getKey={(project) => (project as Project).id!.toString()}
          itemHeight={40}
          onPress={(project) => {
            if (
              mySettingsData!.mySettings!.projectInitialViewTypeCode ===
              ProjectInitialViewType.KanbanBoard
            ) {
              push(
                `/app/${loginUser!.organizationId}/${props.teamId}/projects/${
                  (project as Project).id
                }/board/`
              );
            } else if (
              mySettingsData!.mySettings!.projectInitialViewTypeCode ===
              ProjectInitialViewType.GanttChart
            ) {
              push(
                `/app/${loginUser!.organizationId}/${props.teamId}/projects/${
                  (project as Project).id
                }/schedule/`
              );
            } else {
              push(
                `/app/${loginUser!.organizationId}/${props.teamId}/projects/${
                  (project as Project).id
                }/list/`
              );
            }
          }}
          virticalDraggable={true}
          onDrop={async (info) => {
            if (sortKey !== ProjectSortKey.SortNo) {
              return;
            }
            const isMoveToFirst = info.endRowIndex === 0;
            const isMoveToLast = info.endRowIndex === projects.length - 1;
            const isMoveToDown = info.endRowIndex - info.startRowIndex > 0;

            let sortNo;
            if (isMoveToFirst) {
              sortNo = new Date().getTime();
            } else if (isMoveToLast) {
              sortNo = projects[info.endRowIndex]!.sortNoInList - 1000;
            } else {
              if (isMoveToDown) {
                const beforeTask = projects[info.endRowIndex];
                const afterTask = projects[info.endRowIndex + 1];
                sortNo = Math.floor((beforeTask!.sortNoInList + afterTask!.sortNoInList) / 2);
              } else {
                const beforeTask = projects[info.endRowIndex - 1];
                const afterTask = projects[info.endRowIndex];
                sortNo = Math.floor((beforeTask!.sortNoInList + afterTask!.sortNoInList) / 2);
              }
            }

            await updateProjectSortNo({
              variables: {
                id: (info.item as Project).id!,
                input: {
                  sortNoInList: sortNo,
                  versionNo: (info.item as Project).versionNo,
                },
              },
              optimisticResponse: {
                __typename: 'Mutation',
                updateProjectSortNoInList: Object.assign(
                  {
                    __typename: 'Project',
                  },
                  info.item as Project,
                  { sortNoInList: sortNo }
                ),
              },
            });
          }}
          onEndReached={() => {
            if ((projectsResult?.data?.teamProjects?.length ?? 0) < pageSize) {
              return;
            }
            projectsResult.fetchMore({
              variables: {
                offset: projectsResult.data!.teamProjects!.length,
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return Object.assign({}, prev, {
                  teamProjects: [
                    ...(prev.teamProjects || []),
                    ...(fetchMoreResult.teamProjects || []),
                  ],
                });
              },
            });
          }}
        />
        <ErrorMessageModal
          showModal={showErrorModal}
          title={'プロジェクトを作成できません'}
          message={`プロジェクトを作成する権限がありません${'\n'}権限が必要な場合、管理権限を持っているメンバーに問い合わせてください`}
          onCloseModal={() => setShowErrorModal(false)}
        />
      </GlobalDragContextProvider>
    </>
  );
});

const ProjectList = () => {
  const { push } = useHistory();
  const [showDialog, setShowDialog] = useState(false);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const teamId = LoginUtil.getTeamIdFromURl();
  const { loading, data } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId,
    },
  });
  const { loading: mySettingsLoading, data: mySettingsData } = useMySettingsQuery();
  const { loading: meLoading, data: meData } = useMeQuery({
    fetchPolicy: 'network-only',
  });

  if (loading || mySettingsLoading || meLoading || !meData?.me) {
    return <></>;
  }

  return (
    <Container>
      <ResizableColumn
        cookieName="PROJECT_LIST_WIDTH"
        renderChild={(width) => {
          return (
            <List
              organization={data!.organization!}
              teamId={teamId!}
              me={meData.me}
              setShowDialog={setShowDialog}
              isWidthOver230={width > 230}
            />
          );
        }}
      />
      <Modal
        isShow={showDialog}
        title="プロジェクトを作成する"
        onClose={() => {
          setShowDialog(false);
        }}>
        <ProjectCreateDialog
          onComplete={(newProject) => {
            setShowDialog(false);
            if (
              mySettingsData!.mySettings!.projectInitialViewTypeCode ===
              ProjectInitialViewType.KanbanBoard
            ) {
              push(`/app/${loginUser!.organizationId}/${teamId}/projects/${newProject.id}/board/`);
            } else if (
              mySettingsData!.mySettings!.projectInitialViewTypeCode ===
              ProjectInitialViewType.GanttChart
            ) {
              push(
                `/app/${loginUser!.organizationId}/${teamId}/projects/${newProject.id}/schedule/`
              );
            } else {
              push(`/app/${loginUser!.organizationId}/${teamId}/projects/${newProject.id}/list/`);
            }
          }}
          onCancel={() => {
            setShowDialog(false);
          }}
        />
      </Modal>
    </Container>
  );
};

export default ProjectList;
