import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import { View, Image } from 'react-native';
import Typography, { TypographyType } from '../../../../../presentational/atoms/typography';
import Modal from '../../../../../presentational/molecules/modal';
import { IStyleTheme, IThemePart } from '../../../../../theme';
import {
  useOrganizationQuery,
  Organization,
  useMeQuery,
  useProjectCustomAttributeMastersQuery,
  ProjectCustomAttributeMaster,
  ProjectCustomAttributeMastersDocument,
  useCreateProjectCustomAttributeMasterMutation,
  CustomerAttributeType,
  useProjectCustomAttributeMasterQuery,
  useUpdateProjectCustomAttributeMasterMutation,
  ProjectCustomAttributeMasterListItem,
  useDeleteProjectCustomAttributeMasterMutation,
  Plan,
  useAddProjectCustomAttributeMasterListItemMutation,
  ProjectCustomAttributeMasterDocument,
  useUpdateProjectCustomAttributeMasterListItemMutation,
  useDeleteProjectCustomAttributeMasterListItemMutation,
  TaskCustomAttributeMaster,
  useCreateTaskCustomAttributeMasterMutation,
  TaskCustomAttributeMastersDocument,
  useTaskCustomAttributeMasterQuery,
  useUpdateTaskCustomAttributeMasterListItemMutation,
  TaskCustomAttributeMasterListItem,
  useUpdateTaskCustomAttributeMasterMutation,
  useAddTaskCustomAttributeMasterListItemMutation,
  TaskCustomAttributeMasterDocument,
  useDeleteTaskCustomAttributeMasterListItemMutation,
  useDeleteTaskCustomAttributeMasterMutation,
  useTaskCustomAttributeMastersQuery,
  Member,
} from '../../../../../../graphql/api/API';
import { LoginUserContext } from '../../../../../../modules/auth/LoginUserContext';
import Form from '../../../../../presentational/atoms/form';
import Input, { ListValueMap } from '../../../../../presentational/atoms/input';
import Button from '../../../../../presentational/atoms/button';
import { Route, Redirect, useHistory, useParams, Switch as RouterSwitch } from 'react-router';
import VirtualizedFlatList, {
  GlobalDragContextProvider,
} from '../../../../../presentational/atoms/list2/virtualized-flat-list';
import CustomScrollView from '../../../../../presentational/atoms/custom-scroll-view';
import noData01 from '../../../../../../base64Images/no-data/no-data-1';
import noData03 from '../../../../../../base64Images/no-data/no-data-3';
import useDimensions from 'use-dimensions';
import SettingsElement from '../../../organisms/settings-element';
import TextList from '../../../../../presentational/atoms/text-list';
import ColorUtil from '../../../../../../util/ColorUtil';
import PlusIcon from '../../../../../presentational/molecules/image-icon/plus';
import SearchIcon from '../../../../../presentational/molecules/image-icon/search';
import ResizableColumn from '../../../organisms/resizable-column';
import when from '../../../../../../lang-extention/When';
import Switch from '../../../../../presentational/atoms/switch';
import DeleteIcon from '../../../../../presentational/molecules/image-icon/delete';
import EditIcon from '../../../../../presentational/molecules/image-icon/edit';
import EditableText from '../../../../../presentational/atoms/editable-text';
import PlanNotAllowedView from '../../../organisms/plan-not-allowed-view';

const Container = styled.View`
  height: calc(100vh - 57px);
  display: flex;
  flex-direction: row;
`;

const MainAreaContainer = styled.View`
  display: flex;
  flex: 1;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.4s;
  z-index: 100;
  height: calc(100vh);
`;

const MainAreaHeader = styled.View`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: ${(props: IStyleTheme) => props.theme.colors.subHeader};
  padding: 5px 10px;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.4s;
  z-index: 2;
  height: 42px;
`;

const Item = styled.View`
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  border-bottom-width: 1px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
`;

const AddButton = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  padding-right: 5px;
  padding-left: 5px;
`;

interface IListItemInputProps {
  item: { id: string; value: string; deleted: boolean };
  allListItems: { id: string; value: string; deleted: boolean }[];
  index: number;
  emptyItem: boolean;
  onBlur: (value: string) => void;
  onDelete: () => void;
  onChangeRequired: (value: boolean) => void;
}

const ListItemInput = (props: IListItemInputProps) => {
  const [item, setItem] = useState('');
  const [required, setRequired] = useState(false);
  const [deleted, setDeleted] = useState(false);

  return (
    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
      <Input
        name={`item${props.item.id}`}
        label={props.emptyItem ? undefined : `選択肢${props.index}`}
        value={props.item.value.trim()}
        onChange={(value) => setItem(value)}
        onBlur={() => props.onBlur(item)}
        containerStyle={{
          maxWidth: props.emptyItem ? 300 : 400,
          opacity: required ? 0.4 : 1,
          marginBottom: 10,
        }}
        readonly={props.emptyItem}
        validate={Object.assign(
          props.emptyItem || deleted
            ? {}
            : {
                required: {
                  value: true,
                  message: '選択肢を入力してください',
                },
              },
          {
            validate: (value: string) => {
              if (
                props.allListItems.filter((it) => it!.id !== props.item.id && it!.value === value)
                  .length > 0
              ) {
                return '同じ値の選択肢が他に登録されています';
              }
              return true;
            },
          }
        )}
      />
      {props.emptyItem ? (
        <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
          <Switch
            value={!required}
            onValueChange={(value) => {
              setRequired(!value);
              props.onChangeRequired(!value);
            }}
          />
          <Typography variant={TypographyType.Normal} style={{ marginLeft: 5, textAlign: 'right' }}>
            {required ? '未選択を許可しない' : '未選択を許可する'}
          </Typography>
        </View>
      ) : (
        <DeleteIcon
          size={23}
          containerStyle={{ marginRight: 5 }}
          onPress={() => {
            setDeleted(true);
            props.onDelete();
          }}>
          <Typography variant={TypographyType.Normal}>削除する</Typography>
        </DeleteIcon>
      )}
    </View>
  );
};

interface IRegiserProjectCustomAttributeDialogProps {
  organization: Organization;
  customAttributes: ProjectCustomAttributeMaster[];
  onComplete: (customAttribute: ProjectCustomAttributeMaster) => void;
  onCancel: () => void;
}

const RegiserProjectCustomAttributeDialog = (props: IRegiserProjectCustomAttributeDialogProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const history = useHistory();
  const [loginUser, __] = useContext(LoginUserContext);
  const [name, setName] = useState<string>('');
  const [required, setRequired] = useState(false);
  const [attributeType, setAttibuteType] = useState(CustomerAttributeType.FreeText);
  const [listItems, setListItems] = useState<{ id: string; value: string; deleted: boolean }[]>([
    { id: '0', value: '- (未選択)', deleted: false },
  ]);
  const [createAttribute, _] = useCreateProjectCustomAttributeMasterMutation({
    variables: {
      input: {
        name: name,
        type: attributeType,
        listItems:
          attributeType === CustomerAttributeType.List
            ? listItems
                .filter((item) => item.value.trim().length > 0)
                .filter((item) => item.id !== '0')
                .filter((item) => !item.deleted)
                .map((item) => item.value)
            : [],
        required: required,
      },
    },
    refetchQueries: [
      {
        query: ProjectCustomAttributeMastersDocument,
      },
    ],
  });

  return (
    <CustomScrollView style={{ maxHeight: window.innerHeight - 100 }}>
      <Form>
        <View style={{ minWidth: 500, marginTop: 10 }}>
          <Input
            name={'name'}
            label={'項目名'}
            autoFocus
            value={name.trim()}
            onChange={setName}
            validate={{
              required: {
                value: true,
                message: '項目名を入力してください',
              },
              validate: (value: string) => {
                if (props.customAttributes.filter((attr) => attr.name === value).length > 0) {
                  return '同じ項目名がすでに登録済みです';
                }
                return true;
              },
            }}
          />
        </View>
        <View style={{ marginTop: 10 }}>
          <Input
            name={'attributeType'}
            label={'項目タイプ'}
            type={'picker'}
            initialValue={CustomerAttributeType.FreeText}
            isSearchable={true}
            pickerItems={[
              {
                label: 'フリーテキスト',
                value: CustomerAttributeType.FreeText,
              },
              {
                label: '数値',
                value: CustomerAttributeType.Number,
              },
              {
                label: '日付',
                value: CustomerAttributeType.Date,
              },
              {
                label: '日時',
                value: CustomerAttributeType.DateTime,
              },
              {
                label: '選択リスト',
                value: CustomerAttributeType.List,
              },
            ]}
            onChange={(value) => setAttibuteType(value as CustomerAttributeType)}
          />
          <Typography variant={TypographyType.Description}>
            {'※項目タイプはあとから変更できません'}
          </Typography>
        </View>
        {attributeType === CustomerAttributeType.List ? (
          <View style={{ minWidth: 500, marginTop: 10 }}>
            {listItems
              .filter((item) => !item.deleted)
              .map((item, i) => {
                return (
                  <ListItemInput
                    index={i}
                    item={item}
                    allListItems={listItems}
                    emptyItem={item.id === '0'}
                    onBlur={(value) => {
                      if (item.id === '0') {
                        return;
                      }
                      const findIndex = listItems.findIndex((it) => it.id === item.id);

                      const newValue = listItems.slice();
                      newValue[findIndex].value = value;
                      setListItems(newValue);
                    }}
                    onDelete={() => {
                      if (item.id === '0') {
                        return;
                      }
                      const findIndex = listItems.findIndex((it) => it.id === item.id);

                      const newValue = listItems.slice();
                      newValue[findIndex].deleted = true;
                      setListItems(newValue);
                    }}
                    onChangeRequired={(value) => setRequired(value)}
                    key={i}
                  />
                );
              })}
            <AddButton
              onPress={() => {
                setListItems(
                  listItems.concat([{ id: listItems.length.toString(), value: '', deleted: false }])
                );
              }}>
              <PlusIcon
                size={14}
                containerStyle={{ marginLeft: 10 }}
                onPress={() =>
                  setListItems(
                    listItems.concat([
                      { id: listItems.length.toString(), value: '', deleted: false },
                    ])
                  )
                }>
                <Typography
                  variant={TypographyType.Normal}
                  style={{ fontSize: 14, color: themeContext.colors.description }}>
                  選択肢を追加する
                </Typography>
              </PlusIcon>
            </AddButton>
          </View>
        ) : (
          <View style={{ flexDirection: 'row', marginTop: 20, marginBottom: 10 }}>
            <Switch
              value={required}
              onValueChange={(value) => {
                setRequired(value);
              }}
            />
            <Typography
              variant={TypographyType.Normal}
              style={{ marginLeft: 5, textAlign: 'right' }}>
              {required ? '値の入力を必須にする' : '値の入力を必須にしない'}
            </Typography>
          </View>
        )}

        <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 10 }}>
          <Button
            text="登録する"
            completeText="登録しました"
            style={{ marginRight: 5, paddingVertical: 10, paddingHorizontal: 20 }}
            textStyle={{ fontSize: 14 }}
            onPress={async () => {
              const result = await createAttribute();
              props.onComplete(result.data!.createProjectCustomAttributeMaster!);
            }}
          />
          <Button
            text="キャンセル"
            style={{ paddingVertical: 10, paddingHorizontal: 20, backgroundColor: 'transparent' }}
            textStyle={{ fontSize: 14, color: themeContext.colors.primary }}
            onPress={async () => {
              props.onCancel();
            }}
            disableValidate={true}
          />
        </View>
      </Form>
    </CustomScrollView>
  );
};

interface IRegiserTaskCustomAttributeDialogProps {
  organization: Organization;
  customAttributes: TaskCustomAttributeMaster[];
  onComplete: (customAttribute: TaskCustomAttributeMaster) => void;
  onCancel: () => void;
}

const RegiserTaskCustomAttributeDialog = (props: IRegiserTaskCustomAttributeDialogProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const history = useHistory();
  const [loginUser, __] = useContext(LoginUserContext);
  const [name, setName] = useState<string>('');
  const [required, setRequired] = useState(false);
  const [attributeType, setAttibuteType] = useState(CustomerAttributeType.FreeText);
  const [listItems, setListItems] = useState<{ id: string; value: string; deleted: boolean }[]>([
    { id: '0', value: '- (未選択)', deleted: false },
  ]);
  const [createAttribute, _] = useCreateTaskCustomAttributeMasterMutation({
    variables: {
      input: {
        name: name,
        type: attributeType,
        listItems:
          attributeType === CustomerAttributeType.List
            ? listItems
                .filter((item) => item.value.trim().length > 0)
                .filter((item) => item.id !== '0')
                .filter((item) => !item.deleted)
                .map((item) => item.value)
            : [],
        required: required,
      },
    },
    refetchQueries: [
      {
        query: TaskCustomAttributeMastersDocument,
      },
    ],
  });

  return (
    <CustomScrollView style={{ maxHeight: window.innerHeight - 100 }}>
      <Form>
        <View style={{ minWidth: 500, marginTop: 10 }}>
          <Input
            name={'name'}
            label={'項目名'}
            autoFocus
            value={name.trim()}
            onChange={setName}
            validate={{
              required: {
                value: true,
                message: '項目名を入力してください',
              },
              validate: (value: string) => {
                if (props.customAttributes.filter((attr) => attr.name === value).length > 0) {
                  return '同じ項目名がすでに登録済みです';
                }
                return true;
              },
            }}
          />
        </View>
        <View style={{ marginTop: 10 }}>
          <Input
            name={'attributeType'}
            label={'項目タイプ'}
            type={'picker'}
            initialValue={CustomerAttributeType.FreeText}
            isSearchable={true}
            pickerItems={[
              {
                label: 'フリーテキスト',
                value: CustomerAttributeType.FreeText,
              },
              {
                label: '数値',
                value: CustomerAttributeType.Number,
              },
              {
                label: '日付',
                value: CustomerAttributeType.Date,
              },
              {
                label: '日時',
                value: CustomerAttributeType.DateTime,
              },
              {
                label: '選択リスト',
                value: CustomerAttributeType.List,
              },
            ]}
            onChange={(value) => setAttibuteType(value as CustomerAttributeType)}
          />
          <Typography variant={TypographyType.Description}>
            {'※項目タイプはあとから変更できません'}
          </Typography>
        </View>
        {attributeType === CustomerAttributeType.List ? (
          <View style={{ minWidth: 500, marginTop: 10 }}>
            {listItems
              .filter((item) => !item.deleted)
              .map((item, i) => {
                return (
                  <ListItemInput
                    index={i}
                    item={item}
                    allListItems={listItems}
                    emptyItem={item.id === '0'}
                    onBlur={(value) => {
                      if (item.id === '0') {
                        return;
                      }
                      const findIndex = listItems.findIndex((it) => it.id === item.id);

                      const newValue = listItems.slice();
                      newValue[findIndex].value = value;
                      setListItems(newValue);
                    }}
                    onDelete={() => {
                      if (item.id === '0') {
                        return;
                      }
                      const findIndex = listItems.findIndex((it) => it.id === item.id);

                      const newValue = listItems.slice();
                      newValue[findIndex].deleted = true;
                      setListItems(newValue);
                    }}
                    onChangeRequired={(value) => setRequired(value)}
                    key={i}
                  />
                );
              })}
            <AddButton
              onPress={() => {
                setListItems(
                  listItems.concat([{ id: listItems.length.toString(), value: '', deleted: false }])
                );
              }}>
              <PlusIcon
                size={14}
                containerStyle={{ marginLeft: 10 }}
                onPress={() =>
                  setListItems(
                    listItems.concat([
                      { id: listItems.length.toString(), value: '', deleted: false },
                    ])
                  )
                }>
                <Typography
                  variant={TypographyType.Normal}
                  style={{ fontSize: 14, color: themeContext.colors.description }}>
                  選択肢を追加する
                </Typography>
              </PlusIcon>
            </AddButton>
          </View>
        ) : (
          <View style={{ flexDirection: 'row', marginTop: 20, marginBottom: 10 }}>
            <Switch
              value={required}
              onValueChange={(value) => {
                setRequired(value);
              }}
            />
            <Typography
              variant={TypographyType.Normal}
              style={{ marginLeft: 5, textAlign: 'right' }}>
              {required ? '値の入力を必須にする' : '値の入力を必須にしない'}
            </Typography>
          </View>
        )}

        <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 10 }}>
          <Button
            text="登録する"
            completeText="登録しました"
            style={{ marginRight: 5, paddingVertical: 10, paddingHorizontal: 20 }}
            textStyle={{ fontSize: 14 }}
            onPress={async () => {
              const result = await createAttribute();
              props.onComplete(result.data!.createTaskCustomAttributeMaster!);
            }}
          />
          <Button
            text="キャンセル"
            style={{ paddingVertical: 10, paddingHorizontal: 20, backgroundColor: 'transparent' }}
            textStyle={{ fontSize: 14, color: themeContext.colors.primary }}
            onPress={async () => {
              props.onCancel();
            }}
            disableValidate={true}
          />
        </View>
      </Form>
    </CustomScrollView>
  );
};

const ProjectCustomAttributeListtWrapper = () => {
  return (
    <ResizableColumn
      cookieName="CUSTOM_ATTRIBUTE_PROJECT_LIST_WIDTH"
      defaultWidth={240}
      maxWidth={500}
      renderChild={(width) => {
        return <ProjectCustomAttributeList />;
      }}
    />
  );
};

const TaskCustomAttributeListtWrapper = () => {
  return (
    <ResizableColumn
      cookieName="CUSTOM_ATTRIBUTE_TASK_LIST_WIDTH"
      defaultWidth={240}
      maxWidth={500}
      renderChild={(width) => {
        return <TaskCustomAttributeList />;
      }}
    />
  );
};

const ProjectCustomAttributeList = React.memo(() => {
  const [searchrName, setSearchName] = useState('');
  const themeContext: IThemePart = useContext(ThemeContext);
  const history = useHistory();
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [showDialog, setShowDialog] = useState(false);
  const [showCannotOperateDialog, setShowCannotOperateDialog] = useState(false);
  const { loading, data, error } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId!,
    },
  });
  const { loading: attributeLoading, data: attributeData } = useProjectCustomAttributeMastersQuery({
    fetchPolicy: 'network-only',
  });
  const { loading: meLoading, data: meData } = useMeQuery({
    fetchPolicy: 'network-only',
  });

  const attributes = useMemo(() => {
    return (attributeData?.projectCustomAttributeMasters ?? [])
      .filter((attr) => attr?.name.indexOf(searchrName) !== -1)
      .sort((a, b) => a?.sortNo - b?.sortNo);
  }, [attributeData?.projectCustomAttributeMasters, searchrName]);

  const [updateCustomAttributeMaster] = useUpdateProjectCustomAttributeMasterMutation();

  if (loading || attributeLoading || meLoading) {
    return <></>;
  }

  return (
    <View
      style={{
        zIndex: 1,
        borderLeftWidth: 1,
        borderRadius: 20,
        borderColor: themeContext.colors.separator,
      }}>
      <View
        style={{
          backgroundColor: ColorUtil.lignten(themeContext.colors.header, 5),
          paddingVertical: 7,
          paddingHorizontal: 3,
        }}>
        <Typography
          variant={TypographyType.SubTitle}
          ellipsis={true}
          style={{ color: '#FFFFFF', fontSize: 16, fontWeight: '600' }}>
          プロジェクトのカスタム項目
        </Typography>
      </View>
      <View style={{ borderBottomWidth: 1, borderColor: themeContext.colors.separator }}>
        <Form>
          <View style={{ flexDirection: 'column' }}>
            <Input
              name={'searchMember'}
              label={''}
              icon={<SearchIcon size={20} containerStyle={{ marginLeft: 10 }} />}
              containerStyle={{ marginVertical: 10 }}
              inputstyle={{ borderWidth: 0 }}
              inputContainerStyle={{
                marginHorizontal: 10,
                backgroundColor: themeContext.colors.baseColor,
                shadowOffset: {
                  width: -1,
                  height: -1,
                },
                shadowOpacity: 0.1,
                elevation: 2,
                borderWidth: 1,
                borderRadius: 20,
                borderColor: themeContext.colors.separator,
              }}
              onChange={(value: string) => setSearchName(value)}
            />
          </View>
        </Form>
        <AddButton
          style={{
            display: 'flex',
            flexDirection: 'row',
            paddingBottom: 10,
            paddingHorizontal: 5,
          }}
          onPress={async () => {
            if (!meData?.me!.projectCustomAttributePermissionFlg) {
              setShowCannotOperateDialog(true);
              return;
            }
            setShowDialog(true);
          }}>
          <PlusIcon
            size={12}
            containerStyle={{ marginLeft: 10 }}
            onPress={async () => {
              if (!meData?.me!.projectCustomAttributePermissionFlg) {
                setShowCannotOperateDialog(true);
                return;
              }
              setShowDialog(true);
            }}>
            <Typography
              variant={TypographyType.Normal}
              style={{ fontSize: 12, color: themeContext.colors.description }}>
              新しいカスタム項目を追加する
            </Typography>
          </PlusIcon>
        </AddButton>
        <Modal
          title={'プロジェクトのカスタム項目を追加できません'}
          isShow={showCannotOperateDialog}
          onClose={() => {
            setShowCannotOperateDialog(false);
          }}>
          <Typography
            variant={TypographyType.Normal}
            style={{ textAlign: 'center', marginVertical: 10 }}>
            プロジェクトのカスタム項目の編集権限が必要です。
          </Typography>
          <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
            <Button
              text={'OK'}
              style={{ minWidth: 100, marginRight: 10, marginVertical: 20 }}
              onPress={async () => {
                setShowCannotOperateDialog(false);
              }}
            />
          </View>
        </Modal>
      </View>

      <GlobalDragContextProvider>
        <VirtualizedFlatList
          style={{ height: 'calc(100vh - 280px)' }}
          items={attributes}
          renderItem={(item, index) => {
            return (
              <Item>
                <View style={{ flexDirection: 'column' }}>
                  <Typography
                    variant={TypographyType.Normal}
                    ellipsis={true}
                    style={{
                      fontSize: 13,
                      color: themeContext.colors.textColor,
                      textAlign: 'left',
                      lineHeight: 17,
                    }}>
                    {(item as ProjectCustomAttributeMaster).name}
                  </Typography>
                </View>
              </Item>
            );
          }}
          getKey={(memberOrInviation: any) =>
            (memberOrInviation as ProjectCustomAttributeMaster).id!
          }
          itemHeight={40}
          onPress={(memberOrInviation: any) => {
            history.push(
              `/app/${loginUser!.organizationId}/custom-attribute/project/${(
                memberOrInviation as ProjectCustomAttributeMaster
              ).id!}/`
            );
            return;
          }}
          isDraggable
          virticalDraggable
          onDrop={async (info) => {
            if (!meData?.me?.projectCustomAttributePermissionFlg) {
              return;
            }
            const isMoveToFirst = info.endRowIndex === 0;
            const isMoveToLast = info.endRowIndex === attributes.length - 1;
            const isMoveToDown = info.endRowIndex - info.startRowIndex > 0;

            let sortNo;
            if (isMoveToFirst) {
              sortNo = attributes[info.endRowIndex]!.sortNo - 1000;
            } else if (isMoveToLast) {
              sortNo = new Date().getTime();
            } else {
              if (isMoveToDown) {
                const beforeTask = attributes[info.endRowIndex];
                const afterTask = attributes[info.endRowIndex + 1];
                sortNo = Math.floor((beforeTask!.sortNo + afterTask!.sortNo) / 2);
              } else {
                const beforeTask = attributes[info.endRowIndex - 1];
                const afterTask = attributes[info.endRowIndex];
                sortNo = Math.floor((beforeTask!.sortNo + afterTask!.sortNo) / 2);
              }
            }

            await updateCustomAttributeMaster({
              variables: {
                id: (info.item as ProjectCustomAttributeMaster).id!,
                input: {
                  sortNo: sortNo,
                  name: (info.item as ProjectCustomAttributeMaster).name,
                  type: (info.item as ProjectCustomAttributeMaster).type,
                  required: (info.item as ProjectCustomAttributeMaster).required,
                  versionNo: (info.item as ProjectCustomAttributeMaster).versionNo,
                },
              },
            });
          }}
        />
      </GlobalDragContextProvider>
      <Modal
        title={'新しく項目を追加する'}
        isShow={showDialog}
        onClose={() => {
          setShowDialog(false);
        }}>
        <RegiserProjectCustomAttributeDialog
          organization={data!.organization!}
          customAttributes={
            attributeData?.projectCustomAttributeMasters as ProjectCustomAttributeMaster[]
          }
          onComplete={(customAttribute) => {
            setShowDialog(false);
            history.push(
              `/app/${loginUser!.organizationId}/custom-attribute/project/${customAttribute!.id!}/`
            );
          }}
          onCancel={() => {
            setShowDialog(false);
          }}
        />
      </Modal>
    </View>
  );
});

const TaskCustomAttributeList = React.memo(() => {
  const [searchrName, setSearchName] = useState('');
  const themeContext: IThemePart = useContext(ThemeContext);
  const history = useHistory();
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [showDialog, setShowDialog] = useState(false);
  const [showCannotOperateDialog, setShowCannotOperateDialog] = useState(false);
  const { loading, data, error } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId!,
    },
  });
  const { loading: attributeLoading, data: attributeData } = useTaskCustomAttributeMastersQuery({
    fetchPolicy: 'network-only',
  });
  const { loading: meLoading, data: meData } = useMeQuery({
    fetchPolicy: 'network-only',
  });

  const attributes = useMemo(() => {
    return (attributeData?.taskCustomAttributeMasters ?? [])
      .filter((attr) => attr?.name.indexOf(searchrName) !== -1)
      .sort((a, b) => a?.sortNo - b?.sortNo);
  }, [attributeData?.taskCustomAttributeMasters, searchrName]);

  const [updateCustomAttributeMaster] = useUpdateTaskCustomAttributeMasterMutation();

  if (loading || attributeLoading || meLoading) {
    return <></>;
  }

  return (
    <View
      style={{
        zIndex: 1,
        borderLeftWidth: 1,
        borderRadius: 20,
        borderColor: themeContext.colors.separator,
      }}>
      <View
        style={{
          backgroundColor: ColorUtil.lignten(themeContext.colors.header, 5),
          paddingVertical: 7,
          paddingHorizontal: 3,
        }}>
        <Typography
          variant={TypographyType.SubTitle}
          ellipsis={true}
          style={{ color: '#FFFFFF', fontSize: 16, fontWeight: '600' }}>
          タスクのカスタム項目
        </Typography>
      </View>
      <View style={{ borderBottomWidth: 1, borderColor: themeContext.colors.separator }}>
        <Form>
          <View style={{ flexDirection: 'column' }}>
            <Input
              name={'searchMember'}
              label={''}
              icon={<SearchIcon size={20} containerStyle={{ marginLeft: 10 }} />}
              containerStyle={{ marginVertical: 10 }}
              inputstyle={{ borderWidth: 0 }}
              inputContainerStyle={{
                marginHorizontal: 10,
                backgroundColor: themeContext.colors.baseColor,
                shadowOffset: {
                  width: -1,
                  height: -1,
                },
                shadowOpacity: 0.1,
                elevation: 2,
                borderWidth: 1,
                borderRadius: 20,
                borderColor: themeContext.colors.separator,
              }}
              onChange={(value: string) => setSearchName(value)}
            />
          </View>
        </Form>
        <AddButton
          style={{
            display: 'flex',
            flexDirection: 'row',
            paddingBottom: 10,
            paddingHorizontal: 5,
          }}
          onPress={async () => {
            if (!meData?.me!.taskCustomAttributePermissionFlg) {
              setShowCannotOperateDialog(true);
              return;
            }
            setShowDialog(true);
          }}>
          <PlusIcon
            size={12}
            containerStyle={{ marginLeft: 10 }}
            onPress={async () => {
              if (!meData?.me!.taskCustomAttributePermissionFlg) {
                setShowCannotOperateDialog(true);
                return;
              }
              setShowDialog(true);
            }}>
            <Typography
              variant={TypographyType.Normal}
              style={{ fontSize: 12, color: themeContext.colors.description }}>
              新しいカスタム項目を追加する
            </Typography>
          </PlusIcon>
        </AddButton>
        <Modal
          title={'タスクのカスタム項目を追加できません'}
          isShow={showCannotOperateDialog}
          onClose={() => {
            setShowCannotOperateDialog(false);
          }}>
          <Typography
            variant={TypographyType.Normal}
            style={{ textAlign: 'center', marginVertical: 10 }}>
            タスクのカスタム項目の編集権限が必要です。
          </Typography>
          <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
            <Button
              text={'OK'}
              style={{ minWidth: 100, marginRight: 10, marginVertical: 20 }}
              onPress={async () => {
                setShowCannotOperateDialog(false);
              }}
            />
          </View>
        </Modal>
      </View>

      <GlobalDragContextProvider>
        <VirtualizedFlatList
          style={{ height: 'calc(100vh - 280px)' }}
          items={attributes}
          renderItem={(item, index) => {
            return (
              <Item>
                <View style={{ flexDirection: 'column' }}>
                  <Typography
                    variant={TypographyType.Normal}
                    ellipsis={true}
                    style={{
                      fontSize: 13,
                      color: themeContext.colors.textColor,
                      textAlign: 'left',
                      lineHeight: 17,
                    }}>
                    {(item as TaskCustomAttributeMaster).name}
                  </Typography>
                </View>
              </Item>
            );
          }}
          getKey={(memberOrInviation: any) => (memberOrInviation as TaskCustomAttributeMaster).id!}
          itemHeight={40}
          onPress={(memberOrInviation: any) => {
            history.push(
              `/app/${loginUser!.organizationId}/custom-attribute/task/${(
                memberOrInviation as TaskCustomAttributeMaster
              ).id!}/`
            );
            return;
          }}
          isDraggable
          virticalDraggable
          onDrop={async (info) => {
            if (!meData?.me?.taskCustomAttributePermissionFlg) {
              return;
            }
            const isMoveToFirst = info.endRowIndex === 0;
            const isMoveToLast = info.endRowIndex === attributes.length - 1;
            const isMoveToDown = info.endRowIndex - info.startRowIndex > 0;

            let sortNo;
            if (isMoveToFirst) {
              sortNo = attributes[info.endRowIndex]!.sortNo - 1000;
            } else if (isMoveToLast) {
              sortNo = new Date().getTime();
            } else {
              if (isMoveToDown) {
                const beforeTask = attributes[info.endRowIndex];
                const afterTask = attributes[info.endRowIndex + 1];
                sortNo = Math.floor((beforeTask!.sortNo + afterTask!.sortNo) / 2);
              } else {
                const beforeTask = attributes[info.endRowIndex - 1];
                const afterTask = attributes[info.endRowIndex];
                sortNo = Math.floor((beforeTask!.sortNo + afterTask!.sortNo) / 2);
              }
            }

            await updateCustomAttributeMaster({
              variables: {
                id: (info.item as TaskCustomAttributeMaster).id!,
                input: {
                  sortNo: sortNo,
                  name: (info.item as TaskCustomAttributeMaster).name,
                  type: (info.item as TaskCustomAttributeMaster).type,
                  required: (info.item as TaskCustomAttributeMaster).required,
                  versionNo: (info.item as TaskCustomAttributeMaster).versionNo,
                },
              },
            });
          }}
        />
      </GlobalDragContextProvider>
      <Modal
        title={'新しく項目を追加する'}
        isShow={showDialog}
        onClose={() => {
          setShowDialog(false);
        }}>
        <RegiserTaskCustomAttributeDialog
          organization={data!.organization!}
          customAttributes={
            attributeData?.taskCustomAttributeMasters as TaskCustomAttributeMaster[]
          }
          onComplete={(customAttribute) => {
            setShowDialog(false);
            history.push(
              `/app/${loginUser!.organizationId}/custom-attribute/task/${customAttribute!.id!}/`
            );
          }}
          onCancel={() => {
            setShowDialog(false);
          }}
        />
      </Modal>
    </View>
  );
});

interface IProjectCustomAttributeNameModalProps {
  closeModal: () => void;
  projectCustomAttribute: ProjectCustomAttributeMaster;
}

const ProjectCustomAttributeNameModal = (props: IProjectCustomAttributeNameModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [name, setName] = useState(props!.projectCustomAttribute!.name);

  const [updateProjectCustomAttribute] = useUpdateProjectCustomAttributeMasterMutation({
    variables: {
      id: props.projectCustomAttribute.id!,
      input: {
        name: name,
        type: props.projectCustomAttribute.type,
        sortNo: props.projectCustomAttribute.sortNo,
        required: props.projectCustomAttribute.required,
        versionNo: props.projectCustomAttribute.versionNo,
      },
    },
  });

  useEffect(() => {
    setName(props.projectCustomAttribute.name);
  }, []);

  return (
    <Form style={{ marginTop: 10 }}>
      <Input
        name={'name'}
        label={'項目名'}
        initialValue={name ?? ''}
        onChange={setName}
        validate={{
          maxLength: {
            value: 50,
            message: '50文字以内で入力してください',
          },
        }}
      />
      <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1 }}>
        <Button
          text={'変更する'}
          style={{
            minWidth: 100,
            marginRight: 10,
          }}
          onPress={async () => {
            await updateProjectCustomAttribute();
            props.closeModal();
          }}
        />
        <Button
          text={'キャンセル'}
          style={{
            minWidth: 100,
            marginRight: 10,
            backgroundColor: 'transparent',
          }}
          textStyle={{ color: themeContext.colors.primary }}
          disableValidate={true}
          onPress={async () => {
            props.closeModal();
          }}
        />
      </View>
    </Form>
  );
};

interface ITaskCustomAttributeNameModalProps {
  closeModal: () => void;
  taskCustomAttribute: TaskCustomAttributeMaster;
}

const TaskCustomAttributeNameModal = (props: ITaskCustomAttributeNameModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [name, setName] = useState(props!.taskCustomAttribute!.name);

  const [updateTaskCustomAttribute] = useUpdateTaskCustomAttributeMasterMutation({
    variables: {
      id: props.taskCustomAttribute.id!,
      input: {
        name: name,
        type: props.taskCustomAttribute.type,
        sortNo: props.taskCustomAttribute.sortNo,
        required: props.taskCustomAttribute.required,
        versionNo: props.taskCustomAttribute.versionNo,
      },
    },
  });

  useEffect(() => {
    setName(props.taskCustomAttribute.name);
  }, []);

  return (
    <Form style={{ marginTop: 10 }}>
      <Input
        name={'name'}
        label={'項目名'}
        initialValue={name ?? ''}
        onChange={setName}
        validate={{
          maxLength: {
            value: 50,
            message: '50文字以内で入力してください',
          },
        }}
      />
      <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1 }}>
        <Button
          text={'変更する'}
          style={{
            minWidth: 100,
            marginRight: 10,
          }}
          onPress={async () => {
            await updateTaskCustomAttribute();
            props.closeModal();
          }}
        />
        <Button
          text={'キャンセル'}
          style={{
            minWidth: 100,
            marginRight: 10,
            backgroundColor: 'transparent',
          }}
          textStyle={{ color: themeContext.colors.primary }}
          disableValidate={true}
          onPress={async () => {
            props.closeModal();
          }}
        />
      </View>
    </Form>
  );
};

interface IProjectCustomAttributeRequiredModalProps {
  closeModal: () => void;
  projectCustomAttribute: ProjectCustomAttributeMaster;
}

const ProjectCustomAttributeRequiredModal = (props: IProjectCustomAttributeRequiredModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [required, setRequired] = useState(props!.projectCustomAttribute!.required);

  const [updateProjectCustomAttribute] = useUpdateProjectCustomAttributeMasterMutation({
    variables: {
      id: props.projectCustomAttribute.id!,
      input: {
        name: props.projectCustomAttribute.name,
        type: props.projectCustomAttribute.type,
        sortNo: props.projectCustomAttribute.sortNo,
        required: required,
        versionNo: props.projectCustomAttribute.versionNo,
      },
    },
  });

  useEffect(() => {
    setRequired(props.projectCustomAttribute.required);
  }, []);

  return (
    <Form style={{ marginTop: 10 }}>
      <Input
        name={'attributeType'}
        label={'項目タイプ'}
        type={'picker'}
        initialValue={props.projectCustomAttribute.required ? 'true' : 'false'}
        pickerItems={[
          {
            label:
              props.projectCustomAttribute.type === CustomerAttributeType.List
                ? '未選択を許可しない'
                : '値の入力を必須にする',
            value: 'true',
          },
          {
            label:
              props.projectCustomAttribute.type === CustomerAttributeType.List
                ? '未選択を許可する'
                : '値の入力を必須にしない',
            value: 'false',
          },
        ]}
        onChange={(value) => setRequired(value == 'true')}
      />
      <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1 }}>
        <Button
          text={'変更する'}
          style={{
            minWidth: 100,
            marginRight: 10,
          }}
          onPress={async () => {
            await updateProjectCustomAttribute();
            props.closeModal();
          }}
        />
        <Button
          text={'キャンセル'}
          style={{
            minWidth: 100,
            marginRight: 10,
            backgroundColor: 'transparent',
          }}
          textStyle={{ color: themeContext.colors.primary }}
          disableValidate={true}
          onPress={async () => {
            props.closeModal();
          }}
        />
      </View>
    </Form>
  );
};

interface ITaskCustomAttributeRequiredModalProps {
  closeModal: () => void;
  taskCustomAttribute: TaskCustomAttributeMaster;
}

const TaskCustomAttributeRequiredModal = (props: ITaskCustomAttributeRequiredModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [required, setRequired] = useState(props!.taskCustomAttribute!.required);

  const [updateProjectCustomAttribute] = useUpdateTaskCustomAttributeMasterMutation({
    variables: {
      id: props.taskCustomAttribute.id!,
      input: {
        name: props.taskCustomAttribute.name,
        type: props.taskCustomAttribute.type,
        sortNo: props.taskCustomAttribute.sortNo,
        required: required,
        versionNo: props.taskCustomAttribute.versionNo,
      },
    },
  });

  useEffect(() => {
    setRequired(props.taskCustomAttribute.required);
  }, []);

  return (
    <Form style={{ marginTop: 10 }}>
      <Input
        name={'attributeType'}
        label={'項目タイプ'}
        type={'picker'}
        initialValue={props.taskCustomAttribute.required ? 'true' : 'false'}
        pickerItems={[
          {
            label:
              props.taskCustomAttribute.type === CustomerAttributeType.List
                ? '未選択を許可しない'
                : '値の入力を必須にする',
            value: 'true',
          },
          {
            label:
              props.taskCustomAttribute.type === CustomerAttributeType.List
                ? '未選択を許可する'
                : '値の入力を必須にしない',
            value: 'false',
          },
        ]}
        onChange={(value) => setRequired(value == 'true')}
      />
      <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1 }}>
        <Button
          text={'変更する'}
          style={{
            minWidth: 100,
            marginRight: 10,
          }}
          onPress={async () => {
            await updateProjectCustomAttribute();
            props.closeModal();
          }}
        />
        <Button
          text={'キャンセル'}
          style={{
            minWidth: 100,
            marginRight: 10,
            backgroundColor: 'transparent',
          }}
          textStyle={{ color: themeContext.colors.primary }}
          disableValidate={true}
          onPress={async () => {
            props.closeModal();
          }}
        />
      </View>
    </Form>
  );
};

interface IProjectCustomAttributeListItemAddModalProps {
  closeModal: () => void;
  projectCustomAttribute: ProjectCustomAttributeMaster;
}

const ProjectCustomAttributeListItemAddModal = (
  props: IProjectCustomAttributeListItemAddModalProps
) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [value, setValue] = useState('');

  const [addItem] = useAddProjectCustomAttributeMasterListItemMutation({
    variables: {
      projectCustomAttributeMasterId: props.projectCustomAttribute.id!,
      input: {
        value: value,
      },
    },
    refetchQueries: [
      {
        query: ProjectCustomAttributeMasterDocument,
        variables: {
          id: props.projectCustomAttribute.id!,
        },
      },
    ],
  });

  return (
    <Form style={{ marginTop: 10 }}>
      <View style={{ minWidth: 400, marginTop: 10 }}>
        <Input
          name={`item`}
          label={`選択肢`}
          value={value.trim()}
          autoFocus
          onChange={(value) => setValue(value)}
          containerStyle={{
            maxWidth: 400,
            opacity: 1,
            marginBottom: 10,
          }}
          validate={{
            required: {
              value: true,
              message: '選択肢を入力してください',
            },
            validate: (value: string) => {
              if (
                props.projectCustomAttribute.listItem.filter(
                  (it) => it!.id !== props.projectCustomAttribute.id && it!.value === value
                ).length > 0
              ) {
                return '同じ値の選択肢が他に登録されています';
              }
              return true;
            },
          }}
        />
      </View>
      <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1 }}>
        <Button
          text={'追加する'}
          style={{
            minWidth: 100,
            marginRight: 10,
          }}
          onPress={async () => {
            await addItem();
            props.closeModal();
          }}
        />
        <Button
          text={'キャンセル'}
          style={{
            minWidth: 100,
            marginRight: 10,
            backgroundColor: 'transparent',
          }}
          textStyle={{ color: themeContext.colors.primary }}
          disableValidate={true}
          onPress={async () => {
            props.closeModal();
          }}
        />
      </View>
    </Form>
  );
};

interface ITaskCustomAttributeListItemAddModalProps {
  closeModal: () => void;
  taskCustomAttribute: TaskCustomAttributeMaster;
}

const TaskCustomAttributeListItemAddModal = (props: ITaskCustomAttributeListItemAddModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [value, setValue] = useState('');

  const [addItem] = useAddTaskCustomAttributeMasterListItemMutation({
    variables: {
      taskCustomAttributeMasterId: props.taskCustomAttribute.id!,
      input: {
        value: value,
      },
    },
    refetchQueries: [
      {
        query: TaskCustomAttributeMasterDocument,
        variables: {
          id: props.taskCustomAttribute.id!,
        },
      },
    ],
  });

  return (
    <Form style={{ marginTop: 10 }}>
      <View style={{ minWidth: 400, marginTop: 10 }}>
        <Input
          name={`item`}
          label={`選択肢`}
          value={value.trim()}
          autoFocus
          onChange={(value) => setValue(value)}
          containerStyle={{
            maxWidth: 400,
            opacity: 1,
            marginBottom: 10,
          }}
          validate={{
            required: {
              value: true,
              message: '選択肢を入力してください',
            },
            validate: (value: string) => {
              if (
                props.taskCustomAttribute.listItem.filter(
                  (it) => it!.id !== props.taskCustomAttribute.id && it!.value === value
                ).length > 0
              ) {
                return '同じ値の選択肢が他に登録されています';
              }
              return true;
            },
          }}
        />
      </View>
      <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1 }}>
        <Button
          text={'追加する'}
          style={{
            minWidth: 100,
            marginRight: 10,
          }}
          onPress={async () => {
            await addItem();
            props.closeModal();
          }}
        />
        <Button
          text={'キャンセル'}
          style={{
            minWidth: 100,
            marginRight: 10,
            backgroundColor: 'transparent',
          }}
          textStyle={{ color: themeContext.colors.primary }}
          disableValidate={true}
          onPress={async () => {
            props.closeModal();
          }}
        />
      </View>
    </Form>
  );
};

interface IProjectCustomAttributeListItemUpdateModalProps {
  showModal: boolean;
  projectCustomAttributeMaster: ProjectCustomAttributeMaster;
  projectCustomAttributeListItem: ProjectCustomAttributeMasterListItem;
  onClose: () => void;
}

const ProjectCustomAttributeListItemUpdateModal = (
  props: IProjectCustomAttributeListItemUpdateModalProps
) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [value, setValue] = useState(props.projectCustomAttributeListItem.value);

  const [updateItem] = useUpdateProjectCustomAttributeMasterListItemMutation({
    variables: {
      id: props.projectCustomAttributeListItem.id!,
      input: {
        value: value,
        sortNo: props.projectCustomAttributeListItem.sortNo,
        versionNo: props.projectCustomAttributeListItem.versionNo,
      },
    },
    refetchQueries: [
      {
        query: ProjectCustomAttributeMasterDocument,
        variables: {
          id: props.projectCustomAttributeMaster.id!,
        },
      },
    ],
  });

  useEffect(() => {
    setValue(props.projectCustomAttributeListItem.value);
  }, [props.showModal]);

  return (
    <Modal isShow={props.showModal} onClose={props.onClose} title="選択肢を変更する">
      <Typography
        variant={TypographyType.Description}
        style={{ marginLeft: 5, color: themeContext.colors.error, fontSize: 14 }}>
        {`選択肢を変更すると、すでにこの選択肢を使用しているプロジェクトにも影響があります。${'\n'}すでにこの選択肢を使用している場合、そちらも合わせて更新されます。`}
      </Typography>
      <Form style={{ marginTop: 10 }}>
        <View style={{ minWidth: 400, marginTop: 10, alignItems: 'center' }}>
          <Input
            name={`item`}
            label={`選択肢`}
            value={value.trim()}
            autoFocus
            onChange={(value) => setValue(value)}
            containerStyle={{
              maxWidth: 400,
              opacity: 1,
              marginBottom: 10,
            }}
            validate={{
              required: {
                value: true,
                message: '選択肢を入力してください',
              },
              validate: (value: string) => {
                if (
                  props.projectCustomAttributeMaster.listItem.filter(
                    (it) =>
                      it!.id !== props.projectCustomAttributeListItem.id && it!.value === value
                  ).length > 0
                ) {
                  return '同じ値の選択肢が他に登録されています';
                }
                return true;
              },
            }}
          />
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1 }}>
          <Button
            text={'変更する'}
            style={{
              minWidth: 100,
              marginRight: 10,
            }}
            onPress={async () => {
              await updateItem();
              props.onClose();
            }}
          />
          <Button
            text={'キャンセル'}
            style={{
              minWidth: 100,
              marginRight: 10,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.primary }}
            disableValidate={true}
            onPress={async () => {
              props.onClose();
            }}
          />
        </View>
      </Form>
    </Modal>
  );
};

interface ITaskCustomAttributeListItemUpdateModalProps {
  showModal: boolean;
  taskCustomAttributeMaster: TaskCustomAttributeMaster;
  taskCustomAttributeListItem: TaskCustomAttributeMasterListItem;
  onClose: () => void;
}

const TaskCustomAttributeListItemUpdateModal = (
  props: ITaskCustomAttributeListItemUpdateModalProps
) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [value, setValue] = useState(props.taskCustomAttributeListItem.value);

  const [updateItem] = useUpdateTaskCustomAttributeMasterListItemMutation({
    variables: {
      id: props.taskCustomAttributeListItem.id!,
      input: {
        value: value,
        sortNo: props.taskCustomAttributeListItem.sortNo,
        versionNo: props.taskCustomAttributeListItem.versionNo,
      },
    },
    refetchQueries: [
      {
        query: TaskCustomAttributeMasterDocument,
        variables: {
          id: props.taskCustomAttributeMaster.id!,
        },
      },
    ],
  });

  useEffect(() => {
    setValue(props.taskCustomAttributeListItem.value);
  }, [props.showModal]);

  return (
    <Modal isShow={props.showModal} onClose={props.onClose} title="選択肢を変更する">
      <Typography
        variant={TypographyType.Description}
        style={{ marginLeft: 5, color: themeContext.colors.error, fontSize: 14 }}>
        {`選択肢を変更すると、すでにこの選択肢を使用しているタスクにも影響があります。${'\n'}すでにこの選択肢を使用している場合、そちらも合わせて更新されます。`}
      </Typography>
      <Form style={{ marginTop: 10 }}>
        <View style={{ minWidth: 400, marginTop: 10, alignItems: 'center' }}>
          <Input
            name={`item`}
            label={`選択肢`}
            value={value.trim()}
            autoFocus
            onChange={(value) => setValue(value)}
            containerStyle={{
              maxWidth: 400,
              opacity: 1,
              marginBottom: 10,
            }}
            validate={{
              required: {
                value: true,
                message: '選択肢を入力してください',
              },
              validate: (value: string) => {
                if (
                  props.taskCustomAttributeMaster.listItem.filter(
                    (it) => it!.id !== props.taskCustomAttributeListItem.id && it!.value === value
                  ).length > 0
                ) {
                  return '同じ値の選択肢が他に登録されています';
                }
                return true;
              },
            }}
          />
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1 }}>
          <Button
            text={'変更する'}
            style={{
              minWidth: 100,
              marginRight: 10,
            }}
            onPress={async () => {
              await updateItem();
              props.onClose();
            }}
          />
          <Button
            text={'キャンセル'}
            style={{
              minWidth: 100,
              marginRight: 10,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.primary }}
            disableValidate={true}
            onPress={async () => {
              props.onClose();
            }}
          />
        </View>
      </Form>
    </Modal>
  );
};

interface IProjectCustomAttributeListItemDeleteModalProps {
  showModal: boolean;
  projectCustomAttributeMaster: ProjectCustomAttributeMaster;
  projectCustomAttributeListItem: ProjectCustomAttributeMasterListItem;
  onClose: () => void;
}

const ProjectCustomAttributeListItemDeleteModal = (
  props: IProjectCustomAttributeListItemDeleteModalProps
) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const history = useHistory();
  const [loginUser, __] = useContext(LoginUserContext);
  const [showModal, setShowModal] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [deleteItem, _] = useDeleteProjectCustomAttributeMasterListItemMutation({
    variables: {
      id: props.projectCustomAttributeListItem.id!,
      input: {
        versionNo: props.projectCustomAttributeListItem.versionNo,
      },
    },
    update(cache) {
      cache.evict({
        id: cache.identify(props.projectCustomAttributeListItem),
      });
    },
  });

  useEffect(() => {
    if (props.showModal) {
      setShowModal(true);
    }
  }, [props.showModal]);

  return (
    <>
      <Modal
        title={`「${props.projectCustomAttributeListItem.value}」を削除しますか？`}
        isShow={showModal}
        onClose={() => {
          setShowModal(false);
          setShowAlertModal(false);
          props.onClose();
        }}>
        <Typography
          variant={TypographyType.Normal}
          style={{ textAlign: 'center', marginVertical: 10 }}>
          {`この選択肢が使用されているプロジェクトは、カスタム項目の値が削除されます`}
        </Typography>
        <Form>
          <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
            <Button
              text={'キャンセル'}
              style={{
                minWidth: 100,
                marginRight: 10,
                marginVertical: 10,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.primary }}
              disableValidate={true}
              onPress={() => {
                setShowModal(false);
                setShowAlertModal(false);
                props.onClose();
              }}
            />
            <Button
              text={'削除する'}
              style={{
                minWidth: 100,
                marginRight: 10,
                marginVertical: 10,
                borderColor: themeContext.colors.error,
                borderRadius: 3,
                borderWidth: 1,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.error }}
              onPress={async () => {
                setShowModal(false);
                setShowAlertModal(true);
              }}
            />
          </View>
        </Form>
      </Modal>
      <Modal
        title={`本当に削除してよろしいですか？`}
        isShow={showAlertModal}
        onClose={() => {
          setShowModal(false);
          setShowAlertModal(false);
          props.onClose();
        }}>
        <Typography
          variant={TypographyType.Description}
          style={{ textAlign: 'center', color: themeContext.colors.error }}>
          {`この操作はやり直しが出来ません`}
        </Typography>
        <Form>
          <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
            <Button
              text={'キャンセル'}
              style={{
                minWidth: 100,
                marginRight: 10,
                marginVertical: 10,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.primary }}
              disableValidate={true}
              onPress={() => {
                setShowModal(false);
                setShowAlertModal(false);
                props.onClose();
              }}
            />
            <Button
              text={'削除する'}
              style={{
                minWidth: 100,
                marginRight: 10,
                marginVertical: 10,
                borderColor: themeContext.colors.error,
                borderRadius: 3,
                borderWidth: 1,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.error }}
              onPress={async () => {
                await deleteItem();
                setShowModal(false);
                setShowAlertModal(false);
                props.onClose();
              }}
            />
          </View>
        </Form>
      </Modal>
    </>
  );
};

interface ITaskCustomAttributeListItemDeleteModalProps {
  showModal: boolean;
  taskCustomAttributeMaster: TaskCustomAttributeMaster;
  taskCustomAttributeListItem: TaskCustomAttributeMasterListItem;
  onClose: () => void;
}

const TaskCustomAttributeListItemDeleteModal = (
  props: ITaskCustomAttributeListItemDeleteModalProps
) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const history = useHistory();
  const [loginUser, __] = useContext(LoginUserContext);
  const [showModal, setShowModal] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [deleteItem, _] = useDeleteTaskCustomAttributeMasterListItemMutation({
    variables: {
      id: props.taskCustomAttributeListItem.id!,
      input: {
        versionNo: props.taskCustomAttributeListItem.versionNo,
      },
    },
    update(cache) {
      cache.evict({
        id: cache.identify(props.taskCustomAttributeListItem),
      });
    },
  });

  useEffect(() => {
    if (props.showModal) {
      setShowModal(true);
    }
  }, [props.showModal]);

  return (
    <>
      <Modal
        title={`「${props.taskCustomAttributeListItem.value}」を削除しますか？`}
        isShow={showModal}
        onClose={() => {
          setShowModal(false);
          setShowAlertModal(false);
          props.onClose();
        }}>
        <Typography
          variant={TypographyType.Normal}
          style={{ textAlign: 'center', marginVertical: 10 }}>
          {`この選択肢が使用されているタスクは、カスタム項目の値が削除されます`}
        </Typography>
        <Form>
          <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
            <Button
              text={'キャンセル'}
              style={{
                minWidth: 100,
                marginRight: 10,
                marginVertical: 10,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.primary }}
              disableValidate={true}
              onPress={() => {
                setShowModal(false);
                setShowAlertModal(false);
                props.onClose();
              }}
            />
            <Button
              text={'削除する'}
              style={{
                minWidth: 100,
                marginRight: 10,
                marginVertical: 10,
                borderColor: themeContext.colors.error,
                borderRadius: 3,
                borderWidth: 1,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.error }}
              onPress={async () => {
                setShowModal(false);
                setShowAlertModal(true);
              }}
            />
          </View>
        </Form>
      </Modal>
      <Modal
        title={`本当に削除してよろしいですか？`}
        isShow={showAlertModal}
        onClose={() => {
          setShowModal(false);
          setShowAlertModal(false);
          props.onClose();
        }}>
        <Typography
          variant={TypographyType.Description}
          style={{ textAlign: 'center', color: themeContext.colors.error }}>
          {`この操作はやり直しが出来ません`}
        </Typography>
        <Form>
          <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
            <Button
              text={'キャンセル'}
              style={{
                minWidth: 100,
                marginRight: 10,
                marginVertical: 10,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.primary }}
              disableValidate={true}
              onPress={() => {
                setShowModal(false);
                setShowAlertModal(false);
                props.onClose();
              }}
            />
            <Button
              text={'削除する'}
              style={{
                minWidth: 100,
                marginRight: 10,
                marginVertical: 10,
                borderColor: themeContext.colors.error,
                borderRadius: 3,
                borderWidth: 1,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.error }}
              onPress={async () => {
                await deleteItem();
                setShowModal(false);
                setShowAlertModal(false);
                props.onClose();
              }}
            />
          </View>
        </Form>
      </Modal>
    </>
  );
};

interface IDeleteCustomAttributeDialogProps {
  customAttribute: ProjectCustomAttributeMaster;
  me: Member;
}

const DeleteCustomAttributeDialog = (props: IDeleteCustomAttributeDialogProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const history = useHistory();
  const [loginUser, __] = useContext(LoginUserContext);
  const [showModal, setShowModal] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [deleteCustomAttribute, _] = useDeleteProjectCustomAttributeMasterMutation({
    variables: {
      id: props.customAttribute.id!,
      input: {
        versionNo: props.customAttribute.versionNo,
      },
    },
    update(cache) {
      cache.evict({
        id: cache.identify(props.customAttribute),
      });
    },
  });

  return (
    <>
      <DeleteIcon
        size={23}
        disabled={!props.me.projectCustomAttributePermissionFlg}
        onPress={() => {
          setShowModal(true);
        }}>
        <Typography variant={TypographyType.Normal} style={{ fontSize: 16 }}>
          削除する
        </Typography>
      </DeleteIcon>
      <Modal
        title={`「${props.customAttribute.name}」を削除しますか？`}
        isShow={showModal}
        onClose={() => {
          setShowModal(false);
          setShowAlertModal(false);
        }}>
        <Typography
          variant={TypographyType.Normal}
          style={{ textAlign: 'center', marginVertical: 10 }}>
          {`このカスタム項目が使用されているプロジェクトからは、項目が削除されます`}
        </Typography>
        <Form>
          <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
            <Button
              text={'キャンセル'}
              style={{
                minWidth: 100,
                marginRight: 10,
                marginVertical: 10,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.primary }}
              disableValidate={true}
              onPress={() => {
                setShowModal(false);
                setShowAlertModal(false);
              }}
            />
            <Button
              text={'削除する'}
              style={{
                minWidth: 100,
                marginRight: 10,
                marginVertical: 10,
                borderColor: themeContext.colors.error,
                borderRadius: 3,
                borderWidth: 1,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.error }}
              onPress={async () => {
                setShowModal(false);
                setShowAlertModal(true);
              }}
            />
          </View>
        </Form>
      </Modal>
      <Modal
        title={`本当に削除してよろしいですか？`}
        isShow={showAlertModal}
        onClose={() => {
          setShowModal(false);
          setShowAlertModal(false);
        }}>
        <Typography
          variant={TypographyType.Description}
          style={{ textAlign: 'center', color: themeContext.colors.error }}>
          {`この操作はやり直しが出来ません`}
        </Typography>
        <Form>
          <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
            <Button
              text={'キャンセル'}
              style={{
                minWidth: 100,
                marginRight: 10,
                marginVertical: 10,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.primary }}
              disableValidate={true}
              onPress={() => {
                setShowModal(false);
                setShowAlertModal(false);
              }}
            />
            <Button
              text={'削除する'}
              style={{
                minWidth: 100,
                marginRight: 10,
                marginVertical: 10,
                borderColor: themeContext.colors.error,
                borderRadius: 3,
                borderWidth: 1,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.error }}
              onPress={async () => {
                await deleteCustomAttribute();
                setShowModal(false);
                setShowAlertModal(false);
                history.push(`/app/${loginUser!.organizationId}/custom-attribute/project/`);
              }}
            />
          </View>
        </Form>
      </Modal>
    </>
  );
};

interface IDeleteTaskCustomAttributeDialogProps {
  customAttribute: TaskCustomAttributeMaster;
  me: Member;
}

const DeleteTaskCustomAttributeDialog = (props: IDeleteTaskCustomAttributeDialogProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const history = useHistory();
  const [loginUser, __] = useContext(LoginUserContext);
  const [showModal, setShowModal] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [deleteCustomAttribute, _] = useDeleteTaskCustomAttributeMasterMutation({
    variables: {
      id: props.customAttribute.id!,
      input: {
        versionNo: props.customAttribute.versionNo,
      },
    },
    update(cache) {
      cache.evict({
        id: cache.identify(props.customAttribute),
      });
    },
  });

  return (
    <>
      <DeleteIcon
        size={23}
        disabled={!props.me.taskCustomAttributePermissionFlg}
        onPress={() => {
          setShowModal(true);
        }}>
        <Typography variant={TypographyType.Normal} style={{ fontSize: 16 }}>
          {`削除する`}
        </Typography>
      </DeleteIcon>
      <Modal
        title={`「${props.customAttribute.name}」を削除しますか？`}
        isShow={showModal}
        onClose={() => {
          setShowModal(false);
          setShowAlertModal(false);
        }}>
        <Typography
          variant={TypographyType.Normal}
          style={{ textAlign: 'center', marginVertical: 10 }}>
          {`このカスタム項目が使用されているタスクからは、項目が削除されます`}
        </Typography>
        <Form>
          <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
            <Button
              text={'キャンセル'}
              style={{
                minWidth: 100,
                marginRight: 10,
                marginVertical: 10,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.primary }}
              disableValidate={true}
              onPress={() => {
                setShowModal(false);
                setShowAlertModal(false);
              }}
            />
            <Button
              text={'削除する'}
              style={{
                minWidth: 100,
                marginRight: 10,
                marginVertical: 10,
                borderColor: themeContext.colors.error,
                borderRadius: 3,
                borderWidth: 1,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.error }}
              onPress={async () => {
                setShowModal(false);
                setShowAlertModal(true);
              }}
            />
          </View>
        </Form>
      </Modal>
      <Modal
        title={`本当に削除してよろしいですか？`}
        isShow={showAlertModal}
        onClose={() => {
          setShowModal(false);
          setShowAlertModal(false);
        }}>
        <Typography
          variant={TypographyType.Description}
          style={{ textAlign: 'center', color: themeContext.colors.error }}>
          {`この操作はやり直しが出来ません`}
        </Typography>
        <Form>
          <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
            <Button
              text={'キャンセル'}
              style={{
                minWidth: 100,
                marginRight: 10,
                marginVertical: 10,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.primary }}
              disableValidate={true}
              onPress={() => {
                setShowModal(false);
                setShowAlertModal(false);
              }}
            />
            <Button
              text={'削除する'}
              style={{
                minWidth: 100,
                marginRight: 10,
                marginVertical: 10,
                borderColor: themeContext.colors.error,
                borderRadius: 3,
                borderWidth: 1,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.error }}
              onPress={async () => {
                await deleteCustomAttribute();
                setShowModal(false);
                setShowAlertModal(false);
                history.push(`/app/${loginUser!.organizationId}/custom-attribute/task/`);
              }}
            />
          </View>
        </Form>
      </Modal>
    </>
  );
};

interface IProjectAttributeItemRowProps {
  projectCustomAttributeMaster: ProjectCustomAttributeMaster;
  item: ProjectCustomAttributeMasterListItem;
  me: Member;
}

const ProjectAttributeItemRow = (props: IProjectAttributeItemRowProps) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  return (
    <View style={{ flexDirection: 'row', cursor: 'grab' } as any}>
      <Typography variant={TypographyType.Normal} style={{ minWidth: 100 }}>
        {props.item.value}
      </Typography>
      <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 10 }}>
        <EditIcon
          size={24}
          disabled={!props.me.projectCustomAttributePermissionFlg}
          onPress={() => setShowEditModal(true)}>
          <Typography variant={TypographyType.Description} style={{ marginLeft: 5 }}>
            変更する
          </Typography>
        </EditIcon>
      </View>
      <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 10 }}>
        <DeleteIcon
          size={24}
          disabled={!props.me.projectCustomAttributePermissionFlg}
          onPress={() => setShowDeleteModal(true)}>
          <Typography variant={TypographyType.Description} style={{ marginLeft: 5 }}>
            削除する
          </Typography>
        </DeleteIcon>
      </View>
      <ProjectCustomAttributeListItemUpdateModal
        showModal={showEditModal}
        projectCustomAttributeMaster={props.projectCustomAttributeMaster}
        projectCustomAttributeListItem={props.item}
        onClose={() => setShowEditModal(false)}
      />
      <ProjectCustomAttributeListItemDeleteModal
        showModal={showDeleteModal}
        projectCustomAttributeMaster={props.projectCustomAttributeMaster}
        projectCustomAttributeListItem={props.item}
        onClose={() => setShowDeleteModal(false)}
      />
    </View>
  );
};

interface ITaskAttributeItemRowProps {
  taskCustomAttributeMaster: TaskCustomAttributeMaster;
  item: TaskCustomAttributeMasterListItem;
  me: Member;
}

const TaskAttributeItemRow = (props: ITaskAttributeItemRowProps) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  return (
    <View style={{ flexDirection: 'row', cursor: 'grab' } as any}>
      <Typography variant={TypographyType.Normal} style={{ minWidth: 100 }}>
        {props.item.value}
      </Typography>
      <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 10 }}>
        <EditIcon
          size={24}
          disabled={!props.me.taskCustomAttributePermissionFlg}
          onPress={() => setShowEditModal(true)}>
          <Typography variant={TypographyType.Description} style={{ marginLeft: 5 }}>
            変更する
          </Typography>
        </EditIcon>
      </View>
      <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 10 }}>
        <DeleteIcon
          size={24}
          disabled={!props.me.taskCustomAttributePermissionFlg}
          onPress={() => setShowDeleteModal(true)}>
          <Typography variant={TypographyType.Description} style={{ marginLeft: 5 }}>
            削除する
          </Typography>
        </DeleteIcon>
      </View>
      <TaskCustomAttributeListItemUpdateModal
        showModal={showEditModal}
        taskCustomAttributeMaster={props.taskCustomAttributeMaster}
        taskCustomAttributeListItem={props.item}
        onClose={() => setShowEditModal(false)}
      />
      <TaskCustomAttributeListItemDeleteModal
        showModal={showDeleteModal}
        taskCustomAttributeMaster={props.taskCustomAttributeMaster}
        taskCustomAttributeListItem={props.item}
        onClose={() => setShowDeleteModal(false)}
      />
    </View>
  );
};

interface IProjectAttributeContainerrInnerProps {
  projectCustomAttribute: ProjectCustomAttributeMaster;
}

const ProjectAttributeContainerrInner = (props: IProjectAttributeContainerrInnerProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [updateItem] = useUpdateProjectCustomAttributeMasterListItemMutation();

  const { loading: meLoading, data: meData } = useMeQuery();

  if (meLoading) {
    return <></>;
  }

  return (
    <CustomScrollView style={{ paddingBottom: 20 }}>
      <MainAreaHeader style={{} as any}>
        <Typography
          variant={TypographyType.Normal}
          style={[{ color: '#FFFFFF', fontWeight: '600' }, {}] as any}>
          {props.projectCustomAttribute.name}
        </Typography>
      </MainAreaHeader>
      <View
        style={{
          flexDirection: 'column',
          paddingVertical: 20,
          paddingHorizontal: 30,
        }}>
        {!meData?.me?.projectCustomAttributePermissionFlg && (
          <Typography
            variant={TypographyType.Normal}
            style={{ color: themeContext.colors.description, marginBottom: 20 }}>
            ※プロジェクトのカスタム項目の編集権限がありません
          </Typography>
        )}
        <SettingsElement
          title={'項目名'}
          titleWidth={170}
          changeText={'項目名を変更する'}
          disabled={!meData?.me?.projectCustomAttributePermissionFlg}
          modal={(closeModal) => (
            <ProjectCustomAttributeNameModal
              closeModal={closeModal}
              projectCustomAttribute={props.projectCustomAttribute}
            />
          )}>
          <Typography variant={TypographyType.Normal}>
            {props.projectCustomAttribute.name}
          </Typography>
        </SettingsElement>
        <SettingsElement title={'項目タイプ'} titleWidth={170}>
          <>
            <Typography variant={TypographyType.Normal}>
              {when(props.projectCustomAttribute.type)
                .on(
                  (v) => v === CustomerAttributeType.FreeText,
                  () => 'フリーテキスト'
                )
                .on(
                  (v) => v === CustomerAttributeType.Number,
                  () => '数値'
                )
                .on(
                  (v) => v === CustomerAttributeType.Date,
                  () => '日付'
                )
                .on(
                  (v) => v === CustomerAttributeType.DateTime,
                  () => '日時'
                )
                .on(
                  (v) => v === CustomerAttributeType.List,
                  () => '選択リスト'
                )
                .otherwise(() => '-')}
            </Typography>
            <Typography variant={TypographyType.Description}>
              {'項目タイプは変更できません'}
            </Typography>
          </>
        </SettingsElement>
        {props.projectCustomAttribute.type === CustomerAttributeType.List && (
          <SettingsElement
            title={'選択肢'}
            titleWidth={170}
            changeText={'選択肢を追加する'}
            disabled={!meData?.me?.projectCustomAttributePermissionFlg}
            modal={(closeModal) => (
              <ProjectCustomAttributeListItemAddModal
                closeModal={closeModal}
                projectCustomAttribute={props.projectCustomAttribute}
              />
            )}>
            <GlobalDragContextProvider>
              <VirtualizedFlatList
                style={{ height: 'auto', marginBottom: 20 }}
                isDraggable
                virticalDraggable
                items={props.projectCustomAttribute.listItem
                  .slice()
                  .sort((a, b) => b!.sortNo - a!.sortNo)}
                renderItem={(item) => (
                  <ProjectAttributeItemRow
                    item={item as ProjectCustomAttributeMasterListItem}
                    projectCustomAttributeMaster={props.projectCustomAttribute}
                    me={meData?.me!}
                  />
                )}
                onDrop={async (info) => {
                  if (!meData?.me?.projectCustomAttributePermissionFlg) {
                    return;
                  }
                  const sortedItems = props.projectCustomAttribute.listItem
                    .slice()
                    .sort((a, b) => b!.sortNo - a!.sortNo);

                  const isMoveToFirst = info.endRowIndex === 0;
                  const isMoveToLast = info.endRowIndex === sortedItems.length - 1;
                  const isMoveToDown = info.endRowIndex - info.startRowIndex > 0;

                  let sortNo;
                  if (isMoveToFirst) {
                    sortNo = new Date().getTime();
                  } else if (isMoveToLast) {
                    sortNo = sortedItems[info.endRowIndex]!.sortNo - 1000;
                  } else {
                    if (isMoveToDown) {
                      const beforeTask = sortedItems[info.endRowIndex];
                      const afterTask = sortedItems[info.endRowIndex + 1];
                      sortNo = Math.floor((beforeTask!.sortNo + afterTask!.sortNo) / 2);
                    } else {
                      const beforeTask = sortedItems[info.endRowIndex - 1];
                      const afterTask = sortedItems[info.endRowIndex];
                      sortNo = Math.floor((beforeTask!.sortNo + afterTask!.sortNo) / 2);
                    }
                  }

                  await updateItem({
                    variables: {
                      id: (info.item as ProjectCustomAttributeMasterListItem).id!,
                      input: {
                        sortNo: sortNo,
                        value: (info.item as ProjectCustomAttributeMasterListItem).value,
                        versionNo: (info.item as ProjectCustomAttributeMasterListItem).versionNo,
                      },
                    },
                  });
                }}
                getKey={(item) => (item as ProjectCustomAttributeMasterListItem).id!}
                itemHeight={30}
              />
            </GlobalDragContextProvider>
          </SettingsElement>
        )}
        <SettingsElement
          title={'値の入力ルール'}
          titleWidth={170}
          changeText={'ルールを変更する'}
          disabled={!meData?.me?.projectCustomAttributePermissionFlg}
          modal={(closeModal) => (
            <ProjectCustomAttributeRequiredModal
              closeModal={closeModal}
              projectCustomAttribute={props.projectCustomAttribute}
            />
          )}>
          {props.projectCustomAttribute.type === CustomerAttributeType.List ? (
            <Typography variant={TypographyType.Normal}>
              {props.projectCustomAttribute.required ? '未選択を許可しない' : '未選択を許可する'}
            </Typography>
          ) : (
            <Typography variant={TypographyType.Normal}>
              {props.projectCustomAttribute.required
                ? '値の入力を必須にする'
                : '値の入力を必須にしない'}
            </Typography>
          )}
        </SettingsElement>
        <SettingsElement titleWidth={170} title={'カスタム項目の削除'}>
          <DeleteCustomAttributeDialog
            customAttribute={props.projectCustomAttribute}
            me={meData?.me!}
          />
        </SettingsElement>
      </View>
    </CustomScrollView>
  );
};

const CanNotEditTaskCustomAttributeModal = () => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <Modal
      title={'プロジェクトのカスタム項目を追加できません'}
      isShow={show}
      onClose={() => {
        setShow(false);
      }}>
      <Typography
        variant={TypographyType.Normal}
        style={{ textAlign: 'center', marginVertical: 10 }}>
        プロジェクトのカスタム項目の編集権限が必要です。
      </Typography>
      <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
        <Button
          text={'OK'}
          style={{ minWidth: 100, marginRight: 10, marginVertical: 20 }}
          onPress={async () => {
            setShow(false);
          }}
        />
      </View>
    </Modal>
  );
};

interface ITaskAttributeContainerrInnerProps {
  taskCustomAttribute: TaskCustomAttributeMaster;
}

const TaskAttributeContainerrInner = (props: ITaskAttributeContainerrInnerProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [updateItem] = useUpdateTaskCustomAttributeMasterListItemMutation();
  const { loading: meLoading, data: meData } = useMeQuery();

  if (meLoading) {
    return <></>;
  }

  return (
    <CustomScrollView style={{ paddingBottom: 20 }}>
      <MainAreaHeader style={{} as any}>
        <Typography
          variant={TypographyType.Normal}
          style={[{ color: '#FFFFFF', fontWeight: '600' }, {}] as any}>
          {props.taskCustomAttribute.name}
        </Typography>
      </MainAreaHeader>
      <View
        style={{
          flexDirection: 'column',
          paddingVertical: 20,
          paddingHorizontal: 30,
        }}>
        {!meData?.me?.taskCustomAttributePermissionFlg && (
          <Typography
            variant={TypographyType.Normal}
            style={{ color: themeContext.colors.description, marginBottom: 20 }}>
            ※タスクのカスタム項目の編集権限がありません
          </Typography>
        )}
        <SettingsElement
          title={'項目名'}
          titleWidth={170}
          changeText={'項目名を変更する'}
          disabled={!meData?.me?.taskCustomAttributePermissionFlg}
          modal={(closeModal) => (
            <TaskCustomAttributeNameModal
              closeModal={closeModal}
              taskCustomAttribute={props.taskCustomAttribute}
            />
          )}>
          <Typography variant={TypographyType.Normal}>{props.taskCustomAttribute.name}</Typography>
        </SettingsElement>
        <SettingsElement title={'項目タイプ'} titleWidth={170}>
          <>
            <Typography variant={TypographyType.Normal}>
              {when(props.taskCustomAttribute.type)
                .on(
                  (v) => v === CustomerAttributeType.FreeText,
                  () => 'フリーテキスト'
                )
                .on(
                  (v) => v === CustomerAttributeType.Number,
                  () => '数値'
                )
                .on(
                  (v) => v === CustomerAttributeType.Date,
                  () => '日付'
                )
                .on(
                  (v) => v === CustomerAttributeType.DateTime,
                  () => '日時'
                )
                .on(
                  (v) => v === CustomerAttributeType.List,
                  () => '選択リスト'
                )
                .otherwise(() => '-')}
            </Typography>
            <Typography variant={TypographyType.Description}>
              {'項目タイプは変更できません'}
            </Typography>
          </>
        </SettingsElement>
        {props.taskCustomAttribute.type === CustomerAttributeType.List && (
          <SettingsElement
            title={'選択肢'}
            titleWidth={170}
            changeText={'選択肢を追加する'}
            disabled={!meData?.me?.taskCustomAttributePermissionFlg}
            modal={(closeModal) => (
              <TaskCustomAttributeListItemAddModal
                closeModal={closeModal}
                taskCustomAttribute={props.taskCustomAttribute}
              />
            )}>
            <GlobalDragContextProvider>
              <VirtualizedFlatList
                style={{ height: 'auto', marginBottom: 20 }}
                isDraggable
                virticalDraggable
                items={props.taskCustomAttribute.listItem
                  .slice()
                  .sort((a, b) => b!.sortNo - a!.sortNo)}
                renderItem={(item) => (
                  <TaskAttributeItemRow
                    item={item as TaskCustomAttributeMasterListItem}
                    taskCustomAttributeMaster={props.taskCustomAttribute}
                    me={meData?.me!}
                  />
                )}
                onDrop={async (info) => {
                  if (!meData?.me?.taskCustomAttributePermissionFlg) {
                    return;
                  }
                  const sortedItems = props.taskCustomAttribute.listItem
                    .slice()
                    .sort((a, b) => b!.sortNo - a!.sortNo);

                  const isMoveToFirst = info.endRowIndex === 0;
                  const isMoveToLast = info.endRowIndex === sortedItems.length - 1;
                  const isMoveToDown = info.endRowIndex - info.startRowIndex > 0;

                  let sortNo;
                  if (isMoveToFirst) {
                    sortNo = new Date().getTime();
                  } else if (isMoveToLast) {
                    sortNo = sortedItems[info.endRowIndex]!.sortNo - 1000;
                  } else {
                    if (isMoveToDown) {
                      const beforeTask = sortedItems[info.endRowIndex];
                      const afterTask = sortedItems[info.endRowIndex + 1];
                      sortNo = Math.floor((beforeTask!.sortNo + afterTask!.sortNo) / 2);
                    } else {
                      const beforeTask = sortedItems[info.endRowIndex - 1];
                      const afterTask = sortedItems[info.endRowIndex];
                      sortNo = Math.floor((beforeTask!.sortNo + afterTask!.sortNo) / 2);
                    }
                  }

                  await updateItem({
                    variables: {
                      id: (info.item as TaskCustomAttributeMasterListItem).id!,
                      input: {
                        sortNo: sortNo,
                        value: (info.item as TaskCustomAttributeMasterListItem).value,
                        versionNo: (info.item as TaskCustomAttributeMasterListItem).versionNo,
                      },
                    },
                  });
                }}
                getKey={(item) => (item as TaskCustomAttributeMasterListItem).id!}
                itemHeight={30}
              />
            </GlobalDragContextProvider>
          </SettingsElement>
        )}
        <SettingsElement
          title={'値の入力ルール'}
          titleWidth={170}
          changeText={'ルールを変更する'}
          disabled={!meData?.me?.taskCustomAttributePermissionFlg}
          modal={(closeModal) => (
            <TaskCustomAttributeRequiredModal
              closeModal={closeModal}
              taskCustomAttribute={props.taskCustomAttribute}
            />
          )}>
          {props.taskCustomAttribute.type === CustomerAttributeType.List ? (
            <Typography variant={TypographyType.Normal}>
              {props.taskCustomAttribute.required ? '未選択を許可しない' : '未選択を許可する'}
            </Typography>
          ) : (
            <Typography variant={TypographyType.Normal}>
              {props.taskCustomAttribute.required
                ? '値の入力を必須にする'
                : '値の入力を必須にしない'}
            </Typography>
          )}
        </SettingsElement>
        <SettingsElement titleWidth={170} title={'カスタム項目の削除'}>
          <DeleteTaskCustomAttributeDialog
            customAttribute={props.taskCustomAttribute}
            me={meData?.me!}
          />
        </SettingsElement>
      </View>
    </CustomScrollView>
  );
};

const ProjectAttributeContainer = React.memo(() => {
  const params = useParams();
  const { loading, data } = useProjectCustomAttributeMasterQuery({
    variables: {
      id: (params as any).projectCustomAtrributeId,
    },
    fetchPolicy: 'network-only',
  });
  if (loading || !data?.projectCustomAttributeMaster) {
    return <></>;
  }

  return (
    <ProjectAttributeContainerrInner projectCustomAttribute={data!.projectCustomAttributeMaster} />
  );
});

const TaskAttributeContainer = React.memo(() => {
  const params = useParams();
  const { loading, data } = useTaskCustomAttributeMasterQuery({
    variables: {
      id: (params as any).taskCustomAtrributeId,
    },
    fetchPolicy: 'network-only',
  });
  if (loading || !data?.taskCustomAttributeMaster) {
    return <></>;
  }

  return <TaskAttributeContainerrInner taskCustomAttribute={data!.taskCustomAttributeMaster} />;
});

const ProjectAttributeMainArea = React.memo(() => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const { window } = useDimensions();
  const { loading, data } = useProjectCustomAttributeMastersQuery({
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <></>;
  }

  return (
    <MainAreaContainer>
      <RouterSwitch>
        <Route path="/app/:organizationId/custom-attribute/project/:projectCustomAtrributeId/">
          <ProjectAttributeContainer />
        </Route>
        <Route>
          <View
            style={{
              height: 'calc(100vh - 57px)',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            {data?.projectCustomAttributeMasters?.length ?? 0 > 0 ? (
              <>
                <Image
                  source={{ uri: noData01 }}
                  resizeMode="contain"
                  style={{
                    width: Math.min(window.width / 4, 300),
                    height: 200,
                    opacity: 0.7,
                  }}
                />
                <Typography
                  variant={TypographyType.Normal}
                  style={{ color: themeContext.colors.description }}>
                  カスタム項目を選択してください
                </Typography>
              </>
            ) : (
              <>
                <Image
                  source={{ uri: noData03 }}
                  resizeMode="contain"
                  style={{
                    width: Math.min(window.width / 4, 300),
                    height: 200,
                    opacity: 0.7,
                  }}
                />
                <Typography
                  variant={TypographyType.Normal}
                  style={{ color: themeContext.colors.description }}>
                  カスタム項目を登録してください
                </Typography>
              </>
            )}
          </View>
        </Route>
      </RouterSwitch>
    </MainAreaContainer>
  );
});

const TaskAttributeMainArea = React.memo(() => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const { window } = useDimensions();
  const { loading, data } = useTaskCustomAttributeMastersQuery({
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <></>;
  }

  return (
    <MainAreaContainer>
      <RouterSwitch>
        <Route path="/app/:organizationId/custom-attribute/task/:taskCustomAtrributeId/">
          <TaskAttributeContainer />
        </Route>
        <Route>
          <View
            style={{
              height: 'calc(100vh - 57px)',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            {data?.taskCustomAttributeMasters?.length ?? 0 > 0 ? (
              <>
                <Image
                  source={{ uri: noData01 }}
                  resizeMode="contain"
                  style={{
                    width: Math.min(window.width / 4, 300),
                    height: 200,
                    opacity: 0.7,
                  }}
                />
                <Typography
                  variant={TypographyType.Normal}
                  style={{ color: themeContext.colors.description }}>
                  カスタム項目を選択してください
                </Typography>
              </>
            ) : (
              <>
                <Image
                  source={{ uri: noData03 }}
                  resizeMode="contain"
                  style={{
                    width: Math.min(window.width / 4, 300),
                    height: 200,
                    opacity: 0.7,
                  }}
                />
                <Typography
                  variant={TypographyType.Normal}
                  style={{ color: themeContext.colors.description }}>
                  カスタム項目を登録してください
                </Typography>
              </>
            )}
          </View>
        </Route>
      </RouterSwitch>
    </MainAreaContainer>
  );
});

const AppCustomAttributePage = () => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [notAllowedPlan, setNotAllowedPlan] = useState(false);

  const { loading: organizationLoading, data: organizationData } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId,
    },
    fetchPolicy: 'network-only',
  });

  // Basicプランの場合には使用できないようにする
  useEffect(() => {
    setTimeout(() => {
      if (organizationData?.organization?.plan.code === Plan.Basic) {
        setNotAllowedPlan(true);
      }
    }, 1000);
  }, [organizationData]);

  if (organizationLoading) {
    return <></>;
  }

  return (
    <Container>
      <PlanNotAllowedView
        isNotAllowedPlan={notAllowedPlan}
        style={{ height: 'calc(100vh)', width: '100%' }}>
        <CustomScrollView
          style={{
            maxWidth: 200,
            height: 'calc(100vh - 57px)',
          }}>
          <View
            style={{
              backgroundColor: themeContext.colors.header,
              paddingVertical: 7,
              paddingHorizontal: 3,
            }}>
            <Typography
              variant={TypographyType.SubTitle}
              ellipsis={true}
              style={{ color: '#FFFFFF', fontSize: 16, fontWeight: '600', maxWidth: 200 }}>
              カスタム項目の管理
            </Typography>
          </View>
          <TextList
            items={[
              {
                data: 'プロジェクト',
                path: `/app/${loginUser!.organizationId}/custom-attribute/project`,
              },
              {
                data: 'タスク',
                path: `/app/${loginUser!.organizationId}/custom-attribute/task`,
              },
            ]}
            style={{ width: 200 }}
            textStyle={{ fontSize: 14 }}
            onPress={(item) => {}}
          />
        </CustomScrollView>
        <RouterSwitch>
          <Route
            path={`/app/:organizationId/custom-attribute/project`}
            component={ProjectCustomAttributeListtWrapper}
          />
          <Route
            path={`/app/:organizationId/custom-attribute/task`}
            component={TaskCustomAttributeListtWrapper}
          />
          <Redirect to={`/app/:organizationId/custom-attribute/project`} />
        </RouterSwitch>
        <RouterSwitch>
          <Route
            path={`/app/:organizationId/custom-attribute/project`}
            component={ProjectAttributeMainArea}
          />
          <Route
            path={`/app/:organizationId/custom-attribute/task`}
            component={TaskAttributeMainArea}
          />
          <Redirect to={`/app/:organizationId/custom-attribute/project`} />
        </RouterSwitch>
      </PlanNotAllowedView>
    </Container>
  );
};

export default React.memo(AppCustomAttributePage);
