import React, { useContext, useEffect, useRef, useState } from 'react';
import { View, TouchableOpacity } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../theme';
import { SketchPicker, ColorResult } from 'react-color';
import Typography, { TypographyType } from '../typography';
import Modal from '../../molecules/modal';
import Button from '../button';

interface IProps {
  color: string | null | undefined;
  onChange: (value: string | null | undefined) => void;
  onShowPicker?: () => void;
  onClosePicker?: () => void;
  editable?: boolean;
  onClickWhenNotEditable?: () => void;
}

export const ColorPicker: React.FC<IProps> = (props: IProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [color, setColor] = useState<ColorResult | null | undefined>(
    props.color ? ({ hex: props.color } as ColorResult) : undefined
  );
  const [showPicker, setShowPicker] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isOverY, setOverY] = useState(false);
  const pickerRef = useRef();

  useEffect(() => {
    setShowModal(false);
    setShowPicker(false);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setOverY((pickerRef.current as any)?.getBoundingClientRect().bottom > window.innerHeight);
    }, 400);
  }, [showPicker]);

  useEffect(() => {
    if (showPicker) {
      if (props.onShowPicker) {
        props.onShowPicker();
      }
    } else {
      if (props.onClosePicker) {
        props.onClosePicker();
      }
    }
  }, [showPicker]);

  return (
    <View style={{ flexDirection: 'row' }}>
      <TouchableOpacity
        onPress={() => {
          if (props.editable === false) {
            if (props.onClickWhenNotEditable) {
              props.onClickWhenNotEditable();
            }
            return;
          }
          setShowPicker(true);
        }}
        style={{
          backgroundColor: color?.hex ?? '#fff',
          borderColor: color?.hex ? undefined : '#eee',
          borderWidth: color?.hex ? undefined : 1,
          width: 30,
          height: 30,
          marginLeft: 10,
        }}
      />
      {color ? (
        <TouchableOpacity onPress={() => setShowModal(true)} style={{ marginLeft: 10 }}>
          <Typography variant={TypographyType.Normal} style={{ color: themeContext.colors.link }}>
            色を未指定に戻す
          </Typography>
        </TouchableOpacity>
      ) : (
        <TouchableOpacity onPress={() => setShowPicker(true)} style={{ marginLeft: 10 }}>
          <Typography
            variant={TypographyType.Normal}
            style={{ color: themeContext.colors.description }}>
            指定なし
          </Typography>
        </TouchableOpacity>
      )}
      {showModal && (
        <Modal
          title={'色を未指定に戻しますか？'}
          isShow={showModal}
          onClose={() => {
            setShowModal(false);
          }}>
          <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1 }}>
            <Button
              text={'未指定にする'}
              completeText="変更しました"
              style={{ minWidth: 100, marginRight: 10, marginVertical: 10 }}
              onPress={async () => {
                props.onChange(null);
                setColor(null);
                setShowModal(false);
              }}
            />
            <Button
              text={'キャンセル'}
              style={{
                minWidth: 100,
                marginRight: 10,
                marginVertical: 10,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.primary }}
              disableValidate={true}
              onPress={() => {
                setShowModal(false);
              }}
            />
          </View>
        </Modal>
      )}
      {showPicker && (
        <View
          style={{ position: 'absolute', left: 50, top: isOverY ? -100 : -10 }}
          ref={pickerRef as any}>
          <SketchPicker
            disableAlpha
            color={color?.hex ? (color as any) : undefined}
            onChange={(pickedColor, event) => {
              event.preventDefault();
              setColor(pickedColor as any);
            }}
          />
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              backgroundColor: '#FFFFFF',
              marginTop: -5,
              borderColor: '#ccc',
              borderWidth: 1,
              borderTopWidth: 0,
              shadowColor: '#000',
              shadowOffset: {
                width: 0,
                height: 3,
              },
              shadowOpacity: 0.29,
              shadowRadius: 4.65,
              elevation: 7,
            }}>
            <Button
              text={'決定'}
              completeText="決定"
              style={{ marginVertical: 5 }}
              onPress={async () => {
                props.onChange(color?.hex);
                setShowPicker(false);
                setShowModal(false);
              }}
            />
            <Button
              text={'キャンセル'}
              style={{
                marginVertical: 5,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.primary }}
              disableValidate={true}
              onPress={() => {
                props.onChange(props.color);
                setColor(props.color ? ({ hex: props.color } as ColorResult) : undefined);
                setShowPicker(false);
                setShowModal(false);
              }}
            />
          </View>
        </View>
      )}
    </View>
  );
};
