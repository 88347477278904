import React, { useContext, useEffect, useRef, useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { useHistory, useLocation } from 'react-router';
import styled, { ThemeContext } from 'styled-components/native';
import {
  Task,
  useProjectQuery,
  CalendarEvent,
  useDeleteCalendarEventMutation,
  ProjectCalendarEventsDocument,
  useUpdateCalendarEventMutation,
} from '../../../../../../graphql/api/API';
import { LoginUserContext } from '../../../../../../modules/auth/LoginUserContext';
import Button from '../../../../../presentational/atoms/button';
import EditableText from '../../../../../presentational/atoms/editable-text';
import Typography, { TypographyType } from '../../../../../presentational/atoms/typography';
import Modal from '../../../../../presentational/molecules/modal';
import { IStyleTheme, IThemePart } from '../../../../../theme';
import { useHotkeys } from 'react-hotkeys-hook';
import MoveCalendarEventModal from '../../../organisms/move-calendar-event-modal';
import UrlUtil from '../../../../../../util/UrlUtil';
import DeleteIcon from '../../../../../presentational/molecules/image-icon/delete';
import CloseIcon from '../../../../../presentational/molecules/image-icon/close';
import MenuIcon from '../../../../../presentational/molecules/image-icon/menu';
import FileMoveIcon from '../../../../../presentational/molecules/image-icon/file-move';
import HorizontalMenu from '../../horizontal-menu';

const HeaderMenu = styled.View`
  display: flex;
  flex-direction: row;
  padding: 10px 15px;
  justify-content: space-between;
  align-items: center;
  border-bottom-width: 1px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
`;

const TitleArea = styled.View`
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  align-items: flex-start;
`;

const Menu = styled.View`
  position: absolute;
  top: 40px;
  right: 0;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
  border-width: 1px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 160px;
`;

const CommonArea = styled.View`
  display: flex;
  flex-direction: column;
  padding: 5px 15px;
  align-items: flex-start;
`;

interface IDeleteCalendarEventModalProps {
  calendarEvent: CalendarEvent;
  showModal: boolean;
  onPressYes: () => Promise<void>;
  onCloseModal: () => void;
}
const DeleteCalendarEventConfirmModal = (props: IDeleteCalendarEventModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  return (
    <Modal
      title={'このカレンダー予定の作業記録を削除しますか？'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <View style={{ flexDirection: 'column' }}>
        <View style={{ marginTop: 10 }}>
          <Typography variant={TypographyType.Normal} style={{ textAlign: 'center' }}>
            {props.calendarEvent.eventName}
          </Typography>
        </View>
        <View style={{ marginTop: 10 }}>
          <Typography
            variant={TypographyType.Description}
            style={{ textAlign: 'center', color: themeContext.colors.error }}>
            {`この操作はやり直しが出来ません`}
          </Typography>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
          <Button
            text={'キャンセル'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.primary }}
            disableValidate={true}
            onPress={() => {
              props.onCloseModal();
            }}
          />
          <Button
            text={'削除する'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              borderColor: themeContext.colors.error,
              borderRadius: 3,
              borderWidth: 1,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.error }}
            onPress={async () => {
              await props.onPressYes();
            }}
          />
        </View>
      </View>
    </Modal>
  );
};

interface ICalendarEventDetailCommonProps {
  organizationId: string;
  calendarEvent: CalendarEvent;
  children: React.ReactNode;
}

const CalendarEventDetailCommon = (props: ICalendarEventDetailCommonProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const history = useHistory();
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const { pathname } = useLocation();

  const fetchProjectResult = useProjectQuery({
    variables: {
      id: props.calendarEvent.projectId,
    },
  });

  const [deleteTask] = useDeleteCalendarEventMutation({
    variables: {
      id: props.calendarEvent.id,
      input: {
        versionNo: props.calendarEvent.versionNo,
      },
    },
    update: (cache, result) => {
      cache.evict({
        id: cache.identify(props.calendarEvent),
      });
    },
    refetchQueries: [
      {
        query: ProjectCalendarEventsDocument,
        variables: {
          projectId: props.calendarEvent.projectId,
        },
      },
    ],
  });
  const [updateCalendarEvent] = useUpdateCalendarEventMutation();

  const [showModal, setShowModal] = useState(false);
  const [showMoveModal, setShowMoveModal] = useState(false);
  const menuRef = useRef();
  const [openMenu, setOpenMenu] = useState(false);
  useHotkeys('Esc', () => setOpenMenu(false));
  const clickDocument = (e: any) => {
    if ((menuRef?.current as any)?.contains(e.target)) {
      (menuRef?.current as any)?.click();
      return;
    }
    setOpenMenu(false);
  };
  useEffect(() => {
    window.addEventListener('click', clickDocument);
    return () => {
      window.removeEventListener('click', clickDocument);
    };
  }, [clickDocument]);

  if (fetchProjectResult.loading || !fetchProjectResult.data?.project) {
    return <></>;
  }

  const isCurrentProject =
    pathname.indexOf(
      `/app/${props.organizationId}/${
        fetchProjectResult.data.project.team.id
      }/projects/${fetchProjectResult.data.project.id!}/`
    ) !== -1;

  return (
    <View>
      <View
        style={
          {
            position: 'sticky',
            top: 0,
            zIndex: 5,
            backgroundColor: themeContext.colors.baseColor,
          } as any
        }>
        <HeaderMenu style={{ zIndex: 3 }}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Typography variant={TypographyType.Normal}>カレンダーの予定</Typography>
          </View>

          <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            {!isCurrentProject && (
              <View style={{ marginHorizontal: 10 }}>
                <TouchableOpacity
                  onPress={() => {
                    history.push(
                      `/app/${props.organizationId}/${
                        fetchProjectResult!.data!.project!.team.id
                      }/projects/${fetchProjectResult!.data!.project!
                        .id!}/calendar-events/calendar-event/${props.calendarEvent.id}/detail/`
                    );
                  }}>
                  <Typography
                    variant={TypographyType.Description}
                    style={{ color: themeContext.colors.link }}>
                    プロジェクトへ移動
                  </Typography>
                </TouchableOpacity>
              </View>
            )}
            <MenuIcon
              size={28}
              containerStyle={{ marginLeft: 5 }}
              onPress={() => setOpenMenu(true)}
            />
            <CloseIcon
              size={18}
              containerStyle={{ marginLeft: 5 }}
              onPress={() => {
                history.push(UrlUtil.createCalendarEventDetailCloseUrl());
              }}
            />
          </View>
          {openMenu && (
            <Menu ref={menuRef as any}>
              <View style={{ paddingHorizontal: 5, marginVertical: 5 }}>
                <FileMoveIcon
                  size={20}
                  containerStyle={{ marginRight: 5 }}
                  onPress={() => {
                    setOpenMenu(false);
                    setShowMoveModal(true);
                  }}>
                  <Typography variant={TypographyType.SubTitle}>移動する</Typography>
                </FileMoveIcon>
              </View>
              <View style={{ paddingHorizontal: 5, marginVertical: 5 }}>
                <DeleteIcon
                  size={23}
                  containerStyle={{ marginRight: 5 }}
                  onPress={() => {
                    setOpenMenu(false);
                    setShowModal(true);
                  }}>
                  <Typography variant={TypographyType.SubTitle}>削除する</Typography>
                </DeleteIcon>
              </View>
            </Menu>
          )}
          <DeleteCalendarEventConfirmModal
            calendarEvent={props.calendarEvent}
            showModal={showModal}
            onPressYes={async () => {
              setShowModal(false);
              // 先にURLを書き換えて、タスク詳細画面を閉じた後に、削除リクエストをする。
              // 順序を逆にすると、削除後にタスク情報を取得しようとして、サーバーサイドでEntityAlreadyDeletedException発生してしまうので注意すること。
              history.push(UrlUtil.createCalendarEventDetailCloseUrl());
              await deleteTask();
            }}
            onCloseModal={() => setShowModal(false)}
          />
          <MoveCalendarEventModal
            calendarEvent={props.calendarEvent}
            showModal={showMoveModal}
            onCloseModal={() => setShowMoveModal(false)}
          />
        </HeaderMenu>

        <TitleArea>
          <EditableText
            style={{ width: '100%' }}
            ellipsis={false}
            value={props.calendarEvent.eventName}
            validate={{
              required: {
                value: true,
                message: '予定名を入力してください',
              },
              maxLength: {
                value: 200,
                message: '200文字以内で入力してください',
              },
            }}
            onChange={(value) => {
              updateCalendarEvent({
                variables: {
                  id: props.calendarEvent.id!,
                  input: {
                    eventName: (value as string) || props.calendarEvent.eventName,
                    startDateTime: props.calendarEvent.startDateTime,
                    endDateTime: props.calendarEvent.endDateTime,
                    versionNo: props.calendarEvent.versionNo,
                  },
                },
              });
            }}
            textStyle={{ fontSize: 18 }}
          />
        </TitleArea>
      </View>
      <CommonArea>
        <HorizontalMenu
          style={{ marginLeft: 10 }}
          menus={[
            {
              title: '基本情報',
              path:
                UrlUtil.getCurrentPathWithoutSearch()
                  .replace(/\/calendar-event\/.*/, ``)
                  .replace(/\/+$/, ``) + `/calendar-event/${props.calendarEvent.id!}/detail/`,
            },
            {
              title: '作業履歴',
              path:
                UrlUtil.getCurrentPathWithoutSearch()
                  .replace(/\/calendar-event\/.*/, ``)
                  .replace(/\/+$/, ``) +
                `/calendar-event/${props.calendarEvent.id!}/working-history/`,
            },
            {
              title: '作業予定',
              path:
                UrlUtil.getCurrentPathWithoutSearch()
                  .replace(/\/calendar-event\/.*/, ``)
                  .replace(/\/+$/, ``) +
                `/calendar-event/${props.calendarEvent.id!}/working-shedule/`,
            },
          ]}
        />
      </CommonArea>
      {props.children}
    </View>
  );
};

export default CalendarEventDetailCommon;
