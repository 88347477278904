import React, { useContext } from 'react';
import { Text, StyleProp, ViewStyle, TextStyle } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../theme';
import List, { ListItemData } from '../list';
import Typography, { TypographyType } from '../typography';

const Container = styled.View`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0 0.7rem;
  background-color: ${(props: IStyleTheme) => props.theme.colors.subHeader};
  font-size: 1.5rem;
  height: 2rem;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.4s;
`;

const renderGroupHeader = <DATA extends {}>(
  title: string,
  headerStyle?: StyleProp<ViewStyle>,
  headerTextStyle?: StyleProp<TextStyle>
) => {
  return (
    <Container style={headerStyle as any}>
      <Typography
        variant={TypographyType.Normal}
        style={[{ color: '#FFFFFF', fontWeight: '400' }, headerTextStyle] as any}>
        {title}
      </Typography>
    </Container>
  );
};

interface Props<DATA extends {}> {
  items: ListItemData<DATA>[];
  getKey?: (item: ListItemData<DATA>, index?: number) => string;
  renderItem: (item: ListItemData<DATA>, dragging: boolean) => React.ReactElement<any, any> | null;
  showGroupHeader?: boolean;
  useStickyHeader?: boolean;
  onPress?: (item: ListItemData<DATA>) => void;
  style?: StyleProp<ViewStyle>;
  headerText?: string;
  headerStyle?: StyleProp<ViewStyle>;
  headerTextStyle?: StyleProp<TextStyle>;
  headerComponent?: React.ReactElement<any, any>;
  subHeader?: React.ReactElement<any, any>;
  afterElement?: React.ReactElement<any, any>;
  filter?: (item: ListItemData<DATA>) => boolean;
  draggable?: boolean;
  onDrop?: (
    startIndex: number,
    endIndex: number,
    startColumnIndex: number,
    endColumnIndex: number,
    item: ListItemData<DATA>,
    sortedItems: ListItemData<DATA>[]
  ) => void;
}

const StandardList = <DATA extends {}>(props: Props<DATA>) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  return (
    <List
      style={props.style}
      items={props.items}
      renderItem={props.renderItem}
      headerText={props.headerText}
      headerStyle={props.headerStyle}
      headerTextStyle={props.headerTextStyle}
      headerComponent={props.headerComponent}
      subHeader={props.subHeader}
      afterElement={props.afterElement}
      getKey={props.getKey}
      showGroupHeader={props.showGroupHeader}
      useStickyHeader={props.useStickyHeader}
      onPress={props.onPress}
      filter={props.filter}
      draggable={props.draggable}
      onDrop={props.onDrop}
      renderGroupHeader={renderGroupHeader}
    />
  );
};

export default StandardList;
