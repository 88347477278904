import React, { useContext, useEffect, useState } from 'react';
import { TextStyle, View } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../../theme';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import DateUtil from '../../../../../util/DateUtil';
import TimeUtil from '../../../../../util/TimeUtil';
import Avatar from '../../../../presentational/atoms/avatar';
import {
  CalendarWorkingHistoryDocument,
  LatestWorkingHistoryDocument,
  Member,
  Organization,
  Plan,
  Priority,
  ProjectWorkingTimeSecDocument,
  Task,
  TaskDocument,
  TaskWorkingHistorySummaryGroupByAssineeDocument,
  WorkingHistoryForSummaryDocument,
  useCancelFavoriteTaskMutation,
  useCompleteTaskMutation,
  useFavoriteTaskMutation,
  useIncompleteTaskMutation,
  useStartTaskMutation,
  useStopTaskMutation,
} from '../../../../../graphql/api/API';
import TaskUtil from '../../../../../util/TaskUtil';
import useInterval from 'use-interval';
import when from '../../../../../lang-extention/When';
import ColorUtil from '../../../../../util/ColorUtil';
import { TaskProgressBar } from '../task-progress-bar';
import useDimensions from 'use-dimensions';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import PlayIcon from '../../../../presentational/molecules/image-icon/play';
import StopIcon from '../../../../presentational/molecules/image-icon/stop';
import CheckIcon from '../../../../presentational/molecules/image-icon/check';
import StarIcon from '../../../../presentational/molecules/image-icon/star';
import ErrorMessageModal from '../error-message-modal';

interface IContainerProps extends IStyleTheme {
  isComplete: boolean;
  isTracking: boolean;
}

const Container = styled.View<IContainerProps>`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 6px;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.4s;
  z-index: 100;
  border-width: 1px;
  border-color: ${(props: IContainerProps) => props.theme.colors.separator};
  background-color: ${(props: IContainerProps) =>
    props.isTracking
      ? '#FFFFF0'
      : props.isComplete
      ? ColorUtil.lignten(props.theme.colors.separator, 3)
      : props.theme.colors.baseColor};
`;

interface IMainProps extends IStyleTheme {
  showClientName: boolean;
}

const Main = styled.View<IMainProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  padding-bottom: 3px;
  overflow: hidden;
  min-height: ${(props: IMainProps) => (props.showClientName ? '45px;' : undefined)};
`;

interface ITaskWorkingTimeProps {
  task: Task;
  style?: TextStyle;
}

export const TaskWorkingTime = (props: ITaskWorkingTimeProps) => {
  const [workingTimeSec, setWorkingTimeSec] = useState(0);
  useEffect(() => {
    setWorkingTimeSec(TaskUtil.prepareTotalWorkingTimeSec(props.task));
  }, [props.task]);

  useInterval(() => {
    if (props.task.workingMembers.length > 0) {
      setWorkingTimeSec(TaskUtil.prepareTotalWorkingTimeSec(props.task));
    }
  }, 1000);

  return (
    <Typography variant={TypographyType.Normal} style={props.style}>
      {TimeUtil.formatForTask(workingTimeSec)}
    </Typography>
  );
};

export const TaskRemainingWorkingTime = (props: ITaskWorkingTimeProps) => {
  const [remainingWorkingTimeSec, setRemainingWorkingTimeSec] = useState<number | null>(0);
  useEffect(() => {
    setRemainingWorkingTimeSec(TaskUtil.prepareRemainingWorkingTimeSec(props.task));
  }, [props.task]);

  useInterval(() => {
    if (props.task.workingMembers.length > 0) {
      setRemainingWorkingTimeSec(TaskUtil.prepareRemainingWorkingTimeSec(props.task));
    }
  }, 1000);

  return (
    <Typography variant={TypographyType.Normal} style={props.style}>
      {remainingWorkingTimeSec ? TimeUtil.formatForTask(remainingWorkingTimeSec) : '-'}
    </Typography>
  );
};

export const TaskEstimatedRemainingWorkingTime = (props: ITaskWorkingTimeProps) => {
  const [estimatedRemainingWorkingTimeSec, setEstimatedRemainingWorkingTimeSec] = useState<
    number | null
  >(0);
  useEffect(() => {
    setEstimatedRemainingWorkingTimeSec(
      TaskUtil.prepareEstimatedRemainingWorkingTimeSec(props.task)
    );
  }, [props.task]);

  useInterval(() => {
    if (props.task.workingMembers.length > 0) {
      setEstimatedRemainingWorkingTimeSec(
        TaskUtil.prepareEstimatedRemainingWorkingTimeSec(props.task)
      );
    }
  }, 1000);

  return (
    <Typography variant={TypographyType.Normal} style={props.style}>
      {estimatedRemainingWorkingTimeSec
        ? TimeUtil.formatForTask(estimatedRemainingWorkingTimeSec)
        : '-'}
    </Typography>
  );
};

export const TaskPredictTotalWorkingTime = (props: ITaskWorkingTimeProps) => {
  const [predictWorkingTimeSec, setPredictRemainingWorkingTimeSec] = useState<number | null>(0);
  useEffect(() => {
    setPredictRemainingWorkingTimeSec(TaskUtil.preparePredictWorkingTimeSec(props.task));
  }, [props.task]);

  useInterval(() => {
    if (props.task.workingMembers.length > 0) {
      setPredictRemainingWorkingTimeSec(TaskUtil.preparePredictWorkingTimeSec(props.task));
    }
  }, 1000);

  return (
    <Typography variant={TypographyType.Normal} style={props.style}>
      {predictWorkingTimeSec ? TimeUtil.formatForTask(predictWorkingTimeSec) : '-'}
    </Typography>
  );
};

interface IProps {
  task: Task;
  organization: Organization;
  me: Member;
  showTeamName: boolean;
  showProjectName: boolean;
  showClientName?: boolean;
  showProgressDeviationRate?: boolean;
  showFavorite?: boolean;
  showAssigner?: boolean;
}

const TaskSummary = (props: IProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const { window } = useDimensions();
  const windowWidth = window.width;
  const [loginUser, _] = useContext(LoginUserContext);
  const [isTracking, setTracking] = useState(TaskUtil.isTracking(props.task, loginUser!));
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showCompleteTaskStartErrorModal, setShowCompleteTaskStartErrorModal] = useState(false);

  useEffect(() => {
    setTracking(TaskUtil.isTracking(props.task, loginUser!));
  }, [props.task.workingMembers]);

  const [startTask, startTaskResult] = useStartTaskMutation({
    update: (cache, result) => {
      // レスポンスの中のネストされたデータは、自動的にはキャッシュ更新されないので、自前でキャッシュ更新している
      cache.writeQuery({
        query: TaskDocument,
        variables: { id: result.data!.startTask!.startTask.id! },
        data: { task: result.data!.startTask!.startTask },
      });
      cache.writeQuery({
        query: LatestWorkingHistoryDocument,
        data: { latestWorkingHistory: result.data!.startTask!.startWorkingHistory },
      });
      if (result.data?.startTask?.stopTask) {
        cache.writeQuery({
          query: TaskDocument,
          variables: { id: result.data!.startTask!.stopTask!.id! },
          data: { task: result.data!.startTask!.stopTask },
        });
      }
      cache.modify({
        fields: {
          workingHistoriesSpecifyTermForCalendar(existing = [], { storeFieldName }) {
            const newWorkingHistories = cache.writeQuery({
              query: CalendarWorkingHistoryDocument,
              data: result.data?.startTask?.stopWorkingHistory,
            });
            const targetMemberData = existing.filter((d: any) => d.memberId === loginUser?.id)[0];
            if((targetMemberData?.length ?? 0) === 0){
              return [...existing, {
                memberId: loginUser?.id,
                workingHistories: newWorkingHistories
              }];
            }
            return existing.map((data: any) => {
              if(data.memberId !== loginUser?.id){
                return data;
              }
              return {
                memberId: data.memberId,
                workingHistories: [...targetMemberData.workingHistories, newWorkingHistories]
              }
            })
          },
        },
      });
    },
    onError: (error) => {
      setTracking(TaskUtil.isTracking(props.task, loginUser!));
    },
    // refetchQueries: [
    //   {
    //     query: TaskWorkingHistorySummaryGroupByAssineeDocument,
    //     variables: {
    //       teamId: props.task.project.team.id,
    //       taskId: props.task.id!,
    //     },
    //   },
    //   {
    //     query: TaskWorkingHistoriesDocument,
    //     variables: {
    //       taskId: props.task.id!,
    //     },
    //   },
    // ],
  });
  const [stopTask, stopTaskResult] = useStopTaskMutation({
    update: (cache, result) => {
      // レスポンスの中のネストされたデータは、自動的にはキャッシュ更新されないので、自前でキャッシュ更新している
      cache.writeQuery({
        query: TaskDocument,
        variables: { id: result.data!.stopTask!.stopTask.id! },
        data: { task: result.data!.stopTask!.stopTask },
      });        
    },
    onError: (error) => {
      setTracking(TaskUtil.isTracking(props.task, loginUser!));
    },
    refetchQueries: [
      {
        query: TaskWorkingHistorySummaryGroupByAssineeDocument,
        variables: {
          teamId: props.task.project.team.id!,
          taskId: props.task.id!,
        },
      },
      {
        query: LatestWorkingHistoryDocument,
      },
      {
        query: ProjectWorkingTimeSecDocument,
        variables: {
          id: props.task.project.id,
        },
      },
    ],
  });
  const [completeTask, completeTaskResult] = useCompleteTaskMutation({
    onError: (error) => {
      setTracking(TaskUtil.isTracking(props.task, loginUser!));
    },
    refetchQueries: [
      {
        query: TaskWorkingHistorySummaryGroupByAssineeDocument,
        variables: {
          teamId: props.task.project.team.id!,
          taskId: props.task.id!,
        },
      },
      {
        query: LatestWorkingHistoryDocument,
      },
      {
        query: ProjectWorkingTimeSecDocument,
        variables: {
          id: props.task.project.id,
        },
      },
    ],
  });
  const [incompleteTask, incompleteTaskResult] = useIncompleteTaskMutation();
  const [favoriteTask] = useFavoriteTaskMutation({
    variables: {
      id: props.task.id!,
      input: {
        versionNo: props.task.versionNo,
      },
    },
  });
  const [cancelFavoriteTask] = useCancelFavoriteTaskMutation({
    variables: {
      id: props.task.id!,
      input: {
        versionNo: props.task.versionNo,
      },
    },
  });
  return (
    <Container isComplete={props.task.completeDateTime !== null} isTracking={isTracking}>
      <Main showClientName={props.showClientName}>
        <View style={{ flexDirection: 'row', flex: 1, minWidth: 67, alignItems: 'center' }}>
          {isTracking ? (
            <StopIcon
              size={23}
              containerStyle={
                {
                  marginTop: 3,
                  marginRight: 10,
                } as any
              }
              disabled={startTaskResult.loading || stopTaskResult.loading}
              onPress={() => {
                if (isTracking !== TaskUtil.isTracking(props.task, loginUser!)) {
                  return;
                }
                setTracking(false);
                stopTask({
                  variables: {
                    id: props.task.id!,
                    input: {
                      versionNo: props.task.versionNo,
                    },
                  },
                });
              }}
            />
          ) : (
            <PlayIcon
              size={23}
              containerStyle={
                {
                  marginTop: 3,
                  marginRight: 10,
                } as any
              }
              disabled={startTaskResult.loading || stopTaskResult.loading}
              onPress={() => {
                if (isTracking !== TaskUtil.isTracking(props.task, loginUser!)) {
                  return;
                }
                if (!!props.task.completeDateTime || props.task.project.complete) {
                  setShowCompleteTaskStartErrorModal(true);
                  return;
                }
                setTracking(true);
                startTask({
                  variables: {
                    id: props.task.id!,
                    input: {
                      versionNo: props.task.versionNo,
                    },
                  },
                });
              }}
            />
          )}
          {props.showTeamName && windowWidth >= 1550 && (
            <View
              style={{
                maxWidth: 140,
                minWidth: 140,
                marginRight: 10,
                justifyContent: 'flex-start',
              }}>
              <Typography
                variant={TypographyType.Normal}
                ellipsis={true}
                style={{
                  fontSize: 12,
                  paddingHorizontal: 5,
                  textAlign: 'left',
                }}>
                {props.task.project.team.name}
              </Typography>
            </View>
          )}
          {props.showProjectName && (
            <View
              style={{
                justifyContent: 'flex-start',
              }}>
              {props.showClientName && props.task.project.client && (
                <View>
                  <Typography
                    variant={TypographyType.Normal}
                    ellipsis={true}
                    style={{
                      paddingTop: 5,
                      fontSize: 10,
                      lineHeight: 11,
                      width: 200,
                      paddingHorizontal: 5,
                      color: themeContext.colors.description,
                      textAlign: 'left',
                    }}>
                    {props.task.project.client?.name ?? '-'}
                  </Typography>
                </View>
              )}
              <View>
                <Typography
                  variant={TypographyType.Normal}
                  ellipsis={true}
                  style={{
                    fontSize: 12,
                    width: 200,
                    paddingHorizontal: 5,
                    textAlign: 'left',
                  }}>
                  {props.task.project.name}
                </Typography>
              </View>
            </View>
          )}
          <Typography
            variant={TypographyType.Normal}
            ellipsis={true}
            tooltipWhenEllipsis={true}
            tooltipId={`task-${props.task.id}`}
            style={
              [
                {
                  fontSize: 14,
                  minWidth: 30,
                  textAlign: 'left',
                },
                props.task.completeDateTime !== null
                  ? {
                      color: themeContext.colors.description,
                      textDecorationLine: 'line-through',
                      textDecorationColor: themeContext.colors.textColor,
                    }
                  : {},
              ] as any
            }>
            {props.task.completeDateTime !== null
              ? `\u00A0${props.task.title}\u00A0`
              : props.task.title}
          </Typography>
        </View>
        <View style={{ flexDirection: 'row' }}>
          {props.showFavorite !== false && (
            <View
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: 2,
                paddingHorizontal: 5,
                width: 25,
                marginLeft: 15,
              }}>
              {props.task.favoriteMembers.filter((member) => member!.id === loginUser!.id).length >
              0 ? (
                <StarIcon
                  size={16}
                  on={true}
                  containerStyle={{ marginLeft: 3 }}
                  onPress={() => {
                    cancelFavoriteTask();
                  }}
                />
              ) : (
                <StarIcon
                  size={16}
                  on={false}
                  containerStyle={{ marginLeft: 3 }}
                  onPress={() => {
                    favoriteTask();
                  }}
                />
              )}
            </View>
          )}
          {(props.task.project.team.organization.plan.code === Plan.Business ||
            props.task.project.team.organization.plan.code === Plan.Enterprise) &&
            props.showAssigner !== false && (
              <View
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingTop: 2,
                  paddingHorizontal: 5,
                  width: 60,
                }}>
                {props.task.assignees.length >= 2 ? (
                  <View
                    style={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginLeft: 5,
                      maxWidth: 15,
                    }}>
                    <Avatar
                      size={24}
                      textStyle={{ fontSize: 10 }}
                      name={`${props.task.assignees.length}名`}
                    />
                  </View>
                ) : (
                  props.task.assignees.map((info, i) => {
                    return (
                      <View
                        style={{
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginLeft: 5,
                          maxWidth: 15,
                        }}
                        key={i}>
                        <Avatar
                          size={24}
                          name={info.member.name!}
                          imageUrl={info.member.profileImageUrl}
                        />
                      </View>
                    );
                  })
                )}
              </View>
            )}
          <Typography
            variant={TypographyType.Normal}
            style={{ fontSize: 12, paddingHorizontal: 5, width: 70, textAlign: 'center' }}>
            {when(props.task.priority)
              .on(
                (v) => v === Priority.High,
                () => '高'
              )
              .on(
                (v) => v === Priority.Normal,
                () => '中'
              )
              .on(
                (v) => v === Priority.Low,
                () => '低'
              )
              .otherwise(() => '-')}
          </Typography>
          {windowWidth > 1500 && (
            <Typography
              variant={TypographyType.Normal}
              style={{ fontSize: 12, paddingHorizontal: 5, width: 90, textAlign: 'center' }}>
              {DateUtil.formatDate(props.task.scheduledStartDateTime)}
            </Typography>
          )}
          {windowWidth > 1400 && (
            <Typography
              variant={TypographyType.Normal}
              style={{ fontSize: 12, paddingHorizontal: 5, width: 90, textAlign: 'center' }}>
              {DateUtil.formatDate(props.task.scheduledEndDateTime)}
            </Typography>
          )}
          <Typography
            variant={TypographyType.Normal}
            style={{ fontSize: 12, paddingHorizontal: 5, width: 60, textAlign: 'center' }}>
            {`${props.task.progressRate}%`}
          </Typography>
          <View
            style={{
              flexDirection: 'row',
              paddingHorizontal: 5,
              width: 100,
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <TaskWorkingTime
              task={props.task}
              style={{ fontSize: 12, minWidth: 50, marginRight: 5, textAlign: 'center' }}
            />
            {(props.organization.plan.code === Plan.Business ||
              props.organization.plan.code === Plan.Enterprise) && (
              <>
                {props.task.workingMembers.length >= 2 ? (
                  <View
                    style={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginLeft: 5,
                      maxWidth: 15,
                    }}>
                    <Avatar
                      size={24}
                      textStyle={{ fontSize: 10 }}
                      name={`${props.task.workingMembers.length}名`}
                    />
                  </View>
                ) : (
                  props.task.workingMembers.map((workingMemberInfo, i) => {
                    return (
                      <View
                        style={{
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginLeft: 5,
                          maxWidth: 15,
                        }}
                        key={i}>
                        <Avatar
                          size={24}
                          name={workingMemberInfo!.member.name!}
                          imageUrl={workingMemberInfo!.member.profileImageUrl}
                        />
                      </View>
                    );
                  })
                )}
              </>
            )}
          </View>
          <Typography
            variant={TypographyType.Normal}
            style={{ fontSize: 12, paddingHorizontal: 5, width: 80, textAlign: 'center' }}>
            {TimeUtil.formatForTask(props.task.estimateTimeSec)}
          </Typography>
          {windowWidth > 1600 && (
            <Typography
              variant={TypographyType.Normal}
              style={{ fontSize: 12, paddingHorizontal: 5, width: 100, textAlign: 'center' }}
              ellipsis={true}>
              {props.task.progressDeviationRate
                ? TimeUtil.formatForTask(TaskUtil.preparePredictWorkingTimeSec(props.task))
                : '-'}
            </Typography>
          )}
          {windowWidth > 1600 && props.showProgressDeviationRate === true && (
            <Typography
              variant={TypographyType.Normal}
              style={{ fontSize: 12, paddingHorizontal: 5, width: 100, textAlign: 'center' }}>
              {props.task.progressDeviationRate ? `${props.task.progressDeviationRate}%` : '-'}
            </Typography>
          )}

          <View style={{ width: 40, justifyContent: 'center', alignItems: 'center' }}>
            {props.task.completeDateTime ? (
              <CheckIcon
                size={16}
                on={true}
                onPress={() => {
                  if (!props.me.taskCreatePermissionFlg) {
                    setShowErrorModal(true);
                    return;
                  }
                  incompleteTask({
                    variables: {
                      id: props.task.id!,
                      input: {
                        versionNo: props.task.versionNo,
                      },
                    },
                  });
                }}
              />
            ) : (
              <CheckIcon
                size={16}
                on={false}
                onPress={() => {
                  if (!props.me.taskCreatePermissionFlg) {
                    setShowErrorModal(true);
                    return;
                  }
                  completeTask({
                    variables: {
                      id: props.task.id!,
                      input: {
                        versionNo: props.task.versionNo,
                      },
                    },
                    // refetchQueries: [
                    //   {
                    //     query: TaskWorkingHistorySummaryGroupByAssineeDocument,
                    //     variables: {
                    //       teamId: props.task.project.team.id,
                    //       taskId: props.task.id!,
                    //     },
                    //   },
                    //   {
                    //     query: LatestWorkingHistoryDocument,
                    //   },
                    //   {
                    //     query: TaskWorkingHistoriesDocument,
                    //     variables: {
                    //       taskId: props.task.id!,
                    //     },
                    //   },
                    //   {
                    //     query: ProjectWorkingTimeSecDocument,
                    //     variables: {
                    //       id: props.task.project.id,
                    //     },
                    //   },
                    // ],
                  });
                }}
              />
            )}
          </View>
        </View>
      </Main>
      <TaskProgressBar task={props.task} />
      <ErrorMessageModal
        showModal={showErrorModal}
        title={'タスクを更新できません'}
        message={`タスクを更新する権限がありません${'\n'}権限が必要な場合、管理権限を持っているメンバーに問い合わせてください`}
        onCloseModal={() => setShowErrorModal(false)}
      />
      <ErrorMessageModal
        showModal={showCompleteTaskStartErrorModal}
        title={'作業開始することができません'}
        message={`完了しているタスク・完了しているプロジェクトは${'\n'}作業開始することが出来ません。`}
        onCloseModal={() => setShowCompleteTaskStartErrorModal(false)}
      />
    </Container>
  );
};

export default React.memo(TaskSummary);
