import React, { createContext, useContext, useState } from 'react';
import { Text, View } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../../../theme';
import HorizontalMenu from '../../../organisms/horizontal-menu';
import SelectButton from '../../../../../presentational/molecules/select-button';
import FavoriteTaskList from '../../../organisms/favorite-task-list';
import AssignedTaskList from '../../../organisms/assigned-task-list';
import WorkedTaskList from '../../../organisms/worked-task-list';
import FavoriteProject from '../../../organisms/favorite-project';
import AssignedProject from '../../../organisms/assigned-project';
import { Route, Switch, useLocation } from 'react-router';
import { LoginUserContext } from '../../../../../../modules/auth/LoginUserContext';
import { Organization, Plan, Task, useOrganizationQuery } from '../../../../../../graphql/api/API';
import moment from 'moment-timezone';
import Typography, { TypographyType } from '../../../../../presentational/atoms/typography';
import Spinner from '../../../../../presentational/atoms/spinner';
import useDimensions from 'use-dimensions';

const Container = styled.View`
  height: calc(100vh - 57px);
  display: flex;
  flex-direction: row;
`;

const MainAreaContainer = styled.View`
  flex: 1;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.4s;
  marginright: 50px;
  z-index: 2;
`;
// 本当はoverflow-xはvisibleにしたいのだが、CSSの仕様で、overflow-yを指定した場合には、xの挙動もそちらで上書きされるため実現できない

const MainAreaHeader = styled.View`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
  padding: 0.2rem 1rem;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.4s;
  z-index: 2;
`;

const MainAreaHeaderTop = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0;
`;

const MainAreaHeaderBottom = styled.View`
  display: flex;
  width: 100%;
  height: 23px;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0.2rem 0;
`;

const FilterArea = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
`;

const FilterElementsContainer = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.2rem 0;
  margin: 0 0.2rem;
`;

const FilterElements = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export type TaskFilterExpireDate = 'today' | '3days' | 'week';

export interface TaskFilter {
  expire: TaskFilterExpireDate | null;
  taskStatusIds: string[];
  assignerIds: string[];
  showCompleteTask: string[];
}

export const TaskFilterContext = createContext([
  { expire: null, taskStatusIds: [], assignerIds: [], showCompleteTask: [] } as TaskFilter,
  (value: TaskFilter) => {},
]);

const TaskFilterContextProvider = (props: any) => {
  const [filter, setFilter] = useState<TaskFilter>({
    expire: null,
    taskStatusIds: [],
    assignerIds: [],
    showCompleteTask: ['incomplete'],
  });
  return (
    <TaskFilterContext.Provider value={[filter, setFilter]}>
      {props.children}
    </TaskFilterContext.Provider>
  );
};

export function matchExpireFilter(task: Task, taskFilter: TaskFilter): boolean {
  if (taskFilter.expire !== null) {
    if (task!.scheduledEndDateTime !== null) {
      const endDateTime = moment(task!.scheduledEndDateTime);
      if (
        taskFilter.expire === 'today' &&
        endDateTime.format('YYYYMMDD') === moment().format('YYYYMMDD')
      ) {
        return true;
      }
      if (taskFilter.expire === '3days' && endDateTime.diff(moment(), 'days') <= 3) {
        return true;
      }
      if (taskFilter.expire === 'week' && endDateTime.diff(moment(), 'days') <= 7) {
        return true;
      }
    }
    return false;
  }
  return true;
}

export function matchTaskStatus(task: Task, taskFilter: TaskFilter): boolean {
  if (taskFilter.taskStatusIds.length === 0) {
    return true;
  }
  return taskFilter.taskStatusIds.indexOf(task!.taskStatus!.id!) !== -1;
}

export function matchTaskAssigner(task: Task, taskFilter: TaskFilter): boolean {
  if (taskFilter.assignerIds.length === 0) {
    return true;
  }
  if (task.assignees.length === 0) {
    // タスクの担当者がいなければそのタスクは非表示
    return taskFilter.assignerIds.includes('empty');
  }

  // 絞り込みがある場合には、その絞り込みに合致するタスクを表示
  return (
    task.assignees.filter((info) => taskFilter.assignerIds.indexOf(info.member!.id!) !== -1)
      .length > 0
  );
}

export function matchTaskComplete(task: Task, taskFilter: TaskFilter): boolean {
  if (taskFilter.showCompleteTask.length === 0) {
    return true;
  }
  if (
    taskFilter.showCompleteTask.filter((elem) => elem === 'complete').length > 0 &&
    task.completeDateTime != null
  ) {
    return true;
  }
  if (
    taskFilter.showCompleteTask.filter((elem) => elem === 'incomplete').length > 0 &&
    task.completeDateTime == null
  ) {
    return true;
  }
  return false;
}

interface IFilterProps {}

const Filter = (props: IFilterProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, _] = useContext(LoginUserContext);
  const [taskFilter, setTaskFilter] = useContext(TaskFilterContext);
  const { window: win } = useDimensions();
  const windowWidth = win.width;
  const [selectedKey, setSelectedKey] = useState<string[]>(['incomplete']);
  return (
    <FilterArea>
      <FilterElementsContainer>
        <Text
          style={{
            fontSize: 7,
            color: themeContext.colors.description,
            textAlign: 'center',
            marginBottom: 2,
          }}>
          ステータス
        </Text>
        <FilterElements>
          <SelectButton
            textStyle={{ maxWidth: 70 }}
            contents={[
              {
                content: '未完了',
                key: 'incomplete',
              },
              {
                content: '完了',
                key: 'complete',
              },
            ]}
            values={selectedKey}
            multiSelect={true}
            onChange={(value) => {
              setSelectedKey(value);
              setTaskFilter({
                expire: (taskFilter as TaskFilter).expire,
                taskStatusIds: (taskFilter as TaskFilter).taskStatusIds,
                assignerIds: (taskFilter as TaskFilter).assignerIds,
                showCompleteTask: value,
              });
            }}
          />
        </FilterElements>
      </FilterElementsContainer>
      {windowWidth > 1250 && (
        <FilterElementsContainer>
          <Text
            style={{
              fontSize: 7,
              color: themeContext.colors.description,
              textAlign: 'center',
              marginBottom: 2,
            }}>
            〆切日
          </Text>
          <FilterElements>
            <SelectButton
              contents={[
                {
                  key: 'today',
                  content: '今日',
                },
                {
                  key: '3days',
                  content: '3日以内',
                },
                {
                  key: 'week',
                  content: '1週間以内',
                },
              ]}
              onChange={(value) => {
                setTaskFilter({
                  expire: value[0] || null,
                  taskStatusIds: (taskFilter as TaskFilter).taskStatusIds,
                  assignerIds: (taskFilter as TaskFilter).assignerIds,
                  showCompleteTask: (taskFilter as TaskFilter).showCompleteTask,
                });
              }}
            />
          </FilterElements>
        </FilterElementsContainer>
      )}
    </FilterArea>
  );
};

interface IProjectContainerInnerProps {
  organization: Organization;
}

const ProjectContainerInner = React.memo((props: IProjectContainerInnerProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const location = useLocation();

  return (
    <>
      <TaskFilterContextProvider>
        <MainAreaHeader>
          <MainAreaHeaderTop>
            <Typography
              variant={TypographyType.SubTitle}
              style={{ marginLeft: 15, marginRight: 30 }}>
              マイタスク
            </Typography>
          </MainAreaHeaderTop>
          <MainAreaHeaderBottom>
            <HorizontalMenu
              style={{ marginLeft: 10 }}
              menus={
                props.organization.plan.code === Plan.Basic ||
                props.organization.plan.code === Plan.Professional
                  ? [
                      {
                        title: 'お気に入りプロジェクト',
                        path: `/app/${props.organization.id!}/my/favorite-project/`,
                      },
                      {
                        title: 'お気に入りタスク',
                        path: `/app/${props.organization.id!}/my/favorite-task/list/`,
                      },
                      {
                        title: '最近作業したことのあるタスク',
                        path: `/app/${props.organization.id!}/my/worked-task/list/`,
                      },
                    ]
                  : [
                      {
                        title: 'お気に入りプロジェクト',
                        path: `/app/${props.organization.id!}/my/favorite-project/`,
                      },
                      {
                        title: '自分が担当者のプロジェクト',
                        path: `/app/${props.organization.id!}/my/assigned-project/`,
                      },
                      {
                        title: 'お気に入りタスク',
                        path: `/app/${props.organization.id!}/my/favorite-task/list/`,
                      },
                      {
                        title: '自分が担当者のタスク',
                        path: `/app/${props.organization.id!}/my/assigned-task/list/`,
                      },
                      {
                        title: '最近作業したことのあるタスク',
                        path: `/app/${props.organization.id!}/my/worked-task/list/`,
                      },
                    ]
              }
            />
            {location.pathname.indexOf('/my/favorite-project/') === -1 &&
              location.pathname.indexOf('/my/assigned-project/') === -1 && <Filter />}
          </MainAreaHeaderBottom>
        </MainAreaHeader>
        <Switch>
          <Route path={'/app/:organizationId/my/favorite-project/'} component={FavoriteProject} />
          <Route path={'/app/:organizationId/my/assigned-project/'} component={AssignedProject} />
          <Route
            path={'/app/:organizationId/my/favorite-task/list/'}
            component={FavoriteTaskList}
          />
          <Route
            path={'/app/:organizationId/my/assigned-task/list/'}
            component={AssignedTaskList}
          />
          <Route path={'/app/:organizationId/my/worked-task/list/'} component={WorkedTaskList} />
        </Switch>
      </TaskFilterContextProvider>
    </>
  );
});

const AppMyTasksPage = () => {
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const { data, loading, error } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId,
    },
  });

  return (
    <Container>
      <MainAreaContainer>
        <Spinner loading={loading}>
          <ProjectContainerInner organization={data?.organization as any} />
        </Spinner>
      </MainAreaContainer>
    </Container>
  );
};

export default React.memo(AppMyTasksPage);
