import queryString from 'query-string';
import { CalendarEvent, Project, Task } from '../graphql/api/API';

export default class UrlUtil {
  private constructor() {}

  public static getCurrentPathWithoutSearch(): string {
    return window.location.hash.replace('#', '').replace(/\?.*/, '');
  }

  public static createPathWithCurrentSearch(path: string): string {
    const hasSearch = window.location.hash.indexOf('?') !== -1;
    if (hasSearch) {
      const searchStr = window.location.hash.substring(window.location.hash.indexOf('?') + 1);
      const search = queryString.parse(searchStr);
      return path.replace('#', '').replace(/\?.*/, '') + '?' + queryString.stringify(search);
    } else {
      return path;
    }
  }

  public static moveProjectDetailUrl(project: Project): string {
    return UrlUtil.addSearch(UrlUtil.getCurrentPathWithoutSearch(), {
      projectDetailId: project.id,
      projectDetailMode: 'standard',
    });
  }

  public static moveProjectDetailUrlById(projectId: string): string {
    return UrlUtil.addSearch(UrlUtil.getCurrentPathWithoutSearch(), {
      projectDetailId: projectId,
      projectDetailMode: 'standard',
    });
  }

  public static createTaskDetailUrl(task: Task, additionalPath?: string): string {
    return UrlUtil.createPathWithCurrentSearch(
      UrlUtil.getCurrentPathWithoutSearch()
        .replace(/\/calendar-event\/.*/, ``)
        .replace(/\/task\/.*/, ``)
        .replace(/\/+$/, ``) +
        `/task/${task.id!}/detail/` +
        (additionalPath || '')
    );
  }

  public static createTaskTemplateDetailUrl(task: Task, additionalPath?: string): string {
    return UrlUtil.createPathWithCurrentSearch(
      UrlUtil.getCurrentPathWithoutSearch()
        .replace(/\/task-template\/.*/, ``)
        .replace(/\/+$/, ``) +
        `/task-template/${task.id!}/detail/` +
        (additionalPath || '')
    );
  }

  public static createCalendarEventDetailUrl(
    calendarEvent: CalendarEvent,
    additionalPath?: string
  ): string {
    return UrlUtil.createPathWithCurrentSearch(
      UrlUtil.getCurrentPathWithoutSearch()
        .replace(/\/task\/.*/, ``)
        .replace(/\/calendar-event\/.*/, ``)
        .replace(/\/+$/, ``) +
        `/calendar-event/${calendarEvent.id!}/detail/` +
        (additionalPath || '')
    );
  }

  public static createCalendarEventDetailUrlById(
    calendarEventId: string,
    additionalPath?: string
  ): string {
    return UrlUtil.createPathWithCurrentSearch(
      UrlUtil.getCurrentPathWithoutSearch()
        .replace(/\/task\/.*/, ``)
        .replace(/\/calendar-event\/.*/, ``)
        .replace(/\/+$/, ``) +
        `/calendar-event/${calendarEventId}/detail/` +
        (additionalPath || '')
    );
  }

  public static createTaskDetailUrlById(taskId: string, additionalPath?: string): string {
    return UrlUtil.createPathWithCurrentSearch(
      UrlUtil.getCurrentPathWithoutSearch()
        .replace(/\/task\/.*/, ``)
        .replace(/\/calendar-event\/.*/, ``)
        .replace(/\/+$/, ``) +
        `/task/${taskId}/detail/` +
        (additionalPath || '')
    );
  }

  public static createTaskDetailCloseUrl(): string {
    return UrlUtil.createPathWithCurrentSearch(
      UrlUtil.getCurrentPathWithoutSearch().replace(/\/task\/(.+)\//g, '/')
    );
  }

  public static createTaskTemplateDetailCloseUrl(): string {
    return UrlUtil.createPathWithCurrentSearch(
      UrlUtil.getCurrentPathWithoutSearch().replace(/\/task-template\/(.+)\//g, '/')
    );
  }

  public static createCalendarEventDetailCloseUrl(): string {
    return UrlUtil.createPathWithCurrentSearch(
      UrlUtil.getCurrentPathWithoutSearch().replace(/\/calendar-event\/(.+)\//g, '/')
    );
  }

  public static addSearch(path: string, param: Object): string {
    const hasSearch = window.location.hash.indexOf('?') !== -1;
    if (hasSearch) {
      const searchStr = window.location.hash.substring(window.location.hash.indexOf('?') + 1);
      const search = queryString.parse(searchStr);
      const newSearch = Object.assign(search, param);
      return path.replace('#', '').replace(/\?.*/, '') + '?' + queryString.stringify(newSearch);
    } else {
      return path.replace('#', '').replace(/\?.*/, '') + '?' + queryString.stringify(param);
    }
  }

  public static removeSearch(key: any): string {
    const hasSearch = window.location.hash.indexOf('?') !== -1;
    if (hasSearch) {
      const searchStr = window.location.hash.substring(window.location.hash.indexOf('?') + 1);
      const search = queryString.parse(searchStr);
      delete search[key];
      return (
        UrlUtil.getCurrentPathWithoutSearch().replace(/\?.*/, '') +
        '?' +
        queryString.stringify(search)
      );
    } else {
      return UrlUtil.getCurrentPathWithoutSearch();
    }
  }

  public static removeSearchs(keys: any[]): string {
    const hasSearch = window.location.hash.indexOf('?') !== -1;
    if (hasSearch) {
      const searchStr = window.location.hash.substring(window.location.hash.indexOf('?') + 1);
      const search = queryString.parse(searchStr);
      keys.forEach((key) => {
        delete search[key];
      });
      return (
        UrlUtil.getCurrentPathWithoutSearch().replace(/\?.*/, '') +
        '?' +
        queryString.stringify(search)
      );
    } else {
      return UrlUtil.getCurrentPathWithoutSearch();
    }
  }
}
