import * as SecureStore from 'expo-secure-store';
import * as Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { Platform } from 'react-native';
import { LoginUser } from './LoginUserContext';
import * as Sentry from 'sentry-expo';
import HashIdUtil from '../../util/HashIdUtil';
import moment from 'moment-timezone';
import Constants from 'expo-constants';
export default class LoginUtil {
  private constructor() {}

  public static JWT_TOKEN_KEY = 'act'; //access token
  public static STAY_LOGGED_IN_KEY = 'sli';
  public static JWT_ORGANIZATION_ID = 'orgid';

  public static getTeamIdFromURl(): string | null {
    if (this.isWeb()) {
      const hash = window.location.hash;
      const matches = hash.match(/app\/[^¥/]+\/([^¥/]+)\//);
      if (matches && matches.length > 1) {
        return matches[1];
      }
    }
    return null;
  }

  public static async isLoggedIn(): Promise<Boolean> {
    const token = await this.getAllJwtTokens();
    return token !== null;
  }

  public static async saveToken(token: string, stayLoggedIn?: boolean) {
    const jwtTokenList: any = (await this.getAllJwtTokens()) || {};
    const user = this.getUserFromJwtToken(token);
    jwtTokenList[user!.organizationId] = token;
    if (LoginUtil.isWeb()) {
      const stay =
        stayLoggedIn !== null && stayLoggedIn !== undefined
          ? stayLoggedIn
          : Cookies.get(this.STAY_LOGGED_IN_KEY) === 'true';
      Cookies.set(LoginUtil.STAY_LOGGED_IN_KEY, stay ? 'true' : 'false', {
        secure: true,
        expires: stay ? moment().add(30, 'days').toDate() : undefined,
      });
      Cookies.set(LoginUtil.JWT_TOKEN_KEY, JSON.stringify(jwtTokenList), {
        secure: true,
        expires: stay ? moment().add(30, 'days').toDate() : undefined,
      });
      //TODO 将来的にはWeb版でもCookieに組織IDを保存したい
    } else {
      await SecureStore.setItemAsync(LoginUtil.JWT_TOKEN_KEY, JSON.stringify(jwtTokenList));
      await SecureStore.setItemAsync(LoginUtil.JWT_ORGANIZATION_ID, user!.organizationId);
    }

    if (Sentry.Native) {
      Sentry.Native.setUser({
        id: HashIdUtil.decord(user!.id).toString(),
      });
    } else {
      (Sentry as any).Browser.setUser({
        id: HashIdUtil.decord(user!.id),
      });
    }
  }

  public static async switchOrganization(user: LoginUser): Promise<void> {
    await SecureStore.setItemAsync(LoginUtil.JWT_ORGANIZATION_ID, user!.organizationId);
  }

  public static async getLoginUser(): Promise<LoginUser | null> {
    const jwtToken = await this.getLoginUsersJwtToken();
    return this.getUserFromJwtToken(jwtToken);
  }

  public static async getLoginUsersJwtToken(): Promise<string | null> {
    const jwtTokenList: any = (await this.getAllJwtTokens()) || {};
    if (LoginUtil.isWeb()) {
      const organizationId = this.getOrganizationIdFromUrl();
      if (organizationId) {
        return jwtTokenList[organizationId];
      }

      const firstOrganizationId = Object.keys(jwtTokenList)[0]; //TODO WebだとURLから表示する組織を判断しているが、スマホだと他の手段が必要。ストレージを使うか？
      if (firstOrganizationId) {
        return jwtTokenList[firstOrganizationId];
      }
      return null;
    }

    const organizationId = await SecureStore.getItemAsync(LoginUtil.JWT_ORGANIZATION_ID);
    if (organizationId) {
      return jwtTokenList[organizationId];
    }
    const firstOrganizationId = Object.keys(jwtTokenList)[0];
    if (firstOrganizationId) {
      await SecureStore.setItemAsync(LoginUtil.JWT_ORGANIZATION_ID, firstOrganizationId);
      return jwtTokenList[firstOrganizationId];
    }
    return null;
  }

  public static async getAllUserFromJwtTokens(): Promise<LoginUser[]> {
    const jwtTokenList: any = (await this.getAllJwtTokens()) || {};
    return Object.keys(jwtTokenList)
      .map((key) => jwtTokenList[key])
      .map((jwtToken) => this.getUserFromJwtToken(jwtToken))
      .filter((user) => user !== null) as LoginUser[];
  }

  public static async logout() {
    // const token = await this.getLoginUsersJwtToken();
    // try {
    //   await fetch(Constants.manifest!.extra!.logoutEndPoint, {
    //     method: 'POST',
    //     mode: "cors",
    //     credentials: "include",
    //     headers: {
    //       ':method': 'POST',
    //       ':path': '/logout',
    //       ':schema': 'https',
    //       'Accept': '*/*',
    //       'Authorization': `Bearer ${token}`,
    //       'Cache-Control': 'no-cache',
    //       'Pragma': 'no-cache',
    //       'Origin': `${new URL(window.location.href).origin}`,
    //     }
    //   });
    // } catch(e){
    //   console.log(e)
    //   //NOP
    // }
    await this.deleteJwtToken();
  }

  public static async deleteJwtToken() {
    if (Sentry.Native) {
      Sentry.Native.configureScope((scope) => scope.setUser(null));
    } else {
      (Sentry as any).Browser.configureScope((scope: any) => scope.setUser(null));
    }

    if (LoginUtil.isWeb()) {
      Cookies.remove(LoginUtil.STAY_LOGGED_IN_KEY);
      Cookies.remove(LoginUtil.JWT_TOKEN_KEY);
    } else {
      await SecureStore.deleteItemAsync(LoginUtil.JWT_TOKEN_KEY);
      await SecureStore.deleteItemAsync(LoginUtil.JWT_ORGANIZATION_ID);
    }
  }

  private static getOrganizationIdFromUrl(): string | null {
    if (this.isWeb()) {
      const hash = window.location.hash;
      const matches = hash.match(/app\/([^¥/]+)\//);
      if (matches && matches.length > 1) {
        return matches[1];
      }
    }
    return null;
  }

  public static async getAllJwtTokens(): Promise<Object | null> {
    if (LoginUtil.isWeb()) {
      const result = Cookies.get(LoginUtil.JWT_TOKEN_KEY);
      if (result) {
        return JSON.parse(result);
      }
      return null;
    }
    const result = await SecureStore.getItemAsync(LoginUtil.JWT_TOKEN_KEY);
    if (result) {
      return JSON.parse(result);
    }
    return null;
  }

  public static getUserFromJwtToken(token: string | null | undefined): LoginUser | null {
    if (token === null || token === undefined) {
      return null;
    }
    const decoded = jwtDecode(token);
    return {
      id: (decoded as any).sub,
      mailAddress: (decoded as any).upn,
      name: (decoded as any).name,
      organizationId: (decoded as any).organizationId,
      myTeamId: (decoded as any).myTeamId || null,
      jwtToken: token,
    };
  }

  private static isWeb(): boolean {
    return Platform.OS === 'web';
  }
}
