import React, { useContext, useState } from 'react';
import styled, { ThemeContext } from 'styled-components/native';
import { Image } from 'react-native';
import { useScreenDimensions } from 'use-dimensions';
import { Redirect, useHistory, useLocation } from 'react-router';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import Button from '../../../../presentational/atoms/button';
import Form from '../../../../presentational/atoms/form';
import Input from '../../../../presentational/atoms/input';
import LoginUtil from '../../../../../modules/auth/LoginUtil';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import login from '../../../../../base64Images/login/login';
import logoLargeBlack from '../../../../../base64Images/logo/logo-large-black';

const Header = styled.View`
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  height: 100px;
  padding-left: 20px;
  padding-top: 20px;
`;

const Container = styled.View`
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  height: calc(100vh - 100px);
`;

const LeftContainer = styled.View`
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 0 50px;
`;

const RightContainer = styled.View`
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 0 50px;
`;

const RightContainerInner = styled.View`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const LinkContainer = styled.View`
  margin: 1.5rem;
  text-align: center;
`;

const SignupOrganization = () => {
  const { height, width } = useScreenDimensions();
  const history = useHistory();
  const { state } = useLocation();
  const [organizationName, setOrganizationName] = useState('');
  const [teamName, setTeamName] = useState('');
  if (state === null || state === undefined) {
    return <Redirect to={'/signup'} />;
  }

  return (
    <>
      <Header>
        <Image
          source={{ uri: logoLargeBlack }}
          resizeMode="contain"
          style={{
            width: 300,
            height: 100,
          }}
        />
      </Header>
      <Container>
        <LeftContainer>
          <Image
            source={{ uri: login }}
            resizeMode="contain"
            style={{
              width: Math.max(width / 4, 500),
              height: Math.max(width / 4, 500),
            }}
          />
        </LeftContainer>
        <RightContainer>
          <RightContainerInner>
            <Typography variant={TypographyType.Title} style={{ marginVertical: 10 }}>
              {`所属組織の情報を入力してください`}
            </Typography>
            <Typography variant={TypographyType.Normal} style={{ marginBottom: 50 }}>
              ※あとから変更することもできます。
            </Typography>
            <Form style={{ marginBottom: 30 }}>
              <Input
                name={'organizationName'}
                label={'企業・組織名'}
                description={`※こちらの名前で組織アカウントが作成されます。${'\n'}   あとから変更できます。`}
                validate={{
                  required: {
                    value: true,
                    message: '所属している企業・組織名を入力してください',
                  },
                  maxLength: {
                    value: 20,
                    message: '20文字以内で入力してください',
                  },
                }}
                onChange={(value: string) => setOrganizationName(value)}
              />
              <Input
                name={'teamName'}
                label={'所属部署・チーム名'}
                description={`※最初に作成されるチーム名になります。${'\n'}   あとから変更できます。`}
                validate={{
                  required: {
                    value: true,
                    message: '所属している部署・チーム名を入力してください',
                  },
                  maxLength: {
                    value: 20,
                    message: '20文字以内で入力してください',
                  },
                }}
                onChange={(value: string) => setTeamName(value)}
              />
              <Button
                text="次へ"
                style={{ marginTop: 20, minWidth: 300 }}
                onPress={async () => {
                  history.push({
                    pathname: '/signup/licence-count',
                    state: Object.assign(state, {
                      organizationName: organizationName,
                      teamName: teamName,
                    }),
                  });
                }}
              />
            </Form>
          </RightContainerInner>
        </RightContainer>
      </Container>
    </>
  );
};

export default React.memo(SignupOrganization);
