import React, { useContext, useEffect, useRef, useState } from 'react';
import { View, TouchableOpacity, Image } from 'react-native';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import {
  CustomerAttributeType,
  Member,
  ProjectCustomAttributeMaster,
  ProjectTemplate,
  ProjectTemplateCustomAttribute,
  ProjectTemplateCustomAttributesByProjectDocument,
  useAddProjectTemplateCustomAttributeMutation,
  useDeleteProjectTemplateCustomAttributeMutation,
  useMeQuery,
  useOrganizationClientsQuery,
  useOrganizationMembersQuery,
  useProjectCustomAttributeMastersQuery,
  useProjectTemplateCustomAttributesByProjectQuery,
  useUpdateProjectTemplateCustomAttributeMutation,
  useUpdateProjectTemplateMutation,
} from '../../../../../../graphql/api/API';
import EditableText from '../../../../../presentational/atoms/editable-text';
import Input, { ListValueMap } from '../../../../../presentational/atoms/input';
import Typography, { TypographyType } from '../../../../../presentational/atoms/typography';
import { IStyleTheme, IThemePart } from '../../../../../theme';
import Form from '../../../../../presentational/atoms/form';
import { LoginUserContext } from '../../../../../../modules/auth/LoginUserContext';
import Button from '../../../../../presentational/atoms/button';
import useHover from '../../../../../presentational/atoms/editable-text/use-hover';
import TaskInfoElement from '../../task-detail/task-info-element';
import CustomAttributeIcon from '../../../../../presentational/molecules/image-icon/custom-attribute';
import DeleteIcon from '../../../../../presentational/molecules/image-icon/delete';
import when from '../../../../../../lang-extention/When';
import moment from 'moment-timezone';
import Modal from '../../../../../presentational/molecules/modal';
import { useHotkeys } from 'react-hotkeys-hook';
import DateTimePicker from '../../../../../presentational/atoms/date-time-picker/index.web';
import VirtualizedFlatList, {
  GlobalDragContextProvider,
} from '../../../../../presentational/atoms/list2/virtualized-flat-list';

const Conatiner = styled.View`
  display: flex;
  flex-direction: column;
  padding: 10px 15px;G
  align-items: flex-start;
  z-index: 2; //DateTimePickerを使用する際には、外側の要素のz-indexを指定しないと正しく表示されないケースがあるので、ここで指定している
`;

const Row = styled.View`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: space-between;
`;

interface IAttachmentFileItemMenuProps extends IStyleTheme {
  isHover: boolean;
}

const AttachmentFileItemMenu = styled.View<IAttachmentFileItemMenuProps>`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.4s;
  z-index: 2;
  position: absolute;
  right: 5;
  top: 5;
  opacity: ${(props: IAttachmentFileItemMenuProps) => (props.isHover ? '1' : '0')};
`;

interface IProjectDescriptionProps {
  project: ProjectTemplate;
  organizationMembers: Member[];
  descriptionRowRef: any;
}

const ProjectDescription = (props: IProjectDescriptionProps) => {
  const [loginUser, _] = useContext(LoginUserContext);
  const [hoverRef, isHover, setHover] = useHover();
  const [showEditor, setShowEditor] = useState(false);
  const themeContext: IThemePart = useContext(ThemeContext);
  const [description, setDescription] = useState(props.project.description || '');
  const [updateProject, __] = useUpdateProjectTemplateMutation();

  const isContentEmpty =
    props.project.description === null ||
    (props.project.description &&
      JSON.parse(props.project.description).blocks &&
      JSON.parse(props.project.description).blocks.length > 0 &&
      JSON.parse(props.project.description).blocks[0]?.text === '');

  return (
    <TaskInfoElement title={'説明'} style={{ flex: 1 }} elementStyle={{ alignItems: 'flex-start' }}>
      <View ref={hoverRef as any}>
        {showEditor ? (
          <>
            <Form style={{ zIndex: 2 }}>
              <Input
                initialValue={props.project.description || ''}
                name={'description'}
                type={'rich-text-editor'}
                zIndexAdjustRef={props.descriptionRowRef}
                showToolBarAlways={true}
                autoFocus={true}
                onChange={(value) => {
                  setDescription(value);
                }}
              />
            </Form>
            <View style={{ flexDirection: 'row', marginTop: 10, zIndex: 1 }}>
              <Button
                text="保存"
                style={{ height: 30, marginRight: 5 }}
                textStyle={{ fontSize: 14 }}
                onPress={async () => {
                  if (description === props.project.description) {
                    return;
                  }
                  setDescription(description);
                  setShowEditor(false);
                  setHover(false);
                  updateProject({
                    variables: {
                      id: props.project.id!,
                      input: {
                        name: props.project.name,
                        description: description,
                        clientId: props.project.client?.id,
                        estimateTimeSec: props.project.estimateTimeSec,
                        versionNo: props.project.versionNo,
                      },
                    },
                  });
                }}
              />
              <Button
                text="キャンセル"
                style={{ height: 30, backgroundColor: 'transparent' }}
                textStyle={{ fontSize: 14, color: themeContext.colors.primary }}
                onPress={() => {
                  setDescription(props.project.description || '');
                  setShowEditor(false);
                  setHover(false);
                }}
              />
            </View>
          </>
        ) : (
          <TouchableOpacity onPress={() => setShowEditor(true)} style={{ zIndex: 2 }}>
            <Form>
              <div
                style={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  backgroundColor: themeContext.colors.separator,
                  zIndex: 2,
                  opacity: isHover ? 0.5 : 0,
                  cursor: 'pointer',
                }}
              />
              <View
                style={{ opacity: isContentEmpty ? 0.2 : 1, minHeight: 50, paddingHorizontal: 10 }}>
                <Input
                  initialValue={
                    isContentEmpty
                      ? `{
                  "blocks": [
                    {
                      "key": "dummy",
                      "text": "説明を追加...",
                      "type": "unstyled",
                      "depth": 0,
                      "inlineStyleRanges": [],
                      "entityRanges": [],
                      "data": {}
                    }
                  ],
                  "entityMap": {}
                }
                `
                      : props.project.description!
                  }
                  name={'description'}
                  type={'rich-text-editor'}
                  zIndexAdjustRef={props.descriptionRowRef}
                  showToolBarAlways={false}
                  multiline={true}
                  readonly={true}
                />
              </View>
            </Form>
          </TouchableOpacity>
        )}
      </View>
    </TaskInfoElement>
  );
};

interface IDeleteCustomAttributeModalProps {
  customAttribute: ProjectTemplateCustomAttribute;
  showModal: boolean;
  onPressYes: () => Promise<void>;
  onCloseModal: () => void;
}
const DeleteCustomAttributeConfirmModal = (props: IDeleteCustomAttributeModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  useHotkeys(
    'Enter',
    () => {
      props.onPressYes();
    },
    {
      enabled: props.showModal,
    }
  );

  return (
    <Modal
      title={'このカスタム項目を削除しますか？'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <View style={{ flexDirection: 'column' }}>
        <View style={{ marginTop: 10 }}>
          <Typography variant={TypographyType.Normal} style={{ textAlign: 'center' }}>
            {props.customAttribute.master.name}
          </Typography>
        </View>
        <View style={{ marginTop: 10 }}>
          <Typography
            variant={TypographyType.Description}
            style={{ textAlign: 'center', color: themeContext.colors.error }}>
            {`この操作はやり直しが出来ません`}
          </Typography>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
          <Button
            text={'キャンセル'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.primary }}
            disableValidate={true}
            onPress={() => {
              props.onCloseModal();
            }}
          />
          <Button
            text={'削除する'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              borderColor: themeContext.colors.error,
              borderRadius: 3,
              borderWidth: 1,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.error }}
            onPress={async () => {
              await props.onPressYes();
            }}
          />
        </View>
      </View>
    </Modal>
  );
};

interface IAddCustomAttributeModalProps {
  project: ProjectTemplate;
  alreadyExistProjectCustomAttributes: ProjectTemplateCustomAttribute[];
  showModal: boolean;
  onPressYes: () => Promise<void>;
  onCloseModal: () => void;
}

const AddCustomAttributeModal = (props: IAddCustomAttributeModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [attribute, setAttribute] = useState<ProjectCustomAttributeMaster | null>(null);
  const [value, setValue] = useState<string | null>(null);
  const { data, loading } = useProjectCustomAttributeMastersQuery({
    fetchPolicy: 'network-only',
  });
  const [addCustomAttribute] = useAddProjectTemplateCustomAttributeMutation({
    variables: {
      projectTemplateId: props.project.id!,
      input: {
        projectCustomAttributeMasterId: attribute?.id!,
        projectCustomAttributeMasterListItemId:
          attribute?.type === CustomerAttributeType.List ? value : null,
        value: attribute?.type === CustomerAttributeType.List ? null : value!,
      },
    },
    refetchQueries: [
      {
        query: ProjectTemplateCustomAttributesByProjectDocument,
        variables: {
          projectTemplateId: props.project.id!,
        },
      },
    ],
  });

  useEffect(() => {
    setAttribute(null);
    setValue(null);
  }, [props.showModal]);

  const attributes = data?.projectCustomAttributeMasters?.filter(
    (master) =>
      !props.alreadyExistProjectCustomAttributes.find((exist) => exist.master.id === master!.id)
  );

  if (loading) {
    return <></>;
  }

  return (
    <Modal
      title={'カスタム項目を追加する'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <View style={{ flexDirection: 'column' }}>
        <Form style={{ minWidth: 400, zIndex: 2 }}>
          <Row>
            <TaskInfoElement title={`カスタム項目`} style={{ flex: 1 }}>
              <Input
                name={'attribute'}
                type={'picker'}
                isSearchable={true}
                placeholder={'選択してください'}
                pickerItems={(attributes ?? []).map((master) => {
                  return {
                    label: master!.name,
                    value: master!.id!,
                  };
                })}
                onChange={(value) => {
                  const targets = (attributes || []).filter((master) => master!.id! === value);
                  if (targets && targets.length > 0) {
                    setAttribute(targets[0] ?? null);
                  }
                }}
                validate={{
                  required: {
                    value: true,
                    message: '選択肢を入力してください',
                  },
                }}
              />
            </TaskInfoElement>
          </Row>
          <Row style={{ zIndex: 2 }}>
            <TaskInfoElement title={`値`} style={{ flex: 1 }}>
              {when(attribute?.type) //
                .on(
                  (value) => value === CustomerAttributeType.FreeText,
                  () => (
                    <Input
                      name={'textValue'}
                      placeholder={'値を入力してください'}
                      onChange={(value) => setValue(value)}
                      validate={{
                        required: {
                          value: attribute?.required,
                          message: '値を入力してください',
                        },
                        maxLength: {
                          value: 50,
                          message: '50文字以内で入力してください',
                        },
                      }}
                    />
                  )
                )
                .on(
                  (value) => value === CustomerAttributeType.Number,
                  () => (
                    <Input
                      name={'numberValue'}
                      placeholder={'値を入力してください'}
                      onChange={(value) => setValue(value)}
                      validate={{
                        required: {
                          value: attribute?.required,
                          message: '値を入力してください',
                        },
                        pattern: {
                          value: /^[0-9]+$/,
                          message: '半角数字を入力してください。',
                        },
                      }}
                    />
                  )
                )
                .on(
                  (value) => value === CustomerAttributeType.Date,
                  () => (
                    <DateTimePicker
                      mode={'date'}
                      name={'dateValue'}
                      onChange={(value) => {
                        if (value) {
                          setValue(value!.toISOString());
                        } else {
                          setValue(null);
                        }
                      }}
                      onBlur={(value, element) => {
                        if (value) {
                          setValue(value!.toISOString());
                        } else {
                          setValue(null);
                        }
                      }}
                      onPressEnter={(value) => {
                        if (value) {
                          setValue(value!.toISOString());
                        } else {
                          setValue(null);
                        }
                      }}
                      validate={{
                        required: {
                          value: attribute!.required,
                          message: '値を入力してください',
                        },
                      }}
                    />
                  )
                )
                .on(
                  (value) => value === CustomerAttributeType.DateTime,
                  () => (
                    <DateTimePicker
                      mode={'datetime'}
                      name={'datetimeValue'}
                      onChange={(value) => {
                        if (value) {
                          setValue(value!.toISOString());
                        } else {
                          setValue(null);
                        }
                      }}
                      onBlur={(value, element) => {
                        if (value) {
                          setValue(value!.toISOString());
                        } else {
                          setValue(null);
                        }
                      }}
                      onPressEnter={(value) => {
                        if (value) {
                          setValue(value!.toISOString());
                        } else {
                          setValue(null);
                        }
                      }}
                      validate={{
                        required: {
                          value: attribute!.required,
                          message: '値を入力してください',
                        },
                      }}
                    />
                  )
                )
                .on(
                  (value) => value === CustomerAttributeType.List,
                  () => (
                    <Input
                      name="listValue"
                      value={''}
                      type={'picker'}
                      inputContainerStyle={{ minWidth: 400 }}
                      placeholder={'選択してください'}
                      pickerItems={
                        attribute!.required
                          ? attribute!.listItem
                              .slice()
                              .sort((a, b) => b!.sortNo - a!.sortNo)
                              .map((item) => {
                                return {
                                  label: item!.value,
                                  value: item!.id,
                                };
                              })
                          : [
                              {
                                label: '-',
                                value: null,
                              },
                            ].concat(
                              attribute!.listItem
                                .slice()
                                .sort((a, b) => b!.sortNo - a!.sortNo)
                                .map((item) => {
                                  return {
                                    label: item!.value,
                                    value: item!.id,
                                  } as ListValueMap;
                                })
                            )
                      }
                      onChange={(value) => setValue((value as string) || null)}
                      validate={{
                        required: {
                          value: attribute!.required,
                          message: '選択肢を入力してください',
                        },
                      }}
                    />
                  )
                )
                .otherwise(() => (
                  <Input name={'value'} placeholder={'-'} readonly />
                ))}
            </TaskInfoElement>
          </Row>
          <View
            style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
            <Button
              text={'登録する'}
              style={{
                flex: 1,
                width: '100%',
                minHeight: 50,
                marginVertical: 10,
              }}
              textStyle={{
                backgroundColor: themeContext.colors.primary,
                color: '#FFFFFF',
                padding: 5,
              }}
              onPress={async () => {
                await addCustomAttribute();
                await props.onPressYes();
              }}
            />
            <Button
              text={'キャンセル'}
              style={{
                flex: 1,
                width: '100%',
                minHeight: 50,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.primary }}
              disableValidate={true}
              onPress={() => {
                props.onCloseModal();
              }}
            />
          </View>
        </Form>
      </View>
    </Modal>
  );
};

interface ICustomAttributeRowProps {
  project: ProjectTemplate;
  customAttribute: ProjectTemplateCustomAttribute;
  zIndex: number;
}

const CustomAttributeRow = (props: ICustomAttributeRowProps) => {
  const ref = useRef();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [updateCustomAttribute] = useUpdateProjectTemplateCustomAttributeMutation();
  const [deleteCustomAttribute] = useDeleteProjectTemplateCustomAttributeMutation({
    refetchQueries: [
      {
        query: ProjectTemplateCustomAttributesByProjectDocument,
        variables: {
          projectTemplateId: props.project.id!,
        },
      },
    ],
  });

  useEffect(() => {
    (
      ref as any
    ).current.parentElement.parentElement.parentElement.parentElement.parentElement.style.zIndex =
      props.zIndex;
  }, [props.zIndex]);

  return (
    <View key={props.customAttribute.id} ref={ref as any} style={{ minWidth: 400 }}>
      <TaskInfoElement
        title={props.customAttribute.master.name}
        style={{ flex: 1 }}
        contentFlexDirection={'row'}
        contentContainerStyle={{ alignItems: 'center' }}
        elementStyle={{ alignItems: 'center' }}
        key={props.customAttribute.id}>
        {when(props.customAttribute.master.type) //
          .on(
            (value) => value === CustomerAttributeType.FreeText,
            () => (
              <EditableText
                value={props.customAttribute.value ?? ''}
                style={{ minWidth: 100 }}
                type={'text'}
                validate={{
                  required: {
                    value: props.customAttribute.master.required,
                    message: '値を入力してください',
                  },
                  maxLength: {
                    value: 50,
                    message: '50文字以内で入力してください',
                  },
                }}
                onChange={async (value) => {
                  await updateCustomAttribute({
                    variables: {
                      id: props.customAttribute.id!,
                      input: {
                        value: value as string,
                        sortNo: props.customAttribute.sortNo,
                      },
                    },
                  });
                }}
                textStyle={{ fontSize: 16 }}
              />
            )
          )
          .on(
            (value) => value === CustomerAttributeType.Number,
            () => (
              <EditableText
                value={props.customAttribute.value ?? ''}
                style={{ minWidth: 100 }}
                type={'text'}
                validate={{
                  required: {
                    value: props.customAttribute.master.required,
                    message: '値を入力してください',
                  },
                  pattern: {
                    value: /^[0-9]+$/,
                    message: '半角数字を入力してください。',
                  },
                }}
                onChange={async (value) => {
                  await updateCustomAttribute({
                    variables: {
                      id: props.customAttribute.id!,
                      input: {
                        value: value as string,
                        sortNo: props.customAttribute.sortNo,
                      },
                    },
                  });
                }}
                textStyle={{ fontSize: 16 }}
              />
            )
          )
          .on(
            (value) => value === CustomerAttributeType.Date,
            () => (
              <EditableText
                value={props.customAttribute.value ? moment(props.customAttribute.value) : null}
                style={{ minWidth: 100 }}
                type={'date-picker'}
                disableClear={props.customAttribute.master.required}
                validate={{
                  required: {
                    value: props.customAttribute.master.required,
                    message: '値を入力してください',
                  },
                }}
                onChange={async (value) => {
                  await updateCustomAttribute({
                    variables: {
                      id: props.customAttribute.id!,
                      input: {
                        value: value ? (value as moment.Moment).toISOString() : null,
                        sortNo: props.customAttribute.sortNo,
                      },
                    },
                  });
                }}
                textStyle={{ fontSize: 16 }}
              />
            )
          )
          .on(
            (value) => value === CustomerAttributeType.DateTime,
            () => (
              <EditableText
                value={props.customAttribute.value ? moment(props.customAttribute.value) : null}
                style={{ minWidth: 100 }}
                type={'date-time-picker'}
                disableClear={props.customAttribute.master.required}
                validate={{
                  required: {
                    value: props.customAttribute.master.required,
                    message: '値を入力してください',
                  },
                }}
                onChange={async (value) => {
                  await updateCustomAttribute({
                    variables: {
                      id: props.customAttribute.id!,
                      input: {
                        value: value ? (value as moment.Moment).toISOString() : null,
                        sortNo: props.customAttribute.sortNo,
                      },
                    },
                  });
                }}
                textStyle={{ fontSize: 16 }}
              />
            )
          )
          .on(
            (value) => value === CustomerAttributeType.List,
            () => (
              <EditableText
                value={props.customAttribute.listItem?.id ?? ''}
                type={'picker'}
                isSearchable={true}
                style={{ minWidth: 100 }}
                emptyText={'-'}
                pickerItems={
                  props.customAttribute.master!.required
                    ? props.customAttribute
                        .master!.listItem.slice()
                        .sort((a, b) => b!.sortNo - a!.sortNo)
                        .map((item) => {
                          return {
                            label: item!.value,
                            value: item!.id,
                          };
                        })
                    : [
                        {
                          label: '-',
                          value: null,
                        },
                      ].concat(
                        props.customAttribute
                          .master!.listItem.slice()
                          .sort((a, b) => b!.sortNo - a!.sortNo)
                          .map((item) => {
                            return {
                              label: item!.value,
                              value: item!.id,
                            } as ListValueMap;
                          })
                      )
                }
                onChange={async (value) => {
                  await updateCustomAttribute({
                    variables: {
                      id: props.customAttribute.id!,
                      input: {
                        projectCustomAttributeMasterListItemId:
                          !!value && (value as string).trim().length > 0 ? (value as string) : null,
                        sortNo: props.customAttribute.sortNo,
                      },
                    },
                  });
                }}
                textStyle={{ fontSize: 18 }}
              />
            )
          )
          .otherwise(() => (
            <Input name={'value'} placeholder={'-'} readonly />
          ))}
        <DeleteIcon
          size={21}
          containerStyle={{ marginLeft: 5, padding: 5 }}
          onPress={() => setShowDeleteModal(true)}
        />
      </TaskInfoElement>
      <DeleteCustomAttributeConfirmModal
        customAttribute={props.customAttribute}
        showModal={showDeleteModal}
        onPressYes={async () => {
          setShowDeleteModal(false);
          await deleteCustomAttribute({
            variables: {
              id: props.customAttribute.id!,
            },
          });
        }}
        onCloseModal={() => setShowDeleteModal(false)}
      />
    </View>
  );
};

interface IProjectDetailStandardInfoProps {
  organizationId: string;
  project: ProjectTemplate;
  parentScrollViewRef: any;
}

const ProjectDetailStandardInfo = (props: IProjectDetailStandardInfoProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const attachmentFileInputRef = useRef();
  const [loginUser, _] = useContext(LoginUserContext);
  const [showAddCustomAttributeModal, setShowAddCustomAttributeModal] = useState(false);
  const descriptionRowRef = useRef();
  const { loading: meLoading, data: meData } = useMeQuery();
  const { loading: organizationMembersLoading, data: organizationMembersData } =
    useOrganizationMembersQuery({
      variables: {
        organizationId: loginUser!.organizationId!,
      },
    });
  const { loading: clientLoading, data: clientData } = useOrganizationClientsQuery({
    variables: {
      organizationId: loginUser!.organizationId,
    },
    fetchPolicy: 'network-only',
  });

  const { loading: customAttributeLoading, data: customAttributeData } =
    useProjectTemplateCustomAttributesByProjectQuery({
      variables: {
        projectTemplateId: props.project.id!,
      },
      fetchPolicy: 'network-only',
    });

  const [updateProject, __] = useUpdateProjectTemplateMutation();
  const [updateProjectTemplateCustomAttribute] = useUpdateProjectTemplateCustomAttributeMutation();

  if (
    meLoading ||
    !meData?.me ||
    organizationMembersLoading ||
    clientLoading ||
    customAttributeLoading
  ) {
    return <></>;
  }

  return (
    <>
      <Conatiner>
        <Row>
          <TaskInfoElement
            title={'取引先'}
            style={{ flex: 1, maxWidth: 600 }}
            elementStyle={{ alignItems: 'flex-start' }}>
            <EditableText
              value={props.project.client?.id || ''}
              style={{ width: '100%' }}
              type={'picker'}
              isSearchable={true}
              renderComponent={() => {
                if (props.project.client) {
                  return (
                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                      <Typography variant={TypographyType.Normal} style={{ marginLeft: 5 }}>
                        {props.project.client!.name}
                      </Typography>
                    </View>
                  );
                }
                return <Typography variant={TypographyType.Normal}>-</Typography>;
              }}
              pickerItems={[
                {
                  label: 'なし',
                  value: '',
                },
              ].concat(
                (clientData?.organizationClients || []).slice().map((client) => {
                  return {
                    label: `${client!.name}` + (client!.code ? ` (${client!.code})` : ''),
                    value: client!.id,
                  } as ListValueMap;
                })
              )}
              onChange={(value) => {
                updateProject({
                  variables: {
                    id: props.project.id!,
                    input: {
                      name: props.project.name,
                      description: props.project.description,
                      clientId: (value as string) || null,
                      estimateTimeSec: props.project.estimateTimeSec,
                      versionNo: props.project.versionNo,
                    },
                  },
                });
              }}
              textStyle={{ fontSize: 18 }}
            />
          </TaskInfoElement>
        </Row>
        <Row style={{ zIndex: 2, justifyContent: 'flex-start' }}>
          <TaskInfoElement
            title={'見積時間'}
            style={{ flex: 1, maxWidth: 300 }}
            contentFlexDirection={'row'}>
            <EditableText
              containerStyle={{ minWidth: 100 }}
              value={props.project.estimateTimeSec || null}
              type={'time-picker'}
              onChange={(value) => {
                updateProject({
                  variables: {
                    id: props.project.id!,
                    input: {
                      name: props.project.name,
                      description: props.project.description,
                      clientId: props.project.client?.id ?? null,
                      estimateTimeSec: (value as number) || null,
                      versionNo: props.project.versionNo,
                    },
                  },
                });
              }}
            />
          </TaskInfoElement>
        </Row>
        <Row>
          <TaskInfoElement title={` `} contentContainerStyle={{ alignItems: 'flex-start' }}>
            <CustomAttributeIcon size={14} onPress={() => setShowAddCustomAttributeModal(true)}>
              <Typography
                variant={TypographyType.Description}
                style={{ marginLeft: 10, color: themeContext.colors.description }}>
                カスタム項目を追加
              </Typography>
            </CustomAttributeIcon>
          </TaskInfoElement>
        </Row>
        <GlobalDragContextProvider>
          <VirtualizedFlatList
            style={{ height: 'auto' }}
            items={(customAttributeData?.projectTemplateCustomAttributesByProject ?? [])
              .slice()
              .sort((a, b) => a!.sortNo - b!.sortNo)}
            renderItem={(customAttribute, i) => {
              return (
                <Row
                  style={{
                    zIndex:
                      customAttributeData!.projectTemplateCustomAttributesByProject!.length +
                      10 -
                      i,
                    cursor: 'grab',
                  }}>
                  <CustomAttributeRow
                    project={props.project}
                    customAttribute={customAttribute as ProjectTemplateCustomAttribute}
                    zIndex={
                      customAttributeData!.projectTemplateCustomAttributesByProject!.length + 10 - i
                    }
                    key={(customAttribute as ProjectTemplateCustomAttribute).id}
                  />
                </Row>
              );
            }}
            getKey={(item) => (item as any).id}
            itemHeight={50}
            virticalDraggable={true}
            onDrop={async (info) => {
              const sortedItems = (
                customAttributeData?.projectTemplateCustomAttributesByProject ?? []
              )
                .slice()
                .sort((a, b) => a!.sortNo - b!.sortNo);

              const isMoveToFirst = info.endRowIndex === 0;
              const isMoveToLast = info.endRowIndex === sortedItems.length - 1;
              const isMoveToDown = info.endRowIndex - info.startRowIndex > 0;
              let sortNo;
              if (isMoveToLast) {
                sortNo = new Date().getTime();
              } else if (isMoveToFirst) {
                sortNo = sortedItems[info.endRowIndex]!.sortNo - 1000;
              } else {
                if (isMoveToDown) {
                  const beforeTask = sortedItems[info.endRowIndex];
                  const afterTask = sortedItems[info.endRowIndex + 1];
                  sortNo = Math.floor((beforeTask!.sortNo + afterTask!.sortNo) / 2);
                } else {
                  const beforeTask = sortedItems[info.endRowIndex - 1];
                  const afterTask = sortedItems[info.endRowIndex];
                  sortNo = Math.floor((beforeTask!.sortNo + afterTask!.sortNo) / 2);
                }
              }
              await updateProjectTemplateCustomAttribute({
                variables: {
                  id: (info.item as ProjectTemplateCustomAttribute).id!,
                  input: {
                    sortNo: sortNo,
                    value: (info.item as ProjectTemplateCustomAttribute).value,
                    projectCustomAttributeMasterListItemId: (
                      info.item as ProjectTemplateCustomAttribute
                    ).listItem?.id,
                  },
                },
              });
            }}
          />
        </GlobalDragContextProvider>
        <Row style={{ marginTop: 20 }} ref={descriptionRowRef}>
          <ProjectDescription
            project={props.project}
            organizationMembers={organizationMembersData!.organizationMembers as Member[]}
            descriptionRowRef={descriptionRowRef}
          />
        </Row>
        <AddCustomAttributeModal
          project={props.project}
          alreadyExistProjectCustomAttributes={
            (customAttributeData?.projectTemplateCustomAttributesByProject as ProjectTemplateCustomAttribute[]) ??
            []
          }
          showModal={showAddCustomAttributeModal}
          onCloseModal={() => setShowAddCustomAttributeModal(false)}
          onPressYes={async () => setShowAddCustomAttributeModal(false)}
        />
      </Conatiner>
    </>
  );
};

export default ProjectDetailStandardInfo;
