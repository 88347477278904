import React, { useContext } from 'react';
//@ts-ignore
import { ThemeContext } from 'styled-components/native';
import { View, Image } from 'react-native';
import { IThemePart } from '../../../../theme';
import { ResponsiveBar } from '@nivo/bar';
import TimeUtil from '../../../../../util/TimeUtil';
import _ from 'lodash';
import moment from 'moment-timezone';
import {
  Organization,
  ReportType,
  useTeamDateGraphDataQuery,
} from '../../../../../graphql/api/API';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import { useHistory } from 'react-router';
import useDimensions from 'use-dimensions';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import noData05 from '../../../../../base64Images/no-data/no-data-5';
import Spinner from '../../../../presentational/atoms/spinner';

function prepareDate(data: any) {
  return data.map((d: any) => {
    return merge(
      d.date,
      d.items.map((item: any) => {
        return {
          [`team-${item.teamId}`]: item.workingTimeSec,
          [`team-${item.teamId}-teamId`]: item.teamId,
          [`team-${item.teamId}-teamName`]: item.teamName,
          [`team-${item.teamId}Color`]: item.color,
        };
      })
    );
  });
}

function prepareTotalWorkingTimeSecGroupByDate(data: any) {
  return data.map((d: any) => {
    return {
      date: d.date,
      value: d.items.reduce(function (sum: number, item: any) {
        return sum + item.workingTimeSec;
      }, 0),
    };
  });
}

function prepareMaxWorkingTimeSec(data: any) {
  const results = prepareTotalWorkingTimeSecGroupByDate(data);
  let maxValue = 0;
  results.forEach((item: any) => {
    if (maxValue < item.value) {
      maxValue = item.value;
    }
  });
  return maxValue;
}

function merge(date: any, items: any[]) {
  let result: any = {};
  result['date'] = date;
  for (let i = 0, item; (item = items[i]); i++) {
    for (let k in item) {
      if (!result.hasOwnProperty(k)) {
        result[k] = item[k];
      }
    }
  }
  return result;
}

function prepareDataKeys(data: any): string[] {
  return Array.from(
    new Set(
      data
        .map((d: any) => d.items.map((item: any) => `team-${item.teamId}`))
        .reduce((acc: any, value: any) => acc.concat(value), [])
    )
  );
}

function prepareDataKeyAndTitle(data: any): string[] {
  return _.uniqBy(
    Array.from(
      new Set(
        data
          .map((d: any) =>
            d.items.map((item: any) => {
              return {
                key: `team-${item.teamId}`,
                title: item.teamName,
              };
            })
          )
          .reduce((acc: any, value: any) => acc.concat(value), [])
      )
    ),
    'key'
  );
}

interface IBarGraphInnerProps {
  organization: Organization;
  data: any;
}

const BarGraphInner = React.memo((props: IBarGraphInnerProps) => {
  const history = useHistory();
  const [loginUser, _] = useContext(LoginUserContext);
  const themeContext: IThemePart = useContext(ThemeContext);
  const { window: win } = useDimensions();
  const maxWorkingTimeSec = prepareMaxWorkingTimeSec(props.data);

  const isEmpty = props.data.filter((d: any) => d.items.length > 0).length === 0;
  if (isEmpty) {
    return (
      <View
        style={{
          height: Math.max(Math.min(win.height / 3, 500), 300),
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Image
          source={{ uri: noData05 }}
          resizeMode="contain"
          style={{
            width: 350,
            height: 200,
            opacity: 0.7,
          }}
        />
        <Typography
          variant={TypographyType.Normal}
          style={{ color: themeContext.colors.description, textAlign: 'center' }}>
          {`ご指定の集計範囲にはデータがありませんでした。${'\n'}別の期間を指定してください。`}
        </Typography>
      </View>
    );
  }

  const isLargeData =
    props.data.reduce((count: number, data: any) => {
      return count + data.items.length;
    }, 0) > 100;

  if (isLargeData) {
    return (
      <View>
        <View
          style={{
            height: Math.max(Math.min(win.height / 3, 500), 200),
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Typography variant={TypographyType.Description} style={{ fontSize: 16 }}>
            データ量が多いため、グラフを表示できませんでした。
          </Typography>
        </View>
      </View>
    );
  }

  return (
    <View>
      <View style={{ height: Math.max(Math.min(win.height / 3, 500), 200) }}>
        <ResponsiveBar
          data={prepareDate(props.data)}
          keys={prepareDataKeys(props.data)}
          indexBy="date"
          margin={{ top: 50, right: 130, bottom: 50, left: 90 }}
          padding={0.3}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          colors={(data) => {
            return data.data[`${data.id}Color`];
          }}
          defs={[
            {
              id: 'dots',
              type: 'patternDots',
              background: 'inherit',
              color: '#38bcb2',
              size: 4,
              padding: 1,
              stagger: true,
            },
            {
              id: 'lines',
              type: 'patternLines',
              background: 'inherit',
              color: '#eed312',
              rotation: -45,
              lineWidth: 6,
              spacing: 10,
            },
          ]}
          fill={[
            {
              match: {
                id: 'fries',
              },
              id: 'dots',
            },
            {
              match: {
                id: 'sandwich',
              },
              id: 'lines',
            },
          ]}
          borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          axisBottom={{
            legend: '分',
            legendPosition: 'start',
            legendOffset: 13,
            format: (value) => moment(value).format('MM/DD'),
          }}
          axisLeft={{
            format: (value) => TimeUtil.format(Math.ceil((value as number) / 60) as number),
            tickValues:
              maxWorkingTimeSec < 900
                ? [...Array(Math.ceil(maxWorkingTimeSec / (1 * 60) + 1))].map((_, i) => i * 1 * 60)
                : maxWorkingTimeSec < 1800
                ? [...Array(Math.ceil(maxWorkingTimeSec / (5 * 60) + 1))].map((_, i) => i * 5 * 60)
                : maxWorkingTimeSec < 3600
                ? [...Array(Math.ceil(maxWorkingTimeSec / (10 * 60) + 1))].map(
                    (_, i) => i * 10 * 60
                  )
                : maxWorkingTimeSec < 3600 * 5
                ? [...Array(Math.ceil(maxWorkingTimeSec / (30 * 60) + 1))].map(
                    (_, i) => i * 30 * 60
                  )
                : [...Array(10)].map(
                    (_, i) => i * (Math.ceil(maxWorkingTimeSec / (60 * 60) / 10) * 60 * 60)
                  ),
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          layers={['grid', 'axes', 'bars', 'markers']}
          labelFormat={(value) => {
            return TimeUtil.formatForTask(value as number);
          }}
          tooltip={(input) => {
            return (
              <View>
                <Typography variant={TypographyType.Normal} style={{}}>
                  チーム:{input.data[`${input.id}-teamName`]}
                </Typography>
                <Typography variant={TypographyType.Normal} style={{}}>
                  作業時間:{TimeUtil.formatForTask(input.data[input.id] as number)}
                </Typography>
              </View>
            );
          }}
          legends={[
            {
              data: prepareDataKeyAndTitle(props.data).map((d: any) => {
                return {
                  id: d.key,
                  label: d.title,
                };
              }),
              dataFrom: 'keys',
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
        />
      </View>
    </View>
  );
});

interface ITeamBarGraphProps {
  reportType: ReportType;
  organization: Organization;
  startDateTime: moment.Moment;
  endDateTime: moment.Moment;
  teamId?: string | null;
  targetMemberId?: string | null;
  teamIds: string[];
  projectIds: string[];
  clientIds: string[];
  memberIds: string[];
  setLoadData: (value: boolean) => void;
}

const TeamBarGraph = (props: ITeamBarGraphProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, _] = useContext(LoginUserContext);
  const { loading, data, error } = useTeamDateGraphDataQuery({
    variables: {
      input: {
        reportType: props.reportType,
        organizationId: loginUser!.organizationId,
        teamId: props.teamId,
        memberId: props.targetMemberId,
        start: moment(props.startDateTime).startOf('day').toISOString(),
        end: moment(props.endDateTime).endOf('day').toISOString(),
        teamIds: props.teamIds,
        projectIds: props.projectIds,
        clientIds: props.clientIds,
        memberIds: props.memberIds,
        timeZoneOffset: moment().tz(moment.tz.guess()).format('Z'),
      },
    },
    fetchPolicy: 'network-only',
    onCompleted: () => props.setLoadData(true),
  });

  if (loading) {
    return <Spinner loading={loading} />;
  }

  return <BarGraphInner organization={props.organization} data={data!.teamDateGraphData} />;
};

export default React.memo(TeamBarGraph);
