import React, { useContext, useEffect, useRef, useState } from 'react';
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../../../theme';
import { LoginUserContext } from '../../../../../../modules/auth/LoginUserContext';
import {
  useFetchOrganizationActiveMembersQuery,
  useMeQuery,
  WorkingHistory,
} from '../../../../../../graphql/api/API';
import { useParams } from 'react-router';
import MemberStatus from '../../../organisms/member-status';
import { View } from 'react-native';
import Typography, { TypographyType } from '../../../../../presentational/atoms/typography';
import Spinner from '../../../../../presentational/atoms/spinner';
import CustomScrollView from '../../../../../presentational/atoms/custom-scroll-view';
import Form from '../../../../../presentational/atoms/form';
import Input from '../../../../../presentational/atoms/input';
import Icon from '../../../../../presentational/atoms/icon';
import SearchIcon from '../../../../../presentational/molecules/image-icon/search';

interface IAppOrganizationMembersStatusPageParam {
  organizationId: string;
}

const AppOrganizationMembersStatusPage = () => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [onlyInTeamTask, setOnlyInTeamTask] = useState(true);
  const [searchInput, setSearchInput] = useState('');
  const params = useParams<IAppOrganizationMembersStatusPageParam>();
  const { loading, data, error } = useFetchOrganizationActiveMembersQuery({
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  });
  const { loading: meLoading, data: meData } = useMeQuery();

  if (meLoading) {
    return <></>;
  }

  return (
    <CustomScrollView
      style={{
        flexDirection: 'column',
        height: 'calc(100vh - 57px)',
        width: '100%',
      }}>
      <View
        style={{
          backgroundColor: themeContext.colors.header,
          paddingVertical: 7,
          paddingHorizontal: 3,
          zIndex: 1,
        }}>
        <Typography
          variant={TypographyType.SubTitle}
          style={{ color: '#FFFFFF', fontSize: 16, fontWeight: '600' }}>
          組織のメンバーの状況（最新の作業状況）
        </Typography>
      </View>
      <View style={{ paddingHorizontal: 20, paddingTop: 5 }}>
        <Form>
          <Input
            name={'searchMember'}
            label={''}
            icon={<SearchIcon size={20} containerStyle={{ marginLeft: 10 }} />}
            containerStyle={{ marginVertical: 10 }}
            inputstyle={{ borderWidth: 0 }}
            inputContainerStyle={{
              margin: 5,
              backgroundColor: themeContext.colors.baseColor,
              shadowOffset: {
                width: -1,
                height: -1,
              },
              shadowOpacity: 0.1,
              elevation: 2,
              borderWidth: 1,
              borderRadius: 20,
              borderColor: themeContext.colors.separator,
            }}
            onChange={(value: string) => setSearchInput(value)}
          />
        </Form>
        <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
          <Spinner loading={loading}>
            {(data?.fetchOrganizationActiveMembers || [])
              .slice()
              .filter(
                (member) =>
                  member?.member.name?.includes(searchInput) ||
                  member?.member.mailAddress.includes(searchInput)
              )
              .sort((a, b) => {
                if (a!.member.id! === loginUser!.id) {
                  return -1;
                }
                return a!.member.id! > b!.member.id! ? 1 : 0;
              })
              .map((memberWithLatestTask, i) => {
                const isLoginMember = memberWithLatestTask!.member!.id === loginUser!.id;
                return (
                  <View style={{ marginHorizontal: 10 }} key={i}>
                    <MemberStatus
                      organizationId={params.organizationId}
                      isLoginMember={isLoginMember}
                      isMeAdmin={meData!.me!.adminRole}
                      memberStatus={memberWithLatestTask!}
                    />
                  </View>
                );
              })}
          </Spinner>
        </View>
      </View>
    </CustomScrollView>
  );
};

export default React.memo(AppOrganizationMembersStatusPage);
