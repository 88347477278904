import moment from 'moment-timezone';

export default class DateUtil {
  private constructor() {}

  public static formatDate(date: Date): string {
    if (date) {
      return moment(date).format('YYYY/MM/DD');
    }
    return '-';
  }

  public static formatDateTime(date: Date): string {
    if (date) {
      return moment(date).format('YYYY/MM/DD HH:mm');
    }
    return '-';
  }

  public static roundMinutes(value: moment.Moment, roundMinutes: number): moment.Moment {
    if (value === null || value === undefined) {
      return value;
    }

    const fiveMinutesMilliSeconds = roundMinutes * 60 * 1000;
    const epocTimeMilliSeconds =
      Math.round(value.toDate().getTime() / fiveMinutesMilliSeconds) * fiveMinutesMilliSeconds;
    return moment(new Date(epocTimeMilliSeconds));
  }
}
