import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { Text, TouchableOpacity, View, Image } from 'react-native';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../../../theme';
import Typography, { TypographyType } from '../../../../../presentational/atoms/typography';
import ProjectList from '../../../organisms/project-list';
import HorizontalMenu from '../../../organisms/horizontal-menu';
import Avatar from '../../../../../presentational/atoms/avatar';
import SelectButton from '../../../../../presentational/molecules/select-button';
import TaskList from '../../../organisms/task-list';
import { Route, Switch, useHistory, useLocation, useParams } from 'react-router';
import AttachmentList from './attachment-list';
import ProjectBoard from './board';
import ProjectViewBoard from './project-board';
import ProjectListFull from '../../../organisms/project-list-full';
import ProjectContractListFull from '../../../organisms/project-contract-list-full';
import ProjectGanttChart from './gantt-chart';
import CalendarEventList from './../../../organisms/calendar-event-list';
import { LoginUserContext } from '../../../../../../modules/auth/LoginUserContext';
import {
  JoinedTeamsWithoutPersonalTeamDocument,
  Organization,
  Project,
  ProjectCompleteFilter,
  ProjectStatus,
  Task,
  TaskStatus,
  Team,
  TeamMemberRelationsDocument,
  useCancelFavoriteProjectMutation,
  useCompleteProjectMutation,
  useFavoriteProjectMutation,
  useIncompleteProjectMutation,
  useJoinedTeamsWithoutPersonalTeamQuery,
  useJoinToTeamMutation,
  useMeQuery,
  useMySettingsQuery,
  useOrganizationClientsQuery,
  useOrganizationQuery,
  useProjectMembersQuery,
  useProjectQuery,
  useProjectStatusQuery,
  useProjectTasksEstimateTimeSecQuery,
  useProjectTaskStatusQuery,
  useProjectWorkingTimeSecQuery,
  useTeamMembersQuery,
  useTeamProjectsQuery,
  useTeamProjectStatusQuery,
  useTeamQuery,
  useUpdateProjectSortNoInListMutation,
} from '../../../../../../graphql/api/API';
import moment from 'moment-timezone';
import Modal from '../../../../../presentational/molecules/modal';
import LoginUtil from '../../../../../../modules/auth/LoginUtil';
import Button from '../../../../../presentational/atoms/button';
import Form from '../../../../../presentational/atoms/form';
import noData01 from '../../../../../../base64Images/no-data/no-data-1';
import noData03 from '../../../../../../base64Images/no-data/no-data-3';
import useDimensions from 'use-dimensions';
import Spinner from '../../../../../presentational/atoms/spinner';
import TimeUtil from '../../../../../../util/TimeUtil';
import MultiPickerFilter from '../../../../../presentational/atoms/multi-picker-filter';
import CheckIcon from '../../../../../presentational/molecules/image-icon/check';
import StarIcon from '../../../../../presentational/molecules/image-icon/star';
import EditIcon from '../../../../../presentational/molecules/image-icon/edit';
import ErrorMessageModal from '../../../organisms/error-message-modal';
import Input from '../../../../../presentational/atoms/input';
import SearchIcon from '../../../../../presentational/molecules/image-icon/search';
import OrganizationUtil from '../../../../../../util/OrganizationUtil';

const MenuContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 5px;
  background-color: #879eb3;
`;

interface IMenuItemProps extends IStyleTheme {
  isActive: boolean;
}

const MenuItem = styled.TouchableOpacity<IMenuItemProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom-width: ${(props: IMenuItemProps) => (props.isActive ? '2px' : '0')};
  border-color: #ffffff;
  margin-right: 24px;
`;

const Container = styled.View`
  height: calc(100vh - 57px);
  display: flex;
  flex-direction: column;
`;

const MainAreaContainer = styled.View`
  flex: 1;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.4s;
  marginright: 50px;
  z-index: 1;
`;
// 本当はoverflow-xはvisibleにしたいのだが、CSSの仕様で、overflow-yを指定した場合には、xの挙動もそちらで上書きされるため実現できない

const MainAreaHeader = styled.View`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
  padding: 5px 10px;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.4s;
  z-index: 2;
`;

const MainAreaHeaderForProjectList = styled.View`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.4s;
  z-index: 2;
`;

const MainAreaHeaderTop = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  z-index: 1;
  width: 100%;
`;

const MainAreaHeaderBottom = styled.View`
  display: flex;
  width: 100%;
  height: 40px;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0.2rem 0;
`;

const MainAreaHeaderBottomForProject = styled.View`
  display: flex;
  width: 100%;
  height: 56px;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 5px 20px;
`;

const FilterArea = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
`;

const FilterElementsContainer = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.2rem 0;
  margin: 0 0.2rem;
`;

const FilterElements = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ParticipationToTeam = styled.View`
  width: 100%;
  height: 100px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(248, 248, 248);
`;

export type TaskFilterExpireDate = 'today' | '3days' | 'week';

export interface TaskFilter {
  expire: TaskFilterExpireDate | null;
  taskStatusIds: string[];
  assignerIds: string[];
  showCompleteTask: string[];
  isInitialView: boolean;
}

export const TaskFilterContext = createContext([
  {
    expire: null,
    taskStatusIds: [],
    assignerIds: [],
    showCompleteTask: [],
    isInitialView: true,
  } as TaskFilter,
  (value: TaskFilter) => {},
]);

export const TaskFilterContextProvider = (props: any) => {
  const [filter, setFilter] = useState<TaskFilter>({
    expire: null,
    taskStatusIds: [],
    assignerIds: [],
    showCompleteTask: [],
    isInitialView: true,
  });
  return (
    <TaskFilterContext.Provider value={[filter, setFilter]}>
      {props.children}
    </TaskFilterContext.Provider>
  );
};

export interface ProjectFilter {
  projectStatusIds: string[];
  assignerIds: string[];
  clientIds: string[];
  favoriteOnly: boolean;
  projectName: string | null;
  completeCondition: ProjectCompleteFilter;
  isInitialView: boolean;
}

export const ProjectFilterContext = createContext([
  {
    projectStatusIds: [],
    assignerIds: [],
    clientIds: [],
    favoriteOnly: false,
    projectName: null,
    completeCondition: ProjectCompleteFilter.Incomplete,
    isInitialView: true,
  } as ProjectFilter,
  (value: ProjectFilter) => {},
]);

export const ProjectFilterContextProvider = (props: any) => {
  const [filter, setFilter] = useState<ProjectFilter>({
    projectStatusIds: [],
    assignerIds: [],
    clientIds: [],
    favoriteOnly: false,
    projectName: null,
    completeCondition: ProjectCompleteFilter.Incomplete,
    isInitialView: true,
  });
  return (
    <ProjectFilterContext.Provider value={[filter, setFilter]}>
      {props.children}
    </ProjectFilterContext.Provider>
  );
};

export function matchExpireFilter(task: Task, taskFilter: TaskFilter): boolean {
  if (taskFilter.expire !== null) {
    if (task!.scheduledEndDateTime !== null) {
      const endDateTime = moment(task!.scheduledEndDateTime);
      if (
        taskFilter.expire === 'today' &&
        endDateTime.format('YYYYMMDD') === moment().format('YYYYMMDD')
      ) {
        return true;
      }
      if (taskFilter.expire === '3days' && endDateTime.diff(moment(), 'days') <= 3) {
        return true;
      }
      if (taskFilter.expire === 'week' && endDateTime.diff(moment(), 'days') <= 7) {
        return true;
      }
    }
    return false;
  }
  return true;
}

export function matchTaskStatus(task: Task, taskFilter: TaskFilter): boolean {
  if (taskFilter.taskStatusIds.length === 0) {
    return true;
  }
  return taskFilter.taskStatusIds.indexOf(task!.taskStatus!.id!) !== -1;
}

export function matchTaskAssigner(task: Task, taskFilter: TaskFilter): boolean {
  if (taskFilter.assignerIds.length === 0) {
    // 絞り込みなしなら全部表示
    return true;
  }

  if (task.assignees.length === 0) {
    // タスクの担当者がいなければそのタスクは非表示
    return taskFilter.assignerIds.includes('empty');
  }

  // 絞り込みがある場合には、その絞り込みに合致するタスクを表示
  return (
    task.assignees.filter((info) => taskFilter.assignerIds.indexOf(info.member!.id!) !== -1)
      .length > 0
  );
}

interface IFilterProps {
  project: Project;
  taskStatuses: TaskStatus[];
}

const Filter = (props: IFilterProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const location = useLocation();
  const [loginUser, _] = useContext(LoginUserContext);
  const [taskFilter, setTaskFilter] = useContext(TaskFilterContext);
  const [selectedStatusIds, setSelectedStatusIds] = useState<string[]>([]);
  const { window: win } = useDimensions();
  const windowWidth = win.width;
  const { loading, data, error } = useProjectMembersQuery({
    variables: {
      projectId: props.project.id!,
    },
  });

  useEffect(() => {
    setSelectedStatusIds(
      props.taskStatuses?.filter((status) => !status!.endStatus).map((status) => status!.id!) || []
    );
    //@ts-ignore
    setTaskFilter({
      expire: (taskFilter as TaskFilter).expire,
      taskStatusIds:
        props.taskStatuses?.filter((status) => !status!.endStatus).map((status) => status!.id!) ||
        [],
      // 初期表示では完了ステータスだけ非表示にフィルター設定する
      assignerIds: (taskFilter as TaskFilter).assignerIds,
      showCompleteTask: (taskFilter as TaskFilter).showCompleteTask,
      isInitialView: false,
    });
  }, [props.project]);

  if (loading || !data?.projectMembers) {
    return <></>;
  }

  return (
    <FilterArea>
      {!props.project.team.personalTeam && (
        <FilterElementsContainer>
          <Text
            style={{
              fontSize: 10,
              color: themeContext.colors.description,
              textAlign: 'center',
              marginBottom: 2,
            }}>
            担当者
          </Text>
          <FilterElements>
            {data.projectMembers.length <= 5 ? (
              <SelectButton
                contents={data
                  .projectMembers!.slice()
                  .sort((a, b) => {
                    if (a!.id === loginUser!.id) {
                      // ログインしているユーザー自身を先頭に表示する
                      return 1;
                    }
                    return (a!.name as any) - (b!.name as any);
                  })
                  .map((member: any) => {
                    return {
                      key: member.id.toString(),
                      content: (
                        <Avatar
                          size={22}
                          name={member.name}
                          imageUrl={member.profileImageUrl}
                          showToolTip={true}
                          toolTipKey={member.id.toString()}
                        />
                      ),
                    };
                  })}
                multiSelect={true}
                values={(taskFilter as TaskFilter).assignerIds}
                onChange={(value) => {
                  //@ts-ignore
                  setTaskFilter({
                    ...(taskFilter as TaskFilter),
                    assignerIds: value,
                  });
                }}
              />
            ) : (
              <MultiPickerFilter
                placeHolder="担当者を絞り込む"
                containerStyle={{ width: '6rem' }}
                emptyPickerItem={{
                  label: '未割り当て',
                  value: 'empty',
                }}
                pickerItems={data.projectMembers
                  .slice()
                  .sort((a, b) => {
                    if (a!.id === loginUser!.id) {
                      // ログインしているユーザー自身を先頭に表示する
                      return 1;
                    }
                    return (a!.name as any) - (b!.name as any);
                  })
                  .map((member) => {
                    return {
                      label: member!.name!,
                      subLabel: member!.mailAddress!,
                      value: member!.id!,
                      imageUrl: member!.profileImageUrl,
                    };
                  })}
                onChange={(items) => {
                  //@ts-ignore
                  setTaskFilter({
                    ...(taskFilter as TaskFilter),
                    assignerIds: items.map((item) => item.value),
                  });
                }}
                onBlur={(items) => {
                  //@ts-ignore
                  setTaskFilter({
                    ...(taskFilter as TaskFilter),
                    assignerIds: items.map((item) => item.value),
                  });
                }}
              />
            )}
          </FilterElements>
        </FilterElementsContainer>
      )}
      {windowWidth > 1400 && (
        <FilterElementsContainer>
          <Text
            style={{
              fontSize: 10,
              color: themeContext.colors.description,
              textAlign: 'center',
              marginBottom: 2,
            }}>
            〆切日
          </Text>
          <FilterElements>
            <SelectButton
              contents={[
                {
                  key: 'today',
                  content: '今日',
                },
                {
                  key: '3days',
                  content: '3日以内',
                },
                {
                  key: 'week',
                  content: '1週間以内',
                },
              ]}
              onChange={(value) => {
                //@ts-ignore
                setTaskFilter({
                  expire: value[0] || null,
                  taskStatusIds: (taskFilter as TaskFilter).taskStatusIds,
                  assignerIds: (taskFilter as TaskFilter).assignerIds,
                  showCompleteTask: (taskFilter as TaskFilter).showCompleteTask,
                });
              }}
            />
          </FilterElements>
        </FilterElementsContainer>
      )}
      {location.pathname.indexOf('/board/') === -1 && (
        <FilterElementsContainer>
          <Text
            style={{
              fontSize: 10,
              color: themeContext.colors.description,
              textAlign: 'center',
              marginBottom: 2,
            }}>
            ステータス
          </Text>
          <FilterElements>
            <SelectButton
              textStyle={{ maxWidth: 70 }}
              contents={props.taskStatuses!.map((status) => {
                return {
                  key: status!.id!.toString(),
                  content: status!.name,
                };
              })}
              values={selectedStatusIds}
              multiSelect={true}
              onChange={(value) => {
                setSelectedStatusIds(value);
                //@ts-ignore
                setTaskFilter({
                  expire: (taskFilter as TaskFilter).expire,
                  taskStatusIds: value,
                  assignerIds: (taskFilter as TaskFilter).assignerIds,
                  showCompleteTask: (taskFilter as TaskFilter).showCompleteTask,
                });
              }}
            />
          </FilterElements>
        </FilterElementsContainer>
      )}
    </FilterArea>
  );
};

interface IProjectFilterProps {
  team: Team;
  projectStatuses: ProjectStatus[];
}

const ProjectFilter = (props: IProjectFilterProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const location = useLocation();
  const [loginUser, _] = useContext(LoginUserContext);
  const [projectFilter, setProjectFilter] = useContext(ProjectFilterContext);
  const [selectedStatusIds, setSelectedStatusIds] = useState<string[]>([]);
  const [selectedClientIds, setSelectedClientIds] = useState<string[]>([]);
  const [searchProjectName, setSearchProjectName] = useState('');
  const [searchProjectNameLive, setSearchProjectNameLive] = useState('');
  const { loading, data, error } = useTeamMembersQuery({
    variables: {
      teamId: props.team.id!,
    },
  });
  const {
    loading: clientLoading,
    data: clientData,
    error: clientError,
  } = useOrganizationClientsQuery({
    variables: {
      organizationId: loginUser!.organizationId,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    setSelectedStatusIds(
      props.projectStatuses?.filter((status) => !status!.endStatus).map((status) => status!.id!) ||
        []
    );
    //@ts-ignore
    setProjectFilter({
      ...(projectFilter as ProjectFilter),
      isInitialView: false,
      projectStatusIds:
        props.projectStatuses
          ?.filter((status) => !status!.endStatus)
          .map((status) => status!.id!) || [],
      // 初期表示では完了ステータスだけ非表示にフィルター設定する
    });
  }, [props.team]);

  if (loading || !data?.teamMembers || clientLoading || !clientData?.organizationClients) {
    return <></>;
  }

  return (
    <FilterArea>
      {!props.team.personalTeam && (
        <FilterElementsContainer>
          <Text
            style={{
              fontSize: 10,
              color: themeContext.colors.description,
              textAlign: 'center',
              marginBottom: 2,
            }}>
            担当者
          </Text>
          <FilterElements>
            {data.teamMembers.length <= 5 ? (
              <SelectButton
                contents={data
                  .teamMembers!.slice()
                  .sort((a, b) => {
                    if (a!.id === loginUser!.id) {
                      // ログインしているユーザー自身を先頭に表示する
                      return 1;
                    }
                    return (a!.name as any) - (b!.name as any);
                  })
                  .map((member: any) => {
                    return {
                      key: member.id.toString(),
                      content: (
                        <Avatar
                          size={22}
                          name={member.name}
                          imageUrl={member.profileImageUrl}
                          showToolTip={true}
                          toolTipKey={member.id.toString()}
                        />
                      ),
                    };
                  })}
                multiSelect={true}
                values={(projectFilter as ProjectFilter).assignerIds}
                onChange={(value) => {
                  //@ts-ignore
                  setProjectFilter({
                    ...(projectFilter as ProjectFilter),
                    assignerIds: value,
                  });
                }}
              />
            ) : (
              <MultiPickerFilter
                placeHolder="担当者を絞り込む"
                containerStyle={{ width: '6rem' }}
                emptyPickerItem={{
                  label: '未割り当て',
                  value: 'empty',
                }}
                pickerItems={data.teamMembers
                  .slice()
                  .sort((a, b) => {
                    if (a!.id === loginUser!.id) {
                      // ログインしているユーザー自身を先頭に表示する
                      return 1;
                    }
                    return (a!.name as any) - (b!.name as any);
                  })
                  .map((member) => {
                    return {
                      label: member!.name!,
                      subLabel: member!.mailAddress!,
                      value: member!.id!,
                      imageUrl: member!.profileImageUrl,
                    };
                  })}
                onChange={(items) => {
                  //@ts-ignore
                  setProjectFilter({
                    ...(projectFilter as ProjectFilter),
                    assignerIds: items.map((item) => item.value),
                  });
                }}
                onBlur={(items) => {
                  //@ts-ignore
                  setProjectFilter({
                    ...(projectFilter as ProjectFilter),
                    assignerIds: items.map((item) => item.value),
                  });
                }}
              />
            )}
          </FilterElements>
        </FilterElementsContainer>
      )}
      <FilterElementsContainer>
        <View style={{ marginTop: 10, paddingLeft: 10 }}>
          <Text
            style={{
              fontSize: 10,
              color: themeContext.colors.description,
              textAlign: 'center',
              marginBottom: 2,
            }}>
            取引先
          </Text>
          <FilterElements>
            <MultiPickerFilter
              placeHolder="取引先を絞り込む"
              value={selectedClientIds}
              pickerItems={(clientData?.organizationClients?.slice() ?? [])
                .sort((a, b) => (a!.name as any) - (b!.name as any))
                .map((client) => {
                  return {
                    label: client!.name!,
                    value: client!.id!,
                  };
                })}
              onChange={(items) => {}}
              onBlur={(items) => {
                setSelectedClientIds(items.map((item) => item.value));
                //@ts-ignore
                setProjectFilter({
                  ...(projectFilter as ProjectFilter),
                  clientIds: items.map((item) => item.value),
                });
              }}
            />
          </FilterElements>
        </View>
      </FilterElementsContainer>
      <FilterElementsContainer>
        <Text
          style={{
            fontSize: 10,
            color: themeContext.colors.description,
            textAlign: 'center',
            marginBottom: 2,
          }}>
          お気に入り
        </Text>
        <FilterElements>
          <SelectButton
            contents={[
              {
                key: 'favoriteOnly',
                content: 'お気に入りのみ',
              },
            ]}
            values={selectedStatusIds}
            onChange={(value) => {
              //@ts-ignore
              setProjectFilter({
                ...(projectFilter as ProjectFilter),
                favoriteOnly: value.includes('favoriteOnly'),
              });
            }}
          />
        </FilterElements>
      </FilterElementsContainer>
      {location.pathname.indexOf('/board/') === -1 ? (
        <FilterElementsContainer>
          <Text
            style={{
              fontSize: 10,
              color: themeContext.colors.description,
              textAlign: 'center',
              marginBottom: 2,
            }}>
            ステータス
          </Text>
          <FilterElements>
            <SelectButton
              textStyle={{ maxWidth: 70 }}
              contents={props.projectStatuses!.map((status) => {
                return {
                  key: status!.id!.toString(),
                  content: status!.name,
                };
              })}
              values={selectedStatusIds}
              multiSelect={true}
              onChange={(value) => {
                setSelectedStatusIds(value);
                //@ts-ignore
                setProjectFilter({
                  ...(projectFilter as ProjectFilter),
                  projectStatusIds: value,
                });
              }}
            />
          </FilterElements>
        </FilterElementsContainer>
      ) : (
        <Form style={{ width: 'auto', minWidth: 450 }}>
          <Input
            name={'searchProject'}
            label={''}
            value={searchProjectName}
            placeholder={'プロジェクト名で絞り込む(Enterキーで検索)'}
            icon={<SearchIcon size={20} containerStyle={{ marginLeft: 10 }} />}
            containerStyle={{ marginVertical: 10 }}
            inputstyle={{ borderWidth: 0, fontSize: 14, minWidth: 200 }}
            inputContainerStyle={{
              marginHorizontal: 10,
              backgroundColor: themeContext.colors.baseColor,
              shadowOffset: {
                width: -1,
                height: -1,
              },
              shadowOpacity: 0.1,
              elevation: 2,
              borderWidth: 1,
              borderRadius: 20,
              borderColor: themeContext.colors.separator,
            }}
            onChange={(value: string) => {
              setSearchProjectNameLive(value);
              if (value?.trim() === '') {
                setSearchProjectName('');
                //@ts-ignore
                setProjectFilter({
                  ...(projectFilter as ProjectFilter),
                  projectName: null,
                });
              }
            }}
            onPressEnterIncludeIMEConvert={(value: string) => {
              setSearchProjectName(value);
              //@ts-ignore
              setProjectFilter({
                ...(projectFilter as ProjectFilter),
                projectName: value,
              });
            }}
            onBlur={(value: string) => {
              setSearchProjectName(value);
              //@ts-ignore
              setProjectFilter({
                ...(projectFilter as ProjectFilter),
                projectName: value,
              });
            }}
          />
        </Form>
      )}
    </FilterArea>
  );
};

interface ICompleteProjectModalProps {
  project: Project;
  showModal: boolean;
  onPressYes: () => Promise<void>;
  onCloseModal: () => void;
}
const CompleteProjectConfirmModal = (props: ICompleteProjectModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  return (
    <Modal
      title={'このプロジェクトを完了しますか？'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <View style={{ flexDirection: 'column' }}>
        <View style={{ marginTop: 10 }}>
          <Typography variant={TypographyType.Normal} style={{ textAlign: 'center' }}>
            {props.project.name}
          </Typography>
        </View>
        <View style={{ marginTop: 10 }}>
          <Typography variant={TypographyType.Description} style={{ textAlign: 'center' }}>
            {`作業中のタスクがある場合、自動的に作業停止します。`}
          </Typography>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
          <Button
            text={'完了する'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
            }}
            onPress={async () => {
              await props.onPressYes();
            }}
          />
          <Button
            text={'キャンセル'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.primary }}
            disableValidate={true}
            onPress={() => {
              props.onCloseModal();
            }}
          />
        </View>
      </View>
    </Modal>
  );
};

interface IIncompleteProjectModalProps {
  project: Project;
  showModal: boolean;
  onPressYes: () => Promise<void>;
  onCloseModal: () => void;
}
const IncompleteProjectConfirmModal = (props: IIncompleteProjectModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  return (
    <Modal
      title={'このプロジェクトを未完了に戻しますか？'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <View style={{ flexDirection: 'column' }}>
        <View style={{ marginTop: 10 }}>
          <Typography variant={TypographyType.Normal} style={{ textAlign: 'center' }}>
            {props.project.name}
          </Typography>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
          <Button
            text={'未完了に戻す'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
            }}
            onPress={async () => {
              await props.onPressYes();
            }}
          />
          <Button
            text={'キャンセル'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.primary }}
            disableValidate={true}
            onPress={() => {
              props.onCloseModal();
            }}
          />
        </View>
      </View>
    </Modal>
  );
};

interface IProjectContainerInnerProps {
  organization: Organization;
  teamId: string;
  projectId: string;
}

const ProjectContainerInner = React.memo((props: IProjectContainerInnerProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [showModal, setShowModal] = useState(false);
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [showIncompleteModal, setShowIncompleteModal] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const menuRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const [showErrorModal, setShowErrorModal] = useState(false);

  useEffect(() => {
    setDeleted(false);
  }, [props.projectId]);

  const { loading: mySettingsLoading, data: mySettingsData } = useMySettingsQuery();
  const { loading: meLoading, data: meData } = useMeQuery();

  const { loading: teamLoading, data: teamData } = useTeamQuery({
    variables: {
      id: props.teamId,
    },
  });

  const { loading, data, error } = useProjectQuery({
    variables: {
      id: props.projectId,
    },
    fetchPolicy: 'network-only',
    skip: deleted || props.projectId === 'task',
  });

  const { loading: taskStatusLoading, data: taskStatusData } = useProjectTaskStatusQuery({
    variables: {
      projectId: props.projectId,
    },
    fetchPolicy: 'network-only',
    skip: props.projectId === 'task',
  });

  const { loading: totalWorkingSecLoading, data: totalWorkingSecData } =
    useProjectWorkingTimeSecQuery({
      variables: {
        id: props.projectId,
      },
      fetchPolicy: 'network-only',
      skip: props.projectId === 'task',
    });

  const { loading: totalTaskEstimateWorkingSecLoading, data: totalTaskEstimateWorkingSecData } =
    useProjectTasksEstimateTimeSecQuery({
      variables: {
        id: props.projectId,
      },
      fetchPolicy: 'network-only',
      skip: props.projectId === 'task',
    });

  const [completeProject] = useCompleteProjectMutation({
    variables: {
      id: props.projectId,
      input: {
        versionNo: data?.project?.versionNo || 0,
      },
    },
  });
  const [incompleteProject] = useIncompleteProjectMutation({
    variables: {
      id: props.projectId,
      input: {
        versionNo: data?.project?.versionNo || 0,
      },
    },
  });
  const [favoriteProject] = useFavoriteProjectMutation({
    variables: {
      id: props.projectId,
      input: {
        versionNo: data?.project?.versionNo || 0,
      },
    },
  });
  const [cancelFavoriteProject] = useCancelFavoriteProjectMutation({
    variables: {
      id: props.projectId,
      input: {
        versionNo: data?.project?.versionNo || 0,
      },
    },
  });

  const clickDocument = (e: any) => {
    if ((menuRef?.current as any)?.contains(e.target)) {
      (menuRef?.current as any)?.click();
      return;
    }
    setOpenMenu(false);
  };
  useEffect(() => {
    window.addEventListener('click', clickDocument);
    return () => {
      window.removeEventListener('click', clickDocument);
    };
  }, [clickDocument]);

  if (
    loading ||
    !data?.project ||
    teamLoading ||
    taskStatusLoading ||
    mySettingsLoading ||
    meLoading ||
    !meData?.me
  ) {
    return <></>;
  }

  return (
    <>
      <TaskFilterContextProvider>
        <MainAreaHeader>
          <MainAreaHeaderTop>
            <View
              style={{
                flexDirection: 'row',
              }}>
              <View style={{ flexDirection: 'column' }}>
                {data!.project!.client && (
                  <Typography
                    variant={TypographyType.Description}
                    style={{ marginLeft: 3, marginRight: 10, maxWidth: 600, fontSize: 11 }}
                    ellipsis={true}>
                    {data!.project!.client!.name}
                  </Typography>
                )}
                {data!.project!.key && (
                  <Typography
                    variant={TypographyType.Description}
                    style={{ marginLeft: 3, marginRight: 10, maxWidth: 600, fontSize: 15 }}
                    ellipsis={true}>
                    {data!.project!.key}
                  </Typography>
                )}
                <Typography
                  variant={TypographyType.SubTitle}
                  style={{ marginLeft: 3, marginRight: 10, maxWidth: 600 }}
                  ellipsis={true}>
                  {data!.project!.name}
                </Typography>
              </View>
              <EditIcon
                size={22}
                containerStyle={{ marginRight: 8, marginLeft: 5 }}
                onPress={() => {
                  history.push(
                    location.pathname +
                      `?projectDetailId=${props.projectId}&projectDetailMode=standard`
                  );
                }}
              />
              {data.project.favoriteMembers.filter((member) => member!.id === loginUser!.id)
                .length > 0 ? (
                <StarIcon
                  size={22}
                  on={true}
                  containerStyle={{ marginLeft: 3, marginRight: 10 }}
                  onPress={() => {
                    cancelFavoriteProject();
                  }}
                />
              ) : (
                <StarIcon
                  size={22}
                  on={false}
                  containerStyle={{ marginLeft: 3, marginRight: 10 }}
                  onPress={() => {
                    favoriteProject();
                  }}
                />
              )}
              {data.project?.completeDateTime ? (
                <>
                  <CheckIcon
                    size={22}
                    on={true}
                    containerStyle={{ marginRight: 10 }}
                    onPress={() => {
                      if (!meData.me?.projectUpdatePermissionFlg) {
                        setShowErrorModal(true);
                        return;
                      }
                      setShowIncompleteModal(true);
                    }}
                  />
                  <IncompleteProjectConfirmModal
                    project={data!.project!}
                    showModal={showIncompleteModal}
                    onPressYes={async () => {
                      setShowIncompleteModal(false);
                      await incompleteProject();
                    }}
                    onCloseModal={() => setShowIncompleteModal(false)}
                  />
                </>
              ) : (
                <>
                  <CheckIcon
                    size={22}
                    on={false}
                    containerStyle={{ marginRight: 10 }}
                    onPress={() => {
                      if (!meData.me?.projectUpdatePermissionFlg) {
                        setShowErrorModal(true);
                        return;
                      }
                      setShowCompleteModal(true);
                    }}
                  />
                  <CompleteProjectConfirmModal
                    project={data!.project!}
                    showModal={showCompleteModal}
                    onPressYes={async () => {
                      setShowCompleteModal(false);
                      await completeProject();
                    }}
                    onCloseModal={() => setShowCompleteModal(false)}
                  />
                </>
              )}
            </View>
            <View>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <View>
                  {(props.organization.plan.code === 'Business' ||
                    props.organization.plan.code === 'Enterprise') && (
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        //@ts-ignore
                        gap: 5,
                        marginRight: 10,
                      }}>
                      <Typography
                        variant={TypographyType.Description}
                        style={{ textAlign: 'center' }}>
                        担当者:
                      </Typography>
                      {data.project?.assignees.length === 0 ? (
                        <Typography
                          variant={TypographyType.Description}
                          style={{ textAlign: 'center' }}>
                          未設定
                        </Typography>
                      ) : data.project?.assignees.length > 10 ? (
                        <Avatar
                          size={24}
                          textStyle={{ fontSize: 10 }}
                          name={`${data.project.assignees.length}名`}
                        />
                      ) : (
                        data.project.assignees.map((info, i) => {
                          return (
                            <Avatar
                              size={24}
                              name={info.member.name!}
                              imageUrl={info.member.profileImageUrl}
                              showToolTip
                              key={i}
                            />
                          );
                        })
                      )}
                    </View>
                  )}
                </View>
                <Typography
                  variant={TypographyType.Description}
                  style={{ textAlign: 'right', marginRight: 10 }}>
                  {`プロジェクトの見積時間: ${
                    data?.project.estimateTimeSec
                      ? TimeUtil.formatSecToShortTime(data?.project.estimateTimeSec ?? 0)
                      : '-'
                  }`}
                </Typography>
                <Typography
                  variant={TypographyType.Description}
                  style={{ textAlign: 'right', marginRight: 10 }}>
                  {`タスクの合計見積時間: ${
                    totalTaskEstimateWorkingSecData
                      ? TimeUtil.formatSecToShortTime(
                          totalTaskEstimateWorkingSecData?.projectTasksEstimateTimeSec ?? 0
                        )
                      : '-'
                  }`}
                </Typography>
                <Typography
                  variant={TypographyType.Description}
                  style={{ textAlign: 'right', marginRight: 10 }}>
                  {`合計作業時間: ${TimeUtil.formatForTask(
                    totalWorkingSecData?.projectWorkingTimeSec ?? 0
                  )}`}
                </Typography>
              </View>
            </View>
          </MainAreaHeaderTop>
          <MainAreaHeaderBottom>
            <HorizontalMenu
              style={{ marginLeft: 10 }}
              menus={[
                {
                  title: 'リスト',
                  path: `/app/${loginUser!.organizationId}/${props.teamId}/projects/${
                    data!.project!.id
                  }/list/`,
                },
                {
                  title: 'ボード',
                  path: `/app/${loginUser!.organizationId}/${props.teamId}/projects/${
                    data!.project!.id
                  }/board/`,
                },
                {
                  title: 'スケジュール',
                  path: `/app/${loginUser!.organizationId}/${props.teamId}/projects/${
                    data!.project!.id
                  }/schedule/`,
                },
                {
                  title: 'カレンダー予定',
                  path: `/app/${loginUser!.organizationId}/${props.teamId}/projects/${
                    data!.project!.id
                  }/calendar-events/`,
                },
              ]}
            />
            <Filter
              project={data!.project!}
              taskStatuses={taskStatusData!.projectTaskStatus as TaskStatus[]}
            />
          </MainAreaHeaderBottom>
        </MainAreaHeader>
        <Switch>
          <Route
            path={'/app/:organizationId/:teamId/projects/:projectId/list/'}
            component={TaskList}
          />
          <Route
            path={'/app/:organizationId/:teamId/projects/:projectId/board/'}
            component={ProjectBoard}
          />
          <Route
            path={'/app/:organizationId/:teamId/projects/:projectId/schedule/'}
            component={ProjectGanttChart}
          />
          <Route
            path={'/app/:organizationId/:teamId/projects/:projectId/calendar-events/'}
            component={CalendarEventList}
          />
          <Route
            path={'/app/:organizationId/:teamId/projects/:projectId/attachments/'}
            component={AttachmentList}
          />
        </Switch>
      </TaskFilterContextProvider>
      <ErrorMessageModal
        showModal={showErrorModal}
        title={'プロジェクトを編集できません'}
        message={`プロジェクトを編集する権限がありません${'\n'}権限が必要な場合、管理権限を持っているメンバーに問い合わせてください`}
        onCloseModal={() => setShowErrorModal(false)}
      />
    </>
  );
});

const ProjectContainer = React.memo(() => {
  const params = useParams();
  const [loginUser, _] = useContext(LoginUserContext);
  const { loading, data } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId,
    },
  });

  if (loading) {
    return <></>;
  }

  if (!(params as any).projectId || (params as any).projectId === 'view' || (params as any).projectId === 'contract') {
    return <></>;
  }

  return (
    <ProjectContainerInner
      organization={data!.organization!}
      teamId={(params as any).teamId}
      projectId={(params as any).projectId}
    />
  );
});

interface IProjectListContainerInnerProps {
  organization: Organization;
  teamId: string;
}

const ProjectListContainerInner = React.memo((props: IProjectListContainerInnerProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [showModal, setShowModal] = useState(false);
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [showIncompleteModal, setShowIncompleteModal] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const menuRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const [showErrorModal, setShowErrorModal] = useState(false);

  const teamResult = useTeamQuery({
    variables: {
      id: props.teamId,
    },
    skip: props.teamId === null,
  });

  const clickDocument = (e: any) => {
    if ((menuRef?.current as any)?.contains(e.target)) {
      (menuRef?.current as any)?.click();
      return;
    }
    setOpenMenu(false);
  };
  useEffect(() => {
    window.addEventListener('click', clickDocument);
    return () => {
      window.removeEventListener('click', clickDocument);
    };
  }, [clickDocument]);

  const { loading, data } = useTeamProjectStatusQuery({
    variables: {
      teamId: props.teamId,
    },
    fetchPolicy: 'network-only',
  });

  if (teamResult.loading || teamResult.error || loading || !data?.teamProjectStatus) {
    return <></>;
  }

  return (
    <>
      <ProjectFilterContextProvider>
        <MainAreaHeaderForProjectList>
          <View
            style={{
              backgroundColor: themeContext.colors.header,
              width: '100%',
              padding: 5,
            }}>
            <Typography
              variant={TypographyType.SubTitle}
              ellipsis={true}
              style={{ color: '#FFFFFF', fontSize: 16, fontWeight: '600' }}>
              {OrganizationUtil.isTeamPlan(props.organization)
                ? teamResult.data?.team?.name
                : 'プロジェクト一覧'}
            </Typography>
          </View>
          <MainAreaHeaderBottomForProject>
            <HorizontalMenu
              style={{ marginLeft: 10 }}
              menus={[
                {
                  title: 'リスト',
                  path: `/app/${loginUser!.organizationId}/${props.teamId}/projects/view/list/`,
                },
                {
                  title: 'ボード',
                  path: `/app/${loginUser!.organizationId}/${props.teamId}/projects/view/board/`,
                },
                // {
                //   title: 'スケジュール',
                //   path: `/app/${loginUser!.organizationId}/${props.teamId}/projects/view/schedule/`,
                // },
              ]}
            />
            <ProjectFilter
              team={teamResult.data!.team!}
              projectStatuses={data!.teamProjectStatus as ProjectStatus[]}
            />
          </MainAreaHeaderBottomForProject>
        </MainAreaHeaderForProjectList>
        <Switch>
          <Route
            path={'/app/:organizationId/:teamId/projects/view/list/'}
            component={ProjectListFull}
          />
          <Route
            path={'/app/:organizationId/:teamId/projects/view/board/'}
            component={ProjectViewBoard}
          />
        </Switch>
      </ProjectFilterContextProvider>
      {/* <ErrorMessageModal
        showModal={showErrorModal}
        title={'プロジェクトを編集できません'}
        message={`プロジェクトを編集する権限がありません${'\n'}権限が必要な場合、管理権限を持っているメンバーに問い合わせてください`}
        onCloseModal={() => setShowErrorModal(false)}
      /> */}
    </>
  );
});

const ProjectListContainer = React.memo(() => {
  const params = useParams();
  const [loginUser, _] = useContext(LoginUserContext);
  const { loading, data } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId,
    },
  });

  if (loading) {
    return <></>;
  }

  return (
    <ProjectListContainerInner organization={data!.organization!} teamId={(params as any).teamId} />
  );
});


interface IProjectContractListContainerInnerProps {
  organization: Organization;
  teamId: string;
}

const ProjectContractListContainerInner = React.memo((props: IProjectContractListContainerInnerProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [showModal, setShowModal] = useState(false);
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [showIncompleteModal, setShowIncompleteModal] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const menuRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const [showErrorModal, setShowErrorModal] = useState(false);

  const teamResult = useTeamQuery({
    variables: {
      id: props.teamId,
    },
    skip: props.teamId === null,
  });

  const clickDocument = (e: any) => {
    if ((menuRef?.current as any)?.contains(e.target)) {
      (menuRef?.current as any)?.click();
      return;
    }
    setOpenMenu(false);
  };
  useEffect(() => {
    window.addEventListener('click', clickDocument);
    return () => {
      window.removeEventListener('click', clickDocument);
    };
  }, [clickDocument]);

  const { loading, data } = useTeamProjectStatusQuery({
    variables: {
      teamId: props.teamId,
    },
    fetchPolicy: 'network-only',
  });

  if (teamResult.loading || teamResult.error || loading || !data?.teamProjectStatus) {
    return <></>;
  }

  return (
    <>
      <ProjectFilterContextProvider>
        <MainAreaHeaderForProjectList>
          <View
            style={{
              backgroundColor: themeContext.colors.header,
              width: '100%',
              padding: 5,
            }}>
            <Typography
              variant={TypographyType.SubTitle}
              ellipsis={true}
              style={{ color: '#FFFFFF', fontSize: 16, fontWeight: '600' }}>
              {OrganizationUtil.isTeamPlan(props.organization)
                ? teamResult.data?.team?.name
                : 'プロジェクト一覧'}
            </Typography>
          </View>
          <MainAreaHeaderBottomForProject>
            <View/>
            <ProjectFilter
              team={teamResult.data!.team!}
              projectStatuses={data!.teamProjectStatus as ProjectStatus[]}
            />
          </MainAreaHeaderBottomForProject>
        </MainAreaHeaderForProjectList>
        <Switch>
          <Route
            path={'/app/:organizationId/:teamId/projects/contracts/list/'}
            component={ProjectContractListFull}
          />
        </Switch>
      </ProjectFilterContextProvider>
    </>
  );
});



const ProjectContractListContainer = React.memo(() => {
  const params = useParams();
  const [loginUser, _] = useContext(LoginUserContext);
  const { loading, data } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId,
    },
  });

  if (loading) {
    return <></>;
  }

  return (
    <ProjectContractListContainerInner organization={data!.organization!} teamId={(params as any).teamId} />
  );
});

interface IConfirmJoinTeamModalProps {
  team: Team;
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  onComplete: () => void;
  onCancel: () => void;
}

const ConfirmJoinTeamModal = (props: IConfirmJoinTeamModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, _] = useContext(LoginUserContext);
  const [joinMemberToTeam] = useJoinToTeamMutation();

  return (
    <Modal
      isShow={props.showModal}
      onClose={() => props.setShowModal(false)}
      title={`${props.team.name}チームに参加しますか？`}>
      <Form>
        <Typography
          variant={TypographyType.Description}
          style={{ textAlign: 'center', marginTop: 10 }}>
          チームに参加すると、このチームのタスクを作業することが出来ます。
        </Typography>
        <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 20 }}>
          <Button
            text="チームに参加する"
            completeText="チームに参加しました"
            style={{ height: 30, marginRight: 5 }}
            textStyle={{ fontSize: 14 }}
            onPress={async () => {
              await joinMemberToTeam({
                variables: {
                  input: {
                    teamId: props.team.id!,
                    memberId: loginUser!.id,
                  },
                },
                refetchQueries: [
                  {
                    query: JoinedTeamsWithoutPersonalTeamDocument,
                    variables: {
                      organizationId: loginUser!.organizationId,
                      withArchivedTeam: true,
                    },
                  },
                  {
                    query: JoinedTeamsWithoutPersonalTeamDocument,
                    variables: {
                      organizationId: loginUser!.organizationId,
                      withArchivedTeam: false,
                    },
                  },
                  {
                    query: TeamMemberRelationsDocument,
                  },
                ],
              });
              props.onComplete();
            }}
          />
          <Button
            text="キャンセル"
            style={{ height: 30, backgroundColor: 'transparent' }}
            textStyle={{ fontSize: 14, color: themeContext.colors.primary }}
            onPress={async () => {
              props.onCancel();
            }}
            disableValidate={true}
          />
        </View>
      </Form>
    </Modal>
  );
};

const AppTeamProjectPage = () => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, _] = useContext(LoginUserContext);
  const teamId = LoginUtil.getTeamIdFromURl();
  const { window } = useDimensions();
  const history = useHistory();
  const location = useLocation();
  const [showConfirmJoinModal, setShowConfirmJoinModal] = useState(false);
  const [viewMode, setViewMode] = useState<'task' | 'project' | 'contract'>('task');
  const teamResult = useTeamQuery({
    variables: {
      id: teamId!,
    },
    skip: teamId === null,
    fetchPolicy: 'network-only',
  });
  const meResult = useMeQuery({
    fetchPolicy: "network-only"
  })

  const joinedTeamResult = useJoinedTeamsWithoutPersonalTeamQuery({
    variables: {
      organizationId: loginUser!.organizationId,
      withArchivedTeam: true,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (location.pathname.indexOf('projects/view') !== -1) {
      setViewMode('project');
      return;
    }
    if (location.pathname.indexOf('projects/contracts') !== -1) {
      setViewMode('contract');
      return;
    }
    setViewMode('task');
  }, [location.pathname]);

  return (
    <Container>
      <MenuContainer>
        <MenuItem
          isActive={viewMode === 'task'}
          onPress={() => {
            setViewMode('task');
            history.push(`/app/${loginUser!.organizationId}/${teamId}/projects/`);
          }}>
          <Typography
            variant={TypographyType.Normal}
            style={{ fontSize: 12, color: themeContext.colors.baseColor }}>
            タスク一覧
          </Typography>
        </MenuItem>
        <MenuItem
          isActive={viewMode === 'project'}
          onPress={() => {
            setViewMode('project');
            history.push(`/app/${loginUser!.organizationId}/${teamId}/projects/view/list/`);
          }}>
          <Typography
            variant={TypographyType.Normal}
            style={{ fontSize: 12, color: themeContext.colors.baseColor }}>
            プロジェクト一覧
          </Typography>
        </MenuItem>
        {meResult.data?.me?.laborCostManagementRole && (
          <MenuItem
            isActive={viewMode === 'contract'}
            onPress={() => {
              setViewMode('contract');
              history.push(`/app/${loginUser!.organizationId}/${teamId}/projects/contracts/list/`);
            }}>
            <Typography
              variant={TypographyType.Normal}
              style={{ fontSize: 12, color: themeContext.colors.baseColor }}>
              プロジェクト契約一覧
            </Typography>
          </MenuItem>
        )}
      </MenuContainer>
      <View style={{ flexDirection: 'row' }}>
        {viewMode === 'task' && <ProjectList />}
        <MainAreaContainer>
          <Switch>
            <Route path="/app/:organizationId/:teamId/projects/view/">
              <ProjectListContainer />
            </Route>
            <Route path="/app/:organizationId/:teamId/projects/contracts/">
              <ProjectContractListContainer />
            </Route>
            <Route path="/app/:organizationId/:teamId/projects/:projectId/">
              <ProjectContainer />
            </Route>
            <Route>
              <View
                style={{
                  height: 'calc(100vh - 57px)',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Image
                  source={{ uri: noData01 }}
                  resizeMode="contain"
                  style={{
                    width: Math.min(window.width / 4, 300),
                    height: 200,
                    opacity: 0.7,
                  }}
                />
                <Typography
                  variant={TypographyType.Normal}
                  style={{ color: themeContext.colors.description }}>
                  プロジェクトを選択してください
                </Typography>
              </View>
            </Route>
          </Switch>
        </MainAreaContainer>
      </View>
      <Spinner loading={joinedTeamResult.loading || teamResult.loading}>
        {!teamResult.data?.team?.personalTeam &&
          !joinedTeamResult.data?.joinedTeamsWithoutPersonalTeam?.includes(
            teamResult.data?.team as any
          ) && (
            <ParticipationToTeam>
              <Typography variant={TypographyType.Normal} style={{ lineHeight: 40 }}>
                <Typography variant={TypographyType.Normal} style={{ fontWeight: '900' }}>
                  {teamResult.data?.team?.name}
                </Typography>
                チームのプロジェクトページです。
              </Typography>
              <Button
                text={`このチームに参加する`}
                style={{ height: 30, marginRight: 5 }}
                textStyle={{ fontSize: 14, fontWeight: '900' }}
                onPress={() => setShowConfirmJoinModal(true)}
              />
              <ConfirmJoinTeamModal
                team={teamResult.data?.team as any}
                showModal={showConfirmJoinModal}
                setShowModal={() => setShowConfirmJoinModal(false)}
                onComplete={() => setShowConfirmJoinModal(false)}
                onCancel={() => setShowConfirmJoinModal(false)}
              />
            </ParticipationToTeam>
          )}
      </Spinner>
    </Container>
  );
};

export default React.memo(AppTeamProjectPage);
