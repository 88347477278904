import React, { useContext, useEffect, useState } from 'react';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import { useScreenDimensions } from 'use-dimensions';
import { useHistory, useLocation } from 'react-router';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import Button from '../../../../presentational/atoms/button';
import Separator from '../../../../presentational/atoms/separator';
import Form from '../../../../presentational/atoms/form';
import Input from '../../../../presentational/atoms/input';
import Link from '../../../../presentational/atoms/link';
import { IStyleTheme, IThemePart } from '../../../../theme';
import {
  useLoginV2Mutation,
  useLoginWithAppleForWebV2Mutation,
  useLoginWithGoogleV2Mutation,
} from '../../../../../graphql/api/API';
import LoginUtil from '../../../../../modules/auth/LoginUtil';
import { LoginUser, LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import logoLargeBlack from '../../../../../base64Images/logo/logo-large-black';
import loginImage from '../../../../../base64Images/login/login';
import GoogleLogin, { GoogleLoginResponse } from 'react-google-login';
import Constants from 'expo-constants';
import TwoFactorLoginModal from '../../../share/organisms/two-factor-login';
import DownIcon from '../../../../presentational/molecules/image-icon/caret-down';
import UpIcon from '../../../../presentational/molecules/image-icon/caret-up';
//@ts-ignore
import AppleSignin from 'react-apple-signin-auth';
import Checkbox from '../../../../presentational/atoms/checkbox';

const Header = styled.View`
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  height: 100px;
  padding-left: 20px;
  padding-top: 20px;
`;

const Container = styled.View`
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  height: calc(100vh - 100px);
`;

const LeftContainer = styled.View`
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 0 50px;
`;

const RightContainer = styled.View`
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 0 50px;
`;

const RightContainerInner = styled.View`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LinkContainer = styled.View`
  margin-top: 1.5rem;
`;

const ErrorMessage = styled.Text<IStyleTheme>`
  font-size: 0.9rem;
  color: ${(props: any) => props.theme.colors.error};
  text-align: left;
  padding-bottom: 3rem;
`;

const SigninOtherPage = () => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [stayLoggedIn, setStayLoggedIn] = useState(false);
  const [isOAuthLogin, setOAuthLogin] = useState(false);
  const [mailAddress, setMailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const { height, width } = useScreenDimensions();
  const [showTwoFactorLoginModal, setShowTwoFactorLoginModal] = useState(false);
  const [oneTimeToken, setOneTimeToken] = useState('');
  const [showAccountSelectableGoogleAccountLogin, setShowAccountSelectableGoogleAccountLogin] =
    useState(false);
  const history = useHistory();
  const [requestLogin, loginResult] = useLoginV2Mutation({
    onError: (e) => {
      if (e.graphQLErrors.find((ge) => ge.extensions?.code === 'authentication-failed')) {
        setErrorMessage('メールアドレスかパスワードが間違っています。');
      }
    },
  });
  const [requestLoginWithGoogle, LoginResultWithGoogle] = useLoginWithGoogleV2Mutation({
    onError: (e) => {
      if (e.graphQLErrors.find((ge) => ge.extensions?.code === 'authentication-failed')) {
        setErrorMessage('このGoogleアカウントは会員登録されていません。');
      }
    },
  });
  const [requestLoginWithApple, LoginResultWithApple] = useLoginWithAppleForWebV2Mutation({
    onError: (e) => {
      if (e.graphQLErrors.find((ge) => ge.extensions?.code === 'authentication-failed')) {
        setErrorMessage('このApple IDは会員登録されていません。');
      }
    },
  });

  useEffect(() => {
    if (!!loginResult.data?.loginV2) {
      if (loginResult.data?.loginV2?.requiredTwoFactorAuth) {
        setOneTimeToken(loginResult.data!.loginV2!.oneTimeToken!);
        setShowTwoFactorLoginModal(true);
      } else {
        (async () => {
          await LoginUtil.saveToken(
            loginResult.data!.loginV2!.loginUserToken!,
            isOAuthLogin ? true : stayLoggedIn
          );
          const user = await LoginUtil.getLoginUser();
          setLoginUser(user);
          history.push(`/app/`);
        })();
      }
    }
  }, [loginResult.data?.loginV2, isOAuthLogin, stayLoggedIn]);

  return (
    <>
      <TwoFactorLoginModal
        onClose={() => setShowTwoFactorLoginModal(false)}
        show={showTwoFactorLoginModal}
        oneTimeToken={oneTimeToken}
        onSuccess={async (token) => {
          await LoginUtil.saveToken(token, isOAuthLogin ? true : stayLoggedIn);
          const user = await LoginUtil.getLoginUser();
          setLoginUser(user);
          history.push(`/app/`);
        }}
      />
      <Header>
        <Image
          source={{ uri: logoLargeBlack }}
          resizeMode="contain"
          style={{
            width: 300,
            height: 100,
          }}
        />
      </Header>
      <Container>
        <LeftContainer>
          <Image
            source={{ uri: loginImage }}
            resizeMode="contain"
            style={{
              width: Math.max(width / 4, 500),
              height: Math.max(width / 4, 500),
            }}
          />
        </LeftContainer>
        <RightContainer>
          <RightContainerInner>
            <Typography variant={TypographyType.Title} style={{ marginVertical: 30 }}>
              ログイン
            </Typography>
            <View style={{ flexDirection: 'row' }}>
              <GoogleLogin
                clientId={Constants.manifest!.extra!.googleClientId}
                buttonText="Googleアカウントでログインする"
                scope={
                  'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile'
                }
                responseType={'id_token'}
                prompt={'consent'}
                onSuccess={async (response) => {
                  const result = await requestLoginWithGoogle({
                    variables: {
                      code: (response as GoogleLoginResponse).tokenId!,
                    },
                  });
                  if (!!result?.data?.loginWithGoogleV2) {
                    setOAuthLogin(true);
                    if (result?.data?.loginWithGoogleV2?.requiredTwoFactorAuth) {
                      setOneTimeToken(result!.data!.loginWithGoogleV2!.oneTimeToken!);
                      setShowTwoFactorLoginModal(true);
                    } else {
                      await LoginUtil.saveToken(
                        result.data!.loginWithGoogleV2!.loginUserToken!,
                        true
                      );
                      const user = await LoginUtil.getLoginUser();
                      setLoginUser(user);
                      history.push(`/app/`);
                    }
                  }
                }}
                onFailure={(error) => {}}
                cookiePolicy={'single_host_origin'}
              />
              {showAccountSelectableGoogleAccountLogin ? (
                <UpIcon
                  size={14}
                  containerStyle={{ marginLeft: 2 }}
                  onPress={() => setShowAccountSelectableGoogleAccountLogin(false)}
                />
              ) : (
                <DownIcon
                  size={14}
                  containerStyle={{ marginLeft: 2 }}
                  onPress={() => setShowAccountSelectableGoogleAccountLogin(true)}
                />
              )}
            </View>

            {showAccountSelectableGoogleAccountLogin && (
              <View style={{ flexDirection: 'row', marginTop: 20, marginRight: 12 }}>
                <GoogleLogin
                  clientId={Constants.manifest!.extra!.googleClientId}
                  buttonText="Googleアカウント選択してログインする"
                  scope={
                    'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile'
                  }
                  responseType={'id_token'}
                  prompt={'consent'}
                  onSuccess={async (response) => {
                    const result = await requestLoginWithGoogle({
                      variables: {
                        code: (response as GoogleLoginResponse).tokenId!,
                      },
                    });
                    if (!!result?.data?.loginWithGoogleV2) {
                      setOAuthLogin(true);
                      if (result?.data?.loginWithGoogleV2?.requiredTwoFactorAuth) {
                        setOneTimeToken(result!.data!.loginWithGoogleV2!.oneTimeToken!);
                        setShowTwoFactorLoginModal(true);
                      } else {
                        await LoginUtil.saveToken(
                          result.data!.loginWithGoogleV2!.loginUserToken!,
                          true
                        );
                        const user = await LoginUtil.getLoginUser();
                        setLoginUser(user);
                        history.push(`/app/`);
                      }
                    }
                  }}
                  onFailure={(error) => {}}
                  cookiePolicy={'single_host_origin'}
                />
              </View>
            )}

            <View style={{ width: 280, marginVertical: 20, marginRight: 12, paddingRight: 2 }}>
              <AppleSignin
                uiType="light"
                style={{
                  borderColor: themeContext.colors.separator,
                  shadowOffset: {
                    width: 2,
                    height: 2,
                  },
                  shadowOpacity: 0.1,
                  borderWidth: 2,
                  color: themeContext.colors.description,
                }}
                buttonExtraChildren={'Apple IDでログイン'}
                authOptions={{
                  clientId: Constants.manifest!.extra!.appleAuthClientId,
                  redirectURI: Constants.manifest!.extra!.appleAuthRedirectUrl,
                  scope: 'email name',
                  usePopup: true,
                }}
                onSuccess={async (response: any) => {
                  const result = await requestLoginWithApple({
                    variables: {
                      input: {
                        code: response.authorization.code,
                        token: response.authorization.id_token,
                      },
                    },
                  });

                  if (!!result?.data?.loginWithAppleForWebV2) {
                    setOAuthLogin(true);
                    if (result.data?.loginWithAppleForWebV2?.requiredTwoFactorAuth) {
                      setOneTimeToken(result.data.loginWithAppleForWebV2.oneTimeToken!);
                      setShowTwoFactorLoginModal(true);
                    } else {
                      await LoginUtil.saveToken(
                        result.data!.loginWithAppleForWebV2!.loginUserToken!,
                        true
                      );
                      const user = await LoginUtil.getLoginUser();
                      setLoginUser(user);
                      history.push(`/app/`);
                    }
                  }
                }}
                onError={(error: any) => {}}
              />
            </View>

            <Separator text={'または'} outerStyle={{ marginVertical: 30 }} />
            {!!errorMessage && (
              <ErrorMessage>
                <Text>{errorMessage}</Text>
              </ErrorMessage>
            )}
            <Form style={{ marginBottom: 30 }}>
              <Input
                name={'mailAddress'}
                label={'メールアドレス'}
                validate={{
                  required: {
                    value: true,
                    message: 'メールアドレスを入力してください',
                  },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: '正しいメールアドレスを入力してください',
                  },
                }}
                onChange={(value: string) => setMailAddress(value)}
              />
              <Input
                name={'password'}
                type={'password'}
                label={'パスワード'}
                validate={{
                  required: {
                    value: true,
                    message: 'パスワードを入力してください',
                  },
                }}
                onChange={(value: string) => setPassword(value)}
              />
              <TouchableOpacity
                style={{
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  marginLeft: 10,
                  marginTop: 10,
                }}
                onPress={() => {
                  setStayLoggedIn(!stayLoggedIn);
                }}>
                <Checkbox
                  size={20}
                  value={stayLoggedIn}
                  onValueChange={(value) => setStayLoggedIn(!stayLoggedIn)}
                />
                <Typography
                  variant={TypographyType.Normal}
                  style={{ marginLeft: 10, fontSize: 16 }}>
                  ログイン状態を保持する
                </Typography>
              </TouchableOpacity>
              <Button
                text="ログインする"
                style={{ marginTop: 20, minWidth: 300 }}
                onPress={async () => {
                  setOAuthLogin(false);
                  await requestLogin({
                    variables: {
                      input: {
                        mailAddress: mailAddress,
                        password: password,
                      },
                    },
                  });
                }}
              />
            </Form>
            {/* <LinkContainer>
              <Link path={'/signup/new-organization'}>
                <Typography
                  variant={TypographyType.MenuItemActive}
                  style={{ color: themeContext.colors.link }}>
                  新規登録する
                </Typography>
              </Link>
            </LinkContainer> */}
            <LinkContainer>
              <Link path={'/forgot-password'}>
                <Typography
                  variant={TypographyType.MenuItemActive}
                  style={{ color: themeContext.colors.link }}>
                  パスワードをお忘れの場合
                </Typography>
              </Link>
            </LinkContainer>
          </RightContainerInner>
        </RightContainer>
      </Container>
    </>
  );
};

export default React.memo(SigninOtherPage);
