import React, { useContext, useEffect, useState } from 'react';
import { Text, TouchableOpacity } from 'react-native';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import {
  MyGoogleAccountDocument,
  useConnectAppleAccountMutation,
  useDisconnectAppleAccountMutation,
  useAppleAccountQuery,
  AppleAccountDocument,
} from '../../../../../../../graphql/api/API';
import Button from '../../../../../../presentational/atoms/button';
import Form from '../../../../../../presentational/atoms/form';
import Typography, { TypographyType } from '../../../../../../presentational/atoms/typography';
import Constants from 'expo-constants';
import { View } from 'react-native';
import { IThemePart } from '../../../../../../theme';
import Modal from '../../../../../../presentational/molecules/modal';
//@ts-ignore
import AppleSignin from 'react-apple-signin-auth';

const Container = styled.View`
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
`;

interface IDisconnectModalProps {
  showModal: boolean;
  closeModal: () => void;
}

const DisconnectModal = (props: IDisconnectModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [disconnectAppleAccount, __] = useDisconnectAppleAccountMutation({
    refetchQueries: [
      {
        query: AppleAccountDocument,
      },
    ],
  });
  return (
    <Modal
      title={'Apple IDを連携解除する'}
      isShow={props.showModal}
      onClose={() => {
        props.closeModal();
      }}>
      <View style={{ marginTop: 10 }}>
        <Typography
          variant={TypographyType.Description}
          style={{ textAlign: 'center', fontSize: 14, lineHeight: 18 }}>
          {`Apple IDを連携解除しますか？${'\n'}あとで連携し直すことも出来ます。`}
        </Typography>
      </View>
      <Form style={{ marginTop: 20 }}>
        <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1 }}>
          <Button
            text={'連携解除する'}
            completeText={'連携解除しました'}
            style={{
              minWidth: 100,
              marginRight: 10,
            }}
            onPress={async () => {
              await disconnectAppleAccount();
              props.closeModal();
            }}
          />
          <Button
            text={'キャンセル'}
            style={{
              minWidth: 100,
              marginRight: 10,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.primary }}
            disableValidate={true}
            onPress={async () => {
              props.closeModal();
            }}
          />
        </View>
      </Form>
    </Modal>
  );
};

const AppMySettingsAppleAccountPage = () => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [showModal, setShowModal] = useState(false);
  const { loading, data } = useAppleAccountQuery();
  const [connecApplleAccount, _] = useConnectAppleAccountMutation({
    onError: (e) => {
      if (e.graphQLErrors.find((ge) => ge.extensions?.code === 'apple-account-already-used')) {
        window.alert('このApple IDは、すでに他のユーザーに紐付けされています');
      }
    },
    refetchQueries: [
      {
        query: AppleAccountDocument,
      },
    ],
  });

  if (loading) {
    return <></>;
  }

  return (
    <Container>
      {data!.appleAccount ? (
        <View>
          <Typography variant={TypographyType.Normal}>連携中のApple ID</Typography>
          <Typography variant={TypographyType.Normal}>{data!.appleAccount!.mailAddress}</Typography>
          <View style={{ maxWidth: 300 }}>
            <AppleSignin
              uiType="light"
              style={{
                borderColor: themeContext.colors.separator,
                shadowOffset: {
                  width: 2,
                  height: 2,
                },
                shadowOpacity: 0.1,
                borderWidth: 2,
                color: themeContext.colors.description,
              }}
              buttonExtraChildren={'Apple IDを再連携する'}
              authOptions={{
                clientId: Constants.manifest!.extra!.appleAuthClientId,
                redirectURI: Constants.manifest!.extra!.appleAuthRedirectUrl,
                scope: 'email name',
                usePopup: true,
              }}
              onSuccess={(response: any) => {
                connecApplleAccount({
                  variables: {
                    input: {
                      code: response.authorization.code,
                      token: response.authorization.id_token,
                    },
                  },
                });
              }}
              onError={(error: any) => {}}
            />
          </View>
          <Typography
            variant={TypographyType.Description}
            style={{ fontSize: 14, lineHeight: 22, marginTop: 20 }}>
            Apple IDの連携を解除すると、Apple IDでのログインが出来なくなります。
          </Typography>
          <TouchableOpacity
            onPress={() => {
              setShowModal(true);
            }}
            style={{ paddingTop: 20, paddingBottom: 20 }}>
            <Typography variant={TypographyType.Normal} style={{ color: themeContext.colors.link }}>
              {'Apple ID連携を解除する'}
            </Typography>
          </TouchableOpacity>
          <DisconnectModal showModal={showModal} closeModal={() => setShowModal(false)} />
        </View>
      ) : (
        <View>
          <View style={{ maxWidth: 300 }}>
            <AppleSignin
              uiType="light"
              style={{
                borderColor: themeContext.colors.separator,
                shadowOffset: {
                  width: 2,
                  height: 2,
                },
                shadowOpacity: 0.1,
                borderWidth: 2,
                color: themeContext.colors.description,
              }}
              buttonExtraChildren={'Apple IDを連携する'}
              authOptions={{
                clientId: Constants.manifest!.extra!.appleAuthClientId,
                redirectURI: Constants.manifest!.extra!.appleAuthRedirectUrl,
                scope: 'email name',
                usePopup: true,
              }}
              onSuccess={(response: any) => {
                connecApplleAccount({
                  variables: {
                    input: {
                      code: response.authorization.code,
                      token: response.authorization.id_token,
                    },
                  },
                });
              }}
              onError={(error: any) => {}}
            />
          </View>
          <Typography
            variant={TypographyType.Description}
            style={{ fontSize: 14, lineHeight: 22, marginTop: 20 }}>
            Apple IDを連携すると、Apple IDでログインすることができます。
          </Typography>
        </View>
      )}
    </Container>
  );
};

export default AppMySettingsAppleAccountPage;
