import React, { useContext, useState } from 'react';
import styled from 'styled-components/native';
import Button from '../../../../../../presentational/atoms/button';
import Form from '../../../../../../presentational/atoms/form';
import Input from '../../../../../../presentational/atoms/input';
import Typography, { TypographyType } from '../../../../../../presentational/atoms/typography';
import SettingsElement from '../../../../organisms/settings-element';
import { LoginUserContext } from '../../../../../../../modules/auth/LoginUserContext';
import LoginUtil from '../../../../../../../modules/auth/LoginUtil';
import AvatarEdit from 'react-avatar-edit';
import Avatar from '../../../../../../presentational/atoms/avatar';
import {
  useUpdateMemberProfileMutation,
  useRefleshTokenMutation,
  useMeQuery,
  Member,
  useUpdateProfileImageMutation,
  useOrganizationQuery,
  Plan,
} from '../../../../../../../graphql/api/API';
import base64Util from '../../../../../../../util/Base64Util';
import { View } from 'react-native';
import { IThemePart } from '../../../../../../theme';
import { ThemeContext } from 'styled-components/native';

const Container = styled.View`
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
`;

interface IProfileModalProps {
  me: Member;
  closeModal: () => void;
}

const ProfileModal = (props: IProfileModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [image, setImage] = useState(
    props.me.profileImageUrl ? base64Util.convertImgDataURL(props.me.profileImageUrl) : undefined
  );

  const [updateProfileImage] = useUpdateProfileImageMutation({
    variables: {
      input: {
        profileImageBase64: image,
        versionNo: props.me.versionNo,
      },
    },
  });
  const [refleshToken] = useRefleshTokenMutation();
  return (
    <>
      <AvatarEdit
        width={600}
        height={300}
        label={`クリックして画像を選択してください`}
        labelStyle={{
          color: themeContext.colors.description,
          cursor: 'pointer',
          paddingLeft: 150,
          paddingRight: 150,
          paddingTop: 150,
          paddingBottom: 150,
        }}
        onCrop={setImage}
        src={image}
        onBeforeFileLoad={(elem: React.ChangeEvent<HTMLInputElement>) => {
          if (elem.target.files && elem.target.files[0].size > 10 * 1024 * 1024) {
            alert('ファイルサイズは10MB以下にしてください');
            elem.target.value = '';
          }
        }}
      />
      <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
        <Button
          text="変更する"
          completeText="変更しました"
          style={{ marginTop: 20, height: 40, width: 200 }}
          onPress={async () => {
            await updateProfileImage();

            const newToken = await refleshToken();
            await LoginUtil.saveToken(newToken!.data!.refleshToken!);
            const user = await LoginUtil.getLoginUser();
            setLoginUser(user);
            props.closeModal();
          }}
        />
        <Button
          text="アイコンを削除する"
          style={{ marginTop: 20, height: 40, width: 200, backgroundColor: 'transparent' }}
          textStyle={{ color: themeContext.colors.error }}
          onPress={async () => {
            await updateProfileImage({
              variables: {
                input: {
                  profileImageBase64: null,
                  versionNo: props.me.versionNo,
                },
              },
            });
            const newToken = await refleshToken();
            await LoginUtil.saveToken(newToken!.data!.refleshToken!);
            const user = await LoginUtil.getLoginUser();
            setLoginUser(user);
            props.closeModal();
          }}
        />
        <Button
          text="キャンセル"
          style={{ marginTop: 20, height: 40, width: 200, backgroundColor: 'transparent' }}
          textStyle={{ color: themeContext.colors.primary }}
          onPress={async () => {
            props.closeModal();
          }}
        />
      </View>
    </>
  );
};

interface INameModalProps {
  closeModal: () => void;
  me: Member;
}

const NameModal = (props: INameModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [name, setName] = useState(props!.me!.name);

  const [updateMemberProfile] = useUpdateMemberProfileMutation({
    variables: {
      input: {
        id: props.me.id!,
        name: name!,
        mailAddress: props.me!.mailAddress,
        department: props.me.department,
        employeeNumber: props.me.employeeNumber,
        versionNo: props.me.versionNo,
      },
    },
  });
  const [refleshToken] = useRefleshTokenMutation();

  return (
    <Form style={{ marginTop: 10 }}>
      <Input
        name={'name'}
        label={'氏名'}
        initialValue={name}
        onChange={setName}
        validate={{
          required: {
            value: true,
            message: '氏名を入力してください',
          },
          maxLength: {
            value: 50,
            message: '氏名は50文字以内で入力してください',
          },
        }}
      />
      <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1 }}>
        <Button
          text={'変更する'}
          style={{
            minWidth: 100,
            marginRight: 10,
          }}
          onPress={async () => {
            await updateMemberProfile();

            const newToken = await refleshToken();
            await LoginUtil.saveToken(newToken!.data!.refleshToken!);
            const user = await LoginUtil.getLoginUser();
            setLoginUser(user);
            props.closeModal();
          }}
        />
        <Button
          text={'キャンセル'}
          style={{
            minWidth: 100,
            marginRight: 10,
            backgroundColor: 'transparent',
          }}
          textStyle={{ color: themeContext.colors.primary }}
          disableValidate={true}
          onPress={async () => {
            props.closeModal();
          }}
        />
      </View>
    </Form>
  );
};

interface IDepartmentModalProps {
  closeModal: () => void;
  me: Member;
}

const DepartmentModal = (props: IDepartmentModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [department, setDepartment] = useState(props!.me!.department);

  const [updateMemberProfile] = useUpdateMemberProfileMutation({
    variables: {
      input: {
        id: props.me.id!,
        name: props.me.name!,
        department: department,
        employeeNumber: props.me.employeeNumber,
        mailAddress: props.me!.mailAddress,
        versionNo: props.me.versionNo,
      },
    },
  });
  const [refleshToken] = useRefleshTokenMutation();

  return (
    <Form style={{ marginTop: 10 }}>
      <Input
        name={'department'}
        label={'所属部署名・チーム名'}
        initialValue={department}
        onChange={setDepartment}
        validate={{
          maxLength: {
            value: 50,
            message: '50文字以内で入力してください',
          },
        }}
      />
      <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1 }}>
        <Button
          text={'変更する'}
          style={{
            minWidth: 100,
            marginRight: 10,
          }}
          onPress={async () => {
            await updateMemberProfile();

            const newToken = await refleshToken();
            await LoginUtil.saveToken(newToken!.data!.refleshToken!);
            const user = await LoginUtil.getLoginUser();
            setLoginUser(user);
            props.closeModal();
          }}
        />
        <Button
          text={'キャンセル'}
          style={{
            minWidth: 100,
            marginRight: 10,
            backgroundColor: 'transparent',
          }}
          textStyle={{ color: themeContext.colors.primary }}
          disableValidate={true}
          onPress={async () => {
            props.closeModal();
          }}
        />
      </View>
    </Form>
  );
};

interface IEmployeeNumberModalProps {
  closeModal: () => void;
  me: Member;
}

const EmployeeModal = (props: IEmployeeNumberModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [employeeNumber, setEmployeeNumber] = useState(props!.me!.employeeNumber);

  const [updateMemberProfile] = useUpdateMemberProfileMutation({
    variables: {
      input: {
        id: props.me.id!,
        name: props.me.name!,
        department: props.me.department,
        employeeNumber: employeeNumber,
        mailAddress: props.me!.mailAddress,
        versionNo: props.me.versionNo,
      },
    },
  });
  const [refleshToken] = useRefleshTokenMutation();

  return (
    <Form style={{ marginTop: 10 }}>
      <Input
        name={'employeeNumber'}
        label={'従業員番号'}
        initialValue={employeeNumber}
        onChange={setEmployeeNumber}
        validate={{
          maxLength: {
            value: 20,
            message: '20文字以内で入力してください',
          },
        }}
      />
      <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1 }}>
        <Button
          text={'変更する'}
          style={{
            minWidth: 100,
            marginRight: 10,
          }}
          onPress={async () => {
            await updateMemberProfile();

            const newToken = await refleshToken();
            await LoginUtil.saveToken(newToken!.data!.refleshToken!);
            const user = await LoginUtil.getLoginUser();
            setLoginUser(user);
            props.closeModal();
          }}
        />
        <Button
          text={'キャンセル'}
          style={{
            minWidth: 100,
            marginRight: 10,
            backgroundColor: 'transparent',
          }}
          textStyle={{ color: themeContext.colors.primary }}
          disableValidate={true}
          onPress={async () => {
            props.closeModal();
          }}
        />
      </View>
    </Form>
  );
};

interface IMailAddressModalProps {
  closeModal: () => void;
  me: Member;
}

const MailAddressModal = (props: IMailAddressModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [mailAddress, setMailAddress] = useState(props.me!.mailAddress);

  const [updateMemberProfile] = useUpdateMemberProfileMutation({
    variables: {
      input: {
        id: props.me.id!,
        name: props.me!.name!,
        mailAddress: mailAddress,
        department: props.me.department,
        employeeNumber: props.me.employeeNumber,
        versionNo: props.me.versionNo,
      },
    },
    onError: (e) => {
      if (e.graphQLErrors.find((ge) => ge.extensions?.code === 'mail-address-already-used')) {
        window.alert('このメールアドレスは、すでに他のユーザーに紐付けされています');
      }
    },
  });
  const [refleshToken] = useRefleshTokenMutation();

  return (
    <Form style={{ marginTop: 10 }}>
      <Input
        name={'mailAddress'}
        label={'メールアドレス'}
        initialValue={mailAddress}
        onChange={setMailAddress}
        validate={{
          required: {
            value: true,
            message: 'メールアドレスを入力してください',
          },
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: '正しいメールアドレスを入力してください',
          },
        }}
      />
      <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1 }}>
        <Button
          text={'変更する'}
          style={{
            minWidth: 100,
            marginRight: 10,
          }}
          onPress={async () => {
            await updateMemberProfile();

            const newToken = await refleshToken();
            await LoginUtil.saveToken(newToken.data!.refleshToken!);
            const user = await LoginUtil.getLoginUser();
            setLoginUser(user);
            props.closeModal();
          }}
        />
        <Button
          text={'キャンセル'}
          style={{
            minWidth: 100,
            marginRight: 10,
            backgroundColor: 'transparent',
          }}
          textStyle={{ color: themeContext.colors.primary }}
          disableValidate={true}
          onPress={async () => {
            props.closeModal();
          }}
        />
      </View>
    </Form>
  );
};

const AppMySettingsProfilePage = () => {
  const [loginUser] = useContext(LoginUserContext);
  const { loading, data, error } = useMeQuery();
  const { loading: organizationLoading, data: organizatinoData } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId,
    },
  });

  if (loading || !data?.me || organizationLoading) {
    return <></>;
  }

  return (
    <Container>
      <SettingsElement
        title={'プロフィール写真'}
        changeText={'プロフィール写真を変更する'}
        modal={(closeModal) => <ProfileModal me={data!.me!} closeModal={closeModal} />}>
        <Avatar
          size={50}
          imageUrl={data.me!.profileImageUrl}
          name={data!.me!.name}
          containerStyle={{ margin: 6 }}
        />
      </SettingsElement>
      <SettingsElement
        title={'氏名'}
        changeText={'氏名を変更する'}
        modal={(closeModal) => <NameModal closeModal={closeModal} me={data!.me!} />}>
        <Typography variant={TypographyType.Normal}>{data!.me.name}</Typography>
      </SettingsElement>
      <SettingsElement
        title={'メールアドレス'}
        changeText={'メールアドレスを変更する'}
        modal={(closeModal) => <MailAddressModal closeModal={closeModal} me={data!.me!} />}>
        <Typography variant={TypographyType.Normal}>{data!.me!.mailAddress}</Typography>
      </SettingsElement>
      {organizatinoData.organization.plan.code === Plan.Business && (
        <>
          <SettingsElement
            title={'所属部署'}
            changeText={'所属部署を変更する'}
            modal={(closeModal) => <DepartmentModal closeModal={closeModal} me={data!.me!} />}>
            <Typography variant={TypographyType.Normal}>
              {data!.me.department ?? '未登録'}
            </Typography>
          </SettingsElement>
          <SettingsElement
            title={'従業員番号'}
            changeText={'従業員番号を変更する'}
            modal={(closeModal) => <EmployeeModal closeModal={closeModal} me={data!.me!} />}>
            <Typography variant={TypographyType.Normal}>
              {data!.me.employeeNumber ?? '未登録'}
            </Typography>
          </SettingsElement>
        </>
      )}
    </Container>
  );
};

export default AppMySettingsProfilePage;
