import React, { ReactNode, useContext } from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import Typography, { TypographyType } from '../../../../../presentational/atoms/typography';
import { IThemePart } from '../../../../../theme';

const ElementContainer = styled.View`
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
`;

const Element = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

const ElementTitle = styled.View`
  min-width: 100px;
`;

const ElementContent = styled.View`
  flex: 1;
  padding-right: 10px;
`;

interface IProps {
  title: string;
  style?: StyleProp<ViewStyle>;
  elementStyle?: StyleProp<ViewStyle>;
  titleContainerStyle?: StyleProp<ViewStyle>;
  children?: ReactNode;
  contentFlexDirection?: 'row' | 'column';
  contentFlexWrap?: 'wrap' | 'nowrap';
}

const TaskInfoElement = (props: IProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  return (
    <ElementContainer style={props.style as any}>
      <Element style={props.elementStyle as any}>
        <ElementTitle style={props.titleContainerStyle as any}>
          <Typography
            variant={TypographyType.Description}
            style={{ color: themeContext.colors.description }}>
            {props.title}
          </Typography>
        </ElementTitle>
        <ElementContent
          style={{
            flexDirection: props.contentFlexDirection || 'column',
            flexWrap: props.contentFlexWrap || 'nowrap',
          }}>
          {props.children}
        </ElementContent>
      </Element>
    </ElementContainer>
  );
};

export default TaskInfoElement;
