import React, { useContext, useState } from 'react';
import { StyleProp, TextStyle, ViewStyle } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../theme';
import Typography, { TypographyType } from '../typography';

const Container = styled.View`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const SeparatorView = styled.View`
  height: 1px;
  width: 100%;
  margin: 1rem;
  background-color: ${(props: IStyleTheme) => props.theme.colors.separator};
  align-items: center;
  justify-content: center;
`;

const HalfSeparatorView = styled(SeparatorView)`
  width: 50%;
`;

const TextContainer = styled.View`
  margin: 0 1rem;
`;

interface IProps {
  text?: string;
  lineStyle?: StyleProp<ViewStyle>;
  textOuterStyle?: StyleProp<ViewStyle>;
  outerStyle?: StyleProp<ViewStyle>;
}

const Separator: React.FC<IProps> = (props: IProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  if (props.text) {
    return (
      <Container style={props.outerStyle as any}>
        <HalfSeparatorView style={props.lineStyle as any} />
        <TextContainer style={props.textOuterStyle as any}>
          <Typography variant={TypographyType.Description}>{props.text}</Typography>
        </TextContainer>
        <HalfSeparatorView style={props.lineStyle as any} />
      </Container>
    );
  }
  return (
    <>
      <Container style={props.outerStyle as any}>
        <SeparatorView style={props.lineStyle as any} />
      </Container>
    </>
  );
};

export default React.memo(Separator);
