import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { View, Image, TouchableOpacity, Text } from 'react-native';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../../theme';
import TaskSummary from '../task-summary';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import { useHistory, useLocation, useParams } from 'react-router';
import {
  Member,
  Organization,
  Plan,
  Priority,
  Project,
  SortKey,
  SortOrder,
  Tag,
  Task,
  TaskCompleteFilter,
  TaskDocument,
  useCreateTaskMutation,
  useMeQuery,
  useOrganizationQuery,
  useOrganizationTagsQuery,
  useProjectQuery,
  useSearchTasksQuery,
  useTeamMembersQuery,
  useTeamQuery,
  useUpdateTaskSortNoInListMutation,
} from '../../../../../graphql/api/API';
import TaskSummaryForNewTask from '../task-summary-for-new-task';
import VirtualizedFlatList, {
  GlobalDragContextProvider,
} from '../../../../presentational/atoms/list2/virtualized-flat-list';
import {
  matchExpireFilter,
  matchTaskAssigner,
  matchTaskStatus,
  TaskFilter,
  TaskFilterContext,
} from '../../templates/app/team-projects';
import moment from 'moment-timezone';
import useDimensions from 'use-dimensions';
import noData02 from '../../../../../base64Images/no-data/no-data-2';
import UrlUtil from '../../../../../util/UrlUtil';
import PlusIcon from '../../../../presentational/molecules/image-icon/plus';
import Spinner from '../../../../presentational/atoms/spinner';
import queryString from 'query-string';
import CaretUpIcon from '../../../../presentational/molecules/image-icon/caret-up';
import CaretDownIcon from '../../../../presentational/molecules/image-icon/caret-down';
import CaretSetIcon from '../../../../presentational/molecules/image-icon/caret-set';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import when from '../../../../../lang-extention/When';
import Modal from '../../../../presentational/molecules/modal';
import Button from '../../../../presentational/atoms/button';
import EditableText from '../../../../presentational/atoms/editable-text';
import Input, { ListValueMap } from '../../../../presentational/atoms/input';
import Form from '../../../../presentational/atoms/form';
import TaskInfoElement from '../task-detail/task-info-element';
import CreatableMutiselect from '../../../../presentational/atoms/creatable-mutiselect';
import Mutiselect from '../../../../presentational/atoms/multiselect';
import { toast } from 'react-toastify';
import { MentionData } from '@draft-js-plugins/mention';
import Checkbox from '../../../../presentational/atoms/checkbox';
import ErrorMessageModal from '../error-message-modal';

const Container = styled.View``;

const TopMenu = styled.View`
  height: 40px;
`;

interface ITaskListWrapperProps extends IStyleTheme {
  isFavoritePage: boolean;
  isAssignedPage: boolean;
}

const TaskListWrapper = styled.View<ITaskListWrapperProps>`
  height: ${(props: ITaskListWrapperProps) =>
    props.isFavoritePage || props.isAssignedPage
      ? 'calc(100vh - 57px - 90px - 45px - 70px)'
      : 'calc(100vh - 57px - 90px - 45px)'};
`;
// 本当はoverflow-xはvisibleにしたいのだが、CSSの仕様で、overflow-yを指定した場合には、xの挙動もそちらで上書きされるため実現できない

const TaskAddButton = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  padding-right: 5px;
  padding-left: 5px;
`;

const TaskRegisterConatiner = styled.View`
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  align-items: flex-start;
  z-index: 2; //DateTimePickerを使用する際には、外側の要素のz-indexを指定しないと正しく表示されないケースがあるので、ここで指定している
`;

const Row = styled.View`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: space-between;
`;

interface IHeaderProps {
  organization: Organization;
  teamId: string;
  projectId: string;
  searchCondition: any;
  isFavoritePage: boolean;
  isAssignedPage: boolean;
}

const Header = (props: IHeaderProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const { window } = useDimensions();
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const history = useHistory();
  const windowWidth = window.width;

  return (
    <View
      style={{
        marginRight: 10,
        paddingRight: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}>
      <TouchableOpacity
        style={{ flexDirection: 'row', alignItems: 'flex-start', marginLeft: 50 }}
        onPress={() => {
          history.push(
            queryString.stringifyUrl({
              url: props.isFavoritePage
                ? `/app/${props.organization.id!}/my/favorite-project/${props.projectId}/list/`
                : props.isAssignedPage
                ? `/app/${props.organization.id!}/my/assigned-project/${props.projectId}/list/`
                : `/app/${props.organization.id!}/${props.teamId}/projects/${
                    props.projectId
                  }/list/`,
              query: Object.assign(props.searchCondition, {
                sortKey: when(props.searchCondition.sortOrder) //
                  .on(
                    (v) => v === null || v === undefined,
                    () => SortKey.Title
                  )
                  .on(
                    (v) => v === SortOrder.Asc,
                    () => SortKey.Title
                  )
                  .otherwise(() => null),
                sortOrder: when(props.searchCondition.sortOrder) //
                  .on(
                    (v) => v === null || v === undefined,
                    () => SortOrder.Asc
                  )
                  .on(
                    (v) => v === SortOrder.Asc,
                    () => SortOrder.Desc
                  )
                  .otherwise(() => null),
              }),
            })
          );
        }}>
        {props.searchCondition.sortKey === SortKey.Title ? (
          when(props.searchCondition.sortOrder) //
            .on(
              (v) => v === null || v === undefined,
              () => (
                <CaretSetIcon
                  size={12}
                  containerStyle={{
                    opacity: 0.2,
                    marginLeft: 3,
                  }}
                  onPress={() => {
                    history.push(
                      queryString.stringifyUrl({
                        url: props.isFavoritePage
                          ? `/app/${props.organization.id!}/my/favorite-project/${
                              props.projectId
                            }/list/`
                          : props.isAssignedPage
                          ? `/app/${props.organization.id!}/my/assigned-project/${
                              props.projectId
                            }/list/`
                          : `/app/${props.organization.id!}/${props.teamId}/projects/${
                              props.projectId
                            }/list/`,
                        query: Object.assign(props.searchCondition, {
                          sortKey: SortKey.Title,
                          sortOrder: SortOrder.Asc,
                        }),
                      })
                    );
                  }}
                />
              )
            )
            .on(
              (v) => v === SortOrder.Asc,
              () => (
                <CaretUpIcon
                  size={10}
                  containerStyle={{ marginLeft: 3 }}
                  onPress={() => {
                    history.push(
                      queryString.stringifyUrl({
                        url: props.isFavoritePage
                          ? `/app/${props.organization.id!}/my/favorite-project/${
                              props.projectId
                            }/list/`
                          : props.isAssignedPage
                          ? `/app/${props.organization.id!}/my/assigned-project/${
                              props.projectId
                            }/list/`
                          : `/app/${props.organization.id!}/${props.teamId}/projects/${
                              props.projectId
                            }/list/`,
                        query: Object.assign(props.searchCondition, {
                          sortKey: SortKey.Title,
                          sortOrder: SortOrder.Desc,
                        }),
                      })
                    );
                  }}
                />
              )
            )
            .otherwise(() => (
              <CaretDownIcon
                size={10}
                containerStyle={{ marginLeft: 3 }}
                onPress={() => {
                  history.push(
                    queryString.stringifyUrl({
                      url: props.isFavoritePage
                        ? `/app/${props.organization.id!}/my/favorite-project/${
                            props.projectId
                          }/list/`
                        : props.isAssignedPage
                        ? `/app/${props.organization.id!}/my/assigned-project/${
                            props.projectId
                          }/list/`
                        : `/app/${props.organization.id!}/${props.teamId}/projects/${
                            props.projectId
                          }/list/`,
                      query: Object.assign(props.searchCondition, {
                        sortKey: null,
                        sortOrder: null,
                      }),
                    })
                  );
                }}
              />
            ))
        ) : (
          <CaretSetIcon
            size={12}
            containerStyle={{
              opacity: 0.2,
              marginLeft: 3,
            }}
            onPress={() => {
              history.push(
                queryString.stringifyUrl({
                  url: props.isFavoritePage
                    ? `/app/${props.organization.id!}/my/favorite-project/${props.projectId}/list/`
                    : props.isAssignedPage
                    ? `/app/${props.organization.id!}/my/assigned-project/${props.projectId}/list/`
                    : `/app/${props.organization.id!}/${props.teamId}/projects/${
                        props.projectId
                      }/list/`,
                  query: Object.assign(props.searchCondition, {
                    sortKey: SortKey.Title,
                    sortOrder: SortOrder.Asc,
                  }),
                })
              );
            }}
          />
        )}
        <Typography
          variant={TypographyType.Normal}
          style={{
            fontSize: 12,
            marginLeft: 0,
            color: themeContext.colors.description,
            textAlign: 'left',
          }}>
          タスク名
        </Typography>
      </TouchableOpacity>
      <View style={{ flexDirection: 'row' }}>
        {(props.organization.plan.code === Plan.Business ||
          props.organization.plan.code === Plan.Enterprise) && (
          <TouchableOpacity
            style={{ flexDirection: 'row' }}
            onPress={() => {
              history.push(
                queryString.stringifyUrl({
                  url: props.isFavoritePage
                    ? `/app/${props.organization.id!}/my/favorite-project/${props.projectId}/list/`
                    : props.isAssignedPage
                    ? `/app/${props.organization.id!}/my/assigned-project/${props.projectId}/list/`
                    : `/app/${props.organization.id!}/${props.teamId}/projects/${
                        props.projectId
                      }/list/`,
                  query: Object.assign(props.searchCondition, {
                    sortKey: when(props.searchCondition.sortOrder) //
                      .on(
                        (v) => v === null || v === undefined,
                        () => SortKey.Assigner
                      )
                      .on(
                        (v) => v === SortOrder.Asc,
                        () => SortKey.Assigner
                      )
                      .otherwise(() => null),
                    sortOrder: when(props.searchCondition.sortOrder) //
                      .on(
                        (v) => v === null || v === undefined,
                        () => SortOrder.Asc
                      )
                      .on(
                        (v) => v === SortOrder.Asc,
                        () => SortOrder.Desc
                      )
                      .otherwise(() => null),
                  }),
                })
              );
            }}>
            {props.searchCondition.sortKey === SortKey.Assigner ? (
              when(props.searchCondition.sortOrder) //
                .on(
                  (v) => v === null || v === undefined,
                  () => (
                    <CaretSetIcon
                      size={12}
                      containerStyle={{
                        opacity: 0.2,
                        marginLeft: 3,
                      }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: props.isFavoritePage
                              ? `/app/${props.organization.id!}/my/favorite-project/${
                                  props.projectId
                                }/list/`
                              : props.isAssignedPage
                              ? `/app/${props.organization.id!}/my/assigned-project/${
                                  props.projectId
                                }/list/`
                              : `/app/${props.organization.id!}/${props.teamId}/projects/${
                                  props.projectId
                                }/list/`,
                            query: Object.assign(props.searchCondition, {
                              sortKey: SortKey.Assigner,
                              sortOrder: SortOrder.Asc,
                            }),
                          })
                        );
                      }}
                    />
                  )
                )
                .on(
                  (v) => v === SortOrder.Asc,
                  () => (
                    <CaretUpIcon
                      size={10}
                      containerStyle={{ marginLeft: 3 }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: props.isFavoritePage
                              ? `/app/${props.organization.id!}/my/favorite-project/${
                                  props.projectId
                                }/list/`
                              : props.isAssignedPage
                              ? `/app/${props.organization.id!}/my/assigned-project/${
                                  props.projectId
                                }/list/`
                              : `/app/${props.organization.id!}/${props.teamId}/projects/${
                                  props.projectId
                                }/list/`,
                            query: Object.assign(props.searchCondition, {
                              sortKey: SortKey.Assigner,
                              sortOrder: SortOrder.Desc,
                            }),
                          })
                        );
                      }}
                    />
                  )
                )
                .otherwise(() => (
                  <CaretDownIcon
                    size={10}
                    containerStyle={{ marginLeft: 3 }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: props.isFavoritePage
                            ? `/app/${props.organization.id!}/my/favorite-project/${
                                props.projectId
                              }/list/`
                            : props.isAssignedPage
                            ? `/app/${props.organization.id!}/my/assigned-project/${
                                props.projectId
                              }/list/`
                            : `/app/${props.organization.id!}/${props.teamId}/projects/${
                                props.projectId
                              }/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: null,
                            sortOrder: null,
                          }),
                        })
                      );
                    }}
                  />
                ))
            ) : (
              <CaretSetIcon
                size={12}
                containerStyle={{
                  opacity: 0.2,
                  marginLeft: 3,
                }}
                onPress={() => {
                  history.push(
                    queryString.stringifyUrl({
                      url: props.isFavoritePage
                        ? `/app/${props.organization.id!}/my/favorite-project/${
                            props.projectId
                          }/list/`
                        : props.isAssignedPage
                        ? `/app/${props.organization.id!}/my/assigned-project/${
                            props.projectId
                          }/list/`
                        : `/app/${props.organization.id!}/${props.teamId}/projects/${
                            props.projectId
                          }/list/`,
                      query: Object.assign(props.searchCondition, {
                        sortKey: SortKey.Assigner,
                        sortOrder: SortOrder.Asc,
                      }),
                    })
                  );
                }}
              />
            )}
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontSize: 12,
                color: themeContext.colors.description,
                textAlign: 'center',
                paddingRight: 5,
                width: 50,
              }}>
              担当者
            </Typography>
          </TouchableOpacity>
        )}
        <TouchableOpacity
          style={{ flexDirection: 'row' }}
          onPress={() => {
            history.push(
              queryString.stringifyUrl({
                url: props.isFavoritePage
                  ? `/app/${props.organization.id!}/my/favorite-project/${props.projectId}/list/`
                  : props.isAssignedPage
                  ? `/app/${props.organization.id!}/my/assigned-project/${props.projectId}/list/`
                  : `/app/${props.organization.id!}/${props.teamId}/projects/${
                      props.projectId
                    }/list/`,
                query: Object.assign(props.searchCondition, {
                  sortKey: when(props.searchCondition.sortOrder) //
                    .on(
                      (v) => v === null || v === undefined,
                      () => SortKey.Priority
                    )
                    .on(
                      (v) => v === SortOrder.Asc,
                      () => SortKey.Priority
                    )
                    .otherwise(() => null),
                  sortOrder: when(props.searchCondition.sortOrder) //
                    .on(
                      (v) => v === null || v === undefined,
                      () => SortOrder.Asc
                    )
                    .on(
                      (v) => v === SortOrder.Asc,
                      () => SortOrder.Desc
                    )
                    .otherwise(() => null),
                }),
              })
            );
          }}>
          {props.searchCondition.sortKey === SortKey.Priority ? (
            when(props.searchCondition.sortOrder) //
              .on(
                (v) => v === null || v === undefined,
                () => (
                  <CaretSetIcon
                    size={12}
                    containerStyle={{
                      opacity: 0.2,
                      marginLeft: 3,
                    }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: props.isFavoritePage
                            ? `/app/${props.organization.id!}/my/favorite-project/${
                                props.projectId
                              }/list/`
                            : props.isAssignedPage
                            ? `/app/${props.organization.id!}/my/assigned-project/${
                                props.projectId
                              }/list/`
                            : `/app/${props.organization.id!}/${props.teamId}/projects/${
                                props.projectId
                              }/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: SortKey.Priority,
                            sortOrder: SortOrder.Asc,
                          }),
                        })
                      );
                    }}
                  />
                )
              )
              .on(
                (v) => v === SortOrder.Asc,
                () => (
                  <CaretUpIcon
                    size={10}
                    containerStyle={{ marginLeft: 3 }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: props.isFavoritePage
                            ? `/app/${props.organization.id!}/my/favorite-project/${
                                props.projectId
                              }/list/`
                            : props.isAssignedPage
                            ? `/app/${props.organization.id!}/my/assigned-project/${
                                props.projectId
                              }/list/`
                            : `/app/${props.organization.id!}/${props.teamId}/projects/${
                                props.projectId
                              }/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: SortKey.Priority,
                            sortOrder: SortOrder.Desc,
                          }),
                        })
                      );
                    }}
                  />
                )
              )
              .otherwise(() => (
                <CaretDownIcon
                  size={10}
                  containerStyle={{ marginLeft: 3 }}
                  onPress={() => {
                    history.push(
                      queryString.stringifyUrl({
                        url: props.isFavoritePage
                          ? `/app/${props.organization.id!}/my/favorite-project/${
                              props.projectId
                            }/list/`
                          : props.isAssignedPage
                          ? `/app/${props.organization.id!}/my/assigned-project/${
                              props.projectId
                            }/list/`
                          : `/app/${props.organization.id!}/${props.teamId}/projects/${
                              props.projectId
                            }/list/`,
                        query: Object.assign(props.searchCondition, {
                          sortKey: null,
                          sortOrder: null,
                        }),
                      })
                    );
                  }}
                />
              ))
          ) : (
            <CaretSetIcon
              size={12}
              containerStyle={{
                opacity: 0.2,
                marginLeft: 3,
              }}
              onPress={() => {
                history.push(
                  queryString.stringifyUrl({
                    url: props.isFavoritePage
                      ? `/app/${props.organization.id!}/my/favorite-project/${
                          props.projectId
                        }/list/`
                      : props.isAssignedPage
                      ? `/app/${props.organization.id!}/my/assigned-project/${
                          props.projectId
                        }/list/`
                      : `/app/${props.organization.id!}/${props.teamId}/projects/${
                          props.projectId
                        }/list/`,
                    query: Object.assign(props.searchCondition, {
                      sortKey: SortKey.Priority,
                      sortOrder: SortOrder.Asc,
                    }),
                  })
                );
              }}
            />
          )}
          <Typography
            variant={TypographyType.Normal}
            style={{
              fontSize: 12,
              color: themeContext.colors.description,
              textAlign: 'center',
              paddingRight: 5,
              width: 50,
            }}>
            優先度
          </Typography>
        </TouchableOpacity>
        {windowWidth > 1500 && (
          <TouchableOpacity
            style={{ flexDirection: 'row' }}
            onPress={() => {
              history.push(
                queryString.stringifyUrl({
                  url: props.isFavoritePage
                    ? `/app/${props.organization.id!}/my/favorite-project/${props.projectId}/list/`
                    : props.isAssignedPage
                    ? `/app/${props.organization.id!}/my/assigned-project/${props.projectId}/list/`
                    : `/app/${props.organization.id!}/${props.teamId}/projects/${
                        props.projectId
                      }/list/`,
                  query: Object.assign(props.searchCondition, {
                    sortKey: when(props.searchCondition.sortOrder) //
                      .on(
                        (v) => v === null || v === undefined,
                        () => SortKey.ScheduledStartDate
                      )
                      .on(
                        (v) => v === SortOrder.Asc,
                        () => SortKey.ScheduledStartDate
                      )
                      .otherwise(() => null),
                    sortOrder: when(props.searchCondition.sortOrder) //
                      .on(
                        (v) => v === null || v === undefined,
                        () => SortOrder.Asc
                      )
                      .on(
                        (v) => v === SortOrder.Asc,
                        () => SortOrder.Desc
                      )
                      .otherwise(() => null),
                  }),
                })
              );
            }}>
            {props.searchCondition.sortKey === SortKey.ScheduledStartDate ? (
              when(props.searchCondition.sortOrder) //
                .on(
                  (v) => v === null || v === undefined,
                  () => (
                    <CaretSetIcon
                      size={12}
                      containerStyle={{
                        opacity: 0.2,
                        marginLeft: 3,
                      }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: props.isFavoritePage
                              ? `/app/${props.organization.id!}/my/favorite-project/${
                                  props.projectId
                                }/list/`
                              : props.isAssignedPage
                              ? `/app/${props.organization.id!}/my/assigned-project/${
                                  props.projectId
                                }/list/`
                              : `/app/${props.organization.id!}/${props.teamId}/projects/${
                                  props.projectId
                                }/list/`,
                            query: Object.assign(props.searchCondition, {
                              sortKey: SortKey.ScheduledStartDate,
                              sortOrder: SortOrder.Asc,
                            }),
                          })
                        );
                      }}
                    />
                  )
                )
                .on(
                  (v) => v === SortOrder.Asc,
                  () => (
                    <CaretUpIcon
                      size={10}
                      containerStyle={{ marginLeft: 3 }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: props.isFavoritePage
                              ? `/app/${props.organization.id!}/my/favorite-project/${
                                  props.projectId
                                }/list/`
                              : props.isAssignedPage
                              ? `/app/${props.organization.id!}/my/assigned-project/${
                                  props.projectId
                                }/list/`
                              : `/app/${props.organization.id!}/${props.teamId}/projects/${
                                  props.projectId
                                }/list/`,
                            query: Object.assign(props.searchCondition, {
                              sortKey: SortKey.ScheduledStartDate,
                              sortOrder: SortOrder.Desc,
                            }),
                          })
                        );
                      }}
                    />
                  )
                )
                .otherwise(() => (
                  <CaretDownIcon
                    size={10}
                    containerStyle={{ marginLeft: 3 }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: props.isFavoritePage
                            ? `/app/${props.organization.id!}/my/favorite-project/${
                                props.projectId
                              }/list/`
                            : props.isAssignedPage
                            ? `/app/${props.organization.id!}/my/assigned-project/${
                                props.projectId
                              }/list/`
                            : `/app/${props.organization.id!}/${props.teamId}/projects/${
                                props.projectId
                              }/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: null,
                            sortOrder: null,
                          }),
                        })
                      );
                    }}
                  />
                ))
            ) : (
              <CaretSetIcon
                size={12}
                containerStyle={{
                  opacity: 0.2,
                  marginLeft: 3,
                }}
                onPress={() => {
                  history.push(
                    queryString.stringifyUrl({
                      url: props.isFavoritePage
                        ? `/app/${props.organization.id!}/my/favorite-project/${
                            props.projectId
                          }/list/`
                        : props.isAssignedPage
                        ? `/app/${props.organization.id!}/my/assigned-project/${
                            props.projectId
                          }/list/`
                        : `/app/${props.organization.id!}/${props.teamId}/projects/${
                            props.projectId
                          }/list/`,
                      query: Object.assign(props.searchCondition, {
                        sortKey: SortKey.ScheduledStartDate,
                        sortOrder: SortOrder.Asc,
                      }),
                    })
                  );
                }}
              />
            )}
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontSize: 12,
                color: themeContext.colors.description,
                textAlign: 'center',
                paddingRight: 5,
                width: 80,
              }}>
              開始予定日
            </Typography>
          </TouchableOpacity>
        )}
        {windowWidth > 1400 && (
          <TouchableOpacity
            style={{ flexDirection: 'row' }}
            onPress={() => {
              history.push(
                queryString.stringifyUrl({
                  url: props.isFavoritePage
                    ? `/app/${props.organization.id!}/my/favorite-project/${props.projectId}/list/`
                    : props.isAssignedPage
                    ? `/app/${props.organization.id!}/my/assigned-project/${props.projectId}/list/`
                    : `/app/${props.organization.id!}/${props.teamId}/projects/${
                        props.projectId
                      }/list/`,
                  query: Object.assign(props.searchCondition, {
                    sortKey: when(props.searchCondition.sortOrder) //
                      .on(
                        (v) => v === null || v === undefined,
                        () => SortKey.ScheduledEndDate
                      )
                      .on(
                        (v) => v === SortOrder.Asc,
                        () => SortKey.ScheduledEndDate
                      )
                      .otherwise(() => null),
                    sortOrder: when(props.searchCondition.sortOrder) //
                      .on(
                        (v) => v === null || v === undefined,
                        () => SortOrder.Asc
                      )
                      .on(
                        (v) => v === SortOrder.Asc,
                        () => SortOrder.Desc
                      )
                      .otherwise(() => null),
                  }),
                })
              );
            }}>
            {props.searchCondition.sortKey === SortKey.ScheduledEndDate ? (
              when(props.searchCondition.sortOrder) //
                .on(
                  (v) => v === null || v === undefined,
                  () => (
                    <CaretSetIcon
                      size={12}
                      containerStyle={{
                        opacity: 0.2,
                        marginLeft: 3,
                        width: 20,
                        justifyContent: 'flex-end',
                      }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: props.isFavoritePage
                              ? `/app/${props.organization.id!}/my/favorite-project/${
                                  props.projectId
                                }/list/`
                              : props.isAssignedPage
                              ? `/app/${props.organization.id!}/my/assigned-project/${
                                  props.projectId
                                }/list/`
                              : `/app/${props.organization.id!}/${props.teamId}/projects/${
                                  props.projectId
                                }/list/`,
                            query: Object.assign(props.searchCondition, {
                              sortKey: SortKey.ScheduledEndDate,
                              sortOrder: SortOrder.Asc,
                            }),
                          })
                        );
                      }}
                    />
                  )
                )
                .on(
                  (v) => v === SortOrder.Asc,
                  () => (
                    <CaretUpIcon
                      size={10}
                      containerStyle={{ marginLeft: 3, width: 20, justifyContent: 'flex-end' }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: props.isFavoritePage
                              ? `/app/${props.organization.id!}/my/favorite-project/${
                                  props.projectId
                                }/list/`
                              : props.isAssignedPage
                              ? `/app/${props.organization.id!}/my/assigned-project/${
                                  props.projectId
                                }/list/`
                              : `/app/${props.organization.id!}/${props.teamId}/projects/${
                                  props.projectId
                                }/list/`,
                            query: Object.assign(props.searchCondition, {
                              sortKey: SortKey.ScheduledEndDate,
                              sortOrder: SortOrder.Desc,
                            }),
                          })
                        );
                      }}
                    />
                  )
                )
                .otherwise(() => (
                  <CaretDownIcon
                    size={10}
                    containerStyle={{ marginLeft: 3, width: 20, justifyContent: 'flex-end' }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: props.isFavoritePage
                            ? `/app/${props.organization.id!}/my/favorite-project/${
                                props.projectId
                              }/list/`
                            : props.isAssignedPage
                            ? `/app/${props.organization.id!}/my/assigned-project/${
                                props.projectId
                              }/list/`
                            : `/app/${props.organization.id!}/${props.teamId}/projects/${
                                props.projectId
                              }/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: null,
                            sortOrder: null,
                          }),
                        })
                      );
                    }}
                  />
                ))
            ) : (
              <CaretSetIcon
                size={12}
                containerStyle={{
                  opacity: 0.2,
                  marginLeft: 3,
                  width: 20,
                  justifyContent: 'flex-end',
                }}
                onPress={() => {
                  history.push(
                    queryString.stringifyUrl({
                      url: props.isFavoritePage
                        ? `/app/${props.organization.id!}/my/favorite-project/${
                            props.projectId
                          }/list/`
                        : props.isAssignedPage
                        ? `/app/${props.organization.id!}/my/assigned-project/${
                            props.projectId
                          }/list/`
                        : `/app/${props.organization.id!}/${props.teamId}/projects/${
                            props.projectId
                          }/list/`,
                      query: Object.assign(props.searchCondition, {
                        sortKey: SortKey.ScheduledEndDate,
                        sortOrder: SortOrder.Asc,
                      }),
                    })
                  );
                }}
              />
            )}
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontSize: 12,
                color: themeContext.colors.description,
                textAlign: 'left',
                paddingRight: 5,
                paddingLeft: 5,
                width: 60,
              }}>
              〆切日
            </Typography>
          </TouchableOpacity>
        )}
        <TouchableOpacity
          style={{ flexDirection: 'row' }}
          onPress={() => {
            history.push(
              queryString.stringifyUrl({
                url: props.isFavoritePage
                  ? `/app/${props.organization.id!}/my/favorite-project/${props.projectId}/list/`
                  : props.isAssignedPage
                  ? `/app/${props.organization.id!}/my/assigned-project/${props.projectId}/list/`
                  : `/app/${props.organization.id!}/${props.teamId}/projects/${
                      props.projectId
                    }/list/`,
                query: Object.assign(props.searchCondition, {
                  sortKey: when(props.searchCondition.sortOrder) //
                    .on(
                      (v) => v === null || v === undefined,
                      () => SortKey.ProgressRate
                    )
                    .on(
                      (v) => v === SortOrder.Asc,
                      () => SortKey.ProgressRate
                    )
                    .otherwise(() => null),
                  sortOrder: when(props.searchCondition.sortOrder) //
                    .on(
                      (v) => v === null || v === undefined,
                      () => SortOrder.Asc
                    )
                    .on(
                      (v) => v === SortOrder.Asc,
                      () => SortOrder.Desc
                    )
                    .otherwise(() => null),
                }),
              })
            );
          }}>
          {props.searchCondition.sortKey === SortKey.ProgressRate ? (
            when(props.searchCondition.sortOrder) //
              .on(
                (v) => v === null || v === undefined,
                () => (
                  <CaretSetIcon
                    size={12}
                    containerStyle={{
                      opacity: 0.2,
                      marginLeft: 3,
                      width: 10,
                      justifyContent: 'flex-end',
                    }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: props.isFavoritePage
                            ? `/app/${props.organization.id!}/my/favorite-project/${
                                props.projectId
                              }/list/`
                            : props.isAssignedPage
                            ? `/app/${props.organization.id!}/my/assigned-project/${
                                props.projectId
                              }/list/`
                            : `/app/${props.organization.id!}/${props.teamId}/projects/${
                                props.projectId
                              }/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: SortKey.ProgressRate,
                            sortOrder: SortOrder.Asc,
                          }),
                        })
                      );
                    }}
                  />
                )
              )
              .on(
                (v) => v === SortOrder.Asc,
                () => (
                  <CaretUpIcon
                    size={10}
                    containerStyle={{ marginLeft: 3, width: 10, justifyContent: 'flex-end' }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: props.isFavoritePage
                            ? `/app/${props.organization.id!}/my/favorite-project/${
                                props.projectId
                              }/list/`
                            : props.isAssignedPage
                            ? `/app/${props.organization.id!}/my/assigned-project/${
                                props.projectId
                              }/list/`
                            : `/app/${props.organization.id!}/${props.teamId}/projects/${
                                props.projectId
                              }/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: SortKey.ProgressRate,
                            sortOrder: SortOrder.Desc,
                          }),
                        })
                      );
                    }}
                  />
                )
              )
              .otherwise(() => (
                <CaretDownIcon
                  size={10}
                  containerStyle={{ marginLeft: 3, width: 10, justifyContent: 'flex-end' }}
                  onPress={() => {
                    history.push(
                      queryString.stringifyUrl({
                        url: props.isFavoritePage
                          ? `/app/${props.organization.id!}/my/favorite-project/${
                              props.projectId
                            }/list/`
                          : props.isAssignedPage
                          ? `/app/${props.organization.id!}/my/assigned-project/${
                              props.projectId
                            }/list/`
                          : `/app/${props.organization.id!}/${props.teamId}/projects/${
                              props.projectId
                            }/list/`,
                        query: Object.assign(props.searchCondition, {
                          sortKey: null,
                          sortOrder: null,
                        }),
                      })
                    );
                  }}
                />
              ))
          ) : (
            <CaretSetIcon
              size={12}
              containerStyle={{
                opacity: 0.2,
                marginLeft: 3,
                width: 10,
                justifyContent: 'flex-end',
              }}
              onPress={() => {
                history.push(
                  queryString.stringifyUrl({
                    url: props.isFavoritePage
                      ? `/app/${props.organization.id!}/my/favorite-project/${
                          props.projectId
                        }/list/`
                      : props.isAssignedPage
                      ? `/app/${props.organization.id!}/my/assigned-project/${
                          props.projectId
                        }/list/`
                      : `/app/${props.organization.id!}/${props.teamId}/projects/${
                          props.projectId
                        }/list/`,
                    query: Object.assign(props.searchCondition, {
                      sortKey: SortKey.ProgressRate,
                      sortOrder: SortOrder.Asc,
                    }),
                  })
                );
              }}
            />
          )}
          <Typography
            variant={TypographyType.Normal}
            style={{
              fontSize: 12,
              color: themeContext.colors.description,
              textAlign: 'left',
              paddingRight: 5,
              paddingLeft: 5,
              width: 50,
            }}>
            進捗率
          </Typography>
        </TouchableOpacity>
        <TouchableOpacity
          style={{ flexDirection: 'row' }}
          onPress={() => {
            history.push(
              queryString.stringifyUrl({
                url: props.isFavoritePage
                  ? `/app/${props.organization.id!}/my/favorite-project/${props.projectId}/list/`
                  : props.isAssignedPage
                  ? `/app/${props.organization.id!}/my/assigned-project/${props.projectId}/list/`
                  : `/app/${props.organization.id!}/${props.teamId}/projects/${
                      props.projectId
                    }/list/`,
                query: Object.assign(props.searchCondition, {
                  sortKey: when(props.searchCondition.sortOrder) //
                    .on(
                      (v) => v === null || v === undefined,
                      () => SortKey.WorkingTimeSec
                    )
                    .on(
                      (v) => v === SortOrder.Asc,
                      () => SortKey.WorkingTimeSec
                    )
                    .otherwise(() => null),
                  sortOrder: when(props.searchCondition.sortOrder) //
                    .on(
                      (v) => v === null || v === undefined,
                      () => SortOrder.Asc
                    )
                    .on(
                      (v) => v === SortOrder.Asc,
                      () => SortOrder.Desc
                    )
                    .otherwise(() => null),
                }),
              })
            );
          }}>
          {props.searchCondition.sortKey === SortKey.WorkingTimeSec ? (
            when(props.searchCondition.sortOrder) //
              .on(
                (v) => v === null || v === undefined,
                () => (
                  <CaretSetIcon
                    size={12}
                    containerStyle={{
                      opacity: 0.2,
                      marginLeft: 3,
                      width: 20,
                      justifyContent: 'flex-end',
                    }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: props.isFavoritePage
                            ? `/app/${props.organization.id!}/my/favorite-project/${
                                props.projectId
                              }/list/`
                            : props.isAssignedPage
                            ? `/app/${props.organization.id!}/my/assigned-project/${
                                props.projectId
                              }/list/`
                            : `/app/${props.organization.id!}/${props.teamId}/projects/${
                                props.projectId
                              }/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: SortKey.WorkingTimeSec,
                            sortOrder: SortOrder.Asc,
                          }),
                        })
                      );
                    }}
                  />
                )
              )
              .on(
                (v) => v === SortOrder.Asc,
                () => (
                  <CaretUpIcon
                    size={10}
                    containerStyle={{ marginLeft: 3, width: 20, justifyContent: 'flex-end' }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: props.isFavoritePage
                            ? `/app/${props.organization.id!}/my/favorite-project/${
                                props.projectId
                              }/list/`
                            : props.isAssignedPage
                            ? `/app/${props.organization.id!}/my/assigned-project/${
                                props.projectId
                              }/list/`
                            : `/app/${props.organization.id!}/${props.teamId}/projects/${
                                props.projectId
                              }/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: SortKey.WorkingTimeSec,
                            sortOrder: SortOrder.Desc,
                          }),
                        })
                      );
                    }}
                  />
                )
              )
              .otherwise(() => (
                <CaretDownIcon
                  size={10}
                  containerStyle={{ marginLeft: 3, width: 20, justifyContent: 'flex-end' }}
                  onPress={() => {
                    history.push(
                      queryString.stringifyUrl({
                        url: props.isFavoritePage
                          ? `/app/${props.organization.id!}/my/favorite-project/${
                              props.projectId
                            }/list/`
                          : props.isAssignedPage
                          ? `/app/${props.organization.id!}/my/assigned-project/${
                              props.projectId
                            }/list/`
                          : `/app/${props.organization.id!}/${props.teamId}/projects/${
                              props.projectId
                            }/list/`,
                        query: Object.assign(props.searchCondition, {
                          sortKey: null,
                          sortOrder: null,
                        }),
                      })
                    );
                  }}
                />
              ))
          ) : (
            <CaretSetIcon
              size={12}
              containerStyle={{
                opacity: 0.2,
                marginLeft: 3,
                width: 20,
                justifyContent: 'flex-end',
              }}
              onPress={() => {
                history.push(
                  queryString.stringifyUrl({
                    url: props.isFavoritePage
                      ? `/app/${props.organization.id!}/my/favorite-project/${
                          props.projectId
                        }/list/`
                      : props.isAssignedPage
                      ? `/app/${props.organization.id!}/my/assigned-project/${
                          props.projectId
                        }/list/`
                      : `/app/${props.organization.id!}/${props.teamId}/projects/${
                          props.projectId
                        }/list/`,
                    query: Object.assign(props.searchCondition, {
                      sortKey: SortKey.WorkingTimeSec,
                      sortOrder: SortOrder.Asc,
                    }),
                  })
                );
              }}
            />
          )}
          <Typography
            variant={TypographyType.Normal}
            style={{
              fontSize: 12,
              color: themeContext.colors.description,
              textAlign: 'left',
              paddingHorizontal: 5,
              width: 80,
            }}>
            作業時間
          </Typography>
        </TouchableOpacity>

        <TouchableOpacity
          style={{ flexDirection: 'row' }}
          onPress={() => {
            history.push(
              queryString.stringifyUrl({
                url: props.isFavoritePage
                  ? `/app/${props.organization.id!}/my/favorite-project/${props.projectId}/list/`
                  : props.isAssignedPage
                  ? `/app/${props.organization.id!}/my/assigned-project/${props.projectId}/list/`
                  : `/app/${props.organization.id!}/${props.teamId}/projects/${
                      props.projectId
                    }/list/`,
                query: Object.assign(props.searchCondition, {
                  sortKey: when(props.searchCondition.sortOrder) //
                    .on(
                      (v) => v === null || v === undefined,
                      () => SortKey.EstimateWorkingTimeSec
                    )
                    .on(
                      (v) => v === SortOrder.Asc,
                      () => SortKey.EstimateWorkingTimeSec
                    )
                    .otherwise(() => null),
                  sortOrder: when(props.searchCondition.sortOrder) //
                    .on(
                      (v) => v === null || v === undefined,
                      () => SortOrder.Asc
                    )
                    .on(
                      (v) => v === SortOrder.Asc,
                      () => SortOrder.Desc
                    )
                    .otherwise(() => null),
                }),
              })
            );
          }}>
          {props.searchCondition.sortKey === SortKey.EstimateWorkingTimeSec ? (
            when(props.searchCondition.sortOrder) //
              .on(
                (v) => v === null || v === undefined,
                () => (
                  <CaretSetIcon
                    size={12}
                    containerStyle={{
                      opacity: 0.2,
                      marginLeft: 3,
                      width: 10,
                      justifyContent: 'flex-end',
                    }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: props.isFavoritePage
                            ? `/app/${props.organization.id!}/my/favorite-project/${
                                props.projectId
                              }/list/`
                            : props.isAssignedPage
                            ? `/app/${props.organization.id!}/my/assigned-project/${
                                props.projectId
                              }/list/`
                            : `/app/${props.organization.id!}/${props.teamId}/projects/${
                                props.projectId
                              }/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: SortKey.EstimateWorkingTimeSec,
                            sortOrder: SortOrder.Asc,
                          }),
                        })
                      );
                    }}
                  />
                )
              )
              .on(
                (v) => v === SortOrder.Asc,
                () => (
                  <CaretUpIcon
                    size={10}
                    containerStyle={{ marginLeft: 3, width: 10, justifyContent: 'flex-end' }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: props.isFavoritePage
                            ? `/app/${props.organization.id!}/my/favorite-project/${
                                props.projectId
                              }/list/`
                            : props.isAssignedPage
                            ? `/app/${props.organization.id!}/my/assigned-project/${
                                props.projectId
                              }/list/`
                            : `/app/${props.organization.id!}/${props.teamId}/projects/${
                                props.projectId
                              }/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: SortKey.EstimateWorkingTimeSec,
                            sortOrder: SortOrder.Desc,
                          }),
                        })
                      );
                    }}
                  />
                )
              )
              .otherwise(() => (
                <CaretDownIcon
                  size={10}
                  containerStyle={{ marginLeft: 3, width: 10, justifyContent: 'flex-end' }}
                  onPress={() => {
                    history.push(
                      queryString.stringifyUrl({
                        url: props.isFavoritePage
                          ? `/app/${props.organization.id!}/my/favorite-project/${
                              props.projectId
                            }/list/`
                          : props.isAssignedPage
                          ? `/app/${props.organization.id!}/my/assigned-project/${
                              props.projectId
                            }/list/`
                          : `/app/${props.organization.id!}/${props.teamId}/projects/${
                              props.projectId
                            }/list/`,
                        query: Object.assign(props.searchCondition, {
                          sortKey: null,
                          sortOrder: null,
                        }),
                      })
                    );
                  }}
                />
              ))
          ) : (
            <CaretSetIcon
              size={12}
              containerStyle={{
                opacity: 0.2,
                marginLeft: 3,
                width: 10,
                justifyContent: 'flex-end',
              }}
              onPress={() => {
                history.push(
                  queryString.stringifyUrl({
                    url: props.isFavoritePage
                      ? `/app/${props.organization.id!}/my/favorite-project/${
                          props.projectId
                        }/list/`
                      : props.isAssignedPage
                      ? `/app/${props.organization.id!}/my/assigned-project/${
                          props.projectId
                        }/list/`
                      : `/app/${props.organization.id!}/${props.teamId}/projects/${
                          props.projectId
                        }/list/`,
                    query: Object.assign(props.searchCondition, {
                      sortKey: SortKey.EstimateWorkingTimeSec,
                      sortOrder: SortOrder.Asc,
                    }),
                  })
                );
              }}
            />
          )}
          <Typography
            variant={TypographyType.Normal}
            style={{
              fontSize: 12,
              color: themeContext.colors.description,
              textAlign: 'left',
              paddingHorizontal: 5,
              width: 70,
            }}>
            見積時間
          </Typography>
        </TouchableOpacity>

        {windowWidth > 1600 && (
          <Typography
            variant={TypographyType.Normal}
            style={{
              fontSize: 12,
              color: themeContext.colors.description,
              textAlign: 'center',
              paddingHorizontal: 5,
              width: 100,
            }}>
            予想見積時間
          </Typography>
        )}
        <Typography
          variant={TypographyType.Normal}
          style={{
            fontSize: 12,
            color: themeContext.colors.description,
            paddingHorizontal: 5,
            width: 40,
            textAlign: 'center',
          }}>
          完了
        </Typography>
      </View>
    </View>
  );
};

interface ITaskAddProps {
  setShowNewTask: (value: boolean) => void;
  onClickRegisterDetail?: () => void;
  me: Member;
}

const TaskAdd = (props: ITaskAddProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [showErrorModal, setShowErrorModal] = useState(false);
  return (
    <View style={{ flexDirection: 'row' }}>
      <TaskAddButton
        style={{
          display: 'flex',
          flexDirection: 'row',
          paddingHorizontal: 5,
          marginTop: 10,
        }}
        onPress={() => {
          if (!props.me.taskCreatePermissionFlg) {
            setShowErrorModal(true);
            return;
          }
          props.setShowNewTask(true);
        }}>
        <PlusIcon
          size={14}
          containerStyle={{ marginLeft: 10 }}
          onPress={() => {
            if (!props.me.taskCreatePermissionFlg) {
              setShowErrorModal(true);
              return;
            }
            props.setShowNewTask(true);
          }}>
          <Typography
            variant={TypographyType.Normal}
            style={{ fontSize: 14, color: themeContext.colors.description }}>
            タスクを追加する
          </Typography>
        </PlusIcon>
      </TaskAddButton>
      {props.onClickRegisterDetail && (
        <TaskAddButton
          style={{
            display: 'flex',
            flexDirection: 'row',
            paddingHorizontal: 5,
            marginTop: 10,
          }}
          onPress={() => {
            if (!props.me.taskCreatePermissionFlg) {
              setShowErrorModal(true);
              return;
            }
            props.onClickRegisterDetail!();
          }}>
          <PlusIcon
            size={14}
            containerStyle={{ marginLeft: 10 }}
            onPress={() => {
              if (!props.me.taskCreatePermissionFlg) {
                setShowErrorModal(true);
                return;
              }
              props.onClickRegisterDetail!();
            }}>
            <Typography
              variant={TypographyType.Normal}
              style={{ fontSize: 14, color: themeContext.colors.description }}>
              詳細情報を含めて登録する
            </Typography>
          </PlusIcon>
        </TaskAddButton>
      )}
      <ErrorMessageModal
        showModal={showErrorModal}
        title={'タスクを作成できません'}
        message={`タスクを作成する権限がありません${'\n'}権限が必要な場合、管理権限を持っているメンバーに問い合わせてください`}
        onCloseModal={() => setShowErrorModal(false)}
      />
    </View>
  );
};

interface IAlertCanNotDragModalProps {
  showModal: boolean;
  onCloseModal: () => void;
}

const AlertCanNotDragModal = (props: IAlertCanNotDragModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  return (
    <Modal
      title={'並び替えができません'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <View style={{ flexDirection: 'column' }}>
        <View style={{ marginTop: 10 }}>
          <Typography
            variant={TypographyType.Description}
            style={{ textAlign: 'center', fontSize: 16, lineHeight: 22 }}>
            {`ドラッグ操作で並び順を変更したい場合、${'\n'}表示順の指定を解除してください`}
          </Typography>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
          <Button
            text={'閉じる'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
            }}
            onPress={() => props.onCloseModal()}
          />
        </View>
      </View>
    </Modal>
  );
};

interface ITagSettingsProps {
  organizationTags: Tag[];
  organization: Organization;
  onChange: (tagIds: string[], newTagNames: string[]) => void;
}

const TagSettings = (props: ITagSettingsProps) => {
  const isBasicPlan = props.organization.plan.code === Plan.Basic;

  return (
    <Form style={{ width: '100%' }}>
      <CreatableMutiselect
        name={'tags'}
        noOptionsMessage={
          isBasicPlan ? '新しいタグを付与するには、アップグレードが必要です' : undefined
        }
        validate={(item: string) => item !== '' && item.length <= 100}
        initialValues={[]}
        pickerItems={props.organizationTags!.map((tag) => ({
          value: tag!.name,
          label: tag!.name,
        }))}
        placeholder={'タグを選択する'}
        isSearchable={true}
        onBlur={(values) => {
          props.onChange(
            props
              .organizationTags!.filter((tag) => (values as string[]).includes(tag!.name))
              .map((tag) => tag!.id!),
            isBasicPlan
              ? [] //Basicプランの場合には新しいタグを登録できないようにしている
              : (values as string[]).filter(
                  (item) => !props.organizationTags!.map((tag) => tag?.name).includes(item)
                )
          );
        }}
        onPressEnter={(values) => {
          props.onChange(
            props
              .organizationTags!.filter((tag) => (values as string[]).includes(tag!.name))
              .map((tag) => tag!.id!),
            isBasicPlan
              ? [] //Basicプランの場合には新しいタグを登録できないようにしている
              : (values as string[]).filter(
                  (item) => !props.organizationTags!.map((tag) => tag?.name).includes(item)
                )
          );
        }}
      />
    </Form>
  );
};

interface IAssigneesSettingsProps {
  teamMembers: Member[];
  onChange: (members: Member[]) => void;
}

const AssigneesSettings = (props: IAssigneesSettingsProps) => {
  return (
    <Form style={{ width: '100%' }}>
      <Mutiselect
        name={'assignees'}
        validate={(item: string) => item !== '' && item.length <= 100}
        initialValues={[]}
        pickerItems={props.teamMembers.map(
          (member) =>
            ({
              value: member!.id!,
              label: member!.name,
              imageUrl: member!.profileImageUrl,
            } as ListValueMap)
        )}
        placeholder={'担当者を選択する'}
        isSearchable={true}
        onBlur={(values) => {
          const members = props.teamMembers!.filter((member) =>
            (values as string[]).includes(member!.id!)
          );
          props.onChange(members);
        }}
        onPressEnter={(values) => {
          const members = props.teamMembers!.filter((member) =>
            (values as string[]).includes(member!.id!)
          );
          props.onChange(members);
        }}
      />
    </Form>
  );
};

interface ITaskDescriptionProps {
  organizationMembers: Member[];
  descriptionRowRef: any;
  onChange: (value: string) => void;
}

const TaskDescription = (props: ITaskDescriptionProps) => {
  return (
    <Form style={{ zIndex: 2, minWidth: 600 }}>
      <Input
        name={'description'}
        type={'rich-text-editor'}
        showToolBarAlways={true}
        focus={false}
        mentions={props.organizationMembers!.map((member) => {
          return {
            id: member!.id!,
            name: `${member!.name || '?'}`,
            avatar: member!.profileImageUrl,
          } as MentionData;
        })}
        onChange={props.onChange}
      />
    </Form>
  );
};

interface IAddTaskDetailModalProps {
  project: Project;
  showModal: boolean;
  onCloseModal: () => void;
}

const AddTaskDetailModal = (props: IAddTaskDetailModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const descriptionRowRef = useRef();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [estimateTimeSec, setEstimateTimeSec] = useState<number | null>(null);
  const [priority, setPriority] = useState<Priority | null>(null);
  const [scheduledStartDateTime, setScheduledStartDateTime] = useState<moment.Moment | null>(null);
  const [scheduledEndDateTime, setScheduledEndDateTime] = useState<moment.Moment | null>(null);
  const [assignees, setAssignees] = useState<Member[]>([]);
  const [tagIds, setTagIds] = useState<string[]>([]);
  const [newTagNames, setNewTagNames] = useState<string[]>([]);
  const [createContinuous, setCreateContinuous] = useState(false);
  const fetchTeamResult = useTeamQuery({
    variables: {
      id: props.project.team.id!,
    },
  });
  const fetchTeamMembers = useTeamMembersQuery({
    variables: {
      teamId: props.project.team.id!,
    },
  });
  const { loading: organizationTagsLoading, data: organizationTagsData } = useOrganizationTagsQuery(
    {
      variables: {
        organizationId: props.project.team.organization.id!,
      },
      fetchPolicy: 'network-only',
    }
  );
  const [createTask] = useCreateTaskMutation({
    variables: {
      projectId: props.project.id!,
      input: {
        title,
        description,
        estimateTimeSec,
        priority,
        scheduledStartDateTime: scheduledStartDateTime
          ? scheduledStartDateTime.toISOString()
          : null,
        scheduledEndDateTime: scheduledEndDateTime ? scheduledEndDateTime.toISOString() : null,
        assigneeIds: assignees ? assignees.map((member) => member!.id!) : [],
        tagIds,
        newTagNames,
      },
    },
    update: (cache, result) => {
      // 自動的にはRefetchされないので。
      cache.modify({
        fields: {
          projectTasks(existing = []) {
            const newTask = cache.writeQuery({
              data: result.data?.createTask,
              query: TaskDocument,
            });
            return [newTask, ...existing];
          },
          searchTasks(existing = []) {
            const newTask = cache.writeQuery({
              data: result.data?.createTask,
              query: TaskDocument,
            });
            return [newTask, ...existing];
          },
          boardTasks(existingRef, { readField }) {
            const taskStatusId = `TaskStatus:${result.data?.createTask?.taskStatus?.id}`; // Pusherからemptyデータが来るケースがあるので、taskStatusが無い場合がある
            if (
              taskStatusId === existingRef.taskStatus.__ref &&
              !existingRef.tasks.includes(taskStatusId)
            ) {
              return {
                taskStatus: existingRef.taskStatus,
                tasks: [
                  {
                    __ref: `Task:${result.data?.createTask!.id}`,
                  },
                  ...existingRef.tasks,
                ],
              };
            }
            return existingRef;
          },
        },
      });
    },
  });

  useEffect(() => {
    setTitle('');
    setDescription('');
    setEstimateTimeSec(null);
    setPriority(null);
    setScheduledStartDateTime(null);
    setScheduledEndDateTime(null);
    setAssignees([]);
    setTagIds([]);
    setNewTagNames([]);
    setCreateContinuous(false);
  }, [props.showModal]);

  if (fetchTeamResult.loading || fetchTeamMembers.loading || organizationTagsLoading) {
    return <></>;
  }

  return (
    <Modal
      title={'タスクを登録する'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <TaskRegisterConatiner>
        <Form style={{ marginTop: 10 }}>
          <Row>
            <TaskInfoElement title={'タイトル'} style={{ flex: 1 }}>
              <Input
                name="title"
                placeholder={'タイトル'}
                value={title}
                focus={true}
                inputContainerStyle={{ minWidth: 600 }}
                validate={{
                  required: {
                    value: true,
                    message: 'タイトルを入力してください',
                  },
                  maxLength: {
                    value: 100,
                    message: '100文字以内で入力してください',
                  },
                }}
                onChange={(value) => setTitle(value as string)}
              />
            </TaskInfoElement>
          </Row>
          {(fetchTeamResult.data!.team!.organization.plan.code === Plan.Business ||
            fetchTeamResult.data!.team!.organization.plan.code === Plan.Enterprise) && (
            <Row>
              <TaskInfoElement title={'担当者'} style={{ flex: 1 }}>
                <AssigneesSettings
                  teamMembers={
                    (fetchTeamMembers?.data?.teamMembers as Member[]) ?? ([] as Member[])
                  }
                  onChange={(newValues) => setAssignees(newValues)}
                />
              </TaskInfoElement>
            </Row>
          )}
          <Row>
            <TaskInfoElement title={'優先度'} style={{ flex: 1 }}>
              <Input
                name="priority"
                value={priority?.toString() || ''}
                type={'picker'}
                inputContainerStyle={{ minWidth: 600 }}
                pickerItems={[
                  {
                    label: 'なし',
                    value: '',
                  },
                  {
                    label: '高',
                    value: Priority.High,
                  },
                  {
                    label: '中',
                    value: Priority.Normal,
                  },
                  {
                    label: '低',
                    value: Priority.Low,
                  },
                ]}
                onChange={(value) => setPriority((value as Priority) || null)}
              />
            </TaskInfoElement>
          </Row>
          <Row>
            <TaskInfoElement title={'タグ'} style={{ flex: 1 }}>
              <TagSettings
                organizationTags={organizationTagsData!.organizationTags as Tag[]}
                organization={props.project.team.organization!}
                onChange={(tagIds, newTagNames) => {
                  setTagIds(tagIds);
                  setNewTagNames(newTagNames);
                }}
              />
            </TaskInfoElement>
          </Row>
          <Row style={{ zIndex: 4, justifyContent: 'flex-start' }}>
            <TaskInfoElement title={'開始予定日'} style={{ flex: 1 }}>
              <EditableText
                value={scheduledStartDateTime}
                type={'date-time-picker'}
                selectableEndDate={scheduledEndDateTime}
                style={{ minWidth: 600 }}
                onChange={(value) => {
                  setScheduledStartDateTime((value as moment.Moment) || null);
                }}
              />
            </TaskInfoElement>
          </Row>
          <Row style={{ zIndex: 3, justifyContent: 'flex-start' }}>
            <TaskInfoElement title={'〆切日'} style={{ flex: 1 }}>
              <EditableText
                value={scheduledEndDateTime}
                type={'date-time-picker'}
                selectableEndDate={scheduledEndDateTime}
                style={{ minWidth: 600 }}
                onChange={(value) => {
                  setScheduledEndDateTime((value as moment.Moment) || null);
                }}
              />
            </TaskInfoElement>
          </Row>
          <Row style={{ zIndex: 2, justifyContent: 'flex-start' }}>
            <TaskInfoElement title={'見積時間'} style={{ flex: 1 }} contentFlexDirection={'row'}>
              <EditableText
                value={estimateTimeSec}
                type={'time-picker'}
                style={{ flex: 1, minWidth: 600 }}
                onChange={(value) => {
                  setEstimateTimeSec((value as number) || null);
                }}
              />
            </TaskInfoElement>
          </Row>
          <Row style={{ justifyContent: 'flex-start' }} ref={descriptionRowRef}>
            <TaskInfoElement title={'説明'} style={{ flex: 1 }} contentFlexDirection={'row'}>
              <TaskDescription
                organizationMembers={fetchTeamMembers.data!.teamMembers as Member[]}
                descriptionRowRef={descriptionRowRef}
                onChange={(value) => setDescription(value)}
              />
            </TaskInfoElement>
          </Row>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 20,
            }}>
            <TouchableOpacity
              style={{ flexDirection: 'row' }}
              onPress={() => setCreateContinuous(!createContinuous)}>
              <Checkbox
                size={18}
                color={themeContext.colors.primary}
                value={createContinuous}
                onValueChange={(value) => setCreateContinuous(value)}
              />
              <Typography
                variant={TypographyType.Normal}
                style={{
                  fontSize: 13,
                  lineHeight: 18,
                  marginLeft: 5,
                  color: themeContext.colors.description,
                }}>
                連続して登録する
              </Typography>
            </TouchableOpacity>
            <View style={{ width: 150 }}></View>
          </View>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 20,
            }}>
            <Button
              text="作成する"
              completeText="作成しました"
              style={{ marginRight: 5 }}
              textStyle={{ fontSize: 18 }}
              onPress={async () => {
                const result = await createTask();
                if (createContinuous && result.data?.createTask) {
                  toast('タスクを登録しました', {
                    type: 'success',
                    position: 'bottom-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                  return;
                }
                props.onCloseModal();
              }}
            />
            <Button
              text="キャンセル"
              style={{ backgroundColor: 'transparent' }}
              textStyle={{ fontSize: 18, color: themeContext.colors.primary }}
              onPress={() => props.onCloseModal()}
              disableValidate={true}
            />
          </View>
        </Form>
      </TaskRegisterConatiner>
    </Modal>
  );
};

interface ITaskListInnerProps {
  organizationId: string;
  teamId: string;
  projectId: string;
  searchCondition: any;
}

const TaskListInner = React.memo((props: ITaskListInnerProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const history = useHistory();
  const pageSize = 50;
  const [taskFilter, __] = useContext(TaskFilterContext);
  const [showNewTaskTop, setShowNewTaskTop] = useState(false);
  const [showCreateTasksLoading, setShowCreateTasksLoading] = useState(false);
  const [showNewTaskBottom, setShowNewTaskBottom] = useState(false);
  const [showNewTaskDetailModal, setShowNewTaskDetailModal] = useState(false);
  const [showCanNotDragModal, setShowCanNotDragModal] = useState(false);
  const { loading, data, fetchMore } = useSearchTasksQuery({
    variables: {
      input: {
        projectIdList: [props.projectId],
        taskStatusIdList: (taskFilter as TaskFilter).taskStatusIds || [],
        completeCondition: TaskCompleteFilter.Both,
        assignedMemberIdList: (taskFilter as TaskFilter).assignerIds || [],
        scheduledEndDateTime: {
          to: when((taskFilter as TaskFilter)?.expire)
            .on(
              (v) => v === 'today',
              () => moment().endOf('day').toISOString()
            )
            .on(
              (v) => v === '3days',
              () => moment().add(3, 'days').endOf('day').toISOString()
            )
            .on(
              (v) => v === 'week',
              () => moment().add(1, 'week').endOf('day').toISOString()
            )
            .otherwise(() => null),
        },
        sortKey: props.searchCondition?.sortKey || SortKey.SortNoInList,
        sortOrder: props.searchCondition?.sortOrder || SortOrder.Desc,
      },
      offset: 0,
      limit: pageSize,
    },
    fetchPolicy: 'network-only',
    skip:
      props.projectId === 'task' ||
      (taskFilter as TaskFilter)?.taskStatusIds?.length === 0 ||
      (taskFilter as TaskFilter)?.taskStatusIds?.length === undefined,
  });
  const logimMemberResult = useMeQuery();
  const projectResult = useProjectQuery({
    variables: {
      id: props.projectId,
    },
  });
  const { loading: organizationLoading, data: organizationData } = useOrganizationQuery({
    variables: {
      id: props.organizationId,
    },
  });
  const [updateTaskSortNo, _] = useUpdateTaskSortNoInListMutation();

  // Memo化しておかないと、データに変更がなくても、このコンポーネントが描画される度に、全てのTaskSummary再描画がされてしまう。
  const tasks = useMemo(() => {
    if (loading || !data?.searchTasks) {
      return [];
    }
    return data!.searchTasks!;
  }, [loading, data?.searchTasks, taskFilter]);

  const renderItem = useCallback(
    (item, index) => {
      if (!organizationData?.organization || !logimMemberResult.data?.me) {
        return <></>;
      }
      return (
        <TaskSummary
          task={item!}
          organization={organizationData!.organization!}
          showTeamName={false}
          showProjectName={false}
          me={logimMemberResult.data.me}
        />
      );
    },
    [props.teamId, organizationData?.organization, logimMemberResult.data?.me]
  );

  const getKey = useCallback((task) => task!.id!.toString(), []);

  const onPress = useCallback(
    (task) => {
      history.push(UrlUtil.createTaskDetailUrl(task));
    },
    [props.organizationId, props.teamId, props.projectId]
  );

  const onDrop = useCallback(
    async (info) => {
      if (props.searchCondition?.sortKey) {
        setShowCanNotDragModal(true);
        return;
      }
      const isMoveToFirst = info.endRowIndex === 0;
      const isMoveToLast = info.endRowIndex === tasks.length - 1;
      const isMoveToDown = info.endRowIndex - info.startRowIndex > 0;

      let sortNo;
      if (isMoveToFirst) {
        sortNo = new Date().getTime();
      } else if (isMoveToLast) {
        sortNo = tasks[info.endRowIndex]!.sortNoInList - 1000;
      } else {
        if (isMoveToDown) {
          const beforeTask = tasks[info.endRowIndex];
          const afterTask = tasks[info.endRowIndex + 1];
          sortNo = Math.floor((beforeTask!.sortNoInList + afterTask!.sortNoInList) / 2);
        } else {
          const beforeTask = tasks[info.endRowIndex - 1];
          const afterTask = tasks[info.endRowIndex];
          sortNo = Math.floor((beforeTask!.sortNoInList + afterTask!.sortNoInList) / 2);
        }
      }

      await updateTaskSortNo({
        variables: {
          id: info.item!.id,
          input: {
            sortNoInList: sortNo,
            versionNo: info.item!.versionNo,
          },
        },
        // Memo: 以下のコードを有効にすると、タスクリストでドラッグ操作した際に、タスクが表示されなくなるケースがあったため、コメントアウトしている。
        // optimisticResponse: {
        //   __typename: 'Mutation',
        //   updateTaskSortNoInList: Object.assign(
        //     {
        //       __typename: 'Task',
        //     },
        //     info.item,
        //     { sortNoInList: sortNo }
        //   ),
        // },
      });
    },
    [props.teamId, tasks, updateTaskSortNo, props.searchCondition?.sortKey, setShowCanNotDragModal]
  );

  if (!data) {
    return <></>;
  }
  if (logimMemberResult.loading || !logimMemberResult.data?.me) {
    return <></>;
  }
  if (projectResult.loading || !projectResult.data) {
    return <></>;
  }
  if (organizationLoading || !organizationData) {
    return <></>;
  }

  return (
    <TaskListWrapper
      isFavoritePage={location.hash.indexOf('/my/favorite-project/') !== -1}
      isAssignedPage={location.hash.indexOf('/my/assigned-project/') !== -1}>
      <TopMenu>
        <TaskAdd
          setShowNewTask={setShowNewTaskTop}
          onClickRegisterDetail={() => setShowNewTaskDetailModal(true)}
          me={logimMemberResult.data.me}
        />
      </TopMenu>
      <Header
        organization={projectResult.data!.project!.team.organization}
        teamId={props.teamId}
        projectId={props.projectId}
        searchCondition={props.searchCondition}
        isFavoritePage={location.hash.indexOf('/my/favorite-project/') !== -1}
        isAssignedPage={location.hash.indexOf('/my/assigned-project/') !== -1}
      />
      {showNewTaskTop && (
        <TaskSummaryForNewTask
          organization={projectResult.data!.project!.team.organization}
          setShowNewTask={setShowNewTaskTop}
          loginMember={logimMemberResult.data!.me!}
          showTeamName={false}
          showProjectName={false}
          setShowCreateTasksLoading={setShowCreateTasksLoading}
        />
      )}
      {tasks.length === 0 ? (
        <View
          style={{
            height: 'calc(100% - 150px)',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Image
            source={{ uri: noData02 }}
            resizeMode="contain"
            style={{
              width: 300,
              height: 200,
              opacity: 0.7,
            }}
          />
          <Typography
            variant={TypographyType.Normal}
            style={{ color: themeContext.colors.description }}>
            さあ、タスクを作成してみましょう
          </Typography>
        </View>
      ) : (
        <GlobalDragContextProvider>
          <Spinner loading={showCreateTasksLoading} />
          <VirtualizedFlatList
            items={tasks}
            style={{
              height:
                location.hash.indexOf('/my/favorite-project/') !== -1 ||
                location.hash.indexOf('/my/assigned-project/') !== -1
                  ? 'calc(100vh - 280px - 70px)'
                  : 'calc(100vh - 280px)',
            }}
            renderItem={renderItem}
            getKey={getKey}
            itemHeight={43}
            onPress={onPress}
            virticalDraggable={true}
            onDrop={onDrop}
            afterLastItemElement={
              showNewTaskBottom ? (
                <TaskSummaryForNewTask
                  organization={projectResult.data!.project!.team.organization}
                  setShowNewTask={setShowNewTaskBottom}
                  loginMember={logimMemberResult.data!.me!}
                  showTeamName={false}
                  showProjectName={false}
                  createWithBottom={true}
                  setShowCreateTasksLoading={setShowCreateTasksLoading}
                />
              ) : (
                <TaskAdd setShowNewTask={setShowNewTaskBottom} me={logimMemberResult.data.me} />
              )
            }
            onEndReached={() => {
              if ((data?.searchTasks?.length ?? 0) < pageSize) {
                return;
              }
              fetchMore({
                variables: {
                  offset: data!.searchTasks!.length,
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev;
                  return Object.assign({}, prev, {
                    searchTasks: [
                      ...(prev.searchTasks || []),
                      ...(fetchMoreResult.searchTasks || []),
                    ],
                  });
                },
              });
            }}
          />
        </GlobalDragContextProvider>
      )}
      <AlertCanNotDragModal
        showModal={showCanNotDragModal}
        onCloseModal={() => setShowCanNotDragModal(false)}
      />
      <AddTaskDetailModal
        project={projectResult!.data!.project!}
        showModal={showNewTaskDetailModal}
        onCloseModal={() => setShowNewTaskDetailModal(false)}
      />
    </TaskListWrapper>
  );
});

interface ITaskListParam {
  teamId: string;
  projectId: string;
  organizationId: string;
}

const TaskList = () => {
  const params = useParams<ITaskListParam>();
  const { search } = useLocation();
  const searchCondition = queryString.parse(search);
  return (
    <Container>
      <TaskListInner
        organizationId={params.organizationId}
        teamId={params.teamId}
        projectId={params.projectId}
        searchCondition={searchCondition}
      />
    </Container>
  );
};

export default React.memo(TaskList);
