import React, { createContext, useContext, useEffect, useState } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../../../theme';
import HorizontalMenu from '../../../organisms/horizontal-menu';
import SelectButton from '../../../../../presentational/molecules/select-button';
import SearchTaskList from '../../../organisms/search-task-list';
import { Route, Switch, useHistory, useLocation } from 'react-router';
import { LoginUserContext } from '../../../../../../modules/auth/LoginUserContext';
import {
  Organization,
  Plan,
  Project,
  ProjectCompleteFilter,
  Task,
  TaskCompleteFilter,
  Team,
  useOrganizationClientsQuery,
  useOrganizationMembersQuery,
  useOrganizationQuery,
  useOrganizationTeamsQuery,
  useTeamProjectsForSelectorQuery,
  useTeamProjectsQuery,
  useTeamQuery,
} from '../../../../../../graphql/api/API';
import moment from 'moment-timezone';
import Typography, { TypographyType } from '../../../../../presentational/atoms/typography';
import Form from '../../../../../presentational/atoms/form';
import Input, { ListValueMap } from '../../../../../presentational/atoms/input';
import Icon from '../../../../../presentational/atoms/icon';
import Avatar from '../../../../../presentational/atoms/avatar';
import queryString from 'query-string';
import MultiPickerFilter from '../../../../../presentational/atoms/multi-picker-filter';
import SearchIcon from '../../../../../presentational/molecules/image-icon/search';
import EditableText from '../../../../../presentational/atoms/editable-text';
import Button from '../../../../../presentational/atoms/button';
import when from '../../../../../../lang-extention/When';
import Checkbox from '../../../../../presentational/atoms/checkbox';

const Container = styled.View`
  height: calc(100vh - 57px);
  display: flex;
  flex-direction: row;
`;

const MainAreaContainer = styled.View`
  flex: 1;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.4s;
  marginright: 50px;
  z-index: 2;
`;
// 本当はoverflow-xはvisibleにしたいのだが、CSSの仕様で、overflow-yを指定した場合には、xの挙動もそちらで上書きされるため実現できない

const MainAreaHeader = styled.View`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
  padding: 0.2rem 1rem;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.4s;
  position: relative;
  z-index: 10;
`;

const MainAreaHeaderTop = styled.View`
  display: flex;
  padding: 0.5rem 0;
  z-index: 2;
`;

const MainAreaHeaderBottom = styled.View`
  display: flex;
  width: 100%;
  height: 23px;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0.2rem 0;
  margin-top: 0.2rem;
`;

const FilterArea = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
`;

const FilterElementsContainer = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.2rem 0;
  margin: 0 0.2rem;
`;

const FilterElements = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export type TaskFilterExpireDate = 'today' | '3days' | 'week';

export interface TaskFilter {
  expire: TaskFilterExpireDate | null;
  taskStatusIds: string[];
  assignerIds: string[];
  showCompleteTask: string[];
}

export const TaskFilterContext = createContext([
  { expire: null, taskStatusIds: [], assignerIds: [], showCompleteTask: [] } as TaskFilter,
  (value: TaskFilter) => {},
]);

const TaskFilterContextProvider = (props: any) => {
  const [filter, setFilter] = useState<TaskFilter>({
    expire: null,
    taskStatusIds: [],
    assignerIds: [],
    showCompleteTask: ['incomplete'],
  });
  return (
    <TaskFilterContext.Provider value={[filter, setFilter]}>
      {props.children}
    </TaskFilterContext.Provider>
  );
};

export function matchTaskComplete(task: Task, taskFilter: TaskFilter): boolean {
  if (taskFilter.showCompleteTask.length === 0) {
    return true;
  }
  if (
    taskFilter.showCompleteTask.filter((elem) => elem === 'complete').length > 0 &&
    task.completeDateTime != null
  ) {
    return true;
  }
  if (
    taskFilter.showCompleteTask.filter((elem) => elem === 'incomplete').length > 0 &&
    task.completeDateTime == null
  ) {
    return true;
  }
  return false;
}

export function matchExpireFilter(task: Task, taskFilter: TaskFilter): boolean {
  if (taskFilter.expire !== null) {
    if (task!.scheduledEndDateTime !== null) {
      const endDateTime = moment(task!.scheduledEndDateTime);
      if (
        taskFilter.expire === 'today' &&
        endDateTime.format('YYYYMMDD') === moment().format('YYYYMMDD')
      ) {
        return true;
      }
      if (taskFilter.expire === '3days' && endDateTime.diff(moment(), 'days') <= 3) {
        return true;
      }
      if (taskFilter.expire === 'week' && endDateTime.diff(moment(), 'days') <= 7) {
        return true;
      }
    }
    return false;
  }
  return true;
}

export function matchTaskStatus(task: Task, taskFilter: TaskFilter): boolean {
  if (taskFilter.taskStatusIds.length === 0) {
    return true;
  }
  return taskFilter.taskStatusIds.indexOf(task!.taskStatus!.id!) !== -1;
}

export function matchTaskAssigner(task: Task, taskFilter: TaskFilter): boolean {
  if (taskFilter.assignerIds.length === 0) {
    // 絞り込みなしなら全部表示
    return true;
  }

  if (task.assignees.length === 0) {
    // タスクの担当者がいなければそのタスクは非表示
    return taskFilter.assignerIds.includes('empty');
  }

  // 絞り込みがある場合には、その絞り込みに合致するタスクを表示
  return (
    task.assignees.filter((info) => taskFilter.assignerIds.indexOf(info.member!.id!) !== -1)
      .length > 0
  );
}

const progressDeviationRate = [
  {
    label: '指定なし',
    value: 0,
    from: null,
    to: null,
  },
  {
    label: '予定よりも順調(見積の乖離率 90%以内)',
    value: 1,
    from: null,
    to: 90,
  },
  {
    label: 'ほぼ予定通り(見積の乖離率 90〜110%)',
    value: 2,
    from: 90,
    to: 109,
  },
  {
    label: '予定より遅れている(見積の乖離率 120%〜)',
    value: 3,
    from: 120,
    to: null,
  },
] as any;

interface ITeamSelectorProps {
  teamId: string | null;
  setTeamId: (value: string | null) => void;
}

const TeamSelector = React.memo((props: ITeamSelectorProps) => {
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const { data: teamsData, loading: teamsLoading } = useOrganizationTeamsQuery({
    variables: {
      organizationId: loginUser!.organizationId,
    },
    fetchPolicy: 'network-only',
  });

  if (teamsLoading) {
    return <></>;
  }

  return (
    <Form style={{ width: 300 }}>
      <Input
        name={'team'}
        label={'チーム'}
        type={'picker'}
        containerStyle={{ marginBottom: 0 }}
        isSearchable={true}
        value={props.teamId || ''}
        pickerItems={[{ label: '指定なし', value: null } as any].concat(
          teamsData?.organizationTeams?.map((team) => {
            return {
              label: team!.name,
              value: team!.id!,
            };
          })
        )}
        onChange={(value) => {
          props.setTeamId(value as any);
        }}
      />
      <View style={{ height: 20 }} />
    </Form>
  );
});

interface IClientSelectorProps {
  organization: Organization;
  clientId: string | null;
  setClientId: (value: string | null) => void;
}

const ClientSelector = React.memo((props: IClientSelectorProps) => {
  const { data, loading } = useOrganizationClientsQuery({
    variables: {
      organizationId: props.organization.id!,
    },
    fetchPolicy: 'network-only',
  });

  return (
    <Form style={{ width: 300 }}>
      <Input
        name={'client'}
        label={'取引先'}
        type={'picker'}
        containerStyle={{ marginBottom: 0 }}
        isSearchable={true}
        value={props.clientId || ''}
        pickerItems={[{ label: '指定なし', value: null } as any].concat(
          data?.organizationClients?.map((client) => {
            return {
              label: client!.name,
              value: client!.id!,
            };
          })
        )}
        onChange={(value) => {
          props.setClientId(value as any);
        }}
      />
      <View style={{ height: 20 }} />
    </Form>
  );
});

interface IProjectSelectorProps {
  organization: Organization;
  teamId: string | null;
  projectId: string | null;
  setProjectId: (value: string | null) => void;
}

const ProjectSelector = React.memo((props: IProjectSelectorProps) => {
  const [isIncludeCompletedProject, setIncludeCompletedProject] = useState(false);
  const { data: projectsData, loading: projectsLoading } = useTeamProjectsForSelectorQuery({
    variables: {
      //@ts-ignore
      teamId: props.teamId,
      input: {
        completeCondition: ProjectCompleteFilter.Incomplete,
      },
    },
    fetchPolicy: 'network-only',
    skip: !props.teamId,
  });

  useEffect(() => {
    if (
      projectsData?.teamProjectsForSelector?.filter((project) => project!.teamId === props.teamId).length ===
      0
    ) {
      props.setProjectId(null);
    }
  }, [props.teamId, isIncludeCompletedProject]);

  return (
    <Form style={{ width: 300 }}>
      <Input
        name={'project'}
        label={'プロジェクト'}
        type={'picker'}
        containerStyle={{ marginBottom: 0 }}
        isSearchable={true}
        value={props.projectId || ''}
        pickerItems={[{ label: '指定なし', value: null } as any].concat(
          projectsData?.teamProjectsForSelector
            ?.filter((project) => {
              if (isIncludeCompletedProject) {
                return true;
              }
              return project!.complete === false;
            })
            .map((project) => {
              return {
                label: project!.name,
                value: project!.id!,
              };
            })
        )}
        onChange={(value) => {
          props.setProjectId(value as any);
        }}
      />
      <View style={{ height: 20 }}>
        <View style={{ flexDirection: 'row', marginTop: 5 }}>
          <Checkbox value={isIncludeCompletedProject} onValueChange={setIncludeCompletedProject} />
          <TouchableOpacity onPress={() => setIncludeCompletedProject(!isIncludeCompletedProject)}>
            <Typography variant={TypographyType.Description} style={{ marginLeft: 5 }}>
              完了したプロジェクトを含む
            </Typography>
          </TouchableOpacity>
        </View>
      </View>
    </Form>
  );
});

interface ISearchConditionProps {
  searchCondition: any;
}

const SearchCondition = (props: ISearchConditionProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const history = useHistory();
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [searchText, setSearchText] = useState<string>(
    (props.searchCondition?.text as string) || (props.searchCondition?.title as string) || ''
  );
  const [completeCondition, setCompleteCondition] = useState<TaskCompleteFilter>(
    props.searchCondition.completeCondition || TaskCompleteFilter.Incomplete
  );
  const [selectedKey, setSelectedKey] = useState<string[]>(
    props.searchCondition.completeCondition
      ? when(props.searchCondition.completeCondition)
          .on(
            (v) => v === TaskCompleteFilter.Complete,
            () => ['complete']
          )
          .on(
            (v) => v === TaskCompleteFilter.Incomplete,
            () => ['incomplete']
          )
          .otherwise(() => ['complete', 'incomplete'])
      : ['incomplete']
  );
  const [assignedMemberIdList, setAssignedMemberIdList] = useState<string[]>(
    props.searchCondition.assignedMemberIdList
      ? Array.isArray(props.searchCondition.assignedMemberIdList)
        ? props.searchCondition.assignedMemberIdList
        : [props.searchCondition.assignedMemberIdList]
      : []
  );
  const [scheduledStartDateTimeFrom, setScheduledStartDateTimeFrom] =
    useState<moment.Moment | null>(
      props.searchCondition.scheduledStartDateTimeFrom
        ? moment(props.searchCondition.scheduledStartDateTimeFrom)
        : null
    );
  const [scheduledStartDateTimeTo, setScheduledStartDateTimeTo] = useState<moment.Moment | null>(
    props.searchCondition.scheduledStartDateTimeTo
      ? moment(props.searchCondition.scheduledStartDateTimeTo)
      : null
  );
  const [scheduledEndDateTimeFrom, setScheduledEndDateTimeFrom] = useState<moment.Moment | null>(
    props.searchCondition.scheduledEndDateTimeFrom
      ? moment(props.searchCondition.scheduledEndDateTimeFrom)
      : null
  );
  const [scheduledEndDateTimeTo, setScheduledEndDateTimeTo] = useState<moment.Moment | null>(
    props.searchCondition.scheduledEndDateTimeTo
      ? moment(props.searchCondition.scheduledEndDateTimeTo)
      : null
  );
  const [estimageWorkingTimeSecFrom, setEstimageWorkingTimeSecFrom] = useState<number | null>(
    props.searchCondition.estimateWorkingTimeSecFrom || null
  );
  const [estimageWorkingTimeSecTo, setEstimageWorkingTimeSecTo] = useState<number | null>(
    props.searchCondition.estimateWorkingTimeSecTo || null
  );
  const [workingTimeSecFrom, setWorkingTimeSecFrom] = useState<number | null>(
    props.searchCondition.workingTimeSecFrom || null
  );
  const [workingTimeSecTo, setWorkingTimeSecTo] = useState<number | null>(
    props.searchCondition.workingTimeSecTo || null
  );
  const [deviationRateFrom, setDeviationRateFrom] = useState<number | null>(
    props.searchCondition.deviationRateFrom || null
  );
  const [deviationRateTo, setDeviationRateTo] = useState<number | null>(
    props.searchCondition.deviationRateTo || null
  );
  const [teamId, setTeamId] = useState<string | null>(props.searchCondition.teamIdList || null);
  const [projectId, setProjectId] = useState<string | null>(
    props.searchCondition.projectIdList || null
  );
  const [clientId, setClientId] = useState<string | null>(
    props.searchCondition.clientIdList || null
  );

  const { loading: organizationMemberLoading, data: organizationMemberData } =
    useOrganizationMembersQuery({
      variables: {
        organizationId: loginUser!.organizationId!,
      },
    });
  const { loading: organizationLoading, data: organizationData } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId,
    },
  });
  const { data: myTeamData, loading: myTeamLoading } = useTeamQuery({
    variables: {
      //@ts-ignore
      id: loginUser!.myTeamId,
    },
    fetchPolicy: 'network-only',
    skip: !loginUser!.myTeamId,
  });

  useEffect(() => {
    if (organizationMemberData?.organizationMembers && props.searchCondition.assignedMemberIdList) {
      setAssignedMemberIdList(props.searchCondition.assignedMemberIdList);
    }
  }, [organizationMemberData]);

  if (organizationMemberLoading || organizationLoading || myTeamLoading) {
    return <></>;
  }

  return (
    <View>
      <View style={{ flexDirection: 'row', flexWrap: 'wrap', zIndex: 2, alignItems: 'center' }}>
        <FilterElementsContainer style={{ zIndex: 2, marginBottom: 12 }}>
          <FilterElements>
            <Form style={{ width: 300, flexDirection: 'column' }}>
              <Input
                name={'title'}
                placeholder={'タスクのタイトル'}
                value={searchText}
                inputstyle={{ borderWidth: 0 }}
                inputContainerStyle={{
                  marginHorizontal: 10,
                  backgroundColor: themeContext.colors.baseColor,
                  shadowOffset: {
                    width: -1,
                    height: -1,
                  },
                  shadowOpacity: 0.1,
                  elevation: 2,
                  borderWidth: 1,
                  borderRadius: 20,
                  borderColor: themeContext.colors.separator,
                }}
                onChange={(value: string) => {
                  setSearchText(value);
                }}
              />
            </Form>
          </FilterElements>
        </FilterElementsContainer>

        {(organizationData?.organization?.plan.code === Plan.Business ||
          organizationData?.organization?.plan.code === Plan.Enterprise) && (
          <FilterElementsContainer style={{ zIndex: 2, marginBottom: 25 }}>
            <Text
              style={{
                fontSize: 10,
                color: themeContext.colors.description,
                textAlign: 'center',
                marginBottom: 2,
              }}>
              担当者
            </Text>
            <FilterElements>
              {(organizationMemberData?.organizationMembers || []).length <= 5 ? (
                <SelectButton
                  contents={(organizationMemberData?.organizationMembers || [])
                    .slice()
                    .sort((a, b) => {
                      if (a!.id === loginUser!.id) {
                        // ログインしているユーザー自身を先頭に表示する
                        return 1;
                      }
                      return (a!.name as any) - (b!.name as any);
                    })
                    .map((member: any) => {
                      return {
                        key: member.id.toString(),
                        content: (
                          <Avatar
                            size={22}
                            name={member.name}
                            imageUrl={member.profileImageUrl}
                            showToolTip={true}
                            toolTipKey={member.id.toString()}
                          />
                        ),
                      };
                    })}
                  multiSelect={true}
                  values={assignedMemberIdList}
                  onChange={(value) => {
                    setAssignedMemberIdList(value);
                  }}
                />
              ) : (
                <MultiPickerFilter
                  placeHolder="担当者を絞り込む"
                  containerStyle={{ width: '6rem' }}
                  emptyPickerItem={{
                    label: '未割り当て',
                    value: 'empty',
                  }}
                  value={assignedMemberIdList}
                  pickerItems={organizationMemberData?.organizationMembers
                    ?.slice()
                    .sort((a, b) => {
                      if (a!.id === loginUser!.id) {
                        // ログインしているユーザー自身を先頭に表示する
                        return 1;
                      }
                      return (a!.name as any) - (b!.name as any);
                    })
                    .map((member) => {
                      return {
                        label: member!.name!,
                        subLabel: member!.mailAddress!,
                        value: member!.id!,
                        imageUrl: member!.profileImageUrl,
                      };
                    })}
                  onChange={(items) => {
                    setAssignedMemberIdList(items.map((item) => item.value));
                  }}
                  onBlur={(items) => {
                    setAssignedMemberIdList(items.map((item) => item.value));
                  }}
                />
              )}
            </FilterElements>
          </FilterElementsContainer>
        )}
        <FilterElementsContainer style={{ zIndex: 2, marginBottom: 25 }}>
          <Text
            style={{
              fontSize: 10,
              color: themeContext.colors.description,
              textAlign: 'center',
              marginBottom: 2,
            }}>
            ステータス
          </Text>
          <FilterElements>
            <SelectButton
              textStyle={{ maxWidth: 70 }}
              contents={[
                {
                  content: '未完了',
                  key: 'incomplete',
                },
                {
                  content: '完了',
                  key: 'complete',
                },
              ]}
              values={selectedKey}
              multiSelect={true}
              onChange={(value) => {
                setSelectedKey(value);
                if (value.length === 1) {
                  if (value[0] === 'complete') {
                    setCompleteCondition(TaskCompleteFilter.Complete);
                  } else {
                    setCompleteCondition(TaskCompleteFilter.Incomplete);
                  }
                } else {
                  setCompleteCondition(TaskCompleteFilter.Both);
                }
              }}
            />
          </FilterElements>
        </FilterElementsContainer>
        <FilterElementsContainer style={{ zIndex: 2, marginBottom: 25 }}>
          <Text
            style={{
              fontSize: 10,
              color: themeContext.colors.description,
              textAlign: 'center',
              marginBottom: 2,
            }}>
            開始予定日
          </Text>
          <FilterElements
            style={{
              borderColor: themeContext.colors.separator,
              borderWidth: 1,
              borderRadius: 7,
              paddingHorizontal: 10,
            }}>
            <EditableText
              value={scheduledStartDateTimeFrom}
              type={'date-picker'}
              containerStyle={{ minWidth: 30, alignItems: 'center' }}
              onChange={(value) => {
                setScheduledStartDateTimeFrom(
                  value ? (value as moment.Moment).startOf('day') : null
                );
              }}
            />
            <Text
              style={{
                fontSize: 10,
                color: themeContext.colors.description,
                textAlign: 'center',
                marginBottom: 2,
                minWidth: 20,
              }}>
              〜
            </Text>
            <EditableText
              value={scheduledStartDateTimeTo}
              type={'date-picker'}
              containerStyle={{ minWidth: 30, alignItems: 'center' }}
              onChange={(value) => {
                setScheduledStartDateTimeTo(value ? (value as moment.Moment).endOf('day') : null);
              }}
            />
          </FilterElements>
        </FilterElementsContainer>
        <FilterElementsContainer style={{ zIndex: 2, marginBottom: 25 }}>
          <Text
            style={{
              fontSize: 10,
              color: themeContext.colors.description,
              textAlign: 'center',
              marginBottom: 2,
            }}>
            〆切日
          </Text>
          <FilterElements
            style={{
              borderColor: themeContext.colors.separator,
              borderWidth: 1,
              borderRadius: 7,
              paddingHorizontal: 10,
            }}>
            <EditableText
              value={scheduledEndDateTimeFrom}
              type={'date-picker'}
              containerStyle={{ minWidth: 30, alignItems: 'center' }}
              onChange={(value) => {
                setScheduledEndDateTimeFrom(value ? (value as moment.Moment).startOf('day') : null);
              }}
            />
            <Text
              style={{
                fontSize: 10,
                color: themeContext.colors.description,
                textAlign: 'center',
                marginBottom: 2,
                minWidth: 20,
              }}>
              〜
            </Text>
            <EditableText
              value={scheduledEndDateTimeTo}
              type={'date-picker'}
              containerStyle={{ minWidth: 30, alignItems: 'center' }}
              onChange={(value) => {
                setScheduledEndDateTimeTo(value ? (value as moment.Moment).endOf('day') : null);
              }}
            />
          </FilterElements>
        </FilterElementsContainer>
        <FilterElementsContainer style={{ zIndex: 2, marginBottom: 25 }}>
          <Text
            style={{
              fontSize: 10,
              color: themeContext.colors.description,
              textAlign: 'center',
              marginBottom: 2,
            }}>
            見積時間
          </Text>
          <FilterElements
            style={{
              borderColor: themeContext.colors.separator,
              borderWidth: 1,
              borderRadius: 7,
              paddingHorizontal: 10,
            }}>
            <EditableText
              value={estimageWorkingTimeSecFrom || null}
              type={'time-picker'}
              style={{ minWidth: 30, alignItems: 'center' }}
              textStyle={{ textAlign: 'center' }}
              onChange={(value) => {
                setEstimageWorkingTimeSecFrom(value as number);
              }}
            />
            <Text
              style={{
                fontSize: 10,
                color: themeContext.colors.description,
                textAlign: 'center',
                marginBottom: 2,
                minWidth: 20,
              }}>
              〜
            </Text>
            <EditableText
              value={estimageWorkingTimeSecTo || null}
              type={'time-picker'}
              style={{ minWidth: 30, alignItems: 'center' }}
              textStyle={{ textAlign: 'center' }}
              onChange={(value) => {
                setEstimageWorkingTimeSecTo(value as number);
              }}
            />
          </FilterElements>
        </FilterElementsContainer>
        <FilterElementsContainer style={{ zIndex: 2, marginBottom: 25 }}>
          <Text
            style={{
              fontSize: 10,
              color: themeContext.colors.description,
              textAlign: 'center',
              marginBottom: 2,
            }}>
            作業時間
          </Text>
          <FilterElements
            style={{
              borderColor: themeContext.colors.separator,
              borderWidth: 1,
              borderRadius: 7,
              paddingHorizontal: 10,
            }}>
            <EditableText
              value={workingTimeSecFrom || null}
              type={'time-picker'}
              style={{ minWidth: 30, alignItems: 'center' }}
              textStyle={{ textAlign: 'center' }}
              onChange={(value) => {
                setWorkingTimeSecFrom(value as number);
              }}
            />
            <Text
              style={{
                fontSize: 10,
                color: themeContext.colors.description,
                textAlign: 'center',
                marginBottom: 2,
                minWidth: 20,
              }}>
              〜
            </Text>
            <EditableText
              value={workingTimeSecTo || null}
              type={'time-picker'}
              style={{ minWidth: 30, alignItems: 'center' }}
              textStyle={{ textAlign: 'center' }}
              onChange={(value) => {
                setWorkingTimeSecTo(value as number);
              }}
            />
          </FilterElements>
        </FilterElementsContainer>
        <FilterElementsContainer>
          <FilterElements
            style={{
              borderColor: themeContext.colors.separator,
            }}>
            <Form style={{ minWidth: 400 }}>
              <Input
                name={'deviationRateFrom'}
                label={'見積の乖離率'}
                type={'picker'}
                containerStyle={{ marginBottom: 0 }}
                pickerItems={progressDeviationRate}
                value={
                  progressDeviationRate.findIndex(
                    (preset: any) => preset.from == deviationRateFrom
                  ) || 0
                }
                initialValue={
                  progressDeviationRate.findIndex(
                    (preset: any) => preset.from == deviationRateFrom
                  ) || 0
                }
                onChange={(value) => {
                  setDeviationRateFrom(progressDeviationRate[Number(value)].from);
                  setDeviationRateTo(progressDeviationRate[Number(value)].to);
                }}
              />
              <View style={{ height: 20 }} />
            </Form>
          </FilterElements>
        </FilterElementsContainer>
        <View style={{ flexDirection: 'row' }}>
          {(organizationData!.organization!.plan.code === Plan.Business ||
            organizationData!.organization!.plan.code === Plan.Enterprise) && (
            <FilterElementsContainer>
              <FilterElements>
                <TeamSelector teamId={teamId} setTeamId={setTeamId} />
              </FilterElements>
            </FilterElementsContainer>
          )}
          <FilterElementsContainer>
            <FilterElements>
              <ClientSelector
                organization={organizationData!.organization!}
                clientId={clientId}
                setClientId={setClientId}
              />
            </FilterElements>
          </FilterElementsContainer>
          <FilterElementsContainer>
            <FilterElements>
              <ProjectSelector
                organization={organizationData!.organization!}
                teamId={
                  organizationData!.organization!.plan.code === Plan.Business ||
                  organizationData!.organization!.plan.code === Plan.Enterprise
                    ? teamId || null
                    : myTeamData!.team?.id || null
                }
                projectId={projectId}
                setProjectId={setProjectId}
              />
            </FilterElements>
          </FilterElementsContainer>
        </View>
        <FilterElementsContainer>
          <Form style={{ width: 'auto', marginLeft: 10 }}>
            <Button
              text="検索する"
              style={{ padding: 10, height: 35, marginTop: 15 }}
              textStyle={{ fontSize: 14, lineHeight: 16 }}
              leftInnerElement={<SearchIcon size={18} reverse={true} />}
              onPress={() => {
                history.push(
                  queryString.stringifyUrl({
                    url: `/app/${loginUser!.organizationId}/search-task/list/`,
                    query: {
                      title: searchText,
                      teamIdList: teamId ? [teamId] : [],
                      projectIdList: projectId ? [projectId] : [],
                      clientIdList: clientId ? [clientId] : [],
                      taskStatusIdList: [],
                      assignedMemberIdList: assignedMemberIdList,
                      scheduledStartDateTimeFrom: scheduledStartDateTimeFrom?.toISOString(),
                      scheduledStartDateTimeTo: scheduledStartDateTimeTo?.toISOString(),
                      scheduledEndDateTimeFrom: scheduledEndDateTimeFrom?.toISOString(),
                      scheduledEndDateTimeTo: scheduledEndDateTimeTo?.toISOString(),
                      estimateWorkingTimeSecFrom: estimageWorkingTimeSecFrom,
                      estimateWorkingTimeSecTo: estimageWorkingTimeSecTo,
                      workingTimeSecFrom: workingTimeSecFrom,
                      workingTimeSecTo: workingTimeSecTo,
                      deviationRateFrom: deviationRateFrom,
                      deviationRateTo: deviationRateTo,
                      completeCondition: completeCondition,
                    } as any,
                  })
                );
              }}
            />
            <View style={{ height: 20 }} />
          </Form>
        </FilterElementsContainer>
      </View>
    </View>
  );
};

interface IProjectContainerInnerProps {}

const ProjectContainerInner = React.memo((props: IProjectContainerInnerProps) => {
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const { search } = useLocation();
  const searchCondition = queryString.parse(search);

  return (
    <>
      <TaskFilterContextProvider>
        <MainAreaHeader>
          <MainAreaHeaderTop>
            <SearchCondition searchCondition={searchCondition} />
          </MainAreaHeaderTop>
          <MainAreaHeaderBottom>
            <HorizontalMenu
              style={{ marginLeft: 10 }}
              menus={[
                {
                  title: 'リスト',
                  path: `/app/${loginUser!.organizationId}/search-task/list/`,
                },
              ]}
            />
          </MainAreaHeaderBottom>
        </MainAreaHeader>
        <Switch>
          <Route path={'/app/:organizationId/search-task/list/'} component={SearchTaskList} />
          {/* <Route path={'/app/:organizationId/search-task/board/'} component={ProjectBoard} /> */}
        </Switch>
      </TaskFilterContextProvider>
    </>
  );
});

const AppSearchTasksPage = () => {
  return (
    <Container>
      <MainAreaContainer>
        <ProjectContainerInner />
      </MainAreaContainer>
    </Container>
  );
};

export default React.memo(AppSearchTasksPage);
