import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { type SetupIntentResult, loadStripe } from '@stripe/stripe-js';
import Constants from 'expo-constants';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import {
  BillingType,
  type Contract,
  ContractDocument,
  ContractPeriod,
  CreditCardDocument,
  type Invoice,
  InvoicesDocument,
  MemberStatus,
  type Organization,
  OrganizationDocument,
  Plan,
  UpcomingInvoiceDocument,
  useChangeContractPeriodMutation,
  useChangePlanMutation,
  useConfirmAmountAfterLicenceCountChangeLazyQuery,
  useContractQuery,
  useCreditCardLazyQuery,
  useMemberInvitationsQuery,
  useOrganizationMembersQuery,
  useOrganizationQuery,
  usePaymentSecretQuery,
  usePlanProductsMonthlyQuery,
  useRegisterCreditCardMutation,
  useUpcomingInvoiceQuery,
  useUpdateBillToMailAddressMutation,
  useUpdateLicenceCountMutation,
  useUpdateProjectMutation,
} from '../../../../../../../graphql/api/API';
import { contract } from '../../../../../../../graphql/documents/queries';
import when from '../../../../../../../lang-extention/When';
import { LoginUserContext } from '../../../../../../../modules/auth/LoginUserContext';
import TimeUtil from '../../../../../../../util/TimeUtil';
import Button from '../../../../../../presentational/atoms/button';
import Checkbox from '../../../../../../presentational/atoms/checkbox';
import Form from '../../../../../../presentational/atoms/form';
import Input from '../../../../../../presentational/atoms/input';
import Link from '../../../../../../presentational/atoms/link';
import Separator from '../../../../../../presentational/atoms/separator';
import Spinner from '../../../../../../presentational/atoms/spinner';
import Typography, { TypographyType } from '../../../../../../presentational/atoms/typography';
import CaretDownIcon from '../../../../../../presentational/molecules/image-icon/caret-down';
import Modal from '../../../../../../presentational/molecules/modal';
import type { IThemePart } from '../../../../../../theme';
import SettingsElement from '../../../../organisms/settings-element';

const Container = styled.View`
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
`;

const ContractPeriodName = {
  Monthly: '月契約',
  Yearly: '年間契約',
};

const CardForm = styled.View`
  margin: 10px 0 20px 0;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  padding: 10px 14px;
  font-size: 1em;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
  width: 100%;
`;

const StripeCardFormContainer = styled.View`
  width: 50vw;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const CardFormElement = styled.View`
  width: 100%;
  padding: 5px 0;
`;

const BillingTypeName = {
  CreditCard: 'クレジットカード',
  Invoice: '請求書払い',
  NonRegistered: 'お支払い方法が登録されていません',
  AppleIap: 'AppleID決済',
};

const CreditCardRegisterForm = (props: {
  handleSubmit: () => void;
  contract: Contract;
  handleCancel: () => void;
  submitText: string;
}) => {
  const stripePromise = loadStripe(Constants.manifest!.extra!.stripe.token);

  return (
    <Elements stripe={stripePromise}>
      <StripeCreditCardRegisterForm
        contract={props.contract}
        handleCancel={props.handleCancel}
        handleSubmit={props.handleSubmit}
        submitText={props.submitText}
      />
    </Elements>
  );
};

const StripeCreditCardRegisterForm = (props: {
  handleSubmit: () => void;
  contract: Contract;
  handleCancel: () => void;
  submitText: string;
}) => {
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const themeContext: IThemePart = useContext(ThemeContext);
  const stripe = useStripe();
  const elements = useElements();
  const [cardNumberValid, setCardNumberValid] = useState(false);
  const [cvcValid, setCvcValid] = useState(false);
  const [expiryValid, setExpiryValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [registerCreditCardMutation] = useRegisterCreditCardMutation({
    refetchQueries: [
      {
        query: ContractDocument,
        variables: {
          organizationId: loginUser?.organizationId!,
        },
      },
      {
        query: CreditCardDocument,
        variables: {
          organizationId: loginUser?.organizationId!,
        },
      },
    ],
  });

  const { data: paymentSecretData, loading: paymentSecretLoading } = usePaymentSecretQuery({
    fetchPolicy: 'network-only',
  });

  const options = {
    style: {
      base: {
        color: '#424770',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
    showIcon: true,
  };

  useEffect(() => {
    setErrorMessage(null);
  }, []);

  return (
    <StripeCardFormContainer>
      <CardFormElement>
        <Typography variant={TypographyType.ElementiTitle}>カード番号</Typography>
        <CardForm>
          <CardNumberElement options={options} onChange={(e) => setCardNumberValid(e.complete)} />
        </CardForm>
      </CardFormElement>
      <CardFormElement>
        <Typography variant={TypographyType.ElementiTitle}>有効期限</Typography>
        <CardForm>
          <CardExpiryElement options={options} onChange={(e) => setExpiryValid(e.complete)} />
        </CardForm>
      </CardFormElement>
      <CardFormElement>
        <Typography variant={TypographyType.ElementiTitle}>CVC</Typography>
        <CardForm>
          <CardCvcElement options={options} onChange={(e) => setCvcValid(e.complete)} />
        </CardForm>
      </CardFormElement>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1,
        }}>
        <Button
          text={props.submitText}
          style={{ minWidth: 100 }}
          onPress={async () => {
            setErrorMessage(null);

            if (!stripe || !elements) {
              return;
            }
            const card = elements.getElement(CardNumberElement);
            if (!card) {
              return;
            }

            const result: SetupIntentResult = await stripe.confirmCardSetup(
              paymentSecretData?.paymentSecret!,
              {
                payment_method: {
                  card: card,
                },
              }
            );

            if (result.error?.message) {
              setErrorMessage(result.error.message);
              return;
            }

            await registerCreditCardMutation({
              variables: {
                contractId: props.contract.id!,
                input: {
                  paymentMethodId: result?.setupIntent?.payment_method!,
                  versionNo: props.contract.versionNo!,
                },
              },
            });

            await props.handleSubmit();
          }}
          isDisabled={!cardNumberValid || !cvcValid || !expiryValid || paymentSecretLoading}
        />
        {props.handleCancel && (
          <Button
            text={'キャンセル'}
            style={{
              minWidth: 100,
              marginRight: 10,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.primary }}
            disableValidate={true}
            onPress={async () => {
              props.handleCancel();
            }}
          />
        )}
      </View>
      {errorMessage && (
        <Typography
          variant={TypographyType.Description}
          style={{ color: themeContext.colors.error, marginTop: 10 }}>
          {errorMessage}
        </Typography>
      )}
    </StripeCardFormContainer>
  );
};

interface IPlanModalProps {
  isShow: boolean;
  contract: Contract;
  closeModal: () => void;
  onChangePlan: () => void;
}

const PlanModal = (props: IPlanModalProps) => {
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const themeContext: IThemePart = useContext(ThemeContext);
  const [upgraded, setUpgraded] = useState(false);
  const [changePlan] = useChangePlanMutation({
    refetchQueries: [
      {
        query: OrganizationDocument,
        variables: {
          id: loginUser?.organizationId,
        },
      },
    ],
  });

  const { loading: planProductsMonthlyLoading, data: planProductsMonthlyData } =
    usePlanProductsMonthlyQuery({
      fetchPolicy: 'network-only',
    });

  const handleSubmit = async () => {
    await changePlan({
      variables: {
        id: props.contract.id,
        input: {
          planId: planProductsMonthlyData?.planProductsMonthly?.find(
            (plan) =>
              plan?.code === Plan.Professional && plan.contractPreriod === ContractPeriod.Monthly
          )?.id!,
          versionNo: props.contract.versionNo! + 1, // 直前のMutationでバージョンアップしてしまうため、1をプラスする。これ以外の方法がうまくいかなかった
        },
      },
    });

    props.onChangePlan();
    props.closeModal();
  };

  useEffect(() => {
    setUpgraded(false);
  }, [props.isShow]);

  if (planProductsMonthlyLoading) {
    return <></>;
  }

  return (
    <Modal isShow={props.isShow} onClose={props.closeModal} title="Proプランへアップグレードする">
      <View style={{ width: '50vw', maxWidth: 550, paddingHorizontal: 10 }}>
        {!upgraded && (
          <>
            <Typography variant={TypographyType.Normal}>
              Proプランへアップグレードします。
            </Typography>
            {moment(props.contract?.freeTrialExpireDatetime).diff(moment(), 'days') < 0 ? (
              <View>
                <Typography
                  variant={TypographyType.Normal}
                  style={{ color: themeContext.colors.error }}>
                  すでに無料試用期間をご利用になっているため、{'\n'}
                  Proプランへアップグレードする際にご利用料金が発生します。{'\n'}
                  あらかじめご了承ください。
                </Typography>
                <CreditCardRegisterForm
                  contract={props.contract}
                  handleSubmit={handleSubmit}
                  handleCancel={() => props.closeModal()}
                  submitText="アップグレードする"
                />
              </View>
            ) : (
              <View>
                <Typography variant={TypographyType.Normal}>
                  アップグレード後、1ヶ月間の無料トライアル期間が付与されます。{'\n'}
                  無料トライアル期間終了後、お支払い方法を登録しない限りは請求は発生しませんので、ご安心ください。
                  {'\n'}
                </Typography>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                    zIndex: 1,
                    paddingVertical: 15,
                  }}>
                  <Button
                    text="アップグレードする"
                    style={{ minWidth: 100 }}
                    onPress={async () => {
                      await changePlan({
                        variables: {
                          id: props.contract.id,
                          input: {
                            planId: planProductsMonthlyData?.planProductsMonthly?.find(
                              (plan) =>
                                plan?.code === Plan.Professional &&
                                plan.contractPreriod === ContractPeriod.Monthly
                            )?.id!,
                            versionNo: props.contract.versionNo!,
                          },
                        },
                      });
                      props.onChangePlan();
                      setUpgraded(true);
                    }}
                  />
                  <Button
                    text={'キャンセル'}
                    style={{
                      minWidth: 100,
                      marginRight: 10,
                      backgroundColor: 'transparent',
                    }}
                    textStyle={{ color: themeContext.colors.primary }}
                    disableValidate={true}
                    onPress={() => {
                      props.closeModal();
                    }}
                  />
                </View>
              </View>
            )}
          </>
        )}
        {upgraded && (
          <>
            <View
              style={{
                flexDirection: 'column',
                alignItems: 'center',
              }}>
              <Typography variant={TypographyType.Normal}>
                Proプランへのアップグレードが完了しました。{'\n'}
                パワフルなProプランの機能を是非お楽しみください。
              </Typography>
              <Button
                text={'閉じる'}
                style={{
                  minWidth: 150,
                  marginVertical: 15,
                }}
                onPress={() => {
                  props.closeModal();
                }}
              />
            </View>
          </>
        )}
      </View>
    </Modal>
  );
};

interface IDowngradeToBasicPlanModalProps {
  isShow: boolean;
  contract: Contract;
  closeModal: () => void;
  onChangePlan: () => void;
}

const DowngradeToBasicPlanModal = (props: IDowngradeToBasicPlanModalProps) => {
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const themeContext: IThemePart = useContext(ThemeContext);
  const [downgraded, setDowngraded] = useState(false);
  const [changePlan] = useChangePlanMutation({
    refetchQueries: [
      {
        query: OrganizationDocument,
        variables: {
          id: loginUser?.organizationId,
        },
      },
    ],
  });
  const { loading: planProductsMonthlyLoading, data: planProductsMonthlyData } =
    usePlanProductsMonthlyQuery();

  useEffect(() => {
    setDowngraded(false);
  }, [props.isShow]);

  if (planProductsMonthlyLoading) {
    return <></>;
  }

  const basicPlanProduct = planProductsMonthlyData!.planProductsMonthly!.find(
    (plan) => plan?.code === Plan.Basic && plan.contractPreriod === ContractPeriod.Monthly
  );

  return (
    <Modal
      isShow={props.isShow}
      onClose={props.closeModal}
      title=" Basicプランへダウングレードする">
      <View style={{ width: '50vw', maxWidth: 550, paddingHorizontal: 10 }}>
        {!downgraded && (
          <>
            <Typography variant={TypographyType.Normal}>
              Basicプランへダウングレードします。
            </Typography>
            <View>
              <Typography variant={TypographyType.Normal}>
                即座にBasicプランに変更され、{'\n'}
                Proプランの機能がご利用できなくなりますのでご注意ください。{'\n'}
                Basicプランに変更すると、ご利用料金は発生しなくなります。{'\n\n'}
              </Typography>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  zIndex: 1,
                  paddingVertical: 15,
                }}>
                <Button
                  text="ダウングレードする"
                  style={{ minWidth: 100 }}
                  onPress={async () => {
                    await changePlan({
                      variables: {
                        id: props.contract.id,
                        input: {
                          planId: basicPlanProduct!.id!,
                          versionNo: props.contract.versionNo!,
                        },
                      },
                    });
                    props.onChangePlan();
                    setDowngraded(true);
                  }}
                />
                <Button
                  text={'キャンセル'}
                  style={{
                    minWidth: 100,
                    marginRight: 10,
                    backgroundColor: 'transparent',
                  }}
                  textStyle={{ color: themeContext.colors.primary }}
                  disableValidate={true}
                  onPress={() => {
                    props.closeModal();
                  }}
                />
              </View>
            </View>
          </>
        )}
        {downgraded && (
          <>
            <View
              style={{
                flexDirection: 'column',
                alignItems: 'center',
              }}>
              <Typography variant={TypographyType.Normal}>
                Basicプランへのダウングレードが完了しました。{'\n'}
                今後は無料でご利用頂けます。
              </Typography>
              <Button
                text={'閉じる'}
                style={{
                  minWidth: 150,
                  marginVertical: 15,
                }}
                onPress={() => {
                  props.closeModal();
                }}
              />
            </View>
          </>
        )}
      </View>
    </Modal>
  );
};

const BillToMailAddressModal = (props: {
  closeModal: () => void;
  billToMailAddress: string;
  contractId: string;
  versionNo: number;
}) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [billToMailAddress, setBillToMailAddress] = useState(props.billToMailAddress);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [updateBillToMailAddress] = useUpdateBillToMailAddressMutation({
    variables: {
      id: props.contractId,
      input: {
        billToMailAddress: billToMailAddress,
        versionNo: props.versionNo,
      },
    },
    update: (cache, result) => {
      cache.writeQuery({
        query: ContractDocument,
        data: { payment: result },
        variables: {
          organizationId: loginUser?.organizationId!,
        },
      });
    },
  });

  return (
    <Form style={{ marginTop: 10 }}>
      <Input
        name={'mailAddress'}
        label={'請求先メールアドレス'}
        initialValue={props.billToMailAddress}
        onChange={setBillToMailAddress}
        validate={{
          required: {
            value: true,
            message: 'メールアドレスを入力してください',
          },
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: '正しいメールアドレスを入力してください',
          },
        }}
      />
      <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1 }}>
        <Button
          text={'変更する'}
          style={{
            minWidth: 100,
            marginRight: 10,
          }}
          onPress={async () => {
            await updateBillToMailAddress();
            props.closeModal();
          }}
        />
        <Button
          text={'キャンセル'}
          style={{
            minWidth: 100,
            marginRight: 10,
            backgroundColor: 'transparent',
          }}
          textStyle={{ color: themeContext.colors.primary }}
          disableValidate={true}
          onPress={async () => {
            props.closeModal();
          }}
        />
      </View>
    </Form>
  );
};

const LicenceCountModal = (props: {
  closeModal: () => void;
  contract: Contract;
  upcomingInvoiceData: Invoice;
}) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [licenceCount, setLicenceCount] = useState(props.contract.licenceCount);
  const [confirmed, setConfirmed] = useState(false);
  const [modalState, setModalState] = useState<'INPUT' | 'CONFIRMING' | 'FINISHED'>('INPUT');
  const [updateLicenceCount] = useUpdateLicenceCountMutation({
    variables: {
      id: props.contract.id,
      input: {
        licenceCount: licenceCount,
        versionNo: props.contract.versionNo!,
      },
    },
    refetchQueries: [
      {
        query: UpcomingInvoiceDocument,
      },
      {
        query: InvoicesDocument,
      },
    ],
  });
  const [confirmAmount, { data: confirmAmountData, loading: confirmAmountLoading }] =
    useConfirmAmountAfterLicenceCountChangeLazyQuery({
      fetchPolicy: 'network-only',
    });

  return (
    <Form style={{ width: 380, marginTop: 10 }}>
      <Spinner loading={confirmAmountLoading} />
      {(modalState === 'CONFIRMING' || modalState === 'INPUT') && (
        <>
          <View style={{ display: 'flex', flexDirection: 'column' }}>
            {modalState === 'CONFIRMING' && (
              <Typography variant={TypographyType.Normal} style={{ paddingBottom: 20 }}>
                以下のように変更します。よろしいですか？
              </Typography>
            )}
            <Input
              name={'changingLicenceCount'}
              type={'text'}
              label={'変更後のライセンス数'}
              inputstyle={{
                borderColor:
                  modalState === 'CONFIRMING' ? themeContext.colors.description : undefined,
                color: modalState === 'CONFIRMING' ? themeContext.colors.description : undefined,
              }}
              readonly={modalState === 'CONFIRMING'}
              validate={{
                required: {
                  value: true,
                  message: '使用中のライセンス数以上の数字を入力してください。',
                },
                min: {
                  value: props.contract.usingLicenceCount,
                  message: '使用中のライセンス数以上の数字を入力してください。',
                },
                max: {
                  value: 1000,
                  message: '1000以下の数字を入力してください',
                },
                pattern: {
                  value: /^[0-9]+$/,
                  message: '使用中のライセンス数以上の数字を入力してください。',
                },
              }}
              initialValue={props.contract.licenceCount}
              onChange={(value) => setLicenceCount(Number.parseInt(value))}
            />
            {modalState === 'CONFIRMING' && (
              <View style={{ marginVertical: 5 }}>
                <Typography variant={TypographyType.ElementiTitle}>ご請求金額</Typography>
                {props.contract.billingType !== BillingType.Invoice && (
                  <View style={{ marginVertical: 10 }}>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}>
                      <Typography variant={TypographyType.Normal}>
                        (今回請求分)追加分の日割料金(税込)
                      </Typography>
                      <Typography variant={TypographyType.Normal}>
                        {confirmAmountData?.confirmAmountAfterLicenceCountChange?.dailyRateAmount.toLocaleString(
                          'ja-JP'
                        )}
                        円
                      </Typography>
                    </View>
                    <Typography
                      variant={TypographyType.Description}
                      style={{ paddingVertical: 5, color: themeContext.colors.error }}>
                      追加分のライセンスは、残り期間の日割料金が発生します。クレジットカード支払いをご利用の場合には、即座に請求処理が行われます。
                    </Typography>
                  </View>
                )}
                {props.contract.billingType === BillingType.Invoice &&
                  props.contract.contractPreriod === ContractPeriod.Yearly && (
                    <View style={{ marginVertical: 10 }}>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}>
                        <Typography variant={TypographyType.Normal}>
                          (今回請求分)追加分の月割料金(税込)
                        </Typography>
                        <Typography variant={TypographyType.Normal}>
                          {confirmAmountData?.confirmAmountAfterLicenceCountChange?.dailyRateAmount.toLocaleString(
                            'ja-JP'
                          )}
                          円
                        </Typography>
                      </View>
                      <Typography
                        variant={TypographyType.Description}
                        style={{ paddingVertical: 5, color: themeContext.colors.error }}>
                        {`追加分のライセンスは、残り期間の月割料金が発生します。${'\n'}(翌月1日から契約終了日までの日割り料金となります。)`}
                      </Typography>
                      <Typography
                        variant={TypographyType.Description}
                        style={{ paddingVertical: 5, color: themeContext.colors.error }}>
                        翌月月初に、追加ライセンス分の請求書を送付させていただきます。
                      </Typography>
                    </View>
                  )}
                <View style={{ marginVertical: 10 }}>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}>
                    <Typography
                      variant={TypographyType.Normal}
                      style={{ color: themeContext.colors.description }}>
                      次回更新時からのご利用料(税込)
                    </Typography>
                    <Typography
                      variant={TypographyType.Normal}
                      style={{ color: themeContext.colors.description }}>
                      {confirmAmountData?.confirmAmountAfterLicenceCountChange?.nextTotalAmount.toLocaleString(
                        'ja-JP'
                      )}
                      円
                    </Typography>
                  </View>
                  <Typography
                    variant={TypographyType.Description}
                    style={{ paddingVertical: 5, color: themeContext.colors.description }}>
                    次回更新時の請求日に請求させていただく金額となります。
                  </Typography>
                </View>
              </View>
            )}
          </View>
          {modalState === 'CONFIRMING' && (
            <View style={{ flexDirection: 'row' }}>
              <Checkbox
                size={20}
                value={confirmed}
                onValueChange={setConfirmed}
                color={confirmed ? themeContext.colors.primary : undefined}
              />
              <TouchableOpacity onPress={() => setConfirmed(!confirmed)}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{ lineHeight: 20, color: themeContext.colors.error, marginLeft: 10 }}>
                  {`ご利用金額・請求金額を確認したら${'\n'}チェックを入れてください`}
                </Typography>
              </TouchableOpacity>
            </View>
          )}
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              zIndex: 1,
              paddingVertical: 15,
            }}>
            <Button
              text={modalState === 'CONFIRMING' ? '変更する' : '変更内容を確認する'}
              isDisabled={
                licenceCount === props.contract.licenceCount ||
                (modalState === 'CONFIRMING' && confirmed === false)
              }
              style={{
                minWidth: 150,
                marginRight: 10,
              }}
              onPress={async () => {
                if (modalState === 'CONFIRMING') {
                  await updateLicenceCount();
                  setModalState('FINISHED');
                } else {
                  confirmAmount({
                    variables: {
                      id: props.contract.id!,
                      input: {
                        licenceCount: licenceCount,
                      },
                    },
                  });
                  setModalState('CONFIRMING');
                }
              }}
            />
            <Button
              text={modalState === 'CONFIRMING' ? '戻る' : 'キャンセル'}
              style={{
                minWidth: 150,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.primary }}
              disableValidate
              onPress={() => {
                if (modalState === 'CONFIRMING') {
                  setModalState('INPUT');
                } else {
                  props.closeModal();
                }
              }}
            />
          </View>
        </>
      )}
      {modalState === 'FINISHED' && (
        <>
          <View
            style={{
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <Typography variant={TypographyType.Normal}>
              ライセンス数の変更が完了しました。
            </Typography>
            <Button
              text={'閉じる'}
              style={{
                minWidth: 150,
                marginVertical: 15,
              }}
              onPress={() => {
                props.closeModal();
              }}
            />
          </View>
        </>
      )}
    </Form>
  );
};

const OrganizationContractPage = () => {
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const themeContext: IThemePart = useContext(ThemeContext);
  const [showUpgradeToProPlanModal, setShowUpgradeToProPlanModal] = useState(false);
  const [showDowngradeToProPlanModal, setShowDowngradeToProPlanModal] = useState(false);
  const { loading: organizationLoading, data: organizationData } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId!,
    },
  });
  const { loading: contractLoading, data: contractData } = useContractQuery({
    fetchPolicy: 'network-only',
  });
  const [changeContractPeriod] = useChangeContractPeriodMutation();

  const [getCreditCard, { data: creditCardData, loading: creditCardLoading }] =
    useCreditCardLazyQuery();

  const {
    loading: upcomingInvoiceLoading,
    data: upcomingInvoiceData,
    refetch: upcomingInvoiceRefetch,
  } = useUpcomingInvoiceQuery({
    fetchPolicy: 'network-only',
  });

  const { loading: membersLoading, data: membersData } = useOrganizationMembersQuery({
    variables: {
      organizationId: loginUser!.organizationId!,
    },
    fetchPolicy: 'network-only',
  });
  const { loading: invitationsLoadind, data: invitationsData } = useMemberInvitationsQuery({
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (contractData?.contract?.billingType === BillingType.CreditCard) {
      getCreditCard();
    }
  }, [contractData, creditCardData]);

  const getDisplayPlanName = useCallback(
    (organization: Organization) =>
      when(organization.plan.code)
        .on(
          (v) => v === 'Basic',
          () => 'Basicプラン（個人利用プラン）'
        )
        .on(
          (v) => v === 'Professional',
          () => 'Proプラン（個人利用プラン）'
        )
        .on(
          (v) => v === 'Business',
          () => 'Businessプラン（チーム利用プラン）'
        )
        .on(
          (v) => v === 'Enterprise',
          () => 'Enterpriseプラン（チーム利用プラン）'
        )
        .otherwise(() => '-'),
    [organizationData]
  );

  if (
    organizationLoading ||
    contractLoading ||
    creditCardLoading ||
    upcomingInvoiceLoading ||
    membersLoading ||
    invitationsLoadind
  ) {
    return <></>;
  }

  return (
    <Container>
      <SettingsElement title={'現在契約中のプラン'}>
        <Typography variant={TypographyType.Normal}>
          {getDisplayPlanName(organizationData?.organization!)}
        </Typography>
        <Typography
          variant={TypographyType.Description}
          style={{ color: themeContext.colors.error }}>
          {contractData?.contract?.plan.code !== Plan.Basic &&
            // AppleID決済の場合、トライアル期間はApple側での管理になるので表示しない
            contractData?.contract?.billingType! !== BillingType.AppleIap &&
            moment(contractData?.contract?.freeTrialExpireDatetime).diff(moment(), 'days') >= 0 &&
            `フリートライアル期間は、残り${moment(
              contractData?.contract?.freeTrialExpireDatetime
            ).diff(moment(), 'days')}日です。`}
        </Typography>
        {contractData?.contract?.billingType! === BillingType.AppleIap && (
          <Typography variant={TypographyType.Description}>
            AppleID決済をご利用の場合には、この画面からプラン変更をすることはできません。{`\n`}
            また、退会をする際には、先にAppleID決済で契約しているプロプランを解約する必要があります。
            {`\n`}
            詳しくはヘルプページをご覧ください。{`\n`}
            <Link
              isExternalLink
              path={
                'https://manual.timedesigner.com/v1/appleid'
              }>
              ヘルプページを開く
            </Link>
          </Typography>
        )}
        {contractData!.contract!.plan.code === Plan.Basic && (
          <TouchableOpacity
            style={{ marginTop: 15 }}
            onPress={() => setShowUpgradeToProPlanModal(true)}>
            <Typography
              variant={TypographyType.Normal}
              style={{ fontSize: 16, color: themeContext.colors.link }}>
              Proプランへアップグレードする
            </Typography>
          </TouchableOpacity>
        )}
        {contractData!.contract!.plan.code === Plan.Professional &&
          contractData?.contract?.billingType !== BillingType.AppleIap && (
            <TouchableOpacity
              style={{ marginTop: 15 }}
              onPress={() => setShowDowngradeToProPlanModal(true)}>
              <Typography
                variant={TypographyType.Normal}
                style={{ fontSize: 16, color: themeContext.colors.link }}>
                Basicプランへダウングレードする
              </Typography>
            </TouchableOpacity>
          )}
      </SettingsElement>
      <DowngradeToBasicPlanModal
        isShow={showDowngradeToProPlanModal}
        contract={contractData!.contract!}
        closeModal={() => setShowDowngradeToProPlanModal(false)}
        onChangePlan={() => {
          upcomingInvoiceRefetch();
        }}
      />
      <PlanModal
        isShow={showUpgradeToProPlanModal}
        contract={contractData!.contract!}
        closeModal={() => setShowUpgradeToProPlanModal(false)}
        onChangePlan={() => {
          upcomingInvoiceRefetch();
        }}
      />
      {(contractData?.contract?.plan.code === Plan.Business ||
        contractData?.contract?.plan.code === Plan.Enterprise) && (
        <SettingsElement
          title={'ライセンス数'}
          changeText={'ライセンス数を変更する'}
          modal={(closeModal) => (
            <LicenceCountModal
              contract={contractData?.contract!}
              closeModal={closeModal}
              upcomingInvoiceData={upcomingInvoiceData?.upcomingInvoice!}
            />
          )}>
          <Typography variant={TypographyType.Normal}>
            {contractData?.contract?.licenceCount}人分のライセンス
          </Typography>
          <Typography variant={TypographyType.Description}>
            現在{contractData?.contract?.usingLicenceCount}人分のライセンスを使用しています。
          </Typography>
        </SettingsElement>
      )}
      {(contractData?.contract?.plan.code === Plan.Business ||
        contractData?.contract?.plan.code === Plan.Enterprise) &&
        contractData?.contract?.freeLicenceCoupon && (
          <SettingsElement title={'特別無料枠'}>
            <Typography variant={TypographyType.Normal}>
              {contractData.contract.freeLicenceCoupon.freeStartLicenceCount}〜
              {contractData.contract.freeLicenceCoupon.freeEndLicenceCount}
              名の間は追加ライセンス無料
            </Typography>
          </SettingsElement>
        )}
      {(contractData?.contract?.plan.code === Plan.Business ||
        contractData?.contract?.plan.code === Plan.Enterprise) && (
        <SettingsElement title={'メンバー登録状況'}>
          <View style={{ flexDirection: 'row' }}>
            <Typography variant={TypographyType.Normal} style={{ fontSize: 16 }}>
              有効:{' '}
              {
                membersData!.organizationMembers!.filter(
                  (member) => member!.memberStatus === MemberStatus.Active
                ).length
              }
              名
            </Typography>
            <Typography variant={TypographyType.Normal} style={{ fontSize: 16, marginLeft: 10 }}>
              無効:{' '}
              {
                membersData!.organizationMembers!.filter(
                  (member) => member!.memberStatus === MemberStatus.Inactive
                ).length
              }
              名
            </Typography>
            <Typography variant={TypographyType.Normal} style={{ fontSize: 16, marginLeft: 10 }}>
              招待中: {invitationsData!.memberInvitations!.length}名
            </Typography>
          </View>
        </SettingsElement>
      )}
      {contractData?.contract?.plan.code !== Plan.Basic && (
        <SettingsElement
          title={'現在のご契約期間'}
          changeText={
            // プロフェッショナルプランで、支払い方法が登録されているもしくはフリートライアル期間中にはこの画面から変更可能
            contractData?.contract?.plan.code === Plan.Professional &&
            contractData?.contract?.billingType !== BillingType.AppleIap &&
            contractData.contract.billingType !== BillingType.NonRegistered
              ? `ご契約期間を${
                  contractData?.contract?.plan.contractPreriod === ContractPeriod.Monthly
                    ? '年間契約'
                    : '月契約'
                }に変更する`
              : undefined
          }
          modal={(closeModal) => (
            <>
              <View style={{ paddingVertical: 20 }}>
                <Typography variant={TypographyType.Normal}>
                  ご契約期間を現在の
                  {ContractPeriodName[contractData?.contract?.plan.contractPreriod!]}
                  から
                  {contractData?.contract?.plan.contractPreriod == ContractPeriod.Monthly
                    ? '年間契約'
                    : '月契約'}
                  へと変更します。 {'\n'}よろしいですか？
                </Typography>
                <Typography variant={TypographyType.Description}>
                  ※契約期間の変更は、次の請求日より適用されます。
                </Typography>
              </View>
              <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1 }}>
                <Button
                  text={'(金額に問題がないことを確認した上で)変更する'}
                  style={{
                    minWidth: 100,
                    marginRight: 10,
                  }}
                  onPress={async () => {
                    await changeContractPeriod({
                      variables: {
                        id: contractData?.contract?.id!,
                        input: {
                          contractPreriod:
                            contractData?.contract?.plan.contractPreriod == ContractPeriod.Monthly
                              ? ContractPeriod.Yearly
                              : ContractPeriod.Monthly,
                          versionNo: contractData?.contract?.versionNo!,
                        },
                      },
                    });
                    await upcomingInvoiceRefetch();
                    closeModal();
                  }}
                />
                <Button
                  text={'キャンセル'}
                  style={{
                    minWidth: 100,
                    marginRight: 10,
                    backgroundColor: 'transparent',
                  }}
                  textStyle={{ color: themeContext.colors.primary }}
                  disableValidate={true}
                  onPress={async () => {
                    closeModal();
                  }}
                />
              </View>
            </>
          )}>
          <Typography variant={TypographyType.Normal}>
            {ContractPeriodName[contractData?.contract?.plan.contractPreriod!]}
          </Typography>
          {(contractData?.contract?.plan.code === Plan.Business ||
            contractData?.contract?.plan.code === Plan.Enterprise) && (
            <>
              <Typography
                variant={TypographyType.Description}
                style={{ fontSize: 12, marginVertical: 10 }}>
                ご契約を月額 ⇔
                年額に変更したい場合には、お手数ですがお問い合わせをお願いいたします。
              </Typography>
              <Link path={`https://form.timedesigner.com/other-inquiry/`} isExternalLink={true}>
                <Typography
                  variant={TypographyType.Description}
                  style={{ color: themeContext.colors.link }}>
                  お問い合わせはこちらへ
                </Typography>
              </Link>
            </>
          )}
          {contractData?.contract?.plan.code === Plan.Professional &&
            contractData.contract.billingType === BillingType.NonRegistered && (
              <>
                <Typography variant={TypographyType.Description}>
                  お支払い方法の登録後、ご契約期間を月額 ⇔ 年額に変更することが可能になります。
                </Typography>
              </>
            )}
        </SettingsElement>
      )}
      {contractData?.contract?.plan.code !== Plan.Basic &&
        contractData?.contract?.billingType !== BillingType.AppleIap && (
          <SettingsElement title={'次回のご請求について'}>
            <Typography variant={TypographyType.Normal}>
              {upcomingInvoiceData?.upcomingInvoice?.total}円 を{' '}
              {moment(upcomingInvoiceData?.upcomingInvoice?.billingDateTime).format(
                'YYYY年MM月DD日'
              )}{' '}
              にご請求予定です。
            </Typography>
            {contractData?.contract?.billingType === BillingType.NonRegistered && (
              <Typography variant={TypographyType.Description}>
                {`クレジットカードをご登録しなければ、${'\n'}勝手に課金されることはありませんので、ご安心ください。`}
              </Typography>
            )}
          </SettingsElement>
        )}
      {contractData?.contract?.plan.code !== Plan.Basic && (
        <SettingsElement
          title={'現在のお支払い方法'}
          changeText={
            contractData?.contract?.billingType === BillingType.CreditCard
              ? 'クレジットカードを変更する'
              : contractData?.contract?.billingType === BillingType.Invoice ||
                contractData?.contract?.billingType === BillingType.AppleIap
              ? undefined
              : 'クレジットカードを登録する'
          }
          modal={(closeModal) => (
            <CreditCardRegisterForm
              contract={contractData?.contract!}
              handleSubmit={closeModal}
              handleCancel={closeModal}
              submitText={'登録する'}
            />
          )}>
          <Typography variant={TypographyType.Normal}>
            {BillingTypeName[contractData?.contract?.billingType!]}
          </Typography>
          {contractData?.contract?.billingType === BillingType.CreditCard ? (
            <View
              style={{
                marginVertical: 10,
              }}>
              <Typography variant={TypographyType.Description}>
                カード番号: ************{creditCardData?.creditCard?.cardNumberLast4}
              </Typography>
              <Typography variant={TypographyType.Description}>
                有効期限: {creditCardData?.creditCard?.expireYearMonth}
                <Typography
                  variant={TypographyType.Description}
                  style={{ color: '#f44336', paddingLeft: 5 }}>
                  {moment(creditCardData?.creditCard?.expireYearMonth).diff(moment(), 'month') <
                    0 && '※有効期限切れ'}
                </Typography>
              </Typography>
            </View>
          ) : null}
        </SettingsElement>
      )}
      {(contractData?.contract?.plan.code === Plan.Business ||
        contractData?.contract?.plan.code === Plan.Enterprise) && (
        <SettingsElement
          title={'請求先メールアドレス'}
          changeText={'請求先メールアドレスを変更する'}
          modal={(closeModal) => (
            <BillToMailAddressModal
              closeModal={closeModal}
              billToMailAddress={contractData?.contract?.billToMailAddress!}
              contractId={contractData?.contract?.id!}
              versionNo={contractData?.contract?.versionNo!}
            />
          )}>
          <Typography variant={TypographyType.Normal}>
            {contractData?.contract?.billToMailAddress}
          </Typography>
          <Typography variant={TypographyType.Description}>
            領収書などのご請求に関連するメールは、こちらのメールアドレスに送信されます。
          </Typography>
        </SettingsElement>
      )}
      {contractData?.contract?.billingType! !== BillingType.AppleIap && (
        <SettingsElement title={'ご解約・退会について'}>
          <View style={{ paddingVertical: 13 }}>
            <Link path={`/app/${organizationData!.organization!.id!}/withdrawal/`}>
              解約・退会の手続きを行う
            </Link>
          </View>
        </SettingsElement>
      )}
      {(contractData?.contract?.plan.code === Plan.Business ||
        contractData?.contract?.plan.code === Plan.Enterprise) && (
        <View style={{ paddingVertical: 13 }}>
          <Typography variant={TypographyType.Description}>
            ※請求書払いをご希望の方は
            <Link path={'https://form.timedesigner.com/invoice/'} isExternalLink={true}>
              こちら
            </Link>
            からお問い合わせください
          </Typography>
        </View>
      )}
    </Container>
  );
};

export default OrganizationContractPage;
