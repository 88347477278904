import React, { useContext, useState } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { LoginUserContext } from '../../../../../../modules/auth/LoginUserContext';
import {
  Organization,
  Plan,
  SortKey,
  SortOrder,
  Task,
  TaskCompleteFilter,
  useOrganizationQuery,
  useOrganizationTeamsQuery,
  useSearchTasksQuery,
} from '../../../../../../graphql/api/API';
import moment from 'moment-timezone';
import CustomScrollView from '../../../../../presentational/atoms/custom-scroll-view';
//@ts-ignore
import { ThemeContext } from 'styled-components/native';
import { IThemePart } from '../../../../../theme';
import Table from '../../../../../presentational/molecules/table';
import UrlUtil from '../../../../../../util/UrlUtil';
import TimeUtil from '../../../../../../util/TimeUtil';
import { useHistory } from 'react-router';
import Avatar from '../../../../../presentational/atoms/avatar';
import Typography, { TypographyType } from '../../../../../presentational/atoms/typography';
import queryString from 'query-string';
import Form from '../../../../../presentational/atoms/form';
import Input from '../../../../../presentational/atoms/input';
import TaskUtil from '../../../../../../util/TaskUtil';

interface IExpiredTaskListProps {
  organization: Organization;
  teamId: string | null;
}

const ExpiredTaskList = (props: IExpiredTaskListProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser] = useContext(LoginUserContext);
  const history = useHistory();
  const [currentDateTime] = useState(moment());
  const { loading, data, error } = useSearchTasksQuery({ //TODO TDV1-112
    variables: {
      input: {
        teamIdList: props.teamId ? [props.teamId] : [],
        scheduledEndDateTime: {
          from: null,
          to: currentDateTime.toISOString(),
        },
        completeCondition: TaskCompleteFilter.Incomplete,
        sortKey: SortKey.ScheduledEndDate,
        sortOrder: SortOrder.Asc,
      },
      limit: 100, // TODO ページング対応がすぐできないので、一旦強制的に上限を設定
      offset: 0
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  return (
    <View
      style={{
        marginHorizontal: 20,
        marginVertical: 10,
        minWidth: 500,
      }}>
      <Table
        style={{ width: '100%' }}
        title={'期限が過ぎているタスク'}
        rightHeaderComponent={
          <TouchableOpacity
            onPress={() => {
              history.push(
                queryString.stringifyUrl({
                  url: `/app/${loginUser!.organizationId}/search-task/list/`,
                  query: {
                    teamIdList: props.teamId ? [props.teamId] : [],
                    scheduledEndDateTimeTo: currentDateTime.toISOString(),
                    completeCondition: TaskCompleteFilter.Incomplete,
                    sortKey: SortKey.ScheduledEndDate,
                    sortOrder: SortOrder.Asc,
                  } as any,
                })
              );
            }}>
            <Typography
              variant={TypographyType.Normal}
              style={{ fontSize: 14, color: themeContext.colors.link }}>
              詳細を見る
            </Typography>
          </TouchableOpacity>
        }
        rowData={(data?.searchTasks || []).map((task) => {
          return {
            チーム: (
              <Typography variant={TypographyType.Normal} style={{ fontSize: 14 }} ellipsis={true}>
                {task!.project!.team.name}
              </Typography>
            ),
            取引先: (
              <Typography variant={TypographyType.Normal} style={{ fontSize: 14 }} ellipsis={true}>
                {task!.project!.client?.name ?? '-'}
                {task!.project!.client?.code && ` (${task!.project!.client.code})`}
              </Typography>
            ),
            プロジェクト: (
              <Typography variant={TypographyType.Normal} style={{ fontSize: 14 }} ellipsis={true}>
                {task!.project!.name}
              </Typography>
            ),
            タスク: (
              <Typography variant={TypographyType.Normal} style={{ fontSize: 14 }} ellipsis={true}>
                {task!.title}
              </Typography>
            ),
            担当者: (
              <View>
                {task!.assignees!.length > 2 ? (
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      //@ts-ignore
                      gap: 10,
                    }}>
                    {task?.assignees.map((info) => {
                      return (
                        <Avatar
                          size={24}
                          name={info.member.name || '-'}
                          imageUrl={info.member.profileImageUrl}
                          showToolTip
                          key={info.member.id}
                        />
                      );
                    })}
                  </View>
                ) : (
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}>
                    {task?.assignees.map((info, i) => {
                      return (
                        <View
                          key={info.member.id}
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginLeft: i === 0 ? undefined : 10,
                          }}>
                          <Avatar
                            size={24}
                            name={info.member.name || '-'}
                            imageUrl={info.member.profileImageUrl}
                          />
                          <Typography
                            variant={TypographyType.Normal}
                            style={{ marginLeft: 5, fontSize: 14, maxWidth: 130 }}
                            ellipsis={true}>
                            {info.member.name || '-'}
                          </Typography>
                        </View>
                      );
                    })}
                  </View>
                )}
              </View>
            ),
            〆切日: (
              <Typography variant={TypographyType.Normal} style={{ fontSize: 14 }}>
                {task!.scheduledEndDateTime ? moment(task!.scheduledEndDateTime).format('ll') : '-'}
              </Typography>
            ),
            進捗率: (
              <Typography variant={TypographyType.Normal} style={{ fontSize: 14 }}>
                {`${task!.progressRate || 0}%`}
              </Typography>
            ),
            taskId: task?.id,
            rowStyle: { height: 40 },
          };
        })}
        rowDataStyle={{
          チーム: {
            width: 200,
          },
          取引先: {
            width: 200,
          },
          プロジェクト: {
            width: 200,
          },
          タスク: {
            width: 200,
          },
          担当者: {
            width: 230,
          },
          〆切日: {
            width: 140,
          },
          進捗率: {
            width: 70,
          },
        }}
        filter={
          props.organization.plan.code === Plan.Business ||
          props.organization.plan.code === Plan.Enterprise
            ? ['チーム', '取引先', 'プロジェクト', 'タスク', '担当者', '〆切日', '進捗率']
            : ['取引先', 'プロジェクト', 'タスク', '〆切日', '進捗率']
        }
        scrollEnabled={false}
        containerStyle={{ justifyContent: 'center' }}
        onPress={(rowData) => {
          history.push(UrlUtil.createTaskDetailUrlById(rowData.taskId));
        }}
      />
    </View>
  );
};

interface IExpireSoonTaskListProps {
  organization: Organization;
  teamId: string | null;
}

const ExpireSoonTaskList = (props: IExpireSoonTaskListProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser] = useContext(LoginUserContext);
  const history = useHistory();
  const [currentDateTime] = useState(moment());
  const { loading, data, error } = useSearchTasksQuery({ //TODO TDV1-112
    variables: {
      input: {
        teamIdList: props.teamId ? [props.teamId] : [],
        scheduledEndDateTime: {
          from: currentDateTime.toISOString(),
          to: moment(currentDateTime).add(1, 'week').toISOString(),
        },
        completeCondition: TaskCompleteFilter.Incomplete,
        sortKey: SortKey.ScheduledEndDate,
        sortOrder: SortOrder.Asc,
      },
      limit: 100, // TODO ページング対応がすぐできないので、一旦強制的に上限を設定
      offset: 0
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  return (
    <View
      style={{
        marginHorizontal: 20,
        marginVertical: 10,
        minWidth: 500,
      }}>
      <Table
        style={{ width: '100%' }}
        title={'期限が1週間以内のタスク'}
        rightHeaderComponent={
          <TouchableOpacity
            onPress={() => {
              history.push(
                queryString.stringifyUrl({
                  url: `/app/${loginUser!.organizationId}/search-task/list/`,
                  query: {
                    teamIdList: props.teamId ? [props.teamId] : [],
                    scheduledEndDateTimeFrom: currentDateTime.toISOString(),
                    scheduledEndDateTimeTo: moment(currentDateTime).add(1, 'week').toISOString(),
                    completeCondition: TaskCompleteFilter.Incomplete,
                    sortKey: SortKey.ScheduledEndDate,
                    sortOrder: SortOrder.Asc,
                  } as any,
                })
              );
            }}>
            <Typography
              variant={TypographyType.Normal}
              style={{ fontSize: 14, color: themeContext.colors.link }}>
              詳細を見る
            </Typography>
          </TouchableOpacity>
        }
        rowData={(data?.searchTasks || []).map((task) => {
          return {
            チーム: (
              <Typography variant={TypographyType.Normal} style={{ fontSize: 14 }} ellipsis={true}>
                {task!.project!.team.name}
              </Typography>
            ),
            取引先: (
              <Typography variant={TypographyType.Normal} style={{ fontSize: 14 }} ellipsis={true}>
                {task!.project!.client?.name ?? '-'}
                {task!.project!.client?.code && ` (${task!.project!.client.code})`}
              </Typography>
            ),
            プロジェクト: (
              <Typography variant={TypographyType.Normal} style={{ fontSize: 14 }} ellipsis={true}>
                {task!.project!.name}
              </Typography>
            ),
            タスク: (
              <Typography variant={TypographyType.Normal} style={{ fontSize: 14 }} ellipsis={true}>
                {task!.title}
              </Typography>
            ),
            担当者: (
              <View>
                {task!.assignees!.length > 2 ? (
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      //@ts-ignore
                      gap: 10,
                    }}>
                    {task?.assignees.map((info) => {
                      return (
                        <Avatar
                          size={24}
                          name={info.member.name || '-'}
                          imageUrl={info.member.profileImageUrl}
                          showToolTip
                          key={info.member.id}
                        />
                      );
                    })}
                  </View>
                ) : (
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}>
                    {task?.assignees.map((info, i) => {
                      return (
                        <View
                          key={info.member.id}
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginLeft: i === 0 ? undefined : 10,
                          }}>
                          <Avatar
                            size={24}
                            name={info.member.name || '-'}
                            imageUrl={info.member.profileImageUrl}
                          />
                          <Typography
                            variant={TypographyType.Normal}
                            style={{ marginLeft: 5, fontSize: 14, maxWidth: 130 }}
                            ellipsis={true}>
                            {info.member.name || '-'}
                          </Typography>
                        </View>
                      );
                    })}
                  </View>
                )}
              </View>
            ),
            〆切日: (
              <Typography variant={TypographyType.Normal} style={{ fontSize: 14 }}>
                {task!.scheduledEndDateTime ? moment(task!.scheduledEndDateTime).format('ll') : '-'}
              </Typography>
            ),
            進捗率: (
              <Typography variant={TypographyType.Normal} style={{ fontSize: 14 }}>
                {`${task!.progressRate || 0}%`}
              </Typography>
            ),
            taskId: task?.id,
            rowStyle: { height: 40 },
          };
        })}
        rowDataStyle={{
          タスク: {
            width: 200,
          },
          プロジェクト: {
            width: 200,
          },
          取引先: {
            width: 200,
          },
          チーム: {
            width: 200,
          },
          担当者: {
            width: 230,
          },
          〆切日: {
            width: 140,
          },
          進捗率: {
            width: 70,
          },
        }}
        filter={
          props.organization.plan.code === Plan.Business ||
          props.organization.plan.code === Plan.Enterprise
            ? ['チーム', '取引先', 'プロジェクト', 'タスク', '担当者', '〆切日', '進捗率']
            : ['取引先', 'プロジェクト', 'タスク', '〆切日', '進捗率']
        }
        scrollEnabled={false}
        containerStyle={{ justifyContent: 'center' }}
        onPress={(rowData) => {
          history.push(UrlUtil.createTaskDetailUrlById(rowData.taskId));
        }}
      />
    </View>
  );
};

interface INotGoingWellTaskListProps {
  organization: Organization;
  teamId: string | null;
}

const NotGoingWellTaskList = React.memo((props: INotGoingWellTaskListProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser] = useContext(LoginUserContext);
  const history = useHistory();
  const [currentDateTime] = useState(moment());
  const { loading, data, error } = useSearchTasksQuery({ //TODO TDV1-112
    variables: {
      input: {
        teamIdList: props.teamId ? [props.teamId] : [],
        deviationRate: {
          from: 120,
          to: null,
        },
        completeCondition: TaskCompleteFilter.Incomplete,
        sortKey: SortKey.DeviationRate,
        sortOrder: SortOrder.Desc,
      },
      limit: 100, // TODO ページング対応がすぐできないので、一旦強制的に上限を設定
      offset: 0
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  return (
    <View
      style={{
        marginHorizontal: 20,
        marginVertical: 10,
        minWidth: 500,
      }}>
      <Table
        style={{ width: '100%' }}
        title={'進捗が順調ではないタスク'}
        rightHeaderComponent={
          <TouchableOpacity
            onPress={() => {
              history.push(
                queryString.stringifyUrl({
                  url: `/app/${loginUser!.organizationId}/search-task/list/`,
                  query: {
                    teamIdList: props.teamId ? [props.teamId] : [],
                    deviationRateFrom: 120,
                    completeCondition: TaskCompleteFilter.Incomplete,
                    sortKey: SortKey.DeviationRate,
                    sortOrder: SortOrder.Desc,
                  } as any,
                })
              );
            }}>
            <Typography
              variant={TypographyType.Normal}
              style={{ fontSize: 14, color: themeContext.colors.link }}>
              詳細を見る
            </Typography>
          </TouchableOpacity>
        }
        rowData={(data?.searchTasks || []).map((task) => {
          return {
            チーム: (
              <Typography variant={TypographyType.Normal} style={{ fontSize: 14 }} ellipsis={true}>
                {task!.project!.team.name}
              </Typography>
            ),
            取引先: (
              <Typography variant={TypographyType.Normal} style={{ fontSize: 14 }} ellipsis={true}>
                {task!.project!.client?.name ?? '-'}
                {task!.project!.client?.code && ` (${task!.project!.client.code})`}
              </Typography>
            ),
            プロジェクト: (
              <Typography variant={TypographyType.Normal} style={{ fontSize: 14 }} ellipsis={true}>
                {task!.project!.name}
              </Typography>
            ),
            タスク: (
              <Typography variant={TypographyType.Normal} style={{ fontSize: 14 }} ellipsis={true}>
                {task!.title}
              </Typography>
            ),
            担当者: (
              <View>
                {task!.assignees!.length > 1 ? (
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      //@ts-ignore
                      gap: 10,
                    }}>
                    {task?.assignees.map((info) => {
                      return (
                        <Avatar
                          size={24}
                          name={info.member.name || '-'}
                          imageUrl={info.member.profileImageUrl}
                          showToolTip
                          key={info.member.id}
                        />
                      );
                    })}
                  </View>
                ) : (
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}>
                    {task?.assignees.map((info, i) => {
                      return (
                        <View
                          key={info.member.id}
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginLeft: i === 0 ? undefined : 10,
                          }}>
                          <Avatar
                            size={24}
                            name={info.member.name || '-'}
                            imageUrl={info.member.profileImageUrl}
                          />
                          <Typography
                            variant={TypographyType.Normal}
                            style={{ marginLeft: 5, fontSize: 14, maxWidth: 130 }}
                            ellipsis={true}>
                            {info.member.name || '-'}
                          </Typography>
                        </View>
                      );
                    })}
                  </View>
                )}
              </View>
            ),
            〆切日: (
              <Typography variant={TypographyType.Normal} style={{ fontSize: 14 }}>
                {task!.scheduledEndDateTime ? moment(task!.scheduledEndDateTime).format('ll') : '-'}
              </Typography>
            ),
            進捗率: (
              <Typography variant={TypographyType.Normal} style={{ fontSize: 14 }}>
                {`${task!.progressRate || 0}%`}
              </Typography>
            ),
            見積時間: (
              <Typography variant={TypographyType.Normal} style={{ fontSize: 14 }}>
                {TimeUtil.formatForTask(task!.estimateTimeSec)}
              </Typography>
            ),
            作業時間: (
              <Typography variant={TypographyType.Normal} style={{ fontSize: 14 }}>
                {TimeUtil.formatForTask(task!.workingTimeSec)}
              </Typography>
            ),
            予想見積時間: (
              <Typography variant={TypographyType.Normal} style={{ fontSize: 14 }}>
                {task!.progressDeviationRate
                  ? TimeUtil.formatForTask(TaskUtil.preparePredictWorkingTimeSec(task! as Task))
                  : '-'}
              </Typography>
            ),
            見積の乖離率: (
              <Typography variant={TypographyType.Normal} style={{ fontSize: 14 }}>
                {task!.progressDeviationRate ? `${task!.progressDeviationRate}%` : '-'}
              </Typography>
            ),
            taskId: task?.id,
            rowStyle: { height: 40 },
          };
        })}
        rowDataStyle={{
          チーム: {
            width: 200,
          },
          取引先: {
            width: 200,
          },
          プロジェクト: {
            width: 200,
          },
          タスク: {
            width: 200,
          },
          担当者: {
            width: 120,
          },
          〆切日: {
            width: 140,
          },
          進捗率: {
            width: 70,
          },
          見積時間: {
            width: 100,
          },
          作業時間: {
            width: 100,
          },
          予想見積時間: {
            width: 120,
          },
          見積の乖離率: {
            width: 110,
          },
        }}
        filter={
          props.organization.plan.code === Plan.Business ||
          props.organization.plan.code === Plan.Enterprise
            ? [
                'チーム',
                'タスク',
                'プロジェクト',
                '取引先',
                '担当者',
                '〆切日',
                '進捗率',
                '見積時間',
                '作業時間',
                '予想見積時間',
                '見積の乖離率',
              ]
            : [
                '取引先',
                'プロジェクト',
                'タスク',
                '〆切日',
                '進捗率',
                '見積時間',
                '作業時間',
                '予想見積時間',
                '見積の乖離率',
              ]
        }
        scrollEnabled={false}
        containerStyle={{ justifyContent: 'center' }}
        onPress={(rowData) => {
          history.push(UrlUtil.createTaskDetailUrlById(rowData.taskId));
        }}
      />
    </View>
  );
});

const DashboardPage = () => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, _] = useContext(LoginUserContext);
  const [teamId, setTeamId] = useState<string | null>(null);
  const { loading, data, error } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId,
    },
  });
  const { data: teamsData, loading: teamsLoading } = useOrganizationTeamsQuery({
    variables: {
      organizationId: loginUser!.organizationId,
    },
    fetchPolicy: 'network-only',
  });

  if (loading || teamsLoading) {
    return <></>;
  }

  return (
    <CustomScrollView
      style={{
        height: 'calc(100vh - 57px)',
      }}>
      <View style={{ overflow: 'scroll' }}>
        {(data!.organization!.plan.code === Plan.Business ||
          data!.organization!.plan.code === Plan.Enterprise) && (
          <Form style={{ width: 500, marginLeft: 20, marginTop: 20 }}>
            <Input
              name={'team'}
              label={'チームを選択する'}
              type={'picker'}
              containerStyle={{ marginBottom: 0 }}
              isSearchable={true}
              value={''}
              pickerItems={[{ label: '指定なし', value: null } as any].concat(
                teamsData?.organizationTeams?.map((team) => {
                  return {
                    label: team!.name,
                    value: team!.id!,
                  };
                })
              )}
              onChange={(value) => {
                setTeamId(value as any);
              }}
            />
          </Form>
        )}
        <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
          <ExpiredTaskList organization={data!.organization!} teamId={teamId} />
          <ExpireSoonTaskList organization={data!.organization!} teamId={teamId} />
          <NotGoingWellTaskList organization={data!.organization!} teamId={teamId} />
        </View>
      </View>
    </CustomScrollView>
  );
};

export default React.memo(DashboardPage);
