import React, { useContext } from 'react';
import ListMenuApp from '../../../organisms/list-menu-app';
import TeamSettingsInfoPage from './team-info';
import TeamSettingsArchivePage from './team-archive';
import TeamSettingsClosingPage from './team-closing';
import TeamSettingsMemberPage from './team-member';
import CustomScrollView from '../../../../../presentational/atoms/custom-scroll-view';

const AppTeamSettingsPage = () => {
  return (
    <CustomScrollView
      style={{
        height: 'calc(100vh - 57px)',
      }}>
      <ListMenuApp
        headerTitle={'チームの各種設定'}
        routes={[
          {
            name: 'チーム情報',
            path: `/app/:organizationId/:teamId/settings/info/`,
            component: TeamSettingsInfoPage,
          },
          {
            name: 'チームメンバーの管理',
            path: `/app/:organizationId/:teamId/settings/member/`,
            component: TeamSettingsMemberPage,
          },
          {
            name: 'チームのアーカイブ',
            path: `/app/:organizationId/:teamId/settings/archive/`,
            component: TeamSettingsArchivePage,
          },
          {
            name: 'チームの締め処理',
            path: `/app/:organizationId/:teamId/settings/closing/`,
            component: TeamSettingsClosingPage,
          },
        ]}
      />
    </CustomScrollView>
  );
};

export default React.memo(AppTeamSettingsPage);
