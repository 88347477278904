import React, { useContext, useState } from 'react';
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../../theme';
import LoginUtil from '../../../../../modules/auth/LoginUtil';
import {
  Project,
  ProjectDocument,
  ProjectStatus,
  TaskStatus,
  Team,
  TeamDocument,
  TeamProjectStatusDocument,
  useCreateProjectStatusMutation,
  useCreateTaskStatusMutation,
} from '../../../../../graphql/api/API';
import Form from '../../../../presentational/atoms/form';
import Input from '../../../../presentational/atoms/input';
import { View } from 'react-native';
import Button from '../../../../presentational/atoms/button';

const Container = styled.View``;

interface IProps {
  team: Team;
  onComplete: (projectStatus: ProjectStatus) => void;
  onCancel: () => void;
}

const ProjectStatusCreateDialog = (props: IProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [statusName, setStatusName] = useState('');
  const [createProjectStatus, _] = useCreateProjectStatusMutation({
    variables: {
      teamId: props.team.id!,
      input: {
        name: statusName,
      },
    },
    refetchQueries: [
      {
        query: TeamProjectStatusDocument,
        variables: {
          teamId: props.team.id,
        },
      },
    ],
  });

  return (
    <Container>
      <Form style={{ minWidth: 300 }}>
        <Input
          name={'taskStatusName'}
          label={'ステータス名'}
          focus={true}
          onChange={(value) => setStatusName(value)}
          onPressEnter={async () => {
            const result = await createProjectStatus();
            props.onComplete(result.data!.createProjectStatus! as ProjectStatus);
          }}
          validate={{
            required: {
              value: true,
              message: '新しいステータスの名前を入力してください',
            },
            maxLength: {
              value: 20,
              message: '20文字以内で入力してください',
            },
          }}
        />
        <View style={{ flexDirection: 'row' }}>
          <Button
            text="作成する"
            completeText="作成しました"
            style={{ height: 30, marginRight: 5 }}
            textStyle={{ fontSize: 14 }}
            onPress={async () => {
              const result = await createProjectStatus();
              props.onComplete(result.data!.createProjectStatus! as ProjectStatus);
            }}
          />
          <Button
            text="キャンセル"
            style={{ height: 30, backgroundColor: 'transparent' }}
            textStyle={{ fontSize: 14, color: themeContext.colors.primary }}
            onPress={async () => {
              props.onCancel();
            }}
            disableValidate={true}
          />
        </View>
      </Form>
    </Container>
  );
};

export default ProjectStatusCreateDialog;
