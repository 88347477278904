import React, { useContext, useState } from 'react';
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../../theme';
import LoginUtil from '../../../../../modules/auth/LoginUtil';
import {
  Client,
  CsvReportItemDefinition,
  CsvReportItemDefinitionsDocument,
  OrganizationClientsDocument,
  useRegisterClientMutation,
  useRegisterCsvReportItemDefinitionMutation,
} from '../../../../../graphql/api/API';
import Form from '../../../../presentational/atoms/form';
import Input from '../../../../presentational/atoms/input';
import { View } from 'react-native';
import Button from '../../../../presentational/atoms/button';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';

const Container = styled.View``;

interface IProps {
  onComplete: (definition: CsvReportItemDefinition) => void;
  onCancel: () => void;
}

const CreateCsvReportItemDefinitionDialog = (props: IProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [name, setName] = useState('');
  const [registerCsvReportItemDefinition, _] = useRegisterCsvReportItemDefinitionMutation({
    variables: {
      input: {
        name: name,
      },
    },
    refetchQueries: [
      {
        query: CsvReportItemDefinitionsDocument,
      },
    ],
  });

  return (
    <Container>
      <Form style={{ minWidth: 400 }}>
        <Input
          name={'definitionName'}
          label={'CSV出力設定名称'}
          focus={true}
          onChange={(value) => setName(value)}
          onPressEnter={async () => {
            const result = await registerCsvReportItemDefinition();
            props.onComplete(result.data!.registerCsvReportItemDefinition!);
          }}
          validate={{
            required: {
              value: true,
              message: 'CSV出力設定の名前を入力してください',
            },
            maxLength: {
              value: 100,
              message: '100文字以内で入力してください',
            },
          }}
        />
        <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1 }}>
          <Button
            text={'登録する'}
            completeText={'登録しました'}
            style={{
              minWidth: 100,
              marginRight: 10,
            }}
            onPress={async () => {
              const result = await registerCsvReportItemDefinition();
              props.onComplete(result.data!.registerCsvReportItemDefinition!);
            }}
          />
          <Button
            text={'キャンセル'}
            style={{
              minWidth: 100,
              marginRight: 10,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.primary }}
            disableValidate={true}
            onPress={async () => {
              props.onCancel();
            }}
          />
        </View>
      </Form>
    </Container>
  );
};

export default CreateCsvReportItemDefinitionDialog;
