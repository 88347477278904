import moment from 'moment-timezone';
import React, { useContext, useEffect, useState, useMemo, useCallback } from 'react';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import { IStyleTheme, IThemePart } from '../../../../theme';
import {
  CalendarEventDocument,
  GoogleCalendarEvent,
  Organization,
  Plan,
  Project,
  ProjectCompleteFilter,
  ProjectSortKey,
  SortOrder,
  Task,
  TaskSortKey,
  Team,
  ThirdPartyCalendarType,
  useCreateCalendarEventWithWorkingScheduleMutation,
  useCreateCalendarEventWithTaskAndWorkingScheduleMutation,
  useFavoriteProjectsQuery,
  useFavoriteTasksQuery,
  useOrganizationQuery,
  useProjectTasksQuery,
  useTeamProjectsQuery,
  useUpdateProjectSortNoInFavoriteProjectMutation,
  useUpdateTaskSortNoInFavoriteTaskMutation,
  WorkingSchedule,
  WorkingScheduleDocument,
  useTeamMemberRelationsQuery,
  TeamMemberRelation,
  Priority,
  useOrganizationClientsQuery,
  useOrganizationMembersQuery,
  Member,
  ProjectQuery,
  ProjectQueryVariables,
  TaskQueryVariables,
  TaskQuery,
  ProjectDocument,
  TaskDocument,
  useTeamProjectsForSelectorQuery,
  useProjectTasksForSelectorQuery,
} from '../../../../../graphql/api/API';
import { useCustomFavoriteTasksQuery } from '../../../../../graphql/api/CustomApi';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import Modal from '../../../../presentational/molecules/modal';
import { TouchableOpacity, useWindowDimensions, View, Text } from 'react-native';
import Button from '../../../../presentational/atoms/button';
import Form from '../../../../presentational/atoms/form';
import Input from '../../../../presentational/atoms/input';
import EditableText from '../../../../presentational/atoms/editable-text';
import VirtualizedFlatList, {
  GlobalDragContextProvider,
} from '../../../../presentational/atoms/list2/virtualized-flat-list';
import SearchIcon from '../../../../presentational/molecules/image-icon/search';
import Checkbox from '../../../../presentational/atoms/checkbox';
import OrganizationUtil from '../../../../../util/OrganizationUtil';
import Avatar from '../../../../presentational/atoms/avatar';
import TaskInfoElement from '../../../native/templates/task-detail/task-info-element';
import CustomScrollView from '../../../../presentational/atoms/custom-scroll-view';
import DateUtil from '../../../../../util/DateUtil';
import when from '../../../../../lang-extention/When';
import TimeUtil from '../../../../../util/TimeUtil';
import MultiPickerFilter from '../../../../presentational/atoms/multi-picker-filter';
import { useLazyQueryPromise } from '../../../../../graphql/extention/useLazyQueryPromise';

interface IMenuItemProps extends IStyleTheme {
  isActive: boolean;
}

const MenuItem = styled.TouchableOpacity<IMenuItemProps>`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom-width: ${(props: IMenuItemProps) => (props.isActive ? '2px' : '0')};
  border-color: #ffbebe;
  padding: 10px;
`;

interface IItemProps extends IStyleTheme {
  isSelected: boolean;
}

const Item = styled.View`
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  min-width: 200px;
  max-width: 90vw;
  border-bottom-width: 1px;
  border-color: ${(props: IItemProps) => props.theme.colors.separator};
  background-color: ${(props: IItemProps) =>
    props.isSelected ? props.theme.colors.primary : props.theme.colors.baseColor};
`;

interface ITaskListInnerProps {
  tasks: Task[];
  organization: Organization;
  selectedTask: Task | null;
  setSelectedTask: (task: Task) => void;
}

const TaskListInner = React.memo((props: ITaskListInnerProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const { height: windowHeight } = useWindowDimensions();
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [includeCompletedTask, setIncludeCompletedTask] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchTextLive, setSearchTextLive] = useState('');
  const [showSearchCondition, setShowSearchCondition] = useState(false);
  const [focusSearchMember, setForcusSearchMember] = useState(false);
  const [focusSearchClient, setForcusSearchClient] = useState(false);
  const [clientIds, setClientIds] = useState<string[]>([]);
  const [memberIds, setMemberIds] = useState<string[]>([]);
  const [updateTaskSortNo, _] = useUpdateTaskSortNoInFavoriteTaskMutation();

  const {
    loading: clientLoading,
    data: clientData,
    error: clientError,
  } = useOrganizationClientsQuery({
    variables: {
      organizationId: loginUser!.organizationId,
    },
    fetchPolicy: 'network-only',
  });

  const {
    loading: memberLoading,
    data: memberData,
    error: memberError,
  } = useOrganizationMembersQuery({
    variables: {
      organizationId: loginUser!.organizationId,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    setIncludeCompletedTask(false);
  }, []);

  const renderItem = useCallback(
    (item: Task, index) => {
      const isSelected = props.selectedTask?.id === item.id;
      return (
        <Item isSelected={isSelected}>
          {(props.organization.plan.code === Plan.Business ||
            props.organization.plan.code === Plan.Enterprise) && (
            <>
              <Typography
                variant={TypographyType.Normal}
                ellipsis={true}
                style={{ color: isSelected ? '#FFFFFF' : undefined, maxWidth: '30vw' }}>
                {item.project.team.name}
              </Typography>
              <Typography
                variant={TypographyType.Normal}
                style={{
                  marginHorizontal: 5,
                  color: isSelected ? '#FFFFFF' : themeContext.colors.description,
                }}>
                {'>'}
              </Typography>
            </>
          )}
          <View style={{ flexDirection: 'column' }}>
            {item.project.client && (
              <Typography
                variant={TypographyType.Normal}
                ellipsis={true}
                style={{
                  color: isSelected ? '#FFFFFF' : themeContext.colors.description,
                  fontSize: 9,
                  lineHeight: 10,
                  maxWidth: '30vw',
                }}>
                {item.project.client.name}
                {'\n'}
              </Typography>
            )}
            <Typography
              variant={TypographyType.Normal}
              ellipsis={true}
              style={{
                color: isSelected ? '#FFFFFF' : undefined,
                lineHeight: 16,
                maxWidth: '30vw',
              }}>
              {item.project.name}
            </Typography>
          </View>
          <Typography
            variant={TypographyType.Normal}
            style={{
              marginHorizontal: 5,
              color: isSelected ? '#FFFFFF' : themeContext.colors.description,
            }}>
            {'>'}
          </Typography>
          <Typography
            variant={TypographyType.Normal}
            ellipsis={true}
            style={{ color: isSelected ? '#FFFFFF' : undefined, maxWidth: '30vw' }}>
            {item.title}
          </Typography>
        </Item>
      );
    },
    [props.selectedTask]
  );

  const getKey = useCallback((task) => task!.id!.toString(), []);

  if (props.tasks.length === 0) {
    return (
      <Typography
        variant={TypographyType.Description}
        style={{ marginLeft: 10, fontSize: 18, marginVertical: 30 }}>
        お気に入りタスクがありません
      </Typography>
    );
  }
  return (
    <>
      <Form style={{ marginBottom: 10, zIndex: 3 }}>
        <Input
          name={'search'}
          label={''}
          autoFocus
          value={searchText}
          placeholder={'プロジェクト名かタスク名で検索する'}
          icon={<SearchIcon size={20} containerStyle={{ marginLeft: 10 }} />}
          inputstyle={{ borderWidth: 0, borderBottomWidth: 0, paddingVertical: 10, lineHeight: 20 }}
          containerStyle={{ overflow: 'hidden', flex: 1 }}
          inputContainerStyle={{
            paddingHorizontal: 10,
            backgroundColor: themeContext.colors.baseColor,
            borderWidth: 1,
            borderRadius: 20,
            borderColor: themeContext.colors.separator,
          }}
          onChange={(value: string) => {
            setSearchTextLive(value);
            if (value?.trim() === '') {
              setSearchText('');
            }
          }}
          onPressEnterIncludeIMEConvert={(value: string) => setSearchText(value)}
          onBlur={(value: string) => setSearchText(value)}
        />
        {searchTextLive?.trim().length > 0 && (
          <Typography
            variant={TypographyType.Description}
            style={{ marginLeft: 10, fontSize: 12, textAlign: 'center', marginBottom: 10 }}>
            Enterキーを押すと絞り込みが行われます
          </Typography>
        )}
        {/* <TouchableOpacity
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginLeft: 10,
            marginTop: 10,
          }}
          onPress={() => {
            setIncludeCompletedTask(!includeCompletedTask);
          }}>
          <Checkbox
            size={16}
            value={includeCompletedTask}
            onValueChange={(value) => setIncludeCompletedTask(!includeCompletedTask)}
          />
          <Typography variant={TypographyType.Normal} style={{ marginLeft: 10, fontSize: 12 }}>
            完了したタスクを含める
          </Typography>
        </TouchableOpacity> */}
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}>
          <View>
            <TouchableOpacity
              onPress={() => setShowSearchCondition(!showSearchCondition)}
              style={{ marginLeft: 20, marginBottom: 5, marginTop: 5, flexDirection: 'row' }}>
              <Text style={{ color: '#909090' }}>{'検索条件を追加する'}</Text>
              <Text style={{ color: '#909090', marginLeft: 10 }}>
                {showSearchCondition ? '▼' : '▲'}
              </Text>
            </TouchableOpacity>
            {showSearchCondition && (
              <View style={{ marginBottom: 5 }}>
                <View
                  style={{
                    flexDirection: focusSearchClient ? 'column' : 'row',
                    //@ts-ignore
                    overflowX: focusSearchClient ? undefined : 'hidden',
                    marginBottom: 5,
                    zIndex: 2,
                  }}>
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}>
                    <MultiPickerFilter
                      placeHolder="取引先を絞り込む"
                      value={clientIds}
                      containerStyle={{ marginRight: 10 }}
                      listStyle={{ maxWidth: 280 }}
                      pickerItems={(clientData?.organizationClients?.slice() ?? [])
                        .sort((a, b) => (a!.name as any) - (b!.name as any))
                        .map((client) => {
                          return {
                            label: client!.name!,
                            value: client!.id!,
                          };
                        })}
                      onFocus={() => setForcusSearchClient(true)}
                      onBlur={(items) => {
                        setClientIds(items.map((item) => item.value));
                        setForcusSearchClient(false);
                      }}
                      renderLabel={(items) => {
                        if (items.length === 0) {
                          return (
                            <View
                              style={{
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                marginLeft: 20,
                              }}>
                              <SearchIcon size={16} />
                              <Typography
                                variant={TypographyType.Normal}
                                style={{ marginLeft: 10, fontSize: 12 }}
                                ellipsis={true}>
                                取引先で絞り込む
                              </Typography>
                            </View>
                          );
                        }
                        return (
                          <View>
                            <View
                              style={{
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                marginLeft: 20,
                              }}>
                              <SearchIcon size={16} />
                              <Typography
                                variant={TypographyType.Normal}
                                style={{ marginLeft: 10, fontSize: 12 }}
                                ellipsis={true}>
                                取引先で絞り込む
                              </Typography>
                            </View>
                            {items.map((item) => {
                              return (
                                <View style={{ marginLeft: 38 }}>
                                  <Typography
                                    variant={TypographyType.Description}
                                    ellipsis
                                    style={{
                                      fontSize: 14,
                                      lineHeight: 16,
                                    }}>
                                    {item.label}
                                  </Typography>
                                </View>
                              );
                            })}
                          </View>
                        );
                      }}
                    />
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: focusSearchMember ? 'column' : 'row',
                    //@ts-ignore
                    overflowX: focusSearchMember ? undefined : 'hidden',
                  }}>
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}>
                    <MultiPickerFilter
                      placeHolder="担当者を絞り込む"
                      value={memberIds}
                      containerStyle={{ marginRight: 10 }}
                      listStyle={{ maxWidth: 280 }}
                      pickerItems={(memberData?.organizationMembers?.slice() ?? [])
                        .sort((a, b) => (a!.name as any) - (b!.name as any))
                        .map((member) => {
                          return {
                            label: member!.name!,
                            value: member!.id!,
                          };
                        })}
                      onFocus={() => setForcusSearchMember(true)}
                      onBlur={(items) => {
                        setMemberIds(items.map((item) => item.value));
                        setForcusSearchMember(false);
                      }}
                      renderLabel={(items) => {
                        if (items.length === 0) {
                          return (
                            <View
                              style={{
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                marginLeft: 20,
                              }}>
                              <SearchIcon size={16} />
                              <Typography
                                variant={TypographyType.Normal}
                                style={{ marginLeft: 10, fontSize: 12 }}
                                ellipsis={true}>
                                担当者で絞り込む
                              </Typography>
                            </View>
                          );
                        }
                        return (
                          <View>
                            <View
                              style={{
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                marginLeft: 20,
                              }}>
                              <SearchIcon size={16} />
                              <Typography
                                variant={TypographyType.Normal}
                                style={{ marginLeft: 10, fontSize: 12 }}
                                ellipsis={true}>
                                担当者で絞り込む
                              </Typography>
                            </View>
                            {items.map((item) => {
                              return (
                                <View style={{ marginLeft: 38 }}>
                                  <Typography
                                    variant={TypographyType.Description}
                                    ellipsis
                                    style={{
                                      fontSize: 14,
                                      lineHeight: 16,
                                    }}>
                                    {item.label}
                                  </Typography>
                                </View>
                              );
                            })}
                          </View>
                        );
                      }}
                    />
                  </View>
                </View>
              </View>
            )}
          </View>
        </View>
      </Form>
      <GlobalDragContextProvider>
        <VirtualizedFlatList
          style={{
            height: 'auto',
            maxHeight: windowHeight >= 870 ? 300 : windowHeight >= 720 ? '23vh' : '140px',
            borderWidth: 1,
            borderColor: themeContext.colors.separator,
            borderBottomWidth: 0,
            marginBottom: 20,
          }}
          items={props.tasks
            .filter((task) => {
              if (includeCompletedTask) {
                return true;
              }
              return task.completeDateTime === null && !task.project.completeDateTime;
            })
            .filter((task) => {
              let result = true;
              if (searchText) {
                result =
                  result &&
                  (task.title.indexOf(searchText) !== -1 ||
                    task.project.name.indexOf(searchText) !== -1);
              }
              if (memberIds && memberIds.length > 0) {
                result =
                  result &&
                  task.assignees.filter((member) => memberIds.includes(member!.member.id!)).length >
                    0;
              }
              if (clientIds && clientIds.length > 0) {
                result =
                  result && !!task.project.client && clientIds.includes(task.project.client.id!);
              }
              return result;
            })}
          renderItem={renderItem as any}
          selectableByKeyboard={true}
          getKey={getKey}
          itemHeight={43}
          onPress={(item) => {
            props.setSelectedTask(item as Task);
          }}
          virticalDraggable={true}
          onDrop={async (info) => {
            const isMoveToFirst = info.endRowIndex === 0;
            const isMoveToLast = info.endRowIndex === props.tasks.length - 1;
            const isMoveToDown = info.endRowIndex - info.startRowIndex > 0;
            let sortNo;
            if (isMoveToFirst) {
              sortNo = new Date().getTime();
            } else if (isMoveToLast) {
              sortNo =
                props.tasks[info.endRowIndex]!.favoriteMembersInfo!.filter(
                  (info) => info!.member.id === loginUser!.id
                )?.[0]?.sortNo - 1000;
            } else {
              if (isMoveToDown) {
                const beforeTask = props.tasks[info.endRowIndex];
                const afterTask = props.tasks[info.endRowIndex + 1];
                sortNo = Math.floor(
                  (beforeTask!.favoriteMembersInfo!.filter(
                    (info) => info!.member.id === loginUser!.id
                  )?.[0]?.sortNo +
                    afterTask!.favoriteMembersInfo!.filter(
                      (info) => info!.member.id === loginUser!.id
                    )?.[0]?.sortNo) /
                    2
                );
              } else {
                const beforeTask = props.tasks[info.endRowIndex - 1];
                const afterTask = props.tasks[info.endRowIndex];
                sortNo = Math.floor(
                  (beforeTask!.favoriteMembersInfo!.filter(
                    (info) => info!.member.id === loginUser!.id
                  )?.[0]?.sortNo +
                    afterTask!.favoriteMembersInfo!.filter(
                      (info) => info!.member.id === loginUser!.id
                    )?.[0]?.sortNo) /
                    2
                );
              }
            }
            await updateTaskSortNo({
              variables: {
                id: (info.item! as Task).id!,
                input: {
                  sortNo: sortNo,
                  versionNo: (info.item as Task).versionNo,
                },
              },
            });
          }}
        />
      </GlobalDragContextProvider>
    </>
  );
});

interface IProjectListInnerProps {
  projects: Project[];
  organization: Organization;
  selectedProject: Project | null;
  setSelectedProject: (project: Project) => void;
}

const ProjectListInner = React.memo((props: IProjectListInnerProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const { height: windowHeight } = useWindowDimensions();
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [includeCompletedProject, setIncludeCompletedProject] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchTextLive, setSearchTextLive] = useState('');
  const [showSearchCondition, setShowSearchCondition] = useState(false);
  const [focusSearchMember, setForcusSearchMember] = useState(false);
  const [focusSearchClient, setForcusSearchClient] = useState(false);
  const [clientIds, setClientIds] = useState<string[]>([]);
  const [memberIds, setMemberIds] = useState<string[]>([]);
  const [updateProjectSortNo] = useUpdateProjectSortNoInFavoriteProjectMutation();

  const {
    loading: clientLoading,
    data: clientData,
    error: clientError,
  } = useOrganizationClientsQuery({
    variables: {
      organizationId: loginUser!.organizationId,
    },
    fetchPolicy: 'network-only',
  });

  const {
    loading: memberLoading,
    data: memberData,
    error: memberError,
  } = useOrganizationMembersQuery({
    variables: {
      organizationId: loginUser!.organizationId,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    setIncludeCompletedProject(false);
  }, []);

  const renderItem = useCallback(
    (item: Project, index) => {
      const isSelected = props.selectedProject?.id === item.id;
      return (
        <Item isSelected={isSelected}>
          {(props.organization.plan.code === Plan.Business ||
            props.organization.plan.code === Plan.Enterprise) && (
            <>
              <Typography
                variant={TypographyType.Normal}
                ellipsis={true}
                style={{ color: isSelected ? '#FFFFFF' : undefined, maxWidth: '30vw' }}>
                {item.team.name}
              </Typography>
              <Typography
                variant={TypographyType.Normal}
                style={{
                  marginHorizontal: 5,
                  color: isSelected ? '#FFFFFF' : themeContext.colors.description,
                }}>
                {'>'}
              </Typography>
            </>
          )}
          <View style={{ flexDirection: 'column' }}>
            {item.client && (
              <Typography
                variant={TypographyType.Normal}
                ellipsis={true}
                style={{
                  color: isSelected ? '#FFFFFF' : themeContext.colors.description,
                  fontSize: 9,
                  lineHeight: 10,
                  maxWidth: '30vw',
                }}>
                {item.client.name}
                {'\n'}
              </Typography>
            )}
            <Typography
              variant={TypographyType.Normal}
              ellipsis={true}
              style={{
                color: isSelected ? '#FFFFFF' : undefined,
                lineHeight: 16,
                maxWidth: '30vw',
              }}>
              {item.name}
            </Typography>
          </View>
        </Item>
      );
    },
    [props.selectedProject]
  );

  const getKey = useCallback((project) => project!.id!.toString(), []);

  if (props.projects.length === 0) {
    return (
      <Typography
        variant={TypographyType.Description}
        style={{ marginLeft: 10, fontSize: 18, marginVertical: 30 }}>
        お気に入りプロジェクトがありません
      </Typography>
    );
  }
  return (
    <>
      <Form style={{ marginBottom: 10, zIndex: 3 }}>
        <Input
          name={'search'}
          label={''}
          autoFocus
          value={searchText}
          placeholder={'プロジェクト名で検索する'}
          icon={<SearchIcon size={20} containerStyle={{ marginLeft: 10 }} />}
          inputstyle={{ borderWidth: 0, borderBottomWidth: 0, paddingVertical: 10, lineHeight: 20 }}
          containerStyle={{ overflow: 'hidden', flex: 1 }}
          inputContainerStyle={{
            paddingHorizontal: 10,
            backgroundColor: themeContext.colors.baseColor,
            borderWidth: 1,
            borderRadius: 20,
            borderColor: themeContext.colors.separator,
          }}
          onChange={(value: string) => {
            setSearchTextLive(value);
            if (value?.trim() === '') {
              setSearchText('');
            }
          }}
          onPressEnterIncludeIMEConvert={(value: string) => setSearchText(value)}
          onBlur={(value: string) => setSearchText(value)}
        />
        {searchTextLive?.trim().length > 0 && (
          <Typography
            variant={TypographyType.Description}
            style={{ marginLeft: 10, fontSize: 12, textAlign: 'center', marginBottom: 10 }}>
            Enterキーを押すと絞り込みが行われます
          </Typography>
        )}
        {/* <TouchableOpacity
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginLeft: 10,
            marginTop: 10,
          }}
          onPress={() => {
            setIncludeCompletedProject(!includeCompletedProject);
          }}>
          <Checkbox
            size={16}
            value={includeCompletedProject}
            onValueChange={(value) => setIncludeCompletedProject(!includeCompletedProject)}
          />
          <Typography variant={TypographyType.Normal} style={{ marginLeft: 10, fontSize: 12 }}>
            完了したプロジェクトを含める
          </Typography>
        </TouchableOpacity> */}
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}>
          <View>
            <TouchableOpacity
              onPress={() => setShowSearchCondition(!showSearchCondition)}
              style={{ marginLeft: 20, marginBottom: 5, marginTop: 5, flexDirection: 'row' }}>
              <Text style={{ color: '#909090' }}>{'検索条件を追加する'}</Text>
              <Text style={{ color: '#909090', marginLeft: 10 }}>
                {showSearchCondition ? '▼' : '▲'}
              </Text>
            </TouchableOpacity>
            {showSearchCondition && (
              <View style={{ marginBottom: 5 }}>
                <View
                  style={{
                    flexDirection: focusSearchClient ? 'column' : 'row',
                    //@ts-ignore
                    overflowX: focusSearchClient ? undefined : 'hidden',
                    marginBottom: 5,
                    zIndex: 2,
                  }}>
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}>
                    <MultiPickerFilter
                      placeHolder="取引先を絞り込む"
                      value={clientIds}
                      containerStyle={{ marginRight: 10 }}
                      listStyle={{ maxWidth: 280 }}
                      pickerItems={(clientData?.organizationClients?.slice() ?? [])
                        .sort((a, b) => (a!.name as any) - (b!.name as any))
                        .map((client) => {
                          return {
                            label: client!.name!,
                            value: client!.id!,
                          };
                        })}
                      onFocus={() => setForcusSearchClient(true)}
                      onBlur={(items) => {
                        setClientIds(items.map((item) => item.value));
                        setForcusSearchClient(false);
                      }}
                      renderLabel={(items) => {
                        if (items.length === 0) {
                          return (
                            <View
                              style={{
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                marginLeft: 20,
                              }}>
                              <SearchIcon size={16} />
                              <Typography
                                variant={TypographyType.Normal}
                                style={{ marginLeft: 10, fontSize: 12 }}
                                ellipsis={true}>
                                取引先で絞り込む
                              </Typography>
                            </View>
                          );
                        }
                        return (
                          <View>
                            <View
                              style={{
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                marginLeft: 20,
                              }}>
                              <SearchIcon size={16} />
                              <Typography
                                variant={TypographyType.Normal}
                                style={{ marginLeft: 10, fontSize: 12 }}
                                ellipsis={true}>
                                取引先で絞り込む
                              </Typography>
                            </View>
                            {items.map((item) => {
                              return (
                                <View style={{ marginLeft: 38 }}>
                                  <Typography
                                    variant={TypographyType.Description}
                                    ellipsis
                                    style={{
                                      fontSize: 14,
                                      lineHeight: 16,
                                    }}>
                                    {item.label}
                                  </Typography>
                                </View>
                              );
                            })}
                          </View>
                        );
                      }}
                    />
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: focusSearchMember ? 'column' : 'row',
                    //@ts-ignore
                    overflowX: focusSearchMember ? undefined : 'hidden',
                  }}>
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}>
                    <MultiPickerFilter
                      placeHolder="担当者を絞り込む"
                      value={memberIds}
                      containerStyle={{ marginRight: 10 }}
                      listStyle={{ maxWidth: 280 }}
                      pickerItems={(memberData?.organizationMembers?.slice() ?? [])
                        .sort((a, b) => (a!.name as any) - (b!.name as any))
                        .map((member) => {
                          return {
                            label: member!.name!,
                            value: member!.id!,
                          };
                        })}
                      onFocus={() => setForcusSearchMember(true)}
                      onBlur={(items) => {
                        setMemberIds(items.map((item) => item.value));
                        setForcusSearchMember(false);
                      }}
                      renderLabel={(items) => {
                        if (items.length === 0) {
                          return (
                            <View
                              style={{
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                marginLeft: 20,
                              }}>
                              <SearchIcon size={16} />
                              <Typography
                                variant={TypographyType.Normal}
                                style={{ marginLeft: 10, fontSize: 12 }}
                                ellipsis={true}>
                                担当者で絞り込む
                              </Typography>
                            </View>
                          );
                        }
                        return (
                          <View>
                            <View
                              style={{
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                marginLeft: 20,
                              }}>
                              <SearchIcon size={16} />
                              <Typography
                                variant={TypographyType.Normal}
                                style={{ marginLeft: 10, fontSize: 12 }}
                                ellipsis={true}>
                                担当者で絞り込む
                              </Typography>
                            </View>
                            {items.map((item) => {
                              return (
                                <View style={{ marginLeft: 38 }}>
                                  <Typography
                                    variant={TypographyType.Description}
                                    ellipsis
                                    style={{
                                      fontSize: 14,
                                      lineHeight: 16,
                                    }}>
                                    {item.label}
                                  </Typography>
                                </View>
                              );
                            })}
                          </View>
                        );
                      }}
                    />
                  </View>
                </View>
              </View>
            )}
          </View>
        </View>
      </Form>
      <GlobalDragContextProvider>
        <VirtualizedFlatList
          style={{
            height: 'auto',
            maxHeight: windowHeight >= 900 ? 300 : windowHeight >= 750 ? '23vh' : '140px',
            borderWidth: 1,
            borderColor: themeContext.colors.separator,
            borderBottomWidth: 0,
            marginBottom: 20,
          }}
          items={props.projects
            .filter((project) => {
              if (includeCompletedProject) {
                return true;
              }
              return project.completeDateTime === null;
            })
            .filter((project) => {
              let result = true;
              if (searchText) {
                result = result && project.name.indexOf(searchText) !== -1;
              }
              if (memberIds && memberIds.length > 0) {
                result =
                  result &&
                  project.assignees.filter((member) => memberIds.includes(member!.member.id!))
                    .length > 0;
              }
              if (clientIds && clientIds.length > 0) {
                result = result && !!project.client && clientIds.includes(project.client.id!);
              }
              return result;
            })}
          renderItem={renderItem as any}
          selectableByKeyboard={true}
          getKey={getKey}
          itemHeight={43}
          onPress={(item) => {
            props.setSelectedProject(item as Project);
          }}
          virticalDraggable={true}
          onDrop={async (info) => {
            const isMoveToFirst = info.endRowIndex === 0;
            const isMoveToLast = info.endRowIndex === props.projects.length - 1;
            const isMoveToDown = info.endRowIndex - info.startRowIndex > 0;
            let sortNo;
            if (isMoveToFirst) {
              sortNo = new Date().getTime();
            } else if (isMoveToLast) {
              sortNo =
                props.projects[info.endRowIndex]!.favoriteMembersInfo!.filter(
                  (info) => info!.member.id === loginUser!.id
                )?.[0]?.sortNo - 1000;
            } else {
              if (isMoveToDown) {
                const beforeTask = props.projects[info.endRowIndex];
                const afterTask = props.projects[info.endRowIndex + 1];
                sortNo = Math.floor(
                  (beforeTask!.favoriteMembersInfo!.filter(
                    (info) => info!.member.id === loginUser!.id
                  )?.[0]?.sortNo +
                    afterTask!.favoriteMembersInfo!.filter(
                      (info) => info!.member.id === loginUser!.id
                    )?.[0]?.sortNo) /
                    2
                );
              } else {
                const beforeTask = props.projects[info.endRowIndex - 1];
                const afterTask = props.projects[info.endRowIndex];
                sortNo = Math.floor(
                  (beforeTask!.favoriteMembersInfo!.filter(
                    (info) => info!.member.id === loginUser!.id
                  )?.[0]?.sortNo +
                    afterTask!.favoriteMembersInfo!.filter(
                      (info) => info!.member.id === loginUser!.id
                    )?.[0]?.sortNo) /
                    2
                );
              }
            }
            await updateProjectSortNo({
              variables: {
                id: (info.item as Project).id!,
                input: {
                  sortNo: sortNo,
                  versionNo: (info.item as Project).versionNo,
                },
              },
            });
          }}
        />
      </GlobalDragContextProvider>
    </>
  );
});

interface ICreateWorkingScheduleFromCalendarEventModalProps {
  showModal: boolean;
  onPressYes: (value: WorkingSchedule) => void;
  onCloseModal: () => void;
  googleCalendarEvent: GoogleCalendarEvent | null;
  targetMember?: Member;
}

const CreateWorkingScheduleFromCalendarEventModal = React.memo(
  (props: ICreateWorkingScheduleFromCalendarEventModalProps) => {
    //TODO すでに非公開になっている予定であれば、予定名を非公開固定にするように修正する

    const themeContext: IThemePart = useContext(ThemeContext);
    const [loginUser, _] = useContext(LoginUserContext);
    const [mode, setMode] = useState<'favoriteTask' | 'favoriteProject' | 'allTask'>(
      'favoriteTask'
    );
    const [eventName, setEventName] = useState<string | null>(
      props.googleCalendarEvent?.title || null
    );
    const [hideEventName, setHideEventName] = useState(false);
    const [showMemo, setShowMemo] = useState(false);
    const [memo, setMemo] = useState('');
    const [team, setTeam] = useState<Team | null>(null);
    const [teamId, setTeamId] = useState<string | null>(null);
    const [project, setProject] = useState<Project | null>(null);
    const [projectId, setProjectId] = useState<string | null>(null);
    const [task, setTask] = useState<Task | null>(null);
    const [taskId, setTaskId] = useState<string | null>(null);
    const [newTaskTitle, setNewTaskTitle] = useState<string | null>(null);
    const [startDateTime, setStartDateTime] = useState<moment.Moment>(
      props.googleCalendarEvent?.startDateTime
        ? moment(props.googleCalendarEvent.startDateTime)
        : moment()
    );
    const [endDateTime, setEndDateTime] = useState<moment.Moment>(
      props.googleCalendarEvent?.endDateTime
        ? moment(props.googleCalendarEvent.endDateTime)
        : moment()
    );
    const { height: windowHeight, width: windowWidth } = useWindowDimensions();

    const fetchProjectById = useLazyQueryPromise<ProjectQuery, ProjectQueryVariables>(
      ProjectDocument
    );

    const fetchTaskById = useLazyQueryPromise<TaskQuery, TaskQueryVariables>(
      TaskDocument
    );
    
    const { data: organizationData, loading: organizationLoading } = useOrganizationQuery({
      variables: {
        id: loginUser!.organizationId,
      },
    });
    const fetchTeams = useTeamMemberRelationsQuery({
      fetchPolicy: 'network-only',
    });

    const joinedTeamsData = useMemo(
      () =>
        ((fetchTeams.data?.teamMemberRelations as TeamMemberRelation[]) || [])
          .slice()
          .sort((a, b) => {
            return b!.sortNo - a!.sortNo; // ソート番号は降順
          })
          .map((rel) => rel.team),
      [fetchTeams.data?.teamMemberRelations]
    );
    const { data: projectsData, loading: projectsLoading } = useTeamProjectsForSelectorQuery({
      variables: {
        teamId:
          organizationData?.organization?.plan.code === Plan.Basic ||
          organizationData?.organization?.plan.code === Plan.Professional
            ? joinedTeamsData?.[0]?.id || ''
            : teamId || '',
        input: {
          completeCondition: ProjectCompleteFilter.Incomplete,
          sortKey: ProjectSortKey.SortNo,
          sortOrder: SortOrder.Desc,
        },
      },
      skip: teamId == null || teamId === undefined,
      fetchPolicy: 'network-only',
    });

    const { data: tasksData, loading: tasksLoading } = useProjectTasksForSelectorQuery({
      variables: {
        projectId: projectId || '',
        input: {
          sortKey: TaskSortKey.ProjectSortNo,
          sortOrder: SortOrder.Desc,
        },
      },
      skip: !projectId,
      fetchPolicy: 'network-only',
    });

    const { loading, data, error } = useCustomFavoriteTasksQuery({ //TODO TDV1-112
      fetchPolicy: 'network-only',
    });

    const { loading: favoriteProjectLoading, data: favoriteProjectData } = useFavoriteProjectsQuery( //TODO TDV1-112
      {
        variables: {
          input: {
            completeCondition: ProjectCompleteFilter.Incomplete,
          },
        },
        fetchPolicy: 'network-only',
      }
    );

    const favoriteTasks = useMemo(() => {
      if (loading || !data?.favoriteTasks) {
        return [];
      }
      return data!.favoriteTasks!.slice().sort((a, b) => {
        return (
          b!.favoriteMembersInfo!.filter((info) => info!.member.id === loginUser!.id)?.[0]?.sortNo -
          a!.favoriteMembersInfo!.filter((info) => info!.member.id === loginUser!.id)?.[0]?.sortNo
        ); // ソート番号は昇順
      });
    }, [loading, data?.favoriteTasks]);

    const favoriteProjects = useMemo(() => {
      if (favoriteProjectLoading || !favoriteProjectData?.favoriteProjects) {
        return [];
      }
      return favoriteProjectData!.favoriteProjects!.slice().sort((a, b) => {
        return (
          b!.favoriteMembersInfo!.filter((info) => info!.member.id === loginUser!.id)?.[0]?.sortNo -
          a!.favoriteMembersInfo!.filter((info) => info!.member.id === loginUser!.id)?.[0]?.sortNo
        ); // ソート番号は昇順
      });
    }, [favoriteProjectLoading, favoriteProjectData?.favoriteProjects]);

    const [createCalendarEvent, __] = useCreateCalendarEventWithWorkingScheduleMutation({
      variables: {
        projectId: projectId ?? '',
        taskId: taskId ?? null,
        input: {
          startDateTime: startDateTime?.toISOString(),
          endDateTime: endDateTime?.toISOString(),
          calendarType: ThirdPartyCalendarType.GoogleCalendar,
          thirdPartyCalendarEventId: props.googleCalendarEvent?.id!,
          thirdPartyCalendarId: props.googleCalendarEvent?.calendarId!,
          eventName: eventName!,
          hideEventName: hideEventName,
          memo: memo,
          targetMemberId:
            loginUser?.id !== props.targetMember?.id ? props.targetMember?.id : undefined,
        },
      },
      update: (cache, result) => {
        cache.modify({
          fields: {
            projectCalendarEvents(existing = [], { storeFieldName }) {
              const newCalendarEvent = cache.writeQuery({
                data: result.data!.createCalendarEventWithWorkingSchedule!.calendarEvent,
                query: CalendarEventDocument,
              });
              return [...existing, newCalendarEvent];
            },
            workingSchedulesSpecifyTermForCalendar(existing = [], { storeFieldName }) {
              const targetMemberId = loginUser?.id !== props.targetMember?.id ? props.targetMember?.id : loginUser?.id;
              const newWorkingSchedule = cache.writeQuery({
                data: result.data!.createCalendarEventWithWorkingSchedule!.workingSchedule,
                query: WorkingScheduleDocument,
              });
              const targetMemberData = existing.filter((d: any) => d.memberId === targetMemberId)[0];
              if((targetMemberData?.length ?? 0) === 0){
                return [...existing, {
                  memberId: targetMemberId,
                  workingSchedules: [newWorkingSchedule]
                }];
              }
              return existing.map((data: any) => {
                if(data.memberId !== targetMemberId){
                  return data;
                }
                return {
                  memberId: data.memberId,
                  workingSchedules: [...targetMemberData.workingSchedules, newWorkingSchedule]
                }
              })
            },
          },
        });
      },
    });

    const [createCalendarEventWithTask] = useCreateCalendarEventWithTaskAndWorkingScheduleMutation({
      variables: {
        projectId: projectId! ?? '',
        input: {
          calendarEvent: {
            startDateTime: startDateTime?.toISOString(),
            endDateTime: endDateTime?.toISOString(),
            calendarType: ThirdPartyCalendarType.GoogleCalendar,
            thirdPartyCalendarEventId: props.googleCalendarEvent?.id!,
            thirdPartyCalendarId: props.googleCalendarEvent?.calendarId!,
            eventName: eventName!,
            hideEventName: hideEventName,
            targetMemberId:
              loginUser?.id !== props.targetMember?.id ? props.targetMember?.id : undefined,
          },
          task: {
            title: newTaskTitle || '',
          },
        },
      },
      update: (cache, result) => {
        cache.modify({
          fields: {
            workingSchedulesSpecifyTermForCalendar(existing = [], { storeFieldName }) {
              const targetMemberId = loginUser?.id !== props.targetMember?.id ? props.targetMember?.id : loginUser?.id;
              const newWorkingSchedule = cache.writeQuery({
                data: result.data!.createCalendarEventWithTaskAndWorkingSchedule!.workingSchedule,
                query: WorkingScheduleDocument,
              });
              const targetMemberData = existing.filter((d: any) => d.memberId === targetMemberId)[0];
              if((targetMemberData?.length ?? 0) === 0){
                return [...existing, {
                  memberId: targetMemberId,
                  workingSchedules: [newWorkingSchedule]
                }];
              }
              return existing.map((data: any) => {
                if(data.memberId !== targetMemberId){
                  return data;
                }
                return {
                  memberId: data.memberId,
                  workingSchedules: [...targetMemberData.workingSchedules, newWorkingSchedule]
                }
              })
            },
          },
        });
      },
    });

    useEffect(() => {
      if (joinedTeamsData?.[0]) {
        setTeam(joinedTeamsData[0]);
        setTeamId(joinedTeamsData[0].id!);
      } else {
        setTeam(null);
        setTeamId(null);
      }
    }, [joinedTeamsData, props.showModal]);

    useEffect(() => {
      setMode('favoriteTask');
      setProject(null);
      setProjectId(null);
      setTask(null);
      setTaskId(null);
      setNewTaskTitle(null);
      setEventName(props.googleCalendarEvent?.title || null);
      setHideEventName(false);
      setStartDateTime(
        props.googleCalendarEvent?.startDateTime
          ? moment(props.googleCalendarEvent.startDateTime)
          : moment()
      );
      setEndDateTime(
        props.googleCalendarEvent?.endDateTime
          ? moment(props.googleCalendarEvent.endDateTime)
          : moment()
      );
      setMemo('');
      setShowMemo(false);
    }, [props.showModal]);

    useEffect(() => {
      if(!!projectId && (!project || (project?.id !== projectId))){
        fetchProjectById({
          id: projectId,
        }).then(result => {
          setProject(result.data.project ?? null)
          setProjectId(result.data.project?.id!)
        })
      }
    }, [project, projectId, setProject])

    useEffect(() => {
      if(!!taskId && (!task || (task?.id !== taskId))){
        fetchTaskById({
          id: taskId,
        }).then(result => {
          setTask(result.data.task as Task ?? null)
          setTaskId(result.data.task?.id!)
        })
      }
    }, [task, taskId, setTaskId])
    
    if (organizationLoading || favoriteProjectLoading) {
      return <></>;
    }

    return (
      <Modal
        title={'作業予定の追加'}
        isShow={props.showModal}
        onClose={() => {
          props.onCloseModal();
        }}>
        <View style={{ flexDirection: 'row' }}>
          <View>
            <View
              style={{
                flexDirection: 'row',
                marginBottom: 20,
              }}>
              <MenuItem
                isActive={mode === 'favoriteTask'}
                onPress={() => {
                  setTask(null);
                  setTaskId(null);
                  setNewTaskTitle(null);
                  setProject(null);
                  setProjectId(null);
                  setMode('favoriteTask');
                }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{ fontSize: 14, lineHeight: 18, textAlign: 'center' }}>
                  {`お気に入りタスク${'\n'}から選択`}
                </Typography>
              </MenuItem>
              <MenuItem
                isActive={mode === 'favoriteProject'}
                onPress={() => {
                  setTask(null);
                  setTaskId(null);
                  setNewTaskTitle(null);
                  setProject(null);
                  setProjectId(null);
                  setMode('favoriteProject');
                }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{ fontSize: 14, lineHeight: 18, textAlign: 'center', minWidth: 170 }}>
                  {`お気に入りプロジェクト${'\n'}から選択`}
                </Typography>
              </MenuItem>
              <MenuItem
                isActive={mode === 'allTask'}
                onPress={() => {
                  setTask(null);
                  setTaskId(null);
                  setNewTaskTitle(null);
                  setProject(null);
                  setProjectId(null);
                  setMode('allTask');
                }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{ fontSize: 14, lineHeight: 18, textAlign: 'center' }}>
                  {`全てのプロジェクト${'\n'}タスクから選択`}
                </Typography>
              </MenuItem>
            </View>
            <Form style={{ minWidth: 500 }}>
              <View style={{ flexDirection: 'row', zIndex: 5 }}>
                <View style={{ flex: 1, marginHorizontal: 10 }}>
                  {mode === 'favoriteTask' && (
                    <TaskListInner
                      tasks={favoriteTasks as Task[]}
                      organization={organizationData!.organization!}
                      selectedTask={task}
                      setSelectedTask={(selectedTask) => {
                        setTeam(selectedTask.project.team);
                        setProject(selectedTask.project);
                        setProjectId(selectedTask.project?.id!);
                        setTask(selectedTask);
                        setTaskId(selectedTask?.id!);
                        setNewTaskTitle(null);
                      }}
                    />
                  )}
                  {mode === 'favoriteProject' && (
                    <>
                      <ProjectListInner
                        projects={favoriteProjects as Project[]}
                        organization={organizationData!.organization!}
                        selectedProject={project}
                        setSelectedProject={(selectedProject) => {
                          setTeam(selectedProject.team);
                          setTeamId(selectedProject.team.id!);
                          setProject(selectedProject);
                          setProjectId(selectedProject.id!);
                          setTask(null);
                          setTaskId(null);
                          setNewTaskTitle(null);
                        }}
                      />
                      <Input
                        name={'task'}
                        label={'タスクを指定したい場合には選択してください(任意)'}
                        type={'picker'}
                        isSearchable={true}
                        isCreatable={true}
                        pickerItems={tasksData?.projectTasksForSelector
                          ?.filter((task) => task!.completeDateTime === null)
                          .map((task) => {
                            return {
                              label: task!.title,
                              value: task!.id!,
                            };
                          })}
                        onChange={(value) => {
                          const targets = tasksData?.projectTasksForSelector?.filter(
                            (task) => task!.id! === value
                          );
                          if (targets && targets.length > 0) {
                            setTaskId(targets?.[0]?.id as string);
                            setNewTaskTitle(null);
                          } else {
                            setTaskId(null);
                            setNewTaskTitle(value);
                          }
                        }}
                      />
                    </>
                  )}
                  {mode === 'allTask' && (
                    <>
                      {(organizationData!.organization!.plan.code === Plan.Business ||
                        organizationData!.organization!.plan.code === Plan.Enterprise) && (
                        <Input
                          name={'team'}
                          label={'チーム'}
                          type={'picker'}
                          isSearchable={true}
                          pickerItems={joinedTeamsData?.map((team) => {
                            return {
                              label: team!.name,
                              value: team!.id!,
                            };
                          })}
                          initialValue={
                            joinedTeamsData ? ((joinedTeamsData as Team[])?.[0]?.id as string) : ''
                          }
                          onChange={(value) => {
                            const targets = joinedTeamsData?.filter((team) => team!.id! === value);
                            if (targets && targets.length > 0) {
                              setTeam(targets[0]);
                              setTeamId(targets[0].id!);
                              setProject(null)
                              setProjectId(null)
                              setTask(null)
                              setTaskId(null)
                            }
                          }}
                          validate={{
                            required: {
                              value: true,
                              message: 'チームを選択してください',
                            },
                          }}
                        />
                      )}
                      <Input
                        name={'project'}
                        label={'プロジェクト'}
                        type={'picker'}
                        isSearchable={true}
                        pickerItems={projectsData?.teamProjectsForSelector
                          ?.filter((project) => project!.complete === false)
                          .map((project) => {
                            return {
                              label: `${project!.name}${
                                project!.clientName ? ` (${project!.clientName})` : ''
                              }`,
                              value: project!.id!,
                            };
                          })}
                        onChange={(value) => {
                          const targets = projectsData?.teamProjectsForSelector?.filter(
                            (project) => project!.id! === value
                          );
                          if (targets && targets.length > 0) {
                            setProjectId(targets[0]?.id!);
                            setTask(null)
                            setTaskId(null)
                          }
                        }}
                        validate={{
                          required: {
                            value: true,
                            message: 'プロジェクトを選択してください',
                          },
                        }}
                      />
                      <Input
                        name={'task'}
                        label={'タスクを指定したい場合には選択してください(任意)'}
                        type={'picker'}
                        isSearchable={true}
                        isCreatable={true}
                        pickerItems={tasksData?.projectTasksForSelector
                          ?.filter((task) => task!.completeDateTime === null)
                          .map((task) => {
                            return {
                              label: task!.title,
                              value: task!.id!,
                            };
                          })}
                        onChange={(value) => {
                          const targets = tasksData?.projectTasksForSelector?.filter(
                            (task) => task!.id! === value
                          );
                          if (targets && targets.length > 0) {
                            setTaskId(targets[0]?.id!);
                            setNewTaskTitle(null);
                          } else {
                            setTask(null);
                            setTaskId(null);
                            setNewTaskTitle(value);
                          }
                        }}
                      />
                    </>
                  )}
                </View>
              </View>
              <Input
                name={'eventName'}
                label={'予定名'}
                type={'text'}
                readonly={true}
                inputstyle={{ borderWidth: 0 }}
                value={hideEventName ? 'カレンダーの予定（非公開）' : eventName || ''}
                onChange={(value) => {
                  setEventName(value);
                }}
              />
              <TouchableOpacity
                style={{
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  marginLeft: 10,
                  marginTop: 10,
                }}
                onPress={() => {
                  setHideEventName(!hideEventName);
                }}>
                <Checkbox
                  size={16}
                  value={hideEventName}
                  onValueChange={(value) => setHideEventName(!hideEventName)}
                />
                <Typography
                  variant={TypographyType.Normal}
                  style={{ marginLeft: 10, fontSize: 12, marginRight: 10 }}>
                  予定名を非公開にする
                </Typography>
                {OrganizationUtil.isPersonalPlan(organizationData!.organization!) ? (
                  <Typography
                    variant={TypographyType.Description}
                    style={{ marginLeft: 10, fontSize: 12 }}>
                    {`作業予定のタイトルとして保存されます。${'\n'}予定名を保存したくない場合には、予定名を非公開として登録してください。`}
                  </Typography>
                ) : (
                  <Typography
                    variant={TypographyType.Description}
                    style={{ marginLeft: 10, fontSize: 12 }}>
                    {`作業予定のタイトルとして保存されます。${'\n'}他の人に見られたくない予定の場合には、予定名を非公開として登録してください。`}
                  </Typography>
                )}
              </TouchableOpacity>
              <View
                style={{ flexDirection: 'row', alignItems: 'center', zIndex: 3, marginTop: 10 }}>
                <View style={{ minWidth: 90 }}>
                  <Typography
                    variant={TypographyType.Normal}
                    style={{ fontSize: 16, lineHeight: 22 }}>
                    開始日時
                  </Typography>
                </View>
                <EditableText
                  value={startDateTime}
                  type={'date-time-picker'}
                  textStyle={{ fontSize: 18, lineHeight: 22 }}
                  containerStyle={{
                    borderBottomWidth: 1,
                    borderBottomColor: themeContext.colors.textColor,
                  }}
                  disableClear={true}
                  onChange={(value) => setStartDateTime(value as moment.Moment)}
                />
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginTop: 20,
                  zIndex: 2,
                }}>
                <View style={{ minWidth: 90 }}>
                  <Typography
                    variant={TypographyType.Normal}
                    style={{ fontSize: 16, lineHeight: 22 }}>
                    終了日時
                  </Typography>
                </View>
                <EditableText
                  value={endDateTime}
                  type={'date-time-picker'}
                  textStyle={{ fontSize: 18, lineHeight: 22 }}
                  containerStyle={{
                    borderBottomWidth: 1,
                    borderBottomColor: themeContext.colors.textColor,
                  }}
                  disableClear={true}
                  onChange={(value) => setEndDateTime(value as moment.Moment)}
                />
              </View>
              {startDateTime?.isAfter(endDateTime) && (
                <View style={{ marginTop: 10 }}>
                  <Typography
                    variant={TypographyType.Description}
                    style={{ color: themeContext.colors.error }}>
                    開始日時は、終了日時よりも前にしてください
                  </Typography>
                </View>
              )}
              {(!startDateTime || !endDateTime) && (
                <View style={{ marginTop: 10 }}>
                  <Typography
                    variant={TypographyType.Description}
                    style={{ color: themeContext.colors.error }}>
                    開始日時・終了日時を入力してください
                  </Typography>
                </View>
              )}
              <View
                style={
                  {
                    flexDirection: 'row',
                    marginTop: 20,
                    zIndex: 2,
                    alignItems: 'flex-start',
                  } as any
                }>
                <View style={{ minWidth: 88 }}>
                  <Typography
                    variant={TypographyType.Normal}
                    style={{ fontSize: 18, lineHeight: 22 }}>
                    作業メモ
                  </Typography>
                </View>
                <View style={{ minWidth: 'calc(100% - 90px)' }}>
                  {showMemo ? (
                    <Form style={{ zIndex: 2 }}>
                      <Input
                        initialValue={''}
                        name={'description'}
                        type={'rich-text-editor'}
                        showToolBarAlways={true}
                        onChange={(value) => {
                          setMemo(value);
                        }}
                      />
                    </Form>
                  ) : (
                    <TouchableOpacity onPress={() => setShowMemo(true)}>
                      <Typography
                        variant={TypographyType.Description}
                        style={{ color: themeContext.colors.link }}>
                        作業メモを追加する
                      </Typography>
                    </TouchableOpacity>
                  )}
                </View>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  zIndex: 1,
                  marginTop: 10,
                }}>
                <Button
                  text={'登録する'}
                  style={{ minWidth: 100, marginRight: 10, marginVertical: 10 }}
                  isDisabled={
                    startDateTime?.isAfter(endDateTime) ||
                    !startDateTime ||
                    !endDateTime ||
                    !team ||
                    !project ||
                    !(eventName?.length && eventName.length > 0)
                  }
                  onPress={async () => {
                    if (task === null && newTaskTitle !== null) {
                      const { data } = await createCalendarEventWithTask();
                      props.onPressYes(
                        data!.createCalendarEventWithTaskAndWorkingSchedule!
                          .workingSchedule! as WorkingSchedule
                      );
                    } else {
                      const { data } = await createCalendarEvent();
                      props.onPressYes(
                        data!.createCalendarEventWithWorkingSchedule!
                          .workingSchedule! as WorkingSchedule
                      );
                    }
                  }}
                />
                <Button
                  text={'キャンセル'}
                  style={{
                    minWidth: 100,
                    marginRight: 10,
                    marginVertical: 10,
                    backgroundColor: 'transparent',
                  }}
                  textStyle={{ color: themeContext.colors.primary }}
                  disableValidate={true}
                  onPress={() => {
                    props.onCloseModal();
                  }}
                />
              </View>
            </Form>
          </View>
          {(project || task) && windowWidth >= 1200 && (
            <CustomScrollView
              style={{
                borderLeftColor: themeContext.colors.separator,
                borderLeftWidth: 2,
                height: 'auto',
                maxHeight: windowHeight >= 770 ? 600 : '480px',
                maxWidth: 300,
              }}>
              {project && (
                <View style={{ marginLeft: 10 }}>
                  <View>
                    <TaskInfoElement title={'プロジェクト'}>
                      <Typography
                        variant={TypographyType.Normal}
                        style={{ fontSize: 16, lineHeight: 22, fontWeight: '600' }}>
                        {project.name}
                      </Typography>
                    </TaskInfoElement>
                    <TaskInfoElement title={'担当者'}>
                      {project.assignees.length === 0 && (
                        <Typography
                          variant={TypographyType.Normal}
                          style={{ fontSize: 16, lineHeight: 22 }}>
                          {'-'}
                        </Typography>
                      )}
                      {project.assignees.map((info, i) => {
                        return (
                          <View
                            style={{
                              flexDirection: 'row',
                              marginBottom: 5,
                            }}
                            key={i}>
                            <Avatar
                              size={24}
                              name={info.member.name!}
                              imageUrl={info.member.profileImageUrl}
                            />
                            <Typography
                              variant={TypographyType.Normal}
                              style={{ fontSize: 16, lineHeight: 22 }}>
                              {info.member.name}
                            </Typography>
                          </View>
                        );
                      })}
                    </TaskInfoElement>
                    <TaskInfoElement title={'取引先'}>
                      <Typography
                        variant={TypographyType.Normal}
                        style={{ fontSize: 16, lineHeight: 22 }}>
                        {project.client?.name ?? '-'}
                      </Typography>
                    </TaskInfoElement>
                    <TaskInfoElement title={'管理コード'}>
                      <Typography
                        variant={TypographyType.Normal}
                        style={{ fontSize: 16, lineHeight: 22 }}>
                        {project.key ?? '-'}
                      </Typography>
                    </TaskInfoElement>
                    <TaskInfoElement title={'開始予定日'}>
                      <Typography
                        variant={TypographyType.Normal}
                        style={{ fontSize: 16, lineHeight: 22 }}>
                        {DateUtil.formatDate(project.scheduledStartDateTime)}
                      </Typography>
                    </TaskInfoElement>
                    <TaskInfoElement title={'〆切日'}>
                      <Typography
                        variant={TypographyType.Normal}
                        style={{ fontSize: 16, lineHeight: 22 }}>
                        {DateUtil.formatDate(project.scheduledEndDateTime)}
                      </Typography>
                    </TaskInfoElement>
                  </View>
                </View>
              )}
              {task && (
                <View
                  style={{
                    marginLeft: 10,
                    borderTopColor: themeContext.colors.separator,
                    borderTopWidth: 2,
                  }}>
                  <View>
                    <TaskInfoElement title={'タスク'}>
                      <Typography
                        variant={TypographyType.Normal}
                        style={{ fontSize: 16, lineHeight: 22, fontWeight: '600' }}>
                        {task.title}
                      </Typography>
                    </TaskInfoElement>
                    <TaskInfoElement title={'担当者'}>
                      {task.assignees.length === 0 && (
                        <Typography
                          variant={TypographyType.Normal}
                          style={{ fontSize: 16, lineHeight: 22 }}>
                          {'-'}
                        </Typography>
                      )}
                      {task.assignees.map((info, i) => {
                        return (
                          <View
                            style={{
                              flexDirection: 'row',
                              marginBottom: 5,
                            }}
                            key={i}>
                            <Avatar
                              size={24}
                              name={info.member.name!}
                              imageUrl={info.member.profileImageUrl}
                            />
                            <Typography
                              variant={TypographyType.Normal}
                              style={{ fontSize: 16, lineHeight: 22 }}>
                              {info.member.name}
                            </Typography>
                          </View>
                        );
                      })}
                    </TaskInfoElement>
                    <TaskInfoElement title={'優先度'}>
                      <Typography
                        variant={TypographyType.Normal}
                        style={{ fontSize: 16, lineHeight: 22 }}>
                        {when(task!.priority)
                          .on(
                            (v) => v === Priority.High,
                            () => '高'
                          )
                          .on(
                            (v) => v === Priority.Normal,
                            () => '中'
                          )
                          .on(
                            (v) => v === Priority.Low,
                            () => '低'
                          )
                          .otherwise(() => '-')}
                      </Typography>
                    </TaskInfoElement>
                    <TaskInfoElement title={'開始予定日'}>
                      <Typography
                        variant={TypographyType.Normal}
                        style={{ fontSize: 16, lineHeight: 22 }}>
                        {DateUtil.formatDateTime(task.scheduledStartDateTime)}
                      </Typography>
                    </TaskInfoElement>
                    <TaskInfoElement title={'〆切日'}>
                      <Typography
                        variant={TypographyType.Normal}
                        style={{ fontSize: 16, lineHeight: 22 }}>
                        {DateUtil.formatDateTime(task.scheduledEndDateTime)}
                      </Typography>
                    </TaskInfoElement>
                    <TaskInfoElement title={'作業時間'}>
                      <Typography
                        variant={TypographyType.Normal}
                        style={{ fontSize: 16, lineHeight: 22 }}>
                        {TimeUtil.formatForTask(task.workingTimeSec)}
                      </Typography>
                    </TaskInfoElement>
                    <TaskInfoElement title={'見積時間'}>
                      <Typography
                        variant={TypographyType.Normal}
                        style={{ fontSize: 16, lineHeight: 22 }}>
                        {TimeUtil.formatForTask(task.scheduleWorkingTimeSec)}
                      </Typography>
                    </TaskInfoElement>
                    <TaskInfoElement title={'進捗率'}>
                      <Typography
                        variant={TypographyType.Normal}
                        style={{ fontSize: 16, lineHeight: 22 }}>
                        {task.progressRate ? `${task.progressRate}%` : '-'}
                      </Typography>
                    </TaskInfoElement>
                  </View>
                </View>
              )}
            </CustomScrollView>
          )}
        </View>
      </Modal>
    );
  }
);

export default CreateWorkingScheduleFromCalendarEventModal;
