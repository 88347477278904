import queryString from 'query-string';
import React, { useContext, useEffect, useState } from 'react';
import { Alert, Image, TouchableOpacity, View } from 'react-native';
import { useHistory, useLocation } from 'react-router';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import { useScreenDimensions } from 'use-dimensions';
import invalidImage from '../../../../../base64Images/invalid-invite-key/invalid-invite-key';
import login from '../../../../../base64Images/login/login';
import logoLargeBlack from '../../../../../base64Images/logo/logo-large-black';
import {
  useConfirmJoinableQuery,
  useMemberInvitationQuery,
  useParseGoogleAuthCodeWithInviteKeyMutation,
} from '../../../../../graphql/api/API';
import Button from '../../../../presentational/atoms/button';
import Form from '../../../../presentational/atoms/form';
import Input from '../../../../presentational/atoms/input';
import Link from '../../../../presentational/atoms/link';
import Separator from '../../../../presentational/atoms/separator';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import GoogleConnectDummyButton from '../../../../presentational/molecules/google-connect-dummy-button';
import OpenLinkIcon from '../../../../presentational/molecules/image-icon/open-in-new';
import Modal from '../../../../presentational/molecules/modal';
import PasswordStrengthChecker from '../../../../presentational/molecules/password-strength-checker';
import type { IThemePart } from '../../../../theme';
import GoogleSignupDescriptionModal from '../../organisms/google-signup-description-modal';

const Header = styled.View`
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  height: 100px;
  padding-left: 20px;
  padding-top: 20px;
`;

const Container = styled.View`
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  height: calc(100vh - 100px);
`;

const LeftContainer = styled.View`
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 0 50px;
`;

const RightContainer = styled.View`
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 0 50px;
`;

const RightContainerInner = styled.View`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LinkContainer = styled.View`
  margin-top: 1.5rem;
`;

const DescriptionContainerTop = styled.View`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
`;

const DescriptionContainerBottom = styled.View`
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
`;

interface ILicenseLimitModalProps {
  showModal: boolean;
  onCloseModal: () => void;
}

const LicenseLimitModal = (props: ILicenseLimitModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  return (
    <Modal
      title={'登録を進めることができません'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <View style={{ flexDirection: 'column' }}>
        <View style={{ marginTop: 10 }}>
          <Typography
            variant={TypographyType.Normal}
            style={{ textAlign: 'center' }}
            ellipsis={false}>
            {`参加しようとしている組織の利用可能ライセンス数が上限に達しているため、メンバー登録ができません。${'\n'}登録を進めるには、ライセンス数の追加、もしくは不要なメンバーを無効にする操作が必要です。${'\n'}お手数ですが、招待された組織の管理者の方へお問い合わせください。`}
          </Typography>
          <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 20 }}>
            <OpenLinkIcon size={24} containerStyle={{ marginRight: 5 }} />
            <Link
              path={
                'https://manual.timedesigner.com/v1/-147'
              }
              isExternalLink={true}>
              <Typography
                variant={TypographyType.Normal}
                style={{ fontSize: 16, color: themeContext.colors.link }}>
                {`ライセンス数の確認・変更方法について確認する`}
              </Typography>
            </Link>
          </View>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
          <Button
            text={'閉じる'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              borderColor: themeContext.colors.error,
              borderRadius: 3,
              borderWidth: 1,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.error }}
            onPress={() => {
              props.onCloseModal();
            }}
          />
        </View>
      </View>
    </Modal>
  );
};

const JoinOrganizationPage = () => {
  const [password, setPassword] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const { height, width } = useScreenDimensions();
  const [showGoogleSignupDescriptionModal, setShowGoogleSignupDescriptionModal] = useState(false);
  const history = useHistory();
  const themeContext: IThemePart = useContext(ThemeContext);
  const { search } = useLocation();
  const { loading: invitationLoading, data: invitationData } = useMemberInvitationQuery({
    variables: {
      key: queryString.parse(search).key as string,
    },
  });
  const { loading: joinableLoading, data: joinableData } = useConfirmJoinableQuery({
    variables: {
      key: queryString.parse(search).key as string,
    },
  });
  const [parseGoogleAuthCode, parseGoogleAuthCodeResult] =
    useParseGoogleAuthCodeWithInviteKeyMutation({
      onError: (e) => {
        if (e.graphQLErrors.find((ge) => ge.extensions?.code === 'google-account-already-used')) {
          window.alert('このGoogleアカウントは、すでに他のユーザーで登録されています');
          window.location.reload();
        }
        if (e.graphQLErrors.find((ge) => ge.extensions?.code === 'mail-address-already-used')) {
          window.alert('このメールアドレスは、すでに他のユーザーで登録されています');
          window.location.reload();
        }
      },
    });

  useEffect(() => {
    setShowErrorModal(!joinableData?.confirmJoinable);
  }, [joinableData]);

  if (invitationLoading || joinableLoading) {
    return <></>;
  }

  if (invitationData?.memberInvitation === null) {
    return (
      <>
        <Header>
          <Image
            source={{ uri: logoLargeBlack }}
            resizeMode="contain"
            style={{
              width: 300,
              height: 100,
            }}
          />
        </Header>
        <Container>
          <LeftContainer>
            <Image
              source={{ uri: invalidImage }}
              resizeMode="contain"
              style={{
                width: Math.max(width / 4, 500),
                height: Math.max(width / 4, 500),
              }}
            />
          </LeftContainer>
          <RightContainer>
            <RightContainerInner>
              <Typography
                variant={TypographyType.Title}
                style={{ marginVertical: 10, textAlign: 'center' }}>
                この招待URLは無効です
              </Typography>
              <DescriptionContainerTop>
                <Typography
                  variant={TypographyType.Description}
                  style={{ textAlign: 'center', fontSize: 14, lineHeight: 28 }}>
                  {`このリンクは無効になっているか、すでに使用済みです。${'\n\n'}すでに会員登録済みの場合には、${'\n'}ログイン画面からログインしてください。${'\n\n'}もし組織への参加が出来ずにお困りでしたらば、${'\n'}招待者の方に問い合わせて、${'\n'}再度招待URLを発行してもらってください。`}
                </Typography>
              </DescriptionContainerTop>
              <LinkContainer>
                <Link path={'/signin'}>
                  <Typography
                    variant={TypographyType.MenuItemActive}
                    style={{ color: themeContext.colors.link }}>
                    ログインする
                  </Typography>
                </Link>
              </LinkContainer>
            </RightContainerInner>
          </RightContainer>
        </Container>
      </>
    );
  }

  return (
    <>
      <Header>
        <Image
          source={{ uri: logoLargeBlack }}
          resizeMode="contain"
          style={{
            width: 300,
            height: 100,
          }}
        />
      </Header>
      <Container>
        <LeftContainer>
          <Image
            source={{ uri: login }}
            resizeMode="contain"
            style={{
              width: width / 4,
              height: width / 4,
            }}
          />
        </LeftContainer>
        <RightContainer>
          <RightContainerInner>
            <Typography
              variant={TypographyType.Title}
              style={{ marginVertical: 10, textAlign: 'center' }}>
              「{invitationData?.memberInvitation!.organizationName}」に参加する
            </Typography>
            <DescriptionContainerTop>
              <Typography variant={TypographyType.Description}>Time Designerの</Typography>
              <Link path="https://timedesigner.com/terms.html" isExternalLink={true}>
                <Typography
                  variant={TypographyType.Description}
                  style={{ color: themeContext.colors.link, fontWeight: '600' }}>
                  利用規約
                </Typography>
              </Link>
              <Typography variant={TypographyType.Description}>・</Typography>
              <Link path="https://timedesigner.com/privacy-policy.html" isExternalLink={true}>
                <Typography
                  variant={TypographyType.Description}
                  style={{ color: themeContext.colors.link, fontWeight: '600' }}>
                  プライバシーポリシー
                </Typography>
              </Link>
              <Typography variant={TypographyType.Description}>に</Typography>
            </DescriptionContainerTop>
            <DescriptionContainerBottom>
              <Typography variant={TypographyType.Description}>
                同意してアカウントを作成します。
              </Typography>
            </DescriptionContainerBottom>
            <TouchableOpacity
              disabled={!joinableData?.confirmJoinable}
              onPress={() => setShowGoogleSignupDescriptionModal(true)}>
              <GoogleConnectDummyButton text="Googleアカウントで会員登録する" />
            </TouchableOpacity>
            {parseGoogleAuthCodeResult.data &&
              parseGoogleAuthCodeResult.data!.parseGoogleAuthCodeWithInviteKey === null && (
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    marginVertical: 10,
                    textAlign: 'center',
                    color: themeContext.colors.error,
                  }}>
                  {`招待されたメールアドレスと${'\n'}同じメールアドレスのGoogleアカウントではありません。`}
                </Typography>
              )}
            <Separator text={'または'} outerStyle={{ marginVertical: 30 }} />
            <Form style={{ marginBottom: 30 }}>
              <Input
                name={'mailAddress'}
                type={'text'}
                label={'メールアドレス'}
                initialValue={'招待メールを受信したメールアドレス'}
                readonly={true}
              />
              <Input
                name={'password'}
                type={'password'}
                label={'パスワード'}
                readonly={!joinableData?.confirmJoinable}
                validate={{
                  required: {
                    value: true,
                    message: 'パスワードを入力してください',
                  },
                  minLength: {
                    value: 8,
                    message: 'パスワードは8文字以上で入力してください',
                  },
                }}
                onChange={(value: string) => setPassword(value)}
              />
              <PasswordStrengthChecker password={password} />
              <Button
                text="メールアドレスで登録する"
                style={{ marginTop: 20, minWidth: 300 }}
                isDisabled={!joinableData?.confirmJoinable}
                onPress={async () => {
                  history.push({
                    pathname: '/join/input-profile',
                    state: {
                      key: queryString.parse(search).key as string,
                      password: password,
                      name: invitationData?.memberInvitation?.name,
                      specifyNameByInvitaion: !!invitationData?.memberInvitation?.name,
                    },
                  });
                }}
              />
            </Form>
          </RightContainerInner>
        </RightContainer>
      </Container>
      <LicenseLimitModal
        showModal={showErrorModal}
        onCloseModal={() => {
          setShowErrorModal(false);
        }}
      />
      <GoogleSignupDescriptionModal
        showModal={showGoogleSignupDescriptionModal}
        onCloseModal={() => setShowGoogleSignupDescriptionModal(false)}
        onSuccess={async (response) => {
          const result = await parseGoogleAuthCode({
            variables: {
              code: response.code!,
              key: queryString.parse(search).key as string,
            },
          });

          //招待されたメアドと、Googleアカウントのメアドが一致しない場合には登録させない
          if (result.data!.parseGoogleAuthCodeWithInviteKey === null) {
            return;
          }

          setShowGoogleSignupDescriptionModal(false);
          history.push({
            pathname: '/join/input-profile',
            state: {
              key: queryString.parse(search).key as string,
              mailAddress: result.data!.parseGoogleAuthCodeWithInviteKey!.mailAddress!,
              googleAuthCode: response.code!,
              name:
                invitationData?.memberInvitation?.name ??
                result.data!.parseGoogleAuthCodeWithInviteKey!.name!,
              profileImageUrl: result.data!.parseGoogleAuthCodeWithInviteKey!.profileImageUrl,
            },
          });
        }}
      />
    </>
  );
};

export default React.memo(JoinOrganizationPage);
