import Constants from 'expo-constants';
import React, { useContext } from 'react';
import GoogleLogin, { type GoogleLoginResponse, type GoogleLoginResponseOffline } from 'react-google-login';
import { Image, View } from 'react-native';
//@ts-ignore
import { ThemeContext } from 'styled-components/native';
import googleLoginImageUrl from '../../../../../base64Images/google-login-description';
import Button from '../../../../presentational/atoms/button';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import Modal from '../../../../presentational/molecules/modal';
import type { IThemePart } from '../../../../theme';

interface IGoogleSignupDescriptionModal {
  showModal: boolean;
  onCloseModal: () => void;
  onSuccess: (response: GoogleLoginResponse | GoogleLoginResponseOffline) => void;
}

const GoogleSignupDescriptionModal = (props: IGoogleSignupDescriptionModal) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  return (
    <Modal
      title={'Googleアカウントでの登録について'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <View style={{ flexDirection: 'column' }}>
        <View style={{ marginTop: 10 }}>
          <Typography
            variant={TypographyType.Normal}
            style={{ textAlign: 'center', fontSize: 13 }}
            ellipsis={false}>
            {`「次へ」をクリックしてください。${'\n'}Googleアカウントへのログインダイアログが表示された際に、${'\n'}チェックボックスが表示された場合には${'\n'}全てクリックしてONにしてください。`}
          </Typography>
          <Typography
            variant={TypographyType.Normal}
            style={{ textAlign: 'center', fontSize: 11 }}
            ellipsis={false}>
            {`※OFFにするとGoogleカレンダー連携等が有効になりません。`}
          </Typography>
        </View>
        <Image
          style={{ width: 800, height: 400 }}
          resizeMode={'contain'}
          source={{ uri: googleLoginImageUrl }}
        />
        <GoogleLogin
          clientId={Constants.manifest!.extra!.googleClientId}
          buttonText="Googleアカウントで会員登録する"
          scope={
            'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/admin.directory.user.readonly https://www.googleapis.com/auth/admin.directory.resource.calendar.readonly'
          }
          responseType={'code'}
          accessType={'offline'}
          prompt={'consent'}
          onSuccess={async (response) => {
            props.onSuccess(response);
          }}
          cookiePolicy={'single_host_origin'}
          render={(props) => {
            return (
              <Button
                text={'次へ'}
                style={{
                  flex: 1,
                  width: '80%',
                  marginVertical: 10,
                }}
                textStyle={{
                  backgroundColor: themeContext.colors.primary,
                  color: '#FFFFFF',
                  padding: 5,
                }}
                onPress={() => props.onClick()}
              />
            );
          }}
        />
      </View>
    </Modal>
  );
};

export default React.memo(GoogleSignupDescriptionModal);
