import React, { useContext } from 'react';
//@ts-ignore
import { ThemeContext } from 'styled-components/native';
import { View } from 'react-native';
import { IThemePart } from '../../../../../../../theme';
import {
  Organization,
  ReportType,
  useGraphTotalWorkingTimeSecQuery,
} from '../../../../../../../../graphql/api/API';
import { LoginUserContext } from '../../../../../../../../modules/auth/LoginUserContext';
import moment from 'moment-timezone';
import TimeUtil from '../../../../../../../../util/TimeUtil';
import Typography, { TypographyType } from '../../../../../../../presentational/atoms/typography';

interface IGraphTotalWorkingTimeSecProps {
  reportType: ReportType;
  organization: Organization;
  teamIds: string[];
  setTeamIds: (value: string[]) => void;
  projectIds: string[];
  setProjectIds: (value: string[]) => void;
  clientIds: string[];
  setClientIds: (value: string[]) => void;
  memberIds: string[];
  setMemberIds: (value: string[]) => void;
  teamId: string | null;
  memberId: string | null;
  start: moment.Moment;
  end: moment.Moment;
}

const GraphTotalWorkingTimeSec = (props: IGraphTotalWorkingTimeSecProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser] = useContext(LoginUserContext);

  const { loading, data } = useGraphTotalWorkingTimeSecQuery({
    variables: {
      input: {
        reportType: props.reportType,
        teamIds: [],
        projectIds: [],
        clientIds: [],
        memberIds: [],
        organizationId: loginUser!.organizationId,
        teamId: props.teamId,
        memberId: props.memberId,
        start: moment(props.start).startOf('day').toISOString(),
        end: moment(props.end).endOf('day').toISOString(),
        timeZoneOffset: moment().tz(moment.tz.guess()).format('Z'),
      },
    },
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <></>;
  }

  return (
    <View style={{ marginTop: 10, marginRight: 10 }}>
      <Typography
        variant={TypographyType.Normal}
        style={{
          fontSize: 8,
          lineHeight: 12,
          textAlign: 'center',
          color: themeContext.colors.description,
        }}>
        合計作業時間
      </Typography>
      <Typography
        variant={TypographyType.Normal}
        style={{ color: themeContext.colors.description, fontSize: 15, textAlign: 'center' }}>
        {TimeUtil.formatForTask(data?.graphTotalWorkingTimeSec ?? 0)}
      </Typography>
    </View>
  );
};

export default GraphTotalWorkingTimeSec;
