import React, { useCallback, useContext, useMemo, useState } from 'react';
import { View, Image, TouchableOpacity } from 'react-native';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../../theme';
import TaskSummary from '../task-summary';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import { useHistory, useLocation, useParams } from 'react-router';
import {
  Organization,
  Plan,
  SortKey,
  SortOrder,
  Task,
  TaskCompleteFilter,
  useAssignedTasksQuery,
  useMeQuery,
  useOrganizationQuery,
  useSearchTasksQuery,
  useUpdateTaskSortNoInMyTaskMutation,
} from '../../../../../graphql/api/API';
import VirtualizedFlatList, {
  GlobalDragContextProvider,
} from '../../../../presentational/atoms/list2/virtualized-flat-list';
import useDimensions from 'use-dimensions';
import noData04 from '../../../../../base64Images/no-data/no-data-4';
import { TaskFilter, TaskFilterContext } from '../../templates/app/my-tasks';
import UrlUtil from '../../../../../util/UrlUtil';
import queryString from 'query-string';
import moment from 'moment-timezone';
import when from '../../../../../lang-extention/When';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import CaretSetIcon from '../../../../presentational/molecules/image-icon/caret-set';
import CaretDownIcon from '../../../../presentational/molecules/image-icon/caret-down';
import CaretUpIcon from '../../../../presentational/molecules/image-icon/caret-up';

const Container = styled.View``;

const TaskListWrapper = styled.View`
  height: calc(100vh - 57px - 90px);
  overflow-y: scroll;
  scrollbar-width: none;
`;
// 本当はoverflow-xはvisibleにしたいのだが、CSSの仕様で、overflow-yを指定した場合には、xの挙動もそちらで上書きされるため実現できない

interface IHeaderProps {
  organization: Organization;
  searchCondition: any;
}

const Header = (props: IHeaderProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const { window } = useDimensions();
  const windowWidth = window.width;
  const history = useHistory();
  return (
    <View
      style={{
        marginTop: 10,
        paddingRight: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}>
      <View style={{ flexDirection: 'row', marginLeft: 50 }}>
        {(props.organization.plan.code === Plan.Business ||
          props.organization.plan.code === Plan.Enterprise) &&
          windowWidth >= 1550 && (
            <TouchableOpacity
              style={{
                flexDirection: 'row',
                alignItems: 'flex-start',
                paddingHorizontal: 5,
                width: 150,
              }}
              onPress={() => {
                history.push(
                  queryString.stringifyUrl({
                    url: `/app/${props.organization.id!}/my/assigned-task/list/`,
                    query: Object.assign(props.searchCondition, {
                      sortKey: when(props.searchCondition?.sortOrder) //
                        .on(
                          (v) => v === null || v === undefined,
                          () => SortKey.TeamName
                        )
                        .on(
                          (v) => v === SortOrder.Asc,
                          () => SortKey.TeamName
                        )
                        .otherwise(() => null),
                      sortOrder: when(props.searchCondition?.sortOrder) //
                        .on(
                          (v) => v === null || v === undefined,
                          () => SortOrder.Asc
                        )
                        .on(
                          (v) => v === SortOrder.Asc,
                          () => SortOrder.Desc
                        )
                        .otherwise(() => null),
                    }),
                  })
                );
              }}>
              {props.searchCondition?.sortKey === SortKey.TeamName ? (
                when(props.searchCondition?.sortOrder) //
                  .on(
                    (v) => v === null || v === undefined,
                    () => (
                      <CaretSetIcon
                        size={12}
                        containerStyle={{
                          opacity: 0.2,
                          marginLeft: 3,
                        }}
                        onPress={() => {
                          history.push(
                            queryString.stringifyUrl({
                              url: `/app/${props.organization.id!}/my/assigned-task/list/`,
                              query: Object.assign(props.searchCondition, {
                                sortKey: SortKey.TeamName,
                                sortOrder: SortOrder.Asc,
                              }),
                            })
                          );
                        }}
                      />
                    )
                  )
                  .on(
                    (v) => v === SortOrder.Asc,
                    () => (
                      <CaretUpIcon
                        size={10}
                        containerStyle={{ marginLeft: 3 }}
                        onPress={() => {
                          history.push(
                            queryString.stringifyUrl({
                              url: `/app/${props.organization.id!}/my/assigned-task/list/`,
                              query: Object.assign(props.searchCondition, {
                                sortKey: SortKey.TeamName,
                                sortOrder: SortOrder.Desc,
                              }),
                            })
                          );
                        }}
                      />
                    )
                  )
                  .otherwise(() => {
                    return (
                      <CaretDownIcon
                        size={10}
                        containerStyle={{ marginLeft: 3 }}
                        onPress={() => {
                          history.push(
                            queryString.stringifyUrl({
                              url: `/app/${props.organization.id!}/my/assigned-task/list/`,
                              query: Object.assign(props.searchCondition, {
                                sortKey: null,
                                sortOrder: null,
                              }),
                            })
                          );
                        }}
                      />
                    );
                  })
              ) : (
                <CaretSetIcon
                  size={12}
                  containerStyle={{
                    opacity: 0.2,
                    marginLeft: 3,
                  }}
                  onPress={() => {
                    history.push(
                      queryString.stringifyUrl({
                        url: `/app/${props.organization.id!}/my/assigned-task/list/`,
                        query: Object.assign(props.searchCondition, {
                          sortKey: SortKey.TeamName,
                          sortOrder: SortOrder.Asc,
                        }),
                      })
                    );
                  }}
                />
              )}
              <Typography
                variant={TypographyType.Normal}
                style={{
                  fontSize: 12,
                  color: themeContext.colors.description,
                  textAlign: 'left',
                }}>
                チーム名
              </Typography>
            </TouchableOpacity>
          )}
        <TouchableOpacity
          style={{
            flexDirection: 'row',
            alignItems: 'flex-start',
            paddingHorizontal: 5,
            width: 200,
          }}
          onPress={() => {
            history.push(
              queryString.stringifyUrl({
                url: `/app/${props.organization.id!}/my/assigned-task/list/`,
                query: Object.assign(props.searchCondition, {
                  sortKey: when(props.searchCondition?.sortOrder) //
                    .on(
                      (v) => v === null || v === undefined,
                      () => SortKey.ProjectName
                    )
                    .on(
                      (v) => v === SortOrder.Asc,
                      () => SortKey.ProjectName
                    )
                    .otherwise(() => null),
                  sortOrder: when(props.searchCondition?.sortOrder) //
                    .on(
                      (v) => v === null || v === undefined,
                      () => SortOrder.Asc
                    )
                    .on(
                      (v) => v === SortOrder.Asc,
                      () => SortOrder.Desc
                    )
                    .otherwise(() => null),
                }),
              })
            );
          }}>
          {props.searchCondition?.sortKey === SortKey.ProjectName ? (
            when(props.searchCondition?.sortOrder) //
              .on(
                (v) => v === null || v === undefined,
                () => (
                  <CaretSetIcon
                    size={12}
                    containerStyle={{
                      opacity: 0.2,
                      marginLeft: 3,
                    }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: `/app/${props.organization.id!}/my/assigned-task/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: SortKey.ProjectName,
                            sortOrder: SortOrder.Asc,
                          }),
                        })
                      );
                    }}
                  />
                )
              )
              .on(
                (v) => v === SortOrder.Asc,
                () => (
                  <CaretUpIcon
                    size={10}
                    containerStyle={{ marginLeft: 3 }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: `/app/${props.organization.id!}/my/assigned-task/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: SortKey.ProjectName,
                            sortOrder: SortOrder.Desc,
                          }),
                        })
                      );
                    }}
                  />
                )
              )
              .otherwise(() => {
                return (
                  <CaretDownIcon
                    size={10}
                    containerStyle={{ marginLeft: 3 }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: `/app/${props.organization.id!}/my/assigned-task/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: null,
                            sortOrder: null,
                          }),
                        })
                      );
                    }}
                  />
                );
              })
          ) : (
            <CaretSetIcon
              size={12}
              containerStyle={{
                opacity: 0.2,
                marginLeft: 3,
              }}
              onPress={() => {
                history.push(
                  queryString.stringifyUrl({
                    url: `/app/${props.organization.id!}/my/assigned-task/list/`,
                    query: Object.assign(props.searchCondition, {
                      sortKey: SortKey.ProjectName,
                      sortOrder: SortOrder.Asc,
                    }),
                  })
                );
              }}
            />
          )}
          <Typography
            variant={TypographyType.Normal}
            style={{
              fontSize: 12,
              color: themeContext.colors.description,
              textAlign: 'left',
            }}>
            プロジェクト名
          </Typography>
        </TouchableOpacity>
        <TouchableOpacity
          style={{
            flexDirection: 'row',
            alignItems: 'flex-start',
          }}
          onPress={() => {
            history.push(
              queryString.stringifyUrl({
                url: `/app/${props.organization.id!}/my/assigned-task/list/`,
                query: Object.assign(props.searchCondition, {
                  sortKey: when(props.searchCondition?.sortOrder) //
                    .on(
                      (v) => v === null || v === undefined,
                      () => SortKey.Title
                    )
                    .on(
                      (v) => v === SortOrder.Asc,
                      () => SortKey.Title
                    )
                    .otherwise(() => null),
                  sortOrder: when(props.searchCondition?.sortOrder) //
                    .on(
                      (v) => v === null || v === undefined,
                      () => SortOrder.Asc
                    )
                    .on(
                      (v) => v === SortOrder.Asc,
                      () => SortOrder.Desc
                    )
                    .otherwise(() => null),
                }),
              })
            );
          }}>
          {props.searchCondition?.sortKey === SortKey.Title ? (
            when(props.searchCondition?.sortOrder) //
              .on(
                (v) => v === null || v === undefined,
                () => (
                  <CaretSetIcon
                    size={12}
                    containerStyle={{
                      opacity: 0.2,
                      marginLeft: 3,
                    }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: `/app/${props.organization.id!}/my/assigned-task/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: SortKey.Title,
                            sortOrder: SortOrder.Asc,
                          }),
                        })
                      );
                    }}
                  />
                )
              )
              .on(
                (v) => v === SortOrder.Asc,
                () => (
                  <CaretUpIcon
                    size={10}
                    containerStyle={{ marginLeft: 3 }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: `/app/${props.organization.id!}/my/assigned-task/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: SortKey.Title,
                            sortOrder: SortOrder.Desc,
                          }),
                        })
                      );
                    }}
                  />
                )
              )
              .otherwise(() => {
                return (
                  <CaretDownIcon
                    size={10}
                    containerStyle={{ marginLeft: 3 }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: `/app/${props.organization.id!}/my/assigned-task/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: null,
                            sortOrder: null,
                          }),
                        })
                      );
                    }}
                  />
                );
              })
          ) : (
            <CaretSetIcon
              size={12}
              containerStyle={{
                opacity: 0.2,
                marginLeft: 3,
              }}
              onPress={() => {
                history.push(
                  queryString.stringifyUrl({
                    url: `/app/${props.organization.id!}/my/assigned-task/list/`,
                    query: Object.assign(props.searchCondition, {
                      sortKey: SortKey.Title,
                      sortOrder: SortOrder.Asc,
                    }),
                  })
                );
              }}
            />
          )}
          <Typography
            variant={TypographyType.Normal}
            style={{ fontSize: 12, color: themeContext.colors.description }}>
            タスク名
          </Typography>
        </TouchableOpacity>
      </View>
      <View style={{ flexDirection: 'row' }}>
        <TouchableOpacity
          style={{
            flexDirection: 'row',
            alignItems: 'flex-start',
            paddingHorizontal: 5,
            width: 70,
          }}
          onPress={() => {
            history.push(
              queryString.stringifyUrl({
                url: `/app/${props.organization.id!}/my/assigned-task/list/`,
                query: Object.assign(props.searchCondition, {
                  sortKey: when(props.searchCondition?.sortOrder) //
                    .on(
                      (v) => v === null || v === undefined,
                      () => SortKey.Priority
                    )
                    .on(
                      (v) => v === SortOrder.Asc,
                      () => SortKey.Priority
                    )
                    .otherwise(() => null),
                  sortOrder: when(props.searchCondition?.sortOrder) //
                    .on(
                      (v) => v === null || v === undefined,
                      () => SortOrder.Asc
                    )
                    .on(
                      (v) => v === SortOrder.Asc,
                      () => SortOrder.Desc
                    )
                    .otherwise(() => null),
                }),
              })
            );
          }}>
          {props.searchCondition?.sortKey === SortKey.Priority ? (
            when(props.searchCondition?.sortOrder) //
              .on(
                (v) => v === null || v === undefined,
                () => (
                  <CaretSetIcon
                    size={12}
                    containerStyle={{
                      opacity: 0.2,
                      marginLeft: 3,
                    }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: `/app/${props.organization.id!}/my/assigned-task/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: SortKey.Priority,
                            sortOrder: SortOrder.Asc,
                          }),
                        })
                      );
                    }}
                  />
                )
              )
              .on(
                (v) => v === SortOrder.Asc,
                () => (
                  <CaretUpIcon
                    size={10}
                    containerStyle={{ marginLeft: 3 }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: `/app/${props.organization.id!}/my/assigned-task/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: SortKey.Priority,
                            sortOrder: SortOrder.Desc,
                          }),
                        })
                      );
                    }}
                  />
                )
              )
              .otherwise(() => {
                return (
                  <CaretDownIcon
                    size={10}
                    containerStyle={{ marginLeft: 3 }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: `/app/${props.organization.id!}/my/assigned-task/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: null,
                            sortOrder: null,
                          }),
                        })
                      );
                    }}
                  />
                );
              })
          ) : (
            <CaretSetIcon
              size={12}
              containerStyle={{
                opacity: 0.2,
                marginLeft: 3,
              }}
              onPress={() => {
                history.push(
                  queryString.stringifyUrl({
                    url: `/app/${props.organization.id!}/my/assigned-task/list/`,
                    query: Object.assign(props.searchCondition, {
                      sortKey: SortKey.Priority,
                      sortOrder: SortOrder.Asc,
                    }),
                  })
                );
              }}
            />
          )}
          <Typography
            variant={TypographyType.Normal}
            style={{
              fontSize: 12,
              color: themeContext.colors.description,
              textAlign: 'center',
            }}>
            優先度
          </Typography>
        </TouchableOpacity>

        {windowWidth > 1500 && (
          <TouchableOpacity
            style={{
              flexDirection: 'row',
              alignItems: 'flex-start',
              paddingHorizontal: 5,
              width: 90,
            }}
            onPress={() => {
              history.push(
                queryString.stringifyUrl({
                  url: `/app/${props.organization.id!}/my/assigned-task/list/`,
                  query: Object.assign(props.searchCondition, {
                    sortKey: when(props.searchCondition?.sortOrder) //
                      .on(
                        (v) => v === null || v === undefined,
                        () => SortKey.ScheduledStartDate
                      )
                      .on(
                        (v) => v === SortOrder.Asc,
                        () => SortKey.ScheduledStartDate
                      )
                      .otherwise(() => null),
                    sortOrder: when(props.searchCondition?.sortOrder) //
                      .on(
                        (v) => v === null || v === undefined,
                        () => SortOrder.Asc
                      )
                      .on(
                        (v) => v === SortOrder.Asc,
                        () => SortOrder.Desc
                      )
                      .otherwise(() => null),
                  }),
                })
              );
            }}>
            {props.searchCondition?.sortKey === SortKey.ScheduledStartDate ? (
              when(props.searchCondition?.sortOrder) //
                .on(
                  (v) => v === null || v === undefined,
                  () => (
                    <CaretSetIcon
                      size={12}
                      containerStyle={{
                        opacity: 0.2,
                        marginLeft: 3,
                      }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: `/app/${props.organization.id!}/my/assigned-task/list/`,
                            query: Object.assign(props.searchCondition, {
                              sortKey: SortKey.ScheduledStartDate,
                              sortOrder: SortOrder.Asc,
                            }),
                          })
                        );
                      }}
                    />
                  )
                )
                .on(
                  (v) => v === SortOrder.Asc,
                  () => (
                    <CaretUpIcon
                      size={10}
                      containerStyle={{ marginLeft: 3 }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: `/app/${props.organization.id!}/my/assigned-task/list/`,
                            query: Object.assign(props.searchCondition, {
                              sortKey: SortKey.ScheduledStartDate,
                              sortOrder: SortOrder.Desc,
                            }),
                          })
                        );
                      }}
                    />
                  )
                )
                .otherwise(() => {
                  return (
                    <CaretDownIcon
                      size={10}
                      containerStyle={{ marginLeft: 3 }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: `/app/${props.organization.id!}/my/assigned-task/list/`,
                            query: Object.assign(props.searchCondition, {
                              sortKey: null,
                              sortOrder: null,
                            }),
                          })
                        );
                      }}
                    />
                  );
                })
            ) : (
              <CaretSetIcon
                size={12}
                containerStyle={{
                  opacity: 0.2,
                  marginLeft: 3,
                }}
                onPress={() => {
                  history.push(
                    queryString.stringifyUrl({
                      url: `/app/${props.organization.id!}/my/assigned-task/list/`,
                      query: Object.assign(props.searchCondition, {
                        sortKey: SortKey.ScheduledStartDate,
                        sortOrder: SortOrder.Asc,
                      }),
                    })
                  );
                }}
              />
            )}
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontSize: 12,
                color: themeContext.colors.description,
                textAlign: 'center',
              }}>
              開始予定日
            </Typography>
          </TouchableOpacity>
        )}
        {windowWidth > 1400 && (
          <TouchableOpacity
            style={{
              flexDirection: 'row',
              alignItems: 'flex-start',
              paddingHorizontal: 5,
              width: 90,
            }}
            onPress={() => {
              history.push(
                queryString.stringifyUrl({
                  url: `/app/${props.organization.id!}/my/assigned-task/list/`,
                  query: Object.assign(props.searchCondition, {
                    sortKey: when(props.searchCondition?.sortOrder) //
                      .on(
                        (v) => v === null || v === undefined,
                        () => SortKey.ScheduledEndDate
                      )
                      .on(
                        (v) => v === SortOrder.Asc,
                        () => SortKey.ScheduledEndDate
                      )
                      .otherwise(() => null),
                    sortOrder: when(props.searchCondition?.sortOrder) //
                      .on(
                        (v) => v === null || v === undefined,
                        () => SortOrder.Asc
                      )
                      .on(
                        (v) => v === SortOrder.Asc,
                        () => SortOrder.Desc
                      )
                      .otherwise(() => null),
                  }),
                })
              );
            }}>
            {props.searchCondition?.sortKey === SortKey.ScheduledEndDate ? (
              when(props.searchCondition?.sortOrder) //
                .on(
                  (v) => v === null || v === undefined,
                  () => (
                    <CaretSetIcon
                      size={12}
                      containerStyle={{
                        opacity: 0.2,
                        marginLeft: 3,
                      }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: `/app/${props.organization.id!}/my/assigned-task/list/`,
                            query: Object.assign(props.searchCondition, {
                              sortKey: SortKey.ScheduledEndDate,
                              sortOrder: SortOrder.Asc,
                            }),
                          })
                        );
                      }}
                    />
                  )
                )
                .on(
                  (v) => v === SortOrder.Asc,
                  () => (
                    <CaretUpIcon
                      size={10}
                      containerStyle={{ marginLeft: 3 }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: `/app/${props.organization.id!}/my/assigned-task/list/`,
                            query: Object.assign(props.searchCondition, {
                              sortKey: SortKey.ScheduledEndDate,
                              sortOrder: SortOrder.Desc,
                            }),
                          })
                        );
                      }}
                    />
                  )
                )
                .otherwise(() => {
                  return (
                    <CaretDownIcon
                      size={10}
                      containerStyle={{ marginLeft: 3 }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: `/app/${props.organization.id!}/my/assigned-task/list/`,
                            query: Object.assign(props.searchCondition, {
                              sortKey: null,
                              sortOrder: null,
                            }),
                          })
                        );
                      }}
                    />
                  );
                })
            ) : (
              <CaretSetIcon
                size={12}
                containerStyle={{
                  opacity: 0.2,
                  marginLeft: 3,
                }}
                onPress={() => {
                  history.push(
                    queryString.stringifyUrl({
                      url: `/app/${props.organization.id!}/my/assigned-task/list/`,
                      query: Object.assign(props.searchCondition, {
                        sortKey: SortKey.ScheduledEndDate,
                        sortOrder: SortOrder.Asc,
                      }),
                    })
                  );
                }}
              />
            )}
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontSize: 12,
                color: themeContext.colors.description,
                textAlign: 'center',
              }}>
              〆切日
            </Typography>
          </TouchableOpacity>
        )}
        <TouchableOpacity
          style={{
            flexDirection: 'row',
            alignItems: 'flex-start',
            width: 70,
          }}
          onPress={() => {
            history.push(
              queryString.stringifyUrl({
                url: `/app/${props.organization.id!}/my/assigned-task/list/`,
                query: Object.assign(props.searchCondition, {
                  sortKey: when(props.searchCondition?.sortOrder) //
                    .on(
                      (v) => v === null || v === undefined,
                      () => SortKey.ProgressRate
                    )
                    .on(
                      (v) => v === SortOrder.Asc,
                      () => SortKey.ProgressRate
                    )
                    .otherwise(() => null),
                  sortOrder: when(props.searchCondition?.sortOrder) //
                    .on(
                      (v) => v === null || v === undefined,
                      () => SortOrder.Asc
                    )
                    .on(
                      (v) => v === SortOrder.Asc,
                      () => SortOrder.Desc
                    )
                    .otherwise(() => null),
                }),
              })
            );
          }}>
          {props.searchCondition?.sortKey === SortKey.WorkingTimeSec ? (
            when(props.searchCondition?.sortOrder) //
              .on(
                (v) => v === null || v === undefined,
                () => (
                  <CaretSetIcon
                    size={12}
                    containerStyle={{
                      opacity: 0.2,
                      marginLeft: 3,
                    }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: `/app/${props.organization.id!}/my/assigned-task/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: SortKey.ProgressRate,
                            sortOrder: SortOrder.Asc,
                          }),
                        })
                      );
                    }}
                  />
                )
              )
              .on(
                (v) => v === SortOrder.Asc,
                () => (
                  <CaretUpIcon
                    size={10}
                    containerStyle={{ marginLeft: 3 }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: `/app/${props.organization.id!}/my/assigned-task/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: SortKey.ProgressRate,
                            sortOrder: SortOrder.Desc,
                          }),
                        })
                      );
                    }}
                  />
                )
              )
              .otherwise(() => {
                return (
                  <CaretDownIcon
                    size={10}
                    containerStyle={{ marginLeft: 3 }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: `/app/${props.organization.id!}/my/assigned-task/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: null,
                            sortOrder: null,
                          }),
                        })
                      );
                    }}
                  />
                );
              })
          ) : (
            <CaretSetIcon
              size={12}
              containerStyle={{
                opacity: 0.2,
                marginLeft: 3,
              }}
              onPress={() => {
                history.push(
                  queryString.stringifyUrl({
                    url: `/app/${props.organization.id!}/my/assigned-task/list/`,
                    query: Object.assign(props.searchCondition, {
                      sortKey: SortKey.ProgressRate,
                      sortOrder: SortOrder.Asc,
                    }),
                  })
                );
              }}
            />
          )}
          <Typography
            variant={TypographyType.Normal}
            style={{
              fontSize: 12,
              color: themeContext.colors.description,
              textAlign: 'center',
            }}>
            進捗率
          </Typography>
        </TouchableOpacity>

        <TouchableOpacity
          style={{
            flexDirection: 'row',
            alignItems: 'flex-start',
            paddingHorizontal: 5,
            width: 85,
          }}
          onPress={() => {
            history.push(
              queryString.stringifyUrl({
                url: `/app/${props.organization.id!}/my/assigned-task/list/`,
                query: Object.assign(props.searchCondition, {
                  sortKey: when(props.searchCondition?.sortOrder) //
                    .on(
                      (v) => v === null || v === undefined,
                      () => SortKey.WorkingTimeSec
                    )
                    .on(
                      (v) => v === SortOrder.Asc,
                      () => SortKey.WorkingTimeSec
                    )
                    .otherwise(() => null),
                  sortOrder: when(props.searchCondition?.sortOrder) //
                    .on(
                      (v) => v === null || v === undefined,
                      () => SortOrder.Asc
                    )
                    .on(
                      (v) => v === SortOrder.Asc,
                      () => SortOrder.Desc
                    )
                    .otherwise(() => null),
                }),
              })
            );
          }}>
          {props.searchCondition?.sortKey === SortKey.WorkingTimeSec ? (
            when(props.searchCondition?.sortOrder) //
              .on(
                (v) => v === null || v === undefined,
                () => (
                  <CaretSetIcon
                    size={12}
                    containerStyle={{
                      opacity: 0.2,
                      marginLeft: 3,
                    }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: `/app/${props.organization.id!}/my/assigned-task/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: SortKey.WorkingTimeSec,
                            sortOrder: SortOrder.Asc,
                          }),
                        })
                      );
                    }}
                  />
                )
              )
              .on(
                (v) => v === SortOrder.Asc,
                () => (
                  <CaretUpIcon
                    size={10}
                    containerStyle={{ marginLeft: 3 }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: `/app/${props.organization.id!}/my/assigned-task/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: SortKey.WorkingTimeSec,
                            sortOrder: SortOrder.Desc,
                          }),
                        })
                      );
                    }}
                  />
                )
              )
              .otherwise(() => {
                return (
                  <CaretDownIcon
                    size={10}
                    containerStyle={{ marginLeft: 3 }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: `/app/${props.organization.id!}/my/assigned-task/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: null,
                            sortOrder: null,
                          }),
                        })
                      );
                    }}
                  />
                );
              })
          ) : (
            <CaretSetIcon
              size={12}
              containerStyle={{
                opacity: 0.2,
                marginLeft: 3,
              }}
              onPress={() => {
                history.push(
                  queryString.stringifyUrl({
                    url: `/app/${props.organization.id!}/my/assigned-task/list/`,
                    query: Object.assign(props.searchCondition, {
                      sortKey: SortKey.WorkingTimeSec,
                      sortOrder: SortOrder.Asc,
                    }),
                  })
                );
              }}
            />
          )}
          <Typography
            variant={TypographyType.Normal}
            style={{
              fontSize: 12,
              color: themeContext.colors.description,
              textAlign: 'center',
            }}>
            作業時間
          </Typography>
        </TouchableOpacity>

        <TouchableOpacity
          style={{
            flexDirection: 'row',
            alignItems: 'flex-start',
            paddingHorizontal: 5,
            width: 85,
          }}
          onPress={() => {
            history.push(
              queryString.stringifyUrl({
                url: `/app/${props.organization.id!}/my/assigned-task/list/`,
                query: Object.assign(props.searchCondition, {
                  sortKey: when(props.searchCondition?.sortOrder) //
                    .on(
                      (v) => v === null || v === undefined,
                      () => SortKey.EstimateWorkingTimeSec
                    )
                    .on(
                      (v) => v === SortOrder.Asc,
                      () => SortKey.EstimateWorkingTimeSec
                    )
                    .otherwise(() => null),
                  sortOrder: when(props.searchCondition?.sortOrder) //
                    .on(
                      (v) => v === null || v === undefined,
                      () => SortOrder.Asc
                    )
                    .on(
                      (v) => v === SortOrder.Asc,
                      () => SortOrder.Desc
                    )
                    .otherwise(() => null),
                }),
              })
            );
          }}>
          {props.searchCondition?.sortKey === SortKey.EstimateWorkingTimeSec ? (
            when(props.searchCondition?.sortOrder) //
              .on(
                (v) => v === null || v === undefined,
                () => (
                  <CaretSetIcon
                    size={12}
                    containerStyle={{
                      opacity: 0.2,
                      marginLeft: 3,
                    }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: `/app/${props.organization.id!}/my/assigned-task/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: SortKey.EstimateWorkingTimeSec,
                            sortOrder: SortOrder.Asc,
                          }),
                        })
                      );
                    }}
                  />
                )
              )
              .on(
                (v) => v === SortOrder.Asc,
                () => (
                  <CaretUpIcon
                    size={10}
                    containerStyle={{ marginLeft: 3 }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: `/app/${props.organization.id!}/my/assigned-task/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: SortKey.EstimateWorkingTimeSec,
                            sortOrder: SortOrder.Desc,
                          }),
                        })
                      );
                    }}
                  />
                )
              )
              .otherwise(() => {
                return (
                  <CaretDownIcon
                    size={10}
                    containerStyle={{ marginLeft: 3 }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: `/app/${props.organization.id!}/my/assigned-task/list/`,
                          query: Object.assign(props.searchCondition, {
                            sortKey: null,
                            sortOrder: null,
                          }),
                        })
                      );
                    }}
                  />
                );
              })
          ) : (
            <CaretSetIcon
              size={12}
              containerStyle={{
                opacity: 0.2,
                marginLeft: 3,
              }}
              onPress={() => {
                history.push(
                  queryString.stringifyUrl({
                    url: `/app/${props.organization.id!}/my/assigned-task/list/`,
                    query: Object.assign(props.searchCondition, {
                      sortKey: SortKey.EstimateWorkingTimeSec,
                      sortOrder: SortOrder.Asc,
                    }),
                  })
                );
              }}
            />
          )}
          <Typography
            variant={TypographyType.Normal}
            style={{
              fontSize: 12,
              color: themeContext.colors.description,
              textAlign: 'center',
            }}>
            見積時間
          </Typography>
        </TouchableOpacity>

        {windowWidth > 1600 && (
          <TouchableOpacity
            style={{
              flexDirection: 'row',
              alignItems: 'flex-start',
              paddingHorizontal: 5,
              width: 100,
            }}
            onPress={() => {
              history.push(
                queryString.stringifyUrl({
                  url: `/app/${props.organization.id!}/my/assigned-task/list/`,
                  query: Object.assign(props.searchCondition, {
                    sortKey: when(props.searchCondition?.sortOrder) //
                      .on(
                        (v) => v === null || v === undefined,
                        () => SortKey.DeviationRate
                      )
                      .on(
                        (v) => v === SortOrder.Asc,
                        () => SortKey.DeviationRate
                      )
                      .otherwise(() => null),
                    sortOrder: when(props.searchCondition?.sortOrder) //
                      .on(
                        (v) => v === null || v === undefined,
                        () => SortOrder.Asc
                      )
                      .on(
                        (v) => v === SortOrder.Asc,
                        () => SortOrder.Desc
                      )
                      .otherwise(() => null),
                  }),
                })
              );
            }}>
            {props.searchCondition?.sortKey === SortKey.DeviationRate ? (
              when(props.searchCondition?.sortOrder) //
                .on(
                  (v) => v === null || v === undefined,
                  () => (
                    <CaretSetIcon
                      size={12}
                      containerStyle={{
                        opacity: 0.2,
                        marginLeft: 3,
                      }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: `/app/${props.organization.id!}/my/assigned-task/list/`,
                            query: Object.assign(props.searchCondition, {
                              sortKey: SortKey.DeviationRate,
                              sortOrder: SortOrder.Asc,
                            }),
                          })
                        );
                      }}
                    />
                  )
                )
                .on(
                  (v) => v === SortOrder.Asc,
                  () => (
                    <CaretUpIcon
                      size={10}
                      containerStyle={{ marginLeft: 3 }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: `/app/${props.organization.id!}/my/assigned-task/list/`,
                            query: Object.assign(props.searchCondition, {
                              sortKey: SortKey.DeviationRate,
                              sortOrder: SortOrder.Desc,
                            }),
                          })
                        );
                      }}
                    />
                  )
                )
                .otherwise(() => {
                  return (
                    <CaretDownIcon
                      size={10}
                      containerStyle={{ marginLeft: 3 }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: `/app/${props.organization.id!}/my/assigned-task/list/`,
                            query: Object.assign(props.searchCondition, {
                              sortKey: null,
                              sortOrder: null,
                            }),
                          })
                        );
                      }}
                    />
                  );
                })
            ) : (
              <CaretSetIcon
                size={12}
                containerStyle={{
                  opacity: 0.2,
                  marginLeft: 3,
                }}
                onPress={() => {
                  history.push(
                    queryString.stringifyUrl({
                      url: `/app/${props.organization.id!}/my/assigned-task/list/`,
                      query: Object.assign(props.searchCondition, {
                        sortKey: SortKey.DeviationRate,
                        sortOrder: SortOrder.Asc,
                      }),
                    })
                  );
                }}
              />
            )}
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontSize: 12,
                color: themeContext.colors.description,
                textAlign: 'center',
              }}>
              予想見積時間
            </Typography>
          </TouchableOpacity>
        )}

        <Typography
          variant={TypographyType.Normal}
          style={{
            fontSize: 12,
            color: themeContext.colors.description,
            paddingHorizontal: 5,
            width: 40,
            textAlign: 'center',
          }}>
          完了
        </Typography>
      </View>
    </View>
  );
};

interface ITaskListInnerProps {
  organization: Organization;
  tasks: Task[];
  searchCondition: any;
  onEndReached: () => void;
}

const TaskListInner = React.memo((props: ITaskListInnerProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const history = useHistory();
  const [showCanNotDragModal, setShowCanNotDragModal] = useState(false);
  const [updateTaskSortNo, _] = useUpdateTaskSortNoInMyTaskMutation();
  const { loading: meLoading, data: meData } = useMeQuery();

  const renderItem = useCallback((item, index) => {
    if (!meData?.me) {
      return <></>;
    }
    return (
      <TaskSummary
        task={item as Task}
        organization={props.organization}
        me={meData.me}
        showTeamName={
          props.organization.plan.code === Plan.Business ||
          props.organization.plan.code === Plan.Enterprise
        }
        showProjectName={true}
        showClientName
        showAssigner={false}
        key={index}
      />
    );
  }, []);

  const onPress = useCallback(
    (task) => {
      history.push(UrlUtil.createTaskDetailUrl(task));
    },
    [props.organization.id!, location.hash]
  );

  const onDrop = useCallback(
    async (info) => {
      if (props.searchCondition?.sortKey) {
        setShowCanNotDragModal(true);
        return;
      }
      const isMoveToFirst = info.endRowIndex === 0;
      const isMoveToLast = info.endRowIndex === props.tasks.length - 1;
      const isMoveToDown = info.endRowIndex - info.startRowIndex > 0;
      let sortNo;
      if (isMoveToFirst) {
        sortNo = new Date().getTime();
      } else if (isMoveToLast) {
        sortNo =
          props.tasks[info.endRowIndex]!.assignees!.filter(
            (info) => info!.member.id === loginUser!.id
          )?.[0]?.sortNo - 1000;
      } else {
        if (isMoveToDown) {
          const beforeTask = props.tasks[info.endRowIndex];
          const afterTask = props.tasks[info.endRowIndex + 1];
          sortNo = Math.floor(
            (beforeTask!.assignees!.filter((info) => info!.member.id === loginUser!.id)?.[0]
              ?.sortNo +
              afterTask!.assignees!.filter((info) => info!.member.id === loginUser!.id)?.[0]
                ?.sortNo) /
              2
          );
        } else {
          const beforeTask = props.tasks[info.endRowIndex - 1];
          const afterTask = props.tasks[info.endRowIndex];
          sortNo = Math.floor(
            (beforeTask!.assignees!.filter((info) => info!.member.id === loginUser!.id)?.[0]
              ?.sortNo +
              afterTask!.assignees!.filter((info) => info!.member.id === loginUser!.id)?.[0]
                ?.sortNo) /
              2
          );
        }
      }
      await updateTaskSortNo({
        variables: {
          id: (info.item! as Task).id!,
          input: {
            sortNoInMyTask: sortNo,
            versionNo: (info.item as Task).versionNo,
          },
        },
      });
    },
    [props.tasks, updateTaskSortNo]
  );

  const getKey = useCallback((task) => task!.id!.toString(), []);

  return (
    <GlobalDragContextProvider>
      <VirtualizedFlatList
        style={{
          height: 'calc(100vh - 190px)',
        }}
        items={props.tasks}
        renderItem={renderItem}
        getKey={getKey}
        itemHeight={60}
        onPress={onPress}
        virticalDraggable={true}
        onEndReached={props.onEndReached}
        onDrop={onDrop}
      />
    </GlobalDragContextProvider>
  );
});

interface ITaskListInnerWrapperProps {
  organization: Organization;
  searchCondition: any;
}

const TaskListInnerWrapper = React.memo((props: ITaskListInnerWrapperProps) => {
  const pageSize = 50;
  const themeContext: IThemePart = useContext(ThemeContext);
  const [taskFilter, __] = useContext(TaskFilterContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const { loading, data, fetchMore } = useAssignedTasksQuery({
    variables: {
      input: {
        projectIdList: [],
        taskStatusIdList: [],
        completeCondition:
          (taskFilter as TaskFilter).showCompleteTask.length === 0 ||
          (taskFilter as TaskFilter).showCompleteTask.length === 2
            ? TaskCompleteFilter.Both
            : (taskFilter as TaskFilter).showCompleteTask?.[0] === 'incomplete'
            ? TaskCompleteFilter.Incomplete
            : TaskCompleteFilter.Complete,
        assignedMemberIdList: [loginUser!.id],
        scheduledEndDateTime: {
          to: when((taskFilter as TaskFilter)?.expire)
            .on(
              (v) => v === 'today',
              () => moment().endOf('day').toISOString()
            )
            .on(
              (v) => v === '3days',
              () => moment().add(3, 'days').endOf('day').toISOString()
            )
            .on(
              (v) => v === 'week',
              () => moment().add(1, 'week').endOf('day').toISOString()
            )
            .otherwise(() => null),
        },
        sortKey: props.searchCondition?.sortKey || SortKey.SortNoInTaskAssignee,
        sortOrder: props.searchCondition?.sortOrder || SortOrder.Desc,
      },
      offset: 0,
      limit: pageSize,
    },
    fetchPolicy: 'network-only',
  });

  return (
    <TaskListWrapper>
      <Header organization={props.organization} searchCondition={props.searchCondition} />
      {(data?.assignedTasks?.length ?? 0) > 0 ? (
        <TaskListInner
          organization={props.organization}
          tasks={data!.assignedTasks as Task[]}
          searchCondition={props.searchCondition}
          onEndReached={() => {
            if ((data?.assignedTasks?.length ?? 0) < pageSize) {
              return;
            }
            fetchMore({
              variables: {
                offset: data!.assignedTasks!.length || 0,
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return Object.assign({}, prev, {
                  searchTasks: [
                    ...(prev.assignedTasks || []),
                    ...(fetchMoreResult.assignedTasks || []),
                  ],
                });
              },
            });
          }}
        />
      ) : (
        <View
          style={{
            height: 'calc(100% - 150px)',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Image
            source={{ uri: noData04 }}
            resizeMode="contain"
            style={{
              width: 350,
              height: 200,
              opacity: 0.7,
            }}
          />
          <Typography
            variant={TypographyType.Normal}
            style={{ color: themeContext.colors.description, textAlign: 'center' }}>
            {`該当するタスクはありません`}
          </Typography>
        </View>
      )}
    </TaskListWrapper>
  );
});

interface ITaskListParam {
  teamId: string;
  projectId: string;
  organizationId: string;
}

const AssignedTaskList = () => {
  const params = useParams<ITaskListParam>();
  const { search } = useLocation();
  const searchCondition = queryString.parse(search);
  const fetchOrganization = useOrganizationQuery({
    variables: {
      id: params.organizationId,
    },
  });

  if (fetchOrganization.loading) {
    return <></>;
  }

  return (
    <Container>
      <TaskListInnerWrapper
        organization={fetchOrganization.data!.organization!}
        searchCondition={searchCondition}
      />
    </Container>
  );
};

export default React.memo(AssignedTaskList);
