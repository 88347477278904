import React, { useContext, useEffect, useState } from 'react';
import styled, { ThemeContext } from 'styled-components/native';
import Button from '../../../../../presentational/atoms/button';
import Form from '../../../../../presentational/atoms/form';
import Typography, { TypographyType } from '../../../../../presentational/atoms/typography';
import SettingsElement from '../../../organisms/settings-element';
import {
  Organization,
  useMeQuery,
  useOrganizationQuery,
  useWithdrawMutation,
} from '../../../../../../graphql/api/API';
import { LoginUserContext } from '../../../../../../modules/auth/LoginUserContext';
import Checkbox from '../../../../../presentational/atoms/checkbox';
import { IThemePart } from '../../../../../theme';
import { View, TouchableOpacity } from 'react-native';
import Input from '../../../../../presentational/atoms/input';
import LoginUtil from '../../../../../../modules/auth/LoginUtil';
import { useHistory } from 'react-router';

const Container = styled.View`
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  margin-top: 50px;
  overflow: scroll;
`;

interface IWithdrawModalProps {
  organization: Organization;
  closeModal: () => void;
}

const WithdrawModal = (props: IWithdrawModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [step, setStep] = useState(1);
  const [isChecked, setChecked] = useState(false);
  const [isCheckedLast, setCheckedLast] = useState(false);
  const [reason, setReason] = useState('');
  const history = useHistory();

  const [withdraw, _] = useWithdrawMutation({
    variables: {
      input: {
        reason: reason,
      },
    },
  });

  if (step === 2) {
    return (
      <Form style={{ paddingVertical: 20 }}>
        <Typography variant={TypographyType.Normal}>
          よろしければ、退会理由を教えて頂けますと幸いです。
        </Typography>
        <View style={{ flexDirection: 'row', paddingHorizontal: 20, marginVertical: 20 }}>
          <Input
            name={'reason'}
            label={''}
            inputstyle={{ borderWidth: 1, borderBottomWidth: 1 }}
            multiline={true}
            placeholder={'ご退会の理由をご入力ください'}
            onChange={(value: string) => setReason(value)}
          />
        </View>
        <Button
          key={2}
          text="次へ"
          isDisabled={reason.length === 0}
          style={{ marginTop: 20, height: 40, width: 300 }}
          onPress={() => setStep(3)}
        />
      </Form>
    );
  }
  if (step === 3) {
    return (
      <Form style={{ paddingVertical: 20 }}>
        <Typography variant={TypographyType.Normal} style={{ color: themeContext.colors.error }}>
          本当に退会しますか？{'\n'}この操作はやり直しは出来ません。
        </Typography>
        <View style={{ flexDirection: 'row', paddingHorizontal: 20, marginVertical: 20 }}>
          <Checkbox
            size={20}
            value={isCheckedLast}
            onValueChange={setCheckedLast}
            color={isCheckedLast ? themeContext.colors.primary : undefined}></Checkbox>
          <TouchableOpacity onPress={() => setCheckedLast(!isCheckedLast)}>
            <Typography
              variant={TypographyType.Normal}
              style={{ lineHeight: 20, color: themeContext.colors.error, marginLeft: 10 }}>
              本当に退会したい
            </Typography>
          </TouchableOpacity>
        </View>
        <Button
          key={3}
          isDisabled={!isCheckedLast}
          text="退会を実行する"
          style={{ marginTop: 20, height: 40, width: 300 }}
          onPress={async () => {
            await withdraw();
            await LoginUtil.deleteJwtToken();
            window.alert(
              `退会のお手続きが完了しました。ご利用頂きまして、誠にありがとうございました。`
            );
            window.location.reload();
          }}
        />
      </Form>
    );
  }
  return (
    <Form style={{ paddingVertical: 20 }}>
      <Typography variant={TypographyType.Normal}>
        退会をするとデータは失われます。{'\n'}退会後に元に戻すことはできませんのでご了承ください。
      </Typography>
      <View style={{ flexDirection: 'row', paddingHorizontal: 20, marginVertical: 20 }}>
        <Checkbox
          size={20}
          value={isChecked}
          onValueChange={setChecked}
          color={isChecked ? themeContext.colors.primary : undefined}></Checkbox>
        <TouchableOpacity onPress={() => setChecked(!isChecked)}>
          <Typography
            variant={TypographyType.Normal}
            style={{ lineHeight: 20, color: themeContext.colors.error, marginLeft: 10 }}>
            理解しました
          </Typography>
        </TouchableOpacity>
      </View>
      <Button
        key={1} // keyを指定しておかないと、なぜかStepが進んでも同じボタンが表示されてしまうため、指定するようにしている
        isDisabled={!isChecked}
        text="次へ"
        style={{ marginTop: 20, height: 40, width: 300 }}
        onPress={() => setStep(2)}
      />
    </Form>
  );
};

const OrganizationContractPage = () => {
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const { loading, data } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId!,
    },
  });
  const { loading: meLoading, data: meData } = useMeQuery();

  if (loading || meLoading) {
    return <></>;
  }

  if (!meData!.me!.adminRole) {
    return (
      <Container>
        <SettingsElement title={'退会のお手続き'}>
          <Typography variant={TypographyType.Normal}>
            退会のお手続きは、管理者権限があるメンバーしか実行出来ません。
          </Typography>
        </SettingsElement>
      </Container>
    );
  }

  return (
    <Container>
      <SettingsElement
        title={'退会のお手続き'}
        changeText={'退会の手続きを行う'}
        modal={(closeModal) => (
          <WithdrawModal organization={data!.organization!} closeModal={closeModal} />
        )}>
        <Typography variant={TypographyType.Normal}>
          退会をご希望の場合には、以下から退会のお手続きを進めてください。{'\n'}
          退会をするとデータは失われます。{'\n'}退会後に元に戻すことはできませんのでご了承ください。
        </Typography>
      </SettingsElement>
    </Container>
  );
};

export default OrganizationContractPage;
