import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ActivityIndicator, View } from 'react-native';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../../../../../theme';
import Typography, { TypographyType } from '../../../../../../../presentational/atoms/typography';
import {
  GraphDataProject,
  GraphDataProjectSortKey,
  Organization,
  ReportType,
  SortOrder,
  useGraphDataProjectListQuery,
} from '../../../../../../../../graphql/api/API';
import { LoginUserContext } from '../../../../../../../../modules/auth/LoginUserContext';
import OrganizationUtil from '../../../../../../../../util/OrganizationUtil';
import TimeUtil from '../../../../../../../../util/TimeUtil';
import EditIcon from '../../../../../../../presentational/molecules/image-icon/edit';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router';
import VirtualizedFlatList, {
  GlobalDragContextProvider,
} from '../../../../../../../presentational/atoms/list2/virtualized-flat-list';
import moment from 'moment-timezone';
import useDimensions from 'use-dimensions';
import queryString from 'query-string';
import SortHeaderItem from '../../../../../../../presentational/molecules/sort-header-item';
import Spinner from '../../../../../../../presentational/atoms/spinner';
import ProjectDateGraph from '../../../../../organisms/bar-graph/project-date-graph';
import ProjectBarGraph from '../../../../../organisms/bar-graph/project-bar-graph';
import ProjectSunburstGraph from '../../../../../organisms/sunburst-graph/project';

const Container = styled.View`
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  margin-right: 10px;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.4s;
  z-index: 100;
  border-width: 1px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
`;

const Main = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  padding-bottom: 3px;
  overflow: hidden;
`;

interface IProps {
  project: GraphDataProject;
  organization: Organization;
}

const GraphProjectSummary = (props: IProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser] = useContext(LoginUserContext);
  const history = useHistory();

  const onPress = useCallback(() => {
    history.push(
      location.hash.replace('#', '') +
        `?projectDetailId=${props.project.projectId}&projectDetailMode=standard`
    );
  }, [loginUser, history, props.project]);

  return (
    <Container>
      <Main>
        <View style={{ flexDirection: 'row', flex: 1 }}>
          {OrganizationUtil.isTeamPlan(props.organization) && (
            <View
              style={{
                flex: 1,
                minWidth: 140,
                marginRight: 10,
                justifyContent: 'flex-start',
              }}>
              <Typography
                variant={TypographyType.Normal}
                ellipsis={true}
                style={{
                  fontSize: 12,
                  paddingHorizontal: 5,
                  textAlign: 'left',
                }}>
                {props.project.teamName}
              </Typography>
            </View>
          )}
          <View
            style={{
              flex: 1,
              minWidth: 140,
              marginRight: 10,
              justifyContent: 'flex-start',
            }}>
            <Typography
              variant={TypographyType.Normal}
              ellipsis={true}
              style={{
                fontSize: 12,
                paddingHorizontal: 5,
                textAlign: 'left',
              }}>
              {props.project.projectName}
            </Typography>
          </View>
          <View
            style={{
              flex: 1,
              minWidth: 140,
              marginRight: 10,
              justifyContent: 'flex-start',
            }}>
            <Typography
              variant={TypographyType.Normal}
              ellipsis={true}
              style={{
                fontSize: 12,
                paddingHorizontal: 5,
                textAlign: 'left',
              }}>
              {props.project.clientName ?? '-'}
            </Typography>
          </View>
          <View
            style={{
              width: 80,
              marginRight: 10,
              justifyContent: 'flex-end',
            }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontSize: 12,
                textAlign: 'right',
              }}>
              {TimeUtil.formatForTask(props.project.workingTimeSec)}
            </Typography>
          </View>
          {OrganizationUtil.isTeamPlan(props.organization) && (
            <View
              style={{
                width: 80,
                marginRight: 10,
                justifyContent: 'flex-end',
              }}>
              <Typography
                variant={TypographyType.Normal}
                ellipsis={true}
                style={{
                  fontSize: 12,
                  textAlign: 'right',
                }}>
                {props.project.laborCost
                  ? `${new Intl.NumberFormat('ja-JP', {
                      style: 'currency',
                      currency: 'JPY',
                    }).format(props.project.laborCost)}`
                  : '-'}
              </Typography>
            </View>
          )}
          <View
            style={{
              width: 50,
              marginRight: 10,
              justifyContent: 'flex-end',
            }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontSize: 12,
                textAlign: 'right',
              }}>
              {props.project.percent}%
            </Typography>
          </View>
          <View
            style={{
              width: 30,
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <EditIcon size={24} onPress={onPress} />
          </View>
        </View>
      </Main>
    </Container>
  );
};

interface IProjectHeaderProps {
  organization: Organization;
  graphType: 'date' | 'bar' | 'sunburst';
  searchCondition: any;
  url: string;
}

const ProjectHeader = (props: IProjectHeaderProps) => {
  const history = useHistory();
  const themeContext: IThemePart = useContext(ThemeContext);
  return (
    <View
      style={{
        flexDirection: 'row',
        marginHorizontal: 10,
        paddingHorizontal: 10,
        backgroundColor: themeContext.colors.subHeader,
      }}>
      {OrganizationUtil.isTeamPlan(props.organization) && (
        <SortHeaderItem
          organization={props.organization}
          searchCondition={props.searchCondition}
          sortKey={GraphDataProjectSortKey.TeamName}
          style={{
            flex: 1,
            minWidth: 140,
            marginRight: 10,
            justifyContent: 'flex-start',
          }}
          caretStyle={'white'}
          url={props.url}>
          <Typography
            variant={TypographyType.Normal}
            style={{
              fontSize: 12,
              paddingHorizontal: 5,
              textAlign: 'left',
              color: themeContext.colors.baseColor,
            }}>
            チーム名
          </Typography>
        </SortHeaderItem>
      )}
      <SortHeaderItem
        organization={props.organization}
        searchCondition={props.searchCondition}
        sortKey={GraphDataProjectSortKey.ProjectName}
        style={{
          flex: 1,
          minWidth: 140,
          marginRight: 10,
          justifyContent: 'flex-start',
        }}
        caretStyle={'white'}
        url={props.url}>
        <Typography
          variant={TypographyType.Normal}
          style={{
            fontSize: 12,
            paddingHorizontal: 5,
            textAlign: 'left',
            color: themeContext.colors.baseColor,
          }}>
          プロジェクト名
        </Typography>
      </SortHeaderItem>
      <SortHeaderItem
        organization={props.organization}
        searchCondition={props.searchCondition}
        sortKey={GraphDataProjectSortKey.ClientName}
        style={{
          flex: 1,
          minWidth: 140,
          marginRight: 10,
          justifyContent: 'flex-start',
        }}
        caretStyle={'white'}
        url={props.url}>
        <Typography
          variant={TypographyType.Normal}
          style={{
            fontSize: 12,
            paddingHorizontal: 5,
            textAlign: 'left',
            color: themeContext.colors.baseColor,
          }}>
          取引先名
        </Typography>
      </SortHeaderItem>
      <SortHeaderItem
        organization={props.organization}
        searchCondition={props.searchCondition}
        sortKey={GraphDataProjectSortKey.WorkingTimeSec}
        style={{
          width: 80,
          marginRight: 10,
          justifyContent: 'flex-end',
        }}
        caretStyle={'white'}
        url={props.url}>
        <Typography
          variant={TypographyType.Normal}
          style={{
            fontSize: 12,
            textAlign: 'right',
            color: themeContext.colors.baseColor,
          }}>
          作業時間
        </Typography>
      </SortHeaderItem>
      {OrganizationUtil.isTeamPlan(props.organization) && (
        <SortHeaderItem
          organization={props.organization}
          searchCondition={props.searchCondition}
          sortKey={GraphDataProjectSortKey.LaborCost}
          style={{
            width: 80,
            marginRight: 10,
            justifyContent: 'flex-end',
          }}
          caretStyle={'white'}
          url={props.url}>
          <Typography
            variant={TypographyType.Normal}
            style={{
              fontSize: 12,
              textAlign: 'right',
              color: themeContext.colors.baseColor,
            }}>
            人件費
          </Typography>
        </SortHeaderItem>
      )}
      <View
        style={{
          width: 50,
          marginRight: 10,
          justifyContent: 'flex-end',
        }}>
        <Typography
          variant={TypographyType.Normal}
          style={{
            fontSize: 12,
            textAlign: 'right',
            color: themeContext.colors.baseColor,
          }}>
          割合
        </Typography>
      </View>
      <View
        style={{
          width: 30,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      />
    </View>
  );
};

interface IProjectListProps {
  organization: Organization;
  startDateTime: moment.Moment;
  endDateTime: moment.Moment;
  memberId?: string | null;
  teamId?: string | null;
  teamIds: string[];
  projectIds: string[];
  clientIds: string[];
  memberIds: string[];
  graphPath: string;
  graphType: 'date' | 'bar' | 'sunburst';
  reportType: ReportType;
  url: string;
  setLoadData: (value: boolean) => void;
}

export const ProjectList = (props: IProjectListProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const pageSize = 50;
  const [loginUser] = useContext(LoginUserContext);
  const { window: win } = useDimensions();
  const { search } = useLocation();
  const searchCondition = queryString.parse(search);
  const [loadingMore, setLoadingMore] = useState(false);
  const [isLoadListData, setLoadListData] = useState(false);

  const { data, loading, fetchMore } = useGraphDataProjectListQuery({
    variables: {
      input: {
        reportType: props.reportType,
        organizationId: loginUser!.organizationId,
        memberId: props.memberId,
        teamId: props.teamId,
        start: moment(props.startDateTime).startOf('day').toISOString(),
        end: moment(props.endDateTime).endOf('day').toISOString(),
        teamIds: props.teamIds,
        projectIds: props.projectIds,
        clientIds: props.clientIds,
        memberIds: props.memberIds,
        timeZoneOffset: moment().tz(moment.tz.guess()).format('Z'),
        sortKey:
          (searchCondition?.sortKey as GraphDataProjectSortKey) ?? GraphDataProjectSortKey.TeamName,
        sortOrder: (searchCondition?.sortOrder as SortOrder) ?? SortOrder.Desc,
      },
      offset: 0,
      limit: pageSize,
    },
    fetchPolicy: 'network-only',
  });

  const renderItem = useCallback(
    (item, index) => <GraphProjectSummary organization={props.organization} project={item} />,
    [props.organization]
  );

  const getKey = useCallback((data) => data!.projectId!.toString(), []);

  const onEndReached = async () => {
    if ((data?.graphDataProjectList?.length ?? 0) < pageSize) {
      return;
    }
    setLoadingMore(true);
    await fetchMore({
      variables: {
        offset: data!.graphDataProjectList!.length || 0,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          graphDataProjectList: [
            ...(prev.graphDataProjectList || []),
            ...(fetchMoreResult.graphDataProjectList || []),
          ],
        });
      },
    });
    setTimeout(() => {
      setLoadingMore(false);
    }, 1000);
  };

  const graphHeight = Math.max(Math.min(win.height / 3, 500), 200);

  useEffect(() => {
    if (loading) {
      setLoadListData(false);
    } else {
      if (data?.graphDataProjectList) {
        setLoadListData(true);
      }
    }
  }, [data?.graphDataProjectList, loading]);

  return (
    <>
      <View
        style={{
          minHeight: Math.max(Math.min(win.height / 3, 500), 200),
          paddingTop: 10,
          paddingBottom: 10,
        }}>
        {isLoadListData && (
          <Switch>
            <Route
              path={`${props.graphPath}/graph-project/date/`}
              render={(routeProps) => (
                <ProjectDateGraph
                  reportType={props.reportType}
                  organization={props.organization}
                  startDateTime={props.startDateTime}
                  endDateTime={props.endDateTime}
                  teamIds={props.teamIds}
                  projectIds={props.projectIds}
                  clientIds={props.clientIds}
                  memberIds={props.memberIds}
                  targetMemberId={props.memberId}
                  teamId={props.teamId}
                  setLoadData={props.setLoadData}
                />
              )}
            />
            <Route
              path={`${props.graphPath}/graph-project/bar/`}
              render={(routeProps) => (
                <ProjectBarGraph
                  reportType={props.reportType}
                  organization={props.organization}
                  startDateTime={props.startDateTime}
                  endDateTime={props.endDateTime}
                  teamIds={props.teamIds}
                  projectIds={props.projectIds}
                  clientIds={props.clientIds}
                  memberIds={props.memberIds}
                  targetMemberId={props.memberId}
                  teamId={props.teamId}
                  setLoadData={props.setLoadData}
                />
              )}
            />
            <Route
              path={`${props.graphPath}/graph-project/sunburst/`}
              render={(routeProps) => (
                <ProjectSunburstGraph
                  reportType={props.reportType}
                  organization={props.organization}
                  startDateTime={props.startDateTime}
                  endDateTime={props.endDateTime}
                  teamIds={props.teamIds}
                  projectIds={props.projectIds}
                  clientIds={props.clientIds}
                  memberIds={props.memberIds}
                  targetMemberId={props.memberId}
                  teamId={props.teamId}
                  setLoadData={props.setLoadData}
                />
              )}
            />
            <Redirect to={props.url} />
          </Switch>
        )}
      </View>
      <View style={{ overflowX: 'scroll' } as any}>
        <GlobalDragContextProvider>
          <VirtualizedFlatList
            style={{
              height: `calc(100vh - ${graphHeight}px - 230px`,
              minWidth: 800,
            }}
            loading={loading}
            items={data?.graphDataProjectList ?? []}
            renderItem={renderItem}
            headerElement={
              <ProjectHeader
                organization={props.organization}
                graphType={props.graphType}
                searchCondition={searchCondition}
                url={props.url}
              />
            }
            afterElement={
              <>
                {loadingMore && (
                  <ActivityIndicator size={'small'} color={themeContext.colors.primary} />
                )}
              </>
            }
            getKey={getKey}
            itemHeight={40}
            isDraggable={false}
            onEndReached={onEndReached}
          />
        </GlobalDragContextProvider>
      </View>
    </>
  );
};
