import moment from 'moment-timezone';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { TouchableOpacity, View } from 'react-native';
import { useHistory, useLocation } from 'react-router';
import { toast } from 'react-toastify';
import styled, { ThemeContext } from 'styled-components/native';
import {
  FavoriteProjectsDocument,
  type Project,
  ProjectAttachmentFilesDocument,
  ProjectInitialViewType,
  type Team,
  TeamProjectsDocument,
  useCancelFavoriteProjectMutation,
  useCompleteProjectMutation,
  useCopyProjectMutation,
  useCreateAttachementFileForProjectMutation,
  useCreateAttachementFileUploadUrlMutation,
  useDeleteProjectMutation,
  useFavoriteProjectMutation,
  useIncompleteProjectMutation,
  useJoinedTeamsQuery,
  useMeQuery,
  useMoveProjectMutation,
  useMySettingsQuery,
  useProjectQuery,
  useTeamProjectStatusQuery,
  useUpdateProjectMutation,
  useUpdateStatusOfProjectMutation,
} from '../../../../../../graphql/api/API';
import { LoginUserContext } from '../../../../../../modules/auth/LoginUserContext';
import ColorUtil from '../../../../../../util/ColorUtil';
import UrlUtil from '../../../../../../util/UrlUtil';
import Button from '../../../../../presentational/atoms/button';
import Checkbox from '../../../../../presentational/atoms/checkbox';
import EditableText from '../../../../../presentational/atoms/editable-text';
import Form from '../../../../../presentational/atoms/form';
import Icon from '../../../../../presentational/atoms/icon';
import Input from '../../../../../presentational/atoms/input';
import Typography, { TypographyType } from '../../../../../presentational/atoms/typography';
import AttachmentIcon from '../../../../../presentational/molecules/image-icon/attachment';
import CheckIcon from '../../../../../presentational/molecules/image-icon/check';
import CloseIcon from '../../../../../presentational/molecules/image-icon/close';
import CopyIcon from '../../../../../presentational/molecules/image-icon/copy';
import DeleteIcon from '../../../../../presentational/molecules/image-icon/delete';
import FileMoveIcon from '../../../../../presentational/molecules/image-icon/file-move';
import MenuIcon from '../../../../../presentational/molecules/image-icon/menu';
import StarIcon from '../../../../../presentational/molecules/image-icon/star';
import Modal from '../../../../../presentational/molecules/modal';
import type { IStyleTheme, IThemePart } from '../../../../../theme';
import ErrorMessageModal from '../../error-message-modal';

const HeaderMenu = styled.View`
  display: flex;
  flex-direction: row;
  padding: 10px 15px;
  justify-content: space-between;
  align-items: center;
  border-bottom-width: 1px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
`;

const TitleArea = styled.View`
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  align-items: flex-start;
`;

const CommonArea = styled.View`
  display: flex;
  flex-direction: column;
  padding: 5px 15px;
  align-items: flex-start;
`;

const Menu = styled.View`
  position: absolute;
  top: 40px;
  right: 0;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
  border-width: 1px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 160px;
`;

interface IDeleteProjectModalProps {
  project: Project;
  showModal: boolean;
  onPressYes: () => Promise<void>;
  onCloseModal: () => void;
}
const DeleteProjectConfirmModal = (props: IDeleteProjectModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const { loading, data } = useMeQuery({
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <></>;
  }

  if (data!.me!.projectDeletePermissionFlg) {
    return (
      <Modal
        title={'このプロジェクトを削除しますか？'}
        isShow={props.showModal}
        onClose={() => {
          props.onCloseModal();
        }}>
        <View style={{ flexDirection: 'column' }}>
          <View style={{ marginTop: 10 }}>
            <Typography variant={TypographyType.Normal} style={{ textAlign: 'center' }}>
              {props.project.name}
            </Typography>
          </View>
          <View style={{ marginTop: 10 }}>
            <Typography
              variant={TypographyType.Description}
              style={{ textAlign: 'center', color: themeContext.colors.error }}>
              {`プロジェクトのタスクや作業履歴等のデータも全て削除されます。${'\n\n'}この操作はやり直しが出来ません。${'\n'}本当に削除してよろしいですか？`}
            </Typography>
          </View>
          <View
            style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
            <Button
              text={'キャンセル'}
              style={{
                minWidth: 100,
                marginRight: 10,
                marginVertical: 10,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.primary }}
              disableValidate={true}
              onPress={() => {
                props.onCloseModal();
              }}
            />
            <Button
              text={'削除する'}
              style={{
                minWidth: 100,
                marginRight: 10,
                marginVertical: 10,
                borderColor: themeContext.colors.error,
                borderRadius: 3,
                borderWidth: 1,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.error }}
              onPress={async () => {
                await props.onPressYes();
              }}
            />
          </View>
        </View>
      </Modal>
    );
  }
  return (
    <Modal
      title={'プロジェクトを削除する権限がありません'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <View style={{ flexDirection: 'column' }}>
        <View style={{ marginTop: 10 }}>
          <Typography
            variant={TypographyType.Description}
            style={{ textAlign: 'center', color: themeContext.colors.error }}>
            {`プロジェクトを削除する権限がないため、削除することができません。${'\n'}権限が必要な場合、管理権限を持っているメンバーの方に問い合わせてください。`}
          </Typography>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
          <Button
            text={'キャンセル'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.primary }}
            disableValidate={true}
            onPress={() => {
              props.onCloseModal();
            }}
          />
        </View>
      </View>
    </Modal>
  );
};

interface ICopyProjectModalProps {
  project: Project;
  showModal: boolean;
  onPressYes: (
    copyProjectAssignee: boolean,
    copyTaskAssignee: boolean,
    copyTaskComment: boolean
  ) => Promise<void>;
  onCloseModal: () => void;
}
const CopyProjectConfirmModal = (props: ICopyProjectModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [copyProjectAssignee, setCopyProjectAssignee] = useState(false);
  const [copyTaskAssignee, setCopyTaskAssignee] = useState(false);
  const [copyTaskComment, setCopyTaskComment] = useState(false);
  return (
    <Modal
      title={'このプロジェクトをコピーしますか？'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <View style={{ flexDirection: 'column' }}>
        <View style={{ marginTop: 10 }}>
          <Typography variant={TypographyType.Normal} style={{ textAlign: 'center' }}>
            {props.project.name}
          </Typography>
        </View>
        <View style={{ marginTop: 10 }}>
          <Typography variant={TypographyType.Description} style={{ textAlign: 'left' }}>
            {`タスクも合わせてコピーされます。`}
          </Typography>
        </View>
        <View style={{ marginTop: 10 }}>
          <Typography variant={TypographyType.Description} style={{ textAlign: 'left' }}>
            {`プロジェクトの担当者、タスクの担当者・進捗率・作業履歴・コメントはコピーされません。${'\n'}担当者・コメントもコピーしたい場合には、以下にチェックを入れてください。`}
          </Typography>
        </View>
        <TouchableOpacity
          style={{ flexDirection: 'row', marginTop: 20 }}
          onPress={() => setCopyProjectAssignee(!copyProjectAssignee)}>
          <Checkbox size={24} value={copyProjectAssignee} color={themeContext.colors.description} />
          <Typography
            variant={TypographyType.Normal}
            style={{
              color: themeContext.colors.description,
              marginLeft: 10,
            }}>
            プロジェクトの担当者情報もコピーする
          </Typography>
        </TouchableOpacity>
        <TouchableOpacity
          style={{ flexDirection: 'row', marginTop: 10 }}
          onPress={() => setCopyTaskAssignee(!copyTaskAssignee)}>
          <Checkbox size={24} value={copyTaskAssignee} color={themeContext.colors.description} />
          <Typography
            variant={TypographyType.Normal}
            style={{
              color: themeContext.colors.description,
              marginLeft: 10,
            }}>
            タスクの担当者情報もコピーする
          </Typography>
        </TouchableOpacity>
        <TouchableOpacity
          style={{ flexDirection: 'row', marginTop: 10 }}
          onPress={() => setCopyTaskComment(!copyTaskComment)}>
          <Checkbox size={24} value={copyTaskComment} color={themeContext.colors.description} />
          <Typography
            variant={TypographyType.Normal}
            style={{
              color: themeContext.colors.description,
              marginLeft: 10,
            }}>
            タスクのコメントもコピーする
          </Typography>
        </TouchableOpacity>
        <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
          <Button
            text={'コピーする'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
            }}
            onPress={async () => {
              await props.onPressYes(copyProjectAssignee, copyTaskAssignee, copyTaskComment);
            }}
          />
          <Button
            text={'キャンセル'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.primary }}
            disableValidate={true}
            onPress={() => {
              props.onCloseModal();
            }}
          />
        </View>
      </View>
    </Modal>
  );
};

interface IMoveProjectModalProps {
  project: Project;
  showModal: boolean;
  onPressYes: (team: Team) => void;
  onCloseModal: () => void;
}
const MoveProjectConfirmModal = React.memo((props: IMoveProjectModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [team, setTeam] = useState<Team | null>();
  const { loading, data } = useJoinedTeamsQuery({
    variables: {
      organizationId: loginUser!.organizationId,
    },
    fetchPolicy: 'network-only',
  });

  return (
    <Modal
      title={'このプロジェクトを他のチームに移動しますか？'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <View style={{ flexDirection: 'column' }}>
        <View style={{ marginTop: 10 }}>
          <Typography variant={TypographyType.Normal} style={{ textAlign: 'center' }}>
            {props.project.name}
          </Typography>
        </View>
        <Form>
          <Input
            name={'team'}
            label={'チームを選択'}
            type={'picker'}
            isSearchable={true}
            pickerItems={(data?.joinedTeams || []).map((team) => {
              return {
                label: team!.name,
                value: team!.id!,
              };
            })}
            onChange={(value) => {
              const targets = (data?.joinedTeams || []).filter((team) => team!.id! === value);
              if (targets && targets.length > 0) {
                setTeam((targets[0] as Team) || null);
              }
            }}
            validate={{
              required: {
                value: true,
                message: 'チームを選択してください',
              },
            }}
          />
        </Form>
        <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
          <Button
            text={'移動する'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
            }}
            onPress={() => {
              props.onPressYes(team!);
            }}
          />
          <Button
            text={'キャンセル'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.primary }}
            disableValidate={true}
            onPress={() => {
              props.onCloseModal();
            }}
          />
        </View>
      </View>
    </Modal>
  );
});

interface ICompleteProjectModalProps {
  project: Project;
  showModal: boolean;
  onPressYes: () => Promise<void>;
  onCloseModal: () => void;
}
const CompleteProjectConfirmModal = (props: ICompleteProjectModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  return (
    <Modal
      title={'このプロジェクトを完了しますか？'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <View style={{ flexDirection: 'column' }}>
        <View style={{ marginTop: 10 }}>
          <Typography variant={TypographyType.Normal} style={{ textAlign: 'center' }}>
            {props.project.name}
          </Typography>
        </View>
        <View style={{ marginTop: 10 }}>
          <Typography variant={TypographyType.Description} style={{ textAlign: 'center' }}>
            {`作業中のタスクがある場合、自動的に作業停止します。`}
          </Typography>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
          <Button
            text={'完了する'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
            }}
            onPress={async () => {
              await props.onPressYes();
            }}
          />
          <Button
            text={'キャンセル'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.primary }}
            disableValidate={true}
            onPress={() => {
              props.onCloseModal();
            }}
          />
        </View>
      </View>
    </Modal>
  );
};

interface IProjectDetailCommonProps {
  organizationId: string;
  project: Project;
  projectDetailMode: string;
  children: React.ReactNode;
}

const ProjectDetailCommon = (props: IProjectDetailCommonProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const history = useHistory();
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const { pathname } = useLocation();
  const attachmentFileInputRef = useRef();
  const fetchProjectResult = useProjectQuery({
    variables: {
      id: props.project.id!,
    },
    fetchPolicy: 'network-only',
  });

  const [createAttachmentFileUploadUrl, __] = useCreateAttachementFileUploadUrlMutation();
  const [createAttachemntFile, ___] = useCreateAttachementFileForProjectMutation();

  const [showModal, setShowModal] = useState(false);
  const [showCopyModal, setShowCopyModal] = useState(false);
  const [showMoveModal, setShowMoveModal] = useState(false);
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [showIncompleteModal, setShowIncompleteModal] = useState(false);
  const [projectName, setProjectName] = useState(props.project.name);
  const [clientId, setClientId] = useState(props.project.client?.id || null);
  const menuRef = useRef();
  const [openMenu, setOpenMenu] = useState(false);
  const [showCreateProjectErrorModal, setShowCreateProjectErrorModal] = useState(false);
  const [showUpdateProjectErrorModal, setShowUpdateProjectErrorModal] = useState(false);
  const [showDeleteProjectErrorModal, setShowDeleteProjectErrorModal] = useState(false);
  const [rerenderProjectStatus, setRerenderProjectStatus] = useState(false);
  useHotkeys('Esc', () => setOpenMenu(false));
  const clickDocument = (e: any) => {
    if ((menuRef?.current as any)?.contains(e.target)) {
      (menuRef?.current as any)?.click();
      return;
    }
    setOpenMenu(false);
  };
  useEffect(() => {
    window.addEventListener('click', clickDocument);
    return () => {
      window.removeEventListener('click', clickDocument);
    };
  }, [clickDocument]);

  const [completeProject] = useCompleteProjectMutation({
    variables: {
      id: props.project.id!,
      input: {
        versionNo: props.project.versionNo,
      },
    },
  });
  const [incompleteProject] = useIncompleteProjectMutation({
    variables: {
      id: props.project.id!,
      input: {
        versionNo: props.project.versionNo,
      },
    },
  });
  const [moveProject] = useMoveProjectMutation();
  const [deleteProject] = useDeleteProjectMutation({
    variables: {
      id: props.project.id!,
      input: {
        versionNo: props.project.versionNo,
      },
    },
  });
  const [copyProject] = useCopyProjectMutation();
  const [updateProject] = useUpdateProjectMutation();
  const [favoriteProject] = useFavoriteProjectMutation({
    variables: {
      id: props.project.id!,
      input: {
        versionNo: props.project.versionNo,
      },
    },
    refetchQueries: [
      {
        query: FavoriteProjectsDocument,
      },
    ],
  });
  const [cancelFavoriteProject] = useCancelFavoriteProjectMutation({
    variables: {
      id: props.project.id!,
      input: {
        versionNo: props.project.versionNo,
      },
    },
    refetchQueries: [
      {
        query: FavoriteProjectsDocument,
      },
    ],
  });

  const { loading: mySettingsLoading, data: mySettingsData } = useMySettingsQuery();
  const { loading: meLoading, data: meData } = useMeQuery({});

  const fetchProjectStatuses = useTeamProjectStatusQuery({
    variables: {
      teamId: props.project.team.id!,
    },
    fetchPolicy: 'network-only',
  });
  const [updateProjectStatus, updateProjectStatusResult] = useUpdateStatusOfProjectMutation({
    update: (cache, result) => {
      cache.modify({
        fields: {
          boardProjects(existingRef, { readField }) {
            const projectStatusId = `ProjectStatus:${result.data?.updateStatusOfProject?.projectStatus?.id}`; // Pusherからemptyデータが来るケースがあるので、taskStatusが無い場合がある
            if (!existingRef.projects.includes(projectStatusId)) {
              return {
                projectStatus: existingRef.projectStatus,
                projects: [
                  ...existingRef.projects,
                  {
                    __ref: `Project:${result.data?.updateStatusOfProject!.id}`,
                  },
                ],
              };
            } else if (
              projectStatusId !== existingRef.projectStatus.__ref &&
              existingRef.projects.includes(projectStatusId)
            ) {
              return {
                projectStatus: existingRef.projectStatus,
                projects: existingRef.projects.filter(
                  (projectRef: any) =>
                    projectRef !== `Project:${result.data?.updateStatusOfProject!.id}`
                ),
              };
            }
            return existingRef;
          },
        },
      });
    },
    refetchQueries: [
      {
        query: TeamProjectsDocument,
        variables: {
          teamId: props.project.team.id!,
        },
      },
    ],
  });

  if (fetchProjectResult.loading || !fetchProjectResult.data?.project || mySettingsLoading) {
    return <></>;
  }

  const isCurrentProject =
    pathname.indexOf(
      `/app/${props.organizationId}/${props.project.team.id}/projects/${props.project.id!}/`
    ) !== -1;

  if (
    meLoading ||
    !meData?.me ||
    fetchProjectStatuses.loading ||
    !fetchProjectStatuses.data?.teamProjectStatus
  ) {
    return <></>;
  }

  return (
    <View>
      <View
        style={
          {
            position: 'sticky',
            top: 0,
            zIndex: 5,
            backgroundColor: themeContext.colors.baseColor,
          } as any
        }>
        <HeaderMenu style={{ zIndex: 3 }}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Typography
              variant={TypographyType.Description}
              style={{ fontSize: 14, marginRight: 10, marginLeft: 10 }}>
              ステータス:
            </Typography>
            {!rerenderProjectStatus && (
              <EditableText
                style={{ minWidth: 150 }}
                value={props.project.projectStatus.id!.toString()}
                editable={meData.me.projectUpdatePermissionFlg}
                onClickWhenNotEditable={() => setShowUpdateProjectErrorModal(true)}
                type={'picker'}
                isSearchable={true}
                emptyText={'-'}
                pickerItems={fetchProjectStatuses.data?.teamProjectStatus?.map((status) => {
                  return {
                    label: status!.name,
                    value: status!.id,
                  };
                })}
                onChange={async (value) => {
                  if (
                    fetchProjectStatuses.data?.teamProjectStatus?.filter(
                      (status) => status!.id === value
                    )?.[0]?.endStatus
                  ) {
                    setShowCompleteModal(true);
                    return;
                  }
                  await updateProjectStatus({
                    variables: {
                      id: props.project.id!,
                      input: {
                        projectStatusId: value as string,
                        versionNo: props.project.versionNo,
                      },
                    },
                  });
                }}
              />
            )}
            <CompleteProjectConfirmModal
              project={props.project}
              showModal={showCompleteModal}
              onCloseModal={() => {
                setRerenderProjectStatus(true);
                setTimeout(() => {
                  setRerenderProjectStatus(false);
                }, 200);
                setShowCompleteModal(false);
              }}
              onPressYes={async () => {
                await updateProjectStatus({
                  variables: {
                    id: props.project.id!,
                    input: {
                      projectStatusId:
                        fetchProjectStatuses.data?.teamProjectStatus?.filter(
                          (status) => status?.endStatus
                        )?.[0]?.id ?? '',
                      versionNo: props.project.versionNo,
                    },
                  },
                });
                setShowCompleteModal(false);
              }}
            />
          </View>

          <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            {!isCurrentProject && (
              <View style={{ marginHorizontal: 10 }}>
                <TouchableOpacity
                  onPress={() => {
                    if (
                      mySettingsData!.mySettings!.projectInitialViewTypeCode ===
                      ProjectInitialViewType.KanbanBoard
                    ) {
                      history.push(
                        `/app/${props.organizationId}/${props.project.team.id}/projects/${props
                          .project.id!}/board/`
                      );
                    } else if (
                      mySettingsData!.mySettings!.projectInitialViewTypeCode ===
                      ProjectInitialViewType.GanttChart
                    ) {
                      history.push(
                        `/app/${props.organizationId}/${props.project.team.id}/projects/${props
                          .project.id!}/schedule/`
                      );
                    } else {
                      history.push(
                        `/app/${props.organizationId}/${props.project.team.id}/projects/${props
                          .project.id!}/list/`
                      );
                    }
                  }}>
                  <Typography
                    variant={TypographyType.Description}
                    style={{ color: themeContext.colors.link }}>
                    プロジェクトへ移動
                  </Typography>
                </TouchableOpacity>
              </View>
            )}
            {props.project.favoriteMembers.filter((member) => member!.id === loginUser!.id).length >
            0 ? (
              <StarIcon
                size={22}
                on={true}
                containerStyle={{ marginLeft: 3 }}
                onPress={() => {
                  cancelFavoriteProject();
                }}
              />
            ) : (
              <StarIcon
                size={22}
                on={false}
                containerStyle={{ marginLeft: 3 }}
                onPress={() => {
                  favoriteProject();
                }}
              />
            )}
            <MenuIcon
              size={28}
              containerStyle={{ marginLeft: 5 }}
              onPress={() => setOpenMenu(true)}
            />
            <CloseIcon
              size={18}
              containerStyle={{ marginLeft: 5 }}
              onPress={() => {
                history.push(UrlUtil.removeSearchs(['projectDetailId', 'projectDetailMode']));
              }}
            />
          </View>
          {openMenu && (
            <Menu ref={menuRef as any}>
              <View style={{ paddingHorizontal: 5, marginVertical: 5 }}>
                <CopyIcon
                  size={20}
                  containerStyle={{ marginRight: 5 }}
                  onPress={() => {
                    setOpenMenu(false);
                    if (!meData.me?.projectCreatePermissionFlg) {
                      setShowCreateProjectErrorModal(true);
                      return;
                    }
                    setShowCopyModal(true);
                  }}>
                  <Typography variant={TypographyType.SubTitle}>コピーする</Typography>
                </CopyIcon>
              </View>
              <View style={{ paddingHorizontal: 5 }}>
                <FileMoveIcon
                  size={20}
                  containerStyle={{ marginRight: 5 }}
                  onPress={() => {
                    setOpenMenu(false);
                    if (!meData.me?.projectUpdatePermissionFlg) {
                      setShowUpdateProjectErrorModal(true);
                      return;
                    }
                    setShowMoveModal(true);
                  }}>
                  <Typography variant={TypographyType.SubTitle}>移動する</Typography>
                </FileMoveIcon>
              </View>
              <View style={{ paddingHorizontal: 5 }}>
                <AttachmentIcon
                  size={20}
                  containerStyle={{ marginRight: 5 }}
                  onPress={() => {
                    if (attachmentFileInputRef.current) {
                      (attachmentFileInputRef.current as any).click();
                    }
                  }}>
                  <Typography variant={TypographyType.SubTitle}>添付する</Typography>
                  <input
                    type="file"
                    ref={attachmentFileInputRef as any}
                    style={{ display: 'none' }}
                    onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                      setOpenMenu(false);

                      let isSuccess = true;
                      await Promise.all(
                        Array.from(e.target.files!).map(async (file) => {
                          const uploadUrlResult = await createAttachmentFileUploadUrl({
                            variables: {
                              input: {
                                fileName: file.name,
                                sizeByte: file.size,
                                type: file.type,
                              },
                            },
                          });

                          await fetch(
                            uploadUrlResult.data!.createAttachementFileUploadUrl!.uploadUrl,
                            {
                              method: 'PUT',
                              body: file,
                            }
                          ).then((response) => {
                            if (!response.ok) {
                              isSuccess = false;
                            }
                          });

                          const attachmentFileResult = await createAttachemntFile({
                            variables: {
                              input: {
                                projectId: props.project.id!,
                                fileName:
                                  uploadUrlResult.data!.createAttachementFileUploadUrl!.fileName,
                                sizeByte:
                                  uploadUrlResult.data!.createAttachementFileUploadUrl!.sizeByte,
                                type: uploadUrlResult.data!.createAttachementFileUploadUrl!.type,
                                key: uploadUrlResult.data!.createAttachementFileUploadUrl!.key,
                              },
                            },
                            refetchQueries: [
                              {
                                query: ProjectAttachmentFilesDocument,
                                variables: {
                                  projectId: props.project.id,
                                },
                              },
                            ],
                          });
                          if (attachmentFileResult.errors) {
                            isSuccess = false;
                          }
                        })
                      );

                      if (isSuccess) {
                        toast('ファイルアップロードが完了しました', {
                          type: 'success',
                          position: 'bottom-right',
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        });
                      } else {
                        toast('ファイルアップロードに失敗しました', {
                          type: 'error',
                          position: 'bottom-right',
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        });
                      }
                    }}
                  />
                </AttachmentIcon>
              </View>
              <View style={{ paddingHorizontal: 5, marginVertical: 5 }}>
                <DeleteIcon
                  size={23}
                  containerStyle={{ marginRight: 5 }}
                  onPress={() => {
                    setOpenMenu(false);
                    if (!meData.me?.projectDeletePermissionFlg) {
                      setShowDeleteProjectErrorModal(true);
                      return;
                    }
                    setShowModal(true);
                  }}>
                  <Typography variant={TypographyType.SubTitle}>削除する</Typography>
                </DeleteIcon>
              </View>
            </Menu>
          )}
          <CopyProjectConfirmModal
            project={props.project}
            showModal={showCopyModal}
            onPressYes={async (copyProjectAssignee, copyTaskAssignee, copyTaskComment) => {
              setShowCopyModal(false);
              const result = await copyProject({
                variables: {
                  id: props.project.id!,
                  input: {
                    copyProjectAssignee: copyProjectAssignee,
                    copyTaskAssignee: copyTaskAssignee,
                    copyTaskComment: copyTaskComment,
                    versionNo: props.project.versionNo,
                  },
                },
              });
              if (
                mySettingsData!.mySettings!.projectInitialViewTypeCode ===
                ProjectInitialViewType.KanbanBoard
              ) {
                history.push(
                  `/app/${props.organizationId}/${props.project.team.id}/projects/${result.data!
                    .copyProject!.id!}/board/`
                );
              } else if (
                mySettingsData!.mySettings!.projectInitialViewTypeCode ===
                ProjectInitialViewType.GanttChart
              ) {
                history.push(
                  `/app/${props.organizationId}/${props.project.team.id}/projects/${result.data!
                    .copyProject!.id!}/schedule/`
                );
              } else {
                history.push(
                  `/app/${props.organizationId}/${props.project.team.id}/projects/${result.data!
                    .copyProject!.id!}/list/`
                );
              }
            }}
            onCloseModal={() => setShowCopyModal(false)}
          />
          <DeleteProjectConfirmModal
            project={props.project}
            showModal={showModal}
            onPressYes={async () => {
              setShowModal(false);
              // 先にURLを書き換えて、プロジェクト詳細画面を閉じた後に、削除リクエストをする。
              // 順序を逆にすると、削除後にプロジェクト情報を取得しようとして、サーバーサイドでEntityAlreadyDeletedException発生してしまうので注意すること。
              history.push(`/app/${loginUser!.organizationId}/${props.project.team.id!}/projects/`);
              await deleteProject();
            }}
            onCloseModal={() => setShowModal(false)}
          />
          <MoveProjectConfirmModal
            project={props.project}
            showModal={showMoveModal}
            onPressYes={async (team) => {
              setShowMoveModal(false);
              await moveProject({
                variables: {
                  id: props.project.id!,
                  input: {
                    teamId: team.id!,
                    versionNo: props.project.versionNo,
                  },
                },
              });
              if (
                mySettingsData!.mySettings!.projectInitialViewTypeCode ===
                ProjectInitialViewType.KanbanBoard
              ) {
                history.push(
                  `/app/${loginUser!.organizationId}/${team.id!}/projects/${props!.project!
                    .id!}/board/`
                );
              } else if (
                mySettingsData!.mySettings!.projectInitialViewTypeCode ===
                ProjectInitialViewType.GanttChart
              ) {
                history.push(
                  `/app/${loginUser!.organizationId}/${team.id!}/projects/${props!.project!
                    .id!}/schedule/`
                );
              } else {
                history.push(
                  `/app/${loginUser!.organizationId}/${team.id!}/projects/${props!.project!
                    .id!}/list/`
                );
              }
            }}
            onCloseModal={() => setShowMoveModal(false)}
          />
        </HeaderMenu>

        <TitleArea>
          <EditableText
            style={{ width: '100%' }}
            ellipsis={false}
            value={props.project.name}
            validate={{
              required: {
                value: true,
                message: 'タイトルを入力してください',
              },
              maxLength: {
                value: 100,
                message: '100文字以内で入力してください',
              },
            }}
            editable={meData.me?.projectUpdatePermissionFlg}
            onClickWhenNotEditable={() => setShowUpdateProjectErrorModal(true)}
            onChange={(value) => {
              updateProject({
                variables: {
                  id: props.project.id!,
                  input: {
                    name: (value as string) || props.project.name,
                    description: props.project.description,
                    clientId: clientId || null,
                    assigneeIds: props.project.assignees.map((info) => info.member.id!) ?? [],
                    estimateTimeSec: props.project.estimateTimeSec,
                    scheduledStartDateTime: props.project.scheduledStartDateTime,
                    scheduledEndDateTime: props.project.scheduledEndDateTime,
                    key: props.project.key,
                    versionNo: props.project.versionNo,
                  },
                },
              });
            }}
            textStyle={{ fontSize: 18 }}
          />
        </TitleArea>
      </View>
      {props.children}
      <ErrorMessageModal
        showModal={showCreateProjectErrorModal}
        title={'プロジェクトを作成できません'}
        message={`プロジェクトを作成する権限がありません${'\n'}権限が必要な場合、管理権限を持っているメンバーに問い合わせてください`}
        onCloseModal={() => setShowCreateProjectErrorModal(false)}
      />
      <ErrorMessageModal
        showModal={showUpdateProjectErrorModal}
        title={'プロジェクトを編集できません'}
        message={`プロジェクトを編集する権限がありません${'\n'}権限が必要な場合、管理権限を持っているメンバーに問い合わせてください`}
        onCloseModal={() => setShowUpdateProjectErrorModal(false)}
      />
      <ErrorMessageModal
        showModal={showDeleteProjectErrorModal}
        title={'プロジェクトを削除できません'}
        message={`プロジェクトを削除する権限がありません${'\n'}権限が必要な場合、管理権限を持っているメンバーに問い合わせてください`}
        onCloseModal={() => setShowDeleteProjectErrorModal(false)}
      />
    </View>
  );
};

export default ProjectDetailCommon;
