import Pusher from 'pusher-js/react-native';
import { LoginUser } from '../modules/auth/LoginUserContext';
import Constants from 'expo-constants';
import * as Sentry from 'sentry-expo';

/**
 * Pusherクライアントを作成するファクトリークラス。
 * 通常はform dataとして認証情報をおくるが、Quarkus側でどうしてもデータを受け取ることが
 * できなかったため、Bodyで送信するようカスタマイズしている。
 * @see {@link https://github.com/pusher/pusher-js#authorizer-function}
 */
export default class PusherClientFactory {
  private constructor() {}

  public static create(user: LoginUser) {
    Pusher.logToConsole = Constants.manifest!.extra!.environment === 'develop';
    return new Pusher(Constants.manifest!.extra!.pusher.app_key, {
      cluster: Constants.manifest!.extra!.pusher.cluster,
      forceTLS: Constants.manifest!.extra!.environment === 'develop' ? false : true,
      authorizer: (channel) => ({
        authorize: async (socketId, callback) => {
          const res = await fetch(Constants.manifest!.extra!.pusher.authEndpoint, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              authorization: `Bearer ${user?.jwtToken}`,
            }),
            body: JSON.stringify({
              socket_id: socketId,
              channel_name: channel.name,
            }),
          });
          try {
            callback(null, await res.json());
          } catch (e) {
            if (Sentry.Native) {
              Sentry.Native.captureException(e);
              Sentry.Native.captureMessage(JSON.stringify(res));
            } else {
              (Sentry as any).Browser.captureException(e);
              (Sentry as any).Browser.captureMessage(JSON.stringify(res));
            }
          }
        },
      }),
    });
  }
}
