import React, { useContext } from 'react';
//@ts-ignore
import { ThemeContext } from 'styled-components/native';
import { View, Image } from 'react-native';
import { IThemePart } from '../../../../theme';
import { ResponsiveSunburst } from '@nivo/sunburst';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import TimeUtil from '../../../../../util/TimeUtil';
import {
  Organization,
  ReportType,
  useTeamSunburstGraphDataQuery,
} from '../../../../../graphql/api/API';
import moment from 'moment-timezone';
import useDimensions from 'use-dimensions';
import noData05 from '../../../../../base64Images/no-data/no-data-5';
import Spinner from '../../../../presentational/atoms/spinner';
interface ISunburstGraphInnerProps {
  organization: Organization;
  data: any;
}

const SunburstGraphInner = (props: ISunburstGraphInnerProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const { window: win } = useDimensions();

  const isEmpty = props.data?.children?.length === 0;
  if (isEmpty) {
    return (
      <View
        style={{
          height: Math.max(Math.min(win.height / 3, 500), 300),
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Image
          source={{ uri: noData05 }}
          resizeMode="contain"
          style={{
            width: 350,
            height: 200,
            opacity: 0.7,
          }}
        />
        <Typography
          variant={TypographyType.Normal}
          style={{ color: themeContext.colors.description, textAlign: 'center' }}>
          {`ご指定の集計範囲にはデータがありませんでした。${'\n'}別の期間を指定してください。`}
        </Typography>
      </View>
    );
  }

  const isLargeData = props.data?.children?.length > 50;
  if (isLargeData) {
    return (
      <View>
        <View
          style={{
            height: Math.max(Math.min(win.height / 3, 500), 200),
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Typography variant={TypographyType.Description} style={{ fontSize: 16 }}>
            データ量が多いため、グラフを表示できませんでした。
          </Typography>
        </View>
      </View>
    );
  }

  return (
    <View>
      <View style={{ height: Math.max(Math.min(win.height / 3, 500), 200) }}>
        <ResponsiveSunburst
          data={props.data}
          margin={{ top: 40, right: 20, bottom: 20, left: 20 }}
          id="name"
          value="workingTimeSec"
          cornerRadius={2}
          borderWidth={1}
          borderColor="white"
          colors={(data) => {
            return data.color!;
          }}
          childColor={'noinherit'}
          animate={false}
          motionConfig="gentle"
          isInteractive={true}
          layers={['slices', 'sliceLabels']}
          tooltip={(input) => {
            let teamName = null;
            let percent = null;
            if (input.depth === 1) {
              teamName = (input.data as any).name;
              percent = input.data.percent;
            }
            return (
              <View
                style={{
                  backgroundColor: '#FFFFFF',
                  padding: 5,
                  shadowOffset: {
                    width: 2,
                    height: 2,
                  },
                  shadowOpacity: 0.1,
                  borderColor: themeContext.colors.separator,
                  borderWidth: 1,
                }}>
                <Typography variant={TypographyType.Normal}>チーム:{teamName}</Typography>
                <Typography variant={TypographyType.Normal}>
                  作業時間:{TimeUtil.formatForTask(input.value)}
                </Typography>
                {percent && (
                  <Typography variant={TypographyType.Normal}>割合:{percent}%</Typography>
                )}
              </View>
            );
          }}
        />
      </View>
    </View>
  );
};

interface ISunburstGraphProps {
  reportType: ReportType;
  organization: Organization;
  startDateTime: moment.Moment;
  endDateTime: moment.Moment;
  teamId?: string | null;
  targetMemberId?: string | null;
  teamIds: string[];
  projectIds: string[];
  clientIds: string[];
  memberIds: string[];
  setLoadData: (value: boolean) => void;
}

const TeamSunburstGraph = (props: ISunburstGraphProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, _] = useContext(LoginUserContext);
  const { loading, data, error } = useTeamSunburstGraphDataQuery({
    variables: {
      input: {
        reportType: props.reportType,
        organizationId: loginUser!.organizationId,
        teamId: props.teamId,
        memberId: props.targetMemberId,
        start: moment(props.startDateTime).startOf('day').toISOString(),
        end: moment(props.endDateTime).endOf('day').toISOString(),
        teamIds: props.teamIds,
        projectIds: props.projectIds,
        clientIds: props.clientIds,
        memberIds: props.memberIds,
        timeZoneOffset: moment().tz(moment.tz.guess()).format('Z'),
      },
    },
    onCompleted: () => props.setLoadData(true),
  });

  if (loading) {
    return <Spinner loading={loading} />;
  }

  return (
    <SunburstGraphInner
      organization={props.organization}
      data={{
        name: 'root',
        children: data!.teamSunburstGraphData!,
      }}
    />
  );
};

export default React.memo(TeamSunburstGraph);
