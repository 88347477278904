import React, { useContext, useState } from 'react';
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../../theme';
import LoginUtil from '../../../../../modules/auth/LoginUtil';
import {
  Project,
  ProjectDocument,
  ProjectTaskStatusDocument,
  TaskStatus,
  useCreateTaskStatusMutation,
} from '../../../../../graphql/api/API';
import Form from '../../../../presentational/atoms/form';
import Input from '../../../../presentational/atoms/input';
import { View } from 'react-native';
import Button from '../../../../presentational/atoms/button';
import { CustomBoardTasksDocument } from '../../../../../graphql/api/CustomApi';

const Container = styled.View``;

interface IProps {
  project: Project;
  onComplete: (taskStatus: TaskStatus) => void;
  onCancel: () => void;
}

const TaskStatusCreateDialog = (props: IProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const teamId = LoginUtil.getTeamIdFromURl();
  const [taskStatusName, setTaskStatusName] = useState('');
  const [createTaskStatus, _] = useCreateTaskStatusMutation({
    variables: {
      projectId: props.project.id!,
      input: {
        name: taskStatusName,
      },
    },
    refetchQueries: [
      {
        query: ProjectDocument,
        variables: {
          teamId: teamId,
          id: props.project.id!,
        },
      },
      {
        query: ProjectTaskStatusDocument,
        variables: {
          projectId: props.project.id!,
        },
      },
    ],
  });

  return (
    <Container>
      <Form style={{ minWidth: 300 }}>
        <Input
          name={'taskStatusName'}
          label={'ステータス名'}
          focus={true}
          onChange={(value) => setTaskStatusName(value)}
          onPressEnter={async () => {
            const result = await createTaskStatus();
            props.onComplete(result.data!.createTaskStatus! as TaskStatus);
          }}
          validate={{
            required: {
              value: true,
              message: '新しいステータスの名前を入力してください',
            },
            maxLength: {
              value: 20,
              message: '20文字以内で入力してください',
            },
          }}
        />
        <View style={{ flexDirection: 'row' }}>
          <Button
            text="作成する"
            completeText="作成しました"
            style={{ height: 30, marginRight: 5 }}
            textStyle={{ fontSize: 14 }}
            onPress={async () => {
              const result = await createTaskStatus();
              props.onComplete(result.data!.createTaskStatus! as TaskStatus);
            }}
          />
          <Button
            text="キャンセル"
            style={{ height: 30, backgroundColor: 'transparent' }}
            textStyle={{ fontSize: 14, color: themeContext.colors.primary }}
            onPress={async () => {
              props.onCancel();
            }}
            disableValidate={true}
          />
        </View>
      </Form>
    </Container>
  );
};

export default TaskStatusCreateDialog;
