import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components/native';
import { Image } from 'react-native';
import { useScreenDimensions } from 'use-dimensions';
import { useHistory } from 'react-router';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import { IThemePart } from '../../../../theme';
import Button from '../../../../presentational/atoms/button';
import logoLargeBlack from '../../../../../base64Images/logo/logo-large-black';
import passwordResetImage from '../../../../../base64Images/password-reset/password-reset';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';

const Header = styled.View`
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  height: 100px;
  padding-left: 20px;
  padding-top: 20px;
`;

const Container = styled.View`
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  height: calc(100vh - 100px);
`;

const LeftContainer = styled.View`
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 0 50px;
`;

const RightContainer = styled.View`
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 0 50px;
`;

const RightContainerInner = styled.View`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const PasswordResetCompletePage = () => {
  const { height, width } = useScreenDimensions();
  const [loginUser, _] = useContext(LoginUserContext);
  const history = useHistory();
  const themeContext: IThemePart = useContext(ThemeContext);
  return (
    <>
      <Header>
        <Image
          source={{ uri: logoLargeBlack }}
          resizeMode="contain"
          style={{
            width: 300,
            height: 100,
          }}
        />
      </Header>
      <Container>
        <LeftContainer>
          <Image
            source={{ uri: passwordResetImage }}
            resizeMode="contain"
            style={{
              width: Math.max(width / 5, 400),
              height: Math.max(width / 5, 400),
            }}
          />
        </LeftContainer>
        <RightContainer>
          <RightContainerInner>
            <Typography variant={TypographyType.Title} style={{ marginVertical: 10 }}>
              {`パスワードを再設定しました。${'\n'}新しいパスワードでログインしてください`}
            </Typography>
            <Button
              text={'ログイン画面へ'}
              onPress={() => history.push(`/signin`)}
              style={{ marginTop: 50, minWidth: 300 }}
            />
          </RightContainerInner>
        </RightContainer>
      </Container>
    </>
  );
};

export default React.memo(PasswordResetCompletePage);
