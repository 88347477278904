import React, { useContext, useState } from 'react';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import { Image } from 'react-native';
import { useScreenDimensions } from 'use-dimensions';
import { Redirect, useHistory, useLocation } from 'react-router';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import Button from '../../../../presentational/atoms/button';
import Form from '../../../../presentational/atoms/form';
import {
  useSignupWithGoogleAccountMutation,
  useSignupWithAppleAccountMutation,
  useSignupWithMailAddressMutation,
  Plan,
} from '../../../../../graphql/api/API';
import LoginUtil from '../../../../../modules/auth/LoginUtil';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import login from '../../../../../base64Images/login/login';
import logoLargeBlack from '../../../../../base64Images/logo/logo-large-black';
import moment from 'moment-timezone';

const Header = styled.View`
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  height: 100px;
  padding-left: 20px;
  padding-top: 20px;
`;

const Container = styled.View`
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  height: calc(100vh - 100px);
`;

const LeftContainer = styled.View`
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 0 50px;
`;

const RightContainer = styled.View`
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 0 50px;
`;

const RightContainerInner = styled.View`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const SignupConfirmBeta = () => {
  const { height, width } = useScreenDimensions();
  const history = useHistory();
  const { state } = useLocation();
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  if (state === null || state === undefined) {
    return <Redirect to={'/signup'} />;
  }
  const [requestSignupWithMailAddress, _] = useSignupWithMailAddressMutation({
    variables: {
      input: {
        key: (state as any).key,
        password: (state as any).password,
        name: (state as any).name,
        profileImageBase64: (state as any).profileImageBase64,
        planCode: (state as any).plan,
        organizationName: (state as any).organizationName,
        teamName: (state as any).teamName,
        licenceCount:
          (state as any).plan === Plan.Basic || (state as any).plan === Plan.Professional
            ? 1
            : (state as any).licenceCount,
      },
    },
    fetchPolicy: 'no-cache',
  });

  const [requestSignupWithGoogle, __] = useSignupWithGoogleAccountMutation({
    variables: {
      input: {
        name: (state as any).name,
        profileImageBase64: (state as any).profileImageBase64,
        planCode: (state as any).plan,
        googleAuthCode: (state as any).googleAuthCode,
        organizationName: (state as any).organizationName,
        teamName: (state as any).teamName,
        licenceCount:
          (state as any).plan === Plan.Basic || (state as any).plan === Plan.Professional
            ? 1
            : (state as any).licenceCount,
      },
    },
    fetchPolicy: 'no-cache',
  });

  const [requestSignupWithApple] = useSignupWithAppleAccountMutation({
    variables: {
      input: {
        name: (state as any).name,
        planCode: (state as any).plan,
        appleAuthCode: (state as any).appleAuthCode,
        appleToken: (state as any).appleAuthToken,
        appleUserId: (state as any).appleUserId,
        organizationName: (state as any).organizationName,
        teamName: (state as any).teamName,
        licenceCount:
          (state as any).plan === Plan.Basic || (state as any).plan === Plan.Professional
            ? 1
            : (state as any).licenceCount,
      },
    },
    fetchPolicy: 'no-cache',
  });

  return (
    <>
      <Header>
        <Image
          source={{ uri: logoLargeBlack }}
          resizeMode="contain"
          style={{
            width: 300,
            height: 100,
          }}
        />
      </Header>
      <Container>
        <LeftContainer>
          <Image
            source={{ uri: login }}
            resizeMode="contain"
            style={{
              width: Math.max(width / 4, 500),
              height: Math.max(width / 4, 500),
            }}
          />
        </LeftContainer>
        <RightContainer>
          <RightContainerInner>
            {moment().isBefore(moment('2022/02/01')) ? (
              <>
                <Typography
                  variant={TypographyType.Title}
                  style={{ marginVertical: 20, lineHeight: 36 }}>
                  {`Time Designerは2月末まで無料でご利用頂けます。`}
                </Typography>
                <Typography
                  variant={TypographyType.Normal}
                  style={{ marginBottom: 50, fontSize: 17, lineHeight: 30 }}>
                  {`Basicプランをご利用の場合、${'\n'}3月以降も引き続き無料でご利用頂けます。${'\n\n'}Proプラン・Businessプランをご利用の場合、${'\n'}2月末まで無料試用期間となり、3月からご利用料金がかかります。${'\n'}クレジットカードのご登録を頂かない限り、${'\n'}勝手に課金されることはありませんのでご安心ください。`}
                </Typography>
              </>
            ) : (
              <>
                <Typography
                  variant={TypographyType.Normal}
                  style={{ marginVertical: 20, fontSize: 17, lineHeight: 30 }}>
                  {`Basicプランをご利用の場合、無料でご利用頂けます。`}
                </Typography>
                <Typography
                  variant={TypographyType.Normal}
                  style={{ marginBottom: 50, fontSize: 17, lineHeight: 30 }}>
                  {`Proプラン・Businessプランをご利用の場合、${'\n'}1ヶ月間無料でご試用頂けます。${'\n'}それ以降もご利用を継続する場合には、${'\n'}クレジットカードをご登録頂く必要がございますので、${'\n'}ご了承ください。`}
                </Typography>
              </>
            )}
            <Form style={{ marginBottom: 30 }}>
              <Button
                text="登録する"
                style={{ marginTop: 20, minWidth: 300 }}
                onPress={async () => {
                  if ((state as any).googleAuthCode) {
                    const result = await requestSignupWithGoogle();
                    if (result.data?.signupWithGoogleAccount) {
                      await LoginUtil.saveToken(result.data!.signupWithGoogleAccount);
                      const user = await LoginUtil.getLoginUser();
                      setLoginUser(user);
                      history.push('/signup/thanks');
                    }
                  } else if ((state as any).appleAuthCode) {
                    const result = await requestSignupWithApple();
                    if (result.data?.signupWithAppleAccount) {
                      await LoginUtil.saveToken(result.data!.signupWithAppleAccount);
                      const user = await LoginUtil.getLoginUser();
                      setLoginUser(user);
                      history.push('/signup/thanks');
                    }
                  } else {
                    const result = await requestSignupWithMailAddress();
                    if (result.data?.signupWithMailAddress) {
                      await LoginUtil.saveToken(result.data!.signupWithMailAddress);
                      const user = await LoginUtil.getLoginUser();
                      setLoginUser(user);
                      history.push('/signup/thanks');
                    }
                  }
                }}
              />
            </Form>
          </RightContainerInner>
        </RightContainer>
      </Container>
    </>
  );
};

export default React.memo(SignupConfirmBeta);
