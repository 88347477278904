import React, { useContext, useState, useEffect, useRef } from 'react';
import { TextInputProps, TextStyle, View, ViewStyle, TouchableOpacity, Text } from 'react-native';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import { ValidationRules } from 'react-hook-form';
import { IStyleTheme, IThemePart } from '../../../theme';
import FormContext from '../form/context';
import Typography, { TypographyType } from '../typography';
import TimeUtil from '../../../../util/TimeUtil';
import Button from '../../../presentational/atoms/button';
import Form from '../form';
import CaretUpIcon from '../../molecules/image-icon/caret-up';
import CaretDownIcon from '../../molecules/image-icon/caret-down';
import CreatableSelect from 'react-select/creatable';
import ColorUtil from '../../../../util/ColorUtil';
import moment from 'moment-timezone';
import { ListValueMap } from '../input';
import { TimeSelector } from '../date-time-picker/index.web';

const times = [...Array(48)].map((_, i) => {
  if (i % 4 === 0) {
    return {
      label: `${Math.floor(i / 4)}:00`,
      value: `${Math.floor(i / 4) * 60 * 60}`,
    };
  }
  if (i % 4 === 1) {
    return {
      label: `${Math.floor(i / 4)}:15`,
      value: `${Math.floor(i / 4) * 60 * 60 + 15 * 60}`,
    };
  }
  if (i % 4 === 2) {
    return {
      label: `${Math.floor(i / 4)}:30`,
      value: `${Math.floor(i / 4) * 60 * 60 + 30 * 60}`,
    };
  }
  return {
    label: `${Math.floor(i / 4)}:45`,
    value: `${Math.floor(i / 4) * 60 * 60 + 45 * 60}`,
  };
}) as ListValueMap[];

interface ILabelProps extends IStyleTheme {
  isFloat: boolean;
}

const Container = styled.View<IStyleTheme>`
  margin: 1rem 0;
  width: 100%;
`;

const Label = styled.Text<ILabelProps>`
  position: absolute;
  left: ${(props: ILabelProps) => (props.isFloat ? 0 : 0)};
  top: ${(props: ILabelProps) => (props.isFloat ? '-1.5rem' : 0)};
  font-size: ${(props: ILabelProps) => (props.isFloat ? '0.7rem' : '1rem')};
  color: ${(props: ILabelProps) => props.theme.colors.label};
  transition: all 0.2s;
`;

const ErrorMessage = styled.Text<IStyleTheme>`
  font-size: 0.9rem;
  color: ${(props: IStyleTheme) => props.theme.colors.error};
  text-align: left;
`;

const Description = styled.Text<IStyleTheme>`
  font-size: 0.9rem;
  color: ${(props: IStyleTheme) => props.theme.colors.description};
  text-align: left;
`;

const InputText = styled.TextInput<IStyleTheme>`
  height: 1rem,
  fontSize: 1rem,
  color: ${(props: IStyleTheme) => props.theme.colors.input};
  border-bottom-color: '#555';
  outlineWidth: 0;
  border-bottom-width: 1px;
  padding: 0.3rem;
  width: 100%;
`;

interface IProps extends TextInputProps {
  ref?: any;
  name: string;
  label?: string;
  placeholder?: string;
  description?: string;
  containerStyle?: ViewStyle;
  inputContainerStyle?: ViewStyle;
  inputstyle?: TextStyle;
  validate?: ValidationRules;
  initialValue?: number;
  onChange?: (value: number | null) => void;
  onBlur?: (value: number | null, textInputElement: any) => void;
  onPressEnter?: (value: number | null) => void;
  onFocus?: () => void;
  focus?: boolean;
  additionalErrorMessage?: string | null;
  readonly?: boolean;
  disableClear?: boolean;
  enableDays?: boolean;
  disableSeconds?: boolean;
}

const TimePicker: React.FC<IProps> = (props: IProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [value, setValue] = React.useState<number | null>(props.initialValue || 0);
  const [inputValue, setInputValue] = React.useState<string>('');
  const [days, setDays] = React.useState<number>(
    props.enableDays ? Math.floor((props.initialValue || 0) / (60 * 60 * 24)) : 0
  );
  const [hours, setHours] = React.useState<number>(
    props.enableDays
      ? Math.floor((props.initialValue || 0) / (60 * 60)) -
          24 * Math.floor((props.initialValue || 0) / (60 * 60 * 24))
      : Math.floor((props.initialValue || 0) / (60 * 60))
  );
  const [minites, setMinites] = React.useState<number>(
    Math.floor((props.initialValue || 0) / 60) % 60
  );
  const [dirty, setDirty] = useState(false);
  const [isFocused, setFocused] = useState(false);
  const [isOverX, setOverX] = useState(false);
  const calendarRef = useRef();
  const form = useContext(FormContext);
  const [selectInputValue, setSelectInputValue] = useState('');
  const [focusTimeSelect, setFocusTimeSelect] = useState(false);
  const [isOpenTimeSelect, setOpenTimeSelect] = useState(false);
  const timeSelectRef = useRef();

  // バリデーションを使えるように設定
  const { mode } = props as any;
  let textInputRef: any = useRef();
  let textInputElement: any = null;
  const setTextInputRef = (element: any) => {
    textInputElement = element;
    form.register({ name: props.name }, props.validate || {});
    textInputRef.current = element;
  };

  useEffect(() => {
    setValue((days * 24 + hours) * 60 * 60 + minites * 60);
  }, [days, hours, minites]);

  useEffect(() => {
    if (textInputElement && props.focus === true) {
      setFocused(true);
      textInputElement.focus();
    }
  }, [textInputElement, props.focus]);

  useEffect(() => {
    if (value !== null && value !== undefined && !isFocused) {
      if (!!props.disableSeconds) {
        setInputValue(TimeUtil.formatSecToShortTime(value));
      } else {
        setInputValue(TimeUtil.formatForTask(value));
      }
    }
  }, [value]);

  const clickDocument = (e: any) => {
    if (focusTimeSelect) {
      return;
    }
    if ((calendarRef?.current as any)?.contains(e.target)) {
      return;
    }
    if ((textInputRef?.current as any)?.contains(e.target)) {
      return;
    }
    if (props.onBlur) {
      props.onBlur(value, textInputElement);
    }
  };
  useEffect(() => {
    window.addEventListener('click', clickDocument);
    return () => {
      window.removeEventListener('click', clickDocument);
    };
  }, [clickDocument]);

  useEffect(() => {
    setOverX((calendarRef.current as any)?.getBoundingClientRect().x + 250 > window.innerWidth);
  }, []);

  return (
    <>
      <InputText
        multiline={props.multiline}
        ref={setTextInputRef}
        autoCompleteType={'off'}
        value={inputValue}
        onChangeText={(value: string) => {
          setInputValue(value);
          if (value) {
            const times = value.split(':');
            if (!!props.disableSeconds) {
              if (times.length === 2) {
                let newSeconds = 0;
                const hours = Number(times[0].replace(':', ''));
                if (Number.isFinite(hours)) {
                  newSeconds = newSeconds + hours * 60 * 60;
                }
                const minutes = Number(times[1].replace(':', ''));
                if (Number.isFinite(minutes)) {
                  newSeconds = newSeconds + minutes * 60;
                }
                setValue(newSeconds);
                return;
              }
              {
                let newSeconds = 0;
                const seconds = Number(value.replace(':', ''));
                if (Number.isFinite(seconds)) {
                  newSeconds = newSeconds + seconds;
                }
                setValue(newSeconds);
                return;
              }
            } else {
              if (times.length >= 3) {
                let newSeconds = 0;
                const hours = Number(times[0].replace(':', ''));
                if (Number.isFinite(hours)) {
                  newSeconds = newSeconds + hours * 60 * 60;
                }
                const minutes = Number(times[1].replace(':', ''));
                if (Number.isFinite(minutes)) {
                  newSeconds = newSeconds + minutes * 60;
                }
                const seconds = Number(times[2].replace(':', ''));
                if (Number.isFinite(seconds)) {
                  newSeconds = newSeconds + seconds;
                }
                setValue(newSeconds);
                return;
              }
              if (times.length === 2) {
                let newSeconds = 0;
                const minutes = Number(times[0].replace(':', ''));
                if (Number.isFinite(minutes)) {
                  newSeconds = newSeconds + minutes * 60;
                }
                const seconds = Number(times[1].replace(':', ''));
                if (Number.isFinite(seconds)) {
                  newSeconds = newSeconds + seconds;
                }
                setValue(newSeconds);
                return;
              }
              {
                let newSeconds = 0;
                const seconds = Number(value.replace(':', ''));
                if (Number.isFinite(seconds)) {
                  newSeconds = newSeconds + seconds;
                }
                setValue(newSeconds);
                return;
              }
            }
          }
        }}
        onKeyPress={(event: any) => {
          switch (event.nativeEvent.key) {
            case 'Enter':
              if (props.onPressEnter) {
                if (inputValue.trim().length === 0) {
                  props.onPressEnter(null);
                } else {
                  props.onPressEnter(value);
                }
              }
              break;
            case 'Escape':
              setValue(props.initialValue || null);
              setFocused(false);
              setDirty(false);
              if (props.onChange) {
                props.onChange(props.initialValue || null);
              }
              textInputElement.blur();
              break;
            case 'ArrowUp':
              event.preventDefault();
              if (value !== null && value !== undefined) {
                const newValue = value + 5 * 60;
                setValue(newValue);
                if (!!props.disableSeconds) {
                  setInputValue(TimeUtil.formatSecToShortTime(newValue));
                } else {
                  setInputValue(TimeUtil.formatForTask(newValue));
                }
              }
              break;
            case 'ArrowDown':
              event.preventDefault();
              if (value !== null && value !== undefined && value >= 5 * 60) {
                const newValue = value - 5 * 60;
                setValue(newValue);
                if (!!props.disableSeconds) {
                  setInputValue(TimeUtil.formatSecToShortTime(newValue));
                } else {
                  setInputValue(TimeUtil.formatForTask(newValue));
                }
              }
              break;
            default:
              break;
          }
        }}
      />
      <Container
        ref={calendarRef as any}
        style={[
          {
            backgroundColor: '#FFFFFF',
            position: 'absolute',
            top: 20,
            width: props.enableDays ? 360 : 240,
            right: isOverX ? 0 : 'auto',
            zIndex: 2,
            borderWidth: 1,
            borderColor: themeContext.colors.separator,
            paddingHorizontal: 10,
            paddingVertical: 10,
            shadowOffset: {
              width: 1,
              height: 1,
            },
            shadowOpacity: 0.1,
            elevation: 2,
          },
          props.containerStyle as any,
        ]}>
        <View style={{ flexDirection: 'column' }}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: 10,
              borderTopWidth: 1,
              borderColor: themeContext.colors.separator,
              flex: 1,
            }}>
            <TimeSelector
              minutes={Math.floor((value ?? 0) / 60)}
              unlimitedHour={true}
              enableDays={props.enableDays}
              onFocus={() => setFocusTimeSelect(true)}
              onBlur={() => setFocusTimeSelect(false)}
              onChange={(min) => {
                const newValue = min * 60;
                setValue(newValue);
                if (!!props.disableSeconds) {
                  setInputValue(TimeUtil.formatSecToShortTime(newValue));
                } else {
                  setInputValue(TimeUtil.formatForTask(newValue));
                }

                if (!!props.enableDays) {
                  const hour = Math.floor(newValue / 60 / 60) % 24;
                  const day = Math.floor(newValue / 60 / 60 / 24);
                  setHours(hour);
                  setDays(day);
                } else {
                  const hour = Math.floor(newValue / 60 / 60);
                  setHours(hour);
                  setDays(0);
                }
                const minutes = Math.floor((newValue % (60 * 60)) / 60);
                setMinites(minutes);
              }}
            />
          </View>
          <View style={{ flexDirection: 'row' }}>
            {!!props.enableDays && (
              <View style={{ flexDirection: 'column', width: 120 }}>
                <CaretUpIcon
                  size={12}
                  containerStyle={{ paddingVertical: 0, justifyContent: 'center' }}
                  onPress={() => {
                    if (days === 7) {
                      return;
                    }
                    setDays(days + 1);
                    if (!!props.disableSeconds) {
                      TimeUtil.formatSecToShortTime(
                        ((days + 1) * 24 + hours) * 60 * 60 + minites * 60
                      );
                    } else {
                      TimeUtil.formatForTask(((days + 1) * 24 + hours) * 60 * 60 + minites * 60);
                    }
                  }}
                />
                <Typography variant={TypographyType.Normal} style={{ textAlign: 'center' }}>
                  {`${days}日`}
                </Typography>
                <CaretDownIcon
                  size={12}
                  containerStyle={{ paddingVertical: 0, justifyContent: 'center' }}
                  onPress={() => {
                    if (days <= 1) {
                      setDays(0);
                      if (!!props.disableSeconds) {
                        setInputValue(
                          TimeUtil.formatSecToShortTime(
                            ((days + 1) * 24 + hours) * 60 * 60 + minites * 60
                          )
                        );
                      } else {
                        setInputValue(
                          TimeUtil.formatForTask((0 * 24 + hours) * 60 * 60 + minites * 60)
                        );
                      }
                    } else {
                      setDays(days - 1);
                      if (!!props.disableSeconds) {
                        setInputValue(
                          TimeUtil.formatSecToShortTime(
                            ((days - 1) * 24 + hours) * 60 * 60 + minites * 60
                          )
                        );
                      } else {
                        setInputValue(
                          TimeUtil.formatForTask(((days - 1) * 24 + hours) * 60 * 60 + minites * 60)
                        );
                      }
                    }
                  }}
                />
              </View>
            )}
            <View style={{ flexDirection: 'column', width: 120 }}>
              <CaretUpIcon
                size={12}
                containerStyle={{ paddingVertical: 0, justifyContent: 'center' }}
                onPress={() => {
                  if (props.enableDays && hours === 23) {
                    setHours(0);
                    if (!!props.disableSeconds) {
                      setInputValue(
                        TimeUtil.formatSecToShortTime(days * 24 * 60 * 60 + minites * 60)
                      );
                    } else {
                      setInputValue(TimeUtil.formatForTask(days * 24 * 60 * 60 + minites * 60));
                    }
                  } else {
                    setHours(hours + 1);
                    if (!!props.disableSeconds) {
                      setInputValue(
                        TimeUtil.formatSecToShortTime(
                          (days * 24 + (hours + 1)) * 60 * 60 + minites * 60
                        )
                      );
                    } else {
                      setInputValue(
                        TimeUtil.formatForTask((days * 24 + (hours + 1)) * 60 * 60 + minites * 60)
                      );
                    }
                  }
                }}
              />
              <Typography variant={TypographyType.Normal} style={{ textAlign: 'center' }}>
                {`${hours}時間`}
              </Typography>
              <CaretDownIcon
                size={12}
                containerStyle={{ paddingVertical: 0, justifyContent: 'center' }}
                onPress={() => {
                  if (hours <= 1) {
                    setHours(0);
                    if (!!props.disableSeconds) {
                      setInputValue(
                        TimeUtil.formatSecToShortTime((days * 24 + 0) * 60 * 60 + minites * 60)
                      );
                    } else {
                      setInputValue(
                        TimeUtil.formatForTask((days * 24 + 0) * 60 * 60 + minites * 60)
                      );
                    }
                  } else {
                    setHours(hours - 1);
                    if (!!props.disableSeconds) {
                      setInputValue(
                        TimeUtil.formatSecToShortTime(
                          (days * 24 + hours - 1) * 60 * 60 + minites * 60
                        )
                      );
                    } else {
                      setInputValue(
                        TimeUtil.formatForTask((days * 24 + hours - 1) * 60 * 60 + minites * 60)
                      );
                    }
                  }
                }}
              />
            </View>
            <View style={{ flexDirection: 'column', width: 120 }}>
              <CaretUpIcon
                size={12}
                containerStyle={{ paddingVertical: 0, justifyContent: 'center' }}
                onPress={() => {
                  if (minites === 59) {
                    setMinites(0);
                    if (!!props.disableSeconds) {
                      setInputValue(
                        TimeUtil.formatSecToShortTime((days * 24 + hours) * 60 * 60 + 0 * 60)
                      );
                    } else {
                      setInputValue(TimeUtil.formatForTask((days * 24 + hours) * 60 * 60 + 0 * 60));
                    }
                  } else {
                    setMinites(minites + 1);
                    if (!!props.disableSeconds) {
                      setInputValue(
                        TimeUtil.formatSecToShortTime(
                          (days * 24 + hours) * 60 * 60 + (minites + 1) * 60
                        )
                      );
                    } else {
                      setInputValue(
                        TimeUtil.formatForTask((days * 24 + hours) * 60 * 60 + (minites + 1) * 60)
                      );
                    }
                  }
                }}
              />
              <Typography variant={TypographyType.Normal} style={{ textAlign: 'center' }}>
                {`${minites}分`}
              </Typography>
              <CaretDownIcon
                size={12}
                containerStyle={{ paddingVertical: 0, justifyContent: 'center' }}
                onPress={() => {
                  if (minites === 0) {
                    setMinites(59);
                    if (!!props.disableSeconds) {
                      setInputValue(
                        TimeUtil.formatSecToShortTime((days * 24 + hours) * 60 * 60 + 59 * 60)
                      );
                    } else {
                      setInputValue(
                        TimeUtil.formatForTask((days * 24 + hours) * 60 * 60 + 59 * 60)
                      );
                    }
                  } else {
                    setMinites(minites - 1);
                    if (!!props.disableSeconds) {
                      setInputValue(
                        TimeUtil.formatSecToShortTime(
                          (days * 24 + hours) * 60 * 60 + (minites - 1) * 60
                        )
                      );
                    } else {
                      setInputValue(
                        TimeUtil.formatForTask((days * 24 + hours) * 60 * 60 + (minites - 1) * 60)
                      );
                    }
                  }
                }}
              />
            </View>
          </View>
          <View
            style={{
              flexDirection: 'column',
              alignItems: 'center',
              borderTopWidth: 1,
              borderColor: themeContext.colors.separator,
            }}>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 16,
              }}>
              <Button
                text={'決定'}
                style={{ minWidth: 30, paddingVertical: 2, paddingHorizontal: 10 }}
                textStyle={{ fontSize: 14 }}
                onPress={() => {
                  if (props.onChange) {
                    props.onChange(value);
                  }
                }}
              />
              {props.disableClear !== true && (
                <Button
                  text={'クリア'}
                  style={{
                    minWidth: 30,
                    paddingVertical: 2,
                    paddingHorizontal: 10,
                    marginLeft: 10,
                    backgroundColor: 'transparent',
                  }}
                  textStyle={{ color: themeContext.colors.primary, fontSize: 14 }}
                  disableValidate={true}
                  onPress={() => {
                    setValue(null);
                    setInputValue('');
                    if (props.onChange) {
                      props.onChange(null);
                    }
                  }}
                />
              )}
            </View>
          </View>
        </View>
      </Container>
    </>
  );
};

export default TimePicker;
