import React, { useContext } from 'react';
import ListMenuApp from '../../../organisms/list-menu-app';
import { View } from 'react-native';
import AppTeamAnalyticsGraphPage from './graph';
import TeamDataExportPage from './export';
import TeamCsvExportPage from './csv-export';
import { LoginUserContext } from '../../../../../../modules/auth/LoginUserContext';
import { Plan, useOrganizationQuery, useTeamQuery } from '../../../../../../graphql/api/API';
import CustomScrollView from '../../../../../presentational/atoms/custom-scroll-view';
import LoginUtil from '../../../../../../modules/auth/LoginUtil';

const AppTeamAnalyticsPage = () => {
  const [loginUser, _] = useContext(LoginUserContext);
  const teamId = LoginUtil.getTeamIdFromURl();
  const { loading, data, error } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId,
    },
  });
  const fetchTeam = useTeamQuery({
    variables: {
      id: teamId!,
    },
  });

  if (loading || fetchTeam.loading) {
    return <></>;
  }

  return (
    <CustomScrollView
      style={{
        height: 'calc(100vh - 57px)',
      }}>
      <ListMenuApp
        headerTitle={`チームの時間分析`}
        resizeCookeName="TEAM_ANALYTICS_LIST_WIDTH"
        routes={[
          {
            name: 'グラフ分析',
            path: `/app/:organizationId/:teamId/team-analytics/graph/`,
            component: AppTeamAnalyticsGraphPage,
          },
          {
            name: 'Excelファイル出力',
            path: `/app/:organizationId/:teamId/team-analytics/export/`,
            component: TeamDataExportPage,
          },
          {
            name: 'CSVファイル出力',
            path: `/app/:organizationId/:teamId/team-analytics/csv-export/`,
            component: TeamCsvExportPage,
          },
        ]}
      />
    </CustomScrollView>
  );
};

export default React.memo(AppTeamAnalyticsPage);
