import moment from 'moment-timezone';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { useHistory, useLocation } from 'react-router';
import styled, { ThemeContext } from 'styled-components/native';
import {
  LatestWorkingHistoryDocument,
  ProjectTasksDocument,
  Task,
  TaskWorkingHistorySummaryGroupByAssineeDocument,
  useDeleteTaskMutation,
  useProjectQuery,
  useStartTaskMutation,
  useStopTaskMutation,
  useUpdateTaskMutation,
  useUpdateStatusOfTaskMutation,
  useProjectTaskStatusQuery,
  TaskDocument,
  useCopyTaskMutation,
  ProjectWorkingTimeSecDocument,
  useCreateAttachementFileUploadUrlMutation,
  useCreateAttachementFileForTaskMutation,
  TaskAttachmentFilesDocument,
  ProjectInitialViewType,
  useMySettingsQuery,
  useFavoriteTaskMutation,
  useCancelFavoriteTaskMutation,
  useMeQuery,
  TaskWorkingHistoriesForSummaryDocument,
  WorkingHistoryForSummaryDocument,
  CalendarWorkingHistoryDocument,
} from '../../../../../../graphql/api/API';
import { LoginUserContext } from '../../../../../../modules/auth/LoginUserContext';
import Button from '../../../../../presentational/atoms/button';
import EditableText from '../../../../../presentational/atoms/editable-text';
import Icon from '../../../../../presentational/atoms/icon';
import Typography, { TypographyType } from '../../../../../presentational/atoms/typography';
import Modal from '../../../../../presentational/molecules/modal';
import { IStyleTheme, IThemePart } from '../../../../../theme';
import HorizontalMenu from '../../horizontal-menu';
import { useHotkeys } from 'react-hotkeys-hook';
import { toast } from 'react-toastify';
import MoveTaskModal from '../../../organisms/move-task-modal';
import TaskUtil from '../../../../../../util/TaskUtil';
import UrlUtil from '../../../../../../util/UrlUtil';
import StopIcon from '../../../../../presentational/molecules/image-icon/stop';
import PlayIcon from '../../../../../presentational/molecules/image-icon/play';
import DeleteIcon from '../../../../../presentational/molecules/image-icon/delete';
import CloseIcon from '../../../../../presentational/molecules/image-icon/close';
import StarIcon from '../../../../../presentational/molecules/image-icon/star';
import MenuIcon from '../../../../../presentational/molecules/image-icon/menu';
import FileMoveIcon from '../../../../../presentational/molecules/image-icon/file-move';
import CopyIcon from '../../../../../presentational/molecules/image-icon/copy';
import AttachmentIcon from '../../../../../presentational/molecules/image-icon/attachment';
import HashIdUtil from '../../../../../../util/HashIdUtil';
import Checkbox from '../../../../../presentational/atoms/checkbox';
import taskList from '../../task-list';
import ErrorMessageModal from '../../error-message-modal';

const HeaderMenu = styled.View`
  display: flex;
  flex-direction: row;
  padding: 10px 15px;
  justify-content: space-between;
  align-items: center;
  border-bottom-width: 1px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
`;

const TitleArea = styled.View`
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  align-items: flex-start;
`;

const CommonArea = styled.View`
  display: flex;
  flex-direction: column;
  padding: 5px 15px;
  align-items: flex-start;
`;

const Menu = styled.View`
  position: absolute;
  top: 40px;
  right: 0;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
  border-width: 1px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 160px;
`;

interface ICopyTaskModalProps {
  task: Task;
  showModal: boolean;
  onPressYes: (copyAssignee: boolean, copyComment: boolean) => Promise<void>;
  onCloseModal: () => void;
}
const CopyTaskConfirmModal = (props: ICopyTaskModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [copyAssignee, setCopyAssignee] = useState(false);
  const [copyComment, setCopyComment] = useState(false);
  return (
    <Modal
      title={'このタスクをコピーしますか？'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <View style={{ flexDirection: 'column' }}>
        <View style={{ marginTop: 10 }}>
          <Typography variant={TypographyType.Normal} style={{ textAlign: 'center' }}>
            {props.task.title}
          </Typography>
        </View>
        <View style={{ marginTop: 10 }}>
          <Typography variant={TypographyType.Description} style={{ textAlign: 'left' }}>
            {`タスクの担当者・進捗率・作業履歴・コメントはコピーされません。${'\n'}担当者・コメントもコピーしたい場合には、以下にチェックを入れてください。`}
          </Typography>
        </View>
        <TouchableOpacity
          style={{ flexDirection: 'row', marginTop: 20 }}
          onPress={() => setCopyAssignee(!copyAssignee)}>
          <Checkbox size={24} value={copyAssignee} color={themeContext.colors.description} />
          <Typography
            variant={TypographyType.Normal}
            style={{
              color: themeContext.colors.description,
              marginLeft: 10,
            }}>
            タスクの担当者情報もコピーする
          </Typography>
        </TouchableOpacity>
        <TouchableOpacity
          style={{ flexDirection: 'row', marginTop: 10 }}
          onPress={() => setCopyComment(!copyComment)}>
          <Checkbox size={24} value={copyComment} color={themeContext.colors.description} />
          <Typography
            variant={TypographyType.Normal}
            style={{
              color: themeContext.colors.description,
              marginLeft: 10,
            }}>
            タスクのコメントもコピーする
          </Typography>
        </TouchableOpacity>
        <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
          <Button
            text={'コピーする'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
            }}
            onPress={async () => {
              await props.onPressYes(copyAssignee, copyComment);
            }}
          />
          <Button
            text={'キャンセル'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.primary }}
            disableValidate={true}
            onPress={() => {
              props.onCloseModal();
            }}
          />
        </View>
      </View>
    </Modal>
  );
};

interface IDeleteTaskModalProps {
  task: Task;
  showModal: boolean;
  onPressYes: () => Promise<void>;
  onCloseModal: () => void;
}
const DeleteTaskConfirmModal = (props: IDeleteTaskModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const { loading, data } = useMeQuery({});

  if (loading || !data?.me) {
    return <></>;
  }

  if (data!.me!.taskDeletePermissionFlg) {
    return (
      <Modal
        title={'このタスクを削除しますか？'}
        isShow={props.showModal}
        onClose={() => {
          props.onCloseModal();
        }}>
        <View style={{ flexDirection: 'column' }}>
          <View style={{ marginTop: 10 }}>
            <Typography variant={TypographyType.Normal} style={{ textAlign: 'center' }}>
              {props.task.title}
            </Typography>
          </View>
          <View style={{ marginTop: 10 }}>
            <Typography
              variant={TypographyType.Description}
              style={{ textAlign: 'center', color: themeContext.colors.error }}>
              {`この操作はやり直しが出来ません`}
            </Typography>
          </View>
          <View
            style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
            <Button
              text={'キャンセル'}
              style={{
                minWidth: 100,
                marginRight: 10,
                marginVertical: 10,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.primary }}
              disableValidate={true}
              onPress={() => {
                props.onCloseModal();
              }}
            />
            <Button
              text={'削除する'}
              style={{
                minWidth: 100,
                marginRight: 10,
                marginVertical: 10,
                borderColor: themeContext.colors.error,
                borderRadius: 3,
                borderWidth: 1,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.error }}
              onPress={async () => {
                await props.onPressYes();
              }}
            />
          </View>
        </View>
      </Modal>
    );
  }
  return (
    <Modal
      title={'タスクを削除する権限がありません'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <View style={{ flexDirection: 'column' }}>
        <View style={{ marginTop: 10 }}>
          <Typography
            variant={TypographyType.Description}
            style={{ textAlign: 'center', color: themeContext.colors.error }}>
            {`タスクを削除する権限がないため、削除することができません。${'\n'}権限が必要な場合、管理権限を持っているメンバーの方に問い合わせてください。`}
          </Typography>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
          <Button
            text={'キャンセル'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.primary }}
            disableValidate={true}
            onPress={() => {
              props.onCloseModal();
            }}
          />
        </View>
      </View>
    </Modal>
  );
};

interface ITaskDetailCommonProps {
  organizationId: string;
  task: Task;
  showCalendarMenu: boolean;
  children: React.ReactNode;
}

const TaskDetailCommon = (props: ITaskDetailCommonProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const history = useHistory();
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [isTracking, setTracking] = useState(TaskUtil.isTracking(props.task, loginUser!));
  const { pathname } = useLocation();
  const attachmentFileInputRef = useRef();

  useEffect(() => {
    setTracking(TaskUtil.isTracking(props.task, loginUser!));
  }, [props.task.workingMembers]);

  const fetchTaskStatuses = useProjectTaskStatusQuery({
    variables: {
      projectId: props.task.project.id!,
    },
    fetchPolicy: 'network-only',
  });

  const [deleteTask, _] = useDeleteTaskMutation({
    variables: {
      id: props.task.id!,
      input: {
        versionNo: props.task.versionNo,
      },
    },
    update: (cache, result) => {
      cache.evict({
        id: cache.identify(props.task),
      });
    },
    refetchQueries: [
      {
        query: ProjectTasksDocument,
        variables: {
          teamId: props.task.project.team.id,
          projectId: props.task.project.id!,
        },
      },
    ],
  });
  const [updateTask, updateTaskResult] = useUpdateTaskMutation();
  const [updateTaskStatus, updateTaskStatusResult] = useUpdateStatusOfTaskMutation({
    update: (cache, result) => {
      cache.modify({
        fields: {
          boardTasks(existingRef, { readField }) {
            const taskStatusId = `TaskStatus:${result.data?.updateStatusOfTask?.taskStatus?.id}`; // Pusherからemptyデータが来るケースがあるので、taskStatusが無い場合がある
            if (
              taskStatusId === existingRef.taskStatus.__ref &&
              !existingRef.tasks.includes(taskStatusId)
            ) {
              return {
                taskStatus: existingRef.taskStatus,
                tasks: [
                  ...existingRef.tasks,
                  {
                    __ref: `Task:${result.data?.updateStatusOfTask!.id}`,
                  },
                ],
              };
            } else if (
              taskStatusId !== existingRef.taskStatus.__ref &&
              existingRef.tasks.includes(taskStatusId)
            ) {
              return {
                taskStatus: existingRef.taskStatus,
                tasks: existingRef.tasks.filter(
                  (taskRef: any) => taskRef !== `Task:${result.data?.updateStatusOfTask!.id}`
                ),
              };
            }
            return existingRef;
          },
        },
      });
    },
    refetchQueries: [
      {
        query: TaskWorkingHistorySummaryGroupByAssineeDocument,
        variables: {
          teamId: props.task.project.team.id!,
          taskId: props.task.id!,
        },
      },
      {
        query: LatestWorkingHistoryDocument,
      },
      {
        query: ProjectWorkingTimeSecDocument,
        variables: {
          id: props.task.project.id,
        },
      },
    ],
  });
  const [startTask, startTaskResult] = useStartTaskMutation({
    update: (cache, result) => {
      // レスポンスの中のネストされたデータは、自動的にはキャッシュ更新されないので、自前でキャッシュ更新している
      cache.writeQuery({
        query: TaskDocument,
        variables: { id: result.data!.startTask!.startTask.id! },
        data: { task: result.data!.startTask!.startTask },
      });
      cache.writeQuery({
        query: LatestWorkingHistoryDocument,
        data: { latestWorkingHistory: result.data!.startTask!.startWorkingHistory },
      });
      if (result.data?.startTask?.stopTask) {
        cache.writeQuery({
          query: TaskDocument,
          variables: { id: result.data!.startTask!.stopTask!.id! },
          data: { task: result.data!.startTask!.stopTask },
        });
      }
      cache.modify({
        fields: {
          workingHistoriesSpecifyTermForCalendar(existing = [], { storeFieldName }) {
            const newWorkingHistories = cache.writeQuery({
              query: CalendarWorkingHistoryDocument,
              data: result.data?.startTask?.stopWorkingHistory,
            });
            const targetMemberData = existing.filter((d: any) => d.memberId === loginUser?.id)[0];
            if((targetMemberData?.length ?? 0) === 0){
              return [...existing, {
                memberId: loginUser?.id,
                workingHistories: newWorkingHistories
              }];
            }
            return existing.map((data: any) => {
              if(data.memberId !== loginUser?.id){
                return data;
              }
              return {
                memberId: data.memberId,
                workingHistories: [...targetMemberData.workingHistories, newWorkingHistories]
              }
            })
          },
        },
      });
    },
    onError: (error) => {
      setTracking(TaskUtil.isTracking(props.task, loginUser!));
    },
    refetchQueries: [
      {
        query: TaskWorkingHistorySummaryGroupByAssineeDocument,
        variables: {
          teamId: props.task.project.team.id!,
          taskId: props.task.id!,
        },
      },
      {
        query: TaskWorkingHistoriesForSummaryDocument,
        variables: {
          taskId: props.task.id!,
        },
      },
    ],
  });
  const [stopTask, stopTaskResult] = useStopTaskMutation({
    update: (cache, result) => {
      // レスポンスの中のネストされたデータは、自動的にはキャッシュ更新されないので、自前でキャッシュ更新している
      cache.writeQuery({
        query: TaskDocument,
        variables: { id: result.data!.stopTask!.stopTask.id! },
        data: { task: result.data!.stopTask!.stopTask },
      });
    },
    onError: (error) => {
      setTracking(TaskUtil.isTracking(props.task, loginUser!));
    },
    refetchQueries: [
      {
        query: TaskWorkingHistorySummaryGroupByAssineeDocument,
        variables: {
          teamId: props.task.project.team.id!,
          taskId: props.task.id!,
        },
      },
      {
        query: LatestWorkingHistoryDocument,
      },
      {
        query: ProjectWorkingTimeSecDocument,
        variables: {
          id: props.task.project.id,
        },
      },
    ],
  });
  const [copyTask] = useCopyTaskMutation({
    update: (cache, result) => {
      // 自動的にはRefetchされないので。
      cache.modify({
        fields: {
          projectTasks(existing = []) {
            const newTask = cache.writeQuery({
              data: result.data?.copyTask,
              query: TaskDocument,
            });
            return [newTask, ...existing];
          },
          searchTasks(existing = []) {
            const newTask = cache.writeQuery({
              data: result.data?.copyTask,
              query: TaskDocument,
            });
            return [newTask, ...existing];
          },
        },
      });
    },
  });

  const [createAttachmentFileUploadUrl, __] = useCreateAttachementFileUploadUrlMutation();
  const [createAttachemntFile, ___] = useCreateAttachementFileForTaskMutation();

  const [showModal, setShowModal] = useState(false);
  const [showCopyModal, setShowCopyModal] = useState(false);
  const [showMoveModal, setShowMoveModal] = useState(false);
  const [showConfirmStartDialog, setShowConfirmStartDialog] = useState(false);
  const menuRef = useRef();
  const [openMenu, setOpenMenu] = useState(false);
  const [showCreateTaskErrorModal, setShowCreateTaskErrorModal] = useState(false);
  const [showUpdateTaskErrorModal, setShowUpdateTaskErrorModal] = useState(false);
  const [showDeleteTaskErrorModal, setShowDeleteTaskErrorModal] = useState(false);
  const [showCompleteTaskStartErrorModal, setShowCompleteTaskStartErrorModal] = useState(false);
  useHotkeys('Esc', () => setOpenMenu(false));
  const clickDocument = (e: any) => {
    if ((menuRef?.current as any)?.contains(e.target)) {
      (menuRef?.current as any)?.click();
      return;
    }
    setOpenMenu(false);
  };
  useEffect(() => {
    window.addEventListener('click', clickDocument);
    return () => {
      window.removeEventListener('click', clickDocument);
    };
  }, [clickDocument]);

  const [favoriteTask] = useFavoriteTaskMutation({
    variables: {
      id: props.task.id!,
      input: {
        versionNo: props.task.versionNo,
      },
    },
  });
  const [cancelFavoriteTask] = useCancelFavoriteTaskMutation({
    variables: {
      id: props.task.id!,
      input: {
        versionNo: props.task.versionNo,
      },
    },
  });

  const { loading: mySettingsLoading, data: mySettingsData } = useMySettingsQuery();
  const { loading, data } = useMeQuery({});

  if (
    fetchTaskStatuses.loading ||
    !fetchTaskStatuses.data?.projectTaskStatus ||
    mySettingsLoading ||
    loading ||
    !data?.me
  ) {
    return <></>;
  }

  const isCurrentProject =
    pathname.indexOf(
      `/app/${props.organizationId}/${props.task.project.team.id}/projects/${props.task.project
        .id!}/`
    ) !== -1;

  const isMyTaskProject =
    pathname.indexOf(`/app/${props.organizationId}/my/favorite-project`) !== -1 ||
    pathname.indexOf(`/app/${props.organizationId}/my/assigned-project`) !== -1;

  return (
    <View>
      <View
        style={
          {
            position: 'sticky',
            top: 0,
            zIndex: 5,
            backgroundColor: themeContext.colors.baseColor,
          } as any
        }>
        <HeaderMenu style={{ zIndex: 3 }}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            {isTracking ? (
              <StopIcon
                size={30}
                disabled={startTaskResult.loading || stopTaskResult.loading}
                onPress={() => {
                  if (isTracking !== TaskUtil.isTracking(props.task, loginUser!)) {
                    return;
                  }
                  setTracking(false);
                  stopTask({
                    variables: {
                      id: props.task.id!,
                      input: {
                        versionNo: props.task.versionNo,
                      },
                    },
                  });
                }}
              />
            ) : (
              <>
                <PlayIcon
                  size={30}
                  disabled={startTaskResult.loading || stopTaskResult.loading}
                  onPress={() => {
                    if (isTracking !== TaskUtil.isTracking(props.task, loginUser!)) {
                      return;
                    }
                    if (!!props.task.completeDateTime || props.task.project.complete) {
                      setShowCompleteTaskStartErrorModal(true);
                      return;
                    }
                    setTracking(true);
                    startTask({
                      variables: {
                        id: props.task.id!,
                        input: {
                          versionNo: props.task.versionNo,
                        },
                      },
                    });
                  }}
                />
              </>
            )}
            <Typography
              variant={TypographyType.Description}
              style={{ fontSize: 14, marginRight: 10, marginLeft: 10 }}>
              ステータス:
            </Typography>
            <EditableText
              style={{ minWidth: 150 }}
              value={props.task.taskStatus.id!.toString()}
              editable={data.me.taskUpdatePermissionFlg}
              onClickWhenNotEditable={() => setShowUpdateTaskErrorModal(true)}
              type={'picker'}
              isSearchable={true}
              emptyText={'-'}
              pickerItems={fetchTaskStatuses.data?.projectTaskStatus.map((status) => {
                return {
                  label: status!.name,
                  value: status!.id,
                };
              })}
              onChange={async (value) => {
                await updateTaskStatus({
                  variables: {
                    id: props.task.id!,
                    input: {
                      taskStatusId: value as string,
                      versionNo: props.task.versionNo,
                    },
                  },
                  refetchQueries: [
                    {
                      query: TaskWorkingHistorySummaryGroupByAssineeDocument,
                      variables: {
                        teamId: props.task.project.team.id!,
                        taskId: props.task.id!,
                      },
                    },
                    {
                      query: LatestWorkingHistoryDocument,
                    },
                    {
                      query: TaskWorkingHistoriesForSummaryDocument,
                      variables: {
                        taskId: props.task.id!,
                      },
                    },
                    {
                      query: ProjectWorkingTimeSecDocument,
                      variables: {
                        id: props.task.project.id,
                      },
                    },
                  ],
                });
              }}
            />
          </View>

          <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            {!isCurrentProject && (
              <View style={{ marginHorizontal: 10 }}>
                <TouchableOpacity
                  onPress={() => {
                    if (
                      mySettingsData!.mySettings!.projectInitialViewTypeCode ===
                      ProjectInitialViewType.KanbanBoard
                    ) {
                      history.push(
                        `/app/${props.organizationId}/${props.task.project.team.id}/projects/${props
                          .task.project.id!}/board/task/${props.task.id}/detail/`
                      );
                    } else if (
                      mySettingsData!.mySettings!.projectInitialViewTypeCode ===
                      ProjectInitialViewType.GanttChart
                    ) {
                      history.push(
                        `/app/${props.organizationId}/${props.task.project.team.id}/projects/${props
                          .task.project.id!}/schedule/task/${props.task.id}/detail/`
                      );
                    } else {
                      history.push(
                        `/app/${props.organizationId}/${props.task.project.team.id}/projects/${props
                          .task.project.id!}/list/task/${props.task.id}/detail/`
                      );
                    }
                  }}>
                  <Typography
                    variant={TypographyType.Description}
                    style={{ color: themeContext.colors.link }}>
                    プロジェクトへ移動
                  </Typography>
                </TouchableOpacity>
              </View>
            )}
            {props.task.favoriteMembers.filter((member) => member!.id === loginUser!.id).length >
            0 ? (
              <StarIcon
                size={22}
                on={true}
                containerStyle={{ marginLeft: 3 }}
                onPress={() => {
                  cancelFavoriteTask();
                }}
              />
            ) : (
              <StarIcon
                size={22}
                on={false}
                containerStyle={{ marginLeft: 3 }}
                onPress={() => {
                  favoriteTask();
                }}
              />
            )}
            <MenuIcon
              size={28}
              containerStyle={{ marginLeft: 5 }}
              onPress={() => setOpenMenu(true)}
            />
            <CloseIcon
              size={18}
              containerStyle={{ marginLeft: 5 }}
              onPress={() => {
                history.push(UrlUtil.createTaskDetailCloseUrl());
              }}
            />
          </View>
          {openMenu && (
            <Menu ref={menuRef as any}>
              <View style={{ paddingHorizontal: 5, marginVertical: 5 }}>
                <CopyIcon
                  size={20}
                  containerStyle={{ marginRight: 5 }}
                  onPress={() => {
                    setOpenMenu(false);
                    if (!data.me?.taskCreatePermissionFlg) {
                      setShowCreateTaskErrorModal(true);
                      return;
                    }
                    setShowCopyModal(true);
                  }}>
                  <Typography variant={TypographyType.SubTitle}>コピーする</Typography>
                </CopyIcon>
              </View>
              <View style={{ paddingHorizontal: 5, marginVertical: 5 }}>
                <FileMoveIcon
                  size={20}
                  containerStyle={{ marginRight: 5 }}
                  onPress={() => {
                    setOpenMenu(false);
                    if (!data.me?.taskUpdatePermissionFlg) {
                      setShowUpdateTaskErrorModal(true);
                      return;
                    }
                    setShowMoveModal(true);
                  }}>
                  <Typography variant={TypographyType.SubTitle}>移動する</Typography>
                </FileMoveIcon>
              </View>
              <View style={{ paddingHorizontal: 5, marginVertical: 5 }}>
                <AttachmentIcon
                  size={20}
                  containerStyle={{ marginRight: 5 }}
                  onPress={() => {
                    if (attachmentFileInputRef.current) {
                      (attachmentFileInputRef.current as any).click();
                    }
                  }}>
                  <Typography variant={TypographyType.SubTitle}>添付する</Typography>
                  <input
                    type="file"
                    ref={attachmentFileInputRef as any}
                    style={{ display: 'none' }}
                    onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                      setOpenMenu(false);

                      let isSuccess = true;
                      await Promise.all(
                        Array.from(e.target.files!).map(async (file) => {
                          const uploadUrlResult = await createAttachmentFileUploadUrl({
                            variables: {
                              input: {
                                fileName: file.name,
                                sizeByte: file.size,
                                type: file.type,
                              },
                            },
                          });

                          await fetch(
                            uploadUrlResult.data!.createAttachementFileUploadUrl!.uploadUrl,
                            {
                              method: 'PUT',
                              body: file,
                            }
                          ).then((response) => {
                            if (!response.ok) {
                              isSuccess = false;
                            }
                          });

                          const attachmentFileResult = await createAttachemntFile({
                            variables: {
                              input: {
                                taskId: props.task.id!,
                                fileName:
                                  uploadUrlResult.data!.createAttachementFileUploadUrl!.fileName,
                                sizeByte:
                                  uploadUrlResult.data!.createAttachementFileUploadUrl!.sizeByte,
                                type: uploadUrlResult.data!.createAttachementFileUploadUrl!.type,
                                key: uploadUrlResult.data!.createAttachementFileUploadUrl!.key,
                              },
                            },
                            refetchQueries: [
                              {
                                query: TaskAttachmentFilesDocument,
                                variables: {
                                  taskId: props.task.id,
                                },
                              },
                            ],
                          });
                          if (attachmentFileResult.errors) {
                            isSuccess = false;
                          }
                        })
                      );

                      if (isSuccess) {
                        toast('ファイルアップロードが完了しました', {
                          type: 'success',
                          position: 'bottom-right',
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        });
                      } else {
                        toast('ファイルアップロードに失敗しました', {
                          type: 'error',
                          position: 'bottom-right',
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        });
                      }
                    }}
                  />
                </AttachmentIcon>
              </View>
              <View style={{ paddingHorizontal: 5, marginVertical: 5 }}>
                <DeleteIcon
                  size={23}
                  containerStyle={{ marginRight: 5 }}
                  onPress={() => {
                    setOpenMenu(false);
                    if (!data.me?.taskDeletePermissionFlg) {
                      setShowUpdateTaskErrorModal(true);
                      return;
                    }
                    setShowModal(true);
                  }}>
                  <Typography variant={TypographyType.SubTitle}>削除する</Typography>
                </DeleteIcon>
              </View>
            </Menu>
          )}
          <CopyTaskConfirmModal
            task={props.task}
            showModal={showCopyModal}
            onPressYes={async (copyAssignee, copyComment) => {
              setShowCopyModal(false);
              const result = await copyTask({
                variables: {
                  id: props.task.id!,
                  input: {
                    copyAssignee: copyAssignee,
                    copyComment: copyComment,
                    versionNo: props.task.versionNo,
                  },
                },
              });
              if (location.hash.indexOf('/board/') !== -1) {
                history.push(
                  `/app/${props.organizationId}/${
                    props.task.project.team.id
                  }/projects/${result.data!.copyTask!.project.id!}/board/task/${
                    result.data!.copyTask!.id
                  }/detail/`
                );
              } else if (location.hash.indexOf('/schedule/') !== -1) {
                history.push(
                  `/app/${props.organizationId}/${
                    props.task.project.team.id
                  }/projects/${result.data!.copyTask!.project.id!}/schedule/task/${
                    result.data!.copyTask!.id
                  }/detail/`
                );
              } else {
                history.push(
                  `/app/${props.organizationId}/${
                    props.task.project.team.id
                  }/projects/${result.data!.copyTask!.project.id!}/list/task/${
                    result.data!.copyTask!.id
                  }/detail/`
                );
              }
            }}
            onCloseModal={() => setShowCopyModal(false)}
          />
          <DeleteTaskConfirmModal
            task={props.task}
            showModal={showModal}
            onPressYes={async () => {
              setShowModal(false);
              // 先にURLを書き換えて、タスク詳細画面を閉じた後に、削除リクエストをする。
              // 順序を逆にすると、削除後にタスク情報を取得しようとして、サーバーサイドでEntityAlreadyDeletedException発生してしまうので注意すること。
              history.push(UrlUtil.createTaskDetailCloseUrl());
              await deleteTask();
            }}
            onCloseModal={() => setShowModal(false)}
          />
          <MoveTaskModal
            task={props.task}
            showModal={showMoveModal}
            onCloseModal={() => setShowMoveModal(false)}
          />
        </HeaderMenu>

        {!isCurrentProject && !isMyTaskProject && (
          <View style={{ marginTop: 5 }}>
            <Typography
              variant={TypographyType.Normal}
              style={{
                color: themeContext.colors.description,
                fontSize: 14,
                lineHeight: 18,
                marginLeft: 17,
              }}>
              {props.task.project.team.name}
            </Typography>
            <Typography
              variant={TypographyType.Normal}
              style={{
                color: themeContext.colors.description,
                fontSize: 14,
                lineHeight: 18,
                marginLeft: 17,
              }}>
              {props.task.project.name}
            </Typography>
          </View>
        )}
        <TitleArea>
          <EditableText
            style={{ width: '100%' }}
            ellipsis={false}
            editable={data.me.taskUpdatePermissionFlg}
            onClickWhenNotEditable={() => setShowUpdateTaskErrorModal(true)}
            value={props.task.title}
            validate={{
              required: {
                value: true,
                message: 'タイトルを入力してください',
              },
              maxLength: {
                value: 100,
                message: '100文字以内で入力してください',
              },
            }}
            onChange={(value) => {
              updateTask({
                variables: {
                  id: props.task.id!,
                  input: {
                    title: (value as string) || props.task.title,
                    description: props.task.description,
                    assigneeIds: props.task.assignees.map((info) => info.member!.id!),
                    estimateTimeSec: props.task.estimateTimeSec,
                    priority: props.task.priority,
                    progressRate: props.task.progressRate,
                    scheduledStartDateTime: props.task.scheduledStartDateTime,
                    scheduledEndDateTime: props.task.scheduledEndDateTime,
                    versionNo: props.task.versionNo,
                  },
                },
              });
            }}
            textStyle={{ fontSize: 18 }}
          />
        </TitleArea>
      </View>

      <CommonArea>
        <HorizontalMenu
          style={{ marginLeft: 10 }}
          menus={
            props.showCalendarMenu
              ? [
                  {
                    title: '基本情報',
                    path:
                      UrlUtil.getCurrentPathWithoutSearch()
                        .replace(/\/task\/.*/, ``)
                        .replace(/\/+$/, ``) + `/task/${props.task.id!}/detail/`,
                  },
                  {
                    title: '作業履歴',
                    path:
                      UrlUtil.getCurrentPathWithoutSearch()
                        .replace(/\/task\/.*/, ``)
                        .replace(/\/+$/, ``) + `/task/${props.task.id!}/working-history/`,
                  },
                  {
                    title: '作業予定',
                    path:
                      UrlUtil.getCurrentPathWithoutSearch()
                        .replace(/\/task\/.*/, ``)
                        .replace(/\/+$/, ``) + `/task/${props.task.id!}/working-schedule/`,
                  },
                  {
                    title: 'リマインダー',
                    path:
                      UrlUtil.getCurrentPathWithoutSearch()
                        .replace(/\/task\/.*/, ``)
                        .replace(/\/+$/, ``) + `/task/${props.task.id!}/remainder/`,
                  },
                  {
                    title: '外部カレンダー連携',
                    path:
                      UrlUtil.getCurrentPathWithoutSearch()
                        .replace(/\/task\/.*/, ``)
                        .replace(/\/+$/, ``) + `/task/${props.task.id!}/calendar/`,
                  },
                  {
                    title: 'その他',
                    path:
                      UrlUtil.getCurrentPathWithoutSearch()
                        .replace(/\/task\/.*/, ``)
                        .replace(/\/+$/, ``) + `/task/${props.task.id!}/history/`,
                  },
                ]
              : [
                  {
                    title: '基本情報',
                    path:
                      UrlUtil.getCurrentPathWithoutSearch()
                        .replace(/\/task\/.*/, ``)
                        .replace(/\/+$/, ``) + `/task/${props.task.id!}/detail/`,
                  },
                  {
                    title: '作業履歴',
                    path:
                      UrlUtil.getCurrentPathWithoutSearch()
                        .replace(/\/task\/.*/, ``)
                        .replace(/\/+$/, ``) + `/task/${props.task.id!}/working-history/`,
                  },
                  {
                    title: '作業予定',
                    path:
                      UrlUtil.getCurrentPathWithoutSearch()
                        .replace(/\/task\/.*/, ``)
                        .replace(/\/+$/, ``) + `/task/${props.task.id!}/working-schedule/`,
                  },
                  {
                    title: 'リマインダー',
                    path:
                      UrlUtil.getCurrentPathWithoutSearch()
                        .replace(/\/task\/.*/, ``)
                        .replace(/\/+$/, ``) + `/task/${props.task.id!}/remainder/`,
                  },
                  {
                    title: 'その他',
                    path:
                      UrlUtil.getCurrentPathWithoutSearch()
                        .replace(/\/task\/.*/, ``)
                        .replace(/\/+$/, ``) + `/task/${props.task.id!}/history/`,
                  },
                ]
          }
        />
      </CommonArea>
      {props.children}

      <ErrorMessageModal
        showModal={showCreateTaskErrorModal}
        title={'タスクを作成できません'}
        message={`タスクを作成する権限がありません${'\n'}権限が必要な場合、管理権限を持っているメンバーに問い合わせてください`}
        onCloseModal={() => setShowCreateTaskErrorModal(false)}
      />
      <ErrorMessageModal
        showModal={showUpdateTaskErrorModal}
        title={'タスクを編集できません'}
        message={`タスクを編集する権限がありません${'\n'}権限が必要な場合、管理権限を持っているメンバーに問い合わせてください`}
        onCloseModal={() => setShowUpdateTaskErrorModal(false)}
      />
      <ErrorMessageModal
        showModal={showDeleteTaskErrorModal}
        title={'タスクを削除できません'}
        message={`タスクを削除する権限がありません${'\n'}権限が必要な場合、管理権限を持っているメンバーに問い合わせてください`}
        onCloseModal={() => setShowDeleteTaskErrorModal(false)}
      />
      <ErrorMessageModal
        showModal={showCompleteTaskStartErrorModal}
        title={'作業開始することができません'}
        message={`完了しているタスク・完了しているプロジェクトは${'\n'}作業開始することが出来ません。`}
        onCloseModal={() => setShowCompleteTaskStartErrorModal(false)}
      />
    </View>
  );
};

export default TaskDetailCommon;
