import React, { useContext, useState } from 'react';
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../../theme';
import {
  Project,
  TaskStatus,
  useArchiveCompletedTasksMutation,
} from '../../../../../graphql/api/API';
import Form from '../../../../presentational/atoms/form';
import { View } from 'react-native';
import Button from '../../../../presentational/atoms/button';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';

const Container = styled.View``;

interface IProps {
  taskStatus: TaskStatus;
  onComplete: () => void;
  onCancel: () => void;
}

const TaskArchiveOnBoardDialog = (props: IProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [archiveCompletedTasks, _] = useArchiveCompletedTasksMutation({
    variables: {
      projectId: props.taskStatus.project.id!,
    },
  });

  return (
    <Container>
      <Form style={{ minWidth: 300 }}>
        <Typography
          variant={TypographyType.Description}
          style={{ fontSize: 16, lineHeight: 22, marginBottom: 20 }}>
          {`完了したタスクをボードからクリアします。${'\n'}クリアしたタスクはボード上には表示されませんが、あとから参照することはできます。${'\n'}完了したタスクが増えてきた時には、タスクをクリアして管理しやすくしましょう。`}
        </Typography>
        <View style={{ flexDirection: 'row' }}>
          <Button
            text="クリアする"
            completeText="クリアしました"
            style={{ height: 30, marginRight: 5 }}
            textStyle={{ fontSize: 14 }}
            onPress={async () => {
              await archiveCompletedTasks();
              props.onComplete();
            }}
          />
          <Button
            text="キャンセル"
            style={{ height: 30, backgroundColor: 'transparent' }}
            textStyle={{ fontSize: 14, color: themeContext.colors.primary }}
            onPress={async () => {
              props.onCancel();
            }}
            disableValidate={true}
          />
        </View>
      </Form>
    </Container>
  );
};

export default TaskArchiveOnBoardDialog;
