import React, { useContext, useEffect, useState } from 'react';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import { Image, View } from 'react-native';
import { useScreenDimensions } from 'use-dimensions';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import logoLargeBlack from '../../../../../base64Images/logo/logo-large-black';
import loginImage from '../../../../../base64Images/access-deny';
import { IThemePart } from '../../../../theme';
import Button from '../../../../presentational/atoms/button';
import LoginUtil from '../../../../../modules/auth/LoginUtil';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import { useHistory } from 'react-router';
import {
  useDisableAccessControlByTokenMutation,
  useHasDisableAclPermissionLazyQuery,
  useMeQuery,
} from '../../../../../graphql/api/API';
import ApolloClientFactory from '../../../../../graphql/client/ApolloClientFactory';
import Modal from '../../../../presentational/molecules/modal';
import Form from '../../../../presentational/atoms/form';

const Header = styled.View`
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  height: 100px;
  padding-left: 20px;
  padding-top: 20px;
`;

const Container = styled.View`
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  height: calc(100vh - 100px);
`;

const LeftContainer = styled.View`
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-left: 50px;
`;

const RightContainer = styled.View`
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-right: 50px;
`;

const RightContainerInner = styled.View`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface IDisableAclModalProps {
  token: string;
  isShow: boolean;
  onClose: () => void;
}

const DisableAclModal = (props: IDisableAclModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const history = useHistory();
  const [disableAcl, _] = useDisableAccessControlByTokenMutation({
    variables: {
      token: props.token,
    },
    client: client,
  });

  return (
    <Modal
      title={'IPアドレス制限の解除'}
      isShow={props.isShow}
      isPositionTop={true}
      onClose={props.onClose}>
      <View style={{ flexDirection: 'column' }}>
        <View style={{ justifyContent: 'center', marginTop: 10 }}>
          <Form style={{ margin: 0, padding: 0, width: '100%' }}>
            <Typography
              variant={TypographyType.Title}
              style={{ fontSize: 16, textAlign: 'center' }}>
              {`本当にIPアドレス制限を解除しますか？`}
            </Typography>
            <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 30 }}>
              <Button
                text={'キャンセル'}
                style={{
                  minWidth: 100,
                  marginRight: 10,
                  marginVertical: 10,
                  backgroundColor: 'transparent',
                }}
                textStyle={{ color: themeContext.colors.primary }}
                disableValidate={true}
                onPress={() => {
                  props.onClose();
                }}
              />
              <Button
                text={'解除する'}
                style={{
                  minWidth: 100,
                  marginRight: 10,
                  marginVertical: 10,
                  borderColor: themeContext.colors.error,
                  borderRadius: 3,
                  borderWidth: 1,
                  backgroundColor: 'transparent',
                }}
                textStyle={{ color: themeContext.colors.error }}
                onPress={async () => {
                  await disableAcl();
                  history.push(`/app/${loginUser?.organizationId}/reset-acl-complete`);
                  props.onClose();
                }}
              />
            </View>
          </Form>
        </View>
      </View>
    </Modal>
  );
};

const client = ApolloClientFactory.create(
  () => {},
  (message) => {},
  () => {},
  () => {},
  () => {},
  true
);

const AccessDenyResetPage = () => {
  const { width } = useScreenDimensions();
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [jwtToken, setJwtToken] = useState<string | null>(null);
  const [isShowModal, setShowModal] = useState<boolean>(false);
  const history = useHistory();
  const [fetchHasDisableAclPermission, fetchHasDisableAclPermissionResult] =
    useHasDisableAclPermissionLazyQuery({
      client: client,
    });

  useEffect(() => {
    LoginUtil.getLoginUsersJwtToken().then(async (token) => {
      if (!token) {
        return;
      }
      await fetchHasDisableAclPermission({
        variables: {
          token: token,
        },
      });
      setJwtToken(token);
    });
  }, []);

  if (fetchHasDisableAclPermissionResult?.data?.hasDisableAclPermission === false) {
    history.push(`/app/${loginUser?.organizationId}/acl`);
    return <></>;
  }

  return (
    <View style={{ paddingBottom: 50 }}>
      <Header>
        <Image
          source={{ uri: logoLargeBlack }}
          resizeMode="contain"
          style={{
            width: 300,
            height: 100,
          }}
        />
      </Header>
      <Container>
        <LeftContainer>
          <Image
            source={{ uri: loginImage }}
            resizeMode="contain"
            style={{
              width: Math.max(width / 4, 500),
              height: Math.max(width / 4, 500),
            }}
          />
        </LeftContainer>
        <RightContainer>
          <RightContainerInner>
            <Typography
              variant={TypographyType.Title}
              style={{ fontSize: 20, marginVertical: 30, textAlign: 'center' }}>
              {`IPアドレス制限を解除する`}
            </Typography>
            <Typography
              variant={TypographyType.Title}
              style={{ fontSize: 14, textAlign: 'center' }}>
              {`あなたは管理者権限ユーザーですので、${'\n'}IPアドレス制限を解除することが出来ます。${'\n'}解除したい場合、以下の解除ボタンをクリックしてください。`}
            </Typography>
            <Button
              text="IPアドレス制限を解除する"
              style={{ marginTop: 10, backgroundColor: themeContext.colors.error }}
              onPress={() => setShowModal(true)}
            />
          </RightContainerInner>
        </RightContainer>
      </Container>
      <DisableAclModal
        token={jwtToken}
        isShow={isShowModal}
        onClose={() => {
          setShowModal(false);
        }}
      />
    </View>
  );
};

export default React.memo(AccessDenyResetPage);
