import React, { useContext, useEffect, useState } from 'react';
import { TextStyle, View } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../../theme';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import DateUtil from '../../../../../util/DateUtil';
import TimeUtil from '../../../../../util/TimeUtil';
import Avatar from '../../../../presentational/atoms/avatar';
import {
  LatestWorkingHistoryDocument,
  Member,
  Organization,
  Plan,
  Priority,
  Project,
  ProjectContract,
  ProjectContractType,
  ProjectWorkingTimeSecDocument,
  Task,
  TaskDocument,
  TaskWorkingHistorySummaryGroupByAssineeDocument,
  useCancelFavoriteProjectMutation,
  useCancelFavoriteTaskMutation,
  useCompleteTaskMutation,
  useFavoriteProjectMutation,
  useFavoriteTaskMutation,
  useIncompleteTaskMutation,
  useStartTaskMutation,
  useStopTaskMutation,
} from '../../../../../graphql/api/API';
import TaskUtil from '../../../../../util/TaskUtil';
import useInterval from 'use-interval';
import when from '../../../../../lang-extention/When';
import ColorUtil from '../../../../../util/ColorUtil';
import { TaskProgressBar } from '../task-progress-bar';
import useDimensions from 'use-dimensions';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import PlayIcon from '../../../../presentational/molecules/image-icon/play';
import StopIcon from '../../../../presentational/molecules/image-icon/stop';
import CheckIcon from '../../../../presentational/molecules/image-icon/check';
import StarIcon from '../../../../presentational/molecules/image-icon/star';
import ErrorMessageModal from '../error-message-modal';
import OrganizationUtil from '../../../../../util/OrganizationUtil';

interface IContainerProps extends IStyleTheme {
  isComplete: boolean;
  isTracking: boolean;
}

const Container = styled.View<IContainerProps>`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 6px;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.4s;
  z-index: 100;
  border-width: 1px;
  border-color: ${(props: IContainerProps) => props.theme.colors.separator};
  background-color: ${(props: IContainerProps) => props.theme.colors.baseColor};
`;

interface IMainProps extends IStyleTheme {
  showClientName: boolean;
}

const Main = styled.View<IMainProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  padding-bottom: 3px;
  overflow: hidden;
  min-height: 45px;
`;

interface IProjectWorkingTimeProps {
  project: Project;
  style?: TextStyle;
}

export const ProjectWorkingTime = (props: IProjectWorkingTimeProps) => {
  return (
    <Typography variant={TypographyType.Normal} style={props.style}>
      {TimeUtil.formatForTask(props.project.workingTimeSec)}
    </Typography>
  );
};

interface IProps {
  projectContract: ProjectContract;
  organization: Organization;
  me: Member;
  showTeamName: boolean;
  showClientName?: boolean;
  showFavorite?: boolean;
  showAssigner?: boolean;
}

const ProjectSummary = (props: IProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const { window } = useDimensions();
  const windowWidth = window.width;
  const [loginUser, _] = useContext(LoginUserContext);
  const [showErrorModal, setShowErrorModal] = useState(false);

  return (
    <Container>
      <Main showClientName={props.showClientName}>
        <View style={{ flexDirection: 'row', flex: 1, minWidth: 67, alignItems: 'center' }}>
          {props.showTeamName && windowWidth >= 1550 && (
            <View
              style={{
                maxWidth: 140,
                minWidth: 140,
                marginRight: 10,
                justifyContent: 'flex-start',
              }}>
              <Typography
                variant={TypographyType.Normal}
                ellipsis={true}
                style={{
                  fontSize: 12,
                  paddingHorizontal: 5,
                  textAlign: 'left',
                }}>
                {props.projectContract.project!.team.name}
              </Typography>
            </View>
          )}
          <View
            style={{
              flex: 1,
              marginRight: 10,
              justifyContent: 'flex-start',
            }}>
            <View style={{ flexDirection: 'column' }}>
              {props.projectContract.project!.client && (
                <View>
                  <Typography
                    variant={TypographyType.Description}
                    ellipsis={true}
                    style={{
                      fontSize: 11,
                      minWidth: 30,
                      textAlign: 'left',
                    }}>
                    {props.projectContract.project!.client.name}
                  </Typography>
                </View>
              )}
              <View>
                <Typography
                  variant={TypographyType.Normal}
                  ellipsis={true}
                  tooltipWhenEllipsis={true}
                  tooltipId={`project-contract-${props.projectContract.id}`}
                  style={
                    [
                      {
                        fontSize: 14,
                        minWidth: 30,
                        textAlign: 'left',
                      },
                    ] as any
                  }>
                  {props.projectContract.project!.name}
                </Typography>
              </View>
            </View>
          </View>
        </View>
        <View style={{ flexDirection: 'row' }}>
        {windowWidth > 1700 && (
          <View
            style={{
              maxWidth: 100,
              minWidth: 100,
              marginRight: 10,
              justifyContent: 'center',
            }}>
            <Typography
              variant={TypographyType.Normal}
              ellipsis={true}
              tooltipWhenEllipsis={true}
              tooltipId={`key-${props.projectContract!.id}`}
              style={{
                fontSize: 14,
                minWidth: 30,
                textAlign: 'left',
              }}>
              {props.projectContract.project!.key ?? '-'}
            </Typography>
          </View>
        )}
          {windowWidth > 1600 && (
          <View
            style={{
              maxWidth: 140,
              minWidth: 140,
              marginRight: 10,
              justifyContent: 'center',
            }}>
            <Typography
              variant={TypographyType.Normal}
              ellipsis={true}
              tooltipWhenEllipsis={true}
              tooltipId={`client-${props.projectContract!.id}`}
              style={{
                fontSize: 14,
                minWidth: 30,
                textAlign: 'left',
              }}>
              {props.projectContract.project!.client?.name ?? '-'}
            </Typography>
          </View>
          )}
         
         {windowWidth > 200 && (
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}>
            <Typography
              variant={TypographyType.Normal}
              style={{ fontSize: 12, paddingHorizontal: 5, width: 100, textAlign: 'center' }}>
              {props.projectContract.year ?  `${props.projectContract.year}年${props.projectContract.month}月` : 'なし'}
            </Typography>
          </View>
            )}
          {windowWidth > 2000 && (
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}>
            <Typography
              variant={TypographyType.Normal}
              style={{ fontSize: 12, paddingHorizontal: 5, width: 100, textAlign: 'center' }}>
              {when(props.projectContract.contractType)
                .on(
                  (v) => v === ProjectContractType.FixAmount,
                  () => '固定料金'
                )
                .on(
                  (v) => v === ProjectContractType.MeteredRate,
                  () => '従量制料金'
                )
                .on(
                  (v) => v === ProjectContractType.SettlementRange,
                  () => '精算幅付き料金'
                )
                .otherwise(() => '-')}
            </Typography>
          </View>
            )}
          {windowWidth > 2000 && (
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}>
            <Typography
              variant={TypographyType.Normal}
              style={{ fontSize: 12, paddingHorizontal: 5, width: 80, textAlign: 'center' }}>
              {`${props.projectContract.taxRate}%`}
            </Typography>
          </View>
           )}
          {windowWidth > 200 && (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <Typography
                variant={TypographyType.Normal}
                style={{ fontSize: 12, paddingHorizontal: 5, width: 120, textAlign: 'center' }}>
                {props.projectContract!.salesAmountWithoutTax
                  ? `${new Intl.NumberFormat('ja-JP', {
                      style: 'currency',
                      currency: 'JPY',
                    }).format(props.projectContract!.salesAmountWithoutTax ?? 0)}`
                  : '-'}
              </Typography>
            </View>
          )}
           {OrganizationUtil.isTeamPlan(props.organization) && windowWidth > 200 && (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <Typography
                variant={TypographyType.Normal}
                style={{ fontSize: 12, paddingHorizontal: 5, width: 120, textAlign: 'center' }}>
                {props.projectContract!.costBudgetWithoutTax
                  ? `${new Intl.NumberFormat('ja-JP', {
                      style: 'currency',
                      currency: 'JPY',
                    }).format(props.projectContract!.costBudgetWithoutTax ?? 0)}`
                  : '-'}
              </Typography>
            </View>
          )}
          {OrganizationUtil.isTeamPlan(props.organization) && windowWidth > 200 && (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <Typography
                variant={TypographyType.Normal}
                style={[
                  { fontSize: 12, paddingHorizontal: 5, width: 120, textAlign: 'center'},
                  (!!props.projectContract!.costBudgetWithoutTax && !!props.projectContract!.laborCostWithoutTax && (props.projectContract!.laborCostWithoutTax ?? 0) > (props.projectContract!.costBudgetWithoutTax ?? 0)) ? {color:  themeContext.colors.error, fontWeight: '600' } : {}
                ]}>
                {props.projectContract!.laborCostWithoutTax
                  ? `${new Intl.NumberFormat('ja-JP', {
                      style: 'currency',
                      currency: 'JPY',
                    }).format(props.projectContract!.laborCostWithoutTax ?? 0)}`
                  : '-'}
              </Typography>
            </View>
          )}
          {OrganizationUtil.isTeamPlan(props.organization) && windowWidth > 1500 && (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <Typography
                variant={TypographyType.Normal}
                style={{ fontSize: 12, paddingHorizontal: 5, width: 120, textAlign: 'center' }}>
                 {new Intl.NumberFormat('ja-JP', {
                    style: 'currency',
                    currency: 'JPY',
                  }).format(props.projectContract.grossProfit ?? '-')}
              </Typography>
            </View>
          )}
          {OrganizationUtil.isTeamPlan(props.organization) && windowWidth > 2000 && (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <Typography
                variant={TypographyType.Normal}
                style={{ fontSize: 12, paddingHorizontal: 5, width: 80, textAlign: 'center' }}>
                {props.projectContract.grossProfitRatePercent
                    ? `${props.projectContract.grossProfitRatePercent}%`
                    : '-'}
              </Typography>
            </View>
          )}
          {OrganizationUtil.isTeamPlan(props.organization) && windowWidth > 2000 && (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <Typography
                variant={TypographyType.Normal}
                style={{ fontSize: 12, paddingHorizontal: 5, width: 120, textAlign: 'center' }}>
                {props.projectContract.laborShare
                    ? `${props.projectContract.laborShare}%`
                    : '-'}
              </Typography>
            </View>
          )}
           <View
            style={{
              flexDirection: 'row',
              width: 100,
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Typography variant={TypographyType.Normal}  style={{ fontSize: 12, minWidth: 50, marginRight: 5, textAlign: 'center' }}>
              {TimeUtil.formatForTask(props.projectContract.totalWorkingTimeSec)}
            </Typography> 
          </View>
        </View>
      </Main>
      <ErrorMessageModal
        showModal={showErrorModal}
        title={'プロジェクトを更新できません'}
        message={`プロジェクトを更新する権限がありません${'\n'}権限が必要な場合、管理権限を持っているメンバーに問い合わせてください`}
        onCloseModal={() => setShowErrorModal(false)}
      />
    </Container>
  );
};

export default React.memo(ProjectSummary);
