import React, { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import { IRouteItem } from '../../../../../routes/RouteItem';
import { IStyleTheme, IThemePart } from '../../../../theme';
import { prepareAppRoutesDefine } from '../../../../../routes/web/app';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import { useMeQuery } from '../../../../../graphql/api/API';

const Container = styled.View`
  display: flex;
  flex: 1;
  height: calc(100vh - 57px);
  padding: 0;
  margin: 0;
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
  z-index: 0;
`;

const AppMainContainer = () => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, _] = useContext(LoginUserContext);
  const { loading, data } = useMeQuery();
  if (loading || !data?.me) {
    return <></>;
  }

  return (
    <Container>
      <Switch>
        <>
          {prepareAppRoutesDefine(data!.me!).map((route: IRouteItem, key: number) => {
            return <Route path={route.path as any} component={route.component} key={key} />;
          })}
        </>
      </Switch>
    </Container>
  );
};

export default React.memo(AppMainContainer);
