import React, { ReactNode, useContext, useState } from 'react';
import { View, Text, StyleProp, ViewStyle } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../theme';
import Icon from '../../atoms/icon';
import Typography, { TypographyType } from '../../atoms/typography';
import CustomScrollView from '../../atoms/custom-scroll-view';
import LeftIcon from '../image-icon/left';
import RightIcon from '../image-icon/right';

const Container = styled.View`
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
  display: flex;
  flex-direction: row;
`;

interface IHeaderProps extends IStyleTheme {
  isFirst: boolean;
  isLast: boolean;
}

const Header = styled.View<IHeaderProps>`
  background-color: ${(props: IStyleTheme) => props.theme.colors.subHeader};
  padding: 0 10px;
  height: 30px;
  box-shadow: 0 5px #999;
  shadow-opacity: 0.1;
  shadow-radius: 0;
  transition: all 0.4s;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  border-left-width: ${(props: IHeaderProps) => (props.isFirst ? '1px' : '0')};
  border-right-width: ${(props: IHeaderProps) => (props.isLast ? '1px' : '0')};
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.subHeader};
`;

interface IFooterProps extends IStyleTheme {
  isFirst: boolean;
  isLast: boolean;
}

const Footer = styled.View<IFooterProps>`
  background-color: ${(props: IStyleTheme) => props.theme.colors.subHeader};
  padding: 0 10px;
  height: 30px;
  box-shadow: 0 5px #999;
  shadow-opacity: 0.1;
  shadow-radius: 0;
  transition: all 0.4s;
  z-index: 100;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: ${(props: IFooterProps) => (props.isFirst ? '1px' : '0')};
  border-right-width: ${(props: IFooterProps) => (props.isLast ? '1px' : '0')};
  border-color: ${(props: IStyleTheme) => props.theme.colors.subHeader};
`;

const NonDisplayFooter = styled.View<IFooterProps>`
  box-shadow: 0 5px #999;
  shadow-opacity: 0.1;
  shadow-radius: 0;
  transition: all 0.4s;
  z-index: 100;
  border-bottom-width: 1px;
  border-left-width: ${(props: IFooterProps) => (props.isFirst ? '1px' : '0')};
  border-right-width: ${(props: IFooterProps) => (props.isLast ? '1px' : '0')};
  border-color: ${(props: IStyleTheme) => props.theme.colors.subHeader};
`;

interface IColumnProps extends IStyleTheme {
  isFirst: boolean;
  isLast: boolean;
}

const Column = styled.View<IColumnProps>`
  display: flex;
  flex-direction: column;
  border-left-width: ${(props: IColumnProps) => (props.isFirst ? '1px' : '0')};
  border-right-width: ${(props: IColumnProps) => (props.isLast ? '1px' : '0')};
  border-color: ${(props: IStyleTheme) => props.theme.colors.subHeader};
`;

const CellTouchable = styled.TouchableOpacity`
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
  padding: 5px 10px;
  justify-content: center;
`;

const Cell = styled.View`
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
  padding: 5px 10px;
  justify-content: center;
`;

interface IProps {
  rowData: Object[];
  rowDataStyle?: Object;
  style?: StyleProp<ViewStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  height?: number;
  onPress?: (rowData: any) => void;
  filter?: string[];
  hideFooter?: boolean;
  title?: string;
  rightHeaderComponent?: React.ReactElement;
  scrollEnabled?: boolean;
  pageSize?: number;
}

const Table = (props: IProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(props.pageSize ?? 10);
  const hasNextPage = props.rowData.length > pageNo * pageSize;
  const hasPrevPage = pageNo > 1;
  const headers =
    props.rowData.length > 0
      ? props.filter
        ? Object.keys(props.rowData[0])
            .filter((key) => key !== 'rowStyle')
            .filter((key) => props.filter!.indexOf(key) !== -1)
        : Object.keys(props.rowData[0]).filter((key) => key !== 'rowStyle')
      : props.filter || [];
  return (
    <>
      <CustomScrollView
        scrollEnabled={props.scrollEnabled !== undefined ? props.scrollEnabled : true}
        style={[{ width: '100%' }, props.style as any]}>
        <Container style={(props.containerStyle, { overflowX: 'scroll' })}>
          <View style={{ flexDirection: 'column' }}>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              {props.title && (
                <Typography
                  variant={TypographyType.SubTitle}
                  style={{ textAlign: 'left', fontSize: 14, fontWeight: '600', marginRight: 20 }}>
                  {props.title}
                </Typography>
              )}
              {props.rightHeaderComponent}
            </View>
            <View style={{ flexDirection: 'row' }}>
              {headers.map((header, i) => {
                const customStyle = props.rowDataStyle ? (props.rowDataStyle as any)[header] : null;
                return (
                  <Column
                    isFirst={i === 0}
                    isLast={i === headers.length - 1}
                    key={i}
                    style={customStyle || { flex: 1 }}>
                    <Header isFirst={i === 0} isLast={i === headers.length - 1}>
                      <Typography
                        variant={TypographyType.Normal}
                        style={{ color: '#FFFFFF', textAlign: 'left', fontSize: 14 }}>
                        {header}
                      </Typography>
                    </Header>
                    {props.rowData.length === 0 ? (
                      <>
                        {i === 0 ? (
                          <Typography
                            variant={TypographyType.Normal}
                            style={{
                              color: themeContext.colors.description,
                              textAlign: 'left',
                              fontSize: 14,
                              marginLeft: 10,
                            }}>
                            データがありません
                          </Typography>
                        ) : (
                          <Typography
                            variant={TypographyType.Normal}
                            style={{
                              color: themeContext.colors.description,
                              textAlign: 'left',
                              fontSize: 14,
                            }}>
                            {' '}
                          </Typography>
                        )}
                      </>
                    ) : (
                      <>
                        {props.rowData
                          .filter(
                            (data, i) => (pageNo - 1) * pageSize <= i && pageNo * pageSize > i
                          )
                          .map((data, j) => {
                            return props.onPress ? (
                              <CellTouchable
                                onPress={() => {
                                  if (props.onPress) {
                                    props.onPress(data);
                                  }
                                }}
                                style={[
                                  { height: props.height, maxWidth: 300 },
                                  (data as any).rowStyle || {},
                                ]}
                                key={j}>
                                {typeof (data as any)[header] === 'function' ? (
                                  (data as any)[header]()
                                ) : (
                                  <Typography
                                    variant={TypographyType.Normal}
                                    style={{ fontSize: 13, maxWidth: 300 }}
                                    // tooltipId={`cell-${i}-${j}`}
                                    // tooltipWhenEllipsis
                                    ellipsis={true}>
                                    {(data as any)[header]}
                                  </Typography>
                                )}
                              </CellTouchable>
                            ) : (
                              <Cell
                                style={[
                                  { height: props.height, maxWidth: 300 },
                                  (data as any).rowStyle || {},
                                ]}
                                key={j}>
                                {typeof (data as any)[header] === 'function' ? (
                                  (data as any)[header]()
                                ) : (
                                  <Typography
                                    variant={TypographyType.Normal}
                                    style={{ fontSize: 13, maxWidth: 300 }}
                                    // tooltipId={`cell-${i}-${j}`}
                                    // tooltipWhenEllipsis
                                    ellipsis={true}>
                                    {(data as any)[header]}
                                  </Typography>
                                )}
                              </Cell>
                            );
                          })}
                      </>
                    )}
                    {props.hideFooter === true ? (
                      <NonDisplayFooter />
                    ) : (
                      <Footer isFirst={i === 0} isLast={i === headers.length - 1}>
                        {headers.length - 1 === i ? (
                          <>
                            <LeftIcon
                              size={30}
                              reverse={true}
                              containerStyle={{ opacity: hasPrevPage ? 1 : 0.1 }}
                              onPress={() => setPageNo(Math.max(pageNo - 1, 1))}
                            />
                            <Typography
                              variant={TypographyType.Normal}
                              style={{
                                color: '#FFFFFF',
                                textAlign: 'center',
                                marginHorizontal: 0,
                              }}>
                              {pageNo}
                            </Typography>
                            <RightIcon
                              size={30}
                              reverse={true}
                              containerStyle={{ opacity: hasNextPage ? 1 : 0.1 }}
                              onPress={() =>
                                setPageNo(
                                  Math.min(pageNo + 1, Math.ceil(props.rowData.length / pageSize))
                                )
                              }
                            />
                          </>
                        ) : null}
                      </Footer>
                    )}
                  </Column>
                );
              })}
            </View>
          </View>
        </Container>
      </CustomScrollView>
    </>
  );
};

export default Table;
