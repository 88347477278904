import React, { useCallback, useContext, useEffect, useState } from 'react';
import { View } from 'react-native';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../theme';
import Typography, { TypographyType } from '../../atoms/typography';
import zxcvbn from 'zxcvbn';

interface IProps {
  password: string;
}

const PasswordStrengthChecker = (props: IProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [strengthLevel, setStrengthLevel] = useState(0);

  useEffect(() => {
    setStrengthLevel(zxcvbn(props.password).score);
  }, [props.password]);

  const getColor = useCallback(
    (level) => {
      if (level === 0) {
        return '#fd4c4c';
      }
      if (level === 1) {
        return '#ff9e4f';
      }
      if (level === 2) {
        return '#ebf70f';
      }
      if (level === 3) {
        return '#72d95e';
      }
      return '#62f1e9';
    },
    [strengthLevel]
  );

  const getText = useCallback(
    (level) => {
      if (level === 0) {
        return '弱いパスワードです。数字や記号を入れた方が安全です。';
      }
      if (level === 1) {
        return '弱いパスワードです。数字や記号を入れた方が安全です。';
      }
      if (level === 2) {
        return 'やや弱いパスワードです。数字や記号を入れた方が安全です。';
      }
      if (level === 3) {
        return '問題ありません。数字や記号を入れるとより安心です。';
      }
      return '問題ない強度のパスワードです。';
    },
    [strengthLevel]
  );

  if (props.password.length === 0) {
    return <></>;
  }

  return (
    <>
      <View style={{ flexDirection: 'row', width: '80%' }}>
        {[...Array(strengthLevel + 1)].map((_, i) => (
          <View
            style={{
              height: 5,
              width: '24%',
              marginRight: '1%',
              backgroundColor: getColor(strengthLevel),
            }}
          />
        ))}
      </View>
      <Typography variant={TypographyType.Description} style={{ fontSize: 10 }}>
        {getText(strengthLevel)}
      </Typography>
    </>
  );
};

export default PasswordStrengthChecker;
