import moment from 'moment-timezone';
import React, { useContext, useState, createContext, useEffect, useRef } from 'react';
import { Animated, NativeSyntheticEvent, View } from 'react-native';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../../theme';
import Hamburger from '../../../../../../third-party-lib/react-native-hamburger';
import {
  prepareMyMenuAppRoutes,
  prepareTeamMenuAppRoutes,
  prepareOtherMenuAppRoutes,
  IRouteItemTeamMenu,
} from '../../../../../routes/web/app';
import { IRouteItem } from '../../../../../routes/RouteItem';
import SideMenuItem from '../side-menu-item';
import SideMenuInboxItem from '../side-menu-inbox-item';
import Separator from '../../../../presentational/atoms/separator';
import CustomScrollView from '../../../../presentational/atoms/custom-scroll-view';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import {
  TeamMemberRelation,
  useCloseAllTeamMenuMutation,
  useMeQuery,
  useOrganizationQuery,
  useTeamMemberRelationsQuery,
  useUpdateTeamMemberRelationMutation,
} from '../../../../../graphql/api/API';
import logoLargeWhite from '../../../../../base64Images/logo/logo-large-white';
import { NonForwardedRefAnimatedView } from '../../../../presentational/atoms/list2/virtualized-flat-list';
import {
  GestureHandlerStateChangeNativeEvent,
  PanGestureHandler,
  PanGestureHandlerEventPayload,
  PanGestureHandlerGestureEvent,
  State,
} from 'react-native-gesture-handler';
import Cookies from 'js-cookie';
import Icon from '../../../../presentational/atoms/icon';
import MenuIcon from '../../../../presentational/molecules/image-icon/menu';
import { useHotkeys } from 'react-hotkeys-hook';
import CloseIcon from '../../../../presentational/molecules/image-icon/close';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';

const Container = styled.View`
  height: 100vh;
  padding-top: 10px;
  background-color: #2b2a34;
  box-shadow: 5px 0px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.4s;
  z-index: 10;
`;

const Menu = styled.View`
  position: absolute;
  top: 20px;
  right: 0;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
  border-width: 1px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 160px;
`;

interface IHeaderProps extends IStyleTheme {
  isOpen: boolean;
}

const Header = styled.View<IHeaderProps>`
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  align-item: center;
  justify-content: ${(props: IHeaderProps) => (props.isOpen ? 'space-between' : 'center')};
  transition: all 0.4s;
`;

interface ILogoProps extends IStyleTheme {
  isOpen: boolean;
  isSideMenuWidthOver200: boolean;
}

const Logo = styled.Image<ILogoProps>`
  width: ${(props: ILogoProps) =>
    props.isOpen ? (props.isSideMenuWidthOver200 ? '200px' : 'calc(100% - 30px)') : '0'};
  height: 50px;
  opacity: ${(props: ILogoProps) => (props.isOpen ? '1' : '0')};
  transition: all 0.4s;
`;

interface ITeamItemProps {
  route: IRouteItem;
  showOnlyIcon: boolean;
  isDraggingAnyTeam: boolean;
  setDraggingAnyTeam: (value: boolean) => void;
  teamMemberRelation: TeamMemberRelation;
  rowIndex: number;
  allTeamMemberRelations: TeamMemberRelation[];
}

const TeamItem = (props: ITeamItemProps) => {
  const height = 44;
  const [globalDragInfo, setGlobalDragInfo] = useContext(GlobalDragContext);
  const [isDragging, setDragging] = useState(false);
  const [translationY, setTranslationY] = useState(new Animated.Value(0));
  const [updateTeamMemberRelation] = useUpdateTeamMemberRelationMutation({
    variables: {
      id: props.teamMemberRelation.id!,
      input: {
        sortNo: props.teamMemberRelation.sortNo,
        openMenu: props.teamMemberRelation.openMenu,
        versionNo: props.teamMemberRelation.versionNo,
      },
    },
  });

  useEffect(() => {
    if (!isDragging) {
      if ((globalDragInfo as GlobalDragInfo).dragging) {
        if (
          (globalDragInfo as GlobalDragInfo).endIndex >= props.rowIndex &&
          (globalDragInfo as GlobalDragInfo).startIndex < props.rowIndex
        ) {
          Animated.timing(translationY, {
            toValue: -height,
            duration: 200,
            useNativeDriver: true,
          }).start();
        } else if (
          (globalDragInfo as GlobalDragInfo).endIndex <= props.rowIndex &&
          (globalDragInfo as GlobalDragInfo).startIndex < props.rowIndex
        ) {
          Animated.timing(translationY, {
            toValue: 0,
            duration: 200,
            useNativeDriver: true,
          }).start();
        }
        if (
          (globalDragInfo as GlobalDragInfo).endIndex <= props.rowIndex &&
          (globalDragInfo as GlobalDragInfo).startIndex > props.rowIndex
        ) {
          Animated.timing(translationY, {
            toValue: height,
            duration: 200,
            useNativeDriver: true,
          }).start();
        } else if (
          (globalDragInfo as GlobalDragInfo).endIndex > props.rowIndex &&
          (globalDragInfo as GlobalDragInfo).startIndex > props.rowIndex
        ) {
          Animated.timing(translationY, {
            toValue: 0,
            duration: 200,
            useNativeDriver: true,
          }).start();
        }
      } else {
        Animated.timing(translationY, {
          toValue: 0,
          duration: 0,
          useNativeDriver: true,
        }).start();
      }
    }
  }, [globalDragInfo]);

  return (
    <PanGestureHandler
      maxPointers={1}
      onGestureEvent={Animated.event([{ nativeEvent: { translationY: translationY } }], {
        listener: async (
          event: NativeSyntheticEvent<
            PanGestureHandlerEventPayload & GestureHandlerStateChangeNativeEvent
          >
        ) => {
          if (event.nativeEvent.state === State.BEGAN) {
            setDragging(true);
            props.setDraggingAnyTeam(true);
            translationY.setValue(0);
            //@ts-ignore
            setGlobalDragInfo({
              startIndex: 1,
              endIndex: 1,
              dragging: true,
            });
          }
          if (event.nativeEvent.state === State.ACTIVE) {
            const moveIndex = Math.floor(event.nativeEvent.translationY / height);
            const afterIndex = props.rowIndex + moveIndex;
            if ((globalDragInfo as GlobalDragInfo).endIndex != afterIndex) {
              //@ts-ignore
              setGlobalDragInfo({
                startIndex: props.rowIndex,
                endIndex: afterIndex,
                dragging: true,
              });
            }
          }
          if (event.nativeEvent.state === State.END) {
            const moveIndex = Math.floor(event.nativeEvent.translationY / height);
            let afterIndex = props.rowIndex + moveIndex;
            afterIndex = Math.max(afterIndex, 0);
            afterIndex = Math.min(afterIndex, props.allTeamMemberRelations.length - 1);

            if (props.rowIndex != afterIndex) {
              const isMoveToFirst = afterIndex === 0;
              const isMoveToLast = afterIndex === props.allTeamMemberRelations.length - 1;
              const isMoveToDown = afterIndex - props.rowIndex > 0;

              let sortNo;
              if (isMoveToFirst) {
                sortNo = new Date().getTime();
              } else if (isMoveToLast) {
                sortNo = props.allTeamMemberRelations[afterIndex].sortNo - 1000;
              } else {
                if (isMoveToDown) {
                  const beforeTeamMemberRelation = props.allTeamMemberRelations[afterIndex];
                  const afterTeamMemberRelation = props.allTeamMemberRelations[afterIndex + 1];
                  sortNo = Math.floor(
                    (beforeTeamMemberRelation!.sortNo + afterTeamMemberRelation!.sortNo) / 2
                  );
                } else {
                  const beforeTeamMemberRelation = props.allTeamMemberRelations[afterIndex - 1];
                  const afterTeamMemberRelation = props.allTeamMemberRelations[afterIndex];
                  sortNo = Math.floor(
                    (beforeTeamMemberRelation!.sortNo + afterTeamMemberRelation!.sortNo) / 2
                  );
                }
              }
              await updateTeamMemberRelation({
                variables: {
                  id: props.teamMemberRelation.id!,
                  input: {
                    sortNo: sortNo,
                    openMenu: props.teamMemberRelation.openMenu,
                    versionNo: props.teamMemberRelation.versionNo,
                  },
                },
                optimisticResponse: {
                  __typename: 'Mutation',
                  updateTeamMemberRelation: Object.assign(
                    {
                      __typename: 'TeamMemberRelation',
                    },
                    props.teamMemberRelation,
                    { sortNo: sortNo }
                  ),
                },
              });
            }

            //@ts-ignore
            setGlobalDragInfo({
              startIndex: props.rowIndex,
              endIndex: afterIndex,
              dragging: false,
            });
            setTimeout(() => {
              setDragging(false);
              props.setDraggingAnyTeam(false);
            }, 300);
            translationY.setValue(0);
          }
        },
        useNativeDriver: true,
      })}>
      <NonForwardedRefAnimatedView>
        <Animated.View
          style={
            {
              transform: [
                {
                  translateY: translationY,
                },
              ],
            } as any
          }>
          <SideMenuItem
            route={props.route}
            showOnlyIcon={props.showOnlyIcon}
            isTeam={true}
            forceCloseSubMenu={isDragging || props.isDraggingAnyTeam}
            openMenu={props.teamMemberRelation.openMenu}
            onOpenSubMenu={() => {
              updateTeamMemberRelation({
                variables: {
                  id: props.teamMemberRelation.id!,
                  input: {
                    sortNo: props.teamMemberRelation.sortNo,
                    openMenu: true,
                    versionNo: props.teamMemberRelation.versionNo,
                  },
                },
              });
            }}
            onCloseSubMenu={() => {
              updateTeamMemberRelation({
                variables: {
                  id: props.teamMemberRelation.id!,
                  input: {
                    sortNo: props.teamMemberRelation.sortNo,
                    openMenu: false,
                    versionNo: props.teamMemberRelation.versionNo,
                  },
                },
              });
            }}
          />
        </Animated.View>
      </NonForwardedRefAnimatedView>
    </PanGestureHandler>
  );
};

const HamburgerContainer = styled.View`
  height: 50px;
  padding: 8px 3px;
  cursor: pointer;
  padding-top: 14px;
`;

const SideMenu = () => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const menuRef = useRef();
  const [isOpen, setOpen] = useState(
    Cookies.get('IS_SIDE_MENU_OPEN') !== null && Cookies.get('IS_SIDE_MENU_OPEN') !== undefined
      ? Cookies.get('IS_SIDE_MENU_OPEN') === 'true'
      : true
  );
  const [isDraggingAnyTeam, setDraggingAnyTeam] = useState(false);
  const [sideMenuWidth, setSideMenuWidth] = useState(
    Cookies.get('SIDE_MENU_WIDTH')
      ? Math.max(50, Math.min(800, Number(Cookies.get('SIDE_MENU_WIDTH'))))
      : 200
  );
  const [sideMenuWidthTranslation, setSideMenuWidthTranslation] = useState(
    new Animated.Value(sideMenuWidth)
  );
  const [openMenu, setOpenMenu] = useState(false);
  useHotkeys('Esc', () => setOpenMenu(false));
  const clickDocument = (e: any) => {
    if ((menuRef?.current as any)?.contains(e.target)) {
      (menuRef?.current as any)?.click();
      return;
    }
    setOpenMenu(false);
  };
  useEffect(() => {
    window.addEventListener('click', clickDocument);
    return () => {
      window.removeEventListener('click', clickDocument);
    };
  }, [clickDocument]);

  const { loading, data } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId,
    },
  });

  const fetchTeams = useTeamMemberRelationsQuery({
    fetchPolicy: 'network-only',
  });
  const { loading: meLoading, data: meData } = useMeQuery();

  const [closeAllTeam] = useCloseAllTeamMenuMutation();

  if (loading || fetchTeams.loading || meLoading || !data?.organization) {
    return <></>;
  }

  const teamMemberRelations = ((fetchTeams.data?.teamMemberRelations as TeamMemberRelation[]) || [])
    .slice()
    .sort((a, b) => {
      return b!.sortNo - a!.sortNo; // ソート番号は降順
    });

  const teamMenu = prepareTeamMenuAppRoutes(
    data!.organization!,
    teamMemberRelations,
    loginUser!,
    meData!.me!
  ).filter((menu) => menu.menuInvisible !== true);

  return (
    <Animated.View
      style={{
        width: isOpen ? sideMenuWidthTranslation : 64,
        minWidth: 64,
        maxWidth: 800,
        flexDirection: 'row',
      }}>
      <View style={{ flexDirection: 'column', width: 'calc(100% - 3px)' }}>
        <Container>
          <Header isOpen={isOpen}>
            <Logo
              isOpen={isOpen}
              isSideMenuWidthOver200={sideMenuWidth > 240}
              source={{ uri: logoLargeWhite }}
              resizeMode="contain"
            />
            <HamburgerContainer>
              <Hamburger
                active={isOpen}
                color={'#555'}
                onPress={() => {
                  setOpen(!isOpen);
                  Cookies.set('IS_SIDE_MENU_OPEN', `${!isOpen}`, {
                    expires: moment().add(10, 'years').toDate(),
                  });
                }}
              />
            </HamburgerContainer>
          </Header>
          <CustomScrollView>
            <>
              {prepareMyMenuAppRoutes(data!.organization!, loginUser!, meData!.me!)
                .filter((menu) => menu.menuInvisible !== true)
                .map((route: IRouteItem, key: number) => {
                  if (route.id === 'separator') {
                    return (
                      <Separator
                        lineStyle={{ backgroundColor: '#AAAAAA', opacity: 0.5, marginVertical: 6 }}
                        outerStyle={{ paddingHorizontal: 10 }}
                        key={key}
                      />
                    );
                  }
                  if (route.id === 'inbox') {
                    return <SideMenuInboxItem route={route} showOnlyIcon={!isOpen} key={key} />;
                  }
                  return (
                    <SideMenuItem
                      route={route}
                      showOnlyIcon={!isOpen}
                      openMenu={route.initialOpen || false}
                      key={key}
                    />
                  );
                })}

              {teamMenu.filter((menu) => menu.id !== 'separator').length >= 3 && (
                <div style={{ height: 14, zIndex: 3 }}>
                  <MenuIcon
                    size={16}
                    iconStyle={{ position: 'absolute', right: 15, top: 0 }}
                    reverse={true}
                    onPress={() => setOpenMenu(!openMenu)}>
                    {openMenu && (
                      <Menu ref={menuRef as any}>
                        <CloseIcon
                          size={16}
                          containerStyle={{ marginRight: 10 }}
                          onPress={() => {
                            setOpenMenu(false);
                            closeAllTeam();
                          }}>
                          <Typography variant={TypographyType.SubTitle} style={{ fontSize: 12 }}>
                            全てのチームを閉じる
                          </Typography>
                        </CloseIcon>
                      </Menu>
                    )}
                  </MenuIcon>
                </div>
              )}
              <GlobalDragContextProvider>
                {teamMenu.map((route: IRouteItemTeamMenu | IRouteItem, index: number) => {
                  if (route.id === 'separator') {
                    return (
                      <Separator
                        lineStyle={{
                          backgroundColor: '#AAAAAA',
                          opacity: 0.5,
                          marginVertical: 6,
                        }}
                        outerStyle={{ paddingHorizontal: 10 }}
                        key={index}
                      />
                    );
                  }
                  if (route.id === 'inbox') {
                    return <SideMenuInboxItem route={route} showOnlyIcon={!isOpen} key={index} />;
                  }
                  return (
                    <TeamItem
                      route={route}
                      showOnlyIcon={!isOpen}
                      isDraggingAnyTeam={isDraggingAnyTeam}
                      setDraggingAnyTeam={setDraggingAnyTeam}
                      teamMemberRelation={(route as IRouteItemTeamMenu).teamMemberRelation}
                      rowIndex={index}
                      allTeamMemberRelations={teamMemberRelations}
                      key={index}
                    />
                  );
                })}
              </GlobalDragContextProvider>

              {prepareOtherMenuAppRoutes(data!.organization!, meData!.me!)
                .filter((menu) => menu.menuInvisible !== true)
                .map((route: IRouteItem, key: number) => {
                  if (route.id === 'separator') {
                    return (
                      <Separator
                        lineStyle={{ backgroundColor: '#AAAAAA', opacity: 0.5, marginVertical: 6 }}
                        outerStyle={{ paddingHorizontal: 10 }}
                        key={key}
                      />
                    );
                  }
                  if (route.id === 'inbox') {
                    return <SideMenuInboxItem route={route} showOnlyIcon={!isOpen} key={key} />;
                  }
                  return (
                    <SideMenuItem
                      route={route}
                      showOnlyIcon={!isOpen}
                      openMenu={route.initialOpen || false}
                      key={key}
                    />
                  );
                })}
            </>
          </CustomScrollView>
        </Container>
      </View>
      {isOpen && (
        <PanGestureHandler
          maxPointers={1}
          onGestureEvent={Animated.event([], {
            listener: (event: PanGestureHandlerGestureEvent) => {
              if (event.nativeEvent.state === State.BEGAN) {
              }
              if (event.nativeEvent.state === State.ACTIVE) {
                sideMenuWidthTranslation.setValue(sideMenuWidth + event.nativeEvent.translationX);
              }
              if (event.nativeEvent.state === State.END) {
                const value = sideMenuWidth + event.nativeEvent.translationX;
                setSideMenuWidth(value);
                Cookies.set('SIDE_MENU_WIDTH', value.toString(), {
                  expires: moment().add(10, 'years').toDate(),
                });
              }
            },
            useNativeDriver: true,
          })}>
          <NonForwardedRefAnimatedView
            style={{
              width: 3,
              borderLeftWidth: 1,
              borderRightWidth: 1,
              borderColor: themeContext.colors.separator,
              cursor: 'col-resize',
            }}>
            <View style={{ width: 5, backgroundColor: 'red' }}></View>
          </NonForwardedRefAnimatedView>
        </PanGestureHandler>
      )}
    </Animated.View>
  );
};

export default React.memo(SideMenu);

interface GlobalDragInfo {
  startIndex: number;
  endIndex: number;
  dragging: boolean;
}

const GlobalDragContext = createContext([
  { startIndex: 0, endIndex: 0, startColumn: 0, endColumn: 0, dragging: false },
  (value: GlobalDragInfo) => {},
]);

const GlobalDragContextProvider = (props: any) => {
  const [draggingPosIndex, setDraggingPosIndex] = useState<GlobalDragInfo>({
    startIndex: 0,
    endIndex: 0,
    dragging: false,
  });
  return (
    <GlobalDragContext.Provider value={[draggingPosIndex as any, setDraggingPosIndex]}>
      {props.children}
    </GlobalDragContext.Provider>
  );
};
