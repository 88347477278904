import React, { useCallback, useContext, useState, useEffect } from 'react';
import { Text, View } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../../theme';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import Icon from '../../../../presentational/atoms/icon';
import DateUtil from '../../../../../util/DateUtil';
import TimeUtil from '../../../../../util/TimeUtil';
import Avatar from '../../../../presentational/atoms/avatar';
import {
  CalendarWorkingHistoryDocument,
  LatestWorkingHistoryDocument,
  Organization,
  Plan,
  ProjectWorkingTimeSecDocument,
  Task,
  TaskDocument,
  TaskWorkingHistorySummaryGroupByAssineeDocument,
  WorkingHistoryForSummaryDocument,
  useStartTaskMutation,
  useStopTaskMutation,
} from '../../../../../graphql/api/API';
import { TaskProgressBar } from '../task-progress-bar';
import { TaskWorkingTime } from '../task-summary';
import ColorUtil from '../../../../../util/ColorUtil';
import moment from 'moment-timezone';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import TaskUtil from '../../../../../util/TaskUtil';
import PlayIcon from '../../../../presentational/molecules/image-icon/play';
import StopIcon from '../../../../presentational/molecules/image-icon/stop';
import ErrorMessageModal from '../error-message-modal';

interface IContainerProps extends IStyleTheme {
  isComplete: boolean;
  isTracking: boolean;
}

const Container = styled.View<IContainerProps>`
  display: flex;
  flex-direction: column;
  margin: 3px 5px;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.4s;
  z-index: 100;
  border-width: 1px;
  border-color: ${(props: IContainerProps) => props.theme.colors.separator};
  background-color: ${(props: IContainerProps) =>
    props.isTracking
      ? '#FFFFF0'
      : props.isComplete
      ? ColorUtil.lignten(props.theme.colors.separator, 3)
      : props.theme.colors.baseColor};
`;

const Main = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 3px 10px 3px 5px;
`;

interface IProps {
  organization: Organization;
  teamId: string;
  task: Task;
}

const TaskSummaryForBoard = React.memo((props: IProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, _] = useContext(LoginUserContext);
  const [isTracking, setTracking] = useState(TaskUtil.isTracking(props.task, loginUser!));
  const [showCompleteTaskStartErrorModal, setShowCompleteTaskStartErrorModal] = useState(false);

  useEffect(() => {
    setTracking(TaskUtil.isTracking(props.task, loginUser!));
  }, [props.task.workingMembers]);

  const [startTask, startTaskResult] = useStartTaskMutation({
    update: (cache, result) => {
      // レスポンスの中のネストされたデータは、自動的にはキャッシュ更新されないので、自前でキャッシュ更新している
      cache.writeQuery({
        query: TaskDocument,
        variables: { id: result.data!.startTask!.startTask.id! },
        data: { task: result.data!.startTask!.startTask },
      });
      cache.writeQuery({
        query: LatestWorkingHistoryDocument,
        data: { latestWorkingHistory: result.data!.startTask!.startWorkingHistory },
      });
      if (result.data?.startTask?.stopTask) {
        cache.writeQuery({
          query: TaskDocument,
          variables: { id: result.data!.startTask!.stopTask!.id! },
          data: { task: result.data!.startTask!.stopTask },
        });
      }

      cache.modify({
        fields: {
          workingHistoriesSpecifyTermForCalendar(existing = [], { storeFieldName }) {
            const newWorkingHistories = cache.writeQuery({
              query: CalendarWorkingHistoryDocument,
              data: result.data?.startTask?.stopWorkingHistory,
            });
            const targetMemberData = existing.filter((d: any) => d.memberId === loginUser?.id)[0];
            if((targetMemberData?.length ?? 0) === 0){
              return [...existing, {
                memberId: loginUser?.id,
                workingHistories: newWorkingHistories
              }];
            }
            return existing.map((data: any) => {
              if(data.memberId !== loginUser?.id){
                return data;
              }
              return {
                memberId: data.memberId,
                workingHistories: [...targetMemberData.workingHistories, newWorkingHistories]
              }
            })
          },
        },
      });
    },
    onError: (error) => {
      setTracking(TaskUtil.isTracking(props.task, loginUser!));
    },
    // refetchQueries: [
    //   {
    //     query: TaskWorkingHistorySummaryGroupByAssineeDocument,
    //     variables: {
    //       teamId: props.teamId,
    //       taskId: props.task.id!,
    //     },
    //   },
    //   {
    //     query: TaskWorkingHistoriesDocument,
    //     variables: {
    //       taskId: props.task.id!,
    //     },
    //   },
    // ],
  });
  const [stopTask, stopTaskResult] = useStopTaskMutation({
    update: (cache, result) => {
      // レスポンスの中のネストされたデータは、自動的にはキャッシュ更新されないので、自前でキャッシュ更新している
      cache.writeQuery({
        query: TaskDocument,
        variables: { id: result.data!.stopTask!.stopTask.id! },
        data: { task: result.data!.stopTask!.stopTask },
      });
    },
    onError: (error) => {
      setTracking(TaskUtil.isTracking(props.task, loginUser!));
    },
    refetchQueries: [
      {
        query: TaskWorkingHistorySummaryGroupByAssineeDocument,
        variables: {
          teamId: props.teamId,
          taskId: props.task.id!,
        },
      },
      {
        query: LatestWorkingHistoryDocument,
      },
      {
        query: ProjectWorkingTimeSecDocument,
        variables: {
          id: props.task.project.id,
        },
      },
    ],
  });

  const onPressStopTask = useCallback(() => {
    if (isTracking !== TaskUtil.isTracking(props.task, loginUser!)) {
      return;
    }
    setTracking(false);
    stopTask({
      variables: {
        id: props.task.id!,
        input: {
          versionNo: props.task.versionNo,
        },
      },
    });
  }, [props.teamId, props.task, stopTask, isTracking, loginUser]);

  const onPressStartTask = useCallback(() => {
    if (isTracking !== TaskUtil.isTracking(props.task, loginUser!)) {
      return;
    }
    if (!!props.task.completeDateTime || props.task.project.complete) {
      setShowCompleteTaskStartErrorModal(true);
      return;
    }
    setTracking(true);
    startTask({
      variables: {
        id: props.task.id!,
        input: {
          versionNo: props.task.versionNo,
        },
      },
    });
  }, [props.teamId, props.task, loginUser, startTask, isTracking]);

  return (
    <Container isComplete={props.task.completeDateTime !== null} isTracking={isTracking}>
      <Main>
        <View
          style={{
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
          }}>
          <View style={{ justifyContent: 'center', alignItems: 'center' }}>
            {isTracking ? (
              <StopIcon
                size={42}
                disabled={startTaskResult.loading || stopTaskResult.loading}
                onPress={onPressStopTask}
              />
            ) : (
              <>
                <PlayIcon
                  size={42}
                  disabled={startTaskResult.loading || stopTaskResult.loading}
                  onPress={onPressStartTask}
                />
              </>
            )}
          </View>
          <View style={{ flexDirection: 'column', marginLeft: 5, width: 190, marginRight: 20 }}>
            <View style={{ flexDirection: 'row', flex: 1 }}>
              <View
                style={{
                  flexDirection: 'row',
                  flex: 1,
                  justifyContent: 'space-between',
                }}>
                <Typography
                  variant={TypographyType.Normal}
                  ellipsis={true}
                  tooltipWhenEllipsis={true}
                  tooltipId={`task-${props.task.id}`}
                  style={{ fontSize: 14, minWidth: 30, maxWidth: 180, textAlign: 'left' }}>
                  {props.task.title}
                </Typography>
                <View
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    maxWidth: 15,
                  }}>
                  {props.task.workingMembers.length >= 2 ? (
                    <Avatar
                      size={23}
                      textStyle={{ fontSize: 10 }}
                      name={`${props.task.workingMembers.length}名`}
                    />
                  ) : (
                    props.task.workingMembers.map((workingMemberInfo) => {
                      return (
                        <Avatar
                          size={23}
                          name={workingMemberInfo!.member.name!}
                          imageUrl={workingMemberInfo!.member.profileImageUrl}
                        />
                      );
                    })
                  )}
                </View>
              </View>
            </View>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <View
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: 22,
                }}>
                {(props.organization.plan.code === Plan.Business ||
                  props.organization.plan.code === Plan.Enterprise) &&
                  props.task.assignees.length > 0 && (
                    <>
                      {props.task.assignees.length >= 2 ? (
                        <Avatar
                          size={24}
                          textStyle={{ fontSize: 10 }}
                          name={`${props.task.assignees.length}名`}
                        />
                      ) : (
                        props.task.assignees.map((info, i) => {
                          return (
                            <Avatar
                              size={24}
                              name={info.member.name!}
                              imageUrl={info.member.profileImageUrl}
                            />
                          );
                        })
                      )}
                    </>
                  )}
              </View>
              <View style={{ flexDirection: 'row', flex: 1, paddingLeft: 5 }}>
                <TaskWorkingTime
                  task={props.task}
                  style={{ fontSize: 14, textAlign: 'right', minWidth: 65, overflow: 'hidden' }}
                />
                <Typography
                  variant={TypographyType.Normal}
                  style={{ fontSize: 14, textAlign: 'center', marginHorizontal: 3 }}>
                  /
                </Typography>
                <Typography
                  variant={TypographyType.Normal}
                  style={{ fontSize: 14, textAlign: 'left', minWidth: 65, overflow: 'hidden' }}>
                  {TimeUtil.formatForTask(props.task.estimateTimeSec)}
                </Typography>
                <Typography
                  variant={TypographyType.Normal}
                  style={{ fontSize: 14, textAlign: 'right', marginHorizontal: 3, minWidth: 40 }}>
                  {`${props.task.progressRate}%`}
                </Typography>
              </View>
            </View>
          </View>
        </View>
      </Main>
      <TaskProgressBar task={props.task} />
      <ErrorMessageModal
        showModal={showCompleteTaskStartErrorModal}
        title={'作業開始することができません'}
        message={`完了しているタスク・完了しているプロジェクトは${'\n'}作業開始することが出来ません。`}
        onCloseModal={() => setShowCompleteTaskStartErrorModal(false)}
      />
    </Container>
  );
});

export default TaskSummaryForBoard;
