import React, { useContext, useState } from 'react';
import { View } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import Button from '../../../../../../presentational/atoms/button';
import Link from '../../../../../../presentational/atoms/link';
import Typography, { TypographyType } from '../../../../../../presentational/atoms/typography';
import { IThemePart } from '../../../../../../theme';

const Container = styled.View`
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
`;

const SupportInquiryPage = () => {
  const themeContext: IThemePart = useContext(ThemeContext);
  return (
    <Container>
      <Typography
        variant={TypographyType.Description}
        style={{ fontSize: 14, lineHeight: 22, marginBottom: 20 }}>
        お問い合わせは、以下のボタンをクリックしてください。
      </Typography>
      <View>
        <View style={{ justifyContent: 'flex-start', alignItems: 'flex-start', marginBottom: 20 }}>
          <Link path={'https://form.timedesigner.com/bug-report/'} isExternalLink={true}>
            <Button text={'不具合について報告したい'} />
          </Link>
        </View>
        <View style={{ justifyContent: 'flex-start', alignItems: 'flex-start', marginBottom: 20 }}>
          <Link path={'https://form.timedesigner.com/feature-request/'} isExternalLink={true}>
            <Button text={'機能追加・改善のご要望'} />
          </Link>
        </View>
        <View style={{ justifyContent: 'flex-start', alignItems: 'flex-start', marginBottom: 20 }}>
          <Link path={'https://form.timedesigner.com/other-inquiry/'} isExternalLink={true}>
            <Button text={'その他のお問い合わせ'} />
          </Link>
        </View>
      </View>
    </Container>
  );
};

export default SupportInquiryPage;
