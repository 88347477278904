import React, { useContext, useEffect, useState } from 'react';
import styled, { ThemeContext } from 'styled-components/native';
import { View, Text, TouchableOpacity } from 'react-native';
import Icon from '../../../../../../presentational/atoms/icon';
import Typography, { TypographyType } from '../../../../../../presentational/atoms/typography';
import Modal from '../../../../../../presentational/molecules/modal';
import Table from '../../../../../../presentational/molecules/table';
import Avatar from '../../../../../../presentational/atoms/avatar';
import { IStyleTheme, IThemePart } from '../../../../../../theme';
import {
  JoinedTeamsWithoutPersonalTeamDocument,
  Member,
  TeamMemberRelationsDocument,
  TeamMembersDocument,
  useJoinToTeamMutation,
  useOrganizationMembersQuery,
  useRemoveFromTeamMutation,
  useTeamMembersQuery,
} from '../../../../../../../graphql/api/API';
import { LoginUserContext } from '../../../../../../../modules/auth/LoginUserContext';
import Form from '../../../../../../presentational/atoms/form';
import Input from '../../../../../../presentational/atoms/input';
import Button from '../../../../../../presentational/atoms/button';
import LoginUtil from '../../../../../../../modules/auth/LoginUtil';
import PlusIcon from '../../../../../../presentational/molecules/image-icon/plus';

const Container = styled.View`
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
`;

const AddTagArea = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  padding: 10px 0;
`;

interface IRegisterMemberDialogProps {
  members: Member[];
  onComplete: () => void;
  onCancel: () => void;
}

const RegiserMemberDialog = (props: IRegisterMemberDialogProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, __] = useContext(LoginUserContext);
  const teamId = LoginUtil.getTeamIdFromURl();
  const [memberId, setMemberId] = useState<string | null>(null);
  const { loading, data, error } = useOrganizationMembersQuery({
    variables: {
      organizationId: loginUser!.organizationId,
    },
  });
  const [joinMember, _] = useJoinToTeamMutation({
    variables: {
      input: {
        teamId: teamId!,
        memberId: memberId!,
      },
    },
    refetchQueries: [
      {
        query: TeamMembersDocument,
        variables: {
          teamId: teamId!,
        },
      },
      {
        query: JoinedTeamsWithoutPersonalTeamDocument,
        variables: {
          organizationId: loginUser!.organizationId,
          withArchivedTeam: false,
        },
      },
      {
        query: TeamMemberRelationsDocument,
      },
    ],
  });

  if (loading) {
    return <></>;
  }

  return (
    <Form>
      <View style={{ marginTop: 10 }}>
        <Input
          name={'member'}
          label={'メンバー'}
          type={'picker'}
          isSearchable={true}
          pickerItems={data!
            .organizationMembers!.filter(
              (member) =>
                props.members.filter((teamMember) => teamMember.id! === member!.id!).length === 0
            )
            .map((member) => {
              return {
                label: member!.name || '-',
                value: member!.id,
              };
            })}
          onChange={(value) => setMemberId(value)}
          validate={{
            required: {
              value: true,
              message: 'メンバーを選択してください',
            },
          }}
        />
      </View>
      <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
        <Button
          text="追加する"
          completeText="追加しました"
          style={{ height: 30, marginRight: 5 }}
          textStyle={{ fontSize: 14 }}
          isDisabled={memberId === null}
          onPress={async () => {
            await joinMember();
            props.onComplete();
          }}
        />
        <Button
          text="キャンセル"
          style={{ height: 30, backgroundColor: 'transparent' }}
          textStyle={{ fontSize: 14, color: themeContext.colors.primary }}
          onPress={async () => {
            props.onCancel();
          }}
          disableValidate={true}
        />
      </View>
    </Form>
  );
};

interface IRemoveMemberDialogProps {
  member: Member;
  onComplete: () => void;
  onCancel: () => void;
}

const RemoveMemberDialog = (props: IRemoveMemberDialogProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const teamId = LoginUtil.getTeamIdFromURl();
  const [loginUser, __] = useContext(LoginUserContext);
  const [removeMember, _] = useRemoveFromTeamMutation({
    variables: {
      input: {
        teamId: teamId!,
        memberId: props.member.id!,
      },
    },
    refetchQueries: [
      {
        query: TeamMembersDocument,
        variables: {
          teamId: teamId!,
        },
      },
      {
        query: JoinedTeamsWithoutPersonalTeamDocument,
        variables: {
          organizationId: loginUser!.organizationId,
          withArchivedTeam: false,
        },
      },
      {
        query: TeamMemberRelationsDocument,
      },
    ],
  });

  return (
    <Form>
      <View style={{ paddingTop: 10, paddingBottom: 30, paddingHorizontal: 20 }}>
        <Typography variant={TypographyType.Normal} style={{ textAlign: 'center' }}>
          {`${props.member.name}さんを${'\n'}チームから外しますか？`}
        </Typography>
        <Typography
          variant={TypographyType.Description}
          style={{ textAlign: 'center', marginTop: 10 }}>
          {`チームメンバーから外すと、${
            props.member.name
          }さんの画面上では、${'\n'}サイドメニューにこのチームは表示されなくなります。${'\n\n'}一度チームメンバーから外しても、あとから追加し直すことはできます。`}
        </Typography>
      </View>
      <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
        <Button
          text="チームから外す"
          completeText="チームから外しました"
          style={{ height: 30, marginRight: 5 }}
          textStyle={{ fontSize: 14 }}
          onPress={async () => {
            await removeMember();
            props.onComplete();
          }}
        />
        <Button
          text="キャンセル"
          style={{ height: 30, backgroundColor: 'transparent' }}
          textStyle={{ fontSize: 14, color: themeContext.colors.primary }}
          onPress={async () => {
            props.onCancel();
          }}
          disableValidate={true}
        />
      </View>
    </Form>
  );
};

interface IRemoveMemberMenuProps {
  member: Member;
}

const RemoveMemberMenu = (props: IRemoveMemberMenuProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [showRemoveMemberModal, setShowRemoveMemberModal] = useState(false);
  return (
    <>
      <TouchableOpacity style={{ marginLeft: 20 }} onPress={() => setShowRemoveMemberModal(true)}>
        <Typography
          variant={TypographyType.Normal}
          style={{ color: themeContext.colors.link, fontSize: 13 }}>
          チームから外す
        </Typography>
      </TouchableOpacity>
      <Modal
        title={'チームから外す'}
        isShow={showRemoveMemberModal}
        onClose={() => {
          setShowRemoveMemberModal(false);
        }}>
        <RemoveMemberDialog
          member={props.member!}
          onComplete={() => {
            setShowRemoveMemberModal(false);
          }}
          onCancel={() => {
            setShowRemoveMemberModal(false);
          }}
        />
      </Modal>
    </>
  );
};

const MemberTable = () => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const teamId = LoginUtil.getTeamIdFromURl();
  const { loading, data, error } = useTeamMembersQuery({
    variables: {
      teamId: teamId!,
    },
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <></>;
  }

  return (
    <Table
      height={54}
      rowData={data!.teamMembers!.map((member) => {
        return {
          名前: (
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Avatar size={30} name={member!.name} imageUrl={member!.profileImageUrl} />
              <View style={{ flexDirection: 'column', justifyContent: 'center', marginLeft: 10 }}>
                <Typography variant={TypographyType.Normal} style={{ fontSize: 13 }}>
                  {member!.name}
                </Typography>
                <Typography variant={TypographyType.Description} style={{ fontSize: 13 }}>
                  {member!.mailAddress}
                </Typography>
              </View>
            </View>
          ),
          操作: () => (
            <View style={{ flexDirection: 'row' }}>
              <RemoveMemberMenu member={member!} />
            </View>
          ),
        };
      })}
      rowDataStyle={{
        名前: {
          width: 'auto',
        },
        ステータス: {
          maxWidth: 200,
        },
      }}
    />
  );
};

const TeamSettingsMemberPage = () => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const teamId = LoginUtil.getTeamIdFromURl();
  const { loading, data, error } = useTeamMembersQuery({
    variables: {
      teamId: teamId!,
    },
  });

  if (loading) {
    return <></>;
  }

  return (
    <Container>
      <AddTagArea onPress={() => setShowRegisterModal(true)}>
        <PlusIcon size={14} onPress={() => setShowRegisterModal(true)}>
          <Typography variant={TypographyType.Normal} style={{ fontSize: 14 }}>
            チームメンバーを追加する
          </Typography>
        </PlusIcon>
      </AddTagArea>
      <Modal
        title={'新しくメンバーを追加する'}
        isShow={showRegisterModal}
        onClose={() => {
          setShowRegisterModal(false);
        }}>
        <RegiserMemberDialog
          members={data!.teamMembers! as Member[]}
          onComplete={() => {
            setShowRegisterModal(false);
          }}
          onCancel={() => {
            setShowRegisterModal(false);
          }}
        />
      </Modal>
      <MemberTable />
    </Container>
  );
};

export default React.memo(TeamSettingsMemberPage);
