import React, { useContext, useState } from 'react';
import styled, { ThemeContext } from 'styled-components/native';
import { Image } from 'react-native';
import { useScreenDimensions } from 'use-dimensions';
import { Redirect, useHistory, useLocation } from 'react-router';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import Button from '../../../../presentational/atoms/button';
import Form from '../../../../presentational/atoms/form';
import { IThemePart } from '../../../../theme';
import AvatarEdit from 'react-avatar-edit';
import logoLargeBlack from '../../../../../base64Images/logo/logo-large-black';
import login from '../../../../../base64Images/login/login';
import LoginUtil from '../../../../../modules/auth/LoginUtil';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import base64Util from '../../../../../util/Base64Util';
import {
  useJoinToOrganizationWithGoogleAccountByInviteLinkMutation,
  useJoinToOrganizationWithMailAddressByInviteLinkMutation,
} from '../../../../../graphql/api/API';

const Header = styled.View`
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  height: 100px;
  padding-left: 20px;
  padding-top: 20px;
`;

const Container = styled.View`
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  height: calc(100vh - 100px);
`;

const LeftContainer = styled.View`
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 0 50px;
`;

const RightContainer = styled.View`
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 0 50px;
`;

const RightContainerInner = styled.View`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const LinkContainer = styled.View`
  margin: 1.5rem;
  text-align: center;
`;

const JoinWithLinkOrganizationProfileImage = () => {
  const { height, width } = useScreenDimensions();
  const history = useHistory();
  const { state } = useLocation();
  const [imageBase64, setImageBase64] = useState(
    (state as any)?.profileImageUrl
      ? base64Util.convertImgDataURL((state as any).profileImageUrl)
      : ''
  );
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const themeContext: IThemePart = useContext(ThemeContext);
  if (state === null || state === undefined) {
    return <Redirect to={'/signup'} />;
  }

  const [joinOrganizationWithMailAddress, _] =
    useJoinToOrganizationWithMailAddressByInviteLinkMutation({
      variables: {
        input: {
          key: (state as any).key,
          name: (state as any).name,
          mailAddress: (state as any).mailAddress,
          password: (state as any).password,
          profileImageBase64: imageBase64,
        },
      },
    });

  const [joinOrganizationWithGoogleAccount, __] =
    useJoinToOrganizationWithGoogleAccountByInviteLinkMutation({
      variables: {
        input: {
          key: (state as any).key,
          name: (state as any).name,
          profileImageBase64: imageBase64,
          googleAuthCode: (state as any).googleAuthCode,
        },
      },
    });

  return (
    <>
      <Header>
        <Image
          source={{ uri: logoLargeBlack }}
          resizeMode="contain"
          style={{
            width: 300,
            height: 100,
          }}
        />
      </Header>
      <Container>
        <LeftContainer>
          <Image
            source={{ uri: login }}
            resizeMode="contain"
            style={{
              width: Math.max(width / 4, 500),
              height: Math.max(width / 4, 500),
            }}
          />
        </LeftContainer>
        <RightContainer>
          <RightContainerInner>
            <Typography variant={TypographyType.Title} style={{ marginVertical: 10 }}>
              {`プロフィール画像を設定してください${'\n'}あとで設定することも出来ます`}
            </Typography>
            <Form style={{ marginBottom: 30 }}>
              <AvatarEdit
                width={600}
                height={300}
                label={`クリックして画像を選択してください`}
                labelStyle={{
                  color: themeContext.colors.description,
                  cursor: 'pointer',
                  paddingLeft: 150,
                  paddingRight: 150,
                  paddingTop: 150,
                  paddingBottom: 150,
                }}
                onCrop={setImageBase64}
                src={imageBase64}
                onBeforeFileLoad={(elem: React.ChangeEvent<HTMLInputElement>) => {
                  if (elem.target.files && elem.target.files[0].size > 10 * 1024 * 1024) {
                    alert('ファイルサイズは10MB以下にしてください');
                    elem.target.value = '';
                  }
                }}
              />
              <Button
                text="次へ"
                style={{ marginTop: 20, minWidth: 300 }}
                onPress={async () => {
                  if ((state as any).googleAuthCode) {
                    const result = await joinOrganizationWithGoogleAccount();
                    if (result.data?.joinToOrganizationWithGoogleAccountByInviteLink) {
                      await LoginUtil.saveToken(
                        result.data!.joinToOrganizationWithGoogleAccountByInviteLink
                      );
                      const user = await LoginUtil.getLoginUser();
                      setLoginUser(user);
                      history.push('/join-with-link/thanks');
                    }
                  } else {
                    const result = await joinOrganizationWithMailAddress();
                    if (result.data?.joinToOrganizationWithMailAddressByInviteLink) {
                      await LoginUtil.saveToken(
                        result.data!.joinToOrganizationWithMailAddressByInviteLink
                      );
                      const user = await LoginUtil.getLoginUser();
                      setLoginUser(user);
                      history.push('/join-with-link/thanks');
                    }
                  }
                }}
              />
              <Button
                text="あとで登録する"
                style={{ marginTop: 20, minWidth: 300 }}
                onPress={async () => {
                  if ((state as any).googleAuthCode) {
                    const result = await joinOrganizationWithGoogleAccount({
                      variables: {
                        input: {
                          key: (state as any).key,
                          name: (state as any).name,
                          profileImageBase64: null,
                          googleAuthCode: (state as any).googleAuthCode,
                        },
                      },
                    });
                    if (result.data?.joinToOrganizationWithGoogleAccountByInviteLink) {
                      await LoginUtil.saveToken(
                        result.data!.joinToOrganizationWithGoogleAccountByInviteLink
                      );
                      const user = await LoginUtil.getLoginUser();
                      setLoginUser(user);
                      history.push('/join-with-link/thanks');
                    }
                  } else {
                    const result = await joinOrganizationWithMailAddress({
                      variables: {
                        input: {
                          key: (state as any).key,
                          name: (state as any).name,
                          mailAddress: (state as any).mailAddress,
                          password: (state as any).password,
                          profileImageBase64: null,
                        },
                      },
                    });
                    if (result.data?.joinToOrganizationWithMailAddressByInviteLink) {
                      await LoginUtil.saveToken(
                        result.data!.joinToOrganizationWithMailAddressByInviteLink
                      );
                      const user = await LoginUtil.getLoginUser();
                      setLoginUser(user);
                      history.push('/join-with-link/thanks');
                    }
                  }
                }}
              />
            </Form>
          </RightContainerInner>
        </RightContainer>
      </Container>
    </>
  );
};

export default React.memo(JoinWithLinkOrganizationProfileImage);
