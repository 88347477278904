import React, { useContext } from 'react';
import { GestureResponderEvent, Image, Platform, View } from 'react-native';
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';
//@ts-ignore
import { ThemeContext } from 'styled-components/native';
import onImage from '../../../../../base64Images/star/on';
import offImage from '../../../../../base64Images/star/off';
import { IThemePart } from '../../../../theme';

interface IStarIconProps {
  size: number;
  onPress?: (event: GestureResponderEvent) => void;
  disabled?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  children?: React.ReactNode;
  on: boolean;
}

const StarIcon = (props: IStarIconProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  if (props.onPress) {
    return (
      <TouchableOpacity
        disabled={props.disabled}
        onPress={props.onPress}
        style={[
          {
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingVertical: 5,
          },
          props.containerStyle,
        ]}>
        <Image
          source={{ uri: props.on ? onImage : offImage }}
          resizeMode="contain"
          style={[
            {
              height: props.size,
              width: props.size + 2,
              opacity: props.on ? 1 : 1,
              shadowOffset: {
                width: 1,
                height: 1,
              },
              shadowOpacity: 0.1,
            } as any,
            Platform.OS === 'web' ? { elevation: 2, cursor: 'pointer' } : {},
          ]}
        />
        {props.children}
      </TouchableOpacity>
    );
  }
  return (
    <View
      style={[
        {
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          paddingVertical: 5,
        },
        props.containerStyle,
      ]}>
      <Image
        source={{ uri: props.on ? onImage : offImage }}
        resizeMode="contain"
        style={[
          {
            height: props.size,
            width: props.size + 2,
            opacity: props.on ? 1 : 1,
            shadowOffset: {
              width: 1,
              height: 1,
            },
            shadowOpacity: 0.1,
          } as any,
          Platform.OS === 'web' ? { elevation: 2, cursor: 'pointer' } : {},
        ]}
      />
      {props.children}
    </View>
  );
};

export default StarIcon;
