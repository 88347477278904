import moment from 'moment-timezone';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { type TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import {
  type CalendarEvent,
  CalendarEventDocument,
  CalendarWorkingHistoryDocument,
  type Project,
  ProjectWorkingTimeSecDocument,
  type WorkingHistory,
  type WorkingHistoryForSummary,
  WorkingHistoryForSummaryDocument,
  useCalendarEventWorkingHistoriesForSummaryQuery,
  useCalendarEventWorkingHistoriesQuery,
  useDeleteWorkingHistoryMutation,
  useProjectQuery,
  useUpdateWorkingHistoryMutation,
} from '../../../../../../graphql/api/API';
import TimeUtil from '../../../../../../util/TimeUtil';
import Avatar from '../../../../../presentational/atoms/avatar';
import Button from '../../../../../presentational/atoms/button';
import EditableText from '../../../../../presentational/atoms/editable-text';
import useHover from '../../../../../presentational/atoms/editable-text/use-hover';
import Form from '../../../../../presentational/atoms/form';
import Input from '../../../../../presentational/atoms/input';
import List from '../../../../../presentational/atoms/list';
import Typography, { TypographyType } from '../../../../../presentational/atoms/typography';
import DeleteIcon from '../../../../../presentational/molecules/image-icon/delete';
import EditIcon from '../../../../../presentational/molecules/image-icon/edit';
import FileMoveIcon from '../../../../../presentational/molecules/image-icon/file-move';
import MenuIcon from '../../../../../presentational/molecules/image-icon/menu';
import Modal from '../../../../../presentational/molecules/modal';
import type { IStyleTheme, IThemePart } from '../../../../../theme';
import MoveWorkingHistoryModal from '../../../../web/organisms/move-working-history-modal';

const AttachmentFileRow = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
  color: #ffffff;
  font-size: 1.5rem;
  padding: 5px 10px;
  border-bottom-width: 1px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
`;

const Menu = styled.View`
  position: absolute;
  top: 40px;
  right: 0;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
  border-width: 1px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 160px;
`;

interface IWorkingHistoryTimeProps {
  workingHistory: WorkingHistory;
  style?: TextStyle;
}

export const WorkingHistoryTime = (props: IWorkingHistoryTimeProps) => {
  return (
    <Typography variant={TypographyType.Normal} style={props.style}>
      {TimeUtil.formatForTask(
        moment(props.workingHistory.end).diff(moment(props.workingHistory.start), 'seconds')
      )}
    </Typography>
  );
};

interface IModalProps {
  showModal: boolean;
  workingHistory: WorkingHistoryForSummary;
  onPressYes: () => Promise<void>;
  onCloseModal: () => void;
}
const ConfirmModal = (props: IModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  return (
    <Modal
      title={'この作業履歴を削除しますか？'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <Typography variant={TypographyType.Normal} style={{ textAlign: 'center' }}>
        {`${moment(props.workingHistory.start).format('YYYY/MM/DD HH:mm')} 〜 ${
          props.workingHistory.end
            ? moment(props.workingHistory.end).format('YYYY/MM/DD HH:mm')
            : '-'
        }`}
      </Typography>
      <View style={{ marginTop: 10 }}>
        <Typography
          variant={TypographyType.Description}
          style={{ textAlign: 'center', color: themeContext.colors.error }}>
          {`この操作はやり直しが出来ません`}
        </Typography>
      </View>
      <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1 }}>
        <Button
          text={'キャンセル'}
          style={{
            minWidth: 100,
            marginRight: 10,
            marginVertical: 10,
            backgroundColor: 'transparent',
          }}
          textStyle={{ color: themeContext.colors.primary }}
          disableValidate={true}
          onPress={() => {
            props.onCloseModal();
          }}
        />
        <Button
          text={'削除する'}
          style={{
            minWidth: 100,
            marginRight: 10,
            marginVertical: 10,
            borderColor: themeContext.colors.error,
            borderRadius: 3,
            borderWidth: 1,
            backgroundColor: 'transparent',
          }}
          textStyle={{ color: themeContext.colors.error }}
          onPress={async () => {
            await props.onPressYes();
          }}
        />
      </View>
    </Modal>
  );
};

interface IWorkingHistoryItemProps {
  workingHistory: WorkingHistoryForSummary;
  project: Project;
  moveToFront: (value: boolean) => void;
}

const WorkingHistoryItem = (props: IWorkingHistoryItemProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const menuRef = useRef();
  const [openMenu, setOpenMenu] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showMoveModal, setShowMoveModal] = useState(false);
  const [deleteWorkingHistory, __] = useDeleteWorkingHistoryMutation({
    variables: {
      id: props.workingHistory.id!,
      input: {
        versionNo: props.workingHistory.versionNo,
      },
    },
    update: (cache, result) => {
      cache.evict({
        id: cache.identify(props.workingHistory),
      });
      cache.evict({ id: `WorkingHistoryForSummary:${props.workingHistory.id}` });
      cache.evict({ id: `CalendarWorkingHistory:${props.workingHistory.id}` });
    },
    refetchQueries: [
      {
        query: ProjectWorkingTimeSecDocument,
        variables: {
          id: props.workingHistory.calendarEvent!.projectId,
        },
      },
    ],
  });

  useHotkeys('Esc', () => setOpenMenu(false));
  const clickDocument = (e: any) => {
    if ((menuRef?.current as any)?.contains(e.target)) {
      (menuRef?.current as any)?.click();
      return;
    }
    setOpenMenu(false);
    props.moveToFront(false);
  };
  useEffect(() => {
    window.addEventListener('click', clickDocument);
    return () => {
      window.removeEventListener('click', clickDocument);
    };
  }, [clickDocument]);

  return (
    <AttachmentFileRow
      style={{ borderTopWidth: 1, zIndex: 1 }}
      onPress={() => setShowUpdateModal(true)}>
      {!props.project.team.personalTeam && (
        <View style={{ flex: 2, alignItems: 'flex-start', justifyContent: 'center' }}>
          <Avatar
            size={24}
            name={props.workingHistory.workingMember.name || ''}
            imageUrl={props.workingHistory.workingMember.profileImageUrl}
          />
        </View>
      )}
      <View style={{ flex: 2, alignItems: 'center', justifyContent: 'center' }}>
        <Typography
          variant={TypographyType.Normal}
          style={{ fontSize: 13, textAlign: 'center', lineHeight: 17 }}>
          {moment(props.workingHistory.start).format('YYYY/MM/DD HH:mm:ss')}
        </Typography>
      </View>
      <View style={{ flex: 1.5, alignItems: 'center', justifyContent: 'center' }}>
        <Typography variant={TypographyType.Normal} style={{ fontSize: 13, textAlign: 'center' }}>
          〜
        </Typography>
      </View>
      <View style={{ flex: 2, alignItems: 'center', justifyContent: 'center' }}>
        <Typography
          variant={TypographyType.Normal}
          style={{ fontSize: 13, textAlign: 'center', lineHeight: 17 }}>
          {props.workingHistory.end
            ? moment(props.workingHistory.end).format('YYYY/MM/DD HH:mm:ss')
            : '-'}
        </Typography>
      </View>
      <View style={{ flex: 3, alignItems: 'center', justifyContent: 'center', marginLeft: 5 }}>
        <WorkingHistoryTime
          workingHistory={props.workingHistory}
          style={{ fontSize: 13, textAlign: 'center' }}
        />
      </View>
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          <MenuIcon
            size={28}
            onPress={() => {
              props.moveToFront(true);
              setOpenMenu(true);
            }}
          />
        </View>
        {openMenu && (
          <Menu ref={menuRef as any}>
            <View style={{ paddingHorizontal: 5, marginVertical: 5 }}>
              <EditIcon
                size={20}
                containerStyle={{ marginRight: 5 }}
                onPress={() => {
                  props.moveToFront(false);
                  setShowUpdateModal(true);
                  setOpenMenu(false);
                }}>
                <Typography variant={TypographyType.SubTitle}>編集する</Typography>
              </EditIcon>
            </View>
            <View style={{ paddingHorizontal: 5 }}>
              <FileMoveIcon
                size={20}
                containerStyle={{ marginRight: 5 }}
                onPress={() => {
                  props.moveToFront(false);
                  setShowMoveModal(true);
                  setOpenMenu(false);
                }}>
                <Typography variant={TypographyType.SubTitle}>移動する</Typography>
              </FileMoveIcon>
            </View>
            <View style={{ paddingHorizontal: 5 }}>
              <DeleteIcon
                size={23}
                onPress={() => {
                  props.moveToFront(false);
                  setShowDeleteModal(true);
                  setOpenMenu(false);
                }}>
                <Typography variant={TypographyType.SubTitle}>削除する</Typography>
              </DeleteIcon>
            </View>
          </Menu>
        )}
      </View>
      <ConfirmModal
        showModal={showDeleteModal}
        workingHistory={props.workingHistory}
        onPressYes={async () => {
          setShowDeleteModal(false);
          await deleteWorkingHistory();
        }}
        onCloseModal={() => setShowDeleteModal(false)}
      />
      <UpdateWorkingHistoryModal
        workingHistory={props.workingHistory}
        showModal={showUpdateModal}
        onCloseModal={() => setShowUpdateModal(false)}
      />
      <MoveWorkingHistoryModal
        workingHistory={props.workingHistory}
        showModal={showMoveModal}
        onCloseModal={() => setShowMoveModal(false)}
      />
    </AttachmentFileRow>
  );
};

interface IUpdateWorkingHistoryModalProps {
  workingHistory: WorkingHistoryForSummary;
  showModal: boolean;
  onCloseModal: () => void;
}

const UpdateWorkingHistoryModal = (props: IUpdateWorkingHistoryModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [start, setStart] = useState<moment.Moment>(moment(props.workingHistory.start));
  const [end, setEnd] = useState<moment.Moment | null>(
    props.workingHistory.end ? moment(props.workingHistory.end) : null
  );
  const [memo, setMemo] = useState<string | null>(props.workingHistory.memo || null);
  const [hoverRef, isHover] = useHover();
  const [showEditor, setShowEditor] = useState(false);
  const [focusEndDateTimeInput, setFocusEndDateTimeInput] = useState(false);
  const [updateWokingHistory, _] = useUpdateWorkingHistoryMutation({
    variables: {
      id: props.workingHistory.id!,
      input: {
        start: start?.toISOString(),
        end: end ? end.toISOString() : null,
        memo: memo,
        versionNo: props.workingHistory.versionNo,
      },
    },
    refetchQueries: props.workingHistory.calendarEvent
      ? [
          {
            query: CalendarEventDocument,
            variables: {
              id: props.workingHistory.calendarEvent.id!,
            },
          },
          {
            query: WorkingHistoryForSummaryDocument,
            variables: {
              id: props.workingHistory.id!,
            },
          },
          {
            query: CalendarWorkingHistoryDocument,
            variables: {
              id: props.workingHistory.id!,
            },
          },
        ]
      : [],
  });

  useEffect(() => {
    setStart(moment(props.workingHistory.start));
    setEnd(props.workingHistory.end ? moment(props.workingHistory.end) : null);
    setMemo(props.workingHistory.memo || null);
  }, [props.workingHistory, props.showModal]);

  const isContentEmpty =
    props.workingHistory.memo === null ||
    (props.workingHistory.memo &&
      JSON.parse(props.workingHistory.memo).blocks &&
      JSON.parse(props.workingHistory.memo).blocks.length > 0 &&
      JSON.parse(props.workingHistory.memo).blocks[0]?.text === '');

  return (
    <Modal
      title={'作業履歴を編集する'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
        setShowEditor(false);
      }}>
      <View style={{ flexDirection: 'column', justifyContent: 'center', paddingTop: 20 }}>
        <Form>
          <View style={{ flexDirection: 'row', alignItems: 'center', zIndex: 4 }}>
            <View style={{ minWidth: 130 }}>
              <Typography variant={TypographyType.Normal} style={{ fontSize: 18, lineHeight: 22 }}>
                作業開始日時
              </Typography>
            </View>
            <EditableText
              value={start}
              type={'date-time-picker'}
              textStyle={{ fontSize: 18, lineHeight: 22 }}
              containerStyle={{
                borderBottomWidth: 1,
                borderBottomColor: themeContext.colors.textColor,
              }}
              disableClear={true}
              onChange={(value) => {
                setStart(value as moment.Moment);
              }}
            />
          </View>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 20,
              zIndex: focusEndDateTimeInput ? 5 : 3,
            }}>
            <View style={{ minWidth: 130 }}>
              <Typography variant={TypographyType.Normal} style={{ fontSize: 18, lineHeight: 22 }}>
                作業終了日時
              </Typography>
            </View>
            {props.workingHistory.end ? (
              <EditableText
                value={end}
                type={'date-time-picker'}
                textStyle={{ fontSize: 18, lineHeight: 22 }}
                containerStyle={{
                  borderBottomWidth: 1,
                  borderBottomColor: themeContext.colors.textColor,
                }}
                disableClear={true}
                onChange={(value) => {
                  setEnd(value as moment.Moment);
                }}
                onFocus={() => setFocusEndDateTimeInput(true)}
                onBlur={() => setFocusEndDateTimeInput(false)}
              />
            ) : (
              <Typography
                variant={TypographyType.Normal}
                style={{ fontSize: 13, textAlign: 'center', lineHeight: 17 }}>
                -
              </Typography>
            )}
          </View>
          {start?.isAfter(end) && (
            <View style={{ marginTop: 10 }}>
              <Typography
                variant={TypographyType.Description}
                style={{ color: themeContext.colors.error }}>
                開始日時は、終了日時よりも前にしてください
              </Typography>
            </View>
          )}
          {props.workingHistory.end === null && !start && (
            <View style={{ marginTop: 10 }}>
              <Typography
                variant={TypographyType.Description}
                style={{ color: themeContext.colors.error }}>
                開始日時を入力してください
              </Typography>
            </View>
          )}
          {props.workingHistory.end !== null && (!start || !end) && (
            <View style={{ marginTop: 10 }}>
              <Typography
                variant={TypographyType.Description}
                style={{ color: themeContext.colors.error }}>
                開始日時・終了日時を入力してください
              </Typography>
            </View>
          )}
          <View
            style={
              {
                flexDirection: 'row',
                marginTop: 20,
                zIndex: 2,
                alignItems: 'flex-start',
              } as any
            }>
            <View style={{ minWidth: 130 }}>
              <Typography variant={TypographyType.Normal} style={{ fontSize: 18, lineHeight: 22 }}>
                作業メモ
              </Typography>
            </View>
            <View ref={hoverRef as any} style={{ minWidth: 300 }}>
              {showEditor ? (
                <Form style={{ zIndex: 2 }}>
                  <Input
                    initialValue={props.workingHistory.memo || ''}
                    name={'description'}
                    type={'rich-text-editor'}
                    showToolBarAlways={true}
                    autoFocus={true}
                    onChange={(value) => {
                      setMemo(value);
                    }}
                  />
                </Form>
              ) : (
                <TouchableOpacity onPress={() => setShowEditor(true)} style={{ zIndex: 2 }}>
                  <Form>
                    <div
                      style={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        backgroundColor: themeContext.colors.separator,
                        zIndex: 2,
                        opacity: isHover ? 0.5 : 0,
                        cursor: 'pointer',
                      }}
                    />
                    <View
                      style={{
                        opacity: isContentEmpty ? 0.2 : 1,
                        minHeight: 50,
                        paddingHorizontal: 10,
                      }}>
                      <Input
                        initialValue={
                          isContentEmpty
                            ? `{
                  "blocks": [
                    {
                      "key": "dummy",
                      "text": "作業メモを追加...",
                      "type": "unstyled",
                      "depth": 0,
                      "inlineStyleRanges": [],
                      "entityRanges": [],
                      "data": {}
                    }
                  ],
                  "entityMap": {}
                }
                `
                            : props.workingHistory.memo!
                        }
                        name={'description'}
                        type={'rich-text-editor'}
                        showToolBarAlways={false}
                        multiline={true}
                        readonly={true}
                      />
                    </View>
                  </Form>
                </TouchableOpacity>
              )}
            </View>
          </View>
          <View
            style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
            <Button
              text={'更新する'}
              style={{ minWidth: 100, marginRight: 10, marginVertical: 20 }}
              isDisabled={
                props.workingHistory.end !== null ? start?.isAfter(end) || !start || !end : !start
              }
              onPress={async () => {
                await updateWokingHistory();
                props.onCloseModal();
                setShowEditor(false);
              }}
            />
            <Button
              text={'キャンセル'}
              style={{
                minWidth: 100,
                marginLeft: 10,
                marginVertical: 20,
                backgroundColor: '#FFFFFF',
                borderColor: themeContext.colors.primary,
                borderWidth: 1,
                borderRadius: 5,
                height: 40,
              }}
              textStyle={{ color: themeContext.colors.primary }}
              disableValidate={true}
              onPress={() => {
                props.onCloseModal();
                setShowEditor(false);
              }}
            />
          </View>
        </Form>
      </View>
    </Modal>
  );
};

interface IWorkingHistoryListProps {
  calendarEvent: CalendarEvent;
  project: Project;
}

const WorkingHistoryList = React.memo((props: IWorkingHistoryListProps) => {
  const fetchWorkingHistories = useCalendarEventWorkingHistoriesForSummaryQuery({ //TODO TDV1-112
    variables: {
      calendarEventId: props.calendarEvent.id,
    },
    fetchPolicy: 'network-only',
  });

  const renderItem = useCallback(
    (item, dragging, index, isGroupFirstRow, moveToFront) => (
      <WorkingHistoryItem
        workingHistory={item.data}
        project={props.project}
        moveToFront={moveToFront}
        key={index}
      />
    ),
    []
  );

  const workingHistories = (fetchWorkingHistories.data?.calendarEventWorkingHistoriesForSummary || [])
    .slice()
    .map((history) => {
      return {
        data: {
          id: history!.id!,
          start: moment(history!.start),
          end: history!.end ? moment(history!.end) : null,
          memo: history!.memo,
          calendarEvent: history!.calendarEvent,
          workingMember: history!.workingMember,
          workingTimeSec: history?.workingTimeSec,
          versionNo: history!.versionNo,
        } as WorkingHistory,
        group: moment(history!.start).format('ll'),
      };
    })
    .sort(
      (a, b) =>
        (b.data.start as moment.Moment).toDate().getTime() -
        (a.data.start as moment.Moment).toDate().getTime()
    );

  return (
    <List
      style={{ height: 'calc(100vh - 110px)' }}
      items={workingHistories}
      getKey={(workingHistory) => (workingHistory.data as any).id}
      showGroupHeader={true}
      renderItem={renderItem}
    />
  );
});

interface ICalendarEventDetailWorkingHistoryProps {
  calendarEvent: CalendarEvent;
}

const CalendarEventDetailWorkingHistory = (props: ICalendarEventDetailWorkingHistoryProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const { loading, data } = useProjectQuery({
    variables: {
      id: props.calendarEvent.projectId,
    },
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <></>;
  }

  return <WorkingHistoryList calendarEvent={props.calendarEvent} project={data!.project!} />;
};

export default CalendarEventDetailWorkingHistory;
