import React, { useContext, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router';
import styled, { ThemeContext } from 'styled-components/native';
import useDimensions from 'use-dimensions';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import { IStyleTheme, IThemePart } from '../../../../theme';
import CustomScrollView from '../../../../presentational/atoms/custom-scroll-view';
import {
  Announcement,
  UnreadAnnouncementCountDocument,
  useAnnouncementQuery,
  useReadAnnouncementMutation,
} from '../../../../../graphql/api/API';
import Icon from '../../../../presentational/atoms/icon';
import CloseIcon from '../../../../presentational/molecules/image-icon/close';

interface IContainerProps extends IStyleTheme {
  isOpen: boolean;
  windowWidth: number;
}

const Container = styled.View<IContainerProps>`
  height: calc(100vh - 57px);
  right: ${(props: IContainerProps) => (props.windowWidth < 1260 ? '-750px' : '-800px')};
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.4s;
  width: ${(props: IContainerProps) =>
    props.isOpen
      ? '800px'
      : '1px'}; // Note: 閉じている時に幅を 1px ではなく、0pxにすると、なぜかSafariは幅を300pxぐらい取る挙動になってしまう。それによって画面右側300pxぐらいに透明な状態のエリアがかぶさってしまい、画面操作を阻害してしまう。Safari対応のために幅を1pxに指定するようにしている。
  transform: ${(props: IContainerProps) => `translateX(${props.isOpen ? '-800px' : '0'})`};
  background-color: ${(props: IContainerProps) => props.theme.colors.baseColor};
  overflow: hidden;
`;

const HeaderMenu = styled.View`
  display: flex;
  flex-direction: row;
  padding: 10px 15px;
  justify-content: flex-end;
  align-items: center;
  border-bottom-width: 1px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
`;

interface IContainerInnerProps extends IStyleTheme {
  isShow: boolean;
}

const ContainerInner = styled.View<IContainerInnerProps>``;

interface IAnnouncementDetailProps {
  announcement: Announcement;
}

const AnnouncementDetail = (props: IAnnouncementDetailProps) => {
  const [readAnnouncement, __] = useReadAnnouncementMutation({
    variables: {
      id: props.announcement.id,
    },
    refetchQueries: [
      {
        query: UnreadAnnouncementCountDocument,
      },
    ],
  });

  useEffect(() => {
    setTimeout(() => {
      if (!props.announcement.readed) {
        readAnnouncement();
      }
    }, 500);
  }, []);

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: `<iframe src="${props.announcement.url}" style="width: 100%; height: calc(100vh - 57px); border-width: 0;" />`,
      }}
    />
  );
};

interface IMessageDetailInnerProps {
  message: Announcement | null;
  loading: boolean;
}

const MessageDetailInner = (props: IMessageDetailInnerProps) => {
  const { pathname } = useLocation();
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, _] = useContext(LoginUserContext);
  const history = useHistory();
  const dimensions = useDimensions();
  const windowWidth = dimensions.window.width;

  if (props.loading) {
    return (
      <Container isOpen={true} windowWidth={windowWidth}>
        <ContainerInner isShow={true}></ContainerInner>
      </Container>
    );
  }
  if (props.message) {
    if (/.*\/messages\/(.+)\/detail\//.test(pathname)) {
      return (
        <Container isOpen={true} windowWidth={windowWidth}>
          <ContainerInner isShow={true}>
            <HeaderMenu style={{ zIndex: 3 }}>
              <CloseIcon
                size={18}
                onPress={() => {
                  history.push(`/app/${loginUser!.organizationId}/messages/announce/`);
                }}
              />
            </HeaderMenu>
            <AnnouncementDetail announcement={props.message!} />
          </ContainerInner>
        </Container>
      );
    }
  }
  return <Container isOpen={false} windowWidth={windowWidth} />;
};

interface ITaskDetailWithFetchDataProps {
  messageId: string | null;
}

const MessageDetailWithFetchData = (props: ITaskDetailWithFetchDataProps) => {
  const { loading, data, error } = useAnnouncementQuery({
    variables: {
      id: props.messageId!,
    },
    skip: props.messageId === null,
  });

  return <MessageDetailInner loading={loading} message={data?.announcement || null} />;
};

const MessageDetail = () => {
  const { pathname } = useLocation();
  const matches = pathname.match(/.*\/messages\/announce\/(.+)\/[^\/]+/);
  let messageId: string | null = null;
  if (matches && matches.length > 1) {
    messageId = matches[1];
  }

  return <MessageDetailWithFetchData messageId={messageId} />;
};

export default MessageDetail;
