import React, { useContext, useState } from 'react';
import { View } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import { Team, useTeamQuery, useUpdateTeamMutation } from '../../../../../../../graphql/api/API';
import when from '../../../../../../../lang-extention/When';
import { LoginUserContext } from '../../../../../../../modules/auth/LoginUserContext';
import LoginUtil from '../../../../../../../modules/auth/LoginUtil';
import Button from '../../../../../../presentational/atoms/button';
import Form from '../../../../../../presentational/atoms/form';
import Input from '../../../../../../presentational/atoms/input';
import Typography, { TypographyType } from '../../../../../../presentational/atoms/typography';
import { IThemePart } from '../../../../../../theme';
import SettingsElement from '../../../../organisms/settings-element';

const Container = styled.View`
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
`;

interface IDisclosureModalProps {
  closeModal: () => void;
  team: Team;
}

interface ITeamNameModalProps {
  closeModal: () => void;
  team: Team;
}

const TeamNameModal = (props: ITeamNameModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [teamName, setTeamName] = useState(props.team.name);

  const [updateTeam] = useUpdateTeamMutation({
    variables: {
      id: props.team.id!,
      input: {
        name: teamName,
        versionNo: props.team.versionNo,
      },
    },
  });

  return (
    <Form style={{ marginTop: 10 }}>
      <Input
        name={'teamName'}
        label={'チーム名'}
        initialValue={teamName}
        onChange={setTeamName}
        validate={{
          required: {
            value: true,
            message: 'チーム名を入力してください',
          },
          maxLength: {
            value: 100,
            message: '100文字以内で入力してください',
          },
        }}
      />
      <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1 }}>
        <Button
          text={'変更する'}
          completeText="変更しました"
          style={{ minWidth: 100, marginRight: 10, marginVertical: 10 }}
          onPress={async () => {
            await updateTeam();
            props.closeModal();
          }}
        />
        <Button
          text={'キャンセル'}
          style={{
            minWidth: 100,
            marginRight: 10,
            marginVertical: 10,
            backgroundColor: 'transparent',
          }}
          textStyle={{ color: themeContext.colors.primary }}
          disableValidate={true}
          onPress={() => {
            props.closeModal();
          }}
        />
      </View>
    </Form>
  );
};

const TeamSettingsInfoPage = () => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const teamId = LoginUtil.getTeamIdFromURl();
  const { loading, data } = useTeamQuery({
    variables: {
      id: teamId!,
    },
    skip: teamId === null,
  });

  if (loading) {
    return <></>;
  }

  return (
    <Container>
      <SettingsElement
        title={'チーム名'}
        changeText={'チーム名を変更する'}
        modal={(closeModal) => <TeamNameModal closeModal={closeModal} team={data!.team!} />}>
        <Typography variant={TypographyType.Normal} ellipsis={true} style={{ maxWidth: 400 }}>
          {data!.team!.name}
        </Typography>
      </SettingsElement>
    </Container>
  );
};

export default TeamSettingsInfoPage;
