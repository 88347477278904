import { Organization, Plan } from '../graphql/api/API';

export default class OrganizationUtil {
  private constructor() {}

  public static isPersonalPlan(organization: Organization): boolean {
    return organization!.plan.code === Plan.Basic || organization!.plan.code === Plan.Professional;
  }

  public static isTeamPlan(organization: Organization): boolean {
    return !this.isPersonalPlan(organization);
  }
}
