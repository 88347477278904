import React, { useContext, useState } from 'react';
import { View } from 'react-native';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import {
  type Organization,
  useDisableTwoFactorAuthRequiedMutation,
  useEnableTwoFactorAuthRequiedMutation,
  useMySettingsQuery,
  useOrganizationMembersNotEnableTwoFactorAuthQuery,
  useOrganizationQuery,
} from '../../../../../../../graphql/api/API';
import { LoginUserContext } from '../../../../../../../modules/auth/LoginUserContext';
import Avatar from '../../../../../../presentational/atoms/avatar';
import Button from '../../../../../../presentational/atoms/button';
import Link from '../../../../../../presentational/atoms/link';
import Typography, { TypographyType } from '../../../../../../presentational/atoms/typography';
import CheckIcon from '../../../../../../presentational/molecules/image-icon/check';
import HelpIcon from '../../../../../../presentational/molecules/image-icon/help';
import Modal from '../../../../../../presentational/molecules/modal';
import Table from '../../../../../../presentational/molecules/table';
import type { IThemePart } from '../../../../../../theme';
import SettingsElement from '../../../../organisms/settings-element';

const Container = styled.View`
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
`;

interface ITwoFactorAuthRequiredEnableModalProps {
  organization: Organization;
  closeModal: () => void;
}

const TwoFactorAuthRequiredEnableModal = (props: ITwoFactorAuthRequiredEnableModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const { data, loading } = useMySettingsQuery({ nextFetchPolicy: 'network-only' });
  const [enableTwoFactorAuthRequied] = useEnableTwoFactorAuthRequiedMutation();

  if (loading) {
    return <></>;
  }

  return (
    <>
      <View style={{ marginVertical: 10 }}>
        <Typography variant={TypographyType.Normal}>
          2要素認証の必須設定を有効にしますか？
        </Typography>
        {!data!.mySettings!.twoFactorAuthEnabled && (
          <Typography
            variant={TypographyType.Description}
            style={{ color: themeContext.colors.error }}>
            ご自身が2要素認証を有効にしてからでないと、必須設定は行えません。
          </Typography>
        )}
      </View>
      <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1 }}>
        <Button
          text={'有効にする'}
          style={{ minWidth: 100, marginRight: 10, marginVertical: 10 }}
          isDisabled={!data!.mySettings!.twoFactorAuthEnabled}
          onPress={async () => {
            await enableTwoFactorAuthRequied({
              variables: {
                id: props.organization!.id!,
                input: {
                  versionNo: props.organization!.versionNo!,
                },
              },
            });
            props.closeModal();
          }}
        />
        <Button
          text={'キャンセル'}
          style={{
            minWidth: 100,
            marginRight: 10,
            marginVertical: 10,
            backgroundColor: 'transparent',
          }}
          textStyle={{ color: themeContext.colors.primary }}
          disableValidate={true}
          onPress={() => {
            props.closeModal();
          }}
        />
      </View>
    </>
  );
};
interface ITwoFactorAuthRequiredDisableModalProps {
  organization: Organization;
  closeModal: () => void;
}

const TwoFactorAuthRequiredDisableModal = (props: ITwoFactorAuthRequiredDisableModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [disableTwoFactorAuthRequied] = useDisableTwoFactorAuthRequiedMutation();

  return (
    <>
      <Typography variant={TypographyType.Normal}>2要素認証の必須設定を無効にしますか？</Typography>
      <View style={{ marginVertical: 10 }}>
        <Typography variant={TypographyType.Description}>
          {`この設定はあとでもう一度有効にすることができます。`}
        </Typography>
      </View>
      <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1 }}>
        <Button
          text={'無効にする'}
          style={{ minWidth: 100, marginRight: 10, marginVertical: 10 }}
          onPress={async () => {
            await disableTwoFactorAuthRequied({
              variables: {
                id: props.organization!.id!,
                input: {
                  versionNo: props.organization!.versionNo!,
                },
              },
            });
            props.closeModal();
          }}
        />
        <Button
          text={'キャンセル'}
          style={{
            minWidth: 100,
            marginRight: 10,
            marginVertical: 10,
            backgroundColor: 'transparent',
          }}
          textStyle={{ color: themeContext.colors.primary }}
          disableValidate={true}
          onPress={() => {
            props.closeModal();
          }}
        />
      </View>
    </>
  );
};

const OrganizationTwoFactorAuthSettingPage = () => {
  const [loginUser] = useContext(LoginUserContext);

  const { loading, data } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId!,
    },
    fetchPolicy: 'network-only',
  });

  const { loading: membersLoading, data: membersData } =
    useOrganizationMembersNotEnableTwoFactorAuthQuery({
      variables: {
        organizationId: loginUser!.organizationId!,
      },
      fetchPolicy: 'network-only',
    });

  if (loading || membersLoading) {
    return <></>;
  }

  return (
    <Container>
      <SettingsElement
        title={`2要素認証の必須設定`}
        titleWidth={300}
        changeText={
          data?.organization?.requiredTwoFactorAuth
            ? '必須設定を無効にする'
            : '必須設定を有効にする'
        }
        modal={(closeModal) =>
          data?.organization?.requiredTwoFactorAuth ? (
            <TwoFactorAuthRequiredDisableModal
              organization={data!.organization!}
              closeModal={closeModal}
            />
          ) : (
            <TwoFactorAuthRequiredEnableModal
              organization={data!.organization!}
              closeModal={closeModal}
            />
          )
        }>
        <Typography variant={TypographyType.Normal}>
          {data?.organization?.requiredTwoFactorAuth ? '有効' : '無効'}
        </Typography>
        <Typography variant={TypographyType.Description}>
          {`2要素認証の必須設定を行うと、組織に所属するメンバーに対して`}
          {`${'\n'}2要素認証の設定を強制することができるようになります。`}
          {`${'\n'}必須設定を有効にした場合、2要素認証を設定していないメンバーは`}
          {`${'\n'}その場で2要素認証の設定を行わない限り、タイムデザイナーの操作を`}
          {`${'\n'}行うことができなくなります。`}
          {`${'\n'}`}
          <Link
            isExternalLink
            path="https://manual.timedesigner.com/v1/2-5">
            <HelpIcon size={14} /> ヘルプを確認する
          </Link>
        </Typography>
      </SettingsElement>
      <Typography variant={TypographyType.ElementiTitle}>
        まだ2要素認証を設定していないメンバー一覧
      </Typography>
      <View style={{ marginTop: 10 }}>
        {(membersData?.organizationMembersNotEnableTwoFactorAuth?.length ?? 0) > 0 ? (
          <Table
            rowData={membersData!.organizationMembersNotEnableTwoFactorAuth!.map((member) => ({
              メンバー: (
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <Avatar size={30} name={member!.name!} imageUrl={member!.profileImageUrl} />
                  <View
                    style={{ flexDirection: 'column', justifyContent: 'center', marginLeft: 10 }}>
                    <Typography variant={TypographyType.Normal} style={{ fontSize: 13 }}>
                      {member!.name}
                    </Typography>
                    <Typography variant={TypographyType.Description} style={{ fontSize: 13 }}>
                      {member!.mailAddress}
                    </Typography>
                  </View>
                </View>
              ),
              rowStyle: { width: '400px', maxWidth: '400px' },
            }))}
          />
        ) : (
          <Typography variant={TypographyType.Normal}>
            <CheckIcon on={true} size={18} /> すべてのメンバーの2要素認証設定が完了しています。
          </Typography>
        )}
      </View>
    </Container>
  );
};

export default OrganizationTwoFactorAuthSettingPage;
