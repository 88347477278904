import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { Range, getTrackBackground } from 'react-range';
import Typography, { TypographyType } from '../typography';

interface IProgressSliderProps {
  value: number;
  onChange: (value: number) => void;
  readonly?: boolean;
}

const ProgressSlider = React.memo((props: IProgressSliderProps) => {
  const [values, setValues] = useState([props.value || 0]);

  useEffect(() => {
    setValues([props.value]);
  }, [props.value]);

  return (
    <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
      <Typography
        variant={TypographyType.Normal}
        style={{ width: 64, alignItems: 'center', justifyContent: 'center' }}>
        {values[0]} %
      </Typography>
      <Range
        step={5}
        min={0}
        max={100}
        values={values}
        onChange={(values) => setValues(values)}
        onFinalChange={(values) => props.onChange(values[0])}
        disabled={props.readonly === true}
        renderMark={({ props, index }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '10px',
              width: '3px',
              backgroundColor: index * 5 < values[0] ? '#99dcff' : '#f2f2f2',
            }}
          />
        )}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: '20px',
              display: 'flex',
              width: '100%',
            }}>
            <div
              ref={props.ref}
              style={{
                height: '5px',
                width: '100%',
                borderRadius: '4px',
                background: getTrackBackground({
                  values: values,
                  colors: ['#99dcff', '#f8f8f8'],
                  min: 0,
                  max: 100,
                }),
                alignSelf: 'center',
              }}>
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '21px',
              width: '21px',
              borderRadius: '4px',
              backgroundColor: '#FFF',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '0px 2px 6px #AAA',
            }}>
            <div
              style={{
                height: '10px',
                width: '3px',
                backgroundColor: isDragged ? '#99dcff' : '#f8f8f8',
              }}
            />
          </div>
        )}
      />
    </View>
  );
});

export default ProgressSlider;
