import React, { useContext, useState } from 'react';
import { Text, TouchableOpacity } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import Ripple from 'react-native-material-ripple';
import { IStyleTheme, IThemePart } from '../../../../theme';
import { useHistory, useLocation } from 'react-router';
import ColorUtil from '../../../../../util/ColorUtil';
import { IRouteItem } from '../../../../../routes/RouteItem';
import Icon from '../../../../presentational/atoms/icon';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import ToolTip from 'react-portal-tooltip';
import {
  useUnreadAnnouncementCountQuery,
  useUnreadNotificationCountQuery,
} from '../../../../../graphql/api/API';

interface IContainerProps extends IStyleTheme {
  showOnlyIcon: boolean;
  depth: number;
}

const Container = styled.View<IContainerProps>`
  width: ${(props: IContainerProps) => (props.showOnlyIcon ? '60px' : '100%')};
  height: 44px;
  padding: 6px 3px;
  margin-left: ${(props: IContainerProps) =>
    props.showOnlyIcon ? '0' : `${props.depth <= 1 ? 10 : props.depth * 10}px`};
  display: flex;
  flex-direction: row;
  align-item: center;
  justify-content: ${(props: IContainerProps) => (props.showOnlyIcon ? 'center' : 'flex-start')};
  transition: all 0.4s;
`;

interface ITextContainerProps extends IStyleTheme {
  showOnlyIcon: boolean;
}

const TextContainer = styled.View<ITextContainerProps>`
  opacity: ${(props: ITextContainerProps) => (props.showOnlyIcon ? '0' : '1')};
  width: ${(props: ITextContainerProps) => (props.showOnlyIcon ? '0' : 'auto')};
  display: ${(props: ITextContainerProps) => (props.showOnlyIcon ? 'none' : 'flex')};
  align-item: center;
  justify-content: center;
`;

interface IIConContainerProps extends IStyleTheme {
  depth: number;
}

const IconContainer = styled.View<IIConContainerProps>`
  width: ${(props: IIConContainerProps) =>
    props.depth <= 1 ? '32px' : `${32 - props.depth * 10}px`};
  height: ${(props: IIConContainerProps) =>
    props.depth <= 1 ? '32px' : `${32 - props.depth * 10}px`};
  margin: ${(props: IIConContainerProps) => (props.depth <= 1 ? '0' : `${props.depth * 5}px`)};
  display: flex;
  align-item: center;
  justify-content: center;
`;

interface IInnerProps {
  route: IRouteItem;
  showOnlyIcon: boolean;
  depth: number;
}

const SideMenuItemInner: React.FC<IInnerProps> = (props: IInnerProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const history = useHistory();
  const { pathname, search } = useLocation();

  const { loading, data } = useUnreadAnnouncementCountQuery({
    fetchPolicy: 'network-only',
  });
  const { loading: loadingNotificationCount, data: dataNotificationCount } =
    useUnreadNotificationCountQuery();

  let isActive =
    (props.route.path &&
      (props.route.path as any).pathname &&
      pathname.startsWith((props.route.path as any).pathname)) ||
    (props.route.path && props.route.path && pathname.startsWith(props.route.path.toString()));
  if (props.route.id === 'search-team') {
    if (isActive && search !== '?create=true') {
      isActive = true;
    } else {
      isActive = false;
    }
  }
  if (props.route.id === 'create-team') {
    if (isActive && search === '?create=true') {
      isActive = true;
    } else {
      isActive = false;
    }
  }

  return (
    <>
      <Ripple
        rippleColor={ColorUtil.lignten(themeContext.colors.primary, 20)}
        rippleSize={400}
        rippleDuration={400}
        style={{ cursor: 'pointer' }}
        onPress={() => {
          if (props.route.onPress) {
            props.route.onPress();
          }
          if (props.route.menuPath) {
            history.push(props.route.menuPath); // 本当はRippleの外側にLinkタグを入れれば良いのだが、なぜかその構成だと画面遷移時にリロードが発生してしまうため、こちらでHistoryを操作して画面リロードなしの遷移を発生させている
            return;
          }
          if (props.route.path) {
            history.push(props.route.path); // 本当はRippleの外側にLinkタグを入れれば良いのだが、なぜかその構成だと画面遷移時にリロードが発生してしまうため、こちらでHistoryを操作して画面リロードなしの遷移を発生させている
          }
        }}>
        <Container showOnlyIcon={props.showOnlyIcon} depth={props.depth}>
          {props.route.icon && (
            <IconContainer depth={props.depth}>
              {props.route.icon && (
                <TouchableOpacity
                  style={{
                    opacity: isActive ? 1 : 0.5,
                  }}
                  onPress={() => {
                    if (props.route.onPress) {
                      props.route.onPress();
                    }
                  }}>
                  {props.route.iconComponent}
                </TouchableOpacity>
              )}
            </IconContainer>
          )}
          <TextContainer
            showOnlyIcon={props.showOnlyIcon}
            style={{
              paddingHorizontal: props.route.icon ? 0 : 10,
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <Typography
              variant={isActive ? TypographyType.MenuItemActive : TypographyType.MenuItemNonActive}
              ellipsis={true}
              style={{
                color: isActive ? '#EFEFEF' : '#909090',
                fontSize: props.depth <= 1 ? 14 : 14 - props.depth,
                maxWidth: 120,
              }}>
              {props.route.name}
            </Typography>
            {!loadingNotificationCount &&
              dataNotificationCount?.unreadNotificationCount !== undefined &&
              dataNotificationCount?.unreadNotificationCount !== null &&
              dataNotificationCount!.unreadNotificationCount > 0 && (
                <Typography
                  variant={
                    isActive ? TypographyType.MenuItemActive : TypographyType.MenuItemNonActive
                  }
                  ellipsis={true}
                  style={{
                    color: '#EFEFEF',
                    fontSize: props.depth <= 1 ? 14 : 14 - props.depth,
                    marginLeft: 3,
                    paddingTop: 1,
                    backgroundColor: '#c45e5e',
                    textAlign: 'center',
                    width: 24,
                    height: 24,
                    borderRadius: 12,
                  }}>
                  {dataNotificationCount!.unreadNotificationCount >= 10
                    ? '+9'
                    : dataNotificationCount!.unreadNotificationCount}
                </Typography>
              )}
            {!loading &&
              data?.unreadAnnouncementCount !== undefined &&
              data?.unreadAnnouncementCount !== null &&
              data!.unreadAnnouncementCount > 0 && (
                <Typography
                  variant={
                    isActive ? TypographyType.MenuItemActive : TypographyType.MenuItemNonActive
                  }
                  ellipsis={true}
                  style={{
                    color: '#EFEFEF',
                    fontSize: props.depth <= 1 ? 14 : 14 - props.depth,
                    paddingTop: 1,
                    marginLeft: 3,
                    backgroundColor: themeContext.colors.primary,
                    textAlign: 'center',
                    width: 24,
                    height: 24,
                    borderRadius: 12,
                  }}>
                  {data!.unreadAnnouncementCount >= 10 ? '+9' : data!.unreadAnnouncementCount}
                </Typography>
              )}
          </TextContainer>
        </Container>
      </Ripple>
    </>
  );
};

interface IProps {
  route: IRouteItem;
  showOnlyIcon: boolean;
  depth?: number;
}

const SideMenuInboxItem: React.FC<IProps> = (props: IProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [isHover, setHover] = useState(false);
  if (props.showOnlyIcon) {
    return (
      <>
        <div
          id={`tooltip-sidemenu-${props.route.id}`}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}>
          <SideMenuItemInner
            route={props.route}
            showOnlyIcon={props.showOnlyIcon}
            depth={props.depth || 0}
          />
        </div>
        <ToolTip
          active={isHover}
          position="right"
          arrow="center"
          parent={`#tooltip-sidemenu-${props.route.id}`}>
          <Text>{props.route.name}</Text>
        </ToolTip>
      </>
    );
  }
  return (
    <SideMenuItemInner
      route={props.route}
      showOnlyIcon={props.showOnlyIcon}
      depth={props.depth || 0}
    />
  );
};

export default React.memo(SideMenuInboxItem);
