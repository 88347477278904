import React, { useContext, useEffect, useState } from 'react';
import ListMenuApp from '../../../../organisms/list-menu-app';
import { LoginUserContext } from '../../../../../../../modules/auth/LoginUserContext';
import { Plan, useOrganizationQuery } from '../../../../../../../graphql/api/API';
import CustomScrollView from '../../../../../../presentational/atoms/custom-scroll-view';
import LoginUtil from '../../../../../../../modules/auth/LoginUtil';
import PlanNotAllowedView from '../../../../organisms/plan-not-allowed-view';
import ProjectDataExportPage from './project-export';
import TaskDataExportPage from './task-export';

const AppTeamExportsPage = () => {
  const [loginUser, _] = useContext(LoginUserContext);
  const [notAllowedPlan, setNotAllowedPlan] = useState(false);
  const teamId = LoginUtil.getTeamIdFromURl();
  const { loading, data, error } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId,
    },
  });

  // Basicプランの場合には使用できないようにする
  useEffect(() => {
    setTimeout(() => {
      if (data?.organization?.plan.code === Plan.Basic) {
        setNotAllowedPlan(true);
      }
    }, 1000);
  }, [data]);

  if (loading) {
    return <></>;
  }

  return (
    <PlanNotAllowedView
      isNotAllowedPlan={notAllowedPlan}
      style={{ height: 'calc(100vh)', width: '100%' }}>
      <CustomScrollView
        style={{
          height: 'calc(100vh - 57px)',
        }}>
        <ListMenuApp
          headerTitle={`データエクスポート`}
          resizeCookeName="ORGANIZATION_EXPORT_FILE_LIST_WIDTH"
          routes={[
            {
              name: 'プロジェクト',
              path: `/app/:organizationId/${teamId}/export-file/export-projects/`,
              component: ProjectDataExportPage,
            },
            {
              name: 'タスク',
              path: `/app/:organizationId/${teamId}/export-file/export-tasks/`,
              component: TaskDataExportPage,
            },
          ]}
        />
      </CustomScrollView>
    </PlanNotAllowedView>
  );
};

export default React.memo(AppTeamExportsPage);
