import 'expo-dev-client';
import moment from 'moment-timezone';
import React, { Profiler } from 'react';
import Root from './src/components/root/Root';
import Theme from './src/components/theme';
import * as Sentry from 'sentry-expo';
import Constants from 'expo-constants';
import * as Localization from 'expo-localization';
import { Platform } from 'react-native';
import MomentLocaleSetup from './MomentLocale';
import * as WebBrowser from 'expo-web-browser';

let locale = 'ja';
if (Platform.OS === 'web') {
  locale = window.navigator.language === 'ja' ? 'ja' : 'en';
} else {
  locale = Localization.locale.startsWith('ja-') ? 'ja' : 'en';
}

moment.locale(locale); // クライント端末の地域言語に合わせる
moment.tz.setDefault(moment.tz.guess()); // クライアント端末のタイムゾーンに合わせる

Sentry.init({
  dsn: Constants.manifest!.extra!.sentryDsn,
  enableInExpoDevelopment: false,
  debug: false,
  enableNative: false,
});

// electron経由で window.alert を使用すると、デフォルトでelectronのアイコンが入ったダイアログが表示されてしまうため、
// electron側でアイコンを再設定するようにしている。
if (window.hasOwnProperty('electron')) {
  window.alert = (window as any).electron.alert;
  window.addEventListener('contextmenu', (e) => {
    e.preventDefault();
    (window as any).electron.openContextmenu();
  });
}

WebBrowser.maybeCompleteAuthSession();

const App = () => {
  return (
    <Theme>
      {/* スマホアプリだと、moment.localeが何故か効かないため、別途日本語用のロケールファイルを取り込むようにしている */}
      {locale === 'ja' && <MomentLocaleSetup />}
      <Profiler id="Navigation" onRender={() => {}}>
        <Root />
      </Profiler>
    </Theme>
  );
};

export default () => <App />;
