import { DocumentNode, OperationVariables, useApolloClient } from '@apollo/client';
import React from 'react';

export function useLazyQueryPromise<TData = any, TVariables = OperationVariables>(
  query: DocumentNode
) {
  const client = useApolloClient();
  return React.useCallback(
    (variables: TVariables) =>
      client.query<TData, TVariables>({
        query: query,
        variables: variables,
        fetchPolicy: 'network-only',
      }),
    [client]
  );
}
