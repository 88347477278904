import * as Cookies from 'js-cookie';
import moment from 'moment-timezone';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ActivityIndicator,
  Animated,
  Linking,
  type StyleProp,
  Text,
  TouchableOpacity,
  View,
  type ViewProps,
  useWindowDimensions,
} from 'react-native';
//@ts-ignore
import base64 from 'react-native-base64';
import { PanGestureHandler, State } from 'react-native-gesture-handler';
import { useHistory } from 'react-router';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import useInterval from 'use-interval';
import {
  CalendarEventDocument,
  type CalendarWorkingHistory,
  CalendarWorkingHistoryDocument,
  type CalendarWorkingSchedule,
  CalendarWorkingScheduleDocument,
  type ClosingSetting,
  type GoogleCalendar,
  type GoogleCalendarEvent,
  GoogleCalendarEventVisivility,
  LatestWorkingHistoryDocument,
  type Member,
  MemberInvitationDocument,
  MemberStatus,
  ProjectWorkingTimeSecDocument,
  TaskDocument,
  TaskWorkingHistoriesForSummaryDocument,
  TaskWorkingHistorySummaryGroupByAssineeDocument,
  WorkingHistory,
  WorkingHistoryDocument,
  type WorkingHistoryForCalendar,
  WorkingSchedule,
  WorkingScheduleDocument,
  type WorkingScheduleForCalendar,
  WorkingScheduleForSummaryDocument,
  useClosingSettingsQuery,
  useCreateWorkingHistoryByCalendarEventMutation,
  useCreateWorkingHistoryMutation,
  useCreateWorkingScheduleMutation,
  useDeleteGoogleCalendarEventMutation,
  useDeleteWorkingHistoryMutation,
  useDeleteWorkingScheduleMutation,
  useFetchSyncRegisterWorkingHistoriesTargetGoogleCalendarsQuery,
  useFetchSyncRegisterWorkingSchedulesTargetGoogleCalendarsQuery,
  useGoogleCalendarEventsQuery,
  useGoogleCalendarsQuery,
  useMeQuery,
  useMemberQuery,
  useMyGoogleAccountQuery,
  useMyGoogleCalendarsQuery,
  useMySettingsQuery,
  useOrganizationMembersQuery,
  useStartTaskMutation,
  useStopTaskMutation,
  useSyncRegisterWorkingHistoriesFromGoogleCalendarsMutation,
  useSyncRegisterWorkingSchedulesFromGoogleCalendarsMutation,
  useUpdateGoogleCalendarEventMutation,
  useUpdateWorkingHistoryMutation,
  useUpdateWorkingScheduleMutation,
  useWorkingHistoriesSpecifyTermForCalendarQuery,
  useWorkingHistoriesSpecifyTermQuery,
  useWorkingSchedulesSpecifyTermForCalendarQuery,
  useWorkingSchedulesSpecifyTermQuery,
} from '../../../../../graphql/api/API';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import ColorUtil from '../../../../../util/ColorUtil';
import DateUtil from '../../../../../util/DateUtil';
import HashIdUtil from '../../../../../util/HashIdUtil';
import OrganizationUtil from '../../../../../util/OrganizationUtil';
import StringUtil from '../../../../../util/StringUtil';
import TimeUtil from '../../../../../util/TimeUtil';
import UrlUtil from '../../../../../util/UrlUtil';
import Avatar from '../../../../presentational/atoms/avatar';
import Button from '../../../../presentational/atoms/button';
import Checkbox from '../../../../presentational/atoms/checkbox';
import CustomScrollView from '../../../../presentational/atoms/custom-scroll-view';
import EditableText, { type IDateRange } from '../../../../presentational/atoms/editable-text';
import useHover from '../../../../presentational/atoms/editable-text/use-hover';
import Form from '../../../../presentational/atoms/form';
import Input from '../../../../presentational/atoms/input';
import Link from '../../../../presentational/atoms/link';
import VirtualizedFlatList from '../../../../presentational/atoms/list2/virtualized-flat-list';
import MultiPickerFilter from '../../../../presentational/atoms/multi-picker-filter';
import Spinner from '../../../../presentational/atoms/spinner';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import ArrowLeftIcon from '../../../../presentational/molecules/image-icon/arrow-left';
import CalendarIcon from '../../../../presentational/molecules/image-icon/calendar';
import CaretDownIcon from '../../../../presentational/molecules/image-icon/caret-down';
import CaretUpIcon from '../../../../presentational/molecules/image-icon/caret-up';
import CopyIcon from '../../../../presentational/molecules/image-icon/copy';
import DeleteIcon from '../../../../presentational/molecules/image-icon/delete';
import EditIcon from '../../../../presentational/molecules/image-icon/edit';
import FileMoveIcon from '../../../../presentational/molecules/image-icon/file-move';
import LeftIcon from '../../../../presentational/molecules/image-icon/left';
import OpenLinkIcon from '../../../../presentational/molecules/image-icon/open-in-new';
import PlayIcon from '../../../../presentational/molecules/image-icon/play';
import ReloadIcon from '../../../../presentational/molecules/image-icon/reload';
import RightIcon from '../../../../presentational/molecules/image-icon/right';
import StopIcon from '../../../../presentational/molecules/image-icon/stop';
import Modal from '../../../../presentational/molecules/modal';
import SelectButton from '../../../../presentational/molecules/select-button';
import type { IStyleTheme, IThemePart } from '../../../../theme';
import CopyWorkingHistoryModal from '../../../web/organisms/copy-working-history-modal';
import CopyWorkingScheduleModal from '../../../web/organisms/copy-working-schedule-modal';
import CreateGoogleCalendarEventModal from '../../../web/organisms/create-google-calendar-event-modal';
import CreateWorkingHistoryFromCalendarEventModal from '../../../web/organisms/create-working-history-from-calendar-event-modal';
import CreateWorkingHistoryModal from '../../../web/organisms/create-working-history-modal';
import CreateWorkingScheduleFromCalendarEventModal from '../../../web/organisms/create-working-schedule-from-calendar-event-modal';
import CreateWorkingScheduleModal from '../../../web/organisms/create-working-schedule-modal';
import DetailGoogleCalendarEventModal from '../../../web/organisms/detail-google-calendar-event-modal';
import EditGoogleCalendarEventModal from '../../../web/organisms/edit-google-calendar-event-modal';
import EditWorkingHistoryModal from '../../../web/organisms/edit-working-history-modal';
import EditWorkingScheduleModal from '../../../web/organisms/edit-working-schedule-modal';
import ErrorMessageModal from '../../../web/organisms/error-message-modal';
import MoveWorkingHistoryModal from '../../../web/organisms/move-working-history-modal';
import MoveWorkingScheduleModal from '../../../web/organisms/move-working-schedule-modal';

export const CALENDAR_DISPLAY_DATE_RANGE = 'CALENDAR_DISPLAY_DATE_RANGE';
export const CALENDAR_DISPLAY_MEMBERS = 'CALENDAR_DISPLAY_MEMBERS';

const cellHeight = '30px';

const Container = styled.View`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 57px);
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
`;

const TimeColumnSet = styled.View`
  flex-direction: row;
  display: flex;
`;

const TimeMeasureRow = styled.View`
  display: flex;
  flex-direction: row;
`;

const TimeMeasure = styled.View`
  width: 45px;
`;

const DayHeader = styled.View`
  flex: 1;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
`;

const TimeColumnView = styled.View`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 1450px;
  border-top-width: 1px;
  border-left-width: 1px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
`;

interface ITimeCellProps extends IStyleTheme {
  isFirst: boolean;
  isHalfHour: boolean;
}

const TimeCell = styled.TouchableOpacity<ITimeCellProps>`
  height: ${cellHeight};
  display: flex;
  border-bottom-width: 1px;
  border-top-width: ${(props: ITimeCellProps) => (props.isFirst ? '1px' : '0')};
  border-color: ${(props: ITimeCellProps) =>
    props.isHalfHour
      ? ColorUtil.lignten(props.theme.colors.separator, 4)
      : props.theme.colors.separator};
`;

const Header = styled.View`
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
  padding: 0.2rem 1rem;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.4s;
  padding: 10px;
  z-index: 1;
`;

const FilterElementsContainer = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 0.2rem 0;
  margin: 0 0.2rem;
`;

const FilterElements = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

interface IMenuItemProps extends IStyleTheme {
  isActive: boolean;
}

const MenuItem = styled.TouchableOpacity<IMenuItemProps>`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom-width: ${(props: IMenuItemProps) => (props.isActive ? '2px' : '0')};
  border-color: #ffbebe;
  padding: 10px;
`;

interface IGoogleConnectErrorModalProps {
  show: boolean;
  onClose: () => void;
}

const GoogleConnectErrorModal = (props: IGoogleConnectErrorModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const history = useHistory();
  return (
    <Modal
      title={'Googleカレンダーの情報が取得できませんでした'}
      isShow={props.show}
      onClose={() => {
        props.onClose();
      }}>
      <View style={{ marginTop: 10 }}>
        <Typography
          variant={TypographyType.Description}
          style={{ textAlign: 'center', color: themeContext.colors.error }}>
          {`Googleカレンダーの情報取得が出来なくなりました。${'\n'}申し訳ありませんが「Googleアカウント連携」メニューから${'\n'}もう一度Googleアカウントを連携し直してください。`}
        </Typography>
      </View>
      <Form>
        <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
          <Button
            text={'Googleアカウント連携メニューに移動する'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
            }}
            disableValidate={true}
            onPress={() => {
              props.onClose();
              history.push(`/app/${loginUser!.organizationId}/my-settings/google/`);
            }}
          />
        </View>
      </Form>
    </Modal>
  );
};

const Item = styled.View`
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  min-width: 200px;
  max-width: 90vw;
  border-bottom-width: 1px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
  corsor: default;
`;

interface ICreateFromGoogleCalendarModalProps {
  show: boolean;
  startAndEndDateTime: IStartAndEnd;
  selectedCalendarIds: string[];
  onClose: () => void;
}

const CreateFromGoogleCalendarModal = (props: ICreateFromGoogleCalendarModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [startAndEndDateTime, setStartAndEndDateTime] = useState(props.startAndEndDateTime);
  const [selectedCalendarIds, setSelectedCalendarIds] = useState<string[]>([]);
  const [mode, setMode] = useState<'workingHistory' | 'workingSchedule' | null>(null);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const { loading, data } = useGoogleCalendarsQuery({ fetchPolicy: 'network-only' });
  const { loading: loadingWorkingHistoriesTarget, data: dataWorkingHistoriesTarget } =
    useFetchSyncRegisterWorkingHistoriesTargetGoogleCalendarsQuery({
      variables: {
        input: {
          calendarIds: selectedCalendarIds,
          start: startAndEndDateTime.start,
          end: startAndEndDateTime.end,
        },
      },
      skip: mode !== 'workingHistory' || !props.show,
      fetchPolicy: 'network-only',
    });
  const { loading: loadingWorkingSchedulesTarget, data: dataWorkingSchedulesTarget } =
    useFetchSyncRegisterWorkingSchedulesTargetGoogleCalendarsQuery({
      variables: {
        input: {
          calendarIds: selectedCalendarIds,
          start: startAndEndDateTime.start,
          end: startAndEndDateTime.end,
        },
      },
      skip: mode !== 'workingSchedule' || !props.show,
      fetchPolicy: 'network-only',
    });
  const [registerWorkingHistories] = useSyncRegisterWorkingHistoriesFromGoogleCalendarsMutation({
    variables: {
      input: {
        calendarIds: selectedCalendarIds,
        start: startAndEndDateTime.start,
        end: startAndEndDateTime.end,
      },
    },
    update: (cache, result) => {
      cache.modify({
        fields: {
          projectCalendarEvents(existing = [], { storeFieldName }) {
            const newCalendarEvents =
              result.data!.syncRegisterWorkingHistoriesFromGoogleCalendars!.map((data) => {
                return cache.writeQuery({
                  data: data,
                  query: CalendarEventDocument,
                });
              });

            return [...existing, newCalendarEvents];
          },
          workingHistoriesSpecifyTermForCalendar(existing = [], { storeFieldName }) {
            const newWorkingHistories =
              result.data!.syncRegisterWorkingHistoriesFromGoogleCalendars!.map((data) => {
                return cache.writeQuery({
                  data: data,
                  query: WorkingHistoryDocument,
                });
            });

            const targetMemberData = existing.filter((d: any) => d.memberId === loginUser?.id)[0];
            if((targetMemberData?.length ?? 0) === 0){
              return [...existing, {
                memberId: loginUser?.id,
                workingHistories: newWorkingHistories
              }];
            }
            return existing.map((data: any) => {
              if(data.memberId !== loginUser?.id){
                return data;
              }
              return {
                memberId: data.memberId,
                workingHistories: [...targetMemberData.workingHistories, newWorkingHistories]
              }
            })
          },
        },
      });
    },
  });
  const [registerWorkingSchedules] = useSyncRegisterWorkingSchedulesFromGoogleCalendarsMutation({
    variables: {
      input: {
        calendarIds: selectedCalendarIds,
        start: startAndEndDateTime.start,
        end: startAndEndDateTime.end,
      },
    },
    update: (cache, result) => {
      cache.modify({
        fields: {
          projectCalendarEvents(existing = [], { storeFieldName }) {
            const newCalendarEvents =
              result.data!.syncRegisterWorkingSchedulesFromGoogleCalendars!.map((data) => {
                return cache.writeQuery({
                  data: data,
                  query: CalendarEventDocument,
                });
              });

            return [...existing, newCalendarEvents];
          },
          workingSchedulesSpecifyTermForCalendar(existing = [], { storeFieldName }) {
            const newWorkingSchedules =
              result.data!.syncRegisterWorkingSchedulesFromGoogleCalendars!.map((data) => {
                return cache.writeQuery({
                  data: data,
                  query: WorkingScheduleDocument,
                });
            });

            const targetMemberData = existing.filter((d: any) => d.memberId === loginUser?.id)[0];
            if((targetMemberData?.length ?? 0) === 0){
              return [...existing, {
                memberId: loginUser?.id,
                workingSchedules: newWorkingSchedules
              }];
            }              
            return existing.map((data: any) => {
              if(data.memberId !== loginUser?.id){
                return data;
              }
              return {
                memberId: data.memberId,
                workingSchedules: [...targetMemberData.workingSchedules, newWorkingSchedules]
              }
            })
          },
        },
      });
    },
  });
  const calendars = useMemo(() => {
    if (loading) {
      return [];
    }
    return (data?.googleCalendars || []).slice().sort((a, b): any => {
      if (a?.primary) {
        return -1;
      }
      if (a?.myCalendar) {
        if (b?.myCalendar) {
          a!.calendarId! > b!.calendarId!;
        } else {
          return -1;
        }
      }
      return a!.calendarId! > b!.calendarId!;
    });
  }, [loading, data?.googleCalendars]);

  useEffect(() => {
    setMode('workingHistory');
  }, [props.show]);

  useEffect(() => {
    setSelectedCalendarIds(props.selectedCalendarIds);
  }, [props.selectedCalendarIds]);

  useEffect(() => {
    setStartAndEndDateTime(props.startAndEndDateTime);
  }, [props.startAndEndDateTime]);

  return (
    <Modal
      title={'Googleカレンダーから自動登録する'}
      isShow={props.show}
      onClose={() => {
        props.onClose();
      }}>
      <View style={{ flexDirection: 'row', marginBottom: 10 }}>
        <MenuItem
          isActive={mode === 'workingHistory'}
          onPress={() => {
            setMode('workingHistory');
          }}>
          <Typography
            variant={TypographyType.Normal}
            style={{
              fontSize: 14,
              lineHeight: 18,
              textAlign: 'center',
              fontWeight: mode === 'workingHistory' ? '600' : undefined,
            }}>
            {`作業履歴を登録する`}
          </Typography>
        </MenuItem>
        <MenuItem
          isActive={mode === 'workingSchedule'}
          onPress={() => {
            setMode('workingSchedule');
          }}>
          <Typography
            variant={TypographyType.Normal}
            style={{
              fontSize: 14,
              lineHeight: 18,
              textAlign: 'center',
              fontWeight: mode === 'workingSchedule' ? '600' : undefined,
            }}>
            {`作業予定を登録する`}
          </Typography>
        </MenuItem>
      </View>
      <Typography
        variant={TypographyType.Description}
        style={{ fontSize: 14, lineHeight: 18, marginBottom: 20 }}>
        {`カレンダー連携コードを設定したGoogleカレンダーの予定から${
          mode === 'workingHistory' ? '作業履歴' : '作業予定'
        }を自動登録できます。${'\n'}自動登録するためには、Googleカレンダーの予定に連携コードを設定する必要があります。${'\n'}詳細は、右記の「使い方について確認する」のリンクをご参照ください。`}
      </Typography>
      <View style={{ zIndex: 2, flexDirection: 'row', justifyContent: 'space-between' }}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Typography
            variant={TypographyType.SubTitle}
            style={{ textAlign: 'center', fontSize: 14 }}>
            {`対象期間`}
          </Typography>
          <DateRangeHeader
            startAndEndDateTime={startAndEndDateTime}
            setStartAndEndDateTime={setStartAndEndDateTime}
          />
        </View>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <OpenLinkIcon size={24} containerStyle={{ marginRight: 5 }} />
          <Link
            path={
              mode === 'workingHistory'
                ? 'https://manual.timedesigner.com/v1/google-7'
                : 'https://manual.timedesigner.com/v1/google-9'
            }
            isExternalLink={true}>
            <Typography
              variant={TypographyType.Normal}
              style={{ fontSize: 16, color: themeContext.colors.link }}>
              {`使い方について確認する`}
            </Typography>
          </Link>
        </View>
      </View>
      <View
        style={{
          flexDirection: 'row',
          minWidth: 300,
        }}>
        <View style={{ borderWidth: 1, borderColor: themeContext.colors.separator }}>
          <View style={{ backgroundColor: themeContext.colors.subHeader, minWidth: 300 }}>
            <Typography
              variant={TypographyType.SubTitle}
              style={{
                color: '#FFFFFF',
                textAlign: 'center',
                fontSize: 16,
              }}>
              対象のカレンダー
            </Typography>
          </View>
          <CustomScrollView
            style={{
              flexDirection: 'column',
              maxHeight: 300,
              padding: 10,
              maxWidth: 400,
              minWidth: 200,
            }}>
            <Form>
              {calendars.map((calendar, i) => {
                const isSelected = selectedCalendarIds.indexOf(calendar!.calendarId) !== -1;
                return (
                  <View style={{ flexDirection: 'row', marginVertical: 5 }} key={i}>
                    <Checkbox
                      size={24}
                      value={isSelected}
                      onValueChange={(value) => {
                        if (value) {
                          const newSelectedCalendarIds = Array.from(
                            new Set([...selectedCalendarIds, calendar!.calendarId])
                          );
                          setSelectedCalendarIds(newSelectedCalendarIds);
                        } else {
                          const newSelectedCalendarIds = selectedCalendarIds.filter(
                            (calendarId) => calendarId !== calendar!.calendarId
                          );
                          setSelectedCalendarIds(newSelectedCalendarIds);
                        }
                      }}
                      color={
                        isSelected ? calendar!.backgroundColor : themeContext.colors.description
                      }></Checkbox>
                    <Typography
                      variant={TypographyType.Normal}
                      style={{
                        color: isSelected
                          ? calendar!.backgroundColor
                          : themeContext.colors.description,
                        marginLeft: 10,
                        textDecorationLine: isSelected ? undefined : 'line-through',
                      }}>
                      {calendar!.name}
                    </Typography>
                  </View>
                );
              })}
            </Form>
          </CustomScrollView>
        </View>
        {mode === 'workingHistory' ? (
          <VirtualizedFlatList
            style={{
              height: 'auto',
              maxHeight: 300,
              minWidth: 300,
              maxWidth: 400,
              borderWidth: 1,
              borderColor: themeContext.colors.separator,
              marginLeft: 10,
            }}
            items={
              dataWorkingHistoriesTarget?.fetchSyncRegisterWorkingHistoriesTargetGoogleCalendars ??
              []
            }
            headerElement={
              <View style={{ backgroundColor: themeContext.colors.subHeader, minWidth: 300 }}>
                <Typography
                  variant={TypographyType.SubTitle}
                  style={{
                    color: '#FFFFFF',
                    textAlign: 'center',
                    fontSize: 16,
                  }}>
                  登録対象の予定一覧
                </Typography>
              </View>
            }
            listEmptyElment={(loading) => {
              if (loadingWorkingHistoriesTarget) {
                return (
                  <View style={{ width: 200 }}>
                    <ActivityIndicator
                      size={'small'}
                      color={themeContext.colors.primary}
                      style={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: 30,
                        bottom: 0,
                        zIndex: 2,
                      }}
                    />
                  </View>
                );
              }
              return (
                <Item>
                  <Typography
                    variant={TypographyType.Description}
                    ellipsis={true}
                    style={{ maxWidth: 300 }}>
                    {'登録対象の予定はありませんでした'}
                  </Typography>
                </Item>
              );
            }}
            renderItem={(item) => {
              const event = item as GoogleCalendarEvent;
              return (
                <Item>
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      flex: 1,
                    }}>
                    <Typography
                      variant={TypographyType.Normal}
                      ellipsis={true}
                      style={{ maxWidth: 300 }}>
                      {event.title ||
                        (event.visivility === GoogleCalendarEventVisivility.Private ||
                        event.visivility === GoogleCalendarEventVisivility.Confidential
                          ? '予定あり'
                          : '(タイトルなし)')}
                    </Typography>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                      <Typography
                        variant={TypographyType.Normal}
                        style={{
                          fontSize: 13,
                          textAlign: 'center',
                          lineHeight: 17,
                          marginLeft: 20,
                        }}>
                        {moment(event.startDateTime).format('YYYY/MM/DD HH:mm')}
                      </Typography>
                      <Typography
                        variant={TypographyType.Normal}
                        style={{ fontSize: 13, textAlign: 'center' }}>
                        〜
                      </Typography>
                      <Typography
                        variant={TypographyType.Normal}
                        style={{ fontSize: 13, textAlign: 'center', lineHeight: 17 }}>
                        {moment(event.endDateTime).format('HH:mm')}
                      </Typography>
                      <OpenLinkIcon
                        size={14}
                        containerStyle={{ marginLeft: 10 }}
                        onPress={(item) => {
                          Linking.openURL(
                            `https://www.google.com/calendar/event?eid=${base64
                              .encode(event.id + ' ' + event.calendarId)
                              .replace('==', '')}`
                          );
                        }}
                      />
                    </View>
                  </View>
                </Item>
              );
            }}
            getKey={(event: any) => event.id}
            itemHeight={43}
          />
        ) : (
          <VirtualizedFlatList
            style={{
              height: 'auto',
              maxHeight: 300,
              minWidth: 300,
              maxWidth: 400,
              borderWidth: 1,
              borderColor: themeContext.colors.separator,
              marginLeft: 10,
            }}
            items={
              dataWorkingSchedulesTarget?.fetchSyncRegisterWorkingSchedulesTargetGoogleCalendars ??
              []
            }
            headerElement={
              <View style={{ backgroundColor: themeContext.colors.subHeader, minWidth: 300 }}>
                <Typography
                  variant={TypographyType.SubTitle}
                  style={{
                    color: '#FFFFFF',
                    textAlign: 'center',
                    fontSize: 16,
                  }}>
                  登録対象の予定一覧
                </Typography>
              </View>
            }
            listEmptyElment={(loading) => {
              if (loadingWorkingSchedulesTarget) {
                return (
                  <View style={{ width: 200 }}>
                    <ActivityIndicator
                      size={'small'}
                      color={themeContext.colors.primary}
                      style={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: 30,
                        bottom: 0,
                        zIndex: 2,
                      }}
                    />
                  </View>
                );
              }
              return (
                <Item>
                  <Typography
                    variant={TypographyType.Description}
                    ellipsis={true}
                    style={{ maxWidth: 300 }}>
                    {'登録対象の予定はありませんでした'}
                  </Typography>
                </Item>
              );
            }}
            renderItem={(item) => {
              const event = item as GoogleCalendarEvent;
              return (
                <Item>
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      flex: 1,
                    }}>
                    <Typography
                      variant={TypographyType.Normal}
                      ellipsis={true}
                      style={{ maxWidth: 300 }}>
                      {event.title ||
                        (event.visivility === GoogleCalendarEventVisivility.Private ||
                        event.visivility === GoogleCalendarEventVisivility.Confidential
                          ? '予定あり'
                          : '(タイトルなし)')}
                    </Typography>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                      <Typography
                        variant={TypographyType.Normal}
                        style={{
                          fontSize: 13,
                          textAlign: 'center',
                          lineHeight: 17,
                          marginLeft: 20,
                        }}>
                        {moment(event.startDateTime).format('YYYY/MM/DD HH:mm')}
                      </Typography>
                      <Typography
                        variant={TypographyType.Normal}
                        style={{ fontSize: 13, textAlign: 'center' }}>
                        〜
                      </Typography>
                      <Typography
                        variant={TypographyType.Normal}
                        style={{ fontSize: 13, textAlign: 'center', lineHeight: 17 }}>
                        {moment(event.endDateTime).format('HH:mm')}
                      </Typography>
                      <OpenLinkIcon
                        size={14}
                        containerStyle={{ marginLeft: 10 }}
                        onPress={(item) => {
                          Linking.openURL(
                            `https://www.google.com/calendar/event?eid=${base64
                              .encode(event.id + ' ' + event.calendarId)
                              .replace('==', '')}`
                          );
                        }}
                      />
                    </View>
                  </View>
                </Item>
              );
            }}
            getKey={(event: any) => event.id}
            itemHeight={43}
          />
        )}
      </View>

      <Form>
        <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
          {mode === 'workingHistory' ? (
            <Button
              text={'作業履歴を登録する'}
              style={{
                minWidth: 100,
                marginRight: 10,
                marginVertical: 10,
              }}
              disableValidate={true}
              onPress={async () => {
                await registerWorkingHistories();
                props.onClose();
              }}
            />
          ) : (
            <Button
              text={'作業予定を登録する'}
              style={{
                minWidth: 100,
                marginRight: 10,
                marginVertical: 10,
              }}
              disableValidate={true}
              onPress={async () => {
                await registerWorkingSchedules();
                props.onClose();
              }}
            />
          )}
        </View>
      </Form>
    </Modal>
  );
};

interface CalendarHoverEventInfo {
  event: ICalendarEventForDisplay;
  x: number;
  y: number;
}

const CalendarEventHoverContext = createContext([
  null as CalendarHoverEventInfo | null,
  (value: CalendarHoverEventInfo) => {},
]);

const CalendarEventHoverContextProvider = (props: any) => {
  const [event, setEvent] = useState<CalendarHoverEventInfo | null>(null);
  return (
    <CalendarEventHoverContext.Provider value={[event, setEvent]}>
      {props.children}
    </CalendarEventHoverContext.Provider>
  );
};

interface CalendarContextMenuInfo {
  event: ICalendarEventForDisplay;
  x: number;
  y: number;
}

const CalendarContextMenuContext = createContext([
  null as CalendarContextMenuInfo | null,
  (value: CalendarContextMenuInfo) => {},
]);

const CalendarContextMenuContextProvider = (props: any) => {
  const [event, setEvent] = useState<CalendarContextMenuInfo | null>(null);
  return (
    <CalendarContextMenuContext.Provider value={[event, setEvent]}>
      {props.children}
    </CalendarContextMenuContext.Provider>
  );
};

interface IDateRangeHeaderProps {
  startAndEndDateTime: IStartAndEnd;
  setStartAndEndDateTime: (value: IStartAndEnd) => void;
}

const DateRangeHeader = React.memo((props: IDateRangeHeaderProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [startDateTime, setStartDateTime] = useState(props.startAndEndDateTime.start);
  const [endDateTime, setEndDateTime] = useState(props.startAndEndDateTime.end);

  useEffect(() => {
    setStartDateTime(props.startAndEndDateTime.start);
    setEndDateTime(props.startAndEndDateTime.end);
  }, [props.startAndEndDateTime]);

  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 0,
      }}>
      <LeftIcon
        size={30}
        onPress={() => {
          const dateRange = moment(endDateTime).diff(moment(startDateTime), 'days') + 1;
          const newStartDateTime = moment(startDateTime).add(-1 * dateRange, 'days');
          const newEndDateTime = moment(endDateTime).add(-1 * dateRange, 'days');
          setStartDateTime(newStartDateTime);
          setEndDateTime(newEndDateTime);
          props.setStartAndEndDateTime({ start: newStartDateTime, end: newEndDateTime });
        }}
      />
      <EditableText
        value={{
          start: startDateTime,
          end: endDateTime,
        }}
        type={'date-range-picker'}
        disableClear={true}
        containerStyle={{ paddingHorizontal: 10 }}
        textStyle={{ minWidth: 200 }}
        onChange={(value) => {
          setStartDateTime((value as IDateRange)!.start.startOf('day'));
          setEndDateTime((value as IDateRange)!.end.endOf('day'));
          props.setStartAndEndDateTime({
            start: (value as IDateRange)!.start.startOf('day'),
            end: (value as IDateRange)!.end.endOf('day'),
          });
        }}
        onBlur={(value) => {
          setStartDateTime((value as IDateRange)!.start);
          setEndDateTime((value as IDateRange)!.end);
          props.setStartAndEndDateTime({
            start: (value as IDateRange)!.start.startOf('day'),
            end: (value as IDateRange)!.end.endOf('day'),
          });
        }}
      />
      <RightIcon
        size={30}
        onPress={() => {
          const dateRange = moment(endDateTime).diff(moment(startDateTime), 'days') + 1;
          const newStartDateTime = moment(startDateTime).add(dateRange, 'days');
          const newEndDateTime = moment(endDateTime).add(dateRange, 'days');
          setStartDateTime(newStartDateTime);
          setEndDateTime(newEndDateTime);
          props.setStartAndEndDateTime({ start: newStartDateTime, end: newEndDateTime });
        }}
      />
      <Button
        text={'今日'}
        style={{
          backgroundColor: themeContext.colors.baseColor,
          borderWidth: 1,
          borderColor: themeContext.colors.separator,
          paddingVertical: 7,
          paddingHorizontal: 10,
          marginRight: 15,
          marginLeft: 5,
        }}
        textStyle={{ color: themeContext.colors.textColor, fontWeight: '400' }}
        disableValidate={true}
        onPress={() => {
          const dateRange = moment(endDateTime).diff(moment(startDateTime), 'days');
          const newStartDateTime = moment().startOf('day');
          const newEndDateTime = moment(newStartDateTime).add(dateRange, 'days').endOf('day');
          setStartDateTime(newStartDateTime);
          setEndDateTime(newEndDateTime);
          props.setStartAndEndDateTime({ start: newStartDateTime, end: newEndDateTime });
        }}
      />
    </View>
  );
});

interface IDateSelectHeaderProps {
  dateRange: number;
  startDateTime: moment.Moment;
  setStartDateTime: (value: moment.Moment) => void;
}

interface IEventItemProps extends IStyleTheme {
  start: Date;
  end: Date;
}

class EventHandle extends React.Component<any> {
  render() {
    return (
      <Animated.View
        style={
          {
            height: 5,
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 2,
            cursor: 'ns-resize',
          } as any
        }></Animated.View>
    );
  }
}

interface IEventTimeTextProps {
  start: moment.Moment;
  end: moment.Moment;
  color?: string;
  backgroundColor?: string;
}

const EventTimeText = React.memo((props: IEventTimeTextProps) => {
  if (props.start.isBefore(props.end)) {
    return (
      <Text style={{ color: props.color || '#FFFFFF', fontSize: 12 }}>
        {props.start.format('HH:mm')}〜{props.end.format('HH:mm')}
      </Text>
    );
  }
  return (
    <Text style={{ color: props.color || '#FFFFFF', fontSize: 12 }}>
      {props.end.format('HH:mm')}〜{props.start.format('HH:mm')}
    </Text>
  );
});

function adjustSnap(minutes: number, snap: number) {
  return Math.round(minutes / snap) * snap;
}

const getEventColor = (calendarEvent: ICalendarEventForDisplay) => {
  if (calendarEvent.googleCalendarEvent?.backgroundColor) {
    return calendarEvent.googleCalendarEvent.backgroundColor;
  }
  if (calendarEvent.workingHistory?.task) {
    if (calendarEvent.workingHistory.task.project.color) {
      return calendarEvent.workingHistory.task.project.color;
    }
    return ColorUtil.getCalendarColor(HashIdUtil.decord(calendarEvent.workingHistory.task.id!));
  }
  if (calendarEvent.workingHistory?.calendarEvent) {
    if (calendarEvent.workingHistory.calendarEvent.projectColor) {
      return calendarEvent.workingHistory.calendarEvent!.projectColor;
    }
    return ColorUtil.getCalendarColor(
      HashIdUtil.decord(calendarEvent.workingHistory.calendarEvent.id!)
    );
  }
  if (calendarEvent.workingSchedule?.task) {
    if (calendarEvent.workingSchedule.task.project.color) {
      return calendarEvent.workingSchedule.task.project.color;
    }
    return ColorUtil.getCalendarColor(HashIdUtil.decord(calendarEvent.workingSchedule.task.id!));
  }
  if (calendarEvent.workingSchedule?.calendarEvent) {
    if (calendarEvent.workingSchedule.calendarEvent.projectColor) {
      return calendarEvent.workingSchedule.calendarEvent!.projectColor;
    }
    return ColorUtil.getCalendarColor(
      HashIdUtil.decord(calendarEvent.workingSchedule.calendarEvent.id!)
    );
  }
  return ColorUtil.getCalendarColor(null);
};
interface EventItemProps {
  readOnly: boolean;
  targetDate: moment.Moment;
  targetMember: Member;
  onHoverEvent: (value: CalendarHoverEventInfo) => void;
  onHoverOutEvent: () => void;
  setContextMenuEvent: (value: CalendarContextMenuInfo | null) => void;
  start: moment.Moment;
  end: moment.Moment;
  left: number;
  calendarEvent: ICalendarEventForDisplay;
  dragging: boolean;
  draggingLazy: boolean;
  draggingOtherEvent: boolean;
  onDrop: (value: ITimeRange) => void;
  onStart: () => void;
  onStop: () => void;
  onDelete: () => void;
  onPress: () => void;
  draggingHandleLazy: boolean;
  setDraggingHandleLazy: (value: boolean) => void;
  snapMinutes: number;
}

class EventItem extends React.Component<EventItemProps> {
  private timer: any;

  constructor(props: EventItemProps) {
    super(props);
    this.timer = null;
    this.state = {
      translationY: 0,
      draggingLazy: false,
      dragHandle: false,
      hover: false,
      showContextMenu: false,
      contextMenuX: 0,
      contextMenuY: 0,
    };
  }

  componentDidMount() {
    if (this.timer && this.timer.clearTimeout) {
      this.timer.clearTimeout();
    }
  }

  render() {
    const isOvernight =
      this.props.calendarEvent.start.format('YYYY/MM/DD') !== this.props.end.format('YYYY/MM/DD');

    const isFirstHalf =
      isOvernight &&
      this.props.targetDate.format('YYYY/MM/DD') ===
        this.props.calendarEvent.start.format('YYYY/MM/DD');

    const isLastHalf =
      isOvernight &&
      this.props.targetDate.format('YYYY/MM/DD') === this.props.end.format('YYYY/MM/DD');

    const isRevert =
      (this.state as any).dragHandle &&
      (this.state as any).translationY <
        -1 * this.props.end.diff(moment(this.props.calendarEvent.start), 'minutes');

    const top = isRevert
      ? this.props.end.diff(this.props.targetDate.startOf('day'), 'minutes') +
        (this.state as any).translationY
      : (isLastHalf && !this.props.dragging
          ? this.props.targetDate.startOf('day')
          : this.props.start
        ).diff(this.props.targetDate.startOf('day'), 'minutes');

    const fixedHeight = this.props.end.diff(
      isLastHalf ? this.props.targetDate.startOf('day') : this.props.calendarEvent.start,
      'minutes'
    );

    const height = isRevert
      ? moment(this.props.calendarEvent.start).diff(this.props.end, 'minutes') -
        (this.state as any).translationY
      : this.props.end.diff(
          isLastHalf && !this.props.dragging
            ? this.props.targetDate.startOf('day')
            : this.props.start,
          'minutes'
        ) + (this.state as any).translationY;

    return (
      <>
        <div
          onMouseEnter={(e) => {
            const that = this;
            const x = e.clientX;
            const y = e.clientY;
            // Hoverした時にはツールチップ表示するのだが、少しタイミングを遅らせている。
            // 0.4秒以上ホバーした時に、ツールチップを表示し、それ以下の場合には表示しないようにしている。
            // そうしないと、カレンダーをスクロールした際に、ちょっとでもホバー発生するとレンダリングが発生し、それによってスクロールがカクつくという問題が発生していた。
            // それを回避するために、一定時間ホバーしていた時だけ、ツールチップ表示するようにしている。
            if (this.props.dragging) {
              return;
            }
            this.timer = setTimeout(() => {
              that.props.onHoverEvent({
                event: that.props.calendarEvent,
                x: x,
                y: y,
              });
              that.setState(
                Object.assign(that.state, {
                  hover: true,
                })
              );
            }, 400);
          }}
          onMouseLeave={(e) => {
            if (this.timer) {
              clearTimeout(this.timer);
            }
            if (this.props.draggingLazy) {
              return;
            }
            if ((this.state as any).hover) {
              this.props.onHoverOutEvent();
              Object.assign(this.state, {
                hover: false,
              });
            }
          }}>
          <Animated.View
            style={
              {
                position: 'absolute',
                overflow: 'display',
                top: top,
                left: this.props.left,
                width: '100%',
                height: height,
                backgroundColor: getEventColor(this.props.calendarEvent),
                borderColor: ColorUtil.darken(getEventColor(this.props.calendarEvent), 10),
                borderWidth: 1,
                borderRadius: 3,
                zIndex: this.props.dragging || (this.state as any).dragHandle ? 3 : 2,
                cursor: 'pointer',
                opacity:
                  this.props.calendarEvent.googleCalendarEvent?.attendees?.filter(
                    (attendee) => attendee!.self && attendee!.responseStatus === 'declined'
                  ).length ?? 0 > 0
                    ? 0.3
                    : 1,
              } as any
            }>
            <TouchableOpacity
              style={
                {
                  height: fixedHeight,
                  cursor: 'pointer',
                } as any
              }
              onPress={() => {
                if (this.props.draggingLazy) {
                  return;
                }
                this.props.onPress();
              }}>
              <div
                style={{
                  height: fixedHeight,
                }}
                onContextMenu={(e) => {
                  e.nativeEvent.preventDefault();
                  const isRight = e.nativeEvent.which === 3;
                  if (!isRight) {
                    return;
                  }
                  this.setState(
                    Object.assign(this.state, {
                      showContextMenu: true,
                      contextMenuX: e.nativeEvent.offsetX,
                      contextMenuY: e.nativeEvent.offsetY,
                    })
                  );
                  this.props.setContextMenuEvent({
                    event: this.props.calendarEvent,
                    x: e.clientX,
                    y: e.clientY,
                  });
                }}>
                <View
                  style={{
                    overflow: 'hidden',
                    paddingVertical: 2,
                    paddingHorizontal: 5,
                    width: '100%',
                    height: height,
                  }}>
                  {this.props.calendarEvent.workingHistory && (
                    <View
                      style={{
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                      }}>
                      <Text
                        style={
                          {
                            color: '#FFFFFF',
                            overflow: 'hidden',
                            maxWidth: 150,
                            fontSize: 12,
                          } as any
                        }>
                        {StringUtil.ellipsis(
                          this.props.calendarEvent.workingHistory?.task?.title! ||
                            this.props.calendarEvent.workingHistory?.calendarEvent?.eventName!,
                          20
                        )}
                      </Text>
                      <Text
                        style={
                          {
                            color: '#FFFFFF',
                            overflow: 'hidden',
                            maxWidth: 150,
                            fontSize: 12,
                          } as any
                        }>
                        {`(${StringUtil.ellipsis(
                          this.props.calendarEvent.workingHistory?.task?.project.name ||
                            this.props.calendarEvent.workingHistory?.calendarEvent?.projectName!,
                          20
                        )})`}
                      </Text>
                      {this.props.dragging ? (
                        <EventTimeText start={this.props.start} end={this.props.end} />
                      ) : (
                        <EventTimeText
                          start={moment(this.props.calendarEvent.workingHistory?.start)}
                          end={
                            this.props.calendarEvent.workingHistory?.end
                              ? (this.state as any).dragHandle
                                ? DateUtil.roundMinutes(
                                    moment(this.props.end).add(
                                      (this.state as any).translationY,
                                      'minutes'
                                    ),
                                    this.props.snapMinutes
                                  )
                                : moment(this.props.calendarEvent.workingHistory?.end)
                              : moment()
                          }
                        />
                      )}
                    </View>
                  )}
                  {this.props.calendarEvent.workingSchedule && (
                    <View
                      style={{
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                      }}>
                      <Text
                        style={
                          {
                            color: '#FFFFFF',
                            overflow: 'hidden',
                            maxWidth: 150,
                            fontSize: 12,
                          } as any
                        }>
                        {StringUtil.ellipsis(
                          this.props.calendarEvent.workingSchedule?.task?.title! ||
                            this.props.calendarEvent.workingSchedule?.calendarEvent?.eventName!,
                          20
                        )}
                      </Text>
                      <Text
                        style={
                          {
                            color: '#FFFFFF',
                            overflow: 'hidden',
                            maxWidth: 150,
                            fontSize: 12,
                          } as any
                        }>
                        {`(${StringUtil.ellipsis(
                          this.props.calendarEvent.workingSchedule?.task?.project.name ||
                            this.props.calendarEvent.workingSchedule?.calendarEvent?.projectName!,
                          20
                        )})`}
                      </Text>
                      {this.props.dragging ? (
                        <EventTimeText start={this.props.start} end={this.props.end} />
                      ) : (
                        <EventTimeText
                          start={moment(this.props.calendarEvent.workingSchedule!.start)}
                          end={
                            (this.state as any).dragHandle
                              ? DateUtil.roundMinutes(
                                  moment(this.props.end).add(
                                    (this.state as any).translationY,
                                    'minutes'
                                  ),
                                  this.props.snapMinutes
                                )
                              : moment(this.props.calendarEvent.workingSchedule!.end)
                          }
                        />
                      )}
                    </View>
                  )}
                  {this.props.calendarEvent.googleCalendarEvent && (
                    <View>
                      <Text
                        style={
                          {
                            color: this.props.calendarEvent.googleCalendarEvent.foregroundColor,
                            overflow: 'hidden',
                            maxWidth: 150,
                            fontSize: 12,
                            textDecoration:
                              this.props.calendarEvent.googleCalendarEvent.attendees?.filter(
                                (attendee) =>
                                  attendee!.self && attendee!.responseStatus === 'declined'
                              ).length ?? 0 > 0
                                ? 'line-through'
                                : undefined,
                          } as any
                        }>
                        {StringUtil.ellipsis(
                          this.props.calendarEvent.googleCalendarEvent!.title || '',
                          20
                        ) ||
                          (this.props.calendarEvent.googleCalendarEvent.visivility ===
                            GoogleCalendarEventVisivility.Private ||
                          this.props.calendarEvent.googleCalendarEvent.visivility ===
                            GoogleCalendarEventVisivility.Confidential
                            ? '予定あり'
                            : '(タイトルなし)')}
                      </Text>
                      {this.props.dragging ? (
                        <EventTimeText
                          start={this.props.start}
                          end={this.props.end}
                          color={this.props.calendarEvent.googleCalendarEvent?.foregroundColor}
                        />
                      ) : (
                        <EventTimeText
                          start={moment(
                            this.props.calendarEvent.googleCalendarEvent!.startDateTime
                          )}
                          end={moment(
                            this.props.calendarEvent.googleCalendarEvent!.endDateTime
                          ).add(
                            adjustSnap((this.state as any).translationY, this.props.snapMinutes),
                            'minutes'
                          )}
                          color={this.props.calendarEvent.googleCalendarEvent?.foregroundColor}
                        />
                      )}
                    </View>
                  )}
                </View>

                <PanGestureHandler
                  maxPointers={1}
                  onGestureEvent={(event) => {
                    if (this.props.readOnly) {
                      return;
                    }
                    if (event.nativeEvent.state === State.BEGAN) {
                      this.setState({ translationY: 0, dragHandle: true });
                      this.props.setDraggingHandleLazy(true);
                    }
                    if (event.nativeEvent.state === State.ACTIVE) {
                      this.setState({
                        translationY: adjustSnap(
                          event.nativeEvent.translationY -
                            (moment(this.props.end).get('minutes') % this.props.snapMinutes),
                          this.props.snapMinutes
                        ),
                      });
                    }
                    if (event.nativeEvent.state === State.END) {
                      this.setState({ translationY: 0, dragHandle: false });

                      const newEnd = DateUtil.roundMinutes(
                        moment(this.props.end).add(event.nativeEvent.translationY, 'minutes'),
                        this.props.snapMinutes
                      );

                      if (moment(this.props.calendarEvent.start).isBefore(newEnd)) {
                        this.props.onDrop({
                          start: moment(this.props.calendarEvent.start),
                          end: newEnd,
                        });
                      } else {
                        this.props.onDrop({
                          end: moment(this.props.calendarEvent.start),
                          start: newEnd,
                        });
                      }

                      setTimeout(() => this.props.setDraggingHandleLazy(false), 500);
                    }
                  }}>
                  <EventHandle
                    translationY={Math.round((this.state as any).translationY / 5) * 5}
                  />
                </PanGestureHandler>
              </div>
            </TouchableOpacity>
          </Animated.View>
        </div>
      </>
    );
  }
}

interface GhostEventProps {
  calendarEvent: ICalendarEventForDisplay;
}

const GhostEventItem = (props: GhostEventProps) => {
  return (
    <View
      style={{
        opacity: 0.4,
        position: 'absolute',
        top: props.calendarEvent.start.get('hours') * 60 + props.calendarEvent.start.get('minutes'),
        width: '100%',
        height: (props.calendarEvent.end ? moment(props.calendarEvent.end) : moment()).diff(
          moment(props.calendarEvent.start),
          'minutes'
        ),
        backgroundColor: getEventColor(props.calendarEvent),
        borderColor: ColorUtil.darken(getEventColor(props.calendarEvent), 10),
        borderWidth: 1,
        borderRadius: 3,
        zIndex: 1,
      }}></View>
  );
};

interface EventProps {
  readOnly: boolean;
  horizontalDragEnable: boolean;
  targetDate: moment.Moment;
  targetMember: Member;
  dayColumnRef: any;
  calendarEvent: ICalendarEventForDisplay;
  dragging: boolean;
  setDragging: (value: boolean) => void;
  onDrop: (value: ITimeRange) => void;
  onStart: () => void;
  onStop: () => void;
  onDelete: () => void;
  snapMinutes: number;
}

// PanGestureHandler を使う場合には、内部のコンポーネントを、一度古い書き方のコンポーネントで作らないと、エラーが発生するReactNativeのバグがある模様
const Event = React.memo((props: EventProps) => {
  const [translationX, setTranslationX] = useState(new Animated.Value(0));
  const [translationY, setTranslationY] = useState(new Animated.Value(0));
  const [dragging, setDragging] = useState(false);
  const [draggingLazy, setDraggingLazy] = useState(false);
  const [draggingHandleLazy, setDraggingHandleLazy] = useState(false);
  const [showEditGoogleCalendarEventModal, setShowEditGoogleCalendarEventModal] = useState(false);
  const [showDetailGoogleCalendarEventModal, setShowDetailGoogleCalendarEventModal] = useState(false);
  const history = useHistory();
  const [hoverEvent, setHoverEvent] = useContext(CalendarEventHoverContext);
  const [contextMenu, setContextMenu] = useContext(CalendarContextMenuContext);

  // タスク作業中の場合に、1分ごとに描画させるために仕込んでいる
  const [dummy, setDummy] = useState(0);
  useInterval(() => {
    if (props.calendarEvent.end === null) {
      setDummy(dummy + 1);
    }
  }, 1000 * 60);

  return (
    <View
      style={{
        zIndex: 1,
        width: `${Math.floor(100 / props.calendarEvent.overlapEventCount)}%`,
        left: `${
          (100 / props.calendarEvent.overlapEventCount) * props.calendarEvent.overlapIndex
        }%`,
      }}>
      <PanGestureHandler
        maxPointers={1}
        onGestureEvent={(event) => {
          if (props.readOnly) {
            return;
          }
          if (draggingHandleLazy) {
            return;
          }

          if (event.nativeEvent.state === State.BEGAN) {
            setTranslationX(new Animated.Value(0));
            setTranslationY(new Animated.Value(0));

            props.setDragging(true);
            setDragging(true);
            setDraggingLazy(true);
          }
          if (event.nativeEvent.state === State.ACTIVE) {
            if (props.horizontalDragEnable) {
              setTranslationX(
                new Animated.Value(adjustSnap(event.nativeEvent.translationX, props.snapMinutes))
              );
            }

            setTranslationY(
              new Animated.Value(adjustSnap(event.nativeEvent.translationY, props.snapMinutes))
            );
          }
          if (event.nativeEvent.state === State.END) {
            setTranslationX(new Animated.Value(0));
            setTranslationY(new Animated.Value(0));
            props.setDragging(false);
            setDragging(false);
            setTimeout(() => setDraggingLazy(false), 500);

            const dayColumnWidth = props.dayColumnRef.current.scrollWidth;
            let moveDays;
            if (event.nativeEvent.translationX >= 0) {
              moveDays = Math.floor(
                (event.nativeEvent.translationX + dayColumnWidth / 3) / dayColumnWidth
              );
            } else {
              moveDays = Math.ceil(
                (event.nativeEvent.translationX - dayColumnWidth / 3) / dayColumnWidth
              );
            }

            props.onDrop({
              start: DateUtil.roundMinutes(
                moment(props.calendarEvent.start)
                  .add(moveDays, 'days')
                  .add(event.nativeEvent.translationY, 'minutes'),
                props.snapMinutes
              ),
              end: DateUtil.roundMinutes(
                moment(props.calendarEvent.end)
                  .add(moveDays, 'days')
                  .add(event.nativeEvent.translationY, 'minutes'),
                props.snapMinutes
              ),
            });
          }
        }}>
        <EventItem
          readOnly={props.readOnly}
          targetDate={props.targetDate}
          targetMember={props.targetMember}
          onHoverEvent={(info) => {
            //@ts-ignore
            setHoverEvent(info);
          }}
          onHoverOutEvent={() => {
            //@ts-ignore
            setHoverEvent(null);
          }}
          setContextMenuEvent={(info) => {
            //@ts-ignore
            setContextMenu(info);
          }}
          start={moment(props.calendarEvent.start).add(
            (translationY as any).__getValue(),
            'minutes'
          )}
          end={
            props.calendarEvent.end
              ? moment(props.calendarEvent.end).add((translationY as any).__getValue(), 'minutes')
              : moment().add((translationY as any).__getValue(), 'minutes')
          }
          left={(translationX as any).__getValue()}
          calendarEvent={props.calendarEvent}
          dragging={dragging}
          draggingLazy={draggingLazy}
          draggingOtherEvent={props.dragging}
          snapMinutes={props.snapMinutes}
          onDrop={props.onDrop}
          onStart={props.onStart}
          onStop={props.onStop}
          onDelete={props.onDelete}
          draggingHandleLazy={draggingHandleLazy}
          setDraggingHandleLazy={setDraggingHandleLazy}
          onPress={() => {
            if (props.calendarEvent.workingHistory?.task) {
              if (!draggingLazy && !draggingHandleLazy) {
                history.push(UrlUtil.createTaskDetailUrlById(props.calendarEvent.workingHistory.task.id!));
              }
              return;
            }
            if (props.calendarEvent.workingHistory?.calendarEvent) {
              if (!draggingLazy && !draggingHandleLazy) {
                history.push(
                  UrlUtil.createCalendarEventDetailUrlById(
                    props.calendarEvent.workingHistory.calendarEvent.id!
                  )
                );
              }
              return;
            }
            if (props.calendarEvent.workingSchedule?.task) {
              if (!draggingLazy && !draggingHandleLazy) {
                history.push(UrlUtil.createTaskDetailUrlById(props.calendarEvent.workingSchedule.task.id!));
              }
              return;
            }
            if (props.calendarEvent.workingSchedule?.calendarEvent) {
              if (!draggingLazy && !draggingHandleLazy) {
                history.push(
                  UrlUtil.createCalendarEventDetailUrlById(
                    props.calendarEvent.workingSchedule.calendarEvent.id!
                  )
                );
              }
              return;
            }
            if (
              props.calendarEvent.googleCalendarEvent
            ) {
              if(props.calendarEvent.googleCalendarEvent.readonly){
                setShowDetailGoogleCalendarEventModal(true);
              } else {
                setShowEditGoogleCalendarEventModal(true);
              }
              return;
            }
          }}
        />
      </PanGestureHandler>
      {dragging && <GhostEventItem calendarEvent={props.calendarEvent} />}
      {props.calendarEvent.googleCalendarEvent && (
        <>
          <EditGoogleCalendarEventModal
            googleCalendarEvent={props.calendarEvent.googleCalendarEvent}
            showModal={showEditGoogleCalendarEventModal}
            onPressYes={(event) => setShowEditGoogleCalendarEventModal(false)}
            onCloseModal={() => setShowEditGoogleCalendarEventModal(false)}
          />
          <DetailGoogleCalendarEventModal
            googleCalendarEvent={props.calendarEvent.googleCalendarEvent}
            showModal={showDetailGoogleCalendarEventModal}
            onPressYes={(event) => setShowDetailGoogleCalendarEventModal(false)}
            onCloseModal={() => setShowDetailGoogleCalendarEventModal(false)}
          />
        </>
      )}
    </View>
  );
});

interface ICellInnerProps {
  themeContext: IThemePart;
  isFirst: boolean;
  isHalfHour: boolean;
}

class CellInner extends React.Component<ICellInnerProps> {
  constructor(props: ICellInnerProps) {
    super(props);
    this.state = { translationY: 0, dragHandle: false };
  }

  render() {
    return (
      <View
        style={{
          height: cellHeight,
          display: 'flex',
        }}>
        <Animated.View
          style={{
            height: cellHeight,
            display: 'flex',
            borderBottomWidth: 1,
            borderTopWidth: this.props.isFirst ? 1 : 0,
            borderColor: this.props.isHalfHour
              ? ColorUtil.lignten(this.props.themeContext.colors.separator, 4)
              : this.props.themeContext.colors.separator,
          }}
        />
      </View>
    );
  }
}

interface ICellProps {
  calendarType: CalendarType;
  dateTime: moment.Moment;
  snapMinutes: number;
  isFirst: boolean;
  isHalfHour: boolean;
  setNewEventDragging: (value: boolean) => void;
  setNewEventStartAndEnd: (value: IStartAndEndNullable) => void;
  onDrop: () => void;
  onPress: (start: moment.Moment, end: moment.Moment) => void;
}

const Cell = React.memo((props: ICellProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  return (
    <TouchableOpacity
      onPress={() => {
        props.setNewEventDragging(true);
        props.setNewEventStartAndEnd({
          start: props.dateTime,
          end: moment(props.dateTime).add(30, 'minutes'),
        });
        props.onPress(moment(props.dateTime), moment(props.dateTime).add(30, 'minutes'));
        setTimeout(() => {
          props.setNewEventDragging(false);
          props.setNewEventStartAndEnd({ start: null, end: null });
        }, 1000);
      }}>
      <PanGestureHandler
        maxPointers={1}
        onGestureEvent={(event) => {
          if (event.nativeEvent.state === State.BEGAN) {
            props.setNewEventDragging(true);
            props.setNewEventStartAndEnd({
              start: props.dateTime,
              end: moment(props.dateTime).add(30, 'minutes'),
            });
          }
          if (event.nativeEvent.state === State.ACTIVE) {
            if (event.nativeEvent.translationY >= 0) {
              const newEnd = DateUtil.roundMinutes(
                moment(props.dateTime).add(event.nativeEvent.translationY, 'minutes'),
                props.snapMinutes
              );
              props.setNewEventStartAndEnd({ start: props.dateTime, end: newEnd });
            } else {
              const newStart = DateUtil.roundMinutes(
                moment(props.dateTime).add(event.nativeEvent.translationY, 'minutes'),
                props.snapMinutes
              );
              props.setNewEventStartAndEnd({ start: newStart, end: props.dateTime });
            }
          }
          if (event.nativeEvent.state === State.END) {
            props.onDrop();
            setTimeout(() => {
              props.setNewEventDragging(false);
              props.setNewEventStartAndEnd({ start: null, end: null });
            }, 1000);
          }
        }}>
        <CellInner
          themeContext={themeContext}
          isFirst={props.isFirst}
          isHalfHour={props.isHalfHour}
        />
      </PanGestureHandler>
    </TouchableOpacity>
  );
});

interface ITimeColumnProps {
  readOnly: boolean;
  isClosedDate: boolean;
  horizontalDragEnable: boolean;
  calendarType: CalendarType;
  dayColumnRef: any;
  snapMinutes: number;
  date: moment.Moment;
  targetMember: Member;
  events: ICalendarEvent[];
  addEvents: (value: ICalendarEvent) => void;
  moveEvent: (value: ICalendarEvent) => void;
  startWorkingHistory?: (value: CalendarWorkingHistory) => void;
  stopWorkingHistory?: (value: CalendarWorkingHistory) => void;
  deleteEvent: (value: ICalendarEvent) => void;
  dragging: boolean;
  setDragging: (value: boolean) => void;
  columnSetEventHover: boolean;
  setColumnSetEventHover: (value: boolean) => void;
}

const TimeColumn = React.memo((props: ITimeColumnProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [newEventStartAndEnd, setNewEventStartAndEnd] = useState<IStartAndEndNullable>({
    start: null,
    end: null,
  });
  const [columnEventDragging, setColumnEventDragging] = useState(false);

  //TODO イベントの重なりを判定して情報を付与する必要がある

  return (
    <TimeColumnView
      style={{
        zIndex: columnEventDragging ? 1 : 0,
        backgroundColor: props.isClosedDate
          ? themeContext.colors.separator
          : themeContext.colors.baseColor,
      }}>
      <>
        {props.events.map((event: ICalendarEvent, i) => {
          const overlapEvents = props.events
            .filter(
              (ev) =>
                event.start.isBefore(ev.end || moment()) &&
                (event.end === null || event.end.isAfter(ev.start))
            )
            .sort((a, b) => a.start.toDate().getTime() - b.start.toDate().getTime());
          return (
            <Event
              readOnly={props.readOnly || event.googleCalendarEvent?.readonly || false}
              horizontalDragEnable={props.horizontalDragEnable}
              targetDate={props.date}
              targetMember={props.targetMember}
              snapMinutes={props.snapMinutes}
              dayColumnRef={props.dayColumnRef}
              calendarEvent={Object.assign(event, {
                targetMember: props.targetMember,
                overlapEventCount: overlapEvents.length,
                overlapIndex: overlapEvents.findIndex((ev) => {
                  if (event.workingHistory) {
                    return event.workingHistory.id! === ev.workingHistory?.id;
                  }
                  if (event.workingSchedule) {
                    return event.workingSchedule.id! === ev.workingSchedule?.id;
                  }
                  if (event.googleCalendarEvent) {
                    return event.googleCalendarEvent.id! === ev.googleCalendarEvent?.id;
                  }
                  return true;
                }),
              })}
              dragging={props.dragging}
              setDragging={(value) => {
                props.setDragging(value);
                setColumnEventDragging(value);
              }}
              onDrop={(range) => {
                props.moveEvent({
                  targetMember: props.targetMember,
                  start: range.start,
                  end: range.end,
                  workingHistory: event.workingHistory,
                  googleCalendarEvent: event.googleCalendarEvent,
                  workingSchedule: event.workingSchedule,
                });
              }}
              onStart={() => {
                if (props.startWorkingHistory) {
                  props.startWorkingHistory(event.workingHistory!);
                }
              }}
              onStop={() => {
                if (props.stopWorkingHistory) {
                  props.stopWorkingHistory(event.workingHistory!);
                }
              }}
              onDelete={() => {
                props.deleteEvent(event);
              }}
              key={i}
            />
          );
        })}
        {[...Array(48)].map((_, i) => {
          return (
            <Cell
              calendarType={props.calendarType}
              dateTime={moment(
                moment(props.date).format(
                  `YYYY/MM/DD ${Math.floor(i / 2)}:${i % 2 === 0 ? '00' : '30'}`
                )
              )}
              snapMinutes={props.snapMinutes}
              isFirst={i === 0}
              isHalfHour={i % 2 === 0}
              setNewEventDragging={(value) => {
                props.setDragging(value);
                setColumnEventDragging(value);
              }}
              setNewEventStartAndEnd={setNewEventStartAndEnd}
              onPress={(start, end) => {
                props.addEvents({
                  targetMember: props.targetMember,
                  start: moment(start),
                  end: moment(end),
                  workingHistory: null,
                  googleCalendarEvent: null,
                  workingSchedule: null,
                });
              }}
              onDrop={() => {
                props.addEvents({
                  targetMember: props.targetMember,
                  start: newEventStartAndEnd.start!,
                  end: newEventStartAndEnd.end!,
                  workingHistory: null,
                  googleCalendarEvent: null,
                  workingSchedule: null,
                });
              }}
              key={i}
            />
          );
        })}
        {props.dragging === true &&
          newEventStartAndEnd.start !== null &&
          newEventStartAndEnd.end !== null && (
            <View
              style={{
                position: 'absolute',
                top:
                  newEventStartAndEnd.start.get('hours') * 60 +
                  newEventStartAndEnd.start.get('minutes'),
                height:
                  newEventStartAndEnd.end.get('hours') * 60 +
                  newEventStartAndEnd.end.get('minutes') -
                  (newEventStartAndEnd.start.get('hours') * 60 +
                    newEventStartAndEnd.start.get('minutes')),
                width: '100%',
                backgroundColor: '#00b2ca',
                borderColor: ColorUtil.darken('#00b2ca', 10),
                borderWidth: 1,
                borderRadius: 3,
                zIndex: 100,
              }}
            />
          )}
      </>
    </TimeColumnView>
  );
});

interface ITimeRowProps {
  index: number;
}

const TimeRow = React.memo((props: ITimeRowProps) => {
  return (
    <TimeMeasureRow>
      <TimeMeasure>
        {props.index % 2 === 0 && (
          <Typography
            variant={TypographyType.Description}
            style={{
              textAlign: 'right',
              fontSize: 8,
              position: 'absolute',
              top: -8,
              right: 5,
            }}>
            {`${props.index / 2}:00`}
          </Typography>
        )}
      </TimeMeasure>
      <TimeCell isFirst={props.index === 0} isHalfHour={props.index % 2 === 0} />
    </TimeMeasureRow>
  );
});

interface IDayColumnHeaderProps {
  date: moment.Moment;
  showDateHeader: boolean;
  isConnectGoogleAccount: boolean;
  showWorkingHistory: boolean;
  showWorkingSchedule: boolean;
  showGoogleCalendar: boolean;
  targetMemberIds: string[];
  organizatiomMembers: Member[];
}

const DayColumnHeader = React.memo((props: IDayColumnHeaderProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  let columnCount = 0;
  if (props.showWorkingHistory) {
    columnCount = columnCount + 1;
  }
  if (props.showWorkingSchedule) {
    columnCount = columnCount + 1;
  }
  if (props.showGoogleCalendar) {
    columnCount = columnCount + 1;
  }

  return (
    <View
      style={{
        flexDirection: 'column',
        flex: 1,
        borderLeftWidth: 1,
        borderColor: themeContext.colors.separator,
        minWidth: props.targetMemberIds.length * columnCount * 80,
      }}>
      {props.showDateHeader !== false && (
        <View>
          <Typography variant={TypographyType.Normal} style={{ textAlign: 'center', fontSize: 12 }}>
            {props.date.format('MM/DD(ddd)')}
          </Typography>
        </View>
      )}
      <View style={{ flexDirection: 'row' }}>
        {props.targetMemberIds.map((memberId) => {
          const member = props.organizatiomMembers.filter((mem) => mem.id === memberId)[0];
          return (
            <View key={memberId} style={{ flex: 1 }}>
              {props.targetMemberIds.length > 1 && (
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 20,
                  }}>
                  <Avatar size={22} name={member.name!} imageUrl={member.profileImageUrl} />
                  <Typography
                    variant={TypographyType.Normal}
                    style={{ marginLeft: 3, fontSize: 15 }}>
                    {member.name}
                  </Typography>
                </View>
              )}
              <View
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  flex: 1,
                  height: 40,
                  paddingBottom: 8,
                  backgroundColor: 'transparent',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                {props.showWorkingHistory && (
                  <DayHeader>
                    <Typography
                      variant={TypographyType.Normal}
                      style={{ textAlign: 'center', fontSize: 12 }}>
                      作業履歴
                    </Typography>
                  </DayHeader>
                )}
                {props.showWorkingSchedule && (
                  <DayHeader>
                    <Typography
                      variant={TypographyType.Normal}
                      style={{ textAlign: 'center', fontSize: 12 }}>
                      作業予定
                    </Typography>
                  </DayHeader>
                )}
                {props.isConnectGoogleAccount && props.showGoogleCalendar && (
                  <DayHeader>
                    <Typography
                      variant={TypographyType.Normal}
                      style={{ textAlign: 'center', fontSize: 12, lineHeight: 15 }}>
                      {`Google${'\n'}カレンダー`}
                    </Typography>
                  </DayHeader>
                )}
              </View>
            </View>
          );
        })}
      </View>
    </View>
  );
});

interface INowBar {
  onlyLine: boolean;
}

const NowBar = (props: INowBar) => {
  const [ref, isHover] = useHover();

  // 1分ごとに描画させるために仕込んでいる
  const [dummy, setDummy] = useState(0);
  useInterval(() => {
    setDummy(dummy + 1);
  }, 1000 * 60);

  return (
    <View
      ref={ref}
      style={
        {
          position: 'absolute',
          top: moment().get('hours') * 60 + moment().get('minutes') - 3,
          flexDirection: 'row',
          opacity: 0.5,
          width: '100%',
          pointerEvents: 'none',
        } as any
      }>
      {props.onlyLine !== true && (
        <View
          style={{
            backgroundColor: 'red',
            width: 8,
            height: 8,
            borderRadius: 4,
            left: -3,
          }}></View>
      )}
      <View
        style={{
          backgroundColor: 'red',
          width: props.onlyLine ? '100%' : 'calc(100% - 4px)',
          height: 2,
          borderRadius: 1,
          top: 3,
          left: props.onlyLine ? 0 : -4,
        }}></View>
    </View>
  );
};

interface IWorkingHistoryTimeColumnProps {
  dayColumnRef: any;
  readOnly: boolean;
  isClosedDate: boolean;
  closingTargetDateTime: moment.Moment | null;
  targetMember: Member;
  horizontalDragEnable: boolean;
  date: moment.Moment;
  snapMinutes: number;
  dragging: boolean;
  setDragging: (value: boolean) => void;
  columnSetEventHover: boolean;
  setColumnSetEventHover: (value: boolean) => void;
  historyEvents: ICalendarEvent[];
}

const WorkingHistoryTimeColumn = (props: IWorkingHistoryTimeColumnProps) => {
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [newWorkingHistoryStartAndEnd, setNewWorkingHistoryStartAndEnd] =
    useState<IStartAndEndForModal>({
      start: moment(),
      end: moment(),
      show: false,
    });

  const [deleteWorkingHistory] = useDeleteWorkingHistoryMutation();
  const [startWorkingHistory] = useStartTaskMutation();
  const [stopWorkingHistory] = useStopTaskMutation();
  const [updateWorkingHistory] = useUpdateWorkingHistoryMutation();
  const [showCompleteTaskStartErrorModal, setShowCompleteTaskStartErrorModal] = useState(false);
  const [showHasNotProxyEditPermission, setShowHasNotProxyEditPermission] = useState(false);
  const [showClosedDateErrorModal, setShowClosedDateErrorModal] = useState(false);

  const addEvent = useCallback(
    (event) => {
      if (props.readOnly) {
        setShowHasNotProxyEditPermission(true);
        return;
      }
      if (props.isClosedDate) {
        setShowClosedDateErrorModal(true);
        return;
      }
      // if (event.start.isAfter(moment())) {
      //   return;
      // }
      setNewWorkingHistoryStartAndEnd({
        start: event.start,
        end: event.end,
        show: true,
      });
    },
    [props.readOnly, props.isClosedDate, setNewWorkingHistoryStartAndEnd]
  );

  const moveEvent = useCallback(
    (event) => {
      if (props.readOnly) {
        setShowHasNotProxyEditPermission(true);
        return;
      }
      if (props.isClosedDate) {
        setShowClosedDateErrorModal(true);
        return;
      }

      // if (event.start.isAfter(moment())) {
      //   return;
      // }
      updateWorkingHistory({
        variables: {
          id: event.workingHistory!.id!,
          input: {
            start: moment(event.start).toISOString(),
            end: event.workingHistory!.end ? event.end.toISOString() : null,
            memo: event.workingHistory!.memo,
            versionNo: event.workingHistory!.versionNo,
          },
        },
        refetchQueries: [
          {
            query: CalendarWorkingHistoryDocument,
            variables: {
              id: event.workingHistory!.id,
            },
          },
        ],
        // 効いてないようなので、一旦コメントアウト
        // optimisticResponse: {
        //   __typename: 'Mutation',
        //   updateWorkingHistory: Object.assign(
        //     {
        //       __typename: 'WorkingHistory',
        //     },
        //     event.workingHistory,
        //     {
        //       start: moment(event.start).toISOString(),
        //       end: event.workingHistory!.end ? event.end.toISOString() : null,
        //       versionNo: event.workingHistory!.versionNo + 1,
        //     }
        //   ) as any,
        // },
      });
    },
    [props.readOnly, props.isClosedDate, updateWorkingHistory]
  );

  const deleteEvent = useCallback(
    (event) => {
      if (props.readOnly) {
        setShowHasNotProxyEditPermission(true);
        return;
      }
      if (props.isClosedDate) {
        setShowClosedDateErrorModal(true);
        return;
      }

      deleteWorkingHistory({
        variables: {
          id: event.workingHistory!.id!,
          input: {
            versionNo: event.workingHistory!.versionNo,
          },
        },
        update: (cache, result) => {
          cache.evict({
            id: cache.identify(event.workingHistory!),
          });
        },
        refetchQueries: [
          {
            query: TaskDocument,
            variables: {
              id: event.workingHistory!.task.id!,
            },
          },
          {
            query: LatestWorkingHistoryDocument,
          },
          {
            query: ProjectWorkingTimeSecDocument,
            variables: {
              id: event.workingHistory!.task.project.id,
            },
          },
        ],
      });
    },
    [props.readOnly, props.isClosedDate, deleteWorkingHistory]
  );

  const startWorkingHistoryFunc = useCallback(
    (workingHistory) => { //TODO Cal
      if (props.readOnly) {
        setShowHasNotProxyEditPermission(true);
        return;
      }
      if (props.isClosedDate) {
        setShowClosedDateErrorModal(true);
        return;
      }
      if (!!workingHistory.task.completeDateTime || workingHistory.task.project.complete) {
        setShowCompleteTaskStartErrorModal(true);
        return;
      }
      startWorkingHistory({
        variables: {
          id: workingHistory.task.id!,
          input: {
            versionNo: workingHistory.task.versionNo,
          },
        },
        update: (cache, result) => {
          // レスポンスの中のネストされたデータは、自動的にはキャッシュ更新されないので、自前でキャッシュ更新している
          cache.writeQuery({
            query: TaskDocument,
            variables: { id: result.data!.startTask!.startTask.id! },
            data: { task: result.data!.startTask!.startTask },
          });
          cache.writeQuery({
            query: LatestWorkingHistoryDocument,
            data: { latestWorkingHistory: result.data!.startTask!.startWorkingHistory },
          });
          if (result.data?.startTask?.stopTask) {
            cache.writeQuery({
              query: TaskDocument,
              variables: { id: result.data!.startTask!.stopTask!.id! },
              data: { task: result.data!.startTask!.stopTask },
            });
          }
          cache.modify({
            fields: {
              workingHistoriesSpecifyTermForCalendar(existing = [], { storeFieldName }) {
                const newWorkingHistories = cache.writeQuery({
                  query: CalendarWorkingHistoryDocument,
                  data: result.data?.startTask?.stopWorkingHistory,
                });
                const targetMemberData = existing.filter((d: any) => d.memberId === loginUser?.id)[0];
                if((targetMemberData?.length ?? 0) === 0){
                  return [...existing, {
                    memberId: loginUser?.id,
                    workingHistories: newWorkingHistories
                  }];
                }
                return existing.map((data: any) => {
                  if(data.memberId !== loginUser?.id){
                    return data;
                  }
                  return {
                    memberId: data.memberId,
                    workingHistories: [...targetMemberData.workingHistories, newWorkingHistories]
                  }
                })
              },
            },
          });
        },
        refetchQueries: [
          {
            query: TaskWorkingHistorySummaryGroupByAssineeDocument,
            variables: {
              teamId: workingHistory.task.project.team.id!,
              taskId: workingHistory.task.id!,
            },
          },
        ],
      });
    },
    [props.readOnly, props.isClosedDate, startWorkingHistory]
  );

  const stopWorkingHistoryFunc = useCallback(
    (workingHistory) => {
      if (props.readOnly) {
        setShowHasNotProxyEditPermission(true);
        return;
      }
      if (props.isClosedDate) {
        setShowClosedDateErrorModal(true);
        return;
      }

      stopWorkingHistory({
        variables: {
          id: workingHistory.task.id!,
          input: {
            versionNo: workingHistory.task.versionNo,
          },
        },
        update: (cache, result) => {
          // レスポンスの中のネストされたデータは、自動的にはキャッシュ更新されないので、自前でキャッシュ更新している
          cache.writeQuery({
            query: TaskDocument,
            variables: { id: result.data!.stopTask!.stopTask.id! },
            data: { task: result.data!.stopTask!.stopTask },
          });
        },
        refetchQueries: [
          {
            query: TaskWorkingHistorySummaryGroupByAssineeDocument,
            variables: {
              teamId: workingHistory.task.project.team.id!,
              taskId: workingHistory.task.id!,
            },
          },
          {
            query: LatestWorkingHistoryDocument,
          },
          {
            query: ProjectWorkingTimeSecDocument,
            variables: {
              id: workingHistory.task.project.id,
            },
          },
        ],
      });
    },
    [props.readOnly, props.isClosedDate, useStopTaskMutation]
  );

  const onPressYes = useCallback(
    (workingHistory) =>
      setNewWorkingHistoryStartAndEnd({
        start: moment(),
        end: moment(),
        show: false,
      }),
    [setNewWorkingHistoryStartAndEnd]
  );

  const onCloseModal = useCallback(
    () =>
      setNewWorkingHistoryStartAndEnd({
        start: moment(),
        end: moment(),
        show: false,
      }),
    [setNewWorkingHistoryStartAndEnd]
  );

  return (
    <>
      <TimeColumn
        readOnly={props.readOnly || props.isClosedDate}
        isClosedDate={props.isClosedDate}
        horizontalDragEnable={props.horizontalDragEnable}
        calendarType={'WorkingHistory'}
        dayColumnRef={props.dayColumnRef}
        date={props.date}
        targetMember={props.targetMember}
        snapMinutes={props.snapMinutes}
        events={props.historyEvents} //TODO Cal
        dragging={props.dragging}
        setDragging={props.setDragging}
        columnSetEventHover={props.columnSetEventHover}
        setColumnSetEventHover={props.setColumnSetEventHover}
        addEvents={addEvent}
        moveEvent={moveEvent}
        deleteEvent={deleteEvent}
        startWorkingHistory={startWorkingHistoryFunc}
        stopWorkingHistory={stopWorkingHistoryFunc}
      />
      <CreateWorkingHistoryModal
        startDateTime={newWorkingHistoryStartAndEnd.start}
        endDateTime={newWorkingHistoryStartAndEnd.end}
        showModal={newWorkingHistoryStartAndEnd.show}
        onPressYes={onPressYes}
        onCloseModal={onCloseModal}
        targetMember={loginUser?.id !== props.targetMember.id ? props.targetMember : undefined}
      />
      <ErrorMessageModal
        showModal={showCompleteTaskStartErrorModal}
        title={'作業開始することができません'}
        message={`完了しているタスク・完了しているプロジェクトは${'\n'}作業開始することが出来ません。`}
        onCloseModal={() => setShowCompleteTaskStartErrorModal(false)}
      />
      <ErrorMessageModal
        showModal={showHasNotProxyEditPermission}
        title={'他のメンバーの作業データを編集できません'}
        message={`編集するには「代理編集権限」が必要です。`}
        onCloseModal={() => setShowHasNotProxyEditPermission(false)}
      />
      {props.closingTargetDateTime && (
        <ErrorMessageModal
          showModal={showClosedDateErrorModal}
          title={'締め処理済みの日付のため、作業履歴を編集できません'}
          message={`${moment(props.closingTargetDateTime).format(
            'll'
          )}まで締め処理済みです。${'\n'}編集するには管理者による締め処理の解除が必要です。`}
          onCloseModal={() => setShowClosedDateErrorModal(false)}
        />
      )}
    </>
  );
};

interface IMoveGoogleCalendarModalData {
  event: ICalendarEvent | null;
  showModal: boolean;
}

interface ICopyWorkingHistoryModalData {
  event: ICalendarEvent | null;
  showModal: boolean;
}

interface ICopyWorkingScheduleModalData {
  event: ICalendarEvent | null;
  showModal: boolean;
}

interface ICreateWorkingHistoryFromGoogleCalendarModalData {
  event: ICalendarEvent | null;
  showModal: boolean;
}

interface ICreateWorkingScheduleFromGoogleCalendarModalData {
  event: ICalendarEvent | null;
  showModal: boolean;
}

interface IEditWorkingHistory {
  event: CalendarWorkingHistory | null;
  showModal: boolean;
}

interface IEditWorkingSchedule {
  event: CalendarWorkingSchedule | null;
  showModal: boolean;
}

interface IGoogleCalendarTimeColumnProps {
  dayColumnRef: any;
  readOnly: boolean;
  horizontalDragEnable: boolean;
  date: moment.Moment;
  targetMember: Member;
  snapMinutes: number;
  dragging: boolean;
  setDragging: (value: boolean) => void;
  columnSetEventHover: boolean;
  setColumnSetEventHover: (value: boolean) => void;
  googleCalendarEvents: ICalendarEvent[];
}

const GoogleCalendarTimeColumn = React.memo((props: IGoogleCalendarTimeColumnProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [newGoogleCalendarEventStartAndEnd, setNewGoogleCalendarEventStartAndEnd] =
    useState<IStartAndEndForModal>({
      start: moment(),
      end: moment(),
      show: false,
    });
  const [showMoveGoogleCalendarModalData, setShowMoveGoogleCalendarModalData] =
    useState<IMoveGoogleCalendarModalData>({
      event: null,
      showModal: false,
    });
  const [showDeleteGoogleCalendarModalData, setShowDeleteGoogleCalendarModalData] =
    useState<IMoveGoogleCalendarModalData>({
      event: null,
      showModal: false,
    });
  const [deleteGoogleCalendarEvent, ____] = useDeleteGoogleCalendarEventMutation();
  const [updateGoogleCalendarEvent, _____] = useUpdateGoogleCalendarEventMutation();

  return (
    <>
      <TimeColumn
        readOnly={props.readOnly}
        isClosedDate={false}
        horizontalDragEnable={props.horizontalDragEnable}
        calendarType={'GoogleCalendar'}
        dayColumnRef={props.dayColumnRef}
        date={props.date}
        targetMember={props.targetMember}
        snapMinutes={props.snapMinutes}
        events={props.googleCalendarEvents}
        dragging={props.dragging}
        setDragging={props.setDragging}
        columnSetEventHover={props.columnSetEventHover}
        setColumnSetEventHover={props.setColumnSetEventHover}
        addEvents={(event) => {
          if (props.readOnly) {
            return;
          }
          setNewGoogleCalendarEventStartAndEnd({
            start: event.start,
            end: event.end,
            show: true,
          });
        }}
        moveEvent={(event) => {
          if (props.readOnly || event.googleCalendarEvent!.readonly) {
            return;
          }
          setShowMoveGoogleCalendarModalData({
            event: event,
            showModal: true,
          });
        }}
        deleteEvent={(event) => {
          if (props.readOnly || event.googleCalendarEvent!.readonly) {
            return;
          }
          setShowDeleteGoogleCalendarModalData({
            event: event,
            showModal: true,
          });
        }}
      />
      <CreateGoogleCalendarEventModal
        isConnectGoogleAccount={true}
        startDateTime={newGoogleCalendarEventStartAndEnd.start!}
        endDateTime={newGoogleCalendarEventStartAndEnd.end!}
        showModal={newGoogleCalendarEventStartAndEnd.show}
        calendarStartAndEndDateTime={{
          start: props.date.add(-1, 'week'),
          end: props.date.add(1, 'week'),
        }}
        onPressYes={(event) =>
          setNewGoogleCalendarEventStartAndEnd({
            start: moment(),
            end: moment(),
            show: false,
          })
        }
        onCloseModal={() =>
          setNewGoogleCalendarEventStartAndEnd({
            start: moment(),
            end: moment(),
            show: false,
          })
        }
      />
      <Modal
        title={`Googleカレンダーの予定を変更しますか？`}
        isShow={showMoveGoogleCalendarModalData.showModal}
        onClose={() => {
          setShowMoveGoogleCalendarModalData({
            event: null,
            showModal: false,
          });
        }}>
        <Form>
          <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
            <Button
              text={'キャンセル'}
              style={{
                minWidth: 100,
                marginRight: 10,
                marginVertical: 10,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.primary }}
              disableValidate={true}
              onPress={() => {
                setShowMoveGoogleCalendarModalData({
                  event: null,
                  showModal: false,
                });
              }}
            />
            <Button
              text={'変更する'}
              style={{
                minWidth: 100,
                marginRight: 10,
                marginVertical: 10,
                borderColor: themeContext.colors.error,
                borderRadius: 3,
                borderWidth: 1,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.error }}
              onPress={async () => {
                setShowMoveGoogleCalendarModalData({
                  event: null,
                  showModal: false,
                });
                updateGoogleCalendarEvent({
                  variables: {
                    id: showMoveGoogleCalendarModalData.event!.googleCalendarEvent!.id!,
                    input: {
                      calendarId:
                        showMoveGoogleCalendarModalData.event!.googleCalendarEvent!.calendarId,
                      title: showMoveGoogleCalendarModalData.event!.googleCalendarEvent!.title,
                      description:
                        showMoveGoogleCalendarModalData.event!.googleCalendarEvent!.description,
                      location:
                        showMoveGoogleCalendarModalData.event!.googleCalendarEvent!.location,
                      start: showMoveGoogleCalendarModalData.event!.start.toISOString(),
                      end: showMoveGoogleCalendarModalData.event!.end.toISOString(),
                      attendeeMailAddresses:
                        (showMoveGoogleCalendarModalData
                          .event!.googleCalendarEvent!.attendees!.map(
                            (attend) => attend?.mailAddress
                          )
                          .slice() as string[]) || [],
                    },
                  },
                });
              }}
            />
          </View>
        </Form>
      </Modal>
      <Modal
        title={`Googleカレンダーの予定を削除しますか？`}
        isShow={showDeleteGoogleCalendarModalData.showModal}
        onClose={() => {
          setShowDeleteGoogleCalendarModalData({
            event: null,
            showModal: false,
          });
        }}>
        <View style={{ marginTop: 10 }}>
          <Typography
            variant={TypographyType.Description}
            style={{ textAlign: 'center', color: themeContext.colors.error }}>
            {`この操作はやり直しが出来ません`}
          </Typography>
        </View>
        <Form>
          <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
            <Button
              text={'キャンセル'}
              style={{
                minWidth: 100,
                marginRight: 10,
                marginVertical: 10,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.primary }}
              disableValidate={true}
              onPress={() => {
                setShowDeleteGoogleCalendarModalData({
                  event: null,
                  showModal: false,
                });
              }}
            />
            <Button
              text={'削除する'}
              style={{
                minWidth: 100,
                marginRight: 10,
                marginVertical: 10,
                borderColor: themeContext.colors.error,
                borderRadius: 3,
                borderWidth: 1,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.error }}
              onPress={async () => {
                setShowDeleteGoogleCalendarModalData({
                  event: null,
                  showModal: false,
                });
                deleteGoogleCalendarEvent({
                  variables: {
                    id: showDeleteGoogleCalendarModalData.event!.googleCalendarEvent!.id!,
                    calendarId:
                      showDeleteGoogleCalendarModalData.event!.googleCalendarEvent!.calendarId,
                  },
                  update: (cache, result) => {
                    cache.evict({
                      id: cache.identify(
                        showDeleteGoogleCalendarModalData.event!.googleCalendarEvent!
                      ),
                    });
                  },
                });
              }}
            />
          </View>
        </Form>
      </Modal>
    </>
  );
});

interface IDayColumnProps {
  firstColumn: boolean;
  readOnly: boolean;
  targetMember: Member;
  showWorkingHistory: boolean;
  showWorkingSchedule: boolean;
  showGoogleCalendar: boolean;
  horizontalDragEnable: boolean;
  date: moment.Moment;
  snapMinutes: number;
  closingSetting: ClosingSetting;
  scheduleEvents: ICalendarEvent[];
  addScheduleEvent: (value: ICalendarEvent) => void;
  moveScheduleEvent: (value: ICalendarEvent) => void;
  deleteScheduleEvent: (value: CalendarWorkingSchedule) => void;
  historyEvents: ICalendarEvent[];
  isConnectGoogleAccount: boolean;
  googleCalendarEvents: ICalendarEvent[];
}

const DayColumn = React.memo((props: IDayColumnProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [dragging, setDragging] = useState(false);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [columnSetEventHover, setColumnSetEventHover] = useState(false);
  const dayColumnRef = useRef();

  let columnCount = 0;
  if (props.showWorkingHistory) {
    columnCount = columnCount + 1;
  }
  if (props.showWorkingSchedule) {
    columnCount = columnCount + 1;
  }
  if (props.showGoogleCalendar) {
    columnCount = columnCount + 1;
  }

  const isClosedDate = false;
    // !!props.closingSetting.targetDateTime &&
    // moment(props.date).startOf('date').isBefore(moment(props.closingSetting.targetDateTime));
  return (
    <View
      ref={dayColumnRef as any}
      style={{
        flexDirection: 'column',
        flex: 1,
        zIndex: dragging || columnSetEventHover ? 1 : 0,
        borderLeftWidth: 1,
        borderColor: themeContext.colors.separator,
        minWidth: 80 * columnCount,
      }}>
      <TimeColumnSet style={{ overflow: dragging || columnSetEventHover ? 'visible' : 'hidden' }}>
        {props.showWorkingHistory && (
          <WorkingHistoryTimeColumn
            readOnly={props.readOnly}
            isClosedDate={isClosedDate} // 締め処理済みの日付は読み取り専用に設定する
            closingTargetDateTime={props.closingSetting.targetDateTime}
            horizontalDragEnable={props.horizontalDragEnable}
            targetMember={props.targetMember}
            dayColumnRef={dayColumnRef}
            date={props.date}
            snapMinutes={props.snapMinutes}
            historyEvents={props.historyEvents} //TODO Cal
            dragging={dragging}
            setDragging={setDragging}
            columnSetEventHover={columnSetEventHover}
            setColumnSetEventHover={setColumnSetEventHover}
          />
        )}
        {props.showWorkingSchedule && (
          <TimeColumn
            readOnly={props.readOnly}
            isClosedDate={false}
            horizontalDragEnable={props.horizontalDragEnable}
            calendarType={'WorkingSchedule'}
            dayColumnRef={dayColumnRef}
            date={props.date}
            targetMember={props.targetMember}
            snapMinutes={props.snapMinutes}
            events={props.scheduleEvents} //TODO Cal
            addEvents={props.addScheduleEvent}
            moveEvent={props.moveScheduleEvent}
            deleteEvent={(event) => props.deleteScheduleEvent(event.workingSchedule!)}
            dragging={dragging}
            setDragging={setDragging}
            columnSetEventHover={columnSetEventHover}
            setColumnSetEventHover={setColumnSetEventHover}
          />
        )}
        {props.isConnectGoogleAccount && props.showGoogleCalendar && (
          <GoogleCalendarTimeColumn
            readOnly={props.readOnly || props.targetMember.id !== loginUser?.id} //外部カレンダーは、代理編集権限があるユーザーでも、他人が操作できないようにする
            horizontalDragEnable={props.horizontalDragEnable}
            dayColumnRef={dayColumnRef}
            date={props.date}
            targetMember={props.targetMember}
            snapMinutes={props.snapMinutes}
            googleCalendarEvents={props.googleCalendarEvents}
            dragging={dragging}
            setDragging={setDragging}
            columnSetEventHover={columnSetEventHover}
            setColumnSetEventHover={setColumnSetEventHover}
          />
        )}
      </TimeColumnSet>

      {props.date.format('YYYY/MM/DD') === moment().format('YYYY/MM/DD') && (
        <NowBar onlyLine={!props.firstColumn} />
      )}
    </View>
  );
});

const DateSelectHeader = React.memo((props: IDateSelectHeaderProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [startDateTime, setStartDateTime] = useState(props.startDateTime);
  const [selecting, setSelecting] = useState(false)

  useEffect(() => {
    setStartDateTime(props.startDateTime);
  }, [props.startDateTime]);

  useEffect(() => {
    if(selecting){
      setTimeout(() => setSelecting(false), 1000)
    }
  }, [selecting])

  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 0,
      }}>
      <LeftIcon
        size={30}
        disabled={selecting}
        onPress={() => {
          const newStartDateTime = moment(startDateTime).add(-1 * props.dateRange, 'days');
          setStartDateTime(newStartDateTime);
          props.setStartDateTime(newStartDateTime);
          setSelecting(true)
        }}
      />
      <EditableText
        value={startDateTime}
        type={'date-picker'}
        disableClear={true}
        containerStyle={{ paddingHorizontal: 10 }}
        // textStyle={{ minWidth: 200 }}
        onChange={(value) => {
          setStartDateTime((value as moment.Moment)!.startOf('day'));
          props.setStartDateTime((value as moment.Moment)!.startOf('day'));
        }}
        onBlur={(value) => {
          setStartDateTime(value as moment.Moment);
          props.setStartDateTime((value as moment.Moment)!.startOf('day'));
        }}
        renderComponent={(value) => {
          const start = moment(value);
          const end = moment(value).add(props.dateRange - 1, 'days');
          if (start.format('YYYY/MM/DD') === end.format('YYYY/MM/DD')) {
            return (
              <Typography
                variant={TypographyType.Normal}
                style={{ color: themeContext.colors.description }}>
                {`${start.format('YYYY/MM/DD')}`}
              </Typography>
            );
          }
          return (
            <Typography
              variant={TypographyType.Normal}
              style={{ color: themeContext.colors.description }}>
              {`${start.format('YYYY/MM/DD')} 〜 ${
                start.year() === end.year() ? end.format('MM/DD') : end.format('YYYY/MM/DD')
              }`}
            </Typography>
          );
        }}
      />
      <RightIcon
        size={30}
        disabled={selecting}
        onPress={() => {
          const newStartDateTime = moment(startDateTime).add(props.dateRange, 'days');
          setStartDateTime(newStartDateTime);
          props.setStartDateTime(newStartDateTime);
          setSelecting(true)
        }}
      />
      <Button
        text={'今日'}
        style={{
          backgroundColor: themeContext.colors.baseColor,
          borderWidth: 1,
          borderColor: themeContext.colors.separator,
          paddingVertical: 7,
          paddingHorizontal: 10,
          marginRight: 15,
          marginLeft: 5,
        }}
        textStyle={{ color: themeContext.colors.textColor, fontWeight: '400' }}
        disableValidate={true}
        onPress={() => {
          const newStartDateTime = moment().startOf('day');
          setStartDateTime(newStartDateTime);
          props.setStartDateTime(newStartDateTime);
        }}
      />
    </View>
  );
});

interface ICalendarEventHoverTooltipProps {
  isRightSideBar: boolean;
}

const CalendarEventHoverTooltip = React.memo((props: ICalendarEventHoverTooltipProps) => {
  const ref = useRef();
  const [event, _] = useContext(CalendarEventHoverContext);
  const [heightAndWidth, setHeightAndWidth] = useState({ height: 0, width: 0 });
  const calendarEvent = (event as CalendarHoverEventInfo)?.event;

  useEffect(() => {
    const rect = ref.current?.getBoundingClientRect();
    setTimeout(() => {
      setHeightAndWidth({ height: rect?.height ?? 0, width: rect?.width ?? 0 });
    }, 200);
  }, [ref, event]);

  if (!calendarEvent) {
    return <></>;
  }

  const memoSummary = calendarEvent.workingHistory?.memo
    ? JSON.parse(calendarEvent.workingHistory.memo)
        .blocks.map((b: any) => b.text)
        .join('\n')
    : calendarEvent.workingSchedule?.memo
    ? JSON.parse(calendarEvent.workingSchedule.memo)
        .blocks.map((b: any) => b.text)
        .join('\n')
    : undefined;

  return (
    <View
      ref={ref as any}
      style={{
        minWidth: 130,
        backgroundColor: '#FFFFFF',
        borderRadius: 5,
        borderWidth: 1,
        borderColor: '#ccc',
        position: 'fixed',
        top:
          window.innerHeight - 5 >= (event as CalendarHoverEventInfo)!.y + heightAndWidth.height - 5
            ? (event as CalendarHoverEventInfo)!.y
            : window.innerHeight - heightAndWidth.height - 5,
        left:
          window.innerWidth - 40 >= (event as CalendarHoverEventInfo)!.x + heightAndWidth.width
            ? (event as CalendarHoverEventInfo)!.x
            : (event as CalendarHoverEventInfo)!.x - 40,
        zIndex: 5,
        opacity: 0.9,
        paddingVertical: 5,
        paddingHorizontal: 10,
      }}>
      {calendarEvent.workingHistory && (
        <View>
          <View>
            <Text style={{ color: '#aaaaaa', fontSize: 8 }}>タスク</Text>
            <Text
              style={
                {
                  color: '#555555',
                  fontSize: 14,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  maxWidth: 250,
                } as any
              }>
              {calendarEvent.workingHistory!.task?.title ||
                calendarEvent.workingHistory!.calendarEvent?.eventName}
            </Text>
          </View>
          <View style={{ marginTop: 10 }}>
            <Text style={{ color: '#aaaaaa', fontSize: 8 }}>プロジェクト</Text>
            <Text
              style={
                {
                  color: '#555555',
                  fontSize: 14,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  maxWidth: 250,
                } as any
              }>
              {calendarEvent.workingHistory!.task?.project.name ||
                calendarEvent.workingHistory!.calendarEvent?.projectName}
            </Text>
          </View>
          {(calendarEvent.workingHistory!.task?.project.team.personalTeam === false ||
            calendarEvent.workingHistory!.calendarEvent?.personalTeam === false) && (
            <View style={{ marginTop: 10 }}>
              <Text style={{ color: '#aaaaaa', fontSize: 8 }}>チーム</Text>
              <Text
                style={
                  {
                    color: '#555555',
                    fontSize: 14,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    maxWidth: 250,
                  } as any
                }>
                {calendarEvent.workingHistory!.task?.project.team.name ??
                  calendarEvent.workingHistory!.calendarEvent?.teamName}
              </Text>
            </View>
          )}
          {(calendarEvent.workingHistory!.task?.project.client?.name ||
            calendarEvent.workingHistory!.calendarEvent?.clientName) && (
            <View style={{ marginTop: 10 }}>
              <Text style={{ color: '#aaaaaa', fontSize: 8 }}>取引先</Text>
              <Text
                style={
                  {
                    color: '#555555',
                    fontSize: 14,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    maxWidth: 250,
                  } as any
                }>
                {calendarEvent.workingHistory!.task?.project.client?.name ||
                  calendarEvent.workingHistory!.calendarEvent?.clientName}
              </Text>
            </View>
          )}
          <View style={{ marginTop: 10 }}>
            <Text style={{ color: '#aaaaaa', fontSize: 8 }}>作業時間</Text>
            <Text style={{ color: '#555555', fontSize: 14 }}>
              {moment(calendarEvent.workingHistory!.start).format('HH:mm')}〜
              {calendarEvent.workingHistory!.end
                ? moment(calendarEvent.workingHistory!.end).format('HH:mm')
                : moment().format('HH:mm')}
              {'\n'}(
              {calendarEvent.workingHistory!.end
                ? TimeUtil.formatForTask(calendarEvent.workingHistory!.workingTimeSec!)
                : TimeUtil.formatForTask(
                    moment().diff(moment(calendarEvent.workingHistory!.start), 'seconds')
                  )}
              )
            </Text>
          </View>
          {memoSummary && (
            <View style={{ marginTop: 10 }}>
              <Text style={{ color: '#aaaaaa', fontSize: 8 }}>メモ</Text>
              <Text style={{ color: '#555555', fontSize: 14 }}>{memoSummary}</Text>
            </View>
          )}
        </View>
      )}
      {calendarEvent.workingSchedule && (
        <View>
          <View>
            <Text style={{ color: '#aaaaaa', fontSize: 8 }}>タスク</Text>
            <Text
              style={
                {
                  color: '#555555',
                  fontSize: 14,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  maxWidth: 250,
                } as any
              }>
              {calendarEvent.workingSchedule!.task?.title ??
                calendarEvent.workingSchedule!.calendarEvent?.eventName}
            </Text>
          </View>
          <View style={{ marginTop: 10 }}>
            <Text style={{ color: '#aaaaaa', fontSize: 8 }}>プロジェクト</Text>
            <Text
              style={
                {
                  color: '#555555',
                  fontSize: 14,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  maxWidth: 250,
                } as any
              }>
              {calendarEvent.workingSchedule!.task?.project.name ??
                calendarEvent.workingSchedule!.calendarEvent?.eventName}
            </Text>
          </View>
          {(calendarEvent.workingSchedule!.task?.project.team.personalTeam === false ||
            calendarEvent.workingSchedule!.calendarEvent?.personalTeam === false) && (
            <View style={{ marginTop: 10 }}>
              <Text style={{ color: '#aaaaaa', fontSize: 8 }}>チーム</Text>
              <Text
                style={
                  {
                    color: '#555555',
                    fontSize: 14,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    maxWidth: 250,
                  } as any
                }>
                {calendarEvent.workingSchedule!.task?.project.team.name ??
                  calendarEvent.workingSchedule!.calendarEvent?.teamName}
              </Text>
            </View>
          )}
          {(calendarEvent.workingSchedule!.task?.project.client?.name ||
            calendarEvent.workingSchedule!.calendarEvent?.clientName) && (
            <View style={{ marginTop: 10 }}>
              <Text style={{ color: '#aaaaaa', fontSize: 8 }}>取引先</Text>
              <Text
                style={
                  {
                    color: '#555555',
                    fontSize: 14,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    maxWidth: 250,
                  } as any
                }>
                {calendarEvent.workingSchedule!.task?.project.client?.name ||
                  calendarEvent.workingSchedule!.calendarEvent?.clientName}
              </Text>
            </View>
          )}
          <View style={{ marginTop: 10 }}>
            <Text style={{ color: '#aaaaaa', fontSize: 8 }}>作業時間</Text>
            <Text style={{ color: '#555555', fontSize: 14 }}>
              {moment(calendarEvent.workingSchedule!.start).format('HH:mm')}〜
              {calendarEvent.workingSchedule!.end
                ? moment(calendarEvent.workingSchedule!.end).format('HH:mm')
                : moment().format('HH:mm')}
              {'\n'}(
              {calendarEvent.workingSchedule!.end
                ? TimeUtil.formatForTask(calendarEvent.workingSchedule!.workingTimeSec!)
                : TimeUtil.formatForTask(
                    moment().diff(moment(calendarEvent.workingSchedule!.start), 'seconds')
                  )}
              )
            </Text>
          </View>
          {memoSummary && (
            <View style={{ marginTop: 10 }}>
              <Text style={{ color: '#aaaaaa', fontSize: 8 }}>メモ</Text>
              <Text style={{ color: '#555555', fontSize: 14 }}>{memoSummary}</Text>
            </View>
          )}
        </View>
      )}
      {calendarEvent.googleCalendarEvent && (
        <View>
          <View>
            <Text style={{ color: '#aaaaaa', fontSize: 8 }}>タイトル</Text>
            <Text
              style={
                {
                  color: '#555555',
                  fontSize: 12,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  maxWidth: 150,
                } as any
              }>
              {calendarEvent.googleCalendarEvent!.title ||
                (calendarEvent.googleCalendarEvent.visivility ===
                  GoogleCalendarEventVisivility.Private ||
                calendarEvent.googleCalendarEvent.visivility ===
                  GoogleCalendarEventVisivility.Confidential
                  ? '予定あり'
                  : '(タイトルなし)')}
            </Text>
          </View>
          <View style={{ marginTop: 10 }}>
            <Text style={{ color: '#aaaaaa', fontSize: 8 }}>時間</Text>
            <Text style={{ color: '#555555', fontSize: 14 }}>
              {moment(calendarEvent.googleCalendarEvent!.startDateTime).format('HH:mm')}〜
              {calendarEvent.googleCalendarEvent!.endDateTime
                ? moment(calendarEvent.googleCalendarEvent!.endDateTime).format('HH:mm')
                : moment().format('HH:mm')}
            </Text>
          </View>
        </View>
      )}
    </View>
  );
});

const Overlay = styled.TouchableOpacity`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  opacity: 0;
  display: block;
  z-index: 99;
  transition: all 0.4s;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface ICalendarContextMenuProps {
  isRightSideBar: boolean;
  myGoogleCalendars: GoogleCalendar[];
}

const CalendarContextMenu = React.memo((props: ICalendarContextMenuProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const { height: windowHeight, width: windowWidth } = useWindowDimensions();
  const [event, setEvent] = useContext(CalendarContextMenuContext);
  const calendarEvent = (event as CalendarContextMenuInfo)?.event;
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [deleteGoogleCalendarEvent, ____] = useDeleteGoogleCalendarEventMutation();
  const [deleteWorkingHistory, __] = useDeleteWorkingHistoryMutation();
  const [startWorkingHistory, ______] = useStartTaskMutation();
  const [stopWorkingHistory, _______] = useStopTaskMutation();
  const [deleteWorkingSchedule] = useDeleteWorkingScheduleMutation();
  const [showCompleteTaskStartErrorModal, setShowCompleteTaskStartErrorModal] = useState(false);

  const { loading, data } = useMeQuery();
  const { data: mySettingsData } = useMySettingsQuery({
    fetchPolicy: 'network-only',
  });

  const [showDeleteGoogleCalendarModalData, setShowDeleteGoogleCalendarModalData] =
    useState<IMoveGoogleCalendarModalData>({
      event: null,
      showModal: false,
    });
  const [
    showCopyWorkingHistoryModalData,
    setShowCopyWorkingHistoryModalData,
  ] = useState<ICopyWorkingHistoryModalData>({
    event: null,
    showModal: false,
  });  
  const [
    showCopyWorkingScheduleModalData,
    setShowCopyWorkingScheduleModalData,
  ] = useState<ICopyWorkingScheduleModalData>({
    event: null,
    showModal: false,
  });  
  const [
    showCreateWorkingHistoryFromGoogleCalendarModalData,
    setShowCreateWorkingHistoryFromGoogleCalendarModalData,
  ] = useState<ICreateWorkingHistoryFromGoogleCalendarModalData>({
    event: null,
    showModal: false,
  });
  const [
    showCreateWorkingScheduleFromGoogleCalendarModalData,
    setShowCreateWorkingScheduleFromGoogleCalendarModalData,
  ] = useState<ICreateWorkingScheduleFromGoogleCalendarModalData>({
    event: null,
    showModal: false,
  });
  const [showEditWorkingHistoryModalData, setShowEditWorkingHistoryModalData] =
    useState<IEditWorkingHistory>({
      event: null,
      showModal: false,
    });
  const [showMoveWorkingHistoryModalData, setShowMoveWorkingHistoryModalData] =
  useState<IEditWorkingHistory>({
    event: null,
    showModal: false,
  });
  const [showEditWorkingScheduleModalData, setShowEditWorkingScheduleModalData] =
    useState<IEditWorkingSchedule>({
      event: null,
      showModal: false,
    });
  const [showMoveWorkingScheduleModalData, setShowMoveWorkingScheduleModalData] =
    useState<IEditWorkingSchedule>({
      event: null,
      showModal: false,
    });

  const [createWorkingHistory] = useCreateWorkingHistoryMutation({
    update: (cache, result) => {
      cache.modify({
        fields: {
          taskWorkingHistories(existing = [], { storeFieldName }) {
            const newWorkingHistory = cache.writeQuery({
              data: result.data!.createWorkingHistory,
              query: WorkingHistoryDocument,
            });
            return [...existing, newWorkingHistory];
          },
          workingHistoriesSpecifyTermForCalendar(existing = [], { storeFieldName }) {
            const newWorkingHistory = cache.writeQuery({
              data: result.data!.createWorkingHistory,
              query: WorkingHistoryDocument,
            });
            const targetMemberData = existing.filter((d: any) => d.memberId === loginUser?.id)[0];
            if((targetMemberData?.length ?? 0) === 0){
              return [...existing, {
                memberId: result.data!.createWorkingHistory?.workingMember.id,
                workingHistories: [newWorkingHistory]
              }];
            }
            return existing.map((data: any) => {
              if(data.memberId !== loginUser?.id){
                return data;
              }
              return {
                memberId: data.memberId,
                workingHistories: [...targetMemberData.workingHistories, newWorkingHistory]
              }
            })
          },
        },
      });
    },
    refetchQueries: [
      {
        query: LatestWorkingHistoryDocument,
      },
    ],
  });

  const [createWorkingHistoryByCalendarEvent] = useCreateWorkingHistoryByCalendarEventMutation({
    update: (cache, result) => {
      cache.modify({
        fields: {
          workingHistoriesSpecifyTermForCalendar(existing = [], { storeFieldName }) {
            const newWorkingHistory = cache.writeQuery({
              data: result.data!.createWorkingHistoryByCalendarEvent,
              query: WorkingHistoryDocument,
            });
            const targetMemberData = existing.filter((d: any) => d.memberId === loginUser?.id)[0];
            if((targetMemberData?.length ?? 0) === 0){
              return [...existing, {
                memberId: result.data!.createWorkingHistoryByCalendarEvent?.workingMember.id,
                workingHistories: [newWorkingHistory]
              }];
            }
            return existing.map((data: any) => {
              if(data.memberId !== loginUser?.id){
                return data;
              }
              return {
                memberId: data.memberId,
                workingHistories: [...targetMemberData.workingHistories, newWorkingHistory]
              }
            })
          },
        },
      });
    },
  });

  const [createWorkingSchedule] =  useCreateWorkingScheduleMutation({
    update: (cache, result) => {
      cache.modify({
        fields: {
          workingSchedulesSpecifyTermForCalendar(existing = [], { storeFieldName }) {
            const newWorkingSchedule = cache.writeQuery({
              data: result.data!.createWorkingSchedule,
              query: WorkingScheduleDocument,
            });
            const targetMemberData = existing.filter((d: any) => d.memberId === loginUser?.id)[0];
            if((targetMemberData?.length ?? 0) === 0){
              return [...existing, {
                memberId: loginUser?.id,
                workingSchedules: [newWorkingSchedule]
              }];
            }
            return existing.map((data: any) => {
              if(data.memberId !== loginUser?.id){
                return data;
              }
              return {
                memberId: data.memberId,
                workingSchedules: [...targetMemberData.workingSchedules, newWorkingSchedule]
              }
            })
          },
        },
      });
    },
  })

  const overFlowHeight =
    (event as CalendarHoverEventInfo)?.y &&
    (event as CalendarHoverEventInfo)!.y > windowHeight - 150
      ? (event as CalendarHoverEventInfo)!.y - (windowHeight - 150)
      : 0;

  const overFlowWidth =
    (event as CalendarHoverEventInfo)?.x && (event as CalendarHoverEventInfo)!.x > windowWidth - 250
      ? (event as CalendarHoverEventInfo)!.x - (windowWidth - 250)
      : 0;

  return (
    <>
      {calendarEvent && (
        <>
          <Overlay
            onPress={() => {
              //@ts-ignore
              setEvent(null);
            }}></Overlay>
          <View
            style={{
              minWidth: 130,
              backgroundColor: '#FFFFFF',
              borderRadius: 5,
              borderWidth: 1,
              borderColor: '#ccc',
              position: 'absolute',
              top:
                (event as CalendarHoverEventInfo)!.y -
                (props.isRightSideBar ? 150 : 200) -
                overFlowHeight,
              left:
                (event as CalendarHoverEventInfo)!.x -
                (props.isRightSideBar ? (event as CalendarHoverEventInfo)!.x - 200 : 200) -
                overFlowWidth,
              zIndex: 100,
              opacity: 0.9,
              paddingVertical: 5,
              paddingHorizontal: 10,
              alignItems: 'flex-start',
            }}>
            {calendarEvent.workingHistory && (
              <>
                {(calendarEvent.workingHistory.task?.workingMembers || []).filter(
                  (member) => member!.member.id === loginUser!.id
                ).length > 0 ? (
                  <StopIcon
                    size={20}
                    containerStyle={{ marginBottom: 10 }}
                    onPress={() => {
                      stopWorkingHistory({
                        variables: {
                          id: calendarEvent.workingHistory!.task!.id!,
                          input: {
                            versionNo: calendarEvent.workingHistory!.task!.versionNo,
                          },
                        },
                        update: (cache, result) => {
                          // レスポンスの中のネストされたデータは、自動的にはキャッシュ更新されないので、自前でキャッシュ更新している
                          cache.writeQuery({
                            query: TaskDocument,
                            variables: { id: result.data!.stopTask!.stopTask.id! },
                            data: { task: result.data!.stopTask!.stopTask },
                          });
                        },
                        refetchQueries: [
                          //TODO なぜかteamが取得できない。サーバーサイドで削られている
                          // {
                          //   query: TaskWorkingHistorySummaryGroupByAssineeDocument,
                          //   variables: {
                          //     teamId: calendarEvent.workingHistory!.task!.project.team.id!,
                          //     taskId: calendarEvent.workingHistory!.task!.id!,
                          //   },
                          // },
                          {
                            query: LatestWorkingHistoryDocument,
                          },
                          {
                            query: ProjectWorkingTimeSecDocument,
                            variables: {
                              id: calendarEvent.workingHistory!.task!.project.id,
                            },
                          },
                          {
                            query: CalendarWorkingHistoryDocument,
                            variables: {
                              id: calendarEvent.workingHistory!.id,
                            },
                          },
                        ],
                      });
                      //@ts-ignore
                      setEvent(null);
                    }}>
                    <Typography variant={TypographyType.Normal}>作業停止する</Typography>
                  </StopIcon>
                ) : (
                  <>
                    {calendarEvent.workingHistory.task &&
                      calendarEvent.workingHistory.workingMember.id === loginUser?.id && (
                        <PlayIcon
                          size={20}
                          containerStyle={{ marginBottom: 10 }}
                          onPress={() => {
                            if (
                              !!calendarEvent!.workingHistory!.task!.completeDateTime ||
                              calendarEvent!.workingHistory!.task!.project.complete
                            ) {
                              setShowCompleteTaskStartErrorModal(true);
                              return;
                            }
                            startWorkingHistory({
                              variables: {
                                id: calendarEvent.workingHistory!.task!.id!,
                                input: {
                                  versionNo: calendarEvent.workingHistory!.task!.versionNo,
                                },
                              },
                              update: (cache, result) => {
                                // レスポンスの中のネストされたデータは、自動的にはキャッシュ更新されないので、自前でキャッシュ更新している
                                cache.writeQuery({
                                  query: TaskDocument,
                                  variables: { id: result.data!.startTask!.startTask.id! },
                                  data: { task: result.data!.startTask!.startTask },
                                });
                                cache.writeQuery({
                                  query: LatestWorkingHistoryDocument,
                                  data: {
                                    latestWorkingHistory:
                                      result.data!.startTask!.startWorkingHistory,
                                  },
                                });
                                if (result.data?.startTask?.stopTask) {
                                  cache.writeQuery({
                                    query: TaskDocument,
                                    variables: { id: result.data!.startTask!.stopTask!.id! },
                                    data: { task: result.data!.startTask!.stopTask },
                                  });
                                }
                                cache.modify({
                                  fields: {
                                    workingHistoriesSpecifyTermForCalendar(existing = [], { storeFieldName }) {
                                      const newWorkingHistories = cache.writeQuery({
                                        query: CalendarWorkingHistoryDocument,
                                        data: result.data?.startTask?.stopWorkingHistory,
                                      });
                                      const targetMemberData = existing.filter((d: any) => d.memberId === loginUser?.id)[0];
                                      if((targetMemberData?.length ?? 0) === 0){
                                        return [...existing, {
                                          memberId: loginUser?.id,
                                          workingHistories: newWorkingHistories
                                        }];
                                      }
                                      return existing.map((data: any) => {
                                        if(data.memberId !== loginUser?.id){
                                          return data;
                                        }
                                        return {
                                          memberId: data.memberId,
                                          workingHistories: [...targetMemberData.workingHistories, newWorkingHistories]
                                        }
                                      })
                                    },
                                  },
                                });
                              },
                              refetchQueries: [
                                //TODO なぜかteamが取得できない。サーバーサイドで削られている
                                // {
                                //   query: TaskWorkingHistorySummaryGroupByAssineeDocument,
                                //   variables: {
                                //     teamId: calendarEvent.workingHistory!.task!.project.team.id!,
                                //     taskId: calendarEvent.workingHistory!.task!.id!,
                                //   },
                                // },                       
                                {
                                  query: LatestWorkingHistoryDocument,
                                },
                              ],
                            });
                            //@ts-ignore
                            setEvent(null);
                          }}>
                          <Typography variant={TypographyType.Normal}>作業再開する</Typography>
                        </PlayIcon>
                      )}
                  </>
                )}
                {(calendarEvent.workingHistory.workingMember.id === loginUser?.id || data?.me?.proxyEditWorkingDataRole) && (
                    <>
                      <EditIcon
                        size={21}
                        containerStyle={{ marginRight: 5, marginBottom: 8 }}
                        onPress={() => {
                          //@ts-ignore
                          setEvent(null);
                          setShowEditWorkingHistoryModalData({
                            event: calendarEvent.workingHistory,
                            showModal: true,
                          });
                        }}>
                        <Typography variant={TypographyType.Normal}>編集する</Typography>
                      </EditIcon>
                      <FileMoveIcon
                        size={21}
                        containerStyle={{ marginRight: 5 }}
                        onPress={() => {
                          //@ts-ignore
                          setEvent(null);
                          setShowMoveWorkingHistoryModalData({
                            event: calendarEvent.workingHistory,
                            showModal: true,
                          });
                        }}>
                        <Typography variant={TypographyType.Normal}>他のタスクへ移動する</Typography>
                      </FileMoveIcon>
                    </>
                )}
                {calendarEvent.workingHistory?.task && calendarEvent.workingHistory?.end && (
                  <CopyIcon
                    size={21}
                    containerStyle={{ marginRight: 5 }}
                    onPress={async () => {
                      if (calendarEvent.workingHistory?.task) {
                        if(calendarEvent.workingHistory?.workingMember.id === loginUser?.id){
                          setShowCopyWorkingHistoryModalData({
                            event: calendarEvent,
                            showModal: true,
                          })
                        } else {
                          await createWorkingHistory({
                            variables: {
                              taskId: calendarEvent.workingHistory!.task.id!,
                              input: {
                                start: moment(calendarEvent.workingHistory!.start!).toISOString(),
                                end: moment(calendarEvent.workingHistory!.end!).toISOString(),
                                targetMemberId: loginUser?.id,
                                memo:
                                  mySettingsData?.mySettings?.copyWorkingScheduleMemoToHistory ===
                                  true
                                    ? calendarEvent.workingHistory.memo
                                    : null,
                              },
                            },
                          });
                        }
                      }
                      //@ts-ignore
                      setEvent(null);
                    }}>
                    {calendarEvent.workingHistory?.workingMember.id === loginUser?.id ? (
                      <Typography variant={TypographyType.Normal}>作業履歴を複製する</Typography>
                    ) : (
                      <Typography variant={TypographyType.Normal}>自分の作業履歴として複製する</Typography>
                    )}
                    </CopyIcon>
                )}
                {(calendarEvent.workingHistory.workingMember.id === loginUser?.id || data?.me?.proxyEditWorkingDataRole) && (
                  <DeleteIcon
                    size={23}
                    containerStyle={{ marginRight: 5, left: -3 }}
                    onPress={() => {
                      deleteWorkingHistory({
                        variables: {
                          id: calendarEvent.workingHistory!.id!,
                          input: {
                            versionNo: calendarEvent.workingHistory!.versionNo,
                          },
                        },
                        update: (cache, result) => {
                          cache.evict({
                            id: cache.identify(calendarEvent.workingHistory!),
                          });
                        },
                        refetchQueries: calendarEvent.workingHistory!.task
                          ? [
                              {
                                query: TaskDocument,
                                variables: {
                                  id: calendarEvent.workingHistory!.task.id!,
                                },
                              },
                              {
                                query: LatestWorkingHistoryDocument,
                              },
                              {
                                query: ProjectWorkingTimeSecDocument,
                                variables: {
                                  id: calendarEvent.workingHistory!.task.project.id,
                                },
                              },
                            ]
                          : [
                              {
                                query: ProjectWorkingTimeSecDocument,
                                variables: {
                                  id: calendarEvent.workingHistory!.calendarEvent!.projectId,
                                },
                              },
                            ],
                      });
                      //@ts-ignore
                      setEvent(null);
                    }}>
                    <Typography variant={TypographyType.Normal}>削除する</Typography>
                  </DeleteIcon>
                )}
                </>
            )}
            {calendarEvent.workingSchedule && (
              <>
                {(calendarEvent.workingSchedule.task?.workingMembers ?? []).filter(
                  (member) => member!.member.id === loginUser!.id
                ).length > 0 ? (
                  <StopIcon
                    size={20}
                    containerStyle={{ marginBottom: 10 }}
                    onPress={() => {
                      stopWorkingHistory({
                        variables: {
                          id: calendarEvent.workingSchedule!.task!.id!,
                          input: {
                            versionNo: calendarEvent.workingSchedule!.task!.versionNo,
                          },
                        },
                        update: (cache, result) => {
                          // レスポンスの中のネストされたデータは、自動的にはキャッシュ更新されないので、自前でキャッシュ更新している
                          cache.writeQuery({
                            query: TaskDocument,
                            variables: { id: result.data!.stopTask!.stopTask.id! },
                            data: { task: result.data!.stopTask!.stopTask },
                          });
                        },
                        refetchQueries: [
                          {
                            query: TaskWorkingHistorySummaryGroupByAssineeDocument,
                            variables: {
                              teamId: calendarEvent.workingSchedule!.task!.project.team.id!,
                              taskId: calendarEvent.workingSchedule!.task!.id!,
                            },
                          },
                          {
                            query: LatestWorkingHistoryDocument,
                          },
                          {
                            query: ProjectWorkingTimeSecDocument,
                            variables: {
                              id: calendarEvent.workingSchedule!.task!.project.id,
                            },
                          },
                        ],
                      });
                      //@ts-ignore
                      setEvent(null);
                    }}>
                    <Typography variant={TypographyType.Normal}>
                      このタスクを作業停止する
                    </Typography>
                  </StopIcon>
                ) : (
                  <>
                    {calendarEvent.workingSchedule.task &&
                      calendarEvent.workingSchedule.workingMember.id === loginUser?.id && (
                        <PlayIcon
                          size={20}
                          containerStyle={{ marginBottom: 10, justifyContent: 'flex-start' }}
                          onPress={() => {
                            if (
                              !!calendarEvent!.workingSchedule!.task!.completeDateTime ||
                              calendarEvent!.workingSchedule!.task!.project.complete
                            ) {
                              setShowCompleteTaskStartErrorModal(true);
                              return;
                            }
                            startWorkingHistory({
                              variables: {
                                id: calendarEvent.workingSchedule!.task!.id!,
                                input: {
                                  workingHistoryMemo:
                                    mySettingsData?.mySettings?.copyWorkingScheduleMemoToHistory ===
                                    true
                                      ? calendarEvent!.workingSchedule!.memo
                                      : null,
                                  versionNo: calendarEvent.workingSchedule!.task!.versionNo,
                                },
                              },
                              update: (cache, result) => {
                                // レスポンスの中のネストされたデータは、自動的にはキャッシュ更新されないので、自前でキャッシュ更新している
                                cache.writeQuery({
                                  query: TaskDocument,
                                  variables: { id: result.data!.startTask!.startTask.id! },
                                  data: { task: result.data!.startTask!.startTask },
                                });
                                if (result.data?.startTask?.stopTask) {
                                  cache.writeQuery({
                                    query: TaskDocument,
                                    variables: { id: result.data!.startTask!.stopTask!.id! },
                                    data: { task: result.data!.startTask!.stopTask },
                                  });
                                }
                              },
                              refetchQueries: [
                                {
                                  query: LatestWorkingHistoryDocument,
                                },
                              ],
                            });
                            //@ts-ignore
                            setEvent(null);
                          }}>
                          <Typography variant={TypographyType.Normal}>
                            このタスクを作業開始する
                          </Typography>
                        </PlayIcon>
                      )}
                    {calendarEvent.workingSchedule.workingMember.id === loginUser?.id && (
                      <CopyIcon
                        size={20}
                        containerStyle={{ marginBottom: 10 }}
                        onPress={async () => {
                          if (calendarEvent.workingSchedule?.task) {
                            await createWorkingHistory({
                              variables: {
                                taskId: calendarEvent.workingSchedule!.task.id!,
                                input: {
                                  start: moment(calendarEvent.workingSchedule!.start!).toISOString(),
                                  end: moment(calendarEvent.workingSchedule!.end!).toISOString(),
                                  targetMemberId:
                                    loginUser?.id !== calendarEvent.workingSchedule.workingMember.id
                                      ? calendarEvent.workingSchedule.workingMember.id
                                      : undefined,
                                  memo:
                                    mySettingsData?.mySettings?.copyWorkingScheduleMemoToHistory ===
                                    true
                                      ? calendarEvent.workingSchedule.memo
                                      : null,
                                },
                              },
                            });
                          }
                          if (calendarEvent.workingSchedule?.calendarEvent) {
                            createWorkingHistoryByCalendarEvent({
                              variables: {
                                calendarEventId: calendarEvent.workingSchedule.calendarEvent.id,
                                input: {
                                  start: moment(calendarEvent.workingSchedule!.start!).toISOString(),
                                  end: moment(calendarEvent.workingSchedule!.end!).toISOString(),
                                  targetMemberId:
                                    loginUser?.id !== calendarEvent.workingSchedule.workingMember.id
                                      ? calendarEvent.workingSchedule.workingMember.id
                                      : undefined,
                                  memo:
                                    mySettingsData?.mySettings?.copyWorkingScheduleMemoToHistory ===
                                    true
                                      ? calendarEvent.workingSchedule.memo
                                      : null,
                                },
                              },
                            });
                          }
                          //@ts-ignore
                          setEvent(null);
                        }}>
                        <Typography variant={TypographyType.Normal}>
                          この予定から作業履歴を作成する
                        </Typography>
                      </CopyIcon>
                    )}
                  </>
                )}
                {(calendarEvent.workingSchedule.workingMember.id === loginUser?.id || data?.me?.proxyEditWorkingDataRole) && (
                  <>
                    <EditIcon
                      size={21}
                      containerStyle={{ marginRight: 5, marginBottom: 8 }}
                      onPress={() => {
                        //@ts-ignore
                        setEvent(null);
                        setShowEditWorkingScheduleModalData({
                          event: calendarEvent.workingSchedule,
                          showModal: true,
                        });
                      }}>
                      <Typography variant={TypographyType.Normal}>編集する</Typography>
                    </EditIcon>
                    <FileMoveIcon
                      size={21}
                      containerStyle={{ marginRight: 5 }}
                      onPress={() => {
                        //@ts-ignore
                        setEvent(null);
                        setShowMoveWorkingScheduleModalData({
                          event: calendarEvent.workingSchedule,
                          showModal: true,
                        });
                      }}>
                      <Typography variant={TypographyType.Normal}>他のタスクへ移動する</Typography>
                    </FileMoveIcon>
                  </>
                )}
                <CopyIcon
                  size={21}
                  containerStyle={{ marginRight: 5 }}
                  onPress={async () => {
                    if (calendarEvent.workingSchedule?.task) {
                      if(calendarEvent.workingSchedule?.workingMember.id === loginUser?.id){
                        setShowCopyWorkingScheduleModalData({
                          event: calendarEvent,
                          showModal: true,
                        })
                      } else {
                        await createWorkingSchedule({
                          variables: {
                            taskId: calendarEvent.workingSchedule!.task.id!,
                            input: {
                              start: moment(calendarEvent.workingSchedule!.start!).toISOString(),
                              end: moment(calendarEvent.workingSchedule!.end!).toISOString(),
                              targetMemberId: loginUser?.id,
                              memo:
                                mySettingsData?.mySettings?.copyWorkingScheduleMemoToHistory ===
                                true
                                  ? calendarEvent.workingSchedule.memo
                                  : null,
                            },
                          },
                        });
                      }
                    }
                    //@ts-ignore
                    setEvent(null);
                  }}>
                  {calendarEvent.workingSchedule?.workingMember.id === loginUser?.id ? (
                    <Typography variant={TypographyType.Normal}>作業予定を複製する</Typography>
                  ) : (
                    <Typography variant={TypographyType.Normal}>自分の作業予定として複製する</Typography>
                  )}
                </CopyIcon>
                {(calendarEvent.workingSchedule.workingMember.id === loginUser?.id || data?.me?.proxyEditWorkingDataRole) && (
                  <DeleteIcon
                    size={23}
                    containerStyle={{ marginRight: 5 }}
                    onPress={async () => {
                      await deleteWorkingSchedule({
                        variables: {
                          id: calendarEvent.workingSchedule!.id!,
                          input: {
                            versionNo: calendarEvent.workingSchedule!.versionNo,
                          },
                        },
                        update: (cache, result) => {
                          cache.evict({
                            id: cache.identify(calendarEvent.workingSchedule!),
                          });
                          cache.evict({ id: `WorkingScheduleForSummary:${calendarEvent.workingSchedule!.id}` });
                          cache.evict({ id: `CalendarWorkingSchedule:${calendarEvent.workingSchedule!.id}` });                    
                        },
                      });
                      //@ts-ignore
                      setEvent(null);
                    }}>
                    <Typography variant={TypographyType.Normal}>削除する</Typography>
                  </DeleteIcon>
                )}
                </>
            )}
            {calendarEvent.googleCalendarEvent && calendarEvent.targetMember.id === loginUser.id && (
              <>
                <CopyIcon
                  size={20}
                  containerStyle={{ marginBottom: 10 }}
                  onPress={async () => {
                    setShowCreateWorkingHistoryFromGoogleCalendarModalData({
                      showModal: true,
                      event: calendarEvent,
                    });
                  }}>
                  <Typography variant={TypographyType.Normal}>
                    この予定から作業履歴を作成する
                  </Typography>
                </CopyIcon>
                <CopyIcon
                  size={20}
                  containerStyle={{ marginBottom: 10 }}
                  onPress={async () => {
                    setShowCreateWorkingScheduleFromGoogleCalendarModalData({
                      showModal: true,
                      event: calendarEvent,
                    });
                  }}>
                  <Typography variant={TypographyType.Normal}>
                    この予定から作業予定を作成する
                  </Typography>
                </CopyIcon>
                <DeleteIcon
                  size={23}
                  containerStyle={{ marginRight: 5 }}
                  onPress={() => {
                    setShowDeleteGoogleCalendarModalData({
                      event: calendarEvent,
                      showModal: true,
                    });
                    //@ts-ignore
                    setEvent(null);
                  }}>
                  <Typography variant={TypographyType.Normal}>削除する</Typography>
                </DeleteIcon>
              </>
            )}
          </View>
        </>
      )}
      <Modal
        title={`Googleカレンダーの予定を削除しますか？`}
        isShow={showDeleteGoogleCalendarModalData.showModal}
        onClose={() => {
          setShowDeleteGoogleCalendarModalData({
            event: null,
            showModal: false,
          });
        }}>
        <View style={{ marginTop: 10 }}>
          <Typography
            variant={TypographyType.Description}
            style={{ textAlign: 'center', color: themeContext.colors.error }}>
            {`この操作はやり直しが出来ません`}
          </Typography>
        </View>
        <Form>
          <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
            <Button
              text={'キャンセル'}
              style={{
                minWidth: 100,
                marginRight: 10,
                marginVertical: 10,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.primary }}
              disableValidate={true}
              onPress={() => {
                setShowDeleteGoogleCalendarModalData({
                  event: null,
                  showModal: false,
                });
              }}
            />
            <Button
              text={'削除する'}
              style={{
                minWidth: 100,
                marginRight: 10,
                marginVertical: 10,
                borderColor: themeContext.colors.error,
                borderRadius: 3,
                borderWidth: 1,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.error }}
              onPress={async () => {
                setShowDeleteGoogleCalendarModalData({
                  event: null,
                  showModal: false,
                });
                deleteGoogleCalendarEvent({
                  variables: {
                    id: showDeleteGoogleCalendarModalData.event!.googleCalendarEvent!.id!,
                    calendarId:
                      showDeleteGoogleCalendarModalData.event!.googleCalendarEvent!.calendarId,
                  },
                  update: (cache, result) => {
                    cache.evict({
                      id: cache.identify(
                        showDeleteGoogleCalendarModalData.event!.googleCalendarEvent!
                      ),
                    });
                  },
                });
              }}
            />
          </View>
        </Form>
      </Modal>
      <CopyWorkingHistoryModal
        workingHistory={showCopyWorkingHistoryModalData?.event?.workingHistory ?? null}
        showModal={showCopyWorkingHistoryModalData.showModal}
        onCloseModal={() => {
          setShowCopyWorkingHistoryModalData({
            event: null,
            showModal: false,
          });
          //@ts-ignore
          setEvent(null);
        }}
        onPressYes={() => {
          setShowCopyWorkingHistoryModalData({
            event: null,
            showModal: false,
          });
          //@ts-ignore
          setEvent(null);
        }}
      />
      <CopyWorkingScheduleModal
        workingSchedule={showCopyWorkingScheduleModalData?.event?.workingSchedule ?? null}
        showModal={showCopyWorkingScheduleModalData.showModal}
        onCloseModal={() => {
          setShowCopyWorkingScheduleModalData({
            event: null,
            showModal: false,
          });
          //@ts-ignore
          setEvent(null);
        }}
        onPressYes={() => {
          setShowCopyWorkingScheduleModalData({
            event: null,
            showModal: false,
          });
          //@ts-ignore
          setEvent(null);
        }}
      />
      <CreateWorkingHistoryFromCalendarEventModal
        googleCalendarEvent={calendarEvent?.googleCalendarEvent || null}
        targetMember={calendarEvent?.targetMember}
        showModal={showCreateWorkingHistoryFromGoogleCalendarModalData.showModal}
        onCloseModal={() => {
          setShowCreateWorkingHistoryFromGoogleCalendarModalData({
            event: null,
            showModal: false,
          });
          //@ts-ignore
          setEvent(null);
        }}
        onPressYes={() => {
          setShowCreateWorkingHistoryFromGoogleCalendarModalData({
            event: null,
            showModal: false,
          });
          //@ts-ignore
          setEvent(null);
        }}
      />
      <CreateWorkingScheduleFromCalendarEventModal
        googleCalendarEvent={calendarEvent?.googleCalendarEvent || null}
        targetMember={calendarEvent?.targetMember}
        showModal={showCreateWorkingScheduleFromGoogleCalendarModalData.showModal}
        onCloseModal={() => {
          setShowCreateWorkingScheduleFromGoogleCalendarModalData({
            event: null,
            showModal: false,
          });
          //@ts-ignore
          setEvent(null);
        }}
        onPressYes={() => {
          setShowCreateWorkingScheduleFromGoogleCalendarModalData({
            event: null,
            showModal: false,
          });
          //@ts-ignore
          setEvent(null);
        }}
      />

      {showEditWorkingHistoryModalData.event && data?.me && (
        <EditWorkingHistoryModal
          showModal={showEditWorkingHistoryModalData.showModal}
          workingHistory={showEditWorkingHistoryModalData.event}
          me={data.me}
          onPressYes={() => {
            setShowEditWorkingHistoryModalData({
              event: null,
              showModal: false,
            });
            //@ts-ignore
            setEvent(null);
          }}
          onCloseModal={() => {
            setShowEditWorkingHistoryModalData({
              event: null,
              showModal: false,
            });
            //@ts-ignore
            setEvent(null);
          }}
        />
      )}
      {showMoveWorkingHistoryModalData.event && (
        <MoveWorkingHistoryModal
          showModal={showMoveWorkingHistoryModalData.showModal}
          workingHistory={showMoveWorkingHistoryModalData.event}
          onMove={() => {
            setShowMoveWorkingHistoryModalData({
              event: null,
              showModal: false,
            });
            //@ts-ignore
            setEvent(null);
          }}
          onCloseModal={() => {
            setShowMoveWorkingHistoryModalData({
              event: null,
              showModal: false,
            });
            //@ts-ignore
            setEvent(null);
          }}
        />
      )}
      {showEditWorkingScheduleModalData.event && data?.me &&  (
        <EditWorkingScheduleModal
          workingSchedule={showEditWorkingScheduleModalData.event}
          showModal={showEditWorkingScheduleModalData.showModal}
          me={data.me}
          onPressYes={async () => {
            setShowEditWorkingScheduleModalData({
              event: null,
              showModal: false,
            });
            //@ts-ignore
            setEvent(null);
          }}
          onCloseModal={() => {
            setShowEditWorkingScheduleModalData({
              event: null,
              showModal: false,
            });
            //@ts-ignore
            setEvent(null);
          }}
        />
      )}
       {showMoveWorkingScheduleModalData.event && (
        <MoveWorkingScheduleModal
          workingSchedule={showMoveWorkingScheduleModalData.event}
          showModal={showMoveWorkingScheduleModalData.showModal}
          onMove={() => {
            setShowMoveWorkingScheduleModalData({
              event: null,
              showModal: false,
            });
            //@ts-ignore
            setEvent(null);
          }}
          onCloseModal={() => {
            setShowMoveWorkingScheduleModalData({
              event: null,
              showModal: false,
            });
            //@ts-ignore
            setEvent(null);
          }}
        />
      )}
      <ErrorMessageModal
        showModal={showCompleteTaskStartErrorModal}
        title={'作業開始することができません'}
        message={`完了しているタスク・完了しているプロジェクトは${'\n'}作業開始することが出来ません。`}
        onCloseModal={() => setShowCompleteTaskStartErrorModal(false)}
      />
    </>
  );
});

interface IMemberDayColumnProps {
  member: Member;
  hasProxyEditRole: boolean;
  firstColumn: boolean;
  showWorkingHistory: boolean;
  showWorkingSchedule: boolean;
  showGoogleCalendar: boolean;
  horizontalDragEnable: boolean;
  targetDate: moment.Moment;
  snapMinutes: number;
  isConnectGoogleAccount: boolean;
  workingHistories: ICalendarEvent[];
  workingSchedules: ICalendarEvent[];
  googleCaledarEvents: ICalendarEvent[];
  isOtherMemberColumn: boolean;
  closingSetting: ClosingSetting;
}

const MemberDayColumn = (props: IMemberDayColumnProps) => {
  const [loginUser, setLoginUser] = useContext(LoginUserContext);

  const [deleteWorkingSchedule] = useDeleteWorkingScheduleMutation();
  const [updateWorkingSchedule, ___] = useUpdateWorkingScheduleMutation();
  const [showHasNotProxyEditPermission, setShowHasNotProxyEditPermission] = useState(false);
  const [newWorkingScheduleStartAndEnd, setNewWorkingScheduleStartAndEnd] =
    useState<IStartAndEndForModal>({
      start: moment(),
      end: moment(),
      show: false,
    });

  // const fetchWorkingHistories = useWorkingHistoriesSpecifyTermQuery({ //TODO TDV1-112
  //   variables: {
  //     input: {
  //       start: props.targetDate.startOf('days').toISOString(),
  //       end: props.targetDate.endOf('days').toISOString(),
  //       memberId: props.member?.id,
  //     },
  //   },
  //   skip: !props.isOtherMemberColumn,
  //   fetchPolicy: 'network-only',
  // });

  // const fetchWorkingSchedules = useWorkingSchedulesSpecifyTermQuery({ //TODO TDV1-112
  //   variables: {
  //     input: {
  //       start: props.targetDate.startOf('days').toISOString(),
  //       end: props.targetDate.endOf('days').toISOString(),
  //       memberId: props.member?.id,
  //     },
  //   },
  //   skip: !props.isOtherMemberColumn,
  //   fetchPolicy: 'network-only',
  // });

  // const fetchGoogleCalendarEvents = useGoogleCalendarEventsQuery({
  //   variables: {
  //     input: {
  //       calendarIds: [props.member.mailAddress],
  //       start: props.targetDate.startOf('days').toISOString(),
  //       end: props.targetDate.endOf('days').toISOString(),
  //     },
  //   },
  //   skip: !props.isConnectGoogleAccount || !props.isOtherMemberColumn,
  //   onError: (e) => {
  //     if (e.graphQLErrors.find((ge) => ge.extensions?.code === 'google-token-update-failure')) {
  //       return;
  //     }
  //     if (e.graphQLErrors.find((ge) => ge.extensions?.code === 'google-account-permission-error')) {
  //       return;
  //     }
  //   },
  // });

  // const otherMemberHistoryEvents = (fetchWorkingHistories.data?.workingHistoriesSpecifyTerm ?? [])
  //   .slice()
  //   .filter((event) => event!.workingMember.id === props.member.id)
  //   .filter((event) => {
  //     return (
  //       moment(event!.start).format('YYYY/MM/DD') === props.targetDate.format('YYYY/MM/DD') ||
  //       (event!.end ? moment(event!.end) : moment()).format('YYYY/MM/DD') ===
  //         props.targetDate.format('YYYY/MM/DD')
  //     );
  //   })
  //   .map((event) => {
  //     return {
  //       start: moment(event!.start),
  //       end: event!.end ? moment(event!.end) : null,
  //       workingHistory: event,
  //     } as ICalendarEvent;
  //   });

  // const otherMemberScheduleEvents = (fetchWorkingSchedules.data?.workingSchedulesSpecifyTerm ?? [])
  //   .slice()
  //   .filter((event) => event!.workingMember.id === props.member.id)
  //   .filter((event) => {
  //     return (
  //       moment(event!.start).format('YYYY/MM/DD') === props.targetDate.format('YYYY/MM/DD') ||
  //       (event!.end ? moment(event!.end) : moment()).format('YYYY/MM/DD') ===
  //         props.targetDate.format('YYYY/MM/DD')
  //     );
  //   })
  //   .map((event) => {
  //     return {
  //       start: moment(event!.start),
  //       end: moment(event!.end),
  //       workingSchedule: event,
  //     } as ICalendarEvent;
  //   });

  // const otherMemberGoogleCalendarEvents = (
  //   (fetchGoogleCalendarEvents.data?.googleCalendarEvents ?? []) as GoogleCalendarEvent[]
  // )
  //   .slice()
  //   .filter(
  //     (event) =>
  //       event!.startDateTime &&
  //       event!.endDateTime &&
  //       (moment(event!.startDateTime).format('YYYY/MM/DD') ===
  //         props.targetDate.format('YYYY/MM/DD') ||
  //         moment(event!.endDateTime).format('YYYY/MM/DD') === props.targetDate.format('YYYY/MM/DD'))
  //   )
  //   .map((event) => {
  //     return {
  //       start: moment(event!.startDateTime),
  //       end: event!.endDateTime ? moment(event!.endDateTime) : null,
  //       googleCalendarEvent: event,
  //     } as ICalendarEvent;
  //   });

  return (
    <>
      <DayColumn
        firstColumn={props.firstColumn}
        readOnly={props.member.id !== loginUser!.id && !props.hasProxyEditRole}
        targetMember={props.member}
        showWorkingHistory={props.showWorkingHistory}
        showWorkingSchedule={props.showWorkingSchedule}
        showGoogleCalendar={props.showGoogleCalendar}
        horizontalDragEnable={props.horizontalDragEnable}
        date={props.targetDate}
        closingSetting={props.closingSetting}
        snapMinutes={props.snapMinutes}
        addScheduleEvent={(event) => {
          if (props.member.id !== loginUser!.id && !props.hasProxyEditRole) {
            setShowHasNotProxyEditPermission(true);
            return;
          }
          setNewWorkingScheduleStartAndEnd({
            start: event.start,
            end: event.end,
            show: true,
          });
        }}
        deleteScheduleEvent={(event) => {
          if (props.member.id !== loginUser!.id && !props.hasProxyEditRole) {
            setShowHasNotProxyEditPermission(true);
            return;
          }
          deleteWorkingSchedule({
            variables: {
              id: event.id!,
              input: {
                versionNo: event.versionNo,
              },
            },
            update: (cache, result) => {
              cache.evict({
                id: cache.identify(event),
              });
              cache.evict({ id: `WorkingScheduleForSummary:${event.id}` });
              cache.evict({ id: `CalendarWorkingHistory:${event.id}` });
            },
          });
        }}
        moveScheduleEvent={(event) => {
          if (props.member.id !== loginUser!.id && !props.hasProxyEditRole) {
            setShowHasNotProxyEditPermission(true);
            return;
          }
          updateWorkingSchedule({
            variables: {
              id: event.workingSchedule!.id!,
              input: {
                start: moment(event.start).toISOString(),
                end: event.end.toISOString(),
                memo: event.workingSchedule?.memo,
                versionNo: event.workingSchedule!.versionNo,
              },
            },
            refetchQueries: [
              {
                query: CalendarWorkingScheduleDocument,
                variables: {
                  id: event.workingSchedule!.id,
                },
              },
              {
                query: WorkingScheduleForSummaryDocument,
                variables: {
                  id: event.workingSchedule!.id,
                },
              },
            ]
          });
        }}
        historyEvents={props.workingHistories}
        scheduleEvents={props.workingSchedules}
        isConnectGoogleAccount={props.isConnectGoogleAccount}
        googleCalendarEvents={props.googleCaledarEvents}
      />
      <CreateWorkingScheduleModal
        startDateTime={newWorkingScheduleStartAndEnd.start}
        endDateTime={newWorkingScheduleStartAndEnd.end}
        targetMember={loginUser?.id !== props.member.id ? props.member : undefined}
        showModal={newWorkingScheduleStartAndEnd.show}
        onPressYes={(workingSchedule) =>
          setNewWorkingScheduleStartAndEnd({
            start: moment(),
            end: moment(),
            show: false,
          })
        }
        onCloseModal={() =>
          setNewWorkingScheduleStartAndEnd({
            start: moment(),
            end: moment(),
            show: false,
          })
        }
      />
      <ErrorMessageModal
        showModal={showHasNotProxyEditPermission}
        title={'他のメンバーの作業データを編集できません'}
        message={`編集するには「代理編集権限」が必要です。`}
        onCloseModal={() => setShowHasNotProxyEditPermission(false)}
      />
    </>
  );
};

interface ITimeRange {
  start: moment.Moment;
  end: moment.Moment;
}

type CalendarType = 'WorkingHistory' | 'GoogleCalendar' | 'WorkingSchedule';

interface ICalendarEvent {
  start: moment.Moment;
  end: moment.Moment;
  targetMember: Member;
  workingHistory: CalendarWorkingHistory | null; 
  workingSchedule: CalendarWorkingSchedule | null;
  googleCalendarEvent: GoogleCalendarEvent | null;
}

interface ICalendarEventForDisplay extends ICalendarEvent {
  /** 同じ時間帯に重なるイベント数。自分自身も含むので、重なるイベントが無い場合は1となる */
  overlapEventCount: number;

  /** 重なるイベントの表示順位 */
  overlapIndex: number;

  targetMember: Member;
}

interface IMemberCalendarData {
  member: Member;
  workingHistories: CalendarWorkingHistory[];
  workingSchedules: CalendarWorkingSchedule[];
  googleCaledarEvents: GoogleCalendarEvent[];
}

interface ICalendarMainViewProps {
  displayDays: number;
  hasProxyEditRole: boolean;
  targetMemberIds: string[];
  member: Member;
  organizatiomMembers: Member[];
  myGoogleCalendars: GoogleCalendar[];
  startAndEndDateTime: IStartAndEnd;
  setNewGoogleCalendarEventStartAndEnd: (value: IStartAndEndForModal) => void;
  showHeader: boolean;
  showSecondHeader: boolean;
  isConnectGoogleAccount: boolean;
  showWorkingHistory: boolean;
  showWorkingSchedule: boolean;
  showGoogleCalendar: boolean;
  snapMinutes: number;
  refetchCalendar: () => void;
  isRightSideBar: boolean;
  horizontalDragEnable: boolean;
  workingHistories: WorkingHistoryForCalendar[];
  workingSchedules: WorkingScheduleForCalendar[];
  googleCaledarEvents: GoogleCalendarEvent[];
  closingSetting: ClosingSetting;
}

const CalendarMainView = React.memo((props: ICalendarMainViewProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);

  const headerScrollViewRef = useRef();
  const timeMeasureScrollViewRef = useRef();
  const dayColumnScrollViewRef = useRef();
  const [calendarWidth, setCalendarWidth] = useState<number>(0);
  const [calendarInnerWidth, setCalendarInnerWidth] = useState<number>(0);

  //TODO ここでFetchするべきか？

  const renderTimeRow = useCallback((_, i) => {
    return <TimeRow index={i} key={i} />;
  }, []);

  const renderDayColumnHeader = useCallback(
    (_, i) => {
      return (
        <DayColumnHeader
          date={moment(props.startAndEndDateTime.start).startOf('day').add(i, 'day')}
          targetMemberIds={props.targetMemberIds}
          showDateHeader={props.showHeader !== false}
          isConnectGoogleAccount={props.isConnectGoogleAccount}
          showWorkingHistory={props.showWorkingHistory}
          showWorkingSchedule={props.showWorkingSchedule}
          showGoogleCalendar={props.showGoogleCalendar}
          organizatiomMembers={props.organizatiomMembers}
          key={i}
        />
      );
    },
    [
      props.startAndEndDateTime,
      props.showHeader,
      props.isConnectGoogleAccount,
      props.showWorkingHistory,
      props.showWorkingSchedule,
      props.showGoogleCalendar,
      props.targetMemberIds,
      props.organizatiomMembers,
    ]
  );

  const renderDayColummn = useCallback(
    (_, i) => {
      const targetDate = moment(props.startAndEndDateTime.start).startOf('day').add(i, 'day');
      return props.targetMemberIds.map((memberId, index) => {
          const historyEvents = (props.workingHistories.filter(data => data.memberId === memberId)?.[0]?.workingHistories || [])
          .filter((event) => {
            return (
              moment(event!.start).format('YYYY/MM/DD') === targetDate.format('YYYY/MM/DD') ||
              (event!.end ? moment(event!.end) : moment()).format('YYYY/MM/DD') ===
                targetDate.format('YYYY/MM/DD')
            );
          })
          .map((event) => {
            return {
              start: moment(event!.start),
              end: event!.end ? moment(event!.end) : null,
              targetMember: props.member,
              workingHistory: event,
            };
          });

        const scheduleEvents = (props.workingSchedules.filter(data => data.memberId === memberId)?.[0]?.workingSchedules || [])
          .filter((event) => {
            return (
              moment(event!.start).format('YYYY/MM/DD') === targetDate.format('YYYY/MM/DD') ||
              (event!.end ? moment(event!.end) : moment()).format('YYYY/MM/DD') ===
                targetDate.format('YYYY/MM/DD')
            );
          })
          .map((event) => {
            return {
              start: moment(event!.start),
              end: moment(event!.end),
              targetMember: props.member,
              workingSchedule: event,
            };
          });

          const googleCalendarEvents = (props.googleCaledarEvents.filter(data => {
            if(memberId === loginUser?.id){
              return !data.targetMemberId
            }
            return data.targetMemberId === memberId
          }) || [])
          .filter(
            (event) =>
              event!.startDateTime &&
              event!.endDateTime &&
              (moment(event!.startDateTime).format('YYYY/MM/DD') ===
                targetDate.format('YYYY/MM/DD') ||
                moment(event!.endDateTime).format('YYYY/MM/DD') ===
                  targetDate.format('YYYY/MM/DD'))
          )
          .map((event) => {
            return {
              start: moment(event!.startDateTime),
              end: event!.endDateTime ? moment(event!.endDateTime) : null,
              targetMember: props.member,
              googleCalendarEvent: event,
            };
          })

        return (
          <MemberDayColumn
            firstColumn={index === 0}
            hasProxyEditRole={props.hasProxyEditRole}
            horizontalDragEnable={props.horizontalDragEnable}
            isConnectGoogleAccount={props.isConnectGoogleAccount}
            member={props.organizatiomMembers.filter((mem) => mem.id === memberId)[0]}
            workingHistories={historyEvents as any}
            workingSchedules={scheduleEvents as any}
            googleCaledarEvents={googleCalendarEvents as any}
            showGoogleCalendar={props.showGoogleCalendar}
            showWorkingHistory={props.showWorkingHistory}
            showWorkingSchedule={props.showWorkingSchedule}
            snapMinutes={props.snapMinutes}
            targetDate={targetDate}
            isOtherMemberColumn={props.member.id !== memberId}
            closingSetting={props.closingSetting}
            key={`${i}-${index}`}
          />
        );
      });
    },
    [
      props.startAndEndDateTime,
      props.targetMemberIds,
      props.isConnectGoogleAccount,
      props.showWorkingHistory,
      props.showWorkingSchedule,
      props.showGoogleCalendar,
      props.snapMinutes,
      props.horizontalDragEnable,
      props.workingHistories,
      props.workingSchedules,
      props.googleCaledarEvents,
      props.displayDays,
      props.member,
      props.organizatiomMembers,
      props.closingSetting,
      props.member,
      loginUser,
    ]
  );

  useEffect(() => {
    if ((dayColumnScrollViewRef as any).current?.getNativeScrollRef()) {
      // 現在日時の3時間前あたりにスクロールする
      (dayColumnScrollViewRef as any).current.getNativeScrollRef().scrollTop = Math.max(
        moment().get('hours') * 60 - 3 * 60,
        0
      );
    }
  }, []);

  useEffect(() => {
    setCalendarWidth(
      (headerScrollViewRef.current as any)?.getBoundingClientRect()?.['width'] - 70 ?? null
    );
  }, (headerScrollViewRef.current as any)?.getBoundingClientRect()?.['width']);

  useEffect(() => {
    let columnCount = 0;
    if (props.showWorkingHistory) {
      columnCount = columnCount + 1;
    }
    if (props.showWorkingSchedule) {
      columnCount = columnCount + 1;
    }
    if (props.showGoogleCalendar) {
      columnCount = columnCount + 1;
    }
    setCalendarInnerWidth(props.targetMemberIds.length * columnCount * 80 * props.displayDays);
  }, [
    props.targetMemberIds,
    props.showWorkingHistory,
    props.showWorkingSchedule,
    props.showGoogleCalendar,
    props.displayDays,
  ]);

  return (
    <View style={{ flexDirection: 'row', justifyContent: 'flex-start', marginTop: 10 }}>
      <View
        style={
          {
            flexDirection: 'column',
            flex: 1,
          } as any
        }>
        <CustomScrollView
          scrollViewRef={timeMeasureScrollViewRef as any}
          style={
            {
              top:
                (props.showHeader !== false ? (props.showSecondHeader ? 188 : 144) : 28) +
                (props.targetMemberIds.length > 1 ? 20 : 0),
              width: 50,
              position: 'fixed',
              paddingTop: 20,
              backgroundColor: themeContext.colors.baseColor,
              zIndex: 10,
              overflow: 'scroll',
              height: `calc(100vh - ${props.showSecondHeader ? 270 : 240}px)`,
              marginTop: 50,
            } as any
          }
          // forceHiddenScrollIndicator={true}
          additionalOnScroll={({ nativeEvent }: any) => {
            if ((dayColumnScrollViewRef as any).current?.getNativeScrollRef()) {
              (dayColumnScrollViewRef.current as any).getNativeScrollRef().scrollTop =
                nativeEvent.contentOffset.y;
            }
          }}>
          {[...Array(48)].map(renderTimeRow)}
        </CustomScrollView>
        {props.showHeader !== false && (
          <View
            style={
              {
                zIndex: 11,
                backgroundColor: themeContext.colors.baseColor,
                position: 'fixed',
                top: 60,
                width: 50,
                height: 65,
              } as any
            }></View>
        )}
        <View style={{ width: '100%' }}>
          <CustomScrollView
            scrollViewRef={headerScrollViewRef as any}
            horizontal={true}
            contentContainerStyle={{
              width: props.isRightSideBar ? 'calc(100% - 70px)' : calendarInnerWidth > calendarWidth ? undefined : 'calc(100% - 70px)',
            }}
            additionalOnScroll={({ nativeEvent }) => {
              if ((dayColumnScrollViewRef as any).current?.getNativeScrollRef()) {
                (dayColumnScrollViewRef as any).current.getNativeScrollRef().scrollLeft =
                  nativeEvent.contentOffset.x;
              }
            }}>
            <View
              style={{
                left: 50,
                width: '100%',
                flexDirection: 'row',
              }}>
              {[
                ...Array(
                  props.startAndEndDateTime.end.diff(props.startAndEndDateTime.start, 'days') + 1
                ),
              ].map(renderDayColumnHeader)}
            </View>
          </CustomScrollView>
        </View>
        <View style={{ flexDirection: 'row', marginTop: 3 }}>
          <View style={{ width: 50 }}></View>
          <CalendarEventHoverTooltip isRightSideBar={props.isRightSideBar} />
          <CalendarContextMenu
            isRightSideBar={props.isRightSideBar}
            myGoogleCalendars={props.myGoogleCalendars}
          />
          <CustomScrollView
            style={{
              height: `calc(100vh - ${props.showSecondHeader ? 280 : 250}px)`,
              flexDirection: 'row',
              overflow: 'scroll',
            }}
            scrollViewRef={dayColumnScrollViewRef as any}
            horizontal={true}
            contentContainerStyle={{
              width: 'calc(100% - 20px) ',
            }}
            additionalOnScroll={({ nativeEvent }: any) => {
              if ((headerScrollViewRef as any).current?.getNativeScrollRef()) {
                (headerScrollViewRef as any).current.getNativeScrollRef().scrollLeft =
                  nativeEvent.contentOffset.x;
                (timeMeasureScrollViewRef as any).current.getNativeScrollRef().scrollTop =
                  nativeEvent.contentOffset.y;
              }
            }}>
            <>
              <View style={{ width: '100%', flexDirection: 'row', height: 1450 }}>
                {[
                  ...Array(
                    props.startAndEndDateTime.end.diff(props.startAndEndDateTime.start, 'days') + 1
                  ),
                ].map(renderDayColummn)}
              </View>
            </>
          </CustomScrollView>
        </View>
      </View>
    </View>
  );
});

interface IGoogleCalendarPickerModalProps {
  selectedCalendarIds: string[];
  onChangeSelectedCalendarIds: (value: string[]) => void;
  isConnectGoogleAccount: boolean;
}

const GoogleCalendarPickerModal = (props: IGoogleCalendarPickerModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, _] = useContext(LoginUserContext);
  const [showModal, setShowModal] = useState(false);
  const [selectedCalendarIds, setSelectedCalendarIds] = useState<string[]>([]);
  const { loading, data } = useGoogleCalendarsQuery({
    skip: !props.isConnectGoogleAccount,
  });

  const calendars = useMemo(() => {
    if (loading) {
      return [];
    }
    return (data?.googleCalendars || []).slice().sort((a, b): any => {
      if (a?.primary) {
        return -1;
      }
      if (a?.myCalendar) {
        if (b?.myCalendar) {
          a!.calendarId! > b!.calendarId!;
        } else {
          return -1;
        }
      }
      return a!.calendarId! > b!.calendarId!;
    });
  }, [loading, data?.googleCalendars]);

  useEffect(() => {
    setSelectedCalendarIds(props.selectedCalendarIds);
  }, [props.selectedCalendarIds]);

  return (
    <View style={{ flexDirection: 'column' }}>
      <Typography variant={TypographyType.Description} style={{ fontSize: 8, textAlign: 'center' }}>
        {`カレンダーを選択`}
      </Typography>
      <CalendarIcon size={22} onPress={() => setShowModal(true)} />
      <Modal
        title={'表示するカレンダーを選択'}
        isShow={showModal}
        onClose={() => setShowModal(false)}>
        <CustomScrollView
          style={{
            flexDirection: 'column',
            maxHeight: 300,
            width: '100%',
            padding: 10,
          }}>
          <Form>
            {calendars.map((calendar, i) => {
              const isSelected = selectedCalendarIds.indexOf(calendar!.calendarId) !== -1;
              return (
                <View style={{ flexDirection: 'row', marginVertical: 5 }} key={i}>
                  <Checkbox
                    size={20}
                    value={isSelected}
                    onValueChange={(value) => {
                      if (value) {
                        const newSelectedCalendarIds = Array.from(
                          new Set([...selectedCalendarIds, calendar!.calendarId])
                        );
                        setSelectedCalendarIds(newSelectedCalendarIds);
                        Cookies.set(
                          `SELECTED_CALENDAR_IDS_${loginUser!.organizationId}`,
                          JSON.stringify(newSelectedCalendarIds),
                          {
                            expires: moment().add(10, 'years').toDate(),
                          }
                        );
                      } else {
                        const newSelectedCalendarIds = selectedCalendarIds.filter(
                          (calendarId) => calendarId !== calendar!.calendarId
                        );
                        setSelectedCalendarIds(newSelectedCalendarIds);
                        Cookies.set(
                          `SELECTED_CALENDAR_IDS_${loginUser!.organizationId}`,
                          JSON.stringify(newSelectedCalendarIds),
                          {
                            expires: moment().add(10, 'years').toDate(),
                          }
                        );
                      }
                    }}
                    color={isSelected ? calendar!.backgroundColor : themeContext.colors.description}
                  />
                  <Typography
                    variant={TypographyType.Normal}
                    style={{
                      color: isSelected
                        ? calendar!.backgroundColor
                        : themeContext.colors.description,
                      marginLeft: 10,
                      textDecorationLine: isSelected ? undefined : 'line-through',
                    }}>
                    {calendar!.name}
                  </Typography>
                </View>
              );
            })}
          </Form>
        </CustomScrollView>
        <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
          <Button
            text={'変更する'}
            style={{ minWidth: 100, marginRight: 10, marginVertical: 10 }}
            onPress={async () => {
              props.onChangeSelectedCalendarIds(selectedCalendarIds);
              setShowModal(false);
            }}
          />
          <Button
            text={'キャンセル'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.primary }}
            disableValidate={true}
            onPress={() => setShowModal(false)}
          />
        </View>
      </Modal>
    </View>
  );
};

const parseCalendarFilterFromCookie = (organizationId: string): string[] | null => {
  const cookieValue = Cookies.get(`SHOW_CALENDAR_FILTER_${organizationId}`);
  if (cookieValue === null || cookieValue === undefined) {
    return null;
  }

  const jsonValue = JSON.parse(cookieValue);
  if (!Array.isArray(jsonValue)) {
    return null;
  }

  const filterdValue = jsonValue.filter(
    (value) => value === 'history' || value === 'schedule' || value === 'google'
  );
  if (filterdValue.length === 0) {
    return null;
  }
  return filterdValue;
};

const parseCalendarSnapMinutesFromCookie = (organizationId: string): number | null => {
  const cookieValue = Cookies.get(`CALENDAR_SNAP_MINUTES_${organizationId}`);
  if (cookieValue === null || cookieValue === undefined) {
    return null;
  }

  const numberValue = Number(cookieValue);
  if (numberValue === 1 || numberValue === 5 || numberValue === 15 || numberValue === 30) {
    return numberValue;
  }
  return null;
};

const parseSelectedMembers = (memberId: string, organizatonMembers: Member[]): string[] => {
  const cookieValue = Cookies.get(CALENDAR_DISPLAY_MEMBERS);
  if (cookieValue === null || cookieValue === undefined) {
    return [memberId];
  }

  const jsonValue = JSON.parse(cookieValue);
  if (!Array.isArray(jsonValue)) {
    return [memberId];
  }

  const organizationMemberIds = organizatonMembers.map((member) => member!.id!);
  const filterdValue = jsonValue.filter((id) => organizationMemberIds.includes(id));

  if (filterdValue.length === 0) {
    return [memberId];
  }
  return [memberId].concat(filterdValue.filter((id) => id !== memberId));
};

interface ICalendarInnerProps {
  isOtherMemberCalendar: boolean;
  organizationId: string;
  teamId?: string;
  member: Member;
  organizationMembers: Member[];
  targetMemberIds: string[];
  setTargetMemberIds: (memberIds: string[]) => void;
  initialDisplayDateRange?: number;
  startAndEndDateTime: IStartAndEnd;
  setStartAndEndDateTime: (value: IStartAndEnd) => void;
  showHeader: boolean;
  isRightSideBar: boolean;
  selectedCalendarIds: string[];
  setSelectedCalendarIds: (value: string[]) => void;
  isConnectGoogleAccount: boolean;
  hasGoogleCalendarPermission: boolean;
  snapMinutes: number;
  horizontalDragEnable: boolean;
  myGoogleCalendars: GoogleCalendar[];
  refetchCalendar: () => void;
  style?: StyleProp<ViewProps>;
  goBackTo?: string;
  workingHistories: WorkingHistoryForCalendar[];
  workingSchedules: WorkingScheduleForCalendar[];
  googleCaledarEvents: GoogleCalendarEvent[];
}

const CalendarInner = React.memo((props: ICalendarInnerProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, _] = useContext(LoginUserContext);
  const [displayDays, setDisplayDays] = useState(props.initialDisplayDateRange ?? 7);
  const [showCreateFromGoogleCalendarModal, setShowCreateFromGoogleCalendarModal] = useState(false);
  const showSecondMenuCookieValue = Cookies.get(`SHOW_CALENDAR_SECOND_MENU`);
  const [showSecondMenu, setShowSecondMenu] = useState(
    props.hasGoogleCalendarPermission
      ? showSecondMenuCookieValue === undefined || showSecondMenuCookieValue === 'true'
      : false
  );
  const [newGoogleCalendarEventStartAndEnd, setNewGoogleCalendarEventStartAndEnd] =
    useState<IStartAndEndForModal>({
      start: moment(),
      end: moment(),
      show: false,
    });
  const [showInactiveMember, setShowInactiveMember] = useState(false);

  const { loading: loadingMe, data: dataMe } = useMeQuery();
  const { loading: loadingClosingSetting, data: dataClosingSetting } = useClosingSettingsQuery({
    fetchPolicy: 'network-only',
  });

  const [dummyLoading, setDummyLoading] = useState(false);
  const history = useHistory();
  const [snapMinutes, setSnapMinutes] = useState(props.snapMinutes);
  const [showCalendarFilter, setShowCalendarFilter] = useState<string[]>([]);
  useEffect(() => {
    setSnapMinutes(props.snapMinutes);
  }, [props.snapMinutes]);

  useEffect(() => {
    setShowCalendarFilter(
      parseCalendarFilterFromCookie(loginUser!.organizationId) || ['history', 'schedule', 'google']
    );
    setSnapMinutes(
      parseCalendarSnapMinutesFromCookie(loginUser!.organizationId) || props.snapMinutes || 15
    );
  }, []);

  if (loadingMe || !dataMe?.me || loadingClosingSetting || !dataClosingSetting?.closingSettings) {
    return <></>;
  }

  return (
    <>
      {props.showHeader !== false && (
        <Header>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
              zIndex: 2,
            }}>
            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
              {props.goBackTo && (
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: 15,
                  }}>
                  <ArrowLeftIcon
                    size={30}
                    containerStyle={{ opacity: 0.7 }}
                    onPress={() => {
                      history.push(props.goBackTo!);
                    }}>
                    戻る
                  </ArrowLeftIcon>
                  {/* {props.member.id !== loginUser?.id && (
                    <View
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 20,
                      }}>
                      <Avatar
                        containerStyle={{ marginLeft: 3 }}
                        size={18}
                        name={props.member.name!}
                        imageUrl={props.member.profileImageUrl}
                      />
                      <Typography
                        variant={TypographyType.Normal}
                        style={{ marginLeft: 3, fontSize: 18 }}>
                        {props.member.name}
                      </Typography>
                    </View>
                  )} */}
                </View>
              )}
              <DateSelectHeader
                dateRange={displayDays}
                startDateTime={props.startAndEndDateTime.start}
                setStartDateTime={(value) => {
                  props.setStartAndEndDateTime({
                    start: moment(value).startOf('day'),
                    end: moment(value)
                      .add(displayDays - 1, 'days')
                      .endOf('day'),
                  });
                }}
              />
              {props.initialDisplayDateRange && (
                <Form style={{ width: 'auto', minWidth: 100 }}>
                  <Input
                    name={'date-range'}
                    type={'picker'}
                    initialValue={props.initialDisplayDateRange}
                    containerStyle={{ marginBottom: 0, marginTop: 2 }}
                    pickerItems={[
                      {
                        label: '1日間',
                        value: 1,
                      },
                      {
                        label: '3日間',
                        value: 3,
                      },
                      {
                        label: '5日間',
                        value: 5,
                      },
                      {
                        label: '7日間',
                        value: 7,
                      },
                    ]}
                    onChange={(value) => {
                      if (props.isOtherMemberCalendar) {
                        //他人のカレンダー表示ではそのメンバーのみを初期表示
                        props.setTargetMemberIds([props.member.id!]);
                      } else {
                        // 自分のカレンダー画面でのみ、表示メンバーをCookieから復元
                        props.setTargetMemberIds(
                          parseSelectedMembers(props.member.id!, props.organizationMembers)
                        );
                      }
                      setDisplayDays(Number(value));
                      Cookies.set('CALENDAR_DISPLAY_DATE_RANGE', value, {
                        expires: moment().add(10, 'years').toDate(),
                      });
                      props.setStartAndEndDateTime({
                        start: moment(props.startAndEndDateTime.start).startOf('day'),
                        end: moment(props.startAndEndDateTime.start)
                          .add(Number(value) - 1, 'days')
                          .endOf('day'),
                      });
                    }}
                  />
                </Form>
              )}
            </View>
            <View style={{ flexDirection: 'row' }}>
              {(props.organizationMembers?.filter(
                (member) => showInactiveMember || member.memberStatus === MemberStatus.Active
              ).length ?? 0) > 1 && (
                <FilterElementsContainer>
                  <View style={{ justifyContent: 'center' }}>
                    <Text
                      style={{
                        fontSize: 7,
                        color: themeContext.colors.description,
                        textAlign: 'center',
                        marginBottom: 2,
                      }}>
                      表示するメンバー
                    </Text>
                    <FilterElements>
                      {(props.organizationMembers?.filter(
                        (member) =>
                          showInactiveMember || member.memberStatus === MemberStatus.Active
                      ).length ?? 0) <= 5 ? (
                        <SelectButton
                          contents={(
                            props.organizationMembers.filter(
                              (member) =>
                                showInactiveMember || member.memberStatus === MemberStatus.Active
                            ) ?? []
                          )
                            .slice()
                            .sort((a, b) => {
                              if (a!.id === loginUser!.id) {
                                // ログインしているユーザー自身を先頭に表示する
                                return 1;
                              }
                              return (a!.name as any) - (b!.name as any);
                            })
                            .map((member) => {
                              return {
                                key: member!.id!.toString(),
                                content: (
                                  <Avatar
                                    size={22}
                                    name={member!.name!}
                                    imageUrl={member!.profileImageUrl}
                                    showToolTip={true}
                                    toolTipKey={member!.id!.toString()}
                                  />
                                ),
                              };
                            })}
                          multiSelect={true}
                          values={props.targetMemberIds}
                          onChange={(value) => {
                            props.setTargetMemberIds(value);
                            if (!props.isOtherMemberCalendar) {
                              Cookies.set(CALENDAR_DISPLAY_MEMBERS, JSON.stringify(value), {
                                expires: moment().add(10, 'years').toDate(),
                              });
                            }
                          }}
                        />
                      ) : (
                        <MultiPickerFilter
                          placeHolder="メンバーを絞り込む"
                          containerStyle={{ width: '6rem' }}
                          value={props.targetMemberIds}
                          pickerItems={(
                            props.organizationMembers.filter(
                              (member) =>
                                showInactiveMember || member.memberStatus === MemberStatus.Active
                            ) ?? []
                          )
                            .slice()
                            .sort((a, b) => {
                              if (a!.id === loginUser!.id) {
                                // ログインしているユーザー自身を先頭に表示する
                                return 1;
                              }
                              return (a!.name as any) - (b!.name as any);
                            })
                            .map((member) => {
                              return {
                                label: member!.name!,
                                subLabel: member!.mailAddress!,
                                value: member!.id!,
                                imageUrl: member!.profileImageUrl,
                              };
                            })}
                          onChange={(items) => {
                            const value = items.map((item) => item.value);
                            props.setTargetMemberIds(value);
                            if (!props.isOtherMemberCalendar) {
                              Cookies.set(CALENDAR_DISPLAY_MEMBERS, JSON.stringify(value), {
                                expires: moment().add(10, 'years').toDate(),
                              });
                            }
                          }}
                          footerElement={
                            <View
                              style={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'flex-start',
                                marginLeft: 15,
                              }}>
                              {showInactiveMember ? (
                                <TouchableOpacity onPress={() => setShowInactiveMember(false)}>
                                  <Typography
                                    variant={TypographyType.Normal}
                                    style={{
                                      fontSize: 12,
                                      color: themeContext.colors.link,
                                      textAlign: 'left',
                                      paddingHorizontal: 5,
                                    }}>
                                    {`無効ステータスのメンバーを表示しない`}
                                  </Typography>
                                </TouchableOpacity>
                              ) : (
                                <TouchableOpacity onPress={() => setShowInactiveMember(true)}>
                                  <Typography
                                    variant={TypographyType.Normal}
                                    style={{
                                      fontSize: 12,
                                      color: themeContext.colors.link,
                                      textAlign: 'left',
                                      paddingHorizontal: 5,
                                    }}>
                                    {`無効ステータスのメンバーも表示する`}
                                  </Typography>
                                </TouchableOpacity>
                              )}
                            </View>
                          }
                        />
                      )}
                    </FilterElements>
                  </View>
                </FilterElementsContainer>
              )}
              <FilterElementsContainer>
                <FilterElements>
                  <SelectButton
                    multiSelect={true}
                    values={showCalendarFilter}
                    contents={
                      props.isConnectGoogleAccount && props.hasGoogleCalendarPermission
                        ? [
                            {
                              key: 'history',
                              content: '作業履歴',
                            },
                            {
                              key: 'schedule',
                              content: '作業予定',
                            },
                            {
                              key: 'google',
                              content: 'Googleカレンダー',
                            },
                          ]
                        : [
                            {
                              key: 'history',
                              content: '作業履歴',
                            },
                            {
                              key: 'schedule',
                              content: '作業予定',
                            },
                          ]
                    }
                    onChange={(value) => {
                      setShowCalendarFilter(value);
                      Cookies.set(
                        `SHOW_CALENDAR_FILTER_${loginUser!.organizationId}`,
                        JSON.stringify(value),
                        {
                          expires: moment().add(10, 'years').toDate(),
                        }
                      );
                    }}
                  />
                </FilterElements>
              </FilterElementsContainer>
              {props.isConnectGoogleAccount && props.hasGoogleCalendarPermission && (
                <GoogleCalendarPickerModal
                  selectedCalendarIds={props.selectedCalendarIds}
                  onChangeSelectedCalendarIds={props.setSelectedCalendarIds}
                  isConnectGoogleAccount={props.isConnectGoogleAccount}
                />
              )}
              {!(props.isConnectGoogleAccount && props.hasGoogleCalendarPermission) && (
                <>
                  <View style={{ flexDirection: 'column' }}>
                    <Typography
                      variant={TypographyType.Description}
                      style={{ fontSize: 8, textAlign: 'center' }}>
                      スナップ
                    </Typography>
                    <EditableText
                      style={{ minWidth: 100 }}
                      textStyle={{ textAlign: 'center', fontSize: 12 }}
                      value={snapMinutes}
                      type={'picker'}
                      isSearchable={true}
                      pickerItems={[
                        {
                          label: '1分単位',
                          value: 1,
                        },
                        {
                          label: '5分単位',
                          value: 5,
                        },
                        {
                          label: '15分単位',
                          value: 15,
                        },
                        {
                          label: '30分単位',
                          value: 30,
                        },
                      ]}
                      onChange={(value) => {
                        if (value) {
                          const numberValue = Number(value);
                          setSnapMinutes(numberValue);
                          if (
                            numberValue === 1 ||
                            numberValue === 5 ||
                            numberValue === 15 ||
                            numberValue === 30
                          ) {
                            Cookies.set(
                              `CALENDAR_SNAP_MINUTES_${loginUser!.organizationId}`,
                              numberValue?.toString(),
                              {
                                expires: moment().add(10, 'years').toDate(),
                              }
                            );
                          }
                        }
                      }}
                    />
                  </View>
                  <View style={{ flexDirection: 'column' }}>
                    <Typography
                      variant={TypographyType.Description}
                      style={{ fontSize: 8, textAlign: 'center' }}>
                      再読込
                    </Typography>
                    <ReloadIcon
                      size={24}
                      containerStyle={{ paddingHorizontal: 0, paddingVertical: 0 }}
                      onPress={async () => {
                        setDummyLoading(true);
                        await props.refetchCalendar();
                        setDummyLoading(false);
                      }}
                    />
                  </View>
                </>
              )}
              {!showSecondMenu &&
                props.isConnectGoogleAccount &&
                props.hasGoogleCalendarPermission && (
                  <View style={{ paddingLeft: 5 }}>
                    <Typography
                      variant={TypographyType.Description}
                      style={{ fontSize: 8, textAlign: 'center' }}>
                      開く
                    </Typography>
                    <CaretDownIcon
                      size={20}
                      onPress={() => {
                        setShowSecondMenu(true);
                        Cookies.set(`SHOW_CALENDAR_SECOND_MENU`, 'true', {
                          expires: moment().add(10, 'years').toDate(),
                        });
                      }}
                    />
                  </View>
                )}
            </View>
          </View>
          {showSecondMenu && props.isConnectGoogleAccount && props.hasGoogleCalendarPermission && (
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'flex-end',
                width: '100%',
              }}>
              <Form
                style={{
                  flexDirection: 'column',
                  marginHorizontal: 5,
                  justifyContent: 'center',
                }}>
                <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                  <Button
                    text={`Googleカレンダーから自動登録`}
                    textStyle={{ fontSize: 13 }}
                    style={{ paddingVertical: 7, paddingHorizontal: 10 }}
                    disableValidate={true}
                    onPress={() => setShowCreateFromGoogleCalendarModal(true)}
                  />
                </View>
              </Form>
              <View style={{ flexDirection: 'column' }}>
                <Typography
                  variant={TypographyType.Description}
                  style={{ fontSize: 8, textAlign: 'center' }}>
                  スナップ
                </Typography>
                <EditableText
                  style={{ minWidth: 100 }}
                  textStyle={{ textAlign: 'center', fontSize: 12 }}
                  value={snapMinutes}
                  type={'picker'}
                  isSearchable={true}
                  pickerItems={[
                    {
                      label: '1分単位',
                      value: 1,
                    },
                    {
                      label: '5分単位',
                      value: 5,
                    },
                    {
                      label: '15分単位',
                      value: 15,
                    },
                    {
                      label: '30分単位',
                      value: 30,
                    },
                  ]}
                  onChange={(value) => {
                    if (value) {
                      const numberValue = Number(value);
                      setSnapMinutes(numberValue);
                      if (
                        numberValue === 1 ||
                        numberValue === 5 ||
                        numberValue === 15 ||
                        numberValue === 30
                      ) {
                        Cookies.set(
                          `CALENDAR_SNAP_MINUTES_${loginUser!.organizationId}`,
                          numberValue?.toString(),
                          {
                            expires: moment().add(10, 'years').toDate(),
                          }
                        );
                      }
                    }
                  }}
                />
              </View>
              <View style={{ flexDirection: 'column' }}>
                <Typography
                  variant={TypographyType.Description}
                  style={{ fontSize: 8, textAlign: 'center' }}>
                  再読込
                </Typography>
                <ReloadIcon
                  size={24}
                  containerStyle={{ paddingHorizontal: 0, paddingVertical: 0 }}
                  onPress={async () => {
                    setDummyLoading(true);
                    await props.refetchCalendar();
                    setDummyLoading(false);
                  }}
                />
              </View>
              <View style={{ paddingLeft: 10 }}>
                <Typography
                  variant={TypographyType.Description}
                  style={{ fontSize: 8, textAlign: 'center' }}>
                  閉じる
                </Typography>
                <CaretUpIcon
                  size={20}
                  onPress={() => {
                    setShowSecondMenu(false);
                    Cookies.set(`SHOW_CALENDAR_SECOND_MENU`, 'false', {
                      expires: moment().add(10, 'years').toDate(),
                    });
                  }}
                />
              </View>
            </View>
          )}
        </Header>
      )}

      <View
        style={{
          opacity: dummyLoading ? 0.4 : 1,
        }}>
        <CalendarMainView
          hasProxyEditRole={dataMe.me.proxyEditWorkingDataRole}
          isRightSideBar={props.isRightSideBar}
          targetMemberIds={props.targetMemberIds}
          horizontalDragEnable={props.horizontalDragEnable}
          isConnectGoogleAccount={props.isConnectGoogleAccount}
          showWorkingHistory={showCalendarFilter.indexOf('history') !== -1}
          showWorkingSchedule={showCalendarFilter.indexOf('schedule') !== -1}
          showGoogleCalendar={showCalendarFilter.indexOf('google') !== -1}
          snapMinutes={snapMinutes}
          refetchCalendar={props.refetchCalendar}
          showHeader={props.showHeader}
          showSecondHeader={showSecondMenu}
          startAndEndDateTime={props.startAndEndDateTime}
          setNewGoogleCalendarEventStartAndEnd={setNewGoogleCalendarEventStartAndEnd}
          workingHistories={props.workingHistories}
          workingSchedules={props.workingSchedules}
          googleCaledarEvents={props.googleCaledarEvents}
          displayDays={displayDays}
          member={props.member}
          organizatiomMembers={(props.organizationMembers as Member[]) ?? []}
          myGoogleCalendars={props.myGoogleCalendars}
          closingSetting={dataClosingSetting.closingSettings}
        />
      </View>

      {props.isConnectGoogleAccount && props.hasGoogleCalendarPermission && (
        <CreateFromGoogleCalendarModal
          show={showCreateFromGoogleCalendarModal}
          startAndEndDateTime={props.startAndEndDateTime}
          selectedCalendarIds={props.selectedCalendarIds}
          onClose={() => setShowCreateFromGoogleCalendarModal(false)}
        />
      )}
    </>
  );
});

export interface IStartAndEnd {
  start: moment.Moment;
  end: moment.Moment;
}

interface IStartAndEndNullable {
  start: moment.Moment | null;
  end: moment.Moment | null;
}

interface IStartAndEndForModal {
  start: moment.Moment;
  end: moment.Moment;
  show: boolean;
}

interface ICalendarInnerWrapperProps {
  isOtherMemberCalendar: boolean;
  initialDisplayDateRange?: number;
  startAndEndDateTime: IStartAndEnd;
  style?: StyleProp<ViewProps>;
  showHeader?: boolean;
  isRightSideBar: boolean;
  member: Member;
  teamId?: string;
  isConnectGoogleAccount: boolean;
  hasGoogleCalendarPermission: boolean;
  snapMinutes: number;
  horizontalDragEnable: boolean;
  goBackTo?: string;
}

const CalendarInnerWrapper = (props: ICalendarInnerWrapperProps) => {
  const [loginUser, _] = useContext(LoginUserContext);
  const themeContext: IThemePart = useContext(ThemeContext);
  const [showGooglePersmissionErrorModal, setShowGooglePermissionErrorModal] = useState(false);
  const [showGoogleConnectErrorModal, setShowGoogleConnectErrorModal] = useState(false);
  const [selectedCalendarIds, setSelectedCalendarIds] = useState<string[]>([]);
  const [startAndEndDateTime, setStartAndEndDateTime] = useState<IStartAndEnd>({
    start: moment(props.startAndEndDateTime.start).startOf('date'),
    end: moment(props.startAndEndDateTime.end).endOf('date'),
  });
  const [targetMemberIds, setTargetMemberIds] = useState<string[]>([]);

  useEffect(() => {
    setStartAndEndDateTime(props.startAndEndDateTime);
  }, [props.startAndEndDateTime]);

  const { loading, data } = useOrganizationMembersQuery({
    variables: {
      organizationId: loginUser!.organizationId,
    },
  });

  const fetchWorkingHistories = useWorkingHistoriesSpecifyTermForCalendarQuery({
    variables: {
      input: {
        start: startAndEndDateTime.start.toISOString(),
        end: startAndEndDateTime.end.toISOString(),
        memberIds: targetMemberIds.length === 0 ? [loginUser?.id] : targetMemberIds as any,
      },
    },
    fetchPolicy: 'cache-and-network',
    skip: targetMemberIds.length === 0
  });

  const fetchWorkingSchedules = useWorkingSchedulesSpecifyTermForCalendarQuery({
    variables: {
      input: {
        start: startAndEndDateTime.start.toISOString(),
        end: startAndEndDateTime.end.toISOString(),
        memberIds: targetMemberIds.length === 0 ? [loginUser?.id] : targetMemberIds as any,
      },
    },
    fetchPolicy: 'cache-and-network',
    skip: targetMemberIds.length === 0
  });

  const fetchGoogleCalendarEvents = useGoogleCalendarEventsQuery({
    variables: {
      input: {
        calendarIds: selectedCalendarIds,
        start: startAndEndDateTime.start.toISOString(),
        end: startAndEndDateTime.end.toISOString(),
        memberIds: targetMemberIds.length === 0 ? [loginUser?.id] : targetMemberIds as any,
      },
    },
    skip: !props.isConnectGoogleAccount || selectedCalendarIds.length === 0,
    fetchPolicy: 'cache-first',
    onError: (e) => {
      if (e.graphQLErrors.find((ge) => ge.extensions?.code === 'google-token-update-failure')) {
        setShowGoogleConnectErrorModal(true);
      }
      if (e.graphQLErrors.find((ge) => ge.extensions?.code === 'google-account-permission-error')) {
        setShowGooglePermissionErrorModal(true);
      }
    },
  });

  useEffect(() => {
    if (props.isConnectGoogleAccount && selectedCalendarIds.length > 0) {
      fetchGoogleCalendarEvents.refetch(); //TODO TDV1-112
    }
  }, [selectedCalendarIds, props.isConnectGoogleAccount]);

  const { loading: myGoogleCalendarLoading, data: myGoogleCalendarData } =
    useMyGoogleCalendarsQuery({
      skip: !props.isConnectGoogleAccount,
    });

  const parseSelectedCalendarIdsFromCookie = (
    organizationId: string,
    calendars: GoogleCalendar[]
  ): string[] | null => {
    const cookieValue = Cookies.get(`SELECTED_CALENDAR_IDS_${organizationId}`);
    if (cookieValue === null || cookieValue === undefined) {
      return null;
    }

    const jsonValue = JSON.parse(cookieValue);
    if (!Array.isArray(jsonValue)) {
      return null;
    }

    const filterdValue = jsonValue.filter(
      (value) => calendars.filter((cal) => cal.calendarId === value).length > 0
    );
    if (filterdValue.length === 0) {
      return null;
    }
    return filterdValue;
  };

  useEffect(() => {
    if (props.member.id !== loginUser!.id) {
      setSelectedCalendarIds([props.member!.mailAddress]);
    } else if (myGoogleCalendarData?.myGoogleCalendars) {
      //自分のカレンダーを表示する際には、
      //Cookieに設定が保存されている場合には、その設定されているカレンダーの表示を優先し、
      //そうでない場合には、Googleカレンダー上のマイカレンダーを表示するようにする
      const fromCookieValue = parseSelectedCalendarIdsFromCookie(
        loginUser!.organizationId,
        (myGoogleCalendarData?.myGoogleCalendars as GoogleCalendar[]) || []
      );
      setSelectedCalendarIds(
        fromCookieValue || myGoogleCalendarData!.myGoogleCalendars!.map((cal) => cal!.calendarId)
      );
    }
  }, [myGoogleCalendarData?.myGoogleCalendars, props.member]);

  useEffect(() => {
    if(props.isRightSideBar){
      //右サイドバーののカレンダー表示では自分のカレンダーのみを初期表示
      setTargetMemberIds([loginUser!.id!]);
      return;
    }
    if (props.isOtherMemberCalendar) {
      //他人のカレンダー表示ではそのメンバーのみを初期表示
      setTargetMemberIds([props.member.id!]);
    } else {
      // 自分のカレンダー画面でのみ、表示メンバーをCookieから復元
      setTargetMemberIds(
        parseSelectedMembers(props.member.id!, (data?.organizationMembers as Member[]) ?? [])
      );
    }
  }, [props.member, data?.organizationMembers, loginUser?.id, props.isRightSideBar]);


  if (loading || !data?.organizationMembers) {
    return <></>;
  }

  return (
    <>
      <CalendarInner
        isOtherMemberCalendar={props.isOtherMemberCalendar}
        organizationId={loginUser!.organizationId}
        teamId={props.teamId}
        member={props.member}
        organizationMembers={data.organizationMembers as Member[]}
        targetMemberIds={targetMemberIds}
        setTargetMemberIds={(memberIds) => setTargetMemberIds(memberIds)}
        initialDisplayDateRange={props.initialDisplayDateRange}
        startAndEndDateTime={startAndEndDateTime}
        setStartAndEndDateTime={setStartAndEndDateTime}
        showHeader={props.showHeader !== false}
        isRightSideBar={props.isRightSideBar}
        style={props.style}
        selectedCalendarIds={selectedCalendarIds}
        setSelectedCalendarIds={setSelectedCalendarIds}
        workingHistories={
          (fetchWorkingHistories.data?.workingHistoriesSpecifyTermForCalendar ?? []) as WorkingHistoryForCalendar[]
        }
        workingSchedules={
          (fetchWorkingSchedules.data?.workingSchedulesSpecifyTermForCalendar || []) as WorkingScheduleForCalendar[]
        }
        googleCaledarEvents={
          (fetchGoogleCalendarEvents.data?.googleCalendarEvents || []) as GoogleCalendarEvent[]
        }
        refetchCalendar={async () => {
          await fetchWorkingHistories.refetch();
          await fetchWorkingSchedules.refetch();
          if (props.isConnectGoogleAccount) {
            await fetchGoogleCalendarEvents.refetch();
          }
        }}
        isConnectGoogleAccount={props.isConnectGoogleAccount}
        hasGoogleCalendarPermission={props.hasGoogleCalendarPermission}
        snapMinutes={props.snapMinutes}
        horizontalDragEnable={props.horizontalDragEnable}
        myGoogleCalendars={(myGoogleCalendarData?.myGoogleCalendars as GoogleCalendar[]) ?? []}
        goBackTo={props.goBackTo}
      />
      <GoogleConnectErrorModal
        show={showGoogleConnectErrorModal}
        onClose={() => setShowGoogleConnectErrorModal(false)}
      />
      <GoogleConnectErrorModal
        show={showGooglePersmissionErrorModal}
        onClose={() => setShowGooglePermissionErrorModal(false)}
      />
    </>
  );
};

interface Props {
  initialDisplayDateRange?: number;
  startDateTime: moment.Moment;
  endDateTime: moment.Moment;
  style?: StyleProp<ViewProps>;
  showHeader?: boolean;
  isRightSideBar: boolean;
  memberId: string;
  teamId?: string;
  snapMinutes?: number;
  horizontalDragEnable: boolean;
  goBackTo?: string;
}

const Calendar = React.memo((props: Props) => {
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const history = useHistory();
  const [showGooglePersmissionErrorModal, setShowGooglePermissionErrorModal] = useState(false);
  const [showGoogleConnectErrorModal, setShowGoogleConnectErrorModal] = useState(false);
  const { loading, data } = useMemberQuery({
    variables: {
      memberId: props.memberId!,
    },
    skip: !props.memberId,
  });
  const fetchGoogleAccount = useMyGoogleAccountQuery({
    fetchPolicy: 'network-only',
    onError: (e) => {
      if (e.graphQLErrors.find((ge) => ge.extensions?.code === 'google-token-update-failure')) {
        setShowGoogleConnectErrorModal(true);
      }
      if (e.graphQLErrors.find((ge) => ge.extensions?.code === 'google-account-permission-error')) {
        setShowGooglePermissionErrorModal(true);
      }
    },
  });
  const isConnectGoogleAccount = !!fetchGoogleAccount.data?.myGoogleAccount;
  const hasGoogleCalendarPermission =
    !!fetchGoogleAccount.data?.myGoogleAccount?.calendarPermission;

  if (fetchGoogleAccount.loading || loading || !data?.member) {
    return <></>;
  }

  return (
    <CalendarEventHoverContextProvider>
      <CalendarContextMenuContextProvider>
        <Container
          style={
            [
              props.style,
              {
                width: '100%',
                height: props.showHeader !== false ? 'calc(100vh - 57px)' : 'calc(100vh - 130px)',
              },
            ] as any
          }>
          <Spinner loading={loading || fetchGoogleAccount.loading}>
            <CalendarInnerWrapper
              isOtherMemberCalendar={props.memberId !== loginUser?.id}
              initialDisplayDateRange={props.initialDisplayDateRange}
              startAndEndDateTime={{ start: props.startDateTime, end: props.endDateTime }}
              showHeader={props.showHeader}
              isRightSideBar={props.isRightSideBar}
              teamId={props.teamId}
              member={data.member as Member}
              style={props.style}
              isConnectGoogleAccount={isConnectGoogleAccount}
              hasGoogleCalendarPermission={hasGoogleCalendarPermission}
              snapMinutes={props.snapMinutes || 15}
              horizontalDragEnable={props.horizontalDragEnable}
              goBackTo={props.goBackTo}
            />
          </Spinner>
        </Container>
        <GoogleConnectErrorModal
          show={showGoogleConnectErrorModal}
          onClose={() => setShowGoogleConnectErrorModal(false)}
        />
        <GoogleConnectErrorModal
          show={showGooglePersmissionErrorModal}
          onClose={() => setShowGooglePermissionErrorModal(false)}
        />
      </CalendarContextMenuContextProvider>
    </CalendarEventHoverContextProvider>
  );
});

export default Calendar;
