import React, { useContext, useEffect, useState } from 'react';
import { Text, TextStyle, View } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../../theme';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import DateUtil from '../../../../../util/DateUtil';
import TimeUtil from '../../../../../util/TimeUtil';
import { Organization, Plan, Priority, ProjectTemplateTask } from '../../../../../graphql/api/API';
import when from '../../../../../lang-extention/When';
import { TaskProgressBar } from '../task-template-progress-bar';
import useDimensions from 'use-dimensions';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import PlayIcon from '../../../../presentational/molecules/image-icon/play';
import CheckIcon from '../../../../presentational/molecules/image-icon/check';
import StarIcon from '../../../../presentational/molecules/image-icon/star';

const Container = styled.View`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 6px;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.4s;
  z-index: 100;
  border-width: 1px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
`;

const Main = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  padding-bottom: 3px;
  overflow: hidden;
`;

interface ITaskWorkingTimeProps {
  task: ProjectTemplateTask;
  style?: TextStyle;
}

export const TaskWorkingTime = (props: ITaskWorkingTimeProps) => {
  return (
    <Typography variant={TypographyType.Normal} style={props.style}>
      {TimeUtil.formatForTask(0)}
    </Typography>
  );
};

export const TaskRemainingWorkingTime = (props: ITaskWorkingTimeProps) => {
  return (
    <Typography variant={TypographyType.Normal} style={props.style}>
      {'-'}
    </Typography>
  );
};

export const TaskEstimatedRemainingWorkingTime = (props: ITaskWorkingTimeProps) => {
  return (
    <Typography variant={TypographyType.Normal} style={props.style}>
      {'-'}
    </Typography>
  );
};

export const TaskPredictTotalWorkingTime = (props: ITaskWorkingTimeProps) => {
  return (
    <Typography variant={TypographyType.Normal} style={props.style}>
      {'-'}
    </Typography>
  );
};

interface IProps {
  task: ProjectTemplateTask;
  organization: Organization;
}

const TaskSummary = (props: IProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const { window } = useDimensions();
  const windowWidth = window.width;
  const [loginUser, _] = useContext(LoginUserContext);

  return (
    <Container>
      <Main>
        <View style={{ flexDirection: 'row', flex: 1, minWidth: 67 }}>
          <PlayIcon
            size={23}
            containerStyle={
              {
                marginTop: 3,
                marginRight: 10,
              } as any
            }
          />
          <Typography
            variant={TypographyType.Normal}
            ellipsis={true}
            style={
              [
                {
                  fontSize: 14,
                  minWidth: 30,
                  textAlign: 'left',
                },
              ] as any
            }>
            {props.task.title}
          </Typography>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <View
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: 2,
              paddingHorizontal: 5,
              width: 25,
              marginLeft: 15,
            }}>
            <StarIcon size={16} on={false} containerStyle={{ marginLeft: 3 }} />
          </View>
          {(props.organization.plan.code === Plan.Business ||
            props.organization.plan.code === Plan.Enterprise) && (
            <Typography
              variant={TypographyType.Normal}
              style={{ fontSize: 12, width: 50, textAlign: 'center' }}>
              -
            </Typography>
          )}
          <Typography
            variant={TypographyType.Normal}
            style={{ fontSize: 12, paddingHorizontal: 5, width: 60, textAlign: 'center' }}>
            {when(props.task.priority)
              .on(
                (v) => v === Priority.High,
                () => '高'
              )
              .on(
                (v) => v === Priority.Normal,
                () => '中'
              )
              .on(
                (v) => v === Priority.Low,
                () => '低'
              )
              .otherwise(() => '-')}
          </Typography>
          {windowWidth > 1400 && (
            <>
              <Typography
                variant={TypographyType.Normal}
                style={{ fontSize: 12, paddingHorizontal: 5, width: 90, textAlign: 'center' }}>
                {DateUtil.formatDate(null)}
              </Typography>
              <Typography
                variant={TypographyType.Normal}
                style={{ fontSize: 12, paddingHorizontal: 5, width: 90, textAlign: 'center' }}>
                {DateUtil.formatDate(null)}
              </Typography>
            </>
          )}
          <Typography
            variant={TypographyType.Normal}
            style={{ fontSize: 12, paddingHorizontal: 5, width: 50, textAlign: 'center' }}>
            {`- %`}
          </Typography>
          <View
            style={{
              flexDirection: 'row',
              paddingHorizontal: 5,
              width: 100,
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <TaskWorkingTime
              task={props.task}
              style={{ fontSize: 12, minWidth: 50, marginRight: 5, textAlign: 'center' }}
            />
          </View>
          <Typography
            variant={TypographyType.Normal}
            style={{ fontSize: 12, paddingHorizontal: 5, width: 80, textAlign: 'center' }}>
            {TimeUtil.formatForTask(props.task.estimateTimeSec)}
          </Typography>

          <View style={{ width: 40, justifyContent: 'center', alignItems: 'center' }}>
            <CheckIcon size={16} on={false} />
          </View>
        </View>
      </Main>
      <TaskProgressBar />
    </Container>
  );
};

export default React.memo(TaskSummary);
