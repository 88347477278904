import {
  ApolloClient,
  type FieldPolicy,
  InMemoryCache,
  type ServerError,
  createHttpLink,
  from,
  split,
} from '@apollo/client';
import type { Reference } from '@apollo/client/utilities';
import { setContext } from '@apollo/link-context';
import { onError } from '@apollo/link-error';
import Constants from 'expo-constants';
import { Platform } from 'react-native';
import * as Sentry from 'sentry-expo';
import type { LoginUser } from '../../modules/auth/LoginUserContext';
import LoginUtil from '../../modules/auth/LoginUtil';
import HashIdUtil from '../../util/HashIdUtil';

/**
 * デフォルトの offsetLimitPagination を使用すると、Apollo Clientのキャッシュに、同じデータが複数登録されてしまう。
 * 重複したデータが取得されてしまうので、それを回避するために offsetLimitPagination をベースに以下のようにカスタマイズしている。
 * incomingを直接マージするのではなく、existingに存在しないものだけにフィルタリングしてマージするようにしている。
 */
type KeyArgs = FieldPolicy<any>['keyArgs'];
function customOffsetLimitPagination<T = Reference>(keyArgs: KeyArgs = false): FieldPolicy<T[]> {
  return {
    keyArgs,
    merge(existing, incoming, { args }) {
      const merged = existing ? existing.slice(0) : [];
      const adjustedIncoming = incoming.filter(
        (i: any) => !merged.find((e: any) => e.__ref === i.__ref)
      );
      if (args) {
        const { offset = 0 } = args;
        for (let i = 0; i < adjustedIncoming.length; ++i) {
          merged[offset + i] = adjustedIncoming[i];
        }
      } else {
        merged.push.apply(merged, adjustedIncoming);
      }
      return merged;
    },
  };
}

const createHttpLinkFunc = (loginUser: LoginUser | undefined | null) => {
  if(!loginUser || !loginUser?.id){
    return createHttpLink({ uri: Constants.manifest!.extra!.graphqlEndPoint });
  }

  let organizationId = 0;
  try {
    organizationId = HashIdUtil.decord(loginUser.organizationId)
  } catch(e){
    return createHttpLink({ uri: Constants.manifest!.extra!.graphqlEndPoint });
  }

  // if(organizationId === 1){
  //   return createHttpLink({ uri: Constants.manifest!.extra!.graphqlEndPoint3 });
  // }

  if(organizationId === 749){ //749:つなぐグループ
    return createHttpLink({ uri: Constants.manifest!.extra!.graphqlEndPoint2 });
  }

  return createHttpLink({ uri: Constants.manifest!.extra!.graphqlEndPoint });
}


export default class ApolloClientFactory {
  private constructor() {}

  public static create(
    logoutProcess: () => void,
    showErrorDialog: (message: string, reloadOnCloseError?: boolean) => void,
    showForceUpdateDialog: () => void,
    showIpAddressDenyScreen: () => void,
    onTwoFactorAuthRequiredError?: () => void, // ネイティブアプリの場合必須
    noAuth?: boolean,
    loginUser?: LoginUser | null
  ): ApolloClient<any> {
    const errorLink = onError(({ graphQLErrors, networkError, forward, response, operation }) => {
      // タブがバックグラウンドの場合には無視する
      if(window?.document?.visibilityState === 'hidden'){
        return;
      }

      try {
        if (networkError) {
          if (Platform.OS !== 'web') {
            console.log(networkError);
          }

          if ((networkError as any).statusCode === 400) {
            if ((networkError as any).result) {
              if ((networkError as any).result.errorCode === 'FORCE_UPDATE') {
                showForceUpdateDialog();
                return;
              }
            }
          }

          if ((networkError as any).statusCode === 403) {
            showIpAddressDenyScreen();
            return;
          }

          // 認証エラーの場合には、ログアウト処理を実施する
          if (networkError && (networkError as ServerError).statusCode === 401) {
            LoginUtil.isLoggedIn().then((loggedIn) => {
              if (loggedIn) {
                showErrorDialog(`認証情報が無効になりました。${'\n'}再度ログインをお願いします。`);
                if (Platform.OS === 'web') {
                  setTimeout(() => {
                    logoutProcess();
                  }, 1000);
                } else {
                  logoutProcess();
                }
              }
            });
            try {
              if (Sentry.Native) {
                Sentry.Native.captureException(Error(JSON.stringify(networkError)));
              } else {
                (Sentry as any).Browser.captureException(Error(JSON.stringify(networkError)));
              }
            } catch(_e){
              //NOP
            }
            return;
          }

          // 各画面側でハンドリングされていないエラーについては、アラートをあげる。
          // ネットワークエラーの場合にはGQLサーバーが落ちている可能性があるため、
          // リロードやリダイレクトをするともう一度GQLにリクエストを飛ばしてまたエラーになり、
          // さらにリロードされ、、、と無限にエラーが発生してしまうので、
          // アラートを上げるだけに留める。
          if (Platform.OS === 'web') {
            showErrorDialog(
              `エラーが発生しました。${'\n'}申し訳ございませんが、画面を再読み込みします。`
            );
          } else {
            showErrorDialog(`申し訳ございません。エラーが発生しました`);
          }

          try {
            if (Sentry.Native) {
              Sentry.Native.captureException(Error(JSON.stringify(networkError)));
            } else {
              (Sentry as any).Browser.captureException(Error(JSON.stringify(networkError)));
            }
          } catch(_e){
            //NOP
          }

          LoginUtil.getLoginUser().then((loginUser) => {
            if (Platform.OS === 'web') {
              if (loginUser) {
                window.location.href = `/#/app/${loginUser!.organizationId}/my/favorite-project/`;
              } else {
                window.location.href = `/#/app/`;
              }
            }
          });
          return;
        }

        if (graphQLErrors) {
          if (Sentry.Native) {
            Sentry.Native.captureException(Error(JSON.stringify(graphQLErrors)));
          } else {
            (Sentry as any).Browser.captureException(Error(JSON.stringify(graphQLErrors)));
          }

          if (graphQLErrors.find((error) => error.extensions?.code === 'login-required')) {
            LoginUtil.isLoggedIn().then((loggedIn) => {
              if (loggedIn) {
                showErrorDialog(`認証情報が無効になりました。${'\n'}再度ログインをお願いします。`);
                if (Platform.OS === 'web') {
                  setTimeout(() => {
                    logoutProcess();
                  }, 1000);
                } else {
                  logoutProcess();
                }
              }
            });
            return;
          }
          if (graphQLErrors.find((error) => error.extensions?.code === 'payment-required')) {
            showErrorDialog(
              `現在、お客様のアカウントは休止状態となっているため操作ができません。${'\n'}管理者の方にお問い合わせください。`
            );
            return;
          }
          if (graphQLErrors.find((error) => error.extensions?.code === 'all-licences-used')) {
            showErrorDialog(
              `参加しようとしている組織の利用可能ライセンス数が上限に達しているため、メンバー登録ができませんでした。${'\n'}登録を進めるには、ライセンス数の追加、もしくは不要なメンバーを無効にする操作が必要です。${'\n'}お手数ですが、招待された組織の管理者の方へお問い合わせください。`
            );
            return;
          }
          if (graphQLErrors.find((error) => error.extensions?.code === 'two-factor-auth-required')) {
            LoginUtil.getLoginUser().then((loginUser) => {
              if (!!loginUser) {
                if (Platform.OS === 'web') {
                  window.location.href = `/#/required-two-factor-auth-setting/`;
                } else {
                  logoutProcess();
                  onTwoFactorAuthRequiredError!();
                }
              }
            });
            return;
          }
          if (graphQLErrors.find((error) => error.extensions?.code === 'role-permission')) {
            showErrorDialog(`この操作を行う権限がありません。`);
            return;
          }
          if (
            graphQLErrors.find((error) => error.extensions?.code === 'closed-term-data-operation')
          ) {
            showErrorDialog(`締め処理済みの期間の作業履歴が含まれるため操作できません`, false);
            return;
          }

          // 各画面側でハンドリングされているエラーについては無視するようにする
          if (graphQLErrors.find((error) => error.extensions?.code === 'authentication-failed')) {
            return;
          }
          if (graphQLErrors.find((error) => error.extensions?.code === 'two-factor-auth-required')) {
            return;
          }
          if (
            graphQLErrors.find(
              (error) => error.extensions?.code === 'two-factor-auth-one-time-token-expired'
            )
          ) {
            return;
          }
          if (
            graphQLErrors.find((error) => error.extensions?.code === 'google-account-already-used')
          ) {
            return;
          }
          if (
            graphQLErrors.find((error) => error.extensions?.code === 'apple-account-already-used')
          ) {
            return;
          }
          if (graphQLErrors.find((error) => error.extensions?.code === 'mail-address-already-used')) {
            return;
          }
          if (
            graphQLErrors.find((error) => error.extensions?.code === 'google-token-update-failure')
          ) {
            return;
          }
          if (
            graphQLErrors.find(
              (error) => error.extensions?.code === 'google-account-permission-error'
            )
          ) {
            return;
          }
          if (
            graphQLErrors.find(
              (error) => error.extensions?.code === 'jwt-token-not-found'
            )
          ) {
            return;
          }
          if (
            graphQLErrors.find(
              (error) => error.extensions?.code === 'context-not-active'
            )
          ) {
            return;
          }

          // スマホアプリの場合、プロジェクトを削除した際に、タスク一覧を描画しようとしてEntityAlreadyDeleteが発生してしまう。
          // これをどうしても避けられないため、仕方なく、プロジェクト情報取得時のEntityAlreadyDeleteを無視するようにしている
          if (Platform.OS !== 'web') {
            if (
              graphQLErrors.find(
                (error) =>
                  error.extensions?.code === 'entity-already-deleted' &&
                  error.path?.find((p) => p === 'project')
              )
            ) {
              return;
            }
          }

          // 通知に関しては、DB保存される前にFetchが走る可能性があるので、１件取得のエラーを無視する
          if (operation.operationName === 'notification') {
            return;
          }

          // 各画面側でハンドリングされていないエラーについては、アラートをあげてトップにリダイレクトさせる
          if (Platform.OS === 'web') {
            showErrorDialog(
              `エラーが発生しました。${'\n'}申し訳ございませんが、画面を再読み込みします。`
            );

            try {
              if (Sentry.Native) {
                Sentry.Native.captureException(Error(JSON.stringify(graphQLErrors)));
              } else {
                (Sentry as any).Browser.captureException(Error(JSON.stringify(graphQLErrors)));
              }
            } catch(_e){
              //NOP
            }
          } else {
            showErrorDialog(`申し訳ございません。エラーが発生しました`);

            try {
              if (Sentry.Native) {
                Sentry.Native.captureException(Error(JSON.stringify(graphQLErrors)));
              } else {
                (Sentry as any).Browser.captureException(Error(JSON.stringify(graphQLErrors)));
              }
            } catch(_e){
              //NOP
            }
          }
          LoginUtil.getLoginUser().then((loginUser) => {
            if (Platform.OS === 'web') {
              if (loginUser) {
                window.location.href = `/#/app/${loginUser!.organizationId}/my/favorite-project/`;
              } else {
                window.location.href = `/#/app/`;
              }
            }
          });
          return;
        }
      } catch(e){
        try {
          if (Sentry.Native) {
            Sentry.Native.captureException(e);
          } else {
            (Sentry as any).Browser.captureException(e);
          }
        } catch(_e){
          //NOP
        }
      }
    });

    const httpLink = createHttpLinkFunc(loginUser);
    
    const authLink = setContext(async (_, { headers }) => {
      if (noAuth === true) {
        return {
          headers,
        };
      }

      const token = await LoginUtil.getLoginUsersJwtToken();
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : '',
        },
      };
    });

    const forceUpdateCheckLink = setContext((_, { headers }) => {
      if (Platform.OS === 'ios') {
        return {
          headers: {
            ...headers,
            'X-APPLICATION-TYPE': 'ios',
            'X-APPLICATION-VERSION': Constants.manifest!.version,
          },
        };
      }
      if (Platform.OS === 'android') {
        return {
          headers: {
            ...headers,
            'X-APPLICATION-TYPE': 'android',
            'X-APPLICATION-VERSION': Constants.manifest!.version,
          },
        };
      }
      return {
        headers: {
          ...headers,
          'X-APPLICATION-TYPE': 'web',
          'X-APPLICATION-VERSION': Constants.manifest!.extra!.version,
        },
      };
    });

    const customUserAgentLink = setContext((_, { headers }) => {
      if (Platform.OS === 'ios') {
        return {
          headers: {
            ...headers,
            'User-Agent': `Time Designer IOS ${Constants.manifest!.version}`,
          },
        };
      }
      if (Platform.OS === 'android') {
        return {
          headers: {
            ...headers,
            'User-Agent': `Time Designer Android ${Constants.manifest!.version}`,
          },
        };
      }
      return {
        headers: {
          ...headers,
        },
      };
    });

    return new ApolloClient({
      link: from([
        errorLink as any,
        customUserAgentLink.concat(forceUpdateCheckLink.concat(authLink.concat(httpLink as any))),
      ]),
      cache: new InMemoryCache(),
      connectToDevTools: Constants.manifest!.extra!.environment !== 'production',
    });
  }
}
