import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { View, Image, TouchableOpacity, Text } from 'react-native';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../../theme';
import TaskSummary from '../task-summary';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import { useHistory, useLocation, useParams } from 'react-router';
import {
  Member,
  Organization,
  Plan,
  Project,
  ProjectCompleteFilter,
  ProjectFavoriteFilter,
  ProjectInitialViewType,
  ProjectSortKey,
  SortOrder,
  useFavoriteProjectsQuery,
  useMeQuery,
  useMySettingsQuery,
  useOrganizationQuery,
  useTeamProjectsQuery,
  useTeamQuery,
  useUpdateProjectSortNoInFavoriteProjectMutation,
  useUpdateProjectSortNoInListMutation,
} from '../../../../../graphql/api/API';
import VirtualizedFlatList, {
  GlobalDragContextProvider,
} from '../../../../presentational/atoms/list2/virtualized-flat-list';
import { ProjectFilter, ProjectFilterContext } from '../../templates/app/team-projects';
import moment from 'moment-timezone';
import useDimensions from 'use-dimensions';
import noData02 from '../../../../../base64Images/no-data/no-data-2';
import PlusIcon from '../../../../presentational/molecules/image-icon/plus';
import queryString from 'query-string';
import CaretUpIcon from '../../../../presentational/molecules/image-icon/caret-up';
import CaretDownIcon from '../../../../presentational/molecules/image-icon/caret-down';
import CaretSetIcon from '../../../../presentational/molecules/image-icon/caret-set';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import when from '../../../../../lang-extention/When';
import Modal from '../../../../presentational/molecules/modal';
import Button from '../../../../presentational/atoms/button';
import ErrorMessageModal from '../error-message-modal';
import ProjectCreateDialog from '../project-create-dialog';
import ProjectSummary from '../project-summary';
import LoginUtil from '../../../../../modules/auth/LoginUtil';
import ColorUtil from '../../../../../util/ColorUtil';
import Form from '../../../../presentational/atoms/form';
import Input from '../../../../presentational/atoms/input';
import SearchIcon from '../../../../presentational/molecules/image-icon/search';
import OrganizationUtil from '../../../../../util/OrganizationUtil';

const Container = styled.View``;

interface IProjectItemProps extends IStyleTheme {
  isComplete: boolean;
}

const ProjectItem = styled.View<IProjectItemProps>`
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  min-width: 200px;
  border-bottom-width: 1px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
  background-color: ${(props: IProjectItemProps) =>
    props.isComplete
      ? ColorUtil.lignten(props.theme.colors.separator, 3)
      : props.theme.colors.baseColor};
`;

const ProjectAddButton = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  padding-right: 5px;
  padding-left: 5px;
`;

const TopMenu = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

interface ITaskListWrapperProps extends IStyleTheme {
  isFavoritePage: boolean;
  isAssignedPage: boolean;
}

const TaskListWrapper = styled.View<ITaskListWrapperProps>`
  height: ${(props: ITaskListWrapperProps) =>
    props.isFavoritePage || props.isAssignedPage
      ? 'calc(100vh - 57px - 90px - 45px - 70px)'
      : 'calc(100vh - 57px - 90px - 45px)'};
`;
// 本当はoverflow-xはvisibleにしたいのだが、CSSの仕様で、overflow-yを指定した場合には、xの挙動もそちらで上書きされるため実現できない

const TaskAddButton = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  padding-right: 5px;
  padding-left: 5px;
`;

const TaskRegisterConatiner = styled.View`
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  align-items: flex-start;
  z-index: 2; //DateTimePickerを使用する際には、外側の要素のz-indexを指定しないと正しく表示されないケースがあるので、ここで指定している
`;

const Row = styled.View`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: space-between;
`;

interface IHeaderProps {
  organization: Organization;
  teamId?: string;
  sortKey: ProjectSortKey;
  sortOrder: SortOrder | null;
  isFavoritePage: boolean;
  isAssignedPage: boolean;
  showTeamName: boolean;
}

const Header = (props: IHeaderProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const { window } = useDimensions();
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const history = useHistory();
  const windowWidth = window.width;

  return (
    <View
      style={{
        marginTop: 10,
        marginRight: 10,
        paddingLeft: 10,
        paddingRight: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}>
      <View
        style={{
          flexDirection: 'row',
          flex: 1,
          minWidth: 67,
          alignItems: 'center',
        }}>
        {props.showTeamName &&
          (props.organization.plan.code === Plan.Business ||
            props.organization.plan.code === Plan.Enterprise) &&
          windowWidth >= 1550 && (
            <TouchableOpacity
              style={{ flexDirection: 'row', alignItems: 'flex-start', width: 150 }}
              onPress={() => {
                history.push(
                  queryString.stringifyUrl({
                    url: props.isFavoritePage
                      ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                      : props.isAssignedPage
                      ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                      : `/app/${props.organization.id!}/${props.teamId}/projects/view/list/`,
                    query: {
                      sortKey: when(props.sortOrder) //
                        .on(
                          (v) => v === null || v === undefined,
                          () => ProjectSortKey.TeamName
                        )
                        .on(
                          (v) => v === SortOrder.Asc,
                          () => ProjectSortKey.TeamName
                        )
                        .otherwise(() => null),
                      sortOrder: when(props.sortOrder) //
                        .on(
                          (v) => v === null || v === undefined,
                          () => SortOrder.Asc
                        )
                        .on(
                          (v) => v === SortOrder.Asc,
                          () => SortOrder.Desc
                        )
                        .otherwise(() => null),
                    },
                  })
                );
              }}>
              {props.sortKey === ProjectSortKey.TeamName ? (
                when(props.sortOrder) //
                  .on(
                    (v) => v === null || v === undefined,
                    () => (
                      <CaretSetIcon
                        size={12}
                        containerStyle={{
                          opacity: 0.2,
                          marginLeft: 3,
                        }}
                        onPress={() => {
                          history.push(
                            queryString.stringifyUrl({
                              url: props.isFavoritePage
                                ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                                : props.isAssignedPage
                                ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                                : `/app/${props.organization.id!}/${
                                    props.teamId
                                  }/projects/view/list/`,
                              query: {
                                sortKey: ProjectSortKey.TeamName,
                                sortOrder: SortOrder.Asc,
                              },
                            })
                          );
                        }}
                      />
                    )
                  )
                  .on(
                    (v) => v === SortOrder.Asc,
                    () => (
                      <CaretUpIcon
                        size={10}
                        containerStyle={{ marginLeft: 3 }}
                        onPress={() => {
                          history.push(
                            queryString.stringifyUrl({
                              url: props.isFavoritePage
                                ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                                : props.isAssignedPage
                                ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                                : `/app/${props.organization.id!}/${
                                    props.teamId
                                  }/projects/view/list/`,
                              query: {
                                sortKey: ProjectSortKey.TeamName,
                                sortOrder: SortOrder.Desc,
                              },
                            })
                          );
                        }}
                      />
                    )
                  )
                  .otherwise(() => (
                    <CaretDownIcon
                      size={10}
                      containerStyle={{ marginLeft: 3 }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: props.isFavoritePage
                              ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                              : props.isAssignedPage
                              ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                              : `/app/${props.organization.id!}/${
                                  props.teamId
                                }/projects/view/list/`,
                            query: {
                              sortKey: null,
                              sortOrder: null,
                            },
                          })
                        );
                      }}
                    />
                  ))
              ) : (
                <CaretSetIcon
                  size={12}
                  containerStyle={{
                    opacity: 0.2,
                    marginLeft: 3,
                  }}
                  onPress={() => {
                    history.push(
                      queryString.stringifyUrl({
                        url: props.isFavoritePage
                          ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                          : props.isAssignedPage
                          ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                          : `/app/${props.organization.id!}/${props.teamId}/projects/view/list/`,
                        query: {
                          sortKey: ProjectSortKey.TeamName,
                          sortOrder: SortOrder.Asc,
                        },
                      })
                    );
                  }}
                />
              )}
              <Typography
                variant={TypographyType.Normal}
                style={{
                  fontSize: 12,
                  marginLeft: 0,
                  color: themeContext.colors.description,
                  textAlign: 'left',
                }}>
                チーム名
              </Typography>
            </TouchableOpacity>
          )}
        <TouchableOpacity
          style={{ flexDirection: 'row', flex: 1 }}
          onPress={() => {
            history.push(
              queryString.stringifyUrl({
                url: props.isFavoritePage
                  ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                  : props.isAssignedPage
                  ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                  : `/app/${props.organization.id!}/${props.teamId}/projects/view/list/`,
                query: {
                  sortKey: when(props.sortOrder) //
                    .on(
                      (v) => v === null || v === undefined,
                      () => ProjectSortKey.ProjectName
                    )
                    .on(
                      (v) => v === SortOrder.Asc,
                      () => ProjectSortKey.ProjectName
                    )
                    .otherwise(() => null),
                  sortOrder: when(props.sortOrder) //
                    .on(
                      (v) => v === null || v === undefined,
                      () => SortOrder.Asc
                    )
                    .on(
                      (v) => v === SortOrder.Asc,
                      () => SortOrder.Desc
                    )
                    .otherwise(() => null),
                },
              })
            );
          }}>
          {props.sortKey === ProjectSortKey.ProjectName ? (
            when(props.sortOrder) //
              .on(
                (v) => v === null || v === undefined,
                () => (
                  <CaretSetIcon
                    size={12}
                    containerStyle={{
                      opacity: 0.2,
                      marginLeft: 3,
                    }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: props.isFavoritePage
                            ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                            : props.isAssignedPage
                            ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                            : `/app/${props.organization.id!}/${props.teamId}/projects/view/list/`,
                          query: {
                            sortKey: ProjectSortKey.ProjectName,
                            sortOrder: SortOrder.Asc,
                          },
                        })
                      );
                    }}
                  />
                )
              )
              .on(
                (v) => v === SortOrder.Asc,
                () => (
                  <CaretUpIcon
                    size={10}
                    containerStyle={{ marginLeft: 3 }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: props.isFavoritePage
                            ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                            : props.isAssignedPage
                            ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                            : `/app/${props.organization.id!}/${props.teamId}/projects/view/list/`,
                          query: {
                            sortKey: ProjectSortKey.ProjectName,
                            sortOrder: SortOrder.Desc,
                          },
                        })
                      );
                    }}
                  />
                )
              )
              .otherwise(() => (
                <CaretDownIcon
                  size={10}
                  containerStyle={{ marginLeft: 3 }}
                  onPress={() => {
                    history.push(
                      queryString.stringifyUrl({
                        url: props.isFavoritePage
                          ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                          : props.isAssignedPage
                          ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                          : `/app/${props.organization.id!}/${props.teamId}/projects/view/list/`,
                        query: {
                          sortKey: null,
                          sortOrder: null,
                        },
                      })
                    );
                  }}
                />
              ))
          ) : (
            <CaretSetIcon
              size={12}
              containerStyle={{
                opacity: 0.2,
                marginLeft: 3,
              }}
              onPress={() => {
                history.push(
                  queryString.stringifyUrl({
                    url: props.isFavoritePage
                      ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                      : props.isAssignedPage
                      ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                      : `/app/${props.organization.id!}/${props.teamId}/projects/view/list/`,
                    query: {
                      sortKey: ProjectSortKey.ProjectName,
                      sortOrder: SortOrder.Asc,
                    },
                  })
                );
              }}
            />
          )}
          <Typography
            variant={TypographyType.Normal}
            style={{
              fontSize: 12,
              marginLeft: 0,
              color: themeContext.colors.description,
              textAlign: 'left',
            }}>
            プロジェクト名
          </Typography>
        </TouchableOpacity>
      </View>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <TouchableOpacity
          style={{ flexDirection: 'row', width: 105 }}
          onPress={() => {
            history.push(
              queryString.stringifyUrl({
                url: props.isFavoritePage
                  ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                  : props.isAssignedPage
                  ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                  : `/app/${props.organization.id!}/${props.teamId}/projects/view/list/`,
                query: {
                  sortKey: when(props.sortOrder) //
                    .on(
                      (v) => v === null || v === undefined,
                      () => ProjectSortKey.ProjectKey
                    )
                    .on(
                      (v) => v === SortOrder.Asc,
                      () => ProjectSortKey.ProjectKey
                    )
                    .otherwise(() => null),
                  sortOrder: when(props.sortOrder) //
                    .on(
                      (v) => v === null || v === undefined,
                      () => SortOrder.Asc
                    )
                    .on(
                      (v) => v === SortOrder.Asc,
                      () => SortOrder.Desc
                    )
                    .otherwise(() => null),
                },
              })
            );
          }}>
          {props.sortKey === ProjectSortKey.ProjectKey ? (
            when(props.sortOrder) //
              .on(
                (v) => v === null || v === undefined,
                () => (
                  <CaretSetIcon
                    size={12}
                    containerStyle={{
                      opacity: 0.2,
                      marginLeft: 3,
                    }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: props.isFavoritePage
                            ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                            : props.isAssignedPage
                            ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                            : `/app/${props.organization.id!}/${props.teamId}/projects/view/list/`,
                          query: {
                            sortKey: ProjectSortKey.ProjectKey,
                            sortOrder: SortOrder.Asc,
                          },
                        })
                      );
                    }}
                  />
                )
              )
              .on(
                (v) => v === SortOrder.Asc,
                () => (
                  <CaretUpIcon
                    size={10}
                    containerStyle={{ marginLeft: 3 }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: props.isFavoritePage
                            ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                            : props.isAssignedPage
                            ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                            : `/app/${props.organization.id!}/${props.teamId}/projects/view/list/`,
                          query: {
                            sortKey: ProjectSortKey.ProjectKey,
                            sortOrder: SortOrder.Desc,
                          },
                        })
                      );
                    }}
                  />
                )
              )
              .otherwise(() => (
                <CaretDownIcon
                  size={10}
                  containerStyle={{ marginLeft: 3 }}
                  onPress={() => {
                    history.push(
                      queryString.stringifyUrl({
                        url: props.isFavoritePage
                          ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                          : props.isAssignedPage
                          ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                          : `/app/${props.organization.id!}/${props.teamId}/projects/view/list/`,
                        query: {
                          sortKey: null,
                          sortOrder: null,
                        },
                      })
                    );
                  }}
                />
              ))
          ) : (
            <CaretSetIcon
              size={12}
              containerStyle={{
                opacity: 0.2,
                marginLeft: 3,
              }}
              onPress={() => {
                history.push(
                  queryString.stringifyUrl({
                    url: props.isFavoritePage
                      ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                      : props.isAssignedPage
                      ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                      : `/app/${props.organization.id!}/${props.teamId}/projects/view/list/`,
                    query: {
                      sortKey: ProjectSortKey.ProjectKey,
                      sortOrder: SortOrder.Asc,
                    },
                  })
                );
              }}
            />
          )}
          <Typography
            variant={TypographyType.Normal}
            style={{
              fontSize: 12,
              marginLeft: 0,
              color: themeContext.colors.description,
              textAlign: 'left',
            }}>
            管理番号
          </Typography>
        </TouchableOpacity>
        <TouchableOpacity
          style={{ flexDirection: 'row', width: 160 }}
          onPress={() => {
            history.push(
              queryString.stringifyUrl({
                url: props.isFavoritePage
                  ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                  : props.isAssignedPage
                  ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                  : `/app/${props.organization.id!}/${props.teamId}/projects/view/list/`,
                query: {
                  sortKey: when(props.sortOrder) //
                    .on(
                      (v) => v === null || v === undefined,
                      () => ProjectSortKey.ClientName
                    )
                    .on(
                      (v) => v === SortOrder.Asc,
                      () => ProjectSortKey.ClientName
                    )
                    .otherwise(() => null),
                  sortOrder: when(props.sortOrder) //
                    .on(
                      (v) => v === null || v === undefined,
                      () => SortOrder.Asc
                    )
                    .on(
                      (v) => v === SortOrder.Asc,
                      () => SortOrder.Desc
                    )
                    .otherwise(() => null),
                },
              })
            );
          }}>
          {props.sortKey === ProjectSortKey.ClientName ? (
            when(props.sortOrder) //
              .on(
                (v) => v === null || v === undefined,
                () => (
                  <CaretSetIcon
                    size={12}
                    containerStyle={{
                      opacity: 0.2,
                      marginLeft: 3,
                    }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: props.isFavoritePage
                            ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                            : props.isAssignedPage
                            ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                            : `/app/${props.organization.id!}/${props.teamId}/projects/view/list/`,
                          query: {
                            sortKey: ProjectSortKey.ClientName,
                            sortOrder: SortOrder.Asc,
                          },
                        })
                      );
                    }}
                  />
                )
              )
              .on(
                (v) => v === SortOrder.Asc,
                () => (
                  <CaretUpIcon
                    size={10}
                    containerStyle={{ marginLeft: 3 }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: props.isFavoritePage
                            ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                            : props.isAssignedPage
                            ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                            : `/app/${props.organization.id!}/${props.teamId}/projects/view/list/`,
                          query: {
                            sortKey: ProjectSortKey.ClientName,
                            sortOrder: SortOrder.Desc,
                          },
                        })
                      );
                    }}
                  />
                )
              )
              .otherwise(() => (
                <CaretDownIcon
                  size={10}
                  containerStyle={{ marginLeft: 3 }}
                  onPress={() => {
                    history.push(
                      queryString.stringifyUrl({
                        url: props.isFavoritePage
                          ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                          : props.isAssignedPage
                          ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                          : `/app/${props.organization.id!}/${props.teamId}/projects/view/list/`,
                        query: {
                          sortKey: null,
                          sortOrder: null,
                        },
                      })
                    );
                  }}
                />
              ))
          ) : (
            <CaretSetIcon
              size={12}
              containerStyle={{
                opacity: 0.2,
                marginLeft: 3,
              }}
              onPress={() => {
                history.push(
                  queryString.stringifyUrl({
                    url: props.isFavoritePage
                      ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                      : props.isAssignedPage
                      ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                      : `/app/${props.organization.id!}/${props.teamId}/projects/view/list/`,
                    query: {
                      sortKey: ProjectSortKey.ClientName,
                      sortOrder: SortOrder.Asc,
                    },
                  })
                );
              }}
            />
          )}
          <Typography
            variant={TypographyType.Normal}
            style={{
              fontSize: 12,
              marginLeft: 0,
              color: themeContext.colors.description,
              textAlign: 'left',
            }}>
            取引先
          </Typography>
        </TouchableOpacity>
        {(props.organization.plan.code === Plan.Business ||
          props.organization.plan.code === Plan.Enterprise) && (
          <Typography
            variant={TypographyType.Normal}
            style={{
              fontSize: 12,
              color: themeContext.colors.description,
              textAlign: 'center',
              width: 50,
            }}>
            担当者
          </Typography>
        )}
        {windowWidth > 1500 && (
          <TouchableOpacity
            style={{ flexDirection: 'row', width: 90 }}
            onPress={() => {
              history.push(
                queryString.stringifyUrl({
                  url: props.isFavoritePage
                    ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                    : props.isAssignedPage
                    ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                    : `/app/${props.organization.id!}/${props.teamId}/projects/view/list/`,
                  query: {
                    sortKey: when(props.sortOrder) //
                      .on(
                        (v) => v === null || v === undefined,
                        () => ProjectSortKey.ScheduledStartDate
                      )
                      .on(
                        (v) => v === SortOrder.Asc,
                        () => ProjectSortKey.ScheduledStartDate
                      )
                      .otherwise(() => null),
                    sortOrder: when(props.sortOrder) //
                      .on(
                        (v) => v === null || v === undefined,
                        () => SortOrder.Asc
                      )
                      .on(
                        (v) => v === SortOrder.Asc,
                        () => SortOrder.Desc
                      )
                      .otherwise(() => null),
                  },
                })
              );
            }}>
            {props.sortKey === ProjectSortKey.ScheduledStartDate ? (
              when(props.sortOrder) //
                .on(
                  (v) => v === null || v === undefined,
                  () => (
                    <CaretSetIcon
                      size={12}
                      containerStyle={{
                        opacity: 0.2,
                        marginLeft: 3,
                      }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: props.isFavoritePage
                              ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                              : props.isAssignedPage
                              ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                              : `/app/${props.organization.id!}/${
                                  props.teamId
                                }/projects/view/list/`,
                            query: {
                              sortKey: ProjectSortKey.ScheduledStartDate,
                              sortOrder: SortOrder.Asc,
                            },
                          })
                        );
                      }}
                    />
                  )
                )
                .on(
                  (v) => v === SortOrder.Asc,
                  () => (
                    <CaretUpIcon
                      size={10}
                      containerStyle={{ marginLeft: 3 }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: props.isFavoritePage
                              ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                              : props.isAssignedPage
                              ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                              : `/app/${props.organization.id!}/${
                                  props.teamId
                                }/projects/view/list/`,
                            query: {
                              sortKey: ProjectSortKey.ScheduledStartDate,
                              sortOrder: SortOrder.Desc,
                            },
                          })
                        );
                      }}
                    />
                  )
                )
                .otherwise(() => (
                  <CaretDownIcon
                    size={10}
                    containerStyle={{ marginLeft: 3 }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: props.isFavoritePage
                            ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                            : props.isAssignedPage
                            ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                            : `/app/${props.organization.id!}/${props.teamId}/projects/view/list/`,
                          query: {
                            sortKey: null,
                            sortOrder: null,
                          },
                        })
                      );
                    }}
                  />
                ))
            ) : (
              <CaretSetIcon
                size={12}
                containerStyle={{
                  opacity: 0.2,
                  marginLeft: 3,
                }}
                onPress={() => {
                  history.push(
                    queryString.stringifyUrl({
                      url: props.isFavoritePage
                        ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                        : props.isAssignedPage
                        ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                        : `/app/${props.organization.id!}/${props.teamId}/projects/view/list/`,
                      query: {
                        sortKey: ProjectSortKey.ScheduledStartDate,
                        sortOrder: SortOrder.Asc,
                      },
                    })
                  );
                }}
              />
            )}
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontSize: 12,
                color: themeContext.colors.description,
                textAlign: 'center',
                paddingRight: 10,
                width: 80,
              }}>
              開始予定日
            </Typography>
          </TouchableOpacity>
        )}
        {windowWidth > 1400 && (
          <TouchableOpacity
            style={{ flexDirection: 'row', width: 85, paddingLeft: 5 }}
            onPress={() => {
              history.push(
                queryString.stringifyUrl({
                  url: props.isFavoritePage
                    ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                    : props.isAssignedPage
                    ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                    : `/app/${props.organization.id!}/${props.teamId}/projects/view/list/`,
                  query: {
                    sortKey: when(props.sortOrder) //
                      .on(
                        (v) => v === null || v === undefined,
                        () => ProjectSortKey.ScheduledEndDate
                      )
                      .on(
                        (v) => v === SortOrder.Asc,
                        () => ProjectSortKey.ScheduledEndDate
                      )
                      .otherwise(() => null),
                    sortOrder: when(props.sortOrder) //
                      .on(
                        (v) => v === null || v === undefined,
                        () => SortOrder.Asc
                      )
                      .on(
                        (v) => v === SortOrder.Asc,
                        () => SortOrder.Desc
                      )
                      .otherwise(() => null),
                  },
                })
              );
            }}>
            {props.sortKey === ProjectSortKey.ScheduledEndDate ? (
              when(props.sortOrder) //
                .on(
                  (v) => v === null || v === undefined,
                  () => (
                    <CaretSetIcon
                      size={12}
                      containerStyle={{
                        opacity: 0.2,
                        marginLeft: 3,
                        width: 20,
                        justifyContent: 'flex-end',
                      }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: props.isFavoritePage
                              ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                              : props.isAssignedPage
                              ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                              : `/app/${props.organization.id!}/${
                                  props.teamId
                                }/projects/view/list/`,
                            query: {
                              sortKey: ProjectSortKey.ScheduledEndDate,
                              sortOrder: SortOrder.Asc,
                            },
                          })
                        );
                      }}
                    />
                  )
                )
                .on(
                  (v) => v === SortOrder.Asc,
                  () => (
                    <CaretUpIcon
                      size={10}
                      containerStyle={{ marginLeft: 3, width: 20, justifyContent: 'flex-end' }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: props.isFavoritePage
                              ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                              : props.isAssignedPage
                              ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                              : `/app/${props.organization.id!}/${
                                  props.teamId
                                }/projects/view/list/`,
                            query: {
                              sortKey: ProjectSortKey.ScheduledEndDate,
                              sortOrder: SortOrder.Desc,
                            },
                          })
                        );
                      }}
                    />
                  )
                )
                .otherwise(() => (
                  <CaretDownIcon
                    size={10}
                    containerStyle={{ marginLeft: 3, width: 20, justifyContent: 'flex-end' }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: props.isFavoritePage
                            ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                            : props.isAssignedPage
                            ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                            : `/app/${props.organization.id!}/${props.teamId}/projects/view/list/`,
                          query: {
                            sortKey: null,
                            sortOrder: null,
                          },
                        })
                      );
                    }}
                  />
                ))
            ) : (
              <CaretSetIcon
                size={12}
                containerStyle={{
                  opacity: 0.2,
                  marginLeft: 3,
                  width: 20,
                  justifyContent: 'flex-end',
                }}
                onPress={() => {
                  history.push(
                    queryString.stringifyUrl({
                      url: props.isFavoritePage
                        ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                        : props.isAssignedPage
                        ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                        : `/app/${props.organization.id!}/${props.teamId}/projects/view/list/`,
                      query: {
                        sortKey: ProjectSortKey.ScheduledEndDate,
                        sortOrder: SortOrder.Asc,
                      },
                    })
                  );
                }}
              />
            )}
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontSize: 12,
                color: themeContext.colors.description,
                textAlign: 'left',
                paddingRight: 5,
                paddingLeft: 5,
                width: 60,
              }}>
              〆切日
            </Typography>
          </TouchableOpacity>
        )}
        <TouchableOpacity
          style={{ flexDirection: 'row', width: 95, marginRight: 10 }}
          onPress={() => {
            history.push(
              queryString.stringifyUrl({
                url: props.isFavoritePage
                  ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                  : props.isAssignedPage
                  ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                  : `/app/${props.organization.id!}/${props.teamId}/projects/view/list/`,
                query: {
                  sortKey: when(props.sortOrder) //
                    .on(
                      (v) => v === null || v === undefined,
                      () => ProjectSortKey.WorkingTimeSec
                    )
                    .on(
                      (v) => v === SortOrder.Asc,
                      () => ProjectSortKey.WorkingTimeSec
                    )
                    .otherwise(() => null),
                  sortOrder: when(props.sortOrder) //
                    .on(
                      (v) => v === null || v === undefined,
                      () => SortOrder.Asc
                    )
                    .on(
                      (v) => v === SortOrder.Asc,
                      () => SortOrder.Desc
                    )
                    .otherwise(() => null),
                },
              })
            );
          }}>
          {props.sortKey === ProjectSortKey.WorkingTimeSec ? (
            when(props.sortOrder) //
              .on(
                (v) => v === null || v === undefined,
                () => (
                  <CaretSetIcon
                    size={12}
                    containerStyle={{
                      opacity: 0.2,
                      marginLeft: 3,
                      width: 20,
                      justifyContent: 'flex-end',
                    }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: props.isFavoritePage
                            ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                            : props.isAssignedPage
                            ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                            : `/app/${props.organization.id!}/${props.teamId}/projects/view/list/`,
                          query: {
                            sortKey: ProjectSortKey.WorkingTimeSec,
                            sortOrder: SortOrder.Asc,
                          },
                        })
                      );
                    }}
                  />
                )
              )
              .on(
                (v) => v === SortOrder.Asc,
                () => (
                  <CaretUpIcon
                    size={10}
                    containerStyle={{ marginLeft: 3, width: 20, justifyContent: 'flex-end' }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: props.isFavoritePage
                            ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                            : props.isAssignedPage
                            ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                            : `/app/${props.organization.id!}/${props.teamId}/projects/view/list/`,
                          query: {
                            sortKey: ProjectSortKey.WorkingTimeSec,
                            sortOrder: SortOrder.Desc,
                          },
                        })
                      );
                    }}
                  />
                )
              )
              .otherwise(() => (
                <CaretDownIcon
                  size={10}
                  containerStyle={{ marginLeft: 3, width: 20, justifyContent: 'flex-end' }}
                  onPress={() => {
                    history.push(
                      queryString.stringifyUrl({
                        url: props.isFavoritePage
                          ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                          : props.isAssignedPage
                          ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                          : `/app/${props.organization.id!}/${props.teamId}/projects/view/list/`,
                        query: {
                          sortKey: null,
                          sortOrder: null,
                        },
                      })
                    );
                  }}
                />
              ))
          ) : (
            <CaretSetIcon
              size={12}
              containerStyle={{
                opacity: 0.2,
                marginLeft: 3,
                width: 20,
                justifyContent: 'flex-end',
              }}
              onPress={() => {
                history.push(
                  queryString.stringifyUrl({
                    url: props.isFavoritePage
                      ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                      : props.isAssignedPage
                      ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                      : `/app/${props.organization.id!}/${props.teamId}/projects/view/list/`,
                    query: {
                      sortKey: ProjectSortKey.WorkingTimeSec,
                      sortOrder: SortOrder.Asc,
                    },
                  })
                );
              }}
            />
          )}
          <Typography
            variant={TypographyType.Normal}
            style={{
              fontSize: 12,
              color: themeContext.colors.description,
              textAlign: 'left',
              paddingHorizontal: 5,
              width: 80,
            }}>
            作業時間
          </Typography>
        </TouchableOpacity>

        <TouchableOpacity
          style={{ flexDirection: 'row', width: 100, paddingLeft: 10 }}
          onPress={() => {
            history.push(
              queryString.stringifyUrl({
                url: props.isFavoritePage
                  ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                  : props.isAssignedPage
                  ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                  : `/app/${props.organization.id!}/${props.teamId}/projects/view/list/`,
                query: {
                  sortKey: when(props.sortOrder) //
                    .on(
                      (v) => v === null || v === undefined,
                      () => ProjectSortKey.EstimateWorkingTimeSec
                    )
                    .on(
                      (v) => v === SortOrder.Asc,
                      () => ProjectSortKey.EstimateWorkingTimeSec
                    )
                    .otherwise(() => null),
                  sortOrder: when(props.sortOrder) //
                    .on(
                      (v) => v === null || v === undefined,
                      () => SortOrder.Asc
                    )
                    .on(
                      (v) => v === SortOrder.Asc,
                      () => SortOrder.Desc
                    )
                    .otherwise(() => null),
                },
              })
            );
          }}>
          {props.sortKey === ProjectSortKey.EstimateWorkingTimeSec ? (
            when(props.sortOrder) //
              .on(
                (v) => v === null || v === undefined,
                () => (
                  <CaretSetIcon
                    size={12}
                    containerStyle={{
                      opacity: 0.2,
                      marginLeft: 3,
                      width: 10,
                      justifyContent: 'flex-end',
                    }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: props.isFavoritePage
                            ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                            : props.isAssignedPage
                            ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                            : `/app/${props.organization.id!}/${props.teamId}/projects/view/list/`,
                          query: {
                            sortKey: ProjectSortKey.EstimateWorkingTimeSec,
                            sortOrder: SortOrder.Asc,
                          },
                        })
                      );
                    }}
                  />
                )
              )
              .on(
                (v) => v === SortOrder.Asc,
                () => (
                  <CaretUpIcon
                    size={10}
                    containerStyle={{ marginLeft: 3, width: 10, justifyContent: 'flex-end' }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: props.isFavoritePage
                            ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                            : props.isAssignedPage
                            ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                            : `/app/${props.organization.id!}/${props.teamId}/projects/view/list/`,
                          query: {
                            sortKey: ProjectSortKey.EstimateWorkingTimeSec,
                            sortOrder: SortOrder.Desc,
                          },
                        })
                      );
                    }}
                  />
                )
              )
              .otherwise(() => (
                <CaretDownIcon
                  size={10}
                  containerStyle={{ marginLeft: 3, width: 10, justifyContent: 'flex-end' }}
                  onPress={() => {
                    history.push(
                      queryString.stringifyUrl({
                        url: props.isFavoritePage
                          ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                          : props.isAssignedPage
                          ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                          : `/app/${props.organization.id!}/${props.teamId}/projects/view/list/`,
                        query: {
                          sortKey: null,
                          sortOrder: null,
                        },
                      })
                    );
                  }}
                />
              ))
          ) : (
            <CaretSetIcon
              size={12}
              containerStyle={{
                opacity: 0.2,
                marginLeft: 3,
                width: 10,
                justifyContent: 'flex-end',
              }}
              onPress={() => {
                history.push(
                  queryString.stringifyUrl({
                    url: props.isFavoritePage
                      ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                      : props.isAssignedPage
                      ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                      : `/app/${props.organization.id!}/${props.teamId}/projects/view/list/`,
                    query: {
                      sortKey: ProjectSortKey.EstimateWorkingTimeSec,
                      sortOrder: SortOrder.Asc,
                    },
                  })
                );
              }}
            />
          )}
          <Typography
            variant={TypographyType.Normal}
            style={{
              fontSize: 12,
              color: themeContext.colors.description,
              textAlign: 'left',
              paddingHorizontal: 5,
              width: 70,
            }}>
            見積時間
          </Typography>
        </TouchableOpacity>
        {/* {OrganizationUtil.isTeamPlan(props.organization) && windowWidth > 1400 && (
          <TouchableOpacity
            style={{ flexDirection: 'row', width: 100, paddingLeft: 10 }}
            onPress={() => {
              history.push(
                queryString.stringifyUrl({
                  url: props.isFavoritePage
                    ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                    : props.isAssignedPage
                    ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                    : `/app/${props.organization.id!}/${props.teamId}/projects/view/list/`,
                  query: {
                    sortKey: when(props.sortOrder) //
                      .on(
                        (v) => v === null || v === undefined,
                        () => ProjectSortKey.LaborCost
                      )
                      .on(
                        (v) => v === SortOrder.Asc,
                        () => ProjectSortKey.LaborCost
                      )
                      .otherwise(() => null),
                    sortOrder: when(props.sortOrder) //
                      .on(
                        (v) => v === null || v === undefined,
                        () => SortOrder.Asc
                      )
                      .on(
                        (v) => v === SortOrder.Asc,
                        () => SortOrder.Desc
                      )
                      .otherwise(() => null),
                  },
                })
              );
            }}>
            {props.sortKey === ProjectSortKey.LaborCost ? (
              when(props.sortOrder) //
                .on(
                  (v) => v === null || v === undefined,
                  () => (
                    <CaretSetIcon
                      size={12}
                      containerStyle={{
                        opacity: 0.2,
                        marginLeft: 3,
                        width: 10,
                        justifyContent: 'flex-end',
                      }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: props.isFavoritePage
                              ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                              : props.isAssignedPage
                              ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                              : `/app/${props.organization.id!}/${
                                  props.teamId
                                }/projects/view/list/`,
                            query: {
                              sortKey: ProjectSortKey.LaborCost,
                              sortOrder: SortOrder.Asc,
                            },
                          })
                        );
                      }}
                    />
                  )
                )
                .on(
                  (v) => v === SortOrder.Asc,
                  () => (
                    <CaretUpIcon
                      size={10}
                      containerStyle={{ marginLeft: 3, width: 10, justifyContent: 'flex-end' }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: props.isFavoritePage
                              ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                              : props.isAssignedPage
                              ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                              : `/app/${props.organization.id!}/${
                                  props.teamId
                                }/projects/view/list/`,
                            query: {
                              sortKey: ProjectSortKey.LaborCost,
                              sortOrder: SortOrder.Desc,
                            },
                          })
                        );
                      }}
                    />
                  )
                )
                .otherwise(() => (
                  <CaretDownIcon
                    size={10}
                    containerStyle={{ marginLeft: 3, width: 10, justifyContent: 'flex-end' }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: props.isFavoritePage
                            ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                            : props.isAssignedPage
                            ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                            : `/app/${props.organization.id!}/${props.teamId}/projects/view/list/`,
                          query: {
                            sortKey: null,
                            sortOrder: null,
                          },
                        })
                      );
                    }}
                  />
                ))
            ) : (
              <CaretSetIcon
                size={12}
                containerStyle={{
                  opacity: 0.2,
                  marginLeft: 3,
                  width: 10,
                  justifyContent: 'flex-end',
                }}
                onPress={() => {
                  history.push(
                    queryString.stringifyUrl({
                      url: props.isFavoritePage
                        ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                        : props.isAssignedPage
                        ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                        : `/app/${props.organization.id!}/${props.teamId}/projects/view/list/`,
                      query: {
                        sortKey: ProjectSortKey.LaborCost,
                        sortOrder: SortOrder.Asc,
                      },
                    })
                  );
                }}
              />
            )}
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontSize: 12,
                color: themeContext.colors.description,
                textAlign: 'left',
                paddingHorizontal: 5,
                width: 70,
              }}>
              人件費
            </Typography>
          </TouchableOpacity>
        )}
        {windowWidth > 1400 && (
          <TouchableOpacity
            style={{ flexDirection: 'row', width: 100, paddingLeft: 10 }}
            onPress={() => {
              history.push(
                queryString.stringifyUrl({
                  url: props.isFavoritePage
                    ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                    : props.isAssignedPage
                    ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                    : `/app/${props.organization.id!}/${props.teamId}/projects/view/list/`,
                  query: {
                    sortKey: when(props.sortOrder) //
                      .on(
                        (v) => v === null || v === undefined,
                        () => ProjectSortKey.SalesAmount
                      )
                      .on(
                        (v) => v === SortOrder.Asc,
                        () => ProjectSortKey.SalesAmount
                      )
                      .otherwise(() => null),
                    sortOrder: when(props.sortOrder) //
                      .on(
                        (v) => v === null || v === undefined,
                        () => SortOrder.Asc
                      )
                      .on(
                        (v) => v === SortOrder.Asc,
                        () => SortOrder.Desc
                      )
                      .otherwise(() => null),
                  },
                })
              );
            }}>
            {props.sortKey === ProjectSortKey.SalesAmount ? (
              when(props.sortOrder) //
                .on(
                  (v) => v === null || v === undefined,
                  () => (
                    <CaretSetIcon
                      size={12}
                      containerStyle={{
                        opacity: 0.2,
                        marginLeft: 3,
                        width: 10,
                        justifyContent: 'flex-end',
                      }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: props.isFavoritePage
                              ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                              : props.isAssignedPage
                              ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                              : `/app/${props.organization.id!}/${
                                  props.teamId
                                }/projects/view/list/`,
                            query: {
                              sortKey: ProjectSortKey.SalesAmount,
                              sortOrder: SortOrder.Asc,
                            },
                          })
                        );
                      }}
                    />
                  )
                )
                .on(
                  (v) => v === SortOrder.Asc,
                  () => (
                    <CaretUpIcon
                      size={10}
                      containerStyle={{ marginLeft: 3, width: 10, justifyContent: 'flex-end' }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: props.isFavoritePage
                              ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                              : props.isAssignedPage
                              ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                              : `/app/${props.organization.id!}/${
                                  props.teamId
                                }/projects/view/list/`,
                            query: {
                              sortKey: ProjectSortKey.SalesAmount,
                              sortOrder: SortOrder.Desc,
                            },
                          })
                        );
                      }}
                    />
                  )
                )
                .otherwise(() => (
                  <CaretDownIcon
                    size={10}
                    containerStyle={{ marginLeft: 3, width: 10, justifyContent: 'flex-end' }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: props.isFavoritePage
                            ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                            : props.isAssignedPage
                            ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                            : `/app/${props.organization.id!}/${props.teamId}/projects/view/list/`,
                          query: {
                            sortKey: null,
                            sortOrder: null,
                          },
                        })
                      );
                    }}
                  />
                ))
            ) : (
              <CaretSetIcon
                size={12}
                containerStyle={{
                  opacity: 0.2,
                  marginLeft: 3,
                  width: 10,
                  justifyContent: 'flex-end',
                }}
                onPress={() => {
                  history.push(
                    queryString.stringifyUrl({
                      url: props.isFavoritePage
                        ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                        : props.isAssignedPage
                        ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                        : `/app/${props.organization.id!}/${props.teamId}/projects/view/list/`,
                      query: {
                        sortKey: ProjectSortKey.SalesAmount,
                        sortOrder: SortOrder.Asc,
                      },
                    })
                  );
                }}
              />
            )}
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontSize: 12,
                color: themeContext.colors.description,
                textAlign: 'left',
                paddingHorizontal: 5,
              }}>
              売上
            </Typography>
          </TouchableOpacity>
        )}
        {OrganizationUtil.isTeamPlan(props.organization) && windowWidth > 1400 && (
          <TouchableOpacity
            style={{ flexDirection: 'row', width: 100, paddingLeft: 10 }}
            onPress={() => {
              history.push(
                queryString.stringifyUrl({
                  url: props.isFavoritePage
                    ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                    : props.isAssignedPage
                    ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                    : `/app/${props.organization.id!}/${props.teamId}/projects/view/list/`,
                  query: {
                    sortKey: when(props.sortOrder) //
                      .on(
                        (v) => v === null || v === undefined,
                        () => ProjectSortKey.CostBudget
                      )
                      .on(
                        (v) => v === SortOrder.Asc,
                        () => ProjectSortKey.CostBudget
                      )
                      .otherwise(() => null),
                    sortOrder: when(props.sortOrder) //
                      .on(
                        (v) => v === null || v === undefined,
                        () => SortOrder.Asc
                      )
                      .on(
                        (v) => v === SortOrder.Asc,
                        () => SortOrder.Desc
                      )
                      .otherwise(() => null),
                  },
                })
              );
            }}>
            {props.sortKey === ProjectSortKey.CostBudget ? (
              when(props.sortOrder) //
                .on(
                  (v) => v === null || v === undefined,
                  () => (
                    <CaretSetIcon
                      size={12}
                      containerStyle={{
                        opacity: 0.2,
                        marginLeft: 3,
                        width: 10,
                        justifyContent: 'flex-end',
                      }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: props.isFavoritePage
                              ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                              : props.isAssignedPage
                              ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                              : `/app/${props.organization.id!}/${
                                  props.teamId
                                }/projects/view/list/`,
                            query: {
                              sortKey: ProjectSortKey.CostBudget,
                              sortOrder: SortOrder.Asc,
                            },
                          })
                        );
                      }}
                    />
                  )
                )
                .on(
                  (v) => v === SortOrder.Asc,
                  () => (
                    <CaretUpIcon
                      size={10}
                      containerStyle={{ marginLeft: 3, width: 10, justifyContent: 'flex-end' }}
                      onPress={() => {
                        history.push(
                          queryString.stringifyUrl({
                            url: props.isFavoritePage
                              ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                              : props.isAssignedPage
                              ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                              : `/app/${props.organization.id!}/${
                                  props.teamId
                                }/projects/view/list/`,
                            query: {
                              sortKey: ProjectSortKey.CostBudget,
                              sortOrder: SortOrder.Desc,
                            },
                          })
                        );
                      }}
                    />
                  )
                )
                .otherwise(() => (
                  <CaretDownIcon
                    size={10}
                    containerStyle={{ marginLeft: 3, width: 10, justifyContent: 'flex-end' }}
                    onPress={() => {
                      history.push(
                        queryString.stringifyUrl({
                          url: props.isFavoritePage
                            ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                            : props.isAssignedPage
                            ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                            : `/app/${props.organization.id!}/${props.teamId}/projects/view/list/`,
                          query: {
                            sortKey: null,
                            sortOrder: null,
                          },
                        })
                      );
                    }}
                  />
                ))
            ) : (
              <CaretSetIcon
                size={12}
                containerStyle={{
                  opacity: 0.2,
                  marginLeft: 3,
                  width: 10,
                  justifyContent: 'flex-end',
                }}
                onPress={() => {
                  history.push(
                    queryString.stringifyUrl({
                      url: props.isFavoritePage
                        ? `/app/${props.organization.id!}/my/favorite-project/view/list/`
                        : props.isAssignedPage
                        ? `/app/${props.organization.id!}/my/assigned-project/view/list/`
                        : `/app/${props.organization.id!}/${props.teamId}/projects/view/list/`,
                      query: {
                        sortKey: ProjectSortKey.CostBudget,
                        sortOrder: SortOrder.Asc,
                      },
                    })
                  );
                }}
              />
            )}
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontSize: 12,
                color: themeContext.colors.description,
                textAlign: 'left',
                paddingHorizontal: 5,
              }}>
              予算
            </Typography>
          </TouchableOpacity>
        )} */}
      </View>
    </View>
  );
};

interface IAlertCanNotDragModalProps {
  showModal: boolean;
  onCloseModal: () => void;
}

const AlertCanNotDragModal = (props: IAlertCanNotDragModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  return (
    <Modal
      title={'並び替えができません'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <View style={{ flexDirection: 'column' }}>
        <View style={{ marginTop: 10 }}>
          <Typography
            variant={TypographyType.Description}
            style={{ textAlign: 'center', fontSize: 16, lineHeight: 22 }}>
            {`ドラッグ操作で並び順を変更したい場合、${'\n'}表示順の指定を解除してください`}
          </Typography>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
          <Button
            text={'閉じる'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
            }}
            onPress={() => props.onCloseModal()}
          />
        </View>
      </View>
    </Modal>
  );
};

interface IProjectListFullInnerProps {
  organization: Organization;
  me: Member;
  showTeamName: boolean;
  searchCondition: any;
  setShowDialog: (value: boolean) => void;
}

const ProjectListFullInner = React.memo((props: IProjectListFullInnerProps) => {
  const pageSize = 50;
  const [projectFilter, __] = useContext(ProjectFilterContext);

  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const { push } = useHistory();
  const { search } = useLocation();
  const [showCompleteProject, setShowCompleteProject] = useState(false);
  const [focusSearchClient, setForcusSearchClient] = useState(false);
  const [focusSearchMember, setForcusSearchMember] = useState(false);
  const [memberIds, setMemberIds] = useState<string[]>([]);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSearchCondition, setShowSearchCondition] = useState(false);
  const [showCanNotDragModal, setShowCanNotDragModal] = useState(false);
  const [sortKey, setSortKey] = useState<ProjectSortKey>(ProjectSortKey.SortNo);
  const [sortOrder, setSortOrder] = useState<SortOrder | null>(null);

  const { loading: mySettingsLoading, data: mySettingsData } = useMySettingsQuery();

  const projectsResult = useFavoriteProjectsQuery({
    variables: {
      offset: 0,
      limit: pageSize,
      input: {
        completeCondition:
          (projectFilter as ProjectFilter).completeCondition ?? ProjectCompleteFilter.Incomplete,
        projectName: (projectFilter as ProjectFilter).projectName ?? null,
        clientIds: (projectFilter as ProjectFilter).clientIds || [],
        assineeMemberIds: (projectFilter as ProjectFilter).assignerIds || [],
        sortKey: sortKey,
        sortOrder: sortOrder,
      },
    },
    fetchPolicy: 'network-only',
  });

  const [updateProjectSortNo, _] = useUpdateProjectSortNoInFavoriteProjectMutation();

  const projects = useMemo(() => {
    if (projectsResult.loading || !projectsResult.data?.favoriteProjects) {
      return [];
    }
    return projectsResult.data!.favoriteProjects!;
  }, [projectsResult.loading, projectsResult.data?.favoriteProjects]);

  useEffect(() => {
    setSortKey(props.searchCondition?.sortKey || ProjectSortKey.SortNo);
  }, [props.searchCondition.sortKey]);

  useEffect(() => {
    setSortOrder(props.searchCondition?.sortOrder ?? null);
  }, [props.searchCondition.sortOrder]);

  if (mySettingsLoading || projectsResult.loading || projectsResult.error) {
    return <></>;
  }

  return (
    <TaskListWrapper
      isFavoritePage={location.hash.indexOf('/my/favorite-project/') !== -1}
      isAssignedPage={location.hash.indexOf('/my/assigned-project/') !== -1}>
      <Header
        organization={props.organization}
        sortKey={sortKey}
        sortOrder={sortOrder}
        isFavoritePage={location.hash.indexOf('/my/favorite-project/') !== -1}
        isAssignedPage={location.hash.indexOf('/my/assigned-project/') !== -1}
        showTeamName={props.showTeamName}
      />
      {projects.length === 0 ? (
        <View
          style={{
            height: 'calc(100% - 150px)',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Image
            source={{ uri: noData02 }}
            resizeMode="contain"
            style={{
              width: 300,
              height: 200,
              opacity: 0.7,
            }}
          />
          <Typography
            variant={TypographyType.Normal}
            style={{ color: themeContext.colors.description }}>
            さあ、プロジェクトを作成してみましょう
          </Typography>
        </View>
      ) : (
        <GlobalDragContextProvider>
          <VirtualizedFlatList
            style={{ height: 'calc(100vh - 310px)' }}
            items={projects}
            renderItem={(item, index) => {
              return (
                <ProjectSummary
                  showTeamName={OrganizationUtil.isTeamPlan(props.organization)}
                  organization={props.organization}
                  project={item as Project}
                  me={props.me}
                  showAssigner
                  showClientName
                  showFavorite
                />
              );
            }}
            getKey={(project) => (project as Project).id!.toString()}
            itemHeight={50}
            onPress={(project) => {
              if (
                mySettingsData!.mySettings!.projectInitialViewTypeCode ===
                ProjectInitialViewType.KanbanBoard
              ) {
                push(
                  `/app/${loginUser!.organizationId}/my/favorite-project/view/board/?` +
                    queryString.stringify(
                      Object.assign(queryString.parse(search), {
                        projectDetailId: (project as Project).id,
                        projectDetailMode: 'standard'
                      })
                    )
                );
              } else if (
                mySettingsData!.mySettings!.projectInitialViewTypeCode ===
                ProjectInitialViewType.GanttChart
              ) {
                push(
                  `/app/${loginUser!.organizationId}/my/favorite-project/view/schedule/?` +
                    queryString.stringify(
                      Object.assign(queryString.parse(search), {
                        projectDetailId: (project as Project).id,
                        projectDetailMode: 'standard'
                      })
                    )
                );
              } else {
                push(
                  `/app/${loginUser!.organizationId}/my/favorite-project/view/list/?` +
                    queryString.stringify(
                      Object.assign(queryString.parse(search), {
                        projectDetailId: (project as Project).id,
                        projectDetailMode: 'standard'
                      })
                    ) 
                );
              }
            }}
            virticalDraggable={true}
            onDrop={async (info) => {
              if (sortKey !== ProjectSortKey.SortNo) {
                setShowCanNotDragModal(true);
                return;
              }
              const isMoveToFirst = info.endRowIndex === 0;
              const isMoveToLast = info.endRowIndex === projects.length - 1;
              const isMoveToDown = info.endRowIndex - info.startRowIndex > 0;

              let sortNo;
              if (isMoveToFirst) {
                sortNo = new Date().getTime();
              } else if (isMoveToLast) {
                sortNo = projects[info.endRowIndex]!.sortNoInList - 1000;
              } else {
                if (isMoveToDown) {
                  const beforeTask = projects[info.endRowIndex];
                  const afterTask = projects[info.endRowIndex + 1];
                  sortNo = Math.floor((beforeTask!.sortNoInList + afterTask!.sortNoInList) / 2);
                } else {
                  const beforeTask = projects[info.endRowIndex - 1];
                  const afterTask = projects[info.endRowIndex];
                  sortNo = Math.floor((beforeTask!.sortNoInList + afterTask!.sortNoInList) / 2);
                }
              }

              await updateProjectSortNo({
                variables: {
                  id: (info.item as Project).id!,
                  input: {
                    sortNo: sortNo,
                    versionNo: (info.item as Project).versionNo,
                  },
                },
                // optimisticResponse: {
                //   __typename: 'Mutation',
                //   updateProjectSortNoInFavoriteProject: Object.assign(
                //     {
                //       __typename: 'Project',
                //     },
                //     info.item as Project,
                //     { sortNoInList: sortNo }
                //   ),
                // },
              });
            }}
            onEndReached={() => {
              if ((projectsResult?.data?.favoriteProjects?.length ?? 0) < pageSize) {
                return;
              }
              projectsResult.fetchMore({
                variables: {
                  offset: projectsResult.data!.favoriteProjects!.length,
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev;
                  return Object.assign({}, prev, {
                    favoriteProjects: [
                      ...(prev.favoriteProjects || []),
                      ...(fetchMoreResult.favoriteProjects || []),
                    ],
                  });
                },
              });
            }}
          />
          <AlertCanNotDragModal
            showModal={showCanNotDragModal}
            onCloseModal={() => setShowCanNotDragModal(false)}
          />
        </GlobalDragContextProvider>
      )}
    </TaskListWrapper>
  );
});

const FavoriteProjectListFull = () => {
  const { push } = useHistory();
  const [showDialog, setShowDialog] = useState(false);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const { search } = useLocation();
  const searchCondition = queryString.parse(search);
  const { loading, data } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId,
    },
  });
  const { loading: mySettingsLoading, data: mySettingsData } = useMySettingsQuery();
  const { loading: meLoading, data: meData } = useMeQuery({
    fetchPolicy: 'network-only',
  });

  if (loading || mySettingsLoading || meLoading || !meData?.me) {
    return <></>;
  }

  return (
    <Container>
      <ProjectListFullInner
        organization={data!.organization!}
        me={meData.me}
        setShowDialog={setShowDialog}
        showTeamName={true}
        searchCondition={searchCondition}
      />
      <Modal
        isShow={showDialog}
        title="プロジェクトを作成する"
        onClose={() => {
          setShowDialog(false);
        }}>
        <ProjectCreateDialog
          onComplete={(newProject) => {
            setShowDialog(false);
            if (
              mySettingsData!.mySettings!.projectInitialViewTypeCode ===
              ProjectInitialViewType.KanbanBoard
            ) {
              push(
                `/app/${
                  loginUser!.organizationId
                }/my/favorite-project/view/board/?projectDetailId=${
                  newProject.id
                }&projectDetailMode=standard`
              );
            } else {
              push(
                `/app/${loginUser!.organizationId}/my/favorite-project/view/list/?projectDetailId=${
                  newProject.id
                }&projectDetailMode=standard`
              );
            }
          }}
          onCancel={() => {
            setShowDialog(false);
          }}
        />
      </Modal>
    </Container>
  );
};

export default React.memo(FavoriteProjectListFull);
