import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Scalar for BigDecimal */
  BigDecimal: any;
  /** Scalar for BigInteger */
  BigInteger: any;
  /** Scalar for Date */
  Date: any;
  /** Scalar for DateTime */
  DateTime: any;
};

export type AccessControlSettings = {
  __typename?: 'AccessControlSettings';
  enable: Scalars['Boolean'];
  id?: Maybe<Scalars['ID']>;
  ipAddresses: Array<Maybe<Scalars['String']>>;
  versionNo: Scalars['Int'];
};

export type AddCsvDefinitionDetailInputInput = {
  reportItemCode: Scalars['String'];
  sortNo: Scalars['BigInteger'];
  versionNo: Scalars['Int'];
};

export type AddTaskCommentInputInput = {
  comment: Scalars['String'];
  versionNo: Scalars['Int'];
};

export type AllMemberWorkingWorkingHistoriesInputInput = {
  /** ISO-8601 */
  end: Scalars['DateTime'];
};

export type AmountAfterLicenceCountChange = {
  __typename?: 'AmountAfterLicenceCountChange';
  dailyRateAmount: Scalars['Int'];
  nextTotalAmount: Scalars['Int'];
};

export type Announcement = {
  __typename?: 'Announcement';
  /** ISO-8601 */
  announceDateTime: Scalars['DateTime'];
  id: Scalars['ID'];
  readed: Scalars['Boolean'];
  title: Scalars['String'];
  url: Scalars['String'];
};

export type ApiKey = {
  __typename?: 'ApiKey';
  token: Scalars['String'];
};

export type AppleAccount = {
  __typename?: 'AppleAccount';
  mailAddress: Scalars['String'];
};

export type AppleProfile = {
  __typename?: 'AppleProfile';
  appleUserId?: Maybe<Scalars['String']>;
  mailAddress: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  registerd?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
};

export type ArchiveTeamInputInput = {
  versionNo: Scalars['Int'];
};

export type AssignedProjectMemberInfo = {
  __typename?: 'AssignedProjectMemberInfo';
  member: Member;
  sortNo: Scalars['BigInteger'];
};

export type AssignedTaskMemberInfo = {
  __typename?: 'AssignedTaskMemberInfo';
  member: Member;
  sortNo: Scalars['BigInteger'];
};

export type AttachmentFile = {
  __typename?: 'AttachmentFile';
  fileName: Scalars['String'];
  id: Scalars['ID'];
  sizeByte: Scalars['BigInteger'];
  type?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  uploadDateTime: Scalars['DateTime'];
};

export type AttachmentFileDownloadInfo = {
  __typename?: 'AttachmentFileDownloadInfo';
  downloadUrl: Scalars['String'];
};

export type AttachmentFileUploadInfo = {
  __typename?: 'AttachmentFileUploadInfo';
  fileName: Scalars['String'];
  key: Scalars['String'];
  sizeByte: Scalars['BigInteger'];
  type?: Maybe<Scalars['String']>;
  uploadUrl: Scalars['String'];
};

export type Attendee = {
  __typename?: 'Attendee';
  mailAddress?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  responseStatus?: Maybe<Scalars['String']>;
  self: Scalars['Boolean'];
};

export enum BillingType {
  AppleIap = 'AppleIap',
  CreditCard = 'CreditCard',
  Invoice = 'Invoice',
  NonRegistered = 'NonRegistered',
}

export type CalendarEvent = {
  __typename?: 'CalendarEvent';
  calendarType: ThirdPartyCalendarType;
  clientId?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  endDateTime: Scalars['DateTime'];
  eventName: Scalars['String'];
  id: Scalars['ID'];
  laborCost?: Maybe<Scalars['BigInteger']>;
  personalTeam?: Maybe<Scalars['Boolean']>;
  projectColor?: Maybe<Scalars['String']>;
  projectId: Scalars['String'];
  projectName: Scalars['String'];
  /** ISO-8601 */
  startDateTime: Scalars['DateTime'];
  taskId?: Maybe<Scalars['String']>;
  taskTitle?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['String']>;
  teamName?: Maybe<Scalars['String']>;
  thirdPartyCalendarEventId: Scalars['String'];
  thirdPartyCalendarInfoList: Array<Maybe<ThirdPartyCalendarInfo>>;
  versionNo: Scalars['Int'];
  workingTimeSec: Scalars['BigInteger'];
};

export type CalendarWorkingHistory = {
  __typename?: 'CalendarWorkingHistory';
  calendarEvent?: Maybe<WorkingHistoryCalendarEventPart>;
  /** ISO-8601 */
  end?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  memo?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  start: Scalars['DateTime'];
  task?: Maybe<WorkingHistoryTaskPart>;
  versionNo: Scalars['Int'];
  workingMember: WorkingHistoryMemberPart;
  workingTimeSec?: Maybe<Scalars['Int']>;
};

export type CalendarWorkingSchedule = {
  __typename?: 'CalendarWorkingSchedule';
  calendarEvent?: Maybe<WorkingScheduleCalendarEventPart>;
  /** ISO-8601 */
  end: Scalars['DateTime'];
  id?: Maybe<Scalars['ID']>;
  memo?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  start: Scalars['DateTime'];
  task?: Maybe<WorkingScheduleTaskPart>;
  versionNo: Scalars['Int'];
  workingMember: WorkingScheduleMemberPart;
  workingTimeSec: Scalars['Int'];
};

export type CancelFavoriteProjectInputInput = {
  versionNo: Scalars['Int'];
};

export type CancelFavoriteTaskInputInput = {
  versionNo: Scalars['Int'];
};

export type ChangeContractPeriodInputInput = {
  contractPreriod: ContractPeriod;
  versionNo: Scalars['Int'];
};

export type ChangePlanInputInput = {
  planId: Scalars['String'];
  versionNo: Scalars['Int'];
};

export type CheckDuplicateTermProjectContractInputInput = {
  excludeProjectContractId?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Int']>;
  projectId: Scalars['String'];
  timeZoneId: Scalars['String'];
  year?: Maybe<Scalars['Int']>;
};

export type Client = {
  __typename?: 'Client';
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  sortNo: Scalars['BigInteger'];
  versionNo: Scalars['Int'];
};

export type ClientBarGraphData = {
  __typename?: 'ClientBarGraphData';
  clientId: Scalars['String'];
  clientName: Scalars['String'];
  color: Scalars['String'];
  percent: Scalars['Float'];
  reportType: ReportType;
  workingTimeSec: Scalars['BigInteger'];
};

export type ClientDateGraphData = {
  __typename?: 'ClientDateGraphData';
  /** ISO-8601 */
  date: Scalars['Date'];
  items: Array<Maybe<ClientDateGraphDataDetail>>;
  reportType: ReportType;
};

export type ClientDateGraphDataDetail = {
  __typename?: 'ClientDateGraphDataDetail';
  clientId: Scalars['String'];
  clientName: Scalars['String'];
  color: Scalars['String'];
  percent: Scalars['Float'];
  workingTimeSec: Scalars['BigInteger'];
};

export type ClientGraphDataInputInput = {
  clientIds: Array<Maybe<Scalars['String']>>;
  /** ISO-8601 */
  end: Scalars['DateTime'];
  memberId?: Maybe<Scalars['String']>;
  memberIds: Array<Maybe<Scalars['String']>>;
  organizationId: Scalars['String'];
  projectIds: Array<Maybe<Scalars['String']>>;
  reportType: ReportType;
  /** ISO-8601 */
  start: Scalars['DateTime'];
  teamId?: Maybe<Scalars['String']>;
  teamIds: Array<Maybe<Scalars['String']>>;
  timeZoneOffset: Scalars['String'];
};

export enum ClientSortKey {
  Name = 'Name',
  SortNo = 'SortNo',
}

export type ClientSunburstGraphData = {
  __typename?: 'ClientSunburstGraphData';
  clientId: Scalars['String'];
  color: Scalars['String'];
  name: Scalars['String'];
  percent: Scalars['Float'];
  reportType: ReportType;
  workingTimeSec: Scalars['BigInteger'];
};

export type CloseInputInput = {
  /** ISO-8601 */
  targetDateTime?: Maybe<Scalars['DateTime']>;
  timeZoneId: Scalars['String'];
  versionNo: Scalars['Int'];
};

export type ClosingProcessPart = {
  __typename?: 'ClosingProcessPart';
  closed: Scalars['Boolean'];
  /** ISO-8601 */
  targetDateTime?: Maybe<Scalars['DateTime']>;
};

export type ClosingSetting = {
  __typename?: 'ClosingSetting';
  id: Scalars['ID'];
  /** ISO-8601 */
  targetDateTime?: Maybe<Scalars['DateTime']>;
  timeZone: TimeZone;
  versionNo: Scalars['Int'];
};

export type CompleteProjectInputInput = {
  versionNo: Scalars['Int'];
};

export type CompleteSubTaskInputInput = {
  versionNo: Scalars['Int'];
};

export type CompleteTaskInputInput = {
  versionNo: Scalars['Int'];
};

export type CompleteTaskResult = {
  __typename?: 'CompleteTaskResult';
  stopWorkingHistory: Array<Maybe<WorkingHistory>>;
  task: Task;
};

export type ConfirmAmountAfterLicenceCountChangeInputInput = {
  licenceCount: Scalars['Int'];
};

export type ConnectAppleAccountInputInput = {
  code: Scalars['String'];
  token: Scalars['String'];
};

export type Contract = {
  __typename?: 'Contract';
  billToMailAddress: Scalars['String'];
  billingType: BillingType;
  contractPreriod: ContractPeriod;
  freeLicenceCoupon?: Maybe<RangeFreeLicenceCoupon>;
  /** ISO-8601 */
  freeTrialExpireDatetime?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  licenceCount: Scalars['Int'];
  plan: PlanProduct;
  revenuecatOriginalAppUserId?: Maybe<Scalars['String']>;
  usingLicenceCount: Scalars['Int'];
  versionNo?: Maybe<Scalars['Int']>;
};

export enum ContractPeriod {
  Monthly = 'Monthly',
  Yearly = 'Yearly',
}

export type CopyProjectInputInput = {
  copyProjectAssignee?: Maybe<Scalars['Boolean']>;
  copyTaskAssignee?: Maybe<Scalars['Boolean']>;
  copyTaskComment?: Maybe<Scalars['Boolean']>;
  versionNo?: Maybe<Scalars['BigInteger']>;
};

export type CopyTaskInputInput = {
  copyAssignee?: Maybe<Scalars['Boolean']>;
  copyComment?: Maybe<Scalars['Boolean']>;
  versionNo?: Maybe<Scalars['BigInteger']>;
};

export type CreateAttachementFileDownloadUrlInputInput = {
  attachmentFileId: Scalars['String'];
};

export type CreateAttachementFileUploadUrlInputInput = {
  fileName: Scalars['String'];
  sizeByte: Scalars['BigInteger'];
  type?: Maybe<Scalars['String']>;
};

export type CreateCalendarEventWithWorkingHistoryResult = {
  __typename?: 'CreateCalendarEventWithWorkingHistoryResult';
  calendarEvent: CalendarEvent;
  workingHistory: WorkingHistory;
};

export type CreateCalendarEventWithWorkingScheduleResult = {
  __typename?: 'CreateCalendarEventWithWorkingScheduleResult';
  calendarEvent: CalendarEvent;
  workingSchedule: WorkingSchedule;
};

export type CreateICalFileDownloadUrlInputInput = {
  iCalExportId: Scalars['String'];
};

export type CreateInvitationLinkInputInput = {
  clientManagementRole: Scalars['Boolean'];
  memo?: Maybe<Scalars['String']>;
  organizationMemberViewRole: Scalars['Boolean'];
  organizationReportRole: Scalars['Boolean'];
  projectCreatePermissionFlg: Scalars['Boolean'];
  projectCustomAttributePermissionFlg: Scalars['Boolean'];
  projectDeletePermissionFlg: Scalars['Boolean'];
  projectUpdatePermissionFlg: Scalars['Boolean'];
  proxyEditWorkingDataRole: Scalars['Boolean'];
  taskCreatePermissionFlg: Scalars['Boolean'];
  taskCustomAttributePermissionFlg: Scalars['Boolean'];
  taskDeletePermissionFlg: Scalars['Boolean'];
  taskUpdatePermissionFlg: Scalars['Boolean'];
  teamIds: Array<Maybe<Scalars['String']>>;
  teamMemberViewRole: Scalars['Boolean'];
  teamReportRole: Scalars['Boolean'];
};

export type CreateProjectAttachmentFileInputInput = {
  fileName: Scalars['String'];
  key: Scalars['String'];
  projectId: Scalars['String'];
  sizeByte: Scalars['BigInteger'];
  type?: Maybe<Scalars['String']>;
};

export type CreateTaskAttachmentFileInputInput = {
  fileName: Scalars['String'];
  key: Scalars['String'];
  sizeByte: Scalars['BigInteger'];
  taskId: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type CreateWorkingHistoryWithTaskResult = {
  __typename?: 'CreateWorkingHistoryWithTaskResult';
  task: Task;
  workingHistory: WorkingHistory;
};

export type CreateWorkingScheduleWithTaskResult = {
  __typename?: 'CreateWorkingScheduleWithTaskResult';
  task: Task;
  workingSchedule: WorkingSchedule;
};

export type CreditCard = {
  __typename?: 'CreditCard';
  cardNumberLast4?: Maybe<Scalars['String']>;
  expireYearMonth?: Maybe<Scalars['String']>;
};

export type CsvReportItemDefinition = {
  __typename?: 'CsvReportItemDefinition';
  id: Scalars['ID'];
  items: Array<Maybe<CsvReportItemDefinitionDetail>>;
  name: Scalars['String'];
  versionNo: Scalars['Int'];
};

export type CsvReportItemDefinitionDetail = {
  __typename?: 'CsvReportItemDefinitionDetail';
  id: Scalars['ID'];
  reportItemCode: ReportItem;
  reportItemName: Scalars['String'];
  sortNo: Scalars['BigInteger'];
};

export enum CustomerAttributeType {
  Date = 'Date',
  DateTime = 'DateTime',
  FreeText = 'FreeText',
  List = 'List',
  Number = 'Number',
}

export type DeleteCalendarEventInputInput = {
  versionNo: Scalars['Int'];
};

export type DeleteClientInputInput = {
  versionNo: Scalars['Int'];
};

export type DeleteCsvDefinitionDetailInputInput = {
  detailId: Scalars['ID'];
  versionNo: Scalars['Int'];
};

export type DeleteCsvReportItemDefinitionInputInput = {
  versionNo: Scalars['Int'];
};

export type DeleteLaborCostInputInput = {
  versionNo: Scalars['Int'];
};

export type DeleteMeterdRateContractInputInput = {
  versionNo: Scalars['Int'];
};

export type DeleteProjectContractInputInput = {
  versionNo: Scalars['Int'];
};

export type DeleteProjectCustomAttributeMasterInputInput = {
  versionNo: Scalars['Int'];
};

export type DeleteProjectCustomAttributeMasterListItemInputInput = {
  versionNo: Scalars['Int'];
};

export type DeleteProjectInputInput = {
  versionNo: Scalars['Int'];
};

export type DeleteProjectStatusInputInput = {
  projectMoveDestStatusId?: Maybe<Scalars['ID']>;
  versionNo: Scalars['Int'];
};

export type DeleteProjectTemplateInputInput = {
  versionNo: Scalars['Int'];
};

export type DeleteProjectTemplateSubTaskInputInput = {
  versionNo: Scalars['Int'];
};

export type DeleteProjectTemplateTaskInputInput = {
  versionNo: Scalars['Int'];
};

export type DeleteProjectTemplateTaskStatusInputInput = {
  taskMoveDestStatusId?: Maybe<Scalars['ID']>;
  versionNo: Scalars['Int'];
};

export type DeleteSettlementRangeContractInputInput = {
  versionNo: Scalars['Int'];
};

export type DeleteSubTaskInputInput = {
  versionNo: Scalars['Int'];
};

export type DeleteTagInputInput = {
  versionNo: Scalars['Int'];
};

export type DeleteTaskCommentInputInput = {
  versionNo: Scalars['Int'];
};

export type DeleteTaskCustomAttributeMasterInputInput = {
  versionNo: Scalars['Int'];
};

export type DeleteTaskCustomAttributeMasterListItemInputInput = {
  versionNo: Scalars['Int'];
};

export type DeleteTaskInputInput = {
  versionNo: Scalars['Int'];
};

export type DeleteTaskStatusInputInput = {
  taskMoveDestStatusId?: Maybe<Scalars['ID']>;
  versionNo: Scalars['Int'];
};

export type DeleteWorkingHistoryInputInput = {
  versionNo: Scalars['Int'];
};

export type DisableTwoFactorAuthRequiredInputInput = {
  versionNo: Scalars['Int'];
};

export type EnableTwoFactorAuthRequiredInputInput = {
  versionNo: Scalars['Int'];
};

export type ExportClientExcel = {
  __typename?: 'ExportClientExcel';
  id: Scalars['ID'];
  /** ISO-8601 */
  requestDateTime: Scalars['DateTime'];
  requestMember: Member;
  status: JobProgressStatus;
  timeZoneOffset: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  versionNo: Scalars['Int'];
};

export type ExportMemberExcel = {
  __typename?: 'ExportMemberExcel';
  id: Scalars['ID'];
  /** ISO-8601 */
  requestDateTime: Scalars['DateTime'];
  requestMember: Member;
  status: JobProgressStatus;
  timeZoneOffset: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  versionNo: Scalars['Int'];
};

export type ExportProjectExcel = {
  __typename?: 'ExportProjectExcel';
  id: Scalars['ID'];
  includeCompleted: Scalars['Boolean'];
  /** ISO-8601 */
  requestDateTime: Scalars['DateTime'];
  requestMember: Member;
  status: JobProgressStatus;
  teamid?: Maybe<Scalars['String']>;
  timeZoneOffset: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  versionNo: Scalars['Int'];
};

export type ExportTaskExcel = {
  __typename?: 'ExportTaskExcel';
  id: Scalars['ID'];
  includeCompleted: Scalars['Boolean'];
  /** ISO-8601 */
  requestDateTime: Scalars['DateTime'];
  requestMember: Member;
  status: JobProgressStatus;
  teamid?: Maybe<Scalars['String']>;
  timeZoneOffset: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  versionNo: Scalars['Int'];
};

export type FavoriteProjectInputInput = {
  versionNo: Scalars['Int'];
};

export type FavoriteProjectMemberInfo = {
  __typename?: 'FavoriteProjectMemberInfo';
  member: Member;
  sortNo: Scalars['BigInteger'];
};

export enum FavoriteTaskCompleteFilter {
  Both = 'Both',
  Complete = 'Complete',
  Incomplete = 'Incomplete',
}

export type FavoriteTaskInputInput = {
  versionNo: Scalars['Int'];
};

export type FavoriteTaskMemberInfo = {
  __typename?: 'FavoriteTaskMemberInfo';
  member: Member;
  sortNo: Scalars['BigInteger'];
};

export enum FavoriteTaskSortKey {
  Assigner = 'Assigner',
  ClientName = 'ClientName',
  DeviationRate = 'DeviationRate',
  EstimateWorkingTimeSec = 'EstimateWorkingTimeSec',
  Priority = 'Priority',
  ProgressRate = 'ProgressRate',
  ProjectName = 'ProjectName',
  ScheduledEndDate = 'ScheduledEndDate',
  ScheduledStartDate = 'ScheduledStartDate',
  SortNoInFavoriteList = 'SortNoInFavoriteList',
  SortNoInGanttChart = 'SortNoInGanttChart',
  SortNoInList = 'SortNoInList',
  TeamName = 'TeamName',
  Title = 'Title',
  WorkingTimeSec = 'WorkingTimeSec',
}

export type FetchAssignedProjectsInputInput = {
  assineeMemberIds?: Maybe<Array<Scalars['String']>>;
  clientIds?: Maybe<Array<Scalars['String']>>;
  completeCondition?: Maybe<ProjectCompleteFilter>;
  favoriteCondition?: Maybe<ProjectFavoriteFilter>;
  projectName?: Maybe<Scalars['String']>;
  sortKey?: Maybe<ProjectSortKey>;
  sortOrder?: Maybe<SortOrder>;
};

export type FetchClientsInputInput = {
  clientName?: Maybe<Scalars['String']>;
  sortKey?: Maybe<ClientSortKey>;
  sortOrder?: Maybe<SortOrder>;
};

export type FetchExportProjectExcelInputInput = {
  teamId?: Maybe<Scalars['String']>;
};

export type FetchExportTaskExcelInputInput = {
  teamId?: Maybe<Scalars['String']>;
};

export type FetchFavoriteProjectsInputInput = {
  assineeMemberIds?: Maybe<Array<Scalars['String']>>;
  clientIds?: Maybe<Array<Scalars['String']>>;
  completeCondition?: Maybe<ProjectCompleteFilter>;
  projectName?: Maybe<Scalars['String']>;
  sortKey?: Maybe<ProjectSortKey>;
  sortOrder?: Maybe<SortOrder>;
};

export type FetchProjectTasksInputInput = {
  sortKey?: Maybe<TaskSortKey>;
  sortOrder?: Maybe<SortOrder>;
  taskStatusIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FetchProjectTemplatesInputInput = {
  name?: Maybe<Scalars['String']>;
  sortKey?: Maybe<ProjectTemplateSortKey>;
  sortOrder?: Maybe<SortOrder>;
};

export type FetchTagsInputInput = {
  name?: Maybe<Scalars['String']>;
  sortKey?: Maybe<TagSortKey>;
  sortOrder?: Maybe<SortOrder>;
};

export type FetchTeamProjectContractsInputInput = {
  assineeMemberIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  completeCondition?: Maybe<ProjectCompleteFilter>;
  favoriteCondition?: Maybe<ProjectFavoriteFilter>;
  projectName?: Maybe<Scalars['String']>;
  projectStatusIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  sortKey?: Maybe<ProjectContractSortKey>;
  sortOrder?: Maybe<SortOrder>;
};

export type FetchTeamProjectsInputInput = {
  assineeMemberIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  completeCondition?: Maybe<ProjectCompleteFilter>;
  favoriteCondition?: Maybe<ProjectFavoriteFilter>;
  projectName?: Maybe<Scalars['String']>;
  projectStatusIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  sortKey?: Maybe<ProjectSortKey>;
  sortOrder?: Maybe<SortOrder>;
};

export type FileDownloadInfo = {
  __typename?: 'FileDownloadInfo';
  downloadUrl: Scalars['String'];
};

export type FirstViewProjectInfo = {
  __typename?: 'FirstViewProjectInfo';
  projectId?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['String']>;
};

export type GoogleAccount = {
  __typename?: 'GoogleAccount';
  calendarPermission: Scalars['Boolean'];
  mailAddress: Scalars['String'];
  name: Scalars['String'];
};

export type GoogleCalendar = {
  __typename?: 'GoogleCalendar';
  backgroundColor: Scalars['String'];
  calendarId: Scalars['String'];
  colorId: Scalars['String'];
  foregroundColor: Scalars['String'];
  id: Scalars['ID'];
  myCalendar: Scalars['Boolean'];
  name: Scalars['String'];
  primary: Scalars['Boolean'];
  readonly: Scalars['Boolean'];
};

export type GoogleCalendarEvent = {
  __typename?: 'GoogleCalendarEvent';
  allDay: Scalars['Boolean'];
  attendees?: Maybe<Array<Maybe<Attendee>>>;
  backgroundColor: Scalars['String'];
  calendarId: Scalars['String'];
  colorId?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  dateWhenAllDay?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  endDateTime?: Maybe<Scalars['DateTime']>;
  foregroundColor: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  location?: Maybe<Scalars['String']>;
  organizer?: Maybe<NameAndMailAddress>;
  readonly: Scalars['Boolean'];
  /** ISO-8601 */
  startDateTime?: Maybe<Scalars['DateTime']>;
  status?: Maybe<GoogleCalendarEventStatus>;
  targetMemberId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  visivility?: Maybe<GoogleCalendarEventVisivility>;
};

export enum GoogleCalendarEventStatus {
  Cancelled = 'CANCELLED',
  Confirmed = 'CONFIRMED',
  Tentative = 'TENTATIVE',
}

export enum GoogleCalendarEventVisivility {
  Confidential = 'CONFIDENTIAL',
  Default = 'DEFAULT',
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export type GoogleCalendarInputInput = {
  calendarIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** ISO-8601 */
  end: Scalars['DateTime'];
  memberIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** ISO-8601 */
  start: Scalars['DateTime'];
};

export type GoogleProfile = {
  __typename?: 'GoogleProfile';
  domain?: Maybe<Scalars['String']>;
  mailAddress: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  profileImageUrl?: Maybe<Scalars['String']>;
  registerd?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
};

export type GraphDataClient = {
  __typename?: 'GraphDataClient';
  clientId: Scalars['String'];
  clientName: Scalars['String'];
  color: Scalars['String'];
  laborCost?: Maybe<Scalars['BigInteger']>;
  percent: Scalars['Float'];
  reportType: ReportType;
  workingTimeSec: Scalars['BigInteger'];
};

export type GraphDataClientListInputInput = {
  clientIds: Array<Maybe<Scalars['String']>>;
  /** ISO-8601 */
  end: Scalars['DateTime'];
  memberId?: Maybe<Scalars['String']>;
  memberIds: Array<Maybe<Scalars['String']>>;
  organizationId: Scalars['String'];
  projectIds: Array<Maybe<Scalars['String']>>;
  reportType: ReportType;
  sortKey?: Maybe<GraphDataClientSortKey>;
  sortOrder?: Maybe<SortOrder>;
  /** ISO-8601 */
  start: Scalars['DateTime'];
  teamId?: Maybe<Scalars['String']>;
  teamIds: Array<Maybe<Scalars['String']>>;
  timeZoneOffset: Scalars['String'];
};

export enum GraphDataClientSortKey {
  ClientName = 'ClientName',
  LaborCost = 'LaborCost',
  WorkingTimeSec = 'WorkingTimeSec',
}

export type GraphDataMember = {
  __typename?: 'GraphDataMember';
  color?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  employeeNumber?: Maybe<Scalars['String']>;
  laborCost?: Maybe<Scalars['BigInteger']>;
  mailAddress?: Maybe<Scalars['String']>;
  memberId: Scalars['String'];
  name: Scalars['String'];
  percent: Scalars['Float'];
  profileImageUrl?: Maybe<Scalars['String']>;
  reportType: ReportType;
  workingTimeSec: Scalars['BigInteger'];
};

export type GraphDataMemberListInputInput = {
  clientIds: Array<Maybe<Scalars['String']>>;
  /** ISO-8601 */
  end: Scalars['DateTime'];
  memberId?: Maybe<Scalars['String']>;
  memberIds: Array<Maybe<Scalars['String']>>;
  organizationId: Scalars['String'];
  projectIds: Array<Maybe<Scalars['String']>>;
  reportType: ReportType;
  sortKey?: Maybe<GraphDataMemberSortKey>;
  sortOrder?: Maybe<SortOrder>;
  /** ISO-8601 */
  start: Scalars['DateTime'];
  teamId?: Maybe<Scalars['String']>;
  teamIds: Array<Maybe<Scalars['String']>>;
  timeZoneOffset: Scalars['String'];
};

export enum GraphDataMemberSortKey {
  Department = 'Department',
  LaborCost = 'LaborCost',
  MemberName = 'MemberName',
  WorkingTimeSec = 'WorkingTimeSec',
}

export type GraphDataProject = {
  __typename?: 'GraphDataProject';
  clientId?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  color: Scalars['String'];
  laborCost?: Maybe<Scalars['BigInteger']>;
  percent: Scalars['Float'];
  projectId: Scalars['String'];
  projectName: Scalars['String'];
  reportType: ReportType;
  teamId: Scalars['String'];
  teamName: Scalars['String'];
  workingTimeSec: Scalars['BigInteger'];
};

export type GraphDataProjectListInputInput = {
  clientIds: Array<Maybe<Scalars['String']>>;
  /** ISO-8601 */
  end: Scalars['DateTime'];
  memberId?: Maybe<Scalars['String']>;
  memberIds: Array<Maybe<Scalars['String']>>;
  organizationId: Scalars['String'];
  projectIds: Array<Maybe<Scalars['String']>>;
  reportType: ReportType;
  sortKey?: Maybe<GraphDataProjectSortKey>;
  sortOrder?: Maybe<SortOrder>;
  /** ISO-8601 */
  start: Scalars['DateTime'];
  teamId?: Maybe<Scalars['String']>;
  teamIds: Array<Maybe<Scalars['String']>>;
  timeZoneOffset: Scalars['String'];
};

export enum GraphDataProjectSortKey {
  ClientName = 'ClientName',
  LaborCost = 'LaborCost',
  ProjectName = 'ProjectName',
  TeamName = 'TeamName',
  WorkingTimeSec = 'WorkingTimeSec',
}

export type GraphDataTask = {
  __typename?: 'GraphDataTask';
  clientId?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  color: Scalars['String'];
  itemType: GraphDataTaskItemType;
  laborCost?: Maybe<Scalars['BigInteger']>;
  percent: Scalars['Float'];
  projectId: Scalars['String'];
  projectName: Scalars['String'];
  reportType: ReportType;
  tagNames: Array<Maybe<Scalars['String']>>;
  taskId: Scalars['String'];
  taskTitle: Scalars['String'];
  teamId: Scalars['String'];
  teamName: Scalars['String'];
  workingMembers: Array<Maybe<GraphTaskWorkingMember>>;
  workingTimeSec: Scalars['BigInteger'];
};

export enum GraphDataTaskItemType {
  CalendarEvent = 'CalendarEvent',
  Dummy = 'DUMMY',
  Task = 'TASK',
}

export type GraphDataTaskListInputInput = {
  clientIds: Array<Maybe<Scalars['String']>>;
  /** ISO-8601 */
  end: Scalars['DateTime'];
  memberId?: Maybe<Scalars['String']>;
  memberIds: Array<Maybe<Scalars['String']>>;
  organizationId: Scalars['String'];
  projectIds: Array<Maybe<Scalars['String']>>;
  reportType: ReportType;
  sortKey?: Maybe<GraphDataTaskSortKey>;
  sortOrder?: Maybe<SortOrder>;
  /** ISO-8601 */
  start: Scalars['DateTime'];
  teamId?: Maybe<Scalars['String']>;
  teamIds: Array<Maybe<Scalars['String']>>;
  timeZoneOffset: Scalars['String'];
};

export enum GraphDataTaskSortKey {
  ClientName = 'ClientName',
  LaborCost = 'LaborCost',
  ProjectName = 'ProjectName',
  TaskTitle = 'TaskTitle',
  TeamName = 'TeamName',
  WorkingTimeSec = 'WorkingTimeSec',
}

export type GraphDataTeam = {
  __typename?: 'GraphDataTeam';
  color: Scalars['String'];
  laborCost?: Maybe<Scalars['BigInteger']>;
  percent: Scalars['Float'];
  reportType: ReportType;
  teamId: Scalars['String'];
  teamName: Scalars['String'];
  workingTimeSec: Scalars['BigInteger'];
};

export type GraphDataTeamListInputInput = {
  clientIds: Array<Maybe<Scalars['String']>>;
  /** ISO-8601 */
  end: Scalars['DateTime'];
  memberId?: Maybe<Scalars['String']>;
  memberIds: Array<Maybe<Scalars['String']>>;
  organizationId: Scalars['String'];
  projectIds: Array<Maybe<Scalars['String']>>;
  reportType: ReportType;
  sortKey?: Maybe<GraphDataTeamSortKey>;
  sortOrder?: Maybe<SortOrder>;
  /** ISO-8601 */
  start: Scalars['DateTime'];
  teamId?: Maybe<Scalars['String']>;
  teamIds: Array<Maybe<Scalars['String']>>;
  timeZoneOffset: Scalars['String'];
};

export enum GraphDataTeamSortKey {
  LaborCost = 'LaborCost',
  TeamName = 'TeamName',
  WorkingTimeSec = 'WorkingTimeSec',
}

export type GraphTaskWorkingMember = {
  __typename?: 'GraphTaskWorkingMember';
  mailAddress: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  profileImageUrl?: Maybe<Scalars['String']>;
};

export type ICalExport = {
  __typename?: 'ICalExport';
  /** ISO-8601 */
  end: Scalars['DateTime'];
  id: Scalars['ID'];
  /** ISO-8601 */
  start: Scalars['DateTime'];
  status: ICalExportStatus;
  type: ICalFileType;
};

export type ICalExportInputInput = {
  /** ISO-8601 */
  end: Scalars['DateTime'];
  /** ISO-8601 */
  start: Scalars['DateTime'];
  type: ICalFileType;
};

export enum ICalExportStatus {
  Accepted = 'Accepted',
  Failure = 'Failure',
  InProgress = 'InProgress',
  Success = 'Success',
}

export type ICalFileDownloadInfo = {
  __typename?: 'ICalFileDownloadInfo';
  downloadUrl: Scalars['String'];
};

export enum ICalFileType {
  WorkingHistory = 'WorkingHistory',
  WorkingSchedule = 'WorkingSchedule',
}

export type ICalKey = {
  __typename?: 'ICalKey';
  timeEntriesUrl: Scalars['String'];
  workSchedulesUrl: Scalars['String'];
};

export type IncompleteProjectInputInput = {
  versionNo: Scalars['Int'];
};

export type IncompleteSubTaskInputInput = {
  versionNo: Scalars['Int'];
};

export type IncompleteTaskInputInput = {
  versionNo: Scalars['Int'];
};

export enum InitialViewType {
  MyPage = 'MyPage',
  Project = 'Project',
}

export type InviteMemberWithMailAddressInputInput = {
  adminRole: Scalars['Boolean'];
  clientManagementRole: Scalars['Boolean'];
  department?: Maybe<Scalars['String']>;
  employeeNumber?: Maybe<Scalars['String']>;
  laborCostManagementRole: Scalars['Boolean'];
  mailAddress: Scalars['String'];
  memberManagementRole: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  organizationMemberViewRole: Scalars['Boolean'];
  organizationReportRole: Scalars['Boolean'];
  projectCreatePermissionFlg: Scalars['Boolean'];
  projectCustomAttributePermissionFlg: Scalars['Boolean'];
  projectDeletePermissionFlg: Scalars['Boolean'];
  projectUpdatePermissionFlg: Scalars['Boolean'];
  proxyEditWorkingDataRole: Scalars['Boolean'];
  salesManagementRole: Scalars['Boolean'];
  taskCreatePermissionFlg: Scalars['Boolean'];
  taskCustomAttributePermissionFlg: Scalars['Boolean'];
  taskDeletePermissionFlg: Scalars['Boolean'];
  taskUpdatePermissionFlg: Scalars['Boolean'];
  teamIds: Array<Maybe<Scalars['String']>>;
  teamMemberViewRole: Scalars['Boolean'];
  teamReportRole: Scalars['Boolean'];
};

export type Invoice = {
  __typename?: 'Invoice';
  /** ISO-8601 */
  billingDateTime: Scalars['DateTime'];
  billingNumber?: Maybe<Scalars['String']>;
  discount: Scalars['Int'];
  invoicePdfUrl?: Maybe<Scalars['String']>;
  paid: Scalars['Boolean'];
  /** ISO-8601 */
  periodEndDateTime: Scalars['DateTime'];
  /** ISO-8601 */
  periodStartDateTime: Scalars['DateTime'];
  quantity: Scalars['Int'];
  receiptUrl?: Maybe<Scalars['String']>;
  subtotal: Scalars['Int'];
  tax: Scalars['Int'];
  title: Scalars['String'];
  total: Scalars['Int'];
  unitPrice: Scalars['Int'];
};

export type IpAddressListInputInput = {
  allowIpAddresses?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type IssueAppUserIdInputInput = {
  versionNo: Scalars['Int'];
};

export enum JobProgressStatus {
  Accepted = 'Accepted',
  Failure = 'Failure',
  InProgress = 'InProgress',
  Success = 'Success',
}

export type JoinMemberToTeamInputInput = {
  memberId: Scalars['String'];
  teamId: Scalars['String'];
};

export type JoinOrganizationMailAddressByInviteLinkInputInput = {
  key: Scalars['String'];
  /** 氏名(100文字以内) */
  name: Scalars['String'];
  /** パスワード(8〜200文字) */
  password: Scalars['String'];
  /** プロフィール画像（base64エンコード） */
  profileImageBase64?: Maybe<Scalars['String']>;
};

export type JoinOrganizationMailAddressInputInput = {
  key: Scalars['String'];
  /** 氏名(100文字以内) */
  name: Scalars['String'];
  /** パスワード(8〜200文字) */
  password: Scalars['String'];
  /** プロフィール画像（base64エンコード） */
  profileImageBase64?: Maybe<Scalars['String']>;
};

export type JoinToOrganizationWithGoogleAccountByInviteLinkInputInput = {
  /** Googleアカウントの認証コード */
  googleAuthCode: Scalars['String'];
  key: Scalars['String'];
  /** 氏名(100文字以内) */
  name: Scalars['String'];
  /** プロフィール画像（base64エンコード） */
  profileImageBase64?: Maybe<Scalars['String']>;
};

export type JoinToOrganizationWithGoogleAccountInputInput = {
  /** Googleアカウントの認証コード */
  googleAuthCode: Scalars['String'];
  key: Scalars['String'];
  /** 氏名(100文字以内) */
  name: Scalars['String'];
  /** プロフィール画像（base64エンコード） */
  profileImageBase64?: Maybe<Scalars['String']>;
};

export type LaborCost = {
  __typename?: 'LaborCost';
  hourlyWage: Scalars['Int'];
  id?: Maybe<Scalars['ID']>;
  /** ISO-8601 */
  startDateTime: Scalars['DateTime'];
  versionNo: Scalars['Int'];
};

export type LoginInputInput = {
  mailAddress: Scalars['String'];
  password: Scalars['String'];
};

export type LoginResult = {
  __typename?: 'LoginResult';
  loginUserToken?: Maybe<Scalars['String']>;
  oneTimeToken?: Maybe<Scalars['String']>;
  requiredTwoFactorAuth: Scalars['Boolean'];
};

export type LoginWithAppleAccountInputInput = {
  code: Scalars['String'];
  token: Scalars['String'];
};

export type LowerPart = {
  __typename?: 'LowerPart';
  deducationHourlyUnitPrice: Scalars['Int'];
  lowerHour: Scalars['Int'];
};

export type Member = {
  __typename?: 'Member';
  adminRole: Scalars['Boolean'];
  clientManagementRole: Scalars['Boolean'];
  department?: Maybe<Scalars['String']>;
  employeeNumber?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  laborCostManagementRole: Scalars['Boolean'];
  mailAddress: Scalars['String'];
  memberManagementRole: Scalars['Boolean'];
  /** ISO-8601 */
  memberRegistrationDateTime: Scalars['DateTime'];
  memberStatus: MemberStatus;
  name?: Maybe<Scalars['String']>;
  organizationMemberViewRole: Scalars['Boolean'];
  organizationReportRole: Scalars['Boolean'];
  profileImageUrl?: Maybe<Scalars['String']>;
  projectCreatePermissionFlg: Scalars['Boolean'];
  projectCustomAttributePermissionFlg: Scalars['Boolean'];
  projectDeletePermissionFlg: Scalars['Boolean'];
  projectUpdatePermissionFlg: Scalars['Boolean'];
  proxyEditWorkingDataRole: Scalars['Boolean'];
  salesManagementRole: Scalars['Boolean'];
  statusMessage?: Maybe<Scalars['String']>;
  taskCreatePermissionFlg: Scalars['Boolean'];
  taskCustomAttributePermissionFlg: Scalars['Boolean'];
  taskDeletePermissionFlg: Scalars['Boolean'];
  taskUpdatePermissionFlg: Scalars['Boolean'];
  teamMemberViewRole: Scalars['Boolean'];
  teamReportRole: Scalars['Boolean'];
  versionNo: Scalars['Int'];
};

export type MemberBarGraphData = {
  __typename?: 'MemberBarGraphData';
  color: Scalars['String'];
  department?: Maybe<Scalars['String']>;
  employeeNumber?: Maybe<Scalars['String']>;
  laborCost?: Maybe<Scalars['BigInteger']>;
  mailAddress?: Maybe<Scalars['String']>;
  memberId: Scalars['String'];
  name: Scalars['String'];
  percent: Scalars['Float'];
  profileImageUrl?: Maybe<Scalars['String']>;
  reportType: ReportType;
  workingTimeSec: Scalars['BigInteger'];
};

export type MemberDateGraphData = {
  __typename?: 'MemberDateGraphData';
  /** ISO-8601 */
  date: Scalars['Date'];
  items: Array<Maybe<MemberDateGraphDataDetail>>;
  reportType: ReportType;
};

export type MemberDateGraphDataDetail = {
  __typename?: 'MemberDateGraphDataDetail';
  color?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  employeeNumber?: Maybe<Scalars['String']>;
  laborCost?: Maybe<Scalars['BigInteger']>;
  mailAddress?: Maybe<Scalars['String']>;
  memberId: Scalars['String'];
  name: Scalars['String'];
  percent: Scalars['Float'];
  profileImageUrl?: Maybe<Scalars['String']>;
  workingTimeSec: Scalars['BigInteger'];
};

export type MemberGraphDataInputInput = {
  clientIds: Array<Maybe<Scalars['String']>>;
  /** ISO-8601 */
  end: Scalars['DateTime'];
  memberId?: Maybe<Scalars['String']>;
  memberIds: Array<Maybe<Scalars['String']>>;
  organizationId: Scalars['String'];
  projectIds: Array<Maybe<Scalars['String']>>;
  reportType: ReportType;
  /** ISO-8601 */
  start: Scalars['DateTime'];
  teamId?: Maybe<Scalars['String']>;
  teamIds: Array<Maybe<Scalars['String']>>;
  timeZoneOffset: Scalars['String'];
};

export type MemberInvitation = {
  __typename?: 'MemberInvitation';
  adminRole: Scalars['Boolean'];
  allowThirdPartyCalendarConnect: Scalars['Boolean'];
  clientManagementRole: Scalars['Boolean'];
  createMemberId?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  employeeNumber?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  invitationKey: Scalars['String'];
  laborCostManagementRole: Scalars['Boolean'];
  mailAddress: Scalars['String'];
  memberManagementRole: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  organizationMemberViewRole: Scalars['Boolean'];
  organizationName: Scalars['String'];
  organizationReportRole: Scalars['Boolean'];
  projectCreatePermissionFlg: Scalars['Boolean'];
  projectCustomAttributePermissionFlg: Scalars['Boolean'];
  projectDeletePermissionFlg: Scalars['Boolean'];
  projectUpdatePermissionFlg: Scalars['Boolean'];
  proxyEditWorkingDataRole: Scalars['Boolean'];
  salesManagementRole: Scalars['Boolean'];
  taskCreatePermissionFlg: Scalars['Boolean'];
  taskCustomAttributePermissionFlg: Scalars['Boolean'];
  taskDeletePermissionFlg: Scalars['Boolean'];
  taskUpdatePermissionFlg: Scalars['Boolean'];
  teamMemberViewRole: Scalars['Boolean'];
  teamReportRole: Scalars['Boolean'];
  versionNo: Scalars['Int'];
};

export type MemberInvitationLink = {
  __typename?: 'MemberInvitationLink';
  allowThirdPartyCalendarConnect: Scalars['Boolean'];
  clientManagementRole: Scalars['Boolean'];
  /** ISO-8601 */
  createDateTime: Scalars['DateTime'];
  createMemberId: Scalars['String'];
  id: Scalars['ID'];
  invitationKey: Scalars['String'];
  invitationUrl: Scalars['String'];
  memo?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  organizationMemberViewRole: Scalars['Boolean'];
  organizationName: Scalars['String'];
  organizationReportRole: Scalars['Boolean'];
  projectCreatePermissionFlg: Scalars['Boolean'];
  projectCustomAttributePermissionFlg: Scalars['Boolean'];
  projectDeletePermissionFlg: Scalars['Boolean'];
  projectUpdatePermissionFlg: Scalars['Boolean'];
  proxyEditWorkingDataRole: Scalars['Boolean'];
  taskCreatePermissionFlg: Scalars['Boolean'];
  taskCustomAttributePermissionFlg: Scalars['Boolean'];
  taskDeletePermissionFlg: Scalars['Boolean'];
  taskUpdatePermissionFlg: Scalars['Boolean'];
  teamMemberViewRole: Scalars['Boolean'];
  teamReportRole: Scalars['Boolean'];
  versionNo: Scalars['Int'];
};

export type MemberRegisterRequest = {
  __typename?: 'MemberRegisterRequest';
  mailAddress: Scalars['String'];
  memberRegisterKey: Scalars['String'];
};

export type MemberRegisterRequestInputInput = {
  /** メールアドレス */
  mailAddress: Scalars['String'];
};

export type MemberRegisterRequestWithInvitationLInkInputInput = {
  key: Scalars['String'];
  /** メールアドレス */
  mailAddress: Scalars['String'];
};

export type MemberSettings = {
  __typename?: 'MemberSettings';
  allowExportCalendar: Scalars['Boolean'];
  autoAssignWhenTaskStart: Scalars['Boolean'];
  calendarStartDayOfWeek: Scalars['Int'];
  copyWorkingScheduleMemoToHistory: Scalars['Boolean'];
  id: Scalars['ID'];
  initialViewTypeCode: InitialViewType;
  longTimeWorkingAlertMinutes?: Maybe<Scalars['Int']>;
  member: Member;
  projectInitialViewTypeCode: ProjectInitialViewType;
  twoFactorAuthEnabled: Scalars['Boolean'];
  versionNo: Scalars['Int'];
};

export enum MemberStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Inviting = 'Inviting',
}

export type MemberStatusCalendarEventPart = {
  __typename?: 'MemberStatusCalendarEventPart';
  eventName: Scalars['String'];
  id: Scalars['ID'];
  workingTimeSec: Scalars['Int'];
};

export type MemberStatusProjectPart = {
  __typename?: 'MemberStatusProjectPart';
  id: Scalars['ID'];
  projectName: Scalars['String'];
};

export type MemberStatusTaskPart = {
  __typename?: 'MemberStatusTaskPart';
  /** ISO-8601 */
  completeDateTime?: Maybe<Scalars['DateTime']>;
  estimateTimeSec?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  priority?: Maybe<Priority>;
  progressRate: Scalars['Int'];
  /** ISO-8601 */
  scheduledEndDateTime?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  versionNo: Scalars['Int'];
  workingMembers: Array<Maybe<MemberStatusWorkingMemberInfo>>;
  workingTimeSec: Scalars['BigInteger'];
};

export type MemberStatusWorkingHistoryPart = {
  __typename?: 'MemberStatusWorkingHistoryPart';
  calendarEvent?: Maybe<MemberStatusCalendarEventPart>;
  /** ISO-8601 */
  end?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  project: MemberStatusProjectPart;
  /** ISO-8601 */
  start: Scalars['DateTime'];
  task?: Maybe<MemberStatusTaskPart>;
  versionNo: Scalars['Int'];
  workingTimeSec?: Maybe<Scalars['Int']>;
};

export type MemberStatusWorkingMemberInfo = {
  __typename?: 'MemberStatusWorkingMemberInfo';
  id: Scalars['ID'];
  /** ISO-8601 */
  lastWorkingStartDateTime: Scalars['DateTime'];
};

export type MemberSunburstGraphData = {
  __typename?: 'MemberSunburstGraphData';
  color: Scalars['String'];
  department?: Maybe<Scalars['String']>;
  mailAddress: Scalars['String'];
  memberId: Scalars['String'];
  name: Scalars['String'];
  percent: Scalars['Float'];
  profileImageUrl?: Maybe<Scalars['String']>;
  reportType: ReportType;
  workingTimeSec: Scalars['BigInteger'];
};

export type MemberWithLatestTask = {
  __typename?: 'MemberWithLatestTask';
  latestWorkingHistory?: Maybe<MemberStatusWorkingHistoryPart>;
  member: Member;
};

export type Mention = {
  __typename?: 'Mention';
  comment?: Maybe<TaskComment>;
  id: Scalars['ID'];
  project?: Maybe<Project>;
  sendMember: Member;
  task?: Maybe<Task>;
};

export enum MentionType {
  Comment = 'Comment',
  ProjectDescription = 'ProjectDescription',
  TaskDescription = 'TaskDescription',
}

export type MentionV2 = {
  __typename?: 'MentionV2';
  commentId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mentionType: MentionType;
  message: Scalars['String'];
  projectId: Scalars['String'];
  projectName: Scalars['String'];
  sendMember: NotificationV2MemberPart;
  taskId?: Maybe<Scalars['String']>;
  taskTitle?: Maybe<Scalars['String']>;
  teamId: Scalars['String'];
  teamName: Scalars['String'];
};

export type MeteredRateContract = {
  __typename?: 'MeteredRateContract';
  hourUnitPrice: Scalars['Int'];
  id?: Maybe<Scalars['ID']>;
  member?: Maybe<MeteredRateContractMemberPart>;
  versionNo: Scalars['Int'];
};

export type MeteredRateContractMemberPart = {
  __typename?: 'MeteredRateContractMemberPart';
  laborCostWithoutTax: Scalars['BigInteger'];
  memberId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  profileImageUrl?: Maybe<Scalars['String']>;
  roundedTotalWorkingTimeSec: Scalars['Int'];
  salesAmountWithoutTax: Scalars['BigInteger'];
  totalWorkingTimeSec: Scalars['Int'];
};

export type MoveCalendarEventInputInput = {
  projectId: Scalars['String'];
  taskId?: Maybe<Scalars['String']>;
  versionNo: Scalars['Int'];
};

export type MoveProjectInputInput = {
  teamId: Scalars['String'];
  versionNo: Scalars['Int'];
};

export type MoveTaskInputInput = {
  projectId: Scalars['String'];
  versionNo: Scalars['Int'];
};

export type MoveWorkingHistoryInputInput = {
  taskId: Scalars['String'];
  versionNo: Scalars['Int'];
};

export type MoveWorkingScheduleInputInput = {
  taskId: Scalars['String'];
  versionNo: Scalars['Int'];
};

/** Mutation root */
export type Mutation = {
  __typename?: 'Mutation';
  addCsvReportItem?: Maybe<CsvReportItemDefinition>;
  addProjectCustomAttribute?: Maybe<ProjectCustomAttribute>;
  addProjectCustomAttributeMasterListItem?: Maybe<ProjectCustomAttributeMasterListItem>;
  addProjectTemplateCustomAttribute?: Maybe<ProjectTemplateCustomAttribute>;
  addProjectTemplateTaskCustomAttribute?: Maybe<ProjectTemplateTaskCustomAttribute>;
  addTaskComment?: Maybe<TaskComment>;
  addTaskCustomAttribute?: Maybe<TaskCustomAttribute>;
  addTaskCustomAttributeMasterListItem?: Maybe<TaskCustomAttributeMasterListItem>;
  archiveCompletedTasks?: Maybe<Task>;
  archiveTeam?: Maybe<Team>;
  cancelFavoriteProject?: Maybe<Project>;
  cancelFavoriteTask?: Maybe<Task>;
  changeContractPeriod?: Maybe<Contract>;
  changePlan?: Maybe<Contract>;
  closeAllTeamMenu?: Maybe<Array<Maybe<TeamMemberRelation>>>;
  closeTerm?: Maybe<ClosingSetting>;
  completeProject?: Maybe<Project>;
  completeSubTask?: Maybe<SubTask>;
  completeTask?: Maybe<CompleteTaskResult>;
  connectAppleAccount?: Maybe<Scalars['Boolean']>;
  connectGoogleAccount?: Maybe<GoogleProfile>;
  copyProject?: Maybe<Project>;
  copyTask?: Maybe<Task>;
  createAttachementFileDownloadUrl?: Maybe<AttachmentFileDownloadInfo>;
  createAttachementFileForProject?: Maybe<AttachmentFile>;
  createAttachementFileForTask?: Maybe<AttachmentFile>;
  createAttachementFileUploadUrl?: Maybe<AttachmentFileUploadInfo>;
  createCalendarEventWithTaskAndWorkingHistory?: Maybe<CreateCalendarEventWithWorkingHistoryResult>;
  createCalendarEventWithTaskAndWorkingSchedule?: Maybe<CreateCalendarEventWithWorkingScheduleResult>;
  createCalendarEventWithWorkingHistory?: Maybe<CreateCalendarEventWithWorkingHistoryResult>;
  createCalendarEventWithWorkingSchedule?: Maybe<CreateCalendarEventWithWorkingScheduleResult>;
  createExportOrganizationClientExcelDownloadUrl?: Maybe<FileDownloadInfo>;
  createExportOrganizationMemberExcelDownloadUrl?: Maybe<FileDownloadInfo>;
  createExportProjectExcelDownloadUrl?: Maybe<FileDownloadInfo>;
  createExportTaskExcelDownloadUrl?: Maybe<FileDownloadInfo>;
  createICalFileDownloadUrl?: Maybe<ICalFileDownloadInfo>;
  createInvitationLink?: Maybe<MemberInvitationLink>;
  createLaborCost?: Maybe<LaborCost>;
  createMeteredRateContract?: Maybe<MeteredRateContract>;
  createMyCsvReportDownloadUrl?: Maybe<FileDownloadInfo>;
  createMyReportDownloadUrl?: Maybe<FileDownloadInfo>;
  createOrganizationCsvReportDownloadUrl?: Maybe<FileDownloadInfo>;
  createOrganizationReportDownloadUrl?: Maybe<FileDownloadInfo>;
  createProject?: Maybe<Project>;
  createProjectContract?: Maybe<ProjectContract>;
  createProjectCustomAttributeMaster?: Maybe<ProjectCustomAttributeMaster>;
  createProjectStatus?: Maybe<ProjectStatus>;
  createProjectTemplate?: Maybe<ProjectTemplate>;
  createProjectTemplateSubTask?: Maybe<ProjectTemplateSubTask>;
  createProjectTemplateTask?: Maybe<ProjectTemplateTask>;
  createProjectTemplateTaskRemainder?: Maybe<ProjectTemplateTaskRemainder>;
  createProjectTemplateTaskStatus?: Maybe<ProjectTemplateTaskStatus>;
  createProjectTemplateTasks?: Maybe<Array<Maybe<ProjectTemplateTask>>>;
  createSettlementRangeContract?: Maybe<SettlementRangeContract>;
  createSubTask?: Maybe<SubTask>;
  createTag?: Maybe<Tag>;
  createTask?: Maybe<Task>;
  createTaskCustomAttributeMaster?: Maybe<TaskCustomAttributeMaster>;
  createTaskRemainder?: Maybe<TaskRemainder>;
  createTaskStatus?: Maybe<TaskStatus>;
  createTasks?: Maybe<Array<Maybe<Task>>>;
  createTeam?: Maybe<Team>;
  createTeamCsvReportDownloadUrl?: Maybe<FileDownloadInfo>;
  createTeamReportDownloadUrl?: Maybe<FileDownloadInfo>;
  createWorkingHistory?: Maybe<WorkingHistory>;
  createWorkingHistoryByCalendarEvent?: Maybe<WorkingHistory>;
  createWorkingHistoryWithTask?: Maybe<CreateWorkingHistoryWithTaskResult>;
  createWorkingSchedule?: Maybe<WorkingSchedule>;
  createWorkingScheduleWithTask?: Maybe<CreateWorkingScheduleWithTaskResult>;
  deactivateMemberTwoFactorAuth?: Maybe<TwoFactorAuthSetting>;
  deactivateTwoFactorAuth?: Maybe<TwoFactorAuthSetting>;
  deleteAttachementFile?: Maybe<AttachmentFile>;
  deleteCalendarEvent?: Maybe<CalendarEvent>;
  deleteClient?: Maybe<Client>;
  deleteCsvReportItem?: Maybe<CsvReportItemDefinition>;
  deleteCsvReportItemDefinition?: Maybe<CsvReportItemDefinition>;
  deleteGoogleCalendarEvent?: Maybe<GoogleCalendarEvent>;
  deleteInvitation?: Maybe<Scalars['Boolean']>;
  deleteInvitationLink?: Maybe<Scalars['Boolean']>;
  deleteLaborCost?: Maybe<LaborCost>;
  deleteMeteredRateContract?: Maybe<MeteredRateContract>;
  deleteProject?: Maybe<Project>;
  deleteProjectContract?: Maybe<ProjectContract>;
  deleteProjectCustomAttribute?: Maybe<ProjectCustomAttribute>;
  deleteProjectCustomAttributeMaster?: Maybe<ProjectCustomAttributeMaster>;
  deleteProjectCustomAttributeMasterListItem?: Maybe<ProjectCustomAttributeMasterListItem>;
  deleteProjectStatus?: Maybe<ProjectStatus>;
  deleteProjectTemplate?: Maybe<ProjectTemplate>;
  deleteProjectTemplateCustomAttribute?: Maybe<ProjectTemplateCustomAttribute>;
  deleteProjectTemplateSubTask?: Maybe<ProjectTemplateSubTask>;
  deleteProjectTemplateTask?: Maybe<ProjectTemplateTask>;
  deleteProjectTemplateTaskCustomAttribute?: Maybe<ProjectTemplateTaskCustomAttribute>;
  deleteProjectTemplateTaskRemainder?: Maybe<ProjectTemplateTaskRemainder>;
  deleteProjectTemplateTaskStatus?: Maybe<ProjectTemplateTaskStatus>;
  deleteSettlementRangeContract?: Maybe<SettlementRangeContract>;
  deleteSubTask?: Maybe<Task>;
  deleteTag?: Maybe<Tag>;
  deleteTask?: Maybe<Task>;
  deleteTaskComment?: Maybe<TaskComment>;
  deleteTaskCustomAttribute?: Maybe<TaskCustomAttribute>;
  deleteTaskCustomAttributeMaster?: Maybe<TaskCustomAttributeMaster>;
  deleteTaskCustomAttributeMasterListItem?: Maybe<TaskCustomAttributeMasterListItem>;
  deleteTaskRemainder?: Maybe<TaskRemainder>;
  deleteTaskStatus?: Maybe<TaskStatus>;
  deleteWorkingHistory?: Maybe<WorkingHistory>;
  deleteWorkingSchedule?: Maybe<WorkingSchedule>;
  disableAccessControl?: Maybe<AccessControlSettings>;
  disableAccessControlByToken?: Maybe<AccessControlSettings>;
  disableThirdPartyCalendarConnect?: Maybe<Organization>;
  disableTwoFactorAuthRequied?: Maybe<Organization>;
  disconnectAppleAccount: Scalars['Boolean'];
  disconnectGoogleAccount: Scalars['Boolean'];
  enableAccessControl?: Maybe<AccessControlSettings>;
  enableThirdPartyCalendarConnect?: Maybe<Organization>;
  enableTwoFactorAuthRequied?: Maybe<Organization>;
  exportICalFile?: Maybe<ICalExport>;
  favoriteProject?: Maybe<Project>;
  favoriteTask?: Maybe<Task>;
  incompleteProject?: Maybe<Project>;
  incompleteSubTask?: Maybe<SubTask>;
  incompleteTask?: Maybe<Task>;
  inviteMember?: Maybe<MemberInvitation>;
  issueAppUserId?: Maybe<Contract>;
  joinMemberToTeam?: Maybe<Member>;
  joinToOrganizationWithGoogleAccount?: Maybe<Scalars['String']>;
  joinToOrganizationWithGoogleAccountByInviteLink?: Maybe<Scalars['String']>;
  joinToOrganizationWithMailAddress?: Maybe<Scalars['String']>;
  joinToOrganizationWithMailAddressByInviteLink?: Maybe<Scalars['String']>;
  joinToTeam?: Maybe<TeamMemberRelation>;
  login?: Maybe<Scalars['String']>;
  loginV2?: Maybe<LoginResult>;
  loginWithApple?: Maybe<Scalars['String']>;
  loginWithAppleForWeb?: Maybe<Scalars['String']>;
  loginWithAppleForWebV2?: Maybe<LoginResult>;
  loginWithAppleV2?: Maybe<LoginResult>;
  loginWithGoogle?: Maybe<Scalars['String']>;
  loginWithGoogleV2?: Maybe<LoginResult>;
  logout: Scalars['Boolean'];
  moveCalendarEvent?: Maybe<CalendarEvent>;
  moveProject?: Maybe<Project>;
  moveTask?: Maybe<Task>;
  moveWorkingHistory?: Maybe<WorkingHistory>;
  moveWorkingSchedule?: Maybe<WorkingSchedule>;
  openAllTeamMenu?: Maybe<Array<Maybe<TeamMemberRelation>>>;
  parseAppleAuthCode?: Maybe<AppleProfile>;
  parseGoogleAuthCode?: Maybe<GoogleProfile>;
  parseGoogleAuthCodeWithInviteKey?: Maybe<GoogleProfile>;
  parseGoogleAuthCodeWithInviteLink?: Maybe<GoogleProfile>;
  proxyLogin?: Maybe<Scalars['String']>;
  readAllAnnouncement?: Maybe<Array<Maybe<Announcement>>>;
  readAnnouncement?: Maybe<Announcement>;
  refleshApiKey?: Maybe<ApiKey>;
  refleshToken?: Maybe<Scalars['String']>;
  regenerateRecoveryCodes?: Maybe<TwoFactorAuthSetting>;
  registerClient?: Maybe<Client>;
  registerCreditCard?: Maybe<Scalars['Boolean']>;
  registerCsvReportItemDefinition?: Maybe<CsvReportItemDefinition>;
  registerGoogleCalendarEvent?: Maybe<GoogleCalendarEvent>;
  registerPushNotificationDevice?: Maybe<Scalars['Boolean']>;
  relateProjectTemplateTaskTags?: Maybe<Array<Maybe<Tag>>>;
  relateTaskTags?: Maybe<Array<Maybe<Tag>>>;
  removeFromTeam?: Maybe<TeamMemberRelation>;
  removeMemberFromTeam?: Maybe<Member>;
  requestExportOrganizationClientExcel?: Maybe<ExportClientExcel>;
  requestExportOrganizationMemberExcel?: Maybe<ExportMemberExcel>;
  requestExportOrganizationProjectExcel?: Maybe<ExportProjectExcel>;
  requestExportOrganizationTaskExcel?: Maybe<ExportTaskExcel>;
  requestExportTeamProjectExcel?: Maybe<ExportProjectExcel>;
  requestExportTeamTaskExcel?: Maybe<ExportTaskExcel>;
  requestMemberRegister?: Maybe<Scalars['Boolean']>;
  requestMemberRegisterWithInvitationLink?: Maybe<Scalars['Boolean']>;
  requestMyCsvReport?: Maybe<MyCsvReport>;
  requestMyReport?: Maybe<MyReport>;
  requestOrganizationCsvReport?: Maybe<OrganizationCsvReport>;
  requestOrganizationReport?: Maybe<OrganizationReport>;
  requestPasswordReset?: Maybe<Scalars['Boolean']>;
  requestTeamCsvReport?: Maybe<TeamCsvReport>;
  requestTeamReport?: Maybe<TeamReport>;
  resendInvitation?: Maybe<MemberInvitation>;
  resetPassword: Scalars['Boolean'];
  setUpTwoFactorAuth?: Maybe<TwoFactorAuthSetUpInfo>;
  /** Apple認証で会員登録を実行する */
  signupWithAppleAccount?: Maybe<Scalars['String']>;
  /** Google認証で会員登録を実行する */
  signupWithGoogleAccount?: Maybe<Scalars['String']>;
  /** メールアドレスとパスワードを指定した会員登録を実行する */
  signupWithMailAddress?: Maybe<Scalars['String']>;
  startTask?: Maybe<StartTaskResult>;
  stopTask?: Maybe<StopTaskResult>;
  syncRegisterWorkingHistoriesFromGoogleCalendars?: Maybe<Array<Maybe<WorkingHistory>>>;
  syncRegisterWorkingSchedulesFromGoogleCalendars?: Maybe<Array<Maybe<WorkingSchedule>>>;
  teamCloseTerm?: Maybe<TeamClosingSetting>;
  twoFactorLogin?: Maybe<Scalars['String']>;
  twoFactorRecoveryCodeLogin?: Maybe<Scalars['String']>;
  unarchiveTeam?: Maybe<Team>;
  updateAccessAllowIpAddresses?: Maybe<AccessControlSettings>;
  updateBillToMailAddress?: Maybe<Contract>;
  updateCalendarEvent?: Maybe<CalendarEvent>;
  updateClient?: Maybe<Client>;
  updateClientSortNo?: Maybe<Client>;
  updateCsvReportItemDefinition?: Maybe<CsvReportItemDefinition>;
  updateCsvReportItemSortNo?: Maybe<CsvReportItemDefinition>;
  updateGoogleCalendarEvent?: Maybe<GoogleCalendarEvent>;
  updateInvitation?: Maybe<MemberInvitation>;
  updateInvitationLink?: Maybe<MemberInvitationLink>;
  updateLaborCost?: Maybe<LaborCost>;
  updateLicenceCount?: Maybe<Contract>;
  updateMemberProfile?: Maybe<Member>;
  updateMemberRole?: Maybe<Member>;
  updateMemberStatusMessage?: Maybe<Member>;
  updateMeteredRateContract?: Maybe<MeteredRateContract>;
  updateMySettings?: Maybe<MemberSettings>;
  updateNotificationToRead?: Maybe<Scalars['Boolean']>;
  updateNotificationToReadV2?: Maybe<NotificationV2>;
  updateNotificationToReceived?: Maybe<Scalars['Boolean']>;
  updateNotificationToReceivedV2?: Maybe<NotificationV2>;
  updateOrganization?: Maybe<Organization>;
  updateOrganizationImage?: Maybe<Organization>;
  updatePassword: Scalars['Boolean'];
  updateProfileImage?: Maybe<Member>;
  updateProject?: Maybe<Project>;
  updateProjectContract?: Maybe<ProjectContract>;
  updateProjectCustomAttribute?: Maybe<ProjectCustomAttribute>;
  updateProjectCustomAttributeMaster?: Maybe<ProjectCustomAttributeMaster>;
  updateProjectCustomAttributeMasterListItem?: Maybe<ProjectCustomAttributeMasterListItem>;
  updateProjectSortNoInAssignedProject?: Maybe<Project>;
  updateProjectSortNoInFavoriteProject?: Maybe<Project>;
  updateProjectSortNoInList?: Maybe<Project>;
  updateProjectStatus?: Maybe<ProjectStatus>;
  updateProjectTemplate?: Maybe<ProjectTemplate>;
  updateProjectTemplateCustomAttribute?: Maybe<ProjectTemplateCustomAttribute>;
  updateProjectTemplateSortNoInList?: Maybe<ProjectTemplate>;
  updateProjectTemplateSubTask?: Maybe<ProjectTemplateSubTask>;
  updateProjectTemplateSubTaskSortNo?: Maybe<ProjectTemplateSubTask>;
  updateProjectTemplateTask?: Maybe<ProjectTemplateTask>;
  updateProjectTemplateTaskCustomAttribute?: Maybe<ProjectTemplateTaskCustomAttribute>;
  updateProjectTemplateTaskRemainder?: Maybe<ProjectTemplateTaskRemainder>;
  updateProjectTemplateTaskSortNoInList?: Maybe<ProjectTemplateTask>;
  updateProjectTemplateTaskSortNoInTaskStatus?: Maybe<ProjectTemplateTask>;
  updateProjectTemplateTaskStatus?: Maybe<ProjectTemplateTaskStatus>;
  updateSettlementRangeContract?: Maybe<SettlementRangeContract>;
  updateStatusOfProject?: Maybe<Project>;
  updateStatusOfTask?: Maybe<Task>;
  updateSubTask?: Maybe<SubTask>;
  updateSubTaskSortNo?: Maybe<SubTask>;
  updateTag?: Maybe<Tag>;
  updateTagSortNo?: Maybe<Tag>;
  updateTask?: Maybe<Task>;
  updateTaskComment?: Maybe<TaskComment>;
  updateTaskCustomAttribute?: Maybe<TaskCustomAttribute>;
  updateTaskCustomAttributeMaster?: Maybe<TaskCustomAttributeMaster>;
  updateTaskCustomAttributeMasterListItem?: Maybe<TaskCustomAttributeMasterListItem>;
  updateTaskRemainder?: Maybe<TaskRemainder>;
  updateTaskSortNoInFavoriteTask?: Maybe<Task>;
  updateTaskSortNoInGanttChart?: Maybe<Task>;
  updateTaskSortNoInList?: Maybe<Task>;
  updateTaskSortNoInMyTask?: Maybe<Task>;
  updateTaskStatus?: Maybe<TaskStatus>;
  updateTeam?: Maybe<Team>;
  updateTeamMemberRelation?: Maybe<TeamMemberRelation>;
  updateToActive?: Maybe<Member>;
  updateToInactive?: Maybe<Member>;
  updateWorkingHistory?: Maybe<WorkingHistory>;
  updateWorkingSchedule?: Maybe<WorkingSchedule>;
  upgradeToProPlanIOS?: Maybe<Contract>;
  verifyTwoFactorAuth?: Maybe<TwoFactorAuthSetting>;
  withdraw?: Maybe<Scalars['Boolean']>;
};

/** Mutation root */
export type MutationAddCsvReportItemArgs = {
  id: Scalars['ID'];
  input: AddCsvDefinitionDetailInputInput;
};

/** Mutation root */
export type MutationAddProjectCustomAttributeArgs = {
  input: RegisterProjectCustomAttributeInputInput;
  projectId: Scalars['String'];
};

/** Mutation root */
export type MutationAddProjectCustomAttributeMasterListItemArgs = {
  input: RegisterProjectCustomAttributeMasterListItemInputInput;
  projectCustomAttributeMasterId: Scalars['String'];
};

/** Mutation root */
export type MutationAddProjectTemplateCustomAttributeArgs = {
  input: RegisterProjectTemplateCustomAttributeInputInput;
  projectTemplateId: Scalars['String'];
};

/** Mutation root */
export type MutationAddProjectTemplateTaskCustomAttributeArgs = {
  input: RegisterProjectTemplateTaskCustomAttributeInputInput;
  projectTemplateTaskId: Scalars['String'];
};

/** Mutation root */
export type MutationAddTaskCommentArgs = {
  input?: Maybe<AddTaskCommentInputInput>;
  taskId: Scalars['String'];
};

/** Mutation root */
export type MutationAddTaskCustomAttributeArgs = {
  input: RegisterTaskCustomAttributeInputInput;
  taskId: Scalars['String'];
};

/** Mutation root */
export type MutationAddTaskCustomAttributeMasterListItemArgs = {
  input: RegisterTaskCustomAttributeMasterListItemInputInput;
  taskCustomAttributeMasterId: Scalars['String'];
};

/** Mutation root */
export type MutationArchiveCompletedTasksArgs = {
  projectId: Scalars['String'];
};

/** Mutation root */
export type MutationArchiveTeamArgs = {
  id: Scalars['ID'];
  input: ArchiveTeamInputInput;
};

/** Mutation root */
export type MutationCancelFavoriteProjectArgs = {
  id: Scalars['ID'];
  input?: Maybe<CancelFavoriteProjectInputInput>;
};

/** Mutation root */
export type MutationCancelFavoriteTaskArgs = {
  id: Scalars['ID'];
  input?: Maybe<CancelFavoriteTaskInputInput>;
};

/** Mutation root */
export type MutationChangeContractPeriodArgs = {
  id: Scalars['ID'];
  input?: Maybe<ChangeContractPeriodInputInput>;
};

/** Mutation root */
export type MutationChangePlanArgs = {
  id: Scalars['ID'];
  input?: Maybe<ChangePlanInputInput>;
};

/** Mutation root */
export type MutationCloseTermArgs = {
  input?: Maybe<CloseInputInput>;
};

/** Mutation root */
export type MutationCompleteProjectArgs = {
  id: Scalars['ID'];
  input?: Maybe<CompleteProjectInputInput>;
};

/** Mutation root */
export type MutationCompleteSubTaskArgs = {
  id: Scalars['ID'];
  input: CompleteSubTaskInputInput;
};

/** Mutation root */
export type MutationCompleteTaskArgs = {
  id: Scalars['ID'];
  input: CompleteTaskInputInput;
};

/** Mutation root */
export type MutationConnectAppleAccountArgs = {
  input?: Maybe<ConnectAppleAccountInputInput>;
};

/** Mutation root */
export type MutationConnectGoogleAccountArgs = {
  code: Scalars['String'];
};

/** Mutation root */
export type MutationCopyProjectArgs = {
  id: Scalars['ID'];
  input?: Maybe<CopyProjectInputInput>;
};

/** Mutation root */
export type MutationCopyTaskArgs = {
  id: Scalars['ID'];
  input: CopyTaskInputInput;
};

/** Mutation root */
export type MutationCreateAttachementFileDownloadUrlArgs = {
  input: CreateAttachementFileDownloadUrlInputInput;
};

/** Mutation root */
export type MutationCreateAttachementFileForProjectArgs = {
  input: CreateProjectAttachmentFileInputInput;
};

/** Mutation root */
export type MutationCreateAttachementFileForTaskArgs = {
  input: CreateTaskAttachmentFileInputInput;
};

/** Mutation root */
export type MutationCreateAttachementFileUploadUrlArgs = {
  input: CreateAttachementFileUploadUrlInputInput;
};

/** Mutation root */
export type MutationCreateCalendarEventWithTaskAndWorkingHistoryArgs = {
  input: RegisterCalendarEventWithTaskInputInput;
  projectId: Scalars['String'];
};

/** Mutation root */
export type MutationCreateCalendarEventWithTaskAndWorkingScheduleArgs = {
  input: RegisterCalendarEventWithTaskInputInput;
  projectId: Scalars['String'];
};

/** Mutation root */
export type MutationCreateCalendarEventWithWorkingHistoryArgs = {
  input: RegisterCalendarEventInputInput;
  projectId: Scalars['String'];
  taskId?: Maybe<Scalars['String']>;
};

/** Mutation root */
export type MutationCreateCalendarEventWithWorkingScheduleArgs = {
  input: RegisterCalendarEventInputInput;
  projectId: Scalars['String'];
  taskId?: Maybe<Scalars['String']>;
};

/** Mutation root */
export type MutationCreateExportOrganizationClientExcelDownloadUrlArgs = {
  id: Scalars['ID'];
};

/** Mutation root */
export type MutationCreateExportOrganizationMemberExcelDownloadUrlArgs = {
  id: Scalars['ID'];
};

/** Mutation root */
export type MutationCreateExportProjectExcelDownloadUrlArgs = {
  id: Scalars['ID'];
};

/** Mutation root */
export type MutationCreateExportTaskExcelDownloadUrlArgs = {
  id: Scalars['ID'];
};

/** Mutation root */
export type MutationCreateICalFileDownloadUrlArgs = {
  input: CreateICalFileDownloadUrlInputInput;
};

/** Mutation root */
export type MutationCreateInvitationLinkArgs = {
  input: CreateInvitationLinkInputInput;
};

/** Mutation root */
export type MutationCreateLaborCostArgs = {
  input: RegisterLaborCostInputInput;
};

/** Mutation root */
export type MutationCreateMeteredRateContractArgs = {
  input: RegisterMeterdRateContractInputInput;
};

/** Mutation root */
export type MutationCreateMyCsvReportDownloadUrlArgs = {
  id: Scalars['ID'];
  isUtf8: Scalars['Boolean'];
};

/** Mutation root */
export type MutationCreateMyReportDownloadUrlArgs = {
  id: Scalars['ID'];
};

/** Mutation root */
export type MutationCreateOrganizationCsvReportDownloadUrlArgs = {
  id: Scalars['ID'];
  isUtf8: Scalars['Boolean'];
};

/** Mutation root */
export type MutationCreateOrganizationReportDownloadUrlArgs = {
  id: Scalars['ID'];
};

/** Mutation root */
export type MutationCreateProjectArgs = {
  input?: Maybe<RegisterProjectInputInput>;
  teamId: Scalars['String'];
};

/** Mutation root */
export type MutationCreateProjectContractArgs = {
  input: RegisterProjectContractInputInput;
};

/** Mutation root */
export type MutationCreateProjectCustomAttributeMasterArgs = {
  input: RegisterProjectCustomAttributeMasterInputInput;
};

/** Mutation root */
export type MutationCreateProjectStatusArgs = {
  input: RegisterProjectStatusInputInput;
  teamId: Scalars['String'];
};

/** Mutation root */
export type MutationCreateProjectTemplateArgs = {
  input?: Maybe<RegisterProjectTemplateInputInput>;
};

/** Mutation root */
export type MutationCreateProjectTemplateSubTaskArgs = {
  input: RegisterProjectTemplateSubTaskInputInput;
  projectTemplateTaskId: Scalars['String'];
};

/** Mutation root */
export type MutationCreateProjectTemplateTaskArgs = {
  input: RegisterProjectTemplateTaskInputInput;
  projectTemplateId: Scalars['String'];
};

/** Mutation root */
export type MutationCreateProjectTemplateTaskRemainderArgs = {
  input: RegisterProjectTemplateTaskRemainderInputInput;
  projectTemplateTaskId: Scalars['String'];
};

/** Mutation root */
export type MutationCreateProjectTemplateTaskStatusArgs = {
  input: RegisterProjectTemplateTaskStatusInputInput;
  projectTemplateId: Scalars['String'];
};

/** Mutation root */
export type MutationCreateProjectTemplateTasksArgs = {
  inputs: RegisterProjectTemplateTasksInputInput;
  projectTemplateId: Scalars['String'];
};

/** Mutation root */
export type MutationCreateSettlementRangeContractArgs = {
  input: RegisterSettlementRangeContractInputInput;
};

/** Mutation root */
export type MutationCreateSubTaskArgs = {
  input: RegisterSubTaskInputInput;
  taskId: Scalars['String'];
};

/** Mutation root */
export type MutationCreateTagArgs = {
  input: RegisterTagInputInput;
};

/** Mutation root */
export type MutationCreateTaskArgs = {
  input: RegisterTaskInputInput;
  projectId: Scalars['String'];
};

/** Mutation root */
export type MutationCreateTaskCustomAttributeMasterArgs = {
  input: RegisterTaskCustomAttributeMasterInputInput;
};

/** Mutation root */
export type MutationCreateTaskRemainderArgs = {
  input: RegisterTaskRemainderInputInput;
  taskId: Scalars['String'];
};

/** Mutation root */
export type MutationCreateTaskStatusArgs = {
  input: RegisterTaskStatusInputInput;
  projectId: Scalars['String'];
};

/** Mutation root */
export type MutationCreateTasksArgs = {
  input: RegisterTasksInputInput;
  projectId: Scalars['String'];
};

/** Mutation root */
export type MutationCreateTeamArgs = {
  input?: Maybe<RegisterTeamInputInput>;
  organizationId: Scalars['String'];
};

/** Mutation root */
export type MutationCreateTeamCsvReportDownloadUrlArgs = {
  id: Scalars['ID'];
  isUtf8: Scalars['Boolean'];
};

/** Mutation root */
export type MutationCreateTeamReportDownloadUrlArgs = {
  id: Scalars['ID'];
};

/** Mutation root */
export type MutationCreateWorkingHistoryArgs = {
  input: RegisterWorkingHistoryInputInput;
  taskId: Scalars['String'];
};

/** Mutation root */
export type MutationCreateWorkingHistoryByCalendarEventArgs = {
  calendarEventId: Scalars['String'];
  input: RegisterWorkingHistoryInputInput;
};

/** Mutation root */
export type MutationCreateWorkingHistoryWithTaskArgs = {
  input: RegisterWorkingHistoryWithTaskInputInput;
  projectId: Scalars['String'];
};

/** Mutation root */
export type MutationCreateWorkingScheduleArgs = {
  input: RegisterWorkingScheduleInputInput;
  taskId: Scalars['String'];
};

/** Mutation root */
export type MutationCreateWorkingScheduleWithTaskArgs = {
  input: RegisterWorkingScheduleWithTaskInputInput;
  projectId: Scalars['String'];
};

/** Mutation root */
export type MutationDeactivateMemberTwoFactorAuthArgs = {
  memberId: Scalars['String'];
};

/** Mutation root */
export type MutationDeleteAttachementFileArgs = {
  id: Scalars['ID'];
};

/** Mutation root */
export type MutationDeleteCalendarEventArgs = {
  id: Scalars['String'];
  input: DeleteCalendarEventInputInput;
};

/** Mutation root */
export type MutationDeleteClientArgs = {
  id: Scalars['ID'];
  input: DeleteClientInputInput;
};

/** Mutation root */
export type MutationDeleteCsvReportItemArgs = {
  id: Scalars['ID'];
  input: DeleteCsvDefinitionDetailInputInput;
};

/** Mutation root */
export type MutationDeleteCsvReportItemDefinitionArgs = {
  id: Scalars['ID'];
  input: DeleteCsvReportItemDefinitionInputInput;
};

/** Mutation root */
export type MutationDeleteGoogleCalendarEventArgs = {
  calendarId: Scalars['String'];
  id: Scalars['ID'];
};

/** Mutation root */
export type MutationDeleteInvitationArgs = {
  key: Scalars['String'];
};

/** Mutation root */
export type MutationDeleteInvitationLinkArgs = {
  id: Scalars['ID'];
};

/** Mutation root */
export type MutationDeleteLaborCostArgs = {
  id: Scalars['ID'];
  input: DeleteLaborCostInputInput;
};

/** Mutation root */
export type MutationDeleteMeteredRateContractArgs = {
  id: Scalars['ID'];
  input: DeleteMeterdRateContractInputInput;
};

/** Mutation root */
export type MutationDeleteProjectArgs = {
  id: Scalars['ID'];
  input?: Maybe<DeleteProjectInputInput>;
};

/** Mutation root */
export type MutationDeleteProjectContractArgs = {
  id: Scalars['ID'];
  input: DeleteProjectContractInputInput;
};

/** Mutation root */
export type MutationDeleteProjectCustomAttributeArgs = {
  id: Scalars['ID'];
};

/** Mutation root */
export type MutationDeleteProjectCustomAttributeMasterArgs = {
  id: Scalars['ID'];
  input: DeleteProjectCustomAttributeMasterInputInput;
};

/** Mutation root */
export type MutationDeleteProjectCustomAttributeMasterListItemArgs = {
  id: Scalars['ID'];
  input: DeleteProjectCustomAttributeMasterListItemInputInput;
};

/** Mutation root */
export type MutationDeleteProjectStatusArgs = {
  id: Scalars['ID'];
  input: DeleteProjectStatusInputInput;
};

/** Mutation root */
export type MutationDeleteProjectTemplateArgs = {
  id: Scalars['ID'];
  input?: Maybe<DeleteProjectTemplateInputInput>;
};

/** Mutation root */
export type MutationDeleteProjectTemplateCustomAttributeArgs = {
  id: Scalars['ID'];
};

/** Mutation root */
export type MutationDeleteProjectTemplateSubTaskArgs = {
  id: Scalars['ID'];
  input: DeleteProjectTemplateSubTaskInputInput;
};

/** Mutation root */
export type MutationDeleteProjectTemplateTaskArgs = {
  id: Scalars['ID'];
  input: DeleteProjectTemplateTaskInputInput;
};

/** Mutation root */
export type MutationDeleteProjectTemplateTaskCustomAttributeArgs = {
  id: Scalars['ID'];
};

/** Mutation root */
export type MutationDeleteProjectTemplateTaskRemainderArgs = {
  id: Scalars['ID'];
};

/** Mutation root */
export type MutationDeleteProjectTemplateTaskStatusArgs = {
  id: Scalars['ID'];
  input: DeleteProjectTemplateTaskStatusInputInput;
};

/** Mutation root */
export type MutationDeleteSettlementRangeContractArgs = {
  id: Scalars['ID'];
  input: DeleteSettlementRangeContractInputInput;
};

/** Mutation root */
export type MutationDeleteSubTaskArgs = {
  id: Scalars['ID'];
  input: DeleteSubTaskInputInput;
};

/** Mutation root */
export type MutationDeleteTagArgs = {
  id: Scalars['ID'];
  input: DeleteTagInputInput;
};

/** Mutation root */
export type MutationDeleteTaskArgs = {
  id: Scalars['ID'];
  input: DeleteTaskInputInput;
};

/** Mutation root */
export type MutationDeleteTaskCommentArgs = {
  id: Scalars['ID'];
  input: DeleteTaskCommentInputInput;
};

/** Mutation root */
export type MutationDeleteTaskCustomAttributeArgs = {
  id: Scalars['ID'];
};

/** Mutation root */
export type MutationDeleteTaskCustomAttributeMasterArgs = {
  id: Scalars['ID'];
  input: DeleteTaskCustomAttributeMasterInputInput;
};

/** Mutation root */
export type MutationDeleteTaskCustomAttributeMasterListItemArgs = {
  id: Scalars['ID'];
  input: DeleteTaskCustomAttributeMasterListItemInputInput;
};

/** Mutation root */
export type MutationDeleteTaskRemainderArgs = {
  id: Scalars['ID'];
};

/** Mutation root */
export type MutationDeleteTaskStatusArgs = {
  id: Scalars['ID'];
  input: DeleteTaskStatusInputInput;
};

/** Mutation root */
export type MutationDeleteWorkingHistoryArgs = {
  id: Scalars['String'];
  input: DeleteWorkingHistoryInputInput;
};

/** Mutation root */
export type MutationDeleteWorkingScheduleArgs = {
  id: Scalars['String'];
  input: DeleteWorkingHistoryInputInput;
};

/** Mutation root */
export type MutationDisableAccessControlArgs = {
  input: UpdateAccessControlInputInput;
};

/** Mutation root */
export type MutationDisableAccessControlByTokenArgs = {
  token: Scalars['String'];
};

/** Mutation root */
export type MutationDisableThirdPartyCalendarConnectArgs = {
  input: UpdateThirdPartyCalendarConnectInputInput;
};

/** Mutation root */
export type MutationDisableTwoFactorAuthRequiedArgs = {
  id: Scalars['ID'];
  input: DisableTwoFactorAuthRequiredInputInput;
};

/** Mutation root */
export type MutationEnableAccessControlArgs = {
  input: UpdateAccessControlInputInput;
};

/** Mutation root */
export type MutationEnableThirdPartyCalendarConnectArgs = {
  input: UpdateThirdPartyCalendarConnectInputInput;
};

/** Mutation root */
export type MutationEnableTwoFactorAuthRequiedArgs = {
  id: Scalars['ID'];
  input: EnableTwoFactorAuthRequiredInputInput;
};

/** Mutation root */
export type MutationExportICalFileArgs = {
  input?: Maybe<ICalExportInputInput>;
};

/** Mutation root */
export type MutationFavoriteProjectArgs = {
  id: Scalars['ID'];
  input?: Maybe<FavoriteProjectInputInput>;
};

/** Mutation root */
export type MutationFavoriteTaskArgs = {
  id: Scalars['ID'];
  input?: Maybe<FavoriteTaskInputInput>;
};

/** Mutation root */
export type MutationIncompleteProjectArgs = {
  id: Scalars['ID'];
  input?: Maybe<IncompleteProjectInputInput>;
};

/** Mutation root */
export type MutationIncompleteSubTaskArgs = {
  id: Scalars['ID'];
  input: IncompleteSubTaskInputInput;
};

/** Mutation root */
export type MutationIncompleteTaskArgs = {
  id: Scalars['ID'];
  input: IncompleteTaskInputInput;
};

/** Mutation root */
export type MutationInviteMemberArgs = {
  input: InviteMemberWithMailAddressInputInput;
};

/** Mutation root */
export type MutationIssueAppUserIdArgs = {
  id: Scalars['ID'];
  input?: Maybe<IssueAppUserIdInputInput>;
};

/** Mutation root */
export type MutationJoinMemberToTeamArgs = {
  id: Scalars['ID'];
  input: JoinMemberToTeamInputInput;
};

/** Mutation root */
export type MutationJoinToOrganizationWithGoogleAccountArgs = {
  input: JoinToOrganizationWithGoogleAccountInputInput;
};

/** Mutation root */
export type MutationJoinToOrganizationWithGoogleAccountByInviteLinkArgs = {
  input: JoinToOrganizationWithGoogleAccountByInviteLinkInputInput;
};

/** Mutation root */
export type MutationJoinToOrganizationWithMailAddressArgs = {
  input: JoinOrganizationMailAddressInputInput;
};

/** Mutation root */
export type MutationJoinToOrganizationWithMailAddressByInviteLinkArgs = {
  input: JoinOrganizationMailAddressByInviteLinkInputInput;
};

/** Mutation root */
export type MutationJoinToTeamArgs = {
  input: JoinMemberToTeamInputInput;
};

/** Mutation root */
export type MutationLoginArgs = {
  input?: Maybe<LoginInputInput>;
};

/** Mutation root */
export type MutationLoginV2Args = {
  input: LoginInputInput;
};

/** Mutation root */
export type MutationLoginWithAppleArgs = {
  input?: Maybe<LoginWithAppleAccountInputInput>;
};

/** Mutation root */
export type MutationLoginWithAppleForWebArgs = {
  input?: Maybe<LoginWithAppleAccountInputInput>;
};

/** Mutation root */
export type MutationLoginWithAppleForWebV2Args = {
  input: LoginWithAppleAccountInputInput;
};

/** Mutation root */
export type MutationLoginWithAppleV2Args = {
  input: LoginWithAppleAccountInputInput;
};

/** Mutation root */
export type MutationLoginWithGoogleArgs = {
  code?: Maybe<Scalars['String']>;
};

/** Mutation root */
export type MutationLoginWithGoogleV2Args = {
  code: Scalars['String'];
};

/** Mutation root */
export type MutationMoveCalendarEventArgs = {
  id: Scalars['String'];
  input: MoveCalendarEventInputInput;
};

/** Mutation root */
export type MutationMoveProjectArgs = {
  id: Scalars['ID'];
  input?: Maybe<MoveProjectInputInput>;
};

/** Mutation root */
export type MutationMoveTaskArgs = {
  id: Scalars['ID'];
  input: MoveTaskInputInput;
};

/** Mutation root */
export type MutationMoveWorkingHistoryArgs = {
  id: Scalars['String'];
  input: MoveWorkingHistoryInputInput;
};

/** Mutation root */
export type MutationMoveWorkingScheduleArgs = {
  id: Scalars['String'];
  input: MoveWorkingScheduleInputInput;
};

/** Mutation root */
export type MutationParseAppleAuthCodeArgs = {
  input: ParseAppleAccountInputInput;
};

/** Mutation root */
export type MutationParseGoogleAuthCodeArgs = {
  code: Scalars['String'];
};

/** Mutation root */
export type MutationParseGoogleAuthCodeWithInviteKeyArgs = {
  code: Scalars['String'];
  key: Scalars['String'];
};

/** Mutation root */
export type MutationParseGoogleAuthCodeWithInviteLinkArgs = {
  code: Scalars['String'];
};

/** Mutation root */
export type MutationProxyLoginArgs = {
  input?: Maybe<ProxyLoginInputInput>;
};

/** Mutation root */
export type MutationReadAnnouncementArgs = {
  id: Scalars['ID'];
};

/** Mutation root */
export type MutationRegisterClientArgs = {
  input?: Maybe<RegisterClientInputInput>;
  organizationId: Scalars['String'];
};

/** Mutation root */
export type MutationRegisterCreditCardArgs = {
  contractId: Scalars['ID'];
  input?: Maybe<RegisterCreditCardInputInput>;
};

/** Mutation root */
export type MutationRegisterCsvReportItemDefinitionArgs = {
  input: RegisterCsvReportItemDefinitionInputInput;
};

/** Mutation root */
export type MutationRegisterGoogleCalendarEventArgs = {
  input: RegisterGoogleCalendarEventInputInput;
};

/** Mutation root */
export type MutationRegisterPushNotificationDeviceArgs = {
  input: RegisterPushNotificationDeviceInputInput;
};

/** Mutation root */
export type MutationRelateProjectTemplateTaskTagsArgs = {
  input?: Maybe<RelateTaskTagsInputInput>;
  projectTemplateTaskId: Scalars['String'];
};

/** Mutation root */
export type MutationRelateTaskTagsArgs = {
  input?: Maybe<RelateTaskTagsInputInput>;
  taskId: Scalars['String'];
};

/** Mutation root */
export type MutationRemoveFromTeamArgs = {
  input: RemoveMemberToTeamInputInput;
};

/** Mutation root */
export type MutationRemoveMemberFromTeamArgs = {
  id: Scalars['ID'];
  input: RemoveMemberToTeamInputInput;
};

/** Mutation root */
export type MutationRequestExportOrganizationClientExcelArgs = {
  input: RequestExportClientExcelInputInput;
};

/** Mutation root */
export type MutationRequestExportOrganizationMemberExcelArgs = {
  input: RequestExportMemberExcelInputInput;
};

/** Mutation root */
export type MutationRequestExportOrganizationProjectExcelArgs = {
  input: RequestExportProjectExcelInputInput;
};

/** Mutation root */
export type MutationRequestExportOrganizationTaskExcelArgs = {
  input: RequestExportTaskExcelInputInput;
};

/** Mutation root */
export type MutationRequestExportTeamProjectExcelArgs = {
  input: RequestExportProjectExcelInputInput;
};

/** Mutation root */
export type MutationRequestExportTeamTaskExcelArgs = {
  input: RequestExportTaskExcelInputInput;
};

/** Mutation root */
export type MutationRequestMemberRegisterArgs = {
  input: MemberRegisterRequestInputInput;
};

/** Mutation root */
export type MutationRequestMemberRegisterWithInvitationLinkArgs = {
  input: MemberRegisterRequestWithInvitationLInkInputInput;
};

/** Mutation root */
export type MutationRequestMyCsvReportArgs = {
  input: RequestMyCsvReportInputInput;
};

/** Mutation root */
export type MutationRequestMyReportArgs = {
  input: RequestMyReportInputInput;
};

/** Mutation root */
export type MutationRequestOrganizationCsvReportArgs = {
  input: RequestOrganizationCsvReportInputInput;
};

/** Mutation root */
export type MutationRequestOrganizationReportArgs = {
  input: RequestOrganizationReportInputInput;
};

/** Mutation root */
export type MutationRequestPasswordResetArgs = {
  input: RequestPasswordResetInputInput;
};

/** Mutation root */
export type MutationRequestTeamCsvReportArgs = {
  input: RequestTeamCsvReportInputInput;
};

/** Mutation root */
export type MutationRequestTeamReportArgs = {
  input: RequestTeamReportInputInput;
};

/** Mutation root */
export type MutationResendInvitationArgs = {
  key: Scalars['String'];
};

/** Mutation root */
export type MutationResetPasswordArgs = {
  input?: Maybe<ResetPasswordInputInput>;
};

/** Mutation root */
export type MutationSignupWithAppleAccountArgs = {
  input: SignupWithAppleAccountInputInput;
};

/** Mutation root */
export type MutationSignupWithGoogleAccountArgs = {
  input: SignupWithGoogleAccountInputInput;
};

/** Mutation root */
export type MutationSignupWithMailAddressArgs = {
  input: SignupWithMailAddressInputInput;
};

/** Mutation root */
export type MutationStartTaskArgs = {
  id: Scalars['ID'];
  input: StartTaskInputInput;
};

/** Mutation root */
export type MutationStopTaskArgs = {
  id: Scalars['ID'];
  input: StopTaskInputInput;
};

/** Mutation root */
export type MutationSyncRegisterWorkingHistoriesFromGoogleCalendarsArgs = {
  input: RegisterWorkingHistoriesFromGoogleCallendarEventsInputInput;
};

/** Mutation root */
export type MutationSyncRegisterWorkingSchedulesFromGoogleCalendarsArgs = {
  input: RegisterWorkingSchedulesFromGoogleCallendarEventsInputInput;
};

/** Mutation root */
export type MutationTeamCloseTermArgs = {
  input?: Maybe<TeamCloseInputInput>;
};

/** Mutation root */
export type MutationTwoFactorLoginArgs = {
  input: TwoFactorLoginInputInput;
};

/** Mutation root */
export type MutationTwoFactorRecoveryCodeLoginArgs = {
  input: TwoFactorRecoveryCodeLoginInputInput;
};

/** Mutation root */
export type MutationUnarchiveTeamArgs = {
  id: Scalars['ID'];
  input: UnarchiveTeamInputInput;
};

/** Mutation root */
export type MutationUpdateAccessAllowIpAddressesArgs = {
  input?: Maybe<UpdateAccessAllowIpAddressInputInput>;
};

/** Mutation root */
export type MutationUpdateBillToMailAddressArgs = {
  id: Scalars['ID'];
  input?: Maybe<UpdateBillToMailAddressInput>;
};

/** Mutation root */
export type MutationUpdateCalendarEventArgs = {
  id: Scalars['String'];
  input: UpdateCalendarEventInputInput;
};

/** Mutation root */
export type MutationUpdateClientArgs = {
  id: Scalars['ID'];
  input: UpdateClientInputInput;
};

/** Mutation root */
export type MutationUpdateClientSortNoArgs = {
  id: Scalars['ID'];
  input?: Maybe<UpdateClientSortNoInputInput>;
};

/** Mutation root */
export type MutationUpdateCsvReportItemDefinitionArgs = {
  id: Scalars['ID'];
  input: UpdateCsvReportItemDefinitionInputInput;
};

/** Mutation root */
export type MutationUpdateCsvReportItemSortNoArgs = {
  id: Scalars['ID'];
  input: UpdateCsvDefinitionDetailSortNoInputInput;
};

/** Mutation root */
export type MutationUpdateGoogleCalendarEventArgs = {
  id: Scalars['ID'];
  input: UpdateGoogleCalendarEventInputInput;
};

/** Mutation root */
export type MutationUpdateInvitationArgs = {
  id: Scalars['ID'];
  input: UpdateMemberInvitationInputInput;
};

/** Mutation root */
export type MutationUpdateInvitationLinkArgs = {
  id: Scalars['ID'];
  input: UpdateMemberInvitationLinkInputInput;
};

/** Mutation root */
export type MutationUpdateLaborCostArgs = {
  id: Scalars['ID'];
  input: UpdateLaborCostInputInput;
};

/** Mutation root */
export type MutationUpdateLicenceCountArgs = {
  id: Scalars['ID'];
  input?: Maybe<UpdateLicenceCountInputInput>;
};

/** Mutation root */
export type MutationUpdateMemberProfileArgs = {
  input: UpdateMemberProfileInputInput;
};

/** Mutation root */
export type MutationUpdateMemberRoleArgs = {
  id: Scalars['ID'];
  input: UpdateMemberRoleInputInput;
};

/** Mutation root */
export type MutationUpdateMemberStatusMessageArgs = {
  input: UpdateMemberStatusMessageInputInput;
};

/** Mutation root */
export type MutationUpdateMeteredRateContractArgs = {
  id: Scalars['ID'];
  input: UpdateMeterdRateContractInputInput;
};

/** Mutation root */
export type MutationUpdateMySettingsArgs = {
  input: UpdateMemberSettingsInputInput;
};

/** Mutation root */
export type MutationUpdateNotificationToReadArgs = {
  id?: Maybe<Scalars['String']>;
};

/** Mutation root */
export type MutationUpdateNotificationToReadV2Args = {
  id?: Maybe<Scalars['String']>;
};

/** Mutation root */
export type MutationUpdateNotificationToReceivedArgs = {
  id?: Maybe<Scalars['String']>;
};

/** Mutation root */
export type MutationUpdateNotificationToReceivedV2Args = {
  id?: Maybe<Scalars['String']>;
};

/** Mutation root */
export type MutationUpdateOrganizationArgs = {
  id: Scalars['ID'];
  input: UpdateOrganizationInputInput;
};

/** Mutation root */
export type MutationUpdateOrganizationImageArgs = {
  id: Scalars['ID'];
  input: UpdateOrganizationImageInputInput;
};

/** Mutation root */
export type MutationUpdatePasswordArgs = {
  input?: Maybe<UpdatePasswordInputInput>;
};

/** Mutation root */
export type MutationUpdateProfileImageArgs = {
  input: UpdateProfileImageInputInput;
};

/** Mutation root */
export type MutationUpdateProjectArgs = {
  id: Scalars['ID'];
  input?: Maybe<UpdateProjectInputInput>;
};

/** Mutation root */
export type MutationUpdateProjectContractArgs = {
  id: Scalars['ID'];
  input: UpdateProjectContractInputInput;
};

/** Mutation root */
export type MutationUpdateProjectCustomAttributeArgs = {
  id: Scalars['ID'];
  input: UpdateProjectCustomAttributeInputInput;
};

/** Mutation root */
export type MutationUpdateProjectCustomAttributeMasterArgs = {
  id: Scalars['ID'];
  input: UpdateProjectCustomAttributeMasterInputInput;
};

/** Mutation root */
export type MutationUpdateProjectCustomAttributeMasterListItemArgs = {
  id: Scalars['ID'];
  input: UpdateProjectCustomAttributeMasterListItemInputInput;
};

/** Mutation root */
export type MutationUpdateProjectSortNoInAssignedProjectArgs = {
  id: Scalars['ID'];
  input?: Maybe<UpdateProjectSortNoInAssignedProjectInputInput>;
};

/** Mutation root */
export type MutationUpdateProjectSortNoInFavoriteProjectArgs = {
  id: Scalars['ID'];
  input?: Maybe<UpdateProjectSortNoInFavoriteProjectInputInput>;
};

/** Mutation root */
export type MutationUpdateProjectSortNoInListArgs = {
  id: Scalars['ID'];
  input?: Maybe<UpdateProjectSortNoInListInputInput>;
};

/** Mutation root */
export type MutationUpdateProjectStatusArgs = {
  id: Scalars['ID'];
  input: UpdateProjectStatusInputInput;
};

/** Mutation root */
export type MutationUpdateProjectTemplateArgs = {
  id: Scalars['ID'];
  input?: Maybe<UpdateProjectTemplateInputInput>;
};

/** Mutation root */
export type MutationUpdateProjectTemplateCustomAttributeArgs = {
  id: Scalars['ID'];
  input: UpdateProjectTemplateCustomAttributeInputInput;
};

/** Mutation root */
export type MutationUpdateProjectTemplateSortNoInListArgs = {
  id: Scalars['ID'];
  input?: Maybe<UpdateProjectTemplateSortNoInListInputInput>;
};

/** Mutation root */
export type MutationUpdateProjectTemplateSubTaskArgs = {
  id: Scalars['ID'];
  input: UpdateProjectTemplateSubTaskInputInput;
};

/** Mutation root */
export type MutationUpdateProjectTemplateSubTaskSortNoArgs = {
  id: Scalars['ID'];
  input: UpdateProjectTemplateSubTaskSortNoInputInput;
};

/** Mutation root */
export type MutationUpdateProjectTemplateTaskArgs = {
  id: Scalars['ID'];
  input: UpdateProjectTemplateTaskInputInput;
};

/** Mutation root */
export type MutationUpdateProjectTemplateTaskCustomAttributeArgs = {
  id: Scalars['ID'];
  input: UpdateProjectTemplateTaskCustomAttributeInputInput;
};

/** Mutation root */
export type MutationUpdateProjectTemplateTaskRemainderArgs = {
  id: Scalars['String'];
  input: UpdateProjectTemplateTaskRemainderInputInput;
};

/** Mutation root */
export type MutationUpdateProjectTemplateTaskSortNoInListArgs = {
  id: Scalars['ID'];
  input: UpdateProjectTemplateTaskSortNoInListInputInput;
};

/** Mutation root */
export type MutationUpdateProjectTemplateTaskSortNoInTaskStatusArgs = {
  id: Scalars['ID'];
  input: UpdateProjectTemplateTaskStatusTaskInputInput;
};

/** Mutation root */
export type MutationUpdateProjectTemplateTaskStatusArgs = {
  id: Scalars['ID'];
  input: UpdateProjectTemplateTaskStatusInputInput;
};

/** Mutation root */
export type MutationUpdateSettlementRangeContractArgs = {
  id: Scalars['ID'];
  input: UpdateSettlementRangeContractInputInput;
};

/** Mutation root */
export type MutationUpdateStatusOfProjectArgs = {
  id: Scalars['ID'];
  input: UpdateProjectStatusOfProjectInputInput;
};

/** Mutation root */
export type MutationUpdateStatusOfTaskArgs = {
  id: Scalars['ID'];
  input: UpdateTaskStatusTaskInputInput;
};

/** Mutation root */
export type MutationUpdateSubTaskArgs = {
  id: Scalars['ID'];
  input: UpdateSubTaskInputInput;
};

/** Mutation root */
export type MutationUpdateSubTaskSortNoArgs = {
  id: Scalars['ID'];
  input: UpdateSubTaskSortNoInputInput;
};

/** Mutation root */
export type MutationUpdateTagArgs = {
  id: Scalars['ID'];
  input: UpdateTagInputInput;
};

/** Mutation root */
export type MutationUpdateTagSortNoArgs = {
  id: Scalars['ID'];
  input?: Maybe<UpdateTagSortNoInputInput>;
};

/** Mutation root */
export type MutationUpdateTaskArgs = {
  id: Scalars['ID'];
  input: UpdateTaskInputInput;
};

/** Mutation root */
export type MutationUpdateTaskCommentArgs = {
  id: Scalars['ID'];
  input: UpdateTaskCommentInputInput;
};

/** Mutation root */
export type MutationUpdateTaskCustomAttributeArgs = {
  id: Scalars['ID'];
  input: UpdateTaskCustomAttributeInputInput;
};

/** Mutation root */
export type MutationUpdateTaskCustomAttributeMasterArgs = {
  id: Scalars['ID'];
  input: UpdateTasktCustomAttributeMasterInputInput;
};

/** Mutation root */
export type MutationUpdateTaskCustomAttributeMasterListItemArgs = {
  id: Scalars['ID'];
  input: UpdateTaskCustomAttributeMasterListItemInputInput;
};

/** Mutation root */
export type MutationUpdateTaskRemainderArgs = {
  id: Scalars['String'];
  input: UpdateTaskRemainderInputInput;
};

/** Mutation root */
export type MutationUpdateTaskSortNoInFavoriteTaskArgs = {
  id: Scalars['ID'];
  input: UpdateTaskSortNoInFavoriteTaskInputInput;
};

/** Mutation root */
export type MutationUpdateTaskSortNoInGanttChartArgs = {
  id: Scalars['ID'];
  input: UpdateTaskSortNoInGanttChartInputInput;
};

/** Mutation root */
export type MutationUpdateTaskSortNoInListArgs = {
  id: Scalars['ID'];
  input: UpdateTaskSortNoInListInputInput;
};

/** Mutation root */
export type MutationUpdateTaskSortNoInMyTaskArgs = {
  id: Scalars['ID'];
  input: UpdateTaskSortNoInMyTaskInputInput;
};

/** Mutation root */
export type MutationUpdateTaskStatusArgs = {
  id: Scalars['ID'];
  input: UpdateTaskStatusInputInput;
};

/** Mutation root */
export type MutationUpdateTeamArgs = {
  id: Scalars['ID'];
  input: UpdateTeamInputInput;
};

/** Mutation root */
export type MutationUpdateTeamMemberRelationArgs = {
  id: Scalars['ID'];
  input?: Maybe<UpdateTeamMemberRelationInputInput>;
};

/** Mutation root */
export type MutationUpdateToActiveArgs = {
  id: Scalars['ID'];
  input: UpdateToActiveInputInput;
};

/** Mutation root */
export type MutationUpdateToInactiveArgs = {
  id: Scalars['ID'];
  input: UpdateToInactiveInputInput;
};

/** Mutation root */
export type MutationUpdateWorkingHistoryArgs = {
  id: Scalars['String'];
  input: UpdateWorkingHistoryInputInput;
};

/** Mutation root */
export type MutationUpdateWorkingScheduleArgs = {
  id: Scalars['String'];
  input: UpdateWorkingScheduleInputInput;
};

/** Mutation root */
export type MutationUpgradeToProPlanIosArgs = {
  id: Scalars['ID'];
  input?: Maybe<UpgradeToProPlanIosInputInput>;
};

/** Mutation root */
export type MutationVerifyTwoFactorAuthArgs = {
  input: VerifyTwoFactorAuthInputInput;
};

/** Mutation root */
export type MutationWithdrawArgs = {
  input: WithdrawalInputInput;
};

export type MyCsvReport = {
  __typename?: 'MyCsvReport';
  csvReportItemDefinition?: Maybe<CsvReportItemDefinition>;
  /** ISO-8601 */
  end: Scalars['DateTime'];
  id: Scalars['ID'];
  includeWorkingData: Scalars['Boolean'];
  reportType: ReportType;
  /** ISO-8601 */
  requestDateTime: Scalars['DateTime'];
  sjisFileUrl?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  start: Scalars['DateTime'];
  status: JobProgressStatus;
  timeZoneOffset: Scalars['String'];
  utf8FileUrl?: Maybe<Scalars['String']>;
  versionNo: Scalars['Int'];
};

export type MyReport = {
  __typename?: 'MyReport';
  /** ISO-8601 */
  end: Scalars['DateTime'];
  id: Scalars['ID'];
  includeWorkingData: Scalars['Boolean'];
  reportType: ReportType;
  /** ISO-8601 */
  requestDateTime: Scalars['DateTime'];
  /** ISO-8601 */
  start: Scalars['DateTime'];
  status: JobProgressStatus;
  timeZoneOffset: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  versionNo: Scalars['Int'];
};

export type NameAndMailAddress = {
  __typename?: 'NameAndMailAddress';
  mailAddress?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type Notification = {
  __typename?: 'Notification';
  id: Scalars['ID'];
  mention?: Maybe<Mention>;
  notificationType: NotificationType;
  projectAssignNotification?: Maybe<ProjectAssignNotification>;
  /** ISO-8601 */
  readDateTime?: Maybe<Scalars['DateTime']>;
  /** ISO-8601 */
  receivedDateTime?: Maybe<Scalars['DateTime']>;
  /** ISO-8601 */
  sendDateTime: Scalars['DateTime'];
  targetMember: Member;
  taskAssignNotification?: Maybe<TaskAssignNotification>;
  taskRemainderNotification?: Maybe<TaskRemainderNotification>;
};

export enum NotificationType {
  Mention = 'Mention',
  ProjectAssigned = 'ProjectAssigned',
  TaskAssigned = 'TaskAssigned',
  TaskDeadlineNear = 'TaskDeadlineNear',
  TaskDeadlinePassed = 'TaskDeadlinePassed',
  TaskWorkingLongTime = 'TaskWorkingLongTime',
}

export type NotificationV2 = {
  __typename?: 'NotificationV2';
  id: Scalars['ID'];
  mention?: Maybe<MentionV2>;
  notificationType: NotificationType;
  projectAssignNotification?: Maybe<ProjectAssignNotificationV2>;
  /** ISO-8601 */
  readDateTime?: Maybe<Scalars['DateTime']>;
  /** ISO-8601 */
  receivedDateTime?: Maybe<Scalars['DateTime']>;
  /** ISO-8601 */
  sendDateTime: Scalars['DateTime'];
  targetMember: NotificationV2MemberPart;
  taskAssignNotification?: Maybe<TaskAssignNotificationV2>;
  taskRemainderNotification?: Maybe<TaskRemainderNotificationV2>;
};

export type NotificationV2MemberPart = {
  __typename?: 'NotificationV2MemberPart';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  profileImageUrl?: Maybe<Scalars['String']>;
};

export type Organization = {
  __typename?: 'Organization';
  allowConnectThirdPartyCalendar: Scalars['Boolean'];
  contactMailAddress: Scalars['String'];
  contractPreriod: ContractPeriod;
  id?: Maybe<Scalars['ID']>;
  imageUrl?: Maybe<Scalars['String']>;
  ipRestrictionFlg: Scalars['String'];
  name: Scalars['String'];
  plan: PlanProduct;
  requiredTwoFactorAuth: Scalars['Boolean'];
  suspensionFlg: Scalars['Boolean'];
  versionNo: Scalars['Int'];
};

export type OrganizationCsvReport = {
  __typename?: 'OrganizationCsvReport';
  csvReportItemDefinition?: Maybe<CsvReportItemDefinition>;
  /** ISO-8601 */
  end: Scalars['DateTime'];
  id: Scalars['ID'];
  includeWorkingData: Scalars['Boolean'];
  reportType: ReportType;
  /** ISO-8601 */
  requestDateTime: Scalars['DateTime'];
  requestMember: Member;
  sjisFileUrl?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  start: Scalars['DateTime'];
  status: JobProgressStatus;
  timeZoneOffset: Scalars['String'];
  utf8FileUrl?: Maybe<Scalars['String']>;
  versionNo: Scalars['Int'];
};

export type OrganizationReport = {
  __typename?: 'OrganizationReport';
  /** ISO-8601 */
  end: Scalars['DateTime'];
  id: Scalars['ID'];
  includeWorkingData: Scalars['Boolean'];
  reportType: ReportType;
  /** ISO-8601 */
  requestDateTime: Scalars['DateTime'];
  requestMember: Member;
  /** ISO-8601 */
  start: Scalars['DateTime'];
  status: JobProgressStatus;
  timeZoneOffset: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  versionNo: Scalars['Int'];
};

export type ParseAppleAccountInputInput = {
  code: Scalars['String'];
  token: Scalars['String'];
};

export type PasswordResetRequest = {
  __typename?: 'PasswordResetRequest';
  password_reset_key: Scalars['String'];
};

export enum Plan {
  Basic = 'Basic',
  Business = 'Business',
  Enterprise = 'Enterprise',
  Professional = 'Professional',
}

export type PlanProduct = {
  __typename?: 'PlanProduct';
  code: Plan;
  contractPreriod: ContractPeriod;
  id?: Maybe<Scalars['ID']>;
};

export type PlatformVersion = {
  __typename?: 'PlatformVersion';
  forceUpdateVersion: Scalars['String'];
  latestVersion: Scalars['String'];
};

export enum Priority {
  High = 'High',
  Low = 'Low',
  Normal = 'Normal',
}

export type Project = {
  __typename?: 'Project';
  assignees: Array<AssignedProjectMemberInfo>;
  calendarLinkageCode: Scalars['String'];
  client?: Maybe<Client>;
  color?: Maybe<Scalars['String']>;
  complete: Scalars['Boolean'];
  /** ISO-8601 */
  completeDateTime?: Maybe<Scalars['DateTime']>;
  costBudgetWithoutTax?: Maybe<Scalars['BigInteger']>;
  createdMember?: Maybe<Member>;
  description?: Maybe<Scalars['String']>;
  estimateTimeSec?: Maybe<Scalars['Int']>;
  favoriteMembers: Array<Maybe<Member>>;
  favoriteMembersInfo: Array<Maybe<FavoriteProjectMemberInfo>>;
  id?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['String']>;
  laborCost?: Maybe<Scalars['BigInteger']>;
  laborCostWithoutTax?: Maybe<Scalars['BigInteger']>;
  laborShare?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  priority?: Maybe<Priority>;
  projectStatus: ProjectStatus;
  /** ISO-8601 */
  registerDateTime: Scalars['DateTime'];
  salesAmountWithoutTax?: Maybe<Scalars['BigInteger']>;
  /** ISO-8601 */
  scheduledEndDateTime?: Maybe<Scalars['DateTime']>;
  /** ISO-8601 */
  scheduledStartDateTime?: Maybe<Scalars['DateTime']>;
  sortNoInGanttChart: Scalars['BigInteger'];
  sortNoInList: Scalars['BigInteger'];
  sortNoInProjectStatus: Scalars['BigInteger'];
  team: Team;
  totalScheduleTimeSec?: Maybe<Scalars['Int']>;
  versionNo: Scalars['Int'];
  workingTimeSec: Scalars['Int'];
};

export type ProjectAssignNotification = {
  __typename?: 'ProjectAssignNotification';
  id: Scalars['ID'];
  project: Project;
  sendMember: Member;
};

export type ProjectAssignNotificationV2 = {
  __typename?: 'ProjectAssignNotificationV2';
  id: Scalars['ID'];
  projectId: Scalars['String'];
  projectName: Scalars['String'];
  sendMember: NotificationV2MemberPart;
  teamId: Scalars['String'];
  teamName: Scalars['String'];
};

export type ProjectBarGraphData = {
  __typename?: 'ProjectBarGraphData';
  color: Scalars['String'];
  percent: Scalars['Float'];
  projectId: Scalars['String'];
  projectName: Scalars['String'];
  reportType: ReportType;
  teamId: Scalars['String'];
  teamName: Scalars['String'];
  workingTimeSec: Scalars['BigInteger'];
};

export enum ProjectCompleteFilter {
  Both = 'Both',
  Complete = 'Complete',
  Incomplete = 'Incomplete',
}

export type ProjectContract = {
  __typename?: 'ProjectContract';
  allMemberMeteredRateContract?: Maybe<MeteredRateContract>;
  allMemberSettlementRangeContract?: Maybe<SettlementRangeContract>;
  contractType: ProjectContractType;
  costBudgetWithoutTax?: Maybe<Scalars['BigInteger']>;
  /** ISO-8601 */
  endDateTime?: Maybe<Scalars['DateTime']>;
  fixSalesAmountWithoutTax?: Maybe<Scalars['BigInteger']>;
  grossProfit: Scalars['BigInteger'];
  grossProfitRatePercent: Scalars['Int'];
  id?: Maybe<Scalars['ID']>;
  laborCostWithoutTax: Scalars['BigInteger'];
  laborShare: Scalars['Int'];
  membersMeteredRateContract?: Maybe<Array<Maybe<MeteredRateContract>>>;
  membersSettlementRangeContract?: Maybe<Array<Maybe<SettlementRangeContract>>>;
  month?: Maybe<Scalars['Int']>;
  project?: Maybe<Project>;
  roundTimeType: ProjectContractRoundTimeType;
  roundedTotalWorkingTimeSec: Scalars['Int'];
  salesAmountWithoutTax: Scalars['BigInteger'];
  salesRoundMinutes?: Maybe<Scalars['Int']>;
  /** ISO-8601 */
  startDateTime?: Maybe<Scalars['DateTime']>;
  taxRate: Scalars['Int'];
  termType: ProjectContractTermType;
  timeZone: TimeZone;
  totalWorkingTimeSec: Scalars['Int'];
  unitPriceType: ProjectContractUnitPriceType;
  versionNo: Scalars['Int'];
  year?: Maybe<Scalars['Int']>;
};

export enum ProjectContractRoundTimeType {
  Ceil = 'Ceil',
  Floor = 'Floor',
  None = 'None',
  Round = 'Round',
}

export enum ProjectContractSortKey {
  ClientName = 'ClientName',
  ContractTerm = 'ContractTerm',
  ContractType = 'ContractType',
  CostBudget = 'CostBudget',
  GrossProfit = 'GrossProfit',
  GrossProfitRate = 'GrossProfitRate',
  LaborCost = 'LaborCost',
  LaborShare = 'LaborShare',
  ProjectKey = 'ProjectKey',
  ProjectName = 'ProjectName',
  SalesAmount = 'SalesAmount',
  TaxRate = 'TaxRate',
  TeamName = 'TeamName',
  WorkingTimeSec = 'WorkingTimeSec',
}

export enum ProjectContractTermType {
  Monthly = 'Monthly',
  Total = 'Total',
}

export enum ProjectContractType {
  FixAmount = 'FixAmount',
  MeteredRate = 'MeteredRate',
  SettlementRange = 'SettlementRange',
}

export enum ProjectContractUnitPriceType {
  Anyone = 'Anyone',
  PerMember = 'PerMember',
}

export type ProjectCustomAttribute = {
  __typename?: 'ProjectCustomAttribute';
  id?: Maybe<Scalars['ID']>;
  listItem?: Maybe<ProjectCustomAttributeMasterListItem>;
  master: ProjectCustomAttributeMaster;
  sortNo: Scalars['BigInteger'];
  value?: Maybe<Scalars['String']>;
};

export type ProjectCustomAttributeMaster = {
  __typename?: 'ProjectCustomAttributeMaster';
  id?: Maybe<Scalars['ID']>;
  listItem: Array<Maybe<ProjectCustomAttributeMasterListItem>>;
  name: Scalars['String'];
  required: Scalars['Boolean'];
  sortNo: Scalars['BigInteger'];
  type: CustomerAttributeType;
  versionNo: Scalars['Int'];
};

export type ProjectCustomAttributeMasterListItem = {
  __typename?: 'ProjectCustomAttributeMasterListItem';
  id?: Maybe<Scalars['ID']>;
  sortNo: Scalars['BigInteger'];
  value: Scalars['String'];
  versionNo: Scalars['Int'];
};

export type ProjectCustomAttributeType = {
  __typename?: 'ProjectCustomAttributeType';
  code?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  sortNo?: Maybe<Scalars['BigInteger']>;
};

export type ProjectDateGraphData = {
  __typename?: 'ProjectDateGraphData';
  /** ISO-8601 */
  date: Scalars['Date'];
  items: Array<Maybe<ProjectDateGraphDataDetail>>;
  reportType: ReportType;
};

export type ProjectDateGraphDataDetail = {
  __typename?: 'ProjectDateGraphDataDetail';
  color: Scalars['String'];
  percent: Scalars['Float'];
  projectId: Scalars['String'];
  projectName: Scalars['String'];
  teamId: Scalars['String'];
  teamName: Scalars['String'];
  workingTimeSec: Scalars['BigInteger'];
};

export enum ProjectFavoriteFilter {
  Both = 'Both',
  Favorite = 'Favorite',
  NotFavorite = 'NotFavorite',
}

export type ProjectForSelector = {
  __typename?: 'ProjectForSelector';
  clientId?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  complete: Scalars['Boolean'];
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  teamId: Scalars['String'];
  teamtName: Scalars['String'];
};

export type ProjectGraphDataInputInput = {
  clientIds: Array<Maybe<Scalars['String']>>;
  /** ISO-8601 */
  end: Scalars['DateTime'];
  memberId?: Maybe<Scalars['String']>;
  memberIds: Array<Maybe<Scalars['String']>>;
  organizationId: Scalars['String'];
  projectIds: Array<Maybe<Scalars['String']>>;
  reportType: ReportType;
  /** ISO-8601 */
  start: Scalars['DateTime'];
  teamId?: Maybe<Scalars['String']>;
  teamIds: Array<Maybe<Scalars['String']>>;
  timeZoneOffset: Scalars['String'];
};

export enum ProjectInitialViewType {
  GanttChart = 'GanttChart',
  KanbanBoard = 'KanbanBoard',
  List = 'List',
}

export enum ProjectSortKey {
  ClientName = 'ClientName',
  CostBudget = 'CostBudget',
  EstimateWorkingTimeSec = 'EstimateWorkingTimeSec',
  LaborCost = 'LaborCost',
  ProjectKey = 'ProjectKey',
  ProjectName = 'ProjectName',
  SalesAmount = 'SalesAmount',
  ScheduledEndDate = 'ScheduledEndDate',
  ScheduledStartDate = 'ScheduledStartDate',
  SortNo = 'SortNo',
  TeamName = 'TeamName',
  WorkingTimeSec = 'WorkingTimeSec',
}

export type ProjectStatus = {
  __typename?: 'ProjectStatus';
  endStatus: Scalars['Boolean'];
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  sortNo: Scalars['BigInteger'];
  teamId: Scalars['String'];
  versionNo: Scalars['Int'];
};

export type ProjectStatusWithProject = {
  __typename?: 'ProjectStatusWithProject';
  projectStatus: ProjectStatus;
  projects: Array<Maybe<Project>>;
};

export type ProjectSunburstGraphData = {
  __typename?: 'ProjectSunburstGraphData';
  children: Array<Maybe<ProjectSunburstGraphProjectData>>;
  color: Scalars['String'];
  name: Scalars['String'];
  reportType: ReportType;
  teamId: Scalars['String'];
};

export type ProjectSunburstGraphProjectData = {
  __typename?: 'ProjectSunburstGraphProjectData';
  color: Scalars['String'];
  name: Scalars['String'];
  percent: Scalars['Float'];
  projectId: Scalars['String'];
  workingTimeSec: Scalars['BigInteger'];
};

export type ProjectTemplate = {
  __typename?: 'ProjectTemplate';
  client?: Maybe<Client>;
  description?: Maybe<Scalars['String']>;
  estimateTimeSec?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  priority?: Maybe<Priority>;
  sortNoInList: Scalars['BigInteger'];
  versionNo: Scalars['Int'];
};

export type ProjectTemplateCustomAttribute = {
  __typename?: 'ProjectTemplateCustomAttribute';
  id?: Maybe<Scalars['ID']>;
  listItem?: Maybe<ProjectCustomAttributeMasterListItem>;
  master: ProjectCustomAttributeMaster;
  sortNo: Scalars['BigInteger'];
  value?: Maybe<Scalars['String']>;
};

export enum ProjectTemplateSortKey {
  Name = 'Name',
  SortNo = 'SortNo',
}

export type ProjectTemplateSubTask = {
  __typename?: 'ProjectTemplateSubTask';
  createdMember: Member;
  id?: Maybe<Scalars['ID']>;
  projectTemplateTask: ProjectTemplateTask;
  sortNo: Scalars['BigInteger'];
  title: Scalars['String'];
  versionNo: Scalars['Int'];
};

export type ProjectTemplateTask = {
  __typename?: 'ProjectTemplateTask';
  createdMember: Member;
  description?: Maybe<Scalars['String']>;
  estimateTimeSec?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  priority?: Maybe<Priority>;
  projectTemplate: ProjectTemplate;
  projectTemplateTaskStatus: ProjectTemplateTaskStatus;
  sortNoInList: Scalars['BigInteger'];
  sortNoInTaskStatus: Scalars['BigInteger'];
  title: Scalars['String'];
  versionNo: Scalars['Int'];
};

export type ProjectTemplateTaskCustomAttribute = {
  __typename?: 'ProjectTemplateTaskCustomAttribute';
  id?: Maybe<Scalars['ID']>;
  listItem?: Maybe<TaskCustomAttributeMasterListItem>;
  master: TaskCustomAttributeMaster;
  sortNo: Scalars['BigInteger'];
  value?: Maybe<Scalars['String']>;
};

export type ProjectTemplateTaskRemainder = {
  __typename?: 'ProjectTemplateTaskRemainder';
  beforeDeadlineMinutes: Scalars['Int'];
  id?: Maybe<Scalars['ID']>;
  projectTemplateTask: ProjectTemplateTask;
  targetMember?: Maybe<Member>;
  taskRemainderDestType: TaskRemainderDestType;
  taskRemainderType: TaskRemainderType;
};

export type ProjectTemplateTaskStatus = {
  __typename?: 'ProjectTemplateTaskStatus';
  endStatus: Scalars['Boolean'];
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  projectTemplate: ProjectTemplate;
  sortNo: Scalars['BigInteger'];
  versionNo: Scalars['Int'];
};

export type ProjectTemplateTaskStatusWithTask = {
  __typename?: 'ProjectTemplateTaskStatusWithTask';
  projectTemplateTaskStatus: ProjectTemplateTaskStatus;
  projectTemplateTasks: Array<Maybe<ProjectTemplateTask>>;
};

export type ProxyLoginInputInput = {
  mailAddress: Scalars['String'];
  password: Scalars['String'];
  userId: Scalars['BigInteger'];
};

/** Query root */
export type Query = {
  __typename?: 'Query';
  accessControlSettings?: Maybe<AccessControlSettings>;
  allMemberWorkingHistoriesSpecifyTerm?: Maybe<Array<Maybe<WorkingHistory>>>;
  allTeamClosingSettings?: Maybe<Array<Maybe<TeamClosingSetting>>>;
  announcement?: Maybe<Announcement>;
  announcements?: Maybe<Array<Maybe<Announcement>>>;
  apiKey?: Maybe<ApiKey>;
  appleAccount?: Maybe<AppleAccount>;
  assignedProjects?: Maybe<Array<Maybe<Project>>>;
  assignedTasks?: Maybe<Array<Maybe<Task>>>;
  attachmentFile?: Maybe<AttachmentFile>;
  boardProjects?: Maybe<ProjectStatusWithProject>;
  boardTasks?: Maybe<TaskStatusWithTask>;
  calendarEvent?: Maybe<CalendarEvent>;
  calendarEventWorkingHistories?: Maybe<Array<Maybe<WorkingHistory>>>;
  calendarEventWorkingHistoriesForSummary?: Maybe<Array<Maybe<WorkingHistoryForSummary>>>;
  calendarEventWorkingSchedules?: Maybe<Array<Maybe<WorkingSchedule>>>;
  calendarEventWorkingSchedulesForSummary?: Maybe<Array<Maybe<WorkingScheduleForSummary>>>;
  calendarWorkingHistory?: Maybe<CalendarWorkingHistory>;
  calendarWorkingSchedule?: Maybe<CalendarWorkingSchedule>;
  client?: Maybe<Client>;
  clientBarGraphData?: Maybe<Array<Maybe<ClientBarGraphData>>>;
  clientByCode?: Maybe<Client>;
  clientDateGraphData?: Maybe<Array<Maybe<ClientDateGraphData>>>;
  clientProjects?: Maybe<Array<Maybe<Project>>>;
  clientSunburstGraphData?: Maybe<Array<Maybe<ClientSunburstGraphData>>>;
  closingSettings?: Maybe<ClosingSetting>;
  confirmAmountAfterLicenceCountChange?: Maybe<AmountAfterLicenceCountChange>;
  confirmJoinable?: Maybe<Scalars['Boolean']>;
  confirmJoinableWithLink?: Maybe<Scalars['Boolean']>;
  contract?: Maybe<Contract>;
  creditCard?: Maybe<CreditCard>;
  csvReportItemDefinition?: Maybe<CsvReportItemDefinition>;
  csvReportItemDefinitions?: Maybe<Array<Maybe<CsvReportItemDefinition>>>;
  defaultCsvReportItemDefinition?: Maybe<CsvReportItemDefinition>;
  environment?: Maybe<Scalars['String']>;
  existAdminMember?: Maybe<Scalars['Boolean']>;
  exportClientExcel?: Maybe<ExportClientExcel>;
  exportClientExcels?: Maybe<Array<Maybe<ExportClientExcel>>>;
  exportMemberExcel?: Maybe<ExportMemberExcel>;
  exportMemberExcels?: Maybe<Array<Maybe<ExportMemberExcel>>>;
  exportProjectExcel?: Maybe<ExportProjectExcel>;
  exportProjectExcels?: Maybe<Array<Maybe<ExportProjectExcel>>>;
  exportTaskExcel?: Maybe<ExportTaskExcel>;
  exportTaskExcels?: Maybe<Array<Maybe<ExportTaskExcel>>>;
  favoriteProjects?: Maybe<Array<Maybe<Project>>>;
  favoriteTasks?: Maybe<Array<Maybe<Task>>>;
  fetchOrganizationActiveMembers?: Maybe<Array<Maybe<MemberWithLatestTask>>>;
  fetchOrganizationMembers?: Maybe<Array<Maybe<MemberWithLatestTask>>>;
  fetchSyncRegisterWorkingHistoriesTargetGoogleCalendars?: Maybe<Array<Maybe<GoogleCalendarEvent>>>;
  fetchSyncRegisterWorkingSchedulesTargetGoogleCalendars?: Maybe<Array<Maybe<GoogleCalendarEvent>>>;
  fetchTeamActiveMembers?: Maybe<Array<Maybe<MemberWithLatestTask>>>;
  fetchTeamMembers?: Maybe<Array<Maybe<MemberWithLatestTask>>>;
  firstViewProject?: Maybe<FirstViewProjectInfo>;
  googleCalendarEvent?: Maybe<GoogleCalendarEvent>;
  googleCalendarEvents?: Maybe<Array<Maybe<GoogleCalendarEvent>>>;
  googleCalendars?: Maybe<Array<Maybe<GoogleCalendar>>>;
  googleWorkSpaceMembers?: Maybe<Array<Maybe<GoogleAccount>>>;
  graphDataClientList?: Maybe<Array<Maybe<GraphDataClient>>>;
  graphDataMemberList?: Maybe<Array<Maybe<GraphDataMember>>>;
  graphDataProjectList?: Maybe<Array<Maybe<GraphDataProject>>>;
  graphDataTaskList?: Maybe<Array<Maybe<GraphDataTask>>>;
  graphDataTeamList?: Maybe<Array<Maybe<GraphDataTeam>>>;
  graphSearchTarget?: Maybe<SearchGraphTarget>;
  graphTotalWorkingTimeSec: Scalars['BigInteger'];
  hasDisableAclPermission: Scalars['Boolean'];
  iCalExport?: Maybe<ICalExport>;
  iCalKey?: Maybe<ICalKey>;
  invoices?: Maybe<Array<Maybe<Invoice>>>;
  isExistLoginPassword: Scalars['Boolean'];
  isExistsDuplicateTermProjectContract: Scalars['Boolean'];
  isIpAddressAccessAllowed: Scalars['Boolean'];
  isIpAddressAccessAllowedWithAllowdIpAddressList: Scalars['Boolean'];
  joinedTeams?: Maybe<Array<Maybe<Team>>>;
  joinedTeamsWithoutPersonalTeam?: Maybe<Array<Maybe<Team>>>;
  laborCost?: Maybe<LaborCost>;
  laborCosts?: Maybe<Array<Maybe<LaborCost>>>;
  latestWorkingHistory?: Maybe<WorkingHistory>;
  latestWorkingTask?: Maybe<Task>;
  mailAddressAvailable: Scalars['Boolean'];
  me?: Maybe<Member>;
  member?: Maybe<Member>;
  memberBarGraphData?: Maybe<Array<Maybe<MemberBarGraphData>>>;
  memberDateGraphData?: Maybe<Array<Maybe<MemberDateGraphData>>>;
  memberInvitation?: Maybe<MemberInvitation>;
  memberInvitationLink?: Maybe<MemberInvitationLink>;
  memberInvitationLinks?: Maybe<Array<Maybe<MemberInvitationLink>>>;
  memberInvitations?: Maybe<Array<Maybe<MemberInvitation>>>;
  memberRegisterRequest?: Maybe<MemberRegisterRequest>;
  memberRegisterRequestWithInvitationLink?: Maybe<MemberRegisterRequest>;
  memberSunburstGraphData?: Maybe<Array<Maybe<MemberSunburstGraphData>>>;
  myCsvReport?: Maybe<MyCsvReport>;
  myCsvReports?: Maybe<Array<Maybe<MyCsvReport>>>;
  myGoogleAccount?: Maybe<GoogleAccount>;
  myGoogleCalendars?: Maybe<Array<Maybe<GoogleCalendar>>>;
  myReport?: Maybe<MyReport>;
  myReports?: Maybe<Array<Maybe<MyReport>>>;
  mySettings?: Maybe<MemberSettings>;
  myTasks?: Maybe<Array<Maybe<Task>>>;
  notification?: Maybe<Notification>;
  notificationV2?: Maybe<NotificationV2>;
  notifications?: Maybe<Array<Maybe<Notification>>>;
  notificationsV2?: Maybe<Array<Maybe<NotificationV2>>>;
  organization?: Maybe<Organization>;
  organizationArchivedTeamsWithoutPersonalTeam?: Maybe<Array<Maybe<Team>>>;
  organizationClients?: Maybe<Array<Maybe<Client>>>;
  organizationCsvReport?: Maybe<OrganizationCsvReport>;
  organizationCsvReports?: Maybe<Array<Maybe<OrganizationCsvReport>>>;
  organizationMembers?: Maybe<Array<Maybe<Member>>>;
  organizationMembersNotEnableTwoFactorAuth?: Maybe<Array<Maybe<Member>>>;
  organizationReport?: Maybe<OrganizationReport>;
  organizationReports?: Maybe<Array<Maybe<OrganizationReport>>>;
  organizationTags?: Maybe<Array<Maybe<Tag>>>;
  organizationTeams?: Maybe<Array<Maybe<Team>>>;
  organizationTeamsWithoutPersonalTeam?: Maybe<Array<Maybe<Team>>>;
  organizations?: Maybe<Array<Maybe<Organization>>>;
  passwordResetRequest?: Maybe<PasswordResetRequest>;
  paymentSecret?: Maybe<Scalars['String']>;
  planProduct?: Maybe<PlanProduct>;
  planProductsMonthly?: Maybe<Array<Maybe<PlanProduct>>>;
  planProductsYearly?: Maybe<Array<Maybe<PlanProduct>>>;
  project?: Maybe<Project>;
  projectAttachmentFiles?: Maybe<Array<Maybe<AttachmentFile>>>;
  projectBarGraphData?: Maybe<Array<Maybe<ProjectBarGraphData>>>;
  projectByKey?: Maybe<Project>;
  projectCalendarEvents?: Maybe<Array<Maybe<CalendarEvent>>>;
  projectContract?: Maybe<ProjectContract>;
  projectContractsByProjectId?: Maybe<Array<Maybe<ProjectContract>>>;
  projectCustomAttribute?: Maybe<ProjectCustomAttribute>;
  projectCustomAttributeMaster?: Maybe<ProjectCustomAttributeMaster>;
  projectCustomAttributeMasters?: Maybe<Array<Maybe<ProjectCustomAttributeMaster>>>;
  projectCustomAttributeTypes?: Maybe<Array<Maybe<ProjectCustomAttributeType>>>;
  projectCustomAttributesByProject?: Maybe<Array<Maybe<ProjectCustomAttribute>>>;
  projectDateGraphData?: Maybe<Array<Maybe<ProjectDateGraphData>>>;
  projectMembers?: Maybe<Array<Maybe<Member>>>;
  projectStatus?: Maybe<ProjectStatus>;
  projectSunburstGraphData?: Maybe<Array<Maybe<ProjectSunburstGraphData>>>;
  projectTaskStatus?: Maybe<Array<Maybe<TaskStatus>>>;
  projectTasks?: Maybe<Array<Maybe<Task>>>;
  projectTasksEstimateTimeSec?: Maybe<Scalars['BigInteger']>;
  projectTasksForSelector?: Maybe<Array<Maybe<TaskForSelector>>>;
  projectTasksGroupByStatus?: Maybe<Array<Maybe<TaskStatusWithTask>>>;
  projectTemplate?: Maybe<ProjectTemplate>;
  projectTemplateCustomAttribute?: Maybe<ProjectTemplateCustomAttribute>;
  projectTemplateCustomAttributesByProject?: Maybe<Array<Maybe<ProjectTemplateCustomAttribute>>>;
  projectTemplateSubTask?: Maybe<ProjectTemplateSubTask>;
  projectTemplateSubTasks?: Maybe<Array<Maybe<ProjectTemplateSubTask>>>;
  projectTemplateTask?: Maybe<ProjectTemplateTask>;
  projectTemplateTaskCustomAttribute?: Maybe<ProjectTemplateTaskCustomAttribute>;
  projectTemplateTaskCustomAttributesByTask?: Maybe<
    Array<Maybe<ProjectTemplateTaskCustomAttribute>>
  >;
  projectTemplateTaskRemainder?: Maybe<ProjectTemplateTaskRemainder>;
  projectTemplateTaskRemainders?: Maybe<Array<Maybe<ProjectTemplateTaskRemainder>>>;
  projectTemplateTaskStatus?: Maybe<ProjectTemplateTaskStatus>;
  projectTemplateTaskStatuses?: Maybe<Array<Maybe<ProjectTemplateTaskStatus>>>;
  projectTemplateTaskTags?: Maybe<Array<Maybe<Tag>>>;
  projectTemplateTasks?: Maybe<Array<Maybe<ProjectTemplateTask>>>;
  projectTemplateTasksGroupByStatus?: Maybe<Array<Maybe<ProjectTemplateTaskStatusWithTask>>>;
  projectTemplates?: Maybe<Array<Maybe<ProjectTemplate>>>;
  projectWorkingTimeSec?: Maybe<Scalars['BigInteger']>;
  searchFavoriteTasks?: Maybe<Array<Maybe<Task>>>;
  searchTasks?: Maybe<Array<Maybe<Task>>>;
  srcIpAddress?: Maybe<Scalars['String']>;
  subTask?: Maybe<SubTask>;
  subTasks?: Maybe<Array<Maybe<SubTask>>>;
  tag?: Maybe<Tag>;
  tagTasks?: Maybe<Array<Maybe<Task>>>;
  task?: Maybe<Task>;
  taskAttachmentFiles?: Maybe<Array<Maybe<AttachmentFile>>>;
  taskBarGraphData?: Maybe<Array<Maybe<TaskBarGraphData>>>;
  taskComment?: Maybe<TaskComment>;
  taskComments?: Maybe<Array<Maybe<TaskComment>>>;
  taskCommentsMin?: Maybe<Array<Maybe<TaskCommentMin>>>;
  taskCustomAttribute?: Maybe<TaskCustomAttribute>;
  taskCustomAttributeMaster?: Maybe<TaskCustomAttributeMaster>;
  taskCustomAttributeMasters?: Maybe<Array<Maybe<TaskCustomAttributeMaster>>>;
  taskCustomAttributeTypes?: Maybe<Array<Maybe<TaskCustomAttributeType>>>;
  taskCustomAttributesByTask?: Maybe<Array<Maybe<TaskCustomAttribute>>>;
  taskDateGraphData?: Maybe<Array<Maybe<TaskDateGraphData>>>;
  taskRemainder?: Maybe<TaskRemainder>;
  taskRemainders?: Maybe<Array<Maybe<TaskRemainder>>>;
  taskStatus?: Maybe<TaskStatus>;
  taskSunburstGraphData?: Maybe<Array<Maybe<TaskSunburstGraphData>>>;
  taskTags?: Maybe<Array<Maybe<Tag>>>;
  taskWorkingHistories?: Maybe<Array<Maybe<WorkingHistory>>>;
  taskWorkingHistoriesForSummary?: Maybe<Array<Maybe<WorkingHistoryForSummary>>>;
  taskWorkingHistorySummaryGroupByAssinee?: Maybe<
    Array<Maybe<WorkingHistorySummaryGroupByAssinee>>
  >;
  taskWorkingScheduleSummaryGroupByAssinee?: Maybe<
    Array<Maybe<WorkingScheduleSummaryGroupByAssinee>>
  >;
  taskWorkingSchedules?: Maybe<Array<Maybe<WorkingSchedule>>>;
  taskWorkingSchedulesForSummary?: Maybe<Array<Maybe<WorkingScheduleForSummary>>>;
  team?: Maybe<Team>;
  teamBarGraphData?: Maybe<Array<Maybe<TeamBarGraphData>>>;
  teamClosingSettings?: Maybe<TeamClosingSetting>;
  teamCsvReport?: Maybe<TeamCsvReport>;
  teamCsvReports?: Maybe<Array<Maybe<TeamCsvReport>>>;
  teamDateGraphData?: Maybe<Array<Maybe<TeamDateGraphData>>>;
  teamMemberRelation?: Maybe<TeamMemberRelation>;
  teamMemberRelations?: Maybe<Array<Maybe<TeamMemberRelation>>>;
  teamMembers?: Maybe<Array<Maybe<Member>>>;
  teamProjectContracts?: Maybe<Array<Maybe<ProjectContract>>>;
  teamProjectStatus?: Maybe<Array<Maybe<ProjectStatus>>>;
  teamProjects?: Maybe<Array<Maybe<Project>>>;
  teamProjectsForSelector?: Maybe<Array<Maybe<ProjectForSelector>>>;
  teamReport?: Maybe<TeamReport>;
  teamReports?: Maybe<Array<Maybe<TeamReport>>>;
  teamSunburstGraphData?: Maybe<Array<Maybe<TeamSunburstGraphData>>>;
  timeZone?: Maybe<TimeZone>;
  timeZones?: Maybe<Array<Maybe<TimeZone>>>;
  twoFactorAuthEnabled?: Maybe<Scalars['Boolean']>;
  twoFactorAuthSetting?: Maybe<TwoFactorAuthSetting>;
  unreadAnnouncementCount?: Maybe<Scalars['Int']>;
  unreadNotificationCount?: Maybe<Scalars['Int']>;
  upcomingInvoice?: Maybe<Invoice>;
  version?: Maybe<Version>;
  workedTasks?: Maybe<Array<Maybe<Task>>>;
  workingHistoriesSpecifyTerm?: Maybe<Array<Maybe<WorkingHistory>>>;
  workingHistoriesSpecifyTermForCalendar?: Maybe<Array<Maybe<WorkingHistoryForCalendar>>>;
  workingHistoriesSpecifyTermForSummary?: Maybe<Array<Maybe<WorkingHistoryForSummary>>>;
  workingHistory?: Maybe<WorkingHistory>;
  workingHistoryForSummary?: Maybe<WorkingHistoryForSummary>;
  workingSchedule?: Maybe<WorkingSchedule>;
  workingScheduleForSummary?: Maybe<WorkingScheduleForSummary>;
  workingSchedulesSpecifyTerm?: Maybe<Array<Maybe<WorkingSchedule>>>;
  workingSchedulesSpecifyTermForCalendar?: Maybe<Array<Maybe<WorkingScheduleForCalendar>>>;
  workingSchedulesSpecifyTermForSummary?: Maybe<Array<Maybe<WorkingScheduleForSummary>>>;
};

/** Query root */
export type QueryAllMemberWorkingHistoriesSpecifyTermArgs = {
  input: AllMemberWorkingWorkingHistoriesInputInput;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

/** Query root */
export type QueryAnnouncementArgs = {
  id: Scalars['ID'];
};

/** Query root */
export type QueryAnnouncementsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

/** Query root */
export type QueryAssignedProjectsArgs = {
  input?: Maybe<FetchAssignedProjectsInputInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

/** Query root */
export type QueryAssignedTasksArgs = {
  input?: Maybe<SearchTaskInputInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

/** Query root */
export type QueryAttachmentFileArgs = {
  id: Scalars['ID'];
};

/** Query root */
export type QueryBoardProjectsArgs = {
  assigneeIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  favoriteCondition?: Maybe<ProjectFavoriteFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  projectName?: Maybe<Scalars['String']>;
  projectStatusId?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['String']>;
};

/** Query root */
export type QueryBoardTasksArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  projectId?: Maybe<Scalars['String']>;
  taskStatusId?: Maybe<Scalars['String']>;
};

/** Query root */
export type QueryCalendarEventArgs = {
  id: Scalars['String'];
};

/** Query root */
export type QueryCalendarEventWorkingHistoriesArgs = {
  calendarEventId: Scalars['String'];
  end?: Maybe<Scalars['DateTime']>;
  start?: Maybe<Scalars['DateTime']>;
};

/** Query root */
export type QueryCalendarEventWorkingHistoriesForSummaryArgs = {
  calendarEventId: Scalars['String'];
  end?: Maybe<Scalars['DateTime']>;
  start?: Maybe<Scalars['DateTime']>;
};

/** Query root */
export type QueryCalendarEventWorkingSchedulesArgs = {
  calendarEventId: Scalars['String'];
};

/** Query root */
export type QueryCalendarEventWorkingSchedulesForSummaryArgs = {
  calendarEventId: Scalars['String'];
};

/** Query root */
export type QueryCalendarWorkingHistoryArgs = {
  id: Scalars['String'];
};

/** Query root */
export type QueryCalendarWorkingScheduleArgs = {
  id: Scalars['String'];
};

/** Query root */
export type QueryClientArgs = {
  id: Scalars['ID'];
};

/** Query root */
export type QueryClientBarGraphDataArgs = {
  input: ClientGraphDataInputInput;
};

/** Query root */
export type QueryClientByCodeArgs = {
  code: Scalars['String'];
};

/** Query root */
export type QueryClientDateGraphDataArgs = {
  input: ClientGraphDataInputInput;
};

/** Query root */
export type QueryClientProjectsArgs = {
  clientId: Scalars['String'];
};

/** Query root */
export type QueryClientSunburstGraphDataArgs = {
  input: ClientGraphDataInputInput;
};

/** Query root */
export type QueryConfirmAmountAfterLicenceCountChangeArgs = {
  id: Scalars['ID'];
  input?: Maybe<ConfirmAmountAfterLicenceCountChangeInputInput>;
};

/** Query root */
export type QueryConfirmJoinableArgs = {
  key: Scalars['String'];
};

/** Query root */
export type QueryConfirmJoinableWithLinkArgs = {
  key: Scalars['String'];
};

/** Query root */
export type QueryCsvReportItemDefinitionArgs = {
  id: Scalars['ID'];
};

/** Query root */
export type QueryExistAdminMemberArgs = {
  excludeMemberId: Scalars['String'];
};

/** Query root */
export type QueryExportClientExcelArgs = {
  id: Scalars['ID'];
};

/** Query root */
export type QueryExportMemberExcelArgs = {
  id: Scalars['ID'];
};

/** Query root */
export type QueryExportProjectExcelArgs = {
  id: Scalars['ID'];
};

/** Query root */
export type QueryExportProjectExcelsArgs = {
  input: FetchExportProjectExcelInputInput;
};

/** Query root */
export type QueryExportTaskExcelArgs = {
  id: Scalars['ID'];
};

/** Query root */
export type QueryExportTaskExcelsArgs = {
  input: FetchExportTaskExcelInputInput;
};

/** Query root */
export type QueryFavoriteProjectsArgs = {
  input?: Maybe<FetchFavoriteProjectsInputInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

/** Query root */
export type QueryFetchSyncRegisterWorkingHistoriesTargetGoogleCalendarsArgs = {
  input: RegisterWorkingHistoriesFromGoogleCallendarEventsInputInput;
};

/** Query root */
export type QueryFetchSyncRegisterWorkingSchedulesTargetGoogleCalendarsArgs = {
  input: RegisterWorkingSchedulesFromGoogleCallendarEventsInputInput;
};

/** Query root */
export type QueryFetchTeamActiveMembersArgs = {
  teamId: Scalars['String'];
};

/** Query root */
export type QueryFetchTeamMembersArgs = {
  teamId: Scalars['String'];
};

/** Query root */
export type QueryGoogleCalendarEventArgs = {
  calendarId: Scalars['String'];
  id: Scalars['ID'];
};

/** Query root */
export type QueryGoogleCalendarEventsArgs = {
  input?: Maybe<GoogleCalendarInputInput>;
};

/** Query root */
export type QueryGraphDataClientListArgs = {
  input: GraphDataClientListInputInput;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

/** Query root */
export type QueryGraphDataMemberListArgs = {
  input: GraphDataMemberListInputInput;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

/** Query root */
export type QueryGraphDataProjectListArgs = {
  input: GraphDataProjectListInputInput;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

/** Query root */
export type QueryGraphDataTaskListArgs = {
  input: GraphDataTaskListInputInput;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

/** Query root */
export type QueryGraphDataTeamListArgs = {
  input: GraphDataTeamListInputInput;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

/** Query root */
export type QueryGraphSearchTargetArgs = {
  input: SearchGraphTargetInputInput;
};

/** Query root */
export type QueryGraphTotalWorkingTimeSecArgs = {
  input: SearchGraphTargetInputInput;
};

/** Query root */
export type QueryHasDisableAclPermissionArgs = {
  token: Scalars['String'];
};

/** Query root */
export type QueryICalExportArgs = {
  id?: Maybe<Scalars['ID']>;
};

/** Query root */
export type QueryIsExistsDuplicateTermProjectContractArgs = {
  input: CheckDuplicateTermProjectContractInputInput;
};

/** Query root */
export type QueryIsIpAddressAccessAllowedWithAllowdIpAddressListArgs = {
  input: IpAddressListInputInput;
};

/** Query root */
export type QueryJoinedTeamsArgs = {
  organizationId: Scalars['String'];
};

/** Query root */
export type QueryJoinedTeamsWithoutPersonalTeamArgs = {
  organizationId: Scalars['String'];
  withArchivedTeam: Scalars['Boolean'];
};

/** Query root */
export type QueryLaborCostArgs = {
  id?: Maybe<Scalars['String']>;
};

/** Query root */
export type QueryLaborCostsArgs = {
  memberId: Scalars['String'];
};

/** Query root */
export type QueryMailAddressAvailableArgs = {
  mailAddress: Scalars['String'];
};

/** Query root */
export type QueryMemberArgs = {
  memberId: Scalars['String'];
};

/** Query root */
export type QueryMemberBarGraphDataArgs = {
  input: MemberGraphDataInputInput;
};

/** Query root */
export type QueryMemberDateGraphDataArgs = {
  input: MemberGraphDataInputInput;
};

/** Query root */
export type QueryMemberInvitationArgs = {
  key: Scalars['String'];
};

/** Query root */
export type QueryMemberInvitationLinkArgs = {
  key: Scalars['String'];
};

/** Query root */
export type QueryMemberRegisterRequestArgs = {
  key: Scalars['String'];
};

/** Query root */
export type QueryMemberRegisterRequestWithInvitationLinkArgs = {
  key: Scalars['String'];
};

/** Query root */
export type QueryMemberSunburstGraphDataArgs = {
  input: MemberGraphDataInputInput;
};

/** Query root */
export type QueryMyCsvReportArgs = {
  id: Scalars['ID'];
};

/** Query root */
export type QueryMyReportArgs = {
  id: Scalars['ID'];
};

/** Query root */
export type QueryNotificationArgs = {
  id?: Maybe<Scalars['String']>;
};

/** Query root */
export type QueryNotificationV2Args = {
  id?: Maybe<Scalars['String']>;
};

/** Query root */
export type QueryNotificationsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

/** Query root */
export type QueryNotificationsV2Args = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  read: Scalars['Boolean'];
  unread: Scalars['Boolean'];
};

/** Query root */
export type QueryOrganizationArgs = {
  id: Scalars['ID'];
};

/** Query root */
export type QueryOrganizationArchivedTeamsWithoutPersonalTeamArgs = {
  organizationId: Scalars['String'];
};

/** Query root */
export type QueryOrganizationClientsArgs = {
  input?: Maybe<FetchClientsInputInput>;
  organizationId: Scalars['String'];
};

/** Query root */
export type QueryOrganizationCsvReportArgs = {
  id: Scalars['ID'];
};

/** Query root */
export type QueryOrganizationMembersArgs = {
  organizationId: Scalars['String'];
};

/** Query root */
export type QueryOrganizationMembersNotEnableTwoFactorAuthArgs = {
  organizationId: Scalars['String'];
};

/** Query root */
export type QueryOrganizationReportArgs = {
  id: Scalars['ID'];
};

/** Query root */
export type QueryOrganizationTagsArgs = {
  input?: Maybe<FetchTagsInputInput>;
  organizationId: Scalars['String'];
};

/** Query root */
export type QueryOrganizationTeamsArgs = {
  organizationId: Scalars['String'];
};

/** Query root */
export type QueryOrganizationTeamsWithoutPersonalTeamArgs = {
  organizationId: Scalars['String'];
};

/** Query root */
export type QueryOrganizationsArgs = {
  tokens?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Query root */
export type QueryPasswordResetRequestArgs = {
  key: Scalars['String'];
};

/** Query root */
export type QueryPlanProductArgs = {
  id: Scalars['ID'];
};

/** Query root */
export type QueryProjectArgs = {
  id: Scalars['ID'];
};

/** Query root */
export type QueryProjectAttachmentFilesArgs = {
  projectId: Scalars['String'];
};

/** Query root */
export type QueryProjectBarGraphDataArgs = {
  input: ProjectGraphDataInputInput;
};

/** Query root */
export type QueryProjectByKeyArgs = {
  key: Scalars['String'];
};

/** Query root */
export type QueryProjectCalendarEventsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  projectId: Scalars['String'];
};

/** Query root */
export type QueryProjectContractArgs = {
  id?: Maybe<Scalars['String']>;
};

/** Query root */
export type QueryProjectContractsByProjectIdArgs = {
  projectId?: Maybe<Scalars['String']>;
};

/** Query root */
export type QueryProjectCustomAttributeArgs = {
  id: Scalars['ID'];
};

/** Query root */
export type QueryProjectCustomAttributeMasterArgs = {
  id: Scalars['ID'];
};

/** Query root */
export type QueryProjectCustomAttributesByProjectArgs = {
  projectId: Scalars['String'];
};

/** Query root */
export type QueryProjectDateGraphDataArgs = {
  input: ProjectGraphDataInputInput;
};

/** Query root */
export type QueryProjectMembersArgs = {
  projectId: Scalars['String'];
};

/** Query root */
export type QueryProjectStatusArgs = {
  id: Scalars['String'];
};

/** Query root */
export type QueryProjectSunburstGraphDataArgs = {
  input: ProjectGraphDataInputInput;
};

/** Query root */
export type QueryProjectTaskStatusArgs = {
  projectId: Scalars['String'];
};

/** Query root */
export type QueryProjectTasksArgs = {
  input?: Maybe<FetchProjectTasksInputInput>;
  projectId: Scalars['String'];
};

/** Query root */
export type QueryProjectTasksEstimateTimeSecArgs = {
  id: Scalars['ID'];
};

/** Query root */
export type QueryProjectTasksForSelectorArgs = {
  input?: Maybe<FetchProjectTasksInputInput>;
  projectId: Scalars['String'];
};

/** Query root */
export type QueryProjectTasksGroupByStatusArgs = {
  projectId?: Maybe<Scalars['String']>;
};

/** Query root */
export type QueryProjectTemplateArgs = {
  id: Scalars['ID'];
};

/** Query root */
export type QueryProjectTemplateCustomAttributeArgs = {
  id: Scalars['ID'];
};

/** Query root */
export type QueryProjectTemplateCustomAttributesByProjectArgs = {
  projectTemplateId: Scalars['String'];
};

/** Query root */
export type QueryProjectTemplateSubTaskArgs = {
  id?: Maybe<Scalars['String']>;
};

/** Query root */
export type QueryProjectTemplateSubTasksArgs = {
  projectTemplateTaskId: Scalars['String'];
};

/** Query root */
export type QueryProjectTemplateTaskArgs = {
  id?: Maybe<Scalars['String']>;
};

/** Query root */
export type QueryProjectTemplateTaskCustomAttributeArgs = {
  id: Scalars['ID'];
};

/** Query root */
export type QueryProjectTemplateTaskCustomAttributesByTaskArgs = {
  projectTemplateTaskId: Scalars['String'];
};

/** Query root */
export type QueryProjectTemplateTaskRemainderArgs = {
  id?: Maybe<Scalars['String']>;
};

/** Query root */
export type QueryProjectTemplateTaskRemaindersArgs = {
  projectTemplateTaskId: Scalars['String'];
};

/** Query root */
export type QueryProjectTemplateTaskStatusArgs = {
  id: Scalars['String'];
};

/** Query root */
export type QueryProjectTemplateTaskStatusesArgs = {
  projectTemplateId: Scalars['String'];
};

/** Query root */
export type QueryProjectTemplateTaskTagsArgs = {
  projectTemplateTaskId: Scalars['String'];
};

/** Query root */
export type QueryProjectTemplateTasksArgs = {
  projectTemplateId: Scalars['String'];
};

/** Query root */
export type QueryProjectTemplateTasksGroupByStatusArgs = {
  projectTemplateId?: Maybe<Scalars['String']>;
};

/** Query root */
export type QueryProjectTemplatesArgs = {
  input?: Maybe<FetchProjectTemplatesInputInput>;
};

/** Query root */
export type QueryProjectWorkingTimeSecArgs = {
  id: Scalars['ID'];
};

/** Query root */
export type QuerySearchFavoriteTasksArgs = {
  input: SearchFavoriteTaskInputInput;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

/** Query root */
export type QuerySearchTasksArgs = {
  input: SearchTaskInputInput;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

/** Query root */
export type QuerySubTaskArgs = {
  id?: Maybe<Scalars['String']>;
};

/** Query root */
export type QuerySubTasksArgs = {
  taskId: Scalars['String'];
};

/** Query root */
export type QueryTagArgs = {
  id: Scalars['ID'];
};

/** Query root */
export type QueryTagTasksArgs = {
  tagId: Scalars['String'];
};

/** Query root */
export type QueryTaskArgs = {
  id?: Maybe<Scalars['String']>;
};

/** Query root */
export type QueryTaskAttachmentFilesArgs = {
  taskId: Scalars['String'];
};

/** Query root */
export type QueryTaskBarGraphDataArgs = {
  input: TaskGraphDataInputInput;
};

/** Query root */
export type QueryTaskCommentArgs = {
  id: Scalars['ID'];
};

/** Query root */
export type QueryTaskCommentsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sortOrder?: Maybe<SortOrder>;
  taskId: Scalars['String'];
};

/** Query root */
export type QueryTaskCommentsMinArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sortOrder?: Maybe<SortOrder>;
  taskId: Scalars['String'];
};

/** Query root */
export type QueryTaskCustomAttributeArgs = {
  id: Scalars['ID'];
};

/** Query root */
export type QueryTaskCustomAttributeMasterArgs = {
  id: Scalars['ID'];
};

/** Query root */
export type QueryTaskCustomAttributesByTaskArgs = {
  taskId: Scalars['String'];
};

/** Query root */
export type QueryTaskDateGraphDataArgs = {
  input: TaskGraphDataInputInput;
};

/** Query root */
export type QueryTaskRemainderArgs = {
  id?: Maybe<Scalars['String']>;
};

/** Query root */
export type QueryTaskRemaindersArgs = {
  taskId: Scalars['String'];
};

/** Query root */
export type QueryTaskStatusArgs = {
  id: Scalars['String'];
};

/** Query root */
export type QueryTaskSunburstGraphDataArgs = {
  input: TaskGraphDataInputInput;
};

/** Query root */
export type QueryTaskTagsArgs = {
  taskId: Scalars['String'];
};

/** Query root */
export type QueryTaskWorkingHistoriesArgs = {
  end?: Maybe<Scalars['DateTime']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  onlySelfData?: Maybe<Scalars['Boolean']>;
  start?: Maybe<Scalars['DateTime']>;
  taskId: Scalars['String'];
};

/** Query root */
export type QueryTaskWorkingHistoriesForSummaryArgs = {
  end?: Maybe<Scalars['DateTime']>;
  limit?: Maybe<Scalars['Int']>;
  memberId?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  onlySelfData?: Maybe<Scalars['Boolean']>;
  start?: Maybe<Scalars['DateTime']>;
  taskId: Scalars['String'];
  teamId?: Maybe<Scalars['String']>;
};

/** Query root */
export type QueryTaskWorkingHistorySummaryGroupByAssineeArgs = {
  taskId: Scalars['String'];
  teamId?: Maybe<Scalars['String']>;
};

/** Query root */
export type QueryTaskWorkingScheduleSummaryGroupByAssineeArgs = {
  taskId: Scalars['String'];
  teamId?: Maybe<Scalars['String']>;
};

/** Query root */
export type QueryTaskWorkingSchedulesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  onlySelfData?: Maybe<Scalars['Boolean']>;
  taskId: Scalars['String'];
};

/** Query root */
export type QueryTaskWorkingSchedulesForSummaryArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  onlySelfData?: Maybe<Scalars['Boolean']>;
  taskId: Scalars['String'];
};

/** Query root */
export type QueryTeamArgs = {
  id: Scalars['ID'];
};

/** Query root */
export type QueryTeamBarGraphDataArgs = {
  input: TeamtGraphDataInputInput;
};

/** Query root */
export type QueryTeamClosingSettingsArgs = {
  teamId: Scalars['String'];
};

/** Query root */
export type QueryTeamCsvReportArgs = {
  id: Scalars['ID'];
};

/** Query root */
export type QueryTeamCsvReportsArgs = {
  teamId: Scalars['ID'];
};

/** Query root */
export type QueryTeamDateGraphDataArgs = {
  input: TeamtGraphDataInputInput;
};

/** Query root */
export type QueryTeamMemberRelationArgs = {
  id: Scalars['ID'];
};

/** Query root */
export type QueryTeamMembersArgs = {
  teamId: Scalars['String'];
};

/** Query root */
export type QueryTeamProjectContractsArgs = {
  input?: Maybe<FetchTeamProjectContractsInputInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  teamId: Scalars['String'];
};

/** Query root */
export type QueryTeamProjectStatusArgs = {
  teamId: Scalars['String'];
};

/** Query root */
export type QueryTeamProjectsArgs = {
  input?: Maybe<FetchTeamProjectsInputInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  teamId: Scalars['String'];
};

/** Query root */
export type QueryTeamProjectsForSelectorArgs = {
  input?: Maybe<FetchTeamProjectsInputInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  teamId: Scalars['String'];
};

/** Query root */
export type QueryTeamReportArgs = {
  id: Scalars['ID'];
};

/** Query root */
export type QueryTeamReportsArgs = {
  teamId: Scalars['ID'];
};

/** Query root */
export type QueryTeamSunburstGraphDataArgs = {
  input: TeamtGraphDataInputInput;
};

/** Query root */
export type QueryTimeZoneArgs = {
  id: Scalars['ID'];
};

/** Query root */
export type QueryTwoFactorAuthEnabledArgs = {
  memberId: Scalars['String'];
};

/** Query root */
export type QueryWorkedTasksArgs = {
  input?: Maybe<SearchTaskInputInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

/** Query root */
export type QueryWorkingHistoriesSpecifyTermArgs = {
  input: WorkingHistoriesSpecifyTermInputInput;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

/** Query root */
export type QueryWorkingHistoriesSpecifyTermForCalendarArgs = {
  input: WorkingHistoriesSpecifyTermForCalendarInputInput;
};

/** Query root */
export type QueryWorkingHistoriesSpecifyTermForSummaryArgs = {
  input: WorkingHistoriesSpecifyTermInputInput;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

/** Query root */
export type QueryWorkingHistoryArgs = {
  id: Scalars['String'];
};

/** Query root */
export type QueryWorkingHistoryForSummaryArgs = {
  id: Scalars['String'];
};

/** Query root */
export type QueryWorkingScheduleArgs = {
  id: Scalars['String'];
};

/** Query root */
export type QueryWorkingScheduleForSummaryArgs = {
  id: Scalars['String'];
};

/** Query root */
export type QueryWorkingSchedulesSpecifyTermArgs = {
  input: WorkingSchedulesSpecifyTermInputInput;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

/** Query root */
export type QueryWorkingSchedulesSpecifyTermForCalendarArgs = {
  input: WorkingSchedulesSpecifyTermForCalendarInputInput;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

/** Query root */
export type QueryWorkingSchedulesSpecifyTermForSummaryArgs = {
  input: WorkingSchedulesSpecifyTermInputInput;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type RangeFreeLicenceCoupon = {
  __typename?: 'RangeFreeLicenceCoupon';
  freeEndLicenceCount: Scalars['Int'];
  freeStartLicenceCount: Scalars['Int'];
  id: Scalars['ID'];
};

export type RegisterCalendarEventInputInput = {
  calendarType: ThirdPartyCalendarType;
  /** ISO-8601 */
  endDateTime: Scalars['DateTime'];
  eventName: Scalars['String'];
  hideEventName: Scalars['Boolean'];
  memo?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  startDateTime: Scalars['DateTime'];
  targetMemberId?: Maybe<Scalars['String']>;
  thirdPartyCalendarEventId: Scalars['String'];
  thirdPartyCalendarId: Scalars['String'];
};

export type RegisterCalendarEventWithTaskInputInput = {
  calendarEvent: RegisterCalendarEventInputInput;
  task: RegisterTaskInputInput;
};

export type RegisterClientInputInput = {
  code?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type RegisterCreditCardInputInput = {
  paymentMethodId: Scalars['String'];
  versionNo: Scalars['Int'];
};

export type RegisterCsvReportItemDefinitionInputInput = {
  name: Scalars['String'];
};

export type RegisterGoogleCalendarEventInputInput = {
  attendeeMailAddresses: Array<Maybe<Scalars['String']>>;
  calendarId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  end: Scalars['DateTime'];
  location?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  start: Scalars['DateTime'];
  title?: Maybe<Scalars['String']>;
};

export type RegisterLaborCostInputInput = {
  hourlyWage: Scalars['Int'];
  memberId: Scalars['String'];
  /** ISO-8601 */
  startDateTime: Scalars['DateTime'];
};

export type RegisterMeterdRateContractInputInput = {
  hourlyUnitPrice: Scalars['Int'];
  memberId?: Maybe<Scalars['String']>;
  projectContractId: Scalars['String'];
};

export type RegisterProjectContractInputInput = {
  contractTermType: ProjectContractTermType;
  contractType: ProjectContractType;
  copyPreviousContractMemberUnitPrice?: Maybe<Scalars['Boolean']>;
  costBudgetWithoutTax?: Maybe<Scalars['BigInteger']>;
  fixSalesAmountWithoutTax?: Maybe<Scalars['BigInteger']>;
  meterdRateAllMemberHourlyUnitPrice?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  projectId: Scalars['String'];
  roundTimeType: ProjectContractRoundTimeType;
  salesRoundMinutes?: Maybe<Scalars['Int']>;
  settlementRangeAllMemberUnitPrice?: Maybe<SettlementRangeAllMemberPartInputInput>;
  taxRate: Scalars['Int'];
  timeZoneId: Scalars['String'];
  unitPriceType: ProjectContractUnitPriceType;
  year?: Maybe<Scalars['Int']>;
};

export type RegisterProjectCustomAttributeInputInput = {
  projectCustomAttributeMasterId: Scalars['String'];
  projectCustomAttributeMasterListItemId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type RegisterProjectCustomAttributeMasterInputInput = {
  listItems: Array<Scalars['String']>;
  name: Scalars['String'];
  required: Scalars['Boolean'];
  type: CustomerAttributeType;
};

export type RegisterProjectCustomAttributeMasterListItemInputInput = {
  value: Scalars['String'];
};

export type RegisterProjectInputInput = {
  assigneeIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  estimateTimeSec?: Maybe<Scalars['Int']>;
  favorite?: Maybe<Scalars['Boolean']>;
  key?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  projectStatusId?: Maybe<Scalars['String']>;
  projectTemplateId?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  scheduledEndDateTime?: Maybe<Scalars['DateTime']>;
  /** ISO-8601 */
  scheduledStartDateTime?: Maybe<Scalars['DateTime']>;
};

export type RegisterProjectStatusInputInput = {
  name: Scalars['String'];
};

export type RegisterProjectTemplateCustomAttributeInputInput = {
  projectCustomAttributeMasterId: Scalars['String'];
  projectCustomAttributeMasterListItemId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type RegisterProjectTemplateInputInput = {
  clientId?: Maybe<Scalars['String']>;
  estimateTimeSec?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
};

export type RegisterProjectTemplateSubTaskInputInput = {
  sortNo?: Maybe<Scalars['BigInteger']>;
  title: Scalars['String'];
};

export type RegisterProjectTemplateTaskCustomAttributeInputInput = {
  taskCustomAttributeMasterId: Scalars['String'];
  taskCustomAttributeMasterListItemId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type RegisterProjectTemplateTaskInputInput = {
  description?: Maybe<Scalars['String']>;
  estimateTimeSec?: Maybe<Scalars['Int']>;
  priority?: Maybe<Priority>;
  projectTemplateTaskStatusId?: Maybe<Scalars['String']>;
  sortNoInList?: Maybe<Scalars['BigInteger']>;
  sortNoInTaskStatus?: Maybe<Scalars['BigInteger']>;
  title: Scalars['String'];
};

export type RegisterProjectTemplateTaskRemainderInputInput = {
  beforeDeadlineMinutes: Scalars['Int'];
  targetMemberId?: Maybe<Scalars['String']>;
  taskRemainderDestType: TaskRemainderDestType;
  taskRemainderType: TaskRemainderType;
};

export type RegisterProjectTemplateTaskStatusInputInput = {
  name: Scalars['String'];
};

export type RegisterProjectTemplateTasksInputInput = {
  tasks: Array<Maybe<RegisterProjectTemplateTaskInputInput>>;
};

export type RegisterPushNotificationDeviceInputInput = {
  token: Scalars['String'];
};

export type RegisterSettlementRangeContractInputInput = {
  deductionHourlyUnitPrice: Scalars['Int'];
  excessHourlyUnitPrice: Scalars['Int'];
  lowerTimeHour: Scalars['Int'];
  memberId?: Maybe<Scalars['String']>;
  monthlyUnitPrice: Scalars['Int'];
  projectContractId: Scalars['String'];
  upperTimeHour: Scalars['Int'];
};

export type RegisterSubTaskInputInput = {
  sortNo?: Maybe<Scalars['BigInteger']>;
  title: Scalars['String'];
};

export type RegisterTagInputInput = {
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
};

export type RegisterTaskCustomAttributeInputInput = {
  taskCustomAttributeMasterId: Scalars['String'];
  taskCustomAttributeMasterListItemId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type RegisterTaskCustomAttributeMasterInputInput = {
  listItems: Array<Scalars['String']>;
  name: Scalars['String'];
  required: Scalars['Boolean'];
  type: CustomerAttributeType;
};

export type RegisterTaskCustomAttributeMasterListItemInputInput = {
  value: Scalars['String'];
};

export type RegisterTaskInputInput = {
  assigneeIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  asssigneeId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  estimateTimeSec?: Maybe<Scalars['Int']>;
  newTagNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  priority?: Maybe<Priority>;
  /** ISO-8601 */
  scheduledEndDateTime?: Maybe<Scalars['DateTime']>;
  /** ISO-8601 */
  scheduledStartDateTime?: Maybe<Scalars['DateTime']>;
  sortNoInGanttChart?: Maybe<Scalars['BigInteger']>;
  sortNoInList?: Maybe<Scalars['BigInteger']>;
  sortNoInTaskStatus?: Maybe<Scalars['BigInteger']>;
  tagIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  taskStatusId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type RegisterTaskRemainderInputInput = {
  beforeDeadlineMinutes: Scalars['Int'];
  targetMemberId?: Maybe<Scalars['String']>;
  taskRemainderDestType: TaskRemainderDestType;
  taskRemainderType: TaskRemainderType;
};

export type RegisterTaskStatusInputInput = {
  name: Scalars['String'];
};

export type RegisterTasksInputInput = {
  tasks: Array<Maybe<RegisterTaskInputInput>>;
};

export type RegisterTeamInputInput = {
  name: Scalars['String'];
};

export type RegisterWorkingHistoriesFromGoogleCallendarEventsInputInput = {
  calendarIds: Array<Maybe<Scalars['String']>>;
  /** ISO-8601 */
  end: Scalars['DateTime'];
  /** ISO-8601 */
  start: Scalars['DateTime'];
};

export type RegisterWorkingHistoryInputInput = {
  /** ISO-8601 */
  end: Scalars['DateTime'];
  memo?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  start: Scalars['DateTime'];
  targetMemberId?: Maybe<Scalars['String']>;
};

export type RegisterWorkingHistoryWithTaskInputInput = {
  task: RegisterTaskInputInput;
  workingHistory: RegisterWorkingHistoryInputInput;
};

export type RegisterWorkingScheduleInputInput = {
  /** ISO-8601 */
  end: Scalars['DateTime'];
  memo?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  start: Scalars['DateTime'];
  targetMemberId?: Maybe<Scalars['String']>;
};

export type RegisterWorkingScheduleWithTaskInputInput = {
  task: RegisterTaskInputInput;
  workingSchedule: RegisterWorkingScheduleInputInput;
};

export type RegisterWorkingSchedulesFromGoogleCallendarEventsInputInput = {
  calendarIds: Array<Maybe<Scalars['String']>>;
  /** ISO-8601 */
  end: Scalars['DateTime'];
  /** ISO-8601 */
  start: Scalars['DateTime'];
};

export type RelateTaskTagsInputInput = {
  newTagNames: Array<Maybe<Scalars['String']>>;
  tagIds: Array<Maybe<Scalars['String']>>;
};

export type RemoveMemberToTeamInputInput = {
  memberId: Scalars['String'];
  teamId: Scalars['String'];
};

export enum ReportItem {
  ProjectAssignee = 'ProjectAssignee',
  ProjectClientCode = 'ProjectClientCode',
  ProjectClientName = 'ProjectClientName',
  ProjectCustomAttribute = 'ProjectCustomAttribute',
  ProjectEstimateEndDate = 'ProjectEstimateEndDate',
  ProjectEstimateStartDate = 'ProjectEstimateStartDate',
  ProjectEstimateTimeMin = 'ProjectEstimateTimeMin',
  ProjectKey = 'ProjectKey',
  ProjectName = 'ProjectName',
  TaskActualStartDatetime = 'TaskActualStartDatetime',
  TaskAssignee = 'TaskAssignee',
  TaskComplateDatetime = 'TaskComplateDatetime',
  TaskCustomAttribute = 'TaskCustomAttribute',
  TaskEstimateEndDate = 'TaskEstimateEndDate',
  TaskEstimateStartDate = 'TaskEstimateStartDate',
  TaskEstimateWorkingTimeMin = 'TaskEstimateWorkingTimeMin',
  TaskIdeaProgressRate = 'TaskIdeaProgressRate',
  TaskPredicionRemainWorkingTimeMin = 'TaskPredicionRemainWorkingTimeMin',
  TaskPredicionTotalWorkingTimeMin = 'TaskPredicionTotalWorkingTimeMin',
  TaskPriority = 'TaskPriority',
  TaskProgressAnalysis = 'TaskProgressAnalysis',
  TaskProgressRate = 'TaskProgressRate',
  TaskRemainWorkingTimeMin = 'TaskRemainWorkingTimeMin',
  TaskStatus = 'TaskStatus',
  TaskTags = 'TaskTags',
  TaskTitle = 'TaskTitle',
  TaskTotalWorkingTimeMin = 'TaskTotalWorkingTimeMin',
  TaskType = 'TaskType',
  TeamName = 'TeamName',
  WorkingHistoryEndDatetime = 'WorkingHistoryEndDatetime',
  WorkingHistoryLaborCost = 'WorkingHistoryLaborCost',
  WorkingHistoryMember = 'WorkingHistoryMember',
  WorkingHistoryMemberDepartment = 'WorkingHistoryMemberDepartment',
  WorkingHistoryMemberEmployeeNumber = 'WorkingHistoryMemberEmployeeNumber',
  WorkingHistoryMemo = 'WorkingHistoryMemo',
  WorkingHistoryStartDatetime = 'WorkingHistoryStartDatetime',
  WorkingHistoryTimeMin = 'WorkingHistoryTimeMin',
  WorkingHistoryTimeSec = 'WorkingHistoryTimeSec',
}

export enum ReportType {
  WorkingHistory = 'WorkingHistory',
  WorkingSchedule = 'WorkingSchedule',
}

export type RequestExportClientExcelInputInput = {
  timeZoneOffset: Scalars['String'];
};

export type RequestExportMemberExcelInputInput = {
  timeZoneOffset: Scalars['String'];
};

export type RequestExportProjectExcelInputInput = {
  includeCompleteProject: Scalars['Boolean'];
  teamId?: Maybe<Scalars['String']>;
  timeZoneOffset: Scalars['String'];
};

export type RequestExportTaskExcelInputInput = {
  includeCompleteTask: Scalars['Boolean'];
  teamId?: Maybe<Scalars['String']>;
  timeZoneOffset: Scalars['String'];
};

export type RequestMyCsvReportInputInput = {
  /** ISO-8601 */
  end: Scalars['DateTime'];
  includeWorkingData: Scalars['Boolean'];
  reportItemPrintDefinitionId?: Maybe<Scalars['String']>;
  reportType: ReportType;
  /** ISO-8601 */
  start: Scalars['DateTime'];
  timeZoneOffset: Scalars['String'];
};

export type RequestMyReportInputInput = {
  /** ISO-8601 */
  end: Scalars['DateTime'];
  includeWorkingData: Scalars['Boolean'];
  reportType: ReportType;
  /** ISO-8601 */
  start: Scalars['DateTime'];
  timeZoneOffset: Scalars['String'];
};

export type RequestOrganizationCsvReportInputInput = {
  /** ISO-8601 */
  end: Scalars['DateTime'];
  includeWorkingData: Scalars['Boolean'];
  reportItemPrintDefinitionId?: Maybe<Scalars['String']>;
  reportType: ReportType;
  /** ISO-8601 */
  start: Scalars['DateTime'];
  timeZoneOffset: Scalars['String'];
};

export type RequestOrganizationReportInputInput = {
  /** ISO-8601 */
  end: Scalars['DateTime'];
  includeWorkingData: Scalars['Boolean'];
  reportType: ReportType;
  /** ISO-8601 */
  start: Scalars['DateTime'];
  timeZoneOffset: Scalars['String'];
};

export type RequestPasswordResetInputInput = {
  /** メールアドレス */
  mailAddress: Scalars['String'];
};

export type RequestTeamCsvReportInputInput = {
  /** ISO-8601 */
  end: Scalars['DateTime'];
  includeWorkingData: Scalars['Boolean'];
  reportItemPrintDefinitionId?: Maybe<Scalars['String']>;
  reportType: ReportType;
  /** ISO-8601 */
  start: Scalars['DateTime'];
  teamId: Scalars['String'];
  timeZoneOffset: Scalars['String'];
};

export type RequestTeamReportInputInput = {
  /** ISO-8601 */
  end: Scalars['DateTime'];
  includeWorkingData: Scalars['Boolean'];
  reportType: ReportType;
  /** ISO-8601 */
  start: Scalars['DateTime'];
  teamId: Scalars['String'];
  timeZoneOffset: Scalars['String'];
};

export type ResetPasswordInputInput = {
  key: Scalars['String'];
  /** パスワード(8〜200文字) */
  password: Scalars['String'];
};

export type SearchFavoriteTaskInputDateTimeFromToInput = {
  /** ISO-8601 */
  from?: Maybe<Scalars['DateTime']>;
  /** ISO-8601 */
  to?: Maybe<Scalars['DateTime']>;
};

export type SearchFavoriteTaskInputInput = {
  assignedMemberIdList?: Maybe<Array<Maybe<Scalars['String']>>>;
  completeCondition: FavoriteTaskCompleteFilter;
  deviationRate?: Maybe<SearchFavoriteTaskInputIntegerFromToInput>;
  estimateWorkingTimeSec?: Maybe<SearchFavoriteTaskInputIntegerFromToInput>;
  favorite?: Maybe<Scalars['Boolean']>;
  projectIdList?: Maybe<Array<Maybe<Scalars['String']>>>;
  scheduledEndDateTime?: Maybe<SearchFavoriteTaskInputDateTimeFromToInput>;
  scheduledStartDateTime?: Maybe<SearchFavoriteTaskInputDateTimeFromToInput>;
  sortKey?: Maybe<FavoriteTaskSortKey>;
  sortOrder?: Maybe<SortOrder>;
  taskStatusIdList?: Maybe<Array<Maybe<Scalars['String']>>>;
  teamIdList?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  workingTimeSec?: Maybe<SearchFavoriteTaskInputIntegerFromToInput>;
};

export type SearchFavoriteTaskInputIntegerFromToInput = {
  from?: Maybe<Scalars['Int']>;
  to?: Maybe<Scalars['Int']>;
};

export type SearchGraphTarget = {
  __typename?: 'SearchGraphTarget';
  clients: Array<Maybe<SearchGraphTargetClient>>;
  members: Array<Maybe<SearchGraphTargetMember>>;
  projects: Array<Maybe<SearchGraphTargetProject>>;
  teams: Array<Maybe<SearchGraphTargetTeam>>;
};

export type SearchGraphTargetClient = {
  __typename?: 'SearchGraphTargetClient';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type SearchGraphTargetInputInput = {
  clientIds: Array<Maybe<Scalars['String']>>;
  /** ISO-8601 */
  end: Scalars['DateTime'];
  memberId?: Maybe<Scalars['String']>;
  memberIds: Array<Maybe<Scalars['String']>>;
  organizationId: Scalars['String'];
  projectIds: Array<Maybe<Scalars['String']>>;
  reportType: ReportType;
  /** ISO-8601 */
  start: Scalars['DateTime'];
  teamId?: Maybe<Scalars['String']>;
  teamIds: Array<Maybe<Scalars['String']>>;
  timeZoneOffset: Scalars['String'];
};

export type SearchGraphTargetMember = {
  __typename?: 'SearchGraphTargetMember';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type SearchGraphTargetProject = {
  __typename?: 'SearchGraphTargetProject';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type SearchGraphTargetTeam = {
  __typename?: 'SearchGraphTargetTeam';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type SearchTaskInputDateTimeFromToInput = {
  /** ISO-8601 */
  from?: Maybe<Scalars['DateTime']>;
  /** ISO-8601 */
  to?: Maybe<Scalars['DateTime']>;
};

export type SearchTaskInputInput = {
  assignedMemberIdList?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientIdList?: Maybe<Array<Maybe<Scalars['String']>>>;
  completeCondition: TaskCompleteFilter;
  deviationRate?: Maybe<SearchTaskInputIntegerFromToInput>;
  estimateWorkingTimeSec?: Maybe<SearchTaskInputIntegerFromToInput>;
  projectIdList?: Maybe<Array<Maybe<Scalars['String']>>>;
  scheduledEndDateTime?: Maybe<SearchTaskInputDateTimeFromToInput>;
  scheduledStartDateTime?: Maybe<SearchTaskInputDateTimeFromToInput>;
  sortKey?: Maybe<SortKey>;
  sortOrder?: Maybe<SortOrder>;
  taskStatusIdList?: Maybe<Array<Maybe<Scalars['String']>>>;
  teamIdList?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  workingTimeSec?: Maybe<SearchTaskInputIntegerFromToInput>;
};

export type SearchTaskInputIntegerFromToInput = {
  from?: Maybe<Scalars['Int']>;
  to?: Maybe<Scalars['Int']>;
};

export type SettlementRangeAllMemberPartInputInput = {
  deductionHourlyUnitPrice: Scalars['Int'];
  excessHourlyUnitPrice: Scalars['Int'];
  lowerTimeHour: Scalars['Int'];
  monthlyUnitPrice: Scalars['Int'];
  upperTimeHour: Scalars['Int'];
};

export type SettlementRangeContract = {
  __typename?: 'SettlementRangeContract';
  id?: Maybe<Scalars['ID']>;
  lowerHourPart: LowerPart;
  member?: Maybe<SettlementRangeContractMemberPart>;
  monthlyUnitPrice: Scalars['Int'];
  upperHourPart: UpperPart;
  versionNo: Scalars['Int'];
};

export type SettlementRangeContractMemberPart = {
  __typename?: 'SettlementRangeContractMemberPart';
  laborCostWithoutTax: Scalars['BigInteger'];
  memberId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  profileImageUrl?: Maybe<Scalars['String']>;
  roundedTotalWorkingTimeSec: Scalars['Int'];
  salesAmountWithoutTax: Scalars['BigInteger'];
  totalWorkingTimeSec: Scalars['Int'];
};

export type SignupWithAppleAccountInputInput = {
  appleAuthCode: Scalars['String'];
  appleToken: Scalars['String'];
  appleUserId: Scalars['String'];
  /** ライセンス数 */
  licenceCount: Scalars['Int'];
  /** 氏名(100文字以内) */
  name: Scalars['String'];
  /** 組織名(100文字以内) */
  organizationName: Scalars['String'];
  /** 契約プラン */
  planCode: Plan;
  /** プロフィール画像（base64エンコード） */
  profileImageBase64?: Maybe<Scalars['String']>;
  /** 所属部署・チーム名(100文字以内) */
  teamName?: Maybe<Scalars['String']>;
};

export type SignupWithGoogleAccountInputInput = {
  /** Googleアカウントの認証コード */
  googleAuthCode: Scalars['String'];
  /** ライセンス数 */
  licenceCount: Scalars['Int'];
  /** 氏名(100文字以内) */
  name: Scalars['String'];
  /** 組織名(100文字以内) */
  organizationName: Scalars['String'];
  /** 契約プラン */
  planCode: Plan;
  /** プロフィール画像（base64エンコード） */
  profileImageBase64?: Maybe<Scalars['String']>;
  /** 所属部署・チーム名(100文字以内) */
  teamName?: Maybe<Scalars['String']>;
};

export type SignupWithMailAddressInputInput = {
  key: Scalars['String'];
  /** ライセンス数 */
  licenceCount: Scalars['Int'];
  /** 氏名(100文字以内) */
  name: Scalars['String'];
  /** 組織名(100文字以内) */
  organizationName: Scalars['String'];
  /** パスワード(8〜200文字) */
  password: Scalars['String'];
  /** 契約プラン */
  planCode: Plan;
  /** プロフィール画像（base64エンコード） */
  profileImageBase64?: Maybe<Scalars['String']>;
  /** 所属部署・チーム名(100文字以内) */
  teamName?: Maybe<Scalars['String']>;
};

export enum SortKey {
  Assigner = 'Assigner',
  ClientName = 'ClientName',
  DeviationRate = 'DeviationRate',
  EstimateWorkingTimeSec = 'EstimateWorkingTimeSec',
  Priority = 'Priority',
  ProgressRate = 'ProgressRate',
  ProjectName = 'ProjectName',
  ScheduledEndDate = 'ScheduledEndDate',
  ScheduledStartDate = 'ScheduledStartDate',
  SortNoInGanttChart = 'SortNoInGanttChart',
  SortNoInList = 'SortNoInList',
  SortNoInTaskAssignee = 'SortNoInTaskAssignee',
  TeamName = 'TeamName',
  Title = 'Title',
  WorkingTimeSec = 'WorkingTimeSec',
}

export enum SortOrder {
  Asc = 'Asc',
  Desc = 'Desc',
}

export type StartTaskInputInput = {
  versionNo: Scalars['Int'];
  workingHistoryMemo?: Maybe<Scalars['String']>;
};

export type StartTaskResult = {
  __typename?: 'StartTaskResult';
  startTask: Task;
  startWorkingHistory: WorkingHistory;
  stopTask?: Maybe<Task>;
  stopWorkingHistory?: Maybe<WorkingHistory>;
};

export type StopTaskInputInput = {
  versionNo: Scalars['Int'];
  workingMemberId?: Maybe<Scalars['String']>;
};

export type StopTaskResult = {
  __typename?: 'StopTaskResult';
  stopTask: Task;
  stopWorkingHistory: WorkingHistory;
  stopWorkingHistoryCalendar: CalendarWorkingHistory;
  stopWorkingHistorySummary: WorkingHistoryForSummary;
};

export type SubTask = {
  __typename?: 'SubTask';
  complete: Scalars['Boolean'];
  /** ISO-8601 */
  completeDateTime?: Maybe<Scalars['DateTime']>;
  createdMember: Member;
  id?: Maybe<Scalars['ID']>;
  sortNo: Scalars['BigInteger'];
  task: Task;
  title: Scalars['String'];
  versionNo: Scalars['Int'];
};

export enum SunburstGraphItemType {
  CalendarEvent = 'CalendarEvent',
  Dummy = 'DUMMY',
  Task = 'TASK',
}

export type Tag = {
  __typename?: 'Tag';
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  organization: Organization;
  /** 関連する全てのタスクの数。ログインユーザーが見ることのできないタスクもカウントしている。 */
  relatedTaskCount: Scalars['Int'];
  sortNo: Scalars['BigInteger'];
  versionNo: Scalars['Int'];
};

export enum TagSortKey {
  Name = 'Name',
  SortNo = 'SortNo',
}

export type Task = {
  __typename?: 'Task';
  assignees: Array<AssignedTaskMemberInfo>;
  asssignee?: Maybe<Member>;
  calendarLinkageCode: Scalars['String'];
  /** ISO-8601 */
  completeDateTime?: Maybe<Scalars['DateTime']>;
  createdMember: Member;
  description?: Maybe<Scalars['String']>;
  estimateTimeSec?: Maybe<Scalars['Int']>;
  /** 予想残作業時間 */
  estimatedRemainingTimeSec?: Maybe<Scalars['BigInteger']>;
  favoriteMembers: Array<Maybe<Member>>;
  favoriteMembersInfo: Array<Maybe<FavoriteTaskMemberInfo>>;
  id?: Maybe<Scalars['ID']>;
  laborCost?: Maybe<Scalars['BigInteger']>;
  priority?: Maybe<Priority>;
  progressDeviationRate?: Maybe<Scalars['Int']>;
  progressRate: Scalars['Int'];
  project: Project;
  /** ISO-8601 */
  registerDateTime: Scalars['DateTime'];
  /** 残作業時間 */
  remainingTimeSec?: Maybe<Scalars['BigInteger']>;
  scheduleWorkingTimeSec?: Maybe<Scalars['BigInteger']>;
  /** ISO-8601 */
  scheduledEndDateTime?: Maybe<Scalars['DateTime']>;
  /** ISO-8601 */
  scheduledStartDateTime?: Maybe<Scalars['DateTime']>;
  sortNoInGanttChart: Scalars['BigInteger'];
  sortNoInList: Scalars['BigInteger'];
  sortNoInMyTask?: Maybe<Scalars['BigInteger']>;
  sortNoInTaskStatus: Scalars['BigInteger'];
  taskStatus: TaskStatus;
  title: Scalars['String'];
  versionNo: Scalars['Int'];
  workingMembers: Array<Maybe<WorkingMemberInfo>>;
  /** 合計作業時間。ただし、作業中の作業履歴分は集計対象外である。作業中のものも含めた合計作業時間は、lastWorkingStartDateTimeを使って計算する */
  workingTimeSec: Scalars['BigInteger'];
};

export type TaskAssignNotification = {
  __typename?: 'TaskAssignNotification';
  id: Scalars['ID'];
  projectId: Scalars['String'];
  sendMember: Member;
  task: Task;
  teamId: Scalars['String'];
};

export type TaskAssignNotificationV2 = {
  __typename?: 'TaskAssignNotificationV2';
  id: Scalars['ID'];
  projectId: Scalars['String'];
  projectName: Scalars['String'];
  sendMember: NotificationV2MemberPart;
  taskId: Scalars['String'];
  taskTitle: Scalars['String'];
  teamId: Scalars['String'];
  teamName: Scalars['String'];
};

export type TaskBarGraphData = {
  __typename?: 'TaskBarGraphData';
  color: Scalars['String'];
  itemType: TaskBarGraphItemType;
  percent: Scalars['Float'];
  projectId: Scalars['String'];
  projectName: Scalars['String'];
  reportType: ReportType;
  taskId: Scalars['String'];
  taskTitle: Scalars['String'];
  teamId: Scalars['String'];
  teamName: Scalars['String'];
  workingTimeSec: Scalars['BigInteger'];
};

export enum TaskBarGraphItemType {
  CalendarEvent = 'CalendarEvent',
  Dummy = 'DUMMY',
  Task = 'TASK',
}

export type TaskComment = {
  __typename?: 'TaskComment';
  comment: Scalars['String'];
  /** ISO-8601 */
  commentedDateTime: Scalars['DateTime'];
  createdMember: Member;
  id?: Maybe<Scalars['ID']>;
  projectId: Scalars['String'];
  projectName: Scalars['String'];
  task?: Maybe<Task>;
  taskId: Scalars['String'];
  taskTitle: Scalars['String'];
  teamId: Scalars['String'];
  teamName: Scalars['String'];
  versionNo: Scalars['Int'];
};

export type TaskCommentMin = {
  __typename?: 'TaskCommentMin';
  comment: Scalars['String'];
  /** ISO-8601 */
  commentedDateTime: Scalars['DateTime'];
  createdMember: TaskCommentMinMemberPart;
  id?: Maybe<Scalars['ID']>;
  versionNo: Scalars['Int'];
};

export type TaskCommentMinMemberPart = {
  __typename?: 'TaskCommentMinMemberPart';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  profileImageUrl?: Maybe<Scalars['String']>;
};

export enum TaskCompleteFilter {
  Both = 'Both',
  Complete = 'Complete',
  Incomplete = 'Incomplete',
}

export type TaskCustomAttribute = {
  __typename?: 'TaskCustomAttribute';
  id?: Maybe<Scalars['ID']>;
  listItem?: Maybe<TaskCustomAttributeMasterListItem>;
  master: TaskCustomAttributeMaster;
  sortNo: Scalars['BigInteger'];
  value?: Maybe<Scalars['String']>;
};

export type TaskCustomAttributeMaster = {
  __typename?: 'TaskCustomAttributeMaster';
  id?: Maybe<Scalars['ID']>;
  listItem: Array<Maybe<TaskCustomAttributeMasterListItem>>;
  name: Scalars['String'];
  required: Scalars['Boolean'];
  sortNo: Scalars['BigInteger'];
  type: CustomerAttributeType;
  versionNo: Scalars['Int'];
};

export type TaskCustomAttributeMasterListItem = {
  __typename?: 'TaskCustomAttributeMasterListItem';
  id?: Maybe<Scalars['ID']>;
  sortNo: Scalars['BigInteger'];
  value: Scalars['String'];
  versionNo: Scalars['Int'];
};

export type TaskCustomAttributeType = {
  __typename?: 'TaskCustomAttributeType';
  code?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  sortNo?: Maybe<Scalars['BigInteger']>;
};

export type TaskDateGraphData = {
  __typename?: 'TaskDateGraphData';
  /** ISO-8601 */
  date: Scalars['Date'];
  items: Array<Maybe<TaskDateGraphDataDetail>>;
  reportType: ReportType;
};

export type TaskDateGraphDataDetail = {
  __typename?: 'TaskDateGraphDataDetail';
  color: Scalars['String'];
  itemType: TaskDateGraphItemType;
  percent: Scalars['Float'];
  projectId: Scalars['String'];
  projectName: Scalars['String'];
  taskId: Scalars['String'];
  taskTitle: Scalars['String'];
  teamId: Scalars['String'];
  teamName: Scalars['String'];
  workingTimeSec: Scalars['BigInteger'];
};

export enum TaskDateGraphItemType {
  CalendarEvent = 'CalendarEvent',
  Dummy = 'DUMMY',
  Task = 'TASK',
}

export type TaskForSelector = {
  __typename?: 'TaskForSelector';
  /** ISO-8601 */
  completeDateTime?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  projectId?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type TaskGraphDataInputInput = {
  clientIds: Array<Maybe<Scalars['String']>>;
  /** ISO-8601 */
  end: Scalars['DateTime'];
  memberId?: Maybe<Scalars['String']>;
  memberIds: Array<Maybe<Scalars['String']>>;
  organizationId: Scalars['String'];
  projectIds: Array<Maybe<Scalars['String']>>;
  reportType: ReportType;
  /** ISO-8601 */
  start: Scalars['DateTime'];
  teamId?: Maybe<Scalars['String']>;
  teamIds: Array<Maybe<Scalars['String']>>;
  timeZoneOffset: Scalars['String'];
};

export type TaskRemainder = {
  __typename?: 'TaskRemainder';
  beforeDeadlineMinutes: Scalars['Int'];
  id?: Maybe<Scalars['ID']>;
  notificationSentFlg: Scalars['Boolean'];
  targetMember?: Maybe<Member>;
  task: Task;
  taskRemainderDestType: TaskRemainderDestType;
  taskRemainderType: TaskRemainderType;
};

export enum TaskRemainderDestType {
  AssignedMember = 'AssignedMember',
  SpecifiedMember = 'SpecifiedMember',
  TeamMember = 'TeamMember',
}

export type TaskRemainderNotification = {
  __typename?: 'TaskRemainderNotification';
  beforeDeadlineMinutes: Scalars['Int'];
  id: Scalars['ID'];
  task: Task;
  taskRemainderType: TaskRemainderType;
};

export type TaskRemainderNotificationV2 = {
  __typename?: 'TaskRemainderNotificationV2';
  id: Scalars['ID'];
  projectId: Scalars['String'];
  projectName: Scalars['String'];
  /** ISO-8601 */
  scheduledEndDateTime: Scalars['DateTime'];
  taskId: Scalars['String'];
  taskRemainderType: TaskRemainderType;
  taskTitle: Scalars['String'];
  teamId: Scalars['String'];
  teamName: Scalars['String'];
};

export enum TaskRemainderType {
  DeadlineNear = 'DeadlineNear',
  DeadlinePassed = 'DeadlinePassed',
}

export enum TaskSortKey {
  ProjectSortNo = 'projectSortNo',
  Title = 'title',
}

export type TaskStatus = {
  __typename?: 'TaskStatus';
  endStatus: Scalars['Boolean'];
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  project: Project;
  sortNo: Scalars['BigInteger'];
  versionNo: Scalars['Int'];
};

export type TaskStatusWithTask = {
  __typename?: 'TaskStatusWithTask';
  taskStatus: TaskStatus;
  tasks: Array<Maybe<Task>>;
};

export type TaskSunburstGraphData = {
  __typename?: 'TaskSunburstGraphData';
  children: Array<Maybe<TaskSunburstGraphProjectData>>;
  color: Scalars['String'];
  name: Scalars['String'];
  reportType: ReportType;
  teamId: Scalars['String'];
};

export type TaskSunburstGraphProjectData = {
  __typename?: 'TaskSunburstGraphProjectData';
  children: Array<Maybe<TaskSunburstGraphTaskData>>;
  color: Scalars['String'];
  name: Scalars['String'];
  projectId: Scalars['String'];
};

export type TaskSunburstGraphTaskData = {
  __typename?: 'TaskSunburstGraphTaskData';
  color: Scalars['String'];
  itemType: SunburstGraphItemType;
  name: Scalars['String'];
  percent: Scalars['Float'];
  taskId: Scalars['String'];
  workingTimeSec: Scalars['BigInteger'];
};

export type Team = {
  __typename?: 'Team';
  archived: Scalars['Boolean'];
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  organization: Organization;
  personalTeam: Scalars['Boolean'];
  status: TeamStatus;
  versionNo: Scalars['Int'];
};

export type TeamBarGraphData = {
  __typename?: 'TeamBarGraphData';
  color: Scalars['String'];
  percent: Scalars['Float'];
  reportType: ReportType;
  teamId: Scalars['String'];
  teamName: Scalars['String'];
  workingTimeSec: Scalars['BigInteger'];
};

export type TeamCloseInputInput = {
  closingType: TeamClosingType;
  /** ISO-8601 */
  targetDateTime?: Maybe<Scalars['DateTime']>;
  teamId: Scalars['String'];
  timeZoneId: Scalars['String'];
  versionNo: Scalars['Int'];
};

export type TeamClosingSetting = {
  __typename?: 'TeamClosingSetting';
  closingType: TeamClosingType;
  id: Scalars['ID'];
  /** ISO-8601 */
  targetDateTime?: Maybe<Scalars['DateTime']>;
  teamId: Scalars['String'];
  timeZone: TimeZone;
  versionNo: Scalars['Int'];
};

export enum TeamClosingType {
  ApplyOrganizationSetting = 'ApplyOrganizationSetting',
  ApplyTeamSetting = 'ApplyTeamSetting',
}

export type TeamCsvReport = {
  __typename?: 'TeamCsvReport';
  csvReportItemDefinition?: Maybe<CsvReportItemDefinition>;
  /** ISO-8601 */
  end: Scalars['DateTime'];
  id: Scalars['ID'];
  includeWorkingData: Scalars['Boolean'];
  reportType: ReportType;
  /** ISO-8601 */
  requestDateTime: Scalars['DateTime'];
  requestMember: Member;
  sjisFileUrl?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  start: Scalars['DateTime'];
  status: JobProgressStatus;
  team: Team;
  timeZoneOffset: Scalars['String'];
  utf8FileUrl?: Maybe<Scalars['String']>;
  versionNo: Scalars['Int'];
};

export type TeamDateGraphData = {
  __typename?: 'TeamDateGraphData';
  /** ISO-8601 */
  date: Scalars['Date'];
  items: Array<Maybe<TeamDateGraphDataDetail>>;
  reportType: ReportType;
};

export type TeamDateGraphDataDetail = {
  __typename?: 'TeamDateGraphDataDetail';
  color: Scalars['String'];
  percent: Scalars['Float'];
  teamId: Scalars['String'];
  teamName: Scalars['String'];
  workingTimeSec: Scalars['BigInteger'];
};

export type TeamMemberRelation = {
  __typename?: 'TeamMemberRelation';
  id?: Maybe<Scalars['ID']>;
  openMenu: Scalars['Boolean'];
  sortNo: Scalars['BigInteger'];
  team: Team;
  versionNo: Scalars['Int'];
};

export type TeamReport = {
  __typename?: 'TeamReport';
  /** ISO-8601 */
  end: Scalars['DateTime'];
  id: Scalars['ID'];
  includeWorkingData: Scalars['Boolean'];
  reportType: ReportType;
  /** ISO-8601 */
  requestDateTime: Scalars['DateTime'];
  requestMember: Member;
  /** ISO-8601 */
  start: Scalars['DateTime'];
  status: JobProgressStatus;
  team: Team;
  timeZoneOffset: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  versionNo: Scalars['Int'];
};

export enum TeamStatus {
  Active = 'Active',
  Archived = 'Archived',
}

export type TeamSunburstGraphData = {
  __typename?: 'TeamSunburstGraphData';
  color: Scalars['String'];
  name: Scalars['String'];
  percent: Scalars['Float'];
  reportType: ReportType;
  teamId: Scalars['String'];
  workingTimeSec: Scalars['BigInteger'];
};

export type TeamtGraphDataInputInput = {
  clientIds: Array<Maybe<Scalars['String']>>;
  /** ISO-8601 */
  end: Scalars['DateTime'];
  memberId?: Maybe<Scalars['String']>;
  memberIds: Array<Maybe<Scalars['String']>>;
  organizationId: Scalars['String'];
  projectIds: Array<Maybe<Scalars['String']>>;
  reportType: ReportType;
  /** ISO-8601 */
  start: Scalars['DateTime'];
  teamId?: Maybe<Scalars['String']>;
  teamIds: Array<Maybe<Scalars['String']>>;
  timeZoneOffset: Scalars['String'];
};

export type ThirdPartyCalendarInfo = {
  __typename?: 'ThirdPartyCalendarInfo';
  member: Member;
  thirdPartyCalendarId: Scalars['String'];
};

export enum ThirdPartyCalendarType {
  GoogleCalendar = 'GoogleCalendar',
}

export type TimeZone = {
  __typename?: 'TimeZone';
  code: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  offsetTime: Scalars['String'];
  sortNo: Scalars['Int'];
};

export type TwoFactorAuthSetUpInfo = {
  __typename?: 'TwoFactorAuthSetUpInfo';
  qrImageBase64: Scalars['String'];
  secret: Scalars['String'];
  verifyToken: Scalars['String'];
};

export type TwoFactorAuthSetting = {
  __typename?: 'TwoFactorAuthSetting';
  enabled: Scalars['Boolean'];
  recoveryCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TwoFactorLoginInputInput = {
  code: Scalars['String'];
  oneTimeToken: Scalars['String'];
};

export type TwoFactorRecoveryCodeLoginInputInput = {
  oneTimeToken: Scalars['String'];
  recoveryCode: Scalars['String'];
};

export type UnarchiveTeamInputInput = {
  versionNo: Scalars['Int'];
};

export type UpdateAccessAllowIpAddressInputInput = {
  ipAddresses: Array<Maybe<Scalars['String']>>;
  versionNo: Scalars['Int'];
};

export type UpdateAccessControlInputInput = {
  versionNo: Scalars['Int'];
};

export type UpdateBillToMailAddressInput = {
  billToMailAddress: Scalars['String'];
  versionNo: Scalars['Int'];
};

export type UpdateCalendarEventInputInput = {
  /** ISO-8601 */
  endDateTime: Scalars['DateTime'];
  eventName: Scalars['String'];
  /** ISO-8601 */
  startDateTime: Scalars['DateTime'];
  versionNo: Scalars['Int'];
};

export type UpdateClientInputInput = {
  code?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  versionNo: Scalars['Int'];
};

export type UpdateClientSortNoInputInput = {
  sortNo: Scalars['BigInteger'];
  versionNo: Scalars['Int'];
};

export type UpdateCsvDefinitionDetailSortNoInputInput = {
  detailId: Scalars['ID'];
  sortNo: Scalars['BigInteger'];
  versionNo: Scalars['Int'];
};

export type UpdateCsvReportItemDefinitionInputInput = {
  name: Scalars['String'];
  versionNo: Scalars['Int'];
};

export type UpdateGoogleCalendarEventInputInput = {
  attendeeMailAddresses: Array<Maybe<Scalars['String']>>;
  calendarId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  end: Scalars['DateTime'];
  location?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  start: Scalars['DateTime'];
  title?: Maybe<Scalars['String']>;
};

export type UpdateLaborCostInputInput = {
  hourlyWage: Scalars['Int'];
  /** ISO-8601 */
  startDateTime?: Maybe<Scalars['DateTime']>;
  versionNo: Scalars['Int'];
};

export type UpdateLicenceCountInputInput = {
  licenceCount: Scalars['Int'];
  versionNo: Scalars['Int'];
};

export type UpdateMemberInvitationInputInput = {
  adminRole: Scalars['Boolean'];
  clientManagementRole: Scalars['Boolean'];
  department?: Maybe<Scalars['String']>;
  employeeNumber?: Maybe<Scalars['String']>;
  laborCostManagementRole: Scalars['Boolean'];
  memberManagementRole: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  organizationMemberViewRole: Scalars['Boolean'];
  organizationReportRole: Scalars['Boolean'];
  projectCreatePermissionFlg: Scalars['Boolean'];
  projectCustomAttributePermissionFlg: Scalars['Boolean'];
  projectDeletePermissionFlg: Scalars['Boolean'];
  projectUpdatePermissionFlg: Scalars['Boolean'];
  proxyEditWorkingDataRole: Scalars['Boolean'];
  salesManagementRole: Scalars['Boolean'];
  taskCreatePermissionFlg: Scalars['Boolean'];
  taskCustomAttributePermissionFlg: Scalars['Boolean'];
  taskDeletePermissionFlg: Scalars['Boolean'];
  taskUpdatePermissionFlg: Scalars['Boolean'];
  teamMemberViewRole: Scalars['Boolean'];
  teamReportRole: Scalars['Boolean'];
  versionNo: Scalars['Int'];
};

export type UpdateMemberInvitationLinkInputInput = {
  memo?: Maybe<Scalars['String']>;
  versionNo: Scalars['Int'];
};

export type UpdateMemberProfileInputInput = {
  department?: Maybe<Scalars['String']>;
  employeeNumber?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mailAddress: Scalars['String'];
  name: Scalars['String'];
  versionNo: Scalars['Int'];
};

export type UpdateMemberRoleInputInput = {
  adminRole: Scalars['Boolean'];
  clientManagementRole: Scalars['Boolean'];
  laborCostManagementRole: Scalars['Boolean'];
  memberManagementRole: Scalars['Boolean'];
  organizationMemberViewRole: Scalars['Boolean'];
  organizationReportRole: Scalars['Boolean'];
  projectCreatePermission: Scalars['Boolean'];
  projectCustomAttributePermission: Scalars['Boolean'];
  projectDeletePermission: Scalars['Boolean'];
  projectUpdatePermission: Scalars['Boolean'];
  proxyEditWorkingDataRole: Scalars['Boolean'];
  salesManagementRole: Scalars['Boolean'];
  taskCreatePermission: Scalars['Boolean'];
  taskCustomAttributePermission: Scalars['Boolean'];
  taskDeletePermission: Scalars['Boolean'];
  taskUpdatePermission: Scalars['Boolean'];
  teamMemberViewRole: Scalars['Boolean'];
  teamReportRole: Scalars['Boolean'];
  versionNo: Scalars['Int'];
};

export type UpdateMemberSettingsInputInput = {
  allowExportCalendar?: Maybe<Scalars['Boolean']>;
  autoAssignWhenTaskStart: Scalars['Boolean'];
  calendarStartDayOfWeek: Scalars['Int'];
  copyWorkingScheduleMemoToHistory?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  initialViewType?: Maybe<InitialViewType>;
  longTimeWorkingAlertMinutes?: Maybe<Scalars['Int']>;
  projectInitialViewTypeCode: ProjectInitialViewType;
  versionNo: Scalars['Int'];
};

export type UpdateMemberStatusMessageInputInput = {
  statusMessage?: Maybe<Scalars['String']>;
  versionNo: Scalars['Int'];
};

export type UpdateMeterdRateContractInputInput = {
  hourlyUnitPrice: Scalars['Int'];
  versionNo: Scalars['Int'];
};

export type UpdateOrganizationImageInputInput = {
  imageBase64?: Maybe<Scalars['String']>;
  versionNo: Scalars['Int'];
};

export type UpdateOrganizationInputInput = {
  name: Scalars['String'];
  versionNo: Scalars['Int'];
};

export type UpdatePasswordInputInput = {
  id: Scalars['ID'];
  /** パスワード(8〜200文字) */
  password: Scalars['String'];
  versionNo: Scalars['Int'];
};

export type UpdateProfileImageInputInput = {
  profileImageBase64?: Maybe<Scalars['String']>;
  versionNo: Scalars['Int'];
};

export type UpdateProjectContractInputInput = {
  contractTermType: ProjectContractTermType;
  contractType: ProjectContractType;
  costBudgetWithoutTax?: Maybe<Scalars['BigInteger']>;
  fixSalesAmountWithoutTax?: Maybe<Scalars['BigInteger']>;
  month?: Maybe<Scalars['Int']>;
  roundTimeType: ProjectContractRoundTimeType;
  salesRoundMinutes?: Maybe<Scalars['Int']>;
  taxRate: Scalars['Int'];
  timeZoneId: Scalars['String'];
  unitPriceType: ProjectContractUnitPriceType;
  versionNo: Scalars['Int'];
  year?: Maybe<Scalars['Int']>;
};

export type UpdateProjectCustomAttributeInputInput = {
  projectCustomAttributeMasterListItemId?: Maybe<Scalars['String']>;
  sortNo: Scalars['BigInteger'];
  value?: Maybe<Scalars['String']>;
};

export type UpdateProjectCustomAttributeMasterInputInput = {
  name: Scalars['String'];
  required: Scalars['Boolean'];
  sortNo: Scalars['BigInteger'];
  type: CustomerAttributeType;
  versionNo: Scalars['Int'];
};

export type UpdateProjectCustomAttributeMasterListItemInputInput = {
  sortNo: Scalars['BigInteger'];
  value: Scalars['String'];
  versionNo: Scalars['Int'];
};

export type UpdateProjectInputInput = {
  assigneeIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  estimateTimeSec?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** ISO-8601 */
  scheduledEndDateTime?: Maybe<Scalars['DateTime']>;
  /** ISO-8601 */
  scheduledStartDateTime?: Maybe<Scalars['DateTime']>;
  versionNo: Scalars['Int'];
};

export type UpdateProjectSortNoInAssignedProjectInputInput = {
  sortNo: Scalars['BigInteger'];
  versionNo: Scalars['Int'];
};

export type UpdateProjectSortNoInFavoriteProjectInputInput = {
  sortNo: Scalars['BigInteger'];
  versionNo: Scalars['Int'];
};

export type UpdateProjectSortNoInListInputInput = {
  sortNoInList: Scalars['BigInteger'];
  versionNo: Scalars['Int'];
};

export type UpdateProjectStatusInputInput = {
  name: Scalars['String'];
  sortNo: Scalars['BigInteger'];
  versionNo: Scalars['Int'];
};

export type UpdateProjectStatusOfProjectInputInput = {
  projectStatusId: Scalars['String'];
  sortNoInProjectStatus?: Maybe<Scalars['BigInteger']>;
  versionNo: Scalars['Int'];
};

export type UpdateProjectTemplateCustomAttributeInputInput = {
  projectCustomAttributeMasterListItemId?: Maybe<Scalars['String']>;
  sortNo: Scalars['BigInteger'];
  value?: Maybe<Scalars['String']>;
};

export type UpdateProjectTemplateInputInput = {
  clientId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  estimateTimeSec?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  versionNo: Scalars['Int'];
};

export type UpdateProjectTemplateSortNoInListInputInput = {
  sortNoInList: Scalars['BigInteger'];
  versionNo: Scalars['Int'];
};

export type UpdateProjectTemplateSubTaskInputInput = {
  title: Scalars['String'];
  versionNo: Scalars['Int'];
};

export type UpdateProjectTemplateSubTaskSortNoInputInput = {
  sortNo: Scalars['BigInteger'];
  versionNo: Scalars['Int'];
};

export type UpdateProjectTemplateTaskCustomAttributeInputInput = {
  sortNo: Scalars['BigInteger'];
  taskCustomAttributeMasterListItemId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type UpdateProjectTemplateTaskInputInput = {
  description?: Maybe<Scalars['String']>;
  estimateTimeSec?: Maybe<Scalars['Int']>;
  priority?: Maybe<Priority>;
  title: Scalars['String'];
  versionNo: Scalars['Int'];
};

export type UpdateProjectTemplateTaskRemainderInputInput = {
  beforeDeadlineMinutes: Scalars['Int'];
  targetMemberId?: Maybe<Scalars['String']>;
  taskRemainderDestType: TaskRemainderDestType;
  taskRemainderType: TaskRemainderType;
};

export type UpdateProjectTemplateTaskSortNoInListInputInput = {
  sortNoInList: Scalars['BigInteger'];
  versionNo: Scalars['Int'];
};

export type UpdateProjectTemplateTaskStatusInputInput = {
  name: Scalars['String'];
  sortNo: Scalars['BigInteger'];
  versionNo: Scalars['Int'];
};

export type UpdateProjectTemplateTaskStatusTaskInputInput = {
  sortNoInTaskStatus?: Maybe<Scalars['BigInteger']>;
  versionNo: Scalars['Int'];
};

export type UpdateSettlementRangeContractInputInput = {
  deductionHourlyUnitPrice: Scalars['Int'];
  excessHourlyUnitPrice: Scalars['Int'];
  lowerTimeHour: Scalars['Int'];
  monthlyUnitPrice: Scalars['Int'];
  upperTimeHour: Scalars['Int'];
  versionNo: Scalars['Int'];
};

export type UpdateSubTaskInputInput = {
  title: Scalars['String'];
  versionNo: Scalars['Int'];
};

export type UpdateSubTaskSortNoInputInput = {
  sortNo: Scalars['BigInteger'];
  versionNo: Scalars['Int'];
};

export type UpdateTagInputInput = {
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  versionNo: Scalars['Int'];
};

export type UpdateTagSortNoInputInput = {
  sortNo: Scalars['BigInteger'];
  versionNo: Scalars['Int'];
};

export type UpdateTaskCommentInputInput = {
  comment: Scalars['String'];
  versionNo: Scalars['Int'];
};

export type UpdateTaskCustomAttributeInputInput = {
  sortNo: Scalars['BigInteger'];
  taskCustomAttributeMasterListItemId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type UpdateTaskCustomAttributeMasterListItemInputInput = {
  sortNo: Scalars['BigInteger'];
  value: Scalars['String'];
  versionNo: Scalars['Int'];
};

export type UpdateTaskInputInput = {
  assigneeIds?: Maybe<Array<Scalars['String']>>;
  asssigneeId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  estimateTimeSec?: Maybe<Scalars['Int']>;
  priority?: Maybe<Priority>;
  progressRate: Scalars['Int'];
  /** ISO-8601 */
  scheduledEndDateTime?: Maybe<Scalars['DateTime']>;
  /** ISO-8601 */
  scheduledStartDateTime?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  versionNo: Scalars['Int'];
};

export type UpdateTaskRemainderInputInput = {
  beforeDeadlineMinutes: Scalars['Int'];
  targetMemberId?: Maybe<Scalars['String']>;
  taskRemainderDestType: TaskRemainderDestType;
  taskRemainderType: TaskRemainderType;
};

export type UpdateTaskSortNoInFavoriteTaskInputInput = {
  sortNo: Scalars['BigInteger'];
  versionNo: Scalars['Int'];
};

export type UpdateTaskSortNoInGanttChartInputInput = {
  sortNoInGanttChart: Scalars['BigInteger'];
  versionNo: Scalars['Int'];
};

export type UpdateTaskSortNoInListInputInput = {
  sortNoInList: Scalars['BigInteger'];
  versionNo: Scalars['Int'];
};

export type UpdateTaskSortNoInMyTaskInputInput = {
  sortNoInMyTask: Scalars['BigInteger'];
  versionNo: Scalars['Int'];
};

export type UpdateTaskStatusInputInput = {
  name: Scalars['String'];
  sortNo: Scalars['BigInteger'];
  versionNo: Scalars['Int'];
};

export type UpdateTaskStatusTaskInputInput = {
  sortNoInTaskStatus?: Maybe<Scalars['BigInteger']>;
  taskStatusId: Scalars['String'];
  versionNo: Scalars['Int'];
};

export type UpdateTasktCustomAttributeMasterInputInput = {
  name: Scalars['String'];
  required: Scalars['Boolean'];
  sortNo: Scalars['BigInteger'];
  type: CustomerAttributeType;
  versionNo: Scalars['Int'];
};

export type UpdateTeamInputInput = {
  name: Scalars['String'];
  versionNo: Scalars['Int'];
};

export type UpdateTeamMemberRelationInputInput = {
  openMenu: Scalars['Boolean'];
  sortNo: Scalars['BigInteger'];
  versionNo: Scalars['Int'];
};

export type UpdateThirdPartyCalendarConnectInputInput = {
  versionNo: Scalars['Int'];
};

export type UpdateToActiveInputInput = {
  versionNo: Scalars['Int'];
};

export type UpdateToInactiveInputInput = {
  versionNo: Scalars['Int'];
};

export type UpdateWorkingHistoryInputInput = {
  /** ISO-8601 */
  end?: Maybe<Scalars['DateTime']>;
  memo?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  start: Scalars['DateTime'];
  targetMemberId?: Maybe<Scalars['String']>;
  versionNo: Scalars['Int'];
};

export type UpdateWorkingScheduleInputInput = {
  /** ISO-8601 */
  end?: Maybe<Scalars['DateTime']>;
  memo?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  start: Scalars['DateTime'];
  targetMemberId?: Maybe<Scalars['String']>;
  versionNo: Scalars['Int'];
};

export type UpgradeToProPlanIosInputInput = {
  versionNo: Scalars['Int'];
};

export type UpperPart = {
  __typename?: 'UpperPart';
  excessHourlyUnitPrice: Scalars['Int'];
  upperHour: Scalars['Int'];
};

export type VerifyTwoFactorAuthInputInput = {
  code: Scalars['String'];
  verifyToken: Scalars['String'];
};

export type Version = {
  __typename?: 'Version';
  android: PlatformVersion;
  ios: PlatformVersion;
};

export type WithdrawalInputInput = {
  /** 退会理由 */
  reason?: Maybe<Scalars['String']>;
};

export type WorkingHistoriesSpecifyTermForCalendarInputInput = {
  /** ISO-8601 */
  end: Scalars['DateTime'];
  memberIds: Array<Maybe<Scalars['String']>>;
  /** ISO-8601 */
  start: Scalars['DateTime'];
};

export type WorkingHistoriesSpecifyTermInputInput = {
  /** ISO-8601 */
  end: Scalars['DateTime'];
  memberId?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  start: Scalars['DateTime'];
};

export type WorkingHistory = {
  __typename?: 'WorkingHistory';
  calendarEvent?: Maybe<CalendarEvent>;
  closingProcessInfo: ClosingProcessPart;
  /** ISO-8601 */
  end?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  memo?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  start: Scalars['DateTime'];
  task?: Maybe<Task>;
  versionNo: Scalars['Int'];
  workingMember: Member;
  workingTimeSec?: Maybe<Scalars['Int']>;
};

export type WorkingHistoryCalendarEventPart = {
  __typename?: 'WorkingHistoryCalendarEventPart';
  calendarType: ThirdPartyCalendarType;
  clientId?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  endDateTime: Scalars['DateTime'];
  eventName: Scalars['String'];
  id: Scalars['ID'];
  personalTeam?: Maybe<Scalars['Boolean']>;
  projectColor?: Maybe<Scalars['String']>;
  projectId: Scalars['String'];
  projectName: Scalars['String'];
  /** ISO-8601 */
  startDateTime: Scalars['DateTime'];
  taskId?: Maybe<Scalars['String']>;
  taskTitle?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['String']>;
  teamName?: Maybe<Scalars['String']>;
  thirdPartyCalendarEventId: Scalars['String'];
  versionNo: Scalars['Int'];
};

export type WorkingHistoryClientPart = {
  __typename?: 'WorkingHistoryClientPart';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type WorkingHistoryForCalendar = {
  __typename?: 'WorkingHistoryForCalendar';
  memberId: Scalars['String'];
  workingHistories: Array<Maybe<CalendarWorkingHistory>>;
};

export type WorkingHistoryForSummary = {
  __typename?: 'WorkingHistoryForSummary';
  calendarEvent?: Maybe<WorkingHistoryForSummaryCalendarEventPart>;
  closingProcessInfo: WorkingHistoryForSummaryClosingProcessPart;
  /** ISO-8601 */
  end?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  memo?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  start: Scalars['DateTime'];
  task?: Maybe<WorkingHistoryForSummaryTaskPart>;
  versionNo: Scalars['Int'];
  workingMember: WorkingHistoryForSummaryMemberPart;
  workingTimeSec?: Maybe<Scalars['Int']>;
};

export type WorkingHistoryForSummaryCalendarEventPart = {
  __typename?: 'WorkingHistoryForSummaryCalendarEventPart';
  clientId?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  eventName: Scalars['String'];
  id: Scalars['ID'];
  projectId: Scalars['String'];
  projectName: Scalars['String'];
  teamId: Scalars['String'];
};

export type WorkingHistoryForSummaryClientPart = {
  __typename?: 'WorkingHistoryForSummaryClientPart';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type WorkingHistoryForSummaryClosingProcessPart = {
  __typename?: 'WorkingHistoryForSummaryClosingProcessPart';
  closed: Scalars['Boolean'];
  /** ISO-8601 */
  targetDateTime?: Maybe<Scalars['DateTime']>;
};

export type WorkingHistoryForSummaryMemberPart = {
  __typename?: 'WorkingHistoryForSummaryMemberPart';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  profileImageUrl?: Maybe<Scalars['String']>;
};

export type WorkingHistoryForSummaryProjectPart = {
  __typename?: 'WorkingHistoryForSummaryProjectPart';
  client?: Maybe<WorkingHistoryForSummaryClientPart>;
  id: Scalars['ID'];
  name: Scalars['String'];
  team: WorkingHistoryForSummaryTeamPart;
};

export type WorkingHistoryForSummaryTaskPart = {
  __typename?: 'WorkingHistoryForSummaryTaskPart';
  id: Scalars['ID'];
  project: WorkingHistoryForSummaryProjectPart;
  title?: Maybe<Scalars['String']>;
  versionNo: Scalars['Int'];
};

export type WorkingHistoryForSummaryTeamPart = {
  __typename?: 'WorkingHistoryForSummaryTeamPart';
  id: Scalars['ID'];
  personalTeam: Scalars['Boolean'];
};

export type WorkingHistoryMemberPart = {
  __typename?: 'WorkingHistoryMemberPart';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  profileImageUrl?: Maybe<Scalars['String']>;
};

export type WorkingHistoryProjectPart = {
  __typename?: 'WorkingHistoryProjectPart';
  client?: Maybe<WorkingHistoryClientPart>;
  color?: Maybe<Scalars['String']>;
  complete: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  team: WorkingHistoryTeamPart;
};

export type WorkingHistorySummaryGroupByAssinee = {
  __typename?: 'WorkingHistorySummaryGroupByAssinee';
  /** 最後に作業を開始した時の日時。一度も作業していない場合にはNULL (ISO-8601) */
  lastWorkingStartDateTime?: Maybe<Scalars['DateTime']>;
  member?: Maybe<Member>;
  totalWorkingTimeSec: Scalars['Int'];
  /** 作業中の場合はTrue */
  tracking: Scalars['Boolean'];
};

export type WorkingHistoryTaskPart = {
  __typename?: 'WorkingHistoryTaskPart';
  /** ISO-8601 */
  completeDateTime?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  project: WorkingHistoryProjectPart;
  title?: Maybe<Scalars['String']>;
  versionNo: Scalars['Int'];
  workingMembers: Array<Maybe<WorkingHistoryWorkingMemberInfoPart>>;
};

export type WorkingHistoryTeamPart = {
  __typename?: 'WorkingHistoryTeamPart';
  id: Scalars['ID'];
  name: Scalars['String'];
  personalTeam: Scalars['Boolean'];
};

export type WorkingHistoryWorkingMemberInfoPart = {
  __typename?: 'WorkingHistoryWorkingMemberInfoPart';
  member: WorkingHistoryMemberPart;
};

export type WorkingMemberInfo = {
  __typename?: 'WorkingMemberInfo';
  /** ISO-8601 */
  lastWorkingStartDateTime: Scalars['DateTime'];
  /** ISO-8601 */
  lastWorkingStopDateTime?: Maybe<Scalars['DateTime']>;
  member: Member;
};

export type WorkingSchedule = {
  __typename?: 'WorkingSchedule';
  calendarEvent?: Maybe<CalendarEvent>;
  /** ISO-8601 */
  end: Scalars['DateTime'];
  id?: Maybe<Scalars['ID']>;
  memo?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  start: Scalars['DateTime'];
  task?: Maybe<Task>;
  versionNo: Scalars['Int'];
  workingMember: Member;
  workingTimeSec: Scalars['Int'];
};

export type WorkingScheduleCalendarEventPart = {
  __typename?: 'WorkingScheduleCalendarEventPart';
  calendarType: ThirdPartyCalendarType;
  clientId?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  endDateTime: Scalars['DateTime'];
  eventName: Scalars['String'];
  id: Scalars['ID'];
  personalTeam?: Maybe<Scalars['Boolean']>;
  projectColor?: Maybe<Scalars['String']>;
  projectId: Scalars['String'];
  projectName: Scalars['String'];
  /** ISO-8601 */
  startDateTime: Scalars['DateTime'];
  taskId?: Maybe<Scalars['String']>;
  taskTitle?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['String']>;
  teamName?: Maybe<Scalars['String']>;
  thirdPartyCalendarEventId: Scalars['String'];
  versionNo: Scalars['Int'];
};

export type WorkingScheduleClientPart = {
  __typename?: 'WorkingScheduleClientPart';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type WorkingScheduleForCalendar = {
  __typename?: 'WorkingScheduleForCalendar';
  memberId: Scalars['String'];
  workingSchedules: Array<Maybe<CalendarWorkingSchedule>>;
};

export type WorkingScheduleForSummary = {
  __typename?: 'WorkingScheduleForSummary';
  calendarEvent?: Maybe<WorkingScheduleForSummaryCalendarEventPart>;
  /** ISO-8601 */
  end: Scalars['DateTime'];
  id?: Maybe<Scalars['ID']>;
  memo?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  start: Scalars['DateTime'];
  task?: Maybe<WorkingScheduleForSummaryTaskPart>;
  versionNo: Scalars['Int'];
  workingMember: WorkingScheduleForSummaryMemberPart;
  workingTimeSec: Scalars['Int'];
};

export type WorkingScheduleForSummaryCalendarEventPart = {
  __typename?: 'WorkingScheduleForSummaryCalendarEventPart';
  clientId?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  eventName: Scalars['String'];
  id: Scalars['ID'];
  projectId: Scalars['String'];
  projectName: Scalars['String'];
  teamId: Scalars['String'];
};

export type WorkingScheduleForSummaryClientPart = {
  __typename?: 'WorkingScheduleForSummaryClientPart';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type WorkingScheduleForSummaryMemberPart = {
  __typename?: 'WorkingScheduleForSummaryMemberPart';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  profileImageUrl?: Maybe<Scalars['String']>;
};

export type WorkingScheduleForSummaryProjectPart = {
  __typename?: 'WorkingScheduleForSummaryProjectPart';
  client?: Maybe<WorkingScheduleForSummaryClientPart>;
  id: Scalars['ID'];
  name: Scalars['String'];
  team: WorkingScheduleForSummaryTeamPart;
};

export type WorkingScheduleForSummaryTaskPart = {
  __typename?: 'WorkingScheduleForSummaryTaskPart';
  id: Scalars['ID'];
  project: WorkingScheduleForSummaryProjectPart;
  title?: Maybe<Scalars['String']>;
  versionNo: Scalars['Int'];
};

export type WorkingScheduleForSummaryTeamPart = {
  __typename?: 'WorkingScheduleForSummaryTeamPart';
  id: Scalars['ID'];
  personalTeam: Scalars['Boolean'];
};

export type WorkingScheduleMemberPart = {
  __typename?: 'WorkingScheduleMemberPart';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  profileImageUrl?: Maybe<Scalars['String']>;
};

export type WorkingScheduleProjectPart = {
  __typename?: 'WorkingScheduleProjectPart';
  client?: Maybe<WorkingScheduleClientPart>;
  color?: Maybe<Scalars['String']>;
  complete: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  team: WorkingScheduleTeamPart;
};

export type WorkingScheduleSummaryGroupByAssinee = {
  __typename?: 'WorkingScheduleSummaryGroupByAssinee';
  member?: Maybe<Member>;
  totalWorkingTimeSec: Scalars['Int'];
};

export type WorkingScheduleTaskPart = {
  __typename?: 'WorkingScheduleTaskPart';
  /** ISO-8601 */
  completeDateTime?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  project: WorkingScheduleProjectPart;
  title?: Maybe<Scalars['String']>;
  versionNo: Scalars['Int'];
  workingMembers: Array<Maybe<WorkingScheduleWorkingMemberInfoPart>>;
};

export type WorkingScheduleTeamPart = {
  __typename?: 'WorkingScheduleTeamPart';
  id: Scalars['ID'];
  name: Scalars['String'];
  personalTeam: Scalars['Boolean'];
};

export type WorkingScheduleWorkingMemberInfoPart = {
  __typename?: 'WorkingScheduleWorkingMemberInfoPart';
  member: WorkingScheduleMemberPart;
};

export type WorkingSchedulesSpecifyTermForCalendarInputInput = {
  /** ISO-8601 */
  end: Scalars['DateTime'];
  memberIds: Array<Maybe<Scalars['String']>>;
  /** ISO-8601 */
  start: Scalars['DateTime'];
};

export type WorkingSchedulesSpecifyTermInputInput = {
  /** ISO-8601 */
  end: Scalars['DateTime'];
  memberId?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  start: Scalars['DateTime'];
};

export type AddCsvReportItemMutationVariables = Exact<{
  id: Scalars['ID'];
  input: AddCsvDefinitionDetailInputInput;
}>;

export type AddCsvReportItemMutation = { __typename?: 'Mutation' } & {
  addCsvReportItem?: Maybe<
    { __typename?: 'CsvReportItemDefinition' } & Pick<
      CsvReportItemDefinition,
      'id' | 'name' | 'versionNo'
    > & {
        items: Array<
          Maybe<
            { __typename?: 'CsvReportItemDefinitionDetail' } & Pick<
              CsvReportItemDefinitionDetail,
              'id' | 'reportItemCode' | 'reportItemName' | 'sortNo'
            >
          >
        >;
      }
  >;
};

export type AddProjectCustomAttributeMutationVariables = Exact<{
  input: RegisterProjectCustomAttributeInputInput;
  projectId: Scalars['String'];
}>;

export type AddProjectCustomAttributeMutation = { __typename?: 'Mutation' } & {
  addProjectCustomAttribute?: Maybe<
    { __typename?: 'ProjectCustomAttribute' } & Pick<
      ProjectCustomAttribute,
      'id' | 'sortNo' | 'value'
    > & {
        listItem?: Maybe<
          { __typename?: 'ProjectCustomAttributeMasterListItem' } & Pick<
            ProjectCustomAttributeMasterListItem,
            'id' | 'sortNo' | 'value' | 'versionNo'
          >
        >;
        master: { __typename?: 'ProjectCustomAttributeMaster' } & Pick<
          ProjectCustomAttributeMaster,
          'id' | 'name' | 'required' | 'sortNo' | 'type' | 'versionNo'
        > & {
            listItem: Array<
              Maybe<
                { __typename?: 'ProjectCustomAttributeMasterListItem' } & Pick<
                  ProjectCustomAttributeMasterListItem,
                  'id' | 'sortNo' | 'value' | 'versionNo'
                >
              >
            >;
          };
      }
  >;
};

export type AddProjectCustomAttributeMasterListItemMutationVariables = Exact<{
  input: RegisterProjectCustomAttributeMasterListItemInputInput;
  projectCustomAttributeMasterId: Scalars['String'];
}>;

export type AddProjectCustomAttributeMasterListItemMutation = { __typename?: 'Mutation' } & {
  addProjectCustomAttributeMasterListItem?: Maybe<
    { __typename?: 'ProjectCustomAttributeMasterListItem' } & Pick<
      ProjectCustomAttributeMasterListItem,
      'id' | 'sortNo' | 'value' | 'versionNo'
    >
  >;
};

export type AddProjectTemplateCustomAttributeMutationVariables = Exact<{
  input: RegisterProjectTemplateCustomAttributeInputInput;
  projectTemplateId: Scalars['String'];
}>;

export type AddProjectTemplateCustomAttributeMutation = { __typename?: 'Mutation' } & {
  addProjectTemplateCustomAttribute?: Maybe<
    { __typename?: 'ProjectTemplateCustomAttribute' } & Pick<
      ProjectTemplateCustomAttribute,
      'id' | 'sortNo' | 'value'
    > & {
        listItem?: Maybe<
          { __typename?: 'ProjectCustomAttributeMasterListItem' } & Pick<
            ProjectCustomAttributeMasterListItem,
            'id' | 'sortNo' | 'value' | 'versionNo'
          >
        >;
        master: { __typename?: 'ProjectCustomAttributeMaster' } & Pick<
          ProjectCustomAttributeMaster,
          'id' | 'name' | 'required' | 'sortNo' | 'type' | 'versionNo'
        > & {
            listItem: Array<
              Maybe<
                { __typename?: 'ProjectCustomAttributeMasterListItem' } & Pick<
                  ProjectCustomAttributeMasterListItem,
                  'id' | 'sortNo' | 'value' | 'versionNo'
                >
              >
            >;
          };
      }
  >;
};

export type AddProjectTemplateTaskCustomAttributeMutationVariables = Exact<{
  input: RegisterProjectTemplateTaskCustomAttributeInputInput;
  projectTemplateTaskId: Scalars['String'];
}>;

export type AddProjectTemplateTaskCustomAttributeMutation = { __typename?: 'Mutation' } & {
  addProjectTemplateTaskCustomAttribute?: Maybe<
    { __typename?: 'ProjectTemplateTaskCustomAttribute' } & Pick<
      ProjectTemplateTaskCustomAttribute,
      'id' | 'sortNo' | 'value'
    > & {
        listItem?: Maybe<
          { __typename?: 'TaskCustomAttributeMasterListItem' } & Pick<
            TaskCustomAttributeMasterListItem,
            'id' | 'sortNo' | 'value' | 'versionNo'
          >
        >;
        master: { __typename?: 'TaskCustomAttributeMaster' } & Pick<
          TaskCustomAttributeMaster,
          'id' | 'name' | 'required' | 'sortNo' | 'type' | 'versionNo'
        > & {
            listItem: Array<
              Maybe<
                { __typename?: 'TaskCustomAttributeMasterListItem' } & Pick<
                  TaskCustomAttributeMasterListItem,
                  'id' | 'sortNo' | 'value' | 'versionNo'
                >
              >
            >;
          };
      }
  >;
};

export type AddTaskCommentMutationVariables = Exact<{
  input?: Maybe<AddTaskCommentInputInput>;
  taskId: Scalars['String'];
}>;

export type AddTaskCommentMutation = { __typename?: 'Mutation' } & {
  addTaskComment?: Maybe<
    { __typename?: 'TaskComment' } & Pick<
      TaskComment,
      | 'comment'
      | 'commentedDateTime'
      | 'id'
      | 'projectId'
      | 'projectName'
      | 'taskId'
      | 'taskTitle'
      | 'teamId'
      | 'teamName'
      | 'versionNo'
    > & {
        createdMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
        task?: Maybe<
          { __typename?: 'Task' } & Pick<
            Task,
            | 'calendarLinkageCode'
            | 'completeDateTime'
            | 'description'
            | 'estimateTimeSec'
            | 'estimatedRemainingTimeSec'
            | 'id'
            | 'laborCost'
            | 'priority'
            | 'progressDeviationRate'
            | 'progressRate'
            | 'registerDateTime'
            | 'remainingTimeSec'
            | 'scheduleWorkingTimeSec'
            | 'scheduledEndDateTime'
            | 'scheduledStartDateTime'
            | 'sortNoInGanttChart'
            | 'sortNoInList'
            | 'sortNoInMyTask'
            | 'sortNoInTaskStatus'
            | 'title'
            | 'versionNo'
            | 'workingTimeSec'
          > & {
              assignees: Array<
                { __typename?: 'AssignedTaskMemberInfo' } & Pick<
                  AssignedTaskMemberInfo,
                  'sortNo'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >;
              asssignee?: Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >;
              createdMember: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
              favoriteMembers: Array<
                Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >
              >;
              favoriteMembersInfo: Array<
                Maybe<
                  { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                    FavoriteTaskMemberInfo,
                    'sortNo'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >
              >;
              project: { __typename?: 'Project' } & Pick<
                Project,
                | 'calendarLinkageCode'
                | 'color'
                | 'complete'
                | 'completeDateTime'
                | 'costBudgetWithoutTax'
                | 'description'
                | 'estimateTimeSec'
                | 'id'
                | 'key'
                | 'laborCost'
                | 'laborCostWithoutTax'
                | 'laborShare'
                | 'name'
                | 'priority'
                | 'registerDateTime'
                | 'salesAmountWithoutTax'
                | 'scheduledEndDateTime'
                | 'scheduledStartDateTime'
                | 'sortNoInGanttChart'
                | 'sortNoInList'
                | 'sortNoInProjectStatus'
                | 'totalScheduleTimeSec'
                | 'versionNo'
                | 'workingTimeSec'
              > & {
                  assignees: Array<
                    { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                      AssignedProjectMemberInfo,
                      'sortNo'
                    >
                  >;
                  client?: Maybe<
                    { __typename?: 'Client' } & Pick<
                      Client,
                      'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                    >
                  >;
                  createdMember?: Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >;
                  favoriteMembers: Array<
                    Maybe<
                      { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >
                    >
                  >;
                  favoriteMembersInfo: Array<
                    Maybe<
                      { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                        FavoriteProjectMemberInfo,
                        'sortNo'
                      >
                    >
                  >;
                  projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                    ProjectStatus,
                    'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                  >;
                  team: { __typename?: 'Team' } & Pick<
                    Team,
                    'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                  > & {
                      organization: { __typename?: 'Organization' } & Pick<
                        Organization,
                        | 'allowConnectThirdPartyCalendar'
                        | 'contactMailAddress'
                        | 'contractPreriod'
                        | 'id'
                        | 'imageUrl'
                        | 'ipRestrictionFlg'
                        | 'name'
                        | 'requiredTwoFactorAuth'
                        | 'suspensionFlg'
                        | 'versionNo'
                      > & {
                          plan: { __typename?: 'PlanProduct' } & Pick<
                            PlanProduct,
                            'code' | 'contractPreriod' | 'id'
                          >;
                        };
                    };
                };
              taskStatus: { __typename?: 'TaskStatus' } & Pick<
                TaskStatus,
                'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
              > & {
                  project: { __typename?: 'Project' } & Pick<
                    Project,
                    | 'calendarLinkageCode'
                    | 'color'
                    | 'complete'
                    | 'completeDateTime'
                    | 'costBudgetWithoutTax'
                    | 'description'
                    | 'estimateTimeSec'
                    | 'id'
                    | 'key'
                    | 'laborCost'
                    | 'laborCostWithoutTax'
                    | 'laborShare'
                    | 'name'
                    | 'priority'
                    | 'registerDateTime'
                    | 'salesAmountWithoutTax'
                    | 'scheduledEndDateTime'
                    | 'scheduledStartDateTime'
                    | 'sortNoInGanttChart'
                    | 'sortNoInList'
                    | 'sortNoInProjectStatus'
                    | 'totalScheduleTimeSec'
                    | 'versionNo'
                    | 'workingTimeSec'
                  >;
                };
              workingMembers: Array<
                Maybe<
                  { __typename?: 'WorkingMemberInfo' } & Pick<
                    WorkingMemberInfo,
                    'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >
              >;
            }
        >;
      }
  >;
};

export type AddTaskCustomAttributeMutationVariables = Exact<{
  input: RegisterTaskCustomAttributeInputInput;
  taskId: Scalars['String'];
}>;

export type AddTaskCustomAttributeMutation = { __typename?: 'Mutation' } & {
  addTaskCustomAttribute?: Maybe<
    { __typename?: 'TaskCustomAttribute' } & Pick<
      TaskCustomAttribute,
      'id' | 'sortNo' | 'value'
    > & {
        listItem?: Maybe<
          { __typename?: 'TaskCustomAttributeMasterListItem' } & Pick<
            TaskCustomAttributeMasterListItem,
            'id' | 'sortNo' | 'value' | 'versionNo'
          >
        >;
        master: { __typename?: 'TaskCustomAttributeMaster' } & Pick<
          TaskCustomAttributeMaster,
          'id' | 'name' | 'required' | 'sortNo' | 'type' | 'versionNo'
        > & {
            listItem: Array<
              Maybe<
                { __typename?: 'TaskCustomAttributeMasterListItem' } & Pick<
                  TaskCustomAttributeMasterListItem,
                  'id' | 'sortNo' | 'value' | 'versionNo'
                >
              >
            >;
          };
      }
  >;
};

export type AddTaskCustomAttributeMasterListItemMutationVariables = Exact<{
  input: RegisterTaskCustomAttributeMasterListItemInputInput;
  taskCustomAttributeMasterId: Scalars['String'];
}>;

export type AddTaskCustomAttributeMasterListItemMutation = { __typename?: 'Mutation' } & {
  addTaskCustomAttributeMasterListItem?: Maybe<
    { __typename?: 'TaskCustomAttributeMasterListItem' } & Pick<
      TaskCustomAttributeMasterListItem,
      'id' | 'sortNo' | 'value' | 'versionNo'
    >
  >;
};

export type ArchiveCompletedTasksMutationVariables = Exact<{
  projectId: Scalars['String'];
}>;

export type ArchiveCompletedTasksMutation = { __typename?: 'Mutation' } & {
  archiveCompletedTasks?: Maybe<
    { __typename?: 'Task' } & Pick<
      Task,
      | 'calendarLinkageCode'
      | 'completeDateTime'
      | 'description'
      | 'estimateTimeSec'
      | 'estimatedRemainingTimeSec'
      | 'id'
      | 'laborCost'
      | 'priority'
      | 'progressDeviationRate'
      | 'progressRate'
      | 'registerDateTime'
      | 'remainingTimeSec'
      | 'scheduleWorkingTimeSec'
      | 'scheduledEndDateTime'
      | 'scheduledStartDateTime'
      | 'sortNoInGanttChart'
      | 'sortNoInList'
      | 'sortNoInMyTask'
      | 'sortNoInTaskStatus'
      | 'title'
      | 'versionNo'
      | 'workingTimeSec'
    > & {
        assignees: Array<
          { __typename?: 'AssignedTaskMemberInfo' } & Pick<AssignedTaskMemberInfo, 'sortNo'> & {
              member: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
            }
        >;
        asssignee?: Maybe<
          { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >
        >;
        createdMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
        favoriteMembers: Array<
          Maybe<
            { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >
          >
        >;
        favoriteMembersInfo: Array<
          Maybe<
            { __typename?: 'FavoriteTaskMemberInfo' } & Pick<FavoriteTaskMemberInfo, 'sortNo'> & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
        project: { __typename?: 'Project' } & Pick<
          Project,
          | 'calendarLinkageCode'
          | 'color'
          | 'complete'
          | 'completeDateTime'
          | 'costBudgetWithoutTax'
          | 'description'
          | 'estimateTimeSec'
          | 'id'
          | 'key'
          | 'laborCost'
          | 'laborCostWithoutTax'
          | 'laborShare'
          | 'name'
          | 'priority'
          | 'registerDateTime'
          | 'salesAmountWithoutTax'
          | 'scheduledEndDateTime'
          | 'scheduledStartDateTime'
          | 'sortNoInGanttChart'
          | 'sortNoInList'
          | 'sortNoInProjectStatus'
          | 'totalScheduleTimeSec'
          | 'versionNo'
          | 'workingTimeSec'
        > & {
            assignees: Array<
              { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                AssignedProjectMemberInfo,
                'sortNo'
              >
            >;
            client?: Maybe<
              { __typename?: 'Client' } & Pick<
                Client,
                'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
              >
            >;
            createdMember?: Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >;
            favoriteMembers: Array<
              Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >
            >;
            favoriteMembersInfo: Array<
              Maybe<
                { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                  FavoriteProjectMemberInfo,
                  'sortNo'
                >
              >
            >;
            projectStatus: { __typename?: 'ProjectStatus' } & Pick<
              ProjectStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
            >;
            team: { __typename?: 'Team' } & Pick<
              Team,
              'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
            > & {
                organization: { __typename?: 'Organization' } & Pick<
                  Organization,
                  | 'allowConnectThirdPartyCalendar'
                  | 'contactMailAddress'
                  | 'contractPreriod'
                  | 'id'
                  | 'imageUrl'
                  | 'ipRestrictionFlg'
                  | 'name'
                  | 'requiredTwoFactorAuth'
                  | 'suspensionFlg'
                  | 'versionNo'
                > & {
                    plan: { __typename?: 'PlanProduct' } & Pick<
                      PlanProduct,
                      'code' | 'contractPreriod' | 'id'
                    >;
                  };
              };
          };
        taskStatus: { __typename?: 'TaskStatus' } & Pick<
          TaskStatus,
          'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
        > & {
            project: { __typename?: 'Project' } & Pick<
              Project,
              | 'calendarLinkageCode'
              | 'color'
              | 'complete'
              | 'completeDateTime'
              | 'costBudgetWithoutTax'
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'key'
              | 'laborCost'
              | 'laborCostWithoutTax'
              | 'laborShare'
              | 'name'
              | 'priority'
              | 'registerDateTime'
              | 'salesAmountWithoutTax'
              | 'scheduledEndDateTime'
              | 'scheduledStartDateTime'
              | 'sortNoInGanttChart'
              | 'sortNoInList'
              | 'sortNoInProjectStatus'
              | 'totalScheduleTimeSec'
              | 'versionNo'
              | 'workingTimeSec'
            >;
          };
        workingMembers: Array<
          Maybe<
            { __typename?: 'WorkingMemberInfo' } & Pick<
              WorkingMemberInfo,
              'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
            > & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
      }
  >;
};

export type ArchiveTeamMutationVariables = Exact<{
  id: Scalars['ID'];
  input: ArchiveTeamInputInput;
}>;

export type ArchiveTeamMutation = { __typename?: 'Mutation' } & {
  archiveTeam?: Maybe<
    { __typename?: 'Team' } & Pick<
      Team,
      'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
    > & {
        organization: { __typename?: 'Organization' } & Pick<
          Organization,
          | 'allowConnectThirdPartyCalendar'
          | 'contactMailAddress'
          | 'contractPreriod'
          | 'id'
          | 'imageUrl'
          | 'ipRestrictionFlg'
          | 'name'
          | 'requiredTwoFactorAuth'
          | 'suspensionFlg'
          | 'versionNo'
        > & {
            plan: { __typename?: 'PlanProduct' } & Pick<
              PlanProduct,
              'code' | 'contractPreriod' | 'id'
            >;
          };
      }
  >;
};

export type CancelFavoriteProjectMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: Maybe<CancelFavoriteProjectInputInput>;
}>;

export type CancelFavoriteProjectMutation = { __typename?: 'Mutation' } & {
  cancelFavoriteProject?: Maybe<
    { __typename?: 'Project' } & Pick<
      Project,
      | 'calendarLinkageCode'
      | 'color'
      | 'complete'
      | 'completeDateTime'
      | 'costBudgetWithoutTax'
      | 'description'
      | 'estimateTimeSec'
      | 'id'
      | 'key'
      | 'laborCost'
      | 'laborCostWithoutTax'
      | 'laborShare'
      | 'name'
      | 'priority'
      | 'registerDateTime'
      | 'salesAmountWithoutTax'
      | 'scheduledEndDateTime'
      | 'scheduledStartDateTime'
      | 'sortNoInGanttChart'
      | 'sortNoInList'
      | 'sortNoInProjectStatus'
      | 'totalScheduleTimeSec'
      | 'versionNo'
      | 'workingTimeSec'
    > & {
        assignees: Array<
          { __typename?: 'AssignedProjectMemberInfo' } & Pick<
            AssignedProjectMemberInfo,
            'sortNo'
          > & {
              member: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
            }
        >;
        client?: Maybe<
          { __typename?: 'Client' } & Pick<Client, 'code' | 'id' | 'name' | 'sortNo' | 'versionNo'>
        >;
        createdMember?: Maybe<
          { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >
        >;
        favoriteMembers: Array<
          Maybe<
            { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >
          >
        >;
        favoriteMembersInfo: Array<
          Maybe<
            { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
              FavoriteProjectMemberInfo,
              'sortNo'
            > & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
        projectStatus: { __typename?: 'ProjectStatus' } & Pick<
          ProjectStatus,
          'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
        >;
        team: { __typename?: 'Team' } & Pick<
          Team,
          'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
        > & {
            organization: { __typename?: 'Organization' } & Pick<
              Organization,
              | 'allowConnectThirdPartyCalendar'
              | 'contactMailAddress'
              | 'contractPreriod'
              | 'id'
              | 'imageUrl'
              | 'ipRestrictionFlg'
              | 'name'
              | 'requiredTwoFactorAuth'
              | 'suspensionFlg'
              | 'versionNo'
            > & {
                plan: { __typename?: 'PlanProduct' } & Pick<
                  PlanProduct,
                  'code' | 'contractPreriod' | 'id'
                >;
              };
          };
      }
  >;
};

export type CancelFavoriteTaskMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: Maybe<CancelFavoriteTaskInputInput>;
}>;

export type CancelFavoriteTaskMutation = { __typename?: 'Mutation' } & {
  cancelFavoriteTask?: Maybe<
    { __typename?: 'Task' } & Pick<
      Task,
      | 'calendarLinkageCode'
      | 'completeDateTime'
      | 'description'
      | 'estimateTimeSec'
      | 'estimatedRemainingTimeSec'
      | 'id'
      | 'laborCost'
      | 'priority'
      | 'progressDeviationRate'
      | 'progressRate'
      | 'registerDateTime'
      | 'remainingTimeSec'
      | 'scheduleWorkingTimeSec'
      | 'scheduledEndDateTime'
      | 'scheduledStartDateTime'
      | 'sortNoInGanttChart'
      | 'sortNoInList'
      | 'sortNoInMyTask'
      | 'sortNoInTaskStatus'
      | 'title'
      | 'versionNo'
      | 'workingTimeSec'
    > & {
        assignees: Array<
          { __typename?: 'AssignedTaskMemberInfo' } & Pick<AssignedTaskMemberInfo, 'sortNo'> & {
              member: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
            }
        >;
        asssignee?: Maybe<
          { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >
        >;
        createdMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
        favoriteMembers: Array<
          Maybe<
            { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >
          >
        >;
        favoriteMembersInfo: Array<
          Maybe<
            { __typename?: 'FavoriteTaskMemberInfo' } & Pick<FavoriteTaskMemberInfo, 'sortNo'> & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
        project: { __typename?: 'Project' } & Pick<
          Project,
          | 'calendarLinkageCode'
          | 'color'
          | 'complete'
          | 'completeDateTime'
          | 'costBudgetWithoutTax'
          | 'description'
          | 'estimateTimeSec'
          | 'id'
          | 'key'
          | 'laborCost'
          | 'laborCostWithoutTax'
          | 'laborShare'
          | 'name'
          | 'priority'
          | 'registerDateTime'
          | 'salesAmountWithoutTax'
          | 'scheduledEndDateTime'
          | 'scheduledStartDateTime'
          | 'sortNoInGanttChart'
          | 'sortNoInList'
          | 'sortNoInProjectStatus'
          | 'totalScheduleTimeSec'
          | 'versionNo'
          | 'workingTimeSec'
        > & {
            assignees: Array<
              { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                AssignedProjectMemberInfo,
                'sortNo'
              >
            >;
            client?: Maybe<
              { __typename?: 'Client' } & Pick<
                Client,
                'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
              >
            >;
            createdMember?: Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >;
            favoriteMembers: Array<
              Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >
            >;
            favoriteMembersInfo: Array<
              Maybe<
                { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                  FavoriteProjectMemberInfo,
                  'sortNo'
                >
              >
            >;
            projectStatus: { __typename?: 'ProjectStatus' } & Pick<
              ProjectStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
            >;
            team: { __typename?: 'Team' } & Pick<
              Team,
              'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
            > & {
                organization: { __typename?: 'Organization' } & Pick<
                  Organization,
                  | 'allowConnectThirdPartyCalendar'
                  | 'contactMailAddress'
                  | 'contractPreriod'
                  | 'id'
                  | 'imageUrl'
                  | 'ipRestrictionFlg'
                  | 'name'
                  | 'requiredTwoFactorAuth'
                  | 'suspensionFlg'
                  | 'versionNo'
                > & {
                    plan: { __typename?: 'PlanProduct' } & Pick<
                      PlanProduct,
                      'code' | 'contractPreriod' | 'id'
                    >;
                  };
              };
          };
        taskStatus: { __typename?: 'TaskStatus' } & Pick<
          TaskStatus,
          'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
        > & {
            project: { __typename?: 'Project' } & Pick<
              Project,
              | 'calendarLinkageCode'
              | 'color'
              | 'complete'
              | 'completeDateTime'
              | 'costBudgetWithoutTax'
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'key'
              | 'laborCost'
              | 'laborCostWithoutTax'
              | 'laborShare'
              | 'name'
              | 'priority'
              | 'registerDateTime'
              | 'salesAmountWithoutTax'
              | 'scheduledEndDateTime'
              | 'scheduledStartDateTime'
              | 'sortNoInGanttChart'
              | 'sortNoInList'
              | 'sortNoInProjectStatus'
              | 'totalScheduleTimeSec'
              | 'versionNo'
              | 'workingTimeSec'
            >;
          };
        workingMembers: Array<
          Maybe<
            { __typename?: 'WorkingMemberInfo' } & Pick<
              WorkingMemberInfo,
              'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
            > & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
      }
  >;
};

export type ChangeContractPeriodMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: Maybe<ChangeContractPeriodInputInput>;
}>;

export type ChangeContractPeriodMutation = { __typename?: 'Mutation' } & {
  changeContractPeriod?: Maybe<
    { __typename?: 'Contract' } & Pick<
      Contract,
      | 'billToMailAddress'
      | 'billingType'
      | 'contractPreriod'
      | 'freeTrialExpireDatetime'
      | 'id'
      | 'licenceCount'
      | 'revenuecatOriginalAppUserId'
      | 'usingLicenceCount'
      | 'versionNo'
    > & {
        freeLicenceCoupon?: Maybe<
          { __typename?: 'RangeFreeLicenceCoupon' } & Pick<
            RangeFreeLicenceCoupon,
            'freeEndLicenceCount' | 'freeStartLicenceCount' | 'id'
          >
        >;
        plan: { __typename?: 'PlanProduct' } & Pick<PlanProduct, 'code' | 'contractPreriod' | 'id'>;
      }
  >;
};

export type ChangePlanMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: Maybe<ChangePlanInputInput>;
}>;

export type ChangePlanMutation = { __typename?: 'Mutation' } & {
  changePlan?: Maybe<
    { __typename?: 'Contract' } & Pick<
      Contract,
      | 'billToMailAddress'
      | 'billingType'
      | 'contractPreriod'
      | 'freeTrialExpireDatetime'
      | 'id'
      | 'licenceCount'
      | 'revenuecatOriginalAppUserId'
      | 'usingLicenceCount'
      | 'versionNo'
    > & {
        freeLicenceCoupon?: Maybe<
          { __typename?: 'RangeFreeLicenceCoupon' } & Pick<
            RangeFreeLicenceCoupon,
            'freeEndLicenceCount' | 'freeStartLicenceCount' | 'id'
          >
        >;
        plan: { __typename?: 'PlanProduct' } & Pick<PlanProduct, 'code' | 'contractPreriod' | 'id'>;
      }
  >;
};

export type CloseAllTeamMenuMutationVariables = Exact<{ [key: string]: never }>;

export type CloseAllTeamMenuMutation = { __typename?: 'Mutation' } & {
  closeAllTeamMenu?: Maybe<
    Array<
      Maybe<
        { __typename?: 'TeamMemberRelation' } & Pick<
          TeamMemberRelation,
          'id' | 'openMenu' | 'sortNo' | 'versionNo'
        > & {
            team: { __typename?: 'Team' } & Pick<
              Team,
              'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
            > & {
                organization: { __typename?: 'Organization' } & Pick<
                  Organization,
                  | 'allowConnectThirdPartyCalendar'
                  | 'contactMailAddress'
                  | 'contractPreriod'
                  | 'id'
                  | 'imageUrl'
                  | 'ipRestrictionFlg'
                  | 'name'
                  | 'requiredTwoFactorAuth'
                  | 'suspensionFlg'
                  | 'versionNo'
                > & {
                    plan: { __typename?: 'PlanProduct' } & Pick<
                      PlanProduct,
                      'code' | 'contractPreriod' | 'id'
                    >;
                  };
              };
          }
      >
    >
  >;
};

export type CloseTermMutationVariables = Exact<{
  input?: Maybe<CloseInputInput>;
}>;

export type CloseTermMutation = { __typename?: 'Mutation' } & {
  closeTerm?: Maybe<
    { __typename?: 'ClosingSetting' } & Pick<
      ClosingSetting,
      'id' | 'targetDateTime' | 'versionNo'
    > & {
        timeZone: { __typename?: 'TimeZone' } & Pick<
          TimeZone,
          'code' | 'id' | 'name' | 'offsetTime' | 'sortNo'
        >;
      }
  >;
};

export type CompleteProjectMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: Maybe<CompleteProjectInputInput>;
}>;

export type CompleteProjectMutation = { __typename?: 'Mutation' } & {
  completeProject?: Maybe<
    { __typename?: 'Project' } & Pick<
      Project,
      | 'calendarLinkageCode'
      | 'color'
      | 'complete'
      | 'completeDateTime'
      | 'costBudgetWithoutTax'
      | 'description'
      | 'estimateTimeSec'
      | 'id'
      | 'key'
      | 'laborCost'
      | 'laborCostWithoutTax'
      | 'laborShare'
      | 'name'
      | 'priority'
      | 'registerDateTime'
      | 'salesAmountWithoutTax'
      | 'scheduledEndDateTime'
      | 'scheduledStartDateTime'
      | 'sortNoInGanttChart'
      | 'sortNoInList'
      | 'sortNoInProjectStatus'
      | 'totalScheduleTimeSec'
      | 'versionNo'
      | 'workingTimeSec'
    > & {
        assignees: Array<
          { __typename?: 'AssignedProjectMemberInfo' } & Pick<
            AssignedProjectMemberInfo,
            'sortNo'
          > & {
              member: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
            }
        >;
        client?: Maybe<
          { __typename?: 'Client' } & Pick<Client, 'code' | 'id' | 'name' | 'sortNo' | 'versionNo'>
        >;
        createdMember?: Maybe<
          { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >
        >;
        favoriteMembers: Array<
          Maybe<
            { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >
          >
        >;
        favoriteMembersInfo: Array<
          Maybe<
            { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
              FavoriteProjectMemberInfo,
              'sortNo'
            > & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
        projectStatus: { __typename?: 'ProjectStatus' } & Pick<
          ProjectStatus,
          'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
        >;
        team: { __typename?: 'Team' } & Pick<
          Team,
          'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
        > & {
            organization: { __typename?: 'Organization' } & Pick<
              Organization,
              | 'allowConnectThirdPartyCalendar'
              | 'contactMailAddress'
              | 'contractPreriod'
              | 'id'
              | 'imageUrl'
              | 'ipRestrictionFlg'
              | 'name'
              | 'requiredTwoFactorAuth'
              | 'suspensionFlg'
              | 'versionNo'
            > & {
                plan: { __typename?: 'PlanProduct' } & Pick<
                  PlanProduct,
                  'code' | 'contractPreriod' | 'id'
                >;
              };
          };
      }
  >;
};

export type CompleteSubTaskMutationVariables = Exact<{
  id: Scalars['ID'];
  input: CompleteSubTaskInputInput;
}>;

export type CompleteSubTaskMutation = { __typename?: 'Mutation' } & {
  completeSubTask?: Maybe<
    { __typename?: 'SubTask' } & Pick<
      SubTask,
      'complete' | 'completeDateTime' | 'id' | 'sortNo' | 'title' | 'versionNo'
    > & {
        createdMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
        task: { __typename?: 'Task' } & Pick<
          Task,
          | 'calendarLinkageCode'
          | 'completeDateTime'
          | 'description'
          | 'estimateTimeSec'
          | 'estimatedRemainingTimeSec'
          | 'id'
          | 'laborCost'
          | 'priority'
          | 'progressDeviationRate'
          | 'progressRate'
          | 'registerDateTime'
          | 'remainingTimeSec'
          | 'scheduleWorkingTimeSec'
          | 'scheduledEndDateTime'
          | 'scheduledStartDateTime'
          | 'sortNoInGanttChart'
          | 'sortNoInList'
          | 'sortNoInMyTask'
          | 'sortNoInTaskStatus'
          | 'title'
          | 'versionNo'
          | 'workingTimeSec'
        > & {
            assignees: Array<
              { __typename?: 'AssignedTaskMemberInfo' } & Pick<AssignedTaskMemberInfo, 'sortNo'> & {
                  member: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                }
            >;
            asssignee?: Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >;
            createdMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
            favoriteMembers: Array<
              Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >
            >;
            favoriteMembersInfo: Array<
              Maybe<
                { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                  FavoriteTaskMemberInfo,
                  'sortNo'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >
            >;
            project: { __typename?: 'Project' } & Pick<
              Project,
              | 'calendarLinkageCode'
              | 'color'
              | 'complete'
              | 'completeDateTime'
              | 'costBudgetWithoutTax'
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'key'
              | 'laborCost'
              | 'laborCostWithoutTax'
              | 'laborShare'
              | 'name'
              | 'priority'
              | 'registerDateTime'
              | 'salesAmountWithoutTax'
              | 'scheduledEndDateTime'
              | 'scheduledStartDateTime'
              | 'sortNoInGanttChart'
              | 'sortNoInList'
              | 'sortNoInProjectStatus'
              | 'totalScheduleTimeSec'
              | 'versionNo'
              | 'workingTimeSec'
            > & {
                assignees: Array<
                  { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                    AssignedProjectMemberInfo,
                    'sortNo'
                  >
                >;
                client?: Maybe<
                  { __typename?: 'Client' } & Pick<
                    Client,
                    'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                  >
                >;
                createdMember?: Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >;
                favoriteMembers: Array<
                  Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >
                >;
                favoriteMembersInfo: Array<
                  Maybe<
                    { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                      FavoriteProjectMemberInfo,
                      'sortNo'
                    >
                  >
                >;
                projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                  ProjectStatus,
                  'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                >;
                team: { __typename?: 'Team' } & Pick<
                  Team,
                  'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                > & {
                    organization: { __typename?: 'Organization' } & Pick<
                      Organization,
                      | 'allowConnectThirdPartyCalendar'
                      | 'contactMailAddress'
                      | 'contractPreriod'
                      | 'id'
                      | 'imageUrl'
                      | 'ipRestrictionFlg'
                      | 'name'
                      | 'requiredTwoFactorAuth'
                      | 'suspensionFlg'
                      | 'versionNo'
                    > & {
                        plan: { __typename?: 'PlanProduct' } & Pick<
                          PlanProduct,
                          'code' | 'contractPreriod' | 'id'
                        >;
                      };
                  };
              };
            taskStatus: { __typename?: 'TaskStatus' } & Pick<
              TaskStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
            > & {
                project: { __typename?: 'Project' } & Pick<
                  Project,
                  | 'calendarLinkageCode'
                  | 'color'
                  | 'complete'
                  | 'completeDateTime'
                  | 'costBudgetWithoutTax'
                  | 'description'
                  | 'estimateTimeSec'
                  | 'id'
                  | 'key'
                  | 'laborCost'
                  | 'laborCostWithoutTax'
                  | 'laborShare'
                  | 'name'
                  | 'priority'
                  | 'registerDateTime'
                  | 'salesAmountWithoutTax'
                  | 'scheduledEndDateTime'
                  | 'scheduledStartDateTime'
                  | 'sortNoInGanttChart'
                  | 'sortNoInList'
                  | 'sortNoInProjectStatus'
                  | 'totalScheduleTimeSec'
                  | 'versionNo'
                  | 'workingTimeSec'
                >;
              };
            workingMembers: Array<
              Maybe<
                { __typename?: 'WorkingMemberInfo' } & Pick<
                  WorkingMemberInfo,
                  'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >
            >;
          };
      }
  >;
};

export type CompleteTaskMutationVariables = Exact<{
  id: Scalars['ID'];
  input: CompleteTaskInputInput;
}>;

export type CompleteTaskMutation = { __typename?: 'Mutation' } & {
  completeTask?: Maybe<
    { __typename?: 'CompleteTaskResult' } & {
      stopWorkingHistory: Array<
        Maybe<
          { __typename?: 'WorkingHistory' } & Pick<
            WorkingHistory,
            'end' | 'id' | 'memo' | 'start' | 'versionNo' | 'workingTimeSec'
          > & {
              calendarEvent?: Maybe<
                { __typename?: 'CalendarEvent' } & Pick<
                  CalendarEvent,
                  | 'calendarType'
                  | 'clientId'
                  | 'clientName'
                  | 'endDateTime'
                  | 'eventName'
                  | 'id'
                  | 'laborCost'
                  | 'personalTeam'
                  | 'projectColor'
                  | 'projectId'
                  | 'projectName'
                  | 'startDateTime'
                  | 'taskId'
                  | 'taskTitle'
                  | 'teamId'
                  | 'teamName'
                  | 'thirdPartyCalendarEventId'
                  | 'versionNo'
                  | 'workingTimeSec'
                > & {
                    thirdPartyCalendarInfoList: Array<
                      Maybe<
                        { __typename?: 'ThirdPartyCalendarInfo' } & Pick<
                          ThirdPartyCalendarInfo,
                          'thirdPartyCalendarId'
                        > & {
                            member: { __typename?: 'Member' } & Pick<
                              Member,
                              | 'adminRole'
                              | 'clientManagementRole'
                              | 'department'
                              | 'employeeNumber'
                              | 'id'
                              | 'laborCostManagementRole'
                              | 'mailAddress'
                              | 'memberManagementRole'
                              | 'memberRegistrationDateTime'
                              | 'memberStatus'
                              | 'name'
                              | 'organizationMemberViewRole'
                              | 'organizationReportRole'
                              | 'profileImageUrl'
                              | 'projectCreatePermissionFlg'
                              | 'projectCustomAttributePermissionFlg'
                              | 'projectDeletePermissionFlg'
                              | 'projectUpdatePermissionFlg'
                              | 'proxyEditWorkingDataRole'
                              | 'salesManagementRole'
                              | 'statusMessage'
                              | 'taskCreatePermissionFlg'
                              | 'taskCustomAttributePermissionFlg'
                              | 'taskDeletePermissionFlg'
                              | 'taskUpdatePermissionFlg'
                              | 'teamMemberViewRole'
                              | 'teamReportRole'
                              | 'versionNo'
                            >;
                          }
                      >
                    >;
                  }
              >;
              closingProcessInfo: { __typename?: 'ClosingProcessPart' } & Pick<
                ClosingProcessPart,
                'closed' | 'targetDateTime'
              >;
              task?: Maybe<
                { __typename?: 'Task' } & Pick<
                  Task,
                  | 'calendarLinkageCode'
                  | 'completeDateTime'
                  | 'description'
                  | 'estimateTimeSec'
                  | 'estimatedRemainingTimeSec'
                  | 'id'
                  | 'laborCost'
                  | 'priority'
                  | 'progressDeviationRate'
                  | 'progressRate'
                  | 'registerDateTime'
                  | 'remainingTimeSec'
                  | 'scheduleWorkingTimeSec'
                  | 'scheduledEndDateTime'
                  | 'scheduledStartDateTime'
                  | 'sortNoInGanttChart'
                  | 'sortNoInList'
                  | 'sortNoInMyTask'
                  | 'sortNoInTaskStatus'
                  | 'title'
                  | 'versionNo'
                  | 'workingTimeSec'
                > & {
                    assignees: Array<
                      { __typename?: 'AssignedTaskMemberInfo' } & Pick<
                        AssignedTaskMemberInfo,
                        'sortNo'
                      > & {
                          member: { __typename?: 'Member' } & Pick<
                            Member,
                            | 'adminRole'
                            | 'clientManagementRole'
                            | 'department'
                            | 'employeeNumber'
                            | 'id'
                            | 'laborCostManagementRole'
                            | 'mailAddress'
                            | 'memberManagementRole'
                            | 'memberRegistrationDateTime'
                            | 'memberStatus'
                            | 'name'
                            | 'organizationMemberViewRole'
                            | 'organizationReportRole'
                            | 'profileImageUrl'
                            | 'projectCreatePermissionFlg'
                            | 'projectCustomAttributePermissionFlg'
                            | 'projectDeletePermissionFlg'
                            | 'projectUpdatePermissionFlg'
                            | 'proxyEditWorkingDataRole'
                            | 'salesManagementRole'
                            | 'statusMessage'
                            | 'taskCreatePermissionFlg'
                            | 'taskCustomAttributePermissionFlg'
                            | 'taskDeletePermissionFlg'
                            | 'taskUpdatePermissionFlg'
                            | 'teamMemberViewRole'
                            | 'teamReportRole'
                            | 'versionNo'
                          >;
                        }
                    >;
                    asssignee?: Maybe<
                      { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >
                    >;
                    createdMember: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                    favoriteMembers: Array<
                      Maybe<
                        { __typename?: 'Member' } & Pick<
                          Member,
                          | 'adminRole'
                          | 'clientManagementRole'
                          | 'department'
                          | 'employeeNumber'
                          | 'id'
                          | 'laborCostManagementRole'
                          | 'mailAddress'
                          | 'memberManagementRole'
                          | 'memberRegistrationDateTime'
                          | 'memberStatus'
                          | 'name'
                          | 'organizationMemberViewRole'
                          | 'organizationReportRole'
                          | 'profileImageUrl'
                          | 'projectCreatePermissionFlg'
                          | 'projectCustomAttributePermissionFlg'
                          | 'projectDeletePermissionFlg'
                          | 'projectUpdatePermissionFlg'
                          | 'proxyEditWorkingDataRole'
                          | 'salesManagementRole'
                          | 'statusMessage'
                          | 'taskCreatePermissionFlg'
                          | 'taskCustomAttributePermissionFlg'
                          | 'taskDeletePermissionFlg'
                          | 'taskUpdatePermissionFlg'
                          | 'teamMemberViewRole'
                          | 'teamReportRole'
                          | 'versionNo'
                        >
                      >
                    >;
                    favoriteMembersInfo: Array<
                      Maybe<
                        { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                          FavoriteTaskMemberInfo,
                          'sortNo'
                        > & {
                            member: { __typename?: 'Member' } & Pick<
                              Member,
                              | 'adminRole'
                              | 'clientManagementRole'
                              | 'department'
                              | 'employeeNumber'
                              | 'id'
                              | 'laborCostManagementRole'
                              | 'mailAddress'
                              | 'memberManagementRole'
                              | 'memberRegistrationDateTime'
                              | 'memberStatus'
                              | 'name'
                              | 'organizationMemberViewRole'
                              | 'organizationReportRole'
                              | 'profileImageUrl'
                              | 'projectCreatePermissionFlg'
                              | 'projectCustomAttributePermissionFlg'
                              | 'projectDeletePermissionFlg'
                              | 'projectUpdatePermissionFlg'
                              | 'proxyEditWorkingDataRole'
                              | 'salesManagementRole'
                              | 'statusMessage'
                              | 'taskCreatePermissionFlg'
                              | 'taskCustomAttributePermissionFlg'
                              | 'taskDeletePermissionFlg'
                              | 'taskUpdatePermissionFlg'
                              | 'teamMemberViewRole'
                              | 'teamReportRole'
                              | 'versionNo'
                            >;
                          }
                      >
                    >;
                    project: { __typename?: 'Project' } & Pick<
                      Project,
                      | 'calendarLinkageCode'
                      | 'color'
                      | 'complete'
                      | 'completeDateTime'
                      | 'costBudgetWithoutTax'
                      | 'description'
                      | 'estimateTimeSec'
                      | 'id'
                      | 'key'
                      | 'laborCost'
                      | 'laborCostWithoutTax'
                      | 'laborShare'
                      | 'name'
                      | 'priority'
                      | 'registerDateTime'
                      | 'salesAmountWithoutTax'
                      | 'scheduledEndDateTime'
                      | 'scheduledStartDateTime'
                      | 'sortNoInGanttChart'
                      | 'sortNoInList'
                      | 'sortNoInProjectStatus'
                      | 'totalScheduleTimeSec'
                      | 'versionNo'
                      | 'workingTimeSec'
                    > & {
                        assignees: Array<
                          { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                            AssignedProjectMemberInfo,
                            'sortNo'
                          >
                        >;
                        client?: Maybe<
                          { __typename?: 'Client' } & Pick<
                            Client,
                            'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                          >
                        >;
                        createdMember?: Maybe<
                          { __typename?: 'Member' } & Pick<
                            Member,
                            | 'adminRole'
                            | 'clientManagementRole'
                            | 'department'
                            | 'employeeNumber'
                            | 'id'
                            | 'laborCostManagementRole'
                            | 'mailAddress'
                            | 'memberManagementRole'
                            | 'memberRegistrationDateTime'
                            | 'memberStatus'
                            | 'name'
                            | 'organizationMemberViewRole'
                            | 'organizationReportRole'
                            | 'profileImageUrl'
                            | 'projectCreatePermissionFlg'
                            | 'projectCustomAttributePermissionFlg'
                            | 'projectDeletePermissionFlg'
                            | 'projectUpdatePermissionFlg'
                            | 'proxyEditWorkingDataRole'
                            | 'salesManagementRole'
                            | 'statusMessage'
                            | 'taskCreatePermissionFlg'
                            | 'taskCustomAttributePermissionFlg'
                            | 'taskDeletePermissionFlg'
                            | 'taskUpdatePermissionFlg'
                            | 'teamMemberViewRole'
                            | 'teamReportRole'
                            | 'versionNo'
                          >
                        >;
                        favoriteMembers: Array<
                          Maybe<
                            { __typename?: 'Member' } & Pick<
                              Member,
                              | 'adminRole'
                              | 'clientManagementRole'
                              | 'department'
                              | 'employeeNumber'
                              | 'id'
                              | 'laborCostManagementRole'
                              | 'mailAddress'
                              | 'memberManagementRole'
                              | 'memberRegistrationDateTime'
                              | 'memberStatus'
                              | 'name'
                              | 'organizationMemberViewRole'
                              | 'organizationReportRole'
                              | 'profileImageUrl'
                              | 'projectCreatePermissionFlg'
                              | 'projectCustomAttributePermissionFlg'
                              | 'projectDeletePermissionFlg'
                              | 'projectUpdatePermissionFlg'
                              | 'proxyEditWorkingDataRole'
                              | 'salesManagementRole'
                              | 'statusMessage'
                              | 'taskCreatePermissionFlg'
                              | 'taskCustomAttributePermissionFlg'
                              | 'taskDeletePermissionFlg'
                              | 'taskUpdatePermissionFlg'
                              | 'teamMemberViewRole'
                              | 'teamReportRole'
                              | 'versionNo'
                            >
                          >
                        >;
                        favoriteMembersInfo: Array<
                          Maybe<
                            { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                              FavoriteProjectMemberInfo,
                              'sortNo'
                            >
                          >
                        >;
                        projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                          ProjectStatus,
                          'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                        >;
                        team: { __typename?: 'Team' } & Pick<
                          Team,
                          'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                        > & {
                            organization: { __typename?: 'Organization' } & Pick<
                              Organization,
                              | 'allowConnectThirdPartyCalendar'
                              | 'contactMailAddress'
                              | 'contractPreriod'
                              | 'id'
                              | 'imageUrl'
                              | 'ipRestrictionFlg'
                              | 'name'
                              | 'requiredTwoFactorAuth'
                              | 'suspensionFlg'
                              | 'versionNo'
                            > & {
                                plan: { __typename?: 'PlanProduct' } & Pick<
                                  PlanProduct,
                                  'code' | 'contractPreriod' | 'id'
                                >;
                              };
                          };
                      };
                    taskStatus: { __typename?: 'TaskStatus' } & Pick<
                      TaskStatus,
                      'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
                    > & {
                        project: { __typename?: 'Project' } & Pick<
                          Project,
                          | 'calendarLinkageCode'
                          | 'color'
                          | 'complete'
                          | 'completeDateTime'
                          | 'costBudgetWithoutTax'
                          | 'description'
                          | 'estimateTimeSec'
                          | 'id'
                          | 'key'
                          | 'laborCost'
                          | 'laborCostWithoutTax'
                          | 'laborShare'
                          | 'name'
                          | 'priority'
                          | 'registerDateTime'
                          | 'salesAmountWithoutTax'
                          | 'scheduledEndDateTime'
                          | 'scheduledStartDateTime'
                          | 'sortNoInGanttChart'
                          | 'sortNoInList'
                          | 'sortNoInProjectStatus'
                          | 'totalScheduleTimeSec'
                          | 'versionNo'
                          | 'workingTimeSec'
                        >;
                      };
                    workingMembers: Array<
                      Maybe<
                        { __typename?: 'WorkingMemberInfo' } & Pick<
                          WorkingMemberInfo,
                          'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                        > & {
                            member: { __typename?: 'Member' } & Pick<
                              Member,
                              | 'adminRole'
                              | 'clientManagementRole'
                              | 'department'
                              | 'employeeNumber'
                              | 'id'
                              | 'laborCostManagementRole'
                              | 'mailAddress'
                              | 'memberManagementRole'
                              | 'memberRegistrationDateTime'
                              | 'memberStatus'
                              | 'name'
                              | 'organizationMemberViewRole'
                              | 'organizationReportRole'
                              | 'profileImageUrl'
                              | 'projectCreatePermissionFlg'
                              | 'projectCustomAttributePermissionFlg'
                              | 'projectDeletePermissionFlg'
                              | 'projectUpdatePermissionFlg'
                              | 'proxyEditWorkingDataRole'
                              | 'salesManagementRole'
                              | 'statusMessage'
                              | 'taskCreatePermissionFlg'
                              | 'taskCustomAttributePermissionFlg'
                              | 'taskDeletePermissionFlg'
                              | 'taskUpdatePermissionFlg'
                              | 'teamMemberViewRole'
                              | 'teamReportRole'
                              | 'versionNo'
                            >;
                          }
                      >
                    >;
                  }
              >;
              workingMember: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
            }
        >
      >;
      task: { __typename?: 'Task' } & Pick<
        Task,
        | 'calendarLinkageCode'
        | 'completeDateTime'
        | 'description'
        | 'estimateTimeSec'
        | 'estimatedRemainingTimeSec'
        | 'id'
        | 'laborCost'
        | 'priority'
        | 'progressDeviationRate'
        | 'progressRate'
        | 'registerDateTime'
        | 'remainingTimeSec'
        | 'scheduleWorkingTimeSec'
        | 'scheduledEndDateTime'
        | 'scheduledStartDateTime'
        | 'sortNoInGanttChart'
        | 'sortNoInList'
        | 'sortNoInMyTask'
        | 'sortNoInTaskStatus'
        | 'title'
        | 'versionNo'
        | 'workingTimeSec'
      >;
    }
  >;
};

export type ConnectAppleAccountMutationVariables = Exact<{
  input?: Maybe<ConnectAppleAccountInputInput>;
}>;

export type ConnectAppleAccountMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'connectAppleAccount'
>;

export type ConnectGoogleAccountMutationVariables = Exact<{
  code: Scalars['String'];
}>;

export type ConnectGoogleAccountMutation = { __typename?: 'Mutation' } & {
  connectGoogleAccount?: Maybe<
    { __typename?: 'GoogleProfile' } & Pick<
      GoogleProfile,
      'domain' | 'mailAddress' | 'name' | 'profileImageUrl' | 'registerd' | 'token'
    >
  >;
};

export type CopyProjectMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: Maybe<CopyProjectInputInput>;
}>;

export type CopyProjectMutation = { __typename?: 'Mutation' } & {
  copyProject?: Maybe<
    { __typename?: 'Project' } & Pick<
      Project,
      | 'calendarLinkageCode'
      | 'color'
      | 'complete'
      | 'completeDateTime'
      | 'costBudgetWithoutTax'
      | 'description'
      | 'estimateTimeSec'
      | 'id'
      | 'key'
      | 'laborCost'
      | 'laborCostWithoutTax'
      | 'laborShare'
      | 'name'
      | 'priority'
      | 'registerDateTime'
      | 'salesAmountWithoutTax'
      | 'scheduledEndDateTime'
      | 'scheduledStartDateTime'
      | 'sortNoInGanttChart'
      | 'sortNoInList'
      | 'sortNoInProjectStatus'
      | 'totalScheduleTimeSec'
      | 'versionNo'
      | 'workingTimeSec'
    > & {
        assignees: Array<
          { __typename?: 'AssignedProjectMemberInfo' } & Pick<
            AssignedProjectMemberInfo,
            'sortNo'
          > & {
              member: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
            }
        >;
        client?: Maybe<
          { __typename?: 'Client' } & Pick<Client, 'code' | 'id' | 'name' | 'sortNo' | 'versionNo'>
        >;
        createdMember?: Maybe<
          { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >
        >;
        favoriteMembers: Array<
          Maybe<
            { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >
          >
        >;
        favoriteMembersInfo: Array<
          Maybe<
            { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
              FavoriteProjectMemberInfo,
              'sortNo'
            > & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
        projectStatus: { __typename?: 'ProjectStatus' } & Pick<
          ProjectStatus,
          'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
        >;
        team: { __typename?: 'Team' } & Pick<
          Team,
          'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
        > & {
            organization: { __typename?: 'Organization' } & Pick<
              Organization,
              | 'allowConnectThirdPartyCalendar'
              | 'contactMailAddress'
              | 'contractPreriod'
              | 'id'
              | 'imageUrl'
              | 'ipRestrictionFlg'
              | 'name'
              | 'requiredTwoFactorAuth'
              | 'suspensionFlg'
              | 'versionNo'
            > & {
                plan: { __typename?: 'PlanProduct' } & Pick<
                  PlanProduct,
                  'code' | 'contractPreriod' | 'id'
                >;
              };
          };
      }
  >;
};

export type CopyTaskMutationVariables = Exact<{
  id: Scalars['ID'];
  input: CopyTaskInputInput;
}>;

export type CopyTaskMutation = { __typename?: 'Mutation' } & {
  copyTask?: Maybe<
    { __typename?: 'Task' } & Pick<
      Task,
      | 'calendarLinkageCode'
      | 'completeDateTime'
      | 'description'
      | 'estimateTimeSec'
      | 'estimatedRemainingTimeSec'
      | 'id'
      | 'laborCost'
      | 'priority'
      | 'progressDeviationRate'
      | 'progressRate'
      | 'registerDateTime'
      | 'remainingTimeSec'
      | 'scheduleWorkingTimeSec'
      | 'scheduledEndDateTime'
      | 'scheduledStartDateTime'
      | 'sortNoInGanttChart'
      | 'sortNoInList'
      | 'sortNoInMyTask'
      | 'sortNoInTaskStatus'
      | 'title'
      | 'versionNo'
      | 'workingTimeSec'
    > & {
        assignees: Array<
          { __typename?: 'AssignedTaskMemberInfo' } & Pick<AssignedTaskMemberInfo, 'sortNo'> & {
              member: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
            }
        >;
        asssignee?: Maybe<
          { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >
        >;
        createdMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
        favoriteMembers: Array<
          Maybe<
            { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >
          >
        >;
        favoriteMembersInfo: Array<
          Maybe<
            { __typename?: 'FavoriteTaskMemberInfo' } & Pick<FavoriteTaskMemberInfo, 'sortNo'> & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
        project: { __typename?: 'Project' } & Pick<
          Project,
          | 'calendarLinkageCode'
          | 'color'
          | 'complete'
          | 'completeDateTime'
          | 'costBudgetWithoutTax'
          | 'description'
          | 'estimateTimeSec'
          | 'id'
          | 'key'
          | 'laborCost'
          | 'laborCostWithoutTax'
          | 'laborShare'
          | 'name'
          | 'priority'
          | 'registerDateTime'
          | 'salesAmountWithoutTax'
          | 'scheduledEndDateTime'
          | 'scheduledStartDateTime'
          | 'sortNoInGanttChart'
          | 'sortNoInList'
          | 'sortNoInProjectStatus'
          | 'totalScheduleTimeSec'
          | 'versionNo'
          | 'workingTimeSec'
        > & {
            assignees: Array<
              { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                AssignedProjectMemberInfo,
                'sortNo'
              >
            >;
            client?: Maybe<
              { __typename?: 'Client' } & Pick<
                Client,
                'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
              >
            >;
            createdMember?: Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >;
            favoriteMembers: Array<
              Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >
            >;
            favoriteMembersInfo: Array<
              Maybe<
                { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                  FavoriteProjectMemberInfo,
                  'sortNo'
                >
              >
            >;
            projectStatus: { __typename?: 'ProjectStatus' } & Pick<
              ProjectStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
            >;
            team: { __typename?: 'Team' } & Pick<
              Team,
              'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
            > & {
                organization: { __typename?: 'Organization' } & Pick<
                  Organization,
                  | 'allowConnectThirdPartyCalendar'
                  | 'contactMailAddress'
                  | 'contractPreriod'
                  | 'id'
                  | 'imageUrl'
                  | 'ipRestrictionFlg'
                  | 'name'
                  | 'requiredTwoFactorAuth'
                  | 'suspensionFlg'
                  | 'versionNo'
                > & {
                    plan: { __typename?: 'PlanProduct' } & Pick<
                      PlanProduct,
                      'code' | 'contractPreriod' | 'id'
                    >;
                  };
              };
          };
        taskStatus: { __typename?: 'TaskStatus' } & Pick<
          TaskStatus,
          'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
        > & {
            project: { __typename?: 'Project' } & Pick<
              Project,
              | 'calendarLinkageCode'
              | 'color'
              | 'complete'
              | 'completeDateTime'
              | 'costBudgetWithoutTax'
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'key'
              | 'laborCost'
              | 'laborCostWithoutTax'
              | 'laborShare'
              | 'name'
              | 'priority'
              | 'registerDateTime'
              | 'salesAmountWithoutTax'
              | 'scheduledEndDateTime'
              | 'scheduledStartDateTime'
              | 'sortNoInGanttChart'
              | 'sortNoInList'
              | 'sortNoInProjectStatus'
              | 'totalScheduleTimeSec'
              | 'versionNo'
              | 'workingTimeSec'
            >;
          };
        workingMembers: Array<
          Maybe<
            { __typename?: 'WorkingMemberInfo' } & Pick<
              WorkingMemberInfo,
              'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
            > & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
      }
  >;
};

export type CreateAttachementFileDownloadUrlMutationVariables = Exact<{
  input: CreateAttachementFileDownloadUrlInputInput;
}>;

export type CreateAttachementFileDownloadUrlMutation = { __typename?: 'Mutation' } & {
  createAttachementFileDownloadUrl?: Maybe<
    { __typename?: 'AttachmentFileDownloadInfo' } & Pick<AttachmentFileDownloadInfo, 'downloadUrl'>
  >;
};

export type CreateAttachementFileForProjectMutationVariables = Exact<{
  input: CreateProjectAttachmentFileInputInput;
}>;

export type CreateAttachementFileForProjectMutation = { __typename?: 'Mutation' } & {
  createAttachementFileForProject?: Maybe<
    { __typename?: 'AttachmentFile' } & Pick<
      AttachmentFile,
      'fileName' | 'id' | 'sizeByte' | 'type' | 'uploadDateTime'
    >
  >;
};

export type CreateAttachementFileForTaskMutationVariables = Exact<{
  input: CreateTaskAttachmentFileInputInput;
}>;

export type CreateAttachementFileForTaskMutation = { __typename?: 'Mutation' } & {
  createAttachementFileForTask?: Maybe<
    { __typename?: 'AttachmentFile' } & Pick<
      AttachmentFile,
      'fileName' | 'id' | 'sizeByte' | 'type' | 'uploadDateTime'
    >
  >;
};

export type CreateAttachementFileUploadUrlMutationVariables = Exact<{
  input: CreateAttachementFileUploadUrlInputInput;
}>;

export type CreateAttachementFileUploadUrlMutation = { __typename?: 'Mutation' } & {
  createAttachementFileUploadUrl?: Maybe<
    { __typename?: 'AttachmentFileUploadInfo' } & Pick<
      AttachmentFileUploadInfo,
      'fileName' | 'key' | 'sizeByte' | 'type' | 'uploadUrl'
    >
  >;
};

export type CreateCalendarEventWithTaskAndWorkingHistoryMutationVariables = Exact<{
  input: RegisterCalendarEventWithTaskInputInput;
  projectId: Scalars['String'];
}>;

export type CreateCalendarEventWithTaskAndWorkingHistoryMutation = { __typename?: 'Mutation' } & {
  createCalendarEventWithTaskAndWorkingHistory?: Maybe<
    { __typename?: 'CreateCalendarEventWithWorkingHistoryResult' } & {
      calendarEvent: { __typename?: 'CalendarEvent' } & Pick<
        CalendarEvent,
        | 'calendarType'
        | 'clientId'
        | 'clientName'
        | 'endDateTime'
        | 'eventName'
        | 'id'
        | 'laborCost'
        | 'personalTeam'
        | 'projectColor'
        | 'projectId'
        | 'projectName'
        | 'startDateTime'
        | 'taskId'
        | 'taskTitle'
        | 'teamId'
        | 'teamName'
        | 'thirdPartyCalendarEventId'
        | 'versionNo'
        | 'workingTimeSec'
      > & {
          thirdPartyCalendarInfoList: Array<
            Maybe<
              { __typename?: 'ThirdPartyCalendarInfo' } & Pick<
                ThirdPartyCalendarInfo,
                'thirdPartyCalendarId'
              > & {
                  member: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                }
            >
          >;
        };
      workingHistory: { __typename?: 'WorkingHistory' } & Pick<
        WorkingHistory,
        'end' | 'id' | 'memo' | 'start' | 'versionNo' | 'workingTimeSec'
      > & {
          calendarEvent?: Maybe<
            { __typename?: 'CalendarEvent' } & Pick<
              CalendarEvent,
              | 'calendarType'
              | 'clientId'
              | 'clientName'
              | 'endDateTime'
              | 'eventName'
              | 'id'
              | 'laborCost'
              | 'personalTeam'
              | 'projectColor'
              | 'projectId'
              | 'projectName'
              | 'startDateTime'
              | 'taskId'
              | 'taskTitle'
              | 'teamId'
              | 'teamName'
              | 'thirdPartyCalendarEventId'
              | 'versionNo'
              | 'workingTimeSec'
            >
          >;
          closingProcessInfo: { __typename?: 'ClosingProcessPart' } & Pick<
            ClosingProcessPart,
            'closed' | 'targetDateTime'
          >;
          task?: Maybe<
            { __typename?: 'Task' } & Pick<
              Task,
              | 'calendarLinkageCode'
              | 'completeDateTime'
              | 'description'
              | 'estimateTimeSec'
              | 'estimatedRemainingTimeSec'
              | 'id'
              | 'laborCost'
              | 'priority'
              | 'progressDeviationRate'
              | 'progressRate'
              | 'registerDateTime'
              | 'remainingTimeSec'
              | 'scheduleWorkingTimeSec'
              | 'scheduledEndDateTime'
              | 'scheduledStartDateTime'
              | 'sortNoInGanttChart'
              | 'sortNoInList'
              | 'sortNoInMyTask'
              | 'sortNoInTaskStatus'
              | 'title'
              | 'versionNo'
              | 'workingTimeSec'
            > & {
                assignees: Array<
                  { __typename?: 'AssignedTaskMemberInfo' } & Pick<
                    AssignedTaskMemberInfo,
                    'sortNo'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >;
                asssignee?: Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >;
                createdMember: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
                favoriteMembers: Array<
                  Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >
                >;
                favoriteMembersInfo: Array<
                  Maybe<
                    { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                      FavoriteTaskMemberInfo,
                      'sortNo'
                    > & {
                        member: { __typename?: 'Member' } & Pick<
                          Member,
                          | 'adminRole'
                          | 'clientManagementRole'
                          | 'department'
                          | 'employeeNumber'
                          | 'id'
                          | 'laborCostManagementRole'
                          | 'mailAddress'
                          | 'memberManagementRole'
                          | 'memberRegistrationDateTime'
                          | 'memberStatus'
                          | 'name'
                          | 'organizationMemberViewRole'
                          | 'organizationReportRole'
                          | 'profileImageUrl'
                          | 'projectCreatePermissionFlg'
                          | 'projectCustomAttributePermissionFlg'
                          | 'projectDeletePermissionFlg'
                          | 'projectUpdatePermissionFlg'
                          | 'proxyEditWorkingDataRole'
                          | 'salesManagementRole'
                          | 'statusMessage'
                          | 'taskCreatePermissionFlg'
                          | 'taskCustomAttributePermissionFlg'
                          | 'taskDeletePermissionFlg'
                          | 'taskUpdatePermissionFlg'
                          | 'teamMemberViewRole'
                          | 'teamReportRole'
                          | 'versionNo'
                        >;
                      }
                  >
                >;
                project: { __typename?: 'Project' } & Pick<
                  Project,
                  | 'calendarLinkageCode'
                  | 'color'
                  | 'complete'
                  | 'completeDateTime'
                  | 'costBudgetWithoutTax'
                  | 'description'
                  | 'estimateTimeSec'
                  | 'id'
                  | 'key'
                  | 'laborCost'
                  | 'laborCostWithoutTax'
                  | 'laborShare'
                  | 'name'
                  | 'priority'
                  | 'registerDateTime'
                  | 'salesAmountWithoutTax'
                  | 'scheduledEndDateTime'
                  | 'scheduledStartDateTime'
                  | 'sortNoInGanttChart'
                  | 'sortNoInList'
                  | 'sortNoInProjectStatus'
                  | 'totalScheduleTimeSec'
                  | 'versionNo'
                  | 'workingTimeSec'
                > & {
                    assignees: Array<
                      { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                        AssignedProjectMemberInfo,
                        'sortNo'
                      >
                    >;
                    client?: Maybe<
                      { __typename?: 'Client' } & Pick<
                        Client,
                        'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                      >
                    >;
                    createdMember?: Maybe<
                      { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >
                    >;
                    favoriteMembers: Array<
                      Maybe<
                        { __typename?: 'Member' } & Pick<
                          Member,
                          | 'adminRole'
                          | 'clientManagementRole'
                          | 'department'
                          | 'employeeNumber'
                          | 'id'
                          | 'laborCostManagementRole'
                          | 'mailAddress'
                          | 'memberManagementRole'
                          | 'memberRegistrationDateTime'
                          | 'memberStatus'
                          | 'name'
                          | 'organizationMemberViewRole'
                          | 'organizationReportRole'
                          | 'profileImageUrl'
                          | 'projectCreatePermissionFlg'
                          | 'projectCustomAttributePermissionFlg'
                          | 'projectDeletePermissionFlg'
                          | 'projectUpdatePermissionFlg'
                          | 'proxyEditWorkingDataRole'
                          | 'salesManagementRole'
                          | 'statusMessage'
                          | 'taskCreatePermissionFlg'
                          | 'taskCustomAttributePermissionFlg'
                          | 'taskDeletePermissionFlg'
                          | 'taskUpdatePermissionFlg'
                          | 'teamMemberViewRole'
                          | 'teamReportRole'
                          | 'versionNo'
                        >
                      >
                    >;
                    favoriteMembersInfo: Array<
                      Maybe<
                        { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                          FavoriteProjectMemberInfo,
                          'sortNo'
                        >
                      >
                    >;
                    projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                      ProjectStatus,
                      'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                    >;
                    team: { __typename?: 'Team' } & Pick<
                      Team,
                      'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                    > & {
                        organization: { __typename?: 'Organization' } & Pick<
                          Organization,
                          | 'allowConnectThirdPartyCalendar'
                          | 'contactMailAddress'
                          | 'contractPreriod'
                          | 'id'
                          | 'imageUrl'
                          | 'ipRestrictionFlg'
                          | 'name'
                          | 'requiredTwoFactorAuth'
                          | 'suspensionFlg'
                          | 'versionNo'
                        > & {
                            plan: { __typename?: 'PlanProduct' } & Pick<
                              PlanProduct,
                              'code' | 'contractPreriod' | 'id'
                            >;
                          };
                      };
                  };
                taskStatus: { __typename?: 'TaskStatus' } & Pick<
                  TaskStatus,
                  'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
                > & {
                    project: { __typename?: 'Project' } & Pick<
                      Project,
                      | 'calendarLinkageCode'
                      | 'color'
                      | 'complete'
                      | 'completeDateTime'
                      | 'costBudgetWithoutTax'
                      | 'description'
                      | 'estimateTimeSec'
                      | 'id'
                      | 'key'
                      | 'laborCost'
                      | 'laborCostWithoutTax'
                      | 'laborShare'
                      | 'name'
                      | 'priority'
                      | 'registerDateTime'
                      | 'salesAmountWithoutTax'
                      | 'scheduledEndDateTime'
                      | 'scheduledStartDateTime'
                      | 'sortNoInGanttChart'
                      | 'sortNoInList'
                      | 'sortNoInProjectStatus'
                      | 'totalScheduleTimeSec'
                      | 'versionNo'
                      | 'workingTimeSec'
                    >;
                  };
                workingMembers: Array<
                  Maybe<
                    { __typename?: 'WorkingMemberInfo' } & Pick<
                      WorkingMemberInfo,
                      'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                    > & {
                        member: { __typename?: 'Member' } & Pick<
                          Member,
                          | 'adminRole'
                          | 'clientManagementRole'
                          | 'department'
                          | 'employeeNumber'
                          | 'id'
                          | 'laborCostManagementRole'
                          | 'mailAddress'
                          | 'memberManagementRole'
                          | 'memberRegistrationDateTime'
                          | 'memberStatus'
                          | 'name'
                          | 'organizationMemberViewRole'
                          | 'organizationReportRole'
                          | 'profileImageUrl'
                          | 'projectCreatePermissionFlg'
                          | 'projectCustomAttributePermissionFlg'
                          | 'projectDeletePermissionFlg'
                          | 'projectUpdatePermissionFlg'
                          | 'proxyEditWorkingDataRole'
                          | 'salesManagementRole'
                          | 'statusMessage'
                          | 'taskCreatePermissionFlg'
                          | 'taskCustomAttributePermissionFlg'
                          | 'taskDeletePermissionFlg'
                          | 'taskUpdatePermissionFlg'
                          | 'teamMemberViewRole'
                          | 'teamReportRole'
                          | 'versionNo'
                        >;
                      }
                  >
                >;
              }
          >;
          workingMember: { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >;
        };
    }
  >;
};

export type CreateCalendarEventWithTaskAndWorkingScheduleMutationVariables = Exact<{
  input: RegisterCalendarEventWithTaskInputInput;
  projectId: Scalars['String'];
}>;

export type CreateCalendarEventWithTaskAndWorkingScheduleMutation = { __typename?: 'Mutation' } & {
  createCalendarEventWithTaskAndWorkingSchedule?: Maybe<
    { __typename?: 'CreateCalendarEventWithWorkingScheduleResult' } & {
      calendarEvent: { __typename?: 'CalendarEvent' } & Pick<
        CalendarEvent,
        | 'calendarType'
        | 'clientId'
        | 'clientName'
        | 'endDateTime'
        | 'eventName'
        | 'id'
        | 'laborCost'
        | 'personalTeam'
        | 'projectColor'
        | 'projectId'
        | 'projectName'
        | 'startDateTime'
        | 'taskId'
        | 'taskTitle'
        | 'teamId'
        | 'teamName'
        | 'thirdPartyCalendarEventId'
        | 'versionNo'
        | 'workingTimeSec'
      > & {
          thirdPartyCalendarInfoList: Array<
            Maybe<
              { __typename?: 'ThirdPartyCalendarInfo' } & Pick<
                ThirdPartyCalendarInfo,
                'thirdPartyCalendarId'
              > & {
                  member: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                }
            >
          >;
        };
      workingSchedule: { __typename?: 'WorkingSchedule' } & Pick<
        WorkingSchedule,
        'end' | 'id' | 'memo' | 'start' | 'versionNo' | 'workingTimeSec'
      > & {
          calendarEvent?: Maybe<
            { __typename?: 'CalendarEvent' } & Pick<
              CalendarEvent,
              | 'calendarType'
              | 'clientId'
              | 'clientName'
              | 'endDateTime'
              | 'eventName'
              | 'id'
              | 'laborCost'
              | 'personalTeam'
              | 'projectColor'
              | 'projectId'
              | 'projectName'
              | 'startDateTime'
              | 'taskId'
              | 'taskTitle'
              | 'teamId'
              | 'teamName'
              | 'thirdPartyCalendarEventId'
              | 'versionNo'
              | 'workingTimeSec'
            >
          >;
          task?: Maybe<
            { __typename?: 'Task' } & Pick<
              Task,
              | 'calendarLinkageCode'
              | 'completeDateTime'
              | 'description'
              | 'estimateTimeSec'
              | 'estimatedRemainingTimeSec'
              | 'id'
              | 'laborCost'
              | 'priority'
              | 'progressDeviationRate'
              | 'progressRate'
              | 'registerDateTime'
              | 'remainingTimeSec'
              | 'scheduleWorkingTimeSec'
              | 'scheduledEndDateTime'
              | 'scheduledStartDateTime'
              | 'sortNoInGanttChart'
              | 'sortNoInList'
              | 'sortNoInMyTask'
              | 'sortNoInTaskStatus'
              | 'title'
              | 'versionNo'
              | 'workingTimeSec'
            > & {
                assignees: Array<
                  { __typename?: 'AssignedTaskMemberInfo' } & Pick<
                    AssignedTaskMemberInfo,
                    'sortNo'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >;
                asssignee?: Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >;
                createdMember: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
                favoriteMembers: Array<
                  Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >
                >;
                favoriteMembersInfo: Array<
                  Maybe<
                    { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                      FavoriteTaskMemberInfo,
                      'sortNo'
                    > & {
                        member: { __typename?: 'Member' } & Pick<
                          Member,
                          | 'adminRole'
                          | 'clientManagementRole'
                          | 'department'
                          | 'employeeNumber'
                          | 'id'
                          | 'laborCostManagementRole'
                          | 'mailAddress'
                          | 'memberManagementRole'
                          | 'memberRegistrationDateTime'
                          | 'memberStatus'
                          | 'name'
                          | 'organizationMemberViewRole'
                          | 'organizationReportRole'
                          | 'profileImageUrl'
                          | 'projectCreatePermissionFlg'
                          | 'projectCustomAttributePermissionFlg'
                          | 'projectDeletePermissionFlg'
                          | 'projectUpdatePermissionFlg'
                          | 'proxyEditWorkingDataRole'
                          | 'salesManagementRole'
                          | 'statusMessage'
                          | 'taskCreatePermissionFlg'
                          | 'taskCustomAttributePermissionFlg'
                          | 'taskDeletePermissionFlg'
                          | 'taskUpdatePermissionFlg'
                          | 'teamMemberViewRole'
                          | 'teamReportRole'
                          | 'versionNo'
                        >;
                      }
                  >
                >;
                project: { __typename?: 'Project' } & Pick<
                  Project,
                  | 'calendarLinkageCode'
                  | 'color'
                  | 'complete'
                  | 'completeDateTime'
                  | 'costBudgetWithoutTax'
                  | 'description'
                  | 'estimateTimeSec'
                  | 'id'
                  | 'key'
                  | 'laborCost'
                  | 'laborCostWithoutTax'
                  | 'laborShare'
                  | 'name'
                  | 'priority'
                  | 'registerDateTime'
                  | 'salesAmountWithoutTax'
                  | 'scheduledEndDateTime'
                  | 'scheduledStartDateTime'
                  | 'sortNoInGanttChart'
                  | 'sortNoInList'
                  | 'sortNoInProjectStatus'
                  | 'totalScheduleTimeSec'
                  | 'versionNo'
                  | 'workingTimeSec'
                > & {
                    assignees: Array<
                      { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                        AssignedProjectMemberInfo,
                        'sortNo'
                      >
                    >;
                    client?: Maybe<
                      { __typename?: 'Client' } & Pick<
                        Client,
                        'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                      >
                    >;
                    createdMember?: Maybe<
                      { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >
                    >;
                    favoriteMembers: Array<
                      Maybe<
                        { __typename?: 'Member' } & Pick<
                          Member,
                          | 'adminRole'
                          | 'clientManagementRole'
                          | 'department'
                          | 'employeeNumber'
                          | 'id'
                          | 'laborCostManagementRole'
                          | 'mailAddress'
                          | 'memberManagementRole'
                          | 'memberRegistrationDateTime'
                          | 'memberStatus'
                          | 'name'
                          | 'organizationMemberViewRole'
                          | 'organizationReportRole'
                          | 'profileImageUrl'
                          | 'projectCreatePermissionFlg'
                          | 'projectCustomAttributePermissionFlg'
                          | 'projectDeletePermissionFlg'
                          | 'projectUpdatePermissionFlg'
                          | 'proxyEditWorkingDataRole'
                          | 'salesManagementRole'
                          | 'statusMessage'
                          | 'taskCreatePermissionFlg'
                          | 'taskCustomAttributePermissionFlg'
                          | 'taskDeletePermissionFlg'
                          | 'taskUpdatePermissionFlg'
                          | 'teamMemberViewRole'
                          | 'teamReportRole'
                          | 'versionNo'
                        >
                      >
                    >;
                    favoriteMembersInfo: Array<
                      Maybe<
                        { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                          FavoriteProjectMemberInfo,
                          'sortNo'
                        >
                      >
                    >;
                    projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                      ProjectStatus,
                      'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                    >;
                    team: { __typename?: 'Team' } & Pick<
                      Team,
                      'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                    > & {
                        organization: { __typename?: 'Organization' } & Pick<
                          Organization,
                          | 'allowConnectThirdPartyCalendar'
                          | 'contactMailAddress'
                          | 'contractPreriod'
                          | 'id'
                          | 'imageUrl'
                          | 'ipRestrictionFlg'
                          | 'name'
                          | 'requiredTwoFactorAuth'
                          | 'suspensionFlg'
                          | 'versionNo'
                        > & {
                            plan: { __typename?: 'PlanProduct' } & Pick<
                              PlanProduct,
                              'code' | 'contractPreriod' | 'id'
                            >;
                          };
                      };
                  };
                taskStatus: { __typename?: 'TaskStatus' } & Pick<
                  TaskStatus,
                  'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
                > & {
                    project: { __typename?: 'Project' } & Pick<
                      Project,
                      | 'calendarLinkageCode'
                      | 'color'
                      | 'complete'
                      | 'completeDateTime'
                      | 'costBudgetWithoutTax'
                      | 'description'
                      | 'estimateTimeSec'
                      | 'id'
                      | 'key'
                      | 'laborCost'
                      | 'laborCostWithoutTax'
                      | 'laborShare'
                      | 'name'
                      | 'priority'
                      | 'registerDateTime'
                      | 'salesAmountWithoutTax'
                      | 'scheduledEndDateTime'
                      | 'scheduledStartDateTime'
                      | 'sortNoInGanttChart'
                      | 'sortNoInList'
                      | 'sortNoInProjectStatus'
                      | 'totalScheduleTimeSec'
                      | 'versionNo'
                      | 'workingTimeSec'
                    >;
                  };
                workingMembers: Array<
                  Maybe<
                    { __typename?: 'WorkingMemberInfo' } & Pick<
                      WorkingMemberInfo,
                      'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                    > & {
                        member: { __typename?: 'Member' } & Pick<
                          Member,
                          | 'adminRole'
                          | 'clientManagementRole'
                          | 'department'
                          | 'employeeNumber'
                          | 'id'
                          | 'laborCostManagementRole'
                          | 'mailAddress'
                          | 'memberManagementRole'
                          | 'memberRegistrationDateTime'
                          | 'memberStatus'
                          | 'name'
                          | 'organizationMemberViewRole'
                          | 'organizationReportRole'
                          | 'profileImageUrl'
                          | 'projectCreatePermissionFlg'
                          | 'projectCustomAttributePermissionFlg'
                          | 'projectDeletePermissionFlg'
                          | 'projectUpdatePermissionFlg'
                          | 'proxyEditWorkingDataRole'
                          | 'salesManagementRole'
                          | 'statusMessage'
                          | 'taskCreatePermissionFlg'
                          | 'taskCustomAttributePermissionFlg'
                          | 'taskDeletePermissionFlg'
                          | 'taskUpdatePermissionFlg'
                          | 'teamMemberViewRole'
                          | 'teamReportRole'
                          | 'versionNo'
                        >;
                      }
                  >
                >;
              }
          >;
          workingMember: { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >;
        };
    }
  >;
};

export type CreateCalendarEventWithWorkingHistoryMutationVariables = Exact<{
  input: RegisterCalendarEventInputInput;
  projectId: Scalars['String'];
  taskId?: Maybe<Scalars['String']>;
}>;

export type CreateCalendarEventWithWorkingHistoryMutation = { __typename?: 'Mutation' } & {
  createCalendarEventWithWorkingHistory?: Maybe<
    { __typename?: 'CreateCalendarEventWithWorkingHistoryResult' } & {
      calendarEvent: { __typename?: 'CalendarEvent' } & Pick<
        CalendarEvent,
        | 'calendarType'
        | 'clientId'
        | 'clientName'
        | 'endDateTime'
        | 'eventName'
        | 'id'
        | 'laborCost'
        | 'personalTeam'
        | 'projectColor'
        | 'projectId'
        | 'projectName'
        | 'startDateTime'
        | 'taskId'
        | 'taskTitle'
        | 'teamId'
        | 'teamName'
        | 'thirdPartyCalendarEventId'
        | 'versionNo'
        | 'workingTimeSec'
      > & {
          thirdPartyCalendarInfoList: Array<
            Maybe<
              { __typename?: 'ThirdPartyCalendarInfo' } & Pick<
                ThirdPartyCalendarInfo,
                'thirdPartyCalendarId'
              > & {
                  member: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                }
            >
          >;
        };
      workingHistory: { __typename?: 'WorkingHistory' } & Pick<
        WorkingHistory,
        'end' | 'id' | 'memo' | 'start' | 'versionNo' | 'workingTimeSec'
      > & {
          calendarEvent?: Maybe<
            { __typename?: 'CalendarEvent' } & Pick<
              CalendarEvent,
              | 'calendarType'
              | 'clientId'
              | 'clientName'
              | 'endDateTime'
              | 'eventName'
              | 'id'
              | 'laborCost'
              | 'personalTeam'
              | 'projectColor'
              | 'projectId'
              | 'projectName'
              | 'startDateTime'
              | 'taskId'
              | 'taskTitle'
              | 'teamId'
              | 'teamName'
              | 'thirdPartyCalendarEventId'
              | 'versionNo'
              | 'workingTimeSec'
            >
          >;
          closingProcessInfo: { __typename?: 'ClosingProcessPart' } & Pick<
            ClosingProcessPart,
            'closed' | 'targetDateTime'
          >;
          task?: Maybe<
            { __typename?: 'Task' } & Pick<
              Task,
              | 'calendarLinkageCode'
              | 'completeDateTime'
              | 'description'
              | 'estimateTimeSec'
              | 'estimatedRemainingTimeSec'
              | 'id'
              | 'laborCost'
              | 'priority'
              | 'progressDeviationRate'
              | 'progressRate'
              | 'registerDateTime'
              | 'remainingTimeSec'
              | 'scheduleWorkingTimeSec'
              | 'scheduledEndDateTime'
              | 'scheduledStartDateTime'
              | 'sortNoInGanttChart'
              | 'sortNoInList'
              | 'sortNoInMyTask'
              | 'sortNoInTaskStatus'
              | 'title'
              | 'versionNo'
              | 'workingTimeSec'
            > & {
                assignees: Array<
                  { __typename?: 'AssignedTaskMemberInfo' } & Pick<
                    AssignedTaskMemberInfo,
                    'sortNo'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >;
                asssignee?: Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >;
                createdMember: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
                favoriteMembers: Array<
                  Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >
                >;
                favoriteMembersInfo: Array<
                  Maybe<
                    { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                      FavoriteTaskMemberInfo,
                      'sortNo'
                    > & {
                        member: { __typename?: 'Member' } & Pick<
                          Member,
                          | 'adminRole'
                          | 'clientManagementRole'
                          | 'department'
                          | 'employeeNumber'
                          | 'id'
                          | 'laborCostManagementRole'
                          | 'mailAddress'
                          | 'memberManagementRole'
                          | 'memberRegistrationDateTime'
                          | 'memberStatus'
                          | 'name'
                          | 'organizationMemberViewRole'
                          | 'organizationReportRole'
                          | 'profileImageUrl'
                          | 'projectCreatePermissionFlg'
                          | 'projectCustomAttributePermissionFlg'
                          | 'projectDeletePermissionFlg'
                          | 'projectUpdatePermissionFlg'
                          | 'proxyEditWorkingDataRole'
                          | 'salesManagementRole'
                          | 'statusMessage'
                          | 'taskCreatePermissionFlg'
                          | 'taskCustomAttributePermissionFlg'
                          | 'taskDeletePermissionFlg'
                          | 'taskUpdatePermissionFlg'
                          | 'teamMemberViewRole'
                          | 'teamReportRole'
                          | 'versionNo'
                        >;
                      }
                  >
                >;
                project: { __typename?: 'Project' } & Pick<
                  Project,
                  | 'calendarLinkageCode'
                  | 'color'
                  | 'complete'
                  | 'completeDateTime'
                  | 'costBudgetWithoutTax'
                  | 'description'
                  | 'estimateTimeSec'
                  | 'id'
                  | 'key'
                  | 'laborCost'
                  | 'laborCostWithoutTax'
                  | 'laborShare'
                  | 'name'
                  | 'priority'
                  | 'registerDateTime'
                  | 'salesAmountWithoutTax'
                  | 'scheduledEndDateTime'
                  | 'scheduledStartDateTime'
                  | 'sortNoInGanttChart'
                  | 'sortNoInList'
                  | 'sortNoInProjectStatus'
                  | 'totalScheduleTimeSec'
                  | 'versionNo'
                  | 'workingTimeSec'
                > & {
                    assignees: Array<
                      { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                        AssignedProjectMemberInfo,
                        'sortNo'
                      >
                    >;
                    client?: Maybe<
                      { __typename?: 'Client' } & Pick<
                        Client,
                        'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                      >
                    >;
                    createdMember?: Maybe<
                      { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >
                    >;
                    favoriteMembers: Array<
                      Maybe<
                        { __typename?: 'Member' } & Pick<
                          Member,
                          | 'adminRole'
                          | 'clientManagementRole'
                          | 'department'
                          | 'employeeNumber'
                          | 'id'
                          | 'laborCostManagementRole'
                          | 'mailAddress'
                          | 'memberManagementRole'
                          | 'memberRegistrationDateTime'
                          | 'memberStatus'
                          | 'name'
                          | 'organizationMemberViewRole'
                          | 'organizationReportRole'
                          | 'profileImageUrl'
                          | 'projectCreatePermissionFlg'
                          | 'projectCustomAttributePermissionFlg'
                          | 'projectDeletePermissionFlg'
                          | 'projectUpdatePermissionFlg'
                          | 'proxyEditWorkingDataRole'
                          | 'salesManagementRole'
                          | 'statusMessage'
                          | 'taskCreatePermissionFlg'
                          | 'taskCustomAttributePermissionFlg'
                          | 'taskDeletePermissionFlg'
                          | 'taskUpdatePermissionFlg'
                          | 'teamMemberViewRole'
                          | 'teamReportRole'
                          | 'versionNo'
                        >
                      >
                    >;
                    favoriteMembersInfo: Array<
                      Maybe<
                        { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                          FavoriteProjectMemberInfo,
                          'sortNo'
                        >
                      >
                    >;
                    projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                      ProjectStatus,
                      'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                    >;
                    team: { __typename?: 'Team' } & Pick<
                      Team,
                      'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                    > & {
                        organization: { __typename?: 'Organization' } & Pick<
                          Organization,
                          | 'allowConnectThirdPartyCalendar'
                          | 'contactMailAddress'
                          | 'contractPreriod'
                          | 'id'
                          | 'imageUrl'
                          | 'ipRestrictionFlg'
                          | 'name'
                          | 'requiredTwoFactorAuth'
                          | 'suspensionFlg'
                          | 'versionNo'
                        > & {
                            plan: { __typename?: 'PlanProduct' } & Pick<
                              PlanProduct,
                              'code' | 'contractPreriod' | 'id'
                            >;
                          };
                      };
                  };
                taskStatus: { __typename?: 'TaskStatus' } & Pick<
                  TaskStatus,
                  'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
                > & {
                    project: { __typename?: 'Project' } & Pick<
                      Project,
                      | 'calendarLinkageCode'
                      | 'color'
                      | 'complete'
                      | 'completeDateTime'
                      | 'costBudgetWithoutTax'
                      | 'description'
                      | 'estimateTimeSec'
                      | 'id'
                      | 'key'
                      | 'laborCost'
                      | 'laborCostWithoutTax'
                      | 'laborShare'
                      | 'name'
                      | 'priority'
                      | 'registerDateTime'
                      | 'salesAmountWithoutTax'
                      | 'scheduledEndDateTime'
                      | 'scheduledStartDateTime'
                      | 'sortNoInGanttChart'
                      | 'sortNoInList'
                      | 'sortNoInProjectStatus'
                      | 'totalScheduleTimeSec'
                      | 'versionNo'
                      | 'workingTimeSec'
                    >;
                  };
                workingMembers: Array<
                  Maybe<
                    { __typename?: 'WorkingMemberInfo' } & Pick<
                      WorkingMemberInfo,
                      'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                    > & {
                        member: { __typename?: 'Member' } & Pick<
                          Member,
                          | 'adminRole'
                          | 'clientManagementRole'
                          | 'department'
                          | 'employeeNumber'
                          | 'id'
                          | 'laborCostManagementRole'
                          | 'mailAddress'
                          | 'memberManagementRole'
                          | 'memberRegistrationDateTime'
                          | 'memberStatus'
                          | 'name'
                          | 'organizationMemberViewRole'
                          | 'organizationReportRole'
                          | 'profileImageUrl'
                          | 'projectCreatePermissionFlg'
                          | 'projectCustomAttributePermissionFlg'
                          | 'projectDeletePermissionFlg'
                          | 'projectUpdatePermissionFlg'
                          | 'proxyEditWorkingDataRole'
                          | 'salesManagementRole'
                          | 'statusMessage'
                          | 'taskCreatePermissionFlg'
                          | 'taskCustomAttributePermissionFlg'
                          | 'taskDeletePermissionFlg'
                          | 'taskUpdatePermissionFlg'
                          | 'teamMemberViewRole'
                          | 'teamReportRole'
                          | 'versionNo'
                        >;
                      }
                  >
                >;
              }
          >;
          workingMember: { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >;
        };
    }
  >;
};

export type CreateCalendarEventWithWorkingScheduleMutationVariables = Exact<{
  input: RegisterCalendarEventInputInput;
  projectId: Scalars['String'];
  taskId?: Maybe<Scalars['String']>;
}>;

export type CreateCalendarEventWithWorkingScheduleMutation = { __typename?: 'Mutation' } & {
  createCalendarEventWithWorkingSchedule?: Maybe<
    { __typename?: 'CreateCalendarEventWithWorkingScheduleResult' } & {
      calendarEvent: { __typename?: 'CalendarEvent' } & Pick<
        CalendarEvent,
        | 'calendarType'
        | 'clientId'
        | 'clientName'
        | 'endDateTime'
        | 'eventName'
        | 'id'
        | 'laborCost'
        | 'personalTeam'
        | 'projectColor'
        | 'projectId'
        | 'projectName'
        | 'startDateTime'
        | 'taskId'
        | 'taskTitle'
        | 'teamId'
        | 'teamName'
        | 'thirdPartyCalendarEventId'
        | 'versionNo'
        | 'workingTimeSec'
      > & {
          thirdPartyCalendarInfoList: Array<
            Maybe<
              { __typename?: 'ThirdPartyCalendarInfo' } & Pick<
                ThirdPartyCalendarInfo,
                'thirdPartyCalendarId'
              > & {
                  member: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                }
            >
          >;
        };
      workingSchedule: { __typename?: 'WorkingSchedule' } & Pick<
        WorkingSchedule,
        'end' | 'id' | 'memo' | 'start' | 'versionNo' | 'workingTimeSec'
      > & {
          calendarEvent?: Maybe<
            { __typename?: 'CalendarEvent' } & Pick<
              CalendarEvent,
              | 'calendarType'
              | 'clientId'
              | 'clientName'
              | 'endDateTime'
              | 'eventName'
              | 'id'
              | 'laborCost'
              | 'personalTeam'
              | 'projectColor'
              | 'projectId'
              | 'projectName'
              | 'startDateTime'
              | 'taskId'
              | 'taskTitle'
              | 'teamId'
              | 'teamName'
              | 'thirdPartyCalendarEventId'
              | 'versionNo'
              | 'workingTimeSec'
            >
          >;
          task?: Maybe<
            { __typename?: 'Task' } & Pick<
              Task,
              | 'calendarLinkageCode'
              | 'completeDateTime'
              | 'description'
              | 'estimateTimeSec'
              | 'estimatedRemainingTimeSec'
              | 'id'
              | 'laborCost'
              | 'priority'
              | 'progressDeviationRate'
              | 'progressRate'
              | 'registerDateTime'
              | 'remainingTimeSec'
              | 'scheduleWorkingTimeSec'
              | 'scheduledEndDateTime'
              | 'scheduledStartDateTime'
              | 'sortNoInGanttChart'
              | 'sortNoInList'
              | 'sortNoInMyTask'
              | 'sortNoInTaskStatus'
              | 'title'
              | 'versionNo'
              | 'workingTimeSec'
            > & {
                assignees: Array<
                  { __typename?: 'AssignedTaskMemberInfo' } & Pick<
                    AssignedTaskMemberInfo,
                    'sortNo'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >;
                asssignee?: Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >;
                createdMember: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
                favoriteMembers: Array<
                  Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >
                >;
                favoriteMembersInfo: Array<
                  Maybe<
                    { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                      FavoriteTaskMemberInfo,
                      'sortNo'
                    > & {
                        member: { __typename?: 'Member' } & Pick<
                          Member,
                          | 'adminRole'
                          | 'clientManagementRole'
                          | 'department'
                          | 'employeeNumber'
                          | 'id'
                          | 'laborCostManagementRole'
                          | 'mailAddress'
                          | 'memberManagementRole'
                          | 'memberRegistrationDateTime'
                          | 'memberStatus'
                          | 'name'
                          | 'organizationMemberViewRole'
                          | 'organizationReportRole'
                          | 'profileImageUrl'
                          | 'projectCreatePermissionFlg'
                          | 'projectCustomAttributePermissionFlg'
                          | 'projectDeletePermissionFlg'
                          | 'projectUpdatePermissionFlg'
                          | 'proxyEditWorkingDataRole'
                          | 'salesManagementRole'
                          | 'statusMessage'
                          | 'taskCreatePermissionFlg'
                          | 'taskCustomAttributePermissionFlg'
                          | 'taskDeletePermissionFlg'
                          | 'taskUpdatePermissionFlg'
                          | 'teamMemberViewRole'
                          | 'teamReportRole'
                          | 'versionNo'
                        >;
                      }
                  >
                >;
                project: { __typename?: 'Project' } & Pick<
                  Project,
                  | 'calendarLinkageCode'
                  | 'color'
                  | 'complete'
                  | 'completeDateTime'
                  | 'costBudgetWithoutTax'
                  | 'description'
                  | 'estimateTimeSec'
                  | 'id'
                  | 'key'
                  | 'laborCost'
                  | 'laborCostWithoutTax'
                  | 'laborShare'
                  | 'name'
                  | 'priority'
                  | 'registerDateTime'
                  | 'salesAmountWithoutTax'
                  | 'scheduledEndDateTime'
                  | 'scheduledStartDateTime'
                  | 'sortNoInGanttChart'
                  | 'sortNoInList'
                  | 'sortNoInProjectStatus'
                  | 'totalScheduleTimeSec'
                  | 'versionNo'
                  | 'workingTimeSec'
                > & {
                    assignees: Array<
                      { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                        AssignedProjectMemberInfo,
                        'sortNo'
                      >
                    >;
                    client?: Maybe<
                      { __typename?: 'Client' } & Pick<
                        Client,
                        'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                      >
                    >;
                    createdMember?: Maybe<
                      { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >
                    >;
                    favoriteMembers: Array<
                      Maybe<
                        { __typename?: 'Member' } & Pick<
                          Member,
                          | 'adminRole'
                          | 'clientManagementRole'
                          | 'department'
                          | 'employeeNumber'
                          | 'id'
                          | 'laborCostManagementRole'
                          | 'mailAddress'
                          | 'memberManagementRole'
                          | 'memberRegistrationDateTime'
                          | 'memberStatus'
                          | 'name'
                          | 'organizationMemberViewRole'
                          | 'organizationReportRole'
                          | 'profileImageUrl'
                          | 'projectCreatePermissionFlg'
                          | 'projectCustomAttributePermissionFlg'
                          | 'projectDeletePermissionFlg'
                          | 'projectUpdatePermissionFlg'
                          | 'proxyEditWorkingDataRole'
                          | 'salesManagementRole'
                          | 'statusMessage'
                          | 'taskCreatePermissionFlg'
                          | 'taskCustomAttributePermissionFlg'
                          | 'taskDeletePermissionFlg'
                          | 'taskUpdatePermissionFlg'
                          | 'teamMemberViewRole'
                          | 'teamReportRole'
                          | 'versionNo'
                        >
                      >
                    >;
                    favoriteMembersInfo: Array<
                      Maybe<
                        { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                          FavoriteProjectMemberInfo,
                          'sortNo'
                        >
                      >
                    >;
                    projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                      ProjectStatus,
                      'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                    >;
                    team: { __typename?: 'Team' } & Pick<
                      Team,
                      'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                    > & {
                        organization: { __typename?: 'Organization' } & Pick<
                          Organization,
                          | 'allowConnectThirdPartyCalendar'
                          | 'contactMailAddress'
                          | 'contractPreriod'
                          | 'id'
                          | 'imageUrl'
                          | 'ipRestrictionFlg'
                          | 'name'
                          | 'requiredTwoFactorAuth'
                          | 'suspensionFlg'
                          | 'versionNo'
                        > & {
                            plan: { __typename?: 'PlanProduct' } & Pick<
                              PlanProduct,
                              'code' | 'contractPreriod' | 'id'
                            >;
                          };
                      };
                  };
                taskStatus: { __typename?: 'TaskStatus' } & Pick<
                  TaskStatus,
                  'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
                > & {
                    project: { __typename?: 'Project' } & Pick<
                      Project,
                      | 'calendarLinkageCode'
                      | 'color'
                      | 'complete'
                      | 'completeDateTime'
                      | 'costBudgetWithoutTax'
                      | 'description'
                      | 'estimateTimeSec'
                      | 'id'
                      | 'key'
                      | 'laborCost'
                      | 'laborCostWithoutTax'
                      | 'laborShare'
                      | 'name'
                      | 'priority'
                      | 'registerDateTime'
                      | 'salesAmountWithoutTax'
                      | 'scheduledEndDateTime'
                      | 'scheduledStartDateTime'
                      | 'sortNoInGanttChart'
                      | 'sortNoInList'
                      | 'sortNoInProjectStatus'
                      | 'totalScheduleTimeSec'
                      | 'versionNo'
                      | 'workingTimeSec'
                    >;
                  };
                workingMembers: Array<
                  Maybe<
                    { __typename?: 'WorkingMemberInfo' } & Pick<
                      WorkingMemberInfo,
                      'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                    > & {
                        member: { __typename?: 'Member' } & Pick<
                          Member,
                          | 'adminRole'
                          | 'clientManagementRole'
                          | 'department'
                          | 'employeeNumber'
                          | 'id'
                          | 'laborCostManagementRole'
                          | 'mailAddress'
                          | 'memberManagementRole'
                          | 'memberRegistrationDateTime'
                          | 'memberStatus'
                          | 'name'
                          | 'organizationMemberViewRole'
                          | 'organizationReportRole'
                          | 'profileImageUrl'
                          | 'projectCreatePermissionFlg'
                          | 'projectCustomAttributePermissionFlg'
                          | 'projectDeletePermissionFlg'
                          | 'projectUpdatePermissionFlg'
                          | 'proxyEditWorkingDataRole'
                          | 'salesManagementRole'
                          | 'statusMessage'
                          | 'taskCreatePermissionFlg'
                          | 'taskCustomAttributePermissionFlg'
                          | 'taskDeletePermissionFlg'
                          | 'taskUpdatePermissionFlg'
                          | 'teamMemberViewRole'
                          | 'teamReportRole'
                          | 'versionNo'
                        >;
                      }
                  >
                >;
              }
          >;
          workingMember: { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >;
        };
    }
  >;
};

export type CreateExportOrganizationClientExcelDownloadUrlMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CreateExportOrganizationClientExcelDownloadUrlMutation = { __typename?: 'Mutation' } & {
  createExportOrganizationClientExcelDownloadUrl?: Maybe<
    { __typename?: 'FileDownloadInfo' } & Pick<FileDownloadInfo, 'downloadUrl'>
  >;
};

export type CreateExportOrganizationMemberExcelDownloadUrlMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CreateExportOrganizationMemberExcelDownloadUrlMutation = { __typename?: 'Mutation' } & {
  createExportOrganizationMemberExcelDownloadUrl?: Maybe<
    { __typename?: 'FileDownloadInfo' } & Pick<FileDownloadInfo, 'downloadUrl'>
  >;
};

export type CreateExportProjectExcelDownloadUrlMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CreateExportProjectExcelDownloadUrlMutation = { __typename?: 'Mutation' } & {
  createExportProjectExcelDownloadUrl?: Maybe<
    { __typename?: 'FileDownloadInfo' } & Pick<FileDownloadInfo, 'downloadUrl'>
  >;
};

export type CreateExportTaskExcelDownloadUrlMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CreateExportTaskExcelDownloadUrlMutation = { __typename?: 'Mutation' } & {
  createExportTaskExcelDownloadUrl?: Maybe<
    { __typename?: 'FileDownloadInfo' } & Pick<FileDownloadInfo, 'downloadUrl'>
  >;
};

export type CreateICalFileDownloadUrlMutationVariables = Exact<{
  input: CreateICalFileDownloadUrlInputInput;
}>;

export type CreateICalFileDownloadUrlMutation = { __typename?: 'Mutation' } & {
  createICalFileDownloadUrl?: Maybe<
    { __typename?: 'ICalFileDownloadInfo' } & Pick<ICalFileDownloadInfo, 'downloadUrl'>
  >;
};

export type CreateInvitationLinkMutationVariables = Exact<{
  input: CreateInvitationLinkInputInput;
}>;

export type CreateInvitationLinkMutation = { __typename?: 'Mutation' } & {
  createInvitationLink?: Maybe<
    { __typename?: 'MemberInvitationLink' } & Pick<
      MemberInvitationLink,
      | 'allowThirdPartyCalendarConnect'
      | 'clientManagementRole'
      | 'createDateTime'
      | 'createMemberId'
      | 'id'
      | 'invitationKey'
      | 'invitationUrl'
      | 'memo'
      | 'organizationId'
      | 'organizationMemberViewRole'
      | 'organizationName'
      | 'organizationReportRole'
      | 'projectCreatePermissionFlg'
      | 'projectCustomAttributePermissionFlg'
      | 'projectDeletePermissionFlg'
      | 'projectUpdatePermissionFlg'
      | 'proxyEditWorkingDataRole'
      | 'taskCreatePermissionFlg'
      | 'taskCustomAttributePermissionFlg'
      | 'taskDeletePermissionFlg'
      | 'taskUpdatePermissionFlg'
      | 'teamMemberViewRole'
      | 'teamReportRole'
      | 'versionNo'
    >
  >;
};

export type CreateLaborCostMutationVariables = Exact<{
  input: RegisterLaborCostInputInput;
}>;

export type CreateLaborCostMutation = { __typename?: 'Mutation' } & {
  createLaborCost?: Maybe<
    { __typename?: 'LaborCost' } & Pick<
      LaborCost,
      'hourlyWage' | 'id' | 'startDateTime' | 'versionNo'
    >
  >;
};

export type CreateMeteredRateContractMutationVariables = Exact<{
  input: RegisterMeterdRateContractInputInput;
}>;

export type CreateMeteredRateContractMutation = { __typename?: 'Mutation' } & {
  createMeteredRateContract?: Maybe<
    { __typename?: 'MeteredRateContract' } & Pick<
      MeteredRateContract,
      'hourUnitPrice' | 'id' | 'versionNo'
    > & {
        member?: Maybe<
          { __typename?: 'MeteredRateContractMemberPart' } & Pick<
            MeteredRateContractMemberPart,
            | 'laborCostWithoutTax'
            | 'memberId'
            | 'name'
            | 'profileImageUrl'
            | 'roundedTotalWorkingTimeSec'
            | 'salesAmountWithoutTax'
            | 'totalWorkingTimeSec'
          >
        >;
      }
  >;
};

export type CreateMyCsvReportDownloadUrlMutationVariables = Exact<{
  id: Scalars['ID'];
  isUtf8: Scalars['Boolean'];
}>;

export type CreateMyCsvReportDownloadUrlMutation = { __typename?: 'Mutation' } & {
  createMyCsvReportDownloadUrl?: Maybe<
    { __typename?: 'FileDownloadInfo' } & Pick<FileDownloadInfo, 'downloadUrl'>
  >;
};

export type CreateMyReportDownloadUrlMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CreateMyReportDownloadUrlMutation = { __typename?: 'Mutation' } & {
  createMyReportDownloadUrl?: Maybe<
    { __typename?: 'FileDownloadInfo' } & Pick<FileDownloadInfo, 'downloadUrl'>
  >;
};

export type CreateOrganizationCsvReportDownloadUrlMutationVariables = Exact<{
  id: Scalars['ID'];
  isUtf8: Scalars['Boolean'];
}>;

export type CreateOrganizationCsvReportDownloadUrlMutation = { __typename?: 'Mutation' } & {
  createOrganizationCsvReportDownloadUrl?: Maybe<
    { __typename?: 'FileDownloadInfo' } & Pick<FileDownloadInfo, 'downloadUrl'>
  >;
};

export type CreateOrganizationReportDownloadUrlMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CreateOrganizationReportDownloadUrlMutation = { __typename?: 'Mutation' } & {
  createOrganizationReportDownloadUrl?: Maybe<
    { __typename?: 'FileDownloadInfo' } & Pick<FileDownloadInfo, 'downloadUrl'>
  >;
};

export type CreateProjectMutationVariables = Exact<{
  input?: Maybe<RegisterProjectInputInput>;
  teamId: Scalars['String'];
}>;

export type CreateProjectMutation = { __typename?: 'Mutation' } & {
  createProject?: Maybe<
    { __typename?: 'Project' } & Pick<
      Project,
      | 'calendarLinkageCode'
      | 'color'
      | 'complete'
      | 'completeDateTime'
      | 'costBudgetWithoutTax'
      | 'description'
      | 'estimateTimeSec'
      | 'id'
      | 'key'
      | 'laborCost'
      | 'laborCostWithoutTax'
      | 'laborShare'
      | 'name'
      | 'priority'
      | 'registerDateTime'
      | 'salesAmountWithoutTax'
      | 'scheduledEndDateTime'
      | 'scheduledStartDateTime'
      | 'sortNoInGanttChart'
      | 'sortNoInList'
      | 'sortNoInProjectStatus'
      | 'totalScheduleTimeSec'
      | 'versionNo'
      | 'workingTimeSec'
    > & {
        assignees: Array<
          { __typename?: 'AssignedProjectMemberInfo' } & Pick<
            AssignedProjectMemberInfo,
            'sortNo'
          > & {
              member: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
            }
        >;
        client?: Maybe<
          { __typename?: 'Client' } & Pick<Client, 'code' | 'id' | 'name' | 'sortNo' | 'versionNo'>
        >;
        createdMember?: Maybe<
          { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >
        >;
        favoriteMembers: Array<
          Maybe<
            { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >
          >
        >;
        favoriteMembersInfo: Array<
          Maybe<
            { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
              FavoriteProjectMemberInfo,
              'sortNo'
            > & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
        projectStatus: { __typename?: 'ProjectStatus' } & Pick<
          ProjectStatus,
          'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
        >;
        team: { __typename?: 'Team' } & Pick<
          Team,
          'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
        > & {
            organization: { __typename?: 'Organization' } & Pick<
              Organization,
              | 'allowConnectThirdPartyCalendar'
              | 'contactMailAddress'
              | 'contractPreriod'
              | 'id'
              | 'imageUrl'
              | 'ipRestrictionFlg'
              | 'name'
              | 'requiredTwoFactorAuth'
              | 'suspensionFlg'
              | 'versionNo'
            > & {
                plan: { __typename?: 'PlanProduct' } & Pick<
                  PlanProduct,
                  'code' | 'contractPreriod' | 'id'
                >;
              };
          };
      }
  >;
};

export type CreateProjectContractMutationVariables = Exact<{
  input: RegisterProjectContractInputInput;
}>;

export type CreateProjectContractMutation = { __typename?: 'Mutation' } & {
  createProjectContract?: Maybe<
    { __typename?: 'ProjectContract' } & Pick<
      ProjectContract,
      | 'contractType'
      | 'costBudgetWithoutTax'
      | 'endDateTime'
      | 'fixSalesAmountWithoutTax'
      | 'grossProfit'
      | 'grossProfitRatePercent'
      | 'id'
      | 'laborCostWithoutTax'
      | 'laborShare'
      | 'month'
      | 'roundTimeType'
      | 'roundedTotalWorkingTimeSec'
      | 'salesAmountWithoutTax'
      | 'salesRoundMinutes'
      | 'startDateTime'
      | 'taxRate'
      | 'termType'
      | 'totalWorkingTimeSec'
      | 'unitPriceType'
      | 'versionNo'
      | 'year'
    > & {
        allMemberMeteredRateContract?: Maybe<
          { __typename?: 'MeteredRateContract' } & Pick<
            MeteredRateContract,
            'hourUnitPrice' | 'id' | 'versionNo'
          > & {
              member?: Maybe<
                { __typename?: 'MeteredRateContractMemberPart' } & Pick<
                  MeteredRateContractMemberPart,
                  | 'laborCostWithoutTax'
                  | 'memberId'
                  | 'name'
                  | 'profileImageUrl'
                  | 'roundedTotalWorkingTimeSec'
                  | 'salesAmountWithoutTax'
                  | 'totalWorkingTimeSec'
                >
              >;
            }
        >;
        allMemberSettlementRangeContract?: Maybe<
          { __typename?: 'SettlementRangeContract' } & Pick<
            SettlementRangeContract,
            'id' | 'monthlyUnitPrice' | 'versionNo'
          > & {
              lowerHourPart: { __typename?: 'LowerPart' } & Pick<
                LowerPart,
                'deducationHourlyUnitPrice' | 'lowerHour'
              >;
              member?: Maybe<
                { __typename?: 'SettlementRangeContractMemberPart' } & Pick<
                  SettlementRangeContractMemberPart,
                  | 'laborCostWithoutTax'
                  | 'memberId'
                  | 'name'
                  | 'profileImageUrl'
                  | 'roundedTotalWorkingTimeSec'
                  | 'salesAmountWithoutTax'
                  | 'totalWorkingTimeSec'
                >
              >;
              upperHourPart: { __typename?: 'UpperPart' } & Pick<
                UpperPart,
                'excessHourlyUnitPrice' | 'upperHour'
              >;
            }
        >;
        membersMeteredRateContract?: Maybe<
          Array<
            Maybe<
              { __typename?: 'MeteredRateContract' } & Pick<
                MeteredRateContract,
                'hourUnitPrice' | 'id' | 'versionNo'
              > & {
                  member?: Maybe<
                    { __typename?: 'MeteredRateContractMemberPart' } & Pick<
                      MeteredRateContractMemberPart,
                      | 'laborCostWithoutTax'
                      | 'memberId'
                      | 'name'
                      | 'profileImageUrl'
                      | 'roundedTotalWorkingTimeSec'
                      | 'salesAmountWithoutTax'
                      | 'totalWorkingTimeSec'
                    >
                  >;
                }
            >
          >
        >;
        membersSettlementRangeContract?: Maybe<
          Array<
            Maybe<
              { __typename?: 'SettlementRangeContract' } & Pick<
                SettlementRangeContract,
                'id' | 'monthlyUnitPrice' | 'versionNo'
              > & {
                  lowerHourPart: { __typename?: 'LowerPart' } & Pick<
                    LowerPart,
                    'deducationHourlyUnitPrice' | 'lowerHour'
                  >;
                  member?: Maybe<
                    { __typename?: 'SettlementRangeContractMemberPart' } & Pick<
                      SettlementRangeContractMemberPart,
                      | 'laborCostWithoutTax'
                      | 'memberId'
                      | 'name'
                      | 'profileImageUrl'
                      | 'roundedTotalWorkingTimeSec'
                      | 'salesAmountWithoutTax'
                      | 'totalWorkingTimeSec'
                    >
                  >;
                  upperHourPart: { __typename?: 'UpperPart' } & Pick<
                    UpperPart,
                    'excessHourlyUnitPrice' | 'upperHour'
                  >;
                }
            >
          >
        >;
        project?: Maybe<
          { __typename?: 'Project' } & Pick<
            Project,
            | 'calendarLinkageCode'
            | 'color'
            | 'complete'
            | 'completeDateTime'
            | 'costBudgetWithoutTax'
            | 'description'
            | 'estimateTimeSec'
            | 'id'
            | 'key'
            | 'laborCost'
            | 'laborCostWithoutTax'
            | 'laborShare'
            | 'name'
            | 'priority'
            | 'registerDateTime'
            | 'salesAmountWithoutTax'
            | 'scheduledEndDateTime'
            | 'scheduledStartDateTime'
            | 'sortNoInGanttChart'
            | 'sortNoInList'
            | 'sortNoInProjectStatus'
            | 'totalScheduleTimeSec'
            | 'versionNo'
            | 'workingTimeSec'
          > & {
              assignees: Array<
                { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                  AssignedProjectMemberInfo,
                  'sortNo'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >;
              client?: Maybe<
                { __typename?: 'Client' } & Pick<
                  Client,
                  'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                >
              >;
              createdMember?: Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >;
              favoriteMembers: Array<
                Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >
              >;
              favoriteMembersInfo: Array<
                Maybe<
                  { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                    FavoriteProjectMemberInfo,
                    'sortNo'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >
              >;
              projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                ProjectStatus,
                'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
              >;
              team: { __typename?: 'Team' } & Pick<
                Team,
                'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
              > & {
                  organization: { __typename?: 'Organization' } & Pick<
                    Organization,
                    | 'allowConnectThirdPartyCalendar'
                    | 'contactMailAddress'
                    | 'contractPreriod'
                    | 'id'
                    | 'imageUrl'
                    | 'ipRestrictionFlg'
                    | 'name'
                    | 'requiredTwoFactorAuth'
                    | 'suspensionFlg'
                    | 'versionNo'
                  > & {
                      plan: { __typename?: 'PlanProduct' } & Pick<
                        PlanProduct,
                        'code' | 'contractPreriod' | 'id'
                      >;
                    };
                };
            }
        >;
        timeZone: { __typename?: 'TimeZone' } & Pick<
          TimeZone,
          'code' | 'id' | 'name' | 'offsetTime' | 'sortNo'
        >;
      }
  >;
};

export type CreateProjectCustomAttributeMasterMutationVariables = Exact<{
  input: RegisterProjectCustomAttributeMasterInputInput;
}>;

export type CreateProjectCustomAttributeMasterMutation = { __typename?: 'Mutation' } & {
  createProjectCustomAttributeMaster?: Maybe<
    { __typename?: 'ProjectCustomAttributeMaster' } & Pick<
      ProjectCustomAttributeMaster,
      'id' | 'name' | 'required' | 'sortNo' | 'type' | 'versionNo'
    > & {
        listItem: Array<
          Maybe<
            { __typename?: 'ProjectCustomAttributeMasterListItem' } & Pick<
              ProjectCustomAttributeMasterListItem,
              'id' | 'sortNo' | 'value' | 'versionNo'
            >
          >
        >;
      }
  >;
};

export type CreateProjectStatusMutationVariables = Exact<{
  input: RegisterProjectStatusInputInput;
  teamId: Scalars['String'];
}>;

export type CreateProjectStatusMutation = { __typename?: 'Mutation' } & {
  createProjectStatus?: Maybe<
    { __typename?: 'ProjectStatus' } & Pick<
      ProjectStatus,
      'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
    >
  >;
};

export type CreateProjectTemplateMutationVariables = Exact<{
  input?: Maybe<RegisterProjectTemplateInputInput>;
}>;

export type CreateProjectTemplateMutation = { __typename?: 'Mutation' } & {
  createProjectTemplate?: Maybe<
    { __typename?: 'ProjectTemplate' } & Pick<
      ProjectTemplate,
      'description' | 'estimateTimeSec' | 'id' | 'name' | 'priority' | 'sortNoInList' | 'versionNo'
    > & {
        client?: Maybe<
          { __typename?: 'Client' } & Pick<Client, 'code' | 'id' | 'name' | 'sortNo' | 'versionNo'>
        >;
      }
  >;
};

export type CreateProjectTemplateSubTaskMutationVariables = Exact<{
  input: RegisterProjectTemplateSubTaskInputInput;
  projectTemplateTaskId: Scalars['String'];
}>;

export type CreateProjectTemplateSubTaskMutation = { __typename?: 'Mutation' } & {
  createProjectTemplateSubTask?: Maybe<
    { __typename?: 'ProjectTemplateSubTask' } & Pick<
      ProjectTemplateSubTask,
      'id' | 'sortNo' | 'title' | 'versionNo'
    > & {
        createdMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
        projectTemplateTask: { __typename?: 'ProjectTemplateTask' } & Pick<
          ProjectTemplateTask,
          | 'description'
          | 'estimateTimeSec'
          | 'id'
          | 'priority'
          | 'sortNoInList'
          | 'sortNoInTaskStatus'
          | 'title'
          | 'versionNo'
        > & {
            createdMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
            projectTemplate: { __typename?: 'ProjectTemplate' } & Pick<
              ProjectTemplate,
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'name'
              | 'priority'
              | 'sortNoInList'
              | 'versionNo'
            > & {
                client?: Maybe<
                  { __typename?: 'Client' } & Pick<
                    Client,
                    'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                  >
                >;
              };
            projectTemplateTaskStatus: { __typename?: 'ProjectTemplateTaskStatus' } & Pick<
              ProjectTemplateTaskStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
            > & {
                projectTemplate: { __typename?: 'ProjectTemplate' } & Pick<
                  ProjectTemplate,
                  | 'description'
                  | 'estimateTimeSec'
                  | 'id'
                  | 'name'
                  | 'priority'
                  | 'sortNoInList'
                  | 'versionNo'
                >;
              };
          };
      }
  >;
};

export type CreateProjectTemplateTaskMutationVariables = Exact<{
  input: RegisterProjectTemplateTaskInputInput;
  projectTemplateId: Scalars['String'];
}>;

export type CreateProjectTemplateTaskMutation = { __typename?: 'Mutation' } & {
  createProjectTemplateTask?: Maybe<
    { __typename?: 'ProjectTemplateTask' } & Pick<
      ProjectTemplateTask,
      | 'description'
      | 'estimateTimeSec'
      | 'id'
      | 'priority'
      | 'sortNoInList'
      | 'sortNoInTaskStatus'
      | 'title'
      | 'versionNo'
    > & {
        createdMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
        projectTemplate: { __typename?: 'ProjectTemplate' } & Pick<
          ProjectTemplate,
          | 'description'
          | 'estimateTimeSec'
          | 'id'
          | 'name'
          | 'priority'
          | 'sortNoInList'
          | 'versionNo'
        > & {
            client?: Maybe<
              { __typename?: 'Client' } & Pick<
                Client,
                'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
              >
            >;
          };
        projectTemplateTaskStatus: { __typename?: 'ProjectTemplateTaskStatus' } & Pick<
          ProjectTemplateTaskStatus,
          'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
        > & {
            projectTemplate: { __typename?: 'ProjectTemplate' } & Pick<
              ProjectTemplate,
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'name'
              | 'priority'
              | 'sortNoInList'
              | 'versionNo'
            >;
          };
      }
  >;
};

export type CreateProjectTemplateTaskRemainderMutationVariables = Exact<{
  input: RegisterProjectTemplateTaskRemainderInputInput;
  projectTemplateTaskId: Scalars['String'];
}>;

export type CreateProjectTemplateTaskRemainderMutation = { __typename?: 'Mutation' } & {
  createProjectTemplateTaskRemainder?: Maybe<
    { __typename?: 'ProjectTemplateTaskRemainder' } & Pick<
      ProjectTemplateTaskRemainder,
      'beforeDeadlineMinutes' | 'id' | 'taskRemainderDestType' | 'taskRemainderType'
    > & {
        projectTemplateTask: { __typename?: 'ProjectTemplateTask' } & Pick<
          ProjectTemplateTask,
          | 'description'
          | 'estimateTimeSec'
          | 'id'
          | 'priority'
          | 'sortNoInList'
          | 'sortNoInTaskStatus'
          | 'title'
          | 'versionNo'
        > & {
            createdMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
            projectTemplate: { __typename?: 'ProjectTemplate' } & Pick<
              ProjectTemplate,
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'name'
              | 'priority'
              | 'sortNoInList'
              | 'versionNo'
            > & {
                client?: Maybe<
                  { __typename?: 'Client' } & Pick<
                    Client,
                    'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                  >
                >;
              };
            projectTemplateTaskStatus: { __typename?: 'ProjectTemplateTaskStatus' } & Pick<
              ProjectTemplateTaskStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
            > & {
                projectTemplate: { __typename?: 'ProjectTemplate' } & Pick<
                  ProjectTemplate,
                  | 'description'
                  | 'estimateTimeSec'
                  | 'id'
                  | 'name'
                  | 'priority'
                  | 'sortNoInList'
                  | 'versionNo'
                >;
              };
          };
        targetMember?: Maybe<
          { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >
        >;
      }
  >;
};

export type CreateProjectTemplateTaskStatusMutationVariables = Exact<{
  input: RegisterProjectTemplateTaskStatusInputInput;
  projectTemplateId: Scalars['String'];
}>;

export type CreateProjectTemplateTaskStatusMutation = { __typename?: 'Mutation' } & {
  createProjectTemplateTaskStatus?: Maybe<
    { __typename?: 'ProjectTemplateTaskStatus' } & Pick<
      ProjectTemplateTaskStatus,
      'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
    > & {
        projectTemplate: { __typename?: 'ProjectTemplate' } & Pick<
          ProjectTemplate,
          | 'description'
          | 'estimateTimeSec'
          | 'id'
          | 'name'
          | 'priority'
          | 'sortNoInList'
          | 'versionNo'
        > & {
            client?: Maybe<
              { __typename?: 'Client' } & Pick<
                Client,
                'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
              >
            >;
          };
      }
  >;
};

export type CreateProjectTemplateTasksMutationVariables = Exact<{
  inputs: RegisterProjectTemplateTasksInputInput;
  projectTemplateId: Scalars['String'];
}>;

export type CreateProjectTemplateTasksMutation = { __typename?: 'Mutation' } & {
  createProjectTemplateTasks?: Maybe<
    Array<
      Maybe<
        { __typename?: 'ProjectTemplateTask' } & Pick<
          ProjectTemplateTask,
          | 'description'
          | 'estimateTimeSec'
          | 'id'
          | 'priority'
          | 'sortNoInList'
          | 'sortNoInTaskStatus'
          | 'title'
          | 'versionNo'
        > & {
            createdMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
            projectTemplate: { __typename?: 'ProjectTemplate' } & Pick<
              ProjectTemplate,
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'name'
              | 'priority'
              | 'sortNoInList'
              | 'versionNo'
            > & {
                client?: Maybe<
                  { __typename?: 'Client' } & Pick<
                    Client,
                    'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                  >
                >;
              };
            projectTemplateTaskStatus: { __typename?: 'ProjectTemplateTaskStatus' } & Pick<
              ProjectTemplateTaskStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
            > & {
                projectTemplate: { __typename?: 'ProjectTemplate' } & Pick<
                  ProjectTemplate,
                  | 'description'
                  | 'estimateTimeSec'
                  | 'id'
                  | 'name'
                  | 'priority'
                  | 'sortNoInList'
                  | 'versionNo'
                >;
              };
          }
      >
    >
  >;
};

export type CreateSettlementRangeContractMutationVariables = Exact<{
  input: RegisterSettlementRangeContractInputInput;
}>;

export type CreateSettlementRangeContractMutation = { __typename?: 'Mutation' } & {
  createSettlementRangeContract?: Maybe<
    { __typename?: 'SettlementRangeContract' } & Pick<
      SettlementRangeContract,
      'id' | 'monthlyUnitPrice' | 'versionNo'
    > & {
        lowerHourPart: { __typename?: 'LowerPart' } & Pick<
          LowerPart,
          'deducationHourlyUnitPrice' | 'lowerHour'
        >;
        member?: Maybe<
          { __typename?: 'SettlementRangeContractMemberPart' } & Pick<
            SettlementRangeContractMemberPart,
            | 'laborCostWithoutTax'
            | 'memberId'
            | 'name'
            | 'profileImageUrl'
            | 'roundedTotalWorkingTimeSec'
            | 'salesAmountWithoutTax'
            | 'totalWorkingTimeSec'
          >
        >;
        upperHourPart: { __typename?: 'UpperPart' } & Pick<
          UpperPart,
          'excessHourlyUnitPrice' | 'upperHour'
        >;
      }
  >;
};

export type CreateSubTaskMutationVariables = Exact<{
  input: RegisterSubTaskInputInput;
  taskId: Scalars['String'];
}>;

export type CreateSubTaskMutation = { __typename?: 'Mutation' } & {
  createSubTask?: Maybe<
    { __typename?: 'SubTask' } & Pick<
      SubTask,
      'complete' | 'completeDateTime' | 'id' | 'sortNo' | 'title' | 'versionNo'
    > & {
        createdMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
        task: { __typename?: 'Task' } & Pick<
          Task,
          | 'calendarLinkageCode'
          | 'completeDateTime'
          | 'description'
          | 'estimateTimeSec'
          | 'estimatedRemainingTimeSec'
          | 'id'
          | 'laborCost'
          | 'priority'
          | 'progressDeviationRate'
          | 'progressRate'
          | 'registerDateTime'
          | 'remainingTimeSec'
          | 'scheduleWorkingTimeSec'
          | 'scheduledEndDateTime'
          | 'scheduledStartDateTime'
          | 'sortNoInGanttChart'
          | 'sortNoInList'
          | 'sortNoInMyTask'
          | 'sortNoInTaskStatus'
          | 'title'
          | 'versionNo'
          | 'workingTimeSec'
        > & {
            assignees: Array<
              { __typename?: 'AssignedTaskMemberInfo' } & Pick<AssignedTaskMemberInfo, 'sortNo'> & {
                  member: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                }
            >;
            asssignee?: Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >;
            createdMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
            favoriteMembers: Array<
              Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >
            >;
            favoriteMembersInfo: Array<
              Maybe<
                { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                  FavoriteTaskMemberInfo,
                  'sortNo'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >
            >;
            project: { __typename?: 'Project' } & Pick<
              Project,
              | 'calendarLinkageCode'
              | 'color'
              | 'complete'
              | 'completeDateTime'
              | 'costBudgetWithoutTax'
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'key'
              | 'laborCost'
              | 'laborCostWithoutTax'
              | 'laborShare'
              | 'name'
              | 'priority'
              | 'registerDateTime'
              | 'salesAmountWithoutTax'
              | 'scheduledEndDateTime'
              | 'scheduledStartDateTime'
              | 'sortNoInGanttChart'
              | 'sortNoInList'
              | 'sortNoInProjectStatus'
              | 'totalScheduleTimeSec'
              | 'versionNo'
              | 'workingTimeSec'
            > & {
                assignees: Array<
                  { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                    AssignedProjectMemberInfo,
                    'sortNo'
                  >
                >;
                client?: Maybe<
                  { __typename?: 'Client' } & Pick<
                    Client,
                    'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                  >
                >;
                createdMember?: Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >;
                favoriteMembers: Array<
                  Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >
                >;
                favoriteMembersInfo: Array<
                  Maybe<
                    { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                      FavoriteProjectMemberInfo,
                      'sortNo'
                    >
                  >
                >;
                projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                  ProjectStatus,
                  'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                >;
                team: { __typename?: 'Team' } & Pick<
                  Team,
                  'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                > & {
                    organization: { __typename?: 'Organization' } & Pick<
                      Organization,
                      | 'allowConnectThirdPartyCalendar'
                      | 'contactMailAddress'
                      | 'contractPreriod'
                      | 'id'
                      | 'imageUrl'
                      | 'ipRestrictionFlg'
                      | 'name'
                      | 'requiredTwoFactorAuth'
                      | 'suspensionFlg'
                      | 'versionNo'
                    > & {
                        plan: { __typename?: 'PlanProduct' } & Pick<
                          PlanProduct,
                          'code' | 'contractPreriod' | 'id'
                        >;
                      };
                  };
              };
            taskStatus: { __typename?: 'TaskStatus' } & Pick<
              TaskStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
            > & {
                project: { __typename?: 'Project' } & Pick<
                  Project,
                  | 'calendarLinkageCode'
                  | 'color'
                  | 'complete'
                  | 'completeDateTime'
                  | 'costBudgetWithoutTax'
                  | 'description'
                  | 'estimateTimeSec'
                  | 'id'
                  | 'key'
                  | 'laborCost'
                  | 'laborCostWithoutTax'
                  | 'laborShare'
                  | 'name'
                  | 'priority'
                  | 'registerDateTime'
                  | 'salesAmountWithoutTax'
                  | 'scheduledEndDateTime'
                  | 'scheduledStartDateTime'
                  | 'sortNoInGanttChart'
                  | 'sortNoInList'
                  | 'sortNoInProjectStatus'
                  | 'totalScheduleTimeSec'
                  | 'versionNo'
                  | 'workingTimeSec'
                >;
              };
            workingMembers: Array<
              Maybe<
                { __typename?: 'WorkingMemberInfo' } & Pick<
                  WorkingMemberInfo,
                  'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >
            >;
          };
      }
  >;
};

export type CreateTagMutationVariables = Exact<{
  input: RegisterTagInputInput;
}>;

export type CreateTagMutation = { __typename?: 'Mutation' } & {
  createTag?: Maybe<
    { __typename?: 'Tag' } & Pick<
      Tag,
      'id' | 'name' | 'note' | 'relatedTaskCount' | 'sortNo' | 'versionNo'
    > & {
        organization: { __typename?: 'Organization' } & Pick<
          Organization,
          | 'allowConnectThirdPartyCalendar'
          | 'contactMailAddress'
          | 'contractPreriod'
          | 'id'
          | 'imageUrl'
          | 'ipRestrictionFlg'
          | 'name'
          | 'requiredTwoFactorAuth'
          | 'suspensionFlg'
          | 'versionNo'
        > & {
            plan: { __typename?: 'PlanProduct' } & Pick<
              PlanProduct,
              'code' | 'contractPreriod' | 'id'
            >;
          };
      }
  >;
};

export type CreateTaskMutationVariables = Exact<{
  input: RegisterTaskInputInput;
  projectId: Scalars['String'];
}>;

export type CreateTaskMutation = { __typename?: 'Mutation' } & {
  createTask?: Maybe<
    { __typename?: 'Task' } & Pick<
      Task,
      | 'calendarLinkageCode'
      | 'completeDateTime'
      | 'description'
      | 'estimateTimeSec'
      | 'estimatedRemainingTimeSec'
      | 'id'
      | 'laborCost'
      | 'priority'
      | 'progressDeviationRate'
      | 'progressRate'
      | 'registerDateTime'
      | 'remainingTimeSec'
      | 'scheduleWorkingTimeSec'
      | 'scheduledEndDateTime'
      | 'scheduledStartDateTime'
      | 'sortNoInGanttChart'
      | 'sortNoInList'
      | 'sortNoInMyTask'
      | 'sortNoInTaskStatus'
      | 'title'
      | 'versionNo'
      | 'workingTimeSec'
    > & {
        assignees: Array<
          { __typename?: 'AssignedTaskMemberInfo' } & Pick<AssignedTaskMemberInfo, 'sortNo'> & {
              member: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
            }
        >;
        asssignee?: Maybe<
          { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >
        >;
        createdMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
        favoriteMembers: Array<
          Maybe<
            { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >
          >
        >;
        favoriteMembersInfo: Array<
          Maybe<
            { __typename?: 'FavoriteTaskMemberInfo' } & Pick<FavoriteTaskMemberInfo, 'sortNo'> & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
        project: { __typename?: 'Project' } & Pick<
          Project,
          | 'calendarLinkageCode'
          | 'color'
          | 'complete'
          | 'completeDateTime'
          | 'costBudgetWithoutTax'
          | 'description'
          | 'estimateTimeSec'
          | 'id'
          | 'key'
          | 'laborCost'
          | 'laborCostWithoutTax'
          | 'laborShare'
          | 'name'
          | 'priority'
          | 'registerDateTime'
          | 'salesAmountWithoutTax'
          | 'scheduledEndDateTime'
          | 'scheduledStartDateTime'
          | 'sortNoInGanttChart'
          | 'sortNoInList'
          | 'sortNoInProjectStatus'
          | 'totalScheduleTimeSec'
          | 'versionNo'
          | 'workingTimeSec'
        > & {
            assignees: Array<
              { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                AssignedProjectMemberInfo,
                'sortNo'
              >
            >;
            client?: Maybe<
              { __typename?: 'Client' } & Pick<
                Client,
                'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
              >
            >;
            createdMember?: Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >;
            favoriteMembers: Array<
              Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >
            >;
            favoriteMembersInfo: Array<
              Maybe<
                { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                  FavoriteProjectMemberInfo,
                  'sortNo'
                >
              >
            >;
            projectStatus: { __typename?: 'ProjectStatus' } & Pick<
              ProjectStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
            >;
            team: { __typename?: 'Team' } & Pick<
              Team,
              'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
            > & {
                organization: { __typename?: 'Organization' } & Pick<
                  Organization,
                  | 'allowConnectThirdPartyCalendar'
                  | 'contactMailAddress'
                  | 'contractPreriod'
                  | 'id'
                  | 'imageUrl'
                  | 'ipRestrictionFlg'
                  | 'name'
                  | 'requiredTwoFactorAuth'
                  | 'suspensionFlg'
                  | 'versionNo'
                > & {
                    plan: { __typename?: 'PlanProduct' } & Pick<
                      PlanProduct,
                      'code' | 'contractPreriod' | 'id'
                    >;
                  };
              };
          };
        taskStatus: { __typename?: 'TaskStatus' } & Pick<
          TaskStatus,
          'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
        > & {
            project: { __typename?: 'Project' } & Pick<
              Project,
              | 'calendarLinkageCode'
              | 'color'
              | 'complete'
              | 'completeDateTime'
              | 'costBudgetWithoutTax'
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'key'
              | 'laborCost'
              | 'laborCostWithoutTax'
              | 'laborShare'
              | 'name'
              | 'priority'
              | 'registerDateTime'
              | 'salesAmountWithoutTax'
              | 'scheduledEndDateTime'
              | 'scheduledStartDateTime'
              | 'sortNoInGanttChart'
              | 'sortNoInList'
              | 'sortNoInProjectStatus'
              | 'totalScheduleTimeSec'
              | 'versionNo'
              | 'workingTimeSec'
            >;
          };
        workingMembers: Array<
          Maybe<
            { __typename?: 'WorkingMemberInfo' } & Pick<
              WorkingMemberInfo,
              'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
            > & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
      }
  >;
};

export type CreateTaskCustomAttributeMasterMutationVariables = Exact<{
  input: RegisterTaskCustomAttributeMasterInputInput;
}>;

export type CreateTaskCustomAttributeMasterMutation = { __typename?: 'Mutation' } & {
  createTaskCustomAttributeMaster?: Maybe<
    { __typename?: 'TaskCustomAttributeMaster' } & Pick<
      TaskCustomAttributeMaster,
      'id' | 'name' | 'required' | 'sortNo' | 'type' | 'versionNo'
    > & {
        listItem: Array<
          Maybe<
            { __typename?: 'TaskCustomAttributeMasterListItem' } & Pick<
              TaskCustomAttributeMasterListItem,
              'id' | 'sortNo' | 'value' | 'versionNo'
            >
          >
        >;
      }
  >;
};

export type CreateTaskRemainderMutationVariables = Exact<{
  input: RegisterTaskRemainderInputInput;
  taskId: Scalars['String'];
}>;

export type CreateTaskRemainderMutation = { __typename?: 'Mutation' } & {
  createTaskRemainder?: Maybe<
    { __typename?: 'TaskRemainder' } & Pick<
      TaskRemainder,
      | 'beforeDeadlineMinutes'
      | 'id'
      | 'notificationSentFlg'
      | 'taskRemainderDestType'
      | 'taskRemainderType'
    > & {
        targetMember?: Maybe<
          { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >
        >;
        task: { __typename?: 'Task' } & Pick<
          Task,
          | 'calendarLinkageCode'
          | 'completeDateTime'
          | 'description'
          | 'estimateTimeSec'
          | 'estimatedRemainingTimeSec'
          | 'id'
          | 'laborCost'
          | 'priority'
          | 'progressDeviationRate'
          | 'progressRate'
          | 'registerDateTime'
          | 'remainingTimeSec'
          | 'scheduleWorkingTimeSec'
          | 'scheduledEndDateTime'
          | 'scheduledStartDateTime'
          | 'sortNoInGanttChart'
          | 'sortNoInList'
          | 'sortNoInMyTask'
          | 'sortNoInTaskStatus'
          | 'title'
          | 'versionNo'
          | 'workingTimeSec'
        > & {
            assignees: Array<
              { __typename?: 'AssignedTaskMemberInfo' } & Pick<AssignedTaskMemberInfo, 'sortNo'> & {
                  member: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                }
            >;
            asssignee?: Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >;
            createdMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
            favoriteMembers: Array<
              Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >
            >;
            favoriteMembersInfo: Array<
              Maybe<
                { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                  FavoriteTaskMemberInfo,
                  'sortNo'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >
            >;
            project: { __typename?: 'Project' } & Pick<
              Project,
              | 'calendarLinkageCode'
              | 'color'
              | 'complete'
              | 'completeDateTime'
              | 'costBudgetWithoutTax'
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'key'
              | 'laborCost'
              | 'laborCostWithoutTax'
              | 'laborShare'
              | 'name'
              | 'priority'
              | 'registerDateTime'
              | 'salesAmountWithoutTax'
              | 'scheduledEndDateTime'
              | 'scheduledStartDateTime'
              | 'sortNoInGanttChart'
              | 'sortNoInList'
              | 'sortNoInProjectStatus'
              | 'totalScheduleTimeSec'
              | 'versionNo'
              | 'workingTimeSec'
            > & {
                assignees: Array<
                  { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                    AssignedProjectMemberInfo,
                    'sortNo'
                  >
                >;
                client?: Maybe<
                  { __typename?: 'Client' } & Pick<
                    Client,
                    'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                  >
                >;
                createdMember?: Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >;
                favoriteMembers: Array<
                  Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >
                >;
                favoriteMembersInfo: Array<
                  Maybe<
                    { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                      FavoriteProjectMemberInfo,
                      'sortNo'
                    >
                  >
                >;
                projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                  ProjectStatus,
                  'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                >;
                team: { __typename?: 'Team' } & Pick<
                  Team,
                  'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                > & {
                    organization: { __typename?: 'Organization' } & Pick<
                      Organization,
                      | 'allowConnectThirdPartyCalendar'
                      | 'contactMailAddress'
                      | 'contractPreriod'
                      | 'id'
                      | 'imageUrl'
                      | 'ipRestrictionFlg'
                      | 'name'
                      | 'requiredTwoFactorAuth'
                      | 'suspensionFlg'
                      | 'versionNo'
                    > & {
                        plan: { __typename?: 'PlanProduct' } & Pick<
                          PlanProduct,
                          'code' | 'contractPreriod' | 'id'
                        >;
                      };
                  };
              };
            taskStatus: { __typename?: 'TaskStatus' } & Pick<
              TaskStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
            > & {
                project: { __typename?: 'Project' } & Pick<
                  Project,
                  | 'calendarLinkageCode'
                  | 'color'
                  | 'complete'
                  | 'completeDateTime'
                  | 'costBudgetWithoutTax'
                  | 'description'
                  | 'estimateTimeSec'
                  | 'id'
                  | 'key'
                  | 'laborCost'
                  | 'laborCostWithoutTax'
                  | 'laborShare'
                  | 'name'
                  | 'priority'
                  | 'registerDateTime'
                  | 'salesAmountWithoutTax'
                  | 'scheduledEndDateTime'
                  | 'scheduledStartDateTime'
                  | 'sortNoInGanttChart'
                  | 'sortNoInList'
                  | 'sortNoInProjectStatus'
                  | 'totalScheduleTimeSec'
                  | 'versionNo'
                  | 'workingTimeSec'
                >;
              };
            workingMembers: Array<
              Maybe<
                { __typename?: 'WorkingMemberInfo' } & Pick<
                  WorkingMemberInfo,
                  'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >
            >;
          };
      }
  >;
};

export type CreateTaskStatusMutationVariables = Exact<{
  input: RegisterTaskStatusInputInput;
  projectId: Scalars['String'];
}>;

export type CreateTaskStatusMutation = { __typename?: 'Mutation' } & {
  createTaskStatus?: Maybe<
    { __typename?: 'TaskStatus' } & Pick<
      TaskStatus,
      'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
    > & {
        project: { __typename?: 'Project' } & Pick<
          Project,
          | 'calendarLinkageCode'
          | 'color'
          | 'complete'
          | 'completeDateTime'
          | 'costBudgetWithoutTax'
          | 'description'
          | 'estimateTimeSec'
          | 'id'
          | 'key'
          | 'laborCost'
          | 'laborCostWithoutTax'
          | 'laborShare'
          | 'name'
          | 'priority'
          | 'registerDateTime'
          | 'salesAmountWithoutTax'
          | 'scheduledEndDateTime'
          | 'scheduledStartDateTime'
          | 'sortNoInGanttChart'
          | 'sortNoInList'
          | 'sortNoInProjectStatus'
          | 'totalScheduleTimeSec'
          | 'versionNo'
          | 'workingTimeSec'
        > & {
            assignees: Array<
              { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                AssignedProjectMemberInfo,
                'sortNo'
              > & {
                  member: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                }
            >;
            client?: Maybe<
              { __typename?: 'Client' } & Pick<
                Client,
                'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
              >
            >;
            createdMember?: Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >;
            favoriteMembers: Array<
              Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >
            >;
            favoriteMembersInfo: Array<
              Maybe<
                { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                  FavoriteProjectMemberInfo,
                  'sortNo'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >
            >;
            projectStatus: { __typename?: 'ProjectStatus' } & Pick<
              ProjectStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
            >;
            team: { __typename?: 'Team' } & Pick<
              Team,
              'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
            > & {
                organization: { __typename?: 'Organization' } & Pick<
                  Organization,
                  | 'allowConnectThirdPartyCalendar'
                  | 'contactMailAddress'
                  | 'contractPreriod'
                  | 'id'
                  | 'imageUrl'
                  | 'ipRestrictionFlg'
                  | 'name'
                  | 'requiredTwoFactorAuth'
                  | 'suspensionFlg'
                  | 'versionNo'
                > & {
                    plan: { __typename?: 'PlanProduct' } & Pick<
                      PlanProduct,
                      'code' | 'contractPreriod' | 'id'
                    >;
                  };
              };
          };
      }
  >;
};

export type CreateTasksMutationVariables = Exact<{
  input: RegisterTasksInputInput;
  projectId: Scalars['String'];
}>;

export type CreateTasksMutation = { __typename?: 'Mutation' } & {
  createTasks?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Task' } & Pick<
          Task,
          | 'calendarLinkageCode'
          | 'completeDateTime'
          | 'description'
          | 'estimateTimeSec'
          | 'estimatedRemainingTimeSec'
          | 'id'
          | 'laborCost'
          | 'priority'
          | 'progressDeviationRate'
          | 'progressRate'
          | 'registerDateTime'
          | 'remainingTimeSec'
          | 'scheduleWorkingTimeSec'
          | 'scheduledEndDateTime'
          | 'scheduledStartDateTime'
          | 'sortNoInGanttChart'
          | 'sortNoInList'
          | 'sortNoInMyTask'
          | 'sortNoInTaskStatus'
          | 'title'
          | 'versionNo'
          | 'workingTimeSec'
        > & {
            assignees: Array<
              { __typename?: 'AssignedTaskMemberInfo' } & Pick<AssignedTaskMemberInfo, 'sortNo'> & {
                  member: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                }
            >;
            asssignee?: Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >;
            createdMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
            favoriteMembers: Array<
              Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >
            >;
            favoriteMembersInfo: Array<
              Maybe<
                { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                  FavoriteTaskMemberInfo,
                  'sortNo'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >
            >;
            project: { __typename?: 'Project' } & Pick<
              Project,
              | 'calendarLinkageCode'
              | 'color'
              | 'complete'
              | 'completeDateTime'
              | 'costBudgetWithoutTax'
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'key'
              | 'laborCost'
              | 'laborCostWithoutTax'
              | 'laborShare'
              | 'name'
              | 'priority'
              | 'registerDateTime'
              | 'salesAmountWithoutTax'
              | 'scheduledEndDateTime'
              | 'scheduledStartDateTime'
              | 'sortNoInGanttChart'
              | 'sortNoInList'
              | 'sortNoInProjectStatus'
              | 'totalScheduleTimeSec'
              | 'versionNo'
              | 'workingTimeSec'
            > & {
                assignees: Array<
                  { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                    AssignedProjectMemberInfo,
                    'sortNo'
                  >
                >;
                client?: Maybe<
                  { __typename?: 'Client' } & Pick<
                    Client,
                    'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                  >
                >;
                createdMember?: Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >;
                favoriteMembers: Array<
                  Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >
                >;
                favoriteMembersInfo: Array<
                  Maybe<
                    { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                      FavoriteProjectMemberInfo,
                      'sortNo'
                    >
                  >
                >;
                projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                  ProjectStatus,
                  'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                >;
                team: { __typename?: 'Team' } & Pick<
                  Team,
                  'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                > & {
                    organization: { __typename?: 'Organization' } & Pick<
                      Organization,
                      | 'allowConnectThirdPartyCalendar'
                      | 'contactMailAddress'
                      | 'contractPreriod'
                      | 'id'
                      | 'imageUrl'
                      | 'ipRestrictionFlg'
                      | 'name'
                      | 'requiredTwoFactorAuth'
                      | 'suspensionFlg'
                      | 'versionNo'
                    > & {
                        plan: { __typename?: 'PlanProduct' } & Pick<
                          PlanProduct,
                          'code' | 'contractPreriod' | 'id'
                        >;
                      };
                  };
              };
            taskStatus: { __typename?: 'TaskStatus' } & Pick<
              TaskStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
            > & {
                project: { __typename?: 'Project' } & Pick<
                  Project,
                  | 'calendarLinkageCode'
                  | 'color'
                  | 'complete'
                  | 'completeDateTime'
                  | 'costBudgetWithoutTax'
                  | 'description'
                  | 'estimateTimeSec'
                  | 'id'
                  | 'key'
                  | 'laborCost'
                  | 'laborCostWithoutTax'
                  | 'laborShare'
                  | 'name'
                  | 'priority'
                  | 'registerDateTime'
                  | 'salesAmountWithoutTax'
                  | 'scheduledEndDateTime'
                  | 'scheduledStartDateTime'
                  | 'sortNoInGanttChart'
                  | 'sortNoInList'
                  | 'sortNoInProjectStatus'
                  | 'totalScheduleTimeSec'
                  | 'versionNo'
                  | 'workingTimeSec'
                >;
              };
            workingMembers: Array<
              Maybe<
                { __typename?: 'WorkingMemberInfo' } & Pick<
                  WorkingMemberInfo,
                  'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >
            >;
          }
      >
    >
  >;
};

export type CreateTeamMutationVariables = Exact<{
  input?: Maybe<RegisterTeamInputInput>;
  organizationId: Scalars['String'];
}>;

export type CreateTeamMutation = { __typename?: 'Mutation' } & {
  createTeam?: Maybe<
    { __typename?: 'Team' } & Pick<
      Team,
      'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
    > & {
        organization: { __typename?: 'Organization' } & Pick<
          Organization,
          | 'allowConnectThirdPartyCalendar'
          | 'contactMailAddress'
          | 'contractPreriod'
          | 'id'
          | 'imageUrl'
          | 'ipRestrictionFlg'
          | 'name'
          | 'requiredTwoFactorAuth'
          | 'suspensionFlg'
          | 'versionNo'
        > & {
            plan: { __typename?: 'PlanProduct' } & Pick<
              PlanProduct,
              'code' | 'contractPreriod' | 'id'
            >;
          };
      }
  >;
};

export type CreateTeamCsvReportDownloadUrlMutationVariables = Exact<{
  id: Scalars['ID'];
  isUtf8: Scalars['Boolean'];
}>;

export type CreateTeamCsvReportDownloadUrlMutation = { __typename?: 'Mutation' } & {
  createTeamCsvReportDownloadUrl?: Maybe<
    { __typename?: 'FileDownloadInfo' } & Pick<FileDownloadInfo, 'downloadUrl'>
  >;
};

export type CreateTeamReportDownloadUrlMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CreateTeamReportDownloadUrlMutation = { __typename?: 'Mutation' } & {
  createTeamReportDownloadUrl?: Maybe<
    { __typename?: 'FileDownloadInfo' } & Pick<FileDownloadInfo, 'downloadUrl'>
  >;
};

export type CreateWorkingHistoryMutationVariables = Exact<{
  input: RegisterWorkingHistoryInputInput;
  taskId: Scalars['String'];
}>;

export type CreateWorkingHistoryMutation = { __typename?: 'Mutation' } & {
  createWorkingHistory?: Maybe<
    { __typename?: 'WorkingHistory' } & Pick<
      WorkingHistory,
      'end' | 'id' | 'memo' | 'start' | 'versionNo' | 'workingTimeSec'
    > & {
        calendarEvent?: Maybe<
          { __typename?: 'CalendarEvent' } & Pick<
            CalendarEvent,
            | 'calendarType'
            | 'clientId'
            | 'clientName'
            | 'endDateTime'
            | 'eventName'
            | 'id'
            | 'laborCost'
            | 'personalTeam'
            | 'projectColor'
            | 'projectId'
            | 'projectName'
            | 'startDateTime'
            | 'taskId'
            | 'taskTitle'
            | 'teamId'
            | 'teamName'
            | 'thirdPartyCalendarEventId'
            | 'versionNo'
            | 'workingTimeSec'
          > & {
              thirdPartyCalendarInfoList: Array<
                Maybe<
                  { __typename?: 'ThirdPartyCalendarInfo' } & Pick<
                    ThirdPartyCalendarInfo,
                    'thirdPartyCalendarId'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >
              >;
            }
        >;
        closingProcessInfo: { __typename?: 'ClosingProcessPart' } & Pick<
          ClosingProcessPart,
          'closed' | 'targetDateTime'
        >;
        task?: Maybe<
          { __typename?: 'Task' } & Pick<
            Task,
            | 'calendarLinkageCode'
            | 'completeDateTime'
            | 'description'
            | 'estimateTimeSec'
            | 'estimatedRemainingTimeSec'
            | 'id'
            | 'laborCost'
            | 'priority'
            | 'progressDeviationRate'
            | 'progressRate'
            | 'registerDateTime'
            | 'remainingTimeSec'
            | 'scheduleWorkingTimeSec'
            | 'scheduledEndDateTime'
            | 'scheduledStartDateTime'
            | 'sortNoInGanttChart'
            | 'sortNoInList'
            | 'sortNoInMyTask'
            | 'sortNoInTaskStatus'
            | 'title'
            | 'versionNo'
            | 'workingTimeSec'
          > & {
              assignees: Array<
                { __typename?: 'AssignedTaskMemberInfo' } & Pick<
                  AssignedTaskMemberInfo,
                  'sortNo'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >;
              asssignee?: Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >;
              createdMember: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
              favoriteMembers: Array<
                Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >
              >;
              favoriteMembersInfo: Array<
                Maybe<
                  { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                    FavoriteTaskMemberInfo,
                    'sortNo'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >
              >;
              project: { __typename?: 'Project' } & Pick<
                Project,
                | 'calendarLinkageCode'
                | 'color'
                | 'complete'
                | 'completeDateTime'
                | 'costBudgetWithoutTax'
                | 'description'
                | 'estimateTimeSec'
                | 'id'
                | 'key'
                | 'laborCost'
                | 'laborCostWithoutTax'
                | 'laborShare'
                | 'name'
                | 'priority'
                | 'registerDateTime'
                | 'salesAmountWithoutTax'
                | 'scheduledEndDateTime'
                | 'scheduledStartDateTime'
                | 'sortNoInGanttChart'
                | 'sortNoInList'
                | 'sortNoInProjectStatus'
                | 'totalScheduleTimeSec'
                | 'versionNo'
                | 'workingTimeSec'
              > & {
                  assignees: Array<
                    { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                      AssignedProjectMemberInfo,
                      'sortNo'
                    >
                  >;
                  client?: Maybe<
                    { __typename?: 'Client' } & Pick<
                      Client,
                      'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                    >
                  >;
                  createdMember?: Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >;
                  favoriteMembers: Array<
                    Maybe<
                      { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >
                    >
                  >;
                  favoriteMembersInfo: Array<
                    Maybe<
                      { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                        FavoriteProjectMemberInfo,
                        'sortNo'
                      >
                    >
                  >;
                  projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                    ProjectStatus,
                    'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                  >;
                  team: { __typename?: 'Team' } & Pick<
                    Team,
                    'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                  > & {
                      organization: { __typename?: 'Organization' } & Pick<
                        Organization,
                        | 'allowConnectThirdPartyCalendar'
                        | 'contactMailAddress'
                        | 'contractPreriod'
                        | 'id'
                        | 'imageUrl'
                        | 'ipRestrictionFlg'
                        | 'name'
                        | 'requiredTwoFactorAuth'
                        | 'suspensionFlg'
                        | 'versionNo'
                      > & {
                          plan: { __typename?: 'PlanProduct' } & Pick<
                            PlanProduct,
                            'code' | 'contractPreriod' | 'id'
                          >;
                        };
                    };
                };
              taskStatus: { __typename?: 'TaskStatus' } & Pick<
                TaskStatus,
                'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
              > & {
                  project: { __typename?: 'Project' } & Pick<
                    Project,
                    | 'calendarLinkageCode'
                    | 'color'
                    | 'complete'
                    | 'completeDateTime'
                    | 'costBudgetWithoutTax'
                    | 'description'
                    | 'estimateTimeSec'
                    | 'id'
                    | 'key'
                    | 'laborCost'
                    | 'laborCostWithoutTax'
                    | 'laborShare'
                    | 'name'
                    | 'priority'
                    | 'registerDateTime'
                    | 'salesAmountWithoutTax'
                    | 'scheduledEndDateTime'
                    | 'scheduledStartDateTime'
                    | 'sortNoInGanttChart'
                    | 'sortNoInList'
                    | 'sortNoInProjectStatus'
                    | 'totalScheduleTimeSec'
                    | 'versionNo'
                    | 'workingTimeSec'
                  >;
                };
              workingMembers: Array<
                Maybe<
                  { __typename?: 'WorkingMemberInfo' } & Pick<
                    WorkingMemberInfo,
                    'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >
              >;
            }
        >;
        workingMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
      }
  >;
};

export type CreateWorkingHistoryByCalendarEventMutationVariables = Exact<{
  calendarEventId: Scalars['String'];
  input: RegisterWorkingHistoryInputInput;
}>;

export type CreateWorkingHistoryByCalendarEventMutation = { __typename?: 'Mutation' } & {
  createWorkingHistoryByCalendarEvent?: Maybe<
    { __typename?: 'WorkingHistory' } & Pick<
      WorkingHistory,
      'end' | 'id' | 'memo' | 'start' | 'versionNo' | 'workingTimeSec'
    > & {
        calendarEvent?: Maybe<
          { __typename?: 'CalendarEvent' } & Pick<
            CalendarEvent,
            | 'calendarType'
            | 'clientId'
            | 'clientName'
            | 'endDateTime'
            | 'eventName'
            | 'id'
            | 'laborCost'
            | 'personalTeam'
            | 'projectColor'
            | 'projectId'
            | 'projectName'
            | 'startDateTime'
            | 'taskId'
            | 'taskTitle'
            | 'teamId'
            | 'teamName'
            | 'thirdPartyCalendarEventId'
            | 'versionNo'
            | 'workingTimeSec'
          > & {
              thirdPartyCalendarInfoList: Array<
                Maybe<
                  { __typename?: 'ThirdPartyCalendarInfo' } & Pick<
                    ThirdPartyCalendarInfo,
                    'thirdPartyCalendarId'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >
              >;
            }
        >;
        closingProcessInfo: { __typename?: 'ClosingProcessPart' } & Pick<
          ClosingProcessPart,
          'closed' | 'targetDateTime'
        >;
        task?: Maybe<
          { __typename?: 'Task' } & Pick<
            Task,
            | 'calendarLinkageCode'
            | 'completeDateTime'
            | 'description'
            | 'estimateTimeSec'
            | 'estimatedRemainingTimeSec'
            | 'id'
            | 'laborCost'
            | 'priority'
            | 'progressDeviationRate'
            | 'progressRate'
            | 'registerDateTime'
            | 'remainingTimeSec'
            | 'scheduleWorkingTimeSec'
            | 'scheduledEndDateTime'
            | 'scheduledStartDateTime'
            | 'sortNoInGanttChart'
            | 'sortNoInList'
            | 'sortNoInMyTask'
            | 'sortNoInTaskStatus'
            | 'title'
            | 'versionNo'
            | 'workingTimeSec'
          > & {
              assignees: Array<
                { __typename?: 'AssignedTaskMemberInfo' } & Pick<
                  AssignedTaskMemberInfo,
                  'sortNo'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >;
              asssignee?: Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >;
              createdMember: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
              favoriteMembers: Array<
                Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >
              >;
              favoriteMembersInfo: Array<
                Maybe<
                  { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                    FavoriteTaskMemberInfo,
                    'sortNo'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >
              >;
              project: { __typename?: 'Project' } & Pick<
                Project,
                | 'calendarLinkageCode'
                | 'color'
                | 'complete'
                | 'completeDateTime'
                | 'costBudgetWithoutTax'
                | 'description'
                | 'estimateTimeSec'
                | 'id'
                | 'key'
                | 'laborCost'
                | 'laborCostWithoutTax'
                | 'laborShare'
                | 'name'
                | 'priority'
                | 'registerDateTime'
                | 'salesAmountWithoutTax'
                | 'scheduledEndDateTime'
                | 'scheduledStartDateTime'
                | 'sortNoInGanttChart'
                | 'sortNoInList'
                | 'sortNoInProjectStatus'
                | 'totalScheduleTimeSec'
                | 'versionNo'
                | 'workingTimeSec'
              > & {
                  assignees: Array<
                    { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                      AssignedProjectMemberInfo,
                      'sortNo'
                    >
                  >;
                  client?: Maybe<
                    { __typename?: 'Client' } & Pick<
                      Client,
                      'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                    >
                  >;
                  createdMember?: Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >;
                  favoriteMembers: Array<
                    Maybe<
                      { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >
                    >
                  >;
                  favoriteMembersInfo: Array<
                    Maybe<
                      { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                        FavoriteProjectMemberInfo,
                        'sortNo'
                      >
                    >
                  >;
                  projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                    ProjectStatus,
                    'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                  >;
                  team: { __typename?: 'Team' } & Pick<
                    Team,
                    'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                  > & {
                      organization: { __typename?: 'Organization' } & Pick<
                        Organization,
                        | 'allowConnectThirdPartyCalendar'
                        | 'contactMailAddress'
                        | 'contractPreriod'
                        | 'id'
                        | 'imageUrl'
                        | 'ipRestrictionFlg'
                        | 'name'
                        | 'requiredTwoFactorAuth'
                        | 'suspensionFlg'
                        | 'versionNo'
                      > & {
                          plan: { __typename?: 'PlanProduct' } & Pick<
                            PlanProduct,
                            'code' | 'contractPreriod' | 'id'
                          >;
                        };
                    };
                };
              taskStatus: { __typename?: 'TaskStatus' } & Pick<
                TaskStatus,
                'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
              > & {
                  project: { __typename?: 'Project' } & Pick<
                    Project,
                    | 'calendarLinkageCode'
                    | 'color'
                    | 'complete'
                    | 'completeDateTime'
                    | 'costBudgetWithoutTax'
                    | 'description'
                    | 'estimateTimeSec'
                    | 'id'
                    | 'key'
                    | 'laborCost'
                    | 'laborCostWithoutTax'
                    | 'laborShare'
                    | 'name'
                    | 'priority'
                    | 'registerDateTime'
                    | 'salesAmountWithoutTax'
                    | 'scheduledEndDateTime'
                    | 'scheduledStartDateTime'
                    | 'sortNoInGanttChart'
                    | 'sortNoInList'
                    | 'sortNoInProjectStatus'
                    | 'totalScheduleTimeSec'
                    | 'versionNo'
                    | 'workingTimeSec'
                  >;
                };
              workingMembers: Array<
                Maybe<
                  { __typename?: 'WorkingMemberInfo' } & Pick<
                    WorkingMemberInfo,
                    'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >
              >;
            }
        >;
        workingMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
      }
  >;
};

export type CreateWorkingHistoryWithTaskMutationVariables = Exact<{
  input: RegisterWorkingHistoryWithTaskInputInput;
  projectId: Scalars['String'];
}>;

export type CreateWorkingHistoryWithTaskMutation = { __typename?: 'Mutation' } & {
  createWorkingHistoryWithTask?: Maybe<
    { __typename?: 'CreateWorkingHistoryWithTaskResult' } & {
      task: { __typename?: 'Task' } & Pick<
        Task,
        | 'calendarLinkageCode'
        | 'completeDateTime'
        | 'description'
        | 'estimateTimeSec'
        | 'estimatedRemainingTimeSec'
        | 'id'
        | 'laborCost'
        | 'priority'
        | 'progressDeviationRate'
        | 'progressRate'
        | 'registerDateTime'
        | 'remainingTimeSec'
        | 'scheduleWorkingTimeSec'
        | 'scheduledEndDateTime'
        | 'scheduledStartDateTime'
        | 'sortNoInGanttChart'
        | 'sortNoInList'
        | 'sortNoInMyTask'
        | 'sortNoInTaskStatus'
        | 'title'
        | 'versionNo'
        | 'workingTimeSec'
      > & {
          assignees: Array<
            { __typename?: 'AssignedTaskMemberInfo' } & Pick<AssignedTaskMemberInfo, 'sortNo'> & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >;
          asssignee?: Maybe<
            { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >
          >;
          createdMember: { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >;
          favoriteMembers: Array<
            Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >
          >;
          favoriteMembersInfo: Array<
            Maybe<
              { __typename?: 'FavoriteTaskMemberInfo' } & Pick<FavoriteTaskMemberInfo, 'sortNo'> & {
                  member: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                }
            >
          >;
          project: { __typename?: 'Project' } & Pick<
            Project,
            | 'calendarLinkageCode'
            | 'color'
            | 'complete'
            | 'completeDateTime'
            | 'costBudgetWithoutTax'
            | 'description'
            | 'estimateTimeSec'
            | 'id'
            | 'key'
            | 'laborCost'
            | 'laborCostWithoutTax'
            | 'laborShare'
            | 'name'
            | 'priority'
            | 'registerDateTime'
            | 'salesAmountWithoutTax'
            | 'scheduledEndDateTime'
            | 'scheduledStartDateTime'
            | 'sortNoInGanttChart'
            | 'sortNoInList'
            | 'sortNoInProjectStatus'
            | 'totalScheduleTimeSec'
            | 'versionNo'
            | 'workingTimeSec'
          > & {
              assignees: Array<
                { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                  AssignedProjectMemberInfo,
                  'sortNo'
                >
              >;
              client?: Maybe<
                { __typename?: 'Client' } & Pick<
                  Client,
                  'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                >
              >;
              createdMember?: Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >;
              favoriteMembers: Array<
                Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >
              >;
              favoriteMembersInfo: Array<
                Maybe<
                  { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                    FavoriteProjectMemberInfo,
                    'sortNo'
                  >
                >
              >;
              projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                ProjectStatus,
                'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
              >;
              team: { __typename?: 'Team' } & Pick<
                Team,
                'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
              > & {
                  organization: { __typename?: 'Organization' } & Pick<
                    Organization,
                    | 'allowConnectThirdPartyCalendar'
                    | 'contactMailAddress'
                    | 'contractPreriod'
                    | 'id'
                    | 'imageUrl'
                    | 'ipRestrictionFlg'
                    | 'name'
                    | 'requiredTwoFactorAuth'
                    | 'suspensionFlg'
                    | 'versionNo'
                  > & {
                      plan: { __typename?: 'PlanProduct' } & Pick<
                        PlanProduct,
                        'code' | 'contractPreriod' | 'id'
                      >;
                    };
                };
            };
          taskStatus: { __typename?: 'TaskStatus' } & Pick<
            TaskStatus,
            'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
          > & {
              project: { __typename?: 'Project' } & Pick<
                Project,
                | 'calendarLinkageCode'
                | 'color'
                | 'complete'
                | 'completeDateTime'
                | 'costBudgetWithoutTax'
                | 'description'
                | 'estimateTimeSec'
                | 'id'
                | 'key'
                | 'laborCost'
                | 'laborCostWithoutTax'
                | 'laborShare'
                | 'name'
                | 'priority'
                | 'registerDateTime'
                | 'salesAmountWithoutTax'
                | 'scheduledEndDateTime'
                | 'scheduledStartDateTime'
                | 'sortNoInGanttChart'
                | 'sortNoInList'
                | 'sortNoInProjectStatus'
                | 'totalScheduleTimeSec'
                | 'versionNo'
                | 'workingTimeSec'
              >;
            };
          workingMembers: Array<
            Maybe<
              { __typename?: 'WorkingMemberInfo' } & Pick<
                WorkingMemberInfo,
                'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
              > & {
                  member: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                }
            >
          >;
        };
      workingHistory: { __typename?: 'WorkingHistory' } & Pick<
        WorkingHistory,
        'end' | 'id' | 'memo' | 'start' | 'versionNo' | 'workingTimeSec'
      > & {
          calendarEvent?: Maybe<
            { __typename?: 'CalendarEvent' } & Pick<
              CalendarEvent,
              | 'calendarType'
              | 'clientId'
              | 'clientName'
              | 'endDateTime'
              | 'eventName'
              | 'id'
              | 'laborCost'
              | 'personalTeam'
              | 'projectColor'
              | 'projectId'
              | 'projectName'
              | 'startDateTime'
              | 'taskId'
              | 'taskTitle'
              | 'teamId'
              | 'teamName'
              | 'thirdPartyCalendarEventId'
              | 'versionNo'
              | 'workingTimeSec'
            > & {
                thirdPartyCalendarInfoList: Array<
                  Maybe<
                    { __typename?: 'ThirdPartyCalendarInfo' } & Pick<
                      ThirdPartyCalendarInfo,
                      'thirdPartyCalendarId'
                    > & {
                        member: { __typename?: 'Member' } & Pick<
                          Member,
                          | 'adminRole'
                          | 'clientManagementRole'
                          | 'department'
                          | 'employeeNumber'
                          | 'id'
                          | 'laborCostManagementRole'
                          | 'mailAddress'
                          | 'memberManagementRole'
                          | 'memberRegistrationDateTime'
                          | 'memberStatus'
                          | 'name'
                          | 'organizationMemberViewRole'
                          | 'organizationReportRole'
                          | 'profileImageUrl'
                          | 'projectCreatePermissionFlg'
                          | 'projectCustomAttributePermissionFlg'
                          | 'projectDeletePermissionFlg'
                          | 'projectUpdatePermissionFlg'
                          | 'proxyEditWorkingDataRole'
                          | 'salesManagementRole'
                          | 'statusMessage'
                          | 'taskCreatePermissionFlg'
                          | 'taskCustomAttributePermissionFlg'
                          | 'taskDeletePermissionFlg'
                          | 'taskUpdatePermissionFlg'
                          | 'teamMemberViewRole'
                          | 'teamReportRole'
                          | 'versionNo'
                        >;
                      }
                  >
                >;
              }
          >;
          closingProcessInfo: { __typename?: 'ClosingProcessPart' } & Pick<
            ClosingProcessPart,
            'closed' | 'targetDateTime'
          >;
          task?: Maybe<
            { __typename?: 'Task' } & Pick<
              Task,
              | 'calendarLinkageCode'
              | 'completeDateTime'
              | 'description'
              | 'estimateTimeSec'
              | 'estimatedRemainingTimeSec'
              | 'id'
              | 'laborCost'
              | 'priority'
              | 'progressDeviationRate'
              | 'progressRate'
              | 'registerDateTime'
              | 'remainingTimeSec'
              | 'scheduleWorkingTimeSec'
              | 'scheduledEndDateTime'
              | 'scheduledStartDateTime'
              | 'sortNoInGanttChart'
              | 'sortNoInList'
              | 'sortNoInMyTask'
              | 'sortNoInTaskStatus'
              | 'title'
              | 'versionNo'
              | 'workingTimeSec'
            >
          >;
          workingMember: { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >;
        };
    }
  >;
};

export type CreateWorkingScheduleMutationVariables = Exact<{
  input: RegisterWorkingScheduleInputInput;
  taskId: Scalars['String'];
}>;

export type CreateWorkingScheduleMutation = { __typename?: 'Mutation' } & {
  createWorkingSchedule?: Maybe<
    { __typename?: 'WorkingSchedule' } & Pick<
      WorkingSchedule,
      'end' | 'id' | 'memo' | 'start' | 'versionNo' | 'workingTimeSec'
    > & {
        calendarEvent?: Maybe<
          { __typename?: 'CalendarEvent' } & Pick<
            CalendarEvent,
            | 'calendarType'
            | 'clientId'
            | 'clientName'
            | 'endDateTime'
            | 'eventName'
            | 'id'
            | 'laborCost'
            | 'personalTeam'
            | 'projectColor'
            | 'projectId'
            | 'projectName'
            | 'startDateTime'
            | 'taskId'
            | 'taskTitle'
            | 'teamId'
            | 'teamName'
            | 'thirdPartyCalendarEventId'
            | 'versionNo'
            | 'workingTimeSec'
          > & {
              thirdPartyCalendarInfoList: Array<
                Maybe<
                  { __typename?: 'ThirdPartyCalendarInfo' } & Pick<
                    ThirdPartyCalendarInfo,
                    'thirdPartyCalendarId'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >
              >;
            }
        >;
        task?: Maybe<
          { __typename?: 'Task' } & Pick<
            Task,
            | 'calendarLinkageCode'
            | 'completeDateTime'
            | 'description'
            | 'estimateTimeSec'
            | 'estimatedRemainingTimeSec'
            | 'id'
            | 'laborCost'
            | 'priority'
            | 'progressDeviationRate'
            | 'progressRate'
            | 'registerDateTime'
            | 'remainingTimeSec'
            | 'scheduleWorkingTimeSec'
            | 'scheduledEndDateTime'
            | 'scheduledStartDateTime'
            | 'sortNoInGanttChart'
            | 'sortNoInList'
            | 'sortNoInMyTask'
            | 'sortNoInTaskStatus'
            | 'title'
            | 'versionNo'
            | 'workingTimeSec'
          > & {
              assignees: Array<
                { __typename?: 'AssignedTaskMemberInfo' } & Pick<
                  AssignedTaskMemberInfo,
                  'sortNo'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >;
              asssignee?: Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >;
              createdMember: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
              favoriteMembers: Array<
                Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >
              >;
              favoriteMembersInfo: Array<
                Maybe<
                  { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                    FavoriteTaskMemberInfo,
                    'sortNo'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >
              >;
              project: { __typename?: 'Project' } & Pick<
                Project,
                | 'calendarLinkageCode'
                | 'color'
                | 'complete'
                | 'completeDateTime'
                | 'costBudgetWithoutTax'
                | 'description'
                | 'estimateTimeSec'
                | 'id'
                | 'key'
                | 'laborCost'
                | 'laborCostWithoutTax'
                | 'laborShare'
                | 'name'
                | 'priority'
                | 'registerDateTime'
                | 'salesAmountWithoutTax'
                | 'scheduledEndDateTime'
                | 'scheduledStartDateTime'
                | 'sortNoInGanttChart'
                | 'sortNoInList'
                | 'sortNoInProjectStatus'
                | 'totalScheduleTimeSec'
                | 'versionNo'
                | 'workingTimeSec'
              > & {
                  assignees: Array<
                    { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                      AssignedProjectMemberInfo,
                      'sortNo'
                    >
                  >;
                  client?: Maybe<
                    { __typename?: 'Client' } & Pick<
                      Client,
                      'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                    >
                  >;
                  createdMember?: Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >;
                  favoriteMembers: Array<
                    Maybe<
                      { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >
                    >
                  >;
                  favoriteMembersInfo: Array<
                    Maybe<
                      { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                        FavoriteProjectMemberInfo,
                        'sortNo'
                      >
                    >
                  >;
                  projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                    ProjectStatus,
                    'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                  >;
                  team: { __typename?: 'Team' } & Pick<
                    Team,
                    'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                  > & {
                      organization: { __typename?: 'Organization' } & Pick<
                        Organization,
                        | 'allowConnectThirdPartyCalendar'
                        | 'contactMailAddress'
                        | 'contractPreriod'
                        | 'id'
                        | 'imageUrl'
                        | 'ipRestrictionFlg'
                        | 'name'
                        | 'requiredTwoFactorAuth'
                        | 'suspensionFlg'
                        | 'versionNo'
                      > & {
                          plan: { __typename?: 'PlanProduct' } & Pick<
                            PlanProduct,
                            'code' | 'contractPreriod' | 'id'
                          >;
                        };
                    };
                };
              taskStatus: { __typename?: 'TaskStatus' } & Pick<
                TaskStatus,
                'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
              > & {
                  project: { __typename?: 'Project' } & Pick<
                    Project,
                    | 'calendarLinkageCode'
                    | 'color'
                    | 'complete'
                    | 'completeDateTime'
                    | 'costBudgetWithoutTax'
                    | 'description'
                    | 'estimateTimeSec'
                    | 'id'
                    | 'key'
                    | 'laborCost'
                    | 'laborCostWithoutTax'
                    | 'laborShare'
                    | 'name'
                    | 'priority'
                    | 'registerDateTime'
                    | 'salesAmountWithoutTax'
                    | 'scheduledEndDateTime'
                    | 'scheduledStartDateTime'
                    | 'sortNoInGanttChart'
                    | 'sortNoInList'
                    | 'sortNoInProjectStatus'
                    | 'totalScheduleTimeSec'
                    | 'versionNo'
                    | 'workingTimeSec'
                  >;
                };
              workingMembers: Array<
                Maybe<
                  { __typename?: 'WorkingMemberInfo' } & Pick<
                    WorkingMemberInfo,
                    'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >
              >;
            }
        >;
        workingMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
      }
  >;
};

export type CreateWorkingScheduleWithTaskMutationVariables = Exact<{
  input: RegisterWorkingScheduleWithTaskInputInput;
  projectId: Scalars['String'];
}>;

export type CreateWorkingScheduleWithTaskMutation = { __typename?: 'Mutation' } & {
  createWorkingScheduleWithTask?: Maybe<
    { __typename?: 'CreateWorkingScheduleWithTaskResult' } & {
      task: { __typename?: 'Task' } & Pick<
        Task,
        | 'calendarLinkageCode'
        | 'completeDateTime'
        | 'description'
        | 'estimateTimeSec'
        | 'estimatedRemainingTimeSec'
        | 'id'
        | 'laborCost'
        | 'priority'
        | 'progressDeviationRate'
        | 'progressRate'
        | 'registerDateTime'
        | 'remainingTimeSec'
        | 'scheduleWorkingTimeSec'
        | 'scheduledEndDateTime'
        | 'scheduledStartDateTime'
        | 'sortNoInGanttChart'
        | 'sortNoInList'
        | 'sortNoInMyTask'
        | 'sortNoInTaskStatus'
        | 'title'
        | 'versionNo'
        | 'workingTimeSec'
      > & {
          assignees: Array<
            { __typename?: 'AssignedTaskMemberInfo' } & Pick<AssignedTaskMemberInfo, 'sortNo'> & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >;
          asssignee?: Maybe<
            { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >
          >;
          createdMember: { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >;
          favoriteMembers: Array<
            Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >
          >;
          favoriteMembersInfo: Array<
            Maybe<
              { __typename?: 'FavoriteTaskMemberInfo' } & Pick<FavoriteTaskMemberInfo, 'sortNo'> & {
                  member: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                }
            >
          >;
          project: { __typename?: 'Project' } & Pick<
            Project,
            | 'calendarLinkageCode'
            | 'color'
            | 'complete'
            | 'completeDateTime'
            | 'costBudgetWithoutTax'
            | 'description'
            | 'estimateTimeSec'
            | 'id'
            | 'key'
            | 'laborCost'
            | 'laborCostWithoutTax'
            | 'laborShare'
            | 'name'
            | 'priority'
            | 'registerDateTime'
            | 'salesAmountWithoutTax'
            | 'scheduledEndDateTime'
            | 'scheduledStartDateTime'
            | 'sortNoInGanttChart'
            | 'sortNoInList'
            | 'sortNoInProjectStatus'
            | 'totalScheduleTimeSec'
            | 'versionNo'
            | 'workingTimeSec'
          > & {
              assignees: Array<
                { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                  AssignedProjectMemberInfo,
                  'sortNo'
                >
              >;
              client?: Maybe<
                { __typename?: 'Client' } & Pick<
                  Client,
                  'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                >
              >;
              createdMember?: Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >;
              favoriteMembers: Array<
                Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >
              >;
              favoriteMembersInfo: Array<
                Maybe<
                  { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                    FavoriteProjectMemberInfo,
                    'sortNo'
                  >
                >
              >;
              projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                ProjectStatus,
                'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
              >;
              team: { __typename?: 'Team' } & Pick<
                Team,
                'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
              > & {
                  organization: { __typename?: 'Organization' } & Pick<
                    Organization,
                    | 'allowConnectThirdPartyCalendar'
                    | 'contactMailAddress'
                    | 'contractPreriod'
                    | 'id'
                    | 'imageUrl'
                    | 'ipRestrictionFlg'
                    | 'name'
                    | 'requiredTwoFactorAuth'
                    | 'suspensionFlg'
                    | 'versionNo'
                  > & {
                      plan: { __typename?: 'PlanProduct' } & Pick<
                        PlanProduct,
                        'code' | 'contractPreriod' | 'id'
                      >;
                    };
                };
            };
          taskStatus: { __typename?: 'TaskStatus' } & Pick<
            TaskStatus,
            'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
          > & {
              project: { __typename?: 'Project' } & Pick<
                Project,
                | 'calendarLinkageCode'
                | 'color'
                | 'complete'
                | 'completeDateTime'
                | 'costBudgetWithoutTax'
                | 'description'
                | 'estimateTimeSec'
                | 'id'
                | 'key'
                | 'laborCost'
                | 'laborCostWithoutTax'
                | 'laborShare'
                | 'name'
                | 'priority'
                | 'registerDateTime'
                | 'salesAmountWithoutTax'
                | 'scheduledEndDateTime'
                | 'scheduledStartDateTime'
                | 'sortNoInGanttChart'
                | 'sortNoInList'
                | 'sortNoInProjectStatus'
                | 'totalScheduleTimeSec'
                | 'versionNo'
                | 'workingTimeSec'
              >;
            };
          workingMembers: Array<
            Maybe<
              { __typename?: 'WorkingMemberInfo' } & Pick<
                WorkingMemberInfo,
                'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
              > & {
                  member: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                }
            >
          >;
        };
      workingSchedule: { __typename?: 'WorkingSchedule' } & Pick<
        WorkingSchedule,
        'end' | 'id' | 'memo' | 'start' | 'versionNo' | 'workingTimeSec'
      > & {
          calendarEvent?: Maybe<
            { __typename?: 'CalendarEvent' } & Pick<
              CalendarEvent,
              | 'calendarType'
              | 'clientId'
              | 'clientName'
              | 'endDateTime'
              | 'eventName'
              | 'id'
              | 'laborCost'
              | 'personalTeam'
              | 'projectColor'
              | 'projectId'
              | 'projectName'
              | 'startDateTime'
              | 'taskId'
              | 'taskTitle'
              | 'teamId'
              | 'teamName'
              | 'thirdPartyCalendarEventId'
              | 'versionNo'
              | 'workingTimeSec'
            > & {
                thirdPartyCalendarInfoList: Array<
                  Maybe<
                    { __typename?: 'ThirdPartyCalendarInfo' } & Pick<
                      ThirdPartyCalendarInfo,
                      'thirdPartyCalendarId'
                    > & {
                        member: { __typename?: 'Member' } & Pick<
                          Member,
                          | 'adminRole'
                          | 'clientManagementRole'
                          | 'department'
                          | 'employeeNumber'
                          | 'id'
                          | 'laborCostManagementRole'
                          | 'mailAddress'
                          | 'memberManagementRole'
                          | 'memberRegistrationDateTime'
                          | 'memberStatus'
                          | 'name'
                          | 'organizationMemberViewRole'
                          | 'organizationReportRole'
                          | 'profileImageUrl'
                          | 'projectCreatePermissionFlg'
                          | 'projectCustomAttributePermissionFlg'
                          | 'projectDeletePermissionFlg'
                          | 'projectUpdatePermissionFlg'
                          | 'proxyEditWorkingDataRole'
                          | 'salesManagementRole'
                          | 'statusMessage'
                          | 'taskCreatePermissionFlg'
                          | 'taskCustomAttributePermissionFlg'
                          | 'taskDeletePermissionFlg'
                          | 'taskUpdatePermissionFlg'
                          | 'teamMemberViewRole'
                          | 'teamReportRole'
                          | 'versionNo'
                        >;
                      }
                  >
                >;
              }
          >;
          task?: Maybe<
            { __typename?: 'Task' } & Pick<
              Task,
              | 'calendarLinkageCode'
              | 'completeDateTime'
              | 'description'
              | 'estimateTimeSec'
              | 'estimatedRemainingTimeSec'
              | 'id'
              | 'laborCost'
              | 'priority'
              | 'progressDeviationRate'
              | 'progressRate'
              | 'registerDateTime'
              | 'remainingTimeSec'
              | 'scheduleWorkingTimeSec'
              | 'scheduledEndDateTime'
              | 'scheduledStartDateTime'
              | 'sortNoInGanttChart'
              | 'sortNoInList'
              | 'sortNoInMyTask'
              | 'sortNoInTaskStatus'
              | 'title'
              | 'versionNo'
              | 'workingTimeSec'
            >
          >;
          workingMember: { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >;
        };
    }
  >;
};

export type DeactivateMemberTwoFactorAuthMutationVariables = Exact<{
  memberId: Scalars['String'];
}>;

export type DeactivateMemberTwoFactorAuthMutation = { __typename?: 'Mutation' } & {
  deactivateMemberTwoFactorAuth?: Maybe<
    { __typename?: 'TwoFactorAuthSetting' } & Pick<
      TwoFactorAuthSetting,
      'enabled' | 'recoveryCodes'
    >
  >;
};

export type DeactivateTwoFactorAuthMutationVariables = Exact<{ [key: string]: never }>;

export type DeactivateTwoFactorAuthMutation = { __typename?: 'Mutation' } & {
  deactivateTwoFactorAuth?: Maybe<
    { __typename?: 'TwoFactorAuthSetting' } & Pick<
      TwoFactorAuthSetting,
      'enabled' | 'recoveryCodes'
    >
  >;
};

export type DeleteAttachementFileMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteAttachementFileMutation = { __typename?: 'Mutation' } & {
  deleteAttachementFile?: Maybe<
    { __typename?: 'AttachmentFile' } & Pick<
      AttachmentFile,
      'fileName' | 'id' | 'sizeByte' | 'type' | 'uploadDateTime'
    >
  >;
};

export type DeleteCalendarEventMutationVariables = Exact<{
  id: Scalars['String'];
  input: DeleteCalendarEventInputInput;
}>;

export type DeleteCalendarEventMutation = { __typename?: 'Mutation' } & {
  deleteCalendarEvent?: Maybe<
    { __typename?: 'CalendarEvent' } & Pick<
      CalendarEvent,
      | 'calendarType'
      | 'clientId'
      | 'clientName'
      | 'endDateTime'
      | 'eventName'
      | 'id'
      | 'laborCost'
      | 'personalTeam'
      | 'projectColor'
      | 'projectId'
      | 'projectName'
      | 'startDateTime'
      | 'taskId'
      | 'taskTitle'
      | 'teamId'
      | 'teamName'
      | 'thirdPartyCalendarEventId'
      | 'versionNo'
      | 'workingTimeSec'
    > & {
        thirdPartyCalendarInfoList: Array<
          Maybe<
            { __typename?: 'ThirdPartyCalendarInfo' } & Pick<
              ThirdPartyCalendarInfo,
              'thirdPartyCalendarId'
            > & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
      }
  >;
};

export type DeleteClientMutationVariables = Exact<{
  id: Scalars['ID'];
  input: DeleteClientInputInput;
}>;

export type DeleteClientMutation = { __typename?: 'Mutation' } & {
  deleteClient?: Maybe<
    { __typename?: 'Client' } & Pick<Client, 'code' | 'id' | 'name' | 'sortNo' | 'versionNo'>
  >;
};

export type DeleteCsvReportItemMutationVariables = Exact<{
  id: Scalars['ID'];
  input: DeleteCsvDefinitionDetailInputInput;
}>;

export type DeleteCsvReportItemMutation = { __typename?: 'Mutation' } & {
  deleteCsvReportItem?: Maybe<
    { __typename?: 'CsvReportItemDefinition' } & Pick<
      CsvReportItemDefinition,
      'id' | 'name' | 'versionNo'
    > & {
        items: Array<
          Maybe<
            { __typename?: 'CsvReportItemDefinitionDetail' } & Pick<
              CsvReportItemDefinitionDetail,
              'id' | 'reportItemCode' | 'reportItemName' | 'sortNo'
            >
          >
        >;
      }
  >;
};

export type DeleteCsvReportItemDefinitionMutationVariables = Exact<{
  id: Scalars['ID'];
  input: DeleteCsvReportItemDefinitionInputInput;
}>;

export type DeleteCsvReportItemDefinitionMutation = { __typename?: 'Mutation' } & {
  deleteCsvReportItemDefinition?: Maybe<
    { __typename?: 'CsvReportItemDefinition' } & Pick<
      CsvReportItemDefinition,
      'id' | 'name' | 'versionNo'
    > & {
        items: Array<
          Maybe<
            { __typename?: 'CsvReportItemDefinitionDetail' } & Pick<
              CsvReportItemDefinitionDetail,
              'id' | 'reportItemCode' | 'reportItemName' | 'sortNo'
            >
          >
        >;
      }
  >;
};

export type DeleteGoogleCalendarEventMutationVariables = Exact<{
  calendarId: Scalars['String'];
  id: Scalars['ID'];
}>;

export type DeleteGoogleCalendarEventMutation = { __typename?: 'Mutation' } & {
  deleteGoogleCalendarEvent?: Maybe<
    { __typename?: 'GoogleCalendarEvent' } & Pick<
      GoogleCalendarEvent,
      | 'allDay'
      | 'backgroundColor'
      | 'calendarId'
      | 'colorId'
      | 'dateWhenAllDay'
      | 'description'
      | 'endDateTime'
      | 'foregroundColor'
      | 'id'
      | 'location'
      | 'readonly'
      | 'startDateTime'
      | 'status'
      | 'targetMemberId'
      | 'title'
      | 'visivility'
    > & {
        attendees?: Maybe<
          Array<
            Maybe<
              { __typename?: 'Attendee' } & Pick<
                Attendee,
                'mailAddress' | 'name' | 'responseStatus' | 'self'
              >
            >
          >
        >;
        organizer?: Maybe<
          { __typename?: 'NameAndMailAddress' } & Pick<NameAndMailAddress, 'mailAddress' | 'name'>
        >;
      }
  >;
};

export type DeleteInvitationMutationVariables = Exact<{
  key: Scalars['String'];
}>;

export type DeleteInvitationMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'deleteInvitation'
>;

export type DeleteInvitationLinkMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteInvitationLinkMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'deleteInvitationLink'
>;

export type DeleteLaborCostMutationVariables = Exact<{
  id: Scalars['ID'];
  input: DeleteLaborCostInputInput;
}>;

export type DeleteLaborCostMutation = { __typename?: 'Mutation' } & {
  deleteLaborCost?: Maybe<
    { __typename?: 'LaborCost' } & Pick<
      LaborCost,
      'hourlyWage' | 'id' | 'startDateTime' | 'versionNo'
    >
  >;
};

export type DeleteMeteredRateContractMutationVariables = Exact<{
  id: Scalars['ID'];
  input: DeleteMeterdRateContractInputInput;
}>;

export type DeleteMeteredRateContractMutation = { __typename?: 'Mutation' } & {
  deleteMeteredRateContract?: Maybe<
    { __typename?: 'MeteredRateContract' } & Pick<
      MeteredRateContract,
      'hourUnitPrice' | 'id' | 'versionNo'
    > & {
        member?: Maybe<
          { __typename?: 'MeteredRateContractMemberPart' } & Pick<
            MeteredRateContractMemberPart,
            | 'laborCostWithoutTax'
            | 'memberId'
            | 'name'
            | 'profileImageUrl'
            | 'roundedTotalWorkingTimeSec'
            | 'salesAmountWithoutTax'
            | 'totalWorkingTimeSec'
          >
        >;
      }
  >;
};

export type DeleteProjectMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: Maybe<DeleteProjectInputInput>;
}>;

export type DeleteProjectMutation = { __typename?: 'Mutation' } & {
  deleteProject?: Maybe<
    { __typename?: 'Project' } & Pick<
      Project,
      | 'calendarLinkageCode'
      | 'color'
      | 'complete'
      | 'completeDateTime'
      | 'costBudgetWithoutTax'
      | 'description'
      | 'estimateTimeSec'
      | 'id'
      | 'key'
      | 'laborCost'
      | 'laborCostWithoutTax'
      | 'laborShare'
      | 'name'
      | 'priority'
      | 'registerDateTime'
      | 'salesAmountWithoutTax'
      | 'scheduledEndDateTime'
      | 'scheduledStartDateTime'
      | 'sortNoInGanttChart'
      | 'sortNoInList'
      | 'sortNoInProjectStatus'
      | 'totalScheduleTimeSec'
      | 'versionNo'
      | 'workingTimeSec'
    > & {
        assignees: Array<
          { __typename?: 'AssignedProjectMemberInfo' } & Pick<
            AssignedProjectMemberInfo,
            'sortNo'
          > & {
              member: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
            }
        >;
        client?: Maybe<
          { __typename?: 'Client' } & Pick<Client, 'code' | 'id' | 'name' | 'sortNo' | 'versionNo'>
        >;
        createdMember?: Maybe<
          { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >
        >;
        favoriteMembers: Array<
          Maybe<
            { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >
          >
        >;
        favoriteMembersInfo: Array<
          Maybe<
            { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
              FavoriteProjectMemberInfo,
              'sortNo'
            > & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
        projectStatus: { __typename?: 'ProjectStatus' } & Pick<
          ProjectStatus,
          'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
        >;
        team: { __typename?: 'Team' } & Pick<
          Team,
          'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
        > & {
            organization: { __typename?: 'Organization' } & Pick<
              Organization,
              | 'allowConnectThirdPartyCalendar'
              | 'contactMailAddress'
              | 'contractPreriod'
              | 'id'
              | 'imageUrl'
              | 'ipRestrictionFlg'
              | 'name'
              | 'requiredTwoFactorAuth'
              | 'suspensionFlg'
              | 'versionNo'
            > & {
                plan: { __typename?: 'PlanProduct' } & Pick<
                  PlanProduct,
                  'code' | 'contractPreriod' | 'id'
                >;
              };
          };
      }
  >;
};

export type DeleteProjectContractMutationVariables = Exact<{
  id: Scalars['ID'];
  input: DeleteProjectContractInputInput;
}>;

export type DeleteProjectContractMutation = { __typename?: 'Mutation' } & {
  deleteProjectContract?: Maybe<
    { __typename?: 'ProjectContract' } & Pick<
      ProjectContract,
      | 'contractType'
      | 'costBudgetWithoutTax'
      | 'endDateTime'
      | 'fixSalesAmountWithoutTax'
      | 'grossProfit'
      | 'grossProfitRatePercent'
      | 'id'
      | 'laborCostWithoutTax'
      | 'laborShare'
      | 'month'
      | 'roundTimeType'
      | 'roundedTotalWorkingTimeSec'
      | 'salesAmountWithoutTax'
      | 'salesRoundMinutes'
      | 'startDateTime'
      | 'taxRate'
      | 'termType'
      | 'totalWorkingTimeSec'
      | 'unitPriceType'
      | 'versionNo'
      | 'year'
    > & {
        allMemberMeteredRateContract?: Maybe<
          { __typename?: 'MeteredRateContract' } & Pick<
            MeteredRateContract,
            'hourUnitPrice' | 'id' | 'versionNo'
          > & {
              member?: Maybe<
                { __typename?: 'MeteredRateContractMemberPart' } & Pick<
                  MeteredRateContractMemberPart,
                  | 'laborCostWithoutTax'
                  | 'memberId'
                  | 'name'
                  | 'profileImageUrl'
                  | 'roundedTotalWorkingTimeSec'
                  | 'salesAmountWithoutTax'
                  | 'totalWorkingTimeSec'
                >
              >;
            }
        >;
        allMemberSettlementRangeContract?: Maybe<
          { __typename?: 'SettlementRangeContract' } & Pick<
            SettlementRangeContract,
            'id' | 'monthlyUnitPrice' | 'versionNo'
          > & {
              lowerHourPart: { __typename?: 'LowerPart' } & Pick<
                LowerPart,
                'deducationHourlyUnitPrice' | 'lowerHour'
              >;
              member?: Maybe<
                { __typename?: 'SettlementRangeContractMemberPart' } & Pick<
                  SettlementRangeContractMemberPart,
                  | 'laborCostWithoutTax'
                  | 'memberId'
                  | 'name'
                  | 'profileImageUrl'
                  | 'roundedTotalWorkingTimeSec'
                  | 'salesAmountWithoutTax'
                  | 'totalWorkingTimeSec'
                >
              >;
              upperHourPart: { __typename?: 'UpperPart' } & Pick<
                UpperPart,
                'excessHourlyUnitPrice' | 'upperHour'
              >;
            }
        >;
        membersMeteredRateContract?: Maybe<
          Array<
            Maybe<
              { __typename?: 'MeteredRateContract' } & Pick<
                MeteredRateContract,
                'hourUnitPrice' | 'id' | 'versionNo'
              > & {
                  member?: Maybe<
                    { __typename?: 'MeteredRateContractMemberPart' } & Pick<
                      MeteredRateContractMemberPart,
                      | 'laborCostWithoutTax'
                      | 'memberId'
                      | 'name'
                      | 'profileImageUrl'
                      | 'roundedTotalWorkingTimeSec'
                      | 'salesAmountWithoutTax'
                      | 'totalWorkingTimeSec'
                    >
                  >;
                }
            >
          >
        >;
        membersSettlementRangeContract?: Maybe<
          Array<
            Maybe<
              { __typename?: 'SettlementRangeContract' } & Pick<
                SettlementRangeContract,
                'id' | 'monthlyUnitPrice' | 'versionNo'
              > & {
                  lowerHourPart: { __typename?: 'LowerPart' } & Pick<
                    LowerPart,
                    'deducationHourlyUnitPrice' | 'lowerHour'
                  >;
                  member?: Maybe<
                    { __typename?: 'SettlementRangeContractMemberPart' } & Pick<
                      SettlementRangeContractMemberPart,
                      | 'laborCostWithoutTax'
                      | 'memberId'
                      | 'name'
                      | 'profileImageUrl'
                      | 'roundedTotalWorkingTimeSec'
                      | 'salesAmountWithoutTax'
                      | 'totalWorkingTimeSec'
                    >
                  >;
                  upperHourPart: { __typename?: 'UpperPart' } & Pick<
                    UpperPart,
                    'excessHourlyUnitPrice' | 'upperHour'
                  >;
                }
            >
          >
        >;
        project?: Maybe<
          { __typename?: 'Project' } & Pick<
            Project,
            | 'calendarLinkageCode'
            | 'color'
            | 'complete'
            | 'completeDateTime'
            | 'costBudgetWithoutTax'
            | 'description'
            | 'estimateTimeSec'
            | 'id'
            | 'key'
            | 'laborCost'
            | 'laborCostWithoutTax'
            | 'laborShare'
            | 'name'
            | 'priority'
            | 'registerDateTime'
            | 'salesAmountWithoutTax'
            | 'scheduledEndDateTime'
            | 'scheduledStartDateTime'
            | 'sortNoInGanttChart'
            | 'sortNoInList'
            | 'sortNoInProjectStatus'
            | 'totalScheduleTimeSec'
            | 'versionNo'
            | 'workingTimeSec'
          > & {
              assignees: Array<
                { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                  AssignedProjectMemberInfo,
                  'sortNo'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >;
              client?: Maybe<
                { __typename?: 'Client' } & Pick<
                  Client,
                  'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                >
              >;
              createdMember?: Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >;
              favoriteMembers: Array<
                Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >
              >;
              favoriteMembersInfo: Array<
                Maybe<
                  { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                    FavoriteProjectMemberInfo,
                    'sortNo'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >
              >;
              projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                ProjectStatus,
                'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
              >;
              team: { __typename?: 'Team' } & Pick<
                Team,
                'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
              > & {
                  organization: { __typename?: 'Organization' } & Pick<
                    Organization,
                    | 'allowConnectThirdPartyCalendar'
                    | 'contactMailAddress'
                    | 'contractPreriod'
                    | 'id'
                    | 'imageUrl'
                    | 'ipRestrictionFlg'
                    | 'name'
                    | 'requiredTwoFactorAuth'
                    | 'suspensionFlg'
                    | 'versionNo'
                  > & {
                      plan: { __typename?: 'PlanProduct' } & Pick<
                        PlanProduct,
                        'code' | 'contractPreriod' | 'id'
                      >;
                    };
                };
            }
        >;
        timeZone: { __typename?: 'TimeZone' } & Pick<
          TimeZone,
          'code' | 'id' | 'name' | 'offsetTime' | 'sortNo'
        >;
      }
  >;
};

export type DeleteProjectCustomAttributeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteProjectCustomAttributeMutation = { __typename?: 'Mutation' } & {
  deleteProjectCustomAttribute?: Maybe<
    { __typename?: 'ProjectCustomAttribute' } & Pick<
      ProjectCustomAttribute,
      'id' | 'sortNo' | 'value'
    > & {
        listItem?: Maybe<
          { __typename?: 'ProjectCustomAttributeMasterListItem' } & Pick<
            ProjectCustomAttributeMasterListItem,
            'id' | 'sortNo' | 'value' | 'versionNo'
          >
        >;
        master: { __typename?: 'ProjectCustomAttributeMaster' } & Pick<
          ProjectCustomAttributeMaster,
          'id' | 'name' | 'required' | 'sortNo' | 'type' | 'versionNo'
        > & {
            listItem: Array<
              Maybe<
                { __typename?: 'ProjectCustomAttributeMasterListItem' } & Pick<
                  ProjectCustomAttributeMasterListItem,
                  'id' | 'sortNo' | 'value' | 'versionNo'
                >
              >
            >;
          };
      }
  >;
};

export type DeleteProjectCustomAttributeMasterMutationVariables = Exact<{
  id: Scalars['ID'];
  input: DeleteProjectCustomAttributeMasterInputInput;
}>;

export type DeleteProjectCustomAttributeMasterMutation = { __typename?: 'Mutation' } & {
  deleteProjectCustomAttributeMaster?: Maybe<
    { __typename?: 'ProjectCustomAttributeMaster' } & Pick<
      ProjectCustomAttributeMaster,
      'id' | 'name' | 'required' | 'sortNo' | 'type' | 'versionNo'
    > & {
        listItem: Array<
          Maybe<
            { __typename?: 'ProjectCustomAttributeMasterListItem' } & Pick<
              ProjectCustomAttributeMasterListItem,
              'id' | 'sortNo' | 'value' | 'versionNo'
            >
          >
        >;
      }
  >;
};

export type DeleteProjectCustomAttributeMasterListItemMutationVariables = Exact<{
  id: Scalars['ID'];
  input: DeleteProjectCustomAttributeMasterListItemInputInput;
}>;

export type DeleteProjectCustomAttributeMasterListItemMutation = { __typename?: 'Mutation' } & {
  deleteProjectCustomAttributeMasterListItem?: Maybe<
    { __typename?: 'ProjectCustomAttributeMasterListItem' } & Pick<
      ProjectCustomAttributeMasterListItem,
      'id' | 'sortNo' | 'value' | 'versionNo'
    >
  >;
};

export type DeleteProjectStatusMutationVariables = Exact<{
  id: Scalars['ID'];
  input: DeleteProjectStatusInputInput;
}>;

export type DeleteProjectStatusMutation = { __typename?: 'Mutation' } & {
  deleteProjectStatus?: Maybe<
    { __typename?: 'ProjectStatus' } & Pick<
      ProjectStatus,
      'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
    >
  >;
};

export type DeleteProjectTemplateMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: Maybe<DeleteProjectTemplateInputInput>;
}>;

export type DeleteProjectTemplateMutation = { __typename?: 'Mutation' } & {
  deleteProjectTemplate?: Maybe<
    { __typename?: 'ProjectTemplate' } & Pick<
      ProjectTemplate,
      'description' | 'estimateTimeSec' | 'id' | 'name' | 'priority' | 'sortNoInList' | 'versionNo'
    > & {
        client?: Maybe<
          { __typename?: 'Client' } & Pick<Client, 'code' | 'id' | 'name' | 'sortNo' | 'versionNo'>
        >;
      }
  >;
};

export type DeleteProjectTemplateCustomAttributeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteProjectTemplateCustomAttributeMutation = { __typename?: 'Mutation' } & {
  deleteProjectTemplateCustomAttribute?: Maybe<
    { __typename?: 'ProjectTemplateCustomAttribute' } & Pick<
      ProjectTemplateCustomAttribute,
      'id' | 'sortNo' | 'value'
    > & {
        listItem?: Maybe<
          { __typename?: 'ProjectCustomAttributeMasterListItem' } & Pick<
            ProjectCustomAttributeMasterListItem,
            'id' | 'sortNo' | 'value' | 'versionNo'
          >
        >;
        master: { __typename?: 'ProjectCustomAttributeMaster' } & Pick<
          ProjectCustomAttributeMaster,
          'id' | 'name' | 'required' | 'sortNo' | 'type' | 'versionNo'
        > & {
            listItem: Array<
              Maybe<
                { __typename?: 'ProjectCustomAttributeMasterListItem' } & Pick<
                  ProjectCustomAttributeMasterListItem,
                  'id' | 'sortNo' | 'value' | 'versionNo'
                >
              >
            >;
          };
      }
  >;
};

export type DeleteProjectTemplateSubTaskMutationVariables = Exact<{
  id: Scalars['ID'];
  input: DeleteProjectTemplateSubTaskInputInput;
}>;

export type DeleteProjectTemplateSubTaskMutation = { __typename?: 'Mutation' } & {
  deleteProjectTemplateSubTask?: Maybe<
    { __typename?: 'ProjectTemplateSubTask' } & Pick<
      ProjectTemplateSubTask,
      'id' | 'sortNo' | 'title' | 'versionNo'
    > & {
        createdMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
        projectTemplateTask: { __typename?: 'ProjectTemplateTask' } & Pick<
          ProjectTemplateTask,
          | 'description'
          | 'estimateTimeSec'
          | 'id'
          | 'priority'
          | 'sortNoInList'
          | 'sortNoInTaskStatus'
          | 'title'
          | 'versionNo'
        > & {
            createdMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
            projectTemplate: { __typename?: 'ProjectTemplate' } & Pick<
              ProjectTemplate,
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'name'
              | 'priority'
              | 'sortNoInList'
              | 'versionNo'
            > & {
                client?: Maybe<
                  { __typename?: 'Client' } & Pick<
                    Client,
                    'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                  >
                >;
              };
            projectTemplateTaskStatus: { __typename?: 'ProjectTemplateTaskStatus' } & Pick<
              ProjectTemplateTaskStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
            > & {
                projectTemplate: { __typename?: 'ProjectTemplate' } & Pick<
                  ProjectTemplate,
                  | 'description'
                  | 'estimateTimeSec'
                  | 'id'
                  | 'name'
                  | 'priority'
                  | 'sortNoInList'
                  | 'versionNo'
                >;
              };
          };
      }
  >;
};

export type DeleteProjectTemplateTaskMutationVariables = Exact<{
  id: Scalars['ID'];
  input: DeleteProjectTemplateTaskInputInput;
}>;

export type DeleteProjectTemplateTaskMutation = { __typename?: 'Mutation' } & {
  deleteProjectTemplateTask?: Maybe<
    { __typename?: 'ProjectTemplateTask' } & Pick<
      ProjectTemplateTask,
      | 'description'
      | 'estimateTimeSec'
      | 'id'
      | 'priority'
      | 'sortNoInList'
      | 'sortNoInTaskStatus'
      | 'title'
      | 'versionNo'
    > & {
        createdMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
        projectTemplate: { __typename?: 'ProjectTemplate' } & Pick<
          ProjectTemplate,
          | 'description'
          | 'estimateTimeSec'
          | 'id'
          | 'name'
          | 'priority'
          | 'sortNoInList'
          | 'versionNo'
        > & {
            client?: Maybe<
              { __typename?: 'Client' } & Pick<
                Client,
                'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
              >
            >;
          };
        projectTemplateTaskStatus: { __typename?: 'ProjectTemplateTaskStatus' } & Pick<
          ProjectTemplateTaskStatus,
          'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
        > & {
            projectTemplate: { __typename?: 'ProjectTemplate' } & Pick<
              ProjectTemplate,
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'name'
              | 'priority'
              | 'sortNoInList'
              | 'versionNo'
            >;
          };
      }
  >;
};

export type DeleteProjectTemplateTaskCustomAttributeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteProjectTemplateTaskCustomAttributeMutation = { __typename?: 'Mutation' } & {
  deleteProjectTemplateTaskCustomAttribute?: Maybe<
    { __typename?: 'ProjectTemplateTaskCustomAttribute' } & Pick<
      ProjectTemplateTaskCustomAttribute,
      'id' | 'sortNo' | 'value'
    > & {
        listItem?: Maybe<
          { __typename?: 'TaskCustomAttributeMasterListItem' } & Pick<
            TaskCustomAttributeMasterListItem,
            'id' | 'sortNo' | 'value' | 'versionNo'
          >
        >;
        master: { __typename?: 'TaskCustomAttributeMaster' } & Pick<
          TaskCustomAttributeMaster,
          'id' | 'name' | 'required' | 'sortNo' | 'type' | 'versionNo'
        > & {
            listItem: Array<
              Maybe<
                { __typename?: 'TaskCustomAttributeMasterListItem' } & Pick<
                  TaskCustomAttributeMasterListItem,
                  'id' | 'sortNo' | 'value' | 'versionNo'
                >
              >
            >;
          };
      }
  >;
};

export type DeleteProjectTemplateTaskRemainderMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteProjectTemplateTaskRemainderMutation = { __typename?: 'Mutation' } & {
  deleteProjectTemplateTaskRemainder?: Maybe<
    { __typename?: 'ProjectTemplateTaskRemainder' } & Pick<
      ProjectTemplateTaskRemainder,
      'beforeDeadlineMinutes' | 'id' | 'taskRemainderDestType' | 'taskRemainderType'
    > & {
        projectTemplateTask: { __typename?: 'ProjectTemplateTask' } & Pick<
          ProjectTemplateTask,
          | 'description'
          | 'estimateTimeSec'
          | 'id'
          | 'priority'
          | 'sortNoInList'
          | 'sortNoInTaskStatus'
          | 'title'
          | 'versionNo'
        > & {
            createdMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
            projectTemplate: { __typename?: 'ProjectTemplate' } & Pick<
              ProjectTemplate,
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'name'
              | 'priority'
              | 'sortNoInList'
              | 'versionNo'
            > & {
                client?: Maybe<
                  { __typename?: 'Client' } & Pick<
                    Client,
                    'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                  >
                >;
              };
            projectTemplateTaskStatus: { __typename?: 'ProjectTemplateTaskStatus' } & Pick<
              ProjectTemplateTaskStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
            > & {
                projectTemplate: { __typename?: 'ProjectTemplate' } & Pick<
                  ProjectTemplate,
                  | 'description'
                  | 'estimateTimeSec'
                  | 'id'
                  | 'name'
                  | 'priority'
                  | 'sortNoInList'
                  | 'versionNo'
                >;
              };
          };
        targetMember?: Maybe<
          { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >
        >;
      }
  >;
};

export type DeleteProjectTemplateTaskStatusMutationVariables = Exact<{
  id: Scalars['ID'];
  input: DeleteProjectTemplateTaskStatusInputInput;
}>;

export type DeleteProjectTemplateTaskStatusMutation = { __typename?: 'Mutation' } & {
  deleteProjectTemplateTaskStatus?: Maybe<
    { __typename?: 'ProjectTemplateTaskStatus' } & Pick<
      ProjectTemplateTaskStatus,
      'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
    > & {
        projectTemplate: { __typename?: 'ProjectTemplate' } & Pick<
          ProjectTemplate,
          | 'description'
          | 'estimateTimeSec'
          | 'id'
          | 'name'
          | 'priority'
          | 'sortNoInList'
          | 'versionNo'
        > & {
            client?: Maybe<
              { __typename?: 'Client' } & Pick<
                Client,
                'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
              >
            >;
          };
      }
  >;
};

export type DeleteSettlementRangeContractMutationVariables = Exact<{
  id: Scalars['ID'];
  input: DeleteSettlementRangeContractInputInput;
}>;

export type DeleteSettlementRangeContractMutation = { __typename?: 'Mutation' } & {
  deleteSettlementRangeContract?: Maybe<
    { __typename?: 'SettlementRangeContract' } & Pick<
      SettlementRangeContract,
      'id' | 'monthlyUnitPrice' | 'versionNo'
    > & {
        lowerHourPart: { __typename?: 'LowerPart' } & Pick<
          LowerPart,
          'deducationHourlyUnitPrice' | 'lowerHour'
        >;
        member?: Maybe<
          { __typename?: 'SettlementRangeContractMemberPart' } & Pick<
            SettlementRangeContractMemberPart,
            | 'laborCostWithoutTax'
            | 'memberId'
            | 'name'
            | 'profileImageUrl'
            | 'roundedTotalWorkingTimeSec'
            | 'salesAmountWithoutTax'
            | 'totalWorkingTimeSec'
          >
        >;
        upperHourPart: { __typename?: 'UpperPart' } & Pick<
          UpperPart,
          'excessHourlyUnitPrice' | 'upperHour'
        >;
      }
  >;
};

export type DeleteSubTaskMutationVariables = Exact<{
  id: Scalars['ID'];
  input: DeleteSubTaskInputInput;
}>;

export type DeleteSubTaskMutation = { __typename?: 'Mutation' } & {
  deleteSubTask?: Maybe<
    { __typename?: 'Task' } & Pick<
      Task,
      | 'calendarLinkageCode'
      | 'completeDateTime'
      | 'description'
      | 'estimateTimeSec'
      | 'estimatedRemainingTimeSec'
      | 'id'
      | 'laborCost'
      | 'priority'
      | 'progressDeviationRate'
      | 'progressRate'
      | 'registerDateTime'
      | 'remainingTimeSec'
      | 'scheduleWorkingTimeSec'
      | 'scheduledEndDateTime'
      | 'scheduledStartDateTime'
      | 'sortNoInGanttChart'
      | 'sortNoInList'
      | 'sortNoInMyTask'
      | 'sortNoInTaskStatus'
      | 'title'
      | 'versionNo'
      | 'workingTimeSec'
    > & {
        assignees: Array<
          { __typename?: 'AssignedTaskMemberInfo' } & Pick<AssignedTaskMemberInfo, 'sortNo'> & {
              member: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
            }
        >;
        asssignee?: Maybe<
          { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >
        >;
        createdMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
        favoriteMembers: Array<
          Maybe<
            { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >
          >
        >;
        favoriteMembersInfo: Array<
          Maybe<
            { __typename?: 'FavoriteTaskMemberInfo' } & Pick<FavoriteTaskMemberInfo, 'sortNo'> & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
        project: { __typename?: 'Project' } & Pick<
          Project,
          | 'calendarLinkageCode'
          | 'color'
          | 'complete'
          | 'completeDateTime'
          | 'costBudgetWithoutTax'
          | 'description'
          | 'estimateTimeSec'
          | 'id'
          | 'key'
          | 'laborCost'
          | 'laborCostWithoutTax'
          | 'laborShare'
          | 'name'
          | 'priority'
          | 'registerDateTime'
          | 'salesAmountWithoutTax'
          | 'scheduledEndDateTime'
          | 'scheduledStartDateTime'
          | 'sortNoInGanttChart'
          | 'sortNoInList'
          | 'sortNoInProjectStatus'
          | 'totalScheduleTimeSec'
          | 'versionNo'
          | 'workingTimeSec'
        > & {
            assignees: Array<
              { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                AssignedProjectMemberInfo,
                'sortNo'
              >
            >;
            client?: Maybe<
              { __typename?: 'Client' } & Pick<
                Client,
                'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
              >
            >;
            createdMember?: Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >;
            favoriteMembers: Array<
              Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >
            >;
            favoriteMembersInfo: Array<
              Maybe<
                { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                  FavoriteProjectMemberInfo,
                  'sortNo'
                >
              >
            >;
            projectStatus: { __typename?: 'ProjectStatus' } & Pick<
              ProjectStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
            >;
            team: { __typename?: 'Team' } & Pick<
              Team,
              'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
            > & {
                organization: { __typename?: 'Organization' } & Pick<
                  Organization,
                  | 'allowConnectThirdPartyCalendar'
                  | 'contactMailAddress'
                  | 'contractPreriod'
                  | 'id'
                  | 'imageUrl'
                  | 'ipRestrictionFlg'
                  | 'name'
                  | 'requiredTwoFactorAuth'
                  | 'suspensionFlg'
                  | 'versionNo'
                > & {
                    plan: { __typename?: 'PlanProduct' } & Pick<
                      PlanProduct,
                      'code' | 'contractPreriod' | 'id'
                    >;
                  };
              };
          };
        taskStatus: { __typename?: 'TaskStatus' } & Pick<
          TaskStatus,
          'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
        > & {
            project: { __typename?: 'Project' } & Pick<
              Project,
              | 'calendarLinkageCode'
              | 'color'
              | 'complete'
              | 'completeDateTime'
              | 'costBudgetWithoutTax'
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'key'
              | 'laborCost'
              | 'laborCostWithoutTax'
              | 'laborShare'
              | 'name'
              | 'priority'
              | 'registerDateTime'
              | 'salesAmountWithoutTax'
              | 'scheduledEndDateTime'
              | 'scheduledStartDateTime'
              | 'sortNoInGanttChart'
              | 'sortNoInList'
              | 'sortNoInProjectStatus'
              | 'totalScheduleTimeSec'
              | 'versionNo'
              | 'workingTimeSec'
            >;
          };
        workingMembers: Array<
          Maybe<
            { __typename?: 'WorkingMemberInfo' } & Pick<
              WorkingMemberInfo,
              'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
            > & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
      }
  >;
};

export type DeleteTagMutationVariables = Exact<{
  id: Scalars['ID'];
  input: DeleteTagInputInput;
}>;

export type DeleteTagMutation = { __typename?: 'Mutation' } & {
  deleteTag?: Maybe<
    { __typename?: 'Tag' } & Pick<
      Tag,
      'id' | 'name' | 'note' | 'relatedTaskCount' | 'sortNo' | 'versionNo'
    > & {
        organization: { __typename?: 'Organization' } & Pick<
          Organization,
          | 'allowConnectThirdPartyCalendar'
          | 'contactMailAddress'
          | 'contractPreriod'
          | 'id'
          | 'imageUrl'
          | 'ipRestrictionFlg'
          | 'name'
          | 'requiredTwoFactorAuth'
          | 'suspensionFlg'
          | 'versionNo'
        > & {
            plan: { __typename?: 'PlanProduct' } & Pick<
              PlanProduct,
              'code' | 'contractPreriod' | 'id'
            >;
          };
      }
  >;
};

export type DeleteTaskMutationVariables = Exact<{
  id: Scalars['ID'];
  input: DeleteTaskInputInput;
}>;

export type DeleteTaskMutation = { __typename?: 'Mutation' } & {
  deleteTask?: Maybe<
    { __typename?: 'Task' } & Pick<
      Task,
      | 'calendarLinkageCode'
      | 'completeDateTime'
      | 'description'
      | 'estimateTimeSec'
      | 'estimatedRemainingTimeSec'
      | 'id'
      | 'laborCost'
      | 'priority'
      | 'progressDeviationRate'
      | 'progressRate'
      | 'registerDateTime'
      | 'remainingTimeSec'
      | 'scheduleWorkingTimeSec'
      | 'scheduledEndDateTime'
      | 'scheduledStartDateTime'
      | 'sortNoInGanttChart'
      | 'sortNoInList'
      | 'sortNoInMyTask'
      | 'sortNoInTaskStatus'
      | 'title'
      | 'versionNo'
      | 'workingTimeSec'
    > & {
        assignees: Array<
          { __typename?: 'AssignedTaskMemberInfo' } & Pick<AssignedTaskMemberInfo, 'sortNo'> & {
              member: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
            }
        >;
        asssignee?: Maybe<
          { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >
        >;
        createdMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
        favoriteMembers: Array<
          Maybe<
            { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >
          >
        >;
        favoriteMembersInfo: Array<
          Maybe<
            { __typename?: 'FavoriteTaskMemberInfo' } & Pick<FavoriteTaskMemberInfo, 'sortNo'> & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
        project: { __typename?: 'Project' } & Pick<
          Project,
          | 'calendarLinkageCode'
          | 'color'
          | 'complete'
          | 'completeDateTime'
          | 'costBudgetWithoutTax'
          | 'description'
          | 'estimateTimeSec'
          | 'id'
          | 'key'
          | 'laborCost'
          | 'laborCostWithoutTax'
          | 'laborShare'
          | 'name'
          | 'priority'
          | 'registerDateTime'
          | 'salesAmountWithoutTax'
          | 'scheduledEndDateTime'
          | 'scheduledStartDateTime'
          | 'sortNoInGanttChart'
          | 'sortNoInList'
          | 'sortNoInProjectStatus'
          | 'totalScheduleTimeSec'
          | 'versionNo'
          | 'workingTimeSec'
        > & {
            assignees: Array<
              { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                AssignedProjectMemberInfo,
                'sortNo'
              >
            >;
            client?: Maybe<
              { __typename?: 'Client' } & Pick<
                Client,
                'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
              >
            >;
            createdMember?: Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >;
            favoriteMembers: Array<
              Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >
            >;
            favoriteMembersInfo: Array<
              Maybe<
                { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                  FavoriteProjectMemberInfo,
                  'sortNo'
                >
              >
            >;
            projectStatus: { __typename?: 'ProjectStatus' } & Pick<
              ProjectStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
            >;
            team: { __typename?: 'Team' } & Pick<
              Team,
              'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
            > & {
                organization: { __typename?: 'Organization' } & Pick<
                  Organization,
                  | 'allowConnectThirdPartyCalendar'
                  | 'contactMailAddress'
                  | 'contractPreriod'
                  | 'id'
                  | 'imageUrl'
                  | 'ipRestrictionFlg'
                  | 'name'
                  | 'requiredTwoFactorAuth'
                  | 'suspensionFlg'
                  | 'versionNo'
                > & {
                    plan: { __typename?: 'PlanProduct' } & Pick<
                      PlanProduct,
                      'code' | 'contractPreriod' | 'id'
                    >;
                  };
              };
          };
        taskStatus: { __typename?: 'TaskStatus' } & Pick<
          TaskStatus,
          'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
        > & {
            project: { __typename?: 'Project' } & Pick<
              Project,
              | 'calendarLinkageCode'
              | 'color'
              | 'complete'
              | 'completeDateTime'
              | 'costBudgetWithoutTax'
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'key'
              | 'laborCost'
              | 'laborCostWithoutTax'
              | 'laborShare'
              | 'name'
              | 'priority'
              | 'registerDateTime'
              | 'salesAmountWithoutTax'
              | 'scheduledEndDateTime'
              | 'scheduledStartDateTime'
              | 'sortNoInGanttChart'
              | 'sortNoInList'
              | 'sortNoInProjectStatus'
              | 'totalScheduleTimeSec'
              | 'versionNo'
              | 'workingTimeSec'
            >;
          };
        workingMembers: Array<
          Maybe<
            { __typename?: 'WorkingMemberInfo' } & Pick<
              WorkingMemberInfo,
              'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
            > & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
      }
  >;
};

export type DeleteTaskCommentMutationVariables = Exact<{
  id: Scalars['ID'];
  input: DeleteTaskCommentInputInput;
}>;

export type DeleteTaskCommentMutation = { __typename?: 'Mutation' } & {
  deleteTaskComment?: Maybe<
    { __typename?: 'TaskComment' } & Pick<
      TaskComment,
      | 'comment'
      | 'commentedDateTime'
      | 'id'
      | 'projectId'
      | 'projectName'
      | 'taskId'
      | 'taskTitle'
      | 'teamId'
      | 'teamName'
      | 'versionNo'
    > & {
        createdMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
        task?: Maybe<
          { __typename?: 'Task' } & Pick<
            Task,
            | 'calendarLinkageCode'
            | 'completeDateTime'
            | 'description'
            | 'estimateTimeSec'
            | 'estimatedRemainingTimeSec'
            | 'id'
            | 'laborCost'
            | 'priority'
            | 'progressDeviationRate'
            | 'progressRate'
            | 'registerDateTime'
            | 'remainingTimeSec'
            | 'scheduleWorkingTimeSec'
            | 'scheduledEndDateTime'
            | 'scheduledStartDateTime'
            | 'sortNoInGanttChart'
            | 'sortNoInList'
            | 'sortNoInMyTask'
            | 'sortNoInTaskStatus'
            | 'title'
            | 'versionNo'
            | 'workingTimeSec'
          > & {
              assignees: Array<
                { __typename?: 'AssignedTaskMemberInfo' } & Pick<
                  AssignedTaskMemberInfo,
                  'sortNo'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >;
              asssignee?: Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >;
              createdMember: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
              favoriteMembers: Array<
                Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >
              >;
              favoriteMembersInfo: Array<
                Maybe<
                  { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                    FavoriteTaskMemberInfo,
                    'sortNo'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >
              >;
              project: { __typename?: 'Project' } & Pick<
                Project,
                | 'calendarLinkageCode'
                | 'color'
                | 'complete'
                | 'completeDateTime'
                | 'costBudgetWithoutTax'
                | 'description'
                | 'estimateTimeSec'
                | 'id'
                | 'key'
                | 'laborCost'
                | 'laborCostWithoutTax'
                | 'laborShare'
                | 'name'
                | 'priority'
                | 'registerDateTime'
                | 'salesAmountWithoutTax'
                | 'scheduledEndDateTime'
                | 'scheduledStartDateTime'
                | 'sortNoInGanttChart'
                | 'sortNoInList'
                | 'sortNoInProjectStatus'
                | 'totalScheduleTimeSec'
                | 'versionNo'
                | 'workingTimeSec'
              > & {
                  assignees: Array<
                    { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                      AssignedProjectMemberInfo,
                      'sortNo'
                    >
                  >;
                  client?: Maybe<
                    { __typename?: 'Client' } & Pick<
                      Client,
                      'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                    >
                  >;
                  createdMember?: Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >;
                  favoriteMembers: Array<
                    Maybe<
                      { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >
                    >
                  >;
                  favoriteMembersInfo: Array<
                    Maybe<
                      { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                        FavoriteProjectMemberInfo,
                        'sortNo'
                      >
                    >
                  >;
                  projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                    ProjectStatus,
                    'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                  >;
                  team: { __typename?: 'Team' } & Pick<
                    Team,
                    'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                  > & {
                      organization: { __typename?: 'Organization' } & Pick<
                        Organization,
                        | 'allowConnectThirdPartyCalendar'
                        | 'contactMailAddress'
                        | 'contractPreriod'
                        | 'id'
                        | 'imageUrl'
                        | 'ipRestrictionFlg'
                        | 'name'
                        | 'requiredTwoFactorAuth'
                        | 'suspensionFlg'
                        | 'versionNo'
                      > & {
                          plan: { __typename?: 'PlanProduct' } & Pick<
                            PlanProduct,
                            'code' | 'contractPreriod' | 'id'
                          >;
                        };
                    };
                };
              taskStatus: { __typename?: 'TaskStatus' } & Pick<
                TaskStatus,
                'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
              > & {
                  project: { __typename?: 'Project' } & Pick<
                    Project,
                    | 'calendarLinkageCode'
                    | 'color'
                    | 'complete'
                    | 'completeDateTime'
                    | 'costBudgetWithoutTax'
                    | 'description'
                    | 'estimateTimeSec'
                    | 'id'
                    | 'key'
                    | 'laborCost'
                    | 'laborCostWithoutTax'
                    | 'laborShare'
                    | 'name'
                    | 'priority'
                    | 'registerDateTime'
                    | 'salesAmountWithoutTax'
                    | 'scheduledEndDateTime'
                    | 'scheduledStartDateTime'
                    | 'sortNoInGanttChart'
                    | 'sortNoInList'
                    | 'sortNoInProjectStatus'
                    | 'totalScheduleTimeSec'
                    | 'versionNo'
                    | 'workingTimeSec'
                  >;
                };
              workingMembers: Array<
                Maybe<
                  { __typename?: 'WorkingMemberInfo' } & Pick<
                    WorkingMemberInfo,
                    'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >
              >;
            }
        >;
      }
  >;
};

export type DeleteTaskCustomAttributeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteTaskCustomAttributeMutation = { __typename?: 'Mutation' } & {
  deleteTaskCustomAttribute?: Maybe<
    { __typename?: 'TaskCustomAttribute' } & Pick<
      TaskCustomAttribute,
      'id' | 'sortNo' | 'value'
    > & {
        listItem?: Maybe<
          { __typename?: 'TaskCustomAttributeMasterListItem' } & Pick<
            TaskCustomAttributeMasterListItem,
            'id' | 'sortNo' | 'value' | 'versionNo'
          >
        >;
        master: { __typename?: 'TaskCustomAttributeMaster' } & Pick<
          TaskCustomAttributeMaster,
          'id' | 'name' | 'required' | 'sortNo' | 'type' | 'versionNo'
        > & {
            listItem: Array<
              Maybe<
                { __typename?: 'TaskCustomAttributeMasterListItem' } & Pick<
                  TaskCustomAttributeMasterListItem,
                  'id' | 'sortNo' | 'value' | 'versionNo'
                >
              >
            >;
          };
      }
  >;
};

export type DeleteTaskCustomAttributeMasterMutationVariables = Exact<{
  id: Scalars['ID'];
  input: DeleteTaskCustomAttributeMasterInputInput;
}>;

export type DeleteTaskCustomAttributeMasterMutation = { __typename?: 'Mutation' } & {
  deleteTaskCustomAttributeMaster?: Maybe<
    { __typename?: 'TaskCustomAttributeMaster' } & Pick<
      TaskCustomAttributeMaster,
      'id' | 'name' | 'required' | 'sortNo' | 'type' | 'versionNo'
    > & {
        listItem: Array<
          Maybe<
            { __typename?: 'TaskCustomAttributeMasterListItem' } & Pick<
              TaskCustomAttributeMasterListItem,
              'id' | 'sortNo' | 'value' | 'versionNo'
            >
          >
        >;
      }
  >;
};

export type DeleteTaskCustomAttributeMasterListItemMutationVariables = Exact<{
  id: Scalars['ID'];
  input: DeleteTaskCustomAttributeMasterListItemInputInput;
}>;

export type DeleteTaskCustomAttributeMasterListItemMutation = { __typename?: 'Mutation' } & {
  deleteTaskCustomAttributeMasterListItem?: Maybe<
    { __typename?: 'TaskCustomAttributeMasterListItem' } & Pick<
      TaskCustomAttributeMasterListItem,
      'id' | 'sortNo' | 'value' | 'versionNo'
    >
  >;
};

export type DeleteTaskRemainderMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteTaskRemainderMutation = { __typename?: 'Mutation' } & {
  deleteTaskRemainder?: Maybe<
    { __typename?: 'TaskRemainder' } & Pick<
      TaskRemainder,
      | 'beforeDeadlineMinutes'
      | 'id'
      | 'notificationSentFlg'
      | 'taskRemainderDestType'
      | 'taskRemainderType'
    > & {
        targetMember?: Maybe<
          { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >
        >;
        task: { __typename?: 'Task' } & Pick<
          Task,
          | 'calendarLinkageCode'
          | 'completeDateTime'
          | 'description'
          | 'estimateTimeSec'
          | 'estimatedRemainingTimeSec'
          | 'id'
          | 'laborCost'
          | 'priority'
          | 'progressDeviationRate'
          | 'progressRate'
          | 'registerDateTime'
          | 'remainingTimeSec'
          | 'scheduleWorkingTimeSec'
          | 'scheduledEndDateTime'
          | 'scheduledStartDateTime'
          | 'sortNoInGanttChart'
          | 'sortNoInList'
          | 'sortNoInMyTask'
          | 'sortNoInTaskStatus'
          | 'title'
          | 'versionNo'
          | 'workingTimeSec'
        > & {
            assignees: Array<
              { __typename?: 'AssignedTaskMemberInfo' } & Pick<AssignedTaskMemberInfo, 'sortNo'> & {
                  member: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                }
            >;
            asssignee?: Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >;
            createdMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
            favoriteMembers: Array<
              Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >
            >;
            favoriteMembersInfo: Array<
              Maybe<
                { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                  FavoriteTaskMemberInfo,
                  'sortNo'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >
            >;
            project: { __typename?: 'Project' } & Pick<
              Project,
              | 'calendarLinkageCode'
              | 'color'
              | 'complete'
              | 'completeDateTime'
              | 'costBudgetWithoutTax'
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'key'
              | 'laborCost'
              | 'laborCostWithoutTax'
              | 'laborShare'
              | 'name'
              | 'priority'
              | 'registerDateTime'
              | 'salesAmountWithoutTax'
              | 'scheduledEndDateTime'
              | 'scheduledStartDateTime'
              | 'sortNoInGanttChart'
              | 'sortNoInList'
              | 'sortNoInProjectStatus'
              | 'totalScheduleTimeSec'
              | 'versionNo'
              | 'workingTimeSec'
            > & {
                assignees: Array<
                  { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                    AssignedProjectMemberInfo,
                    'sortNo'
                  >
                >;
                client?: Maybe<
                  { __typename?: 'Client' } & Pick<
                    Client,
                    'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                  >
                >;
                createdMember?: Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >;
                favoriteMembers: Array<
                  Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >
                >;
                favoriteMembersInfo: Array<
                  Maybe<
                    { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                      FavoriteProjectMemberInfo,
                      'sortNo'
                    >
                  >
                >;
                projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                  ProjectStatus,
                  'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                >;
                team: { __typename?: 'Team' } & Pick<
                  Team,
                  'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                > & {
                    organization: { __typename?: 'Organization' } & Pick<
                      Organization,
                      | 'allowConnectThirdPartyCalendar'
                      | 'contactMailAddress'
                      | 'contractPreriod'
                      | 'id'
                      | 'imageUrl'
                      | 'ipRestrictionFlg'
                      | 'name'
                      | 'requiredTwoFactorAuth'
                      | 'suspensionFlg'
                      | 'versionNo'
                    > & {
                        plan: { __typename?: 'PlanProduct' } & Pick<
                          PlanProduct,
                          'code' | 'contractPreriod' | 'id'
                        >;
                      };
                  };
              };
            taskStatus: { __typename?: 'TaskStatus' } & Pick<
              TaskStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
            > & {
                project: { __typename?: 'Project' } & Pick<
                  Project,
                  | 'calendarLinkageCode'
                  | 'color'
                  | 'complete'
                  | 'completeDateTime'
                  | 'costBudgetWithoutTax'
                  | 'description'
                  | 'estimateTimeSec'
                  | 'id'
                  | 'key'
                  | 'laborCost'
                  | 'laborCostWithoutTax'
                  | 'laborShare'
                  | 'name'
                  | 'priority'
                  | 'registerDateTime'
                  | 'salesAmountWithoutTax'
                  | 'scheduledEndDateTime'
                  | 'scheduledStartDateTime'
                  | 'sortNoInGanttChart'
                  | 'sortNoInList'
                  | 'sortNoInProjectStatus'
                  | 'totalScheduleTimeSec'
                  | 'versionNo'
                  | 'workingTimeSec'
                >;
              };
            workingMembers: Array<
              Maybe<
                { __typename?: 'WorkingMemberInfo' } & Pick<
                  WorkingMemberInfo,
                  'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >
            >;
          };
      }
  >;
};

export type DeleteTaskStatusMutationVariables = Exact<{
  id: Scalars['ID'];
  input: DeleteTaskStatusInputInput;
}>;

export type DeleteTaskStatusMutation = { __typename?: 'Mutation' } & {
  deleteTaskStatus?: Maybe<
    { __typename?: 'TaskStatus' } & Pick<
      TaskStatus,
      'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
    > & {
        project: { __typename?: 'Project' } & Pick<
          Project,
          | 'calendarLinkageCode'
          | 'color'
          | 'complete'
          | 'completeDateTime'
          | 'costBudgetWithoutTax'
          | 'description'
          | 'estimateTimeSec'
          | 'id'
          | 'key'
          | 'laborCost'
          | 'laborCostWithoutTax'
          | 'laborShare'
          | 'name'
          | 'priority'
          | 'registerDateTime'
          | 'salesAmountWithoutTax'
          | 'scheduledEndDateTime'
          | 'scheduledStartDateTime'
          | 'sortNoInGanttChart'
          | 'sortNoInList'
          | 'sortNoInProjectStatus'
          | 'totalScheduleTimeSec'
          | 'versionNo'
          | 'workingTimeSec'
        > & {
            assignees: Array<
              { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                AssignedProjectMemberInfo,
                'sortNo'
              > & {
                  member: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                }
            >;
            client?: Maybe<
              { __typename?: 'Client' } & Pick<
                Client,
                'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
              >
            >;
            createdMember?: Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >;
            favoriteMembers: Array<
              Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >
            >;
            favoriteMembersInfo: Array<
              Maybe<
                { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                  FavoriteProjectMemberInfo,
                  'sortNo'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >
            >;
            projectStatus: { __typename?: 'ProjectStatus' } & Pick<
              ProjectStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
            >;
            team: { __typename?: 'Team' } & Pick<
              Team,
              'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
            > & {
                organization: { __typename?: 'Organization' } & Pick<
                  Organization,
                  | 'allowConnectThirdPartyCalendar'
                  | 'contactMailAddress'
                  | 'contractPreriod'
                  | 'id'
                  | 'imageUrl'
                  | 'ipRestrictionFlg'
                  | 'name'
                  | 'requiredTwoFactorAuth'
                  | 'suspensionFlg'
                  | 'versionNo'
                > & {
                    plan: { __typename?: 'PlanProduct' } & Pick<
                      PlanProduct,
                      'code' | 'contractPreriod' | 'id'
                    >;
                  };
              };
          };
      }
  >;
};

export type DeleteWorkingHistoryMutationVariables = Exact<{
  id: Scalars['String'];
  input: DeleteWorkingHistoryInputInput;
}>;

export type DeleteWorkingHistoryMutation = { __typename?: 'Mutation' } & {
  deleteWorkingHistory?: Maybe<
    { __typename?: 'WorkingHistory' } & Pick<
      WorkingHistory,
      'end' | 'id' | 'memo' | 'start' | 'versionNo' | 'workingTimeSec'
    > & {
        calendarEvent?: Maybe<
          { __typename?: 'CalendarEvent' } & Pick<
            CalendarEvent,
            | 'calendarType'
            | 'clientId'
            | 'clientName'
            | 'endDateTime'
            | 'eventName'
            | 'id'
            | 'laborCost'
            | 'personalTeam'
            | 'projectColor'
            | 'projectId'
            | 'projectName'
            | 'startDateTime'
            | 'taskId'
            | 'taskTitle'
            | 'teamId'
            | 'teamName'
            | 'thirdPartyCalendarEventId'
            | 'versionNo'
            | 'workingTimeSec'
          > & {
              thirdPartyCalendarInfoList: Array<
                Maybe<
                  { __typename?: 'ThirdPartyCalendarInfo' } & Pick<
                    ThirdPartyCalendarInfo,
                    'thirdPartyCalendarId'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >
              >;
            }
        >;
        closingProcessInfo: { __typename?: 'ClosingProcessPart' } & Pick<
          ClosingProcessPart,
          'closed' | 'targetDateTime'
        >;
        task?: Maybe<
          { __typename?: 'Task' } & Pick<
            Task,
            | 'calendarLinkageCode'
            | 'completeDateTime'
            | 'description'
            | 'estimateTimeSec'
            | 'estimatedRemainingTimeSec'
            | 'id'
            | 'laborCost'
            | 'priority'
            | 'progressDeviationRate'
            | 'progressRate'
            | 'registerDateTime'
            | 'remainingTimeSec'
            | 'scheduleWorkingTimeSec'
            | 'scheduledEndDateTime'
            | 'scheduledStartDateTime'
            | 'sortNoInGanttChart'
            | 'sortNoInList'
            | 'sortNoInMyTask'
            | 'sortNoInTaskStatus'
            | 'title'
            | 'versionNo'
            | 'workingTimeSec'
          > & {
              assignees: Array<
                { __typename?: 'AssignedTaskMemberInfo' } & Pick<
                  AssignedTaskMemberInfo,
                  'sortNo'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >;
              asssignee?: Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >;
              createdMember: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
              favoriteMembers: Array<
                Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >
              >;
              favoriteMembersInfo: Array<
                Maybe<
                  { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                    FavoriteTaskMemberInfo,
                    'sortNo'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >
              >;
              project: { __typename?: 'Project' } & Pick<
                Project,
                | 'calendarLinkageCode'
                | 'color'
                | 'complete'
                | 'completeDateTime'
                | 'costBudgetWithoutTax'
                | 'description'
                | 'estimateTimeSec'
                | 'id'
                | 'key'
                | 'laborCost'
                | 'laborCostWithoutTax'
                | 'laborShare'
                | 'name'
                | 'priority'
                | 'registerDateTime'
                | 'salesAmountWithoutTax'
                | 'scheduledEndDateTime'
                | 'scheduledStartDateTime'
                | 'sortNoInGanttChart'
                | 'sortNoInList'
                | 'sortNoInProjectStatus'
                | 'totalScheduleTimeSec'
                | 'versionNo'
                | 'workingTimeSec'
              > & {
                  assignees: Array<
                    { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                      AssignedProjectMemberInfo,
                      'sortNo'
                    >
                  >;
                  client?: Maybe<
                    { __typename?: 'Client' } & Pick<
                      Client,
                      'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                    >
                  >;
                  createdMember?: Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >;
                  favoriteMembers: Array<
                    Maybe<
                      { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >
                    >
                  >;
                  favoriteMembersInfo: Array<
                    Maybe<
                      { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                        FavoriteProjectMemberInfo,
                        'sortNo'
                      >
                    >
                  >;
                  projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                    ProjectStatus,
                    'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                  >;
                  team: { __typename?: 'Team' } & Pick<
                    Team,
                    'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                  > & {
                      organization: { __typename?: 'Organization' } & Pick<
                        Organization,
                        | 'allowConnectThirdPartyCalendar'
                        | 'contactMailAddress'
                        | 'contractPreriod'
                        | 'id'
                        | 'imageUrl'
                        | 'ipRestrictionFlg'
                        | 'name'
                        | 'requiredTwoFactorAuth'
                        | 'suspensionFlg'
                        | 'versionNo'
                      > & {
                          plan: { __typename?: 'PlanProduct' } & Pick<
                            PlanProduct,
                            'code' | 'contractPreriod' | 'id'
                          >;
                        };
                    };
                };
              taskStatus: { __typename?: 'TaskStatus' } & Pick<
                TaskStatus,
                'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
              > & {
                  project: { __typename?: 'Project' } & Pick<
                    Project,
                    | 'calendarLinkageCode'
                    | 'color'
                    | 'complete'
                    | 'completeDateTime'
                    | 'costBudgetWithoutTax'
                    | 'description'
                    | 'estimateTimeSec'
                    | 'id'
                    | 'key'
                    | 'laborCost'
                    | 'laborCostWithoutTax'
                    | 'laborShare'
                    | 'name'
                    | 'priority'
                    | 'registerDateTime'
                    | 'salesAmountWithoutTax'
                    | 'scheduledEndDateTime'
                    | 'scheduledStartDateTime'
                    | 'sortNoInGanttChart'
                    | 'sortNoInList'
                    | 'sortNoInProjectStatus'
                    | 'totalScheduleTimeSec'
                    | 'versionNo'
                    | 'workingTimeSec'
                  >;
                };
              workingMembers: Array<
                Maybe<
                  { __typename?: 'WorkingMemberInfo' } & Pick<
                    WorkingMemberInfo,
                    'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >
              >;
            }
        >;
        workingMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
      }
  >;
};

export type DeleteWorkingScheduleMutationVariables = Exact<{
  id: Scalars['String'];
  input: DeleteWorkingHistoryInputInput;
}>;

export type DeleteWorkingScheduleMutation = { __typename?: 'Mutation' } & {
  deleteWorkingSchedule?: Maybe<
    { __typename?: 'WorkingSchedule' } & Pick<
      WorkingSchedule,
      'end' | 'id' | 'memo' | 'start' | 'versionNo' | 'workingTimeSec'
    > & {
        calendarEvent?: Maybe<
          { __typename?: 'CalendarEvent' } & Pick<
            CalendarEvent,
            | 'calendarType'
            | 'clientId'
            | 'clientName'
            | 'endDateTime'
            | 'eventName'
            | 'id'
            | 'laborCost'
            | 'personalTeam'
            | 'projectColor'
            | 'projectId'
            | 'projectName'
            | 'startDateTime'
            | 'taskId'
            | 'taskTitle'
            | 'teamId'
            | 'teamName'
            | 'thirdPartyCalendarEventId'
            | 'versionNo'
            | 'workingTimeSec'
          > & {
              thirdPartyCalendarInfoList: Array<
                Maybe<
                  { __typename?: 'ThirdPartyCalendarInfo' } & Pick<
                    ThirdPartyCalendarInfo,
                    'thirdPartyCalendarId'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >
              >;
            }
        >;
        task?: Maybe<
          { __typename?: 'Task' } & Pick<
            Task,
            | 'calendarLinkageCode'
            | 'completeDateTime'
            | 'description'
            | 'estimateTimeSec'
            | 'estimatedRemainingTimeSec'
            | 'id'
            | 'laborCost'
            | 'priority'
            | 'progressDeviationRate'
            | 'progressRate'
            | 'registerDateTime'
            | 'remainingTimeSec'
            | 'scheduleWorkingTimeSec'
            | 'scheduledEndDateTime'
            | 'scheduledStartDateTime'
            | 'sortNoInGanttChart'
            | 'sortNoInList'
            | 'sortNoInMyTask'
            | 'sortNoInTaskStatus'
            | 'title'
            | 'versionNo'
            | 'workingTimeSec'
          > & {
              assignees: Array<
                { __typename?: 'AssignedTaskMemberInfo' } & Pick<
                  AssignedTaskMemberInfo,
                  'sortNo'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >;
              asssignee?: Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >;
              createdMember: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
              favoriteMembers: Array<
                Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >
              >;
              favoriteMembersInfo: Array<
                Maybe<
                  { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                    FavoriteTaskMemberInfo,
                    'sortNo'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >
              >;
              project: { __typename?: 'Project' } & Pick<
                Project,
                | 'calendarLinkageCode'
                | 'color'
                | 'complete'
                | 'completeDateTime'
                | 'costBudgetWithoutTax'
                | 'description'
                | 'estimateTimeSec'
                | 'id'
                | 'key'
                | 'laborCost'
                | 'laborCostWithoutTax'
                | 'laborShare'
                | 'name'
                | 'priority'
                | 'registerDateTime'
                | 'salesAmountWithoutTax'
                | 'scheduledEndDateTime'
                | 'scheduledStartDateTime'
                | 'sortNoInGanttChart'
                | 'sortNoInList'
                | 'sortNoInProjectStatus'
                | 'totalScheduleTimeSec'
                | 'versionNo'
                | 'workingTimeSec'
              > & {
                  assignees: Array<
                    { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                      AssignedProjectMemberInfo,
                      'sortNo'
                    >
                  >;
                  client?: Maybe<
                    { __typename?: 'Client' } & Pick<
                      Client,
                      'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                    >
                  >;
                  createdMember?: Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >;
                  favoriteMembers: Array<
                    Maybe<
                      { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >
                    >
                  >;
                  favoriteMembersInfo: Array<
                    Maybe<
                      { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                        FavoriteProjectMemberInfo,
                        'sortNo'
                      >
                    >
                  >;
                  projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                    ProjectStatus,
                    'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                  >;
                  team: { __typename?: 'Team' } & Pick<
                    Team,
                    'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                  > & {
                      organization: { __typename?: 'Organization' } & Pick<
                        Organization,
                        | 'allowConnectThirdPartyCalendar'
                        | 'contactMailAddress'
                        | 'contractPreriod'
                        | 'id'
                        | 'imageUrl'
                        | 'ipRestrictionFlg'
                        | 'name'
                        | 'requiredTwoFactorAuth'
                        | 'suspensionFlg'
                        | 'versionNo'
                      > & {
                          plan: { __typename?: 'PlanProduct' } & Pick<
                            PlanProduct,
                            'code' | 'contractPreriod' | 'id'
                          >;
                        };
                    };
                };
              taskStatus: { __typename?: 'TaskStatus' } & Pick<
                TaskStatus,
                'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
              > & {
                  project: { __typename?: 'Project' } & Pick<
                    Project,
                    | 'calendarLinkageCode'
                    | 'color'
                    | 'complete'
                    | 'completeDateTime'
                    | 'costBudgetWithoutTax'
                    | 'description'
                    | 'estimateTimeSec'
                    | 'id'
                    | 'key'
                    | 'laborCost'
                    | 'laborCostWithoutTax'
                    | 'laborShare'
                    | 'name'
                    | 'priority'
                    | 'registerDateTime'
                    | 'salesAmountWithoutTax'
                    | 'scheduledEndDateTime'
                    | 'scheduledStartDateTime'
                    | 'sortNoInGanttChart'
                    | 'sortNoInList'
                    | 'sortNoInProjectStatus'
                    | 'totalScheduleTimeSec'
                    | 'versionNo'
                    | 'workingTimeSec'
                  >;
                };
              workingMembers: Array<
                Maybe<
                  { __typename?: 'WorkingMemberInfo' } & Pick<
                    WorkingMemberInfo,
                    'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >
              >;
            }
        >;
        workingMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
      }
  >;
};

export type DisableAccessControlMutationVariables = Exact<{
  input: UpdateAccessControlInputInput;
}>;

export type DisableAccessControlMutation = { __typename?: 'Mutation' } & {
  disableAccessControl?: Maybe<
    { __typename?: 'AccessControlSettings' } & Pick<
      AccessControlSettings,
      'enable' | 'id' | 'ipAddresses' | 'versionNo'
    >
  >;
};

export type DisableAccessControlByTokenMutationVariables = Exact<{
  token: Scalars['String'];
}>;

export type DisableAccessControlByTokenMutation = { __typename?: 'Mutation' } & {
  disableAccessControlByToken?: Maybe<
    { __typename?: 'AccessControlSettings' } & Pick<
      AccessControlSettings,
      'enable' | 'id' | 'ipAddresses' | 'versionNo'
    >
  >;
};

export type DisableThirdPartyCalendarConnectMutationVariables = Exact<{
  input: UpdateThirdPartyCalendarConnectInputInput;
}>;

export type DisableThirdPartyCalendarConnectMutation = { __typename?: 'Mutation' } & {
  disableThirdPartyCalendarConnect?: Maybe<
    { __typename?: 'Organization' } & Pick<
      Organization,
      | 'allowConnectThirdPartyCalendar'
      | 'contactMailAddress'
      | 'contractPreriod'
      | 'id'
      | 'imageUrl'
      | 'ipRestrictionFlg'
      | 'name'
      | 'requiredTwoFactorAuth'
      | 'suspensionFlg'
      | 'versionNo'
    > & {
        plan: { __typename?: 'PlanProduct' } & Pick<PlanProduct, 'code' | 'contractPreriod' | 'id'>;
      }
  >;
};

export type DisableTwoFactorAuthRequiedMutationVariables = Exact<{
  id: Scalars['ID'];
  input: DisableTwoFactorAuthRequiredInputInput;
}>;

export type DisableTwoFactorAuthRequiedMutation = { __typename?: 'Mutation' } & {
  disableTwoFactorAuthRequied?: Maybe<
    { __typename?: 'Organization' } & Pick<
      Organization,
      | 'allowConnectThirdPartyCalendar'
      | 'contactMailAddress'
      | 'contractPreriod'
      | 'id'
      | 'imageUrl'
      | 'ipRestrictionFlg'
      | 'name'
      | 'requiredTwoFactorAuth'
      | 'suspensionFlg'
      | 'versionNo'
    > & {
        plan: { __typename?: 'PlanProduct' } & Pick<PlanProduct, 'code' | 'contractPreriod' | 'id'>;
      }
  >;
};

export type DisconnectAppleAccountMutationVariables = Exact<{ [key: string]: never }>;

export type DisconnectAppleAccountMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'disconnectAppleAccount'
>;

export type DisconnectGoogleAccountMutationVariables = Exact<{ [key: string]: never }>;

export type DisconnectGoogleAccountMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'disconnectGoogleAccount'
>;

export type EnableAccessControlMutationVariables = Exact<{
  input: UpdateAccessControlInputInput;
}>;

export type EnableAccessControlMutation = { __typename?: 'Mutation' } & {
  enableAccessControl?: Maybe<
    { __typename?: 'AccessControlSettings' } & Pick<
      AccessControlSettings,
      'enable' | 'id' | 'ipAddresses' | 'versionNo'
    >
  >;
};

export type EnableThirdPartyCalendarConnectMutationVariables = Exact<{
  input: UpdateThirdPartyCalendarConnectInputInput;
}>;

export type EnableThirdPartyCalendarConnectMutation = { __typename?: 'Mutation' } & {
  enableThirdPartyCalendarConnect?: Maybe<
    { __typename?: 'Organization' } & Pick<
      Organization,
      | 'allowConnectThirdPartyCalendar'
      | 'contactMailAddress'
      | 'contractPreriod'
      | 'id'
      | 'imageUrl'
      | 'ipRestrictionFlg'
      | 'name'
      | 'requiredTwoFactorAuth'
      | 'suspensionFlg'
      | 'versionNo'
    > & {
        plan: { __typename?: 'PlanProduct' } & Pick<PlanProduct, 'code' | 'contractPreriod' | 'id'>;
      }
  >;
};

export type EnableTwoFactorAuthRequiedMutationVariables = Exact<{
  id: Scalars['ID'];
  input: EnableTwoFactorAuthRequiredInputInput;
}>;

export type EnableTwoFactorAuthRequiedMutation = { __typename?: 'Mutation' } & {
  enableTwoFactorAuthRequied?: Maybe<
    { __typename?: 'Organization' } & Pick<
      Organization,
      | 'allowConnectThirdPartyCalendar'
      | 'contactMailAddress'
      | 'contractPreriod'
      | 'id'
      | 'imageUrl'
      | 'ipRestrictionFlg'
      | 'name'
      | 'requiredTwoFactorAuth'
      | 'suspensionFlg'
      | 'versionNo'
    > & {
        plan: { __typename?: 'PlanProduct' } & Pick<PlanProduct, 'code' | 'contractPreriod' | 'id'>;
      }
  >;
};

export type ExportICalFileMutationVariables = Exact<{
  input?: Maybe<ICalExportInputInput>;
}>;

export type ExportICalFileMutation = { __typename?: 'Mutation' } & {
  exportICalFile?: Maybe<
    { __typename?: 'ICalExport' } & Pick<ICalExport, 'end' | 'id' | 'start' | 'status' | 'type'>
  >;
};

export type FavoriteProjectMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: Maybe<FavoriteProjectInputInput>;
}>;

export type FavoriteProjectMutation = { __typename?: 'Mutation' } & {
  favoriteProject?: Maybe<
    { __typename?: 'Project' } & Pick<
      Project,
      | 'calendarLinkageCode'
      | 'color'
      | 'complete'
      | 'completeDateTime'
      | 'costBudgetWithoutTax'
      | 'description'
      | 'estimateTimeSec'
      | 'id'
      | 'key'
      | 'laborCost'
      | 'laborCostWithoutTax'
      | 'laborShare'
      | 'name'
      | 'priority'
      | 'registerDateTime'
      | 'salesAmountWithoutTax'
      | 'scheduledEndDateTime'
      | 'scheduledStartDateTime'
      | 'sortNoInGanttChart'
      | 'sortNoInList'
      | 'sortNoInProjectStatus'
      | 'totalScheduleTimeSec'
      | 'versionNo'
      | 'workingTimeSec'
    > & {
        assignees: Array<
          { __typename?: 'AssignedProjectMemberInfo' } & Pick<
            AssignedProjectMemberInfo,
            'sortNo'
          > & {
              member: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
            }
        >;
        client?: Maybe<
          { __typename?: 'Client' } & Pick<Client, 'code' | 'id' | 'name' | 'sortNo' | 'versionNo'>
        >;
        createdMember?: Maybe<
          { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >
        >;
        favoriteMembers: Array<
          Maybe<
            { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >
          >
        >;
        favoriteMembersInfo: Array<
          Maybe<
            { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
              FavoriteProjectMemberInfo,
              'sortNo'
            > & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
        projectStatus: { __typename?: 'ProjectStatus' } & Pick<
          ProjectStatus,
          'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
        >;
        team: { __typename?: 'Team' } & Pick<
          Team,
          'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
        > & {
            organization: { __typename?: 'Organization' } & Pick<
              Organization,
              | 'allowConnectThirdPartyCalendar'
              | 'contactMailAddress'
              | 'contractPreriod'
              | 'id'
              | 'imageUrl'
              | 'ipRestrictionFlg'
              | 'name'
              | 'requiredTwoFactorAuth'
              | 'suspensionFlg'
              | 'versionNo'
            > & {
                plan: { __typename?: 'PlanProduct' } & Pick<
                  PlanProduct,
                  'code' | 'contractPreriod' | 'id'
                >;
              };
          };
      }
  >;
};

export type FavoriteTaskMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: Maybe<FavoriteTaskInputInput>;
}>;

export type FavoriteTaskMutation = { __typename?: 'Mutation' } & {
  favoriteTask?: Maybe<
    { __typename?: 'Task' } & Pick<
      Task,
      | 'calendarLinkageCode'
      | 'completeDateTime'
      | 'description'
      | 'estimateTimeSec'
      | 'estimatedRemainingTimeSec'
      | 'id'
      | 'laborCost'
      | 'priority'
      | 'progressDeviationRate'
      | 'progressRate'
      | 'registerDateTime'
      | 'remainingTimeSec'
      | 'scheduleWorkingTimeSec'
      | 'scheduledEndDateTime'
      | 'scheduledStartDateTime'
      | 'sortNoInGanttChart'
      | 'sortNoInList'
      | 'sortNoInMyTask'
      | 'sortNoInTaskStatus'
      | 'title'
      | 'versionNo'
      | 'workingTimeSec'
    > & {
        assignees: Array<
          { __typename?: 'AssignedTaskMemberInfo' } & Pick<AssignedTaskMemberInfo, 'sortNo'> & {
              member: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
            }
        >;
        asssignee?: Maybe<
          { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >
        >;
        createdMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
        favoriteMembers: Array<
          Maybe<
            { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >
          >
        >;
        favoriteMembersInfo: Array<
          Maybe<
            { __typename?: 'FavoriteTaskMemberInfo' } & Pick<FavoriteTaskMemberInfo, 'sortNo'> & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
        project: { __typename?: 'Project' } & Pick<
          Project,
          | 'calendarLinkageCode'
          | 'color'
          | 'complete'
          | 'completeDateTime'
          | 'costBudgetWithoutTax'
          | 'description'
          | 'estimateTimeSec'
          | 'id'
          | 'key'
          | 'laborCost'
          | 'laborCostWithoutTax'
          | 'laborShare'
          | 'name'
          | 'priority'
          | 'registerDateTime'
          | 'salesAmountWithoutTax'
          | 'scheduledEndDateTime'
          | 'scheduledStartDateTime'
          | 'sortNoInGanttChart'
          | 'sortNoInList'
          | 'sortNoInProjectStatus'
          | 'totalScheduleTimeSec'
          | 'versionNo'
          | 'workingTimeSec'
        > & {
            assignees: Array<
              { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                AssignedProjectMemberInfo,
                'sortNo'
              >
            >;
            client?: Maybe<
              { __typename?: 'Client' } & Pick<
                Client,
                'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
              >
            >;
            createdMember?: Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >;
            favoriteMembers: Array<
              Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >
            >;
            favoriteMembersInfo: Array<
              Maybe<
                { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                  FavoriteProjectMemberInfo,
                  'sortNo'
                >
              >
            >;
            projectStatus: { __typename?: 'ProjectStatus' } & Pick<
              ProjectStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
            >;
            team: { __typename?: 'Team' } & Pick<
              Team,
              'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
            > & {
                organization: { __typename?: 'Organization' } & Pick<
                  Organization,
                  | 'allowConnectThirdPartyCalendar'
                  | 'contactMailAddress'
                  | 'contractPreriod'
                  | 'id'
                  | 'imageUrl'
                  | 'ipRestrictionFlg'
                  | 'name'
                  | 'requiredTwoFactorAuth'
                  | 'suspensionFlg'
                  | 'versionNo'
                > & {
                    plan: { __typename?: 'PlanProduct' } & Pick<
                      PlanProduct,
                      'code' | 'contractPreriod' | 'id'
                    >;
                  };
              };
          };
        taskStatus: { __typename?: 'TaskStatus' } & Pick<
          TaskStatus,
          'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
        > & {
            project: { __typename?: 'Project' } & Pick<
              Project,
              | 'calendarLinkageCode'
              | 'color'
              | 'complete'
              | 'completeDateTime'
              | 'costBudgetWithoutTax'
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'key'
              | 'laborCost'
              | 'laborCostWithoutTax'
              | 'laborShare'
              | 'name'
              | 'priority'
              | 'registerDateTime'
              | 'salesAmountWithoutTax'
              | 'scheduledEndDateTime'
              | 'scheduledStartDateTime'
              | 'sortNoInGanttChart'
              | 'sortNoInList'
              | 'sortNoInProjectStatus'
              | 'totalScheduleTimeSec'
              | 'versionNo'
              | 'workingTimeSec'
            >;
          };
        workingMembers: Array<
          Maybe<
            { __typename?: 'WorkingMemberInfo' } & Pick<
              WorkingMemberInfo,
              'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
            > & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
      }
  >;
};

export type IncompleteProjectMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: Maybe<IncompleteProjectInputInput>;
}>;

export type IncompleteProjectMutation = { __typename?: 'Mutation' } & {
  incompleteProject?: Maybe<
    { __typename?: 'Project' } & Pick<
      Project,
      | 'calendarLinkageCode'
      | 'color'
      | 'complete'
      | 'completeDateTime'
      | 'costBudgetWithoutTax'
      | 'description'
      | 'estimateTimeSec'
      | 'id'
      | 'key'
      | 'laborCost'
      | 'laborCostWithoutTax'
      | 'laborShare'
      | 'name'
      | 'priority'
      | 'registerDateTime'
      | 'salesAmountWithoutTax'
      | 'scheduledEndDateTime'
      | 'scheduledStartDateTime'
      | 'sortNoInGanttChart'
      | 'sortNoInList'
      | 'sortNoInProjectStatus'
      | 'totalScheduleTimeSec'
      | 'versionNo'
      | 'workingTimeSec'
    > & {
        assignees: Array<
          { __typename?: 'AssignedProjectMemberInfo' } & Pick<
            AssignedProjectMemberInfo,
            'sortNo'
          > & {
              member: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
            }
        >;
        client?: Maybe<
          { __typename?: 'Client' } & Pick<Client, 'code' | 'id' | 'name' | 'sortNo' | 'versionNo'>
        >;
        createdMember?: Maybe<
          { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >
        >;
        favoriteMembers: Array<
          Maybe<
            { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >
          >
        >;
        favoriteMembersInfo: Array<
          Maybe<
            { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
              FavoriteProjectMemberInfo,
              'sortNo'
            > & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
        projectStatus: { __typename?: 'ProjectStatus' } & Pick<
          ProjectStatus,
          'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
        >;
        team: { __typename?: 'Team' } & Pick<
          Team,
          'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
        > & {
            organization: { __typename?: 'Organization' } & Pick<
              Organization,
              | 'allowConnectThirdPartyCalendar'
              | 'contactMailAddress'
              | 'contractPreriod'
              | 'id'
              | 'imageUrl'
              | 'ipRestrictionFlg'
              | 'name'
              | 'requiredTwoFactorAuth'
              | 'suspensionFlg'
              | 'versionNo'
            > & {
                plan: { __typename?: 'PlanProduct' } & Pick<
                  PlanProduct,
                  'code' | 'contractPreriod' | 'id'
                >;
              };
          };
      }
  >;
};

export type IncompleteSubTaskMutationVariables = Exact<{
  id: Scalars['ID'];
  input: IncompleteSubTaskInputInput;
}>;

export type IncompleteSubTaskMutation = { __typename?: 'Mutation' } & {
  incompleteSubTask?: Maybe<
    { __typename?: 'SubTask' } & Pick<
      SubTask,
      'complete' | 'completeDateTime' | 'id' | 'sortNo' | 'title' | 'versionNo'
    > & {
        createdMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
        task: { __typename?: 'Task' } & Pick<
          Task,
          | 'calendarLinkageCode'
          | 'completeDateTime'
          | 'description'
          | 'estimateTimeSec'
          | 'estimatedRemainingTimeSec'
          | 'id'
          | 'laborCost'
          | 'priority'
          | 'progressDeviationRate'
          | 'progressRate'
          | 'registerDateTime'
          | 'remainingTimeSec'
          | 'scheduleWorkingTimeSec'
          | 'scheduledEndDateTime'
          | 'scheduledStartDateTime'
          | 'sortNoInGanttChart'
          | 'sortNoInList'
          | 'sortNoInMyTask'
          | 'sortNoInTaskStatus'
          | 'title'
          | 'versionNo'
          | 'workingTimeSec'
        > & {
            assignees: Array<
              { __typename?: 'AssignedTaskMemberInfo' } & Pick<AssignedTaskMemberInfo, 'sortNo'> & {
                  member: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                }
            >;
            asssignee?: Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >;
            createdMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
            favoriteMembers: Array<
              Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >
            >;
            favoriteMembersInfo: Array<
              Maybe<
                { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                  FavoriteTaskMemberInfo,
                  'sortNo'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >
            >;
            project: { __typename?: 'Project' } & Pick<
              Project,
              | 'calendarLinkageCode'
              | 'color'
              | 'complete'
              | 'completeDateTime'
              | 'costBudgetWithoutTax'
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'key'
              | 'laborCost'
              | 'laborCostWithoutTax'
              | 'laborShare'
              | 'name'
              | 'priority'
              | 'registerDateTime'
              | 'salesAmountWithoutTax'
              | 'scheduledEndDateTime'
              | 'scheduledStartDateTime'
              | 'sortNoInGanttChart'
              | 'sortNoInList'
              | 'sortNoInProjectStatus'
              | 'totalScheduleTimeSec'
              | 'versionNo'
              | 'workingTimeSec'
            > & {
                assignees: Array<
                  { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                    AssignedProjectMemberInfo,
                    'sortNo'
                  >
                >;
                client?: Maybe<
                  { __typename?: 'Client' } & Pick<
                    Client,
                    'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                  >
                >;
                createdMember?: Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >;
                favoriteMembers: Array<
                  Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >
                >;
                favoriteMembersInfo: Array<
                  Maybe<
                    { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                      FavoriteProjectMemberInfo,
                      'sortNo'
                    >
                  >
                >;
                projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                  ProjectStatus,
                  'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                >;
                team: { __typename?: 'Team' } & Pick<
                  Team,
                  'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                > & {
                    organization: { __typename?: 'Organization' } & Pick<
                      Organization,
                      | 'allowConnectThirdPartyCalendar'
                      | 'contactMailAddress'
                      | 'contractPreriod'
                      | 'id'
                      | 'imageUrl'
                      | 'ipRestrictionFlg'
                      | 'name'
                      | 'requiredTwoFactorAuth'
                      | 'suspensionFlg'
                      | 'versionNo'
                    > & {
                        plan: { __typename?: 'PlanProduct' } & Pick<
                          PlanProduct,
                          'code' | 'contractPreriod' | 'id'
                        >;
                      };
                  };
              };
            taskStatus: { __typename?: 'TaskStatus' } & Pick<
              TaskStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
            > & {
                project: { __typename?: 'Project' } & Pick<
                  Project,
                  | 'calendarLinkageCode'
                  | 'color'
                  | 'complete'
                  | 'completeDateTime'
                  | 'costBudgetWithoutTax'
                  | 'description'
                  | 'estimateTimeSec'
                  | 'id'
                  | 'key'
                  | 'laborCost'
                  | 'laborCostWithoutTax'
                  | 'laborShare'
                  | 'name'
                  | 'priority'
                  | 'registerDateTime'
                  | 'salesAmountWithoutTax'
                  | 'scheduledEndDateTime'
                  | 'scheduledStartDateTime'
                  | 'sortNoInGanttChart'
                  | 'sortNoInList'
                  | 'sortNoInProjectStatus'
                  | 'totalScheduleTimeSec'
                  | 'versionNo'
                  | 'workingTimeSec'
                >;
              };
            workingMembers: Array<
              Maybe<
                { __typename?: 'WorkingMemberInfo' } & Pick<
                  WorkingMemberInfo,
                  'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >
            >;
          };
      }
  >;
};

export type IncompleteTaskMutationVariables = Exact<{
  id: Scalars['ID'];
  input: IncompleteTaskInputInput;
}>;

export type IncompleteTaskMutation = { __typename?: 'Mutation' } & {
  incompleteTask?: Maybe<
    { __typename?: 'Task' } & Pick<
      Task,
      | 'calendarLinkageCode'
      | 'completeDateTime'
      | 'description'
      | 'estimateTimeSec'
      | 'estimatedRemainingTimeSec'
      | 'id'
      | 'laborCost'
      | 'priority'
      | 'progressDeviationRate'
      | 'progressRate'
      | 'registerDateTime'
      | 'remainingTimeSec'
      | 'scheduleWorkingTimeSec'
      | 'scheduledEndDateTime'
      | 'scheduledStartDateTime'
      | 'sortNoInGanttChart'
      | 'sortNoInList'
      | 'sortNoInMyTask'
      | 'sortNoInTaskStatus'
      | 'title'
      | 'versionNo'
      | 'workingTimeSec'
    > & {
        assignees: Array<
          { __typename?: 'AssignedTaskMemberInfo' } & Pick<AssignedTaskMemberInfo, 'sortNo'> & {
              member: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
            }
        >;
        asssignee?: Maybe<
          { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >
        >;
        createdMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
        favoriteMembers: Array<
          Maybe<
            { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >
          >
        >;
        favoriteMembersInfo: Array<
          Maybe<
            { __typename?: 'FavoriteTaskMemberInfo' } & Pick<FavoriteTaskMemberInfo, 'sortNo'> & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
        project: { __typename?: 'Project' } & Pick<
          Project,
          | 'calendarLinkageCode'
          | 'color'
          | 'complete'
          | 'completeDateTime'
          | 'costBudgetWithoutTax'
          | 'description'
          | 'estimateTimeSec'
          | 'id'
          | 'key'
          | 'laborCost'
          | 'laborCostWithoutTax'
          | 'laborShare'
          | 'name'
          | 'priority'
          | 'registerDateTime'
          | 'salesAmountWithoutTax'
          | 'scheduledEndDateTime'
          | 'scheduledStartDateTime'
          | 'sortNoInGanttChart'
          | 'sortNoInList'
          | 'sortNoInProjectStatus'
          | 'totalScheduleTimeSec'
          | 'versionNo'
          | 'workingTimeSec'
        > & {
            assignees: Array<
              { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                AssignedProjectMemberInfo,
                'sortNo'
              >
            >;
            client?: Maybe<
              { __typename?: 'Client' } & Pick<
                Client,
                'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
              >
            >;
            createdMember?: Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >;
            favoriteMembers: Array<
              Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >
            >;
            favoriteMembersInfo: Array<
              Maybe<
                { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                  FavoriteProjectMemberInfo,
                  'sortNo'
                >
              >
            >;
            projectStatus: { __typename?: 'ProjectStatus' } & Pick<
              ProjectStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
            >;
            team: { __typename?: 'Team' } & Pick<
              Team,
              'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
            > & {
                organization: { __typename?: 'Organization' } & Pick<
                  Organization,
                  | 'allowConnectThirdPartyCalendar'
                  | 'contactMailAddress'
                  | 'contractPreriod'
                  | 'id'
                  | 'imageUrl'
                  | 'ipRestrictionFlg'
                  | 'name'
                  | 'requiredTwoFactorAuth'
                  | 'suspensionFlg'
                  | 'versionNo'
                > & {
                    plan: { __typename?: 'PlanProduct' } & Pick<
                      PlanProduct,
                      'code' | 'contractPreriod' | 'id'
                    >;
                  };
              };
          };
        taskStatus: { __typename?: 'TaskStatus' } & Pick<
          TaskStatus,
          'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
        > & {
            project: { __typename?: 'Project' } & Pick<
              Project,
              | 'calendarLinkageCode'
              | 'color'
              | 'complete'
              | 'completeDateTime'
              | 'costBudgetWithoutTax'
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'key'
              | 'laborCost'
              | 'laborCostWithoutTax'
              | 'laborShare'
              | 'name'
              | 'priority'
              | 'registerDateTime'
              | 'salesAmountWithoutTax'
              | 'scheduledEndDateTime'
              | 'scheduledStartDateTime'
              | 'sortNoInGanttChart'
              | 'sortNoInList'
              | 'sortNoInProjectStatus'
              | 'totalScheduleTimeSec'
              | 'versionNo'
              | 'workingTimeSec'
            >;
          };
        workingMembers: Array<
          Maybe<
            { __typename?: 'WorkingMemberInfo' } & Pick<
              WorkingMemberInfo,
              'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
            > & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
      }
  >;
};

export type InviteMemberMutationVariables = Exact<{
  input: InviteMemberWithMailAddressInputInput;
}>;

export type InviteMemberMutation = { __typename?: 'Mutation' } & {
  inviteMember?: Maybe<
    { __typename?: 'MemberInvitation' } & Pick<
      MemberInvitation,
      | 'adminRole'
      | 'allowThirdPartyCalendarConnect'
      | 'clientManagementRole'
      | 'createMemberId'
      | 'department'
      | 'employeeNumber'
      | 'id'
      | 'invitationKey'
      | 'laborCostManagementRole'
      | 'mailAddress'
      | 'memberManagementRole'
      | 'name'
      | 'organizationId'
      | 'organizationMemberViewRole'
      | 'organizationName'
      | 'organizationReportRole'
      | 'projectCreatePermissionFlg'
      | 'projectCustomAttributePermissionFlg'
      | 'projectDeletePermissionFlg'
      | 'projectUpdatePermissionFlg'
      | 'proxyEditWorkingDataRole'
      | 'salesManagementRole'
      | 'taskCreatePermissionFlg'
      | 'taskCustomAttributePermissionFlg'
      | 'taskDeletePermissionFlg'
      | 'taskUpdatePermissionFlg'
      | 'teamMemberViewRole'
      | 'teamReportRole'
      | 'versionNo'
    >
  >;
};

export type IssueAppUserIdMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: Maybe<IssueAppUserIdInputInput>;
}>;

export type IssueAppUserIdMutation = { __typename?: 'Mutation' } & {
  issueAppUserId?: Maybe<
    { __typename?: 'Contract' } & Pick<
      Contract,
      | 'billToMailAddress'
      | 'billingType'
      | 'contractPreriod'
      | 'freeTrialExpireDatetime'
      | 'id'
      | 'licenceCount'
      | 'revenuecatOriginalAppUserId'
      | 'usingLicenceCount'
      | 'versionNo'
    > & {
        freeLicenceCoupon?: Maybe<
          { __typename?: 'RangeFreeLicenceCoupon' } & Pick<
            RangeFreeLicenceCoupon,
            'freeEndLicenceCount' | 'freeStartLicenceCount' | 'id'
          >
        >;
        plan: { __typename?: 'PlanProduct' } & Pick<PlanProduct, 'code' | 'contractPreriod' | 'id'>;
      }
  >;
};

export type JoinMemberToTeamMutationVariables = Exact<{
  id: Scalars['ID'];
  input: JoinMemberToTeamInputInput;
}>;

export type JoinMemberToTeamMutation = { __typename?: 'Mutation' } & {
  joinMemberToTeam?: Maybe<
    { __typename?: 'Member' } & Pick<
      Member,
      | 'adminRole'
      | 'clientManagementRole'
      | 'department'
      | 'employeeNumber'
      | 'id'
      | 'laborCostManagementRole'
      | 'mailAddress'
      | 'memberManagementRole'
      | 'memberRegistrationDateTime'
      | 'memberStatus'
      | 'name'
      | 'organizationMemberViewRole'
      | 'organizationReportRole'
      | 'profileImageUrl'
      | 'projectCreatePermissionFlg'
      | 'projectCustomAttributePermissionFlg'
      | 'projectDeletePermissionFlg'
      | 'projectUpdatePermissionFlg'
      | 'proxyEditWorkingDataRole'
      | 'salesManagementRole'
      | 'statusMessage'
      | 'taskCreatePermissionFlg'
      | 'taskCustomAttributePermissionFlg'
      | 'taskDeletePermissionFlg'
      | 'taskUpdatePermissionFlg'
      | 'teamMemberViewRole'
      | 'teamReportRole'
      | 'versionNo'
    >
  >;
};

export type JoinToOrganizationWithGoogleAccountMutationVariables = Exact<{
  input: JoinToOrganizationWithGoogleAccountInputInput;
}>;

export type JoinToOrganizationWithGoogleAccountMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'joinToOrganizationWithGoogleAccount'
>;

export type JoinToOrganizationWithGoogleAccountByInviteLinkMutationVariables = Exact<{
  input: JoinToOrganizationWithGoogleAccountByInviteLinkInputInput;
}>;

export type JoinToOrganizationWithGoogleAccountByInviteLinkMutation = {
  __typename?: 'Mutation';
} & Pick<Mutation, 'joinToOrganizationWithGoogleAccountByInviteLink'>;

export type JoinToOrganizationWithMailAddressMutationVariables = Exact<{
  input: JoinOrganizationMailAddressInputInput;
}>;

export type JoinToOrganizationWithMailAddressMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'joinToOrganizationWithMailAddress'
>;

export type JoinToOrganizationWithMailAddressByInviteLinkMutationVariables = Exact<{
  input: JoinOrganizationMailAddressByInviteLinkInputInput;
}>;

export type JoinToOrganizationWithMailAddressByInviteLinkMutation = {
  __typename?: 'Mutation';
} & Pick<Mutation, 'joinToOrganizationWithMailAddressByInviteLink'>;

export type JoinToTeamMutationVariables = Exact<{
  input: JoinMemberToTeamInputInput;
}>;

export type JoinToTeamMutation = { __typename?: 'Mutation' } & {
  joinToTeam?: Maybe<
    { __typename?: 'TeamMemberRelation' } & Pick<
      TeamMemberRelation,
      'id' | 'openMenu' | 'sortNo' | 'versionNo'
    > & {
        team: { __typename?: 'Team' } & Pick<
          Team,
          'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
        > & {
            organization: { __typename?: 'Organization' } & Pick<
              Organization,
              | 'allowConnectThirdPartyCalendar'
              | 'contactMailAddress'
              | 'contractPreriod'
              | 'id'
              | 'imageUrl'
              | 'ipRestrictionFlg'
              | 'name'
              | 'requiredTwoFactorAuth'
              | 'suspensionFlg'
              | 'versionNo'
            > & {
                plan: { __typename?: 'PlanProduct' } & Pick<
                  PlanProduct,
                  'code' | 'contractPreriod' | 'id'
                >;
              };
          };
      }
  >;
};

export type LoginMutationVariables = Exact<{
  input?: Maybe<LoginInputInput>;
}>;

export type LoginMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'login'>;

export type LoginV2MutationVariables = Exact<{
  input: LoginInputInput;
}>;

export type LoginV2Mutation = { __typename?: 'Mutation' } & {
  loginV2?: Maybe<
    { __typename?: 'LoginResult' } & Pick<
      LoginResult,
      'loginUserToken' | 'oneTimeToken' | 'requiredTwoFactorAuth'
    >
  >;
};

export type LoginWithAppleMutationVariables = Exact<{
  input?: Maybe<LoginWithAppleAccountInputInput>;
}>;

export type LoginWithAppleMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'loginWithApple'>;

export type LoginWithAppleForWebMutationVariables = Exact<{
  input?: Maybe<LoginWithAppleAccountInputInput>;
}>;

export type LoginWithAppleForWebMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'loginWithAppleForWeb'
>;

export type LoginWithAppleForWebV2MutationVariables = Exact<{
  input: LoginWithAppleAccountInputInput;
}>;

export type LoginWithAppleForWebV2Mutation = { __typename?: 'Mutation' } & {
  loginWithAppleForWebV2?: Maybe<
    { __typename?: 'LoginResult' } & Pick<
      LoginResult,
      'loginUserToken' | 'oneTimeToken' | 'requiredTwoFactorAuth'
    >
  >;
};

export type LoginWithAppleV2MutationVariables = Exact<{
  input: LoginWithAppleAccountInputInput;
}>;

export type LoginWithAppleV2Mutation = { __typename?: 'Mutation' } & {
  loginWithAppleV2?: Maybe<
    { __typename?: 'LoginResult' } & Pick<
      LoginResult,
      'loginUserToken' | 'oneTimeToken' | 'requiredTwoFactorAuth'
    >
  >;
};

export type LoginWithGoogleMutationVariables = Exact<{
  code?: Maybe<Scalars['String']>;
}>;

export type LoginWithGoogleMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'loginWithGoogle'
>;

export type LoginWithGoogleV2MutationVariables = Exact<{
  code: Scalars['String'];
}>;

export type LoginWithGoogleV2Mutation = { __typename?: 'Mutation' } & {
  loginWithGoogleV2?: Maybe<
    { __typename?: 'LoginResult' } & Pick<
      LoginResult,
      'loginUserToken' | 'oneTimeToken' | 'requiredTwoFactorAuth'
    >
  >;
};

export type LogoutMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'logout'>;

export type MoveCalendarEventMutationVariables = Exact<{
  id: Scalars['String'];
  input: MoveCalendarEventInputInput;
}>;

export type MoveCalendarEventMutation = { __typename?: 'Mutation' } & {
  moveCalendarEvent?: Maybe<
    { __typename?: 'CalendarEvent' } & Pick<
      CalendarEvent,
      | 'calendarType'
      | 'clientId'
      | 'clientName'
      | 'endDateTime'
      | 'eventName'
      | 'id'
      | 'laborCost'
      | 'personalTeam'
      | 'projectColor'
      | 'projectId'
      | 'projectName'
      | 'startDateTime'
      | 'taskId'
      | 'taskTitle'
      | 'teamId'
      | 'teamName'
      | 'thirdPartyCalendarEventId'
      | 'versionNo'
      | 'workingTimeSec'
    > & {
        thirdPartyCalendarInfoList: Array<
          Maybe<
            { __typename?: 'ThirdPartyCalendarInfo' } & Pick<
              ThirdPartyCalendarInfo,
              'thirdPartyCalendarId'
            > & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
      }
  >;
};

export type MoveProjectMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: Maybe<MoveProjectInputInput>;
}>;

export type MoveProjectMutation = { __typename?: 'Mutation' } & {
  moveProject?: Maybe<
    { __typename?: 'Project' } & Pick<
      Project,
      | 'calendarLinkageCode'
      | 'color'
      | 'complete'
      | 'completeDateTime'
      | 'costBudgetWithoutTax'
      | 'description'
      | 'estimateTimeSec'
      | 'id'
      | 'key'
      | 'laborCost'
      | 'laborCostWithoutTax'
      | 'laborShare'
      | 'name'
      | 'priority'
      | 'registerDateTime'
      | 'salesAmountWithoutTax'
      | 'scheduledEndDateTime'
      | 'scheduledStartDateTime'
      | 'sortNoInGanttChart'
      | 'sortNoInList'
      | 'sortNoInProjectStatus'
      | 'totalScheduleTimeSec'
      | 'versionNo'
      | 'workingTimeSec'
    > & {
        assignees: Array<
          { __typename?: 'AssignedProjectMemberInfo' } & Pick<
            AssignedProjectMemberInfo,
            'sortNo'
          > & {
              member: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
            }
        >;
        client?: Maybe<
          { __typename?: 'Client' } & Pick<Client, 'code' | 'id' | 'name' | 'sortNo' | 'versionNo'>
        >;
        createdMember?: Maybe<
          { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >
        >;
        favoriteMembers: Array<
          Maybe<
            { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >
          >
        >;
        favoriteMembersInfo: Array<
          Maybe<
            { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
              FavoriteProjectMemberInfo,
              'sortNo'
            > & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
        projectStatus: { __typename?: 'ProjectStatus' } & Pick<
          ProjectStatus,
          'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
        >;
        team: { __typename?: 'Team' } & Pick<
          Team,
          'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
        > & {
            organization: { __typename?: 'Organization' } & Pick<
              Organization,
              | 'allowConnectThirdPartyCalendar'
              | 'contactMailAddress'
              | 'contractPreriod'
              | 'id'
              | 'imageUrl'
              | 'ipRestrictionFlg'
              | 'name'
              | 'requiredTwoFactorAuth'
              | 'suspensionFlg'
              | 'versionNo'
            > & {
                plan: { __typename?: 'PlanProduct' } & Pick<
                  PlanProduct,
                  'code' | 'contractPreriod' | 'id'
                >;
              };
          };
      }
  >;
};

export type MoveTaskMutationVariables = Exact<{
  id: Scalars['ID'];
  input: MoveTaskInputInput;
}>;

export type MoveTaskMutation = { __typename?: 'Mutation' } & {
  moveTask?: Maybe<
    { __typename?: 'Task' } & Pick<
      Task,
      | 'calendarLinkageCode'
      | 'completeDateTime'
      | 'description'
      | 'estimateTimeSec'
      | 'estimatedRemainingTimeSec'
      | 'id'
      | 'laborCost'
      | 'priority'
      | 'progressDeviationRate'
      | 'progressRate'
      | 'registerDateTime'
      | 'remainingTimeSec'
      | 'scheduleWorkingTimeSec'
      | 'scheduledEndDateTime'
      | 'scheduledStartDateTime'
      | 'sortNoInGanttChart'
      | 'sortNoInList'
      | 'sortNoInMyTask'
      | 'sortNoInTaskStatus'
      | 'title'
      | 'versionNo'
      | 'workingTimeSec'
    > & {
        assignees: Array<
          { __typename?: 'AssignedTaskMemberInfo' } & Pick<AssignedTaskMemberInfo, 'sortNo'> & {
              member: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
            }
        >;
        asssignee?: Maybe<
          { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >
        >;
        createdMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
        favoriteMembers: Array<
          Maybe<
            { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >
          >
        >;
        favoriteMembersInfo: Array<
          Maybe<
            { __typename?: 'FavoriteTaskMemberInfo' } & Pick<FavoriteTaskMemberInfo, 'sortNo'> & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
        project: { __typename?: 'Project' } & Pick<
          Project,
          | 'calendarLinkageCode'
          | 'color'
          | 'complete'
          | 'completeDateTime'
          | 'costBudgetWithoutTax'
          | 'description'
          | 'estimateTimeSec'
          | 'id'
          | 'key'
          | 'laborCost'
          | 'laborCostWithoutTax'
          | 'laborShare'
          | 'name'
          | 'priority'
          | 'registerDateTime'
          | 'salesAmountWithoutTax'
          | 'scheduledEndDateTime'
          | 'scheduledStartDateTime'
          | 'sortNoInGanttChart'
          | 'sortNoInList'
          | 'sortNoInProjectStatus'
          | 'totalScheduleTimeSec'
          | 'versionNo'
          | 'workingTimeSec'
        > & {
            assignees: Array<
              { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                AssignedProjectMemberInfo,
                'sortNo'
              >
            >;
            client?: Maybe<
              { __typename?: 'Client' } & Pick<
                Client,
                'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
              >
            >;
            createdMember?: Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >;
            favoriteMembers: Array<
              Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >
            >;
            favoriteMembersInfo: Array<
              Maybe<
                { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                  FavoriteProjectMemberInfo,
                  'sortNo'
                >
              >
            >;
            projectStatus: { __typename?: 'ProjectStatus' } & Pick<
              ProjectStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
            >;
            team: { __typename?: 'Team' } & Pick<
              Team,
              'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
            > & {
                organization: { __typename?: 'Organization' } & Pick<
                  Organization,
                  | 'allowConnectThirdPartyCalendar'
                  | 'contactMailAddress'
                  | 'contractPreriod'
                  | 'id'
                  | 'imageUrl'
                  | 'ipRestrictionFlg'
                  | 'name'
                  | 'requiredTwoFactorAuth'
                  | 'suspensionFlg'
                  | 'versionNo'
                > & {
                    plan: { __typename?: 'PlanProduct' } & Pick<
                      PlanProduct,
                      'code' | 'contractPreriod' | 'id'
                    >;
                  };
              };
          };
        taskStatus: { __typename?: 'TaskStatus' } & Pick<
          TaskStatus,
          'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
        > & {
            project: { __typename?: 'Project' } & Pick<
              Project,
              | 'calendarLinkageCode'
              | 'color'
              | 'complete'
              | 'completeDateTime'
              | 'costBudgetWithoutTax'
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'key'
              | 'laborCost'
              | 'laborCostWithoutTax'
              | 'laborShare'
              | 'name'
              | 'priority'
              | 'registerDateTime'
              | 'salesAmountWithoutTax'
              | 'scheduledEndDateTime'
              | 'scheduledStartDateTime'
              | 'sortNoInGanttChart'
              | 'sortNoInList'
              | 'sortNoInProjectStatus'
              | 'totalScheduleTimeSec'
              | 'versionNo'
              | 'workingTimeSec'
            >;
          };
        workingMembers: Array<
          Maybe<
            { __typename?: 'WorkingMemberInfo' } & Pick<
              WorkingMemberInfo,
              'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
            > & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
      }
  >;
};

export type MoveWorkingHistoryMutationVariables = Exact<{
  id: Scalars['String'];
  input: MoveWorkingHistoryInputInput;
}>;

export type MoveWorkingHistoryMutation = { __typename?: 'Mutation' } & {
  moveWorkingHistory?: Maybe<
    { __typename?: 'WorkingHistory' } & Pick<
      WorkingHistory,
      'end' | 'id' | 'memo' | 'start' | 'versionNo' | 'workingTimeSec'
    > & {
        calendarEvent?: Maybe<
          { __typename?: 'CalendarEvent' } & Pick<
            CalendarEvent,
            | 'calendarType'
            | 'clientId'
            | 'clientName'
            | 'endDateTime'
            | 'eventName'
            | 'id'
            | 'laborCost'
            | 'personalTeam'
            | 'projectColor'
            | 'projectId'
            | 'projectName'
            | 'startDateTime'
            | 'taskId'
            | 'taskTitle'
            | 'teamId'
            | 'teamName'
            | 'thirdPartyCalendarEventId'
            | 'versionNo'
            | 'workingTimeSec'
          > & {
              thirdPartyCalendarInfoList: Array<
                Maybe<
                  { __typename?: 'ThirdPartyCalendarInfo' } & Pick<
                    ThirdPartyCalendarInfo,
                    'thirdPartyCalendarId'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >
              >;
            }
        >;
        closingProcessInfo: { __typename?: 'ClosingProcessPart' } & Pick<
          ClosingProcessPart,
          'closed' | 'targetDateTime'
        >;
        task?: Maybe<
          { __typename?: 'Task' } & Pick<
            Task,
            | 'calendarLinkageCode'
            | 'completeDateTime'
            | 'description'
            | 'estimateTimeSec'
            | 'estimatedRemainingTimeSec'
            | 'id'
            | 'laborCost'
            | 'priority'
            | 'progressDeviationRate'
            | 'progressRate'
            | 'registerDateTime'
            | 'remainingTimeSec'
            | 'scheduleWorkingTimeSec'
            | 'scheduledEndDateTime'
            | 'scheduledStartDateTime'
            | 'sortNoInGanttChart'
            | 'sortNoInList'
            | 'sortNoInMyTask'
            | 'sortNoInTaskStatus'
            | 'title'
            | 'versionNo'
            | 'workingTimeSec'
          > & {
              assignees: Array<
                { __typename?: 'AssignedTaskMemberInfo' } & Pick<
                  AssignedTaskMemberInfo,
                  'sortNo'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >;
              asssignee?: Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >;
              createdMember: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
              favoriteMembers: Array<
                Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >
              >;
              favoriteMembersInfo: Array<
                Maybe<
                  { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                    FavoriteTaskMemberInfo,
                    'sortNo'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >
              >;
              project: { __typename?: 'Project' } & Pick<
                Project,
                | 'calendarLinkageCode'
                | 'color'
                | 'complete'
                | 'completeDateTime'
                | 'costBudgetWithoutTax'
                | 'description'
                | 'estimateTimeSec'
                | 'id'
                | 'key'
                | 'laborCost'
                | 'laborCostWithoutTax'
                | 'laborShare'
                | 'name'
                | 'priority'
                | 'registerDateTime'
                | 'salesAmountWithoutTax'
                | 'scheduledEndDateTime'
                | 'scheduledStartDateTime'
                | 'sortNoInGanttChart'
                | 'sortNoInList'
                | 'sortNoInProjectStatus'
                | 'totalScheduleTimeSec'
                | 'versionNo'
                | 'workingTimeSec'
              > & {
                  assignees: Array<
                    { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                      AssignedProjectMemberInfo,
                      'sortNo'
                    >
                  >;
                  client?: Maybe<
                    { __typename?: 'Client' } & Pick<
                      Client,
                      'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                    >
                  >;
                  createdMember?: Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >;
                  favoriteMembers: Array<
                    Maybe<
                      { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >
                    >
                  >;
                  favoriteMembersInfo: Array<
                    Maybe<
                      { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                        FavoriteProjectMemberInfo,
                        'sortNo'
                      >
                    >
                  >;
                  projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                    ProjectStatus,
                    'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                  >;
                  team: { __typename?: 'Team' } & Pick<
                    Team,
                    'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                  > & {
                      organization: { __typename?: 'Organization' } & Pick<
                        Organization,
                        | 'allowConnectThirdPartyCalendar'
                        | 'contactMailAddress'
                        | 'contractPreriod'
                        | 'id'
                        | 'imageUrl'
                        | 'ipRestrictionFlg'
                        | 'name'
                        | 'requiredTwoFactorAuth'
                        | 'suspensionFlg'
                        | 'versionNo'
                      > & {
                          plan: { __typename?: 'PlanProduct' } & Pick<
                            PlanProduct,
                            'code' | 'contractPreriod' | 'id'
                          >;
                        };
                    };
                };
              taskStatus: { __typename?: 'TaskStatus' } & Pick<
                TaskStatus,
                'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
              > & {
                  project: { __typename?: 'Project' } & Pick<
                    Project,
                    | 'calendarLinkageCode'
                    | 'color'
                    | 'complete'
                    | 'completeDateTime'
                    | 'costBudgetWithoutTax'
                    | 'description'
                    | 'estimateTimeSec'
                    | 'id'
                    | 'key'
                    | 'laborCost'
                    | 'laborCostWithoutTax'
                    | 'laborShare'
                    | 'name'
                    | 'priority'
                    | 'registerDateTime'
                    | 'salesAmountWithoutTax'
                    | 'scheduledEndDateTime'
                    | 'scheduledStartDateTime'
                    | 'sortNoInGanttChart'
                    | 'sortNoInList'
                    | 'sortNoInProjectStatus'
                    | 'totalScheduleTimeSec'
                    | 'versionNo'
                    | 'workingTimeSec'
                  >;
                };
              workingMembers: Array<
                Maybe<
                  { __typename?: 'WorkingMemberInfo' } & Pick<
                    WorkingMemberInfo,
                    'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >
              >;
            }
        >;
        workingMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
      }
  >;
};

export type MoveWorkingScheduleMutationVariables = Exact<{
  id: Scalars['String'];
  input: MoveWorkingScheduleInputInput;
}>;

export type MoveWorkingScheduleMutation = { __typename?: 'Mutation' } & {
  moveWorkingSchedule?: Maybe<
    { __typename?: 'WorkingSchedule' } & Pick<
      WorkingSchedule,
      'end' | 'id' | 'memo' | 'start' | 'versionNo' | 'workingTimeSec'
    > & {
        calendarEvent?: Maybe<
          { __typename?: 'CalendarEvent' } & Pick<
            CalendarEvent,
            | 'calendarType'
            | 'clientId'
            | 'clientName'
            | 'endDateTime'
            | 'eventName'
            | 'id'
            | 'laborCost'
            | 'personalTeam'
            | 'projectColor'
            | 'projectId'
            | 'projectName'
            | 'startDateTime'
            | 'taskId'
            | 'taskTitle'
            | 'teamId'
            | 'teamName'
            | 'thirdPartyCalendarEventId'
            | 'versionNo'
            | 'workingTimeSec'
          > & {
              thirdPartyCalendarInfoList: Array<
                Maybe<
                  { __typename?: 'ThirdPartyCalendarInfo' } & Pick<
                    ThirdPartyCalendarInfo,
                    'thirdPartyCalendarId'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >
              >;
            }
        >;
        task?: Maybe<
          { __typename?: 'Task' } & Pick<
            Task,
            | 'calendarLinkageCode'
            | 'completeDateTime'
            | 'description'
            | 'estimateTimeSec'
            | 'estimatedRemainingTimeSec'
            | 'id'
            | 'laborCost'
            | 'priority'
            | 'progressDeviationRate'
            | 'progressRate'
            | 'registerDateTime'
            | 'remainingTimeSec'
            | 'scheduleWorkingTimeSec'
            | 'scheduledEndDateTime'
            | 'scheduledStartDateTime'
            | 'sortNoInGanttChart'
            | 'sortNoInList'
            | 'sortNoInMyTask'
            | 'sortNoInTaskStatus'
            | 'title'
            | 'versionNo'
            | 'workingTimeSec'
          > & {
              assignees: Array<
                { __typename?: 'AssignedTaskMemberInfo' } & Pick<
                  AssignedTaskMemberInfo,
                  'sortNo'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >;
              asssignee?: Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >;
              createdMember: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
              favoriteMembers: Array<
                Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >
              >;
              favoriteMembersInfo: Array<
                Maybe<
                  { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                    FavoriteTaskMemberInfo,
                    'sortNo'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >
              >;
              project: { __typename?: 'Project' } & Pick<
                Project,
                | 'calendarLinkageCode'
                | 'color'
                | 'complete'
                | 'completeDateTime'
                | 'costBudgetWithoutTax'
                | 'description'
                | 'estimateTimeSec'
                | 'id'
                | 'key'
                | 'laborCost'
                | 'laborCostWithoutTax'
                | 'laborShare'
                | 'name'
                | 'priority'
                | 'registerDateTime'
                | 'salesAmountWithoutTax'
                | 'scheduledEndDateTime'
                | 'scheduledStartDateTime'
                | 'sortNoInGanttChart'
                | 'sortNoInList'
                | 'sortNoInProjectStatus'
                | 'totalScheduleTimeSec'
                | 'versionNo'
                | 'workingTimeSec'
              > & {
                  assignees: Array<
                    { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                      AssignedProjectMemberInfo,
                      'sortNo'
                    >
                  >;
                  client?: Maybe<
                    { __typename?: 'Client' } & Pick<
                      Client,
                      'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                    >
                  >;
                  createdMember?: Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >;
                  favoriteMembers: Array<
                    Maybe<
                      { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >
                    >
                  >;
                  favoriteMembersInfo: Array<
                    Maybe<
                      { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                        FavoriteProjectMemberInfo,
                        'sortNo'
                      >
                    >
                  >;
                  projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                    ProjectStatus,
                    'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                  >;
                  team: { __typename?: 'Team' } & Pick<
                    Team,
                    'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                  > & {
                      organization: { __typename?: 'Organization' } & Pick<
                        Organization,
                        | 'allowConnectThirdPartyCalendar'
                        | 'contactMailAddress'
                        | 'contractPreriod'
                        | 'id'
                        | 'imageUrl'
                        | 'ipRestrictionFlg'
                        | 'name'
                        | 'requiredTwoFactorAuth'
                        | 'suspensionFlg'
                        | 'versionNo'
                      > & {
                          plan: { __typename?: 'PlanProduct' } & Pick<
                            PlanProduct,
                            'code' | 'contractPreriod' | 'id'
                          >;
                        };
                    };
                };
              taskStatus: { __typename?: 'TaskStatus' } & Pick<
                TaskStatus,
                'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
              > & {
                  project: { __typename?: 'Project' } & Pick<
                    Project,
                    | 'calendarLinkageCode'
                    | 'color'
                    | 'complete'
                    | 'completeDateTime'
                    | 'costBudgetWithoutTax'
                    | 'description'
                    | 'estimateTimeSec'
                    | 'id'
                    | 'key'
                    | 'laborCost'
                    | 'laborCostWithoutTax'
                    | 'laborShare'
                    | 'name'
                    | 'priority'
                    | 'registerDateTime'
                    | 'salesAmountWithoutTax'
                    | 'scheduledEndDateTime'
                    | 'scheduledStartDateTime'
                    | 'sortNoInGanttChart'
                    | 'sortNoInList'
                    | 'sortNoInProjectStatus'
                    | 'totalScheduleTimeSec'
                    | 'versionNo'
                    | 'workingTimeSec'
                  >;
                };
              workingMembers: Array<
                Maybe<
                  { __typename?: 'WorkingMemberInfo' } & Pick<
                    WorkingMemberInfo,
                    'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >
              >;
            }
        >;
        workingMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
      }
  >;
};

export type OpenAllTeamMenuMutationVariables = Exact<{ [key: string]: never }>;

export type OpenAllTeamMenuMutation = { __typename?: 'Mutation' } & {
  openAllTeamMenu?: Maybe<
    Array<
      Maybe<
        { __typename?: 'TeamMemberRelation' } & Pick<
          TeamMemberRelation,
          'id' | 'openMenu' | 'sortNo' | 'versionNo'
        > & {
            team: { __typename?: 'Team' } & Pick<
              Team,
              'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
            > & {
                organization: { __typename?: 'Organization' } & Pick<
                  Organization,
                  | 'allowConnectThirdPartyCalendar'
                  | 'contactMailAddress'
                  | 'contractPreriod'
                  | 'id'
                  | 'imageUrl'
                  | 'ipRestrictionFlg'
                  | 'name'
                  | 'requiredTwoFactorAuth'
                  | 'suspensionFlg'
                  | 'versionNo'
                > & {
                    plan: { __typename?: 'PlanProduct' } & Pick<
                      PlanProduct,
                      'code' | 'contractPreriod' | 'id'
                    >;
                  };
              };
          }
      >
    >
  >;
};

export type ParseAppleAuthCodeMutationVariables = Exact<{
  input: ParseAppleAccountInputInput;
}>;

export type ParseAppleAuthCodeMutation = { __typename?: 'Mutation' } & {
  parseAppleAuthCode?: Maybe<
    { __typename?: 'AppleProfile' } & Pick<
      AppleProfile,
      'appleUserId' | 'mailAddress' | 'name' | 'registerd' | 'token'
    >
  >;
};

export type ParseGoogleAuthCodeMutationVariables = Exact<{
  code: Scalars['String'];
}>;

export type ParseGoogleAuthCodeMutation = { __typename?: 'Mutation' } & {
  parseGoogleAuthCode?: Maybe<
    { __typename?: 'GoogleProfile' } & Pick<
      GoogleProfile,
      'domain' | 'mailAddress' | 'name' | 'profileImageUrl' | 'registerd' | 'token'
    >
  >;
};

export type ParseGoogleAuthCodeWithInviteKeyMutationVariables = Exact<{
  code: Scalars['String'];
  key: Scalars['String'];
}>;

export type ParseGoogleAuthCodeWithInviteKeyMutation = { __typename?: 'Mutation' } & {
  parseGoogleAuthCodeWithInviteKey?: Maybe<
    { __typename?: 'GoogleProfile' } & Pick<
      GoogleProfile,
      'domain' | 'mailAddress' | 'name' | 'profileImageUrl' | 'registerd' | 'token'
    >
  >;
};

export type ParseGoogleAuthCodeWithInviteLinkMutationVariables = Exact<{
  code: Scalars['String'];
}>;

export type ParseGoogleAuthCodeWithInviteLinkMutation = { __typename?: 'Mutation' } & {
  parseGoogleAuthCodeWithInviteLink?: Maybe<
    { __typename?: 'GoogleProfile' } & Pick<
      GoogleProfile,
      'domain' | 'mailAddress' | 'name' | 'profileImageUrl' | 'registerd' | 'token'
    >
  >;
};

export type ProxyLoginMutationVariables = Exact<{
  input?: Maybe<ProxyLoginInputInput>;
}>;

export type ProxyLoginMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'proxyLogin'>;

export type ReadAllAnnouncementMutationVariables = Exact<{ [key: string]: never }>;

export type ReadAllAnnouncementMutation = { __typename?: 'Mutation' } & {
  readAllAnnouncement?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Announcement' } & Pick<
          Announcement,
          'announceDateTime' | 'id' | 'readed' | 'title' | 'url'
        >
      >
    >
  >;
};

export type ReadAnnouncementMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ReadAnnouncementMutation = { __typename?: 'Mutation' } & {
  readAnnouncement?: Maybe<
    { __typename?: 'Announcement' } & Pick<
      Announcement,
      'announceDateTime' | 'id' | 'readed' | 'title' | 'url'
    >
  >;
};

export type RefleshApiKeyMutationVariables = Exact<{ [key: string]: never }>;

export type RefleshApiKeyMutation = { __typename?: 'Mutation' } & {
  refleshApiKey?: Maybe<{ __typename?: 'ApiKey' } & Pick<ApiKey, 'token'>>;
};

export type RefleshTokenMutationVariables = Exact<{ [key: string]: never }>;

export type RefleshTokenMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'refleshToken'>;

export type RegenerateRecoveryCodesMutationVariables = Exact<{ [key: string]: never }>;

export type RegenerateRecoveryCodesMutation = { __typename?: 'Mutation' } & {
  regenerateRecoveryCodes?: Maybe<
    { __typename?: 'TwoFactorAuthSetting' } & Pick<
      TwoFactorAuthSetting,
      'enabled' | 'recoveryCodes'
    >
  >;
};

export type RegisterClientMutationVariables = Exact<{
  input?: Maybe<RegisterClientInputInput>;
  organizationId: Scalars['String'];
}>;

export type RegisterClientMutation = { __typename?: 'Mutation' } & {
  registerClient?: Maybe<
    { __typename?: 'Client' } & Pick<Client, 'code' | 'id' | 'name' | 'sortNo' | 'versionNo'>
  >;
};

export type RegisterCreditCardMutationVariables = Exact<{
  contractId: Scalars['ID'];
  input?: Maybe<RegisterCreditCardInputInput>;
}>;

export type RegisterCreditCardMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'registerCreditCard'
>;

export type RegisterCsvReportItemDefinitionMutationVariables = Exact<{
  input: RegisterCsvReportItemDefinitionInputInput;
}>;

export type RegisterCsvReportItemDefinitionMutation = { __typename?: 'Mutation' } & {
  registerCsvReportItemDefinition?: Maybe<
    { __typename?: 'CsvReportItemDefinition' } & Pick<
      CsvReportItemDefinition,
      'id' | 'name' | 'versionNo'
    > & {
        items: Array<
          Maybe<
            { __typename?: 'CsvReportItemDefinitionDetail' } & Pick<
              CsvReportItemDefinitionDetail,
              'id' | 'reportItemCode' | 'reportItemName' | 'sortNo'
            >
          >
        >;
      }
  >;
};

export type RegisterGoogleCalendarEventMutationVariables = Exact<{
  input: RegisterGoogleCalendarEventInputInput;
}>;

export type RegisterGoogleCalendarEventMutation = { __typename?: 'Mutation' } & {
  registerGoogleCalendarEvent?: Maybe<
    { __typename?: 'GoogleCalendarEvent' } & Pick<
      GoogleCalendarEvent,
      | 'allDay'
      | 'backgroundColor'
      | 'calendarId'
      | 'colorId'
      | 'dateWhenAllDay'
      | 'description'
      | 'endDateTime'
      | 'foregroundColor'
      | 'id'
      | 'location'
      | 'readonly'
      | 'startDateTime'
      | 'status'
      | 'targetMemberId'
      | 'title'
      | 'visivility'
    > & {
        attendees?: Maybe<
          Array<
            Maybe<
              { __typename?: 'Attendee' } & Pick<
                Attendee,
                'mailAddress' | 'name' | 'responseStatus' | 'self'
              >
            >
          >
        >;
        organizer?: Maybe<
          { __typename?: 'NameAndMailAddress' } & Pick<NameAndMailAddress, 'mailAddress' | 'name'>
        >;
      }
  >;
};

export type RegisterPushNotificationDeviceMutationVariables = Exact<{
  input: RegisterPushNotificationDeviceInputInput;
}>;

export type RegisterPushNotificationDeviceMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'registerPushNotificationDevice'
>;

export type RelateProjectTemplateTaskTagsMutationVariables = Exact<{
  input?: Maybe<RelateTaskTagsInputInput>;
  projectTemplateTaskId: Scalars['String'];
}>;

export type RelateProjectTemplateTaskTagsMutation = { __typename?: 'Mutation' } & {
  relateProjectTemplateTaskTags?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Tag' } & Pick<
          Tag,
          'id' | 'name' | 'note' | 'relatedTaskCount' | 'sortNo' | 'versionNo'
        > & {
            organization: { __typename?: 'Organization' } & Pick<
              Organization,
              | 'allowConnectThirdPartyCalendar'
              | 'contactMailAddress'
              | 'contractPreriod'
              | 'id'
              | 'imageUrl'
              | 'ipRestrictionFlg'
              | 'name'
              | 'requiredTwoFactorAuth'
              | 'suspensionFlg'
              | 'versionNo'
            > & {
                plan: { __typename?: 'PlanProduct' } & Pick<
                  PlanProduct,
                  'code' | 'contractPreriod' | 'id'
                >;
              };
          }
      >
    >
  >;
};

export type RelateTaskTagsMutationVariables = Exact<{
  input?: Maybe<RelateTaskTagsInputInput>;
  taskId: Scalars['String'];
}>;

export type RelateTaskTagsMutation = { __typename?: 'Mutation' } & {
  relateTaskTags?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Tag' } & Pick<
          Tag,
          'id' | 'name' | 'note' | 'relatedTaskCount' | 'sortNo' | 'versionNo'
        > & {
            organization: { __typename?: 'Organization' } & Pick<
              Organization,
              | 'allowConnectThirdPartyCalendar'
              | 'contactMailAddress'
              | 'contractPreriod'
              | 'id'
              | 'imageUrl'
              | 'ipRestrictionFlg'
              | 'name'
              | 'requiredTwoFactorAuth'
              | 'suspensionFlg'
              | 'versionNo'
            > & {
                plan: { __typename?: 'PlanProduct' } & Pick<
                  PlanProduct,
                  'code' | 'contractPreriod' | 'id'
                >;
              };
          }
      >
    >
  >;
};

export type RemoveFromTeamMutationVariables = Exact<{
  input: RemoveMemberToTeamInputInput;
}>;

export type RemoveFromTeamMutation = { __typename?: 'Mutation' } & {
  removeFromTeam?: Maybe<
    { __typename?: 'TeamMemberRelation' } & Pick<
      TeamMemberRelation,
      'id' | 'openMenu' | 'sortNo' | 'versionNo'
    > & {
        team: { __typename?: 'Team' } & Pick<
          Team,
          'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
        > & {
            organization: { __typename?: 'Organization' } & Pick<
              Organization,
              | 'allowConnectThirdPartyCalendar'
              | 'contactMailAddress'
              | 'contractPreriod'
              | 'id'
              | 'imageUrl'
              | 'ipRestrictionFlg'
              | 'name'
              | 'requiredTwoFactorAuth'
              | 'suspensionFlg'
              | 'versionNo'
            > & {
                plan: { __typename?: 'PlanProduct' } & Pick<
                  PlanProduct,
                  'code' | 'contractPreriod' | 'id'
                >;
              };
          };
      }
  >;
};

export type RemoveMemberFromTeamMutationVariables = Exact<{
  id: Scalars['ID'];
  input: RemoveMemberToTeamInputInput;
}>;

export type RemoveMemberFromTeamMutation = { __typename?: 'Mutation' } & {
  removeMemberFromTeam?: Maybe<
    { __typename?: 'Member' } & Pick<
      Member,
      | 'adminRole'
      | 'clientManagementRole'
      | 'department'
      | 'employeeNumber'
      | 'id'
      | 'laborCostManagementRole'
      | 'mailAddress'
      | 'memberManagementRole'
      | 'memberRegistrationDateTime'
      | 'memberStatus'
      | 'name'
      | 'organizationMemberViewRole'
      | 'organizationReportRole'
      | 'profileImageUrl'
      | 'projectCreatePermissionFlg'
      | 'projectCustomAttributePermissionFlg'
      | 'projectDeletePermissionFlg'
      | 'projectUpdatePermissionFlg'
      | 'proxyEditWorkingDataRole'
      | 'salesManagementRole'
      | 'statusMessage'
      | 'taskCreatePermissionFlg'
      | 'taskCustomAttributePermissionFlg'
      | 'taskDeletePermissionFlg'
      | 'taskUpdatePermissionFlg'
      | 'teamMemberViewRole'
      | 'teamReportRole'
      | 'versionNo'
    >
  >;
};

export type RequestExportOrganizationClientExcelMutationVariables = Exact<{
  input: RequestExportClientExcelInputInput;
}>;

export type RequestExportOrganizationClientExcelMutation = { __typename?: 'Mutation' } & {
  requestExportOrganizationClientExcel?: Maybe<
    { __typename?: 'ExportClientExcel' } & Pick<
      ExportClientExcel,
      'id' | 'requestDateTime' | 'status' | 'timeZoneOffset' | 'url' | 'versionNo'
    > & {
        requestMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
      }
  >;
};

export type RequestExportOrganizationMemberExcelMutationVariables = Exact<{
  input: RequestExportMemberExcelInputInput;
}>;

export type RequestExportOrganizationMemberExcelMutation = { __typename?: 'Mutation' } & {
  requestExportOrganizationMemberExcel?: Maybe<
    { __typename?: 'ExportMemberExcel' } & Pick<
      ExportMemberExcel,
      'id' | 'requestDateTime' | 'status' | 'timeZoneOffset' | 'url' | 'versionNo'
    > & {
        requestMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
      }
  >;
};

export type RequestExportOrganizationProjectExcelMutationVariables = Exact<{
  input: RequestExportProjectExcelInputInput;
}>;

export type RequestExportOrganizationProjectExcelMutation = { __typename?: 'Mutation' } & {
  requestExportOrganizationProjectExcel?: Maybe<
    { __typename?: 'ExportProjectExcel' } & Pick<
      ExportProjectExcel,
      | 'id'
      | 'includeCompleted'
      | 'requestDateTime'
      | 'status'
      | 'teamid'
      | 'timeZoneOffset'
      | 'url'
      | 'versionNo'
    > & {
        requestMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
      }
  >;
};

export type RequestExportOrganizationTaskExcelMutationVariables = Exact<{
  input: RequestExportTaskExcelInputInput;
}>;

export type RequestExportOrganizationTaskExcelMutation = { __typename?: 'Mutation' } & {
  requestExportOrganizationTaskExcel?: Maybe<
    { __typename?: 'ExportTaskExcel' } & Pick<
      ExportTaskExcel,
      | 'id'
      | 'includeCompleted'
      | 'requestDateTime'
      | 'status'
      | 'teamid'
      | 'timeZoneOffset'
      | 'url'
      | 'versionNo'
    > & {
        requestMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
      }
  >;
};

export type RequestExportTeamProjectExcelMutationVariables = Exact<{
  input: RequestExportProjectExcelInputInput;
}>;

export type RequestExportTeamProjectExcelMutation = { __typename?: 'Mutation' } & {
  requestExportTeamProjectExcel?: Maybe<
    { __typename?: 'ExportProjectExcel' } & Pick<
      ExportProjectExcel,
      | 'id'
      | 'includeCompleted'
      | 'requestDateTime'
      | 'status'
      | 'teamid'
      | 'timeZoneOffset'
      | 'url'
      | 'versionNo'
    > & {
        requestMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
      }
  >;
};

export type RequestExportTeamTaskExcelMutationVariables = Exact<{
  input: RequestExportTaskExcelInputInput;
}>;

export type RequestExportTeamTaskExcelMutation = { __typename?: 'Mutation' } & {
  requestExportTeamTaskExcel?: Maybe<
    { __typename?: 'ExportTaskExcel' } & Pick<
      ExportTaskExcel,
      | 'id'
      | 'includeCompleted'
      | 'requestDateTime'
      | 'status'
      | 'teamid'
      | 'timeZoneOffset'
      | 'url'
      | 'versionNo'
    > & {
        requestMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
      }
  >;
};

export type RequestMemberRegisterMutationVariables = Exact<{
  input: MemberRegisterRequestInputInput;
}>;

export type RequestMemberRegisterMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'requestMemberRegister'
>;

export type RequestMemberRegisterWithInvitationLinkMutationVariables = Exact<{
  input: MemberRegisterRequestWithInvitationLInkInputInput;
}>;

export type RequestMemberRegisterWithInvitationLinkMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'requestMemberRegisterWithInvitationLink'
>;

export type RequestMyCsvReportMutationVariables = Exact<{
  input: RequestMyCsvReportInputInput;
}>;

export type RequestMyCsvReportMutation = { __typename?: 'Mutation' } & {
  requestMyCsvReport?: Maybe<
    { __typename?: 'MyCsvReport' } & Pick<
      MyCsvReport,
      | 'end'
      | 'id'
      | 'includeWorkingData'
      | 'reportType'
      | 'requestDateTime'
      | 'sjisFileUrl'
      | 'start'
      | 'status'
      | 'timeZoneOffset'
      | 'utf8FileUrl'
      | 'versionNo'
    > & {
        csvReportItemDefinition?: Maybe<
          { __typename?: 'CsvReportItemDefinition' } & Pick<
            CsvReportItemDefinition,
            'id' | 'name' | 'versionNo'
          > & {
              items: Array<
                Maybe<
                  { __typename?: 'CsvReportItemDefinitionDetail' } & Pick<
                    CsvReportItemDefinitionDetail,
                    'id' | 'reportItemCode' | 'reportItemName' | 'sortNo'
                  >
                >
              >;
            }
        >;
      }
  >;
};

export type RequestMyReportMutationVariables = Exact<{
  input: RequestMyReportInputInput;
}>;

export type RequestMyReportMutation = { __typename?: 'Mutation' } & {
  requestMyReport?: Maybe<
    { __typename?: 'MyReport' } & Pick<
      MyReport,
      | 'end'
      | 'id'
      | 'includeWorkingData'
      | 'reportType'
      | 'requestDateTime'
      | 'start'
      | 'status'
      | 'timeZoneOffset'
      | 'url'
      | 'versionNo'
    >
  >;
};

export type RequestOrganizationCsvReportMutationVariables = Exact<{
  input: RequestOrganizationCsvReportInputInput;
}>;

export type RequestOrganizationCsvReportMutation = { __typename?: 'Mutation' } & {
  requestOrganizationCsvReport?: Maybe<
    { __typename?: 'OrganizationCsvReport' } & Pick<
      OrganizationCsvReport,
      | 'end'
      | 'id'
      | 'includeWorkingData'
      | 'reportType'
      | 'requestDateTime'
      | 'sjisFileUrl'
      | 'start'
      | 'status'
      | 'timeZoneOffset'
      | 'utf8FileUrl'
      | 'versionNo'
    > & {
        csvReportItemDefinition?: Maybe<
          { __typename?: 'CsvReportItemDefinition' } & Pick<
            CsvReportItemDefinition,
            'id' | 'name' | 'versionNo'
          > & {
              items: Array<
                Maybe<
                  { __typename?: 'CsvReportItemDefinitionDetail' } & Pick<
                    CsvReportItemDefinitionDetail,
                    'id' | 'reportItemCode' | 'reportItemName' | 'sortNo'
                  >
                >
              >;
            }
        >;
        requestMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
      }
  >;
};

export type RequestOrganizationReportMutationVariables = Exact<{
  input: RequestOrganizationReportInputInput;
}>;

export type RequestOrganizationReportMutation = { __typename?: 'Mutation' } & {
  requestOrganizationReport?: Maybe<
    { __typename?: 'OrganizationReport' } & Pick<
      OrganizationReport,
      | 'end'
      | 'id'
      | 'includeWorkingData'
      | 'reportType'
      | 'requestDateTime'
      | 'start'
      | 'status'
      | 'timeZoneOffset'
      | 'url'
      | 'versionNo'
    > & {
        requestMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
      }
  >;
};

export type RequestPasswordResetMutationVariables = Exact<{
  input: RequestPasswordResetInputInput;
}>;

export type RequestPasswordResetMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'requestPasswordReset'
>;

export type RequestTeamCsvReportMutationVariables = Exact<{
  input: RequestTeamCsvReportInputInput;
}>;

export type RequestTeamCsvReportMutation = { __typename?: 'Mutation' } & {
  requestTeamCsvReport?: Maybe<
    { __typename?: 'TeamCsvReport' } & Pick<
      TeamCsvReport,
      | 'end'
      | 'id'
      | 'includeWorkingData'
      | 'reportType'
      | 'requestDateTime'
      | 'sjisFileUrl'
      | 'start'
      | 'status'
      | 'timeZoneOffset'
      | 'utf8FileUrl'
      | 'versionNo'
    > & {
        csvReportItemDefinition?: Maybe<
          { __typename?: 'CsvReportItemDefinition' } & Pick<
            CsvReportItemDefinition,
            'id' | 'name' | 'versionNo'
          > & {
              items: Array<
                Maybe<
                  { __typename?: 'CsvReportItemDefinitionDetail' } & Pick<
                    CsvReportItemDefinitionDetail,
                    'id' | 'reportItemCode' | 'reportItemName' | 'sortNo'
                  >
                >
              >;
            }
        >;
        requestMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
        team: { __typename?: 'Team' } & Pick<
          Team,
          'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
        > & {
            organization: { __typename?: 'Organization' } & Pick<
              Organization,
              | 'allowConnectThirdPartyCalendar'
              | 'contactMailAddress'
              | 'contractPreriod'
              | 'id'
              | 'imageUrl'
              | 'ipRestrictionFlg'
              | 'name'
              | 'requiredTwoFactorAuth'
              | 'suspensionFlg'
              | 'versionNo'
            > & {
                plan: { __typename?: 'PlanProduct' } & Pick<
                  PlanProduct,
                  'code' | 'contractPreriod' | 'id'
                >;
              };
          };
      }
  >;
};

export type RequestTeamReportMutationVariables = Exact<{
  input: RequestTeamReportInputInput;
}>;

export type RequestTeamReportMutation = { __typename?: 'Mutation' } & {
  requestTeamReport?: Maybe<
    { __typename?: 'TeamReport' } & Pick<
      TeamReport,
      | 'end'
      | 'id'
      | 'includeWorkingData'
      | 'reportType'
      | 'requestDateTime'
      | 'start'
      | 'status'
      | 'timeZoneOffset'
      | 'url'
      | 'versionNo'
    > & {
        requestMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
        team: { __typename?: 'Team' } & Pick<
          Team,
          'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
        > & {
            organization: { __typename?: 'Organization' } & Pick<
              Organization,
              | 'allowConnectThirdPartyCalendar'
              | 'contactMailAddress'
              | 'contractPreriod'
              | 'id'
              | 'imageUrl'
              | 'ipRestrictionFlg'
              | 'name'
              | 'requiredTwoFactorAuth'
              | 'suspensionFlg'
              | 'versionNo'
            > & {
                plan: { __typename?: 'PlanProduct' } & Pick<
                  PlanProduct,
                  'code' | 'contractPreriod' | 'id'
                >;
              };
          };
      }
  >;
};

export type ResendInvitationMutationVariables = Exact<{
  key: Scalars['String'];
}>;

export type ResendInvitationMutation = { __typename?: 'Mutation' } & {
  resendInvitation?: Maybe<
    { __typename?: 'MemberInvitation' } & Pick<
      MemberInvitation,
      | 'adminRole'
      | 'allowThirdPartyCalendarConnect'
      | 'clientManagementRole'
      | 'createMemberId'
      | 'department'
      | 'employeeNumber'
      | 'id'
      | 'invitationKey'
      | 'laborCostManagementRole'
      | 'mailAddress'
      | 'memberManagementRole'
      | 'name'
      | 'organizationId'
      | 'organizationMemberViewRole'
      | 'organizationName'
      | 'organizationReportRole'
      | 'projectCreatePermissionFlg'
      | 'projectCustomAttributePermissionFlg'
      | 'projectDeletePermissionFlg'
      | 'projectUpdatePermissionFlg'
      | 'proxyEditWorkingDataRole'
      | 'salesManagementRole'
      | 'taskCreatePermissionFlg'
      | 'taskCustomAttributePermissionFlg'
      | 'taskDeletePermissionFlg'
      | 'taskUpdatePermissionFlg'
      | 'teamMemberViewRole'
      | 'teamReportRole'
      | 'versionNo'
    >
  >;
};

export type ResetPasswordMutationVariables = Exact<{
  input?: Maybe<ResetPasswordInputInput>;
}>;

export type ResetPasswordMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'resetPassword'>;

export type SetUpTwoFactorAuthMutationVariables = Exact<{ [key: string]: never }>;

export type SetUpTwoFactorAuthMutation = { __typename?: 'Mutation' } & {
  setUpTwoFactorAuth?: Maybe<
    { __typename?: 'TwoFactorAuthSetUpInfo' } & Pick<
      TwoFactorAuthSetUpInfo,
      'qrImageBase64' | 'secret' | 'verifyToken'
    >
  >;
};

export type SignupWithAppleAccountMutationVariables = Exact<{
  input: SignupWithAppleAccountInputInput;
}>;

export type SignupWithAppleAccountMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'signupWithAppleAccount'
>;

export type SignupWithGoogleAccountMutationVariables = Exact<{
  input: SignupWithGoogleAccountInputInput;
}>;

export type SignupWithGoogleAccountMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'signupWithGoogleAccount'
>;

export type SignupWithMailAddressMutationVariables = Exact<{
  input: SignupWithMailAddressInputInput;
}>;

export type SignupWithMailAddressMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'signupWithMailAddress'
>;

export type StartTaskMutationVariables = Exact<{
  id: Scalars['ID'];
  input: StartTaskInputInput;
}>;

export type StartTaskMutation = { __typename?: 'Mutation' } & {
  startTask?: Maybe<
    { __typename?: 'StartTaskResult' } & {
      startTask: { __typename?: 'Task' } & Pick<
        Task,
        | 'calendarLinkageCode'
        | 'completeDateTime'
        | 'description'
        | 'estimateTimeSec'
        | 'estimatedRemainingTimeSec'
        | 'id'
        | 'laborCost'
        | 'priority'
        | 'progressDeviationRate'
        | 'progressRate'
        | 'registerDateTime'
        | 'remainingTimeSec'
        | 'scheduleWorkingTimeSec'
        | 'scheduledEndDateTime'
        | 'scheduledStartDateTime'
        | 'sortNoInGanttChart'
        | 'sortNoInList'
        | 'sortNoInMyTask'
        | 'sortNoInTaskStatus'
        | 'title'
        | 'versionNo'
        | 'workingTimeSec'
      > & {
          assignees: Array<
            { __typename?: 'AssignedTaskMemberInfo' } & Pick<AssignedTaskMemberInfo, 'sortNo'> & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >;
          asssignee?: Maybe<
            { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >
          >;
          createdMember: { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >;
          favoriteMembers: Array<
            Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >
          >;
          favoriteMembersInfo: Array<
            Maybe<
              { __typename?: 'FavoriteTaskMemberInfo' } & Pick<FavoriteTaskMemberInfo, 'sortNo'> & {
                  member: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                }
            >
          >;
          project: { __typename?: 'Project' } & Pick<
            Project,
            | 'calendarLinkageCode'
            | 'color'
            | 'complete'
            | 'completeDateTime'
            | 'costBudgetWithoutTax'
            | 'description'
            | 'estimateTimeSec'
            | 'id'
            | 'key'
            | 'laborCost'
            | 'laborCostWithoutTax'
            | 'laborShare'
            | 'name'
            | 'priority'
            | 'registerDateTime'
            | 'salesAmountWithoutTax'
            | 'scheduledEndDateTime'
            | 'scheduledStartDateTime'
            | 'sortNoInGanttChart'
            | 'sortNoInList'
            | 'sortNoInProjectStatus'
            | 'totalScheduleTimeSec'
            | 'versionNo'
            | 'workingTimeSec'
          > & {
              assignees: Array<
                { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                  AssignedProjectMemberInfo,
                  'sortNo'
                >
              >;
              client?: Maybe<
                { __typename?: 'Client' } & Pick<
                  Client,
                  'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                >
              >;
              createdMember?: Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >;
              favoriteMembers: Array<
                Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >
              >;
              favoriteMembersInfo: Array<
                Maybe<
                  { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                    FavoriteProjectMemberInfo,
                    'sortNo'
                  >
                >
              >;
              projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                ProjectStatus,
                'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
              >;
              team: { __typename?: 'Team' } & Pick<
                Team,
                'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
              > & {
                  organization: { __typename?: 'Organization' } & Pick<
                    Organization,
                    | 'allowConnectThirdPartyCalendar'
                    | 'contactMailAddress'
                    | 'contractPreriod'
                    | 'id'
                    | 'imageUrl'
                    | 'ipRestrictionFlg'
                    | 'name'
                    | 'requiredTwoFactorAuth'
                    | 'suspensionFlg'
                    | 'versionNo'
                  > & {
                      plan: { __typename?: 'PlanProduct' } & Pick<
                        PlanProduct,
                        'code' | 'contractPreriod' | 'id'
                      >;
                    };
                };
            };
          taskStatus: { __typename?: 'TaskStatus' } & Pick<
            TaskStatus,
            'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
          > & {
              project: { __typename?: 'Project' } & Pick<
                Project,
                | 'calendarLinkageCode'
                | 'color'
                | 'complete'
                | 'completeDateTime'
                | 'costBudgetWithoutTax'
                | 'description'
                | 'estimateTimeSec'
                | 'id'
                | 'key'
                | 'laborCost'
                | 'laborCostWithoutTax'
                | 'laborShare'
                | 'name'
                | 'priority'
                | 'registerDateTime'
                | 'salesAmountWithoutTax'
                | 'scheduledEndDateTime'
                | 'scheduledStartDateTime'
                | 'sortNoInGanttChart'
                | 'sortNoInList'
                | 'sortNoInProjectStatus'
                | 'totalScheduleTimeSec'
                | 'versionNo'
                | 'workingTimeSec'
              >;
            };
          workingMembers: Array<
            Maybe<
              { __typename?: 'WorkingMemberInfo' } & Pick<
                WorkingMemberInfo,
                'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
              > & {
                  member: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                }
            >
          >;
        };
      startWorkingHistory: { __typename?: 'WorkingHistory' } & Pick<
        WorkingHistory,
        'end' | 'id' | 'memo' | 'start' | 'versionNo' | 'workingTimeSec'
      > & {
          calendarEvent?: Maybe<
            { __typename?: 'CalendarEvent' } & Pick<
              CalendarEvent,
              | 'calendarType'
              | 'clientId'
              | 'clientName'
              | 'endDateTime'
              | 'eventName'
              | 'id'
              | 'laborCost'
              | 'personalTeam'
              | 'projectColor'
              | 'projectId'
              | 'projectName'
              | 'startDateTime'
              | 'taskId'
              | 'taskTitle'
              | 'teamId'
              | 'teamName'
              | 'thirdPartyCalendarEventId'
              | 'versionNo'
              | 'workingTimeSec'
            > & {
                thirdPartyCalendarInfoList: Array<
                  Maybe<
                    { __typename?: 'ThirdPartyCalendarInfo' } & Pick<
                      ThirdPartyCalendarInfo,
                      'thirdPartyCalendarId'
                    > & {
                        member: { __typename?: 'Member' } & Pick<
                          Member,
                          | 'adminRole'
                          | 'clientManagementRole'
                          | 'department'
                          | 'employeeNumber'
                          | 'id'
                          | 'laborCostManagementRole'
                          | 'mailAddress'
                          | 'memberManagementRole'
                          | 'memberRegistrationDateTime'
                          | 'memberStatus'
                          | 'name'
                          | 'organizationMemberViewRole'
                          | 'organizationReportRole'
                          | 'profileImageUrl'
                          | 'projectCreatePermissionFlg'
                          | 'projectCustomAttributePermissionFlg'
                          | 'projectDeletePermissionFlg'
                          | 'projectUpdatePermissionFlg'
                          | 'proxyEditWorkingDataRole'
                          | 'salesManagementRole'
                          | 'statusMessage'
                          | 'taskCreatePermissionFlg'
                          | 'taskCustomAttributePermissionFlg'
                          | 'taskDeletePermissionFlg'
                          | 'taskUpdatePermissionFlg'
                          | 'teamMemberViewRole'
                          | 'teamReportRole'
                          | 'versionNo'
                        >;
                      }
                  >
                >;
              }
          >;
          closingProcessInfo: { __typename?: 'ClosingProcessPart' } & Pick<
            ClosingProcessPart,
            'closed' | 'targetDateTime'
          >;
          task?: Maybe<
            { __typename?: 'Task' } & Pick<
              Task,
              | 'calendarLinkageCode'
              | 'completeDateTime'
              | 'description'
              | 'estimateTimeSec'
              | 'estimatedRemainingTimeSec'
              | 'id'
              | 'laborCost'
              | 'priority'
              | 'progressDeviationRate'
              | 'progressRate'
              | 'registerDateTime'
              | 'remainingTimeSec'
              | 'scheduleWorkingTimeSec'
              | 'scheduledEndDateTime'
              | 'scheduledStartDateTime'
              | 'sortNoInGanttChart'
              | 'sortNoInList'
              | 'sortNoInMyTask'
              | 'sortNoInTaskStatus'
              | 'title'
              | 'versionNo'
              | 'workingTimeSec'
            > & {
                assignees: Array<
                  { __typename?: 'AssignedTaskMemberInfo' } & Pick<AssignedTaskMemberInfo, 'sortNo'>
                >;
                asssignee?: Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >;
                createdMember: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
                favoriteMembers: Array<
                  Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >
                >;
                favoriteMembersInfo: Array<
                  Maybe<
                    { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                      FavoriteTaskMemberInfo,
                      'sortNo'
                    >
                  >
                >;
                project: { __typename?: 'Project' } & Pick<
                  Project,
                  | 'calendarLinkageCode'
                  | 'color'
                  | 'complete'
                  | 'completeDateTime'
                  | 'costBudgetWithoutTax'
                  | 'description'
                  | 'estimateTimeSec'
                  | 'id'
                  | 'key'
                  | 'laborCost'
                  | 'laborCostWithoutTax'
                  | 'laborShare'
                  | 'name'
                  | 'priority'
                  | 'registerDateTime'
                  | 'salesAmountWithoutTax'
                  | 'scheduledEndDateTime'
                  | 'scheduledStartDateTime'
                  | 'sortNoInGanttChart'
                  | 'sortNoInList'
                  | 'sortNoInProjectStatus'
                  | 'totalScheduleTimeSec'
                  | 'versionNo'
                  | 'workingTimeSec'
                >;
                taskStatus: { __typename?: 'TaskStatus' } & Pick<
                  TaskStatus,
                  'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
                >;
                workingMembers: Array<
                  Maybe<
                    { __typename?: 'WorkingMemberInfo' } & Pick<
                      WorkingMemberInfo,
                      'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                    >
                  >
                >;
              }
          >;
          workingMember: { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >;
        };
      stopTask?: Maybe<
        { __typename?: 'Task' } & Pick<
          Task,
          | 'calendarLinkageCode'
          | 'completeDateTime'
          | 'description'
          | 'estimateTimeSec'
          | 'estimatedRemainingTimeSec'
          | 'id'
          | 'laborCost'
          | 'priority'
          | 'progressDeviationRate'
          | 'progressRate'
          | 'registerDateTime'
          | 'remainingTimeSec'
          | 'scheduleWorkingTimeSec'
          | 'scheduledEndDateTime'
          | 'scheduledStartDateTime'
          | 'sortNoInGanttChart'
          | 'sortNoInList'
          | 'sortNoInMyTask'
          | 'sortNoInTaskStatus'
          | 'title'
          | 'versionNo'
          | 'workingTimeSec'
        > & {
            assignees: Array<
              { __typename?: 'AssignedTaskMemberInfo' } & Pick<AssignedTaskMemberInfo, 'sortNo'>
            >;
            asssignee?: Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >;
            createdMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
            favoriteMembers: Array<
              Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >
            >;
            favoriteMembersInfo: Array<
              Maybe<
                { __typename?: 'FavoriteTaskMemberInfo' } & Pick<FavoriteTaskMemberInfo, 'sortNo'>
              >
            >;
            project: { __typename?: 'Project' } & Pick<
              Project,
              | 'calendarLinkageCode'
              | 'color'
              | 'complete'
              | 'completeDateTime'
              | 'costBudgetWithoutTax'
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'key'
              | 'laborCost'
              | 'laborCostWithoutTax'
              | 'laborShare'
              | 'name'
              | 'priority'
              | 'registerDateTime'
              | 'salesAmountWithoutTax'
              | 'scheduledEndDateTime'
              | 'scheduledStartDateTime'
              | 'sortNoInGanttChart'
              | 'sortNoInList'
              | 'sortNoInProjectStatus'
              | 'totalScheduleTimeSec'
              | 'versionNo'
              | 'workingTimeSec'
            >;
            taskStatus: { __typename?: 'TaskStatus' } & Pick<
              TaskStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
            >;
            workingMembers: Array<
              Maybe<
                { __typename?: 'WorkingMemberInfo' } & Pick<
                  WorkingMemberInfo,
                  'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                >
              >
            >;
          }
      >;
      stopWorkingHistory?: Maybe<
        { __typename?: 'WorkingHistory' } & Pick<
          WorkingHistory,
          'end' | 'id' | 'memo' | 'start' | 'versionNo' | 'workingTimeSec'
        > & {
            calendarEvent?: Maybe<
              { __typename?: 'CalendarEvent' } & Pick<
                CalendarEvent,
                | 'calendarType'
                | 'clientId'
                | 'clientName'
                | 'endDateTime'
                | 'eventName'
                | 'id'
                | 'laborCost'
                | 'personalTeam'
                | 'projectColor'
                | 'projectId'
                | 'projectName'
                | 'startDateTime'
                | 'taskId'
                | 'taskTitle'
                | 'teamId'
                | 'teamName'
                | 'thirdPartyCalendarEventId'
                | 'versionNo'
                | 'workingTimeSec'
              >
            >;
            closingProcessInfo: { __typename?: 'ClosingProcessPart' } & Pick<
              ClosingProcessPart,
              'closed' | 'targetDateTime'
            >;
            task?: Maybe<
              { __typename?: 'Task' } & Pick<
                Task,
                | 'calendarLinkageCode'
                | 'completeDateTime'
                | 'description'
                | 'estimateTimeSec'
                | 'estimatedRemainingTimeSec'
                | 'id'
                | 'laborCost'
                | 'priority'
                | 'progressDeviationRate'
                | 'progressRate'
                | 'registerDateTime'
                | 'remainingTimeSec'
                | 'scheduleWorkingTimeSec'
                | 'scheduledEndDateTime'
                | 'scheduledStartDateTime'
                | 'sortNoInGanttChart'
                | 'sortNoInList'
                | 'sortNoInMyTask'
                | 'sortNoInTaskStatus'
                | 'title'
                | 'versionNo'
                | 'workingTimeSec'
              >
            >;
            workingMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
          }
      >;
    }
  >;
};

export type StopTaskMutationVariables = Exact<{
  id: Scalars['ID'];
  input: StopTaskInputInput;
}>;

export type StopTaskMutation = { __typename?: 'Mutation' } & {
  stopTask?: Maybe<
    { __typename?: 'StopTaskResult' } & {
      stopTask: { __typename?: 'Task' } & Pick<
        Task,
        | 'calendarLinkageCode'
        | 'completeDateTime'
        | 'description'
        | 'estimateTimeSec'
        | 'estimatedRemainingTimeSec'
        | 'id'
        | 'laborCost'
        | 'priority'
        | 'progressDeviationRate'
        | 'progressRate'
        | 'registerDateTime'
        | 'remainingTimeSec'
        | 'scheduleWorkingTimeSec'
        | 'scheduledEndDateTime'
        | 'scheduledStartDateTime'
        | 'sortNoInGanttChart'
        | 'sortNoInList'
        | 'sortNoInMyTask'
        | 'sortNoInTaskStatus'
        | 'title'
        | 'versionNo'
        | 'workingTimeSec'
      > & {
          assignees: Array<
            { __typename?: 'AssignedTaskMemberInfo' } & Pick<AssignedTaskMemberInfo, 'sortNo'> & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >;
          asssignee?: Maybe<
            { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >
          >;
          createdMember: { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >;
          favoriteMembers: Array<
            Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >
          >;
          favoriteMembersInfo: Array<
            Maybe<
              { __typename?: 'FavoriteTaskMemberInfo' } & Pick<FavoriteTaskMemberInfo, 'sortNo'> & {
                  member: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                }
            >
          >;
          project: { __typename?: 'Project' } & Pick<
            Project,
            | 'calendarLinkageCode'
            | 'color'
            | 'complete'
            | 'completeDateTime'
            | 'costBudgetWithoutTax'
            | 'description'
            | 'estimateTimeSec'
            | 'id'
            | 'key'
            | 'laborCost'
            | 'laborCostWithoutTax'
            | 'laborShare'
            | 'name'
            | 'priority'
            | 'registerDateTime'
            | 'salesAmountWithoutTax'
            | 'scheduledEndDateTime'
            | 'scheduledStartDateTime'
            | 'sortNoInGanttChart'
            | 'sortNoInList'
            | 'sortNoInProjectStatus'
            | 'totalScheduleTimeSec'
            | 'versionNo'
            | 'workingTimeSec'
          > & {
              assignees: Array<
                { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                  AssignedProjectMemberInfo,
                  'sortNo'
                >
              >;
              client?: Maybe<
                { __typename?: 'Client' } & Pick<
                  Client,
                  'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                >
              >;
              createdMember?: Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >;
              favoriteMembers: Array<
                Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >
              >;
              favoriteMembersInfo: Array<
                Maybe<
                  { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                    FavoriteProjectMemberInfo,
                    'sortNo'
                  >
                >
              >;
              projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                ProjectStatus,
                'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
              >;
              team: { __typename?: 'Team' } & Pick<
                Team,
                'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
              > & {
                  organization: { __typename?: 'Organization' } & Pick<
                    Organization,
                    | 'allowConnectThirdPartyCalendar'
                    | 'contactMailAddress'
                    | 'contractPreriod'
                    | 'id'
                    | 'imageUrl'
                    | 'ipRestrictionFlg'
                    | 'name'
                    | 'requiredTwoFactorAuth'
                    | 'suspensionFlg'
                    | 'versionNo'
                  > & {
                      plan: { __typename?: 'PlanProduct' } & Pick<
                        PlanProduct,
                        'code' | 'contractPreriod' | 'id'
                      >;
                    };
                };
            };
          taskStatus: { __typename?: 'TaskStatus' } & Pick<
            TaskStatus,
            'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
          > & {
              project: { __typename?: 'Project' } & Pick<
                Project,
                | 'calendarLinkageCode'
                | 'color'
                | 'complete'
                | 'completeDateTime'
                | 'costBudgetWithoutTax'
                | 'description'
                | 'estimateTimeSec'
                | 'id'
                | 'key'
                | 'laborCost'
                | 'laborCostWithoutTax'
                | 'laborShare'
                | 'name'
                | 'priority'
                | 'registerDateTime'
                | 'salesAmountWithoutTax'
                | 'scheduledEndDateTime'
                | 'scheduledStartDateTime'
                | 'sortNoInGanttChart'
                | 'sortNoInList'
                | 'sortNoInProjectStatus'
                | 'totalScheduleTimeSec'
                | 'versionNo'
                | 'workingTimeSec'
              >;
            };
          workingMembers: Array<
            Maybe<
              { __typename?: 'WorkingMemberInfo' } & Pick<
                WorkingMemberInfo,
                'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
              > & {
                  member: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                }
            >
          >;
        };
      stopWorkingHistory: { __typename?: 'WorkingHistory' } & Pick<
        WorkingHistory,
        'end' | 'id' | 'memo' | 'start' | 'versionNo' | 'workingTimeSec'
      > & {
          calendarEvent?: Maybe<
            { __typename?: 'CalendarEvent' } & Pick<
              CalendarEvent,
              | 'calendarType'
              | 'clientId'
              | 'clientName'
              | 'endDateTime'
              | 'eventName'
              | 'id'
              | 'laborCost'
              | 'personalTeam'
              | 'projectColor'
              | 'projectId'
              | 'projectName'
              | 'startDateTime'
              | 'taskId'
              | 'taskTitle'
              | 'teamId'
              | 'teamName'
              | 'thirdPartyCalendarEventId'
              | 'versionNo'
              | 'workingTimeSec'
            > & {
                thirdPartyCalendarInfoList: Array<
                  Maybe<
                    { __typename?: 'ThirdPartyCalendarInfo' } & Pick<
                      ThirdPartyCalendarInfo,
                      'thirdPartyCalendarId'
                    > & {
                        member: { __typename?: 'Member' } & Pick<
                          Member,
                          | 'adminRole'
                          | 'clientManagementRole'
                          | 'department'
                          | 'employeeNumber'
                          | 'id'
                          | 'laborCostManagementRole'
                          | 'mailAddress'
                          | 'memberManagementRole'
                          | 'memberRegistrationDateTime'
                          | 'memberStatus'
                          | 'name'
                          | 'organizationMemberViewRole'
                          | 'organizationReportRole'
                          | 'profileImageUrl'
                          | 'projectCreatePermissionFlg'
                          | 'projectCustomAttributePermissionFlg'
                          | 'projectDeletePermissionFlg'
                          | 'projectUpdatePermissionFlg'
                          | 'proxyEditWorkingDataRole'
                          | 'salesManagementRole'
                          | 'statusMessage'
                          | 'taskCreatePermissionFlg'
                          | 'taskCustomAttributePermissionFlg'
                          | 'taskDeletePermissionFlg'
                          | 'taskUpdatePermissionFlg'
                          | 'teamMemberViewRole'
                          | 'teamReportRole'
                          | 'versionNo'
                        >;
                      }
                  >
                >;
              }
          >;
          closingProcessInfo: { __typename?: 'ClosingProcessPart' } & Pick<
            ClosingProcessPart,
            'closed' | 'targetDateTime'
          >;
          task?: Maybe<
            { __typename?: 'Task' } & Pick<
              Task,
              | 'calendarLinkageCode'
              | 'completeDateTime'
              | 'description'
              | 'estimateTimeSec'
              | 'estimatedRemainingTimeSec'
              | 'id'
              | 'laborCost'
              | 'priority'
              | 'progressDeviationRate'
              | 'progressRate'
              | 'registerDateTime'
              | 'remainingTimeSec'
              | 'scheduleWorkingTimeSec'
              | 'scheduledEndDateTime'
              | 'scheduledStartDateTime'
              | 'sortNoInGanttChart'
              | 'sortNoInList'
              | 'sortNoInMyTask'
              | 'sortNoInTaskStatus'
              | 'title'
              | 'versionNo'
              | 'workingTimeSec'
            > & {
                assignees: Array<
                  { __typename?: 'AssignedTaskMemberInfo' } & Pick<AssignedTaskMemberInfo, 'sortNo'>
                >;
                asssignee?: Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >;
                createdMember: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
                favoriteMembers: Array<
                  Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >
                >;
                favoriteMembersInfo: Array<
                  Maybe<
                    { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                      FavoriteTaskMemberInfo,
                      'sortNo'
                    >
                  >
                >;
                project: { __typename?: 'Project' } & Pick<
                  Project,
                  | 'calendarLinkageCode'
                  | 'color'
                  | 'complete'
                  | 'completeDateTime'
                  | 'costBudgetWithoutTax'
                  | 'description'
                  | 'estimateTimeSec'
                  | 'id'
                  | 'key'
                  | 'laborCost'
                  | 'laborCostWithoutTax'
                  | 'laborShare'
                  | 'name'
                  | 'priority'
                  | 'registerDateTime'
                  | 'salesAmountWithoutTax'
                  | 'scheduledEndDateTime'
                  | 'scheduledStartDateTime'
                  | 'sortNoInGanttChart'
                  | 'sortNoInList'
                  | 'sortNoInProjectStatus'
                  | 'totalScheduleTimeSec'
                  | 'versionNo'
                  | 'workingTimeSec'
                >;
                taskStatus: { __typename?: 'TaskStatus' } & Pick<
                  TaskStatus,
                  'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
                >;
                workingMembers: Array<
                  Maybe<
                    { __typename?: 'WorkingMemberInfo' } & Pick<
                      WorkingMemberInfo,
                      'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                    >
                  >
                >;
              }
          >;
          workingMember: { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >;
        };
      stopWorkingHistoryCalendar: { __typename?: 'CalendarWorkingHistory' } & Pick<
        CalendarWorkingHistory,
        'end' | 'id' | 'memo' | 'start' | 'versionNo' | 'workingTimeSec'
      > & {
          calendarEvent?: Maybe<
            { __typename?: 'WorkingHistoryCalendarEventPart' } & Pick<
              WorkingHistoryCalendarEventPart,
              | 'calendarType'
              | 'clientId'
              | 'clientName'
              | 'endDateTime'
              | 'eventName'
              | 'id'
              | 'personalTeam'
              | 'projectColor'
              | 'projectId'
              | 'projectName'
              | 'startDateTime'
              | 'taskId'
              | 'taskTitle'
              | 'teamId'
              | 'teamName'
              | 'thirdPartyCalendarEventId'
              | 'versionNo'
            >
          >;
          task?: Maybe<
            { __typename?: 'WorkingHistoryTaskPart' } & Pick<
              WorkingHistoryTaskPart,
              'completeDateTime' | 'id' | 'title' | 'versionNo'
            >
          >;
          workingMember: { __typename?: 'WorkingHistoryMemberPart' } & Pick<
            WorkingHistoryMemberPart,
            'id' | 'name' | 'profileImageUrl'
          >;
        };
      stopWorkingHistorySummary: { __typename?: 'WorkingHistoryForSummary' } & Pick<
        WorkingHistoryForSummary,
        'end' | 'id' | 'memo' | 'start' | 'versionNo' | 'workingTimeSec'
      > & {
          calendarEvent?: Maybe<
            { __typename?: 'WorkingHistoryForSummaryCalendarEventPart' } & Pick<
              WorkingHistoryForSummaryCalendarEventPart,
              | 'clientId'
              | 'clientName'
              | 'eventName'
              | 'id'
              | 'projectId'
              | 'projectName'
              | 'teamId'
            >
          >;
          closingProcessInfo: { __typename?: 'WorkingHistoryForSummaryClosingProcessPart' } & Pick<
            WorkingHistoryForSummaryClosingProcessPart,
            'closed' | 'targetDateTime'
          >;
          task?: Maybe<
            { __typename?: 'WorkingHistoryForSummaryTaskPart' } & Pick<
              WorkingHistoryForSummaryTaskPart,
              'id' | 'title' | 'versionNo'
            >
          >;
          workingMember: { __typename?: 'WorkingHistoryForSummaryMemberPart' } & Pick<
            WorkingHistoryForSummaryMemberPart,
            'id' | 'name' | 'profileImageUrl'
          >;
        };
    }
  >;
};

export type SyncRegisterWorkingHistoriesFromGoogleCalendarsMutationVariables = Exact<{
  input: RegisterWorkingHistoriesFromGoogleCallendarEventsInputInput;
}>;

export type SyncRegisterWorkingHistoriesFromGoogleCalendarsMutation = {
  __typename?: 'Mutation';
} & {
  syncRegisterWorkingHistoriesFromGoogleCalendars?: Maybe<
    Array<
      Maybe<
        { __typename?: 'WorkingHistory' } & Pick<
          WorkingHistory,
          'end' | 'id' | 'memo' | 'start' | 'versionNo' | 'workingTimeSec'
        > & {
            calendarEvent?: Maybe<
              { __typename?: 'CalendarEvent' } & Pick<
                CalendarEvent,
                | 'calendarType'
                | 'clientId'
                | 'clientName'
                | 'endDateTime'
                | 'eventName'
                | 'id'
                | 'laborCost'
                | 'personalTeam'
                | 'projectColor'
                | 'projectId'
                | 'projectName'
                | 'startDateTime'
                | 'taskId'
                | 'taskTitle'
                | 'teamId'
                | 'teamName'
                | 'thirdPartyCalendarEventId'
                | 'versionNo'
                | 'workingTimeSec'
              > & {
                  thirdPartyCalendarInfoList: Array<
                    Maybe<
                      { __typename?: 'ThirdPartyCalendarInfo' } & Pick<
                        ThirdPartyCalendarInfo,
                        'thirdPartyCalendarId'
                      > & {
                          member: { __typename?: 'Member' } & Pick<
                            Member,
                            | 'adminRole'
                            | 'clientManagementRole'
                            | 'department'
                            | 'employeeNumber'
                            | 'id'
                            | 'laborCostManagementRole'
                            | 'mailAddress'
                            | 'memberManagementRole'
                            | 'memberRegistrationDateTime'
                            | 'memberStatus'
                            | 'name'
                            | 'organizationMemberViewRole'
                            | 'organizationReportRole'
                            | 'profileImageUrl'
                            | 'projectCreatePermissionFlg'
                            | 'projectCustomAttributePermissionFlg'
                            | 'projectDeletePermissionFlg'
                            | 'projectUpdatePermissionFlg'
                            | 'proxyEditWorkingDataRole'
                            | 'salesManagementRole'
                            | 'statusMessage'
                            | 'taskCreatePermissionFlg'
                            | 'taskCustomAttributePermissionFlg'
                            | 'taskDeletePermissionFlg'
                            | 'taskUpdatePermissionFlg'
                            | 'teamMemberViewRole'
                            | 'teamReportRole'
                            | 'versionNo'
                          >;
                        }
                    >
                  >;
                }
            >;
            closingProcessInfo: { __typename?: 'ClosingProcessPart' } & Pick<
              ClosingProcessPart,
              'closed' | 'targetDateTime'
            >;
            task?: Maybe<
              { __typename?: 'Task' } & Pick<
                Task,
                | 'calendarLinkageCode'
                | 'completeDateTime'
                | 'description'
                | 'estimateTimeSec'
                | 'estimatedRemainingTimeSec'
                | 'id'
                | 'laborCost'
                | 'priority'
                | 'progressDeviationRate'
                | 'progressRate'
                | 'registerDateTime'
                | 'remainingTimeSec'
                | 'scheduleWorkingTimeSec'
                | 'scheduledEndDateTime'
                | 'scheduledStartDateTime'
                | 'sortNoInGanttChart'
                | 'sortNoInList'
                | 'sortNoInMyTask'
                | 'sortNoInTaskStatus'
                | 'title'
                | 'versionNo'
                | 'workingTimeSec'
              > & {
                  assignees: Array<
                    { __typename?: 'AssignedTaskMemberInfo' } & Pick<
                      AssignedTaskMemberInfo,
                      'sortNo'
                    > & {
                        member: { __typename?: 'Member' } & Pick<
                          Member,
                          | 'adminRole'
                          | 'clientManagementRole'
                          | 'department'
                          | 'employeeNumber'
                          | 'id'
                          | 'laborCostManagementRole'
                          | 'mailAddress'
                          | 'memberManagementRole'
                          | 'memberRegistrationDateTime'
                          | 'memberStatus'
                          | 'name'
                          | 'organizationMemberViewRole'
                          | 'organizationReportRole'
                          | 'profileImageUrl'
                          | 'projectCreatePermissionFlg'
                          | 'projectCustomAttributePermissionFlg'
                          | 'projectDeletePermissionFlg'
                          | 'projectUpdatePermissionFlg'
                          | 'proxyEditWorkingDataRole'
                          | 'salesManagementRole'
                          | 'statusMessage'
                          | 'taskCreatePermissionFlg'
                          | 'taskCustomAttributePermissionFlg'
                          | 'taskDeletePermissionFlg'
                          | 'taskUpdatePermissionFlg'
                          | 'teamMemberViewRole'
                          | 'teamReportRole'
                          | 'versionNo'
                        >;
                      }
                  >;
                  asssignee?: Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >;
                  createdMember: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                  favoriteMembers: Array<
                    Maybe<
                      { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >
                    >
                  >;
                  favoriteMembersInfo: Array<
                    Maybe<
                      { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                        FavoriteTaskMemberInfo,
                        'sortNo'
                      > & {
                          member: { __typename?: 'Member' } & Pick<
                            Member,
                            | 'adminRole'
                            | 'clientManagementRole'
                            | 'department'
                            | 'employeeNumber'
                            | 'id'
                            | 'laborCostManagementRole'
                            | 'mailAddress'
                            | 'memberManagementRole'
                            | 'memberRegistrationDateTime'
                            | 'memberStatus'
                            | 'name'
                            | 'organizationMemberViewRole'
                            | 'organizationReportRole'
                            | 'profileImageUrl'
                            | 'projectCreatePermissionFlg'
                            | 'projectCustomAttributePermissionFlg'
                            | 'projectDeletePermissionFlg'
                            | 'projectUpdatePermissionFlg'
                            | 'proxyEditWorkingDataRole'
                            | 'salesManagementRole'
                            | 'statusMessage'
                            | 'taskCreatePermissionFlg'
                            | 'taskCustomAttributePermissionFlg'
                            | 'taskDeletePermissionFlg'
                            | 'taskUpdatePermissionFlg'
                            | 'teamMemberViewRole'
                            | 'teamReportRole'
                            | 'versionNo'
                          >;
                        }
                    >
                  >;
                  project: { __typename?: 'Project' } & Pick<
                    Project,
                    | 'calendarLinkageCode'
                    | 'color'
                    | 'complete'
                    | 'completeDateTime'
                    | 'costBudgetWithoutTax'
                    | 'description'
                    | 'estimateTimeSec'
                    | 'id'
                    | 'key'
                    | 'laborCost'
                    | 'laborCostWithoutTax'
                    | 'laborShare'
                    | 'name'
                    | 'priority'
                    | 'registerDateTime'
                    | 'salesAmountWithoutTax'
                    | 'scheduledEndDateTime'
                    | 'scheduledStartDateTime'
                    | 'sortNoInGanttChart'
                    | 'sortNoInList'
                    | 'sortNoInProjectStatus'
                    | 'totalScheduleTimeSec'
                    | 'versionNo'
                    | 'workingTimeSec'
                  > & {
                      assignees: Array<
                        { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                          AssignedProjectMemberInfo,
                          'sortNo'
                        >
                      >;
                      client?: Maybe<
                        { __typename?: 'Client' } & Pick<
                          Client,
                          'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                        >
                      >;
                      createdMember?: Maybe<
                        { __typename?: 'Member' } & Pick<
                          Member,
                          | 'adminRole'
                          | 'clientManagementRole'
                          | 'department'
                          | 'employeeNumber'
                          | 'id'
                          | 'laborCostManagementRole'
                          | 'mailAddress'
                          | 'memberManagementRole'
                          | 'memberRegistrationDateTime'
                          | 'memberStatus'
                          | 'name'
                          | 'organizationMemberViewRole'
                          | 'organizationReportRole'
                          | 'profileImageUrl'
                          | 'projectCreatePermissionFlg'
                          | 'projectCustomAttributePermissionFlg'
                          | 'projectDeletePermissionFlg'
                          | 'projectUpdatePermissionFlg'
                          | 'proxyEditWorkingDataRole'
                          | 'salesManagementRole'
                          | 'statusMessage'
                          | 'taskCreatePermissionFlg'
                          | 'taskCustomAttributePermissionFlg'
                          | 'taskDeletePermissionFlg'
                          | 'taskUpdatePermissionFlg'
                          | 'teamMemberViewRole'
                          | 'teamReportRole'
                          | 'versionNo'
                        >
                      >;
                      favoriteMembers: Array<
                        Maybe<
                          { __typename?: 'Member' } & Pick<
                            Member,
                            | 'adminRole'
                            | 'clientManagementRole'
                            | 'department'
                            | 'employeeNumber'
                            | 'id'
                            | 'laborCostManagementRole'
                            | 'mailAddress'
                            | 'memberManagementRole'
                            | 'memberRegistrationDateTime'
                            | 'memberStatus'
                            | 'name'
                            | 'organizationMemberViewRole'
                            | 'organizationReportRole'
                            | 'profileImageUrl'
                            | 'projectCreatePermissionFlg'
                            | 'projectCustomAttributePermissionFlg'
                            | 'projectDeletePermissionFlg'
                            | 'projectUpdatePermissionFlg'
                            | 'proxyEditWorkingDataRole'
                            | 'salesManagementRole'
                            | 'statusMessage'
                            | 'taskCreatePermissionFlg'
                            | 'taskCustomAttributePermissionFlg'
                            | 'taskDeletePermissionFlg'
                            | 'taskUpdatePermissionFlg'
                            | 'teamMemberViewRole'
                            | 'teamReportRole'
                            | 'versionNo'
                          >
                        >
                      >;
                      favoriteMembersInfo: Array<
                        Maybe<
                          { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                            FavoriteProjectMemberInfo,
                            'sortNo'
                          >
                        >
                      >;
                      projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                        ProjectStatus,
                        'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                      >;
                      team: { __typename?: 'Team' } & Pick<
                        Team,
                        'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                      > & {
                          organization: { __typename?: 'Organization' } & Pick<
                            Organization,
                            | 'allowConnectThirdPartyCalendar'
                            | 'contactMailAddress'
                            | 'contractPreriod'
                            | 'id'
                            | 'imageUrl'
                            | 'ipRestrictionFlg'
                            | 'name'
                            | 'requiredTwoFactorAuth'
                            | 'suspensionFlg'
                            | 'versionNo'
                          > & {
                              plan: { __typename?: 'PlanProduct' } & Pick<
                                PlanProduct,
                                'code' | 'contractPreriod' | 'id'
                              >;
                            };
                        };
                    };
                  taskStatus: { __typename?: 'TaskStatus' } & Pick<
                    TaskStatus,
                    'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
                  > & {
                      project: { __typename?: 'Project' } & Pick<
                        Project,
                        | 'calendarLinkageCode'
                        | 'color'
                        | 'complete'
                        | 'completeDateTime'
                        | 'costBudgetWithoutTax'
                        | 'description'
                        | 'estimateTimeSec'
                        | 'id'
                        | 'key'
                        | 'laborCost'
                        | 'laborCostWithoutTax'
                        | 'laborShare'
                        | 'name'
                        | 'priority'
                        | 'registerDateTime'
                        | 'salesAmountWithoutTax'
                        | 'scheduledEndDateTime'
                        | 'scheduledStartDateTime'
                        | 'sortNoInGanttChart'
                        | 'sortNoInList'
                        | 'sortNoInProjectStatus'
                        | 'totalScheduleTimeSec'
                        | 'versionNo'
                        | 'workingTimeSec'
                      >;
                    };
                  workingMembers: Array<
                    Maybe<
                      { __typename?: 'WorkingMemberInfo' } & Pick<
                        WorkingMemberInfo,
                        'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                      > & {
                          member: { __typename?: 'Member' } & Pick<
                            Member,
                            | 'adminRole'
                            | 'clientManagementRole'
                            | 'department'
                            | 'employeeNumber'
                            | 'id'
                            | 'laborCostManagementRole'
                            | 'mailAddress'
                            | 'memberManagementRole'
                            | 'memberRegistrationDateTime'
                            | 'memberStatus'
                            | 'name'
                            | 'organizationMemberViewRole'
                            | 'organizationReportRole'
                            | 'profileImageUrl'
                            | 'projectCreatePermissionFlg'
                            | 'projectCustomAttributePermissionFlg'
                            | 'projectDeletePermissionFlg'
                            | 'projectUpdatePermissionFlg'
                            | 'proxyEditWorkingDataRole'
                            | 'salesManagementRole'
                            | 'statusMessage'
                            | 'taskCreatePermissionFlg'
                            | 'taskCustomAttributePermissionFlg'
                            | 'taskDeletePermissionFlg'
                            | 'taskUpdatePermissionFlg'
                            | 'teamMemberViewRole'
                            | 'teamReportRole'
                            | 'versionNo'
                          >;
                        }
                    >
                  >;
                }
            >;
            workingMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
          }
      >
    >
  >;
};

export type SyncRegisterWorkingSchedulesFromGoogleCalendarsMutationVariables = Exact<{
  input: RegisterWorkingSchedulesFromGoogleCallendarEventsInputInput;
}>;

export type SyncRegisterWorkingSchedulesFromGoogleCalendarsMutation = {
  __typename?: 'Mutation';
} & {
  syncRegisterWorkingSchedulesFromGoogleCalendars?: Maybe<
    Array<
      Maybe<
        { __typename?: 'WorkingSchedule' } & Pick<
          WorkingSchedule,
          'end' | 'id' | 'memo' | 'start' | 'versionNo' | 'workingTimeSec'
        > & {
            calendarEvent?: Maybe<
              { __typename?: 'CalendarEvent' } & Pick<
                CalendarEvent,
                | 'calendarType'
                | 'clientId'
                | 'clientName'
                | 'endDateTime'
                | 'eventName'
                | 'id'
                | 'laborCost'
                | 'personalTeam'
                | 'projectColor'
                | 'projectId'
                | 'projectName'
                | 'startDateTime'
                | 'taskId'
                | 'taskTitle'
                | 'teamId'
                | 'teamName'
                | 'thirdPartyCalendarEventId'
                | 'versionNo'
                | 'workingTimeSec'
              > & {
                  thirdPartyCalendarInfoList: Array<
                    Maybe<
                      { __typename?: 'ThirdPartyCalendarInfo' } & Pick<
                        ThirdPartyCalendarInfo,
                        'thirdPartyCalendarId'
                      > & {
                          member: { __typename?: 'Member' } & Pick<
                            Member,
                            | 'adminRole'
                            | 'clientManagementRole'
                            | 'department'
                            | 'employeeNumber'
                            | 'id'
                            | 'laborCostManagementRole'
                            | 'mailAddress'
                            | 'memberManagementRole'
                            | 'memberRegistrationDateTime'
                            | 'memberStatus'
                            | 'name'
                            | 'organizationMemberViewRole'
                            | 'organizationReportRole'
                            | 'profileImageUrl'
                            | 'projectCreatePermissionFlg'
                            | 'projectCustomAttributePermissionFlg'
                            | 'projectDeletePermissionFlg'
                            | 'projectUpdatePermissionFlg'
                            | 'proxyEditWorkingDataRole'
                            | 'salesManagementRole'
                            | 'statusMessage'
                            | 'taskCreatePermissionFlg'
                            | 'taskCustomAttributePermissionFlg'
                            | 'taskDeletePermissionFlg'
                            | 'taskUpdatePermissionFlg'
                            | 'teamMemberViewRole'
                            | 'teamReportRole'
                            | 'versionNo'
                          >;
                        }
                    >
                  >;
                }
            >;
            task?: Maybe<
              { __typename?: 'Task' } & Pick<
                Task,
                | 'calendarLinkageCode'
                | 'completeDateTime'
                | 'description'
                | 'estimateTimeSec'
                | 'estimatedRemainingTimeSec'
                | 'id'
                | 'laborCost'
                | 'priority'
                | 'progressDeviationRate'
                | 'progressRate'
                | 'registerDateTime'
                | 'remainingTimeSec'
                | 'scheduleWorkingTimeSec'
                | 'scheduledEndDateTime'
                | 'scheduledStartDateTime'
                | 'sortNoInGanttChart'
                | 'sortNoInList'
                | 'sortNoInMyTask'
                | 'sortNoInTaskStatus'
                | 'title'
                | 'versionNo'
                | 'workingTimeSec'
              > & {
                  assignees: Array<
                    { __typename?: 'AssignedTaskMemberInfo' } & Pick<
                      AssignedTaskMemberInfo,
                      'sortNo'
                    > & {
                        member: { __typename?: 'Member' } & Pick<
                          Member,
                          | 'adminRole'
                          | 'clientManagementRole'
                          | 'department'
                          | 'employeeNumber'
                          | 'id'
                          | 'laborCostManagementRole'
                          | 'mailAddress'
                          | 'memberManagementRole'
                          | 'memberRegistrationDateTime'
                          | 'memberStatus'
                          | 'name'
                          | 'organizationMemberViewRole'
                          | 'organizationReportRole'
                          | 'profileImageUrl'
                          | 'projectCreatePermissionFlg'
                          | 'projectCustomAttributePermissionFlg'
                          | 'projectDeletePermissionFlg'
                          | 'projectUpdatePermissionFlg'
                          | 'proxyEditWorkingDataRole'
                          | 'salesManagementRole'
                          | 'statusMessage'
                          | 'taskCreatePermissionFlg'
                          | 'taskCustomAttributePermissionFlg'
                          | 'taskDeletePermissionFlg'
                          | 'taskUpdatePermissionFlg'
                          | 'teamMemberViewRole'
                          | 'teamReportRole'
                          | 'versionNo'
                        >;
                      }
                  >;
                  asssignee?: Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >;
                  createdMember: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                  favoriteMembers: Array<
                    Maybe<
                      { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >
                    >
                  >;
                  favoriteMembersInfo: Array<
                    Maybe<
                      { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                        FavoriteTaskMemberInfo,
                        'sortNo'
                      > & {
                          member: { __typename?: 'Member' } & Pick<
                            Member,
                            | 'adminRole'
                            | 'clientManagementRole'
                            | 'department'
                            | 'employeeNumber'
                            | 'id'
                            | 'laborCostManagementRole'
                            | 'mailAddress'
                            | 'memberManagementRole'
                            | 'memberRegistrationDateTime'
                            | 'memberStatus'
                            | 'name'
                            | 'organizationMemberViewRole'
                            | 'organizationReportRole'
                            | 'profileImageUrl'
                            | 'projectCreatePermissionFlg'
                            | 'projectCustomAttributePermissionFlg'
                            | 'projectDeletePermissionFlg'
                            | 'projectUpdatePermissionFlg'
                            | 'proxyEditWorkingDataRole'
                            | 'salesManagementRole'
                            | 'statusMessage'
                            | 'taskCreatePermissionFlg'
                            | 'taskCustomAttributePermissionFlg'
                            | 'taskDeletePermissionFlg'
                            | 'taskUpdatePermissionFlg'
                            | 'teamMemberViewRole'
                            | 'teamReportRole'
                            | 'versionNo'
                          >;
                        }
                    >
                  >;
                  project: { __typename?: 'Project' } & Pick<
                    Project,
                    | 'calendarLinkageCode'
                    | 'color'
                    | 'complete'
                    | 'completeDateTime'
                    | 'costBudgetWithoutTax'
                    | 'description'
                    | 'estimateTimeSec'
                    | 'id'
                    | 'key'
                    | 'laborCost'
                    | 'laborCostWithoutTax'
                    | 'laborShare'
                    | 'name'
                    | 'priority'
                    | 'registerDateTime'
                    | 'salesAmountWithoutTax'
                    | 'scheduledEndDateTime'
                    | 'scheduledStartDateTime'
                    | 'sortNoInGanttChart'
                    | 'sortNoInList'
                    | 'sortNoInProjectStatus'
                    | 'totalScheduleTimeSec'
                    | 'versionNo'
                    | 'workingTimeSec'
                  > & {
                      assignees: Array<
                        { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                          AssignedProjectMemberInfo,
                          'sortNo'
                        >
                      >;
                      client?: Maybe<
                        { __typename?: 'Client' } & Pick<
                          Client,
                          'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                        >
                      >;
                      createdMember?: Maybe<
                        { __typename?: 'Member' } & Pick<
                          Member,
                          | 'adminRole'
                          | 'clientManagementRole'
                          | 'department'
                          | 'employeeNumber'
                          | 'id'
                          | 'laborCostManagementRole'
                          | 'mailAddress'
                          | 'memberManagementRole'
                          | 'memberRegistrationDateTime'
                          | 'memberStatus'
                          | 'name'
                          | 'organizationMemberViewRole'
                          | 'organizationReportRole'
                          | 'profileImageUrl'
                          | 'projectCreatePermissionFlg'
                          | 'projectCustomAttributePermissionFlg'
                          | 'projectDeletePermissionFlg'
                          | 'projectUpdatePermissionFlg'
                          | 'proxyEditWorkingDataRole'
                          | 'salesManagementRole'
                          | 'statusMessage'
                          | 'taskCreatePermissionFlg'
                          | 'taskCustomAttributePermissionFlg'
                          | 'taskDeletePermissionFlg'
                          | 'taskUpdatePermissionFlg'
                          | 'teamMemberViewRole'
                          | 'teamReportRole'
                          | 'versionNo'
                        >
                      >;
                      favoriteMembers: Array<
                        Maybe<
                          { __typename?: 'Member' } & Pick<
                            Member,
                            | 'adminRole'
                            | 'clientManagementRole'
                            | 'department'
                            | 'employeeNumber'
                            | 'id'
                            | 'laborCostManagementRole'
                            | 'mailAddress'
                            | 'memberManagementRole'
                            | 'memberRegistrationDateTime'
                            | 'memberStatus'
                            | 'name'
                            | 'organizationMemberViewRole'
                            | 'organizationReportRole'
                            | 'profileImageUrl'
                            | 'projectCreatePermissionFlg'
                            | 'projectCustomAttributePermissionFlg'
                            | 'projectDeletePermissionFlg'
                            | 'projectUpdatePermissionFlg'
                            | 'proxyEditWorkingDataRole'
                            | 'salesManagementRole'
                            | 'statusMessage'
                            | 'taskCreatePermissionFlg'
                            | 'taskCustomAttributePermissionFlg'
                            | 'taskDeletePermissionFlg'
                            | 'taskUpdatePermissionFlg'
                            | 'teamMemberViewRole'
                            | 'teamReportRole'
                            | 'versionNo'
                          >
                        >
                      >;
                      favoriteMembersInfo: Array<
                        Maybe<
                          { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                            FavoriteProjectMemberInfo,
                            'sortNo'
                          >
                        >
                      >;
                      projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                        ProjectStatus,
                        'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                      >;
                      team: { __typename?: 'Team' } & Pick<
                        Team,
                        'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                      > & {
                          organization: { __typename?: 'Organization' } & Pick<
                            Organization,
                            | 'allowConnectThirdPartyCalendar'
                            | 'contactMailAddress'
                            | 'contractPreriod'
                            | 'id'
                            | 'imageUrl'
                            | 'ipRestrictionFlg'
                            | 'name'
                            | 'requiredTwoFactorAuth'
                            | 'suspensionFlg'
                            | 'versionNo'
                          > & {
                              plan: { __typename?: 'PlanProduct' } & Pick<
                                PlanProduct,
                                'code' | 'contractPreriod' | 'id'
                              >;
                            };
                        };
                    };
                  taskStatus: { __typename?: 'TaskStatus' } & Pick<
                    TaskStatus,
                    'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
                  > & {
                      project: { __typename?: 'Project' } & Pick<
                        Project,
                        | 'calendarLinkageCode'
                        | 'color'
                        | 'complete'
                        | 'completeDateTime'
                        | 'costBudgetWithoutTax'
                        | 'description'
                        | 'estimateTimeSec'
                        | 'id'
                        | 'key'
                        | 'laborCost'
                        | 'laborCostWithoutTax'
                        | 'laborShare'
                        | 'name'
                        | 'priority'
                        | 'registerDateTime'
                        | 'salesAmountWithoutTax'
                        | 'scheduledEndDateTime'
                        | 'scheduledStartDateTime'
                        | 'sortNoInGanttChart'
                        | 'sortNoInList'
                        | 'sortNoInProjectStatus'
                        | 'totalScheduleTimeSec'
                        | 'versionNo'
                        | 'workingTimeSec'
                      >;
                    };
                  workingMembers: Array<
                    Maybe<
                      { __typename?: 'WorkingMemberInfo' } & Pick<
                        WorkingMemberInfo,
                        'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                      > & {
                          member: { __typename?: 'Member' } & Pick<
                            Member,
                            | 'adminRole'
                            | 'clientManagementRole'
                            | 'department'
                            | 'employeeNumber'
                            | 'id'
                            | 'laborCostManagementRole'
                            | 'mailAddress'
                            | 'memberManagementRole'
                            | 'memberRegistrationDateTime'
                            | 'memberStatus'
                            | 'name'
                            | 'organizationMemberViewRole'
                            | 'organizationReportRole'
                            | 'profileImageUrl'
                            | 'projectCreatePermissionFlg'
                            | 'projectCustomAttributePermissionFlg'
                            | 'projectDeletePermissionFlg'
                            | 'projectUpdatePermissionFlg'
                            | 'proxyEditWorkingDataRole'
                            | 'salesManagementRole'
                            | 'statusMessage'
                            | 'taskCreatePermissionFlg'
                            | 'taskCustomAttributePermissionFlg'
                            | 'taskDeletePermissionFlg'
                            | 'taskUpdatePermissionFlg'
                            | 'teamMemberViewRole'
                            | 'teamReportRole'
                            | 'versionNo'
                          >;
                        }
                    >
                  >;
                }
            >;
            workingMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
          }
      >
    >
  >;
};

export type TeamCloseTermMutationVariables = Exact<{
  input?: Maybe<TeamCloseInputInput>;
}>;

export type TeamCloseTermMutation = { __typename?: 'Mutation' } & {
  teamCloseTerm?: Maybe<
    { __typename?: 'TeamClosingSetting' } & Pick<
      TeamClosingSetting,
      'closingType' | 'id' | 'targetDateTime' | 'teamId' | 'versionNo'
    > & {
        timeZone: { __typename?: 'TimeZone' } & Pick<
          TimeZone,
          'code' | 'id' | 'name' | 'offsetTime' | 'sortNo'
        >;
      }
  >;
};

export type TwoFactorLoginMutationVariables = Exact<{
  input: TwoFactorLoginInputInput;
}>;

export type TwoFactorLoginMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'twoFactorLogin'>;

export type TwoFactorRecoveryCodeLoginMutationVariables = Exact<{
  input: TwoFactorRecoveryCodeLoginInputInput;
}>;

export type TwoFactorRecoveryCodeLoginMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'twoFactorRecoveryCodeLogin'
>;

export type UnarchiveTeamMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UnarchiveTeamInputInput;
}>;

export type UnarchiveTeamMutation = { __typename?: 'Mutation' } & {
  unarchiveTeam?: Maybe<
    { __typename?: 'Team' } & Pick<
      Team,
      'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
    > & {
        organization: { __typename?: 'Organization' } & Pick<
          Organization,
          | 'allowConnectThirdPartyCalendar'
          | 'contactMailAddress'
          | 'contractPreriod'
          | 'id'
          | 'imageUrl'
          | 'ipRestrictionFlg'
          | 'name'
          | 'requiredTwoFactorAuth'
          | 'suspensionFlg'
          | 'versionNo'
        > & {
            plan: { __typename?: 'PlanProduct' } & Pick<
              PlanProduct,
              'code' | 'contractPreriod' | 'id'
            >;
          };
      }
  >;
};

export type UpdateAccessAllowIpAddressesMutationVariables = Exact<{
  input?: Maybe<UpdateAccessAllowIpAddressInputInput>;
}>;

export type UpdateAccessAllowIpAddressesMutation = { __typename?: 'Mutation' } & {
  updateAccessAllowIpAddresses?: Maybe<
    { __typename?: 'AccessControlSettings' } & Pick<
      AccessControlSettings,
      'enable' | 'id' | 'ipAddresses' | 'versionNo'
    >
  >;
};

export type UpdateBillToMailAddressMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: Maybe<UpdateBillToMailAddressInput>;
}>;

export type UpdateBillToMailAddressMutation = { __typename?: 'Mutation' } & {
  updateBillToMailAddress?: Maybe<
    { __typename?: 'Contract' } & Pick<
      Contract,
      | 'billToMailAddress'
      | 'billingType'
      | 'contractPreriod'
      | 'freeTrialExpireDatetime'
      | 'id'
      | 'licenceCount'
      | 'revenuecatOriginalAppUserId'
      | 'usingLicenceCount'
      | 'versionNo'
    > & {
        freeLicenceCoupon?: Maybe<
          { __typename?: 'RangeFreeLicenceCoupon' } & Pick<
            RangeFreeLicenceCoupon,
            'freeEndLicenceCount' | 'freeStartLicenceCount' | 'id'
          >
        >;
        plan: { __typename?: 'PlanProduct' } & Pick<PlanProduct, 'code' | 'contractPreriod' | 'id'>;
      }
  >;
};

export type UpdateCalendarEventMutationVariables = Exact<{
  id: Scalars['String'];
  input: UpdateCalendarEventInputInput;
}>;

export type UpdateCalendarEventMutation = { __typename?: 'Mutation' } & {
  updateCalendarEvent?: Maybe<
    { __typename?: 'CalendarEvent' } & Pick<
      CalendarEvent,
      | 'calendarType'
      | 'clientId'
      | 'clientName'
      | 'endDateTime'
      | 'eventName'
      | 'id'
      | 'laborCost'
      | 'personalTeam'
      | 'projectColor'
      | 'projectId'
      | 'projectName'
      | 'startDateTime'
      | 'taskId'
      | 'taskTitle'
      | 'teamId'
      | 'teamName'
      | 'thirdPartyCalendarEventId'
      | 'versionNo'
      | 'workingTimeSec'
    > & {
        thirdPartyCalendarInfoList: Array<
          Maybe<
            { __typename?: 'ThirdPartyCalendarInfo' } & Pick<
              ThirdPartyCalendarInfo,
              'thirdPartyCalendarId'
            > & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
      }
  >;
};

export type UpdateClientMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateClientInputInput;
}>;

export type UpdateClientMutation = { __typename?: 'Mutation' } & {
  updateClient?: Maybe<
    { __typename?: 'Client' } & Pick<Client, 'code' | 'id' | 'name' | 'sortNo' | 'versionNo'>
  >;
};

export type UpdateClientSortNoMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: Maybe<UpdateClientSortNoInputInput>;
}>;

export type UpdateClientSortNoMutation = { __typename?: 'Mutation' } & {
  updateClientSortNo?: Maybe<
    { __typename?: 'Client' } & Pick<Client, 'code' | 'id' | 'name' | 'sortNo' | 'versionNo'>
  >;
};

export type UpdateCsvReportItemDefinitionMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateCsvReportItemDefinitionInputInput;
}>;

export type UpdateCsvReportItemDefinitionMutation = { __typename?: 'Mutation' } & {
  updateCsvReportItemDefinition?: Maybe<
    { __typename?: 'CsvReportItemDefinition' } & Pick<
      CsvReportItemDefinition,
      'id' | 'name' | 'versionNo'
    > & {
        items: Array<
          Maybe<
            { __typename?: 'CsvReportItemDefinitionDetail' } & Pick<
              CsvReportItemDefinitionDetail,
              'id' | 'reportItemCode' | 'reportItemName' | 'sortNo'
            >
          >
        >;
      }
  >;
};

export type UpdateCsvReportItemSortNoMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateCsvDefinitionDetailSortNoInputInput;
}>;

export type UpdateCsvReportItemSortNoMutation = { __typename?: 'Mutation' } & {
  updateCsvReportItemSortNo?: Maybe<
    { __typename?: 'CsvReportItemDefinition' } & Pick<
      CsvReportItemDefinition,
      'id' | 'name' | 'versionNo'
    > & {
        items: Array<
          Maybe<
            { __typename?: 'CsvReportItemDefinitionDetail' } & Pick<
              CsvReportItemDefinitionDetail,
              'id' | 'reportItemCode' | 'reportItemName' | 'sortNo'
            >
          >
        >;
      }
  >;
};

export type UpdateGoogleCalendarEventMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateGoogleCalendarEventInputInput;
}>;

export type UpdateGoogleCalendarEventMutation = { __typename?: 'Mutation' } & {
  updateGoogleCalendarEvent?: Maybe<
    { __typename?: 'GoogleCalendarEvent' } & Pick<
      GoogleCalendarEvent,
      | 'allDay'
      | 'backgroundColor'
      | 'calendarId'
      | 'colorId'
      | 'dateWhenAllDay'
      | 'description'
      | 'endDateTime'
      | 'foregroundColor'
      | 'id'
      | 'location'
      | 'readonly'
      | 'startDateTime'
      | 'status'
      | 'targetMemberId'
      | 'title'
      | 'visivility'
    > & {
        attendees?: Maybe<
          Array<
            Maybe<
              { __typename?: 'Attendee' } & Pick<
                Attendee,
                'mailAddress' | 'name' | 'responseStatus' | 'self'
              >
            >
          >
        >;
        organizer?: Maybe<
          { __typename?: 'NameAndMailAddress' } & Pick<NameAndMailAddress, 'mailAddress' | 'name'>
        >;
      }
  >;
};

export type UpdateInvitationMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateMemberInvitationInputInput;
}>;

export type UpdateInvitationMutation = { __typename?: 'Mutation' } & {
  updateInvitation?: Maybe<
    { __typename?: 'MemberInvitation' } & Pick<
      MemberInvitation,
      | 'adminRole'
      | 'allowThirdPartyCalendarConnect'
      | 'clientManagementRole'
      | 'createMemberId'
      | 'department'
      | 'employeeNumber'
      | 'id'
      | 'invitationKey'
      | 'laborCostManagementRole'
      | 'mailAddress'
      | 'memberManagementRole'
      | 'name'
      | 'organizationId'
      | 'organizationMemberViewRole'
      | 'organizationName'
      | 'organizationReportRole'
      | 'projectCreatePermissionFlg'
      | 'projectCustomAttributePermissionFlg'
      | 'projectDeletePermissionFlg'
      | 'projectUpdatePermissionFlg'
      | 'proxyEditWorkingDataRole'
      | 'salesManagementRole'
      | 'taskCreatePermissionFlg'
      | 'taskCustomAttributePermissionFlg'
      | 'taskDeletePermissionFlg'
      | 'taskUpdatePermissionFlg'
      | 'teamMemberViewRole'
      | 'teamReportRole'
      | 'versionNo'
    >
  >;
};

export type UpdateInvitationLinkMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateMemberInvitationLinkInputInput;
}>;

export type UpdateInvitationLinkMutation = { __typename?: 'Mutation' } & {
  updateInvitationLink?: Maybe<
    { __typename?: 'MemberInvitationLink' } & Pick<
      MemberInvitationLink,
      | 'allowThirdPartyCalendarConnect'
      | 'clientManagementRole'
      | 'createDateTime'
      | 'createMemberId'
      | 'id'
      | 'invitationKey'
      | 'invitationUrl'
      | 'memo'
      | 'organizationId'
      | 'organizationMemberViewRole'
      | 'organizationName'
      | 'organizationReportRole'
      | 'projectCreatePermissionFlg'
      | 'projectCustomAttributePermissionFlg'
      | 'projectDeletePermissionFlg'
      | 'projectUpdatePermissionFlg'
      | 'proxyEditWorkingDataRole'
      | 'taskCreatePermissionFlg'
      | 'taskCustomAttributePermissionFlg'
      | 'taskDeletePermissionFlg'
      | 'taskUpdatePermissionFlg'
      | 'teamMemberViewRole'
      | 'teamReportRole'
      | 'versionNo'
    >
  >;
};

export type UpdateLaborCostMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateLaborCostInputInput;
}>;

export type UpdateLaborCostMutation = { __typename?: 'Mutation' } & {
  updateLaborCost?: Maybe<
    { __typename?: 'LaborCost' } & Pick<
      LaborCost,
      'hourlyWage' | 'id' | 'startDateTime' | 'versionNo'
    >
  >;
};

export type UpdateLicenceCountMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: Maybe<UpdateLicenceCountInputInput>;
}>;

export type UpdateLicenceCountMutation = { __typename?: 'Mutation' } & {
  updateLicenceCount?: Maybe<
    { __typename?: 'Contract' } & Pick<
      Contract,
      | 'billToMailAddress'
      | 'billingType'
      | 'contractPreriod'
      | 'freeTrialExpireDatetime'
      | 'id'
      | 'licenceCount'
      | 'revenuecatOriginalAppUserId'
      | 'usingLicenceCount'
      | 'versionNo'
    > & {
        freeLicenceCoupon?: Maybe<
          { __typename?: 'RangeFreeLicenceCoupon' } & Pick<
            RangeFreeLicenceCoupon,
            'freeEndLicenceCount' | 'freeStartLicenceCount' | 'id'
          >
        >;
        plan: { __typename?: 'PlanProduct' } & Pick<PlanProduct, 'code' | 'contractPreriod' | 'id'>;
      }
  >;
};

export type UpdateMemberProfileMutationVariables = Exact<{
  input: UpdateMemberProfileInputInput;
}>;

export type UpdateMemberProfileMutation = { __typename?: 'Mutation' } & {
  updateMemberProfile?: Maybe<
    { __typename?: 'Member' } & Pick<
      Member,
      | 'adminRole'
      | 'clientManagementRole'
      | 'department'
      | 'employeeNumber'
      | 'id'
      | 'laborCostManagementRole'
      | 'mailAddress'
      | 'memberManagementRole'
      | 'memberRegistrationDateTime'
      | 'memberStatus'
      | 'name'
      | 'organizationMemberViewRole'
      | 'organizationReportRole'
      | 'profileImageUrl'
      | 'projectCreatePermissionFlg'
      | 'projectCustomAttributePermissionFlg'
      | 'projectDeletePermissionFlg'
      | 'projectUpdatePermissionFlg'
      | 'proxyEditWorkingDataRole'
      | 'salesManagementRole'
      | 'statusMessage'
      | 'taskCreatePermissionFlg'
      | 'taskCustomAttributePermissionFlg'
      | 'taskDeletePermissionFlg'
      | 'taskUpdatePermissionFlg'
      | 'teamMemberViewRole'
      | 'teamReportRole'
      | 'versionNo'
    >
  >;
};

export type UpdateMemberRoleMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateMemberRoleInputInput;
}>;

export type UpdateMemberRoleMutation = { __typename?: 'Mutation' } & {
  updateMemberRole?: Maybe<
    { __typename?: 'Member' } & Pick<
      Member,
      | 'adminRole'
      | 'clientManagementRole'
      | 'department'
      | 'employeeNumber'
      | 'id'
      | 'laborCostManagementRole'
      | 'mailAddress'
      | 'memberManagementRole'
      | 'memberRegistrationDateTime'
      | 'memberStatus'
      | 'name'
      | 'organizationMemberViewRole'
      | 'organizationReportRole'
      | 'profileImageUrl'
      | 'projectCreatePermissionFlg'
      | 'projectCustomAttributePermissionFlg'
      | 'projectDeletePermissionFlg'
      | 'projectUpdatePermissionFlg'
      | 'proxyEditWorkingDataRole'
      | 'salesManagementRole'
      | 'statusMessage'
      | 'taskCreatePermissionFlg'
      | 'taskCustomAttributePermissionFlg'
      | 'taskDeletePermissionFlg'
      | 'taskUpdatePermissionFlg'
      | 'teamMemberViewRole'
      | 'teamReportRole'
      | 'versionNo'
    >
  >;
};

export type UpdateMemberStatusMessageMutationVariables = Exact<{
  input: UpdateMemberStatusMessageInputInput;
}>;

export type UpdateMemberStatusMessageMutation = { __typename?: 'Mutation' } & {
  updateMemberStatusMessage?: Maybe<
    { __typename?: 'Member' } & Pick<
      Member,
      | 'adminRole'
      | 'clientManagementRole'
      | 'department'
      | 'employeeNumber'
      | 'id'
      | 'laborCostManagementRole'
      | 'mailAddress'
      | 'memberManagementRole'
      | 'memberRegistrationDateTime'
      | 'memberStatus'
      | 'name'
      | 'organizationMemberViewRole'
      | 'organizationReportRole'
      | 'profileImageUrl'
      | 'projectCreatePermissionFlg'
      | 'projectCustomAttributePermissionFlg'
      | 'projectDeletePermissionFlg'
      | 'projectUpdatePermissionFlg'
      | 'proxyEditWorkingDataRole'
      | 'salesManagementRole'
      | 'statusMessage'
      | 'taskCreatePermissionFlg'
      | 'taskCustomAttributePermissionFlg'
      | 'taskDeletePermissionFlg'
      | 'taskUpdatePermissionFlg'
      | 'teamMemberViewRole'
      | 'teamReportRole'
      | 'versionNo'
    >
  >;
};

export type UpdateMeteredRateContractMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateMeterdRateContractInputInput;
}>;

export type UpdateMeteredRateContractMutation = { __typename?: 'Mutation' } & {
  updateMeteredRateContract?: Maybe<
    { __typename?: 'MeteredRateContract' } & Pick<
      MeteredRateContract,
      'hourUnitPrice' | 'id' | 'versionNo'
    > & {
        member?: Maybe<
          { __typename?: 'MeteredRateContractMemberPart' } & Pick<
            MeteredRateContractMemberPart,
            | 'laborCostWithoutTax'
            | 'memberId'
            | 'name'
            | 'profileImageUrl'
            | 'roundedTotalWorkingTimeSec'
            | 'salesAmountWithoutTax'
            | 'totalWorkingTimeSec'
          >
        >;
      }
  >;
};

export type UpdateMySettingsMutationVariables = Exact<{
  input: UpdateMemberSettingsInputInput;
}>;

export type UpdateMySettingsMutation = { __typename?: 'Mutation' } & {
  updateMySettings?: Maybe<
    { __typename?: 'MemberSettings' } & Pick<
      MemberSettings,
      | 'allowExportCalendar'
      | 'autoAssignWhenTaskStart'
      | 'calendarStartDayOfWeek'
      | 'copyWorkingScheduleMemoToHistory'
      | 'id'
      | 'initialViewTypeCode'
      | 'longTimeWorkingAlertMinutes'
      | 'projectInitialViewTypeCode'
      | 'twoFactorAuthEnabled'
      | 'versionNo'
    > & {
        member: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
      }
  >;
};

export type UpdateNotificationToReadMutationVariables = Exact<{
  id?: Maybe<Scalars['String']>;
}>;

export type UpdateNotificationToReadMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'updateNotificationToRead'
>;

export type UpdateNotificationToReadV2MutationVariables = Exact<{
  id?: Maybe<Scalars['String']>;
}>;

export type UpdateNotificationToReadV2Mutation = { __typename?: 'Mutation' } & {
  updateNotificationToReadV2?: Maybe<
    { __typename?: 'NotificationV2' } & Pick<
      NotificationV2,
      'id' | 'notificationType' | 'readDateTime' | 'receivedDateTime' | 'sendDateTime'
    > & {
        mention?: Maybe<
          { __typename?: 'MentionV2' } & Pick<
            MentionV2,
            | 'commentId'
            | 'id'
            | 'mentionType'
            | 'message'
            | 'projectId'
            | 'projectName'
            | 'taskId'
            | 'taskTitle'
            | 'teamId'
            | 'teamName'
          > & {
              sendMember: { __typename?: 'NotificationV2MemberPart' } & Pick<
                NotificationV2MemberPart,
                'id' | 'name' | 'profileImageUrl'
              >;
            }
        >;
        projectAssignNotification?: Maybe<
          { __typename?: 'ProjectAssignNotificationV2' } & Pick<
            ProjectAssignNotificationV2,
            'id' | 'projectId' | 'projectName' | 'teamId' | 'teamName'
          > & {
              sendMember: { __typename?: 'NotificationV2MemberPart' } & Pick<
                NotificationV2MemberPart,
                'id' | 'name' | 'profileImageUrl'
              >;
            }
        >;
        targetMember: { __typename?: 'NotificationV2MemberPart' } & Pick<
          NotificationV2MemberPart,
          'id' | 'name' | 'profileImageUrl'
        >;
        taskAssignNotification?: Maybe<
          { __typename?: 'TaskAssignNotificationV2' } & Pick<
            TaskAssignNotificationV2,
            'id' | 'projectId' | 'projectName' | 'taskId' | 'taskTitle' | 'teamId' | 'teamName'
          > & {
              sendMember: { __typename?: 'NotificationV2MemberPart' } & Pick<
                NotificationV2MemberPart,
                'id' | 'name' | 'profileImageUrl'
              >;
            }
        >;
        taskRemainderNotification?: Maybe<
          { __typename?: 'TaskRemainderNotificationV2' } & Pick<
            TaskRemainderNotificationV2,
            | 'id'
            | 'projectId'
            | 'projectName'
            | 'scheduledEndDateTime'
            | 'taskId'
            | 'taskRemainderType'
            | 'taskTitle'
            | 'teamId'
            | 'teamName'
          >
        >;
      }
  >;
};

export type UpdateNotificationToReceivedMutationVariables = Exact<{
  id?: Maybe<Scalars['String']>;
}>;

export type UpdateNotificationToReceivedMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'updateNotificationToReceived'
>;

export type UpdateNotificationToReceivedV2MutationVariables = Exact<{
  id?: Maybe<Scalars['String']>;
}>;

export type UpdateNotificationToReceivedV2Mutation = { __typename?: 'Mutation' } & {
  updateNotificationToReceivedV2?: Maybe<
    { __typename?: 'NotificationV2' } & Pick<
      NotificationV2,
      'id' | 'notificationType' | 'readDateTime' | 'receivedDateTime' | 'sendDateTime'
    > & {
        mention?: Maybe<
          { __typename?: 'MentionV2' } & Pick<
            MentionV2,
            | 'commentId'
            | 'id'
            | 'mentionType'
            | 'message'
            | 'projectId'
            | 'projectName'
            | 'taskId'
            | 'taskTitle'
            | 'teamId'
            | 'teamName'
          > & {
              sendMember: { __typename?: 'NotificationV2MemberPart' } & Pick<
                NotificationV2MemberPart,
                'id' | 'name' | 'profileImageUrl'
              >;
            }
        >;
        projectAssignNotification?: Maybe<
          { __typename?: 'ProjectAssignNotificationV2' } & Pick<
            ProjectAssignNotificationV2,
            'id' | 'projectId' | 'projectName' | 'teamId' | 'teamName'
          > & {
              sendMember: { __typename?: 'NotificationV2MemberPart' } & Pick<
                NotificationV2MemberPart,
                'id' | 'name' | 'profileImageUrl'
              >;
            }
        >;
        targetMember: { __typename?: 'NotificationV2MemberPart' } & Pick<
          NotificationV2MemberPart,
          'id' | 'name' | 'profileImageUrl'
        >;
        taskAssignNotification?: Maybe<
          { __typename?: 'TaskAssignNotificationV2' } & Pick<
            TaskAssignNotificationV2,
            'id' | 'projectId' | 'projectName' | 'taskId' | 'taskTitle' | 'teamId' | 'teamName'
          > & {
              sendMember: { __typename?: 'NotificationV2MemberPart' } & Pick<
                NotificationV2MemberPart,
                'id' | 'name' | 'profileImageUrl'
              >;
            }
        >;
        taskRemainderNotification?: Maybe<
          { __typename?: 'TaskRemainderNotificationV2' } & Pick<
            TaskRemainderNotificationV2,
            | 'id'
            | 'projectId'
            | 'projectName'
            | 'scheduledEndDateTime'
            | 'taskId'
            | 'taskRemainderType'
            | 'taskTitle'
            | 'teamId'
            | 'teamName'
          >
        >;
      }
  >;
};

export type UpdateOrganizationMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateOrganizationInputInput;
}>;

export type UpdateOrganizationMutation = { __typename?: 'Mutation' } & {
  updateOrganization?: Maybe<
    { __typename?: 'Organization' } & Pick<
      Organization,
      | 'allowConnectThirdPartyCalendar'
      | 'contactMailAddress'
      | 'contractPreriod'
      | 'id'
      | 'imageUrl'
      | 'ipRestrictionFlg'
      | 'name'
      | 'requiredTwoFactorAuth'
      | 'suspensionFlg'
      | 'versionNo'
    > & {
        plan: { __typename?: 'PlanProduct' } & Pick<PlanProduct, 'code' | 'contractPreriod' | 'id'>;
      }
  >;
};

export type UpdateOrganizationImageMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateOrganizationImageInputInput;
}>;

export type UpdateOrganizationImageMutation = { __typename?: 'Mutation' } & {
  updateOrganizationImage?: Maybe<
    { __typename?: 'Organization' } & Pick<
      Organization,
      | 'allowConnectThirdPartyCalendar'
      | 'contactMailAddress'
      | 'contractPreriod'
      | 'id'
      | 'imageUrl'
      | 'ipRestrictionFlg'
      | 'name'
      | 'requiredTwoFactorAuth'
      | 'suspensionFlg'
      | 'versionNo'
    > & {
        plan: { __typename?: 'PlanProduct' } & Pick<PlanProduct, 'code' | 'contractPreriod' | 'id'>;
      }
  >;
};

export type UpdatePasswordMutationVariables = Exact<{
  input?: Maybe<UpdatePasswordInputInput>;
}>;

export type UpdatePasswordMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'updatePassword'>;

export type UpdateProfileImageMutationVariables = Exact<{
  input: UpdateProfileImageInputInput;
}>;

export type UpdateProfileImageMutation = { __typename?: 'Mutation' } & {
  updateProfileImage?: Maybe<
    { __typename?: 'Member' } & Pick<
      Member,
      | 'adminRole'
      | 'clientManagementRole'
      | 'department'
      | 'employeeNumber'
      | 'id'
      | 'laborCostManagementRole'
      | 'mailAddress'
      | 'memberManagementRole'
      | 'memberRegistrationDateTime'
      | 'memberStatus'
      | 'name'
      | 'organizationMemberViewRole'
      | 'organizationReportRole'
      | 'profileImageUrl'
      | 'projectCreatePermissionFlg'
      | 'projectCustomAttributePermissionFlg'
      | 'projectDeletePermissionFlg'
      | 'projectUpdatePermissionFlg'
      | 'proxyEditWorkingDataRole'
      | 'salesManagementRole'
      | 'statusMessage'
      | 'taskCreatePermissionFlg'
      | 'taskCustomAttributePermissionFlg'
      | 'taskDeletePermissionFlg'
      | 'taskUpdatePermissionFlg'
      | 'teamMemberViewRole'
      | 'teamReportRole'
      | 'versionNo'
    >
  >;
};

export type UpdateProjectMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: Maybe<UpdateProjectInputInput>;
}>;

export type UpdateProjectMutation = { __typename?: 'Mutation' } & {
  updateProject?: Maybe<
    { __typename?: 'Project' } & Pick<
      Project,
      | 'calendarLinkageCode'
      | 'color'
      | 'complete'
      | 'completeDateTime'
      | 'costBudgetWithoutTax'
      | 'description'
      | 'estimateTimeSec'
      | 'id'
      | 'key'
      | 'laborCost'
      | 'laborCostWithoutTax'
      | 'laborShare'
      | 'name'
      | 'priority'
      | 'registerDateTime'
      | 'salesAmountWithoutTax'
      | 'scheduledEndDateTime'
      | 'scheduledStartDateTime'
      | 'sortNoInGanttChart'
      | 'sortNoInList'
      | 'sortNoInProjectStatus'
      | 'totalScheduleTimeSec'
      | 'versionNo'
      | 'workingTimeSec'
    > & {
        assignees: Array<
          { __typename?: 'AssignedProjectMemberInfo' } & Pick<
            AssignedProjectMemberInfo,
            'sortNo'
          > & {
              member: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
            }
        >;
        client?: Maybe<
          { __typename?: 'Client' } & Pick<Client, 'code' | 'id' | 'name' | 'sortNo' | 'versionNo'>
        >;
        createdMember?: Maybe<
          { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >
        >;
        favoriteMembers: Array<
          Maybe<
            { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >
          >
        >;
        favoriteMembersInfo: Array<
          Maybe<
            { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
              FavoriteProjectMemberInfo,
              'sortNo'
            > & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
        projectStatus: { __typename?: 'ProjectStatus' } & Pick<
          ProjectStatus,
          'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
        >;
        team: { __typename?: 'Team' } & Pick<
          Team,
          'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
        > & {
            organization: { __typename?: 'Organization' } & Pick<
              Organization,
              | 'allowConnectThirdPartyCalendar'
              | 'contactMailAddress'
              | 'contractPreriod'
              | 'id'
              | 'imageUrl'
              | 'ipRestrictionFlg'
              | 'name'
              | 'requiredTwoFactorAuth'
              | 'suspensionFlg'
              | 'versionNo'
            > & {
                plan: { __typename?: 'PlanProduct' } & Pick<
                  PlanProduct,
                  'code' | 'contractPreriod' | 'id'
                >;
              };
          };
      }
  >;
};

export type UpdateProjectContractMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateProjectContractInputInput;
}>;

export type UpdateProjectContractMutation = { __typename?: 'Mutation' } & {
  updateProjectContract?: Maybe<
    { __typename?: 'ProjectContract' } & Pick<
      ProjectContract,
      | 'contractType'
      | 'costBudgetWithoutTax'
      | 'endDateTime'
      | 'fixSalesAmountWithoutTax'
      | 'grossProfit'
      | 'grossProfitRatePercent'
      | 'id'
      | 'laborCostWithoutTax'
      | 'laborShare'
      | 'month'
      | 'roundTimeType'
      | 'roundedTotalWorkingTimeSec'
      | 'salesAmountWithoutTax'
      | 'salesRoundMinutes'
      | 'startDateTime'
      | 'taxRate'
      | 'termType'
      | 'totalWorkingTimeSec'
      | 'unitPriceType'
      | 'versionNo'
      | 'year'
    > & {
        allMemberMeteredRateContract?: Maybe<
          { __typename?: 'MeteredRateContract' } & Pick<
            MeteredRateContract,
            'hourUnitPrice' | 'id' | 'versionNo'
          > & {
              member?: Maybe<
                { __typename?: 'MeteredRateContractMemberPart' } & Pick<
                  MeteredRateContractMemberPart,
                  | 'laborCostWithoutTax'
                  | 'memberId'
                  | 'name'
                  | 'profileImageUrl'
                  | 'roundedTotalWorkingTimeSec'
                  | 'salesAmountWithoutTax'
                  | 'totalWorkingTimeSec'
                >
              >;
            }
        >;
        allMemberSettlementRangeContract?: Maybe<
          { __typename?: 'SettlementRangeContract' } & Pick<
            SettlementRangeContract,
            'id' | 'monthlyUnitPrice' | 'versionNo'
          > & {
              lowerHourPart: { __typename?: 'LowerPart' } & Pick<
                LowerPart,
                'deducationHourlyUnitPrice' | 'lowerHour'
              >;
              member?: Maybe<
                { __typename?: 'SettlementRangeContractMemberPart' } & Pick<
                  SettlementRangeContractMemberPart,
                  | 'laborCostWithoutTax'
                  | 'memberId'
                  | 'name'
                  | 'profileImageUrl'
                  | 'roundedTotalWorkingTimeSec'
                  | 'salesAmountWithoutTax'
                  | 'totalWorkingTimeSec'
                >
              >;
              upperHourPart: { __typename?: 'UpperPart' } & Pick<
                UpperPart,
                'excessHourlyUnitPrice' | 'upperHour'
              >;
            }
        >;
        membersMeteredRateContract?: Maybe<
          Array<
            Maybe<
              { __typename?: 'MeteredRateContract' } & Pick<
                MeteredRateContract,
                'hourUnitPrice' | 'id' | 'versionNo'
              > & {
                  member?: Maybe<
                    { __typename?: 'MeteredRateContractMemberPart' } & Pick<
                      MeteredRateContractMemberPart,
                      | 'laborCostWithoutTax'
                      | 'memberId'
                      | 'name'
                      | 'profileImageUrl'
                      | 'roundedTotalWorkingTimeSec'
                      | 'salesAmountWithoutTax'
                      | 'totalWorkingTimeSec'
                    >
                  >;
                }
            >
          >
        >;
        membersSettlementRangeContract?: Maybe<
          Array<
            Maybe<
              { __typename?: 'SettlementRangeContract' } & Pick<
                SettlementRangeContract,
                'id' | 'monthlyUnitPrice' | 'versionNo'
              > & {
                  lowerHourPart: { __typename?: 'LowerPart' } & Pick<
                    LowerPart,
                    'deducationHourlyUnitPrice' | 'lowerHour'
                  >;
                  member?: Maybe<
                    { __typename?: 'SettlementRangeContractMemberPart' } & Pick<
                      SettlementRangeContractMemberPart,
                      | 'laborCostWithoutTax'
                      | 'memberId'
                      | 'name'
                      | 'profileImageUrl'
                      | 'roundedTotalWorkingTimeSec'
                      | 'salesAmountWithoutTax'
                      | 'totalWorkingTimeSec'
                    >
                  >;
                  upperHourPart: { __typename?: 'UpperPart' } & Pick<
                    UpperPart,
                    'excessHourlyUnitPrice' | 'upperHour'
                  >;
                }
            >
          >
        >;
        project?: Maybe<
          { __typename?: 'Project' } & Pick<
            Project,
            | 'calendarLinkageCode'
            | 'color'
            | 'complete'
            | 'completeDateTime'
            | 'costBudgetWithoutTax'
            | 'description'
            | 'estimateTimeSec'
            | 'id'
            | 'key'
            | 'laborCost'
            | 'laborCostWithoutTax'
            | 'laborShare'
            | 'name'
            | 'priority'
            | 'registerDateTime'
            | 'salesAmountWithoutTax'
            | 'scheduledEndDateTime'
            | 'scheduledStartDateTime'
            | 'sortNoInGanttChart'
            | 'sortNoInList'
            | 'sortNoInProjectStatus'
            | 'totalScheduleTimeSec'
            | 'versionNo'
            | 'workingTimeSec'
          > & {
              assignees: Array<
                { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                  AssignedProjectMemberInfo,
                  'sortNo'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >;
              client?: Maybe<
                { __typename?: 'Client' } & Pick<
                  Client,
                  'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                >
              >;
              createdMember?: Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >;
              favoriteMembers: Array<
                Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >
              >;
              favoriteMembersInfo: Array<
                Maybe<
                  { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                    FavoriteProjectMemberInfo,
                    'sortNo'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >
              >;
              projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                ProjectStatus,
                'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
              >;
              team: { __typename?: 'Team' } & Pick<
                Team,
                'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
              > & {
                  organization: { __typename?: 'Organization' } & Pick<
                    Organization,
                    | 'allowConnectThirdPartyCalendar'
                    | 'contactMailAddress'
                    | 'contractPreriod'
                    | 'id'
                    | 'imageUrl'
                    | 'ipRestrictionFlg'
                    | 'name'
                    | 'requiredTwoFactorAuth'
                    | 'suspensionFlg'
                    | 'versionNo'
                  > & {
                      plan: { __typename?: 'PlanProduct' } & Pick<
                        PlanProduct,
                        'code' | 'contractPreriod' | 'id'
                      >;
                    };
                };
            }
        >;
        timeZone: { __typename?: 'TimeZone' } & Pick<
          TimeZone,
          'code' | 'id' | 'name' | 'offsetTime' | 'sortNo'
        >;
      }
  >;
};

export type UpdateProjectCustomAttributeMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateProjectCustomAttributeInputInput;
}>;

export type UpdateProjectCustomAttributeMutation = { __typename?: 'Mutation' } & {
  updateProjectCustomAttribute?: Maybe<
    { __typename?: 'ProjectCustomAttribute' } & Pick<
      ProjectCustomAttribute,
      'id' | 'sortNo' | 'value'
    > & {
        listItem?: Maybe<
          { __typename?: 'ProjectCustomAttributeMasterListItem' } & Pick<
            ProjectCustomAttributeMasterListItem,
            'id' | 'sortNo' | 'value' | 'versionNo'
          >
        >;
        master: { __typename?: 'ProjectCustomAttributeMaster' } & Pick<
          ProjectCustomAttributeMaster,
          'id' | 'name' | 'required' | 'sortNo' | 'type' | 'versionNo'
        > & {
            listItem: Array<
              Maybe<
                { __typename?: 'ProjectCustomAttributeMasterListItem' } & Pick<
                  ProjectCustomAttributeMasterListItem,
                  'id' | 'sortNo' | 'value' | 'versionNo'
                >
              >
            >;
          };
      }
  >;
};

export type UpdateProjectCustomAttributeMasterMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateProjectCustomAttributeMasterInputInput;
}>;

export type UpdateProjectCustomAttributeMasterMutation = { __typename?: 'Mutation' } & {
  updateProjectCustomAttributeMaster?: Maybe<
    { __typename?: 'ProjectCustomAttributeMaster' } & Pick<
      ProjectCustomAttributeMaster,
      'id' | 'name' | 'required' | 'sortNo' | 'type' | 'versionNo'
    > & {
        listItem: Array<
          Maybe<
            { __typename?: 'ProjectCustomAttributeMasterListItem' } & Pick<
              ProjectCustomAttributeMasterListItem,
              'id' | 'sortNo' | 'value' | 'versionNo'
            >
          >
        >;
      }
  >;
};

export type UpdateProjectCustomAttributeMasterListItemMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateProjectCustomAttributeMasterListItemInputInput;
}>;

export type UpdateProjectCustomAttributeMasterListItemMutation = { __typename?: 'Mutation' } & {
  updateProjectCustomAttributeMasterListItem?: Maybe<
    { __typename?: 'ProjectCustomAttributeMasterListItem' } & Pick<
      ProjectCustomAttributeMasterListItem,
      'id' | 'sortNo' | 'value' | 'versionNo'
    >
  >;
};

export type UpdateProjectSortNoInAssignedProjectMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: Maybe<UpdateProjectSortNoInAssignedProjectInputInput>;
}>;

export type UpdateProjectSortNoInAssignedProjectMutation = { __typename?: 'Mutation' } & {
  updateProjectSortNoInAssignedProject?: Maybe<
    { __typename?: 'Project' } & Pick<
      Project,
      | 'calendarLinkageCode'
      | 'color'
      | 'complete'
      | 'completeDateTime'
      | 'costBudgetWithoutTax'
      | 'description'
      | 'estimateTimeSec'
      | 'id'
      | 'key'
      | 'laborCost'
      | 'laborCostWithoutTax'
      | 'laborShare'
      | 'name'
      | 'priority'
      | 'registerDateTime'
      | 'salesAmountWithoutTax'
      | 'scheduledEndDateTime'
      | 'scheduledStartDateTime'
      | 'sortNoInGanttChart'
      | 'sortNoInList'
      | 'sortNoInProjectStatus'
      | 'totalScheduleTimeSec'
      | 'versionNo'
      | 'workingTimeSec'
    > & {
        assignees: Array<
          { __typename?: 'AssignedProjectMemberInfo' } & Pick<
            AssignedProjectMemberInfo,
            'sortNo'
          > & {
              member: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
            }
        >;
        client?: Maybe<
          { __typename?: 'Client' } & Pick<Client, 'code' | 'id' | 'name' | 'sortNo' | 'versionNo'>
        >;
        createdMember?: Maybe<
          { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >
        >;
        favoriteMembers: Array<
          Maybe<
            { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >
          >
        >;
        favoriteMembersInfo: Array<
          Maybe<
            { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
              FavoriteProjectMemberInfo,
              'sortNo'
            > & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
        projectStatus: { __typename?: 'ProjectStatus' } & Pick<
          ProjectStatus,
          'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
        >;
        team: { __typename?: 'Team' } & Pick<
          Team,
          'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
        > & {
            organization: { __typename?: 'Organization' } & Pick<
              Organization,
              | 'allowConnectThirdPartyCalendar'
              | 'contactMailAddress'
              | 'contractPreriod'
              | 'id'
              | 'imageUrl'
              | 'ipRestrictionFlg'
              | 'name'
              | 'requiredTwoFactorAuth'
              | 'suspensionFlg'
              | 'versionNo'
            > & {
                plan: { __typename?: 'PlanProduct' } & Pick<
                  PlanProduct,
                  'code' | 'contractPreriod' | 'id'
                >;
              };
          };
      }
  >;
};

export type UpdateProjectSortNoInFavoriteProjectMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: Maybe<UpdateProjectSortNoInFavoriteProjectInputInput>;
}>;

export type UpdateProjectSortNoInFavoriteProjectMutation = { __typename?: 'Mutation' } & {
  updateProjectSortNoInFavoriteProject?: Maybe<
    { __typename?: 'Project' } & Pick<
      Project,
      | 'calendarLinkageCode'
      | 'color'
      | 'complete'
      | 'completeDateTime'
      | 'costBudgetWithoutTax'
      | 'description'
      | 'estimateTimeSec'
      | 'id'
      | 'key'
      | 'laborCost'
      | 'laborCostWithoutTax'
      | 'laborShare'
      | 'name'
      | 'priority'
      | 'registerDateTime'
      | 'salesAmountWithoutTax'
      | 'scheduledEndDateTime'
      | 'scheduledStartDateTime'
      | 'sortNoInGanttChart'
      | 'sortNoInList'
      | 'sortNoInProjectStatus'
      | 'totalScheduleTimeSec'
      | 'versionNo'
      | 'workingTimeSec'
    > & {
        assignees: Array<
          { __typename?: 'AssignedProjectMemberInfo' } & Pick<
            AssignedProjectMemberInfo,
            'sortNo'
          > & {
              member: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
            }
        >;
        client?: Maybe<
          { __typename?: 'Client' } & Pick<Client, 'code' | 'id' | 'name' | 'sortNo' | 'versionNo'>
        >;
        createdMember?: Maybe<
          { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >
        >;
        favoriteMembers: Array<
          Maybe<
            { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >
          >
        >;
        favoriteMembersInfo: Array<
          Maybe<
            { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
              FavoriteProjectMemberInfo,
              'sortNo'
            > & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
        projectStatus: { __typename?: 'ProjectStatus' } & Pick<
          ProjectStatus,
          'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
        >;
        team: { __typename?: 'Team' } & Pick<
          Team,
          'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
        > & {
            organization: { __typename?: 'Organization' } & Pick<
              Organization,
              | 'allowConnectThirdPartyCalendar'
              | 'contactMailAddress'
              | 'contractPreriod'
              | 'id'
              | 'imageUrl'
              | 'ipRestrictionFlg'
              | 'name'
              | 'requiredTwoFactorAuth'
              | 'suspensionFlg'
              | 'versionNo'
            > & {
                plan: { __typename?: 'PlanProduct' } & Pick<
                  PlanProduct,
                  'code' | 'contractPreriod' | 'id'
                >;
              };
          };
      }
  >;
};

export type UpdateProjectSortNoInListMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: Maybe<UpdateProjectSortNoInListInputInput>;
}>;

export type UpdateProjectSortNoInListMutation = { __typename?: 'Mutation' } & {
  updateProjectSortNoInList?: Maybe<
    { __typename?: 'Project' } & Pick<
      Project,
      | 'calendarLinkageCode'
      | 'color'
      | 'complete'
      | 'completeDateTime'
      | 'costBudgetWithoutTax'
      | 'description'
      | 'estimateTimeSec'
      | 'id'
      | 'key'
      | 'laborCost'
      | 'laborCostWithoutTax'
      | 'laborShare'
      | 'name'
      | 'priority'
      | 'registerDateTime'
      | 'salesAmountWithoutTax'
      | 'scheduledEndDateTime'
      | 'scheduledStartDateTime'
      | 'sortNoInGanttChart'
      | 'sortNoInList'
      | 'sortNoInProjectStatus'
      | 'totalScheduleTimeSec'
      | 'versionNo'
      | 'workingTimeSec'
    > & {
        assignees: Array<
          { __typename?: 'AssignedProjectMemberInfo' } & Pick<
            AssignedProjectMemberInfo,
            'sortNo'
          > & {
              member: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
            }
        >;
        client?: Maybe<
          { __typename?: 'Client' } & Pick<Client, 'code' | 'id' | 'name' | 'sortNo' | 'versionNo'>
        >;
        createdMember?: Maybe<
          { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >
        >;
        favoriteMembers: Array<
          Maybe<
            { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >
          >
        >;
        favoriteMembersInfo: Array<
          Maybe<
            { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
              FavoriteProjectMemberInfo,
              'sortNo'
            > & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
        projectStatus: { __typename?: 'ProjectStatus' } & Pick<
          ProjectStatus,
          'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
        >;
        team: { __typename?: 'Team' } & Pick<
          Team,
          'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
        > & {
            organization: { __typename?: 'Organization' } & Pick<
              Organization,
              | 'allowConnectThirdPartyCalendar'
              | 'contactMailAddress'
              | 'contractPreriod'
              | 'id'
              | 'imageUrl'
              | 'ipRestrictionFlg'
              | 'name'
              | 'requiredTwoFactorAuth'
              | 'suspensionFlg'
              | 'versionNo'
            > & {
                plan: { __typename?: 'PlanProduct' } & Pick<
                  PlanProduct,
                  'code' | 'contractPreriod' | 'id'
                >;
              };
          };
      }
  >;
};

export type UpdateProjectStatusMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateProjectStatusInputInput;
}>;

export type UpdateProjectStatusMutation = { __typename?: 'Mutation' } & {
  updateProjectStatus?: Maybe<
    { __typename?: 'ProjectStatus' } & Pick<
      ProjectStatus,
      'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
    >
  >;
};

export type UpdateProjectTemplateMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: Maybe<UpdateProjectTemplateInputInput>;
}>;

export type UpdateProjectTemplateMutation = { __typename?: 'Mutation' } & {
  updateProjectTemplate?: Maybe<
    { __typename?: 'ProjectTemplate' } & Pick<
      ProjectTemplate,
      'description' | 'estimateTimeSec' | 'id' | 'name' | 'priority' | 'sortNoInList' | 'versionNo'
    > & {
        client?: Maybe<
          { __typename?: 'Client' } & Pick<Client, 'code' | 'id' | 'name' | 'sortNo' | 'versionNo'>
        >;
      }
  >;
};

export type UpdateProjectTemplateCustomAttributeMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateProjectTemplateCustomAttributeInputInput;
}>;

export type UpdateProjectTemplateCustomAttributeMutation = { __typename?: 'Mutation' } & {
  updateProjectTemplateCustomAttribute?: Maybe<
    { __typename?: 'ProjectTemplateCustomAttribute' } & Pick<
      ProjectTemplateCustomAttribute,
      'id' | 'sortNo' | 'value'
    > & {
        listItem?: Maybe<
          { __typename?: 'ProjectCustomAttributeMasterListItem' } & Pick<
            ProjectCustomAttributeMasterListItem,
            'id' | 'sortNo' | 'value' | 'versionNo'
          >
        >;
        master: { __typename?: 'ProjectCustomAttributeMaster' } & Pick<
          ProjectCustomAttributeMaster,
          'id' | 'name' | 'required' | 'sortNo' | 'type' | 'versionNo'
        > & {
            listItem: Array<
              Maybe<
                { __typename?: 'ProjectCustomAttributeMasterListItem' } & Pick<
                  ProjectCustomAttributeMasterListItem,
                  'id' | 'sortNo' | 'value' | 'versionNo'
                >
              >
            >;
          };
      }
  >;
};

export type UpdateProjectTemplateSortNoInListMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: Maybe<UpdateProjectTemplateSortNoInListInputInput>;
}>;

export type UpdateProjectTemplateSortNoInListMutation = { __typename?: 'Mutation' } & {
  updateProjectTemplateSortNoInList?: Maybe<
    { __typename?: 'ProjectTemplate' } & Pick<
      ProjectTemplate,
      'description' | 'estimateTimeSec' | 'id' | 'name' | 'priority' | 'sortNoInList' | 'versionNo'
    > & {
        client?: Maybe<
          { __typename?: 'Client' } & Pick<Client, 'code' | 'id' | 'name' | 'sortNo' | 'versionNo'>
        >;
      }
  >;
};

export type UpdateProjectTemplateSubTaskMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateProjectTemplateSubTaskInputInput;
}>;

export type UpdateProjectTemplateSubTaskMutation = { __typename?: 'Mutation' } & {
  updateProjectTemplateSubTask?: Maybe<
    { __typename?: 'ProjectTemplateSubTask' } & Pick<
      ProjectTemplateSubTask,
      'id' | 'sortNo' | 'title' | 'versionNo'
    > & {
        createdMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
        projectTemplateTask: { __typename?: 'ProjectTemplateTask' } & Pick<
          ProjectTemplateTask,
          | 'description'
          | 'estimateTimeSec'
          | 'id'
          | 'priority'
          | 'sortNoInList'
          | 'sortNoInTaskStatus'
          | 'title'
          | 'versionNo'
        > & {
            createdMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
            projectTemplate: { __typename?: 'ProjectTemplate' } & Pick<
              ProjectTemplate,
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'name'
              | 'priority'
              | 'sortNoInList'
              | 'versionNo'
            > & {
                client?: Maybe<
                  { __typename?: 'Client' } & Pick<
                    Client,
                    'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                  >
                >;
              };
            projectTemplateTaskStatus: { __typename?: 'ProjectTemplateTaskStatus' } & Pick<
              ProjectTemplateTaskStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
            > & {
                projectTemplate: { __typename?: 'ProjectTemplate' } & Pick<
                  ProjectTemplate,
                  | 'description'
                  | 'estimateTimeSec'
                  | 'id'
                  | 'name'
                  | 'priority'
                  | 'sortNoInList'
                  | 'versionNo'
                >;
              };
          };
      }
  >;
};

export type UpdateProjectTemplateSubTaskSortNoMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateProjectTemplateSubTaskSortNoInputInput;
}>;

export type UpdateProjectTemplateSubTaskSortNoMutation = { __typename?: 'Mutation' } & {
  updateProjectTemplateSubTaskSortNo?: Maybe<
    { __typename?: 'ProjectTemplateSubTask' } & Pick<
      ProjectTemplateSubTask,
      'id' | 'sortNo' | 'title' | 'versionNo'
    > & {
        createdMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
        projectTemplateTask: { __typename?: 'ProjectTemplateTask' } & Pick<
          ProjectTemplateTask,
          | 'description'
          | 'estimateTimeSec'
          | 'id'
          | 'priority'
          | 'sortNoInList'
          | 'sortNoInTaskStatus'
          | 'title'
          | 'versionNo'
        > & {
            createdMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
            projectTemplate: { __typename?: 'ProjectTemplate' } & Pick<
              ProjectTemplate,
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'name'
              | 'priority'
              | 'sortNoInList'
              | 'versionNo'
            > & {
                client?: Maybe<
                  { __typename?: 'Client' } & Pick<
                    Client,
                    'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                  >
                >;
              };
            projectTemplateTaskStatus: { __typename?: 'ProjectTemplateTaskStatus' } & Pick<
              ProjectTemplateTaskStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
            > & {
                projectTemplate: { __typename?: 'ProjectTemplate' } & Pick<
                  ProjectTemplate,
                  | 'description'
                  | 'estimateTimeSec'
                  | 'id'
                  | 'name'
                  | 'priority'
                  | 'sortNoInList'
                  | 'versionNo'
                >;
              };
          };
      }
  >;
};

export type UpdateProjectTemplateTaskMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateProjectTemplateTaskInputInput;
}>;

export type UpdateProjectTemplateTaskMutation = { __typename?: 'Mutation' } & {
  updateProjectTemplateTask?: Maybe<
    { __typename?: 'ProjectTemplateTask' } & Pick<
      ProjectTemplateTask,
      | 'description'
      | 'estimateTimeSec'
      | 'id'
      | 'priority'
      | 'sortNoInList'
      | 'sortNoInTaskStatus'
      | 'title'
      | 'versionNo'
    > & {
        createdMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
        projectTemplate: { __typename?: 'ProjectTemplate' } & Pick<
          ProjectTemplate,
          | 'description'
          | 'estimateTimeSec'
          | 'id'
          | 'name'
          | 'priority'
          | 'sortNoInList'
          | 'versionNo'
        > & {
            client?: Maybe<
              { __typename?: 'Client' } & Pick<
                Client,
                'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
              >
            >;
          };
        projectTemplateTaskStatus: { __typename?: 'ProjectTemplateTaskStatus' } & Pick<
          ProjectTemplateTaskStatus,
          'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
        > & {
            projectTemplate: { __typename?: 'ProjectTemplate' } & Pick<
              ProjectTemplate,
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'name'
              | 'priority'
              | 'sortNoInList'
              | 'versionNo'
            >;
          };
      }
  >;
};

export type UpdateProjectTemplateTaskCustomAttributeMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateProjectTemplateTaskCustomAttributeInputInput;
}>;

export type UpdateProjectTemplateTaskCustomAttributeMutation = { __typename?: 'Mutation' } & {
  updateProjectTemplateTaskCustomAttribute?: Maybe<
    { __typename?: 'ProjectTemplateTaskCustomAttribute' } & Pick<
      ProjectTemplateTaskCustomAttribute,
      'id' | 'sortNo' | 'value'
    > & {
        listItem?: Maybe<
          { __typename?: 'TaskCustomAttributeMasterListItem' } & Pick<
            TaskCustomAttributeMasterListItem,
            'id' | 'sortNo' | 'value' | 'versionNo'
          >
        >;
        master: { __typename?: 'TaskCustomAttributeMaster' } & Pick<
          TaskCustomAttributeMaster,
          'id' | 'name' | 'required' | 'sortNo' | 'type' | 'versionNo'
        > & {
            listItem: Array<
              Maybe<
                { __typename?: 'TaskCustomAttributeMasterListItem' } & Pick<
                  TaskCustomAttributeMasterListItem,
                  'id' | 'sortNo' | 'value' | 'versionNo'
                >
              >
            >;
          };
      }
  >;
};

export type UpdateProjectTemplateTaskRemainderMutationVariables = Exact<{
  id: Scalars['String'];
  input: UpdateProjectTemplateTaskRemainderInputInput;
}>;

export type UpdateProjectTemplateTaskRemainderMutation = { __typename?: 'Mutation' } & {
  updateProjectTemplateTaskRemainder?: Maybe<
    { __typename?: 'ProjectTemplateTaskRemainder' } & Pick<
      ProjectTemplateTaskRemainder,
      'beforeDeadlineMinutes' | 'id' | 'taskRemainderDestType' | 'taskRemainderType'
    > & {
        projectTemplateTask: { __typename?: 'ProjectTemplateTask' } & Pick<
          ProjectTemplateTask,
          | 'description'
          | 'estimateTimeSec'
          | 'id'
          | 'priority'
          | 'sortNoInList'
          | 'sortNoInTaskStatus'
          | 'title'
          | 'versionNo'
        > & {
            createdMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
            projectTemplate: { __typename?: 'ProjectTemplate' } & Pick<
              ProjectTemplate,
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'name'
              | 'priority'
              | 'sortNoInList'
              | 'versionNo'
            > & {
                client?: Maybe<
                  { __typename?: 'Client' } & Pick<
                    Client,
                    'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                  >
                >;
              };
            projectTemplateTaskStatus: { __typename?: 'ProjectTemplateTaskStatus' } & Pick<
              ProjectTemplateTaskStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
            > & {
                projectTemplate: { __typename?: 'ProjectTemplate' } & Pick<
                  ProjectTemplate,
                  | 'description'
                  | 'estimateTimeSec'
                  | 'id'
                  | 'name'
                  | 'priority'
                  | 'sortNoInList'
                  | 'versionNo'
                >;
              };
          };
        targetMember?: Maybe<
          { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >
        >;
      }
  >;
};

export type UpdateProjectTemplateTaskSortNoInListMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateProjectTemplateTaskSortNoInListInputInput;
}>;

export type UpdateProjectTemplateTaskSortNoInListMutation = { __typename?: 'Mutation' } & {
  updateProjectTemplateTaskSortNoInList?: Maybe<
    { __typename?: 'ProjectTemplateTask' } & Pick<
      ProjectTemplateTask,
      | 'description'
      | 'estimateTimeSec'
      | 'id'
      | 'priority'
      | 'sortNoInList'
      | 'sortNoInTaskStatus'
      | 'title'
      | 'versionNo'
    > & {
        createdMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
        projectTemplate: { __typename?: 'ProjectTemplate' } & Pick<
          ProjectTemplate,
          | 'description'
          | 'estimateTimeSec'
          | 'id'
          | 'name'
          | 'priority'
          | 'sortNoInList'
          | 'versionNo'
        > & {
            client?: Maybe<
              { __typename?: 'Client' } & Pick<
                Client,
                'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
              >
            >;
          };
        projectTemplateTaskStatus: { __typename?: 'ProjectTemplateTaskStatus' } & Pick<
          ProjectTemplateTaskStatus,
          'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
        > & {
            projectTemplate: { __typename?: 'ProjectTemplate' } & Pick<
              ProjectTemplate,
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'name'
              | 'priority'
              | 'sortNoInList'
              | 'versionNo'
            >;
          };
      }
  >;
};

export type UpdateProjectTemplateTaskSortNoInTaskStatusMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateProjectTemplateTaskStatusTaskInputInput;
}>;

export type UpdateProjectTemplateTaskSortNoInTaskStatusMutation = { __typename?: 'Mutation' } & {
  updateProjectTemplateTaskSortNoInTaskStatus?: Maybe<
    { __typename?: 'ProjectTemplateTask' } & Pick<
      ProjectTemplateTask,
      | 'description'
      | 'estimateTimeSec'
      | 'id'
      | 'priority'
      | 'sortNoInList'
      | 'sortNoInTaskStatus'
      | 'title'
      | 'versionNo'
    > & {
        createdMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
        projectTemplate: { __typename?: 'ProjectTemplate' } & Pick<
          ProjectTemplate,
          | 'description'
          | 'estimateTimeSec'
          | 'id'
          | 'name'
          | 'priority'
          | 'sortNoInList'
          | 'versionNo'
        > & {
            client?: Maybe<
              { __typename?: 'Client' } & Pick<
                Client,
                'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
              >
            >;
          };
        projectTemplateTaskStatus: { __typename?: 'ProjectTemplateTaskStatus' } & Pick<
          ProjectTemplateTaskStatus,
          'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
        > & {
            projectTemplate: { __typename?: 'ProjectTemplate' } & Pick<
              ProjectTemplate,
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'name'
              | 'priority'
              | 'sortNoInList'
              | 'versionNo'
            >;
          };
      }
  >;
};

export type UpdateProjectTemplateTaskStatusMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateProjectTemplateTaskStatusInputInput;
}>;

export type UpdateProjectTemplateTaskStatusMutation = { __typename?: 'Mutation' } & {
  updateProjectTemplateTaskStatus?: Maybe<
    { __typename?: 'ProjectTemplateTaskStatus' } & Pick<
      ProjectTemplateTaskStatus,
      'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
    > & {
        projectTemplate: { __typename?: 'ProjectTemplate' } & Pick<
          ProjectTemplate,
          | 'description'
          | 'estimateTimeSec'
          | 'id'
          | 'name'
          | 'priority'
          | 'sortNoInList'
          | 'versionNo'
        > & {
            client?: Maybe<
              { __typename?: 'Client' } & Pick<
                Client,
                'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
              >
            >;
          };
      }
  >;
};

export type UpdateSettlementRangeContractMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateSettlementRangeContractInputInput;
}>;

export type UpdateSettlementRangeContractMutation = { __typename?: 'Mutation' } & {
  updateSettlementRangeContract?: Maybe<
    { __typename?: 'SettlementRangeContract' } & Pick<
      SettlementRangeContract,
      'id' | 'monthlyUnitPrice' | 'versionNo'
    > & {
        lowerHourPart: { __typename?: 'LowerPart' } & Pick<
          LowerPart,
          'deducationHourlyUnitPrice' | 'lowerHour'
        >;
        member?: Maybe<
          { __typename?: 'SettlementRangeContractMemberPart' } & Pick<
            SettlementRangeContractMemberPart,
            | 'laborCostWithoutTax'
            | 'memberId'
            | 'name'
            | 'profileImageUrl'
            | 'roundedTotalWorkingTimeSec'
            | 'salesAmountWithoutTax'
            | 'totalWorkingTimeSec'
          >
        >;
        upperHourPart: { __typename?: 'UpperPart' } & Pick<
          UpperPart,
          'excessHourlyUnitPrice' | 'upperHour'
        >;
      }
  >;
};

export type UpdateStatusOfProjectMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateProjectStatusOfProjectInputInput;
}>;

export type UpdateStatusOfProjectMutation = { __typename?: 'Mutation' } & {
  updateStatusOfProject?: Maybe<
    { __typename?: 'Project' } & Pick<
      Project,
      | 'calendarLinkageCode'
      | 'color'
      | 'complete'
      | 'completeDateTime'
      | 'costBudgetWithoutTax'
      | 'description'
      | 'estimateTimeSec'
      | 'id'
      | 'key'
      | 'laborCost'
      | 'laborCostWithoutTax'
      | 'laborShare'
      | 'name'
      | 'priority'
      | 'registerDateTime'
      | 'salesAmountWithoutTax'
      | 'scheduledEndDateTime'
      | 'scheduledStartDateTime'
      | 'sortNoInGanttChart'
      | 'sortNoInList'
      | 'sortNoInProjectStatus'
      | 'totalScheduleTimeSec'
      | 'versionNo'
      | 'workingTimeSec'
    > & {
        assignees: Array<
          { __typename?: 'AssignedProjectMemberInfo' } & Pick<
            AssignedProjectMemberInfo,
            'sortNo'
          > & {
              member: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
            }
        >;
        client?: Maybe<
          { __typename?: 'Client' } & Pick<Client, 'code' | 'id' | 'name' | 'sortNo' | 'versionNo'>
        >;
        createdMember?: Maybe<
          { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >
        >;
        favoriteMembers: Array<
          Maybe<
            { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >
          >
        >;
        favoriteMembersInfo: Array<
          Maybe<
            { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
              FavoriteProjectMemberInfo,
              'sortNo'
            > & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
        projectStatus: { __typename?: 'ProjectStatus' } & Pick<
          ProjectStatus,
          'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
        >;
        team: { __typename?: 'Team' } & Pick<
          Team,
          'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
        > & {
            organization: { __typename?: 'Organization' } & Pick<
              Organization,
              | 'allowConnectThirdPartyCalendar'
              | 'contactMailAddress'
              | 'contractPreriod'
              | 'id'
              | 'imageUrl'
              | 'ipRestrictionFlg'
              | 'name'
              | 'requiredTwoFactorAuth'
              | 'suspensionFlg'
              | 'versionNo'
            > & {
                plan: { __typename?: 'PlanProduct' } & Pick<
                  PlanProduct,
                  'code' | 'contractPreriod' | 'id'
                >;
              };
          };
      }
  >;
};

export type UpdateStatusOfTaskMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateTaskStatusTaskInputInput;
}>;

export type UpdateStatusOfTaskMutation = { __typename?: 'Mutation' } & {
  updateStatusOfTask?: Maybe<
    { __typename?: 'Task' } & Pick<
      Task,
      | 'calendarLinkageCode'
      | 'completeDateTime'
      | 'description'
      | 'estimateTimeSec'
      | 'estimatedRemainingTimeSec'
      | 'id'
      | 'laborCost'
      | 'priority'
      | 'progressDeviationRate'
      | 'progressRate'
      | 'registerDateTime'
      | 'remainingTimeSec'
      | 'scheduleWorkingTimeSec'
      | 'scheduledEndDateTime'
      | 'scheduledStartDateTime'
      | 'sortNoInGanttChart'
      | 'sortNoInList'
      | 'sortNoInMyTask'
      | 'sortNoInTaskStatus'
      | 'title'
      | 'versionNo'
      | 'workingTimeSec'
    > & {
        assignees: Array<
          { __typename?: 'AssignedTaskMemberInfo' } & Pick<AssignedTaskMemberInfo, 'sortNo'> & {
              member: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
            }
        >;
        asssignee?: Maybe<
          { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >
        >;
        createdMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
        favoriteMembers: Array<
          Maybe<
            { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >
          >
        >;
        favoriteMembersInfo: Array<
          Maybe<
            { __typename?: 'FavoriteTaskMemberInfo' } & Pick<FavoriteTaskMemberInfo, 'sortNo'> & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
        project: { __typename?: 'Project' } & Pick<
          Project,
          | 'calendarLinkageCode'
          | 'color'
          | 'complete'
          | 'completeDateTime'
          | 'costBudgetWithoutTax'
          | 'description'
          | 'estimateTimeSec'
          | 'id'
          | 'key'
          | 'laborCost'
          | 'laborCostWithoutTax'
          | 'laborShare'
          | 'name'
          | 'priority'
          | 'registerDateTime'
          | 'salesAmountWithoutTax'
          | 'scheduledEndDateTime'
          | 'scheduledStartDateTime'
          | 'sortNoInGanttChart'
          | 'sortNoInList'
          | 'sortNoInProjectStatus'
          | 'totalScheduleTimeSec'
          | 'versionNo'
          | 'workingTimeSec'
        > & {
            assignees: Array<
              { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                AssignedProjectMemberInfo,
                'sortNo'
              >
            >;
            client?: Maybe<
              { __typename?: 'Client' } & Pick<
                Client,
                'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
              >
            >;
            createdMember?: Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >;
            favoriteMembers: Array<
              Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >
            >;
            favoriteMembersInfo: Array<
              Maybe<
                { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                  FavoriteProjectMemberInfo,
                  'sortNo'
                >
              >
            >;
            projectStatus: { __typename?: 'ProjectStatus' } & Pick<
              ProjectStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
            >;
            team: { __typename?: 'Team' } & Pick<
              Team,
              'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
            > & {
                organization: { __typename?: 'Organization' } & Pick<
                  Organization,
                  | 'allowConnectThirdPartyCalendar'
                  | 'contactMailAddress'
                  | 'contractPreriod'
                  | 'id'
                  | 'imageUrl'
                  | 'ipRestrictionFlg'
                  | 'name'
                  | 'requiredTwoFactorAuth'
                  | 'suspensionFlg'
                  | 'versionNo'
                > & {
                    plan: { __typename?: 'PlanProduct' } & Pick<
                      PlanProduct,
                      'code' | 'contractPreriod' | 'id'
                    >;
                  };
              };
          };
        taskStatus: { __typename?: 'TaskStatus' } & Pick<
          TaskStatus,
          'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
        > & {
            project: { __typename?: 'Project' } & Pick<
              Project,
              | 'calendarLinkageCode'
              | 'color'
              | 'complete'
              | 'completeDateTime'
              | 'costBudgetWithoutTax'
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'key'
              | 'laborCost'
              | 'laborCostWithoutTax'
              | 'laborShare'
              | 'name'
              | 'priority'
              | 'registerDateTime'
              | 'salesAmountWithoutTax'
              | 'scheduledEndDateTime'
              | 'scheduledStartDateTime'
              | 'sortNoInGanttChart'
              | 'sortNoInList'
              | 'sortNoInProjectStatus'
              | 'totalScheduleTimeSec'
              | 'versionNo'
              | 'workingTimeSec'
            >;
          };
        workingMembers: Array<
          Maybe<
            { __typename?: 'WorkingMemberInfo' } & Pick<
              WorkingMemberInfo,
              'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
            > & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
      }
  >;
};

export type UpdateSubTaskMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateSubTaskInputInput;
}>;

export type UpdateSubTaskMutation = { __typename?: 'Mutation' } & {
  updateSubTask?: Maybe<
    { __typename?: 'SubTask' } & Pick<
      SubTask,
      'complete' | 'completeDateTime' | 'id' | 'sortNo' | 'title' | 'versionNo'
    > & {
        createdMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
        task: { __typename?: 'Task' } & Pick<
          Task,
          | 'calendarLinkageCode'
          | 'completeDateTime'
          | 'description'
          | 'estimateTimeSec'
          | 'estimatedRemainingTimeSec'
          | 'id'
          | 'laborCost'
          | 'priority'
          | 'progressDeviationRate'
          | 'progressRate'
          | 'registerDateTime'
          | 'remainingTimeSec'
          | 'scheduleWorkingTimeSec'
          | 'scheduledEndDateTime'
          | 'scheduledStartDateTime'
          | 'sortNoInGanttChart'
          | 'sortNoInList'
          | 'sortNoInMyTask'
          | 'sortNoInTaskStatus'
          | 'title'
          | 'versionNo'
          | 'workingTimeSec'
        > & {
            assignees: Array<
              { __typename?: 'AssignedTaskMemberInfo' } & Pick<AssignedTaskMemberInfo, 'sortNo'> & {
                  member: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                }
            >;
            asssignee?: Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >;
            createdMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
            favoriteMembers: Array<
              Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >
            >;
            favoriteMembersInfo: Array<
              Maybe<
                { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                  FavoriteTaskMemberInfo,
                  'sortNo'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >
            >;
            project: { __typename?: 'Project' } & Pick<
              Project,
              | 'calendarLinkageCode'
              | 'color'
              | 'complete'
              | 'completeDateTime'
              | 'costBudgetWithoutTax'
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'key'
              | 'laborCost'
              | 'laborCostWithoutTax'
              | 'laborShare'
              | 'name'
              | 'priority'
              | 'registerDateTime'
              | 'salesAmountWithoutTax'
              | 'scheduledEndDateTime'
              | 'scheduledStartDateTime'
              | 'sortNoInGanttChart'
              | 'sortNoInList'
              | 'sortNoInProjectStatus'
              | 'totalScheduleTimeSec'
              | 'versionNo'
              | 'workingTimeSec'
            > & {
                assignees: Array<
                  { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                    AssignedProjectMemberInfo,
                    'sortNo'
                  >
                >;
                client?: Maybe<
                  { __typename?: 'Client' } & Pick<
                    Client,
                    'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                  >
                >;
                createdMember?: Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >;
                favoriteMembers: Array<
                  Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >
                >;
                favoriteMembersInfo: Array<
                  Maybe<
                    { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                      FavoriteProjectMemberInfo,
                      'sortNo'
                    >
                  >
                >;
                projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                  ProjectStatus,
                  'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                >;
                team: { __typename?: 'Team' } & Pick<
                  Team,
                  'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                > & {
                    organization: { __typename?: 'Organization' } & Pick<
                      Organization,
                      | 'allowConnectThirdPartyCalendar'
                      | 'contactMailAddress'
                      | 'contractPreriod'
                      | 'id'
                      | 'imageUrl'
                      | 'ipRestrictionFlg'
                      | 'name'
                      | 'requiredTwoFactorAuth'
                      | 'suspensionFlg'
                      | 'versionNo'
                    > & {
                        plan: { __typename?: 'PlanProduct' } & Pick<
                          PlanProduct,
                          'code' | 'contractPreriod' | 'id'
                        >;
                      };
                  };
              };
            taskStatus: { __typename?: 'TaskStatus' } & Pick<
              TaskStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
            > & {
                project: { __typename?: 'Project' } & Pick<
                  Project,
                  | 'calendarLinkageCode'
                  | 'color'
                  | 'complete'
                  | 'completeDateTime'
                  | 'costBudgetWithoutTax'
                  | 'description'
                  | 'estimateTimeSec'
                  | 'id'
                  | 'key'
                  | 'laborCost'
                  | 'laborCostWithoutTax'
                  | 'laborShare'
                  | 'name'
                  | 'priority'
                  | 'registerDateTime'
                  | 'salesAmountWithoutTax'
                  | 'scheduledEndDateTime'
                  | 'scheduledStartDateTime'
                  | 'sortNoInGanttChart'
                  | 'sortNoInList'
                  | 'sortNoInProjectStatus'
                  | 'totalScheduleTimeSec'
                  | 'versionNo'
                  | 'workingTimeSec'
                >;
              };
            workingMembers: Array<
              Maybe<
                { __typename?: 'WorkingMemberInfo' } & Pick<
                  WorkingMemberInfo,
                  'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >
            >;
          };
      }
  >;
};

export type UpdateSubTaskSortNoMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateSubTaskSortNoInputInput;
}>;

export type UpdateSubTaskSortNoMutation = { __typename?: 'Mutation' } & {
  updateSubTaskSortNo?: Maybe<
    { __typename?: 'SubTask' } & Pick<
      SubTask,
      'complete' | 'completeDateTime' | 'id' | 'sortNo' | 'title' | 'versionNo'
    > & {
        createdMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
        task: { __typename?: 'Task' } & Pick<
          Task,
          | 'calendarLinkageCode'
          | 'completeDateTime'
          | 'description'
          | 'estimateTimeSec'
          | 'estimatedRemainingTimeSec'
          | 'id'
          | 'laborCost'
          | 'priority'
          | 'progressDeviationRate'
          | 'progressRate'
          | 'registerDateTime'
          | 'remainingTimeSec'
          | 'scheduleWorkingTimeSec'
          | 'scheduledEndDateTime'
          | 'scheduledStartDateTime'
          | 'sortNoInGanttChart'
          | 'sortNoInList'
          | 'sortNoInMyTask'
          | 'sortNoInTaskStatus'
          | 'title'
          | 'versionNo'
          | 'workingTimeSec'
        > & {
            assignees: Array<
              { __typename?: 'AssignedTaskMemberInfo' } & Pick<AssignedTaskMemberInfo, 'sortNo'> & {
                  member: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                }
            >;
            asssignee?: Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >;
            createdMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
            favoriteMembers: Array<
              Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >
            >;
            favoriteMembersInfo: Array<
              Maybe<
                { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                  FavoriteTaskMemberInfo,
                  'sortNo'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >
            >;
            project: { __typename?: 'Project' } & Pick<
              Project,
              | 'calendarLinkageCode'
              | 'color'
              | 'complete'
              | 'completeDateTime'
              | 'costBudgetWithoutTax'
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'key'
              | 'laborCost'
              | 'laborCostWithoutTax'
              | 'laborShare'
              | 'name'
              | 'priority'
              | 'registerDateTime'
              | 'salesAmountWithoutTax'
              | 'scheduledEndDateTime'
              | 'scheduledStartDateTime'
              | 'sortNoInGanttChart'
              | 'sortNoInList'
              | 'sortNoInProjectStatus'
              | 'totalScheduleTimeSec'
              | 'versionNo'
              | 'workingTimeSec'
            > & {
                assignees: Array<
                  { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                    AssignedProjectMemberInfo,
                    'sortNo'
                  >
                >;
                client?: Maybe<
                  { __typename?: 'Client' } & Pick<
                    Client,
                    'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                  >
                >;
                createdMember?: Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >;
                favoriteMembers: Array<
                  Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >
                >;
                favoriteMembersInfo: Array<
                  Maybe<
                    { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                      FavoriteProjectMemberInfo,
                      'sortNo'
                    >
                  >
                >;
                projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                  ProjectStatus,
                  'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                >;
                team: { __typename?: 'Team' } & Pick<
                  Team,
                  'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                > & {
                    organization: { __typename?: 'Organization' } & Pick<
                      Organization,
                      | 'allowConnectThirdPartyCalendar'
                      | 'contactMailAddress'
                      | 'contractPreriod'
                      | 'id'
                      | 'imageUrl'
                      | 'ipRestrictionFlg'
                      | 'name'
                      | 'requiredTwoFactorAuth'
                      | 'suspensionFlg'
                      | 'versionNo'
                    > & {
                        plan: { __typename?: 'PlanProduct' } & Pick<
                          PlanProduct,
                          'code' | 'contractPreriod' | 'id'
                        >;
                      };
                  };
              };
            taskStatus: { __typename?: 'TaskStatus' } & Pick<
              TaskStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
            > & {
                project: { __typename?: 'Project' } & Pick<
                  Project,
                  | 'calendarLinkageCode'
                  | 'color'
                  | 'complete'
                  | 'completeDateTime'
                  | 'costBudgetWithoutTax'
                  | 'description'
                  | 'estimateTimeSec'
                  | 'id'
                  | 'key'
                  | 'laborCost'
                  | 'laborCostWithoutTax'
                  | 'laborShare'
                  | 'name'
                  | 'priority'
                  | 'registerDateTime'
                  | 'salesAmountWithoutTax'
                  | 'scheduledEndDateTime'
                  | 'scheduledStartDateTime'
                  | 'sortNoInGanttChart'
                  | 'sortNoInList'
                  | 'sortNoInProjectStatus'
                  | 'totalScheduleTimeSec'
                  | 'versionNo'
                  | 'workingTimeSec'
                >;
              };
            workingMembers: Array<
              Maybe<
                { __typename?: 'WorkingMemberInfo' } & Pick<
                  WorkingMemberInfo,
                  'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >
            >;
          };
      }
  >;
};

export type UpdateTagMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateTagInputInput;
}>;

export type UpdateTagMutation = { __typename?: 'Mutation' } & {
  updateTag?: Maybe<
    { __typename?: 'Tag' } & Pick<
      Tag,
      'id' | 'name' | 'note' | 'relatedTaskCount' | 'sortNo' | 'versionNo'
    > & {
        organization: { __typename?: 'Organization' } & Pick<
          Organization,
          | 'allowConnectThirdPartyCalendar'
          | 'contactMailAddress'
          | 'contractPreriod'
          | 'id'
          | 'imageUrl'
          | 'ipRestrictionFlg'
          | 'name'
          | 'requiredTwoFactorAuth'
          | 'suspensionFlg'
          | 'versionNo'
        > & {
            plan: { __typename?: 'PlanProduct' } & Pick<
              PlanProduct,
              'code' | 'contractPreriod' | 'id'
            >;
          };
      }
  >;
};

export type UpdateTagSortNoMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: Maybe<UpdateTagSortNoInputInput>;
}>;

export type UpdateTagSortNoMutation = { __typename?: 'Mutation' } & {
  updateTagSortNo?: Maybe<
    { __typename?: 'Tag' } & Pick<
      Tag,
      'id' | 'name' | 'note' | 'relatedTaskCount' | 'sortNo' | 'versionNo'
    > & {
        organization: { __typename?: 'Organization' } & Pick<
          Organization,
          | 'allowConnectThirdPartyCalendar'
          | 'contactMailAddress'
          | 'contractPreriod'
          | 'id'
          | 'imageUrl'
          | 'ipRestrictionFlg'
          | 'name'
          | 'requiredTwoFactorAuth'
          | 'suspensionFlg'
          | 'versionNo'
        > & {
            plan: { __typename?: 'PlanProduct' } & Pick<
              PlanProduct,
              'code' | 'contractPreriod' | 'id'
            >;
          };
      }
  >;
};

export type UpdateTaskMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateTaskInputInput;
}>;

export type UpdateTaskMutation = { __typename?: 'Mutation' } & {
  updateTask?: Maybe<
    { __typename?: 'Task' } & Pick<
      Task,
      | 'calendarLinkageCode'
      | 'completeDateTime'
      | 'description'
      | 'estimateTimeSec'
      | 'estimatedRemainingTimeSec'
      | 'id'
      | 'laborCost'
      | 'priority'
      | 'progressDeviationRate'
      | 'progressRate'
      | 'registerDateTime'
      | 'remainingTimeSec'
      | 'scheduleWorkingTimeSec'
      | 'scheduledEndDateTime'
      | 'scheduledStartDateTime'
      | 'sortNoInGanttChart'
      | 'sortNoInList'
      | 'sortNoInMyTask'
      | 'sortNoInTaskStatus'
      | 'title'
      | 'versionNo'
      | 'workingTimeSec'
    > & {
        assignees: Array<
          { __typename?: 'AssignedTaskMemberInfo' } & Pick<AssignedTaskMemberInfo, 'sortNo'> & {
              member: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
            }
        >;
        asssignee?: Maybe<
          { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >
        >;
        createdMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
        favoriteMembers: Array<
          Maybe<
            { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >
          >
        >;
        favoriteMembersInfo: Array<
          Maybe<
            { __typename?: 'FavoriteTaskMemberInfo' } & Pick<FavoriteTaskMemberInfo, 'sortNo'> & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
        project: { __typename?: 'Project' } & Pick<
          Project,
          | 'calendarLinkageCode'
          | 'color'
          | 'complete'
          | 'completeDateTime'
          | 'costBudgetWithoutTax'
          | 'description'
          | 'estimateTimeSec'
          | 'id'
          | 'key'
          | 'laborCost'
          | 'laborCostWithoutTax'
          | 'laborShare'
          | 'name'
          | 'priority'
          | 'registerDateTime'
          | 'salesAmountWithoutTax'
          | 'scheduledEndDateTime'
          | 'scheduledStartDateTime'
          | 'sortNoInGanttChart'
          | 'sortNoInList'
          | 'sortNoInProjectStatus'
          | 'totalScheduleTimeSec'
          | 'versionNo'
          | 'workingTimeSec'
        > & {
            assignees: Array<
              { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                AssignedProjectMemberInfo,
                'sortNo'
              >
            >;
            client?: Maybe<
              { __typename?: 'Client' } & Pick<
                Client,
                'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
              >
            >;
            createdMember?: Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >;
            favoriteMembers: Array<
              Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >
            >;
            favoriteMembersInfo: Array<
              Maybe<
                { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                  FavoriteProjectMemberInfo,
                  'sortNo'
                >
              >
            >;
            projectStatus: { __typename?: 'ProjectStatus' } & Pick<
              ProjectStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
            >;
            team: { __typename?: 'Team' } & Pick<
              Team,
              'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
            > & {
                organization: { __typename?: 'Organization' } & Pick<
                  Organization,
                  | 'allowConnectThirdPartyCalendar'
                  | 'contactMailAddress'
                  | 'contractPreriod'
                  | 'id'
                  | 'imageUrl'
                  | 'ipRestrictionFlg'
                  | 'name'
                  | 'requiredTwoFactorAuth'
                  | 'suspensionFlg'
                  | 'versionNo'
                > & {
                    plan: { __typename?: 'PlanProduct' } & Pick<
                      PlanProduct,
                      'code' | 'contractPreriod' | 'id'
                    >;
                  };
              };
          };
        taskStatus: { __typename?: 'TaskStatus' } & Pick<
          TaskStatus,
          'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
        > & {
            project: { __typename?: 'Project' } & Pick<
              Project,
              | 'calendarLinkageCode'
              | 'color'
              | 'complete'
              | 'completeDateTime'
              | 'costBudgetWithoutTax'
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'key'
              | 'laborCost'
              | 'laborCostWithoutTax'
              | 'laborShare'
              | 'name'
              | 'priority'
              | 'registerDateTime'
              | 'salesAmountWithoutTax'
              | 'scheduledEndDateTime'
              | 'scheduledStartDateTime'
              | 'sortNoInGanttChart'
              | 'sortNoInList'
              | 'sortNoInProjectStatus'
              | 'totalScheduleTimeSec'
              | 'versionNo'
              | 'workingTimeSec'
            >;
          };
        workingMembers: Array<
          Maybe<
            { __typename?: 'WorkingMemberInfo' } & Pick<
              WorkingMemberInfo,
              'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
            > & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
      }
  >;
};

export type UpdateTaskCommentMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateTaskCommentInputInput;
}>;

export type UpdateTaskCommentMutation = { __typename?: 'Mutation' } & {
  updateTaskComment?: Maybe<
    { __typename?: 'TaskComment' } & Pick<
      TaskComment,
      | 'comment'
      | 'commentedDateTime'
      | 'id'
      | 'projectId'
      | 'projectName'
      | 'taskId'
      | 'taskTitle'
      | 'teamId'
      | 'teamName'
      | 'versionNo'
    > & {
        createdMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
        task?: Maybe<
          { __typename?: 'Task' } & Pick<
            Task,
            | 'calendarLinkageCode'
            | 'completeDateTime'
            | 'description'
            | 'estimateTimeSec'
            | 'estimatedRemainingTimeSec'
            | 'id'
            | 'laborCost'
            | 'priority'
            | 'progressDeviationRate'
            | 'progressRate'
            | 'registerDateTime'
            | 'remainingTimeSec'
            | 'scheduleWorkingTimeSec'
            | 'scheduledEndDateTime'
            | 'scheduledStartDateTime'
            | 'sortNoInGanttChart'
            | 'sortNoInList'
            | 'sortNoInMyTask'
            | 'sortNoInTaskStatus'
            | 'title'
            | 'versionNo'
            | 'workingTimeSec'
          > & {
              assignees: Array<
                { __typename?: 'AssignedTaskMemberInfo' } & Pick<
                  AssignedTaskMemberInfo,
                  'sortNo'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >;
              asssignee?: Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >;
              createdMember: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
              favoriteMembers: Array<
                Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >
              >;
              favoriteMembersInfo: Array<
                Maybe<
                  { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                    FavoriteTaskMemberInfo,
                    'sortNo'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >
              >;
              project: { __typename?: 'Project' } & Pick<
                Project,
                | 'calendarLinkageCode'
                | 'color'
                | 'complete'
                | 'completeDateTime'
                | 'costBudgetWithoutTax'
                | 'description'
                | 'estimateTimeSec'
                | 'id'
                | 'key'
                | 'laborCost'
                | 'laborCostWithoutTax'
                | 'laborShare'
                | 'name'
                | 'priority'
                | 'registerDateTime'
                | 'salesAmountWithoutTax'
                | 'scheduledEndDateTime'
                | 'scheduledStartDateTime'
                | 'sortNoInGanttChart'
                | 'sortNoInList'
                | 'sortNoInProjectStatus'
                | 'totalScheduleTimeSec'
                | 'versionNo'
                | 'workingTimeSec'
              > & {
                  assignees: Array<
                    { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                      AssignedProjectMemberInfo,
                      'sortNo'
                    >
                  >;
                  client?: Maybe<
                    { __typename?: 'Client' } & Pick<
                      Client,
                      'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                    >
                  >;
                  createdMember?: Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >;
                  favoriteMembers: Array<
                    Maybe<
                      { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >
                    >
                  >;
                  favoriteMembersInfo: Array<
                    Maybe<
                      { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                        FavoriteProjectMemberInfo,
                        'sortNo'
                      >
                    >
                  >;
                  projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                    ProjectStatus,
                    'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                  >;
                  team: { __typename?: 'Team' } & Pick<
                    Team,
                    'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                  > & {
                      organization: { __typename?: 'Organization' } & Pick<
                        Organization,
                        | 'allowConnectThirdPartyCalendar'
                        | 'contactMailAddress'
                        | 'contractPreriod'
                        | 'id'
                        | 'imageUrl'
                        | 'ipRestrictionFlg'
                        | 'name'
                        | 'requiredTwoFactorAuth'
                        | 'suspensionFlg'
                        | 'versionNo'
                      > & {
                          plan: { __typename?: 'PlanProduct' } & Pick<
                            PlanProduct,
                            'code' | 'contractPreriod' | 'id'
                          >;
                        };
                    };
                };
              taskStatus: { __typename?: 'TaskStatus' } & Pick<
                TaskStatus,
                'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
              > & {
                  project: { __typename?: 'Project' } & Pick<
                    Project,
                    | 'calendarLinkageCode'
                    | 'color'
                    | 'complete'
                    | 'completeDateTime'
                    | 'costBudgetWithoutTax'
                    | 'description'
                    | 'estimateTimeSec'
                    | 'id'
                    | 'key'
                    | 'laborCost'
                    | 'laborCostWithoutTax'
                    | 'laborShare'
                    | 'name'
                    | 'priority'
                    | 'registerDateTime'
                    | 'salesAmountWithoutTax'
                    | 'scheduledEndDateTime'
                    | 'scheduledStartDateTime'
                    | 'sortNoInGanttChart'
                    | 'sortNoInList'
                    | 'sortNoInProjectStatus'
                    | 'totalScheduleTimeSec'
                    | 'versionNo'
                    | 'workingTimeSec'
                  >;
                };
              workingMembers: Array<
                Maybe<
                  { __typename?: 'WorkingMemberInfo' } & Pick<
                    WorkingMemberInfo,
                    'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >
              >;
            }
        >;
      }
  >;
};

export type UpdateTaskCustomAttributeMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateTaskCustomAttributeInputInput;
}>;

export type UpdateTaskCustomAttributeMutation = { __typename?: 'Mutation' } & {
  updateTaskCustomAttribute?: Maybe<
    { __typename?: 'TaskCustomAttribute' } & Pick<
      TaskCustomAttribute,
      'id' | 'sortNo' | 'value'
    > & {
        listItem?: Maybe<
          { __typename?: 'TaskCustomAttributeMasterListItem' } & Pick<
            TaskCustomAttributeMasterListItem,
            'id' | 'sortNo' | 'value' | 'versionNo'
          >
        >;
        master: { __typename?: 'TaskCustomAttributeMaster' } & Pick<
          TaskCustomAttributeMaster,
          'id' | 'name' | 'required' | 'sortNo' | 'type' | 'versionNo'
        > & {
            listItem: Array<
              Maybe<
                { __typename?: 'TaskCustomAttributeMasterListItem' } & Pick<
                  TaskCustomAttributeMasterListItem,
                  'id' | 'sortNo' | 'value' | 'versionNo'
                >
              >
            >;
          };
      }
  >;
};

export type UpdateTaskCustomAttributeMasterMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateTasktCustomAttributeMasterInputInput;
}>;

export type UpdateTaskCustomAttributeMasterMutation = { __typename?: 'Mutation' } & {
  updateTaskCustomAttributeMaster?: Maybe<
    { __typename?: 'TaskCustomAttributeMaster' } & Pick<
      TaskCustomAttributeMaster,
      'id' | 'name' | 'required' | 'sortNo' | 'type' | 'versionNo'
    > & {
        listItem: Array<
          Maybe<
            { __typename?: 'TaskCustomAttributeMasterListItem' } & Pick<
              TaskCustomAttributeMasterListItem,
              'id' | 'sortNo' | 'value' | 'versionNo'
            >
          >
        >;
      }
  >;
};

export type UpdateTaskCustomAttributeMasterListItemMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateTaskCustomAttributeMasterListItemInputInput;
}>;

export type UpdateTaskCustomAttributeMasterListItemMutation = { __typename?: 'Mutation' } & {
  updateTaskCustomAttributeMasterListItem?: Maybe<
    { __typename?: 'TaskCustomAttributeMasterListItem' } & Pick<
      TaskCustomAttributeMasterListItem,
      'id' | 'sortNo' | 'value' | 'versionNo'
    >
  >;
};

export type UpdateTaskRemainderMutationVariables = Exact<{
  id: Scalars['String'];
  input: UpdateTaskRemainderInputInput;
}>;

export type UpdateTaskRemainderMutation = { __typename?: 'Mutation' } & {
  updateTaskRemainder?: Maybe<
    { __typename?: 'TaskRemainder' } & Pick<
      TaskRemainder,
      | 'beforeDeadlineMinutes'
      | 'id'
      | 'notificationSentFlg'
      | 'taskRemainderDestType'
      | 'taskRemainderType'
    > & {
        targetMember?: Maybe<
          { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >
        >;
        task: { __typename?: 'Task' } & Pick<
          Task,
          | 'calendarLinkageCode'
          | 'completeDateTime'
          | 'description'
          | 'estimateTimeSec'
          | 'estimatedRemainingTimeSec'
          | 'id'
          | 'laborCost'
          | 'priority'
          | 'progressDeviationRate'
          | 'progressRate'
          | 'registerDateTime'
          | 'remainingTimeSec'
          | 'scheduleWorkingTimeSec'
          | 'scheduledEndDateTime'
          | 'scheduledStartDateTime'
          | 'sortNoInGanttChart'
          | 'sortNoInList'
          | 'sortNoInMyTask'
          | 'sortNoInTaskStatus'
          | 'title'
          | 'versionNo'
          | 'workingTimeSec'
        > & {
            assignees: Array<
              { __typename?: 'AssignedTaskMemberInfo' } & Pick<AssignedTaskMemberInfo, 'sortNo'> & {
                  member: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                }
            >;
            asssignee?: Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >;
            createdMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
            favoriteMembers: Array<
              Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >
            >;
            favoriteMembersInfo: Array<
              Maybe<
                { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                  FavoriteTaskMemberInfo,
                  'sortNo'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >
            >;
            project: { __typename?: 'Project' } & Pick<
              Project,
              | 'calendarLinkageCode'
              | 'color'
              | 'complete'
              | 'completeDateTime'
              | 'costBudgetWithoutTax'
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'key'
              | 'laborCost'
              | 'laborCostWithoutTax'
              | 'laborShare'
              | 'name'
              | 'priority'
              | 'registerDateTime'
              | 'salesAmountWithoutTax'
              | 'scheduledEndDateTime'
              | 'scheduledStartDateTime'
              | 'sortNoInGanttChart'
              | 'sortNoInList'
              | 'sortNoInProjectStatus'
              | 'totalScheduleTimeSec'
              | 'versionNo'
              | 'workingTimeSec'
            > & {
                assignees: Array<
                  { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                    AssignedProjectMemberInfo,
                    'sortNo'
                  >
                >;
                client?: Maybe<
                  { __typename?: 'Client' } & Pick<
                    Client,
                    'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                  >
                >;
                createdMember?: Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >;
                favoriteMembers: Array<
                  Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >
                >;
                favoriteMembersInfo: Array<
                  Maybe<
                    { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                      FavoriteProjectMemberInfo,
                      'sortNo'
                    >
                  >
                >;
                projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                  ProjectStatus,
                  'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                >;
                team: { __typename?: 'Team' } & Pick<
                  Team,
                  'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                > & {
                    organization: { __typename?: 'Organization' } & Pick<
                      Organization,
                      | 'allowConnectThirdPartyCalendar'
                      | 'contactMailAddress'
                      | 'contractPreriod'
                      | 'id'
                      | 'imageUrl'
                      | 'ipRestrictionFlg'
                      | 'name'
                      | 'requiredTwoFactorAuth'
                      | 'suspensionFlg'
                      | 'versionNo'
                    > & {
                        plan: { __typename?: 'PlanProduct' } & Pick<
                          PlanProduct,
                          'code' | 'contractPreriod' | 'id'
                        >;
                      };
                  };
              };
            taskStatus: { __typename?: 'TaskStatus' } & Pick<
              TaskStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
            > & {
                project: { __typename?: 'Project' } & Pick<
                  Project,
                  | 'calendarLinkageCode'
                  | 'color'
                  | 'complete'
                  | 'completeDateTime'
                  | 'costBudgetWithoutTax'
                  | 'description'
                  | 'estimateTimeSec'
                  | 'id'
                  | 'key'
                  | 'laborCost'
                  | 'laborCostWithoutTax'
                  | 'laborShare'
                  | 'name'
                  | 'priority'
                  | 'registerDateTime'
                  | 'salesAmountWithoutTax'
                  | 'scheduledEndDateTime'
                  | 'scheduledStartDateTime'
                  | 'sortNoInGanttChart'
                  | 'sortNoInList'
                  | 'sortNoInProjectStatus'
                  | 'totalScheduleTimeSec'
                  | 'versionNo'
                  | 'workingTimeSec'
                >;
              };
            workingMembers: Array<
              Maybe<
                { __typename?: 'WorkingMemberInfo' } & Pick<
                  WorkingMemberInfo,
                  'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >
            >;
          };
      }
  >;
};

export type UpdateTaskSortNoInFavoriteTaskMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateTaskSortNoInFavoriteTaskInputInput;
}>;

export type UpdateTaskSortNoInFavoriteTaskMutation = { __typename?: 'Mutation' } & {
  updateTaskSortNoInFavoriteTask?: Maybe<
    { __typename?: 'Task' } & Pick<
      Task,
      | 'calendarLinkageCode'
      | 'completeDateTime'
      | 'description'
      | 'estimateTimeSec'
      | 'estimatedRemainingTimeSec'
      | 'id'
      | 'laborCost'
      | 'priority'
      | 'progressDeviationRate'
      | 'progressRate'
      | 'registerDateTime'
      | 'remainingTimeSec'
      | 'scheduleWorkingTimeSec'
      | 'scheduledEndDateTime'
      | 'scheduledStartDateTime'
      | 'sortNoInGanttChart'
      | 'sortNoInList'
      | 'sortNoInMyTask'
      | 'sortNoInTaskStatus'
      | 'title'
      | 'versionNo'
      | 'workingTimeSec'
    > & {
        assignees: Array<
          { __typename?: 'AssignedTaskMemberInfo' } & Pick<AssignedTaskMemberInfo, 'sortNo'> & {
              member: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
            }
        >;
        asssignee?: Maybe<
          { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >
        >;
        createdMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
        favoriteMembers: Array<
          Maybe<
            { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >
          >
        >;
        favoriteMembersInfo: Array<
          Maybe<
            { __typename?: 'FavoriteTaskMemberInfo' } & Pick<FavoriteTaskMemberInfo, 'sortNo'> & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
        project: { __typename?: 'Project' } & Pick<
          Project,
          | 'calendarLinkageCode'
          | 'color'
          | 'complete'
          | 'completeDateTime'
          | 'costBudgetWithoutTax'
          | 'description'
          | 'estimateTimeSec'
          | 'id'
          | 'key'
          | 'laborCost'
          | 'laborCostWithoutTax'
          | 'laborShare'
          | 'name'
          | 'priority'
          | 'registerDateTime'
          | 'salesAmountWithoutTax'
          | 'scheduledEndDateTime'
          | 'scheduledStartDateTime'
          | 'sortNoInGanttChart'
          | 'sortNoInList'
          | 'sortNoInProjectStatus'
          | 'totalScheduleTimeSec'
          | 'versionNo'
          | 'workingTimeSec'
        > & {
            assignees: Array<
              { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                AssignedProjectMemberInfo,
                'sortNo'
              >
            >;
            client?: Maybe<
              { __typename?: 'Client' } & Pick<
                Client,
                'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
              >
            >;
            createdMember?: Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >;
            favoriteMembers: Array<
              Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >
            >;
            favoriteMembersInfo: Array<
              Maybe<
                { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                  FavoriteProjectMemberInfo,
                  'sortNo'
                >
              >
            >;
            projectStatus: { __typename?: 'ProjectStatus' } & Pick<
              ProjectStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
            >;
            team: { __typename?: 'Team' } & Pick<
              Team,
              'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
            > & {
                organization: { __typename?: 'Organization' } & Pick<
                  Organization,
                  | 'allowConnectThirdPartyCalendar'
                  | 'contactMailAddress'
                  | 'contractPreriod'
                  | 'id'
                  | 'imageUrl'
                  | 'ipRestrictionFlg'
                  | 'name'
                  | 'requiredTwoFactorAuth'
                  | 'suspensionFlg'
                  | 'versionNo'
                > & {
                    plan: { __typename?: 'PlanProduct' } & Pick<
                      PlanProduct,
                      'code' | 'contractPreriod' | 'id'
                    >;
                  };
              };
          };
        taskStatus: { __typename?: 'TaskStatus' } & Pick<
          TaskStatus,
          'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
        > & {
            project: { __typename?: 'Project' } & Pick<
              Project,
              | 'calendarLinkageCode'
              | 'color'
              | 'complete'
              | 'completeDateTime'
              | 'costBudgetWithoutTax'
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'key'
              | 'laborCost'
              | 'laborCostWithoutTax'
              | 'laborShare'
              | 'name'
              | 'priority'
              | 'registerDateTime'
              | 'salesAmountWithoutTax'
              | 'scheduledEndDateTime'
              | 'scheduledStartDateTime'
              | 'sortNoInGanttChart'
              | 'sortNoInList'
              | 'sortNoInProjectStatus'
              | 'totalScheduleTimeSec'
              | 'versionNo'
              | 'workingTimeSec'
            >;
          };
        workingMembers: Array<
          Maybe<
            { __typename?: 'WorkingMemberInfo' } & Pick<
              WorkingMemberInfo,
              'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
            > & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
      }
  >;
};

export type UpdateTaskSortNoInGanttChartMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateTaskSortNoInGanttChartInputInput;
}>;

export type UpdateTaskSortNoInGanttChartMutation = { __typename?: 'Mutation' } & {
  updateTaskSortNoInGanttChart?: Maybe<
    { __typename?: 'Task' } & Pick<
      Task,
      | 'calendarLinkageCode'
      | 'completeDateTime'
      | 'description'
      | 'estimateTimeSec'
      | 'estimatedRemainingTimeSec'
      | 'id'
      | 'laborCost'
      | 'priority'
      | 'progressDeviationRate'
      | 'progressRate'
      | 'registerDateTime'
      | 'remainingTimeSec'
      | 'scheduleWorkingTimeSec'
      | 'scheduledEndDateTime'
      | 'scheduledStartDateTime'
      | 'sortNoInGanttChart'
      | 'sortNoInList'
      | 'sortNoInMyTask'
      | 'sortNoInTaskStatus'
      | 'title'
      | 'versionNo'
      | 'workingTimeSec'
    > & {
        assignees: Array<
          { __typename?: 'AssignedTaskMemberInfo' } & Pick<AssignedTaskMemberInfo, 'sortNo'> & {
              member: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
            }
        >;
        asssignee?: Maybe<
          { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >
        >;
        createdMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
        favoriteMembers: Array<
          Maybe<
            { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >
          >
        >;
        favoriteMembersInfo: Array<
          Maybe<
            { __typename?: 'FavoriteTaskMemberInfo' } & Pick<FavoriteTaskMemberInfo, 'sortNo'> & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
        project: { __typename?: 'Project' } & Pick<
          Project,
          | 'calendarLinkageCode'
          | 'color'
          | 'complete'
          | 'completeDateTime'
          | 'costBudgetWithoutTax'
          | 'description'
          | 'estimateTimeSec'
          | 'id'
          | 'key'
          | 'laborCost'
          | 'laborCostWithoutTax'
          | 'laborShare'
          | 'name'
          | 'priority'
          | 'registerDateTime'
          | 'salesAmountWithoutTax'
          | 'scheduledEndDateTime'
          | 'scheduledStartDateTime'
          | 'sortNoInGanttChart'
          | 'sortNoInList'
          | 'sortNoInProjectStatus'
          | 'totalScheduleTimeSec'
          | 'versionNo'
          | 'workingTimeSec'
        > & {
            assignees: Array<
              { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                AssignedProjectMemberInfo,
                'sortNo'
              >
            >;
            client?: Maybe<
              { __typename?: 'Client' } & Pick<
                Client,
                'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
              >
            >;
            createdMember?: Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >;
            favoriteMembers: Array<
              Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >
            >;
            favoriteMembersInfo: Array<
              Maybe<
                { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                  FavoriteProjectMemberInfo,
                  'sortNo'
                >
              >
            >;
            projectStatus: { __typename?: 'ProjectStatus' } & Pick<
              ProjectStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
            >;
            team: { __typename?: 'Team' } & Pick<
              Team,
              'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
            > & {
                organization: { __typename?: 'Organization' } & Pick<
                  Organization,
                  | 'allowConnectThirdPartyCalendar'
                  | 'contactMailAddress'
                  | 'contractPreriod'
                  | 'id'
                  | 'imageUrl'
                  | 'ipRestrictionFlg'
                  | 'name'
                  | 'requiredTwoFactorAuth'
                  | 'suspensionFlg'
                  | 'versionNo'
                > & {
                    plan: { __typename?: 'PlanProduct' } & Pick<
                      PlanProduct,
                      'code' | 'contractPreriod' | 'id'
                    >;
                  };
              };
          };
        taskStatus: { __typename?: 'TaskStatus' } & Pick<
          TaskStatus,
          'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
        > & {
            project: { __typename?: 'Project' } & Pick<
              Project,
              | 'calendarLinkageCode'
              | 'color'
              | 'complete'
              | 'completeDateTime'
              | 'costBudgetWithoutTax'
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'key'
              | 'laborCost'
              | 'laborCostWithoutTax'
              | 'laborShare'
              | 'name'
              | 'priority'
              | 'registerDateTime'
              | 'salesAmountWithoutTax'
              | 'scheduledEndDateTime'
              | 'scheduledStartDateTime'
              | 'sortNoInGanttChart'
              | 'sortNoInList'
              | 'sortNoInProjectStatus'
              | 'totalScheduleTimeSec'
              | 'versionNo'
              | 'workingTimeSec'
            >;
          };
        workingMembers: Array<
          Maybe<
            { __typename?: 'WorkingMemberInfo' } & Pick<
              WorkingMemberInfo,
              'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
            > & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
      }
  >;
};

export type UpdateTaskSortNoInListMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateTaskSortNoInListInputInput;
}>;

export type UpdateTaskSortNoInListMutation = { __typename?: 'Mutation' } & {
  updateTaskSortNoInList?: Maybe<
    { __typename?: 'Task' } & Pick<
      Task,
      | 'calendarLinkageCode'
      | 'completeDateTime'
      | 'description'
      | 'estimateTimeSec'
      | 'estimatedRemainingTimeSec'
      | 'id'
      | 'laborCost'
      | 'priority'
      | 'progressDeviationRate'
      | 'progressRate'
      | 'registerDateTime'
      | 'remainingTimeSec'
      | 'scheduleWorkingTimeSec'
      | 'scheduledEndDateTime'
      | 'scheduledStartDateTime'
      | 'sortNoInGanttChart'
      | 'sortNoInList'
      | 'sortNoInMyTask'
      | 'sortNoInTaskStatus'
      | 'title'
      | 'versionNo'
      | 'workingTimeSec'
    > & {
        assignees: Array<
          { __typename?: 'AssignedTaskMemberInfo' } & Pick<AssignedTaskMemberInfo, 'sortNo'> & {
              member: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
            }
        >;
        asssignee?: Maybe<
          { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >
        >;
        createdMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
        favoriteMembers: Array<
          Maybe<
            { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >
          >
        >;
        favoriteMembersInfo: Array<
          Maybe<
            { __typename?: 'FavoriteTaskMemberInfo' } & Pick<FavoriteTaskMemberInfo, 'sortNo'> & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
        project: { __typename?: 'Project' } & Pick<
          Project,
          | 'calendarLinkageCode'
          | 'color'
          | 'complete'
          | 'completeDateTime'
          | 'costBudgetWithoutTax'
          | 'description'
          | 'estimateTimeSec'
          | 'id'
          | 'key'
          | 'laborCost'
          | 'laborCostWithoutTax'
          | 'laborShare'
          | 'name'
          | 'priority'
          | 'registerDateTime'
          | 'salesAmountWithoutTax'
          | 'scheduledEndDateTime'
          | 'scheduledStartDateTime'
          | 'sortNoInGanttChart'
          | 'sortNoInList'
          | 'sortNoInProjectStatus'
          | 'totalScheduleTimeSec'
          | 'versionNo'
          | 'workingTimeSec'
        > & {
            assignees: Array<
              { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                AssignedProjectMemberInfo,
                'sortNo'
              >
            >;
            client?: Maybe<
              { __typename?: 'Client' } & Pick<
                Client,
                'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
              >
            >;
            createdMember?: Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >;
            favoriteMembers: Array<
              Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >
            >;
            favoriteMembersInfo: Array<
              Maybe<
                { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                  FavoriteProjectMemberInfo,
                  'sortNo'
                >
              >
            >;
            projectStatus: { __typename?: 'ProjectStatus' } & Pick<
              ProjectStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
            >;
            team: { __typename?: 'Team' } & Pick<
              Team,
              'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
            > & {
                organization: { __typename?: 'Organization' } & Pick<
                  Organization,
                  | 'allowConnectThirdPartyCalendar'
                  | 'contactMailAddress'
                  | 'contractPreriod'
                  | 'id'
                  | 'imageUrl'
                  | 'ipRestrictionFlg'
                  | 'name'
                  | 'requiredTwoFactorAuth'
                  | 'suspensionFlg'
                  | 'versionNo'
                > & {
                    plan: { __typename?: 'PlanProduct' } & Pick<
                      PlanProduct,
                      'code' | 'contractPreriod' | 'id'
                    >;
                  };
              };
          };
        taskStatus: { __typename?: 'TaskStatus' } & Pick<
          TaskStatus,
          'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
        > & {
            project: { __typename?: 'Project' } & Pick<
              Project,
              | 'calendarLinkageCode'
              | 'color'
              | 'complete'
              | 'completeDateTime'
              | 'costBudgetWithoutTax'
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'key'
              | 'laborCost'
              | 'laborCostWithoutTax'
              | 'laborShare'
              | 'name'
              | 'priority'
              | 'registerDateTime'
              | 'salesAmountWithoutTax'
              | 'scheduledEndDateTime'
              | 'scheduledStartDateTime'
              | 'sortNoInGanttChart'
              | 'sortNoInList'
              | 'sortNoInProjectStatus'
              | 'totalScheduleTimeSec'
              | 'versionNo'
              | 'workingTimeSec'
            >;
          };
        workingMembers: Array<
          Maybe<
            { __typename?: 'WorkingMemberInfo' } & Pick<
              WorkingMemberInfo,
              'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
            > & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
      }
  >;
};

export type UpdateTaskSortNoInMyTaskMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateTaskSortNoInMyTaskInputInput;
}>;

export type UpdateTaskSortNoInMyTaskMutation = { __typename?: 'Mutation' } & {
  updateTaskSortNoInMyTask?: Maybe<
    { __typename?: 'Task' } & Pick<
      Task,
      | 'calendarLinkageCode'
      | 'completeDateTime'
      | 'description'
      | 'estimateTimeSec'
      | 'estimatedRemainingTimeSec'
      | 'id'
      | 'laborCost'
      | 'priority'
      | 'progressDeviationRate'
      | 'progressRate'
      | 'registerDateTime'
      | 'remainingTimeSec'
      | 'scheduleWorkingTimeSec'
      | 'scheduledEndDateTime'
      | 'scheduledStartDateTime'
      | 'sortNoInGanttChart'
      | 'sortNoInList'
      | 'sortNoInMyTask'
      | 'sortNoInTaskStatus'
      | 'title'
      | 'versionNo'
      | 'workingTimeSec'
    > & {
        assignees: Array<
          { __typename?: 'AssignedTaskMemberInfo' } & Pick<AssignedTaskMemberInfo, 'sortNo'> & {
              member: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
            }
        >;
        asssignee?: Maybe<
          { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >
        >;
        createdMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
        favoriteMembers: Array<
          Maybe<
            { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >
          >
        >;
        favoriteMembersInfo: Array<
          Maybe<
            { __typename?: 'FavoriteTaskMemberInfo' } & Pick<FavoriteTaskMemberInfo, 'sortNo'> & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
        project: { __typename?: 'Project' } & Pick<
          Project,
          | 'calendarLinkageCode'
          | 'color'
          | 'complete'
          | 'completeDateTime'
          | 'costBudgetWithoutTax'
          | 'description'
          | 'estimateTimeSec'
          | 'id'
          | 'key'
          | 'laborCost'
          | 'laborCostWithoutTax'
          | 'laborShare'
          | 'name'
          | 'priority'
          | 'registerDateTime'
          | 'salesAmountWithoutTax'
          | 'scheduledEndDateTime'
          | 'scheduledStartDateTime'
          | 'sortNoInGanttChart'
          | 'sortNoInList'
          | 'sortNoInProjectStatus'
          | 'totalScheduleTimeSec'
          | 'versionNo'
          | 'workingTimeSec'
        > & {
            assignees: Array<
              { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                AssignedProjectMemberInfo,
                'sortNo'
              >
            >;
            client?: Maybe<
              { __typename?: 'Client' } & Pick<
                Client,
                'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
              >
            >;
            createdMember?: Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >;
            favoriteMembers: Array<
              Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >
            >;
            favoriteMembersInfo: Array<
              Maybe<
                { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                  FavoriteProjectMemberInfo,
                  'sortNo'
                >
              >
            >;
            projectStatus: { __typename?: 'ProjectStatus' } & Pick<
              ProjectStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
            >;
            team: { __typename?: 'Team' } & Pick<
              Team,
              'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
            > & {
                organization: { __typename?: 'Organization' } & Pick<
                  Organization,
                  | 'allowConnectThirdPartyCalendar'
                  | 'contactMailAddress'
                  | 'contractPreriod'
                  | 'id'
                  | 'imageUrl'
                  | 'ipRestrictionFlg'
                  | 'name'
                  | 'requiredTwoFactorAuth'
                  | 'suspensionFlg'
                  | 'versionNo'
                > & {
                    plan: { __typename?: 'PlanProduct' } & Pick<
                      PlanProduct,
                      'code' | 'contractPreriod' | 'id'
                    >;
                  };
              };
          };
        taskStatus: { __typename?: 'TaskStatus' } & Pick<
          TaskStatus,
          'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
        > & {
            project: { __typename?: 'Project' } & Pick<
              Project,
              | 'calendarLinkageCode'
              | 'color'
              | 'complete'
              | 'completeDateTime'
              | 'costBudgetWithoutTax'
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'key'
              | 'laborCost'
              | 'laborCostWithoutTax'
              | 'laborShare'
              | 'name'
              | 'priority'
              | 'registerDateTime'
              | 'salesAmountWithoutTax'
              | 'scheduledEndDateTime'
              | 'scheduledStartDateTime'
              | 'sortNoInGanttChart'
              | 'sortNoInList'
              | 'sortNoInProjectStatus'
              | 'totalScheduleTimeSec'
              | 'versionNo'
              | 'workingTimeSec'
            >;
          };
        workingMembers: Array<
          Maybe<
            { __typename?: 'WorkingMemberInfo' } & Pick<
              WorkingMemberInfo,
              'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
            > & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
      }
  >;
};

export type UpdateTaskStatusMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateTaskStatusInputInput;
}>;

export type UpdateTaskStatusMutation = { __typename?: 'Mutation' } & {
  updateTaskStatus?: Maybe<
    { __typename?: 'TaskStatus' } & Pick<
      TaskStatus,
      'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
    > & {
        project: { __typename?: 'Project' } & Pick<
          Project,
          | 'calendarLinkageCode'
          | 'color'
          | 'complete'
          | 'completeDateTime'
          | 'costBudgetWithoutTax'
          | 'description'
          | 'estimateTimeSec'
          | 'id'
          | 'key'
          | 'laborCost'
          | 'laborCostWithoutTax'
          | 'laborShare'
          | 'name'
          | 'priority'
          | 'registerDateTime'
          | 'salesAmountWithoutTax'
          | 'scheduledEndDateTime'
          | 'scheduledStartDateTime'
          | 'sortNoInGanttChart'
          | 'sortNoInList'
          | 'sortNoInProjectStatus'
          | 'totalScheduleTimeSec'
          | 'versionNo'
          | 'workingTimeSec'
        > & {
            assignees: Array<
              { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                AssignedProjectMemberInfo,
                'sortNo'
              > & {
                  member: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                }
            >;
            client?: Maybe<
              { __typename?: 'Client' } & Pick<
                Client,
                'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
              >
            >;
            createdMember?: Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >;
            favoriteMembers: Array<
              Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >
            >;
            favoriteMembersInfo: Array<
              Maybe<
                { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                  FavoriteProjectMemberInfo,
                  'sortNo'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >
            >;
            projectStatus: { __typename?: 'ProjectStatus' } & Pick<
              ProjectStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
            >;
            team: { __typename?: 'Team' } & Pick<
              Team,
              'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
            > & {
                organization: { __typename?: 'Organization' } & Pick<
                  Organization,
                  | 'allowConnectThirdPartyCalendar'
                  | 'contactMailAddress'
                  | 'contractPreriod'
                  | 'id'
                  | 'imageUrl'
                  | 'ipRestrictionFlg'
                  | 'name'
                  | 'requiredTwoFactorAuth'
                  | 'suspensionFlg'
                  | 'versionNo'
                > & {
                    plan: { __typename?: 'PlanProduct' } & Pick<
                      PlanProduct,
                      'code' | 'contractPreriod' | 'id'
                    >;
                  };
              };
          };
      }
  >;
};

export type UpdateTeamMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateTeamInputInput;
}>;

export type UpdateTeamMutation = { __typename?: 'Mutation' } & {
  updateTeam?: Maybe<
    { __typename?: 'Team' } & Pick<
      Team,
      'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
    > & {
        organization: { __typename?: 'Organization' } & Pick<
          Organization,
          | 'allowConnectThirdPartyCalendar'
          | 'contactMailAddress'
          | 'contractPreriod'
          | 'id'
          | 'imageUrl'
          | 'ipRestrictionFlg'
          | 'name'
          | 'requiredTwoFactorAuth'
          | 'suspensionFlg'
          | 'versionNo'
        > & {
            plan: { __typename?: 'PlanProduct' } & Pick<
              PlanProduct,
              'code' | 'contractPreriod' | 'id'
            >;
          };
      }
  >;
};

export type UpdateTeamMemberRelationMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: Maybe<UpdateTeamMemberRelationInputInput>;
}>;

export type UpdateTeamMemberRelationMutation = { __typename?: 'Mutation' } & {
  updateTeamMemberRelation?: Maybe<
    { __typename?: 'TeamMemberRelation' } & Pick<
      TeamMemberRelation,
      'id' | 'openMenu' | 'sortNo' | 'versionNo'
    > & {
        team: { __typename?: 'Team' } & Pick<
          Team,
          'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
        > & {
            organization: { __typename?: 'Organization' } & Pick<
              Organization,
              | 'allowConnectThirdPartyCalendar'
              | 'contactMailAddress'
              | 'contractPreriod'
              | 'id'
              | 'imageUrl'
              | 'ipRestrictionFlg'
              | 'name'
              | 'requiredTwoFactorAuth'
              | 'suspensionFlg'
              | 'versionNo'
            > & {
                plan: { __typename?: 'PlanProduct' } & Pick<
                  PlanProduct,
                  'code' | 'contractPreriod' | 'id'
                >;
              };
          };
      }
  >;
};

export type UpdateToActiveMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateToActiveInputInput;
}>;

export type UpdateToActiveMutation = { __typename?: 'Mutation' } & {
  updateToActive?: Maybe<
    { __typename?: 'Member' } & Pick<
      Member,
      | 'adminRole'
      | 'clientManagementRole'
      | 'department'
      | 'employeeNumber'
      | 'id'
      | 'laborCostManagementRole'
      | 'mailAddress'
      | 'memberManagementRole'
      | 'memberRegistrationDateTime'
      | 'memberStatus'
      | 'name'
      | 'organizationMemberViewRole'
      | 'organizationReportRole'
      | 'profileImageUrl'
      | 'projectCreatePermissionFlg'
      | 'projectCustomAttributePermissionFlg'
      | 'projectDeletePermissionFlg'
      | 'projectUpdatePermissionFlg'
      | 'proxyEditWorkingDataRole'
      | 'salesManagementRole'
      | 'statusMessage'
      | 'taskCreatePermissionFlg'
      | 'taskCustomAttributePermissionFlg'
      | 'taskDeletePermissionFlg'
      | 'taskUpdatePermissionFlg'
      | 'teamMemberViewRole'
      | 'teamReportRole'
      | 'versionNo'
    >
  >;
};

export type UpdateToInactiveMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateToInactiveInputInput;
}>;

export type UpdateToInactiveMutation = { __typename?: 'Mutation' } & {
  updateToInactive?: Maybe<
    { __typename?: 'Member' } & Pick<
      Member,
      | 'adminRole'
      | 'clientManagementRole'
      | 'department'
      | 'employeeNumber'
      | 'id'
      | 'laborCostManagementRole'
      | 'mailAddress'
      | 'memberManagementRole'
      | 'memberRegistrationDateTime'
      | 'memberStatus'
      | 'name'
      | 'organizationMemberViewRole'
      | 'organizationReportRole'
      | 'profileImageUrl'
      | 'projectCreatePermissionFlg'
      | 'projectCustomAttributePermissionFlg'
      | 'projectDeletePermissionFlg'
      | 'projectUpdatePermissionFlg'
      | 'proxyEditWorkingDataRole'
      | 'salesManagementRole'
      | 'statusMessage'
      | 'taskCreatePermissionFlg'
      | 'taskCustomAttributePermissionFlg'
      | 'taskDeletePermissionFlg'
      | 'taskUpdatePermissionFlg'
      | 'teamMemberViewRole'
      | 'teamReportRole'
      | 'versionNo'
    >
  >;
};

export type UpdateWorkingHistoryMutationVariables = Exact<{
  id: Scalars['String'];
  input: UpdateWorkingHistoryInputInput;
}>;

export type UpdateWorkingHistoryMutation = { __typename?: 'Mutation' } & {
  updateWorkingHistory?: Maybe<
    { __typename?: 'WorkingHistory' } & Pick<
      WorkingHistory,
      'end' | 'id' | 'memo' | 'start' | 'versionNo' | 'workingTimeSec'
    > & {
        calendarEvent?: Maybe<
          { __typename?: 'CalendarEvent' } & Pick<
            CalendarEvent,
            | 'calendarType'
            | 'clientId'
            | 'clientName'
            | 'endDateTime'
            | 'eventName'
            | 'id'
            | 'laborCost'
            | 'personalTeam'
            | 'projectColor'
            | 'projectId'
            | 'projectName'
            | 'startDateTime'
            | 'taskId'
            | 'taskTitle'
            | 'teamId'
            | 'teamName'
            | 'thirdPartyCalendarEventId'
            | 'versionNo'
            | 'workingTimeSec'
          > & {
              thirdPartyCalendarInfoList: Array<
                Maybe<
                  { __typename?: 'ThirdPartyCalendarInfo' } & Pick<
                    ThirdPartyCalendarInfo,
                    'thirdPartyCalendarId'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >
              >;
            }
        >;
        closingProcessInfo: { __typename?: 'ClosingProcessPart' } & Pick<
          ClosingProcessPart,
          'closed' | 'targetDateTime'
        >;
        task?: Maybe<
          { __typename?: 'Task' } & Pick<
            Task,
            | 'calendarLinkageCode'
            | 'completeDateTime'
            | 'description'
            | 'estimateTimeSec'
            | 'estimatedRemainingTimeSec'
            | 'id'
            | 'laborCost'
            | 'priority'
            | 'progressDeviationRate'
            | 'progressRate'
            | 'registerDateTime'
            | 'remainingTimeSec'
            | 'scheduleWorkingTimeSec'
            | 'scheduledEndDateTime'
            | 'scheduledStartDateTime'
            | 'sortNoInGanttChart'
            | 'sortNoInList'
            | 'sortNoInMyTask'
            | 'sortNoInTaskStatus'
            | 'title'
            | 'versionNo'
            | 'workingTimeSec'
          > & {
              assignees: Array<
                { __typename?: 'AssignedTaskMemberInfo' } & Pick<
                  AssignedTaskMemberInfo,
                  'sortNo'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >;
              asssignee?: Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >;
              createdMember: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
              favoriteMembers: Array<
                Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >
              >;
              favoriteMembersInfo: Array<
                Maybe<
                  { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                    FavoriteTaskMemberInfo,
                    'sortNo'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >
              >;
              project: { __typename?: 'Project' } & Pick<
                Project,
                | 'calendarLinkageCode'
                | 'color'
                | 'complete'
                | 'completeDateTime'
                | 'costBudgetWithoutTax'
                | 'description'
                | 'estimateTimeSec'
                | 'id'
                | 'key'
                | 'laborCost'
                | 'laborCostWithoutTax'
                | 'laborShare'
                | 'name'
                | 'priority'
                | 'registerDateTime'
                | 'salesAmountWithoutTax'
                | 'scheduledEndDateTime'
                | 'scheduledStartDateTime'
                | 'sortNoInGanttChart'
                | 'sortNoInList'
                | 'sortNoInProjectStatus'
                | 'totalScheduleTimeSec'
                | 'versionNo'
                | 'workingTimeSec'
              > & {
                  assignees: Array<
                    { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                      AssignedProjectMemberInfo,
                      'sortNo'
                    >
                  >;
                  client?: Maybe<
                    { __typename?: 'Client' } & Pick<
                      Client,
                      'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                    >
                  >;
                  createdMember?: Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >;
                  favoriteMembers: Array<
                    Maybe<
                      { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >
                    >
                  >;
                  favoriteMembersInfo: Array<
                    Maybe<
                      { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                        FavoriteProjectMemberInfo,
                        'sortNo'
                      >
                    >
                  >;
                  projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                    ProjectStatus,
                    'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                  >;
                  team: { __typename?: 'Team' } & Pick<
                    Team,
                    'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                  > & {
                      organization: { __typename?: 'Organization' } & Pick<
                        Organization,
                        | 'allowConnectThirdPartyCalendar'
                        | 'contactMailAddress'
                        | 'contractPreriod'
                        | 'id'
                        | 'imageUrl'
                        | 'ipRestrictionFlg'
                        | 'name'
                        | 'requiredTwoFactorAuth'
                        | 'suspensionFlg'
                        | 'versionNo'
                      > & {
                          plan: { __typename?: 'PlanProduct' } & Pick<
                            PlanProduct,
                            'code' | 'contractPreriod' | 'id'
                          >;
                        };
                    };
                };
              taskStatus: { __typename?: 'TaskStatus' } & Pick<
                TaskStatus,
                'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
              > & {
                  project: { __typename?: 'Project' } & Pick<
                    Project,
                    | 'calendarLinkageCode'
                    | 'color'
                    | 'complete'
                    | 'completeDateTime'
                    | 'costBudgetWithoutTax'
                    | 'description'
                    | 'estimateTimeSec'
                    | 'id'
                    | 'key'
                    | 'laborCost'
                    | 'laborCostWithoutTax'
                    | 'laborShare'
                    | 'name'
                    | 'priority'
                    | 'registerDateTime'
                    | 'salesAmountWithoutTax'
                    | 'scheduledEndDateTime'
                    | 'scheduledStartDateTime'
                    | 'sortNoInGanttChart'
                    | 'sortNoInList'
                    | 'sortNoInProjectStatus'
                    | 'totalScheduleTimeSec'
                    | 'versionNo'
                    | 'workingTimeSec'
                  >;
                };
              workingMembers: Array<
                Maybe<
                  { __typename?: 'WorkingMemberInfo' } & Pick<
                    WorkingMemberInfo,
                    'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >
              >;
            }
        >;
        workingMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
      }
  >;
};

export type UpdateWorkingScheduleMutationVariables = Exact<{
  id: Scalars['String'];
  input: UpdateWorkingScheduleInputInput;
}>;

export type UpdateWorkingScheduleMutation = { __typename?: 'Mutation' } & {
  updateWorkingSchedule?: Maybe<
    { __typename?: 'WorkingSchedule' } & Pick<
      WorkingSchedule,
      'end' | 'id' | 'memo' | 'start' | 'versionNo' | 'workingTimeSec'
    > & {
        calendarEvent?: Maybe<
          { __typename?: 'CalendarEvent' } & Pick<
            CalendarEvent,
            | 'calendarType'
            | 'clientId'
            | 'clientName'
            | 'endDateTime'
            | 'eventName'
            | 'id'
            | 'laborCost'
            | 'personalTeam'
            | 'projectColor'
            | 'projectId'
            | 'projectName'
            | 'startDateTime'
            | 'taskId'
            | 'taskTitle'
            | 'teamId'
            | 'teamName'
            | 'thirdPartyCalendarEventId'
            | 'versionNo'
            | 'workingTimeSec'
          > & {
              thirdPartyCalendarInfoList: Array<
                Maybe<
                  { __typename?: 'ThirdPartyCalendarInfo' } & Pick<
                    ThirdPartyCalendarInfo,
                    'thirdPartyCalendarId'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >
              >;
            }
        >;
        task?: Maybe<
          { __typename?: 'Task' } & Pick<
            Task,
            | 'calendarLinkageCode'
            | 'completeDateTime'
            | 'description'
            | 'estimateTimeSec'
            | 'estimatedRemainingTimeSec'
            | 'id'
            | 'laborCost'
            | 'priority'
            | 'progressDeviationRate'
            | 'progressRate'
            | 'registerDateTime'
            | 'remainingTimeSec'
            | 'scheduleWorkingTimeSec'
            | 'scheduledEndDateTime'
            | 'scheduledStartDateTime'
            | 'sortNoInGanttChart'
            | 'sortNoInList'
            | 'sortNoInMyTask'
            | 'sortNoInTaskStatus'
            | 'title'
            | 'versionNo'
            | 'workingTimeSec'
          > & {
              assignees: Array<
                { __typename?: 'AssignedTaskMemberInfo' } & Pick<
                  AssignedTaskMemberInfo,
                  'sortNo'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >;
              asssignee?: Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >;
              createdMember: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
              favoriteMembers: Array<
                Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >
              >;
              favoriteMembersInfo: Array<
                Maybe<
                  { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                    FavoriteTaskMemberInfo,
                    'sortNo'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >
              >;
              project: { __typename?: 'Project' } & Pick<
                Project,
                | 'calendarLinkageCode'
                | 'color'
                | 'complete'
                | 'completeDateTime'
                | 'costBudgetWithoutTax'
                | 'description'
                | 'estimateTimeSec'
                | 'id'
                | 'key'
                | 'laborCost'
                | 'laborCostWithoutTax'
                | 'laborShare'
                | 'name'
                | 'priority'
                | 'registerDateTime'
                | 'salesAmountWithoutTax'
                | 'scheduledEndDateTime'
                | 'scheduledStartDateTime'
                | 'sortNoInGanttChart'
                | 'sortNoInList'
                | 'sortNoInProjectStatus'
                | 'totalScheduleTimeSec'
                | 'versionNo'
                | 'workingTimeSec'
              > & {
                  assignees: Array<
                    { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                      AssignedProjectMemberInfo,
                      'sortNo'
                    >
                  >;
                  client?: Maybe<
                    { __typename?: 'Client' } & Pick<
                      Client,
                      'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                    >
                  >;
                  createdMember?: Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >;
                  favoriteMembers: Array<
                    Maybe<
                      { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >
                    >
                  >;
                  favoriteMembersInfo: Array<
                    Maybe<
                      { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                        FavoriteProjectMemberInfo,
                        'sortNo'
                      >
                    >
                  >;
                  projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                    ProjectStatus,
                    'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                  >;
                  team: { __typename?: 'Team' } & Pick<
                    Team,
                    'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                  > & {
                      organization: { __typename?: 'Organization' } & Pick<
                        Organization,
                        | 'allowConnectThirdPartyCalendar'
                        | 'contactMailAddress'
                        | 'contractPreriod'
                        | 'id'
                        | 'imageUrl'
                        | 'ipRestrictionFlg'
                        | 'name'
                        | 'requiredTwoFactorAuth'
                        | 'suspensionFlg'
                        | 'versionNo'
                      > & {
                          plan: { __typename?: 'PlanProduct' } & Pick<
                            PlanProduct,
                            'code' | 'contractPreriod' | 'id'
                          >;
                        };
                    };
                };
              taskStatus: { __typename?: 'TaskStatus' } & Pick<
                TaskStatus,
                'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
              > & {
                  project: { __typename?: 'Project' } & Pick<
                    Project,
                    | 'calendarLinkageCode'
                    | 'color'
                    | 'complete'
                    | 'completeDateTime'
                    | 'costBudgetWithoutTax'
                    | 'description'
                    | 'estimateTimeSec'
                    | 'id'
                    | 'key'
                    | 'laborCost'
                    | 'laborCostWithoutTax'
                    | 'laborShare'
                    | 'name'
                    | 'priority'
                    | 'registerDateTime'
                    | 'salesAmountWithoutTax'
                    | 'scheduledEndDateTime'
                    | 'scheduledStartDateTime'
                    | 'sortNoInGanttChart'
                    | 'sortNoInList'
                    | 'sortNoInProjectStatus'
                    | 'totalScheduleTimeSec'
                    | 'versionNo'
                    | 'workingTimeSec'
                  >;
                };
              workingMembers: Array<
                Maybe<
                  { __typename?: 'WorkingMemberInfo' } & Pick<
                    WorkingMemberInfo,
                    'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >
              >;
            }
        >;
        workingMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
      }
  >;
};

export type UpgradeToProPlanIosMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: Maybe<UpgradeToProPlanIosInputInput>;
}>;

export type UpgradeToProPlanIosMutation = { __typename?: 'Mutation' } & {
  upgradeToProPlanIOS?: Maybe<
    { __typename?: 'Contract' } & Pick<
      Contract,
      | 'billToMailAddress'
      | 'billingType'
      | 'contractPreriod'
      | 'freeTrialExpireDatetime'
      | 'id'
      | 'licenceCount'
      | 'revenuecatOriginalAppUserId'
      | 'usingLicenceCount'
      | 'versionNo'
    > & {
        freeLicenceCoupon?: Maybe<
          { __typename?: 'RangeFreeLicenceCoupon' } & Pick<
            RangeFreeLicenceCoupon,
            'freeEndLicenceCount' | 'freeStartLicenceCount' | 'id'
          >
        >;
        plan: { __typename?: 'PlanProduct' } & Pick<PlanProduct, 'code' | 'contractPreriod' | 'id'>;
      }
  >;
};

export type VerifyTwoFactorAuthMutationVariables = Exact<{
  input: VerifyTwoFactorAuthInputInput;
}>;

export type VerifyTwoFactorAuthMutation = { __typename?: 'Mutation' } & {
  verifyTwoFactorAuth?: Maybe<
    { __typename?: 'TwoFactorAuthSetting' } & Pick<
      TwoFactorAuthSetting,
      'enabled' | 'recoveryCodes'
    >
  >;
};

export type WithdrawMutationVariables = Exact<{
  input: WithdrawalInputInput;
}>;

export type WithdrawMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'withdraw'>;

export type AccessControlSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type AccessControlSettingsQuery = { __typename?: 'Query' } & {
  accessControlSettings?: Maybe<
    { __typename?: 'AccessControlSettings' } & Pick<
      AccessControlSettings,
      'enable' | 'id' | 'ipAddresses' | 'versionNo'
    >
  >;
};

export type AllMemberWorkingHistoriesSpecifyTermQueryVariables = Exact<{
  input: AllMemberWorkingWorkingHistoriesInputInput;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;

export type AllMemberWorkingHistoriesSpecifyTermQuery = { __typename?: 'Query' } & {
  allMemberWorkingHistoriesSpecifyTerm?: Maybe<
    Array<
      Maybe<
        { __typename?: 'WorkingHistory' } & Pick<
          WorkingHistory,
          'end' | 'id' | 'memo' | 'start' | 'versionNo' | 'workingTimeSec'
        > & {
            calendarEvent?: Maybe<
              { __typename?: 'CalendarEvent' } & Pick<
                CalendarEvent,
                | 'calendarType'
                | 'clientId'
                | 'clientName'
                | 'endDateTime'
                | 'eventName'
                | 'id'
                | 'laborCost'
                | 'personalTeam'
                | 'projectColor'
                | 'projectId'
                | 'projectName'
                | 'startDateTime'
                | 'taskId'
                | 'taskTitle'
                | 'teamId'
                | 'teamName'
                | 'thirdPartyCalendarEventId'
                | 'versionNo'
                | 'workingTimeSec'
              > & {
                  thirdPartyCalendarInfoList: Array<
                    Maybe<
                      { __typename?: 'ThirdPartyCalendarInfo' } & Pick<
                        ThirdPartyCalendarInfo,
                        'thirdPartyCalendarId'
                      > & {
                          member: { __typename?: 'Member' } & Pick<
                            Member,
                            | 'adminRole'
                            | 'clientManagementRole'
                            | 'department'
                            | 'employeeNumber'
                            | 'id'
                            | 'laborCostManagementRole'
                            | 'mailAddress'
                            | 'memberManagementRole'
                            | 'memberRegistrationDateTime'
                            | 'memberStatus'
                            | 'name'
                            | 'organizationMemberViewRole'
                            | 'organizationReportRole'
                            | 'profileImageUrl'
                            | 'projectCreatePermissionFlg'
                            | 'projectCustomAttributePermissionFlg'
                            | 'projectDeletePermissionFlg'
                            | 'projectUpdatePermissionFlg'
                            | 'proxyEditWorkingDataRole'
                            | 'salesManagementRole'
                            | 'statusMessage'
                            | 'taskCreatePermissionFlg'
                            | 'taskCustomAttributePermissionFlg'
                            | 'taskDeletePermissionFlg'
                            | 'taskUpdatePermissionFlg'
                            | 'teamMemberViewRole'
                            | 'teamReportRole'
                            | 'versionNo'
                          >;
                        }
                    >
                  >;
                }
            >;
            closingProcessInfo: { __typename?: 'ClosingProcessPart' } & Pick<
              ClosingProcessPart,
              'closed' | 'targetDateTime'
            >;
            task?: Maybe<
              { __typename?: 'Task' } & Pick<
                Task,
                | 'calendarLinkageCode'
                | 'completeDateTime'
                | 'description'
                | 'estimateTimeSec'
                | 'estimatedRemainingTimeSec'
                | 'id'
                | 'laborCost'
                | 'priority'
                | 'progressDeviationRate'
                | 'progressRate'
                | 'registerDateTime'
                | 'remainingTimeSec'
                | 'scheduleWorkingTimeSec'
                | 'scheduledEndDateTime'
                | 'scheduledStartDateTime'
                | 'sortNoInGanttChart'
                | 'sortNoInList'
                | 'sortNoInMyTask'
                | 'sortNoInTaskStatus'
                | 'title'
                | 'versionNo'
                | 'workingTimeSec'
              > & {
                  assignees: Array<
                    { __typename?: 'AssignedTaskMemberInfo' } & Pick<
                      AssignedTaskMemberInfo,
                      'sortNo'
                    > & {
                        member: { __typename?: 'Member' } & Pick<
                          Member,
                          | 'adminRole'
                          | 'clientManagementRole'
                          | 'department'
                          | 'employeeNumber'
                          | 'id'
                          | 'laborCostManagementRole'
                          | 'mailAddress'
                          | 'memberManagementRole'
                          | 'memberRegistrationDateTime'
                          | 'memberStatus'
                          | 'name'
                          | 'organizationMemberViewRole'
                          | 'organizationReportRole'
                          | 'profileImageUrl'
                          | 'projectCreatePermissionFlg'
                          | 'projectCustomAttributePermissionFlg'
                          | 'projectDeletePermissionFlg'
                          | 'projectUpdatePermissionFlg'
                          | 'proxyEditWorkingDataRole'
                          | 'salesManagementRole'
                          | 'statusMessage'
                          | 'taskCreatePermissionFlg'
                          | 'taskCustomAttributePermissionFlg'
                          | 'taskDeletePermissionFlg'
                          | 'taskUpdatePermissionFlg'
                          | 'teamMemberViewRole'
                          | 'teamReportRole'
                          | 'versionNo'
                        >;
                      }
                  >;
                  asssignee?: Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >;
                  createdMember: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                  favoriteMembers: Array<
                    Maybe<
                      { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >
                    >
                  >;
                  favoriteMembersInfo: Array<
                    Maybe<
                      { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                        FavoriteTaskMemberInfo,
                        'sortNo'
                      > & {
                          member: { __typename?: 'Member' } & Pick<
                            Member,
                            | 'adminRole'
                            | 'clientManagementRole'
                            | 'department'
                            | 'employeeNumber'
                            | 'id'
                            | 'laborCostManagementRole'
                            | 'mailAddress'
                            | 'memberManagementRole'
                            | 'memberRegistrationDateTime'
                            | 'memberStatus'
                            | 'name'
                            | 'organizationMemberViewRole'
                            | 'organizationReportRole'
                            | 'profileImageUrl'
                            | 'projectCreatePermissionFlg'
                            | 'projectCustomAttributePermissionFlg'
                            | 'projectDeletePermissionFlg'
                            | 'projectUpdatePermissionFlg'
                            | 'proxyEditWorkingDataRole'
                            | 'salesManagementRole'
                            | 'statusMessage'
                            | 'taskCreatePermissionFlg'
                            | 'taskCustomAttributePermissionFlg'
                            | 'taskDeletePermissionFlg'
                            | 'taskUpdatePermissionFlg'
                            | 'teamMemberViewRole'
                            | 'teamReportRole'
                            | 'versionNo'
                          >;
                        }
                    >
                  >;
                  project: { __typename?: 'Project' } & Pick<
                    Project,
                    | 'calendarLinkageCode'
                    | 'color'
                    | 'complete'
                    | 'completeDateTime'
                    | 'costBudgetWithoutTax'
                    | 'description'
                    | 'estimateTimeSec'
                    | 'id'
                    | 'key'
                    | 'laborCost'
                    | 'laborCostWithoutTax'
                    | 'laborShare'
                    | 'name'
                    | 'priority'
                    | 'registerDateTime'
                    | 'salesAmountWithoutTax'
                    | 'scheduledEndDateTime'
                    | 'scheduledStartDateTime'
                    | 'sortNoInGanttChart'
                    | 'sortNoInList'
                    | 'sortNoInProjectStatus'
                    | 'totalScheduleTimeSec'
                    | 'versionNo'
                    | 'workingTimeSec'
                  > & {
                      assignees: Array<
                        { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                          AssignedProjectMemberInfo,
                          'sortNo'
                        >
                      >;
                      client?: Maybe<
                        { __typename?: 'Client' } & Pick<
                          Client,
                          'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                        >
                      >;
                      createdMember?: Maybe<
                        { __typename?: 'Member' } & Pick<
                          Member,
                          | 'adminRole'
                          | 'clientManagementRole'
                          | 'department'
                          | 'employeeNumber'
                          | 'id'
                          | 'laborCostManagementRole'
                          | 'mailAddress'
                          | 'memberManagementRole'
                          | 'memberRegistrationDateTime'
                          | 'memberStatus'
                          | 'name'
                          | 'organizationMemberViewRole'
                          | 'organizationReportRole'
                          | 'profileImageUrl'
                          | 'projectCreatePermissionFlg'
                          | 'projectCustomAttributePermissionFlg'
                          | 'projectDeletePermissionFlg'
                          | 'projectUpdatePermissionFlg'
                          | 'proxyEditWorkingDataRole'
                          | 'salesManagementRole'
                          | 'statusMessage'
                          | 'taskCreatePermissionFlg'
                          | 'taskCustomAttributePermissionFlg'
                          | 'taskDeletePermissionFlg'
                          | 'taskUpdatePermissionFlg'
                          | 'teamMemberViewRole'
                          | 'teamReportRole'
                          | 'versionNo'
                        >
                      >;
                      favoriteMembers: Array<
                        Maybe<
                          { __typename?: 'Member' } & Pick<
                            Member,
                            | 'adminRole'
                            | 'clientManagementRole'
                            | 'department'
                            | 'employeeNumber'
                            | 'id'
                            | 'laborCostManagementRole'
                            | 'mailAddress'
                            | 'memberManagementRole'
                            | 'memberRegistrationDateTime'
                            | 'memberStatus'
                            | 'name'
                            | 'organizationMemberViewRole'
                            | 'organizationReportRole'
                            | 'profileImageUrl'
                            | 'projectCreatePermissionFlg'
                            | 'projectCustomAttributePermissionFlg'
                            | 'projectDeletePermissionFlg'
                            | 'projectUpdatePermissionFlg'
                            | 'proxyEditWorkingDataRole'
                            | 'salesManagementRole'
                            | 'statusMessage'
                            | 'taskCreatePermissionFlg'
                            | 'taskCustomAttributePermissionFlg'
                            | 'taskDeletePermissionFlg'
                            | 'taskUpdatePermissionFlg'
                            | 'teamMemberViewRole'
                            | 'teamReportRole'
                            | 'versionNo'
                          >
                        >
                      >;
                      favoriteMembersInfo: Array<
                        Maybe<
                          { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                            FavoriteProjectMemberInfo,
                            'sortNo'
                          >
                        >
                      >;
                      projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                        ProjectStatus,
                        'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                      >;
                      team: { __typename?: 'Team' } & Pick<
                        Team,
                        'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                      > & {
                          organization: { __typename?: 'Organization' } & Pick<
                            Organization,
                            | 'allowConnectThirdPartyCalendar'
                            | 'contactMailAddress'
                            | 'contractPreriod'
                            | 'id'
                            | 'imageUrl'
                            | 'ipRestrictionFlg'
                            | 'name'
                            | 'requiredTwoFactorAuth'
                            | 'suspensionFlg'
                            | 'versionNo'
                          > & {
                              plan: { __typename?: 'PlanProduct' } & Pick<
                                PlanProduct,
                                'code' | 'contractPreriod' | 'id'
                              >;
                            };
                        };
                    };
                  taskStatus: { __typename?: 'TaskStatus' } & Pick<
                    TaskStatus,
                    'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
                  > & {
                      project: { __typename?: 'Project' } & Pick<
                        Project,
                        | 'calendarLinkageCode'
                        | 'color'
                        | 'complete'
                        | 'completeDateTime'
                        | 'costBudgetWithoutTax'
                        | 'description'
                        | 'estimateTimeSec'
                        | 'id'
                        | 'key'
                        | 'laborCost'
                        | 'laborCostWithoutTax'
                        | 'laborShare'
                        | 'name'
                        | 'priority'
                        | 'registerDateTime'
                        | 'salesAmountWithoutTax'
                        | 'scheduledEndDateTime'
                        | 'scheduledStartDateTime'
                        | 'sortNoInGanttChart'
                        | 'sortNoInList'
                        | 'sortNoInProjectStatus'
                        | 'totalScheduleTimeSec'
                        | 'versionNo'
                        | 'workingTimeSec'
                      >;
                    };
                  workingMembers: Array<
                    Maybe<
                      { __typename?: 'WorkingMemberInfo' } & Pick<
                        WorkingMemberInfo,
                        'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                      > & {
                          member: { __typename?: 'Member' } & Pick<
                            Member,
                            | 'adminRole'
                            | 'clientManagementRole'
                            | 'department'
                            | 'employeeNumber'
                            | 'id'
                            | 'laborCostManagementRole'
                            | 'mailAddress'
                            | 'memberManagementRole'
                            | 'memberRegistrationDateTime'
                            | 'memberStatus'
                            | 'name'
                            | 'organizationMemberViewRole'
                            | 'organizationReportRole'
                            | 'profileImageUrl'
                            | 'projectCreatePermissionFlg'
                            | 'projectCustomAttributePermissionFlg'
                            | 'projectDeletePermissionFlg'
                            | 'projectUpdatePermissionFlg'
                            | 'proxyEditWorkingDataRole'
                            | 'salesManagementRole'
                            | 'statusMessage'
                            | 'taskCreatePermissionFlg'
                            | 'taskCustomAttributePermissionFlg'
                            | 'taskDeletePermissionFlg'
                            | 'taskUpdatePermissionFlg'
                            | 'teamMemberViewRole'
                            | 'teamReportRole'
                            | 'versionNo'
                          >;
                        }
                    >
                  >;
                }
            >;
            workingMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
          }
      >
    >
  >;
};

export type AllTeamClosingSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type AllTeamClosingSettingsQuery = { __typename?: 'Query' } & {
  allTeamClosingSettings?: Maybe<
    Array<
      Maybe<
        { __typename?: 'TeamClosingSetting' } & Pick<
          TeamClosingSetting,
          'closingType' | 'id' | 'targetDateTime' | 'teamId' | 'versionNo'
        > & {
            timeZone: { __typename?: 'TimeZone' } & Pick<
              TimeZone,
              'code' | 'id' | 'name' | 'offsetTime' | 'sortNo'
            >;
          }
      >
    >
  >;
};

export type AnnouncementQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type AnnouncementQuery = { __typename?: 'Query' } & {
  announcement?: Maybe<
    { __typename?: 'Announcement' } & Pick<
      Announcement,
      'announceDateTime' | 'id' | 'readed' | 'title' | 'url'
    >
  >;
};

export type AnnouncementsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;

export type AnnouncementsQuery = { __typename?: 'Query' } & {
  announcements?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Announcement' } & Pick<
          Announcement,
          'announceDateTime' | 'id' | 'readed' | 'title' | 'url'
        >
      >
    >
  >;
};

export type ApiKeyQueryVariables = Exact<{ [key: string]: never }>;

export type ApiKeyQuery = { __typename?: 'Query' } & {
  apiKey?: Maybe<{ __typename?: 'ApiKey' } & Pick<ApiKey, 'token'>>;
};

export type AppleAccountQueryVariables = Exact<{ [key: string]: never }>;

export type AppleAccountQuery = { __typename?: 'Query' } & {
  appleAccount?: Maybe<{ __typename?: 'AppleAccount' } & Pick<AppleAccount, 'mailAddress'>>;
};

export type AssignedProjectsQueryVariables = Exact<{
  input?: Maybe<FetchAssignedProjectsInputInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;

export type AssignedProjectsQuery = { __typename?: 'Query' } & {
  assignedProjects?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Project' } & Pick<
          Project,
          | 'calendarLinkageCode'
          | 'color'
          | 'complete'
          | 'completeDateTime'
          | 'costBudgetWithoutTax'
          | 'description'
          | 'estimateTimeSec'
          | 'id'
          | 'key'
          | 'laborCost'
          | 'laborCostWithoutTax'
          | 'laborShare'
          | 'name'
          | 'priority'
          | 'registerDateTime'
          | 'salesAmountWithoutTax'
          | 'scheduledEndDateTime'
          | 'scheduledStartDateTime'
          | 'sortNoInGanttChart'
          | 'sortNoInList'
          | 'sortNoInProjectStatus'
          | 'totalScheduleTimeSec'
          | 'versionNo'
          | 'workingTimeSec'
        > & {
            assignees: Array<
              { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                AssignedProjectMemberInfo,
                'sortNo'
              > & {
                  member: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                }
            >;
            client?: Maybe<
              { __typename?: 'Client' } & Pick<
                Client,
                'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
              >
            >;
            createdMember?: Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >;
            favoriteMembers: Array<
              Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >
            >;
            favoriteMembersInfo: Array<
              Maybe<
                { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                  FavoriteProjectMemberInfo,
                  'sortNo'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >
            >;
            projectStatus: { __typename?: 'ProjectStatus' } & Pick<
              ProjectStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
            >;
            team: { __typename?: 'Team' } & Pick<
              Team,
              'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
            > & {
                organization: { __typename?: 'Organization' } & Pick<
                  Organization,
                  | 'allowConnectThirdPartyCalendar'
                  | 'contactMailAddress'
                  | 'contractPreriod'
                  | 'id'
                  | 'imageUrl'
                  | 'ipRestrictionFlg'
                  | 'name'
                  | 'requiredTwoFactorAuth'
                  | 'suspensionFlg'
                  | 'versionNo'
                > & {
                    plan: { __typename?: 'PlanProduct' } & Pick<
                      PlanProduct,
                      'code' | 'contractPreriod' | 'id'
                    >;
                  };
              };
          }
      >
    >
  >;
};

export type AssignedTasksQueryVariables = Exact<{
  input?: Maybe<SearchTaskInputInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;

export type AssignedTasksQuery = { __typename?: 'Query' } & {
  assignedTasks?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Task' } & Pick<
          Task,
          | 'calendarLinkageCode'
          | 'completeDateTime'
          | 'description'
          | 'estimateTimeSec'
          | 'estimatedRemainingTimeSec'
          | 'id'
          | 'laborCost'
          | 'priority'
          | 'progressDeviationRate'
          | 'progressRate'
          | 'registerDateTime'
          | 'remainingTimeSec'
          | 'scheduleWorkingTimeSec'
          | 'scheduledEndDateTime'
          | 'scheduledStartDateTime'
          | 'sortNoInGanttChart'
          | 'sortNoInList'
          | 'sortNoInMyTask'
          | 'sortNoInTaskStatus'
          | 'title'
          | 'versionNo'
          | 'workingTimeSec'
        > & {
            assignees: Array<
              { __typename?: 'AssignedTaskMemberInfo' } & Pick<AssignedTaskMemberInfo, 'sortNo'> & {
                  member: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                }
            >;
            asssignee?: Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >;
            createdMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
            favoriteMembers: Array<
              Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >
            >;
            favoriteMembersInfo: Array<
              Maybe<
                { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                  FavoriteTaskMemberInfo,
                  'sortNo'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >
            >;
            project: { __typename?: 'Project' } & Pick<
              Project,
              | 'calendarLinkageCode'
              | 'color'
              | 'complete'
              | 'completeDateTime'
              | 'costBudgetWithoutTax'
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'key'
              | 'laborCost'
              | 'laborCostWithoutTax'
              | 'laborShare'
              | 'name'
              | 'priority'
              | 'registerDateTime'
              | 'salesAmountWithoutTax'
              | 'scheduledEndDateTime'
              | 'scheduledStartDateTime'
              | 'sortNoInGanttChart'
              | 'sortNoInList'
              | 'sortNoInProjectStatus'
              | 'totalScheduleTimeSec'
              | 'versionNo'
              | 'workingTimeSec'
            > & {
                assignees: Array<
                  { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                    AssignedProjectMemberInfo,
                    'sortNo'
                  >
                >;
                client?: Maybe<
                  { __typename?: 'Client' } & Pick<
                    Client,
                    'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                  >
                >;
                createdMember?: Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >;
                favoriteMembers: Array<
                  Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >
                >;
                favoriteMembersInfo: Array<
                  Maybe<
                    { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                      FavoriteProjectMemberInfo,
                      'sortNo'
                    >
                  >
                >;
                projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                  ProjectStatus,
                  'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                >;
                team: { __typename?: 'Team' } & Pick<
                  Team,
                  'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                > & {
                    organization: { __typename?: 'Organization' } & Pick<
                      Organization,
                      | 'allowConnectThirdPartyCalendar'
                      | 'contactMailAddress'
                      | 'contractPreriod'
                      | 'id'
                      | 'imageUrl'
                      | 'ipRestrictionFlg'
                      | 'name'
                      | 'requiredTwoFactorAuth'
                      | 'suspensionFlg'
                      | 'versionNo'
                    > & {
                        plan: { __typename?: 'PlanProduct' } & Pick<
                          PlanProduct,
                          'code' | 'contractPreriod' | 'id'
                        >;
                      };
                  };
              };
            taskStatus: { __typename?: 'TaskStatus' } & Pick<
              TaskStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
            > & {
                project: { __typename?: 'Project' } & Pick<
                  Project,
                  | 'calendarLinkageCode'
                  | 'color'
                  | 'complete'
                  | 'completeDateTime'
                  | 'costBudgetWithoutTax'
                  | 'description'
                  | 'estimateTimeSec'
                  | 'id'
                  | 'key'
                  | 'laborCost'
                  | 'laborCostWithoutTax'
                  | 'laborShare'
                  | 'name'
                  | 'priority'
                  | 'registerDateTime'
                  | 'salesAmountWithoutTax'
                  | 'scheduledEndDateTime'
                  | 'scheduledStartDateTime'
                  | 'sortNoInGanttChart'
                  | 'sortNoInList'
                  | 'sortNoInProjectStatus'
                  | 'totalScheduleTimeSec'
                  | 'versionNo'
                  | 'workingTimeSec'
                >;
              };
            workingMembers: Array<
              Maybe<
                { __typename?: 'WorkingMemberInfo' } & Pick<
                  WorkingMemberInfo,
                  'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >
            >;
          }
      >
    >
  >;
};

export type AttachmentFileQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type AttachmentFileQuery = { __typename?: 'Query' } & {
  attachmentFile?: Maybe<
    { __typename?: 'AttachmentFile' } & Pick<
      AttachmentFile,
      'fileName' | 'id' | 'sizeByte' | 'type' | 'uploadDateTime'
    >
  >;
};

export type BoardProjectsQueryVariables = Exact<{
  assigneeIds?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  clientIds?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  favoriteCondition?: Maybe<ProjectFavoriteFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  projectName?: Maybe<Scalars['String']>;
  projectStatusId?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['String']>;
}>;

export type BoardProjectsQuery = { __typename?: 'Query' } & {
  boardProjects?: Maybe<
    { __typename?: 'ProjectStatusWithProject' } & {
      projectStatus: { __typename?: 'ProjectStatus' } & Pick<
        ProjectStatus,
        'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
      >;
      projects: Array<
        Maybe<
          { __typename?: 'Project' } & Pick<
            Project,
            | 'calendarLinkageCode'
            | 'color'
            | 'complete'
            | 'completeDateTime'
            | 'costBudgetWithoutTax'
            | 'description'
            | 'estimateTimeSec'
            | 'id'
            | 'key'
            | 'laborCost'
            | 'laborCostWithoutTax'
            | 'laborShare'
            | 'name'
            | 'priority'
            | 'registerDateTime'
            | 'salesAmountWithoutTax'
            | 'scheduledEndDateTime'
            | 'scheduledStartDateTime'
            | 'sortNoInGanttChart'
            | 'sortNoInList'
            | 'sortNoInProjectStatus'
            | 'totalScheduleTimeSec'
            | 'versionNo'
            | 'workingTimeSec'
          > & {
              assignees: Array<
                { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                  AssignedProjectMemberInfo,
                  'sortNo'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >;
              client?: Maybe<
                { __typename?: 'Client' } & Pick<
                  Client,
                  'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                >
              >;
              createdMember?: Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >;
              favoriteMembers: Array<
                Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >
              >;
              favoriteMembersInfo: Array<
                Maybe<
                  { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                    FavoriteProjectMemberInfo,
                    'sortNo'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >
              >;
              projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                ProjectStatus,
                'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
              >;
              team: { __typename?: 'Team' } & Pick<
                Team,
                'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
              > & {
                  organization: { __typename?: 'Organization' } & Pick<
                    Organization,
                    | 'allowConnectThirdPartyCalendar'
                    | 'contactMailAddress'
                    | 'contractPreriod'
                    | 'id'
                    | 'imageUrl'
                    | 'ipRestrictionFlg'
                    | 'name'
                    | 'requiredTwoFactorAuth'
                    | 'suspensionFlg'
                    | 'versionNo'
                  > & {
                      plan: { __typename?: 'PlanProduct' } & Pick<
                        PlanProduct,
                        'code' | 'contractPreriod' | 'id'
                      >;
                    };
                };
            }
        >
      >;
    }
  >;
};

export type BoardTasksQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  projectId?: Maybe<Scalars['String']>;
  taskStatusId?: Maybe<Scalars['String']>;
}>;

export type BoardTasksQuery = { __typename?: 'Query' } & {
  boardTasks?: Maybe<
    { __typename?: 'TaskStatusWithTask' } & {
      taskStatus: { __typename?: 'TaskStatus' } & Pick<
        TaskStatus,
        'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
      > & {
          project: { __typename?: 'Project' } & Pick<
            Project,
            | 'calendarLinkageCode'
            | 'color'
            | 'complete'
            | 'completeDateTime'
            | 'costBudgetWithoutTax'
            | 'description'
            | 'estimateTimeSec'
            | 'id'
            | 'key'
            | 'laborCost'
            | 'laborCostWithoutTax'
            | 'laborShare'
            | 'name'
            | 'priority'
            | 'registerDateTime'
            | 'salesAmountWithoutTax'
            | 'scheduledEndDateTime'
            | 'scheduledStartDateTime'
            | 'sortNoInGanttChart'
            | 'sortNoInList'
            | 'sortNoInProjectStatus'
            | 'totalScheduleTimeSec'
            | 'versionNo'
            | 'workingTimeSec'
          > & {
              assignees: Array<
                { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                  AssignedProjectMemberInfo,
                  'sortNo'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >;
              client?: Maybe<
                { __typename?: 'Client' } & Pick<
                  Client,
                  'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                >
              >;
              createdMember?: Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >;
              favoriteMembers: Array<
                Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >
              >;
              favoriteMembersInfo: Array<
                Maybe<
                  { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                    FavoriteProjectMemberInfo,
                    'sortNo'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >
              >;
              projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                ProjectStatus,
                'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
              >;
              team: { __typename?: 'Team' } & Pick<
                Team,
                'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
              > & {
                  organization: { __typename?: 'Organization' } & Pick<
                    Organization,
                    | 'allowConnectThirdPartyCalendar'
                    | 'contactMailAddress'
                    | 'contractPreriod'
                    | 'id'
                    | 'imageUrl'
                    | 'ipRestrictionFlg'
                    | 'name'
                    | 'requiredTwoFactorAuth'
                    | 'suspensionFlg'
                    | 'versionNo'
                  > & {
                      plan: { __typename?: 'PlanProduct' } & Pick<
                        PlanProduct,
                        'code' | 'contractPreriod' | 'id'
                      >;
                    };
                };
            };
        };
      tasks: Array<
        Maybe<
          { __typename?: 'Task' } & Pick<
            Task,
            | 'calendarLinkageCode'
            | 'completeDateTime'
            | 'description'
            | 'estimateTimeSec'
            | 'estimatedRemainingTimeSec'
            | 'id'
            | 'laborCost'
            | 'priority'
            | 'progressDeviationRate'
            | 'progressRate'
            | 'registerDateTime'
            | 'remainingTimeSec'
            | 'scheduleWorkingTimeSec'
            | 'scheduledEndDateTime'
            | 'scheduledStartDateTime'
            | 'sortNoInGanttChart'
            | 'sortNoInList'
            | 'sortNoInMyTask'
            | 'sortNoInTaskStatus'
            | 'title'
            | 'versionNo'
            | 'workingTimeSec'
          > & {
              assignees: Array<
                { __typename?: 'AssignedTaskMemberInfo' } & Pick<AssignedTaskMemberInfo, 'sortNo'>
              >;
              asssignee?: Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >;
              createdMember: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
              favoriteMembers: Array<
                Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >
              >;
              favoriteMembersInfo: Array<
                Maybe<
                  { __typename?: 'FavoriteTaskMemberInfo' } & Pick<FavoriteTaskMemberInfo, 'sortNo'>
                >
              >;
              project: { __typename?: 'Project' } & Pick<
                Project,
                | 'calendarLinkageCode'
                | 'color'
                | 'complete'
                | 'completeDateTime'
                | 'costBudgetWithoutTax'
                | 'description'
                | 'estimateTimeSec'
                | 'id'
                | 'key'
                | 'laborCost'
                | 'laborCostWithoutTax'
                | 'laborShare'
                | 'name'
                | 'priority'
                | 'registerDateTime'
                | 'salesAmountWithoutTax'
                | 'scheduledEndDateTime'
                | 'scheduledStartDateTime'
                | 'sortNoInGanttChart'
                | 'sortNoInList'
                | 'sortNoInProjectStatus'
                | 'totalScheduleTimeSec'
                | 'versionNo'
                | 'workingTimeSec'
              >;
              taskStatus: { __typename?: 'TaskStatus' } & Pick<
                TaskStatus,
                'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
              >;
              workingMembers: Array<
                Maybe<
                  { __typename?: 'WorkingMemberInfo' } & Pick<
                    WorkingMemberInfo,
                    'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >
              >;
            }
        >
      >;
    }
  >;
};

export type CalendarEventQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type CalendarEventQuery = { __typename?: 'Query' } & {
  calendarEvent?: Maybe<
    { __typename?: 'CalendarEvent' } & Pick<
      CalendarEvent,
      | 'calendarType'
      | 'clientId'
      | 'clientName'
      | 'endDateTime'
      | 'eventName'
      | 'id'
      | 'laborCost'
      | 'personalTeam'
      | 'projectColor'
      | 'projectId'
      | 'projectName'
      | 'startDateTime'
      | 'taskId'
      | 'taskTitle'
      | 'teamId'
      | 'teamName'
      | 'thirdPartyCalendarEventId'
      | 'versionNo'
      | 'workingTimeSec'
    > & {
        thirdPartyCalendarInfoList: Array<
          Maybe<
            { __typename?: 'ThirdPartyCalendarInfo' } & Pick<
              ThirdPartyCalendarInfo,
              'thirdPartyCalendarId'
            > & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
      }
  >;
};

export type CalendarEventWorkingHistoriesQueryVariables = Exact<{
  calendarEventId: Scalars['String'];
  end?: Maybe<Scalars['DateTime']>;
  start?: Maybe<Scalars['DateTime']>;
}>;

export type CalendarEventWorkingHistoriesQuery = { __typename?: 'Query' } & {
  calendarEventWorkingHistories?: Maybe<
    Array<
      Maybe<
        { __typename?: 'WorkingHistory' } & Pick<
          WorkingHistory,
          'end' | 'id' | 'memo' | 'start' | 'versionNo' | 'workingTimeSec'
        > & {
            calendarEvent?: Maybe<
              { __typename?: 'CalendarEvent' } & Pick<
                CalendarEvent,
                | 'calendarType'
                | 'clientId'
                | 'clientName'
                | 'endDateTime'
                | 'eventName'
                | 'id'
                | 'laborCost'
                | 'personalTeam'
                | 'projectColor'
                | 'projectId'
                | 'projectName'
                | 'startDateTime'
                | 'taskId'
                | 'taskTitle'
                | 'teamId'
                | 'teamName'
                | 'thirdPartyCalendarEventId'
                | 'versionNo'
                | 'workingTimeSec'
              > & {
                  thirdPartyCalendarInfoList: Array<
                    Maybe<
                      { __typename?: 'ThirdPartyCalendarInfo' } & Pick<
                        ThirdPartyCalendarInfo,
                        'thirdPartyCalendarId'
                      > & {
                          member: { __typename?: 'Member' } & Pick<
                            Member,
                            | 'adminRole'
                            | 'clientManagementRole'
                            | 'department'
                            | 'employeeNumber'
                            | 'id'
                            | 'laborCostManagementRole'
                            | 'mailAddress'
                            | 'memberManagementRole'
                            | 'memberRegistrationDateTime'
                            | 'memberStatus'
                            | 'name'
                            | 'organizationMemberViewRole'
                            | 'organizationReportRole'
                            | 'profileImageUrl'
                            | 'projectCreatePermissionFlg'
                            | 'projectCustomAttributePermissionFlg'
                            | 'projectDeletePermissionFlg'
                            | 'projectUpdatePermissionFlg'
                            | 'proxyEditWorkingDataRole'
                            | 'salesManagementRole'
                            | 'statusMessage'
                            | 'taskCreatePermissionFlg'
                            | 'taskCustomAttributePermissionFlg'
                            | 'taskDeletePermissionFlg'
                            | 'taskUpdatePermissionFlg'
                            | 'teamMemberViewRole'
                            | 'teamReportRole'
                            | 'versionNo'
                          >;
                        }
                    >
                  >;
                }
            >;
            closingProcessInfo: { __typename?: 'ClosingProcessPart' } & Pick<
              ClosingProcessPart,
              'closed' | 'targetDateTime'
            >;
            task?: Maybe<
              { __typename?: 'Task' } & Pick<
                Task,
                | 'calendarLinkageCode'
                | 'completeDateTime'
                | 'description'
                | 'estimateTimeSec'
                | 'estimatedRemainingTimeSec'
                | 'id'
                | 'laborCost'
                | 'priority'
                | 'progressDeviationRate'
                | 'progressRate'
                | 'registerDateTime'
                | 'remainingTimeSec'
                | 'scheduleWorkingTimeSec'
                | 'scheduledEndDateTime'
                | 'scheduledStartDateTime'
                | 'sortNoInGanttChart'
                | 'sortNoInList'
                | 'sortNoInMyTask'
                | 'sortNoInTaskStatus'
                | 'title'
                | 'versionNo'
                | 'workingTimeSec'
              > & {
                  assignees: Array<
                    { __typename?: 'AssignedTaskMemberInfo' } & Pick<
                      AssignedTaskMemberInfo,
                      'sortNo'
                    > & {
                        member: { __typename?: 'Member' } & Pick<
                          Member,
                          | 'adminRole'
                          | 'clientManagementRole'
                          | 'department'
                          | 'employeeNumber'
                          | 'id'
                          | 'laborCostManagementRole'
                          | 'mailAddress'
                          | 'memberManagementRole'
                          | 'memberRegistrationDateTime'
                          | 'memberStatus'
                          | 'name'
                          | 'organizationMemberViewRole'
                          | 'organizationReportRole'
                          | 'profileImageUrl'
                          | 'projectCreatePermissionFlg'
                          | 'projectCustomAttributePermissionFlg'
                          | 'projectDeletePermissionFlg'
                          | 'projectUpdatePermissionFlg'
                          | 'proxyEditWorkingDataRole'
                          | 'salesManagementRole'
                          | 'statusMessage'
                          | 'taskCreatePermissionFlg'
                          | 'taskCustomAttributePermissionFlg'
                          | 'taskDeletePermissionFlg'
                          | 'taskUpdatePermissionFlg'
                          | 'teamMemberViewRole'
                          | 'teamReportRole'
                          | 'versionNo'
                        >;
                      }
                  >;
                  asssignee?: Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >;
                  createdMember: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                  favoriteMembers: Array<
                    Maybe<
                      { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >
                    >
                  >;
                  favoriteMembersInfo: Array<
                    Maybe<
                      { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                        FavoriteTaskMemberInfo,
                        'sortNo'
                      > & {
                          member: { __typename?: 'Member' } & Pick<
                            Member,
                            | 'adminRole'
                            | 'clientManagementRole'
                            | 'department'
                            | 'employeeNumber'
                            | 'id'
                            | 'laborCostManagementRole'
                            | 'mailAddress'
                            | 'memberManagementRole'
                            | 'memberRegistrationDateTime'
                            | 'memberStatus'
                            | 'name'
                            | 'organizationMemberViewRole'
                            | 'organizationReportRole'
                            | 'profileImageUrl'
                            | 'projectCreatePermissionFlg'
                            | 'projectCustomAttributePermissionFlg'
                            | 'projectDeletePermissionFlg'
                            | 'projectUpdatePermissionFlg'
                            | 'proxyEditWorkingDataRole'
                            | 'salesManagementRole'
                            | 'statusMessage'
                            | 'taskCreatePermissionFlg'
                            | 'taskCustomAttributePermissionFlg'
                            | 'taskDeletePermissionFlg'
                            | 'taskUpdatePermissionFlg'
                            | 'teamMemberViewRole'
                            | 'teamReportRole'
                            | 'versionNo'
                          >;
                        }
                    >
                  >;
                  project: { __typename?: 'Project' } & Pick<
                    Project,
                    | 'calendarLinkageCode'
                    | 'color'
                    | 'complete'
                    | 'completeDateTime'
                    | 'costBudgetWithoutTax'
                    | 'description'
                    | 'estimateTimeSec'
                    | 'id'
                    | 'key'
                    | 'laborCost'
                    | 'laborCostWithoutTax'
                    | 'laborShare'
                    | 'name'
                    | 'priority'
                    | 'registerDateTime'
                    | 'salesAmountWithoutTax'
                    | 'scheduledEndDateTime'
                    | 'scheduledStartDateTime'
                    | 'sortNoInGanttChart'
                    | 'sortNoInList'
                    | 'sortNoInProjectStatus'
                    | 'totalScheduleTimeSec'
                    | 'versionNo'
                    | 'workingTimeSec'
                  > & {
                      assignees: Array<
                        { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                          AssignedProjectMemberInfo,
                          'sortNo'
                        >
                      >;
                      client?: Maybe<
                        { __typename?: 'Client' } & Pick<
                          Client,
                          'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                        >
                      >;
                      createdMember?: Maybe<
                        { __typename?: 'Member' } & Pick<
                          Member,
                          | 'adminRole'
                          | 'clientManagementRole'
                          | 'department'
                          | 'employeeNumber'
                          | 'id'
                          | 'laborCostManagementRole'
                          | 'mailAddress'
                          | 'memberManagementRole'
                          | 'memberRegistrationDateTime'
                          | 'memberStatus'
                          | 'name'
                          | 'organizationMemberViewRole'
                          | 'organizationReportRole'
                          | 'profileImageUrl'
                          | 'projectCreatePermissionFlg'
                          | 'projectCustomAttributePermissionFlg'
                          | 'projectDeletePermissionFlg'
                          | 'projectUpdatePermissionFlg'
                          | 'proxyEditWorkingDataRole'
                          | 'salesManagementRole'
                          | 'statusMessage'
                          | 'taskCreatePermissionFlg'
                          | 'taskCustomAttributePermissionFlg'
                          | 'taskDeletePermissionFlg'
                          | 'taskUpdatePermissionFlg'
                          | 'teamMemberViewRole'
                          | 'teamReportRole'
                          | 'versionNo'
                        >
                      >;
                      favoriteMembers: Array<
                        Maybe<
                          { __typename?: 'Member' } & Pick<
                            Member,
                            | 'adminRole'
                            | 'clientManagementRole'
                            | 'department'
                            | 'employeeNumber'
                            | 'id'
                            | 'laborCostManagementRole'
                            | 'mailAddress'
                            | 'memberManagementRole'
                            | 'memberRegistrationDateTime'
                            | 'memberStatus'
                            | 'name'
                            | 'organizationMemberViewRole'
                            | 'organizationReportRole'
                            | 'profileImageUrl'
                            | 'projectCreatePermissionFlg'
                            | 'projectCustomAttributePermissionFlg'
                            | 'projectDeletePermissionFlg'
                            | 'projectUpdatePermissionFlg'
                            | 'proxyEditWorkingDataRole'
                            | 'salesManagementRole'
                            | 'statusMessage'
                            | 'taskCreatePermissionFlg'
                            | 'taskCustomAttributePermissionFlg'
                            | 'taskDeletePermissionFlg'
                            | 'taskUpdatePermissionFlg'
                            | 'teamMemberViewRole'
                            | 'teamReportRole'
                            | 'versionNo'
                          >
                        >
                      >;
                      favoriteMembersInfo: Array<
                        Maybe<
                          { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                            FavoriteProjectMemberInfo,
                            'sortNo'
                          >
                        >
                      >;
                      projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                        ProjectStatus,
                        'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                      >;
                      team: { __typename?: 'Team' } & Pick<
                        Team,
                        'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                      > & {
                          organization: { __typename?: 'Organization' } & Pick<
                            Organization,
                            | 'allowConnectThirdPartyCalendar'
                            | 'contactMailAddress'
                            | 'contractPreriod'
                            | 'id'
                            | 'imageUrl'
                            | 'ipRestrictionFlg'
                            | 'name'
                            | 'requiredTwoFactorAuth'
                            | 'suspensionFlg'
                            | 'versionNo'
                          > & {
                              plan: { __typename?: 'PlanProduct' } & Pick<
                                PlanProduct,
                                'code' | 'contractPreriod' | 'id'
                              >;
                            };
                        };
                    };
                  taskStatus: { __typename?: 'TaskStatus' } & Pick<
                    TaskStatus,
                    'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
                  > & {
                      project: { __typename?: 'Project' } & Pick<
                        Project,
                        | 'calendarLinkageCode'
                        | 'color'
                        | 'complete'
                        | 'completeDateTime'
                        | 'costBudgetWithoutTax'
                        | 'description'
                        | 'estimateTimeSec'
                        | 'id'
                        | 'key'
                        | 'laborCost'
                        | 'laborCostWithoutTax'
                        | 'laborShare'
                        | 'name'
                        | 'priority'
                        | 'registerDateTime'
                        | 'salesAmountWithoutTax'
                        | 'scheduledEndDateTime'
                        | 'scheduledStartDateTime'
                        | 'sortNoInGanttChart'
                        | 'sortNoInList'
                        | 'sortNoInProjectStatus'
                        | 'totalScheduleTimeSec'
                        | 'versionNo'
                        | 'workingTimeSec'
                      >;
                    };
                  workingMembers: Array<
                    Maybe<
                      { __typename?: 'WorkingMemberInfo' } & Pick<
                        WorkingMemberInfo,
                        'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                      > & {
                          member: { __typename?: 'Member' } & Pick<
                            Member,
                            | 'adminRole'
                            | 'clientManagementRole'
                            | 'department'
                            | 'employeeNumber'
                            | 'id'
                            | 'laborCostManagementRole'
                            | 'mailAddress'
                            | 'memberManagementRole'
                            | 'memberRegistrationDateTime'
                            | 'memberStatus'
                            | 'name'
                            | 'organizationMemberViewRole'
                            | 'organizationReportRole'
                            | 'profileImageUrl'
                            | 'projectCreatePermissionFlg'
                            | 'projectCustomAttributePermissionFlg'
                            | 'projectDeletePermissionFlg'
                            | 'projectUpdatePermissionFlg'
                            | 'proxyEditWorkingDataRole'
                            | 'salesManagementRole'
                            | 'statusMessage'
                            | 'taskCreatePermissionFlg'
                            | 'taskCustomAttributePermissionFlg'
                            | 'taskDeletePermissionFlg'
                            | 'taskUpdatePermissionFlg'
                            | 'teamMemberViewRole'
                            | 'teamReportRole'
                            | 'versionNo'
                          >;
                        }
                    >
                  >;
                }
            >;
            workingMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
          }
      >
    >
  >;
};

export type CalendarEventWorkingHistoriesForSummaryQueryVariables = Exact<{
  calendarEventId: Scalars['String'];
  end?: Maybe<Scalars['DateTime']>;
  start?: Maybe<Scalars['DateTime']>;
}>;

export type CalendarEventWorkingHistoriesForSummaryQuery = { __typename?: 'Query' } & {
  calendarEventWorkingHistoriesForSummary?: Maybe<
    Array<
      Maybe<
        { __typename?: 'WorkingHistoryForSummary' } & Pick<
          WorkingHistoryForSummary,
          'end' | 'id' | 'memo' | 'start' | 'versionNo' | 'workingTimeSec'
        > & {
            calendarEvent?: Maybe<
              { __typename?: 'WorkingHistoryForSummaryCalendarEventPart' } & Pick<
                WorkingHistoryForSummaryCalendarEventPart,
                | 'clientId'
                | 'clientName'
                | 'eventName'
                | 'id'
                | 'projectId'
                | 'projectName'
                | 'teamId'
              >
            >;
            closingProcessInfo: {
              __typename?: 'WorkingHistoryForSummaryClosingProcessPart';
            } & Pick<WorkingHistoryForSummaryClosingProcessPart, 'closed' | 'targetDateTime'>;
            task?: Maybe<
              { __typename?: 'WorkingHistoryForSummaryTaskPart' } & Pick<
                WorkingHistoryForSummaryTaskPart,
                'id' | 'title' | 'versionNo'
              > & {
                  project: { __typename?: 'WorkingHistoryForSummaryProjectPart' } & Pick<
                    WorkingHistoryForSummaryProjectPart,
                    'id' | 'name'
                  > & {
                      client?: Maybe<
                        { __typename?: 'WorkingHistoryForSummaryClientPart' } & Pick<
                          WorkingHistoryForSummaryClientPart,
                          'id' | 'name'
                        >
                      >;
                      team: { __typename?: 'WorkingHistoryForSummaryTeamPart' } & Pick<
                        WorkingHistoryForSummaryTeamPart,
                        'id' | 'personalTeam'
                      >;
                    };
                }
            >;
            workingMember: { __typename?: 'WorkingHistoryForSummaryMemberPart' } & Pick<
              WorkingHistoryForSummaryMemberPart,
              'id' | 'name' | 'profileImageUrl'
            >;
          }
      >
    >
  >;
};

export type CalendarEventWorkingSchedulesQueryVariables = Exact<{
  calendarEventId: Scalars['String'];
}>;

export type CalendarEventWorkingSchedulesQuery = { __typename?: 'Query' } & {
  calendarEventWorkingSchedules?: Maybe<
    Array<
      Maybe<
        { __typename?: 'WorkingSchedule' } & Pick<
          WorkingSchedule,
          'end' | 'id' | 'memo' | 'start' | 'versionNo' | 'workingTimeSec'
        > & {
            calendarEvent?: Maybe<
              { __typename?: 'CalendarEvent' } & Pick<
                CalendarEvent,
                | 'calendarType'
                | 'clientId'
                | 'clientName'
                | 'endDateTime'
                | 'eventName'
                | 'id'
                | 'laborCost'
                | 'personalTeam'
                | 'projectColor'
                | 'projectId'
                | 'projectName'
                | 'startDateTime'
                | 'taskId'
                | 'taskTitle'
                | 'teamId'
                | 'teamName'
                | 'thirdPartyCalendarEventId'
                | 'versionNo'
                | 'workingTimeSec'
              > & {
                  thirdPartyCalendarInfoList: Array<
                    Maybe<
                      { __typename?: 'ThirdPartyCalendarInfo' } & Pick<
                        ThirdPartyCalendarInfo,
                        'thirdPartyCalendarId'
                      > & {
                          member: { __typename?: 'Member' } & Pick<
                            Member,
                            | 'adminRole'
                            | 'clientManagementRole'
                            | 'department'
                            | 'employeeNumber'
                            | 'id'
                            | 'laborCostManagementRole'
                            | 'mailAddress'
                            | 'memberManagementRole'
                            | 'memberRegistrationDateTime'
                            | 'memberStatus'
                            | 'name'
                            | 'organizationMemberViewRole'
                            | 'organizationReportRole'
                            | 'profileImageUrl'
                            | 'projectCreatePermissionFlg'
                            | 'projectCustomAttributePermissionFlg'
                            | 'projectDeletePermissionFlg'
                            | 'projectUpdatePermissionFlg'
                            | 'proxyEditWorkingDataRole'
                            | 'salesManagementRole'
                            | 'statusMessage'
                            | 'taskCreatePermissionFlg'
                            | 'taskCustomAttributePermissionFlg'
                            | 'taskDeletePermissionFlg'
                            | 'taskUpdatePermissionFlg'
                            | 'teamMemberViewRole'
                            | 'teamReportRole'
                            | 'versionNo'
                          >;
                        }
                    >
                  >;
                }
            >;
            task?: Maybe<
              { __typename?: 'Task' } & Pick<
                Task,
                | 'calendarLinkageCode'
                | 'completeDateTime'
                | 'description'
                | 'estimateTimeSec'
                | 'estimatedRemainingTimeSec'
                | 'id'
                | 'laborCost'
                | 'priority'
                | 'progressDeviationRate'
                | 'progressRate'
                | 'registerDateTime'
                | 'remainingTimeSec'
                | 'scheduleWorkingTimeSec'
                | 'scheduledEndDateTime'
                | 'scheduledStartDateTime'
                | 'sortNoInGanttChart'
                | 'sortNoInList'
                | 'sortNoInMyTask'
                | 'sortNoInTaskStatus'
                | 'title'
                | 'versionNo'
                | 'workingTimeSec'
              > & {
                  assignees: Array<
                    { __typename?: 'AssignedTaskMemberInfo' } & Pick<
                      AssignedTaskMemberInfo,
                      'sortNo'
                    > & {
                        member: { __typename?: 'Member' } & Pick<
                          Member,
                          | 'adminRole'
                          | 'clientManagementRole'
                          | 'department'
                          | 'employeeNumber'
                          | 'id'
                          | 'laborCostManagementRole'
                          | 'mailAddress'
                          | 'memberManagementRole'
                          | 'memberRegistrationDateTime'
                          | 'memberStatus'
                          | 'name'
                          | 'organizationMemberViewRole'
                          | 'organizationReportRole'
                          | 'profileImageUrl'
                          | 'projectCreatePermissionFlg'
                          | 'projectCustomAttributePermissionFlg'
                          | 'projectDeletePermissionFlg'
                          | 'projectUpdatePermissionFlg'
                          | 'proxyEditWorkingDataRole'
                          | 'salesManagementRole'
                          | 'statusMessage'
                          | 'taskCreatePermissionFlg'
                          | 'taskCustomAttributePermissionFlg'
                          | 'taskDeletePermissionFlg'
                          | 'taskUpdatePermissionFlg'
                          | 'teamMemberViewRole'
                          | 'teamReportRole'
                          | 'versionNo'
                        >;
                      }
                  >;
                  asssignee?: Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >;
                  createdMember: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                  favoriteMembers: Array<
                    Maybe<
                      { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >
                    >
                  >;
                  favoriteMembersInfo: Array<
                    Maybe<
                      { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                        FavoriteTaskMemberInfo,
                        'sortNo'
                      > & {
                          member: { __typename?: 'Member' } & Pick<
                            Member,
                            | 'adminRole'
                            | 'clientManagementRole'
                            | 'department'
                            | 'employeeNumber'
                            | 'id'
                            | 'laborCostManagementRole'
                            | 'mailAddress'
                            | 'memberManagementRole'
                            | 'memberRegistrationDateTime'
                            | 'memberStatus'
                            | 'name'
                            | 'organizationMemberViewRole'
                            | 'organizationReportRole'
                            | 'profileImageUrl'
                            | 'projectCreatePermissionFlg'
                            | 'projectCustomAttributePermissionFlg'
                            | 'projectDeletePermissionFlg'
                            | 'projectUpdatePermissionFlg'
                            | 'proxyEditWorkingDataRole'
                            | 'salesManagementRole'
                            | 'statusMessage'
                            | 'taskCreatePermissionFlg'
                            | 'taskCustomAttributePermissionFlg'
                            | 'taskDeletePermissionFlg'
                            | 'taskUpdatePermissionFlg'
                            | 'teamMemberViewRole'
                            | 'teamReportRole'
                            | 'versionNo'
                          >;
                        }
                    >
                  >;
                  project: { __typename?: 'Project' } & Pick<
                    Project,
                    | 'calendarLinkageCode'
                    | 'color'
                    | 'complete'
                    | 'completeDateTime'
                    | 'costBudgetWithoutTax'
                    | 'description'
                    | 'estimateTimeSec'
                    | 'id'
                    | 'key'
                    | 'laborCost'
                    | 'laborCostWithoutTax'
                    | 'laborShare'
                    | 'name'
                    | 'priority'
                    | 'registerDateTime'
                    | 'salesAmountWithoutTax'
                    | 'scheduledEndDateTime'
                    | 'scheduledStartDateTime'
                    | 'sortNoInGanttChart'
                    | 'sortNoInList'
                    | 'sortNoInProjectStatus'
                    | 'totalScheduleTimeSec'
                    | 'versionNo'
                    | 'workingTimeSec'
                  > & {
                      assignees: Array<
                        { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                          AssignedProjectMemberInfo,
                          'sortNo'
                        >
                      >;
                      client?: Maybe<
                        { __typename?: 'Client' } & Pick<
                          Client,
                          'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                        >
                      >;
                      createdMember?: Maybe<
                        { __typename?: 'Member' } & Pick<
                          Member,
                          | 'adminRole'
                          | 'clientManagementRole'
                          | 'department'
                          | 'employeeNumber'
                          | 'id'
                          | 'laborCostManagementRole'
                          | 'mailAddress'
                          | 'memberManagementRole'
                          | 'memberRegistrationDateTime'
                          | 'memberStatus'
                          | 'name'
                          | 'organizationMemberViewRole'
                          | 'organizationReportRole'
                          | 'profileImageUrl'
                          | 'projectCreatePermissionFlg'
                          | 'projectCustomAttributePermissionFlg'
                          | 'projectDeletePermissionFlg'
                          | 'projectUpdatePermissionFlg'
                          | 'proxyEditWorkingDataRole'
                          | 'salesManagementRole'
                          | 'statusMessage'
                          | 'taskCreatePermissionFlg'
                          | 'taskCustomAttributePermissionFlg'
                          | 'taskDeletePermissionFlg'
                          | 'taskUpdatePermissionFlg'
                          | 'teamMemberViewRole'
                          | 'teamReportRole'
                          | 'versionNo'
                        >
                      >;
                      favoriteMembers: Array<
                        Maybe<
                          { __typename?: 'Member' } & Pick<
                            Member,
                            | 'adminRole'
                            | 'clientManagementRole'
                            | 'department'
                            | 'employeeNumber'
                            | 'id'
                            | 'laborCostManagementRole'
                            | 'mailAddress'
                            | 'memberManagementRole'
                            | 'memberRegistrationDateTime'
                            | 'memberStatus'
                            | 'name'
                            | 'organizationMemberViewRole'
                            | 'organizationReportRole'
                            | 'profileImageUrl'
                            | 'projectCreatePermissionFlg'
                            | 'projectCustomAttributePermissionFlg'
                            | 'projectDeletePermissionFlg'
                            | 'projectUpdatePermissionFlg'
                            | 'proxyEditWorkingDataRole'
                            | 'salesManagementRole'
                            | 'statusMessage'
                            | 'taskCreatePermissionFlg'
                            | 'taskCustomAttributePermissionFlg'
                            | 'taskDeletePermissionFlg'
                            | 'taskUpdatePermissionFlg'
                            | 'teamMemberViewRole'
                            | 'teamReportRole'
                            | 'versionNo'
                          >
                        >
                      >;
                      favoriteMembersInfo: Array<
                        Maybe<
                          { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                            FavoriteProjectMemberInfo,
                            'sortNo'
                          >
                        >
                      >;
                      projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                        ProjectStatus,
                        'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                      >;
                      team: { __typename?: 'Team' } & Pick<
                        Team,
                        'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                      > & {
                          organization: { __typename?: 'Organization' } & Pick<
                            Organization,
                            | 'allowConnectThirdPartyCalendar'
                            | 'contactMailAddress'
                            | 'contractPreriod'
                            | 'id'
                            | 'imageUrl'
                            | 'ipRestrictionFlg'
                            | 'name'
                            | 'requiredTwoFactorAuth'
                            | 'suspensionFlg'
                            | 'versionNo'
                          > & {
                              plan: { __typename?: 'PlanProduct' } & Pick<
                                PlanProduct,
                                'code' | 'contractPreriod' | 'id'
                              >;
                            };
                        };
                    };
                  taskStatus: { __typename?: 'TaskStatus' } & Pick<
                    TaskStatus,
                    'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
                  > & {
                      project: { __typename?: 'Project' } & Pick<
                        Project,
                        | 'calendarLinkageCode'
                        | 'color'
                        | 'complete'
                        | 'completeDateTime'
                        | 'costBudgetWithoutTax'
                        | 'description'
                        | 'estimateTimeSec'
                        | 'id'
                        | 'key'
                        | 'laborCost'
                        | 'laborCostWithoutTax'
                        | 'laborShare'
                        | 'name'
                        | 'priority'
                        | 'registerDateTime'
                        | 'salesAmountWithoutTax'
                        | 'scheduledEndDateTime'
                        | 'scheduledStartDateTime'
                        | 'sortNoInGanttChart'
                        | 'sortNoInList'
                        | 'sortNoInProjectStatus'
                        | 'totalScheduleTimeSec'
                        | 'versionNo'
                        | 'workingTimeSec'
                      >;
                    };
                  workingMembers: Array<
                    Maybe<
                      { __typename?: 'WorkingMemberInfo' } & Pick<
                        WorkingMemberInfo,
                        'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                      > & {
                          member: { __typename?: 'Member' } & Pick<
                            Member,
                            | 'adminRole'
                            | 'clientManagementRole'
                            | 'department'
                            | 'employeeNumber'
                            | 'id'
                            | 'laborCostManagementRole'
                            | 'mailAddress'
                            | 'memberManagementRole'
                            | 'memberRegistrationDateTime'
                            | 'memberStatus'
                            | 'name'
                            | 'organizationMemberViewRole'
                            | 'organizationReportRole'
                            | 'profileImageUrl'
                            | 'projectCreatePermissionFlg'
                            | 'projectCustomAttributePermissionFlg'
                            | 'projectDeletePermissionFlg'
                            | 'projectUpdatePermissionFlg'
                            | 'proxyEditWorkingDataRole'
                            | 'salesManagementRole'
                            | 'statusMessage'
                            | 'taskCreatePermissionFlg'
                            | 'taskCustomAttributePermissionFlg'
                            | 'taskDeletePermissionFlg'
                            | 'taskUpdatePermissionFlg'
                            | 'teamMemberViewRole'
                            | 'teamReportRole'
                            | 'versionNo'
                          >;
                        }
                    >
                  >;
                }
            >;
            workingMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
          }
      >
    >
  >;
};

export type CalendarEventWorkingSchedulesForSummaryQueryVariables = Exact<{
  calendarEventId: Scalars['String'];
}>;

export type CalendarEventWorkingSchedulesForSummaryQuery = { __typename?: 'Query' } & {
  calendarEventWorkingSchedulesForSummary?: Maybe<
    Array<
      Maybe<
        { __typename?: 'WorkingScheduleForSummary' } & Pick<
          WorkingScheduleForSummary,
          'end' | 'id' | 'memo' | 'start' | 'versionNo' | 'workingTimeSec'
        > & {
            calendarEvent?: Maybe<
              { __typename?: 'WorkingScheduleForSummaryCalendarEventPart' } & Pick<
                WorkingScheduleForSummaryCalendarEventPart,
                | 'clientId'
                | 'clientName'
                | 'eventName'
                | 'id'
                | 'projectId'
                | 'projectName'
                | 'teamId'
              >
            >;
            task?: Maybe<
              { __typename?: 'WorkingScheduleForSummaryTaskPart' } & Pick<
                WorkingScheduleForSummaryTaskPart,
                'id' | 'title' | 'versionNo'
              > & {
                  project: { __typename?: 'WorkingScheduleForSummaryProjectPart' } & Pick<
                    WorkingScheduleForSummaryProjectPart,
                    'id' | 'name'
                  > & {
                      client?: Maybe<
                        { __typename?: 'WorkingScheduleForSummaryClientPart' } & Pick<
                          WorkingScheduleForSummaryClientPart,
                          'id' | 'name'
                        >
                      >;
                      team: { __typename?: 'WorkingScheduleForSummaryTeamPart' } & Pick<
                        WorkingScheduleForSummaryTeamPart,
                        'id' | 'personalTeam'
                      >;
                    };
                }
            >;
            workingMember: { __typename?: 'WorkingScheduleForSummaryMemberPart' } & Pick<
              WorkingScheduleForSummaryMemberPart,
              'id' | 'name' | 'profileImageUrl'
            >;
          }
      >
    >
  >;
};

export type CalendarWorkingHistoryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type CalendarWorkingHistoryQuery = { __typename?: 'Query' } & {
  calendarWorkingHistory?: Maybe<
    { __typename?: 'CalendarWorkingHistory' } & Pick<
      CalendarWorkingHistory,
      'end' | 'id' | 'memo' | 'start' | 'versionNo' | 'workingTimeSec'
    > & {
        calendarEvent?: Maybe<
          { __typename?: 'WorkingHistoryCalendarEventPart' } & Pick<
            WorkingHistoryCalendarEventPart,
            | 'calendarType'
            | 'clientId'
            | 'clientName'
            | 'endDateTime'
            | 'eventName'
            | 'id'
            | 'personalTeam'
            | 'projectColor'
            | 'projectId'
            | 'projectName'
            | 'startDateTime'
            | 'taskId'
            | 'taskTitle'
            | 'teamId'
            | 'teamName'
            | 'thirdPartyCalendarEventId'
            | 'versionNo'
          >
        >;
        task?: Maybe<
          { __typename?: 'WorkingHistoryTaskPart' } & Pick<
            WorkingHistoryTaskPart,
            'completeDateTime' | 'id' | 'title' | 'versionNo'
          > & {
              project: { __typename?: 'WorkingHistoryProjectPart' } & Pick<
                WorkingHistoryProjectPart,
                'color' | 'complete' | 'id' | 'name'
              > & {
                  client?: Maybe<
                    { __typename?: 'WorkingHistoryClientPart' } & Pick<
                      WorkingHistoryClientPart,
                      'id' | 'name'
                    >
                  >;
                  team: { __typename?: 'WorkingHistoryTeamPart' } & Pick<
                    WorkingHistoryTeamPart,
                    'id' | 'name' | 'personalTeam'
                  >;
                };
              workingMembers: Array<
                Maybe<
                  { __typename?: 'WorkingHistoryWorkingMemberInfoPart' } & {
                    member: { __typename?: 'WorkingHistoryMemberPart' } & Pick<
                      WorkingHistoryMemberPart,
                      'id' | 'name' | 'profileImageUrl'
                    >;
                  }
                >
              >;
            }
        >;
        workingMember: { __typename?: 'WorkingHistoryMemberPart' } & Pick<
          WorkingHistoryMemberPart,
          'id' | 'name' | 'profileImageUrl'
        >;
      }
  >;
};

export type CalendarWorkingScheduleQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type CalendarWorkingScheduleQuery = { __typename?: 'Query' } & {
  calendarWorkingSchedule?: Maybe<
    { __typename?: 'CalendarWorkingSchedule' } & Pick<
      CalendarWorkingSchedule,
      'end' | 'id' | 'memo' | 'start' | 'versionNo' | 'workingTimeSec'
    > & {
        calendarEvent?: Maybe<
          { __typename?: 'WorkingScheduleCalendarEventPart' } & Pick<
            WorkingScheduleCalendarEventPart,
            | 'calendarType'
            | 'clientId'
            | 'clientName'
            | 'endDateTime'
            | 'eventName'
            | 'id'
            | 'personalTeam'
            | 'projectColor'
            | 'projectId'
            | 'projectName'
            | 'startDateTime'
            | 'taskId'
            | 'taskTitle'
            | 'teamId'
            | 'teamName'
            | 'thirdPartyCalendarEventId'
            | 'versionNo'
          >
        >;
        task?: Maybe<
          { __typename?: 'WorkingScheduleTaskPart' } & Pick<
            WorkingScheduleTaskPart,
            'completeDateTime' | 'id' | 'title' | 'versionNo'
          > & {
              project: { __typename?: 'WorkingScheduleProjectPart' } & Pick<
                WorkingScheduleProjectPart,
                'color' | 'complete' | 'id' | 'name'
              > & {
                  client?: Maybe<
                    { __typename?: 'WorkingScheduleClientPart' } & Pick<
                      WorkingScheduleClientPart,
                      'id' | 'name'
                    >
                  >;
                  team: { __typename?: 'WorkingScheduleTeamPart' } & Pick<
                    WorkingScheduleTeamPart,
                    'id' | 'name' | 'personalTeam'
                  >;
                };
              workingMembers: Array<
                Maybe<
                  { __typename?: 'WorkingScheduleWorkingMemberInfoPart' } & {
                    member: { __typename?: 'WorkingScheduleMemberPart' } & Pick<
                      WorkingScheduleMemberPart,
                      'id' | 'name' | 'profileImageUrl'
                    >;
                  }
                >
              >;
            }
        >;
        workingMember: { __typename?: 'WorkingScheduleMemberPart' } & Pick<
          WorkingScheduleMemberPart,
          'id' | 'name' | 'profileImageUrl'
        >;
      }
  >;
};

export type ClientQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ClientQuery = { __typename?: 'Query' } & {
  client?: Maybe<
    { __typename?: 'Client' } & Pick<Client, 'code' | 'id' | 'name' | 'sortNo' | 'versionNo'>
  >;
};

export type ClientBarGraphDataQueryVariables = Exact<{
  input: ClientGraphDataInputInput;
}>;

export type ClientBarGraphDataQuery = { __typename?: 'Query' } & {
  clientBarGraphData?: Maybe<
    Array<
      Maybe<
        { __typename?: 'ClientBarGraphData' } & Pick<
          ClientBarGraphData,
          'clientId' | 'clientName' | 'color' | 'percent' | 'reportType' | 'workingTimeSec'
        >
      >
    >
  >;
};

export type ClientByCodeQueryVariables = Exact<{
  code: Scalars['String'];
}>;

export type ClientByCodeQuery = { __typename?: 'Query' } & {
  clientByCode?: Maybe<
    { __typename?: 'Client' } & Pick<Client, 'code' | 'id' | 'name' | 'sortNo' | 'versionNo'>
  >;
};

export type ClientDateGraphDataQueryVariables = Exact<{
  input: ClientGraphDataInputInput;
}>;

export type ClientDateGraphDataQuery = { __typename?: 'Query' } & {
  clientDateGraphData?: Maybe<
    Array<
      Maybe<
        { __typename?: 'ClientDateGraphData' } & Pick<
          ClientDateGraphData,
          'date' | 'reportType'
        > & {
            items: Array<
              Maybe<
                { __typename?: 'ClientDateGraphDataDetail' } & Pick<
                  ClientDateGraphDataDetail,
                  'clientId' | 'clientName' | 'color' | 'percent' | 'workingTimeSec'
                >
              >
            >;
          }
      >
    >
  >;
};

export type ClientProjectsQueryVariables = Exact<{
  clientId: Scalars['String'];
}>;

export type ClientProjectsQuery = { __typename?: 'Query' } & {
  clientProjects?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Project' } & Pick<
          Project,
          | 'calendarLinkageCode'
          | 'color'
          | 'complete'
          | 'completeDateTime'
          | 'costBudgetWithoutTax'
          | 'description'
          | 'estimateTimeSec'
          | 'id'
          | 'key'
          | 'laborCost'
          | 'laborCostWithoutTax'
          | 'laborShare'
          | 'name'
          | 'priority'
          | 'registerDateTime'
          | 'salesAmountWithoutTax'
          | 'scheduledEndDateTime'
          | 'scheduledStartDateTime'
          | 'sortNoInGanttChart'
          | 'sortNoInList'
          | 'sortNoInProjectStatus'
          | 'totalScheduleTimeSec'
          | 'versionNo'
          | 'workingTimeSec'
        > & {
            assignees: Array<
              { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                AssignedProjectMemberInfo,
                'sortNo'
              > & {
                  member: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                }
            >;
            client?: Maybe<
              { __typename?: 'Client' } & Pick<
                Client,
                'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
              >
            >;
            createdMember?: Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >;
            favoriteMembers: Array<
              Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >
            >;
            favoriteMembersInfo: Array<
              Maybe<
                { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                  FavoriteProjectMemberInfo,
                  'sortNo'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >
            >;
            projectStatus: { __typename?: 'ProjectStatus' } & Pick<
              ProjectStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
            >;
            team: { __typename?: 'Team' } & Pick<
              Team,
              'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
            > & {
                organization: { __typename?: 'Organization' } & Pick<
                  Organization,
                  | 'allowConnectThirdPartyCalendar'
                  | 'contactMailAddress'
                  | 'contractPreriod'
                  | 'id'
                  | 'imageUrl'
                  | 'ipRestrictionFlg'
                  | 'name'
                  | 'requiredTwoFactorAuth'
                  | 'suspensionFlg'
                  | 'versionNo'
                > & {
                    plan: { __typename?: 'PlanProduct' } & Pick<
                      PlanProduct,
                      'code' | 'contractPreriod' | 'id'
                    >;
                  };
              };
          }
      >
    >
  >;
};

export type ClientSunburstGraphDataQueryVariables = Exact<{
  input: ClientGraphDataInputInput;
}>;

export type ClientSunburstGraphDataQuery = { __typename?: 'Query' } & {
  clientSunburstGraphData?: Maybe<
    Array<
      Maybe<
        { __typename?: 'ClientSunburstGraphData' } & Pick<
          ClientSunburstGraphData,
          'clientId' | 'color' | 'name' | 'percent' | 'reportType' | 'workingTimeSec'
        >
      >
    >
  >;
};

export type ClosingSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type ClosingSettingsQuery = { __typename?: 'Query' } & {
  closingSettings?: Maybe<
    { __typename?: 'ClosingSetting' } & Pick<
      ClosingSetting,
      'id' | 'targetDateTime' | 'versionNo'
    > & {
        timeZone: { __typename?: 'TimeZone' } & Pick<
          TimeZone,
          'code' | 'id' | 'name' | 'offsetTime' | 'sortNo'
        >;
      }
  >;
};

export type ConfirmAmountAfterLicenceCountChangeQueryVariables = Exact<{
  id: Scalars['ID'];
  input?: Maybe<ConfirmAmountAfterLicenceCountChangeInputInput>;
}>;

export type ConfirmAmountAfterLicenceCountChangeQuery = { __typename?: 'Query' } & {
  confirmAmountAfterLicenceCountChange?: Maybe<
    { __typename?: 'AmountAfterLicenceCountChange' } & Pick<
      AmountAfterLicenceCountChange,
      'dailyRateAmount' | 'nextTotalAmount'
    >
  >;
};

export type ConfirmJoinableQueryVariables = Exact<{
  key: Scalars['String'];
}>;

export type ConfirmJoinableQuery = { __typename?: 'Query' } & Pick<Query, 'confirmJoinable'>;

export type ConfirmJoinableWithLinkQueryVariables = Exact<{
  key: Scalars['String'];
}>;

export type ConfirmJoinableWithLinkQuery = { __typename?: 'Query' } & Pick<
  Query,
  'confirmJoinableWithLink'
>;

export type ContractQueryVariables = Exact<{ [key: string]: never }>;

export type ContractQuery = { __typename?: 'Query' } & {
  contract?: Maybe<
    { __typename?: 'Contract' } & Pick<
      Contract,
      | 'billToMailAddress'
      | 'billingType'
      | 'contractPreriod'
      | 'freeTrialExpireDatetime'
      | 'id'
      | 'licenceCount'
      | 'revenuecatOriginalAppUserId'
      | 'usingLicenceCount'
      | 'versionNo'
    > & {
        freeLicenceCoupon?: Maybe<
          { __typename?: 'RangeFreeLicenceCoupon' } & Pick<
            RangeFreeLicenceCoupon,
            'freeEndLicenceCount' | 'freeStartLicenceCount' | 'id'
          >
        >;
        plan: { __typename?: 'PlanProduct' } & Pick<PlanProduct, 'code' | 'contractPreriod' | 'id'>;
      }
  >;
};

export type CreditCardQueryVariables = Exact<{ [key: string]: never }>;

export type CreditCardQuery = { __typename?: 'Query' } & {
  creditCard?: Maybe<
    { __typename?: 'CreditCard' } & Pick<CreditCard, 'cardNumberLast4' | 'expireYearMonth'>
  >;
};

export type CsvReportItemDefinitionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CsvReportItemDefinitionQuery = { __typename?: 'Query' } & {
  csvReportItemDefinition?: Maybe<
    { __typename?: 'CsvReportItemDefinition' } & Pick<
      CsvReportItemDefinition,
      'id' | 'name' | 'versionNo'
    > & {
        items: Array<
          Maybe<
            { __typename?: 'CsvReportItemDefinitionDetail' } & Pick<
              CsvReportItemDefinitionDetail,
              'id' | 'reportItemCode' | 'reportItemName' | 'sortNo'
            >
          >
        >;
      }
  >;
};

export type CsvReportItemDefinitionsQueryVariables = Exact<{ [key: string]: never }>;

export type CsvReportItemDefinitionsQuery = { __typename?: 'Query' } & {
  csvReportItemDefinitions?: Maybe<
    Array<
      Maybe<
        { __typename?: 'CsvReportItemDefinition' } & Pick<
          CsvReportItemDefinition,
          'id' | 'name' | 'versionNo'
        > & {
            items: Array<
              Maybe<
                { __typename?: 'CsvReportItemDefinitionDetail' } & Pick<
                  CsvReportItemDefinitionDetail,
                  'id' | 'reportItemCode' | 'reportItemName' | 'sortNo'
                >
              >
            >;
          }
      >
    >
  >;
};

export type DefaultCsvReportItemDefinitionQueryVariables = Exact<{ [key: string]: never }>;

export type DefaultCsvReportItemDefinitionQuery = { __typename?: 'Query' } & {
  defaultCsvReportItemDefinition?: Maybe<
    { __typename?: 'CsvReportItemDefinition' } & Pick<
      CsvReportItemDefinition,
      'id' | 'name' | 'versionNo'
    > & {
        items: Array<
          Maybe<
            { __typename?: 'CsvReportItemDefinitionDetail' } & Pick<
              CsvReportItemDefinitionDetail,
              'id' | 'reportItemCode' | 'reportItemName' | 'sortNo'
            >
          >
        >;
      }
  >;
};

export type EnvironmentQueryVariables = Exact<{ [key: string]: never }>;

export type EnvironmentQuery = { __typename?: 'Query' } & Pick<Query, 'environment'>;

export type ExistAdminMemberQueryVariables = Exact<{
  excludeMemberId: Scalars['String'];
}>;

export type ExistAdminMemberQuery = { __typename?: 'Query' } & Pick<Query, 'existAdminMember'>;

export type ExportClientExcelQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ExportClientExcelQuery = { __typename?: 'Query' } & {
  exportClientExcel?: Maybe<
    { __typename?: 'ExportClientExcel' } & Pick<
      ExportClientExcel,
      'id' | 'requestDateTime' | 'status' | 'timeZoneOffset' | 'url' | 'versionNo'
    > & {
        requestMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
      }
  >;
};

export type ExportClientExcelsQueryVariables = Exact<{ [key: string]: never }>;

export type ExportClientExcelsQuery = { __typename?: 'Query' } & {
  exportClientExcels?: Maybe<
    Array<
      Maybe<
        { __typename?: 'ExportClientExcel' } & Pick<
          ExportClientExcel,
          'id' | 'requestDateTime' | 'status' | 'timeZoneOffset' | 'url' | 'versionNo'
        > & {
            requestMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
          }
      >
    >
  >;
};

export type ExportMemberExcelQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ExportMemberExcelQuery = { __typename?: 'Query' } & {
  exportMemberExcel?: Maybe<
    { __typename?: 'ExportMemberExcel' } & Pick<
      ExportMemberExcel,
      'id' | 'requestDateTime' | 'status' | 'timeZoneOffset' | 'url' | 'versionNo'
    > & {
        requestMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
      }
  >;
};

export type ExportMemberExcelsQueryVariables = Exact<{ [key: string]: never }>;

export type ExportMemberExcelsQuery = { __typename?: 'Query' } & {
  exportMemberExcels?: Maybe<
    Array<
      Maybe<
        { __typename?: 'ExportMemberExcel' } & Pick<
          ExportMemberExcel,
          'id' | 'requestDateTime' | 'status' | 'timeZoneOffset' | 'url' | 'versionNo'
        > & {
            requestMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
          }
      >
    >
  >;
};

export type ExportProjectExcelQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ExportProjectExcelQuery = { __typename?: 'Query' } & {
  exportProjectExcel?: Maybe<
    { __typename?: 'ExportProjectExcel' } & Pick<
      ExportProjectExcel,
      | 'id'
      | 'includeCompleted'
      | 'requestDateTime'
      | 'status'
      | 'teamid'
      | 'timeZoneOffset'
      | 'url'
      | 'versionNo'
    > & {
        requestMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
      }
  >;
};

export type ExportProjectExcelsQueryVariables = Exact<{
  input: FetchExportProjectExcelInputInput;
}>;

export type ExportProjectExcelsQuery = { __typename?: 'Query' } & {
  exportProjectExcels?: Maybe<
    Array<
      Maybe<
        { __typename?: 'ExportProjectExcel' } & Pick<
          ExportProjectExcel,
          | 'id'
          | 'includeCompleted'
          | 'requestDateTime'
          | 'status'
          | 'teamid'
          | 'timeZoneOffset'
          | 'url'
          | 'versionNo'
        > & {
            requestMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
          }
      >
    >
  >;
};

export type ExportTaskExcelQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ExportTaskExcelQuery = { __typename?: 'Query' } & {
  exportTaskExcel?: Maybe<
    { __typename?: 'ExportTaskExcel' } & Pick<
      ExportTaskExcel,
      | 'id'
      | 'includeCompleted'
      | 'requestDateTime'
      | 'status'
      | 'teamid'
      | 'timeZoneOffset'
      | 'url'
      | 'versionNo'
    > & {
        requestMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
      }
  >;
};

export type ExportTaskExcelsQueryVariables = Exact<{
  input: FetchExportTaskExcelInputInput;
}>;

export type ExportTaskExcelsQuery = { __typename?: 'Query' } & {
  exportTaskExcels?: Maybe<
    Array<
      Maybe<
        { __typename?: 'ExportTaskExcel' } & Pick<
          ExportTaskExcel,
          | 'id'
          | 'includeCompleted'
          | 'requestDateTime'
          | 'status'
          | 'teamid'
          | 'timeZoneOffset'
          | 'url'
          | 'versionNo'
        > & {
            requestMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
          }
      >
    >
  >;
};

export type FavoriteProjectsQueryVariables = Exact<{
  input?: Maybe<FetchFavoriteProjectsInputInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;

export type FavoriteProjectsQuery = { __typename?: 'Query' } & {
  favoriteProjects?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Project' } & Pick<
          Project,
          | 'calendarLinkageCode'
          | 'color'
          | 'complete'
          | 'completeDateTime'
          | 'costBudgetWithoutTax'
          | 'description'
          | 'estimateTimeSec'
          | 'id'
          | 'key'
          | 'laborCost'
          | 'laborCostWithoutTax'
          | 'laborShare'
          | 'name'
          | 'priority'
          | 'registerDateTime'
          | 'salesAmountWithoutTax'
          | 'scheduledEndDateTime'
          | 'scheduledStartDateTime'
          | 'sortNoInGanttChart'
          | 'sortNoInList'
          | 'sortNoInProjectStatus'
          | 'totalScheduleTimeSec'
          | 'versionNo'
          | 'workingTimeSec'
        > & {
            assignees: Array<
              { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                AssignedProjectMemberInfo,
                'sortNo'
              > & {
                  member: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                }
            >;
            client?: Maybe<
              { __typename?: 'Client' } & Pick<
                Client,
                'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
              >
            >;
            createdMember?: Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >;
            favoriteMembers: Array<
              Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >
            >;
            favoriteMembersInfo: Array<
              Maybe<
                { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                  FavoriteProjectMemberInfo,
                  'sortNo'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >
            >;
            projectStatus: { __typename?: 'ProjectStatus' } & Pick<
              ProjectStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
            >;
            team: { __typename?: 'Team' } & Pick<
              Team,
              'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
            > & {
                organization: { __typename?: 'Organization' } & Pick<
                  Organization,
                  | 'allowConnectThirdPartyCalendar'
                  | 'contactMailAddress'
                  | 'contractPreriod'
                  | 'id'
                  | 'imageUrl'
                  | 'ipRestrictionFlg'
                  | 'name'
                  | 'requiredTwoFactorAuth'
                  | 'suspensionFlg'
                  | 'versionNo'
                > & {
                    plan: { __typename?: 'PlanProduct' } & Pick<
                      PlanProduct,
                      'code' | 'contractPreriod' | 'id'
                    >;
                  };
              };
          }
      >
    >
  >;
};

export type FavoriteTasksQueryVariables = Exact<{ [key: string]: never }>;

export type FavoriteTasksQuery = { __typename?: 'Query' } & {
  favoriteTasks?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Task' } & Pick<
          Task,
          | 'calendarLinkageCode'
          | 'completeDateTime'
          | 'description'
          | 'estimateTimeSec'
          | 'estimatedRemainingTimeSec'
          | 'id'
          | 'laborCost'
          | 'priority'
          | 'progressDeviationRate'
          | 'progressRate'
          | 'registerDateTime'
          | 'remainingTimeSec'
          | 'scheduleWorkingTimeSec'
          | 'scheduledEndDateTime'
          | 'scheduledStartDateTime'
          | 'sortNoInGanttChart'
          | 'sortNoInList'
          | 'sortNoInMyTask'
          | 'sortNoInTaskStatus'
          | 'title'
          | 'versionNo'
          | 'workingTimeSec'
        > & {
            assignees: Array<
              { __typename?: 'AssignedTaskMemberInfo' } & Pick<AssignedTaskMemberInfo, 'sortNo'> & {
                  member: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                }
            >;
            asssignee?: Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >;
            createdMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
            favoriteMembers: Array<
              Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >
            >;
            favoriteMembersInfo: Array<
              Maybe<
                { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                  FavoriteTaskMemberInfo,
                  'sortNo'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >
            >;
            project: { __typename?: 'Project' } & Pick<
              Project,
              | 'calendarLinkageCode'
              | 'color'
              | 'complete'
              | 'completeDateTime'
              | 'costBudgetWithoutTax'
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'key'
              | 'laborCost'
              | 'laborCostWithoutTax'
              | 'laborShare'
              | 'name'
              | 'priority'
              | 'registerDateTime'
              | 'salesAmountWithoutTax'
              | 'scheduledEndDateTime'
              | 'scheduledStartDateTime'
              | 'sortNoInGanttChart'
              | 'sortNoInList'
              | 'sortNoInProjectStatus'
              | 'totalScheduleTimeSec'
              | 'versionNo'
              | 'workingTimeSec'
            > & {
                assignees: Array<
                  { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                    AssignedProjectMemberInfo,
                    'sortNo'
                  >
                >;
                client?: Maybe<
                  { __typename?: 'Client' } & Pick<
                    Client,
                    'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                  >
                >;
                createdMember?: Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >;
                favoriteMembers: Array<
                  Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >
                >;
                favoriteMembersInfo: Array<
                  Maybe<
                    { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                      FavoriteProjectMemberInfo,
                      'sortNo'
                    >
                  >
                >;
                projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                  ProjectStatus,
                  'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                >;
                team: { __typename?: 'Team' } & Pick<
                  Team,
                  'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                > & {
                    organization: { __typename?: 'Organization' } & Pick<
                      Organization,
                      | 'allowConnectThirdPartyCalendar'
                      | 'contactMailAddress'
                      | 'contractPreriod'
                      | 'id'
                      | 'imageUrl'
                      | 'ipRestrictionFlg'
                      | 'name'
                      | 'requiredTwoFactorAuth'
                      | 'suspensionFlg'
                      | 'versionNo'
                    > & {
                        plan: { __typename?: 'PlanProduct' } & Pick<
                          PlanProduct,
                          'code' | 'contractPreriod' | 'id'
                        >;
                      };
                  };
              };
            taskStatus: { __typename?: 'TaskStatus' } & Pick<
              TaskStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
            > & {
                project: { __typename?: 'Project' } & Pick<
                  Project,
                  | 'calendarLinkageCode'
                  | 'color'
                  | 'complete'
                  | 'completeDateTime'
                  | 'costBudgetWithoutTax'
                  | 'description'
                  | 'estimateTimeSec'
                  | 'id'
                  | 'key'
                  | 'laborCost'
                  | 'laborCostWithoutTax'
                  | 'laborShare'
                  | 'name'
                  | 'priority'
                  | 'registerDateTime'
                  | 'salesAmountWithoutTax'
                  | 'scheduledEndDateTime'
                  | 'scheduledStartDateTime'
                  | 'sortNoInGanttChart'
                  | 'sortNoInList'
                  | 'sortNoInProjectStatus'
                  | 'totalScheduleTimeSec'
                  | 'versionNo'
                  | 'workingTimeSec'
                >;
              };
            workingMembers: Array<
              Maybe<
                { __typename?: 'WorkingMemberInfo' } & Pick<
                  WorkingMemberInfo,
                  'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >
            >;
          }
      >
    >
  >;
};

export type FetchOrganizationActiveMembersQueryVariables = Exact<{ [key: string]: never }>;

export type FetchOrganizationActiveMembersQuery = { __typename?: 'Query' } & {
  fetchOrganizationActiveMembers?: Maybe<
    Array<
      Maybe<
        { __typename?: 'MemberWithLatestTask' } & {
          latestWorkingHistory?: Maybe<
            { __typename?: 'MemberStatusWorkingHistoryPart' } & Pick<
              MemberStatusWorkingHistoryPart,
              'end' | 'id' | 'start' | 'versionNo' | 'workingTimeSec'
            > & {
                calendarEvent?: Maybe<
                  { __typename?: 'MemberStatusCalendarEventPart' } & Pick<
                    MemberStatusCalendarEventPart,
                    'eventName' | 'id' | 'workingTimeSec'
                  >
                >;
                project: { __typename?: 'MemberStatusProjectPart' } & Pick<
                  MemberStatusProjectPart,
                  'id' | 'projectName'
                >;
                task?: Maybe<
                  { __typename?: 'MemberStatusTaskPart' } & Pick<
                    MemberStatusTaskPart,
                    | 'completeDateTime'
                    | 'estimateTimeSec'
                    | 'id'
                    | 'priority'
                    | 'progressRate'
                    | 'scheduledEndDateTime'
                    | 'title'
                    | 'versionNo'
                    | 'workingTimeSec'
                  > & {
                      workingMembers: Array<
                        Maybe<
                          { __typename?: 'MemberStatusWorkingMemberInfo' } & Pick<
                            MemberStatusWorkingMemberInfo,
                            'id' | 'lastWorkingStartDateTime'
                          >
                        >
                      >;
                    }
                >;
              }
          >;
          member: { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >;
        }
      >
    >
  >;
};

export type FetchOrganizationMembersQueryVariables = Exact<{ [key: string]: never }>;

export type FetchOrganizationMembersQuery = { __typename?: 'Query' } & {
  fetchOrganizationMembers?: Maybe<
    Array<
      Maybe<
        { __typename?: 'MemberWithLatestTask' } & {
          latestWorkingHistory?: Maybe<
            { __typename?: 'MemberStatusWorkingHistoryPart' } & Pick<
              MemberStatusWorkingHistoryPart,
              'end' | 'id' | 'start' | 'versionNo' | 'workingTimeSec'
            > & {
                calendarEvent?: Maybe<
                  { __typename?: 'MemberStatusCalendarEventPart' } & Pick<
                    MemberStatusCalendarEventPart,
                    'eventName' | 'id' | 'workingTimeSec'
                  >
                >;
                project: { __typename?: 'MemberStatusProjectPart' } & Pick<
                  MemberStatusProjectPart,
                  'id' | 'projectName'
                >;
                task?: Maybe<
                  { __typename?: 'MemberStatusTaskPart' } & Pick<
                    MemberStatusTaskPart,
                    | 'completeDateTime'
                    | 'estimateTimeSec'
                    | 'id'
                    | 'priority'
                    | 'progressRate'
                    | 'scheduledEndDateTime'
                    | 'title'
                    | 'versionNo'
                    | 'workingTimeSec'
                  > & {
                      workingMembers: Array<
                        Maybe<
                          { __typename?: 'MemberStatusWorkingMemberInfo' } & Pick<
                            MemberStatusWorkingMemberInfo,
                            'id' | 'lastWorkingStartDateTime'
                          >
                        >
                      >;
                    }
                >;
              }
          >;
          member: { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >;
        }
      >
    >
  >;
};

export type FetchSyncRegisterWorkingHistoriesTargetGoogleCalendarsQueryVariables = Exact<{
  input: RegisterWorkingHistoriesFromGoogleCallendarEventsInputInput;
}>;

export type FetchSyncRegisterWorkingHistoriesTargetGoogleCalendarsQuery = {
  __typename?: 'Query';
} & {
  fetchSyncRegisterWorkingHistoriesTargetGoogleCalendars?: Maybe<
    Array<
      Maybe<
        { __typename?: 'GoogleCalendarEvent' } & Pick<
          GoogleCalendarEvent,
          | 'allDay'
          | 'backgroundColor'
          | 'calendarId'
          | 'colorId'
          | 'dateWhenAllDay'
          | 'description'
          | 'endDateTime'
          | 'foregroundColor'
          | 'id'
          | 'location'
          | 'readonly'
          | 'startDateTime'
          | 'status'
          | 'targetMemberId'
          | 'title'
          | 'visivility'
        > & {
            attendees?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'Attendee' } & Pick<
                    Attendee,
                    'mailAddress' | 'name' | 'responseStatus' | 'self'
                  >
                >
              >
            >;
            organizer?: Maybe<
              { __typename?: 'NameAndMailAddress' } & Pick<
                NameAndMailAddress,
                'mailAddress' | 'name'
              >
            >;
          }
      >
    >
  >;
};

export type FetchSyncRegisterWorkingSchedulesTargetGoogleCalendarsQueryVariables = Exact<{
  input: RegisterWorkingSchedulesFromGoogleCallendarEventsInputInput;
}>;

export type FetchSyncRegisterWorkingSchedulesTargetGoogleCalendarsQuery = {
  __typename?: 'Query';
} & {
  fetchSyncRegisterWorkingSchedulesTargetGoogleCalendars?: Maybe<
    Array<
      Maybe<
        { __typename?: 'GoogleCalendarEvent' } & Pick<
          GoogleCalendarEvent,
          | 'allDay'
          | 'backgroundColor'
          | 'calendarId'
          | 'colorId'
          | 'dateWhenAllDay'
          | 'description'
          | 'endDateTime'
          | 'foregroundColor'
          | 'id'
          | 'location'
          | 'readonly'
          | 'startDateTime'
          | 'status'
          | 'targetMemberId'
          | 'title'
          | 'visivility'
        > & {
            attendees?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'Attendee' } & Pick<
                    Attendee,
                    'mailAddress' | 'name' | 'responseStatus' | 'self'
                  >
                >
              >
            >;
            organizer?: Maybe<
              { __typename?: 'NameAndMailAddress' } & Pick<
                NameAndMailAddress,
                'mailAddress' | 'name'
              >
            >;
          }
      >
    >
  >;
};

export type FetchTeamActiveMembersQueryVariables = Exact<{
  teamId: Scalars['String'];
}>;

export type FetchTeamActiveMembersQuery = { __typename?: 'Query' } & {
  fetchTeamActiveMembers?: Maybe<
    Array<
      Maybe<
        { __typename?: 'MemberWithLatestTask' } & {
          latestWorkingHistory?: Maybe<
            { __typename?: 'MemberStatusWorkingHistoryPart' } & Pick<
              MemberStatusWorkingHistoryPart,
              'end' | 'id' | 'start' | 'versionNo' | 'workingTimeSec'
            > & {
                calendarEvent?: Maybe<
                  { __typename?: 'MemberStatusCalendarEventPart' } & Pick<
                    MemberStatusCalendarEventPart,
                    'eventName' | 'id' | 'workingTimeSec'
                  >
                >;
                project: { __typename?: 'MemberStatusProjectPart' } & Pick<
                  MemberStatusProjectPart,
                  'id' | 'projectName'
                >;
                task?: Maybe<
                  { __typename?: 'MemberStatusTaskPart' } & Pick<
                    MemberStatusTaskPart,
                    | 'completeDateTime'
                    | 'estimateTimeSec'
                    | 'id'
                    | 'priority'
                    | 'progressRate'
                    | 'scheduledEndDateTime'
                    | 'title'
                    | 'versionNo'
                    | 'workingTimeSec'
                  > & {
                      workingMembers: Array<
                        Maybe<
                          { __typename?: 'MemberStatusWorkingMemberInfo' } & Pick<
                            MemberStatusWorkingMemberInfo,
                            'id' | 'lastWorkingStartDateTime'
                          >
                        >
                      >;
                    }
                >;
              }
          >;
          member: { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >;
        }
      >
    >
  >;
};

export type FetchTeamMembersQueryVariables = Exact<{
  teamId: Scalars['String'];
}>;

export type FetchTeamMembersQuery = { __typename?: 'Query' } & {
  fetchTeamMembers?: Maybe<
    Array<
      Maybe<
        { __typename?: 'MemberWithLatestTask' } & {
          latestWorkingHistory?: Maybe<
            { __typename?: 'MemberStatusWorkingHistoryPart' } & Pick<
              MemberStatusWorkingHistoryPart,
              'end' | 'id' | 'start' | 'versionNo' | 'workingTimeSec'
            > & {
                calendarEvent?: Maybe<
                  { __typename?: 'MemberStatusCalendarEventPart' } & Pick<
                    MemberStatusCalendarEventPart,
                    'eventName' | 'id' | 'workingTimeSec'
                  >
                >;
                project: { __typename?: 'MemberStatusProjectPart' } & Pick<
                  MemberStatusProjectPart,
                  'id' | 'projectName'
                >;
                task?: Maybe<
                  { __typename?: 'MemberStatusTaskPart' } & Pick<
                    MemberStatusTaskPart,
                    | 'completeDateTime'
                    | 'estimateTimeSec'
                    | 'id'
                    | 'priority'
                    | 'progressRate'
                    | 'scheduledEndDateTime'
                    | 'title'
                    | 'versionNo'
                    | 'workingTimeSec'
                  > & {
                      workingMembers: Array<
                        Maybe<
                          { __typename?: 'MemberStatusWorkingMemberInfo' } & Pick<
                            MemberStatusWorkingMemberInfo,
                            'id' | 'lastWorkingStartDateTime'
                          >
                        >
                      >;
                    }
                >;
              }
          >;
          member: { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >;
        }
      >
    >
  >;
};

export type FirstViewProjectQueryVariables = Exact<{ [key: string]: never }>;

export type FirstViewProjectQuery = { __typename?: 'Query' } & {
  firstViewProject?: Maybe<
    { __typename?: 'FirstViewProjectInfo' } & Pick<FirstViewProjectInfo, 'projectId' | 'teamId'>
  >;
};

export type GoogleCalendarEventQueryVariables = Exact<{
  calendarId: Scalars['String'];
  id: Scalars['ID'];
}>;

export type GoogleCalendarEventQuery = { __typename?: 'Query' } & {
  googleCalendarEvent?: Maybe<
    { __typename?: 'GoogleCalendarEvent' } & Pick<
      GoogleCalendarEvent,
      | 'allDay'
      | 'backgroundColor'
      | 'calendarId'
      | 'colorId'
      | 'dateWhenAllDay'
      | 'description'
      | 'endDateTime'
      | 'foregroundColor'
      | 'id'
      | 'location'
      | 'readonly'
      | 'startDateTime'
      | 'status'
      | 'targetMemberId'
      | 'title'
      | 'visivility'
    > & {
        attendees?: Maybe<
          Array<
            Maybe<
              { __typename?: 'Attendee' } & Pick<
                Attendee,
                'mailAddress' | 'name' | 'responseStatus' | 'self'
              >
            >
          >
        >;
        organizer?: Maybe<
          { __typename?: 'NameAndMailAddress' } & Pick<NameAndMailAddress, 'mailAddress' | 'name'>
        >;
      }
  >;
};

export type GoogleCalendarEventsQueryVariables = Exact<{
  input?: Maybe<GoogleCalendarInputInput>;
}>;

export type GoogleCalendarEventsQuery = { __typename?: 'Query' } & {
  googleCalendarEvents?: Maybe<
    Array<
      Maybe<
        { __typename?: 'GoogleCalendarEvent' } & Pick<
          GoogleCalendarEvent,
          | 'allDay'
          | 'backgroundColor'
          | 'calendarId'
          | 'colorId'
          | 'dateWhenAllDay'
          | 'description'
          | 'endDateTime'
          | 'foregroundColor'
          | 'id'
          | 'location'
          | 'readonly'
          | 'startDateTime'
          | 'status'
          | 'targetMemberId'
          | 'title'
          | 'visivility'
        > & {
            attendees?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'Attendee' } & Pick<
                    Attendee,
                    'mailAddress' | 'name' | 'responseStatus' | 'self'
                  >
                >
              >
            >;
            organizer?: Maybe<
              { __typename?: 'NameAndMailAddress' } & Pick<
                NameAndMailAddress,
                'mailAddress' | 'name'
              >
            >;
          }
      >
    >
  >;
};

export type GoogleCalendarsQueryVariables = Exact<{ [key: string]: never }>;

export type GoogleCalendarsQuery = { __typename?: 'Query' } & {
  googleCalendars?: Maybe<
    Array<
      Maybe<
        { __typename?: 'GoogleCalendar' } & Pick<
          GoogleCalendar,
          | 'backgroundColor'
          | 'calendarId'
          | 'colorId'
          | 'foregroundColor'
          | 'id'
          | 'myCalendar'
          | 'name'
          | 'primary'
          | 'readonly'
        >
      >
    >
  >;
};

export type GoogleWorkSpaceMembersQueryVariables = Exact<{ [key: string]: never }>;

export type GoogleWorkSpaceMembersQuery = { __typename?: 'Query' } & {
  googleWorkSpaceMembers?: Maybe<
    Array<
      Maybe<
        { __typename?: 'GoogleAccount' } & Pick<
          GoogleAccount,
          'calendarPermission' | 'mailAddress' | 'name'
        >
      >
    >
  >;
};

export type GraphDataClientListQueryVariables = Exact<{
  input: GraphDataClientListInputInput;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;

export type GraphDataClientListQuery = { __typename?: 'Query' } & {
  graphDataClientList?: Maybe<
    Array<
      Maybe<
        { __typename?: 'GraphDataClient' } & Pick<
          GraphDataClient,
          | 'clientId'
          | 'clientName'
          | 'color'
          | 'laborCost'
          | 'percent'
          | 'reportType'
          | 'workingTimeSec'
        >
      >
    >
  >;
};

export type GraphDataMemberListQueryVariables = Exact<{
  input: GraphDataMemberListInputInput;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;

export type GraphDataMemberListQuery = { __typename?: 'Query' } & {
  graphDataMemberList?: Maybe<
    Array<
      Maybe<
        { __typename?: 'GraphDataMember' } & Pick<
          GraphDataMember,
          | 'color'
          | 'department'
          | 'employeeNumber'
          | 'laborCost'
          | 'mailAddress'
          | 'memberId'
          | 'name'
          | 'percent'
          | 'profileImageUrl'
          | 'reportType'
          | 'workingTimeSec'
        >
      >
    >
  >;
};

export type GraphDataProjectListQueryVariables = Exact<{
  input: GraphDataProjectListInputInput;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;

export type GraphDataProjectListQuery = { __typename?: 'Query' } & {
  graphDataProjectList?: Maybe<
    Array<
      Maybe<
        { __typename?: 'GraphDataProject' } & Pick<
          GraphDataProject,
          | 'clientId'
          | 'clientName'
          | 'color'
          | 'laborCost'
          | 'percent'
          | 'projectId'
          | 'projectName'
          | 'reportType'
          | 'teamId'
          | 'teamName'
          | 'workingTimeSec'
        >
      >
    >
  >;
};

export type GraphDataTaskListQueryVariables = Exact<{
  input: GraphDataTaskListInputInput;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;

export type GraphDataTaskListQuery = { __typename?: 'Query' } & {
  graphDataTaskList?: Maybe<
    Array<
      Maybe<
        { __typename?: 'GraphDataTask' } & Pick<
          GraphDataTask,
          | 'clientId'
          | 'clientName'
          | 'color'
          | 'itemType'
          | 'laborCost'
          | 'percent'
          | 'projectId'
          | 'projectName'
          | 'reportType'
          | 'tagNames'
          | 'taskId'
          | 'taskTitle'
          | 'teamId'
          | 'teamName'
          | 'workingTimeSec'
        > & {
            workingMembers: Array<
              Maybe<
                { __typename?: 'GraphTaskWorkingMember' } & Pick<
                  GraphTaskWorkingMember,
                  'mailAddress' | 'name' | 'profileImageUrl'
                >
              >
            >;
          }
      >
    >
  >;
};

export type GraphDataTeamListQueryVariables = Exact<{
  input: GraphDataTeamListInputInput;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;

export type GraphDataTeamListQuery = { __typename?: 'Query' } & {
  graphDataTeamList?: Maybe<
    Array<
      Maybe<
        { __typename?: 'GraphDataTeam' } & Pick<
          GraphDataTeam,
          | 'color'
          | 'laborCost'
          | 'percent'
          | 'reportType'
          | 'teamId'
          | 'teamName'
          | 'workingTimeSec'
        >
      >
    >
  >;
};

export type GraphSearchTargetQueryVariables = Exact<{
  input: SearchGraphTargetInputInput;
}>;

export type GraphSearchTargetQuery = { __typename?: 'Query' } & {
  graphSearchTarget?: Maybe<
    { __typename?: 'SearchGraphTarget' } & {
      clients: Array<
        Maybe<
          { __typename?: 'SearchGraphTargetClient' } & Pick<SearchGraphTargetClient, 'id' | 'name'>
        >
      >;
      members: Array<
        Maybe<
          { __typename?: 'SearchGraphTargetMember' } & Pick<SearchGraphTargetMember, 'id' | 'name'>
        >
      >;
      projects: Array<
        Maybe<
          { __typename?: 'SearchGraphTargetProject' } & Pick<
            SearchGraphTargetProject,
            'id' | 'name'
          >
        >
      >;
      teams: Array<
        Maybe<{ __typename?: 'SearchGraphTargetTeam' } & Pick<SearchGraphTargetTeam, 'id' | 'name'>>
      >;
    }
  >;
};

export type GraphTotalWorkingTimeSecQueryVariables = Exact<{
  input: SearchGraphTargetInputInput;
}>;

export type GraphTotalWorkingTimeSecQuery = { __typename?: 'Query' } & Pick<
  Query,
  'graphTotalWorkingTimeSec'
>;

export type HasDisableAclPermissionQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type HasDisableAclPermissionQuery = { __typename?: 'Query' } & Pick<
  Query,
  'hasDisableAclPermission'
>;

export type ICalExportQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;

export type ICalExportQuery = { __typename?: 'Query' } & {
  iCalExport?: Maybe<
    { __typename?: 'ICalExport' } & Pick<ICalExport, 'end' | 'id' | 'start' | 'status' | 'type'>
  >;
};

export type ICalKeyQueryVariables = Exact<{ [key: string]: never }>;

export type ICalKeyQuery = { __typename?: 'Query' } & {
  iCalKey?: Maybe<
    { __typename?: 'ICalKey' } & Pick<ICalKey, 'timeEntriesUrl' | 'workSchedulesUrl'>
  >;
};

export type InvoicesQueryVariables = Exact<{ [key: string]: never }>;

export type InvoicesQuery = { __typename?: 'Query' } & {
  invoices?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Invoice' } & Pick<
          Invoice,
          | 'billingDateTime'
          | 'billingNumber'
          | 'discount'
          | 'invoicePdfUrl'
          | 'paid'
          | 'periodEndDateTime'
          | 'periodStartDateTime'
          | 'quantity'
          | 'receiptUrl'
          | 'subtotal'
          | 'tax'
          | 'title'
          | 'total'
          | 'unitPrice'
        >
      >
    >
  >;
};

export type IsExistLoginPasswordQueryVariables = Exact<{ [key: string]: never }>;

export type IsExistLoginPasswordQuery = { __typename?: 'Query' } & Pick<
  Query,
  'isExistLoginPassword'
>;

export type IsExistsDuplicateTermProjectContractQueryVariables = Exact<{
  input: CheckDuplicateTermProjectContractInputInput;
}>;

export type IsExistsDuplicateTermProjectContractQuery = { __typename?: 'Query' } & Pick<
  Query,
  'isExistsDuplicateTermProjectContract'
>;

export type IsIpAddressAccessAllowedQueryVariables = Exact<{ [key: string]: never }>;

export type IsIpAddressAccessAllowedQuery = { __typename?: 'Query' } & Pick<
  Query,
  'isIpAddressAccessAllowed'
>;

export type IsIpAddressAccessAllowedWithAllowdIpAddressListQueryVariables = Exact<{
  input: IpAddressListInputInput;
}>;

export type IsIpAddressAccessAllowedWithAllowdIpAddressListQuery = { __typename?: 'Query' } & Pick<
  Query,
  'isIpAddressAccessAllowedWithAllowdIpAddressList'
>;

export type JoinedTeamsQueryVariables = Exact<{
  organizationId: Scalars['String'];
}>;

export type JoinedTeamsQuery = { __typename?: 'Query' } & {
  joinedTeams?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Team' } & Pick<
          Team,
          'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
        > & {
            organization: { __typename?: 'Organization' } & Pick<
              Organization,
              | 'allowConnectThirdPartyCalendar'
              | 'contactMailAddress'
              | 'contractPreriod'
              | 'id'
              | 'imageUrl'
              | 'ipRestrictionFlg'
              | 'name'
              | 'requiredTwoFactorAuth'
              | 'suspensionFlg'
              | 'versionNo'
            > & {
                plan: { __typename?: 'PlanProduct' } & Pick<
                  PlanProduct,
                  'code' | 'contractPreriod' | 'id'
                >;
              };
          }
      >
    >
  >;
};

export type JoinedTeamsWithoutPersonalTeamQueryVariables = Exact<{
  organizationId: Scalars['String'];
  withArchivedTeam: Scalars['Boolean'];
}>;

export type JoinedTeamsWithoutPersonalTeamQuery = { __typename?: 'Query' } & {
  joinedTeamsWithoutPersonalTeam?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Team' } & Pick<
          Team,
          'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
        > & {
            organization: { __typename?: 'Organization' } & Pick<
              Organization,
              | 'allowConnectThirdPartyCalendar'
              | 'contactMailAddress'
              | 'contractPreriod'
              | 'id'
              | 'imageUrl'
              | 'ipRestrictionFlg'
              | 'name'
              | 'requiredTwoFactorAuth'
              | 'suspensionFlg'
              | 'versionNo'
            > & {
                plan: { __typename?: 'PlanProduct' } & Pick<
                  PlanProduct,
                  'code' | 'contractPreriod' | 'id'
                >;
              };
          }
      >
    >
  >;
};

export type LaborCostQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
}>;

export type LaborCostQuery = { __typename?: 'Query' } & {
  laborCost?: Maybe<
    { __typename?: 'LaborCost' } & Pick<
      LaborCost,
      'hourlyWage' | 'id' | 'startDateTime' | 'versionNo'
    >
  >;
};

export type LaborCostsQueryVariables = Exact<{
  memberId: Scalars['String'];
}>;

export type LaborCostsQuery = { __typename?: 'Query' } & {
  laborCosts?: Maybe<
    Array<
      Maybe<
        { __typename?: 'LaborCost' } & Pick<
          LaborCost,
          'hourlyWage' | 'id' | 'startDateTime' | 'versionNo'
        >
      >
    >
  >;
};

export type LatestWorkingHistoryQueryVariables = Exact<{ [key: string]: never }>;

export type LatestWorkingHistoryQuery = { __typename?: 'Query' } & {
  latestWorkingHistory?: Maybe<
    { __typename?: 'WorkingHistory' } & Pick<
      WorkingHistory,
      'end' | 'id' | 'memo' | 'start' | 'versionNo' | 'workingTimeSec'
    > & {
        calendarEvent?: Maybe<
          { __typename?: 'CalendarEvent' } & Pick<
            CalendarEvent,
            | 'calendarType'
            | 'clientId'
            | 'clientName'
            | 'endDateTime'
            | 'eventName'
            | 'id'
            | 'laborCost'
            | 'personalTeam'
            | 'projectColor'
            | 'projectId'
            | 'projectName'
            | 'startDateTime'
            | 'taskId'
            | 'taskTitle'
            | 'teamId'
            | 'teamName'
            | 'thirdPartyCalendarEventId'
            | 'versionNo'
            | 'workingTimeSec'
          > & {
              thirdPartyCalendarInfoList: Array<
                Maybe<
                  { __typename?: 'ThirdPartyCalendarInfo' } & Pick<
                    ThirdPartyCalendarInfo,
                    'thirdPartyCalendarId'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >
              >;
            }
        >;
        closingProcessInfo: { __typename?: 'ClosingProcessPart' } & Pick<
          ClosingProcessPart,
          'closed' | 'targetDateTime'
        >;
        task?: Maybe<
          { __typename?: 'Task' } & Pick<
            Task,
            | 'calendarLinkageCode'
            | 'completeDateTime'
            | 'description'
            | 'estimateTimeSec'
            | 'estimatedRemainingTimeSec'
            | 'id'
            | 'laborCost'
            | 'priority'
            | 'progressDeviationRate'
            | 'progressRate'
            | 'registerDateTime'
            | 'remainingTimeSec'
            | 'scheduleWorkingTimeSec'
            | 'scheduledEndDateTime'
            | 'scheduledStartDateTime'
            | 'sortNoInGanttChart'
            | 'sortNoInList'
            | 'sortNoInMyTask'
            | 'sortNoInTaskStatus'
            | 'title'
            | 'versionNo'
            | 'workingTimeSec'
          > & {
              assignees: Array<
                { __typename?: 'AssignedTaskMemberInfo' } & Pick<
                  AssignedTaskMemberInfo,
                  'sortNo'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >;
              asssignee?: Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >;
              createdMember: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
              favoriteMembers: Array<
                Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >
              >;
              favoriteMembersInfo: Array<
                Maybe<
                  { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                    FavoriteTaskMemberInfo,
                    'sortNo'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >
              >;
              project: { __typename?: 'Project' } & Pick<
                Project,
                | 'calendarLinkageCode'
                | 'color'
                | 'complete'
                | 'completeDateTime'
                | 'costBudgetWithoutTax'
                | 'description'
                | 'estimateTimeSec'
                | 'id'
                | 'key'
                | 'laborCost'
                | 'laborCostWithoutTax'
                | 'laborShare'
                | 'name'
                | 'priority'
                | 'registerDateTime'
                | 'salesAmountWithoutTax'
                | 'scheduledEndDateTime'
                | 'scheduledStartDateTime'
                | 'sortNoInGanttChart'
                | 'sortNoInList'
                | 'sortNoInProjectStatus'
                | 'totalScheduleTimeSec'
                | 'versionNo'
                | 'workingTimeSec'
              > & {
                  assignees: Array<
                    { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                      AssignedProjectMemberInfo,
                      'sortNo'
                    >
                  >;
                  client?: Maybe<
                    { __typename?: 'Client' } & Pick<
                      Client,
                      'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                    >
                  >;
                  createdMember?: Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >;
                  favoriteMembers: Array<
                    Maybe<
                      { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >
                    >
                  >;
                  favoriteMembersInfo: Array<
                    Maybe<
                      { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                        FavoriteProjectMemberInfo,
                        'sortNo'
                      >
                    >
                  >;
                  projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                    ProjectStatus,
                    'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                  >;
                  team: { __typename?: 'Team' } & Pick<
                    Team,
                    'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                  > & {
                      organization: { __typename?: 'Organization' } & Pick<
                        Organization,
                        | 'allowConnectThirdPartyCalendar'
                        | 'contactMailAddress'
                        | 'contractPreriod'
                        | 'id'
                        | 'imageUrl'
                        | 'ipRestrictionFlg'
                        | 'name'
                        | 'requiredTwoFactorAuth'
                        | 'suspensionFlg'
                        | 'versionNo'
                      > & {
                          plan: { __typename?: 'PlanProduct' } & Pick<
                            PlanProduct,
                            'code' | 'contractPreriod' | 'id'
                          >;
                        };
                    };
                };
              taskStatus: { __typename?: 'TaskStatus' } & Pick<
                TaskStatus,
                'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
              > & {
                  project: { __typename?: 'Project' } & Pick<
                    Project,
                    | 'calendarLinkageCode'
                    | 'color'
                    | 'complete'
                    | 'completeDateTime'
                    | 'costBudgetWithoutTax'
                    | 'description'
                    | 'estimateTimeSec'
                    | 'id'
                    | 'key'
                    | 'laborCost'
                    | 'laborCostWithoutTax'
                    | 'laborShare'
                    | 'name'
                    | 'priority'
                    | 'registerDateTime'
                    | 'salesAmountWithoutTax'
                    | 'scheduledEndDateTime'
                    | 'scheduledStartDateTime'
                    | 'sortNoInGanttChart'
                    | 'sortNoInList'
                    | 'sortNoInProjectStatus'
                    | 'totalScheduleTimeSec'
                    | 'versionNo'
                    | 'workingTimeSec'
                  >;
                };
              workingMembers: Array<
                Maybe<
                  { __typename?: 'WorkingMemberInfo' } & Pick<
                    WorkingMemberInfo,
                    'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >
              >;
            }
        >;
        workingMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
      }
  >;
};

export type LatestWorkingTaskQueryVariables = Exact<{ [key: string]: never }>;

export type LatestWorkingTaskQuery = { __typename?: 'Query' } & {
  latestWorkingTask?: Maybe<
    { __typename?: 'Task' } & Pick<
      Task,
      | 'calendarLinkageCode'
      | 'completeDateTime'
      | 'description'
      | 'estimateTimeSec'
      | 'estimatedRemainingTimeSec'
      | 'id'
      | 'laborCost'
      | 'priority'
      | 'progressDeviationRate'
      | 'progressRate'
      | 'registerDateTime'
      | 'remainingTimeSec'
      | 'scheduleWorkingTimeSec'
      | 'scheduledEndDateTime'
      | 'scheduledStartDateTime'
      | 'sortNoInGanttChart'
      | 'sortNoInList'
      | 'sortNoInMyTask'
      | 'sortNoInTaskStatus'
      | 'title'
      | 'versionNo'
      | 'workingTimeSec'
    > & {
        assignees: Array<
          { __typename?: 'AssignedTaskMemberInfo' } & Pick<AssignedTaskMemberInfo, 'sortNo'> & {
              member: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
            }
        >;
        asssignee?: Maybe<
          { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >
        >;
        createdMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
        favoriteMembers: Array<
          Maybe<
            { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >
          >
        >;
        favoriteMembersInfo: Array<
          Maybe<
            { __typename?: 'FavoriteTaskMemberInfo' } & Pick<FavoriteTaskMemberInfo, 'sortNo'> & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
        project: { __typename?: 'Project' } & Pick<
          Project,
          | 'calendarLinkageCode'
          | 'color'
          | 'complete'
          | 'completeDateTime'
          | 'costBudgetWithoutTax'
          | 'description'
          | 'estimateTimeSec'
          | 'id'
          | 'key'
          | 'laborCost'
          | 'laborCostWithoutTax'
          | 'laborShare'
          | 'name'
          | 'priority'
          | 'registerDateTime'
          | 'salesAmountWithoutTax'
          | 'scheduledEndDateTime'
          | 'scheduledStartDateTime'
          | 'sortNoInGanttChart'
          | 'sortNoInList'
          | 'sortNoInProjectStatus'
          | 'totalScheduleTimeSec'
          | 'versionNo'
          | 'workingTimeSec'
        > & {
            assignees: Array<
              { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                AssignedProjectMemberInfo,
                'sortNo'
              >
            >;
            client?: Maybe<
              { __typename?: 'Client' } & Pick<
                Client,
                'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
              >
            >;
            createdMember?: Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >;
            favoriteMembers: Array<
              Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >
            >;
            favoriteMembersInfo: Array<
              Maybe<
                { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                  FavoriteProjectMemberInfo,
                  'sortNo'
                >
              >
            >;
            projectStatus: { __typename?: 'ProjectStatus' } & Pick<
              ProjectStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
            >;
            team: { __typename?: 'Team' } & Pick<
              Team,
              'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
            > & {
                organization: { __typename?: 'Organization' } & Pick<
                  Organization,
                  | 'allowConnectThirdPartyCalendar'
                  | 'contactMailAddress'
                  | 'contractPreriod'
                  | 'id'
                  | 'imageUrl'
                  | 'ipRestrictionFlg'
                  | 'name'
                  | 'requiredTwoFactorAuth'
                  | 'suspensionFlg'
                  | 'versionNo'
                > & {
                    plan: { __typename?: 'PlanProduct' } & Pick<
                      PlanProduct,
                      'code' | 'contractPreriod' | 'id'
                    >;
                  };
              };
          };
        taskStatus: { __typename?: 'TaskStatus' } & Pick<
          TaskStatus,
          'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
        > & {
            project: { __typename?: 'Project' } & Pick<
              Project,
              | 'calendarLinkageCode'
              | 'color'
              | 'complete'
              | 'completeDateTime'
              | 'costBudgetWithoutTax'
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'key'
              | 'laborCost'
              | 'laborCostWithoutTax'
              | 'laborShare'
              | 'name'
              | 'priority'
              | 'registerDateTime'
              | 'salesAmountWithoutTax'
              | 'scheduledEndDateTime'
              | 'scheduledStartDateTime'
              | 'sortNoInGanttChart'
              | 'sortNoInList'
              | 'sortNoInProjectStatus'
              | 'totalScheduleTimeSec'
              | 'versionNo'
              | 'workingTimeSec'
            >;
          };
        workingMembers: Array<
          Maybe<
            { __typename?: 'WorkingMemberInfo' } & Pick<
              WorkingMemberInfo,
              'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
            > & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
      }
  >;
};

export type MailAddressAvailableQueryVariables = Exact<{
  mailAddress: Scalars['String'];
}>;

export type MailAddressAvailableQuery = { __typename?: 'Query' } & Pick<
  Query,
  'mailAddressAvailable'
>;

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = { __typename?: 'Query' } & {
  me?: Maybe<
    { __typename?: 'Member' } & Pick<
      Member,
      | 'adminRole'
      | 'clientManagementRole'
      | 'department'
      | 'employeeNumber'
      | 'id'
      | 'laborCostManagementRole'
      | 'mailAddress'
      | 'memberManagementRole'
      | 'memberRegistrationDateTime'
      | 'memberStatus'
      | 'name'
      | 'organizationMemberViewRole'
      | 'organizationReportRole'
      | 'profileImageUrl'
      | 'projectCreatePermissionFlg'
      | 'projectCustomAttributePermissionFlg'
      | 'projectDeletePermissionFlg'
      | 'projectUpdatePermissionFlg'
      | 'proxyEditWorkingDataRole'
      | 'salesManagementRole'
      | 'statusMessage'
      | 'taskCreatePermissionFlg'
      | 'taskCustomAttributePermissionFlg'
      | 'taskDeletePermissionFlg'
      | 'taskUpdatePermissionFlg'
      | 'teamMemberViewRole'
      | 'teamReportRole'
      | 'versionNo'
    >
  >;
};

export type MemberQueryVariables = Exact<{
  memberId: Scalars['String'];
}>;

export type MemberQuery = { __typename?: 'Query' } & {
  member?: Maybe<
    { __typename?: 'Member' } & Pick<
      Member,
      | 'adminRole'
      | 'clientManagementRole'
      | 'department'
      | 'employeeNumber'
      | 'id'
      | 'laborCostManagementRole'
      | 'mailAddress'
      | 'memberManagementRole'
      | 'memberRegistrationDateTime'
      | 'memberStatus'
      | 'name'
      | 'organizationMemberViewRole'
      | 'organizationReportRole'
      | 'profileImageUrl'
      | 'projectCreatePermissionFlg'
      | 'projectCustomAttributePermissionFlg'
      | 'projectDeletePermissionFlg'
      | 'projectUpdatePermissionFlg'
      | 'proxyEditWorkingDataRole'
      | 'salesManagementRole'
      | 'statusMessage'
      | 'taskCreatePermissionFlg'
      | 'taskCustomAttributePermissionFlg'
      | 'taskDeletePermissionFlg'
      | 'taskUpdatePermissionFlg'
      | 'teamMemberViewRole'
      | 'teamReportRole'
      | 'versionNo'
    >
  >;
};

export type MemberBarGraphDataQueryVariables = Exact<{
  input: MemberGraphDataInputInput;
}>;

export type MemberBarGraphDataQuery = { __typename?: 'Query' } & {
  memberBarGraphData?: Maybe<
    Array<
      Maybe<
        { __typename?: 'MemberBarGraphData' } & Pick<
          MemberBarGraphData,
          | 'color'
          | 'department'
          | 'employeeNumber'
          | 'laborCost'
          | 'mailAddress'
          | 'memberId'
          | 'name'
          | 'percent'
          | 'profileImageUrl'
          | 'reportType'
          | 'workingTimeSec'
        >
      >
    >
  >;
};

export type MemberDateGraphDataQueryVariables = Exact<{
  input: MemberGraphDataInputInput;
}>;

export type MemberDateGraphDataQuery = { __typename?: 'Query' } & {
  memberDateGraphData?: Maybe<
    Array<
      Maybe<
        { __typename?: 'MemberDateGraphData' } & Pick<
          MemberDateGraphData,
          'date' | 'reportType'
        > & {
            items: Array<
              Maybe<
                { __typename?: 'MemberDateGraphDataDetail' } & Pick<
                  MemberDateGraphDataDetail,
                  | 'color'
                  | 'department'
                  | 'employeeNumber'
                  | 'laborCost'
                  | 'mailAddress'
                  | 'memberId'
                  | 'name'
                  | 'percent'
                  | 'profileImageUrl'
                  | 'workingTimeSec'
                >
              >
            >;
          }
      >
    >
  >;
};

export type MemberInvitationQueryVariables = Exact<{
  key: Scalars['String'];
}>;

export type MemberInvitationQuery = { __typename?: 'Query' } & {
  memberInvitation?: Maybe<
    { __typename?: 'MemberInvitation' } & Pick<
      MemberInvitation,
      | 'adminRole'
      | 'allowThirdPartyCalendarConnect'
      | 'clientManagementRole'
      | 'createMemberId'
      | 'department'
      | 'employeeNumber'
      | 'id'
      | 'invitationKey'
      | 'laborCostManagementRole'
      | 'mailAddress'
      | 'memberManagementRole'
      | 'name'
      | 'organizationId'
      | 'organizationMemberViewRole'
      | 'organizationName'
      | 'organizationReportRole'
      | 'projectCreatePermissionFlg'
      | 'projectCustomAttributePermissionFlg'
      | 'projectDeletePermissionFlg'
      | 'projectUpdatePermissionFlg'
      | 'proxyEditWorkingDataRole'
      | 'salesManagementRole'
      | 'taskCreatePermissionFlg'
      | 'taskCustomAttributePermissionFlg'
      | 'taskDeletePermissionFlg'
      | 'taskUpdatePermissionFlg'
      | 'teamMemberViewRole'
      | 'teamReportRole'
      | 'versionNo'
    >
  >;
};

export type MemberInvitationLinkQueryVariables = Exact<{
  key: Scalars['String'];
}>;

export type MemberInvitationLinkQuery = { __typename?: 'Query' } & {
  memberInvitationLink?: Maybe<
    { __typename?: 'MemberInvitationLink' } & Pick<
      MemberInvitationLink,
      | 'allowThirdPartyCalendarConnect'
      | 'clientManagementRole'
      | 'createDateTime'
      | 'createMemberId'
      | 'id'
      | 'invitationKey'
      | 'invitationUrl'
      | 'memo'
      | 'organizationId'
      | 'organizationMemberViewRole'
      | 'organizationName'
      | 'organizationReportRole'
      | 'projectCreatePermissionFlg'
      | 'projectCustomAttributePermissionFlg'
      | 'projectDeletePermissionFlg'
      | 'projectUpdatePermissionFlg'
      | 'proxyEditWorkingDataRole'
      | 'taskCreatePermissionFlg'
      | 'taskCustomAttributePermissionFlg'
      | 'taskDeletePermissionFlg'
      | 'taskUpdatePermissionFlg'
      | 'teamMemberViewRole'
      | 'teamReportRole'
      | 'versionNo'
    >
  >;
};

export type MemberInvitationLinksQueryVariables = Exact<{ [key: string]: never }>;

export type MemberInvitationLinksQuery = { __typename?: 'Query' } & {
  memberInvitationLinks?: Maybe<
    Array<
      Maybe<
        { __typename?: 'MemberInvitationLink' } & Pick<
          MemberInvitationLink,
          | 'allowThirdPartyCalendarConnect'
          | 'clientManagementRole'
          | 'createDateTime'
          | 'createMemberId'
          | 'id'
          | 'invitationKey'
          | 'invitationUrl'
          | 'memo'
          | 'organizationId'
          | 'organizationMemberViewRole'
          | 'organizationName'
          | 'organizationReportRole'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >
      >
    >
  >;
};

export type MemberInvitationsQueryVariables = Exact<{ [key: string]: never }>;

export type MemberInvitationsQuery = { __typename?: 'Query' } & {
  memberInvitations?: Maybe<
    Array<
      Maybe<
        { __typename?: 'MemberInvitation' } & Pick<
          MemberInvitation,
          | 'adminRole'
          | 'allowThirdPartyCalendarConnect'
          | 'clientManagementRole'
          | 'createMemberId'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'invitationKey'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'name'
          | 'organizationId'
          | 'organizationMemberViewRole'
          | 'organizationName'
          | 'organizationReportRole'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >
      >
    >
  >;
};

export type MemberRegisterRequestQueryVariables = Exact<{
  key: Scalars['String'];
}>;

export type MemberRegisterRequestQuery = { __typename?: 'Query' } & {
  memberRegisterRequest?: Maybe<
    { __typename?: 'MemberRegisterRequest' } & Pick<
      MemberRegisterRequest,
      'mailAddress' | 'memberRegisterKey'
    >
  >;
};

export type MemberRegisterRequestWithInvitationLinkQueryVariables = Exact<{
  key: Scalars['String'];
}>;

export type MemberRegisterRequestWithInvitationLinkQuery = { __typename?: 'Query' } & {
  memberRegisterRequestWithInvitationLink?: Maybe<
    { __typename?: 'MemberRegisterRequest' } & Pick<
      MemberRegisterRequest,
      'mailAddress' | 'memberRegisterKey'
    >
  >;
};

export type MemberSunburstGraphDataQueryVariables = Exact<{
  input: MemberGraphDataInputInput;
}>;

export type MemberSunburstGraphDataQuery = { __typename?: 'Query' } & {
  memberSunburstGraphData?: Maybe<
    Array<
      Maybe<
        { __typename?: 'MemberSunburstGraphData' } & Pick<
          MemberSunburstGraphData,
          | 'color'
          | 'department'
          | 'mailAddress'
          | 'memberId'
          | 'name'
          | 'percent'
          | 'profileImageUrl'
          | 'reportType'
          | 'workingTimeSec'
        >
      >
    >
  >;
};

export type MyCsvReportQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type MyCsvReportQuery = { __typename?: 'Query' } & {
  myCsvReport?: Maybe<
    { __typename?: 'MyCsvReport' } & Pick<
      MyCsvReport,
      | 'end'
      | 'id'
      | 'includeWorkingData'
      | 'reportType'
      | 'requestDateTime'
      | 'sjisFileUrl'
      | 'start'
      | 'status'
      | 'timeZoneOffset'
      | 'utf8FileUrl'
      | 'versionNo'
    > & {
        csvReportItemDefinition?: Maybe<
          { __typename?: 'CsvReportItemDefinition' } & Pick<
            CsvReportItemDefinition,
            'id' | 'name' | 'versionNo'
          > & {
              items: Array<
                Maybe<
                  { __typename?: 'CsvReportItemDefinitionDetail' } & Pick<
                    CsvReportItemDefinitionDetail,
                    'id' | 'reportItemCode' | 'reportItemName' | 'sortNo'
                  >
                >
              >;
            }
        >;
      }
  >;
};

export type MyCsvReportsQueryVariables = Exact<{ [key: string]: never }>;

export type MyCsvReportsQuery = { __typename?: 'Query' } & {
  myCsvReports?: Maybe<
    Array<
      Maybe<
        { __typename?: 'MyCsvReport' } & Pick<
          MyCsvReport,
          | 'end'
          | 'id'
          | 'includeWorkingData'
          | 'reportType'
          | 'requestDateTime'
          | 'sjisFileUrl'
          | 'start'
          | 'status'
          | 'timeZoneOffset'
          | 'utf8FileUrl'
          | 'versionNo'
        > & {
            csvReportItemDefinition?: Maybe<
              { __typename?: 'CsvReportItemDefinition' } & Pick<
                CsvReportItemDefinition,
                'id' | 'name' | 'versionNo'
              > & {
                  items: Array<
                    Maybe<
                      { __typename?: 'CsvReportItemDefinitionDetail' } & Pick<
                        CsvReportItemDefinitionDetail,
                        'id' | 'reportItemCode' | 'reportItemName' | 'sortNo'
                      >
                    >
                  >;
                }
            >;
          }
      >
    >
  >;
};

export type MyGoogleAccountQueryVariables = Exact<{ [key: string]: never }>;

export type MyGoogleAccountQuery = { __typename?: 'Query' } & {
  myGoogleAccount?: Maybe<
    { __typename?: 'GoogleAccount' } & Pick<
      GoogleAccount,
      'calendarPermission' | 'mailAddress' | 'name'
    >
  >;
};

export type MyGoogleCalendarsQueryVariables = Exact<{ [key: string]: never }>;

export type MyGoogleCalendarsQuery = { __typename?: 'Query' } & {
  myGoogleCalendars?: Maybe<
    Array<
      Maybe<
        { __typename?: 'GoogleCalendar' } & Pick<
          GoogleCalendar,
          | 'backgroundColor'
          | 'calendarId'
          | 'colorId'
          | 'foregroundColor'
          | 'id'
          | 'myCalendar'
          | 'name'
          | 'primary'
          | 'readonly'
        >
      >
    >
  >;
};

export type MyReportQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type MyReportQuery = { __typename?: 'Query' } & {
  myReport?: Maybe<
    { __typename?: 'MyReport' } & Pick<
      MyReport,
      | 'end'
      | 'id'
      | 'includeWorkingData'
      | 'reportType'
      | 'requestDateTime'
      | 'start'
      | 'status'
      | 'timeZoneOffset'
      | 'url'
      | 'versionNo'
    >
  >;
};

export type MyReportsQueryVariables = Exact<{ [key: string]: never }>;

export type MyReportsQuery = { __typename?: 'Query' } & {
  myReports?: Maybe<
    Array<
      Maybe<
        { __typename?: 'MyReport' } & Pick<
          MyReport,
          | 'end'
          | 'id'
          | 'includeWorkingData'
          | 'reportType'
          | 'requestDateTime'
          | 'start'
          | 'status'
          | 'timeZoneOffset'
          | 'url'
          | 'versionNo'
        >
      >
    >
  >;
};

export type MySettingsQueryVariables = Exact<{ [key: string]: never }>;

export type MySettingsQuery = { __typename?: 'Query' } & {
  mySettings?: Maybe<
    { __typename?: 'MemberSettings' } & Pick<
      MemberSettings,
      | 'allowExportCalendar'
      | 'autoAssignWhenTaskStart'
      | 'calendarStartDayOfWeek'
      | 'copyWorkingScheduleMemoToHistory'
      | 'id'
      | 'initialViewTypeCode'
      | 'longTimeWorkingAlertMinutes'
      | 'projectInitialViewTypeCode'
      | 'twoFactorAuthEnabled'
      | 'versionNo'
    > & {
        member: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
      }
  >;
};

export type MyTasksQueryVariables = Exact<{ [key: string]: never }>;

export type MyTasksQuery = { __typename?: 'Query' } & {
  myTasks?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Task' } & Pick<
          Task,
          | 'calendarLinkageCode'
          | 'completeDateTime'
          | 'description'
          | 'estimateTimeSec'
          | 'estimatedRemainingTimeSec'
          | 'id'
          | 'laborCost'
          | 'priority'
          | 'progressDeviationRate'
          | 'progressRate'
          | 'registerDateTime'
          | 'remainingTimeSec'
          | 'scheduleWorkingTimeSec'
          | 'scheduledEndDateTime'
          | 'scheduledStartDateTime'
          | 'sortNoInGanttChart'
          | 'sortNoInList'
          | 'sortNoInMyTask'
          | 'sortNoInTaskStatus'
          | 'title'
          | 'versionNo'
          | 'workingTimeSec'
        > & {
            assignees: Array<
              { __typename?: 'AssignedTaskMemberInfo' } & Pick<AssignedTaskMemberInfo, 'sortNo'> & {
                  member: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                }
            >;
            asssignee?: Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >;
            createdMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
            favoriteMembers: Array<
              Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >
            >;
            favoriteMembersInfo: Array<
              Maybe<
                { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                  FavoriteTaskMemberInfo,
                  'sortNo'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >
            >;
            project: { __typename?: 'Project' } & Pick<
              Project,
              | 'calendarLinkageCode'
              | 'color'
              | 'complete'
              | 'completeDateTime'
              | 'costBudgetWithoutTax'
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'key'
              | 'laborCost'
              | 'laborCostWithoutTax'
              | 'laborShare'
              | 'name'
              | 'priority'
              | 'registerDateTime'
              | 'salesAmountWithoutTax'
              | 'scheduledEndDateTime'
              | 'scheduledStartDateTime'
              | 'sortNoInGanttChart'
              | 'sortNoInList'
              | 'sortNoInProjectStatus'
              | 'totalScheduleTimeSec'
              | 'versionNo'
              | 'workingTimeSec'
            > & {
                assignees: Array<
                  { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                    AssignedProjectMemberInfo,
                    'sortNo'
                  >
                >;
                client?: Maybe<
                  { __typename?: 'Client' } & Pick<
                    Client,
                    'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                  >
                >;
                createdMember?: Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >;
                favoriteMembers: Array<
                  Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >
                >;
                favoriteMembersInfo: Array<
                  Maybe<
                    { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                      FavoriteProjectMemberInfo,
                      'sortNo'
                    >
                  >
                >;
                projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                  ProjectStatus,
                  'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                >;
                team: { __typename?: 'Team' } & Pick<
                  Team,
                  'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                > & {
                    organization: { __typename?: 'Organization' } & Pick<
                      Organization,
                      | 'allowConnectThirdPartyCalendar'
                      | 'contactMailAddress'
                      | 'contractPreriod'
                      | 'id'
                      | 'imageUrl'
                      | 'ipRestrictionFlg'
                      | 'name'
                      | 'requiredTwoFactorAuth'
                      | 'suspensionFlg'
                      | 'versionNo'
                    > & {
                        plan: { __typename?: 'PlanProduct' } & Pick<
                          PlanProduct,
                          'code' | 'contractPreriod' | 'id'
                        >;
                      };
                  };
              };
            taskStatus: { __typename?: 'TaskStatus' } & Pick<
              TaskStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
            > & {
                project: { __typename?: 'Project' } & Pick<
                  Project,
                  | 'calendarLinkageCode'
                  | 'color'
                  | 'complete'
                  | 'completeDateTime'
                  | 'costBudgetWithoutTax'
                  | 'description'
                  | 'estimateTimeSec'
                  | 'id'
                  | 'key'
                  | 'laborCost'
                  | 'laborCostWithoutTax'
                  | 'laborShare'
                  | 'name'
                  | 'priority'
                  | 'registerDateTime'
                  | 'salesAmountWithoutTax'
                  | 'scheduledEndDateTime'
                  | 'scheduledStartDateTime'
                  | 'sortNoInGanttChart'
                  | 'sortNoInList'
                  | 'sortNoInProjectStatus'
                  | 'totalScheduleTimeSec'
                  | 'versionNo'
                  | 'workingTimeSec'
                >;
              };
            workingMembers: Array<
              Maybe<
                { __typename?: 'WorkingMemberInfo' } & Pick<
                  WorkingMemberInfo,
                  'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >
            >;
          }
      >
    >
  >;
};

export type NotificationQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
}>;

export type NotificationQuery = { __typename?: 'Query' } & {
  notification?: Maybe<
    { __typename?: 'Notification' } & Pick<
      Notification,
      'id' | 'notificationType' | 'readDateTime' | 'receivedDateTime' | 'sendDateTime'
    > & {
        mention?: Maybe<
          { __typename?: 'Mention' } & Pick<Mention, 'id'> & {
              comment?: Maybe<
                { __typename?: 'TaskComment' } & Pick<
                  TaskComment,
                  | 'comment'
                  | 'commentedDateTime'
                  | 'id'
                  | 'projectId'
                  | 'projectName'
                  | 'taskId'
                  | 'taskTitle'
                  | 'teamId'
                  | 'teamName'
                  | 'versionNo'
                > & {
                    createdMember: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                    task?: Maybe<
                      { __typename?: 'Task' } & Pick<
                        Task,
                        | 'calendarLinkageCode'
                        | 'completeDateTime'
                        | 'description'
                        | 'estimateTimeSec'
                        | 'estimatedRemainingTimeSec'
                        | 'id'
                        | 'laborCost'
                        | 'priority'
                        | 'progressDeviationRate'
                        | 'progressRate'
                        | 'registerDateTime'
                        | 'remainingTimeSec'
                        | 'scheduleWorkingTimeSec'
                        | 'scheduledEndDateTime'
                        | 'scheduledStartDateTime'
                        | 'sortNoInGanttChart'
                        | 'sortNoInList'
                        | 'sortNoInMyTask'
                        | 'sortNoInTaskStatus'
                        | 'title'
                        | 'versionNo'
                        | 'workingTimeSec'
                      > & {
                          assignees: Array<
                            { __typename?: 'AssignedTaskMemberInfo' } & Pick<
                              AssignedTaskMemberInfo,
                              'sortNo'
                            > & {
                                member: { __typename?: 'Member' } & Pick<
                                  Member,
                                  | 'adminRole'
                                  | 'clientManagementRole'
                                  | 'department'
                                  | 'employeeNumber'
                                  | 'id'
                                  | 'laborCostManagementRole'
                                  | 'mailAddress'
                                  | 'memberManagementRole'
                                  | 'memberRegistrationDateTime'
                                  | 'memberStatus'
                                  | 'name'
                                  | 'organizationMemberViewRole'
                                  | 'organizationReportRole'
                                  | 'profileImageUrl'
                                  | 'projectCreatePermissionFlg'
                                  | 'projectCustomAttributePermissionFlg'
                                  | 'projectDeletePermissionFlg'
                                  | 'projectUpdatePermissionFlg'
                                  | 'proxyEditWorkingDataRole'
                                  | 'salesManagementRole'
                                  | 'statusMessage'
                                  | 'taskCreatePermissionFlg'
                                  | 'taskCustomAttributePermissionFlg'
                                  | 'taskDeletePermissionFlg'
                                  | 'taskUpdatePermissionFlg'
                                  | 'teamMemberViewRole'
                                  | 'teamReportRole'
                                  | 'versionNo'
                                >;
                              }
                          >;
                          asssignee?: Maybe<
                            { __typename?: 'Member' } & Pick<
                              Member,
                              | 'adminRole'
                              | 'clientManagementRole'
                              | 'department'
                              | 'employeeNumber'
                              | 'id'
                              | 'laborCostManagementRole'
                              | 'mailAddress'
                              | 'memberManagementRole'
                              | 'memberRegistrationDateTime'
                              | 'memberStatus'
                              | 'name'
                              | 'organizationMemberViewRole'
                              | 'organizationReportRole'
                              | 'profileImageUrl'
                              | 'projectCreatePermissionFlg'
                              | 'projectCustomAttributePermissionFlg'
                              | 'projectDeletePermissionFlg'
                              | 'projectUpdatePermissionFlg'
                              | 'proxyEditWorkingDataRole'
                              | 'salesManagementRole'
                              | 'statusMessage'
                              | 'taskCreatePermissionFlg'
                              | 'taskCustomAttributePermissionFlg'
                              | 'taskDeletePermissionFlg'
                              | 'taskUpdatePermissionFlg'
                              | 'teamMemberViewRole'
                              | 'teamReportRole'
                              | 'versionNo'
                            >
                          >;
                          createdMember: { __typename?: 'Member' } & Pick<
                            Member,
                            | 'adminRole'
                            | 'clientManagementRole'
                            | 'department'
                            | 'employeeNumber'
                            | 'id'
                            | 'laborCostManagementRole'
                            | 'mailAddress'
                            | 'memberManagementRole'
                            | 'memberRegistrationDateTime'
                            | 'memberStatus'
                            | 'name'
                            | 'organizationMemberViewRole'
                            | 'organizationReportRole'
                            | 'profileImageUrl'
                            | 'projectCreatePermissionFlg'
                            | 'projectCustomAttributePermissionFlg'
                            | 'projectDeletePermissionFlg'
                            | 'projectUpdatePermissionFlg'
                            | 'proxyEditWorkingDataRole'
                            | 'salesManagementRole'
                            | 'statusMessage'
                            | 'taskCreatePermissionFlg'
                            | 'taskCustomAttributePermissionFlg'
                            | 'taskDeletePermissionFlg'
                            | 'taskUpdatePermissionFlg'
                            | 'teamMemberViewRole'
                            | 'teamReportRole'
                            | 'versionNo'
                          >;
                          favoriteMembers: Array<
                            Maybe<
                              { __typename?: 'Member' } & Pick<
                                Member,
                                | 'adminRole'
                                | 'clientManagementRole'
                                | 'department'
                                | 'employeeNumber'
                                | 'id'
                                | 'laborCostManagementRole'
                                | 'mailAddress'
                                | 'memberManagementRole'
                                | 'memberRegistrationDateTime'
                                | 'memberStatus'
                                | 'name'
                                | 'organizationMemberViewRole'
                                | 'organizationReportRole'
                                | 'profileImageUrl'
                                | 'projectCreatePermissionFlg'
                                | 'projectCustomAttributePermissionFlg'
                                | 'projectDeletePermissionFlg'
                                | 'projectUpdatePermissionFlg'
                                | 'proxyEditWorkingDataRole'
                                | 'salesManagementRole'
                                | 'statusMessage'
                                | 'taskCreatePermissionFlg'
                                | 'taskCustomAttributePermissionFlg'
                                | 'taskDeletePermissionFlg'
                                | 'taskUpdatePermissionFlg'
                                | 'teamMemberViewRole'
                                | 'teamReportRole'
                                | 'versionNo'
                              >
                            >
                          >;
                          favoriteMembersInfo: Array<
                            Maybe<
                              { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                                FavoriteTaskMemberInfo,
                                'sortNo'
                              > & {
                                  member: { __typename?: 'Member' } & Pick<
                                    Member,
                                    | 'adminRole'
                                    | 'clientManagementRole'
                                    | 'department'
                                    | 'employeeNumber'
                                    | 'id'
                                    | 'laborCostManagementRole'
                                    | 'mailAddress'
                                    | 'memberManagementRole'
                                    | 'memberRegistrationDateTime'
                                    | 'memberStatus'
                                    | 'name'
                                    | 'organizationMemberViewRole'
                                    | 'organizationReportRole'
                                    | 'profileImageUrl'
                                    | 'projectCreatePermissionFlg'
                                    | 'projectCustomAttributePermissionFlg'
                                    | 'projectDeletePermissionFlg'
                                    | 'projectUpdatePermissionFlg'
                                    | 'proxyEditWorkingDataRole'
                                    | 'salesManagementRole'
                                    | 'statusMessage'
                                    | 'taskCreatePermissionFlg'
                                    | 'taskCustomAttributePermissionFlg'
                                    | 'taskDeletePermissionFlg'
                                    | 'taskUpdatePermissionFlg'
                                    | 'teamMemberViewRole'
                                    | 'teamReportRole'
                                    | 'versionNo'
                                  >;
                                }
                            >
                          >;
                          project: { __typename?: 'Project' } & Pick<
                            Project,
                            | 'calendarLinkageCode'
                            | 'color'
                            | 'complete'
                            | 'completeDateTime'
                            | 'costBudgetWithoutTax'
                            | 'description'
                            | 'estimateTimeSec'
                            | 'id'
                            | 'key'
                            | 'laborCost'
                            | 'laborCostWithoutTax'
                            | 'laborShare'
                            | 'name'
                            | 'priority'
                            | 'registerDateTime'
                            | 'salesAmountWithoutTax'
                            | 'scheduledEndDateTime'
                            | 'scheduledStartDateTime'
                            | 'sortNoInGanttChart'
                            | 'sortNoInList'
                            | 'sortNoInProjectStatus'
                            | 'totalScheduleTimeSec'
                            | 'versionNo'
                            | 'workingTimeSec'
                          > & {
                              assignees: Array<
                                { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                                  AssignedProjectMemberInfo,
                                  'sortNo'
                                >
                              >;
                              client?: Maybe<
                                { __typename?: 'Client' } & Pick<
                                  Client,
                                  'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                                >
                              >;
                              createdMember?: Maybe<
                                { __typename?: 'Member' } & Pick<
                                  Member,
                                  | 'adminRole'
                                  | 'clientManagementRole'
                                  | 'department'
                                  | 'employeeNumber'
                                  | 'id'
                                  | 'laborCostManagementRole'
                                  | 'mailAddress'
                                  | 'memberManagementRole'
                                  | 'memberRegistrationDateTime'
                                  | 'memberStatus'
                                  | 'name'
                                  | 'organizationMemberViewRole'
                                  | 'organizationReportRole'
                                  | 'profileImageUrl'
                                  | 'projectCreatePermissionFlg'
                                  | 'projectCustomAttributePermissionFlg'
                                  | 'projectDeletePermissionFlg'
                                  | 'projectUpdatePermissionFlg'
                                  | 'proxyEditWorkingDataRole'
                                  | 'salesManagementRole'
                                  | 'statusMessage'
                                  | 'taskCreatePermissionFlg'
                                  | 'taskCustomAttributePermissionFlg'
                                  | 'taskDeletePermissionFlg'
                                  | 'taskUpdatePermissionFlg'
                                  | 'teamMemberViewRole'
                                  | 'teamReportRole'
                                  | 'versionNo'
                                >
                              >;
                              favoriteMembers: Array<
                                Maybe<
                                  { __typename?: 'Member' } & Pick<
                                    Member,
                                    | 'adminRole'
                                    | 'clientManagementRole'
                                    | 'department'
                                    | 'employeeNumber'
                                    | 'id'
                                    | 'laborCostManagementRole'
                                    | 'mailAddress'
                                    | 'memberManagementRole'
                                    | 'memberRegistrationDateTime'
                                    | 'memberStatus'
                                    | 'name'
                                    | 'organizationMemberViewRole'
                                    | 'organizationReportRole'
                                    | 'profileImageUrl'
                                    | 'projectCreatePermissionFlg'
                                    | 'projectCustomAttributePermissionFlg'
                                    | 'projectDeletePermissionFlg'
                                    | 'projectUpdatePermissionFlg'
                                    | 'proxyEditWorkingDataRole'
                                    | 'salesManagementRole'
                                    | 'statusMessage'
                                    | 'taskCreatePermissionFlg'
                                    | 'taskCustomAttributePermissionFlg'
                                    | 'taskDeletePermissionFlg'
                                    | 'taskUpdatePermissionFlg'
                                    | 'teamMemberViewRole'
                                    | 'teamReportRole'
                                    | 'versionNo'
                                  >
                                >
                              >;
                              favoriteMembersInfo: Array<
                                Maybe<
                                  { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                                    FavoriteProjectMemberInfo,
                                    'sortNo'
                                  >
                                >
                              >;
                              projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                                ProjectStatus,
                                'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                              >;
                              team: { __typename?: 'Team' } & Pick<
                                Team,
                                'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                              > & {
                                  organization: { __typename?: 'Organization' } & Pick<
                                    Organization,
                                    | 'allowConnectThirdPartyCalendar'
                                    | 'contactMailAddress'
                                    | 'contractPreriod'
                                    | 'id'
                                    | 'imageUrl'
                                    | 'ipRestrictionFlg'
                                    | 'name'
                                    | 'requiredTwoFactorAuth'
                                    | 'suspensionFlg'
                                    | 'versionNo'
                                  > & {
                                      plan: { __typename?: 'PlanProduct' } & Pick<
                                        PlanProduct,
                                        'code' | 'contractPreriod' | 'id'
                                      >;
                                    };
                                };
                            };
                          taskStatus: { __typename?: 'TaskStatus' } & Pick<
                            TaskStatus,
                            'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
                          > & {
                              project: { __typename?: 'Project' } & Pick<
                                Project,
                                | 'calendarLinkageCode'
                                | 'color'
                                | 'complete'
                                | 'completeDateTime'
                                | 'costBudgetWithoutTax'
                                | 'description'
                                | 'estimateTimeSec'
                                | 'id'
                                | 'key'
                                | 'laborCost'
                                | 'laborCostWithoutTax'
                                | 'laborShare'
                                | 'name'
                                | 'priority'
                                | 'registerDateTime'
                                | 'salesAmountWithoutTax'
                                | 'scheduledEndDateTime'
                                | 'scheduledStartDateTime'
                                | 'sortNoInGanttChart'
                                | 'sortNoInList'
                                | 'sortNoInProjectStatus'
                                | 'totalScheduleTimeSec'
                                | 'versionNo'
                                | 'workingTimeSec'
                              >;
                            };
                          workingMembers: Array<
                            Maybe<
                              { __typename?: 'WorkingMemberInfo' } & Pick<
                                WorkingMemberInfo,
                                'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                              > & {
                                  member: { __typename?: 'Member' } & Pick<
                                    Member,
                                    | 'adminRole'
                                    | 'clientManagementRole'
                                    | 'department'
                                    | 'employeeNumber'
                                    | 'id'
                                    | 'laborCostManagementRole'
                                    | 'mailAddress'
                                    | 'memberManagementRole'
                                    | 'memberRegistrationDateTime'
                                    | 'memberStatus'
                                    | 'name'
                                    | 'organizationMemberViewRole'
                                    | 'organizationReportRole'
                                    | 'profileImageUrl'
                                    | 'projectCreatePermissionFlg'
                                    | 'projectCustomAttributePermissionFlg'
                                    | 'projectDeletePermissionFlg'
                                    | 'projectUpdatePermissionFlg'
                                    | 'proxyEditWorkingDataRole'
                                    | 'salesManagementRole'
                                    | 'statusMessage'
                                    | 'taskCreatePermissionFlg'
                                    | 'taskCustomAttributePermissionFlg'
                                    | 'taskDeletePermissionFlg'
                                    | 'taskUpdatePermissionFlg'
                                    | 'teamMemberViewRole'
                                    | 'teamReportRole'
                                    | 'versionNo'
                                  >;
                                }
                            >
                          >;
                        }
                    >;
                  }
              >;
              project?: Maybe<
                { __typename?: 'Project' } & Pick<
                  Project,
                  | 'calendarLinkageCode'
                  | 'color'
                  | 'complete'
                  | 'completeDateTime'
                  | 'costBudgetWithoutTax'
                  | 'description'
                  | 'estimateTimeSec'
                  | 'id'
                  | 'key'
                  | 'laborCost'
                  | 'laborCostWithoutTax'
                  | 'laborShare'
                  | 'name'
                  | 'priority'
                  | 'registerDateTime'
                  | 'salesAmountWithoutTax'
                  | 'scheduledEndDateTime'
                  | 'scheduledStartDateTime'
                  | 'sortNoInGanttChart'
                  | 'sortNoInList'
                  | 'sortNoInProjectStatus'
                  | 'totalScheduleTimeSec'
                  | 'versionNo'
                  | 'workingTimeSec'
                >
              >;
              sendMember: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
              task?: Maybe<
                { __typename?: 'Task' } & Pick<
                  Task,
                  | 'calendarLinkageCode'
                  | 'completeDateTime'
                  | 'description'
                  | 'estimateTimeSec'
                  | 'estimatedRemainingTimeSec'
                  | 'id'
                  | 'laborCost'
                  | 'priority'
                  | 'progressDeviationRate'
                  | 'progressRate'
                  | 'registerDateTime'
                  | 'remainingTimeSec'
                  | 'scheduleWorkingTimeSec'
                  | 'scheduledEndDateTime'
                  | 'scheduledStartDateTime'
                  | 'sortNoInGanttChart'
                  | 'sortNoInList'
                  | 'sortNoInMyTask'
                  | 'sortNoInTaskStatus'
                  | 'title'
                  | 'versionNo'
                  | 'workingTimeSec'
                >
              >;
            }
        >;
        projectAssignNotification?: Maybe<
          { __typename?: 'ProjectAssignNotification' } & Pick<ProjectAssignNotification, 'id'> & {
              project: { __typename?: 'Project' } & Pick<
                Project,
                | 'calendarLinkageCode'
                | 'color'
                | 'complete'
                | 'completeDateTime'
                | 'costBudgetWithoutTax'
                | 'description'
                | 'estimateTimeSec'
                | 'id'
                | 'key'
                | 'laborCost'
                | 'laborCostWithoutTax'
                | 'laborShare'
                | 'name'
                | 'priority'
                | 'registerDateTime'
                | 'salesAmountWithoutTax'
                | 'scheduledEndDateTime'
                | 'scheduledStartDateTime'
                | 'sortNoInGanttChart'
                | 'sortNoInList'
                | 'sortNoInProjectStatus'
                | 'totalScheduleTimeSec'
                | 'versionNo'
                | 'workingTimeSec'
              >;
              sendMember: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
            }
        >;
        targetMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
        taskAssignNotification?: Maybe<
          { __typename?: 'TaskAssignNotification' } & Pick<
            TaskAssignNotification,
            'id' | 'projectId' | 'teamId'
          > & {
              sendMember: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
              task: { __typename?: 'Task' } & Pick<
                Task,
                | 'calendarLinkageCode'
                | 'completeDateTime'
                | 'description'
                | 'estimateTimeSec'
                | 'estimatedRemainingTimeSec'
                | 'id'
                | 'laborCost'
                | 'priority'
                | 'progressDeviationRate'
                | 'progressRate'
                | 'registerDateTime'
                | 'remainingTimeSec'
                | 'scheduleWorkingTimeSec'
                | 'scheduledEndDateTime'
                | 'scheduledStartDateTime'
                | 'sortNoInGanttChart'
                | 'sortNoInList'
                | 'sortNoInMyTask'
                | 'sortNoInTaskStatus'
                | 'title'
                | 'versionNo'
                | 'workingTimeSec'
              >;
            }
        >;
        taskRemainderNotification?: Maybe<
          { __typename?: 'TaskRemainderNotification' } & Pick<
            TaskRemainderNotification,
            'beforeDeadlineMinutes' | 'id' | 'taskRemainderType'
          > & {
              task: { __typename?: 'Task' } & Pick<
                Task,
                | 'calendarLinkageCode'
                | 'completeDateTime'
                | 'description'
                | 'estimateTimeSec'
                | 'estimatedRemainingTimeSec'
                | 'id'
                | 'laborCost'
                | 'priority'
                | 'progressDeviationRate'
                | 'progressRate'
                | 'registerDateTime'
                | 'remainingTimeSec'
                | 'scheduleWorkingTimeSec'
                | 'scheduledEndDateTime'
                | 'scheduledStartDateTime'
                | 'sortNoInGanttChart'
                | 'sortNoInList'
                | 'sortNoInMyTask'
                | 'sortNoInTaskStatus'
                | 'title'
                | 'versionNo'
                | 'workingTimeSec'
              >;
            }
        >;
      }
  >;
};

export type NotificationV2QueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
}>;

export type NotificationV2Query = { __typename?: 'Query' } & {
  notificationV2?: Maybe<
    { __typename?: 'NotificationV2' } & Pick<
      NotificationV2,
      'id' | 'notificationType' | 'readDateTime' | 'receivedDateTime' | 'sendDateTime'
    > & {
        mention?: Maybe<
          { __typename?: 'MentionV2' } & Pick<
            MentionV2,
            | 'commentId'
            | 'id'
            | 'mentionType'
            | 'message'
            | 'projectId'
            | 'projectName'
            | 'taskId'
            | 'taskTitle'
            | 'teamId'
            | 'teamName'
          > & {
              sendMember: { __typename?: 'NotificationV2MemberPart' } & Pick<
                NotificationV2MemberPart,
                'id' | 'name' | 'profileImageUrl'
              >;
            }
        >;
        projectAssignNotification?: Maybe<
          { __typename?: 'ProjectAssignNotificationV2' } & Pick<
            ProjectAssignNotificationV2,
            'id' | 'projectId' | 'projectName' | 'teamId' | 'teamName'
          > & {
              sendMember: { __typename?: 'NotificationV2MemberPart' } & Pick<
                NotificationV2MemberPart,
                'id' | 'name' | 'profileImageUrl'
              >;
            }
        >;
        targetMember: { __typename?: 'NotificationV2MemberPart' } & Pick<
          NotificationV2MemberPart,
          'id' | 'name' | 'profileImageUrl'
        >;
        taskAssignNotification?: Maybe<
          { __typename?: 'TaskAssignNotificationV2' } & Pick<
            TaskAssignNotificationV2,
            'id' | 'projectId' | 'projectName' | 'taskId' | 'taskTitle' | 'teamId' | 'teamName'
          > & {
              sendMember: { __typename?: 'NotificationV2MemberPart' } & Pick<
                NotificationV2MemberPart,
                'id' | 'name' | 'profileImageUrl'
              >;
            }
        >;
        taskRemainderNotification?: Maybe<
          { __typename?: 'TaskRemainderNotificationV2' } & Pick<
            TaskRemainderNotificationV2,
            | 'id'
            | 'projectId'
            | 'projectName'
            | 'scheduledEndDateTime'
            | 'taskId'
            | 'taskRemainderType'
            | 'taskTitle'
            | 'teamId'
            | 'teamName'
          >
        >;
      }
  >;
};

export type NotificationsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;

export type NotificationsQuery = { __typename?: 'Query' } & {
  notifications?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Notification' } & Pick<
          Notification,
          'id' | 'notificationType' | 'readDateTime' | 'receivedDateTime' | 'sendDateTime'
        > & {
            mention?: Maybe<
              { __typename?: 'Mention' } & Pick<Mention, 'id'> & {
                  comment?: Maybe<
                    { __typename?: 'TaskComment' } & Pick<
                      TaskComment,
                      | 'comment'
                      | 'commentedDateTime'
                      | 'id'
                      | 'projectId'
                      | 'projectName'
                      | 'taskId'
                      | 'taskTitle'
                      | 'teamId'
                      | 'teamName'
                      | 'versionNo'
                    > & {
                        createdMember: { __typename?: 'Member' } & Pick<
                          Member,
                          | 'adminRole'
                          | 'clientManagementRole'
                          | 'department'
                          | 'employeeNumber'
                          | 'id'
                          | 'laborCostManagementRole'
                          | 'mailAddress'
                          | 'memberManagementRole'
                          | 'memberRegistrationDateTime'
                          | 'memberStatus'
                          | 'name'
                          | 'organizationMemberViewRole'
                          | 'organizationReportRole'
                          | 'profileImageUrl'
                          | 'projectCreatePermissionFlg'
                          | 'projectCustomAttributePermissionFlg'
                          | 'projectDeletePermissionFlg'
                          | 'projectUpdatePermissionFlg'
                          | 'proxyEditWorkingDataRole'
                          | 'salesManagementRole'
                          | 'statusMessage'
                          | 'taskCreatePermissionFlg'
                          | 'taskCustomAttributePermissionFlg'
                          | 'taskDeletePermissionFlg'
                          | 'taskUpdatePermissionFlg'
                          | 'teamMemberViewRole'
                          | 'teamReportRole'
                          | 'versionNo'
                        >;
                        task?: Maybe<
                          { __typename?: 'Task' } & Pick<
                            Task,
                            | 'calendarLinkageCode'
                            | 'completeDateTime'
                            | 'description'
                            | 'estimateTimeSec'
                            | 'estimatedRemainingTimeSec'
                            | 'id'
                            | 'laborCost'
                            | 'priority'
                            | 'progressDeviationRate'
                            | 'progressRate'
                            | 'registerDateTime'
                            | 'remainingTimeSec'
                            | 'scheduleWorkingTimeSec'
                            | 'scheduledEndDateTime'
                            | 'scheduledStartDateTime'
                            | 'sortNoInGanttChart'
                            | 'sortNoInList'
                            | 'sortNoInMyTask'
                            | 'sortNoInTaskStatus'
                            | 'title'
                            | 'versionNo'
                            | 'workingTimeSec'
                          > & {
                              assignees: Array<
                                { __typename?: 'AssignedTaskMemberInfo' } & Pick<
                                  AssignedTaskMemberInfo,
                                  'sortNo'
                                > & {
                                    member: { __typename?: 'Member' } & Pick<
                                      Member,
                                      | 'adminRole'
                                      | 'clientManagementRole'
                                      | 'department'
                                      | 'employeeNumber'
                                      | 'id'
                                      | 'laborCostManagementRole'
                                      | 'mailAddress'
                                      | 'memberManagementRole'
                                      | 'memberRegistrationDateTime'
                                      | 'memberStatus'
                                      | 'name'
                                      | 'organizationMemberViewRole'
                                      | 'organizationReportRole'
                                      | 'profileImageUrl'
                                      | 'projectCreatePermissionFlg'
                                      | 'projectCustomAttributePermissionFlg'
                                      | 'projectDeletePermissionFlg'
                                      | 'projectUpdatePermissionFlg'
                                      | 'proxyEditWorkingDataRole'
                                      | 'salesManagementRole'
                                      | 'statusMessage'
                                      | 'taskCreatePermissionFlg'
                                      | 'taskCustomAttributePermissionFlg'
                                      | 'taskDeletePermissionFlg'
                                      | 'taskUpdatePermissionFlg'
                                      | 'teamMemberViewRole'
                                      | 'teamReportRole'
                                      | 'versionNo'
                                    >;
                                  }
                              >;
                              asssignee?: Maybe<
                                { __typename?: 'Member' } & Pick<
                                  Member,
                                  | 'adminRole'
                                  | 'clientManagementRole'
                                  | 'department'
                                  | 'employeeNumber'
                                  | 'id'
                                  | 'laborCostManagementRole'
                                  | 'mailAddress'
                                  | 'memberManagementRole'
                                  | 'memberRegistrationDateTime'
                                  | 'memberStatus'
                                  | 'name'
                                  | 'organizationMemberViewRole'
                                  | 'organizationReportRole'
                                  | 'profileImageUrl'
                                  | 'projectCreatePermissionFlg'
                                  | 'projectCustomAttributePermissionFlg'
                                  | 'projectDeletePermissionFlg'
                                  | 'projectUpdatePermissionFlg'
                                  | 'proxyEditWorkingDataRole'
                                  | 'salesManagementRole'
                                  | 'statusMessage'
                                  | 'taskCreatePermissionFlg'
                                  | 'taskCustomAttributePermissionFlg'
                                  | 'taskDeletePermissionFlg'
                                  | 'taskUpdatePermissionFlg'
                                  | 'teamMemberViewRole'
                                  | 'teamReportRole'
                                  | 'versionNo'
                                >
                              >;
                              createdMember: { __typename?: 'Member' } & Pick<
                                Member,
                                | 'adminRole'
                                | 'clientManagementRole'
                                | 'department'
                                | 'employeeNumber'
                                | 'id'
                                | 'laborCostManagementRole'
                                | 'mailAddress'
                                | 'memberManagementRole'
                                | 'memberRegistrationDateTime'
                                | 'memberStatus'
                                | 'name'
                                | 'organizationMemberViewRole'
                                | 'organizationReportRole'
                                | 'profileImageUrl'
                                | 'projectCreatePermissionFlg'
                                | 'projectCustomAttributePermissionFlg'
                                | 'projectDeletePermissionFlg'
                                | 'projectUpdatePermissionFlg'
                                | 'proxyEditWorkingDataRole'
                                | 'salesManagementRole'
                                | 'statusMessage'
                                | 'taskCreatePermissionFlg'
                                | 'taskCustomAttributePermissionFlg'
                                | 'taskDeletePermissionFlg'
                                | 'taskUpdatePermissionFlg'
                                | 'teamMemberViewRole'
                                | 'teamReportRole'
                                | 'versionNo'
                              >;
                              favoriteMembers: Array<
                                Maybe<
                                  { __typename?: 'Member' } & Pick<
                                    Member,
                                    | 'adminRole'
                                    | 'clientManagementRole'
                                    | 'department'
                                    | 'employeeNumber'
                                    | 'id'
                                    | 'laborCostManagementRole'
                                    | 'mailAddress'
                                    | 'memberManagementRole'
                                    | 'memberRegistrationDateTime'
                                    | 'memberStatus'
                                    | 'name'
                                    | 'organizationMemberViewRole'
                                    | 'organizationReportRole'
                                    | 'profileImageUrl'
                                    | 'projectCreatePermissionFlg'
                                    | 'projectCustomAttributePermissionFlg'
                                    | 'projectDeletePermissionFlg'
                                    | 'projectUpdatePermissionFlg'
                                    | 'proxyEditWorkingDataRole'
                                    | 'salesManagementRole'
                                    | 'statusMessage'
                                    | 'taskCreatePermissionFlg'
                                    | 'taskCustomAttributePermissionFlg'
                                    | 'taskDeletePermissionFlg'
                                    | 'taskUpdatePermissionFlg'
                                    | 'teamMemberViewRole'
                                    | 'teamReportRole'
                                    | 'versionNo'
                                  >
                                >
                              >;
                              favoriteMembersInfo: Array<
                                Maybe<
                                  { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                                    FavoriteTaskMemberInfo,
                                    'sortNo'
                                  > & {
                                      member: { __typename?: 'Member' } & Pick<
                                        Member,
                                        | 'adminRole'
                                        | 'clientManagementRole'
                                        | 'department'
                                        | 'employeeNumber'
                                        | 'id'
                                        | 'laborCostManagementRole'
                                        | 'mailAddress'
                                        | 'memberManagementRole'
                                        | 'memberRegistrationDateTime'
                                        | 'memberStatus'
                                        | 'name'
                                        | 'organizationMemberViewRole'
                                        | 'organizationReportRole'
                                        | 'profileImageUrl'
                                        | 'projectCreatePermissionFlg'
                                        | 'projectCustomAttributePermissionFlg'
                                        | 'projectDeletePermissionFlg'
                                        | 'projectUpdatePermissionFlg'
                                        | 'proxyEditWorkingDataRole'
                                        | 'salesManagementRole'
                                        | 'statusMessage'
                                        | 'taskCreatePermissionFlg'
                                        | 'taskCustomAttributePermissionFlg'
                                        | 'taskDeletePermissionFlg'
                                        | 'taskUpdatePermissionFlg'
                                        | 'teamMemberViewRole'
                                        | 'teamReportRole'
                                        | 'versionNo'
                                      >;
                                    }
                                >
                              >;
                              project: { __typename?: 'Project' } & Pick<
                                Project,
                                | 'calendarLinkageCode'
                                | 'color'
                                | 'complete'
                                | 'completeDateTime'
                                | 'costBudgetWithoutTax'
                                | 'description'
                                | 'estimateTimeSec'
                                | 'id'
                                | 'key'
                                | 'laborCost'
                                | 'laborCostWithoutTax'
                                | 'laborShare'
                                | 'name'
                                | 'priority'
                                | 'registerDateTime'
                                | 'salesAmountWithoutTax'
                                | 'scheduledEndDateTime'
                                | 'scheduledStartDateTime'
                                | 'sortNoInGanttChart'
                                | 'sortNoInList'
                                | 'sortNoInProjectStatus'
                                | 'totalScheduleTimeSec'
                                | 'versionNo'
                                | 'workingTimeSec'
                              > & {
                                  assignees: Array<
                                    { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                                      AssignedProjectMemberInfo,
                                      'sortNo'
                                    >
                                  >;
                                  client?: Maybe<
                                    { __typename?: 'Client' } & Pick<
                                      Client,
                                      'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                                    >
                                  >;
                                  createdMember?: Maybe<
                                    { __typename?: 'Member' } & Pick<
                                      Member,
                                      | 'adminRole'
                                      | 'clientManagementRole'
                                      | 'department'
                                      | 'employeeNumber'
                                      | 'id'
                                      | 'laborCostManagementRole'
                                      | 'mailAddress'
                                      | 'memberManagementRole'
                                      | 'memberRegistrationDateTime'
                                      | 'memberStatus'
                                      | 'name'
                                      | 'organizationMemberViewRole'
                                      | 'organizationReportRole'
                                      | 'profileImageUrl'
                                      | 'projectCreatePermissionFlg'
                                      | 'projectCustomAttributePermissionFlg'
                                      | 'projectDeletePermissionFlg'
                                      | 'projectUpdatePermissionFlg'
                                      | 'proxyEditWorkingDataRole'
                                      | 'salesManagementRole'
                                      | 'statusMessage'
                                      | 'taskCreatePermissionFlg'
                                      | 'taskCustomAttributePermissionFlg'
                                      | 'taskDeletePermissionFlg'
                                      | 'taskUpdatePermissionFlg'
                                      | 'teamMemberViewRole'
                                      | 'teamReportRole'
                                      | 'versionNo'
                                    >
                                  >;
                                  favoriteMembers: Array<
                                    Maybe<
                                      { __typename?: 'Member' } & Pick<
                                        Member,
                                        | 'adminRole'
                                        | 'clientManagementRole'
                                        | 'department'
                                        | 'employeeNumber'
                                        | 'id'
                                        | 'laborCostManagementRole'
                                        | 'mailAddress'
                                        | 'memberManagementRole'
                                        | 'memberRegistrationDateTime'
                                        | 'memberStatus'
                                        | 'name'
                                        | 'organizationMemberViewRole'
                                        | 'organizationReportRole'
                                        | 'profileImageUrl'
                                        | 'projectCreatePermissionFlg'
                                        | 'projectCustomAttributePermissionFlg'
                                        | 'projectDeletePermissionFlg'
                                        | 'projectUpdatePermissionFlg'
                                        | 'proxyEditWorkingDataRole'
                                        | 'salesManagementRole'
                                        | 'statusMessage'
                                        | 'taskCreatePermissionFlg'
                                        | 'taskCustomAttributePermissionFlg'
                                        | 'taskDeletePermissionFlg'
                                        | 'taskUpdatePermissionFlg'
                                        | 'teamMemberViewRole'
                                        | 'teamReportRole'
                                        | 'versionNo'
                                      >
                                    >
                                  >;
                                  favoriteMembersInfo: Array<
                                    Maybe<
                                      { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                                        FavoriteProjectMemberInfo,
                                        'sortNo'
                                      >
                                    >
                                  >;
                                  projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                                    ProjectStatus,
                                    'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                                  >;
                                  team: { __typename?: 'Team' } & Pick<
                                    Team,
                                    | 'archived'
                                    | 'id'
                                    | 'name'
                                    | 'personalTeam'
                                    | 'status'
                                    | 'versionNo'
                                  > & {
                                      organization: { __typename?: 'Organization' } & Pick<
                                        Organization,
                                        | 'allowConnectThirdPartyCalendar'
                                        | 'contactMailAddress'
                                        | 'contractPreriod'
                                        | 'id'
                                        | 'imageUrl'
                                        | 'ipRestrictionFlg'
                                        | 'name'
                                        | 'requiredTwoFactorAuth'
                                        | 'suspensionFlg'
                                        | 'versionNo'
                                      > & {
                                          plan: { __typename?: 'PlanProduct' } & Pick<
                                            PlanProduct,
                                            'code' | 'contractPreriod' | 'id'
                                          >;
                                        };
                                    };
                                };
                              taskStatus: { __typename?: 'TaskStatus' } & Pick<
                                TaskStatus,
                                'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
                              > & {
                                  project: { __typename?: 'Project' } & Pick<
                                    Project,
                                    | 'calendarLinkageCode'
                                    | 'color'
                                    | 'complete'
                                    | 'completeDateTime'
                                    | 'costBudgetWithoutTax'
                                    | 'description'
                                    | 'estimateTimeSec'
                                    | 'id'
                                    | 'key'
                                    | 'laborCost'
                                    | 'laborCostWithoutTax'
                                    | 'laborShare'
                                    | 'name'
                                    | 'priority'
                                    | 'registerDateTime'
                                    | 'salesAmountWithoutTax'
                                    | 'scheduledEndDateTime'
                                    | 'scheduledStartDateTime'
                                    | 'sortNoInGanttChart'
                                    | 'sortNoInList'
                                    | 'sortNoInProjectStatus'
                                    | 'totalScheduleTimeSec'
                                    | 'versionNo'
                                    | 'workingTimeSec'
                                  >;
                                };
                              workingMembers: Array<
                                Maybe<
                                  { __typename?: 'WorkingMemberInfo' } & Pick<
                                    WorkingMemberInfo,
                                    'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                                  > & {
                                      member: { __typename?: 'Member' } & Pick<
                                        Member,
                                        | 'adminRole'
                                        | 'clientManagementRole'
                                        | 'department'
                                        | 'employeeNumber'
                                        | 'id'
                                        | 'laborCostManagementRole'
                                        | 'mailAddress'
                                        | 'memberManagementRole'
                                        | 'memberRegistrationDateTime'
                                        | 'memberStatus'
                                        | 'name'
                                        | 'organizationMemberViewRole'
                                        | 'organizationReportRole'
                                        | 'profileImageUrl'
                                        | 'projectCreatePermissionFlg'
                                        | 'projectCustomAttributePermissionFlg'
                                        | 'projectDeletePermissionFlg'
                                        | 'projectUpdatePermissionFlg'
                                        | 'proxyEditWorkingDataRole'
                                        | 'salesManagementRole'
                                        | 'statusMessage'
                                        | 'taskCreatePermissionFlg'
                                        | 'taskCustomAttributePermissionFlg'
                                        | 'taskDeletePermissionFlg'
                                        | 'taskUpdatePermissionFlg'
                                        | 'teamMemberViewRole'
                                        | 'teamReportRole'
                                        | 'versionNo'
                                      >;
                                    }
                                >
                              >;
                            }
                        >;
                      }
                  >;
                  project?: Maybe<
                    { __typename?: 'Project' } & Pick<
                      Project,
                      | 'calendarLinkageCode'
                      | 'color'
                      | 'complete'
                      | 'completeDateTime'
                      | 'costBudgetWithoutTax'
                      | 'description'
                      | 'estimateTimeSec'
                      | 'id'
                      | 'key'
                      | 'laborCost'
                      | 'laborCostWithoutTax'
                      | 'laborShare'
                      | 'name'
                      | 'priority'
                      | 'registerDateTime'
                      | 'salesAmountWithoutTax'
                      | 'scheduledEndDateTime'
                      | 'scheduledStartDateTime'
                      | 'sortNoInGanttChart'
                      | 'sortNoInList'
                      | 'sortNoInProjectStatus'
                      | 'totalScheduleTimeSec'
                      | 'versionNo'
                      | 'workingTimeSec'
                    >
                  >;
                  sendMember: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                  task?: Maybe<
                    { __typename?: 'Task' } & Pick<
                      Task,
                      | 'calendarLinkageCode'
                      | 'completeDateTime'
                      | 'description'
                      | 'estimateTimeSec'
                      | 'estimatedRemainingTimeSec'
                      | 'id'
                      | 'laborCost'
                      | 'priority'
                      | 'progressDeviationRate'
                      | 'progressRate'
                      | 'registerDateTime'
                      | 'remainingTimeSec'
                      | 'scheduleWorkingTimeSec'
                      | 'scheduledEndDateTime'
                      | 'scheduledStartDateTime'
                      | 'sortNoInGanttChart'
                      | 'sortNoInList'
                      | 'sortNoInMyTask'
                      | 'sortNoInTaskStatus'
                      | 'title'
                      | 'versionNo'
                      | 'workingTimeSec'
                    >
                  >;
                }
            >;
            projectAssignNotification?: Maybe<
              { __typename?: 'ProjectAssignNotification' } & Pick<
                ProjectAssignNotification,
                'id'
              > & {
                  project: { __typename?: 'Project' } & Pick<
                    Project,
                    | 'calendarLinkageCode'
                    | 'color'
                    | 'complete'
                    | 'completeDateTime'
                    | 'costBudgetWithoutTax'
                    | 'description'
                    | 'estimateTimeSec'
                    | 'id'
                    | 'key'
                    | 'laborCost'
                    | 'laborCostWithoutTax'
                    | 'laborShare'
                    | 'name'
                    | 'priority'
                    | 'registerDateTime'
                    | 'salesAmountWithoutTax'
                    | 'scheduledEndDateTime'
                    | 'scheduledStartDateTime'
                    | 'sortNoInGanttChart'
                    | 'sortNoInList'
                    | 'sortNoInProjectStatus'
                    | 'totalScheduleTimeSec'
                    | 'versionNo'
                    | 'workingTimeSec'
                  >;
                  sendMember: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                }
            >;
            targetMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
            taskAssignNotification?: Maybe<
              { __typename?: 'TaskAssignNotification' } & Pick<
                TaskAssignNotification,
                'id' | 'projectId' | 'teamId'
              > & {
                  sendMember: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                  task: { __typename?: 'Task' } & Pick<
                    Task,
                    | 'calendarLinkageCode'
                    | 'completeDateTime'
                    | 'description'
                    | 'estimateTimeSec'
                    | 'estimatedRemainingTimeSec'
                    | 'id'
                    | 'laborCost'
                    | 'priority'
                    | 'progressDeviationRate'
                    | 'progressRate'
                    | 'registerDateTime'
                    | 'remainingTimeSec'
                    | 'scheduleWorkingTimeSec'
                    | 'scheduledEndDateTime'
                    | 'scheduledStartDateTime'
                    | 'sortNoInGanttChart'
                    | 'sortNoInList'
                    | 'sortNoInMyTask'
                    | 'sortNoInTaskStatus'
                    | 'title'
                    | 'versionNo'
                    | 'workingTimeSec'
                  >;
                }
            >;
            taskRemainderNotification?: Maybe<
              { __typename?: 'TaskRemainderNotification' } & Pick<
                TaskRemainderNotification,
                'beforeDeadlineMinutes' | 'id' | 'taskRemainderType'
              > & {
                  task: { __typename?: 'Task' } & Pick<
                    Task,
                    | 'calendarLinkageCode'
                    | 'completeDateTime'
                    | 'description'
                    | 'estimateTimeSec'
                    | 'estimatedRemainingTimeSec'
                    | 'id'
                    | 'laborCost'
                    | 'priority'
                    | 'progressDeviationRate'
                    | 'progressRate'
                    | 'registerDateTime'
                    | 'remainingTimeSec'
                    | 'scheduleWorkingTimeSec'
                    | 'scheduledEndDateTime'
                    | 'scheduledStartDateTime'
                    | 'sortNoInGanttChart'
                    | 'sortNoInList'
                    | 'sortNoInMyTask'
                    | 'sortNoInTaskStatus'
                    | 'title'
                    | 'versionNo'
                    | 'workingTimeSec'
                  >;
                }
            >;
          }
      >
    >
  >;
};

export type NotificationsV2QueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  read: Scalars['Boolean'];
  unread: Scalars['Boolean'];
}>;

export type NotificationsV2Query = { __typename?: 'Query' } & {
  notificationsV2?: Maybe<
    Array<
      Maybe<
        { __typename?: 'NotificationV2' } & Pick<
          NotificationV2,
          'id' | 'notificationType' | 'readDateTime' | 'receivedDateTime' | 'sendDateTime'
        > & {
            mention?: Maybe<
              { __typename?: 'MentionV2' } & Pick<
                MentionV2,
                | 'commentId'
                | 'id'
                | 'mentionType'
                | 'message'
                | 'projectId'
                | 'projectName'
                | 'taskId'
                | 'taskTitle'
                | 'teamId'
                | 'teamName'
              > & {
                  sendMember: { __typename?: 'NotificationV2MemberPart' } & Pick<
                    NotificationV2MemberPart,
                    'id' | 'name' | 'profileImageUrl'
                  >;
                }
            >;
            projectAssignNotification?: Maybe<
              { __typename?: 'ProjectAssignNotificationV2' } & Pick<
                ProjectAssignNotificationV2,
                'id' | 'projectId' | 'projectName' | 'teamId' | 'teamName'
              > & {
                  sendMember: { __typename?: 'NotificationV2MemberPart' } & Pick<
                    NotificationV2MemberPart,
                    'id' | 'name' | 'profileImageUrl'
                  >;
                }
            >;
            targetMember: { __typename?: 'NotificationV2MemberPart' } & Pick<
              NotificationV2MemberPart,
              'id' | 'name' | 'profileImageUrl'
            >;
            taskAssignNotification?: Maybe<
              { __typename?: 'TaskAssignNotificationV2' } & Pick<
                TaskAssignNotificationV2,
                'id' | 'projectId' | 'projectName' | 'taskId' | 'taskTitle' | 'teamId' | 'teamName'
              > & {
                  sendMember: { __typename?: 'NotificationV2MemberPart' } & Pick<
                    NotificationV2MemberPart,
                    'id' | 'name' | 'profileImageUrl'
                  >;
                }
            >;
            taskRemainderNotification?: Maybe<
              { __typename?: 'TaskRemainderNotificationV2' } & Pick<
                TaskRemainderNotificationV2,
                | 'id'
                | 'projectId'
                | 'projectName'
                | 'scheduledEndDateTime'
                | 'taskId'
                | 'taskRemainderType'
                | 'taskTitle'
                | 'teamId'
                | 'teamName'
              >
            >;
          }
      >
    >
  >;
};

export type OrganizationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type OrganizationQuery = { __typename?: 'Query' } & {
  organization?: Maybe<
    { __typename?: 'Organization' } & Pick<
      Organization,
      | 'allowConnectThirdPartyCalendar'
      | 'contactMailAddress'
      | 'contractPreriod'
      | 'id'
      | 'imageUrl'
      | 'ipRestrictionFlg'
      | 'name'
      | 'requiredTwoFactorAuth'
      | 'suspensionFlg'
      | 'versionNo'
    > & {
        plan: { __typename?: 'PlanProduct' } & Pick<PlanProduct, 'code' | 'contractPreriod' | 'id'>;
      }
  >;
};

export type OrganizationArchivedTeamsWithoutPersonalTeamQueryVariables = Exact<{
  organizationId: Scalars['String'];
}>;

export type OrganizationArchivedTeamsWithoutPersonalTeamQuery = { __typename?: 'Query' } & {
  organizationArchivedTeamsWithoutPersonalTeam?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Team' } & Pick<
          Team,
          'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
        > & {
            organization: { __typename?: 'Organization' } & Pick<
              Organization,
              | 'allowConnectThirdPartyCalendar'
              | 'contactMailAddress'
              | 'contractPreriod'
              | 'id'
              | 'imageUrl'
              | 'ipRestrictionFlg'
              | 'name'
              | 'requiredTwoFactorAuth'
              | 'suspensionFlg'
              | 'versionNo'
            > & {
                plan: { __typename?: 'PlanProduct' } & Pick<
                  PlanProduct,
                  'code' | 'contractPreriod' | 'id'
                >;
              };
          }
      >
    >
  >;
};

export type OrganizationClientsQueryVariables = Exact<{
  input?: Maybe<FetchClientsInputInput>;
  organizationId: Scalars['String'];
}>;

export type OrganizationClientsQuery = { __typename?: 'Query' } & {
  organizationClients?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Client' } & Pick<Client, 'code' | 'id' | 'name' | 'sortNo' | 'versionNo'>
      >
    >
  >;
};

export type OrganizationCsvReportQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type OrganizationCsvReportQuery = { __typename?: 'Query' } & {
  organizationCsvReport?: Maybe<
    { __typename?: 'OrganizationCsvReport' } & Pick<
      OrganizationCsvReport,
      | 'end'
      | 'id'
      | 'includeWorkingData'
      | 'reportType'
      | 'requestDateTime'
      | 'sjisFileUrl'
      | 'start'
      | 'status'
      | 'timeZoneOffset'
      | 'utf8FileUrl'
      | 'versionNo'
    > & {
        csvReportItemDefinition?: Maybe<
          { __typename?: 'CsvReportItemDefinition' } & Pick<
            CsvReportItemDefinition,
            'id' | 'name' | 'versionNo'
          > & {
              items: Array<
                Maybe<
                  { __typename?: 'CsvReportItemDefinitionDetail' } & Pick<
                    CsvReportItemDefinitionDetail,
                    'id' | 'reportItemCode' | 'reportItemName' | 'sortNo'
                  >
                >
              >;
            }
        >;
        requestMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
      }
  >;
};

export type OrganizationCsvReportsQueryVariables = Exact<{ [key: string]: never }>;

export type OrganizationCsvReportsQuery = { __typename?: 'Query' } & {
  organizationCsvReports?: Maybe<
    Array<
      Maybe<
        { __typename?: 'OrganizationCsvReport' } & Pick<
          OrganizationCsvReport,
          | 'end'
          | 'id'
          | 'includeWorkingData'
          | 'reportType'
          | 'requestDateTime'
          | 'sjisFileUrl'
          | 'start'
          | 'status'
          | 'timeZoneOffset'
          | 'utf8FileUrl'
          | 'versionNo'
        > & {
            csvReportItemDefinition?: Maybe<
              { __typename?: 'CsvReportItemDefinition' } & Pick<
                CsvReportItemDefinition,
                'id' | 'name' | 'versionNo'
              > & {
                  items: Array<
                    Maybe<
                      { __typename?: 'CsvReportItemDefinitionDetail' } & Pick<
                        CsvReportItemDefinitionDetail,
                        'id' | 'reportItemCode' | 'reportItemName' | 'sortNo'
                      >
                    >
                  >;
                }
            >;
            requestMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
          }
      >
    >
  >;
};

export type OrganizationMembersQueryVariables = Exact<{
  organizationId: Scalars['String'];
}>;

export type OrganizationMembersQuery = { __typename?: 'Query' } & {
  organizationMembers?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >
      >
    >
  >;
};

export type OrganizationMembersNotEnableTwoFactorAuthQueryVariables = Exact<{
  organizationId: Scalars['String'];
}>;

export type OrganizationMembersNotEnableTwoFactorAuthQuery = { __typename?: 'Query' } & {
  organizationMembersNotEnableTwoFactorAuth?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >
      >
    >
  >;
};

export type OrganizationReportQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type OrganizationReportQuery = { __typename?: 'Query' } & {
  organizationReport?: Maybe<
    { __typename?: 'OrganizationReport' } & Pick<
      OrganizationReport,
      | 'end'
      | 'id'
      | 'includeWorkingData'
      | 'reportType'
      | 'requestDateTime'
      | 'start'
      | 'status'
      | 'timeZoneOffset'
      | 'url'
      | 'versionNo'
    > & {
        requestMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
      }
  >;
};

export type OrganizationReportsQueryVariables = Exact<{ [key: string]: never }>;

export type OrganizationReportsQuery = { __typename?: 'Query' } & {
  organizationReports?: Maybe<
    Array<
      Maybe<
        { __typename?: 'OrganizationReport' } & Pick<
          OrganizationReport,
          | 'end'
          | 'id'
          | 'includeWorkingData'
          | 'reportType'
          | 'requestDateTime'
          | 'start'
          | 'status'
          | 'timeZoneOffset'
          | 'url'
          | 'versionNo'
        > & {
            requestMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
          }
      >
    >
  >;
};

export type OrganizationTagsQueryVariables = Exact<{
  input?: Maybe<FetchTagsInputInput>;
  organizationId: Scalars['String'];
}>;

export type OrganizationTagsQuery = { __typename?: 'Query' } & {
  organizationTags?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Tag' } & Pick<
          Tag,
          'id' | 'name' | 'note' | 'relatedTaskCount' | 'sortNo' | 'versionNo'
        > & {
            organization: { __typename?: 'Organization' } & Pick<
              Organization,
              | 'allowConnectThirdPartyCalendar'
              | 'contactMailAddress'
              | 'contractPreriod'
              | 'id'
              | 'imageUrl'
              | 'ipRestrictionFlg'
              | 'name'
              | 'requiredTwoFactorAuth'
              | 'suspensionFlg'
              | 'versionNo'
            > & {
                plan: { __typename?: 'PlanProduct' } & Pick<
                  PlanProduct,
                  'code' | 'contractPreriod' | 'id'
                >;
              };
          }
      >
    >
  >;
};

export type OrganizationTeamsQueryVariables = Exact<{
  organizationId: Scalars['String'];
}>;

export type OrganizationTeamsQuery = { __typename?: 'Query' } & {
  organizationTeams?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Team' } & Pick<
          Team,
          'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
        > & {
            organization: { __typename?: 'Organization' } & Pick<
              Organization,
              | 'allowConnectThirdPartyCalendar'
              | 'contactMailAddress'
              | 'contractPreriod'
              | 'id'
              | 'imageUrl'
              | 'ipRestrictionFlg'
              | 'name'
              | 'requiredTwoFactorAuth'
              | 'suspensionFlg'
              | 'versionNo'
            > & {
                plan: { __typename?: 'PlanProduct' } & Pick<
                  PlanProduct,
                  'code' | 'contractPreriod' | 'id'
                >;
              };
          }
      >
    >
  >;
};

export type OrganizationTeamsWithoutPersonalTeamQueryVariables = Exact<{
  organizationId: Scalars['String'];
}>;

export type OrganizationTeamsWithoutPersonalTeamQuery = { __typename?: 'Query' } & {
  organizationTeamsWithoutPersonalTeam?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Team' } & Pick<
          Team,
          'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
        > & {
            organization: { __typename?: 'Organization' } & Pick<
              Organization,
              | 'allowConnectThirdPartyCalendar'
              | 'contactMailAddress'
              | 'contractPreriod'
              | 'id'
              | 'imageUrl'
              | 'ipRestrictionFlg'
              | 'name'
              | 'requiredTwoFactorAuth'
              | 'suspensionFlg'
              | 'versionNo'
            > & {
                plan: { __typename?: 'PlanProduct' } & Pick<
                  PlanProduct,
                  'code' | 'contractPreriod' | 'id'
                >;
              };
          }
      >
    >
  >;
};

export type OrganizationsQueryVariables = Exact<{
  tokens?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;

export type OrganizationsQuery = { __typename?: 'Query' } & {
  organizations?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Organization' } & Pick<
          Organization,
          | 'allowConnectThirdPartyCalendar'
          | 'contactMailAddress'
          | 'contractPreriod'
          | 'id'
          | 'imageUrl'
          | 'ipRestrictionFlg'
          | 'name'
          | 'requiredTwoFactorAuth'
          | 'suspensionFlg'
          | 'versionNo'
        > & {
            plan: { __typename?: 'PlanProduct' } & Pick<
              PlanProduct,
              'code' | 'contractPreriod' | 'id'
            >;
          }
      >
    >
  >;
};

export type PasswordResetRequestQueryVariables = Exact<{
  key: Scalars['String'];
}>;

export type PasswordResetRequestQuery = { __typename?: 'Query' } & {
  passwordResetRequest?: Maybe<
    { __typename?: 'PasswordResetRequest' } & Pick<PasswordResetRequest, 'password_reset_key'>
  >;
};

export type PaymentSecretQueryVariables = Exact<{ [key: string]: never }>;

export type PaymentSecretQuery = { __typename?: 'Query' } & Pick<Query, 'paymentSecret'>;

export type PlanProductQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type PlanProductQuery = { __typename?: 'Query' } & {
  planProduct?: Maybe<
    { __typename?: 'PlanProduct' } & Pick<PlanProduct, 'code' | 'contractPreriod' | 'id'>
  >;
};

export type PlanProductsMonthlyQueryVariables = Exact<{ [key: string]: never }>;

export type PlanProductsMonthlyQuery = { __typename?: 'Query' } & {
  planProductsMonthly?: Maybe<
    Array<
      Maybe<{ __typename?: 'PlanProduct' } & Pick<PlanProduct, 'code' | 'contractPreriod' | 'id'>>
    >
  >;
};

export type PlanProductsYearlyQueryVariables = Exact<{ [key: string]: never }>;

export type PlanProductsYearlyQuery = { __typename?: 'Query' } & {
  planProductsYearly?: Maybe<
    Array<
      Maybe<{ __typename?: 'PlanProduct' } & Pick<PlanProduct, 'code' | 'contractPreriod' | 'id'>>
    >
  >;
};

export type ProjectQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ProjectQuery = { __typename?: 'Query' } & {
  project?: Maybe<
    { __typename?: 'Project' } & Pick<
      Project,
      | 'calendarLinkageCode'
      | 'color'
      | 'complete'
      | 'completeDateTime'
      | 'costBudgetWithoutTax'
      | 'description'
      | 'estimateTimeSec'
      | 'id'
      | 'key'
      | 'laborCost'
      | 'laborCostWithoutTax'
      | 'laborShare'
      | 'name'
      | 'priority'
      | 'registerDateTime'
      | 'salesAmountWithoutTax'
      | 'scheduledEndDateTime'
      | 'scheduledStartDateTime'
      | 'sortNoInGanttChart'
      | 'sortNoInList'
      | 'sortNoInProjectStatus'
      | 'totalScheduleTimeSec'
      | 'versionNo'
      | 'workingTimeSec'
    > & {
        assignees: Array<
          { __typename?: 'AssignedProjectMemberInfo' } & Pick<
            AssignedProjectMemberInfo,
            'sortNo'
          > & {
              member: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
            }
        >;
        client?: Maybe<
          { __typename?: 'Client' } & Pick<Client, 'code' | 'id' | 'name' | 'sortNo' | 'versionNo'>
        >;
        createdMember?: Maybe<
          { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >
        >;
        favoriteMembers: Array<
          Maybe<
            { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >
          >
        >;
        favoriteMembersInfo: Array<
          Maybe<
            { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
              FavoriteProjectMemberInfo,
              'sortNo'
            > & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
        projectStatus: { __typename?: 'ProjectStatus' } & Pick<
          ProjectStatus,
          'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
        >;
        team: { __typename?: 'Team' } & Pick<
          Team,
          'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
        > & {
            organization: { __typename?: 'Organization' } & Pick<
              Organization,
              | 'allowConnectThirdPartyCalendar'
              | 'contactMailAddress'
              | 'contractPreriod'
              | 'id'
              | 'imageUrl'
              | 'ipRestrictionFlg'
              | 'name'
              | 'requiredTwoFactorAuth'
              | 'suspensionFlg'
              | 'versionNo'
            > & {
                plan: { __typename?: 'PlanProduct' } & Pick<
                  PlanProduct,
                  'code' | 'contractPreriod' | 'id'
                >;
              };
          };
      }
  >;
};

export type ProjectAttachmentFilesQueryVariables = Exact<{
  projectId: Scalars['String'];
}>;

export type ProjectAttachmentFilesQuery = { __typename?: 'Query' } & {
  projectAttachmentFiles?: Maybe<
    Array<
      Maybe<
        { __typename?: 'AttachmentFile' } & Pick<
          AttachmentFile,
          'fileName' | 'id' | 'sizeByte' | 'type' | 'uploadDateTime'
        >
      >
    >
  >;
};

export type ProjectBarGraphDataQueryVariables = Exact<{
  input: ProjectGraphDataInputInput;
}>;

export type ProjectBarGraphDataQuery = { __typename?: 'Query' } & {
  projectBarGraphData?: Maybe<
    Array<
      Maybe<
        { __typename?: 'ProjectBarGraphData' } & Pick<
          ProjectBarGraphData,
          | 'color'
          | 'percent'
          | 'projectId'
          | 'projectName'
          | 'reportType'
          | 'teamId'
          | 'teamName'
          | 'workingTimeSec'
        >
      >
    >
  >;
};

export type ProjectByKeyQueryVariables = Exact<{
  key: Scalars['String'];
}>;

export type ProjectByKeyQuery = { __typename?: 'Query' } & {
  projectByKey?: Maybe<
    { __typename?: 'Project' } & Pick<
      Project,
      | 'calendarLinkageCode'
      | 'color'
      | 'complete'
      | 'completeDateTime'
      | 'costBudgetWithoutTax'
      | 'description'
      | 'estimateTimeSec'
      | 'id'
      | 'key'
      | 'laborCost'
      | 'laborCostWithoutTax'
      | 'laborShare'
      | 'name'
      | 'priority'
      | 'registerDateTime'
      | 'salesAmountWithoutTax'
      | 'scheduledEndDateTime'
      | 'scheduledStartDateTime'
      | 'sortNoInGanttChart'
      | 'sortNoInList'
      | 'sortNoInProjectStatus'
      | 'totalScheduleTimeSec'
      | 'versionNo'
      | 'workingTimeSec'
    > & {
        assignees: Array<
          { __typename?: 'AssignedProjectMemberInfo' } & Pick<
            AssignedProjectMemberInfo,
            'sortNo'
          > & {
              member: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
            }
        >;
        client?: Maybe<
          { __typename?: 'Client' } & Pick<Client, 'code' | 'id' | 'name' | 'sortNo' | 'versionNo'>
        >;
        createdMember?: Maybe<
          { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >
        >;
        favoriteMembers: Array<
          Maybe<
            { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >
          >
        >;
        favoriteMembersInfo: Array<
          Maybe<
            { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
              FavoriteProjectMemberInfo,
              'sortNo'
            > & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
        projectStatus: { __typename?: 'ProjectStatus' } & Pick<
          ProjectStatus,
          'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
        >;
        team: { __typename?: 'Team' } & Pick<
          Team,
          'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
        > & {
            organization: { __typename?: 'Organization' } & Pick<
              Organization,
              | 'allowConnectThirdPartyCalendar'
              | 'contactMailAddress'
              | 'contractPreriod'
              | 'id'
              | 'imageUrl'
              | 'ipRestrictionFlg'
              | 'name'
              | 'requiredTwoFactorAuth'
              | 'suspensionFlg'
              | 'versionNo'
            > & {
                plan: { __typename?: 'PlanProduct' } & Pick<
                  PlanProduct,
                  'code' | 'contractPreriod' | 'id'
                >;
              };
          };
      }
  >;
};

export type ProjectCalendarEventsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  projectId: Scalars['String'];
}>;

export type ProjectCalendarEventsQuery = { __typename?: 'Query' } & {
  projectCalendarEvents?: Maybe<
    Array<
      Maybe<
        { __typename?: 'CalendarEvent' } & Pick<
          CalendarEvent,
          | 'calendarType'
          | 'clientId'
          | 'clientName'
          | 'endDateTime'
          | 'eventName'
          | 'id'
          | 'laborCost'
          | 'personalTeam'
          | 'projectColor'
          | 'projectId'
          | 'projectName'
          | 'startDateTime'
          | 'taskId'
          | 'taskTitle'
          | 'teamId'
          | 'teamName'
          | 'thirdPartyCalendarEventId'
          | 'versionNo'
          | 'workingTimeSec'
        > & {
            thirdPartyCalendarInfoList: Array<
              Maybe<
                { __typename?: 'ThirdPartyCalendarInfo' } & Pick<
                  ThirdPartyCalendarInfo,
                  'thirdPartyCalendarId'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >
            >;
          }
      >
    >
  >;
};

export type ProjectContractQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
}>;

export type ProjectContractQuery = { __typename?: 'Query' } & {
  projectContract?: Maybe<
    { __typename?: 'ProjectContract' } & Pick<
      ProjectContract,
      | 'contractType'
      | 'costBudgetWithoutTax'
      | 'endDateTime'
      | 'fixSalesAmountWithoutTax'
      | 'grossProfit'
      | 'grossProfitRatePercent'
      | 'id'
      | 'laborCostWithoutTax'
      | 'laborShare'
      | 'month'
      | 'roundTimeType'
      | 'roundedTotalWorkingTimeSec'
      | 'salesAmountWithoutTax'
      | 'salesRoundMinutes'
      | 'startDateTime'
      | 'taxRate'
      | 'termType'
      | 'totalWorkingTimeSec'
      | 'unitPriceType'
      | 'versionNo'
      | 'year'
    > & {
        allMemberMeteredRateContract?: Maybe<
          { __typename?: 'MeteredRateContract' } & Pick<
            MeteredRateContract,
            'hourUnitPrice' | 'id' | 'versionNo'
          > & {
              member?: Maybe<
                { __typename?: 'MeteredRateContractMemberPart' } & Pick<
                  MeteredRateContractMemberPart,
                  | 'laborCostWithoutTax'
                  | 'memberId'
                  | 'name'
                  | 'profileImageUrl'
                  | 'roundedTotalWorkingTimeSec'
                  | 'salesAmountWithoutTax'
                  | 'totalWorkingTimeSec'
                >
              >;
            }
        >;
        allMemberSettlementRangeContract?: Maybe<
          { __typename?: 'SettlementRangeContract' } & Pick<
            SettlementRangeContract,
            'id' | 'monthlyUnitPrice' | 'versionNo'
          > & {
              lowerHourPart: { __typename?: 'LowerPart' } & Pick<
                LowerPart,
                'deducationHourlyUnitPrice' | 'lowerHour'
              >;
              member?: Maybe<
                { __typename?: 'SettlementRangeContractMemberPart' } & Pick<
                  SettlementRangeContractMemberPart,
                  | 'laborCostWithoutTax'
                  | 'memberId'
                  | 'name'
                  | 'profileImageUrl'
                  | 'roundedTotalWorkingTimeSec'
                  | 'salesAmountWithoutTax'
                  | 'totalWorkingTimeSec'
                >
              >;
              upperHourPart: { __typename?: 'UpperPart' } & Pick<
                UpperPart,
                'excessHourlyUnitPrice' | 'upperHour'
              >;
            }
        >;
        membersMeteredRateContract?: Maybe<
          Array<
            Maybe<
              { __typename?: 'MeteredRateContract' } & Pick<
                MeteredRateContract,
                'hourUnitPrice' | 'id' | 'versionNo'
              > & {
                  member?: Maybe<
                    { __typename?: 'MeteredRateContractMemberPart' } & Pick<
                      MeteredRateContractMemberPart,
                      | 'laborCostWithoutTax'
                      | 'memberId'
                      | 'name'
                      | 'profileImageUrl'
                      | 'roundedTotalWorkingTimeSec'
                      | 'salesAmountWithoutTax'
                      | 'totalWorkingTimeSec'
                    >
                  >;
                }
            >
          >
        >;
        membersSettlementRangeContract?: Maybe<
          Array<
            Maybe<
              { __typename?: 'SettlementRangeContract' } & Pick<
                SettlementRangeContract,
                'id' | 'monthlyUnitPrice' | 'versionNo'
              > & {
                  lowerHourPart: { __typename?: 'LowerPart' } & Pick<
                    LowerPart,
                    'deducationHourlyUnitPrice' | 'lowerHour'
                  >;
                  member?: Maybe<
                    { __typename?: 'SettlementRangeContractMemberPart' } & Pick<
                      SettlementRangeContractMemberPart,
                      | 'laborCostWithoutTax'
                      | 'memberId'
                      | 'name'
                      | 'profileImageUrl'
                      | 'roundedTotalWorkingTimeSec'
                      | 'salesAmountWithoutTax'
                      | 'totalWorkingTimeSec'
                    >
                  >;
                  upperHourPart: { __typename?: 'UpperPart' } & Pick<
                    UpperPart,
                    'excessHourlyUnitPrice' | 'upperHour'
                  >;
                }
            >
          >
        >;
        project?: Maybe<
          { __typename?: 'Project' } & Pick<
            Project,
            | 'calendarLinkageCode'
            | 'color'
            | 'complete'
            | 'completeDateTime'
            | 'costBudgetWithoutTax'
            | 'description'
            | 'estimateTimeSec'
            | 'id'
            | 'key'
            | 'laborCost'
            | 'laborCostWithoutTax'
            | 'laborShare'
            | 'name'
            | 'priority'
            | 'registerDateTime'
            | 'salesAmountWithoutTax'
            | 'scheduledEndDateTime'
            | 'scheduledStartDateTime'
            | 'sortNoInGanttChart'
            | 'sortNoInList'
            | 'sortNoInProjectStatus'
            | 'totalScheduleTimeSec'
            | 'versionNo'
            | 'workingTimeSec'
          > & {
              assignees: Array<
                { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                  AssignedProjectMemberInfo,
                  'sortNo'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >;
              client?: Maybe<
                { __typename?: 'Client' } & Pick<
                  Client,
                  'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                >
              >;
              createdMember?: Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >;
              favoriteMembers: Array<
                Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >
              >;
              favoriteMembersInfo: Array<
                Maybe<
                  { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                    FavoriteProjectMemberInfo,
                    'sortNo'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >
              >;
              projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                ProjectStatus,
                'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
              >;
              team: { __typename?: 'Team' } & Pick<
                Team,
                'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
              > & {
                  organization: { __typename?: 'Organization' } & Pick<
                    Organization,
                    | 'allowConnectThirdPartyCalendar'
                    | 'contactMailAddress'
                    | 'contractPreriod'
                    | 'id'
                    | 'imageUrl'
                    | 'ipRestrictionFlg'
                    | 'name'
                    | 'requiredTwoFactorAuth'
                    | 'suspensionFlg'
                    | 'versionNo'
                  > & {
                      plan: { __typename?: 'PlanProduct' } & Pick<
                        PlanProduct,
                        'code' | 'contractPreriod' | 'id'
                      >;
                    };
                };
            }
        >;
        timeZone: { __typename?: 'TimeZone' } & Pick<
          TimeZone,
          'code' | 'id' | 'name' | 'offsetTime' | 'sortNo'
        >;
      }
  >;
};

export type ProjectContractsByProjectIdQueryVariables = Exact<{
  projectId?: Maybe<Scalars['String']>;
}>;

export type ProjectContractsByProjectIdQuery = { __typename?: 'Query' } & {
  projectContractsByProjectId?: Maybe<
    Array<
      Maybe<
        { __typename?: 'ProjectContract' } & Pick<
          ProjectContract,
          | 'contractType'
          | 'costBudgetWithoutTax'
          | 'endDateTime'
          | 'fixSalesAmountWithoutTax'
          | 'grossProfit'
          | 'grossProfitRatePercent'
          | 'id'
          | 'laborCostWithoutTax'
          | 'laborShare'
          | 'month'
          | 'roundTimeType'
          | 'roundedTotalWorkingTimeSec'
          | 'salesAmountWithoutTax'
          | 'salesRoundMinutes'
          | 'startDateTime'
          | 'taxRate'
          | 'termType'
          | 'totalWorkingTimeSec'
          | 'unitPriceType'
          | 'versionNo'
          | 'year'
        > & {
            allMemberMeteredRateContract?: Maybe<
              { __typename?: 'MeteredRateContract' } & Pick<
                MeteredRateContract,
                'hourUnitPrice' | 'id' | 'versionNo'
              > & {
                  member?: Maybe<
                    { __typename?: 'MeteredRateContractMemberPart' } & Pick<
                      MeteredRateContractMemberPart,
                      | 'laborCostWithoutTax'
                      | 'memberId'
                      | 'name'
                      | 'profileImageUrl'
                      | 'roundedTotalWorkingTimeSec'
                      | 'salesAmountWithoutTax'
                      | 'totalWorkingTimeSec'
                    >
                  >;
                }
            >;
            allMemberSettlementRangeContract?: Maybe<
              { __typename?: 'SettlementRangeContract' } & Pick<
                SettlementRangeContract,
                'id' | 'monthlyUnitPrice' | 'versionNo'
              > & {
                  lowerHourPart: { __typename?: 'LowerPart' } & Pick<
                    LowerPart,
                    'deducationHourlyUnitPrice' | 'lowerHour'
                  >;
                  member?: Maybe<
                    { __typename?: 'SettlementRangeContractMemberPart' } & Pick<
                      SettlementRangeContractMemberPart,
                      | 'laborCostWithoutTax'
                      | 'memberId'
                      | 'name'
                      | 'profileImageUrl'
                      | 'roundedTotalWorkingTimeSec'
                      | 'salesAmountWithoutTax'
                      | 'totalWorkingTimeSec'
                    >
                  >;
                  upperHourPart: { __typename?: 'UpperPart' } & Pick<
                    UpperPart,
                    'excessHourlyUnitPrice' | 'upperHour'
                  >;
                }
            >;
            membersMeteredRateContract?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'MeteredRateContract' } & Pick<
                    MeteredRateContract,
                    'hourUnitPrice' | 'id' | 'versionNo'
                  > & {
                      member?: Maybe<
                        { __typename?: 'MeteredRateContractMemberPart' } & Pick<
                          MeteredRateContractMemberPart,
                          | 'laborCostWithoutTax'
                          | 'memberId'
                          | 'name'
                          | 'profileImageUrl'
                          | 'roundedTotalWorkingTimeSec'
                          | 'salesAmountWithoutTax'
                          | 'totalWorkingTimeSec'
                        >
                      >;
                    }
                >
              >
            >;
            membersSettlementRangeContract?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'SettlementRangeContract' } & Pick<
                    SettlementRangeContract,
                    'id' | 'monthlyUnitPrice' | 'versionNo'
                  > & {
                      lowerHourPart: { __typename?: 'LowerPart' } & Pick<
                        LowerPart,
                        'deducationHourlyUnitPrice' | 'lowerHour'
                      >;
                      member?: Maybe<
                        { __typename?: 'SettlementRangeContractMemberPart' } & Pick<
                          SettlementRangeContractMemberPart,
                          | 'laborCostWithoutTax'
                          | 'memberId'
                          | 'name'
                          | 'profileImageUrl'
                          | 'roundedTotalWorkingTimeSec'
                          | 'salesAmountWithoutTax'
                          | 'totalWorkingTimeSec'
                        >
                      >;
                      upperHourPart: { __typename?: 'UpperPart' } & Pick<
                        UpperPart,
                        'excessHourlyUnitPrice' | 'upperHour'
                      >;
                    }
                >
              >
            >;
            project?: Maybe<
              { __typename?: 'Project' } & Pick<
                Project,
                | 'calendarLinkageCode'
                | 'color'
                | 'complete'
                | 'completeDateTime'
                | 'costBudgetWithoutTax'
                | 'description'
                | 'estimateTimeSec'
                | 'id'
                | 'key'
                | 'laborCost'
                | 'laborCostWithoutTax'
                | 'laborShare'
                | 'name'
                | 'priority'
                | 'registerDateTime'
                | 'salesAmountWithoutTax'
                | 'scheduledEndDateTime'
                | 'scheduledStartDateTime'
                | 'sortNoInGanttChart'
                | 'sortNoInList'
                | 'sortNoInProjectStatus'
                | 'totalScheduleTimeSec'
                | 'versionNo'
                | 'workingTimeSec'
              > & {
                  assignees: Array<
                    { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                      AssignedProjectMemberInfo,
                      'sortNo'
                    > & {
                        member: { __typename?: 'Member' } & Pick<
                          Member,
                          | 'adminRole'
                          | 'clientManagementRole'
                          | 'department'
                          | 'employeeNumber'
                          | 'id'
                          | 'laborCostManagementRole'
                          | 'mailAddress'
                          | 'memberManagementRole'
                          | 'memberRegistrationDateTime'
                          | 'memberStatus'
                          | 'name'
                          | 'organizationMemberViewRole'
                          | 'organizationReportRole'
                          | 'profileImageUrl'
                          | 'projectCreatePermissionFlg'
                          | 'projectCustomAttributePermissionFlg'
                          | 'projectDeletePermissionFlg'
                          | 'projectUpdatePermissionFlg'
                          | 'proxyEditWorkingDataRole'
                          | 'salesManagementRole'
                          | 'statusMessage'
                          | 'taskCreatePermissionFlg'
                          | 'taskCustomAttributePermissionFlg'
                          | 'taskDeletePermissionFlg'
                          | 'taskUpdatePermissionFlg'
                          | 'teamMemberViewRole'
                          | 'teamReportRole'
                          | 'versionNo'
                        >;
                      }
                  >;
                  client?: Maybe<
                    { __typename?: 'Client' } & Pick<
                      Client,
                      'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                    >
                  >;
                  createdMember?: Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >;
                  favoriteMembers: Array<
                    Maybe<
                      { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >
                    >
                  >;
                  favoriteMembersInfo: Array<
                    Maybe<
                      { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                        FavoriteProjectMemberInfo,
                        'sortNo'
                      > & {
                          member: { __typename?: 'Member' } & Pick<
                            Member,
                            | 'adminRole'
                            | 'clientManagementRole'
                            | 'department'
                            | 'employeeNumber'
                            | 'id'
                            | 'laborCostManagementRole'
                            | 'mailAddress'
                            | 'memberManagementRole'
                            | 'memberRegistrationDateTime'
                            | 'memberStatus'
                            | 'name'
                            | 'organizationMemberViewRole'
                            | 'organizationReportRole'
                            | 'profileImageUrl'
                            | 'projectCreatePermissionFlg'
                            | 'projectCustomAttributePermissionFlg'
                            | 'projectDeletePermissionFlg'
                            | 'projectUpdatePermissionFlg'
                            | 'proxyEditWorkingDataRole'
                            | 'salesManagementRole'
                            | 'statusMessage'
                            | 'taskCreatePermissionFlg'
                            | 'taskCustomAttributePermissionFlg'
                            | 'taskDeletePermissionFlg'
                            | 'taskUpdatePermissionFlg'
                            | 'teamMemberViewRole'
                            | 'teamReportRole'
                            | 'versionNo'
                          >;
                        }
                    >
                  >;
                  projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                    ProjectStatus,
                    'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                  >;
                  team: { __typename?: 'Team' } & Pick<
                    Team,
                    'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                  > & {
                      organization: { __typename?: 'Organization' } & Pick<
                        Organization,
                        | 'allowConnectThirdPartyCalendar'
                        | 'contactMailAddress'
                        | 'contractPreriod'
                        | 'id'
                        | 'imageUrl'
                        | 'ipRestrictionFlg'
                        | 'name'
                        | 'requiredTwoFactorAuth'
                        | 'suspensionFlg'
                        | 'versionNo'
                      > & {
                          plan: { __typename?: 'PlanProduct' } & Pick<
                            PlanProduct,
                            'code' | 'contractPreriod' | 'id'
                          >;
                        };
                    };
                }
            >;
            timeZone: { __typename?: 'TimeZone' } & Pick<
              TimeZone,
              'code' | 'id' | 'name' | 'offsetTime' | 'sortNo'
            >;
          }
      >
    >
  >;
};

export type ProjectCustomAttributeQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ProjectCustomAttributeQuery = { __typename?: 'Query' } & {
  projectCustomAttribute?: Maybe<
    { __typename?: 'ProjectCustomAttribute' } & Pick<
      ProjectCustomAttribute,
      'id' | 'sortNo' | 'value'
    > & {
        listItem?: Maybe<
          { __typename?: 'ProjectCustomAttributeMasterListItem' } & Pick<
            ProjectCustomAttributeMasterListItem,
            'id' | 'sortNo' | 'value' | 'versionNo'
          >
        >;
        master: { __typename?: 'ProjectCustomAttributeMaster' } & Pick<
          ProjectCustomAttributeMaster,
          'id' | 'name' | 'required' | 'sortNo' | 'type' | 'versionNo'
        > & {
            listItem: Array<
              Maybe<
                { __typename?: 'ProjectCustomAttributeMasterListItem' } & Pick<
                  ProjectCustomAttributeMasterListItem,
                  'id' | 'sortNo' | 'value' | 'versionNo'
                >
              >
            >;
          };
      }
  >;
};

export type ProjectCustomAttributeMasterQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ProjectCustomAttributeMasterQuery = { __typename?: 'Query' } & {
  projectCustomAttributeMaster?: Maybe<
    { __typename?: 'ProjectCustomAttributeMaster' } & Pick<
      ProjectCustomAttributeMaster,
      'id' | 'name' | 'required' | 'sortNo' | 'type' | 'versionNo'
    > & {
        listItem: Array<
          Maybe<
            { __typename?: 'ProjectCustomAttributeMasterListItem' } & Pick<
              ProjectCustomAttributeMasterListItem,
              'id' | 'sortNo' | 'value' | 'versionNo'
            >
          >
        >;
      }
  >;
};

export type ProjectCustomAttributeMastersQueryVariables = Exact<{ [key: string]: never }>;

export type ProjectCustomAttributeMastersQuery = { __typename?: 'Query' } & {
  projectCustomAttributeMasters?: Maybe<
    Array<
      Maybe<
        { __typename?: 'ProjectCustomAttributeMaster' } & Pick<
          ProjectCustomAttributeMaster,
          'id' | 'name' | 'required' | 'sortNo' | 'type' | 'versionNo'
        > & {
            listItem: Array<
              Maybe<
                { __typename?: 'ProjectCustomAttributeMasterListItem' } & Pick<
                  ProjectCustomAttributeMasterListItem,
                  'id' | 'sortNo' | 'value' | 'versionNo'
                >
              >
            >;
          }
      >
    >
  >;
};

export type ProjectCustomAttributeTypesQueryVariables = Exact<{ [key: string]: never }>;

export type ProjectCustomAttributeTypesQuery = { __typename?: 'Query' } & {
  projectCustomAttributeTypes?: Maybe<
    Array<
      Maybe<
        { __typename?: 'ProjectCustomAttributeType' } & Pick<
          ProjectCustomAttributeType,
          'code' | 'name' | 'sortNo'
        >
      >
    >
  >;
};

export type ProjectCustomAttributesByProjectQueryVariables = Exact<{
  projectId: Scalars['String'];
}>;

export type ProjectCustomAttributesByProjectQuery = { __typename?: 'Query' } & {
  projectCustomAttributesByProject?: Maybe<
    Array<
      Maybe<
        { __typename?: 'ProjectCustomAttribute' } & Pick<
          ProjectCustomAttribute,
          'id' | 'sortNo' | 'value'
        > & {
            listItem?: Maybe<
              { __typename?: 'ProjectCustomAttributeMasterListItem' } & Pick<
                ProjectCustomAttributeMasterListItem,
                'id' | 'sortNo' | 'value' | 'versionNo'
              >
            >;
            master: { __typename?: 'ProjectCustomAttributeMaster' } & Pick<
              ProjectCustomAttributeMaster,
              'id' | 'name' | 'required' | 'sortNo' | 'type' | 'versionNo'
            > & {
                listItem: Array<
                  Maybe<
                    { __typename?: 'ProjectCustomAttributeMasterListItem' } & Pick<
                      ProjectCustomAttributeMasterListItem,
                      'id' | 'sortNo' | 'value' | 'versionNo'
                    >
                  >
                >;
              };
          }
      >
    >
  >;
};

export type ProjectDateGraphDataQueryVariables = Exact<{
  input: ProjectGraphDataInputInput;
}>;

export type ProjectDateGraphDataQuery = { __typename?: 'Query' } & {
  projectDateGraphData?: Maybe<
    Array<
      Maybe<
        { __typename?: 'ProjectDateGraphData' } & Pick<
          ProjectDateGraphData,
          'date' | 'reportType'
        > & {
            items: Array<
              Maybe<
                { __typename?: 'ProjectDateGraphDataDetail' } & Pick<
                  ProjectDateGraphDataDetail,
                  | 'color'
                  | 'percent'
                  | 'projectId'
                  | 'projectName'
                  | 'teamId'
                  | 'teamName'
                  | 'workingTimeSec'
                >
              >
            >;
          }
      >
    >
  >;
};

export type ProjectMembersQueryVariables = Exact<{
  projectId: Scalars['String'];
}>;

export type ProjectMembersQuery = { __typename?: 'Query' } & {
  projectMembers?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >
      >
    >
  >;
};

export type ProjectStatusQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type ProjectStatusQuery = { __typename?: 'Query' } & {
  projectStatus?: Maybe<
    { __typename?: 'ProjectStatus' } & Pick<
      ProjectStatus,
      'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
    >
  >;
};

export type ProjectSunburstGraphDataQueryVariables = Exact<{
  input: ProjectGraphDataInputInput;
}>;

export type ProjectSunburstGraphDataQuery = { __typename?: 'Query' } & {
  projectSunburstGraphData?: Maybe<
    Array<
      Maybe<
        { __typename?: 'ProjectSunburstGraphData' } & Pick<
          ProjectSunburstGraphData,
          'color' | 'name' | 'reportType' | 'teamId'
        > & {
            children: Array<
              Maybe<
                { __typename?: 'ProjectSunburstGraphProjectData' } & Pick<
                  ProjectSunburstGraphProjectData,
                  'color' | 'name' | 'percent' | 'projectId' | 'workingTimeSec'
                >
              >
            >;
          }
      >
    >
  >;
};

export type ProjectTaskStatusQueryVariables = Exact<{
  projectId: Scalars['String'];
}>;

export type ProjectTaskStatusQuery = { __typename?: 'Query' } & {
  projectTaskStatus?: Maybe<
    Array<
      Maybe<
        { __typename?: 'TaskStatus' } & Pick<
          TaskStatus,
          'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
        > & {
            project: { __typename?: 'Project' } & Pick<
              Project,
              | 'calendarLinkageCode'
              | 'color'
              | 'complete'
              | 'completeDateTime'
              | 'costBudgetWithoutTax'
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'key'
              | 'laborCost'
              | 'laborCostWithoutTax'
              | 'laborShare'
              | 'name'
              | 'priority'
              | 'registerDateTime'
              | 'salesAmountWithoutTax'
              | 'scheduledEndDateTime'
              | 'scheduledStartDateTime'
              | 'sortNoInGanttChart'
              | 'sortNoInList'
              | 'sortNoInProjectStatus'
              | 'totalScheduleTimeSec'
              | 'versionNo'
              | 'workingTimeSec'
            > & {
                assignees: Array<
                  { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                    AssignedProjectMemberInfo,
                    'sortNo'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >;
                client?: Maybe<
                  { __typename?: 'Client' } & Pick<
                    Client,
                    'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                  >
                >;
                createdMember?: Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >;
                favoriteMembers: Array<
                  Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >
                >;
                favoriteMembersInfo: Array<
                  Maybe<
                    { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                      FavoriteProjectMemberInfo,
                      'sortNo'
                    > & {
                        member: { __typename?: 'Member' } & Pick<
                          Member,
                          | 'adminRole'
                          | 'clientManagementRole'
                          | 'department'
                          | 'employeeNumber'
                          | 'id'
                          | 'laborCostManagementRole'
                          | 'mailAddress'
                          | 'memberManagementRole'
                          | 'memberRegistrationDateTime'
                          | 'memberStatus'
                          | 'name'
                          | 'organizationMemberViewRole'
                          | 'organizationReportRole'
                          | 'profileImageUrl'
                          | 'projectCreatePermissionFlg'
                          | 'projectCustomAttributePermissionFlg'
                          | 'projectDeletePermissionFlg'
                          | 'projectUpdatePermissionFlg'
                          | 'proxyEditWorkingDataRole'
                          | 'salesManagementRole'
                          | 'statusMessage'
                          | 'taskCreatePermissionFlg'
                          | 'taskCustomAttributePermissionFlg'
                          | 'taskDeletePermissionFlg'
                          | 'taskUpdatePermissionFlg'
                          | 'teamMemberViewRole'
                          | 'teamReportRole'
                          | 'versionNo'
                        >;
                      }
                  >
                >;
                projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                  ProjectStatus,
                  'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                >;
                team: { __typename?: 'Team' } & Pick<
                  Team,
                  'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                > & {
                    organization: { __typename?: 'Organization' } & Pick<
                      Organization,
                      | 'allowConnectThirdPartyCalendar'
                      | 'contactMailAddress'
                      | 'contractPreriod'
                      | 'id'
                      | 'imageUrl'
                      | 'ipRestrictionFlg'
                      | 'name'
                      | 'requiredTwoFactorAuth'
                      | 'suspensionFlg'
                      | 'versionNo'
                    > & {
                        plan: { __typename?: 'PlanProduct' } & Pick<
                          PlanProduct,
                          'code' | 'contractPreriod' | 'id'
                        >;
                      };
                  };
              };
          }
      >
    >
  >;
};

export type ProjectTasksQueryVariables = Exact<{
  input?: Maybe<FetchProjectTasksInputInput>;
  projectId: Scalars['String'];
}>;

export type ProjectTasksQuery = { __typename?: 'Query' } & {
  projectTasks?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Task' } & Pick<
          Task,
          | 'calendarLinkageCode'
          | 'completeDateTime'
          | 'description'
          | 'estimateTimeSec'
          | 'estimatedRemainingTimeSec'
          | 'id'
          | 'laborCost'
          | 'priority'
          | 'progressDeviationRate'
          | 'progressRate'
          | 'registerDateTime'
          | 'remainingTimeSec'
          | 'scheduleWorkingTimeSec'
          | 'scheduledEndDateTime'
          | 'scheduledStartDateTime'
          | 'sortNoInGanttChart'
          | 'sortNoInList'
          | 'sortNoInMyTask'
          | 'sortNoInTaskStatus'
          | 'title'
          | 'versionNo'
          | 'workingTimeSec'
        > & {
            assignees: Array<
              { __typename?: 'AssignedTaskMemberInfo' } & Pick<AssignedTaskMemberInfo, 'sortNo'> & {
                  member: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                }
            >;
            asssignee?: Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >;
            createdMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
            favoriteMembers: Array<
              Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >
            >;
            favoriteMembersInfo: Array<
              Maybe<
                { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                  FavoriteTaskMemberInfo,
                  'sortNo'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >
            >;
            project: { __typename?: 'Project' } & Pick<
              Project,
              | 'calendarLinkageCode'
              | 'color'
              | 'complete'
              | 'completeDateTime'
              | 'costBudgetWithoutTax'
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'key'
              | 'laborCost'
              | 'laborCostWithoutTax'
              | 'laborShare'
              | 'name'
              | 'priority'
              | 'registerDateTime'
              | 'salesAmountWithoutTax'
              | 'scheduledEndDateTime'
              | 'scheduledStartDateTime'
              | 'sortNoInGanttChart'
              | 'sortNoInList'
              | 'sortNoInProjectStatus'
              | 'totalScheduleTimeSec'
              | 'versionNo'
              | 'workingTimeSec'
            > & {
                assignees: Array<
                  { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                    AssignedProjectMemberInfo,
                    'sortNo'
                  >
                >;
                client?: Maybe<
                  { __typename?: 'Client' } & Pick<
                    Client,
                    'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                  >
                >;
                createdMember?: Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >;
                favoriteMembers: Array<
                  Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >
                >;
                favoriteMembersInfo: Array<
                  Maybe<
                    { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                      FavoriteProjectMemberInfo,
                      'sortNo'
                    >
                  >
                >;
                projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                  ProjectStatus,
                  'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                >;
                team: { __typename?: 'Team' } & Pick<
                  Team,
                  'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                > & {
                    organization: { __typename?: 'Organization' } & Pick<
                      Organization,
                      | 'allowConnectThirdPartyCalendar'
                      | 'contactMailAddress'
                      | 'contractPreriod'
                      | 'id'
                      | 'imageUrl'
                      | 'ipRestrictionFlg'
                      | 'name'
                      | 'requiredTwoFactorAuth'
                      | 'suspensionFlg'
                      | 'versionNo'
                    > & {
                        plan: { __typename?: 'PlanProduct' } & Pick<
                          PlanProduct,
                          'code' | 'contractPreriod' | 'id'
                        >;
                      };
                  };
              };
            taskStatus: { __typename?: 'TaskStatus' } & Pick<
              TaskStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
            > & {
                project: { __typename?: 'Project' } & Pick<
                  Project,
                  | 'calendarLinkageCode'
                  | 'color'
                  | 'complete'
                  | 'completeDateTime'
                  | 'costBudgetWithoutTax'
                  | 'description'
                  | 'estimateTimeSec'
                  | 'id'
                  | 'key'
                  | 'laborCost'
                  | 'laborCostWithoutTax'
                  | 'laborShare'
                  | 'name'
                  | 'priority'
                  | 'registerDateTime'
                  | 'salesAmountWithoutTax'
                  | 'scheduledEndDateTime'
                  | 'scheduledStartDateTime'
                  | 'sortNoInGanttChart'
                  | 'sortNoInList'
                  | 'sortNoInProjectStatus'
                  | 'totalScheduleTimeSec'
                  | 'versionNo'
                  | 'workingTimeSec'
                >;
              };
            workingMembers: Array<
              Maybe<
                { __typename?: 'WorkingMemberInfo' } & Pick<
                  WorkingMemberInfo,
                  'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >
            >;
          }
      >
    >
  >;
};

export type ProjectTasksEstimateTimeSecQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ProjectTasksEstimateTimeSecQuery = { __typename?: 'Query' } & Pick<
  Query,
  'projectTasksEstimateTimeSec'
>;

export type ProjectTasksForSelectorQueryVariables = Exact<{
  input?: Maybe<FetchProjectTasksInputInput>;
  projectId: Scalars['String'];
}>;

export type ProjectTasksForSelectorQuery = { __typename?: 'Query' } & {
  projectTasksForSelector?: Maybe<
    Array<
      Maybe<
        { __typename?: 'TaskForSelector' } & Pick<
          TaskForSelector,
          'completeDateTime' | 'id' | 'projectId' | 'projectName' | 'title'
        >
      >
    >
  >;
};

export type ProjectTasksGroupByStatusQueryVariables = Exact<{
  projectId?: Maybe<Scalars['String']>;
}>;

export type ProjectTasksGroupByStatusQuery = { __typename?: 'Query' } & {
  projectTasksGroupByStatus?: Maybe<
    Array<
      Maybe<
        { __typename?: 'TaskStatusWithTask' } & {
          taskStatus: { __typename?: 'TaskStatus' } & Pick<
            TaskStatus,
            'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
          > & {
              project: { __typename?: 'Project' } & Pick<
                Project,
                | 'calendarLinkageCode'
                | 'color'
                | 'complete'
                | 'completeDateTime'
                | 'costBudgetWithoutTax'
                | 'description'
                | 'estimateTimeSec'
                | 'id'
                | 'key'
                | 'laborCost'
                | 'laborCostWithoutTax'
                | 'laborShare'
                | 'name'
                | 'priority'
                | 'registerDateTime'
                | 'salesAmountWithoutTax'
                | 'scheduledEndDateTime'
                | 'scheduledStartDateTime'
                | 'sortNoInGanttChart'
                | 'sortNoInList'
                | 'sortNoInProjectStatus'
                | 'totalScheduleTimeSec'
                | 'versionNo'
                | 'workingTimeSec'
              > & {
                  assignees: Array<
                    { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                      AssignedProjectMemberInfo,
                      'sortNo'
                    > & {
                        member: { __typename?: 'Member' } & Pick<
                          Member,
                          | 'adminRole'
                          | 'clientManagementRole'
                          | 'department'
                          | 'employeeNumber'
                          | 'id'
                          | 'laborCostManagementRole'
                          | 'mailAddress'
                          | 'memberManagementRole'
                          | 'memberRegistrationDateTime'
                          | 'memberStatus'
                          | 'name'
                          | 'organizationMemberViewRole'
                          | 'organizationReportRole'
                          | 'profileImageUrl'
                          | 'projectCreatePermissionFlg'
                          | 'projectCustomAttributePermissionFlg'
                          | 'projectDeletePermissionFlg'
                          | 'projectUpdatePermissionFlg'
                          | 'proxyEditWorkingDataRole'
                          | 'salesManagementRole'
                          | 'statusMessage'
                          | 'taskCreatePermissionFlg'
                          | 'taskCustomAttributePermissionFlg'
                          | 'taskDeletePermissionFlg'
                          | 'taskUpdatePermissionFlg'
                          | 'teamMemberViewRole'
                          | 'teamReportRole'
                          | 'versionNo'
                        >;
                      }
                  >;
                  client?: Maybe<
                    { __typename?: 'Client' } & Pick<
                      Client,
                      'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                    >
                  >;
                  createdMember?: Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >;
                  favoriteMembers: Array<
                    Maybe<
                      { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >
                    >
                  >;
                  favoriteMembersInfo: Array<
                    Maybe<
                      { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                        FavoriteProjectMemberInfo,
                        'sortNo'
                      > & {
                          member: { __typename?: 'Member' } & Pick<
                            Member,
                            | 'adminRole'
                            | 'clientManagementRole'
                            | 'department'
                            | 'employeeNumber'
                            | 'id'
                            | 'laborCostManagementRole'
                            | 'mailAddress'
                            | 'memberManagementRole'
                            | 'memberRegistrationDateTime'
                            | 'memberStatus'
                            | 'name'
                            | 'organizationMemberViewRole'
                            | 'organizationReportRole'
                            | 'profileImageUrl'
                            | 'projectCreatePermissionFlg'
                            | 'projectCustomAttributePermissionFlg'
                            | 'projectDeletePermissionFlg'
                            | 'projectUpdatePermissionFlg'
                            | 'proxyEditWorkingDataRole'
                            | 'salesManagementRole'
                            | 'statusMessage'
                            | 'taskCreatePermissionFlg'
                            | 'taskCustomAttributePermissionFlg'
                            | 'taskDeletePermissionFlg'
                            | 'taskUpdatePermissionFlg'
                            | 'teamMemberViewRole'
                            | 'teamReportRole'
                            | 'versionNo'
                          >;
                        }
                    >
                  >;
                  projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                    ProjectStatus,
                    'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                  >;
                  team: { __typename?: 'Team' } & Pick<
                    Team,
                    'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                  > & {
                      organization: { __typename?: 'Organization' } & Pick<
                        Organization,
                        | 'allowConnectThirdPartyCalendar'
                        | 'contactMailAddress'
                        | 'contractPreriod'
                        | 'id'
                        | 'imageUrl'
                        | 'ipRestrictionFlg'
                        | 'name'
                        | 'requiredTwoFactorAuth'
                        | 'suspensionFlg'
                        | 'versionNo'
                      > & {
                          plan: { __typename?: 'PlanProduct' } & Pick<
                            PlanProduct,
                            'code' | 'contractPreriod' | 'id'
                          >;
                        };
                    };
                };
            };
          tasks: Array<
            Maybe<
              { __typename?: 'Task' } & Pick<
                Task,
                | 'calendarLinkageCode'
                | 'completeDateTime'
                | 'description'
                | 'estimateTimeSec'
                | 'estimatedRemainingTimeSec'
                | 'id'
                | 'laborCost'
                | 'priority'
                | 'progressDeviationRate'
                | 'progressRate'
                | 'registerDateTime'
                | 'remainingTimeSec'
                | 'scheduleWorkingTimeSec'
                | 'scheduledEndDateTime'
                | 'scheduledStartDateTime'
                | 'sortNoInGanttChart'
                | 'sortNoInList'
                | 'sortNoInMyTask'
                | 'sortNoInTaskStatus'
                | 'title'
                | 'versionNo'
                | 'workingTimeSec'
              > & {
                  assignees: Array<
                    { __typename?: 'AssignedTaskMemberInfo' } & Pick<
                      AssignedTaskMemberInfo,
                      'sortNo'
                    >
                  >;
                  asssignee?: Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >;
                  createdMember: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                  favoriteMembers: Array<
                    Maybe<
                      { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >
                    >
                  >;
                  favoriteMembersInfo: Array<
                    Maybe<
                      { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                        FavoriteTaskMemberInfo,
                        'sortNo'
                      >
                    >
                  >;
                  project: { __typename?: 'Project' } & Pick<
                    Project,
                    | 'calendarLinkageCode'
                    | 'color'
                    | 'complete'
                    | 'completeDateTime'
                    | 'costBudgetWithoutTax'
                    | 'description'
                    | 'estimateTimeSec'
                    | 'id'
                    | 'key'
                    | 'laborCost'
                    | 'laborCostWithoutTax'
                    | 'laborShare'
                    | 'name'
                    | 'priority'
                    | 'registerDateTime'
                    | 'salesAmountWithoutTax'
                    | 'scheduledEndDateTime'
                    | 'scheduledStartDateTime'
                    | 'sortNoInGanttChart'
                    | 'sortNoInList'
                    | 'sortNoInProjectStatus'
                    | 'totalScheduleTimeSec'
                    | 'versionNo'
                    | 'workingTimeSec'
                  >;
                  taskStatus: { __typename?: 'TaskStatus' } & Pick<
                    TaskStatus,
                    'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
                  >;
                  workingMembers: Array<
                    Maybe<
                      { __typename?: 'WorkingMemberInfo' } & Pick<
                        WorkingMemberInfo,
                        'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                      > & {
                          member: { __typename?: 'Member' } & Pick<
                            Member,
                            | 'adminRole'
                            | 'clientManagementRole'
                            | 'department'
                            | 'employeeNumber'
                            | 'id'
                            | 'laborCostManagementRole'
                            | 'mailAddress'
                            | 'memberManagementRole'
                            | 'memberRegistrationDateTime'
                            | 'memberStatus'
                            | 'name'
                            | 'organizationMemberViewRole'
                            | 'organizationReportRole'
                            | 'profileImageUrl'
                            | 'projectCreatePermissionFlg'
                            | 'projectCustomAttributePermissionFlg'
                            | 'projectDeletePermissionFlg'
                            | 'projectUpdatePermissionFlg'
                            | 'proxyEditWorkingDataRole'
                            | 'salesManagementRole'
                            | 'statusMessage'
                            | 'taskCreatePermissionFlg'
                            | 'taskCustomAttributePermissionFlg'
                            | 'taskDeletePermissionFlg'
                            | 'taskUpdatePermissionFlg'
                            | 'teamMemberViewRole'
                            | 'teamReportRole'
                            | 'versionNo'
                          >;
                        }
                    >
                  >;
                }
            >
          >;
        }
      >
    >
  >;
};

export type ProjectTemplateQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ProjectTemplateQuery = { __typename?: 'Query' } & {
  projectTemplate?: Maybe<
    { __typename?: 'ProjectTemplate' } & Pick<
      ProjectTemplate,
      'description' | 'estimateTimeSec' | 'id' | 'name' | 'priority' | 'sortNoInList' | 'versionNo'
    > & {
        client?: Maybe<
          { __typename?: 'Client' } & Pick<Client, 'code' | 'id' | 'name' | 'sortNo' | 'versionNo'>
        >;
      }
  >;
};

export type ProjectTemplateCustomAttributeQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ProjectTemplateCustomAttributeQuery = { __typename?: 'Query' } & {
  projectTemplateCustomAttribute?: Maybe<
    { __typename?: 'ProjectTemplateCustomAttribute' } & Pick<
      ProjectTemplateCustomAttribute,
      'id' | 'sortNo' | 'value'
    > & {
        listItem?: Maybe<
          { __typename?: 'ProjectCustomAttributeMasterListItem' } & Pick<
            ProjectCustomAttributeMasterListItem,
            'id' | 'sortNo' | 'value' | 'versionNo'
          >
        >;
        master: { __typename?: 'ProjectCustomAttributeMaster' } & Pick<
          ProjectCustomAttributeMaster,
          'id' | 'name' | 'required' | 'sortNo' | 'type' | 'versionNo'
        > & {
            listItem: Array<
              Maybe<
                { __typename?: 'ProjectCustomAttributeMasterListItem' } & Pick<
                  ProjectCustomAttributeMasterListItem,
                  'id' | 'sortNo' | 'value' | 'versionNo'
                >
              >
            >;
          };
      }
  >;
};

export type ProjectTemplateCustomAttributesByProjectQueryVariables = Exact<{
  projectTemplateId: Scalars['String'];
}>;

export type ProjectTemplateCustomAttributesByProjectQuery = { __typename?: 'Query' } & {
  projectTemplateCustomAttributesByProject?: Maybe<
    Array<
      Maybe<
        { __typename?: 'ProjectTemplateCustomAttribute' } & Pick<
          ProjectTemplateCustomAttribute,
          'id' | 'sortNo' | 'value'
        > & {
            listItem?: Maybe<
              { __typename?: 'ProjectCustomAttributeMasterListItem' } & Pick<
                ProjectCustomAttributeMasterListItem,
                'id' | 'sortNo' | 'value' | 'versionNo'
              >
            >;
            master: { __typename?: 'ProjectCustomAttributeMaster' } & Pick<
              ProjectCustomAttributeMaster,
              'id' | 'name' | 'required' | 'sortNo' | 'type' | 'versionNo'
            > & {
                listItem: Array<
                  Maybe<
                    { __typename?: 'ProjectCustomAttributeMasterListItem' } & Pick<
                      ProjectCustomAttributeMasterListItem,
                      'id' | 'sortNo' | 'value' | 'versionNo'
                    >
                  >
                >;
              };
          }
      >
    >
  >;
};

export type ProjectTemplateSubTaskQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
}>;

export type ProjectTemplateSubTaskQuery = { __typename?: 'Query' } & {
  projectTemplateSubTask?: Maybe<
    { __typename?: 'ProjectTemplateSubTask' } & Pick<
      ProjectTemplateSubTask,
      'id' | 'sortNo' | 'title' | 'versionNo'
    > & {
        createdMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
        projectTemplateTask: { __typename?: 'ProjectTemplateTask' } & Pick<
          ProjectTemplateTask,
          | 'description'
          | 'estimateTimeSec'
          | 'id'
          | 'priority'
          | 'sortNoInList'
          | 'sortNoInTaskStatus'
          | 'title'
          | 'versionNo'
        > & {
            createdMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
            projectTemplate: { __typename?: 'ProjectTemplate' } & Pick<
              ProjectTemplate,
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'name'
              | 'priority'
              | 'sortNoInList'
              | 'versionNo'
            > & {
                client?: Maybe<
                  { __typename?: 'Client' } & Pick<
                    Client,
                    'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                  >
                >;
              };
            projectTemplateTaskStatus: { __typename?: 'ProjectTemplateTaskStatus' } & Pick<
              ProjectTemplateTaskStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
            > & {
                projectTemplate: { __typename?: 'ProjectTemplate' } & Pick<
                  ProjectTemplate,
                  | 'description'
                  | 'estimateTimeSec'
                  | 'id'
                  | 'name'
                  | 'priority'
                  | 'sortNoInList'
                  | 'versionNo'
                >;
              };
          };
      }
  >;
};

export type ProjectTemplateSubTasksQueryVariables = Exact<{
  projectTemplateTaskId: Scalars['String'];
}>;

export type ProjectTemplateSubTasksQuery = { __typename?: 'Query' } & {
  projectTemplateSubTasks?: Maybe<
    Array<
      Maybe<
        { __typename?: 'ProjectTemplateSubTask' } & Pick<
          ProjectTemplateSubTask,
          'id' | 'sortNo' | 'title' | 'versionNo'
        > & {
            createdMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
            projectTemplateTask: { __typename?: 'ProjectTemplateTask' } & Pick<
              ProjectTemplateTask,
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'priority'
              | 'sortNoInList'
              | 'sortNoInTaskStatus'
              | 'title'
              | 'versionNo'
            > & {
                createdMember: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
                projectTemplate: { __typename?: 'ProjectTemplate' } & Pick<
                  ProjectTemplate,
                  | 'description'
                  | 'estimateTimeSec'
                  | 'id'
                  | 'name'
                  | 'priority'
                  | 'sortNoInList'
                  | 'versionNo'
                > & {
                    client?: Maybe<
                      { __typename?: 'Client' } & Pick<
                        Client,
                        'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                      >
                    >;
                  };
                projectTemplateTaskStatus: { __typename?: 'ProjectTemplateTaskStatus' } & Pick<
                  ProjectTemplateTaskStatus,
                  'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
                > & {
                    projectTemplate: { __typename?: 'ProjectTemplate' } & Pick<
                      ProjectTemplate,
                      | 'description'
                      | 'estimateTimeSec'
                      | 'id'
                      | 'name'
                      | 'priority'
                      | 'sortNoInList'
                      | 'versionNo'
                    >;
                  };
              };
          }
      >
    >
  >;
};

export type ProjectTemplateTaskQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
}>;

export type ProjectTemplateTaskQuery = { __typename?: 'Query' } & {
  projectTemplateTask?: Maybe<
    { __typename?: 'ProjectTemplateTask' } & Pick<
      ProjectTemplateTask,
      | 'description'
      | 'estimateTimeSec'
      | 'id'
      | 'priority'
      | 'sortNoInList'
      | 'sortNoInTaskStatus'
      | 'title'
      | 'versionNo'
    > & {
        createdMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
        projectTemplate: { __typename?: 'ProjectTemplate' } & Pick<
          ProjectTemplate,
          | 'description'
          | 'estimateTimeSec'
          | 'id'
          | 'name'
          | 'priority'
          | 'sortNoInList'
          | 'versionNo'
        > & {
            client?: Maybe<
              { __typename?: 'Client' } & Pick<
                Client,
                'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
              >
            >;
          };
        projectTemplateTaskStatus: { __typename?: 'ProjectTemplateTaskStatus' } & Pick<
          ProjectTemplateTaskStatus,
          'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
        > & {
            projectTemplate: { __typename?: 'ProjectTemplate' } & Pick<
              ProjectTemplate,
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'name'
              | 'priority'
              | 'sortNoInList'
              | 'versionNo'
            >;
          };
      }
  >;
};

export type ProjectTemplateTaskCustomAttributeQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ProjectTemplateTaskCustomAttributeQuery = { __typename?: 'Query' } & {
  projectTemplateTaskCustomAttribute?: Maybe<
    { __typename?: 'ProjectTemplateTaskCustomAttribute' } & Pick<
      ProjectTemplateTaskCustomAttribute,
      'id' | 'sortNo' | 'value'
    > & {
        listItem?: Maybe<
          { __typename?: 'TaskCustomAttributeMasterListItem' } & Pick<
            TaskCustomAttributeMasterListItem,
            'id' | 'sortNo' | 'value' | 'versionNo'
          >
        >;
        master: { __typename?: 'TaskCustomAttributeMaster' } & Pick<
          TaskCustomAttributeMaster,
          'id' | 'name' | 'required' | 'sortNo' | 'type' | 'versionNo'
        > & {
            listItem: Array<
              Maybe<
                { __typename?: 'TaskCustomAttributeMasterListItem' } & Pick<
                  TaskCustomAttributeMasterListItem,
                  'id' | 'sortNo' | 'value' | 'versionNo'
                >
              >
            >;
          };
      }
  >;
};

export type ProjectTemplateTaskCustomAttributesByTaskQueryVariables = Exact<{
  projectTemplateTaskId: Scalars['String'];
}>;

export type ProjectTemplateTaskCustomAttributesByTaskQuery = { __typename?: 'Query' } & {
  projectTemplateTaskCustomAttributesByTask?: Maybe<
    Array<
      Maybe<
        { __typename?: 'ProjectTemplateTaskCustomAttribute' } & Pick<
          ProjectTemplateTaskCustomAttribute,
          'id' | 'sortNo' | 'value'
        > & {
            listItem?: Maybe<
              { __typename?: 'TaskCustomAttributeMasterListItem' } & Pick<
                TaskCustomAttributeMasterListItem,
                'id' | 'sortNo' | 'value' | 'versionNo'
              >
            >;
            master: { __typename?: 'TaskCustomAttributeMaster' } & Pick<
              TaskCustomAttributeMaster,
              'id' | 'name' | 'required' | 'sortNo' | 'type' | 'versionNo'
            > & {
                listItem: Array<
                  Maybe<
                    { __typename?: 'TaskCustomAttributeMasterListItem' } & Pick<
                      TaskCustomAttributeMasterListItem,
                      'id' | 'sortNo' | 'value' | 'versionNo'
                    >
                  >
                >;
              };
          }
      >
    >
  >;
};

export type ProjectTemplateTaskRemainderQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
}>;

export type ProjectTemplateTaskRemainderQuery = { __typename?: 'Query' } & {
  projectTemplateTaskRemainder?: Maybe<
    { __typename?: 'ProjectTemplateTaskRemainder' } & Pick<
      ProjectTemplateTaskRemainder,
      'beforeDeadlineMinutes' | 'id' | 'taskRemainderDestType' | 'taskRemainderType'
    > & {
        projectTemplateTask: { __typename?: 'ProjectTemplateTask' } & Pick<
          ProjectTemplateTask,
          | 'description'
          | 'estimateTimeSec'
          | 'id'
          | 'priority'
          | 'sortNoInList'
          | 'sortNoInTaskStatus'
          | 'title'
          | 'versionNo'
        > & {
            createdMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
            projectTemplate: { __typename?: 'ProjectTemplate' } & Pick<
              ProjectTemplate,
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'name'
              | 'priority'
              | 'sortNoInList'
              | 'versionNo'
            > & {
                client?: Maybe<
                  { __typename?: 'Client' } & Pick<
                    Client,
                    'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                  >
                >;
              };
            projectTemplateTaskStatus: { __typename?: 'ProjectTemplateTaskStatus' } & Pick<
              ProjectTemplateTaskStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
            > & {
                projectTemplate: { __typename?: 'ProjectTemplate' } & Pick<
                  ProjectTemplate,
                  | 'description'
                  | 'estimateTimeSec'
                  | 'id'
                  | 'name'
                  | 'priority'
                  | 'sortNoInList'
                  | 'versionNo'
                >;
              };
          };
        targetMember?: Maybe<
          { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >
        >;
      }
  >;
};

export type ProjectTemplateTaskRemaindersQueryVariables = Exact<{
  projectTemplateTaskId: Scalars['String'];
}>;

export type ProjectTemplateTaskRemaindersQuery = { __typename?: 'Query' } & {
  projectTemplateTaskRemainders?: Maybe<
    Array<
      Maybe<
        { __typename?: 'ProjectTemplateTaskRemainder' } & Pick<
          ProjectTemplateTaskRemainder,
          'beforeDeadlineMinutes' | 'id' | 'taskRemainderDestType' | 'taskRemainderType'
        > & {
            projectTemplateTask: { __typename?: 'ProjectTemplateTask' } & Pick<
              ProjectTemplateTask,
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'priority'
              | 'sortNoInList'
              | 'sortNoInTaskStatus'
              | 'title'
              | 'versionNo'
            > & {
                createdMember: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
                projectTemplate: { __typename?: 'ProjectTemplate' } & Pick<
                  ProjectTemplate,
                  | 'description'
                  | 'estimateTimeSec'
                  | 'id'
                  | 'name'
                  | 'priority'
                  | 'sortNoInList'
                  | 'versionNo'
                > & {
                    client?: Maybe<
                      { __typename?: 'Client' } & Pick<
                        Client,
                        'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                      >
                    >;
                  };
                projectTemplateTaskStatus: { __typename?: 'ProjectTemplateTaskStatus' } & Pick<
                  ProjectTemplateTaskStatus,
                  'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
                > & {
                    projectTemplate: { __typename?: 'ProjectTemplate' } & Pick<
                      ProjectTemplate,
                      | 'description'
                      | 'estimateTimeSec'
                      | 'id'
                      | 'name'
                      | 'priority'
                      | 'sortNoInList'
                      | 'versionNo'
                    >;
                  };
              };
            targetMember?: Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >;
          }
      >
    >
  >;
};

export type ProjectTemplateTaskStatusQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type ProjectTemplateTaskStatusQuery = { __typename?: 'Query' } & {
  projectTemplateTaskStatus?: Maybe<
    { __typename?: 'ProjectTemplateTaskStatus' } & Pick<
      ProjectTemplateTaskStatus,
      'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
    > & {
        projectTemplate: { __typename?: 'ProjectTemplate' } & Pick<
          ProjectTemplate,
          | 'description'
          | 'estimateTimeSec'
          | 'id'
          | 'name'
          | 'priority'
          | 'sortNoInList'
          | 'versionNo'
        > & {
            client?: Maybe<
              { __typename?: 'Client' } & Pick<
                Client,
                'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
              >
            >;
          };
      }
  >;
};

export type ProjectTemplateTaskStatusesQueryVariables = Exact<{
  projectTemplateId: Scalars['String'];
}>;

export type ProjectTemplateTaskStatusesQuery = { __typename?: 'Query' } & {
  projectTemplateTaskStatuses?: Maybe<
    Array<
      Maybe<
        { __typename?: 'ProjectTemplateTaskStatus' } & Pick<
          ProjectTemplateTaskStatus,
          'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
        > & {
            projectTemplate: { __typename?: 'ProjectTemplate' } & Pick<
              ProjectTemplate,
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'name'
              | 'priority'
              | 'sortNoInList'
              | 'versionNo'
            > & {
                client?: Maybe<
                  { __typename?: 'Client' } & Pick<
                    Client,
                    'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                  >
                >;
              };
          }
      >
    >
  >;
};

export type ProjectTemplateTaskTagsQueryVariables = Exact<{
  projectTemplateTaskId: Scalars['String'];
}>;

export type ProjectTemplateTaskTagsQuery = { __typename?: 'Query' } & {
  projectTemplateTaskTags?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Tag' } & Pick<
          Tag,
          'id' | 'name' | 'note' | 'relatedTaskCount' | 'sortNo' | 'versionNo'
        > & {
            organization: { __typename?: 'Organization' } & Pick<
              Organization,
              | 'allowConnectThirdPartyCalendar'
              | 'contactMailAddress'
              | 'contractPreriod'
              | 'id'
              | 'imageUrl'
              | 'ipRestrictionFlg'
              | 'name'
              | 'requiredTwoFactorAuth'
              | 'suspensionFlg'
              | 'versionNo'
            > & {
                plan: { __typename?: 'PlanProduct' } & Pick<
                  PlanProduct,
                  'code' | 'contractPreriod' | 'id'
                >;
              };
          }
      >
    >
  >;
};

export type ProjectTemplateTasksQueryVariables = Exact<{
  projectTemplateId: Scalars['String'];
}>;

export type ProjectTemplateTasksQuery = { __typename?: 'Query' } & {
  projectTemplateTasks?: Maybe<
    Array<
      Maybe<
        { __typename?: 'ProjectTemplateTask' } & Pick<
          ProjectTemplateTask,
          | 'description'
          | 'estimateTimeSec'
          | 'id'
          | 'priority'
          | 'sortNoInList'
          | 'sortNoInTaskStatus'
          | 'title'
          | 'versionNo'
        > & {
            createdMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
            projectTemplate: { __typename?: 'ProjectTemplate' } & Pick<
              ProjectTemplate,
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'name'
              | 'priority'
              | 'sortNoInList'
              | 'versionNo'
            > & {
                client?: Maybe<
                  { __typename?: 'Client' } & Pick<
                    Client,
                    'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                  >
                >;
              };
            projectTemplateTaskStatus: { __typename?: 'ProjectTemplateTaskStatus' } & Pick<
              ProjectTemplateTaskStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
            > & {
                projectTemplate: { __typename?: 'ProjectTemplate' } & Pick<
                  ProjectTemplate,
                  | 'description'
                  | 'estimateTimeSec'
                  | 'id'
                  | 'name'
                  | 'priority'
                  | 'sortNoInList'
                  | 'versionNo'
                >;
              };
          }
      >
    >
  >;
};

export type ProjectTemplateTasksGroupByStatusQueryVariables = Exact<{
  projectTemplateId?: Maybe<Scalars['String']>;
}>;

export type ProjectTemplateTasksGroupByStatusQuery = { __typename?: 'Query' } & {
  projectTemplateTasksGroupByStatus?: Maybe<
    Array<
      Maybe<
        { __typename?: 'ProjectTemplateTaskStatusWithTask' } & {
          projectTemplateTaskStatus: { __typename?: 'ProjectTemplateTaskStatus' } & Pick<
            ProjectTemplateTaskStatus,
            'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
          > & {
              projectTemplate: { __typename?: 'ProjectTemplate' } & Pick<
                ProjectTemplate,
                | 'description'
                | 'estimateTimeSec'
                | 'id'
                | 'name'
                | 'priority'
                | 'sortNoInList'
                | 'versionNo'
              > & {
                  client?: Maybe<
                    { __typename?: 'Client' } & Pick<
                      Client,
                      'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                    >
                  >;
                };
            };
          projectTemplateTasks: Array<
            Maybe<
              { __typename?: 'ProjectTemplateTask' } & Pick<
                ProjectTemplateTask,
                | 'description'
                | 'estimateTimeSec'
                | 'id'
                | 'priority'
                | 'sortNoInList'
                | 'sortNoInTaskStatus'
                | 'title'
                | 'versionNo'
              > & {
                  createdMember: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                  projectTemplate: { __typename?: 'ProjectTemplate' } & Pick<
                    ProjectTemplate,
                    | 'description'
                    | 'estimateTimeSec'
                    | 'id'
                    | 'name'
                    | 'priority'
                    | 'sortNoInList'
                    | 'versionNo'
                  >;
                  projectTemplateTaskStatus: { __typename?: 'ProjectTemplateTaskStatus' } & Pick<
                    ProjectTemplateTaskStatus,
                    'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
                  >;
                }
            >
          >;
        }
      >
    >
  >;
};

export type ProjectTemplatesQueryVariables = Exact<{
  input?: Maybe<FetchProjectTemplatesInputInput>;
}>;

export type ProjectTemplatesQuery = { __typename?: 'Query' } & {
  projectTemplates?: Maybe<
    Array<
      Maybe<
        { __typename?: 'ProjectTemplate' } & Pick<
          ProjectTemplate,
          | 'description'
          | 'estimateTimeSec'
          | 'id'
          | 'name'
          | 'priority'
          | 'sortNoInList'
          | 'versionNo'
        > & {
            client?: Maybe<
              { __typename?: 'Client' } & Pick<
                Client,
                'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
              >
            >;
          }
      >
    >
  >;
};

export type ProjectWorkingTimeSecQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ProjectWorkingTimeSecQuery = { __typename?: 'Query' } & Pick<
  Query,
  'projectWorkingTimeSec'
>;

export type SearchFavoriteTasksQueryVariables = Exact<{
  input: SearchFavoriteTaskInputInput;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;

export type SearchFavoriteTasksQuery = { __typename?: 'Query' } & {
  searchFavoriteTasks?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Task' } & Pick<
          Task,
          | 'calendarLinkageCode'
          | 'completeDateTime'
          | 'description'
          | 'estimateTimeSec'
          | 'estimatedRemainingTimeSec'
          | 'id'
          | 'laborCost'
          | 'priority'
          | 'progressDeviationRate'
          | 'progressRate'
          | 'registerDateTime'
          | 'remainingTimeSec'
          | 'scheduleWorkingTimeSec'
          | 'scheduledEndDateTime'
          | 'scheduledStartDateTime'
          | 'sortNoInGanttChart'
          | 'sortNoInList'
          | 'sortNoInMyTask'
          | 'sortNoInTaskStatus'
          | 'title'
          | 'versionNo'
          | 'workingTimeSec'
        > & {
            assignees: Array<
              { __typename?: 'AssignedTaskMemberInfo' } & Pick<AssignedTaskMemberInfo, 'sortNo'> & {
                  member: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                }
            >;
            asssignee?: Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >;
            createdMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
            favoriteMembers: Array<
              Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >
            >;
            favoriteMembersInfo: Array<
              Maybe<
                { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                  FavoriteTaskMemberInfo,
                  'sortNo'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >
            >;
            project: { __typename?: 'Project' } & Pick<
              Project,
              | 'calendarLinkageCode'
              | 'color'
              | 'complete'
              | 'completeDateTime'
              | 'costBudgetWithoutTax'
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'key'
              | 'laborCost'
              | 'laborCostWithoutTax'
              | 'laborShare'
              | 'name'
              | 'priority'
              | 'registerDateTime'
              | 'salesAmountWithoutTax'
              | 'scheduledEndDateTime'
              | 'scheduledStartDateTime'
              | 'sortNoInGanttChart'
              | 'sortNoInList'
              | 'sortNoInProjectStatus'
              | 'totalScheduleTimeSec'
              | 'versionNo'
              | 'workingTimeSec'
            > & {
                assignees: Array<
                  { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                    AssignedProjectMemberInfo,
                    'sortNo'
                  >
                >;
                client?: Maybe<
                  { __typename?: 'Client' } & Pick<
                    Client,
                    'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                  >
                >;
                createdMember?: Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >;
                favoriteMembers: Array<
                  Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >
                >;
                favoriteMembersInfo: Array<
                  Maybe<
                    { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                      FavoriteProjectMemberInfo,
                      'sortNo'
                    >
                  >
                >;
                projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                  ProjectStatus,
                  'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                >;
                team: { __typename?: 'Team' } & Pick<
                  Team,
                  'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                > & {
                    organization: { __typename?: 'Organization' } & Pick<
                      Organization,
                      | 'allowConnectThirdPartyCalendar'
                      | 'contactMailAddress'
                      | 'contractPreriod'
                      | 'id'
                      | 'imageUrl'
                      | 'ipRestrictionFlg'
                      | 'name'
                      | 'requiredTwoFactorAuth'
                      | 'suspensionFlg'
                      | 'versionNo'
                    > & {
                        plan: { __typename?: 'PlanProduct' } & Pick<
                          PlanProduct,
                          'code' | 'contractPreriod' | 'id'
                        >;
                      };
                  };
              };
            taskStatus: { __typename?: 'TaskStatus' } & Pick<
              TaskStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
            > & {
                project: { __typename?: 'Project' } & Pick<
                  Project,
                  | 'calendarLinkageCode'
                  | 'color'
                  | 'complete'
                  | 'completeDateTime'
                  | 'costBudgetWithoutTax'
                  | 'description'
                  | 'estimateTimeSec'
                  | 'id'
                  | 'key'
                  | 'laborCost'
                  | 'laborCostWithoutTax'
                  | 'laborShare'
                  | 'name'
                  | 'priority'
                  | 'registerDateTime'
                  | 'salesAmountWithoutTax'
                  | 'scheduledEndDateTime'
                  | 'scheduledStartDateTime'
                  | 'sortNoInGanttChart'
                  | 'sortNoInList'
                  | 'sortNoInProjectStatus'
                  | 'totalScheduleTimeSec'
                  | 'versionNo'
                  | 'workingTimeSec'
                >;
              };
            workingMembers: Array<
              Maybe<
                { __typename?: 'WorkingMemberInfo' } & Pick<
                  WorkingMemberInfo,
                  'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >
            >;
          }
      >
    >
  >;
};

export type SearchTasksQueryVariables = Exact<{
  input: SearchTaskInputInput;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;

export type SearchTasksQuery = { __typename?: 'Query' } & {
  searchTasks?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Task' } & Pick<
          Task,
          | 'calendarLinkageCode'
          | 'completeDateTime'
          | 'description'
          | 'estimateTimeSec'
          | 'estimatedRemainingTimeSec'
          | 'id'
          | 'laborCost'
          | 'priority'
          | 'progressDeviationRate'
          | 'progressRate'
          | 'registerDateTime'
          | 'remainingTimeSec'
          | 'scheduleWorkingTimeSec'
          | 'scheduledEndDateTime'
          | 'scheduledStartDateTime'
          | 'sortNoInGanttChart'
          | 'sortNoInList'
          | 'sortNoInMyTask'
          | 'sortNoInTaskStatus'
          | 'title'
          | 'versionNo'
          | 'workingTimeSec'
        > & {
            assignees: Array<
              { __typename?: 'AssignedTaskMemberInfo' } & Pick<AssignedTaskMemberInfo, 'sortNo'> & {
                  member: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                }
            >;
            asssignee?: Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >;
            createdMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
            favoriteMembers: Array<
              Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >
            >;
            favoriteMembersInfo: Array<
              Maybe<
                { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                  FavoriteTaskMemberInfo,
                  'sortNo'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >
            >;
            project: { __typename?: 'Project' } & Pick<
              Project,
              | 'calendarLinkageCode'
              | 'color'
              | 'complete'
              | 'completeDateTime'
              | 'costBudgetWithoutTax'
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'key'
              | 'laborCost'
              | 'laborCostWithoutTax'
              | 'laborShare'
              | 'name'
              | 'priority'
              | 'registerDateTime'
              | 'salesAmountWithoutTax'
              | 'scheduledEndDateTime'
              | 'scheduledStartDateTime'
              | 'sortNoInGanttChart'
              | 'sortNoInList'
              | 'sortNoInProjectStatus'
              | 'totalScheduleTimeSec'
              | 'versionNo'
              | 'workingTimeSec'
            > & {
                assignees: Array<
                  { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                    AssignedProjectMemberInfo,
                    'sortNo'
                  >
                >;
                client?: Maybe<
                  { __typename?: 'Client' } & Pick<
                    Client,
                    'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                  >
                >;
                createdMember?: Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >;
                favoriteMembers: Array<
                  Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >
                >;
                favoriteMembersInfo: Array<
                  Maybe<
                    { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                      FavoriteProjectMemberInfo,
                      'sortNo'
                    >
                  >
                >;
                projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                  ProjectStatus,
                  'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                >;
                team: { __typename?: 'Team' } & Pick<
                  Team,
                  'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                > & {
                    organization: { __typename?: 'Organization' } & Pick<
                      Organization,
                      | 'allowConnectThirdPartyCalendar'
                      | 'contactMailAddress'
                      | 'contractPreriod'
                      | 'id'
                      | 'imageUrl'
                      | 'ipRestrictionFlg'
                      | 'name'
                      | 'requiredTwoFactorAuth'
                      | 'suspensionFlg'
                      | 'versionNo'
                    > & {
                        plan: { __typename?: 'PlanProduct' } & Pick<
                          PlanProduct,
                          'code' | 'contractPreriod' | 'id'
                        >;
                      };
                  };
              };
            taskStatus: { __typename?: 'TaskStatus' } & Pick<
              TaskStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
            > & {
                project: { __typename?: 'Project' } & Pick<
                  Project,
                  | 'calendarLinkageCode'
                  | 'color'
                  | 'complete'
                  | 'completeDateTime'
                  | 'costBudgetWithoutTax'
                  | 'description'
                  | 'estimateTimeSec'
                  | 'id'
                  | 'key'
                  | 'laborCost'
                  | 'laborCostWithoutTax'
                  | 'laborShare'
                  | 'name'
                  | 'priority'
                  | 'registerDateTime'
                  | 'salesAmountWithoutTax'
                  | 'scheduledEndDateTime'
                  | 'scheduledStartDateTime'
                  | 'sortNoInGanttChart'
                  | 'sortNoInList'
                  | 'sortNoInProjectStatus'
                  | 'totalScheduleTimeSec'
                  | 'versionNo'
                  | 'workingTimeSec'
                >;
              };
            workingMembers: Array<
              Maybe<
                { __typename?: 'WorkingMemberInfo' } & Pick<
                  WorkingMemberInfo,
                  'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >
            >;
          }
      >
    >
  >;
};

export type SrcIpAddressQueryVariables = Exact<{ [key: string]: never }>;

export type SrcIpAddressQuery = { __typename?: 'Query' } & Pick<Query, 'srcIpAddress'>;

export type SubTaskQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
}>;

export type SubTaskQuery = { __typename?: 'Query' } & {
  subTask?: Maybe<
    { __typename?: 'SubTask' } & Pick<
      SubTask,
      'complete' | 'completeDateTime' | 'id' | 'sortNo' | 'title' | 'versionNo'
    > & {
        createdMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
        task: { __typename?: 'Task' } & Pick<
          Task,
          | 'calendarLinkageCode'
          | 'completeDateTime'
          | 'description'
          | 'estimateTimeSec'
          | 'estimatedRemainingTimeSec'
          | 'id'
          | 'laborCost'
          | 'priority'
          | 'progressDeviationRate'
          | 'progressRate'
          | 'registerDateTime'
          | 'remainingTimeSec'
          | 'scheduleWorkingTimeSec'
          | 'scheduledEndDateTime'
          | 'scheduledStartDateTime'
          | 'sortNoInGanttChart'
          | 'sortNoInList'
          | 'sortNoInMyTask'
          | 'sortNoInTaskStatus'
          | 'title'
          | 'versionNo'
          | 'workingTimeSec'
        > & {
            assignees: Array<
              { __typename?: 'AssignedTaskMemberInfo' } & Pick<AssignedTaskMemberInfo, 'sortNo'> & {
                  member: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                }
            >;
            asssignee?: Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >;
            createdMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
            favoriteMembers: Array<
              Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >
            >;
            favoriteMembersInfo: Array<
              Maybe<
                { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                  FavoriteTaskMemberInfo,
                  'sortNo'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >
            >;
            project: { __typename?: 'Project' } & Pick<
              Project,
              | 'calendarLinkageCode'
              | 'color'
              | 'complete'
              | 'completeDateTime'
              | 'costBudgetWithoutTax'
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'key'
              | 'laborCost'
              | 'laborCostWithoutTax'
              | 'laborShare'
              | 'name'
              | 'priority'
              | 'registerDateTime'
              | 'salesAmountWithoutTax'
              | 'scheduledEndDateTime'
              | 'scheduledStartDateTime'
              | 'sortNoInGanttChart'
              | 'sortNoInList'
              | 'sortNoInProjectStatus'
              | 'totalScheduleTimeSec'
              | 'versionNo'
              | 'workingTimeSec'
            > & {
                assignees: Array<
                  { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                    AssignedProjectMemberInfo,
                    'sortNo'
                  >
                >;
                client?: Maybe<
                  { __typename?: 'Client' } & Pick<
                    Client,
                    'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                  >
                >;
                createdMember?: Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >;
                favoriteMembers: Array<
                  Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >
                >;
                favoriteMembersInfo: Array<
                  Maybe<
                    { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                      FavoriteProjectMemberInfo,
                      'sortNo'
                    >
                  >
                >;
                projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                  ProjectStatus,
                  'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                >;
                team: { __typename?: 'Team' } & Pick<
                  Team,
                  'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                > & {
                    organization: { __typename?: 'Organization' } & Pick<
                      Organization,
                      | 'allowConnectThirdPartyCalendar'
                      | 'contactMailAddress'
                      | 'contractPreriod'
                      | 'id'
                      | 'imageUrl'
                      | 'ipRestrictionFlg'
                      | 'name'
                      | 'requiredTwoFactorAuth'
                      | 'suspensionFlg'
                      | 'versionNo'
                    > & {
                        plan: { __typename?: 'PlanProduct' } & Pick<
                          PlanProduct,
                          'code' | 'contractPreriod' | 'id'
                        >;
                      };
                  };
              };
            taskStatus: { __typename?: 'TaskStatus' } & Pick<
              TaskStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
            > & {
                project: { __typename?: 'Project' } & Pick<
                  Project,
                  | 'calendarLinkageCode'
                  | 'color'
                  | 'complete'
                  | 'completeDateTime'
                  | 'costBudgetWithoutTax'
                  | 'description'
                  | 'estimateTimeSec'
                  | 'id'
                  | 'key'
                  | 'laborCost'
                  | 'laborCostWithoutTax'
                  | 'laborShare'
                  | 'name'
                  | 'priority'
                  | 'registerDateTime'
                  | 'salesAmountWithoutTax'
                  | 'scheduledEndDateTime'
                  | 'scheduledStartDateTime'
                  | 'sortNoInGanttChart'
                  | 'sortNoInList'
                  | 'sortNoInProjectStatus'
                  | 'totalScheduleTimeSec'
                  | 'versionNo'
                  | 'workingTimeSec'
                >;
              };
            workingMembers: Array<
              Maybe<
                { __typename?: 'WorkingMemberInfo' } & Pick<
                  WorkingMemberInfo,
                  'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >
            >;
          };
      }
  >;
};

export type SubTasksQueryVariables = Exact<{
  taskId: Scalars['String'];
}>;

export type SubTasksQuery = { __typename?: 'Query' } & {
  subTasks?: Maybe<
    Array<
      Maybe<
        { __typename?: 'SubTask' } & Pick<
          SubTask,
          'complete' | 'completeDateTime' | 'id' | 'sortNo' | 'title' | 'versionNo'
        > & {
            createdMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
            task: { __typename?: 'Task' } & Pick<
              Task,
              | 'calendarLinkageCode'
              | 'completeDateTime'
              | 'description'
              | 'estimateTimeSec'
              | 'estimatedRemainingTimeSec'
              | 'id'
              | 'laborCost'
              | 'priority'
              | 'progressDeviationRate'
              | 'progressRate'
              | 'registerDateTime'
              | 'remainingTimeSec'
              | 'scheduleWorkingTimeSec'
              | 'scheduledEndDateTime'
              | 'scheduledStartDateTime'
              | 'sortNoInGanttChart'
              | 'sortNoInList'
              | 'sortNoInMyTask'
              | 'sortNoInTaskStatus'
              | 'title'
              | 'versionNo'
              | 'workingTimeSec'
            > & {
                assignees: Array<
                  { __typename?: 'AssignedTaskMemberInfo' } & Pick<
                    AssignedTaskMemberInfo,
                    'sortNo'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >;
                asssignee?: Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >;
                createdMember: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
                favoriteMembers: Array<
                  Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >
                >;
                favoriteMembersInfo: Array<
                  Maybe<
                    { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                      FavoriteTaskMemberInfo,
                      'sortNo'
                    > & {
                        member: { __typename?: 'Member' } & Pick<
                          Member,
                          | 'adminRole'
                          | 'clientManagementRole'
                          | 'department'
                          | 'employeeNumber'
                          | 'id'
                          | 'laborCostManagementRole'
                          | 'mailAddress'
                          | 'memberManagementRole'
                          | 'memberRegistrationDateTime'
                          | 'memberStatus'
                          | 'name'
                          | 'organizationMemberViewRole'
                          | 'organizationReportRole'
                          | 'profileImageUrl'
                          | 'projectCreatePermissionFlg'
                          | 'projectCustomAttributePermissionFlg'
                          | 'projectDeletePermissionFlg'
                          | 'projectUpdatePermissionFlg'
                          | 'proxyEditWorkingDataRole'
                          | 'salesManagementRole'
                          | 'statusMessage'
                          | 'taskCreatePermissionFlg'
                          | 'taskCustomAttributePermissionFlg'
                          | 'taskDeletePermissionFlg'
                          | 'taskUpdatePermissionFlg'
                          | 'teamMemberViewRole'
                          | 'teamReportRole'
                          | 'versionNo'
                        >;
                      }
                  >
                >;
                project: { __typename?: 'Project' } & Pick<
                  Project,
                  | 'calendarLinkageCode'
                  | 'color'
                  | 'complete'
                  | 'completeDateTime'
                  | 'costBudgetWithoutTax'
                  | 'description'
                  | 'estimateTimeSec'
                  | 'id'
                  | 'key'
                  | 'laborCost'
                  | 'laborCostWithoutTax'
                  | 'laborShare'
                  | 'name'
                  | 'priority'
                  | 'registerDateTime'
                  | 'salesAmountWithoutTax'
                  | 'scheduledEndDateTime'
                  | 'scheduledStartDateTime'
                  | 'sortNoInGanttChart'
                  | 'sortNoInList'
                  | 'sortNoInProjectStatus'
                  | 'totalScheduleTimeSec'
                  | 'versionNo'
                  | 'workingTimeSec'
                > & {
                    assignees: Array<
                      { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                        AssignedProjectMemberInfo,
                        'sortNo'
                      >
                    >;
                    client?: Maybe<
                      { __typename?: 'Client' } & Pick<
                        Client,
                        'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                      >
                    >;
                    createdMember?: Maybe<
                      { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >
                    >;
                    favoriteMembers: Array<
                      Maybe<
                        { __typename?: 'Member' } & Pick<
                          Member,
                          | 'adminRole'
                          | 'clientManagementRole'
                          | 'department'
                          | 'employeeNumber'
                          | 'id'
                          | 'laborCostManagementRole'
                          | 'mailAddress'
                          | 'memberManagementRole'
                          | 'memberRegistrationDateTime'
                          | 'memberStatus'
                          | 'name'
                          | 'organizationMemberViewRole'
                          | 'organizationReportRole'
                          | 'profileImageUrl'
                          | 'projectCreatePermissionFlg'
                          | 'projectCustomAttributePermissionFlg'
                          | 'projectDeletePermissionFlg'
                          | 'projectUpdatePermissionFlg'
                          | 'proxyEditWorkingDataRole'
                          | 'salesManagementRole'
                          | 'statusMessage'
                          | 'taskCreatePermissionFlg'
                          | 'taskCustomAttributePermissionFlg'
                          | 'taskDeletePermissionFlg'
                          | 'taskUpdatePermissionFlg'
                          | 'teamMemberViewRole'
                          | 'teamReportRole'
                          | 'versionNo'
                        >
                      >
                    >;
                    favoriteMembersInfo: Array<
                      Maybe<
                        { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                          FavoriteProjectMemberInfo,
                          'sortNo'
                        >
                      >
                    >;
                    projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                      ProjectStatus,
                      'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                    >;
                    team: { __typename?: 'Team' } & Pick<
                      Team,
                      'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                    > & {
                        organization: { __typename?: 'Organization' } & Pick<
                          Organization,
                          | 'allowConnectThirdPartyCalendar'
                          | 'contactMailAddress'
                          | 'contractPreriod'
                          | 'id'
                          | 'imageUrl'
                          | 'ipRestrictionFlg'
                          | 'name'
                          | 'requiredTwoFactorAuth'
                          | 'suspensionFlg'
                          | 'versionNo'
                        > & {
                            plan: { __typename?: 'PlanProduct' } & Pick<
                              PlanProduct,
                              'code' | 'contractPreriod' | 'id'
                            >;
                          };
                      };
                  };
                taskStatus: { __typename?: 'TaskStatus' } & Pick<
                  TaskStatus,
                  'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
                > & {
                    project: { __typename?: 'Project' } & Pick<
                      Project,
                      | 'calendarLinkageCode'
                      | 'color'
                      | 'complete'
                      | 'completeDateTime'
                      | 'costBudgetWithoutTax'
                      | 'description'
                      | 'estimateTimeSec'
                      | 'id'
                      | 'key'
                      | 'laborCost'
                      | 'laborCostWithoutTax'
                      | 'laborShare'
                      | 'name'
                      | 'priority'
                      | 'registerDateTime'
                      | 'salesAmountWithoutTax'
                      | 'scheduledEndDateTime'
                      | 'scheduledStartDateTime'
                      | 'sortNoInGanttChart'
                      | 'sortNoInList'
                      | 'sortNoInProjectStatus'
                      | 'totalScheduleTimeSec'
                      | 'versionNo'
                      | 'workingTimeSec'
                    >;
                  };
                workingMembers: Array<
                  Maybe<
                    { __typename?: 'WorkingMemberInfo' } & Pick<
                      WorkingMemberInfo,
                      'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                    > & {
                        member: { __typename?: 'Member' } & Pick<
                          Member,
                          | 'adminRole'
                          | 'clientManagementRole'
                          | 'department'
                          | 'employeeNumber'
                          | 'id'
                          | 'laborCostManagementRole'
                          | 'mailAddress'
                          | 'memberManagementRole'
                          | 'memberRegistrationDateTime'
                          | 'memberStatus'
                          | 'name'
                          | 'organizationMemberViewRole'
                          | 'organizationReportRole'
                          | 'profileImageUrl'
                          | 'projectCreatePermissionFlg'
                          | 'projectCustomAttributePermissionFlg'
                          | 'projectDeletePermissionFlg'
                          | 'projectUpdatePermissionFlg'
                          | 'proxyEditWorkingDataRole'
                          | 'salesManagementRole'
                          | 'statusMessage'
                          | 'taskCreatePermissionFlg'
                          | 'taskCustomAttributePermissionFlg'
                          | 'taskDeletePermissionFlg'
                          | 'taskUpdatePermissionFlg'
                          | 'teamMemberViewRole'
                          | 'teamReportRole'
                          | 'versionNo'
                        >;
                      }
                  >
                >;
              };
          }
      >
    >
  >;
};

export type TagQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type TagQuery = { __typename?: 'Query' } & {
  tag?: Maybe<
    { __typename?: 'Tag' } & Pick<
      Tag,
      'id' | 'name' | 'note' | 'relatedTaskCount' | 'sortNo' | 'versionNo'
    > & {
        organization: { __typename?: 'Organization' } & Pick<
          Organization,
          | 'allowConnectThirdPartyCalendar'
          | 'contactMailAddress'
          | 'contractPreriod'
          | 'id'
          | 'imageUrl'
          | 'ipRestrictionFlg'
          | 'name'
          | 'requiredTwoFactorAuth'
          | 'suspensionFlg'
          | 'versionNo'
        > & {
            plan: { __typename?: 'PlanProduct' } & Pick<
              PlanProduct,
              'code' | 'contractPreriod' | 'id'
            >;
          };
      }
  >;
};

export type TagTasksQueryVariables = Exact<{
  tagId: Scalars['String'];
}>;

export type TagTasksQuery = { __typename?: 'Query' } & {
  tagTasks?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Task' } & Pick<
          Task,
          | 'calendarLinkageCode'
          | 'completeDateTime'
          | 'description'
          | 'estimateTimeSec'
          | 'estimatedRemainingTimeSec'
          | 'id'
          | 'laborCost'
          | 'priority'
          | 'progressDeviationRate'
          | 'progressRate'
          | 'registerDateTime'
          | 'remainingTimeSec'
          | 'scheduleWorkingTimeSec'
          | 'scheduledEndDateTime'
          | 'scheduledStartDateTime'
          | 'sortNoInGanttChart'
          | 'sortNoInList'
          | 'sortNoInMyTask'
          | 'sortNoInTaskStatus'
          | 'title'
          | 'versionNo'
          | 'workingTimeSec'
        > & {
            assignees: Array<
              { __typename?: 'AssignedTaskMemberInfo' } & Pick<AssignedTaskMemberInfo, 'sortNo'> & {
                  member: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                }
            >;
            asssignee?: Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >;
            createdMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
            favoriteMembers: Array<
              Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >
            >;
            favoriteMembersInfo: Array<
              Maybe<
                { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                  FavoriteTaskMemberInfo,
                  'sortNo'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >
            >;
            project: { __typename?: 'Project' } & Pick<
              Project,
              | 'calendarLinkageCode'
              | 'color'
              | 'complete'
              | 'completeDateTime'
              | 'costBudgetWithoutTax'
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'key'
              | 'laborCost'
              | 'laborCostWithoutTax'
              | 'laborShare'
              | 'name'
              | 'priority'
              | 'registerDateTime'
              | 'salesAmountWithoutTax'
              | 'scheduledEndDateTime'
              | 'scheduledStartDateTime'
              | 'sortNoInGanttChart'
              | 'sortNoInList'
              | 'sortNoInProjectStatus'
              | 'totalScheduleTimeSec'
              | 'versionNo'
              | 'workingTimeSec'
            > & {
                assignees: Array<
                  { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                    AssignedProjectMemberInfo,
                    'sortNo'
                  >
                >;
                client?: Maybe<
                  { __typename?: 'Client' } & Pick<
                    Client,
                    'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                  >
                >;
                createdMember?: Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >;
                favoriteMembers: Array<
                  Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >
                >;
                favoriteMembersInfo: Array<
                  Maybe<
                    { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                      FavoriteProjectMemberInfo,
                      'sortNo'
                    >
                  >
                >;
                projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                  ProjectStatus,
                  'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                >;
                team: { __typename?: 'Team' } & Pick<
                  Team,
                  'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                > & {
                    organization: { __typename?: 'Organization' } & Pick<
                      Organization,
                      | 'allowConnectThirdPartyCalendar'
                      | 'contactMailAddress'
                      | 'contractPreriod'
                      | 'id'
                      | 'imageUrl'
                      | 'ipRestrictionFlg'
                      | 'name'
                      | 'requiredTwoFactorAuth'
                      | 'suspensionFlg'
                      | 'versionNo'
                    > & {
                        plan: { __typename?: 'PlanProduct' } & Pick<
                          PlanProduct,
                          'code' | 'contractPreriod' | 'id'
                        >;
                      };
                  };
              };
            taskStatus: { __typename?: 'TaskStatus' } & Pick<
              TaskStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
            > & {
                project: { __typename?: 'Project' } & Pick<
                  Project,
                  | 'calendarLinkageCode'
                  | 'color'
                  | 'complete'
                  | 'completeDateTime'
                  | 'costBudgetWithoutTax'
                  | 'description'
                  | 'estimateTimeSec'
                  | 'id'
                  | 'key'
                  | 'laborCost'
                  | 'laborCostWithoutTax'
                  | 'laborShare'
                  | 'name'
                  | 'priority'
                  | 'registerDateTime'
                  | 'salesAmountWithoutTax'
                  | 'scheduledEndDateTime'
                  | 'scheduledStartDateTime'
                  | 'sortNoInGanttChart'
                  | 'sortNoInList'
                  | 'sortNoInProjectStatus'
                  | 'totalScheduleTimeSec'
                  | 'versionNo'
                  | 'workingTimeSec'
                >;
              };
            workingMembers: Array<
              Maybe<
                { __typename?: 'WorkingMemberInfo' } & Pick<
                  WorkingMemberInfo,
                  'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >
            >;
          }
      >
    >
  >;
};

export type TaskQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
}>;

export type TaskQuery = { __typename?: 'Query' } & {
  task?: Maybe<
    { __typename?: 'Task' } & Pick<
      Task,
      | 'calendarLinkageCode'
      | 'completeDateTime'
      | 'description'
      | 'estimateTimeSec'
      | 'estimatedRemainingTimeSec'
      | 'id'
      | 'laborCost'
      | 'priority'
      | 'progressDeviationRate'
      | 'progressRate'
      | 'registerDateTime'
      | 'remainingTimeSec'
      | 'scheduleWorkingTimeSec'
      | 'scheduledEndDateTime'
      | 'scheduledStartDateTime'
      | 'sortNoInGanttChart'
      | 'sortNoInList'
      | 'sortNoInMyTask'
      | 'sortNoInTaskStatus'
      | 'title'
      | 'versionNo'
      | 'workingTimeSec'
    > & {
        assignees: Array<
          { __typename?: 'AssignedTaskMemberInfo' } & Pick<AssignedTaskMemberInfo, 'sortNo'> & {
              member: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
            }
        >;
        asssignee?: Maybe<
          { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >
        >;
        createdMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
        favoriteMembers: Array<
          Maybe<
            { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >
          >
        >;
        favoriteMembersInfo: Array<
          Maybe<
            { __typename?: 'FavoriteTaskMemberInfo' } & Pick<FavoriteTaskMemberInfo, 'sortNo'> & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
        project: { __typename?: 'Project' } & Pick<
          Project,
          | 'calendarLinkageCode'
          | 'color'
          | 'complete'
          | 'completeDateTime'
          | 'costBudgetWithoutTax'
          | 'description'
          | 'estimateTimeSec'
          | 'id'
          | 'key'
          | 'laborCost'
          | 'laborCostWithoutTax'
          | 'laborShare'
          | 'name'
          | 'priority'
          | 'registerDateTime'
          | 'salesAmountWithoutTax'
          | 'scheduledEndDateTime'
          | 'scheduledStartDateTime'
          | 'sortNoInGanttChart'
          | 'sortNoInList'
          | 'sortNoInProjectStatus'
          | 'totalScheduleTimeSec'
          | 'versionNo'
          | 'workingTimeSec'
        > & {
            assignees: Array<
              { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                AssignedProjectMemberInfo,
                'sortNo'
              >
            >;
            client?: Maybe<
              { __typename?: 'Client' } & Pick<
                Client,
                'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
              >
            >;
            createdMember?: Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >;
            favoriteMembers: Array<
              Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >
            >;
            favoriteMembersInfo: Array<
              Maybe<
                { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                  FavoriteProjectMemberInfo,
                  'sortNo'
                >
              >
            >;
            projectStatus: { __typename?: 'ProjectStatus' } & Pick<
              ProjectStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
            >;
            team: { __typename?: 'Team' } & Pick<
              Team,
              'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
            > & {
                organization: { __typename?: 'Organization' } & Pick<
                  Organization,
                  | 'allowConnectThirdPartyCalendar'
                  | 'contactMailAddress'
                  | 'contractPreriod'
                  | 'id'
                  | 'imageUrl'
                  | 'ipRestrictionFlg'
                  | 'name'
                  | 'requiredTwoFactorAuth'
                  | 'suspensionFlg'
                  | 'versionNo'
                > & {
                    plan: { __typename?: 'PlanProduct' } & Pick<
                      PlanProduct,
                      'code' | 'contractPreriod' | 'id'
                    >;
                  };
              };
          };
        taskStatus: { __typename?: 'TaskStatus' } & Pick<
          TaskStatus,
          'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
        > & {
            project: { __typename?: 'Project' } & Pick<
              Project,
              | 'calendarLinkageCode'
              | 'color'
              | 'complete'
              | 'completeDateTime'
              | 'costBudgetWithoutTax'
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'key'
              | 'laborCost'
              | 'laborCostWithoutTax'
              | 'laborShare'
              | 'name'
              | 'priority'
              | 'registerDateTime'
              | 'salesAmountWithoutTax'
              | 'scheduledEndDateTime'
              | 'scheduledStartDateTime'
              | 'sortNoInGanttChart'
              | 'sortNoInList'
              | 'sortNoInProjectStatus'
              | 'totalScheduleTimeSec'
              | 'versionNo'
              | 'workingTimeSec'
            >;
          };
        workingMembers: Array<
          Maybe<
            { __typename?: 'WorkingMemberInfo' } & Pick<
              WorkingMemberInfo,
              'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
            > & {
                member: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
              }
          >
        >;
      }
  >;
};

export type TaskAttachmentFilesQueryVariables = Exact<{
  taskId: Scalars['String'];
}>;

export type TaskAttachmentFilesQuery = { __typename?: 'Query' } & {
  taskAttachmentFiles?: Maybe<
    Array<
      Maybe<
        { __typename?: 'AttachmentFile' } & Pick<
          AttachmentFile,
          'fileName' | 'id' | 'sizeByte' | 'type' | 'uploadDateTime'
        >
      >
    >
  >;
};

export type TaskBarGraphDataQueryVariables = Exact<{
  input: TaskGraphDataInputInput;
}>;

export type TaskBarGraphDataQuery = { __typename?: 'Query' } & {
  taskBarGraphData?: Maybe<
    Array<
      Maybe<
        { __typename?: 'TaskBarGraphData' } & Pick<
          TaskBarGraphData,
          | 'color'
          | 'itemType'
          | 'percent'
          | 'projectId'
          | 'projectName'
          | 'reportType'
          | 'taskId'
          | 'taskTitle'
          | 'teamId'
          | 'teamName'
          | 'workingTimeSec'
        >
      >
    >
  >;
};

export type TaskCommentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type TaskCommentQuery = { __typename?: 'Query' } & {
  taskComment?: Maybe<
    { __typename?: 'TaskComment' } & Pick<
      TaskComment,
      | 'comment'
      | 'commentedDateTime'
      | 'id'
      | 'projectId'
      | 'projectName'
      | 'taskId'
      | 'taskTitle'
      | 'teamId'
      | 'teamName'
      | 'versionNo'
    > & {
        createdMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
        task?: Maybe<
          { __typename?: 'Task' } & Pick<
            Task,
            | 'calendarLinkageCode'
            | 'completeDateTime'
            | 'description'
            | 'estimateTimeSec'
            | 'estimatedRemainingTimeSec'
            | 'id'
            | 'laborCost'
            | 'priority'
            | 'progressDeviationRate'
            | 'progressRate'
            | 'registerDateTime'
            | 'remainingTimeSec'
            | 'scheduleWorkingTimeSec'
            | 'scheduledEndDateTime'
            | 'scheduledStartDateTime'
            | 'sortNoInGanttChart'
            | 'sortNoInList'
            | 'sortNoInMyTask'
            | 'sortNoInTaskStatus'
            | 'title'
            | 'versionNo'
            | 'workingTimeSec'
          > & {
              assignees: Array<
                { __typename?: 'AssignedTaskMemberInfo' } & Pick<
                  AssignedTaskMemberInfo,
                  'sortNo'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >;
              asssignee?: Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >;
              createdMember: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
              favoriteMembers: Array<
                Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >
              >;
              favoriteMembersInfo: Array<
                Maybe<
                  { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                    FavoriteTaskMemberInfo,
                    'sortNo'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >
              >;
              project: { __typename?: 'Project' } & Pick<
                Project,
                | 'calendarLinkageCode'
                | 'color'
                | 'complete'
                | 'completeDateTime'
                | 'costBudgetWithoutTax'
                | 'description'
                | 'estimateTimeSec'
                | 'id'
                | 'key'
                | 'laborCost'
                | 'laborCostWithoutTax'
                | 'laborShare'
                | 'name'
                | 'priority'
                | 'registerDateTime'
                | 'salesAmountWithoutTax'
                | 'scheduledEndDateTime'
                | 'scheduledStartDateTime'
                | 'sortNoInGanttChart'
                | 'sortNoInList'
                | 'sortNoInProjectStatus'
                | 'totalScheduleTimeSec'
                | 'versionNo'
                | 'workingTimeSec'
              > & {
                  assignees: Array<
                    { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                      AssignedProjectMemberInfo,
                      'sortNo'
                    >
                  >;
                  client?: Maybe<
                    { __typename?: 'Client' } & Pick<
                      Client,
                      'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                    >
                  >;
                  createdMember?: Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >;
                  favoriteMembers: Array<
                    Maybe<
                      { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >
                    >
                  >;
                  favoriteMembersInfo: Array<
                    Maybe<
                      { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                        FavoriteProjectMemberInfo,
                        'sortNo'
                      >
                    >
                  >;
                  projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                    ProjectStatus,
                    'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                  >;
                  team: { __typename?: 'Team' } & Pick<
                    Team,
                    'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                  > & {
                      organization: { __typename?: 'Organization' } & Pick<
                        Organization,
                        | 'allowConnectThirdPartyCalendar'
                        | 'contactMailAddress'
                        | 'contractPreriod'
                        | 'id'
                        | 'imageUrl'
                        | 'ipRestrictionFlg'
                        | 'name'
                        | 'requiredTwoFactorAuth'
                        | 'suspensionFlg'
                        | 'versionNo'
                      > & {
                          plan: { __typename?: 'PlanProduct' } & Pick<
                            PlanProduct,
                            'code' | 'contractPreriod' | 'id'
                          >;
                        };
                    };
                };
              taskStatus: { __typename?: 'TaskStatus' } & Pick<
                TaskStatus,
                'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
              > & {
                  project: { __typename?: 'Project' } & Pick<
                    Project,
                    | 'calendarLinkageCode'
                    | 'color'
                    | 'complete'
                    | 'completeDateTime'
                    | 'costBudgetWithoutTax'
                    | 'description'
                    | 'estimateTimeSec'
                    | 'id'
                    | 'key'
                    | 'laborCost'
                    | 'laborCostWithoutTax'
                    | 'laborShare'
                    | 'name'
                    | 'priority'
                    | 'registerDateTime'
                    | 'salesAmountWithoutTax'
                    | 'scheduledEndDateTime'
                    | 'scheduledStartDateTime'
                    | 'sortNoInGanttChart'
                    | 'sortNoInList'
                    | 'sortNoInProjectStatus'
                    | 'totalScheduleTimeSec'
                    | 'versionNo'
                    | 'workingTimeSec'
                  >;
                };
              workingMembers: Array<
                Maybe<
                  { __typename?: 'WorkingMemberInfo' } & Pick<
                    WorkingMemberInfo,
                    'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >
              >;
            }
        >;
      }
  >;
};

export type TaskCommentsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sortOrder?: Maybe<SortOrder>;
  taskId: Scalars['String'];
}>;

export type TaskCommentsQuery = { __typename?: 'Query' } & {
  taskComments?: Maybe<
    Array<
      Maybe<
        { __typename?: 'TaskComment' } & Pick<
          TaskComment,
          | 'comment'
          | 'commentedDateTime'
          | 'id'
          | 'projectId'
          | 'projectName'
          | 'taskId'
          | 'taskTitle'
          | 'teamId'
          | 'teamName'
          | 'versionNo'
        > & {
            createdMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
            task?: Maybe<
              { __typename?: 'Task' } & Pick<
                Task,
                | 'calendarLinkageCode'
                | 'completeDateTime'
                | 'description'
                | 'estimateTimeSec'
                | 'estimatedRemainingTimeSec'
                | 'id'
                | 'laborCost'
                | 'priority'
                | 'progressDeviationRate'
                | 'progressRate'
                | 'registerDateTime'
                | 'remainingTimeSec'
                | 'scheduleWorkingTimeSec'
                | 'scheduledEndDateTime'
                | 'scheduledStartDateTime'
                | 'sortNoInGanttChart'
                | 'sortNoInList'
                | 'sortNoInMyTask'
                | 'sortNoInTaskStatus'
                | 'title'
                | 'versionNo'
                | 'workingTimeSec'
              > & {
                  assignees: Array<
                    { __typename?: 'AssignedTaskMemberInfo' } & Pick<
                      AssignedTaskMemberInfo,
                      'sortNo'
                    > & {
                        member: { __typename?: 'Member' } & Pick<
                          Member,
                          | 'adminRole'
                          | 'clientManagementRole'
                          | 'department'
                          | 'employeeNumber'
                          | 'id'
                          | 'laborCostManagementRole'
                          | 'mailAddress'
                          | 'memberManagementRole'
                          | 'memberRegistrationDateTime'
                          | 'memberStatus'
                          | 'name'
                          | 'organizationMemberViewRole'
                          | 'organizationReportRole'
                          | 'profileImageUrl'
                          | 'projectCreatePermissionFlg'
                          | 'projectCustomAttributePermissionFlg'
                          | 'projectDeletePermissionFlg'
                          | 'projectUpdatePermissionFlg'
                          | 'proxyEditWorkingDataRole'
                          | 'salesManagementRole'
                          | 'statusMessage'
                          | 'taskCreatePermissionFlg'
                          | 'taskCustomAttributePermissionFlg'
                          | 'taskDeletePermissionFlg'
                          | 'taskUpdatePermissionFlg'
                          | 'teamMemberViewRole'
                          | 'teamReportRole'
                          | 'versionNo'
                        >;
                      }
                  >;
                  asssignee?: Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >;
                  createdMember: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                  favoriteMembers: Array<
                    Maybe<
                      { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >
                    >
                  >;
                  favoriteMembersInfo: Array<
                    Maybe<
                      { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                        FavoriteTaskMemberInfo,
                        'sortNo'
                      > & {
                          member: { __typename?: 'Member' } & Pick<
                            Member,
                            | 'adminRole'
                            | 'clientManagementRole'
                            | 'department'
                            | 'employeeNumber'
                            | 'id'
                            | 'laborCostManagementRole'
                            | 'mailAddress'
                            | 'memberManagementRole'
                            | 'memberRegistrationDateTime'
                            | 'memberStatus'
                            | 'name'
                            | 'organizationMemberViewRole'
                            | 'organizationReportRole'
                            | 'profileImageUrl'
                            | 'projectCreatePermissionFlg'
                            | 'projectCustomAttributePermissionFlg'
                            | 'projectDeletePermissionFlg'
                            | 'projectUpdatePermissionFlg'
                            | 'proxyEditWorkingDataRole'
                            | 'salesManagementRole'
                            | 'statusMessage'
                            | 'taskCreatePermissionFlg'
                            | 'taskCustomAttributePermissionFlg'
                            | 'taskDeletePermissionFlg'
                            | 'taskUpdatePermissionFlg'
                            | 'teamMemberViewRole'
                            | 'teamReportRole'
                            | 'versionNo'
                          >;
                        }
                    >
                  >;
                  project: { __typename?: 'Project' } & Pick<
                    Project,
                    | 'calendarLinkageCode'
                    | 'color'
                    | 'complete'
                    | 'completeDateTime'
                    | 'costBudgetWithoutTax'
                    | 'description'
                    | 'estimateTimeSec'
                    | 'id'
                    | 'key'
                    | 'laborCost'
                    | 'laborCostWithoutTax'
                    | 'laborShare'
                    | 'name'
                    | 'priority'
                    | 'registerDateTime'
                    | 'salesAmountWithoutTax'
                    | 'scheduledEndDateTime'
                    | 'scheduledStartDateTime'
                    | 'sortNoInGanttChart'
                    | 'sortNoInList'
                    | 'sortNoInProjectStatus'
                    | 'totalScheduleTimeSec'
                    | 'versionNo'
                    | 'workingTimeSec'
                  > & {
                      assignees: Array<
                        { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                          AssignedProjectMemberInfo,
                          'sortNo'
                        >
                      >;
                      client?: Maybe<
                        { __typename?: 'Client' } & Pick<
                          Client,
                          'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                        >
                      >;
                      createdMember?: Maybe<
                        { __typename?: 'Member' } & Pick<
                          Member,
                          | 'adminRole'
                          | 'clientManagementRole'
                          | 'department'
                          | 'employeeNumber'
                          | 'id'
                          | 'laborCostManagementRole'
                          | 'mailAddress'
                          | 'memberManagementRole'
                          | 'memberRegistrationDateTime'
                          | 'memberStatus'
                          | 'name'
                          | 'organizationMemberViewRole'
                          | 'organizationReportRole'
                          | 'profileImageUrl'
                          | 'projectCreatePermissionFlg'
                          | 'projectCustomAttributePermissionFlg'
                          | 'projectDeletePermissionFlg'
                          | 'projectUpdatePermissionFlg'
                          | 'proxyEditWorkingDataRole'
                          | 'salesManagementRole'
                          | 'statusMessage'
                          | 'taskCreatePermissionFlg'
                          | 'taskCustomAttributePermissionFlg'
                          | 'taskDeletePermissionFlg'
                          | 'taskUpdatePermissionFlg'
                          | 'teamMemberViewRole'
                          | 'teamReportRole'
                          | 'versionNo'
                        >
                      >;
                      favoriteMembers: Array<
                        Maybe<
                          { __typename?: 'Member' } & Pick<
                            Member,
                            | 'adminRole'
                            | 'clientManagementRole'
                            | 'department'
                            | 'employeeNumber'
                            | 'id'
                            | 'laborCostManagementRole'
                            | 'mailAddress'
                            | 'memberManagementRole'
                            | 'memberRegistrationDateTime'
                            | 'memberStatus'
                            | 'name'
                            | 'organizationMemberViewRole'
                            | 'organizationReportRole'
                            | 'profileImageUrl'
                            | 'projectCreatePermissionFlg'
                            | 'projectCustomAttributePermissionFlg'
                            | 'projectDeletePermissionFlg'
                            | 'projectUpdatePermissionFlg'
                            | 'proxyEditWorkingDataRole'
                            | 'salesManagementRole'
                            | 'statusMessage'
                            | 'taskCreatePermissionFlg'
                            | 'taskCustomAttributePermissionFlg'
                            | 'taskDeletePermissionFlg'
                            | 'taskUpdatePermissionFlg'
                            | 'teamMemberViewRole'
                            | 'teamReportRole'
                            | 'versionNo'
                          >
                        >
                      >;
                      favoriteMembersInfo: Array<
                        Maybe<
                          { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                            FavoriteProjectMemberInfo,
                            'sortNo'
                          >
                        >
                      >;
                      projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                        ProjectStatus,
                        'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                      >;
                      team: { __typename?: 'Team' } & Pick<
                        Team,
                        'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                      > & {
                          organization: { __typename?: 'Organization' } & Pick<
                            Organization,
                            | 'allowConnectThirdPartyCalendar'
                            | 'contactMailAddress'
                            | 'contractPreriod'
                            | 'id'
                            | 'imageUrl'
                            | 'ipRestrictionFlg'
                            | 'name'
                            | 'requiredTwoFactorAuth'
                            | 'suspensionFlg'
                            | 'versionNo'
                          > & {
                              plan: { __typename?: 'PlanProduct' } & Pick<
                                PlanProduct,
                                'code' | 'contractPreriod' | 'id'
                              >;
                            };
                        };
                    };
                  taskStatus: { __typename?: 'TaskStatus' } & Pick<
                    TaskStatus,
                    'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
                  > & {
                      project: { __typename?: 'Project' } & Pick<
                        Project,
                        | 'calendarLinkageCode'
                        | 'color'
                        | 'complete'
                        | 'completeDateTime'
                        | 'costBudgetWithoutTax'
                        | 'description'
                        | 'estimateTimeSec'
                        | 'id'
                        | 'key'
                        | 'laborCost'
                        | 'laborCostWithoutTax'
                        | 'laborShare'
                        | 'name'
                        | 'priority'
                        | 'registerDateTime'
                        | 'salesAmountWithoutTax'
                        | 'scheduledEndDateTime'
                        | 'scheduledStartDateTime'
                        | 'sortNoInGanttChart'
                        | 'sortNoInList'
                        | 'sortNoInProjectStatus'
                        | 'totalScheduleTimeSec'
                        | 'versionNo'
                        | 'workingTimeSec'
                      >;
                    };
                  workingMembers: Array<
                    Maybe<
                      { __typename?: 'WorkingMemberInfo' } & Pick<
                        WorkingMemberInfo,
                        'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                      > & {
                          member: { __typename?: 'Member' } & Pick<
                            Member,
                            | 'adminRole'
                            | 'clientManagementRole'
                            | 'department'
                            | 'employeeNumber'
                            | 'id'
                            | 'laborCostManagementRole'
                            | 'mailAddress'
                            | 'memberManagementRole'
                            | 'memberRegistrationDateTime'
                            | 'memberStatus'
                            | 'name'
                            | 'organizationMemberViewRole'
                            | 'organizationReportRole'
                            | 'profileImageUrl'
                            | 'projectCreatePermissionFlg'
                            | 'projectCustomAttributePermissionFlg'
                            | 'projectDeletePermissionFlg'
                            | 'projectUpdatePermissionFlg'
                            | 'proxyEditWorkingDataRole'
                            | 'salesManagementRole'
                            | 'statusMessage'
                            | 'taskCreatePermissionFlg'
                            | 'taskCustomAttributePermissionFlg'
                            | 'taskDeletePermissionFlg'
                            | 'taskUpdatePermissionFlg'
                            | 'teamMemberViewRole'
                            | 'teamReportRole'
                            | 'versionNo'
                          >;
                        }
                    >
                  >;
                }
            >;
          }
      >
    >
  >;
};

export type TaskCommentsMinQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sortOrder?: Maybe<SortOrder>;
  taskId: Scalars['String'];
}>;

export type TaskCommentsMinQuery = { __typename?: 'Query' } & {
  taskCommentsMin?: Maybe<
    Array<
      Maybe<
        { __typename?: 'TaskCommentMin' } & Pick<
          TaskCommentMin,
          'comment' | 'commentedDateTime' | 'id' | 'versionNo'
        > & {
            createdMember: { __typename?: 'TaskCommentMinMemberPart' } & Pick<
              TaskCommentMinMemberPart,
              'id' | 'name' | 'profileImageUrl'
            >;
          }
      >
    >
  >;
};

export type TaskCustomAttributeQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type TaskCustomAttributeQuery = { __typename?: 'Query' } & {
  taskCustomAttribute?: Maybe<
    { __typename?: 'TaskCustomAttribute' } & Pick<
      TaskCustomAttribute,
      'id' | 'sortNo' | 'value'
    > & {
        listItem?: Maybe<
          { __typename?: 'TaskCustomAttributeMasterListItem' } & Pick<
            TaskCustomAttributeMasterListItem,
            'id' | 'sortNo' | 'value' | 'versionNo'
          >
        >;
        master: { __typename?: 'TaskCustomAttributeMaster' } & Pick<
          TaskCustomAttributeMaster,
          'id' | 'name' | 'required' | 'sortNo' | 'type' | 'versionNo'
        > & {
            listItem: Array<
              Maybe<
                { __typename?: 'TaskCustomAttributeMasterListItem' } & Pick<
                  TaskCustomAttributeMasterListItem,
                  'id' | 'sortNo' | 'value' | 'versionNo'
                >
              >
            >;
          };
      }
  >;
};

export type TaskCustomAttributeMasterQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type TaskCustomAttributeMasterQuery = { __typename?: 'Query' } & {
  taskCustomAttributeMaster?: Maybe<
    { __typename?: 'TaskCustomAttributeMaster' } & Pick<
      TaskCustomAttributeMaster,
      'id' | 'name' | 'required' | 'sortNo' | 'type' | 'versionNo'
    > & {
        listItem: Array<
          Maybe<
            { __typename?: 'TaskCustomAttributeMasterListItem' } & Pick<
              TaskCustomAttributeMasterListItem,
              'id' | 'sortNo' | 'value' | 'versionNo'
            >
          >
        >;
      }
  >;
};

export type TaskCustomAttributeMastersQueryVariables = Exact<{ [key: string]: never }>;

export type TaskCustomAttributeMastersQuery = { __typename?: 'Query' } & {
  taskCustomAttributeMasters?: Maybe<
    Array<
      Maybe<
        { __typename?: 'TaskCustomAttributeMaster' } & Pick<
          TaskCustomAttributeMaster,
          'id' | 'name' | 'required' | 'sortNo' | 'type' | 'versionNo'
        > & {
            listItem: Array<
              Maybe<
                { __typename?: 'TaskCustomAttributeMasterListItem' } & Pick<
                  TaskCustomAttributeMasterListItem,
                  'id' | 'sortNo' | 'value' | 'versionNo'
                >
              >
            >;
          }
      >
    >
  >;
};

export type TaskCustomAttributeTypesQueryVariables = Exact<{ [key: string]: never }>;

export type TaskCustomAttributeTypesQuery = { __typename?: 'Query' } & {
  taskCustomAttributeTypes?: Maybe<
    Array<
      Maybe<
        { __typename?: 'TaskCustomAttributeType' } & Pick<
          TaskCustomAttributeType,
          'code' | 'name' | 'sortNo'
        >
      >
    >
  >;
};

export type TaskCustomAttributesByTaskQueryVariables = Exact<{
  taskId: Scalars['String'];
}>;

export type TaskCustomAttributesByTaskQuery = { __typename?: 'Query' } & {
  taskCustomAttributesByTask?: Maybe<
    Array<
      Maybe<
        { __typename?: 'TaskCustomAttribute' } & Pick<
          TaskCustomAttribute,
          'id' | 'sortNo' | 'value'
        > & {
            listItem?: Maybe<
              { __typename?: 'TaskCustomAttributeMasterListItem' } & Pick<
                TaskCustomAttributeMasterListItem,
                'id' | 'sortNo' | 'value' | 'versionNo'
              >
            >;
            master: { __typename?: 'TaskCustomAttributeMaster' } & Pick<
              TaskCustomAttributeMaster,
              'id' | 'name' | 'required' | 'sortNo' | 'type' | 'versionNo'
            > & {
                listItem: Array<
                  Maybe<
                    { __typename?: 'TaskCustomAttributeMasterListItem' } & Pick<
                      TaskCustomAttributeMasterListItem,
                      'id' | 'sortNo' | 'value' | 'versionNo'
                    >
                  >
                >;
              };
          }
      >
    >
  >;
};

export type TaskDateGraphDataQueryVariables = Exact<{
  input: TaskGraphDataInputInput;
}>;

export type TaskDateGraphDataQuery = { __typename?: 'Query' } & {
  taskDateGraphData?: Maybe<
    Array<
      Maybe<
        { __typename?: 'TaskDateGraphData' } & Pick<TaskDateGraphData, 'date' | 'reportType'> & {
            items: Array<
              Maybe<
                { __typename?: 'TaskDateGraphDataDetail' } & Pick<
                  TaskDateGraphDataDetail,
                  | 'color'
                  | 'itemType'
                  | 'percent'
                  | 'projectId'
                  | 'projectName'
                  | 'taskId'
                  | 'taskTitle'
                  | 'teamId'
                  | 'teamName'
                  | 'workingTimeSec'
                >
              >
            >;
          }
      >
    >
  >;
};

export type TaskRemainderQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
}>;

export type TaskRemainderQuery = { __typename?: 'Query' } & {
  taskRemainder?: Maybe<
    { __typename?: 'TaskRemainder' } & Pick<
      TaskRemainder,
      | 'beforeDeadlineMinutes'
      | 'id'
      | 'notificationSentFlg'
      | 'taskRemainderDestType'
      | 'taskRemainderType'
    > & {
        targetMember?: Maybe<
          { __typename?: 'Member' } & Pick<
            Member,
            | 'adminRole'
            | 'clientManagementRole'
            | 'department'
            | 'employeeNumber'
            | 'id'
            | 'laborCostManagementRole'
            | 'mailAddress'
            | 'memberManagementRole'
            | 'memberRegistrationDateTime'
            | 'memberStatus'
            | 'name'
            | 'organizationMemberViewRole'
            | 'organizationReportRole'
            | 'profileImageUrl'
            | 'projectCreatePermissionFlg'
            | 'projectCustomAttributePermissionFlg'
            | 'projectDeletePermissionFlg'
            | 'projectUpdatePermissionFlg'
            | 'proxyEditWorkingDataRole'
            | 'salesManagementRole'
            | 'statusMessage'
            | 'taskCreatePermissionFlg'
            | 'taskCustomAttributePermissionFlg'
            | 'taskDeletePermissionFlg'
            | 'taskUpdatePermissionFlg'
            | 'teamMemberViewRole'
            | 'teamReportRole'
            | 'versionNo'
          >
        >;
        task: { __typename?: 'Task' } & Pick<
          Task,
          | 'calendarLinkageCode'
          | 'completeDateTime'
          | 'description'
          | 'estimateTimeSec'
          | 'estimatedRemainingTimeSec'
          | 'id'
          | 'laborCost'
          | 'priority'
          | 'progressDeviationRate'
          | 'progressRate'
          | 'registerDateTime'
          | 'remainingTimeSec'
          | 'scheduleWorkingTimeSec'
          | 'scheduledEndDateTime'
          | 'scheduledStartDateTime'
          | 'sortNoInGanttChart'
          | 'sortNoInList'
          | 'sortNoInMyTask'
          | 'sortNoInTaskStatus'
          | 'title'
          | 'versionNo'
          | 'workingTimeSec'
        > & {
            assignees: Array<
              { __typename?: 'AssignedTaskMemberInfo' } & Pick<AssignedTaskMemberInfo, 'sortNo'> & {
                  member: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                }
            >;
            asssignee?: Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >;
            createdMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
            favoriteMembers: Array<
              Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >
            >;
            favoriteMembersInfo: Array<
              Maybe<
                { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                  FavoriteTaskMemberInfo,
                  'sortNo'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >
            >;
            project: { __typename?: 'Project' } & Pick<
              Project,
              | 'calendarLinkageCode'
              | 'color'
              | 'complete'
              | 'completeDateTime'
              | 'costBudgetWithoutTax'
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'key'
              | 'laborCost'
              | 'laborCostWithoutTax'
              | 'laborShare'
              | 'name'
              | 'priority'
              | 'registerDateTime'
              | 'salesAmountWithoutTax'
              | 'scheduledEndDateTime'
              | 'scheduledStartDateTime'
              | 'sortNoInGanttChart'
              | 'sortNoInList'
              | 'sortNoInProjectStatus'
              | 'totalScheduleTimeSec'
              | 'versionNo'
              | 'workingTimeSec'
            > & {
                assignees: Array<
                  { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                    AssignedProjectMemberInfo,
                    'sortNo'
                  >
                >;
                client?: Maybe<
                  { __typename?: 'Client' } & Pick<
                    Client,
                    'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                  >
                >;
                createdMember?: Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >;
                favoriteMembers: Array<
                  Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >
                >;
                favoriteMembersInfo: Array<
                  Maybe<
                    { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                      FavoriteProjectMemberInfo,
                      'sortNo'
                    >
                  >
                >;
                projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                  ProjectStatus,
                  'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                >;
                team: { __typename?: 'Team' } & Pick<
                  Team,
                  'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                > & {
                    organization: { __typename?: 'Organization' } & Pick<
                      Organization,
                      | 'allowConnectThirdPartyCalendar'
                      | 'contactMailAddress'
                      | 'contractPreriod'
                      | 'id'
                      | 'imageUrl'
                      | 'ipRestrictionFlg'
                      | 'name'
                      | 'requiredTwoFactorAuth'
                      | 'suspensionFlg'
                      | 'versionNo'
                    > & {
                        plan: { __typename?: 'PlanProduct' } & Pick<
                          PlanProduct,
                          'code' | 'contractPreriod' | 'id'
                        >;
                      };
                  };
              };
            taskStatus: { __typename?: 'TaskStatus' } & Pick<
              TaskStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
            > & {
                project: { __typename?: 'Project' } & Pick<
                  Project,
                  | 'calendarLinkageCode'
                  | 'color'
                  | 'complete'
                  | 'completeDateTime'
                  | 'costBudgetWithoutTax'
                  | 'description'
                  | 'estimateTimeSec'
                  | 'id'
                  | 'key'
                  | 'laborCost'
                  | 'laborCostWithoutTax'
                  | 'laborShare'
                  | 'name'
                  | 'priority'
                  | 'registerDateTime'
                  | 'salesAmountWithoutTax'
                  | 'scheduledEndDateTime'
                  | 'scheduledStartDateTime'
                  | 'sortNoInGanttChart'
                  | 'sortNoInList'
                  | 'sortNoInProjectStatus'
                  | 'totalScheduleTimeSec'
                  | 'versionNo'
                  | 'workingTimeSec'
                >;
              };
            workingMembers: Array<
              Maybe<
                { __typename?: 'WorkingMemberInfo' } & Pick<
                  WorkingMemberInfo,
                  'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >
            >;
          };
      }
  >;
};

export type TaskRemaindersQueryVariables = Exact<{
  taskId: Scalars['String'];
}>;

export type TaskRemaindersQuery = { __typename?: 'Query' } & {
  taskRemainders?: Maybe<
    Array<
      Maybe<
        { __typename?: 'TaskRemainder' } & Pick<
          TaskRemainder,
          | 'beforeDeadlineMinutes'
          | 'id'
          | 'notificationSentFlg'
          | 'taskRemainderDestType'
          | 'taskRemainderType'
        > & {
            targetMember?: Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >;
            task: { __typename?: 'Task' } & Pick<
              Task,
              | 'calendarLinkageCode'
              | 'completeDateTime'
              | 'description'
              | 'estimateTimeSec'
              | 'estimatedRemainingTimeSec'
              | 'id'
              | 'laborCost'
              | 'priority'
              | 'progressDeviationRate'
              | 'progressRate'
              | 'registerDateTime'
              | 'remainingTimeSec'
              | 'scheduleWorkingTimeSec'
              | 'scheduledEndDateTime'
              | 'scheduledStartDateTime'
              | 'sortNoInGanttChart'
              | 'sortNoInList'
              | 'sortNoInMyTask'
              | 'sortNoInTaskStatus'
              | 'title'
              | 'versionNo'
              | 'workingTimeSec'
            > & {
                assignees: Array<
                  { __typename?: 'AssignedTaskMemberInfo' } & Pick<
                    AssignedTaskMemberInfo,
                    'sortNo'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >;
                asssignee?: Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >;
                createdMember: { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >;
                favoriteMembers: Array<
                  Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >
                >;
                favoriteMembersInfo: Array<
                  Maybe<
                    { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                      FavoriteTaskMemberInfo,
                      'sortNo'
                    > & {
                        member: { __typename?: 'Member' } & Pick<
                          Member,
                          | 'adminRole'
                          | 'clientManagementRole'
                          | 'department'
                          | 'employeeNumber'
                          | 'id'
                          | 'laborCostManagementRole'
                          | 'mailAddress'
                          | 'memberManagementRole'
                          | 'memberRegistrationDateTime'
                          | 'memberStatus'
                          | 'name'
                          | 'organizationMemberViewRole'
                          | 'organizationReportRole'
                          | 'profileImageUrl'
                          | 'projectCreatePermissionFlg'
                          | 'projectCustomAttributePermissionFlg'
                          | 'projectDeletePermissionFlg'
                          | 'projectUpdatePermissionFlg'
                          | 'proxyEditWorkingDataRole'
                          | 'salesManagementRole'
                          | 'statusMessage'
                          | 'taskCreatePermissionFlg'
                          | 'taskCustomAttributePermissionFlg'
                          | 'taskDeletePermissionFlg'
                          | 'taskUpdatePermissionFlg'
                          | 'teamMemberViewRole'
                          | 'teamReportRole'
                          | 'versionNo'
                        >;
                      }
                  >
                >;
                project: { __typename?: 'Project' } & Pick<
                  Project,
                  | 'calendarLinkageCode'
                  | 'color'
                  | 'complete'
                  | 'completeDateTime'
                  | 'costBudgetWithoutTax'
                  | 'description'
                  | 'estimateTimeSec'
                  | 'id'
                  | 'key'
                  | 'laborCost'
                  | 'laborCostWithoutTax'
                  | 'laborShare'
                  | 'name'
                  | 'priority'
                  | 'registerDateTime'
                  | 'salesAmountWithoutTax'
                  | 'scheduledEndDateTime'
                  | 'scheduledStartDateTime'
                  | 'sortNoInGanttChart'
                  | 'sortNoInList'
                  | 'sortNoInProjectStatus'
                  | 'totalScheduleTimeSec'
                  | 'versionNo'
                  | 'workingTimeSec'
                > & {
                    assignees: Array<
                      { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                        AssignedProjectMemberInfo,
                        'sortNo'
                      >
                    >;
                    client?: Maybe<
                      { __typename?: 'Client' } & Pick<
                        Client,
                        'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                      >
                    >;
                    createdMember?: Maybe<
                      { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >
                    >;
                    favoriteMembers: Array<
                      Maybe<
                        { __typename?: 'Member' } & Pick<
                          Member,
                          | 'adminRole'
                          | 'clientManagementRole'
                          | 'department'
                          | 'employeeNumber'
                          | 'id'
                          | 'laborCostManagementRole'
                          | 'mailAddress'
                          | 'memberManagementRole'
                          | 'memberRegistrationDateTime'
                          | 'memberStatus'
                          | 'name'
                          | 'organizationMemberViewRole'
                          | 'organizationReportRole'
                          | 'profileImageUrl'
                          | 'projectCreatePermissionFlg'
                          | 'projectCustomAttributePermissionFlg'
                          | 'projectDeletePermissionFlg'
                          | 'projectUpdatePermissionFlg'
                          | 'proxyEditWorkingDataRole'
                          | 'salesManagementRole'
                          | 'statusMessage'
                          | 'taskCreatePermissionFlg'
                          | 'taskCustomAttributePermissionFlg'
                          | 'taskDeletePermissionFlg'
                          | 'taskUpdatePermissionFlg'
                          | 'teamMemberViewRole'
                          | 'teamReportRole'
                          | 'versionNo'
                        >
                      >
                    >;
                    favoriteMembersInfo: Array<
                      Maybe<
                        { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                          FavoriteProjectMemberInfo,
                          'sortNo'
                        >
                      >
                    >;
                    projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                      ProjectStatus,
                      'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                    >;
                    team: { __typename?: 'Team' } & Pick<
                      Team,
                      'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                    > & {
                        organization: { __typename?: 'Organization' } & Pick<
                          Organization,
                          | 'allowConnectThirdPartyCalendar'
                          | 'contactMailAddress'
                          | 'contractPreriod'
                          | 'id'
                          | 'imageUrl'
                          | 'ipRestrictionFlg'
                          | 'name'
                          | 'requiredTwoFactorAuth'
                          | 'suspensionFlg'
                          | 'versionNo'
                        > & {
                            plan: { __typename?: 'PlanProduct' } & Pick<
                              PlanProduct,
                              'code' | 'contractPreriod' | 'id'
                            >;
                          };
                      };
                  };
                taskStatus: { __typename?: 'TaskStatus' } & Pick<
                  TaskStatus,
                  'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
                > & {
                    project: { __typename?: 'Project' } & Pick<
                      Project,
                      | 'calendarLinkageCode'
                      | 'color'
                      | 'complete'
                      | 'completeDateTime'
                      | 'costBudgetWithoutTax'
                      | 'description'
                      | 'estimateTimeSec'
                      | 'id'
                      | 'key'
                      | 'laborCost'
                      | 'laborCostWithoutTax'
                      | 'laborShare'
                      | 'name'
                      | 'priority'
                      | 'registerDateTime'
                      | 'salesAmountWithoutTax'
                      | 'scheduledEndDateTime'
                      | 'scheduledStartDateTime'
                      | 'sortNoInGanttChart'
                      | 'sortNoInList'
                      | 'sortNoInProjectStatus'
                      | 'totalScheduleTimeSec'
                      | 'versionNo'
                      | 'workingTimeSec'
                    >;
                  };
                workingMembers: Array<
                  Maybe<
                    { __typename?: 'WorkingMemberInfo' } & Pick<
                      WorkingMemberInfo,
                      'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                    > & {
                        member: { __typename?: 'Member' } & Pick<
                          Member,
                          | 'adminRole'
                          | 'clientManagementRole'
                          | 'department'
                          | 'employeeNumber'
                          | 'id'
                          | 'laborCostManagementRole'
                          | 'mailAddress'
                          | 'memberManagementRole'
                          | 'memberRegistrationDateTime'
                          | 'memberStatus'
                          | 'name'
                          | 'organizationMemberViewRole'
                          | 'organizationReportRole'
                          | 'profileImageUrl'
                          | 'projectCreatePermissionFlg'
                          | 'projectCustomAttributePermissionFlg'
                          | 'projectDeletePermissionFlg'
                          | 'projectUpdatePermissionFlg'
                          | 'proxyEditWorkingDataRole'
                          | 'salesManagementRole'
                          | 'statusMessage'
                          | 'taskCreatePermissionFlg'
                          | 'taskCustomAttributePermissionFlg'
                          | 'taskDeletePermissionFlg'
                          | 'taskUpdatePermissionFlg'
                          | 'teamMemberViewRole'
                          | 'teamReportRole'
                          | 'versionNo'
                        >;
                      }
                  >
                >;
              };
          }
      >
    >
  >;
};

export type TaskStatusQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type TaskStatusQuery = { __typename?: 'Query' } & {
  taskStatus?: Maybe<
    { __typename?: 'TaskStatus' } & Pick<
      TaskStatus,
      'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
    > & {
        project: { __typename?: 'Project' } & Pick<
          Project,
          | 'calendarLinkageCode'
          | 'color'
          | 'complete'
          | 'completeDateTime'
          | 'costBudgetWithoutTax'
          | 'description'
          | 'estimateTimeSec'
          | 'id'
          | 'key'
          | 'laborCost'
          | 'laborCostWithoutTax'
          | 'laborShare'
          | 'name'
          | 'priority'
          | 'registerDateTime'
          | 'salesAmountWithoutTax'
          | 'scheduledEndDateTime'
          | 'scheduledStartDateTime'
          | 'sortNoInGanttChart'
          | 'sortNoInList'
          | 'sortNoInProjectStatus'
          | 'totalScheduleTimeSec'
          | 'versionNo'
          | 'workingTimeSec'
        > & {
            assignees: Array<
              { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                AssignedProjectMemberInfo,
                'sortNo'
              > & {
                  member: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                }
            >;
            client?: Maybe<
              { __typename?: 'Client' } & Pick<
                Client,
                'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
              >
            >;
            createdMember?: Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >;
            favoriteMembers: Array<
              Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >
            >;
            favoriteMembersInfo: Array<
              Maybe<
                { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                  FavoriteProjectMemberInfo,
                  'sortNo'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >
            >;
            projectStatus: { __typename?: 'ProjectStatus' } & Pick<
              ProjectStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
            >;
            team: { __typename?: 'Team' } & Pick<
              Team,
              'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
            > & {
                organization: { __typename?: 'Organization' } & Pick<
                  Organization,
                  | 'allowConnectThirdPartyCalendar'
                  | 'contactMailAddress'
                  | 'contractPreriod'
                  | 'id'
                  | 'imageUrl'
                  | 'ipRestrictionFlg'
                  | 'name'
                  | 'requiredTwoFactorAuth'
                  | 'suspensionFlg'
                  | 'versionNo'
                > & {
                    plan: { __typename?: 'PlanProduct' } & Pick<
                      PlanProduct,
                      'code' | 'contractPreriod' | 'id'
                    >;
                  };
              };
          };
      }
  >;
};

export type TaskSunburstGraphDataQueryVariables = Exact<{
  input: TaskGraphDataInputInput;
}>;

export type TaskSunburstGraphDataQuery = { __typename?: 'Query' } & {
  taskSunburstGraphData?: Maybe<
    Array<
      Maybe<
        { __typename?: 'TaskSunburstGraphData' } & Pick<
          TaskSunburstGraphData,
          'color' | 'name' | 'reportType' | 'teamId'
        > & {
            children: Array<
              Maybe<
                { __typename?: 'TaskSunburstGraphProjectData' } & Pick<
                  TaskSunburstGraphProjectData,
                  'color' | 'name' | 'projectId'
                > & {
                    children: Array<
                      Maybe<
                        { __typename?: 'TaskSunburstGraphTaskData' } & Pick<
                          TaskSunburstGraphTaskData,
                          'color' | 'itemType' | 'name' | 'percent' | 'taskId' | 'workingTimeSec'
                        >
                      >
                    >;
                  }
              >
            >;
          }
      >
    >
  >;
};

export type TaskTagsQueryVariables = Exact<{
  taskId: Scalars['String'];
}>;

export type TaskTagsQuery = { __typename?: 'Query' } & {
  taskTags?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Tag' } & Pick<
          Tag,
          'id' | 'name' | 'note' | 'relatedTaskCount' | 'sortNo' | 'versionNo'
        > & {
            organization: { __typename?: 'Organization' } & Pick<
              Organization,
              | 'allowConnectThirdPartyCalendar'
              | 'contactMailAddress'
              | 'contractPreriod'
              | 'id'
              | 'imageUrl'
              | 'ipRestrictionFlg'
              | 'name'
              | 'requiredTwoFactorAuth'
              | 'suspensionFlg'
              | 'versionNo'
            > & {
                plan: { __typename?: 'PlanProduct' } & Pick<
                  PlanProduct,
                  'code' | 'contractPreriod' | 'id'
                >;
              };
          }
      >
    >
  >;
};

export type TaskWorkingHistoriesQueryVariables = Exact<{
  end?: Maybe<Scalars['DateTime']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  onlySelfData?: Maybe<Scalars['Boolean']>;
  start?: Maybe<Scalars['DateTime']>;
  taskId: Scalars['String'];
}>;

export type TaskWorkingHistoriesQuery = { __typename?: 'Query' } & {
  taskWorkingHistories?: Maybe<
    Array<
      Maybe<
        { __typename?: 'WorkingHistory' } & Pick<
          WorkingHistory,
          'end' | 'id' | 'memo' | 'start' | 'versionNo' | 'workingTimeSec'
        > & {
            calendarEvent?: Maybe<
              { __typename?: 'CalendarEvent' } & Pick<
                CalendarEvent,
                | 'calendarType'
                | 'clientId'
                | 'clientName'
                | 'endDateTime'
                | 'eventName'
                | 'id'
                | 'laborCost'
                | 'personalTeam'
                | 'projectColor'
                | 'projectId'
                | 'projectName'
                | 'startDateTime'
                | 'taskId'
                | 'taskTitle'
                | 'teamId'
                | 'teamName'
                | 'thirdPartyCalendarEventId'
                | 'versionNo'
                | 'workingTimeSec'
              > & {
                  thirdPartyCalendarInfoList: Array<
                    Maybe<
                      { __typename?: 'ThirdPartyCalendarInfo' } & Pick<
                        ThirdPartyCalendarInfo,
                        'thirdPartyCalendarId'
                      > & {
                          member: { __typename?: 'Member' } & Pick<
                            Member,
                            | 'adminRole'
                            | 'clientManagementRole'
                            | 'department'
                            | 'employeeNumber'
                            | 'id'
                            | 'laborCostManagementRole'
                            | 'mailAddress'
                            | 'memberManagementRole'
                            | 'memberRegistrationDateTime'
                            | 'memberStatus'
                            | 'name'
                            | 'organizationMemberViewRole'
                            | 'organizationReportRole'
                            | 'profileImageUrl'
                            | 'projectCreatePermissionFlg'
                            | 'projectCustomAttributePermissionFlg'
                            | 'projectDeletePermissionFlg'
                            | 'projectUpdatePermissionFlg'
                            | 'proxyEditWorkingDataRole'
                            | 'salesManagementRole'
                            | 'statusMessage'
                            | 'taskCreatePermissionFlg'
                            | 'taskCustomAttributePermissionFlg'
                            | 'taskDeletePermissionFlg'
                            | 'taskUpdatePermissionFlg'
                            | 'teamMemberViewRole'
                            | 'teamReportRole'
                            | 'versionNo'
                          >;
                        }
                    >
                  >;
                }
            >;
            closingProcessInfo: { __typename?: 'ClosingProcessPart' } & Pick<
              ClosingProcessPart,
              'closed' | 'targetDateTime'
            >;
            task?: Maybe<
              { __typename?: 'Task' } & Pick<
                Task,
                | 'calendarLinkageCode'
                | 'completeDateTime'
                | 'description'
                | 'estimateTimeSec'
                | 'estimatedRemainingTimeSec'
                | 'id'
                | 'laborCost'
                | 'priority'
                | 'progressDeviationRate'
                | 'progressRate'
                | 'registerDateTime'
                | 'remainingTimeSec'
                | 'scheduleWorkingTimeSec'
                | 'scheduledEndDateTime'
                | 'scheduledStartDateTime'
                | 'sortNoInGanttChart'
                | 'sortNoInList'
                | 'sortNoInMyTask'
                | 'sortNoInTaskStatus'
                | 'title'
                | 'versionNo'
                | 'workingTimeSec'
              > & {
                  assignees: Array<
                    { __typename?: 'AssignedTaskMemberInfo' } & Pick<
                      AssignedTaskMemberInfo,
                      'sortNo'
                    > & {
                        member: { __typename?: 'Member' } & Pick<
                          Member,
                          | 'adminRole'
                          | 'clientManagementRole'
                          | 'department'
                          | 'employeeNumber'
                          | 'id'
                          | 'laborCostManagementRole'
                          | 'mailAddress'
                          | 'memberManagementRole'
                          | 'memberRegistrationDateTime'
                          | 'memberStatus'
                          | 'name'
                          | 'organizationMemberViewRole'
                          | 'organizationReportRole'
                          | 'profileImageUrl'
                          | 'projectCreatePermissionFlg'
                          | 'projectCustomAttributePermissionFlg'
                          | 'projectDeletePermissionFlg'
                          | 'projectUpdatePermissionFlg'
                          | 'proxyEditWorkingDataRole'
                          | 'salesManagementRole'
                          | 'statusMessage'
                          | 'taskCreatePermissionFlg'
                          | 'taskCustomAttributePermissionFlg'
                          | 'taskDeletePermissionFlg'
                          | 'taskUpdatePermissionFlg'
                          | 'teamMemberViewRole'
                          | 'teamReportRole'
                          | 'versionNo'
                        >;
                      }
                  >;
                  asssignee?: Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >;
                  createdMember: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                  favoriteMembers: Array<
                    Maybe<
                      { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >
                    >
                  >;
                  favoriteMembersInfo: Array<
                    Maybe<
                      { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                        FavoriteTaskMemberInfo,
                        'sortNo'
                      > & {
                          member: { __typename?: 'Member' } & Pick<
                            Member,
                            | 'adminRole'
                            | 'clientManagementRole'
                            | 'department'
                            | 'employeeNumber'
                            | 'id'
                            | 'laborCostManagementRole'
                            | 'mailAddress'
                            | 'memberManagementRole'
                            | 'memberRegistrationDateTime'
                            | 'memberStatus'
                            | 'name'
                            | 'organizationMemberViewRole'
                            | 'organizationReportRole'
                            | 'profileImageUrl'
                            | 'projectCreatePermissionFlg'
                            | 'projectCustomAttributePermissionFlg'
                            | 'projectDeletePermissionFlg'
                            | 'projectUpdatePermissionFlg'
                            | 'proxyEditWorkingDataRole'
                            | 'salesManagementRole'
                            | 'statusMessage'
                            | 'taskCreatePermissionFlg'
                            | 'taskCustomAttributePermissionFlg'
                            | 'taskDeletePermissionFlg'
                            | 'taskUpdatePermissionFlg'
                            | 'teamMemberViewRole'
                            | 'teamReportRole'
                            | 'versionNo'
                          >;
                        }
                    >
                  >;
                  project: { __typename?: 'Project' } & Pick<
                    Project,
                    | 'calendarLinkageCode'
                    | 'color'
                    | 'complete'
                    | 'completeDateTime'
                    | 'costBudgetWithoutTax'
                    | 'description'
                    | 'estimateTimeSec'
                    | 'id'
                    | 'key'
                    | 'laborCost'
                    | 'laborCostWithoutTax'
                    | 'laborShare'
                    | 'name'
                    | 'priority'
                    | 'registerDateTime'
                    | 'salesAmountWithoutTax'
                    | 'scheduledEndDateTime'
                    | 'scheduledStartDateTime'
                    | 'sortNoInGanttChart'
                    | 'sortNoInList'
                    | 'sortNoInProjectStatus'
                    | 'totalScheduleTimeSec'
                    | 'versionNo'
                    | 'workingTimeSec'
                  > & {
                      assignees: Array<
                        { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                          AssignedProjectMemberInfo,
                          'sortNo'
                        >
                      >;
                      client?: Maybe<
                        { __typename?: 'Client' } & Pick<
                          Client,
                          'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                        >
                      >;
                      createdMember?: Maybe<
                        { __typename?: 'Member' } & Pick<
                          Member,
                          | 'adminRole'
                          | 'clientManagementRole'
                          | 'department'
                          | 'employeeNumber'
                          | 'id'
                          | 'laborCostManagementRole'
                          | 'mailAddress'
                          | 'memberManagementRole'
                          | 'memberRegistrationDateTime'
                          | 'memberStatus'
                          | 'name'
                          | 'organizationMemberViewRole'
                          | 'organizationReportRole'
                          | 'profileImageUrl'
                          | 'projectCreatePermissionFlg'
                          | 'projectCustomAttributePermissionFlg'
                          | 'projectDeletePermissionFlg'
                          | 'projectUpdatePermissionFlg'
                          | 'proxyEditWorkingDataRole'
                          | 'salesManagementRole'
                          | 'statusMessage'
                          | 'taskCreatePermissionFlg'
                          | 'taskCustomAttributePermissionFlg'
                          | 'taskDeletePermissionFlg'
                          | 'taskUpdatePermissionFlg'
                          | 'teamMemberViewRole'
                          | 'teamReportRole'
                          | 'versionNo'
                        >
                      >;
                      favoriteMembers: Array<
                        Maybe<
                          { __typename?: 'Member' } & Pick<
                            Member,
                            | 'adminRole'
                            | 'clientManagementRole'
                            | 'department'
                            | 'employeeNumber'
                            | 'id'
                            | 'laborCostManagementRole'
                            | 'mailAddress'
                            | 'memberManagementRole'
                            | 'memberRegistrationDateTime'
                            | 'memberStatus'
                            | 'name'
                            | 'organizationMemberViewRole'
                            | 'organizationReportRole'
                            | 'profileImageUrl'
                            | 'projectCreatePermissionFlg'
                            | 'projectCustomAttributePermissionFlg'
                            | 'projectDeletePermissionFlg'
                            | 'projectUpdatePermissionFlg'
                            | 'proxyEditWorkingDataRole'
                            | 'salesManagementRole'
                            | 'statusMessage'
                            | 'taskCreatePermissionFlg'
                            | 'taskCustomAttributePermissionFlg'
                            | 'taskDeletePermissionFlg'
                            | 'taskUpdatePermissionFlg'
                            | 'teamMemberViewRole'
                            | 'teamReportRole'
                            | 'versionNo'
                          >
                        >
                      >;
                      favoriteMembersInfo: Array<
                        Maybe<
                          { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                            FavoriteProjectMemberInfo,
                            'sortNo'
                          >
                        >
                      >;
                      projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                        ProjectStatus,
                        'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                      >;
                      team: { __typename?: 'Team' } & Pick<
                        Team,
                        'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                      > & {
                          organization: { __typename?: 'Organization' } & Pick<
                            Organization,
                            | 'allowConnectThirdPartyCalendar'
                            | 'contactMailAddress'
                            | 'contractPreriod'
                            | 'id'
                            | 'imageUrl'
                            | 'ipRestrictionFlg'
                            | 'name'
                            | 'requiredTwoFactorAuth'
                            | 'suspensionFlg'
                            | 'versionNo'
                          > & {
                              plan: { __typename?: 'PlanProduct' } & Pick<
                                PlanProduct,
                                'code' | 'contractPreriod' | 'id'
                              >;
                            };
                        };
                    };
                  taskStatus: { __typename?: 'TaskStatus' } & Pick<
                    TaskStatus,
                    'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
                  > & {
                      project: { __typename?: 'Project' } & Pick<
                        Project,
                        | 'calendarLinkageCode'
                        | 'color'
                        | 'complete'
                        | 'completeDateTime'
                        | 'costBudgetWithoutTax'
                        | 'description'
                        | 'estimateTimeSec'
                        | 'id'
                        | 'key'
                        | 'laborCost'
                        | 'laborCostWithoutTax'
                        | 'laborShare'
                        | 'name'
                        | 'priority'
                        | 'registerDateTime'
                        | 'salesAmountWithoutTax'
                        | 'scheduledEndDateTime'
                        | 'scheduledStartDateTime'
                        | 'sortNoInGanttChart'
                        | 'sortNoInList'
                        | 'sortNoInProjectStatus'
                        | 'totalScheduleTimeSec'
                        | 'versionNo'
                        | 'workingTimeSec'
                      >;
                    };
                  workingMembers: Array<
                    Maybe<
                      { __typename?: 'WorkingMemberInfo' } & Pick<
                        WorkingMemberInfo,
                        'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                      > & {
                          member: { __typename?: 'Member' } & Pick<
                            Member,
                            | 'adminRole'
                            | 'clientManagementRole'
                            | 'department'
                            | 'employeeNumber'
                            | 'id'
                            | 'laborCostManagementRole'
                            | 'mailAddress'
                            | 'memberManagementRole'
                            | 'memberRegistrationDateTime'
                            | 'memberStatus'
                            | 'name'
                            | 'organizationMemberViewRole'
                            | 'organizationReportRole'
                            | 'profileImageUrl'
                            | 'projectCreatePermissionFlg'
                            | 'projectCustomAttributePermissionFlg'
                            | 'projectDeletePermissionFlg'
                            | 'projectUpdatePermissionFlg'
                            | 'proxyEditWorkingDataRole'
                            | 'salesManagementRole'
                            | 'statusMessage'
                            | 'taskCreatePermissionFlg'
                            | 'taskCustomAttributePermissionFlg'
                            | 'taskDeletePermissionFlg'
                            | 'taskUpdatePermissionFlg'
                            | 'teamMemberViewRole'
                            | 'teamReportRole'
                            | 'versionNo'
                          >;
                        }
                    >
                  >;
                }
            >;
            workingMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
          }
      >
    >
  >;
};

export type TaskWorkingHistoriesForSummaryQueryVariables = Exact<{
  end?: Maybe<Scalars['DateTime']>;
  limit?: Maybe<Scalars['Int']>;
  memberId?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  onlySelfData?: Maybe<Scalars['Boolean']>;
  start?: Maybe<Scalars['DateTime']>;
  taskId: Scalars['String'];
  teamId?: Maybe<Scalars['String']>;
}>;

export type TaskWorkingHistoriesForSummaryQuery = { __typename?: 'Query' } & {
  taskWorkingHistoriesForSummary?: Maybe<
    Array<
      Maybe<
        { __typename?: 'WorkingHistoryForSummary' } & Pick<
          WorkingHistoryForSummary,
          'end' | 'id' | 'memo' | 'start' | 'versionNo' | 'workingTimeSec'
        > & {
            calendarEvent?: Maybe<
              { __typename?: 'WorkingHistoryForSummaryCalendarEventPart' } & Pick<
                WorkingHistoryForSummaryCalendarEventPart,
                | 'clientId'
                | 'clientName'
                | 'eventName'
                | 'id'
                | 'projectId'
                | 'projectName'
                | 'teamId'
              >
            >;
            closingProcessInfo: {
              __typename?: 'WorkingHistoryForSummaryClosingProcessPart';
            } & Pick<WorkingHistoryForSummaryClosingProcessPart, 'closed' | 'targetDateTime'>;
            task?: Maybe<
              { __typename?: 'WorkingHistoryForSummaryTaskPart' } & Pick<
                WorkingHistoryForSummaryTaskPart,
                'id' | 'title' | 'versionNo'
              > & {
                  project: { __typename?: 'WorkingHistoryForSummaryProjectPart' } & Pick<
                    WorkingHistoryForSummaryProjectPart,
                    'id' | 'name'
                  > & {
                      client?: Maybe<
                        { __typename?: 'WorkingHistoryForSummaryClientPart' } & Pick<
                          WorkingHistoryForSummaryClientPart,
                          'id' | 'name'
                        >
                      >;
                      team: { __typename?: 'WorkingHistoryForSummaryTeamPart' } & Pick<
                        WorkingHistoryForSummaryTeamPart,
                        'id' | 'personalTeam'
                      >;
                    };
                }
            >;
            workingMember: { __typename?: 'WorkingHistoryForSummaryMemberPart' } & Pick<
              WorkingHistoryForSummaryMemberPart,
              'id' | 'name' | 'profileImageUrl'
            >;
          }
      >
    >
  >;
};

export type TaskWorkingHistorySummaryGroupByAssineeQueryVariables = Exact<{
  taskId: Scalars['String'];
  teamId?: Maybe<Scalars['String']>;
}>;

export type TaskWorkingHistorySummaryGroupByAssineeQuery = { __typename?: 'Query' } & {
  taskWorkingHistorySummaryGroupByAssinee?: Maybe<
    Array<
      Maybe<
        { __typename?: 'WorkingHistorySummaryGroupByAssinee' } & Pick<
          WorkingHistorySummaryGroupByAssinee,
          'lastWorkingStartDateTime' | 'totalWorkingTimeSec' | 'tracking'
        > & {
            member?: Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >;
          }
      >
    >
  >;
};

export type TaskWorkingScheduleSummaryGroupByAssineeQueryVariables = Exact<{
  taskId: Scalars['String'];
  teamId?: Maybe<Scalars['String']>;
}>;

export type TaskWorkingScheduleSummaryGroupByAssineeQuery = { __typename?: 'Query' } & {
  taskWorkingScheduleSummaryGroupByAssinee?: Maybe<
    Array<
      Maybe<
        { __typename?: 'WorkingScheduleSummaryGroupByAssinee' } & Pick<
          WorkingScheduleSummaryGroupByAssinee,
          'totalWorkingTimeSec'
        > & {
            member?: Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >;
          }
      >
    >
  >;
};

export type TaskWorkingSchedulesQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  onlySelfData?: Maybe<Scalars['Boolean']>;
  taskId: Scalars['String'];
}>;

export type TaskWorkingSchedulesQuery = { __typename?: 'Query' } & {
  taskWorkingSchedules?: Maybe<
    Array<
      Maybe<
        { __typename?: 'WorkingSchedule' } & Pick<
          WorkingSchedule,
          'end' | 'id' | 'memo' | 'start' | 'versionNo' | 'workingTimeSec'
        > & {
            calendarEvent?: Maybe<
              { __typename?: 'CalendarEvent' } & Pick<
                CalendarEvent,
                | 'calendarType'
                | 'clientId'
                | 'clientName'
                | 'endDateTime'
                | 'eventName'
                | 'id'
                | 'laborCost'
                | 'personalTeam'
                | 'projectColor'
                | 'projectId'
                | 'projectName'
                | 'startDateTime'
                | 'taskId'
                | 'taskTitle'
                | 'teamId'
                | 'teamName'
                | 'thirdPartyCalendarEventId'
                | 'versionNo'
                | 'workingTimeSec'
              > & {
                  thirdPartyCalendarInfoList: Array<
                    Maybe<
                      { __typename?: 'ThirdPartyCalendarInfo' } & Pick<
                        ThirdPartyCalendarInfo,
                        'thirdPartyCalendarId'
                      > & {
                          member: { __typename?: 'Member' } & Pick<
                            Member,
                            | 'adminRole'
                            | 'clientManagementRole'
                            | 'department'
                            | 'employeeNumber'
                            | 'id'
                            | 'laborCostManagementRole'
                            | 'mailAddress'
                            | 'memberManagementRole'
                            | 'memberRegistrationDateTime'
                            | 'memberStatus'
                            | 'name'
                            | 'organizationMemberViewRole'
                            | 'organizationReportRole'
                            | 'profileImageUrl'
                            | 'projectCreatePermissionFlg'
                            | 'projectCustomAttributePermissionFlg'
                            | 'projectDeletePermissionFlg'
                            | 'projectUpdatePermissionFlg'
                            | 'proxyEditWorkingDataRole'
                            | 'salesManagementRole'
                            | 'statusMessage'
                            | 'taskCreatePermissionFlg'
                            | 'taskCustomAttributePermissionFlg'
                            | 'taskDeletePermissionFlg'
                            | 'taskUpdatePermissionFlg'
                            | 'teamMemberViewRole'
                            | 'teamReportRole'
                            | 'versionNo'
                          >;
                        }
                    >
                  >;
                }
            >;
            task?: Maybe<
              { __typename?: 'Task' } & Pick<
                Task,
                | 'calendarLinkageCode'
                | 'completeDateTime'
                | 'description'
                | 'estimateTimeSec'
                | 'estimatedRemainingTimeSec'
                | 'id'
                | 'laborCost'
                | 'priority'
                | 'progressDeviationRate'
                | 'progressRate'
                | 'registerDateTime'
                | 'remainingTimeSec'
                | 'scheduleWorkingTimeSec'
                | 'scheduledEndDateTime'
                | 'scheduledStartDateTime'
                | 'sortNoInGanttChart'
                | 'sortNoInList'
                | 'sortNoInMyTask'
                | 'sortNoInTaskStatus'
                | 'title'
                | 'versionNo'
                | 'workingTimeSec'
              > & {
                  assignees: Array<
                    { __typename?: 'AssignedTaskMemberInfo' } & Pick<
                      AssignedTaskMemberInfo,
                      'sortNo'
                    > & {
                        member: { __typename?: 'Member' } & Pick<
                          Member,
                          | 'adminRole'
                          | 'clientManagementRole'
                          | 'department'
                          | 'employeeNumber'
                          | 'id'
                          | 'laborCostManagementRole'
                          | 'mailAddress'
                          | 'memberManagementRole'
                          | 'memberRegistrationDateTime'
                          | 'memberStatus'
                          | 'name'
                          | 'organizationMemberViewRole'
                          | 'organizationReportRole'
                          | 'profileImageUrl'
                          | 'projectCreatePermissionFlg'
                          | 'projectCustomAttributePermissionFlg'
                          | 'projectDeletePermissionFlg'
                          | 'projectUpdatePermissionFlg'
                          | 'proxyEditWorkingDataRole'
                          | 'salesManagementRole'
                          | 'statusMessage'
                          | 'taskCreatePermissionFlg'
                          | 'taskCustomAttributePermissionFlg'
                          | 'taskDeletePermissionFlg'
                          | 'taskUpdatePermissionFlg'
                          | 'teamMemberViewRole'
                          | 'teamReportRole'
                          | 'versionNo'
                        >;
                      }
                  >;
                  asssignee?: Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >;
                  createdMember: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                  favoriteMembers: Array<
                    Maybe<
                      { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >
                    >
                  >;
                  favoriteMembersInfo: Array<
                    Maybe<
                      { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                        FavoriteTaskMemberInfo,
                        'sortNo'
                      > & {
                          member: { __typename?: 'Member' } & Pick<
                            Member,
                            | 'adminRole'
                            | 'clientManagementRole'
                            | 'department'
                            | 'employeeNumber'
                            | 'id'
                            | 'laborCostManagementRole'
                            | 'mailAddress'
                            | 'memberManagementRole'
                            | 'memberRegistrationDateTime'
                            | 'memberStatus'
                            | 'name'
                            | 'organizationMemberViewRole'
                            | 'organizationReportRole'
                            | 'profileImageUrl'
                            | 'projectCreatePermissionFlg'
                            | 'projectCustomAttributePermissionFlg'
                            | 'projectDeletePermissionFlg'
                            | 'projectUpdatePermissionFlg'
                            | 'proxyEditWorkingDataRole'
                            | 'salesManagementRole'
                            | 'statusMessage'
                            | 'taskCreatePermissionFlg'
                            | 'taskCustomAttributePermissionFlg'
                            | 'taskDeletePermissionFlg'
                            | 'taskUpdatePermissionFlg'
                            | 'teamMemberViewRole'
                            | 'teamReportRole'
                            | 'versionNo'
                          >;
                        }
                    >
                  >;
                  project: { __typename?: 'Project' } & Pick<
                    Project,
                    | 'calendarLinkageCode'
                    | 'color'
                    | 'complete'
                    | 'completeDateTime'
                    | 'costBudgetWithoutTax'
                    | 'description'
                    | 'estimateTimeSec'
                    | 'id'
                    | 'key'
                    | 'laborCost'
                    | 'laborCostWithoutTax'
                    | 'laborShare'
                    | 'name'
                    | 'priority'
                    | 'registerDateTime'
                    | 'salesAmountWithoutTax'
                    | 'scheduledEndDateTime'
                    | 'scheduledStartDateTime'
                    | 'sortNoInGanttChart'
                    | 'sortNoInList'
                    | 'sortNoInProjectStatus'
                    | 'totalScheduleTimeSec'
                    | 'versionNo'
                    | 'workingTimeSec'
                  > & {
                      assignees: Array<
                        { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                          AssignedProjectMemberInfo,
                          'sortNo'
                        >
                      >;
                      client?: Maybe<
                        { __typename?: 'Client' } & Pick<
                          Client,
                          'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                        >
                      >;
                      createdMember?: Maybe<
                        { __typename?: 'Member' } & Pick<
                          Member,
                          | 'adminRole'
                          | 'clientManagementRole'
                          | 'department'
                          | 'employeeNumber'
                          | 'id'
                          | 'laborCostManagementRole'
                          | 'mailAddress'
                          | 'memberManagementRole'
                          | 'memberRegistrationDateTime'
                          | 'memberStatus'
                          | 'name'
                          | 'organizationMemberViewRole'
                          | 'organizationReportRole'
                          | 'profileImageUrl'
                          | 'projectCreatePermissionFlg'
                          | 'projectCustomAttributePermissionFlg'
                          | 'projectDeletePermissionFlg'
                          | 'projectUpdatePermissionFlg'
                          | 'proxyEditWorkingDataRole'
                          | 'salesManagementRole'
                          | 'statusMessage'
                          | 'taskCreatePermissionFlg'
                          | 'taskCustomAttributePermissionFlg'
                          | 'taskDeletePermissionFlg'
                          | 'taskUpdatePermissionFlg'
                          | 'teamMemberViewRole'
                          | 'teamReportRole'
                          | 'versionNo'
                        >
                      >;
                      favoriteMembers: Array<
                        Maybe<
                          { __typename?: 'Member' } & Pick<
                            Member,
                            | 'adminRole'
                            | 'clientManagementRole'
                            | 'department'
                            | 'employeeNumber'
                            | 'id'
                            | 'laborCostManagementRole'
                            | 'mailAddress'
                            | 'memberManagementRole'
                            | 'memberRegistrationDateTime'
                            | 'memberStatus'
                            | 'name'
                            | 'organizationMemberViewRole'
                            | 'organizationReportRole'
                            | 'profileImageUrl'
                            | 'projectCreatePermissionFlg'
                            | 'projectCustomAttributePermissionFlg'
                            | 'projectDeletePermissionFlg'
                            | 'projectUpdatePermissionFlg'
                            | 'proxyEditWorkingDataRole'
                            | 'salesManagementRole'
                            | 'statusMessage'
                            | 'taskCreatePermissionFlg'
                            | 'taskCustomAttributePermissionFlg'
                            | 'taskDeletePermissionFlg'
                            | 'taskUpdatePermissionFlg'
                            | 'teamMemberViewRole'
                            | 'teamReportRole'
                            | 'versionNo'
                          >
                        >
                      >;
                      favoriteMembersInfo: Array<
                        Maybe<
                          { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                            FavoriteProjectMemberInfo,
                            'sortNo'
                          >
                        >
                      >;
                      projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                        ProjectStatus,
                        'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                      >;
                      team: { __typename?: 'Team' } & Pick<
                        Team,
                        'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                      > & {
                          organization: { __typename?: 'Organization' } & Pick<
                            Organization,
                            | 'allowConnectThirdPartyCalendar'
                            | 'contactMailAddress'
                            | 'contractPreriod'
                            | 'id'
                            | 'imageUrl'
                            | 'ipRestrictionFlg'
                            | 'name'
                            | 'requiredTwoFactorAuth'
                            | 'suspensionFlg'
                            | 'versionNo'
                          > & {
                              plan: { __typename?: 'PlanProduct' } & Pick<
                                PlanProduct,
                                'code' | 'contractPreriod' | 'id'
                              >;
                            };
                        };
                    };
                  taskStatus: { __typename?: 'TaskStatus' } & Pick<
                    TaskStatus,
                    'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
                  > & {
                      project: { __typename?: 'Project' } & Pick<
                        Project,
                        | 'calendarLinkageCode'
                        | 'color'
                        | 'complete'
                        | 'completeDateTime'
                        | 'costBudgetWithoutTax'
                        | 'description'
                        | 'estimateTimeSec'
                        | 'id'
                        | 'key'
                        | 'laborCost'
                        | 'laborCostWithoutTax'
                        | 'laborShare'
                        | 'name'
                        | 'priority'
                        | 'registerDateTime'
                        | 'salesAmountWithoutTax'
                        | 'scheduledEndDateTime'
                        | 'scheduledStartDateTime'
                        | 'sortNoInGanttChart'
                        | 'sortNoInList'
                        | 'sortNoInProjectStatus'
                        | 'totalScheduleTimeSec'
                        | 'versionNo'
                        | 'workingTimeSec'
                      >;
                    };
                  workingMembers: Array<
                    Maybe<
                      { __typename?: 'WorkingMemberInfo' } & Pick<
                        WorkingMemberInfo,
                        'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                      > & {
                          member: { __typename?: 'Member' } & Pick<
                            Member,
                            | 'adminRole'
                            | 'clientManagementRole'
                            | 'department'
                            | 'employeeNumber'
                            | 'id'
                            | 'laborCostManagementRole'
                            | 'mailAddress'
                            | 'memberManagementRole'
                            | 'memberRegistrationDateTime'
                            | 'memberStatus'
                            | 'name'
                            | 'organizationMemberViewRole'
                            | 'organizationReportRole'
                            | 'profileImageUrl'
                            | 'projectCreatePermissionFlg'
                            | 'projectCustomAttributePermissionFlg'
                            | 'projectDeletePermissionFlg'
                            | 'projectUpdatePermissionFlg'
                            | 'proxyEditWorkingDataRole'
                            | 'salesManagementRole'
                            | 'statusMessage'
                            | 'taskCreatePermissionFlg'
                            | 'taskCustomAttributePermissionFlg'
                            | 'taskDeletePermissionFlg'
                            | 'taskUpdatePermissionFlg'
                            | 'teamMemberViewRole'
                            | 'teamReportRole'
                            | 'versionNo'
                          >;
                        }
                    >
                  >;
                }
            >;
            workingMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
          }
      >
    >
  >;
};

export type TaskWorkingSchedulesForSummaryQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  onlySelfData?: Maybe<Scalars['Boolean']>;
  taskId: Scalars['String'];
}>;

export type TaskWorkingSchedulesForSummaryQuery = { __typename?: 'Query' } & {
  taskWorkingSchedulesForSummary?: Maybe<
    Array<
      Maybe<
        { __typename?: 'WorkingScheduleForSummary' } & Pick<
          WorkingScheduleForSummary,
          'end' | 'id' | 'memo' | 'start' | 'versionNo' | 'workingTimeSec'
        > & {
            calendarEvent?: Maybe<
              { __typename?: 'WorkingScheduleForSummaryCalendarEventPart' } & Pick<
                WorkingScheduleForSummaryCalendarEventPart,
                | 'clientId'
                | 'clientName'
                | 'eventName'
                | 'id'
                | 'projectId'
                | 'projectName'
                | 'teamId'
              >
            >;
            task?: Maybe<
              { __typename?: 'WorkingScheduleForSummaryTaskPart' } & Pick<
                WorkingScheduleForSummaryTaskPart,
                'id' | 'title' | 'versionNo'
              > & {
                  project: { __typename?: 'WorkingScheduleForSummaryProjectPart' } & Pick<
                    WorkingScheduleForSummaryProjectPart,
                    'id' | 'name'
                  > & {
                      client?: Maybe<
                        { __typename?: 'WorkingScheduleForSummaryClientPart' } & Pick<
                          WorkingScheduleForSummaryClientPart,
                          'id' | 'name'
                        >
                      >;
                      team: { __typename?: 'WorkingScheduleForSummaryTeamPart' } & Pick<
                        WorkingScheduleForSummaryTeamPart,
                        'id' | 'personalTeam'
                      >;
                    };
                }
            >;
            workingMember: { __typename?: 'WorkingScheduleForSummaryMemberPart' } & Pick<
              WorkingScheduleForSummaryMemberPart,
              'id' | 'name' | 'profileImageUrl'
            >;
          }
      >
    >
  >;
};

export type TeamQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type TeamQuery = { __typename?: 'Query' } & {
  team?: Maybe<
    { __typename?: 'Team' } & Pick<
      Team,
      'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
    > & {
        organization: { __typename?: 'Organization' } & Pick<
          Organization,
          | 'allowConnectThirdPartyCalendar'
          | 'contactMailAddress'
          | 'contractPreriod'
          | 'id'
          | 'imageUrl'
          | 'ipRestrictionFlg'
          | 'name'
          | 'requiredTwoFactorAuth'
          | 'suspensionFlg'
          | 'versionNo'
        > & {
            plan: { __typename?: 'PlanProduct' } & Pick<
              PlanProduct,
              'code' | 'contractPreriod' | 'id'
            >;
          };
      }
  >;
};

export type TeamBarGraphDataQueryVariables = Exact<{
  input: TeamtGraphDataInputInput;
}>;

export type TeamBarGraphDataQuery = { __typename?: 'Query' } & {
  teamBarGraphData?: Maybe<
    Array<
      Maybe<
        { __typename?: 'TeamBarGraphData' } & Pick<
          TeamBarGraphData,
          'color' | 'percent' | 'reportType' | 'teamId' | 'teamName' | 'workingTimeSec'
        >
      >
    >
  >;
};

export type TeamClosingSettingsQueryVariables = Exact<{
  teamId: Scalars['String'];
}>;

export type TeamClosingSettingsQuery = { __typename?: 'Query' } & {
  teamClosingSettings?: Maybe<
    { __typename?: 'TeamClosingSetting' } & Pick<
      TeamClosingSetting,
      'closingType' | 'id' | 'targetDateTime' | 'teamId' | 'versionNo'
    > & {
        timeZone: { __typename?: 'TimeZone' } & Pick<
          TimeZone,
          'code' | 'id' | 'name' | 'offsetTime' | 'sortNo'
        >;
      }
  >;
};

export type TeamCsvReportQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type TeamCsvReportQuery = { __typename?: 'Query' } & {
  teamCsvReport?: Maybe<
    { __typename?: 'TeamCsvReport' } & Pick<
      TeamCsvReport,
      | 'end'
      | 'id'
      | 'includeWorkingData'
      | 'reportType'
      | 'requestDateTime'
      | 'sjisFileUrl'
      | 'start'
      | 'status'
      | 'timeZoneOffset'
      | 'utf8FileUrl'
      | 'versionNo'
    > & {
        csvReportItemDefinition?: Maybe<
          { __typename?: 'CsvReportItemDefinition' } & Pick<
            CsvReportItemDefinition,
            'id' | 'name' | 'versionNo'
          > & {
              items: Array<
                Maybe<
                  { __typename?: 'CsvReportItemDefinitionDetail' } & Pick<
                    CsvReportItemDefinitionDetail,
                    'id' | 'reportItemCode' | 'reportItemName' | 'sortNo'
                  >
                >
              >;
            }
        >;
        requestMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
        team: { __typename?: 'Team' } & Pick<
          Team,
          'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
        > & {
            organization: { __typename?: 'Organization' } & Pick<
              Organization,
              | 'allowConnectThirdPartyCalendar'
              | 'contactMailAddress'
              | 'contractPreriod'
              | 'id'
              | 'imageUrl'
              | 'ipRestrictionFlg'
              | 'name'
              | 'requiredTwoFactorAuth'
              | 'suspensionFlg'
              | 'versionNo'
            > & {
                plan: { __typename?: 'PlanProduct' } & Pick<
                  PlanProduct,
                  'code' | 'contractPreriod' | 'id'
                >;
              };
          };
      }
  >;
};

export type TeamCsvReportsQueryVariables = Exact<{
  teamId: Scalars['ID'];
}>;

export type TeamCsvReportsQuery = { __typename?: 'Query' } & {
  teamCsvReports?: Maybe<
    Array<
      Maybe<
        { __typename?: 'TeamCsvReport' } & Pick<
          TeamCsvReport,
          | 'end'
          | 'id'
          | 'includeWorkingData'
          | 'reportType'
          | 'requestDateTime'
          | 'sjisFileUrl'
          | 'start'
          | 'status'
          | 'timeZoneOffset'
          | 'utf8FileUrl'
          | 'versionNo'
        > & {
            csvReportItemDefinition?: Maybe<
              { __typename?: 'CsvReportItemDefinition' } & Pick<
                CsvReportItemDefinition,
                'id' | 'name' | 'versionNo'
              > & {
                  items: Array<
                    Maybe<
                      { __typename?: 'CsvReportItemDefinitionDetail' } & Pick<
                        CsvReportItemDefinitionDetail,
                        'id' | 'reportItemCode' | 'reportItemName' | 'sortNo'
                      >
                    >
                  >;
                }
            >;
            requestMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
            team: { __typename?: 'Team' } & Pick<
              Team,
              'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
            > & {
                organization: { __typename?: 'Organization' } & Pick<
                  Organization,
                  | 'allowConnectThirdPartyCalendar'
                  | 'contactMailAddress'
                  | 'contractPreriod'
                  | 'id'
                  | 'imageUrl'
                  | 'ipRestrictionFlg'
                  | 'name'
                  | 'requiredTwoFactorAuth'
                  | 'suspensionFlg'
                  | 'versionNo'
                > & {
                    plan: { __typename?: 'PlanProduct' } & Pick<
                      PlanProduct,
                      'code' | 'contractPreriod' | 'id'
                    >;
                  };
              };
          }
      >
    >
  >;
};

export type TeamDateGraphDataQueryVariables = Exact<{
  input: TeamtGraphDataInputInput;
}>;

export type TeamDateGraphDataQuery = { __typename?: 'Query' } & {
  teamDateGraphData?: Maybe<
    Array<
      Maybe<
        { __typename?: 'TeamDateGraphData' } & Pick<TeamDateGraphData, 'date' | 'reportType'> & {
            items: Array<
              Maybe<
                { __typename?: 'TeamDateGraphDataDetail' } & Pick<
                  TeamDateGraphDataDetail,
                  'color' | 'percent' | 'teamId' | 'teamName' | 'workingTimeSec'
                >
              >
            >;
          }
      >
    >
  >;
};

export type TeamMemberRelationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type TeamMemberRelationQuery = { __typename?: 'Query' } & {
  teamMemberRelation?: Maybe<
    { __typename?: 'TeamMemberRelation' } & Pick<
      TeamMemberRelation,
      'id' | 'openMenu' | 'sortNo' | 'versionNo'
    > & {
        team: { __typename?: 'Team' } & Pick<
          Team,
          'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
        > & {
            organization: { __typename?: 'Organization' } & Pick<
              Organization,
              | 'allowConnectThirdPartyCalendar'
              | 'contactMailAddress'
              | 'contractPreriod'
              | 'id'
              | 'imageUrl'
              | 'ipRestrictionFlg'
              | 'name'
              | 'requiredTwoFactorAuth'
              | 'suspensionFlg'
              | 'versionNo'
            > & {
                plan: { __typename?: 'PlanProduct' } & Pick<
                  PlanProduct,
                  'code' | 'contractPreriod' | 'id'
                >;
              };
          };
      }
  >;
};

export type TeamMemberRelationsQueryVariables = Exact<{ [key: string]: never }>;

export type TeamMemberRelationsQuery = { __typename?: 'Query' } & {
  teamMemberRelations?: Maybe<
    Array<
      Maybe<
        { __typename?: 'TeamMemberRelation' } & Pick<
          TeamMemberRelation,
          'id' | 'openMenu' | 'sortNo' | 'versionNo'
        > & {
            team: { __typename?: 'Team' } & Pick<
              Team,
              'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
            > & {
                organization: { __typename?: 'Organization' } & Pick<
                  Organization,
                  | 'allowConnectThirdPartyCalendar'
                  | 'contactMailAddress'
                  | 'contractPreriod'
                  | 'id'
                  | 'imageUrl'
                  | 'ipRestrictionFlg'
                  | 'name'
                  | 'requiredTwoFactorAuth'
                  | 'suspensionFlg'
                  | 'versionNo'
                > & {
                    plan: { __typename?: 'PlanProduct' } & Pick<
                      PlanProduct,
                      'code' | 'contractPreriod' | 'id'
                    >;
                  };
              };
          }
      >
    >
  >;
};

export type TeamMembersQueryVariables = Exact<{
  teamId: Scalars['String'];
}>;

export type TeamMembersQuery = { __typename?: 'Query' } & {
  teamMembers?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >
      >
    >
  >;
};

export type TeamProjectContractsQueryVariables = Exact<{
  input?: Maybe<FetchTeamProjectContractsInputInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  teamId: Scalars['String'];
}>;

export type TeamProjectContractsQuery = { __typename?: 'Query' } & {
  teamProjectContracts?: Maybe<
    Array<
      Maybe<
        { __typename?: 'ProjectContract' } & Pick<
          ProjectContract,
          | 'contractType'
          | 'costBudgetWithoutTax'
          | 'endDateTime'
          | 'fixSalesAmountWithoutTax'
          | 'grossProfit'
          | 'grossProfitRatePercent'
          | 'id'
          | 'laborCostWithoutTax'
          | 'laborShare'
          | 'month'
          | 'roundTimeType'
          | 'roundedTotalWorkingTimeSec'
          | 'salesAmountWithoutTax'
          | 'salesRoundMinutes'
          | 'startDateTime'
          | 'taxRate'
          | 'termType'
          | 'totalWorkingTimeSec'
          | 'unitPriceType'
          | 'versionNo'
          | 'year'
        > & {
            allMemberMeteredRateContract?: Maybe<
              { __typename?: 'MeteredRateContract' } & Pick<
                MeteredRateContract,
                'hourUnitPrice' | 'id' | 'versionNo'
              > & {
                  member?: Maybe<
                    { __typename?: 'MeteredRateContractMemberPart' } & Pick<
                      MeteredRateContractMemberPart,
                      | 'laborCostWithoutTax'
                      | 'memberId'
                      | 'name'
                      | 'profileImageUrl'
                      | 'roundedTotalWorkingTimeSec'
                      | 'salesAmountWithoutTax'
                      | 'totalWorkingTimeSec'
                    >
                  >;
                }
            >;
            allMemberSettlementRangeContract?: Maybe<
              { __typename?: 'SettlementRangeContract' } & Pick<
                SettlementRangeContract,
                'id' | 'monthlyUnitPrice' | 'versionNo'
              > & {
                  lowerHourPart: { __typename?: 'LowerPart' } & Pick<
                    LowerPart,
                    'deducationHourlyUnitPrice' | 'lowerHour'
                  >;
                  member?: Maybe<
                    { __typename?: 'SettlementRangeContractMemberPart' } & Pick<
                      SettlementRangeContractMemberPart,
                      | 'laborCostWithoutTax'
                      | 'memberId'
                      | 'name'
                      | 'profileImageUrl'
                      | 'roundedTotalWorkingTimeSec'
                      | 'salesAmountWithoutTax'
                      | 'totalWorkingTimeSec'
                    >
                  >;
                  upperHourPart: { __typename?: 'UpperPart' } & Pick<
                    UpperPart,
                    'excessHourlyUnitPrice' | 'upperHour'
                  >;
                }
            >;
            membersMeteredRateContract?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'MeteredRateContract' } & Pick<
                    MeteredRateContract,
                    'hourUnitPrice' | 'id' | 'versionNo'
                  > & {
                      member?: Maybe<
                        { __typename?: 'MeteredRateContractMemberPart' } & Pick<
                          MeteredRateContractMemberPart,
                          | 'laborCostWithoutTax'
                          | 'memberId'
                          | 'name'
                          | 'profileImageUrl'
                          | 'roundedTotalWorkingTimeSec'
                          | 'salesAmountWithoutTax'
                          | 'totalWorkingTimeSec'
                        >
                      >;
                    }
                >
              >
            >;
            membersSettlementRangeContract?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'SettlementRangeContract' } & Pick<
                    SettlementRangeContract,
                    'id' | 'monthlyUnitPrice' | 'versionNo'
                  > & {
                      lowerHourPart: { __typename?: 'LowerPart' } & Pick<
                        LowerPart,
                        'deducationHourlyUnitPrice' | 'lowerHour'
                      >;
                      member?: Maybe<
                        { __typename?: 'SettlementRangeContractMemberPart' } & Pick<
                          SettlementRangeContractMemberPart,
                          | 'laborCostWithoutTax'
                          | 'memberId'
                          | 'name'
                          | 'profileImageUrl'
                          | 'roundedTotalWorkingTimeSec'
                          | 'salesAmountWithoutTax'
                          | 'totalWorkingTimeSec'
                        >
                      >;
                      upperHourPart: { __typename?: 'UpperPart' } & Pick<
                        UpperPart,
                        'excessHourlyUnitPrice' | 'upperHour'
                      >;
                    }
                >
              >
            >;
            project?: Maybe<
              { __typename?: 'Project' } & Pick<
                Project,
                | 'calendarLinkageCode'
                | 'color'
                | 'complete'
                | 'completeDateTime'
                | 'costBudgetWithoutTax'
                | 'description'
                | 'estimateTimeSec'
                | 'id'
                | 'key'
                | 'laborCost'
                | 'laborCostWithoutTax'
                | 'laborShare'
                | 'name'
                | 'priority'
                | 'registerDateTime'
                | 'salesAmountWithoutTax'
                | 'scheduledEndDateTime'
                | 'scheduledStartDateTime'
                | 'sortNoInGanttChart'
                | 'sortNoInList'
                | 'sortNoInProjectStatus'
                | 'totalScheduleTimeSec'
                | 'versionNo'
                | 'workingTimeSec'
              > & {
                  assignees: Array<
                    { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                      AssignedProjectMemberInfo,
                      'sortNo'
                    > & {
                        member: { __typename?: 'Member' } & Pick<
                          Member,
                          | 'adminRole'
                          | 'clientManagementRole'
                          | 'department'
                          | 'employeeNumber'
                          | 'id'
                          | 'laborCostManagementRole'
                          | 'mailAddress'
                          | 'memberManagementRole'
                          | 'memberRegistrationDateTime'
                          | 'memberStatus'
                          | 'name'
                          | 'organizationMemberViewRole'
                          | 'organizationReportRole'
                          | 'profileImageUrl'
                          | 'projectCreatePermissionFlg'
                          | 'projectCustomAttributePermissionFlg'
                          | 'projectDeletePermissionFlg'
                          | 'projectUpdatePermissionFlg'
                          | 'proxyEditWorkingDataRole'
                          | 'salesManagementRole'
                          | 'statusMessage'
                          | 'taskCreatePermissionFlg'
                          | 'taskCustomAttributePermissionFlg'
                          | 'taskDeletePermissionFlg'
                          | 'taskUpdatePermissionFlg'
                          | 'teamMemberViewRole'
                          | 'teamReportRole'
                          | 'versionNo'
                        >;
                      }
                  >;
                  client?: Maybe<
                    { __typename?: 'Client' } & Pick<
                      Client,
                      'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                    >
                  >;
                  createdMember?: Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >;
                  favoriteMembers: Array<
                    Maybe<
                      { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >
                    >
                  >;
                  favoriteMembersInfo: Array<
                    Maybe<
                      { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                        FavoriteProjectMemberInfo,
                        'sortNo'
                      > & {
                          member: { __typename?: 'Member' } & Pick<
                            Member,
                            | 'adminRole'
                            | 'clientManagementRole'
                            | 'department'
                            | 'employeeNumber'
                            | 'id'
                            | 'laborCostManagementRole'
                            | 'mailAddress'
                            | 'memberManagementRole'
                            | 'memberRegistrationDateTime'
                            | 'memberStatus'
                            | 'name'
                            | 'organizationMemberViewRole'
                            | 'organizationReportRole'
                            | 'profileImageUrl'
                            | 'projectCreatePermissionFlg'
                            | 'projectCustomAttributePermissionFlg'
                            | 'projectDeletePermissionFlg'
                            | 'projectUpdatePermissionFlg'
                            | 'proxyEditWorkingDataRole'
                            | 'salesManagementRole'
                            | 'statusMessage'
                            | 'taskCreatePermissionFlg'
                            | 'taskCustomAttributePermissionFlg'
                            | 'taskDeletePermissionFlg'
                            | 'taskUpdatePermissionFlg'
                            | 'teamMemberViewRole'
                            | 'teamReportRole'
                            | 'versionNo'
                          >;
                        }
                    >
                  >;
                  projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                    ProjectStatus,
                    'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                  >;
                  team: { __typename?: 'Team' } & Pick<
                    Team,
                    'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                  > & {
                      organization: { __typename?: 'Organization' } & Pick<
                        Organization,
                        | 'allowConnectThirdPartyCalendar'
                        | 'contactMailAddress'
                        | 'contractPreriod'
                        | 'id'
                        | 'imageUrl'
                        | 'ipRestrictionFlg'
                        | 'name'
                        | 'requiredTwoFactorAuth'
                        | 'suspensionFlg'
                        | 'versionNo'
                      > & {
                          plan: { __typename?: 'PlanProduct' } & Pick<
                            PlanProduct,
                            'code' | 'contractPreriod' | 'id'
                          >;
                        };
                    };
                }
            >;
            timeZone: { __typename?: 'TimeZone' } & Pick<
              TimeZone,
              'code' | 'id' | 'name' | 'offsetTime' | 'sortNo'
            >;
          }
      >
    >
  >;
};

export type TeamProjectStatusQueryVariables = Exact<{
  teamId: Scalars['String'];
}>;

export type TeamProjectStatusQuery = { __typename?: 'Query' } & {
  teamProjectStatus?: Maybe<
    Array<
      Maybe<
        { __typename?: 'ProjectStatus' } & Pick<
          ProjectStatus,
          'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
        >
      >
    >
  >;
};

export type TeamProjectsQueryVariables = Exact<{
  input?: Maybe<FetchTeamProjectsInputInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  teamId: Scalars['String'];
}>;

export type TeamProjectsQuery = { __typename?: 'Query' } & {
  teamProjects?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Project' } & Pick<
          Project,
          | 'calendarLinkageCode'
          | 'color'
          | 'complete'
          | 'completeDateTime'
          | 'costBudgetWithoutTax'
          | 'description'
          | 'estimateTimeSec'
          | 'id'
          | 'key'
          | 'laborCost'
          | 'laborCostWithoutTax'
          | 'laborShare'
          | 'name'
          | 'priority'
          | 'registerDateTime'
          | 'salesAmountWithoutTax'
          | 'scheduledEndDateTime'
          | 'scheduledStartDateTime'
          | 'sortNoInGanttChart'
          | 'sortNoInList'
          | 'sortNoInProjectStatus'
          | 'totalScheduleTimeSec'
          | 'versionNo'
          | 'workingTimeSec'
        > & {
            assignees: Array<
              { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                AssignedProjectMemberInfo,
                'sortNo'
              > & {
                  member: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                }
            >;
            client?: Maybe<
              { __typename?: 'Client' } & Pick<
                Client,
                'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
              >
            >;
            createdMember?: Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >;
            favoriteMembers: Array<
              Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >
            >;
            favoriteMembersInfo: Array<
              Maybe<
                { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                  FavoriteProjectMemberInfo,
                  'sortNo'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >
            >;
            projectStatus: { __typename?: 'ProjectStatus' } & Pick<
              ProjectStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
            >;
            team: { __typename?: 'Team' } & Pick<
              Team,
              'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
            > & {
                organization: { __typename?: 'Organization' } & Pick<
                  Organization,
                  | 'allowConnectThirdPartyCalendar'
                  | 'contactMailAddress'
                  | 'contractPreriod'
                  | 'id'
                  | 'imageUrl'
                  | 'ipRestrictionFlg'
                  | 'name'
                  | 'requiredTwoFactorAuth'
                  | 'suspensionFlg'
                  | 'versionNo'
                > & {
                    plan: { __typename?: 'PlanProduct' } & Pick<
                      PlanProduct,
                      'code' | 'contractPreriod' | 'id'
                    >;
                  };
              };
          }
      >
    >
  >;
};

export type TeamProjectsForSelectorQueryVariables = Exact<{
  input?: Maybe<FetchTeamProjectsInputInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  teamId: Scalars['String'];
}>;

export type TeamProjectsForSelectorQuery = { __typename?: 'Query' } & {
  teamProjectsForSelector?: Maybe<
    Array<
      Maybe<
        { __typename?: 'ProjectForSelector' } & Pick<
          ProjectForSelector,
          'clientId' | 'clientName' | 'complete' | 'id' | 'name' | 'teamId' | 'teamtName'
        >
      >
    >
  >;
};

export type TeamReportQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type TeamReportQuery = { __typename?: 'Query' } & {
  teamReport?: Maybe<
    { __typename?: 'TeamReport' } & Pick<
      TeamReport,
      | 'end'
      | 'id'
      | 'includeWorkingData'
      | 'reportType'
      | 'requestDateTime'
      | 'start'
      | 'status'
      | 'timeZoneOffset'
      | 'url'
      | 'versionNo'
    > & {
        requestMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
        team: { __typename?: 'Team' } & Pick<
          Team,
          'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
        > & {
            organization: { __typename?: 'Organization' } & Pick<
              Organization,
              | 'allowConnectThirdPartyCalendar'
              | 'contactMailAddress'
              | 'contractPreriod'
              | 'id'
              | 'imageUrl'
              | 'ipRestrictionFlg'
              | 'name'
              | 'requiredTwoFactorAuth'
              | 'suspensionFlg'
              | 'versionNo'
            > & {
                plan: { __typename?: 'PlanProduct' } & Pick<
                  PlanProduct,
                  'code' | 'contractPreriod' | 'id'
                >;
              };
          };
      }
  >;
};

export type TeamReportsQueryVariables = Exact<{
  teamId: Scalars['ID'];
}>;

export type TeamReportsQuery = { __typename?: 'Query' } & {
  teamReports?: Maybe<
    Array<
      Maybe<
        { __typename?: 'TeamReport' } & Pick<
          TeamReport,
          | 'end'
          | 'id'
          | 'includeWorkingData'
          | 'reportType'
          | 'requestDateTime'
          | 'start'
          | 'status'
          | 'timeZoneOffset'
          | 'url'
          | 'versionNo'
        > & {
            requestMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
            team: { __typename?: 'Team' } & Pick<
              Team,
              'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
            > & {
                organization: { __typename?: 'Organization' } & Pick<
                  Organization,
                  | 'allowConnectThirdPartyCalendar'
                  | 'contactMailAddress'
                  | 'contractPreriod'
                  | 'id'
                  | 'imageUrl'
                  | 'ipRestrictionFlg'
                  | 'name'
                  | 'requiredTwoFactorAuth'
                  | 'suspensionFlg'
                  | 'versionNo'
                > & {
                    plan: { __typename?: 'PlanProduct' } & Pick<
                      PlanProduct,
                      'code' | 'contractPreriod' | 'id'
                    >;
                  };
              };
          }
      >
    >
  >;
};

export type TeamSunburstGraphDataQueryVariables = Exact<{
  input: TeamtGraphDataInputInput;
}>;

export type TeamSunburstGraphDataQuery = { __typename?: 'Query' } & {
  teamSunburstGraphData?: Maybe<
    Array<
      Maybe<
        { __typename?: 'TeamSunburstGraphData' } & Pick<
          TeamSunburstGraphData,
          'color' | 'name' | 'percent' | 'reportType' | 'teamId' | 'workingTimeSec'
        >
      >
    >
  >;
};

export type TimeZoneQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type TimeZoneQuery = { __typename?: 'Query' } & {
  timeZone?: Maybe<
    { __typename?: 'TimeZone' } & Pick<TimeZone, 'code' | 'id' | 'name' | 'offsetTime' | 'sortNo'>
  >;
};

export type TimeZonesQueryVariables = Exact<{ [key: string]: never }>;

export type TimeZonesQuery = { __typename?: 'Query' } & {
  timeZones?: Maybe<
    Array<
      Maybe<
        { __typename?: 'TimeZone' } & Pick<
          TimeZone,
          'code' | 'id' | 'name' | 'offsetTime' | 'sortNo'
        >
      >
    >
  >;
};

export type TwoFactorAuthEnabledQueryVariables = Exact<{
  memberId: Scalars['String'];
}>;

export type TwoFactorAuthEnabledQuery = { __typename?: 'Query' } & Pick<
  Query,
  'twoFactorAuthEnabled'
>;

export type TwoFactorAuthSettingQueryVariables = Exact<{ [key: string]: never }>;

export type TwoFactorAuthSettingQuery = { __typename?: 'Query' } & {
  twoFactorAuthSetting?: Maybe<
    { __typename?: 'TwoFactorAuthSetting' } & Pick<
      TwoFactorAuthSetting,
      'enabled' | 'recoveryCodes'
    >
  >;
};

export type UnreadAnnouncementCountQueryVariables = Exact<{ [key: string]: never }>;

export type UnreadAnnouncementCountQuery = { __typename?: 'Query' } & Pick<
  Query,
  'unreadAnnouncementCount'
>;

export type UnreadNotificationCountQueryVariables = Exact<{ [key: string]: never }>;

export type UnreadNotificationCountQuery = { __typename?: 'Query' } & Pick<
  Query,
  'unreadNotificationCount'
>;

export type UpcomingInvoiceQueryVariables = Exact<{ [key: string]: never }>;

export type UpcomingInvoiceQuery = { __typename?: 'Query' } & {
  upcomingInvoice?: Maybe<
    { __typename?: 'Invoice' } & Pick<
      Invoice,
      | 'billingDateTime'
      | 'billingNumber'
      | 'discount'
      | 'invoicePdfUrl'
      | 'paid'
      | 'periodEndDateTime'
      | 'periodStartDateTime'
      | 'quantity'
      | 'receiptUrl'
      | 'subtotal'
      | 'tax'
      | 'title'
      | 'total'
      | 'unitPrice'
    >
  >;
};

export type VersionQueryVariables = Exact<{ [key: string]: never }>;

export type VersionQuery = { __typename?: 'Query' } & {
  version?: Maybe<
    { __typename?: 'Version' } & {
      android: { __typename?: 'PlatformVersion' } & Pick<
        PlatformVersion,
        'forceUpdateVersion' | 'latestVersion'
      >;
      ios: { __typename?: 'PlatformVersion' } & Pick<
        PlatformVersion,
        'forceUpdateVersion' | 'latestVersion'
      >;
    }
  >;
};

export type WorkedTasksQueryVariables = Exact<{
  input?: Maybe<SearchTaskInputInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;

export type WorkedTasksQuery = { __typename?: 'Query' } & {
  workedTasks?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Task' } & Pick<
          Task,
          | 'calendarLinkageCode'
          | 'completeDateTime'
          | 'description'
          | 'estimateTimeSec'
          | 'estimatedRemainingTimeSec'
          | 'id'
          | 'laborCost'
          | 'priority'
          | 'progressDeviationRate'
          | 'progressRate'
          | 'registerDateTime'
          | 'remainingTimeSec'
          | 'scheduleWorkingTimeSec'
          | 'scheduledEndDateTime'
          | 'scheduledStartDateTime'
          | 'sortNoInGanttChart'
          | 'sortNoInList'
          | 'sortNoInMyTask'
          | 'sortNoInTaskStatus'
          | 'title'
          | 'versionNo'
          | 'workingTimeSec'
        > & {
            assignees: Array<
              { __typename?: 'AssignedTaskMemberInfo' } & Pick<AssignedTaskMemberInfo, 'sortNo'> & {
                  member: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                }
            >;
            asssignee?: Maybe<
              { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >
            >;
            createdMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
            favoriteMembers: Array<
              Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >
            >;
            favoriteMembersInfo: Array<
              Maybe<
                { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                  FavoriteTaskMemberInfo,
                  'sortNo'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >
            >;
            project: { __typename?: 'Project' } & Pick<
              Project,
              | 'calendarLinkageCode'
              | 'color'
              | 'complete'
              | 'completeDateTime'
              | 'costBudgetWithoutTax'
              | 'description'
              | 'estimateTimeSec'
              | 'id'
              | 'key'
              | 'laborCost'
              | 'laborCostWithoutTax'
              | 'laborShare'
              | 'name'
              | 'priority'
              | 'registerDateTime'
              | 'salesAmountWithoutTax'
              | 'scheduledEndDateTime'
              | 'scheduledStartDateTime'
              | 'sortNoInGanttChart'
              | 'sortNoInList'
              | 'sortNoInProjectStatus'
              | 'totalScheduleTimeSec'
              | 'versionNo'
              | 'workingTimeSec'
            > & {
                assignees: Array<
                  { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                    AssignedProjectMemberInfo,
                    'sortNo'
                  >
                >;
                client?: Maybe<
                  { __typename?: 'Client' } & Pick<
                    Client,
                    'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                  >
                >;
                createdMember?: Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >;
                favoriteMembers: Array<
                  Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >
                >;
                favoriteMembersInfo: Array<
                  Maybe<
                    { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                      FavoriteProjectMemberInfo,
                      'sortNo'
                    >
                  >
                >;
                projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                  ProjectStatus,
                  'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                >;
                team: { __typename?: 'Team' } & Pick<
                  Team,
                  'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                > & {
                    organization: { __typename?: 'Organization' } & Pick<
                      Organization,
                      | 'allowConnectThirdPartyCalendar'
                      | 'contactMailAddress'
                      | 'contractPreriod'
                      | 'id'
                      | 'imageUrl'
                      | 'ipRestrictionFlg'
                      | 'name'
                      | 'requiredTwoFactorAuth'
                      | 'suspensionFlg'
                      | 'versionNo'
                    > & {
                        plan: { __typename?: 'PlanProduct' } & Pick<
                          PlanProduct,
                          'code' | 'contractPreriod' | 'id'
                        >;
                      };
                  };
              };
            taskStatus: { __typename?: 'TaskStatus' } & Pick<
              TaskStatus,
              'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
            > & {
                project: { __typename?: 'Project' } & Pick<
                  Project,
                  | 'calendarLinkageCode'
                  | 'color'
                  | 'complete'
                  | 'completeDateTime'
                  | 'costBudgetWithoutTax'
                  | 'description'
                  | 'estimateTimeSec'
                  | 'id'
                  | 'key'
                  | 'laborCost'
                  | 'laborCostWithoutTax'
                  | 'laborShare'
                  | 'name'
                  | 'priority'
                  | 'registerDateTime'
                  | 'salesAmountWithoutTax'
                  | 'scheduledEndDateTime'
                  | 'scheduledStartDateTime'
                  | 'sortNoInGanttChart'
                  | 'sortNoInList'
                  | 'sortNoInProjectStatus'
                  | 'totalScheduleTimeSec'
                  | 'versionNo'
                  | 'workingTimeSec'
                >;
              };
            workingMembers: Array<
              Maybe<
                { __typename?: 'WorkingMemberInfo' } & Pick<
                  WorkingMemberInfo,
                  'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >
            >;
          }
      >
    >
  >;
};

export type WorkingHistoriesSpecifyTermQueryVariables = Exact<{
  input: WorkingHistoriesSpecifyTermInputInput;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;

export type WorkingHistoriesSpecifyTermQuery = { __typename?: 'Query' } & {
  workingHistoriesSpecifyTerm?: Maybe<
    Array<
      Maybe<
        { __typename?: 'WorkingHistory' } & Pick<
          WorkingHistory,
          'end' | 'id' | 'memo' | 'start' | 'versionNo' | 'workingTimeSec'
        > & {
            calendarEvent?: Maybe<
              { __typename?: 'CalendarEvent' } & Pick<
                CalendarEvent,
                | 'calendarType'
                | 'clientId'
                | 'clientName'
                | 'endDateTime'
                | 'eventName'
                | 'id'
                | 'laborCost'
                | 'personalTeam'
                | 'projectColor'
                | 'projectId'
                | 'projectName'
                | 'startDateTime'
                | 'taskId'
                | 'taskTitle'
                | 'teamId'
                | 'teamName'
                | 'thirdPartyCalendarEventId'
                | 'versionNo'
                | 'workingTimeSec'
              > & {
                  thirdPartyCalendarInfoList: Array<
                    Maybe<
                      { __typename?: 'ThirdPartyCalendarInfo' } & Pick<
                        ThirdPartyCalendarInfo,
                        'thirdPartyCalendarId'
                      > & {
                          member: { __typename?: 'Member' } & Pick<
                            Member,
                            | 'adminRole'
                            | 'clientManagementRole'
                            | 'department'
                            | 'employeeNumber'
                            | 'id'
                            | 'laborCostManagementRole'
                            | 'mailAddress'
                            | 'memberManagementRole'
                            | 'memberRegistrationDateTime'
                            | 'memberStatus'
                            | 'name'
                            | 'organizationMemberViewRole'
                            | 'organizationReportRole'
                            | 'profileImageUrl'
                            | 'projectCreatePermissionFlg'
                            | 'projectCustomAttributePermissionFlg'
                            | 'projectDeletePermissionFlg'
                            | 'projectUpdatePermissionFlg'
                            | 'proxyEditWorkingDataRole'
                            | 'salesManagementRole'
                            | 'statusMessage'
                            | 'taskCreatePermissionFlg'
                            | 'taskCustomAttributePermissionFlg'
                            | 'taskDeletePermissionFlg'
                            | 'taskUpdatePermissionFlg'
                            | 'teamMemberViewRole'
                            | 'teamReportRole'
                            | 'versionNo'
                          >;
                        }
                    >
                  >;
                }
            >;
            closingProcessInfo: { __typename?: 'ClosingProcessPart' } & Pick<
              ClosingProcessPart,
              'closed' | 'targetDateTime'
            >;
            task?: Maybe<
              { __typename?: 'Task' } & Pick<
                Task,
                | 'calendarLinkageCode'
                | 'completeDateTime'
                | 'description'
                | 'estimateTimeSec'
                | 'estimatedRemainingTimeSec'
                | 'id'
                | 'laborCost'
                | 'priority'
                | 'progressDeviationRate'
                | 'progressRate'
                | 'registerDateTime'
                | 'remainingTimeSec'
                | 'scheduleWorkingTimeSec'
                | 'scheduledEndDateTime'
                | 'scheduledStartDateTime'
                | 'sortNoInGanttChart'
                | 'sortNoInList'
                | 'sortNoInMyTask'
                | 'sortNoInTaskStatus'
                | 'title'
                | 'versionNo'
                | 'workingTimeSec'
              > & {
                  assignees: Array<
                    { __typename?: 'AssignedTaskMemberInfo' } & Pick<
                      AssignedTaskMemberInfo,
                      'sortNo'
                    > & {
                        member: { __typename?: 'Member' } & Pick<
                          Member,
                          | 'adminRole'
                          | 'clientManagementRole'
                          | 'department'
                          | 'employeeNumber'
                          | 'id'
                          | 'laborCostManagementRole'
                          | 'mailAddress'
                          | 'memberManagementRole'
                          | 'memberRegistrationDateTime'
                          | 'memberStatus'
                          | 'name'
                          | 'organizationMemberViewRole'
                          | 'organizationReportRole'
                          | 'profileImageUrl'
                          | 'projectCreatePermissionFlg'
                          | 'projectCustomAttributePermissionFlg'
                          | 'projectDeletePermissionFlg'
                          | 'projectUpdatePermissionFlg'
                          | 'proxyEditWorkingDataRole'
                          | 'salesManagementRole'
                          | 'statusMessage'
                          | 'taskCreatePermissionFlg'
                          | 'taskCustomAttributePermissionFlg'
                          | 'taskDeletePermissionFlg'
                          | 'taskUpdatePermissionFlg'
                          | 'teamMemberViewRole'
                          | 'teamReportRole'
                          | 'versionNo'
                        >;
                      }
                  >;
                  asssignee?: Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >;
                  createdMember: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                  favoriteMembers: Array<
                    Maybe<
                      { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >
                    >
                  >;
                  favoriteMembersInfo: Array<
                    Maybe<
                      { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                        FavoriteTaskMemberInfo,
                        'sortNo'
                      > & {
                          member: { __typename?: 'Member' } & Pick<
                            Member,
                            | 'adminRole'
                            | 'clientManagementRole'
                            | 'department'
                            | 'employeeNumber'
                            | 'id'
                            | 'laborCostManagementRole'
                            | 'mailAddress'
                            | 'memberManagementRole'
                            | 'memberRegistrationDateTime'
                            | 'memberStatus'
                            | 'name'
                            | 'organizationMemberViewRole'
                            | 'organizationReportRole'
                            | 'profileImageUrl'
                            | 'projectCreatePermissionFlg'
                            | 'projectCustomAttributePermissionFlg'
                            | 'projectDeletePermissionFlg'
                            | 'projectUpdatePermissionFlg'
                            | 'proxyEditWorkingDataRole'
                            | 'salesManagementRole'
                            | 'statusMessage'
                            | 'taskCreatePermissionFlg'
                            | 'taskCustomAttributePermissionFlg'
                            | 'taskDeletePermissionFlg'
                            | 'taskUpdatePermissionFlg'
                            | 'teamMemberViewRole'
                            | 'teamReportRole'
                            | 'versionNo'
                          >;
                        }
                    >
                  >;
                  project: { __typename?: 'Project' } & Pick<
                    Project,
                    | 'calendarLinkageCode'
                    | 'color'
                    | 'complete'
                    | 'completeDateTime'
                    | 'costBudgetWithoutTax'
                    | 'description'
                    | 'estimateTimeSec'
                    | 'id'
                    | 'key'
                    | 'laborCost'
                    | 'laborCostWithoutTax'
                    | 'laborShare'
                    | 'name'
                    | 'priority'
                    | 'registerDateTime'
                    | 'salesAmountWithoutTax'
                    | 'scheduledEndDateTime'
                    | 'scheduledStartDateTime'
                    | 'sortNoInGanttChart'
                    | 'sortNoInList'
                    | 'sortNoInProjectStatus'
                    | 'totalScheduleTimeSec'
                    | 'versionNo'
                    | 'workingTimeSec'
                  > & {
                      assignees: Array<
                        { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                          AssignedProjectMemberInfo,
                          'sortNo'
                        >
                      >;
                      client?: Maybe<
                        { __typename?: 'Client' } & Pick<
                          Client,
                          'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                        >
                      >;
                      createdMember?: Maybe<
                        { __typename?: 'Member' } & Pick<
                          Member,
                          | 'adminRole'
                          | 'clientManagementRole'
                          | 'department'
                          | 'employeeNumber'
                          | 'id'
                          | 'laborCostManagementRole'
                          | 'mailAddress'
                          | 'memberManagementRole'
                          | 'memberRegistrationDateTime'
                          | 'memberStatus'
                          | 'name'
                          | 'organizationMemberViewRole'
                          | 'organizationReportRole'
                          | 'profileImageUrl'
                          | 'projectCreatePermissionFlg'
                          | 'projectCustomAttributePermissionFlg'
                          | 'projectDeletePermissionFlg'
                          | 'projectUpdatePermissionFlg'
                          | 'proxyEditWorkingDataRole'
                          | 'salesManagementRole'
                          | 'statusMessage'
                          | 'taskCreatePermissionFlg'
                          | 'taskCustomAttributePermissionFlg'
                          | 'taskDeletePermissionFlg'
                          | 'taskUpdatePermissionFlg'
                          | 'teamMemberViewRole'
                          | 'teamReportRole'
                          | 'versionNo'
                        >
                      >;
                      favoriteMembers: Array<
                        Maybe<
                          { __typename?: 'Member' } & Pick<
                            Member,
                            | 'adminRole'
                            | 'clientManagementRole'
                            | 'department'
                            | 'employeeNumber'
                            | 'id'
                            | 'laborCostManagementRole'
                            | 'mailAddress'
                            | 'memberManagementRole'
                            | 'memberRegistrationDateTime'
                            | 'memberStatus'
                            | 'name'
                            | 'organizationMemberViewRole'
                            | 'organizationReportRole'
                            | 'profileImageUrl'
                            | 'projectCreatePermissionFlg'
                            | 'projectCustomAttributePermissionFlg'
                            | 'projectDeletePermissionFlg'
                            | 'projectUpdatePermissionFlg'
                            | 'proxyEditWorkingDataRole'
                            | 'salesManagementRole'
                            | 'statusMessage'
                            | 'taskCreatePermissionFlg'
                            | 'taskCustomAttributePermissionFlg'
                            | 'taskDeletePermissionFlg'
                            | 'taskUpdatePermissionFlg'
                            | 'teamMemberViewRole'
                            | 'teamReportRole'
                            | 'versionNo'
                          >
                        >
                      >;
                      favoriteMembersInfo: Array<
                        Maybe<
                          { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                            FavoriteProjectMemberInfo,
                            'sortNo'
                          >
                        >
                      >;
                      projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                        ProjectStatus,
                        'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                      >;
                      team: { __typename?: 'Team' } & Pick<
                        Team,
                        'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                      > & {
                          organization: { __typename?: 'Organization' } & Pick<
                            Organization,
                            | 'allowConnectThirdPartyCalendar'
                            | 'contactMailAddress'
                            | 'contractPreriod'
                            | 'id'
                            | 'imageUrl'
                            | 'ipRestrictionFlg'
                            | 'name'
                            | 'requiredTwoFactorAuth'
                            | 'suspensionFlg'
                            | 'versionNo'
                          > & {
                              plan: { __typename?: 'PlanProduct' } & Pick<
                                PlanProduct,
                                'code' | 'contractPreriod' | 'id'
                              >;
                            };
                        };
                    };
                  taskStatus: { __typename?: 'TaskStatus' } & Pick<
                    TaskStatus,
                    'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
                  > & {
                      project: { __typename?: 'Project' } & Pick<
                        Project,
                        | 'calendarLinkageCode'
                        | 'color'
                        | 'complete'
                        | 'completeDateTime'
                        | 'costBudgetWithoutTax'
                        | 'description'
                        | 'estimateTimeSec'
                        | 'id'
                        | 'key'
                        | 'laborCost'
                        | 'laborCostWithoutTax'
                        | 'laborShare'
                        | 'name'
                        | 'priority'
                        | 'registerDateTime'
                        | 'salesAmountWithoutTax'
                        | 'scheduledEndDateTime'
                        | 'scheduledStartDateTime'
                        | 'sortNoInGanttChart'
                        | 'sortNoInList'
                        | 'sortNoInProjectStatus'
                        | 'totalScheduleTimeSec'
                        | 'versionNo'
                        | 'workingTimeSec'
                      >;
                    };
                  workingMembers: Array<
                    Maybe<
                      { __typename?: 'WorkingMemberInfo' } & Pick<
                        WorkingMemberInfo,
                        'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                      > & {
                          member: { __typename?: 'Member' } & Pick<
                            Member,
                            | 'adminRole'
                            | 'clientManagementRole'
                            | 'department'
                            | 'employeeNumber'
                            | 'id'
                            | 'laborCostManagementRole'
                            | 'mailAddress'
                            | 'memberManagementRole'
                            | 'memberRegistrationDateTime'
                            | 'memberStatus'
                            | 'name'
                            | 'organizationMemberViewRole'
                            | 'organizationReportRole'
                            | 'profileImageUrl'
                            | 'projectCreatePermissionFlg'
                            | 'projectCustomAttributePermissionFlg'
                            | 'projectDeletePermissionFlg'
                            | 'projectUpdatePermissionFlg'
                            | 'proxyEditWorkingDataRole'
                            | 'salesManagementRole'
                            | 'statusMessage'
                            | 'taskCreatePermissionFlg'
                            | 'taskCustomAttributePermissionFlg'
                            | 'taskDeletePermissionFlg'
                            | 'taskUpdatePermissionFlg'
                            | 'teamMemberViewRole'
                            | 'teamReportRole'
                            | 'versionNo'
                          >;
                        }
                    >
                  >;
                }
            >;
            workingMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
          }
      >
    >
  >;
};

export type WorkingHistoriesSpecifyTermForCalendarQueryVariables = Exact<{
  input: WorkingHistoriesSpecifyTermForCalendarInputInput;
}>;

export type WorkingHistoriesSpecifyTermForCalendarQuery = { __typename?: 'Query' } & {
  workingHistoriesSpecifyTermForCalendar?: Maybe<
    Array<
      Maybe<
        { __typename?: 'WorkingHistoryForCalendar' } & Pick<
          WorkingHistoryForCalendar,
          'memberId'
        > & {
            workingHistories: Array<
              Maybe<
                { __typename?: 'CalendarWorkingHistory' } & Pick<
                  CalendarWorkingHistory,
                  'end' | 'id' | 'memo' | 'start' | 'versionNo' | 'workingTimeSec'
                > & {
                    calendarEvent?: Maybe<
                      { __typename?: 'WorkingHistoryCalendarEventPart' } & Pick<
                        WorkingHistoryCalendarEventPart,
                        | 'calendarType'
                        | 'clientId'
                        | 'clientName'
                        | 'endDateTime'
                        | 'eventName'
                        | 'id'
                        | 'personalTeam'
                        | 'projectColor'
                        | 'projectId'
                        | 'projectName'
                        | 'startDateTime'
                        | 'taskId'
                        | 'taskTitle'
                        | 'teamId'
                        | 'teamName'
                        | 'thirdPartyCalendarEventId'
                        | 'versionNo'
                      >
                    >;
                    task?: Maybe<
                      { __typename?: 'WorkingHistoryTaskPart' } & Pick<
                        WorkingHistoryTaskPart,
                        'completeDateTime' | 'id' | 'title' | 'versionNo'
                      > & {
                          project: { __typename?: 'WorkingHistoryProjectPart' } & Pick<
                            WorkingHistoryProjectPart,
                            'color' | 'complete' | 'id' | 'name'
                          > & {
                              client?: Maybe<
                                { __typename?: 'WorkingHistoryClientPart' } & Pick<
                                  WorkingHistoryClientPart,
                                  'id' | 'name'
                                >
                              >;
                              team: { __typename?: 'WorkingHistoryTeamPart' } & Pick<
                                WorkingHistoryTeamPart,
                                'id' | 'name' | 'personalTeam'
                              >;
                            };
                          workingMembers: Array<
                            Maybe<
                              { __typename?: 'WorkingHistoryWorkingMemberInfoPart' } & {
                                member: { __typename?: 'WorkingHistoryMemberPart' } & Pick<
                                  WorkingHistoryMemberPart,
                                  'id' | 'name' | 'profileImageUrl'
                                >;
                              }
                            >
                          >;
                        }
                    >;
                    workingMember: { __typename?: 'WorkingHistoryMemberPart' } & Pick<
                      WorkingHistoryMemberPart,
                      'id' | 'name' | 'profileImageUrl'
                    >;
                  }
              >
            >;
          }
      >
    >
  >;
};

export type WorkingHistoriesSpecifyTermForSummaryQueryVariables = Exact<{
  input: WorkingHistoriesSpecifyTermInputInput;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;

export type WorkingHistoriesSpecifyTermForSummaryQuery = { __typename?: 'Query' } & {
  workingHistoriesSpecifyTermForSummary?: Maybe<
    Array<
      Maybe<
        { __typename?: 'WorkingHistoryForSummary' } & Pick<
          WorkingHistoryForSummary,
          'end' | 'id' | 'memo' | 'start' | 'versionNo' | 'workingTimeSec'
        > & {
            calendarEvent?: Maybe<
              { __typename?: 'WorkingHistoryForSummaryCalendarEventPart' } & Pick<
                WorkingHistoryForSummaryCalendarEventPart,
                | 'clientId'
                | 'clientName'
                | 'eventName'
                | 'id'
                | 'projectId'
                | 'projectName'
                | 'teamId'
              >
            >;
            closingProcessInfo: {
              __typename?: 'WorkingHistoryForSummaryClosingProcessPart';
            } & Pick<WorkingHistoryForSummaryClosingProcessPart, 'closed' | 'targetDateTime'>;
            task?: Maybe<
              { __typename?: 'WorkingHistoryForSummaryTaskPart' } & Pick<
                WorkingHistoryForSummaryTaskPart,
                'id' | 'title' | 'versionNo'
              > & {
                  project: { __typename?: 'WorkingHistoryForSummaryProjectPart' } & Pick<
                    WorkingHistoryForSummaryProjectPart,
                    'id' | 'name'
                  > & {
                      client?: Maybe<
                        { __typename?: 'WorkingHistoryForSummaryClientPart' } & Pick<
                          WorkingHistoryForSummaryClientPart,
                          'id' | 'name'
                        >
                      >;
                      team: { __typename?: 'WorkingHistoryForSummaryTeamPart' } & Pick<
                        WorkingHistoryForSummaryTeamPart,
                        'id' | 'personalTeam'
                      >;
                    };
                }
            >;
            workingMember: { __typename?: 'WorkingHistoryForSummaryMemberPart' } & Pick<
              WorkingHistoryForSummaryMemberPart,
              'id' | 'name' | 'profileImageUrl'
            >;
          }
      >
    >
  >;
};

export type WorkingHistoryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type WorkingHistoryQuery = { __typename?: 'Query' } & {
  workingHistory?: Maybe<
    { __typename?: 'WorkingHistory' } & Pick<
      WorkingHistory,
      'end' | 'id' | 'memo' | 'start' | 'versionNo' | 'workingTimeSec'
    > & {
        calendarEvent?: Maybe<
          { __typename?: 'CalendarEvent' } & Pick<
            CalendarEvent,
            | 'calendarType'
            | 'clientId'
            | 'clientName'
            | 'endDateTime'
            | 'eventName'
            | 'id'
            | 'laborCost'
            | 'personalTeam'
            | 'projectColor'
            | 'projectId'
            | 'projectName'
            | 'startDateTime'
            | 'taskId'
            | 'taskTitle'
            | 'teamId'
            | 'teamName'
            | 'thirdPartyCalendarEventId'
            | 'versionNo'
            | 'workingTimeSec'
          > & {
              thirdPartyCalendarInfoList: Array<
                Maybe<
                  { __typename?: 'ThirdPartyCalendarInfo' } & Pick<
                    ThirdPartyCalendarInfo,
                    'thirdPartyCalendarId'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >
              >;
            }
        >;
        closingProcessInfo: { __typename?: 'ClosingProcessPart' } & Pick<
          ClosingProcessPart,
          'closed' | 'targetDateTime'
        >;
        task?: Maybe<
          { __typename?: 'Task' } & Pick<
            Task,
            | 'calendarLinkageCode'
            | 'completeDateTime'
            | 'description'
            | 'estimateTimeSec'
            | 'estimatedRemainingTimeSec'
            | 'id'
            | 'laborCost'
            | 'priority'
            | 'progressDeviationRate'
            | 'progressRate'
            | 'registerDateTime'
            | 'remainingTimeSec'
            | 'scheduleWorkingTimeSec'
            | 'scheduledEndDateTime'
            | 'scheduledStartDateTime'
            | 'sortNoInGanttChart'
            | 'sortNoInList'
            | 'sortNoInMyTask'
            | 'sortNoInTaskStatus'
            | 'title'
            | 'versionNo'
            | 'workingTimeSec'
          > & {
              assignees: Array<
                { __typename?: 'AssignedTaskMemberInfo' } & Pick<
                  AssignedTaskMemberInfo,
                  'sortNo'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >;
              asssignee?: Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >;
              createdMember: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
              favoriteMembers: Array<
                Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >
              >;
              favoriteMembersInfo: Array<
                Maybe<
                  { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                    FavoriteTaskMemberInfo,
                    'sortNo'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >
              >;
              project: { __typename?: 'Project' } & Pick<
                Project,
                | 'calendarLinkageCode'
                | 'color'
                | 'complete'
                | 'completeDateTime'
                | 'costBudgetWithoutTax'
                | 'description'
                | 'estimateTimeSec'
                | 'id'
                | 'key'
                | 'laborCost'
                | 'laborCostWithoutTax'
                | 'laborShare'
                | 'name'
                | 'priority'
                | 'registerDateTime'
                | 'salesAmountWithoutTax'
                | 'scheduledEndDateTime'
                | 'scheduledStartDateTime'
                | 'sortNoInGanttChart'
                | 'sortNoInList'
                | 'sortNoInProjectStatus'
                | 'totalScheduleTimeSec'
                | 'versionNo'
                | 'workingTimeSec'
              > & {
                  assignees: Array<
                    { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                      AssignedProjectMemberInfo,
                      'sortNo'
                    >
                  >;
                  client?: Maybe<
                    { __typename?: 'Client' } & Pick<
                      Client,
                      'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                    >
                  >;
                  createdMember?: Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >;
                  favoriteMembers: Array<
                    Maybe<
                      { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >
                    >
                  >;
                  favoriteMembersInfo: Array<
                    Maybe<
                      { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                        FavoriteProjectMemberInfo,
                        'sortNo'
                      >
                    >
                  >;
                  projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                    ProjectStatus,
                    'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                  >;
                  team: { __typename?: 'Team' } & Pick<
                    Team,
                    'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                  > & {
                      organization: { __typename?: 'Organization' } & Pick<
                        Organization,
                        | 'allowConnectThirdPartyCalendar'
                        | 'contactMailAddress'
                        | 'contractPreriod'
                        | 'id'
                        | 'imageUrl'
                        | 'ipRestrictionFlg'
                        | 'name'
                        | 'requiredTwoFactorAuth'
                        | 'suspensionFlg'
                        | 'versionNo'
                      > & {
                          plan: { __typename?: 'PlanProduct' } & Pick<
                            PlanProduct,
                            'code' | 'contractPreriod' | 'id'
                          >;
                        };
                    };
                };
              taskStatus: { __typename?: 'TaskStatus' } & Pick<
                TaskStatus,
                'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
              > & {
                  project: { __typename?: 'Project' } & Pick<
                    Project,
                    | 'calendarLinkageCode'
                    | 'color'
                    | 'complete'
                    | 'completeDateTime'
                    | 'costBudgetWithoutTax'
                    | 'description'
                    | 'estimateTimeSec'
                    | 'id'
                    | 'key'
                    | 'laborCost'
                    | 'laborCostWithoutTax'
                    | 'laborShare'
                    | 'name'
                    | 'priority'
                    | 'registerDateTime'
                    | 'salesAmountWithoutTax'
                    | 'scheduledEndDateTime'
                    | 'scheduledStartDateTime'
                    | 'sortNoInGanttChart'
                    | 'sortNoInList'
                    | 'sortNoInProjectStatus'
                    | 'totalScheduleTimeSec'
                    | 'versionNo'
                    | 'workingTimeSec'
                  >;
                };
              workingMembers: Array<
                Maybe<
                  { __typename?: 'WorkingMemberInfo' } & Pick<
                    WorkingMemberInfo,
                    'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >
              >;
            }
        >;
        workingMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
      }
  >;
};

export type WorkingHistoryForSummaryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type WorkingHistoryForSummaryQuery = { __typename?: 'Query' } & {
  workingHistoryForSummary?: Maybe<
    { __typename?: 'WorkingHistoryForSummary' } & Pick<
      WorkingHistoryForSummary,
      'end' | 'id' | 'memo' | 'start' | 'versionNo' | 'workingTimeSec'
    > & {
        calendarEvent?: Maybe<
          { __typename?: 'WorkingHistoryForSummaryCalendarEventPart' } & Pick<
            WorkingHistoryForSummaryCalendarEventPart,
            'clientId' | 'clientName' | 'eventName' | 'id' | 'projectId' | 'projectName' | 'teamId'
          >
        >;
        closingProcessInfo: { __typename?: 'WorkingHistoryForSummaryClosingProcessPart' } & Pick<
          WorkingHistoryForSummaryClosingProcessPart,
          'closed' | 'targetDateTime'
        >;
        task?: Maybe<
          { __typename?: 'WorkingHistoryForSummaryTaskPart' } & Pick<
            WorkingHistoryForSummaryTaskPart,
            'id' | 'title' | 'versionNo'
          > & {
              project: { __typename?: 'WorkingHistoryForSummaryProjectPart' } & Pick<
                WorkingHistoryForSummaryProjectPart,
                'id' | 'name'
              > & {
                  client?: Maybe<
                    { __typename?: 'WorkingHistoryForSummaryClientPart' } & Pick<
                      WorkingHistoryForSummaryClientPart,
                      'id' | 'name'
                    >
                  >;
                  team: { __typename?: 'WorkingHistoryForSummaryTeamPart' } & Pick<
                    WorkingHistoryForSummaryTeamPart,
                    'id' | 'personalTeam'
                  >;
                };
            }
        >;
        workingMember: { __typename?: 'WorkingHistoryForSummaryMemberPart' } & Pick<
          WorkingHistoryForSummaryMemberPart,
          'id' | 'name' | 'profileImageUrl'
        >;
      }
  >;
};

export type WorkingScheduleQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type WorkingScheduleQuery = { __typename?: 'Query' } & {
  workingSchedule?: Maybe<
    { __typename?: 'WorkingSchedule' } & Pick<
      WorkingSchedule,
      'end' | 'id' | 'memo' | 'start' | 'versionNo' | 'workingTimeSec'
    > & {
        calendarEvent?: Maybe<
          { __typename?: 'CalendarEvent' } & Pick<
            CalendarEvent,
            | 'calendarType'
            | 'clientId'
            | 'clientName'
            | 'endDateTime'
            | 'eventName'
            | 'id'
            | 'laborCost'
            | 'personalTeam'
            | 'projectColor'
            | 'projectId'
            | 'projectName'
            | 'startDateTime'
            | 'taskId'
            | 'taskTitle'
            | 'teamId'
            | 'teamName'
            | 'thirdPartyCalendarEventId'
            | 'versionNo'
            | 'workingTimeSec'
          > & {
              thirdPartyCalendarInfoList: Array<
                Maybe<
                  { __typename?: 'ThirdPartyCalendarInfo' } & Pick<
                    ThirdPartyCalendarInfo,
                    'thirdPartyCalendarId'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >
              >;
            }
        >;
        task?: Maybe<
          { __typename?: 'Task' } & Pick<
            Task,
            | 'calendarLinkageCode'
            | 'completeDateTime'
            | 'description'
            | 'estimateTimeSec'
            | 'estimatedRemainingTimeSec'
            | 'id'
            | 'laborCost'
            | 'priority'
            | 'progressDeviationRate'
            | 'progressRate'
            | 'registerDateTime'
            | 'remainingTimeSec'
            | 'scheduleWorkingTimeSec'
            | 'scheduledEndDateTime'
            | 'scheduledStartDateTime'
            | 'sortNoInGanttChart'
            | 'sortNoInList'
            | 'sortNoInMyTask'
            | 'sortNoInTaskStatus'
            | 'title'
            | 'versionNo'
            | 'workingTimeSec'
          > & {
              assignees: Array<
                { __typename?: 'AssignedTaskMemberInfo' } & Pick<
                  AssignedTaskMemberInfo,
                  'sortNo'
                > & {
                    member: { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >;
                  }
              >;
              asssignee?: Maybe<
                { __typename?: 'Member' } & Pick<
                  Member,
                  | 'adminRole'
                  | 'clientManagementRole'
                  | 'department'
                  | 'employeeNumber'
                  | 'id'
                  | 'laborCostManagementRole'
                  | 'mailAddress'
                  | 'memberManagementRole'
                  | 'memberRegistrationDateTime'
                  | 'memberStatus'
                  | 'name'
                  | 'organizationMemberViewRole'
                  | 'organizationReportRole'
                  | 'profileImageUrl'
                  | 'projectCreatePermissionFlg'
                  | 'projectCustomAttributePermissionFlg'
                  | 'projectDeletePermissionFlg'
                  | 'projectUpdatePermissionFlg'
                  | 'proxyEditWorkingDataRole'
                  | 'salesManagementRole'
                  | 'statusMessage'
                  | 'taskCreatePermissionFlg'
                  | 'taskCustomAttributePermissionFlg'
                  | 'taskDeletePermissionFlg'
                  | 'taskUpdatePermissionFlg'
                  | 'teamMemberViewRole'
                  | 'teamReportRole'
                  | 'versionNo'
                >
              >;
              createdMember: { __typename?: 'Member' } & Pick<
                Member,
                | 'adminRole'
                | 'clientManagementRole'
                | 'department'
                | 'employeeNumber'
                | 'id'
                | 'laborCostManagementRole'
                | 'mailAddress'
                | 'memberManagementRole'
                | 'memberRegistrationDateTime'
                | 'memberStatus'
                | 'name'
                | 'organizationMemberViewRole'
                | 'organizationReportRole'
                | 'profileImageUrl'
                | 'projectCreatePermissionFlg'
                | 'projectCustomAttributePermissionFlg'
                | 'projectDeletePermissionFlg'
                | 'projectUpdatePermissionFlg'
                | 'proxyEditWorkingDataRole'
                | 'salesManagementRole'
                | 'statusMessage'
                | 'taskCreatePermissionFlg'
                | 'taskCustomAttributePermissionFlg'
                | 'taskDeletePermissionFlg'
                | 'taskUpdatePermissionFlg'
                | 'teamMemberViewRole'
                | 'teamReportRole'
                | 'versionNo'
              >;
              favoriteMembers: Array<
                Maybe<
                  { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >
                >
              >;
              favoriteMembersInfo: Array<
                Maybe<
                  { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                    FavoriteTaskMemberInfo,
                    'sortNo'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >
              >;
              project: { __typename?: 'Project' } & Pick<
                Project,
                | 'calendarLinkageCode'
                | 'color'
                | 'complete'
                | 'completeDateTime'
                | 'costBudgetWithoutTax'
                | 'description'
                | 'estimateTimeSec'
                | 'id'
                | 'key'
                | 'laborCost'
                | 'laborCostWithoutTax'
                | 'laborShare'
                | 'name'
                | 'priority'
                | 'registerDateTime'
                | 'salesAmountWithoutTax'
                | 'scheduledEndDateTime'
                | 'scheduledStartDateTime'
                | 'sortNoInGanttChart'
                | 'sortNoInList'
                | 'sortNoInProjectStatus'
                | 'totalScheduleTimeSec'
                | 'versionNo'
                | 'workingTimeSec'
              > & {
                  assignees: Array<
                    { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                      AssignedProjectMemberInfo,
                      'sortNo'
                    >
                  >;
                  client?: Maybe<
                    { __typename?: 'Client' } & Pick<
                      Client,
                      'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                    >
                  >;
                  createdMember?: Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >;
                  favoriteMembers: Array<
                    Maybe<
                      { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >
                    >
                  >;
                  favoriteMembersInfo: Array<
                    Maybe<
                      { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                        FavoriteProjectMemberInfo,
                        'sortNo'
                      >
                    >
                  >;
                  projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                    ProjectStatus,
                    'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                  >;
                  team: { __typename?: 'Team' } & Pick<
                    Team,
                    'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                  > & {
                      organization: { __typename?: 'Organization' } & Pick<
                        Organization,
                        | 'allowConnectThirdPartyCalendar'
                        | 'contactMailAddress'
                        | 'contractPreriod'
                        | 'id'
                        | 'imageUrl'
                        | 'ipRestrictionFlg'
                        | 'name'
                        | 'requiredTwoFactorAuth'
                        | 'suspensionFlg'
                        | 'versionNo'
                      > & {
                          plan: { __typename?: 'PlanProduct' } & Pick<
                            PlanProduct,
                            'code' | 'contractPreriod' | 'id'
                          >;
                        };
                    };
                };
              taskStatus: { __typename?: 'TaskStatus' } & Pick<
                TaskStatus,
                'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
              > & {
                  project: { __typename?: 'Project' } & Pick<
                    Project,
                    | 'calendarLinkageCode'
                    | 'color'
                    | 'complete'
                    | 'completeDateTime'
                    | 'costBudgetWithoutTax'
                    | 'description'
                    | 'estimateTimeSec'
                    | 'id'
                    | 'key'
                    | 'laborCost'
                    | 'laborCostWithoutTax'
                    | 'laborShare'
                    | 'name'
                    | 'priority'
                    | 'registerDateTime'
                    | 'salesAmountWithoutTax'
                    | 'scheduledEndDateTime'
                    | 'scheduledStartDateTime'
                    | 'sortNoInGanttChart'
                    | 'sortNoInList'
                    | 'sortNoInProjectStatus'
                    | 'totalScheduleTimeSec'
                    | 'versionNo'
                    | 'workingTimeSec'
                  >;
                };
              workingMembers: Array<
                Maybe<
                  { __typename?: 'WorkingMemberInfo' } & Pick<
                    WorkingMemberInfo,
                    'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                  > & {
                      member: { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >;
                    }
                >
              >;
            }
        >;
        workingMember: { __typename?: 'Member' } & Pick<
          Member,
          | 'adminRole'
          | 'clientManagementRole'
          | 'department'
          | 'employeeNumber'
          | 'id'
          | 'laborCostManagementRole'
          | 'mailAddress'
          | 'memberManagementRole'
          | 'memberRegistrationDateTime'
          | 'memberStatus'
          | 'name'
          | 'organizationMemberViewRole'
          | 'organizationReportRole'
          | 'profileImageUrl'
          | 'projectCreatePermissionFlg'
          | 'projectCustomAttributePermissionFlg'
          | 'projectDeletePermissionFlg'
          | 'projectUpdatePermissionFlg'
          | 'proxyEditWorkingDataRole'
          | 'salesManagementRole'
          | 'statusMessage'
          | 'taskCreatePermissionFlg'
          | 'taskCustomAttributePermissionFlg'
          | 'taskDeletePermissionFlg'
          | 'taskUpdatePermissionFlg'
          | 'teamMemberViewRole'
          | 'teamReportRole'
          | 'versionNo'
        >;
      }
  >;
};

export type WorkingScheduleForSummaryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type WorkingScheduleForSummaryQuery = { __typename?: 'Query' } & {
  workingScheduleForSummary?: Maybe<
    { __typename?: 'WorkingScheduleForSummary' } & Pick<
      WorkingScheduleForSummary,
      'end' | 'id' | 'memo' | 'start' | 'versionNo' | 'workingTimeSec'
    > & {
        calendarEvent?: Maybe<
          { __typename?: 'WorkingScheduleForSummaryCalendarEventPart' } & Pick<
            WorkingScheduleForSummaryCalendarEventPart,
            'clientId' | 'clientName' | 'eventName' | 'id' | 'projectId' | 'projectName' | 'teamId'
          >
        >;
        task?: Maybe<
          { __typename?: 'WorkingScheduleForSummaryTaskPart' } & Pick<
            WorkingScheduleForSummaryTaskPart,
            'id' | 'title' | 'versionNo'
          > & {
              project: { __typename?: 'WorkingScheduleForSummaryProjectPart' } & Pick<
                WorkingScheduleForSummaryProjectPart,
                'id' | 'name'
              > & {
                  client?: Maybe<
                    { __typename?: 'WorkingScheduleForSummaryClientPart' } & Pick<
                      WorkingScheduleForSummaryClientPart,
                      'id' | 'name'
                    >
                  >;
                  team: { __typename?: 'WorkingScheduleForSummaryTeamPart' } & Pick<
                    WorkingScheduleForSummaryTeamPart,
                    'id' | 'personalTeam'
                  >;
                };
            }
        >;
        workingMember: { __typename?: 'WorkingScheduleForSummaryMemberPart' } & Pick<
          WorkingScheduleForSummaryMemberPart,
          'id' | 'name' | 'profileImageUrl'
        >;
      }
  >;
};

export type WorkingSchedulesSpecifyTermQueryVariables = Exact<{
  input: WorkingSchedulesSpecifyTermInputInput;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;

export type WorkingSchedulesSpecifyTermQuery = { __typename?: 'Query' } & {
  workingSchedulesSpecifyTerm?: Maybe<
    Array<
      Maybe<
        { __typename?: 'WorkingSchedule' } & Pick<
          WorkingSchedule,
          'end' | 'id' | 'memo' | 'start' | 'versionNo' | 'workingTimeSec'
        > & {
            calendarEvent?: Maybe<
              { __typename?: 'CalendarEvent' } & Pick<
                CalendarEvent,
                | 'calendarType'
                | 'clientId'
                | 'clientName'
                | 'endDateTime'
                | 'eventName'
                | 'id'
                | 'laborCost'
                | 'personalTeam'
                | 'projectColor'
                | 'projectId'
                | 'projectName'
                | 'startDateTime'
                | 'taskId'
                | 'taskTitle'
                | 'teamId'
                | 'teamName'
                | 'thirdPartyCalendarEventId'
                | 'versionNo'
                | 'workingTimeSec'
              > & {
                  thirdPartyCalendarInfoList: Array<
                    Maybe<
                      { __typename?: 'ThirdPartyCalendarInfo' } & Pick<
                        ThirdPartyCalendarInfo,
                        'thirdPartyCalendarId'
                      > & {
                          member: { __typename?: 'Member' } & Pick<
                            Member,
                            | 'adminRole'
                            | 'clientManagementRole'
                            | 'department'
                            | 'employeeNumber'
                            | 'id'
                            | 'laborCostManagementRole'
                            | 'mailAddress'
                            | 'memberManagementRole'
                            | 'memberRegistrationDateTime'
                            | 'memberStatus'
                            | 'name'
                            | 'organizationMemberViewRole'
                            | 'organizationReportRole'
                            | 'profileImageUrl'
                            | 'projectCreatePermissionFlg'
                            | 'projectCustomAttributePermissionFlg'
                            | 'projectDeletePermissionFlg'
                            | 'projectUpdatePermissionFlg'
                            | 'proxyEditWorkingDataRole'
                            | 'salesManagementRole'
                            | 'statusMessage'
                            | 'taskCreatePermissionFlg'
                            | 'taskCustomAttributePermissionFlg'
                            | 'taskDeletePermissionFlg'
                            | 'taskUpdatePermissionFlg'
                            | 'teamMemberViewRole'
                            | 'teamReportRole'
                            | 'versionNo'
                          >;
                        }
                    >
                  >;
                }
            >;
            task?: Maybe<
              { __typename?: 'Task' } & Pick<
                Task,
                | 'calendarLinkageCode'
                | 'completeDateTime'
                | 'description'
                | 'estimateTimeSec'
                | 'estimatedRemainingTimeSec'
                | 'id'
                | 'laborCost'
                | 'priority'
                | 'progressDeviationRate'
                | 'progressRate'
                | 'registerDateTime'
                | 'remainingTimeSec'
                | 'scheduleWorkingTimeSec'
                | 'scheduledEndDateTime'
                | 'scheduledStartDateTime'
                | 'sortNoInGanttChart'
                | 'sortNoInList'
                | 'sortNoInMyTask'
                | 'sortNoInTaskStatus'
                | 'title'
                | 'versionNo'
                | 'workingTimeSec'
              > & {
                  assignees: Array<
                    { __typename?: 'AssignedTaskMemberInfo' } & Pick<
                      AssignedTaskMemberInfo,
                      'sortNo'
                    > & {
                        member: { __typename?: 'Member' } & Pick<
                          Member,
                          | 'adminRole'
                          | 'clientManagementRole'
                          | 'department'
                          | 'employeeNumber'
                          | 'id'
                          | 'laborCostManagementRole'
                          | 'mailAddress'
                          | 'memberManagementRole'
                          | 'memberRegistrationDateTime'
                          | 'memberStatus'
                          | 'name'
                          | 'organizationMemberViewRole'
                          | 'organizationReportRole'
                          | 'profileImageUrl'
                          | 'projectCreatePermissionFlg'
                          | 'projectCustomAttributePermissionFlg'
                          | 'projectDeletePermissionFlg'
                          | 'projectUpdatePermissionFlg'
                          | 'proxyEditWorkingDataRole'
                          | 'salesManagementRole'
                          | 'statusMessage'
                          | 'taskCreatePermissionFlg'
                          | 'taskCustomAttributePermissionFlg'
                          | 'taskDeletePermissionFlg'
                          | 'taskUpdatePermissionFlg'
                          | 'teamMemberViewRole'
                          | 'teamReportRole'
                          | 'versionNo'
                        >;
                      }
                  >;
                  asssignee?: Maybe<
                    { __typename?: 'Member' } & Pick<
                      Member,
                      | 'adminRole'
                      | 'clientManagementRole'
                      | 'department'
                      | 'employeeNumber'
                      | 'id'
                      | 'laborCostManagementRole'
                      | 'mailAddress'
                      | 'memberManagementRole'
                      | 'memberRegistrationDateTime'
                      | 'memberStatus'
                      | 'name'
                      | 'organizationMemberViewRole'
                      | 'organizationReportRole'
                      | 'profileImageUrl'
                      | 'projectCreatePermissionFlg'
                      | 'projectCustomAttributePermissionFlg'
                      | 'projectDeletePermissionFlg'
                      | 'projectUpdatePermissionFlg'
                      | 'proxyEditWorkingDataRole'
                      | 'salesManagementRole'
                      | 'statusMessage'
                      | 'taskCreatePermissionFlg'
                      | 'taskCustomAttributePermissionFlg'
                      | 'taskDeletePermissionFlg'
                      | 'taskUpdatePermissionFlg'
                      | 'teamMemberViewRole'
                      | 'teamReportRole'
                      | 'versionNo'
                    >
                  >;
                  createdMember: { __typename?: 'Member' } & Pick<
                    Member,
                    | 'adminRole'
                    | 'clientManagementRole'
                    | 'department'
                    | 'employeeNumber'
                    | 'id'
                    | 'laborCostManagementRole'
                    | 'mailAddress'
                    | 'memberManagementRole'
                    | 'memberRegistrationDateTime'
                    | 'memberStatus'
                    | 'name'
                    | 'organizationMemberViewRole'
                    | 'organizationReportRole'
                    | 'profileImageUrl'
                    | 'projectCreatePermissionFlg'
                    | 'projectCustomAttributePermissionFlg'
                    | 'projectDeletePermissionFlg'
                    | 'projectUpdatePermissionFlg'
                    | 'proxyEditWorkingDataRole'
                    | 'salesManagementRole'
                    | 'statusMessage'
                    | 'taskCreatePermissionFlg'
                    | 'taskCustomAttributePermissionFlg'
                    | 'taskDeletePermissionFlg'
                    | 'taskUpdatePermissionFlg'
                    | 'teamMemberViewRole'
                    | 'teamReportRole'
                    | 'versionNo'
                  >;
                  favoriteMembers: Array<
                    Maybe<
                      { __typename?: 'Member' } & Pick<
                        Member,
                        | 'adminRole'
                        | 'clientManagementRole'
                        | 'department'
                        | 'employeeNumber'
                        | 'id'
                        | 'laborCostManagementRole'
                        | 'mailAddress'
                        | 'memberManagementRole'
                        | 'memberRegistrationDateTime'
                        | 'memberStatus'
                        | 'name'
                        | 'organizationMemberViewRole'
                        | 'organizationReportRole'
                        | 'profileImageUrl'
                        | 'projectCreatePermissionFlg'
                        | 'projectCustomAttributePermissionFlg'
                        | 'projectDeletePermissionFlg'
                        | 'projectUpdatePermissionFlg'
                        | 'proxyEditWorkingDataRole'
                        | 'salesManagementRole'
                        | 'statusMessage'
                        | 'taskCreatePermissionFlg'
                        | 'taskCustomAttributePermissionFlg'
                        | 'taskDeletePermissionFlg'
                        | 'taskUpdatePermissionFlg'
                        | 'teamMemberViewRole'
                        | 'teamReportRole'
                        | 'versionNo'
                      >
                    >
                  >;
                  favoriteMembersInfo: Array<
                    Maybe<
                      { __typename?: 'FavoriteTaskMemberInfo' } & Pick<
                        FavoriteTaskMemberInfo,
                        'sortNo'
                      > & {
                          member: { __typename?: 'Member' } & Pick<
                            Member,
                            | 'adminRole'
                            | 'clientManagementRole'
                            | 'department'
                            | 'employeeNumber'
                            | 'id'
                            | 'laborCostManagementRole'
                            | 'mailAddress'
                            | 'memberManagementRole'
                            | 'memberRegistrationDateTime'
                            | 'memberStatus'
                            | 'name'
                            | 'organizationMemberViewRole'
                            | 'organizationReportRole'
                            | 'profileImageUrl'
                            | 'projectCreatePermissionFlg'
                            | 'projectCustomAttributePermissionFlg'
                            | 'projectDeletePermissionFlg'
                            | 'projectUpdatePermissionFlg'
                            | 'proxyEditWorkingDataRole'
                            | 'salesManagementRole'
                            | 'statusMessage'
                            | 'taskCreatePermissionFlg'
                            | 'taskCustomAttributePermissionFlg'
                            | 'taskDeletePermissionFlg'
                            | 'taskUpdatePermissionFlg'
                            | 'teamMemberViewRole'
                            | 'teamReportRole'
                            | 'versionNo'
                          >;
                        }
                    >
                  >;
                  project: { __typename?: 'Project' } & Pick<
                    Project,
                    | 'calendarLinkageCode'
                    | 'color'
                    | 'complete'
                    | 'completeDateTime'
                    | 'costBudgetWithoutTax'
                    | 'description'
                    | 'estimateTimeSec'
                    | 'id'
                    | 'key'
                    | 'laborCost'
                    | 'laborCostWithoutTax'
                    | 'laborShare'
                    | 'name'
                    | 'priority'
                    | 'registerDateTime'
                    | 'salesAmountWithoutTax'
                    | 'scheduledEndDateTime'
                    | 'scheduledStartDateTime'
                    | 'sortNoInGanttChart'
                    | 'sortNoInList'
                    | 'sortNoInProjectStatus'
                    | 'totalScheduleTimeSec'
                    | 'versionNo'
                    | 'workingTimeSec'
                  > & {
                      assignees: Array<
                        { __typename?: 'AssignedProjectMemberInfo' } & Pick<
                          AssignedProjectMemberInfo,
                          'sortNo'
                        >
                      >;
                      client?: Maybe<
                        { __typename?: 'Client' } & Pick<
                          Client,
                          'code' | 'id' | 'name' | 'sortNo' | 'versionNo'
                        >
                      >;
                      createdMember?: Maybe<
                        { __typename?: 'Member' } & Pick<
                          Member,
                          | 'adminRole'
                          | 'clientManagementRole'
                          | 'department'
                          | 'employeeNumber'
                          | 'id'
                          | 'laborCostManagementRole'
                          | 'mailAddress'
                          | 'memberManagementRole'
                          | 'memberRegistrationDateTime'
                          | 'memberStatus'
                          | 'name'
                          | 'organizationMemberViewRole'
                          | 'organizationReportRole'
                          | 'profileImageUrl'
                          | 'projectCreatePermissionFlg'
                          | 'projectCustomAttributePermissionFlg'
                          | 'projectDeletePermissionFlg'
                          | 'projectUpdatePermissionFlg'
                          | 'proxyEditWorkingDataRole'
                          | 'salesManagementRole'
                          | 'statusMessage'
                          | 'taskCreatePermissionFlg'
                          | 'taskCustomAttributePermissionFlg'
                          | 'taskDeletePermissionFlg'
                          | 'taskUpdatePermissionFlg'
                          | 'teamMemberViewRole'
                          | 'teamReportRole'
                          | 'versionNo'
                        >
                      >;
                      favoriteMembers: Array<
                        Maybe<
                          { __typename?: 'Member' } & Pick<
                            Member,
                            | 'adminRole'
                            | 'clientManagementRole'
                            | 'department'
                            | 'employeeNumber'
                            | 'id'
                            | 'laborCostManagementRole'
                            | 'mailAddress'
                            | 'memberManagementRole'
                            | 'memberRegistrationDateTime'
                            | 'memberStatus'
                            | 'name'
                            | 'organizationMemberViewRole'
                            | 'organizationReportRole'
                            | 'profileImageUrl'
                            | 'projectCreatePermissionFlg'
                            | 'projectCustomAttributePermissionFlg'
                            | 'projectDeletePermissionFlg'
                            | 'projectUpdatePermissionFlg'
                            | 'proxyEditWorkingDataRole'
                            | 'salesManagementRole'
                            | 'statusMessage'
                            | 'taskCreatePermissionFlg'
                            | 'taskCustomAttributePermissionFlg'
                            | 'taskDeletePermissionFlg'
                            | 'taskUpdatePermissionFlg'
                            | 'teamMemberViewRole'
                            | 'teamReportRole'
                            | 'versionNo'
                          >
                        >
                      >;
                      favoriteMembersInfo: Array<
                        Maybe<
                          { __typename?: 'FavoriteProjectMemberInfo' } & Pick<
                            FavoriteProjectMemberInfo,
                            'sortNo'
                          >
                        >
                      >;
                      projectStatus: { __typename?: 'ProjectStatus' } & Pick<
                        ProjectStatus,
                        'endStatus' | 'id' | 'name' | 'sortNo' | 'teamId' | 'versionNo'
                      >;
                      team: { __typename?: 'Team' } & Pick<
                        Team,
                        'archived' | 'id' | 'name' | 'personalTeam' | 'status' | 'versionNo'
                      > & {
                          organization: { __typename?: 'Organization' } & Pick<
                            Organization,
                            | 'allowConnectThirdPartyCalendar'
                            | 'contactMailAddress'
                            | 'contractPreriod'
                            | 'id'
                            | 'imageUrl'
                            | 'ipRestrictionFlg'
                            | 'name'
                            | 'requiredTwoFactorAuth'
                            | 'suspensionFlg'
                            | 'versionNo'
                          > & {
                              plan: { __typename?: 'PlanProduct' } & Pick<
                                PlanProduct,
                                'code' | 'contractPreriod' | 'id'
                              >;
                            };
                        };
                    };
                  taskStatus: { __typename?: 'TaskStatus' } & Pick<
                    TaskStatus,
                    'endStatus' | 'id' | 'name' | 'sortNo' | 'versionNo'
                  > & {
                      project: { __typename?: 'Project' } & Pick<
                        Project,
                        | 'calendarLinkageCode'
                        | 'color'
                        | 'complete'
                        | 'completeDateTime'
                        | 'costBudgetWithoutTax'
                        | 'description'
                        | 'estimateTimeSec'
                        | 'id'
                        | 'key'
                        | 'laborCost'
                        | 'laborCostWithoutTax'
                        | 'laborShare'
                        | 'name'
                        | 'priority'
                        | 'registerDateTime'
                        | 'salesAmountWithoutTax'
                        | 'scheduledEndDateTime'
                        | 'scheduledStartDateTime'
                        | 'sortNoInGanttChart'
                        | 'sortNoInList'
                        | 'sortNoInProjectStatus'
                        | 'totalScheduleTimeSec'
                        | 'versionNo'
                        | 'workingTimeSec'
                      >;
                    };
                  workingMembers: Array<
                    Maybe<
                      { __typename?: 'WorkingMemberInfo' } & Pick<
                        WorkingMemberInfo,
                        'lastWorkingStartDateTime' | 'lastWorkingStopDateTime'
                      > & {
                          member: { __typename?: 'Member' } & Pick<
                            Member,
                            | 'adminRole'
                            | 'clientManagementRole'
                            | 'department'
                            | 'employeeNumber'
                            | 'id'
                            | 'laborCostManagementRole'
                            | 'mailAddress'
                            | 'memberManagementRole'
                            | 'memberRegistrationDateTime'
                            | 'memberStatus'
                            | 'name'
                            | 'organizationMemberViewRole'
                            | 'organizationReportRole'
                            | 'profileImageUrl'
                            | 'projectCreatePermissionFlg'
                            | 'projectCustomAttributePermissionFlg'
                            | 'projectDeletePermissionFlg'
                            | 'projectUpdatePermissionFlg'
                            | 'proxyEditWorkingDataRole'
                            | 'salesManagementRole'
                            | 'statusMessage'
                            | 'taskCreatePermissionFlg'
                            | 'taskCustomAttributePermissionFlg'
                            | 'taskDeletePermissionFlg'
                            | 'taskUpdatePermissionFlg'
                            | 'teamMemberViewRole'
                            | 'teamReportRole'
                            | 'versionNo'
                          >;
                        }
                    >
                  >;
                }
            >;
            workingMember: { __typename?: 'Member' } & Pick<
              Member,
              | 'adminRole'
              | 'clientManagementRole'
              | 'department'
              | 'employeeNumber'
              | 'id'
              | 'laborCostManagementRole'
              | 'mailAddress'
              | 'memberManagementRole'
              | 'memberRegistrationDateTime'
              | 'memberStatus'
              | 'name'
              | 'organizationMemberViewRole'
              | 'organizationReportRole'
              | 'profileImageUrl'
              | 'projectCreatePermissionFlg'
              | 'projectCustomAttributePermissionFlg'
              | 'projectDeletePermissionFlg'
              | 'projectUpdatePermissionFlg'
              | 'proxyEditWorkingDataRole'
              | 'salesManagementRole'
              | 'statusMessage'
              | 'taskCreatePermissionFlg'
              | 'taskCustomAttributePermissionFlg'
              | 'taskDeletePermissionFlg'
              | 'taskUpdatePermissionFlg'
              | 'teamMemberViewRole'
              | 'teamReportRole'
              | 'versionNo'
            >;
          }
      >
    >
  >;
};

export type WorkingSchedulesSpecifyTermForCalendarQueryVariables = Exact<{
  input: WorkingSchedulesSpecifyTermForCalendarInputInput;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;

export type WorkingSchedulesSpecifyTermForCalendarQuery = { __typename?: 'Query' } & {
  workingSchedulesSpecifyTermForCalendar?: Maybe<
    Array<
      Maybe<
        { __typename?: 'WorkingScheduleForCalendar' } & Pick<
          WorkingScheduleForCalendar,
          'memberId'
        > & {
            workingSchedules: Array<
              Maybe<
                { __typename?: 'CalendarWorkingSchedule' } & Pick<
                  CalendarWorkingSchedule,
                  'end' | 'id' | 'memo' | 'start' | 'versionNo' | 'workingTimeSec'
                > & {
                    calendarEvent?: Maybe<
                      { __typename?: 'WorkingScheduleCalendarEventPart' } & Pick<
                        WorkingScheduleCalendarEventPart,
                        | 'calendarType'
                        | 'clientId'
                        | 'clientName'
                        | 'endDateTime'
                        | 'eventName'
                        | 'id'
                        | 'personalTeam'
                        | 'projectColor'
                        | 'projectId'
                        | 'projectName'
                        | 'startDateTime'
                        | 'taskId'
                        | 'taskTitle'
                        | 'teamId'
                        | 'teamName'
                        | 'thirdPartyCalendarEventId'
                        | 'versionNo'
                      >
                    >;
                    task?: Maybe<
                      { __typename?: 'WorkingScheduleTaskPart' } & Pick<
                        WorkingScheduleTaskPart,
                        'completeDateTime' | 'id' | 'title' | 'versionNo'
                      > & {
                          project: { __typename?: 'WorkingScheduleProjectPart' } & Pick<
                            WorkingScheduleProjectPart,
                            'color' | 'complete' | 'id' | 'name'
                          > & {
                              client?: Maybe<
                                { __typename?: 'WorkingScheduleClientPart' } & Pick<
                                  WorkingScheduleClientPart,
                                  'id' | 'name'
                                >
                              >;
                              team: { __typename?: 'WorkingScheduleTeamPart' } & Pick<
                                WorkingScheduleTeamPart,
                                'id' | 'name' | 'personalTeam'
                              >;
                            };
                          workingMembers: Array<
                            Maybe<
                              { __typename?: 'WorkingScheduleWorkingMemberInfoPart' } & {
                                member: { __typename?: 'WorkingScheduleMemberPart' } & Pick<
                                  WorkingScheduleMemberPart,
                                  'id' | 'name' | 'profileImageUrl'
                                >;
                              }
                            >
                          >;
                        }
                    >;
                    workingMember: { __typename?: 'WorkingScheduleMemberPart' } & Pick<
                      WorkingScheduleMemberPart,
                      'id' | 'name' | 'profileImageUrl'
                    >;
                  }
              >
            >;
          }
      >
    >
  >;
};

export type WorkingSchedulesSpecifyTermForSummaryQueryVariables = Exact<{
  input: WorkingSchedulesSpecifyTermInputInput;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;

export type WorkingSchedulesSpecifyTermForSummaryQuery = { __typename?: 'Query' } & {
  workingSchedulesSpecifyTermForSummary?: Maybe<
    Array<
      Maybe<
        { __typename?: 'WorkingScheduleForSummary' } & Pick<
          WorkingScheduleForSummary,
          'end' | 'id' | 'memo' | 'start' | 'versionNo' | 'workingTimeSec'
        > & {
            calendarEvent?: Maybe<
              { __typename?: 'WorkingScheduleForSummaryCalendarEventPart' } & Pick<
                WorkingScheduleForSummaryCalendarEventPart,
                | 'clientId'
                | 'clientName'
                | 'eventName'
                | 'id'
                | 'projectId'
                | 'projectName'
                | 'teamId'
              >
            >;
            task?: Maybe<
              { __typename?: 'WorkingScheduleForSummaryTaskPart' } & Pick<
                WorkingScheduleForSummaryTaskPart,
                'id' | 'title' | 'versionNo'
              > & {
                  project: { __typename?: 'WorkingScheduleForSummaryProjectPart' } & Pick<
                    WorkingScheduleForSummaryProjectPart,
                    'id' | 'name'
                  > & {
                      client?: Maybe<
                        { __typename?: 'WorkingScheduleForSummaryClientPart' } & Pick<
                          WorkingScheduleForSummaryClientPart,
                          'id' | 'name'
                        >
                      >;
                      team: { __typename?: 'WorkingScheduleForSummaryTeamPart' } & Pick<
                        WorkingScheduleForSummaryTeamPart,
                        'id' | 'personalTeam'
                      >;
                    };
                }
            >;
            workingMember: { __typename?: 'WorkingScheduleForSummaryMemberPart' } & Pick<
              WorkingScheduleForSummaryMemberPart,
              'id' | 'name' | 'profileImageUrl'
            >;
          }
      >
    >
  >;
};

export const AddCsvReportItemDocument = gql`
  mutation addCsvReportItem($id: ID!, $input: AddCsvDefinitionDetailInputInput!) {
    addCsvReportItem(id: $id, input: $input) {
      id
      items {
        id
        reportItemCode
        reportItemName
        sortNo
      }
      name
      versionNo
    }
  }
`;
export type AddCsvReportItemMutationFn = Apollo.MutationFunction<
  AddCsvReportItemMutation,
  AddCsvReportItemMutationVariables
>;

/**
 * __useAddCsvReportItemMutation__
 *
 * To run a mutation, you first call `useAddCsvReportItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCsvReportItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCsvReportItemMutation, { data, loading, error }] = useAddCsvReportItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCsvReportItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddCsvReportItemMutation,
    AddCsvReportItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddCsvReportItemMutation, AddCsvReportItemMutationVariables>(
    AddCsvReportItemDocument,
    options
  );
}
export type AddCsvReportItemMutationHookResult = ReturnType<typeof useAddCsvReportItemMutation>;
export type AddCsvReportItemMutationResult = Apollo.MutationResult<AddCsvReportItemMutation>;
export type AddCsvReportItemMutationOptions = Apollo.BaseMutationOptions<
  AddCsvReportItemMutation,
  AddCsvReportItemMutationVariables
>;
export const AddProjectCustomAttributeDocument = gql`
  mutation addProjectCustomAttribute(
    $input: RegisterProjectCustomAttributeInputInput!
    $projectId: String!
  ) {
    addProjectCustomAttribute(input: $input, projectId: $projectId) {
      id
      listItem {
        id
        sortNo
        value
        versionNo
      }
      master {
        id
        listItem {
          id
          sortNo
          value
          versionNo
        }
        name
        required
        sortNo
        type
        versionNo
      }
      sortNo
      value
    }
  }
`;
export type AddProjectCustomAttributeMutationFn = Apollo.MutationFunction<
  AddProjectCustomAttributeMutation,
  AddProjectCustomAttributeMutationVariables
>;

/**
 * __useAddProjectCustomAttributeMutation__
 *
 * To run a mutation, you first call `useAddProjectCustomAttributeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProjectCustomAttributeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProjectCustomAttributeMutation, { data, loading, error }] = useAddProjectCustomAttributeMutation({
 *   variables: {
 *      input: // value for 'input'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useAddProjectCustomAttributeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddProjectCustomAttributeMutation,
    AddProjectCustomAttributeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddProjectCustomAttributeMutation,
    AddProjectCustomAttributeMutationVariables
  >(AddProjectCustomAttributeDocument, options);
}
export type AddProjectCustomAttributeMutationHookResult = ReturnType<
  typeof useAddProjectCustomAttributeMutation
>;
export type AddProjectCustomAttributeMutationResult =
  Apollo.MutationResult<AddProjectCustomAttributeMutation>;
export type AddProjectCustomAttributeMutationOptions = Apollo.BaseMutationOptions<
  AddProjectCustomAttributeMutation,
  AddProjectCustomAttributeMutationVariables
>;
export const AddProjectCustomAttributeMasterListItemDocument = gql`
  mutation addProjectCustomAttributeMasterListItem(
    $input: RegisterProjectCustomAttributeMasterListItemInputInput!
    $projectCustomAttributeMasterId: String!
  ) {
    addProjectCustomAttributeMasterListItem(
      input: $input
      projectCustomAttributeMasterId: $projectCustomAttributeMasterId
    ) {
      id
      sortNo
      value
      versionNo
    }
  }
`;
export type AddProjectCustomAttributeMasterListItemMutationFn = Apollo.MutationFunction<
  AddProjectCustomAttributeMasterListItemMutation,
  AddProjectCustomAttributeMasterListItemMutationVariables
>;

/**
 * __useAddProjectCustomAttributeMasterListItemMutation__
 *
 * To run a mutation, you first call `useAddProjectCustomAttributeMasterListItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProjectCustomAttributeMasterListItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProjectCustomAttributeMasterListItemMutation, { data, loading, error }] = useAddProjectCustomAttributeMasterListItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *      projectCustomAttributeMasterId: // value for 'projectCustomAttributeMasterId'
 *   },
 * });
 */
export function useAddProjectCustomAttributeMasterListItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddProjectCustomAttributeMasterListItemMutation,
    AddProjectCustomAttributeMasterListItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddProjectCustomAttributeMasterListItemMutation,
    AddProjectCustomAttributeMasterListItemMutationVariables
  >(AddProjectCustomAttributeMasterListItemDocument, options);
}
export type AddProjectCustomAttributeMasterListItemMutationHookResult = ReturnType<
  typeof useAddProjectCustomAttributeMasterListItemMutation
>;
export type AddProjectCustomAttributeMasterListItemMutationResult =
  Apollo.MutationResult<AddProjectCustomAttributeMasterListItemMutation>;
export type AddProjectCustomAttributeMasterListItemMutationOptions = Apollo.BaseMutationOptions<
  AddProjectCustomAttributeMasterListItemMutation,
  AddProjectCustomAttributeMasterListItemMutationVariables
>;
export const AddProjectTemplateCustomAttributeDocument = gql`
  mutation addProjectTemplateCustomAttribute(
    $input: RegisterProjectTemplateCustomAttributeInputInput!
    $projectTemplateId: String!
  ) {
    addProjectTemplateCustomAttribute(input: $input, projectTemplateId: $projectTemplateId) {
      id
      listItem {
        id
        sortNo
        value
        versionNo
      }
      master {
        id
        listItem {
          id
          sortNo
          value
          versionNo
        }
        name
        required
        sortNo
        type
        versionNo
      }
      sortNo
      value
    }
  }
`;
export type AddProjectTemplateCustomAttributeMutationFn = Apollo.MutationFunction<
  AddProjectTemplateCustomAttributeMutation,
  AddProjectTemplateCustomAttributeMutationVariables
>;

/**
 * __useAddProjectTemplateCustomAttributeMutation__
 *
 * To run a mutation, you first call `useAddProjectTemplateCustomAttributeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProjectTemplateCustomAttributeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProjectTemplateCustomAttributeMutation, { data, loading, error }] = useAddProjectTemplateCustomAttributeMutation({
 *   variables: {
 *      input: // value for 'input'
 *      projectTemplateId: // value for 'projectTemplateId'
 *   },
 * });
 */
export function useAddProjectTemplateCustomAttributeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddProjectTemplateCustomAttributeMutation,
    AddProjectTemplateCustomAttributeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddProjectTemplateCustomAttributeMutation,
    AddProjectTemplateCustomAttributeMutationVariables
  >(AddProjectTemplateCustomAttributeDocument, options);
}
export type AddProjectTemplateCustomAttributeMutationHookResult = ReturnType<
  typeof useAddProjectTemplateCustomAttributeMutation
>;
export type AddProjectTemplateCustomAttributeMutationResult =
  Apollo.MutationResult<AddProjectTemplateCustomAttributeMutation>;
export type AddProjectTemplateCustomAttributeMutationOptions = Apollo.BaseMutationOptions<
  AddProjectTemplateCustomAttributeMutation,
  AddProjectTemplateCustomAttributeMutationVariables
>;
export const AddProjectTemplateTaskCustomAttributeDocument = gql`
  mutation addProjectTemplateTaskCustomAttribute(
    $input: RegisterProjectTemplateTaskCustomAttributeInputInput!
    $projectTemplateTaskId: String!
  ) {
    addProjectTemplateTaskCustomAttribute(
      input: $input
      projectTemplateTaskId: $projectTemplateTaskId
    ) {
      id
      listItem {
        id
        sortNo
        value
        versionNo
      }
      master {
        id
        listItem {
          id
          sortNo
          value
          versionNo
        }
        name
        required
        sortNo
        type
        versionNo
      }
      sortNo
      value
    }
  }
`;
export type AddProjectTemplateTaskCustomAttributeMutationFn = Apollo.MutationFunction<
  AddProjectTemplateTaskCustomAttributeMutation,
  AddProjectTemplateTaskCustomAttributeMutationVariables
>;

/**
 * __useAddProjectTemplateTaskCustomAttributeMutation__
 *
 * To run a mutation, you first call `useAddProjectTemplateTaskCustomAttributeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProjectTemplateTaskCustomAttributeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProjectTemplateTaskCustomAttributeMutation, { data, loading, error }] = useAddProjectTemplateTaskCustomAttributeMutation({
 *   variables: {
 *      input: // value for 'input'
 *      projectTemplateTaskId: // value for 'projectTemplateTaskId'
 *   },
 * });
 */
export function useAddProjectTemplateTaskCustomAttributeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddProjectTemplateTaskCustomAttributeMutation,
    AddProjectTemplateTaskCustomAttributeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddProjectTemplateTaskCustomAttributeMutation,
    AddProjectTemplateTaskCustomAttributeMutationVariables
  >(AddProjectTemplateTaskCustomAttributeDocument, options);
}
export type AddProjectTemplateTaskCustomAttributeMutationHookResult = ReturnType<
  typeof useAddProjectTemplateTaskCustomAttributeMutation
>;
export type AddProjectTemplateTaskCustomAttributeMutationResult =
  Apollo.MutationResult<AddProjectTemplateTaskCustomAttributeMutation>;
export type AddProjectTemplateTaskCustomAttributeMutationOptions = Apollo.BaseMutationOptions<
  AddProjectTemplateTaskCustomAttributeMutation,
  AddProjectTemplateTaskCustomAttributeMutationVariables
>;
export const AddTaskCommentDocument = gql`
  mutation addTaskComment($input: AddTaskCommentInputInput, $taskId: String!) {
    addTaskComment(input: $input, taskId: $taskId) {
      comment
      commentedDateTime
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      id
      projectId
      projectName
      task {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        asssignee {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        calendarLinkageCode
        completeDateTime
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        estimatedRemainingTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        laborCost
        priority
        progressDeviationRate
        progressRate
        project {
          assignees {
            sortNo
          }
          calendarLinkageCode
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          estimateTimeSec
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            sortNo
          }
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          projectStatus {
            endStatus
            id
            name
            sortNo
            teamId
            versionNo
          }
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          team {
            archived
            id
            name
            organization {
              allowConnectThirdPartyCalendar
              contactMailAddress
              contractPreriod
              id
              imageUrl
              ipRestrictionFlg
              name
              plan {
                code
                contractPreriod
                id
              }
              requiredTwoFactorAuth
              suspensionFlg
              versionNo
            }
            personalTeam
            status
            versionNo
          }
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        registerDateTime
        remainingTimeSec
        scheduleWorkingTimeSec
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInMyTask
        sortNoInTaskStatus
        taskStatus {
          endStatus
          id
          name
          project {
            calendarLinkageCode
            color
            complete
            completeDateTime
            costBudgetWithoutTax
            description
            estimateTimeSec
            id
            key
            laborCost
            laborCostWithoutTax
            laborShare
            name
            priority
            registerDateTime
            salesAmountWithoutTax
            scheduledEndDateTime
            scheduledStartDateTime
            sortNoInGanttChart
            sortNoInList
            sortNoInProjectStatus
            totalScheduleTimeSec
            versionNo
            workingTimeSec
          }
          sortNo
          versionNo
        }
        title
        versionNo
        workingMembers {
          lastWorkingStartDateTime
          lastWorkingStopDateTime
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
        }
        workingTimeSec
      }
      taskId
      taskTitle
      teamId
      teamName
      versionNo
    }
  }
`;
export type AddTaskCommentMutationFn = Apollo.MutationFunction<
  AddTaskCommentMutation,
  AddTaskCommentMutationVariables
>;

/**
 * __useAddTaskCommentMutation__
 *
 * To run a mutation, you first call `useAddTaskCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTaskCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTaskCommentMutation, { data, loading, error }] = useAddTaskCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useAddTaskCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<AddTaskCommentMutation, AddTaskCommentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddTaskCommentMutation, AddTaskCommentMutationVariables>(
    AddTaskCommentDocument,
    options
  );
}
export type AddTaskCommentMutationHookResult = ReturnType<typeof useAddTaskCommentMutation>;
export type AddTaskCommentMutationResult = Apollo.MutationResult<AddTaskCommentMutation>;
export type AddTaskCommentMutationOptions = Apollo.BaseMutationOptions<
  AddTaskCommentMutation,
  AddTaskCommentMutationVariables
>;
export const AddTaskCustomAttributeDocument = gql`
  mutation addTaskCustomAttribute(
    $input: RegisterTaskCustomAttributeInputInput!
    $taskId: String!
  ) {
    addTaskCustomAttribute(input: $input, taskId: $taskId) {
      id
      listItem {
        id
        sortNo
        value
        versionNo
      }
      master {
        id
        listItem {
          id
          sortNo
          value
          versionNo
        }
        name
        required
        sortNo
        type
        versionNo
      }
      sortNo
      value
    }
  }
`;
export type AddTaskCustomAttributeMutationFn = Apollo.MutationFunction<
  AddTaskCustomAttributeMutation,
  AddTaskCustomAttributeMutationVariables
>;

/**
 * __useAddTaskCustomAttributeMutation__
 *
 * To run a mutation, you first call `useAddTaskCustomAttributeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTaskCustomAttributeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTaskCustomAttributeMutation, { data, loading, error }] = useAddTaskCustomAttributeMutation({
 *   variables: {
 *      input: // value for 'input'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useAddTaskCustomAttributeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddTaskCustomAttributeMutation,
    AddTaskCustomAttributeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddTaskCustomAttributeMutation,
    AddTaskCustomAttributeMutationVariables
  >(AddTaskCustomAttributeDocument, options);
}
export type AddTaskCustomAttributeMutationHookResult = ReturnType<
  typeof useAddTaskCustomAttributeMutation
>;
export type AddTaskCustomAttributeMutationResult =
  Apollo.MutationResult<AddTaskCustomAttributeMutation>;
export type AddTaskCustomAttributeMutationOptions = Apollo.BaseMutationOptions<
  AddTaskCustomAttributeMutation,
  AddTaskCustomAttributeMutationVariables
>;
export const AddTaskCustomAttributeMasterListItemDocument = gql`
  mutation addTaskCustomAttributeMasterListItem(
    $input: RegisterTaskCustomAttributeMasterListItemInputInput!
    $taskCustomAttributeMasterId: String!
  ) {
    addTaskCustomAttributeMasterListItem(
      input: $input
      taskCustomAttributeMasterId: $taskCustomAttributeMasterId
    ) {
      id
      sortNo
      value
      versionNo
    }
  }
`;
export type AddTaskCustomAttributeMasterListItemMutationFn = Apollo.MutationFunction<
  AddTaskCustomAttributeMasterListItemMutation,
  AddTaskCustomAttributeMasterListItemMutationVariables
>;

/**
 * __useAddTaskCustomAttributeMasterListItemMutation__
 *
 * To run a mutation, you first call `useAddTaskCustomAttributeMasterListItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTaskCustomAttributeMasterListItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTaskCustomAttributeMasterListItemMutation, { data, loading, error }] = useAddTaskCustomAttributeMasterListItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *      taskCustomAttributeMasterId: // value for 'taskCustomAttributeMasterId'
 *   },
 * });
 */
export function useAddTaskCustomAttributeMasterListItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddTaskCustomAttributeMasterListItemMutation,
    AddTaskCustomAttributeMasterListItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddTaskCustomAttributeMasterListItemMutation,
    AddTaskCustomAttributeMasterListItemMutationVariables
  >(AddTaskCustomAttributeMasterListItemDocument, options);
}
export type AddTaskCustomAttributeMasterListItemMutationHookResult = ReturnType<
  typeof useAddTaskCustomAttributeMasterListItemMutation
>;
export type AddTaskCustomAttributeMasterListItemMutationResult =
  Apollo.MutationResult<AddTaskCustomAttributeMasterListItemMutation>;
export type AddTaskCustomAttributeMasterListItemMutationOptions = Apollo.BaseMutationOptions<
  AddTaskCustomAttributeMasterListItemMutation,
  AddTaskCustomAttributeMasterListItemMutationVariables
>;
export const ArchiveCompletedTasksDocument = gql`
  mutation archiveCompletedTasks($projectId: String!) {
    archiveCompletedTasks(projectId: $projectId) {
      assignees {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      asssignee {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      calendarLinkageCode
      completeDateTime
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      estimatedRemainingTimeSec
      favoriteMembers {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      favoriteMembersInfo {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      id
      laborCost
      priority
      progressDeviationRate
      progressRate
      project {
        assignees {
          sortNo
        }
        calendarLinkageCode
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        color
        complete
        completeDateTime
        costBudgetWithoutTax
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          sortNo
        }
        id
        key
        laborCost
        laborCostWithoutTax
        laborShare
        name
        priority
        projectStatus {
          endStatus
          id
          name
          sortNo
          teamId
          versionNo
        }
        registerDateTime
        salesAmountWithoutTax
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInProjectStatus
        team {
          archived
          id
          name
          organization {
            allowConnectThirdPartyCalendar
            contactMailAddress
            contractPreriod
            id
            imageUrl
            ipRestrictionFlg
            name
            plan {
              code
              contractPreriod
              id
            }
            requiredTwoFactorAuth
            suspensionFlg
            versionNo
          }
          personalTeam
          status
          versionNo
        }
        totalScheduleTimeSec
        versionNo
        workingTimeSec
      }
      registerDateTime
      remainingTimeSec
      scheduleWorkingTimeSec
      scheduledEndDateTime
      scheduledStartDateTime
      sortNoInGanttChart
      sortNoInList
      sortNoInMyTask
      sortNoInTaskStatus
      taskStatus {
        endStatus
        id
        name
        project {
          calendarLinkageCode
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          description
          estimateTimeSec
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        sortNo
        versionNo
      }
      title
      versionNo
      workingMembers {
        lastWorkingStartDateTime
        lastWorkingStopDateTime
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
      }
      workingTimeSec
    }
  }
`;
export type ArchiveCompletedTasksMutationFn = Apollo.MutationFunction<
  ArchiveCompletedTasksMutation,
  ArchiveCompletedTasksMutationVariables
>;

/**
 * __useArchiveCompletedTasksMutation__
 *
 * To run a mutation, you first call `useArchiveCompletedTasksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveCompletedTasksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveCompletedTasksMutation, { data, loading, error }] = useArchiveCompletedTasksMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useArchiveCompletedTasksMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveCompletedTasksMutation,
    ArchiveCompletedTasksMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchiveCompletedTasksMutation, ArchiveCompletedTasksMutationVariables>(
    ArchiveCompletedTasksDocument,
    options
  );
}
export type ArchiveCompletedTasksMutationHookResult = ReturnType<
  typeof useArchiveCompletedTasksMutation
>;
export type ArchiveCompletedTasksMutationResult =
  Apollo.MutationResult<ArchiveCompletedTasksMutation>;
export type ArchiveCompletedTasksMutationOptions = Apollo.BaseMutationOptions<
  ArchiveCompletedTasksMutation,
  ArchiveCompletedTasksMutationVariables
>;
export const ArchiveTeamDocument = gql`
  mutation archiveTeam($id: ID!, $input: ArchiveTeamInputInput!) {
    archiveTeam(id: $id, input: $input) {
      archived
      id
      name
      organization {
        allowConnectThirdPartyCalendar
        contactMailAddress
        contractPreriod
        id
        imageUrl
        ipRestrictionFlg
        name
        plan {
          code
          contractPreriod
          id
        }
        requiredTwoFactorAuth
        suspensionFlg
        versionNo
      }
      personalTeam
      status
      versionNo
    }
  }
`;
export type ArchiveTeamMutationFn = Apollo.MutationFunction<
  ArchiveTeamMutation,
  ArchiveTeamMutationVariables
>;

/**
 * __useArchiveTeamMutation__
 *
 * To run a mutation, you first call `useArchiveTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveTeamMutation, { data, loading, error }] = useArchiveTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<ArchiveTeamMutation, ArchiveTeamMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchiveTeamMutation, ArchiveTeamMutationVariables>(
    ArchiveTeamDocument,
    options
  );
}
export type ArchiveTeamMutationHookResult = ReturnType<typeof useArchiveTeamMutation>;
export type ArchiveTeamMutationResult = Apollo.MutationResult<ArchiveTeamMutation>;
export type ArchiveTeamMutationOptions = Apollo.BaseMutationOptions<
  ArchiveTeamMutation,
  ArchiveTeamMutationVariables
>;
export const CancelFavoriteProjectDocument = gql`
  mutation cancelFavoriteProject($id: ID!, $input: CancelFavoriteProjectInputInput) {
    cancelFavoriteProject(id: $id, input: $input) {
      assignees {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      calendarLinkageCode
      client {
        code
        id
        name
        sortNo
        versionNo
      }
      color
      complete
      completeDateTime
      costBudgetWithoutTax
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      favoriteMembers {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      favoriteMembersInfo {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      id
      key
      laborCost
      laborCostWithoutTax
      laborShare
      name
      priority
      projectStatus {
        endStatus
        id
        name
        sortNo
        teamId
        versionNo
      }
      registerDateTime
      salesAmountWithoutTax
      scheduledEndDateTime
      scheduledStartDateTime
      sortNoInGanttChart
      sortNoInList
      sortNoInProjectStatus
      team {
        archived
        id
        name
        organization {
          allowConnectThirdPartyCalendar
          contactMailAddress
          contractPreriod
          id
          imageUrl
          ipRestrictionFlg
          name
          plan {
            code
            contractPreriod
            id
          }
          requiredTwoFactorAuth
          suspensionFlg
          versionNo
        }
        personalTeam
        status
        versionNo
      }
      totalScheduleTimeSec
      versionNo
      workingTimeSec
    }
  }
`;
export type CancelFavoriteProjectMutationFn = Apollo.MutationFunction<
  CancelFavoriteProjectMutation,
  CancelFavoriteProjectMutationVariables
>;

/**
 * __useCancelFavoriteProjectMutation__
 *
 * To run a mutation, you first call `useCancelFavoriteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelFavoriteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelFavoriteProjectMutation, { data, loading, error }] = useCancelFavoriteProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelFavoriteProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelFavoriteProjectMutation,
    CancelFavoriteProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelFavoriteProjectMutation, CancelFavoriteProjectMutationVariables>(
    CancelFavoriteProjectDocument,
    options
  );
}
export type CancelFavoriteProjectMutationHookResult = ReturnType<
  typeof useCancelFavoriteProjectMutation
>;
export type CancelFavoriteProjectMutationResult =
  Apollo.MutationResult<CancelFavoriteProjectMutation>;
export type CancelFavoriteProjectMutationOptions = Apollo.BaseMutationOptions<
  CancelFavoriteProjectMutation,
  CancelFavoriteProjectMutationVariables
>;
export const CancelFavoriteTaskDocument = gql`
  mutation cancelFavoriteTask($id: ID!, $input: CancelFavoriteTaskInputInput) {
    cancelFavoriteTask(id: $id, input: $input) {
      assignees {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      asssignee {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      calendarLinkageCode
      completeDateTime
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      estimatedRemainingTimeSec
      favoriteMembers {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      favoriteMembersInfo {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      id
      laborCost
      priority
      progressDeviationRate
      progressRate
      project {
        assignees {
          sortNo
        }
        calendarLinkageCode
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        color
        complete
        completeDateTime
        costBudgetWithoutTax
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          sortNo
        }
        id
        key
        laborCost
        laborCostWithoutTax
        laborShare
        name
        priority
        projectStatus {
          endStatus
          id
          name
          sortNo
          teamId
          versionNo
        }
        registerDateTime
        salesAmountWithoutTax
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInProjectStatus
        team {
          archived
          id
          name
          organization {
            allowConnectThirdPartyCalendar
            contactMailAddress
            contractPreriod
            id
            imageUrl
            ipRestrictionFlg
            name
            plan {
              code
              contractPreriod
              id
            }
            requiredTwoFactorAuth
            suspensionFlg
            versionNo
          }
          personalTeam
          status
          versionNo
        }
        totalScheduleTimeSec
        versionNo
        workingTimeSec
      }
      registerDateTime
      remainingTimeSec
      scheduleWorkingTimeSec
      scheduledEndDateTime
      scheduledStartDateTime
      sortNoInGanttChart
      sortNoInList
      sortNoInMyTask
      sortNoInTaskStatus
      taskStatus {
        endStatus
        id
        name
        project {
          calendarLinkageCode
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          description
          estimateTimeSec
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        sortNo
        versionNo
      }
      title
      versionNo
      workingMembers {
        lastWorkingStartDateTime
        lastWorkingStopDateTime
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
      }
      workingTimeSec
    }
  }
`;
export type CancelFavoriteTaskMutationFn = Apollo.MutationFunction<
  CancelFavoriteTaskMutation,
  CancelFavoriteTaskMutationVariables
>;

/**
 * __useCancelFavoriteTaskMutation__
 *
 * To run a mutation, you first call `useCancelFavoriteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelFavoriteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelFavoriteTaskMutation, { data, loading, error }] = useCancelFavoriteTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelFavoriteTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelFavoriteTaskMutation,
    CancelFavoriteTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelFavoriteTaskMutation, CancelFavoriteTaskMutationVariables>(
    CancelFavoriteTaskDocument,
    options
  );
}
export type CancelFavoriteTaskMutationHookResult = ReturnType<typeof useCancelFavoriteTaskMutation>;
export type CancelFavoriteTaskMutationResult = Apollo.MutationResult<CancelFavoriteTaskMutation>;
export type CancelFavoriteTaskMutationOptions = Apollo.BaseMutationOptions<
  CancelFavoriteTaskMutation,
  CancelFavoriteTaskMutationVariables
>;
export const ChangeContractPeriodDocument = gql`
  mutation changeContractPeriod($id: ID!, $input: ChangeContractPeriodInputInput) {
    changeContractPeriod(id: $id, input: $input) {
      billToMailAddress
      billingType
      contractPreriod
      freeLicenceCoupon {
        freeEndLicenceCount
        freeStartLicenceCount
        id
      }
      freeTrialExpireDatetime
      id
      licenceCount
      plan {
        code
        contractPreriod
        id
      }
      revenuecatOriginalAppUserId
      usingLicenceCount
      versionNo
    }
  }
`;
export type ChangeContractPeriodMutationFn = Apollo.MutationFunction<
  ChangeContractPeriodMutation,
  ChangeContractPeriodMutationVariables
>;

/**
 * __useChangeContractPeriodMutation__
 *
 * To run a mutation, you first call `useChangeContractPeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeContractPeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeContractPeriodMutation, { data, loading, error }] = useChangeContractPeriodMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeContractPeriodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeContractPeriodMutation,
    ChangeContractPeriodMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangeContractPeriodMutation, ChangeContractPeriodMutationVariables>(
    ChangeContractPeriodDocument,
    options
  );
}
export type ChangeContractPeriodMutationHookResult = ReturnType<
  typeof useChangeContractPeriodMutation
>;
export type ChangeContractPeriodMutationResult =
  Apollo.MutationResult<ChangeContractPeriodMutation>;
export type ChangeContractPeriodMutationOptions = Apollo.BaseMutationOptions<
  ChangeContractPeriodMutation,
  ChangeContractPeriodMutationVariables
>;
export const ChangePlanDocument = gql`
  mutation changePlan($id: ID!, $input: ChangePlanInputInput) {
    changePlan(id: $id, input: $input) {
      billToMailAddress
      billingType
      contractPreriod
      freeLicenceCoupon {
        freeEndLicenceCount
        freeStartLicenceCount
        id
      }
      freeTrialExpireDatetime
      id
      licenceCount
      plan {
        code
        contractPreriod
        id
      }
      revenuecatOriginalAppUserId
      usingLicenceCount
      versionNo
    }
  }
`;
export type ChangePlanMutationFn = Apollo.MutationFunction<
  ChangePlanMutation,
  ChangePlanMutationVariables
>;

/**
 * __useChangePlanMutation__
 *
 * To run a mutation, you first call `useChangePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePlanMutation, { data, loading, error }] = useChangePlanMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePlanMutation(
  baseOptions?: Apollo.MutationHookOptions<ChangePlanMutation, ChangePlanMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangePlanMutation, ChangePlanMutationVariables>(
    ChangePlanDocument,
    options
  );
}
export type ChangePlanMutationHookResult = ReturnType<typeof useChangePlanMutation>;
export type ChangePlanMutationResult = Apollo.MutationResult<ChangePlanMutation>;
export type ChangePlanMutationOptions = Apollo.BaseMutationOptions<
  ChangePlanMutation,
  ChangePlanMutationVariables
>;
export const CloseAllTeamMenuDocument = gql`
  mutation closeAllTeamMenu {
    closeAllTeamMenu {
      id
      openMenu
      sortNo
      team {
        archived
        id
        name
        organization {
          allowConnectThirdPartyCalendar
          contactMailAddress
          contractPreriod
          id
          imageUrl
          ipRestrictionFlg
          name
          plan {
            code
            contractPreriod
            id
          }
          requiredTwoFactorAuth
          suspensionFlg
          versionNo
        }
        personalTeam
        status
        versionNo
      }
      versionNo
    }
  }
`;
export type CloseAllTeamMenuMutationFn = Apollo.MutationFunction<
  CloseAllTeamMenuMutation,
  CloseAllTeamMenuMutationVariables
>;

/**
 * __useCloseAllTeamMenuMutation__
 *
 * To run a mutation, you first call `useCloseAllTeamMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseAllTeamMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeAllTeamMenuMutation, { data, loading, error }] = useCloseAllTeamMenuMutation({
 *   variables: {
 *   },
 * });
 */
export function useCloseAllTeamMenuMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CloseAllTeamMenuMutation,
    CloseAllTeamMenuMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CloseAllTeamMenuMutation, CloseAllTeamMenuMutationVariables>(
    CloseAllTeamMenuDocument,
    options
  );
}
export type CloseAllTeamMenuMutationHookResult = ReturnType<typeof useCloseAllTeamMenuMutation>;
export type CloseAllTeamMenuMutationResult = Apollo.MutationResult<CloseAllTeamMenuMutation>;
export type CloseAllTeamMenuMutationOptions = Apollo.BaseMutationOptions<
  CloseAllTeamMenuMutation,
  CloseAllTeamMenuMutationVariables
>;
export const CloseTermDocument = gql`
  mutation closeTerm($input: CloseInputInput) {
    closeTerm(input: $input) {
      id
      targetDateTime
      timeZone {
        code
        id
        name
        offsetTime
        sortNo
      }
      versionNo
    }
  }
`;
export type CloseTermMutationFn = Apollo.MutationFunction<
  CloseTermMutation,
  CloseTermMutationVariables
>;

/**
 * __useCloseTermMutation__
 *
 * To run a mutation, you first call `useCloseTermMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseTermMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeTermMutation, { data, loading, error }] = useCloseTermMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCloseTermMutation(
  baseOptions?: Apollo.MutationHookOptions<CloseTermMutation, CloseTermMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CloseTermMutation, CloseTermMutationVariables>(
    CloseTermDocument,
    options
  );
}
export type CloseTermMutationHookResult = ReturnType<typeof useCloseTermMutation>;
export type CloseTermMutationResult = Apollo.MutationResult<CloseTermMutation>;
export type CloseTermMutationOptions = Apollo.BaseMutationOptions<
  CloseTermMutation,
  CloseTermMutationVariables
>;
export const CompleteProjectDocument = gql`
  mutation completeProject($id: ID!, $input: CompleteProjectInputInput) {
    completeProject(id: $id, input: $input) {
      assignees {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      calendarLinkageCode
      client {
        code
        id
        name
        sortNo
        versionNo
      }
      color
      complete
      completeDateTime
      costBudgetWithoutTax
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      favoriteMembers {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      favoriteMembersInfo {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      id
      key
      laborCost
      laborCostWithoutTax
      laborShare
      name
      priority
      projectStatus {
        endStatus
        id
        name
        sortNo
        teamId
        versionNo
      }
      registerDateTime
      salesAmountWithoutTax
      scheduledEndDateTime
      scheduledStartDateTime
      sortNoInGanttChart
      sortNoInList
      sortNoInProjectStatus
      team {
        archived
        id
        name
        organization {
          allowConnectThirdPartyCalendar
          contactMailAddress
          contractPreriod
          id
          imageUrl
          ipRestrictionFlg
          name
          plan {
            code
            contractPreriod
            id
          }
          requiredTwoFactorAuth
          suspensionFlg
          versionNo
        }
        personalTeam
        status
        versionNo
      }
      totalScheduleTimeSec
      versionNo
      workingTimeSec
    }
  }
`;
export type CompleteProjectMutationFn = Apollo.MutationFunction<
  CompleteProjectMutation,
  CompleteProjectMutationVariables
>;

/**
 * __useCompleteProjectMutation__
 *
 * To run a mutation, you first call `useCompleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeProjectMutation, { data, loading, error }] = useCompleteProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteProjectMutation,
    CompleteProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CompleteProjectMutation, CompleteProjectMutationVariables>(
    CompleteProjectDocument,
    options
  );
}
export type CompleteProjectMutationHookResult = ReturnType<typeof useCompleteProjectMutation>;
export type CompleteProjectMutationResult = Apollo.MutationResult<CompleteProjectMutation>;
export type CompleteProjectMutationOptions = Apollo.BaseMutationOptions<
  CompleteProjectMutation,
  CompleteProjectMutationVariables
>;
export const CompleteSubTaskDocument = gql`
  mutation completeSubTask($id: ID!, $input: CompleteSubTaskInputInput!) {
    completeSubTask(id: $id, input: $input) {
      complete
      completeDateTime
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      id
      sortNo
      task {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        asssignee {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        calendarLinkageCode
        completeDateTime
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        estimatedRemainingTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        laborCost
        priority
        progressDeviationRate
        progressRate
        project {
          assignees {
            sortNo
          }
          calendarLinkageCode
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          estimateTimeSec
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            sortNo
          }
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          projectStatus {
            endStatus
            id
            name
            sortNo
            teamId
            versionNo
          }
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          team {
            archived
            id
            name
            organization {
              allowConnectThirdPartyCalendar
              contactMailAddress
              contractPreriod
              id
              imageUrl
              ipRestrictionFlg
              name
              plan {
                code
                contractPreriod
                id
              }
              requiredTwoFactorAuth
              suspensionFlg
              versionNo
            }
            personalTeam
            status
            versionNo
          }
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        registerDateTime
        remainingTimeSec
        scheduleWorkingTimeSec
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInMyTask
        sortNoInTaskStatus
        taskStatus {
          endStatus
          id
          name
          project {
            calendarLinkageCode
            color
            complete
            completeDateTime
            costBudgetWithoutTax
            description
            estimateTimeSec
            id
            key
            laborCost
            laborCostWithoutTax
            laborShare
            name
            priority
            registerDateTime
            salesAmountWithoutTax
            scheduledEndDateTime
            scheduledStartDateTime
            sortNoInGanttChart
            sortNoInList
            sortNoInProjectStatus
            totalScheduleTimeSec
            versionNo
            workingTimeSec
          }
          sortNo
          versionNo
        }
        title
        versionNo
        workingMembers {
          lastWorkingStartDateTime
          lastWorkingStopDateTime
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
        }
        workingTimeSec
      }
      title
      versionNo
    }
  }
`;
export type CompleteSubTaskMutationFn = Apollo.MutationFunction<
  CompleteSubTaskMutation,
  CompleteSubTaskMutationVariables
>;

/**
 * __useCompleteSubTaskMutation__
 *
 * To run a mutation, you first call `useCompleteSubTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteSubTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeSubTaskMutation, { data, loading, error }] = useCompleteSubTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteSubTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteSubTaskMutation,
    CompleteSubTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CompleteSubTaskMutation, CompleteSubTaskMutationVariables>(
    CompleteSubTaskDocument,
    options
  );
}
export type CompleteSubTaskMutationHookResult = ReturnType<typeof useCompleteSubTaskMutation>;
export type CompleteSubTaskMutationResult = Apollo.MutationResult<CompleteSubTaskMutation>;
export type CompleteSubTaskMutationOptions = Apollo.BaseMutationOptions<
  CompleteSubTaskMutation,
  CompleteSubTaskMutationVariables
>;
export const CompleteTaskDocument = gql`
  mutation completeTask($id: ID!, $input: CompleteTaskInputInput!) {
    completeTask(id: $id, input: $input) {
      stopWorkingHistory {
        calendarEvent {
          calendarType
          clientId
          clientName
          endDateTime
          eventName
          id
          laborCost
          personalTeam
          projectColor
          projectId
          projectName
          startDateTime
          taskId
          taskTitle
          teamId
          teamName
          thirdPartyCalendarEventId
          thirdPartyCalendarInfoList {
            member {
              adminRole
              clientManagementRole
              department
              employeeNumber
              id
              laborCostManagementRole
              mailAddress
              memberManagementRole
              memberRegistrationDateTime
              memberStatus
              name
              organizationMemberViewRole
              organizationReportRole
              profileImageUrl
              projectCreatePermissionFlg
              projectCustomAttributePermissionFlg
              projectDeletePermissionFlg
              projectUpdatePermissionFlg
              proxyEditWorkingDataRole
              salesManagementRole
              statusMessage
              taskCreatePermissionFlg
              taskCustomAttributePermissionFlg
              taskDeletePermissionFlg
              taskUpdatePermissionFlg
              teamMemberViewRole
              teamReportRole
              versionNo
            }
            thirdPartyCalendarId
          }
          versionNo
          workingTimeSec
        }
        closingProcessInfo {
          closed
          targetDateTime
        }
        end
        id
        memo
        start
        task {
          assignees {
            member {
              adminRole
              clientManagementRole
              department
              employeeNumber
              id
              laborCostManagementRole
              mailAddress
              memberManagementRole
              memberRegistrationDateTime
              memberStatus
              name
              organizationMemberViewRole
              organizationReportRole
              profileImageUrl
              projectCreatePermissionFlg
              projectCustomAttributePermissionFlg
              projectDeletePermissionFlg
              projectUpdatePermissionFlg
              proxyEditWorkingDataRole
              salesManagementRole
              statusMessage
              taskCreatePermissionFlg
              taskCustomAttributePermissionFlg
              taskDeletePermissionFlg
              taskUpdatePermissionFlg
              teamMemberViewRole
              teamReportRole
              versionNo
            }
            sortNo
          }
          asssignee {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          calendarLinkageCode
          completeDateTime
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          estimateTimeSec
          estimatedRemainingTimeSec
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            member {
              adminRole
              clientManagementRole
              department
              employeeNumber
              id
              laborCostManagementRole
              mailAddress
              memberManagementRole
              memberRegistrationDateTime
              memberStatus
              name
              organizationMemberViewRole
              organizationReportRole
              profileImageUrl
              projectCreatePermissionFlg
              projectCustomAttributePermissionFlg
              projectDeletePermissionFlg
              projectUpdatePermissionFlg
              proxyEditWorkingDataRole
              salesManagementRole
              statusMessage
              taskCreatePermissionFlg
              taskCustomAttributePermissionFlg
              taskDeletePermissionFlg
              taskUpdatePermissionFlg
              teamMemberViewRole
              teamReportRole
              versionNo
            }
            sortNo
          }
          id
          laborCost
          priority
          progressDeviationRate
          progressRate
          project {
            assignees {
              sortNo
            }
            calendarLinkageCode
            client {
              code
              id
              name
              sortNo
              versionNo
            }
            color
            complete
            completeDateTime
            costBudgetWithoutTax
            createdMember {
              adminRole
              clientManagementRole
              department
              employeeNumber
              id
              laborCostManagementRole
              mailAddress
              memberManagementRole
              memberRegistrationDateTime
              memberStatus
              name
              organizationMemberViewRole
              organizationReportRole
              profileImageUrl
              projectCreatePermissionFlg
              projectCustomAttributePermissionFlg
              projectDeletePermissionFlg
              projectUpdatePermissionFlg
              proxyEditWorkingDataRole
              salesManagementRole
              statusMessage
              taskCreatePermissionFlg
              taskCustomAttributePermissionFlg
              taskDeletePermissionFlg
              taskUpdatePermissionFlg
              teamMemberViewRole
              teamReportRole
              versionNo
            }
            description
            estimateTimeSec
            favoriteMembers {
              adminRole
              clientManagementRole
              department
              employeeNumber
              id
              laborCostManagementRole
              mailAddress
              memberManagementRole
              memberRegistrationDateTime
              memberStatus
              name
              organizationMemberViewRole
              organizationReportRole
              profileImageUrl
              projectCreatePermissionFlg
              projectCustomAttributePermissionFlg
              projectDeletePermissionFlg
              projectUpdatePermissionFlg
              proxyEditWorkingDataRole
              salesManagementRole
              statusMessage
              taskCreatePermissionFlg
              taskCustomAttributePermissionFlg
              taskDeletePermissionFlg
              taskUpdatePermissionFlg
              teamMemberViewRole
              teamReportRole
              versionNo
            }
            favoriteMembersInfo {
              sortNo
            }
            id
            key
            laborCost
            laborCostWithoutTax
            laborShare
            name
            priority
            projectStatus {
              endStatus
              id
              name
              sortNo
              teamId
              versionNo
            }
            registerDateTime
            salesAmountWithoutTax
            scheduledEndDateTime
            scheduledStartDateTime
            sortNoInGanttChart
            sortNoInList
            sortNoInProjectStatus
            team {
              archived
              id
              name
              organization {
                allowConnectThirdPartyCalendar
                contactMailAddress
                contractPreriod
                id
                imageUrl
                ipRestrictionFlg
                name
                plan {
                  code
                  contractPreriod
                  id
                }
                requiredTwoFactorAuth
                suspensionFlg
                versionNo
              }
              personalTeam
              status
              versionNo
            }
            totalScheduleTimeSec
            versionNo
            workingTimeSec
          }
          registerDateTime
          remainingTimeSec
          scheduleWorkingTimeSec
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInMyTask
          sortNoInTaskStatus
          taskStatus {
            endStatus
            id
            name
            project {
              calendarLinkageCode
              color
              complete
              completeDateTime
              costBudgetWithoutTax
              description
              estimateTimeSec
              id
              key
              laborCost
              laborCostWithoutTax
              laborShare
              name
              priority
              registerDateTime
              salesAmountWithoutTax
              scheduledEndDateTime
              scheduledStartDateTime
              sortNoInGanttChart
              sortNoInList
              sortNoInProjectStatus
              totalScheduleTimeSec
              versionNo
              workingTimeSec
            }
            sortNo
            versionNo
          }
          title
          versionNo
          workingMembers {
            lastWorkingStartDateTime
            lastWorkingStopDateTime
            member {
              adminRole
              clientManagementRole
              department
              employeeNumber
              id
              laborCostManagementRole
              mailAddress
              memberManagementRole
              memberRegistrationDateTime
              memberStatus
              name
              organizationMemberViewRole
              organizationReportRole
              profileImageUrl
              projectCreatePermissionFlg
              projectCustomAttributePermissionFlg
              projectDeletePermissionFlg
              projectUpdatePermissionFlg
              proxyEditWorkingDataRole
              salesManagementRole
              statusMessage
              taskCreatePermissionFlg
              taskCustomAttributePermissionFlg
              taskDeletePermissionFlg
              taskUpdatePermissionFlg
              teamMemberViewRole
              teamReportRole
              versionNo
            }
          }
          workingTimeSec
        }
        versionNo
        workingMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        workingTimeSec
      }
      task {
        calendarLinkageCode
        completeDateTime
        description
        estimateTimeSec
        estimatedRemainingTimeSec
        id
        laborCost
        priority
        progressDeviationRate
        progressRate
        registerDateTime
        remainingTimeSec
        scheduleWorkingTimeSec
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInMyTask
        sortNoInTaskStatus
        title
        versionNo
        workingTimeSec
      }
    }
  }
`;
export type CompleteTaskMutationFn = Apollo.MutationFunction<
  CompleteTaskMutation,
  CompleteTaskMutationVariables
>;

/**
 * __useCompleteTaskMutation__
 *
 * To run a mutation, you first call `useCompleteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeTaskMutation, { data, loading, error }] = useCompleteTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<CompleteTaskMutation, CompleteTaskMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CompleteTaskMutation, CompleteTaskMutationVariables>(
    CompleteTaskDocument,
    options
  );
}
export type CompleteTaskMutationHookResult = ReturnType<typeof useCompleteTaskMutation>;
export type CompleteTaskMutationResult = Apollo.MutationResult<CompleteTaskMutation>;
export type CompleteTaskMutationOptions = Apollo.BaseMutationOptions<
  CompleteTaskMutation,
  CompleteTaskMutationVariables
>;
export const ConnectAppleAccountDocument = gql`
  mutation connectAppleAccount($input: ConnectAppleAccountInputInput) {
    connectAppleAccount(input: $input)
  }
`;
export type ConnectAppleAccountMutationFn = Apollo.MutationFunction<
  ConnectAppleAccountMutation,
  ConnectAppleAccountMutationVariables
>;

/**
 * __useConnectAppleAccountMutation__
 *
 * To run a mutation, you first call `useConnectAppleAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectAppleAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectAppleAccountMutation, { data, loading, error }] = useConnectAppleAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConnectAppleAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConnectAppleAccountMutation,
    ConnectAppleAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ConnectAppleAccountMutation, ConnectAppleAccountMutationVariables>(
    ConnectAppleAccountDocument,
    options
  );
}
export type ConnectAppleAccountMutationHookResult = ReturnType<
  typeof useConnectAppleAccountMutation
>;
export type ConnectAppleAccountMutationResult = Apollo.MutationResult<ConnectAppleAccountMutation>;
export type ConnectAppleAccountMutationOptions = Apollo.BaseMutationOptions<
  ConnectAppleAccountMutation,
  ConnectAppleAccountMutationVariables
>;
export const ConnectGoogleAccountDocument = gql`
  mutation connectGoogleAccount($code: String!) {
    connectGoogleAccount(code: $code) {
      domain
      mailAddress
      name
      profileImageUrl
      registerd
      token
    }
  }
`;
export type ConnectGoogleAccountMutationFn = Apollo.MutationFunction<
  ConnectGoogleAccountMutation,
  ConnectGoogleAccountMutationVariables
>;

/**
 * __useConnectGoogleAccountMutation__
 *
 * To run a mutation, you first call `useConnectGoogleAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectGoogleAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectGoogleAccountMutation, { data, loading, error }] = useConnectGoogleAccountMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useConnectGoogleAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConnectGoogleAccountMutation,
    ConnectGoogleAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ConnectGoogleAccountMutation, ConnectGoogleAccountMutationVariables>(
    ConnectGoogleAccountDocument,
    options
  );
}
export type ConnectGoogleAccountMutationHookResult = ReturnType<
  typeof useConnectGoogleAccountMutation
>;
export type ConnectGoogleAccountMutationResult =
  Apollo.MutationResult<ConnectGoogleAccountMutation>;
export type ConnectGoogleAccountMutationOptions = Apollo.BaseMutationOptions<
  ConnectGoogleAccountMutation,
  ConnectGoogleAccountMutationVariables
>;
export const CopyProjectDocument = gql`
  mutation copyProject($id: ID!, $input: CopyProjectInputInput) {
    copyProject(id: $id, input: $input) {
      assignees {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      calendarLinkageCode
      client {
        code
        id
        name
        sortNo
        versionNo
      }
      color
      complete
      completeDateTime
      costBudgetWithoutTax
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      favoriteMembers {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      favoriteMembersInfo {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      id
      key
      laborCost
      laborCostWithoutTax
      laborShare
      name
      priority
      projectStatus {
        endStatus
        id
        name
        sortNo
        teamId
        versionNo
      }
      registerDateTime
      salesAmountWithoutTax
      scheduledEndDateTime
      scheduledStartDateTime
      sortNoInGanttChart
      sortNoInList
      sortNoInProjectStatus
      team {
        archived
        id
        name
        organization {
          allowConnectThirdPartyCalendar
          contactMailAddress
          contractPreriod
          id
          imageUrl
          ipRestrictionFlg
          name
          plan {
            code
            contractPreriod
            id
          }
          requiredTwoFactorAuth
          suspensionFlg
          versionNo
        }
        personalTeam
        status
        versionNo
      }
      totalScheduleTimeSec
      versionNo
      workingTimeSec
    }
  }
`;
export type CopyProjectMutationFn = Apollo.MutationFunction<
  CopyProjectMutation,
  CopyProjectMutationVariables
>;

/**
 * __useCopyProjectMutation__
 *
 * To run a mutation, you first call `useCopyProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyProjectMutation, { data, loading, error }] = useCopyProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<CopyProjectMutation, CopyProjectMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CopyProjectMutation, CopyProjectMutationVariables>(
    CopyProjectDocument,
    options
  );
}
export type CopyProjectMutationHookResult = ReturnType<typeof useCopyProjectMutation>;
export type CopyProjectMutationResult = Apollo.MutationResult<CopyProjectMutation>;
export type CopyProjectMutationOptions = Apollo.BaseMutationOptions<
  CopyProjectMutation,
  CopyProjectMutationVariables
>;
export const CopyTaskDocument = gql`
  mutation copyTask($id: ID!, $input: CopyTaskInputInput!) {
    copyTask(id: $id, input: $input) {
      assignees {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      asssignee {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      calendarLinkageCode
      completeDateTime
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      estimatedRemainingTimeSec
      favoriteMembers {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      favoriteMembersInfo {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      id
      laborCost
      priority
      progressDeviationRate
      progressRate
      project {
        assignees {
          sortNo
        }
        calendarLinkageCode
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        color
        complete
        completeDateTime
        costBudgetWithoutTax
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          sortNo
        }
        id
        key
        laborCost
        laborCostWithoutTax
        laborShare
        name
        priority
        projectStatus {
          endStatus
          id
          name
          sortNo
          teamId
          versionNo
        }
        registerDateTime
        salesAmountWithoutTax
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInProjectStatus
        team {
          archived
          id
          name
          organization {
            allowConnectThirdPartyCalendar
            contactMailAddress
            contractPreriod
            id
            imageUrl
            ipRestrictionFlg
            name
            plan {
              code
              contractPreriod
              id
            }
            requiredTwoFactorAuth
            suspensionFlg
            versionNo
          }
          personalTeam
          status
          versionNo
        }
        totalScheduleTimeSec
        versionNo
        workingTimeSec
      }
      registerDateTime
      remainingTimeSec
      scheduleWorkingTimeSec
      scheduledEndDateTime
      scheduledStartDateTime
      sortNoInGanttChart
      sortNoInList
      sortNoInMyTask
      sortNoInTaskStatus
      taskStatus {
        endStatus
        id
        name
        project {
          calendarLinkageCode
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          description
          estimateTimeSec
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        sortNo
        versionNo
      }
      title
      versionNo
      workingMembers {
        lastWorkingStartDateTime
        lastWorkingStopDateTime
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
      }
      workingTimeSec
    }
  }
`;
export type CopyTaskMutationFn = Apollo.MutationFunction<
  CopyTaskMutation,
  CopyTaskMutationVariables
>;

/**
 * __useCopyTaskMutation__
 *
 * To run a mutation, you first call `useCopyTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyTaskMutation, { data, loading, error }] = useCopyTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<CopyTaskMutation, CopyTaskMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CopyTaskMutation, CopyTaskMutationVariables>(CopyTaskDocument, options);
}
export type CopyTaskMutationHookResult = ReturnType<typeof useCopyTaskMutation>;
export type CopyTaskMutationResult = Apollo.MutationResult<CopyTaskMutation>;
export type CopyTaskMutationOptions = Apollo.BaseMutationOptions<
  CopyTaskMutation,
  CopyTaskMutationVariables
>;
export const CreateAttachementFileDownloadUrlDocument = gql`
  mutation createAttachementFileDownloadUrl($input: CreateAttachementFileDownloadUrlInputInput!) {
    createAttachementFileDownloadUrl(input: $input) {
      downloadUrl
    }
  }
`;
export type CreateAttachementFileDownloadUrlMutationFn = Apollo.MutationFunction<
  CreateAttachementFileDownloadUrlMutation,
  CreateAttachementFileDownloadUrlMutationVariables
>;

/**
 * __useCreateAttachementFileDownloadUrlMutation__
 *
 * To run a mutation, you first call `useCreateAttachementFileDownloadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAttachementFileDownloadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAttachementFileDownloadUrlMutation, { data, loading, error }] = useCreateAttachementFileDownloadUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAttachementFileDownloadUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAttachementFileDownloadUrlMutation,
    CreateAttachementFileDownloadUrlMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAttachementFileDownloadUrlMutation,
    CreateAttachementFileDownloadUrlMutationVariables
  >(CreateAttachementFileDownloadUrlDocument, options);
}
export type CreateAttachementFileDownloadUrlMutationHookResult = ReturnType<
  typeof useCreateAttachementFileDownloadUrlMutation
>;
export type CreateAttachementFileDownloadUrlMutationResult =
  Apollo.MutationResult<CreateAttachementFileDownloadUrlMutation>;
export type CreateAttachementFileDownloadUrlMutationOptions = Apollo.BaseMutationOptions<
  CreateAttachementFileDownloadUrlMutation,
  CreateAttachementFileDownloadUrlMutationVariables
>;
export const CreateAttachementFileForProjectDocument = gql`
  mutation createAttachementFileForProject($input: CreateProjectAttachmentFileInputInput!) {
    createAttachementFileForProject(input: $input) {
      fileName
      id
      sizeByte
      type
      uploadDateTime
    }
  }
`;
export type CreateAttachementFileForProjectMutationFn = Apollo.MutationFunction<
  CreateAttachementFileForProjectMutation,
  CreateAttachementFileForProjectMutationVariables
>;

/**
 * __useCreateAttachementFileForProjectMutation__
 *
 * To run a mutation, you first call `useCreateAttachementFileForProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAttachementFileForProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAttachementFileForProjectMutation, { data, loading, error }] = useCreateAttachementFileForProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAttachementFileForProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAttachementFileForProjectMutation,
    CreateAttachementFileForProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAttachementFileForProjectMutation,
    CreateAttachementFileForProjectMutationVariables
  >(CreateAttachementFileForProjectDocument, options);
}
export type CreateAttachementFileForProjectMutationHookResult = ReturnType<
  typeof useCreateAttachementFileForProjectMutation
>;
export type CreateAttachementFileForProjectMutationResult =
  Apollo.MutationResult<CreateAttachementFileForProjectMutation>;
export type CreateAttachementFileForProjectMutationOptions = Apollo.BaseMutationOptions<
  CreateAttachementFileForProjectMutation,
  CreateAttachementFileForProjectMutationVariables
>;
export const CreateAttachementFileForTaskDocument = gql`
  mutation createAttachementFileForTask($input: CreateTaskAttachmentFileInputInput!) {
    createAttachementFileForTask(input: $input) {
      fileName
      id
      sizeByte
      type
      uploadDateTime
    }
  }
`;
export type CreateAttachementFileForTaskMutationFn = Apollo.MutationFunction<
  CreateAttachementFileForTaskMutation,
  CreateAttachementFileForTaskMutationVariables
>;

/**
 * __useCreateAttachementFileForTaskMutation__
 *
 * To run a mutation, you first call `useCreateAttachementFileForTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAttachementFileForTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAttachementFileForTaskMutation, { data, loading, error }] = useCreateAttachementFileForTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAttachementFileForTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAttachementFileForTaskMutation,
    CreateAttachementFileForTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAttachementFileForTaskMutation,
    CreateAttachementFileForTaskMutationVariables
  >(CreateAttachementFileForTaskDocument, options);
}
export type CreateAttachementFileForTaskMutationHookResult = ReturnType<
  typeof useCreateAttachementFileForTaskMutation
>;
export type CreateAttachementFileForTaskMutationResult =
  Apollo.MutationResult<CreateAttachementFileForTaskMutation>;
export type CreateAttachementFileForTaskMutationOptions = Apollo.BaseMutationOptions<
  CreateAttachementFileForTaskMutation,
  CreateAttachementFileForTaskMutationVariables
>;
export const CreateAttachementFileUploadUrlDocument = gql`
  mutation createAttachementFileUploadUrl($input: CreateAttachementFileUploadUrlInputInput!) {
    createAttachementFileUploadUrl(input: $input) {
      fileName
      key
      sizeByte
      type
      uploadUrl
    }
  }
`;
export type CreateAttachementFileUploadUrlMutationFn = Apollo.MutationFunction<
  CreateAttachementFileUploadUrlMutation,
  CreateAttachementFileUploadUrlMutationVariables
>;

/**
 * __useCreateAttachementFileUploadUrlMutation__
 *
 * To run a mutation, you first call `useCreateAttachementFileUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAttachementFileUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAttachementFileUploadUrlMutation, { data, loading, error }] = useCreateAttachementFileUploadUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAttachementFileUploadUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAttachementFileUploadUrlMutation,
    CreateAttachementFileUploadUrlMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAttachementFileUploadUrlMutation,
    CreateAttachementFileUploadUrlMutationVariables
  >(CreateAttachementFileUploadUrlDocument, options);
}
export type CreateAttachementFileUploadUrlMutationHookResult = ReturnType<
  typeof useCreateAttachementFileUploadUrlMutation
>;
export type CreateAttachementFileUploadUrlMutationResult =
  Apollo.MutationResult<CreateAttachementFileUploadUrlMutation>;
export type CreateAttachementFileUploadUrlMutationOptions = Apollo.BaseMutationOptions<
  CreateAttachementFileUploadUrlMutation,
  CreateAttachementFileUploadUrlMutationVariables
>;
export const CreateCalendarEventWithTaskAndWorkingHistoryDocument = gql`
  mutation createCalendarEventWithTaskAndWorkingHistory(
    $input: RegisterCalendarEventWithTaskInputInput!
    $projectId: String!
  ) {
    createCalendarEventWithTaskAndWorkingHistory(input: $input, projectId: $projectId) {
      calendarEvent {
        calendarType
        clientId
        clientName
        endDateTime
        eventName
        id
        laborCost
        personalTeam
        projectColor
        projectId
        projectName
        startDateTime
        taskId
        taskTitle
        teamId
        teamName
        thirdPartyCalendarEventId
        thirdPartyCalendarInfoList {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          thirdPartyCalendarId
        }
        versionNo
        workingTimeSec
      }
      workingHistory {
        calendarEvent {
          calendarType
          clientId
          clientName
          endDateTime
          eventName
          id
          laborCost
          personalTeam
          projectColor
          projectId
          projectName
          startDateTime
          taskId
          taskTitle
          teamId
          teamName
          thirdPartyCalendarEventId
          versionNo
          workingTimeSec
        }
        closingProcessInfo {
          closed
          targetDateTime
        }
        end
        id
        memo
        start
        task {
          assignees {
            member {
              adminRole
              clientManagementRole
              department
              employeeNumber
              id
              laborCostManagementRole
              mailAddress
              memberManagementRole
              memberRegistrationDateTime
              memberStatus
              name
              organizationMemberViewRole
              organizationReportRole
              profileImageUrl
              projectCreatePermissionFlg
              projectCustomAttributePermissionFlg
              projectDeletePermissionFlg
              projectUpdatePermissionFlg
              proxyEditWorkingDataRole
              salesManagementRole
              statusMessage
              taskCreatePermissionFlg
              taskCustomAttributePermissionFlg
              taskDeletePermissionFlg
              taskUpdatePermissionFlg
              teamMemberViewRole
              teamReportRole
              versionNo
            }
            sortNo
          }
          asssignee {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          calendarLinkageCode
          completeDateTime
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          estimateTimeSec
          estimatedRemainingTimeSec
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            member {
              adminRole
              clientManagementRole
              department
              employeeNumber
              id
              laborCostManagementRole
              mailAddress
              memberManagementRole
              memberRegistrationDateTime
              memberStatus
              name
              organizationMemberViewRole
              organizationReportRole
              profileImageUrl
              projectCreatePermissionFlg
              projectCustomAttributePermissionFlg
              projectDeletePermissionFlg
              projectUpdatePermissionFlg
              proxyEditWorkingDataRole
              salesManagementRole
              statusMessage
              taskCreatePermissionFlg
              taskCustomAttributePermissionFlg
              taskDeletePermissionFlg
              taskUpdatePermissionFlg
              teamMemberViewRole
              teamReportRole
              versionNo
            }
            sortNo
          }
          id
          laborCost
          priority
          progressDeviationRate
          progressRate
          project {
            assignees {
              sortNo
            }
            calendarLinkageCode
            client {
              code
              id
              name
              sortNo
              versionNo
            }
            color
            complete
            completeDateTime
            costBudgetWithoutTax
            createdMember {
              adminRole
              clientManagementRole
              department
              employeeNumber
              id
              laborCostManagementRole
              mailAddress
              memberManagementRole
              memberRegistrationDateTime
              memberStatus
              name
              organizationMemberViewRole
              organizationReportRole
              profileImageUrl
              projectCreatePermissionFlg
              projectCustomAttributePermissionFlg
              projectDeletePermissionFlg
              projectUpdatePermissionFlg
              proxyEditWorkingDataRole
              salesManagementRole
              statusMessage
              taskCreatePermissionFlg
              taskCustomAttributePermissionFlg
              taskDeletePermissionFlg
              taskUpdatePermissionFlg
              teamMemberViewRole
              teamReportRole
              versionNo
            }
            description
            estimateTimeSec
            favoriteMembers {
              adminRole
              clientManagementRole
              department
              employeeNumber
              id
              laborCostManagementRole
              mailAddress
              memberManagementRole
              memberRegistrationDateTime
              memberStatus
              name
              organizationMemberViewRole
              organizationReportRole
              profileImageUrl
              projectCreatePermissionFlg
              projectCustomAttributePermissionFlg
              projectDeletePermissionFlg
              projectUpdatePermissionFlg
              proxyEditWorkingDataRole
              salesManagementRole
              statusMessage
              taskCreatePermissionFlg
              taskCustomAttributePermissionFlg
              taskDeletePermissionFlg
              taskUpdatePermissionFlg
              teamMemberViewRole
              teamReportRole
              versionNo
            }
            favoriteMembersInfo {
              sortNo
            }
            id
            key
            laborCost
            laborCostWithoutTax
            laborShare
            name
            priority
            projectStatus {
              endStatus
              id
              name
              sortNo
              teamId
              versionNo
            }
            registerDateTime
            salesAmountWithoutTax
            scheduledEndDateTime
            scheduledStartDateTime
            sortNoInGanttChart
            sortNoInList
            sortNoInProjectStatus
            team {
              archived
              id
              name
              organization {
                allowConnectThirdPartyCalendar
                contactMailAddress
                contractPreriod
                id
                imageUrl
                ipRestrictionFlg
                name
                plan {
                  code
                  contractPreriod
                  id
                }
                requiredTwoFactorAuth
                suspensionFlg
                versionNo
              }
              personalTeam
              status
              versionNo
            }
            totalScheduleTimeSec
            versionNo
            workingTimeSec
          }
          registerDateTime
          remainingTimeSec
          scheduleWorkingTimeSec
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInMyTask
          sortNoInTaskStatus
          taskStatus {
            endStatus
            id
            name
            project {
              calendarLinkageCode
              color
              complete
              completeDateTime
              costBudgetWithoutTax
              description
              estimateTimeSec
              id
              key
              laborCost
              laborCostWithoutTax
              laborShare
              name
              priority
              registerDateTime
              salesAmountWithoutTax
              scheduledEndDateTime
              scheduledStartDateTime
              sortNoInGanttChart
              sortNoInList
              sortNoInProjectStatus
              totalScheduleTimeSec
              versionNo
              workingTimeSec
            }
            sortNo
            versionNo
          }
          title
          versionNo
          workingMembers {
            lastWorkingStartDateTime
            lastWorkingStopDateTime
            member {
              adminRole
              clientManagementRole
              department
              employeeNumber
              id
              laborCostManagementRole
              mailAddress
              memberManagementRole
              memberRegistrationDateTime
              memberStatus
              name
              organizationMemberViewRole
              organizationReportRole
              profileImageUrl
              projectCreatePermissionFlg
              projectCustomAttributePermissionFlg
              projectDeletePermissionFlg
              projectUpdatePermissionFlg
              proxyEditWorkingDataRole
              salesManagementRole
              statusMessage
              taskCreatePermissionFlg
              taskCustomAttributePermissionFlg
              taskDeletePermissionFlg
              taskUpdatePermissionFlg
              teamMemberViewRole
              teamReportRole
              versionNo
            }
          }
          workingTimeSec
        }
        versionNo
        workingMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        workingTimeSec
      }
    }
  }
`;
export type CreateCalendarEventWithTaskAndWorkingHistoryMutationFn = Apollo.MutationFunction<
  CreateCalendarEventWithTaskAndWorkingHistoryMutation,
  CreateCalendarEventWithTaskAndWorkingHistoryMutationVariables
>;

/**
 * __useCreateCalendarEventWithTaskAndWorkingHistoryMutation__
 *
 * To run a mutation, you first call `useCreateCalendarEventWithTaskAndWorkingHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCalendarEventWithTaskAndWorkingHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCalendarEventWithTaskAndWorkingHistoryMutation, { data, loading, error }] = useCreateCalendarEventWithTaskAndWorkingHistoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useCreateCalendarEventWithTaskAndWorkingHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCalendarEventWithTaskAndWorkingHistoryMutation,
    CreateCalendarEventWithTaskAndWorkingHistoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCalendarEventWithTaskAndWorkingHistoryMutation,
    CreateCalendarEventWithTaskAndWorkingHistoryMutationVariables
  >(CreateCalendarEventWithTaskAndWorkingHistoryDocument, options);
}
export type CreateCalendarEventWithTaskAndWorkingHistoryMutationHookResult = ReturnType<
  typeof useCreateCalendarEventWithTaskAndWorkingHistoryMutation
>;
export type CreateCalendarEventWithTaskAndWorkingHistoryMutationResult =
  Apollo.MutationResult<CreateCalendarEventWithTaskAndWorkingHistoryMutation>;
export type CreateCalendarEventWithTaskAndWorkingHistoryMutationOptions =
  Apollo.BaseMutationOptions<
    CreateCalendarEventWithTaskAndWorkingHistoryMutation,
    CreateCalendarEventWithTaskAndWorkingHistoryMutationVariables
  >;
export const CreateCalendarEventWithTaskAndWorkingScheduleDocument = gql`
  mutation createCalendarEventWithTaskAndWorkingSchedule(
    $input: RegisterCalendarEventWithTaskInputInput!
    $projectId: String!
  ) {
    createCalendarEventWithTaskAndWorkingSchedule(input: $input, projectId: $projectId) {
      calendarEvent {
        calendarType
        clientId
        clientName
        endDateTime
        eventName
        id
        laborCost
        personalTeam
        projectColor
        projectId
        projectName
        startDateTime
        taskId
        taskTitle
        teamId
        teamName
        thirdPartyCalendarEventId
        thirdPartyCalendarInfoList {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          thirdPartyCalendarId
        }
        versionNo
        workingTimeSec
      }
      workingSchedule {
        calendarEvent {
          calendarType
          clientId
          clientName
          endDateTime
          eventName
          id
          laborCost
          personalTeam
          projectColor
          projectId
          projectName
          startDateTime
          taskId
          taskTitle
          teamId
          teamName
          thirdPartyCalendarEventId
          versionNo
          workingTimeSec
        }
        end
        id
        memo
        start
        task {
          assignees {
            member {
              adminRole
              clientManagementRole
              department
              employeeNumber
              id
              laborCostManagementRole
              mailAddress
              memberManagementRole
              memberRegistrationDateTime
              memberStatus
              name
              organizationMemberViewRole
              organizationReportRole
              profileImageUrl
              projectCreatePermissionFlg
              projectCustomAttributePermissionFlg
              projectDeletePermissionFlg
              projectUpdatePermissionFlg
              proxyEditWorkingDataRole
              salesManagementRole
              statusMessage
              taskCreatePermissionFlg
              taskCustomAttributePermissionFlg
              taskDeletePermissionFlg
              taskUpdatePermissionFlg
              teamMemberViewRole
              teamReportRole
              versionNo
            }
            sortNo
          }
          asssignee {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          calendarLinkageCode
          completeDateTime
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          estimateTimeSec
          estimatedRemainingTimeSec
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            member {
              adminRole
              clientManagementRole
              department
              employeeNumber
              id
              laborCostManagementRole
              mailAddress
              memberManagementRole
              memberRegistrationDateTime
              memberStatus
              name
              organizationMemberViewRole
              organizationReportRole
              profileImageUrl
              projectCreatePermissionFlg
              projectCustomAttributePermissionFlg
              projectDeletePermissionFlg
              projectUpdatePermissionFlg
              proxyEditWorkingDataRole
              salesManagementRole
              statusMessage
              taskCreatePermissionFlg
              taskCustomAttributePermissionFlg
              taskDeletePermissionFlg
              taskUpdatePermissionFlg
              teamMemberViewRole
              teamReportRole
              versionNo
            }
            sortNo
          }
          id
          laborCost
          priority
          progressDeviationRate
          progressRate
          project {
            assignees {
              sortNo
            }
            calendarLinkageCode
            client {
              code
              id
              name
              sortNo
              versionNo
            }
            color
            complete
            completeDateTime
            costBudgetWithoutTax
            createdMember {
              adminRole
              clientManagementRole
              department
              employeeNumber
              id
              laborCostManagementRole
              mailAddress
              memberManagementRole
              memberRegistrationDateTime
              memberStatus
              name
              organizationMemberViewRole
              organizationReportRole
              profileImageUrl
              projectCreatePermissionFlg
              projectCustomAttributePermissionFlg
              projectDeletePermissionFlg
              projectUpdatePermissionFlg
              proxyEditWorkingDataRole
              salesManagementRole
              statusMessage
              taskCreatePermissionFlg
              taskCustomAttributePermissionFlg
              taskDeletePermissionFlg
              taskUpdatePermissionFlg
              teamMemberViewRole
              teamReportRole
              versionNo
            }
            description
            estimateTimeSec
            favoriteMembers {
              adminRole
              clientManagementRole
              department
              employeeNumber
              id
              laborCostManagementRole
              mailAddress
              memberManagementRole
              memberRegistrationDateTime
              memberStatus
              name
              organizationMemberViewRole
              organizationReportRole
              profileImageUrl
              projectCreatePermissionFlg
              projectCustomAttributePermissionFlg
              projectDeletePermissionFlg
              projectUpdatePermissionFlg
              proxyEditWorkingDataRole
              salesManagementRole
              statusMessage
              taskCreatePermissionFlg
              taskCustomAttributePermissionFlg
              taskDeletePermissionFlg
              taskUpdatePermissionFlg
              teamMemberViewRole
              teamReportRole
              versionNo
            }
            favoriteMembersInfo {
              sortNo
            }
            id
            key
            laborCost
            laborCostWithoutTax
            laborShare
            name
            priority
            projectStatus {
              endStatus
              id
              name
              sortNo
              teamId
              versionNo
            }
            registerDateTime
            salesAmountWithoutTax
            scheduledEndDateTime
            scheduledStartDateTime
            sortNoInGanttChart
            sortNoInList
            sortNoInProjectStatus
            team {
              archived
              id
              name
              organization {
                allowConnectThirdPartyCalendar
                contactMailAddress
                contractPreriod
                id
                imageUrl
                ipRestrictionFlg
                name
                plan {
                  code
                  contractPreriod
                  id
                }
                requiredTwoFactorAuth
                suspensionFlg
                versionNo
              }
              personalTeam
              status
              versionNo
            }
            totalScheduleTimeSec
            versionNo
            workingTimeSec
          }
          registerDateTime
          remainingTimeSec
          scheduleWorkingTimeSec
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInMyTask
          sortNoInTaskStatus
          taskStatus {
            endStatus
            id
            name
            project {
              calendarLinkageCode
              color
              complete
              completeDateTime
              costBudgetWithoutTax
              description
              estimateTimeSec
              id
              key
              laborCost
              laborCostWithoutTax
              laborShare
              name
              priority
              registerDateTime
              salesAmountWithoutTax
              scheduledEndDateTime
              scheduledStartDateTime
              sortNoInGanttChart
              sortNoInList
              sortNoInProjectStatus
              totalScheduleTimeSec
              versionNo
              workingTimeSec
            }
            sortNo
            versionNo
          }
          title
          versionNo
          workingMembers {
            lastWorkingStartDateTime
            lastWorkingStopDateTime
            member {
              adminRole
              clientManagementRole
              department
              employeeNumber
              id
              laborCostManagementRole
              mailAddress
              memberManagementRole
              memberRegistrationDateTime
              memberStatus
              name
              organizationMemberViewRole
              organizationReportRole
              profileImageUrl
              projectCreatePermissionFlg
              projectCustomAttributePermissionFlg
              projectDeletePermissionFlg
              projectUpdatePermissionFlg
              proxyEditWorkingDataRole
              salesManagementRole
              statusMessage
              taskCreatePermissionFlg
              taskCustomAttributePermissionFlg
              taskDeletePermissionFlg
              taskUpdatePermissionFlg
              teamMemberViewRole
              teamReportRole
              versionNo
            }
          }
          workingTimeSec
        }
        versionNo
        workingMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        workingTimeSec
      }
    }
  }
`;
export type CreateCalendarEventWithTaskAndWorkingScheduleMutationFn = Apollo.MutationFunction<
  CreateCalendarEventWithTaskAndWorkingScheduleMutation,
  CreateCalendarEventWithTaskAndWorkingScheduleMutationVariables
>;

/**
 * __useCreateCalendarEventWithTaskAndWorkingScheduleMutation__
 *
 * To run a mutation, you first call `useCreateCalendarEventWithTaskAndWorkingScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCalendarEventWithTaskAndWorkingScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCalendarEventWithTaskAndWorkingScheduleMutation, { data, loading, error }] = useCreateCalendarEventWithTaskAndWorkingScheduleMutation({
 *   variables: {
 *      input: // value for 'input'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useCreateCalendarEventWithTaskAndWorkingScheduleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCalendarEventWithTaskAndWorkingScheduleMutation,
    CreateCalendarEventWithTaskAndWorkingScheduleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCalendarEventWithTaskAndWorkingScheduleMutation,
    CreateCalendarEventWithTaskAndWorkingScheduleMutationVariables
  >(CreateCalendarEventWithTaskAndWorkingScheduleDocument, options);
}
export type CreateCalendarEventWithTaskAndWorkingScheduleMutationHookResult = ReturnType<
  typeof useCreateCalendarEventWithTaskAndWorkingScheduleMutation
>;
export type CreateCalendarEventWithTaskAndWorkingScheduleMutationResult =
  Apollo.MutationResult<CreateCalendarEventWithTaskAndWorkingScheduleMutation>;
export type CreateCalendarEventWithTaskAndWorkingScheduleMutationOptions =
  Apollo.BaseMutationOptions<
    CreateCalendarEventWithTaskAndWorkingScheduleMutation,
    CreateCalendarEventWithTaskAndWorkingScheduleMutationVariables
  >;
export const CreateCalendarEventWithWorkingHistoryDocument = gql`
  mutation createCalendarEventWithWorkingHistory(
    $input: RegisterCalendarEventInputInput!
    $projectId: String!
    $taskId: String
  ) {
    createCalendarEventWithWorkingHistory(input: $input, projectId: $projectId, taskId: $taskId) {
      calendarEvent {
        calendarType
        clientId
        clientName
        endDateTime
        eventName
        id
        laborCost
        personalTeam
        projectColor
        projectId
        projectName
        startDateTime
        taskId
        taskTitle
        teamId
        teamName
        thirdPartyCalendarEventId
        thirdPartyCalendarInfoList {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          thirdPartyCalendarId
        }
        versionNo
        workingTimeSec
      }
      workingHistory {
        calendarEvent {
          calendarType
          clientId
          clientName
          endDateTime
          eventName
          id
          laborCost
          personalTeam
          projectColor
          projectId
          projectName
          startDateTime
          taskId
          taskTitle
          teamId
          teamName
          thirdPartyCalendarEventId
          versionNo
          workingTimeSec
        }
        closingProcessInfo {
          closed
          targetDateTime
        }
        end
        id
        memo
        start
        task {
          assignees {
            member {
              adminRole
              clientManagementRole
              department
              employeeNumber
              id
              laborCostManagementRole
              mailAddress
              memberManagementRole
              memberRegistrationDateTime
              memberStatus
              name
              organizationMemberViewRole
              organizationReportRole
              profileImageUrl
              projectCreatePermissionFlg
              projectCustomAttributePermissionFlg
              projectDeletePermissionFlg
              projectUpdatePermissionFlg
              proxyEditWorkingDataRole
              salesManagementRole
              statusMessage
              taskCreatePermissionFlg
              taskCustomAttributePermissionFlg
              taskDeletePermissionFlg
              taskUpdatePermissionFlg
              teamMemberViewRole
              teamReportRole
              versionNo
            }
            sortNo
          }
          asssignee {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          calendarLinkageCode
          completeDateTime
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          estimateTimeSec
          estimatedRemainingTimeSec
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            member {
              adminRole
              clientManagementRole
              department
              employeeNumber
              id
              laborCostManagementRole
              mailAddress
              memberManagementRole
              memberRegistrationDateTime
              memberStatus
              name
              organizationMemberViewRole
              organizationReportRole
              profileImageUrl
              projectCreatePermissionFlg
              projectCustomAttributePermissionFlg
              projectDeletePermissionFlg
              projectUpdatePermissionFlg
              proxyEditWorkingDataRole
              salesManagementRole
              statusMessage
              taskCreatePermissionFlg
              taskCustomAttributePermissionFlg
              taskDeletePermissionFlg
              taskUpdatePermissionFlg
              teamMemberViewRole
              teamReportRole
              versionNo
            }
            sortNo
          }
          id
          laborCost
          priority
          progressDeviationRate
          progressRate
          project {
            assignees {
              sortNo
            }
            calendarLinkageCode
            client {
              code
              id
              name
              sortNo
              versionNo
            }
            color
            complete
            completeDateTime
            costBudgetWithoutTax
            createdMember {
              adminRole
              clientManagementRole
              department
              employeeNumber
              id
              laborCostManagementRole
              mailAddress
              memberManagementRole
              memberRegistrationDateTime
              memberStatus
              name
              organizationMemberViewRole
              organizationReportRole
              profileImageUrl
              projectCreatePermissionFlg
              projectCustomAttributePermissionFlg
              projectDeletePermissionFlg
              projectUpdatePermissionFlg
              proxyEditWorkingDataRole
              salesManagementRole
              statusMessage
              taskCreatePermissionFlg
              taskCustomAttributePermissionFlg
              taskDeletePermissionFlg
              taskUpdatePermissionFlg
              teamMemberViewRole
              teamReportRole
              versionNo
            }
            description
            estimateTimeSec
            favoriteMembers {
              adminRole
              clientManagementRole
              department
              employeeNumber
              id
              laborCostManagementRole
              mailAddress
              memberManagementRole
              memberRegistrationDateTime
              memberStatus
              name
              organizationMemberViewRole
              organizationReportRole
              profileImageUrl
              projectCreatePermissionFlg
              projectCustomAttributePermissionFlg
              projectDeletePermissionFlg
              projectUpdatePermissionFlg
              proxyEditWorkingDataRole
              salesManagementRole
              statusMessage
              taskCreatePermissionFlg
              taskCustomAttributePermissionFlg
              taskDeletePermissionFlg
              taskUpdatePermissionFlg
              teamMemberViewRole
              teamReportRole
              versionNo
            }
            favoriteMembersInfo {
              sortNo
            }
            id
            key
            laborCost
            laborCostWithoutTax
            laborShare
            name
            priority
            projectStatus {
              endStatus
              id
              name
              sortNo
              teamId
              versionNo
            }
            registerDateTime
            salesAmountWithoutTax
            scheduledEndDateTime
            scheduledStartDateTime
            sortNoInGanttChart
            sortNoInList
            sortNoInProjectStatus
            team {
              archived
              id
              name
              organization {
                allowConnectThirdPartyCalendar
                contactMailAddress
                contractPreriod
                id
                imageUrl
                ipRestrictionFlg
                name
                plan {
                  code
                  contractPreriod
                  id
                }
                requiredTwoFactorAuth
                suspensionFlg
                versionNo
              }
              personalTeam
              status
              versionNo
            }
            totalScheduleTimeSec
            versionNo
            workingTimeSec
          }
          registerDateTime
          remainingTimeSec
          scheduleWorkingTimeSec
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInMyTask
          sortNoInTaskStatus
          taskStatus {
            endStatus
            id
            name
            project {
              calendarLinkageCode
              color
              complete
              completeDateTime
              costBudgetWithoutTax
              description
              estimateTimeSec
              id
              key
              laborCost
              laborCostWithoutTax
              laborShare
              name
              priority
              registerDateTime
              salesAmountWithoutTax
              scheduledEndDateTime
              scheduledStartDateTime
              sortNoInGanttChart
              sortNoInList
              sortNoInProjectStatus
              totalScheduleTimeSec
              versionNo
              workingTimeSec
            }
            sortNo
            versionNo
          }
          title
          versionNo
          workingMembers {
            lastWorkingStartDateTime
            lastWorkingStopDateTime
            member {
              adminRole
              clientManagementRole
              department
              employeeNumber
              id
              laborCostManagementRole
              mailAddress
              memberManagementRole
              memberRegistrationDateTime
              memberStatus
              name
              organizationMemberViewRole
              organizationReportRole
              profileImageUrl
              projectCreatePermissionFlg
              projectCustomAttributePermissionFlg
              projectDeletePermissionFlg
              projectUpdatePermissionFlg
              proxyEditWorkingDataRole
              salesManagementRole
              statusMessage
              taskCreatePermissionFlg
              taskCustomAttributePermissionFlg
              taskDeletePermissionFlg
              taskUpdatePermissionFlg
              teamMemberViewRole
              teamReportRole
              versionNo
            }
          }
          workingTimeSec
        }
        versionNo
        workingMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        workingTimeSec
      }
    }
  }
`;
export type CreateCalendarEventWithWorkingHistoryMutationFn = Apollo.MutationFunction<
  CreateCalendarEventWithWorkingHistoryMutation,
  CreateCalendarEventWithWorkingHistoryMutationVariables
>;

/**
 * __useCreateCalendarEventWithWorkingHistoryMutation__
 *
 * To run a mutation, you first call `useCreateCalendarEventWithWorkingHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCalendarEventWithWorkingHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCalendarEventWithWorkingHistoryMutation, { data, loading, error }] = useCreateCalendarEventWithWorkingHistoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *      projectId: // value for 'projectId'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useCreateCalendarEventWithWorkingHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCalendarEventWithWorkingHistoryMutation,
    CreateCalendarEventWithWorkingHistoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCalendarEventWithWorkingHistoryMutation,
    CreateCalendarEventWithWorkingHistoryMutationVariables
  >(CreateCalendarEventWithWorkingHistoryDocument, options);
}
export type CreateCalendarEventWithWorkingHistoryMutationHookResult = ReturnType<
  typeof useCreateCalendarEventWithWorkingHistoryMutation
>;
export type CreateCalendarEventWithWorkingHistoryMutationResult =
  Apollo.MutationResult<CreateCalendarEventWithWorkingHistoryMutation>;
export type CreateCalendarEventWithWorkingHistoryMutationOptions = Apollo.BaseMutationOptions<
  CreateCalendarEventWithWorkingHistoryMutation,
  CreateCalendarEventWithWorkingHistoryMutationVariables
>;
export const CreateCalendarEventWithWorkingScheduleDocument = gql`
  mutation createCalendarEventWithWorkingSchedule(
    $input: RegisterCalendarEventInputInput!
    $projectId: String!
    $taskId: String
  ) {
    createCalendarEventWithWorkingSchedule(input: $input, projectId: $projectId, taskId: $taskId) {
      calendarEvent {
        calendarType
        clientId
        clientName
        endDateTime
        eventName
        id
        laborCost
        personalTeam
        projectColor
        projectId
        projectName
        startDateTime
        taskId
        taskTitle
        teamId
        teamName
        thirdPartyCalendarEventId
        thirdPartyCalendarInfoList {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          thirdPartyCalendarId
        }
        versionNo
        workingTimeSec
      }
      workingSchedule {
        calendarEvent {
          calendarType
          clientId
          clientName
          endDateTime
          eventName
          id
          laborCost
          personalTeam
          projectColor
          projectId
          projectName
          startDateTime
          taskId
          taskTitle
          teamId
          teamName
          thirdPartyCalendarEventId
          versionNo
          workingTimeSec
        }
        end
        id
        memo
        start
        task {
          assignees {
            member {
              adminRole
              clientManagementRole
              department
              employeeNumber
              id
              laborCostManagementRole
              mailAddress
              memberManagementRole
              memberRegistrationDateTime
              memberStatus
              name
              organizationMemberViewRole
              organizationReportRole
              profileImageUrl
              projectCreatePermissionFlg
              projectCustomAttributePermissionFlg
              projectDeletePermissionFlg
              projectUpdatePermissionFlg
              proxyEditWorkingDataRole
              salesManagementRole
              statusMessage
              taskCreatePermissionFlg
              taskCustomAttributePermissionFlg
              taskDeletePermissionFlg
              taskUpdatePermissionFlg
              teamMemberViewRole
              teamReportRole
              versionNo
            }
            sortNo
          }
          asssignee {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          calendarLinkageCode
          completeDateTime
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          estimateTimeSec
          estimatedRemainingTimeSec
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            member {
              adminRole
              clientManagementRole
              department
              employeeNumber
              id
              laborCostManagementRole
              mailAddress
              memberManagementRole
              memberRegistrationDateTime
              memberStatus
              name
              organizationMemberViewRole
              organizationReportRole
              profileImageUrl
              projectCreatePermissionFlg
              projectCustomAttributePermissionFlg
              projectDeletePermissionFlg
              projectUpdatePermissionFlg
              proxyEditWorkingDataRole
              salesManagementRole
              statusMessage
              taskCreatePermissionFlg
              taskCustomAttributePermissionFlg
              taskDeletePermissionFlg
              taskUpdatePermissionFlg
              teamMemberViewRole
              teamReportRole
              versionNo
            }
            sortNo
          }
          id
          laborCost
          priority
          progressDeviationRate
          progressRate
          project {
            assignees {
              sortNo
            }
            calendarLinkageCode
            client {
              code
              id
              name
              sortNo
              versionNo
            }
            color
            complete
            completeDateTime
            costBudgetWithoutTax
            createdMember {
              adminRole
              clientManagementRole
              department
              employeeNumber
              id
              laborCostManagementRole
              mailAddress
              memberManagementRole
              memberRegistrationDateTime
              memberStatus
              name
              organizationMemberViewRole
              organizationReportRole
              profileImageUrl
              projectCreatePermissionFlg
              projectCustomAttributePermissionFlg
              projectDeletePermissionFlg
              projectUpdatePermissionFlg
              proxyEditWorkingDataRole
              salesManagementRole
              statusMessage
              taskCreatePermissionFlg
              taskCustomAttributePermissionFlg
              taskDeletePermissionFlg
              taskUpdatePermissionFlg
              teamMemberViewRole
              teamReportRole
              versionNo
            }
            description
            estimateTimeSec
            favoriteMembers {
              adminRole
              clientManagementRole
              department
              employeeNumber
              id
              laborCostManagementRole
              mailAddress
              memberManagementRole
              memberRegistrationDateTime
              memberStatus
              name
              organizationMemberViewRole
              organizationReportRole
              profileImageUrl
              projectCreatePermissionFlg
              projectCustomAttributePermissionFlg
              projectDeletePermissionFlg
              projectUpdatePermissionFlg
              proxyEditWorkingDataRole
              salesManagementRole
              statusMessage
              taskCreatePermissionFlg
              taskCustomAttributePermissionFlg
              taskDeletePermissionFlg
              taskUpdatePermissionFlg
              teamMemberViewRole
              teamReportRole
              versionNo
            }
            favoriteMembersInfo {
              sortNo
            }
            id
            key
            laborCost
            laborCostWithoutTax
            laborShare
            name
            priority
            projectStatus {
              endStatus
              id
              name
              sortNo
              teamId
              versionNo
            }
            registerDateTime
            salesAmountWithoutTax
            scheduledEndDateTime
            scheduledStartDateTime
            sortNoInGanttChart
            sortNoInList
            sortNoInProjectStatus
            team {
              archived
              id
              name
              organization {
                allowConnectThirdPartyCalendar
                contactMailAddress
                contractPreriod
                id
                imageUrl
                ipRestrictionFlg
                name
                plan {
                  code
                  contractPreriod
                  id
                }
                requiredTwoFactorAuth
                suspensionFlg
                versionNo
              }
              personalTeam
              status
              versionNo
            }
            totalScheduleTimeSec
            versionNo
            workingTimeSec
          }
          registerDateTime
          remainingTimeSec
          scheduleWorkingTimeSec
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInMyTask
          sortNoInTaskStatus
          taskStatus {
            endStatus
            id
            name
            project {
              calendarLinkageCode
              color
              complete
              completeDateTime
              costBudgetWithoutTax
              description
              estimateTimeSec
              id
              key
              laborCost
              laborCostWithoutTax
              laborShare
              name
              priority
              registerDateTime
              salesAmountWithoutTax
              scheduledEndDateTime
              scheduledStartDateTime
              sortNoInGanttChart
              sortNoInList
              sortNoInProjectStatus
              totalScheduleTimeSec
              versionNo
              workingTimeSec
            }
            sortNo
            versionNo
          }
          title
          versionNo
          workingMembers {
            lastWorkingStartDateTime
            lastWorkingStopDateTime
            member {
              adminRole
              clientManagementRole
              department
              employeeNumber
              id
              laborCostManagementRole
              mailAddress
              memberManagementRole
              memberRegistrationDateTime
              memberStatus
              name
              organizationMemberViewRole
              organizationReportRole
              profileImageUrl
              projectCreatePermissionFlg
              projectCustomAttributePermissionFlg
              projectDeletePermissionFlg
              projectUpdatePermissionFlg
              proxyEditWorkingDataRole
              salesManagementRole
              statusMessage
              taskCreatePermissionFlg
              taskCustomAttributePermissionFlg
              taskDeletePermissionFlg
              taskUpdatePermissionFlg
              teamMemberViewRole
              teamReportRole
              versionNo
            }
          }
          workingTimeSec
        }
        versionNo
        workingMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        workingTimeSec
      }
    }
  }
`;
export type CreateCalendarEventWithWorkingScheduleMutationFn = Apollo.MutationFunction<
  CreateCalendarEventWithWorkingScheduleMutation,
  CreateCalendarEventWithWorkingScheduleMutationVariables
>;

/**
 * __useCreateCalendarEventWithWorkingScheduleMutation__
 *
 * To run a mutation, you first call `useCreateCalendarEventWithWorkingScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCalendarEventWithWorkingScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCalendarEventWithWorkingScheduleMutation, { data, loading, error }] = useCreateCalendarEventWithWorkingScheduleMutation({
 *   variables: {
 *      input: // value for 'input'
 *      projectId: // value for 'projectId'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useCreateCalendarEventWithWorkingScheduleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCalendarEventWithWorkingScheduleMutation,
    CreateCalendarEventWithWorkingScheduleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCalendarEventWithWorkingScheduleMutation,
    CreateCalendarEventWithWorkingScheduleMutationVariables
  >(CreateCalendarEventWithWorkingScheduleDocument, options);
}
export type CreateCalendarEventWithWorkingScheduleMutationHookResult = ReturnType<
  typeof useCreateCalendarEventWithWorkingScheduleMutation
>;
export type CreateCalendarEventWithWorkingScheduleMutationResult =
  Apollo.MutationResult<CreateCalendarEventWithWorkingScheduleMutation>;
export type CreateCalendarEventWithWorkingScheduleMutationOptions = Apollo.BaseMutationOptions<
  CreateCalendarEventWithWorkingScheduleMutation,
  CreateCalendarEventWithWorkingScheduleMutationVariables
>;
export const CreateExportOrganizationClientExcelDownloadUrlDocument = gql`
  mutation createExportOrganizationClientExcelDownloadUrl($id: ID!) {
    createExportOrganizationClientExcelDownloadUrl(id: $id) {
      downloadUrl
    }
  }
`;
export type CreateExportOrganizationClientExcelDownloadUrlMutationFn = Apollo.MutationFunction<
  CreateExportOrganizationClientExcelDownloadUrlMutation,
  CreateExportOrganizationClientExcelDownloadUrlMutationVariables
>;

/**
 * __useCreateExportOrganizationClientExcelDownloadUrlMutation__
 *
 * To run a mutation, you first call `useCreateExportOrganizationClientExcelDownloadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExportOrganizationClientExcelDownloadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExportOrganizationClientExcelDownloadUrlMutation, { data, loading, error }] = useCreateExportOrganizationClientExcelDownloadUrlMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCreateExportOrganizationClientExcelDownloadUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateExportOrganizationClientExcelDownloadUrlMutation,
    CreateExportOrganizationClientExcelDownloadUrlMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateExportOrganizationClientExcelDownloadUrlMutation,
    CreateExportOrganizationClientExcelDownloadUrlMutationVariables
  >(CreateExportOrganizationClientExcelDownloadUrlDocument, options);
}
export type CreateExportOrganizationClientExcelDownloadUrlMutationHookResult = ReturnType<
  typeof useCreateExportOrganizationClientExcelDownloadUrlMutation
>;
export type CreateExportOrganizationClientExcelDownloadUrlMutationResult =
  Apollo.MutationResult<CreateExportOrganizationClientExcelDownloadUrlMutation>;
export type CreateExportOrganizationClientExcelDownloadUrlMutationOptions =
  Apollo.BaseMutationOptions<
    CreateExportOrganizationClientExcelDownloadUrlMutation,
    CreateExportOrganizationClientExcelDownloadUrlMutationVariables
  >;
export const CreateExportOrganizationMemberExcelDownloadUrlDocument = gql`
  mutation createExportOrganizationMemberExcelDownloadUrl($id: ID!) {
    createExportOrganizationMemberExcelDownloadUrl(id: $id) {
      downloadUrl
    }
  }
`;
export type CreateExportOrganizationMemberExcelDownloadUrlMutationFn = Apollo.MutationFunction<
  CreateExportOrganizationMemberExcelDownloadUrlMutation,
  CreateExportOrganizationMemberExcelDownloadUrlMutationVariables
>;

/**
 * __useCreateExportOrganizationMemberExcelDownloadUrlMutation__
 *
 * To run a mutation, you first call `useCreateExportOrganizationMemberExcelDownloadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExportOrganizationMemberExcelDownloadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExportOrganizationMemberExcelDownloadUrlMutation, { data, loading, error }] = useCreateExportOrganizationMemberExcelDownloadUrlMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCreateExportOrganizationMemberExcelDownloadUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateExportOrganizationMemberExcelDownloadUrlMutation,
    CreateExportOrganizationMemberExcelDownloadUrlMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateExportOrganizationMemberExcelDownloadUrlMutation,
    CreateExportOrganizationMemberExcelDownloadUrlMutationVariables
  >(CreateExportOrganizationMemberExcelDownloadUrlDocument, options);
}
export type CreateExportOrganizationMemberExcelDownloadUrlMutationHookResult = ReturnType<
  typeof useCreateExportOrganizationMemberExcelDownloadUrlMutation
>;
export type CreateExportOrganizationMemberExcelDownloadUrlMutationResult =
  Apollo.MutationResult<CreateExportOrganizationMemberExcelDownloadUrlMutation>;
export type CreateExportOrganizationMemberExcelDownloadUrlMutationOptions =
  Apollo.BaseMutationOptions<
    CreateExportOrganizationMemberExcelDownloadUrlMutation,
    CreateExportOrganizationMemberExcelDownloadUrlMutationVariables
  >;
export const CreateExportProjectExcelDownloadUrlDocument = gql`
  mutation createExportProjectExcelDownloadUrl($id: ID!) {
    createExportProjectExcelDownloadUrl(id: $id) {
      downloadUrl
    }
  }
`;
export type CreateExportProjectExcelDownloadUrlMutationFn = Apollo.MutationFunction<
  CreateExportProjectExcelDownloadUrlMutation,
  CreateExportProjectExcelDownloadUrlMutationVariables
>;

/**
 * __useCreateExportProjectExcelDownloadUrlMutation__
 *
 * To run a mutation, you first call `useCreateExportProjectExcelDownloadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExportProjectExcelDownloadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExportProjectExcelDownloadUrlMutation, { data, loading, error }] = useCreateExportProjectExcelDownloadUrlMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCreateExportProjectExcelDownloadUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateExportProjectExcelDownloadUrlMutation,
    CreateExportProjectExcelDownloadUrlMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateExportProjectExcelDownloadUrlMutation,
    CreateExportProjectExcelDownloadUrlMutationVariables
  >(CreateExportProjectExcelDownloadUrlDocument, options);
}
export type CreateExportProjectExcelDownloadUrlMutationHookResult = ReturnType<
  typeof useCreateExportProjectExcelDownloadUrlMutation
>;
export type CreateExportProjectExcelDownloadUrlMutationResult =
  Apollo.MutationResult<CreateExportProjectExcelDownloadUrlMutation>;
export type CreateExportProjectExcelDownloadUrlMutationOptions = Apollo.BaseMutationOptions<
  CreateExportProjectExcelDownloadUrlMutation,
  CreateExportProjectExcelDownloadUrlMutationVariables
>;
export const CreateExportTaskExcelDownloadUrlDocument = gql`
  mutation createExportTaskExcelDownloadUrl($id: ID!) {
    createExportTaskExcelDownloadUrl(id: $id) {
      downloadUrl
    }
  }
`;
export type CreateExportTaskExcelDownloadUrlMutationFn = Apollo.MutationFunction<
  CreateExportTaskExcelDownloadUrlMutation,
  CreateExportTaskExcelDownloadUrlMutationVariables
>;

/**
 * __useCreateExportTaskExcelDownloadUrlMutation__
 *
 * To run a mutation, you first call `useCreateExportTaskExcelDownloadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExportTaskExcelDownloadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExportTaskExcelDownloadUrlMutation, { data, loading, error }] = useCreateExportTaskExcelDownloadUrlMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCreateExportTaskExcelDownloadUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateExportTaskExcelDownloadUrlMutation,
    CreateExportTaskExcelDownloadUrlMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateExportTaskExcelDownloadUrlMutation,
    CreateExportTaskExcelDownloadUrlMutationVariables
  >(CreateExportTaskExcelDownloadUrlDocument, options);
}
export type CreateExportTaskExcelDownloadUrlMutationHookResult = ReturnType<
  typeof useCreateExportTaskExcelDownloadUrlMutation
>;
export type CreateExportTaskExcelDownloadUrlMutationResult =
  Apollo.MutationResult<CreateExportTaskExcelDownloadUrlMutation>;
export type CreateExportTaskExcelDownloadUrlMutationOptions = Apollo.BaseMutationOptions<
  CreateExportTaskExcelDownloadUrlMutation,
  CreateExportTaskExcelDownloadUrlMutationVariables
>;
export const CreateICalFileDownloadUrlDocument = gql`
  mutation createICalFileDownloadUrl($input: CreateICalFileDownloadUrlInputInput!) {
    createICalFileDownloadUrl(input: $input) {
      downloadUrl
    }
  }
`;
export type CreateICalFileDownloadUrlMutationFn = Apollo.MutationFunction<
  CreateICalFileDownloadUrlMutation,
  CreateICalFileDownloadUrlMutationVariables
>;

/**
 * __useCreateICalFileDownloadUrlMutation__
 *
 * To run a mutation, you first call `useCreateICalFileDownloadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateICalFileDownloadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createICalFileDownloadUrlMutation, { data, loading, error }] = useCreateICalFileDownloadUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateICalFileDownloadUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateICalFileDownloadUrlMutation,
    CreateICalFileDownloadUrlMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateICalFileDownloadUrlMutation,
    CreateICalFileDownloadUrlMutationVariables
  >(CreateICalFileDownloadUrlDocument, options);
}
export type CreateICalFileDownloadUrlMutationHookResult = ReturnType<
  typeof useCreateICalFileDownloadUrlMutation
>;
export type CreateICalFileDownloadUrlMutationResult =
  Apollo.MutationResult<CreateICalFileDownloadUrlMutation>;
export type CreateICalFileDownloadUrlMutationOptions = Apollo.BaseMutationOptions<
  CreateICalFileDownloadUrlMutation,
  CreateICalFileDownloadUrlMutationVariables
>;
export const CreateInvitationLinkDocument = gql`
  mutation createInvitationLink($input: CreateInvitationLinkInputInput!) {
    createInvitationLink(input: $input) {
      allowThirdPartyCalendarConnect
      clientManagementRole
      createDateTime
      createMemberId
      id
      invitationKey
      invitationUrl
      memo
      organizationId
      organizationMemberViewRole
      organizationName
      organizationReportRole
      projectCreatePermissionFlg
      projectCustomAttributePermissionFlg
      projectDeletePermissionFlg
      projectUpdatePermissionFlg
      proxyEditWorkingDataRole
      taskCreatePermissionFlg
      taskCustomAttributePermissionFlg
      taskDeletePermissionFlg
      taskUpdatePermissionFlg
      teamMemberViewRole
      teamReportRole
      versionNo
    }
  }
`;
export type CreateInvitationLinkMutationFn = Apollo.MutationFunction<
  CreateInvitationLinkMutation,
  CreateInvitationLinkMutationVariables
>;

/**
 * __useCreateInvitationLinkMutation__
 *
 * To run a mutation, you first call `useCreateInvitationLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvitationLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvitationLinkMutation, { data, loading, error }] = useCreateInvitationLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInvitationLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInvitationLinkMutation,
    CreateInvitationLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateInvitationLinkMutation, CreateInvitationLinkMutationVariables>(
    CreateInvitationLinkDocument,
    options
  );
}
export type CreateInvitationLinkMutationHookResult = ReturnType<
  typeof useCreateInvitationLinkMutation
>;
export type CreateInvitationLinkMutationResult =
  Apollo.MutationResult<CreateInvitationLinkMutation>;
export type CreateInvitationLinkMutationOptions = Apollo.BaseMutationOptions<
  CreateInvitationLinkMutation,
  CreateInvitationLinkMutationVariables
>;
export const CreateLaborCostDocument = gql`
  mutation createLaborCost($input: RegisterLaborCostInputInput!) {
    createLaborCost(input: $input) {
      hourlyWage
      id
      startDateTime
      versionNo
    }
  }
`;
export type CreateLaborCostMutationFn = Apollo.MutationFunction<
  CreateLaborCostMutation,
  CreateLaborCostMutationVariables
>;

/**
 * __useCreateLaborCostMutation__
 *
 * To run a mutation, you first call `useCreateLaborCostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLaborCostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLaborCostMutation, { data, loading, error }] = useCreateLaborCostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLaborCostMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateLaborCostMutation,
    CreateLaborCostMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateLaborCostMutation, CreateLaborCostMutationVariables>(
    CreateLaborCostDocument,
    options
  );
}
export type CreateLaborCostMutationHookResult = ReturnType<typeof useCreateLaborCostMutation>;
export type CreateLaborCostMutationResult = Apollo.MutationResult<CreateLaborCostMutation>;
export type CreateLaborCostMutationOptions = Apollo.BaseMutationOptions<
  CreateLaborCostMutation,
  CreateLaborCostMutationVariables
>;
export const CreateMeteredRateContractDocument = gql`
  mutation createMeteredRateContract($input: RegisterMeterdRateContractInputInput!) {
    createMeteredRateContract(input: $input) {
      hourUnitPrice
      id
      member {
        laborCostWithoutTax
        memberId
        name
        profileImageUrl
        roundedTotalWorkingTimeSec
        salesAmountWithoutTax
        totalWorkingTimeSec
      }
      versionNo
    }
  }
`;
export type CreateMeteredRateContractMutationFn = Apollo.MutationFunction<
  CreateMeteredRateContractMutation,
  CreateMeteredRateContractMutationVariables
>;

/**
 * __useCreateMeteredRateContractMutation__
 *
 * To run a mutation, you first call `useCreateMeteredRateContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMeteredRateContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMeteredRateContractMutation, { data, loading, error }] = useCreateMeteredRateContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMeteredRateContractMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMeteredRateContractMutation,
    CreateMeteredRateContractMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateMeteredRateContractMutation,
    CreateMeteredRateContractMutationVariables
  >(CreateMeteredRateContractDocument, options);
}
export type CreateMeteredRateContractMutationHookResult = ReturnType<
  typeof useCreateMeteredRateContractMutation
>;
export type CreateMeteredRateContractMutationResult =
  Apollo.MutationResult<CreateMeteredRateContractMutation>;
export type CreateMeteredRateContractMutationOptions = Apollo.BaseMutationOptions<
  CreateMeteredRateContractMutation,
  CreateMeteredRateContractMutationVariables
>;
export const CreateMyCsvReportDownloadUrlDocument = gql`
  mutation createMyCsvReportDownloadUrl($id: ID!, $isUtf8: Boolean!) {
    createMyCsvReportDownloadUrl(id: $id, isUtf8: $isUtf8) {
      downloadUrl
    }
  }
`;
export type CreateMyCsvReportDownloadUrlMutationFn = Apollo.MutationFunction<
  CreateMyCsvReportDownloadUrlMutation,
  CreateMyCsvReportDownloadUrlMutationVariables
>;

/**
 * __useCreateMyCsvReportDownloadUrlMutation__
 *
 * To run a mutation, you first call `useCreateMyCsvReportDownloadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMyCsvReportDownloadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMyCsvReportDownloadUrlMutation, { data, loading, error }] = useCreateMyCsvReportDownloadUrlMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isUtf8: // value for 'isUtf8'
 *   },
 * });
 */
export function useCreateMyCsvReportDownloadUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMyCsvReportDownloadUrlMutation,
    CreateMyCsvReportDownloadUrlMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateMyCsvReportDownloadUrlMutation,
    CreateMyCsvReportDownloadUrlMutationVariables
  >(CreateMyCsvReportDownloadUrlDocument, options);
}
export type CreateMyCsvReportDownloadUrlMutationHookResult = ReturnType<
  typeof useCreateMyCsvReportDownloadUrlMutation
>;
export type CreateMyCsvReportDownloadUrlMutationResult =
  Apollo.MutationResult<CreateMyCsvReportDownloadUrlMutation>;
export type CreateMyCsvReportDownloadUrlMutationOptions = Apollo.BaseMutationOptions<
  CreateMyCsvReportDownloadUrlMutation,
  CreateMyCsvReportDownloadUrlMutationVariables
>;
export const CreateMyReportDownloadUrlDocument = gql`
  mutation createMyReportDownloadUrl($id: ID!) {
    createMyReportDownloadUrl(id: $id) {
      downloadUrl
    }
  }
`;
export type CreateMyReportDownloadUrlMutationFn = Apollo.MutationFunction<
  CreateMyReportDownloadUrlMutation,
  CreateMyReportDownloadUrlMutationVariables
>;

/**
 * __useCreateMyReportDownloadUrlMutation__
 *
 * To run a mutation, you first call `useCreateMyReportDownloadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMyReportDownloadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMyReportDownloadUrlMutation, { data, loading, error }] = useCreateMyReportDownloadUrlMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCreateMyReportDownloadUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMyReportDownloadUrlMutation,
    CreateMyReportDownloadUrlMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateMyReportDownloadUrlMutation,
    CreateMyReportDownloadUrlMutationVariables
  >(CreateMyReportDownloadUrlDocument, options);
}
export type CreateMyReportDownloadUrlMutationHookResult = ReturnType<
  typeof useCreateMyReportDownloadUrlMutation
>;
export type CreateMyReportDownloadUrlMutationResult =
  Apollo.MutationResult<CreateMyReportDownloadUrlMutation>;
export type CreateMyReportDownloadUrlMutationOptions = Apollo.BaseMutationOptions<
  CreateMyReportDownloadUrlMutation,
  CreateMyReportDownloadUrlMutationVariables
>;
export const CreateOrganizationCsvReportDownloadUrlDocument = gql`
  mutation createOrganizationCsvReportDownloadUrl($id: ID!, $isUtf8: Boolean!) {
    createOrganizationCsvReportDownloadUrl(id: $id, isUtf8: $isUtf8) {
      downloadUrl
    }
  }
`;
export type CreateOrganizationCsvReportDownloadUrlMutationFn = Apollo.MutationFunction<
  CreateOrganizationCsvReportDownloadUrlMutation,
  CreateOrganizationCsvReportDownloadUrlMutationVariables
>;

/**
 * __useCreateOrganizationCsvReportDownloadUrlMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationCsvReportDownloadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationCsvReportDownloadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationCsvReportDownloadUrlMutation, { data, loading, error }] = useCreateOrganizationCsvReportDownloadUrlMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isUtf8: // value for 'isUtf8'
 *   },
 * });
 */
export function useCreateOrganizationCsvReportDownloadUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrganizationCsvReportDownloadUrlMutation,
    CreateOrganizationCsvReportDownloadUrlMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOrganizationCsvReportDownloadUrlMutation,
    CreateOrganizationCsvReportDownloadUrlMutationVariables
  >(CreateOrganizationCsvReportDownloadUrlDocument, options);
}
export type CreateOrganizationCsvReportDownloadUrlMutationHookResult = ReturnType<
  typeof useCreateOrganizationCsvReportDownloadUrlMutation
>;
export type CreateOrganizationCsvReportDownloadUrlMutationResult =
  Apollo.MutationResult<CreateOrganizationCsvReportDownloadUrlMutation>;
export type CreateOrganizationCsvReportDownloadUrlMutationOptions = Apollo.BaseMutationOptions<
  CreateOrganizationCsvReportDownloadUrlMutation,
  CreateOrganizationCsvReportDownloadUrlMutationVariables
>;
export const CreateOrganizationReportDownloadUrlDocument = gql`
  mutation createOrganizationReportDownloadUrl($id: ID!) {
    createOrganizationReportDownloadUrl(id: $id) {
      downloadUrl
    }
  }
`;
export type CreateOrganizationReportDownloadUrlMutationFn = Apollo.MutationFunction<
  CreateOrganizationReportDownloadUrlMutation,
  CreateOrganizationReportDownloadUrlMutationVariables
>;

/**
 * __useCreateOrganizationReportDownloadUrlMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationReportDownloadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationReportDownloadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationReportDownloadUrlMutation, { data, loading, error }] = useCreateOrganizationReportDownloadUrlMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCreateOrganizationReportDownloadUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrganizationReportDownloadUrlMutation,
    CreateOrganizationReportDownloadUrlMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOrganizationReportDownloadUrlMutation,
    CreateOrganizationReportDownloadUrlMutationVariables
  >(CreateOrganizationReportDownloadUrlDocument, options);
}
export type CreateOrganizationReportDownloadUrlMutationHookResult = ReturnType<
  typeof useCreateOrganizationReportDownloadUrlMutation
>;
export type CreateOrganizationReportDownloadUrlMutationResult =
  Apollo.MutationResult<CreateOrganizationReportDownloadUrlMutation>;
export type CreateOrganizationReportDownloadUrlMutationOptions = Apollo.BaseMutationOptions<
  CreateOrganizationReportDownloadUrlMutation,
  CreateOrganizationReportDownloadUrlMutationVariables
>;
export const CreateProjectDocument = gql`
  mutation createProject($input: RegisterProjectInputInput, $teamId: String!) {
    createProject(input: $input, teamId: $teamId) {
      assignees {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      calendarLinkageCode
      client {
        code
        id
        name
        sortNo
        versionNo
      }
      color
      complete
      completeDateTime
      costBudgetWithoutTax
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      favoriteMembers {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      favoriteMembersInfo {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      id
      key
      laborCost
      laborCostWithoutTax
      laborShare
      name
      priority
      projectStatus {
        endStatus
        id
        name
        sortNo
        teamId
        versionNo
      }
      registerDateTime
      salesAmountWithoutTax
      scheduledEndDateTime
      scheduledStartDateTime
      sortNoInGanttChart
      sortNoInList
      sortNoInProjectStatus
      team {
        archived
        id
        name
        organization {
          allowConnectThirdPartyCalendar
          contactMailAddress
          contractPreriod
          id
          imageUrl
          ipRestrictionFlg
          name
          plan {
            code
            contractPreriod
            id
          }
          requiredTwoFactorAuth
          suspensionFlg
          versionNo
        }
        personalTeam
        status
        versionNo
      }
      totalScheduleTimeSec
      versionNo
      workingTimeSec
    }
  }
`;
export type CreateProjectMutationFn = Apollo.MutationFunction<
  CreateProjectMutation,
  CreateProjectMutationVariables
>;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useCreateProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateProjectMutation, CreateProjectMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateProjectMutation, CreateProjectMutationVariables>(
    CreateProjectDocument,
    options
  );
}
export type CreateProjectMutationHookResult = ReturnType<typeof useCreateProjectMutation>;
export type CreateProjectMutationResult = Apollo.MutationResult<CreateProjectMutation>;
export type CreateProjectMutationOptions = Apollo.BaseMutationOptions<
  CreateProjectMutation,
  CreateProjectMutationVariables
>;
export const CreateProjectContractDocument = gql`
  mutation createProjectContract($input: RegisterProjectContractInputInput!) {
    createProjectContract(input: $input) {
      allMemberMeteredRateContract {
        hourUnitPrice
        id
        member {
          laborCostWithoutTax
          memberId
          name
          profileImageUrl
          roundedTotalWorkingTimeSec
          salesAmountWithoutTax
          totalWorkingTimeSec
        }
        versionNo
      }
      allMemberSettlementRangeContract {
        id
        lowerHourPart {
          deducationHourlyUnitPrice
          lowerHour
        }
        member {
          laborCostWithoutTax
          memberId
          name
          profileImageUrl
          roundedTotalWorkingTimeSec
          salesAmountWithoutTax
          totalWorkingTimeSec
        }
        monthlyUnitPrice
        upperHourPart {
          excessHourlyUnitPrice
          upperHour
        }
        versionNo
      }
      contractType
      costBudgetWithoutTax
      endDateTime
      fixSalesAmountWithoutTax
      grossProfit
      grossProfitRatePercent
      id
      laborCostWithoutTax
      laborShare
      membersMeteredRateContract {
        hourUnitPrice
        id
        member {
          laborCostWithoutTax
          memberId
          name
          profileImageUrl
          roundedTotalWorkingTimeSec
          salesAmountWithoutTax
          totalWorkingTimeSec
        }
        versionNo
      }
      membersSettlementRangeContract {
        id
        lowerHourPart {
          deducationHourlyUnitPrice
          lowerHour
        }
        member {
          laborCostWithoutTax
          memberId
          name
          profileImageUrl
          roundedTotalWorkingTimeSec
          salesAmountWithoutTax
          totalWorkingTimeSec
        }
        monthlyUnitPrice
        upperHourPart {
          excessHourlyUnitPrice
          upperHour
        }
        versionNo
      }
      month
      project {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        calendarLinkageCode
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        color
        complete
        completeDateTime
        costBudgetWithoutTax
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        key
        laborCost
        laborCostWithoutTax
        laborShare
        name
        priority
        projectStatus {
          endStatus
          id
          name
          sortNo
          teamId
          versionNo
        }
        registerDateTime
        salesAmountWithoutTax
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInProjectStatus
        team {
          archived
          id
          name
          organization {
            allowConnectThirdPartyCalendar
            contactMailAddress
            contractPreriod
            id
            imageUrl
            ipRestrictionFlg
            name
            plan {
              code
              contractPreriod
              id
            }
            requiredTwoFactorAuth
            suspensionFlg
            versionNo
          }
          personalTeam
          status
          versionNo
        }
        totalScheduleTimeSec
        versionNo
        workingTimeSec
      }
      roundTimeType
      roundedTotalWorkingTimeSec
      salesAmountWithoutTax
      salesRoundMinutes
      startDateTime
      taxRate
      termType
      timeZone {
        code
        id
        name
        offsetTime
        sortNo
      }
      totalWorkingTimeSec
      unitPriceType
      versionNo
      year
    }
  }
`;
export type CreateProjectContractMutationFn = Apollo.MutationFunction<
  CreateProjectContractMutation,
  CreateProjectContractMutationVariables
>;

/**
 * __useCreateProjectContractMutation__
 *
 * To run a mutation, you first call `useCreateProjectContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectContractMutation, { data, loading, error }] = useCreateProjectContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProjectContractMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProjectContractMutation,
    CreateProjectContractMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateProjectContractMutation, CreateProjectContractMutationVariables>(
    CreateProjectContractDocument,
    options
  );
}
export type CreateProjectContractMutationHookResult = ReturnType<
  typeof useCreateProjectContractMutation
>;
export type CreateProjectContractMutationResult =
  Apollo.MutationResult<CreateProjectContractMutation>;
export type CreateProjectContractMutationOptions = Apollo.BaseMutationOptions<
  CreateProjectContractMutation,
  CreateProjectContractMutationVariables
>;
export const CreateProjectCustomAttributeMasterDocument = gql`
  mutation createProjectCustomAttributeMaster(
    $input: RegisterProjectCustomAttributeMasterInputInput!
  ) {
    createProjectCustomAttributeMaster(input: $input) {
      id
      listItem {
        id
        sortNo
        value
        versionNo
      }
      name
      required
      sortNo
      type
      versionNo
    }
  }
`;
export type CreateProjectCustomAttributeMasterMutationFn = Apollo.MutationFunction<
  CreateProjectCustomAttributeMasterMutation,
  CreateProjectCustomAttributeMasterMutationVariables
>;

/**
 * __useCreateProjectCustomAttributeMasterMutation__
 *
 * To run a mutation, you first call `useCreateProjectCustomAttributeMasterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectCustomAttributeMasterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectCustomAttributeMasterMutation, { data, loading, error }] = useCreateProjectCustomAttributeMasterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProjectCustomAttributeMasterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProjectCustomAttributeMasterMutation,
    CreateProjectCustomAttributeMasterMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateProjectCustomAttributeMasterMutation,
    CreateProjectCustomAttributeMasterMutationVariables
  >(CreateProjectCustomAttributeMasterDocument, options);
}
export type CreateProjectCustomAttributeMasterMutationHookResult = ReturnType<
  typeof useCreateProjectCustomAttributeMasterMutation
>;
export type CreateProjectCustomAttributeMasterMutationResult =
  Apollo.MutationResult<CreateProjectCustomAttributeMasterMutation>;
export type CreateProjectCustomAttributeMasterMutationOptions = Apollo.BaseMutationOptions<
  CreateProjectCustomAttributeMasterMutation,
  CreateProjectCustomAttributeMasterMutationVariables
>;
export const CreateProjectStatusDocument = gql`
  mutation createProjectStatus($input: RegisterProjectStatusInputInput!, $teamId: String!) {
    createProjectStatus(input: $input, teamId: $teamId) {
      endStatus
      id
      name
      sortNo
      teamId
      versionNo
    }
  }
`;
export type CreateProjectStatusMutationFn = Apollo.MutationFunction<
  CreateProjectStatusMutation,
  CreateProjectStatusMutationVariables
>;

/**
 * __useCreateProjectStatusMutation__
 *
 * To run a mutation, you first call `useCreateProjectStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectStatusMutation, { data, loading, error }] = useCreateProjectStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useCreateProjectStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProjectStatusMutation,
    CreateProjectStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateProjectStatusMutation, CreateProjectStatusMutationVariables>(
    CreateProjectStatusDocument,
    options
  );
}
export type CreateProjectStatusMutationHookResult = ReturnType<
  typeof useCreateProjectStatusMutation
>;
export type CreateProjectStatusMutationResult = Apollo.MutationResult<CreateProjectStatusMutation>;
export type CreateProjectStatusMutationOptions = Apollo.BaseMutationOptions<
  CreateProjectStatusMutation,
  CreateProjectStatusMutationVariables
>;
export const CreateProjectTemplateDocument = gql`
  mutation createProjectTemplate($input: RegisterProjectTemplateInputInput) {
    createProjectTemplate(input: $input) {
      client {
        code
        id
        name
        sortNo
        versionNo
      }
      description
      estimateTimeSec
      id
      name
      priority
      sortNoInList
      versionNo
    }
  }
`;
export type CreateProjectTemplateMutationFn = Apollo.MutationFunction<
  CreateProjectTemplateMutation,
  CreateProjectTemplateMutationVariables
>;

/**
 * __useCreateProjectTemplateMutation__
 *
 * To run a mutation, you first call `useCreateProjectTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectTemplateMutation, { data, loading, error }] = useCreateProjectTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProjectTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProjectTemplateMutation,
    CreateProjectTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateProjectTemplateMutation, CreateProjectTemplateMutationVariables>(
    CreateProjectTemplateDocument,
    options
  );
}
export type CreateProjectTemplateMutationHookResult = ReturnType<
  typeof useCreateProjectTemplateMutation
>;
export type CreateProjectTemplateMutationResult =
  Apollo.MutationResult<CreateProjectTemplateMutation>;
export type CreateProjectTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateProjectTemplateMutation,
  CreateProjectTemplateMutationVariables
>;
export const CreateProjectTemplateSubTaskDocument = gql`
  mutation createProjectTemplateSubTask(
    $input: RegisterProjectTemplateSubTaskInputInput!
    $projectTemplateTaskId: String!
  ) {
    createProjectTemplateSubTask(input: $input, projectTemplateTaskId: $projectTemplateTaskId) {
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      id
      projectTemplateTask {
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        id
        priority
        projectTemplate {
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          description
          estimateTimeSec
          id
          name
          priority
          sortNoInList
          versionNo
        }
        projectTemplateTaskStatus {
          endStatus
          id
          name
          projectTemplate {
            description
            estimateTimeSec
            id
            name
            priority
            sortNoInList
            versionNo
          }
          sortNo
          versionNo
        }
        sortNoInList
        sortNoInTaskStatus
        title
        versionNo
      }
      sortNo
      title
      versionNo
    }
  }
`;
export type CreateProjectTemplateSubTaskMutationFn = Apollo.MutationFunction<
  CreateProjectTemplateSubTaskMutation,
  CreateProjectTemplateSubTaskMutationVariables
>;

/**
 * __useCreateProjectTemplateSubTaskMutation__
 *
 * To run a mutation, you first call `useCreateProjectTemplateSubTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectTemplateSubTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectTemplateSubTaskMutation, { data, loading, error }] = useCreateProjectTemplateSubTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *      projectTemplateTaskId: // value for 'projectTemplateTaskId'
 *   },
 * });
 */
export function useCreateProjectTemplateSubTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProjectTemplateSubTaskMutation,
    CreateProjectTemplateSubTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateProjectTemplateSubTaskMutation,
    CreateProjectTemplateSubTaskMutationVariables
  >(CreateProjectTemplateSubTaskDocument, options);
}
export type CreateProjectTemplateSubTaskMutationHookResult = ReturnType<
  typeof useCreateProjectTemplateSubTaskMutation
>;
export type CreateProjectTemplateSubTaskMutationResult =
  Apollo.MutationResult<CreateProjectTemplateSubTaskMutation>;
export type CreateProjectTemplateSubTaskMutationOptions = Apollo.BaseMutationOptions<
  CreateProjectTemplateSubTaskMutation,
  CreateProjectTemplateSubTaskMutationVariables
>;
export const CreateProjectTemplateTaskDocument = gql`
  mutation createProjectTemplateTask(
    $input: RegisterProjectTemplateTaskInputInput!
    $projectTemplateId: String!
  ) {
    createProjectTemplateTask(input: $input, projectTemplateId: $projectTemplateId) {
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      id
      priority
      projectTemplate {
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        description
        estimateTimeSec
        id
        name
        priority
        sortNoInList
        versionNo
      }
      projectTemplateTaskStatus {
        endStatus
        id
        name
        projectTemplate {
          description
          estimateTimeSec
          id
          name
          priority
          sortNoInList
          versionNo
        }
        sortNo
        versionNo
      }
      sortNoInList
      sortNoInTaskStatus
      title
      versionNo
    }
  }
`;
export type CreateProjectTemplateTaskMutationFn = Apollo.MutationFunction<
  CreateProjectTemplateTaskMutation,
  CreateProjectTemplateTaskMutationVariables
>;

/**
 * __useCreateProjectTemplateTaskMutation__
 *
 * To run a mutation, you first call `useCreateProjectTemplateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectTemplateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectTemplateTaskMutation, { data, loading, error }] = useCreateProjectTemplateTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *      projectTemplateId: // value for 'projectTemplateId'
 *   },
 * });
 */
export function useCreateProjectTemplateTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProjectTemplateTaskMutation,
    CreateProjectTemplateTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateProjectTemplateTaskMutation,
    CreateProjectTemplateTaskMutationVariables
  >(CreateProjectTemplateTaskDocument, options);
}
export type CreateProjectTemplateTaskMutationHookResult = ReturnType<
  typeof useCreateProjectTemplateTaskMutation
>;
export type CreateProjectTemplateTaskMutationResult =
  Apollo.MutationResult<CreateProjectTemplateTaskMutation>;
export type CreateProjectTemplateTaskMutationOptions = Apollo.BaseMutationOptions<
  CreateProjectTemplateTaskMutation,
  CreateProjectTemplateTaskMutationVariables
>;
export const CreateProjectTemplateTaskRemainderDocument = gql`
  mutation createProjectTemplateTaskRemainder(
    $input: RegisterProjectTemplateTaskRemainderInputInput!
    $projectTemplateTaskId: String!
  ) {
    createProjectTemplateTaskRemainder(
      input: $input
      projectTemplateTaskId: $projectTemplateTaskId
    ) {
      beforeDeadlineMinutes
      id
      projectTemplateTask {
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        id
        priority
        projectTemplate {
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          description
          estimateTimeSec
          id
          name
          priority
          sortNoInList
          versionNo
        }
        projectTemplateTaskStatus {
          endStatus
          id
          name
          projectTemplate {
            description
            estimateTimeSec
            id
            name
            priority
            sortNoInList
            versionNo
          }
          sortNo
          versionNo
        }
        sortNoInList
        sortNoInTaskStatus
        title
        versionNo
      }
      targetMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      taskRemainderDestType
      taskRemainderType
    }
  }
`;
export type CreateProjectTemplateTaskRemainderMutationFn = Apollo.MutationFunction<
  CreateProjectTemplateTaskRemainderMutation,
  CreateProjectTemplateTaskRemainderMutationVariables
>;

/**
 * __useCreateProjectTemplateTaskRemainderMutation__
 *
 * To run a mutation, you first call `useCreateProjectTemplateTaskRemainderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectTemplateTaskRemainderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectTemplateTaskRemainderMutation, { data, loading, error }] = useCreateProjectTemplateTaskRemainderMutation({
 *   variables: {
 *      input: // value for 'input'
 *      projectTemplateTaskId: // value for 'projectTemplateTaskId'
 *   },
 * });
 */
export function useCreateProjectTemplateTaskRemainderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProjectTemplateTaskRemainderMutation,
    CreateProjectTemplateTaskRemainderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateProjectTemplateTaskRemainderMutation,
    CreateProjectTemplateTaskRemainderMutationVariables
  >(CreateProjectTemplateTaskRemainderDocument, options);
}
export type CreateProjectTemplateTaskRemainderMutationHookResult = ReturnType<
  typeof useCreateProjectTemplateTaskRemainderMutation
>;
export type CreateProjectTemplateTaskRemainderMutationResult =
  Apollo.MutationResult<CreateProjectTemplateTaskRemainderMutation>;
export type CreateProjectTemplateTaskRemainderMutationOptions = Apollo.BaseMutationOptions<
  CreateProjectTemplateTaskRemainderMutation,
  CreateProjectTemplateTaskRemainderMutationVariables
>;
export const CreateProjectTemplateTaskStatusDocument = gql`
  mutation createProjectTemplateTaskStatus(
    $input: RegisterProjectTemplateTaskStatusInputInput!
    $projectTemplateId: String!
  ) {
    createProjectTemplateTaskStatus(input: $input, projectTemplateId: $projectTemplateId) {
      endStatus
      id
      name
      projectTemplate {
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        description
        estimateTimeSec
        id
        name
        priority
        sortNoInList
        versionNo
      }
      sortNo
      versionNo
    }
  }
`;
export type CreateProjectTemplateTaskStatusMutationFn = Apollo.MutationFunction<
  CreateProjectTemplateTaskStatusMutation,
  CreateProjectTemplateTaskStatusMutationVariables
>;

/**
 * __useCreateProjectTemplateTaskStatusMutation__
 *
 * To run a mutation, you first call `useCreateProjectTemplateTaskStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectTemplateTaskStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectTemplateTaskStatusMutation, { data, loading, error }] = useCreateProjectTemplateTaskStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *      projectTemplateId: // value for 'projectTemplateId'
 *   },
 * });
 */
export function useCreateProjectTemplateTaskStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProjectTemplateTaskStatusMutation,
    CreateProjectTemplateTaskStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateProjectTemplateTaskStatusMutation,
    CreateProjectTemplateTaskStatusMutationVariables
  >(CreateProjectTemplateTaskStatusDocument, options);
}
export type CreateProjectTemplateTaskStatusMutationHookResult = ReturnType<
  typeof useCreateProjectTemplateTaskStatusMutation
>;
export type CreateProjectTemplateTaskStatusMutationResult =
  Apollo.MutationResult<CreateProjectTemplateTaskStatusMutation>;
export type CreateProjectTemplateTaskStatusMutationOptions = Apollo.BaseMutationOptions<
  CreateProjectTemplateTaskStatusMutation,
  CreateProjectTemplateTaskStatusMutationVariables
>;
export const CreateProjectTemplateTasksDocument = gql`
  mutation createProjectTemplateTasks(
    $inputs: RegisterProjectTemplateTasksInputInput!
    $projectTemplateId: String!
  ) {
    createProjectTemplateTasks(inputs: $inputs, projectTemplateId: $projectTemplateId) {
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      id
      priority
      projectTemplate {
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        description
        estimateTimeSec
        id
        name
        priority
        sortNoInList
        versionNo
      }
      projectTemplateTaskStatus {
        endStatus
        id
        name
        projectTemplate {
          description
          estimateTimeSec
          id
          name
          priority
          sortNoInList
          versionNo
        }
        sortNo
        versionNo
      }
      sortNoInList
      sortNoInTaskStatus
      title
      versionNo
    }
  }
`;
export type CreateProjectTemplateTasksMutationFn = Apollo.MutationFunction<
  CreateProjectTemplateTasksMutation,
  CreateProjectTemplateTasksMutationVariables
>;

/**
 * __useCreateProjectTemplateTasksMutation__
 *
 * To run a mutation, you first call `useCreateProjectTemplateTasksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectTemplateTasksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectTemplateTasksMutation, { data, loading, error }] = useCreateProjectTemplateTasksMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *      projectTemplateId: // value for 'projectTemplateId'
 *   },
 * });
 */
export function useCreateProjectTemplateTasksMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProjectTemplateTasksMutation,
    CreateProjectTemplateTasksMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateProjectTemplateTasksMutation,
    CreateProjectTemplateTasksMutationVariables
  >(CreateProjectTemplateTasksDocument, options);
}
export type CreateProjectTemplateTasksMutationHookResult = ReturnType<
  typeof useCreateProjectTemplateTasksMutation
>;
export type CreateProjectTemplateTasksMutationResult =
  Apollo.MutationResult<CreateProjectTemplateTasksMutation>;
export type CreateProjectTemplateTasksMutationOptions = Apollo.BaseMutationOptions<
  CreateProjectTemplateTasksMutation,
  CreateProjectTemplateTasksMutationVariables
>;
export const CreateSettlementRangeContractDocument = gql`
  mutation createSettlementRangeContract($input: RegisterSettlementRangeContractInputInput!) {
    createSettlementRangeContract(input: $input) {
      id
      lowerHourPart {
        deducationHourlyUnitPrice
        lowerHour
      }
      member {
        laborCostWithoutTax
        memberId
        name
        profileImageUrl
        roundedTotalWorkingTimeSec
        salesAmountWithoutTax
        totalWorkingTimeSec
      }
      monthlyUnitPrice
      upperHourPart {
        excessHourlyUnitPrice
        upperHour
      }
      versionNo
    }
  }
`;
export type CreateSettlementRangeContractMutationFn = Apollo.MutationFunction<
  CreateSettlementRangeContractMutation,
  CreateSettlementRangeContractMutationVariables
>;

/**
 * __useCreateSettlementRangeContractMutation__
 *
 * To run a mutation, you first call `useCreateSettlementRangeContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSettlementRangeContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSettlementRangeContractMutation, { data, loading, error }] = useCreateSettlementRangeContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSettlementRangeContractMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSettlementRangeContractMutation,
    CreateSettlementRangeContractMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateSettlementRangeContractMutation,
    CreateSettlementRangeContractMutationVariables
  >(CreateSettlementRangeContractDocument, options);
}
export type CreateSettlementRangeContractMutationHookResult = ReturnType<
  typeof useCreateSettlementRangeContractMutation
>;
export type CreateSettlementRangeContractMutationResult =
  Apollo.MutationResult<CreateSettlementRangeContractMutation>;
export type CreateSettlementRangeContractMutationOptions = Apollo.BaseMutationOptions<
  CreateSettlementRangeContractMutation,
  CreateSettlementRangeContractMutationVariables
>;
export const CreateSubTaskDocument = gql`
  mutation createSubTask($input: RegisterSubTaskInputInput!, $taskId: String!) {
    createSubTask(input: $input, taskId: $taskId) {
      complete
      completeDateTime
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      id
      sortNo
      task {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        asssignee {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        calendarLinkageCode
        completeDateTime
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        estimatedRemainingTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        laborCost
        priority
        progressDeviationRate
        progressRate
        project {
          assignees {
            sortNo
          }
          calendarLinkageCode
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          estimateTimeSec
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            sortNo
          }
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          projectStatus {
            endStatus
            id
            name
            sortNo
            teamId
            versionNo
          }
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          team {
            archived
            id
            name
            organization {
              allowConnectThirdPartyCalendar
              contactMailAddress
              contractPreriod
              id
              imageUrl
              ipRestrictionFlg
              name
              plan {
                code
                contractPreriod
                id
              }
              requiredTwoFactorAuth
              suspensionFlg
              versionNo
            }
            personalTeam
            status
            versionNo
          }
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        registerDateTime
        remainingTimeSec
        scheduleWorkingTimeSec
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInMyTask
        sortNoInTaskStatus
        taskStatus {
          endStatus
          id
          name
          project {
            calendarLinkageCode
            color
            complete
            completeDateTime
            costBudgetWithoutTax
            description
            estimateTimeSec
            id
            key
            laborCost
            laborCostWithoutTax
            laborShare
            name
            priority
            registerDateTime
            salesAmountWithoutTax
            scheduledEndDateTime
            scheduledStartDateTime
            sortNoInGanttChart
            sortNoInList
            sortNoInProjectStatus
            totalScheduleTimeSec
            versionNo
            workingTimeSec
          }
          sortNo
          versionNo
        }
        title
        versionNo
        workingMembers {
          lastWorkingStartDateTime
          lastWorkingStopDateTime
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
        }
        workingTimeSec
      }
      title
      versionNo
    }
  }
`;
export type CreateSubTaskMutationFn = Apollo.MutationFunction<
  CreateSubTaskMutation,
  CreateSubTaskMutationVariables
>;

/**
 * __useCreateSubTaskMutation__
 *
 * To run a mutation, you first call `useCreateSubTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubTaskMutation, { data, loading, error }] = useCreateSubTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useCreateSubTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateSubTaskMutation, CreateSubTaskMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateSubTaskMutation, CreateSubTaskMutationVariables>(
    CreateSubTaskDocument,
    options
  );
}
export type CreateSubTaskMutationHookResult = ReturnType<typeof useCreateSubTaskMutation>;
export type CreateSubTaskMutationResult = Apollo.MutationResult<CreateSubTaskMutation>;
export type CreateSubTaskMutationOptions = Apollo.BaseMutationOptions<
  CreateSubTaskMutation,
  CreateSubTaskMutationVariables
>;
export const CreateTagDocument = gql`
  mutation createTag($input: RegisterTagInputInput!) {
    createTag(input: $input) {
      id
      name
      note
      organization {
        allowConnectThirdPartyCalendar
        contactMailAddress
        contractPreriod
        id
        imageUrl
        ipRestrictionFlg
        name
        plan {
          code
          contractPreriod
          id
        }
        requiredTwoFactorAuth
        suspensionFlg
        versionNo
      }
      relatedTaskCount
      sortNo
      versionNo
    }
  }
`;
export type CreateTagMutationFn = Apollo.MutationFunction<
  CreateTagMutation,
  CreateTagMutationVariables
>;

/**
 * __useCreateTagMutation__
 *
 * To run a mutation, you first call `useCreateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagMutation, { data, loading, error }] = useCreateTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTagMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTagMutation, CreateTagMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTagMutation, CreateTagMutationVariables>(
    CreateTagDocument,
    options
  );
}
export type CreateTagMutationHookResult = ReturnType<typeof useCreateTagMutation>;
export type CreateTagMutationResult = Apollo.MutationResult<CreateTagMutation>;
export type CreateTagMutationOptions = Apollo.BaseMutationOptions<
  CreateTagMutation,
  CreateTagMutationVariables
>;
export const CreateTaskDocument = gql`
  mutation createTask($input: RegisterTaskInputInput!, $projectId: String!) {
    createTask(input: $input, projectId: $projectId) {
      assignees {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      asssignee {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      calendarLinkageCode
      completeDateTime
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      estimatedRemainingTimeSec
      favoriteMembers {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      favoriteMembersInfo {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      id
      laborCost
      priority
      progressDeviationRate
      progressRate
      project {
        assignees {
          sortNo
        }
        calendarLinkageCode
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        color
        complete
        completeDateTime
        costBudgetWithoutTax
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          sortNo
        }
        id
        key
        laborCost
        laborCostWithoutTax
        laborShare
        name
        priority
        projectStatus {
          endStatus
          id
          name
          sortNo
          teamId
          versionNo
        }
        registerDateTime
        salesAmountWithoutTax
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInProjectStatus
        team {
          archived
          id
          name
          organization {
            allowConnectThirdPartyCalendar
            contactMailAddress
            contractPreriod
            id
            imageUrl
            ipRestrictionFlg
            name
            plan {
              code
              contractPreriod
              id
            }
            requiredTwoFactorAuth
            suspensionFlg
            versionNo
          }
          personalTeam
          status
          versionNo
        }
        totalScheduleTimeSec
        versionNo
        workingTimeSec
      }
      registerDateTime
      remainingTimeSec
      scheduleWorkingTimeSec
      scheduledEndDateTime
      scheduledStartDateTime
      sortNoInGanttChart
      sortNoInList
      sortNoInMyTask
      sortNoInTaskStatus
      taskStatus {
        endStatus
        id
        name
        project {
          calendarLinkageCode
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          description
          estimateTimeSec
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        sortNo
        versionNo
      }
      title
      versionNo
      workingMembers {
        lastWorkingStartDateTime
        lastWorkingStopDateTime
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
      }
      workingTimeSec
    }
  }
`;
export type CreateTaskMutationFn = Apollo.MutationFunction<
  CreateTaskMutation,
  CreateTaskMutationVariables
>;

/**
 * __useCreateTaskMutation__
 *
 * To run a mutation, you first call `useCreateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskMutation, { data, loading, error }] = useCreateTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useCreateTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTaskMutation, CreateTaskMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTaskMutation, CreateTaskMutationVariables>(
    CreateTaskDocument,
    options
  );
}
export type CreateTaskMutationHookResult = ReturnType<typeof useCreateTaskMutation>;
export type CreateTaskMutationResult = Apollo.MutationResult<CreateTaskMutation>;
export type CreateTaskMutationOptions = Apollo.BaseMutationOptions<
  CreateTaskMutation,
  CreateTaskMutationVariables
>;
export const CreateTaskCustomAttributeMasterDocument = gql`
  mutation createTaskCustomAttributeMaster($input: RegisterTaskCustomAttributeMasterInputInput!) {
    createTaskCustomAttributeMaster(input: $input) {
      id
      listItem {
        id
        sortNo
        value
        versionNo
      }
      name
      required
      sortNo
      type
      versionNo
    }
  }
`;
export type CreateTaskCustomAttributeMasterMutationFn = Apollo.MutationFunction<
  CreateTaskCustomAttributeMasterMutation,
  CreateTaskCustomAttributeMasterMutationVariables
>;

/**
 * __useCreateTaskCustomAttributeMasterMutation__
 *
 * To run a mutation, you first call `useCreateTaskCustomAttributeMasterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskCustomAttributeMasterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskCustomAttributeMasterMutation, { data, loading, error }] = useCreateTaskCustomAttributeMasterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTaskCustomAttributeMasterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTaskCustomAttributeMasterMutation,
    CreateTaskCustomAttributeMasterMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateTaskCustomAttributeMasterMutation,
    CreateTaskCustomAttributeMasterMutationVariables
  >(CreateTaskCustomAttributeMasterDocument, options);
}
export type CreateTaskCustomAttributeMasterMutationHookResult = ReturnType<
  typeof useCreateTaskCustomAttributeMasterMutation
>;
export type CreateTaskCustomAttributeMasterMutationResult =
  Apollo.MutationResult<CreateTaskCustomAttributeMasterMutation>;
export type CreateTaskCustomAttributeMasterMutationOptions = Apollo.BaseMutationOptions<
  CreateTaskCustomAttributeMasterMutation,
  CreateTaskCustomAttributeMasterMutationVariables
>;
export const CreateTaskRemainderDocument = gql`
  mutation createTaskRemainder($input: RegisterTaskRemainderInputInput!, $taskId: String!) {
    createTaskRemainder(input: $input, taskId: $taskId) {
      beforeDeadlineMinutes
      id
      notificationSentFlg
      targetMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      task {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        asssignee {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        calendarLinkageCode
        completeDateTime
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        estimatedRemainingTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        laborCost
        priority
        progressDeviationRate
        progressRate
        project {
          assignees {
            sortNo
          }
          calendarLinkageCode
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          estimateTimeSec
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            sortNo
          }
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          projectStatus {
            endStatus
            id
            name
            sortNo
            teamId
            versionNo
          }
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          team {
            archived
            id
            name
            organization {
              allowConnectThirdPartyCalendar
              contactMailAddress
              contractPreriod
              id
              imageUrl
              ipRestrictionFlg
              name
              plan {
                code
                contractPreriod
                id
              }
              requiredTwoFactorAuth
              suspensionFlg
              versionNo
            }
            personalTeam
            status
            versionNo
          }
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        registerDateTime
        remainingTimeSec
        scheduleWorkingTimeSec
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInMyTask
        sortNoInTaskStatus
        taskStatus {
          endStatus
          id
          name
          project {
            calendarLinkageCode
            color
            complete
            completeDateTime
            costBudgetWithoutTax
            description
            estimateTimeSec
            id
            key
            laborCost
            laborCostWithoutTax
            laborShare
            name
            priority
            registerDateTime
            salesAmountWithoutTax
            scheduledEndDateTime
            scheduledStartDateTime
            sortNoInGanttChart
            sortNoInList
            sortNoInProjectStatus
            totalScheduleTimeSec
            versionNo
            workingTimeSec
          }
          sortNo
          versionNo
        }
        title
        versionNo
        workingMembers {
          lastWorkingStartDateTime
          lastWorkingStopDateTime
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
        }
        workingTimeSec
      }
      taskRemainderDestType
      taskRemainderType
    }
  }
`;
export type CreateTaskRemainderMutationFn = Apollo.MutationFunction<
  CreateTaskRemainderMutation,
  CreateTaskRemainderMutationVariables
>;

/**
 * __useCreateTaskRemainderMutation__
 *
 * To run a mutation, you first call `useCreateTaskRemainderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskRemainderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskRemainderMutation, { data, loading, error }] = useCreateTaskRemainderMutation({
 *   variables: {
 *      input: // value for 'input'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useCreateTaskRemainderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTaskRemainderMutation,
    CreateTaskRemainderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTaskRemainderMutation, CreateTaskRemainderMutationVariables>(
    CreateTaskRemainderDocument,
    options
  );
}
export type CreateTaskRemainderMutationHookResult = ReturnType<
  typeof useCreateTaskRemainderMutation
>;
export type CreateTaskRemainderMutationResult = Apollo.MutationResult<CreateTaskRemainderMutation>;
export type CreateTaskRemainderMutationOptions = Apollo.BaseMutationOptions<
  CreateTaskRemainderMutation,
  CreateTaskRemainderMutationVariables
>;
export const CreateTaskStatusDocument = gql`
  mutation createTaskStatus($input: RegisterTaskStatusInputInput!, $projectId: String!) {
    createTaskStatus(input: $input, projectId: $projectId) {
      endStatus
      id
      name
      project {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        calendarLinkageCode
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        color
        complete
        completeDateTime
        costBudgetWithoutTax
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        key
        laborCost
        laborCostWithoutTax
        laborShare
        name
        priority
        projectStatus {
          endStatus
          id
          name
          sortNo
          teamId
          versionNo
        }
        registerDateTime
        salesAmountWithoutTax
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInProjectStatus
        team {
          archived
          id
          name
          organization {
            allowConnectThirdPartyCalendar
            contactMailAddress
            contractPreriod
            id
            imageUrl
            ipRestrictionFlg
            name
            plan {
              code
              contractPreriod
              id
            }
            requiredTwoFactorAuth
            suspensionFlg
            versionNo
          }
          personalTeam
          status
          versionNo
        }
        totalScheduleTimeSec
        versionNo
        workingTimeSec
      }
      sortNo
      versionNo
    }
  }
`;
export type CreateTaskStatusMutationFn = Apollo.MutationFunction<
  CreateTaskStatusMutation,
  CreateTaskStatusMutationVariables
>;

/**
 * __useCreateTaskStatusMutation__
 *
 * To run a mutation, you first call `useCreateTaskStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskStatusMutation, { data, loading, error }] = useCreateTaskStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useCreateTaskStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTaskStatusMutation,
    CreateTaskStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTaskStatusMutation, CreateTaskStatusMutationVariables>(
    CreateTaskStatusDocument,
    options
  );
}
export type CreateTaskStatusMutationHookResult = ReturnType<typeof useCreateTaskStatusMutation>;
export type CreateTaskStatusMutationResult = Apollo.MutationResult<CreateTaskStatusMutation>;
export type CreateTaskStatusMutationOptions = Apollo.BaseMutationOptions<
  CreateTaskStatusMutation,
  CreateTaskStatusMutationVariables
>;
export const CreateTasksDocument = gql`
  mutation createTasks($input: RegisterTasksInputInput!, $projectId: String!) {
    createTasks(input: $input, projectId: $projectId) {
      assignees {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      asssignee {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      calendarLinkageCode
      completeDateTime
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      estimatedRemainingTimeSec
      favoriteMembers {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      favoriteMembersInfo {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      id
      laborCost
      priority
      progressDeviationRate
      progressRate
      project {
        assignees {
          sortNo
        }
        calendarLinkageCode
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        color
        complete
        completeDateTime
        costBudgetWithoutTax
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          sortNo
        }
        id
        key
        laborCost
        laborCostWithoutTax
        laborShare
        name
        priority
        projectStatus {
          endStatus
          id
          name
          sortNo
          teamId
          versionNo
        }
        registerDateTime
        salesAmountWithoutTax
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInProjectStatus
        team {
          archived
          id
          name
          organization {
            allowConnectThirdPartyCalendar
            contactMailAddress
            contractPreriod
            id
            imageUrl
            ipRestrictionFlg
            name
            plan {
              code
              contractPreriod
              id
            }
            requiredTwoFactorAuth
            suspensionFlg
            versionNo
          }
          personalTeam
          status
          versionNo
        }
        totalScheduleTimeSec
        versionNo
        workingTimeSec
      }
      registerDateTime
      remainingTimeSec
      scheduleWorkingTimeSec
      scheduledEndDateTime
      scheduledStartDateTime
      sortNoInGanttChart
      sortNoInList
      sortNoInMyTask
      sortNoInTaskStatus
      taskStatus {
        endStatus
        id
        name
        project {
          calendarLinkageCode
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          description
          estimateTimeSec
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        sortNo
        versionNo
      }
      title
      versionNo
      workingMembers {
        lastWorkingStartDateTime
        lastWorkingStopDateTime
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
      }
      workingTimeSec
    }
  }
`;
export type CreateTasksMutationFn = Apollo.MutationFunction<
  CreateTasksMutation,
  CreateTasksMutationVariables
>;

/**
 * __useCreateTasksMutation__
 *
 * To run a mutation, you first call `useCreateTasksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTasksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTasksMutation, { data, loading, error }] = useCreateTasksMutation({
 *   variables: {
 *      input: // value for 'input'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useCreateTasksMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTasksMutation, CreateTasksMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTasksMutation, CreateTasksMutationVariables>(
    CreateTasksDocument,
    options
  );
}
export type CreateTasksMutationHookResult = ReturnType<typeof useCreateTasksMutation>;
export type CreateTasksMutationResult = Apollo.MutationResult<CreateTasksMutation>;
export type CreateTasksMutationOptions = Apollo.BaseMutationOptions<
  CreateTasksMutation,
  CreateTasksMutationVariables
>;
export const CreateTeamDocument = gql`
  mutation createTeam($input: RegisterTeamInputInput, $organizationId: String!) {
    createTeam(input: $input, organizationId: $organizationId) {
      archived
      id
      name
      organization {
        allowConnectThirdPartyCalendar
        contactMailAddress
        contractPreriod
        id
        imageUrl
        ipRestrictionFlg
        name
        plan {
          code
          contractPreriod
          id
        }
        requiredTwoFactorAuth
        suspensionFlg
        versionNo
      }
      personalTeam
      status
      versionNo
    }
  }
`;
export type CreateTeamMutationFn = Apollo.MutationFunction<
  CreateTeamMutation,
  CreateTeamMutationVariables
>;

/**
 * __useCreateTeamMutation__
 *
 * To run a mutation, you first call `useCreateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamMutation, { data, loading, error }] = useCreateTeamMutation({
 *   variables: {
 *      input: // value for 'input'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useCreateTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTeamMutation, CreateTeamMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTeamMutation, CreateTeamMutationVariables>(
    CreateTeamDocument,
    options
  );
}
export type CreateTeamMutationHookResult = ReturnType<typeof useCreateTeamMutation>;
export type CreateTeamMutationResult = Apollo.MutationResult<CreateTeamMutation>;
export type CreateTeamMutationOptions = Apollo.BaseMutationOptions<
  CreateTeamMutation,
  CreateTeamMutationVariables
>;
export const CreateTeamCsvReportDownloadUrlDocument = gql`
  mutation createTeamCsvReportDownloadUrl($id: ID!, $isUtf8: Boolean!) {
    createTeamCsvReportDownloadUrl(id: $id, isUtf8: $isUtf8) {
      downloadUrl
    }
  }
`;
export type CreateTeamCsvReportDownloadUrlMutationFn = Apollo.MutationFunction<
  CreateTeamCsvReportDownloadUrlMutation,
  CreateTeamCsvReportDownloadUrlMutationVariables
>;

/**
 * __useCreateTeamCsvReportDownloadUrlMutation__
 *
 * To run a mutation, you first call `useCreateTeamCsvReportDownloadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamCsvReportDownloadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamCsvReportDownloadUrlMutation, { data, loading, error }] = useCreateTeamCsvReportDownloadUrlMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isUtf8: // value for 'isUtf8'
 *   },
 * });
 */
export function useCreateTeamCsvReportDownloadUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTeamCsvReportDownloadUrlMutation,
    CreateTeamCsvReportDownloadUrlMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateTeamCsvReportDownloadUrlMutation,
    CreateTeamCsvReportDownloadUrlMutationVariables
  >(CreateTeamCsvReportDownloadUrlDocument, options);
}
export type CreateTeamCsvReportDownloadUrlMutationHookResult = ReturnType<
  typeof useCreateTeamCsvReportDownloadUrlMutation
>;
export type CreateTeamCsvReportDownloadUrlMutationResult =
  Apollo.MutationResult<CreateTeamCsvReportDownloadUrlMutation>;
export type CreateTeamCsvReportDownloadUrlMutationOptions = Apollo.BaseMutationOptions<
  CreateTeamCsvReportDownloadUrlMutation,
  CreateTeamCsvReportDownloadUrlMutationVariables
>;
export const CreateTeamReportDownloadUrlDocument = gql`
  mutation createTeamReportDownloadUrl($id: ID!) {
    createTeamReportDownloadUrl(id: $id) {
      downloadUrl
    }
  }
`;
export type CreateTeamReportDownloadUrlMutationFn = Apollo.MutationFunction<
  CreateTeamReportDownloadUrlMutation,
  CreateTeamReportDownloadUrlMutationVariables
>;

/**
 * __useCreateTeamReportDownloadUrlMutation__
 *
 * To run a mutation, you first call `useCreateTeamReportDownloadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamReportDownloadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamReportDownloadUrlMutation, { data, loading, error }] = useCreateTeamReportDownloadUrlMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCreateTeamReportDownloadUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTeamReportDownloadUrlMutation,
    CreateTeamReportDownloadUrlMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateTeamReportDownloadUrlMutation,
    CreateTeamReportDownloadUrlMutationVariables
  >(CreateTeamReportDownloadUrlDocument, options);
}
export type CreateTeamReportDownloadUrlMutationHookResult = ReturnType<
  typeof useCreateTeamReportDownloadUrlMutation
>;
export type CreateTeamReportDownloadUrlMutationResult =
  Apollo.MutationResult<CreateTeamReportDownloadUrlMutation>;
export type CreateTeamReportDownloadUrlMutationOptions = Apollo.BaseMutationOptions<
  CreateTeamReportDownloadUrlMutation,
  CreateTeamReportDownloadUrlMutationVariables
>;
export const CreateWorkingHistoryDocument = gql`
  mutation createWorkingHistory($input: RegisterWorkingHistoryInputInput!, $taskId: String!) {
    createWorkingHistory(input: $input, taskId: $taskId) {
      calendarEvent {
        calendarType
        clientId
        clientName
        endDateTime
        eventName
        id
        laborCost
        personalTeam
        projectColor
        projectId
        projectName
        startDateTime
        taskId
        taskTitle
        teamId
        teamName
        thirdPartyCalendarEventId
        thirdPartyCalendarInfoList {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          thirdPartyCalendarId
        }
        versionNo
        workingTimeSec
      }
      closingProcessInfo {
        closed
        targetDateTime
      }
      end
      id
      memo
      start
      task {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        asssignee {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        calendarLinkageCode
        completeDateTime
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        estimatedRemainingTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        laborCost
        priority
        progressDeviationRate
        progressRate
        project {
          assignees {
            sortNo
          }
          calendarLinkageCode
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          estimateTimeSec
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            sortNo
          }
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          projectStatus {
            endStatus
            id
            name
            sortNo
            teamId
            versionNo
          }
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          team {
            archived
            id
            name
            organization {
              allowConnectThirdPartyCalendar
              contactMailAddress
              contractPreriod
              id
              imageUrl
              ipRestrictionFlg
              name
              plan {
                code
                contractPreriod
                id
              }
              requiredTwoFactorAuth
              suspensionFlg
              versionNo
            }
            personalTeam
            status
            versionNo
          }
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        registerDateTime
        remainingTimeSec
        scheduleWorkingTimeSec
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInMyTask
        sortNoInTaskStatus
        taskStatus {
          endStatus
          id
          name
          project {
            calendarLinkageCode
            color
            complete
            completeDateTime
            costBudgetWithoutTax
            description
            estimateTimeSec
            id
            key
            laborCost
            laborCostWithoutTax
            laborShare
            name
            priority
            registerDateTime
            salesAmountWithoutTax
            scheduledEndDateTime
            scheduledStartDateTime
            sortNoInGanttChart
            sortNoInList
            sortNoInProjectStatus
            totalScheduleTimeSec
            versionNo
            workingTimeSec
          }
          sortNo
          versionNo
        }
        title
        versionNo
        workingMembers {
          lastWorkingStartDateTime
          lastWorkingStopDateTime
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
        }
        workingTimeSec
      }
      versionNo
      workingMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      workingTimeSec
    }
  }
`;
export type CreateWorkingHistoryMutationFn = Apollo.MutationFunction<
  CreateWorkingHistoryMutation,
  CreateWorkingHistoryMutationVariables
>;

/**
 * __useCreateWorkingHistoryMutation__
 *
 * To run a mutation, you first call `useCreateWorkingHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkingHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkingHistoryMutation, { data, loading, error }] = useCreateWorkingHistoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useCreateWorkingHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateWorkingHistoryMutation,
    CreateWorkingHistoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateWorkingHistoryMutation, CreateWorkingHistoryMutationVariables>(
    CreateWorkingHistoryDocument,
    options
  );
}
export type CreateWorkingHistoryMutationHookResult = ReturnType<
  typeof useCreateWorkingHistoryMutation
>;
export type CreateWorkingHistoryMutationResult =
  Apollo.MutationResult<CreateWorkingHistoryMutation>;
export type CreateWorkingHistoryMutationOptions = Apollo.BaseMutationOptions<
  CreateWorkingHistoryMutation,
  CreateWorkingHistoryMutationVariables
>;
export const CreateWorkingHistoryByCalendarEventDocument = gql`
  mutation createWorkingHistoryByCalendarEvent(
    $calendarEventId: String!
    $input: RegisterWorkingHistoryInputInput!
  ) {
    createWorkingHistoryByCalendarEvent(calendarEventId: $calendarEventId, input: $input) {
      calendarEvent {
        calendarType
        clientId
        clientName
        endDateTime
        eventName
        id
        laborCost
        personalTeam
        projectColor
        projectId
        projectName
        startDateTime
        taskId
        taskTitle
        teamId
        teamName
        thirdPartyCalendarEventId
        thirdPartyCalendarInfoList {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          thirdPartyCalendarId
        }
        versionNo
        workingTimeSec
      }
      closingProcessInfo {
        closed
        targetDateTime
      }
      end
      id
      memo
      start
      task {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        asssignee {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        calendarLinkageCode
        completeDateTime
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        estimatedRemainingTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        laborCost
        priority
        progressDeviationRate
        progressRate
        project {
          assignees {
            sortNo
          }
          calendarLinkageCode
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          estimateTimeSec
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            sortNo
          }
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          projectStatus {
            endStatus
            id
            name
            sortNo
            teamId
            versionNo
          }
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          team {
            archived
            id
            name
            organization {
              allowConnectThirdPartyCalendar
              contactMailAddress
              contractPreriod
              id
              imageUrl
              ipRestrictionFlg
              name
              plan {
                code
                contractPreriod
                id
              }
              requiredTwoFactorAuth
              suspensionFlg
              versionNo
            }
            personalTeam
            status
            versionNo
          }
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        registerDateTime
        remainingTimeSec
        scheduleWorkingTimeSec
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInMyTask
        sortNoInTaskStatus
        taskStatus {
          endStatus
          id
          name
          project {
            calendarLinkageCode
            color
            complete
            completeDateTime
            costBudgetWithoutTax
            description
            estimateTimeSec
            id
            key
            laborCost
            laborCostWithoutTax
            laborShare
            name
            priority
            registerDateTime
            salesAmountWithoutTax
            scheduledEndDateTime
            scheduledStartDateTime
            sortNoInGanttChart
            sortNoInList
            sortNoInProjectStatus
            totalScheduleTimeSec
            versionNo
            workingTimeSec
          }
          sortNo
          versionNo
        }
        title
        versionNo
        workingMembers {
          lastWorkingStartDateTime
          lastWorkingStopDateTime
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
        }
        workingTimeSec
      }
      versionNo
      workingMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      workingTimeSec
    }
  }
`;
export type CreateWorkingHistoryByCalendarEventMutationFn = Apollo.MutationFunction<
  CreateWorkingHistoryByCalendarEventMutation,
  CreateWorkingHistoryByCalendarEventMutationVariables
>;

/**
 * __useCreateWorkingHistoryByCalendarEventMutation__
 *
 * To run a mutation, you first call `useCreateWorkingHistoryByCalendarEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkingHistoryByCalendarEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkingHistoryByCalendarEventMutation, { data, loading, error }] = useCreateWorkingHistoryByCalendarEventMutation({
 *   variables: {
 *      calendarEventId: // value for 'calendarEventId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWorkingHistoryByCalendarEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateWorkingHistoryByCalendarEventMutation,
    CreateWorkingHistoryByCalendarEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateWorkingHistoryByCalendarEventMutation,
    CreateWorkingHistoryByCalendarEventMutationVariables
  >(CreateWorkingHistoryByCalendarEventDocument, options);
}
export type CreateWorkingHistoryByCalendarEventMutationHookResult = ReturnType<
  typeof useCreateWorkingHistoryByCalendarEventMutation
>;
export type CreateWorkingHistoryByCalendarEventMutationResult =
  Apollo.MutationResult<CreateWorkingHistoryByCalendarEventMutation>;
export type CreateWorkingHistoryByCalendarEventMutationOptions = Apollo.BaseMutationOptions<
  CreateWorkingHistoryByCalendarEventMutation,
  CreateWorkingHistoryByCalendarEventMutationVariables
>;
export const CreateWorkingHistoryWithTaskDocument = gql`
  mutation createWorkingHistoryWithTask(
    $input: RegisterWorkingHistoryWithTaskInputInput!
    $projectId: String!
  ) {
    createWorkingHistoryWithTask(input: $input, projectId: $projectId) {
      task {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        asssignee {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        calendarLinkageCode
        completeDateTime
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        estimatedRemainingTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        laborCost
        priority
        progressDeviationRate
        progressRate
        project {
          assignees {
            sortNo
          }
          calendarLinkageCode
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          estimateTimeSec
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            sortNo
          }
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          projectStatus {
            endStatus
            id
            name
            sortNo
            teamId
            versionNo
          }
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          team {
            archived
            id
            name
            organization {
              allowConnectThirdPartyCalendar
              contactMailAddress
              contractPreriod
              id
              imageUrl
              ipRestrictionFlg
              name
              plan {
                code
                contractPreriod
                id
              }
              requiredTwoFactorAuth
              suspensionFlg
              versionNo
            }
            personalTeam
            status
            versionNo
          }
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        registerDateTime
        remainingTimeSec
        scheduleWorkingTimeSec
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInMyTask
        sortNoInTaskStatus
        taskStatus {
          endStatus
          id
          name
          project {
            calendarLinkageCode
            color
            complete
            completeDateTime
            costBudgetWithoutTax
            description
            estimateTimeSec
            id
            key
            laborCost
            laborCostWithoutTax
            laborShare
            name
            priority
            registerDateTime
            salesAmountWithoutTax
            scheduledEndDateTime
            scheduledStartDateTime
            sortNoInGanttChart
            sortNoInList
            sortNoInProjectStatus
            totalScheduleTimeSec
            versionNo
            workingTimeSec
          }
          sortNo
          versionNo
        }
        title
        versionNo
        workingMembers {
          lastWorkingStartDateTime
          lastWorkingStopDateTime
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
        }
        workingTimeSec
      }
      workingHistory {
        calendarEvent {
          calendarType
          clientId
          clientName
          endDateTime
          eventName
          id
          laborCost
          personalTeam
          projectColor
          projectId
          projectName
          startDateTime
          taskId
          taskTitle
          teamId
          teamName
          thirdPartyCalendarEventId
          thirdPartyCalendarInfoList {
            member {
              adminRole
              clientManagementRole
              department
              employeeNumber
              id
              laborCostManagementRole
              mailAddress
              memberManagementRole
              memberRegistrationDateTime
              memberStatus
              name
              organizationMemberViewRole
              organizationReportRole
              profileImageUrl
              projectCreatePermissionFlg
              projectCustomAttributePermissionFlg
              projectDeletePermissionFlg
              projectUpdatePermissionFlg
              proxyEditWorkingDataRole
              salesManagementRole
              statusMessage
              taskCreatePermissionFlg
              taskCustomAttributePermissionFlg
              taskDeletePermissionFlg
              taskUpdatePermissionFlg
              teamMemberViewRole
              teamReportRole
              versionNo
            }
            thirdPartyCalendarId
          }
          versionNo
          workingTimeSec
        }
        closingProcessInfo {
          closed
          targetDateTime
        }
        end
        id
        memo
        start
        task {
          calendarLinkageCode
          completeDateTime
          description
          estimateTimeSec
          estimatedRemainingTimeSec
          id
          laborCost
          priority
          progressDeviationRate
          progressRate
          registerDateTime
          remainingTimeSec
          scheduleWorkingTimeSec
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInMyTask
          sortNoInTaskStatus
          title
          versionNo
          workingTimeSec
        }
        versionNo
        workingMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        workingTimeSec
      }
    }
  }
`;
export type CreateWorkingHistoryWithTaskMutationFn = Apollo.MutationFunction<
  CreateWorkingHistoryWithTaskMutation,
  CreateWorkingHistoryWithTaskMutationVariables
>;

/**
 * __useCreateWorkingHistoryWithTaskMutation__
 *
 * To run a mutation, you first call `useCreateWorkingHistoryWithTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkingHistoryWithTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkingHistoryWithTaskMutation, { data, loading, error }] = useCreateWorkingHistoryWithTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useCreateWorkingHistoryWithTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateWorkingHistoryWithTaskMutation,
    CreateWorkingHistoryWithTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateWorkingHistoryWithTaskMutation,
    CreateWorkingHistoryWithTaskMutationVariables
  >(CreateWorkingHistoryWithTaskDocument, options);
}
export type CreateWorkingHistoryWithTaskMutationHookResult = ReturnType<
  typeof useCreateWorkingHistoryWithTaskMutation
>;
export type CreateWorkingHistoryWithTaskMutationResult =
  Apollo.MutationResult<CreateWorkingHistoryWithTaskMutation>;
export type CreateWorkingHistoryWithTaskMutationOptions = Apollo.BaseMutationOptions<
  CreateWorkingHistoryWithTaskMutation,
  CreateWorkingHistoryWithTaskMutationVariables
>;
export const CreateWorkingScheduleDocument = gql`
  mutation createWorkingSchedule($input: RegisterWorkingScheduleInputInput!, $taskId: String!) {
    createWorkingSchedule(input: $input, taskId: $taskId) {
      calendarEvent {
        calendarType
        clientId
        clientName
        endDateTime
        eventName
        id
        laborCost
        personalTeam
        projectColor
        projectId
        projectName
        startDateTime
        taskId
        taskTitle
        teamId
        teamName
        thirdPartyCalendarEventId
        thirdPartyCalendarInfoList {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          thirdPartyCalendarId
        }
        versionNo
        workingTimeSec
      }
      end
      id
      memo
      start
      task {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        asssignee {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        calendarLinkageCode
        completeDateTime
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        estimatedRemainingTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        laborCost
        priority
        progressDeviationRate
        progressRate
        project {
          assignees {
            sortNo
          }
          calendarLinkageCode
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          estimateTimeSec
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            sortNo
          }
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          projectStatus {
            endStatus
            id
            name
            sortNo
            teamId
            versionNo
          }
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          team {
            archived
            id
            name
            organization {
              allowConnectThirdPartyCalendar
              contactMailAddress
              contractPreriod
              id
              imageUrl
              ipRestrictionFlg
              name
              plan {
                code
                contractPreriod
                id
              }
              requiredTwoFactorAuth
              suspensionFlg
              versionNo
            }
            personalTeam
            status
            versionNo
          }
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        registerDateTime
        remainingTimeSec
        scheduleWorkingTimeSec
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInMyTask
        sortNoInTaskStatus
        taskStatus {
          endStatus
          id
          name
          project {
            calendarLinkageCode
            color
            complete
            completeDateTime
            costBudgetWithoutTax
            description
            estimateTimeSec
            id
            key
            laborCost
            laborCostWithoutTax
            laborShare
            name
            priority
            registerDateTime
            salesAmountWithoutTax
            scheduledEndDateTime
            scheduledStartDateTime
            sortNoInGanttChart
            sortNoInList
            sortNoInProjectStatus
            totalScheduleTimeSec
            versionNo
            workingTimeSec
          }
          sortNo
          versionNo
        }
        title
        versionNo
        workingMembers {
          lastWorkingStartDateTime
          lastWorkingStopDateTime
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
        }
        workingTimeSec
      }
      versionNo
      workingMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      workingTimeSec
    }
  }
`;
export type CreateWorkingScheduleMutationFn = Apollo.MutationFunction<
  CreateWorkingScheduleMutation,
  CreateWorkingScheduleMutationVariables
>;

/**
 * __useCreateWorkingScheduleMutation__
 *
 * To run a mutation, you first call `useCreateWorkingScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkingScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkingScheduleMutation, { data, loading, error }] = useCreateWorkingScheduleMutation({
 *   variables: {
 *      input: // value for 'input'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useCreateWorkingScheduleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateWorkingScheduleMutation,
    CreateWorkingScheduleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateWorkingScheduleMutation, CreateWorkingScheduleMutationVariables>(
    CreateWorkingScheduleDocument,
    options
  );
}
export type CreateWorkingScheduleMutationHookResult = ReturnType<
  typeof useCreateWorkingScheduleMutation
>;
export type CreateWorkingScheduleMutationResult =
  Apollo.MutationResult<CreateWorkingScheduleMutation>;
export type CreateWorkingScheduleMutationOptions = Apollo.BaseMutationOptions<
  CreateWorkingScheduleMutation,
  CreateWorkingScheduleMutationVariables
>;
export const CreateWorkingScheduleWithTaskDocument = gql`
  mutation createWorkingScheduleWithTask(
    $input: RegisterWorkingScheduleWithTaskInputInput!
    $projectId: String!
  ) {
    createWorkingScheduleWithTask(input: $input, projectId: $projectId) {
      task {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        asssignee {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        calendarLinkageCode
        completeDateTime
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        estimatedRemainingTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        laborCost
        priority
        progressDeviationRate
        progressRate
        project {
          assignees {
            sortNo
          }
          calendarLinkageCode
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          estimateTimeSec
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            sortNo
          }
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          projectStatus {
            endStatus
            id
            name
            sortNo
            teamId
            versionNo
          }
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          team {
            archived
            id
            name
            organization {
              allowConnectThirdPartyCalendar
              contactMailAddress
              contractPreriod
              id
              imageUrl
              ipRestrictionFlg
              name
              plan {
                code
                contractPreriod
                id
              }
              requiredTwoFactorAuth
              suspensionFlg
              versionNo
            }
            personalTeam
            status
            versionNo
          }
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        registerDateTime
        remainingTimeSec
        scheduleWorkingTimeSec
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInMyTask
        sortNoInTaskStatus
        taskStatus {
          endStatus
          id
          name
          project {
            calendarLinkageCode
            color
            complete
            completeDateTime
            costBudgetWithoutTax
            description
            estimateTimeSec
            id
            key
            laborCost
            laborCostWithoutTax
            laborShare
            name
            priority
            registerDateTime
            salesAmountWithoutTax
            scheduledEndDateTime
            scheduledStartDateTime
            sortNoInGanttChart
            sortNoInList
            sortNoInProjectStatus
            totalScheduleTimeSec
            versionNo
            workingTimeSec
          }
          sortNo
          versionNo
        }
        title
        versionNo
        workingMembers {
          lastWorkingStartDateTime
          lastWorkingStopDateTime
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
        }
        workingTimeSec
      }
      workingSchedule {
        calendarEvent {
          calendarType
          clientId
          clientName
          endDateTime
          eventName
          id
          laborCost
          personalTeam
          projectColor
          projectId
          projectName
          startDateTime
          taskId
          taskTitle
          teamId
          teamName
          thirdPartyCalendarEventId
          thirdPartyCalendarInfoList {
            member {
              adminRole
              clientManagementRole
              department
              employeeNumber
              id
              laborCostManagementRole
              mailAddress
              memberManagementRole
              memberRegistrationDateTime
              memberStatus
              name
              organizationMemberViewRole
              organizationReportRole
              profileImageUrl
              projectCreatePermissionFlg
              projectCustomAttributePermissionFlg
              projectDeletePermissionFlg
              projectUpdatePermissionFlg
              proxyEditWorkingDataRole
              salesManagementRole
              statusMessage
              taskCreatePermissionFlg
              taskCustomAttributePermissionFlg
              taskDeletePermissionFlg
              taskUpdatePermissionFlg
              teamMemberViewRole
              teamReportRole
              versionNo
            }
            thirdPartyCalendarId
          }
          versionNo
          workingTimeSec
        }
        end
        id
        memo
        start
        task {
          calendarLinkageCode
          completeDateTime
          description
          estimateTimeSec
          estimatedRemainingTimeSec
          id
          laborCost
          priority
          progressDeviationRate
          progressRate
          registerDateTime
          remainingTimeSec
          scheduleWorkingTimeSec
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInMyTask
          sortNoInTaskStatus
          title
          versionNo
          workingTimeSec
        }
        versionNo
        workingMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        workingTimeSec
      }
    }
  }
`;
export type CreateWorkingScheduleWithTaskMutationFn = Apollo.MutationFunction<
  CreateWorkingScheduleWithTaskMutation,
  CreateWorkingScheduleWithTaskMutationVariables
>;

/**
 * __useCreateWorkingScheduleWithTaskMutation__
 *
 * To run a mutation, you first call `useCreateWorkingScheduleWithTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkingScheduleWithTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkingScheduleWithTaskMutation, { data, loading, error }] = useCreateWorkingScheduleWithTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useCreateWorkingScheduleWithTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateWorkingScheduleWithTaskMutation,
    CreateWorkingScheduleWithTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateWorkingScheduleWithTaskMutation,
    CreateWorkingScheduleWithTaskMutationVariables
  >(CreateWorkingScheduleWithTaskDocument, options);
}
export type CreateWorkingScheduleWithTaskMutationHookResult = ReturnType<
  typeof useCreateWorkingScheduleWithTaskMutation
>;
export type CreateWorkingScheduleWithTaskMutationResult =
  Apollo.MutationResult<CreateWorkingScheduleWithTaskMutation>;
export type CreateWorkingScheduleWithTaskMutationOptions = Apollo.BaseMutationOptions<
  CreateWorkingScheduleWithTaskMutation,
  CreateWorkingScheduleWithTaskMutationVariables
>;
export const DeactivateMemberTwoFactorAuthDocument = gql`
  mutation deactivateMemberTwoFactorAuth($memberId: String!) {
    deactivateMemberTwoFactorAuth(memberId: $memberId) {
      enabled
      recoveryCodes
    }
  }
`;
export type DeactivateMemberTwoFactorAuthMutationFn = Apollo.MutationFunction<
  DeactivateMemberTwoFactorAuthMutation,
  DeactivateMemberTwoFactorAuthMutationVariables
>;

/**
 * __useDeactivateMemberTwoFactorAuthMutation__
 *
 * To run a mutation, you first call `useDeactivateMemberTwoFactorAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateMemberTwoFactorAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateMemberTwoFactorAuthMutation, { data, loading, error }] = useDeactivateMemberTwoFactorAuthMutation({
 *   variables: {
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useDeactivateMemberTwoFactorAuthMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeactivateMemberTwoFactorAuthMutation,
    DeactivateMemberTwoFactorAuthMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeactivateMemberTwoFactorAuthMutation,
    DeactivateMemberTwoFactorAuthMutationVariables
  >(DeactivateMemberTwoFactorAuthDocument, options);
}
export type DeactivateMemberTwoFactorAuthMutationHookResult = ReturnType<
  typeof useDeactivateMemberTwoFactorAuthMutation
>;
export type DeactivateMemberTwoFactorAuthMutationResult =
  Apollo.MutationResult<DeactivateMemberTwoFactorAuthMutation>;
export type DeactivateMemberTwoFactorAuthMutationOptions = Apollo.BaseMutationOptions<
  DeactivateMemberTwoFactorAuthMutation,
  DeactivateMemberTwoFactorAuthMutationVariables
>;
export const DeactivateTwoFactorAuthDocument = gql`
  mutation deactivateTwoFactorAuth {
    deactivateTwoFactorAuth {
      enabled
      recoveryCodes
    }
  }
`;
export type DeactivateTwoFactorAuthMutationFn = Apollo.MutationFunction<
  DeactivateTwoFactorAuthMutation,
  DeactivateTwoFactorAuthMutationVariables
>;

/**
 * __useDeactivateTwoFactorAuthMutation__
 *
 * To run a mutation, you first call `useDeactivateTwoFactorAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateTwoFactorAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateTwoFactorAuthMutation, { data, loading, error }] = useDeactivateTwoFactorAuthMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeactivateTwoFactorAuthMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeactivateTwoFactorAuthMutation,
    DeactivateTwoFactorAuthMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeactivateTwoFactorAuthMutation,
    DeactivateTwoFactorAuthMutationVariables
  >(DeactivateTwoFactorAuthDocument, options);
}
export type DeactivateTwoFactorAuthMutationHookResult = ReturnType<
  typeof useDeactivateTwoFactorAuthMutation
>;
export type DeactivateTwoFactorAuthMutationResult =
  Apollo.MutationResult<DeactivateTwoFactorAuthMutation>;
export type DeactivateTwoFactorAuthMutationOptions = Apollo.BaseMutationOptions<
  DeactivateTwoFactorAuthMutation,
  DeactivateTwoFactorAuthMutationVariables
>;
export const DeleteAttachementFileDocument = gql`
  mutation deleteAttachementFile($id: ID!) {
    deleteAttachementFile(id: $id) {
      fileName
      id
      sizeByte
      type
      uploadDateTime
    }
  }
`;
export type DeleteAttachementFileMutationFn = Apollo.MutationFunction<
  DeleteAttachementFileMutation,
  DeleteAttachementFileMutationVariables
>;

/**
 * __useDeleteAttachementFileMutation__
 *
 * To run a mutation, you first call `useDeleteAttachementFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAttachementFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAttachementFileMutation, { data, loading, error }] = useDeleteAttachementFileMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAttachementFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAttachementFileMutation,
    DeleteAttachementFileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAttachementFileMutation, DeleteAttachementFileMutationVariables>(
    DeleteAttachementFileDocument,
    options
  );
}
export type DeleteAttachementFileMutationHookResult = ReturnType<
  typeof useDeleteAttachementFileMutation
>;
export type DeleteAttachementFileMutationResult =
  Apollo.MutationResult<DeleteAttachementFileMutation>;
export type DeleteAttachementFileMutationOptions = Apollo.BaseMutationOptions<
  DeleteAttachementFileMutation,
  DeleteAttachementFileMutationVariables
>;
export const DeleteCalendarEventDocument = gql`
  mutation deleteCalendarEvent($id: String!, $input: DeleteCalendarEventInputInput!) {
    deleteCalendarEvent(id: $id, input: $input) {
      calendarType
      clientId
      clientName
      endDateTime
      eventName
      id
      laborCost
      personalTeam
      projectColor
      projectId
      projectName
      startDateTime
      taskId
      taskTitle
      teamId
      teamName
      thirdPartyCalendarEventId
      thirdPartyCalendarInfoList {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        thirdPartyCalendarId
      }
      versionNo
      workingTimeSec
    }
  }
`;
export type DeleteCalendarEventMutationFn = Apollo.MutationFunction<
  DeleteCalendarEventMutation,
  DeleteCalendarEventMutationVariables
>;

/**
 * __useDeleteCalendarEventMutation__
 *
 * To run a mutation, you first call `useDeleteCalendarEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCalendarEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCalendarEventMutation, { data, loading, error }] = useDeleteCalendarEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCalendarEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCalendarEventMutation,
    DeleteCalendarEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCalendarEventMutation, DeleteCalendarEventMutationVariables>(
    DeleteCalendarEventDocument,
    options
  );
}
export type DeleteCalendarEventMutationHookResult = ReturnType<
  typeof useDeleteCalendarEventMutation
>;
export type DeleteCalendarEventMutationResult = Apollo.MutationResult<DeleteCalendarEventMutation>;
export type DeleteCalendarEventMutationOptions = Apollo.BaseMutationOptions<
  DeleteCalendarEventMutation,
  DeleteCalendarEventMutationVariables
>;
export const DeleteClientDocument = gql`
  mutation deleteClient($id: ID!, $input: DeleteClientInputInput!) {
    deleteClient(id: $id, input: $input) {
      code
      id
      name
      sortNo
      versionNo
    }
  }
`;
export type DeleteClientMutationFn = Apollo.MutationFunction<
  DeleteClientMutation,
  DeleteClientMutationVariables
>;

/**
 * __useDeleteClientMutation__
 *
 * To run a mutation, you first call `useDeleteClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientMutation, { data, loading, error }] = useDeleteClientMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteClientMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteClientMutation, DeleteClientMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteClientMutation, DeleteClientMutationVariables>(
    DeleteClientDocument,
    options
  );
}
export type DeleteClientMutationHookResult = ReturnType<typeof useDeleteClientMutation>;
export type DeleteClientMutationResult = Apollo.MutationResult<DeleteClientMutation>;
export type DeleteClientMutationOptions = Apollo.BaseMutationOptions<
  DeleteClientMutation,
  DeleteClientMutationVariables
>;
export const DeleteCsvReportItemDocument = gql`
  mutation deleteCsvReportItem($id: ID!, $input: DeleteCsvDefinitionDetailInputInput!) {
    deleteCsvReportItem(id: $id, input: $input) {
      id
      items {
        id
        reportItemCode
        reportItemName
        sortNo
      }
      name
      versionNo
    }
  }
`;
export type DeleteCsvReportItemMutationFn = Apollo.MutationFunction<
  DeleteCsvReportItemMutation,
  DeleteCsvReportItemMutationVariables
>;

/**
 * __useDeleteCsvReportItemMutation__
 *
 * To run a mutation, you first call `useDeleteCsvReportItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCsvReportItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCsvReportItemMutation, { data, loading, error }] = useDeleteCsvReportItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCsvReportItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCsvReportItemMutation,
    DeleteCsvReportItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCsvReportItemMutation, DeleteCsvReportItemMutationVariables>(
    DeleteCsvReportItemDocument,
    options
  );
}
export type DeleteCsvReportItemMutationHookResult = ReturnType<
  typeof useDeleteCsvReportItemMutation
>;
export type DeleteCsvReportItemMutationResult = Apollo.MutationResult<DeleteCsvReportItemMutation>;
export type DeleteCsvReportItemMutationOptions = Apollo.BaseMutationOptions<
  DeleteCsvReportItemMutation,
  DeleteCsvReportItemMutationVariables
>;
export const DeleteCsvReportItemDefinitionDocument = gql`
  mutation deleteCsvReportItemDefinition(
    $id: ID!
    $input: DeleteCsvReportItemDefinitionInputInput!
  ) {
    deleteCsvReportItemDefinition(id: $id, input: $input) {
      id
      items {
        id
        reportItemCode
        reportItemName
        sortNo
      }
      name
      versionNo
    }
  }
`;
export type DeleteCsvReportItemDefinitionMutationFn = Apollo.MutationFunction<
  DeleteCsvReportItemDefinitionMutation,
  DeleteCsvReportItemDefinitionMutationVariables
>;

/**
 * __useDeleteCsvReportItemDefinitionMutation__
 *
 * To run a mutation, you first call `useDeleteCsvReportItemDefinitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCsvReportItemDefinitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCsvReportItemDefinitionMutation, { data, loading, error }] = useDeleteCsvReportItemDefinitionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCsvReportItemDefinitionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCsvReportItemDefinitionMutation,
    DeleteCsvReportItemDefinitionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCsvReportItemDefinitionMutation,
    DeleteCsvReportItemDefinitionMutationVariables
  >(DeleteCsvReportItemDefinitionDocument, options);
}
export type DeleteCsvReportItemDefinitionMutationHookResult = ReturnType<
  typeof useDeleteCsvReportItemDefinitionMutation
>;
export type DeleteCsvReportItemDefinitionMutationResult =
  Apollo.MutationResult<DeleteCsvReportItemDefinitionMutation>;
export type DeleteCsvReportItemDefinitionMutationOptions = Apollo.BaseMutationOptions<
  DeleteCsvReportItemDefinitionMutation,
  DeleteCsvReportItemDefinitionMutationVariables
>;
export const DeleteGoogleCalendarEventDocument = gql`
  mutation deleteGoogleCalendarEvent($calendarId: String!, $id: ID!) {
    deleteGoogleCalendarEvent(calendarId: $calendarId, id: $id) {
      allDay
      attendees {
        mailAddress
        name
        responseStatus
        self
      }
      backgroundColor
      calendarId
      colorId
      dateWhenAllDay
      description
      endDateTime
      foregroundColor
      id
      location
      organizer {
        mailAddress
        name
      }
      readonly
      startDateTime
      status
      targetMemberId
      title
      visivility
    }
  }
`;
export type DeleteGoogleCalendarEventMutationFn = Apollo.MutationFunction<
  DeleteGoogleCalendarEventMutation,
  DeleteGoogleCalendarEventMutationVariables
>;

/**
 * __useDeleteGoogleCalendarEventMutation__
 *
 * To run a mutation, you first call `useDeleteGoogleCalendarEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGoogleCalendarEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGoogleCalendarEventMutation, { data, loading, error }] = useDeleteGoogleCalendarEventMutation({
 *   variables: {
 *      calendarId: // value for 'calendarId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteGoogleCalendarEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteGoogleCalendarEventMutation,
    DeleteGoogleCalendarEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteGoogleCalendarEventMutation,
    DeleteGoogleCalendarEventMutationVariables
  >(DeleteGoogleCalendarEventDocument, options);
}
export type DeleteGoogleCalendarEventMutationHookResult = ReturnType<
  typeof useDeleteGoogleCalendarEventMutation
>;
export type DeleteGoogleCalendarEventMutationResult =
  Apollo.MutationResult<DeleteGoogleCalendarEventMutation>;
export type DeleteGoogleCalendarEventMutationOptions = Apollo.BaseMutationOptions<
  DeleteGoogleCalendarEventMutation,
  DeleteGoogleCalendarEventMutationVariables
>;
export const DeleteInvitationDocument = gql`
  mutation deleteInvitation($key: String!) {
    deleteInvitation(key: $key)
  }
`;
export type DeleteInvitationMutationFn = Apollo.MutationFunction<
  DeleteInvitationMutation,
  DeleteInvitationMutationVariables
>;

/**
 * __useDeleteInvitationMutation__
 *
 * To run a mutation, you first call `useDeleteInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvitationMutation, { data, loading, error }] = useDeleteInvitationMutation({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useDeleteInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteInvitationMutation,
    DeleteInvitationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteInvitationMutation, DeleteInvitationMutationVariables>(
    DeleteInvitationDocument,
    options
  );
}
export type DeleteInvitationMutationHookResult = ReturnType<typeof useDeleteInvitationMutation>;
export type DeleteInvitationMutationResult = Apollo.MutationResult<DeleteInvitationMutation>;
export type DeleteInvitationMutationOptions = Apollo.BaseMutationOptions<
  DeleteInvitationMutation,
  DeleteInvitationMutationVariables
>;
export const DeleteInvitationLinkDocument = gql`
  mutation deleteInvitationLink($id: ID!) {
    deleteInvitationLink(id: $id)
  }
`;
export type DeleteInvitationLinkMutationFn = Apollo.MutationFunction<
  DeleteInvitationLinkMutation,
  DeleteInvitationLinkMutationVariables
>;

/**
 * __useDeleteInvitationLinkMutation__
 *
 * To run a mutation, you first call `useDeleteInvitationLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvitationLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvitationLinkMutation, { data, loading, error }] = useDeleteInvitationLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteInvitationLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteInvitationLinkMutation,
    DeleteInvitationLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteInvitationLinkMutation, DeleteInvitationLinkMutationVariables>(
    DeleteInvitationLinkDocument,
    options
  );
}
export type DeleteInvitationLinkMutationHookResult = ReturnType<
  typeof useDeleteInvitationLinkMutation
>;
export type DeleteInvitationLinkMutationResult =
  Apollo.MutationResult<DeleteInvitationLinkMutation>;
export type DeleteInvitationLinkMutationOptions = Apollo.BaseMutationOptions<
  DeleteInvitationLinkMutation,
  DeleteInvitationLinkMutationVariables
>;
export const DeleteLaborCostDocument = gql`
  mutation deleteLaborCost($id: ID!, $input: DeleteLaborCostInputInput!) {
    deleteLaborCost(id: $id, input: $input) {
      hourlyWage
      id
      startDateTime
      versionNo
    }
  }
`;
export type DeleteLaborCostMutationFn = Apollo.MutationFunction<
  DeleteLaborCostMutation,
  DeleteLaborCostMutationVariables
>;

/**
 * __useDeleteLaborCostMutation__
 *
 * To run a mutation, you first call `useDeleteLaborCostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLaborCostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLaborCostMutation, { data, loading, error }] = useDeleteLaborCostMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteLaborCostMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteLaborCostMutation,
    DeleteLaborCostMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteLaborCostMutation, DeleteLaborCostMutationVariables>(
    DeleteLaborCostDocument,
    options
  );
}
export type DeleteLaborCostMutationHookResult = ReturnType<typeof useDeleteLaborCostMutation>;
export type DeleteLaborCostMutationResult = Apollo.MutationResult<DeleteLaborCostMutation>;
export type DeleteLaborCostMutationOptions = Apollo.BaseMutationOptions<
  DeleteLaborCostMutation,
  DeleteLaborCostMutationVariables
>;
export const DeleteMeteredRateContractDocument = gql`
  mutation deleteMeteredRateContract($id: ID!, $input: DeleteMeterdRateContractInputInput!) {
    deleteMeteredRateContract(id: $id, input: $input) {
      hourUnitPrice
      id
      member {
        laborCostWithoutTax
        memberId
        name
        profileImageUrl
        roundedTotalWorkingTimeSec
        salesAmountWithoutTax
        totalWorkingTimeSec
      }
      versionNo
    }
  }
`;
export type DeleteMeteredRateContractMutationFn = Apollo.MutationFunction<
  DeleteMeteredRateContractMutation,
  DeleteMeteredRateContractMutationVariables
>;

/**
 * __useDeleteMeteredRateContractMutation__
 *
 * To run a mutation, you first call `useDeleteMeteredRateContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMeteredRateContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMeteredRateContractMutation, { data, loading, error }] = useDeleteMeteredRateContractMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteMeteredRateContractMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMeteredRateContractMutation,
    DeleteMeteredRateContractMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteMeteredRateContractMutation,
    DeleteMeteredRateContractMutationVariables
  >(DeleteMeteredRateContractDocument, options);
}
export type DeleteMeteredRateContractMutationHookResult = ReturnType<
  typeof useDeleteMeteredRateContractMutation
>;
export type DeleteMeteredRateContractMutationResult =
  Apollo.MutationResult<DeleteMeteredRateContractMutation>;
export type DeleteMeteredRateContractMutationOptions = Apollo.BaseMutationOptions<
  DeleteMeteredRateContractMutation,
  DeleteMeteredRateContractMutationVariables
>;
export const DeleteProjectDocument = gql`
  mutation deleteProject($id: ID!, $input: DeleteProjectInputInput) {
    deleteProject(id: $id, input: $input) {
      assignees {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      calendarLinkageCode
      client {
        code
        id
        name
        sortNo
        versionNo
      }
      color
      complete
      completeDateTime
      costBudgetWithoutTax
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      favoriteMembers {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      favoriteMembersInfo {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      id
      key
      laborCost
      laborCostWithoutTax
      laborShare
      name
      priority
      projectStatus {
        endStatus
        id
        name
        sortNo
        teamId
        versionNo
      }
      registerDateTime
      salesAmountWithoutTax
      scheduledEndDateTime
      scheduledStartDateTime
      sortNoInGanttChart
      sortNoInList
      sortNoInProjectStatus
      team {
        archived
        id
        name
        organization {
          allowConnectThirdPartyCalendar
          contactMailAddress
          contractPreriod
          id
          imageUrl
          ipRestrictionFlg
          name
          plan {
            code
            contractPreriod
            id
          }
          requiredTwoFactorAuth
          suspensionFlg
          versionNo
        }
        personalTeam
        status
        versionNo
      }
      totalScheduleTimeSec
      versionNo
      workingTimeSec
    }
  }
`;
export type DeleteProjectMutationFn = Apollo.MutationFunction<
  DeleteProjectMutation,
  DeleteProjectMutationVariables
>;

/**
 * __useDeleteProjectMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMutation, { data, loading, error }] = useDeleteProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteProjectMutation, DeleteProjectMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteProjectMutation, DeleteProjectMutationVariables>(
    DeleteProjectDocument,
    options
  );
}
export type DeleteProjectMutationHookResult = ReturnType<typeof useDeleteProjectMutation>;
export type DeleteProjectMutationResult = Apollo.MutationResult<DeleteProjectMutation>;
export type DeleteProjectMutationOptions = Apollo.BaseMutationOptions<
  DeleteProjectMutation,
  DeleteProjectMutationVariables
>;
export const DeleteProjectContractDocument = gql`
  mutation deleteProjectContract($id: ID!, $input: DeleteProjectContractInputInput!) {
    deleteProjectContract(id: $id, input: $input) {
      allMemberMeteredRateContract {
        hourUnitPrice
        id
        member {
          laborCostWithoutTax
          memberId
          name
          profileImageUrl
          roundedTotalWorkingTimeSec
          salesAmountWithoutTax
          totalWorkingTimeSec
        }
        versionNo
      }
      allMemberSettlementRangeContract {
        id
        lowerHourPart {
          deducationHourlyUnitPrice
          lowerHour
        }
        member {
          laborCostWithoutTax
          memberId
          name
          profileImageUrl
          roundedTotalWorkingTimeSec
          salesAmountWithoutTax
          totalWorkingTimeSec
        }
        monthlyUnitPrice
        upperHourPart {
          excessHourlyUnitPrice
          upperHour
        }
        versionNo
      }
      contractType
      costBudgetWithoutTax
      endDateTime
      fixSalesAmountWithoutTax
      grossProfit
      grossProfitRatePercent
      id
      laborCostWithoutTax
      laborShare
      membersMeteredRateContract {
        hourUnitPrice
        id
        member {
          laborCostWithoutTax
          memberId
          name
          profileImageUrl
          roundedTotalWorkingTimeSec
          salesAmountWithoutTax
          totalWorkingTimeSec
        }
        versionNo
      }
      membersSettlementRangeContract {
        id
        lowerHourPart {
          deducationHourlyUnitPrice
          lowerHour
        }
        member {
          laborCostWithoutTax
          memberId
          name
          profileImageUrl
          roundedTotalWorkingTimeSec
          salesAmountWithoutTax
          totalWorkingTimeSec
        }
        monthlyUnitPrice
        upperHourPart {
          excessHourlyUnitPrice
          upperHour
        }
        versionNo
      }
      month
      project {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        calendarLinkageCode
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        color
        complete
        completeDateTime
        costBudgetWithoutTax
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        key
        laborCost
        laborCostWithoutTax
        laborShare
        name
        priority
        projectStatus {
          endStatus
          id
          name
          sortNo
          teamId
          versionNo
        }
        registerDateTime
        salesAmountWithoutTax
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInProjectStatus
        team {
          archived
          id
          name
          organization {
            allowConnectThirdPartyCalendar
            contactMailAddress
            contractPreriod
            id
            imageUrl
            ipRestrictionFlg
            name
            plan {
              code
              contractPreriod
              id
            }
            requiredTwoFactorAuth
            suspensionFlg
            versionNo
          }
          personalTeam
          status
          versionNo
        }
        totalScheduleTimeSec
        versionNo
        workingTimeSec
      }
      roundTimeType
      roundedTotalWorkingTimeSec
      salesAmountWithoutTax
      salesRoundMinutes
      startDateTime
      taxRate
      termType
      timeZone {
        code
        id
        name
        offsetTime
        sortNo
      }
      totalWorkingTimeSec
      unitPriceType
      versionNo
      year
    }
  }
`;
export type DeleteProjectContractMutationFn = Apollo.MutationFunction<
  DeleteProjectContractMutation,
  DeleteProjectContractMutationVariables
>;

/**
 * __useDeleteProjectContractMutation__
 *
 * To run a mutation, you first call `useDeleteProjectContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectContractMutation, { data, loading, error }] = useDeleteProjectContractMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteProjectContractMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProjectContractMutation,
    DeleteProjectContractMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteProjectContractMutation, DeleteProjectContractMutationVariables>(
    DeleteProjectContractDocument,
    options
  );
}
export type DeleteProjectContractMutationHookResult = ReturnType<
  typeof useDeleteProjectContractMutation
>;
export type DeleteProjectContractMutationResult =
  Apollo.MutationResult<DeleteProjectContractMutation>;
export type DeleteProjectContractMutationOptions = Apollo.BaseMutationOptions<
  DeleteProjectContractMutation,
  DeleteProjectContractMutationVariables
>;
export const DeleteProjectCustomAttributeDocument = gql`
  mutation deleteProjectCustomAttribute($id: ID!) {
    deleteProjectCustomAttribute(id: $id) {
      id
      listItem {
        id
        sortNo
        value
        versionNo
      }
      master {
        id
        listItem {
          id
          sortNo
          value
          versionNo
        }
        name
        required
        sortNo
        type
        versionNo
      }
      sortNo
      value
    }
  }
`;
export type DeleteProjectCustomAttributeMutationFn = Apollo.MutationFunction<
  DeleteProjectCustomAttributeMutation,
  DeleteProjectCustomAttributeMutationVariables
>;

/**
 * __useDeleteProjectCustomAttributeMutation__
 *
 * To run a mutation, you first call `useDeleteProjectCustomAttributeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectCustomAttributeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectCustomAttributeMutation, { data, loading, error }] = useDeleteProjectCustomAttributeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProjectCustomAttributeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProjectCustomAttributeMutation,
    DeleteProjectCustomAttributeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteProjectCustomAttributeMutation,
    DeleteProjectCustomAttributeMutationVariables
  >(DeleteProjectCustomAttributeDocument, options);
}
export type DeleteProjectCustomAttributeMutationHookResult = ReturnType<
  typeof useDeleteProjectCustomAttributeMutation
>;
export type DeleteProjectCustomAttributeMutationResult =
  Apollo.MutationResult<DeleteProjectCustomAttributeMutation>;
export type DeleteProjectCustomAttributeMutationOptions = Apollo.BaseMutationOptions<
  DeleteProjectCustomAttributeMutation,
  DeleteProjectCustomAttributeMutationVariables
>;
export const DeleteProjectCustomAttributeMasterDocument = gql`
  mutation deleteProjectCustomAttributeMaster(
    $id: ID!
    $input: DeleteProjectCustomAttributeMasterInputInput!
  ) {
    deleteProjectCustomAttributeMaster(id: $id, input: $input) {
      id
      listItem {
        id
        sortNo
        value
        versionNo
      }
      name
      required
      sortNo
      type
      versionNo
    }
  }
`;
export type DeleteProjectCustomAttributeMasterMutationFn = Apollo.MutationFunction<
  DeleteProjectCustomAttributeMasterMutation,
  DeleteProjectCustomAttributeMasterMutationVariables
>;

/**
 * __useDeleteProjectCustomAttributeMasterMutation__
 *
 * To run a mutation, you first call `useDeleteProjectCustomAttributeMasterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectCustomAttributeMasterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectCustomAttributeMasterMutation, { data, loading, error }] = useDeleteProjectCustomAttributeMasterMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteProjectCustomAttributeMasterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProjectCustomAttributeMasterMutation,
    DeleteProjectCustomAttributeMasterMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteProjectCustomAttributeMasterMutation,
    DeleteProjectCustomAttributeMasterMutationVariables
  >(DeleteProjectCustomAttributeMasterDocument, options);
}
export type DeleteProjectCustomAttributeMasterMutationHookResult = ReturnType<
  typeof useDeleteProjectCustomAttributeMasterMutation
>;
export type DeleteProjectCustomAttributeMasterMutationResult =
  Apollo.MutationResult<DeleteProjectCustomAttributeMasterMutation>;
export type DeleteProjectCustomAttributeMasterMutationOptions = Apollo.BaseMutationOptions<
  DeleteProjectCustomAttributeMasterMutation,
  DeleteProjectCustomAttributeMasterMutationVariables
>;
export const DeleteProjectCustomAttributeMasterListItemDocument = gql`
  mutation deleteProjectCustomAttributeMasterListItem(
    $id: ID!
    $input: DeleteProjectCustomAttributeMasterListItemInputInput!
  ) {
    deleteProjectCustomAttributeMasterListItem(id: $id, input: $input) {
      id
      sortNo
      value
      versionNo
    }
  }
`;
export type DeleteProjectCustomAttributeMasterListItemMutationFn = Apollo.MutationFunction<
  DeleteProjectCustomAttributeMasterListItemMutation,
  DeleteProjectCustomAttributeMasterListItemMutationVariables
>;

/**
 * __useDeleteProjectCustomAttributeMasterListItemMutation__
 *
 * To run a mutation, you first call `useDeleteProjectCustomAttributeMasterListItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectCustomAttributeMasterListItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectCustomAttributeMasterListItemMutation, { data, loading, error }] = useDeleteProjectCustomAttributeMasterListItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteProjectCustomAttributeMasterListItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProjectCustomAttributeMasterListItemMutation,
    DeleteProjectCustomAttributeMasterListItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteProjectCustomAttributeMasterListItemMutation,
    DeleteProjectCustomAttributeMasterListItemMutationVariables
  >(DeleteProjectCustomAttributeMasterListItemDocument, options);
}
export type DeleteProjectCustomAttributeMasterListItemMutationHookResult = ReturnType<
  typeof useDeleteProjectCustomAttributeMasterListItemMutation
>;
export type DeleteProjectCustomAttributeMasterListItemMutationResult =
  Apollo.MutationResult<DeleteProjectCustomAttributeMasterListItemMutation>;
export type DeleteProjectCustomAttributeMasterListItemMutationOptions = Apollo.BaseMutationOptions<
  DeleteProjectCustomAttributeMasterListItemMutation,
  DeleteProjectCustomAttributeMasterListItemMutationVariables
>;
export const DeleteProjectStatusDocument = gql`
  mutation deleteProjectStatus($id: ID!, $input: DeleteProjectStatusInputInput!) {
    deleteProjectStatus(id: $id, input: $input) {
      endStatus
      id
      name
      sortNo
      teamId
      versionNo
    }
  }
`;
export type DeleteProjectStatusMutationFn = Apollo.MutationFunction<
  DeleteProjectStatusMutation,
  DeleteProjectStatusMutationVariables
>;

/**
 * __useDeleteProjectStatusMutation__
 *
 * To run a mutation, you first call `useDeleteProjectStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectStatusMutation, { data, loading, error }] = useDeleteProjectStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteProjectStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProjectStatusMutation,
    DeleteProjectStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteProjectStatusMutation, DeleteProjectStatusMutationVariables>(
    DeleteProjectStatusDocument,
    options
  );
}
export type DeleteProjectStatusMutationHookResult = ReturnType<
  typeof useDeleteProjectStatusMutation
>;
export type DeleteProjectStatusMutationResult = Apollo.MutationResult<DeleteProjectStatusMutation>;
export type DeleteProjectStatusMutationOptions = Apollo.BaseMutationOptions<
  DeleteProjectStatusMutation,
  DeleteProjectStatusMutationVariables
>;
export const DeleteProjectTemplateDocument = gql`
  mutation deleteProjectTemplate($id: ID!, $input: DeleteProjectTemplateInputInput) {
    deleteProjectTemplate(id: $id, input: $input) {
      client {
        code
        id
        name
        sortNo
        versionNo
      }
      description
      estimateTimeSec
      id
      name
      priority
      sortNoInList
      versionNo
    }
  }
`;
export type DeleteProjectTemplateMutationFn = Apollo.MutationFunction<
  DeleteProjectTemplateMutation,
  DeleteProjectTemplateMutationVariables
>;

/**
 * __useDeleteProjectTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteProjectTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectTemplateMutation, { data, loading, error }] = useDeleteProjectTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteProjectTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProjectTemplateMutation,
    DeleteProjectTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteProjectTemplateMutation, DeleteProjectTemplateMutationVariables>(
    DeleteProjectTemplateDocument,
    options
  );
}
export type DeleteProjectTemplateMutationHookResult = ReturnType<
  typeof useDeleteProjectTemplateMutation
>;
export type DeleteProjectTemplateMutationResult =
  Apollo.MutationResult<DeleteProjectTemplateMutation>;
export type DeleteProjectTemplateMutationOptions = Apollo.BaseMutationOptions<
  DeleteProjectTemplateMutation,
  DeleteProjectTemplateMutationVariables
>;
export const DeleteProjectTemplateCustomAttributeDocument = gql`
  mutation deleteProjectTemplateCustomAttribute($id: ID!) {
    deleteProjectTemplateCustomAttribute(id: $id) {
      id
      listItem {
        id
        sortNo
        value
        versionNo
      }
      master {
        id
        listItem {
          id
          sortNo
          value
          versionNo
        }
        name
        required
        sortNo
        type
        versionNo
      }
      sortNo
      value
    }
  }
`;
export type DeleteProjectTemplateCustomAttributeMutationFn = Apollo.MutationFunction<
  DeleteProjectTemplateCustomAttributeMutation,
  DeleteProjectTemplateCustomAttributeMutationVariables
>;

/**
 * __useDeleteProjectTemplateCustomAttributeMutation__
 *
 * To run a mutation, you first call `useDeleteProjectTemplateCustomAttributeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectTemplateCustomAttributeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectTemplateCustomAttributeMutation, { data, loading, error }] = useDeleteProjectTemplateCustomAttributeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProjectTemplateCustomAttributeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProjectTemplateCustomAttributeMutation,
    DeleteProjectTemplateCustomAttributeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteProjectTemplateCustomAttributeMutation,
    DeleteProjectTemplateCustomAttributeMutationVariables
  >(DeleteProjectTemplateCustomAttributeDocument, options);
}
export type DeleteProjectTemplateCustomAttributeMutationHookResult = ReturnType<
  typeof useDeleteProjectTemplateCustomAttributeMutation
>;
export type DeleteProjectTemplateCustomAttributeMutationResult =
  Apollo.MutationResult<DeleteProjectTemplateCustomAttributeMutation>;
export type DeleteProjectTemplateCustomAttributeMutationOptions = Apollo.BaseMutationOptions<
  DeleteProjectTemplateCustomAttributeMutation,
  DeleteProjectTemplateCustomAttributeMutationVariables
>;
export const DeleteProjectTemplateSubTaskDocument = gql`
  mutation deleteProjectTemplateSubTask($id: ID!, $input: DeleteProjectTemplateSubTaskInputInput!) {
    deleteProjectTemplateSubTask(id: $id, input: $input) {
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      id
      projectTemplateTask {
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        id
        priority
        projectTemplate {
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          description
          estimateTimeSec
          id
          name
          priority
          sortNoInList
          versionNo
        }
        projectTemplateTaskStatus {
          endStatus
          id
          name
          projectTemplate {
            description
            estimateTimeSec
            id
            name
            priority
            sortNoInList
            versionNo
          }
          sortNo
          versionNo
        }
        sortNoInList
        sortNoInTaskStatus
        title
        versionNo
      }
      sortNo
      title
      versionNo
    }
  }
`;
export type DeleteProjectTemplateSubTaskMutationFn = Apollo.MutationFunction<
  DeleteProjectTemplateSubTaskMutation,
  DeleteProjectTemplateSubTaskMutationVariables
>;

/**
 * __useDeleteProjectTemplateSubTaskMutation__
 *
 * To run a mutation, you first call `useDeleteProjectTemplateSubTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectTemplateSubTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectTemplateSubTaskMutation, { data, loading, error }] = useDeleteProjectTemplateSubTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteProjectTemplateSubTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProjectTemplateSubTaskMutation,
    DeleteProjectTemplateSubTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteProjectTemplateSubTaskMutation,
    DeleteProjectTemplateSubTaskMutationVariables
  >(DeleteProjectTemplateSubTaskDocument, options);
}
export type DeleteProjectTemplateSubTaskMutationHookResult = ReturnType<
  typeof useDeleteProjectTemplateSubTaskMutation
>;
export type DeleteProjectTemplateSubTaskMutationResult =
  Apollo.MutationResult<DeleteProjectTemplateSubTaskMutation>;
export type DeleteProjectTemplateSubTaskMutationOptions = Apollo.BaseMutationOptions<
  DeleteProjectTemplateSubTaskMutation,
  DeleteProjectTemplateSubTaskMutationVariables
>;
export const DeleteProjectTemplateTaskDocument = gql`
  mutation deleteProjectTemplateTask($id: ID!, $input: DeleteProjectTemplateTaskInputInput!) {
    deleteProjectTemplateTask(id: $id, input: $input) {
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      id
      priority
      projectTemplate {
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        description
        estimateTimeSec
        id
        name
        priority
        sortNoInList
        versionNo
      }
      projectTemplateTaskStatus {
        endStatus
        id
        name
        projectTemplate {
          description
          estimateTimeSec
          id
          name
          priority
          sortNoInList
          versionNo
        }
        sortNo
        versionNo
      }
      sortNoInList
      sortNoInTaskStatus
      title
      versionNo
    }
  }
`;
export type DeleteProjectTemplateTaskMutationFn = Apollo.MutationFunction<
  DeleteProjectTemplateTaskMutation,
  DeleteProjectTemplateTaskMutationVariables
>;

/**
 * __useDeleteProjectTemplateTaskMutation__
 *
 * To run a mutation, you first call `useDeleteProjectTemplateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectTemplateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectTemplateTaskMutation, { data, loading, error }] = useDeleteProjectTemplateTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteProjectTemplateTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProjectTemplateTaskMutation,
    DeleteProjectTemplateTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteProjectTemplateTaskMutation,
    DeleteProjectTemplateTaskMutationVariables
  >(DeleteProjectTemplateTaskDocument, options);
}
export type DeleteProjectTemplateTaskMutationHookResult = ReturnType<
  typeof useDeleteProjectTemplateTaskMutation
>;
export type DeleteProjectTemplateTaskMutationResult =
  Apollo.MutationResult<DeleteProjectTemplateTaskMutation>;
export type DeleteProjectTemplateTaskMutationOptions = Apollo.BaseMutationOptions<
  DeleteProjectTemplateTaskMutation,
  DeleteProjectTemplateTaskMutationVariables
>;
export const DeleteProjectTemplateTaskCustomAttributeDocument = gql`
  mutation deleteProjectTemplateTaskCustomAttribute($id: ID!) {
    deleteProjectTemplateTaskCustomAttribute(id: $id) {
      id
      listItem {
        id
        sortNo
        value
        versionNo
      }
      master {
        id
        listItem {
          id
          sortNo
          value
          versionNo
        }
        name
        required
        sortNo
        type
        versionNo
      }
      sortNo
      value
    }
  }
`;
export type DeleteProjectTemplateTaskCustomAttributeMutationFn = Apollo.MutationFunction<
  DeleteProjectTemplateTaskCustomAttributeMutation,
  DeleteProjectTemplateTaskCustomAttributeMutationVariables
>;

/**
 * __useDeleteProjectTemplateTaskCustomAttributeMutation__
 *
 * To run a mutation, you first call `useDeleteProjectTemplateTaskCustomAttributeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectTemplateTaskCustomAttributeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectTemplateTaskCustomAttributeMutation, { data, loading, error }] = useDeleteProjectTemplateTaskCustomAttributeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProjectTemplateTaskCustomAttributeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProjectTemplateTaskCustomAttributeMutation,
    DeleteProjectTemplateTaskCustomAttributeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteProjectTemplateTaskCustomAttributeMutation,
    DeleteProjectTemplateTaskCustomAttributeMutationVariables
  >(DeleteProjectTemplateTaskCustomAttributeDocument, options);
}
export type DeleteProjectTemplateTaskCustomAttributeMutationHookResult = ReturnType<
  typeof useDeleteProjectTemplateTaskCustomAttributeMutation
>;
export type DeleteProjectTemplateTaskCustomAttributeMutationResult =
  Apollo.MutationResult<DeleteProjectTemplateTaskCustomAttributeMutation>;
export type DeleteProjectTemplateTaskCustomAttributeMutationOptions = Apollo.BaseMutationOptions<
  DeleteProjectTemplateTaskCustomAttributeMutation,
  DeleteProjectTemplateTaskCustomAttributeMutationVariables
>;
export const DeleteProjectTemplateTaskRemainderDocument = gql`
  mutation deleteProjectTemplateTaskRemainder($id: ID!) {
    deleteProjectTemplateTaskRemainder(id: $id) {
      beforeDeadlineMinutes
      id
      projectTemplateTask {
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        id
        priority
        projectTemplate {
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          description
          estimateTimeSec
          id
          name
          priority
          sortNoInList
          versionNo
        }
        projectTemplateTaskStatus {
          endStatus
          id
          name
          projectTemplate {
            description
            estimateTimeSec
            id
            name
            priority
            sortNoInList
            versionNo
          }
          sortNo
          versionNo
        }
        sortNoInList
        sortNoInTaskStatus
        title
        versionNo
      }
      targetMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      taskRemainderDestType
      taskRemainderType
    }
  }
`;
export type DeleteProjectTemplateTaskRemainderMutationFn = Apollo.MutationFunction<
  DeleteProjectTemplateTaskRemainderMutation,
  DeleteProjectTemplateTaskRemainderMutationVariables
>;

/**
 * __useDeleteProjectTemplateTaskRemainderMutation__
 *
 * To run a mutation, you first call `useDeleteProjectTemplateTaskRemainderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectTemplateTaskRemainderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectTemplateTaskRemainderMutation, { data, loading, error }] = useDeleteProjectTemplateTaskRemainderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProjectTemplateTaskRemainderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProjectTemplateTaskRemainderMutation,
    DeleteProjectTemplateTaskRemainderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteProjectTemplateTaskRemainderMutation,
    DeleteProjectTemplateTaskRemainderMutationVariables
  >(DeleteProjectTemplateTaskRemainderDocument, options);
}
export type DeleteProjectTemplateTaskRemainderMutationHookResult = ReturnType<
  typeof useDeleteProjectTemplateTaskRemainderMutation
>;
export type DeleteProjectTemplateTaskRemainderMutationResult =
  Apollo.MutationResult<DeleteProjectTemplateTaskRemainderMutation>;
export type DeleteProjectTemplateTaskRemainderMutationOptions = Apollo.BaseMutationOptions<
  DeleteProjectTemplateTaskRemainderMutation,
  DeleteProjectTemplateTaskRemainderMutationVariables
>;
export const DeleteProjectTemplateTaskStatusDocument = gql`
  mutation deleteProjectTemplateTaskStatus(
    $id: ID!
    $input: DeleteProjectTemplateTaskStatusInputInput!
  ) {
    deleteProjectTemplateTaskStatus(id: $id, input: $input) {
      endStatus
      id
      name
      projectTemplate {
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        description
        estimateTimeSec
        id
        name
        priority
        sortNoInList
        versionNo
      }
      sortNo
      versionNo
    }
  }
`;
export type DeleteProjectTemplateTaskStatusMutationFn = Apollo.MutationFunction<
  DeleteProjectTemplateTaskStatusMutation,
  DeleteProjectTemplateTaskStatusMutationVariables
>;

/**
 * __useDeleteProjectTemplateTaskStatusMutation__
 *
 * To run a mutation, you first call `useDeleteProjectTemplateTaskStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectTemplateTaskStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectTemplateTaskStatusMutation, { data, loading, error }] = useDeleteProjectTemplateTaskStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteProjectTemplateTaskStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProjectTemplateTaskStatusMutation,
    DeleteProjectTemplateTaskStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteProjectTemplateTaskStatusMutation,
    DeleteProjectTemplateTaskStatusMutationVariables
  >(DeleteProjectTemplateTaskStatusDocument, options);
}
export type DeleteProjectTemplateTaskStatusMutationHookResult = ReturnType<
  typeof useDeleteProjectTemplateTaskStatusMutation
>;
export type DeleteProjectTemplateTaskStatusMutationResult =
  Apollo.MutationResult<DeleteProjectTemplateTaskStatusMutation>;
export type DeleteProjectTemplateTaskStatusMutationOptions = Apollo.BaseMutationOptions<
  DeleteProjectTemplateTaskStatusMutation,
  DeleteProjectTemplateTaskStatusMutationVariables
>;
export const DeleteSettlementRangeContractDocument = gql`
  mutation deleteSettlementRangeContract(
    $id: ID!
    $input: DeleteSettlementRangeContractInputInput!
  ) {
    deleteSettlementRangeContract(id: $id, input: $input) {
      id
      lowerHourPart {
        deducationHourlyUnitPrice
        lowerHour
      }
      member {
        laborCostWithoutTax
        memberId
        name
        profileImageUrl
        roundedTotalWorkingTimeSec
        salesAmountWithoutTax
        totalWorkingTimeSec
      }
      monthlyUnitPrice
      upperHourPart {
        excessHourlyUnitPrice
        upperHour
      }
      versionNo
    }
  }
`;
export type DeleteSettlementRangeContractMutationFn = Apollo.MutationFunction<
  DeleteSettlementRangeContractMutation,
  DeleteSettlementRangeContractMutationVariables
>;

/**
 * __useDeleteSettlementRangeContractMutation__
 *
 * To run a mutation, you first call `useDeleteSettlementRangeContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSettlementRangeContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSettlementRangeContractMutation, { data, loading, error }] = useDeleteSettlementRangeContractMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSettlementRangeContractMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSettlementRangeContractMutation,
    DeleteSettlementRangeContractMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteSettlementRangeContractMutation,
    DeleteSettlementRangeContractMutationVariables
  >(DeleteSettlementRangeContractDocument, options);
}
export type DeleteSettlementRangeContractMutationHookResult = ReturnType<
  typeof useDeleteSettlementRangeContractMutation
>;
export type DeleteSettlementRangeContractMutationResult =
  Apollo.MutationResult<DeleteSettlementRangeContractMutation>;
export type DeleteSettlementRangeContractMutationOptions = Apollo.BaseMutationOptions<
  DeleteSettlementRangeContractMutation,
  DeleteSettlementRangeContractMutationVariables
>;
export const DeleteSubTaskDocument = gql`
  mutation deleteSubTask($id: ID!, $input: DeleteSubTaskInputInput!) {
    deleteSubTask(id: $id, input: $input) {
      assignees {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      asssignee {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      calendarLinkageCode
      completeDateTime
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      estimatedRemainingTimeSec
      favoriteMembers {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      favoriteMembersInfo {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      id
      laborCost
      priority
      progressDeviationRate
      progressRate
      project {
        assignees {
          sortNo
        }
        calendarLinkageCode
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        color
        complete
        completeDateTime
        costBudgetWithoutTax
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          sortNo
        }
        id
        key
        laborCost
        laborCostWithoutTax
        laborShare
        name
        priority
        projectStatus {
          endStatus
          id
          name
          sortNo
          teamId
          versionNo
        }
        registerDateTime
        salesAmountWithoutTax
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInProjectStatus
        team {
          archived
          id
          name
          organization {
            allowConnectThirdPartyCalendar
            contactMailAddress
            contractPreriod
            id
            imageUrl
            ipRestrictionFlg
            name
            plan {
              code
              contractPreriod
              id
            }
            requiredTwoFactorAuth
            suspensionFlg
            versionNo
          }
          personalTeam
          status
          versionNo
        }
        totalScheduleTimeSec
        versionNo
        workingTimeSec
      }
      registerDateTime
      remainingTimeSec
      scheduleWorkingTimeSec
      scheduledEndDateTime
      scheduledStartDateTime
      sortNoInGanttChart
      sortNoInList
      sortNoInMyTask
      sortNoInTaskStatus
      taskStatus {
        endStatus
        id
        name
        project {
          calendarLinkageCode
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          description
          estimateTimeSec
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        sortNo
        versionNo
      }
      title
      versionNo
      workingMembers {
        lastWorkingStartDateTime
        lastWorkingStopDateTime
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
      }
      workingTimeSec
    }
  }
`;
export type DeleteSubTaskMutationFn = Apollo.MutationFunction<
  DeleteSubTaskMutation,
  DeleteSubTaskMutationVariables
>;

/**
 * __useDeleteSubTaskMutation__
 *
 * To run a mutation, you first call `useDeleteSubTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubTaskMutation, { data, loading, error }] = useDeleteSubTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSubTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteSubTaskMutation, DeleteSubTaskMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteSubTaskMutation, DeleteSubTaskMutationVariables>(
    DeleteSubTaskDocument,
    options
  );
}
export type DeleteSubTaskMutationHookResult = ReturnType<typeof useDeleteSubTaskMutation>;
export type DeleteSubTaskMutationResult = Apollo.MutationResult<DeleteSubTaskMutation>;
export type DeleteSubTaskMutationOptions = Apollo.BaseMutationOptions<
  DeleteSubTaskMutation,
  DeleteSubTaskMutationVariables
>;
export const DeleteTagDocument = gql`
  mutation deleteTag($id: ID!, $input: DeleteTagInputInput!) {
    deleteTag(id: $id, input: $input) {
      id
      name
      note
      organization {
        allowConnectThirdPartyCalendar
        contactMailAddress
        contractPreriod
        id
        imageUrl
        ipRestrictionFlg
        name
        plan {
          code
          contractPreriod
          id
        }
        requiredTwoFactorAuth
        suspensionFlg
        versionNo
      }
      relatedTaskCount
      sortNo
      versionNo
    }
  }
`;
export type DeleteTagMutationFn = Apollo.MutationFunction<
  DeleteTagMutation,
  DeleteTagMutationVariables
>;

/**
 * __useDeleteTagMutation__
 *
 * To run a mutation, you first call `useDeleteTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTagMutation, { data, loading, error }] = useDeleteTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTagMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteTagMutation, DeleteTagMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTagMutation, DeleteTagMutationVariables>(
    DeleteTagDocument,
    options
  );
}
export type DeleteTagMutationHookResult = ReturnType<typeof useDeleteTagMutation>;
export type DeleteTagMutationResult = Apollo.MutationResult<DeleteTagMutation>;
export type DeleteTagMutationOptions = Apollo.BaseMutationOptions<
  DeleteTagMutation,
  DeleteTagMutationVariables
>;
export const DeleteTaskDocument = gql`
  mutation deleteTask($id: ID!, $input: DeleteTaskInputInput!) {
    deleteTask(id: $id, input: $input) {
      assignees {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      asssignee {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      calendarLinkageCode
      completeDateTime
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      estimatedRemainingTimeSec
      favoriteMembers {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      favoriteMembersInfo {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      id
      laborCost
      priority
      progressDeviationRate
      progressRate
      project {
        assignees {
          sortNo
        }
        calendarLinkageCode
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        color
        complete
        completeDateTime
        costBudgetWithoutTax
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          sortNo
        }
        id
        key
        laborCost
        laborCostWithoutTax
        laborShare
        name
        priority
        projectStatus {
          endStatus
          id
          name
          sortNo
          teamId
          versionNo
        }
        registerDateTime
        salesAmountWithoutTax
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInProjectStatus
        team {
          archived
          id
          name
          organization {
            allowConnectThirdPartyCalendar
            contactMailAddress
            contractPreriod
            id
            imageUrl
            ipRestrictionFlg
            name
            plan {
              code
              contractPreriod
              id
            }
            requiredTwoFactorAuth
            suspensionFlg
            versionNo
          }
          personalTeam
          status
          versionNo
        }
        totalScheduleTimeSec
        versionNo
        workingTimeSec
      }
      registerDateTime
      remainingTimeSec
      scheduleWorkingTimeSec
      scheduledEndDateTime
      scheduledStartDateTime
      sortNoInGanttChart
      sortNoInList
      sortNoInMyTask
      sortNoInTaskStatus
      taskStatus {
        endStatus
        id
        name
        project {
          calendarLinkageCode
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          description
          estimateTimeSec
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        sortNo
        versionNo
      }
      title
      versionNo
      workingMembers {
        lastWorkingStartDateTime
        lastWorkingStopDateTime
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
      }
      workingTimeSec
    }
  }
`;
export type DeleteTaskMutationFn = Apollo.MutationFunction<
  DeleteTaskMutation,
  DeleteTaskMutationVariables
>;

/**
 * __useDeleteTaskMutation__
 *
 * To run a mutation, you first call `useDeleteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskMutation, { data, loading, error }] = useDeleteTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteTaskMutation, DeleteTaskMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTaskMutation, DeleteTaskMutationVariables>(
    DeleteTaskDocument,
    options
  );
}
export type DeleteTaskMutationHookResult = ReturnType<typeof useDeleteTaskMutation>;
export type DeleteTaskMutationResult = Apollo.MutationResult<DeleteTaskMutation>;
export type DeleteTaskMutationOptions = Apollo.BaseMutationOptions<
  DeleteTaskMutation,
  DeleteTaskMutationVariables
>;
export const DeleteTaskCommentDocument = gql`
  mutation deleteTaskComment($id: ID!, $input: DeleteTaskCommentInputInput!) {
    deleteTaskComment(id: $id, input: $input) {
      comment
      commentedDateTime
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      id
      projectId
      projectName
      task {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        asssignee {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        calendarLinkageCode
        completeDateTime
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        estimatedRemainingTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        laborCost
        priority
        progressDeviationRate
        progressRate
        project {
          assignees {
            sortNo
          }
          calendarLinkageCode
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          estimateTimeSec
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            sortNo
          }
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          projectStatus {
            endStatus
            id
            name
            sortNo
            teamId
            versionNo
          }
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          team {
            archived
            id
            name
            organization {
              allowConnectThirdPartyCalendar
              contactMailAddress
              contractPreriod
              id
              imageUrl
              ipRestrictionFlg
              name
              plan {
                code
                contractPreriod
                id
              }
              requiredTwoFactorAuth
              suspensionFlg
              versionNo
            }
            personalTeam
            status
            versionNo
          }
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        registerDateTime
        remainingTimeSec
        scheduleWorkingTimeSec
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInMyTask
        sortNoInTaskStatus
        taskStatus {
          endStatus
          id
          name
          project {
            calendarLinkageCode
            color
            complete
            completeDateTime
            costBudgetWithoutTax
            description
            estimateTimeSec
            id
            key
            laborCost
            laborCostWithoutTax
            laborShare
            name
            priority
            registerDateTime
            salesAmountWithoutTax
            scheduledEndDateTime
            scheduledStartDateTime
            sortNoInGanttChart
            sortNoInList
            sortNoInProjectStatus
            totalScheduleTimeSec
            versionNo
            workingTimeSec
          }
          sortNo
          versionNo
        }
        title
        versionNo
        workingMembers {
          lastWorkingStartDateTime
          lastWorkingStopDateTime
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
        }
        workingTimeSec
      }
      taskId
      taskTitle
      teamId
      teamName
      versionNo
    }
  }
`;
export type DeleteTaskCommentMutationFn = Apollo.MutationFunction<
  DeleteTaskCommentMutation,
  DeleteTaskCommentMutationVariables
>;

/**
 * __useDeleteTaskCommentMutation__
 *
 * To run a mutation, you first call `useDeleteTaskCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskCommentMutation, { data, loading, error }] = useDeleteTaskCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTaskCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTaskCommentMutation,
    DeleteTaskCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTaskCommentMutation, DeleteTaskCommentMutationVariables>(
    DeleteTaskCommentDocument,
    options
  );
}
export type DeleteTaskCommentMutationHookResult = ReturnType<typeof useDeleteTaskCommentMutation>;
export type DeleteTaskCommentMutationResult = Apollo.MutationResult<DeleteTaskCommentMutation>;
export type DeleteTaskCommentMutationOptions = Apollo.BaseMutationOptions<
  DeleteTaskCommentMutation,
  DeleteTaskCommentMutationVariables
>;
export const DeleteTaskCustomAttributeDocument = gql`
  mutation deleteTaskCustomAttribute($id: ID!) {
    deleteTaskCustomAttribute(id: $id) {
      id
      listItem {
        id
        sortNo
        value
        versionNo
      }
      master {
        id
        listItem {
          id
          sortNo
          value
          versionNo
        }
        name
        required
        sortNo
        type
        versionNo
      }
      sortNo
      value
    }
  }
`;
export type DeleteTaskCustomAttributeMutationFn = Apollo.MutationFunction<
  DeleteTaskCustomAttributeMutation,
  DeleteTaskCustomAttributeMutationVariables
>;

/**
 * __useDeleteTaskCustomAttributeMutation__
 *
 * To run a mutation, you first call `useDeleteTaskCustomAttributeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskCustomAttributeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskCustomAttributeMutation, { data, loading, error }] = useDeleteTaskCustomAttributeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTaskCustomAttributeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTaskCustomAttributeMutation,
    DeleteTaskCustomAttributeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteTaskCustomAttributeMutation,
    DeleteTaskCustomAttributeMutationVariables
  >(DeleteTaskCustomAttributeDocument, options);
}
export type DeleteTaskCustomAttributeMutationHookResult = ReturnType<
  typeof useDeleteTaskCustomAttributeMutation
>;
export type DeleteTaskCustomAttributeMutationResult =
  Apollo.MutationResult<DeleteTaskCustomAttributeMutation>;
export type DeleteTaskCustomAttributeMutationOptions = Apollo.BaseMutationOptions<
  DeleteTaskCustomAttributeMutation,
  DeleteTaskCustomAttributeMutationVariables
>;
export const DeleteTaskCustomAttributeMasterDocument = gql`
  mutation deleteTaskCustomAttributeMaster(
    $id: ID!
    $input: DeleteTaskCustomAttributeMasterInputInput!
  ) {
    deleteTaskCustomAttributeMaster(id: $id, input: $input) {
      id
      listItem {
        id
        sortNo
        value
        versionNo
      }
      name
      required
      sortNo
      type
      versionNo
    }
  }
`;
export type DeleteTaskCustomAttributeMasterMutationFn = Apollo.MutationFunction<
  DeleteTaskCustomAttributeMasterMutation,
  DeleteTaskCustomAttributeMasterMutationVariables
>;

/**
 * __useDeleteTaskCustomAttributeMasterMutation__
 *
 * To run a mutation, you first call `useDeleteTaskCustomAttributeMasterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskCustomAttributeMasterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskCustomAttributeMasterMutation, { data, loading, error }] = useDeleteTaskCustomAttributeMasterMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTaskCustomAttributeMasterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTaskCustomAttributeMasterMutation,
    DeleteTaskCustomAttributeMasterMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteTaskCustomAttributeMasterMutation,
    DeleteTaskCustomAttributeMasterMutationVariables
  >(DeleteTaskCustomAttributeMasterDocument, options);
}
export type DeleteTaskCustomAttributeMasterMutationHookResult = ReturnType<
  typeof useDeleteTaskCustomAttributeMasterMutation
>;
export type DeleteTaskCustomAttributeMasterMutationResult =
  Apollo.MutationResult<DeleteTaskCustomAttributeMasterMutation>;
export type DeleteTaskCustomAttributeMasterMutationOptions = Apollo.BaseMutationOptions<
  DeleteTaskCustomAttributeMasterMutation,
  DeleteTaskCustomAttributeMasterMutationVariables
>;
export const DeleteTaskCustomAttributeMasterListItemDocument = gql`
  mutation deleteTaskCustomAttributeMasterListItem(
    $id: ID!
    $input: DeleteTaskCustomAttributeMasterListItemInputInput!
  ) {
    deleteTaskCustomAttributeMasterListItem(id: $id, input: $input) {
      id
      sortNo
      value
      versionNo
    }
  }
`;
export type DeleteTaskCustomAttributeMasterListItemMutationFn = Apollo.MutationFunction<
  DeleteTaskCustomAttributeMasterListItemMutation,
  DeleteTaskCustomAttributeMasterListItemMutationVariables
>;

/**
 * __useDeleteTaskCustomAttributeMasterListItemMutation__
 *
 * To run a mutation, you first call `useDeleteTaskCustomAttributeMasterListItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskCustomAttributeMasterListItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskCustomAttributeMasterListItemMutation, { data, loading, error }] = useDeleteTaskCustomAttributeMasterListItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTaskCustomAttributeMasterListItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTaskCustomAttributeMasterListItemMutation,
    DeleteTaskCustomAttributeMasterListItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteTaskCustomAttributeMasterListItemMutation,
    DeleteTaskCustomAttributeMasterListItemMutationVariables
  >(DeleteTaskCustomAttributeMasterListItemDocument, options);
}
export type DeleteTaskCustomAttributeMasterListItemMutationHookResult = ReturnType<
  typeof useDeleteTaskCustomAttributeMasterListItemMutation
>;
export type DeleteTaskCustomAttributeMasterListItemMutationResult =
  Apollo.MutationResult<DeleteTaskCustomAttributeMasterListItemMutation>;
export type DeleteTaskCustomAttributeMasterListItemMutationOptions = Apollo.BaseMutationOptions<
  DeleteTaskCustomAttributeMasterListItemMutation,
  DeleteTaskCustomAttributeMasterListItemMutationVariables
>;
export const DeleteTaskRemainderDocument = gql`
  mutation deleteTaskRemainder($id: ID!) {
    deleteTaskRemainder(id: $id) {
      beforeDeadlineMinutes
      id
      notificationSentFlg
      targetMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      task {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        asssignee {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        calendarLinkageCode
        completeDateTime
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        estimatedRemainingTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        laborCost
        priority
        progressDeviationRate
        progressRate
        project {
          assignees {
            sortNo
          }
          calendarLinkageCode
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          estimateTimeSec
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            sortNo
          }
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          projectStatus {
            endStatus
            id
            name
            sortNo
            teamId
            versionNo
          }
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          team {
            archived
            id
            name
            organization {
              allowConnectThirdPartyCalendar
              contactMailAddress
              contractPreriod
              id
              imageUrl
              ipRestrictionFlg
              name
              plan {
                code
                contractPreriod
                id
              }
              requiredTwoFactorAuth
              suspensionFlg
              versionNo
            }
            personalTeam
            status
            versionNo
          }
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        registerDateTime
        remainingTimeSec
        scheduleWorkingTimeSec
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInMyTask
        sortNoInTaskStatus
        taskStatus {
          endStatus
          id
          name
          project {
            calendarLinkageCode
            color
            complete
            completeDateTime
            costBudgetWithoutTax
            description
            estimateTimeSec
            id
            key
            laborCost
            laborCostWithoutTax
            laborShare
            name
            priority
            registerDateTime
            salesAmountWithoutTax
            scheduledEndDateTime
            scheduledStartDateTime
            sortNoInGanttChart
            sortNoInList
            sortNoInProjectStatus
            totalScheduleTimeSec
            versionNo
            workingTimeSec
          }
          sortNo
          versionNo
        }
        title
        versionNo
        workingMembers {
          lastWorkingStartDateTime
          lastWorkingStopDateTime
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
        }
        workingTimeSec
      }
      taskRemainderDestType
      taskRemainderType
    }
  }
`;
export type DeleteTaskRemainderMutationFn = Apollo.MutationFunction<
  DeleteTaskRemainderMutation,
  DeleteTaskRemainderMutationVariables
>;

/**
 * __useDeleteTaskRemainderMutation__
 *
 * To run a mutation, you first call `useDeleteTaskRemainderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskRemainderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskRemainderMutation, { data, loading, error }] = useDeleteTaskRemainderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTaskRemainderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTaskRemainderMutation,
    DeleteTaskRemainderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTaskRemainderMutation, DeleteTaskRemainderMutationVariables>(
    DeleteTaskRemainderDocument,
    options
  );
}
export type DeleteTaskRemainderMutationHookResult = ReturnType<
  typeof useDeleteTaskRemainderMutation
>;
export type DeleteTaskRemainderMutationResult = Apollo.MutationResult<DeleteTaskRemainderMutation>;
export type DeleteTaskRemainderMutationOptions = Apollo.BaseMutationOptions<
  DeleteTaskRemainderMutation,
  DeleteTaskRemainderMutationVariables
>;
export const DeleteTaskStatusDocument = gql`
  mutation deleteTaskStatus($id: ID!, $input: DeleteTaskStatusInputInput!) {
    deleteTaskStatus(id: $id, input: $input) {
      endStatus
      id
      name
      project {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        calendarLinkageCode
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        color
        complete
        completeDateTime
        costBudgetWithoutTax
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        key
        laborCost
        laborCostWithoutTax
        laborShare
        name
        priority
        projectStatus {
          endStatus
          id
          name
          sortNo
          teamId
          versionNo
        }
        registerDateTime
        salesAmountWithoutTax
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInProjectStatus
        team {
          archived
          id
          name
          organization {
            allowConnectThirdPartyCalendar
            contactMailAddress
            contractPreriod
            id
            imageUrl
            ipRestrictionFlg
            name
            plan {
              code
              contractPreriod
              id
            }
            requiredTwoFactorAuth
            suspensionFlg
            versionNo
          }
          personalTeam
          status
          versionNo
        }
        totalScheduleTimeSec
        versionNo
        workingTimeSec
      }
      sortNo
      versionNo
    }
  }
`;
export type DeleteTaskStatusMutationFn = Apollo.MutationFunction<
  DeleteTaskStatusMutation,
  DeleteTaskStatusMutationVariables
>;

/**
 * __useDeleteTaskStatusMutation__
 *
 * To run a mutation, you first call `useDeleteTaskStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskStatusMutation, { data, loading, error }] = useDeleteTaskStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTaskStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTaskStatusMutation,
    DeleteTaskStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTaskStatusMutation, DeleteTaskStatusMutationVariables>(
    DeleteTaskStatusDocument,
    options
  );
}
export type DeleteTaskStatusMutationHookResult = ReturnType<typeof useDeleteTaskStatusMutation>;
export type DeleteTaskStatusMutationResult = Apollo.MutationResult<DeleteTaskStatusMutation>;
export type DeleteTaskStatusMutationOptions = Apollo.BaseMutationOptions<
  DeleteTaskStatusMutation,
  DeleteTaskStatusMutationVariables
>;
export const DeleteWorkingHistoryDocument = gql`
  mutation deleteWorkingHistory($id: String!, $input: DeleteWorkingHistoryInputInput!) {
    deleteWorkingHistory(id: $id, input: $input) {
      calendarEvent {
        calendarType
        clientId
        clientName
        endDateTime
        eventName
        id
        laborCost
        personalTeam
        projectColor
        projectId
        projectName
        startDateTime
        taskId
        taskTitle
        teamId
        teamName
        thirdPartyCalendarEventId
        thirdPartyCalendarInfoList {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          thirdPartyCalendarId
        }
        versionNo
        workingTimeSec
      }
      closingProcessInfo {
        closed
        targetDateTime
      }
      end
      id
      memo
      start
      task {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        asssignee {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        calendarLinkageCode
        completeDateTime
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        estimatedRemainingTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        laborCost
        priority
        progressDeviationRate
        progressRate
        project {
          assignees {
            sortNo
          }
          calendarLinkageCode
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          estimateTimeSec
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            sortNo
          }
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          projectStatus {
            endStatus
            id
            name
            sortNo
            teamId
            versionNo
          }
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          team {
            archived
            id
            name
            organization {
              allowConnectThirdPartyCalendar
              contactMailAddress
              contractPreriod
              id
              imageUrl
              ipRestrictionFlg
              name
              plan {
                code
                contractPreriod
                id
              }
              requiredTwoFactorAuth
              suspensionFlg
              versionNo
            }
            personalTeam
            status
            versionNo
          }
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        registerDateTime
        remainingTimeSec
        scheduleWorkingTimeSec
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInMyTask
        sortNoInTaskStatus
        taskStatus {
          endStatus
          id
          name
          project {
            calendarLinkageCode
            color
            complete
            completeDateTime
            costBudgetWithoutTax
            description
            estimateTimeSec
            id
            key
            laborCost
            laborCostWithoutTax
            laborShare
            name
            priority
            registerDateTime
            salesAmountWithoutTax
            scheduledEndDateTime
            scheduledStartDateTime
            sortNoInGanttChart
            sortNoInList
            sortNoInProjectStatus
            totalScheduleTimeSec
            versionNo
            workingTimeSec
          }
          sortNo
          versionNo
        }
        title
        versionNo
        workingMembers {
          lastWorkingStartDateTime
          lastWorkingStopDateTime
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
        }
        workingTimeSec
      }
      versionNo
      workingMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      workingTimeSec
    }
  }
`;
export type DeleteWorkingHistoryMutationFn = Apollo.MutationFunction<
  DeleteWorkingHistoryMutation,
  DeleteWorkingHistoryMutationVariables
>;

/**
 * __useDeleteWorkingHistoryMutation__
 *
 * To run a mutation, you first call `useDeleteWorkingHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkingHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkingHistoryMutation, { data, loading, error }] = useDeleteWorkingHistoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteWorkingHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteWorkingHistoryMutation,
    DeleteWorkingHistoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteWorkingHistoryMutation, DeleteWorkingHistoryMutationVariables>(
    DeleteWorkingHistoryDocument,
    options
  );
}
export type DeleteWorkingHistoryMutationHookResult = ReturnType<
  typeof useDeleteWorkingHistoryMutation
>;
export type DeleteWorkingHistoryMutationResult =
  Apollo.MutationResult<DeleteWorkingHistoryMutation>;
export type DeleteWorkingHistoryMutationOptions = Apollo.BaseMutationOptions<
  DeleteWorkingHistoryMutation,
  DeleteWorkingHistoryMutationVariables
>;
export const DeleteWorkingScheduleDocument = gql`
  mutation deleteWorkingSchedule($id: String!, $input: DeleteWorkingHistoryInputInput!) {
    deleteWorkingSchedule(id: $id, input: $input) {
      calendarEvent {
        calendarType
        clientId
        clientName
        endDateTime
        eventName
        id
        laborCost
        personalTeam
        projectColor
        projectId
        projectName
        startDateTime
        taskId
        taskTitle
        teamId
        teamName
        thirdPartyCalendarEventId
        thirdPartyCalendarInfoList {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          thirdPartyCalendarId
        }
        versionNo
        workingTimeSec
      }
      end
      id
      memo
      start
      task {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        asssignee {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        calendarLinkageCode
        completeDateTime
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        estimatedRemainingTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        laborCost
        priority
        progressDeviationRate
        progressRate
        project {
          assignees {
            sortNo
          }
          calendarLinkageCode
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          estimateTimeSec
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            sortNo
          }
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          projectStatus {
            endStatus
            id
            name
            sortNo
            teamId
            versionNo
          }
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          team {
            archived
            id
            name
            organization {
              allowConnectThirdPartyCalendar
              contactMailAddress
              contractPreriod
              id
              imageUrl
              ipRestrictionFlg
              name
              plan {
                code
                contractPreriod
                id
              }
              requiredTwoFactorAuth
              suspensionFlg
              versionNo
            }
            personalTeam
            status
            versionNo
          }
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        registerDateTime
        remainingTimeSec
        scheduleWorkingTimeSec
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInMyTask
        sortNoInTaskStatus
        taskStatus {
          endStatus
          id
          name
          project {
            calendarLinkageCode
            color
            complete
            completeDateTime
            costBudgetWithoutTax
            description
            estimateTimeSec
            id
            key
            laborCost
            laborCostWithoutTax
            laborShare
            name
            priority
            registerDateTime
            salesAmountWithoutTax
            scheduledEndDateTime
            scheduledStartDateTime
            sortNoInGanttChart
            sortNoInList
            sortNoInProjectStatus
            totalScheduleTimeSec
            versionNo
            workingTimeSec
          }
          sortNo
          versionNo
        }
        title
        versionNo
        workingMembers {
          lastWorkingStartDateTime
          lastWorkingStopDateTime
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
        }
        workingTimeSec
      }
      versionNo
      workingMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      workingTimeSec
    }
  }
`;
export type DeleteWorkingScheduleMutationFn = Apollo.MutationFunction<
  DeleteWorkingScheduleMutation,
  DeleteWorkingScheduleMutationVariables
>;

/**
 * __useDeleteWorkingScheduleMutation__
 *
 * To run a mutation, you first call `useDeleteWorkingScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkingScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkingScheduleMutation, { data, loading, error }] = useDeleteWorkingScheduleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteWorkingScheduleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteWorkingScheduleMutation,
    DeleteWorkingScheduleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteWorkingScheduleMutation, DeleteWorkingScheduleMutationVariables>(
    DeleteWorkingScheduleDocument,
    options
  );
}
export type DeleteWorkingScheduleMutationHookResult = ReturnType<
  typeof useDeleteWorkingScheduleMutation
>;
export type DeleteWorkingScheduleMutationResult =
  Apollo.MutationResult<DeleteWorkingScheduleMutation>;
export type DeleteWorkingScheduleMutationOptions = Apollo.BaseMutationOptions<
  DeleteWorkingScheduleMutation,
  DeleteWorkingScheduleMutationVariables
>;
export const DisableAccessControlDocument = gql`
  mutation disableAccessControl($input: UpdateAccessControlInputInput!) {
    disableAccessControl(input: $input) {
      enable
      id
      ipAddresses
      versionNo
    }
  }
`;
export type DisableAccessControlMutationFn = Apollo.MutationFunction<
  DisableAccessControlMutation,
  DisableAccessControlMutationVariables
>;

/**
 * __useDisableAccessControlMutation__
 *
 * To run a mutation, you first call `useDisableAccessControlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableAccessControlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableAccessControlMutation, { data, loading, error }] = useDisableAccessControlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDisableAccessControlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DisableAccessControlMutation,
    DisableAccessControlMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DisableAccessControlMutation, DisableAccessControlMutationVariables>(
    DisableAccessControlDocument,
    options
  );
}
export type DisableAccessControlMutationHookResult = ReturnType<
  typeof useDisableAccessControlMutation
>;
export type DisableAccessControlMutationResult =
  Apollo.MutationResult<DisableAccessControlMutation>;
export type DisableAccessControlMutationOptions = Apollo.BaseMutationOptions<
  DisableAccessControlMutation,
  DisableAccessControlMutationVariables
>;
export const DisableAccessControlByTokenDocument = gql`
  mutation disableAccessControlByToken($token: String!) {
    disableAccessControlByToken(token: $token) {
      enable
      id
      ipAddresses
      versionNo
    }
  }
`;
export type DisableAccessControlByTokenMutationFn = Apollo.MutationFunction<
  DisableAccessControlByTokenMutation,
  DisableAccessControlByTokenMutationVariables
>;

/**
 * __useDisableAccessControlByTokenMutation__
 *
 * To run a mutation, you first call `useDisableAccessControlByTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableAccessControlByTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableAccessControlByTokenMutation, { data, loading, error }] = useDisableAccessControlByTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useDisableAccessControlByTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DisableAccessControlByTokenMutation,
    DisableAccessControlByTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DisableAccessControlByTokenMutation,
    DisableAccessControlByTokenMutationVariables
  >(DisableAccessControlByTokenDocument, options);
}
export type DisableAccessControlByTokenMutationHookResult = ReturnType<
  typeof useDisableAccessControlByTokenMutation
>;
export type DisableAccessControlByTokenMutationResult =
  Apollo.MutationResult<DisableAccessControlByTokenMutation>;
export type DisableAccessControlByTokenMutationOptions = Apollo.BaseMutationOptions<
  DisableAccessControlByTokenMutation,
  DisableAccessControlByTokenMutationVariables
>;
export const DisableThirdPartyCalendarConnectDocument = gql`
  mutation disableThirdPartyCalendarConnect($input: UpdateThirdPartyCalendarConnectInputInput!) {
    disableThirdPartyCalendarConnect(input: $input) {
      allowConnectThirdPartyCalendar
      contactMailAddress
      contractPreriod
      id
      imageUrl
      ipRestrictionFlg
      name
      plan {
        code
        contractPreriod
        id
      }
      requiredTwoFactorAuth
      suspensionFlg
      versionNo
    }
  }
`;
export type DisableThirdPartyCalendarConnectMutationFn = Apollo.MutationFunction<
  DisableThirdPartyCalendarConnectMutation,
  DisableThirdPartyCalendarConnectMutationVariables
>;

/**
 * __useDisableThirdPartyCalendarConnectMutation__
 *
 * To run a mutation, you first call `useDisableThirdPartyCalendarConnectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableThirdPartyCalendarConnectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableThirdPartyCalendarConnectMutation, { data, loading, error }] = useDisableThirdPartyCalendarConnectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDisableThirdPartyCalendarConnectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DisableThirdPartyCalendarConnectMutation,
    DisableThirdPartyCalendarConnectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DisableThirdPartyCalendarConnectMutation,
    DisableThirdPartyCalendarConnectMutationVariables
  >(DisableThirdPartyCalendarConnectDocument, options);
}
export type DisableThirdPartyCalendarConnectMutationHookResult = ReturnType<
  typeof useDisableThirdPartyCalendarConnectMutation
>;
export type DisableThirdPartyCalendarConnectMutationResult =
  Apollo.MutationResult<DisableThirdPartyCalendarConnectMutation>;
export type DisableThirdPartyCalendarConnectMutationOptions = Apollo.BaseMutationOptions<
  DisableThirdPartyCalendarConnectMutation,
  DisableThirdPartyCalendarConnectMutationVariables
>;
export const DisableTwoFactorAuthRequiedDocument = gql`
  mutation disableTwoFactorAuthRequied($id: ID!, $input: DisableTwoFactorAuthRequiredInputInput!) {
    disableTwoFactorAuthRequied(id: $id, input: $input) {
      allowConnectThirdPartyCalendar
      contactMailAddress
      contractPreriod
      id
      imageUrl
      ipRestrictionFlg
      name
      plan {
        code
        contractPreriod
        id
      }
      requiredTwoFactorAuth
      suspensionFlg
      versionNo
    }
  }
`;
export type DisableTwoFactorAuthRequiedMutationFn = Apollo.MutationFunction<
  DisableTwoFactorAuthRequiedMutation,
  DisableTwoFactorAuthRequiedMutationVariables
>;

/**
 * __useDisableTwoFactorAuthRequiedMutation__
 *
 * To run a mutation, you first call `useDisableTwoFactorAuthRequiedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableTwoFactorAuthRequiedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableTwoFactorAuthRequiedMutation, { data, loading, error }] = useDisableTwoFactorAuthRequiedMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDisableTwoFactorAuthRequiedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DisableTwoFactorAuthRequiedMutation,
    DisableTwoFactorAuthRequiedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DisableTwoFactorAuthRequiedMutation,
    DisableTwoFactorAuthRequiedMutationVariables
  >(DisableTwoFactorAuthRequiedDocument, options);
}
export type DisableTwoFactorAuthRequiedMutationHookResult = ReturnType<
  typeof useDisableTwoFactorAuthRequiedMutation
>;
export type DisableTwoFactorAuthRequiedMutationResult =
  Apollo.MutationResult<DisableTwoFactorAuthRequiedMutation>;
export type DisableTwoFactorAuthRequiedMutationOptions = Apollo.BaseMutationOptions<
  DisableTwoFactorAuthRequiedMutation,
  DisableTwoFactorAuthRequiedMutationVariables
>;
export const DisconnectAppleAccountDocument = gql`
  mutation disconnectAppleAccount {
    disconnectAppleAccount
  }
`;
export type DisconnectAppleAccountMutationFn = Apollo.MutationFunction<
  DisconnectAppleAccountMutation,
  DisconnectAppleAccountMutationVariables
>;

/**
 * __useDisconnectAppleAccountMutation__
 *
 * To run a mutation, you first call `useDisconnectAppleAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectAppleAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectAppleAccountMutation, { data, loading, error }] = useDisconnectAppleAccountMutation({
 *   variables: {
 *   },
 * });
 */
export function useDisconnectAppleAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DisconnectAppleAccountMutation,
    DisconnectAppleAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DisconnectAppleAccountMutation,
    DisconnectAppleAccountMutationVariables
  >(DisconnectAppleAccountDocument, options);
}
export type DisconnectAppleAccountMutationHookResult = ReturnType<
  typeof useDisconnectAppleAccountMutation
>;
export type DisconnectAppleAccountMutationResult =
  Apollo.MutationResult<DisconnectAppleAccountMutation>;
export type DisconnectAppleAccountMutationOptions = Apollo.BaseMutationOptions<
  DisconnectAppleAccountMutation,
  DisconnectAppleAccountMutationVariables
>;
export const DisconnectGoogleAccountDocument = gql`
  mutation disconnectGoogleAccount {
    disconnectGoogleAccount
  }
`;
export type DisconnectGoogleAccountMutationFn = Apollo.MutationFunction<
  DisconnectGoogleAccountMutation,
  DisconnectGoogleAccountMutationVariables
>;

/**
 * __useDisconnectGoogleAccountMutation__
 *
 * To run a mutation, you first call `useDisconnectGoogleAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectGoogleAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectGoogleAccountMutation, { data, loading, error }] = useDisconnectGoogleAccountMutation({
 *   variables: {
 *   },
 * });
 */
export function useDisconnectGoogleAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DisconnectGoogleAccountMutation,
    DisconnectGoogleAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DisconnectGoogleAccountMutation,
    DisconnectGoogleAccountMutationVariables
  >(DisconnectGoogleAccountDocument, options);
}
export type DisconnectGoogleAccountMutationHookResult = ReturnType<
  typeof useDisconnectGoogleAccountMutation
>;
export type DisconnectGoogleAccountMutationResult =
  Apollo.MutationResult<DisconnectGoogleAccountMutation>;
export type DisconnectGoogleAccountMutationOptions = Apollo.BaseMutationOptions<
  DisconnectGoogleAccountMutation,
  DisconnectGoogleAccountMutationVariables
>;
export const EnableAccessControlDocument = gql`
  mutation enableAccessControl($input: UpdateAccessControlInputInput!) {
    enableAccessControl(input: $input) {
      enable
      id
      ipAddresses
      versionNo
    }
  }
`;
export type EnableAccessControlMutationFn = Apollo.MutationFunction<
  EnableAccessControlMutation,
  EnableAccessControlMutationVariables
>;

/**
 * __useEnableAccessControlMutation__
 *
 * To run a mutation, you first call `useEnableAccessControlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableAccessControlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableAccessControlMutation, { data, loading, error }] = useEnableAccessControlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnableAccessControlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EnableAccessControlMutation,
    EnableAccessControlMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EnableAccessControlMutation, EnableAccessControlMutationVariables>(
    EnableAccessControlDocument,
    options
  );
}
export type EnableAccessControlMutationHookResult = ReturnType<
  typeof useEnableAccessControlMutation
>;
export type EnableAccessControlMutationResult = Apollo.MutationResult<EnableAccessControlMutation>;
export type EnableAccessControlMutationOptions = Apollo.BaseMutationOptions<
  EnableAccessControlMutation,
  EnableAccessControlMutationVariables
>;
export const EnableThirdPartyCalendarConnectDocument = gql`
  mutation enableThirdPartyCalendarConnect($input: UpdateThirdPartyCalendarConnectInputInput!) {
    enableThirdPartyCalendarConnect(input: $input) {
      allowConnectThirdPartyCalendar
      contactMailAddress
      contractPreriod
      id
      imageUrl
      ipRestrictionFlg
      name
      plan {
        code
        contractPreriod
        id
      }
      requiredTwoFactorAuth
      suspensionFlg
      versionNo
    }
  }
`;
export type EnableThirdPartyCalendarConnectMutationFn = Apollo.MutationFunction<
  EnableThirdPartyCalendarConnectMutation,
  EnableThirdPartyCalendarConnectMutationVariables
>;

/**
 * __useEnableThirdPartyCalendarConnectMutation__
 *
 * To run a mutation, you first call `useEnableThirdPartyCalendarConnectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableThirdPartyCalendarConnectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableThirdPartyCalendarConnectMutation, { data, loading, error }] = useEnableThirdPartyCalendarConnectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnableThirdPartyCalendarConnectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EnableThirdPartyCalendarConnectMutation,
    EnableThirdPartyCalendarConnectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EnableThirdPartyCalendarConnectMutation,
    EnableThirdPartyCalendarConnectMutationVariables
  >(EnableThirdPartyCalendarConnectDocument, options);
}
export type EnableThirdPartyCalendarConnectMutationHookResult = ReturnType<
  typeof useEnableThirdPartyCalendarConnectMutation
>;
export type EnableThirdPartyCalendarConnectMutationResult =
  Apollo.MutationResult<EnableThirdPartyCalendarConnectMutation>;
export type EnableThirdPartyCalendarConnectMutationOptions = Apollo.BaseMutationOptions<
  EnableThirdPartyCalendarConnectMutation,
  EnableThirdPartyCalendarConnectMutationVariables
>;
export const EnableTwoFactorAuthRequiedDocument = gql`
  mutation enableTwoFactorAuthRequied($id: ID!, $input: EnableTwoFactorAuthRequiredInputInput!) {
    enableTwoFactorAuthRequied(id: $id, input: $input) {
      allowConnectThirdPartyCalendar
      contactMailAddress
      contractPreriod
      id
      imageUrl
      ipRestrictionFlg
      name
      plan {
        code
        contractPreriod
        id
      }
      requiredTwoFactorAuth
      suspensionFlg
      versionNo
    }
  }
`;
export type EnableTwoFactorAuthRequiedMutationFn = Apollo.MutationFunction<
  EnableTwoFactorAuthRequiedMutation,
  EnableTwoFactorAuthRequiedMutationVariables
>;

/**
 * __useEnableTwoFactorAuthRequiedMutation__
 *
 * To run a mutation, you first call `useEnableTwoFactorAuthRequiedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableTwoFactorAuthRequiedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableTwoFactorAuthRequiedMutation, { data, loading, error }] = useEnableTwoFactorAuthRequiedMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnableTwoFactorAuthRequiedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EnableTwoFactorAuthRequiedMutation,
    EnableTwoFactorAuthRequiedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EnableTwoFactorAuthRequiedMutation,
    EnableTwoFactorAuthRequiedMutationVariables
  >(EnableTwoFactorAuthRequiedDocument, options);
}
export type EnableTwoFactorAuthRequiedMutationHookResult = ReturnType<
  typeof useEnableTwoFactorAuthRequiedMutation
>;
export type EnableTwoFactorAuthRequiedMutationResult =
  Apollo.MutationResult<EnableTwoFactorAuthRequiedMutation>;
export type EnableTwoFactorAuthRequiedMutationOptions = Apollo.BaseMutationOptions<
  EnableTwoFactorAuthRequiedMutation,
  EnableTwoFactorAuthRequiedMutationVariables
>;
export const ExportICalFileDocument = gql`
  mutation exportICalFile($input: ICalExportInputInput) {
    exportICalFile(input: $input) {
      end
      id
      start
      status
      type
    }
  }
`;
export type ExportICalFileMutationFn = Apollo.MutationFunction<
  ExportICalFileMutation,
  ExportICalFileMutationVariables
>;

/**
 * __useExportICalFileMutation__
 *
 * To run a mutation, you first call `useExportICalFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportICalFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportICalFileMutation, { data, loading, error }] = useExportICalFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportICalFileMutation(
  baseOptions?: Apollo.MutationHookOptions<ExportICalFileMutation, ExportICalFileMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ExportICalFileMutation, ExportICalFileMutationVariables>(
    ExportICalFileDocument,
    options
  );
}
export type ExportICalFileMutationHookResult = ReturnType<typeof useExportICalFileMutation>;
export type ExportICalFileMutationResult = Apollo.MutationResult<ExportICalFileMutation>;
export type ExportICalFileMutationOptions = Apollo.BaseMutationOptions<
  ExportICalFileMutation,
  ExportICalFileMutationVariables
>;
export const FavoriteProjectDocument = gql`
  mutation favoriteProject($id: ID!, $input: FavoriteProjectInputInput) {
    favoriteProject(id: $id, input: $input) {
      assignees {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      calendarLinkageCode
      client {
        code
        id
        name
        sortNo
        versionNo
      }
      color
      complete
      completeDateTime
      costBudgetWithoutTax
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      favoriteMembers {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      favoriteMembersInfo {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      id
      key
      laborCost
      laborCostWithoutTax
      laborShare
      name
      priority
      projectStatus {
        endStatus
        id
        name
        sortNo
        teamId
        versionNo
      }
      registerDateTime
      salesAmountWithoutTax
      scheduledEndDateTime
      scheduledStartDateTime
      sortNoInGanttChart
      sortNoInList
      sortNoInProjectStatus
      team {
        archived
        id
        name
        organization {
          allowConnectThirdPartyCalendar
          contactMailAddress
          contractPreriod
          id
          imageUrl
          ipRestrictionFlg
          name
          plan {
            code
            contractPreriod
            id
          }
          requiredTwoFactorAuth
          suspensionFlg
          versionNo
        }
        personalTeam
        status
        versionNo
      }
      totalScheduleTimeSec
      versionNo
      workingTimeSec
    }
  }
`;
export type FavoriteProjectMutationFn = Apollo.MutationFunction<
  FavoriteProjectMutation,
  FavoriteProjectMutationVariables
>;

/**
 * __useFavoriteProjectMutation__
 *
 * To run a mutation, you first call `useFavoriteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFavoriteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [favoriteProjectMutation, { data, loading, error }] = useFavoriteProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFavoriteProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FavoriteProjectMutation,
    FavoriteProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FavoriteProjectMutation, FavoriteProjectMutationVariables>(
    FavoriteProjectDocument,
    options
  );
}
export type FavoriteProjectMutationHookResult = ReturnType<typeof useFavoriteProjectMutation>;
export type FavoriteProjectMutationResult = Apollo.MutationResult<FavoriteProjectMutation>;
export type FavoriteProjectMutationOptions = Apollo.BaseMutationOptions<
  FavoriteProjectMutation,
  FavoriteProjectMutationVariables
>;
export const FavoriteTaskDocument = gql`
  mutation favoriteTask($id: ID!, $input: FavoriteTaskInputInput) {
    favoriteTask(id: $id, input: $input) {
      assignees {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      asssignee {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      calendarLinkageCode
      completeDateTime
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      estimatedRemainingTimeSec
      favoriteMembers {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      favoriteMembersInfo {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      id
      laborCost
      priority
      progressDeviationRate
      progressRate
      project {
        assignees {
          sortNo
        }
        calendarLinkageCode
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        color
        complete
        completeDateTime
        costBudgetWithoutTax
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          sortNo
        }
        id
        key
        laborCost
        laborCostWithoutTax
        laborShare
        name
        priority
        projectStatus {
          endStatus
          id
          name
          sortNo
          teamId
          versionNo
        }
        registerDateTime
        salesAmountWithoutTax
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInProjectStatus
        team {
          archived
          id
          name
          organization {
            allowConnectThirdPartyCalendar
            contactMailAddress
            contractPreriod
            id
            imageUrl
            ipRestrictionFlg
            name
            plan {
              code
              contractPreriod
              id
            }
            requiredTwoFactorAuth
            suspensionFlg
            versionNo
          }
          personalTeam
          status
          versionNo
        }
        totalScheduleTimeSec
        versionNo
        workingTimeSec
      }
      registerDateTime
      remainingTimeSec
      scheduleWorkingTimeSec
      scheduledEndDateTime
      scheduledStartDateTime
      sortNoInGanttChart
      sortNoInList
      sortNoInMyTask
      sortNoInTaskStatus
      taskStatus {
        endStatus
        id
        name
        project {
          calendarLinkageCode
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          description
          estimateTimeSec
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        sortNo
        versionNo
      }
      title
      versionNo
      workingMembers {
        lastWorkingStartDateTime
        lastWorkingStopDateTime
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
      }
      workingTimeSec
    }
  }
`;
export type FavoriteTaskMutationFn = Apollo.MutationFunction<
  FavoriteTaskMutation,
  FavoriteTaskMutationVariables
>;

/**
 * __useFavoriteTaskMutation__
 *
 * To run a mutation, you first call `useFavoriteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFavoriteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [favoriteTaskMutation, { data, loading, error }] = useFavoriteTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFavoriteTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<FavoriteTaskMutation, FavoriteTaskMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FavoriteTaskMutation, FavoriteTaskMutationVariables>(
    FavoriteTaskDocument,
    options
  );
}
export type FavoriteTaskMutationHookResult = ReturnType<typeof useFavoriteTaskMutation>;
export type FavoriteTaskMutationResult = Apollo.MutationResult<FavoriteTaskMutation>;
export type FavoriteTaskMutationOptions = Apollo.BaseMutationOptions<
  FavoriteTaskMutation,
  FavoriteTaskMutationVariables
>;
export const IncompleteProjectDocument = gql`
  mutation incompleteProject($id: ID!, $input: IncompleteProjectInputInput) {
    incompleteProject(id: $id, input: $input) {
      assignees {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      calendarLinkageCode
      client {
        code
        id
        name
        sortNo
        versionNo
      }
      color
      complete
      completeDateTime
      costBudgetWithoutTax
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      favoriteMembers {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      favoriteMembersInfo {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      id
      key
      laborCost
      laborCostWithoutTax
      laborShare
      name
      priority
      projectStatus {
        endStatus
        id
        name
        sortNo
        teamId
        versionNo
      }
      registerDateTime
      salesAmountWithoutTax
      scheduledEndDateTime
      scheduledStartDateTime
      sortNoInGanttChart
      sortNoInList
      sortNoInProjectStatus
      team {
        archived
        id
        name
        organization {
          allowConnectThirdPartyCalendar
          contactMailAddress
          contractPreriod
          id
          imageUrl
          ipRestrictionFlg
          name
          plan {
            code
            contractPreriod
            id
          }
          requiredTwoFactorAuth
          suspensionFlg
          versionNo
        }
        personalTeam
        status
        versionNo
      }
      totalScheduleTimeSec
      versionNo
      workingTimeSec
    }
  }
`;
export type IncompleteProjectMutationFn = Apollo.MutationFunction<
  IncompleteProjectMutation,
  IncompleteProjectMutationVariables
>;

/**
 * __useIncompleteProjectMutation__
 *
 * To run a mutation, you first call `useIncompleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIncompleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [incompleteProjectMutation, { data, loading, error }] = useIncompleteProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIncompleteProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IncompleteProjectMutation,
    IncompleteProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IncompleteProjectMutation, IncompleteProjectMutationVariables>(
    IncompleteProjectDocument,
    options
  );
}
export type IncompleteProjectMutationHookResult = ReturnType<typeof useIncompleteProjectMutation>;
export type IncompleteProjectMutationResult = Apollo.MutationResult<IncompleteProjectMutation>;
export type IncompleteProjectMutationOptions = Apollo.BaseMutationOptions<
  IncompleteProjectMutation,
  IncompleteProjectMutationVariables
>;
export const IncompleteSubTaskDocument = gql`
  mutation incompleteSubTask($id: ID!, $input: IncompleteSubTaskInputInput!) {
    incompleteSubTask(id: $id, input: $input) {
      complete
      completeDateTime
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      id
      sortNo
      task {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        asssignee {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        calendarLinkageCode
        completeDateTime
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        estimatedRemainingTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        laborCost
        priority
        progressDeviationRate
        progressRate
        project {
          assignees {
            sortNo
          }
          calendarLinkageCode
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          estimateTimeSec
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            sortNo
          }
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          projectStatus {
            endStatus
            id
            name
            sortNo
            teamId
            versionNo
          }
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          team {
            archived
            id
            name
            organization {
              allowConnectThirdPartyCalendar
              contactMailAddress
              contractPreriod
              id
              imageUrl
              ipRestrictionFlg
              name
              plan {
                code
                contractPreriod
                id
              }
              requiredTwoFactorAuth
              suspensionFlg
              versionNo
            }
            personalTeam
            status
            versionNo
          }
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        registerDateTime
        remainingTimeSec
        scheduleWorkingTimeSec
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInMyTask
        sortNoInTaskStatus
        taskStatus {
          endStatus
          id
          name
          project {
            calendarLinkageCode
            color
            complete
            completeDateTime
            costBudgetWithoutTax
            description
            estimateTimeSec
            id
            key
            laborCost
            laborCostWithoutTax
            laborShare
            name
            priority
            registerDateTime
            salesAmountWithoutTax
            scheduledEndDateTime
            scheduledStartDateTime
            sortNoInGanttChart
            sortNoInList
            sortNoInProjectStatus
            totalScheduleTimeSec
            versionNo
            workingTimeSec
          }
          sortNo
          versionNo
        }
        title
        versionNo
        workingMembers {
          lastWorkingStartDateTime
          lastWorkingStopDateTime
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
        }
        workingTimeSec
      }
      title
      versionNo
    }
  }
`;
export type IncompleteSubTaskMutationFn = Apollo.MutationFunction<
  IncompleteSubTaskMutation,
  IncompleteSubTaskMutationVariables
>;

/**
 * __useIncompleteSubTaskMutation__
 *
 * To run a mutation, you first call `useIncompleteSubTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIncompleteSubTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [incompleteSubTaskMutation, { data, loading, error }] = useIncompleteSubTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIncompleteSubTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IncompleteSubTaskMutation,
    IncompleteSubTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IncompleteSubTaskMutation, IncompleteSubTaskMutationVariables>(
    IncompleteSubTaskDocument,
    options
  );
}
export type IncompleteSubTaskMutationHookResult = ReturnType<typeof useIncompleteSubTaskMutation>;
export type IncompleteSubTaskMutationResult = Apollo.MutationResult<IncompleteSubTaskMutation>;
export type IncompleteSubTaskMutationOptions = Apollo.BaseMutationOptions<
  IncompleteSubTaskMutation,
  IncompleteSubTaskMutationVariables
>;
export const IncompleteTaskDocument = gql`
  mutation incompleteTask($id: ID!, $input: IncompleteTaskInputInput!) {
    incompleteTask(id: $id, input: $input) {
      assignees {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      asssignee {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      calendarLinkageCode
      completeDateTime
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      estimatedRemainingTimeSec
      favoriteMembers {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      favoriteMembersInfo {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      id
      laborCost
      priority
      progressDeviationRate
      progressRate
      project {
        assignees {
          sortNo
        }
        calendarLinkageCode
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        color
        complete
        completeDateTime
        costBudgetWithoutTax
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          sortNo
        }
        id
        key
        laborCost
        laborCostWithoutTax
        laborShare
        name
        priority
        projectStatus {
          endStatus
          id
          name
          sortNo
          teamId
          versionNo
        }
        registerDateTime
        salesAmountWithoutTax
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInProjectStatus
        team {
          archived
          id
          name
          organization {
            allowConnectThirdPartyCalendar
            contactMailAddress
            contractPreriod
            id
            imageUrl
            ipRestrictionFlg
            name
            plan {
              code
              contractPreriod
              id
            }
            requiredTwoFactorAuth
            suspensionFlg
            versionNo
          }
          personalTeam
          status
          versionNo
        }
        totalScheduleTimeSec
        versionNo
        workingTimeSec
      }
      registerDateTime
      remainingTimeSec
      scheduleWorkingTimeSec
      scheduledEndDateTime
      scheduledStartDateTime
      sortNoInGanttChart
      sortNoInList
      sortNoInMyTask
      sortNoInTaskStatus
      taskStatus {
        endStatus
        id
        name
        project {
          calendarLinkageCode
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          description
          estimateTimeSec
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        sortNo
        versionNo
      }
      title
      versionNo
      workingMembers {
        lastWorkingStartDateTime
        lastWorkingStopDateTime
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
      }
      workingTimeSec
    }
  }
`;
export type IncompleteTaskMutationFn = Apollo.MutationFunction<
  IncompleteTaskMutation,
  IncompleteTaskMutationVariables
>;

/**
 * __useIncompleteTaskMutation__
 *
 * To run a mutation, you first call `useIncompleteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIncompleteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [incompleteTaskMutation, { data, loading, error }] = useIncompleteTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIncompleteTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<IncompleteTaskMutation, IncompleteTaskMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IncompleteTaskMutation, IncompleteTaskMutationVariables>(
    IncompleteTaskDocument,
    options
  );
}
export type IncompleteTaskMutationHookResult = ReturnType<typeof useIncompleteTaskMutation>;
export type IncompleteTaskMutationResult = Apollo.MutationResult<IncompleteTaskMutation>;
export type IncompleteTaskMutationOptions = Apollo.BaseMutationOptions<
  IncompleteTaskMutation,
  IncompleteTaskMutationVariables
>;
export const InviteMemberDocument = gql`
  mutation inviteMember($input: InviteMemberWithMailAddressInputInput!) {
    inviteMember(input: $input) {
      adminRole
      allowThirdPartyCalendarConnect
      clientManagementRole
      createMemberId
      department
      employeeNumber
      id
      invitationKey
      laborCostManagementRole
      mailAddress
      memberManagementRole
      name
      organizationId
      organizationMemberViewRole
      organizationName
      organizationReportRole
      projectCreatePermissionFlg
      projectCustomAttributePermissionFlg
      projectDeletePermissionFlg
      projectUpdatePermissionFlg
      proxyEditWorkingDataRole
      salesManagementRole
      taskCreatePermissionFlg
      taskCustomAttributePermissionFlg
      taskDeletePermissionFlg
      taskUpdatePermissionFlg
      teamMemberViewRole
      teamReportRole
      versionNo
    }
  }
`;
export type InviteMemberMutationFn = Apollo.MutationFunction<
  InviteMemberMutation,
  InviteMemberMutationVariables
>;

/**
 * __useInviteMemberMutation__
 *
 * To run a mutation, you first call `useInviteMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteMemberMutation, { data, loading, error }] = useInviteMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<InviteMemberMutation, InviteMemberMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InviteMemberMutation, InviteMemberMutationVariables>(
    InviteMemberDocument,
    options
  );
}
export type InviteMemberMutationHookResult = ReturnType<typeof useInviteMemberMutation>;
export type InviteMemberMutationResult = Apollo.MutationResult<InviteMemberMutation>;
export type InviteMemberMutationOptions = Apollo.BaseMutationOptions<
  InviteMemberMutation,
  InviteMemberMutationVariables
>;
export const IssueAppUserIdDocument = gql`
  mutation issueAppUserId($id: ID!, $input: IssueAppUserIdInputInput) {
    issueAppUserId(id: $id, input: $input) {
      billToMailAddress
      billingType
      contractPreriod
      freeLicenceCoupon {
        freeEndLicenceCount
        freeStartLicenceCount
        id
      }
      freeTrialExpireDatetime
      id
      licenceCount
      plan {
        code
        contractPreriod
        id
      }
      revenuecatOriginalAppUserId
      usingLicenceCount
      versionNo
    }
  }
`;
export type IssueAppUserIdMutationFn = Apollo.MutationFunction<
  IssueAppUserIdMutation,
  IssueAppUserIdMutationVariables
>;

/**
 * __useIssueAppUserIdMutation__
 *
 * To run a mutation, you first call `useIssueAppUserIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIssueAppUserIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [issueAppUserIdMutation, { data, loading, error }] = useIssueAppUserIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIssueAppUserIdMutation(
  baseOptions?: Apollo.MutationHookOptions<IssueAppUserIdMutation, IssueAppUserIdMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IssueAppUserIdMutation, IssueAppUserIdMutationVariables>(
    IssueAppUserIdDocument,
    options
  );
}
export type IssueAppUserIdMutationHookResult = ReturnType<typeof useIssueAppUserIdMutation>;
export type IssueAppUserIdMutationResult = Apollo.MutationResult<IssueAppUserIdMutation>;
export type IssueAppUserIdMutationOptions = Apollo.BaseMutationOptions<
  IssueAppUserIdMutation,
  IssueAppUserIdMutationVariables
>;
export const JoinMemberToTeamDocument = gql`
  mutation joinMemberToTeam($id: ID!, $input: JoinMemberToTeamInputInput!) {
    joinMemberToTeam(id: $id, input: $input) {
      adminRole
      clientManagementRole
      department
      employeeNumber
      id
      laborCostManagementRole
      mailAddress
      memberManagementRole
      memberRegistrationDateTime
      memberStatus
      name
      organizationMemberViewRole
      organizationReportRole
      profileImageUrl
      projectCreatePermissionFlg
      projectCustomAttributePermissionFlg
      projectDeletePermissionFlg
      projectUpdatePermissionFlg
      proxyEditWorkingDataRole
      salesManagementRole
      statusMessage
      taskCreatePermissionFlg
      taskCustomAttributePermissionFlg
      taskDeletePermissionFlg
      taskUpdatePermissionFlg
      teamMemberViewRole
      teamReportRole
      versionNo
    }
  }
`;
export type JoinMemberToTeamMutationFn = Apollo.MutationFunction<
  JoinMemberToTeamMutation,
  JoinMemberToTeamMutationVariables
>;

/**
 * __useJoinMemberToTeamMutation__
 *
 * To run a mutation, you first call `useJoinMemberToTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinMemberToTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinMemberToTeamMutation, { data, loading, error }] = useJoinMemberToTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJoinMemberToTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JoinMemberToTeamMutation,
    JoinMemberToTeamMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<JoinMemberToTeamMutation, JoinMemberToTeamMutationVariables>(
    JoinMemberToTeamDocument,
    options
  );
}
export type JoinMemberToTeamMutationHookResult = ReturnType<typeof useJoinMemberToTeamMutation>;
export type JoinMemberToTeamMutationResult = Apollo.MutationResult<JoinMemberToTeamMutation>;
export type JoinMemberToTeamMutationOptions = Apollo.BaseMutationOptions<
  JoinMemberToTeamMutation,
  JoinMemberToTeamMutationVariables
>;
export const JoinToOrganizationWithGoogleAccountDocument = gql`
  mutation joinToOrganizationWithGoogleAccount(
    $input: JoinToOrganizationWithGoogleAccountInputInput!
  ) {
    joinToOrganizationWithGoogleAccount(input: $input)
  }
`;
export type JoinToOrganizationWithGoogleAccountMutationFn = Apollo.MutationFunction<
  JoinToOrganizationWithGoogleAccountMutation,
  JoinToOrganizationWithGoogleAccountMutationVariables
>;

/**
 * __useJoinToOrganizationWithGoogleAccountMutation__
 *
 * To run a mutation, you first call `useJoinToOrganizationWithGoogleAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinToOrganizationWithGoogleAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinToOrganizationWithGoogleAccountMutation, { data, loading, error }] = useJoinToOrganizationWithGoogleAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJoinToOrganizationWithGoogleAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JoinToOrganizationWithGoogleAccountMutation,
    JoinToOrganizationWithGoogleAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    JoinToOrganizationWithGoogleAccountMutation,
    JoinToOrganizationWithGoogleAccountMutationVariables
  >(JoinToOrganizationWithGoogleAccountDocument, options);
}
export type JoinToOrganizationWithGoogleAccountMutationHookResult = ReturnType<
  typeof useJoinToOrganizationWithGoogleAccountMutation
>;
export type JoinToOrganizationWithGoogleAccountMutationResult =
  Apollo.MutationResult<JoinToOrganizationWithGoogleAccountMutation>;
export type JoinToOrganizationWithGoogleAccountMutationOptions = Apollo.BaseMutationOptions<
  JoinToOrganizationWithGoogleAccountMutation,
  JoinToOrganizationWithGoogleAccountMutationVariables
>;
export const JoinToOrganizationWithGoogleAccountByInviteLinkDocument = gql`
  mutation joinToOrganizationWithGoogleAccountByInviteLink(
    $input: JoinToOrganizationWithGoogleAccountByInviteLinkInputInput!
  ) {
    joinToOrganizationWithGoogleAccountByInviteLink(input: $input)
  }
`;
export type JoinToOrganizationWithGoogleAccountByInviteLinkMutationFn = Apollo.MutationFunction<
  JoinToOrganizationWithGoogleAccountByInviteLinkMutation,
  JoinToOrganizationWithGoogleAccountByInviteLinkMutationVariables
>;

/**
 * __useJoinToOrganizationWithGoogleAccountByInviteLinkMutation__
 *
 * To run a mutation, you first call `useJoinToOrganizationWithGoogleAccountByInviteLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinToOrganizationWithGoogleAccountByInviteLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinToOrganizationWithGoogleAccountByInviteLinkMutation, { data, loading, error }] = useJoinToOrganizationWithGoogleAccountByInviteLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJoinToOrganizationWithGoogleAccountByInviteLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JoinToOrganizationWithGoogleAccountByInviteLinkMutation,
    JoinToOrganizationWithGoogleAccountByInviteLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    JoinToOrganizationWithGoogleAccountByInviteLinkMutation,
    JoinToOrganizationWithGoogleAccountByInviteLinkMutationVariables
  >(JoinToOrganizationWithGoogleAccountByInviteLinkDocument, options);
}
export type JoinToOrganizationWithGoogleAccountByInviteLinkMutationHookResult = ReturnType<
  typeof useJoinToOrganizationWithGoogleAccountByInviteLinkMutation
>;
export type JoinToOrganizationWithGoogleAccountByInviteLinkMutationResult =
  Apollo.MutationResult<JoinToOrganizationWithGoogleAccountByInviteLinkMutation>;
export type JoinToOrganizationWithGoogleAccountByInviteLinkMutationOptions =
  Apollo.BaseMutationOptions<
    JoinToOrganizationWithGoogleAccountByInviteLinkMutation,
    JoinToOrganizationWithGoogleAccountByInviteLinkMutationVariables
  >;
export const JoinToOrganizationWithMailAddressDocument = gql`
  mutation joinToOrganizationWithMailAddress($input: JoinOrganizationMailAddressInputInput!) {
    joinToOrganizationWithMailAddress(input: $input)
  }
`;
export type JoinToOrganizationWithMailAddressMutationFn = Apollo.MutationFunction<
  JoinToOrganizationWithMailAddressMutation,
  JoinToOrganizationWithMailAddressMutationVariables
>;

/**
 * __useJoinToOrganizationWithMailAddressMutation__
 *
 * To run a mutation, you first call `useJoinToOrganizationWithMailAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinToOrganizationWithMailAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinToOrganizationWithMailAddressMutation, { data, loading, error }] = useJoinToOrganizationWithMailAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJoinToOrganizationWithMailAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JoinToOrganizationWithMailAddressMutation,
    JoinToOrganizationWithMailAddressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    JoinToOrganizationWithMailAddressMutation,
    JoinToOrganizationWithMailAddressMutationVariables
  >(JoinToOrganizationWithMailAddressDocument, options);
}
export type JoinToOrganizationWithMailAddressMutationHookResult = ReturnType<
  typeof useJoinToOrganizationWithMailAddressMutation
>;
export type JoinToOrganizationWithMailAddressMutationResult =
  Apollo.MutationResult<JoinToOrganizationWithMailAddressMutation>;
export type JoinToOrganizationWithMailAddressMutationOptions = Apollo.BaseMutationOptions<
  JoinToOrganizationWithMailAddressMutation,
  JoinToOrganizationWithMailAddressMutationVariables
>;
export const JoinToOrganizationWithMailAddressByInviteLinkDocument = gql`
  mutation joinToOrganizationWithMailAddressByInviteLink(
    $input: JoinOrganizationMailAddressByInviteLinkInputInput!
  ) {
    joinToOrganizationWithMailAddressByInviteLink(input: $input)
  }
`;
export type JoinToOrganizationWithMailAddressByInviteLinkMutationFn = Apollo.MutationFunction<
  JoinToOrganizationWithMailAddressByInviteLinkMutation,
  JoinToOrganizationWithMailAddressByInviteLinkMutationVariables
>;

/**
 * __useJoinToOrganizationWithMailAddressByInviteLinkMutation__
 *
 * To run a mutation, you first call `useJoinToOrganizationWithMailAddressByInviteLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinToOrganizationWithMailAddressByInviteLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinToOrganizationWithMailAddressByInviteLinkMutation, { data, loading, error }] = useJoinToOrganizationWithMailAddressByInviteLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJoinToOrganizationWithMailAddressByInviteLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JoinToOrganizationWithMailAddressByInviteLinkMutation,
    JoinToOrganizationWithMailAddressByInviteLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    JoinToOrganizationWithMailAddressByInviteLinkMutation,
    JoinToOrganizationWithMailAddressByInviteLinkMutationVariables
  >(JoinToOrganizationWithMailAddressByInviteLinkDocument, options);
}
export type JoinToOrganizationWithMailAddressByInviteLinkMutationHookResult = ReturnType<
  typeof useJoinToOrganizationWithMailAddressByInviteLinkMutation
>;
export type JoinToOrganizationWithMailAddressByInviteLinkMutationResult =
  Apollo.MutationResult<JoinToOrganizationWithMailAddressByInviteLinkMutation>;
export type JoinToOrganizationWithMailAddressByInviteLinkMutationOptions =
  Apollo.BaseMutationOptions<
    JoinToOrganizationWithMailAddressByInviteLinkMutation,
    JoinToOrganizationWithMailAddressByInviteLinkMutationVariables
  >;
export const JoinToTeamDocument = gql`
  mutation joinToTeam($input: JoinMemberToTeamInputInput!) {
    joinToTeam(input: $input) {
      id
      openMenu
      sortNo
      team {
        archived
        id
        name
        organization {
          allowConnectThirdPartyCalendar
          contactMailAddress
          contractPreriod
          id
          imageUrl
          ipRestrictionFlg
          name
          plan {
            code
            contractPreriod
            id
          }
          requiredTwoFactorAuth
          suspensionFlg
          versionNo
        }
        personalTeam
        status
        versionNo
      }
      versionNo
    }
  }
`;
export type JoinToTeamMutationFn = Apollo.MutationFunction<
  JoinToTeamMutation,
  JoinToTeamMutationVariables
>;

/**
 * __useJoinToTeamMutation__
 *
 * To run a mutation, you first call `useJoinToTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinToTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinToTeamMutation, { data, loading, error }] = useJoinToTeamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJoinToTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<JoinToTeamMutation, JoinToTeamMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<JoinToTeamMutation, JoinToTeamMutationVariables>(
    JoinToTeamDocument,
    options
  );
}
export type JoinToTeamMutationHookResult = ReturnType<typeof useJoinToTeamMutation>;
export type JoinToTeamMutationResult = Apollo.MutationResult<JoinToTeamMutation>;
export type JoinToTeamMutationOptions = Apollo.BaseMutationOptions<
  JoinToTeamMutation,
  JoinToTeamMutationVariables
>;
export const LoginDocument = gql`
  mutation login($input: LoginInputInput) {
    login(input: $input)
  }
`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const LoginV2Document = gql`
  mutation loginV2($input: LoginInputInput!) {
    loginV2(input: $input) {
      loginUserToken
      oneTimeToken
      requiredTwoFactorAuth
    }
  }
`;
export type LoginV2MutationFn = Apollo.MutationFunction<LoginV2Mutation, LoginV2MutationVariables>;

/**
 * __useLoginV2Mutation__
 *
 * To run a mutation, you first call `useLoginV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginV2Mutation, { data, loading, error }] = useLoginV2Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginV2Mutation(
  baseOptions?: Apollo.MutationHookOptions<LoginV2Mutation, LoginV2MutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginV2Mutation, LoginV2MutationVariables>(LoginV2Document, options);
}
export type LoginV2MutationHookResult = ReturnType<typeof useLoginV2Mutation>;
export type LoginV2MutationResult = Apollo.MutationResult<LoginV2Mutation>;
export type LoginV2MutationOptions = Apollo.BaseMutationOptions<
  LoginV2Mutation,
  LoginV2MutationVariables
>;
export const LoginWithAppleDocument = gql`
  mutation loginWithApple($input: LoginWithAppleAccountInputInput) {
    loginWithApple(input: $input)
  }
`;
export type LoginWithAppleMutationFn = Apollo.MutationFunction<
  LoginWithAppleMutation,
  LoginWithAppleMutationVariables
>;

/**
 * __useLoginWithAppleMutation__
 *
 * To run a mutation, you first call `useLoginWithAppleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginWithAppleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginWithAppleMutation, { data, loading, error }] = useLoginWithAppleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginWithAppleMutation(
  baseOptions?: Apollo.MutationHookOptions<LoginWithAppleMutation, LoginWithAppleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginWithAppleMutation, LoginWithAppleMutationVariables>(
    LoginWithAppleDocument,
    options
  );
}
export type LoginWithAppleMutationHookResult = ReturnType<typeof useLoginWithAppleMutation>;
export type LoginWithAppleMutationResult = Apollo.MutationResult<LoginWithAppleMutation>;
export type LoginWithAppleMutationOptions = Apollo.BaseMutationOptions<
  LoginWithAppleMutation,
  LoginWithAppleMutationVariables
>;
export const LoginWithAppleForWebDocument = gql`
  mutation loginWithAppleForWeb($input: LoginWithAppleAccountInputInput) {
    loginWithAppleForWeb(input: $input)
  }
`;
export type LoginWithAppleForWebMutationFn = Apollo.MutationFunction<
  LoginWithAppleForWebMutation,
  LoginWithAppleForWebMutationVariables
>;

/**
 * __useLoginWithAppleForWebMutation__
 *
 * To run a mutation, you first call `useLoginWithAppleForWebMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginWithAppleForWebMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginWithAppleForWebMutation, { data, loading, error }] = useLoginWithAppleForWebMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginWithAppleForWebMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginWithAppleForWebMutation,
    LoginWithAppleForWebMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginWithAppleForWebMutation, LoginWithAppleForWebMutationVariables>(
    LoginWithAppleForWebDocument,
    options
  );
}
export type LoginWithAppleForWebMutationHookResult = ReturnType<
  typeof useLoginWithAppleForWebMutation
>;
export type LoginWithAppleForWebMutationResult =
  Apollo.MutationResult<LoginWithAppleForWebMutation>;
export type LoginWithAppleForWebMutationOptions = Apollo.BaseMutationOptions<
  LoginWithAppleForWebMutation,
  LoginWithAppleForWebMutationVariables
>;
export const LoginWithAppleForWebV2Document = gql`
  mutation loginWithAppleForWebV2($input: LoginWithAppleAccountInputInput!) {
    loginWithAppleForWebV2(input: $input) {
      loginUserToken
      oneTimeToken
      requiredTwoFactorAuth
    }
  }
`;
export type LoginWithAppleForWebV2MutationFn = Apollo.MutationFunction<
  LoginWithAppleForWebV2Mutation,
  LoginWithAppleForWebV2MutationVariables
>;

/**
 * __useLoginWithAppleForWebV2Mutation__
 *
 * To run a mutation, you first call `useLoginWithAppleForWebV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginWithAppleForWebV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginWithAppleForWebV2Mutation, { data, loading, error }] = useLoginWithAppleForWebV2Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginWithAppleForWebV2Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginWithAppleForWebV2Mutation,
    LoginWithAppleForWebV2MutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LoginWithAppleForWebV2Mutation,
    LoginWithAppleForWebV2MutationVariables
  >(LoginWithAppleForWebV2Document, options);
}
export type LoginWithAppleForWebV2MutationHookResult = ReturnType<
  typeof useLoginWithAppleForWebV2Mutation
>;
export type LoginWithAppleForWebV2MutationResult =
  Apollo.MutationResult<LoginWithAppleForWebV2Mutation>;
export type LoginWithAppleForWebV2MutationOptions = Apollo.BaseMutationOptions<
  LoginWithAppleForWebV2Mutation,
  LoginWithAppleForWebV2MutationVariables
>;
export const LoginWithAppleV2Document = gql`
  mutation loginWithAppleV2($input: LoginWithAppleAccountInputInput!) {
    loginWithAppleV2(input: $input) {
      loginUserToken
      oneTimeToken
      requiredTwoFactorAuth
    }
  }
`;
export type LoginWithAppleV2MutationFn = Apollo.MutationFunction<
  LoginWithAppleV2Mutation,
  LoginWithAppleV2MutationVariables
>;

/**
 * __useLoginWithAppleV2Mutation__
 *
 * To run a mutation, you first call `useLoginWithAppleV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginWithAppleV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginWithAppleV2Mutation, { data, loading, error }] = useLoginWithAppleV2Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginWithAppleV2Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginWithAppleV2Mutation,
    LoginWithAppleV2MutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginWithAppleV2Mutation, LoginWithAppleV2MutationVariables>(
    LoginWithAppleV2Document,
    options
  );
}
export type LoginWithAppleV2MutationHookResult = ReturnType<typeof useLoginWithAppleV2Mutation>;
export type LoginWithAppleV2MutationResult = Apollo.MutationResult<LoginWithAppleV2Mutation>;
export type LoginWithAppleV2MutationOptions = Apollo.BaseMutationOptions<
  LoginWithAppleV2Mutation,
  LoginWithAppleV2MutationVariables
>;
export const LoginWithGoogleDocument = gql`
  mutation loginWithGoogle($code: String) {
    loginWithGoogle(code: $code)
  }
`;
export type LoginWithGoogleMutationFn = Apollo.MutationFunction<
  LoginWithGoogleMutation,
  LoginWithGoogleMutationVariables
>;

/**
 * __useLoginWithGoogleMutation__
 *
 * To run a mutation, you first call `useLoginWithGoogleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginWithGoogleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginWithGoogleMutation, { data, loading, error }] = useLoginWithGoogleMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useLoginWithGoogleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginWithGoogleMutation,
    LoginWithGoogleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginWithGoogleMutation, LoginWithGoogleMutationVariables>(
    LoginWithGoogleDocument,
    options
  );
}
export type LoginWithGoogleMutationHookResult = ReturnType<typeof useLoginWithGoogleMutation>;
export type LoginWithGoogleMutationResult = Apollo.MutationResult<LoginWithGoogleMutation>;
export type LoginWithGoogleMutationOptions = Apollo.BaseMutationOptions<
  LoginWithGoogleMutation,
  LoginWithGoogleMutationVariables
>;
export const LoginWithGoogleV2Document = gql`
  mutation loginWithGoogleV2($code: String!) {
    loginWithGoogleV2(code: $code) {
      loginUserToken
      oneTimeToken
      requiredTwoFactorAuth
    }
  }
`;
export type LoginWithGoogleV2MutationFn = Apollo.MutationFunction<
  LoginWithGoogleV2Mutation,
  LoginWithGoogleV2MutationVariables
>;

/**
 * __useLoginWithGoogleV2Mutation__
 *
 * To run a mutation, you first call `useLoginWithGoogleV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginWithGoogleV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginWithGoogleV2Mutation, { data, loading, error }] = useLoginWithGoogleV2Mutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useLoginWithGoogleV2Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginWithGoogleV2Mutation,
    LoginWithGoogleV2MutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginWithGoogleV2Mutation, LoginWithGoogleV2MutationVariables>(
    LoginWithGoogleV2Document,
    options
  );
}
export type LoginWithGoogleV2MutationHookResult = ReturnType<typeof useLoginWithGoogleV2Mutation>;
export type LoginWithGoogleV2MutationResult = Apollo.MutationResult<LoginWithGoogleV2Mutation>;
export type LoginWithGoogleV2MutationOptions = Apollo.BaseMutationOptions<
  LoginWithGoogleV2Mutation,
  LoginWithGoogleV2MutationVariables
>;
export const LogoutDocument = gql`
  mutation logout {
    logout
  }
`;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(
  baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<
  LogoutMutation,
  LogoutMutationVariables
>;
export const MoveCalendarEventDocument = gql`
  mutation moveCalendarEvent($id: String!, $input: MoveCalendarEventInputInput!) {
    moveCalendarEvent(id: $id, input: $input) {
      calendarType
      clientId
      clientName
      endDateTime
      eventName
      id
      laborCost
      personalTeam
      projectColor
      projectId
      projectName
      startDateTime
      taskId
      taskTitle
      teamId
      teamName
      thirdPartyCalendarEventId
      thirdPartyCalendarInfoList {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        thirdPartyCalendarId
      }
      versionNo
      workingTimeSec
    }
  }
`;
export type MoveCalendarEventMutationFn = Apollo.MutationFunction<
  MoveCalendarEventMutation,
  MoveCalendarEventMutationVariables
>;

/**
 * __useMoveCalendarEventMutation__
 *
 * To run a mutation, you first call `useMoveCalendarEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveCalendarEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveCalendarEventMutation, { data, loading, error }] = useMoveCalendarEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveCalendarEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MoveCalendarEventMutation,
    MoveCalendarEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MoveCalendarEventMutation, MoveCalendarEventMutationVariables>(
    MoveCalendarEventDocument,
    options
  );
}
export type MoveCalendarEventMutationHookResult = ReturnType<typeof useMoveCalendarEventMutation>;
export type MoveCalendarEventMutationResult = Apollo.MutationResult<MoveCalendarEventMutation>;
export type MoveCalendarEventMutationOptions = Apollo.BaseMutationOptions<
  MoveCalendarEventMutation,
  MoveCalendarEventMutationVariables
>;
export const MoveProjectDocument = gql`
  mutation moveProject($id: ID!, $input: MoveProjectInputInput) {
    moveProject(id: $id, input: $input) {
      assignees {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      calendarLinkageCode
      client {
        code
        id
        name
        sortNo
        versionNo
      }
      color
      complete
      completeDateTime
      costBudgetWithoutTax
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      favoriteMembers {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      favoriteMembersInfo {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      id
      key
      laborCost
      laborCostWithoutTax
      laborShare
      name
      priority
      projectStatus {
        endStatus
        id
        name
        sortNo
        teamId
        versionNo
      }
      registerDateTime
      salesAmountWithoutTax
      scheduledEndDateTime
      scheduledStartDateTime
      sortNoInGanttChart
      sortNoInList
      sortNoInProjectStatus
      team {
        archived
        id
        name
        organization {
          allowConnectThirdPartyCalendar
          contactMailAddress
          contractPreriod
          id
          imageUrl
          ipRestrictionFlg
          name
          plan {
            code
            contractPreriod
            id
          }
          requiredTwoFactorAuth
          suspensionFlg
          versionNo
        }
        personalTeam
        status
        versionNo
      }
      totalScheduleTimeSec
      versionNo
      workingTimeSec
    }
  }
`;
export type MoveProjectMutationFn = Apollo.MutationFunction<
  MoveProjectMutation,
  MoveProjectMutationVariables
>;

/**
 * __useMoveProjectMutation__
 *
 * To run a mutation, you first call `useMoveProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveProjectMutation, { data, loading, error }] = useMoveProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<MoveProjectMutation, MoveProjectMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MoveProjectMutation, MoveProjectMutationVariables>(
    MoveProjectDocument,
    options
  );
}
export type MoveProjectMutationHookResult = ReturnType<typeof useMoveProjectMutation>;
export type MoveProjectMutationResult = Apollo.MutationResult<MoveProjectMutation>;
export type MoveProjectMutationOptions = Apollo.BaseMutationOptions<
  MoveProjectMutation,
  MoveProjectMutationVariables
>;
export const MoveTaskDocument = gql`
  mutation moveTask($id: ID!, $input: MoveTaskInputInput!) {
    moveTask(id: $id, input: $input) {
      assignees {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      asssignee {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      calendarLinkageCode
      completeDateTime
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      estimatedRemainingTimeSec
      favoriteMembers {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      favoriteMembersInfo {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      id
      laborCost
      priority
      progressDeviationRate
      progressRate
      project {
        assignees {
          sortNo
        }
        calendarLinkageCode
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        color
        complete
        completeDateTime
        costBudgetWithoutTax
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          sortNo
        }
        id
        key
        laborCost
        laborCostWithoutTax
        laborShare
        name
        priority
        projectStatus {
          endStatus
          id
          name
          sortNo
          teamId
          versionNo
        }
        registerDateTime
        salesAmountWithoutTax
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInProjectStatus
        team {
          archived
          id
          name
          organization {
            allowConnectThirdPartyCalendar
            contactMailAddress
            contractPreriod
            id
            imageUrl
            ipRestrictionFlg
            name
            plan {
              code
              contractPreriod
              id
            }
            requiredTwoFactorAuth
            suspensionFlg
            versionNo
          }
          personalTeam
          status
          versionNo
        }
        totalScheduleTimeSec
        versionNo
        workingTimeSec
      }
      registerDateTime
      remainingTimeSec
      scheduleWorkingTimeSec
      scheduledEndDateTime
      scheduledStartDateTime
      sortNoInGanttChart
      sortNoInList
      sortNoInMyTask
      sortNoInTaskStatus
      taskStatus {
        endStatus
        id
        name
        project {
          calendarLinkageCode
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          description
          estimateTimeSec
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        sortNo
        versionNo
      }
      title
      versionNo
      workingMembers {
        lastWorkingStartDateTime
        lastWorkingStopDateTime
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
      }
      workingTimeSec
    }
  }
`;
export type MoveTaskMutationFn = Apollo.MutationFunction<
  MoveTaskMutation,
  MoveTaskMutationVariables
>;

/**
 * __useMoveTaskMutation__
 *
 * To run a mutation, you first call `useMoveTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveTaskMutation, { data, loading, error }] = useMoveTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<MoveTaskMutation, MoveTaskMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MoveTaskMutation, MoveTaskMutationVariables>(MoveTaskDocument, options);
}
export type MoveTaskMutationHookResult = ReturnType<typeof useMoveTaskMutation>;
export type MoveTaskMutationResult = Apollo.MutationResult<MoveTaskMutation>;
export type MoveTaskMutationOptions = Apollo.BaseMutationOptions<
  MoveTaskMutation,
  MoveTaskMutationVariables
>;
export const MoveWorkingHistoryDocument = gql`
  mutation moveWorkingHistory($id: String!, $input: MoveWorkingHistoryInputInput!) {
    moveWorkingHistory(id: $id, input: $input) {
      calendarEvent {
        calendarType
        clientId
        clientName
        endDateTime
        eventName
        id
        laborCost
        personalTeam
        projectColor
        projectId
        projectName
        startDateTime
        taskId
        taskTitle
        teamId
        teamName
        thirdPartyCalendarEventId
        thirdPartyCalendarInfoList {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          thirdPartyCalendarId
        }
        versionNo
        workingTimeSec
      }
      closingProcessInfo {
        closed
        targetDateTime
      }
      end
      id
      memo
      start
      task {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        asssignee {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        calendarLinkageCode
        completeDateTime
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        estimatedRemainingTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        laborCost
        priority
        progressDeviationRate
        progressRate
        project {
          assignees {
            sortNo
          }
          calendarLinkageCode
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          estimateTimeSec
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            sortNo
          }
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          projectStatus {
            endStatus
            id
            name
            sortNo
            teamId
            versionNo
          }
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          team {
            archived
            id
            name
            organization {
              allowConnectThirdPartyCalendar
              contactMailAddress
              contractPreriod
              id
              imageUrl
              ipRestrictionFlg
              name
              plan {
                code
                contractPreriod
                id
              }
              requiredTwoFactorAuth
              suspensionFlg
              versionNo
            }
            personalTeam
            status
            versionNo
          }
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        registerDateTime
        remainingTimeSec
        scheduleWorkingTimeSec
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInMyTask
        sortNoInTaskStatus
        taskStatus {
          endStatus
          id
          name
          project {
            calendarLinkageCode
            color
            complete
            completeDateTime
            costBudgetWithoutTax
            description
            estimateTimeSec
            id
            key
            laborCost
            laborCostWithoutTax
            laborShare
            name
            priority
            registerDateTime
            salesAmountWithoutTax
            scheduledEndDateTime
            scheduledStartDateTime
            sortNoInGanttChart
            sortNoInList
            sortNoInProjectStatus
            totalScheduleTimeSec
            versionNo
            workingTimeSec
          }
          sortNo
          versionNo
        }
        title
        versionNo
        workingMembers {
          lastWorkingStartDateTime
          lastWorkingStopDateTime
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
        }
        workingTimeSec
      }
      versionNo
      workingMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      workingTimeSec
    }
  }
`;
export type MoveWorkingHistoryMutationFn = Apollo.MutationFunction<
  MoveWorkingHistoryMutation,
  MoveWorkingHistoryMutationVariables
>;

/**
 * __useMoveWorkingHistoryMutation__
 *
 * To run a mutation, you first call `useMoveWorkingHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveWorkingHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveWorkingHistoryMutation, { data, loading, error }] = useMoveWorkingHistoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveWorkingHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MoveWorkingHistoryMutation,
    MoveWorkingHistoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MoveWorkingHistoryMutation, MoveWorkingHistoryMutationVariables>(
    MoveWorkingHistoryDocument,
    options
  );
}
export type MoveWorkingHistoryMutationHookResult = ReturnType<typeof useMoveWorkingHistoryMutation>;
export type MoveWorkingHistoryMutationResult = Apollo.MutationResult<MoveWorkingHistoryMutation>;
export type MoveWorkingHistoryMutationOptions = Apollo.BaseMutationOptions<
  MoveWorkingHistoryMutation,
  MoveWorkingHistoryMutationVariables
>;
export const MoveWorkingScheduleDocument = gql`
  mutation moveWorkingSchedule($id: String!, $input: MoveWorkingScheduleInputInput!) {
    moveWorkingSchedule(id: $id, input: $input) {
      calendarEvent {
        calendarType
        clientId
        clientName
        endDateTime
        eventName
        id
        laborCost
        personalTeam
        projectColor
        projectId
        projectName
        startDateTime
        taskId
        taskTitle
        teamId
        teamName
        thirdPartyCalendarEventId
        thirdPartyCalendarInfoList {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          thirdPartyCalendarId
        }
        versionNo
        workingTimeSec
      }
      end
      id
      memo
      start
      task {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        asssignee {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        calendarLinkageCode
        completeDateTime
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        estimatedRemainingTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        laborCost
        priority
        progressDeviationRate
        progressRate
        project {
          assignees {
            sortNo
          }
          calendarLinkageCode
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          estimateTimeSec
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            sortNo
          }
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          projectStatus {
            endStatus
            id
            name
            sortNo
            teamId
            versionNo
          }
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          team {
            archived
            id
            name
            organization {
              allowConnectThirdPartyCalendar
              contactMailAddress
              contractPreriod
              id
              imageUrl
              ipRestrictionFlg
              name
              plan {
                code
                contractPreriod
                id
              }
              requiredTwoFactorAuth
              suspensionFlg
              versionNo
            }
            personalTeam
            status
            versionNo
          }
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        registerDateTime
        remainingTimeSec
        scheduleWorkingTimeSec
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInMyTask
        sortNoInTaskStatus
        taskStatus {
          endStatus
          id
          name
          project {
            calendarLinkageCode
            color
            complete
            completeDateTime
            costBudgetWithoutTax
            description
            estimateTimeSec
            id
            key
            laborCost
            laborCostWithoutTax
            laborShare
            name
            priority
            registerDateTime
            salesAmountWithoutTax
            scheduledEndDateTime
            scheduledStartDateTime
            sortNoInGanttChart
            sortNoInList
            sortNoInProjectStatus
            totalScheduleTimeSec
            versionNo
            workingTimeSec
          }
          sortNo
          versionNo
        }
        title
        versionNo
        workingMembers {
          lastWorkingStartDateTime
          lastWorkingStopDateTime
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
        }
        workingTimeSec
      }
      versionNo
      workingMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      workingTimeSec
    }
  }
`;
export type MoveWorkingScheduleMutationFn = Apollo.MutationFunction<
  MoveWorkingScheduleMutation,
  MoveWorkingScheduleMutationVariables
>;

/**
 * __useMoveWorkingScheduleMutation__
 *
 * To run a mutation, you first call `useMoveWorkingScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveWorkingScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveWorkingScheduleMutation, { data, loading, error }] = useMoveWorkingScheduleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveWorkingScheduleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MoveWorkingScheduleMutation,
    MoveWorkingScheduleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MoveWorkingScheduleMutation, MoveWorkingScheduleMutationVariables>(
    MoveWorkingScheduleDocument,
    options
  );
}
export type MoveWorkingScheduleMutationHookResult = ReturnType<
  typeof useMoveWorkingScheduleMutation
>;
export type MoveWorkingScheduleMutationResult = Apollo.MutationResult<MoveWorkingScheduleMutation>;
export type MoveWorkingScheduleMutationOptions = Apollo.BaseMutationOptions<
  MoveWorkingScheduleMutation,
  MoveWorkingScheduleMutationVariables
>;
export const OpenAllTeamMenuDocument = gql`
  mutation openAllTeamMenu {
    openAllTeamMenu {
      id
      openMenu
      sortNo
      team {
        archived
        id
        name
        organization {
          allowConnectThirdPartyCalendar
          contactMailAddress
          contractPreriod
          id
          imageUrl
          ipRestrictionFlg
          name
          plan {
            code
            contractPreriod
            id
          }
          requiredTwoFactorAuth
          suspensionFlg
          versionNo
        }
        personalTeam
        status
        versionNo
      }
      versionNo
    }
  }
`;
export type OpenAllTeamMenuMutationFn = Apollo.MutationFunction<
  OpenAllTeamMenuMutation,
  OpenAllTeamMenuMutationVariables
>;

/**
 * __useOpenAllTeamMenuMutation__
 *
 * To run a mutation, you first call `useOpenAllTeamMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpenAllTeamMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [openAllTeamMenuMutation, { data, loading, error }] = useOpenAllTeamMenuMutation({
 *   variables: {
 *   },
 * });
 */
export function useOpenAllTeamMenuMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OpenAllTeamMenuMutation,
    OpenAllTeamMenuMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<OpenAllTeamMenuMutation, OpenAllTeamMenuMutationVariables>(
    OpenAllTeamMenuDocument,
    options
  );
}
export type OpenAllTeamMenuMutationHookResult = ReturnType<typeof useOpenAllTeamMenuMutation>;
export type OpenAllTeamMenuMutationResult = Apollo.MutationResult<OpenAllTeamMenuMutation>;
export type OpenAllTeamMenuMutationOptions = Apollo.BaseMutationOptions<
  OpenAllTeamMenuMutation,
  OpenAllTeamMenuMutationVariables
>;
export const ParseAppleAuthCodeDocument = gql`
  mutation parseAppleAuthCode($input: ParseAppleAccountInputInput!) {
    parseAppleAuthCode(input: $input) {
      appleUserId
      mailAddress
      name
      registerd
      token
    }
  }
`;
export type ParseAppleAuthCodeMutationFn = Apollo.MutationFunction<
  ParseAppleAuthCodeMutation,
  ParseAppleAuthCodeMutationVariables
>;

/**
 * __useParseAppleAuthCodeMutation__
 *
 * To run a mutation, you first call `useParseAppleAuthCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useParseAppleAuthCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [parseAppleAuthCodeMutation, { data, loading, error }] = useParseAppleAuthCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useParseAppleAuthCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ParseAppleAuthCodeMutation,
    ParseAppleAuthCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ParseAppleAuthCodeMutation, ParseAppleAuthCodeMutationVariables>(
    ParseAppleAuthCodeDocument,
    options
  );
}
export type ParseAppleAuthCodeMutationHookResult = ReturnType<typeof useParseAppleAuthCodeMutation>;
export type ParseAppleAuthCodeMutationResult = Apollo.MutationResult<ParseAppleAuthCodeMutation>;
export type ParseAppleAuthCodeMutationOptions = Apollo.BaseMutationOptions<
  ParseAppleAuthCodeMutation,
  ParseAppleAuthCodeMutationVariables
>;
export const ParseGoogleAuthCodeDocument = gql`
  mutation parseGoogleAuthCode($code: String!) {
    parseGoogleAuthCode(code: $code) {
      domain
      mailAddress
      name
      profileImageUrl
      registerd
      token
    }
  }
`;
export type ParseGoogleAuthCodeMutationFn = Apollo.MutationFunction<
  ParseGoogleAuthCodeMutation,
  ParseGoogleAuthCodeMutationVariables
>;

/**
 * __useParseGoogleAuthCodeMutation__
 *
 * To run a mutation, you first call `useParseGoogleAuthCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useParseGoogleAuthCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [parseGoogleAuthCodeMutation, { data, loading, error }] = useParseGoogleAuthCodeMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useParseGoogleAuthCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ParseGoogleAuthCodeMutation,
    ParseGoogleAuthCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ParseGoogleAuthCodeMutation, ParseGoogleAuthCodeMutationVariables>(
    ParseGoogleAuthCodeDocument,
    options
  );
}
export type ParseGoogleAuthCodeMutationHookResult = ReturnType<
  typeof useParseGoogleAuthCodeMutation
>;
export type ParseGoogleAuthCodeMutationResult = Apollo.MutationResult<ParseGoogleAuthCodeMutation>;
export type ParseGoogleAuthCodeMutationOptions = Apollo.BaseMutationOptions<
  ParseGoogleAuthCodeMutation,
  ParseGoogleAuthCodeMutationVariables
>;
export const ParseGoogleAuthCodeWithInviteKeyDocument = gql`
  mutation parseGoogleAuthCodeWithInviteKey($code: String!, $key: String!) {
    parseGoogleAuthCodeWithInviteKey(code: $code, key: $key) {
      domain
      mailAddress
      name
      profileImageUrl
      registerd
      token
    }
  }
`;
export type ParseGoogleAuthCodeWithInviteKeyMutationFn = Apollo.MutationFunction<
  ParseGoogleAuthCodeWithInviteKeyMutation,
  ParseGoogleAuthCodeWithInviteKeyMutationVariables
>;

/**
 * __useParseGoogleAuthCodeWithInviteKeyMutation__
 *
 * To run a mutation, you first call `useParseGoogleAuthCodeWithInviteKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useParseGoogleAuthCodeWithInviteKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [parseGoogleAuthCodeWithInviteKeyMutation, { data, loading, error }] = useParseGoogleAuthCodeWithInviteKeyMutation({
 *   variables: {
 *      code: // value for 'code'
 *      key: // value for 'key'
 *   },
 * });
 */
export function useParseGoogleAuthCodeWithInviteKeyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ParseGoogleAuthCodeWithInviteKeyMutation,
    ParseGoogleAuthCodeWithInviteKeyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ParseGoogleAuthCodeWithInviteKeyMutation,
    ParseGoogleAuthCodeWithInviteKeyMutationVariables
  >(ParseGoogleAuthCodeWithInviteKeyDocument, options);
}
export type ParseGoogleAuthCodeWithInviteKeyMutationHookResult = ReturnType<
  typeof useParseGoogleAuthCodeWithInviteKeyMutation
>;
export type ParseGoogleAuthCodeWithInviteKeyMutationResult =
  Apollo.MutationResult<ParseGoogleAuthCodeWithInviteKeyMutation>;
export type ParseGoogleAuthCodeWithInviteKeyMutationOptions = Apollo.BaseMutationOptions<
  ParseGoogleAuthCodeWithInviteKeyMutation,
  ParseGoogleAuthCodeWithInviteKeyMutationVariables
>;
export const ParseGoogleAuthCodeWithInviteLinkDocument = gql`
  mutation parseGoogleAuthCodeWithInviteLink($code: String!) {
    parseGoogleAuthCodeWithInviteLink(code: $code) {
      domain
      mailAddress
      name
      profileImageUrl
      registerd
      token
    }
  }
`;
export type ParseGoogleAuthCodeWithInviteLinkMutationFn = Apollo.MutationFunction<
  ParseGoogleAuthCodeWithInviteLinkMutation,
  ParseGoogleAuthCodeWithInviteLinkMutationVariables
>;

/**
 * __useParseGoogleAuthCodeWithInviteLinkMutation__
 *
 * To run a mutation, you first call `useParseGoogleAuthCodeWithInviteLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useParseGoogleAuthCodeWithInviteLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [parseGoogleAuthCodeWithInviteLinkMutation, { data, loading, error }] = useParseGoogleAuthCodeWithInviteLinkMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useParseGoogleAuthCodeWithInviteLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ParseGoogleAuthCodeWithInviteLinkMutation,
    ParseGoogleAuthCodeWithInviteLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ParseGoogleAuthCodeWithInviteLinkMutation,
    ParseGoogleAuthCodeWithInviteLinkMutationVariables
  >(ParseGoogleAuthCodeWithInviteLinkDocument, options);
}
export type ParseGoogleAuthCodeWithInviteLinkMutationHookResult = ReturnType<
  typeof useParseGoogleAuthCodeWithInviteLinkMutation
>;
export type ParseGoogleAuthCodeWithInviteLinkMutationResult =
  Apollo.MutationResult<ParseGoogleAuthCodeWithInviteLinkMutation>;
export type ParseGoogleAuthCodeWithInviteLinkMutationOptions = Apollo.BaseMutationOptions<
  ParseGoogleAuthCodeWithInviteLinkMutation,
  ParseGoogleAuthCodeWithInviteLinkMutationVariables
>;
export const ProxyLoginDocument = gql`
  mutation proxyLogin($input: ProxyLoginInputInput) {
    proxyLogin(input: $input)
  }
`;
export type ProxyLoginMutationFn = Apollo.MutationFunction<
  ProxyLoginMutation,
  ProxyLoginMutationVariables
>;

/**
 * __useProxyLoginMutation__
 *
 * To run a mutation, you first call `useProxyLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProxyLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [proxyLoginMutation, { data, loading, error }] = useProxyLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProxyLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<ProxyLoginMutation, ProxyLoginMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ProxyLoginMutation, ProxyLoginMutationVariables>(
    ProxyLoginDocument,
    options
  );
}
export type ProxyLoginMutationHookResult = ReturnType<typeof useProxyLoginMutation>;
export type ProxyLoginMutationResult = Apollo.MutationResult<ProxyLoginMutation>;
export type ProxyLoginMutationOptions = Apollo.BaseMutationOptions<
  ProxyLoginMutation,
  ProxyLoginMutationVariables
>;
export const ReadAllAnnouncementDocument = gql`
  mutation readAllAnnouncement {
    readAllAnnouncement {
      announceDateTime
      id
      readed
      title
      url
    }
  }
`;
export type ReadAllAnnouncementMutationFn = Apollo.MutationFunction<
  ReadAllAnnouncementMutation,
  ReadAllAnnouncementMutationVariables
>;

/**
 * __useReadAllAnnouncementMutation__
 *
 * To run a mutation, you first call `useReadAllAnnouncementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadAllAnnouncementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readAllAnnouncementMutation, { data, loading, error }] = useReadAllAnnouncementMutation({
 *   variables: {
 *   },
 * });
 */
export function useReadAllAnnouncementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReadAllAnnouncementMutation,
    ReadAllAnnouncementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ReadAllAnnouncementMutation, ReadAllAnnouncementMutationVariables>(
    ReadAllAnnouncementDocument,
    options
  );
}
export type ReadAllAnnouncementMutationHookResult = ReturnType<
  typeof useReadAllAnnouncementMutation
>;
export type ReadAllAnnouncementMutationResult = Apollo.MutationResult<ReadAllAnnouncementMutation>;
export type ReadAllAnnouncementMutationOptions = Apollo.BaseMutationOptions<
  ReadAllAnnouncementMutation,
  ReadAllAnnouncementMutationVariables
>;
export const ReadAnnouncementDocument = gql`
  mutation readAnnouncement($id: ID!) {
    readAnnouncement(id: $id) {
      announceDateTime
      id
      readed
      title
      url
    }
  }
`;
export type ReadAnnouncementMutationFn = Apollo.MutationFunction<
  ReadAnnouncementMutation,
  ReadAnnouncementMutationVariables
>;

/**
 * __useReadAnnouncementMutation__
 *
 * To run a mutation, you first call `useReadAnnouncementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadAnnouncementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readAnnouncementMutation, { data, loading, error }] = useReadAnnouncementMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReadAnnouncementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReadAnnouncementMutation,
    ReadAnnouncementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ReadAnnouncementMutation, ReadAnnouncementMutationVariables>(
    ReadAnnouncementDocument,
    options
  );
}
export type ReadAnnouncementMutationHookResult = ReturnType<typeof useReadAnnouncementMutation>;
export type ReadAnnouncementMutationResult = Apollo.MutationResult<ReadAnnouncementMutation>;
export type ReadAnnouncementMutationOptions = Apollo.BaseMutationOptions<
  ReadAnnouncementMutation,
  ReadAnnouncementMutationVariables
>;
export const RefleshApiKeyDocument = gql`
  mutation refleshApiKey {
    refleshApiKey {
      token
    }
  }
`;
export type RefleshApiKeyMutationFn = Apollo.MutationFunction<
  RefleshApiKeyMutation,
  RefleshApiKeyMutationVariables
>;

/**
 * __useRefleshApiKeyMutation__
 *
 * To run a mutation, you first call `useRefleshApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefleshApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refleshApiKeyMutation, { data, loading, error }] = useRefleshApiKeyMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefleshApiKeyMutation(
  baseOptions?: Apollo.MutationHookOptions<RefleshApiKeyMutation, RefleshApiKeyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RefleshApiKeyMutation, RefleshApiKeyMutationVariables>(
    RefleshApiKeyDocument,
    options
  );
}
export type RefleshApiKeyMutationHookResult = ReturnType<typeof useRefleshApiKeyMutation>;
export type RefleshApiKeyMutationResult = Apollo.MutationResult<RefleshApiKeyMutation>;
export type RefleshApiKeyMutationOptions = Apollo.BaseMutationOptions<
  RefleshApiKeyMutation,
  RefleshApiKeyMutationVariables
>;
export const RefleshTokenDocument = gql`
  mutation refleshToken {
    refleshToken
  }
`;
export type RefleshTokenMutationFn = Apollo.MutationFunction<
  RefleshTokenMutation,
  RefleshTokenMutationVariables
>;

/**
 * __useRefleshTokenMutation__
 *
 * To run a mutation, you first call `useRefleshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefleshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refleshTokenMutation, { data, loading, error }] = useRefleshTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefleshTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<RefleshTokenMutation, RefleshTokenMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RefleshTokenMutation, RefleshTokenMutationVariables>(
    RefleshTokenDocument,
    options
  );
}
export type RefleshTokenMutationHookResult = ReturnType<typeof useRefleshTokenMutation>;
export type RefleshTokenMutationResult = Apollo.MutationResult<RefleshTokenMutation>;
export type RefleshTokenMutationOptions = Apollo.BaseMutationOptions<
  RefleshTokenMutation,
  RefleshTokenMutationVariables
>;
export const RegenerateRecoveryCodesDocument = gql`
  mutation regenerateRecoveryCodes {
    regenerateRecoveryCodes {
      enabled
      recoveryCodes
    }
  }
`;
export type RegenerateRecoveryCodesMutationFn = Apollo.MutationFunction<
  RegenerateRecoveryCodesMutation,
  RegenerateRecoveryCodesMutationVariables
>;

/**
 * __useRegenerateRecoveryCodesMutation__
 *
 * To run a mutation, you first call `useRegenerateRecoveryCodesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegenerateRecoveryCodesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regenerateRecoveryCodesMutation, { data, loading, error }] = useRegenerateRecoveryCodesMutation({
 *   variables: {
 *   },
 * });
 */
export function useRegenerateRecoveryCodesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegenerateRecoveryCodesMutation,
    RegenerateRecoveryCodesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegenerateRecoveryCodesMutation,
    RegenerateRecoveryCodesMutationVariables
  >(RegenerateRecoveryCodesDocument, options);
}
export type RegenerateRecoveryCodesMutationHookResult = ReturnType<
  typeof useRegenerateRecoveryCodesMutation
>;
export type RegenerateRecoveryCodesMutationResult =
  Apollo.MutationResult<RegenerateRecoveryCodesMutation>;
export type RegenerateRecoveryCodesMutationOptions = Apollo.BaseMutationOptions<
  RegenerateRecoveryCodesMutation,
  RegenerateRecoveryCodesMutationVariables
>;
export const RegisterClientDocument = gql`
  mutation registerClient($input: RegisterClientInputInput, $organizationId: String!) {
    registerClient(input: $input, organizationId: $organizationId) {
      code
      id
      name
      sortNo
      versionNo
    }
  }
`;
export type RegisterClientMutationFn = Apollo.MutationFunction<
  RegisterClientMutation,
  RegisterClientMutationVariables
>;

/**
 * __useRegisterClientMutation__
 *
 * To run a mutation, you first call `useRegisterClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerClientMutation, { data, loading, error }] = useRegisterClientMutation({
 *   variables: {
 *      input: // value for 'input'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useRegisterClientMutation(
  baseOptions?: Apollo.MutationHookOptions<RegisterClientMutation, RegisterClientMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RegisterClientMutation, RegisterClientMutationVariables>(
    RegisterClientDocument,
    options
  );
}
export type RegisterClientMutationHookResult = ReturnType<typeof useRegisterClientMutation>;
export type RegisterClientMutationResult = Apollo.MutationResult<RegisterClientMutation>;
export type RegisterClientMutationOptions = Apollo.BaseMutationOptions<
  RegisterClientMutation,
  RegisterClientMutationVariables
>;
export const RegisterCreditCardDocument = gql`
  mutation registerCreditCard($contractId: ID!, $input: RegisterCreditCardInputInput) {
    registerCreditCard(contractId: $contractId, input: $input)
  }
`;
export type RegisterCreditCardMutationFn = Apollo.MutationFunction<
  RegisterCreditCardMutation,
  RegisterCreditCardMutationVariables
>;

/**
 * __useRegisterCreditCardMutation__
 *
 * To run a mutation, you first call `useRegisterCreditCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterCreditCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerCreditCardMutation, { data, loading, error }] = useRegisterCreditCardMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterCreditCardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterCreditCardMutation,
    RegisterCreditCardMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RegisterCreditCardMutation, RegisterCreditCardMutationVariables>(
    RegisterCreditCardDocument,
    options
  );
}
export type RegisterCreditCardMutationHookResult = ReturnType<typeof useRegisterCreditCardMutation>;
export type RegisterCreditCardMutationResult = Apollo.MutationResult<RegisterCreditCardMutation>;
export type RegisterCreditCardMutationOptions = Apollo.BaseMutationOptions<
  RegisterCreditCardMutation,
  RegisterCreditCardMutationVariables
>;
export const RegisterCsvReportItemDefinitionDocument = gql`
  mutation registerCsvReportItemDefinition($input: RegisterCsvReportItemDefinitionInputInput!) {
    registerCsvReportItemDefinition(input: $input) {
      id
      items {
        id
        reportItemCode
        reportItemName
        sortNo
      }
      name
      versionNo
    }
  }
`;
export type RegisterCsvReportItemDefinitionMutationFn = Apollo.MutationFunction<
  RegisterCsvReportItemDefinitionMutation,
  RegisterCsvReportItemDefinitionMutationVariables
>;

/**
 * __useRegisterCsvReportItemDefinitionMutation__
 *
 * To run a mutation, you first call `useRegisterCsvReportItemDefinitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterCsvReportItemDefinitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerCsvReportItemDefinitionMutation, { data, loading, error }] = useRegisterCsvReportItemDefinitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterCsvReportItemDefinitionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterCsvReportItemDefinitionMutation,
    RegisterCsvReportItemDefinitionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegisterCsvReportItemDefinitionMutation,
    RegisterCsvReportItemDefinitionMutationVariables
  >(RegisterCsvReportItemDefinitionDocument, options);
}
export type RegisterCsvReportItemDefinitionMutationHookResult = ReturnType<
  typeof useRegisterCsvReportItemDefinitionMutation
>;
export type RegisterCsvReportItemDefinitionMutationResult =
  Apollo.MutationResult<RegisterCsvReportItemDefinitionMutation>;
export type RegisterCsvReportItemDefinitionMutationOptions = Apollo.BaseMutationOptions<
  RegisterCsvReportItemDefinitionMutation,
  RegisterCsvReportItemDefinitionMutationVariables
>;
export const RegisterGoogleCalendarEventDocument = gql`
  mutation registerGoogleCalendarEvent($input: RegisterGoogleCalendarEventInputInput!) {
    registerGoogleCalendarEvent(input: $input) {
      allDay
      attendees {
        mailAddress
        name
        responseStatus
        self
      }
      backgroundColor
      calendarId
      colorId
      dateWhenAllDay
      description
      endDateTime
      foregroundColor
      id
      location
      organizer {
        mailAddress
        name
      }
      readonly
      startDateTime
      status
      targetMemberId
      title
      visivility
    }
  }
`;
export type RegisterGoogleCalendarEventMutationFn = Apollo.MutationFunction<
  RegisterGoogleCalendarEventMutation,
  RegisterGoogleCalendarEventMutationVariables
>;

/**
 * __useRegisterGoogleCalendarEventMutation__
 *
 * To run a mutation, you first call `useRegisterGoogleCalendarEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterGoogleCalendarEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerGoogleCalendarEventMutation, { data, loading, error }] = useRegisterGoogleCalendarEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterGoogleCalendarEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterGoogleCalendarEventMutation,
    RegisterGoogleCalendarEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegisterGoogleCalendarEventMutation,
    RegisterGoogleCalendarEventMutationVariables
  >(RegisterGoogleCalendarEventDocument, options);
}
export type RegisterGoogleCalendarEventMutationHookResult = ReturnType<
  typeof useRegisterGoogleCalendarEventMutation
>;
export type RegisterGoogleCalendarEventMutationResult =
  Apollo.MutationResult<RegisterGoogleCalendarEventMutation>;
export type RegisterGoogleCalendarEventMutationOptions = Apollo.BaseMutationOptions<
  RegisterGoogleCalendarEventMutation,
  RegisterGoogleCalendarEventMutationVariables
>;
export const RegisterPushNotificationDeviceDocument = gql`
  mutation registerPushNotificationDevice($input: RegisterPushNotificationDeviceInputInput!) {
    registerPushNotificationDevice(input: $input)
  }
`;
export type RegisterPushNotificationDeviceMutationFn = Apollo.MutationFunction<
  RegisterPushNotificationDeviceMutation,
  RegisterPushNotificationDeviceMutationVariables
>;

/**
 * __useRegisterPushNotificationDeviceMutation__
 *
 * To run a mutation, you first call `useRegisterPushNotificationDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterPushNotificationDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerPushNotificationDeviceMutation, { data, loading, error }] = useRegisterPushNotificationDeviceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterPushNotificationDeviceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterPushNotificationDeviceMutation,
    RegisterPushNotificationDeviceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegisterPushNotificationDeviceMutation,
    RegisterPushNotificationDeviceMutationVariables
  >(RegisterPushNotificationDeviceDocument, options);
}
export type RegisterPushNotificationDeviceMutationHookResult = ReturnType<
  typeof useRegisterPushNotificationDeviceMutation
>;
export type RegisterPushNotificationDeviceMutationResult =
  Apollo.MutationResult<RegisterPushNotificationDeviceMutation>;
export type RegisterPushNotificationDeviceMutationOptions = Apollo.BaseMutationOptions<
  RegisterPushNotificationDeviceMutation,
  RegisterPushNotificationDeviceMutationVariables
>;
export const RelateProjectTemplateTaskTagsDocument = gql`
  mutation relateProjectTemplateTaskTags(
    $input: RelateTaskTagsInputInput
    $projectTemplateTaskId: String!
  ) {
    relateProjectTemplateTaskTags(input: $input, projectTemplateTaskId: $projectTemplateTaskId) {
      id
      name
      note
      organization {
        allowConnectThirdPartyCalendar
        contactMailAddress
        contractPreriod
        id
        imageUrl
        ipRestrictionFlg
        name
        plan {
          code
          contractPreriod
          id
        }
        requiredTwoFactorAuth
        suspensionFlg
        versionNo
      }
      relatedTaskCount
      sortNo
      versionNo
    }
  }
`;
export type RelateProjectTemplateTaskTagsMutationFn = Apollo.MutationFunction<
  RelateProjectTemplateTaskTagsMutation,
  RelateProjectTemplateTaskTagsMutationVariables
>;

/**
 * __useRelateProjectTemplateTaskTagsMutation__
 *
 * To run a mutation, you first call `useRelateProjectTemplateTaskTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRelateProjectTemplateTaskTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [relateProjectTemplateTaskTagsMutation, { data, loading, error }] = useRelateProjectTemplateTaskTagsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      projectTemplateTaskId: // value for 'projectTemplateTaskId'
 *   },
 * });
 */
export function useRelateProjectTemplateTaskTagsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RelateProjectTemplateTaskTagsMutation,
    RelateProjectTemplateTaskTagsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RelateProjectTemplateTaskTagsMutation,
    RelateProjectTemplateTaskTagsMutationVariables
  >(RelateProjectTemplateTaskTagsDocument, options);
}
export type RelateProjectTemplateTaskTagsMutationHookResult = ReturnType<
  typeof useRelateProjectTemplateTaskTagsMutation
>;
export type RelateProjectTemplateTaskTagsMutationResult =
  Apollo.MutationResult<RelateProjectTemplateTaskTagsMutation>;
export type RelateProjectTemplateTaskTagsMutationOptions = Apollo.BaseMutationOptions<
  RelateProjectTemplateTaskTagsMutation,
  RelateProjectTemplateTaskTagsMutationVariables
>;
export const RelateTaskTagsDocument = gql`
  mutation relateTaskTags($input: RelateTaskTagsInputInput, $taskId: String!) {
    relateTaskTags(input: $input, taskId: $taskId) {
      id
      name
      note
      organization {
        allowConnectThirdPartyCalendar
        contactMailAddress
        contractPreriod
        id
        imageUrl
        ipRestrictionFlg
        name
        plan {
          code
          contractPreriod
          id
        }
        requiredTwoFactorAuth
        suspensionFlg
        versionNo
      }
      relatedTaskCount
      sortNo
      versionNo
    }
  }
`;
export type RelateTaskTagsMutationFn = Apollo.MutationFunction<
  RelateTaskTagsMutation,
  RelateTaskTagsMutationVariables
>;

/**
 * __useRelateTaskTagsMutation__
 *
 * To run a mutation, you first call `useRelateTaskTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRelateTaskTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [relateTaskTagsMutation, { data, loading, error }] = useRelateTaskTagsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useRelateTaskTagsMutation(
  baseOptions?: Apollo.MutationHookOptions<RelateTaskTagsMutation, RelateTaskTagsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RelateTaskTagsMutation, RelateTaskTagsMutationVariables>(
    RelateTaskTagsDocument,
    options
  );
}
export type RelateTaskTagsMutationHookResult = ReturnType<typeof useRelateTaskTagsMutation>;
export type RelateTaskTagsMutationResult = Apollo.MutationResult<RelateTaskTagsMutation>;
export type RelateTaskTagsMutationOptions = Apollo.BaseMutationOptions<
  RelateTaskTagsMutation,
  RelateTaskTagsMutationVariables
>;
export const RemoveFromTeamDocument = gql`
  mutation removeFromTeam($input: RemoveMemberToTeamInputInput!) {
    removeFromTeam(input: $input) {
      id
      openMenu
      sortNo
      team {
        archived
        id
        name
        organization {
          allowConnectThirdPartyCalendar
          contactMailAddress
          contractPreriod
          id
          imageUrl
          ipRestrictionFlg
          name
          plan {
            code
            contractPreriod
            id
          }
          requiredTwoFactorAuth
          suspensionFlg
          versionNo
        }
        personalTeam
        status
        versionNo
      }
      versionNo
    }
  }
`;
export type RemoveFromTeamMutationFn = Apollo.MutationFunction<
  RemoveFromTeamMutation,
  RemoveFromTeamMutationVariables
>;

/**
 * __useRemoveFromTeamMutation__
 *
 * To run a mutation, you first call `useRemoveFromTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFromTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFromTeamMutation, { data, loading, error }] = useRemoveFromTeamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveFromTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveFromTeamMutation, RemoveFromTeamMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveFromTeamMutation, RemoveFromTeamMutationVariables>(
    RemoveFromTeamDocument,
    options
  );
}
export type RemoveFromTeamMutationHookResult = ReturnType<typeof useRemoveFromTeamMutation>;
export type RemoveFromTeamMutationResult = Apollo.MutationResult<RemoveFromTeamMutation>;
export type RemoveFromTeamMutationOptions = Apollo.BaseMutationOptions<
  RemoveFromTeamMutation,
  RemoveFromTeamMutationVariables
>;
export const RemoveMemberFromTeamDocument = gql`
  mutation removeMemberFromTeam($id: ID!, $input: RemoveMemberToTeamInputInput!) {
    removeMemberFromTeam(id: $id, input: $input) {
      adminRole
      clientManagementRole
      department
      employeeNumber
      id
      laborCostManagementRole
      mailAddress
      memberManagementRole
      memberRegistrationDateTime
      memberStatus
      name
      organizationMemberViewRole
      organizationReportRole
      profileImageUrl
      projectCreatePermissionFlg
      projectCustomAttributePermissionFlg
      projectDeletePermissionFlg
      projectUpdatePermissionFlg
      proxyEditWorkingDataRole
      salesManagementRole
      statusMessage
      taskCreatePermissionFlg
      taskCustomAttributePermissionFlg
      taskDeletePermissionFlg
      taskUpdatePermissionFlg
      teamMemberViewRole
      teamReportRole
      versionNo
    }
  }
`;
export type RemoveMemberFromTeamMutationFn = Apollo.MutationFunction<
  RemoveMemberFromTeamMutation,
  RemoveMemberFromTeamMutationVariables
>;

/**
 * __useRemoveMemberFromTeamMutation__
 *
 * To run a mutation, you first call `useRemoveMemberFromTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMemberFromTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMemberFromTeamMutation, { data, loading, error }] = useRemoveMemberFromTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveMemberFromTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveMemberFromTeamMutation,
    RemoveMemberFromTeamMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveMemberFromTeamMutation, RemoveMemberFromTeamMutationVariables>(
    RemoveMemberFromTeamDocument,
    options
  );
}
export type RemoveMemberFromTeamMutationHookResult = ReturnType<
  typeof useRemoveMemberFromTeamMutation
>;
export type RemoveMemberFromTeamMutationResult =
  Apollo.MutationResult<RemoveMemberFromTeamMutation>;
export type RemoveMemberFromTeamMutationOptions = Apollo.BaseMutationOptions<
  RemoveMemberFromTeamMutation,
  RemoveMemberFromTeamMutationVariables
>;
export const RequestExportOrganizationClientExcelDocument = gql`
  mutation requestExportOrganizationClientExcel($input: RequestExportClientExcelInputInput!) {
    requestExportOrganizationClientExcel(input: $input) {
      id
      requestDateTime
      requestMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      status
      timeZoneOffset
      url
      versionNo
    }
  }
`;
export type RequestExportOrganizationClientExcelMutationFn = Apollo.MutationFunction<
  RequestExportOrganizationClientExcelMutation,
  RequestExportOrganizationClientExcelMutationVariables
>;

/**
 * __useRequestExportOrganizationClientExcelMutation__
 *
 * To run a mutation, you first call `useRequestExportOrganizationClientExcelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestExportOrganizationClientExcelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestExportOrganizationClientExcelMutation, { data, loading, error }] = useRequestExportOrganizationClientExcelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestExportOrganizationClientExcelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestExportOrganizationClientExcelMutation,
    RequestExportOrganizationClientExcelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestExportOrganizationClientExcelMutation,
    RequestExportOrganizationClientExcelMutationVariables
  >(RequestExportOrganizationClientExcelDocument, options);
}
export type RequestExportOrganizationClientExcelMutationHookResult = ReturnType<
  typeof useRequestExportOrganizationClientExcelMutation
>;
export type RequestExportOrganizationClientExcelMutationResult =
  Apollo.MutationResult<RequestExportOrganizationClientExcelMutation>;
export type RequestExportOrganizationClientExcelMutationOptions = Apollo.BaseMutationOptions<
  RequestExportOrganizationClientExcelMutation,
  RequestExportOrganizationClientExcelMutationVariables
>;
export const RequestExportOrganizationMemberExcelDocument = gql`
  mutation requestExportOrganizationMemberExcel($input: RequestExportMemberExcelInputInput!) {
    requestExportOrganizationMemberExcel(input: $input) {
      id
      requestDateTime
      requestMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      status
      timeZoneOffset
      url
      versionNo
    }
  }
`;
export type RequestExportOrganizationMemberExcelMutationFn = Apollo.MutationFunction<
  RequestExportOrganizationMemberExcelMutation,
  RequestExportOrganizationMemberExcelMutationVariables
>;

/**
 * __useRequestExportOrganizationMemberExcelMutation__
 *
 * To run a mutation, you first call `useRequestExportOrganizationMemberExcelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestExportOrganizationMemberExcelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestExportOrganizationMemberExcelMutation, { data, loading, error }] = useRequestExportOrganizationMemberExcelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestExportOrganizationMemberExcelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestExportOrganizationMemberExcelMutation,
    RequestExportOrganizationMemberExcelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestExportOrganizationMemberExcelMutation,
    RequestExportOrganizationMemberExcelMutationVariables
  >(RequestExportOrganizationMemberExcelDocument, options);
}
export type RequestExportOrganizationMemberExcelMutationHookResult = ReturnType<
  typeof useRequestExportOrganizationMemberExcelMutation
>;
export type RequestExportOrganizationMemberExcelMutationResult =
  Apollo.MutationResult<RequestExportOrganizationMemberExcelMutation>;
export type RequestExportOrganizationMemberExcelMutationOptions = Apollo.BaseMutationOptions<
  RequestExportOrganizationMemberExcelMutation,
  RequestExportOrganizationMemberExcelMutationVariables
>;
export const RequestExportOrganizationProjectExcelDocument = gql`
  mutation requestExportOrganizationProjectExcel($input: RequestExportProjectExcelInputInput!) {
    requestExportOrganizationProjectExcel(input: $input) {
      id
      includeCompleted
      requestDateTime
      requestMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      status
      teamid
      timeZoneOffset
      url
      versionNo
    }
  }
`;
export type RequestExportOrganizationProjectExcelMutationFn = Apollo.MutationFunction<
  RequestExportOrganizationProjectExcelMutation,
  RequestExportOrganizationProjectExcelMutationVariables
>;

/**
 * __useRequestExportOrganizationProjectExcelMutation__
 *
 * To run a mutation, you first call `useRequestExportOrganizationProjectExcelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestExportOrganizationProjectExcelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestExportOrganizationProjectExcelMutation, { data, loading, error }] = useRequestExportOrganizationProjectExcelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestExportOrganizationProjectExcelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestExportOrganizationProjectExcelMutation,
    RequestExportOrganizationProjectExcelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestExportOrganizationProjectExcelMutation,
    RequestExportOrganizationProjectExcelMutationVariables
  >(RequestExportOrganizationProjectExcelDocument, options);
}
export type RequestExportOrganizationProjectExcelMutationHookResult = ReturnType<
  typeof useRequestExportOrganizationProjectExcelMutation
>;
export type RequestExportOrganizationProjectExcelMutationResult =
  Apollo.MutationResult<RequestExportOrganizationProjectExcelMutation>;
export type RequestExportOrganizationProjectExcelMutationOptions = Apollo.BaseMutationOptions<
  RequestExportOrganizationProjectExcelMutation,
  RequestExportOrganizationProjectExcelMutationVariables
>;
export const RequestExportOrganizationTaskExcelDocument = gql`
  mutation requestExportOrganizationTaskExcel($input: RequestExportTaskExcelInputInput!) {
    requestExportOrganizationTaskExcel(input: $input) {
      id
      includeCompleted
      requestDateTime
      requestMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      status
      teamid
      timeZoneOffset
      url
      versionNo
    }
  }
`;
export type RequestExportOrganizationTaskExcelMutationFn = Apollo.MutationFunction<
  RequestExportOrganizationTaskExcelMutation,
  RequestExportOrganizationTaskExcelMutationVariables
>;

/**
 * __useRequestExportOrganizationTaskExcelMutation__
 *
 * To run a mutation, you first call `useRequestExportOrganizationTaskExcelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestExportOrganizationTaskExcelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestExportOrganizationTaskExcelMutation, { data, loading, error }] = useRequestExportOrganizationTaskExcelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestExportOrganizationTaskExcelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestExportOrganizationTaskExcelMutation,
    RequestExportOrganizationTaskExcelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestExportOrganizationTaskExcelMutation,
    RequestExportOrganizationTaskExcelMutationVariables
  >(RequestExportOrganizationTaskExcelDocument, options);
}
export type RequestExportOrganizationTaskExcelMutationHookResult = ReturnType<
  typeof useRequestExportOrganizationTaskExcelMutation
>;
export type RequestExportOrganizationTaskExcelMutationResult =
  Apollo.MutationResult<RequestExportOrganizationTaskExcelMutation>;
export type RequestExportOrganizationTaskExcelMutationOptions = Apollo.BaseMutationOptions<
  RequestExportOrganizationTaskExcelMutation,
  RequestExportOrganizationTaskExcelMutationVariables
>;
export const RequestExportTeamProjectExcelDocument = gql`
  mutation requestExportTeamProjectExcel($input: RequestExportProjectExcelInputInput!) {
    requestExportTeamProjectExcel(input: $input) {
      id
      includeCompleted
      requestDateTime
      requestMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      status
      teamid
      timeZoneOffset
      url
      versionNo
    }
  }
`;
export type RequestExportTeamProjectExcelMutationFn = Apollo.MutationFunction<
  RequestExportTeamProjectExcelMutation,
  RequestExportTeamProjectExcelMutationVariables
>;

/**
 * __useRequestExportTeamProjectExcelMutation__
 *
 * To run a mutation, you first call `useRequestExportTeamProjectExcelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestExportTeamProjectExcelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestExportTeamProjectExcelMutation, { data, loading, error }] = useRequestExportTeamProjectExcelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestExportTeamProjectExcelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestExportTeamProjectExcelMutation,
    RequestExportTeamProjectExcelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestExportTeamProjectExcelMutation,
    RequestExportTeamProjectExcelMutationVariables
  >(RequestExportTeamProjectExcelDocument, options);
}
export type RequestExportTeamProjectExcelMutationHookResult = ReturnType<
  typeof useRequestExportTeamProjectExcelMutation
>;
export type RequestExportTeamProjectExcelMutationResult =
  Apollo.MutationResult<RequestExportTeamProjectExcelMutation>;
export type RequestExportTeamProjectExcelMutationOptions = Apollo.BaseMutationOptions<
  RequestExportTeamProjectExcelMutation,
  RequestExportTeamProjectExcelMutationVariables
>;
export const RequestExportTeamTaskExcelDocument = gql`
  mutation requestExportTeamTaskExcel($input: RequestExportTaskExcelInputInput!) {
    requestExportTeamTaskExcel(input: $input) {
      id
      includeCompleted
      requestDateTime
      requestMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      status
      teamid
      timeZoneOffset
      url
      versionNo
    }
  }
`;
export type RequestExportTeamTaskExcelMutationFn = Apollo.MutationFunction<
  RequestExportTeamTaskExcelMutation,
  RequestExportTeamTaskExcelMutationVariables
>;

/**
 * __useRequestExportTeamTaskExcelMutation__
 *
 * To run a mutation, you first call `useRequestExportTeamTaskExcelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestExportTeamTaskExcelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestExportTeamTaskExcelMutation, { data, loading, error }] = useRequestExportTeamTaskExcelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestExportTeamTaskExcelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestExportTeamTaskExcelMutation,
    RequestExportTeamTaskExcelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestExportTeamTaskExcelMutation,
    RequestExportTeamTaskExcelMutationVariables
  >(RequestExportTeamTaskExcelDocument, options);
}
export type RequestExportTeamTaskExcelMutationHookResult = ReturnType<
  typeof useRequestExportTeamTaskExcelMutation
>;
export type RequestExportTeamTaskExcelMutationResult =
  Apollo.MutationResult<RequestExportTeamTaskExcelMutation>;
export type RequestExportTeamTaskExcelMutationOptions = Apollo.BaseMutationOptions<
  RequestExportTeamTaskExcelMutation,
  RequestExportTeamTaskExcelMutationVariables
>;
export const RequestMemberRegisterDocument = gql`
  mutation requestMemberRegister($input: MemberRegisterRequestInputInput!) {
    requestMemberRegister(input: $input)
  }
`;
export type RequestMemberRegisterMutationFn = Apollo.MutationFunction<
  RequestMemberRegisterMutation,
  RequestMemberRegisterMutationVariables
>;

/**
 * __useRequestMemberRegisterMutation__
 *
 * To run a mutation, you first call `useRequestMemberRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestMemberRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestMemberRegisterMutation, { data, loading, error }] = useRequestMemberRegisterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestMemberRegisterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestMemberRegisterMutation,
    RequestMemberRegisterMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RequestMemberRegisterMutation, RequestMemberRegisterMutationVariables>(
    RequestMemberRegisterDocument,
    options
  );
}
export type RequestMemberRegisterMutationHookResult = ReturnType<
  typeof useRequestMemberRegisterMutation
>;
export type RequestMemberRegisterMutationResult =
  Apollo.MutationResult<RequestMemberRegisterMutation>;
export type RequestMemberRegisterMutationOptions = Apollo.BaseMutationOptions<
  RequestMemberRegisterMutation,
  RequestMemberRegisterMutationVariables
>;
export const RequestMemberRegisterWithInvitationLinkDocument = gql`
  mutation requestMemberRegisterWithInvitationLink(
    $input: MemberRegisterRequestWithInvitationLInkInputInput!
  ) {
    requestMemberRegisterWithInvitationLink(input: $input)
  }
`;
export type RequestMemberRegisterWithInvitationLinkMutationFn = Apollo.MutationFunction<
  RequestMemberRegisterWithInvitationLinkMutation,
  RequestMemberRegisterWithInvitationLinkMutationVariables
>;

/**
 * __useRequestMemberRegisterWithInvitationLinkMutation__
 *
 * To run a mutation, you first call `useRequestMemberRegisterWithInvitationLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestMemberRegisterWithInvitationLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestMemberRegisterWithInvitationLinkMutation, { data, loading, error }] = useRequestMemberRegisterWithInvitationLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestMemberRegisterWithInvitationLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestMemberRegisterWithInvitationLinkMutation,
    RequestMemberRegisterWithInvitationLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestMemberRegisterWithInvitationLinkMutation,
    RequestMemberRegisterWithInvitationLinkMutationVariables
  >(RequestMemberRegisterWithInvitationLinkDocument, options);
}
export type RequestMemberRegisterWithInvitationLinkMutationHookResult = ReturnType<
  typeof useRequestMemberRegisterWithInvitationLinkMutation
>;
export type RequestMemberRegisterWithInvitationLinkMutationResult =
  Apollo.MutationResult<RequestMemberRegisterWithInvitationLinkMutation>;
export type RequestMemberRegisterWithInvitationLinkMutationOptions = Apollo.BaseMutationOptions<
  RequestMemberRegisterWithInvitationLinkMutation,
  RequestMemberRegisterWithInvitationLinkMutationVariables
>;
export const RequestMyCsvReportDocument = gql`
  mutation requestMyCsvReport($input: RequestMyCsvReportInputInput!) {
    requestMyCsvReport(input: $input) {
      csvReportItemDefinition {
        id
        items {
          id
          reportItemCode
          reportItemName
          sortNo
        }
        name
        versionNo
      }
      end
      id
      includeWorkingData
      reportType
      requestDateTime
      sjisFileUrl
      start
      status
      timeZoneOffset
      utf8FileUrl
      versionNo
    }
  }
`;
export type RequestMyCsvReportMutationFn = Apollo.MutationFunction<
  RequestMyCsvReportMutation,
  RequestMyCsvReportMutationVariables
>;

/**
 * __useRequestMyCsvReportMutation__
 *
 * To run a mutation, you first call `useRequestMyCsvReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestMyCsvReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestMyCsvReportMutation, { data, loading, error }] = useRequestMyCsvReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestMyCsvReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestMyCsvReportMutation,
    RequestMyCsvReportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RequestMyCsvReportMutation, RequestMyCsvReportMutationVariables>(
    RequestMyCsvReportDocument,
    options
  );
}
export type RequestMyCsvReportMutationHookResult = ReturnType<typeof useRequestMyCsvReportMutation>;
export type RequestMyCsvReportMutationResult = Apollo.MutationResult<RequestMyCsvReportMutation>;
export type RequestMyCsvReportMutationOptions = Apollo.BaseMutationOptions<
  RequestMyCsvReportMutation,
  RequestMyCsvReportMutationVariables
>;
export const RequestMyReportDocument = gql`
  mutation requestMyReport($input: RequestMyReportInputInput!) {
    requestMyReport(input: $input) {
      end
      id
      includeWorkingData
      reportType
      requestDateTime
      start
      status
      timeZoneOffset
      url
      versionNo
    }
  }
`;
export type RequestMyReportMutationFn = Apollo.MutationFunction<
  RequestMyReportMutation,
  RequestMyReportMutationVariables
>;

/**
 * __useRequestMyReportMutation__
 *
 * To run a mutation, you first call `useRequestMyReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestMyReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestMyReportMutation, { data, loading, error }] = useRequestMyReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestMyReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestMyReportMutation,
    RequestMyReportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RequestMyReportMutation, RequestMyReportMutationVariables>(
    RequestMyReportDocument,
    options
  );
}
export type RequestMyReportMutationHookResult = ReturnType<typeof useRequestMyReportMutation>;
export type RequestMyReportMutationResult = Apollo.MutationResult<RequestMyReportMutation>;
export type RequestMyReportMutationOptions = Apollo.BaseMutationOptions<
  RequestMyReportMutation,
  RequestMyReportMutationVariables
>;
export const RequestOrganizationCsvReportDocument = gql`
  mutation requestOrganizationCsvReport($input: RequestOrganizationCsvReportInputInput!) {
    requestOrganizationCsvReport(input: $input) {
      csvReportItemDefinition {
        id
        items {
          id
          reportItemCode
          reportItemName
          sortNo
        }
        name
        versionNo
      }
      end
      id
      includeWorkingData
      reportType
      requestDateTime
      requestMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      sjisFileUrl
      start
      status
      timeZoneOffset
      utf8FileUrl
      versionNo
    }
  }
`;
export type RequestOrganizationCsvReportMutationFn = Apollo.MutationFunction<
  RequestOrganizationCsvReportMutation,
  RequestOrganizationCsvReportMutationVariables
>;

/**
 * __useRequestOrganizationCsvReportMutation__
 *
 * To run a mutation, you first call `useRequestOrganizationCsvReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestOrganizationCsvReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestOrganizationCsvReportMutation, { data, loading, error }] = useRequestOrganizationCsvReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestOrganizationCsvReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestOrganizationCsvReportMutation,
    RequestOrganizationCsvReportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestOrganizationCsvReportMutation,
    RequestOrganizationCsvReportMutationVariables
  >(RequestOrganizationCsvReportDocument, options);
}
export type RequestOrganizationCsvReportMutationHookResult = ReturnType<
  typeof useRequestOrganizationCsvReportMutation
>;
export type RequestOrganizationCsvReportMutationResult =
  Apollo.MutationResult<RequestOrganizationCsvReportMutation>;
export type RequestOrganizationCsvReportMutationOptions = Apollo.BaseMutationOptions<
  RequestOrganizationCsvReportMutation,
  RequestOrganizationCsvReportMutationVariables
>;
export const RequestOrganizationReportDocument = gql`
  mutation requestOrganizationReport($input: RequestOrganizationReportInputInput!) {
    requestOrganizationReport(input: $input) {
      end
      id
      includeWorkingData
      reportType
      requestDateTime
      requestMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      start
      status
      timeZoneOffset
      url
      versionNo
    }
  }
`;
export type RequestOrganizationReportMutationFn = Apollo.MutationFunction<
  RequestOrganizationReportMutation,
  RequestOrganizationReportMutationVariables
>;

/**
 * __useRequestOrganizationReportMutation__
 *
 * To run a mutation, you first call `useRequestOrganizationReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestOrganizationReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestOrganizationReportMutation, { data, loading, error }] = useRequestOrganizationReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestOrganizationReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestOrganizationReportMutation,
    RequestOrganizationReportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestOrganizationReportMutation,
    RequestOrganizationReportMutationVariables
  >(RequestOrganizationReportDocument, options);
}
export type RequestOrganizationReportMutationHookResult = ReturnType<
  typeof useRequestOrganizationReportMutation
>;
export type RequestOrganizationReportMutationResult =
  Apollo.MutationResult<RequestOrganizationReportMutation>;
export type RequestOrganizationReportMutationOptions = Apollo.BaseMutationOptions<
  RequestOrganizationReportMutation,
  RequestOrganizationReportMutationVariables
>;
export const RequestPasswordResetDocument = gql`
  mutation requestPasswordReset($input: RequestPasswordResetInputInput!) {
    requestPasswordReset(input: $input)
  }
`;
export type RequestPasswordResetMutationFn = Apollo.MutationFunction<
  RequestPasswordResetMutation,
  RequestPasswordResetMutationVariables
>;

/**
 * __useRequestPasswordResetMutation__
 *
 * To run a mutation, you first call `useRequestPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPasswordResetMutation, { data, loading, error }] = useRequestPasswordResetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestPasswordResetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestPasswordResetMutation,
    RequestPasswordResetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>(
    RequestPasswordResetDocument,
    options
  );
}
export type RequestPasswordResetMutationHookResult = ReturnType<
  typeof useRequestPasswordResetMutation
>;
export type RequestPasswordResetMutationResult =
  Apollo.MutationResult<RequestPasswordResetMutation>;
export type RequestPasswordResetMutationOptions = Apollo.BaseMutationOptions<
  RequestPasswordResetMutation,
  RequestPasswordResetMutationVariables
>;
export const RequestTeamCsvReportDocument = gql`
  mutation requestTeamCsvReport($input: RequestTeamCsvReportInputInput!) {
    requestTeamCsvReport(input: $input) {
      csvReportItemDefinition {
        id
        items {
          id
          reportItemCode
          reportItemName
          sortNo
        }
        name
        versionNo
      }
      end
      id
      includeWorkingData
      reportType
      requestDateTime
      requestMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      sjisFileUrl
      start
      status
      team {
        archived
        id
        name
        organization {
          allowConnectThirdPartyCalendar
          contactMailAddress
          contractPreriod
          id
          imageUrl
          ipRestrictionFlg
          name
          plan {
            code
            contractPreriod
            id
          }
          requiredTwoFactorAuth
          suspensionFlg
          versionNo
        }
        personalTeam
        status
        versionNo
      }
      timeZoneOffset
      utf8FileUrl
      versionNo
    }
  }
`;
export type RequestTeamCsvReportMutationFn = Apollo.MutationFunction<
  RequestTeamCsvReportMutation,
  RequestTeamCsvReportMutationVariables
>;

/**
 * __useRequestTeamCsvReportMutation__
 *
 * To run a mutation, you first call `useRequestTeamCsvReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestTeamCsvReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestTeamCsvReportMutation, { data, loading, error }] = useRequestTeamCsvReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestTeamCsvReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestTeamCsvReportMutation,
    RequestTeamCsvReportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RequestTeamCsvReportMutation, RequestTeamCsvReportMutationVariables>(
    RequestTeamCsvReportDocument,
    options
  );
}
export type RequestTeamCsvReportMutationHookResult = ReturnType<
  typeof useRequestTeamCsvReportMutation
>;
export type RequestTeamCsvReportMutationResult =
  Apollo.MutationResult<RequestTeamCsvReportMutation>;
export type RequestTeamCsvReportMutationOptions = Apollo.BaseMutationOptions<
  RequestTeamCsvReportMutation,
  RequestTeamCsvReportMutationVariables
>;
export const RequestTeamReportDocument = gql`
  mutation requestTeamReport($input: RequestTeamReportInputInput!) {
    requestTeamReport(input: $input) {
      end
      id
      includeWorkingData
      reportType
      requestDateTime
      requestMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      start
      status
      team {
        archived
        id
        name
        organization {
          allowConnectThirdPartyCalendar
          contactMailAddress
          contractPreriod
          id
          imageUrl
          ipRestrictionFlg
          name
          plan {
            code
            contractPreriod
            id
          }
          requiredTwoFactorAuth
          suspensionFlg
          versionNo
        }
        personalTeam
        status
        versionNo
      }
      timeZoneOffset
      url
      versionNo
    }
  }
`;
export type RequestTeamReportMutationFn = Apollo.MutationFunction<
  RequestTeamReportMutation,
  RequestTeamReportMutationVariables
>;

/**
 * __useRequestTeamReportMutation__
 *
 * To run a mutation, you first call `useRequestTeamReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestTeamReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestTeamReportMutation, { data, loading, error }] = useRequestTeamReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestTeamReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestTeamReportMutation,
    RequestTeamReportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RequestTeamReportMutation, RequestTeamReportMutationVariables>(
    RequestTeamReportDocument,
    options
  );
}
export type RequestTeamReportMutationHookResult = ReturnType<typeof useRequestTeamReportMutation>;
export type RequestTeamReportMutationResult = Apollo.MutationResult<RequestTeamReportMutation>;
export type RequestTeamReportMutationOptions = Apollo.BaseMutationOptions<
  RequestTeamReportMutation,
  RequestTeamReportMutationVariables
>;
export const ResendInvitationDocument = gql`
  mutation resendInvitation($key: String!) {
    resendInvitation(key: $key) {
      adminRole
      allowThirdPartyCalendarConnect
      clientManagementRole
      createMemberId
      department
      employeeNumber
      id
      invitationKey
      laborCostManagementRole
      mailAddress
      memberManagementRole
      name
      organizationId
      organizationMemberViewRole
      organizationName
      organizationReportRole
      projectCreatePermissionFlg
      projectCustomAttributePermissionFlg
      projectDeletePermissionFlg
      projectUpdatePermissionFlg
      proxyEditWorkingDataRole
      salesManagementRole
      taskCreatePermissionFlg
      taskCustomAttributePermissionFlg
      taskDeletePermissionFlg
      taskUpdatePermissionFlg
      teamMemberViewRole
      teamReportRole
      versionNo
    }
  }
`;
export type ResendInvitationMutationFn = Apollo.MutationFunction<
  ResendInvitationMutation,
  ResendInvitationMutationVariables
>;

/**
 * __useResendInvitationMutation__
 *
 * To run a mutation, you first call `useResendInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendInvitationMutation, { data, loading, error }] = useResendInvitationMutation({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useResendInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendInvitationMutation,
    ResendInvitationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResendInvitationMutation, ResendInvitationMutationVariables>(
    ResendInvitationDocument,
    options
  );
}
export type ResendInvitationMutationHookResult = ReturnType<typeof useResendInvitationMutation>;
export type ResendInvitationMutationResult = Apollo.MutationResult<ResendInvitationMutation>;
export type ResendInvitationMutationOptions = Apollo.BaseMutationOptions<
  ResendInvitationMutation,
  ResendInvitationMutationVariables
>;
export const ResetPasswordDocument = gql`
  mutation resetPassword($input: ResetPasswordInputInput) {
    resetPassword(input: $input)
  }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(
    ResetPasswordDocument,
    options
  );
}
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const SetUpTwoFactorAuthDocument = gql`
  mutation setUpTwoFactorAuth {
    setUpTwoFactorAuth {
      qrImageBase64
      secret
      verifyToken
    }
  }
`;
export type SetUpTwoFactorAuthMutationFn = Apollo.MutationFunction<
  SetUpTwoFactorAuthMutation,
  SetUpTwoFactorAuthMutationVariables
>;

/**
 * __useSetUpTwoFactorAuthMutation__
 *
 * To run a mutation, you first call `useSetUpTwoFactorAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUpTwoFactorAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUpTwoFactorAuthMutation, { data, loading, error }] = useSetUpTwoFactorAuthMutation({
 *   variables: {
 *   },
 * });
 */
export function useSetUpTwoFactorAuthMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetUpTwoFactorAuthMutation,
    SetUpTwoFactorAuthMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetUpTwoFactorAuthMutation, SetUpTwoFactorAuthMutationVariables>(
    SetUpTwoFactorAuthDocument,
    options
  );
}
export type SetUpTwoFactorAuthMutationHookResult = ReturnType<typeof useSetUpTwoFactorAuthMutation>;
export type SetUpTwoFactorAuthMutationResult = Apollo.MutationResult<SetUpTwoFactorAuthMutation>;
export type SetUpTwoFactorAuthMutationOptions = Apollo.BaseMutationOptions<
  SetUpTwoFactorAuthMutation,
  SetUpTwoFactorAuthMutationVariables
>;
export const SignupWithAppleAccountDocument = gql`
  mutation signupWithAppleAccount($input: SignupWithAppleAccountInputInput!) {
    signupWithAppleAccount(input: $input)
  }
`;
export type SignupWithAppleAccountMutationFn = Apollo.MutationFunction<
  SignupWithAppleAccountMutation,
  SignupWithAppleAccountMutationVariables
>;

/**
 * __useSignupWithAppleAccountMutation__
 *
 * To run a mutation, you first call `useSignupWithAppleAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupWithAppleAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupWithAppleAccountMutation, { data, loading, error }] = useSignupWithAppleAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignupWithAppleAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignupWithAppleAccountMutation,
    SignupWithAppleAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SignupWithAppleAccountMutation,
    SignupWithAppleAccountMutationVariables
  >(SignupWithAppleAccountDocument, options);
}
export type SignupWithAppleAccountMutationHookResult = ReturnType<
  typeof useSignupWithAppleAccountMutation
>;
export type SignupWithAppleAccountMutationResult =
  Apollo.MutationResult<SignupWithAppleAccountMutation>;
export type SignupWithAppleAccountMutationOptions = Apollo.BaseMutationOptions<
  SignupWithAppleAccountMutation,
  SignupWithAppleAccountMutationVariables
>;
export const SignupWithGoogleAccountDocument = gql`
  mutation signupWithGoogleAccount($input: SignupWithGoogleAccountInputInput!) {
    signupWithGoogleAccount(input: $input)
  }
`;
export type SignupWithGoogleAccountMutationFn = Apollo.MutationFunction<
  SignupWithGoogleAccountMutation,
  SignupWithGoogleAccountMutationVariables
>;

/**
 * __useSignupWithGoogleAccountMutation__
 *
 * To run a mutation, you first call `useSignupWithGoogleAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupWithGoogleAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupWithGoogleAccountMutation, { data, loading, error }] = useSignupWithGoogleAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignupWithGoogleAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignupWithGoogleAccountMutation,
    SignupWithGoogleAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SignupWithGoogleAccountMutation,
    SignupWithGoogleAccountMutationVariables
  >(SignupWithGoogleAccountDocument, options);
}
export type SignupWithGoogleAccountMutationHookResult = ReturnType<
  typeof useSignupWithGoogleAccountMutation
>;
export type SignupWithGoogleAccountMutationResult =
  Apollo.MutationResult<SignupWithGoogleAccountMutation>;
export type SignupWithGoogleAccountMutationOptions = Apollo.BaseMutationOptions<
  SignupWithGoogleAccountMutation,
  SignupWithGoogleAccountMutationVariables
>;
export const SignupWithMailAddressDocument = gql`
  mutation signupWithMailAddress($input: SignupWithMailAddressInputInput!) {
    signupWithMailAddress(input: $input)
  }
`;
export type SignupWithMailAddressMutationFn = Apollo.MutationFunction<
  SignupWithMailAddressMutation,
  SignupWithMailAddressMutationVariables
>;

/**
 * __useSignupWithMailAddressMutation__
 *
 * To run a mutation, you first call `useSignupWithMailAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupWithMailAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupWithMailAddressMutation, { data, loading, error }] = useSignupWithMailAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignupWithMailAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignupWithMailAddressMutation,
    SignupWithMailAddressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignupWithMailAddressMutation, SignupWithMailAddressMutationVariables>(
    SignupWithMailAddressDocument,
    options
  );
}
export type SignupWithMailAddressMutationHookResult = ReturnType<
  typeof useSignupWithMailAddressMutation
>;
export type SignupWithMailAddressMutationResult =
  Apollo.MutationResult<SignupWithMailAddressMutation>;
export type SignupWithMailAddressMutationOptions = Apollo.BaseMutationOptions<
  SignupWithMailAddressMutation,
  SignupWithMailAddressMutationVariables
>;
export const StartTaskDocument = gql`
  mutation startTask($id: ID!, $input: StartTaskInputInput!) {
    startTask(id: $id, input: $input) {
      startTask {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        asssignee {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        calendarLinkageCode
        completeDateTime
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        estimatedRemainingTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        laborCost
        priority
        progressDeviationRate
        progressRate
        project {
          assignees {
            sortNo
          }
          calendarLinkageCode
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          estimateTimeSec
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            sortNo
          }
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          projectStatus {
            endStatus
            id
            name
            sortNo
            teamId
            versionNo
          }
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          team {
            archived
            id
            name
            organization {
              allowConnectThirdPartyCalendar
              contactMailAddress
              contractPreriod
              id
              imageUrl
              ipRestrictionFlg
              name
              plan {
                code
                contractPreriod
                id
              }
              requiredTwoFactorAuth
              suspensionFlg
              versionNo
            }
            personalTeam
            status
            versionNo
          }
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        registerDateTime
        remainingTimeSec
        scheduleWorkingTimeSec
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInMyTask
        sortNoInTaskStatus
        taskStatus {
          endStatus
          id
          name
          project {
            calendarLinkageCode
            color
            complete
            completeDateTime
            costBudgetWithoutTax
            description
            estimateTimeSec
            id
            key
            laborCost
            laborCostWithoutTax
            laborShare
            name
            priority
            registerDateTime
            salesAmountWithoutTax
            scheduledEndDateTime
            scheduledStartDateTime
            sortNoInGanttChart
            sortNoInList
            sortNoInProjectStatus
            totalScheduleTimeSec
            versionNo
            workingTimeSec
          }
          sortNo
          versionNo
        }
        title
        versionNo
        workingMembers {
          lastWorkingStartDateTime
          lastWorkingStopDateTime
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
        }
        workingTimeSec
      }
      startWorkingHistory {
        calendarEvent {
          calendarType
          clientId
          clientName
          endDateTime
          eventName
          id
          laborCost
          personalTeam
          projectColor
          projectId
          projectName
          startDateTime
          taskId
          taskTitle
          teamId
          teamName
          thirdPartyCalendarEventId
          thirdPartyCalendarInfoList {
            member {
              adminRole
              clientManagementRole
              department
              employeeNumber
              id
              laborCostManagementRole
              mailAddress
              memberManagementRole
              memberRegistrationDateTime
              memberStatus
              name
              organizationMemberViewRole
              organizationReportRole
              profileImageUrl
              projectCreatePermissionFlg
              projectCustomAttributePermissionFlg
              projectDeletePermissionFlg
              projectUpdatePermissionFlg
              proxyEditWorkingDataRole
              salesManagementRole
              statusMessage
              taskCreatePermissionFlg
              taskCustomAttributePermissionFlg
              taskDeletePermissionFlg
              taskUpdatePermissionFlg
              teamMemberViewRole
              teamReportRole
              versionNo
            }
            thirdPartyCalendarId
          }
          versionNo
          workingTimeSec
        }
        closingProcessInfo {
          closed
          targetDateTime
        }
        end
        id
        memo
        start
        task {
          assignees {
            sortNo
          }
          asssignee {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          calendarLinkageCode
          completeDateTime
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          estimateTimeSec
          estimatedRemainingTimeSec
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            sortNo
          }
          id
          laborCost
          priority
          progressDeviationRate
          progressRate
          project {
            calendarLinkageCode
            color
            complete
            completeDateTime
            costBudgetWithoutTax
            description
            estimateTimeSec
            id
            key
            laborCost
            laborCostWithoutTax
            laborShare
            name
            priority
            registerDateTime
            salesAmountWithoutTax
            scheduledEndDateTime
            scheduledStartDateTime
            sortNoInGanttChart
            sortNoInList
            sortNoInProjectStatus
            totalScheduleTimeSec
            versionNo
            workingTimeSec
          }
          registerDateTime
          remainingTimeSec
          scheduleWorkingTimeSec
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInMyTask
          sortNoInTaskStatus
          taskStatus {
            endStatus
            id
            name
            sortNo
            versionNo
          }
          title
          versionNo
          workingMembers {
            lastWorkingStartDateTime
            lastWorkingStopDateTime
          }
          workingTimeSec
        }
        versionNo
        workingMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        workingTimeSec
      }
      stopTask {
        assignees {
          sortNo
        }
        asssignee {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        calendarLinkageCode
        completeDateTime
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        estimatedRemainingTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          sortNo
        }
        id
        laborCost
        priority
        progressDeviationRate
        progressRate
        project {
          calendarLinkageCode
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          description
          estimateTimeSec
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        registerDateTime
        remainingTimeSec
        scheduleWorkingTimeSec
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInMyTask
        sortNoInTaskStatus
        taskStatus {
          endStatus
          id
          name
          sortNo
          versionNo
        }
        title
        versionNo
        workingMembers {
          lastWorkingStartDateTime
          lastWorkingStopDateTime
        }
        workingTimeSec
      }
      stopWorkingHistory {
        calendarEvent {
          calendarType
          clientId
          clientName
          endDateTime
          eventName
          id
          laborCost
          personalTeam
          projectColor
          projectId
          projectName
          startDateTime
          taskId
          taskTitle
          teamId
          teamName
          thirdPartyCalendarEventId
          versionNo
          workingTimeSec
        }
        closingProcessInfo {
          closed
          targetDateTime
        }
        end
        id
        memo
        start
        task {
          calendarLinkageCode
          completeDateTime
          description
          estimateTimeSec
          estimatedRemainingTimeSec
          id
          laborCost
          priority
          progressDeviationRate
          progressRate
          registerDateTime
          remainingTimeSec
          scheduleWorkingTimeSec
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInMyTask
          sortNoInTaskStatus
          title
          versionNo
          workingTimeSec
        }
        versionNo
        workingMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        workingTimeSec
      }
    }
  }
`;
export type StartTaskMutationFn = Apollo.MutationFunction<
  StartTaskMutation,
  StartTaskMutationVariables
>;

/**
 * __useStartTaskMutation__
 *
 * To run a mutation, you first call `useStartTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startTaskMutation, { data, loading, error }] = useStartTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<StartTaskMutation, StartTaskMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<StartTaskMutation, StartTaskMutationVariables>(
    StartTaskDocument,
    options
  );
}
export type StartTaskMutationHookResult = ReturnType<typeof useStartTaskMutation>;
export type StartTaskMutationResult = Apollo.MutationResult<StartTaskMutation>;
export type StartTaskMutationOptions = Apollo.BaseMutationOptions<
  StartTaskMutation,
  StartTaskMutationVariables
>;
export const StopTaskDocument = gql`
  mutation stopTask($id: ID!, $input: StopTaskInputInput!) {
    stopTask(id: $id, input: $input) {
      stopTask {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        asssignee {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        calendarLinkageCode
        completeDateTime
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        estimatedRemainingTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        laborCost
        priority
        progressDeviationRate
        progressRate
        project {
          assignees {
            sortNo
          }
          calendarLinkageCode
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          estimateTimeSec
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            sortNo
          }
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          projectStatus {
            endStatus
            id
            name
            sortNo
            teamId
            versionNo
          }
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          team {
            archived
            id
            name
            organization {
              allowConnectThirdPartyCalendar
              contactMailAddress
              contractPreriod
              id
              imageUrl
              ipRestrictionFlg
              name
              plan {
                code
                contractPreriod
                id
              }
              requiredTwoFactorAuth
              suspensionFlg
              versionNo
            }
            personalTeam
            status
            versionNo
          }
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        registerDateTime
        remainingTimeSec
        scheduleWorkingTimeSec
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInMyTask
        sortNoInTaskStatus
        taskStatus {
          endStatus
          id
          name
          project {
            calendarLinkageCode
            color
            complete
            completeDateTime
            costBudgetWithoutTax
            description
            estimateTimeSec
            id
            key
            laborCost
            laborCostWithoutTax
            laborShare
            name
            priority
            registerDateTime
            salesAmountWithoutTax
            scheduledEndDateTime
            scheduledStartDateTime
            sortNoInGanttChart
            sortNoInList
            sortNoInProjectStatus
            totalScheduleTimeSec
            versionNo
            workingTimeSec
          }
          sortNo
          versionNo
        }
        title
        versionNo
        workingMembers {
          lastWorkingStartDateTime
          lastWorkingStopDateTime
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
        }
        workingTimeSec
      }
      stopWorkingHistory {
        calendarEvent {
          calendarType
          clientId
          clientName
          endDateTime
          eventName
          id
          laborCost
          personalTeam
          projectColor
          projectId
          projectName
          startDateTime
          taskId
          taskTitle
          teamId
          teamName
          thirdPartyCalendarEventId
          thirdPartyCalendarInfoList {
            member {
              adminRole
              clientManagementRole
              department
              employeeNumber
              id
              laborCostManagementRole
              mailAddress
              memberManagementRole
              memberRegistrationDateTime
              memberStatus
              name
              organizationMemberViewRole
              organizationReportRole
              profileImageUrl
              projectCreatePermissionFlg
              projectCustomAttributePermissionFlg
              projectDeletePermissionFlg
              projectUpdatePermissionFlg
              proxyEditWorkingDataRole
              salesManagementRole
              statusMessage
              taskCreatePermissionFlg
              taskCustomAttributePermissionFlg
              taskDeletePermissionFlg
              taskUpdatePermissionFlg
              teamMemberViewRole
              teamReportRole
              versionNo
            }
            thirdPartyCalendarId
          }
          versionNo
          workingTimeSec
        }
        closingProcessInfo {
          closed
          targetDateTime
        }
        end
        id
        memo
        start
        task {
          assignees {
            sortNo
          }
          asssignee {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          calendarLinkageCode
          completeDateTime
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          estimateTimeSec
          estimatedRemainingTimeSec
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            sortNo
          }
          id
          laborCost
          priority
          progressDeviationRate
          progressRate
          project {
            calendarLinkageCode
            color
            complete
            completeDateTime
            costBudgetWithoutTax
            description
            estimateTimeSec
            id
            key
            laborCost
            laborCostWithoutTax
            laborShare
            name
            priority
            registerDateTime
            salesAmountWithoutTax
            scheduledEndDateTime
            scheduledStartDateTime
            sortNoInGanttChart
            sortNoInList
            sortNoInProjectStatus
            totalScheduleTimeSec
            versionNo
            workingTimeSec
          }
          registerDateTime
          remainingTimeSec
          scheduleWorkingTimeSec
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInMyTask
          sortNoInTaskStatus
          taskStatus {
            endStatus
            id
            name
            sortNo
            versionNo
          }
          title
          versionNo
          workingMembers {
            lastWorkingStartDateTime
            lastWorkingStopDateTime
          }
          workingTimeSec
        }
        versionNo
        workingMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        workingTimeSec
      }
      stopWorkingHistoryCalendar {
        calendarEvent {
          calendarType
          clientId
          clientName
          endDateTime
          eventName
          id
          personalTeam
          projectColor
          projectId
          projectName
          startDateTime
          taskId
          taskTitle
          teamId
          teamName
          thirdPartyCalendarEventId
          versionNo
        }
        end
        id
        memo
        start
        task {
          completeDateTime
          id
          title
          versionNo
        }
        versionNo
        workingMember {
          id
          name
          profileImageUrl
        }
        workingTimeSec
      }
      stopWorkingHistorySummary {
        calendarEvent {
          clientId
          clientName
          eventName
          id
          projectId
          projectName
          teamId
        }
        closingProcessInfo {
          closed
          targetDateTime
        }
        end
        id
        memo
        start
        task {
          id
          title
          versionNo
        }
        versionNo
        workingMember {
          id
          name
          profileImageUrl
        }
        workingTimeSec
      }
    }
  }
`;
export type StopTaskMutationFn = Apollo.MutationFunction<
  StopTaskMutation,
  StopTaskMutationVariables
>;

/**
 * __useStopTaskMutation__
 *
 * To run a mutation, you first call `useStopTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopTaskMutation, { data, loading, error }] = useStopTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStopTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<StopTaskMutation, StopTaskMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<StopTaskMutation, StopTaskMutationVariables>(StopTaskDocument, options);
}
export type StopTaskMutationHookResult = ReturnType<typeof useStopTaskMutation>;
export type StopTaskMutationResult = Apollo.MutationResult<StopTaskMutation>;
export type StopTaskMutationOptions = Apollo.BaseMutationOptions<
  StopTaskMutation,
  StopTaskMutationVariables
>;
export const SyncRegisterWorkingHistoriesFromGoogleCalendarsDocument = gql`
  mutation syncRegisterWorkingHistoriesFromGoogleCalendars(
    $input: RegisterWorkingHistoriesFromGoogleCallendarEventsInputInput!
  ) {
    syncRegisterWorkingHistoriesFromGoogleCalendars(input: $input) {
      calendarEvent {
        calendarType
        clientId
        clientName
        endDateTime
        eventName
        id
        laborCost
        personalTeam
        projectColor
        projectId
        projectName
        startDateTime
        taskId
        taskTitle
        teamId
        teamName
        thirdPartyCalendarEventId
        thirdPartyCalendarInfoList {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          thirdPartyCalendarId
        }
        versionNo
        workingTimeSec
      }
      closingProcessInfo {
        closed
        targetDateTime
      }
      end
      id
      memo
      start
      task {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        asssignee {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        calendarLinkageCode
        completeDateTime
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        estimatedRemainingTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        laborCost
        priority
        progressDeviationRate
        progressRate
        project {
          assignees {
            sortNo
          }
          calendarLinkageCode
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          estimateTimeSec
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            sortNo
          }
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          projectStatus {
            endStatus
            id
            name
            sortNo
            teamId
            versionNo
          }
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          team {
            archived
            id
            name
            organization {
              allowConnectThirdPartyCalendar
              contactMailAddress
              contractPreriod
              id
              imageUrl
              ipRestrictionFlg
              name
              plan {
                code
                contractPreriod
                id
              }
              requiredTwoFactorAuth
              suspensionFlg
              versionNo
            }
            personalTeam
            status
            versionNo
          }
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        registerDateTime
        remainingTimeSec
        scheduleWorkingTimeSec
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInMyTask
        sortNoInTaskStatus
        taskStatus {
          endStatus
          id
          name
          project {
            calendarLinkageCode
            color
            complete
            completeDateTime
            costBudgetWithoutTax
            description
            estimateTimeSec
            id
            key
            laborCost
            laborCostWithoutTax
            laborShare
            name
            priority
            registerDateTime
            salesAmountWithoutTax
            scheduledEndDateTime
            scheduledStartDateTime
            sortNoInGanttChart
            sortNoInList
            sortNoInProjectStatus
            totalScheduleTimeSec
            versionNo
            workingTimeSec
          }
          sortNo
          versionNo
        }
        title
        versionNo
        workingMembers {
          lastWorkingStartDateTime
          lastWorkingStopDateTime
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
        }
        workingTimeSec
      }
      versionNo
      workingMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      workingTimeSec
    }
  }
`;
export type SyncRegisterWorkingHistoriesFromGoogleCalendarsMutationFn = Apollo.MutationFunction<
  SyncRegisterWorkingHistoriesFromGoogleCalendarsMutation,
  SyncRegisterWorkingHistoriesFromGoogleCalendarsMutationVariables
>;

/**
 * __useSyncRegisterWorkingHistoriesFromGoogleCalendarsMutation__
 *
 * To run a mutation, you first call `useSyncRegisterWorkingHistoriesFromGoogleCalendarsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncRegisterWorkingHistoriesFromGoogleCalendarsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncRegisterWorkingHistoriesFromGoogleCalendarsMutation, { data, loading, error }] = useSyncRegisterWorkingHistoriesFromGoogleCalendarsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSyncRegisterWorkingHistoriesFromGoogleCalendarsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyncRegisterWorkingHistoriesFromGoogleCalendarsMutation,
    SyncRegisterWorkingHistoriesFromGoogleCalendarsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SyncRegisterWorkingHistoriesFromGoogleCalendarsMutation,
    SyncRegisterWorkingHistoriesFromGoogleCalendarsMutationVariables
  >(SyncRegisterWorkingHistoriesFromGoogleCalendarsDocument, options);
}
export type SyncRegisterWorkingHistoriesFromGoogleCalendarsMutationHookResult = ReturnType<
  typeof useSyncRegisterWorkingHistoriesFromGoogleCalendarsMutation
>;
export type SyncRegisterWorkingHistoriesFromGoogleCalendarsMutationResult =
  Apollo.MutationResult<SyncRegisterWorkingHistoriesFromGoogleCalendarsMutation>;
export type SyncRegisterWorkingHistoriesFromGoogleCalendarsMutationOptions =
  Apollo.BaseMutationOptions<
    SyncRegisterWorkingHistoriesFromGoogleCalendarsMutation,
    SyncRegisterWorkingHistoriesFromGoogleCalendarsMutationVariables
  >;
export const SyncRegisterWorkingSchedulesFromGoogleCalendarsDocument = gql`
  mutation syncRegisterWorkingSchedulesFromGoogleCalendars(
    $input: RegisterWorkingSchedulesFromGoogleCallendarEventsInputInput!
  ) {
    syncRegisterWorkingSchedulesFromGoogleCalendars(input: $input) {
      calendarEvent {
        calendarType
        clientId
        clientName
        endDateTime
        eventName
        id
        laborCost
        personalTeam
        projectColor
        projectId
        projectName
        startDateTime
        taskId
        taskTitle
        teamId
        teamName
        thirdPartyCalendarEventId
        thirdPartyCalendarInfoList {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          thirdPartyCalendarId
        }
        versionNo
        workingTimeSec
      }
      end
      id
      memo
      start
      task {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        asssignee {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        calendarLinkageCode
        completeDateTime
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        estimatedRemainingTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        laborCost
        priority
        progressDeviationRate
        progressRate
        project {
          assignees {
            sortNo
          }
          calendarLinkageCode
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          estimateTimeSec
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            sortNo
          }
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          projectStatus {
            endStatus
            id
            name
            sortNo
            teamId
            versionNo
          }
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          team {
            archived
            id
            name
            organization {
              allowConnectThirdPartyCalendar
              contactMailAddress
              contractPreriod
              id
              imageUrl
              ipRestrictionFlg
              name
              plan {
                code
                contractPreriod
                id
              }
              requiredTwoFactorAuth
              suspensionFlg
              versionNo
            }
            personalTeam
            status
            versionNo
          }
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        registerDateTime
        remainingTimeSec
        scheduleWorkingTimeSec
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInMyTask
        sortNoInTaskStatus
        taskStatus {
          endStatus
          id
          name
          project {
            calendarLinkageCode
            color
            complete
            completeDateTime
            costBudgetWithoutTax
            description
            estimateTimeSec
            id
            key
            laborCost
            laborCostWithoutTax
            laborShare
            name
            priority
            registerDateTime
            salesAmountWithoutTax
            scheduledEndDateTime
            scheduledStartDateTime
            sortNoInGanttChart
            sortNoInList
            sortNoInProjectStatus
            totalScheduleTimeSec
            versionNo
            workingTimeSec
          }
          sortNo
          versionNo
        }
        title
        versionNo
        workingMembers {
          lastWorkingStartDateTime
          lastWorkingStopDateTime
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
        }
        workingTimeSec
      }
      versionNo
      workingMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      workingTimeSec
    }
  }
`;
export type SyncRegisterWorkingSchedulesFromGoogleCalendarsMutationFn = Apollo.MutationFunction<
  SyncRegisterWorkingSchedulesFromGoogleCalendarsMutation,
  SyncRegisterWorkingSchedulesFromGoogleCalendarsMutationVariables
>;

/**
 * __useSyncRegisterWorkingSchedulesFromGoogleCalendarsMutation__
 *
 * To run a mutation, you first call `useSyncRegisterWorkingSchedulesFromGoogleCalendarsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncRegisterWorkingSchedulesFromGoogleCalendarsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncRegisterWorkingSchedulesFromGoogleCalendarsMutation, { data, loading, error }] = useSyncRegisterWorkingSchedulesFromGoogleCalendarsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSyncRegisterWorkingSchedulesFromGoogleCalendarsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyncRegisterWorkingSchedulesFromGoogleCalendarsMutation,
    SyncRegisterWorkingSchedulesFromGoogleCalendarsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SyncRegisterWorkingSchedulesFromGoogleCalendarsMutation,
    SyncRegisterWorkingSchedulesFromGoogleCalendarsMutationVariables
  >(SyncRegisterWorkingSchedulesFromGoogleCalendarsDocument, options);
}
export type SyncRegisterWorkingSchedulesFromGoogleCalendarsMutationHookResult = ReturnType<
  typeof useSyncRegisterWorkingSchedulesFromGoogleCalendarsMutation
>;
export type SyncRegisterWorkingSchedulesFromGoogleCalendarsMutationResult =
  Apollo.MutationResult<SyncRegisterWorkingSchedulesFromGoogleCalendarsMutation>;
export type SyncRegisterWorkingSchedulesFromGoogleCalendarsMutationOptions =
  Apollo.BaseMutationOptions<
    SyncRegisterWorkingSchedulesFromGoogleCalendarsMutation,
    SyncRegisterWorkingSchedulesFromGoogleCalendarsMutationVariables
  >;
export const TeamCloseTermDocument = gql`
  mutation teamCloseTerm($input: TeamCloseInputInput) {
    teamCloseTerm(input: $input) {
      closingType
      id
      targetDateTime
      teamId
      timeZone {
        code
        id
        name
        offsetTime
        sortNo
      }
      versionNo
    }
  }
`;
export type TeamCloseTermMutationFn = Apollo.MutationFunction<
  TeamCloseTermMutation,
  TeamCloseTermMutationVariables
>;

/**
 * __useTeamCloseTermMutation__
 *
 * To run a mutation, you first call `useTeamCloseTermMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeamCloseTermMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teamCloseTermMutation, { data, loading, error }] = useTeamCloseTermMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTeamCloseTermMutation(
  baseOptions?: Apollo.MutationHookOptions<TeamCloseTermMutation, TeamCloseTermMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TeamCloseTermMutation, TeamCloseTermMutationVariables>(
    TeamCloseTermDocument,
    options
  );
}
export type TeamCloseTermMutationHookResult = ReturnType<typeof useTeamCloseTermMutation>;
export type TeamCloseTermMutationResult = Apollo.MutationResult<TeamCloseTermMutation>;
export type TeamCloseTermMutationOptions = Apollo.BaseMutationOptions<
  TeamCloseTermMutation,
  TeamCloseTermMutationVariables
>;
export const TwoFactorLoginDocument = gql`
  mutation twoFactorLogin($input: TwoFactorLoginInputInput!) {
    twoFactorLogin(input: $input)
  }
`;
export type TwoFactorLoginMutationFn = Apollo.MutationFunction<
  TwoFactorLoginMutation,
  TwoFactorLoginMutationVariables
>;

/**
 * __useTwoFactorLoginMutation__
 *
 * To run a mutation, you first call `useTwoFactorLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTwoFactorLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [twoFactorLoginMutation, { data, loading, error }] = useTwoFactorLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTwoFactorLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<TwoFactorLoginMutation, TwoFactorLoginMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TwoFactorLoginMutation, TwoFactorLoginMutationVariables>(
    TwoFactorLoginDocument,
    options
  );
}
export type TwoFactorLoginMutationHookResult = ReturnType<typeof useTwoFactorLoginMutation>;
export type TwoFactorLoginMutationResult = Apollo.MutationResult<TwoFactorLoginMutation>;
export type TwoFactorLoginMutationOptions = Apollo.BaseMutationOptions<
  TwoFactorLoginMutation,
  TwoFactorLoginMutationVariables
>;
export const TwoFactorRecoveryCodeLoginDocument = gql`
  mutation twoFactorRecoveryCodeLogin($input: TwoFactorRecoveryCodeLoginInputInput!) {
    twoFactorRecoveryCodeLogin(input: $input)
  }
`;
export type TwoFactorRecoveryCodeLoginMutationFn = Apollo.MutationFunction<
  TwoFactorRecoveryCodeLoginMutation,
  TwoFactorRecoveryCodeLoginMutationVariables
>;

/**
 * __useTwoFactorRecoveryCodeLoginMutation__
 *
 * To run a mutation, you first call `useTwoFactorRecoveryCodeLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTwoFactorRecoveryCodeLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [twoFactorRecoveryCodeLoginMutation, { data, loading, error }] = useTwoFactorRecoveryCodeLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTwoFactorRecoveryCodeLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TwoFactorRecoveryCodeLoginMutation,
    TwoFactorRecoveryCodeLoginMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TwoFactorRecoveryCodeLoginMutation,
    TwoFactorRecoveryCodeLoginMutationVariables
  >(TwoFactorRecoveryCodeLoginDocument, options);
}
export type TwoFactorRecoveryCodeLoginMutationHookResult = ReturnType<
  typeof useTwoFactorRecoveryCodeLoginMutation
>;
export type TwoFactorRecoveryCodeLoginMutationResult =
  Apollo.MutationResult<TwoFactorRecoveryCodeLoginMutation>;
export type TwoFactorRecoveryCodeLoginMutationOptions = Apollo.BaseMutationOptions<
  TwoFactorRecoveryCodeLoginMutation,
  TwoFactorRecoveryCodeLoginMutationVariables
>;
export const UnarchiveTeamDocument = gql`
  mutation unarchiveTeam($id: ID!, $input: UnarchiveTeamInputInput!) {
    unarchiveTeam(id: $id, input: $input) {
      archived
      id
      name
      organization {
        allowConnectThirdPartyCalendar
        contactMailAddress
        contractPreriod
        id
        imageUrl
        ipRestrictionFlg
        name
        plan {
          code
          contractPreriod
          id
        }
        requiredTwoFactorAuth
        suspensionFlg
        versionNo
      }
      personalTeam
      status
      versionNo
    }
  }
`;
export type UnarchiveTeamMutationFn = Apollo.MutationFunction<
  UnarchiveTeamMutation,
  UnarchiveTeamMutationVariables
>;

/**
 * __useUnarchiveTeamMutation__
 *
 * To run a mutation, you first call `useUnarchiveTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnarchiveTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unarchiveTeamMutation, { data, loading, error }] = useUnarchiveTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnarchiveTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<UnarchiveTeamMutation, UnarchiveTeamMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnarchiveTeamMutation, UnarchiveTeamMutationVariables>(
    UnarchiveTeamDocument,
    options
  );
}
export type UnarchiveTeamMutationHookResult = ReturnType<typeof useUnarchiveTeamMutation>;
export type UnarchiveTeamMutationResult = Apollo.MutationResult<UnarchiveTeamMutation>;
export type UnarchiveTeamMutationOptions = Apollo.BaseMutationOptions<
  UnarchiveTeamMutation,
  UnarchiveTeamMutationVariables
>;
export const UpdateAccessAllowIpAddressesDocument = gql`
  mutation updateAccessAllowIpAddresses($input: UpdateAccessAllowIpAddressInputInput) {
    updateAccessAllowIpAddresses(input: $input) {
      enable
      id
      ipAddresses
      versionNo
    }
  }
`;
export type UpdateAccessAllowIpAddressesMutationFn = Apollo.MutationFunction<
  UpdateAccessAllowIpAddressesMutation,
  UpdateAccessAllowIpAddressesMutationVariables
>;

/**
 * __useUpdateAccessAllowIpAddressesMutation__
 *
 * To run a mutation, you first call `useUpdateAccessAllowIpAddressesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccessAllowIpAddressesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccessAllowIpAddressesMutation, { data, loading, error }] = useUpdateAccessAllowIpAddressesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccessAllowIpAddressesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAccessAllowIpAddressesMutation,
    UpdateAccessAllowIpAddressesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAccessAllowIpAddressesMutation,
    UpdateAccessAllowIpAddressesMutationVariables
  >(UpdateAccessAllowIpAddressesDocument, options);
}
export type UpdateAccessAllowIpAddressesMutationHookResult = ReturnType<
  typeof useUpdateAccessAllowIpAddressesMutation
>;
export type UpdateAccessAllowIpAddressesMutationResult =
  Apollo.MutationResult<UpdateAccessAllowIpAddressesMutation>;
export type UpdateAccessAllowIpAddressesMutationOptions = Apollo.BaseMutationOptions<
  UpdateAccessAllowIpAddressesMutation,
  UpdateAccessAllowIpAddressesMutationVariables
>;
export const UpdateBillToMailAddressDocument = gql`
  mutation updateBillToMailAddress($id: ID!, $input: UpdateBillToMailAddressInput) {
    updateBillToMailAddress(id: $id, input: $input) {
      billToMailAddress
      billingType
      contractPreriod
      freeLicenceCoupon {
        freeEndLicenceCount
        freeStartLicenceCount
        id
      }
      freeTrialExpireDatetime
      id
      licenceCount
      plan {
        code
        contractPreriod
        id
      }
      revenuecatOriginalAppUserId
      usingLicenceCount
      versionNo
    }
  }
`;
export type UpdateBillToMailAddressMutationFn = Apollo.MutationFunction<
  UpdateBillToMailAddressMutation,
  UpdateBillToMailAddressMutationVariables
>;

/**
 * __useUpdateBillToMailAddressMutation__
 *
 * To run a mutation, you first call `useUpdateBillToMailAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBillToMailAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBillToMailAddressMutation, { data, loading, error }] = useUpdateBillToMailAddressMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBillToMailAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBillToMailAddressMutation,
    UpdateBillToMailAddressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateBillToMailAddressMutation,
    UpdateBillToMailAddressMutationVariables
  >(UpdateBillToMailAddressDocument, options);
}
export type UpdateBillToMailAddressMutationHookResult = ReturnType<
  typeof useUpdateBillToMailAddressMutation
>;
export type UpdateBillToMailAddressMutationResult =
  Apollo.MutationResult<UpdateBillToMailAddressMutation>;
export type UpdateBillToMailAddressMutationOptions = Apollo.BaseMutationOptions<
  UpdateBillToMailAddressMutation,
  UpdateBillToMailAddressMutationVariables
>;
export const UpdateCalendarEventDocument = gql`
  mutation updateCalendarEvent($id: String!, $input: UpdateCalendarEventInputInput!) {
    updateCalendarEvent(id: $id, input: $input) {
      calendarType
      clientId
      clientName
      endDateTime
      eventName
      id
      laborCost
      personalTeam
      projectColor
      projectId
      projectName
      startDateTime
      taskId
      taskTitle
      teamId
      teamName
      thirdPartyCalendarEventId
      thirdPartyCalendarInfoList {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        thirdPartyCalendarId
      }
      versionNo
      workingTimeSec
    }
  }
`;
export type UpdateCalendarEventMutationFn = Apollo.MutationFunction<
  UpdateCalendarEventMutation,
  UpdateCalendarEventMutationVariables
>;

/**
 * __useUpdateCalendarEventMutation__
 *
 * To run a mutation, you first call `useUpdateCalendarEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCalendarEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCalendarEventMutation, { data, loading, error }] = useUpdateCalendarEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCalendarEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCalendarEventMutation,
    UpdateCalendarEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCalendarEventMutation, UpdateCalendarEventMutationVariables>(
    UpdateCalendarEventDocument,
    options
  );
}
export type UpdateCalendarEventMutationHookResult = ReturnType<
  typeof useUpdateCalendarEventMutation
>;
export type UpdateCalendarEventMutationResult = Apollo.MutationResult<UpdateCalendarEventMutation>;
export type UpdateCalendarEventMutationOptions = Apollo.BaseMutationOptions<
  UpdateCalendarEventMutation,
  UpdateCalendarEventMutationVariables
>;
export const UpdateClientDocument = gql`
  mutation updateClient($id: ID!, $input: UpdateClientInputInput!) {
    updateClient(id: $id, input: $input) {
      code
      id
      name
      sortNo
      versionNo
    }
  }
`;
export type UpdateClientMutationFn = Apollo.MutationFunction<
  UpdateClientMutation,
  UpdateClientMutationVariables
>;

/**
 * __useUpdateClientMutation__
 *
 * To run a mutation, you first call `useUpdateClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientMutation, { data, loading, error }] = useUpdateClientMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateClientMutation, UpdateClientMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateClientMutation, UpdateClientMutationVariables>(
    UpdateClientDocument,
    options
  );
}
export type UpdateClientMutationHookResult = ReturnType<typeof useUpdateClientMutation>;
export type UpdateClientMutationResult = Apollo.MutationResult<UpdateClientMutation>;
export type UpdateClientMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientMutation,
  UpdateClientMutationVariables
>;
export const UpdateClientSortNoDocument = gql`
  mutation updateClientSortNo($id: ID!, $input: UpdateClientSortNoInputInput) {
    updateClientSortNo(id: $id, input: $input) {
      code
      id
      name
      sortNo
      versionNo
    }
  }
`;
export type UpdateClientSortNoMutationFn = Apollo.MutationFunction<
  UpdateClientSortNoMutation,
  UpdateClientSortNoMutationVariables
>;

/**
 * __useUpdateClientSortNoMutation__
 *
 * To run a mutation, you first call `useUpdateClientSortNoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientSortNoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientSortNoMutation, { data, loading, error }] = useUpdateClientSortNoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientSortNoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientSortNoMutation,
    UpdateClientSortNoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateClientSortNoMutation, UpdateClientSortNoMutationVariables>(
    UpdateClientSortNoDocument,
    options
  );
}
export type UpdateClientSortNoMutationHookResult = ReturnType<typeof useUpdateClientSortNoMutation>;
export type UpdateClientSortNoMutationResult = Apollo.MutationResult<UpdateClientSortNoMutation>;
export type UpdateClientSortNoMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientSortNoMutation,
  UpdateClientSortNoMutationVariables
>;
export const UpdateCsvReportItemDefinitionDocument = gql`
  mutation updateCsvReportItemDefinition(
    $id: ID!
    $input: UpdateCsvReportItemDefinitionInputInput!
  ) {
    updateCsvReportItemDefinition(id: $id, input: $input) {
      id
      items {
        id
        reportItemCode
        reportItemName
        sortNo
      }
      name
      versionNo
    }
  }
`;
export type UpdateCsvReportItemDefinitionMutationFn = Apollo.MutationFunction<
  UpdateCsvReportItemDefinitionMutation,
  UpdateCsvReportItemDefinitionMutationVariables
>;

/**
 * __useUpdateCsvReportItemDefinitionMutation__
 *
 * To run a mutation, you first call `useUpdateCsvReportItemDefinitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCsvReportItemDefinitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCsvReportItemDefinitionMutation, { data, loading, error }] = useUpdateCsvReportItemDefinitionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCsvReportItemDefinitionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCsvReportItemDefinitionMutation,
    UpdateCsvReportItemDefinitionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCsvReportItemDefinitionMutation,
    UpdateCsvReportItemDefinitionMutationVariables
  >(UpdateCsvReportItemDefinitionDocument, options);
}
export type UpdateCsvReportItemDefinitionMutationHookResult = ReturnType<
  typeof useUpdateCsvReportItemDefinitionMutation
>;
export type UpdateCsvReportItemDefinitionMutationResult =
  Apollo.MutationResult<UpdateCsvReportItemDefinitionMutation>;
export type UpdateCsvReportItemDefinitionMutationOptions = Apollo.BaseMutationOptions<
  UpdateCsvReportItemDefinitionMutation,
  UpdateCsvReportItemDefinitionMutationVariables
>;
export const UpdateCsvReportItemSortNoDocument = gql`
  mutation updateCsvReportItemSortNo($id: ID!, $input: UpdateCsvDefinitionDetailSortNoInputInput!) {
    updateCsvReportItemSortNo(id: $id, input: $input) {
      id
      items {
        id
        reportItemCode
        reportItemName
        sortNo
      }
      name
      versionNo
    }
  }
`;
export type UpdateCsvReportItemSortNoMutationFn = Apollo.MutationFunction<
  UpdateCsvReportItemSortNoMutation,
  UpdateCsvReportItemSortNoMutationVariables
>;

/**
 * __useUpdateCsvReportItemSortNoMutation__
 *
 * To run a mutation, you first call `useUpdateCsvReportItemSortNoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCsvReportItemSortNoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCsvReportItemSortNoMutation, { data, loading, error }] = useUpdateCsvReportItemSortNoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCsvReportItemSortNoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCsvReportItemSortNoMutation,
    UpdateCsvReportItemSortNoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCsvReportItemSortNoMutation,
    UpdateCsvReportItemSortNoMutationVariables
  >(UpdateCsvReportItemSortNoDocument, options);
}
export type UpdateCsvReportItemSortNoMutationHookResult = ReturnType<
  typeof useUpdateCsvReportItemSortNoMutation
>;
export type UpdateCsvReportItemSortNoMutationResult =
  Apollo.MutationResult<UpdateCsvReportItemSortNoMutation>;
export type UpdateCsvReportItemSortNoMutationOptions = Apollo.BaseMutationOptions<
  UpdateCsvReportItemSortNoMutation,
  UpdateCsvReportItemSortNoMutationVariables
>;
export const UpdateGoogleCalendarEventDocument = gql`
  mutation updateGoogleCalendarEvent($id: ID!, $input: UpdateGoogleCalendarEventInputInput!) {
    updateGoogleCalendarEvent(id: $id, input: $input) {
      allDay
      attendees {
        mailAddress
        name
        responseStatus
        self
      }
      backgroundColor
      calendarId
      colorId
      dateWhenAllDay
      description
      endDateTime
      foregroundColor
      id
      location
      organizer {
        mailAddress
        name
      }
      readonly
      startDateTime
      status
      targetMemberId
      title
      visivility
    }
  }
`;
export type UpdateGoogleCalendarEventMutationFn = Apollo.MutationFunction<
  UpdateGoogleCalendarEventMutation,
  UpdateGoogleCalendarEventMutationVariables
>;

/**
 * __useUpdateGoogleCalendarEventMutation__
 *
 * To run a mutation, you first call `useUpdateGoogleCalendarEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGoogleCalendarEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGoogleCalendarEventMutation, { data, loading, error }] = useUpdateGoogleCalendarEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGoogleCalendarEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateGoogleCalendarEventMutation,
    UpdateGoogleCalendarEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateGoogleCalendarEventMutation,
    UpdateGoogleCalendarEventMutationVariables
  >(UpdateGoogleCalendarEventDocument, options);
}
export type UpdateGoogleCalendarEventMutationHookResult = ReturnType<
  typeof useUpdateGoogleCalendarEventMutation
>;
export type UpdateGoogleCalendarEventMutationResult =
  Apollo.MutationResult<UpdateGoogleCalendarEventMutation>;
export type UpdateGoogleCalendarEventMutationOptions = Apollo.BaseMutationOptions<
  UpdateGoogleCalendarEventMutation,
  UpdateGoogleCalendarEventMutationVariables
>;
export const UpdateInvitationDocument = gql`
  mutation updateInvitation($id: ID!, $input: UpdateMemberInvitationInputInput!) {
    updateInvitation(id: $id, input: $input) {
      adminRole
      allowThirdPartyCalendarConnect
      clientManagementRole
      createMemberId
      department
      employeeNumber
      id
      invitationKey
      laborCostManagementRole
      mailAddress
      memberManagementRole
      name
      organizationId
      organizationMemberViewRole
      organizationName
      organizationReportRole
      projectCreatePermissionFlg
      projectCustomAttributePermissionFlg
      projectDeletePermissionFlg
      projectUpdatePermissionFlg
      proxyEditWorkingDataRole
      salesManagementRole
      taskCreatePermissionFlg
      taskCustomAttributePermissionFlg
      taskDeletePermissionFlg
      taskUpdatePermissionFlg
      teamMemberViewRole
      teamReportRole
      versionNo
    }
  }
`;
export type UpdateInvitationMutationFn = Apollo.MutationFunction<
  UpdateInvitationMutation,
  UpdateInvitationMutationVariables
>;

/**
 * __useUpdateInvitationMutation__
 *
 * To run a mutation, you first call `useUpdateInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvitationMutation, { data, loading, error }] = useUpdateInvitationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateInvitationMutation,
    UpdateInvitationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateInvitationMutation, UpdateInvitationMutationVariables>(
    UpdateInvitationDocument,
    options
  );
}
export type UpdateInvitationMutationHookResult = ReturnType<typeof useUpdateInvitationMutation>;
export type UpdateInvitationMutationResult = Apollo.MutationResult<UpdateInvitationMutation>;
export type UpdateInvitationMutationOptions = Apollo.BaseMutationOptions<
  UpdateInvitationMutation,
  UpdateInvitationMutationVariables
>;
export const UpdateInvitationLinkDocument = gql`
  mutation updateInvitationLink($id: ID!, $input: UpdateMemberInvitationLinkInputInput!) {
    updateInvitationLink(id: $id, input: $input) {
      allowThirdPartyCalendarConnect
      clientManagementRole
      createDateTime
      createMemberId
      id
      invitationKey
      invitationUrl
      memo
      organizationId
      organizationMemberViewRole
      organizationName
      organizationReportRole
      projectCreatePermissionFlg
      projectCustomAttributePermissionFlg
      projectDeletePermissionFlg
      projectUpdatePermissionFlg
      proxyEditWorkingDataRole
      taskCreatePermissionFlg
      taskCustomAttributePermissionFlg
      taskDeletePermissionFlg
      taskUpdatePermissionFlg
      teamMemberViewRole
      teamReportRole
      versionNo
    }
  }
`;
export type UpdateInvitationLinkMutationFn = Apollo.MutationFunction<
  UpdateInvitationLinkMutation,
  UpdateInvitationLinkMutationVariables
>;

/**
 * __useUpdateInvitationLinkMutation__
 *
 * To run a mutation, you first call `useUpdateInvitationLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvitationLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvitationLinkMutation, { data, loading, error }] = useUpdateInvitationLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInvitationLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateInvitationLinkMutation,
    UpdateInvitationLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateInvitationLinkMutation, UpdateInvitationLinkMutationVariables>(
    UpdateInvitationLinkDocument,
    options
  );
}
export type UpdateInvitationLinkMutationHookResult = ReturnType<
  typeof useUpdateInvitationLinkMutation
>;
export type UpdateInvitationLinkMutationResult =
  Apollo.MutationResult<UpdateInvitationLinkMutation>;
export type UpdateInvitationLinkMutationOptions = Apollo.BaseMutationOptions<
  UpdateInvitationLinkMutation,
  UpdateInvitationLinkMutationVariables
>;
export const UpdateLaborCostDocument = gql`
  mutation updateLaborCost($id: ID!, $input: UpdateLaborCostInputInput!) {
    updateLaborCost(id: $id, input: $input) {
      hourlyWage
      id
      startDateTime
      versionNo
    }
  }
`;
export type UpdateLaborCostMutationFn = Apollo.MutationFunction<
  UpdateLaborCostMutation,
  UpdateLaborCostMutationVariables
>;

/**
 * __useUpdateLaborCostMutation__
 *
 * To run a mutation, you first call `useUpdateLaborCostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLaborCostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLaborCostMutation, { data, loading, error }] = useUpdateLaborCostMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLaborCostMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLaborCostMutation,
    UpdateLaborCostMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateLaborCostMutation, UpdateLaborCostMutationVariables>(
    UpdateLaborCostDocument,
    options
  );
}
export type UpdateLaborCostMutationHookResult = ReturnType<typeof useUpdateLaborCostMutation>;
export type UpdateLaborCostMutationResult = Apollo.MutationResult<UpdateLaborCostMutation>;
export type UpdateLaborCostMutationOptions = Apollo.BaseMutationOptions<
  UpdateLaborCostMutation,
  UpdateLaborCostMutationVariables
>;
export const UpdateLicenceCountDocument = gql`
  mutation updateLicenceCount($id: ID!, $input: UpdateLicenceCountInputInput) {
    updateLicenceCount(id: $id, input: $input) {
      billToMailAddress
      billingType
      contractPreriod
      freeLicenceCoupon {
        freeEndLicenceCount
        freeStartLicenceCount
        id
      }
      freeTrialExpireDatetime
      id
      licenceCount
      plan {
        code
        contractPreriod
        id
      }
      revenuecatOriginalAppUserId
      usingLicenceCount
      versionNo
    }
  }
`;
export type UpdateLicenceCountMutationFn = Apollo.MutationFunction<
  UpdateLicenceCountMutation,
  UpdateLicenceCountMutationVariables
>;

/**
 * __useUpdateLicenceCountMutation__
 *
 * To run a mutation, you first call `useUpdateLicenceCountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLicenceCountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLicenceCountMutation, { data, loading, error }] = useUpdateLicenceCountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLicenceCountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLicenceCountMutation,
    UpdateLicenceCountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateLicenceCountMutation, UpdateLicenceCountMutationVariables>(
    UpdateLicenceCountDocument,
    options
  );
}
export type UpdateLicenceCountMutationHookResult = ReturnType<typeof useUpdateLicenceCountMutation>;
export type UpdateLicenceCountMutationResult = Apollo.MutationResult<UpdateLicenceCountMutation>;
export type UpdateLicenceCountMutationOptions = Apollo.BaseMutationOptions<
  UpdateLicenceCountMutation,
  UpdateLicenceCountMutationVariables
>;
export const UpdateMemberProfileDocument = gql`
  mutation updateMemberProfile($input: UpdateMemberProfileInputInput!) {
    updateMemberProfile(input: $input) {
      adminRole
      clientManagementRole
      department
      employeeNumber
      id
      laborCostManagementRole
      mailAddress
      memberManagementRole
      memberRegistrationDateTime
      memberStatus
      name
      organizationMemberViewRole
      organizationReportRole
      profileImageUrl
      projectCreatePermissionFlg
      projectCustomAttributePermissionFlg
      projectDeletePermissionFlg
      projectUpdatePermissionFlg
      proxyEditWorkingDataRole
      salesManagementRole
      statusMessage
      taskCreatePermissionFlg
      taskCustomAttributePermissionFlg
      taskDeletePermissionFlg
      taskUpdatePermissionFlg
      teamMemberViewRole
      teamReportRole
      versionNo
    }
  }
`;
export type UpdateMemberProfileMutationFn = Apollo.MutationFunction<
  UpdateMemberProfileMutation,
  UpdateMemberProfileMutationVariables
>;

/**
 * __useUpdateMemberProfileMutation__
 *
 * To run a mutation, you first call `useUpdateMemberProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberProfileMutation, { data, loading, error }] = useUpdateMemberProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMemberProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMemberProfileMutation,
    UpdateMemberProfileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMemberProfileMutation, UpdateMemberProfileMutationVariables>(
    UpdateMemberProfileDocument,
    options
  );
}
export type UpdateMemberProfileMutationHookResult = ReturnType<
  typeof useUpdateMemberProfileMutation
>;
export type UpdateMemberProfileMutationResult = Apollo.MutationResult<UpdateMemberProfileMutation>;
export type UpdateMemberProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateMemberProfileMutation,
  UpdateMemberProfileMutationVariables
>;
export const UpdateMemberRoleDocument = gql`
  mutation updateMemberRole($id: ID!, $input: UpdateMemberRoleInputInput!) {
    updateMemberRole(id: $id, input: $input) {
      adminRole
      clientManagementRole
      department
      employeeNumber
      id
      laborCostManagementRole
      mailAddress
      memberManagementRole
      memberRegistrationDateTime
      memberStatus
      name
      organizationMemberViewRole
      organizationReportRole
      profileImageUrl
      projectCreatePermissionFlg
      projectCustomAttributePermissionFlg
      projectDeletePermissionFlg
      projectUpdatePermissionFlg
      proxyEditWorkingDataRole
      salesManagementRole
      statusMessage
      taskCreatePermissionFlg
      taskCustomAttributePermissionFlg
      taskDeletePermissionFlg
      taskUpdatePermissionFlg
      teamMemberViewRole
      teamReportRole
      versionNo
    }
  }
`;
export type UpdateMemberRoleMutationFn = Apollo.MutationFunction<
  UpdateMemberRoleMutation,
  UpdateMemberRoleMutationVariables
>;

/**
 * __useUpdateMemberRoleMutation__
 *
 * To run a mutation, you first call `useUpdateMemberRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberRoleMutation, { data, loading, error }] = useUpdateMemberRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMemberRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMemberRoleMutation,
    UpdateMemberRoleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMemberRoleMutation, UpdateMemberRoleMutationVariables>(
    UpdateMemberRoleDocument,
    options
  );
}
export type UpdateMemberRoleMutationHookResult = ReturnType<typeof useUpdateMemberRoleMutation>;
export type UpdateMemberRoleMutationResult = Apollo.MutationResult<UpdateMemberRoleMutation>;
export type UpdateMemberRoleMutationOptions = Apollo.BaseMutationOptions<
  UpdateMemberRoleMutation,
  UpdateMemberRoleMutationVariables
>;
export const UpdateMemberStatusMessageDocument = gql`
  mutation updateMemberStatusMessage($input: UpdateMemberStatusMessageInputInput!) {
    updateMemberStatusMessage(input: $input) {
      adminRole
      clientManagementRole
      department
      employeeNumber
      id
      laborCostManagementRole
      mailAddress
      memberManagementRole
      memberRegistrationDateTime
      memberStatus
      name
      organizationMemberViewRole
      organizationReportRole
      profileImageUrl
      projectCreatePermissionFlg
      projectCustomAttributePermissionFlg
      projectDeletePermissionFlg
      projectUpdatePermissionFlg
      proxyEditWorkingDataRole
      salesManagementRole
      statusMessage
      taskCreatePermissionFlg
      taskCustomAttributePermissionFlg
      taskDeletePermissionFlg
      taskUpdatePermissionFlg
      teamMemberViewRole
      teamReportRole
      versionNo
    }
  }
`;
export type UpdateMemberStatusMessageMutationFn = Apollo.MutationFunction<
  UpdateMemberStatusMessageMutation,
  UpdateMemberStatusMessageMutationVariables
>;

/**
 * __useUpdateMemberStatusMessageMutation__
 *
 * To run a mutation, you first call `useUpdateMemberStatusMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberStatusMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberStatusMessageMutation, { data, loading, error }] = useUpdateMemberStatusMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMemberStatusMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMemberStatusMessageMutation,
    UpdateMemberStatusMessageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMemberStatusMessageMutation,
    UpdateMemberStatusMessageMutationVariables
  >(UpdateMemberStatusMessageDocument, options);
}
export type UpdateMemberStatusMessageMutationHookResult = ReturnType<
  typeof useUpdateMemberStatusMessageMutation
>;
export type UpdateMemberStatusMessageMutationResult =
  Apollo.MutationResult<UpdateMemberStatusMessageMutation>;
export type UpdateMemberStatusMessageMutationOptions = Apollo.BaseMutationOptions<
  UpdateMemberStatusMessageMutation,
  UpdateMemberStatusMessageMutationVariables
>;
export const UpdateMeteredRateContractDocument = gql`
  mutation updateMeteredRateContract($id: ID!, $input: UpdateMeterdRateContractInputInput!) {
    updateMeteredRateContract(id: $id, input: $input) {
      hourUnitPrice
      id
      member {
        laborCostWithoutTax
        memberId
        name
        profileImageUrl
        roundedTotalWorkingTimeSec
        salesAmountWithoutTax
        totalWorkingTimeSec
      }
      versionNo
    }
  }
`;
export type UpdateMeteredRateContractMutationFn = Apollo.MutationFunction<
  UpdateMeteredRateContractMutation,
  UpdateMeteredRateContractMutationVariables
>;

/**
 * __useUpdateMeteredRateContractMutation__
 *
 * To run a mutation, you first call `useUpdateMeteredRateContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeteredRateContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMeteredRateContractMutation, { data, loading, error }] = useUpdateMeteredRateContractMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMeteredRateContractMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMeteredRateContractMutation,
    UpdateMeteredRateContractMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMeteredRateContractMutation,
    UpdateMeteredRateContractMutationVariables
  >(UpdateMeteredRateContractDocument, options);
}
export type UpdateMeteredRateContractMutationHookResult = ReturnType<
  typeof useUpdateMeteredRateContractMutation
>;
export type UpdateMeteredRateContractMutationResult =
  Apollo.MutationResult<UpdateMeteredRateContractMutation>;
export type UpdateMeteredRateContractMutationOptions = Apollo.BaseMutationOptions<
  UpdateMeteredRateContractMutation,
  UpdateMeteredRateContractMutationVariables
>;
export const UpdateMySettingsDocument = gql`
  mutation updateMySettings($input: UpdateMemberSettingsInputInput!) {
    updateMySettings(input: $input) {
      allowExportCalendar
      autoAssignWhenTaskStart
      calendarStartDayOfWeek
      copyWorkingScheduleMemoToHistory
      id
      initialViewTypeCode
      longTimeWorkingAlertMinutes
      member {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      projectInitialViewTypeCode
      twoFactorAuthEnabled
      versionNo
    }
  }
`;
export type UpdateMySettingsMutationFn = Apollo.MutationFunction<
  UpdateMySettingsMutation,
  UpdateMySettingsMutationVariables
>;

/**
 * __useUpdateMySettingsMutation__
 *
 * To run a mutation, you first call `useUpdateMySettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMySettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMySettingsMutation, { data, loading, error }] = useUpdateMySettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMySettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMySettingsMutation,
    UpdateMySettingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMySettingsMutation, UpdateMySettingsMutationVariables>(
    UpdateMySettingsDocument,
    options
  );
}
export type UpdateMySettingsMutationHookResult = ReturnType<typeof useUpdateMySettingsMutation>;
export type UpdateMySettingsMutationResult = Apollo.MutationResult<UpdateMySettingsMutation>;
export type UpdateMySettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateMySettingsMutation,
  UpdateMySettingsMutationVariables
>;
export const UpdateNotificationToReadDocument = gql`
  mutation updateNotificationToRead($id: String) {
    updateNotificationToRead(id: $id)
  }
`;
export type UpdateNotificationToReadMutationFn = Apollo.MutationFunction<
  UpdateNotificationToReadMutation,
  UpdateNotificationToReadMutationVariables
>;

/**
 * __useUpdateNotificationToReadMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationToReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationToReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationToReadMutation, { data, loading, error }] = useUpdateNotificationToReadMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateNotificationToReadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateNotificationToReadMutation,
    UpdateNotificationToReadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateNotificationToReadMutation,
    UpdateNotificationToReadMutationVariables
  >(UpdateNotificationToReadDocument, options);
}
export type UpdateNotificationToReadMutationHookResult = ReturnType<
  typeof useUpdateNotificationToReadMutation
>;
export type UpdateNotificationToReadMutationResult =
  Apollo.MutationResult<UpdateNotificationToReadMutation>;
export type UpdateNotificationToReadMutationOptions = Apollo.BaseMutationOptions<
  UpdateNotificationToReadMutation,
  UpdateNotificationToReadMutationVariables
>;
export const UpdateNotificationToReadV2Document = gql`
  mutation updateNotificationToReadV2($id: String) {
    updateNotificationToReadV2(id: $id) {
      id
      mention {
        commentId
        id
        mentionType
        message
        projectId
        projectName
        sendMember {
          id
          name
          profileImageUrl
        }
        taskId
        taskTitle
        teamId
        teamName
      }
      notificationType
      projectAssignNotification {
        id
        projectId
        projectName
        sendMember {
          id
          name
          profileImageUrl
        }
        teamId
        teamName
      }
      readDateTime
      receivedDateTime
      sendDateTime
      targetMember {
        id
        name
        profileImageUrl
      }
      taskAssignNotification {
        id
        projectId
        projectName
        sendMember {
          id
          name
          profileImageUrl
        }
        taskId
        taskTitle
        teamId
        teamName
      }
      taskRemainderNotification {
        id
        projectId
        projectName
        scheduledEndDateTime
        taskId
        taskRemainderType
        taskTitle
        teamId
        teamName
      }
    }
  }
`;
export type UpdateNotificationToReadV2MutationFn = Apollo.MutationFunction<
  UpdateNotificationToReadV2Mutation,
  UpdateNotificationToReadV2MutationVariables
>;

/**
 * __useUpdateNotificationToReadV2Mutation__
 *
 * To run a mutation, you first call `useUpdateNotificationToReadV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationToReadV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationToReadV2Mutation, { data, loading, error }] = useUpdateNotificationToReadV2Mutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateNotificationToReadV2Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateNotificationToReadV2Mutation,
    UpdateNotificationToReadV2MutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateNotificationToReadV2Mutation,
    UpdateNotificationToReadV2MutationVariables
  >(UpdateNotificationToReadV2Document, options);
}
export type UpdateNotificationToReadV2MutationHookResult = ReturnType<
  typeof useUpdateNotificationToReadV2Mutation
>;
export type UpdateNotificationToReadV2MutationResult =
  Apollo.MutationResult<UpdateNotificationToReadV2Mutation>;
export type UpdateNotificationToReadV2MutationOptions = Apollo.BaseMutationOptions<
  UpdateNotificationToReadV2Mutation,
  UpdateNotificationToReadV2MutationVariables
>;
export const UpdateNotificationToReceivedDocument = gql`
  mutation updateNotificationToReceived($id: String) {
    updateNotificationToReceived(id: $id)
  }
`;
export type UpdateNotificationToReceivedMutationFn = Apollo.MutationFunction<
  UpdateNotificationToReceivedMutation,
  UpdateNotificationToReceivedMutationVariables
>;

/**
 * __useUpdateNotificationToReceivedMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationToReceivedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationToReceivedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationToReceivedMutation, { data, loading, error }] = useUpdateNotificationToReceivedMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateNotificationToReceivedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateNotificationToReceivedMutation,
    UpdateNotificationToReceivedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateNotificationToReceivedMutation,
    UpdateNotificationToReceivedMutationVariables
  >(UpdateNotificationToReceivedDocument, options);
}
export type UpdateNotificationToReceivedMutationHookResult = ReturnType<
  typeof useUpdateNotificationToReceivedMutation
>;
export type UpdateNotificationToReceivedMutationResult =
  Apollo.MutationResult<UpdateNotificationToReceivedMutation>;
export type UpdateNotificationToReceivedMutationOptions = Apollo.BaseMutationOptions<
  UpdateNotificationToReceivedMutation,
  UpdateNotificationToReceivedMutationVariables
>;
export const UpdateNotificationToReceivedV2Document = gql`
  mutation updateNotificationToReceivedV2($id: String) {
    updateNotificationToReceivedV2(id: $id) {
      id
      mention {
        commentId
        id
        mentionType
        message
        projectId
        projectName
        sendMember {
          id
          name
          profileImageUrl
        }
        taskId
        taskTitle
        teamId
        teamName
      }
      notificationType
      projectAssignNotification {
        id
        projectId
        projectName
        sendMember {
          id
          name
          profileImageUrl
        }
        teamId
        teamName
      }
      readDateTime
      receivedDateTime
      sendDateTime
      targetMember {
        id
        name
        profileImageUrl
      }
      taskAssignNotification {
        id
        projectId
        projectName
        sendMember {
          id
          name
          profileImageUrl
        }
        taskId
        taskTitle
        teamId
        teamName
      }
      taskRemainderNotification {
        id
        projectId
        projectName
        scheduledEndDateTime
        taskId
        taskRemainderType
        taskTitle
        teamId
        teamName
      }
    }
  }
`;
export type UpdateNotificationToReceivedV2MutationFn = Apollo.MutationFunction<
  UpdateNotificationToReceivedV2Mutation,
  UpdateNotificationToReceivedV2MutationVariables
>;

/**
 * __useUpdateNotificationToReceivedV2Mutation__
 *
 * To run a mutation, you first call `useUpdateNotificationToReceivedV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationToReceivedV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationToReceivedV2Mutation, { data, loading, error }] = useUpdateNotificationToReceivedV2Mutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateNotificationToReceivedV2Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateNotificationToReceivedV2Mutation,
    UpdateNotificationToReceivedV2MutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateNotificationToReceivedV2Mutation,
    UpdateNotificationToReceivedV2MutationVariables
  >(UpdateNotificationToReceivedV2Document, options);
}
export type UpdateNotificationToReceivedV2MutationHookResult = ReturnType<
  typeof useUpdateNotificationToReceivedV2Mutation
>;
export type UpdateNotificationToReceivedV2MutationResult =
  Apollo.MutationResult<UpdateNotificationToReceivedV2Mutation>;
export type UpdateNotificationToReceivedV2MutationOptions = Apollo.BaseMutationOptions<
  UpdateNotificationToReceivedV2Mutation,
  UpdateNotificationToReceivedV2MutationVariables
>;
export const UpdateOrganizationDocument = gql`
  mutation updateOrganization($id: ID!, $input: UpdateOrganizationInputInput!) {
    updateOrganization(id: $id, input: $input) {
      allowConnectThirdPartyCalendar
      contactMailAddress
      contractPreriod
      id
      imageUrl
      ipRestrictionFlg
      name
      plan {
        code
        contractPreriod
        id
      }
      requiredTwoFactorAuth
      suspensionFlg
      versionNo
    }
  }
`;
export type UpdateOrganizationMutationFn = Apollo.MutationFunction<
  UpdateOrganizationMutation,
  UpdateOrganizationMutationVariables
>;

/**
 * __useUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationMutation, { data, loading, error }] = useUpdateOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrganizationMutation,
    UpdateOrganizationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>(
    UpdateOrganizationDocument,
    options
  );
}
export type UpdateOrganizationMutationHookResult = ReturnType<typeof useUpdateOrganizationMutation>;
export type UpdateOrganizationMutationResult = Apollo.MutationResult<UpdateOrganizationMutation>;
export type UpdateOrganizationMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrganizationMutation,
  UpdateOrganizationMutationVariables
>;
export const UpdateOrganizationImageDocument = gql`
  mutation updateOrganizationImage($id: ID!, $input: UpdateOrganizationImageInputInput!) {
    updateOrganizationImage(id: $id, input: $input) {
      allowConnectThirdPartyCalendar
      contactMailAddress
      contractPreriod
      id
      imageUrl
      ipRestrictionFlg
      name
      plan {
        code
        contractPreriod
        id
      }
      requiredTwoFactorAuth
      suspensionFlg
      versionNo
    }
  }
`;
export type UpdateOrganizationImageMutationFn = Apollo.MutationFunction<
  UpdateOrganizationImageMutation,
  UpdateOrganizationImageMutationVariables
>;

/**
 * __useUpdateOrganizationImageMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationImageMutation, { data, loading, error }] = useUpdateOrganizationImageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizationImageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrganizationImageMutation,
    UpdateOrganizationImageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrganizationImageMutation,
    UpdateOrganizationImageMutationVariables
  >(UpdateOrganizationImageDocument, options);
}
export type UpdateOrganizationImageMutationHookResult = ReturnType<
  typeof useUpdateOrganizationImageMutation
>;
export type UpdateOrganizationImageMutationResult =
  Apollo.MutationResult<UpdateOrganizationImageMutation>;
export type UpdateOrganizationImageMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrganizationImageMutation,
  UpdateOrganizationImageMutationVariables
>;
export const UpdatePasswordDocument = gql`
  mutation updatePassword($input: UpdatePasswordInputInput) {
    updatePassword(input: $input)
  }
`;
export type UpdatePasswordMutationFn = Apollo.MutationFunction<
  UpdatePasswordMutation,
  UpdatePasswordMutationVariables
>;

/**
 * __useUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordMutation, { data, loading, error }] = useUpdatePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePasswordMutation, UpdatePasswordMutationVariables>(
    UpdatePasswordDocument,
    options
  );
}
export type UpdatePasswordMutationHookResult = ReturnType<typeof useUpdatePasswordMutation>;
export type UpdatePasswordMutationResult = Apollo.MutationResult<UpdatePasswordMutation>;
export type UpdatePasswordMutationOptions = Apollo.BaseMutationOptions<
  UpdatePasswordMutation,
  UpdatePasswordMutationVariables
>;
export const UpdateProfileImageDocument = gql`
  mutation updateProfileImage($input: UpdateProfileImageInputInput!) {
    updateProfileImage(input: $input) {
      adminRole
      clientManagementRole
      department
      employeeNumber
      id
      laborCostManagementRole
      mailAddress
      memberManagementRole
      memberRegistrationDateTime
      memberStatus
      name
      organizationMemberViewRole
      organizationReportRole
      profileImageUrl
      projectCreatePermissionFlg
      projectCustomAttributePermissionFlg
      projectDeletePermissionFlg
      projectUpdatePermissionFlg
      proxyEditWorkingDataRole
      salesManagementRole
      statusMessage
      taskCreatePermissionFlg
      taskCustomAttributePermissionFlg
      taskDeletePermissionFlg
      taskUpdatePermissionFlg
      teamMemberViewRole
      teamReportRole
      versionNo
    }
  }
`;
export type UpdateProfileImageMutationFn = Apollo.MutationFunction<
  UpdateProfileImageMutation,
  UpdateProfileImageMutationVariables
>;

/**
 * __useUpdateProfileImageMutation__
 *
 * To run a mutation, you first call `useUpdateProfileImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileImageMutation, { data, loading, error }] = useUpdateProfileImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfileImageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProfileImageMutation,
    UpdateProfileImageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateProfileImageMutation, UpdateProfileImageMutationVariables>(
    UpdateProfileImageDocument,
    options
  );
}
export type UpdateProfileImageMutationHookResult = ReturnType<typeof useUpdateProfileImageMutation>;
export type UpdateProfileImageMutationResult = Apollo.MutationResult<UpdateProfileImageMutation>;
export type UpdateProfileImageMutationOptions = Apollo.BaseMutationOptions<
  UpdateProfileImageMutation,
  UpdateProfileImageMutationVariables
>;
export const UpdateProjectDocument = gql`
  mutation updateProject($id: ID!, $input: UpdateProjectInputInput) {
    updateProject(id: $id, input: $input) {
      assignees {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      calendarLinkageCode
      client {
        code
        id
        name
        sortNo
        versionNo
      }
      color
      complete
      completeDateTime
      costBudgetWithoutTax
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      favoriteMembers {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      favoriteMembersInfo {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      id
      key
      laborCost
      laborCostWithoutTax
      laborShare
      name
      priority
      projectStatus {
        endStatus
        id
        name
        sortNo
        teamId
        versionNo
      }
      registerDateTime
      salesAmountWithoutTax
      scheduledEndDateTime
      scheduledStartDateTime
      sortNoInGanttChart
      sortNoInList
      sortNoInProjectStatus
      team {
        archived
        id
        name
        organization {
          allowConnectThirdPartyCalendar
          contactMailAddress
          contractPreriod
          id
          imageUrl
          ipRestrictionFlg
          name
          plan {
            code
            contractPreriod
            id
          }
          requiredTwoFactorAuth
          suspensionFlg
          versionNo
        }
        personalTeam
        status
        versionNo
      }
      totalScheduleTimeSec
      versionNo
      workingTimeSec
    }
  }
`;
export type UpdateProjectMutationFn = Apollo.MutationFunction<
  UpdateProjectMutation,
  UpdateProjectMutationVariables
>;

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateProjectMutation, UpdateProjectMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(
    UpdateProjectDocument,
    options
  );
}
export type UpdateProjectMutationHookResult = ReturnType<typeof useUpdateProjectMutation>;
export type UpdateProjectMutationResult = Apollo.MutationResult<UpdateProjectMutation>;
export type UpdateProjectMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectMutation,
  UpdateProjectMutationVariables
>;
export const UpdateProjectContractDocument = gql`
  mutation updateProjectContract($id: ID!, $input: UpdateProjectContractInputInput!) {
    updateProjectContract(id: $id, input: $input) {
      allMemberMeteredRateContract {
        hourUnitPrice
        id
        member {
          laborCostWithoutTax
          memberId
          name
          profileImageUrl
          roundedTotalWorkingTimeSec
          salesAmountWithoutTax
          totalWorkingTimeSec
        }
        versionNo
      }
      allMemberSettlementRangeContract {
        id
        lowerHourPart {
          deducationHourlyUnitPrice
          lowerHour
        }
        member {
          laborCostWithoutTax
          memberId
          name
          profileImageUrl
          roundedTotalWorkingTimeSec
          salesAmountWithoutTax
          totalWorkingTimeSec
        }
        monthlyUnitPrice
        upperHourPart {
          excessHourlyUnitPrice
          upperHour
        }
        versionNo
      }
      contractType
      costBudgetWithoutTax
      endDateTime
      fixSalesAmountWithoutTax
      grossProfit
      grossProfitRatePercent
      id
      laborCostWithoutTax
      laborShare
      membersMeteredRateContract {
        hourUnitPrice
        id
        member {
          laborCostWithoutTax
          memberId
          name
          profileImageUrl
          roundedTotalWorkingTimeSec
          salesAmountWithoutTax
          totalWorkingTimeSec
        }
        versionNo
      }
      membersSettlementRangeContract {
        id
        lowerHourPart {
          deducationHourlyUnitPrice
          lowerHour
        }
        member {
          laborCostWithoutTax
          memberId
          name
          profileImageUrl
          roundedTotalWorkingTimeSec
          salesAmountWithoutTax
          totalWorkingTimeSec
        }
        monthlyUnitPrice
        upperHourPart {
          excessHourlyUnitPrice
          upperHour
        }
        versionNo
      }
      month
      project {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        calendarLinkageCode
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        color
        complete
        completeDateTime
        costBudgetWithoutTax
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        key
        laborCost
        laborCostWithoutTax
        laborShare
        name
        priority
        projectStatus {
          endStatus
          id
          name
          sortNo
          teamId
          versionNo
        }
        registerDateTime
        salesAmountWithoutTax
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInProjectStatus
        team {
          archived
          id
          name
          organization {
            allowConnectThirdPartyCalendar
            contactMailAddress
            contractPreriod
            id
            imageUrl
            ipRestrictionFlg
            name
            plan {
              code
              contractPreriod
              id
            }
            requiredTwoFactorAuth
            suspensionFlg
            versionNo
          }
          personalTeam
          status
          versionNo
        }
        totalScheduleTimeSec
        versionNo
        workingTimeSec
      }
      roundTimeType
      roundedTotalWorkingTimeSec
      salesAmountWithoutTax
      salesRoundMinutes
      startDateTime
      taxRate
      termType
      timeZone {
        code
        id
        name
        offsetTime
        sortNo
      }
      totalWorkingTimeSec
      unitPriceType
      versionNo
      year
    }
  }
`;
export type UpdateProjectContractMutationFn = Apollo.MutationFunction<
  UpdateProjectContractMutation,
  UpdateProjectContractMutationVariables
>;

/**
 * __useUpdateProjectContractMutation__
 *
 * To run a mutation, you first call `useUpdateProjectContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectContractMutation, { data, loading, error }] = useUpdateProjectContractMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectContractMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectContractMutation,
    UpdateProjectContractMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateProjectContractMutation, UpdateProjectContractMutationVariables>(
    UpdateProjectContractDocument,
    options
  );
}
export type UpdateProjectContractMutationHookResult = ReturnType<
  typeof useUpdateProjectContractMutation
>;
export type UpdateProjectContractMutationResult =
  Apollo.MutationResult<UpdateProjectContractMutation>;
export type UpdateProjectContractMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectContractMutation,
  UpdateProjectContractMutationVariables
>;
export const UpdateProjectCustomAttributeDocument = gql`
  mutation updateProjectCustomAttribute($id: ID!, $input: UpdateProjectCustomAttributeInputInput!) {
    updateProjectCustomAttribute(id: $id, input: $input) {
      id
      listItem {
        id
        sortNo
        value
        versionNo
      }
      master {
        id
        listItem {
          id
          sortNo
          value
          versionNo
        }
        name
        required
        sortNo
        type
        versionNo
      }
      sortNo
      value
    }
  }
`;
export type UpdateProjectCustomAttributeMutationFn = Apollo.MutationFunction<
  UpdateProjectCustomAttributeMutation,
  UpdateProjectCustomAttributeMutationVariables
>;

/**
 * __useUpdateProjectCustomAttributeMutation__
 *
 * To run a mutation, you first call `useUpdateProjectCustomAttributeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectCustomAttributeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectCustomAttributeMutation, { data, loading, error }] = useUpdateProjectCustomAttributeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectCustomAttributeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectCustomAttributeMutation,
    UpdateProjectCustomAttributeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProjectCustomAttributeMutation,
    UpdateProjectCustomAttributeMutationVariables
  >(UpdateProjectCustomAttributeDocument, options);
}
export type UpdateProjectCustomAttributeMutationHookResult = ReturnType<
  typeof useUpdateProjectCustomAttributeMutation
>;
export type UpdateProjectCustomAttributeMutationResult =
  Apollo.MutationResult<UpdateProjectCustomAttributeMutation>;
export type UpdateProjectCustomAttributeMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectCustomAttributeMutation,
  UpdateProjectCustomAttributeMutationVariables
>;
export const UpdateProjectCustomAttributeMasterDocument = gql`
  mutation updateProjectCustomAttributeMaster(
    $id: ID!
    $input: UpdateProjectCustomAttributeMasterInputInput!
  ) {
    updateProjectCustomAttributeMaster(id: $id, input: $input) {
      id
      listItem {
        id
        sortNo
        value
        versionNo
      }
      name
      required
      sortNo
      type
      versionNo
    }
  }
`;
export type UpdateProjectCustomAttributeMasterMutationFn = Apollo.MutationFunction<
  UpdateProjectCustomAttributeMasterMutation,
  UpdateProjectCustomAttributeMasterMutationVariables
>;

/**
 * __useUpdateProjectCustomAttributeMasterMutation__
 *
 * To run a mutation, you first call `useUpdateProjectCustomAttributeMasterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectCustomAttributeMasterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectCustomAttributeMasterMutation, { data, loading, error }] = useUpdateProjectCustomAttributeMasterMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectCustomAttributeMasterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectCustomAttributeMasterMutation,
    UpdateProjectCustomAttributeMasterMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProjectCustomAttributeMasterMutation,
    UpdateProjectCustomAttributeMasterMutationVariables
  >(UpdateProjectCustomAttributeMasterDocument, options);
}
export type UpdateProjectCustomAttributeMasterMutationHookResult = ReturnType<
  typeof useUpdateProjectCustomAttributeMasterMutation
>;
export type UpdateProjectCustomAttributeMasterMutationResult =
  Apollo.MutationResult<UpdateProjectCustomAttributeMasterMutation>;
export type UpdateProjectCustomAttributeMasterMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectCustomAttributeMasterMutation,
  UpdateProjectCustomAttributeMasterMutationVariables
>;
export const UpdateProjectCustomAttributeMasterListItemDocument = gql`
  mutation updateProjectCustomAttributeMasterListItem(
    $id: ID!
    $input: UpdateProjectCustomAttributeMasterListItemInputInput!
  ) {
    updateProjectCustomAttributeMasterListItem(id: $id, input: $input) {
      id
      sortNo
      value
      versionNo
    }
  }
`;
export type UpdateProjectCustomAttributeMasterListItemMutationFn = Apollo.MutationFunction<
  UpdateProjectCustomAttributeMasterListItemMutation,
  UpdateProjectCustomAttributeMasterListItemMutationVariables
>;

/**
 * __useUpdateProjectCustomAttributeMasterListItemMutation__
 *
 * To run a mutation, you first call `useUpdateProjectCustomAttributeMasterListItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectCustomAttributeMasterListItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectCustomAttributeMasterListItemMutation, { data, loading, error }] = useUpdateProjectCustomAttributeMasterListItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectCustomAttributeMasterListItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectCustomAttributeMasterListItemMutation,
    UpdateProjectCustomAttributeMasterListItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProjectCustomAttributeMasterListItemMutation,
    UpdateProjectCustomAttributeMasterListItemMutationVariables
  >(UpdateProjectCustomAttributeMasterListItemDocument, options);
}
export type UpdateProjectCustomAttributeMasterListItemMutationHookResult = ReturnType<
  typeof useUpdateProjectCustomAttributeMasterListItemMutation
>;
export type UpdateProjectCustomAttributeMasterListItemMutationResult =
  Apollo.MutationResult<UpdateProjectCustomAttributeMasterListItemMutation>;
export type UpdateProjectCustomAttributeMasterListItemMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectCustomAttributeMasterListItemMutation,
  UpdateProjectCustomAttributeMasterListItemMutationVariables
>;
export const UpdateProjectSortNoInAssignedProjectDocument = gql`
  mutation updateProjectSortNoInAssignedProject(
    $id: ID!
    $input: UpdateProjectSortNoInAssignedProjectInputInput
  ) {
    updateProjectSortNoInAssignedProject(id: $id, input: $input) {
      assignees {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      calendarLinkageCode
      client {
        code
        id
        name
        sortNo
        versionNo
      }
      color
      complete
      completeDateTime
      costBudgetWithoutTax
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      favoriteMembers {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      favoriteMembersInfo {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      id
      key
      laborCost
      laborCostWithoutTax
      laborShare
      name
      priority
      projectStatus {
        endStatus
        id
        name
        sortNo
        teamId
        versionNo
      }
      registerDateTime
      salesAmountWithoutTax
      scheduledEndDateTime
      scheduledStartDateTime
      sortNoInGanttChart
      sortNoInList
      sortNoInProjectStatus
      team {
        archived
        id
        name
        organization {
          allowConnectThirdPartyCalendar
          contactMailAddress
          contractPreriod
          id
          imageUrl
          ipRestrictionFlg
          name
          plan {
            code
            contractPreriod
            id
          }
          requiredTwoFactorAuth
          suspensionFlg
          versionNo
        }
        personalTeam
        status
        versionNo
      }
      totalScheduleTimeSec
      versionNo
      workingTimeSec
    }
  }
`;
export type UpdateProjectSortNoInAssignedProjectMutationFn = Apollo.MutationFunction<
  UpdateProjectSortNoInAssignedProjectMutation,
  UpdateProjectSortNoInAssignedProjectMutationVariables
>;

/**
 * __useUpdateProjectSortNoInAssignedProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectSortNoInAssignedProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectSortNoInAssignedProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectSortNoInAssignedProjectMutation, { data, loading, error }] = useUpdateProjectSortNoInAssignedProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectSortNoInAssignedProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectSortNoInAssignedProjectMutation,
    UpdateProjectSortNoInAssignedProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProjectSortNoInAssignedProjectMutation,
    UpdateProjectSortNoInAssignedProjectMutationVariables
  >(UpdateProjectSortNoInAssignedProjectDocument, options);
}
export type UpdateProjectSortNoInAssignedProjectMutationHookResult = ReturnType<
  typeof useUpdateProjectSortNoInAssignedProjectMutation
>;
export type UpdateProjectSortNoInAssignedProjectMutationResult =
  Apollo.MutationResult<UpdateProjectSortNoInAssignedProjectMutation>;
export type UpdateProjectSortNoInAssignedProjectMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectSortNoInAssignedProjectMutation,
  UpdateProjectSortNoInAssignedProjectMutationVariables
>;
export const UpdateProjectSortNoInFavoriteProjectDocument = gql`
  mutation updateProjectSortNoInFavoriteProject(
    $id: ID!
    $input: UpdateProjectSortNoInFavoriteProjectInputInput
  ) {
    updateProjectSortNoInFavoriteProject(id: $id, input: $input) {
      assignees {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      calendarLinkageCode
      client {
        code
        id
        name
        sortNo
        versionNo
      }
      color
      complete
      completeDateTime
      costBudgetWithoutTax
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      favoriteMembers {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      favoriteMembersInfo {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      id
      key
      laborCost
      laborCostWithoutTax
      laborShare
      name
      priority
      projectStatus {
        endStatus
        id
        name
        sortNo
        teamId
        versionNo
      }
      registerDateTime
      salesAmountWithoutTax
      scheduledEndDateTime
      scheduledStartDateTime
      sortNoInGanttChart
      sortNoInList
      sortNoInProjectStatus
      team {
        archived
        id
        name
        organization {
          allowConnectThirdPartyCalendar
          contactMailAddress
          contractPreriod
          id
          imageUrl
          ipRestrictionFlg
          name
          plan {
            code
            contractPreriod
            id
          }
          requiredTwoFactorAuth
          suspensionFlg
          versionNo
        }
        personalTeam
        status
        versionNo
      }
      totalScheduleTimeSec
      versionNo
      workingTimeSec
    }
  }
`;
export type UpdateProjectSortNoInFavoriteProjectMutationFn = Apollo.MutationFunction<
  UpdateProjectSortNoInFavoriteProjectMutation,
  UpdateProjectSortNoInFavoriteProjectMutationVariables
>;

/**
 * __useUpdateProjectSortNoInFavoriteProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectSortNoInFavoriteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectSortNoInFavoriteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectSortNoInFavoriteProjectMutation, { data, loading, error }] = useUpdateProjectSortNoInFavoriteProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectSortNoInFavoriteProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectSortNoInFavoriteProjectMutation,
    UpdateProjectSortNoInFavoriteProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProjectSortNoInFavoriteProjectMutation,
    UpdateProjectSortNoInFavoriteProjectMutationVariables
  >(UpdateProjectSortNoInFavoriteProjectDocument, options);
}
export type UpdateProjectSortNoInFavoriteProjectMutationHookResult = ReturnType<
  typeof useUpdateProjectSortNoInFavoriteProjectMutation
>;
export type UpdateProjectSortNoInFavoriteProjectMutationResult =
  Apollo.MutationResult<UpdateProjectSortNoInFavoriteProjectMutation>;
export type UpdateProjectSortNoInFavoriteProjectMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectSortNoInFavoriteProjectMutation,
  UpdateProjectSortNoInFavoriteProjectMutationVariables
>;
export const UpdateProjectSortNoInListDocument = gql`
  mutation updateProjectSortNoInList($id: ID!, $input: UpdateProjectSortNoInListInputInput) {
    updateProjectSortNoInList(id: $id, input: $input) {
      assignees {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      calendarLinkageCode
      client {
        code
        id
        name
        sortNo
        versionNo
      }
      color
      complete
      completeDateTime
      costBudgetWithoutTax
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      favoriteMembers {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      favoriteMembersInfo {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      id
      key
      laborCost
      laborCostWithoutTax
      laborShare
      name
      priority
      projectStatus {
        endStatus
        id
        name
        sortNo
        teamId
        versionNo
      }
      registerDateTime
      salesAmountWithoutTax
      scheduledEndDateTime
      scheduledStartDateTime
      sortNoInGanttChart
      sortNoInList
      sortNoInProjectStatus
      team {
        archived
        id
        name
        organization {
          allowConnectThirdPartyCalendar
          contactMailAddress
          contractPreriod
          id
          imageUrl
          ipRestrictionFlg
          name
          plan {
            code
            contractPreriod
            id
          }
          requiredTwoFactorAuth
          suspensionFlg
          versionNo
        }
        personalTeam
        status
        versionNo
      }
      totalScheduleTimeSec
      versionNo
      workingTimeSec
    }
  }
`;
export type UpdateProjectSortNoInListMutationFn = Apollo.MutationFunction<
  UpdateProjectSortNoInListMutation,
  UpdateProjectSortNoInListMutationVariables
>;

/**
 * __useUpdateProjectSortNoInListMutation__
 *
 * To run a mutation, you first call `useUpdateProjectSortNoInListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectSortNoInListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectSortNoInListMutation, { data, loading, error }] = useUpdateProjectSortNoInListMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectSortNoInListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectSortNoInListMutation,
    UpdateProjectSortNoInListMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProjectSortNoInListMutation,
    UpdateProjectSortNoInListMutationVariables
  >(UpdateProjectSortNoInListDocument, options);
}
export type UpdateProjectSortNoInListMutationHookResult = ReturnType<
  typeof useUpdateProjectSortNoInListMutation
>;
export type UpdateProjectSortNoInListMutationResult =
  Apollo.MutationResult<UpdateProjectSortNoInListMutation>;
export type UpdateProjectSortNoInListMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectSortNoInListMutation,
  UpdateProjectSortNoInListMutationVariables
>;
export const UpdateProjectStatusDocument = gql`
  mutation updateProjectStatus($id: ID!, $input: UpdateProjectStatusInputInput!) {
    updateProjectStatus(id: $id, input: $input) {
      endStatus
      id
      name
      sortNo
      teamId
      versionNo
    }
  }
`;
export type UpdateProjectStatusMutationFn = Apollo.MutationFunction<
  UpdateProjectStatusMutation,
  UpdateProjectStatusMutationVariables
>;

/**
 * __useUpdateProjectStatusMutation__
 *
 * To run a mutation, you first call `useUpdateProjectStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectStatusMutation, { data, loading, error }] = useUpdateProjectStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectStatusMutation,
    UpdateProjectStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateProjectStatusMutation, UpdateProjectStatusMutationVariables>(
    UpdateProjectStatusDocument,
    options
  );
}
export type UpdateProjectStatusMutationHookResult = ReturnType<
  typeof useUpdateProjectStatusMutation
>;
export type UpdateProjectStatusMutationResult = Apollo.MutationResult<UpdateProjectStatusMutation>;
export type UpdateProjectStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectStatusMutation,
  UpdateProjectStatusMutationVariables
>;
export const UpdateProjectTemplateDocument = gql`
  mutation updateProjectTemplate($id: ID!, $input: UpdateProjectTemplateInputInput) {
    updateProjectTemplate(id: $id, input: $input) {
      client {
        code
        id
        name
        sortNo
        versionNo
      }
      description
      estimateTimeSec
      id
      name
      priority
      sortNoInList
      versionNo
    }
  }
`;
export type UpdateProjectTemplateMutationFn = Apollo.MutationFunction<
  UpdateProjectTemplateMutation,
  UpdateProjectTemplateMutationVariables
>;

/**
 * __useUpdateProjectTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateProjectTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectTemplateMutation, { data, loading, error }] = useUpdateProjectTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectTemplateMutation,
    UpdateProjectTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateProjectTemplateMutation, UpdateProjectTemplateMutationVariables>(
    UpdateProjectTemplateDocument,
    options
  );
}
export type UpdateProjectTemplateMutationHookResult = ReturnType<
  typeof useUpdateProjectTemplateMutation
>;
export type UpdateProjectTemplateMutationResult =
  Apollo.MutationResult<UpdateProjectTemplateMutation>;
export type UpdateProjectTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectTemplateMutation,
  UpdateProjectTemplateMutationVariables
>;
export const UpdateProjectTemplateCustomAttributeDocument = gql`
  mutation updateProjectTemplateCustomAttribute(
    $id: ID!
    $input: UpdateProjectTemplateCustomAttributeInputInput!
  ) {
    updateProjectTemplateCustomAttribute(id: $id, input: $input) {
      id
      listItem {
        id
        sortNo
        value
        versionNo
      }
      master {
        id
        listItem {
          id
          sortNo
          value
          versionNo
        }
        name
        required
        sortNo
        type
        versionNo
      }
      sortNo
      value
    }
  }
`;
export type UpdateProjectTemplateCustomAttributeMutationFn = Apollo.MutationFunction<
  UpdateProjectTemplateCustomAttributeMutation,
  UpdateProjectTemplateCustomAttributeMutationVariables
>;

/**
 * __useUpdateProjectTemplateCustomAttributeMutation__
 *
 * To run a mutation, you first call `useUpdateProjectTemplateCustomAttributeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectTemplateCustomAttributeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectTemplateCustomAttributeMutation, { data, loading, error }] = useUpdateProjectTemplateCustomAttributeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectTemplateCustomAttributeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectTemplateCustomAttributeMutation,
    UpdateProjectTemplateCustomAttributeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProjectTemplateCustomAttributeMutation,
    UpdateProjectTemplateCustomAttributeMutationVariables
  >(UpdateProjectTemplateCustomAttributeDocument, options);
}
export type UpdateProjectTemplateCustomAttributeMutationHookResult = ReturnType<
  typeof useUpdateProjectTemplateCustomAttributeMutation
>;
export type UpdateProjectTemplateCustomAttributeMutationResult =
  Apollo.MutationResult<UpdateProjectTemplateCustomAttributeMutation>;
export type UpdateProjectTemplateCustomAttributeMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectTemplateCustomAttributeMutation,
  UpdateProjectTemplateCustomAttributeMutationVariables
>;
export const UpdateProjectTemplateSortNoInListDocument = gql`
  mutation updateProjectTemplateSortNoInList(
    $id: ID!
    $input: UpdateProjectTemplateSortNoInListInputInput
  ) {
    updateProjectTemplateSortNoInList(id: $id, input: $input) {
      client {
        code
        id
        name
        sortNo
        versionNo
      }
      description
      estimateTimeSec
      id
      name
      priority
      sortNoInList
      versionNo
    }
  }
`;
export type UpdateProjectTemplateSortNoInListMutationFn = Apollo.MutationFunction<
  UpdateProjectTemplateSortNoInListMutation,
  UpdateProjectTemplateSortNoInListMutationVariables
>;

/**
 * __useUpdateProjectTemplateSortNoInListMutation__
 *
 * To run a mutation, you first call `useUpdateProjectTemplateSortNoInListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectTemplateSortNoInListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectTemplateSortNoInListMutation, { data, loading, error }] = useUpdateProjectTemplateSortNoInListMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectTemplateSortNoInListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectTemplateSortNoInListMutation,
    UpdateProjectTemplateSortNoInListMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProjectTemplateSortNoInListMutation,
    UpdateProjectTemplateSortNoInListMutationVariables
  >(UpdateProjectTemplateSortNoInListDocument, options);
}
export type UpdateProjectTemplateSortNoInListMutationHookResult = ReturnType<
  typeof useUpdateProjectTemplateSortNoInListMutation
>;
export type UpdateProjectTemplateSortNoInListMutationResult =
  Apollo.MutationResult<UpdateProjectTemplateSortNoInListMutation>;
export type UpdateProjectTemplateSortNoInListMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectTemplateSortNoInListMutation,
  UpdateProjectTemplateSortNoInListMutationVariables
>;
export const UpdateProjectTemplateSubTaskDocument = gql`
  mutation updateProjectTemplateSubTask($id: ID!, $input: UpdateProjectTemplateSubTaskInputInput!) {
    updateProjectTemplateSubTask(id: $id, input: $input) {
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      id
      projectTemplateTask {
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        id
        priority
        projectTemplate {
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          description
          estimateTimeSec
          id
          name
          priority
          sortNoInList
          versionNo
        }
        projectTemplateTaskStatus {
          endStatus
          id
          name
          projectTemplate {
            description
            estimateTimeSec
            id
            name
            priority
            sortNoInList
            versionNo
          }
          sortNo
          versionNo
        }
        sortNoInList
        sortNoInTaskStatus
        title
        versionNo
      }
      sortNo
      title
      versionNo
    }
  }
`;
export type UpdateProjectTemplateSubTaskMutationFn = Apollo.MutationFunction<
  UpdateProjectTemplateSubTaskMutation,
  UpdateProjectTemplateSubTaskMutationVariables
>;

/**
 * __useUpdateProjectTemplateSubTaskMutation__
 *
 * To run a mutation, you first call `useUpdateProjectTemplateSubTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectTemplateSubTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectTemplateSubTaskMutation, { data, loading, error }] = useUpdateProjectTemplateSubTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectTemplateSubTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectTemplateSubTaskMutation,
    UpdateProjectTemplateSubTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProjectTemplateSubTaskMutation,
    UpdateProjectTemplateSubTaskMutationVariables
  >(UpdateProjectTemplateSubTaskDocument, options);
}
export type UpdateProjectTemplateSubTaskMutationHookResult = ReturnType<
  typeof useUpdateProjectTemplateSubTaskMutation
>;
export type UpdateProjectTemplateSubTaskMutationResult =
  Apollo.MutationResult<UpdateProjectTemplateSubTaskMutation>;
export type UpdateProjectTemplateSubTaskMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectTemplateSubTaskMutation,
  UpdateProjectTemplateSubTaskMutationVariables
>;
export const UpdateProjectTemplateSubTaskSortNoDocument = gql`
  mutation updateProjectTemplateSubTaskSortNo(
    $id: ID!
    $input: UpdateProjectTemplateSubTaskSortNoInputInput!
  ) {
    updateProjectTemplateSubTaskSortNo(id: $id, input: $input) {
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      id
      projectTemplateTask {
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        id
        priority
        projectTemplate {
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          description
          estimateTimeSec
          id
          name
          priority
          sortNoInList
          versionNo
        }
        projectTemplateTaskStatus {
          endStatus
          id
          name
          projectTemplate {
            description
            estimateTimeSec
            id
            name
            priority
            sortNoInList
            versionNo
          }
          sortNo
          versionNo
        }
        sortNoInList
        sortNoInTaskStatus
        title
        versionNo
      }
      sortNo
      title
      versionNo
    }
  }
`;
export type UpdateProjectTemplateSubTaskSortNoMutationFn = Apollo.MutationFunction<
  UpdateProjectTemplateSubTaskSortNoMutation,
  UpdateProjectTemplateSubTaskSortNoMutationVariables
>;

/**
 * __useUpdateProjectTemplateSubTaskSortNoMutation__
 *
 * To run a mutation, you first call `useUpdateProjectTemplateSubTaskSortNoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectTemplateSubTaskSortNoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectTemplateSubTaskSortNoMutation, { data, loading, error }] = useUpdateProjectTemplateSubTaskSortNoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectTemplateSubTaskSortNoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectTemplateSubTaskSortNoMutation,
    UpdateProjectTemplateSubTaskSortNoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProjectTemplateSubTaskSortNoMutation,
    UpdateProjectTemplateSubTaskSortNoMutationVariables
  >(UpdateProjectTemplateSubTaskSortNoDocument, options);
}
export type UpdateProjectTemplateSubTaskSortNoMutationHookResult = ReturnType<
  typeof useUpdateProjectTemplateSubTaskSortNoMutation
>;
export type UpdateProjectTemplateSubTaskSortNoMutationResult =
  Apollo.MutationResult<UpdateProjectTemplateSubTaskSortNoMutation>;
export type UpdateProjectTemplateSubTaskSortNoMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectTemplateSubTaskSortNoMutation,
  UpdateProjectTemplateSubTaskSortNoMutationVariables
>;
export const UpdateProjectTemplateTaskDocument = gql`
  mutation updateProjectTemplateTask($id: ID!, $input: UpdateProjectTemplateTaskInputInput!) {
    updateProjectTemplateTask(id: $id, input: $input) {
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      id
      priority
      projectTemplate {
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        description
        estimateTimeSec
        id
        name
        priority
        sortNoInList
        versionNo
      }
      projectTemplateTaskStatus {
        endStatus
        id
        name
        projectTemplate {
          description
          estimateTimeSec
          id
          name
          priority
          sortNoInList
          versionNo
        }
        sortNo
        versionNo
      }
      sortNoInList
      sortNoInTaskStatus
      title
      versionNo
    }
  }
`;
export type UpdateProjectTemplateTaskMutationFn = Apollo.MutationFunction<
  UpdateProjectTemplateTaskMutation,
  UpdateProjectTemplateTaskMutationVariables
>;

/**
 * __useUpdateProjectTemplateTaskMutation__
 *
 * To run a mutation, you first call `useUpdateProjectTemplateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectTemplateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectTemplateTaskMutation, { data, loading, error }] = useUpdateProjectTemplateTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectTemplateTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectTemplateTaskMutation,
    UpdateProjectTemplateTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProjectTemplateTaskMutation,
    UpdateProjectTemplateTaskMutationVariables
  >(UpdateProjectTemplateTaskDocument, options);
}
export type UpdateProjectTemplateTaskMutationHookResult = ReturnType<
  typeof useUpdateProjectTemplateTaskMutation
>;
export type UpdateProjectTemplateTaskMutationResult =
  Apollo.MutationResult<UpdateProjectTemplateTaskMutation>;
export type UpdateProjectTemplateTaskMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectTemplateTaskMutation,
  UpdateProjectTemplateTaskMutationVariables
>;
export const UpdateProjectTemplateTaskCustomAttributeDocument = gql`
  mutation updateProjectTemplateTaskCustomAttribute(
    $id: ID!
    $input: UpdateProjectTemplateTaskCustomAttributeInputInput!
  ) {
    updateProjectTemplateTaskCustomAttribute(id: $id, input: $input) {
      id
      listItem {
        id
        sortNo
        value
        versionNo
      }
      master {
        id
        listItem {
          id
          sortNo
          value
          versionNo
        }
        name
        required
        sortNo
        type
        versionNo
      }
      sortNo
      value
    }
  }
`;
export type UpdateProjectTemplateTaskCustomAttributeMutationFn = Apollo.MutationFunction<
  UpdateProjectTemplateTaskCustomAttributeMutation,
  UpdateProjectTemplateTaskCustomAttributeMutationVariables
>;

/**
 * __useUpdateProjectTemplateTaskCustomAttributeMutation__
 *
 * To run a mutation, you first call `useUpdateProjectTemplateTaskCustomAttributeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectTemplateTaskCustomAttributeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectTemplateTaskCustomAttributeMutation, { data, loading, error }] = useUpdateProjectTemplateTaskCustomAttributeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectTemplateTaskCustomAttributeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectTemplateTaskCustomAttributeMutation,
    UpdateProjectTemplateTaskCustomAttributeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProjectTemplateTaskCustomAttributeMutation,
    UpdateProjectTemplateTaskCustomAttributeMutationVariables
  >(UpdateProjectTemplateTaskCustomAttributeDocument, options);
}
export type UpdateProjectTemplateTaskCustomAttributeMutationHookResult = ReturnType<
  typeof useUpdateProjectTemplateTaskCustomAttributeMutation
>;
export type UpdateProjectTemplateTaskCustomAttributeMutationResult =
  Apollo.MutationResult<UpdateProjectTemplateTaskCustomAttributeMutation>;
export type UpdateProjectTemplateTaskCustomAttributeMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectTemplateTaskCustomAttributeMutation,
  UpdateProjectTemplateTaskCustomAttributeMutationVariables
>;
export const UpdateProjectTemplateTaskRemainderDocument = gql`
  mutation updateProjectTemplateTaskRemainder(
    $id: String!
    $input: UpdateProjectTemplateTaskRemainderInputInput!
  ) {
    updateProjectTemplateTaskRemainder(id: $id, input: $input) {
      beforeDeadlineMinutes
      id
      projectTemplateTask {
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        id
        priority
        projectTemplate {
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          description
          estimateTimeSec
          id
          name
          priority
          sortNoInList
          versionNo
        }
        projectTemplateTaskStatus {
          endStatus
          id
          name
          projectTemplate {
            description
            estimateTimeSec
            id
            name
            priority
            sortNoInList
            versionNo
          }
          sortNo
          versionNo
        }
        sortNoInList
        sortNoInTaskStatus
        title
        versionNo
      }
      targetMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      taskRemainderDestType
      taskRemainderType
    }
  }
`;
export type UpdateProjectTemplateTaskRemainderMutationFn = Apollo.MutationFunction<
  UpdateProjectTemplateTaskRemainderMutation,
  UpdateProjectTemplateTaskRemainderMutationVariables
>;

/**
 * __useUpdateProjectTemplateTaskRemainderMutation__
 *
 * To run a mutation, you first call `useUpdateProjectTemplateTaskRemainderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectTemplateTaskRemainderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectTemplateTaskRemainderMutation, { data, loading, error }] = useUpdateProjectTemplateTaskRemainderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectTemplateTaskRemainderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectTemplateTaskRemainderMutation,
    UpdateProjectTemplateTaskRemainderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProjectTemplateTaskRemainderMutation,
    UpdateProjectTemplateTaskRemainderMutationVariables
  >(UpdateProjectTemplateTaskRemainderDocument, options);
}
export type UpdateProjectTemplateTaskRemainderMutationHookResult = ReturnType<
  typeof useUpdateProjectTemplateTaskRemainderMutation
>;
export type UpdateProjectTemplateTaskRemainderMutationResult =
  Apollo.MutationResult<UpdateProjectTemplateTaskRemainderMutation>;
export type UpdateProjectTemplateTaskRemainderMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectTemplateTaskRemainderMutation,
  UpdateProjectTemplateTaskRemainderMutationVariables
>;
export const UpdateProjectTemplateTaskSortNoInListDocument = gql`
  mutation updateProjectTemplateTaskSortNoInList(
    $id: ID!
    $input: UpdateProjectTemplateTaskSortNoInListInputInput!
  ) {
    updateProjectTemplateTaskSortNoInList(id: $id, input: $input) {
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      id
      priority
      projectTemplate {
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        description
        estimateTimeSec
        id
        name
        priority
        sortNoInList
        versionNo
      }
      projectTemplateTaskStatus {
        endStatus
        id
        name
        projectTemplate {
          description
          estimateTimeSec
          id
          name
          priority
          sortNoInList
          versionNo
        }
        sortNo
        versionNo
      }
      sortNoInList
      sortNoInTaskStatus
      title
      versionNo
    }
  }
`;
export type UpdateProjectTemplateTaskSortNoInListMutationFn = Apollo.MutationFunction<
  UpdateProjectTemplateTaskSortNoInListMutation,
  UpdateProjectTemplateTaskSortNoInListMutationVariables
>;

/**
 * __useUpdateProjectTemplateTaskSortNoInListMutation__
 *
 * To run a mutation, you first call `useUpdateProjectTemplateTaskSortNoInListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectTemplateTaskSortNoInListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectTemplateTaskSortNoInListMutation, { data, loading, error }] = useUpdateProjectTemplateTaskSortNoInListMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectTemplateTaskSortNoInListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectTemplateTaskSortNoInListMutation,
    UpdateProjectTemplateTaskSortNoInListMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProjectTemplateTaskSortNoInListMutation,
    UpdateProjectTemplateTaskSortNoInListMutationVariables
  >(UpdateProjectTemplateTaskSortNoInListDocument, options);
}
export type UpdateProjectTemplateTaskSortNoInListMutationHookResult = ReturnType<
  typeof useUpdateProjectTemplateTaskSortNoInListMutation
>;
export type UpdateProjectTemplateTaskSortNoInListMutationResult =
  Apollo.MutationResult<UpdateProjectTemplateTaskSortNoInListMutation>;
export type UpdateProjectTemplateTaskSortNoInListMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectTemplateTaskSortNoInListMutation,
  UpdateProjectTemplateTaskSortNoInListMutationVariables
>;
export const UpdateProjectTemplateTaskSortNoInTaskStatusDocument = gql`
  mutation updateProjectTemplateTaskSortNoInTaskStatus(
    $id: ID!
    $input: UpdateProjectTemplateTaskStatusTaskInputInput!
  ) {
    updateProjectTemplateTaskSortNoInTaskStatus(id: $id, input: $input) {
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      id
      priority
      projectTemplate {
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        description
        estimateTimeSec
        id
        name
        priority
        sortNoInList
        versionNo
      }
      projectTemplateTaskStatus {
        endStatus
        id
        name
        projectTemplate {
          description
          estimateTimeSec
          id
          name
          priority
          sortNoInList
          versionNo
        }
        sortNo
        versionNo
      }
      sortNoInList
      sortNoInTaskStatus
      title
      versionNo
    }
  }
`;
export type UpdateProjectTemplateTaskSortNoInTaskStatusMutationFn = Apollo.MutationFunction<
  UpdateProjectTemplateTaskSortNoInTaskStatusMutation,
  UpdateProjectTemplateTaskSortNoInTaskStatusMutationVariables
>;

/**
 * __useUpdateProjectTemplateTaskSortNoInTaskStatusMutation__
 *
 * To run a mutation, you first call `useUpdateProjectTemplateTaskSortNoInTaskStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectTemplateTaskSortNoInTaskStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectTemplateTaskSortNoInTaskStatusMutation, { data, loading, error }] = useUpdateProjectTemplateTaskSortNoInTaskStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectTemplateTaskSortNoInTaskStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectTemplateTaskSortNoInTaskStatusMutation,
    UpdateProjectTemplateTaskSortNoInTaskStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProjectTemplateTaskSortNoInTaskStatusMutation,
    UpdateProjectTemplateTaskSortNoInTaskStatusMutationVariables
  >(UpdateProjectTemplateTaskSortNoInTaskStatusDocument, options);
}
export type UpdateProjectTemplateTaskSortNoInTaskStatusMutationHookResult = ReturnType<
  typeof useUpdateProjectTemplateTaskSortNoInTaskStatusMutation
>;
export type UpdateProjectTemplateTaskSortNoInTaskStatusMutationResult =
  Apollo.MutationResult<UpdateProjectTemplateTaskSortNoInTaskStatusMutation>;
export type UpdateProjectTemplateTaskSortNoInTaskStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectTemplateTaskSortNoInTaskStatusMutation,
  UpdateProjectTemplateTaskSortNoInTaskStatusMutationVariables
>;
export const UpdateProjectTemplateTaskStatusDocument = gql`
  mutation updateProjectTemplateTaskStatus(
    $id: ID!
    $input: UpdateProjectTemplateTaskStatusInputInput!
  ) {
    updateProjectTemplateTaskStatus(id: $id, input: $input) {
      endStatus
      id
      name
      projectTemplate {
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        description
        estimateTimeSec
        id
        name
        priority
        sortNoInList
        versionNo
      }
      sortNo
      versionNo
    }
  }
`;
export type UpdateProjectTemplateTaskStatusMutationFn = Apollo.MutationFunction<
  UpdateProjectTemplateTaskStatusMutation,
  UpdateProjectTemplateTaskStatusMutationVariables
>;

/**
 * __useUpdateProjectTemplateTaskStatusMutation__
 *
 * To run a mutation, you first call `useUpdateProjectTemplateTaskStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectTemplateTaskStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectTemplateTaskStatusMutation, { data, loading, error }] = useUpdateProjectTemplateTaskStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectTemplateTaskStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectTemplateTaskStatusMutation,
    UpdateProjectTemplateTaskStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProjectTemplateTaskStatusMutation,
    UpdateProjectTemplateTaskStatusMutationVariables
  >(UpdateProjectTemplateTaskStatusDocument, options);
}
export type UpdateProjectTemplateTaskStatusMutationHookResult = ReturnType<
  typeof useUpdateProjectTemplateTaskStatusMutation
>;
export type UpdateProjectTemplateTaskStatusMutationResult =
  Apollo.MutationResult<UpdateProjectTemplateTaskStatusMutation>;
export type UpdateProjectTemplateTaskStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectTemplateTaskStatusMutation,
  UpdateProjectTemplateTaskStatusMutationVariables
>;
export const UpdateSettlementRangeContractDocument = gql`
  mutation updateSettlementRangeContract(
    $id: ID!
    $input: UpdateSettlementRangeContractInputInput!
  ) {
    updateSettlementRangeContract(id: $id, input: $input) {
      id
      lowerHourPart {
        deducationHourlyUnitPrice
        lowerHour
      }
      member {
        laborCostWithoutTax
        memberId
        name
        profileImageUrl
        roundedTotalWorkingTimeSec
        salesAmountWithoutTax
        totalWorkingTimeSec
      }
      monthlyUnitPrice
      upperHourPart {
        excessHourlyUnitPrice
        upperHour
      }
      versionNo
    }
  }
`;
export type UpdateSettlementRangeContractMutationFn = Apollo.MutationFunction<
  UpdateSettlementRangeContractMutation,
  UpdateSettlementRangeContractMutationVariables
>;

/**
 * __useUpdateSettlementRangeContractMutation__
 *
 * To run a mutation, you first call `useUpdateSettlementRangeContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSettlementRangeContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSettlementRangeContractMutation, { data, loading, error }] = useUpdateSettlementRangeContractMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSettlementRangeContractMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSettlementRangeContractMutation,
    UpdateSettlementRangeContractMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSettlementRangeContractMutation,
    UpdateSettlementRangeContractMutationVariables
  >(UpdateSettlementRangeContractDocument, options);
}
export type UpdateSettlementRangeContractMutationHookResult = ReturnType<
  typeof useUpdateSettlementRangeContractMutation
>;
export type UpdateSettlementRangeContractMutationResult =
  Apollo.MutationResult<UpdateSettlementRangeContractMutation>;
export type UpdateSettlementRangeContractMutationOptions = Apollo.BaseMutationOptions<
  UpdateSettlementRangeContractMutation,
  UpdateSettlementRangeContractMutationVariables
>;
export const UpdateStatusOfProjectDocument = gql`
  mutation updateStatusOfProject($id: ID!, $input: UpdateProjectStatusOfProjectInputInput!) {
    updateStatusOfProject(id: $id, input: $input) {
      assignees {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      calendarLinkageCode
      client {
        code
        id
        name
        sortNo
        versionNo
      }
      color
      complete
      completeDateTime
      costBudgetWithoutTax
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      favoriteMembers {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      favoriteMembersInfo {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      id
      key
      laborCost
      laborCostWithoutTax
      laborShare
      name
      priority
      projectStatus {
        endStatus
        id
        name
        sortNo
        teamId
        versionNo
      }
      registerDateTime
      salesAmountWithoutTax
      scheduledEndDateTime
      scheduledStartDateTime
      sortNoInGanttChart
      sortNoInList
      sortNoInProjectStatus
      team {
        archived
        id
        name
        organization {
          allowConnectThirdPartyCalendar
          contactMailAddress
          contractPreriod
          id
          imageUrl
          ipRestrictionFlg
          name
          plan {
            code
            contractPreriod
            id
          }
          requiredTwoFactorAuth
          suspensionFlg
          versionNo
        }
        personalTeam
        status
        versionNo
      }
      totalScheduleTimeSec
      versionNo
      workingTimeSec
    }
  }
`;
export type UpdateStatusOfProjectMutationFn = Apollo.MutationFunction<
  UpdateStatusOfProjectMutation,
  UpdateStatusOfProjectMutationVariables
>;

/**
 * __useUpdateStatusOfProjectMutation__
 *
 * To run a mutation, you first call `useUpdateStatusOfProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStatusOfProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStatusOfProjectMutation, { data, loading, error }] = useUpdateStatusOfProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStatusOfProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateStatusOfProjectMutation,
    UpdateStatusOfProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateStatusOfProjectMutation, UpdateStatusOfProjectMutationVariables>(
    UpdateStatusOfProjectDocument,
    options
  );
}
export type UpdateStatusOfProjectMutationHookResult = ReturnType<
  typeof useUpdateStatusOfProjectMutation
>;
export type UpdateStatusOfProjectMutationResult =
  Apollo.MutationResult<UpdateStatusOfProjectMutation>;
export type UpdateStatusOfProjectMutationOptions = Apollo.BaseMutationOptions<
  UpdateStatusOfProjectMutation,
  UpdateStatusOfProjectMutationVariables
>;
export const UpdateStatusOfTaskDocument = gql`
  mutation updateStatusOfTask($id: ID!, $input: UpdateTaskStatusTaskInputInput!) {
    updateStatusOfTask(id: $id, input: $input) {
      assignees {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      asssignee {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      calendarLinkageCode
      completeDateTime
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      estimatedRemainingTimeSec
      favoriteMembers {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      favoriteMembersInfo {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      id
      laborCost
      priority
      progressDeviationRate
      progressRate
      project {
        assignees {
          sortNo
        }
        calendarLinkageCode
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        color
        complete
        completeDateTime
        costBudgetWithoutTax
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          sortNo
        }
        id
        key
        laborCost
        laborCostWithoutTax
        laborShare
        name
        priority
        projectStatus {
          endStatus
          id
          name
          sortNo
          teamId
          versionNo
        }
        registerDateTime
        salesAmountWithoutTax
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInProjectStatus
        team {
          archived
          id
          name
          organization {
            allowConnectThirdPartyCalendar
            contactMailAddress
            contractPreriod
            id
            imageUrl
            ipRestrictionFlg
            name
            plan {
              code
              contractPreriod
              id
            }
            requiredTwoFactorAuth
            suspensionFlg
            versionNo
          }
          personalTeam
          status
          versionNo
        }
        totalScheduleTimeSec
        versionNo
        workingTimeSec
      }
      registerDateTime
      remainingTimeSec
      scheduleWorkingTimeSec
      scheduledEndDateTime
      scheduledStartDateTime
      sortNoInGanttChart
      sortNoInList
      sortNoInMyTask
      sortNoInTaskStatus
      taskStatus {
        endStatus
        id
        name
        project {
          calendarLinkageCode
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          description
          estimateTimeSec
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        sortNo
        versionNo
      }
      title
      versionNo
      workingMembers {
        lastWorkingStartDateTime
        lastWorkingStopDateTime
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
      }
      workingTimeSec
    }
  }
`;
export type UpdateStatusOfTaskMutationFn = Apollo.MutationFunction<
  UpdateStatusOfTaskMutation,
  UpdateStatusOfTaskMutationVariables
>;

/**
 * __useUpdateStatusOfTaskMutation__
 *
 * To run a mutation, you first call `useUpdateStatusOfTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStatusOfTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStatusOfTaskMutation, { data, loading, error }] = useUpdateStatusOfTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStatusOfTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateStatusOfTaskMutation,
    UpdateStatusOfTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateStatusOfTaskMutation, UpdateStatusOfTaskMutationVariables>(
    UpdateStatusOfTaskDocument,
    options
  );
}
export type UpdateStatusOfTaskMutationHookResult = ReturnType<typeof useUpdateStatusOfTaskMutation>;
export type UpdateStatusOfTaskMutationResult = Apollo.MutationResult<UpdateStatusOfTaskMutation>;
export type UpdateStatusOfTaskMutationOptions = Apollo.BaseMutationOptions<
  UpdateStatusOfTaskMutation,
  UpdateStatusOfTaskMutationVariables
>;
export const UpdateSubTaskDocument = gql`
  mutation updateSubTask($id: ID!, $input: UpdateSubTaskInputInput!) {
    updateSubTask(id: $id, input: $input) {
      complete
      completeDateTime
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      id
      sortNo
      task {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        asssignee {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        calendarLinkageCode
        completeDateTime
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        estimatedRemainingTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        laborCost
        priority
        progressDeviationRate
        progressRate
        project {
          assignees {
            sortNo
          }
          calendarLinkageCode
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          estimateTimeSec
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            sortNo
          }
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          projectStatus {
            endStatus
            id
            name
            sortNo
            teamId
            versionNo
          }
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          team {
            archived
            id
            name
            organization {
              allowConnectThirdPartyCalendar
              contactMailAddress
              contractPreriod
              id
              imageUrl
              ipRestrictionFlg
              name
              plan {
                code
                contractPreriod
                id
              }
              requiredTwoFactorAuth
              suspensionFlg
              versionNo
            }
            personalTeam
            status
            versionNo
          }
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        registerDateTime
        remainingTimeSec
        scheduleWorkingTimeSec
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInMyTask
        sortNoInTaskStatus
        taskStatus {
          endStatus
          id
          name
          project {
            calendarLinkageCode
            color
            complete
            completeDateTime
            costBudgetWithoutTax
            description
            estimateTimeSec
            id
            key
            laborCost
            laborCostWithoutTax
            laborShare
            name
            priority
            registerDateTime
            salesAmountWithoutTax
            scheduledEndDateTime
            scheduledStartDateTime
            sortNoInGanttChart
            sortNoInList
            sortNoInProjectStatus
            totalScheduleTimeSec
            versionNo
            workingTimeSec
          }
          sortNo
          versionNo
        }
        title
        versionNo
        workingMembers {
          lastWorkingStartDateTime
          lastWorkingStopDateTime
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
        }
        workingTimeSec
      }
      title
      versionNo
    }
  }
`;
export type UpdateSubTaskMutationFn = Apollo.MutationFunction<
  UpdateSubTaskMutation,
  UpdateSubTaskMutationVariables
>;

/**
 * __useUpdateSubTaskMutation__
 *
 * To run a mutation, you first call `useUpdateSubTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubTaskMutation, { data, loading, error }] = useUpdateSubTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSubTaskMutation, UpdateSubTaskMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSubTaskMutation, UpdateSubTaskMutationVariables>(
    UpdateSubTaskDocument,
    options
  );
}
export type UpdateSubTaskMutationHookResult = ReturnType<typeof useUpdateSubTaskMutation>;
export type UpdateSubTaskMutationResult = Apollo.MutationResult<UpdateSubTaskMutation>;
export type UpdateSubTaskMutationOptions = Apollo.BaseMutationOptions<
  UpdateSubTaskMutation,
  UpdateSubTaskMutationVariables
>;
export const UpdateSubTaskSortNoDocument = gql`
  mutation updateSubTaskSortNo($id: ID!, $input: UpdateSubTaskSortNoInputInput!) {
    updateSubTaskSortNo(id: $id, input: $input) {
      complete
      completeDateTime
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      id
      sortNo
      task {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        asssignee {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        calendarLinkageCode
        completeDateTime
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        estimatedRemainingTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        laborCost
        priority
        progressDeviationRate
        progressRate
        project {
          assignees {
            sortNo
          }
          calendarLinkageCode
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          estimateTimeSec
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            sortNo
          }
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          projectStatus {
            endStatus
            id
            name
            sortNo
            teamId
            versionNo
          }
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          team {
            archived
            id
            name
            organization {
              allowConnectThirdPartyCalendar
              contactMailAddress
              contractPreriod
              id
              imageUrl
              ipRestrictionFlg
              name
              plan {
                code
                contractPreriod
                id
              }
              requiredTwoFactorAuth
              suspensionFlg
              versionNo
            }
            personalTeam
            status
            versionNo
          }
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        registerDateTime
        remainingTimeSec
        scheduleWorkingTimeSec
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInMyTask
        sortNoInTaskStatus
        taskStatus {
          endStatus
          id
          name
          project {
            calendarLinkageCode
            color
            complete
            completeDateTime
            costBudgetWithoutTax
            description
            estimateTimeSec
            id
            key
            laborCost
            laborCostWithoutTax
            laborShare
            name
            priority
            registerDateTime
            salesAmountWithoutTax
            scheduledEndDateTime
            scheduledStartDateTime
            sortNoInGanttChart
            sortNoInList
            sortNoInProjectStatus
            totalScheduleTimeSec
            versionNo
            workingTimeSec
          }
          sortNo
          versionNo
        }
        title
        versionNo
        workingMembers {
          lastWorkingStartDateTime
          lastWorkingStopDateTime
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
        }
        workingTimeSec
      }
      title
      versionNo
    }
  }
`;
export type UpdateSubTaskSortNoMutationFn = Apollo.MutationFunction<
  UpdateSubTaskSortNoMutation,
  UpdateSubTaskSortNoMutationVariables
>;

/**
 * __useUpdateSubTaskSortNoMutation__
 *
 * To run a mutation, you first call `useUpdateSubTaskSortNoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubTaskSortNoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubTaskSortNoMutation, { data, loading, error }] = useUpdateSubTaskSortNoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubTaskSortNoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSubTaskSortNoMutation,
    UpdateSubTaskSortNoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSubTaskSortNoMutation, UpdateSubTaskSortNoMutationVariables>(
    UpdateSubTaskSortNoDocument,
    options
  );
}
export type UpdateSubTaskSortNoMutationHookResult = ReturnType<
  typeof useUpdateSubTaskSortNoMutation
>;
export type UpdateSubTaskSortNoMutationResult = Apollo.MutationResult<UpdateSubTaskSortNoMutation>;
export type UpdateSubTaskSortNoMutationOptions = Apollo.BaseMutationOptions<
  UpdateSubTaskSortNoMutation,
  UpdateSubTaskSortNoMutationVariables
>;
export const UpdateTagDocument = gql`
  mutation updateTag($id: ID!, $input: UpdateTagInputInput!) {
    updateTag(id: $id, input: $input) {
      id
      name
      note
      organization {
        allowConnectThirdPartyCalendar
        contactMailAddress
        contractPreriod
        id
        imageUrl
        ipRestrictionFlg
        name
        plan {
          code
          contractPreriod
          id
        }
        requiredTwoFactorAuth
        suspensionFlg
        versionNo
      }
      relatedTaskCount
      sortNo
      versionNo
    }
  }
`;
export type UpdateTagMutationFn = Apollo.MutationFunction<
  UpdateTagMutation,
  UpdateTagMutationVariables
>;

/**
 * __useUpdateTagMutation__
 *
 * To run a mutation, you first call `useUpdateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTagMutation, { data, loading, error }] = useUpdateTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTagMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTagMutation, UpdateTagMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTagMutation, UpdateTagMutationVariables>(
    UpdateTagDocument,
    options
  );
}
export type UpdateTagMutationHookResult = ReturnType<typeof useUpdateTagMutation>;
export type UpdateTagMutationResult = Apollo.MutationResult<UpdateTagMutation>;
export type UpdateTagMutationOptions = Apollo.BaseMutationOptions<
  UpdateTagMutation,
  UpdateTagMutationVariables
>;
export const UpdateTagSortNoDocument = gql`
  mutation updateTagSortNo($id: ID!, $input: UpdateTagSortNoInputInput) {
    updateTagSortNo(id: $id, input: $input) {
      id
      name
      note
      organization {
        allowConnectThirdPartyCalendar
        contactMailAddress
        contractPreriod
        id
        imageUrl
        ipRestrictionFlg
        name
        plan {
          code
          contractPreriod
          id
        }
        requiredTwoFactorAuth
        suspensionFlg
        versionNo
      }
      relatedTaskCount
      sortNo
      versionNo
    }
  }
`;
export type UpdateTagSortNoMutationFn = Apollo.MutationFunction<
  UpdateTagSortNoMutation,
  UpdateTagSortNoMutationVariables
>;

/**
 * __useUpdateTagSortNoMutation__
 *
 * To run a mutation, you first call `useUpdateTagSortNoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTagSortNoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTagSortNoMutation, { data, loading, error }] = useUpdateTagSortNoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTagSortNoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTagSortNoMutation,
    UpdateTagSortNoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTagSortNoMutation, UpdateTagSortNoMutationVariables>(
    UpdateTagSortNoDocument,
    options
  );
}
export type UpdateTagSortNoMutationHookResult = ReturnType<typeof useUpdateTagSortNoMutation>;
export type UpdateTagSortNoMutationResult = Apollo.MutationResult<UpdateTagSortNoMutation>;
export type UpdateTagSortNoMutationOptions = Apollo.BaseMutationOptions<
  UpdateTagSortNoMutation,
  UpdateTagSortNoMutationVariables
>;
export const UpdateTaskDocument = gql`
  mutation updateTask($id: ID!, $input: UpdateTaskInputInput!) {
    updateTask(id: $id, input: $input) {
      assignees {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      asssignee {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      calendarLinkageCode
      completeDateTime
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      estimatedRemainingTimeSec
      favoriteMembers {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      favoriteMembersInfo {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      id
      laborCost
      priority
      progressDeviationRate
      progressRate
      project {
        assignees {
          sortNo
        }
        calendarLinkageCode
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        color
        complete
        completeDateTime
        costBudgetWithoutTax
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          sortNo
        }
        id
        key
        laborCost
        laborCostWithoutTax
        laborShare
        name
        priority
        projectStatus {
          endStatus
          id
          name
          sortNo
          teamId
          versionNo
        }
        registerDateTime
        salesAmountWithoutTax
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInProjectStatus
        team {
          archived
          id
          name
          organization {
            allowConnectThirdPartyCalendar
            contactMailAddress
            contractPreriod
            id
            imageUrl
            ipRestrictionFlg
            name
            plan {
              code
              contractPreriod
              id
            }
            requiredTwoFactorAuth
            suspensionFlg
            versionNo
          }
          personalTeam
          status
          versionNo
        }
        totalScheduleTimeSec
        versionNo
        workingTimeSec
      }
      registerDateTime
      remainingTimeSec
      scheduleWorkingTimeSec
      scheduledEndDateTime
      scheduledStartDateTime
      sortNoInGanttChart
      sortNoInList
      sortNoInMyTask
      sortNoInTaskStatus
      taskStatus {
        endStatus
        id
        name
        project {
          calendarLinkageCode
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          description
          estimateTimeSec
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        sortNo
        versionNo
      }
      title
      versionNo
      workingMembers {
        lastWorkingStartDateTime
        lastWorkingStopDateTime
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
      }
      workingTimeSec
    }
  }
`;
export type UpdateTaskMutationFn = Apollo.MutationFunction<
  UpdateTaskMutation,
  UpdateTaskMutationVariables
>;

/**
 * __useUpdateTaskMutation__
 *
 * To run a mutation, you first call `useUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskMutation, { data, loading, error }] = useUpdateTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTaskMutation, UpdateTaskMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTaskMutation, UpdateTaskMutationVariables>(
    UpdateTaskDocument,
    options
  );
}
export type UpdateTaskMutationHookResult = ReturnType<typeof useUpdateTaskMutation>;
export type UpdateTaskMutationResult = Apollo.MutationResult<UpdateTaskMutation>;
export type UpdateTaskMutationOptions = Apollo.BaseMutationOptions<
  UpdateTaskMutation,
  UpdateTaskMutationVariables
>;
export const UpdateTaskCommentDocument = gql`
  mutation updateTaskComment($id: ID!, $input: UpdateTaskCommentInputInput!) {
    updateTaskComment(id: $id, input: $input) {
      comment
      commentedDateTime
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      id
      projectId
      projectName
      task {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        asssignee {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        calendarLinkageCode
        completeDateTime
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        estimatedRemainingTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        laborCost
        priority
        progressDeviationRate
        progressRate
        project {
          assignees {
            sortNo
          }
          calendarLinkageCode
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          estimateTimeSec
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            sortNo
          }
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          projectStatus {
            endStatus
            id
            name
            sortNo
            teamId
            versionNo
          }
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          team {
            archived
            id
            name
            organization {
              allowConnectThirdPartyCalendar
              contactMailAddress
              contractPreriod
              id
              imageUrl
              ipRestrictionFlg
              name
              plan {
                code
                contractPreriod
                id
              }
              requiredTwoFactorAuth
              suspensionFlg
              versionNo
            }
            personalTeam
            status
            versionNo
          }
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        registerDateTime
        remainingTimeSec
        scheduleWorkingTimeSec
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInMyTask
        sortNoInTaskStatus
        taskStatus {
          endStatus
          id
          name
          project {
            calendarLinkageCode
            color
            complete
            completeDateTime
            costBudgetWithoutTax
            description
            estimateTimeSec
            id
            key
            laborCost
            laborCostWithoutTax
            laborShare
            name
            priority
            registerDateTime
            salesAmountWithoutTax
            scheduledEndDateTime
            scheduledStartDateTime
            sortNoInGanttChart
            sortNoInList
            sortNoInProjectStatus
            totalScheduleTimeSec
            versionNo
            workingTimeSec
          }
          sortNo
          versionNo
        }
        title
        versionNo
        workingMembers {
          lastWorkingStartDateTime
          lastWorkingStopDateTime
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
        }
        workingTimeSec
      }
      taskId
      taskTitle
      teamId
      teamName
      versionNo
    }
  }
`;
export type UpdateTaskCommentMutationFn = Apollo.MutationFunction<
  UpdateTaskCommentMutation,
  UpdateTaskCommentMutationVariables
>;

/**
 * __useUpdateTaskCommentMutation__
 *
 * To run a mutation, you first call `useUpdateTaskCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskCommentMutation, { data, loading, error }] = useUpdateTaskCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaskCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTaskCommentMutation,
    UpdateTaskCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTaskCommentMutation, UpdateTaskCommentMutationVariables>(
    UpdateTaskCommentDocument,
    options
  );
}
export type UpdateTaskCommentMutationHookResult = ReturnType<typeof useUpdateTaskCommentMutation>;
export type UpdateTaskCommentMutationResult = Apollo.MutationResult<UpdateTaskCommentMutation>;
export type UpdateTaskCommentMutationOptions = Apollo.BaseMutationOptions<
  UpdateTaskCommentMutation,
  UpdateTaskCommentMutationVariables
>;
export const UpdateTaskCustomAttributeDocument = gql`
  mutation updateTaskCustomAttribute($id: ID!, $input: UpdateTaskCustomAttributeInputInput!) {
    updateTaskCustomAttribute(id: $id, input: $input) {
      id
      listItem {
        id
        sortNo
        value
        versionNo
      }
      master {
        id
        listItem {
          id
          sortNo
          value
          versionNo
        }
        name
        required
        sortNo
        type
        versionNo
      }
      sortNo
      value
    }
  }
`;
export type UpdateTaskCustomAttributeMutationFn = Apollo.MutationFunction<
  UpdateTaskCustomAttributeMutation,
  UpdateTaskCustomAttributeMutationVariables
>;

/**
 * __useUpdateTaskCustomAttributeMutation__
 *
 * To run a mutation, you first call `useUpdateTaskCustomAttributeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskCustomAttributeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskCustomAttributeMutation, { data, loading, error }] = useUpdateTaskCustomAttributeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaskCustomAttributeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTaskCustomAttributeMutation,
    UpdateTaskCustomAttributeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTaskCustomAttributeMutation,
    UpdateTaskCustomAttributeMutationVariables
  >(UpdateTaskCustomAttributeDocument, options);
}
export type UpdateTaskCustomAttributeMutationHookResult = ReturnType<
  typeof useUpdateTaskCustomAttributeMutation
>;
export type UpdateTaskCustomAttributeMutationResult =
  Apollo.MutationResult<UpdateTaskCustomAttributeMutation>;
export type UpdateTaskCustomAttributeMutationOptions = Apollo.BaseMutationOptions<
  UpdateTaskCustomAttributeMutation,
  UpdateTaskCustomAttributeMutationVariables
>;
export const UpdateTaskCustomAttributeMasterDocument = gql`
  mutation updateTaskCustomAttributeMaster(
    $id: ID!
    $input: UpdateTasktCustomAttributeMasterInputInput!
  ) {
    updateTaskCustomAttributeMaster(id: $id, input: $input) {
      id
      listItem {
        id
        sortNo
        value
        versionNo
      }
      name
      required
      sortNo
      type
      versionNo
    }
  }
`;
export type UpdateTaskCustomAttributeMasterMutationFn = Apollo.MutationFunction<
  UpdateTaskCustomAttributeMasterMutation,
  UpdateTaskCustomAttributeMasterMutationVariables
>;

/**
 * __useUpdateTaskCustomAttributeMasterMutation__
 *
 * To run a mutation, you first call `useUpdateTaskCustomAttributeMasterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskCustomAttributeMasterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskCustomAttributeMasterMutation, { data, loading, error }] = useUpdateTaskCustomAttributeMasterMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaskCustomAttributeMasterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTaskCustomAttributeMasterMutation,
    UpdateTaskCustomAttributeMasterMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTaskCustomAttributeMasterMutation,
    UpdateTaskCustomAttributeMasterMutationVariables
  >(UpdateTaskCustomAttributeMasterDocument, options);
}
export type UpdateTaskCustomAttributeMasterMutationHookResult = ReturnType<
  typeof useUpdateTaskCustomAttributeMasterMutation
>;
export type UpdateTaskCustomAttributeMasterMutationResult =
  Apollo.MutationResult<UpdateTaskCustomAttributeMasterMutation>;
export type UpdateTaskCustomAttributeMasterMutationOptions = Apollo.BaseMutationOptions<
  UpdateTaskCustomAttributeMasterMutation,
  UpdateTaskCustomAttributeMasterMutationVariables
>;
export const UpdateTaskCustomAttributeMasterListItemDocument = gql`
  mutation updateTaskCustomAttributeMasterListItem(
    $id: ID!
    $input: UpdateTaskCustomAttributeMasterListItemInputInput!
  ) {
    updateTaskCustomAttributeMasterListItem(id: $id, input: $input) {
      id
      sortNo
      value
      versionNo
    }
  }
`;
export type UpdateTaskCustomAttributeMasterListItemMutationFn = Apollo.MutationFunction<
  UpdateTaskCustomAttributeMasterListItemMutation,
  UpdateTaskCustomAttributeMasterListItemMutationVariables
>;

/**
 * __useUpdateTaskCustomAttributeMasterListItemMutation__
 *
 * To run a mutation, you first call `useUpdateTaskCustomAttributeMasterListItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskCustomAttributeMasterListItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskCustomAttributeMasterListItemMutation, { data, loading, error }] = useUpdateTaskCustomAttributeMasterListItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaskCustomAttributeMasterListItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTaskCustomAttributeMasterListItemMutation,
    UpdateTaskCustomAttributeMasterListItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTaskCustomAttributeMasterListItemMutation,
    UpdateTaskCustomAttributeMasterListItemMutationVariables
  >(UpdateTaskCustomAttributeMasterListItemDocument, options);
}
export type UpdateTaskCustomAttributeMasterListItemMutationHookResult = ReturnType<
  typeof useUpdateTaskCustomAttributeMasterListItemMutation
>;
export type UpdateTaskCustomAttributeMasterListItemMutationResult =
  Apollo.MutationResult<UpdateTaskCustomAttributeMasterListItemMutation>;
export type UpdateTaskCustomAttributeMasterListItemMutationOptions = Apollo.BaseMutationOptions<
  UpdateTaskCustomAttributeMasterListItemMutation,
  UpdateTaskCustomAttributeMasterListItemMutationVariables
>;
export const UpdateTaskRemainderDocument = gql`
  mutation updateTaskRemainder($id: String!, $input: UpdateTaskRemainderInputInput!) {
    updateTaskRemainder(id: $id, input: $input) {
      beforeDeadlineMinutes
      id
      notificationSentFlg
      targetMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      task {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        asssignee {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        calendarLinkageCode
        completeDateTime
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        estimatedRemainingTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        laborCost
        priority
        progressDeviationRate
        progressRate
        project {
          assignees {
            sortNo
          }
          calendarLinkageCode
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          estimateTimeSec
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            sortNo
          }
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          projectStatus {
            endStatus
            id
            name
            sortNo
            teamId
            versionNo
          }
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          team {
            archived
            id
            name
            organization {
              allowConnectThirdPartyCalendar
              contactMailAddress
              contractPreriod
              id
              imageUrl
              ipRestrictionFlg
              name
              plan {
                code
                contractPreriod
                id
              }
              requiredTwoFactorAuth
              suspensionFlg
              versionNo
            }
            personalTeam
            status
            versionNo
          }
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        registerDateTime
        remainingTimeSec
        scheduleWorkingTimeSec
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInMyTask
        sortNoInTaskStatus
        taskStatus {
          endStatus
          id
          name
          project {
            calendarLinkageCode
            color
            complete
            completeDateTime
            costBudgetWithoutTax
            description
            estimateTimeSec
            id
            key
            laborCost
            laborCostWithoutTax
            laborShare
            name
            priority
            registerDateTime
            salesAmountWithoutTax
            scheduledEndDateTime
            scheduledStartDateTime
            sortNoInGanttChart
            sortNoInList
            sortNoInProjectStatus
            totalScheduleTimeSec
            versionNo
            workingTimeSec
          }
          sortNo
          versionNo
        }
        title
        versionNo
        workingMembers {
          lastWorkingStartDateTime
          lastWorkingStopDateTime
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
        }
        workingTimeSec
      }
      taskRemainderDestType
      taskRemainderType
    }
  }
`;
export type UpdateTaskRemainderMutationFn = Apollo.MutationFunction<
  UpdateTaskRemainderMutation,
  UpdateTaskRemainderMutationVariables
>;

/**
 * __useUpdateTaskRemainderMutation__
 *
 * To run a mutation, you first call `useUpdateTaskRemainderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskRemainderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskRemainderMutation, { data, loading, error }] = useUpdateTaskRemainderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaskRemainderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTaskRemainderMutation,
    UpdateTaskRemainderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTaskRemainderMutation, UpdateTaskRemainderMutationVariables>(
    UpdateTaskRemainderDocument,
    options
  );
}
export type UpdateTaskRemainderMutationHookResult = ReturnType<
  typeof useUpdateTaskRemainderMutation
>;
export type UpdateTaskRemainderMutationResult = Apollo.MutationResult<UpdateTaskRemainderMutation>;
export type UpdateTaskRemainderMutationOptions = Apollo.BaseMutationOptions<
  UpdateTaskRemainderMutation,
  UpdateTaskRemainderMutationVariables
>;
export const UpdateTaskSortNoInFavoriteTaskDocument = gql`
  mutation updateTaskSortNoInFavoriteTask(
    $id: ID!
    $input: UpdateTaskSortNoInFavoriteTaskInputInput!
  ) {
    updateTaskSortNoInFavoriteTask(id: $id, input: $input) {
      assignees {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      asssignee {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      calendarLinkageCode
      completeDateTime
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      estimatedRemainingTimeSec
      favoriteMembers {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      favoriteMembersInfo {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      id
      laborCost
      priority
      progressDeviationRate
      progressRate
      project {
        assignees {
          sortNo
        }
        calendarLinkageCode
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        color
        complete
        completeDateTime
        costBudgetWithoutTax
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          sortNo
        }
        id
        key
        laborCost
        laborCostWithoutTax
        laborShare
        name
        priority
        projectStatus {
          endStatus
          id
          name
          sortNo
          teamId
          versionNo
        }
        registerDateTime
        salesAmountWithoutTax
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInProjectStatus
        team {
          archived
          id
          name
          organization {
            allowConnectThirdPartyCalendar
            contactMailAddress
            contractPreriod
            id
            imageUrl
            ipRestrictionFlg
            name
            plan {
              code
              contractPreriod
              id
            }
            requiredTwoFactorAuth
            suspensionFlg
            versionNo
          }
          personalTeam
          status
          versionNo
        }
        totalScheduleTimeSec
        versionNo
        workingTimeSec
      }
      registerDateTime
      remainingTimeSec
      scheduleWorkingTimeSec
      scheduledEndDateTime
      scheduledStartDateTime
      sortNoInGanttChart
      sortNoInList
      sortNoInMyTask
      sortNoInTaskStatus
      taskStatus {
        endStatus
        id
        name
        project {
          calendarLinkageCode
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          description
          estimateTimeSec
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        sortNo
        versionNo
      }
      title
      versionNo
      workingMembers {
        lastWorkingStartDateTime
        lastWorkingStopDateTime
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
      }
      workingTimeSec
    }
  }
`;
export type UpdateTaskSortNoInFavoriteTaskMutationFn = Apollo.MutationFunction<
  UpdateTaskSortNoInFavoriteTaskMutation,
  UpdateTaskSortNoInFavoriteTaskMutationVariables
>;

/**
 * __useUpdateTaskSortNoInFavoriteTaskMutation__
 *
 * To run a mutation, you first call `useUpdateTaskSortNoInFavoriteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskSortNoInFavoriteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskSortNoInFavoriteTaskMutation, { data, loading, error }] = useUpdateTaskSortNoInFavoriteTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaskSortNoInFavoriteTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTaskSortNoInFavoriteTaskMutation,
    UpdateTaskSortNoInFavoriteTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTaskSortNoInFavoriteTaskMutation,
    UpdateTaskSortNoInFavoriteTaskMutationVariables
  >(UpdateTaskSortNoInFavoriteTaskDocument, options);
}
export type UpdateTaskSortNoInFavoriteTaskMutationHookResult = ReturnType<
  typeof useUpdateTaskSortNoInFavoriteTaskMutation
>;
export type UpdateTaskSortNoInFavoriteTaskMutationResult =
  Apollo.MutationResult<UpdateTaskSortNoInFavoriteTaskMutation>;
export type UpdateTaskSortNoInFavoriteTaskMutationOptions = Apollo.BaseMutationOptions<
  UpdateTaskSortNoInFavoriteTaskMutation,
  UpdateTaskSortNoInFavoriteTaskMutationVariables
>;
export const UpdateTaskSortNoInGanttChartDocument = gql`
  mutation updateTaskSortNoInGanttChart($id: ID!, $input: UpdateTaskSortNoInGanttChartInputInput!) {
    updateTaskSortNoInGanttChart(id: $id, input: $input) {
      assignees {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      asssignee {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      calendarLinkageCode
      completeDateTime
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      estimatedRemainingTimeSec
      favoriteMembers {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      favoriteMembersInfo {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      id
      laborCost
      priority
      progressDeviationRate
      progressRate
      project {
        assignees {
          sortNo
        }
        calendarLinkageCode
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        color
        complete
        completeDateTime
        costBudgetWithoutTax
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          sortNo
        }
        id
        key
        laborCost
        laborCostWithoutTax
        laborShare
        name
        priority
        projectStatus {
          endStatus
          id
          name
          sortNo
          teamId
          versionNo
        }
        registerDateTime
        salesAmountWithoutTax
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInProjectStatus
        team {
          archived
          id
          name
          organization {
            allowConnectThirdPartyCalendar
            contactMailAddress
            contractPreriod
            id
            imageUrl
            ipRestrictionFlg
            name
            plan {
              code
              contractPreriod
              id
            }
            requiredTwoFactorAuth
            suspensionFlg
            versionNo
          }
          personalTeam
          status
          versionNo
        }
        totalScheduleTimeSec
        versionNo
        workingTimeSec
      }
      registerDateTime
      remainingTimeSec
      scheduleWorkingTimeSec
      scheduledEndDateTime
      scheduledStartDateTime
      sortNoInGanttChart
      sortNoInList
      sortNoInMyTask
      sortNoInTaskStatus
      taskStatus {
        endStatus
        id
        name
        project {
          calendarLinkageCode
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          description
          estimateTimeSec
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        sortNo
        versionNo
      }
      title
      versionNo
      workingMembers {
        lastWorkingStartDateTime
        lastWorkingStopDateTime
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
      }
      workingTimeSec
    }
  }
`;
export type UpdateTaskSortNoInGanttChartMutationFn = Apollo.MutationFunction<
  UpdateTaskSortNoInGanttChartMutation,
  UpdateTaskSortNoInGanttChartMutationVariables
>;

/**
 * __useUpdateTaskSortNoInGanttChartMutation__
 *
 * To run a mutation, you first call `useUpdateTaskSortNoInGanttChartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskSortNoInGanttChartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskSortNoInGanttChartMutation, { data, loading, error }] = useUpdateTaskSortNoInGanttChartMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaskSortNoInGanttChartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTaskSortNoInGanttChartMutation,
    UpdateTaskSortNoInGanttChartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTaskSortNoInGanttChartMutation,
    UpdateTaskSortNoInGanttChartMutationVariables
  >(UpdateTaskSortNoInGanttChartDocument, options);
}
export type UpdateTaskSortNoInGanttChartMutationHookResult = ReturnType<
  typeof useUpdateTaskSortNoInGanttChartMutation
>;
export type UpdateTaskSortNoInGanttChartMutationResult =
  Apollo.MutationResult<UpdateTaskSortNoInGanttChartMutation>;
export type UpdateTaskSortNoInGanttChartMutationOptions = Apollo.BaseMutationOptions<
  UpdateTaskSortNoInGanttChartMutation,
  UpdateTaskSortNoInGanttChartMutationVariables
>;
export const UpdateTaskSortNoInListDocument = gql`
  mutation updateTaskSortNoInList($id: ID!, $input: UpdateTaskSortNoInListInputInput!) {
    updateTaskSortNoInList(id: $id, input: $input) {
      assignees {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      asssignee {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      calendarLinkageCode
      completeDateTime
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      estimatedRemainingTimeSec
      favoriteMembers {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      favoriteMembersInfo {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      id
      laborCost
      priority
      progressDeviationRate
      progressRate
      project {
        assignees {
          sortNo
        }
        calendarLinkageCode
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        color
        complete
        completeDateTime
        costBudgetWithoutTax
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          sortNo
        }
        id
        key
        laborCost
        laborCostWithoutTax
        laborShare
        name
        priority
        projectStatus {
          endStatus
          id
          name
          sortNo
          teamId
          versionNo
        }
        registerDateTime
        salesAmountWithoutTax
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInProjectStatus
        team {
          archived
          id
          name
          organization {
            allowConnectThirdPartyCalendar
            contactMailAddress
            contractPreriod
            id
            imageUrl
            ipRestrictionFlg
            name
            plan {
              code
              contractPreriod
              id
            }
            requiredTwoFactorAuth
            suspensionFlg
            versionNo
          }
          personalTeam
          status
          versionNo
        }
        totalScheduleTimeSec
        versionNo
        workingTimeSec
      }
      registerDateTime
      remainingTimeSec
      scheduleWorkingTimeSec
      scheduledEndDateTime
      scheduledStartDateTime
      sortNoInGanttChart
      sortNoInList
      sortNoInMyTask
      sortNoInTaskStatus
      taskStatus {
        endStatus
        id
        name
        project {
          calendarLinkageCode
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          description
          estimateTimeSec
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        sortNo
        versionNo
      }
      title
      versionNo
      workingMembers {
        lastWorkingStartDateTime
        lastWorkingStopDateTime
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
      }
      workingTimeSec
    }
  }
`;
export type UpdateTaskSortNoInListMutationFn = Apollo.MutationFunction<
  UpdateTaskSortNoInListMutation,
  UpdateTaskSortNoInListMutationVariables
>;

/**
 * __useUpdateTaskSortNoInListMutation__
 *
 * To run a mutation, you first call `useUpdateTaskSortNoInListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskSortNoInListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskSortNoInListMutation, { data, loading, error }] = useUpdateTaskSortNoInListMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaskSortNoInListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTaskSortNoInListMutation,
    UpdateTaskSortNoInListMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTaskSortNoInListMutation,
    UpdateTaskSortNoInListMutationVariables
  >(UpdateTaskSortNoInListDocument, options);
}
export type UpdateTaskSortNoInListMutationHookResult = ReturnType<
  typeof useUpdateTaskSortNoInListMutation
>;
export type UpdateTaskSortNoInListMutationResult =
  Apollo.MutationResult<UpdateTaskSortNoInListMutation>;
export type UpdateTaskSortNoInListMutationOptions = Apollo.BaseMutationOptions<
  UpdateTaskSortNoInListMutation,
  UpdateTaskSortNoInListMutationVariables
>;
export const UpdateTaskSortNoInMyTaskDocument = gql`
  mutation updateTaskSortNoInMyTask($id: ID!, $input: UpdateTaskSortNoInMyTaskInputInput!) {
    updateTaskSortNoInMyTask(id: $id, input: $input) {
      assignees {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      asssignee {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      calendarLinkageCode
      completeDateTime
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      estimatedRemainingTimeSec
      favoriteMembers {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      favoriteMembersInfo {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      id
      laborCost
      priority
      progressDeviationRate
      progressRate
      project {
        assignees {
          sortNo
        }
        calendarLinkageCode
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        color
        complete
        completeDateTime
        costBudgetWithoutTax
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          sortNo
        }
        id
        key
        laborCost
        laborCostWithoutTax
        laborShare
        name
        priority
        projectStatus {
          endStatus
          id
          name
          sortNo
          teamId
          versionNo
        }
        registerDateTime
        salesAmountWithoutTax
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInProjectStatus
        team {
          archived
          id
          name
          organization {
            allowConnectThirdPartyCalendar
            contactMailAddress
            contractPreriod
            id
            imageUrl
            ipRestrictionFlg
            name
            plan {
              code
              contractPreriod
              id
            }
            requiredTwoFactorAuth
            suspensionFlg
            versionNo
          }
          personalTeam
          status
          versionNo
        }
        totalScheduleTimeSec
        versionNo
        workingTimeSec
      }
      registerDateTime
      remainingTimeSec
      scheduleWorkingTimeSec
      scheduledEndDateTime
      scheduledStartDateTime
      sortNoInGanttChart
      sortNoInList
      sortNoInMyTask
      sortNoInTaskStatus
      taskStatus {
        endStatus
        id
        name
        project {
          calendarLinkageCode
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          description
          estimateTimeSec
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        sortNo
        versionNo
      }
      title
      versionNo
      workingMembers {
        lastWorkingStartDateTime
        lastWorkingStopDateTime
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
      }
      workingTimeSec
    }
  }
`;
export type UpdateTaskSortNoInMyTaskMutationFn = Apollo.MutationFunction<
  UpdateTaskSortNoInMyTaskMutation,
  UpdateTaskSortNoInMyTaskMutationVariables
>;

/**
 * __useUpdateTaskSortNoInMyTaskMutation__
 *
 * To run a mutation, you first call `useUpdateTaskSortNoInMyTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskSortNoInMyTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskSortNoInMyTaskMutation, { data, loading, error }] = useUpdateTaskSortNoInMyTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaskSortNoInMyTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTaskSortNoInMyTaskMutation,
    UpdateTaskSortNoInMyTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTaskSortNoInMyTaskMutation,
    UpdateTaskSortNoInMyTaskMutationVariables
  >(UpdateTaskSortNoInMyTaskDocument, options);
}
export type UpdateTaskSortNoInMyTaskMutationHookResult = ReturnType<
  typeof useUpdateTaskSortNoInMyTaskMutation
>;
export type UpdateTaskSortNoInMyTaskMutationResult =
  Apollo.MutationResult<UpdateTaskSortNoInMyTaskMutation>;
export type UpdateTaskSortNoInMyTaskMutationOptions = Apollo.BaseMutationOptions<
  UpdateTaskSortNoInMyTaskMutation,
  UpdateTaskSortNoInMyTaskMutationVariables
>;
export const UpdateTaskStatusDocument = gql`
  mutation updateTaskStatus($id: ID!, $input: UpdateTaskStatusInputInput!) {
    updateTaskStatus(id: $id, input: $input) {
      endStatus
      id
      name
      project {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        calendarLinkageCode
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        color
        complete
        completeDateTime
        costBudgetWithoutTax
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        key
        laborCost
        laborCostWithoutTax
        laborShare
        name
        priority
        projectStatus {
          endStatus
          id
          name
          sortNo
          teamId
          versionNo
        }
        registerDateTime
        salesAmountWithoutTax
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInProjectStatus
        team {
          archived
          id
          name
          organization {
            allowConnectThirdPartyCalendar
            contactMailAddress
            contractPreriod
            id
            imageUrl
            ipRestrictionFlg
            name
            plan {
              code
              contractPreriod
              id
            }
            requiredTwoFactorAuth
            suspensionFlg
            versionNo
          }
          personalTeam
          status
          versionNo
        }
        totalScheduleTimeSec
        versionNo
        workingTimeSec
      }
      sortNo
      versionNo
    }
  }
`;
export type UpdateTaskStatusMutationFn = Apollo.MutationFunction<
  UpdateTaskStatusMutation,
  UpdateTaskStatusMutationVariables
>;

/**
 * __useUpdateTaskStatusMutation__
 *
 * To run a mutation, you first call `useUpdateTaskStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskStatusMutation, { data, loading, error }] = useUpdateTaskStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaskStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTaskStatusMutation,
    UpdateTaskStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTaskStatusMutation, UpdateTaskStatusMutationVariables>(
    UpdateTaskStatusDocument,
    options
  );
}
export type UpdateTaskStatusMutationHookResult = ReturnType<typeof useUpdateTaskStatusMutation>;
export type UpdateTaskStatusMutationResult = Apollo.MutationResult<UpdateTaskStatusMutation>;
export type UpdateTaskStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateTaskStatusMutation,
  UpdateTaskStatusMutationVariables
>;
export const UpdateTeamDocument = gql`
  mutation updateTeam($id: ID!, $input: UpdateTeamInputInput!) {
    updateTeam(id: $id, input: $input) {
      archived
      id
      name
      organization {
        allowConnectThirdPartyCalendar
        contactMailAddress
        contractPreriod
        id
        imageUrl
        ipRestrictionFlg
        name
        plan {
          code
          contractPreriod
          id
        }
        requiredTwoFactorAuth
        suspensionFlg
        versionNo
      }
      personalTeam
      status
      versionNo
    }
  }
`;
export type UpdateTeamMutationFn = Apollo.MutationFunction<
  UpdateTeamMutation,
  UpdateTeamMutationVariables
>;

/**
 * __useUpdateTeamMutation__
 *
 * To run a mutation, you first call `useUpdateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamMutation, { data, loading, error }] = useUpdateTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTeamMutation, UpdateTeamMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTeamMutation, UpdateTeamMutationVariables>(
    UpdateTeamDocument,
    options
  );
}
export type UpdateTeamMutationHookResult = ReturnType<typeof useUpdateTeamMutation>;
export type UpdateTeamMutationResult = Apollo.MutationResult<UpdateTeamMutation>;
export type UpdateTeamMutationOptions = Apollo.BaseMutationOptions<
  UpdateTeamMutation,
  UpdateTeamMutationVariables
>;
export const UpdateTeamMemberRelationDocument = gql`
  mutation updateTeamMemberRelation($id: ID!, $input: UpdateTeamMemberRelationInputInput) {
    updateTeamMemberRelation(id: $id, input: $input) {
      id
      openMenu
      sortNo
      team {
        archived
        id
        name
        organization {
          allowConnectThirdPartyCalendar
          contactMailAddress
          contractPreriod
          id
          imageUrl
          ipRestrictionFlg
          name
          plan {
            code
            contractPreriod
            id
          }
          requiredTwoFactorAuth
          suspensionFlg
          versionNo
        }
        personalTeam
        status
        versionNo
      }
      versionNo
    }
  }
`;
export type UpdateTeamMemberRelationMutationFn = Apollo.MutationFunction<
  UpdateTeamMemberRelationMutation,
  UpdateTeamMemberRelationMutationVariables
>;

/**
 * __useUpdateTeamMemberRelationMutation__
 *
 * To run a mutation, you first call `useUpdateTeamMemberRelationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamMemberRelationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamMemberRelationMutation, { data, loading, error }] = useUpdateTeamMemberRelationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTeamMemberRelationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTeamMemberRelationMutation,
    UpdateTeamMemberRelationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTeamMemberRelationMutation,
    UpdateTeamMemberRelationMutationVariables
  >(UpdateTeamMemberRelationDocument, options);
}
export type UpdateTeamMemberRelationMutationHookResult = ReturnType<
  typeof useUpdateTeamMemberRelationMutation
>;
export type UpdateTeamMemberRelationMutationResult =
  Apollo.MutationResult<UpdateTeamMemberRelationMutation>;
export type UpdateTeamMemberRelationMutationOptions = Apollo.BaseMutationOptions<
  UpdateTeamMemberRelationMutation,
  UpdateTeamMemberRelationMutationVariables
>;
export const UpdateToActiveDocument = gql`
  mutation updateToActive($id: ID!, $input: UpdateToActiveInputInput!) {
    updateToActive(id: $id, input: $input) {
      adminRole
      clientManagementRole
      department
      employeeNumber
      id
      laborCostManagementRole
      mailAddress
      memberManagementRole
      memberRegistrationDateTime
      memberStatus
      name
      organizationMemberViewRole
      organizationReportRole
      profileImageUrl
      projectCreatePermissionFlg
      projectCustomAttributePermissionFlg
      projectDeletePermissionFlg
      projectUpdatePermissionFlg
      proxyEditWorkingDataRole
      salesManagementRole
      statusMessage
      taskCreatePermissionFlg
      taskCustomAttributePermissionFlg
      taskDeletePermissionFlg
      taskUpdatePermissionFlg
      teamMemberViewRole
      teamReportRole
      versionNo
    }
  }
`;
export type UpdateToActiveMutationFn = Apollo.MutationFunction<
  UpdateToActiveMutation,
  UpdateToActiveMutationVariables
>;

/**
 * __useUpdateToActiveMutation__
 *
 * To run a mutation, you first call `useUpdateToActiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateToActiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateToActiveMutation, { data, loading, error }] = useUpdateToActiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateToActiveMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateToActiveMutation, UpdateToActiveMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateToActiveMutation, UpdateToActiveMutationVariables>(
    UpdateToActiveDocument,
    options
  );
}
export type UpdateToActiveMutationHookResult = ReturnType<typeof useUpdateToActiveMutation>;
export type UpdateToActiveMutationResult = Apollo.MutationResult<UpdateToActiveMutation>;
export type UpdateToActiveMutationOptions = Apollo.BaseMutationOptions<
  UpdateToActiveMutation,
  UpdateToActiveMutationVariables
>;
export const UpdateToInactiveDocument = gql`
  mutation updateToInactive($id: ID!, $input: UpdateToInactiveInputInput!) {
    updateToInactive(id: $id, input: $input) {
      adminRole
      clientManagementRole
      department
      employeeNumber
      id
      laborCostManagementRole
      mailAddress
      memberManagementRole
      memberRegistrationDateTime
      memberStatus
      name
      organizationMemberViewRole
      organizationReportRole
      profileImageUrl
      projectCreatePermissionFlg
      projectCustomAttributePermissionFlg
      projectDeletePermissionFlg
      projectUpdatePermissionFlg
      proxyEditWorkingDataRole
      salesManagementRole
      statusMessage
      taskCreatePermissionFlg
      taskCustomAttributePermissionFlg
      taskDeletePermissionFlg
      taskUpdatePermissionFlg
      teamMemberViewRole
      teamReportRole
      versionNo
    }
  }
`;
export type UpdateToInactiveMutationFn = Apollo.MutationFunction<
  UpdateToInactiveMutation,
  UpdateToInactiveMutationVariables
>;

/**
 * __useUpdateToInactiveMutation__
 *
 * To run a mutation, you first call `useUpdateToInactiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateToInactiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateToInactiveMutation, { data, loading, error }] = useUpdateToInactiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateToInactiveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateToInactiveMutation,
    UpdateToInactiveMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateToInactiveMutation, UpdateToInactiveMutationVariables>(
    UpdateToInactiveDocument,
    options
  );
}
export type UpdateToInactiveMutationHookResult = ReturnType<typeof useUpdateToInactiveMutation>;
export type UpdateToInactiveMutationResult = Apollo.MutationResult<UpdateToInactiveMutation>;
export type UpdateToInactiveMutationOptions = Apollo.BaseMutationOptions<
  UpdateToInactiveMutation,
  UpdateToInactiveMutationVariables
>;
export const UpdateWorkingHistoryDocument = gql`
  mutation updateWorkingHistory($id: String!, $input: UpdateWorkingHistoryInputInput!) {
    updateWorkingHistory(id: $id, input: $input) {
      calendarEvent {
        calendarType
        clientId
        clientName
        endDateTime
        eventName
        id
        laborCost
        personalTeam
        projectColor
        projectId
        projectName
        startDateTime
        taskId
        taskTitle
        teamId
        teamName
        thirdPartyCalendarEventId
        thirdPartyCalendarInfoList {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          thirdPartyCalendarId
        }
        versionNo
        workingTimeSec
      }
      closingProcessInfo {
        closed
        targetDateTime
      }
      end
      id
      memo
      start
      task {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        asssignee {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        calendarLinkageCode
        completeDateTime
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        estimatedRemainingTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        laborCost
        priority
        progressDeviationRate
        progressRate
        project {
          assignees {
            sortNo
          }
          calendarLinkageCode
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          estimateTimeSec
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            sortNo
          }
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          projectStatus {
            endStatus
            id
            name
            sortNo
            teamId
            versionNo
          }
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          team {
            archived
            id
            name
            organization {
              allowConnectThirdPartyCalendar
              contactMailAddress
              contractPreriod
              id
              imageUrl
              ipRestrictionFlg
              name
              plan {
                code
                contractPreriod
                id
              }
              requiredTwoFactorAuth
              suspensionFlg
              versionNo
            }
            personalTeam
            status
            versionNo
          }
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        registerDateTime
        remainingTimeSec
        scheduleWorkingTimeSec
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInMyTask
        sortNoInTaskStatus
        taskStatus {
          endStatus
          id
          name
          project {
            calendarLinkageCode
            color
            complete
            completeDateTime
            costBudgetWithoutTax
            description
            estimateTimeSec
            id
            key
            laborCost
            laborCostWithoutTax
            laborShare
            name
            priority
            registerDateTime
            salesAmountWithoutTax
            scheduledEndDateTime
            scheduledStartDateTime
            sortNoInGanttChart
            sortNoInList
            sortNoInProjectStatus
            totalScheduleTimeSec
            versionNo
            workingTimeSec
          }
          sortNo
          versionNo
        }
        title
        versionNo
        workingMembers {
          lastWorkingStartDateTime
          lastWorkingStopDateTime
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
        }
        workingTimeSec
      }
      versionNo
      workingMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      workingTimeSec
    }
  }
`;
export type UpdateWorkingHistoryMutationFn = Apollo.MutationFunction<
  UpdateWorkingHistoryMutation,
  UpdateWorkingHistoryMutationVariables
>;

/**
 * __useUpdateWorkingHistoryMutation__
 *
 * To run a mutation, you first call `useUpdateWorkingHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkingHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkingHistoryMutation, { data, loading, error }] = useUpdateWorkingHistoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWorkingHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWorkingHistoryMutation,
    UpdateWorkingHistoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateWorkingHistoryMutation, UpdateWorkingHistoryMutationVariables>(
    UpdateWorkingHistoryDocument,
    options
  );
}
export type UpdateWorkingHistoryMutationHookResult = ReturnType<
  typeof useUpdateWorkingHistoryMutation
>;
export type UpdateWorkingHistoryMutationResult =
  Apollo.MutationResult<UpdateWorkingHistoryMutation>;
export type UpdateWorkingHistoryMutationOptions = Apollo.BaseMutationOptions<
  UpdateWorkingHistoryMutation,
  UpdateWorkingHistoryMutationVariables
>;
export const UpdateWorkingScheduleDocument = gql`
  mutation updateWorkingSchedule($id: String!, $input: UpdateWorkingScheduleInputInput!) {
    updateWorkingSchedule(id: $id, input: $input) {
      calendarEvent {
        calendarType
        clientId
        clientName
        endDateTime
        eventName
        id
        laborCost
        personalTeam
        projectColor
        projectId
        projectName
        startDateTime
        taskId
        taskTitle
        teamId
        teamName
        thirdPartyCalendarEventId
        thirdPartyCalendarInfoList {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          thirdPartyCalendarId
        }
        versionNo
        workingTimeSec
      }
      end
      id
      memo
      start
      task {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        asssignee {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        calendarLinkageCode
        completeDateTime
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        estimatedRemainingTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        laborCost
        priority
        progressDeviationRate
        progressRate
        project {
          assignees {
            sortNo
          }
          calendarLinkageCode
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          estimateTimeSec
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            sortNo
          }
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          projectStatus {
            endStatus
            id
            name
            sortNo
            teamId
            versionNo
          }
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          team {
            archived
            id
            name
            organization {
              allowConnectThirdPartyCalendar
              contactMailAddress
              contractPreriod
              id
              imageUrl
              ipRestrictionFlg
              name
              plan {
                code
                contractPreriod
                id
              }
              requiredTwoFactorAuth
              suspensionFlg
              versionNo
            }
            personalTeam
            status
            versionNo
          }
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        registerDateTime
        remainingTimeSec
        scheduleWorkingTimeSec
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInMyTask
        sortNoInTaskStatus
        taskStatus {
          endStatus
          id
          name
          project {
            calendarLinkageCode
            color
            complete
            completeDateTime
            costBudgetWithoutTax
            description
            estimateTimeSec
            id
            key
            laborCost
            laborCostWithoutTax
            laborShare
            name
            priority
            registerDateTime
            salesAmountWithoutTax
            scheduledEndDateTime
            scheduledStartDateTime
            sortNoInGanttChart
            sortNoInList
            sortNoInProjectStatus
            totalScheduleTimeSec
            versionNo
            workingTimeSec
          }
          sortNo
          versionNo
        }
        title
        versionNo
        workingMembers {
          lastWorkingStartDateTime
          lastWorkingStopDateTime
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
        }
        workingTimeSec
      }
      versionNo
      workingMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      workingTimeSec
    }
  }
`;
export type UpdateWorkingScheduleMutationFn = Apollo.MutationFunction<
  UpdateWorkingScheduleMutation,
  UpdateWorkingScheduleMutationVariables
>;

/**
 * __useUpdateWorkingScheduleMutation__
 *
 * To run a mutation, you first call `useUpdateWorkingScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkingScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkingScheduleMutation, { data, loading, error }] = useUpdateWorkingScheduleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWorkingScheduleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWorkingScheduleMutation,
    UpdateWorkingScheduleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateWorkingScheduleMutation, UpdateWorkingScheduleMutationVariables>(
    UpdateWorkingScheduleDocument,
    options
  );
}
export type UpdateWorkingScheduleMutationHookResult = ReturnType<
  typeof useUpdateWorkingScheduleMutation
>;
export type UpdateWorkingScheduleMutationResult =
  Apollo.MutationResult<UpdateWorkingScheduleMutation>;
export type UpdateWorkingScheduleMutationOptions = Apollo.BaseMutationOptions<
  UpdateWorkingScheduleMutation,
  UpdateWorkingScheduleMutationVariables
>;
export const UpgradeToProPlanIosDocument = gql`
  mutation upgradeToProPlanIOS($id: ID!, $input: UpgradeToProPlanIOSInputInput) {
    upgradeToProPlanIOS(id: $id, input: $input) {
      billToMailAddress
      billingType
      contractPreriod
      freeLicenceCoupon {
        freeEndLicenceCount
        freeStartLicenceCount
        id
      }
      freeTrialExpireDatetime
      id
      licenceCount
      plan {
        code
        contractPreriod
        id
      }
      revenuecatOriginalAppUserId
      usingLicenceCount
      versionNo
    }
  }
`;
export type UpgradeToProPlanIosMutationFn = Apollo.MutationFunction<
  UpgradeToProPlanIosMutation,
  UpgradeToProPlanIosMutationVariables
>;

/**
 * __useUpgradeToProPlanIosMutation__
 *
 * To run a mutation, you first call `useUpgradeToProPlanIosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpgradeToProPlanIosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upgradeToProPlanIosMutation, { data, loading, error }] = useUpgradeToProPlanIosMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpgradeToProPlanIosMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpgradeToProPlanIosMutation,
    UpgradeToProPlanIosMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpgradeToProPlanIosMutation, UpgradeToProPlanIosMutationVariables>(
    UpgradeToProPlanIosDocument,
    options
  );
}
export type UpgradeToProPlanIosMutationHookResult = ReturnType<
  typeof useUpgradeToProPlanIosMutation
>;
export type UpgradeToProPlanIosMutationResult = Apollo.MutationResult<UpgradeToProPlanIosMutation>;
export type UpgradeToProPlanIosMutationOptions = Apollo.BaseMutationOptions<
  UpgradeToProPlanIosMutation,
  UpgradeToProPlanIosMutationVariables
>;
export const VerifyTwoFactorAuthDocument = gql`
  mutation verifyTwoFactorAuth($input: VerifyTwoFactorAuthInputInput!) {
    verifyTwoFactorAuth(input: $input) {
      enabled
      recoveryCodes
    }
  }
`;
export type VerifyTwoFactorAuthMutationFn = Apollo.MutationFunction<
  VerifyTwoFactorAuthMutation,
  VerifyTwoFactorAuthMutationVariables
>;

/**
 * __useVerifyTwoFactorAuthMutation__
 *
 * To run a mutation, you first call `useVerifyTwoFactorAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyTwoFactorAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyTwoFactorAuthMutation, { data, loading, error }] = useVerifyTwoFactorAuthMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyTwoFactorAuthMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyTwoFactorAuthMutation,
    VerifyTwoFactorAuthMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<VerifyTwoFactorAuthMutation, VerifyTwoFactorAuthMutationVariables>(
    VerifyTwoFactorAuthDocument,
    options
  );
}
export type VerifyTwoFactorAuthMutationHookResult = ReturnType<
  typeof useVerifyTwoFactorAuthMutation
>;
export type VerifyTwoFactorAuthMutationResult = Apollo.MutationResult<VerifyTwoFactorAuthMutation>;
export type VerifyTwoFactorAuthMutationOptions = Apollo.BaseMutationOptions<
  VerifyTwoFactorAuthMutation,
  VerifyTwoFactorAuthMutationVariables
>;
export const WithdrawDocument = gql`
  mutation withdraw($input: WithdrawalInputInput!) {
    withdraw(input: $input)
  }
`;
export type WithdrawMutationFn = Apollo.MutationFunction<
  WithdrawMutation,
  WithdrawMutationVariables
>;

/**
 * __useWithdrawMutation__
 *
 * To run a mutation, you first call `useWithdrawMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWithdrawMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [withdrawMutation, { data, loading, error }] = useWithdrawMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWithdrawMutation(
  baseOptions?: Apollo.MutationHookOptions<WithdrawMutation, WithdrawMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<WithdrawMutation, WithdrawMutationVariables>(WithdrawDocument, options);
}
export type WithdrawMutationHookResult = ReturnType<typeof useWithdrawMutation>;
export type WithdrawMutationResult = Apollo.MutationResult<WithdrawMutation>;
export type WithdrawMutationOptions = Apollo.BaseMutationOptions<
  WithdrawMutation,
  WithdrawMutationVariables
>;
export const AccessControlSettingsDocument = gql`
  query accessControlSettings {
    accessControlSettings {
      enable
      id
      ipAddresses
      versionNo
    }
  }
`;

/**
 * __useAccessControlSettingsQuery__
 *
 * To run a query within a React component, call `useAccessControlSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessControlSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessControlSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccessControlSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AccessControlSettingsQuery,
    AccessControlSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccessControlSettingsQuery, AccessControlSettingsQueryVariables>(
    AccessControlSettingsDocument,
    options
  );
}
export function useAccessControlSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccessControlSettingsQuery,
    AccessControlSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccessControlSettingsQuery, AccessControlSettingsQueryVariables>(
    AccessControlSettingsDocument,
    options
  );
}
export type AccessControlSettingsQueryHookResult = ReturnType<typeof useAccessControlSettingsQuery>;
export type AccessControlSettingsLazyQueryHookResult = ReturnType<
  typeof useAccessControlSettingsLazyQuery
>;
export type AccessControlSettingsQueryResult = Apollo.QueryResult<
  AccessControlSettingsQuery,
  AccessControlSettingsQueryVariables
>;
export const AllMemberWorkingHistoriesSpecifyTermDocument = gql`
  query allMemberWorkingHistoriesSpecifyTerm(
    $input: AllMemberWorkingWorkingHistoriesInputInput!
    $limit: Int
    $offset: Int
  ) {
    allMemberWorkingHistoriesSpecifyTerm(input: $input, limit: $limit, offset: $offset) {
      calendarEvent {
        calendarType
        clientId
        clientName
        endDateTime
        eventName
        id
        laborCost
        personalTeam
        projectColor
        projectId
        projectName
        startDateTime
        taskId
        taskTitle
        teamId
        teamName
        thirdPartyCalendarEventId
        thirdPartyCalendarInfoList {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          thirdPartyCalendarId
        }
        versionNo
        workingTimeSec
      }
      closingProcessInfo {
        closed
        targetDateTime
      }
      end
      id
      memo
      start
      task {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        asssignee {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        calendarLinkageCode
        completeDateTime
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        estimatedRemainingTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        laborCost
        priority
        progressDeviationRate
        progressRate
        project {
          assignees {
            sortNo
          }
          calendarLinkageCode
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          estimateTimeSec
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            sortNo
          }
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          projectStatus {
            endStatus
            id
            name
            sortNo
            teamId
            versionNo
          }
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          team {
            archived
            id
            name
            organization {
              allowConnectThirdPartyCalendar
              contactMailAddress
              contractPreriod
              id
              imageUrl
              ipRestrictionFlg
              name
              plan {
                code
                contractPreriod
                id
              }
              requiredTwoFactorAuth
              suspensionFlg
              versionNo
            }
            personalTeam
            status
            versionNo
          }
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        registerDateTime
        remainingTimeSec
        scheduleWorkingTimeSec
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInMyTask
        sortNoInTaskStatus
        taskStatus {
          endStatus
          id
          name
          project {
            calendarLinkageCode
            color
            complete
            completeDateTime
            costBudgetWithoutTax
            description
            estimateTimeSec
            id
            key
            laborCost
            laborCostWithoutTax
            laborShare
            name
            priority
            registerDateTime
            salesAmountWithoutTax
            scheduledEndDateTime
            scheduledStartDateTime
            sortNoInGanttChart
            sortNoInList
            sortNoInProjectStatus
            totalScheduleTimeSec
            versionNo
            workingTimeSec
          }
          sortNo
          versionNo
        }
        title
        versionNo
        workingMembers {
          lastWorkingStartDateTime
          lastWorkingStopDateTime
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
        }
        workingTimeSec
      }
      versionNo
      workingMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      workingTimeSec
    }
  }
`;

/**
 * __useAllMemberWorkingHistoriesSpecifyTermQuery__
 *
 * To run a query within a React component, call `useAllMemberWorkingHistoriesSpecifyTermQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllMemberWorkingHistoriesSpecifyTermQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllMemberWorkingHistoriesSpecifyTermQuery({
 *   variables: {
 *      input: // value for 'input'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAllMemberWorkingHistoriesSpecifyTermQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllMemberWorkingHistoriesSpecifyTermQuery,
    AllMemberWorkingHistoriesSpecifyTermQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllMemberWorkingHistoriesSpecifyTermQuery,
    AllMemberWorkingHistoriesSpecifyTermQueryVariables
  >(AllMemberWorkingHistoriesSpecifyTermDocument, options);
}
export function useAllMemberWorkingHistoriesSpecifyTermLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllMemberWorkingHistoriesSpecifyTermQuery,
    AllMemberWorkingHistoriesSpecifyTermQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllMemberWorkingHistoriesSpecifyTermQuery,
    AllMemberWorkingHistoriesSpecifyTermQueryVariables
  >(AllMemberWorkingHistoriesSpecifyTermDocument, options);
}
export type AllMemberWorkingHistoriesSpecifyTermQueryHookResult = ReturnType<
  typeof useAllMemberWorkingHistoriesSpecifyTermQuery
>;
export type AllMemberWorkingHistoriesSpecifyTermLazyQueryHookResult = ReturnType<
  typeof useAllMemberWorkingHistoriesSpecifyTermLazyQuery
>;
export type AllMemberWorkingHistoriesSpecifyTermQueryResult = Apollo.QueryResult<
  AllMemberWorkingHistoriesSpecifyTermQuery,
  AllMemberWorkingHistoriesSpecifyTermQueryVariables
>;
export const AllTeamClosingSettingsDocument = gql`
  query allTeamClosingSettings {
    allTeamClosingSettings {
      closingType
      id
      targetDateTime
      teamId
      timeZone {
        code
        id
        name
        offsetTime
        sortNo
      }
      versionNo
    }
  }
`;

/**
 * __useAllTeamClosingSettingsQuery__
 *
 * To run a query within a React component, call `useAllTeamClosingSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTeamClosingSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTeamClosingSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllTeamClosingSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllTeamClosingSettingsQuery,
    AllTeamClosingSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllTeamClosingSettingsQuery, AllTeamClosingSettingsQueryVariables>(
    AllTeamClosingSettingsDocument,
    options
  );
}
export function useAllTeamClosingSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllTeamClosingSettingsQuery,
    AllTeamClosingSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllTeamClosingSettingsQuery, AllTeamClosingSettingsQueryVariables>(
    AllTeamClosingSettingsDocument,
    options
  );
}
export type AllTeamClosingSettingsQueryHookResult = ReturnType<
  typeof useAllTeamClosingSettingsQuery
>;
export type AllTeamClosingSettingsLazyQueryHookResult = ReturnType<
  typeof useAllTeamClosingSettingsLazyQuery
>;
export type AllTeamClosingSettingsQueryResult = Apollo.QueryResult<
  AllTeamClosingSettingsQuery,
  AllTeamClosingSettingsQueryVariables
>;
export const AnnouncementDocument = gql`
  query announcement($id: ID!) {
    announcement(id: $id) {
      announceDateTime
      id
      readed
      title
      url
    }
  }
`;

/**
 * __useAnnouncementQuery__
 *
 * To run a query within a React component, call `useAnnouncementQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnnouncementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnnouncementQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAnnouncementQuery(
  baseOptions: Apollo.QueryHookOptions<AnnouncementQuery, AnnouncementQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AnnouncementQuery, AnnouncementQueryVariables>(
    AnnouncementDocument,
    options
  );
}
export function useAnnouncementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AnnouncementQuery, AnnouncementQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AnnouncementQuery, AnnouncementQueryVariables>(
    AnnouncementDocument,
    options
  );
}
export type AnnouncementQueryHookResult = ReturnType<typeof useAnnouncementQuery>;
export type AnnouncementLazyQueryHookResult = ReturnType<typeof useAnnouncementLazyQuery>;
export type AnnouncementQueryResult = Apollo.QueryResult<
  AnnouncementQuery,
  AnnouncementQueryVariables
>;
export const AnnouncementsDocument = gql`
  query announcements($limit: Int, $offset: Int) {
    announcements(limit: $limit, offset: $offset) {
      announceDateTime
      id
      readed
      title
      url
    }
  }
`;

/**
 * __useAnnouncementsQuery__
 *
 * To run a query within a React component, call `useAnnouncementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnnouncementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnnouncementsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAnnouncementsQuery(
  baseOptions?: Apollo.QueryHookOptions<AnnouncementsQuery, AnnouncementsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AnnouncementsQuery, AnnouncementsQueryVariables>(
    AnnouncementsDocument,
    options
  );
}
export function useAnnouncementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AnnouncementsQuery, AnnouncementsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AnnouncementsQuery, AnnouncementsQueryVariables>(
    AnnouncementsDocument,
    options
  );
}
export type AnnouncementsQueryHookResult = ReturnType<typeof useAnnouncementsQuery>;
export type AnnouncementsLazyQueryHookResult = ReturnType<typeof useAnnouncementsLazyQuery>;
export type AnnouncementsQueryResult = Apollo.QueryResult<
  AnnouncementsQuery,
  AnnouncementsQueryVariables
>;
export const ApiKeyDocument = gql`
  query apiKey {
    apiKey {
      token
    }
  }
`;

/**
 * __useApiKeyQuery__
 *
 * To run a query within a React component, call `useApiKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiKeyQuery({
 *   variables: {
 *   },
 * });
 */
export function useApiKeyQuery(
  baseOptions?: Apollo.QueryHookOptions<ApiKeyQuery, ApiKeyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ApiKeyQuery, ApiKeyQueryVariables>(ApiKeyDocument, options);
}
export function useApiKeyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ApiKeyQuery, ApiKeyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ApiKeyQuery, ApiKeyQueryVariables>(ApiKeyDocument, options);
}
export type ApiKeyQueryHookResult = ReturnType<typeof useApiKeyQuery>;
export type ApiKeyLazyQueryHookResult = ReturnType<typeof useApiKeyLazyQuery>;
export type ApiKeyQueryResult = Apollo.QueryResult<ApiKeyQuery, ApiKeyQueryVariables>;
export const AppleAccountDocument = gql`
  query appleAccount {
    appleAccount {
      mailAddress
    }
  }
`;

/**
 * __useAppleAccountQuery__
 *
 * To run a query within a React component, call `useAppleAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppleAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppleAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useAppleAccountQuery(
  baseOptions?: Apollo.QueryHookOptions<AppleAccountQuery, AppleAccountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppleAccountQuery, AppleAccountQueryVariables>(
    AppleAccountDocument,
    options
  );
}
export function useAppleAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AppleAccountQuery, AppleAccountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AppleAccountQuery, AppleAccountQueryVariables>(
    AppleAccountDocument,
    options
  );
}
export type AppleAccountQueryHookResult = ReturnType<typeof useAppleAccountQuery>;
export type AppleAccountLazyQueryHookResult = ReturnType<typeof useAppleAccountLazyQuery>;
export type AppleAccountQueryResult = Apollo.QueryResult<
  AppleAccountQuery,
  AppleAccountQueryVariables
>;
export const AssignedProjectsDocument = gql`
  query assignedProjects($input: FetchAssignedProjectsInputInput, $limit: Int, $offset: Int) {
    assignedProjects(input: $input, limit: $limit, offset: $offset) {
      assignees {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      calendarLinkageCode
      client {
        code
        id
        name
        sortNo
        versionNo
      }
      color
      complete
      completeDateTime
      costBudgetWithoutTax
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      favoriteMembers {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      favoriteMembersInfo {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      id
      key
      laborCost
      laborCostWithoutTax
      laborShare
      name
      priority
      projectStatus {
        endStatus
        id
        name
        sortNo
        teamId
        versionNo
      }
      registerDateTime
      salesAmountWithoutTax
      scheduledEndDateTime
      scheduledStartDateTime
      sortNoInGanttChart
      sortNoInList
      sortNoInProjectStatus
      team {
        archived
        id
        name
        organization {
          allowConnectThirdPartyCalendar
          contactMailAddress
          contractPreriod
          id
          imageUrl
          ipRestrictionFlg
          name
          plan {
            code
            contractPreriod
            id
          }
          requiredTwoFactorAuth
          suspensionFlg
          versionNo
        }
        personalTeam
        status
        versionNo
      }
      totalScheduleTimeSec
      versionNo
      workingTimeSec
    }
  }
`;

/**
 * __useAssignedProjectsQuery__
 *
 * To run a query within a React component, call `useAssignedProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignedProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignedProjectsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAssignedProjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<AssignedProjectsQuery, AssignedProjectsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AssignedProjectsQuery, AssignedProjectsQueryVariables>(
    AssignedProjectsDocument,
    options
  );
}
export function useAssignedProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AssignedProjectsQuery, AssignedProjectsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AssignedProjectsQuery, AssignedProjectsQueryVariables>(
    AssignedProjectsDocument,
    options
  );
}
export type AssignedProjectsQueryHookResult = ReturnType<typeof useAssignedProjectsQuery>;
export type AssignedProjectsLazyQueryHookResult = ReturnType<typeof useAssignedProjectsLazyQuery>;
export type AssignedProjectsQueryResult = Apollo.QueryResult<
  AssignedProjectsQuery,
  AssignedProjectsQueryVariables
>;
export const AssignedTasksDocument = gql`
  query assignedTasks($input: SearchTaskInputInput, $limit: Int, $offset: Int) {
    assignedTasks(input: $input, limit: $limit, offset: $offset) {
      assignees {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      asssignee {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      calendarLinkageCode
      completeDateTime
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      estimatedRemainingTimeSec
      favoriteMembers {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      favoriteMembersInfo {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      id
      laborCost
      priority
      progressDeviationRate
      progressRate
      project {
        assignees {
          sortNo
        }
        calendarLinkageCode
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        color
        complete
        completeDateTime
        costBudgetWithoutTax
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          sortNo
        }
        id
        key
        laborCost
        laborCostWithoutTax
        laborShare
        name
        priority
        projectStatus {
          endStatus
          id
          name
          sortNo
          teamId
          versionNo
        }
        registerDateTime
        salesAmountWithoutTax
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInProjectStatus
        team {
          archived
          id
          name
          organization {
            allowConnectThirdPartyCalendar
            contactMailAddress
            contractPreriod
            id
            imageUrl
            ipRestrictionFlg
            name
            plan {
              code
              contractPreriod
              id
            }
            requiredTwoFactorAuth
            suspensionFlg
            versionNo
          }
          personalTeam
          status
          versionNo
        }
        totalScheduleTimeSec
        versionNo
        workingTimeSec
      }
      registerDateTime
      remainingTimeSec
      scheduleWorkingTimeSec
      scheduledEndDateTime
      scheduledStartDateTime
      sortNoInGanttChart
      sortNoInList
      sortNoInMyTask
      sortNoInTaskStatus
      taskStatus {
        endStatus
        id
        name
        project {
          calendarLinkageCode
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          description
          estimateTimeSec
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        sortNo
        versionNo
      }
      title
      versionNo
      workingMembers {
        lastWorkingStartDateTime
        lastWorkingStopDateTime
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
      }
      workingTimeSec
    }
  }
`;

/**
 * __useAssignedTasksQuery__
 *
 * To run a query within a React component, call `useAssignedTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignedTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignedTasksQuery({
 *   variables: {
 *      input: // value for 'input'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAssignedTasksQuery(
  baseOptions?: Apollo.QueryHookOptions<AssignedTasksQuery, AssignedTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AssignedTasksQuery, AssignedTasksQueryVariables>(
    AssignedTasksDocument,
    options
  );
}
export function useAssignedTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AssignedTasksQuery, AssignedTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AssignedTasksQuery, AssignedTasksQueryVariables>(
    AssignedTasksDocument,
    options
  );
}
export type AssignedTasksQueryHookResult = ReturnType<typeof useAssignedTasksQuery>;
export type AssignedTasksLazyQueryHookResult = ReturnType<typeof useAssignedTasksLazyQuery>;
export type AssignedTasksQueryResult = Apollo.QueryResult<
  AssignedTasksQuery,
  AssignedTasksQueryVariables
>;
export const AttachmentFileDocument = gql`
  query attachmentFile($id: ID!) {
    attachmentFile(id: $id) {
      fileName
      id
      sizeByte
      type
      uploadDateTime
    }
  }
`;

/**
 * __useAttachmentFileQuery__
 *
 * To run a query within a React component, call `useAttachmentFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttachmentFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttachmentFileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAttachmentFileQuery(
  baseOptions: Apollo.QueryHookOptions<AttachmentFileQuery, AttachmentFileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AttachmentFileQuery, AttachmentFileQueryVariables>(
    AttachmentFileDocument,
    options
  );
}
export function useAttachmentFileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AttachmentFileQuery, AttachmentFileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AttachmentFileQuery, AttachmentFileQueryVariables>(
    AttachmentFileDocument,
    options
  );
}
export type AttachmentFileQueryHookResult = ReturnType<typeof useAttachmentFileQuery>;
export type AttachmentFileLazyQueryHookResult = ReturnType<typeof useAttachmentFileLazyQuery>;
export type AttachmentFileQueryResult = Apollo.QueryResult<
  AttachmentFileQuery,
  AttachmentFileQueryVariables
>;
export const BoardProjectsDocument = gql`
  query boardProjects(
    $assigneeIds: [String]
    $clientIds: [String]
    $favoriteCondition: ProjectFavoriteFilter
    $limit: Int
    $offset: Int
    $projectName: String
    $projectStatusId: String
    $teamId: String
  ) {
    boardProjects(
      assigneeIds: $assigneeIds
      clientIds: $clientIds
      favoriteCondition: $favoriteCondition
      limit: $limit
      offset: $offset
      projectName: $projectName
      projectStatusId: $projectStatusId
      teamId: $teamId
    ) {
      projectStatus {
        endStatus
        id
        name
        sortNo
        teamId
        versionNo
      }
      projects {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        calendarLinkageCode
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        color
        complete
        completeDateTime
        costBudgetWithoutTax
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        key
        laborCost
        laborCostWithoutTax
        laborShare
        name
        priority
        projectStatus {
          endStatus
          id
          name
          sortNo
          teamId
          versionNo
        }
        registerDateTime
        salesAmountWithoutTax
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInProjectStatus
        team {
          archived
          id
          name
          organization {
            allowConnectThirdPartyCalendar
            contactMailAddress
            contractPreriod
            id
            imageUrl
            ipRestrictionFlg
            name
            plan {
              code
              contractPreriod
              id
            }
            requiredTwoFactorAuth
            suspensionFlg
            versionNo
          }
          personalTeam
          status
          versionNo
        }
        totalScheduleTimeSec
        versionNo
        workingTimeSec
      }
    }
  }
`;

/**
 * __useBoardProjectsQuery__
 *
 * To run a query within a React component, call `useBoardProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBoardProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBoardProjectsQuery({
 *   variables: {
 *      assigneeIds: // value for 'assigneeIds'
 *      clientIds: // value for 'clientIds'
 *      favoriteCondition: // value for 'favoriteCondition'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      projectName: // value for 'projectName'
 *      projectStatusId: // value for 'projectStatusId'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useBoardProjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<BoardProjectsQuery, BoardProjectsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BoardProjectsQuery, BoardProjectsQueryVariables>(
    BoardProjectsDocument,
    options
  );
}
export function useBoardProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BoardProjectsQuery, BoardProjectsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BoardProjectsQuery, BoardProjectsQueryVariables>(
    BoardProjectsDocument,
    options
  );
}
export type BoardProjectsQueryHookResult = ReturnType<typeof useBoardProjectsQuery>;
export type BoardProjectsLazyQueryHookResult = ReturnType<typeof useBoardProjectsLazyQuery>;
export type BoardProjectsQueryResult = Apollo.QueryResult<
  BoardProjectsQuery,
  BoardProjectsQueryVariables
>;
export const BoardTasksDocument = gql`
  query boardTasks($limit: Int, $offset: Int, $projectId: String, $taskStatusId: String) {
    boardTasks(limit: $limit, offset: $offset, projectId: $projectId, taskStatusId: $taskStatusId) {
      taskStatus {
        endStatus
        id
        name
        project {
          assignees {
            member {
              adminRole
              clientManagementRole
              department
              employeeNumber
              id
              laborCostManagementRole
              mailAddress
              memberManagementRole
              memberRegistrationDateTime
              memberStatus
              name
              organizationMemberViewRole
              organizationReportRole
              profileImageUrl
              projectCreatePermissionFlg
              projectCustomAttributePermissionFlg
              projectDeletePermissionFlg
              projectUpdatePermissionFlg
              proxyEditWorkingDataRole
              salesManagementRole
              statusMessage
              taskCreatePermissionFlg
              taskCustomAttributePermissionFlg
              taskDeletePermissionFlg
              taskUpdatePermissionFlg
              teamMemberViewRole
              teamReportRole
              versionNo
            }
            sortNo
          }
          calendarLinkageCode
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          estimateTimeSec
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            member {
              adminRole
              clientManagementRole
              department
              employeeNumber
              id
              laborCostManagementRole
              mailAddress
              memberManagementRole
              memberRegistrationDateTime
              memberStatus
              name
              organizationMemberViewRole
              organizationReportRole
              profileImageUrl
              projectCreatePermissionFlg
              projectCustomAttributePermissionFlg
              projectDeletePermissionFlg
              projectUpdatePermissionFlg
              proxyEditWorkingDataRole
              salesManagementRole
              statusMessage
              taskCreatePermissionFlg
              taskCustomAttributePermissionFlg
              taskDeletePermissionFlg
              taskUpdatePermissionFlg
              teamMemberViewRole
              teamReportRole
              versionNo
            }
            sortNo
          }
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          projectStatus {
            endStatus
            id
            name
            sortNo
            teamId
            versionNo
          }
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          team {
            archived
            id
            name
            organization {
              allowConnectThirdPartyCalendar
              contactMailAddress
              contractPreriod
              id
              imageUrl
              ipRestrictionFlg
              name
              plan {
                code
                contractPreriod
                id
              }
              requiredTwoFactorAuth
              suspensionFlg
              versionNo
            }
            personalTeam
            status
            versionNo
          }
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        sortNo
        versionNo
      }
      tasks {
        assignees {
          sortNo
        }
        asssignee {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        calendarLinkageCode
        completeDateTime
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        estimatedRemainingTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          sortNo
        }
        id
        laborCost
        priority
        progressDeviationRate
        progressRate
        project {
          calendarLinkageCode
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          description
          estimateTimeSec
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        registerDateTime
        remainingTimeSec
        scheduleWorkingTimeSec
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInMyTask
        sortNoInTaskStatus
        taskStatus {
          endStatus
          id
          name
          sortNo
          versionNo
        }
        title
        versionNo
        workingMembers {
          lastWorkingStartDateTime
          lastWorkingStopDateTime
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
        }
        workingTimeSec
      }
    }
  }
`;

/**
 * __useBoardTasksQuery__
 *
 * To run a query within a React component, call `useBoardTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useBoardTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBoardTasksQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      projectId: // value for 'projectId'
 *      taskStatusId: // value for 'taskStatusId'
 *   },
 * });
 */
export function useBoardTasksQuery(
  baseOptions?: Apollo.QueryHookOptions<BoardTasksQuery, BoardTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BoardTasksQuery, BoardTasksQueryVariables>(BoardTasksDocument, options);
}
export function useBoardTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BoardTasksQuery, BoardTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BoardTasksQuery, BoardTasksQueryVariables>(
    BoardTasksDocument,
    options
  );
}
export type BoardTasksQueryHookResult = ReturnType<typeof useBoardTasksQuery>;
export type BoardTasksLazyQueryHookResult = ReturnType<typeof useBoardTasksLazyQuery>;
export type BoardTasksQueryResult = Apollo.QueryResult<BoardTasksQuery, BoardTasksQueryVariables>;
export const CalendarEventDocument = gql`
  query calendarEvent($id: String!) {
    calendarEvent(id: $id) {
      calendarType
      clientId
      clientName
      endDateTime
      eventName
      id
      laborCost
      personalTeam
      projectColor
      projectId
      projectName
      startDateTime
      taskId
      taskTitle
      teamId
      teamName
      thirdPartyCalendarEventId
      thirdPartyCalendarInfoList {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        thirdPartyCalendarId
      }
      versionNo
      workingTimeSec
    }
  }
`;

/**
 * __useCalendarEventQuery__
 *
 * To run a query within a React component, call `useCalendarEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCalendarEventQuery(
  baseOptions: Apollo.QueryHookOptions<CalendarEventQuery, CalendarEventQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CalendarEventQuery, CalendarEventQueryVariables>(
    CalendarEventDocument,
    options
  );
}
export function useCalendarEventLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CalendarEventQuery, CalendarEventQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CalendarEventQuery, CalendarEventQueryVariables>(
    CalendarEventDocument,
    options
  );
}
export type CalendarEventQueryHookResult = ReturnType<typeof useCalendarEventQuery>;
export type CalendarEventLazyQueryHookResult = ReturnType<typeof useCalendarEventLazyQuery>;
export type CalendarEventQueryResult = Apollo.QueryResult<
  CalendarEventQuery,
  CalendarEventQueryVariables
>;
export const CalendarEventWorkingHistoriesDocument = gql`
  query calendarEventWorkingHistories($calendarEventId: String!, $end: DateTime, $start: DateTime) {
    calendarEventWorkingHistories(calendarEventId: $calendarEventId, end: $end, start: $start) {
      calendarEvent {
        calendarType
        clientId
        clientName
        endDateTime
        eventName
        id
        laborCost
        personalTeam
        projectColor
        projectId
        projectName
        startDateTime
        taskId
        taskTitle
        teamId
        teamName
        thirdPartyCalendarEventId
        thirdPartyCalendarInfoList {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          thirdPartyCalendarId
        }
        versionNo
        workingTimeSec
      }
      closingProcessInfo {
        closed
        targetDateTime
      }
      end
      id
      memo
      start
      task {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        asssignee {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        calendarLinkageCode
        completeDateTime
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        estimatedRemainingTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        laborCost
        priority
        progressDeviationRate
        progressRate
        project {
          assignees {
            sortNo
          }
          calendarLinkageCode
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          estimateTimeSec
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            sortNo
          }
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          projectStatus {
            endStatus
            id
            name
            sortNo
            teamId
            versionNo
          }
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          team {
            archived
            id
            name
            organization {
              allowConnectThirdPartyCalendar
              contactMailAddress
              contractPreriod
              id
              imageUrl
              ipRestrictionFlg
              name
              plan {
                code
                contractPreriod
                id
              }
              requiredTwoFactorAuth
              suspensionFlg
              versionNo
            }
            personalTeam
            status
            versionNo
          }
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        registerDateTime
        remainingTimeSec
        scheduleWorkingTimeSec
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInMyTask
        sortNoInTaskStatus
        taskStatus {
          endStatus
          id
          name
          project {
            calendarLinkageCode
            color
            complete
            completeDateTime
            costBudgetWithoutTax
            description
            estimateTimeSec
            id
            key
            laborCost
            laborCostWithoutTax
            laborShare
            name
            priority
            registerDateTime
            salesAmountWithoutTax
            scheduledEndDateTime
            scheduledStartDateTime
            sortNoInGanttChart
            sortNoInList
            sortNoInProjectStatus
            totalScheduleTimeSec
            versionNo
            workingTimeSec
          }
          sortNo
          versionNo
        }
        title
        versionNo
        workingMembers {
          lastWorkingStartDateTime
          lastWorkingStopDateTime
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
        }
        workingTimeSec
      }
      versionNo
      workingMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      workingTimeSec
    }
  }
`;

/**
 * __useCalendarEventWorkingHistoriesQuery__
 *
 * To run a query within a React component, call `useCalendarEventWorkingHistoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarEventWorkingHistoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarEventWorkingHistoriesQuery({
 *   variables: {
 *      calendarEventId: // value for 'calendarEventId'
 *      end: // value for 'end'
 *      start: // value for 'start'
 *   },
 * });
 */
export function useCalendarEventWorkingHistoriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    CalendarEventWorkingHistoriesQuery,
    CalendarEventWorkingHistoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CalendarEventWorkingHistoriesQuery,
    CalendarEventWorkingHistoriesQueryVariables
  >(CalendarEventWorkingHistoriesDocument, options);
}
export function useCalendarEventWorkingHistoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CalendarEventWorkingHistoriesQuery,
    CalendarEventWorkingHistoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CalendarEventWorkingHistoriesQuery,
    CalendarEventWorkingHistoriesQueryVariables
  >(CalendarEventWorkingHistoriesDocument, options);
}
export type CalendarEventWorkingHistoriesQueryHookResult = ReturnType<
  typeof useCalendarEventWorkingHistoriesQuery
>;
export type CalendarEventWorkingHistoriesLazyQueryHookResult = ReturnType<
  typeof useCalendarEventWorkingHistoriesLazyQuery
>;
export type CalendarEventWorkingHistoriesQueryResult = Apollo.QueryResult<
  CalendarEventWorkingHistoriesQuery,
  CalendarEventWorkingHistoriesQueryVariables
>;
export const CalendarEventWorkingHistoriesForSummaryDocument = gql`
  query calendarEventWorkingHistoriesForSummary(
    $calendarEventId: String!
    $end: DateTime
    $start: DateTime
  ) {
    calendarEventWorkingHistoriesForSummary(
      calendarEventId: $calendarEventId
      end: $end
      start: $start
    ) {
      calendarEvent {
        clientId
        clientName
        eventName
        id
        projectId
        projectName
        teamId
      }
      closingProcessInfo {
        closed
        targetDateTime
      }
      end
      id
      memo
      start
      task {
        id
        project {
          client {
            id
            name
          }
          id
          name
          team {
            id
            personalTeam
          }
        }
        title
        versionNo
      }
      versionNo
      workingMember {
        id
        name
        profileImageUrl
      }
      workingTimeSec
    }
  }
`;

/**
 * __useCalendarEventWorkingHistoriesForSummaryQuery__
 *
 * To run a query within a React component, call `useCalendarEventWorkingHistoriesForSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarEventWorkingHistoriesForSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarEventWorkingHistoriesForSummaryQuery({
 *   variables: {
 *      calendarEventId: // value for 'calendarEventId'
 *      end: // value for 'end'
 *      start: // value for 'start'
 *   },
 * });
 */
export function useCalendarEventWorkingHistoriesForSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    CalendarEventWorkingHistoriesForSummaryQuery,
    CalendarEventWorkingHistoriesForSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CalendarEventWorkingHistoriesForSummaryQuery,
    CalendarEventWorkingHistoriesForSummaryQueryVariables
  >(CalendarEventWorkingHistoriesForSummaryDocument, options);
}
export function useCalendarEventWorkingHistoriesForSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CalendarEventWorkingHistoriesForSummaryQuery,
    CalendarEventWorkingHistoriesForSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CalendarEventWorkingHistoriesForSummaryQuery,
    CalendarEventWorkingHistoriesForSummaryQueryVariables
  >(CalendarEventWorkingHistoriesForSummaryDocument, options);
}
export type CalendarEventWorkingHistoriesForSummaryQueryHookResult = ReturnType<
  typeof useCalendarEventWorkingHistoriesForSummaryQuery
>;
export type CalendarEventWorkingHistoriesForSummaryLazyQueryHookResult = ReturnType<
  typeof useCalendarEventWorkingHistoriesForSummaryLazyQuery
>;
export type CalendarEventWorkingHistoriesForSummaryQueryResult = Apollo.QueryResult<
  CalendarEventWorkingHistoriesForSummaryQuery,
  CalendarEventWorkingHistoriesForSummaryQueryVariables
>;
export const CalendarEventWorkingSchedulesDocument = gql`
  query calendarEventWorkingSchedules($calendarEventId: String!) {
    calendarEventWorkingSchedules(calendarEventId: $calendarEventId) {
      calendarEvent {
        calendarType
        clientId
        clientName
        endDateTime
        eventName
        id
        laborCost
        personalTeam
        projectColor
        projectId
        projectName
        startDateTime
        taskId
        taskTitle
        teamId
        teamName
        thirdPartyCalendarEventId
        thirdPartyCalendarInfoList {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          thirdPartyCalendarId
        }
        versionNo
        workingTimeSec
      }
      end
      id
      memo
      start
      task {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        asssignee {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        calendarLinkageCode
        completeDateTime
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        estimatedRemainingTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        laborCost
        priority
        progressDeviationRate
        progressRate
        project {
          assignees {
            sortNo
          }
          calendarLinkageCode
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          estimateTimeSec
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            sortNo
          }
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          projectStatus {
            endStatus
            id
            name
            sortNo
            teamId
            versionNo
          }
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          team {
            archived
            id
            name
            organization {
              allowConnectThirdPartyCalendar
              contactMailAddress
              contractPreriod
              id
              imageUrl
              ipRestrictionFlg
              name
              plan {
                code
                contractPreriod
                id
              }
              requiredTwoFactorAuth
              suspensionFlg
              versionNo
            }
            personalTeam
            status
            versionNo
          }
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        registerDateTime
        remainingTimeSec
        scheduleWorkingTimeSec
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInMyTask
        sortNoInTaskStatus
        taskStatus {
          endStatus
          id
          name
          project {
            calendarLinkageCode
            color
            complete
            completeDateTime
            costBudgetWithoutTax
            description
            estimateTimeSec
            id
            key
            laborCost
            laborCostWithoutTax
            laborShare
            name
            priority
            registerDateTime
            salesAmountWithoutTax
            scheduledEndDateTime
            scheduledStartDateTime
            sortNoInGanttChart
            sortNoInList
            sortNoInProjectStatus
            totalScheduleTimeSec
            versionNo
            workingTimeSec
          }
          sortNo
          versionNo
        }
        title
        versionNo
        workingMembers {
          lastWorkingStartDateTime
          lastWorkingStopDateTime
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
        }
        workingTimeSec
      }
      versionNo
      workingMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      workingTimeSec
    }
  }
`;

/**
 * __useCalendarEventWorkingSchedulesQuery__
 *
 * To run a query within a React component, call `useCalendarEventWorkingSchedulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarEventWorkingSchedulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarEventWorkingSchedulesQuery({
 *   variables: {
 *      calendarEventId: // value for 'calendarEventId'
 *   },
 * });
 */
export function useCalendarEventWorkingSchedulesQuery(
  baseOptions: Apollo.QueryHookOptions<
    CalendarEventWorkingSchedulesQuery,
    CalendarEventWorkingSchedulesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CalendarEventWorkingSchedulesQuery,
    CalendarEventWorkingSchedulesQueryVariables
  >(CalendarEventWorkingSchedulesDocument, options);
}
export function useCalendarEventWorkingSchedulesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CalendarEventWorkingSchedulesQuery,
    CalendarEventWorkingSchedulesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CalendarEventWorkingSchedulesQuery,
    CalendarEventWorkingSchedulesQueryVariables
  >(CalendarEventWorkingSchedulesDocument, options);
}
export type CalendarEventWorkingSchedulesQueryHookResult = ReturnType<
  typeof useCalendarEventWorkingSchedulesQuery
>;
export type CalendarEventWorkingSchedulesLazyQueryHookResult = ReturnType<
  typeof useCalendarEventWorkingSchedulesLazyQuery
>;
export type CalendarEventWorkingSchedulesQueryResult = Apollo.QueryResult<
  CalendarEventWorkingSchedulesQuery,
  CalendarEventWorkingSchedulesQueryVariables
>;
export const CalendarEventWorkingSchedulesForSummaryDocument = gql`
  query calendarEventWorkingSchedulesForSummary($calendarEventId: String!) {
    calendarEventWorkingSchedulesForSummary(calendarEventId: $calendarEventId) {
      calendarEvent {
        clientId
        clientName
        eventName
        id
        projectId
        projectName
        teamId
      }
      end
      id
      memo
      start
      task {
        id
        project {
          client {
            id
            name
          }
          id
          name
          team {
            id
            personalTeam
          }
        }
        title
        versionNo
      }
      versionNo
      workingMember {
        id
        name
        profileImageUrl
      }
      workingTimeSec
    }
  }
`;

/**
 * __useCalendarEventWorkingSchedulesForSummaryQuery__
 *
 * To run a query within a React component, call `useCalendarEventWorkingSchedulesForSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarEventWorkingSchedulesForSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarEventWorkingSchedulesForSummaryQuery({
 *   variables: {
 *      calendarEventId: // value for 'calendarEventId'
 *   },
 * });
 */
export function useCalendarEventWorkingSchedulesForSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    CalendarEventWorkingSchedulesForSummaryQuery,
    CalendarEventWorkingSchedulesForSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CalendarEventWorkingSchedulesForSummaryQuery,
    CalendarEventWorkingSchedulesForSummaryQueryVariables
  >(CalendarEventWorkingSchedulesForSummaryDocument, options);
}
export function useCalendarEventWorkingSchedulesForSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CalendarEventWorkingSchedulesForSummaryQuery,
    CalendarEventWorkingSchedulesForSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CalendarEventWorkingSchedulesForSummaryQuery,
    CalendarEventWorkingSchedulesForSummaryQueryVariables
  >(CalendarEventWorkingSchedulesForSummaryDocument, options);
}
export type CalendarEventWorkingSchedulesForSummaryQueryHookResult = ReturnType<
  typeof useCalendarEventWorkingSchedulesForSummaryQuery
>;
export type CalendarEventWorkingSchedulesForSummaryLazyQueryHookResult = ReturnType<
  typeof useCalendarEventWorkingSchedulesForSummaryLazyQuery
>;
export type CalendarEventWorkingSchedulesForSummaryQueryResult = Apollo.QueryResult<
  CalendarEventWorkingSchedulesForSummaryQuery,
  CalendarEventWorkingSchedulesForSummaryQueryVariables
>;
export const CalendarWorkingHistoryDocument = gql`
  query calendarWorkingHistory($id: String!) {
    calendarWorkingHistory(id: $id) {
      calendarEvent {
        calendarType
        clientId
        clientName
        endDateTime
        eventName
        id
        personalTeam
        projectColor
        projectId
        projectName
        startDateTime
        taskId
        taskTitle
        teamId
        teamName
        thirdPartyCalendarEventId
        versionNo
      }
      end
      id
      memo
      start
      task {
        completeDateTime
        id
        project {
          client {
            id
            name
          }
          color
          complete
          id
          name
          team {
            id
            name
            personalTeam
          }
        }
        title
        versionNo
        workingMembers {
          member {
            id
            name
            profileImageUrl
          }
        }
      }
      versionNo
      workingMember {
        id
        name
        profileImageUrl
      }
      workingTimeSec
    }
  }
`;

/**
 * __useCalendarWorkingHistoryQuery__
 *
 * To run a query within a React component, call `useCalendarWorkingHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarWorkingHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarWorkingHistoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCalendarWorkingHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    CalendarWorkingHistoryQuery,
    CalendarWorkingHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CalendarWorkingHistoryQuery, CalendarWorkingHistoryQueryVariables>(
    CalendarWorkingHistoryDocument,
    options
  );
}
export function useCalendarWorkingHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CalendarWorkingHistoryQuery,
    CalendarWorkingHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CalendarWorkingHistoryQuery, CalendarWorkingHistoryQueryVariables>(
    CalendarWorkingHistoryDocument,
    options
  );
}
export type CalendarWorkingHistoryQueryHookResult = ReturnType<
  typeof useCalendarWorkingHistoryQuery
>;
export type CalendarWorkingHistoryLazyQueryHookResult = ReturnType<
  typeof useCalendarWorkingHistoryLazyQuery
>;
export type CalendarWorkingHistoryQueryResult = Apollo.QueryResult<
  CalendarWorkingHistoryQuery,
  CalendarWorkingHistoryQueryVariables
>;
export const CalendarWorkingScheduleDocument = gql`
  query calendarWorkingSchedule($id: String!) {
    calendarWorkingSchedule(id: $id) {
      calendarEvent {
        calendarType
        clientId
        clientName
        endDateTime
        eventName
        id
        personalTeam
        projectColor
        projectId
        projectName
        startDateTime
        taskId
        taskTitle
        teamId
        teamName
        thirdPartyCalendarEventId
        versionNo
      }
      end
      id
      memo
      start
      task {
        completeDateTime
        id
        project {
          client {
            id
            name
          }
          color
          complete
          id
          name
          team {
            id
            name
            personalTeam
          }
        }
        title
        versionNo
        workingMembers {
          member {
            id
            name
            profileImageUrl
          }
        }
      }
      versionNo
      workingMember {
        id
        name
        profileImageUrl
      }
      workingTimeSec
    }
  }
`;

/**
 * __useCalendarWorkingScheduleQuery__
 *
 * To run a query within a React component, call `useCalendarWorkingScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarWorkingScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarWorkingScheduleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCalendarWorkingScheduleQuery(
  baseOptions: Apollo.QueryHookOptions<
    CalendarWorkingScheduleQuery,
    CalendarWorkingScheduleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CalendarWorkingScheduleQuery, CalendarWorkingScheduleQueryVariables>(
    CalendarWorkingScheduleDocument,
    options
  );
}
export function useCalendarWorkingScheduleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CalendarWorkingScheduleQuery,
    CalendarWorkingScheduleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CalendarWorkingScheduleQuery, CalendarWorkingScheduleQueryVariables>(
    CalendarWorkingScheduleDocument,
    options
  );
}
export type CalendarWorkingScheduleQueryHookResult = ReturnType<
  typeof useCalendarWorkingScheduleQuery
>;
export type CalendarWorkingScheduleLazyQueryHookResult = ReturnType<
  typeof useCalendarWorkingScheduleLazyQuery
>;
export type CalendarWorkingScheduleQueryResult = Apollo.QueryResult<
  CalendarWorkingScheduleQuery,
  CalendarWorkingScheduleQueryVariables
>;
export const ClientDocument = gql`
  query client($id: ID!) {
    client(id: $id) {
      code
      id
      name
      sortNo
      versionNo
    }
  }
`;

/**
 * __useClientQuery__
 *
 * To run a query within a React component, call `useClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClientQuery(
  baseOptions: Apollo.QueryHookOptions<ClientQuery, ClientQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ClientQuery, ClientQueryVariables>(ClientDocument, options);
}
export function useClientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ClientQuery, ClientQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ClientQuery, ClientQueryVariables>(ClientDocument, options);
}
export type ClientQueryHookResult = ReturnType<typeof useClientQuery>;
export type ClientLazyQueryHookResult = ReturnType<typeof useClientLazyQuery>;
export type ClientQueryResult = Apollo.QueryResult<ClientQuery, ClientQueryVariables>;
export const ClientBarGraphDataDocument = gql`
  query clientBarGraphData($input: ClientGraphDataInputInput!) {
    clientBarGraphData(input: $input) {
      clientId
      clientName
      color
      percent
      reportType
      workingTimeSec
    }
  }
`;

/**
 * __useClientBarGraphDataQuery__
 *
 * To run a query within a React component, call `useClientBarGraphDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientBarGraphDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientBarGraphDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClientBarGraphDataQuery(
  baseOptions: Apollo.QueryHookOptions<ClientBarGraphDataQuery, ClientBarGraphDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ClientBarGraphDataQuery, ClientBarGraphDataQueryVariables>(
    ClientBarGraphDataDocument,
    options
  );
}
export function useClientBarGraphDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClientBarGraphDataQuery,
    ClientBarGraphDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ClientBarGraphDataQuery, ClientBarGraphDataQueryVariables>(
    ClientBarGraphDataDocument,
    options
  );
}
export type ClientBarGraphDataQueryHookResult = ReturnType<typeof useClientBarGraphDataQuery>;
export type ClientBarGraphDataLazyQueryHookResult = ReturnType<
  typeof useClientBarGraphDataLazyQuery
>;
export type ClientBarGraphDataQueryResult = Apollo.QueryResult<
  ClientBarGraphDataQuery,
  ClientBarGraphDataQueryVariables
>;
export const ClientByCodeDocument = gql`
  query clientByCode($code: String!) {
    clientByCode(code: $code) {
      code
      id
      name
      sortNo
      versionNo
    }
  }
`;

/**
 * __useClientByCodeQuery__
 *
 * To run a query within a React component, call `useClientByCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientByCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientByCodeQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useClientByCodeQuery(
  baseOptions: Apollo.QueryHookOptions<ClientByCodeQuery, ClientByCodeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ClientByCodeQuery, ClientByCodeQueryVariables>(
    ClientByCodeDocument,
    options
  );
}
export function useClientByCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ClientByCodeQuery, ClientByCodeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ClientByCodeQuery, ClientByCodeQueryVariables>(
    ClientByCodeDocument,
    options
  );
}
export type ClientByCodeQueryHookResult = ReturnType<typeof useClientByCodeQuery>;
export type ClientByCodeLazyQueryHookResult = ReturnType<typeof useClientByCodeLazyQuery>;
export type ClientByCodeQueryResult = Apollo.QueryResult<
  ClientByCodeQuery,
  ClientByCodeQueryVariables
>;
export const ClientDateGraphDataDocument = gql`
  query clientDateGraphData($input: ClientGraphDataInputInput!) {
    clientDateGraphData(input: $input) {
      date
      items {
        clientId
        clientName
        color
        percent
        workingTimeSec
      }
      reportType
    }
  }
`;

/**
 * __useClientDateGraphDataQuery__
 *
 * To run a query within a React component, call `useClientDateGraphDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientDateGraphDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientDateGraphDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClientDateGraphDataQuery(
  baseOptions: Apollo.QueryHookOptions<ClientDateGraphDataQuery, ClientDateGraphDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ClientDateGraphDataQuery, ClientDateGraphDataQueryVariables>(
    ClientDateGraphDataDocument,
    options
  );
}
export function useClientDateGraphDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClientDateGraphDataQuery,
    ClientDateGraphDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ClientDateGraphDataQuery, ClientDateGraphDataQueryVariables>(
    ClientDateGraphDataDocument,
    options
  );
}
export type ClientDateGraphDataQueryHookResult = ReturnType<typeof useClientDateGraphDataQuery>;
export type ClientDateGraphDataLazyQueryHookResult = ReturnType<
  typeof useClientDateGraphDataLazyQuery
>;
export type ClientDateGraphDataQueryResult = Apollo.QueryResult<
  ClientDateGraphDataQuery,
  ClientDateGraphDataQueryVariables
>;
export const ClientProjectsDocument = gql`
  query clientProjects($clientId: String!) {
    clientProjects(clientId: $clientId) {
      assignees {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      calendarLinkageCode
      client {
        code
        id
        name
        sortNo
        versionNo
      }
      color
      complete
      completeDateTime
      costBudgetWithoutTax
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      favoriteMembers {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      favoriteMembersInfo {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      id
      key
      laborCost
      laborCostWithoutTax
      laborShare
      name
      priority
      projectStatus {
        endStatus
        id
        name
        sortNo
        teamId
        versionNo
      }
      registerDateTime
      salesAmountWithoutTax
      scheduledEndDateTime
      scheduledStartDateTime
      sortNoInGanttChart
      sortNoInList
      sortNoInProjectStatus
      team {
        archived
        id
        name
        organization {
          allowConnectThirdPartyCalendar
          contactMailAddress
          contractPreriod
          id
          imageUrl
          ipRestrictionFlg
          name
          plan {
            code
            contractPreriod
            id
          }
          requiredTwoFactorAuth
          suspensionFlg
          versionNo
        }
        personalTeam
        status
        versionNo
      }
      totalScheduleTimeSec
      versionNo
      workingTimeSec
    }
  }
`;

/**
 * __useClientProjectsQuery__
 *
 * To run a query within a React component, call `useClientProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientProjectsQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useClientProjectsQuery(
  baseOptions: Apollo.QueryHookOptions<ClientProjectsQuery, ClientProjectsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ClientProjectsQuery, ClientProjectsQueryVariables>(
    ClientProjectsDocument,
    options
  );
}
export function useClientProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ClientProjectsQuery, ClientProjectsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ClientProjectsQuery, ClientProjectsQueryVariables>(
    ClientProjectsDocument,
    options
  );
}
export type ClientProjectsQueryHookResult = ReturnType<typeof useClientProjectsQuery>;
export type ClientProjectsLazyQueryHookResult = ReturnType<typeof useClientProjectsLazyQuery>;
export type ClientProjectsQueryResult = Apollo.QueryResult<
  ClientProjectsQuery,
  ClientProjectsQueryVariables
>;
export const ClientSunburstGraphDataDocument = gql`
  query clientSunburstGraphData($input: ClientGraphDataInputInput!) {
    clientSunburstGraphData(input: $input) {
      clientId
      color
      name
      percent
      reportType
      workingTimeSec
    }
  }
`;

/**
 * __useClientSunburstGraphDataQuery__
 *
 * To run a query within a React component, call `useClientSunburstGraphDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientSunburstGraphDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientSunburstGraphDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClientSunburstGraphDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClientSunburstGraphDataQuery,
    ClientSunburstGraphDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ClientSunburstGraphDataQuery, ClientSunburstGraphDataQueryVariables>(
    ClientSunburstGraphDataDocument,
    options
  );
}
export function useClientSunburstGraphDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClientSunburstGraphDataQuery,
    ClientSunburstGraphDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ClientSunburstGraphDataQuery, ClientSunburstGraphDataQueryVariables>(
    ClientSunburstGraphDataDocument,
    options
  );
}
export type ClientSunburstGraphDataQueryHookResult = ReturnType<
  typeof useClientSunburstGraphDataQuery
>;
export type ClientSunburstGraphDataLazyQueryHookResult = ReturnType<
  typeof useClientSunburstGraphDataLazyQuery
>;
export type ClientSunburstGraphDataQueryResult = Apollo.QueryResult<
  ClientSunburstGraphDataQuery,
  ClientSunburstGraphDataQueryVariables
>;
export const ClosingSettingsDocument = gql`
  query closingSettings {
    closingSettings {
      id
      targetDateTime
      timeZone {
        code
        id
        name
        offsetTime
        sortNo
      }
      versionNo
    }
  }
`;

/**
 * __useClosingSettingsQuery__
 *
 * To run a query within a React component, call `useClosingSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClosingSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClosingSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useClosingSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<ClosingSettingsQuery, ClosingSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ClosingSettingsQuery, ClosingSettingsQueryVariables>(
    ClosingSettingsDocument,
    options
  );
}
export function useClosingSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ClosingSettingsQuery, ClosingSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ClosingSettingsQuery, ClosingSettingsQueryVariables>(
    ClosingSettingsDocument,
    options
  );
}
export type ClosingSettingsQueryHookResult = ReturnType<typeof useClosingSettingsQuery>;
export type ClosingSettingsLazyQueryHookResult = ReturnType<typeof useClosingSettingsLazyQuery>;
export type ClosingSettingsQueryResult = Apollo.QueryResult<
  ClosingSettingsQuery,
  ClosingSettingsQueryVariables
>;
export const ConfirmAmountAfterLicenceCountChangeDocument = gql`
  query confirmAmountAfterLicenceCountChange(
    $id: ID!
    $input: ConfirmAmountAfterLicenceCountChangeInputInput
  ) {
    confirmAmountAfterLicenceCountChange(id: $id, input: $input) {
      dailyRateAmount
      nextTotalAmount
    }
  }
`;

/**
 * __useConfirmAmountAfterLicenceCountChangeQuery__
 *
 * To run a query within a React component, call `useConfirmAmountAfterLicenceCountChangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfirmAmountAfterLicenceCountChangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfirmAmountAfterLicenceCountChangeQuery({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmAmountAfterLicenceCountChangeQuery(
  baseOptions: Apollo.QueryHookOptions<
    ConfirmAmountAfterLicenceCountChangeQuery,
    ConfirmAmountAfterLicenceCountChangeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ConfirmAmountAfterLicenceCountChangeQuery,
    ConfirmAmountAfterLicenceCountChangeQueryVariables
  >(ConfirmAmountAfterLicenceCountChangeDocument, options);
}
export function useConfirmAmountAfterLicenceCountChangeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ConfirmAmountAfterLicenceCountChangeQuery,
    ConfirmAmountAfterLicenceCountChangeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ConfirmAmountAfterLicenceCountChangeQuery,
    ConfirmAmountAfterLicenceCountChangeQueryVariables
  >(ConfirmAmountAfterLicenceCountChangeDocument, options);
}
export type ConfirmAmountAfterLicenceCountChangeQueryHookResult = ReturnType<
  typeof useConfirmAmountAfterLicenceCountChangeQuery
>;
export type ConfirmAmountAfterLicenceCountChangeLazyQueryHookResult = ReturnType<
  typeof useConfirmAmountAfterLicenceCountChangeLazyQuery
>;
export type ConfirmAmountAfterLicenceCountChangeQueryResult = Apollo.QueryResult<
  ConfirmAmountAfterLicenceCountChangeQuery,
  ConfirmAmountAfterLicenceCountChangeQueryVariables
>;
export const ConfirmJoinableDocument = gql`
  query confirmJoinable($key: String!) {
    confirmJoinable(key: $key)
  }
`;

/**
 * __useConfirmJoinableQuery__
 *
 * To run a query within a React component, call `useConfirmJoinableQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfirmJoinableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfirmJoinableQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useConfirmJoinableQuery(
  baseOptions: Apollo.QueryHookOptions<ConfirmJoinableQuery, ConfirmJoinableQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ConfirmJoinableQuery, ConfirmJoinableQueryVariables>(
    ConfirmJoinableDocument,
    options
  );
}
export function useConfirmJoinableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ConfirmJoinableQuery, ConfirmJoinableQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ConfirmJoinableQuery, ConfirmJoinableQueryVariables>(
    ConfirmJoinableDocument,
    options
  );
}
export type ConfirmJoinableQueryHookResult = ReturnType<typeof useConfirmJoinableQuery>;
export type ConfirmJoinableLazyQueryHookResult = ReturnType<typeof useConfirmJoinableLazyQuery>;
export type ConfirmJoinableQueryResult = Apollo.QueryResult<
  ConfirmJoinableQuery,
  ConfirmJoinableQueryVariables
>;
export const ConfirmJoinableWithLinkDocument = gql`
  query confirmJoinableWithLink($key: String!) {
    confirmJoinableWithLink(key: $key)
  }
`;

/**
 * __useConfirmJoinableWithLinkQuery__
 *
 * To run a query within a React component, call `useConfirmJoinableWithLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfirmJoinableWithLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfirmJoinableWithLinkQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useConfirmJoinableWithLinkQuery(
  baseOptions: Apollo.QueryHookOptions<
    ConfirmJoinableWithLinkQuery,
    ConfirmJoinableWithLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ConfirmJoinableWithLinkQuery, ConfirmJoinableWithLinkQueryVariables>(
    ConfirmJoinableWithLinkDocument,
    options
  );
}
export function useConfirmJoinableWithLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ConfirmJoinableWithLinkQuery,
    ConfirmJoinableWithLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ConfirmJoinableWithLinkQuery, ConfirmJoinableWithLinkQueryVariables>(
    ConfirmJoinableWithLinkDocument,
    options
  );
}
export type ConfirmJoinableWithLinkQueryHookResult = ReturnType<
  typeof useConfirmJoinableWithLinkQuery
>;
export type ConfirmJoinableWithLinkLazyQueryHookResult = ReturnType<
  typeof useConfirmJoinableWithLinkLazyQuery
>;
export type ConfirmJoinableWithLinkQueryResult = Apollo.QueryResult<
  ConfirmJoinableWithLinkQuery,
  ConfirmJoinableWithLinkQueryVariables
>;
export const ContractDocument = gql`
  query contract {
    contract {
      billToMailAddress
      billingType
      contractPreriod
      freeLicenceCoupon {
        freeEndLicenceCount
        freeStartLicenceCount
        id
      }
      freeTrialExpireDatetime
      id
      licenceCount
      plan {
        code
        contractPreriod
        id
      }
      revenuecatOriginalAppUserId
      usingLicenceCount
      versionNo
    }
  }
`;

/**
 * __useContractQuery__
 *
 * To run a query within a React component, call `useContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractQuery({
 *   variables: {
 *   },
 * });
 */
export function useContractQuery(
  baseOptions?: Apollo.QueryHookOptions<ContractQuery, ContractQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ContractQuery, ContractQueryVariables>(ContractDocument, options);
}
export function useContractLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ContractQuery, ContractQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ContractQuery, ContractQueryVariables>(ContractDocument, options);
}
export type ContractQueryHookResult = ReturnType<typeof useContractQuery>;
export type ContractLazyQueryHookResult = ReturnType<typeof useContractLazyQuery>;
export type ContractQueryResult = Apollo.QueryResult<ContractQuery, ContractQueryVariables>;
export const CreditCardDocument = gql`
  query creditCard {
    creditCard {
      cardNumberLast4
      expireYearMonth
    }
  }
`;

/**
 * __useCreditCardQuery__
 *
 * To run a query within a React component, call `useCreditCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditCardQuery({
 *   variables: {
 *   },
 * });
 */
export function useCreditCardQuery(
  baseOptions?: Apollo.QueryHookOptions<CreditCardQuery, CreditCardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CreditCardQuery, CreditCardQueryVariables>(CreditCardDocument, options);
}
export function useCreditCardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CreditCardQuery, CreditCardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CreditCardQuery, CreditCardQueryVariables>(
    CreditCardDocument,
    options
  );
}
export type CreditCardQueryHookResult = ReturnType<typeof useCreditCardQuery>;
export type CreditCardLazyQueryHookResult = ReturnType<typeof useCreditCardLazyQuery>;
export type CreditCardQueryResult = Apollo.QueryResult<CreditCardQuery, CreditCardQueryVariables>;
export const CsvReportItemDefinitionDocument = gql`
  query csvReportItemDefinition($id: ID!) {
    csvReportItemDefinition(id: $id) {
      id
      items {
        id
        reportItemCode
        reportItemName
        sortNo
      }
      name
      versionNo
    }
  }
`;

/**
 * __useCsvReportItemDefinitionQuery__
 *
 * To run a query within a React component, call `useCsvReportItemDefinitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCsvReportItemDefinitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCsvReportItemDefinitionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCsvReportItemDefinitionQuery(
  baseOptions: Apollo.QueryHookOptions<
    CsvReportItemDefinitionQuery,
    CsvReportItemDefinitionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CsvReportItemDefinitionQuery, CsvReportItemDefinitionQueryVariables>(
    CsvReportItemDefinitionDocument,
    options
  );
}
export function useCsvReportItemDefinitionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CsvReportItemDefinitionQuery,
    CsvReportItemDefinitionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CsvReportItemDefinitionQuery, CsvReportItemDefinitionQueryVariables>(
    CsvReportItemDefinitionDocument,
    options
  );
}
export type CsvReportItemDefinitionQueryHookResult = ReturnType<
  typeof useCsvReportItemDefinitionQuery
>;
export type CsvReportItemDefinitionLazyQueryHookResult = ReturnType<
  typeof useCsvReportItemDefinitionLazyQuery
>;
export type CsvReportItemDefinitionQueryResult = Apollo.QueryResult<
  CsvReportItemDefinitionQuery,
  CsvReportItemDefinitionQueryVariables
>;
export const CsvReportItemDefinitionsDocument = gql`
  query csvReportItemDefinitions {
    csvReportItemDefinitions {
      id
      items {
        id
        reportItemCode
        reportItemName
        sortNo
      }
      name
      versionNo
    }
  }
`;

/**
 * __useCsvReportItemDefinitionsQuery__
 *
 * To run a query within a React component, call `useCsvReportItemDefinitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCsvReportItemDefinitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCsvReportItemDefinitionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCsvReportItemDefinitionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CsvReportItemDefinitionsQuery,
    CsvReportItemDefinitionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CsvReportItemDefinitionsQuery, CsvReportItemDefinitionsQueryVariables>(
    CsvReportItemDefinitionsDocument,
    options
  );
}
export function useCsvReportItemDefinitionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CsvReportItemDefinitionsQuery,
    CsvReportItemDefinitionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CsvReportItemDefinitionsQuery, CsvReportItemDefinitionsQueryVariables>(
    CsvReportItemDefinitionsDocument,
    options
  );
}
export type CsvReportItemDefinitionsQueryHookResult = ReturnType<
  typeof useCsvReportItemDefinitionsQuery
>;
export type CsvReportItemDefinitionsLazyQueryHookResult = ReturnType<
  typeof useCsvReportItemDefinitionsLazyQuery
>;
export type CsvReportItemDefinitionsQueryResult = Apollo.QueryResult<
  CsvReportItemDefinitionsQuery,
  CsvReportItemDefinitionsQueryVariables
>;
export const DefaultCsvReportItemDefinitionDocument = gql`
  query defaultCsvReportItemDefinition {
    defaultCsvReportItemDefinition {
      id
      items {
        id
        reportItemCode
        reportItemName
        sortNo
      }
      name
      versionNo
    }
  }
`;

/**
 * __useDefaultCsvReportItemDefinitionQuery__
 *
 * To run a query within a React component, call `useDefaultCsvReportItemDefinitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useDefaultCsvReportItemDefinitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDefaultCsvReportItemDefinitionQuery({
 *   variables: {
 *   },
 * });
 */
export function useDefaultCsvReportItemDefinitionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DefaultCsvReportItemDefinitionQuery,
    DefaultCsvReportItemDefinitionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DefaultCsvReportItemDefinitionQuery,
    DefaultCsvReportItemDefinitionQueryVariables
  >(DefaultCsvReportItemDefinitionDocument, options);
}
export function useDefaultCsvReportItemDefinitionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DefaultCsvReportItemDefinitionQuery,
    DefaultCsvReportItemDefinitionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DefaultCsvReportItemDefinitionQuery,
    DefaultCsvReportItemDefinitionQueryVariables
  >(DefaultCsvReportItemDefinitionDocument, options);
}
export type DefaultCsvReportItemDefinitionQueryHookResult = ReturnType<
  typeof useDefaultCsvReportItemDefinitionQuery
>;
export type DefaultCsvReportItemDefinitionLazyQueryHookResult = ReturnType<
  typeof useDefaultCsvReportItemDefinitionLazyQuery
>;
export type DefaultCsvReportItemDefinitionQueryResult = Apollo.QueryResult<
  DefaultCsvReportItemDefinitionQuery,
  DefaultCsvReportItemDefinitionQueryVariables
>;
export const EnvironmentDocument = gql`
  query environment {
    environment
  }
`;

/**
 * __useEnvironmentQuery__
 *
 * To run a query within a React component, call `useEnvironmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnvironmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnvironmentQuery({
 *   variables: {
 *   },
 * });
 */
export function useEnvironmentQuery(
  baseOptions?: Apollo.QueryHookOptions<EnvironmentQuery, EnvironmentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EnvironmentQuery, EnvironmentQueryVariables>(EnvironmentDocument, options);
}
export function useEnvironmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EnvironmentQuery, EnvironmentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EnvironmentQuery, EnvironmentQueryVariables>(
    EnvironmentDocument,
    options
  );
}
export type EnvironmentQueryHookResult = ReturnType<typeof useEnvironmentQuery>;
export type EnvironmentLazyQueryHookResult = ReturnType<typeof useEnvironmentLazyQuery>;
export type EnvironmentQueryResult = Apollo.QueryResult<
  EnvironmentQuery,
  EnvironmentQueryVariables
>;
export const ExistAdminMemberDocument = gql`
  query existAdminMember($excludeMemberId: String!) {
    existAdminMember(excludeMemberId: $excludeMemberId)
  }
`;

/**
 * __useExistAdminMemberQuery__
 *
 * To run a query within a React component, call `useExistAdminMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useExistAdminMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExistAdminMemberQuery({
 *   variables: {
 *      excludeMemberId: // value for 'excludeMemberId'
 *   },
 * });
 */
export function useExistAdminMemberQuery(
  baseOptions: Apollo.QueryHookOptions<ExistAdminMemberQuery, ExistAdminMemberQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExistAdminMemberQuery, ExistAdminMemberQueryVariables>(
    ExistAdminMemberDocument,
    options
  );
}
export function useExistAdminMemberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ExistAdminMemberQuery, ExistAdminMemberQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExistAdminMemberQuery, ExistAdminMemberQueryVariables>(
    ExistAdminMemberDocument,
    options
  );
}
export type ExistAdminMemberQueryHookResult = ReturnType<typeof useExistAdminMemberQuery>;
export type ExistAdminMemberLazyQueryHookResult = ReturnType<typeof useExistAdminMemberLazyQuery>;
export type ExistAdminMemberQueryResult = Apollo.QueryResult<
  ExistAdminMemberQuery,
  ExistAdminMemberQueryVariables
>;
export const ExportClientExcelDocument = gql`
  query exportClientExcel($id: ID!) {
    exportClientExcel(id: $id) {
      id
      requestDateTime
      requestMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      status
      timeZoneOffset
      url
      versionNo
    }
  }
`;

/**
 * __useExportClientExcelQuery__
 *
 * To run a query within a React component, call `useExportClientExcelQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportClientExcelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportClientExcelQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExportClientExcelQuery(
  baseOptions: Apollo.QueryHookOptions<ExportClientExcelQuery, ExportClientExcelQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExportClientExcelQuery, ExportClientExcelQueryVariables>(
    ExportClientExcelDocument,
    options
  );
}
export function useExportClientExcelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ExportClientExcelQuery, ExportClientExcelQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExportClientExcelQuery, ExportClientExcelQueryVariables>(
    ExportClientExcelDocument,
    options
  );
}
export type ExportClientExcelQueryHookResult = ReturnType<typeof useExportClientExcelQuery>;
export type ExportClientExcelLazyQueryHookResult = ReturnType<typeof useExportClientExcelLazyQuery>;
export type ExportClientExcelQueryResult = Apollo.QueryResult<
  ExportClientExcelQuery,
  ExportClientExcelQueryVariables
>;
export const ExportClientExcelsDocument = gql`
  query exportClientExcels {
    exportClientExcels {
      id
      requestDateTime
      requestMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      status
      timeZoneOffset
      url
      versionNo
    }
  }
`;

/**
 * __useExportClientExcelsQuery__
 *
 * To run a query within a React component, call `useExportClientExcelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportClientExcelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportClientExcelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useExportClientExcelsQuery(
  baseOptions?: Apollo.QueryHookOptions<ExportClientExcelsQuery, ExportClientExcelsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExportClientExcelsQuery, ExportClientExcelsQueryVariables>(
    ExportClientExcelsDocument,
    options
  );
}
export function useExportClientExcelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExportClientExcelsQuery,
    ExportClientExcelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExportClientExcelsQuery, ExportClientExcelsQueryVariables>(
    ExportClientExcelsDocument,
    options
  );
}
export type ExportClientExcelsQueryHookResult = ReturnType<typeof useExportClientExcelsQuery>;
export type ExportClientExcelsLazyQueryHookResult = ReturnType<
  typeof useExportClientExcelsLazyQuery
>;
export type ExportClientExcelsQueryResult = Apollo.QueryResult<
  ExportClientExcelsQuery,
  ExportClientExcelsQueryVariables
>;
export const ExportMemberExcelDocument = gql`
  query exportMemberExcel($id: ID!) {
    exportMemberExcel(id: $id) {
      id
      requestDateTime
      requestMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      status
      timeZoneOffset
      url
      versionNo
    }
  }
`;

/**
 * __useExportMemberExcelQuery__
 *
 * To run a query within a React component, call `useExportMemberExcelQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportMemberExcelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportMemberExcelQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExportMemberExcelQuery(
  baseOptions: Apollo.QueryHookOptions<ExportMemberExcelQuery, ExportMemberExcelQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExportMemberExcelQuery, ExportMemberExcelQueryVariables>(
    ExportMemberExcelDocument,
    options
  );
}
export function useExportMemberExcelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ExportMemberExcelQuery, ExportMemberExcelQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExportMemberExcelQuery, ExportMemberExcelQueryVariables>(
    ExportMemberExcelDocument,
    options
  );
}
export type ExportMemberExcelQueryHookResult = ReturnType<typeof useExportMemberExcelQuery>;
export type ExportMemberExcelLazyQueryHookResult = ReturnType<typeof useExportMemberExcelLazyQuery>;
export type ExportMemberExcelQueryResult = Apollo.QueryResult<
  ExportMemberExcelQuery,
  ExportMemberExcelQueryVariables
>;
export const ExportMemberExcelsDocument = gql`
  query exportMemberExcels {
    exportMemberExcels {
      id
      requestDateTime
      requestMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      status
      timeZoneOffset
      url
      versionNo
    }
  }
`;

/**
 * __useExportMemberExcelsQuery__
 *
 * To run a query within a React component, call `useExportMemberExcelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportMemberExcelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportMemberExcelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useExportMemberExcelsQuery(
  baseOptions?: Apollo.QueryHookOptions<ExportMemberExcelsQuery, ExportMemberExcelsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExportMemberExcelsQuery, ExportMemberExcelsQueryVariables>(
    ExportMemberExcelsDocument,
    options
  );
}
export function useExportMemberExcelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExportMemberExcelsQuery,
    ExportMemberExcelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExportMemberExcelsQuery, ExportMemberExcelsQueryVariables>(
    ExportMemberExcelsDocument,
    options
  );
}
export type ExportMemberExcelsQueryHookResult = ReturnType<typeof useExportMemberExcelsQuery>;
export type ExportMemberExcelsLazyQueryHookResult = ReturnType<
  typeof useExportMemberExcelsLazyQuery
>;
export type ExportMemberExcelsQueryResult = Apollo.QueryResult<
  ExportMemberExcelsQuery,
  ExportMemberExcelsQueryVariables
>;
export const ExportProjectExcelDocument = gql`
  query exportProjectExcel($id: ID!) {
    exportProjectExcel(id: $id) {
      id
      includeCompleted
      requestDateTime
      requestMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      status
      teamid
      timeZoneOffset
      url
      versionNo
    }
  }
`;

/**
 * __useExportProjectExcelQuery__
 *
 * To run a query within a React component, call `useExportProjectExcelQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportProjectExcelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportProjectExcelQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExportProjectExcelQuery(
  baseOptions: Apollo.QueryHookOptions<ExportProjectExcelQuery, ExportProjectExcelQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExportProjectExcelQuery, ExportProjectExcelQueryVariables>(
    ExportProjectExcelDocument,
    options
  );
}
export function useExportProjectExcelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExportProjectExcelQuery,
    ExportProjectExcelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExportProjectExcelQuery, ExportProjectExcelQueryVariables>(
    ExportProjectExcelDocument,
    options
  );
}
export type ExportProjectExcelQueryHookResult = ReturnType<typeof useExportProjectExcelQuery>;
export type ExportProjectExcelLazyQueryHookResult = ReturnType<
  typeof useExportProjectExcelLazyQuery
>;
export type ExportProjectExcelQueryResult = Apollo.QueryResult<
  ExportProjectExcelQuery,
  ExportProjectExcelQueryVariables
>;
export const ExportProjectExcelsDocument = gql`
  query exportProjectExcels($input: FetchExportProjectExcelInputInput!) {
    exportProjectExcels(input: $input) {
      id
      includeCompleted
      requestDateTime
      requestMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      status
      teamid
      timeZoneOffset
      url
      versionNo
    }
  }
`;

/**
 * __useExportProjectExcelsQuery__
 *
 * To run a query within a React component, call `useExportProjectExcelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportProjectExcelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportProjectExcelsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportProjectExcelsQuery(
  baseOptions: Apollo.QueryHookOptions<ExportProjectExcelsQuery, ExportProjectExcelsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExportProjectExcelsQuery, ExportProjectExcelsQueryVariables>(
    ExportProjectExcelsDocument,
    options
  );
}
export function useExportProjectExcelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExportProjectExcelsQuery,
    ExportProjectExcelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExportProjectExcelsQuery, ExportProjectExcelsQueryVariables>(
    ExportProjectExcelsDocument,
    options
  );
}
export type ExportProjectExcelsQueryHookResult = ReturnType<typeof useExportProjectExcelsQuery>;
export type ExportProjectExcelsLazyQueryHookResult = ReturnType<
  typeof useExportProjectExcelsLazyQuery
>;
export type ExportProjectExcelsQueryResult = Apollo.QueryResult<
  ExportProjectExcelsQuery,
  ExportProjectExcelsQueryVariables
>;
export const ExportTaskExcelDocument = gql`
  query exportTaskExcel($id: ID!) {
    exportTaskExcel(id: $id) {
      id
      includeCompleted
      requestDateTime
      requestMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      status
      teamid
      timeZoneOffset
      url
      versionNo
    }
  }
`;

/**
 * __useExportTaskExcelQuery__
 *
 * To run a query within a React component, call `useExportTaskExcelQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportTaskExcelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportTaskExcelQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExportTaskExcelQuery(
  baseOptions: Apollo.QueryHookOptions<ExportTaskExcelQuery, ExportTaskExcelQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExportTaskExcelQuery, ExportTaskExcelQueryVariables>(
    ExportTaskExcelDocument,
    options
  );
}
export function useExportTaskExcelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ExportTaskExcelQuery, ExportTaskExcelQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExportTaskExcelQuery, ExportTaskExcelQueryVariables>(
    ExportTaskExcelDocument,
    options
  );
}
export type ExportTaskExcelQueryHookResult = ReturnType<typeof useExportTaskExcelQuery>;
export type ExportTaskExcelLazyQueryHookResult = ReturnType<typeof useExportTaskExcelLazyQuery>;
export type ExportTaskExcelQueryResult = Apollo.QueryResult<
  ExportTaskExcelQuery,
  ExportTaskExcelQueryVariables
>;
export const ExportTaskExcelsDocument = gql`
  query exportTaskExcels($input: FetchExportTaskExcelInputInput!) {
    exportTaskExcels(input: $input) {
      id
      includeCompleted
      requestDateTime
      requestMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      status
      teamid
      timeZoneOffset
      url
      versionNo
    }
  }
`;

/**
 * __useExportTaskExcelsQuery__
 *
 * To run a query within a React component, call `useExportTaskExcelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportTaskExcelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportTaskExcelsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportTaskExcelsQuery(
  baseOptions: Apollo.QueryHookOptions<ExportTaskExcelsQuery, ExportTaskExcelsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExportTaskExcelsQuery, ExportTaskExcelsQueryVariables>(
    ExportTaskExcelsDocument,
    options
  );
}
export function useExportTaskExcelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ExportTaskExcelsQuery, ExportTaskExcelsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExportTaskExcelsQuery, ExportTaskExcelsQueryVariables>(
    ExportTaskExcelsDocument,
    options
  );
}
export type ExportTaskExcelsQueryHookResult = ReturnType<typeof useExportTaskExcelsQuery>;
export type ExportTaskExcelsLazyQueryHookResult = ReturnType<typeof useExportTaskExcelsLazyQuery>;
export type ExportTaskExcelsQueryResult = Apollo.QueryResult<
  ExportTaskExcelsQuery,
  ExportTaskExcelsQueryVariables
>;
export const FavoriteProjectsDocument = gql`
  query favoriteProjects($input: FetchFavoriteProjectsInputInput, $limit: Int, $offset: Int) {
    favoriteProjects(input: $input, limit: $limit, offset: $offset) {
      assignees {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      calendarLinkageCode
      client {
        code
        id
        name
        sortNo
        versionNo
      }
      color
      complete
      completeDateTime
      costBudgetWithoutTax
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      favoriteMembers {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      favoriteMembersInfo {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      id
      key
      laborCost
      laborCostWithoutTax
      laborShare
      name
      priority
      projectStatus {
        endStatus
        id
        name
        sortNo
        teamId
        versionNo
      }
      registerDateTime
      salesAmountWithoutTax
      scheduledEndDateTime
      scheduledStartDateTime
      sortNoInGanttChart
      sortNoInList
      sortNoInProjectStatus
      team {
        archived
        id
        name
        organization {
          allowConnectThirdPartyCalendar
          contactMailAddress
          contractPreriod
          id
          imageUrl
          ipRestrictionFlg
          name
          plan {
            code
            contractPreriod
            id
          }
          requiredTwoFactorAuth
          suspensionFlg
          versionNo
        }
        personalTeam
        status
        versionNo
      }
      totalScheduleTimeSec
      versionNo
      workingTimeSec
    }
  }
`;

/**
 * __useFavoriteProjectsQuery__
 *
 * To run a query within a React component, call `useFavoriteProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFavoriteProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFavoriteProjectsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useFavoriteProjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<FavoriteProjectsQuery, FavoriteProjectsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FavoriteProjectsQuery, FavoriteProjectsQueryVariables>(
    FavoriteProjectsDocument,
    options
  );
}
export function useFavoriteProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FavoriteProjectsQuery, FavoriteProjectsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FavoriteProjectsQuery, FavoriteProjectsQueryVariables>(
    FavoriteProjectsDocument,
    options
  );
}
export type FavoriteProjectsQueryHookResult = ReturnType<typeof useFavoriteProjectsQuery>;
export type FavoriteProjectsLazyQueryHookResult = ReturnType<typeof useFavoriteProjectsLazyQuery>;
export type FavoriteProjectsQueryResult = Apollo.QueryResult<
  FavoriteProjectsQuery,
  FavoriteProjectsQueryVariables
>;
export const FavoriteTasksDocument = gql`
  query favoriteTasks {
    favoriteTasks {
      assignees {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      asssignee {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      calendarLinkageCode
      completeDateTime
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      estimatedRemainingTimeSec
      favoriteMembers {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      favoriteMembersInfo {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      id
      laborCost
      priority
      progressDeviationRate
      progressRate
      project {
        assignees {
          sortNo
        }
        calendarLinkageCode
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        color
        complete
        completeDateTime
        costBudgetWithoutTax
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          sortNo
        }
        id
        key
        laborCost
        laborCostWithoutTax
        laborShare
        name
        priority
        projectStatus {
          endStatus
          id
          name
          sortNo
          teamId
          versionNo
        }
        registerDateTime
        salesAmountWithoutTax
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInProjectStatus
        team {
          archived
          id
          name
          organization {
            allowConnectThirdPartyCalendar
            contactMailAddress
            contractPreriod
            id
            imageUrl
            ipRestrictionFlg
            name
            plan {
              code
              contractPreriod
              id
            }
            requiredTwoFactorAuth
            suspensionFlg
            versionNo
          }
          personalTeam
          status
          versionNo
        }
        totalScheduleTimeSec
        versionNo
        workingTimeSec
      }
      registerDateTime
      remainingTimeSec
      scheduleWorkingTimeSec
      scheduledEndDateTime
      scheduledStartDateTime
      sortNoInGanttChart
      sortNoInList
      sortNoInMyTask
      sortNoInTaskStatus
      taskStatus {
        endStatus
        id
        name
        project {
          calendarLinkageCode
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          description
          estimateTimeSec
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        sortNo
        versionNo
      }
      title
      versionNo
      workingMembers {
        lastWorkingStartDateTime
        lastWorkingStopDateTime
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
      }
      workingTimeSec
    }
  }
`;

/**
 * __useFavoriteTasksQuery__
 *
 * To run a query within a React component, call `useFavoriteTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useFavoriteTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFavoriteTasksQuery({
 *   variables: {
 *   },
 * });
 */
export function useFavoriteTasksQuery(
  baseOptions?: Apollo.QueryHookOptions<FavoriteTasksQuery, FavoriteTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FavoriteTasksQuery, FavoriteTasksQueryVariables>(
    FavoriteTasksDocument,
    options
  );
}
export function useFavoriteTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FavoriteTasksQuery, FavoriteTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FavoriteTasksQuery, FavoriteTasksQueryVariables>(
    FavoriteTasksDocument,
    options
  );
}
export type FavoriteTasksQueryHookResult = ReturnType<typeof useFavoriteTasksQuery>;
export type FavoriteTasksLazyQueryHookResult = ReturnType<typeof useFavoriteTasksLazyQuery>;
export type FavoriteTasksQueryResult = Apollo.QueryResult<
  FavoriteTasksQuery,
  FavoriteTasksQueryVariables
>;
export const FetchOrganizationActiveMembersDocument = gql`
  query fetchOrganizationActiveMembers {
    fetchOrganizationActiveMembers {
      latestWorkingHistory {
        calendarEvent {
          eventName
          id
          workingTimeSec
        }
        end
        id
        project {
          id
          projectName
        }
        start
        task {
          completeDateTime
          estimateTimeSec
          id
          priority
          progressRate
          scheduledEndDateTime
          title
          versionNo
          workingMembers {
            id
            lastWorkingStartDateTime
          }
          workingTimeSec
        }
        versionNo
        workingTimeSec
      }
      member {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
    }
  }
`;

/**
 * __useFetchOrganizationActiveMembersQuery__
 *
 * To run a query within a React component, call `useFetchOrganizationActiveMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchOrganizationActiveMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchOrganizationActiveMembersQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchOrganizationActiveMembersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchOrganizationActiveMembersQuery,
    FetchOrganizationActiveMembersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchOrganizationActiveMembersQuery,
    FetchOrganizationActiveMembersQueryVariables
  >(FetchOrganizationActiveMembersDocument, options);
}
export function useFetchOrganizationActiveMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchOrganizationActiveMembersQuery,
    FetchOrganizationActiveMembersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchOrganizationActiveMembersQuery,
    FetchOrganizationActiveMembersQueryVariables
  >(FetchOrganizationActiveMembersDocument, options);
}
export type FetchOrganizationActiveMembersQueryHookResult = ReturnType<
  typeof useFetchOrganizationActiveMembersQuery
>;
export type FetchOrganizationActiveMembersLazyQueryHookResult = ReturnType<
  typeof useFetchOrganizationActiveMembersLazyQuery
>;
export type FetchOrganizationActiveMembersQueryResult = Apollo.QueryResult<
  FetchOrganizationActiveMembersQuery,
  FetchOrganizationActiveMembersQueryVariables
>;
export const FetchOrganizationMembersDocument = gql`
  query fetchOrganizationMembers {
    fetchOrganizationMembers {
      latestWorkingHistory {
        calendarEvent {
          eventName
          id
          workingTimeSec
        }
        end
        id
        project {
          id
          projectName
        }
        start
        task {
          completeDateTime
          estimateTimeSec
          id
          priority
          progressRate
          scheduledEndDateTime
          title
          versionNo
          workingMembers {
            id
            lastWorkingStartDateTime
          }
          workingTimeSec
        }
        versionNo
        workingTimeSec
      }
      member {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
    }
  }
`;

/**
 * __useFetchOrganizationMembersQuery__
 *
 * To run a query within a React component, call `useFetchOrganizationMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchOrganizationMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchOrganizationMembersQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchOrganizationMembersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchOrganizationMembersQuery,
    FetchOrganizationMembersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchOrganizationMembersQuery, FetchOrganizationMembersQueryVariables>(
    FetchOrganizationMembersDocument,
    options
  );
}
export function useFetchOrganizationMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchOrganizationMembersQuery,
    FetchOrganizationMembersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchOrganizationMembersQuery, FetchOrganizationMembersQueryVariables>(
    FetchOrganizationMembersDocument,
    options
  );
}
export type FetchOrganizationMembersQueryHookResult = ReturnType<
  typeof useFetchOrganizationMembersQuery
>;
export type FetchOrganizationMembersLazyQueryHookResult = ReturnType<
  typeof useFetchOrganizationMembersLazyQuery
>;
export type FetchOrganizationMembersQueryResult = Apollo.QueryResult<
  FetchOrganizationMembersQuery,
  FetchOrganizationMembersQueryVariables
>;
export const FetchSyncRegisterWorkingHistoriesTargetGoogleCalendarsDocument = gql`
  query fetchSyncRegisterWorkingHistoriesTargetGoogleCalendars(
    $input: RegisterWorkingHistoriesFromGoogleCallendarEventsInputInput!
  ) {
    fetchSyncRegisterWorkingHistoriesTargetGoogleCalendars(input: $input) {
      allDay
      attendees {
        mailAddress
        name
        responseStatus
        self
      }
      backgroundColor
      calendarId
      colorId
      dateWhenAllDay
      description
      endDateTime
      foregroundColor
      id
      location
      organizer {
        mailAddress
        name
      }
      readonly
      startDateTime
      status
      targetMemberId
      title
      visivility
    }
  }
`;

/**
 * __useFetchSyncRegisterWorkingHistoriesTargetGoogleCalendarsQuery__
 *
 * To run a query within a React component, call `useFetchSyncRegisterWorkingHistoriesTargetGoogleCalendarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSyncRegisterWorkingHistoriesTargetGoogleCalendarsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSyncRegisterWorkingHistoriesTargetGoogleCalendarsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchSyncRegisterWorkingHistoriesTargetGoogleCalendarsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchSyncRegisterWorkingHistoriesTargetGoogleCalendarsQuery,
    FetchSyncRegisterWorkingHistoriesTargetGoogleCalendarsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchSyncRegisterWorkingHistoriesTargetGoogleCalendarsQuery,
    FetchSyncRegisterWorkingHistoriesTargetGoogleCalendarsQueryVariables
  >(FetchSyncRegisterWorkingHistoriesTargetGoogleCalendarsDocument, options);
}
export function useFetchSyncRegisterWorkingHistoriesTargetGoogleCalendarsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchSyncRegisterWorkingHistoriesTargetGoogleCalendarsQuery,
    FetchSyncRegisterWorkingHistoriesTargetGoogleCalendarsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchSyncRegisterWorkingHistoriesTargetGoogleCalendarsQuery,
    FetchSyncRegisterWorkingHistoriesTargetGoogleCalendarsQueryVariables
  >(FetchSyncRegisterWorkingHistoriesTargetGoogleCalendarsDocument, options);
}
export type FetchSyncRegisterWorkingHistoriesTargetGoogleCalendarsQueryHookResult = ReturnType<
  typeof useFetchSyncRegisterWorkingHistoriesTargetGoogleCalendarsQuery
>;
export type FetchSyncRegisterWorkingHistoriesTargetGoogleCalendarsLazyQueryHookResult = ReturnType<
  typeof useFetchSyncRegisterWorkingHistoriesTargetGoogleCalendarsLazyQuery
>;
export type FetchSyncRegisterWorkingHistoriesTargetGoogleCalendarsQueryResult = Apollo.QueryResult<
  FetchSyncRegisterWorkingHistoriesTargetGoogleCalendarsQuery,
  FetchSyncRegisterWorkingHistoriesTargetGoogleCalendarsQueryVariables
>;
export const FetchSyncRegisterWorkingSchedulesTargetGoogleCalendarsDocument = gql`
  query fetchSyncRegisterWorkingSchedulesTargetGoogleCalendars(
    $input: RegisterWorkingSchedulesFromGoogleCallendarEventsInputInput!
  ) {
    fetchSyncRegisterWorkingSchedulesTargetGoogleCalendars(input: $input) {
      allDay
      attendees {
        mailAddress
        name
        responseStatus
        self
      }
      backgroundColor
      calendarId
      colorId
      dateWhenAllDay
      description
      endDateTime
      foregroundColor
      id
      location
      organizer {
        mailAddress
        name
      }
      readonly
      startDateTime
      status
      targetMemberId
      title
      visivility
    }
  }
`;

/**
 * __useFetchSyncRegisterWorkingSchedulesTargetGoogleCalendarsQuery__
 *
 * To run a query within a React component, call `useFetchSyncRegisterWorkingSchedulesTargetGoogleCalendarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSyncRegisterWorkingSchedulesTargetGoogleCalendarsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSyncRegisterWorkingSchedulesTargetGoogleCalendarsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchSyncRegisterWorkingSchedulesTargetGoogleCalendarsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchSyncRegisterWorkingSchedulesTargetGoogleCalendarsQuery,
    FetchSyncRegisterWorkingSchedulesTargetGoogleCalendarsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchSyncRegisterWorkingSchedulesTargetGoogleCalendarsQuery,
    FetchSyncRegisterWorkingSchedulesTargetGoogleCalendarsQueryVariables
  >(FetchSyncRegisterWorkingSchedulesTargetGoogleCalendarsDocument, options);
}
export function useFetchSyncRegisterWorkingSchedulesTargetGoogleCalendarsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchSyncRegisterWorkingSchedulesTargetGoogleCalendarsQuery,
    FetchSyncRegisterWorkingSchedulesTargetGoogleCalendarsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchSyncRegisterWorkingSchedulesTargetGoogleCalendarsQuery,
    FetchSyncRegisterWorkingSchedulesTargetGoogleCalendarsQueryVariables
  >(FetchSyncRegisterWorkingSchedulesTargetGoogleCalendarsDocument, options);
}
export type FetchSyncRegisterWorkingSchedulesTargetGoogleCalendarsQueryHookResult = ReturnType<
  typeof useFetchSyncRegisterWorkingSchedulesTargetGoogleCalendarsQuery
>;
export type FetchSyncRegisterWorkingSchedulesTargetGoogleCalendarsLazyQueryHookResult = ReturnType<
  typeof useFetchSyncRegisterWorkingSchedulesTargetGoogleCalendarsLazyQuery
>;
export type FetchSyncRegisterWorkingSchedulesTargetGoogleCalendarsQueryResult = Apollo.QueryResult<
  FetchSyncRegisterWorkingSchedulesTargetGoogleCalendarsQuery,
  FetchSyncRegisterWorkingSchedulesTargetGoogleCalendarsQueryVariables
>;
export const FetchTeamActiveMembersDocument = gql`
  query fetchTeamActiveMembers($teamId: String!) {
    fetchTeamActiveMembers(teamId: $teamId) {
      latestWorkingHistory {
        calendarEvent {
          eventName
          id
          workingTimeSec
        }
        end
        id
        project {
          id
          projectName
        }
        start
        task {
          completeDateTime
          estimateTimeSec
          id
          priority
          progressRate
          scheduledEndDateTime
          title
          versionNo
          workingMembers {
            id
            lastWorkingStartDateTime
          }
          workingTimeSec
        }
        versionNo
        workingTimeSec
      }
      member {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
    }
  }
`;

/**
 * __useFetchTeamActiveMembersQuery__
 *
 * To run a query within a React component, call `useFetchTeamActiveMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTeamActiveMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTeamActiveMembersQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useFetchTeamActiveMembersQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchTeamActiveMembersQuery,
    FetchTeamActiveMembersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchTeamActiveMembersQuery, FetchTeamActiveMembersQueryVariables>(
    FetchTeamActiveMembersDocument,
    options
  );
}
export function useFetchTeamActiveMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchTeamActiveMembersQuery,
    FetchTeamActiveMembersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchTeamActiveMembersQuery, FetchTeamActiveMembersQueryVariables>(
    FetchTeamActiveMembersDocument,
    options
  );
}
export type FetchTeamActiveMembersQueryHookResult = ReturnType<
  typeof useFetchTeamActiveMembersQuery
>;
export type FetchTeamActiveMembersLazyQueryHookResult = ReturnType<
  typeof useFetchTeamActiveMembersLazyQuery
>;
export type FetchTeamActiveMembersQueryResult = Apollo.QueryResult<
  FetchTeamActiveMembersQuery,
  FetchTeamActiveMembersQueryVariables
>;
export const FetchTeamMembersDocument = gql`
  query fetchTeamMembers($teamId: String!) {
    fetchTeamMembers(teamId: $teamId) {
      latestWorkingHistory {
        calendarEvent {
          eventName
          id
          workingTimeSec
        }
        end
        id
        project {
          id
          projectName
        }
        start
        task {
          completeDateTime
          estimateTimeSec
          id
          priority
          progressRate
          scheduledEndDateTime
          title
          versionNo
          workingMembers {
            id
            lastWorkingStartDateTime
          }
          workingTimeSec
        }
        versionNo
        workingTimeSec
      }
      member {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
    }
  }
`;

/**
 * __useFetchTeamMembersQuery__
 *
 * To run a query within a React component, call `useFetchTeamMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTeamMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTeamMembersQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useFetchTeamMembersQuery(
  baseOptions: Apollo.QueryHookOptions<FetchTeamMembersQuery, FetchTeamMembersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchTeamMembersQuery, FetchTeamMembersQueryVariables>(
    FetchTeamMembersDocument,
    options
  );
}
export function useFetchTeamMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchTeamMembersQuery, FetchTeamMembersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchTeamMembersQuery, FetchTeamMembersQueryVariables>(
    FetchTeamMembersDocument,
    options
  );
}
export type FetchTeamMembersQueryHookResult = ReturnType<typeof useFetchTeamMembersQuery>;
export type FetchTeamMembersLazyQueryHookResult = ReturnType<typeof useFetchTeamMembersLazyQuery>;
export type FetchTeamMembersQueryResult = Apollo.QueryResult<
  FetchTeamMembersQuery,
  FetchTeamMembersQueryVariables
>;
export const FirstViewProjectDocument = gql`
  query firstViewProject {
    firstViewProject {
      projectId
      teamId
    }
  }
`;

/**
 * __useFirstViewProjectQuery__
 *
 * To run a query within a React component, call `useFirstViewProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useFirstViewProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFirstViewProjectQuery({
 *   variables: {
 *   },
 * });
 */
export function useFirstViewProjectQuery(
  baseOptions?: Apollo.QueryHookOptions<FirstViewProjectQuery, FirstViewProjectQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FirstViewProjectQuery, FirstViewProjectQueryVariables>(
    FirstViewProjectDocument,
    options
  );
}
export function useFirstViewProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FirstViewProjectQuery, FirstViewProjectQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FirstViewProjectQuery, FirstViewProjectQueryVariables>(
    FirstViewProjectDocument,
    options
  );
}
export type FirstViewProjectQueryHookResult = ReturnType<typeof useFirstViewProjectQuery>;
export type FirstViewProjectLazyQueryHookResult = ReturnType<typeof useFirstViewProjectLazyQuery>;
export type FirstViewProjectQueryResult = Apollo.QueryResult<
  FirstViewProjectQuery,
  FirstViewProjectQueryVariables
>;
export const GoogleCalendarEventDocument = gql`
  query googleCalendarEvent($calendarId: String!, $id: ID!) {
    googleCalendarEvent(calendarId: $calendarId, id: $id) {
      allDay
      attendees {
        mailAddress
        name
        responseStatus
        self
      }
      backgroundColor
      calendarId
      colorId
      dateWhenAllDay
      description
      endDateTime
      foregroundColor
      id
      location
      organizer {
        mailAddress
        name
      }
      readonly
      startDateTime
      status
      targetMemberId
      title
      visivility
    }
  }
`;

/**
 * __useGoogleCalendarEventQuery__
 *
 * To run a query within a React component, call `useGoogleCalendarEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGoogleCalendarEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGoogleCalendarEventQuery({
 *   variables: {
 *      calendarId: // value for 'calendarId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGoogleCalendarEventQuery(
  baseOptions: Apollo.QueryHookOptions<GoogleCalendarEventQuery, GoogleCalendarEventQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GoogleCalendarEventQuery, GoogleCalendarEventQueryVariables>(
    GoogleCalendarEventDocument,
    options
  );
}
export function useGoogleCalendarEventLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GoogleCalendarEventQuery,
    GoogleCalendarEventQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GoogleCalendarEventQuery, GoogleCalendarEventQueryVariables>(
    GoogleCalendarEventDocument,
    options
  );
}
export type GoogleCalendarEventQueryHookResult = ReturnType<typeof useGoogleCalendarEventQuery>;
export type GoogleCalendarEventLazyQueryHookResult = ReturnType<
  typeof useGoogleCalendarEventLazyQuery
>;
export type GoogleCalendarEventQueryResult = Apollo.QueryResult<
  GoogleCalendarEventQuery,
  GoogleCalendarEventQueryVariables
>;
export const GoogleCalendarEventsDocument = gql`
  query googleCalendarEvents($input: GoogleCalendarInputInput) {
    googleCalendarEvents(input: $input) {
      allDay
      attendees {
        mailAddress
        name
        responseStatus
        self
      }
      backgroundColor
      calendarId
      colorId
      dateWhenAllDay
      description
      endDateTime
      foregroundColor
      id
      location
      organizer {
        mailAddress
        name
      }
      readonly
      startDateTime
      status
      targetMemberId
      title
      visivility
    }
  }
`;

/**
 * __useGoogleCalendarEventsQuery__
 *
 * To run a query within a React component, call `useGoogleCalendarEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGoogleCalendarEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGoogleCalendarEventsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGoogleCalendarEventsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GoogleCalendarEventsQuery,
    GoogleCalendarEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GoogleCalendarEventsQuery, GoogleCalendarEventsQueryVariables>(
    GoogleCalendarEventsDocument,
    options
  );
}
export function useGoogleCalendarEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GoogleCalendarEventsQuery,
    GoogleCalendarEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GoogleCalendarEventsQuery, GoogleCalendarEventsQueryVariables>(
    GoogleCalendarEventsDocument,
    options
  );
}
export type GoogleCalendarEventsQueryHookResult = ReturnType<typeof useGoogleCalendarEventsQuery>;
export type GoogleCalendarEventsLazyQueryHookResult = ReturnType<
  typeof useGoogleCalendarEventsLazyQuery
>;
export type GoogleCalendarEventsQueryResult = Apollo.QueryResult<
  GoogleCalendarEventsQuery,
  GoogleCalendarEventsQueryVariables
>;
export const GoogleCalendarsDocument = gql`
  query googleCalendars {
    googleCalendars {
      backgroundColor
      calendarId
      colorId
      foregroundColor
      id
      myCalendar
      name
      primary
      readonly
    }
  }
`;

/**
 * __useGoogleCalendarsQuery__
 *
 * To run a query within a React component, call `useGoogleCalendarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGoogleCalendarsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGoogleCalendarsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGoogleCalendarsQuery(
  baseOptions?: Apollo.QueryHookOptions<GoogleCalendarsQuery, GoogleCalendarsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GoogleCalendarsQuery, GoogleCalendarsQueryVariables>(
    GoogleCalendarsDocument,
    options
  );
}
export function useGoogleCalendarsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GoogleCalendarsQuery, GoogleCalendarsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GoogleCalendarsQuery, GoogleCalendarsQueryVariables>(
    GoogleCalendarsDocument,
    options
  );
}
export type GoogleCalendarsQueryHookResult = ReturnType<typeof useGoogleCalendarsQuery>;
export type GoogleCalendarsLazyQueryHookResult = ReturnType<typeof useGoogleCalendarsLazyQuery>;
export type GoogleCalendarsQueryResult = Apollo.QueryResult<
  GoogleCalendarsQuery,
  GoogleCalendarsQueryVariables
>;
export const GoogleWorkSpaceMembersDocument = gql`
  query googleWorkSpaceMembers {
    googleWorkSpaceMembers {
      calendarPermission
      mailAddress
      name
    }
  }
`;

/**
 * __useGoogleWorkSpaceMembersQuery__
 *
 * To run a query within a React component, call `useGoogleWorkSpaceMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGoogleWorkSpaceMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGoogleWorkSpaceMembersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGoogleWorkSpaceMembersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GoogleWorkSpaceMembersQuery,
    GoogleWorkSpaceMembersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GoogleWorkSpaceMembersQuery, GoogleWorkSpaceMembersQueryVariables>(
    GoogleWorkSpaceMembersDocument,
    options
  );
}
export function useGoogleWorkSpaceMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GoogleWorkSpaceMembersQuery,
    GoogleWorkSpaceMembersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GoogleWorkSpaceMembersQuery, GoogleWorkSpaceMembersQueryVariables>(
    GoogleWorkSpaceMembersDocument,
    options
  );
}
export type GoogleWorkSpaceMembersQueryHookResult = ReturnType<
  typeof useGoogleWorkSpaceMembersQuery
>;
export type GoogleWorkSpaceMembersLazyQueryHookResult = ReturnType<
  typeof useGoogleWorkSpaceMembersLazyQuery
>;
export type GoogleWorkSpaceMembersQueryResult = Apollo.QueryResult<
  GoogleWorkSpaceMembersQuery,
  GoogleWorkSpaceMembersQueryVariables
>;
export const GraphDataClientListDocument = gql`
  query graphDataClientList($input: GraphDataClientListInputInput!, $limit: Int, $offset: Int) {
    graphDataClientList(input: $input, limit: $limit, offset: $offset) {
      clientId
      clientName
      color
      laborCost
      percent
      reportType
      workingTimeSec
    }
  }
`;

/**
 * __useGraphDataClientListQuery__
 *
 * To run a query within a React component, call `useGraphDataClientListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGraphDataClientListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGraphDataClientListQuery({
 *   variables: {
 *      input: // value for 'input'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGraphDataClientListQuery(
  baseOptions: Apollo.QueryHookOptions<GraphDataClientListQuery, GraphDataClientListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GraphDataClientListQuery, GraphDataClientListQueryVariables>(
    GraphDataClientListDocument,
    options
  );
}
export function useGraphDataClientListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GraphDataClientListQuery,
    GraphDataClientListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GraphDataClientListQuery, GraphDataClientListQueryVariables>(
    GraphDataClientListDocument,
    options
  );
}
export type GraphDataClientListQueryHookResult = ReturnType<typeof useGraphDataClientListQuery>;
export type GraphDataClientListLazyQueryHookResult = ReturnType<
  typeof useGraphDataClientListLazyQuery
>;
export type GraphDataClientListQueryResult = Apollo.QueryResult<
  GraphDataClientListQuery,
  GraphDataClientListQueryVariables
>;
export const GraphDataMemberListDocument = gql`
  query graphDataMemberList($input: GraphDataMemberListInputInput!, $limit: Int, $offset: Int) {
    graphDataMemberList(input: $input, limit: $limit, offset: $offset) {
      color
      department
      employeeNumber
      laborCost
      mailAddress
      memberId
      name
      percent
      profileImageUrl
      reportType
      workingTimeSec
    }
  }
`;

/**
 * __useGraphDataMemberListQuery__
 *
 * To run a query within a React component, call `useGraphDataMemberListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGraphDataMemberListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGraphDataMemberListQuery({
 *   variables: {
 *      input: // value for 'input'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGraphDataMemberListQuery(
  baseOptions: Apollo.QueryHookOptions<GraphDataMemberListQuery, GraphDataMemberListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GraphDataMemberListQuery, GraphDataMemberListQueryVariables>(
    GraphDataMemberListDocument,
    options
  );
}
export function useGraphDataMemberListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GraphDataMemberListQuery,
    GraphDataMemberListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GraphDataMemberListQuery, GraphDataMemberListQueryVariables>(
    GraphDataMemberListDocument,
    options
  );
}
export type GraphDataMemberListQueryHookResult = ReturnType<typeof useGraphDataMemberListQuery>;
export type GraphDataMemberListLazyQueryHookResult = ReturnType<
  typeof useGraphDataMemberListLazyQuery
>;
export type GraphDataMemberListQueryResult = Apollo.QueryResult<
  GraphDataMemberListQuery,
  GraphDataMemberListQueryVariables
>;
export const GraphDataProjectListDocument = gql`
  query graphDataProjectList($input: GraphDataProjectListInputInput!, $limit: Int, $offset: Int) {
    graphDataProjectList(input: $input, limit: $limit, offset: $offset) {
      clientId
      clientName
      color
      laborCost
      percent
      projectId
      projectName
      reportType
      teamId
      teamName
      workingTimeSec
    }
  }
`;

/**
 * __useGraphDataProjectListQuery__
 *
 * To run a query within a React component, call `useGraphDataProjectListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGraphDataProjectListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGraphDataProjectListQuery({
 *   variables: {
 *      input: // value for 'input'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGraphDataProjectListQuery(
  baseOptions: Apollo.QueryHookOptions<
    GraphDataProjectListQuery,
    GraphDataProjectListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GraphDataProjectListQuery, GraphDataProjectListQueryVariables>(
    GraphDataProjectListDocument,
    options
  );
}
export function useGraphDataProjectListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GraphDataProjectListQuery,
    GraphDataProjectListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GraphDataProjectListQuery, GraphDataProjectListQueryVariables>(
    GraphDataProjectListDocument,
    options
  );
}
export type GraphDataProjectListQueryHookResult = ReturnType<typeof useGraphDataProjectListQuery>;
export type GraphDataProjectListLazyQueryHookResult = ReturnType<
  typeof useGraphDataProjectListLazyQuery
>;
export type GraphDataProjectListQueryResult = Apollo.QueryResult<
  GraphDataProjectListQuery,
  GraphDataProjectListQueryVariables
>;
export const GraphDataTaskListDocument = gql`
  query graphDataTaskList($input: GraphDataTaskListInputInput!, $limit: Int, $offset: Int) {
    graphDataTaskList(input: $input, limit: $limit, offset: $offset) {
      clientId
      clientName
      color
      itemType
      laborCost
      percent
      projectId
      projectName
      reportType
      tagNames
      taskId
      taskTitle
      teamId
      teamName
      workingMembers {
        mailAddress
        name
        profileImageUrl
      }
      workingTimeSec
    }
  }
`;

/**
 * __useGraphDataTaskListQuery__
 *
 * To run a query within a React component, call `useGraphDataTaskListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGraphDataTaskListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGraphDataTaskListQuery({
 *   variables: {
 *      input: // value for 'input'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGraphDataTaskListQuery(
  baseOptions: Apollo.QueryHookOptions<GraphDataTaskListQuery, GraphDataTaskListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GraphDataTaskListQuery, GraphDataTaskListQueryVariables>(
    GraphDataTaskListDocument,
    options
  );
}
export function useGraphDataTaskListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GraphDataTaskListQuery, GraphDataTaskListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GraphDataTaskListQuery, GraphDataTaskListQueryVariables>(
    GraphDataTaskListDocument,
    options
  );
}
export type GraphDataTaskListQueryHookResult = ReturnType<typeof useGraphDataTaskListQuery>;
export type GraphDataTaskListLazyQueryHookResult = ReturnType<typeof useGraphDataTaskListLazyQuery>;
export type GraphDataTaskListQueryResult = Apollo.QueryResult<
  GraphDataTaskListQuery,
  GraphDataTaskListQueryVariables
>;
export const GraphDataTeamListDocument = gql`
  query graphDataTeamList($input: GraphDataTeamListInputInput!, $limit: Int, $offset: Int) {
    graphDataTeamList(input: $input, limit: $limit, offset: $offset) {
      color
      laborCost
      percent
      reportType
      teamId
      teamName
      workingTimeSec
    }
  }
`;

/**
 * __useGraphDataTeamListQuery__
 *
 * To run a query within a React component, call `useGraphDataTeamListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGraphDataTeamListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGraphDataTeamListQuery({
 *   variables: {
 *      input: // value for 'input'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGraphDataTeamListQuery(
  baseOptions: Apollo.QueryHookOptions<GraphDataTeamListQuery, GraphDataTeamListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GraphDataTeamListQuery, GraphDataTeamListQueryVariables>(
    GraphDataTeamListDocument,
    options
  );
}
export function useGraphDataTeamListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GraphDataTeamListQuery, GraphDataTeamListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GraphDataTeamListQuery, GraphDataTeamListQueryVariables>(
    GraphDataTeamListDocument,
    options
  );
}
export type GraphDataTeamListQueryHookResult = ReturnType<typeof useGraphDataTeamListQuery>;
export type GraphDataTeamListLazyQueryHookResult = ReturnType<typeof useGraphDataTeamListLazyQuery>;
export type GraphDataTeamListQueryResult = Apollo.QueryResult<
  GraphDataTeamListQuery,
  GraphDataTeamListQueryVariables
>;
export const GraphSearchTargetDocument = gql`
  query graphSearchTarget($input: SearchGraphTargetInputInput!) {
    graphSearchTarget(input: $input) {
      clients {
        id
        name
      }
      members {
        id
        name
      }
      projects {
        id
        name
      }
      teams {
        id
        name
      }
    }
  }
`;

/**
 * __useGraphSearchTargetQuery__
 *
 * To run a query within a React component, call `useGraphSearchTargetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGraphSearchTargetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGraphSearchTargetQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGraphSearchTargetQuery(
  baseOptions: Apollo.QueryHookOptions<GraphSearchTargetQuery, GraphSearchTargetQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GraphSearchTargetQuery, GraphSearchTargetQueryVariables>(
    GraphSearchTargetDocument,
    options
  );
}
export function useGraphSearchTargetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GraphSearchTargetQuery, GraphSearchTargetQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GraphSearchTargetQuery, GraphSearchTargetQueryVariables>(
    GraphSearchTargetDocument,
    options
  );
}
export type GraphSearchTargetQueryHookResult = ReturnType<typeof useGraphSearchTargetQuery>;
export type GraphSearchTargetLazyQueryHookResult = ReturnType<typeof useGraphSearchTargetLazyQuery>;
export type GraphSearchTargetQueryResult = Apollo.QueryResult<
  GraphSearchTargetQuery,
  GraphSearchTargetQueryVariables
>;
export const GraphTotalWorkingTimeSecDocument = gql`
  query graphTotalWorkingTimeSec($input: SearchGraphTargetInputInput!) {
    graphTotalWorkingTimeSec(input: $input)
  }
`;

/**
 * __useGraphTotalWorkingTimeSecQuery__
 *
 * To run a query within a React component, call `useGraphTotalWorkingTimeSecQuery` and pass it any options that fit your needs.
 * When your component renders, `useGraphTotalWorkingTimeSecQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGraphTotalWorkingTimeSecQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGraphTotalWorkingTimeSecQuery(
  baseOptions: Apollo.QueryHookOptions<
    GraphTotalWorkingTimeSecQuery,
    GraphTotalWorkingTimeSecQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GraphTotalWorkingTimeSecQuery, GraphTotalWorkingTimeSecQueryVariables>(
    GraphTotalWorkingTimeSecDocument,
    options
  );
}
export function useGraphTotalWorkingTimeSecLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GraphTotalWorkingTimeSecQuery,
    GraphTotalWorkingTimeSecQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GraphTotalWorkingTimeSecQuery, GraphTotalWorkingTimeSecQueryVariables>(
    GraphTotalWorkingTimeSecDocument,
    options
  );
}
export type GraphTotalWorkingTimeSecQueryHookResult = ReturnType<
  typeof useGraphTotalWorkingTimeSecQuery
>;
export type GraphTotalWorkingTimeSecLazyQueryHookResult = ReturnType<
  typeof useGraphTotalWorkingTimeSecLazyQuery
>;
export type GraphTotalWorkingTimeSecQueryResult = Apollo.QueryResult<
  GraphTotalWorkingTimeSecQuery,
  GraphTotalWorkingTimeSecQueryVariables
>;
export const HasDisableAclPermissionDocument = gql`
  query hasDisableAclPermission($token: String!) {
    hasDisableAclPermission(token: $token)
  }
`;

/**
 * __useHasDisableAclPermissionQuery__
 *
 * To run a query within a React component, call `useHasDisableAclPermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasDisableAclPermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasDisableAclPermissionQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useHasDisableAclPermissionQuery(
  baseOptions: Apollo.QueryHookOptions<
    HasDisableAclPermissionQuery,
    HasDisableAclPermissionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HasDisableAclPermissionQuery, HasDisableAclPermissionQueryVariables>(
    HasDisableAclPermissionDocument,
    options
  );
}
export function useHasDisableAclPermissionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HasDisableAclPermissionQuery,
    HasDisableAclPermissionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HasDisableAclPermissionQuery, HasDisableAclPermissionQueryVariables>(
    HasDisableAclPermissionDocument,
    options
  );
}
export type HasDisableAclPermissionQueryHookResult = ReturnType<
  typeof useHasDisableAclPermissionQuery
>;
export type HasDisableAclPermissionLazyQueryHookResult = ReturnType<
  typeof useHasDisableAclPermissionLazyQuery
>;
export type HasDisableAclPermissionQueryResult = Apollo.QueryResult<
  HasDisableAclPermissionQuery,
  HasDisableAclPermissionQueryVariables
>;
export const ICalExportDocument = gql`
  query iCalExport($id: ID) {
    iCalExport(id: $id) {
      end
      id
      start
      status
      type
    }
  }
`;

/**
 * __useICalExportQuery__
 *
 * To run a query within a React component, call `useICalExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useICalExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useICalExportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useICalExportQuery(
  baseOptions?: Apollo.QueryHookOptions<ICalExportQuery, ICalExportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ICalExportQuery, ICalExportQueryVariables>(ICalExportDocument, options);
}
export function useICalExportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ICalExportQuery, ICalExportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ICalExportQuery, ICalExportQueryVariables>(
    ICalExportDocument,
    options
  );
}
export type ICalExportQueryHookResult = ReturnType<typeof useICalExportQuery>;
export type ICalExportLazyQueryHookResult = ReturnType<typeof useICalExportLazyQuery>;
export type ICalExportQueryResult = Apollo.QueryResult<ICalExportQuery, ICalExportQueryVariables>;
export const ICalKeyDocument = gql`
  query iCalKey {
    iCalKey {
      timeEntriesUrl
      workSchedulesUrl
    }
  }
`;

/**
 * __useICalKeyQuery__
 *
 * To run a query within a React component, call `useICalKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useICalKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useICalKeyQuery({
 *   variables: {
 *   },
 * });
 */
export function useICalKeyQuery(
  baseOptions?: Apollo.QueryHookOptions<ICalKeyQuery, ICalKeyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ICalKeyQuery, ICalKeyQueryVariables>(ICalKeyDocument, options);
}
export function useICalKeyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ICalKeyQuery, ICalKeyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ICalKeyQuery, ICalKeyQueryVariables>(ICalKeyDocument, options);
}
export type ICalKeyQueryHookResult = ReturnType<typeof useICalKeyQuery>;
export type ICalKeyLazyQueryHookResult = ReturnType<typeof useICalKeyLazyQuery>;
export type ICalKeyQueryResult = Apollo.QueryResult<ICalKeyQuery, ICalKeyQueryVariables>;
export const InvoicesDocument = gql`
  query invoices {
    invoices {
      billingDateTime
      billingNumber
      discount
      invoicePdfUrl
      paid
      periodEndDateTime
      periodStartDateTime
      quantity
      receiptUrl
      subtotal
      tax
      title
      total
      unitPrice
    }
  }
`;

/**
 * __useInvoicesQuery__
 *
 * To run a query within a React component, call `useInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useInvoicesQuery(
  baseOptions?: Apollo.QueryHookOptions<InvoicesQuery, InvoicesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvoicesQuery, InvoicesQueryVariables>(InvoicesDocument, options);
}
export function useInvoicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InvoicesQuery, InvoicesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InvoicesQuery, InvoicesQueryVariables>(InvoicesDocument, options);
}
export type InvoicesQueryHookResult = ReturnType<typeof useInvoicesQuery>;
export type InvoicesLazyQueryHookResult = ReturnType<typeof useInvoicesLazyQuery>;
export type InvoicesQueryResult = Apollo.QueryResult<InvoicesQuery, InvoicesQueryVariables>;
export const IsExistLoginPasswordDocument = gql`
  query isExistLoginPassword {
    isExistLoginPassword
  }
`;

/**
 * __useIsExistLoginPasswordQuery__
 *
 * To run a query within a React component, call `useIsExistLoginPasswordQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsExistLoginPasswordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsExistLoginPasswordQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsExistLoginPasswordQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IsExistLoginPasswordQuery,
    IsExistLoginPasswordQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IsExistLoginPasswordQuery, IsExistLoginPasswordQueryVariables>(
    IsExistLoginPasswordDocument,
    options
  );
}
export function useIsExistLoginPasswordLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IsExistLoginPasswordQuery,
    IsExistLoginPasswordQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IsExistLoginPasswordQuery, IsExistLoginPasswordQueryVariables>(
    IsExistLoginPasswordDocument,
    options
  );
}
export type IsExistLoginPasswordQueryHookResult = ReturnType<typeof useIsExistLoginPasswordQuery>;
export type IsExistLoginPasswordLazyQueryHookResult = ReturnType<
  typeof useIsExistLoginPasswordLazyQuery
>;
export type IsExistLoginPasswordQueryResult = Apollo.QueryResult<
  IsExistLoginPasswordQuery,
  IsExistLoginPasswordQueryVariables
>;
export const IsExistsDuplicateTermProjectContractDocument = gql`
  query isExistsDuplicateTermProjectContract($input: CheckDuplicateTermProjectContractInputInput!) {
    isExistsDuplicateTermProjectContract(input: $input)
  }
`;

/**
 * __useIsExistsDuplicateTermProjectContractQuery__
 *
 * To run a query within a React component, call `useIsExistsDuplicateTermProjectContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsExistsDuplicateTermProjectContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsExistsDuplicateTermProjectContractQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIsExistsDuplicateTermProjectContractQuery(
  baseOptions: Apollo.QueryHookOptions<
    IsExistsDuplicateTermProjectContractQuery,
    IsExistsDuplicateTermProjectContractQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    IsExistsDuplicateTermProjectContractQuery,
    IsExistsDuplicateTermProjectContractQueryVariables
  >(IsExistsDuplicateTermProjectContractDocument, options);
}
export function useIsExistsDuplicateTermProjectContractLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IsExistsDuplicateTermProjectContractQuery,
    IsExistsDuplicateTermProjectContractQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    IsExistsDuplicateTermProjectContractQuery,
    IsExistsDuplicateTermProjectContractQueryVariables
  >(IsExistsDuplicateTermProjectContractDocument, options);
}
export type IsExistsDuplicateTermProjectContractQueryHookResult = ReturnType<
  typeof useIsExistsDuplicateTermProjectContractQuery
>;
export type IsExistsDuplicateTermProjectContractLazyQueryHookResult = ReturnType<
  typeof useIsExistsDuplicateTermProjectContractLazyQuery
>;
export type IsExistsDuplicateTermProjectContractQueryResult = Apollo.QueryResult<
  IsExistsDuplicateTermProjectContractQuery,
  IsExistsDuplicateTermProjectContractQueryVariables
>;
export const IsIpAddressAccessAllowedDocument = gql`
  query isIpAddressAccessAllowed {
    isIpAddressAccessAllowed
  }
`;

/**
 * __useIsIpAddressAccessAllowedQuery__
 *
 * To run a query within a React component, call `useIsIpAddressAccessAllowedQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsIpAddressAccessAllowedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsIpAddressAccessAllowedQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsIpAddressAccessAllowedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IsIpAddressAccessAllowedQuery,
    IsIpAddressAccessAllowedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IsIpAddressAccessAllowedQuery, IsIpAddressAccessAllowedQueryVariables>(
    IsIpAddressAccessAllowedDocument,
    options
  );
}
export function useIsIpAddressAccessAllowedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IsIpAddressAccessAllowedQuery,
    IsIpAddressAccessAllowedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IsIpAddressAccessAllowedQuery, IsIpAddressAccessAllowedQueryVariables>(
    IsIpAddressAccessAllowedDocument,
    options
  );
}
export type IsIpAddressAccessAllowedQueryHookResult = ReturnType<
  typeof useIsIpAddressAccessAllowedQuery
>;
export type IsIpAddressAccessAllowedLazyQueryHookResult = ReturnType<
  typeof useIsIpAddressAccessAllowedLazyQuery
>;
export type IsIpAddressAccessAllowedQueryResult = Apollo.QueryResult<
  IsIpAddressAccessAllowedQuery,
  IsIpAddressAccessAllowedQueryVariables
>;
export const IsIpAddressAccessAllowedWithAllowdIpAddressListDocument = gql`
  query isIpAddressAccessAllowedWithAllowdIpAddressList($input: IpAddressListInputInput!) {
    isIpAddressAccessAllowedWithAllowdIpAddressList(input: $input)
  }
`;

/**
 * __useIsIpAddressAccessAllowedWithAllowdIpAddressListQuery__
 *
 * To run a query within a React component, call `useIsIpAddressAccessAllowedWithAllowdIpAddressListQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsIpAddressAccessAllowedWithAllowdIpAddressListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsIpAddressAccessAllowedWithAllowdIpAddressListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIsIpAddressAccessAllowedWithAllowdIpAddressListQuery(
  baseOptions: Apollo.QueryHookOptions<
    IsIpAddressAccessAllowedWithAllowdIpAddressListQuery,
    IsIpAddressAccessAllowedWithAllowdIpAddressListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    IsIpAddressAccessAllowedWithAllowdIpAddressListQuery,
    IsIpAddressAccessAllowedWithAllowdIpAddressListQueryVariables
  >(IsIpAddressAccessAllowedWithAllowdIpAddressListDocument, options);
}
export function useIsIpAddressAccessAllowedWithAllowdIpAddressListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IsIpAddressAccessAllowedWithAllowdIpAddressListQuery,
    IsIpAddressAccessAllowedWithAllowdIpAddressListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    IsIpAddressAccessAllowedWithAllowdIpAddressListQuery,
    IsIpAddressAccessAllowedWithAllowdIpAddressListQueryVariables
  >(IsIpAddressAccessAllowedWithAllowdIpAddressListDocument, options);
}
export type IsIpAddressAccessAllowedWithAllowdIpAddressListQueryHookResult = ReturnType<
  typeof useIsIpAddressAccessAllowedWithAllowdIpAddressListQuery
>;
export type IsIpAddressAccessAllowedWithAllowdIpAddressListLazyQueryHookResult = ReturnType<
  typeof useIsIpAddressAccessAllowedWithAllowdIpAddressListLazyQuery
>;
export type IsIpAddressAccessAllowedWithAllowdIpAddressListQueryResult = Apollo.QueryResult<
  IsIpAddressAccessAllowedWithAllowdIpAddressListQuery,
  IsIpAddressAccessAllowedWithAllowdIpAddressListQueryVariables
>;
export const JoinedTeamsDocument = gql`
  query joinedTeams($organizationId: String!) {
    joinedTeams(organizationId: $organizationId) {
      archived
      id
      name
      organization {
        allowConnectThirdPartyCalendar
        contactMailAddress
        contractPreriod
        id
        imageUrl
        ipRestrictionFlg
        name
        plan {
          code
          contractPreriod
          id
        }
        requiredTwoFactorAuth
        suspensionFlg
        versionNo
      }
      personalTeam
      status
      versionNo
    }
  }
`;

/**
 * __useJoinedTeamsQuery__
 *
 * To run a query within a React component, call `useJoinedTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJoinedTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJoinedTeamsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useJoinedTeamsQuery(
  baseOptions: Apollo.QueryHookOptions<JoinedTeamsQuery, JoinedTeamsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<JoinedTeamsQuery, JoinedTeamsQueryVariables>(JoinedTeamsDocument, options);
}
export function useJoinedTeamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<JoinedTeamsQuery, JoinedTeamsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<JoinedTeamsQuery, JoinedTeamsQueryVariables>(
    JoinedTeamsDocument,
    options
  );
}
export type JoinedTeamsQueryHookResult = ReturnType<typeof useJoinedTeamsQuery>;
export type JoinedTeamsLazyQueryHookResult = ReturnType<typeof useJoinedTeamsLazyQuery>;
export type JoinedTeamsQueryResult = Apollo.QueryResult<
  JoinedTeamsQuery,
  JoinedTeamsQueryVariables
>;
export const JoinedTeamsWithoutPersonalTeamDocument = gql`
  query joinedTeamsWithoutPersonalTeam($organizationId: String!, $withArchivedTeam: Boolean!) {
    joinedTeamsWithoutPersonalTeam(
      organizationId: $organizationId
      withArchivedTeam: $withArchivedTeam
    ) {
      archived
      id
      name
      organization {
        allowConnectThirdPartyCalendar
        contactMailAddress
        contractPreriod
        id
        imageUrl
        ipRestrictionFlg
        name
        plan {
          code
          contractPreriod
          id
        }
        requiredTwoFactorAuth
        suspensionFlg
        versionNo
      }
      personalTeam
      status
      versionNo
    }
  }
`;

/**
 * __useJoinedTeamsWithoutPersonalTeamQuery__
 *
 * To run a query within a React component, call `useJoinedTeamsWithoutPersonalTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useJoinedTeamsWithoutPersonalTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJoinedTeamsWithoutPersonalTeamQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      withArchivedTeam: // value for 'withArchivedTeam'
 *   },
 * });
 */
export function useJoinedTeamsWithoutPersonalTeamQuery(
  baseOptions: Apollo.QueryHookOptions<
    JoinedTeamsWithoutPersonalTeamQuery,
    JoinedTeamsWithoutPersonalTeamQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    JoinedTeamsWithoutPersonalTeamQuery,
    JoinedTeamsWithoutPersonalTeamQueryVariables
  >(JoinedTeamsWithoutPersonalTeamDocument, options);
}
export function useJoinedTeamsWithoutPersonalTeamLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JoinedTeamsWithoutPersonalTeamQuery,
    JoinedTeamsWithoutPersonalTeamQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    JoinedTeamsWithoutPersonalTeamQuery,
    JoinedTeamsWithoutPersonalTeamQueryVariables
  >(JoinedTeamsWithoutPersonalTeamDocument, options);
}
export type JoinedTeamsWithoutPersonalTeamQueryHookResult = ReturnType<
  typeof useJoinedTeamsWithoutPersonalTeamQuery
>;
export type JoinedTeamsWithoutPersonalTeamLazyQueryHookResult = ReturnType<
  typeof useJoinedTeamsWithoutPersonalTeamLazyQuery
>;
export type JoinedTeamsWithoutPersonalTeamQueryResult = Apollo.QueryResult<
  JoinedTeamsWithoutPersonalTeamQuery,
  JoinedTeamsWithoutPersonalTeamQueryVariables
>;
export const LaborCostDocument = gql`
  query laborCost($id: String) {
    laborCost(id: $id) {
      hourlyWage
      id
      startDateTime
      versionNo
    }
  }
`;

/**
 * __useLaborCostQuery__
 *
 * To run a query within a React component, call `useLaborCostQuery` and pass it any options that fit your needs.
 * When your component renders, `useLaborCostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLaborCostQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLaborCostQuery(
  baseOptions?: Apollo.QueryHookOptions<LaborCostQuery, LaborCostQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LaborCostQuery, LaborCostQueryVariables>(LaborCostDocument, options);
}
export function useLaborCostLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LaborCostQuery, LaborCostQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LaborCostQuery, LaborCostQueryVariables>(LaborCostDocument, options);
}
export type LaborCostQueryHookResult = ReturnType<typeof useLaborCostQuery>;
export type LaborCostLazyQueryHookResult = ReturnType<typeof useLaborCostLazyQuery>;
export type LaborCostQueryResult = Apollo.QueryResult<LaborCostQuery, LaborCostQueryVariables>;
export const LaborCostsDocument = gql`
  query laborCosts($memberId: String!) {
    laborCosts(memberId: $memberId) {
      hourlyWage
      id
      startDateTime
      versionNo
    }
  }
`;

/**
 * __useLaborCostsQuery__
 *
 * To run a query within a React component, call `useLaborCostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLaborCostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLaborCostsQuery({
 *   variables: {
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useLaborCostsQuery(
  baseOptions: Apollo.QueryHookOptions<LaborCostsQuery, LaborCostsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LaborCostsQuery, LaborCostsQueryVariables>(LaborCostsDocument, options);
}
export function useLaborCostsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LaborCostsQuery, LaborCostsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LaborCostsQuery, LaborCostsQueryVariables>(
    LaborCostsDocument,
    options
  );
}
export type LaborCostsQueryHookResult = ReturnType<typeof useLaborCostsQuery>;
export type LaborCostsLazyQueryHookResult = ReturnType<typeof useLaborCostsLazyQuery>;
export type LaborCostsQueryResult = Apollo.QueryResult<LaborCostsQuery, LaborCostsQueryVariables>;
export const LatestWorkingHistoryDocument = gql`
  query latestWorkingHistory {
    latestWorkingHistory {
      calendarEvent {
        calendarType
        clientId
        clientName
        endDateTime
        eventName
        id
        laborCost
        personalTeam
        projectColor
        projectId
        projectName
        startDateTime
        taskId
        taskTitle
        teamId
        teamName
        thirdPartyCalendarEventId
        thirdPartyCalendarInfoList {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          thirdPartyCalendarId
        }
        versionNo
        workingTimeSec
      }
      closingProcessInfo {
        closed
        targetDateTime
      }
      end
      id
      memo
      start
      task {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        asssignee {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        calendarLinkageCode
        completeDateTime
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        estimatedRemainingTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        laborCost
        priority
        progressDeviationRate
        progressRate
        project {
          assignees {
            sortNo
          }
          calendarLinkageCode
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          estimateTimeSec
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            sortNo
          }
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          projectStatus {
            endStatus
            id
            name
            sortNo
            teamId
            versionNo
          }
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          team {
            archived
            id
            name
            organization {
              allowConnectThirdPartyCalendar
              contactMailAddress
              contractPreriod
              id
              imageUrl
              ipRestrictionFlg
              name
              plan {
                code
                contractPreriod
                id
              }
              requiredTwoFactorAuth
              suspensionFlg
              versionNo
            }
            personalTeam
            status
            versionNo
          }
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        registerDateTime
        remainingTimeSec
        scheduleWorkingTimeSec
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInMyTask
        sortNoInTaskStatus
        taskStatus {
          endStatus
          id
          name
          project {
            calendarLinkageCode
            color
            complete
            completeDateTime
            costBudgetWithoutTax
            description
            estimateTimeSec
            id
            key
            laborCost
            laborCostWithoutTax
            laborShare
            name
            priority
            registerDateTime
            salesAmountWithoutTax
            scheduledEndDateTime
            scheduledStartDateTime
            sortNoInGanttChart
            sortNoInList
            sortNoInProjectStatus
            totalScheduleTimeSec
            versionNo
            workingTimeSec
          }
          sortNo
          versionNo
        }
        title
        versionNo
        workingMembers {
          lastWorkingStartDateTime
          lastWorkingStopDateTime
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
        }
        workingTimeSec
      }
      versionNo
      workingMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      workingTimeSec
    }
  }
`;

/**
 * __useLatestWorkingHistoryQuery__
 *
 * To run a query within a React component, call `useLatestWorkingHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestWorkingHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestWorkingHistoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useLatestWorkingHistoryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LatestWorkingHistoryQuery,
    LatestWorkingHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LatestWorkingHistoryQuery, LatestWorkingHistoryQueryVariables>(
    LatestWorkingHistoryDocument,
    options
  );
}
export function useLatestWorkingHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LatestWorkingHistoryQuery,
    LatestWorkingHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LatestWorkingHistoryQuery, LatestWorkingHistoryQueryVariables>(
    LatestWorkingHistoryDocument,
    options
  );
}
export type LatestWorkingHistoryQueryHookResult = ReturnType<typeof useLatestWorkingHistoryQuery>;
export type LatestWorkingHistoryLazyQueryHookResult = ReturnType<
  typeof useLatestWorkingHistoryLazyQuery
>;
export type LatestWorkingHistoryQueryResult = Apollo.QueryResult<
  LatestWorkingHistoryQuery,
  LatestWorkingHistoryQueryVariables
>;
export const LatestWorkingTaskDocument = gql`
  query latestWorkingTask {
    latestWorkingTask {
      assignees {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      asssignee {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      calendarLinkageCode
      completeDateTime
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      estimatedRemainingTimeSec
      favoriteMembers {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      favoriteMembersInfo {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      id
      laborCost
      priority
      progressDeviationRate
      progressRate
      project {
        assignees {
          sortNo
        }
        calendarLinkageCode
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        color
        complete
        completeDateTime
        costBudgetWithoutTax
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          sortNo
        }
        id
        key
        laborCost
        laborCostWithoutTax
        laborShare
        name
        priority
        projectStatus {
          endStatus
          id
          name
          sortNo
          teamId
          versionNo
        }
        registerDateTime
        salesAmountWithoutTax
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInProjectStatus
        team {
          archived
          id
          name
          organization {
            allowConnectThirdPartyCalendar
            contactMailAddress
            contractPreriod
            id
            imageUrl
            ipRestrictionFlg
            name
            plan {
              code
              contractPreriod
              id
            }
            requiredTwoFactorAuth
            suspensionFlg
            versionNo
          }
          personalTeam
          status
          versionNo
        }
        totalScheduleTimeSec
        versionNo
        workingTimeSec
      }
      registerDateTime
      remainingTimeSec
      scheduleWorkingTimeSec
      scheduledEndDateTime
      scheduledStartDateTime
      sortNoInGanttChart
      sortNoInList
      sortNoInMyTask
      sortNoInTaskStatus
      taskStatus {
        endStatus
        id
        name
        project {
          calendarLinkageCode
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          description
          estimateTimeSec
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        sortNo
        versionNo
      }
      title
      versionNo
      workingMembers {
        lastWorkingStartDateTime
        lastWorkingStopDateTime
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
      }
      workingTimeSec
    }
  }
`;

/**
 * __useLatestWorkingTaskQuery__
 *
 * To run a query within a React component, call `useLatestWorkingTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestWorkingTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestWorkingTaskQuery({
 *   variables: {
 *   },
 * });
 */
export function useLatestWorkingTaskQuery(
  baseOptions?: Apollo.QueryHookOptions<LatestWorkingTaskQuery, LatestWorkingTaskQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LatestWorkingTaskQuery, LatestWorkingTaskQueryVariables>(
    LatestWorkingTaskDocument,
    options
  );
}
export function useLatestWorkingTaskLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LatestWorkingTaskQuery, LatestWorkingTaskQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LatestWorkingTaskQuery, LatestWorkingTaskQueryVariables>(
    LatestWorkingTaskDocument,
    options
  );
}
export type LatestWorkingTaskQueryHookResult = ReturnType<typeof useLatestWorkingTaskQuery>;
export type LatestWorkingTaskLazyQueryHookResult = ReturnType<typeof useLatestWorkingTaskLazyQuery>;
export type LatestWorkingTaskQueryResult = Apollo.QueryResult<
  LatestWorkingTaskQuery,
  LatestWorkingTaskQueryVariables
>;
export const MailAddressAvailableDocument = gql`
  query mailAddressAvailable($mailAddress: String!) {
    mailAddressAvailable(mailAddress: $mailAddress)
  }
`;

/**
 * __useMailAddressAvailableQuery__
 *
 * To run a query within a React component, call `useMailAddressAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useMailAddressAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMailAddressAvailableQuery({
 *   variables: {
 *      mailAddress: // value for 'mailAddress'
 *   },
 * });
 */
export function useMailAddressAvailableQuery(
  baseOptions: Apollo.QueryHookOptions<
    MailAddressAvailableQuery,
    MailAddressAvailableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MailAddressAvailableQuery, MailAddressAvailableQueryVariables>(
    MailAddressAvailableDocument,
    options
  );
}
export function useMailAddressAvailableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MailAddressAvailableQuery,
    MailAddressAvailableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MailAddressAvailableQuery, MailAddressAvailableQueryVariables>(
    MailAddressAvailableDocument,
    options
  );
}
export type MailAddressAvailableQueryHookResult = ReturnType<typeof useMailAddressAvailableQuery>;
export type MailAddressAvailableLazyQueryHookResult = ReturnType<
  typeof useMailAddressAvailableLazyQuery
>;
export type MailAddressAvailableQueryResult = Apollo.QueryResult<
  MailAddressAvailableQuery,
  MailAddressAvailableQueryVariables
>;
export const MeDocument = gql`
  query me {
    me {
      adminRole
      clientManagementRole
      department
      employeeNumber
      id
      laborCostManagementRole
      mailAddress
      memberManagementRole
      memberRegistrationDateTime
      memberStatus
      name
      organizationMemberViewRole
      organizationReportRole
      profileImageUrl
      projectCreatePermissionFlg
      projectCustomAttributePermissionFlg
      projectDeletePermissionFlg
      projectUpdatePermissionFlg
      proxyEditWorkingDataRole
      salesManagementRole
      statusMessage
      taskCreatePermissionFlg
      taskCustomAttributePermissionFlg
      taskDeletePermissionFlg
      taskUpdatePermissionFlg
      teamMemberViewRole
      teamReportRole
      versionNo
    }
  }
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const MemberDocument = gql`
  query member($memberId: String!) {
    member(memberId: $memberId) {
      adminRole
      clientManagementRole
      department
      employeeNumber
      id
      laborCostManagementRole
      mailAddress
      memberManagementRole
      memberRegistrationDateTime
      memberStatus
      name
      organizationMemberViewRole
      organizationReportRole
      profileImageUrl
      projectCreatePermissionFlg
      projectCustomAttributePermissionFlg
      projectDeletePermissionFlg
      projectUpdatePermissionFlg
      proxyEditWorkingDataRole
      salesManagementRole
      statusMessage
      taskCreatePermissionFlg
      taskCustomAttributePermissionFlg
      taskDeletePermissionFlg
      taskUpdatePermissionFlg
      teamMemberViewRole
      teamReportRole
      versionNo
    }
  }
`;

/**
 * __useMemberQuery__
 *
 * To run a query within a React component, call `useMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberQuery({
 *   variables: {
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useMemberQuery(
  baseOptions: Apollo.QueryHookOptions<MemberQuery, MemberQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MemberQuery, MemberQueryVariables>(MemberDocument, options);
}
export function useMemberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MemberQuery, MemberQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MemberQuery, MemberQueryVariables>(MemberDocument, options);
}
export type MemberQueryHookResult = ReturnType<typeof useMemberQuery>;
export type MemberLazyQueryHookResult = ReturnType<typeof useMemberLazyQuery>;
export type MemberQueryResult = Apollo.QueryResult<MemberQuery, MemberQueryVariables>;
export const MemberBarGraphDataDocument = gql`
  query memberBarGraphData($input: MemberGraphDataInputInput!) {
    memberBarGraphData(input: $input) {
      color
      department
      employeeNumber
      laborCost
      mailAddress
      memberId
      name
      percent
      profileImageUrl
      reportType
      workingTimeSec
    }
  }
`;

/**
 * __useMemberBarGraphDataQuery__
 *
 * To run a query within a React component, call `useMemberBarGraphDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberBarGraphDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberBarGraphDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMemberBarGraphDataQuery(
  baseOptions: Apollo.QueryHookOptions<MemberBarGraphDataQuery, MemberBarGraphDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MemberBarGraphDataQuery, MemberBarGraphDataQueryVariables>(
    MemberBarGraphDataDocument,
    options
  );
}
export function useMemberBarGraphDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MemberBarGraphDataQuery,
    MemberBarGraphDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MemberBarGraphDataQuery, MemberBarGraphDataQueryVariables>(
    MemberBarGraphDataDocument,
    options
  );
}
export type MemberBarGraphDataQueryHookResult = ReturnType<typeof useMemberBarGraphDataQuery>;
export type MemberBarGraphDataLazyQueryHookResult = ReturnType<
  typeof useMemberBarGraphDataLazyQuery
>;
export type MemberBarGraphDataQueryResult = Apollo.QueryResult<
  MemberBarGraphDataQuery,
  MemberBarGraphDataQueryVariables
>;
export const MemberDateGraphDataDocument = gql`
  query memberDateGraphData($input: MemberGraphDataInputInput!) {
    memberDateGraphData(input: $input) {
      date
      items {
        color
        department
        employeeNumber
        laborCost
        mailAddress
        memberId
        name
        percent
        profileImageUrl
        workingTimeSec
      }
      reportType
    }
  }
`;

/**
 * __useMemberDateGraphDataQuery__
 *
 * To run a query within a React component, call `useMemberDateGraphDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberDateGraphDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberDateGraphDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMemberDateGraphDataQuery(
  baseOptions: Apollo.QueryHookOptions<MemberDateGraphDataQuery, MemberDateGraphDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MemberDateGraphDataQuery, MemberDateGraphDataQueryVariables>(
    MemberDateGraphDataDocument,
    options
  );
}
export function useMemberDateGraphDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MemberDateGraphDataQuery,
    MemberDateGraphDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MemberDateGraphDataQuery, MemberDateGraphDataQueryVariables>(
    MemberDateGraphDataDocument,
    options
  );
}
export type MemberDateGraphDataQueryHookResult = ReturnType<typeof useMemberDateGraphDataQuery>;
export type MemberDateGraphDataLazyQueryHookResult = ReturnType<
  typeof useMemberDateGraphDataLazyQuery
>;
export type MemberDateGraphDataQueryResult = Apollo.QueryResult<
  MemberDateGraphDataQuery,
  MemberDateGraphDataQueryVariables
>;
export const MemberInvitationDocument = gql`
  query memberInvitation($key: String!) {
    memberInvitation(key: $key) {
      adminRole
      allowThirdPartyCalendarConnect
      clientManagementRole
      createMemberId
      department
      employeeNumber
      id
      invitationKey
      laborCostManagementRole
      mailAddress
      memberManagementRole
      name
      organizationId
      organizationMemberViewRole
      organizationName
      organizationReportRole
      projectCreatePermissionFlg
      projectCustomAttributePermissionFlg
      projectDeletePermissionFlg
      projectUpdatePermissionFlg
      proxyEditWorkingDataRole
      salesManagementRole
      taskCreatePermissionFlg
      taskCustomAttributePermissionFlg
      taskDeletePermissionFlg
      taskUpdatePermissionFlg
      teamMemberViewRole
      teamReportRole
      versionNo
    }
  }
`;

/**
 * __useMemberInvitationQuery__
 *
 * To run a query within a React component, call `useMemberInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberInvitationQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useMemberInvitationQuery(
  baseOptions: Apollo.QueryHookOptions<MemberInvitationQuery, MemberInvitationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MemberInvitationQuery, MemberInvitationQueryVariables>(
    MemberInvitationDocument,
    options
  );
}
export function useMemberInvitationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MemberInvitationQuery, MemberInvitationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MemberInvitationQuery, MemberInvitationQueryVariables>(
    MemberInvitationDocument,
    options
  );
}
export type MemberInvitationQueryHookResult = ReturnType<typeof useMemberInvitationQuery>;
export type MemberInvitationLazyQueryHookResult = ReturnType<typeof useMemberInvitationLazyQuery>;
export type MemberInvitationQueryResult = Apollo.QueryResult<
  MemberInvitationQuery,
  MemberInvitationQueryVariables
>;
export const MemberInvitationLinkDocument = gql`
  query memberInvitationLink($key: String!) {
    memberInvitationLink(key: $key) {
      allowThirdPartyCalendarConnect
      clientManagementRole
      createDateTime
      createMemberId
      id
      invitationKey
      invitationUrl
      memo
      organizationId
      organizationMemberViewRole
      organizationName
      organizationReportRole
      projectCreatePermissionFlg
      projectCustomAttributePermissionFlg
      projectDeletePermissionFlg
      projectUpdatePermissionFlg
      proxyEditWorkingDataRole
      taskCreatePermissionFlg
      taskCustomAttributePermissionFlg
      taskDeletePermissionFlg
      taskUpdatePermissionFlg
      teamMemberViewRole
      teamReportRole
      versionNo
    }
  }
`;

/**
 * __useMemberInvitationLinkQuery__
 *
 * To run a query within a React component, call `useMemberInvitationLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberInvitationLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberInvitationLinkQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useMemberInvitationLinkQuery(
  baseOptions: Apollo.QueryHookOptions<
    MemberInvitationLinkQuery,
    MemberInvitationLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MemberInvitationLinkQuery, MemberInvitationLinkQueryVariables>(
    MemberInvitationLinkDocument,
    options
  );
}
export function useMemberInvitationLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MemberInvitationLinkQuery,
    MemberInvitationLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MemberInvitationLinkQuery, MemberInvitationLinkQueryVariables>(
    MemberInvitationLinkDocument,
    options
  );
}
export type MemberInvitationLinkQueryHookResult = ReturnType<typeof useMemberInvitationLinkQuery>;
export type MemberInvitationLinkLazyQueryHookResult = ReturnType<
  typeof useMemberInvitationLinkLazyQuery
>;
export type MemberInvitationLinkQueryResult = Apollo.QueryResult<
  MemberInvitationLinkQuery,
  MemberInvitationLinkQueryVariables
>;
export const MemberInvitationLinksDocument = gql`
  query memberInvitationLinks {
    memberInvitationLinks {
      allowThirdPartyCalendarConnect
      clientManagementRole
      createDateTime
      createMemberId
      id
      invitationKey
      invitationUrl
      memo
      organizationId
      organizationMemberViewRole
      organizationName
      organizationReportRole
      projectCreatePermissionFlg
      projectCustomAttributePermissionFlg
      projectDeletePermissionFlg
      projectUpdatePermissionFlg
      proxyEditWorkingDataRole
      taskCreatePermissionFlg
      taskCustomAttributePermissionFlg
      taskDeletePermissionFlg
      taskUpdatePermissionFlg
      teamMemberViewRole
      teamReportRole
      versionNo
    }
  }
`;

/**
 * __useMemberInvitationLinksQuery__
 *
 * To run a query within a React component, call `useMemberInvitationLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberInvitationLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberInvitationLinksQuery({
 *   variables: {
 *   },
 * });
 */
export function useMemberInvitationLinksQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MemberInvitationLinksQuery,
    MemberInvitationLinksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MemberInvitationLinksQuery, MemberInvitationLinksQueryVariables>(
    MemberInvitationLinksDocument,
    options
  );
}
export function useMemberInvitationLinksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MemberInvitationLinksQuery,
    MemberInvitationLinksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MemberInvitationLinksQuery, MemberInvitationLinksQueryVariables>(
    MemberInvitationLinksDocument,
    options
  );
}
export type MemberInvitationLinksQueryHookResult = ReturnType<typeof useMemberInvitationLinksQuery>;
export type MemberInvitationLinksLazyQueryHookResult = ReturnType<
  typeof useMemberInvitationLinksLazyQuery
>;
export type MemberInvitationLinksQueryResult = Apollo.QueryResult<
  MemberInvitationLinksQuery,
  MemberInvitationLinksQueryVariables
>;
export const MemberInvitationsDocument = gql`
  query memberInvitations {
    memberInvitations {
      adminRole
      allowThirdPartyCalendarConnect
      clientManagementRole
      createMemberId
      department
      employeeNumber
      id
      invitationKey
      laborCostManagementRole
      mailAddress
      memberManagementRole
      name
      organizationId
      organizationMemberViewRole
      organizationName
      organizationReportRole
      projectCreatePermissionFlg
      projectCustomAttributePermissionFlg
      projectDeletePermissionFlg
      projectUpdatePermissionFlg
      proxyEditWorkingDataRole
      salesManagementRole
      taskCreatePermissionFlg
      taskCustomAttributePermissionFlg
      taskDeletePermissionFlg
      taskUpdatePermissionFlg
      teamMemberViewRole
      teamReportRole
      versionNo
    }
  }
`;

/**
 * __useMemberInvitationsQuery__
 *
 * To run a query within a React component, call `useMemberInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberInvitationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMemberInvitationsQuery(
  baseOptions?: Apollo.QueryHookOptions<MemberInvitationsQuery, MemberInvitationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MemberInvitationsQuery, MemberInvitationsQueryVariables>(
    MemberInvitationsDocument,
    options
  );
}
export function useMemberInvitationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MemberInvitationsQuery, MemberInvitationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MemberInvitationsQuery, MemberInvitationsQueryVariables>(
    MemberInvitationsDocument,
    options
  );
}
export type MemberInvitationsQueryHookResult = ReturnType<typeof useMemberInvitationsQuery>;
export type MemberInvitationsLazyQueryHookResult = ReturnType<typeof useMemberInvitationsLazyQuery>;
export type MemberInvitationsQueryResult = Apollo.QueryResult<
  MemberInvitationsQuery,
  MemberInvitationsQueryVariables
>;
export const MemberRegisterRequestDocument = gql`
  query memberRegisterRequest($key: String!) {
    memberRegisterRequest(key: $key) {
      mailAddress
      memberRegisterKey
    }
  }
`;

/**
 * __useMemberRegisterRequestQuery__
 *
 * To run a query within a React component, call `useMemberRegisterRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberRegisterRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberRegisterRequestQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useMemberRegisterRequestQuery(
  baseOptions: Apollo.QueryHookOptions<
    MemberRegisterRequestQuery,
    MemberRegisterRequestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MemberRegisterRequestQuery, MemberRegisterRequestQueryVariables>(
    MemberRegisterRequestDocument,
    options
  );
}
export function useMemberRegisterRequestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MemberRegisterRequestQuery,
    MemberRegisterRequestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MemberRegisterRequestQuery, MemberRegisterRequestQueryVariables>(
    MemberRegisterRequestDocument,
    options
  );
}
export type MemberRegisterRequestQueryHookResult = ReturnType<typeof useMemberRegisterRequestQuery>;
export type MemberRegisterRequestLazyQueryHookResult = ReturnType<
  typeof useMemberRegisterRequestLazyQuery
>;
export type MemberRegisterRequestQueryResult = Apollo.QueryResult<
  MemberRegisterRequestQuery,
  MemberRegisterRequestQueryVariables
>;
export const MemberRegisterRequestWithInvitationLinkDocument = gql`
  query memberRegisterRequestWithInvitationLink($key: String!) {
    memberRegisterRequestWithInvitationLink(key: $key) {
      mailAddress
      memberRegisterKey
    }
  }
`;

/**
 * __useMemberRegisterRequestWithInvitationLinkQuery__
 *
 * To run a query within a React component, call `useMemberRegisterRequestWithInvitationLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberRegisterRequestWithInvitationLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberRegisterRequestWithInvitationLinkQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useMemberRegisterRequestWithInvitationLinkQuery(
  baseOptions: Apollo.QueryHookOptions<
    MemberRegisterRequestWithInvitationLinkQuery,
    MemberRegisterRequestWithInvitationLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MemberRegisterRequestWithInvitationLinkQuery,
    MemberRegisterRequestWithInvitationLinkQueryVariables
  >(MemberRegisterRequestWithInvitationLinkDocument, options);
}
export function useMemberRegisterRequestWithInvitationLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MemberRegisterRequestWithInvitationLinkQuery,
    MemberRegisterRequestWithInvitationLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MemberRegisterRequestWithInvitationLinkQuery,
    MemberRegisterRequestWithInvitationLinkQueryVariables
  >(MemberRegisterRequestWithInvitationLinkDocument, options);
}
export type MemberRegisterRequestWithInvitationLinkQueryHookResult = ReturnType<
  typeof useMemberRegisterRequestWithInvitationLinkQuery
>;
export type MemberRegisterRequestWithInvitationLinkLazyQueryHookResult = ReturnType<
  typeof useMemberRegisterRequestWithInvitationLinkLazyQuery
>;
export type MemberRegisterRequestWithInvitationLinkQueryResult = Apollo.QueryResult<
  MemberRegisterRequestWithInvitationLinkQuery,
  MemberRegisterRequestWithInvitationLinkQueryVariables
>;
export const MemberSunburstGraphDataDocument = gql`
  query memberSunburstGraphData($input: MemberGraphDataInputInput!) {
    memberSunburstGraphData(input: $input) {
      color
      department
      mailAddress
      memberId
      name
      percent
      profileImageUrl
      reportType
      workingTimeSec
    }
  }
`;

/**
 * __useMemberSunburstGraphDataQuery__
 *
 * To run a query within a React component, call `useMemberSunburstGraphDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberSunburstGraphDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberSunburstGraphDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMemberSunburstGraphDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    MemberSunburstGraphDataQuery,
    MemberSunburstGraphDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MemberSunburstGraphDataQuery, MemberSunburstGraphDataQueryVariables>(
    MemberSunburstGraphDataDocument,
    options
  );
}
export function useMemberSunburstGraphDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MemberSunburstGraphDataQuery,
    MemberSunburstGraphDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MemberSunburstGraphDataQuery, MemberSunburstGraphDataQueryVariables>(
    MemberSunburstGraphDataDocument,
    options
  );
}
export type MemberSunburstGraphDataQueryHookResult = ReturnType<
  typeof useMemberSunburstGraphDataQuery
>;
export type MemberSunburstGraphDataLazyQueryHookResult = ReturnType<
  typeof useMemberSunburstGraphDataLazyQuery
>;
export type MemberSunburstGraphDataQueryResult = Apollo.QueryResult<
  MemberSunburstGraphDataQuery,
  MemberSunburstGraphDataQueryVariables
>;
export const MyCsvReportDocument = gql`
  query myCsvReport($id: ID!) {
    myCsvReport(id: $id) {
      csvReportItemDefinition {
        id
        items {
          id
          reportItemCode
          reportItemName
          sortNo
        }
        name
        versionNo
      }
      end
      id
      includeWorkingData
      reportType
      requestDateTime
      sjisFileUrl
      start
      status
      timeZoneOffset
      utf8FileUrl
      versionNo
    }
  }
`;

/**
 * __useMyCsvReportQuery__
 *
 * To run a query within a React component, call `useMyCsvReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyCsvReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyCsvReportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMyCsvReportQuery(
  baseOptions: Apollo.QueryHookOptions<MyCsvReportQuery, MyCsvReportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyCsvReportQuery, MyCsvReportQueryVariables>(MyCsvReportDocument, options);
}
export function useMyCsvReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MyCsvReportQuery, MyCsvReportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyCsvReportQuery, MyCsvReportQueryVariables>(
    MyCsvReportDocument,
    options
  );
}
export type MyCsvReportQueryHookResult = ReturnType<typeof useMyCsvReportQuery>;
export type MyCsvReportLazyQueryHookResult = ReturnType<typeof useMyCsvReportLazyQuery>;
export type MyCsvReportQueryResult = Apollo.QueryResult<
  MyCsvReportQuery,
  MyCsvReportQueryVariables
>;
export const MyCsvReportsDocument = gql`
  query myCsvReports {
    myCsvReports {
      csvReportItemDefinition {
        id
        items {
          id
          reportItemCode
          reportItemName
          sortNo
        }
        name
        versionNo
      }
      end
      id
      includeWorkingData
      reportType
      requestDateTime
      sjisFileUrl
      start
      status
      timeZoneOffset
      utf8FileUrl
      versionNo
    }
  }
`;

/**
 * __useMyCsvReportsQuery__
 *
 * To run a query within a React component, call `useMyCsvReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyCsvReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyCsvReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyCsvReportsQuery(
  baseOptions?: Apollo.QueryHookOptions<MyCsvReportsQuery, MyCsvReportsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyCsvReportsQuery, MyCsvReportsQueryVariables>(
    MyCsvReportsDocument,
    options
  );
}
export function useMyCsvReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MyCsvReportsQuery, MyCsvReportsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyCsvReportsQuery, MyCsvReportsQueryVariables>(
    MyCsvReportsDocument,
    options
  );
}
export type MyCsvReportsQueryHookResult = ReturnType<typeof useMyCsvReportsQuery>;
export type MyCsvReportsLazyQueryHookResult = ReturnType<typeof useMyCsvReportsLazyQuery>;
export type MyCsvReportsQueryResult = Apollo.QueryResult<
  MyCsvReportsQuery,
  MyCsvReportsQueryVariables
>;
export const MyGoogleAccountDocument = gql`
  query myGoogleAccount {
    myGoogleAccount {
      calendarPermission
      mailAddress
      name
    }
  }
`;

/**
 * __useMyGoogleAccountQuery__
 *
 * To run a query within a React component, call `useMyGoogleAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyGoogleAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyGoogleAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyGoogleAccountQuery(
  baseOptions?: Apollo.QueryHookOptions<MyGoogleAccountQuery, MyGoogleAccountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyGoogleAccountQuery, MyGoogleAccountQueryVariables>(
    MyGoogleAccountDocument,
    options
  );
}
export function useMyGoogleAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MyGoogleAccountQuery, MyGoogleAccountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyGoogleAccountQuery, MyGoogleAccountQueryVariables>(
    MyGoogleAccountDocument,
    options
  );
}
export type MyGoogleAccountQueryHookResult = ReturnType<typeof useMyGoogleAccountQuery>;
export type MyGoogleAccountLazyQueryHookResult = ReturnType<typeof useMyGoogleAccountLazyQuery>;
export type MyGoogleAccountQueryResult = Apollo.QueryResult<
  MyGoogleAccountQuery,
  MyGoogleAccountQueryVariables
>;
export const MyGoogleCalendarsDocument = gql`
  query myGoogleCalendars {
    myGoogleCalendars {
      backgroundColor
      calendarId
      colorId
      foregroundColor
      id
      myCalendar
      name
      primary
      readonly
    }
  }
`;

/**
 * __useMyGoogleCalendarsQuery__
 *
 * To run a query within a React component, call `useMyGoogleCalendarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyGoogleCalendarsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyGoogleCalendarsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyGoogleCalendarsQuery(
  baseOptions?: Apollo.QueryHookOptions<MyGoogleCalendarsQuery, MyGoogleCalendarsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyGoogleCalendarsQuery, MyGoogleCalendarsQueryVariables>(
    MyGoogleCalendarsDocument,
    options
  );
}
export function useMyGoogleCalendarsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MyGoogleCalendarsQuery, MyGoogleCalendarsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyGoogleCalendarsQuery, MyGoogleCalendarsQueryVariables>(
    MyGoogleCalendarsDocument,
    options
  );
}
export type MyGoogleCalendarsQueryHookResult = ReturnType<typeof useMyGoogleCalendarsQuery>;
export type MyGoogleCalendarsLazyQueryHookResult = ReturnType<typeof useMyGoogleCalendarsLazyQuery>;
export type MyGoogleCalendarsQueryResult = Apollo.QueryResult<
  MyGoogleCalendarsQuery,
  MyGoogleCalendarsQueryVariables
>;
export const MyReportDocument = gql`
  query myReport($id: ID!) {
    myReport(id: $id) {
      end
      id
      includeWorkingData
      reportType
      requestDateTime
      start
      status
      timeZoneOffset
      url
      versionNo
    }
  }
`;

/**
 * __useMyReportQuery__
 *
 * To run a query within a React component, call `useMyReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyReportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMyReportQuery(
  baseOptions: Apollo.QueryHookOptions<MyReportQuery, MyReportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyReportQuery, MyReportQueryVariables>(MyReportDocument, options);
}
export function useMyReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MyReportQuery, MyReportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyReportQuery, MyReportQueryVariables>(MyReportDocument, options);
}
export type MyReportQueryHookResult = ReturnType<typeof useMyReportQuery>;
export type MyReportLazyQueryHookResult = ReturnType<typeof useMyReportLazyQuery>;
export type MyReportQueryResult = Apollo.QueryResult<MyReportQuery, MyReportQueryVariables>;
export const MyReportsDocument = gql`
  query myReports {
    myReports {
      end
      id
      includeWorkingData
      reportType
      requestDateTime
      start
      status
      timeZoneOffset
      url
      versionNo
    }
  }
`;

/**
 * __useMyReportsQuery__
 *
 * To run a query within a React component, call `useMyReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyReportsQuery(
  baseOptions?: Apollo.QueryHookOptions<MyReportsQuery, MyReportsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyReportsQuery, MyReportsQueryVariables>(MyReportsDocument, options);
}
export function useMyReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MyReportsQuery, MyReportsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyReportsQuery, MyReportsQueryVariables>(MyReportsDocument, options);
}
export type MyReportsQueryHookResult = ReturnType<typeof useMyReportsQuery>;
export type MyReportsLazyQueryHookResult = ReturnType<typeof useMyReportsLazyQuery>;
export type MyReportsQueryResult = Apollo.QueryResult<MyReportsQuery, MyReportsQueryVariables>;
export const MySettingsDocument = gql`
  query mySettings {
    mySettings {
      allowExportCalendar
      autoAssignWhenTaskStart
      calendarStartDayOfWeek
      copyWorkingScheduleMemoToHistory
      id
      initialViewTypeCode
      longTimeWorkingAlertMinutes
      member {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      projectInitialViewTypeCode
      twoFactorAuthEnabled
      versionNo
    }
  }
`;

/**
 * __useMySettingsQuery__
 *
 * To run a query within a React component, call `useMySettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMySettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMySettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMySettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<MySettingsQuery, MySettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MySettingsQuery, MySettingsQueryVariables>(MySettingsDocument, options);
}
export function useMySettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MySettingsQuery, MySettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MySettingsQuery, MySettingsQueryVariables>(
    MySettingsDocument,
    options
  );
}
export type MySettingsQueryHookResult = ReturnType<typeof useMySettingsQuery>;
export type MySettingsLazyQueryHookResult = ReturnType<typeof useMySettingsLazyQuery>;
export type MySettingsQueryResult = Apollo.QueryResult<MySettingsQuery, MySettingsQueryVariables>;
export const MyTasksDocument = gql`
  query myTasks {
    myTasks {
      assignees {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      asssignee {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      calendarLinkageCode
      completeDateTime
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      estimatedRemainingTimeSec
      favoriteMembers {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      favoriteMembersInfo {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      id
      laborCost
      priority
      progressDeviationRate
      progressRate
      project {
        assignees {
          sortNo
        }
        calendarLinkageCode
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        color
        complete
        completeDateTime
        costBudgetWithoutTax
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          sortNo
        }
        id
        key
        laborCost
        laborCostWithoutTax
        laborShare
        name
        priority
        projectStatus {
          endStatus
          id
          name
          sortNo
          teamId
          versionNo
        }
        registerDateTime
        salesAmountWithoutTax
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInProjectStatus
        team {
          archived
          id
          name
          organization {
            allowConnectThirdPartyCalendar
            contactMailAddress
            contractPreriod
            id
            imageUrl
            ipRestrictionFlg
            name
            plan {
              code
              contractPreriod
              id
            }
            requiredTwoFactorAuth
            suspensionFlg
            versionNo
          }
          personalTeam
          status
          versionNo
        }
        totalScheduleTimeSec
        versionNo
        workingTimeSec
      }
      registerDateTime
      remainingTimeSec
      scheduleWorkingTimeSec
      scheduledEndDateTime
      scheduledStartDateTime
      sortNoInGanttChart
      sortNoInList
      sortNoInMyTask
      sortNoInTaskStatus
      taskStatus {
        endStatus
        id
        name
        project {
          calendarLinkageCode
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          description
          estimateTimeSec
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        sortNo
        versionNo
      }
      title
      versionNo
      workingMembers {
        lastWorkingStartDateTime
        lastWorkingStopDateTime
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
      }
      workingTimeSec
    }
  }
`;

/**
 * __useMyTasksQuery__
 *
 * To run a query within a React component, call `useMyTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyTasksQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyTasksQuery(
  baseOptions?: Apollo.QueryHookOptions<MyTasksQuery, MyTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyTasksQuery, MyTasksQueryVariables>(MyTasksDocument, options);
}
export function useMyTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MyTasksQuery, MyTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyTasksQuery, MyTasksQueryVariables>(MyTasksDocument, options);
}
export type MyTasksQueryHookResult = ReturnType<typeof useMyTasksQuery>;
export type MyTasksLazyQueryHookResult = ReturnType<typeof useMyTasksLazyQuery>;
export type MyTasksQueryResult = Apollo.QueryResult<MyTasksQuery, MyTasksQueryVariables>;
export const NotificationDocument = gql`
  query notification($id: String) {
    notification(id: $id) {
      id
      mention {
        comment {
          comment
          commentedDateTime
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          id
          projectId
          projectName
          task {
            assignees {
              member {
                adminRole
                clientManagementRole
                department
                employeeNumber
                id
                laborCostManagementRole
                mailAddress
                memberManagementRole
                memberRegistrationDateTime
                memberStatus
                name
                organizationMemberViewRole
                organizationReportRole
                profileImageUrl
                projectCreatePermissionFlg
                projectCustomAttributePermissionFlg
                projectDeletePermissionFlg
                projectUpdatePermissionFlg
                proxyEditWorkingDataRole
                salesManagementRole
                statusMessage
                taskCreatePermissionFlg
                taskCustomAttributePermissionFlg
                taskDeletePermissionFlg
                taskUpdatePermissionFlg
                teamMemberViewRole
                teamReportRole
                versionNo
              }
              sortNo
            }
            asssignee {
              adminRole
              clientManagementRole
              department
              employeeNumber
              id
              laborCostManagementRole
              mailAddress
              memberManagementRole
              memberRegistrationDateTime
              memberStatus
              name
              organizationMemberViewRole
              organizationReportRole
              profileImageUrl
              projectCreatePermissionFlg
              projectCustomAttributePermissionFlg
              projectDeletePermissionFlg
              projectUpdatePermissionFlg
              proxyEditWorkingDataRole
              salesManagementRole
              statusMessage
              taskCreatePermissionFlg
              taskCustomAttributePermissionFlg
              taskDeletePermissionFlg
              taskUpdatePermissionFlg
              teamMemberViewRole
              teamReportRole
              versionNo
            }
            calendarLinkageCode
            completeDateTime
            createdMember {
              adminRole
              clientManagementRole
              department
              employeeNumber
              id
              laborCostManagementRole
              mailAddress
              memberManagementRole
              memberRegistrationDateTime
              memberStatus
              name
              organizationMemberViewRole
              organizationReportRole
              profileImageUrl
              projectCreatePermissionFlg
              projectCustomAttributePermissionFlg
              projectDeletePermissionFlg
              projectUpdatePermissionFlg
              proxyEditWorkingDataRole
              salesManagementRole
              statusMessage
              taskCreatePermissionFlg
              taskCustomAttributePermissionFlg
              taskDeletePermissionFlg
              taskUpdatePermissionFlg
              teamMemberViewRole
              teamReportRole
              versionNo
            }
            description
            estimateTimeSec
            estimatedRemainingTimeSec
            favoriteMembers {
              adminRole
              clientManagementRole
              department
              employeeNumber
              id
              laborCostManagementRole
              mailAddress
              memberManagementRole
              memberRegistrationDateTime
              memberStatus
              name
              organizationMemberViewRole
              organizationReportRole
              profileImageUrl
              projectCreatePermissionFlg
              projectCustomAttributePermissionFlg
              projectDeletePermissionFlg
              projectUpdatePermissionFlg
              proxyEditWorkingDataRole
              salesManagementRole
              statusMessage
              taskCreatePermissionFlg
              taskCustomAttributePermissionFlg
              taskDeletePermissionFlg
              taskUpdatePermissionFlg
              teamMemberViewRole
              teamReportRole
              versionNo
            }
            favoriteMembersInfo {
              member {
                adminRole
                clientManagementRole
                department
                employeeNumber
                id
                laborCostManagementRole
                mailAddress
                memberManagementRole
                memberRegistrationDateTime
                memberStatus
                name
                organizationMemberViewRole
                organizationReportRole
                profileImageUrl
                projectCreatePermissionFlg
                projectCustomAttributePermissionFlg
                projectDeletePermissionFlg
                projectUpdatePermissionFlg
                proxyEditWorkingDataRole
                salesManagementRole
                statusMessage
                taskCreatePermissionFlg
                taskCustomAttributePermissionFlg
                taskDeletePermissionFlg
                taskUpdatePermissionFlg
                teamMemberViewRole
                teamReportRole
                versionNo
              }
              sortNo
            }
            id
            laborCost
            priority
            progressDeviationRate
            progressRate
            project {
              assignees {
                sortNo
              }
              calendarLinkageCode
              client {
                code
                id
                name
                sortNo
                versionNo
              }
              color
              complete
              completeDateTime
              costBudgetWithoutTax
              createdMember {
                adminRole
                clientManagementRole
                department
                employeeNumber
                id
                laborCostManagementRole
                mailAddress
                memberManagementRole
                memberRegistrationDateTime
                memberStatus
                name
                organizationMemberViewRole
                organizationReportRole
                profileImageUrl
                projectCreatePermissionFlg
                projectCustomAttributePermissionFlg
                projectDeletePermissionFlg
                projectUpdatePermissionFlg
                proxyEditWorkingDataRole
                salesManagementRole
                statusMessage
                taskCreatePermissionFlg
                taskCustomAttributePermissionFlg
                taskDeletePermissionFlg
                taskUpdatePermissionFlg
                teamMemberViewRole
                teamReportRole
                versionNo
              }
              description
              estimateTimeSec
              favoriteMembers {
                adminRole
                clientManagementRole
                department
                employeeNumber
                id
                laborCostManagementRole
                mailAddress
                memberManagementRole
                memberRegistrationDateTime
                memberStatus
                name
                organizationMemberViewRole
                organizationReportRole
                profileImageUrl
                projectCreatePermissionFlg
                projectCustomAttributePermissionFlg
                projectDeletePermissionFlg
                projectUpdatePermissionFlg
                proxyEditWorkingDataRole
                salesManagementRole
                statusMessage
                taskCreatePermissionFlg
                taskCustomAttributePermissionFlg
                taskDeletePermissionFlg
                taskUpdatePermissionFlg
                teamMemberViewRole
                teamReportRole
                versionNo
              }
              favoriteMembersInfo {
                sortNo
              }
              id
              key
              laborCost
              laborCostWithoutTax
              laborShare
              name
              priority
              projectStatus {
                endStatus
                id
                name
                sortNo
                teamId
                versionNo
              }
              registerDateTime
              salesAmountWithoutTax
              scheduledEndDateTime
              scheduledStartDateTime
              sortNoInGanttChart
              sortNoInList
              sortNoInProjectStatus
              team {
                archived
                id
                name
                organization {
                  allowConnectThirdPartyCalendar
                  contactMailAddress
                  contractPreriod
                  id
                  imageUrl
                  ipRestrictionFlg
                  name
                  plan {
                    code
                    contractPreriod
                    id
                  }
                  requiredTwoFactorAuth
                  suspensionFlg
                  versionNo
                }
                personalTeam
                status
                versionNo
              }
              totalScheduleTimeSec
              versionNo
              workingTimeSec
            }
            registerDateTime
            remainingTimeSec
            scheduleWorkingTimeSec
            scheduledEndDateTime
            scheduledStartDateTime
            sortNoInGanttChart
            sortNoInList
            sortNoInMyTask
            sortNoInTaskStatus
            taskStatus {
              endStatus
              id
              name
              project {
                calendarLinkageCode
                color
                complete
                completeDateTime
                costBudgetWithoutTax
                description
                estimateTimeSec
                id
                key
                laborCost
                laborCostWithoutTax
                laborShare
                name
                priority
                registerDateTime
                salesAmountWithoutTax
                scheduledEndDateTime
                scheduledStartDateTime
                sortNoInGanttChart
                sortNoInList
                sortNoInProjectStatus
                totalScheduleTimeSec
                versionNo
                workingTimeSec
              }
              sortNo
              versionNo
            }
            title
            versionNo
            workingMembers {
              lastWorkingStartDateTime
              lastWorkingStopDateTime
              member {
                adminRole
                clientManagementRole
                department
                employeeNumber
                id
                laborCostManagementRole
                mailAddress
                memberManagementRole
                memberRegistrationDateTime
                memberStatus
                name
                organizationMemberViewRole
                organizationReportRole
                profileImageUrl
                projectCreatePermissionFlg
                projectCustomAttributePermissionFlg
                projectDeletePermissionFlg
                projectUpdatePermissionFlg
                proxyEditWorkingDataRole
                salesManagementRole
                statusMessage
                taskCreatePermissionFlg
                taskCustomAttributePermissionFlg
                taskDeletePermissionFlg
                taskUpdatePermissionFlg
                teamMemberViewRole
                teamReportRole
                versionNo
              }
            }
            workingTimeSec
          }
          taskId
          taskTitle
          teamId
          teamName
          versionNo
        }
        id
        project {
          calendarLinkageCode
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          description
          estimateTimeSec
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        sendMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        task {
          calendarLinkageCode
          completeDateTime
          description
          estimateTimeSec
          estimatedRemainingTimeSec
          id
          laborCost
          priority
          progressDeviationRate
          progressRate
          registerDateTime
          remainingTimeSec
          scheduleWorkingTimeSec
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInMyTask
          sortNoInTaskStatus
          title
          versionNo
          workingTimeSec
        }
      }
      notificationType
      projectAssignNotification {
        id
        project {
          calendarLinkageCode
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          description
          estimateTimeSec
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        sendMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
      }
      readDateTime
      receivedDateTime
      sendDateTime
      targetMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      taskAssignNotification {
        id
        projectId
        sendMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        task {
          calendarLinkageCode
          completeDateTime
          description
          estimateTimeSec
          estimatedRemainingTimeSec
          id
          laborCost
          priority
          progressDeviationRate
          progressRate
          registerDateTime
          remainingTimeSec
          scheduleWorkingTimeSec
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInMyTask
          sortNoInTaskStatus
          title
          versionNo
          workingTimeSec
        }
        teamId
      }
      taskRemainderNotification {
        beforeDeadlineMinutes
        id
        task {
          calendarLinkageCode
          completeDateTime
          description
          estimateTimeSec
          estimatedRemainingTimeSec
          id
          laborCost
          priority
          progressDeviationRate
          progressRate
          registerDateTime
          remainingTimeSec
          scheduleWorkingTimeSec
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInMyTask
          sortNoInTaskStatus
          title
          versionNo
          workingTimeSec
        }
        taskRemainderType
      }
    }
  }
`;

/**
 * __useNotificationQuery__
 *
 * To run a query within a React component, call `useNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNotificationQuery(
  baseOptions?: Apollo.QueryHookOptions<NotificationQuery, NotificationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NotificationQuery, NotificationQueryVariables>(
    NotificationDocument,
    options
  );
}
export function useNotificationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NotificationQuery, NotificationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NotificationQuery, NotificationQueryVariables>(
    NotificationDocument,
    options
  );
}
export type NotificationQueryHookResult = ReturnType<typeof useNotificationQuery>;
export type NotificationLazyQueryHookResult = ReturnType<typeof useNotificationLazyQuery>;
export type NotificationQueryResult = Apollo.QueryResult<
  NotificationQuery,
  NotificationQueryVariables
>;
export const NotificationV2Document = gql`
  query notificationV2($id: String) {
    notificationV2(id: $id) {
      id
      mention {
        commentId
        id
        mentionType
        message
        projectId
        projectName
        sendMember {
          id
          name
          profileImageUrl
        }
        taskId
        taskTitle
        teamId
        teamName
      }
      notificationType
      projectAssignNotification {
        id
        projectId
        projectName
        sendMember {
          id
          name
          profileImageUrl
        }
        teamId
        teamName
      }
      readDateTime
      receivedDateTime
      sendDateTime
      targetMember {
        id
        name
        profileImageUrl
      }
      taskAssignNotification {
        id
        projectId
        projectName
        sendMember {
          id
          name
          profileImageUrl
        }
        taskId
        taskTitle
        teamId
        teamName
      }
      taskRemainderNotification {
        id
        projectId
        projectName
        scheduledEndDateTime
        taskId
        taskRemainderType
        taskTitle
        teamId
        teamName
      }
    }
  }
`;

/**
 * __useNotificationV2Query__
 *
 * To run a query within a React component, call `useNotificationV2Query` and pass it any options that fit your needs.
 * When your component renders, `useNotificationV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationV2Query({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNotificationV2Query(
  baseOptions?: Apollo.QueryHookOptions<NotificationV2Query, NotificationV2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NotificationV2Query, NotificationV2QueryVariables>(
    NotificationV2Document,
    options
  );
}
export function useNotificationV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NotificationV2Query, NotificationV2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NotificationV2Query, NotificationV2QueryVariables>(
    NotificationV2Document,
    options
  );
}
export type NotificationV2QueryHookResult = ReturnType<typeof useNotificationV2Query>;
export type NotificationV2LazyQueryHookResult = ReturnType<typeof useNotificationV2LazyQuery>;
export type NotificationV2QueryResult = Apollo.QueryResult<
  NotificationV2Query,
  NotificationV2QueryVariables
>;
export const NotificationsDocument = gql`
  query notifications($limit: Int, $offset: Int) {
    notifications(limit: $limit, offset: $offset) {
      id
      mention {
        comment {
          comment
          commentedDateTime
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          id
          projectId
          projectName
          task {
            assignees {
              member {
                adminRole
                clientManagementRole
                department
                employeeNumber
                id
                laborCostManagementRole
                mailAddress
                memberManagementRole
                memberRegistrationDateTime
                memberStatus
                name
                organizationMemberViewRole
                organizationReportRole
                profileImageUrl
                projectCreatePermissionFlg
                projectCustomAttributePermissionFlg
                projectDeletePermissionFlg
                projectUpdatePermissionFlg
                proxyEditWorkingDataRole
                salesManagementRole
                statusMessage
                taskCreatePermissionFlg
                taskCustomAttributePermissionFlg
                taskDeletePermissionFlg
                taskUpdatePermissionFlg
                teamMemberViewRole
                teamReportRole
                versionNo
              }
              sortNo
            }
            asssignee {
              adminRole
              clientManagementRole
              department
              employeeNumber
              id
              laborCostManagementRole
              mailAddress
              memberManagementRole
              memberRegistrationDateTime
              memberStatus
              name
              organizationMemberViewRole
              organizationReportRole
              profileImageUrl
              projectCreatePermissionFlg
              projectCustomAttributePermissionFlg
              projectDeletePermissionFlg
              projectUpdatePermissionFlg
              proxyEditWorkingDataRole
              salesManagementRole
              statusMessage
              taskCreatePermissionFlg
              taskCustomAttributePermissionFlg
              taskDeletePermissionFlg
              taskUpdatePermissionFlg
              teamMemberViewRole
              teamReportRole
              versionNo
            }
            calendarLinkageCode
            completeDateTime
            createdMember {
              adminRole
              clientManagementRole
              department
              employeeNumber
              id
              laborCostManagementRole
              mailAddress
              memberManagementRole
              memberRegistrationDateTime
              memberStatus
              name
              organizationMemberViewRole
              organizationReportRole
              profileImageUrl
              projectCreatePermissionFlg
              projectCustomAttributePermissionFlg
              projectDeletePermissionFlg
              projectUpdatePermissionFlg
              proxyEditWorkingDataRole
              salesManagementRole
              statusMessage
              taskCreatePermissionFlg
              taskCustomAttributePermissionFlg
              taskDeletePermissionFlg
              taskUpdatePermissionFlg
              teamMemberViewRole
              teamReportRole
              versionNo
            }
            description
            estimateTimeSec
            estimatedRemainingTimeSec
            favoriteMembers {
              adminRole
              clientManagementRole
              department
              employeeNumber
              id
              laborCostManagementRole
              mailAddress
              memberManagementRole
              memberRegistrationDateTime
              memberStatus
              name
              organizationMemberViewRole
              organizationReportRole
              profileImageUrl
              projectCreatePermissionFlg
              projectCustomAttributePermissionFlg
              projectDeletePermissionFlg
              projectUpdatePermissionFlg
              proxyEditWorkingDataRole
              salesManagementRole
              statusMessage
              taskCreatePermissionFlg
              taskCustomAttributePermissionFlg
              taskDeletePermissionFlg
              taskUpdatePermissionFlg
              teamMemberViewRole
              teamReportRole
              versionNo
            }
            favoriteMembersInfo {
              member {
                adminRole
                clientManagementRole
                department
                employeeNumber
                id
                laborCostManagementRole
                mailAddress
                memberManagementRole
                memberRegistrationDateTime
                memberStatus
                name
                organizationMemberViewRole
                organizationReportRole
                profileImageUrl
                projectCreatePermissionFlg
                projectCustomAttributePermissionFlg
                projectDeletePermissionFlg
                projectUpdatePermissionFlg
                proxyEditWorkingDataRole
                salesManagementRole
                statusMessage
                taskCreatePermissionFlg
                taskCustomAttributePermissionFlg
                taskDeletePermissionFlg
                taskUpdatePermissionFlg
                teamMemberViewRole
                teamReportRole
                versionNo
              }
              sortNo
            }
            id
            laborCost
            priority
            progressDeviationRate
            progressRate
            project {
              assignees {
                sortNo
              }
              calendarLinkageCode
              client {
                code
                id
                name
                sortNo
                versionNo
              }
              color
              complete
              completeDateTime
              costBudgetWithoutTax
              createdMember {
                adminRole
                clientManagementRole
                department
                employeeNumber
                id
                laborCostManagementRole
                mailAddress
                memberManagementRole
                memberRegistrationDateTime
                memberStatus
                name
                organizationMemberViewRole
                organizationReportRole
                profileImageUrl
                projectCreatePermissionFlg
                projectCustomAttributePermissionFlg
                projectDeletePermissionFlg
                projectUpdatePermissionFlg
                proxyEditWorkingDataRole
                salesManagementRole
                statusMessage
                taskCreatePermissionFlg
                taskCustomAttributePermissionFlg
                taskDeletePermissionFlg
                taskUpdatePermissionFlg
                teamMemberViewRole
                teamReportRole
                versionNo
              }
              description
              estimateTimeSec
              favoriteMembers {
                adminRole
                clientManagementRole
                department
                employeeNumber
                id
                laborCostManagementRole
                mailAddress
                memberManagementRole
                memberRegistrationDateTime
                memberStatus
                name
                organizationMemberViewRole
                organizationReportRole
                profileImageUrl
                projectCreatePermissionFlg
                projectCustomAttributePermissionFlg
                projectDeletePermissionFlg
                projectUpdatePermissionFlg
                proxyEditWorkingDataRole
                salesManagementRole
                statusMessage
                taskCreatePermissionFlg
                taskCustomAttributePermissionFlg
                taskDeletePermissionFlg
                taskUpdatePermissionFlg
                teamMemberViewRole
                teamReportRole
                versionNo
              }
              favoriteMembersInfo {
                sortNo
              }
              id
              key
              laborCost
              laborCostWithoutTax
              laborShare
              name
              priority
              projectStatus {
                endStatus
                id
                name
                sortNo
                teamId
                versionNo
              }
              registerDateTime
              salesAmountWithoutTax
              scheduledEndDateTime
              scheduledStartDateTime
              sortNoInGanttChart
              sortNoInList
              sortNoInProjectStatus
              team {
                archived
                id
                name
                organization {
                  allowConnectThirdPartyCalendar
                  contactMailAddress
                  contractPreriod
                  id
                  imageUrl
                  ipRestrictionFlg
                  name
                  plan {
                    code
                    contractPreriod
                    id
                  }
                  requiredTwoFactorAuth
                  suspensionFlg
                  versionNo
                }
                personalTeam
                status
                versionNo
              }
              totalScheduleTimeSec
              versionNo
              workingTimeSec
            }
            registerDateTime
            remainingTimeSec
            scheduleWorkingTimeSec
            scheduledEndDateTime
            scheduledStartDateTime
            sortNoInGanttChart
            sortNoInList
            sortNoInMyTask
            sortNoInTaskStatus
            taskStatus {
              endStatus
              id
              name
              project {
                calendarLinkageCode
                color
                complete
                completeDateTime
                costBudgetWithoutTax
                description
                estimateTimeSec
                id
                key
                laborCost
                laborCostWithoutTax
                laborShare
                name
                priority
                registerDateTime
                salesAmountWithoutTax
                scheduledEndDateTime
                scheduledStartDateTime
                sortNoInGanttChart
                sortNoInList
                sortNoInProjectStatus
                totalScheduleTimeSec
                versionNo
                workingTimeSec
              }
              sortNo
              versionNo
            }
            title
            versionNo
            workingMembers {
              lastWorkingStartDateTime
              lastWorkingStopDateTime
              member {
                adminRole
                clientManagementRole
                department
                employeeNumber
                id
                laborCostManagementRole
                mailAddress
                memberManagementRole
                memberRegistrationDateTime
                memberStatus
                name
                organizationMemberViewRole
                organizationReportRole
                profileImageUrl
                projectCreatePermissionFlg
                projectCustomAttributePermissionFlg
                projectDeletePermissionFlg
                projectUpdatePermissionFlg
                proxyEditWorkingDataRole
                salesManagementRole
                statusMessage
                taskCreatePermissionFlg
                taskCustomAttributePermissionFlg
                taskDeletePermissionFlg
                taskUpdatePermissionFlg
                teamMemberViewRole
                teamReportRole
                versionNo
              }
            }
            workingTimeSec
          }
          taskId
          taskTitle
          teamId
          teamName
          versionNo
        }
        id
        project {
          calendarLinkageCode
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          description
          estimateTimeSec
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        sendMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        task {
          calendarLinkageCode
          completeDateTime
          description
          estimateTimeSec
          estimatedRemainingTimeSec
          id
          laborCost
          priority
          progressDeviationRate
          progressRate
          registerDateTime
          remainingTimeSec
          scheduleWorkingTimeSec
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInMyTask
          sortNoInTaskStatus
          title
          versionNo
          workingTimeSec
        }
      }
      notificationType
      projectAssignNotification {
        id
        project {
          calendarLinkageCode
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          description
          estimateTimeSec
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        sendMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
      }
      readDateTime
      receivedDateTime
      sendDateTime
      targetMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      taskAssignNotification {
        id
        projectId
        sendMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        task {
          calendarLinkageCode
          completeDateTime
          description
          estimateTimeSec
          estimatedRemainingTimeSec
          id
          laborCost
          priority
          progressDeviationRate
          progressRate
          registerDateTime
          remainingTimeSec
          scheduleWorkingTimeSec
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInMyTask
          sortNoInTaskStatus
          title
          versionNo
          workingTimeSec
        }
        teamId
      }
      taskRemainderNotification {
        beforeDeadlineMinutes
        id
        task {
          calendarLinkageCode
          completeDateTime
          description
          estimateTimeSec
          estimatedRemainingTimeSec
          id
          laborCost
          priority
          progressDeviationRate
          progressRate
          registerDateTime
          remainingTimeSec
          scheduleWorkingTimeSec
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInMyTask
          sortNoInTaskStatus
          title
          versionNo
          workingTimeSec
        }
        taskRemainderType
      }
    }
  }
`;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useNotificationsQuery(
  baseOptions?: Apollo.QueryHookOptions<NotificationsQuery, NotificationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(
    NotificationsDocument,
    options
  );
}
export function useNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(
    NotificationsDocument,
    options
  );
}
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>;
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>;
export type NotificationsQueryResult = Apollo.QueryResult<
  NotificationsQuery,
  NotificationsQueryVariables
>;
export const NotificationsV2Document = gql`
  query notificationsV2($limit: Int, $offset: Int, $read: Boolean!, $unread: Boolean!) {
    notificationsV2(limit: $limit, offset: $offset, read: $read, unread: $unread) {
      id
      mention {
        commentId
        id
        mentionType
        message
        projectId
        projectName
        sendMember {
          id
          name
          profileImageUrl
        }
        taskId
        taskTitle
        teamId
        teamName
      }
      notificationType
      projectAssignNotification {
        id
        projectId
        projectName
        sendMember {
          id
          name
          profileImageUrl
        }
        teamId
        teamName
      }
      readDateTime
      receivedDateTime
      sendDateTime
      targetMember {
        id
        name
        profileImageUrl
      }
      taskAssignNotification {
        id
        projectId
        projectName
        sendMember {
          id
          name
          profileImageUrl
        }
        taskId
        taskTitle
        teamId
        teamName
      }
      taskRemainderNotification {
        id
        projectId
        projectName
        scheduledEndDateTime
        taskId
        taskRemainderType
        taskTitle
        teamId
        teamName
      }
    }
  }
`;

/**
 * __useNotificationsV2Query__
 *
 * To run a query within a React component, call `useNotificationsV2Query` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsV2Query({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      read: // value for 'read'
 *      unread: // value for 'unread'
 *   },
 * });
 */
export function useNotificationsV2Query(
  baseOptions: Apollo.QueryHookOptions<NotificationsV2Query, NotificationsV2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NotificationsV2Query, NotificationsV2QueryVariables>(
    NotificationsV2Document,
    options
  );
}
export function useNotificationsV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NotificationsV2Query, NotificationsV2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NotificationsV2Query, NotificationsV2QueryVariables>(
    NotificationsV2Document,
    options
  );
}
export type NotificationsV2QueryHookResult = ReturnType<typeof useNotificationsV2Query>;
export type NotificationsV2LazyQueryHookResult = ReturnType<typeof useNotificationsV2LazyQuery>;
export type NotificationsV2QueryResult = Apollo.QueryResult<
  NotificationsV2Query,
  NotificationsV2QueryVariables
>;
export const OrganizationDocument = gql`
  query organization($id: ID!) {
    organization(id: $id) {
      allowConnectThirdPartyCalendar
      contactMailAddress
      contractPreriod
      id
      imageUrl
      ipRestrictionFlg
      name
      plan {
        code
        contractPreriod
        id
      }
      requiredTwoFactorAuth
      suspensionFlg
      versionNo
    }
  }
`;

/**
 * __useOrganizationQuery__
 *
 * To run a query within a React component, call `useOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationQuery(
  baseOptions: Apollo.QueryHookOptions<OrganizationQuery, OrganizationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrganizationQuery, OrganizationQueryVariables>(
    OrganizationDocument,
    options
  );
}
export function useOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrganizationQuery, OrganizationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrganizationQuery, OrganizationQueryVariables>(
    OrganizationDocument,
    options
  );
}
export type OrganizationQueryHookResult = ReturnType<typeof useOrganizationQuery>;
export type OrganizationLazyQueryHookResult = ReturnType<typeof useOrganizationLazyQuery>;
export type OrganizationQueryResult = Apollo.QueryResult<
  OrganizationQuery,
  OrganizationQueryVariables
>;
export const OrganizationArchivedTeamsWithoutPersonalTeamDocument = gql`
  query organizationArchivedTeamsWithoutPersonalTeam($organizationId: String!) {
    organizationArchivedTeamsWithoutPersonalTeam(organizationId: $organizationId) {
      archived
      id
      name
      organization {
        allowConnectThirdPartyCalendar
        contactMailAddress
        contractPreriod
        id
        imageUrl
        ipRestrictionFlg
        name
        plan {
          code
          contractPreriod
          id
        }
        requiredTwoFactorAuth
        suspensionFlg
        versionNo
      }
      personalTeam
      status
      versionNo
    }
  }
`;

/**
 * __useOrganizationArchivedTeamsWithoutPersonalTeamQuery__
 *
 * To run a query within a React component, call `useOrganizationArchivedTeamsWithoutPersonalTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationArchivedTeamsWithoutPersonalTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationArchivedTeamsWithoutPersonalTeamQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationArchivedTeamsWithoutPersonalTeamQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrganizationArchivedTeamsWithoutPersonalTeamQuery,
    OrganizationArchivedTeamsWithoutPersonalTeamQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrganizationArchivedTeamsWithoutPersonalTeamQuery,
    OrganizationArchivedTeamsWithoutPersonalTeamQueryVariables
  >(OrganizationArchivedTeamsWithoutPersonalTeamDocument, options);
}
export function useOrganizationArchivedTeamsWithoutPersonalTeamLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationArchivedTeamsWithoutPersonalTeamQuery,
    OrganizationArchivedTeamsWithoutPersonalTeamQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrganizationArchivedTeamsWithoutPersonalTeamQuery,
    OrganizationArchivedTeamsWithoutPersonalTeamQueryVariables
  >(OrganizationArchivedTeamsWithoutPersonalTeamDocument, options);
}
export type OrganizationArchivedTeamsWithoutPersonalTeamQueryHookResult = ReturnType<
  typeof useOrganizationArchivedTeamsWithoutPersonalTeamQuery
>;
export type OrganizationArchivedTeamsWithoutPersonalTeamLazyQueryHookResult = ReturnType<
  typeof useOrganizationArchivedTeamsWithoutPersonalTeamLazyQuery
>;
export type OrganizationArchivedTeamsWithoutPersonalTeamQueryResult = Apollo.QueryResult<
  OrganizationArchivedTeamsWithoutPersonalTeamQuery,
  OrganizationArchivedTeamsWithoutPersonalTeamQueryVariables
>;
export const OrganizationClientsDocument = gql`
  query organizationClients($input: FetchClientsInputInput, $organizationId: String!) {
    organizationClients(input: $input, organizationId: $organizationId) {
      code
      id
      name
      sortNo
      versionNo
    }
  }
`;

/**
 * __useOrganizationClientsQuery__
 *
 * To run a query within a React component, call `useOrganizationClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationClientsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationClientsQuery(
  baseOptions: Apollo.QueryHookOptions<OrganizationClientsQuery, OrganizationClientsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrganizationClientsQuery, OrganizationClientsQueryVariables>(
    OrganizationClientsDocument,
    options
  );
}
export function useOrganizationClientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationClientsQuery,
    OrganizationClientsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrganizationClientsQuery, OrganizationClientsQueryVariables>(
    OrganizationClientsDocument,
    options
  );
}
export type OrganizationClientsQueryHookResult = ReturnType<typeof useOrganizationClientsQuery>;
export type OrganizationClientsLazyQueryHookResult = ReturnType<
  typeof useOrganizationClientsLazyQuery
>;
export type OrganizationClientsQueryResult = Apollo.QueryResult<
  OrganizationClientsQuery,
  OrganizationClientsQueryVariables
>;
export const OrganizationCsvReportDocument = gql`
  query organizationCsvReport($id: ID!) {
    organizationCsvReport(id: $id) {
      csvReportItemDefinition {
        id
        items {
          id
          reportItemCode
          reportItemName
          sortNo
        }
        name
        versionNo
      }
      end
      id
      includeWorkingData
      reportType
      requestDateTime
      requestMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      sjisFileUrl
      start
      status
      timeZoneOffset
      utf8FileUrl
      versionNo
    }
  }
`;

/**
 * __useOrganizationCsvReportQuery__
 *
 * To run a query within a React component, call `useOrganizationCsvReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationCsvReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationCsvReportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationCsvReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrganizationCsvReportQuery,
    OrganizationCsvReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrganizationCsvReportQuery, OrganizationCsvReportQueryVariables>(
    OrganizationCsvReportDocument,
    options
  );
}
export function useOrganizationCsvReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationCsvReportQuery,
    OrganizationCsvReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrganizationCsvReportQuery, OrganizationCsvReportQueryVariables>(
    OrganizationCsvReportDocument,
    options
  );
}
export type OrganizationCsvReportQueryHookResult = ReturnType<typeof useOrganizationCsvReportQuery>;
export type OrganizationCsvReportLazyQueryHookResult = ReturnType<
  typeof useOrganizationCsvReportLazyQuery
>;
export type OrganizationCsvReportQueryResult = Apollo.QueryResult<
  OrganizationCsvReportQuery,
  OrganizationCsvReportQueryVariables
>;
export const OrganizationCsvReportsDocument = gql`
  query organizationCsvReports {
    organizationCsvReports {
      csvReportItemDefinition {
        id
        items {
          id
          reportItemCode
          reportItemName
          sortNo
        }
        name
        versionNo
      }
      end
      id
      includeWorkingData
      reportType
      requestDateTime
      requestMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      sjisFileUrl
      start
      status
      timeZoneOffset
      utf8FileUrl
      versionNo
    }
  }
`;

/**
 * __useOrganizationCsvReportsQuery__
 *
 * To run a query within a React component, call `useOrganizationCsvReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationCsvReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationCsvReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationCsvReportsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OrganizationCsvReportsQuery,
    OrganizationCsvReportsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrganizationCsvReportsQuery, OrganizationCsvReportsQueryVariables>(
    OrganizationCsvReportsDocument,
    options
  );
}
export function useOrganizationCsvReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationCsvReportsQuery,
    OrganizationCsvReportsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrganizationCsvReportsQuery, OrganizationCsvReportsQueryVariables>(
    OrganizationCsvReportsDocument,
    options
  );
}
export type OrganizationCsvReportsQueryHookResult = ReturnType<
  typeof useOrganizationCsvReportsQuery
>;
export type OrganizationCsvReportsLazyQueryHookResult = ReturnType<
  typeof useOrganizationCsvReportsLazyQuery
>;
export type OrganizationCsvReportsQueryResult = Apollo.QueryResult<
  OrganizationCsvReportsQuery,
  OrganizationCsvReportsQueryVariables
>;
export const OrganizationMembersDocument = gql`
  query organizationMembers($organizationId: String!) {
    organizationMembers(organizationId: $organizationId) {
      adminRole
      clientManagementRole
      department
      employeeNumber
      id
      laborCostManagementRole
      mailAddress
      memberManagementRole
      memberRegistrationDateTime
      memberStatus
      name
      organizationMemberViewRole
      organizationReportRole
      profileImageUrl
      projectCreatePermissionFlg
      projectCustomAttributePermissionFlg
      projectDeletePermissionFlg
      projectUpdatePermissionFlg
      proxyEditWorkingDataRole
      salesManagementRole
      statusMessage
      taskCreatePermissionFlg
      taskCustomAttributePermissionFlg
      taskDeletePermissionFlg
      taskUpdatePermissionFlg
      teamMemberViewRole
      teamReportRole
      versionNo
    }
  }
`;

/**
 * __useOrganizationMembersQuery__
 *
 * To run a query within a React component, call `useOrganizationMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationMembersQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationMembersQuery(
  baseOptions: Apollo.QueryHookOptions<OrganizationMembersQuery, OrganizationMembersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrganizationMembersQuery, OrganizationMembersQueryVariables>(
    OrganizationMembersDocument,
    options
  );
}
export function useOrganizationMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationMembersQuery,
    OrganizationMembersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrganizationMembersQuery, OrganizationMembersQueryVariables>(
    OrganizationMembersDocument,
    options
  );
}
export type OrganizationMembersQueryHookResult = ReturnType<typeof useOrganizationMembersQuery>;
export type OrganizationMembersLazyQueryHookResult = ReturnType<
  typeof useOrganizationMembersLazyQuery
>;
export type OrganizationMembersQueryResult = Apollo.QueryResult<
  OrganizationMembersQuery,
  OrganizationMembersQueryVariables
>;
export const OrganizationMembersNotEnableTwoFactorAuthDocument = gql`
  query organizationMembersNotEnableTwoFactorAuth($organizationId: String!) {
    organizationMembersNotEnableTwoFactorAuth(organizationId: $organizationId) {
      adminRole
      clientManagementRole
      department
      employeeNumber
      id
      laborCostManagementRole
      mailAddress
      memberManagementRole
      memberRegistrationDateTime
      memberStatus
      name
      organizationMemberViewRole
      organizationReportRole
      profileImageUrl
      projectCreatePermissionFlg
      projectCustomAttributePermissionFlg
      projectDeletePermissionFlg
      projectUpdatePermissionFlg
      proxyEditWorkingDataRole
      salesManagementRole
      statusMessage
      taskCreatePermissionFlg
      taskCustomAttributePermissionFlg
      taskDeletePermissionFlg
      taskUpdatePermissionFlg
      teamMemberViewRole
      teamReportRole
      versionNo
    }
  }
`;

/**
 * __useOrganizationMembersNotEnableTwoFactorAuthQuery__
 *
 * To run a query within a React component, call `useOrganizationMembersNotEnableTwoFactorAuthQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationMembersNotEnableTwoFactorAuthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationMembersNotEnableTwoFactorAuthQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationMembersNotEnableTwoFactorAuthQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrganizationMembersNotEnableTwoFactorAuthQuery,
    OrganizationMembersNotEnableTwoFactorAuthQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrganizationMembersNotEnableTwoFactorAuthQuery,
    OrganizationMembersNotEnableTwoFactorAuthQueryVariables
  >(OrganizationMembersNotEnableTwoFactorAuthDocument, options);
}
export function useOrganizationMembersNotEnableTwoFactorAuthLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationMembersNotEnableTwoFactorAuthQuery,
    OrganizationMembersNotEnableTwoFactorAuthQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrganizationMembersNotEnableTwoFactorAuthQuery,
    OrganizationMembersNotEnableTwoFactorAuthQueryVariables
  >(OrganizationMembersNotEnableTwoFactorAuthDocument, options);
}
export type OrganizationMembersNotEnableTwoFactorAuthQueryHookResult = ReturnType<
  typeof useOrganizationMembersNotEnableTwoFactorAuthQuery
>;
export type OrganizationMembersNotEnableTwoFactorAuthLazyQueryHookResult = ReturnType<
  typeof useOrganizationMembersNotEnableTwoFactorAuthLazyQuery
>;
export type OrganizationMembersNotEnableTwoFactorAuthQueryResult = Apollo.QueryResult<
  OrganizationMembersNotEnableTwoFactorAuthQuery,
  OrganizationMembersNotEnableTwoFactorAuthQueryVariables
>;
export const OrganizationReportDocument = gql`
  query organizationReport($id: ID!) {
    organizationReport(id: $id) {
      end
      id
      includeWorkingData
      reportType
      requestDateTime
      requestMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      start
      status
      timeZoneOffset
      url
      versionNo
    }
  }
`;

/**
 * __useOrganizationReportQuery__
 *
 * To run a query within a React component, call `useOrganizationReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationReportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationReportQuery(
  baseOptions: Apollo.QueryHookOptions<OrganizationReportQuery, OrganizationReportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrganizationReportQuery, OrganizationReportQueryVariables>(
    OrganizationReportDocument,
    options
  );
}
export function useOrganizationReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationReportQuery,
    OrganizationReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrganizationReportQuery, OrganizationReportQueryVariables>(
    OrganizationReportDocument,
    options
  );
}
export type OrganizationReportQueryHookResult = ReturnType<typeof useOrganizationReportQuery>;
export type OrganizationReportLazyQueryHookResult = ReturnType<
  typeof useOrganizationReportLazyQuery
>;
export type OrganizationReportQueryResult = Apollo.QueryResult<
  OrganizationReportQuery,
  OrganizationReportQueryVariables
>;
export const OrganizationReportsDocument = gql`
  query organizationReports {
    organizationReports {
      end
      id
      includeWorkingData
      reportType
      requestDateTime
      requestMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      start
      status
      timeZoneOffset
      url
      versionNo
    }
  }
`;

/**
 * __useOrganizationReportsQuery__
 *
 * To run a query within a React component, call `useOrganizationReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationReportsQuery(
  baseOptions?: Apollo.QueryHookOptions<OrganizationReportsQuery, OrganizationReportsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrganizationReportsQuery, OrganizationReportsQueryVariables>(
    OrganizationReportsDocument,
    options
  );
}
export function useOrganizationReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationReportsQuery,
    OrganizationReportsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrganizationReportsQuery, OrganizationReportsQueryVariables>(
    OrganizationReportsDocument,
    options
  );
}
export type OrganizationReportsQueryHookResult = ReturnType<typeof useOrganizationReportsQuery>;
export type OrganizationReportsLazyQueryHookResult = ReturnType<
  typeof useOrganizationReportsLazyQuery
>;
export type OrganizationReportsQueryResult = Apollo.QueryResult<
  OrganizationReportsQuery,
  OrganizationReportsQueryVariables
>;
export const OrganizationTagsDocument = gql`
  query organizationTags($input: FetchTagsInputInput, $organizationId: String!) {
    organizationTags(input: $input, organizationId: $organizationId) {
      id
      name
      note
      organization {
        allowConnectThirdPartyCalendar
        contactMailAddress
        contractPreriod
        id
        imageUrl
        ipRestrictionFlg
        name
        plan {
          code
          contractPreriod
          id
        }
        requiredTwoFactorAuth
        suspensionFlg
        versionNo
      }
      relatedTaskCount
      sortNo
      versionNo
    }
  }
`;

/**
 * __useOrganizationTagsQuery__
 *
 * To run a query within a React component, call `useOrganizationTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationTagsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationTagsQuery(
  baseOptions: Apollo.QueryHookOptions<OrganizationTagsQuery, OrganizationTagsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrganizationTagsQuery, OrganizationTagsQueryVariables>(
    OrganizationTagsDocument,
    options
  );
}
export function useOrganizationTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrganizationTagsQuery, OrganizationTagsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrganizationTagsQuery, OrganizationTagsQueryVariables>(
    OrganizationTagsDocument,
    options
  );
}
export type OrganizationTagsQueryHookResult = ReturnType<typeof useOrganizationTagsQuery>;
export type OrganizationTagsLazyQueryHookResult = ReturnType<typeof useOrganizationTagsLazyQuery>;
export type OrganizationTagsQueryResult = Apollo.QueryResult<
  OrganizationTagsQuery,
  OrganizationTagsQueryVariables
>;
export const OrganizationTeamsDocument = gql`
  query organizationTeams($organizationId: String!) {
    organizationTeams(organizationId: $organizationId) {
      archived
      id
      name
      organization {
        allowConnectThirdPartyCalendar
        contactMailAddress
        contractPreriod
        id
        imageUrl
        ipRestrictionFlg
        name
        plan {
          code
          contractPreriod
          id
        }
        requiredTwoFactorAuth
        suspensionFlg
        versionNo
      }
      personalTeam
      status
      versionNo
    }
  }
`;

/**
 * __useOrganizationTeamsQuery__
 *
 * To run a query within a React component, call `useOrganizationTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationTeamsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationTeamsQuery(
  baseOptions: Apollo.QueryHookOptions<OrganizationTeamsQuery, OrganizationTeamsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrganizationTeamsQuery, OrganizationTeamsQueryVariables>(
    OrganizationTeamsDocument,
    options
  );
}
export function useOrganizationTeamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrganizationTeamsQuery, OrganizationTeamsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrganizationTeamsQuery, OrganizationTeamsQueryVariables>(
    OrganizationTeamsDocument,
    options
  );
}
export type OrganizationTeamsQueryHookResult = ReturnType<typeof useOrganizationTeamsQuery>;
export type OrganizationTeamsLazyQueryHookResult = ReturnType<typeof useOrganizationTeamsLazyQuery>;
export type OrganizationTeamsQueryResult = Apollo.QueryResult<
  OrganizationTeamsQuery,
  OrganizationTeamsQueryVariables
>;
export const OrganizationTeamsWithoutPersonalTeamDocument = gql`
  query organizationTeamsWithoutPersonalTeam($organizationId: String!) {
    organizationTeamsWithoutPersonalTeam(organizationId: $organizationId) {
      archived
      id
      name
      organization {
        allowConnectThirdPartyCalendar
        contactMailAddress
        contractPreriod
        id
        imageUrl
        ipRestrictionFlg
        name
        plan {
          code
          contractPreriod
          id
        }
        requiredTwoFactorAuth
        suspensionFlg
        versionNo
      }
      personalTeam
      status
      versionNo
    }
  }
`;

/**
 * __useOrganizationTeamsWithoutPersonalTeamQuery__
 *
 * To run a query within a React component, call `useOrganizationTeamsWithoutPersonalTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationTeamsWithoutPersonalTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationTeamsWithoutPersonalTeamQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationTeamsWithoutPersonalTeamQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrganizationTeamsWithoutPersonalTeamQuery,
    OrganizationTeamsWithoutPersonalTeamQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrganizationTeamsWithoutPersonalTeamQuery,
    OrganizationTeamsWithoutPersonalTeamQueryVariables
  >(OrganizationTeamsWithoutPersonalTeamDocument, options);
}
export function useOrganizationTeamsWithoutPersonalTeamLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationTeamsWithoutPersonalTeamQuery,
    OrganizationTeamsWithoutPersonalTeamQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrganizationTeamsWithoutPersonalTeamQuery,
    OrganizationTeamsWithoutPersonalTeamQueryVariables
  >(OrganizationTeamsWithoutPersonalTeamDocument, options);
}
export type OrganizationTeamsWithoutPersonalTeamQueryHookResult = ReturnType<
  typeof useOrganizationTeamsWithoutPersonalTeamQuery
>;
export type OrganizationTeamsWithoutPersonalTeamLazyQueryHookResult = ReturnType<
  typeof useOrganizationTeamsWithoutPersonalTeamLazyQuery
>;
export type OrganizationTeamsWithoutPersonalTeamQueryResult = Apollo.QueryResult<
  OrganizationTeamsWithoutPersonalTeamQuery,
  OrganizationTeamsWithoutPersonalTeamQueryVariables
>;
export const OrganizationsDocument = gql`
  query organizations($tokens: [String]) {
    organizations(tokens: $tokens) {
      allowConnectThirdPartyCalendar
      contactMailAddress
      contractPreriod
      id
      imageUrl
      ipRestrictionFlg
      name
      plan {
        code
        contractPreriod
        id
      }
      requiredTwoFactorAuth
      suspensionFlg
      versionNo
    }
  }
`;

/**
 * __useOrganizationsQuery__
 *
 * To run a query within a React component, call `useOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationsQuery({
 *   variables: {
 *      tokens: // value for 'tokens'
 *   },
 * });
 */
export function useOrganizationsQuery(
  baseOptions?: Apollo.QueryHookOptions<OrganizationsQuery, OrganizationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrganizationsQuery, OrganizationsQueryVariables>(
    OrganizationsDocument,
    options
  );
}
export function useOrganizationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrganizationsQuery, OrganizationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrganizationsQuery, OrganizationsQueryVariables>(
    OrganizationsDocument,
    options
  );
}
export type OrganizationsQueryHookResult = ReturnType<typeof useOrganizationsQuery>;
export type OrganizationsLazyQueryHookResult = ReturnType<typeof useOrganizationsLazyQuery>;
export type OrganizationsQueryResult = Apollo.QueryResult<
  OrganizationsQuery,
  OrganizationsQueryVariables
>;
export const PasswordResetRequestDocument = gql`
  query passwordResetRequest($key: String!) {
    passwordResetRequest(key: $key) {
      password_reset_key
    }
  }
`;

/**
 * __usePasswordResetRequestQuery__
 *
 * To run a query within a React component, call `usePasswordResetRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `usePasswordResetRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePasswordResetRequestQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function usePasswordResetRequestQuery(
  baseOptions: Apollo.QueryHookOptions<
    PasswordResetRequestQuery,
    PasswordResetRequestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PasswordResetRequestQuery, PasswordResetRequestQueryVariables>(
    PasswordResetRequestDocument,
    options
  );
}
export function usePasswordResetRequestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PasswordResetRequestQuery,
    PasswordResetRequestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PasswordResetRequestQuery, PasswordResetRequestQueryVariables>(
    PasswordResetRequestDocument,
    options
  );
}
export type PasswordResetRequestQueryHookResult = ReturnType<typeof usePasswordResetRequestQuery>;
export type PasswordResetRequestLazyQueryHookResult = ReturnType<
  typeof usePasswordResetRequestLazyQuery
>;
export type PasswordResetRequestQueryResult = Apollo.QueryResult<
  PasswordResetRequestQuery,
  PasswordResetRequestQueryVariables
>;
export const PaymentSecretDocument = gql`
  query paymentSecret {
    paymentSecret
  }
`;

/**
 * __usePaymentSecretQuery__
 *
 * To run a query within a React component, call `usePaymentSecretQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentSecretQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentSecretQuery({
 *   variables: {
 *   },
 * });
 */
export function usePaymentSecretQuery(
  baseOptions?: Apollo.QueryHookOptions<PaymentSecretQuery, PaymentSecretQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaymentSecretQuery, PaymentSecretQueryVariables>(
    PaymentSecretDocument,
    options
  );
}
export function usePaymentSecretLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaymentSecretQuery, PaymentSecretQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaymentSecretQuery, PaymentSecretQueryVariables>(
    PaymentSecretDocument,
    options
  );
}
export type PaymentSecretQueryHookResult = ReturnType<typeof usePaymentSecretQuery>;
export type PaymentSecretLazyQueryHookResult = ReturnType<typeof usePaymentSecretLazyQuery>;
export type PaymentSecretQueryResult = Apollo.QueryResult<
  PaymentSecretQuery,
  PaymentSecretQueryVariables
>;
export const PlanProductDocument = gql`
  query planProduct($id: ID!) {
    planProduct(id: $id) {
      code
      contractPreriod
      id
    }
  }
`;

/**
 * __usePlanProductQuery__
 *
 * To run a query within a React component, call `usePlanProductQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePlanProductQuery(
  baseOptions: Apollo.QueryHookOptions<PlanProductQuery, PlanProductQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PlanProductQuery, PlanProductQueryVariables>(PlanProductDocument, options);
}
export function usePlanProductLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PlanProductQuery, PlanProductQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PlanProductQuery, PlanProductQueryVariables>(
    PlanProductDocument,
    options
  );
}
export type PlanProductQueryHookResult = ReturnType<typeof usePlanProductQuery>;
export type PlanProductLazyQueryHookResult = ReturnType<typeof usePlanProductLazyQuery>;
export type PlanProductQueryResult = Apollo.QueryResult<
  PlanProductQuery,
  PlanProductQueryVariables
>;
export const PlanProductsMonthlyDocument = gql`
  query planProductsMonthly {
    planProductsMonthly {
      code
      contractPreriod
      id
    }
  }
`;

/**
 * __usePlanProductsMonthlyQuery__
 *
 * To run a query within a React component, call `usePlanProductsMonthlyQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanProductsMonthlyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanProductsMonthlyQuery({
 *   variables: {
 *   },
 * });
 */
export function usePlanProductsMonthlyQuery(
  baseOptions?: Apollo.QueryHookOptions<PlanProductsMonthlyQuery, PlanProductsMonthlyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PlanProductsMonthlyQuery, PlanProductsMonthlyQueryVariables>(
    PlanProductsMonthlyDocument,
    options
  );
}
export function usePlanProductsMonthlyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PlanProductsMonthlyQuery,
    PlanProductsMonthlyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PlanProductsMonthlyQuery, PlanProductsMonthlyQueryVariables>(
    PlanProductsMonthlyDocument,
    options
  );
}
export type PlanProductsMonthlyQueryHookResult = ReturnType<typeof usePlanProductsMonthlyQuery>;
export type PlanProductsMonthlyLazyQueryHookResult = ReturnType<
  typeof usePlanProductsMonthlyLazyQuery
>;
export type PlanProductsMonthlyQueryResult = Apollo.QueryResult<
  PlanProductsMonthlyQuery,
  PlanProductsMonthlyQueryVariables
>;
export const PlanProductsYearlyDocument = gql`
  query planProductsYearly {
    planProductsYearly {
      code
      contractPreriod
      id
    }
  }
`;

/**
 * __usePlanProductsYearlyQuery__
 *
 * To run a query within a React component, call `usePlanProductsYearlyQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanProductsYearlyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanProductsYearlyQuery({
 *   variables: {
 *   },
 * });
 */
export function usePlanProductsYearlyQuery(
  baseOptions?: Apollo.QueryHookOptions<PlanProductsYearlyQuery, PlanProductsYearlyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PlanProductsYearlyQuery, PlanProductsYearlyQueryVariables>(
    PlanProductsYearlyDocument,
    options
  );
}
export function usePlanProductsYearlyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PlanProductsYearlyQuery,
    PlanProductsYearlyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PlanProductsYearlyQuery, PlanProductsYearlyQueryVariables>(
    PlanProductsYearlyDocument,
    options
  );
}
export type PlanProductsYearlyQueryHookResult = ReturnType<typeof usePlanProductsYearlyQuery>;
export type PlanProductsYearlyLazyQueryHookResult = ReturnType<
  typeof usePlanProductsYearlyLazyQuery
>;
export type PlanProductsYearlyQueryResult = Apollo.QueryResult<
  PlanProductsYearlyQuery,
  PlanProductsYearlyQueryVariables
>;
export const ProjectDocument = gql`
  query project($id: ID!) {
    project(id: $id) {
      assignees {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      calendarLinkageCode
      client {
        code
        id
        name
        sortNo
        versionNo
      }
      color
      complete
      completeDateTime
      costBudgetWithoutTax
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      favoriteMembers {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      favoriteMembersInfo {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      id
      key
      laborCost
      laborCostWithoutTax
      laborShare
      name
      priority
      projectStatus {
        endStatus
        id
        name
        sortNo
        teamId
        versionNo
      }
      registerDateTime
      salesAmountWithoutTax
      scheduledEndDateTime
      scheduledStartDateTime
      sortNoInGanttChart
      sortNoInList
      sortNoInProjectStatus
      team {
        archived
        id
        name
        organization {
          allowConnectThirdPartyCalendar
          contactMailAddress
          contractPreriod
          id
          imageUrl
          ipRestrictionFlg
          name
          plan {
            code
            contractPreriod
            id
          }
          requiredTwoFactorAuth
          suspensionFlg
          versionNo
        }
        personalTeam
        status
        versionNo
      }
      totalScheduleTimeSec
      versionNo
      workingTimeSec
    }
  }
`;

/**
 * __useProjectQuery__
 *
 * To run a query within a React component, call `useProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectQuery(
  baseOptions: Apollo.QueryHookOptions<ProjectQuery, ProjectQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, options);
}
export function useProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProjectQuery, ProjectQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, options);
}
export type ProjectQueryHookResult = ReturnType<typeof useProjectQuery>;
export type ProjectLazyQueryHookResult = ReturnType<typeof useProjectLazyQuery>;
export type ProjectQueryResult = Apollo.QueryResult<ProjectQuery, ProjectQueryVariables>;
export const ProjectAttachmentFilesDocument = gql`
  query projectAttachmentFiles($projectId: String!) {
    projectAttachmentFiles(projectId: $projectId) {
      fileName
      id
      sizeByte
      type
      uploadDateTime
    }
  }
`;

/**
 * __useProjectAttachmentFilesQuery__
 *
 * To run a query within a React component, call `useProjectAttachmentFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectAttachmentFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectAttachmentFilesQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectAttachmentFilesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProjectAttachmentFilesQuery,
    ProjectAttachmentFilesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectAttachmentFilesQuery, ProjectAttachmentFilesQueryVariables>(
    ProjectAttachmentFilesDocument,
    options
  );
}
export function useProjectAttachmentFilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectAttachmentFilesQuery,
    ProjectAttachmentFilesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectAttachmentFilesQuery, ProjectAttachmentFilesQueryVariables>(
    ProjectAttachmentFilesDocument,
    options
  );
}
export type ProjectAttachmentFilesQueryHookResult = ReturnType<
  typeof useProjectAttachmentFilesQuery
>;
export type ProjectAttachmentFilesLazyQueryHookResult = ReturnType<
  typeof useProjectAttachmentFilesLazyQuery
>;
export type ProjectAttachmentFilesQueryResult = Apollo.QueryResult<
  ProjectAttachmentFilesQuery,
  ProjectAttachmentFilesQueryVariables
>;
export const ProjectBarGraphDataDocument = gql`
  query projectBarGraphData($input: ProjectGraphDataInputInput!) {
    projectBarGraphData(input: $input) {
      color
      percent
      projectId
      projectName
      reportType
      teamId
      teamName
      workingTimeSec
    }
  }
`;

/**
 * __useProjectBarGraphDataQuery__
 *
 * To run a query within a React component, call `useProjectBarGraphDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectBarGraphDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectBarGraphDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProjectBarGraphDataQuery(
  baseOptions: Apollo.QueryHookOptions<ProjectBarGraphDataQuery, ProjectBarGraphDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectBarGraphDataQuery, ProjectBarGraphDataQueryVariables>(
    ProjectBarGraphDataDocument,
    options
  );
}
export function useProjectBarGraphDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectBarGraphDataQuery,
    ProjectBarGraphDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectBarGraphDataQuery, ProjectBarGraphDataQueryVariables>(
    ProjectBarGraphDataDocument,
    options
  );
}
export type ProjectBarGraphDataQueryHookResult = ReturnType<typeof useProjectBarGraphDataQuery>;
export type ProjectBarGraphDataLazyQueryHookResult = ReturnType<
  typeof useProjectBarGraphDataLazyQuery
>;
export type ProjectBarGraphDataQueryResult = Apollo.QueryResult<
  ProjectBarGraphDataQuery,
  ProjectBarGraphDataQueryVariables
>;
export const ProjectByKeyDocument = gql`
  query projectByKey($key: String!) {
    projectByKey(key: $key) {
      assignees {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      calendarLinkageCode
      client {
        code
        id
        name
        sortNo
        versionNo
      }
      color
      complete
      completeDateTime
      costBudgetWithoutTax
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      favoriteMembers {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      favoriteMembersInfo {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      id
      key
      laborCost
      laborCostWithoutTax
      laborShare
      name
      priority
      projectStatus {
        endStatus
        id
        name
        sortNo
        teamId
        versionNo
      }
      registerDateTime
      salesAmountWithoutTax
      scheduledEndDateTime
      scheduledStartDateTime
      sortNoInGanttChart
      sortNoInList
      sortNoInProjectStatus
      team {
        archived
        id
        name
        organization {
          allowConnectThirdPartyCalendar
          contactMailAddress
          contractPreriod
          id
          imageUrl
          ipRestrictionFlg
          name
          plan {
            code
            contractPreriod
            id
          }
          requiredTwoFactorAuth
          suspensionFlg
          versionNo
        }
        personalTeam
        status
        versionNo
      }
      totalScheduleTimeSec
      versionNo
      workingTimeSec
    }
  }
`;

/**
 * __useProjectByKeyQuery__
 *
 * To run a query within a React component, call `useProjectByKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectByKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectByKeyQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useProjectByKeyQuery(
  baseOptions: Apollo.QueryHookOptions<ProjectByKeyQuery, ProjectByKeyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectByKeyQuery, ProjectByKeyQueryVariables>(
    ProjectByKeyDocument,
    options
  );
}
export function useProjectByKeyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProjectByKeyQuery, ProjectByKeyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectByKeyQuery, ProjectByKeyQueryVariables>(
    ProjectByKeyDocument,
    options
  );
}
export type ProjectByKeyQueryHookResult = ReturnType<typeof useProjectByKeyQuery>;
export type ProjectByKeyLazyQueryHookResult = ReturnType<typeof useProjectByKeyLazyQuery>;
export type ProjectByKeyQueryResult = Apollo.QueryResult<
  ProjectByKeyQuery,
  ProjectByKeyQueryVariables
>;
export const ProjectCalendarEventsDocument = gql`
  query projectCalendarEvents($limit: Int, $offset: Int, $projectId: String!) {
    projectCalendarEvents(limit: $limit, offset: $offset, projectId: $projectId) {
      calendarType
      clientId
      clientName
      endDateTime
      eventName
      id
      laborCost
      personalTeam
      projectColor
      projectId
      projectName
      startDateTime
      taskId
      taskTitle
      teamId
      teamName
      thirdPartyCalendarEventId
      thirdPartyCalendarInfoList {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        thirdPartyCalendarId
      }
      versionNo
      workingTimeSec
    }
  }
`;

/**
 * __useProjectCalendarEventsQuery__
 *
 * To run a query within a React component, call `useProjectCalendarEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectCalendarEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectCalendarEventsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectCalendarEventsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProjectCalendarEventsQuery,
    ProjectCalendarEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectCalendarEventsQuery, ProjectCalendarEventsQueryVariables>(
    ProjectCalendarEventsDocument,
    options
  );
}
export function useProjectCalendarEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectCalendarEventsQuery,
    ProjectCalendarEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectCalendarEventsQuery, ProjectCalendarEventsQueryVariables>(
    ProjectCalendarEventsDocument,
    options
  );
}
export type ProjectCalendarEventsQueryHookResult = ReturnType<typeof useProjectCalendarEventsQuery>;
export type ProjectCalendarEventsLazyQueryHookResult = ReturnType<
  typeof useProjectCalendarEventsLazyQuery
>;
export type ProjectCalendarEventsQueryResult = Apollo.QueryResult<
  ProjectCalendarEventsQuery,
  ProjectCalendarEventsQueryVariables
>;
export const ProjectContractDocument = gql`
  query projectContract($id: String) {
    projectContract(id: $id) {
      allMemberMeteredRateContract {
        hourUnitPrice
        id
        member {
          laborCostWithoutTax
          memberId
          name
          profileImageUrl
          roundedTotalWorkingTimeSec
          salesAmountWithoutTax
          totalWorkingTimeSec
        }
        versionNo
      }
      allMemberSettlementRangeContract {
        id
        lowerHourPart {
          deducationHourlyUnitPrice
          lowerHour
        }
        member {
          laborCostWithoutTax
          memberId
          name
          profileImageUrl
          roundedTotalWorkingTimeSec
          salesAmountWithoutTax
          totalWorkingTimeSec
        }
        monthlyUnitPrice
        upperHourPart {
          excessHourlyUnitPrice
          upperHour
        }
        versionNo
      }
      contractType
      costBudgetWithoutTax
      endDateTime
      fixSalesAmountWithoutTax
      grossProfit
      grossProfitRatePercent
      id
      laborCostWithoutTax
      laborShare
      membersMeteredRateContract {
        hourUnitPrice
        id
        member {
          laborCostWithoutTax
          memberId
          name
          profileImageUrl
          roundedTotalWorkingTimeSec
          salesAmountWithoutTax
          totalWorkingTimeSec
        }
        versionNo
      }
      membersSettlementRangeContract {
        id
        lowerHourPart {
          deducationHourlyUnitPrice
          lowerHour
        }
        member {
          laborCostWithoutTax
          memberId
          name
          profileImageUrl
          roundedTotalWorkingTimeSec
          salesAmountWithoutTax
          totalWorkingTimeSec
        }
        monthlyUnitPrice
        upperHourPart {
          excessHourlyUnitPrice
          upperHour
        }
        versionNo
      }
      month
      project {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        calendarLinkageCode
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        color
        complete
        completeDateTime
        costBudgetWithoutTax
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        key
        laborCost
        laborCostWithoutTax
        laborShare
        name
        priority
        projectStatus {
          endStatus
          id
          name
          sortNo
          teamId
          versionNo
        }
        registerDateTime
        salesAmountWithoutTax
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInProjectStatus
        team {
          archived
          id
          name
          organization {
            allowConnectThirdPartyCalendar
            contactMailAddress
            contractPreriod
            id
            imageUrl
            ipRestrictionFlg
            name
            plan {
              code
              contractPreriod
              id
            }
            requiredTwoFactorAuth
            suspensionFlg
            versionNo
          }
          personalTeam
          status
          versionNo
        }
        totalScheduleTimeSec
        versionNo
        workingTimeSec
      }
      roundTimeType
      roundedTotalWorkingTimeSec
      salesAmountWithoutTax
      salesRoundMinutes
      startDateTime
      taxRate
      termType
      timeZone {
        code
        id
        name
        offsetTime
        sortNo
      }
      totalWorkingTimeSec
      unitPriceType
      versionNo
      year
    }
  }
`;

/**
 * __useProjectContractQuery__
 *
 * To run a query within a React component, call `useProjectContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectContractQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectContractQuery(
  baseOptions?: Apollo.QueryHookOptions<ProjectContractQuery, ProjectContractQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectContractQuery, ProjectContractQueryVariables>(
    ProjectContractDocument,
    options
  );
}
export function useProjectContractLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProjectContractQuery, ProjectContractQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectContractQuery, ProjectContractQueryVariables>(
    ProjectContractDocument,
    options
  );
}
export type ProjectContractQueryHookResult = ReturnType<typeof useProjectContractQuery>;
export type ProjectContractLazyQueryHookResult = ReturnType<typeof useProjectContractLazyQuery>;
export type ProjectContractQueryResult = Apollo.QueryResult<
  ProjectContractQuery,
  ProjectContractQueryVariables
>;
export const ProjectContractsByProjectIdDocument = gql`
  query projectContractsByProjectId($projectId: String) {
    projectContractsByProjectId(projectId: $projectId) {
      allMemberMeteredRateContract {
        hourUnitPrice
        id
        member {
          laborCostWithoutTax
          memberId
          name
          profileImageUrl
          roundedTotalWorkingTimeSec
          salesAmountWithoutTax
          totalWorkingTimeSec
        }
        versionNo
      }
      allMemberSettlementRangeContract {
        id
        lowerHourPart {
          deducationHourlyUnitPrice
          lowerHour
        }
        member {
          laborCostWithoutTax
          memberId
          name
          profileImageUrl
          roundedTotalWorkingTimeSec
          salesAmountWithoutTax
          totalWorkingTimeSec
        }
        monthlyUnitPrice
        upperHourPart {
          excessHourlyUnitPrice
          upperHour
        }
        versionNo
      }
      contractType
      costBudgetWithoutTax
      endDateTime
      fixSalesAmountWithoutTax
      grossProfit
      grossProfitRatePercent
      id
      laborCostWithoutTax
      laborShare
      membersMeteredRateContract {
        hourUnitPrice
        id
        member {
          laborCostWithoutTax
          memberId
          name
          profileImageUrl
          roundedTotalWorkingTimeSec
          salesAmountWithoutTax
          totalWorkingTimeSec
        }
        versionNo
      }
      membersSettlementRangeContract {
        id
        lowerHourPart {
          deducationHourlyUnitPrice
          lowerHour
        }
        member {
          laborCostWithoutTax
          memberId
          name
          profileImageUrl
          roundedTotalWorkingTimeSec
          salesAmountWithoutTax
          totalWorkingTimeSec
        }
        monthlyUnitPrice
        upperHourPart {
          excessHourlyUnitPrice
          upperHour
        }
        versionNo
      }
      month
      project {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        calendarLinkageCode
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        color
        complete
        completeDateTime
        costBudgetWithoutTax
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        key
        laborCost
        laborCostWithoutTax
        laborShare
        name
        priority
        projectStatus {
          endStatus
          id
          name
          sortNo
          teamId
          versionNo
        }
        registerDateTime
        salesAmountWithoutTax
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInProjectStatus
        team {
          archived
          id
          name
          organization {
            allowConnectThirdPartyCalendar
            contactMailAddress
            contractPreriod
            id
            imageUrl
            ipRestrictionFlg
            name
            plan {
              code
              contractPreriod
              id
            }
            requiredTwoFactorAuth
            suspensionFlg
            versionNo
          }
          personalTeam
          status
          versionNo
        }
        totalScheduleTimeSec
        versionNo
        workingTimeSec
      }
      roundTimeType
      roundedTotalWorkingTimeSec
      salesAmountWithoutTax
      salesRoundMinutes
      startDateTime
      taxRate
      termType
      timeZone {
        code
        id
        name
        offsetTime
        sortNo
      }
      totalWorkingTimeSec
      unitPriceType
      versionNo
      year
    }
  }
`;

/**
 * __useProjectContractsByProjectIdQuery__
 *
 * To run a query within a React component, call `useProjectContractsByProjectIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectContractsByProjectIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectContractsByProjectIdQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectContractsByProjectIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProjectContractsByProjectIdQuery,
    ProjectContractsByProjectIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProjectContractsByProjectIdQuery,
    ProjectContractsByProjectIdQueryVariables
  >(ProjectContractsByProjectIdDocument, options);
}
export function useProjectContractsByProjectIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectContractsByProjectIdQuery,
    ProjectContractsByProjectIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectContractsByProjectIdQuery,
    ProjectContractsByProjectIdQueryVariables
  >(ProjectContractsByProjectIdDocument, options);
}
export type ProjectContractsByProjectIdQueryHookResult = ReturnType<
  typeof useProjectContractsByProjectIdQuery
>;
export type ProjectContractsByProjectIdLazyQueryHookResult = ReturnType<
  typeof useProjectContractsByProjectIdLazyQuery
>;
export type ProjectContractsByProjectIdQueryResult = Apollo.QueryResult<
  ProjectContractsByProjectIdQuery,
  ProjectContractsByProjectIdQueryVariables
>;
export const ProjectCustomAttributeDocument = gql`
  query projectCustomAttribute($id: ID!) {
    projectCustomAttribute(id: $id) {
      id
      listItem {
        id
        sortNo
        value
        versionNo
      }
      master {
        id
        listItem {
          id
          sortNo
          value
          versionNo
        }
        name
        required
        sortNo
        type
        versionNo
      }
      sortNo
      value
    }
  }
`;

/**
 * __useProjectCustomAttributeQuery__
 *
 * To run a query within a React component, call `useProjectCustomAttributeQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectCustomAttributeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectCustomAttributeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectCustomAttributeQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProjectCustomAttributeQuery,
    ProjectCustomAttributeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectCustomAttributeQuery, ProjectCustomAttributeQueryVariables>(
    ProjectCustomAttributeDocument,
    options
  );
}
export function useProjectCustomAttributeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectCustomAttributeQuery,
    ProjectCustomAttributeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectCustomAttributeQuery, ProjectCustomAttributeQueryVariables>(
    ProjectCustomAttributeDocument,
    options
  );
}
export type ProjectCustomAttributeQueryHookResult = ReturnType<
  typeof useProjectCustomAttributeQuery
>;
export type ProjectCustomAttributeLazyQueryHookResult = ReturnType<
  typeof useProjectCustomAttributeLazyQuery
>;
export type ProjectCustomAttributeQueryResult = Apollo.QueryResult<
  ProjectCustomAttributeQuery,
  ProjectCustomAttributeQueryVariables
>;
export const ProjectCustomAttributeMasterDocument = gql`
  query projectCustomAttributeMaster($id: ID!) {
    projectCustomAttributeMaster(id: $id) {
      id
      listItem {
        id
        sortNo
        value
        versionNo
      }
      name
      required
      sortNo
      type
      versionNo
    }
  }
`;

/**
 * __useProjectCustomAttributeMasterQuery__
 *
 * To run a query within a React component, call `useProjectCustomAttributeMasterQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectCustomAttributeMasterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectCustomAttributeMasterQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectCustomAttributeMasterQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProjectCustomAttributeMasterQuery,
    ProjectCustomAttributeMasterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProjectCustomAttributeMasterQuery,
    ProjectCustomAttributeMasterQueryVariables
  >(ProjectCustomAttributeMasterDocument, options);
}
export function useProjectCustomAttributeMasterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectCustomAttributeMasterQuery,
    ProjectCustomAttributeMasterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectCustomAttributeMasterQuery,
    ProjectCustomAttributeMasterQueryVariables
  >(ProjectCustomAttributeMasterDocument, options);
}
export type ProjectCustomAttributeMasterQueryHookResult = ReturnType<
  typeof useProjectCustomAttributeMasterQuery
>;
export type ProjectCustomAttributeMasterLazyQueryHookResult = ReturnType<
  typeof useProjectCustomAttributeMasterLazyQuery
>;
export type ProjectCustomAttributeMasterQueryResult = Apollo.QueryResult<
  ProjectCustomAttributeMasterQuery,
  ProjectCustomAttributeMasterQueryVariables
>;
export const ProjectCustomAttributeMastersDocument = gql`
  query projectCustomAttributeMasters {
    projectCustomAttributeMasters {
      id
      listItem {
        id
        sortNo
        value
        versionNo
      }
      name
      required
      sortNo
      type
      versionNo
    }
  }
`;

/**
 * __useProjectCustomAttributeMastersQuery__
 *
 * To run a query within a React component, call `useProjectCustomAttributeMastersQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectCustomAttributeMastersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectCustomAttributeMastersQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectCustomAttributeMastersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProjectCustomAttributeMastersQuery,
    ProjectCustomAttributeMastersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProjectCustomAttributeMastersQuery,
    ProjectCustomAttributeMastersQueryVariables
  >(ProjectCustomAttributeMastersDocument, options);
}
export function useProjectCustomAttributeMastersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectCustomAttributeMastersQuery,
    ProjectCustomAttributeMastersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectCustomAttributeMastersQuery,
    ProjectCustomAttributeMastersQueryVariables
  >(ProjectCustomAttributeMastersDocument, options);
}
export type ProjectCustomAttributeMastersQueryHookResult = ReturnType<
  typeof useProjectCustomAttributeMastersQuery
>;
export type ProjectCustomAttributeMastersLazyQueryHookResult = ReturnType<
  typeof useProjectCustomAttributeMastersLazyQuery
>;
export type ProjectCustomAttributeMastersQueryResult = Apollo.QueryResult<
  ProjectCustomAttributeMastersQuery,
  ProjectCustomAttributeMastersQueryVariables
>;
export const ProjectCustomAttributeTypesDocument = gql`
  query projectCustomAttributeTypes {
    projectCustomAttributeTypes {
      code
      name
      sortNo
    }
  }
`;

/**
 * __useProjectCustomAttributeTypesQuery__
 *
 * To run a query within a React component, call `useProjectCustomAttributeTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectCustomAttributeTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectCustomAttributeTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectCustomAttributeTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProjectCustomAttributeTypesQuery,
    ProjectCustomAttributeTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProjectCustomAttributeTypesQuery,
    ProjectCustomAttributeTypesQueryVariables
  >(ProjectCustomAttributeTypesDocument, options);
}
export function useProjectCustomAttributeTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectCustomAttributeTypesQuery,
    ProjectCustomAttributeTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectCustomAttributeTypesQuery,
    ProjectCustomAttributeTypesQueryVariables
  >(ProjectCustomAttributeTypesDocument, options);
}
export type ProjectCustomAttributeTypesQueryHookResult = ReturnType<
  typeof useProjectCustomAttributeTypesQuery
>;
export type ProjectCustomAttributeTypesLazyQueryHookResult = ReturnType<
  typeof useProjectCustomAttributeTypesLazyQuery
>;
export type ProjectCustomAttributeTypesQueryResult = Apollo.QueryResult<
  ProjectCustomAttributeTypesQuery,
  ProjectCustomAttributeTypesQueryVariables
>;
export const ProjectCustomAttributesByProjectDocument = gql`
  query projectCustomAttributesByProject($projectId: String!) {
    projectCustomAttributesByProject(projectId: $projectId) {
      id
      listItem {
        id
        sortNo
        value
        versionNo
      }
      master {
        id
        listItem {
          id
          sortNo
          value
          versionNo
        }
        name
        required
        sortNo
        type
        versionNo
      }
      sortNo
      value
    }
  }
`;

/**
 * __useProjectCustomAttributesByProjectQuery__
 *
 * To run a query within a React component, call `useProjectCustomAttributesByProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectCustomAttributesByProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectCustomAttributesByProjectQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectCustomAttributesByProjectQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProjectCustomAttributesByProjectQuery,
    ProjectCustomAttributesByProjectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProjectCustomAttributesByProjectQuery,
    ProjectCustomAttributesByProjectQueryVariables
  >(ProjectCustomAttributesByProjectDocument, options);
}
export function useProjectCustomAttributesByProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectCustomAttributesByProjectQuery,
    ProjectCustomAttributesByProjectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectCustomAttributesByProjectQuery,
    ProjectCustomAttributesByProjectQueryVariables
  >(ProjectCustomAttributesByProjectDocument, options);
}
export type ProjectCustomAttributesByProjectQueryHookResult = ReturnType<
  typeof useProjectCustomAttributesByProjectQuery
>;
export type ProjectCustomAttributesByProjectLazyQueryHookResult = ReturnType<
  typeof useProjectCustomAttributesByProjectLazyQuery
>;
export type ProjectCustomAttributesByProjectQueryResult = Apollo.QueryResult<
  ProjectCustomAttributesByProjectQuery,
  ProjectCustomAttributesByProjectQueryVariables
>;
export const ProjectDateGraphDataDocument = gql`
  query projectDateGraphData($input: ProjectGraphDataInputInput!) {
    projectDateGraphData(input: $input) {
      date
      items {
        color
        percent
        projectId
        projectName
        teamId
        teamName
        workingTimeSec
      }
      reportType
    }
  }
`;

/**
 * __useProjectDateGraphDataQuery__
 *
 * To run a query within a React component, call `useProjectDateGraphDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectDateGraphDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectDateGraphDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProjectDateGraphDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProjectDateGraphDataQuery,
    ProjectDateGraphDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectDateGraphDataQuery, ProjectDateGraphDataQueryVariables>(
    ProjectDateGraphDataDocument,
    options
  );
}
export function useProjectDateGraphDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectDateGraphDataQuery,
    ProjectDateGraphDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectDateGraphDataQuery, ProjectDateGraphDataQueryVariables>(
    ProjectDateGraphDataDocument,
    options
  );
}
export type ProjectDateGraphDataQueryHookResult = ReturnType<typeof useProjectDateGraphDataQuery>;
export type ProjectDateGraphDataLazyQueryHookResult = ReturnType<
  typeof useProjectDateGraphDataLazyQuery
>;
export type ProjectDateGraphDataQueryResult = Apollo.QueryResult<
  ProjectDateGraphDataQuery,
  ProjectDateGraphDataQueryVariables
>;
export const ProjectMembersDocument = gql`
  query projectMembers($projectId: String!) {
    projectMembers(projectId: $projectId) {
      adminRole
      clientManagementRole
      department
      employeeNumber
      id
      laborCostManagementRole
      mailAddress
      memberManagementRole
      memberRegistrationDateTime
      memberStatus
      name
      organizationMemberViewRole
      organizationReportRole
      profileImageUrl
      projectCreatePermissionFlg
      projectCustomAttributePermissionFlg
      projectDeletePermissionFlg
      projectUpdatePermissionFlg
      proxyEditWorkingDataRole
      salesManagementRole
      statusMessage
      taskCreatePermissionFlg
      taskCustomAttributePermissionFlg
      taskDeletePermissionFlg
      taskUpdatePermissionFlg
      teamMemberViewRole
      teamReportRole
      versionNo
    }
  }
`;

/**
 * __useProjectMembersQuery__
 *
 * To run a query within a React component, call `useProjectMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectMembersQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectMembersQuery(
  baseOptions: Apollo.QueryHookOptions<ProjectMembersQuery, ProjectMembersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectMembersQuery, ProjectMembersQueryVariables>(
    ProjectMembersDocument,
    options
  );
}
export function useProjectMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProjectMembersQuery, ProjectMembersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectMembersQuery, ProjectMembersQueryVariables>(
    ProjectMembersDocument,
    options
  );
}
export type ProjectMembersQueryHookResult = ReturnType<typeof useProjectMembersQuery>;
export type ProjectMembersLazyQueryHookResult = ReturnType<typeof useProjectMembersLazyQuery>;
export type ProjectMembersQueryResult = Apollo.QueryResult<
  ProjectMembersQuery,
  ProjectMembersQueryVariables
>;
export const ProjectStatusDocument = gql`
  query projectStatus($id: String!) {
    projectStatus(id: $id) {
      endStatus
      id
      name
      sortNo
      teamId
      versionNo
    }
  }
`;

/**
 * __useProjectStatusQuery__
 *
 * To run a query within a React component, call `useProjectStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectStatusQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectStatusQuery(
  baseOptions: Apollo.QueryHookOptions<ProjectStatusQuery, ProjectStatusQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectStatusQuery, ProjectStatusQueryVariables>(
    ProjectStatusDocument,
    options
  );
}
export function useProjectStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProjectStatusQuery, ProjectStatusQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectStatusQuery, ProjectStatusQueryVariables>(
    ProjectStatusDocument,
    options
  );
}
export type ProjectStatusQueryHookResult = ReturnType<typeof useProjectStatusQuery>;
export type ProjectStatusLazyQueryHookResult = ReturnType<typeof useProjectStatusLazyQuery>;
export type ProjectStatusQueryResult = Apollo.QueryResult<
  ProjectStatusQuery,
  ProjectStatusQueryVariables
>;
export const ProjectSunburstGraphDataDocument = gql`
  query projectSunburstGraphData($input: ProjectGraphDataInputInput!) {
    projectSunburstGraphData(input: $input) {
      children {
        color
        name
        percent
        projectId
        workingTimeSec
      }
      color
      name
      reportType
      teamId
    }
  }
`;

/**
 * __useProjectSunburstGraphDataQuery__
 *
 * To run a query within a React component, call `useProjectSunburstGraphDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectSunburstGraphDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectSunburstGraphDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProjectSunburstGraphDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProjectSunburstGraphDataQuery,
    ProjectSunburstGraphDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectSunburstGraphDataQuery, ProjectSunburstGraphDataQueryVariables>(
    ProjectSunburstGraphDataDocument,
    options
  );
}
export function useProjectSunburstGraphDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectSunburstGraphDataQuery,
    ProjectSunburstGraphDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectSunburstGraphDataQuery, ProjectSunburstGraphDataQueryVariables>(
    ProjectSunburstGraphDataDocument,
    options
  );
}
export type ProjectSunburstGraphDataQueryHookResult = ReturnType<
  typeof useProjectSunburstGraphDataQuery
>;
export type ProjectSunburstGraphDataLazyQueryHookResult = ReturnType<
  typeof useProjectSunburstGraphDataLazyQuery
>;
export type ProjectSunburstGraphDataQueryResult = Apollo.QueryResult<
  ProjectSunburstGraphDataQuery,
  ProjectSunburstGraphDataQueryVariables
>;
export const ProjectTaskStatusDocument = gql`
  query projectTaskStatus($projectId: String!) {
    projectTaskStatus(projectId: $projectId) {
      endStatus
      id
      name
      project {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        calendarLinkageCode
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        color
        complete
        completeDateTime
        costBudgetWithoutTax
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        key
        laborCost
        laborCostWithoutTax
        laborShare
        name
        priority
        projectStatus {
          endStatus
          id
          name
          sortNo
          teamId
          versionNo
        }
        registerDateTime
        salesAmountWithoutTax
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInProjectStatus
        team {
          archived
          id
          name
          organization {
            allowConnectThirdPartyCalendar
            contactMailAddress
            contractPreriod
            id
            imageUrl
            ipRestrictionFlg
            name
            plan {
              code
              contractPreriod
              id
            }
            requiredTwoFactorAuth
            suspensionFlg
            versionNo
          }
          personalTeam
          status
          versionNo
        }
        totalScheduleTimeSec
        versionNo
        workingTimeSec
      }
      sortNo
      versionNo
    }
  }
`;

/**
 * __useProjectTaskStatusQuery__
 *
 * To run a query within a React component, call `useProjectTaskStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTaskStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTaskStatusQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectTaskStatusQuery(
  baseOptions: Apollo.QueryHookOptions<ProjectTaskStatusQuery, ProjectTaskStatusQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectTaskStatusQuery, ProjectTaskStatusQueryVariables>(
    ProjectTaskStatusDocument,
    options
  );
}
export function useProjectTaskStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProjectTaskStatusQuery, ProjectTaskStatusQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectTaskStatusQuery, ProjectTaskStatusQueryVariables>(
    ProjectTaskStatusDocument,
    options
  );
}
export type ProjectTaskStatusQueryHookResult = ReturnType<typeof useProjectTaskStatusQuery>;
export type ProjectTaskStatusLazyQueryHookResult = ReturnType<typeof useProjectTaskStatusLazyQuery>;
export type ProjectTaskStatusQueryResult = Apollo.QueryResult<
  ProjectTaskStatusQuery,
  ProjectTaskStatusQueryVariables
>;
export const ProjectTasksDocument = gql`
  query projectTasks($input: FetchProjectTasksInputInput, $projectId: String!) {
    projectTasks(input: $input, projectId: $projectId) {
      assignees {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      asssignee {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      calendarLinkageCode
      completeDateTime
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      estimatedRemainingTimeSec
      favoriteMembers {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      favoriteMembersInfo {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      id
      laborCost
      priority
      progressDeviationRate
      progressRate
      project {
        assignees {
          sortNo
        }
        calendarLinkageCode
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        color
        complete
        completeDateTime
        costBudgetWithoutTax
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          sortNo
        }
        id
        key
        laborCost
        laborCostWithoutTax
        laborShare
        name
        priority
        projectStatus {
          endStatus
          id
          name
          sortNo
          teamId
          versionNo
        }
        registerDateTime
        salesAmountWithoutTax
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInProjectStatus
        team {
          archived
          id
          name
          organization {
            allowConnectThirdPartyCalendar
            contactMailAddress
            contractPreriod
            id
            imageUrl
            ipRestrictionFlg
            name
            plan {
              code
              contractPreriod
              id
            }
            requiredTwoFactorAuth
            suspensionFlg
            versionNo
          }
          personalTeam
          status
          versionNo
        }
        totalScheduleTimeSec
        versionNo
        workingTimeSec
      }
      registerDateTime
      remainingTimeSec
      scheduleWorkingTimeSec
      scheduledEndDateTime
      scheduledStartDateTime
      sortNoInGanttChart
      sortNoInList
      sortNoInMyTask
      sortNoInTaskStatus
      taskStatus {
        endStatus
        id
        name
        project {
          calendarLinkageCode
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          description
          estimateTimeSec
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        sortNo
        versionNo
      }
      title
      versionNo
      workingMembers {
        lastWorkingStartDateTime
        lastWorkingStopDateTime
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
      }
      workingTimeSec
    }
  }
`;

/**
 * __useProjectTasksQuery__
 *
 * To run a query within a React component, call `useProjectTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTasksQuery({
 *   variables: {
 *      input: // value for 'input'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectTasksQuery(
  baseOptions: Apollo.QueryHookOptions<ProjectTasksQuery, ProjectTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectTasksQuery, ProjectTasksQueryVariables>(
    ProjectTasksDocument,
    options
  );
}
export function useProjectTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProjectTasksQuery, ProjectTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectTasksQuery, ProjectTasksQueryVariables>(
    ProjectTasksDocument,
    options
  );
}
export type ProjectTasksQueryHookResult = ReturnType<typeof useProjectTasksQuery>;
export type ProjectTasksLazyQueryHookResult = ReturnType<typeof useProjectTasksLazyQuery>;
export type ProjectTasksQueryResult = Apollo.QueryResult<
  ProjectTasksQuery,
  ProjectTasksQueryVariables
>;
export const ProjectTasksEstimateTimeSecDocument = gql`
  query projectTasksEstimateTimeSec($id: ID!) {
    projectTasksEstimateTimeSec(id: $id)
  }
`;

/**
 * __useProjectTasksEstimateTimeSecQuery__
 *
 * To run a query within a React component, call `useProjectTasksEstimateTimeSecQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTasksEstimateTimeSecQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTasksEstimateTimeSecQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectTasksEstimateTimeSecQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProjectTasksEstimateTimeSecQuery,
    ProjectTasksEstimateTimeSecQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProjectTasksEstimateTimeSecQuery,
    ProjectTasksEstimateTimeSecQueryVariables
  >(ProjectTasksEstimateTimeSecDocument, options);
}
export function useProjectTasksEstimateTimeSecLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectTasksEstimateTimeSecQuery,
    ProjectTasksEstimateTimeSecQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectTasksEstimateTimeSecQuery,
    ProjectTasksEstimateTimeSecQueryVariables
  >(ProjectTasksEstimateTimeSecDocument, options);
}
export type ProjectTasksEstimateTimeSecQueryHookResult = ReturnType<
  typeof useProjectTasksEstimateTimeSecQuery
>;
export type ProjectTasksEstimateTimeSecLazyQueryHookResult = ReturnType<
  typeof useProjectTasksEstimateTimeSecLazyQuery
>;
export type ProjectTasksEstimateTimeSecQueryResult = Apollo.QueryResult<
  ProjectTasksEstimateTimeSecQuery,
  ProjectTasksEstimateTimeSecQueryVariables
>;
export const ProjectTasksForSelectorDocument = gql`
  query projectTasksForSelector($input: FetchProjectTasksInputInput, $projectId: String!) {
    projectTasksForSelector(input: $input, projectId: $projectId) {
      completeDateTime
      id
      projectId
      projectName
      title
    }
  }
`;

/**
 * __useProjectTasksForSelectorQuery__
 *
 * To run a query within a React component, call `useProjectTasksForSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTasksForSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTasksForSelectorQuery({
 *   variables: {
 *      input: // value for 'input'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectTasksForSelectorQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProjectTasksForSelectorQuery,
    ProjectTasksForSelectorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectTasksForSelectorQuery, ProjectTasksForSelectorQueryVariables>(
    ProjectTasksForSelectorDocument,
    options
  );
}
export function useProjectTasksForSelectorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectTasksForSelectorQuery,
    ProjectTasksForSelectorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectTasksForSelectorQuery, ProjectTasksForSelectorQueryVariables>(
    ProjectTasksForSelectorDocument,
    options
  );
}
export type ProjectTasksForSelectorQueryHookResult = ReturnType<
  typeof useProjectTasksForSelectorQuery
>;
export type ProjectTasksForSelectorLazyQueryHookResult = ReturnType<
  typeof useProjectTasksForSelectorLazyQuery
>;
export type ProjectTasksForSelectorQueryResult = Apollo.QueryResult<
  ProjectTasksForSelectorQuery,
  ProjectTasksForSelectorQueryVariables
>;
export const ProjectTasksGroupByStatusDocument = gql`
  query projectTasksGroupByStatus($projectId: String) {
    projectTasksGroupByStatus(projectId: $projectId) {
      taskStatus {
        endStatus
        id
        name
        project {
          assignees {
            member {
              adminRole
              clientManagementRole
              department
              employeeNumber
              id
              laborCostManagementRole
              mailAddress
              memberManagementRole
              memberRegistrationDateTime
              memberStatus
              name
              organizationMemberViewRole
              organizationReportRole
              profileImageUrl
              projectCreatePermissionFlg
              projectCustomAttributePermissionFlg
              projectDeletePermissionFlg
              projectUpdatePermissionFlg
              proxyEditWorkingDataRole
              salesManagementRole
              statusMessage
              taskCreatePermissionFlg
              taskCustomAttributePermissionFlg
              taskDeletePermissionFlg
              taskUpdatePermissionFlg
              teamMemberViewRole
              teamReportRole
              versionNo
            }
            sortNo
          }
          calendarLinkageCode
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          estimateTimeSec
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            member {
              adminRole
              clientManagementRole
              department
              employeeNumber
              id
              laborCostManagementRole
              mailAddress
              memberManagementRole
              memberRegistrationDateTime
              memberStatus
              name
              organizationMemberViewRole
              organizationReportRole
              profileImageUrl
              projectCreatePermissionFlg
              projectCustomAttributePermissionFlg
              projectDeletePermissionFlg
              projectUpdatePermissionFlg
              proxyEditWorkingDataRole
              salesManagementRole
              statusMessage
              taskCreatePermissionFlg
              taskCustomAttributePermissionFlg
              taskDeletePermissionFlg
              taskUpdatePermissionFlg
              teamMemberViewRole
              teamReportRole
              versionNo
            }
            sortNo
          }
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          projectStatus {
            endStatus
            id
            name
            sortNo
            teamId
            versionNo
          }
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          team {
            archived
            id
            name
            organization {
              allowConnectThirdPartyCalendar
              contactMailAddress
              contractPreriod
              id
              imageUrl
              ipRestrictionFlg
              name
              plan {
                code
                contractPreriod
                id
              }
              requiredTwoFactorAuth
              suspensionFlg
              versionNo
            }
            personalTeam
            status
            versionNo
          }
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        sortNo
        versionNo
      }
      tasks {
        assignees {
          sortNo
        }
        asssignee {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        calendarLinkageCode
        completeDateTime
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        estimatedRemainingTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          sortNo
        }
        id
        laborCost
        priority
        progressDeviationRate
        progressRate
        project {
          calendarLinkageCode
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          description
          estimateTimeSec
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        registerDateTime
        remainingTimeSec
        scheduleWorkingTimeSec
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInMyTask
        sortNoInTaskStatus
        taskStatus {
          endStatus
          id
          name
          sortNo
          versionNo
        }
        title
        versionNo
        workingMembers {
          lastWorkingStartDateTime
          lastWorkingStopDateTime
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
        }
        workingTimeSec
      }
    }
  }
`;

/**
 * __useProjectTasksGroupByStatusQuery__
 *
 * To run a query within a React component, call `useProjectTasksGroupByStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTasksGroupByStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTasksGroupByStatusQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectTasksGroupByStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProjectTasksGroupByStatusQuery,
    ProjectTasksGroupByStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectTasksGroupByStatusQuery, ProjectTasksGroupByStatusQueryVariables>(
    ProjectTasksGroupByStatusDocument,
    options
  );
}
export function useProjectTasksGroupByStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectTasksGroupByStatusQuery,
    ProjectTasksGroupByStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectTasksGroupByStatusQuery,
    ProjectTasksGroupByStatusQueryVariables
  >(ProjectTasksGroupByStatusDocument, options);
}
export type ProjectTasksGroupByStatusQueryHookResult = ReturnType<
  typeof useProjectTasksGroupByStatusQuery
>;
export type ProjectTasksGroupByStatusLazyQueryHookResult = ReturnType<
  typeof useProjectTasksGroupByStatusLazyQuery
>;
export type ProjectTasksGroupByStatusQueryResult = Apollo.QueryResult<
  ProjectTasksGroupByStatusQuery,
  ProjectTasksGroupByStatusQueryVariables
>;
export const ProjectTemplateDocument = gql`
  query projectTemplate($id: ID!) {
    projectTemplate(id: $id) {
      client {
        code
        id
        name
        sortNo
        versionNo
      }
      description
      estimateTimeSec
      id
      name
      priority
      sortNoInList
      versionNo
    }
  }
`;

/**
 * __useProjectTemplateQuery__
 *
 * To run a query within a React component, call `useProjectTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<ProjectTemplateQuery, ProjectTemplateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectTemplateQuery, ProjectTemplateQueryVariables>(
    ProjectTemplateDocument,
    options
  );
}
export function useProjectTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProjectTemplateQuery, ProjectTemplateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectTemplateQuery, ProjectTemplateQueryVariables>(
    ProjectTemplateDocument,
    options
  );
}
export type ProjectTemplateQueryHookResult = ReturnType<typeof useProjectTemplateQuery>;
export type ProjectTemplateLazyQueryHookResult = ReturnType<typeof useProjectTemplateLazyQuery>;
export type ProjectTemplateQueryResult = Apollo.QueryResult<
  ProjectTemplateQuery,
  ProjectTemplateQueryVariables
>;
export const ProjectTemplateCustomAttributeDocument = gql`
  query projectTemplateCustomAttribute($id: ID!) {
    projectTemplateCustomAttribute(id: $id) {
      id
      listItem {
        id
        sortNo
        value
        versionNo
      }
      master {
        id
        listItem {
          id
          sortNo
          value
          versionNo
        }
        name
        required
        sortNo
        type
        versionNo
      }
      sortNo
      value
    }
  }
`;

/**
 * __useProjectTemplateCustomAttributeQuery__
 *
 * To run a query within a React component, call `useProjectTemplateCustomAttributeQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTemplateCustomAttributeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTemplateCustomAttributeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectTemplateCustomAttributeQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProjectTemplateCustomAttributeQuery,
    ProjectTemplateCustomAttributeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProjectTemplateCustomAttributeQuery,
    ProjectTemplateCustomAttributeQueryVariables
  >(ProjectTemplateCustomAttributeDocument, options);
}
export function useProjectTemplateCustomAttributeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectTemplateCustomAttributeQuery,
    ProjectTemplateCustomAttributeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectTemplateCustomAttributeQuery,
    ProjectTemplateCustomAttributeQueryVariables
  >(ProjectTemplateCustomAttributeDocument, options);
}
export type ProjectTemplateCustomAttributeQueryHookResult = ReturnType<
  typeof useProjectTemplateCustomAttributeQuery
>;
export type ProjectTemplateCustomAttributeLazyQueryHookResult = ReturnType<
  typeof useProjectTemplateCustomAttributeLazyQuery
>;
export type ProjectTemplateCustomAttributeQueryResult = Apollo.QueryResult<
  ProjectTemplateCustomAttributeQuery,
  ProjectTemplateCustomAttributeQueryVariables
>;
export const ProjectTemplateCustomAttributesByProjectDocument = gql`
  query projectTemplateCustomAttributesByProject($projectTemplateId: String!) {
    projectTemplateCustomAttributesByProject(projectTemplateId: $projectTemplateId) {
      id
      listItem {
        id
        sortNo
        value
        versionNo
      }
      master {
        id
        listItem {
          id
          sortNo
          value
          versionNo
        }
        name
        required
        sortNo
        type
        versionNo
      }
      sortNo
      value
    }
  }
`;

/**
 * __useProjectTemplateCustomAttributesByProjectQuery__
 *
 * To run a query within a React component, call `useProjectTemplateCustomAttributesByProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTemplateCustomAttributesByProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTemplateCustomAttributesByProjectQuery({
 *   variables: {
 *      projectTemplateId: // value for 'projectTemplateId'
 *   },
 * });
 */
export function useProjectTemplateCustomAttributesByProjectQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProjectTemplateCustomAttributesByProjectQuery,
    ProjectTemplateCustomAttributesByProjectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProjectTemplateCustomAttributesByProjectQuery,
    ProjectTemplateCustomAttributesByProjectQueryVariables
  >(ProjectTemplateCustomAttributesByProjectDocument, options);
}
export function useProjectTemplateCustomAttributesByProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectTemplateCustomAttributesByProjectQuery,
    ProjectTemplateCustomAttributesByProjectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectTemplateCustomAttributesByProjectQuery,
    ProjectTemplateCustomAttributesByProjectQueryVariables
  >(ProjectTemplateCustomAttributesByProjectDocument, options);
}
export type ProjectTemplateCustomAttributesByProjectQueryHookResult = ReturnType<
  typeof useProjectTemplateCustomAttributesByProjectQuery
>;
export type ProjectTemplateCustomAttributesByProjectLazyQueryHookResult = ReturnType<
  typeof useProjectTemplateCustomAttributesByProjectLazyQuery
>;
export type ProjectTemplateCustomAttributesByProjectQueryResult = Apollo.QueryResult<
  ProjectTemplateCustomAttributesByProjectQuery,
  ProjectTemplateCustomAttributesByProjectQueryVariables
>;
export const ProjectTemplateSubTaskDocument = gql`
  query projectTemplateSubTask($id: String) {
    projectTemplateSubTask(id: $id) {
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      id
      projectTemplateTask {
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        id
        priority
        projectTemplate {
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          description
          estimateTimeSec
          id
          name
          priority
          sortNoInList
          versionNo
        }
        projectTemplateTaskStatus {
          endStatus
          id
          name
          projectTemplate {
            description
            estimateTimeSec
            id
            name
            priority
            sortNoInList
            versionNo
          }
          sortNo
          versionNo
        }
        sortNoInList
        sortNoInTaskStatus
        title
        versionNo
      }
      sortNo
      title
      versionNo
    }
  }
`;

/**
 * __useProjectTemplateSubTaskQuery__
 *
 * To run a query within a React component, call `useProjectTemplateSubTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTemplateSubTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTemplateSubTaskQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectTemplateSubTaskQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProjectTemplateSubTaskQuery,
    ProjectTemplateSubTaskQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectTemplateSubTaskQuery, ProjectTemplateSubTaskQueryVariables>(
    ProjectTemplateSubTaskDocument,
    options
  );
}
export function useProjectTemplateSubTaskLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectTemplateSubTaskQuery,
    ProjectTemplateSubTaskQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectTemplateSubTaskQuery, ProjectTemplateSubTaskQueryVariables>(
    ProjectTemplateSubTaskDocument,
    options
  );
}
export type ProjectTemplateSubTaskQueryHookResult = ReturnType<
  typeof useProjectTemplateSubTaskQuery
>;
export type ProjectTemplateSubTaskLazyQueryHookResult = ReturnType<
  typeof useProjectTemplateSubTaskLazyQuery
>;
export type ProjectTemplateSubTaskQueryResult = Apollo.QueryResult<
  ProjectTemplateSubTaskQuery,
  ProjectTemplateSubTaskQueryVariables
>;
export const ProjectTemplateSubTasksDocument = gql`
  query projectTemplateSubTasks($projectTemplateTaskId: String!) {
    projectTemplateSubTasks(projectTemplateTaskId: $projectTemplateTaskId) {
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      id
      projectTemplateTask {
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        id
        priority
        projectTemplate {
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          description
          estimateTimeSec
          id
          name
          priority
          sortNoInList
          versionNo
        }
        projectTemplateTaskStatus {
          endStatus
          id
          name
          projectTemplate {
            description
            estimateTimeSec
            id
            name
            priority
            sortNoInList
            versionNo
          }
          sortNo
          versionNo
        }
        sortNoInList
        sortNoInTaskStatus
        title
        versionNo
      }
      sortNo
      title
      versionNo
    }
  }
`;

/**
 * __useProjectTemplateSubTasksQuery__
 *
 * To run a query within a React component, call `useProjectTemplateSubTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTemplateSubTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTemplateSubTasksQuery({
 *   variables: {
 *      projectTemplateTaskId: // value for 'projectTemplateTaskId'
 *   },
 * });
 */
export function useProjectTemplateSubTasksQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProjectTemplateSubTasksQuery,
    ProjectTemplateSubTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectTemplateSubTasksQuery, ProjectTemplateSubTasksQueryVariables>(
    ProjectTemplateSubTasksDocument,
    options
  );
}
export function useProjectTemplateSubTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectTemplateSubTasksQuery,
    ProjectTemplateSubTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectTemplateSubTasksQuery, ProjectTemplateSubTasksQueryVariables>(
    ProjectTemplateSubTasksDocument,
    options
  );
}
export type ProjectTemplateSubTasksQueryHookResult = ReturnType<
  typeof useProjectTemplateSubTasksQuery
>;
export type ProjectTemplateSubTasksLazyQueryHookResult = ReturnType<
  typeof useProjectTemplateSubTasksLazyQuery
>;
export type ProjectTemplateSubTasksQueryResult = Apollo.QueryResult<
  ProjectTemplateSubTasksQuery,
  ProjectTemplateSubTasksQueryVariables
>;
export const ProjectTemplateTaskDocument = gql`
  query projectTemplateTask($id: String) {
    projectTemplateTask(id: $id) {
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      id
      priority
      projectTemplate {
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        description
        estimateTimeSec
        id
        name
        priority
        sortNoInList
        versionNo
      }
      projectTemplateTaskStatus {
        endStatus
        id
        name
        projectTemplate {
          description
          estimateTimeSec
          id
          name
          priority
          sortNoInList
          versionNo
        }
        sortNo
        versionNo
      }
      sortNoInList
      sortNoInTaskStatus
      title
      versionNo
    }
  }
`;

/**
 * __useProjectTemplateTaskQuery__
 *
 * To run a query within a React component, call `useProjectTemplateTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTemplateTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTemplateTaskQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectTemplateTaskQuery(
  baseOptions?: Apollo.QueryHookOptions<ProjectTemplateTaskQuery, ProjectTemplateTaskQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectTemplateTaskQuery, ProjectTemplateTaskQueryVariables>(
    ProjectTemplateTaskDocument,
    options
  );
}
export function useProjectTemplateTaskLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectTemplateTaskQuery,
    ProjectTemplateTaskQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectTemplateTaskQuery, ProjectTemplateTaskQueryVariables>(
    ProjectTemplateTaskDocument,
    options
  );
}
export type ProjectTemplateTaskQueryHookResult = ReturnType<typeof useProjectTemplateTaskQuery>;
export type ProjectTemplateTaskLazyQueryHookResult = ReturnType<
  typeof useProjectTemplateTaskLazyQuery
>;
export type ProjectTemplateTaskQueryResult = Apollo.QueryResult<
  ProjectTemplateTaskQuery,
  ProjectTemplateTaskQueryVariables
>;
export const ProjectTemplateTaskCustomAttributeDocument = gql`
  query projectTemplateTaskCustomAttribute($id: ID!) {
    projectTemplateTaskCustomAttribute(id: $id) {
      id
      listItem {
        id
        sortNo
        value
        versionNo
      }
      master {
        id
        listItem {
          id
          sortNo
          value
          versionNo
        }
        name
        required
        sortNo
        type
        versionNo
      }
      sortNo
      value
    }
  }
`;

/**
 * __useProjectTemplateTaskCustomAttributeQuery__
 *
 * To run a query within a React component, call `useProjectTemplateTaskCustomAttributeQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTemplateTaskCustomAttributeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTemplateTaskCustomAttributeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectTemplateTaskCustomAttributeQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProjectTemplateTaskCustomAttributeQuery,
    ProjectTemplateTaskCustomAttributeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProjectTemplateTaskCustomAttributeQuery,
    ProjectTemplateTaskCustomAttributeQueryVariables
  >(ProjectTemplateTaskCustomAttributeDocument, options);
}
export function useProjectTemplateTaskCustomAttributeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectTemplateTaskCustomAttributeQuery,
    ProjectTemplateTaskCustomAttributeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectTemplateTaskCustomAttributeQuery,
    ProjectTemplateTaskCustomAttributeQueryVariables
  >(ProjectTemplateTaskCustomAttributeDocument, options);
}
export type ProjectTemplateTaskCustomAttributeQueryHookResult = ReturnType<
  typeof useProjectTemplateTaskCustomAttributeQuery
>;
export type ProjectTemplateTaskCustomAttributeLazyQueryHookResult = ReturnType<
  typeof useProjectTemplateTaskCustomAttributeLazyQuery
>;
export type ProjectTemplateTaskCustomAttributeQueryResult = Apollo.QueryResult<
  ProjectTemplateTaskCustomAttributeQuery,
  ProjectTemplateTaskCustomAttributeQueryVariables
>;
export const ProjectTemplateTaskCustomAttributesByTaskDocument = gql`
  query projectTemplateTaskCustomAttributesByTask($projectTemplateTaskId: String!) {
    projectTemplateTaskCustomAttributesByTask(projectTemplateTaskId: $projectTemplateTaskId) {
      id
      listItem {
        id
        sortNo
        value
        versionNo
      }
      master {
        id
        listItem {
          id
          sortNo
          value
          versionNo
        }
        name
        required
        sortNo
        type
        versionNo
      }
      sortNo
      value
    }
  }
`;

/**
 * __useProjectTemplateTaskCustomAttributesByTaskQuery__
 *
 * To run a query within a React component, call `useProjectTemplateTaskCustomAttributesByTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTemplateTaskCustomAttributesByTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTemplateTaskCustomAttributesByTaskQuery({
 *   variables: {
 *      projectTemplateTaskId: // value for 'projectTemplateTaskId'
 *   },
 * });
 */
export function useProjectTemplateTaskCustomAttributesByTaskQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProjectTemplateTaskCustomAttributesByTaskQuery,
    ProjectTemplateTaskCustomAttributesByTaskQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProjectTemplateTaskCustomAttributesByTaskQuery,
    ProjectTemplateTaskCustomAttributesByTaskQueryVariables
  >(ProjectTemplateTaskCustomAttributesByTaskDocument, options);
}
export function useProjectTemplateTaskCustomAttributesByTaskLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectTemplateTaskCustomAttributesByTaskQuery,
    ProjectTemplateTaskCustomAttributesByTaskQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectTemplateTaskCustomAttributesByTaskQuery,
    ProjectTemplateTaskCustomAttributesByTaskQueryVariables
  >(ProjectTemplateTaskCustomAttributesByTaskDocument, options);
}
export type ProjectTemplateTaskCustomAttributesByTaskQueryHookResult = ReturnType<
  typeof useProjectTemplateTaskCustomAttributesByTaskQuery
>;
export type ProjectTemplateTaskCustomAttributesByTaskLazyQueryHookResult = ReturnType<
  typeof useProjectTemplateTaskCustomAttributesByTaskLazyQuery
>;
export type ProjectTemplateTaskCustomAttributesByTaskQueryResult = Apollo.QueryResult<
  ProjectTemplateTaskCustomAttributesByTaskQuery,
  ProjectTemplateTaskCustomAttributesByTaskQueryVariables
>;
export const ProjectTemplateTaskRemainderDocument = gql`
  query projectTemplateTaskRemainder($id: String) {
    projectTemplateTaskRemainder(id: $id) {
      beforeDeadlineMinutes
      id
      projectTemplateTask {
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        id
        priority
        projectTemplate {
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          description
          estimateTimeSec
          id
          name
          priority
          sortNoInList
          versionNo
        }
        projectTemplateTaskStatus {
          endStatus
          id
          name
          projectTemplate {
            description
            estimateTimeSec
            id
            name
            priority
            sortNoInList
            versionNo
          }
          sortNo
          versionNo
        }
        sortNoInList
        sortNoInTaskStatus
        title
        versionNo
      }
      targetMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      taskRemainderDestType
      taskRemainderType
    }
  }
`;

/**
 * __useProjectTemplateTaskRemainderQuery__
 *
 * To run a query within a React component, call `useProjectTemplateTaskRemainderQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTemplateTaskRemainderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTemplateTaskRemainderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectTemplateTaskRemainderQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProjectTemplateTaskRemainderQuery,
    ProjectTemplateTaskRemainderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProjectTemplateTaskRemainderQuery,
    ProjectTemplateTaskRemainderQueryVariables
  >(ProjectTemplateTaskRemainderDocument, options);
}
export function useProjectTemplateTaskRemainderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectTemplateTaskRemainderQuery,
    ProjectTemplateTaskRemainderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectTemplateTaskRemainderQuery,
    ProjectTemplateTaskRemainderQueryVariables
  >(ProjectTemplateTaskRemainderDocument, options);
}
export type ProjectTemplateTaskRemainderQueryHookResult = ReturnType<
  typeof useProjectTemplateTaskRemainderQuery
>;
export type ProjectTemplateTaskRemainderLazyQueryHookResult = ReturnType<
  typeof useProjectTemplateTaskRemainderLazyQuery
>;
export type ProjectTemplateTaskRemainderQueryResult = Apollo.QueryResult<
  ProjectTemplateTaskRemainderQuery,
  ProjectTemplateTaskRemainderQueryVariables
>;
export const ProjectTemplateTaskRemaindersDocument = gql`
  query projectTemplateTaskRemainders($projectTemplateTaskId: String!) {
    projectTemplateTaskRemainders(projectTemplateTaskId: $projectTemplateTaskId) {
      beforeDeadlineMinutes
      id
      projectTemplateTask {
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        id
        priority
        projectTemplate {
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          description
          estimateTimeSec
          id
          name
          priority
          sortNoInList
          versionNo
        }
        projectTemplateTaskStatus {
          endStatus
          id
          name
          projectTemplate {
            description
            estimateTimeSec
            id
            name
            priority
            sortNoInList
            versionNo
          }
          sortNo
          versionNo
        }
        sortNoInList
        sortNoInTaskStatus
        title
        versionNo
      }
      targetMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      taskRemainderDestType
      taskRemainderType
    }
  }
`;

/**
 * __useProjectTemplateTaskRemaindersQuery__
 *
 * To run a query within a React component, call `useProjectTemplateTaskRemaindersQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTemplateTaskRemaindersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTemplateTaskRemaindersQuery({
 *   variables: {
 *      projectTemplateTaskId: // value for 'projectTemplateTaskId'
 *   },
 * });
 */
export function useProjectTemplateTaskRemaindersQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProjectTemplateTaskRemaindersQuery,
    ProjectTemplateTaskRemaindersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProjectTemplateTaskRemaindersQuery,
    ProjectTemplateTaskRemaindersQueryVariables
  >(ProjectTemplateTaskRemaindersDocument, options);
}
export function useProjectTemplateTaskRemaindersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectTemplateTaskRemaindersQuery,
    ProjectTemplateTaskRemaindersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectTemplateTaskRemaindersQuery,
    ProjectTemplateTaskRemaindersQueryVariables
  >(ProjectTemplateTaskRemaindersDocument, options);
}
export type ProjectTemplateTaskRemaindersQueryHookResult = ReturnType<
  typeof useProjectTemplateTaskRemaindersQuery
>;
export type ProjectTemplateTaskRemaindersLazyQueryHookResult = ReturnType<
  typeof useProjectTemplateTaskRemaindersLazyQuery
>;
export type ProjectTemplateTaskRemaindersQueryResult = Apollo.QueryResult<
  ProjectTemplateTaskRemaindersQuery,
  ProjectTemplateTaskRemaindersQueryVariables
>;
export const ProjectTemplateTaskStatusDocument = gql`
  query projectTemplateTaskStatus($id: String!) {
    projectTemplateTaskStatus(id: $id) {
      endStatus
      id
      name
      projectTemplate {
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        description
        estimateTimeSec
        id
        name
        priority
        sortNoInList
        versionNo
      }
      sortNo
      versionNo
    }
  }
`;

/**
 * __useProjectTemplateTaskStatusQuery__
 *
 * To run a query within a React component, call `useProjectTemplateTaskStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTemplateTaskStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTemplateTaskStatusQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectTemplateTaskStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProjectTemplateTaskStatusQuery,
    ProjectTemplateTaskStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectTemplateTaskStatusQuery, ProjectTemplateTaskStatusQueryVariables>(
    ProjectTemplateTaskStatusDocument,
    options
  );
}
export function useProjectTemplateTaskStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectTemplateTaskStatusQuery,
    ProjectTemplateTaskStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectTemplateTaskStatusQuery,
    ProjectTemplateTaskStatusQueryVariables
  >(ProjectTemplateTaskStatusDocument, options);
}
export type ProjectTemplateTaskStatusQueryHookResult = ReturnType<
  typeof useProjectTemplateTaskStatusQuery
>;
export type ProjectTemplateTaskStatusLazyQueryHookResult = ReturnType<
  typeof useProjectTemplateTaskStatusLazyQuery
>;
export type ProjectTemplateTaskStatusQueryResult = Apollo.QueryResult<
  ProjectTemplateTaskStatusQuery,
  ProjectTemplateTaskStatusQueryVariables
>;
export const ProjectTemplateTaskStatusesDocument = gql`
  query projectTemplateTaskStatuses($projectTemplateId: String!) {
    projectTemplateTaskStatuses(projectTemplateId: $projectTemplateId) {
      endStatus
      id
      name
      projectTemplate {
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        description
        estimateTimeSec
        id
        name
        priority
        sortNoInList
        versionNo
      }
      sortNo
      versionNo
    }
  }
`;

/**
 * __useProjectTemplateTaskStatusesQuery__
 *
 * To run a query within a React component, call `useProjectTemplateTaskStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTemplateTaskStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTemplateTaskStatusesQuery({
 *   variables: {
 *      projectTemplateId: // value for 'projectTemplateId'
 *   },
 * });
 */
export function useProjectTemplateTaskStatusesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProjectTemplateTaskStatusesQuery,
    ProjectTemplateTaskStatusesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProjectTemplateTaskStatusesQuery,
    ProjectTemplateTaskStatusesQueryVariables
  >(ProjectTemplateTaskStatusesDocument, options);
}
export function useProjectTemplateTaskStatusesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectTemplateTaskStatusesQuery,
    ProjectTemplateTaskStatusesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectTemplateTaskStatusesQuery,
    ProjectTemplateTaskStatusesQueryVariables
  >(ProjectTemplateTaskStatusesDocument, options);
}
export type ProjectTemplateTaskStatusesQueryHookResult = ReturnType<
  typeof useProjectTemplateTaskStatusesQuery
>;
export type ProjectTemplateTaskStatusesLazyQueryHookResult = ReturnType<
  typeof useProjectTemplateTaskStatusesLazyQuery
>;
export type ProjectTemplateTaskStatusesQueryResult = Apollo.QueryResult<
  ProjectTemplateTaskStatusesQuery,
  ProjectTemplateTaskStatusesQueryVariables
>;
export const ProjectTemplateTaskTagsDocument = gql`
  query projectTemplateTaskTags($projectTemplateTaskId: String!) {
    projectTemplateTaskTags(projectTemplateTaskId: $projectTemplateTaskId) {
      id
      name
      note
      organization {
        allowConnectThirdPartyCalendar
        contactMailAddress
        contractPreriod
        id
        imageUrl
        ipRestrictionFlg
        name
        plan {
          code
          contractPreriod
          id
        }
        requiredTwoFactorAuth
        suspensionFlg
        versionNo
      }
      relatedTaskCount
      sortNo
      versionNo
    }
  }
`;

/**
 * __useProjectTemplateTaskTagsQuery__
 *
 * To run a query within a React component, call `useProjectTemplateTaskTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTemplateTaskTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTemplateTaskTagsQuery({
 *   variables: {
 *      projectTemplateTaskId: // value for 'projectTemplateTaskId'
 *   },
 * });
 */
export function useProjectTemplateTaskTagsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProjectTemplateTaskTagsQuery,
    ProjectTemplateTaskTagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectTemplateTaskTagsQuery, ProjectTemplateTaskTagsQueryVariables>(
    ProjectTemplateTaskTagsDocument,
    options
  );
}
export function useProjectTemplateTaskTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectTemplateTaskTagsQuery,
    ProjectTemplateTaskTagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectTemplateTaskTagsQuery, ProjectTemplateTaskTagsQueryVariables>(
    ProjectTemplateTaskTagsDocument,
    options
  );
}
export type ProjectTemplateTaskTagsQueryHookResult = ReturnType<
  typeof useProjectTemplateTaskTagsQuery
>;
export type ProjectTemplateTaskTagsLazyQueryHookResult = ReturnType<
  typeof useProjectTemplateTaskTagsLazyQuery
>;
export type ProjectTemplateTaskTagsQueryResult = Apollo.QueryResult<
  ProjectTemplateTaskTagsQuery,
  ProjectTemplateTaskTagsQueryVariables
>;
export const ProjectTemplateTasksDocument = gql`
  query projectTemplateTasks($projectTemplateId: String!) {
    projectTemplateTasks(projectTemplateId: $projectTemplateId) {
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      id
      priority
      projectTemplate {
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        description
        estimateTimeSec
        id
        name
        priority
        sortNoInList
        versionNo
      }
      projectTemplateTaskStatus {
        endStatus
        id
        name
        projectTemplate {
          description
          estimateTimeSec
          id
          name
          priority
          sortNoInList
          versionNo
        }
        sortNo
        versionNo
      }
      sortNoInList
      sortNoInTaskStatus
      title
      versionNo
    }
  }
`;

/**
 * __useProjectTemplateTasksQuery__
 *
 * To run a query within a React component, call `useProjectTemplateTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTemplateTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTemplateTasksQuery({
 *   variables: {
 *      projectTemplateId: // value for 'projectTemplateId'
 *   },
 * });
 */
export function useProjectTemplateTasksQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProjectTemplateTasksQuery,
    ProjectTemplateTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectTemplateTasksQuery, ProjectTemplateTasksQueryVariables>(
    ProjectTemplateTasksDocument,
    options
  );
}
export function useProjectTemplateTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectTemplateTasksQuery,
    ProjectTemplateTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectTemplateTasksQuery, ProjectTemplateTasksQueryVariables>(
    ProjectTemplateTasksDocument,
    options
  );
}
export type ProjectTemplateTasksQueryHookResult = ReturnType<typeof useProjectTemplateTasksQuery>;
export type ProjectTemplateTasksLazyQueryHookResult = ReturnType<
  typeof useProjectTemplateTasksLazyQuery
>;
export type ProjectTemplateTasksQueryResult = Apollo.QueryResult<
  ProjectTemplateTasksQuery,
  ProjectTemplateTasksQueryVariables
>;
export const ProjectTemplateTasksGroupByStatusDocument = gql`
  query projectTemplateTasksGroupByStatus($projectTemplateId: String) {
    projectTemplateTasksGroupByStatus(projectTemplateId: $projectTemplateId) {
      projectTemplateTaskStatus {
        endStatus
        id
        name
        projectTemplate {
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          description
          estimateTimeSec
          id
          name
          priority
          sortNoInList
          versionNo
        }
        sortNo
        versionNo
      }
      projectTemplateTasks {
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        id
        priority
        projectTemplate {
          description
          estimateTimeSec
          id
          name
          priority
          sortNoInList
          versionNo
        }
        projectTemplateTaskStatus {
          endStatus
          id
          name
          sortNo
          versionNo
        }
        sortNoInList
        sortNoInTaskStatus
        title
        versionNo
      }
    }
  }
`;

/**
 * __useProjectTemplateTasksGroupByStatusQuery__
 *
 * To run a query within a React component, call `useProjectTemplateTasksGroupByStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTemplateTasksGroupByStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTemplateTasksGroupByStatusQuery({
 *   variables: {
 *      projectTemplateId: // value for 'projectTemplateId'
 *   },
 * });
 */
export function useProjectTemplateTasksGroupByStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProjectTemplateTasksGroupByStatusQuery,
    ProjectTemplateTasksGroupByStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProjectTemplateTasksGroupByStatusQuery,
    ProjectTemplateTasksGroupByStatusQueryVariables
  >(ProjectTemplateTasksGroupByStatusDocument, options);
}
export function useProjectTemplateTasksGroupByStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectTemplateTasksGroupByStatusQuery,
    ProjectTemplateTasksGroupByStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectTemplateTasksGroupByStatusQuery,
    ProjectTemplateTasksGroupByStatusQueryVariables
  >(ProjectTemplateTasksGroupByStatusDocument, options);
}
export type ProjectTemplateTasksGroupByStatusQueryHookResult = ReturnType<
  typeof useProjectTemplateTasksGroupByStatusQuery
>;
export type ProjectTemplateTasksGroupByStatusLazyQueryHookResult = ReturnType<
  typeof useProjectTemplateTasksGroupByStatusLazyQuery
>;
export type ProjectTemplateTasksGroupByStatusQueryResult = Apollo.QueryResult<
  ProjectTemplateTasksGroupByStatusQuery,
  ProjectTemplateTasksGroupByStatusQueryVariables
>;
export const ProjectTemplatesDocument = gql`
  query projectTemplates($input: FetchProjectTemplatesInputInput) {
    projectTemplates(input: $input) {
      client {
        code
        id
        name
        sortNo
        versionNo
      }
      description
      estimateTimeSec
      id
      name
      priority
      sortNoInList
      versionNo
    }
  }
`;

/**
 * __useProjectTemplatesQuery__
 *
 * To run a query within a React component, call `useProjectTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTemplatesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProjectTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<ProjectTemplatesQuery, ProjectTemplatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectTemplatesQuery, ProjectTemplatesQueryVariables>(
    ProjectTemplatesDocument,
    options
  );
}
export function useProjectTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProjectTemplatesQuery, ProjectTemplatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectTemplatesQuery, ProjectTemplatesQueryVariables>(
    ProjectTemplatesDocument,
    options
  );
}
export type ProjectTemplatesQueryHookResult = ReturnType<typeof useProjectTemplatesQuery>;
export type ProjectTemplatesLazyQueryHookResult = ReturnType<typeof useProjectTemplatesLazyQuery>;
export type ProjectTemplatesQueryResult = Apollo.QueryResult<
  ProjectTemplatesQuery,
  ProjectTemplatesQueryVariables
>;
export const ProjectWorkingTimeSecDocument = gql`
  query projectWorkingTimeSec($id: ID!) {
    projectWorkingTimeSec(id: $id)
  }
`;

/**
 * __useProjectWorkingTimeSecQuery__
 *
 * To run a query within a React component, call `useProjectWorkingTimeSecQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectWorkingTimeSecQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectWorkingTimeSecQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectWorkingTimeSecQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProjectWorkingTimeSecQuery,
    ProjectWorkingTimeSecQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectWorkingTimeSecQuery, ProjectWorkingTimeSecQueryVariables>(
    ProjectWorkingTimeSecDocument,
    options
  );
}
export function useProjectWorkingTimeSecLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectWorkingTimeSecQuery,
    ProjectWorkingTimeSecQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectWorkingTimeSecQuery, ProjectWorkingTimeSecQueryVariables>(
    ProjectWorkingTimeSecDocument,
    options
  );
}
export type ProjectWorkingTimeSecQueryHookResult = ReturnType<typeof useProjectWorkingTimeSecQuery>;
export type ProjectWorkingTimeSecLazyQueryHookResult = ReturnType<
  typeof useProjectWorkingTimeSecLazyQuery
>;
export type ProjectWorkingTimeSecQueryResult = Apollo.QueryResult<
  ProjectWorkingTimeSecQuery,
  ProjectWorkingTimeSecQueryVariables
>;
export const SearchFavoriteTasksDocument = gql`
  query searchFavoriteTasks($input: SearchFavoriteTaskInputInput!, $limit: Int, $offset: Int) {
    searchFavoriteTasks(input: $input, limit: $limit, offset: $offset) {
      assignees {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      asssignee {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      calendarLinkageCode
      completeDateTime
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      estimatedRemainingTimeSec
      favoriteMembers {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      favoriteMembersInfo {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      id
      laborCost
      priority
      progressDeviationRate
      progressRate
      project {
        assignees {
          sortNo
        }
        calendarLinkageCode
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        color
        complete
        completeDateTime
        costBudgetWithoutTax
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          sortNo
        }
        id
        key
        laborCost
        laborCostWithoutTax
        laborShare
        name
        priority
        projectStatus {
          endStatus
          id
          name
          sortNo
          teamId
          versionNo
        }
        registerDateTime
        salesAmountWithoutTax
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInProjectStatus
        team {
          archived
          id
          name
          organization {
            allowConnectThirdPartyCalendar
            contactMailAddress
            contractPreriod
            id
            imageUrl
            ipRestrictionFlg
            name
            plan {
              code
              contractPreriod
              id
            }
            requiredTwoFactorAuth
            suspensionFlg
            versionNo
          }
          personalTeam
          status
          versionNo
        }
        totalScheduleTimeSec
        versionNo
        workingTimeSec
      }
      registerDateTime
      remainingTimeSec
      scheduleWorkingTimeSec
      scheduledEndDateTime
      scheduledStartDateTime
      sortNoInGanttChart
      sortNoInList
      sortNoInMyTask
      sortNoInTaskStatus
      taskStatus {
        endStatus
        id
        name
        project {
          calendarLinkageCode
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          description
          estimateTimeSec
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        sortNo
        versionNo
      }
      title
      versionNo
      workingMembers {
        lastWorkingStartDateTime
        lastWorkingStopDateTime
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
      }
      workingTimeSec
    }
  }
`;

/**
 * __useSearchFavoriteTasksQuery__
 *
 * To run a query within a React component, call `useSearchFavoriteTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchFavoriteTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchFavoriteTasksQuery({
 *   variables: {
 *      input: // value for 'input'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useSearchFavoriteTasksQuery(
  baseOptions: Apollo.QueryHookOptions<SearchFavoriteTasksQuery, SearchFavoriteTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchFavoriteTasksQuery, SearchFavoriteTasksQueryVariables>(
    SearchFavoriteTasksDocument,
    options
  );
}
export function useSearchFavoriteTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchFavoriteTasksQuery,
    SearchFavoriteTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchFavoriteTasksQuery, SearchFavoriteTasksQueryVariables>(
    SearchFavoriteTasksDocument,
    options
  );
}
export type SearchFavoriteTasksQueryHookResult = ReturnType<typeof useSearchFavoriteTasksQuery>;
export type SearchFavoriteTasksLazyQueryHookResult = ReturnType<
  typeof useSearchFavoriteTasksLazyQuery
>;
export type SearchFavoriteTasksQueryResult = Apollo.QueryResult<
  SearchFavoriteTasksQuery,
  SearchFavoriteTasksQueryVariables
>;
export const SearchTasksDocument = gql`
  query searchTasks($input: SearchTaskInputInput!, $limit: Int, $offset: Int) {
    searchTasks(input: $input, limit: $limit, offset: $offset) {
      assignees {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      asssignee {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      calendarLinkageCode
      completeDateTime
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      estimatedRemainingTimeSec
      favoriteMembers {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      favoriteMembersInfo {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      id
      laborCost
      priority
      progressDeviationRate
      progressRate
      project {
        assignees {
          sortNo
        }
        calendarLinkageCode
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        color
        complete
        completeDateTime
        costBudgetWithoutTax
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          sortNo
        }
        id
        key
        laborCost
        laborCostWithoutTax
        laborShare
        name
        priority
        projectStatus {
          endStatus
          id
          name
          sortNo
          teamId
          versionNo
        }
        registerDateTime
        salesAmountWithoutTax
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInProjectStatus
        team {
          archived
          id
          name
          organization {
            allowConnectThirdPartyCalendar
            contactMailAddress
            contractPreriod
            id
            imageUrl
            ipRestrictionFlg
            name
            plan {
              code
              contractPreriod
              id
            }
            requiredTwoFactorAuth
            suspensionFlg
            versionNo
          }
          personalTeam
          status
          versionNo
        }
        totalScheduleTimeSec
        versionNo
        workingTimeSec
      }
      registerDateTime
      remainingTimeSec
      scheduleWorkingTimeSec
      scheduledEndDateTime
      scheduledStartDateTime
      sortNoInGanttChart
      sortNoInList
      sortNoInMyTask
      sortNoInTaskStatus
      taskStatus {
        endStatus
        id
        name
        project {
          calendarLinkageCode
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          description
          estimateTimeSec
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        sortNo
        versionNo
      }
      title
      versionNo
      workingMembers {
        lastWorkingStartDateTime
        lastWorkingStopDateTime
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
      }
      workingTimeSec
    }
  }
`;

/**
 * __useSearchTasksQuery__
 *
 * To run a query within a React component, call `useSearchTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchTasksQuery({
 *   variables: {
 *      input: // value for 'input'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useSearchTasksQuery(
  baseOptions: Apollo.QueryHookOptions<SearchTasksQuery, SearchTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchTasksQuery, SearchTasksQueryVariables>(SearchTasksDocument, options);
}
export function useSearchTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchTasksQuery, SearchTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchTasksQuery, SearchTasksQueryVariables>(
    SearchTasksDocument,
    options
  );
}
export type SearchTasksQueryHookResult = ReturnType<typeof useSearchTasksQuery>;
export type SearchTasksLazyQueryHookResult = ReturnType<typeof useSearchTasksLazyQuery>;
export type SearchTasksQueryResult = Apollo.QueryResult<
  SearchTasksQuery,
  SearchTasksQueryVariables
>;
export const SrcIpAddressDocument = gql`
  query srcIpAddress {
    srcIpAddress
  }
`;

/**
 * __useSrcIpAddressQuery__
 *
 * To run a query within a React component, call `useSrcIpAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useSrcIpAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSrcIpAddressQuery({
 *   variables: {
 *   },
 * });
 */
export function useSrcIpAddressQuery(
  baseOptions?: Apollo.QueryHookOptions<SrcIpAddressQuery, SrcIpAddressQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SrcIpAddressQuery, SrcIpAddressQueryVariables>(
    SrcIpAddressDocument,
    options
  );
}
export function useSrcIpAddressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SrcIpAddressQuery, SrcIpAddressQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SrcIpAddressQuery, SrcIpAddressQueryVariables>(
    SrcIpAddressDocument,
    options
  );
}
export type SrcIpAddressQueryHookResult = ReturnType<typeof useSrcIpAddressQuery>;
export type SrcIpAddressLazyQueryHookResult = ReturnType<typeof useSrcIpAddressLazyQuery>;
export type SrcIpAddressQueryResult = Apollo.QueryResult<
  SrcIpAddressQuery,
  SrcIpAddressQueryVariables
>;
export const SubTaskDocument = gql`
  query subTask($id: String) {
    subTask(id: $id) {
      complete
      completeDateTime
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      id
      sortNo
      task {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        asssignee {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        calendarLinkageCode
        completeDateTime
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        estimatedRemainingTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        laborCost
        priority
        progressDeviationRate
        progressRate
        project {
          assignees {
            sortNo
          }
          calendarLinkageCode
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          estimateTimeSec
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            sortNo
          }
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          projectStatus {
            endStatus
            id
            name
            sortNo
            teamId
            versionNo
          }
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          team {
            archived
            id
            name
            organization {
              allowConnectThirdPartyCalendar
              contactMailAddress
              contractPreriod
              id
              imageUrl
              ipRestrictionFlg
              name
              plan {
                code
                contractPreriod
                id
              }
              requiredTwoFactorAuth
              suspensionFlg
              versionNo
            }
            personalTeam
            status
            versionNo
          }
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        registerDateTime
        remainingTimeSec
        scheduleWorkingTimeSec
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInMyTask
        sortNoInTaskStatus
        taskStatus {
          endStatus
          id
          name
          project {
            calendarLinkageCode
            color
            complete
            completeDateTime
            costBudgetWithoutTax
            description
            estimateTimeSec
            id
            key
            laborCost
            laborCostWithoutTax
            laborShare
            name
            priority
            registerDateTime
            salesAmountWithoutTax
            scheduledEndDateTime
            scheduledStartDateTime
            sortNoInGanttChart
            sortNoInList
            sortNoInProjectStatus
            totalScheduleTimeSec
            versionNo
            workingTimeSec
          }
          sortNo
          versionNo
        }
        title
        versionNo
        workingMembers {
          lastWorkingStartDateTime
          lastWorkingStopDateTime
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
        }
        workingTimeSec
      }
      title
      versionNo
    }
  }
`;

/**
 * __useSubTaskQuery__
 *
 * To run a query within a React component, call `useSubTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubTaskQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubTaskQuery(
  baseOptions?: Apollo.QueryHookOptions<SubTaskQuery, SubTaskQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SubTaskQuery, SubTaskQueryVariables>(SubTaskDocument, options);
}
export function useSubTaskLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SubTaskQuery, SubTaskQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SubTaskQuery, SubTaskQueryVariables>(SubTaskDocument, options);
}
export type SubTaskQueryHookResult = ReturnType<typeof useSubTaskQuery>;
export type SubTaskLazyQueryHookResult = ReturnType<typeof useSubTaskLazyQuery>;
export type SubTaskQueryResult = Apollo.QueryResult<SubTaskQuery, SubTaskQueryVariables>;
export const SubTasksDocument = gql`
  query subTasks($taskId: String!) {
    subTasks(taskId: $taskId) {
      complete
      completeDateTime
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      id
      sortNo
      task {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        asssignee {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        calendarLinkageCode
        completeDateTime
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        estimatedRemainingTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        laborCost
        priority
        progressDeviationRate
        progressRate
        project {
          assignees {
            sortNo
          }
          calendarLinkageCode
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          estimateTimeSec
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            sortNo
          }
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          projectStatus {
            endStatus
            id
            name
            sortNo
            teamId
            versionNo
          }
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          team {
            archived
            id
            name
            organization {
              allowConnectThirdPartyCalendar
              contactMailAddress
              contractPreriod
              id
              imageUrl
              ipRestrictionFlg
              name
              plan {
                code
                contractPreriod
                id
              }
              requiredTwoFactorAuth
              suspensionFlg
              versionNo
            }
            personalTeam
            status
            versionNo
          }
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        registerDateTime
        remainingTimeSec
        scheduleWorkingTimeSec
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInMyTask
        sortNoInTaskStatus
        taskStatus {
          endStatus
          id
          name
          project {
            calendarLinkageCode
            color
            complete
            completeDateTime
            costBudgetWithoutTax
            description
            estimateTimeSec
            id
            key
            laborCost
            laborCostWithoutTax
            laborShare
            name
            priority
            registerDateTime
            salesAmountWithoutTax
            scheduledEndDateTime
            scheduledStartDateTime
            sortNoInGanttChart
            sortNoInList
            sortNoInProjectStatus
            totalScheduleTimeSec
            versionNo
            workingTimeSec
          }
          sortNo
          versionNo
        }
        title
        versionNo
        workingMembers {
          lastWorkingStartDateTime
          lastWorkingStopDateTime
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
        }
        workingTimeSec
      }
      title
      versionNo
    }
  }
`;

/**
 * __useSubTasksQuery__
 *
 * To run a query within a React component, call `useSubTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubTasksQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useSubTasksQuery(
  baseOptions: Apollo.QueryHookOptions<SubTasksQuery, SubTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SubTasksQuery, SubTasksQueryVariables>(SubTasksDocument, options);
}
export function useSubTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SubTasksQuery, SubTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SubTasksQuery, SubTasksQueryVariables>(SubTasksDocument, options);
}
export type SubTasksQueryHookResult = ReturnType<typeof useSubTasksQuery>;
export type SubTasksLazyQueryHookResult = ReturnType<typeof useSubTasksLazyQuery>;
export type SubTasksQueryResult = Apollo.QueryResult<SubTasksQuery, SubTasksQueryVariables>;
export const TagDocument = gql`
  query tag($id: ID!) {
    tag(id: $id) {
      id
      name
      note
      organization {
        allowConnectThirdPartyCalendar
        contactMailAddress
        contractPreriod
        id
        imageUrl
        ipRestrictionFlg
        name
        plan {
          code
          contractPreriod
          id
        }
        requiredTwoFactorAuth
        suspensionFlg
        versionNo
      }
      relatedTaskCount
      sortNo
      versionNo
    }
  }
`;

/**
 * __useTagQuery__
 *
 * To run a query within a React component, call `useTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTagQuery(baseOptions: Apollo.QueryHookOptions<TagQuery, TagQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TagQuery, TagQueryVariables>(TagDocument, options);
}
export function useTagLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TagQuery, TagQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TagQuery, TagQueryVariables>(TagDocument, options);
}
export type TagQueryHookResult = ReturnType<typeof useTagQuery>;
export type TagLazyQueryHookResult = ReturnType<typeof useTagLazyQuery>;
export type TagQueryResult = Apollo.QueryResult<TagQuery, TagQueryVariables>;
export const TagTasksDocument = gql`
  query tagTasks($tagId: String!) {
    tagTasks(tagId: $tagId) {
      assignees {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      asssignee {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      calendarLinkageCode
      completeDateTime
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      estimatedRemainingTimeSec
      favoriteMembers {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      favoriteMembersInfo {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      id
      laborCost
      priority
      progressDeviationRate
      progressRate
      project {
        assignees {
          sortNo
        }
        calendarLinkageCode
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        color
        complete
        completeDateTime
        costBudgetWithoutTax
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          sortNo
        }
        id
        key
        laborCost
        laborCostWithoutTax
        laborShare
        name
        priority
        projectStatus {
          endStatus
          id
          name
          sortNo
          teamId
          versionNo
        }
        registerDateTime
        salesAmountWithoutTax
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInProjectStatus
        team {
          archived
          id
          name
          organization {
            allowConnectThirdPartyCalendar
            contactMailAddress
            contractPreriod
            id
            imageUrl
            ipRestrictionFlg
            name
            plan {
              code
              contractPreriod
              id
            }
            requiredTwoFactorAuth
            suspensionFlg
            versionNo
          }
          personalTeam
          status
          versionNo
        }
        totalScheduleTimeSec
        versionNo
        workingTimeSec
      }
      registerDateTime
      remainingTimeSec
      scheduleWorkingTimeSec
      scheduledEndDateTime
      scheduledStartDateTime
      sortNoInGanttChart
      sortNoInList
      sortNoInMyTask
      sortNoInTaskStatus
      taskStatus {
        endStatus
        id
        name
        project {
          calendarLinkageCode
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          description
          estimateTimeSec
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        sortNo
        versionNo
      }
      title
      versionNo
      workingMembers {
        lastWorkingStartDateTime
        lastWorkingStopDateTime
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
      }
      workingTimeSec
    }
  }
`;

/**
 * __useTagTasksQuery__
 *
 * To run a query within a React component, call `useTagTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagTasksQuery({
 *   variables: {
 *      tagId: // value for 'tagId'
 *   },
 * });
 */
export function useTagTasksQuery(
  baseOptions: Apollo.QueryHookOptions<TagTasksQuery, TagTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TagTasksQuery, TagTasksQueryVariables>(TagTasksDocument, options);
}
export function useTagTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TagTasksQuery, TagTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TagTasksQuery, TagTasksQueryVariables>(TagTasksDocument, options);
}
export type TagTasksQueryHookResult = ReturnType<typeof useTagTasksQuery>;
export type TagTasksLazyQueryHookResult = ReturnType<typeof useTagTasksLazyQuery>;
export type TagTasksQueryResult = Apollo.QueryResult<TagTasksQuery, TagTasksQueryVariables>;
export const TaskDocument = gql`
  query task($id: String) {
    task(id: $id) {
      assignees {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      asssignee {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      calendarLinkageCode
      completeDateTime
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      estimatedRemainingTimeSec
      favoriteMembers {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      favoriteMembersInfo {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      id
      laborCost
      priority
      progressDeviationRate
      progressRate
      project {
        assignees {
          sortNo
        }
        calendarLinkageCode
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        color
        complete
        completeDateTime
        costBudgetWithoutTax
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          sortNo
        }
        id
        key
        laborCost
        laborCostWithoutTax
        laborShare
        name
        priority
        projectStatus {
          endStatus
          id
          name
          sortNo
          teamId
          versionNo
        }
        registerDateTime
        salesAmountWithoutTax
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInProjectStatus
        team {
          archived
          id
          name
          organization {
            allowConnectThirdPartyCalendar
            contactMailAddress
            contractPreriod
            id
            imageUrl
            ipRestrictionFlg
            name
            plan {
              code
              contractPreriod
              id
            }
            requiredTwoFactorAuth
            suspensionFlg
            versionNo
          }
          personalTeam
          status
          versionNo
        }
        totalScheduleTimeSec
        versionNo
        workingTimeSec
      }
      registerDateTime
      remainingTimeSec
      scheduleWorkingTimeSec
      scheduledEndDateTime
      scheduledStartDateTime
      sortNoInGanttChart
      sortNoInList
      sortNoInMyTask
      sortNoInTaskStatus
      taskStatus {
        endStatus
        id
        name
        project {
          calendarLinkageCode
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          description
          estimateTimeSec
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        sortNo
        versionNo
      }
      title
      versionNo
      workingMembers {
        lastWorkingStartDateTime
        lastWorkingStopDateTime
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
      }
      workingTimeSec
    }
  }
`;

/**
 * __useTaskQuery__
 *
 * To run a query within a React component, call `useTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTaskQuery(baseOptions?: Apollo.QueryHookOptions<TaskQuery, TaskQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TaskQuery, TaskQueryVariables>(TaskDocument, options);
}
export function useTaskLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TaskQuery, TaskQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TaskQuery, TaskQueryVariables>(TaskDocument, options);
}
export type TaskQueryHookResult = ReturnType<typeof useTaskQuery>;
export type TaskLazyQueryHookResult = ReturnType<typeof useTaskLazyQuery>;
export type TaskQueryResult = Apollo.QueryResult<TaskQuery, TaskQueryVariables>;
export const TaskAttachmentFilesDocument = gql`
  query taskAttachmentFiles($taskId: String!) {
    taskAttachmentFiles(taskId: $taskId) {
      fileName
      id
      sizeByte
      type
      uploadDateTime
    }
  }
`;

/**
 * __useTaskAttachmentFilesQuery__
 *
 * To run a query within a React component, call `useTaskAttachmentFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskAttachmentFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskAttachmentFilesQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskAttachmentFilesQuery(
  baseOptions: Apollo.QueryHookOptions<TaskAttachmentFilesQuery, TaskAttachmentFilesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TaskAttachmentFilesQuery, TaskAttachmentFilesQueryVariables>(
    TaskAttachmentFilesDocument,
    options
  );
}
export function useTaskAttachmentFilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TaskAttachmentFilesQuery,
    TaskAttachmentFilesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TaskAttachmentFilesQuery, TaskAttachmentFilesQueryVariables>(
    TaskAttachmentFilesDocument,
    options
  );
}
export type TaskAttachmentFilesQueryHookResult = ReturnType<typeof useTaskAttachmentFilesQuery>;
export type TaskAttachmentFilesLazyQueryHookResult = ReturnType<
  typeof useTaskAttachmentFilesLazyQuery
>;
export type TaskAttachmentFilesQueryResult = Apollo.QueryResult<
  TaskAttachmentFilesQuery,
  TaskAttachmentFilesQueryVariables
>;
export const TaskBarGraphDataDocument = gql`
  query taskBarGraphData($input: TaskGraphDataInputInput!) {
    taskBarGraphData(input: $input) {
      color
      itemType
      percent
      projectId
      projectName
      reportType
      taskId
      taskTitle
      teamId
      teamName
      workingTimeSec
    }
  }
`;

/**
 * __useTaskBarGraphDataQuery__
 *
 * To run a query within a React component, call `useTaskBarGraphDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskBarGraphDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskBarGraphDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskBarGraphDataQuery(
  baseOptions: Apollo.QueryHookOptions<TaskBarGraphDataQuery, TaskBarGraphDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TaskBarGraphDataQuery, TaskBarGraphDataQueryVariables>(
    TaskBarGraphDataDocument,
    options
  );
}
export function useTaskBarGraphDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TaskBarGraphDataQuery, TaskBarGraphDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TaskBarGraphDataQuery, TaskBarGraphDataQueryVariables>(
    TaskBarGraphDataDocument,
    options
  );
}
export type TaskBarGraphDataQueryHookResult = ReturnType<typeof useTaskBarGraphDataQuery>;
export type TaskBarGraphDataLazyQueryHookResult = ReturnType<typeof useTaskBarGraphDataLazyQuery>;
export type TaskBarGraphDataQueryResult = Apollo.QueryResult<
  TaskBarGraphDataQuery,
  TaskBarGraphDataQueryVariables
>;
export const TaskCommentDocument = gql`
  query taskComment($id: ID!) {
    taskComment(id: $id) {
      comment
      commentedDateTime
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      id
      projectId
      projectName
      task {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        asssignee {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        calendarLinkageCode
        completeDateTime
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        estimatedRemainingTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        laborCost
        priority
        progressDeviationRate
        progressRate
        project {
          assignees {
            sortNo
          }
          calendarLinkageCode
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          estimateTimeSec
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            sortNo
          }
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          projectStatus {
            endStatus
            id
            name
            sortNo
            teamId
            versionNo
          }
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          team {
            archived
            id
            name
            organization {
              allowConnectThirdPartyCalendar
              contactMailAddress
              contractPreriod
              id
              imageUrl
              ipRestrictionFlg
              name
              plan {
                code
                contractPreriod
                id
              }
              requiredTwoFactorAuth
              suspensionFlg
              versionNo
            }
            personalTeam
            status
            versionNo
          }
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        registerDateTime
        remainingTimeSec
        scheduleWorkingTimeSec
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInMyTask
        sortNoInTaskStatus
        taskStatus {
          endStatus
          id
          name
          project {
            calendarLinkageCode
            color
            complete
            completeDateTime
            costBudgetWithoutTax
            description
            estimateTimeSec
            id
            key
            laborCost
            laborCostWithoutTax
            laborShare
            name
            priority
            registerDateTime
            salesAmountWithoutTax
            scheduledEndDateTime
            scheduledStartDateTime
            sortNoInGanttChart
            sortNoInList
            sortNoInProjectStatus
            totalScheduleTimeSec
            versionNo
            workingTimeSec
          }
          sortNo
          versionNo
        }
        title
        versionNo
        workingMembers {
          lastWorkingStartDateTime
          lastWorkingStopDateTime
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
        }
        workingTimeSec
      }
      taskId
      taskTitle
      teamId
      teamName
      versionNo
    }
  }
`;

/**
 * __useTaskCommentQuery__
 *
 * To run a query within a React component, call `useTaskCommentQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskCommentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskCommentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTaskCommentQuery(
  baseOptions: Apollo.QueryHookOptions<TaskCommentQuery, TaskCommentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TaskCommentQuery, TaskCommentQueryVariables>(TaskCommentDocument, options);
}
export function useTaskCommentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TaskCommentQuery, TaskCommentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TaskCommentQuery, TaskCommentQueryVariables>(
    TaskCommentDocument,
    options
  );
}
export type TaskCommentQueryHookResult = ReturnType<typeof useTaskCommentQuery>;
export type TaskCommentLazyQueryHookResult = ReturnType<typeof useTaskCommentLazyQuery>;
export type TaskCommentQueryResult = Apollo.QueryResult<
  TaskCommentQuery,
  TaskCommentQueryVariables
>;
export const TaskCommentsDocument = gql`
  query taskComments($limit: Int, $offset: Int, $sortOrder: SortOrder, $taskId: String!) {
    taskComments(limit: $limit, offset: $offset, sortOrder: $sortOrder, taskId: $taskId) {
      comment
      commentedDateTime
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      id
      projectId
      projectName
      task {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        asssignee {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        calendarLinkageCode
        completeDateTime
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        estimatedRemainingTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        laborCost
        priority
        progressDeviationRate
        progressRate
        project {
          assignees {
            sortNo
          }
          calendarLinkageCode
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          estimateTimeSec
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            sortNo
          }
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          projectStatus {
            endStatus
            id
            name
            sortNo
            teamId
            versionNo
          }
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          team {
            archived
            id
            name
            organization {
              allowConnectThirdPartyCalendar
              contactMailAddress
              contractPreriod
              id
              imageUrl
              ipRestrictionFlg
              name
              plan {
                code
                contractPreriod
                id
              }
              requiredTwoFactorAuth
              suspensionFlg
              versionNo
            }
            personalTeam
            status
            versionNo
          }
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        registerDateTime
        remainingTimeSec
        scheduleWorkingTimeSec
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInMyTask
        sortNoInTaskStatus
        taskStatus {
          endStatus
          id
          name
          project {
            calendarLinkageCode
            color
            complete
            completeDateTime
            costBudgetWithoutTax
            description
            estimateTimeSec
            id
            key
            laborCost
            laborCostWithoutTax
            laborShare
            name
            priority
            registerDateTime
            salesAmountWithoutTax
            scheduledEndDateTime
            scheduledStartDateTime
            sortNoInGanttChart
            sortNoInList
            sortNoInProjectStatus
            totalScheduleTimeSec
            versionNo
            workingTimeSec
          }
          sortNo
          versionNo
        }
        title
        versionNo
        workingMembers {
          lastWorkingStartDateTime
          lastWorkingStopDateTime
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
        }
        workingTimeSec
      }
      taskId
      taskTitle
      teamId
      teamName
      versionNo
    }
  }
`;

/**
 * __useTaskCommentsQuery__
 *
 * To run a query within a React component, call `useTaskCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskCommentsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sortOrder: // value for 'sortOrder'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskCommentsQuery(
  baseOptions: Apollo.QueryHookOptions<TaskCommentsQuery, TaskCommentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TaskCommentsQuery, TaskCommentsQueryVariables>(
    TaskCommentsDocument,
    options
  );
}
export function useTaskCommentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TaskCommentsQuery, TaskCommentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TaskCommentsQuery, TaskCommentsQueryVariables>(
    TaskCommentsDocument,
    options
  );
}
export type TaskCommentsQueryHookResult = ReturnType<typeof useTaskCommentsQuery>;
export type TaskCommentsLazyQueryHookResult = ReturnType<typeof useTaskCommentsLazyQuery>;
export type TaskCommentsQueryResult = Apollo.QueryResult<
  TaskCommentsQuery,
  TaskCommentsQueryVariables
>;
export const TaskCommentsMinDocument = gql`
  query taskCommentsMin($limit: Int, $offset: Int, $sortOrder: SortOrder, $taskId: String!) {
    taskCommentsMin(limit: $limit, offset: $offset, sortOrder: $sortOrder, taskId: $taskId) {
      comment
      commentedDateTime
      createdMember {
        id
        name
        profileImageUrl
      }
      id
      versionNo
    }
  }
`;

/**
 * __useTaskCommentsMinQuery__
 *
 * To run a query within a React component, call `useTaskCommentsMinQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskCommentsMinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskCommentsMinQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sortOrder: // value for 'sortOrder'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskCommentsMinQuery(
  baseOptions: Apollo.QueryHookOptions<TaskCommentsMinQuery, TaskCommentsMinQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TaskCommentsMinQuery, TaskCommentsMinQueryVariables>(
    TaskCommentsMinDocument,
    options
  );
}
export function useTaskCommentsMinLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TaskCommentsMinQuery, TaskCommentsMinQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TaskCommentsMinQuery, TaskCommentsMinQueryVariables>(
    TaskCommentsMinDocument,
    options
  );
}
export type TaskCommentsMinQueryHookResult = ReturnType<typeof useTaskCommentsMinQuery>;
export type TaskCommentsMinLazyQueryHookResult = ReturnType<typeof useTaskCommentsMinLazyQuery>;
export type TaskCommentsMinQueryResult = Apollo.QueryResult<
  TaskCommentsMinQuery,
  TaskCommentsMinQueryVariables
>;
export const TaskCustomAttributeDocument = gql`
  query taskCustomAttribute($id: ID!) {
    taskCustomAttribute(id: $id) {
      id
      listItem {
        id
        sortNo
        value
        versionNo
      }
      master {
        id
        listItem {
          id
          sortNo
          value
          versionNo
        }
        name
        required
        sortNo
        type
        versionNo
      }
      sortNo
      value
    }
  }
`;

/**
 * __useTaskCustomAttributeQuery__
 *
 * To run a query within a React component, call `useTaskCustomAttributeQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskCustomAttributeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskCustomAttributeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTaskCustomAttributeQuery(
  baseOptions: Apollo.QueryHookOptions<TaskCustomAttributeQuery, TaskCustomAttributeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TaskCustomAttributeQuery, TaskCustomAttributeQueryVariables>(
    TaskCustomAttributeDocument,
    options
  );
}
export function useTaskCustomAttributeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TaskCustomAttributeQuery,
    TaskCustomAttributeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TaskCustomAttributeQuery, TaskCustomAttributeQueryVariables>(
    TaskCustomAttributeDocument,
    options
  );
}
export type TaskCustomAttributeQueryHookResult = ReturnType<typeof useTaskCustomAttributeQuery>;
export type TaskCustomAttributeLazyQueryHookResult = ReturnType<
  typeof useTaskCustomAttributeLazyQuery
>;
export type TaskCustomAttributeQueryResult = Apollo.QueryResult<
  TaskCustomAttributeQuery,
  TaskCustomAttributeQueryVariables
>;
export const TaskCustomAttributeMasterDocument = gql`
  query taskCustomAttributeMaster($id: ID!) {
    taskCustomAttributeMaster(id: $id) {
      id
      listItem {
        id
        sortNo
        value
        versionNo
      }
      name
      required
      sortNo
      type
      versionNo
    }
  }
`;

/**
 * __useTaskCustomAttributeMasterQuery__
 *
 * To run a query within a React component, call `useTaskCustomAttributeMasterQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskCustomAttributeMasterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskCustomAttributeMasterQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTaskCustomAttributeMasterQuery(
  baseOptions: Apollo.QueryHookOptions<
    TaskCustomAttributeMasterQuery,
    TaskCustomAttributeMasterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TaskCustomAttributeMasterQuery, TaskCustomAttributeMasterQueryVariables>(
    TaskCustomAttributeMasterDocument,
    options
  );
}
export function useTaskCustomAttributeMasterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TaskCustomAttributeMasterQuery,
    TaskCustomAttributeMasterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TaskCustomAttributeMasterQuery,
    TaskCustomAttributeMasterQueryVariables
  >(TaskCustomAttributeMasterDocument, options);
}
export type TaskCustomAttributeMasterQueryHookResult = ReturnType<
  typeof useTaskCustomAttributeMasterQuery
>;
export type TaskCustomAttributeMasterLazyQueryHookResult = ReturnType<
  typeof useTaskCustomAttributeMasterLazyQuery
>;
export type TaskCustomAttributeMasterQueryResult = Apollo.QueryResult<
  TaskCustomAttributeMasterQuery,
  TaskCustomAttributeMasterQueryVariables
>;
export const TaskCustomAttributeMastersDocument = gql`
  query taskCustomAttributeMasters {
    taskCustomAttributeMasters {
      id
      listItem {
        id
        sortNo
        value
        versionNo
      }
      name
      required
      sortNo
      type
      versionNo
    }
  }
`;

/**
 * __useTaskCustomAttributeMastersQuery__
 *
 * To run a query within a React component, call `useTaskCustomAttributeMastersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskCustomAttributeMastersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskCustomAttributeMastersQuery({
 *   variables: {
 *   },
 * });
 */
export function useTaskCustomAttributeMastersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TaskCustomAttributeMastersQuery,
    TaskCustomAttributeMastersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TaskCustomAttributeMastersQuery, TaskCustomAttributeMastersQueryVariables>(
    TaskCustomAttributeMastersDocument,
    options
  );
}
export function useTaskCustomAttributeMastersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TaskCustomAttributeMastersQuery,
    TaskCustomAttributeMastersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TaskCustomAttributeMastersQuery,
    TaskCustomAttributeMastersQueryVariables
  >(TaskCustomAttributeMastersDocument, options);
}
export type TaskCustomAttributeMastersQueryHookResult = ReturnType<
  typeof useTaskCustomAttributeMastersQuery
>;
export type TaskCustomAttributeMastersLazyQueryHookResult = ReturnType<
  typeof useTaskCustomAttributeMastersLazyQuery
>;
export type TaskCustomAttributeMastersQueryResult = Apollo.QueryResult<
  TaskCustomAttributeMastersQuery,
  TaskCustomAttributeMastersQueryVariables
>;
export const TaskCustomAttributeTypesDocument = gql`
  query taskCustomAttributeTypes {
    taskCustomAttributeTypes {
      code
      name
      sortNo
    }
  }
`;

/**
 * __useTaskCustomAttributeTypesQuery__
 *
 * To run a query within a React component, call `useTaskCustomAttributeTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskCustomAttributeTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskCustomAttributeTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTaskCustomAttributeTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TaskCustomAttributeTypesQuery,
    TaskCustomAttributeTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TaskCustomAttributeTypesQuery, TaskCustomAttributeTypesQueryVariables>(
    TaskCustomAttributeTypesDocument,
    options
  );
}
export function useTaskCustomAttributeTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TaskCustomAttributeTypesQuery,
    TaskCustomAttributeTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TaskCustomAttributeTypesQuery, TaskCustomAttributeTypesQueryVariables>(
    TaskCustomAttributeTypesDocument,
    options
  );
}
export type TaskCustomAttributeTypesQueryHookResult = ReturnType<
  typeof useTaskCustomAttributeTypesQuery
>;
export type TaskCustomAttributeTypesLazyQueryHookResult = ReturnType<
  typeof useTaskCustomAttributeTypesLazyQuery
>;
export type TaskCustomAttributeTypesQueryResult = Apollo.QueryResult<
  TaskCustomAttributeTypesQuery,
  TaskCustomAttributeTypesQueryVariables
>;
export const TaskCustomAttributesByTaskDocument = gql`
  query taskCustomAttributesByTask($taskId: String!) {
    taskCustomAttributesByTask(taskId: $taskId) {
      id
      listItem {
        id
        sortNo
        value
        versionNo
      }
      master {
        id
        listItem {
          id
          sortNo
          value
          versionNo
        }
        name
        required
        sortNo
        type
        versionNo
      }
      sortNo
      value
    }
  }
`;

/**
 * __useTaskCustomAttributesByTaskQuery__
 *
 * To run a query within a React component, call `useTaskCustomAttributesByTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskCustomAttributesByTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskCustomAttributesByTaskQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskCustomAttributesByTaskQuery(
  baseOptions: Apollo.QueryHookOptions<
    TaskCustomAttributesByTaskQuery,
    TaskCustomAttributesByTaskQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TaskCustomAttributesByTaskQuery, TaskCustomAttributesByTaskQueryVariables>(
    TaskCustomAttributesByTaskDocument,
    options
  );
}
export function useTaskCustomAttributesByTaskLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TaskCustomAttributesByTaskQuery,
    TaskCustomAttributesByTaskQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TaskCustomAttributesByTaskQuery,
    TaskCustomAttributesByTaskQueryVariables
  >(TaskCustomAttributesByTaskDocument, options);
}
export type TaskCustomAttributesByTaskQueryHookResult = ReturnType<
  typeof useTaskCustomAttributesByTaskQuery
>;
export type TaskCustomAttributesByTaskLazyQueryHookResult = ReturnType<
  typeof useTaskCustomAttributesByTaskLazyQuery
>;
export type TaskCustomAttributesByTaskQueryResult = Apollo.QueryResult<
  TaskCustomAttributesByTaskQuery,
  TaskCustomAttributesByTaskQueryVariables
>;
export const TaskDateGraphDataDocument = gql`
  query taskDateGraphData($input: TaskGraphDataInputInput!) {
    taskDateGraphData(input: $input) {
      date
      items {
        color
        itemType
        percent
        projectId
        projectName
        taskId
        taskTitle
        teamId
        teamName
        workingTimeSec
      }
      reportType
    }
  }
`;

/**
 * __useTaskDateGraphDataQuery__
 *
 * To run a query within a React component, call `useTaskDateGraphDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskDateGraphDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskDateGraphDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskDateGraphDataQuery(
  baseOptions: Apollo.QueryHookOptions<TaskDateGraphDataQuery, TaskDateGraphDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TaskDateGraphDataQuery, TaskDateGraphDataQueryVariables>(
    TaskDateGraphDataDocument,
    options
  );
}
export function useTaskDateGraphDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TaskDateGraphDataQuery, TaskDateGraphDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TaskDateGraphDataQuery, TaskDateGraphDataQueryVariables>(
    TaskDateGraphDataDocument,
    options
  );
}
export type TaskDateGraphDataQueryHookResult = ReturnType<typeof useTaskDateGraphDataQuery>;
export type TaskDateGraphDataLazyQueryHookResult = ReturnType<typeof useTaskDateGraphDataLazyQuery>;
export type TaskDateGraphDataQueryResult = Apollo.QueryResult<
  TaskDateGraphDataQuery,
  TaskDateGraphDataQueryVariables
>;
export const TaskRemainderDocument = gql`
  query taskRemainder($id: String) {
    taskRemainder(id: $id) {
      beforeDeadlineMinutes
      id
      notificationSentFlg
      targetMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      task {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        asssignee {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        calendarLinkageCode
        completeDateTime
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        estimatedRemainingTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        laborCost
        priority
        progressDeviationRate
        progressRate
        project {
          assignees {
            sortNo
          }
          calendarLinkageCode
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          estimateTimeSec
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            sortNo
          }
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          projectStatus {
            endStatus
            id
            name
            sortNo
            teamId
            versionNo
          }
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          team {
            archived
            id
            name
            organization {
              allowConnectThirdPartyCalendar
              contactMailAddress
              contractPreriod
              id
              imageUrl
              ipRestrictionFlg
              name
              plan {
                code
                contractPreriod
                id
              }
              requiredTwoFactorAuth
              suspensionFlg
              versionNo
            }
            personalTeam
            status
            versionNo
          }
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        registerDateTime
        remainingTimeSec
        scheduleWorkingTimeSec
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInMyTask
        sortNoInTaskStatus
        taskStatus {
          endStatus
          id
          name
          project {
            calendarLinkageCode
            color
            complete
            completeDateTime
            costBudgetWithoutTax
            description
            estimateTimeSec
            id
            key
            laborCost
            laborCostWithoutTax
            laborShare
            name
            priority
            registerDateTime
            salesAmountWithoutTax
            scheduledEndDateTime
            scheduledStartDateTime
            sortNoInGanttChart
            sortNoInList
            sortNoInProjectStatus
            totalScheduleTimeSec
            versionNo
            workingTimeSec
          }
          sortNo
          versionNo
        }
        title
        versionNo
        workingMembers {
          lastWorkingStartDateTime
          lastWorkingStopDateTime
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
        }
        workingTimeSec
      }
      taskRemainderDestType
      taskRemainderType
    }
  }
`;

/**
 * __useTaskRemainderQuery__
 *
 * To run a query within a React component, call `useTaskRemainderQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskRemainderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskRemainderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTaskRemainderQuery(
  baseOptions?: Apollo.QueryHookOptions<TaskRemainderQuery, TaskRemainderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TaskRemainderQuery, TaskRemainderQueryVariables>(
    TaskRemainderDocument,
    options
  );
}
export function useTaskRemainderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TaskRemainderQuery, TaskRemainderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TaskRemainderQuery, TaskRemainderQueryVariables>(
    TaskRemainderDocument,
    options
  );
}
export type TaskRemainderQueryHookResult = ReturnType<typeof useTaskRemainderQuery>;
export type TaskRemainderLazyQueryHookResult = ReturnType<typeof useTaskRemainderLazyQuery>;
export type TaskRemainderQueryResult = Apollo.QueryResult<
  TaskRemainderQuery,
  TaskRemainderQueryVariables
>;
export const TaskRemaindersDocument = gql`
  query taskRemainders($taskId: String!) {
    taskRemainders(taskId: $taskId) {
      beforeDeadlineMinutes
      id
      notificationSentFlg
      targetMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      task {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        asssignee {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        calendarLinkageCode
        completeDateTime
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        estimatedRemainingTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        laborCost
        priority
        progressDeviationRate
        progressRate
        project {
          assignees {
            sortNo
          }
          calendarLinkageCode
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          estimateTimeSec
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            sortNo
          }
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          projectStatus {
            endStatus
            id
            name
            sortNo
            teamId
            versionNo
          }
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          team {
            archived
            id
            name
            organization {
              allowConnectThirdPartyCalendar
              contactMailAddress
              contractPreriod
              id
              imageUrl
              ipRestrictionFlg
              name
              plan {
                code
                contractPreriod
                id
              }
              requiredTwoFactorAuth
              suspensionFlg
              versionNo
            }
            personalTeam
            status
            versionNo
          }
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        registerDateTime
        remainingTimeSec
        scheduleWorkingTimeSec
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInMyTask
        sortNoInTaskStatus
        taskStatus {
          endStatus
          id
          name
          project {
            calendarLinkageCode
            color
            complete
            completeDateTime
            costBudgetWithoutTax
            description
            estimateTimeSec
            id
            key
            laborCost
            laborCostWithoutTax
            laborShare
            name
            priority
            registerDateTime
            salesAmountWithoutTax
            scheduledEndDateTime
            scheduledStartDateTime
            sortNoInGanttChart
            sortNoInList
            sortNoInProjectStatus
            totalScheduleTimeSec
            versionNo
            workingTimeSec
          }
          sortNo
          versionNo
        }
        title
        versionNo
        workingMembers {
          lastWorkingStartDateTime
          lastWorkingStopDateTime
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
        }
        workingTimeSec
      }
      taskRemainderDestType
      taskRemainderType
    }
  }
`;

/**
 * __useTaskRemaindersQuery__
 *
 * To run a query within a React component, call `useTaskRemaindersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskRemaindersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskRemaindersQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskRemaindersQuery(
  baseOptions: Apollo.QueryHookOptions<TaskRemaindersQuery, TaskRemaindersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TaskRemaindersQuery, TaskRemaindersQueryVariables>(
    TaskRemaindersDocument,
    options
  );
}
export function useTaskRemaindersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TaskRemaindersQuery, TaskRemaindersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TaskRemaindersQuery, TaskRemaindersQueryVariables>(
    TaskRemaindersDocument,
    options
  );
}
export type TaskRemaindersQueryHookResult = ReturnType<typeof useTaskRemaindersQuery>;
export type TaskRemaindersLazyQueryHookResult = ReturnType<typeof useTaskRemaindersLazyQuery>;
export type TaskRemaindersQueryResult = Apollo.QueryResult<
  TaskRemaindersQuery,
  TaskRemaindersQueryVariables
>;
export const TaskStatusDocument = gql`
  query taskStatus($id: String!) {
    taskStatus(id: $id) {
      endStatus
      id
      name
      project {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        calendarLinkageCode
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        color
        complete
        completeDateTime
        costBudgetWithoutTax
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        key
        laborCost
        laborCostWithoutTax
        laborShare
        name
        priority
        projectStatus {
          endStatus
          id
          name
          sortNo
          teamId
          versionNo
        }
        registerDateTime
        salesAmountWithoutTax
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInProjectStatus
        team {
          archived
          id
          name
          organization {
            allowConnectThirdPartyCalendar
            contactMailAddress
            contractPreriod
            id
            imageUrl
            ipRestrictionFlg
            name
            plan {
              code
              contractPreriod
              id
            }
            requiredTwoFactorAuth
            suspensionFlg
            versionNo
          }
          personalTeam
          status
          versionNo
        }
        totalScheduleTimeSec
        versionNo
        workingTimeSec
      }
      sortNo
      versionNo
    }
  }
`;

/**
 * __useTaskStatusQuery__
 *
 * To run a query within a React component, call `useTaskStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskStatusQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTaskStatusQuery(
  baseOptions: Apollo.QueryHookOptions<TaskStatusQuery, TaskStatusQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TaskStatusQuery, TaskStatusQueryVariables>(TaskStatusDocument, options);
}
export function useTaskStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TaskStatusQuery, TaskStatusQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TaskStatusQuery, TaskStatusQueryVariables>(
    TaskStatusDocument,
    options
  );
}
export type TaskStatusQueryHookResult = ReturnType<typeof useTaskStatusQuery>;
export type TaskStatusLazyQueryHookResult = ReturnType<typeof useTaskStatusLazyQuery>;
export type TaskStatusQueryResult = Apollo.QueryResult<TaskStatusQuery, TaskStatusQueryVariables>;
export const TaskSunburstGraphDataDocument = gql`
  query taskSunburstGraphData($input: TaskGraphDataInputInput!) {
    taskSunburstGraphData(input: $input) {
      children {
        children {
          color
          itemType
          name
          percent
          taskId
          workingTimeSec
        }
        color
        name
        projectId
      }
      color
      name
      reportType
      teamId
    }
  }
`;

/**
 * __useTaskSunburstGraphDataQuery__
 *
 * To run a query within a React component, call `useTaskSunburstGraphDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskSunburstGraphDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskSunburstGraphDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskSunburstGraphDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    TaskSunburstGraphDataQuery,
    TaskSunburstGraphDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TaskSunburstGraphDataQuery, TaskSunburstGraphDataQueryVariables>(
    TaskSunburstGraphDataDocument,
    options
  );
}
export function useTaskSunburstGraphDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TaskSunburstGraphDataQuery,
    TaskSunburstGraphDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TaskSunburstGraphDataQuery, TaskSunburstGraphDataQueryVariables>(
    TaskSunburstGraphDataDocument,
    options
  );
}
export type TaskSunburstGraphDataQueryHookResult = ReturnType<typeof useTaskSunburstGraphDataQuery>;
export type TaskSunburstGraphDataLazyQueryHookResult = ReturnType<
  typeof useTaskSunburstGraphDataLazyQuery
>;
export type TaskSunburstGraphDataQueryResult = Apollo.QueryResult<
  TaskSunburstGraphDataQuery,
  TaskSunburstGraphDataQueryVariables
>;
export const TaskTagsDocument = gql`
  query taskTags($taskId: String!) {
    taskTags(taskId: $taskId) {
      id
      name
      note
      organization {
        allowConnectThirdPartyCalendar
        contactMailAddress
        contractPreriod
        id
        imageUrl
        ipRestrictionFlg
        name
        plan {
          code
          contractPreriod
          id
        }
        requiredTwoFactorAuth
        suspensionFlg
        versionNo
      }
      relatedTaskCount
      sortNo
      versionNo
    }
  }
`;

/**
 * __useTaskTagsQuery__
 *
 * To run a query within a React component, call `useTaskTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskTagsQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskTagsQuery(
  baseOptions: Apollo.QueryHookOptions<TaskTagsQuery, TaskTagsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TaskTagsQuery, TaskTagsQueryVariables>(TaskTagsDocument, options);
}
export function useTaskTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TaskTagsQuery, TaskTagsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TaskTagsQuery, TaskTagsQueryVariables>(TaskTagsDocument, options);
}
export type TaskTagsQueryHookResult = ReturnType<typeof useTaskTagsQuery>;
export type TaskTagsLazyQueryHookResult = ReturnType<typeof useTaskTagsLazyQuery>;
export type TaskTagsQueryResult = Apollo.QueryResult<TaskTagsQuery, TaskTagsQueryVariables>;
export const TaskWorkingHistoriesDocument = gql`
  query taskWorkingHistories(
    $end: DateTime
    $limit: Int
    $offset: Int
    $onlySelfData: Boolean
    $start: DateTime
    $taskId: String!
  ) {
    taskWorkingHistories(
      end: $end
      limit: $limit
      offset: $offset
      onlySelfData: $onlySelfData
      start: $start
      taskId: $taskId
    ) {
      calendarEvent {
        calendarType
        clientId
        clientName
        endDateTime
        eventName
        id
        laborCost
        personalTeam
        projectColor
        projectId
        projectName
        startDateTime
        taskId
        taskTitle
        teamId
        teamName
        thirdPartyCalendarEventId
        thirdPartyCalendarInfoList {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          thirdPartyCalendarId
        }
        versionNo
        workingTimeSec
      }
      closingProcessInfo {
        closed
        targetDateTime
      }
      end
      id
      memo
      start
      task {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        asssignee {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        calendarLinkageCode
        completeDateTime
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        estimatedRemainingTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        laborCost
        priority
        progressDeviationRate
        progressRate
        project {
          assignees {
            sortNo
          }
          calendarLinkageCode
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          estimateTimeSec
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            sortNo
          }
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          projectStatus {
            endStatus
            id
            name
            sortNo
            teamId
            versionNo
          }
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          team {
            archived
            id
            name
            organization {
              allowConnectThirdPartyCalendar
              contactMailAddress
              contractPreriod
              id
              imageUrl
              ipRestrictionFlg
              name
              plan {
                code
                contractPreriod
                id
              }
              requiredTwoFactorAuth
              suspensionFlg
              versionNo
            }
            personalTeam
            status
            versionNo
          }
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        registerDateTime
        remainingTimeSec
        scheduleWorkingTimeSec
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInMyTask
        sortNoInTaskStatus
        taskStatus {
          endStatus
          id
          name
          project {
            calendarLinkageCode
            color
            complete
            completeDateTime
            costBudgetWithoutTax
            description
            estimateTimeSec
            id
            key
            laborCost
            laborCostWithoutTax
            laborShare
            name
            priority
            registerDateTime
            salesAmountWithoutTax
            scheduledEndDateTime
            scheduledStartDateTime
            sortNoInGanttChart
            sortNoInList
            sortNoInProjectStatus
            totalScheduleTimeSec
            versionNo
            workingTimeSec
          }
          sortNo
          versionNo
        }
        title
        versionNo
        workingMembers {
          lastWorkingStartDateTime
          lastWorkingStopDateTime
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
        }
        workingTimeSec
      }
      versionNo
      workingMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      workingTimeSec
    }
  }
`;

/**
 * __useTaskWorkingHistoriesQuery__
 *
 * To run a query within a React component, call `useTaskWorkingHistoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskWorkingHistoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskWorkingHistoriesQuery({
 *   variables: {
 *      end: // value for 'end'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      onlySelfData: // value for 'onlySelfData'
 *      start: // value for 'start'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskWorkingHistoriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    TaskWorkingHistoriesQuery,
    TaskWorkingHistoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TaskWorkingHistoriesQuery, TaskWorkingHistoriesQueryVariables>(
    TaskWorkingHistoriesDocument,
    options
  );
}
export function useTaskWorkingHistoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TaskWorkingHistoriesQuery,
    TaskWorkingHistoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TaskWorkingHistoriesQuery, TaskWorkingHistoriesQueryVariables>(
    TaskWorkingHistoriesDocument,
    options
  );
}
export type TaskWorkingHistoriesQueryHookResult = ReturnType<typeof useTaskWorkingHistoriesQuery>;
export type TaskWorkingHistoriesLazyQueryHookResult = ReturnType<
  typeof useTaskWorkingHistoriesLazyQuery
>;
export type TaskWorkingHistoriesQueryResult = Apollo.QueryResult<
  TaskWorkingHistoriesQuery,
  TaskWorkingHistoriesQueryVariables
>;
export const TaskWorkingHistoriesForSummaryDocument = gql`
  query taskWorkingHistoriesForSummary(
    $end: DateTime
    $limit: Int
    $memberId: String
    $offset: Int
    $onlySelfData: Boolean
    $start: DateTime
    $taskId: String!
    $teamId: String
  ) {
    taskWorkingHistoriesForSummary(
      end: $end
      limit: $limit
      memberId: $memberId
      offset: $offset
      onlySelfData: $onlySelfData
      start: $start
      taskId: $taskId
      teamId: $teamId
    ) {
      calendarEvent {
        clientId
        clientName
        eventName
        id
        projectId
        projectName
        teamId
      }
      closingProcessInfo {
        closed
        targetDateTime
      }
      end
      id
      memo
      start
      task {
        id
        project {
          client {
            id
            name
          }
          id
          name
          team {
            id
            personalTeam
          }
        }
        title
        versionNo
      }
      versionNo
      workingMember {
        id
        name
        profileImageUrl
      }
      workingTimeSec
    }
  }
`;

/**
 * __useTaskWorkingHistoriesForSummaryQuery__
 *
 * To run a query within a React component, call `useTaskWorkingHistoriesForSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskWorkingHistoriesForSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskWorkingHistoriesForSummaryQuery({
 *   variables: {
 *      end: // value for 'end'
 *      limit: // value for 'limit'
 *      memberId: // value for 'memberId'
 *      offset: // value for 'offset'
 *      onlySelfData: // value for 'onlySelfData'
 *      start: // value for 'start'
 *      taskId: // value for 'taskId'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useTaskWorkingHistoriesForSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    TaskWorkingHistoriesForSummaryQuery,
    TaskWorkingHistoriesForSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TaskWorkingHistoriesForSummaryQuery,
    TaskWorkingHistoriesForSummaryQueryVariables
  >(TaskWorkingHistoriesForSummaryDocument, options);
}
export function useTaskWorkingHistoriesForSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TaskWorkingHistoriesForSummaryQuery,
    TaskWorkingHistoriesForSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TaskWorkingHistoriesForSummaryQuery,
    TaskWorkingHistoriesForSummaryQueryVariables
  >(TaskWorkingHistoriesForSummaryDocument, options);
}
export type TaskWorkingHistoriesForSummaryQueryHookResult = ReturnType<
  typeof useTaskWorkingHistoriesForSummaryQuery
>;
export type TaskWorkingHistoriesForSummaryLazyQueryHookResult = ReturnType<
  typeof useTaskWorkingHistoriesForSummaryLazyQuery
>;
export type TaskWorkingHistoriesForSummaryQueryResult = Apollo.QueryResult<
  TaskWorkingHistoriesForSummaryQuery,
  TaskWorkingHistoriesForSummaryQueryVariables
>;
export const TaskWorkingHistorySummaryGroupByAssineeDocument = gql`
  query taskWorkingHistorySummaryGroupByAssinee($taskId: String!, $teamId: String) {
    taskWorkingHistorySummaryGroupByAssinee(taskId: $taskId, teamId: $teamId) {
      lastWorkingStartDateTime
      member {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      totalWorkingTimeSec
      tracking
    }
  }
`;

/**
 * __useTaskWorkingHistorySummaryGroupByAssineeQuery__
 *
 * To run a query within a React component, call `useTaskWorkingHistorySummaryGroupByAssineeQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskWorkingHistorySummaryGroupByAssineeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskWorkingHistorySummaryGroupByAssineeQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useTaskWorkingHistorySummaryGroupByAssineeQuery(
  baseOptions: Apollo.QueryHookOptions<
    TaskWorkingHistorySummaryGroupByAssineeQuery,
    TaskWorkingHistorySummaryGroupByAssineeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TaskWorkingHistorySummaryGroupByAssineeQuery,
    TaskWorkingHistorySummaryGroupByAssineeQueryVariables
  >(TaskWorkingHistorySummaryGroupByAssineeDocument, options);
}
export function useTaskWorkingHistorySummaryGroupByAssineeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TaskWorkingHistorySummaryGroupByAssineeQuery,
    TaskWorkingHistorySummaryGroupByAssineeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TaskWorkingHistorySummaryGroupByAssineeQuery,
    TaskWorkingHistorySummaryGroupByAssineeQueryVariables
  >(TaskWorkingHistorySummaryGroupByAssineeDocument, options);
}
export type TaskWorkingHistorySummaryGroupByAssineeQueryHookResult = ReturnType<
  typeof useTaskWorkingHistorySummaryGroupByAssineeQuery
>;
export type TaskWorkingHistorySummaryGroupByAssineeLazyQueryHookResult = ReturnType<
  typeof useTaskWorkingHistorySummaryGroupByAssineeLazyQuery
>;
export type TaskWorkingHistorySummaryGroupByAssineeQueryResult = Apollo.QueryResult<
  TaskWorkingHistorySummaryGroupByAssineeQuery,
  TaskWorkingHistorySummaryGroupByAssineeQueryVariables
>;
export const TaskWorkingScheduleSummaryGroupByAssineeDocument = gql`
  query taskWorkingScheduleSummaryGroupByAssinee($taskId: String!, $teamId: String) {
    taskWorkingScheduleSummaryGroupByAssinee(taskId: $taskId, teamId: $teamId) {
      member {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      totalWorkingTimeSec
    }
  }
`;

/**
 * __useTaskWorkingScheduleSummaryGroupByAssineeQuery__
 *
 * To run a query within a React component, call `useTaskWorkingScheduleSummaryGroupByAssineeQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskWorkingScheduleSummaryGroupByAssineeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskWorkingScheduleSummaryGroupByAssineeQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useTaskWorkingScheduleSummaryGroupByAssineeQuery(
  baseOptions: Apollo.QueryHookOptions<
    TaskWorkingScheduleSummaryGroupByAssineeQuery,
    TaskWorkingScheduleSummaryGroupByAssineeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TaskWorkingScheduleSummaryGroupByAssineeQuery,
    TaskWorkingScheduleSummaryGroupByAssineeQueryVariables
  >(TaskWorkingScheduleSummaryGroupByAssineeDocument, options);
}
export function useTaskWorkingScheduleSummaryGroupByAssineeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TaskWorkingScheduleSummaryGroupByAssineeQuery,
    TaskWorkingScheduleSummaryGroupByAssineeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TaskWorkingScheduleSummaryGroupByAssineeQuery,
    TaskWorkingScheduleSummaryGroupByAssineeQueryVariables
  >(TaskWorkingScheduleSummaryGroupByAssineeDocument, options);
}
export type TaskWorkingScheduleSummaryGroupByAssineeQueryHookResult = ReturnType<
  typeof useTaskWorkingScheduleSummaryGroupByAssineeQuery
>;
export type TaskWorkingScheduleSummaryGroupByAssineeLazyQueryHookResult = ReturnType<
  typeof useTaskWorkingScheduleSummaryGroupByAssineeLazyQuery
>;
export type TaskWorkingScheduleSummaryGroupByAssineeQueryResult = Apollo.QueryResult<
  TaskWorkingScheduleSummaryGroupByAssineeQuery,
  TaskWorkingScheduleSummaryGroupByAssineeQueryVariables
>;
export const TaskWorkingSchedulesDocument = gql`
  query taskWorkingSchedules($limit: Int, $offset: Int, $onlySelfData: Boolean, $taskId: String!) {
    taskWorkingSchedules(
      limit: $limit
      offset: $offset
      onlySelfData: $onlySelfData
      taskId: $taskId
    ) {
      calendarEvent {
        calendarType
        clientId
        clientName
        endDateTime
        eventName
        id
        laborCost
        personalTeam
        projectColor
        projectId
        projectName
        startDateTime
        taskId
        taskTitle
        teamId
        teamName
        thirdPartyCalendarEventId
        thirdPartyCalendarInfoList {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          thirdPartyCalendarId
        }
        versionNo
        workingTimeSec
      }
      end
      id
      memo
      start
      task {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        asssignee {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        calendarLinkageCode
        completeDateTime
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        estimatedRemainingTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        laborCost
        priority
        progressDeviationRate
        progressRate
        project {
          assignees {
            sortNo
          }
          calendarLinkageCode
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          estimateTimeSec
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            sortNo
          }
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          projectStatus {
            endStatus
            id
            name
            sortNo
            teamId
            versionNo
          }
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          team {
            archived
            id
            name
            organization {
              allowConnectThirdPartyCalendar
              contactMailAddress
              contractPreriod
              id
              imageUrl
              ipRestrictionFlg
              name
              plan {
                code
                contractPreriod
                id
              }
              requiredTwoFactorAuth
              suspensionFlg
              versionNo
            }
            personalTeam
            status
            versionNo
          }
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        registerDateTime
        remainingTimeSec
        scheduleWorkingTimeSec
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInMyTask
        sortNoInTaskStatus
        taskStatus {
          endStatus
          id
          name
          project {
            calendarLinkageCode
            color
            complete
            completeDateTime
            costBudgetWithoutTax
            description
            estimateTimeSec
            id
            key
            laborCost
            laborCostWithoutTax
            laborShare
            name
            priority
            registerDateTime
            salesAmountWithoutTax
            scheduledEndDateTime
            scheduledStartDateTime
            sortNoInGanttChart
            sortNoInList
            sortNoInProjectStatus
            totalScheduleTimeSec
            versionNo
            workingTimeSec
          }
          sortNo
          versionNo
        }
        title
        versionNo
        workingMembers {
          lastWorkingStartDateTime
          lastWorkingStopDateTime
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
        }
        workingTimeSec
      }
      versionNo
      workingMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      workingTimeSec
    }
  }
`;

/**
 * __useTaskWorkingSchedulesQuery__
 *
 * To run a query within a React component, call `useTaskWorkingSchedulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskWorkingSchedulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskWorkingSchedulesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      onlySelfData: // value for 'onlySelfData'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskWorkingSchedulesQuery(
  baseOptions: Apollo.QueryHookOptions<
    TaskWorkingSchedulesQuery,
    TaskWorkingSchedulesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TaskWorkingSchedulesQuery, TaskWorkingSchedulesQueryVariables>(
    TaskWorkingSchedulesDocument,
    options
  );
}
export function useTaskWorkingSchedulesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TaskWorkingSchedulesQuery,
    TaskWorkingSchedulesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TaskWorkingSchedulesQuery, TaskWorkingSchedulesQueryVariables>(
    TaskWorkingSchedulesDocument,
    options
  );
}
export type TaskWorkingSchedulesQueryHookResult = ReturnType<typeof useTaskWorkingSchedulesQuery>;
export type TaskWorkingSchedulesLazyQueryHookResult = ReturnType<
  typeof useTaskWorkingSchedulesLazyQuery
>;
export type TaskWorkingSchedulesQueryResult = Apollo.QueryResult<
  TaskWorkingSchedulesQuery,
  TaskWorkingSchedulesQueryVariables
>;
export const TaskWorkingSchedulesForSummaryDocument = gql`
  query taskWorkingSchedulesForSummary(
    $limit: Int
    $offset: Int
    $onlySelfData: Boolean
    $taskId: String!
  ) {
    taskWorkingSchedulesForSummary(
      limit: $limit
      offset: $offset
      onlySelfData: $onlySelfData
      taskId: $taskId
    ) {
      calendarEvent {
        clientId
        clientName
        eventName
        id
        projectId
        projectName
        teamId
      }
      end
      id
      memo
      start
      task {
        id
        project {
          client {
            id
            name
          }
          id
          name
          team {
            id
            personalTeam
          }
        }
        title
        versionNo
      }
      versionNo
      workingMember {
        id
        name
        profileImageUrl
      }
      workingTimeSec
    }
  }
`;

/**
 * __useTaskWorkingSchedulesForSummaryQuery__
 *
 * To run a query within a React component, call `useTaskWorkingSchedulesForSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskWorkingSchedulesForSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskWorkingSchedulesForSummaryQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      onlySelfData: // value for 'onlySelfData'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskWorkingSchedulesForSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    TaskWorkingSchedulesForSummaryQuery,
    TaskWorkingSchedulesForSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TaskWorkingSchedulesForSummaryQuery,
    TaskWorkingSchedulesForSummaryQueryVariables
  >(TaskWorkingSchedulesForSummaryDocument, options);
}
export function useTaskWorkingSchedulesForSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TaskWorkingSchedulesForSummaryQuery,
    TaskWorkingSchedulesForSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TaskWorkingSchedulesForSummaryQuery,
    TaskWorkingSchedulesForSummaryQueryVariables
  >(TaskWorkingSchedulesForSummaryDocument, options);
}
export type TaskWorkingSchedulesForSummaryQueryHookResult = ReturnType<
  typeof useTaskWorkingSchedulesForSummaryQuery
>;
export type TaskWorkingSchedulesForSummaryLazyQueryHookResult = ReturnType<
  typeof useTaskWorkingSchedulesForSummaryLazyQuery
>;
export type TaskWorkingSchedulesForSummaryQueryResult = Apollo.QueryResult<
  TaskWorkingSchedulesForSummaryQuery,
  TaskWorkingSchedulesForSummaryQueryVariables
>;
export const TeamDocument = gql`
  query team($id: ID!) {
    team(id: $id) {
      archived
      id
      name
      organization {
        allowConnectThirdPartyCalendar
        contactMailAddress
        contractPreriod
        id
        imageUrl
        ipRestrictionFlg
        name
        plan {
          code
          contractPreriod
          id
        }
        requiredTwoFactorAuth
        suspensionFlg
        versionNo
      }
      personalTeam
      status
      versionNo
    }
  }
`;

/**
 * __useTeamQuery__
 *
 * To run a query within a React component, call `useTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTeamQuery(baseOptions: Apollo.QueryHookOptions<TeamQuery, TeamQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TeamQuery, TeamQueryVariables>(TeamDocument, options);
}
export function useTeamLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TeamQuery, TeamQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TeamQuery, TeamQueryVariables>(TeamDocument, options);
}
export type TeamQueryHookResult = ReturnType<typeof useTeamQuery>;
export type TeamLazyQueryHookResult = ReturnType<typeof useTeamLazyQuery>;
export type TeamQueryResult = Apollo.QueryResult<TeamQuery, TeamQueryVariables>;
export const TeamBarGraphDataDocument = gql`
  query teamBarGraphData($input: TeamtGraphDataInputInput!) {
    teamBarGraphData(input: $input) {
      color
      percent
      reportType
      teamId
      teamName
      workingTimeSec
    }
  }
`;

/**
 * __useTeamBarGraphDataQuery__
 *
 * To run a query within a React component, call `useTeamBarGraphDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamBarGraphDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamBarGraphDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTeamBarGraphDataQuery(
  baseOptions: Apollo.QueryHookOptions<TeamBarGraphDataQuery, TeamBarGraphDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TeamBarGraphDataQuery, TeamBarGraphDataQueryVariables>(
    TeamBarGraphDataDocument,
    options
  );
}
export function useTeamBarGraphDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TeamBarGraphDataQuery, TeamBarGraphDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TeamBarGraphDataQuery, TeamBarGraphDataQueryVariables>(
    TeamBarGraphDataDocument,
    options
  );
}
export type TeamBarGraphDataQueryHookResult = ReturnType<typeof useTeamBarGraphDataQuery>;
export type TeamBarGraphDataLazyQueryHookResult = ReturnType<typeof useTeamBarGraphDataLazyQuery>;
export type TeamBarGraphDataQueryResult = Apollo.QueryResult<
  TeamBarGraphDataQuery,
  TeamBarGraphDataQueryVariables
>;
export const TeamClosingSettingsDocument = gql`
  query teamClosingSettings($teamId: String!) {
    teamClosingSettings(teamId: $teamId) {
      closingType
      id
      targetDateTime
      teamId
      timeZone {
        code
        id
        name
        offsetTime
        sortNo
      }
      versionNo
    }
  }
`;

/**
 * __useTeamClosingSettingsQuery__
 *
 * To run a query within a React component, call `useTeamClosingSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamClosingSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamClosingSettingsQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useTeamClosingSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<TeamClosingSettingsQuery, TeamClosingSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TeamClosingSettingsQuery, TeamClosingSettingsQueryVariables>(
    TeamClosingSettingsDocument,
    options
  );
}
export function useTeamClosingSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TeamClosingSettingsQuery,
    TeamClosingSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TeamClosingSettingsQuery, TeamClosingSettingsQueryVariables>(
    TeamClosingSettingsDocument,
    options
  );
}
export type TeamClosingSettingsQueryHookResult = ReturnType<typeof useTeamClosingSettingsQuery>;
export type TeamClosingSettingsLazyQueryHookResult = ReturnType<
  typeof useTeamClosingSettingsLazyQuery
>;
export type TeamClosingSettingsQueryResult = Apollo.QueryResult<
  TeamClosingSettingsQuery,
  TeamClosingSettingsQueryVariables
>;
export const TeamCsvReportDocument = gql`
  query teamCsvReport($id: ID!) {
    teamCsvReport(id: $id) {
      csvReportItemDefinition {
        id
        items {
          id
          reportItemCode
          reportItemName
          sortNo
        }
        name
        versionNo
      }
      end
      id
      includeWorkingData
      reportType
      requestDateTime
      requestMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      sjisFileUrl
      start
      status
      team {
        archived
        id
        name
        organization {
          allowConnectThirdPartyCalendar
          contactMailAddress
          contractPreriod
          id
          imageUrl
          ipRestrictionFlg
          name
          plan {
            code
            contractPreriod
            id
          }
          requiredTwoFactorAuth
          suspensionFlg
          versionNo
        }
        personalTeam
        status
        versionNo
      }
      timeZoneOffset
      utf8FileUrl
      versionNo
    }
  }
`;

/**
 * __useTeamCsvReportQuery__
 *
 * To run a query within a React component, call `useTeamCsvReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamCsvReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamCsvReportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTeamCsvReportQuery(
  baseOptions: Apollo.QueryHookOptions<TeamCsvReportQuery, TeamCsvReportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TeamCsvReportQuery, TeamCsvReportQueryVariables>(
    TeamCsvReportDocument,
    options
  );
}
export function useTeamCsvReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TeamCsvReportQuery, TeamCsvReportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TeamCsvReportQuery, TeamCsvReportQueryVariables>(
    TeamCsvReportDocument,
    options
  );
}
export type TeamCsvReportQueryHookResult = ReturnType<typeof useTeamCsvReportQuery>;
export type TeamCsvReportLazyQueryHookResult = ReturnType<typeof useTeamCsvReportLazyQuery>;
export type TeamCsvReportQueryResult = Apollo.QueryResult<
  TeamCsvReportQuery,
  TeamCsvReportQueryVariables
>;
export const TeamCsvReportsDocument = gql`
  query teamCsvReports($teamId: ID!) {
    teamCsvReports(teamId: $teamId) {
      csvReportItemDefinition {
        id
        items {
          id
          reportItemCode
          reportItemName
          sortNo
        }
        name
        versionNo
      }
      end
      id
      includeWorkingData
      reportType
      requestDateTime
      requestMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      sjisFileUrl
      start
      status
      team {
        archived
        id
        name
        organization {
          allowConnectThirdPartyCalendar
          contactMailAddress
          contractPreriod
          id
          imageUrl
          ipRestrictionFlg
          name
          plan {
            code
            contractPreriod
            id
          }
          requiredTwoFactorAuth
          suspensionFlg
          versionNo
        }
        personalTeam
        status
        versionNo
      }
      timeZoneOffset
      utf8FileUrl
      versionNo
    }
  }
`;

/**
 * __useTeamCsvReportsQuery__
 *
 * To run a query within a React component, call `useTeamCsvReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamCsvReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamCsvReportsQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useTeamCsvReportsQuery(
  baseOptions: Apollo.QueryHookOptions<TeamCsvReportsQuery, TeamCsvReportsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TeamCsvReportsQuery, TeamCsvReportsQueryVariables>(
    TeamCsvReportsDocument,
    options
  );
}
export function useTeamCsvReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TeamCsvReportsQuery, TeamCsvReportsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TeamCsvReportsQuery, TeamCsvReportsQueryVariables>(
    TeamCsvReportsDocument,
    options
  );
}
export type TeamCsvReportsQueryHookResult = ReturnType<typeof useTeamCsvReportsQuery>;
export type TeamCsvReportsLazyQueryHookResult = ReturnType<typeof useTeamCsvReportsLazyQuery>;
export type TeamCsvReportsQueryResult = Apollo.QueryResult<
  TeamCsvReportsQuery,
  TeamCsvReportsQueryVariables
>;
export const TeamDateGraphDataDocument = gql`
  query teamDateGraphData($input: TeamtGraphDataInputInput!) {
    teamDateGraphData(input: $input) {
      date
      items {
        color
        percent
        teamId
        teamName
        workingTimeSec
      }
      reportType
    }
  }
`;

/**
 * __useTeamDateGraphDataQuery__
 *
 * To run a query within a React component, call `useTeamDateGraphDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamDateGraphDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamDateGraphDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTeamDateGraphDataQuery(
  baseOptions: Apollo.QueryHookOptions<TeamDateGraphDataQuery, TeamDateGraphDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TeamDateGraphDataQuery, TeamDateGraphDataQueryVariables>(
    TeamDateGraphDataDocument,
    options
  );
}
export function useTeamDateGraphDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TeamDateGraphDataQuery, TeamDateGraphDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TeamDateGraphDataQuery, TeamDateGraphDataQueryVariables>(
    TeamDateGraphDataDocument,
    options
  );
}
export type TeamDateGraphDataQueryHookResult = ReturnType<typeof useTeamDateGraphDataQuery>;
export type TeamDateGraphDataLazyQueryHookResult = ReturnType<typeof useTeamDateGraphDataLazyQuery>;
export type TeamDateGraphDataQueryResult = Apollo.QueryResult<
  TeamDateGraphDataQuery,
  TeamDateGraphDataQueryVariables
>;
export const TeamMemberRelationDocument = gql`
  query teamMemberRelation($id: ID!) {
    teamMemberRelation(id: $id) {
      id
      openMenu
      sortNo
      team {
        archived
        id
        name
        organization {
          allowConnectThirdPartyCalendar
          contactMailAddress
          contractPreriod
          id
          imageUrl
          ipRestrictionFlg
          name
          plan {
            code
            contractPreriod
            id
          }
          requiredTwoFactorAuth
          suspensionFlg
          versionNo
        }
        personalTeam
        status
        versionNo
      }
      versionNo
    }
  }
`;

/**
 * __useTeamMemberRelationQuery__
 *
 * To run a query within a React component, call `useTeamMemberRelationQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamMemberRelationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamMemberRelationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTeamMemberRelationQuery(
  baseOptions: Apollo.QueryHookOptions<TeamMemberRelationQuery, TeamMemberRelationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TeamMemberRelationQuery, TeamMemberRelationQueryVariables>(
    TeamMemberRelationDocument,
    options
  );
}
export function useTeamMemberRelationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TeamMemberRelationQuery,
    TeamMemberRelationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TeamMemberRelationQuery, TeamMemberRelationQueryVariables>(
    TeamMemberRelationDocument,
    options
  );
}
export type TeamMemberRelationQueryHookResult = ReturnType<typeof useTeamMemberRelationQuery>;
export type TeamMemberRelationLazyQueryHookResult = ReturnType<
  typeof useTeamMemberRelationLazyQuery
>;
export type TeamMemberRelationQueryResult = Apollo.QueryResult<
  TeamMemberRelationQuery,
  TeamMemberRelationQueryVariables
>;
export const TeamMemberRelationsDocument = gql`
  query teamMemberRelations {
    teamMemberRelations {
      id
      openMenu
      sortNo
      team {
        archived
        id
        name
        organization {
          allowConnectThirdPartyCalendar
          contactMailAddress
          contractPreriod
          id
          imageUrl
          ipRestrictionFlg
          name
          plan {
            code
            contractPreriod
            id
          }
          requiredTwoFactorAuth
          suspensionFlg
          versionNo
        }
        personalTeam
        status
        versionNo
      }
      versionNo
    }
  }
`;

/**
 * __useTeamMemberRelationsQuery__
 *
 * To run a query within a React component, call `useTeamMemberRelationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamMemberRelationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamMemberRelationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeamMemberRelationsQuery(
  baseOptions?: Apollo.QueryHookOptions<TeamMemberRelationsQuery, TeamMemberRelationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TeamMemberRelationsQuery, TeamMemberRelationsQueryVariables>(
    TeamMemberRelationsDocument,
    options
  );
}
export function useTeamMemberRelationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TeamMemberRelationsQuery,
    TeamMemberRelationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TeamMemberRelationsQuery, TeamMemberRelationsQueryVariables>(
    TeamMemberRelationsDocument,
    options
  );
}
export type TeamMemberRelationsQueryHookResult = ReturnType<typeof useTeamMemberRelationsQuery>;
export type TeamMemberRelationsLazyQueryHookResult = ReturnType<
  typeof useTeamMemberRelationsLazyQuery
>;
export type TeamMemberRelationsQueryResult = Apollo.QueryResult<
  TeamMemberRelationsQuery,
  TeamMemberRelationsQueryVariables
>;
export const TeamMembersDocument = gql`
  query teamMembers($teamId: String!) {
    teamMembers(teamId: $teamId) {
      adminRole
      clientManagementRole
      department
      employeeNumber
      id
      laborCostManagementRole
      mailAddress
      memberManagementRole
      memberRegistrationDateTime
      memberStatus
      name
      organizationMemberViewRole
      organizationReportRole
      profileImageUrl
      projectCreatePermissionFlg
      projectCustomAttributePermissionFlg
      projectDeletePermissionFlg
      projectUpdatePermissionFlg
      proxyEditWorkingDataRole
      salesManagementRole
      statusMessage
      taskCreatePermissionFlg
      taskCustomAttributePermissionFlg
      taskDeletePermissionFlg
      taskUpdatePermissionFlg
      teamMemberViewRole
      teamReportRole
      versionNo
    }
  }
`;

/**
 * __useTeamMembersQuery__
 *
 * To run a query within a React component, call `useTeamMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamMembersQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useTeamMembersQuery(
  baseOptions: Apollo.QueryHookOptions<TeamMembersQuery, TeamMembersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TeamMembersQuery, TeamMembersQueryVariables>(TeamMembersDocument, options);
}
export function useTeamMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TeamMembersQuery, TeamMembersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TeamMembersQuery, TeamMembersQueryVariables>(
    TeamMembersDocument,
    options
  );
}
export type TeamMembersQueryHookResult = ReturnType<typeof useTeamMembersQuery>;
export type TeamMembersLazyQueryHookResult = ReturnType<typeof useTeamMembersLazyQuery>;
export type TeamMembersQueryResult = Apollo.QueryResult<
  TeamMembersQuery,
  TeamMembersQueryVariables
>;
export const TeamProjectContractsDocument = gql`
  query teamProjectContracts(
    $input: FetchTeamProjectContractsInputInput
    $limit: Int
    $offset: Int
    $teamId: String!
  ) {
    teamProjectContracts(input: $input, limit: $limit, offset: $offset, teamId: $teamId) {
      allMemberMeteredRateContract {
        hourUnitPrice
        id
        member {
          laborCostWithoutTax
          memberId
          name
          profileImageUrl
          roundedTotalWorkingTimeSec
          salesAmountWithoutTax
          totalWorkingTimeSec
        }
        versionNo
      }
      allMemberSettlementRangeContract {
        id
        lowerHourPart {
          deducationHourlyUnitPrice
          lowerHour
        }
        member {
          laborCostWithoutTax
          memberId
          name
          profileImageUrl
          roundedTotalWorkingTimeSec
          salesAmountWithoutTax
          totalWorkingTimeSec
        }
        monthlyUnitPrice
        upperHourPart {
          excessHourlyUnitPrice
          upperHour
        }
        versionNo
      }
      contractType
      costBudgetWithoutTax
      endDateTime
      fixSalesAmountWithoutTax
      grossProfit
      grossProfitRatePercent
      id
      laborCostWithoutTax
      laborShare
      membersMeteredRateContract {
        hourUnitPrice
        id
        member {
          laborCostWithoutTax
          memberId
          name
          profileImageUrl
          roundedTotalWorkingTimeSec
          salesAmountWithoutTax
          totalWorkingTimeSec
        }
        versionNo
      }
      membersSettlementRangeContract {
        id
        lowerHourPart {
          deducationHourlyUnitPrice
          lowerHour
        }
        member {
          laborCostWithoutTax
          memberId
          name
          profileImageUrl
          roundedTotalWorkingTimeSec
          salesAmountWithoutTax
          totalWorkingTimeSec
        }
        monthlyUnitPrice
        upperHourPart {
          excessHourlyUnitPrice
          upperHour
        }
        versionNo
      }
      month
      project {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        calendarLinkageCode
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        color
        complete
        completeDateTime
        costBudgetWithoutTax
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        key
        laborCost
        laborCostWithoutTax
        laborShare
        name
        priority
        projectStatus {
          endStatus
          id
          name
          sortNo
          teamId
          versionNo
        }
        registerDateTime
        salesAmountWithoutTax
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInProjectStatus
        team {
          archived
          id
          name
          organization {
            allowConnectThirdPartyCalendar
            contactMailAddress
            contractPreriod
            id
            imageUrl
            ipRestrictionFlg
            name
            plan {
              code
              contractPreriod
              id
            }
            requiredTwoFactorAuth
            suspensionFlg
            versionNo
          }
          personalTeam
          status
          versionNo
        }
        totalScheduleTimeSec
        versionNo
        workingTimeSec
      }
      roundTimeType
      roundedTotalWorkingTimeSec
      salesAmountWithoutTax
      salesRoundMinutes
      startDateTime
      taxRate
      termType
      timeZone {
        code
        id
        name
        offsetTime
        sortNo
      }
      totalWorkingTimeSec
      unitPriceType
      versionNo
      year
    }
  }
`;

/**
 * __useTeamProjectContractsQuery__
 *
 * To run a query within a React component, call `useTeamProjectContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamProjectContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamProjectContractsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useTeamProjectContractsQuery(
  baseOptions: Apollo.QueryHookOptions<
    TeamProjectContractsQuery,
    TeamProjectContractsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TeamProjectContractsQuery, TeamProjectContractsQueryVariables>(
    TeamProjectContractsDocument,
    options
  );
}
export function useTeamProjectContractsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TeamProjectContractsQuery,
    TeamProjectContractsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TeamProjectContractsQuery, TeamProjectContractsQueryVariables>(
    TeamProjectContractsDocument,
    options
  );
}
export type TeamProjectContractsQueryHookResult = ReturnType<typeof useTeamProjectContractsQuery>;
export type TeamProjectContractsLazyQueryHookResult = ReturnType<
  typeof useTeamProjectContractsLazyQuery
>;
export type TeamProjectContractsQueryResult = Apollo.QueryResult<
  TeamProjectContractsQuery,
  TeamProjectContractsQueryVariables
>;
export const TeamProjectStatusDocument = gql`
  query teamProjectStatus($teamId: String!) {
    teamProjectStatus(teamId: $teamId) {
      endStatus
      id
      name
      sortNo
      teamId
      versionNo
    }
  }
`;

/**
 * __useTeamProjectStatusQuery__
 *
 * To run a query within a React component, call `useTeamProjectStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamProjectStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamProjectStatusQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useTeamProjectStatusQuery(
  baseOptions: Apollo.QueryHookOptions<TeamProjectStatusQuery, TeamProjectStatusQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TeamProjectStatusQuery, TeamProjectStatusQueryVariables>(
    TeamProjectStatusDocument,
    options
  );
}
export function useTeamProjectStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TeamProjectStatusQuery, TeamProjectStatusQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TeamProjectStatusQuery, TeamProjectStatusQueryVariables>(
    TeamProjectStatusDocument,
    options
  );
}
export type TeamProjectStatusQueryHookResult = ReturnType<typeof useTeamProjectStatusQuery>;
export type TeamProjectStatusLazyQueryHookResult = ReturnType<typeof useTeamProjectStatusLazyQuery>;
export type TeamProjectStatusQueryResult = Apollo.QueryResult<
  TeamProjectStatusQuery,
  TeamProjectStatusQueryVariables
>;
export const TeamProjectsDocument = gql`
  query teamProjects(
    $input: FetchTeamProjectsInputInput
    $limit: Int
    $offset: Int
    $teamId: String!
  ) {
    teamProjects(input: $input, limit: $limit, offset: $offset, teamId: $teamId) {
      assignees {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      calendarLinkageCode
      client {
        code
        id
        name
        sortNo
        versionNo
      }
      color
      complete
      completeDateTime
      costBudgetWithoutTax
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      favoriteMembers {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      favoriteMembersInfo {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      id
      key
      laborCost
      laborCostWithoutTax
      laborShare
      name
      priority
      projectStatus {
        endStatus
        id
        name
        sortNo
        teamId
        versionNo
      }
      registerDateTime
      salesAmountWithoutTax
      scheduledEndDateTime
      scheduledStartDateTime
      sortNoInGanttChart
      sortNoInList
      sortNoInProjectStatus
      team {
        archived
        id
        name
        organization {
          allowConnectThirdPartyCalendar
          contactMailAddress
          contractPreriod
          id
          imageUrl
          ipRestrictionFlg
          name
          plan {
            code
            contractPreriod
            id
          }
          requiredTwoFactorAuth
          suspensionFlg
          versionNo
        }
        personalTeam
        status
        versionNo
      }
      totalScheduleTimeSec
      versionNo
      workingTimeSec
    }
  }
`;

/**
 * __useTeamProjectsQuery__
 *
 * To run a query within a React component, call `useTeamProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamProjectsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useTeamProjectsQuery(
  baseOptions: Apollo.QueryHookOptions<TeamProjectsQuery, TeamProjectsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TeamProjectsQuery, TeamProjectsQueryVariables>(
    TeamProjectsDocument,
    options
  );
}
export function useTeamProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TeamProjectsQuery, TeamProjectsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TeamProjectsQuery, TeamProjectsQueryVariables>(
    TeamProjectsDocument,
    options
  );
}
export type TeamProjectsQueryHookResult = ReturnType<typeof useTeamProjectsQuery>;
export type TeamProjectsLazyQueryHookResult = ReturnType<typeof useTeamProjectsLazyQuery>;
export type TeamProjectsQueryResult = Apollo.QueryResult<
  TeamProjectsQuery,
  TeamProjectsQueryVariables
>;
export const TeamProjectsForSelectorDocument = gql`
  query teamProjectsForSelector(
    $input: FetchTeamProjectsInputInput
    $limit: Int
    $offset: Int
    $teamId: String!
  ) {
    teamProjectsForSelector(input: $input, limit: $limit, offset: $offset, teamId: $teamId) {
      clientId
      clientName
      complete
      id
      name
      teamId
      teamtName
    }
  }
`;

/**
 * __useTeamProjectsForSelectorQuery__
 *
 * To run a query within a React component, call `useTeamProjectsForSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamProjectsForSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamProjectsForSelectorQuery({
 *   variables: {
 *      input: // value for 'input'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useTeamProjectsForSelectorQuery(
  baseOptions: Apollo.QueryHookOptions<
    TeamProjectsForSelectorQuery,
    TeamProjectsForSelectorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TeamProjectsForSelectorQuery, TeamProjectsForSelectorQueryVariables>(
    TeamProjectsForSelectorDocument,
    options
  );
}
export function useTeamProjectsForSelectorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TeamProjectsForSelectorQuery,
    TeamProjectsForSelectorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TeamProjectsForSelectorQuery, TeamProjectsForSelectorQueryVariables>(
    TeamProjectsForSelectorDocument,
    options
  );
}
export type TeamProjectsForSelectorQueryHookResult = ReturnType<
  typeof useTeamProjectsForSelectorQuery
>;
export type TeamProjectsForSelectorLazyQueryHookResult = ReturnType<
  typeof useTeamProjectsForSelectorLazyQuery
>;
export type TeamProjectsForSelectorQueryResult = Apollo.QueryResult<
  TeamProjectsForSelectorQuery,
  TeamProjectsForSelectorQueryVariables
>;
export const TeamReportDocument = gql`
  query teamReport($id: ID!) {
    teamReport(id: $id) {
      end
      id
      includeWorkingData
      reportType
      requestDateTime
      requestMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      start
      status
      team {
        archived
        id
        name
        organization {
          allowConnectThirdPartyCalendar
          contactMailAddress
          contractPreriod
          id
          imageUrl
          ipRestrictionFlg
          name
          plan {
            code
            contractPreriod
            id
          }
          requiredTwoFactorAuth
          suspensionFlg
          versionNo
        }
        personalTeam
        status
        versionNo
      }
      timeZoneOffset
      url
      versionNo
    }
  }
`;

/**
 * __useTeamReportQuery__
 *
 * To run a query within a React component, call `useTeamReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamReportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTeamReportQuery(
  baseOptions: Apollo.QueryHookOptions<TeamReportQuery, TeamReportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TeamReportQuery, TeamReportQueryVariables>(TeamReportDocument, options);
}
export function useTeamReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TeamReportQuery, TeamReportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TeamReportQuery, TeamReportQueryVariables>(
    TeamReportDocument,
    options
  );
}
export type TeamReportQueryHookResult = ReturnType<typeof useTeamReportQuery>;
export type TeamReportLazyQueryHookResult = ReturnType<typeof useTeamReportLazyQuery>;
export type TeamReportQueryResult = Apollo.QueryResult<TeamReportQuery, TeamReportQueryVariables>;
export const TeamReportsDocument = gql`
  query teamReports($teamId: ID!) {
    teamReports(teamId: $teamId) {
      end
      id
      includeWorkingData
      reportType
      requestDateTime
      requestMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      start
      status
      team {
        archived
        id
        name
        organization {
          allowConnectThirdPartyCalendar
          contactMailAddress
          contractPreriod
          id
          imageUrl
          ipRestrictionFlg
          name
          plan {
            code
            contractPreriod
            id
          }
          requiredTwoFactorAuth
          suspensionFlg
          versionNo
        }
        personalTeam
        status
        versionNo
      }
      timeZoneOffset
      url
      versionNo
    }
  }
`;

/**
 * __useTeamReportsQuery__
 *
 * To run a query within a React component, call `useTeamReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamReportsQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useTeamReportsQuery(
  baseOptions: Apollo.QueryHookOptions<TeamReportsQuery, TeamReportsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TeamReportsQuery, TeamReportsQueryVariables>(TeamReportsDocument, options);
}
export function useTeamReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TeamReportsQuery, TeamReportsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TeamReportsQuery, TeamReportsQueryVariables>(
    TeamReportsDocument,
    options
  );
}
export type TeamReportsQueryHookResult = ReturnType<typeof useTeamReportsQuery>;
export type TeamReportsLazyQueryHookResult = ReturnType<typeof useTeamReportsLazyQuery>;
export type TeamReportsQueryResult = Apollo.QueryResult<
  TeamReportsQuery,
  TeamReportsQueryVariables
>;
export const TeamSunburstGraphDataDocument = gql`
  query teamSunburstGraphData($input: TeamtGraphDataInputInput!) {
    teamSunburstGraphData(input: $input) {
      color
      name
      percent
      reportType
      teamId
      workingTimeSec
    }
  }
`;

/**
 * __useTeamSunburstGraphDataQuery__
 *
 * To run a query within a React component, call `useTeamSunburstGraphDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamSunburstGraphDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamSunburstGraphDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTeamSunburstGraphDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    TeamSunburstGraphDataQuery,
    TeamSunburstGraphDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TeamSunburstGraphDataQuery, TeamSunburstGraphDataQueryVariables>(
    TeamSunburstGraphDataDocument,
    options
  );
}
export function useTeamSunburstGraphDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TeamSunburstGraphDataQuery,
    TeamSunburstGraphDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TeamSunburstGraphDataQuery, TeamSunburstGraphDataQueryVariables>(
    TeamSunburstGraphDataDocument,
    options
  );
}
export type TeamSunburstGraphDataQueryHookResult = ReturnType<typeof useTeamSunburstGraphDataQuery>;
export type TeamSunburstGraphDataLazyQueryHookResult = ReturnType<
  typeof useTeamSunburstGraphDataLazyQuery
>;
export type TeamSunburstGraphDataQueryResult = Apollo.QueryResult<
  TeamSunburstGraphDataQuery,
  TeamSunburstGraphDataQueryVariables
>;
export const TimeZoneDocument = gql`
  query timeZone($id: ID!) {
    timeZone(id: $id) {
      code
      id
      name
      offsetTime
      sortNo
    }
  }
`;

/**
 * __useTimeZoneQuery__
 *
 * To run a query within a React component, call `useTimeZoneQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeZoneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeZoneQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTimeZoneQuery(
  baseOptions: Apollo.QueryHookOptions<TimeZoneQuery, TimeZoneQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TimeZoneQuery, TimeZoneQueryVariables>(TimeZoneDocument, options);
}
export function useTimeZoneLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TimeZoneQuery, TimeZoneQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TimeZoneQuery, TimeZoneQueryVariables>(TimeZoneDocument, options);
}
export type TimeZoneQueryHookResult = ReturnType<typeof useTimeZoneQuery>;
export type TimeZoneLazyQueryHookResult = ReturnType<typeof useTimeZoneLazyQuery>;
export type TimeZoneQueryResult = Apollo.QueryResult<TimeZoneQuery, TimeZoneQueryVariables>;
export const TimeZonesDocument = gql`
  query timeZones {
    timeZones {
      code
      id
      name
      offsetTime
      sortNo
    }
  }
`;

/**
 * __useTimeZonesQuery__
 *
 * To run a query within a React component, call `useTimeZonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeZonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeZonesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTimeZonesQuery(
  baseOptions?: Apollo.QueryHookOptions<TimeZonesQuery, TimeZonesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TimeZonesQuery, TimeZonesQueryVariables>(TimeZonesDocument, options);
}
export function useTimeZonesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TimeZonesQuery, TimeZonesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TimeZonesQuery, TimeZonesQueryVariables>(TimeZonesDocument, options);
}
export type TimeZonesQueryHookResult = ReturnType<typeof useTimeZonesQuery>;
export type TimeZonesLazyQueryHookResult = ReturnType<typeof useTimeZonesLazyQuery>;
export type TimeZonesQueryResult = Apollo.QueryResult<TimeZonesQuery, TimeZonesQueryVariables>;
export const TwoFactorAuthEnabledDocument = gql`
  query twoFactorAuthEnabled($memberId: String!) {
    twoFactorAuthEnabled(memberId: $memberId)
  }
`;

/**
 * __useTwoFactorAuthEnabledQuery__
 *
 * To run a query within a React component, call `useTwoFactorAuthEnabledQuery` and pass it any options that fit your needs.
 * When your component renders, `useTwoFactorAuthEnabledQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTwoFactorAuthEnabledQuery({
 *   variables: {
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useTwoFactorAuthEnabledQuery(
  baseOptions: Apollo.QueryHookOptions<
    TwoFactorAuthEnabledQuery,
    TwoFactorAuthEnabledQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TwoFactorAuthEnabledQuery, TwoFactorAuthEnabledQueryVariables>(
    TwoFactorAuthEnabledDocument,
    options
  );
}
export function useTwoFactorAuthEnabledLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TwoFactorAuthEnabledQuery,
    TwoFactorAuthEnabledQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TwoFactorAuthEnabledQuery, TwoFactorAuthEnabledQueryVariables>(
    TwoFactorAuthEnabledDocument,
    options
  );
}
export type TwoFactorAuthEnabledQueryHookResult = ReturnType<typeof useTwoFactorAuthEnabledQuery>;
export type TwoFactorAuthEnabledLazyQueryHookResult = ReturnType<
  typeof useTwoFactorAuthEnabledLazyQuery
>;
export type TwoFactorAuthEnabledQueryResult = Apollo.QueryResult<
  TwoFactorAuthEnabledQuery,
  TwoFactorAuthEnabledQueryVariables
>;
export const TwoFactorAuthSettingDocument = gql`
  query twoFactorAuthSetting {
    twoFactorAuthSetting {
      enabled
      recoveryCodes
    }
  }
`;

/**
 * __useTwoFactorAuthSettingQuery__
 *
 * To run a query within a React component, call `useTwoFactorAuthSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTwoFactorAuthSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTwoFactorAuthSettingQuery({
 *   variables: {
 *   },
 * });
 */
export function useTwoFactorAuthSettingQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TwoFactorAuthSettingQuery,
    TwoFactorAuthSettingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TwoFactorAuthSettingQuery, TwoFactorAuthSettingQueryVariables>(
    TwoFactorAuthSettingDocument,
    options
  );
}
export function useTwoFactorAuthSettingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TwoFactorAuthSettingQuery,
    TwoFactorAuthSettingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TwoFactorAuthSettingQuery, TwoFactorAuthSettingQueryVariables>(
    TwoFactorAuthSettingDocument,
    options
  );
}
export type TwoFactorAuthSettingQueryHookResult = ReturnType<typeof useTwoFactorAuthSettingQuery>;
export type TwoFactorAuthSettingLazyQueryHookResult = ReturnType<
  typeof useTwoFactorAuthSettingLazyQuery
>;
export type TwoFactorAuthSettingQueryResult = Apollo.QueryResult<
  TwoFactorAuthSettingQuery,
  TwoFactorAuthSettingQueryVariables
>;
export const UnreadAnnouncementCountDocument = gql`
  query unreadAnnouncementCount {
    unreadAnnouncementCount
  }
`;

/**
 * __useUnreadAnnouncementCountQuery__
 *
 * To run a query within a React component, call `useUnreadAnnouncementCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnreadAnnouncementCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnreadAnnouncementCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnreadAnnouncementCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UnreadAnnouncementCountQuery,
    UnreadAnnouncementCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UnreadAnnouncementCountQuery, UnreadAnnouncementCountQueryVariables>(
    UnreadAnnouncementCountDocument,
    options
  );
}
export function useUnreadAnnouncementCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UnreadAnnouncementCountQuery,
    UnreadAnnouncementCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UnreadAnnouncementCountQuery, UnreadAnnouncementCountQueryVariables>(
    UnreadAnnouncementCountDocument,
    options
  );
}
export type UnreadAnnouncementCountQueryHookResult = ReturnType<
  typeof useUnreadAnnouncementCountQuery
>;
export type UnreadAnnouncementCountLazyQueryHookResult = ReturnType<
  typeof useUnreadAnnouncementCountLazyQuery
>;
export type UnreadAnnouncementCountQueryResult = Apollo.QueryResult<
  UnreadAnnouncementCountQuery,
  UnreadAnnouncementCountQueryVariables
>;
export const UnreadNotificationCountDocument = gql`
  query unreadNotificationCount {
    unreadNotificationCount
  }
`;

/**
 * __useUnreadNotificationCountQuery__
 *
 * To run a query within a React component, call `useUnreadNotificationCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnreadNotificationCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnreadNotificationCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnreadNotificationCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UnreadNotificationCountQuery,
    UnreadNotificationCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UnreadNotificationCountQuery, UnreadNotificationCountQueryVariables>(
    UnreadNotificationCountDocument,
    options
  );
}
export function useUnreadNotificationCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UnreadNotificationCountQuery,
    UnreadNotificationCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UnreadNotificationCountQuery, UnreadNotificationCountQueryVariables>(
    UnreadNotificationCountDocument,
    options
  );
}
export type UnreadNotificationCountQueryHookResult = ReturnType<
  typeof useUnreadNotificationCountQuery
>;
export type UnreadNotificationCountLazyQueryHookResult = ReturnType<
  typeof useUnreadNotificationCountLazyQuery
>;
export type UnreadNotificationCountQueryResult = Apollo.QueryResult<
  UnreadNotificationCountQuery,
  UnreadNotificationCountQueryVariables
>;
export const UpcomingInvoiceDocument = gql`
  query upcomingInvoice {
    upcomingInvoice {
      billingDateTime
      billingNumber
      discount
      invoicePdfUrl
      paid
      periodEndDateTime
      periodStartDateTime
      quantity
      receiptUrl
      subtotal
      tax
      title
      total
      unitPrice
    }
  }
`;

/**
 * __useUpcomingInvoiceQuery__
 *
 * To run a query within a React component, call `useUpcomingInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcomingInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcomingInvoiceQuery({
 *   variables: {
 *   },
 * });
 */
export function useUpcomingInvoiceQuery(
  baseOptions?: Apollo.QueryHookOptions<UpcomingInvoiceQuery, UpcomingInvoiceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UpcomingInvoiceQuery, UpcomingInvoiceQueryVariables>(
    UpcomingInvoiceDocument,
    options
  );
}
export function useUpcomingInvoiceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UpcomingInvoiceQuery, UpcomingInvoiceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UpcomingInvoiceQuery, UpcomingInvoiceQueryVariables>(
    UpcomingInvoiceDocument,
    options
  );
}
export type UpcomingInvoiceQueryHookResult = ReturnType<typeof useUpcomingInvoiceQuery>;
export type UpcomingInvoiceLazyQueryHookResult = ReturnType<typeof useUpcomingInvoiceLazyQuery>;
export type UpcomingInvoiceQueryResult = Apollo.QueryResult<
  UpcomingInvoiceQuery,
  UpcomingInvoiceQueryVariables
>;
export const VersionDocument = gql`
  query version {
    version {
      android {
        forceUpdateVersion
        latestVersion
      }
      ios {
        forceUpdateVersion
        latestVersion
      }
    }
  }
`;

/**
 * __useVersionQuery__
 *
 * To run a query within a React component, call `useVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVersionQuery({
 *   variables: {
 *   },
 * });
 */
export function useVersionQuery(
  baseOptions?: Apollo.QueryHookOptions<VersionQuery, VersionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VersionQuery, VersionQueryVariables>(VersionDocument, options);
}
export function useVersionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VersionQuery, VersionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VersionQuery, VersionQueryVariables>(VersionDocument, options);
}
export type VersionQueryHookResult = ReturnType<typeof useVersionQuery>;
export type VersionLazyQueryHookResult = ReturnType<typeof useVersionLazyQuery>;
export type VersionQueryResult = Apollo.QueryResult<VersionQuery, VersionQueryVariables>;
export const WorkedTasksDocument = gql`
  query workedTasks($input: SearchTaskInputInput, $limit: Int, $offset: Int) {
    workedTasks(input: $input, limit: $limit, offset: $offset) {
      assignees {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      asssignee {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      calendarLinkageCode
      completeDateTime
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      estimatedRemainingTimeSec
      favoriteMembers {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      favoriteMembersInfo {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      id
      laborCost
      priority
      progressDeviationRate
      progressRate
      project {
        assignees {
          sortNo
        }
        calendarLinkageCode
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        color
        complete
        completeDateTime
        costBudgetWithoutTax
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          sortNo
        }
        id
        key
        laborCost
        laborCostWithoutTax
        laborShare
        name
        priority
        projectStatus {
          endStatus
          id
          name
          sortNo
          teamId
          versionNo
        }
        registerDateTime
        salesAmountWithoutTax
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInProjectStatus
        team {
          archived
          id
          name
          organization {
            allowConnectThirdPartyCalendar
            contactMailAddress
            contractPreriod
            id
            imageUrl
            ipRestrictionFlg
            name
            plan {
              code
              contractPreriod
              id
            }
            requiredTwoFactorAuth
            suspensionFlg
            versionNo
          }
          personalTeam
          status
          versionNo
        }
        totalScheduleTimeSec
        versionNo
        workingTimeSec
      }
      registerDateTime
      remainingTimeSec
      scheduleWorkingTimeSec
      scheduledEndDateTime
      scheduledStartDateTime
      sortNoInGanttChart
      sortNoInList
      sortNoInMyTask
      sortNoInTaskStatus
      taskStatus {
        endStatus
        id
        name
        project {
          calendarLinkageCode
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          description
          estimateTimeSec
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        sortNo
        versionNo
      }
      title
      versionNo
      workingMembers {
        lastWorkingStartDateTime
        lastWorkingStopDateTime
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
      }
      workingTimeSec
    }
  }
`;

/**
 * __useWorkedTasksQuery__
 *
 * To run a query within a React component, call `useWorkedTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkedTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkedTasksQuery({
 *   variables: {
 *      input: // value for 'input'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useWorkedTasksQuery(
  baseOptions?: Apollo.QueryHookOptions<WorkedTasksQuery, WorkedTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WorkedTasksQuery, WorkedTasksQueryVariables>(WorkedTasksDocument, options);
}
export function useWorkedTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WorkedTasksQuery, WorkedTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WorkedTasksQuery, WorkedTasksQueryVariables>(
    WorkedTasksDocument,
    options
  );
}
export type WorkedTasksQueryHookResult = ReturnType<typeof useWorkedTasksQuery>;
export type WorkedTasksLazyQueryHookResult = ReturnType<typeof useWorkedTasksLazyQuery>;
export type WorkedTasksQueryResult = Apollo.QueryResult<
  WorkedTasksQuery,
  WorkedTasksQueryVariables
>;
export const WorkingHistoriesSpecifyTermDocument = gql`
  query workingHistoriesSpecifyTerm(
    $input: WorkingHistoriesSpecifyTermInputInput!
    $limit: Int
    $offset: Int
  ) {
    workingHistoriesSpecifyTerm(input: $input, limit: $limit, offset: $offset) {
      calendarEvent {
        calendarType
        clientId
        clientName
        endDateTime
        eventName
        id
        laborCost
        personalTeam
        projectColor
        projectId
        projectName
        startDateTime
        taskId
        taskTitle
        teamId
        teamName
        thirdPartyCalendarEventId
        thirdPartyCalendarInfoList {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          thirdPartyCalendarId
        }
        versionNo
        workingTimeSec
      }
      closingProcessInfo {
        closed
        targetDateTime
      }
      end
      id
      memo
      start
      task {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        asssignee {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        calendarLinkageCode
        completeDateTime
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        estimatedRemainingTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        laborCost
        priority
        progressDeviationRate
        progressRate
        project {
          assignees {
            sortNo
          }
          calendarLinkageCode
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          estimateTimeSec
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            sortNo
          }
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          projectStatus {
            endStatus
            id
            name
            sortNo
            teamId
            versionNo
          }
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          team {
            archived
            id
            name
            organization {
              allowConnectThirdPartyCalendar
              contactMailAddress
              contractPreriod
              id
              imageUrl
              ipRestrictionFlg
              name
              plan {
                code
                contractPreriod
                id
              }
              requiredTwoFactorAuth
              suspensionFlg
              versionNo
            }
            personalTeam
            status
            versionNo
          }
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        registerDateTime
        remainingTimeSec
        scheduleWorkingTimeSec
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInMyTask
        sortNoInTaskStatus
        taskStatus {
          endStatus
          id
          name
          project {
            calendarLinkageCode
            color
            complete
            completeDateTime
            costBudgetWithoutTax
            description
            estimateTimeSec
            id
            key
            laborCost
            laborCostWithoutTax
            laborShare
            name
            priority
            registerDateTime
            salesAmountWithoutTax
            scheduledEndDateTime
            scheduledStartDateTime
            sortNoInGanttChart
            sortNoInList
            sortNoInProjectStatus
            totalScheduleTimeSec
            versionNo
            workingTimeSec
          }
          sortNo
          versionNo
        }
        title
        versionNo
        workingMembers {
          lastWorkingStartDateTime
          lastWorkingStopDateTime
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
        }
        workingTimeSec
      }
      versionNo
      workingMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      workingTimeSec
    }
  }
`;

/**
 * __useWorkingHistoriesSpecifyTermQuery__
 *
 * To run a query within a React component, call `useWorkingHistoriesSpecifyTermQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkingHistoriesSpecifyTermQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkingHistoriesSpecifyTermQuery({
 *   variables: {
 *      input: // value for 'input'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useWorkingHistoriesSpecifyTermQuery(
  baseOptions: Apollo.QueryHookOptions<
    WorkingHistoriesSpecifyTermQuery,
    WorkingHistoriesSpecifyTermQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    WorkingHistoriesSpecifyTermQuery,
    WorkingHistoriesSpecifyTermQueryVariables
  >(WorkingHistoriesSpecifyTermDocument, options);
}
export function useWorkingHistoriesSpecifyTermLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WorkingHistoriesSpecifyTermQuery,
    WorkingHistoriesSpecifyTermQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WorkingHistoriesSpecifyTermQuery,
    WorkingHistoriesSpecifyTermQueryVariables
  >(WorkingHistoriesSpecifyTermDocument, options);
}
export type WorkingHistoriesSpecifyTermQueryHookResult = ReturnType<
  typeof useWorkingHistoriesSpecifyTermQuery
>;
export type WorkingHistoriesSpecifyTermLazyQueryHookResult = ReturnType<
  typeof useWorkingHistoriesSpecifyTermLazyQuery
>;
export type WorkingHistoriesSpecifyTermQueryResult = Apollo.QueryResult<
  WorkingHistoriesSpecifyTermQuery,
  WorkingHistoriesSpecifyTermQueryVariables
>;
export const WorkingHistoriesSpecifyTermForCalendarDocument = gql`
  query workingHistoriesSpecifyTermForCalendar(
    $input: WorkingHistoriesSpecifyTermForCalendarInputInput!
  ) {
    workingHistoriesSpecifyTermForCalendar(input: $input) {
      memberId
      workingHistories {
        calendarEvent {
          calendarType
          clientId
          clientName
          endDateTime
          eventName
          id
          personalTeam
          projectColor
          projectId
          projectName
          startDateTime
          taskId
          taskTitle
          teamId
          teamName
          thirdPartyCalendarEventId
          versionNo
        }
        end
        id
        memo
        start
        task {
          completeDateTime
          id
          project {
            client {
              id
              name
            }
            color
            complete
            id
            name
            team {
              id
              name
              personalTeam
            }
          }
          title
          versionNo
          workingMembers {
            member {
              id
              name
              profileImageUrl
            }
          }
        }
        versionNo
        workingMember {
          id
          name
          profileImageUrl
        }
        workingTimeSec
      }
    }
  }
`;

/**
 * __useWorkingHistoriesSpecifyTermForCalendarQuery__
 *
 * To run a query within a React component, call `useWorkingHistoriesSpecifyTermForCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkingHistoriesSpecifyTermForCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkingHistoriesSpecifyTermForCalendarQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWorkingHistoriesSpecifyTermForCalendarQuery(
  baseOptions: Apollo.QueryHookOptions<
    WorkingHistoriesSpecifyTermForCalendarQuery,
    WorkingHistoriesSpecifyTermForCalendarQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    WorkingHistoriesSpecifyTermForCalendarQuery,
    WorkingHistoriesSpecifyTermForCalendarQueryVariables
  >(WorkingHistoriesSpecifyTermForCalendarDocument, options);
}
export function useWorkingHistoriesSpecifyTermForCalendarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WorkingHistoriesSpecifyTermForCalendarQuery,
    WorkingHistoriesSpecifyTermForCalendarQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WorkingHistoriesSpecifyTermForCalendarQuery,
    WorkingHistoriesSpecifyTermForCalendarQueryVariables
  >(WorkingHistoriesSpecifyTermForCalendarDocument, options);
}
export type WorkingHistoriesSpecifyTermForCalendarQueryHookResult = ReturnType<
  typeof useWorkingHistoriesSpecifyTermForCalendarQuery
>;
export type WorkingHistoriesSpecifyTermForCalendarLazyQueryHookResult = ReturnType<
  typeof useWorkingHistoriesSpecifyTermForCalendarLazyQuery
>;
export type WorkingHistoriesSpecifyTermForCalendarQueryResult = Apollo.QueryResult<
  WorkingHistoriesSpecifyTermForCalendarQuery,
  WorkingHistoriesSpecifyTermForCalendarQueryVariables
>;
export const WorkingHistoriesSpecifyTermForSummaryDocument = gql`
  query workingHistoriesSpecifyTermForSummary(
    $input: WorkingHistoriesSpecifyTermInputInput!
    $limit: Int
    $offset: Int
  ) {
    workingHistoriesSpecifyTermForSummary(input: $input, limit: $limit, offset: $offset) {
      calendarEvent {
        clientId
        clientName
        eventName
        id
        projectId
        projectName
        teamId
      }
      closingProcessInfo {
        closed
        targetDateTime
      }
      end
      id
      memo
      start
      task {
        id
        project {
          client {
            id
            name
          }
          id
          name
          team {
            id
            personalTeam
          }
        }
        title
        versionNo
      }
      versionNo
      workingMember {
        id
        name
        profileImageUrl
      }
      workingTimeSec
    }
  }
`;

/**
 * __useWorkingHistoriesSpecifyTermForSummaryQuery__
 *
 * To run a query within a React component, call `useWorkingHistoriesSpecifyTermForSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkingHistoriesSpecifyTermForSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkingHistoriesSpecifyTermForSummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useWorkingHistoriesSpecifyTermForSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    WorkingHistoriesSpecifyTermForSummaryQuery,
    WorkingHistoriesSpecifyTermForSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    WorkingHistoriesSpecifyTermForSummaryQuery,
    WorkingHistoriesSpecifyTermForSummaryQueryVariables
  >(WorkingHistoriesSpecifyTermForSummaryDocument, options);
}
export function useWorkingHistoriesSpecifyTermForSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WorkingHistoriesSpecifyTermForSummaryQuery,
    WorkingHistoriesSpecifyTermForSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WorkingHistoriesSpecifyTermForSummaryQuery,
    WorkingHistoriesSpecifyTermForSummaryQueryVariables
  >(WorkingHistoriesSpecifyTermForSummaryDocument, options);
}
export type WorkingHistoriesSpecifyTermForSummaryQueryHookResult = ReturnType<
  typeof useWorkingHistoriesSpecifyTermForSummaryQuery
>;
export type WorkingHistoriesSpecifyTermForSummaryLazyQueryHookResult = ReturnType<
  typeof useWorkingHistoriesSpecifyTermForSummaryLazyQuery
>;
export type WorkingHistoriesSpecifyTermForSummaryQueryResult = Apollo.QueryResult<
  WorkingHistoriesSpecifyTermForSummaryQuery,
  WorkingHistoriesSpecifyTermForSummaryQueryVariables
>;
export const WorkingHistoryDocument = gql`
  query workingHistory($id: String!) {
    workingHistory(id: $id) {
      calendarEvent {
        calendarType
        clientId
        clientName
        endDateTime
        eventName
        id
        laborCost
        personalTeam
        projectColor
        projectId
        projectName
        startDateTime
        taskId
        taskTitle
        teamId
        teamName
        thirdPartyCalendarEventId
        thirdPartyCalendarInfoList {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          thirdPartyCalendarId
        }
        versionNo
        workingTimeSec
      }
      closingProcessInfo {
        closed
        targetDateTime
      }
      end
      id
      memo
      start
      task {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        asssignee {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        calendarLinkageCode
        completeDateTime
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        estimatedRemainingTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        laborCost
        priority
        progressDeviationRate
        progressRate
        project {
          assignees {
            sortNo
          }
          calendarLinkageCode
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          estimateTimeSec
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            sortNo
          }
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          projectStatus {
            endStatus
            id
            name
            sortNo
            teamId
            versionNo
          }
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          team {
            archived
            id
            name
            organization {
              allowConnectThirdPartyCalendar
              contactMailAddress
              contractPreriod
              id
              imageUrl
              ipRestrictionFlg
              name
              plan {
                code
                contractPreriod
                id
              }
              requiredTwoFactorAuth
              suspensionFlg
              versionNo
            }
            personalTeam
            status
            versionNo
          }
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        registerDateTime
        remainingTimeSec
        scheduleWorkingTimeSec
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInMyTask
        sortNoInTaskStatus
        taskStatus {
          endStatus
          id
          name
          project {
            calendarLinkageCode
            color
            complete
            completeDateTime
            costBudgetWithoutTax
            description
            estimateTimeSec
            id
            key
            laborCost
            laborCostWithoutTax
            laborShare
            name
            priority
            registerDateTime
            salesAmountWithoutTax
            scheduledEndDateTime
            scheduledStartDateTime
            sortNoInGanttChart
            sortNoInList
            sortNoInProjectStatus
            totalScheduleTimeSec
            versionNo
            workingTimeSec
          }
          sortNo
          versionNo
        }
        title
        versionNo
        workingMembers {
          lastWorkingStartDateTime
          lastWorkingStopDateTime
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
        }
        workingTimeSec
      }
      versionNo
      workingMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      workingTimeSec
    }
  }
`;

/**
 * __useWorkingHistoryQuery__
 *
 * To run a query within a React component, call `useWorkingHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkingHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkingHistoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWorkingHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<WorkingHistoryQuery, WorkingHistoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WorkingHistoryQuery, WorkingHistoryQueryVariables>(
    WorkingHistoryDocument,
    options
  );
}
export function useWorkingHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WorkingHistoryQuery, WorkingHistoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WorkingHistoryQuery, WorkingHistoryQueryVariables>(
    WorkingHistoryDocument,
    options
  );
}
export type WorkingHistoryQueryHookResult = ReturnType<typeof useWorkingHistoryQuery>;
export type WorkingHistoryLazyQueryHookResult = ReturnType<typeof useWorkingHistoryLazyQuery>;
export type WorkingHistoryQueryResult = Apollo.QueryResult<
  WorkingHistoryQuery,
  WorkingHistoryQueryVariables
>;
export const WorkingHistoryForSummaryDocument = gql`
  query workingHistoryForSummary($id: String!) {
    workingHistoryForSummary(id: $id) {
      calendarEvent {
        clientId
        clientName
        eventName
        id
        projectId
        projectName
        teamId
      }
      closingProcessInfo {
        closed
        targetDateTime
      }
      end
      id
      memo
      start
      task {
        id
        project {
          client {
            id
            name
          }
          id
          name
          team {
            id
            personalTeam
          }
        }
        title
        versionNo
      }
      versionNo
      workingMember {
        id
        name
        profileImageUrl
      }
      workingTimeSec
    }
  }
`;

/**
 * __useWorkingHistoryForSummaryQuery__
 *
 * To run a query within a React component, call `useWorkingHistoryForSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkingHistoryForSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkingHistoryForSummaryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWorkingHistoryForSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    WorkingHistoryForSummaryQuery,
    WorkingHistoryForSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WorkingHistoryForSummaryQuery, WorkingHistoryForSummaryQueryVariables>(
    WorkingHistoryForSummaryDocument,
    options
  );
}
export function useWorkingHistoryForSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WorkingHistoryForSummaryQuery,
    WorkingHistoryForSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WorkingHistoryForSummaryQuery, WorkingHistoryForSummaryQueryVariables>(
    WorkingHistoryForSummaryDocument,
    options
  );
}
export type WorkingHistoryForSummaryQueryHookResult = ReturnType<
  typeof useWorkingHistoryForSummaryQuery
>;
export type WorkingHistoryForSummaryLazyQueryHookResult = ReturnType<
  typeof useWorkingHistoryForSummaryLazyQuery
>;
export type WorkingHistoryForSummaryQueryResult = Apollo.QueryResult<
  WorkingHistoryForSummaryQuery,
  WorkingHistoryForSummaryQueryVariables
>;
export const WorkingScheduleDocument = gql`
  query workingSchedule($id: String!) {
    workingSchedule(id: $id) {
      calendarEvent {
        calendarType
        clientId
        clientName
        endDateTime
        eventName
        id
        laborCost
        personalTeam
        projectColor
        projectId
        projectName
        startDateTime
        taskId
        taskTitle
        teamId
        teamName
        thirdPartyCalendarEventId
        thirdPartyCalendarInfoList {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          thirdPartyCalendarId
        }
        versionNo
        workingTimeSec
      }
      end
      id
      memo
      start
      task {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        asssignee {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        calendarLinkageCode
        completeDateTime
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        estimatedRemainingTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        laborCost
        priority
        progressDeviationRate
        progressRate
        project {
          assignees {
            sortNo
          }
          calendarLinkageCode
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          estimateTimeSec
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            sortNo
          }
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          projectStatus {
            endStatus
            id
            name
            sortNo
            teamId
            versionNo
          }
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          team {
            archived
            id
            name
            organization {
              allowConnectThirdPartyCalendar
              contactMailAddress
              contractPreriod
              id
              imageUrl
              ipRestrictionFlg
              name
              plan {
                code
                contractPreriod
                id
              }
              requiredTwoFactorAuth
              suspensionFlg
              versionNo
            }
            personalTeam
            status
            versionNo
          }
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        registerDateTime
        remainingTimeSec
        scheduleWorkingTimeSec
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInMyTask
        sortNoInTaskStatus
        taskStatus {
          endStatus
          id
          name
          project {
            calendarLinkageCode
            color
            complete
            completeDateTime
            costBudgetWithoutTax
            description
            estimateTimeSec
            id
            key
            laborCost
            laborCostWithoutTax
            laborShare
            name
            priority
            registerDateTime
            salesAmountWithoutTax
            scheduledEndDateTime
            scheduledStartDateTime
            sortNoInGanttChart
            sortNoInList
            sortNoInProjectStatus
            totalScheduleTimeSec
            versionNo
            workingTimeSec
          }
          sortNo
          versionNo
        }
        title
        versionNo
        workingMembers {
          lastWorkingStartDateTime
          lastWorkingStopDateTime
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
        }
        workingTimeSec
      }
      versionNo
      workingMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      workingTimeSec
    }
  }
`;

/**
 * __useWorkingScheduleQuery__
 *
 * To run a query within a React component, call `useWorkingScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkingScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkingScheduleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWorkingScheduleQuery(
  baseOptions: Apollo.QueryHookOptions<WorkingScheduleQuery, WorkingScheduleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WorkingScheduleQuery, WorkingScheduleQueryVariables>(
    WorkingScheduleDocument,
    options
  );
}
export function useWorkingScheduleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WorkingScheduleQuery, WorkingScheduleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WorkingScheduleQuery, WorkingScheduleQueryVariables>(
    WorkingScheduleDocument,
    options
  );
}
export type WorkingScheduleQueryHookResult = ReturnType<typeof useWorkingScheduleQuery>;
export type WorkingScheduleLazyQueryHookResult = ReturnType<typeof useWorkingScheduleLazyQuery>;
export type WorkingScheduleQueryResult = Apollo.QueryResult<
  WorkingScheduleQuery,
  WorkingScheduleQueryVariables
>;
export const WorkingScheduleForSummaryDocument = gql`
  query workingScheduleForSummary($id: String!) {
    workingScheduleForSummary(id: $id) {
      calendarEvent {
        clientId
        clientName
        eventName
        id
        projectId
        projectName
        teamId
      }
      end
      id
      memo
      start
      task {
        id
        project {
          client {
            id
            name
          }
          id
          name
          team {
            id
            personalTeam
          }
        }
        title
        versionNo
      }
      versionNo
      workingMember {
        id
        name
        profileImageUrl
      }
      workingTimeSec
    }
  }
`;

/**
 * __useWorkingScheduleForSummaryQuery__
 *
 * To run a query within a React component, call `useWorkingScheduleForSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkingScheduleForSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkingScheduleForSummaryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWorkingScheduleForSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    WorkingScheduleForSummaryQuery,
    WorkingScheduleForSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WorkingScheduleForSummaryQuery, WorkingScheduleForSummaryQueryVariables>(
    WorkingScheduleForSummaryDocument,
    options
  );
}
export function useWorkingScheduleForSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WorkingScheduleForSummaryQuery,
    WorkingScheduleForSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WorkingScheduleForSummaryQuery,
    WorkingScheduleForSummaryQueryVariables
  >(WorkingScheduleForSummaryDocument, options);
}
export type WorkingScheduleForSummaryQueryHookResult = ReturnType<
  typeof useWorkingScheduleForSummaryQuery
>;
export type WorkingScheduleForSummaryLazyQueryHookResult = ReturnType<
  typeof useWorkingScheduleForSummaryLazyQuery
>;
export type WorkingScheduleForSummaryQueryResult = Apollo.QueryResult<
  WorkingScheduleForSummaryQuery,
  WorkingScheduleForSummaryQueryVariables
>;
export const WorkingSchedulesSpecifyTermDocument = gql`
  query workingSchedulesSpecifyTerm(
    $input: WorkingSchedulesSpecifyTermInputInput!
    $limit: Int
    $offset: Int
  ) {
    workingSchedulesSpecifyTerm(input: $input, limit: $limit, offset: $offset) {
      calendarEvent {
        calendarType
        clientId
        clientName
        endDateTime
        eventName
        id
        laborCost
        personalTeam
        projectColor
        projectId
        projectName
        startDateTime
        taskId
        taskTitle
        teamId
        teamName
        thirdPartyCalendarEventId
        thirdPartyCalendarInfoList {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          thirdPartyCalendarId
        }
        versionNo
        workingTimeSec
      }
      end
      id
      memo
      start
      task {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        asssignee {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        calendarLinkageCode
        completeDateTime
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        estimatedRemainingTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          proxyEditWorkingDataRole
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        id
        laborCost
        priority
        progressDeviationRate
        progressRate
        project {
          assignees {
            sortNo
          }
          calendarLinkageCode
          client {
            code
            id
            name
            sortNo
            versionNo
          }
          color
          complete
          completeDateTime
          costBudgetWithoutTax
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          estimateTimeSec
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            sortNo
          }
          id
          key
          laborCost
          laborCostWithoutTax
          laborShare
          name
          priority
          projectStatus {
            endStatus
            id
            name
            sortNo
            teamId
            versionNo
          }
          registerDateTime
          salesAmountWithoutTax
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          sortNoInProjectStatus
          team {
            archived
            id
            name
            organization {
              allowConnectThirdPartyCalendar
              contactMailAddress
              contractPreriod
              id
              imageUrl
              ipRestrictionFlg
              name
              plan {
                code
                contractPreriod
                id
              }
              requiredTwoFactorAuth
              suspensionFlg
              versionNo
            }
            personalTeam
            status
            versionNo
          }
          totalScheduleTimeSec
          versionNo
          workingTimeSec
        }
        registerDateTime
        remainingTimeSec
        scheduleWorkingTimeSec
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInMyTask
        sortNoInTaskStatus
        taskStatus {
          endStatus
          id
          name
          project {
            calendarLinkageCode
            color
            complete
            completeDateTime
            costBudgetWithoutTax
            description
            estimateTimeSec
            id
            key
            laborCost
            laborCostWithoutTax
            laborShare
            name
            priority
            registerDateTime
            salesAmountWithoutTax
            scheduledEndDateTime
            scheduledStartDateTime
            sortNoInGanttChart
            sortNoInList
            sortNoInProjectStatus
            totalScheduleTimeSec
            versionNo
            workingTimeSec
          }
          sortNo
          versionNo
        }
        title
        versionNo
        workingMembers {
          lastWorkingStartDateTime
          lastWorkingStopDateTime
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            proxyEditWorkingDataRole
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
        }
        workingTimeSec
      }
      versionNo
      workingMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        proxyEditWorkingDataRole
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      workingTimeSec
    }
  }
`;

/**
 * __useWorkingSchedulesSpecifyTermQuery__
 *
 * To run a query within a React component, call `useWorkingSchedulesSpecifyTermQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkingSchedulesSpecifyTermQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkingSchedulesSpecifyTermQuery({
 *   variables: {
 *      input: // value for 'input'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useWorkingSchedulesSpecifyTermQuery(
  baseOptions: Apollo.QueryHookOptions<
    WorkingSchedulesSpecifyTermQuery,
    WorkingSchedulesSpecifyTermQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    WorkingSchedulesSpecifyTermQuery,
    WorkingSchedulesSpecifyTermQueryVariables
  >(WorkingSchedulesSpecifyTermDocument, options);
}
export function useWorkingSchedulesSpecifyTermLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WorkingSchedulesSpecifyTermQuery,
    WorkingSchedulesSpecifyTermQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WorkingSchedulesSpecifyTermQuery,
    WorkingSchedulesSpecifyTermQueryVariables
  >(WorkingSchedulesSpecifyTermDocument, options);
}
export type WorkingSchedulesSpecifyTermQueryHookResult = ReturnType<
  typeof useWorkingSchedulesSpecifyTermQuery
>;
export type WorkingSchedulesSpecifyTermLazyQueryHookResult = ReturnType<
  typeof useWorkingSchedulesSpecifyTermLazyQuery
>;
export type WorkingSchedulesSpecifyTermQueryResult = Apollo.QueryResult<
  WorkingSchedulesSpecifyTermQuery,
  WorkingSchedulesSpecifyTermQueryVariables
>;
export const WorkingSchedulesSpecifyTermForCalendarDocument = gql`
  query workingSchedulesSpecifyTermForCalendar(
    $input: WorkingSchedulesSpecifyTermForCalendarInputInput!
    $limit: Int
    $offset: Int
  ) {
    workingSchedulesSpecifyTermForCalendar(input: $input, limit: $limit, offset: $offset) {
      memberId
      workingSchedules {
        calendarEvent {
          calendarType
          clientId
          clientName
          endDateTime
          eventName
          id
          personalTeam
          projectColor
          projectId
          projectName
          startDateTime
          taskId
          taskTitle
          teamId
          teamName
          thirdPartyCalendarEventId
          versionNo
        }
        end
        id
        memo
        start
        task {
          completeDateTime
          id
          project {
            client {
              id
              name
            }
            color
            complete
            id
            name
            team {
              id
              name
              personalTeam
            }
          }
          title
          versionNo
          workingMembers {
            member {
              id
              name
              profileImageUrl
            }
          }
        }
        versionNo
        workingMember {
          id
          name
          profileImageUrl
        }
        workingTimeSec
      }
    }
  }
`;

/**
 * __useWorkingSchedulesSpecifyTermForCalendarQuery__
 *
 * To run a query within a React component, call `useWorkingSchedulesSpecifyTermForCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkingSchedulesSpecifyTermForCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkingSchedulesSpecifyTermForCalendarQuery({
 *   variables: {
 *      input: // value for 'input'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useWorkingSchedulesSpecifyTermForCalendarQuery(
  baseOptions: Apollo.QueryHookOptions<
    WorkingSchedulesSpecifyTermForCalendarQuery,
    WorkingSchedulesSpecifyTermForCalendarQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    WorkingSchedulesSpecifyTermForCalendarQuery,
    WorkingSchedulesSpecifyTermForCalendarQueryVariables
  >(WorkingSchedulesSpecifyTermForCalendarDocument, options);
}
export function useWorkingSchedulesSpecifyTermForCalendarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WorkingSchedulesSpecifyTermForCalendarQuery,
    WorkingSchedulesSpecifyTermForCalendarQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WorkingSchedulesSpecifyTermForCalendarQuery,
    WorkingSchedulesSpecifyTermForCalendarQueryVariables
  >(WorkingSchedulesSpecifyTermForCalendarDocument, options);
}
export type WorkingSchedulesSpecifyTermForCalendarQueryHookResult = ReturnType<
  typeof useWorkingSchedulesSpecifyTermForCalendarQuery
>;
export type WorkingSchedulesSpecifyTermForCalendarLazyQueryHookResult = ReturnType<
  typeof useWorkingSchedulesSpecifyTermForCalendarLazyQuery
>;
export type WorkingSchedulesSpecifyTermForCalendarQueryResult = Apollo.QueryResult<
  WorkingSchedulesSpecifyTermForCalendarQuery,
  WorkingSchedulesSpecifyTermForCalendarQueryVariables
>;
export const WorkingSchedulesSpecifyTermForSummaryDocument = gql`
  query workingSchedulesSpecifyTermForSummary(
    $input: WorkingSchedulesSpecifyTermInputInput!
    $limit: Int
    $offset: Int
  ) {
    workingSchedulesSpecifyTermForSummary(input: $input, limit: $limit, offset: $offset) {
      calendarEvent {
        clientId
        clientName
        eventName
        id
        projectId
        projectName
        teamId
      }
      end
      id
      memo
      start
      task {
        id
        project {
          client {
            id
            name
          }
          id
          name
          team {
            id
            personalTeam
          }
        }
        title
        versionNo
      }
      versionNo
      workingMember {
        id
        name
        profileImageUrl
      }
      workingTimeSec
    }
  }
`;

/**
 * __useWorkingSchedulesSpecifyTermForSummaryQuery__
 *
 * To run a query within a React component, call `useWorkingSchedulesSpecifyTermForSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkingSchedulesSpecifyTermForSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkingSchedulesSpecifyTermForSummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useWorkingSchedulesSpecifyTermForSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    WorkingSchedulesSpecifyTermForSummaryQuery,
    WorkingSchedulesSpecifyTermForSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    WorkingSchedulesSpecifyTermForSummaryQuery,
    WorkingSchedulesSpecifyTermForSummaryQueryVariables
  >(WorkingSchedulesSpecifyTermForSummaryDocument, options);
}
export function useWorkingSchedulesSpecifyTermForSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WorkingSchedulesSpecifyTermForSummaryQuery,
    WorkingSchedulesSpecifyTermForSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WorkingSchedulesSpecifyTermForSummaryQuery,
    WorkingSchedulesSpecifyTermForSummaryQueryVariables
  >(WorkingSchedulesSpecifyTermForSummaryDocument, options);
}
export type WorkingSchedulesSpecifyTermForSummaryQueryHookResult = ReturnType<
  typeof useWorkingSchedulesSpecifyTermForSummaryQuery
>;
export type WorkingSchedulesSpecifyTermForSummaryLazyQueryHookResult = ReturnType<
  typeof useWorkingSchedulesSpecifyTermForSummaryLazyQuery
>;
export type WorkingSchedulesSpecifyTermForSummaryQueryResult = Apollo.QueryResult<
  WorkingSchedulesSpecifyTermForSummaryQuery,
  WorkingSchedulesSpecifyTermForSummaryQueryVariables
>;
