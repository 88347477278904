import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components/native';
import {
  Organization,
  useDisableAccessControlMutation,
  useDisableThirdPartyCalendarConnectMutation,
  useEnableAccessControlMutation,
  useEnableThirdPartyCalendarConnectMutation,
  useMeQuery,
  useOrganizationQuery,
} from '../../../../../../../graphql/api/API';
import { LoginUserContext } from '../../../../../../../modules/auth/LoginUserContext';
import Button from '../../../../../../presentational/atoms/button';
import Form from '../../../../../../presentational/atoms/form';
import Typography, { TypographyType } from '../../../../../../presentational/atoms/typography';
import { IThemePart } from '../../../../../../theme';
import SettingsElement from '../../../../organisms/settings-element';
import { View } from 'react-native';

const Container = styled.View`
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
`;

interface ISettingsModalProps {
  organization: Organization;
  closeModal: () => void;
}

const SettingsModal = (props: ISettingsModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [enableAccessControl] = useEnableThirdPartyCalendarConnectMutation({
    variables: {
      input: {
        versionNo: props.organization.versionNo,
      },
    },
  });
  const [disableAccessControl] = useDisableThirdPartyCalendarConnectMutation({
    variables: {
      input: {
        versionNo: props.organization.versionNo,
      },
    },
  });

  return (
    <Form style={{ paddingVertical: 20 }}>
      {props.organization.allowConnectThirdPartyCalendar ? (
        <Typography
          variant={TypographyType.Normal}
          style={{
            textAlign: 'center',
          }}>{`カレンダー情報の連携を「許可しない」に変更しますか？`}</Typography>
      ) : (
        <Typography
          variant={TypographyType.Normal}
          style={{
            textAlign: 'center',
          }}>{`カレンダー情報の連携を「許可する」に変更しますか？`}</Typography>
      )}
      <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1 }}>
        {props.organization.allowConnectThirdPartyCalendar ? (
          <Button
            text={'「許可しない」に変更する'}
            completeText="変更しました"
            style={{ minWidth: 100, marginRight: 10, marginVertical: 10 }}
            onPress={async () => {
              await disableAccessControl();
              props.closeModal();
            }}
          />
        ) : (
          <Button
            text={'「許可する」に変更する'}
            completeText="変更しました"
            style={{ minWidth: 100, marginRight: 10, marginVertical: 10 }}
            onPress={async () => {
              await enableAccessControl();
              props.closeModal();
            }}
          />
        )}

        <Button
          text={'キャンセル'}
          style={{
            minWidth: 100,
            marginRight: 10,
            marginVertical: 10,
            backgroundColor: 'transparent',
          }}
          textStyle={{ color: themeContext.colors.primary }}
          disableValidate={true}
          onPress={() => {
            props.closeModal();
          }}
        />
      </View>
    </Form>
  );
};

const ThirdPartyAccountConnectSettingsPage = () => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const { loading, data } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId,
    },
  });
  const { loading: loadingMe, data: dataMe } = useMeQuery({
    fetchPolicy: 'network-only',
  });

  if (loading || loadingMe) {
    return <></>;
  }

  return (
    <Container>
      <View style={{ marginBottom: 50 }}>
        <Typography
          variant={
            TypographyType.Normal
          }>{`Googleアカウント連携する際に、Googleカレンダー情報の連携を${'\n'}許可するかどうかを設定することが出来ます。${'\n'}${'\n'}以下で「許可しない」に設定した場合には、${'\n'}Googleアカウント連携時にカレンダー情報は連携しないようになります。${'\n'}もし、社内の方針等でGoogleカレンダー情報を連携させたくない場合には、${'\n'}「許可しない」に設定してください。`}</Typography>
      </View>
      {!dataMe!.me!.adminRole ? (
        <>
          <View style={{ marginBottom: 30 }}>
            <Typography
              variant={TypographyType.Description}
              style={{
                color: themeContext.colors.error,
                fontSize: 18,
              }}>{`変更するには管理者権限が必要です。`}</Typography>
          </View>
          <SettingsElement title={'Googleカレンダーの連携'} modal={() => {}}>
            <Typography variant={TypographyType.Normal}>
              {data?.organization?.allowConnectThirdPartyCalendar ? '許可する' : '許可しない'}
            </Typography>
          </SettingsElement>
        </>
      ) : (
        <>
          <SettingsElement
            title={'Googleカレンダーの連携'}
            titleWidth={230}
            changeText="設定を変更する"
            modal={(closeModal) => (
              <SettingsModal closeModal={closeModal} organization={data!.organization!} />
            )}>
            <Typography variant={TypographyType.Normal}>
              {data?.organization?.allowConnectThirdPartyCalendar ? '許可する' : '許可しない'}
            </Typography>
          </SettingsElement>
        </>
      )}
    </Container>
  );
};

export default ThirdPartyAccountConnectSettingsPage;
