import React, { useContext, useEffect, useState } from 'react';
import styled, { ThemeContext } from 'styled-components/native';
import { Image, View } from 'react-native';
import { useScreenDimensions } from 'use-dimensions';
import { useHistory, useLocation } from 'react-router';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import Button from '../../../../presentational/atoms/button';
import Form from '../../../../presentational/atoms/form';
import Input from '../../../../presentational/atoms/input';
import Link from '../../../../presentational/atoms/link';
import { IThemePart } from '../../../../theme';
import logoLargeBlack from '../../../../../base64Images/logo/logo-large-black';
import login from '../../../../../base64Images/login/login';
import invalidImage from '../../../../../base64Images/invalid-invite-key/invalid-invite-key';
import queryString from 'query-string';
import { useMemberRegisterRequestQuery } from '../../../../../graphql/api/API';
import PasswordStrengthChecker from '../../../../presentational/molecules/password-strength-checker';

const Header = styled.View`
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  height: 100px;
  padding-left: 20px;
  padding-top: 20px;
`;

const Container = styled.View`
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  height: calc(100vh - 100px);
`;

const LeftContainer = styled.View`
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 0 50px;
`;

const RightContainer = styled.View`
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 0 50px;
`;

const RightContainerInner = styled.View`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LinkContainer = styled.View`
  margin-top: 1.5rem;
`;

const DescriptionContainerTop = styled.View`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
`;

const DescriptionContainerBottom = styled.View`
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
`;

const JoinWithLinkOrganizationWithMailAddressPage = () => {
  const [password, setPassword] = useState('');
  const { height, width } = useScreenDimensions();
  const history = useHistory();
  const themeContext: IThemePart = useContext(ThemeContext);
  const { search } = useLocation();
  const { loading, data, error } = useMemberRegisterRequestQuery({
    variables: {
      key: queryString.parse(search).key as string,
    },
  });

  if (loading) {
    return <></>;
  }

  if (data?.memberRegisterRequest === null) {
    return (
      <>
        <Header>
          <Image
            source={{ uri: logoLargeBlack }}
            resizeMode="contain"
            style={{
              width: 300,
              height: 100,
            }}
          />
        </Header>
        <Container>
          <LeftContainer>
            <Image
              source={{ uri: invalidImage }}
              resizeMode="contain"
              style={{
                width: Math.max(width / 4, 500),
                height: Math.max(width / 4, 500),
              }}
            />
          </LeftContainer>
          <RightContainer>
            <RightContainerInner>
              <Typography
                variant={TypographyType.Title}
                style={{ marginVertical: 10, textAlign: 'center' }}>
                この招待URLは無効です
              </Typography>
              <DescriptionContainerTop>
                <Typography
                  variant={TypographyType.Description}
                  style={{ textAlign: 'center', fontSize: 14, lineHeight: 28 }}>
                  {`このリンクは無効になっているか、すでに使用済みです。${'\n\n'}すでに会員登録済みの場合には、${'\n'}ログイン画面からログインしてください。${'\n\n'}もし組織への参加が出来ずにお困りでしたらば、${'\n'}招待者の方に問い合わせて、${'\n'}再度招待URLを発行してもらってください。`}
                </Typography>
              </DescriptionContainerTop>
              <LinkContainer>
                <Link path={'/signin'}>
                  <Typography
                    variant={TypographyType.MenuItemActive}
                    style={{ color: themeContext.colors.link }}>
                    ログインする
                  </Typography>
                </Link>
              </LinkContainer>
            </RightContainerInner>
          </RightContainer>
        </Container>
      </>
    );
  }

  return (
    <>
      <Header>
        <Image
          source={{ uri: logoLargeBlack }}
          resizeMode="contain"
          style={{
            width: 300,
            height: 100,
          }}
        />
      </Header>
      <Container>
        <LeftContainer>
          <Image
            source={{ uri: login }}
            resizeMode="contain"
            style={{
              width: width / 4,
              height: width / 4,
            }}
          />
        </LeftContainer>
        <RightContainer>
          <RightContainerInner>
            <Typography
              variant={TypographyType.Title}
              style={{ marginVertical: 10, textAlign: 'center' }}>
              パスワードを設定してください
            </Typography>
            <Form style={{ marginBottom: 30 }}>
              <Input
                name={'password'}
                type={'password'}
                label={'パスワード'}
                validate={{
                  required: {
                    value: true,
                    message: 'パスワードを入力してください',
                  },
                  minLength: {
                    value: 8,
                    message: 'パスワードは8文字以上で入力してください',
                  },
                }}
                onChange={(value: string) => setPassword(value)}
              />
              <Input
                name={'passwordConfirm'}
                type={'password'}
                label={'確認のためもう一度入力してください'}
                validate={{
                  validate: (value: string) => {
                    if (value !== password) {
                      return 'パスワードが一致していません';
                    }
                    return true;
                  },
                }}
              />
              <PasswordStrengthChecker password={password} />
              <Button
                text="次へ"
                style={{ marginTop: 20, minWidth: 300 }}
                onPress={() => {
                  history.push({
                    pathname: '/join-with-link/input-profile',
                    state: {
                      key: queryString.parse(search).key as string,
                      password: password,
                    },
                  });
                }}
              />
            </Form>
          </RightContainerInner>
        </RightContainer>
      </Container>
    </>
  );
};

export default React.memo(JoinWithLinkOrganizationWithMailAddressPage);
