import { useApolloClient } from '@apollo/client';
import { EditorState, convertFromRaw } from 'draft-js';
import * as Cookies from 'js-cookie';
import moment from 'moment';
import React, { type ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { Image, Platform, View } from 'react-native';
import { Redirect, useHistory } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import styled, { ThemeContext } from 'styled-components/native';
import useDimensions from 'use-dimensions';
import useInterval from 'use-interval';
import pushAudio from '../../../../../base64Audio/push';
import {
  BillingType,
  InitialViewType,
  type MentionV2,
  MyGoogleAccountDocument,
  Plan,
  ProjectInitialViewType,
  TaskRemainderType,
  useAnnouncementsLazyQuery,
  useConnectGoogleAccountMutation,
  useContractQuery,
  useFirstViewProjectQuery,
  useMeQuery,
  useMyGoogleAccountQuery,
  useMySettingsQuery,
  useOrganizationQuery,
  useUnreadAnnouncementCountLazyQuery,
} from '../../../../../graphql/api/API';
import { type LoginUser, LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import useWebSocketSync from '../../../../../pusher/useWebSocketSync';
import TimeUtil from '../../../../../util/TimeUtil';
import Link from '../../../../presentational/atoms/link';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import CloseIcon from '../../../../presentational/molecules/image-icon/close';
import type { IThemePart } from '../../../../theme';
import AppMainContainer from '../../organisms/app-main-container';
import CalendarEventDetail from '../../organisms/calendar-event-detail';
import LatestTaskHeader from '../../organisms/latest-task-header';
import MessageDetail from '../../organisms/message-detail';
import OrganizationList from '../../organisms/organization-list';
import ProjectDetail from '../../organisms/project-detail';
import ProjectTemplateDetail from '../../organisms/project-template-detail';
import RightSideBar from '../../organisms/right-side-bar';
import SideMenu from '../../organisms/side-menu';
import TaskDetail from '../../organisms/task-detail';
import ProjectTemplateTaskDetail from '../../organisms/task-template-detail';
import './ReactToastify.css';
import Constants from 'expo-constants';
import GoogleLogin from 'react-google-login';
import Button from '../../../../presentational/atoms/button';
import Modal from '../../../../presentational/molecules/modal';

import googleLoginImageUrl from '../../../../../base64Images/google-login-description';
import UrlUtil from '../../../../../util/UrlUtil';

const FooterOverlay = styled.View`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 1;
  background: rgba(0, 0, 0, 0.7);
  padding: 20px 10%;
  display: flex;
  justify-content: center;
`;

const CloseIconWraper = styled.View`
  position: absolute;
  top: 10px;
  right: 10px;
`;

interface INotificationFooterProps {
  children: ReactNode;
  onClose: () => void;
}

const NotificationFooter = (props: INotificationFooterProps) => {
  return (
    <FooterOverlay>
      <CloseIconWraper>
        <CloseIcon size={16} reverse={true} onPress={props.onClose} />
      </CloseIconWraper>
      {props.children}
    </FooterOverlay>
  );
};

interface IReConnectGoogleAccountModalProps {
  showModal: boolean;
  onClose: () => void;
}

const ReConnectGoogleAccountModal = (props: IReConnectGoogleAccountModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const history = useHistory();
  const [step, setStep] = useState<1 | 2 | 3 | 4>(1);
  const [connectGoogleAccount, _] = useConnectGoogleAccountMutation({
    onError: (e) => {
      if (e.graphQLErrors.find((ge) => ge.extensions?.code === 'google-account-already-used')) {
        window.alert('このGoogleアカウントは、すでに他のユーザーに紐付けされています');
      }
    },
    refetchQueries: [
      {
        query: MyGoogleAccountDocument,
      },
    ],
  });

  return (
    <Modal
      title={'Googleアカウントを再連携してください'}
      isShow={props.showModal}
      showClose={false}
      onClose={() => {}}>
      {step === 1 && (
        <View style={{ flexDirection: 'column' }}>
          <View style={{ marginTop: 10 }}>
            <Typography variant={TypographyType.Normal} style={{ textAlign: 'center' }}>
              {`組織の管理者の方によって、Googleカレンダー情報の連携が禁止されました。${`\n`}Googleカレンダー連携をしないようにするために、${'\n'}以下の説明に従い、Googleアカウントの再連携を行ってください。`}
            </Typography>
          </View>
          <View
            style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
            <Button
              text={'次へ'}
              style={{ minWidth: 100, marginRight: 10, marginVertical: 10 }}
              onPress={() => {
                setStep(2);
              }}
            />
          </View>
        </View>
      )}
      {step === 2 && (
        <View style={{ flexDirection: 'column' }}>
          <View style={{ marginTop: 10 }}>
            <Typography variant={TypographyType.Normal} style={{ textAlign: 'center' }}>
              {`Googleのアカウント設定画面を開き、タイムデザイナーのアクセス権限を一度削除してください。${'\n'}詳細な手順を確認するには、以下をクリックしてください。`}
            </Typography>
          </View>
          <View
            style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
            <Link
              isExternalLink={true}
              path={
                'https://manual.timedesigner.com/v1/google-google-workspace'
              }
              style={{
                marginBottom: 10,
              }}>
              <Typography
                variant={TypographyType.Normal}
                style={{ fontSize: 18, color: themeContext.colors.link }}>
                操作手順を確認する
              </Typography>
            </Link>
          </View>
          <View
            style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
            <Button
              text={'次へ'}
              style={{ minWidth: 100, marginRight: 10, marginVertical: 10 }}
              onPress={() => {
                setStep(3);
              }}
            />
            <Button
              text={'戻る'}
              style={{
                minWidth: 100,
                marginRight: 10,
                marginVertical: 10,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.primary }}
              disableValidate={true}
              onPress={() => {
                setStep(1);
              }}
            />
          </View>
        </View>
      )}
      {step === 3 && (
        <View style={{ flexDirection: 'column' }}>
          <View style={{ marginTop: 10 }}>
            <Typography variant={TypographyType.Normal} style={{ textAlign: 'center' }}>
              {`もう一度Googleアカウントを連携してください${'\n'}再連携しないと、Googleアカウントでのログインが出来なくなります。${'\n'}必ず再連携を行うようにしてください。`}
            </Typography>
          </View>
          <View
            style={{
              maxWidth: 300,
              justifyContent: 'center',
              alignItems: 'center',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: 20,
              paddingBottom: 20,
            }}>
            <GoogleLogin
              clientId={Constants.manifest!.extra!.googleClientId}
              buttonText="Googleアカウントを再連携する"
              scope={
                'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile'
              }
              responseType={'code'}
              accessType={'offline'}
              prompt={'consent'}
              onSuccess={async (response) => {
                const result = await connectGoogleAccount({
                  variables: {
                    code: response.code!,
                  },
                });
                if (result?.data?.connectGoogleAccount) {
                  setStep(4);
                }
              }}
              cookiePolicy={'single_host_origin'}
            />
          </View>
          <Button
            text={'戻る'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.primary }}
            disableValidate={true}
            onPress={() => {
              setStep(2);
            }}
          />
        </View>
      )}
      {step === 4 && (
        <View style={{ flexDirection: 'column' }}>
          <View style={{ marginTop: 10 }}>
            <Typography variant={TypographyType.Normal} style={{ textAlign: 'center' }}>
              {`Googleアカウントの再連携が完了しました`}
            </Typography>
          </View>
          <Button
            text={'閉じる'}
            style={{ minWidth: 100, marginRight: 10, marginVertical: 10 }}
            onPress={() => {
              props.onClose();
            }}
          />
        </View>
      )}
    </Modal>
  );
};

const AppPage = () => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const { window: windowDimension } = useDimensions();
  const history = useHistory();
  const windowWidth = windowDimension.width;
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const apolloClient = useApolloClient();
  const audio = new Audio(pushAudio);
  const { data: organizationData, loading: organizationLoading } = useOrganizationQuery({
    variables: {
      id: loginUser?.organizationId!,
    },
    skip: loginUser?.id === undefined,
  });
  const { data: contractData, loading: contractLoading } = useContractQuery({
    fetchPolicy: 'network-only',
    skip: loginUser?.id === undefined,
  });
  const { data: googleAccountData, loading: googleAccountLoading } = useMyGoogleAccountQuery({
    fetchPolicy: 'network-only',
    skip: loginUser?.id === undefined,
  });
  const [fetchUnreadAnnouncementCount] = useUnreadAnnouncementCountLazyQuery({
    fetchPolicy: 'network-only',
  });
  const [fetchAnnouncements] = useAnnouncementsLazyQuery({
    fetchPolicy: 'network-only',
  });

  useInterval(() => {
    fetchUnreadAnnouncementCount();
    fetchAnnouncements();
  }, 1000 * 60 * 60); //1時間に1回、運営からのお知らせを取得する

  const [showNotification, setShowNotification] = useState(false);
  const [showReConnectGoogleAccount, setShowReConnectGoogleAccount] = useState(false);
  const { data: meData, loading: meLoading } = useMeQuery({
    fetchPolicy: 'network-only',
    skip: loginUser?.id === undefined,
  });
  const { loading: mySettingsLoading, data: mySettingsData } = useMySettingsQuery({
    fetchPolicy: 'network-only',
    skip: loginUser?.id === undefined,
  });
  const { loading: firstViewProjectLoading, data: firstViewProjectData } = useFirstViewProjectQuery(
    {
      fetchPolicy: 'network-only',
      skip: loginUser?.id === undefined,
    }
  );

  const onSelectOrganization = useCallback(
    (user: LoginUser) => {
      setLoginUser(user);
      history.push(`/app/${user.organizationId}/`);
    },
    [setLoginUser]
  );

  useInterval(() => {
    if (Platform.OS === 'web') {
        try {
        //アプリへの滞在時間をわかるようにするため、１分ごとにGAにイベントを送信する
        if (!(window as any).gtag) {
          return;
        }
        (window as any).gtag('event', 'staying');
      } catch (e) {
        //NOP
      }
    }
  }, 1000 * 60 * 5);

  const isFreeTrialExpireDateApproaching = useCallback(() => {
    return () =>
      moment(contractData?.contract?.freeTrialExpireDatetime).diff(moment(), 'days') <= 7 &&
      contractData?.contract?.billingType === BillingType.NonRegistered &&
      contractData.contract.plan.code !== Plan.Basic;
  }, [contractData]);

  useInterval(() => {
    // フリートライアル期限が7日以内の場合、期限が近づいていることを24時間ごとに通知する
    if (!organizationData?.organization?.suspensionFlg) {
      setShowNotification(isFreeTrialExpireDateApproaching());
    }
  }, 1000 * 60 * 60 * 24); //TODO このやり方ではダメで、Cookieを使わないと。これだと24時間画面を表示しっぱなしじゃないと表示されない

  useEffect(() => {
    setShowNotification(
      organizationData?.organization?.suspensionFlg! || isFreeTrialExpireDateApproaching()
    );
  }, [organizationData, contractData]);

  useEffect(() => {
    setShowReConnectGoogleAccount(
      organizationData?.organization?.allowConnectThirdPartyCalendar === false &&
        googleAccountData?.myGoogleAccount?.calendarPermission === true
    );
  }, [organizationData, googleAccountData]);

  const moveToMentionTask = (mention: MentionV2) => {
    if (mention.projectId) {
      history.push(UrlUtil.moveProjectDetailUrlById(mention.projectId));
      return;
    }

    const taskId = mention.taskId
    const projectId = mention.projectId
    const teamId = mention.teamId
    history.push(
      `/app/${
        loginUser!.organizationId
      }/${teamId}/projects/${projectId}/list/task/${taskId}/detail/` +
        (mention?.commentId ? `comment/${mention?.commentId}/?forcusComment=true` : '')
    );
  };

  useEffect(() => {
    if ('Notification' in window) {
      // 通知が許可されていたら早期リターン
      const permission = window?.Notification?.permission;
      if (permission === 'denied' || permission === 'granted') {
        return;
      }
      // 通知の許可を求める
      window?.Notification?.requestPermission();
    }
  }, []);

  useWebSocketSync({
    moveToMentionTask: moveToMentionTask,
    notifyMention: (mention, updateNotificationToRead) => {
      const audio = new Audio(pushAudio);
      const value = convertFromRaw(
        JSON.parse(
          (mention?.message)!
        )
      );
      const state = EditorState.createWithContent(value);
      const text = state.getCurrentContent().getPlainText();

      const moveToTaskOrComment = () => {
        // 対象のタスク・コメントへ移動する
        moveToMentionTask(mention);
        updateNotificationToRead();
      };

      // PUSH通知を有効にしている場合には、PUSH通知で内容を通知する
      if ('Notification' in window && window.Notification.permission === 'granted') {
        const notification = new window.Notification(
          `(From:${mention?.sendMember.name}) ${text.replaceAll('\n', '').slice(0, 20)}`,
          {
            silent: true, // 通知音をOFFにしないと、Electronなどでデフォルトのシステム音が鳴ってしまう。
          }
        );
        // PUSH通知だけだと分かりづらいケースもあるので、トーストでも表示する
        toast(`(From:${mention?.sendMember.name}) ${text.replaceAll('\n', '').slice(0, 20)}`, {
          type: 'info',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClick: () => {
            moveToTaskOrComment();
          },
        });
        notification.addEventListener('show', () => {
          audio.volume = 0.8;
          audio.play().catch((err) => {});
        });
        notification.addEventListener('click', () => {
          notification.close();
          moveToTaskOrComment();
        });
      } else {
        // PUSH通知を有効にしていない場合には、トースト表示で通知する
        toast(`(From:${mention?.sendMember.name}) ${text.replaceAll('\n', '').slice(0, 20)}`, {
          type: 'info',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClick: () => {
            moveToTaskOrComment();
          },
        });
        audio.volume = 0.8;
        audio.play().catch((err) => {});
      }
    },
    notifyProjectAssigned: (projectAssigned, updateNotificationToRead) => {
      const audio = new Audio(pushAudio);
      const moveToProject = () => {
        // 対象のプロジェクトへ移動する
        history.push(UrlUtil.moveProjectDetailUrlById(projectAssigned.projectId));
        updateNotificationToRead();
      };

      // PUSH通知を有効にしている場合には、PUSH通知で内容を通知する
      if ('Notification' in window && window.Notification.permission === 'granted') {
        const notification = new window.Notification(
          `(From:${projectAssigned?.sendMember.name}) タスクが割り当てられました}`,
          {
            silent: true, // 通知音をOFFにしないと、Electronなどでデフォルトのシステム音が鳴ってしまう。
          }
        );
        //PUSH通知だけだと分かりづらいケースもあるので、トーストでも表示する
        toast(`(From:${projectAssigned?.sendMember.name}) プロジェクトが割り当てられました}`, {
          type: 'info',
          position: 'bottom-right',
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClick: () => {
            moveToProject();
          },
        });
        notification.addEventListener('show', () => {
          audio.volume = 0.8;
          audio.play().catch((err) => {});
        });
        notification.addEventListener('click', () => {
          notification.close();
          moveToProject();
        });
      } else {
        // PUSH通知を有効にしていない場合には、トースト表示でのみ通知する
        toast(`(From:${projectAssigned?.sendMember.name}) プロジェクトが割り当てられました}`, {
          type: 'info',
          position: 'bottom-right',
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClick: () => {
            moveToProject();
          },
        });
        audio.volume = 0.8;
        audio.play().catch((err) => {});
      }
    },
    notifyTaskAssigned: (taskAssigned, updateNotificationToRead) => {
      const audio = new Audio(pushAudio);
      const moveToTask = () => {
        // 対象のタスクへ移動する
        history.push(
          `/app/${loginUser!.organizationId}/${taskAssigned.teamId}/projects/${
            taskAssigned.projectId
          }/list/task/${taskAssigned.taskId}/detail/`
        );
        updateNotificationToRead();
      };

      // PUSH通知を有効にしている場合には、PUSH通知で内容を通知する
      if ('Notification' in window && window.Notification.permission === 'granted') {
        const notification = new window.Notification(
          `(From:${taskAssigned?.sendMember.name}) タスクが割り当てられました}`,
          {
            silent: true, // 通知音をOFFにしないと、Electronなどでデフォルトのシステム音が鳴ってしまう。
          }
        );
        //PUSH通知だけだと分かりづらいケースもあるので、トーストでも表示する
        toast(`(From:${taskAssigned?.sendMember.name}) タスクが割り当てられました}`, {
          type: 'info',
          position: 'bottom-right',
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClick: () => {
            moveToTask();
          },
        });
        notification.addEventListener('show', () => {
          audio.volume = 0.8;
          audio.play().catch((err) => {});
        });
        notification.addEventListener('click', () => {
          notification.close();
          moveToTask();
        });
      } else {
        // PUSH通知を有効にしていない場合には、トースト表示でのみ通知する
        toast(`(From:${taskAssigned?.sendMember.name}) タスクが割り当てられました}`, {
          type: 'info',
          position: 'bottom-right',
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClick: () => {
            moveToTask();
          },
        });
        audio.volume = 0.8;
        audio.play().catch((err) => {});
      }
    },
    notifyLongTimeWorkingTask: (workingInfo) => {
      const moveToTask = () => {
        history.push(
          `/app/${loginUser!.organizationId}/${workingInfo.teamId}/projects/${
            workingInfo.projectId
          }/list/task/${workingInfo.taskId}/detail/`
        );
      };

      // PUSH通知を有効にしている場合には、PUSH通知で内容を通知する
      if ('Notification' in window && window.Notification.permission === 'granted') {
        const notification = new window.Notification(
          `作業中のタスクがあります${'\n'}${workingInfo.taskTitle}`,
          {
            silent: true, // 通知音をOFFにしないと、Electronなどでデフォルトのシステム音が鳴ってしまう。
          }
        );
        // PUSH通知だけだと分かりづらいケースがあるので、トーストでも表示する
        toast(`作業中のタスクがあります${'\n'}${workingInfo.taskTitle}`, {
          type: 'info',
          position: 'bottom-right',
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClick: () => {
            moveToTask();
          },
        });
        notification.addEventListener('show', () => {
          audio.volume = 0.8;
          audio.play().catch((err) => {});
        });
        notification.addEventListener('click', () => {
          notification.close();
          moveToTask();
        });
      } else {
        // PUSH通知を有効にしていない場合には、トースト表示でのみ通知する
        toast(`作業中のタスクがあります${'\n'}${workingInfo.taskTitle}`, {
          type: 'info',
          position: 'bottom-right',
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClick: () => {
            moveToTask();
          },
        });
        audio.volume = 0.8;
        audio.play().catch((err) => {});
      }
    },
    notifyTaskRemainder: (info) => {
      const moveToTask = () => {
        history.push(
          `/app/${loginUser!.organizationId}/${info.teamId}/projects/${info.projectId}/list/task/${
            info.taskId
          }/detail/`
        );
      };

      // PUSH通知を有効にしている場合には、PUSH通知で内容を通知する
      if ('Notification' in window && window.Notification.permission === 'granted') {
        const notification = new window.Notification(
          info.taskRemainderType === TaskRemainderType.DeadlinePassed
            ? `期限が過ぎたタスクがあります。${'\n'}「${info.taskTitle}」`
            : `期限まであと${TimeUtil.formatWithDay(
                info.remainedBeforeDeadlineMinutes
              )}のタスクがあります。${'\n'}「${info.taskTitle}」`,
          {
            silent: true, // 通知音をOFFにしないと、Electronなどでデフォルトのシステム音が鳴ってしまう。
          }
        );
        //PUSH通知だけだと分かりづらいケースもあるので、トーストでも表示する
        toast(
          info.taskRemainderType === TaskRemainderType.DeadlinePassed
            ? `期限が過ぎたタスクがあります。${'\n'}「${info.taskTitle}」`
            : `期限まであと${TimeUtil.formatWithDay(
                info.remainedBeforeDeadlineMinutes
              )}のタスクがあります。${'\n'}「${info.taskTitle}」`,
          {
            type: 'info',
            position: 'bottom-right',
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            onClick: () => {
              moveToTask();
            },
          }
        );
        notification.addEventListener('show', () => {
          audio.volume = 0.8;
          audio.play().catch((err) => {});
        });
        notification.addEventListener('click', () => {
          notification.close();
          moveToTask();
        });
      } else {
        // PUSH通知を有効にしていない場合には、トースト表示のみで通知する
        toast(
          info.taskRemainderType === TaskRemainderType.DeadlinePassed
            ? `期限が過ぎたタスクがあります。${'\n'}「${info.taskTitle}」`
            : `期限まであと${TimeUtil.formatWithDay(
                info.remainedBeforeDeadlineMinutes
              )}のタスクがあります。${'\n'}「${info.taskTitle}」`,
          {
            type: 'info',
            position: 'bottom-right',
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            onClick: () => {
              moveToTask();
            },
          }
        );
        audio.volume = 0.8;
        audio.play().catch((err) => {});
      }
    },
    notifyUpdateMemberReportSuccess: (id) => {
      toast('Excelファイルの作成が完了しました', {
        type: 'success',
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClick: () => {
          history.push(`/app/${loginUser!.organizationId}/my-analytics/export/`);
        },
      });
    },
    notifyUpdateMemberReportFailure: (id) => {
      toast('Excelファイルの作成に失敗しました', {
        type: 'error',
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClick: () => {
          history.push(`/app/${loginUser!.organizationId}/my-analytics/export/`);
        },
      });
    },
    notifyUpdateMemberCsvReportSuccess: (id) => {
      toast('CSVファイルの作成が完了しました', {
        type: 'success',
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClick: () => {
          history.push(`/app/${loginUser!.organizationId}/my-analytics/csv-export/`);
        },
      });
    },
    notifyUpdateMemberCsvReportFailure: (id) => {
      toast('CSVファイルの作成に失敗しました', {
        type: 'error',
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClick: () => {
          history.push(`/app/${loginUser!.organizationId}/my-analytics/csv-export/`);
        },
      });
    },
    notifyUpdateTeamReportSuccess: (id, teamId) => {
      toast('Excelファイルの作成が完了しました', {
        type: 'success',
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClick: () => {
          history.push(`/app/${loginUser!.organizationId}/${teamId}/team-analytics/export/`);
        },
      });
    },
    notifyUpdateTeamReportFailure: (id, teamId) => {
      toast('Excelファイルの作成に失敗しました', {
        type: 'error',
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClick: () => {
          history.push(`/app/${loginUser!.organizationId}/${teamId}/team-analytics/export/`);
        },
      });
    },
    notifyUpdateTeamCsvReportSuccess: (id, teamId) => {
      toast('CSVファイルの作成が完了しました', {
        type: 'success',
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClick: () => {
          history.push(`/app/${loginUser!.organizationId}/${teamId}/team-analytics/csv-export/`);
        },
      });
    },
    notifyUpdateTeamCsvReportFailure: (id, teamId) => {
      toast('CSVファイルの作成に失敗しました', {
        type: 'error',
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClick: () => {
          history.push(`/app/${loginUser!.organizationId}/${teamId}/team-analytics/csv-export/`);
        },
      });
    },
    notifyUpdateOrganizationReportSuccess: (id) => {
      toast('Excelファイルの作成が完了しました', {
        type: 'success',
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClick: () => {
          history.push(`/app/${loginUser!.organizationId}/organization-analytics/export/`);
        },
      });
    },
    notifyUpdateOrganizationReportFailure: (id) => {
      toast('Excelファイルの作成に失敗しました', {
        type: 'error',
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClick: () => {
          history.push(`/app/${loginUser!.organizationId}/organization-analytics/export/`);
        },
      });
    },
    notifyUpdateOrganizationCsvReportSuccess: (id) => {
      toast('CSVファイルの作成が完了しました', {
        type: 'success',
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClick: () => {
          history.push(`/app/${loginUser!.organizationId}/organization-analytics/csv-export/`);
        },
      });
    },
    notifyUpdateOrganizationCsvReportFailure: (id) => {
      toast('Excelファイルの作成に失敗しました', {
        type: 'error',
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClick: () => {
          history.push(`/app/${loginUser!.organizationId}/organization-analytics/csv-export/`);
        },
      });
    },
    notifyExportProjectExcelSuccess: (data) => {
      toast('Excelファイルの作成が完了しました', {
        type: 'success',
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClick: () => {
          if (organizationData?.organization?.plan.code === Plan.Basic) {
            history.push(`/app/${loginUser!.organizationId}/my-export-file/export-projects/`);
          } else {
            if (data?.teamid) {
              history.push(
                `/app/${loginUser!.organizationId}/${data.teamid}/export-file/export-projects/`
              );
            } else {
              history.push(`/app/${loginUser!.organizationId}/export-file/export-projects/`);
            }
          }
        },
      });
    },
    notifyExportProjectExcelFailure: (data) => {
      toast('Excelファイルの作成に失敗しました', {
        type: 'error',
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClick: () => {
          if (organizationData?.organization?.plan.code === Plan.Basic) {
            history.push(`/app/${loginUser!.organizationId}/my-export-file/export-projects/`);
          } else {
            if (data?.teamid) {
              history.push(
                `/app/${loginUser!.organizationId}/${data.teamid}/export-file/export-projects/`
              );
            } else {
              history.push(`/app/${loginUser!.organizationId}/export-file/export-projects/`);
            }
          }
        },
      });
    },
    notifyExportTaskExcelSuccess: (data) => {
      toast('Excelファイルの作成が完了しました', {
        type: 'success',
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClick: () => {
          if (organizationData?.organization?.plan.code === Plan.Basic) {
            history.push(`/app/${loginUser!.organizationId}/my-export-file/export-tasks/`);
          } else {
            if (data?.teamid) {
              history.push(
                `/app/${loginUser!.organizationId}/${data.teamid}/export-file/export-tasks/`
              );
            } else {
              history.push(`/app/${loginUser!.organizationId}/export-file/export-tasks/`);
            }
          }
        },
      });
    },
    notifyExportTaskExcelFailure: (data) => {
      toast('Excelファイルの作成に失敗しました', {
        type: 'error',
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClick: () => {
          if (organizationData?.organization?.plan.code === Plan.Basic) {
            history.push(`/app/${loginUser!.organizationId}/my-export-file/export-tasks/`);
          } else {
            if (data?.teamid) {
              history.push(
                `/app/${loginUser!.organizationId}/${data.teamid}/export-file/export-tasks/`
              );
            } else {
              history.push(`/app/${loginUser!.organizationId}/export-file/export-tasks/`);
            }
          }
        },
      });
    },
    notifyExportClientExcelSuccess: (data) => {
      toast('Excelファイルの作成が完了しました', {
        type: 'success',
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClick: () => {
          if (organizationData?.organization?.plan.code === Plan.Basic) {
            history.push(`/app/${loginUser!.organizationId}/my-export-file/export-clients/`);
          } else {
            history.push(`/app/${loginUser!.organizationId}/export-file/export-clients/`);
          }
        },
      });
    },
    notifyExportClientExcelFailure: (data) => {
      toast('Excelファイルの作成に失敗しました', {
        type: 'error',
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClick: () => {
          if (organizationData?.organization?.plan.code === Plan.Basic) {
            history.push(`/app/${loginUser!.organizationId}/my-export-file/export-clients/`);
          } else {
            history.push(`/app/${loginUser!.organizationId}/export-file/export-clients/`);
          }
        },
      });
    },
    notifyExportMemberExcelSuccess: (data) => {
      toast('Excelファイルの作成が完了しました', {
        type: 'success',
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClick: () => {
          if (organizationData?.organization?.plan.code === Plan.Basic) {
            history.push(`/app/${loginUser!.organizationId}/my-export-file/export-members/`);
          } else {
            history.push(`/app/${loginUser!.organizationId}/export-file/export-members/`);
          }
        },
      });
    },
    notifyExportMemberExcelFailure: (data) => {
      toast('Excelファイルの作成に失敗しました', {
        type: 'error',
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClick: () => {
          if (organizationData?.organization?.plan.code === Plan.Basic) {
            history.push(`/app/${loginUser!.organizationId}/my-export-file/export-members/`);
          } else {
            history.push(`/app/${loginUser!.organizationId}/export-file/export-members/`);
          }
        },
      });
    },
    notifyExportICalFileSuccess: (id) => {},
    notifyExportICalFileFailure: (id) => {},
  });



  if (!loginUser) {
    location.pathname = '';
    return <Redirect to={'/'} />;
  }

  if (
    mySettingsLoading ||
    firstViewProjectLoading ||
    meLoading ||
    organizationLoading ||
    contractLoading
  ) {
    return <></>;
  }

  if (
    location.hash === '#/app' ||
    location.hash === '#/app/' ||
    location.hash === `#/app/${loginUser.organizationId}/`
  ) {
    if (mySettingsData?.mySettings?.initialViewTypeCode === InitialViewType.MyPage) {
      return <Redirect to={`/app/${loginUser.organizationId}/my/favorite-project/`} />;
    }
    if (firstViewProjectData?.firstViewProject?.projectId) {
      if (
        mySettingsData!.mySettings!.projectInitialViewTypeCode ===
        ProjectInitialViewType.KanbanBoard
      ) {
        return (
          <Redirect
            to={`/app/${loginUser.organizationId}/${
              firstViewProjectData!.firstViewProject!.teamId
            }/projects/${firstViewProjectData!.firstViewProject!.projectId}/board/`}
          />
        );
      }
      if (
        mySettingsData!.mySettings!.projectInitialViewTypeCode === ProjectInitialViewType.GanttChart
      ) {
        return (
          <Redirect
            to={`/app/${loginUser.organizationId}/${
              firstViewProjectData!.firstViewProject!.teamId
            }/projects/${firstViewProjectData!.firstViewProject!.projectId}/schedule/`}
          />
        );
      }
      return (
        <Redirect
          to={`/app/${loginUser.organizationId}/${
            firstViewProjectData!.firstViewProject!.teamId
          }/projects/${firstViewProjectData!.firstViewProject!.projectId}/list/`}
        />
      );
    }
    if (firstViewProjectData?.firstViewProject?.teamId) {
      return (
        <Redirect
          to={`/app/${loginUser.organizationId}/${
            firstViewProjectData!.firstViewProject!.teamId
          }/projects/`}
        />
      );
    }
    return <Redirect to={`/app/${loginUser.organizationId}/my/favorite-project/`} />;
  }

  const isDashboardPage = location.hash.startsWith(`#/app/${loginUser.organizationId}/dashboard/`);
  const isTaskListPage =
    location.hash.indexOf('/my/favorite-project/') !== -1 ||
    location.hash.indexOf('/my/assigned-project/') !== -1 ||
    location.hash.indexOf('/my/favorite-task/list/') !== -1 ||
    location.hash.indexOf('/my/assigned-task/list/') !== -1 ||
    /\/projects\/.+\/list\//.test(location.hash);
  return (
    <>
      <View style={{ flex: 1, flexDirection: 'row' }}>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <OrganizationList onSelectOrganization={onSelectOrganization} />
        <SideMenu />
        <View style={{ flex: 1, flexDirection: 'column', overflow: 'hidden' }}>
          <LatestTaskHeader organizationId={loginUser.organizationId} />
          <View style={{ flexDirection: 'row' }}>
            <AppMainContainer />
            {windowWidth < 2450 || isDashboardPage || isTaskListPage ? (
              <>
                <View style={{ width: 50 }} />
                <View
                  style={{
                    position: 'absolute',
                    right: 0,
                    flexDirection: 'row',
                  }}>
                  <ProjectDetail />
                  <TaskDetail />
                  <CalendarEventDetail />
                  <ProjectTemplateDetail />
                  <ProjectTemplateTaskDetail />
                  <MessageDetail />
                  <RightSideBar />
                </View>
              </>
            ) : (
              <>
                <ProjectDetail />
                <TaskDetail />
                <CalendarEventDetail />
                <ProjectTemplateDetail />
                <ProjectTemplateTaskDetail />
                <MessageDetail />
                <RightSideBar />
              </>
            )}
          </View>
        </View>
      </View>
      {showNotification && Cookies.get('SUPPRESS_FREE_TRIAL_EXPIRE_NOTIFICATION') !== 'true' && (
        <NotificationFooter
          onClose={() => {
            setShowNotification(false);
            Cookies.set('SUPPRESS_FREE_TRIAL_EXPIRE_NOTIFICATION', 'true', {
              expires: moment().add(3, 'days').toDate(),
            });
          }}>
          {organizationData?.organization?.suspensionFlg ? (
            <Typography
              variant={TypographyType.Normal}
              style={{
                color: '#fff',
                textAlign: 'center',
              }}>
              現在、お客様のアカウントは休止状態となっており、操作が制限されるようになっています。
              {'\n'}
              {meData?.me?.adminRole ? (
                <>
                  休止状態を解除するには、
                  <Link
                    path={`/app/${loginUser.organizationId}/${
                      contractData?.contract?.plan.code === Plan.Professional ? 'my-' : ''
                    }settings/contract/`}>
                    「ご契約内容画面」
                  </Link>
                  からお支払い方法のご登録をお願いします。
                </>
              ) : (
                <>休止を解除するには、管理者の方へお問い合わせください。</>
              )}
            </Typography>
          ) : isFreeTrialExpireDateApproaching() ? (
            <Typography
              variant={TypographyType.Normal}
              style={{
                color: '#fff',
                textAlign: 'center',
              }}>
              {moment(contractData?.contract?.freeTrialExpireDatetime).isBefore(moment())
                ? `お客様のフリートライアル期間は終了しました。`
                : `お客様のフリートライアル期間は、残り${moment(
                    contractData?.contract?.freeTrialExpireDatetime
                  ).diff(moment(), 'days')}日です。`}
              {'\n'}
              {meData?.me?.adminRole ? (
                <>
                  引き続きご利用になる場合は、
                  <Link
                    path={`/app/${loginUser.organizationId}/${
                      contractData?.contract?.plan.code === Plan.Professional ? 'my-' : ''
                    }settings/contract/`}>
                    「ご契約内容画面」
                  </Link>
                  からお支払い方法のご登録をお願いします。
                </>
              ) : (
                <>{`※右側のXマークをクリックすると、このメッセージを非表示にすることが出来ます。`}</>
              )}
            </Typography>
          ) : null}
        </NotificationFooter>
      )}
      <ReConnectGoogleAccountModal
        showModal={showReConnectGoogleAccount}
        onClose={() => setShowReConnectGoogleAccount(false)}
      />
    </>
  );
};

export default React.memo(AppPage);
