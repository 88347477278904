import { useState, useEffect, useRef } from 'react';

const useHover = (specifiedRef?: any): [any, boolean, (value: boolean) => void] => {
  const ref = useRef(specifiedRef || null);
  const [value, setValue] = useState(false);

  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);

  useEffect(() => {
    const node = ref.current as any;
    if (node) {
      node.addEventListener('mouseover', handleMouseOver);
      node.addEventListener('mouseout', handleMouseOut);

      return () => {
        node.removeEventListener('mouseover', handleMouseOver);
        node.removeEventListener('mouseout', handleMouseOut);
      };
    }
  }, [ref.current]);

  return [ref, value, setValue];
};

export default useHover;
