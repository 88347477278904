import React from 'react';
import { TouchableOpacity } from 'react-native';
import { Organization, SortOrder } from '../../../../graphql/api/API';
import when from '../../../../lang-extention/When';
import CaretDownIcon from '../image-icon/caret-down';
import CaretSetIcon from '../image-icon/caret-set';
import CaretUpIcon from '../image-icon/caret-up';
import queryString from 'query-string';
import { useHistory } from 'react-router';

interface IProps {
  organization: Organization;
  searchCondition: any;
  url: string;
  sortKey: any;
  children: React.ReactNode;
  style?: any;
  caretStyle?: 'black' | 'white';
}

const SortHeaderItem = (props: IProps) => {
  const history = useHistory();
  return (
    <TouchableOpacity
      style={[{ flexDirection: 'row' }, props.style]}
      onPress={() => {
        history.push(
          queryString.stringifyUrl({
            url: props.url,
            query: Object.assign(props.searchCondition ?? {}, {
              sortKey: when(props.searchCondition?.sortOrder) //
                .on(
                  (v) => v === null || v === undefined,
                  () => props.sortKey
                )
                .on(
                  (v) => v === SortOrder.Asc,
                  () => props.sortKey
                )
                .otherwise(() => null),
              sortOrder: when(props.searchCondition?.sortOrder) //
                .on(
                  (v) => v === null || v === undefined,
                  () => SortOrder.Asc
                )
                .on(
                  (v) => v === SortOrder.Asc,
                  () => SortOrder.Desc
                )
                .otherwise(() => null),
            }),
          })
        );
      }}>
      {props.searchCondition?.sortKey === props.sortKey ? (
        when(props.searchCondition?.sortOrder) //
          .on(
            (v) => v === null || v === undefined,
            () => (
              <CaretSetIcon
                size={12}
                containerStyle={{
                  opacity: 0.2,
                  marginLeft: 3,
                }}
                reverse={props.caretStyle === 'white'}
                onPress={() => {
                  history.push(
                    queryString.stringifyUrl({
                      url: props.url,
                      query: Object.assign(props.searchCondition ?? {}, {
                        sortKey: props.sortKey,
                        sortOrder: SortOrder.Asc,
                      }),
                    })
                  );
                }}
              />
            )
          )
          .on(
            (v) => v === SortOrder.Asc,
            () => (
              <CaretUpIcon
                size={10}
                containerStyle={{ marginLeft: 3 }}
                reverse={props.caretStyle === 'white'}
                onPress={() => {
                  history.push(
                    queryString.stringifyUrl({
                      url: props.url,
                      query: Object.assign(props.searchCondition ?? {}, {
                        sortKey: props.sortKey,
                        sortOrder: SortOrder.Desc,
                      }),
                    })
                  );
                }}
              />
            )
          )
          .otherwise(() => (
            <CaretDownIcon
              size={10}
              containerStyle={{ marginLeft: 3 }}
              reverse={props.caretStyle === 'white'}
              onPress={() => {
                history.push(
                  queryString.stringifyUrl({
                    url: props.url,
                    query: Object.assign(props.searchCondition ?? {}, {
                      sortKey: null,
                      sortOrder: null,
                    }),
                  })
                );
              }}
            />
          ))
      ) : (
        <CaretSetIcon
          size={12}
          containerStyle={{
            opacity: 0.2,
            marginLeft: 3,
          }}
          reverse={props.caretStyle === 'white'}
          onPress={() => {
            history.push(
              queryString.stringifyUrl({
                url: props.url,
                query: Object.assign(props.searchCondition ?? {}, {
                  sortKey: props.sortKey,
                  sortOrder: SortOrder.Asc,
                }),
              })
            );
          }}
        />
      )}
      {props.children}
    </TouchableOpacity>
  );
};

export default SortHeaderItem;
