import React, { createContext, useContext } from 'react';
import { Mode, useForm, UseFormMethods } from 'react-hook-form';
import { View, ViewProps } from 'react-native';
import FormContext from './context';

interface Props extends ViewProps {
  validationMode?: Mode;
  children: React.ReactNode;
}

const Form: React.FC<Props> = (props) => {
  const mode = props.validationMode || 'onBlur';
  const form: UseFormMethods = useForm({ mode: mode });

  return (
    <FormContext.Provider value={form}>
      <View style={[{ width: '100%' }, props.style]} {...props}>
        {props.children}
      </View>
    </FormContext.Provider>
  );
};

export default Form;
