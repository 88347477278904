import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import {
  StyleProp,
  ViewStyle,
  Animated,
  NativeSyntheticEvent,
  View,
  TouchableWithoutFeedback,
  TouchableOpacity,
  NativeScrollEvent,
  FlatListProps,
  LayoutChangeEvent,
  Platform,
  ActivityIndicator,
} from 'react-native';
import {
  GestureHandlerStateChangeNativeEvent,
  PanGestureHandler,
  PanGestureHandlerEventExtra,
  State,
} from 'react-native-gesture-handler';
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../theme';

const FlatList = styled.FlatList`
  display: flex;
  width: 100%;
`;

const Container = styled.View`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

interface DragInfo<T> {
  item: T;
  startRowIndex: number;
  endRowIndex: number;
  startColumnIndex: number;
  endColumnIndex: number;
}

export class NonForwardedRefAnimatedView extends React.Component<any> {
  render() {
    return (
      <Animated.View style={this.props.style} focusable={false} {...this.props}>
        {this.props.children}
      </Animated.View>
    );
  }
}

interface IRenderItemMailProps<T> {
  item: T;
  index: number;
  renderItem: (item: T, index: number) => React.ReactElement<any, any> | null;
}

const RenderItemMain = React.memo(<T extends any>(props: IRenderItemMailProps<T>) => {
  return <>{props.renderItem(props.item, props.index)}</>;
});

interface IDragInfo<T> {
  item: T;
  top: number;
  left: number;
  width: number;
  index: number;
}

interface IRenderItemWrapperProps<T> {
  renderItem: (item: T, index: number) => React.ReactElement<any, any> | null;
  item: T;
  allItemCount: number;
  index: number;
  column: number;
  columnCountMap: ColumnCount[];
  height: number;
  width: number;
  containerRef: any;
  virticalDraggable: boolean;
  horizontalDraggable: boolean;
  isDraggable: boolean;
  ghostViewRef: any;
  listRefs?: Array<IListRefInfo>;
  afterLastItemElement?: React.ReactNode;
  onDraggingItem: (item: IDragInfo<T> | null) => void;
  onDragStart?: (item: T) => void;
  onDropEnd?: () => void;
  onPress?: (item: T, index: number) => void;
  onDrop?: (drop: DragInfo<T>) => void;
  selectedItemIndex: number | null;
  setSelectedItemIndex: (index: number) => void;
}

const RenderItemWrapper = React.memo(<T extends any>(props: IRenderItemWrapperProps<T>) => {
  const [dragging, setDragging] = useState(false);
  const ref = useRef();
  const [translationY, setTranslationY] = useState(new Animated.Value(0));
  const [draggingLazy, setDraggingLazy] = useState(false);
  const [draggingInfo, setDraggingInfo] = useState<IDragInfo<T> | null>(null);
  const [globalDragInfo, setGlobalDragInfo] = useContext(GlobalDragContext);
  const [globalDragColumnInfo, setGlobalDragColumnInfo] = useContext(GlobalDragColumnContext);
  const [isDraggable, setDraggable] = useState(props.isDraggable);
  const touchRef = useRef();

  useEffect(() => {
    if (Platform.OS !== 'web') {
      return;
    }
    if (touchRef && props.selectedItemIndex === props.index) {
      (touchRef.current as any)?.click();
      (touchRef.current as any)?.focus();
    }
  }, [touchRef, props.selectedItemIndex]);

  const onPress = useCallback(() => {
    if (draggingLazy) {
      // ドラッグ＆ドロップした時に、ドロップ時にonPressの処理が発生しないようにキャンセル
      return;
    }
    if (props.onPress) {
      props.onPress(props.item, props.index);
    }
  }, [props.onPress, draggingLazy]);

  const dudListener = useCallback(
    (
      event: NativeSyntheticEvent<
        PanGestureHandlerEventExtra & GestureHandlerStateChangeNativeEvent
      >
    ) => {
      if (!props.virticalDraggable && !props.horizontalDraggable) {
        return;
      }
      if (event.nativeEvent.state === State.BEGAN) {
        setDragging(true);
        setDraggingLazy(true);
        setGlobalDragInfo({
          startIndex: props.index,
          endIndex: props.index,
          startColumn: props.column,
          endColumn: props.column,
          dragging: true,
        });
        setGlobalDragColumnInfo({
          startColumn: props.column,
          endColumn: props.column,
          dragging: true,
        });
        if (props.onDraggingItem) {
          const info = {
            item: props.item,
            left: (ref.current as any).getBoundingClientRect().left,
            top: (ref.current as any).getBoundingClientRect().top,
            width: (ref.current as any).getBoundingClientRect().width,
            index: props.index,
          };
          setDraggingInfo(info);
          props.onDraggingItem(info);
        }

        if (props.onDragStart) {
          props.onDragStart(props.item);
        }
      }
      if (event.nativeEvent.state === State.ACTIVE) {
        (props.ghostViewRef?.current as any).style.transform = `translate(${
          !!props.horizontalDraggable ? event.nativeEvent.translationX : 0
        }px, ${!!props.virticalDraggable ? event.nativeEvent.translationY : 0}px)`;

        const moveColumn = event.nativeEvent.translationX / props.width;
        let afterColumn = Math.round(props.column + moveColumn);
        afterColumn = Math.max(afterColumn, 0);
        afterColumn = Math.min(afterColumn, props.columnCountMap.length - 1);

        const startColumnRef = props.listRefs
          ? props.listRefs?.find((ref) => ref.column === props.column)
          : null;
        const endColumnRef = props.listRefs
          ? props.listRefs?.find((ref) => ref.column === afterColumn)
          : null;

        const startColumnScrollOffset =
          (startColumnRef as any)?.listRefs?.current?._listRef?._scrollMetrics?.offset ?? 0;
        const endColumnScrollOffset =
          (endColumnRef as any)?.listRefs?.current?._listRef?._scrollMetrics?.offset ?? 0;

        const startColumnScrolledIndex =
          props.column === afterColumn || startColumnScrollOffset === 0
            ? 0
            : startColumnScrollOffset / props.height;

        const endColumnScrolledIndex =
          props.column === afterColumn || endColumnScrollOffset === 0
            ? 0
            : endColumnScrollOffset / props.height;

        const moveIndex = event.nativeEvent.translationY / props.height;
        let afterIndex = Math.round(
          props.index + moveIndex - startColumnScrolledIndex + endColumnScrolledIndex
        );
        afterIndex = Math.max(afterIndex, 0);
        afterIndex = Math.min(
          afterIndex,
          props.columnCountMap[afterColumn].count - (props.column === afterColumn ? 1 : 0)
        );

        if (
          (globalDragInfo as GlobalDragInfo).endIndex != afterIndex ||
          (globalDragInfo as GlobalDragInfo).endColumn != afterColumn
        ) {
          setGlobalDragInfo({
            startIndex: props.index,
            endIndex: afterIndex,
            startColumn: props.column,
            endColumn: afterColumn,
            dragging: true,
          });
          setGlobalDragColumnInfo({
            startColumn: props.column,
            endColumn: afterColumn,
            dragging: true,
          });
        }
      }
      if (event.nativeEvent.state === State.END) {
        const moveColumn = event.nativeEvent.translationX / props.width;
        let afterColumn = Math.round(props.column + moveColumn);
        afterColumn = Math.max(afterColumn, 0);
        afterColumn = Math.min(afterColumn, props.columnCountMap.length - 1);

        const startColumnRef = props.listRefs
          ? props.listRefs?.find((ref) => ref.column === props.column)
          : null;
        const endColumnRef = props.listRefs
          ? props.listRefs?.find((ref) => ref.column === afterColumn)
          : null;

        const startColumnScrollOffset =
          (startColumnRef as any)?.listRefs?.current?._listRef?._scrollMetrics?.offset ?? 0;
        const endColumnScrollOffset =
          (endColumnRef as any)?.listRefs?.current?._listRef?._scrollMetrics?.offset ?? 0;

        const startColumnScrolledIndex =
          props.column === afterColumn || startColumnScrollOffset === 0
            ? 0
            : startColumnScrollOffset / props.height;

        const endColumnScrolledIndex =
          props.column === afterColumn || endColumnScrollOffset === 0
            ? 0
            : endColumnScrollOffset / props.height;

        const moveIndex = event.nativeEvent.translationY / props.height;
        let afterIndex = Math.round(
          props.index + moveIndex - startColumnScrolledIndex + endColumnScrolledIndex
        );
        afterIndex = Math.max(afterIndex, 0);
        afterIndex = Math.min(
          afterIndex,
          props.columnCountMap[afterColumn].count - (props.column === afterColumn ? 1 : 0)
        );

        setGlobalDragInfo({
          startIndex: props.index,
          endIndex: afterIndex,
          startColumn: props.column,
          endColumn: afterColumn,
          dragging: false,
        });
        setGlobalDragColumnInfo({
          startColumn: props.column,
          endColumn: afterColumn,
          dragging: false,
        });

        setDragging(false);
        setTimeout(() => {
          setDraggingLazy(false);
        }, 400);

        setDraggingInfo(null);
        props.onDraggingItem(null);

        if (props.onDrop && (props.index !== afterIndex || props.column !== afterColumn)) {
          props.onDrop!({
            startColumnIndex: props.column,
            endColumnIndex: afterColumn,
            startRowIndex: props.index,
            endRowIndex: afterIndex,
            item: props.item,
          });
        }

        if (props.onDropEnd) {
          props.onDropEnd();
        }
      }
    },
    [
      props.virticalDraggable,
      props.horizontalDraggable,
      props.index,
      props.item,
      props.allItemCount,
      props.onDrop,
      globalDragInfo,
    ]
  );

  useEffect(() => {
    if (!dragging) {
      if ((globalDragInfo as GlobalDragInfo).dragging) {
        if (
          (globalDragInfo as GlobalDragInfo).endColumn ===
          (globalDragInfo as GlobalDragInfo).startColumn
        ) {
          if (
            (globalDragInfo as GlobalDragInfo).endIndex >= props.index &&
            (globalDragInfo as GlobalDragInfo).startIndex < props.index &&
            (globalDragInfo as GlobalDragInfo).endColumn === props.column
          ) {
            Animated.timing(translationY, {
              toValue: -props.height,
              duration: 200,
              useNativeDriver: true,
            }).start();
          } else if (
            (globalDragInfo as GlobalDragInfo).endIndex <= props.index &&
            (globalDragInfo as GlobalDragInfo).startIndex < props.index
          ) {
            Animated.timing(translationY, {
              toValue: 0,
              duration: 200,
              useNativeDriver: true,
            }).start();
          }
          if (
            (globalDragInfo as GlobalDragInfo).endIndex <= props.index &&
            (globalDragInfo as GlobalDragInfo).startIndex > props.index &&
            (globalDragInfo as GlobalDragInfo).endColumn === props.column
          ) {
            Animated.timing(translationY, {
              toValue: props.height,
              duration: 200,
              useNativeDriver: true,
            }).start();
          } else if (
            (globalDragInfo as GlobalDragInfo).endIndex > props.index &&
            (globalDragInfo as GlobalDragInfo).startIndex > props.index
          ) {
            Animated.timing(translationY, {
              toValue: 0,
              duration: 200,
              useNativeDriver: true,
            }).start();
          }
        } else {
          if (
            (globalDragInfo as GlobalDragInfo).endIndex <= props.index &&
            (globalDragInfo as GlobalDragInfo).endColumn === props.column
          ) {
            Animated.timing(translationY, {
              toValue: props.height,
              duration: 200,
              useNativeDriver: true,
            }).start();
          } else {
            Animated.timing(translationY, {
              toValue: 0,
              duration: 200,
              useNativeDriver: true,
            }).start();
          }
          if (
            (globalDragInfo as GlobalDragInfo).startColumn === props.column &&
            (globalDragInfo as GlobalDragInfo).startIndex <= props.index
          ) {
            Animated.timing(translationY, {
              toValue: -props.height,
              duration: 200,
              useNativeDriver: true,
            }).start();
          }
        }
      } else {
        Animated.timing(translationY, {
          toValue: 0,
          duration: 0,
          useNativeDriver: true,
        }).start();
      }
    }
  }, [globalDragInfo]);

  if (!isDraggable) {
    if (props.onPress) {
      return (
        <TouchableOpacity
          ref={touchRef as any}
          onPress={() => {
            onPress();
            props.setSelectedItemIndex(props.index);
          }}>
          <RenderItemMain
            item={props.item}
            index={props.index}
            renderItem={props.renderItem as any}
          />
        </TouchableOpacity>
      );
    }
    return (
      <RenderItemMain item={props.item} index={props.index} renderItem={props.renderItem as any} />
    );
  }

  return (
    <PanGestureHandler
      maxPointers={1}
      onGestureEvent={Animated.event([], {
        listener: dudListener,
        useNativeDriver: true,
      })}>
      <NonForwardedRefAnimatedView>
        <Animated.View
          focusable={false}
          style={
            {
              transform: [
                {
                  translateY: translationY,
                },
              ],
            } as any
          }>
          <TouchableWithoutFeedback
            ref={touchRef as any}
            onPress={() => {
              if (onPress) {
                onPress();
                props.setSelectedItemIndex(props.index);
              }
            }}>
            <View
              ref={ref as any}
              style={
                {
                  cursor: props.isDraggable ? 'pointer' : 'default',
                  height: props.height,
                } as any
              }>
              {!dragging && (
                <RenderItemMain
                  item={props.item}
                  index={props.index}
                  renderItem={props.renderItem as any}
                  setDraggable={props.setDraggable}
                />
              )}
            </View>
          </TouchableWithoutFeedback>
          {props.index === props.allItemCount - 1 && props.afterLastItemElement}
        </Animated.View>
      </NonForwardedRefAnimatedView>
    </PanGestureHandler>
  );
});

interface IDragDestColumnInnerProps extends IStyleTheme {
  isHover: boolean;
}

const DragDestColumnInner = styled.View<IDragDestColumnInnerProps>`
  height: 100vh;
  background-color: ${(props: IDragDestColumnInnerProps) =>
    props.isHover ? props.theme.colors.baseColor : props.theme.colors.separator};
  border-style: dotted;
  border-width: 3;
  border-color: ${(props: IDragDestColumnInnerProps) =>
    props.isHover ? '#77e8ff' : props.theme.colors.primary};
  opacity: ${(props: IDragDestColumnInnerProps) => (props.isHover ? 0.7 : 1)};
  transition: all 0.2s;
`;

interface IDragDestColumnProps {
  column: number;
  dragToColumn: number;
}

const DragDestColumn = (props: IDragDestColumnProps) => {
  return <DragDestColumnInner isHover={props.column === props.dragToColumn} />;
};

export interface IListRefInfo {
  column: number;
  columnKey: string;
  listRefs: any;
}

interface ColumnCount {
  columnIndex: number;
  count: number;
}

export interface IListItem<T> {
  id: string;
  data: T;
}

interface ICustomScrollFlatListProps extends FlatListProps<any> {
  listRef: any;
}

const CustomScrollFlatList = (props: ICustomScrollFlatListProps) => {
  const [indicator, setIndicator] = React.useState(new Animated.Value(0));
  const [contentSize, setContentSize] = React.useState(0);
  const [scrollViewHeight, setScrollViewHeight] = React.useState(0);
  const [scrollInitialPos, setScrollInitialPos] = React.useState(0);
  const indicatorSize =
    contentSize > scrollViewHeight ? (scrollViewHeight * scrollViewHeight) / contentSize : 0;
  const difference = scrollViewHeight > indicatorSize ? scrollViewHeight - indicatorSize : 1;

  return (
    <>
      <FlatList
        {...props}
        ref={props.listRef}
        // onScroll={Animated.event([{ nativeEvent: { contentOffset: { y: indicator } } }])}
        onScroll={(event: NativeSyntheticEvent<NativeScrollEvent>) => {
          Animated.timing(indicator, {
            toValue: event.nativeEvent.contentOffset.y,
            duration: 0,
            useNativeDriver: true,
          }).start();
        }}
        onContentSizeChange={(w: number, h: number) => {
          setContentSize(h);
          if (props.onContentSizeChange) {
            props.onContentSizeChange(w, h);
          }
        }}
        onLayout={(event: LayoutChangeEvent) => {
          setScrollViewHeight(event.nativeEvent.layout.height);
        }}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
      />
      {indicatorSize > 0 && (
        <TouchableOpacity
          activeOpacity={1}
          onPress={(event) => {
            event.preventDefault();
            const clickPos = (contentSize / scrollViewHeight) * (event.nativeEvent as any).layerY;
            if ((indicator as any).__getValue() > clickPos) {
              // スクロールバーのバーよりも上側の枠をクリックした場合には、少し上にスクロールさせる
              props.listRef.current._listRef._scrollRef.scrollTo(
                (indicator as any).__getValue() - 20
              );
            } else if (
              (indicator as any).__getValue() + indicatorSize * (contentSize / scrollViewHeight) <
              clickPos
            ) {
              // スクロールバーのバーよりも下側の枠をクリックした場合には、少し下にスクロールさせる
              props.listRef.current._listRef._scrollRef.scrollTo(
                (indicator as any).__getValue() + 20
              );
            }
          }}
          style={
            {
              position: 'absolute',
              right: 0,
              top: 0,
              width: 8,
              height: '100%',
              backgroundColor: '#eeeeee',
              zIndex: 1,
              overflow: 'hidden',
              borderRadius: 4,
              cursor: 'default',
              opacity: 0.5,
            } as any
          }>
          <PanGestureHandler
            maxPointers={1}
            onGestureEvent={(event) => {
              if (event.nativeEvent.state === State.BEGAN) {
                setScrollInitialPos(props.listRef.current.getNativeScrollRef().scrollTop);
              }
              if (event.nativeEvent.state === State.ACTIVE) {
                props.listRef.current.getNativeScrollRef().scrollTop =
                  scrollInitialPos +
                  event.nativeEvent.translationY * (contentSize / scrollViewHeight);
              }
              if (event.nativeEvent.state === State.END) {
                props.listRef.current._listRef._scrollRef.scrollTo(
                  scrollInitialPos +
                    event.nativeEvent.translationY * (contentSize / scrollViewHeight)
                );
              }
            }}>
            <NonForwardedRefAnimatedView
              style={[
                { backgroundColor: '#cccccc', borderRadius: 4, opacity: 0.8 },
                {
                  height: indicatorSize,
                  transform: [
                    {
                      translateY: Animated.multiply(
                        indicator,
                        scrollViewHeight / contentSize
                      ).interpolate({
                        inputRange: [0, difference],
                        outputRange: [0, difference],
                        extrapolate: 'clamp',
                      }),
                    },
                  ],
                },
              ]}
            />
          </PanGestureHandler>
        </TouchableOpacity>
      )}
    </>
  );
};

interface IVirtualizedFlatListProps<T> {
  items: T[];
  renderItem: (item: T, index: number) => React.ReactElement<any, any> | null;
  itemHeight: number;
  itemWidth?: number;
  getKey: (item: T, index: number) => string;
  onPress?: (item: T, index: number) => void;
  onDragStart?: (item: T) => void;
  onDropEnd?: () => void;
  onDrop?: (drop: DragInfo<T>) => void;
  onEndReached?: () => void;
  isDraggable?: boolean;
  virticalDraggable?: boolean;
  horizontalDraggable?: boolean;
  listOuterRef?: any;
  style?: StyleProp<ViewStyle>;
  listStyle?: StyleProp<ViewStyle>;
  additionalListStyle?: StyleProp<ViewStyle>;
  headerElement?: React.ReactNode;
  afterElement?: React.ReactNode;
  afterLastItemElement?: React.ReactNode; //TODO こちらはいらなくなったはず。listFooterComponentを使うのが正しい
  listEmptyElment?: React.ReactNode | ((loading: boolean) => React.ReactNode);
  listHeaderComponent?: React.ReactNode;
  listFooterComponent?: React.ReactNode;
  column?: number;
  columnKey?: string;
  allColumns?: T[][];
  listRef?: any;
  listRefs?: Array<IListRefInfo>;
  addListRefs?: (refs: IListRefInfo) => void;
  updateListRefs?: (refs: IListRefInfo) => void;
  scrollEventThrottle?: number;
  showVirticalScrollBar?: boolean;
  showHorizontalScrollBar?: boolean;
  loading?: boolean;
  selectableByKeyboard?: boolean;
}

const VirtualizedFlatList = <T extends any>(props: IVirtualizedFlatListProps<T>) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const containerRef = useRef();
  const listRef = props.listRef || useRef();
  const ghostViewRef = useRef();
  const [draggingItem, setDraggingItem] = useState<IDragInfo<T> | null>(null);
  const [selectedItemIndex, setSelectedItemIndex] = useState<number | null>(null);

  useHotkeys(
    'down',
    () => {
      if (!props.selectableByKeyboard) {
        return;
      }
      setSelectedItemIndex(
        selectedItemIndex !== null ? Math.min(selectedItemIndex + 1, props.items.length - 1) : 0
      );
    },
    [props.selectableByKeyboard, selectedItemIndex, setSelectedItemIndex]
  );
  useHotkeys(
    'tab',
    () => {
      if (!props.selectableByKeyboard) {
        return;
      }
      setSelectedItemIndex(
        selectedItemIndex !== null ? Math.min(selectedItemIndex + 1, props.items.length - 1) : 0
      );
    },
    [props.selectableByKeyboard, selectedItemIndex, setSelectedItemIndex]
  );
  useHotkeys(
    'up',
    () => {
      if (!props.selectableByKeyboard) {
        return;
      }
      setSelectedItemIndex(selectedItemIndex !== null ? Math.max(selectedItemIndex - 1, 0) : 0);
    },
    [props.selectableByKeyboard, selectedItemIndex, setSelectedItemIndex]
  );
  useHotkeys(
    'shift + tab',
    () => {
      if (!props.selectableByKeyboard) {
        return;
      }
      setSelectedItemIndex(selectedItemIndex !== null ? Math.max(selectedItemIndex - 1, 0) : 0);
    },
    [props.selectableByKeyboard, selectedItemIndex, setSelectedItemIndex]
  );

  useEffect(() => {
    if (listRef?.current && props.listRefs && props.addListRefs && props.updateListRefs) {
      const findIndex = (props.listRefs || []).findIndex(
        (ref) => ref.columnKey === props.columnKey
      );
      if (findIndex === -1) {
        props.addListRefs({
          column: props.column || 0,
          columnKey: props.columnKey!,
          listRefs: listRef,
        });
      } else {
        props.updateListRefs({
          column: props.column || 0,
          columnKey: props.columnKey!,
          listRefs: listRef,
        });
      }
    }
  }, [
    listRef,
    props.column,
    props.columnKey,
    props.listRefs,
    props.addListRefs,
    props.updateListRefs,
  ]);

  const setDraggingItemMemo = useCallback((info) => {
    setDraggingItem(info as IDragInfo<T>);
  }, []);

  const onPress = useCallback(
    (item: T, index: number) => {
      if (props.onPress) {
        props.onPress(item, index);
        setSelectedItemIndex(index);
      }
    },
    [props.onPress, setSelectedItemIndex]
  );

  const onDragStart = useCallback(
    (item: T) => {
      if (props.onDragStart) {
        props.onDragStart(item);
        if (props.listOuterRef) {
          props.listOuterRef.current.style.zIndex = 3;
        }
      }
    },
    [props.onDragStart, ghostViewRef, props.listOuterRef]
  );

  const onDropEnd = useCallback(() => {
    if (props.onDropEnd) {
      props.onDropEnd();
      if (props.listOuterRef) {
        props.listOuterRef.current.style.zIndex = 1;
      }
    }
  }, [props.onDropEnd, ghostViewRef, props.listOuterRef]);

  const columnCountMap: ColumnCount[] = (props.allColumns || [props.items]).map((column, i) => {
    return {
      columnIndex: i,
      count: column.length,
    };
  });

  const renderItem = useCallback(
    ({ item, index }) => {
      return (
        <RenderItemWrapper
          item={item}
          index={index}
          allItemCount={props.items.length}
          column={props.column || 0}
          columnCountMap={columnCountMap}
          renderItem={props.renderItem as any}
          height={props.itemHeight}
          width={props.itemWidth || 100} //TODO あとで直す
          containerRef={containerRef}
          onPress={props.onPress ? (onPress as any) : undefined}
          onDrop={props.onDrop as any}
          onDraggingItem={setDraggingItemMemo}
          onDragStart={onDragStart}
          onDropEnd={onDropEnd}
          isDraggable={props.isDraggable === false ? false : true}
          virticalDraggable={props.virticalDraggable || false}
          horizontalDraggable={props.horizontalDraggable || false}
          ghostViewRef={ghostViewRef}
          listRefs={props.listRefs}
          afterLastItemElement={props.afterLastItemElement}
          selectedItemIndex={selectedItemIndex}
          setSelectedItemIndex={setSelectedItemIndex}
        />
      );
    },
    [
      columnCountMap,
      containerRef,
      ghostViewRef,
      props.items.length,
      props.column,
      props.renderItem,
      props.itemHeight,
      props.itemWidth,
      onPress,
      props.onDrop,
      setDraggingItemMemo,
      props.onDragStart,
      props.onDropEnd,
      props.isDraggable,
      props.virticalDraggable,
      props.horizontalDraggable,
      props.listRefs,
      props.afterLastItemElement,
      selectedItemIndex,
      setSelectedItemIndex,
    ]
  );

  const keyExtractor = useCallback(
    (item, index) => props.getKey(item as T, index),
    [props.getKey, props.items]
  );

  return (
    <>
      {props.loading && (
        <View>
          <ActivityIndicator
            size={'large'}
            color={themeContext.colors.primary}
            style={{
              position: 'absolute',
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              zIndex: 2,
            }}
          />
        </View>
      )}
      <View
        ref={ghostViewRef as any}
        style={
          {
            display: draggingItem ? 'block' : 'none',
            height: 0,
            top: draggingItem
              ? draggingItem?.index * props.itemHeight -
                listRef?.current?._listRef?._scrollMetrics?.offset
              : 0,
            overflow: 'display',
            opacity: 0.8,
            zIndex: 3,
            cursor: 'grabbing',
          } as any
        }>
        {draggingItem && (
          <RenderItemMain item={draggingItem.item} index={2} renderItem={props.renderItem as any} />
        )}
      </View>
      <Container style={props.style as any} ref={containerRef as any}>
        {props.headerElement}
        {Platform.OS === 'web' ? (
          <CustomScrollFlatList
            listRef={listRef as any}
            style={[{ width: (props.listStyle as any)?.width }, props.additionalListStyle]}
            contentContainerStyle={[
              { height: props.itemHeight * props.items.length },
              props.listStyle as any,
            ]}
            data={props.items}
            renderItem={renderItem}
            keyExtractor={keyExtractor}
            windowSize={2}
            ListEmptyComponent={
              props.listEmptyElment ? (
                typeof props.listEmptyElment === 'function' ? (
                  props.listEmptyElment!(props.loading ?? false)
                ) : (
                  <>{props.listEmptyElment}</>
                )
              ) : (
                <></>
              )
            }
            ListHeaderComponent={<>{props.listHeaderComponent}</>}
            ListFooterComponent={<>{props.listFooterComponent}</>}
            scrollEventThrottle={props.scrollEventThrottle}
            showsVerticalScrollIndicator={props.showVirticalScrollBar === false ? false : true}
            showsHorizontalScrollIndicator={props.showHorizontalScrollBar === false ? false : true}
            onEndReached={props.onEndReached}
            onEndReachedThreshold={0.1}
          />
        ) : (
          <FlatList
            ref={listRef as any}
            style={{ width: (props.listStyle as any)?.width }}
            contentContainerStyle={[
              { height: props.itemHeight * props.items.length },
              props.listStyle as any,
            ]}
            data={props.items}
            renderItem={renderItem}
            keyExtractor={keyExtractor}
            windowSize={2}
            ListEmptyComponent={<>{props.listEmptyElment}</>}
            scrollEventThrottle={props.scrollEventThrottle}
            showsVerticalScrollIndicator={props.showVirticalScrollBar === false ? false : true}
            showsHorizontalScrollIndicator={props.showHorizontalScrollBar === false ? false : true}
            onEndReached={props.onEndReached}
            onEndReachedThreshold={0.1}
          />
        )}
        {props.afterElement && props.afterElement}
      </Container>
    </>
  );
};

export default React.memo(VirtualizedFlatList); //memo化しておかないと、ボード画面等でタスク追加する際に、関係ないListも再描画されてしまう

interface GlobalDragInfo {
  startIndex: number;
  endIndex: number;
  startColumn: number;
  endColumn: number;
  dragging: boolean;
}

export const GlobalDragContext = createContext([
  { startIndex: 0, endIndex: 0, startColumn: 0, endColumn: 0, dragging: false },
  (value: GlobalDragInfo) => {},
]);

export const GlobalDragContextProvider = (props: any) => {
  const [draggingPosIndex, setDraggingPosIndex] = useState<GlobalDragInfo>({
    startIndex: 0,
    endIndex: 0,
    startColumn: 0,
    endColumn: 0,
    dragging: false,
  });
  return (
    <GlobalDragContext.Provider value={[draggingPosIndex as any, setDraggingPosIndex]}>
      {props.children}
    </GlobalDragContext.Provider>
  );
};

interface GlobalDragColumnInfo {
  startColumn: number;
  endColumn: number;
  dragging: boolean;
}

export const GlobalDragColumnContext = createContext([
  { startColumn: 0, endColumn: 0, dragging: false },
  (value: GlobalDragColumnInfo) => {},
]);

export const GlobalDragColumnContextProvider = (props: any) => {
  const [draggingPosIndex, setDraggingPosIndex] = useState<GlobalDragColumnInfo>({
    startColumn: 0,
    endColumn: 0,
    dragging: false,
  });
  return (
    <GlobalDragColumnContext.Provider value={[draggingPosIndex as any, setDraggingPosIndex]}>
      {props.children}
    </GlobalDragColumnContext.Provider>
  );
};
