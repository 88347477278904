import TimeUtil from './TimeUtil';

export default class DownloadUtil {
  private constructor() {}

  public static forceDownload(url: string, fileName: string): void {
    if (url) {
      let xhr = new XMLHttpRequest();
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.onload = async () => {
        if (xhr.status !== 200) {
          return false;
        }
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(xhr.response);
        link.download = fileName;
        link.click();
        await TimeUtil.sleep(100);
        link.remove();
      };
      xhr.send();
    }
  }
}
