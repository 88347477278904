import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import useDimensions from 'use-dimensions';
import { ProjectTemplateTask, useProjectTemplateTaskQuery } from '../../../../../graphql/api/API';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import { IStyleTheme, IThemePart } from '../../../../theme';
import TaskDetailCommon from './common';
import TaskDetailCommonLoading from './common-loading';
import TaskDetailLoading from './loading';
import TaskDetailStandardInfo from './standard-info';
import ProjectTemplateTaskRemainderView from './remainder';
import CustomScrollView from '../../../../presentational/atoms/custom-scroll-view';
import queryString from 'query-string';

interface IContainerProps extends IStyleTheme {
  isOpen: boolean;
  windowWidth: number;
  isOpenRightSideBar: boolean;
}

const Container = styled.View<IContainerProps>`
  height: calc(100vh - 57px);
  right: ${(props: IContainerProps) =>
    props.windowWidth < 1260 ? '-490px' : `-${Math.max(props.windowWidth / 2.2, 540) + 10}px`};
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.4s;
  width: ${(props: IContainerProps) =>
    props.isOpen
      ? `${Math.max((props.windowWidth - (props.isOpenRightSideBar ? 500 : 0)) / 2.2, 540)}px`
      : '1px'}; // Note: 閉じている時に幅を 1px ではなく、0pxにすると、なぜかSafariは幅を300pxぐらい取る挙動になってしまう。それによって画面右側300pxぐらいに透明な状態のエリアがかぶさってしまい、画面操作を阻害してしまう。Safari対応のために幅を1pxに指定するようにしている。
  transform: ${(props: IContainerProps) =>
    `translateX(${props.isOpen ? `-${Math.max(props.windowWidth / 2.2, 540)}px` : '0'})`};
  background-color: ${(props: IContainerProps) => props.theme.colors.baseColor};
  overflow: hidden;
`;

interface IContainerInnerProps extends IStyleTheme {
  isShow: boolean;
}

const ContainerInner = styled.View<IContainerInnerProps>``;

interface ITaskDetailInnerProps {
  task: ProjectTemplateTask | null;
  loading: boolean;
  isOpenRightSideBar: boolean;
}

const TaskDetailInner = (props: ITaskDetailInnerProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const { pathname } = useLocation();
  const [loginUser, _] = useContext(LoginUserContext);
  const dimensions = useDimensions();
  const windowWidth = dimensions.window.width;
  const ref = useRef();
  const [isDragOver, setDragOver] = useState(false);

  if (props.loading) {
    return (
      <Container
        isOpen={true}
        windowWidth={windowWidth}
        isOpenRightSideBar={props.isOpenRightSideBar}>
        <CustomScrollView scrollViewRef={ref} style={{ height: 'calc(100vh - 57px)' }}>
          <ContainerInner isShow={true}>
            <TaskDetailCommonLoading>
              <TaskDetailLoading />
            </TaskDetailCommonLoading>
          </ContainerInner>
        </CustomScrollView>
      </Container>
    );
  }
  if (props.task) {
    if (/.*\/task-template\/(.+)\/detail\//.test(pathname)) {
      return (
        <Container
          isOpen={true}
          windowWidth={windowWidth}
          isOpenRightSideBar={props.isOpenRightSideBar}>
          <div
            onDragOver={(e) => {
              e.stopPropagation();
              e.preventDefault();
              if (!isDragOver) {
                setDragOver(true);
              }
            }}>
            <CustomScrollView scrollViewRef={ref} style={{ height: 'calc(100vh - 57px)' }}>
              <ContainerInner isShow={true}>
                <TaskDetailCommon organizationId={loginUser?.organizationId!} task={props.task}>
                  <TaskDetailStandardInfo
                    organizationId={loginUser?.organizationId!}
                    task={props.task}
                    parentScrollViewRef={ref}
                  />
                </TaskDetailCommon>
              </ContainerInner>
            </CustomScrollView>
          </div>
        </Container>
      );
    }
    if (/.*\/task-template\/(.+)\/remainder\//.test(pathname)) {
      return (
        <Container
          isOpen={true}
          windowWidth={windowWidth}
          isOpenRightSideBar={props.isOpenRightSideBar}>
          <CustomScrollView scrollViewRef={ref} style={{ height: 'calc(100vh - 57px)' }}>
            <ContainerInner isShow={true}>
              <TaskDetailCommon organizationId={loginUser?.organizationId!} task={props.task}>
                <ProjectTemplateTaskRemainderView task={props.task} />
              </TaskDetailCommon>
            </ContainerInner>
          </CustomScrollView>
        </Container>
      );
    }
  }
  return (
    <Container
      isOpen={false}
      windowWidth={windowWidth}
      isOpenRightSideBar={props.isOpenRightSideBar}
    />
  );
};

interface ITaskDetailWithFetchDataProps {
  taskId: string | null;
  isOpenRightSideBar: boolean;
}

const TaskDetailWithFetchData = (props: ITaskDetailWithFetchDataProps) => {
  const { loading, data, error } = useProjectTemplateTaskQuery({
    variables: {
      id: props.taskId,
    },
    skip: props.taskId === null,
  });

  return (
    <TaskDetailInner
      loading={loading}
      task={(data?.projectTemplateTask as ProjectTemplateTask) || null}
      isOpenRightSideBar={props.isOpenRightSideBar}
    />
  );
};

const TaskDetail = () => {
  const { pathname, search } = useLocation();
  const taskMatches = pathname.match(/.*\/task-template\/(.+)\/[^\/]+/);
  let taskId: string | null = null;
  if (taskMatches && taskMatches.length > 1) {
    taskId = taskMatches[1].split('/')[0];
  }

  const isOpenRightSideBar = !!queryString.parse(search).right;
  return <TaskDetailWithFetchData taskId={taskId} isOpenRightSideBar={isOpenRightSideBar} />;
};

export default TaskDetail;
