import React, { useContext, useState } from 'react';
import { View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import {
  TwoFactorAuthSettingDocument,
  useDeactivateTwoFactorAuthMutation,
  useRegenerateRecoveryCodesMutation,
  useTwoFactorAuthSettingQuery,
} from '../../../../../../../graphql/api/API';
import Button from '../../../../../../presentational/atoms/button';
import Typography, { TypographyType } from '../../../../../../presentational/atoms/typography';
import Modal from '../../../../../../presentational/molecules/modal';
import { IThemePart } from '../../../../../../theme';
import SettingsElement from '../../../../organisms/settings-element';
import TwoFactorAuthSetting from '../../../../organisms/two-factor-auth-setting';

const Container = styled.View`
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
`;

interface TwoFactorAuthDeactivateModalProps {
  closeModal: () => void;
}

const TwoFactorAuthDeactivateModal = (props: TwoFactorAuthDeactivateModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [deactivateTwoFactorAuth] = useDeactivateTwoFactorAuthMutation();

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <Typography variant={TypographyType.Normal} style={{ paddingVertical: 16 }}>
        2要素認証の設定を解除します。{'\n'}この設定は、あとでもう一度設定し直すことも可能です。
        {'\n'}
        解除しますか？
      </Typography>
      <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1 }}>
        <Button
          text="2要素認証を解除する"
          style={{
            minWidth: 100,
            marginRight: 10,
          }}
          onPress={async () => {
            await deactivateTwoFactorAuth({
              refetchQueries: [
                {
                  query: TwoFactorAuthSettingDocument,
                },
              ],
            });
            props.closeModal();
          }}
        />
        <Button
          text={'キャンセル'}
          style={{
            minWidth: 100,
            marginRight: 10,
            backgroundColor: 'transparent',
          }}
          textStyle={{ color: themeContext.colors.primary }}
          disableValidate={true}
          onPress={async () => {
            props.closeModal();
          }}
        />
      </View>
    </View>
  );
};

const AppTworFactorAuthSettingPage = () => {
  const twoFactorAuthSetting = useTwoFactorAuthSettingQuery();
  const themeContext: IThemePart = useContext(ThemeContext);
  const [showBackupCodes, setShowBackupCodes] = useState(false);
  const [showUpdateBackupCodesModal, setShowUpdateBackupCodesModal] = useState(false);
  const [regenerateRecoveryCodes] = useRegenerateRecoveryCodesMutation();

  const download = () => {
    const newBlob = new Blob(
      [twoFactorAuthSetting.data!.twoFactorAuthSetting!.recoveryCodes!.join('\n')],
      { type: 'text/plain' }
    );
    const objUrl = window.URL.createObjectURL(newBlob);
    const link = document.createElement('a');
    link.href = objUrl;
    link.download = 'timedesigner_backup_code.txt';
    link.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(objUrl);
    }, 250);
  };

  if (twoFactorAuthSetting.loading) {
    return <></>;
  }

  return (
    <Container>
      <SettingsElement
        title={`2要素認証設定`}
        changeText={
          twoFactorAuthSetting.data?.twoFactorAuthSetting?.enabled
            ? '2要素認証設定を解除する'
            : '2要素認証を設定する'
        }
        modal={(closeModal) =>
          twoFactorAuthSetting.data?.twoFactorAuthSetting?.enabled ? (
            <TwoFactorAuthDeactivateModal closeModal={closeModal} />
          ) : (
            <TwoFactorAuthSetting onSuccess={closeModal} onCancel={closeModal} />
          )
        }>
        {twoFactorAuthSetting.data?.twoFactorAuthSetting?.enabled ? '設定済' : '未設定'}
      </SettingsElement>
      {twoFactorAuthSetting.data?.twoFactorAuthSetting?.enabled && (
        <SettingsElement title="バックアップコード">
          {!showBackupCodes ? (
            <TouchableOpacity onPress={() => setShowBackupCodes(true)}>
              <Typography
                variant={TypographyType.Normal}
                style={{
                  color: themeContext.colors.link,
                }}>
                バックアップコードを表示する
              </Typography>
            </TouchableOpacity>
          ) : (
            <TouchableOpacity onPress={() => setShowBackupCodes(false)}>
              <Typography
                variant={TypographyType.Normal}
                style={{
                  color: themeContext.colors.link,
                }}>
                バックアップコードを非表示にする
              </Typography>
            </TouchableOpacity>
          )}
          <Typography variant={TypographyType.Description}>
            2要素認証を設定している状態で、スマートフォンを紛失したり、{'\n'}
            認証アプリでコードを表示できなくなってしまった場合に、{'\n'}
            バックアップコードを使用してログインをすることが出来ます。{'\n'}
          </Typography>
          {showBackupCodes && (
            <>
              <View
                style={{
                  borderColor: themeContext.colors.separator,
                  borderWidth: 1,
                  borderRadius: 10,
                  padding: 12,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  width: 600,
                  marginVertical: 12,
                }}>
                <View>
                  {twoFactorAuthSetting.data?.twoFactorAuthSetting?.recoveryCodes
                    ?.slice(
                      0,
                      twoFactorAuthSetting.data?.twoFactorAuthSetting?.recoveryCodes?.length / 2
                    )
                    .map((code) => (
                      <Typography variant={TypographyType.Normal} style={{ paddingVertical: 8 }}>
                        {code}
                      </Typography>
                    ))}
                </View>
                <View>
                  {twoFactorAuthSetting.data?.twoFactorAuthSetting?.recoveryCodes
                    ?.slice(
                      twoFactorAuthSetting.data?.twoFactorAuthSetting?.recoveryCodes?.length / 2,
                      twoFactorAuthSetting.data?.twoFactorAuthSetting?.recoveryCodes?.length
                    )
                    .map((code) => (
                      <Typography variant={TypographyType.Normal} style={{ paddingVertical: 8 }}>
                        {code}
                      </Typography>
                    ))}
                </View>
              </View>
              <TouchableOpacity onPress={download}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    color: themeContext.colors.link,
                  }}>
                  バックアップコードをダウンロードする
                </Typography>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => setShowUpdateBackupCodesModal(true)}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{
                    color: themeContext.colors.link,
                  }}>
                  バックアップコードを更新する
                </Typography>
              </TouchableOpacity>
            </>
          )}
        </SettingsElement>
      )}
      <Modal
        title="バックアップコードを更新する"
        isShow={showUpdateBackupCodesModal}
        onClose={() => setShowUpdateBackupCodesModal(false)}>
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <Typography variant={TypographyType.Normal} style={{ paddingVertical: 16 }}>
            バックアップコードを更新します。
            {'\n'}
            更新すると、現在のコードは使用できなくなります。{'\n'}
            更新しますか？
          </Typography>
          <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1 }}>
            <Button
              text="更新する"
              style={{
                minWidth: 100,
                marginRight: 10,
              }}
              onPress={async () => {
                await regenerateRecoveryCodes({
                  refetchQueries: [
                    {
                      query: TwoFactorAuthSettingDocument,
                    },
                  ],
                });
                setShowUpdateBackupCodesModal(false);
              }}
            />
            <Button
              text={'キャンセル'}
              style={{
                minWidth: 100,
                marginRight: 10,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.primary }}
              disableValidate={true}
              onPress={async () => {
                setShowUpdateBackupCodesModal(false);
              }}
            />
          </View>
        </View>
      </Modal>
    </Container>
  );
};

export default AppTworFactorAuthSettingPage;
