import React, { useCallback, useContext, useMemo, useState } from 'react';
import { View, Image } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../../theme';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import Icon from '../../../../presentational/atoms/icon';
import { useHistory } from 'react-router';
import {
  Announcement,
  UnreadAnnouncementCountDocument,
  useAnnouncementsQuery,
  useReadAllAnnouncementMutation,
  useUnreadAnnouncementCountLazyQuery,
  useUnreadNotificationCountLazyQuery,
} from '../../../../../graphql/api/API';
import VirtualizedFlatList from '../../../../presentational/atoms/list2/virtualized-flat-list';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import moment from 'moment-timezone';
import Spinner from '../../../../presentational/atoms/spinner';
import noData from '../../../../../base64Images/no-data/no-data-3';
import EmailOpenIcon from '../../../../presentational/molecules/image-icon/email-open';
import EmailAlertIcon from '../../../../presentational/molecules/image-icon/email-alert';
import useInterval from 'use-interval';
import Button from '../../../../presentational/atoms/button';
import Modal from '../../../../presentational/molecules/modal';
import Form from '../../../../presentational/atoms/form';
import TimeUtil from '../../../../../util/TimeUtil';

const TaskListWrapper = styled.View`
  padding: 10px;
  height: calc(100vh - 57px - 20px);
`;

const MessageContainer = styled.View`
  display: flex;
  flex-direction: row;
  height: 50px;
  padding: 0 20px;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 6px;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.4s;
  z-index: 100;
  border-width: 1px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
`;

interface IMessageItemProps {
  message: Announcement;
}

const MessageItem = (props: IMessageItemProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  return (
    <MessageContainer>
      <View>
        {props.message.readed ? <EmailOpenIcon size={20} /> : <EmailAlertIcon size={20} />}
      </View>
      <View style={{}}>
        <Typography
          variant={TypographyType.Normal}
          style={{ fontSize: 13, lineHeight: 15, marginLeft: 10 }}>
          {props.message.title}
        </Typography>
        <Typography
          variant={TypographyType.Description}
          style={{ fontSize: 11, lineHeight: 13, marginTop: 5, marginLeft: 10 }}>
          {moment(props.message.announceDateTime).format('YYYY/MM/DD HH:mm')}
        </Typography>
      </View>
    </MessageContainer>
  );
};

interface IMessageListProps {
  filter: Array<'read' | 'unread'>;
}

const MessageList = React.memo((props: IMessageListProps) => {
  const pageSize = 50;
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, _] = useContext(LoginUserContext);
  const history = useHistory();
  const [showReadAllModal, setShowReadAllModal] = useState(false);
  const { loading, data, fetchMore } = useAnnouncementsQuery({
    variables: {
      offset: 0,
      limit: pageSize,
    },
    fetchPolicy: 'network-only',
  });
  const [readAllAnnouncements] = useReadAllAnnouncementMutation({
    refetchQueries: [
      {
        query: UnreadAnnouncementCountDocument,
      },
    ],
  });

  const messages = useMemo(() => {
    if (loading || !data?.announcements) {
      return [];
    }
    return data!
      .announcements!.slice()
      .filter((message) => {
        let result = false;
        if (props.filter.includes('read')) {
          result = result || message!.readed;
        }
        if (props.filter.includes('unread')) {
          result = result || !message!.readed;
        }
        return result;
      })
      .sort(
        (a, b) => new Date(b!.announceDateTime).getTime() - new Date(a!.announceDateTime).getTime()
      );
  }, [loading, data?.announcements, props.filter]);

  if (loading) {
    return <></>;
  }

  return (
    <TaskListWrapper>
      <Spinner loading={loading} />
      <View style={{ alignSelf: 'flex-start', height: 40, padding: 5 }}>
        <Button
          text="全て既読にする"
          textStyle={{ fontSize: 12 }}
          style={{ height: 15 }}
          leftInnerElement={
            <EmailOpenIcon
              size={17}
              reverse={true}
              containerStyle={{ marginRight: 3, marginTop: 2 }}
            />
          }
          onPress={() => {
            setShowReadAllModal(true);
          }}
        />
        <Modal
          title={`全て既読に更新してよろしいですか？`}
          isShow={showReadAllModal}
          onClose={() => {
            setShowReadAllModal(false);
          }}>
          <Form>
            <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
              <Button
                text={'既読にする'}
                style={{
                  minWidth: 100,
                  marginRight: 10,
                  marginVertical: 10,
                  borderColor: themeContext.colors.error,
                  borderRadius: 3,
                  borderWidth: 1,
                  backgroundColor: 'transparent',
                }}
                textStyle={{ color: themeContext.colors.error }}
                onPress={async () => {
                  await readAllAnnouncements();
                  setShowReadAllModal(false);
                }}
              />
              <Button
                text={'キャンセル'}
                style={{
                  minWidth: 100,
                  marginRight: 10,
                  marginVertical: 10,
                  backgroundColor: 'transparent',
                }}
                textStyle={{ color: themeContext.colors.primary }}
                disableValidate={true}
                onPress={() => {
                  setShowReadAllModal(false);
                }}
              />
            </View>
          </Form>
        </Modal>
      </View>
      <VirtualizedFlatList
        items={messages}
        style={{
          height: 'calc(100vh - 145px - 40px)',
        }}
        renderItem={(item, index) => {
          return <MessageItem message={item as Announcement} />;
        }}
        getKey={(message) => (message as any).id}
        itemHeight={50}
        onPress={(message) => {
          history.push(
            `/app/${loginUser!.organizationId}/messages/announce/${
              (message as Announcement).id
            }/detail/`
          );
        }}
        listEmptyElment={
          <View
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              height: 'calc(100vh - 57px - 40px)',
            }}>
            <Image
              source={{ uri: noData }}
              resizeMode="contain"
              style={{
                width: 350,
                height: 200,
                opacity: 0.7,
              }}
            />
            <Typography
              variant={TypographyType.Normal}
              style={{ color: themeContext.colors.description, textAlign: 'center' }}>
              {`お知らせはありません`}
            </Typography>
          </View>
        }
        onEndReached={() => {
          if ((data?.announcements?.length ?? 0) < pageSize) {
            return;
          }
          fetchMore({
            variables: {
              offset: data!.announcements!.length,
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prev;
              return Object.assign({}, prev, {
                announcements: [
                  ...(prev.announcements || []),
                  ...(fetchMoreResult.announcements || []),
                ],
              });
            },
          });
        }}
      />
    </TaskListWrapper>
  );
});

export default MessageList;
