import React from 'react';
import ListMenuApp from '../../../organisms/list-menu-app';
import OrganizationSettingsInfoPage from './organization-info';
import OrganizationContractPage from './contract';
import CustomScrollView from '../../../../../presentational/atoms/custom-scroll-view';
import OrganizationInvoicePage from './invoice';
import AccessControlSettingsPage from './access-control';
import ThirdPartyAccountConnectSettingsPage from './third-party-account-connect';
import OrganizationTwoFactorAuthSettingPage from './two-factor-auth';
import ClosingSettingsPage from './closing';

const AppOrganizationSettingsPage = () => {
  return (
    <CustomScrollView
      style={{
        height: 'calc(100vh - 57px)',
      }}>
      <ListMenuApp
        headerTitle={'組織の各種設定'}
        routes={[
          {
            name: '組織情報',
            path: `/app/:organizationId/settings/info/`,
            component: OrganizationSettingsInfoPage,
          },
          {
            name: 'ご契約内容',
            path: `/app/:organizationId/settings/contract/`,
            component: OrganizationContractPage,
          },
          {
            name: '締め処理',
            path: `/app/:organizationId/settings/closing/`,
            component: ClosingSettingsPage,
          },
          {
            name: 'IPアドレス制限',
            path: `/app/:organizationId/settings/acl/`,
            component: AccessControlSettingsPage,
          },
          {
            // リリースを二段階にわけるため一時的にコメントアウト
            name: '2要素認証の必須設定',
            path: `/app/:organizationId/settings/two-factor-auth/`,
            component: OrganizationTwoFactorAuthSettingPage,
          },
          {
            name: '外部アカウント連携設定',
            path: `/app/:organizationId/settings/third-party-account-connect/`,
            component: ThirdPartyAccountConnectSettingsPage,
          },
          {
            name: '請求履歴',
            path: `/app/:organizationId/settings/invoice/`,
            component: OrganizationInvoicePage,
          },
        ]}
      />
    </CustomScrollView>
  );
};

export default React.memo(AppOrganizationSettingsPage);
