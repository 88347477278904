import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import {
  BoardTasksQuery,
  BoardTasksQueryVariables,
  FavoriteTasksQuery,
  FavoriteTasksQueryVariables,
} from './API';

const defaultOptions = {};

export const CustomBoardTasksDocument = gql`
  query customBoardTasks($limit: Int, $offset: Int, $projectId: String, $taskStatusId: String) {
    boardTasks(limit: $limit, offset: $offset, projectId: $projectId, taskStatusId: $taskStatusId) {
      taskStatus {
        endStatus
        id
        name
        project {
          assignees {
            member {
              adminRole
              clientManagementRole
              department
              employeeNumber
              id
              laborCostManagementRole
              mailAddress
              memberManagementRole
              memberRegistrationDateTime
              memberStatus
              name
              organizationMemberViewRole
              organizationReportRole
              profileImageUrl
              projectDeletePermissionFlg
              salesManagementRole
              statusMessage
              taskDeletePermissionFlg
              teamMemberViewRole
              teamReportRole
              versionNo
            }
            sortNo
          }
          calendarLinkageCode
          client {
            id
            name
            sortNo
            versionNo
          }
          color
          complete
          completeDateTime
          createdMember {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectDeletePermissionFlg
            salesManagementRole
            statusMessage
            taskDeletePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          description
          favoriteMembers {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectDeletePermissionFlg
            salesManagementRole
            statusMessage
            taskDeletePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          favoriteMembersInfo {
            member {
              adminRole
              clientManagementRole
              department
              employeeNumber
              id
              laborCostManagementRole
              mailAddress
              memberManagementRole
              memberRegistrationDateTime
              memberStatus
              name
              organizationMemberViewRole
              organizationReportRole
              profileImageUrl
              projectDeletePermissionFlg
              salesManagementRole
              statusMessage
              taskDeletePermissionFlg
              teamMemberViewRole
              teamReportRole
              versionNo
            }
            sortNo
          }
          id
          key
          name
          priority
          registerDateTime
          sortNoInGanttChart
          sortNoInList
          team {
            archived
            id
            name
            organization {
              allowConnectThirdPartyCalendar
              contactMailAddress
              contractPreriod
              id
              imageUrl
              ipRestrictionFlg
              name
              plan {
                code
                contractPreriod
                id
              }
              requiredTwoFactorAuth
              suspensionFlg
              versionNo
            }
            personalTeam
            status
            versionNo
          }
          versionNo
        }
        sortNo
        versionNo
      }
      tasks {
        assignees {
          sortNo
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectDeletePermissionFlg
            salesManagementRole
            statusMessage
            taskDeletePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
        }
        asssignee {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectDeletePermissionFlg
          salesManagementRole
          statusMessage
          taskDeletePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        calendarLinkageCode
        completeDateTime
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectDeletePermissionFlg
          salesManagementRole
          statusMessage
          taskDeletePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        estimatedRemainingTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectDeletePermissionFlg
          salesManagementRole
          statusMessage
          taskDeletePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          sortNo
        }
        id
        priority
        progressDeviationRate
        progressRate
        project {
          calendarLinkageCode
          color
          complete
          completeDateTime
          description
          id
          key
          name
          priority
          registerDateTime
          sortNoInGanttChart
          sortNoInList
          versionNo
        }
        registerDateTime
        remainingTimeSec
        scheduleWorkingTimeSec
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        sortNoInMyTask
        sortNoInTaskStatus
        taskStatus {
          endStatus
          id
          name
          sortNo
          versionNo
        }
        title
        versionNo
        workingMembers {
          lastWorkingStartDateTime
          lastWorkingStopDateTime
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectDeletePermissionFlg
            salesManagementRole
            statusMessage
            taskDeletePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
        }
        workingTimeSec
      }
    }
  }
`;

/**
 * __useBoardTasksQuery__
 *
 * To run a query within a React component, call `useBoardTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useBoardTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBoardTasksQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      projectId: // value for 'projectId'
 *      taskStatusId: // value for 'taskStatusId'
 *   },
 * });
 */
export function useCustomBoardTasksQuery(
  baseOptions?: Apollo.QueryHookOptions<BoardTasksQuery, BoardTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BoardTasksQuery, BoardTasksQueryVariables>(
    CustomBoardTasksDocument,
    options
  );
}
export function useCustomBoardTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BoardTasksQuery, BoardTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BoardTasksQuery, BoardTasksQueryVariables>(
    CustomBoardTasksDocument,
    options
  );
}
export type BoardTasksQueryHookResult = ReturnType<typeof useCustomBoardTasksQuery>;
export type BoardTasksLazyQueryHookResult = ReturnType<typeof useCustomBoardTasksLazyQuery>;
export type BoardTasksQueryResult = Apollo.QueryResult<BoardTasksQuery, BoardTasksQueryVariables>;
export const CalendarEventDocument = gql`
  query calendarEvent($id: String!) {
    calendarEvent(id: $id) {
      calendarType
      clientId
      clientName
      endDateTime
      eventName
      id
      projectColor
      projectId
      projectName
      startDateTime
      taskId
      taskTitle
      thirdPartyCalendarEventId
      thirdPartyCalendarInfoList {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectDeletePermissionFlg
          salesManagementRole
          statusMessage
          taskDeletePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        thirdPartyCalendarId
      }
      versionNo
      workingTimeSec
    }
  }
`;

export const CustomFavoriteTasksDocument = gql`
  query favoriteTasks {
    favoriteTasks {
      assignees {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      asssignee {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      calendarLinkageCode
      completeDateTime
      createdMember {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      description
      estimateTimeSec
      estimatedRemainingTimeSec
      favoriteMembers {
        adminRole
        clientManagementRole
        department
        employeeNumber
        id
        laborCostManagementRole
        mailAddress
        memberManagementRole
        memberRegistrationDateTime
        memberStatus
        name
        organizationMemberViewRole
        organizationReportRole
        profileImageUrl
        projectCreatePermissionFlg
        projectCustomAttributePermissionFlg
        projectDeletePermissionFlg
        projectUpdatePermissionFlg
        salesManagementRole
        statusMessage
        taskCreatePermissionFlg
        taskCustomAttributePermissionFlg
        taskDeletePermissionFlg
        taskUpdatePermissionFlg
        teamMemberViewRole
        teamReportRole
        versionNo
      }
      favoriteMembersInfo {
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        sortNo
      }
      id
      priority
      progressDeviationRate
      progressRate
      project {
        assignees {
          member {
            adminRole
            clientManagementRole
            department
            employeeNumber
            id
            laborCostManagementRole
            mailAddress
            memberManagementRole
            memberRegistrationDateTime
            memberStatus
            name
            organizationMemberViewRole
            organizationReportRole
            profileImageUrl
            projectCreatePermissionFlg
            projectCustomAttributePermissionFlg
            projectDeletePermissionFlg
            projectUpdatePermissionFlg
            salesManagementRole
            statusMessage
            taskCreatePermissionFlg
            taskCustomAttributePermissionFlg
            taskDeletePermissionFlg
            taskUpdatePermissionFlg
            teamMemberViewRole
            teamReportRole
            versionNo
          }
          sortNo
        }
        calendarLinkageCode
        client {
          code
          id
          name
          sortNo
          versionNo
        }
        color
        complete
        completeDateTime
        createdMember {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        description
        estimateTimeSec
        favoriteMembers {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
        favoriteMembersInfo {
          sortNo
        }
        id
        key
        name
        priority
        registerDateTime
        scheduledEndDateTime
        scheduledStartDateTime
        sortNoInGanttChart
        sortNoInList
        team {
          archived
          id
          name
          organization {
            allowConnectThirdPartyCalendar
            contactMailAddress
            contractPreriod
            id
            imageUrl
            ipRestrictionFlg
            name
            plan {
              code
              contractPreriod
              id
            }
            requiredTwoFactorAuth
            suspensionFlg
            versionNo
          }
          personalTeam
          status
          versionNo
        }
        versionNo
      }
      registerDateTime
      remainingTimeSec
      scheduleWorkingTimeSec
      scheduledEndDateTime
      scheduledStartDateTime
      sortNoInGanttChart
      sortNoInList
      sortNoInMyTask
      sortNoInTaskStatus
      taskStatus {
        endStatus
        id
        name
        project {
          calendarLinkageCode
          color
          complete
          completeDateTime
          description
          estimateTimeSec
          id
          key
          name
          priority
          registerDateTime
          scheduledEndDateTime
          scheduledStartDateTime
          sortNoInGanttChart
          sortNoInList
          versionNo
        }
        sortNo
        versionNo
      }
      title
      versionNo
      workingMembers {
        lastWorkingStartDateTime
        lastWorkingStopDateTime
        member {
          adminRole
          clientManagementRole
          department
          employeeNumber
          id
          laborCostManagementRole
          mailAddress
          memberManagementRole
          memberRegistrationDateTime
          memberStatus
          name
          organizationMemberViewRole
          organizationReportRole
          profileImageUrl
          projectCreatePermissionFlg
          projectCustomAttributePermissionFlg
          projectDeletePermissionFlg
          projectUpdatePermissionFlg
          salesManagementRole
          statusMessage
          taskCreatePermissionFlg
          taskCustomAttributePermissionFlg
          taskDeletePermissionFlg
          taskUpdatePermissionFlg
          teamMemberViewRole
          teamReportRole
          versionNo
        }
      }
      workingTimeSec
    }
  }
`;

export function useCustomFavoriteTasksQuery(
  baseOptions?: Apollo.QueryHookOptions<FavoriteTasksQuery, FavoriteTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FavoriteTasksQuery, FavoriteTasksQueryVariables>(
    CustomFavoriteTasksDocument,
    options
  );
}
