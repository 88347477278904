import React, { useContext, useEffect, useState } from 'react';
import styled, { ThemeContext } from 'styled-components/native';
import {
  AccessControlSettings,
  IsIpAddressAccessAllowedWithAllowdIpAddressListDocument,
  IsIpAddressAccessAllowedWithAllowdIpAddressListQuery,
  IsIpAddressAccessAllowedWithAllowdIpAddressListQueryVariables,
  useAccessControlSettingsQuery,
  useDisableAccessControlMutation,
  useEnableAccessControlMutation,
  useIsIpAddressAccessAllowedQuery,
  useMeQuery,
  useSrcIpAddressQuery,
  useUpdateAccessAllowIpAddressesMutation,
} from '../../../../../../../graphql/api/API';
import { LoginUserContext } from '../../../../../../../modules/auth/LoginUserContext';
import Button from '../../../../../../presentational/atoms/button';
import Form from '../../../../../../presentational/atoms/form';
import Input from '../../../../../../presentational/atoms/input';
import Typography, { TypographyType } from '../../../../../../presentational/atoms/typography';
import { IThemePart } from '../../../../../../theme';
import SettingsElement from '../../../../organisms/settings-element';
import { View } from 'react-native';
import PlusIcon from '../../../../../../presentational/molecules/image-icon/plus';
import EditableText from '../../../../../../presentational/atoms/editable-text';
import CloseIcon from '../../../../../../presentational/molecules/image-icon/close';
import ReloadIcon from '../../../../../../presentational/molecules/image-icon/reload';
import { useLazyQueryPromise } from '../../../../../../../graphql/extention/useLazyQueryPromise';
import Modal from '../../../../../../presentational/molecules/modal';

const Container = styled.View`
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
`;

const AddButton = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  padding-right: 5px;
  padding-left: 5px;
`;

interface IAccessControlSettingsModalProps {
  closeModal: () => void;
  srcIpAddress: string;
  accessControlSettings: AccessControlSettings;
}

const AccessControlSettingsModal = (props: IAccessControlSettingsModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [enableAccessControl] = useEnableAccessControlMutation({
    variables: {
      input: {
        versionNo: props.accessControlSettings.versionNo,
      },
    },
  });
  const [disableAccessControl] = useDisableAccessControlMutation({
    variables: {
      input: {
        versionNo: props.accessControlSettings.versionNo,
      },
    },
  });
  const { loading, data } = useIsIpAddressAccessAllowedQuery({
    fetchPolicy: 'network-only', //必ずnetwork-onlyにすること
  });

  if (loading) {
    return <></>;
  }

  return (
    <Form style={{ paddingVertical: 20 }}>
      {props.accessControlSettings.enable ? (
        <Typography
          variant={TypographyType.Normal}
          style={{ textAlign: 'center' }}>{`IPアドレス制限を無効にしますか？`}</Typography>
      ) : (
        <Typography
          variant={TypographyType.Normal}
          style={{
            textAlign: 'center',
          }}>{`IPアドレス制限を有効にしますか？${'\n\n'}指定したIPアドレス以外のアクセスは${'\n'}遮断されるようになるので、ご注意ください。`}</Typography>
      )}
      {data!.isIpAddressAccessAllowed !== true && (
        <View style={{ marginTop: 20 }}>
          <Typography
            variant={TypographyType.Normal}
            style={{
              color: themeContext.colors.error,
              textAlign: 'center',
              fontWeight: '600',
            }}>{`現在あなたがアクセスしているIPアドレスが許可されていません。${'\n'}許可するIPアドレスに追加してください。`}</Typography>
        </View>
      )}
      <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1 }}>
        {props.accessControlSettings.enable ? (
          <Button
            text={'IPアドレス制限を無効にする'}
            completeText="変更しました"
            style={{ minWidth: 100, marginRight: 10, marginVertical: 10 }}
            onPress={async () => {
              await disableAccessControl();
              props.closeModal();
            }}
          />
        ) : (
          <Button
            text={'IPアドレス制限を有効にする'}
            completeText="変更しました"
            isDisabled={!data!.isIpAddressAccessAllowed}
            style={{ minWidth: 100, marginRight: 10, marginVertical: 10 }}
            onPress={async () => {
              await enableAccessControl();
              props.closeModal();
            }}
          />
        )}

        <Button
          text={'キャンセル'}
          style={{
            minWidth: 100,
            marginRight: 10,
            marginVertical: 10,
            backgroundColor: 'transparent',
          }}
          textStyle={{ color: themeContext.colors.primary }}
          disableValidate={true}
          onPress={() => {
            props.closeModal();
          }}
        />
      </View>
    </Form>
  );
};

interface IIpAddressRowProps {
  isEnableAccessControl: boolean;
  isAdminRole: boolean;
  ipAddress: string;
  isFirstRow: boolean;
  srcIpAddress: string;
  onUpdate: (value: string) => void;
  onDelete: () => void;
}

const IpAddressRow = (props: IIpAddressRowProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  if (!props.isAdminRole) {
    return (
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}>
        <Typography
          variant={TypographyType.Normal}
          style={{
            fontSize: 16,
          }}>
          {props.ipAddress}
        </Typography>
      </View>
    );
  }
  return (
    <View
      style={{
        flexDirection: 'row',
        marginBottom: 10,
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}>
      <EditableText
        style={{ width: 180 }}
        ellipsis={false}
        value={props.ipAddress}
        validate={{
          required: {
            value: true,
            message: 'IPアドレスを入力してください',
          },
          pattern: {
            value:
              /^(([1-9]?[0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([1-9]?[0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/[1-9]|\/(1|2)[0-9]|\/3[0-2])?$/,
            message: '正しいIPアドレスを入力してください',
          },
        }}
        onChange={(value) => props.onUpdate(value as string)}
        textStyle={{ fontSize: 18 }}
      />
      <CloseIcon size={16} containerStyle={{ marginLeft: 10 }} onPress={props.onDelete} />
    </View>
  );
};

const AccessControlSettingsPage = () => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [durty, setDurty] = useState(false);
  const [ipAddresses, setIpAddresses] = useState<string[]>([]);
  const [newIpAddresses, setNewIpAddresses] = useState<string>('');
  const [showInputIpAddress, setShowInputIpAddress] = useState(false);
  const [showCanNotDeleteIpAddress, setShowCanNotDeleteIpAddress] = useState(false);
  const { loading, data } = useAccessControlSettingsQuery({
    fetchPolicy: 'network-only',
  });
  const { loading: loadingMe, data: dataMe } = useMeQuery({
    fetchPolicy: 'network-only',
  });
  const {
    loading: srcIpAddressLoading,
    data: srcIpAddressData,
    refetch,
  } = useSrcIpAddressQuery({
    fetchPolicy: 'network-only',
  });

  const checkIpAddressAccessAllowed = useLazyQueryPromise<
    IsIpAddressAccessAllowedWithAllowdIpAddressListQuery,
    IsIpAddressAccessAllowedWithAllowdIpAddressListQueryVariables
  >(IsIpAddressAccessAllowedWithAllowdIpAddressListDocument);

  const [updateIpAddresses] = useUpdateAccessAllowIpAddressesMutation({
    variables: {
      input: {
        ipAddresses: ipAddresses,
        versionNo: data?.accessControlSettings?.versionNo ?? 0,
      },
    },
  });

  useEffect(() => {
    setIpAddresses([...((data?.accessControlSettings?.ipAddresses as string[]) ?? [])].sort());
  }, [data?.accessControlSettings?.ipAddresses]);

  useEffect(() => {
    setNewIpAddresses('');
  }, [showInputIpAddress]);

  useEffect(() => {
    const isEqualsArray =
      [...ipAddresses].sort().toString() ===
      [...(data?.accessControlSettings?.ipAddresses ?? [])].sort().toString();
    setDurty(!isEqualsArray);
  }, [ipAddresses, data?.accessControlSettings?.ipAddresses]);

  if (loading || loadingMe || srcIpAddressLoading) {
    return <></>;
  }

  return (
    <Container>
      <View style={{ marginBottom: 30 }}>
        <Typography
          variant={
            TypographyType.Normal
          }>{`IPアドレス制限を有効にすると、指定したIPアドレスからのみ利用可能になります。${'\n'}それ以外のIPアドレスからのアクセスは遮断されます。`}</Typography>
      </View>
      <View style={{ marginBottom: 10 }}>
        <Typography
          variant={TypographyType.Description}
          style={{
            fontSize: 16,
          }}>{`IPアドレスは${'\n'}「12.23.456.789」のように、１つのIPアドレスを指定する書式か、${'\n'}「12.23.456.0/24」のようにサブネットマスクを範囲指定をする書式か、${'\n'}いずれかで入力してください。`}</Typography>
      </View>
      <View style={{ marginTop: 30 }}>
        {!dataMe!.me!.adminRole ? (
          <>
            <View>
              <Typography
                variant={TypographyType.Description}
                style={{
                  color: themeContext.colors.error,
                  fontSize: 18,
                }}>{`変更するには管理者権限が必要です。`}</Typography>
            </View>
            <SettingsElement title={'IPアドレス制限'} modal={() => {}}>
              <Typography variant={TypographyType.Normal}>
                {data?.accessControlSettings?.enable ? '有効' : '無効'}
              </Typography>
            </SettingsElement>
            <SettingsElement title={`アクセスを許可する${'\n'}IPアドレス`} modal={() => {}}>
              {(ipAddresses.length > 0 || showInputIpAddress) && (
                <View
                  style={{
                    marginVertical: 10,
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}>
                  {ipAddresses.slice().map((ipAddress, i) => {
                    return (
                      <IpAddressRow
                        isEnableAccessControl={!!data?.accessControlSettings?.enable}
                        isAdminRole={false}
                        ipAddress={ipAddress!}
                        srcIpAddress={srcIpAddressData!.srcIpAddress!}
                        isFirstRow={i === 0}
                        onUpdate={(value) => {}}
                        onDelete={() => {}}
                      />
                    );
                  })}
                </View>
              )}
            </SettingsElement>
          </>
        ) : (
          <>
            <SettingsElement
              title={'IPアドレス制限'}
              changeText="設定を変更する"
              modal={(closeModal) => (
                <AccessControlSettingsModal
                  closeModal={closeModal}
                  srcIpAddress={srcIpAddressData!.srcIpAddress!}
                  accessControlSettings={data!.accessControlSettings!}
                />
              )}>
              <Typography variant={TypographyType.Normal}>
                {data?.accessControlSettings?.enable ? '有効' : '無効'}
              </Typography>
            </SettingsElement>
            <SettingsElement title={`アクセスを許可する${'\n'}IPアドレス`} modal={() => {}}>
              {(ipAddresses.length > 0 || showInputIpAddress) && (
                <View
                  style={{
                    marginVertical: 10,
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}>
                  {ipAddresses.slice().map((ipAddress, i) => {
                    return (
                      <IpAddressRow
                        isEnableAccessControl={!!data?.accessControlSettings?.enable}
                        isAdminRole={true}
                        ipAddress={ipAddress!}
                        srcIpAddress={srcIpAddressData!.srcIpAddress!}
                        isFirstRow={i === 0}
                        onUpdate={(value) => {
                          const tmpIpAddresses = [...ipAddresses];
                          const index = tmpIpAddresses.findIndex((ip) => ip === ipAddress);
                          tmpIpAddresses.splice(index, 1, value);
                          setIpAddresses(tmpIpAddresses.sort());
                        }}
                        onDelete={async () => {
                          if (data!.accessControlSettings!.enable) {
                            const result = await checkIpAddressAccessAllowed({
                              input: {
                                allowIpAddresses: ipAddresses.filter((ip) => ip !== ipAddress),
                              },
                            });

                            if (!result.data.isIpAddressAccessAllowedWithAllowdIpAddressList) {
                              setShowCanNotDeleteIpAddress(true);
                              return;
                            }
                          }
                          setIpAddresses(ipAddresses.filter((ip) => ip !== ipAddress).sort());
                        }}
                        key={i}
                      />
                    );
                  })}
                  {showInputIpAddress && (
                    <Form>
                      <Input
                        name="newIpAddress"
                        type={'text'}
                        placeholder="xxx.xxx.xxx.xxx"
                        validate={{
                          required: {
                            value: true,
                            message: 'IPアドレスを入力してください',
                          },
                          pattern: {
                            value:
                              /^(([1-9]?[0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([1-9]?[0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/[1-9]|\/(1|2)[0-9]|\/3[0-2])?$/,
                            message: '正しいIPアドレスを入力してください',
                          },
                        }}
                        onChange={(value: string) => {
                          setNewIpAddresses(value);
                        }}
                      />
                      <View style={{ flexDirection: 'row', marginTop: 10 }}>
                        <Button
                          text="追加する"
                          onPress={() => {
                            setShowInputIpAddress(false);
                            setIpAddresses([newIpAddresses, ...ipAddresses].sort());
                          }}
                        />
                        <Button
                          text="キャンセル"
                          style={{ backgroundColor: 'transparent' }}
                          textStyle={{ fontSize: 18, color: themeContext.colors.primary }}
                          disableValidate={true}
                          onPress={() => {
                            setShowInputIpAddress(false);
                          }}
                        />
                      </View>
                    </Form>
                  )}
                </View>
              )}
              {!showInputIpAddress && (
                <AddButton
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                  onPress={async () => {
                    setShowInputIpAddress(true);
                  }}>
                  <PlusIcon
                    size={14}
                    onPress={async () => {
                      setShowInputIpAddress(true);
                    }}>
                    <Typography
                      variant={TypographyType.Normal}
                      style={{ fontSize: 16, color: themeContext.colors.link }}>
                      IPアドレスを追加する
                    </Typography>
                  </PlusIcon>
                </AddButton>
              )}
              {!showInputIpAddress && durty && (
                <View>
                  <View style={{ flexDirection: 'row', marginTop: 30 }}>
                    <Button
                      text="変更を保存する"
                      onPress={() => {
                        setShowInputIpAddress(false);
                        updateIpAddresses();
                      }}
                    />
                    <Button
                      text="キャンセル"
                      style={{ backgroundColor: 'transparent' }}
                      textStyle={{ fontSize: 18, color: themeContext.colors.primary }}
                      disableValidate={true}
                      onPress={() => {
                        setIpAddresses(
                          [...((data?.accessControlSettings?.ipAddresses as string[]) ?? [])].sort()
                        );
                      }}
                    />
                  </View>
                  <Typography
                    variant={TypographyType.Normal}
                    style={{ fontSize: 16, color: themeContext.colors.error }}>
                    {`IPアドレス設定の変更はまだ反映されていません。${'\n'}反映するには「変更を保存する」ボタンをクリックしてください。`}
                  </Typography>
                </View>
              )}
            </SettingsElement>
          </>
        )}
      </View>
      <SettingsElement
        title={`現在あなたが${'\n'}アクセスしている${'\n'}IPアドレス`}
        modal={() => {}}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Typography variant={TypographyType.Normal}>{srcIpAddressData!.srcIpAddress}</Typography>
          <ReloadIcon size={26} containerStyle={{ marginLeft: 10 }} onPress={() => refetch()} />
        </View>
      </SettingsElement>
      <Modal
        title={'このIPアドレスは削除できません'}
        isShow={showCanNotDeleteIpAddress}
        onClose={() => {
          setShowCanNotDeleteIpAddress(false);
        }}>
        <Typography
          variant={TypographyType.Normal}
          style={{ textAlign: 'center', marginVertical: 10 }}>
          {`あなたが今アクセスしているIPアドレスから、${'\n'}アクセス出来なくなってしまうため、${'\n'}このIPアドレスを削除することは出来ません。`}
        </Typography>
        <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
          <Button
            text={'OK'}
            style={{ minWidth: 100, marginRight: 10, marginVertical: 20 }}
            onPress={async () => {
              setShowCanNotDeleteIpAddress(false);
            }}
          />
        </View>
      </Modal>
    </Container>
  );
};

export default AccessControlSettingsPage;
