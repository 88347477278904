import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import {
  StyleProp,
  View,
  ViewStyle,
  TextStyle,
  Animated,
  NativeSyntheticEvent,
} from 'react-native';
import {
  GestureHandlerStateChangeNativeEvent,
  PanGestureHandler,
  PanGestureHandlerEventPayload,
  State,
} from 'react-native-gesture-handler';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
//@ts-ignore
import Ripple from 'react-native-material-ripple';
import { IStyleTheme, IThemePart } from '../../../theme';
import { useHistory } from 'react-router';
import Typography, { TypographyType } from '../typography';

const FlatList = styled.FlatList`
  display: flex;
  width: 100%;
`;

const Header = styled.View`
  display: flex;
  width: 100%;
  background-color: ${(props: IStyleTheme) => props.theme.colors.header};
  color: #ffffff;
  font-size: 1.5rem;
  padding: 5px 10px;
  text-align: center;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
`;

const Container = styled.View`
  display: flex;
  flex-direction: column;
  overflow-y: visible;
`;
//overflowはvisibleに指定しないと、List間でのドラッグ時に隠れてしまう

export interface ListItemData<DATA> {
  data: DATA;
  group?: string;
  path?: string;
}

class NonForwardedRefAnimatedView extends React.Component<any> {
  render() {
    return <Animated.View {...this.props} />;
  }
}

interface IItemProps {
  index: number;
  item: ListItemData<any>;
  items: ListItemData<any>[];
  render: (item: ListItemData<any>, dragging: boolean) => React.ReactElement<any, any> | null;
  draggable?: boolean;
  draggableHorizontal?: boolean;
  setDragging: (value: boolean) => void;
  onDrop?: (
    startIndex: number,
    endIndex: number,
    startColumnIndex: number,
    endColumnIndex: number,
    item: ListItemData<any>
  ) => void;
  columnIndex?: number;
  columnCount?: number;
  onPress?: (item: ListItemData<any>) => void;
}

const Item = (props: IItemProps) => {
  const isDarkMode = false; //TODO ちゃんとダークモード対応あとでする useColorScheme() === 'dark';
  const themeContext: IThemePart = useContext(ThemeContext);
  const history = useHistory();
  const [translationX, setTranslationX] = useState(new Animated.Value(0));
  const [translationY, setTranslationY] = useState(new Animated.Value(0));
  const [dragging, setDragging] = useState(false);
  const [draggingLazy, setDraggingLazy] = useState(false);
  const [draggingPosIndex, setDraggingPosIndex] = useState(props.index);
  const [draggingColumnIndex, setDraggingColumnIndex] = useState(props.columnIndex);
  const [dragInfo, setDragInfo] = useContext(ListSortContext); // 他の行も含めて、ドラッグ中のアイテムの情報 //TODO useContextを使っているので、子コンポーネントを作って、そちらに伝播させないようにしないとパフォーマンスが悪い
  const [globalDragInfo, setGlobalDragInfo] = useContext(ItemDragColumnContext); //TODO useContextを使っているので、子コンポーネントを作って、そちらに伝播させないようにしないとパフォーマンスが悪い
  const [needResetPosition, setNeedResetPosition] = useState(false);
  // const { ref, height, width } = useDimensions(); //TODO これのせいで、画面幅やコンポーネントの幅に変更があると、全てのItemが再レンダリングされてしまう

  const itemHeight = 100; // height;
  const itemWidth = 100; //width;
  const columnWidth = itemWidth;
  const itemPosY = itemHeight * props.index;
  const itemPosX = columnWidth * 1;

  useEffect(() => {
    if (!dragging) {
      if (props.columnCount) {
        if (globalDragInfo !== null) {
          if (
            (globalDragInfo as DragInfo).dragEndColumnIndex ===
            (globalDragInfo as DragInfo).dragStartColumnIndex
          ) {
            if (needResetPosition) {
              Animated.timing(translationY, {
                toValue: 0,
                duration: 200,
                useNativeDriver: true,
              }).start();
            }
            if (
              (globalDragInfo as DragInfo).dragEndIndex >= props.index &&
              (globalDragInfo as DragInfo).dragStartIndex < props.index &&
              (globalDragInfo as DragInfo).dragEndColumnIndex === props.columnIndex
            ) {
              Animated.timing(translationY, {
                toValue: -itemHeight,
                duration: 200,
                useNativeDriver: true,
              }).start();
            } else if (
              (globalDragInfo as DragInfo).dragEndIndex <= props.index &&
              (globalDragInfo as DragInfo).dragStartIndex < props.index
            ) {
              Animated.timing(translationY, {
                toValue: 0,
                duration: 200,
                useNativeDriver: true,
              }).start();
            }
            if (
              (globalDragInfo as DragInfo).dragEndIndex <= props.index &&
              (globalDragInfo as DragInfo).dragStartIndex > props.index &&
              (globalDragInfo as DragInfo).dragEndColumnIndex === props.columnIndex
            ) {
              Animated.timing(translationY, {
                toValue: itemHeight,
                duration: 200,
                useNativeDriver: true,
              }).start();
            } else if (
              (globalDragInfo as DragInfo).dragEndIndex > props.index &&
              (globalDragInfo as DragInfo).dragStartIndex > props.index
            ) {
              Animated.timing(translationY, {
                toValue: 0,
                duration: 200,
                useNativeDriver: true,
              }).start();
            }
          } else {
            if (
              (globalDragInfo as DragInfo).dragEndIndex <= props.index &&
              (globalDragInfo as DragInfo).dragEndColumnIndex === props.columnIndex
            ) {
              setNeedResetPosition(true);
              Animated.timing(translationY, {
                toValue: itemHeight,
                duration: 200,
                useNativeDriver: true,
              }).start();
            } else {
              Animated.timing(translationY, {
                toValue: 0,
                duration: 200,
                useNativeDriver: true,
              }).start();
            }
          }
        }
      } else {
        if (dragInfo !== null) {
          if (
            (dragInfo as DragInfo).dragEndIndex >= props.index &&
            (dragInfo as DragInfo).dragStartIndex < props.index
          ) {
            Animated.timing(translationY, {
              toValue: -itemHeight,
              duration: 200,
              useNativeDriver: true,
            }).start();
          } else if (
            (dragInfo as DragInfo).dragEndIndex <= props.index &&
            (dragInfo as DragInfo).dragStartIndex < props.index
          ) {
            Animated.timing(translationY, {
              toValue: 0,
              duration: 200,
              useNativeDriver: true,
            }).start();
          }
          if (dragInfo !== null) {
            if (
              (dragInfo as DragInfo).dragEndIndex <= props.index &&
              (dragInfo as DragInfo).dragStartIndex > props.index
            ) {
              Animated.timing(translationY, {
                toValue: itemHeight,
                duration: 200,
                useNativeDriver: true,
              }).start();
            } else if (
              (dragInfo as DragInfo).dragEndIndex > props.index &&
              (dragInfo as DragInfo).dragStartIndex > props.index
            ) {
              Animated.timing(translationY, {
                toValue: 0,
                duration: 200,
                useNativeDriver: true,
              }).start();
            }
          }
        }
      }
    }
  }, [dragInfo, globalDragInfo]);

  if (props.draggable) {
    return (
      <PanGestureHandler
        maxPointers={1}
        onGestureEvent={Animated.event(
          props.draggableHorizontal
            ? [
                {
                  nativeEvent: {
                    translationX: translationX,
                    translationY: translationY,
                  },
                },
              ]
            : [
                {
                  nativeEvent: {
                    translationY: translationY,
                  },
                },
              ],
          {
            listener: (
              event: NativeSyntheticEvent<
                PanGestureHandlerEventPayload & GestureHandlerStateChangeNativeEvent
              >
            ) => {
              const currentPosY = itemPosY + event.nativeEvent.translationY;
              const currentIndex =
                currentPosY > itemPosY
                  ? Math.round((currentPosY + itemHeight * 0.8) / itemHeight) - 1
                  : Math.round((currentPosY + itemHeight * 1.2) / itemHeight) - 1;

              const currentPosX = itemPosX + event.nativeEvent.translationX;
              const currentColumnIndex = Math.min(
                (props.columnIndex || 0) + Math.round(currentPosX / columnWidth) - 1,
                props.columnCount ? props.columnCount - 1 : 0
              );

              if (event.nativeEvent.state === State.BEGAN) {
                setDragging(true);
                setDraggingLazy(true);
                props.setDragging(true);
                setDraggingPosIndex(props.index);
                setDraggingColumnIndex(props.columnIndex);
                setTranslationX(new Animated.Value(0));
                setTranslationY(new Animated.Value(0));
                //@ts-ignore
                setDragInfo({
                  dragStartIndex: props.index,
                  dragEndIndex: props.index,
                  dragStartColumnIndex: props.columnIndex,
                  dragEndColumnIndex: props.columnIndex,
                });
                //@ts-ignore
                setGlobalDragInfo({
                  dragStartIndex: props.index,
                  dragEndIndex: props.index,
                  dragStartColumnIndex: props.columnIndex,
                  dragEndColumnIndex: props.columnIndex,
                });
              }
              if (event.nativeEvent.state === State.ACTIVE) {
                if (
                  draggingPosIndex !== currentIndex ||
                  draggingColumnIndex !== currentColumnIndex
                ) {
                  setDraggingPosIndex(currentIndex);
                  setDraggingColumnIndex(currentColumnIndex);
                  //@ts-ignore
                  setDragInfo({
                    dragStartIndex: props.index,
                    dragEndIndex: currentIndex,
                    dragStartColumnIndex: props.columnIndex,
                    dragEndColumnIndex: currentColumnIndex,
                  });
                  //@ts-ignore
                  setGlobalDragInfo({
                    dragStartIndex: props.index,
                    dragEndIndex: currentIndex,
                    dragStartColumnIndex: props.columnIndex,
                    dragEndColumnIndex: currentColumnIndex,
                  });
                }
              }
              if (event.nativeEvent.state === State.END) {
                setDragging(false);
                setTimeout(() => {
                  setDraggingLazy(false);
                }, 200);
                props.setDragging(false);
                setTranslationX(new Animated.Value(0));
                setTranslationY(new Animated.Value(0));
                setDraggingPosIndex(props.index);
                setDraggingColumnIndex(props.columnIndex);
                //@ts-ignore
                setDragInfo({
                  dragStartIndex: props.index,
                  dragEndIndex: props.index,
                  dragStartColumnIndex: props.columnIndex,
                  dragEndColumnIndex: props.columnIndex,
                });
                //@ts-ignore
                setGlobalDragInfo({
                  dragStartIndex: props.index,
                  dragEndIndex: props.index,
                  dragStartColumnIndex: props.columnIndex,
                  dragEndColumnIndex: props.columnIndex,
                });
                if (props.onDrop) {
                  props.onDrop(
                    props.index,
                    currentIndex,
                    props.columnIndex || 0,
                    props.columnCount || 0,
                    props.item
                  );
                }
              }
            },
            useNativeDriver: true,
          }
        )}>
        <NonForwardedRefAnimatedView>
          {/* <View ref={ref as any}> */}
          <View>
            {props.onPress ? (
              <Ripple
                style={{
                  cursor: 'pointer',
                  transform: [
                    {
                      translateX: props.draggableHorizontal ? translationX : 0,
                    },
                    {
                      translateY: translationY,
                    },
                  ],
                }}
                rippleColor={isDarkMode ? '#FFFFFF' : themeContext.colors.primary}
                rippleSize={500}
                onPress={() => {
                  if (draggingLazy) {
                    // ドラッグ＆ドロップした時に、ドロップ時にonPressの処理が発生しないようにキャンセル
                    return;
                  }
                  if (props.onPress) {
                    props.onPress(props.item);
                  }
                  if (props.item.path) {
                    history.push(props.item.path);
                  }
                }}>
                {props.render(props.item, dragging)}
              </Ripple>
            ) : (
              props.render(props.item, dragging)
            )}
          </View>
        </NonForwardedRefAnimatedView>
      </PanGestureHandler>
    );
  }
  return (
    // <View ref={ref as any}>
    <View>
      {props.onPress ? (
        <Ripple
          style={{
            cursor: 'pointer',
          }}
          rippleColor={isDarkMode ? '#FFFFFF' : themeContext.colors.primary}
          rippleSize={500}
          onPress={() => {
            if (props.onPress) {
              props.onPress(props.item);
            }
            if (props.item.path) {
              history.push(props.item.path);
            }
          }}>
          {props.render(props.item, dragging)}
        </Ripple>
      ) : (
        props.render(props.item, dragging)
      )}
    </View>
  );
};

const isHeader = <DATA extends {}>(items: ListItemData<DATA>[], index: number) => {
  return index >= 0 && (index === 0 || items[index].group !== items[index - 1].group);
};

const isAfterHeader = <DATA extends {}>(items: ListItemData<DATA>[], index: number) => {
  return index === 0 || index === 1 || items[index].group !== items[index - 2].group;
};

const isEndOfGroup = <DATA extends {}>(items: ListItemData<DATA>[], index: number) => {
  return index === items.length - 1 ? true : isHeader(items, index + 1);
};

const isRenderHeader = <DATA extends {}>(items: ListItemData<DATA>[], index: number) => {
  return isHeader(items, index);
};

interface IGroupHeaderProps {
  item: ListItemData<any>;
  render?: (title: string) => React.ReactElement<any, any> | null;
}

const GroupHeader = (props: IGroupHeaderProps) => {
  if (props.render) {
    return <View>{props.render(props.item.group || '')}</View>;
  }
  return <View></View>;
};

interface IRowProps {
  items: ListItemData<any>[];
  index: number;
  item: ListItemData<any>;
  renderItem: (item: ListItemData<any>, dragging: boolean) => React.ReactElement<any, any> | null;
  setDragging: (value: boolean) => void;
  draggable?: boolean;
  draggableHorizontal?: boolean;
  onDrop?: (
    startIndex: number,
    endIndex: number,
    startColumnIndex: number,
    endColumnIndex: number,
    item: ListItemData<any>
  ) => void;
  columnIndex?: number;
  columnCount?: number;
  showGroupHeader?: boolean;
  renderGroupHeader?: (title: string) => React.ReactElement<any, any> | null;
  onPress?: (item: ListItemData<any>) => void;
  afterElement?: React.ReactElement<any, any>;
  afterGroupElement?: React.ReactElement<any, any>;
}

const Row = (props: IRowProps) => {
  if (props.showGroupHeader) {
    if (isRenderHeader(props.items, props.index)) {
      return (
        <>
          <GroupHeader item={props.item} render={props.renderGroupHeader} />
          <Item
            index={props.index}
            item={props.item}
            items={props.items}
            render={props.renderItem}
            onPress={props.onPress}
            draggable={props.draggable}
            draggableHorizontal={props.draggableHorizontal}
            setDragging={props.setDragging}
            onDrop={props.onDrop}
            columnIndex={props.columnIndex}
            columnCount={props.columnCount}
          />
          {props.afterGroupElement &&
            isEndOfGroup(props.items, props.index) &&
            props.afterGroupElement}
          {props.afterElement !== undefined &&
            props.items.length - 1 === props.index &&
            props.afterElement}
        </>
      );
    }
    return (
      <>
        <Item
          index={props.index}
          item={props.item}
          items={props.items}
          render={props.renderItem}
          onPress={props.onPress}
          draggable={props.draggable}
          draggableHorizontal={props.draggableHorizontal}
          setDragging={props.setDragging}
          onDrop={props.onDrop}
          columnIndex={props.columnIndex}
          columnCount={props.columnCount}
        />
        {props.afterGroupElement &&
          isEndOfGroup(props.items, props.index) &&
          props.afterGroupElement}
        {props.afterElement !== undefined &&
          props.items.length - 1 === props.index &&
          props.afterElement}
      </>
    );
  }
  return (
    <>
      <Item
        index={props.index}
        item={props.item}
        items={props.items}
        render={props.renderItem}
        onPress={props.onPress}
        draggable={props.draggable}
        draggableHorizontal={props.draggableHorizontal}
        setDragging={props.setDragging}
        onDrop={props.onDrop}
        columnIndex={props.columnIndex}
        columnCount={props.columnCount}
      />
      {props.afterElement !== undefined &&
        props.items.length - 1 === props.index &&
        props.afterElement}
    </>
  );
};

interface Props<DATA> {
  items: ListItemData<DATA>[];
  renderItem: (
    item: ListItemData<DATA>,
    dragging: boolean,
    index: number,
    isGroupFirstRow: boolean,
    moveToFront: (value: boolean) => void
  ) => React.ReactElement<any, any> | null;
  getKey?: (item: ListItemData<DATA>, index?: number) => string;
  showGroupHeader?: boolean;
  useStickyHeader?: boolean;
  renderGroupHeader?: (title: string) => React.ReactElement<any, any> | null;
  onPress?: (item: ListItemData<DATA>) => void;
  onEndReached?: () => void;
  style?: StyleProp<ViewStyle>;
  headerText?: string;
  headerStyle?: StyleProp<ViewStyle>;
  headerTextStyle?: StyleProp<TextStyle>;
  headerComponent?: React.ReactElement<any, any>;
  subHeader?: React.ReactElement<any, any>;
  afterElement?: React.ReactElement<any, any>;
  afterGroupElement?: React.ReactElement<any, any>;
  filter?: (item: ListItemData<DATA>) => boolean;
  draggable?: boolean;
  draggableHorizontal?: boolean;
  columnIndex?: number;
  columnCount?: number;
  onDrop?: (
    startIndex: number,
    endIndex: number,
    startColumnIndex: number,
    endColumnIndex: number,
    item: ListItemData<DATA>,
    sortedItems: ListItemData<DATA>[]
  ) => void;
}

const List = <DATA extends {}>(props: Props<DATA>) => {
  const [stickyHeaderIndices, setStickyHeaderIndices] = useState<Array<number>>([]);
  const filteredItems = props.filter ? props.items.filter(props.filter) : props.items;
  const containerRef = useRef();

  if (props.useStickyHeader) {
    useLayoutEffect(() => {
      const arr = [];
      filteredItems.map((item, index) => {
        if (isRenderHeader(filteredItems, index)) {
          arr.push(filteredItems.indexOf(item));
        }
      });
      arr.push(0);
      setStickyHeaderIndices(arr);
    }, [props.items, props.filter]);
  }

  return (
    <Container ref={containerRef as any}>
      {props.headerComponent
        ? props.headerComponent
        : props.headerText && (
            <Header style={props.headerStyle as any}>
              <Typography
                variant={TypographyType.Normal}
                style={[{ color: '#FFFFFF', fontWeight: '600' }, props.headerTextStyle] as any}>
                {props.headerText}
              </Typography>
            </Header>
          )}
      {props.subHeader && props.subHeader}
      <ListSortContextProvider>
        <FlatList
          style={[{ overflow: 'visible' }, props.style] as any}
          data={filteredItems}
          renderItem={() => <></>}
          onEndReached={props.onEndReached}
          onEndReachedThreshold={0.1}
          CellRendererComponent={({ index, style, ...otherProps }: any) => {
            const [dragging, setDragging] = useState(false);
            const [isMoveToFront, setMoveToFront] = useState(false);
            const renderItem = useCallback(
              (item, dragging) => {
                return props.renderItem(
                  item,
                  dragging,
                  index,
                  props.showGroupHeader ? isAfterHeader(props.items, index) : false,
                  (value) => setMoveToFront(value)
                );
              },
              [index, props.items, props.showGroupHeader]
            );

            useEffect(() => {
              if (dragging) {
                (containerRef.current! as any).style.zIndex = 2;
              } else {
                (containerRef.current! as any).style.zIndex = 1;
              }
            }, [dragging]);

            return (
              <View
                style={[
                  style,
                  { zIndex: dragging || isMoveToFront ? 2 : 1, backgroundColor: '#999999' },
                ]}>
                <Row
                  items={props.items}
                  item={otherProps.item}
                  index={index}
                  renderItem={renderItem}
                  showGroupHeader={props.showGroupHeader}
                  renderGroupHeader={props.renderGroupHeader}
                  onPress={props.onPress}
                  afterElement={props.afterElement}
                  afterGroupElement={props.afterGroupElement}
                  columnIndex={props.columnIndex}
                  columnCount={props.columnCount}
                  draggable={props.draggable}
                  draggableHorizontal={props.draggableHorizontal}
                  setDragging={(value) => setDragging(value)}
                  onDrop={(start, end, startColumn, endColumn, item) => {
                    if (props.onDrop) {
                      const copyItems = props.items.concat([]);
                      copyItems.splice(start, 1);
                      copyItems.splice(end, 0, item);
                      props.onDrop(start, end, startColumn, endColumn, item, copyItems);
                    }
                  }}
                />
              </View>
            );
          }}
          keyExtractor={props.getKey as any}
          stickyHeaderIndices={stickyHeaderIndices}
        />
        {props.afterElement !== undefined && props.items.length === 0 && props.afterElement}
      </ListSortContextProvider>
    </Container>
  );
};

interface DragInfo {
  dragStartIndex: number;
  dragEndIndex: number;
  dragStartColumnIndex: number;
  dragEndColumnIndex: number;
}

const ListSortContext = createContext([
  { dragStartIndex: 0, dragEndIndex: 0, dragStartColumnIndex: 0, dragEndColumnIndex: 0 },
  (value: DragInfo) => {},
]);

const ListSortContextProvider = (props: any) => {
  const [draggingPosIndex, setDraggingPosIndex] = useState<DragInfo | null>(null);
  return (
    <ListSortContext.Provider value={[draggingPosIndex as any, setDraggingPosIndex]}>
      {props.children}
    </ListSortContext.Provider>
  );
};

export const ItemDragColumnContext = createContext([
  { dragStartIndex: 0, dragEndIndex: 0, dragStartColumnIndex: 0, dragEndColumnIndex: 0 },
  (value: DragInfo) => {},
]);

export const ItemDragColumnContextProvider = (props: any) => {
  const [draggingPosIndex, setDraggingPosIndex] = useState<DragInfo | null>(null);
  return (
    <ItemDragColumnContext.Provider value={[draggingPosIndex as any, setDraggingPosIndex]}>
      {props.children}
    </ItemDragColumnContext.Provider>
  );
};

export default React.memo(List);
