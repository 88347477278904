import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import styled, { ThemeContext } from 'styled-components/native';
import useDimensions from 'use-dimensions';
import { ProjectTemplate, useProjectTemplateQuery } from '../../../../../graphql/api/API';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import { IStyleTheme, IThemePart } from '../../../../theme';
import CustomScrollView from '../../../../presentational/atoms/custom-scroll-view';
import Icon from '../../../../presentational/atoms/icon';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import { toast } from 'react-toastify';
import ProjectDetailCommon from './common';
import ProjectDetailCommonLoading from './common-loading';
import queryString from 'query-string';
import ProjectDetailStandardInfo from './standard-info';
import UploadIcon from '../../../../presentational/molecules/image-icon/upload';

interface IContainerProps extends IStyleTheme {
  isOpen: boolean;
  windowWidth: number;
}

const Container = styled.View<IContainerProps>`
  height: calc(100vh - 57px);
  right: ${(props: IContainerProps) =>
    props.windowWidth < 1260 ? '-490px' : `-${Math.max(props.windowWidth / 2.2, 540) + 10}px`};
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.4s;
  width: ${(props: IContainerProps) =>
    props.isOpen
      ? `${Math.max(props.windowWidth / 2.2, 540)}px`
      : '1px'}; // Note: 閉じている時に幅を 1px ではなく、0pxにすると、なぜかSafariは幅を300pxぐらい取る挙動になってしまう。それによって画面右側300pxぐらいに透明な状態のエリアがかぶさってしまい、画面操作を阻害してしまう。Safari対応のために幅を1pxに指定するようにしている。
  transform: ${(props: IContainerProps) =>
    `translateX(${props.isOpen ? `-${Math.max(props.windowWidth / 2.2, 540)}px` : '0'})`};
  background-color: ${(props: IContainerProps) => props.theme.colors.baseColor};
  overflow: hidden;
`;

interface IContainerInnerProps extends IStyleTheme {
  isShow: boolean;
}

const ContainerInner = styled.View<IContainerInnerProps>``;

interface IProjectDetailInnerProps {
  project: ProjectTemplate | null;
  projectDetailMode: string | null;
  loading: boolean;
}

const ProjectDetailInner = (props: IProjectDetailInnerProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const { pathname } = useLocation();
  const [loginUser, _] = useContext(LoginUserContext);
  const dimensions = useDimensions();
  const windowWidth = dimensions.window.width;
  const ref = useRef();
  const [isDragOver, setDragOver] = useState(false);

  if (props.project) {
    return (
      <Container isOpen={true} windowWidth={windowWidth}>
        <div
          onDragOver={(e) => {
            e.stopPropagation();
            e.preventDefault();
            if (!isDragOver) {
              setDragOver(true);
            }
          }}>
          <CustomScrollView scrollViewRef={ref} style={{ height: 'calc(100vh - 57px)' }}>
            <ContainerInner isShow={true}>
              <ProjectDetailCommon
                organizationId={loginUser?.organizationId!}
                project={props.project}
                projectDetailMode={props.projectDetailMode!}>
                <ProjectDetailStandardInfo
                  organizationId={loginUser?.organizationId!}
                  project={props.project}
                  parentScrollViewRef={ref}
                />
              </ProjectDetailCommon>
            </ContainerInner>
          </CustomScrollView>
        </div>
      </Container>
    );
  }
  return <Container isOpen={false} windowWidth={windowWidth} />;
};

interface IProjectDetailWithFetchDataProps {
  projectId: string | null;
  projectDetailMode: string | null;
}

const ProjectDetailWithFetchData = (props: IProjectDetailWithFetchDataProps) => {
  const { loading, data, error } = useProjectTemplateQuery({
    variables: {
      id: props.projectId || '',
    },
    skip: props.projectId === null || props.projectId === undefined,
  });

  return (
    <ProjectDetailInner
      loading={loading}
      project={(data?.projectTemplate as ProjectTemplate) || null}
      projectDetailMode={props.projectDetailMode}
    />
  );
};

const ProjectDetail = () => {
  const { search } = useLocation();
  const projectId = queryString.parse(search).projectTemplateDetailId as string;
  const projectDetailMode = queryString.parse(search).projectDetailMode as string;
  return <ProjectDetailWithFetchData projectId={projectId} projectDetailMode={projectDetailMode} />;
};

export default ProjectDetail;

// TODO テンプレートは公開ステータスをつけたいかな。編集中のものが他の人に使われないように。
