import React from 'react';
import styled from 'styled-components/native';
import { Project } from '../../../../../../graphql/api/API';
import TaskInfoElement from '../task-info-element';
import moment from 'moment-timezone';
import Typography, { TypographyType } from '../../../../../presentational/atoms/typography';

const Conatiner = styled.View`
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  align-items: flex-start;
  z-index: 2; //DateTimePickerを使用する際には、外側の要素のz-indexを指定しないと正しく表示されないケースがあるので、ここで指定している
`;

const Row = styled.View`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: space-between;
`;

interface IProjectHistoryViewProps {
  project: Project;
}

const ProjectHistoryView = (props: IProjectHistoryViewProps) => {
  return (
    <>
      <Conatiner>
        <Row style={{ justifyContent: 'flex-start' }}>
          <TaskInfoElement
            title={'作成者'}
            style={{ flex: 1, maxWidth: 300 }}
            contentFlexDirection={'row'}>
            <Typography variant={TypographyType.Normal}>
              {props.project.createdMember?.name ?? '-'}
            </Typography>
          </TaskInfoElement>
        </Row>
        <Row style={{ justifyContent: 'flex-start' }}>
          <TaskInfoElement
            title={'作成日時'}
            style={{ flex: 1, maxWidth: 300 }}
            contentFlexDirection={'row'}>
            <Typography variant={TypographyType.Normal}>
              {moment(props.project.registerDateTime).format('YYYY/MM/DD HH:mm:ss')}
            </Typography>
          </TaskInfoElement>
        </Row>
      </Conatiner>
    </>
  );
};

export default ProjectHistoryView;
