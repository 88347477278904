import moment from 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import styled, { ThemeContext } from 'styled-components/native';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import { IStyleTheme, IThemePart } from '../../../../theme';
import {
  GoogleCalendarEvent,
  GoogleCalendarEventDocument,
  Plan,
  useGoogleWorkSpaceMembersQuery,
  useMyGoogleAccountQuery,
  useMyGoogleCalendarsQuery,
  useOrganizationQuery,
  useRegisterGoogleCalendarEventMutation,
} from '../../../../../graphql/api/API';
import Avatar from '../../../../presentational/atoms/avatar';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import Modal from '../../../../presentational/molecules/modal';
import { View, TouchableOpacity } from 'react-native';
import Button from '../../../../presentational/atoms/button';
import Form from '../../../../presentational/atoms/form';
import Input from '../../../../presentational/atoms/input';
import Icon from '../../../../presentational/atoms/icon';
import { useHistory } from 'react-router';
import CustomScrollView from '../../../../presentational/atoms/custom-scroll-view';
import { IStartAndEnd } from '../../../share/organisms/calenar';
import EditableText from '../../../../presentational/atoms/editable-text';
import CloseIcon from '../../../../presentational/molecules/image-icon/close';

export interface MailAddressAndName {
  mailAddress: string;
  name: string | null;
}

interface IGuestListProps {
  guests: MailAddressAndName[];
  setGuests: (values: MailAddressAndName[]) => void;
}

const GuestList = (props: IGuestListProps) => {
  if (props.guests.length === 0) {
    return <></>;
  }
  return (
    <CustomScrollView
      style={{
        flexDirection: 'column',
        maxHeight: 300,
        width: '100%',
        padding: 10,
      }}>
      {props.guests.map((guest, i) => {
        return (
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginVertical: 2,
              justifyContent: 'space-between',
            }}
            key={i}>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'flex-start',
              }}>
              <Avatar size={28} name={guest!.name || '-'} />
              <Typography variant={TypographyType.Normal} style={{ marginLeft: 5 }}>
                {guest!.name}
              </Typography>
            </View>
            <CloseIcon
              size={18}
              onPress={() => {
                props.setGuests(
                  props.guests.filter((value) => value.mailAddress !== guest.mailAddress)
                );
              }}
            />
          </View>
        );
      })}
    </CustomScrollView>
  );
};

interface ICreateGoogleCalendarEventModalProps {
  startDateTime?: moment.Moment;
  endDateTime?: moment.Moment;
  showModal: boolean;
  calendarStartAndEndDateTime: IStartAndEnd;
  onPressYes: (value: GoogleCalendarEvent) => void;
  onCloseModal: () => void;
  isConnectGoogleAccount: boolean;
}

const CreateGoogleCalendarEventModal = React.memo((props: ICreateGoogleCalendarEventModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, _] = useContext(LoginUserContext);
  const [title, setTitle] = useState('');
  const [location, setLocation] = useState('');
  const [description, setDescription] = useState('');
  const [startDateTime, setStartDateTime] = useState<moment.Moment>(
    props.startDateTime || moment()
  );
  const [endDateTime, setEndDateTime] = useState<moment.Moment>(props.endDateTime || moment());
  const [calendarId, setCalendarId] = useState('primary');
  const [guests, setGuests] = useState<MailAddressAndName[]>([]);
  const history = useHistory();
  const { loading: myGoogleAccountLoading, data: myGoogleAccountData } = useMyGoogleAccountQuery({
    fetchPolicy: 'network-only',
    onError: (e) => {
      if (e.graphQLErrors.find((ge) => ge.extensions?.code === 'google-token-update-failure')) {
        window.alert(
          'Googleカレンダーの情報取得が出来なくなりました。申し訳ありませんが「Googleアカウント連携」メニューからもう一度Googleアカウントを連携し直してください。'
        );
        history.push(`/app/${loginUser!.organizationId}/my-settings/google/`);
      }
      if (e.graphQLErrors.find((ge) => ge.extensions?.code === 'google-account-permission-error')) {
        window.alert(
          'Googleカレンダーの情報取得ができませんでした。「Googleアカウント連携」メニューからもう一度Googleアカウントを連携し直してください。その際にカレンダーへのアクセスを許可してください。'
        );
        history.push(`/app/${loginUser!.organizationId}/my-settings/google/`);
      }
    },
  });
  const { loading, data } = useGoogleWorkSpaceMembersQuery({
    fetchPolicy: 'cache-first',
    skip: !myGoogleAccountData?.myGoogleAccount,
  });
  const { loading: organizationLoading, data: organizationData } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId,
    },
  });
  const { loading: googleCalendarsLoading, data: googleCalendarsData } = useMyGoogleCalendarsQuery({
    skip: !props.isConnectGoogleAccount,
  });

  const [registerGoogleCalendarEvent, __] = useRegisterGoogleCalendarEventMutation({
    variables: {
      input: {
        calendarId: calendarId,
        title: title,
        location: location || null,
        description: description || null,
        start: startDateTime?.toISOString(),
        end: endDateTime?.toISOString(),
        attendeeMailAddresses: guests.map((guest) => guest.mailAddress),
      },
    },
    update: (cache, result) => {
      cache.modify({
        fields: {
          googleCalendarEvents(existing = [], { storeFieldName }) {
            const newEvent = cache.writeQuery({
              data: result.data!.registerGoogleCalendarEvent,
              query: GoogleCalendarEventDocument,
            });
            return [...existing, newEvent];
          },
        },
      });
    },
  });

  useEffect(() => {
    setStartDateTime(props.startDateTime || moment());
    setEndDateTime(props.endDateTime || moment());
    setGuests([]);
  }, [props.startDateTime, props.endDateTime]);

  if (loading || myGoogleAccountLoading || organizationLoading || googleCalendarsLoading) {
    return <></>;
  }

  return (
    <Modal
      title={'Googleカレンダー予定の追加'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <Form>
        <Input
          name={'title'}
          label={'タイトル'}
          type={'text'}
          autoFocus
          onChange={setTitle}
          validate={{
            required: {
              value: true,
              message: 'タイトルを記入してください',
            },
          }}
        />
        <View style={{ flexDirection: 'row', alignItems: 'center', zIndex: 3 }}>
          <View style={{ minWidth: 90 }}>
            <Typography variant={TypographyType.Normal} style={{ fontSize: 16, lineHeight: 22 }}>
              開始日時
            </Typography>
          </View>
          <EditableText
            value={startDateTime}
            type={'date-time-picker'}
            textStyle={{ fontSize: 18, lineHeight: 22 }}
            containerStyle={{
              borderBottomWidth: 1,
              borderBottomColor: themeContext.colors.textColor,
            }}
            disableClear={true}
            onChange={(value) => {
              setStartDateTime(value as moment.Moment);
            }}
          />
        </View>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: 20,
            zIndex: 2,
          }}>
          <View style={{ minWidth: 90 }}>
            <Typography variant={TypographyType.Normal} style={{ fontSize: 16, lineHeight: 22 }}>
              終了日時
            </Typography>
          </View>
          <EditableText
            value={endDateTime}
            type={'date-time-picker'}
            textStyle={{ fontSize: 18, lineHeight: 22 }}
            containerStyle={{
              borderBottomWidth: 1,
              borderBottomColor: themeContext.colors.textColor,
            }}
            disableClear={true}
            onChange={(value) => {
              setEndDateTime(value as moment.Moment);
            }}
          />
        </View>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: 20,
            zIndex: 1,
          }}>
          <Input
            name={'calendar'}
            label={'カレンダー'}
            type={'picker'}
            isSearchable={true}
            initialValue={myGoogleAccountData!.myGoogleAccount!.mailAddress}
            pickerItems={googleCalendarsData
              ?.myGoogleCalendars!.slice()
              .sort((a, b) => {
                if (a?.primary) {
                  return -1;
                }
                if (a?.myCalendar) {
                  if (b?.myCalendar) {
                    a!.id! > b!.id!;
                  } else {
                    return -1;
                  }
                }
                return a!.id! > b!.id!;
              })
              .map((calendar) => {
                return {
                  label: calendar!.name,
                  value: calendar!.calendarId!,
                };
              })}
            onChange={(value) => setCalendarId(value)}
          />
        </View>

        {(organizationData!.organization!.plan.code === Plan.Business ||
          organizationData!.organization!.plan.code === Plan.Enterprise) && (
          <>
            <View style={{ marginTop: 10 }}>
              <Input
                name={'member'}
                label={'ゲストを追加'}
                type={'picker'}
                isSearchable={true}
                pickerItems={data?.googleWorkSpaceMembers
                  ?.filter(
                    (member) =>
                      member!.mailAddress !== myGoogleAccountData?.myGoogleAccount?.mailAddress
                  )
                  .map((member) => {
                    return {
                      label: member!.name,
                      value: member!.mailAddress,
                    };
                  })}
                onChange={(value) => {
                  if (guests.map((guest) => guest.mailAddress).lastIndexOf(value) === -1) {
                    setGuests([
                      ...guests,
                      {
                        mailAddress: value,
                        name:
                          data?.googleWorkSpaceMembers?.find(
                            (member) => member?.mailAddress === value
                          )?.name || null,
                      },
                    ]);
                  }
                }}
              />
            </View>
            <GuestList guests={guests} setGuests={setGuests} />
          </>
        )}
        {startDateTime?.isAfter(endDateTime) && (
          <View style={{ marginTop: 10 }}>
            <Typography
              variant={TypographyType.Description}
              style={{ color: themeContext.colors.error }}>
              開始日時は、終了日時よりも前にしてください
            </Typography>
          </View>
        )}
        {(!startDateTime || !endDateTime) && (
          <View style={{ marginTop: 10 }}>
            <Typography
              variant={TypographyType.Description}
              style={{ color: themeContext.colors.error }}>
              開始日時・終了日時を入力してください
            </Typography>
          </View>
        )}
        <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1 }}>
          <Button
            text={'追加する'}
            style={{ minWidth: 100, marginRight: 10, marginVertical: 10 }}
            isDisabled={startDateTime?.isAfter(endDateTime) || !startDateTime || !endDateTime}
            onPress={async () => {
              const { data } = await registerGoogleCalendarEvent();
              props.onPressYes(data!.registerGoogleCalendarEvent!);
            }}
          />
          <Button
            text={'キャンセル'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.primary }}
            disableValidate={true}
            onPress={() => {
              props.onCloseModal();
            }}
          />
        </View>
      </Form>
    </Modal>
  );
});

export default CreateGoogleCalendarEventModal;
