import moment from 'moment-timezone';
import queryString from 'query-string';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { TouchableOpacity, View } from 'react-native';
import { useHistory } from 'react-router';
import styled, { ThemeContext } from 'styled-components/native';
import useInterval from 'use-interval';
import {
  Task,
  type WorkingHistory,
  useLatestWorkingHistoryQuery,
  useMeQuery,
} from '../../../../../graphql/api/API';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import LoginUtil from '../../../../../modules/auth/LoginUtil';
import Avatar from '../../../../presentational/atoms/avatar';
import Form from '../../../../presentational/atoms/form';
import Icon from '../../../../presentational/atoms/icon';
import Input from '../../../../presentational/atoms/input';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import SearchIcon from '../../../../presentational/molecules/image-icon/search';
import type { IStyleTheme, IThemePart } from '../../../../theme';
import TaskSummaryForHeader from '../../../web/organisms/task-summary-for-header';

const Container = styled.View`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 57px;
  height: 57px;
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  z-index: 200;
`;

const Menu = styled.View`
  position: absolute;
  top: 40px;
  right: 0;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
  border-width: 1px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 200px;
`;

interface ILatestTaskHeaderProps {
  organizationId: string;
}

const LatestTaskHeader = (props: ILatestTaskHeaderProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [openMenu, setOpenMenu] = useState(false);
  const { loading, data, error } = useMeQuery({
    fetchPolicy: 'network-only',
  });
  const fetchLatestWorkingHistory = useLatestWorkingHistoryQuery({
    fetchPolicy: 'network-only',
  });
  const [searchText, setSearchText] = useState('');
  const menuRef = useRef();
  const history = useHistory();
  useHotkeys('Esc', () => setOpenMenu(false));

  // 他の端末で作業記録をした場合に、最新の作業タスクの表示が反映されない。そのため、画面にフォーカスした時にRefetchして最新情報を取得し直して、反映するようにしている
  // TODO Memo:
  // const onWindowFocus = (e: any) => {
  //   fetchLatestWorkingHistory.refetch();
  // };
  // useEffect(() => {
  //   window.addEventListener('focus', onWindowFocus);
  //   return () => {
  //     window.removeEventListener('focus', onWindowFocus);
  //   };
  // }, [onWindowFocus]);

  const clickDocument = (e: any) => {
    if ((menuRef?.current as any)?.contains(e.target)) {
      (menuRef?.current as any)?.click();
      return;
    }
    setOpenMenu(false);
  };
  useEffect(() => {
    window.addEventListener('click', clickDocument);
    return () => {
      window.removeEventListener('click', clickDocument);
    };
  }, [clickDocument]);

  if (loading || !data?.me) {
    return <></>;
  }
  if (fetchLatestWorkingHistory.loading) {
    return <></>;
  }

  return (
    <Container>
      <View style={{ flex: 1 }}>
        {fetchLatestWorkingHistory.data?.latestWorkingHistory && (
          <TaskSummaryForHeader
            organizationId={props.organizationId}
            workingHistory={fetchLatestWorkingHistory.data!.latestWorkingHistory! as WorkingHistory}
            me={data.me}
          />
        )}
      </View>
      <View style={{ paddingHorizontal: 3, flexDirection: 'row' }}>
        <Form style={{ width: 'auto' }}>
          <Input
            name={'searchProject'}
            label={''}
            placeholder={'タスクを検索する'}
            icon={<SearchIcon size={20} containerStyle={{ marginLeft: 10 }} />}
            containerStyle={{ marginVertical: 10 }}
            inputstyle={{ borderWidth: 0 }}
            inputContainerStyle={{
              marginHorizontal: 10,
              backgroundColor: themeContext.colors.baseColor,
              shadowOffset: {
                width: -1,
                height: -1,
              },
              shadowOpacity: 0.1,
              elevation: 2,
              borderWidth: 1,
              borderRadius: 20,
              borderColor: themeContext.colors.separator,
            }}
            onChange={(value: string) => {
              setSearchText(value);
            }}
            onPressEnter={() => {
              history.push(
                queryString.stringifyUrl({
                  url: `/app/${props.organizationId}/search-task/list/`,
                  query: {
                    text: searchText,
                  },
                })
              );
            }}
          />
        </Form>
      </View>
      <View style={{ alignItems: 'center', justifyContent: 'center', marginRight: 10 }}>
        <Avatar
          size={30}
          name={loginUser!.name}
          imageUrl={data!.me!.profileImageUrl}
          onPress={() => setOpenMenu(true)}></Avatar>
        {openMenu && (
          <Menu ref={menuRef as any}>
            <TouchableOpacity
              onPress={() => {
                LoginUtil.logout();
                window.location.reload();
              }}>
              <Typography variant={TypographyType.SubTitle}>ログアウト</Typography>
            </TouchableOpacity>
          </Menu>
        )}
      </View>
    </Container>
  );
};

export default React.memo(LatestTaskHeader);
