import Clipboard from '@react-native-clipboard/clipboard';
import React, { useContext, useState } from 'react';
import { View } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import { Project, type Task } from '../../../../../../graphql/api/API';
import { LoginUserContext } from '../../../../../../modules/auth/LoginUserContext';
import Button from '../../../../../presentational/atoms/button';
import Link from '../../../../../presentational/atoms/link';
import Typography, { TypographyType } from '../../../../../presentational/atoms/typography';
import CopyIcon from '../../../../../presentational/molecules/image-icon/copy';
import OpenLinkIcon from '../../../../../presentational/molecules/image-icon/open-in-new';
import Modal from '../../../../../presentational/molecules/modal';
import { IStyleTheme, type IThemePart } from '../../../../../theme';
import TaskInfoElement from '../../task-detail/task-info-element';

const Conatiner = styled.View`
  display: flex;
  flex-direction: column;
  padding: 10px 15px;G
  align-items: flex-start;
  z-index: 2; 
`;

const Row = styled.View`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: space-between;
`;

interface ITaskDetailCalendarInfoProps {
  task: Task;
}

const TaskDetailCalendarInfo = (props: ITaskDetailCalendarInfoProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, _] = useContext(LoginUserContext);
  const [showCopyUrlModal, setShowCopyUrlModal] = useState(false);
  return (
    <>
      <Conatiner>
        <Row>
          <TaskInfoElement
            title={'カレンダー連携コード'}
            style={{ flex: 1, maxWidth: 600 }}
            elementStyle={{ alignItems: 'center' }}>
            <View style={{ flexDirection: 'column' }}>
              <Typography
                variant={TypographyType.Normal}
                style={{
                  marginLeft: 10,
                  color: themeContext.colors.link,
                }}>
                {props.task.calendarLinkageCode}
              </Typography>
              <CopyIcon
                size={20}
                containerStyle={{ marginLeft: 5 }}
                onPress={async () => {
                  Clipboard.setString(props.task.calendarLinkageCode);
                  setShowCopyUrlModal(true);
                }}>
                <Typography variant={TypographyType.Normal} style={{ fontSize: 14 }}>
                  コピーする
                </Typography>
              </CopyIcon>
            </View>
            <Modal
              title={'連携コードをコピーしました'}
              isShow={showCopyUrlModal}
              onClose={() => {
                setShowCopyUrlModal(false);
              }}>
              <Typography variant={TypographyType.Normal} style={{ marginVertical: 10 }}>
                {`連携コードをコピーしました。${'\n'}Googleカレンダーの予定の説明欄に登録すると、${'\n'}作業履歴・作業予定を自動登録できるようになります。`}
              </Typography>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <OpenLinkIcon size={24} containerStyle={{ marginRight: 5 }} />
                <Link
                  path={
                    'https://manual.timedesigner.com/v1/google-7'
                  }
                  isExternalLink={true}>
                  <Typography
                    variant={TypographyType.Normal}
                    style={{ fontSize: 16, color: themeContext.colors.link }}>
                    {`使い方について確認する`}
                  </Typography>
                </Link>
              </View>
              <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                <Button
                  text={'OK'}
                  style={{ minWidth: 100, marginRight: 10, marginVertical: 20 }}
                  onPress={async () => {
                    setShowCopyUrlModal(false);
                  }}
                />
              </View>
            </Modal>
          </TaskInfoElement>
        </Row>
      </Conatiner>
    </>
  );
};

export default TaskDetailCalendarInfo;
