import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled, { ThemeContext } from 'styled-components/native';
import styledWeb from 'styled-components';
import { View, TouchableOpacity } from 'react-native';
import { IStyleTheme, IThemePart } from '../../../../../theme';
import VirtualizedFlatList from '../../../../../presentational/atoms/list2/virtualized-flat-list';
import Typography, { TypographyType } from '../../../../../presentational/atoms/typography';
import {
  JoinedTeamsWithoutPersonalTeamDocument,
  JoinedTeamsWithoutPersonalTeamQuery,
  Organization,
  OrganizationTeamsWithoutPersonalTeamDocument,
  Team,
  TeamMemberRelationsDocument,
  useJoinedTeamsWithoutPersonalTeamQuery,
  useJoinToTeamMutation,
  useOrganizationArchivedTeamsWithoutPersonalTeamQuery,
  useOrganizationTeamsWithoutPersonalTeamQuery,
  useRemoveFromTeamMutation,
  useUnarchiveTeamMutation,
} from '../../../../../../graphql/api/API';
import { LoginUserContext } from '../../../../../../modules/auth/LoginUserContext';
import { useHistory, useLocation } from 'react-router';
import Form from '../../../../../presentational/atoms/form';
import Input from '../../../../../presentational/atoms/input';
import Icon from '../../../../../presentational/atoms/icon';
import ColorUtil from '../../../../../../util/ColorUtil';
import Button from '../../../../../presentational/atoms/button';
import Modal from '../../../../../presentational/molecules/modal';
import TeamCreateDialog from '../../../organisms/team-create-dialog';
import CustomScrollView from '../../../../../presentational/atoms/custom-scroll-view';
import { parse } from 'query-string';
import HorizontalMenu from '../../../organisms/horizontal-menu';
import SelectButton from '../../../../../presentational/molecules/select-button';
import CheckIcon from '../../../../../presentational/molecules/image-icon/check';
import SearchIcon from '../../../../../presentational/molecules/image-icon/search';

const ParticipationStatus = styled.View`
  display: flex;
  flex-direction: row;
  align-item: center;
  justify-content: flex-start;
`;

const Search = styled.View``;

const TeamItem = styledWeb.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  border-bottom: 1px ${(props: IStyleTheme) => props.theme.colors.separator} solid;
  &:hover {
    background-color: rgb(244, 245, 247);
  }
`;

interface IConfirmLeaveTeamModalProps {
  team: Team;
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  onComplete: () => void;
  onCancel: () => void;
}

const ConfirmLeaveTeamModal = (props: IConfirmLeaveTeamModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, _] = useContext(LoginUserContext);
  const [removeMemberFromTeam] = useRemoveFromTeamMutation();

  return (
    <Modal
      isShow={props.showModal}
      onClose={() => props.setShowModal(false)}
      title={`${props.team.name}チームから抜けますか？`}>
      <Form>
        <Typography
          variant={TypographyType.Description}
          style={{ textAlign: 'center', marginTop: 10 }}>
          一度チームから抜けても、再度参加しなおすことが出来ます。
        </Typography>
        <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 20 }}>
          <Button
            text="チームから抜ける"
            completeText="チームから抜けました"
            style={{ height: 30, marginRight: 5 }}
            textStyle={{ fontSize: 14 }}
            onPress={async () => {
              await removeMemberFromTeam({
                variables: {
                  input: {
                    teamId: props.team.id!,
                    memberId: loginUser!.id,
                  },
                },
                refetchQueries: [
                  {
                    query: JoinedTeamsWithoutPersonalTeamDocument,
                    variables: {
                      organizationId: loginUser!.organizationId,
                      withArchivedTeam: true,
                    },
                  },
                  {
                    query: JoinedTeamsWithoutPersonalTeamDocument,
                    variables: {
                      organizationId: loginUser!.organizationId,
                      withArchivedTeam: false,
                    },
                  },
                  {
                    query: TeamMemberRelationsDocument,
                  },
                ],
              });
              props.onComplete();
            }}
          />
          <Button
            text="キャンセル"
            style={{ height: 30, backgroundColor: 'transparent' }}
            textStyle={{ fontSize: 14, color: themeContext.colors.primary }}
            onPress={async () => {
              props.onCancel();
            }}
            disableValidate={true}
          />
        </View>
      </Form>
    </Modal>
  );
};

interface IConfirmJoinTeamModalProps {
  team: Team;
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  onComplete: () => void;
  onCancel: () => void;
}

const ConfirmJoinTeamModal = (props: IConfirmJoinTeamModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, _] = useContext(LoginUserContext);
  const [joinMemberToTeam] = useJoinToTeamMutation();

  return (
    <Modal
      isShow={props.showModal}
      onClose={() => props.setShowModal(false)}
      title={`${props.team.name}チームに参加しますか？`}>
      <Form>
        <Typography
          variant={TypographyType.Description}
          style={{ textAlign: 'center', marginTop: 10 }}>
          チームに参加すると、このチームのタスクを作業することが出来ます。
        </Typography>
        <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 20 }}>
          <Button
            text="チームに参加する"
            completeText="チームに参加しました"
            style={{ height: 30, marginRight: 5 }}
            textStyle={{ fontSize: 14 }}
            onPress={async () => {
              await joinMemberToTeam({
                variables: {
                  input: {
                    teamId: props.team.id!,
                    memberId: loginUser!.id,
                  },
                },
                refetchQueries: [
                  {
                    query: JoinedTeamsWithoutPersonalTeamDocument,
                    variables: {
                      organizationId: loginUser!.organizationId,
                      withArchivedTeam: true,
                    },
                  },
                  {
                    query: JoinedTeamsWithoutPersonalTeamDocument,
                    variables: {
                      organizationId: loginUser!.organizationId,
                      withArchivedTeam: false,
                    },
                  },
                  {
                    query: TeamMemberRelationsDocument,
                  },
                ],
              });
              props.onComplete();
            }}
          />
          <Button
            text="キャンセル"
            style={{ height: 30, backgroundColor: 'transparent' }}
            textStyle={{ fontSize: 14, color: themeContext.colors.primary }}
            onPress={async () => {
              props.onCancel();
            }}
            disableValidate={true}
          />
        </View>
      </Form>
    </Modal>
  );
};

interface IConfirmMoveToTeamModalProps {
  team: Team;
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  onComplete: () => void;
  onCancel: () => void;
}

const ConfirmMoveToTeamModal = (props: IConfirmMoveToTeamModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, _] = useContext(LoginUserContext);
  const history = useHistory();

  return (
    <Modal
      isShow={props.showModal}
      onClose={() => props.setShowModal(false)}
      title={`${props.team.name}チームの内容を確認しますか？`}>
      <Form>
        <Typography
          variant={TypographyType.Description}
          style={{ textAlign: 'center', marginTop: 10 }}>
          チームの画面へ移動して、内容を確認することができます。
        </Typography>
        <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 20 }}>
          <Button
            text="確認する"
            style={{ height: 30, marginRight: 5 }}
            textStyle={{ fontSize: 14 }}
            onPress={async () => {
              history.push(`/app/${loginUser!.organizationId}/${props.team.id!}/projects/`);
              props.onComplete();
            }}
          />
          <Button
            text="キャンセル"
            style={{ height: 30, backgroundColor: 'transparent' }}
            textStyle={{ fontSize: 14, color: themeContext.colors.primary }}
            onPress={async () => {
              props.onCancel();
            }}
            disableValidate={true}
          />
        </View>
      </Form>
    </Modal>
  );
};

interface INotArchivedModalProps {
  team: Team;
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  onComplete: () => void;
  onCancel: () => void;
}

const NotArchivedModal = (props: INotArchivedModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, _] = useContext(LoginUserContext);
  const [unarchiveTeam] = useUnarchiveTeamMutation({
    variables: {
      id: props.team.id!,
      input: {
        versionNo: props.team.versionNo,
      },
    },
    refetchQueries: [
      {
        query: OrganizationTeamsWithoutPersonalTeamDocument,
        variables: {
          organizationId: loginUser!.organizationId,
        },
      },
      {
        query: JoinedTeamsWithoutPersonalTeamDocument,
        variables: {
          organizationId: loginUser!.organizationId,
          withArchivedTeam: true,
        },
      },
      {
        query: JoinedTeamsWithoutPersonalTeamDocument,
        variables: {
          organizationId: loginUser!.organizationId,
          withArchivedTeam: false,
        },
      },
      {
        query: TeamMemberRelationsDocument,
      },
    ],
  });

  return (
    <Modal
      isShow={props.showModal}
      onClose={() => props.setShowModal(false)}
      title={`${props.team.name}チームをアーカイブ解除しますか？`}>
      <Form>
        <Typography
          variant={TypographyType.Description}
          style={{ textAlign: 'center', marginTop: 10 }}>
          アーカイブ解除すると、チームを使用できるようになります。
        </Typography>
        <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 20 }}>
          <Button
            text="アーカイブ解除する"
            style={{ height: 30, marginRight: 5 }}
            textStyle={{ fontSize: 14 }}
            onPress={async () => {
              await unarchiveTeam();
              props.onComplete();
            }}
          />
          <Button
            text="キャンセル"
            style={{ height: 30, backgroundColor: 'transparent' }}
            textStyle={{ fontSize: 14, color: themeContext.colors.primary }}
            onPress={async () => {
              props.onCancel();
            }}
            disableValidate={true}
          />
        </View>
      </Form>
    </Modal>
  );
};

interface IProps {
  team: Team;
  joinedTeams: JoinedTeamsWithoutPersonalTeamQuery;
}

const ListItem: React.FC<IProps> = (props: IProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, _] = useContext(LoginUserContext);
  const [isHover, setHover] = useState(false);
  const [showConfirmJoinModal, setShowConfirmJoinModal] = useState(false);
  const [showConfirmLeaveModal, setShowConfirmLeaveModal] = useState(false);
  const [showConfirmMoveModal, setShowConfirmMoveModal] = useState(false);
  const [showNotArchiveMoveModal, setShowNotArchiveMoveModal] = useState(false);
  const isJoinedTeam = props.joinedTeams.joinedTeamsWithoutPersonalTeam!.includes(props.team);

  return (
    <TouchableOpacity
      onPress={() => {
        setShowConfirmMoveModal(true);
        setHover(false);
      }}>
      <TeamItem
        theme={themeContext}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}>
        <View>
          <Typography
            variant={TypographyType.Normal}
            style={{
              fontSize: 14,
              color: themeContext.colors.textColor,
              textAlign: 'left',
              lineHeight: 28,
            }}>
            {props.team.name}
          </Typography>
          <ParticipationStatus>
            {isJoinedTeam ? (
              <CheckIcon on={true} size={12}>
                <Typography
                  variant={TypographyType.Description}
                  style={{
                    fontSize: 12,
                    color: themeContext.colors.description,
                    textAlign: 'left',
                    lineHeight: 25,
                  }}>
                  {'参加済'}
                </Typography>
              </CheckIcon>
            ) : (
              <Typography
                variant={TypographyType.Description}
                style={{
                  fontSize: 12,
                  color: themeContext.colors.description,
                  textAlign: 'left',
                  lineHeight: 25,
                }}>
                {'未参加'}
              </Typography>
            )}
          </ParticipationStatus>
        </View>
        {isHover &&
          !props.team.archived &&
          (!isJoinedTeam ? (
            <Button
              text="チームに参加する"
              style={{ height: 30, marginRight: 5 }}
              textStyle={{ fontSize: 14 }}
              onPress={() => {
                setShowConfirmJoinModal(true);
                setHover(false);
              }}
            />
          ) : (
            <Button
              text="チームから抜ける"
              style={{ height: 30, backgroundColor: 'transparent' }}
              textStyle={{ fontSize: 14, color: themeContext.colors.primary }}
              onPress={() => {
                setShowConfirmLeaveModal(true);
                setHover(false);
              }}
              disableValidate={true}
            />
          ))}
        {isHover && props.team.archived && (
          <Button
            text="アーカイブ解除する"
            style={{ height: 30, marginRight: 5 }}
            textStyle={{ fontSize: 14 }}
            onPress={() => {
              setShowNotArchiveMoveModal(true);
              setHover(false);
            }}
          />
        )}
        <ConfirmJoinTeamModal
          team={props.team}
          showModal={showConfirmJoinModal}
          setShowModal={() => setShowConfirmJoinModal(false)}
          onComplete={() => setShowConfirmJoinModal(false)}
          onCancel={() => setShowConfirmJoinModal(false)}
        />
        <ConfirmLeaveTeamModal
          team={props.team}
          showModal={showConfirmLeaveModal}
          setShowModal={() => setShowConfirmLeaveModal(false)}
          onComplete={() => setShowConfirmLeaveModal(false)}
          onCancel={() => setShowConfirmLeaveModal(false)}
        />
        <ConfirmMoveToTeamModal
          team={props.team}
          showModal={showConfirmMoveModal}
          setShowModal={() => setShowConfirmMoveModal(false)}
          onComplete={() => setShowConfirmMoveModal(false)}
          onCancel={() => setShowConfirmMoveModal(false)}
        />
        <NotArchivedModal
          team={props.team}
          showModal={showNotArchiveMoveModal}
          setShowModal={() => setShowNotArchiveMoveModal(false)}
          onComplete={() => setShowNotArchiveMoveModal(false)}
          onCancel={() => setShowNotArchiveMoveModal(false)}
        />
      </TeamItem>
    </TouchableOpacity>
  );
};

const AppSearchTeamsPage = () => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const history = useHistory();
  const [loginUser, _] = useContext(LoginUserContext);
  const [searchTeamName, setSearchTeamName] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const [showMode, setShowMode] = useState<'notArchived' | 'archived'>('notArchived');
  const location = useLocation();
  const { loading: organizationTeamsLoading, data: organizationTeams } =
    useOrganizationTeamsWithoutPersonalTeamQuery({
      variables: {
        organizationId: loginUser!.organizationId,
      },
      fetchPolicy: 'network-only',
    });
  const { loading: organizationArchivedTeamsLoading, data: organizationArchivedTeams } =
    useOrganizationArchivedTeamsWithoutPersonalTeamQuery({
      variables: {
        organizationId: loginUser!.organizationId,
      },
      fetchPolicy: 'network-only',
    });
  const { loading: joinedTeamsLoading, data: joinedTeams } = useJoinedTeamsWithoutPersonalTeamQuery(
    {
      variables: {
        organizationId: loginUser!.organizationId,
        withArchivedTeam: true,
      },
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    const query = parse(location.search);
    setShowDialog(query.create === 'true');
  }, [location, searchTeamName]);

  const notArchivedTeams = useMemo(() => {
    if (organizationTeamsLoading || !organizationTeams?.organizationTeamsWithoutPersonalTeam) {
      return [];
    }
    return organizationTeams.organizationTeamsWithoutPersonalTeam
      .slice()
      .filter((team) => team!.name.indexOf(searchTeamName) !== -1);
  }, [
    organizationTeamsLoading,
    joinedTeamsLoading,
    organizationTeams?.organizationTeamsWithoutPersonalTeam,
    joinedTeams?.joinedTeamsWithoutPersonalTeam,
    searchTeamName,
  ]);

  const archivedTeams = useMemo(() => {
    if (
      organizationArchivedTeamsLoading ||
      !organizationArchivedTeams?.organizationArchivedTeamsWithoutPersonalTeam
    ) {
      return [];
    }
    return organizationArchivedTeams.organizationArchivedTeamsWithoutPersonalTeam
      .slice()
      .filter((team) => team!.name.indexOf(searchTeamName) !== -1);
  }, [
    organizationTeamsLoading,
    joinedTeamsLoading,
    organizationArchivedTeams?.organizationArchivedTeamsWithoutPersonalTeam,
    joinedTeams?.joinedTeamsWithoutPersonalTeam,
    searchTeamName,
  ]);

  if (organizationTeamsLoading || joinedTeamsLoading || organizationArchivedTeamsLoading) {
    return <></>;
  }

  return (
    <CustomScrollView style={{ height: 'calc(100vh - 57px)' }}>
      <Modal
        isShow={showDialog}
        title="チームを作成する"
        onClose={() => {
          setShowDialog(false);
          history.push(location.pathname);
        }}>
        <TeamCreateDialog
          onComplete={(newTeam) => {
            setShowDialog(false);
            history.push(`/app/${loginUser!.organizationId}/${newTeam.id}/projects/`);
          }}
          onCancel={() => {
            setShowDialog(false);
            history.push(location.pathname);
          }}
        />
      </Modal>
      <View
        style={{
          backgroundColor: themeContext.colors.header,
          paddingVertical: 7,
          paddingHorizontal: 3,
          zIndex: 1,
        }}>
        <Typography
          variant={TypographyType.SubTitle}
          style={{ color: '#FFFFFF', fontSize: 16, fontWeight: '600' }}>
          チームを探す
        </Typography>
      </View>
      <View style={{ marginHorizontal: 20, paddingTop: 5 }}>
        <Search style={{ borderBottomWidth: 1, borderColor: themeContext.colors.separator }}>
          <View
            style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
            <SelectButton
              values={[showMode]}
              containerStyle={{ paddingVertical: 2, marginTop: 10 }}
              contents={[
                {
                  key: 'notArchived',
                  content: '有効なチーム',
                },
                {
                  key: 'archived',
                  content: 'アーカイブ済みのチーム',
                },
              ]}
              onChange={(value) => {
                setShowMode(value[0] as any);
              }}
            />
          </View>
          <Form>
            <Input
              name={'searchTeam'}
              label={''}
              icon={<SearchIcon size={20} containerStyle={{ marginLeft: 10 }} />}
              containerStyle={{ marginVertical: 10 }}
              inputstyle={{ borderWidth: 0 }}
              inputContainerStyle={{
                margin: 5,
                backgroundColor: themeContext.colors.baseColor,
                shadowOffset: {
                  width: -1,
                  height: -1,
                },
                shadowOpacity: 0.1,
                elevation: 2,
                borderWidth: 1,
                borderRadius: 20,
                borderColor: themeContext.colors.separator,
              }}
              onChange={(value: string) => setSearchTeamName(value)}
            />
          </Form>
          {showMode === 'archived' ? (
            <VirtualizedFlatList
              style={{ height: '100%' }}
              items={archivedTeams}
              renderItem={(item, index) => {
                return <ListItem joinedTeams={joinedTeams!} team={item as Team} key={index} />;
              }}
              itemHeight={70}
              getKey={(item, index) => {
                return (item as Organization).id!;
              }}
            />
          ) : (
            <VirtualizedFlatList
              style={{ height: '100%' }}
              items={notArchivedTeams}
              renderItem={(item, index) => {
                return <ListItem joinedTeams={joinedTeams!} team={item as Team} key={index} />;
              }}
              itemHeight={70}
              getKey={(item, index) => {
                return (item as Organization).id!;
              }}
            />
          )}
        </Search>
      </View>
    </CustomScrollView>
  );
};

export default React.memo(AppSearchTeamsPage);
