import { ApolloProvider } from '@apollo/client';
import AppLoading from 'expo-app-loading';
import Constants from 'expo-constants';
import React, { useContext, useEffect, useState } from 'react';
import { HashRouter, Route, Switch, useHistory } from 'react-router-dom';
import * as Sentry from 'sentry-expo';
import ApolloClientFactory from '../../../graphql/client/ApolloClientFactory';
import { LoginUserContext } from '../../../modules/auth/LoginUserContext';
import LoginUtil from '../../../modules/auth/LoginUtil';
import type { IRouteItem } from '../../../routes/RouteItem';
import routes from '../../../routes/web';
import HashIdUtil from '../../../util/HashIdUtil';
import ErrorMessageModal from '../../container/web/organisms/error-message-modal';
import AccessDenyPage from '../../container/web/templates/access-deny';
import AccessDenyResetCompletePage from '../../container/web/templates/access-deny/complete';
import AccessDenyResetPage from '../../container/web/templates/access-deny/reset';
import SignupPage from '../../container/web/templates/signup';
import GaScript from '../../presentational/atoms/ga-script/GaAcript';
import Spinner from '../../presentational/atoms/spinner';

const SwitchRoute = () => {
  const { listen } = useHistory();

  useEffect(() => {
    const unlisten = listen((location) => {
      if (!(window as any).gtag) {
        return;
      }
      if (!Constants.manifest!.extra!.googleAnalyticsId) {
        return;
      }
      (window as any).gtag('config', Constants.manifest!.extra!.googleAnalyticsId, {
        page_path: location.pathname,
      });
    });
    return unlisten;
  }, [Constants.manifest!.extra!.googleAnalyticsId, listen]);

  return (
    <Switch>
      {routes.map((item: IRouteItem, key: number) => {
        return <Route path={item.path as any} component={item.component} key={key} />;
      })}
      <Route path={'/'} component={SignupPage} key={'-1'} />
    </Switch>
  );
};

interface IErrorMessageModal {
  message: string;
  showModal: boolean;
  onCloseModal: () => void;
}

const WebAppRoute = () => {
  const [isLoginChecked, setLoginChecked] = React.useState<boolean>(false);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [dummyLoading, setDummyLoading] = useState(true);
  const [showErrorModal, setShowErrorModal] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [reloadOnCloseErrorModal, setReloadOnCloseErrorModal] = React.useState(true);
  const [showIpAddressDenyScreen, setShowIpAddressDenyScreen] = React.useState(false);

  const loginWithCurrentStoreKey = async () => {
    const loggedIn = await LoginUtil.isLoggedIn();
    if (loggedIn) {
      const user = await LoginUtil.getLoginUser();
      setLoginUser(user);

      //Sentry解析用にユーザー情報をセット
      (Sentry as any).Browser.setUser({
        id: HashIdUtil.decord(user!.id),
      });
    }
    setLoginChecked(true);
    setDummyLoading(false);
  };

  if (!isLoginChecked || (showErrorModal && reloadOnCloseErrorModal)) {
    return (
      <>
      <AppLoading
        startAsync={loginWithCurrentStoreKey}
        onFinish={() => {
          setLoginChecked(true);
          setDummyLoading(false);
        }}
        onError={() => {}}
      />
      <ErrorMessageModal
          showModal={showErrorModal}
          message={errorMessage ?? `エラーが発生しました。${'\n'}申し訳ございませんが、画面を再読み込みします。`}
          onCloseModal={() => {
            if (reloadOnCloseErrorModal) {
              setLoginChecked(false);
              setDummyLoading(true);
              window.location.reload();
            }
          }}
        />
      </>
    );
  }

  return (
    <>
      <GaScript />
      <Spinner loading={dummyLoading} lazyMilliSeconds={500}>
        <ApolloProvider
          client={ApolloClientFactory.create(
            () => {
              LoginUtil.logout();
            },
            (message, reloadOnCloseError) => {
              try {
                if(showErrorModal){
                  return;
                }
                if(setErrorMessage){
                  setErrorMessage(message);
                }
                setShowErrorModal(true);
                if (reloadOnCloseError === true || reloadOnCloseError === false) {
                  setReloadOnCloseErrorModal(reloadOnCloseError);
                }
              } catch (_e) {
                //NOP
              }
            },
            () => {
              if(setErrorMessage){
                setErrorMessage(`アプリケーションが更新されました。${'\n'}画面を再読み込みします`);
              }
              setShowErrorModal(true);
            },
            () => {
              if(setShowIpAddressDenyScreen){
                setShowIpAddressDenyScreen(true);
              }
              window.location.hash = `/app/${loginUser?.organizationId}/acl`;
            },
            undefined,
            undefined,
            loginUser        
          )}>
          <HashRouter>
            {showIpAddressDenyScreen ? (
              <Switch>
                <Route
                  path={'/app/:organizationId/reset-acl-complete'}
                  component={AccessDenyResetCompletePage}
                />
                <Route path={'/app/:organizationId/reset-acl'} component={AccessDenyResetPage} />;
                <Route path={'/app/:organizationId/acl'} component={AccessDenyPage} />;
                <Route path={'/'} component={AccessDenyPage} />;
              </Switch>
            ) : (
              <SwitchRoute />
            )}
          </HashRouter>
        </ApolloProvider>
        <ErrorMessageModal
          showModal={showErrorModal}
          message={errorMessage}
          onCloseModal={() => {
            setShowErrorModal(false);
            setErrorMessage('');
            if (reloadOnCloseErrorModal) {
              window.location.reload();
            }
          }}
        />
      </Spinner>
    </>
  );
};

export default WebAppRoute;
