import React, { useContext, useState, useEffect, useRef, useCallback, useMemo } from 'react';
import {
  TextInputProps,
  TextStyle,
  View,
  ViewStyle,
  TouchableOpacity,
  Text,
  NativeSyntheticEvent,
  TextInputKeyPressEventData,
} from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import { ValidationRules } from 'react-hook-form';
import { IStyleTheme, IThemePart } from '../../../theme';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import FormContext from '../form/context';
import ColorUtil from '../../../../util/ColorUtil';
import Typography, { TypographyType } from '../typography';
import Avatar from '../avatar';
import {
  EditorState,
  convertFromRaw,
  convertToRaw,
  CompositeDecorator,
  ContentBlock,
  ContentState,
} from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createToolbarPlugin from '@draft-js-plugins/static-toolbar';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  CodeButton,
  UnorderedListButton,
  OrderedListButton,
  BlockquoteButton,
  CodeBlockButton,
} from '@draft-js-plugins/buttons';
import createEmojiPlugin from '@draft-js-plugins/emoji';
import 'draft-js/dist/Draft.css';
import './static-toolbar.css';
import './emoji.css';
import './editor.css';
import './mention.css';
import createMentionPlugin, {
  defaultSuggestionsFilter,
  defaultTheme,
  MentionData,
} from '@draft-js-plugins/mention';
import _ from 'lodash';

const toolbarPlugin = createToolbarPlugin();
const { Toolbar } = toolbarPlugin;
const emojiPlugin = createEmojiPlugin();
const { EmojiSuggestions, EmojiSelect } = emojiPlugin;

export interface ListValueMap {
  label: string;
  value: any;
  imageUrl?: string;
}

interface IProps extends TextInputProps {
  type?: 'text' | 'number' | 'password' | 'picker' | 'rich-text-editor';
  ref?: any;
  zIndexAdjustRef?: any;
  name: string;
  label?: string;
  placeholder?: string;
  description?: string;
  icon?: React.ReactElement<any, any>;
  ghostSuffix?: string;
  ghostSuffixStyle?: ViewStyle;
  containerStyle?: ViewStyle;
  inputContainerStyle?: ViewStyle;
  inputstyle?: TextStyle;
  validate?: ValidationRules;
  asyncValidate?: (value: string) => Promise<string | null>;
  autoComplete?:
    | 'cc-csc'
    | 'cc-exp'
    | 'cc-exp-month'
    | 'cc-exp-year'
    | 'cc-number'
    | 'email'
    | 'name'
    | 'password'
    | 'postal-code'
    | 'street-address'
    | 'tel'
    | 'username'
    | 'off';
  initialValue?: string | number;
  value?: string | number;
  pickerItems?: ListValueMap[];
  isSearchable?: boolean;
  isCreatable?: boolean;
  onChange?: (value: string) => void;
  onBlur?: (value: string, textInputElement: any) => void;
  onPressEnter?: (value: string) => void;
  onPressEnterIncludeIMEConvert?: (value: string) => void;
  onFocus?: () => void;
  onPaste?: (value: string) => void;
  focus?: boolean;
  defaultMenuIsOpen?: boolean;
  clearValueOnBlur?: boolean;
  allowContinuousInput?: boolean;
  additionalErrorMessage?: string | null;
  showToolBarAlways?: boolean;
  readonly?: boolean;
  autoFocus?: boolean;
  mentions?: MentionData[];
}

interface IContainerProps extends IStyleTheme {
  existLabel: boolean;
}

const Container = styled.View<IContainerProps>`
  margin: ${(props: IContainerProps) => (props.existLabel ? '1rem 0' : '0')};
  width: 100%;
`;

interface ILabelProps extends IStyleTheme {
  isFloat: boolean;
}

const Label = styled.Text<ILabelProps>`
  position: absolute;
  left: ${(props: ILabelProps) => (props.isFloat ? 0 : 0)};
  top: ${(props: ILabelProps) => (props.isFloat ? '-1.5rem' : 0)};
  font-size: ${(props: ILabelProps) => (props.isFloat ? '0.7rem' : '1rem')};
  color: ${(props: ILabelProps) => props.theme.colors.label};
  transition: all 0.2s;
`;

export const ErrorMessage = styled.Text<IStyleTheme>`
  font-size: 0.9rem;
  color: ${(props: IStyleTheme) => props.theme.colors.error};
  text-align: left;
`;

interface IGhostSuffixProps extends IStyleTheme {
  label: string;
  value: string;
}

const GhostSuffix = styled.Text<IGhostSuffixProps>`
  postion: absolute;
  font-size: 1rem;
  color: ${(props: IGhostSuffixProps) => props.theme.colors.description};
  text-align: left;
`;

const Description = styled.Text<IStyleTheme>`
  font-size: 0.9rem;
  color: ${(props: IGhostSuffixProps) => props.theme.colors.description};
  text-align: left;
`;

interface IInputProps extends IStyleTheme {
  multiline?: boolean;
}

const InputText = styled.TextInput<IInputProps>`
  height: 1rem,
  fontSize: 1rem,
  color: ${(props: IInputProps) => props.theme.colors.input};
  border-bottom-color: '#555';
  outlineWidth: 0;
  border-bottom-width: ${(props: IInputProps) => (props.multiline ? '0' : '1px')};
  padding: 0.3rem;
  width: 100%;
`;

export function generateEmptyRichTextEditorContent() {
  return JSON.stringify(convertToRaw(EditorState.createEmpty().getCurrentContent()));
}

const Input: React.FC<IProps> = (props: IProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [text, setText] = React.useState<string | number>('');
  const [dirty, setDirty] = useState(false);
  const [isFocused, setFocused] = useState(false);
  const [inputHeight, setInputHeight] = useState(0);
  const form = useContext(FormContext);
  const [showToolBar, setShowToolBar] = useState(false);
  const [editorState, setEditorState] = React.useState(() => EditorState.createEmpty());
  const editorRef = useRef();

  const [open, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState(props.mentions || []);
  const renderLink = useCallback((props: any) => {
    return (
      <TouchableOpacity
        style={{ display: 'inline' } as any}
        onPress={() => {
          window.open(props.href, '_blank');
        }}>
        <Text style={{ color: '#2790f3' }}>{props.href}</Text>
      </TouchableOpacity>
    );
  }, []);
  const { plugins, MentionSuggestions, mentionDecorators } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      entityMutability: 'IMMUTABLE',
      mentionPrefix: '@',
      mentionTrigger: ['@', '＠'],
      supportWhitespace: true,
      theme: defaultTheme,
    });
    const { MentionSuggestions, decorators: mentionDecorators } = mentionPlugin;
    const linkifyPlugin = createLinkifyPlugin({
      component: renderLink,
    });
    return { plugins: [linkifyPlugin, mentionPlugin], MentionSuggestions, mentionDecorators };
  }, [createMentionPlugin, createLinkifyPlugin, renderLink]);

  const decorators = _.flattenDeep(plugins.map((plugin) => plugin.decorators));
  const decorator = new CompositeDecorator(
    decorators.filter((decorator, index) => index !== 1).concat(mentionDecorators)
  );

  const onOpenChange = useCallback((_open: boolean) => {
    setOpen(_open);
    if (props.zIndexAdjustRef?.current) {
      props.zIndexAdjustRef.current.style.zIndex = 10000;
    }
  }, []);
  const onSearchChange = useCallback(({ trigger, value }: { trigger: string; value: string }) => {
    setSuggestions(defaultSuggestionsFilter(value, props.mentions || [], trigger));
  }, []);

  useEffect(() => {
    const richTextEditorRef = (editorRef as any)?.current?.editor;
    if (richTextEditorRef) {
      EditorState.moveFocusToEnd(editorState);
      if (props.focus !== false) {
        richTextEditorRef.focus();
      }
    }
  }, [(editorRef as any)?.current?.editor]);

  useEffect(() => {
    setText(props.value || '');
  }, [props.value]);

  useEffect(() => {
    setShowToolBar(props.showToolBarAlways || false);
  }, [props.showToolBarAlways]);

  // バリデーションを使えるように設定
  const { mode } = props as any;
  let textInputRef: any = null;
  let textInputElement: any = null;
  const setTextInputRef = (element: any) => {
    textInputElement = element;
    textInputRef = form.register({ name: props.name }, props.validate || {});
  };

  // 初期値の指定がある場合には、初期値を設定
  useEffect(() => {
    if (props.initialValue === 0 || props.initialValue || props.initialValue === '') {
      setText(props.initialValue);
      form.setValue(props.name, props.initialValue, { shouldDirty: true, shouldValidate: true });
    }
    if (!props.initialValue) {
      setInputHeight(0);
    }
  }, [props.initialValue]);

  useEffect(() => {
    if (props.type === 'rich-text-editor') {
      if (props.initialValue) {
        const value = convertFromRaw(JSON.parse(props.initialValue.toString()));
        const state = EditorState.createWithContent(value, decorator);
        setEditorState(state);
      } else {
        const state = EditorState.createEmpty(decorator);
        setEditorState(state);
      }
    }
  }, [props.initialValue]);

  // 追加のエラーメッセージがある場合にはセットする
  // TODO ここでエラーメッセージをセットしても、Submitはできてしまう。エラーがあったらSubmitもできないように改善したい
  useEffect(() => {
    if (props.additionalErrorMessage) {
      form.setError(props.name, {
        type: 'manual',
        message: props.additionalErrorMessage,
      });
    }
  }, [props.additionalErrorMessage]);

  useEffect(() => {
    if (textInputElement && props.focus === true) {
      setFocused(true);
      textInputElement?.focus();
    }
  }, [textInputElement, props.focus]);

  // useEffect(() => {
  //   if (props.type === 'rich-text-editor') {
  //     if (isFocused) {
  //       window.onbeforeunload = (e: any) => {
  //         e.returnValue = '行った変更が保存されない可能性があります。よろしいですか？';
  //       };
  //     } else {
  //       window.onbeforeunload = null;
  //     }
  //   }
  // }, [isFocused]);

  const clickDocument = (e: any) => {
    if (
      textInputElement &&
      typeof textInputElement?.contains == 'function' &&
      textInputElement?.contains(e.target)
    ) {
      if (props.onPaste) {
        if (e.clipboardData.getData('Text')?.includes('\n')) {
          props.onPaste(e.clipboardData.getData('Text'));
        }
      }
      return;
    }
  };
  useEffect(() => {
    window.addEventListener('paste', clickDocument);
    return () => {
      window.removeEventListener('paste', clickDocument);
    };
  }, [clickDocument]);

  return (
    <Container style={props.containerStyle as any} existLabel={!!props.label}>
      {props.label !== null && props.label !== undefined && (
        <Label
          isFloat={isFocused || String(text).length > 0 || props.type === 'picker'}
          onPress={() => {
            setFocused(true);
            textInputElement.focus();
          }}>
          {props.label}
        </Label>
      )}
      {(props.type === 'text' ||
        props.type === 'password' ||
        props.type === 'number' ||
        props.type === null ||
        props.type === undefined) && (
        <View
          style={[
            {
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            },
            props.inputContainerStyle,
          ]}>
          {props.icon && props.icon}
          <InputText
            multiline={props.multiline}
            ref={setTextInputRef}
            editable={props.readonly !== true}
            secureTextEntry={props.type === 'password'}
            autoCompleteType={props.autoComplete || 'off'}
            autoFocus={props.autoFocus}
            value={text.toString()}
            placeholder={props.placeholder}
            placeholderTextColor={themeContext.colors.description}
            style={
              [
                props.inputstyle,
                props.multiline ? { height: Math.max(inputHeight, 50) } : {},
              ] as any
            }
            onChangeText={(newText: string) => {
              setDirty(true);
              setText(newText);
              if (props.onChange) {
                props.onChange(newText.trim());
              }
              if (mode === 'onChange') {
                form.setValue(props.name, newText.trim(), {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              } else {
                form.setValue(props.name, newText.trim(), {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }
            }}
            onFocus={() => {
              setFocused(true);
              if (props.onFocus) {
                props.onFocus();
              }
            }}
            onBlur={async () => {
              setFocused(false);
              if (mode === 'onBlur') {
                form.setValue(props.name, text, { shouldDirty: true, shouldValidate: true });
              }
              setDirty(false);
              if (props.onBlur) {
                const asyncError = props.asyncValidate
                  ? await props.asyncValidate(text?.toString())
                  : null;

                //Note: ここではあえてuseStateのtextではなく、react-form-hooksのformの値を使用している。
                //      useStateの値を使うと、以下のケースで問題が発生した。
                //      ・Input要素で値を書き込む
                //      ・Escapeキーを押下する
                //      上記の操作をした場合の期待値としては、Escapeキー押下時にstateを空文字に更新しているので、onBlurで空文字が渡されることなのだが、実際には空文字ではなく、入力した文字列が来てしまった。
                //      タイミングの問題＋onKeyPressでRefから直接blurを呼んでいることから、stateが更新されずにonBlurが発動してしまう模様。
                //      formのvalueはちゃんと更新されているので、そちらを使うようにして、暫定回避している
                if (Object.keys(form.errors).length > 0 || asyncError !== null) {
                  if (asyncError !== null) {
                    form.setError(props.name, {
                      type: 'manual',
                      message: asyncError,
                    });
                  } else {
                    props.onBlur((props.initialValue as string) || '', textInputElement);
                  }
                } else {
                  props.onBlur(form.getValues(props.name) || '', textInputElement);
                }
              }
              if (props.clearValueOnBlur === true) {
                setText('');
                form.setValue(props.name, '', { shouldDirty: true, shouldValidate: false });
              }
            }}
            onContentSizeChange={(event: any) => {
              setInputHeight(event.nativeEvent.contentSize.height);
            }}
            onKeyPress={async (event: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
              switch (event.nativeEvent.key) {
                case 'Enter':
                  if ((event.nativeEvent as any).keyCode !== 13) {
                    if (props.onPressEnterIncludeIMEConvert) {
                      props.onPressEnterIncludeIMEConvert(text.toString());
                    }
                    // 日本語入力等のIME確定のためのEnterは13以外になる。本当の確定は13になるので、keycodeが13の時のみ入力を確定させる扱いにする
                    break;
                  }
                  // 通常だとEnterキーを押下すると、Input要素からフォーカスが外れてしまう。
                  // フォーカスを外したくないケースが多いため、以下でフォーカスが外れるのを防いでいる。
                  // 例えば、プロジェクトやタスクを名前で検索する際に、Enterキーを押下するとフォーカスが外れてしまい、別の名前で検索しようとすると、マウスでInput要素をクリックしてフォーカスを合わせ直す必要があり不便になる。
                  event.preventDefault();

                  setText(text);
                  form.setValue(props.name, text, { shouldDirty: true, shouldValidate: true });
                  setDirty(false);

                  if (props.onChange) {
                    props.onChange(text.toString());
                  }

                  if (props.onPressEnter) {
                    props.onPressEnter(text.toString());
                  }
                  if (props.onPressEnterIncludeIMEConvert) {
                    props.onPressEnterIncludeIMEConvert(text.toString());
                  }

                  if (props.allowContinuousInput === true && text.toString().trim().length > 0) {
                    event.preventDefault();
                    setText('');
                    form.setValue(props.name, '', { shouldDirty: true, shouldValidate: false });
                    setFocused(false);
                    setDirty(false);
                  }
                  break;
                case 'Escape':
                  setText(props.initialValue || '');
                  form.setValue(props.name, props.initialValue || '', {
                    shouldDirty: true,
                    shouldValidate: false,
                  });
                  textInputElement.blur();
                  setFocused(false);
                  setDirty(false);
                  break;
                default:
                  break;
              }
            }}
          />
          {props.ghostSuffix && (
            <GhostSuffix
              label={props.label || ''}
              value={text.toString()}
              style={props.ghostSuffixStyle}>
              {props.ghostSuffix}
            </GhostSuffix>
          )}
        </View>
      )}
      {props.type === 'picker' && (
        //TODO Pickerは現状はWeb版しか実装していない
        //TODO 複数選択に対応していない
        <View style={{ zIndex: 11000 }}>
          {props.isCreatable ? (
            <CreatableSelect
              ref={setTextInputRef}
              styles={{
                input: (styles) => {
                  return styles;
                },
                option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                  return {
                    ...styles,
                    backgroundColor: isSelected
                      ? ColorUtil.lignten(themeContext.colors.primary, 20)
                      : isFocused
                      ? ColorUtil.lignten(themeContext.colors.primary, 10)
                      : themeContext.colors.baseColor,
                    color: isSelected || isFocused ? '#FFFFFF' : themeContext.colors.textColor,
                    cursor: isDisabled ? 'not-allowed' : 'default',
                  };
                },
                menuPortal: (provided) => ({ ...provided, zIndex: 12000 }),
                menu: (provided) => ({ ...provided, zIndex: 12000 }),
              }}
              components={{
                Option: (props) => {
                  return (
                    <View
                      ref={props.innerRef}
                      {...props.innerProps}
                      style={
                        {
                          backgroundColor: props.isFocused
                            ? themeContext.colors.subHeader
                            : props.isSelected
                            ? themeContext.colors.header
                            : themeContext.colors.baseColor,
                          height: 34,
                          paddingVertical: 10,
                          paddingHorizontal: 10,
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          flexDirection: 'row',
                          cursor: 'pointer',
                        } as any
                      }>
                      {props.data.imageUrl && (
                        <Avatar
                          size={28}
                          name={props.data.label}
                          imageUrl={props.data.imageUrl}
                          containerStyle={{ marginRight: 10 }}
                        />
                      )}
                      <Typography
                        variant={TypographyType.Normal}
                        ellipsis={true}
                        style={{
                          fontSize: 16,
                          color: props.isFocused
                            ? themeContext.colors.baseColor
                            : props.isSelected
                            ? themeContext.colors.baseColor
                            : themeContext.colors.textColor,
                        }}>
                        {props.children}
                      </Typography>
                    </View>
                  );
                },
              }}
              isMulti={false}
              menuPortalTarget={document.body} //TODO スマホアプリだと動かないかも
              menuPosition={'fixed'}
              placeholder={isFocused ? '' : props.label ?? props.placeholder}
              options={props.pickerItems || []}
              noOptionsMessage={() => '選択可能なものがありません'}
              defaultMenuIsOpen={props.defaultMenuIsOpen || false} //defaultMenuIsOpenを有効にすると、初期表示時に前回選択していた値が、初期選択値になっていない問題がある。　https://github.com/JedWatson/react-select/issues/4013
              isSearchable={props.isSearchable || false}
              value={props.pickerItems?.filter((item) => item?.value === text) as any} //item.valueではなく、item?.valueにしないと、エラーが出るケースがある
              defaultValue={
                props.pickerItems?.filter((item) => item?.value === props.initialValue) as any
              }
              formatCreateLabel={(value) => (value ? `新規作成「${value}」` : '')}
              onChange={(item) => {
                setDirty(true);
                setText((item as ListValueMap).value);
                if (props.onChange) {
                  props.onChange((item as ListValueMap).value);
                }
                if (mode === 'onChange') {
                  form.setValue(props.name, (item as ListValueMap).value, {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                } else {
                  form.setValue(props.name, (item as ListValueMap).value, {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }
              }}
              onFocus={() => {
                setFocused(true);
                if (props.onFocus) {
                  props.onFocus();
                }
              }}
              onBlur={() => {
                setFocused(false);
                if (mode === 'onBlur') {
                  form.setValue(props.name, text, { shouldDirty: true, shouldValidate: true });
                }
                setDirty(false);
                if (props.onBlur) {
                  props.onBlur(text.toString(), textInputElement);
                }
              }}
              onKeyDown={(event) => {
                switch (event.nativeEvent.key) {
                  case 'Enter':
                    setDirty(false);
                    if (props.onPressEnter && !props.onBlur) {
                      props.onPressEnter(text.toString());
                    }
                    break;
                  case 'Escape':
                    setFocused(false);
                    setDirty(false);
                    textInputElement?.blur();
                    break;
                  default:
                    break;
                }
              }}
            />
          ) : (
            <Select
              ref={setTextInputRef}
              styles={{
                input: (styles) => {
                  return styles;
                },
                option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                  return {
                    ...styles,
                    backgroundColor: isSelected
                      ? ColorUtil.lignten(themeContext.colors.primary, 20)
                      : isFocused
                      ? ColorUtil.lignten(themeContext.colors.primary, 10)
                      : themeContext.colors.baseColor,
                    color: isSelected || isFocused ? '#FFFFFF' : themeContext.colors.textColor,
                    cursor: isDisabled ? 'not-allowed' : 'default',
                  };
                },
                menuPortal: (provided) => ({ ...provided, zIndex: 12000 }),
                menu: (provided) => ({ ...provided, zIndex: 12000 }),
              }}
              components={{
                Option: (props) => {
                  return (
                    <View
                      ref={props.innerRef}
                      {...props.innerProps}
                      style={
                        {
                          backgroundColor: props.isFocused
                            ? themeContext.colors.subHeader
                            : props.isSelected
                            ? themeContext.colors.header
                            : themeContext.colors.baseColor,
                          height: 34,
                          paddingVertical: 10,
                          paddingHorizontal: 10,
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          flexDirection: 'row',
                          cursor: 'pointer',
                        } as any
                      }>
                      {props.data.imageUrl && (
                        <Avatar
                          size={28}
                          name={props.data.label}
                          imageUrl={props.data.imageUrl}
                          containerStyle={{ marginRight: 10 }}
                        />
                      )}
                      <Typography
                        variant={TypographyType.Normal}
                        ellipsis={true}
                        style={{
                          fontSize: 16,
                          color: props.isFocused
                            ? themeContext.colors.baseColor
                            : props.isSelected
                            ? themeContext.colors.baseColor
                            : themeContext.colors.textColor,
                        }}>
                        {props.children}
                      </Typography>
                    </View>
                  );
                },
              }}
              isMulti={false}
              menuPortalTarget={document.body} //TODO スマホアプリだと動かないかも
              menuPosition={'fixed'}
              value={props.pickerItems?.filter((item) => item?.value === text) as any} //item.valueではなく、item?.valueにしないと、エラーが出るケースがある
              defaultValue={
                props.pickerItems?.filter((item) => item?.value === props.initialValue) as any
              }
              placeholder={isFocused ? '' : props.label ?? props.placeholder}
              options={props.pickerItems || []}
              noOptionsMessage={() => '選択可能なものがありません'}
              defaultMenuIsOpen={props.defaultMenuIsOpen || false} //defaultMenuIsOpenを有効にすると、初期表示時に前回選択していた値が、初期選択値になっていない問題がある。　https://github.com/JedWatson/react-select/issues/4013
              isSearchable={props.isSearchable || false}
              onChange={(item) => {
                setDirty(true);
                setText((item as ListValueMap).value);
                if (props.onChange) {
                  props.onChange((item as ListValueMap).value);
                }
                if (mode === 'onChange') {
                  form.setValue(props.name, (item as ListValueMap).value, {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                } else {
                  form.setValue(props.name, (item as ListValueMap).value, {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }
              }}
              onFocus={() => {
                setFocused(true);
                if (props.onFocus) {
                  props.onFocus();
                }
              }}
              onBlur={() => {
                setFocused(false);
                if (mode === 'onBlur') {
                  form.setValue(props.name, text, { shouldDirty: true, shouldValidate: true });
                }
                setDirty(false);
                if (props.onBlur) {
                  props.onBlur(text.toString(), textInputElement);
                }
              }}
              onKeyDown={(event) => {
                switch (event.nativeEvent.key) {
                  case 'Enter':
                    setDirty(false);
                    if (props.onPressEnter && !props.onBlur) {
                      props.onPressEnter(text.toString());
                    }
                    break;
                  case 'Escape':
                    setFocused(false);
                    setDirty(false);
                    textInputElement?.blur();
                    break;
                  default:
                    break;
                }
              }}
            />
          )}
        </View>
      )}
      {props.type === 'rich-text-editor' && (
        <div className={props.readonly ? 'readonly' : ''}>
          <div>
            <Editor
              ref={editorRef as any}
              plugins={plugins.concat([toolbarPlugin, emojiPlugin])}
              editorState={editorState}
              readOnly={props.readonly}
              onChange={(value) => {
                setEditorState(value);
                if (props.onChange) {
                  const rawValue = convertToRaw(editorState.getCurrentContent());
                  props.onChange(JSON.stringify(rawValue));
                }
              }}
              onFocus={() => {
                setShowToolBar(true);
                if (props.onFocus) {
                  props.onFocus();
                }
              }}
              onBlur={() => {
                setShowToolBar(false);
                if (props.onBlur) {
                  const rawValue = convertToRaw(editorState.getCurrentContent());
                  props.onBlur(JSON.stringify(rawValue), null);
                }
              }}
              onEscape={() => {
                textInputElement?.blur();
              }}
              blockStyleFn={(contentBlock) => {
                const type = contentBlock.getType();
                if (type === 'blockquote') {
                  return 'timeDesignerBlockquote';
                }
                return type;
              }}
            />
            <MentionSuggestions
              open={open}
              onOpenChange={onOpenChange}
              suggestions={suggestions}
              onSearchChange={onSearchChange}
              onAddMention={() => {
                // get the mention object selected
              }}
            />
            {((props.readonly !== true && props.showToolBarAlways) || showToolBar) && (
              <>
                <Toolbar>
                  {(externalProps) => (
                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                      <BoldButton {...externalProps} />
                      <ItalicButton {...externalProps} />
                      <UnderlineButton {...externalProps} />
                      <UnorderedListButton {...externalProps} />
                      <OrderedListButton {...externalProps} />
                      <BlockquoteButton {...externalProps} />
                    </View>
                  )}
                </Toolbar>
              </>
            )}
          </div>
        </div>
      )}
      {form.errors[props.name] && <ErrorMessage>{form.errors[props.name].message}</ErrorMessage>}
      {(form.errors[props.name] === null || form.errors[props.name] === undefined) &&
        props.description && <Description>{props.description}</Description>}
    </Container>
  );
};

export default Input;
