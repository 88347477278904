import React, { useContext, useEffect, useState } from 'react';
import { TextStyle, View } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../../theme';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import DateUtil from '../../../../../util/DateUtil';
import TimeUtil from '../../../../../util/TimeUtil';
import Avatar from '../../../../presentational/atoms/avatar';
import {
  LatestWorkingHistoryDocument,
  Member,
  Organization,
  Plan,
  Priority,
  Project,
  ProjectWorkingTimeSecDocument,
  Task,
  TaskDocument,
  TaskWorkingHistorySummaryGroupByAssineeDocument,
  useCancelFavoriteProjectMutation,
  useCancelFavoriteTaskMutation,
  useCompleteTaskMutation,
  useFavoriteProjectMutation,
  useFavoriteTaskMutation,
  useIncompleteTaskMutation,
  useStartTaskMutation,
  useStopTaskMutation,
} from '../../../../../graphql/api/API';
import TaskUtil from '../../../../../util/TaskUtil';
import useInterval from 'use-interval';
import when from '../../../../../lang-extention/When';
import ColorUtil from '../../../../../util/ColorUtil';
import { TaskProgressBar } from '../task-progress-bar';
import useDimensions from 'use-dimensions';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import PlayIcon from '../../../../presentational/molecules/image-icon/play';
import StopIcon from '../../../../presentational/molecules/image-icon/stop';
import CheckIcon from '../../../../presentational/molecules/image-icon/check';
import StarIcon from '../../../../presentational/molecules/image-icon/star';
import ErrorMessageModal from '../error-message-modal';
import OrganizationUtil from '../../../../../util/OrganizationUtil';

interface IContainerProps extends IStyleTheme {
  isComplete: boolean;
  isTracking: boolean;
}

const Container = styled.View<IContainerProps>`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 6px;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.4s;
  z-index: 100;
  border-width: 1px;
  border-color: ${(props: IContainerProps) => props.theme.colors.separator};
  background-color: ${(props: IContainerProps) =>
    props.isTracking
      ? '#FFFFF0'
      : props.isComplete
      ? ColorUtil.lignten(props.theme.colors.separator, 3)
      : props.theme.colors.baseColor};
`;

interface IMainProps extends IStyleTheme {
  showClientName: boolean;
}

const Main = styled.View<IMainProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  padding-bottom: 3px;
  overflow: hidden;
  min-height: 45px;
`;

interface IProjectWorkingTimeProps {
  project: Project;
  style?: TextStyle;
}

export const ProjectWorkingTime = (props: IProjectWorkingTimeProps) => {
  return (
    <Typography variant={TypographyType.Normal} style={props.style}>
      {TimeUtil.formatForTask(props.project.workingTimeSec)}
    </Typography>
  );
};

interface IProps {
  project: Project;
  organization: Organization;
  me: Member;
  showTeamName: boolean;
  showClientName?: boolean;
  showFavorite?: boolean;
  showAssigner?: boolean;
}

const ProjectSummary = (props: IProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const { window } = useDimensions();
  const windowWidth = window.width;
  const [loginUser, _] = useContext(LoginUserContext);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const [favoriteProject] = useFavoriteProjectMutation({
    variables: {
      id: props.project.id!,
      input: {
        versionNo: props.project.versionNo,
      },
    },
  });
  const [cancelFavoriteProject] = useCancelFavoriteProjectMutation({
    variables: {
      id: props.project.id!,
      input: {
        versionNo: props.project.versionNo,
      },
    },
  });
  return (
    <Container isComplete={props.project.completeDateTime !== null}>
      <Main showClientName={props.showClientName}>
        <View style={{ flexDirection: 'row', flex: 1, minWidth: 67, alignItems: 'center' }}>
          {props.showTeamName && windowWidth >= 1550 && (
            <View
              style={{
                maxWidth: 140,
                minWidth: 140,
                marginRight: 10,
                justifyContent: 'flex-start',
              }}>
              <Typography
                variant={TypographyType.Normal}
                ellipsis={true}
                style={{
                  fontSize: 12,
                  paddingHorizontal: 5,
                  textAlign: 'left',
                }}>
                {props.project.team.name}
              </Typography>
            </View>
          )}
          <View
            style={{
              flex: 1,
              marginRight: 10,
              justifyContent: 'flex-start',
            }}>
            <View style={{ flexDirection: 'column' }}>
              {props.project.client && (
                <View>
                  <Typography
                    variant={TypographyType.Description}
                    ellipsis={true}
                    style={{
                      fontSize: 11,
                      minWidth: 30,
                      textAlign: 'left',
                    }}>
                    {props.project.client.name}
                  </Typography>
                </View>
              )}
              <View>
                <Typography
                  variant={TypographyType.Normal}
                  ellipsis={true}
                  tooltipWhenEllipsis={true}
                  tooltipId={`project-${props.project.id}`}
                  style={
                    [
                      {
                        fontSize: 14,
                        minWidth: 30,
                        textAlign: 'left',
                      },
                      props.project.completeDateTime !== null
                        ? {
                            color: themeContext.colors.description,
                            textDecorationLine: 'line-through',
                            textDecorationColor: themeContext.colors.textColor,
                          }
                        : {},
                    ] as any
                  }>
                  {props.project.completeDateTime !== null
                    ? `\u00A0${props.project.name}\u00A0`
                    : props.project.name}
                </Typography>
              </View>
            </View>
          </View>
        </View>
        <View style={{ flexDirection: 'row' }}>
          {props.showFavorite !== false && (
            <View
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: 2,
                paddingHorizontal: 5,
                width: 25,
                marginLeft: 15,
                marginRight: 20,
              }}>
              {props.project.favoriteMembers.filter((member) => member!.id === loginUser!.id)
                .length > 0 ? (
                <StarIcon
                  size={16}
                  on={true}
                  containerStyle={{ marginLeft: 3 }}
                  onPress={() => {
                    cancelFavoriteProject();
                  }}
                />
              ) : (
                <StarIcon
                  size={16}
                  on={false}
                  containerStyle={{ marginLeft: 3 }}
                  onPress={() => {
                    favoriteProject();
                  }}
                />
              )}
            </View>
          )}
          <View
            style={{
              maxWidth: 100,
              minWidth: 100,
              marginRight: 10,
              justifyContent: 'center',
            }}>
            <Typography
              variant={TypographyType.Normal}
              ellipsis={true}
              tooltipWhenEllipsis={true}
              tooltipId={`key-${props.project.id}`}
              style={{
                fontSize: 14,
                minWidth: 30,
                textAlign: 'left',
              }}>
              {props.project.key ?? '-'}
            </Typography>
          </View>
          <View
            style={{
              maxWidth: 140,
              minWidth: 140,
              marginRight: 10,
              justifyContent: 'center',
            }}>
            <Typography
              variant={TypographyType.Normal}
              ellipsis={true}
              tooltipWhenEllipsis={true}
              tooltipId={`client-${props.project.id}`}
              style={{
                fontSize: 14,
                minWidth: 30,
                textAlign: 'left',
              }}>
              {props.project.client?.name ?? '-'}
            </Typography>
          </View>
          {(props.project.team.organization.plan.code === Plan.Business ||
            props.project.team.organization.plan.code === Plan.Enterprise) &&
            props.showAssigner !== false && (
              <View
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingTop: 2,
                  paddingHorizontal: 5,
                  width: 60,
                }}>
                {props.project.assignees.length >= 2 ? (
                  <View
                    style={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginLeft: 5,
                      maxWidth: 15,
                    }}>
                    <Avatar
                      size={24}
                      textStyle={{ fontSize: 10 }}
                      name={`${props.project.assignees.length}名`}
                    />
                  </View>
                ) : (
                  props.project.assignees.map((info, i) => {
                    return (
                      <View
                        style={{
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginLeft: 5,
                          maxWidth: 15,
                        }}
                        key={i}>
                        <Avatar
                          size={24}
                          name={info.member.name!}
                          imageUrl={info.member.profileImageUrl}
                        />
                      </View>
                    );
                  })
                )}
              </View>
            )}
          {windowWidth > 1500 && (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <Typography
                variant={TypographyType.Normal}
                style={{ fontSize: 12, paddingHorizontal: 5, width: 90, textAlign: 'center' }}>
                {DateUtil.formatDate(props.project.scheduledStartDateTime)}
              </Typography>
            </View>
          )}
          {windowWidth > 1400 && (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <Typography
                variant={TypographyType.Normal}
                style={{ fontSize: 12, paddingHorizontal: 5, width: 90, textAlign: 'center' }}>
                {DateUtil.formatDate(props.project.scheduledEndDateTime)}
              </Typography>
            </View>
          )}
          <View
            style={{
              flexDirection: 'row',
              width: 100,
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <ProjectWorkingTime
              project={props.project}
              style={{ fontSize: 12, minWidth: 50, marginRight: 5, textAlign: 'center' }}
            />
          </View>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}>
            <Typography
              variant={TypographyType.Normal}
              style={{ fontSize: 12, paddingHorizontal: 5, width: 100, textAlign: 'center' }}>
              {TimeUtil.formatForTask(props.project.estimateTimeSec)}
            </Typography>
          </View>
          {/* {OrganizationUtil.isTeamPlan(props.organization) && windowWidth > 1400 && (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <Typography
                variant={TypographyType.Normal}
                style={{ fontSize: 12, paddingHorizontal: 5, width: 100, textAlign: 'center' }}>
                {props.project.laborCostWithoutTax
                  ? `${new Intl.NumberFormat('ja-JP', {
                      style: 'currency',
                      currency: 'JPY',
                    }).format(props.project.laborCostWithoutTax ?? 0)}`
                  : '-'}
              </Typography>
            </View>
          )}
          {windowWidth > 1400 && (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <Typography
                variant={TypographyType.Normal}
                style={{ fontSize: 12, paddingHorizontal: 5, width: 100, textAlign: 'center' }}>
                {props.project.salesAmountWithoutTax
                  ? `${new Intl.NumberFormat('ja-JP', {
                      style: 'currency',
                      currency: 'JPY',
                    }).format(props.project.salesAmountWithoutTax ?? 0)}`
                  : '-'}
              </Typography>
            </View>
          )}
          {OrganizationUtil.isTeamPlan(props.organization) && windowWidth > 1400 && (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <Typography
                variant={TypographyType.Normal}
                style={{ fontSize: 12, paddingHorizontal: 5, width: 100, textAlign: 'center' }}>
                {props.project.costBudgetWithoutTax
                  ? `${new Intl.NumberFormat('ja-JP', {
                      style: 'currency',
                      currency: 'JPY',
                    }).format(props.project.costBudgetWithoutTax ?? 0)}`
                  : '-'}
              </Typography>
            </View>
          )} */}
        </View>
      </Main>
      <ErrorMessageModal
        showModal={showErrorModal}
        title={'プロジェクトを更新できません'}
        message={`プロジェクトを更新する権限がありません${'\n'}権限が必要な場合、管理権限を持っているメンバーに問い合わせてください`}
        onCloseModal={() => setShowErrorModal(false)}
      />
    </Container>
  );
};

export default React.memo(ProjectSummary);
