import queryString from 'query-string';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';
import styled, { ThemeContext } from 'styled-components/native';
import useDimensions from 'use-dimensions';
import {
  type Project,
  ProjectAttachmentFilesDocument,
  useCreateAttachementFileForProjectMutation,
  useCreateAttachementFileUploadUrlMutation,
  useMeQuery,
  useMyGoogleAccountQuery,
  useProjectQuery,
} from '../../../../../graphql/api/API';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import UrlUtil from '../../../../../util/UrlUtil';
import CustomScrollView from '../../../../presentational/atoms/custom-scroll-view';
import Icon from '../../../../presentational/atoms/icon';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import UploadIcon from '../../../../presentational/molecules/image-icon/upload';
import type { IStyleTheme, IThemePart } from '../../../../theme';
import HorizontalMenu from '../horizontal-menu';
import ProjectDetailCalendarInfo from './calendar';
import ProjectDetailCommon from './common';
import ProjectDetailCommonLoading from './common-loading';
import ProjectHistoryView from './history';
import ProjectDetailLoading from './loading';
import ProjectSalesView from './sales';
import ProjectDetailStandardInfo from './standard-info';

interface IContainerProps extends IStyleTheme {
  isOpen: boolean;
  windowWidth: number;
}

const Container = styled.View<IContainerProps>`
  height: calc(100vh - 57px);
  right: ${(props: IContainerProps) =>
    props.windowWidth < 1260 ? '-490px' : `-${Math.max(props.windowWidth / 2.2, 540) + 10}px`};
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.4s;
  width: ${(props: IContainerProps) =>
    props.isOpen
      ? `${Math.max(props.windowWidth / 2.2, 540)}px`
      : '1px'}; // Note: 閉じている時に幅を 1px ではなく、0pxにすると、なぜかSafariは幅を300pxぐらい取る挙動になってしまう。それによって画面右側300pxぐらいに透明な状態のエリアがかぶさってしまい、画面操作を阻害してしまう。Safari対応のために幅を1pxに指定するようにしている。
  transform: ${(props: IContainerProps) =>
    `translateX(${props.isOpen ? `-${Math.max(props.windowWidth / 2.2, 540)}px` : '0'})`};
  background-color: ${(props: IContainerProps) => props.theme.colors.baseColor};
  overflow: hidden;
`;

const CommonArea = styled.View`
  display: flex;
  flex-direction: column;
  padding: 5px 15px;
  align-items: flex-start;
`;

interface IContainerInnerProps extends IStyleTheme {
  isShow: boolean;
}

const ContainerInner = styled.View<IContainerInnerProps>``;

interface IProjectDetailInnerProps {
  project: Project | null;
  projectDetailMode: string | null;
  loading: boolean;
  showCalendarMenu: boolean;
  showSalesMenu: boolean;
}

const ProjectDetailInner = (props: IProjectDetailInnerProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const { pathname, search } = useLocation();
  const [loginUser, _] = useContext(LoginUserContext);
  const dimensions = useDimensions();
  const windowWidth = dimensions.window.width;
  const ref = useRef();
  const [isDragOver, setDragOver] = useState(false);
  const searchParam = queryString.parse(search);

  const [createAttachmentFileUploadUrl, __] = useCreateAttachementFileUploadUrlMutation();
  const [createAttachemntFile, ___] = useCreateAttachementFileForProjectMutation();

  if (props.loading) {
    return (
      <Container isOpen={true} windowWidth={windowWidth}>
        <CustomScrollView scrollViewRef={ref} style={{ height: 'calc(100vh - 57px)' }}>
          <ContainerInner isShow={true}>
            <ProjectDetailCommonLoading>
              <ProjectDetailLoading />
            </ProjectDetailCommonLoading>
          </ContainerInner>
        </CustomScrollView>
      </Container>
    );
  }
  if (props.project) {
    const menu = [
      {
        title: '基本情報',
        path: '.*projectDetailMode=standard',
        linkPath: UrlUtil.addSearch(UrlUtil.getCurrentPathWithoutSearch(), {
          projectDetailMode: 'standard',
        }),
      },
    ];

    if (props.showCalendarMenu) {
      menu.push({
        title: '外部カレンダー連携',
        path: '.*projectDetailMode=calendar',
        linkPath: UrlUtil.addSearch(UrlUtil.getCurrentPathWithoutSearch(), {
          projectDetailMode: 'calendar',
        }),
      });
    }
    if (props.showSalesMenu) {
      menu.push({
        title: '契約情報',
        path: '.*projectDetailMode=contract',
        linkPath: UrlUtil.addSearch(UrlUtil.getCurrentPathWithoutSearch(), {
          projectDetailMode: 'contract',
        }),
      });
    }
    menu.push({
      title: 'その他',
      path: '.*projectDetailMode=history',
      linkPath: UrlUtil.addSearch(UrlUtil.getCurrentPathWithoutSearch(), {
        projectDetailMode: 'history',
      }),
    });

    return (
      <Container isOpen={true} windowWidth={windowWidth}>
        <div
          onDragOver={(e) => {
            e.stopPropagation();
            e.preventDefault();
            if (!isDragOver) {
              setDragOver(true);
            }
          }}>
          <CustomScrollView scrollViewRef={ref} style={{ height: 'calc(100vh - 57px)' }}>
            <ContainerInner isShow={true}>
              <ProjectDetailCommon
                organizationId={loginUser?.organizationId!}
                project={props.project}
                projectDetailMode={props.projectDetailMode!}>
                <CommonArea>
                  <HorizontalMenu style={{ marginLeft: 10 }} menus={menu} />
                </CommonArea>
                {props.showCalendarMenu && /.*projectDetailMode=calendar.*/.test(search) ? (
                  <ProjectDetailCalendarInfo project={props.project} />
                ) : /.*projectDetailMode=history.*/.test(search) ? (
                  <ProjectHistoryView project={props.project} />
                ) : /.*projectDetailMode=contract.*/.test(search) ? (
                  <ProjectSalesView project={props.project} year={searchParam.contractYear as any} month={searchParam.contractMonth as any} />
                ) : (
                  <ProjectDetailStandardInfo
                    organizationId={loginUser?.organizationId!}
                    project={props.project}
                    parentScrollViewRef={ref}
                  />
                )}
              </ProjectDetailCommon>
            </ContainerInner>
          </CustomScrollView>
          {isDragOver && (
            <div
              onDragLeave={(e) => {
                e.stopPropagation();
                e.preventDefault();
                if (isDragOver) {
                  setDragOver(false);
                }
              }}
              onDrop={async (e) => {
                e.stopPropagation();
                e.preventDefault();
                setDragOver(false);

                let isSuccess = true;
                if (Array.from(e.dataTransfer.files).length === 0) {
                  return;
                }
                await Promise.all(
                  Array.from(e.dataTransfer.files).map(async (file) => {
                    const uploadUrlResult = await createAttachmentFileUploadUrl({
                      variables: {
                        input: {
                          fileName: file.name,
                          sizeByte: file.size,
                          type: file.type,
                        },
                      },
                    });

                    await fetch(uploadUrlResult.data!.createAttachementFileUploadUrl!.uploadUrl, {
                      method: 'PUT',
                      body: file,
                    }).then((response) => {
                      if (!response.ok) {
                        isSuccess = false;
                      }
                    });

                    const attachmentFileResult = await createAttachemntFile({
                      variables: {
                        input: {
                          projectId: props.project?.id || '',
                          fileName: uploadUrlResult.data!.createAttachementFileUploadUrl!.fileName,
                          sizeByte: uploadUrlResult.data!.createAttachementFileUploadUrl!.sizeByte,
                          type: uploadUrlResult.data!.createAttachementFileUploadUrl!.type,
                          key: uploadUrlResult.data!.createAttachementFileUploadUrl!.key,
                        },
                      },
                      refetchQueries: [
                        {
                          query: ProjectAttachmentFilesDocument,
                          variables: {
                            projectId: props.project?.id || '',
                          },
                        },
                      ],
                    });
                    if (attachmentFileResult.errors) {
                      isSuccess = false;
                    }
                  })
                );

                if (isSuccess) {
                  toast('ファイルアップロードが完了しました', {
                    type: 'success',
                    position: 'bottom-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                } else {
                  toast('ファイルアップロードに失敗しました', {
                    type: 'error',
                    position: 'bottom-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                }
              }}
              style={{
                position: 'absolute',
                display: isDragOver ? 'flex' : 'none',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: '#FFFFFF',
                opacity: isDragOver ? 0.9 : 0,
              }}>
              <div
                style={{
                  position: 'absolute',
                  top: 10,
                  left: 10,
                  right: 10,
                  bottom: 10,
                  borderWidth: 5,
                  borderColor: themeContext.colors.primary,
                  borderStyle: 'dotted',
                  display: 'flex',
                }}>
                <UploadIcon
                  size={130}
                  containerStyle={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
                  onPress={() => {
                    if (isDragOver) {
                      setDragOver(false);
                    }
                  }}>
                  <Typography
                    variant={TypographyType.Description}
                    style={{
                      fontSize: 26,
                      textAlign: 'center',
                      marginTop: 20,
                      color: themeContext.colors.primary,
                    }}>
                    ファイルを添付する
                  </Typography>
                </UploadIcon>
              </div>
            </div>
          )}
        </div>
      </Container>
    );
  }
  return (
    <Container isOpen={false} windowWidth={windowWidth}>
      <ContainerInner isShow={false}>
        <ProjectDetailCommonLoading>
          <ProjectDetailLoading />
        </ProjectDetailCommonLoading>
      </ContainerInner>
    </Container>
  );
};

interface IProjectDetailWithFetchDataProps {
  projectId: string | null;
  projectDetailMode: string | null;
}

const ProjectDetailWithFetchData = (props: IProjectDetailWithFetchDataProps) => {
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const { loading, data, error } = useProjectQuery({
    variables: {
      id: props.projectId || '',
    },
    skip: props.projectId === null || props.projectId === undefined,
    fetchPolicy: 'network-only',
  });
  const { loading: loadingGoogleAccount, data: dataGoogleAccount } = useMyGoogleAccountQuery();
  const { loading: loadingMe, data: dataMe } = useMeQuery({
    fetchPolicy: 'network-only',
  });
  return (
    <ProjectDetailInner
      loading={loading}
      project={(data?.project as Project) || null}
      projectDetailMode={props.projectDetailMode}
      showCalendarMenu={dataGoogleAccount?.myGoogleAccount?.calendarPermission ?? false}
      showSalesMenu={dataMe?.me?.laborCostManagementRole ?? false}
    />
  );
};

const ProjectDetail = () => {
  const { search } = useLocation();
  const projectId = queryString.parse(search).projectDetailId as string;
  const projectDetailMode = queryString.parse(search).projectDetailMode as string;
  return <ProjectDetailWithFetchData projectId={projectId} projectDetailMode={projectDetailMode} />;
};

export default ProjectDetail;
