import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components/native';
import { Image } from 'react-native';
import { useScreenDimensions } from 'use-dimensions';
import { Redirect, useLocation } from 'react-router';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import Link from '../../../../presentational/atoms/link';
import { IThemePart } from '../../../../theme';
import logoLargeBlack from '../../../../../base64Images/logo/logo-large-black';
import login from '../../../../../base64Images/login/login';
import { Plan } from '../../../../../graphql/api/API';

const Header = styled.View`
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  height: 100px;
  padding-left: 20px;
  padding-top: 20px;
`;

const Container = styled.View`
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  height: calc(100vh - 100px);
`;

const LeftContainer = styled.View`
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 0 50px;
`;

const RightContainer = styled.View`
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 0 50px;
`;

const RightContainerInner = styled.View`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const LinkContainer = styled.View`
  margin: 1.5rem;
  text-align: center;
`;

const SignupSelectPlanPage = () => {
  const { height, width } = useScreenDimensions();
  const { state } = useLocation();
  const themeContext: IThemePart = useContext(ThemeContext);
  if (state === null || state === undefined) {
    return <Redirect to={'/signup'} />;
  }
  return (
    <>
      <Header>
        <Image
          source={{ uri: logoLargeBlack }}
          resizeMode="contain"
          style={{
            width: 300,
            height: 100,
          }}
        />
      </Header>
      <Container>
        <LeftContainer>
          <Image
            source={{ uri: login }}
            resizeMode="contain"
            style={{
              width: Math.max(width / 4, 500),
              height: Math.max(width / 4, 500),
            }}
          />
        </LeftContainer>
        <RightContainer>
          <RightContainerInner>
            <Typography variant={TypographyType.Title} style={{ marginVertical: 10 }}>
              ご利用用途・プランを選択してください
            </Typography>
            <Typography variant={TypographyType.Normal} style={{ marginBottom: 10 }}>
              ※プランの変更はあとから出来ません。
            </Typography>
            <Link
              isExternalLink={true}
              path="https://timedesigner.com/plan.html"
              style={{ marginBottom: 40 }}>
              <Typography
                variant={TypographyType.MenuItemActive}
                style={{ color: themeContext.colors.primary, fontSize: 17 }}>
                {`プランについて詳細を確認する`}
              </Typography>
            </Link>
            <LinkContainer>
              <Link
                path={'/signup/input-profile'}
                state={{ ...(state as any), ...{ plan: Plan.Basic } }}>
                <Typography
                  variant={TypographyType.MenuItemActive}
                  style={{ color: themeContext.colors.primary, fontSize: 20 }}>
                  {`1人で利用する（Basicプラン）(無料)`}
                </Typography>
              </Link>
            </LinkContainer>
            <LinkContainer>
              <Link
                path={'/signup/input-profile'}
                state={{ ...(state as any), ...{ plan: Plan.Business } }}>
                <Typography
                  variant={TypographyType.MenuItemActive}
                  style={{ color: themeContext.colors.primary, fontSize: 20, lineHeight: 30 }}>
                  {`チームで利用する（Businessプラン）${'\n'}(月額980円 ※税込 1,078円。1ヶ月無料試用)`}
                </Typography>
              </Link>
            </LinkContainer>
          </RightContainerInner>
        </RightContainer>
      </Container>
    </>
  );
};

export default React.memo(SignupSelectPlanPage);
