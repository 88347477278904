import React from 'react';
import DashboardPage from '../../components/container/web/templates/app/dashboard';
import AppMyAnalyticsPage from '../../components/container/web/templates/app/my-analytics';
import AppTeamAnalyticsPage from '../../components/container/web/templates/app/team-analytics';
import AppInboxPage from '../../components/container/web/templates/app/inbox';
import AppTeamMembersStatusPage from '../../components/container/web/templates/app/team-members-status';
import AppTeamMemberCalendarPage from '../../components/container/web/templates/app/team-members-status/team-member-calendar';
import AppOrganizationMembersStatusPage from '../../components/container/web/templates/app/organization-members-status';
import AppOrganizationMemberCalendarPage from '../../components/container/web/templates/app/organization-members-status/organization-member-calendar';
import AppMyTasksPage from '../../components/container/web/templates/app/my-tasks';
import AppSearchTasksPage from '../../components/container/web/templates/app/search-tasks';
import AppTeamProjectPage from '../../components/container/web/templates/app/team-projects';
import { IRouteItem } from '../RouteItem';
import AppOrganizationAnalyticsPage from '../../components/container/web/templates/app/organization-analytics';
import AppOrganizationMembersPage from '../../components/container/web/templates/app/organization-members';
import AppOrganizationSettingsPage from '../../components/container/web/templates/app/organization-settings';
import AppOrganizationExportsPage from '../../components/container/web/templates/app/exports/organization';
import AppTeamExportsPage from '../../components/container/web/templates/app/exports/team';
import { Member, Organization, Plan, Team, TeamMemberRelation } from '../../graphql/api/API';
import AppMySettingsPage from '../../components/container/web/templates/app/my-settings';
import AppTeamSettingsPage from '../../components/container/web/templates/app/team-settings';
import AppSearchTeamsPage from '../../components/container/web/templates/app/search-teams';
import AppWithdrawalPage from '../../components/container/web/templates/app/withdrawal';
import AppCustomAttributePage from '../../components/container/web/templates/app/custom-attribute';
import { LoginUser } from '../../modules/auth/LoginUserContext';
import AppMyCalendarPage from '../../components/container/web/templates/app/my-calendar';
import AppSupportPage from '../../components/container/web/templates/app/support';
import AppOrganizationClientsPage from '../../components/container/web/templates/app/organization-clients';
import AppTagSettingsPage from '../../components/container/web/templates/app/tags';
import ProjectTemplatePage from '../../components/container/web/templates/app/project-templates';
import SearchIcon from '../../components/presentational/molecules/image-icon/search';
import DashboardIcon from '../../components/presentational/molecules/image-icon/dashboard';
import CalendarIcon from '../../components/presentational/molecules/image-icon/calendar';
import InboxIcon from '../../components/presentational/molecules/image-icon/inbox';
import PlusIcon from '../../components/presentational/molecules/image-icon/plus';
import PieChartIcon from '../../components/presentational/molecules/image-icon/pie-chart';
import SettingsIcon from '../../components/presentational/molecules/image-icon/settings';
import PeopleIcon from '../../components/presentational/molecules/image-icon/people';
import ProjectIcon from '../../components/presentational/molecules/image-icon/projects';
import SmileIcon from '../../components/presentational/molecules/image-icon/smile';
import HelpIcon from '../../components/presentational/molecules/image-icon/help';
import PeopleAddIcon from '../../components/presentational/molecules/image-icon/people-add';
import ClientIcon from '../../components/presentational/molecules/image-icon/client';
import TagIcon from '../../components/presentational/molecules/image-icon/tag';
import CopyIcon from '../../components/presentational/molecules/image-icon/copy';
import CustomAttributeIcon from '../../components/presentational/molecules/image-icon/custom-attribute';
import FileMoveIcon from '../../components/presentational/molecules/image-icon/file-move';

export interface IRouteItemTeamMenu extends IRouteItem {
  teamMemberRelation: TeamMemberRelation;
}

const createTeamMenu = (
  organizationId: string,
  plan: Plan,
  teamMemberRelation: TeamMemberRelation | any,
  me: Member,
  index: number
): IRouteItemTeamMenu[] => {
  const team = teamMemberRelation.team;
  return [
    {
      id: `${organizationId}-${team.id}`,
      name: team.personalTeam ? '自分専用プロジェクト' : team.name,
      component: null,
      initialOpen: index === 0,
      teamMemberRelation: teamMemberRelation,
      subItems: [
        {
          id: `${organizationId}-${team.id}-projects`,
          path: `/app/${organizationId}/${team.id}/projects/`,
          name: 'プロジェクト',
          icon: 'format-list-bulleted',
          iconType: 'material-icons',
          iconComponent: <ProjectIcon size={26} reverse={true} />,
          component: AppTeamProjectPage,
          disable: false,
        },
        {
          id: `${organizationId}-${team.id}-analytics`,
          path: `/app/${organizationId}/${team.id}/team-analytics/`,
          menuPath: `/app/${organizationId}/${team.id}/team-analytics/graph/time-entries/graph-member/bar/`,
          name: '時間分析',
          icon: 'pie-chart',
          iconType: 'material-icons',
          iconComponent: <PieChartIcon size={26} reverse={true} />,
          component: AppTeamAnalyticsPage,
          disable: !me.teamReportRole,
        },
        // {
        //   id: `${organizationId}-${team.id}-account`,
        //   path: `/app/${organizationId}/${team.id}/team-account/`,
        //   menuPath: `/app/${organizationId}/${team.id}/team-account/graph/bar/`,
        //   name: '売上・コスト分析',
        //   icon: 'currency-jpy',
        //   iconType: 'material-community-icons',
        //   component: AppTeamAnalyticsPage,
        //   disable: !me.laborCostManagementRole && !me.salesManagementRole,
        // },
        {
          id: `${organizationId}-${team.id}-team-members-status`,
          path: `/app/${organizationId}/${team.id}/team-members-status/`,
          name: 'メンバーの状況',
          icon: 'people',
          iconType: 'material-icons',
          iconComponent: <PeopleIcon size={26} reverse={true} />,
          component: AppTeamMembersStatusPage,
          disable: !!team.personalTeam || !me.teamMemberViewRole,
        },
        {
          id: `${organizationId}-${team.id}-team-members-status`,
          path: `/app/${organizationId}/${team.id}/team-members-status-detail/`,
          name: 'メンバーの状況詳細',
          icon: 'settings',
          iconType: 'material-icons',
          iconComponent: <SearchIcon size={26} reverse={true} />,
          component: AppTeamMemberCalendarPage,
          disable: !me.teamMemberViewRole,
          menuInvisible: true,
        },
        {
          id: `${organizationId}-${team.id}-export`,
          path: `/app/${organizationId}/${team.id}/export-file/`,
          name: 'エクスポート',
          icon: 'settings',
          iconType: 'material-icons',
          iconComponent: <FileMoveIcon size={26} reverse={true} />,
          component: AppTeamExportsPage,
          disable: !me.teamReportRole,
        },
        {
          id: `${organizationId}-${team.id}-settings`,
          path: `/app/${organizationId}/${team.id}/settings/`,
          name: '各種設定',
          icon: 'settings',
          iconType: 'material-icons',
          iconComponent: <SettingsIcon size={26} reverse={true} />,
          component: AppTeamSettingsPage,
          disable: false,
        },
      ].filter((menu) => menu.disable !== true),
    },
  ];
};

const createSearchTeam = (organization: Organization | any): IRouteItem[] => {
  return [
    {
      id: 'search-team',
      path: `/app/${organization.id}/search-teams/`,
      name: 'チームを探す',
      icon: 'search',
      iconType: 'material-icons',
      iconComponent: <SearchIcon size={26} reverse={true} />,
      component: AppSearchTeamsPage,
      disable: false,
    },
  ];
};

const createCreateTeam = (organization: Organization | any): IRouteItem[] => {
  return [
    {
      id: 'create-team',
      path: {
        pathname: `/app/${organization.id}/search-teams/`,
        search: '?create=true',
      },
      name: 'チームを作成する',
      icon: 'plus',
      iconType: 'material-community-icons',
      iconComponent: <PlusIcon size={26} reverse={true} />,
      disable: false,
    },
  ];
};

const createOrganizationMenu = (organization: Organization | any, me: Member): IRouteItem[] => {
  if (
    !me.adminRole &&
    !me.memberManagementRole &&
    !me.laborCostManagementRole &&
    !me.salesManagementRole &&
    !me.clientManagementRole &&
    !me.organizationReportRole &&
    !me.organizationMemberViewRole
  ) {
    return [];
  }
  return [
    {
      id: `${organization.id}`,
      name: '組織',
      component: null,
      initialOpen: true,
      subItems: [
        {
          id: `${organization.id}-analytics`,
          path: `/app/${organization.id}/organization-analytics/`,
          menuPath: `/app/${organization.id}/organization-analytics/graph/time-entries/graph-member/bar/`,
          name: '時間分析',
          icon: 'pie-chart',
          iconType: 'material-icons',
          iconComponent: <PieChartIcon size={26} reverse={true} />,
          component: AppOrganizationAnalyticsPage,
          disable: !me.organizationReportRole,
        },
        // {
        //   id: `${organization.id}-account`,
        //   path: `/app/${organization.id}/organization-account/`,
        //   menuPath: `/app/${organization.id}/organization-account/graph/bar/`,
        //   name: '売上・コスト分析',
        //   icon: 'currency-jpy',
        //   iconType: 'material-community-icons',
        //   component: AppOrganizationAnalyticsPage,
        //   disable: !me.laborCostManagementRole && !me.salesManagementRole,
        // },
        {
          id: `${organization.id}-all-members-status`,
          path: `/app/${organization.id}/all-members-status/`,
          name: 'メンバーの状況',
          icon: 'people',
          iconType: 'material-icons',
          iconComponent: <PeopleIcon size={26} reverse={true} />,
          component: AppOrganizationMembersStatusPage,
          disable: !me.organizationMemberViewRole,
        },
        {
          id: `${organization.id}-all-members-status`,
          path: `/app/${organization.id}/all-members-status-detail/`,
          name: 'メンバーの状況詳細',
          icon: 'settings',
          iconType: 'material-icons',
          iconComponent: <SearchIcon size={26} reverse={true} />,
          component: AppOrganizationMemberCalendarPage,
          disable: !me.organizationMemberViewRole,
          menuInvisible: true,
        },
        {
          id: `${organization.id}-members`,
          path: `/app/${organization.id}/members/`,
          name: 'メンバー管理',
          icon: 'person-pin',
          iconType: 'material-icons',
          iconComponent: <PeopleAddIcon size={26} reverse={true} />,
          component: AppOrganizationMembersPage,
          disable: !me.memberManagementRole && !me.laborCostManagementRole,
        },
        {
          id: `${organization.id}-clients`,
          path: `/app/${organization.id}/clients/`,
          name: '取引先管理',
          icon: 'business',
          iconType: 'material-icons',
          iconComponent: <ClientIcon size={26} reverse={true} />,
          component: AppOrganizationClientsPage,
          disable: !me.clientManagementRole,
        },
        {
          id: `${organization.id}-tags`,
          path: `/app/${organization.id}/tags/`,
          name: 'タグの管理',
          icon: 'local-offer',
          iconType: 'material-icons',
          iconComponent: <TagIcon size={26} reverse={true} />,
          component: AppTagSettingsPage,
          disable: false,
        },
        {
          id: `${organization.id}-tags`,
          path: `/app/${organization.id}/custom-attribute/`,
          name: 'カスタム項目の管理',
          icon: 'local-offer',
          iconType: 'material-icons',
          iconComponent: <CustomAttributeIcon size={26} reverse={true} />,
          component: AppCustomAttributePage,
          disable: false,
        },
        {
          id: `${organization.id}-project-templates`,
          path: `/app/${organization.id}/project-templates/`,
          name: 'テンプレート管理',
          icon: 'copy',
          iconType: 'material-icons',
          iconComponent: <CopyIcon size={26} reverse={true} />,
          component: ProjectTemplatePage,
          disable: false,
        },
        {
          id: `${organization}-export`,
          path: `/app/${organization.id}/export-file/`,
          name: 'エクスポート',
          icon: 'settings',
          iconType: 'material-icons',
          iconComponent: <FileMoveIcon size={26} reverse={true} />,
          component: AppOrganizationExportsPage,
          disable: !me.organizationReportRole,
        },
        {
          id: `${organization.id}-settings`,
          path: `/app/${organization.id}/settings/`,
          name: '各種設定',
          icon: 'settings',
          iconType: 'material-icons',
          iconComponent: <SettingsIcon size={26} reverse={true} />,
          component: AppOrganizationSettingsPage,
          disable: !me.adminRole,
        },
        {
          id: `${organization.id}-withdrawal`,
          path: `/app/${organization.id}/withdrawal/`,
          name: '退会のお手続き',
          icon: 'settings',
          iconType: 'material-icons',
          iconComponent: <SearchIcon size={26} reverse={true} />,
          component: AppWithdrawalPage,
          disable: !me.adminRole,
          menuInvisible: true,
        },
      ].filter((menu) => menu.disable !== true),
    },
  ];
};

const createMyMenu = (organization: Organization | any, user: LoginUser): IRouteItem[] => {
  const empty: IRouteItem[] = [];
  return empty.concat([
    // {
    //   id: 'home',
    //   path: `/app/${organization.id}/home/`,
    //   name: 'ホーム',
    //   icon: 'home',
    //   iconType: 'material-icons',
    //   component: AppHomePage,
    // },

    {
      id: 'mytask',
      path: `/app/${organization.id}/my/`,
      menuPath: `/app/${organization.id}/my/favorite-project/`,
      name: 'マイタスク',
      icon: 'emotsmile',
      iconType: 'simple-line-icons',
      iconComponent: <SmileIcon size={26} reverse={true} />,
      component: AppMyTasksPage,
    },

    {
      id: 'search-task',
      path: `/app/${organization.id}/search-task/list/`,
      name: 'タスク検索結果',
      icon: 'search',
      iconType: 'material-icons',
      component: AppSearchTasksPage,
      disable: true,
    },
    {
      id: `my-projects`,
      path: `/app/${organization.id}/${user.myTeamId}/projects/`,
      name: 'プロジェクト',
      icon: 'format-list-bulleted',
      iconType: 'material-icons',
      iconComponent: <ProjectIcon size={26} reverse={true} />,
      component: AppTeamProjectPage,
      disable: false,
    },
    {
      id: `${organization.id}-my-calendar`,
      path: `/app/${organization.id}/my-calendar/`,
      name: 'カレンダー',
      icon: 'date-range',
      iconType: 'material-icons',
      iconComponent: <CalendarIcon size={26} reverse={true} />,
      component: AppMyCalendarPage,
      disable: false,
    },
    {
      id: 'dashboard',
      path: `/app/${organization.id}/dashboard/`,
      name: 'ダッシュボード',
      icon: 'emotsmile',
      iconType: 'simple-line-icons',
      iconComponent: <DashboardIcon size={20} />,
      component: DashboardPage,
    },
    {
      id: `${organization.id}-my-analytics`,
      path: `/app/${organization.id}/my-analytics/`,
      menuPath: `/app/${organization.id}/my-analytics/graph/time-entries/graph-task/date/`,
      name: '時間分析',
      icon: 'pie-chart',
      iconType: 'material-icons',
      iconComponent: <PieChartIcon size={26} reverse={true} />,
      component: AppMyAnalyticsPage,
      disable: false,
    },
    {
      id: 'inbox',
      path: `/app/${organization.id}/messages/`,
      menuPath: `/app/${organization.id}/messages/notifications/`,
      name: 'メッセージ',
      icon: 'inbox',
      iconType: 'material-icons',
      iconComponent: <InboxIcon size={26} reverse={true} />,
      component: AppInboxPage,
    },
    {
      id: `${organization.id}-clients`,
      path: `/app/${organization.id}/clients/`,
      name: '取引先管理',
      icon: 'business',
      iconType: 'material-icons',
      iconComponent: <ClientIcon size={26} reverse={true} />,
      component: AppOrganizationClientsPage,
      disable:
        (organization as Organization).plan.code == Plan.Business ||
        (organization as Organization).plan.code == Plan.Enterprise,
      menuInvisible:
        (organization as Organization).plan.code == Plan.Business ||
        (organization as Organization).plan.code == Plan.Enterprise,
    },
    {
      id: `${organization.id}-tags`,
      path: `/app/${organization.id}/tags/`,
      name: 'タグの管理',
      icon: 'local-offer',
      iconType: 'material-icons',
      iconComponent: <TagIcon size={26} reverse={true} />,
      component: AppTagSettingsPage,
      disable:
        (organization as Organization).plan.code == Plan.Business ||
        (organization as Organization).plan.code == Plan.Enterprise,
      menuInvisible:
        (organization as Organization).plan.code == Plan.Business ||
        (organization as Organization).plan.code == Plan.Enterprise,
    },
    {
      id: `${organization.id}-tags`,
      path: `/app/${organization.id}/custom-attribute/`,
      name: 'カスタム項目の管理',
      icon: 'local-offer',
      iconType: 'material-icons',
      iconComponent: <CustomAttributeIcon size={26} reverse={true} />,
      component: AppCustomAttributePage,
      disable:
        (organization as Organization).plan.code == Plan.Business ||
        (organization as Organization).plan.code == Plan.Enterprise,
      menuInvisible:
        (organization as Organization).plan.code == Plan.Business ||
        (organization as Organization).plan.code == Plan.Enterprise,
    },
    {
      id: `${organization.id}-project-templates`,
      path: `/app/${organization.id}/project-templates/`,
      name: 'テンプレート管理',
      icon: 'copy',
      iconType: 'material-icons',
      iconComponent: <CopyIcon size={26} reverse={true} />,
      component: ProjectTemplatePage,
      disable:
        (organization as Organization).plan.code == Plan.Business ||
        (organization as Organization).plan.code == Plan.Enterprise,
      menuInvisible:
        (organization as Organization).plan.code == Plan.Business ||
        (organization as Organization).plan.code == Plan.Enterprise,
    },
    {
      id: `${organization.id}-my-export`,
      path: `/app/${organization.id}/my-export-file/`,
      name: 'エクスポート',
      icon: 'settings',
      iconType: 'material-icons',
      iconComponent: <FileMoveIcon size={26} reverse={true} />,
      component: AppOrganizationExportsPage,
      disable:
        (organization as Organization).plan.code == Plan.Business ||
        (organization as Organization).plan.code == Plan.Enterprise,
      menuInvisible:
        (organization as Organization).plan.code == Plan.Business ||
        (organization as Organization).plan.code == Plan.Enterprise,
    },
    {
      id: `${organization.id}-my-settings`,
      path: `/app/${organization.id}/my-settings/`,
      name: '各種設定',
      icon: 'settings',
      iconType: 'material-icons',
      iconComponent: <SettingsIcon size={26} reverse={true} />,
      component: AppMySettingsPage,
      disable: false,
    },
  ]);
};

const createHelpMenu = (organization: Organization | any): IRouteItem[] => {
  return [
    {
      id: 'support',
      path: `/app/${organization.id}/support/`,
      name: 'ヘルプ',
      icon: 'help-circle-outline',
      iconType: 'material-community-icons',
      iconComponent: <HelpIcon size={26} reverse={true} />,
      component: AppSupportPage,
      disable: false,
    },
  ];
};

const separator: IRouteItem = {
  id: 'separator',
  name: 'セパレーター',
  component: null,
};

const prepareMyMenuAppRoutes = (organization: Organization, user: LoginUser, me: Member) => {
  if (organization === null) {
    return [];
  }

  const empty: IRouteItem[] = [];
  if (organization.plan.code === 'Business' || organization.plan.code === 'Enterprise') {
    return empty.concat(
      createMyMenu(organization, user).filter(
        (menu) =>
          menu.id !== 'search-task' && // タスク検索画面はサイドメニューには表示しない
          !(menu.id === 'my-projects' && user.myTeamId === null) //自分専用プロジェクトが存在しない場合には、サイドメニューには表示しない
      ),
      [separator]
    );
  }

  return empty.concat(
    createMyMenu(organization, user).filter(
      (menu) => menu.id !== 'search-task' // タスク検索画面はサイドメニューには表示しない
    ),
    [separator]
  );
};

const prepareTeamMenuAppRoutes = (
  organization: Organization,
  teamMemberRelations: TeamMemberRelation[],
  user: LoginUser,
  me: Member
) => {
  if (organization === null) {
    return [];
  }

  const empty: Array<IRouteItemTeamMenu | IRouteItem> = [];
  if (organization.plan.code === 'Business' || organization.plan.code === 'Enterprise') {
    return empty.concat(
      teamMemberRelations!
        .filter((relation) => relation.team!.personalTeam === false)
        .sort((a, b) => {
          if (a!.team.personalTeam) {
            return -1;
          }
          if (b!.team.personalTeam) {
            return 1;
          }
          return Number(a!.id!) - Number(b!.id!);
        })
        .map((relation, index) => {
          return createTeamMenu(organization.id!, organization.plan.code, relation!, me, index);
        })
        .flat(),
      [separator]
    );
  }
  return [];
};

const prepareOtherMenuAppRoutes = (organization: Organization, me: Member) => {
  if (organization === null) {
    return [];
  }

  const empty: IRouteItem[] = [];
  const organizationMenus = createOrganizationMenu(organization, me);

  if (organization.plan.code === 'Business' || organization.plan.code === 'Enterprise') {
    return empty.concat(
      createSearchTeam(organization),
      createCreateTeam(organization),
      [separator],
      organizationMenus, //
      organizationMenus?.length > 0 ? [separator] : [],
      createHelpMenu(organization) //
    );
  }
  return empty.concat(
    createHelpMenu(organization) //
  );
};

export const prepareAppRoutesDefine = (me: Member) => {
  const organization = {
    id: ':organizationId',
    name: '',
    plan: {
      code: Plan.Enterprise,
    },
    teams: [
      {
        id: ':teamId',
        name: '',
        personalTeam: true,
      },
      {
        id: ':teamId',
        name: '',
        personalTeam: false,
      },
    ],
  };

  const empty: IRouteItem[] = [];
  const organizationMenus = createOrganizationMenu(organization, me);
  return empty.concat(
    createMyMenu(organization, { myTeamId: '' } as LoginUser),
    createTeamMenu(
      organization.id,
      Plan.Enterprise,
      {
        team: {
          id: ':teamId',
          name: '',
          isMyTeam: false,
        },
      },
      me,
      1
    )[0].subItems!, //
    createSearchTeam(organization), //
    createCreateTeam(organization), //
    organizationMenus?.length > 0 ? organizationMenus[0].subItems! : [], //
    createHelpMenu(organization) //
  );
};

export { prepareMyMenuAppRoutes, prepareTeamMenuAppRoutes, prepareOtherMenuAppRoutes };
