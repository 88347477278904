import React, { useContext } from 'react';
import { ColorValue, ViewStyle } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../theme';
import CheckIcon from '../../molecules/image-icon/check';
import Icon from '../icon';

interface ICheckboxInnerProps {
  size?: number;
  color?: ColorValue;
}

const Container = styled.View<ICheckboxInnerProps>`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  border-color: ${(props) =>
    props.color ? props.color : (props as IStyleTheme).theme.colors.primary};
  border-width: 2px;
  box-sizing: border-box;
  user-select: none;
  pointer: cursor;
  background-color: #ffffff;
`;

interface IProps {
  value?: boolean;
  onValueChange?: (value: boolean) => void;
  color?: ColorValue;
  size?: number;
}

const Checkbox: React.FC<IProps> = (props: IProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);

  return (
    <Container size={props.size} color={props.color}>
      <CheckIcon
        size={props.size - 4}
        on={props.value}
        containerStyle={{
          borderRadius: 3,
          height: props.size,
          width: props.size,
        }}
        onPress={props.onValueChange ? () => props.onValueChange!(!props.value) : undefined}
      />
    </Container>
  );
};

Checkbox.defaultProps = {
  size: 16,
};

export default Checkbox;
