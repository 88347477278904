import React, { createContext, useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../../../../theme';
import GanttChart from '../../../../organisms/gantt-chart';

interface IContainerProps extends IStyleTheme {
  height: string;
}

const Container = styled.View<IContainerProps>`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  padding: 10px 0;
  height: ${(props: IContainerProps) => props.height};
`;

interface IProjectGanttChartParam {
  teamId: string;
  projectId: string;
  organizationId: string;
}

interface Props {}

const ProjectGanttChart = (props: Props) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const params = useParams<IProjectGanttChartParam>();
  const { push, location } = useHistory();
  return (
    <Container
      height={
        location.pathname.indexOf('/my/favorite-project/') !== -1 ||
        location.pathname.indexOf('/my/assigned-project/') !== -1
          ? 'calc(100vh - 150px - 130px)'
          : 'calc(100vh - 90px - 130px)'
      }>
      <GanttChart
        organizationId={params.organizationId}
        teamId={params.teamId}
        projectId={params.projectId}
      />
    </Container>
  );
};

export default React.memo(ProjectGanttChart);
