import React, { useContext, useEffect, useState } from 'react';
import { Image, View } from 'react-native';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import {
  TwoFactorAuthSettingDocument,
  useSetUpTwoFactorAuthMutation,
  useVerifyTwoFactorAuthMutation,
} from '../../../../../graphql/api/API';
import Button from '../../../../presentational/atoms/button';
import Form from '../../../../presentational/atoms/form';
import Input from '../../../../presentational/atoms/input';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import { IStyleTheme, IThemePart } from '../../../../theme';

const Container = styled.View`
  display: flex;
  align-items: center;
`;

const ErrorMessage = styled.Text<IStyleTheme>`
  font-size: 0.9rem;
  color: ${(props: any) => props.theme.colors.error};
  text-align: left;
  padding-bottom: 1rem;
`;

interface ITwoFactorAuthSettingProps {
  onSuccess?: () => void;
  onCancel?: () => void;
}

const TwoFactorAuthSetting = (props: ITwoFactorAuthSettingProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [setUpTwoFactorAuth, { loading: setUpTwoFactorAuthLoading }] =
    useSetUpTwoFactorAuthMutation();
  const [qrCodeUrl, setQrCodeUrl] = useState('');
  const [secret, setSecret] = useState('');
  const [code, setCode] = useState('');
  const [verifyToken, setVerifyToken] = useState('');
  const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
  const [verify] = useVerifyTwoFactorAuthMutation({
    onError: (e) => {
      if (e?.graphQLErrors.find((ge) => ge.extensions?.code === 'authentication-failed')) {
        setDisplayErrorMessage(true);
      }
    },
  });

  useEffect(() => {
    const setUp = async () => {
      const result = await setUpTwoFactorAuth();
      setQrCodeUrl(result!.data!.setUpTwoFactorAuth!.qrImageBase64);
      setSecret(result!.data!.setUpTwoFactorAuth!.secret);
      setVerifyToken(result!.data!.setUpTwoFactorAuth!.verifyToken);
    };
    setUp();
  }, []);

  return (
    <Container>
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: 475,
          width: 500,
        }}>
        {setUpTwoFactorAuthLoading ? (
          <div
            style={{
              width: 180,
              height: 180,
            }}></div>
        ) : (
          <Image
            source={{ uri: qrCodeUrl }}
            resizeMode="contain"
            style={{
              width: 180,
              height: 180,
            }}
          />
        )}
        <View
          style={{
            paddingVertical: 16,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
          }}>
          <Typography variant={TypographyType.Normal}>コード:</Typography>
          {secret.match(/.{4}/g)?.map((secretChunk) => (
            <Typography variant={TypographyType.Normal}>{secretChunk}</Typography>
          ))}
        </View>
        <Typography variant={TypographyType.Description}>
          スマートフォンの認証アプリ（Google Authenticator、Authy、Duo Mobileなど）で{'\n'}
          QRコードを読み取るか、コードを入力してください。
          {'\n'}
          その後、アプリに表示された6桁の数字を入力してください。
        </Typography>
        <Form style={{ marginTop: 10, minWidth: 300 }}>
          <Input
            name={'mfa'}
            type={'text'}
            label={'6桁の数字'}
            onChange={(value) => {
              setDisplayErrorMessage(false);
              setCode(value);
            }}
            validate={{
              required: {
                value: true,
                message: '6桁の数字を入力してください',
              },
              pattern: {
                value: /^[0-9]{6}$/,
                message: '6桁の数字を入力してください',
              },
            }}
          />
          {displayErrorMessage && <ErrorMessage>入力された数字が誤っています。</ErrorMessage>}
          <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1 }}>
            <Button
              text="認証する"
              style={{
                minWidth: 100,
                marginRight: 10,
              }}
              onPress={async () => {
                const result = await verify({
                  variables: {
                    input: {
                      code: code,
                      verifyToken: verifyToken,
                    },
                  },
                  refetchQueries: [
                    {
                      query: TwoFactorAuthSettingDocument,
                    },
                  ],
                });
                if (!result) {
                  return;
                } else {
                  if (!!props.onSuccess) {
                    props.onSuccess();
                  }
                }
              }}
            />
            {!!props.onCancel && (
              <Button
                text={'キャンセル'}
                style={{
                  minWidth: 100,
                  marginRight: 10,
                  backgroundColor: 'transparent',
                }}
                textStyle={{ color: themeContext.colors.primary }}
                disableValidate={true}
                onPress={async () => {
                  props.onCancel!();
                }}
              />
            )}
          </View>
        </Form>
      </View>
    </Container>
  );
};

export default TwoFactorAuthSetting;
