import React, { useCallback, useContext, useState, useEffect } from 'react';
import { Text, View } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../../theme';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import Icon from '../../../../presentational/atoms/icon';
import DateUtil from '../../../../../util/DateUtil';
import TimeUtil from '../../../../../util/TimeUtil';
import Avatar from '../../../../presentational/atoms/avatar';
import {
  LatestWorkingHistoryDocument,
  Organization,
  Plan,
  Project,
  ProjectWorkingTimeSecDocument,
  Task,
  TaskDocument,
  TaskWorkingHistorySummaryGroupByAssineeDocument,
  Team,
  useStartTaskMutation,
  useStopTaskMutation,
} from '../../../../../graphql/api/API';
import { TaskProgressBar } from '../task-progress-bar';
import { TaskWorkingTime } from '../task-summary';
import ColorUtil from '../../../../../util/ColorUtil';
import moment from 'moment-timezone';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import TaskUtil from '../../../../../util/TaskUtil';
import PlayIcon from '../../../../presentational/molecules/image-icon/play';
import StopIcon from '../../../../presentational/molecules/image-icon/stop';
import ErrorMessageModal from '../error-message-modal';

interface IContainerProps extends IStyleTheme {
  isComplete: boolean;
  isTracking: boolean;
}

const Container = styled.View<IContainerProps>`
  display: flex;
  flex-direction: column;
  margin: 3px 5px;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.4s;
  z-index: 100;
  border-width: 1px;
  border-color: ${(props: IContainerProps) => props.theme.colors.separator};
  background-color: ${(props: IContainerProps) =>
    props.isTracking
      ? '#FFFFF0'
      : props.isComplete
      ? ColorUtil.lignten(props.theme.colors.separator, 3)
      : props.theme.colors.baseColor};
`;

const Main = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 3px 10px 3px 5px;
  height: 85px;
`;

interface IProps {
  organization: Organization;
  team: Team;
  project: Project;
}

const ProjectSummaryForBoard = React.memo((props: IProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, _] = useContext(LoginUserContext);
  const [showCompleteTaskStartErrorModal, setShowCompleteTaskStartErrorModal] = useState(false);

  return (
    <Container isComplete={props.project.completeDateTime !== null}>
      <Main>
        <View
          style={{
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            height: '100%',
          }}>
          <View style={{ flexDirection: 'column', marginLeft: 5, flex: 1 }}>
            <View style={{ flexDirection: 'row', flex: 1 }}>
              <View
                style={{
                  flexDirection: 'row',
                  flex: 1,
                  justifyContent: 'space-between',
                }}>
                <View style={{ flexDirection: 'column', maxWidth: 235 }}>
                  {props.project.client && (
                    <Typography
                      variant={TypographyType.Description}
                      ellipsis={true}
                      tooltipWhenEllipsis={true}
                      tooltipId={`client-${props.project.id}`}
                      style={{
                        fontSize: 12,
                        lineHeight: 13,
                        minWidth: 30,
                        maxWidth: 180,
                        textAlign: 'left',
                      }}>
                      {props.project.client?.name}
                    </Typography>
                  )}
                  <View>
                    <Typography
                      variant={TypographyType.Normal}
                      ellipsis={true}
                      tooltipWhenEllipsis={true}
                      tooltipId={`project-${props.project.id}`}
                      style={{ fontSize: 15, minWidth: 30, maxWidth: 180, textAlign: 'left' }}>
                      {props.project.name}
                    </Typography>
                  </View>
                </View>
              </View>
            </View>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <View
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: 22,
                }}>
                {(props.organization.plan.code === Plan.Business ||
                  props.organization.plan.code === Plan.Enterprise) &&
                  props.project.assignees.length > 0 && (
                    <>
                      {props.project.assignees.length >= 2 ? (
                        <Avatar
                          size={24}
                          textStyle={{ fontSize: 10 }}
                          name={`${props.project.assignees.length}名`}
                        />
                      ) : (
                        props.project.assignees.map((info, i) => {
                          return (
                            <Avatar
                              size={24}
                              name={info.member.name!}
                              imageUrl={info.member.profileImageUrl}
                            />
                          );
                        })
                      )}
                    </>
                  )}
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  flex: 1,
                  paddingLeft: 5,
                  justifyContent: 'flex-end',
                }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{ fontSize: 14, textAlign: 'left', minWidth: 45, overflow: 'hidden' }}>
                  {TimeUtil.formatForTask(props.project.workingTimeSec)}
                </Typography>
                <Typography
                  variant={TypographyType.Normal}
                  style={{ fontSize: 14, textAlign: 'center', marginHorizontal: 3 }}>
                  /
                </Typography>
                <Typography
                  variant={TypographyType.Normal}
                  style={{ fontSize: 14, textAlign: 'left', minWidth: 65, overflow: 'hidden' }}>
                  {TimeUtil.formatForTask(props.project.estimateTimeSec)}
                </Typography>
              </View>
            </View>
          </View>
        </View>
      </Main>
    </Container>
  );
});

export default ProjectSummaryForBoard;
