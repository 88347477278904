import React, { useContext } from 'react';
import { Image, View } from 'react-native';
import { ThemeContext } from 'styled-components/native';
import googleIcon from '../../../../base64Images/google-icon';
import { IThemePart } from '../../../theme';
import Typography, { TypographyType } from '../../atoms/typography';

interface Props {
  text: string;
}

const GoogleConnectDummyButton = (props: Props) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: 280,
        borderWidth: 1,
        borderColor: themeContext.colors.separator,
        shadowOffset: {
          width: 1,
          height: 1,
        },
        shadowOpacity: 0.2,
        elevation: 2,
      }}>
      <View>
        <Image
          source={{ uri: googleIcon }}
          resizeMode="contain"
          style={{
            width: 30,
            height: 30,
            marginRight: 10,
          }}
        />
      </View>
      <Typography
        variant={TypographyType.Normal}
        style={{ fontSize: 14, textAlign: 'center', paddingVertical: 10 }}>
        {props.text}
      </Typography>
    </View>
  );
};

export default GoogleConnectDummyButton;
