import React, { ReactNode, useContext } from 'react';
//@ts-ignore
import { componentWillAppendToBody } from 'react-append-to-body';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../theme';
import Typography, { TypographyType } from '../../atoms/typography';
import { useHotkeys } from 'react-hotkeys-hook';
import CloseIcon from '../image-icon/close';
import { StyleProp, useWindowDimensions, ViewStyle } from 'react-native';
import useDimensions from 'react-cool-dimensions';

const Container = styled.View`
  cursor: default;
`;

const Header = styled.View`
  background-color: ${(props: IStyleTheme) => props.theme.colors.header};
  padding: 10px 30px;
  box-shadow: 5px 0px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.4s;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  text-align: center;
`;

const Content = styled.View`
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
  padding: 10px 20px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
`;

const Overlay = styled.TouchableOpacity`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000000;
  background: rgba(0, 0, 0, 0.2);
  display: block;
  z-index: 9999;
  transition: all 0.4s;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.TouchableWithoutFeedback`
  z-index: 10000;
  cursor: default;
`;

interface IProps {
  isShow: boolean;
  onClose: () => void;
  title?: string;
  showClose?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  contentStyle?: StyleProp<ViewStyle>;
  children: ReactNode;
}

const Modal = (props: IProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  useHotkeys('Esc', props.onClose, { enabled: props.isShow });
  const { observe, height } = useDimensions();
  const { height: windowHeight } = useWindowDimensions();

  if (!props.isShow) {
    return <></>;
  }
  return (
    <Overlay
      onPress={() => {
        props.onClose();
      }}>
      <ModalContainer>
        <Container style={[props.containerStyle]} ref={observe}>
          {props.title && (
            <Header>
              <Typography
                variant={TypographyType.SubTitle}
                style={{ color: '#FFFFFF', marginRight: 10 }}>
                {props.title}
              </Typography>
              {props.showClose !== false && (
                <CloseIcon
                  size={16}
                  reverse={true}
                  containerStyle={
                    { position: 'absolute', right: 10, top: 5, cursor: 'pointer' } as any
                  }
                  onPress={props.onClose}
                />
              )}
            </Header>
          )}
          <Content
            style={[
              props.contentStyle,
              windowHeight < height ? { overflow: 'scroll', maxHeight: windowHeight - 40 } : {},
            ]}>
            {props.children}
          </Content>
        </Container>
      </ModalContainer>
    </Overlay>
  );
};

export default componentWillAppendToBody(Modal);
