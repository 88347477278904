import moment from 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import styled, { ThemeContext } from 'styled-components/native';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import { IStyleTheme, IThemePart } from '../../../../theme';
import {
  GoogleAccount,
  GoogleCalendarEvent,
  Plan,
  useGoogleWorkSpaceMembersQuery,
  useMyGoogleAccountQuery,
  useOrganizationQuery,
  useUpdateGoogleCalendarEventMutation,
} from '../../../../../graphql/api/API';
import Avatar from '../../../../presentational/atoms/avatar';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import Modal from '../../../../presentational/molecules/modal';
import { View, TouchableOpacity, Linking } from 'react-native';
import Button from '../../../../presentational/atoms/button';
import Form from '../../../../presentational/atoms/form';
import DateTimePicker from '../../../../presentational/atoms/date-time-picker';
import Input from '../../../../presentational/atoms/input';
import Icon from '../../../../presentational/atoms/icon';
import CustomScrollView from '../../../../presentational/atoms/custom-scroll-view';
import { MailAddressAndName } from '../create-google-calendar-event-modal';
import { useHistory } from 'react-router';
import EditableText from '../../../../presentational/atoms/editable-text';
import CloseIcon from '../../../../presentational/molecules/image-icon/close';
import OpenLinkIcon from '../../../../presentational/molecules/image-icon/open-in-new';
import base64 from 'react-native-base64';

interface IGuestListProps {
  guests: MailAddressAndName[];
  setGuests: (values: MailAddressAndName[]) => void;
  googleMembers: GoogleAccount[];
}

const GuestList = (props: IGuestListProps) => {
  if (props.guests.length === 0) {
    return <></>;
  }
  return (
    <CustomScrollView
      style={{
        flexDirection: 'column',
        maxHeight: 300,
        width: '100%',
        padding: 10,
      }}>
      {props.guests.map((guest, i) => {
        return (
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginVertical: 2,
              justifyContent: 'space-between',
            }}
            key={i}>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'flex-start',
              }}>
              <Avatar
                size={28}
                name={
                  guest!.name ||
                  props.googleMembers.find((member) => member.mailAddress === guest.mailAddress)
                    ?.name ||
                  guest.mailAddress ||
                  '-'
                }
              />
              <Typography variant={TypographyType.Normal} style={{ marginLeft: 5 }}>
                {guest!.name ||
                  props.googleMembers.find((member) => member.mailAddress === guest.mailAddress)
                    ?.name ||
                  guest.mailAddress ||
                  '-'}
              </Typography>
            </View>
            <CloseIcon
              size={18}
              onPress={() => {
                props.setGuests(
                  props.guests.filter((value) => value.mailAddress !== guest.mailAddress)
                );
              }}
            />
          </View>
        );
      })}
    </CustomScrollView>
  );
};

interface IEditGoogleCalendarEventModalProps {
  googleCalendarEvent: GoogleCalendarEvent;
  showModal: boolean;
  onPressYes: (value: GoogleCalendarEvent) => void;
  onCloseModal: () => void;
}

const EditGoogleCalendarEventModal = React.memo((props: IEditGoogleCalendarEventModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, _] = useContext(LoginUserContext);
  const [title, setTitle] = useState(props.googleCalendarEvent.title);
  const [location, setLocation] = useState(props.googleCalendarEvent.location);
  const [description, setDescription] = useState(props.googleCalendarEvent.description);
  const [startDateTime, setStartDateTime] = useState<moment.Moment>(
    moment(props.googleCalendarEvent.startDateTime)
  );
  const [endDateTime, setEndDateTime] = useState<moment.Moment>(
    moment(props.googleCalendarEvent.endDateTime)
  );
  const [guests, setGuests] = useState<MailAddressAndName[]>(
    props.googleCalendarEvent.attendees?.map((attend) => {
      return {
        mailAddress: attend!.mailAddress!,
        name: attend!.name || null,
      } as MailAddressAndName;
    }) || []
  );
  const [focusEndDateTimeInput, setFocusEndDateTimeInput] = useState(false);
  const history = useHistory();
  const { loading: myGoogleAccountLoading, data: myGoogleAccountData } = useMyGoogleAccountQuery({
    fetchPolicy: 'network-only',
    onError: (e) => {
      if (e.graphQLErrors.find((ge) => ge.extensions?.code === 'google-token-update-failure')) {
        window.alert(
          'Googleカレンダーの情報取得が出来なくなりました。申し訳ありませんが「Googleアカウント連携」メニューからもう一度Googleアカウントを連携し直してください。'
        );
        history.push(`/app/${loginUser!.organizationId}/my-settings/google/`);
      }
      if (e.graphQLErrors.find((ge) => ge.extensions?.code === 'google-account-permission-error')) {
        window.alert(
          'Googleカレンダーの情報取得ができませんでした。「Googleアカウント連携」メニューからもう一度Googleアカウントを連携し直してください。その際にカレンダーへのアクセスを許可してください。'
        );
        history.push(`/app/${loginUser!.organizationId}/my-settings/google/`);
      }
    },
  });
  const { loading, data } = useGoogleWorkSpaceMembersQuery({
    fetchPolicy: 'cache-first',
    skip: !myGoogleAccountData?.myGoogleAccount,
  });
  const { loading: organizationLoading, data: organizationData } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId,
    },
  });

  const [updateGoogleCalendarEvent, __] = useUpdateGoogleCalendarEventMutation({
    variables: {
      id: props.googleCalendarEvent.id!,
      input: {
        calendarId: props.googleCalendarEvent.calendarId!,
        title: title,
        location: location || null,
        description: description || null,
        start: startDateTime?.toISOString(),
        end: endDateTime?.toISOString(),
        attendeeMailAddresses: guests.map((guest) => guest.mailAddress),
      },
    },
    optimisticResponse: {
      __typename: 'Mutation',
      updateGoogleCalendarEvent: Object.assign(
        {
          __typename: 'GoogleCalendarEvent',
        },
        props.googleCalendarEvent,
        {
          title: title,
          location: location || null,
          description: description || null,
          start: startDateTime?.toISOString(),
          end: endDateTime?.toISOString(),
          attendeeMailAddresses: guests.map((guest) => guest.mailAddress),
        }
      ),
    },
  });

  useEffect(() => {
    setTitle(props.googleCalendarEvent.title);
    setLocation(props.googleCalendarEvent.location);
    setDescription(props.googleCalendarEvent.description);
    setStartDateTime(moment(props.googleCalendarEvent.startDateTime));
    setEndDateTime(moment(props.googleCalendarEvent.endDateTime));
    setGuests(
      props.googleCalendarEvent.attendees?.map((attend) => {
        return {
          mailAddress: attend!.mailAddress!,
          name:
            data?.googleWorkSpaceMembers?.find(
              (member) => member?.mailAddress === attend?.mailAddress
            )?.name || null,
        } as MailAddressAndName;
      }) || []
    );
  }, [props.googleCalendarEvent, props.showModal]);

  if (loading || myGoogleAccountLoading || organizationLoading) {
    return <></>;
  }

  return (
    <Modal
      title={'Googleカレンダー予定の編集'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <Form>
        <Input
          name={'title'}
          label={'タイトル'}
          type={'text'}
          initialValue={title || ''}
          value={title || ''}
          onChange={setTitle}
          validate={{
            required: {
              value: true,
              message: 'タイトルを記入してください',
            },
          }}
        />
        <View style={{ flexDirection: 'row', alignItems: 'center', zIndex: 3 }}>
          <View style={{ minWidth: 90 }}>
            <Typography variant={TypographyType.Normal} style={{ fontSize: 16, lineHeight: 22 }}>
              開始日時
            </Typography>
          </View>
          <EditableText
            value={startDateTime}
            type={'date-time-picker'}
            textStyle={{ fontSize: 18, lineHeight: 22 }}
            containerStyle={{
              borderBottomWidth: 1,
              borderBottomColor: themeContext.colors.textColor,
            }}
            disableClear={true}
            onChange={(value) => {
              setStartDateTime(value as moment.Moment);
            }}
          />
        </View>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: 20,
            zIndex: 2,
          }}>
          <View style={{ minWidth: 90 }}>
            <Typography variant={TypographyType.Normal} style={{ fontSize: 16, lineHeight: 22 }}>
              終了日時
            </Typography>
          </View>
          <EditableText
            value={endDateTime}
            type={'date-time-picker'}
            textStyle={{ fontSize: 18, lineHeight: 22 }}
            containerStyle={{
              borderBottomWidth: 1,
              borderBottomColor: themeContext.colors.textColor,
            }}
            disableClear={true}
            onChange={(value) => {
              setEndDateTime(value as moment.Moment);
            }}
          />
        </View>
        {(organizationData!.organization!.plan.code === Plan.Business ||
          organizationData!.organization!.plan.code === Plan.Enterprise) && (
          <>
            {props.googleCalendarEvent.organizer && (
              <View style={{ marginVertical: 15 }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{ fontSize: 18, lineHeight: 22 }}>
                  {`主催者: ${
                    props.googleCalendarEvent.organizer.name ||
                    data?.googleWorkSpaceMembers?.find(
                      (member) =>
                        member?.mailAddress === props.googleCalendarEvent.organizer?.mailAddress
                    )?.name ||
                    '-'
                  }`}
                </Typography>
              </View>
            )}

            <View style={{ marginTop: 10 }}>
              <Input
                name={'member'}
                label={'ゲストを追加'}
                type={'picker'}
                isSearchable={true}
                pickerItems={data?.googleWorkSpaceMembers
                  ?.filter(
                    (member) =>
                      member!.mailAddress !== myGoogleAccountData?.myGoogleAccount?.mailAddress
                  )
                  .map((member) => {
                    return {
                      label: member!.name,
                      value: member!.mailAddress,
                    };
                  })}
                onChange={(value) => {
                  if (guests.map((guest) => guest.mailAddress).lastIndexOf(value) === -1) {
                    setGuests([
                      ...guests,
                      {
                        mailAddress: value,
                        name:
                          data?.googleWorkSpaceMembers?.find(
                            (member) => member?.mailAddress === value
                          )?.name || null,
                      },
                    ]);
                  }
                }}
              />
            </View>
            <GuestList
              guests={guests}
              setGuests={setGuests}
              googleMembers={data!.googleWorkSpaceMembers! as GoogleAccount[]}
            />
          </>
        )}
        {startDateTime?.isAfter(endDateTime) && (
          <View style={{ marginTop: 10 }}>
            <Typography
              variant={TypographyType.Description}
              style={{ color: themeContext.colors.error }}>
              開始日時は、終了日時よりも前にしてください
            </Typography>
          </View>
        )}
        {(!startDateTime || !endDateTime) && (
          <View style={{ marginTop: 10 }}>
            <Typography
              variant={TypographyType.Description}
              style={{ color: themeContext.colors.error }}>
              開始日時・終了日時を入力してください
            </Typography>
          </View>
        )}
        <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
          <Button
            text={'変更する'}
            style={{ minWidth: 100, marginRight: 10, marginVertical: 10 }}
            isDisabled={startDateTime?.isAfter(endDateTime) || !startDateTime || !endDateTime}
            onPress={async () => {
              const { data } = await updateGoogleCalendarEvent();
              props.onPressYes(data!.updateGoogleCalendarEvent!);
            }}
          />
          <Button
            text={'キャンセル'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.primary }}
            disableValidate={true}
            onPress={() => {
              props.onCloseModal();
            }}
          />
        </View>
      </Form>
      <View style={{ marginVertical: 10, alignItems: 'center' }}>
        <OpenLinkIcon size={14}>
          <TouchableOpacity
            style={{ marginLeft: 3 }}
            onPress={() => {
              Linking.openURL(
                `https://www.google.com/calendar/event?eid=${base64
                  .encode(props.googleCalendarEvent.id + ' ' + props.googleCalendarEvent.calendarId)
                  .replace('==', '')}`
              );
            }}>
            <Typography
              variant={TypographyType.Normal}
              style={{ fontSize: 16, color: themeContext.colors.link }}>
              Googleカレンダーで開く
            </Typography>
          </TouchableOpacity>
        </OpenLinkIcon>
      </View>
    </Modal>
  );
});

export default EditGoogleCalendarEventModal;
