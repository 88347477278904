import React, { useContext } from 'react';
import { View, Text, StyleProp, ViewStyle, TextStyle } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../theme';
import List, { ListItemData } from '../list';
import StandardList from '../standard-list';

interface IContainerProps extends IStyleTheme {
  dragging: boolean;
}

const Container = styled.View<IContainerProps>`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0 0.7rem;
  background-color: ${(props: IContainerProps) =>
    props.dragging ? '#FFFFEE' : props.theme.colors.baseColor};
  min-height: 3rem;
  border-bottom-width: 1px;
  border-bottom-color: ${(props: IContainerProps) => `${props.theme.colors.separator}`};
  opacity: ${(props: IContainerProps) => (props.dragging ? '0.7' : '1')};
  transition: all 0.4s;
`;

const renderItem = (
  item: ListItemData<string>,
  dragging: boolean,
  textStyle?: StyleProp<TextStyle>
) => {
  return (
    <Container dragging={dragging}>
      <Text style={[textStyle]}>{item.data}</Text>
    </Container>
  );
};

interface Props {
  items: ListItemData<string>[];
  showGroupHeader?: boolean;
  useStickyHeader?: boolean;
  onPress?: (item: ListItemData<string>) => void;
  style?: StyleProp<ViewStyle>;
  headerText?: string;
  headerStyle?: StyleProp<ViewStyle>;
  headerTextStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  filter?: (item: ListItemData<string>) => boolean;
  draggable?: boolean;
  onDrop?: (
    startIndex: number,
    endIndex: number,
    startColumnIndex: number,
    endColumnIndex: number,
    item: ListItemData<string>,
    sortedItems: ListItemData<string>[]
  ) => void;
}

const TextList = (props: Props) => {
  return (
    <StandardList
      items={props.items}
      getKey={(item) => item.data}
      showGroupHeader={props.showGroupHeader}
      useStickyHeader={props.useStickyHeader}
      onPress={props.onPress}
      draggable={props.draggable}
      onDrop={props.onDrop}
      style={props.style}
      headerText={props.headerText}
      headerStyle={props.headerStyle}
      headerTextStyle={props.headerTextStyle}
      filter={props.filter}
      renderItem={(item, dragging) => renderItem(item, dragging, props.textStyle)}
    />
  );
};

export default TextList;
