import React, { useContext, useEffect, useRef, useState } from 'react';
import { Platform, TextStyle } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../theme';
import when from '../../../../lang-extention/When';
import ToolTip from 'react-portal-tooltip';

interface ITextProps extends IStyleTheme {
  fontSize: string;
  fontWeight: number;
  lineHeight: string;
}

const Text = styled.Text<ITextProps>`
  color: ${(props: ITextProps) => props.theme.colors.textColor};
  font-size: ${(props: ITextProps) => props.fontSize};
  font-weight: ${(props: ITextProps) => props.fontWeight};
  line-height: ${(props: ITextProps) => props.lineHeight};
`;

export enum TypographyType {
  Title,
  SubTitle,
  ElementiTitle,
  Normal,
  Description,
  Button,
  MenuItemActive,
  MenuItemNonActive,
}

interface IProps {
  variant: TypographyType;
  style?: TextStyle;
  children?: React.ReactNode;
  numberOfLines?: number;
  ellipsis?: boolean;
  textRef?: any;
  tooltipWhenEllipsis?: boolean;
  tooltipId?: string;
}

const Typography: React.FC<IProps> = (props: IProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const refOuter = useRef<HTMLSpanElement>(null);
  const ref = props.textRef || useRef();
  const [isEllipsis, setIsEllipsis] = useState<boolean>(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const textStyle = when(props.variant)
    .on(
      (v) => v === TypographyType.Title,
      () => {
        return {
          color: themeContext.colors.textColor,
          fontFamily: 'NotoSansJP-Bold',
          fontSize: '1.5rem',
          lineHeight: '2rem',
          fontWeight: 600,
        };
      }
    )
    .on(
      (v) => v === TypographyType.SubTitle,
      () => {
        return {
          color: themeContext.colors.textColor,
          fontFamily: 'NotoSansJP-Medium',
          fontSize: '1.2rem',
          lineHeight: '1.8rem',
          fontWeight: 400,
        };
      }
    )
    .on(
      (v) => v === TypographyType.ElementiTitle,
      () => {
        return {
          color: themeContext.colors.textColor,
          fontFamily: 'NotoSansJP-Medium',
          fontSize: '1rem',
          lineHeight: '1.2rem',
          fontWeight: 600,
        };
      }
    )
    .on(
      (v) => v === TypographyType.Normal,
      () => {
        return {
          color: themeContext.colors.textColor,
          fontFamily: 'NotoSansJP-Regular',
          fontSize: '1rem',
          lineHeight: '1.6rem',
          fontWeight: 400,
        };
      }
    )
    .on(
      (v) => v === TypographyType.Description,
      () => {
        return {
          color: themeContext.colors.description,
          fontFamily: 'NotoSansJP-Light',
          fontSize: '0.8rem',
          lineHeight: '1rem',
          fontWeight: 400,
        };
      }
    )
    .on(
      (v) => v === TypographyType.Button,
      () => {
        return {
          color: themeContext.colors.butttonTextColor,
          fontFamily: 'NotoSansJP-Regular',
          fontSize: '1rem',
          lineHeight: '1.4rem',
          fontWeight: 400,
        };
      }
    )
    .on(
      (v) => v === TypographyType.MenuItemActive,
      () => {
        return {
          color: themeContext.colors.primary,
          fontFamily: 'NotoSansJP-Medium',
          fontSize: '1rem',
          lineHeight: '1.4rem',
          fontWeight: 400,
        };
      }
    )
    .on(
      (v) => v === TypographyType.MenuItemNonActive,
      () => {
        return {
          color: themeContext.colors.primary,
          fontFamily: 'NotoSansJP-Medium',
          fontSize: '1rem',
          lineHeight: '1.4rem',
          fontWeight: 400,
        };
      }
    )
    .otherwise(() => {
      return {
        color: themeContext.colors.primary,
        fontFamily: 'NotoSansJP-Regular',
        fontSize: '1rem',
        lineHeight: '1.2rem',
        fontWeight: 400,
      };
    });

  useEffect(() => {
    if (!props.tooltipWhenEllipsis || !props.tooltipId) {
      return () => {};
    }
    const resizeObserver = new ResizeObserver(() => {
      const inner = ref.current?.getBoundingClientRect();
      const outer = refOuter?.current?.getBoundingClientRect();
      setIsEllipsis((outer?.width ?? 0) < (inner?.width ?? 0));
    });
    const el = refOuter.current as Element;
    resizeObserver.observe(el);
    return () => resizeObserver.unobserve(el);
  });

  if (props.tooltipWhenEllipsis && props.tooltipId) {
    return (
      <span
        id={`${props.tooltipId}`}
        ref={refOuter as any}
        style={{ overflow: 'hidden', paddingRight: isEllipsis ? 10 : 0 }}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}>
        <Text
          ref={ref}
          fontSize={Platform.OS === 'web' ? textStyle.fontSize : '20px'} //TODO
          fontWeight={textStyle.fontWeight}
          lineHeight={
            Platform.OS === 'web'
              ? props.style?.lineHeight !== null && props.style?.lineHeight !== undefined
                ? `${props.style!.lineHeight}px`
                : textStyle.lineHeight
              : typeof props.style?.fontSize === 'number'
              ? `${Math.floor(props.style.fontSize * 1.5)}px`
              : '20px'
          } // このあたりは設計がいまいち。lineHeightをpx指定で渡す感じになっている。
          numberOfLines={props.numberOfLines ? props.numberOfLines : props.ellipsis ? 1 : undefined}
          style={
            [
              { color: textStyle.color },
              props.style,
              props.ellipsis
                ? Platform.OS === 'web'
                  ? { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }
                  : { overflow: 'hidden' }
                : {},
            ] as any
          }>
          {(props as any).children}
        </Text>
        {isEllipsis && <span style={{ position: 'absolute', right: -16, bottom: 0 }}>...</span>}
        <ToolTip
          active={isEllipsis && showTooltip}
          position="right"
          arrow="center"
          tooltipTimeout={1}
          parent={`#${props.tooltipId}`}>
          {props.children}
        </ToolTip>
      </span>
    );
  }
  return (
    <Text
      ref={ref}
      fontSize={Platform.OS === 'web' ? textStyle.fontSize : '20px'} //TODO
      fontWeight={textStyle.fontWeight}
      lineHeight={
        Platform.OS === 'web'
          ? props.style?.lineHeight !== null && props.style?.lineHeight !== undefined
            ? `${props.style!.lineHeight}px`
            : textStyle.lineHeight
          : typeof props.style?.fontSize === 'number'
          ? `${Math.floor(props.style.fontSize * 1.5)}px`
          : '20px'
      } // このあたりは設計がいまいち。lineHeightをpx指定で渡す感じになっている。
      numberOfLines={props.numberOfLines ? props.numberOfLines : props.ellipsis ? 1 : undefined}
      style={
        [
          { color: textStyle.color },
          props.style,
          props.ellipsis
            ? Platform.OS === 'web'
              ? { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }
              : { overflow: 'hidden' }
            : {},
        ] as any
      }>
      {(props as any).children}
    </Text>
  );
};

export default React.memo(Typography);
