import queryString from 'query-string';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Animated, type NativeSyntheticEvent, View } from 'react-native';
import {
  type GestureHandlerStateChangeNativeEvent,
  PanGestureHandler,
  type PanGestureHandlerEventPayload,
  State,
} from 'react-native-gesture-handler';
import { useHistory, useLocation, useParams } from 'react-router';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import { type ProjectFilter, ProjectFilterContext } from '..';
import {
  type Member,
  type Organization,
  Plan,
  type Project,
  ProjectFavoriteFilter,
  type ProjectStatus,
  type Team,
  useBoardProjectsQuery,
  useMeQuery,
  useOrganizationQuery,
  useTeamProjectStatusQuery,
  useTeamQuery,
  useUpdateProjectStatusMutation,
  useUpdateStatusOfProjectMutation,
} from '../../../../../../../graphql/api/API';
import Button from '../../../../../../presentational/atoms/button';
import EditableText from '../../../../../../presentational/atoms/editable-text';
import { ListItemData } from '../../../../../../presentational/atoms/list';
import VirtualizedFlatList, {
  GlobalDragColumnContextProvider,
  GlobalDragContextProvider,
  type IListRefInfo,
  NonForwardedRefAnimatedView,
} from '../../../../../../presentational/atoms/list2/virtualized-flat-list';
import Spinner from '../../../../../../presentational/atoms/spinner';
import Typography, { TypographyType } from '../../../../../../presentational/atoms/typography';
import CheckIcon from '../../../../../../presentational/molecules/image-icon/check';
import DeleteIcon from '../../../../../../presentational/molecules/image-icon/delete';
import MenuIcon from '../../../../../../presentational/molecules/image-icon/menu';
import PlusIcon from '../../../../../../presentational/molecules/image-icon/plus';
import Modal from '../../../../../../presentational/molecules/modal';
import type { IStyleTheme, IThemePart } from '../../../../../../theme';
import PlanNotAllowedView from '../../../../organisms/plan-not-allowed-view';
import ProjectStatusCreateDialog from '../../../../organisms/project-status-create-dialog';
import ProjectStatusDeleteDialog from '../../../../organisms/project-status-delete-dialog';
import ProjectSummaryForBoard from '../../../../organisms/project-summary-for-board';

const Container = styled.View`
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 0 20px;
  height: calc(100vh - 57px - 120px);
  width: 100%;
`;

const Menu = styled.View`
  position: absolute;
  top: 25px;
  right: 0;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
  border-width: 1px;
  border-color: ${(props: IStyleTheme) => props.theme.colors.separator};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

interface DropProjectInfo {
  startIndex: number;
  endIndex: number;
  startColumnIndex: number;
  endColumnIndex: number;
  item: Project;
  sortNo: number;
}

interface ICompleteProjectModalProps {
  project: Project;
  showModal: boolean;
  onPressYes: () => Promise<void>;
  onCloseModal: () => void;
}
const CompleteProjectConfirmModal = (props: ICompleteProjectModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  return (
    <Modal
      title={'このプロジェクトを完了しますか？'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <View style={{ flexDirection: 'column' }}>
        <View style={{ marginTop: 10 }}>
          <Typography variant={TypographyType.Normal} style={{ textAlign: 'center' }}>
            {props.project.name}
          </Typography>
        </View>
        <View style={{ marginTop: 10 }}>
          <Typography variant={TypographyType.Description} style={{ textAlign: 'center' }}>
            {`作業中のタスクがある場合、自動的に作業停止します。`}
          </Typography>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
          <Button
            text={'完了する'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
            }}
            onPress={async () => {
              await props.onPressYes();
            }}
          />
          <Button
            text={'キャンセル'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.primary }}
            disableValidate={true}
            onPress={() => {
              props.onCloseModal();
            }}
          />
        </View>
      </View>
    </Modal>
  );
};

interface IColumnHeaderMenuProps {
  team: Team;
  projectStatus: ProjectStatus;
  openMenu: boolean;
  setOpenMenu: (value: boolean) => void;
  onClose: () => void;
}

const ColumnHeaderMenu = (props: IColumnHeaderMenuProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [showStatusDeleteModal, setShowStatusDeleteModal] = useState(false);
  const [showTaskArchiveModal, setShowTaskArchiveModal] = useState(false);

  const menuRef = useRef();
  const clickDocument = (e: any) => {
    if ((menuRef?.current as any)?.contains(e.target)) {
      (menuRef?.current as any)?.click();
      return;
    }
    props.onClose();
  };
  useEffect(() => {
    window.addEventListener('click', clickDocument);
    return () => {
      window.removeEventListener('click', clickDocument);
    };
  }, [clickDocument]);

  return (
    <>
      {props.openMenu && (
        <Menu ref={menuRef as any}>
          {!props.projectStatus.endStatus && (
            <>
              <DeleteIcon
                size={23}
                onPress={() => {
                  setShowStatusDeleteModal(true);
                  props.setOpenMenu(false);
                }}>
                <Typography variant={TypographyType.Normal}>列を削除する</Typography>
              </DeleteIcon>
            </>
          )}
        </Menu>
      )}
      <Modal
        isShow={showStatusDeleteModal}
        title="ステータスを削除する"
        onClose={() => setShowStatusDeleteModal(false)}>
        <ProjectStatusDeleteDialog
          team={props.team}
          projectStatus={props.projectStatus}
          onCancel={() => setShowStatusDeleteModal(false)}
          onComplete={() => setShowStatusDeleteModal(false)}
        />
      </Modal>
    </>
  );
};

interface IColumnHeaderProps {
  team: Team;
  projectStatus: ProjectStatus;
  isDragColumn: boolean;
}

const ColumnHeader = React.memo((props: IColumnHeaderProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [openMenu, setOpenMenu] = useState(false);
  const [updateProjectStatus, _] = useUpdateProjectStatusMutation();

  return (
    <View
      style={
        {
          marginHorizontal: 10,
          paddingBottom: 15,
          flexDirection: 'row',
          cursor: props.isDragColumn ? 'grabbing' : 'grab',
        } as any
      }>
      {props.projectStatus.endStatus && (
        <CheckIcon size={18} on={true} containerStyle={{ marginRight: 10 }} />
      )}
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
        <View>
          <EditableText
            style={{ width: '100%' }}
            value={props.projectStatus.name}
            validate={{
              required: {
                value: true,
                message: 'ステータス名を入力してください',
              },
              maxLength: {
                value: 20,
                message: '20文字以内で入力してください',
              },
            }}
            onChange={(value) => {
              updateProjectStatus({
                variables: {
                  id: props.projectStatus.id!,
                  input: {
                    name: (value as string) || props.projectStatus.name,
                    sortNo: props.projectStatus.sortNo,
                    versionNo: props.projectStatus.versionNo,
                  },
                },
              });
            }}
            textStyle={{ fontSize: 16, maxWidth: 200 }}
          />
        </View>
        {!props.projectStatus.endStatus && (
          <View>
            <MenuIcon size={28} onPress={() => setOpenMenu(true)} />
          </View>
        )}
      </View>
      <ColumnHeaderMenu
        team={props.team}
        projectStatus={props.projectStatus}
        openMenu={openMenu}
        setOpenMenu={setOpenMenu}
        onClose={() => setOpenMenu(false)}
      />
    </View>
  );
});

interface IColumnAddButtonProps {
  team: Team;
}

const ColumnAddButton = (props: IColumnAddButtonProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [showDialog, setShowDialog] = useState(false);
  return (
    <View
      style={{
        marginLeft: 5,
        width: 40,
        height: 40,
        borderRadius: 5,
        backgroundColor: themeContext.colors.separator,
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <PlusIcon size={28} onPress={() => setShowDialog(true)} />
      <Modal
        isShow={showDialog}
        title="新しいステータスを追加する"
        onClose={() => {
          setShowDialog(false);
        }}>
        <ProjectStatusCreateDialog
          team={props.team}
          onComplete={(Status) => setShowDialog(false)}
          onCancel={() => setShowDialog(false)}
        />
      </Modal>
    </View>
  );
};

interface ProjectStatusGroup {
  projectStatus: ProjectStatus;
  projects: Array<Project>;
}

interface IListProps {
  organization: Organization;
  team: Team;
  me: Member;
  group: ProjectStatusGroup;
  column: number;
  listRefs: Array<IListRefInfo>;
  width: number;
  getProjectStatusGroup: (statusColumnIndex: number) => ProjectStatusGroup;
  getAllProjectStatusGroup: () => ProjectStatusGroup[];
  setListRefs: (value: Array<IListRefInfo>) => void;
  setDragItem: (value: boolean) => void;
  onEndReached: () => void;
  listOuterRef: any;
}

const List = React.memo((props: IListProps) => {
  const history = useHistory();
  const { search } = useLocation();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [confirmProjectInfo, setConfirmProjectInfo] = useState<DropProjectInfo | null>(null);
  const [updateProjectSortNo, _] = useUpdateStatusOfProjectMutation();

  useEffect(() => {
    setConfirmProjectInfo(null);
  }, []);

  const onDrop = useCallback(
    async (info) => {
      const isMoveToFirst = info.endRowIndex === 0;
      const isMoveToLast =
        info.startColumnIndex === info.endColumnIndex
          ? info.endRowIndex ===
            props.getProjectStatusGroup(info.endColumnIndex).projects.length - 1
          : info.endRowIndex === props.getProjectStatusGroup(info.endColumnIndex).projects.length;
      const isMoveToDown = info.endRowIndex - info.startRowIndex > 0;
      let sortNo;
      if (isMoveToFirst) {
        sortNo = new Date().getTime();
      } else if (isMoveToLast) {
        sortNo =
          info.startColumnIndex === info.endColumnIndex
            ? props.getProjectStatusGroup(info.endColumnIndex).projects[info.endRowIndex]!
                .sortNoInProjectStatus - 1000
            : props.getProjectStatusGroup(info.endColumnIndex).projects[info.endRowIndex - 1]!
                .sortNoInProjectStatus - 1000;
      } else {
        if (isMoveToDown && info.startColumnIndex === info.endColumnIndex) {
          const beforeTask = props.getProjectStatusGroup(info.endColumnIndex).projects[
            info.endRowIndex
          ];
          const afterTask = props.getProjectStatusGroup(info.endColumnIndex).projects[
            info.endRowIndex + 1
          ];
          sortNo = Math.floor(
            (beforeTask!.sortNoInProjectStatus + afterTask!.sortNoInProjectStatus) / 2
          );
        } else {
          const beforeTask = props.getProjectStatusGroup(info.endColumnIndex).projects[
            info.endRowIndex - 1
          ];
          const afterTask = props.getProjectStatusGroup(info.endColumnIndex).projects[
            info.endRowIndex
          ];
          sortNo = Math.floor(
            (beforeTask!.sortNoInProjectStatus + afterTask!.sortNoInProjectStatus) / 2
          );
        }
      }

      if (props.getProjectStatusGroup(info.endColumnIndex).projectStatus.endStatus) {
        setConfirmProjectInfo(Object.assign(info, { sortNo: sortNo }));
        setTimeout(() => {
          setShowConfirmDialog(true);
        }, 200);
        return;
      }
      setConfirmProjectInfo(null);
      setShowConfirmDialog(false);

      await updateProjectSortNo({
        variables: {
          id: info.item.id!,
          input: {
            projectStatusId: props.getProjectStatusGroup(info.endColumnIndex).projectStatus.id!,
            sortNoInProjectStatus: sortNo,
            versionNo: info.item!.versionNo,
          },
        },
        update: (cache, result) => {
          cache.modify({
            fields: {
              boardProjects(existingProjectRef, { readField }) {
                // 別のステータスに移動した場合には、移動元の配列から削除して、移動先の配列に追加する必要がある。
                // refetchだと一瞬描画が追いつかず、だいぶ違和感のある動きになってしまうため、キャッシュを直接編集する
                if (info.startColumnIndex === info.endColumnIndex) {
                  return existingProjectRef;
                }

                const srcProjectStatusId = props.getProjectStatusGroup(info.startColumnIndex)
                  .projectStatus.id;
                const destProjctStatusId = props.getProjectStatusGroup(info.endColumnIndex)
                  .projectStatus.id;

                const isSrcProjectStatus =
                  existingProjectRef.projectStatus.__ref === `ProjectStatus:${srcProjectStatusId}`;
                const isDestTaskStatus =
                  existingProjectRef.projectStatus.__ref === `ProjectStatus:${destProjctStatusId}`;

                if (isSrcProjectStatus) {
                  return {
                    projectStatus: existingProjectRef.projectStatus,
                    projects: existingProjectRef.projects.filter(
                      (ref: any) =>
                        readField('id', ref) !== `Project:${result.data?.updateStatusOfProject?.id}`
                    ),
                  };
                }

                if (isDestTaskStatus) {
                  return {
                    projectStatus: existingProjectRef.projectStatus,
                    projects: [
                      ...existingProjectRef.projects,
                      { __ref: `Project:${result.data?.updateStatusOfProject?.id}` },
                    ],
                  };
                }

                return existingProjectRef;
              },
            },
          });
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateStatusOfProject: Object.assign(
            {
              __typename: 'Project',
            },
            info.item,
            { sortNoInProjectStatus: sortNo }
          ) as any,
        },
      });
    },
    [props.team, props.getProjectStatusGroup]
  );

  const renderItem = useCallback(
    (item, index) => {
      return (
        <ProjectSummaryForBoard
          organization={props.organization}
          team={props.team}
          project={item as Project}
        />
      );
    },
    [props.team]
  );

  const getKey = useCallback((project) => project!.id!.toString(), []);

  const addListRefs = useCallback(
    (info) => {
      props.listRefs.push(info);
      props.setListRefs(props.listRefs);
    },
    [props.listRefs]
  );

  const updateListRefs = useCallback(
    (info) => {
      const findIndex = props.listRefs.findIndex((ref) => ref.columnKey === info.columnKey);
      props.listRefs[findIndex] = info;
      props.setListRefs(props.listRefs);
    },
    [props.listRefs, props.setListRefs]
  );

  const onPress = useCallback(
    (project) => {
      history.push(
        `/app/${props.organization.id!}/${props.team.id}/projects/view/board/?` +
          queryString.stringify(
            Object.assign(queryString.parse(search), {
              projectDetailId: (project as Project).id,
            })
          ) + '&projectDetailMode=standard'
      );
    },
    [props.organization.id!, props.team]
  );

  const onDragStart = useCallback(() => props.setDragItem(true), [props.setDragItem]);
  const onDropEnd = useCallback(() => props.setDragItem(false), [props.setDragItem]);
  return (
    <>
      <VirtualizedFlatList
        style={{
          height: 'calc(100% - 60px)',
        }}
        listOuterRef={props.listOuterRef}
        items={props.group!.projects}
        column={props.column}
        columnKey={props.group!.projectStatus.id!}
        allColumns={props.getAllProjectStatusGroup().map((group) => group.projects)}
        renderItem={renderItem}
        getKey={getKey}
        listRefs={props.listRefs}
        addListRefs={addListRefs}
        updateListRefs={updateListRefs}
        itemHeight={90}
        itemWidth={props.width}
        onPress={onPress}
        virticalDraggable={true}
        horizontalDraggable={true}
        onDragStart={onDragStart}
        onDropEnd={onDropEnd}
        onDrop={onDrop}
        onEndReached={props.onEndReached}
        key={props.group!.projectStatus.id}
      />
      {confirmProjectInfo && (
        <CompleteProjectConfirmModal
          project={confirmProjectInfo!.item}
          showModal={showConfirmDialog}
          onPressYes={async () => {
            await updateProjectSortNo({
              variables: {
                id: confirmProjectInfo.item.id!,
                input: {
                  projectStatusId: props.getProjectStatusGroup(confirmProjectInfo.endColumnIndex)
                    .projectStatus.id!,
                  sortNoInProjectStatus: confirmProjectInfo.sortNo,
                  versionNo: confirmProjectInfo.item.versionNo,
                },
              },
              update: (cache, result) => {
                cache.modify({
                  fields: {
                    boardProjects(existingProjectRef, { readField }) {
                      // 別のステータスに移動した場合には、移動元の配列から削除して、移動先の配列に追加する必要がある。
                      // refetchだと一瞬描画が追いつかず、だいぶ違和感のある動きになってしまうため、キャッシュを直接編集する
                      if (
                        confirmProjectInfo.startColumnIndex === confirmProjectInfo.endColumnIndex
                      ) {
                        return existingProjectRef;
                      }

                      const srcProjectStatusId = props.getProjectStatusGroup(
                        confirmProjectInfo.startColumnIndex
                      ).projectStatus.id;
                      const destProjctStatusId = props.getProjectStatusGroup(
                        confirmProjectInfo.endColumnIndex
                      ).projectStatus.id;

                      const isSrcProjectStatus =
                        existingProjectRef.projectStatus.__ref ===
                        `ProjectStatus:${srcProjectStatusId}`;
                      const isDestTaskStatus =
                        existingProjectRef.projectStatus.__ref ===
                        `ProjectStatus:${destProjctStatusId}`;

                      if (isSrcProjectStatus) {
                        return {
                          projectStatus: existingProjectRef.projectStatus,
                          projects: existingProjectRef.projects.filter(
                            (ref: any) =>
                              readField('id', ref) !==
                              `Project:${result.data?.updateStatusOfProject?.id}`
                          ),
                        };
                      }

                      if (isDestTaskStatus) {
                        return {
                          projectStatus: existingProjectRef.projectStatus,
                          projects: [
                            ...existingProjectRef.projects,
                            { __ref: `Project:${result.data?.updateStatusOfProject?.id}` },
                          ],
                        };
                      }

                      return existingProjectRef;
                    },
                  },
                });
              },
              optimisticResponse: {
                __typename: 'Mutation',
                updateStatusOfProject: Object.assign(
                  {
                    __typename: 'Project',
                  },
                  confirmProjectInfo.item,
                  { sortNoInProjectStatus: confirmProjectInfo.sortNo }
                ) as any,
              },
            });
            setShowConfirmDialog(false);
          }}
          onCloseModal={() => setShowConfirmDialog(false)}
        />
      )}
    </>
  );
});

interface IColumnProps {
  organization: Organization;
  team: Team;
  me: Member;
  projectStatus: ProjectStatus;
  registerProjects: (projects: Project[]) => void;
  getProjectStatusGroup: (statusColumnIndex: number) => ProjectStatusGroup;
  getAllProjectStatusGroup: () => ProjectStatusGroup[];
  column: number;
  listRefs: Array<IListRefInfo>;
  width: number;
  isNotAllowedPlan: boolean;
  setListRefs: (value: Array<IListRefInfo>) => void;
}

const Column = (props: IColumnProps) => {
  const pageSize = 30;
  const [projectFilter, __] = useContext(ProjectFilterContext);
  const [translationX, setTranslationX] = useState(new Animated.Value(0));
  const [isDragItem, setDragItem] = useState(false);
  const [isDragColumn, setDragColumn] = useState(false);
  const [dragColumnInfo, setDragColumnInfo] = useContext(ColumnDragContext);
  const listOuterRef = useRef();
  const [updateProjectStatus, _] = useUpdateProjectStatusMutation();
  const { loading, data, fetchMore } = useBoardProjectsQuery({
    variables: {
      teamId: props.team.id,
      projectStatusId: props.projectStatus.id,
      assigneeIds: (projectFilter as ProjectFilter).assignerIds || [],
      clientIds: (projectFilter as ProjectFilter).clientIds || [],
      favoriteCondition: (projectFilter as ProjectFilter).favoriteOnly
        ? ProjectFavoriteFilter.Favorite
        : null,
      projectName: (projectFilter as ProjectFilter).projectName ?? null,
      offset: 0,
      limit: pageSize,
    },
    fetchPolicy: 'network-only',
  });

  // Memo化しておかないと、データに変更がなくても、このコンポーネントが描画される度に、全てのTaskSummary再描画がされてしまう。
  const projects = useMemo(() => {
    if (!data?.boardProjects) {
      return [];
    }
    return data!
      .boardProjects!.projects! // .filter((project) => {
      .map((project) => {
        if (!props.isNotAllowedPlan) {
          return project;
        }
        // カンバンボードを利用不可能なプランの場合には、ダミー情報に書き換えて使えないようにする
        return Object.assign({}, project, {
          id: (-1 * Math.random()).toString(),
          name: '-',
        });
      })
      .sort((a, b) => {
        return b!.sortNoInProjectStatus - a!.sortNoInProjectStatus; // ソート番号は降順
      });
  }, [loading, data?.boardProjects, props.isNotAllowedPlan]) as Project[];

  props.registerProjects(projects);

  const dudListener = useCallback(
    (
      event: NativeSyntheticEvent<
        PanGestureHandlerEventPayload & GestureHandlerStateChangeNativeEvent
      >
    ) => {
      if (event.nativeEvent.state === State.BEGAN) {
        setDragColumn(true);
        //@ts-ignore
        setDragColumnInfo({
          startColumn: props.column,
          endColumn: props.column,
          dragging: true,
        });
      }
      if (event.nativeEvent.state === State.ACTIVE) {
        const moveColumn = event.nativeEvent.translationX / props.width;
        let afterColumn = Math.round(props.column + moveColumn);
        afterColumn = Math.max(afterColumn, 0);
        afterColumn = Math.min(afterColumn, props.getAllProjectStatusGroup().length - 1);

        if ((dragColumnInfo as DragColumnInfo).endColumn != afterColumn) {
          //@ts-ignore
          setDragColumnInfo({
            startColumn: props.column,
            endColumn: afterColumn,
            dragging: true,
          });
        }
      }
      if (event.nativeEvent.state === State.END) {
        const moveColumn = event.nativeEvent.translationX / props.width;
        let afterColumn = Math.round(props.column + moveColumn);
        afterColumn = Math.max(afterColumn, 0);
        afterColumn = Math.min(afterColumn, props.getAllProjectStatusGroup().length - 1);

        setDragColumn(false);
        //@ts-ignore
        setDragColumnInfo({
          startColumn: props.column,
          endColumn: afterColumn,
          dragging: false,
        });

        if (props.column !== afterColumn) {
          const isMoveToFirst = afterColumn === 0;
          const isMoveToLast = afterColumn === props.getAllProjectStatusGroup().length - 1;
          const isMoveToDown = afterColumn - props.column > 0;

          let sortNo;
          if (isMoveToFirst) {
            sortNo = props.getProjectStatusGroup(afterColumn).projectStatus.sortNo - 1000;
          } else if (isMoveToLast) {
            sortNo = new Date().getTime();
          } else {
            if (isMoveToDown) {
              const beforeTaskStatus = props.getProjectStatusGroup(afterColumn).projectStatus;
              const afterTaskStatus = props.getProjectStatusGroup(afterColumn + 1).projectStatus;
              sortNo = Math.floor((beforeTaskStatus!.sortNo + afterTaskStatus!.sortNo) / 2);
            } else {
              const beforeTaskStatus = props.getProjectStatusGroup(afterColumn - 1).projectStatus;
              const afterTaskStatus = props.getProjectStatusGroup(afterColumn).projectStatus;
              sortNo = Math.floor((beforeTaskStatus!.sortNo + afterTaskStatus!.sortNo) / 2);
            }
          }

          updateProjectStatus({
            variables: {
              id: props.projectStatus.id!,
              input: {
                name: props.projectStatus.name,
                sortNo: sortNo,
                versionNo: props.projectStatus.versionNo,
              },
            },
            optimisticResponse: {
              __typename: 'Mutation',
              updateProjectStatus: Object.assign(
                {
                  __typename: 'ProjectStatus',
                },
                props.projectStatus,
                { sortNo: sortNo }
              ),
            },
          });
        }
      }
    },
    [
      setDragColumn,
      setDragColumnInfo,
      dragColumnInfo,
      props.getAllProjectStatusGroup,
      props.getProjectStatusGroup,
    ]
  );

  useEffect(() => {
    if ((dragColumnInfo as DragColumnInfo).dragging) {
      if (
        (dragColumnInfo as DragColumnInfo).endColumn !==
        (dragColumnInfo as DragColumnInfo).startColumn
      ) {
        if (
          (dragColumnInfo as DragColumnInfo).endColumn >
            (dragColumnInfo as DragColumnInfo).startColumn &&
          (dragColumnInfo as DragColumnInfo).endColumn >= props.column &&
          (dragColumnInfo as DragColumnInfo).startColumn < props.column
        ) {
          Animated.timing(translationX, {
            toValue: -props.width,
            duration: 200,
            useNativeDriver: true,
          }).start();
        } else if (
          (dragColumnInfo as DragColumnInfo).endColumn <
            (dragColumnInfo as DragColumnInfo).startColumn &&
          (dragColumnInfo as DragColumnInfo).endColumn <= props.column &&
          (dragColumnInfo as DragColumnInfo).startColumn > props.column
        ) {
          Animated.timing(translationX, {
            toValue: props.width,
            duration: 200,
            useNativeDriver: true,
          }).start();
        } else {
          Animated.timing(translationX, {
            toValue: 0,
            duration: 200,
            useNativeDriver: true,
          }).start();
        }
      } else {
        Animated.timing(translationX, {
          toValue: 0,
          duration: 200,
          useNativeDriver: true,
        }).start();
      }
    } else {
      Animated.timing(translationX, {
        toValue: 0,
        duration: 0,
        useNativeDriver: true,
      }).start();
    }
  }, [dragColumnInfo]);

  return (
    <Animated.View
      ref={listOuterRef as any}
      style={
        {
          zIndex: isDragColumn ? 3 : 0,
          transform:
            !isDragColumn && isDragItem
              ? []
              : [
                  {
                    translateX: translationX,
                  },
                ],
        } as any
      }>
      <View
        style={{
          width: props.width,
          height: 'calc(100vh - 220px)',
          backgroundColor: '#f4f5f7',
          paddingVertical: 10,
          paddingHorizontal: 2,
          marginHorizontal: 5,
          borderRadius: 5,
        }}>
        <View
          style={{
            zIndex: 5,
          }}>
          <PanGestureHandler
            maxPointers={1}
            onGestureEvent={Animated.event([{ nativeEvent: { translationX: translationX } }], {
              listener: dudListener,
              useNativeDriver: true,
            })}
            onHandlerStateChange={(event) => {
              if (event.nativeEvent.state === State.BEGAN) {
                setTranslationX(new Animated.Value(0));
              }
              if (event.nativeEvent.state === State.ACTIVE) {
              }
              if (event.nativeEvent.state === State.END) {
                setTranslationX(new Animated.Value(0));
              }
            }}>
            <NonForwardedRefAnimatedView>
              <ColumnHeader
                projectStatus={props.projectStatus}
                team={props.team}
                isDragColumn={isDragColumn}
              />
            </NonForwardedRefAnimatedView>
          </PanGestureHandler>
        </View>
        <List
          organization={props.organization}
          team={props.team}
          me={props.me}
          group={{
            projectStatus: props.projectStatus,
            projects: projects,
          }}
          getProjectStatusGroup={props.getProjectStatusGroup}
          getAllProjectStatusGroup={props.getAllProjectStatusGroup}
          column={props.column}
          listRefs={props.listRefs}
          setListRefs={props.setListRefs}
          width={props.width}
          setDragItem={setDragItem}
          listOuterRef={listOuterRef}
          onEndReached={() => {
            if ((data?.boardProjects?.projects?.length ?? 0) < pageSize) {
              return;
            }
            fetchMore({
              variables: {
                offset: data!.boardProjects!.projects.length,
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return Object.assign({}, prev, {
                  boardProjects: {
                    projectStatus: prev.boardProjects!.projectStatus,
                    projects: [
                      ...(prev.boardProjects?.projects || []),
                      ...(fetchMoreResult.boardProjects?.projects || []),
                    ],
                  },
                });
              },
            });
          }}
        />
      </View>
    </Animated.View>
  );
};

interface IProjectBoardInnerProps {
  team: Team;
  organization: Organization;
  me: Member;
}

const ProjectBoardInner = (props: IProjectBoardInnerProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [notAllowedPlan, setNotAllowedPlan] = useState(false);
  const projectStatusGroups: ProjectStatusGroup[] = []; // この変数はReactのレンダリング管理対象とはしたくないため、あえてstateではなく通常の変数として扱っている
  const { loading, data } = useTeamProjectStatusQuery({
    variables: {
      teamId: props.team.id!,
    },
    fetchPolicy: 'network-only',
  });
  const [listRefs, setListRefs] = useState<Array<IListRefInfo>>([]);

  const projectStatuses = useMemo(() => {
    if (loading || !data?.teamProjectStatus) {
      return [];
    }
    return data!.teamProjectStatus!.slice().sort((a, b) => {
      return a!.sortNo - b!.sortNo; // ソート番号は昇順
    });
  }, [loading, data?.teamProjectStatus]) as ProjectStatus[];

  useEffect(() => {
    setInterval(() => {
      try {
        if (props.organization.plan.code === Plan.Basic) {
          setNotAllowedPlan(true);
        }
      } catch (_e) {
        // NOP
      }
    }, 1000);
  }, [props.organization]);

  if (loading) {
    return <></>;
  }

  return (
    <PlanNotAllowedView isNotAllowedPlan={notAllowedPlan}>
      <Container>
        <View
          style={{
            flexDirection: 'row',
            position: 'absolute',
            top: 40,
            // position: 'absolute', top: 85
          }}>
          <ColumnDragContextProvider>
            <GlobalDragContextProvider>
              <GlobalDragColumnContextProvider>
                {projectStatuses.map((projectStatus, i) => {
                  return (
                    <Column
                      organization={props.organization}
                      team={props.team}
                      me={props.me}
                      projectStatus={projectStatus}
                      registerProjects={(projects) => {
                        const group = projectStatusGroups.find(
                          (group) => group.projectStatus.id === projectStatus.id
                        );
                        if (group) {
                          const findIndex = projectStatusGroups.findIndex(
                            (g) => g.projectStatus.id === projectStatus.id
                          );
                          projectStatusGroups[findIndex].projects = projects;
                        } else {
                          projectStatusGroups.push({
                            projectStatus: projectStatus,
                            projects: projects,
                          });
                        }
                      }}
                      getAllProjectStatusGroup={() => projectStatusGroups}
                      getProjectStatusGroup={(statusColumnIndex) => {
                        const specifiedStatus = projectStatuses[statusColumnIndex];
                        return projectStatusGroups.find(
                          (group) => group.projectStatus.id === specifiedStatus.id
                        )!;
                      }}
                      column={i}
                      listRefs={listRefs}
                      setListRefs={setListRefs}
                      width={290}
                      isNotAllowedPlan={notAllowedPlan}
                      key={i}
                    />
                  );
                })}
              </GlobalDragColumnContextProvider>
            </GlobalDragContextProvider>
          </ColumnDragContextProvider>
          <ColumnAddButton team={props.team} />
        </View>
      </Container>
    </PlanNotAllowedView>
  );
};

interface IProjectBoardParam {
  teamId: string;
  organizationId: string;
}

const ProjectListBoard = () => {
  const params = useParams<IProjectBoardParam>();
  const { loading, data, error } = useTeamQuery({
    variables: {
      id: params.teamId,
    },
    fetchPolicy: 'network-only',
  });
  const fetchOrganization = useOrganizationQuery({
    variables: {
      id: params.organizationId,
    },
  });
  const { loading: meLoading, data: meData } = useMeQuery();

  if (
    loading ||
    !data?.team ||
    fetchOrganization.loading ||
    !fetchOrganization.data ||
    meLoading ||
    !meData?.me
  ) {
    return <></>;
  }

  return (
    <ProjectBoardInner
      organization={fetchOrganization.data!.organization!}
      team={data.team!}
      me={meData.me}
    />
  );
};

interface DragColumnInfo {
  startColumn: number;
  endColumn: number;
  dragging: boolean;
}

const ColumnDragContext = createContext([
  { startColumn: 0, endColumn: 0, dragging: false },
  (value: DragColumnInfo) => {},
]);

const ColumnDragContextProvider = (props: any) => {
  const [draggingPosIndex, setDraggingPosIndex] = useState<DragColumnInfo>({
    startColumn: 0,
    endColumn: 0,
    dragging: false,
  });
  return (
    <ColumnDragContext.Provider value={[draggingPosIndex as any, setDraggingPosIndex]}>
      {props.children}
    </ColumnDragContext.Provider>
  );
};

export default React.memo(ProjectListBoard);
