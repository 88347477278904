import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { View, TouchableOpacity, Image } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import {
  CustomerAttributeType,
  Member,
  Organization,
  Plan,
  Priority,
  ProjectTemplateSubTask,
  ProjectTemplateSubTaskDocument,
  ProjectTemplateTask,
  ProjectTemplateTaskCustomAttribute,
  ProjectTemplateTaskCustomAttributesByTaskDocument,
  ProjectTemplateTaskDocument,
  ProjectTemplateTaskTagsDocument,
  Tag,
  TaskCustomAttributeMaster,
  useAddProjectTemplateTaskCustomAttributeMutation,
  useCreateProjectTemplateSubTaskMutation,
  useDeleteProjectTemplateSubTaskMutation,
  useDeleteProjectTemplateTaskCustomAttributeMutation,
  useMeQuery,
  useOrganizationMembersQuery,
  useOrganizationQuery,
  useOrganizationTagsQuery,
  useProjectTemplateSubTasksQuery,
  useProjectTemplateTaskCustomAttributesByTaskQuery,
  useProjectTemplateTaskTagsQuery,
  useRelateProjectTemplateTaskTagsMutation,
  useTaskCustomAttributeMastersQuery,
  useUpdateProjectTemplateSubTaskMutation,
  useUpdateProjectTemplateSubTaskSortNoMutation,
  useUpdateProjectTemplateTaskCustomAttributeMutation,
  useUpdateProjectTemplateTaskMutation,
} from '../../../../../../graphql/api/API';
import EditableText from '../../../../../presentational/atoms/editable-text';
import Typography, { TypographyType } from '../../../../../presentational/atoms/typography';
import { IStyleTheme, IThemePart } from '../../../../../theme';
import TaskInfoElement from '../task-info-element';
import Form from '../../../../../presentational/atoms/form';
import { LoginUserContext } from '../../../../../../modules/auth/LoginUserContext';
import Button from '../../../../../presentational/atoms/button';
import Modal from '../../../../../presentational/molecules/modal';
import VirtualizedFlatList, {
  GlobalDragContextProvider,
} from '../../../../../presentational/atoms/list2/virtualized-flat-list';
import useHover from '../../../../../presentational/atoms/editable-text/use-hover';
import { useHotkeys } from 'react-hotkeys-hook';
import moment from 'moment-timezone';
import DeleteIcon from '../../../../../presentational/molecules/image-icon/delete';
import CheckIcon from '../../../../../presentational/molecules/image-icon/check';
import PlusIcon from '../../../../../presentational/molecules/image-icon/plus';
import Input from '../../../../../presentational/atoms/input';
import CustomAttributeIcon from '../../../../../presentational/molecules/image-icon/custom-attribute';
import when from '../../../../../../lang-extention/When';
import { ListValueMap } from '../../../../../presentational/atoms/multiselect';
import DateTimePicker from '../../../../../presentational/atoms/date-time-picker/index.web';

const Conatiner = styled.View`
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  align-items: flex-start;
  z-index: 2; //DateTimePickerを使用する際には、外側の要素のz-indexを指定しないと正しく表示されないケースがあるので、ここで指定している
`;

const Row = styled.View`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: space-between;
`;

interface IDeleteSubTaskModalProps {
  subTask: ProjectTemplateSubTask;
  showModal: boolean;
  onPressYes: () => Promise<void>;
  onCloseModal: () => void;
}
const DeleteSubTaskConfirmModal = (props: IDeleteSubTaskModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  useHotkeys(
    'Enter',
    () => {
      props.onPressYes();
    },
    {
      enabled: props.showModal,
    }
  );
  return (
    <Modal
      title={'このチェックリストのアイテムを削除しますか？'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <View style={{ flexDirection: 'column' }}>
        <View style={{ marginTop: 10 }}>
          <Typography variant={TypographyType.Normal} style={{ textAlign: 'center' }}>
            {props.subTask.title}
          </Typography>
        </View>
        <View style={{ marginTop: 10 }}>
          <Typography
            variant={TypographyType.Description}
            style={{ textAlign: 'center', color: themeContext.colors.error }}>
            {`この操作はやり直しが出来ません`}
          </Typography>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
          <Button
            text={'キャンセル'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.primary }}
            disableValidate={true}
            onPress={() => {
              props.onCloseModal();
            }}
          />
          <Button
            text={'削除する'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              borderColor: themeContext.colors.error,
              borderRadius: 3,
              borderWidth: 1,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.error }}
            onPress={async () => {
              await props.onPressYes();
            }}
          />
        </View>
      </View>
    </Modal>
  );
};

interface ISubTaskItemProps {
  subTask: ProjectTemplateSubTask;
  setEditing: (value: boolean) => void;
}

const SubTaskItem = (props: ISubTaskItemProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [hoverRef, isHover] = useHover();
  const [editMode, setEditMode] = useState(false);
  const [title, setTitle] = useState(props.subTask.title);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [updateSubTask, _] = useUpdateProjectTemplateSubTaskMutation({
    variables: {
      id: props.subTask.id!,
      input: {
        title: title,
        versionNo: props.subTask.versionNo,
      },
    },
  });
  const [deleteSubTask, _____] = useDeleteProjectTemplateSubTaskMutation({
    variables: {
      id: props.subTask.id!,
      input: {
        versionNo: props.subTask.versionNo,
      },
    },
    update: (cache, result) => {
      cache.evict({
        id: cache.identify(props.subTask),
      });
    },
  });

  return (
    <View
      style={{
        flexDirection: 'row',
        height: 24,
        marginVertical: 3,
        marginHorizontal: 14,
      }}>
      {editMode ? (
        <Form style={{ margin: 0, padding: 0, width: '100%' }}>
          <Input
            type={'text'}
            name={'subTaskTitle'}
            value={title}
            onChange={setTitle}
            validate={{
              required: {
                value: true,
                message: 'タイトルを入力してください',
              },
              maxLength: {
                value: 100,
                message: '100文字以内で入力してください',
              },
            }}
            focus={true}
            containerStyle={{ marginTop: 2 }}
            inputContainerStyle={{
              height: 20,
            }}
            onBlur={async (value) => {
              props.setEditing(false);
              setEditMode(false);
              if (value.length > 0) {
                await updateSubTask();
              }
            }}
          />
        </Form>
      ) : (
        <View
          style={{
            flexDirection: 'row',
            width: '100%',
          }}>
          <CheckIcon size={22} on={false} containerStyle={{ marginRight: 10 }} />
          <TouchableOpacity
            ref={hoverRef}
            onPress={() => {
              setEditMode(true);
              props.setEditing(true);
            }}
            style={{
              flex: 1,
              backgroundColor: isHover
                ? themeContext.colors.separator
                : themeContext.colors.baseColor,
            }}>
            <Typography
              variant={TypographyType.Normal}
              style={[{ fontSize: 13, flex: 4, width: '100%' }] as any}
              ellipsis={true}>
              {props.subTask.title}
            </Typography>
          </TouchableOpacity>
          <DeleteIcon
            size={18}
            containerStyle={{ marginRight: 5 }}
            onPress={() => {
              setShowDeleteModal(true);
            }}
          />
        </View>
      )}
      <DeleteSubTaskConfirmModal
        subTask={props.subTask}
        showModal={showDeleteModal}
        onPressYes={async () => {
          setShowDeleteModal(false);
          await deleteSubTask();
        }}
        onCloseModal={() => setShowDeleteModal(false)}
      />
    </View>
  );
};

interface ISubTaskListProps {
  task: ProjectTemplateTask;
}

const SubTaskList = React.memo((props: ISubTaskListProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [showNewSubTaskInput, setShowSubTaskInput] = useState(false);
  const [showNewSubTaskInputBottom, setShowSubTaskInputBottom] = useState(false);
  const [editing, setEditing] = useState(false);
  const [newSubTaskTitle, setNewSubTaskTitle] = useState('');
  const { loading: subTasksLoading, data: subTasksData } = useProjectTemplateSubTasksQuery({
    variables: {
      projectTemplateTaskId: props.task.id!,
    },
  });

  const [createSubTask, _] = useCreateProjectTemplateSubTaskMutation({
    variables: {
      projectTemplateTaskId: props.task.id!,
      input: {
        title: newSubTaskTitle,
      },
    },
    update: (cache, result) => {
      cache.modify({
        fields: {
          subTasks(existing = []) {
            const newSubTask = cache.writeQuery({
              data: result.data!.createProjectTemplateSubTask,
              query: ProjectTemplateSubTaskDocument,
            });
            return [...existing, newSubTask];
          },
        },
      });
    },
  });
  const [updateSubTaskSortNo, ____] = useUpdateProjectTemplateSubTaskSortNoMutation();

  const renderSubTaskItem = useCallback(
    (subTask) => {
      return <SubTaskItem subTask={subTask as ProjectTemplateSubTask} setEditing={setEditing} />;
    },
    [setEditing]
  );

  const subTasks = useMemo(() => {
    return (subTasksData?.projectTemplateSubTasks || []).slice().sort((a, b) => {
      return b!.sortNo - a!.sortNo; // ソート番号は昇順
    });
  }, [subTasksData?.projectTemplateSubTasks]);

  const onDrop = useCallback(
    async (info) => {
      const isMoveToFirst = info.endRowIndex === 0;
      const isMoveToLast = info.endRowIndex === subTasks.length - 1;
      const isMoveToDown = info.endRowIndex - info.startRowIndex > 0;

      let sortNo;
      if (isMoveToFirst) {
        sortNo = new Date().getTime();
      } else if (isMoveToLast) {
        sortNo = subTasks[info.endRowIndex]!.sortNo - 1000;
      } else {
        if (isMoveToDown) {
          const beforeTask = subTasks[info.endRowIndex];
          const afterTask = subTasks[info.endRowIndex + 1];
          sortNo = Math.floor((beforeTask!.sortNo + afterTask!.sortNo) / 2);
        } else {
          const beforeTask = subTasks[info.endRowIndex - 1];
          const afterTask = subTasks[info.endRowIndex];
          sortNo = Math.floor((beforeTask!.sortNo + afterTask!.sortNo) / 2);
        }
      }

      await updateSubTaskSortNo({
        variables: {
          id: (info.item! as ProjectTemplateSubTask).id!,
          input: {
            sortNo: sortNo,
            versionNo: (info.item as ProjectTemplateSubTask).versionNo,
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateProjectTemplateSubTaskSortNo: Object.assign(
            {
              __typename: 'ProjectTemplateSubTask',
            },
            info.item as ProjectTemplateSubTask,
            { sortNo: sortNo }
          ),
        },
      });
    },
    [subTasks, updateSubTaskSortNo]
  );

  return (
    <>
      <View style={{ marginTop: 10, width: '100%' }}>
        <GlobalDragContextProvider>
          <VirtualizedFlatList
            style={{ height: 'auto' }}
            items={subTasks}
            renderItem={renderSubTaskItem}
            getKey={(subTask) => (subTask as ProjectTemplateSubTask).id!}
            itemHeight={30}
            isDraggable={!editing}
            virticalDraggable={!editing}
            onDrop={onDrop}
            afterElement={
              <>
                {showNewSubTaskInputBottom && (
                  <View
                    style={{
                      flexDirection: 'row',
                      height: 24,
                      marginVertical: 3,
                      paddingHorizontal: 20,
                    }}>
                    <Form style={{ margin: 0, padding: 0, width: '100%' }}>
                      <Input
                        type={'text'}
                        name={'subTaskTitle'}
                        value={newSubTaskTitle}
                        onChange={setNewSubTaskTitle}
                        validate={{
                          required: {
                            value: true,
                            message: 'タイトルを入力してください',
                          },
                          maxLength: {
                            value: 100,
                            message: '100文字以内で入力してください',
                          },
                        }}
                        focus={true}
                        containerStyle={{ marginTop: 2 }}
                        inputContainerStyle={{
                          height: 20,
                        }}
                        onPressEnter={async (value) => {
                          setTimeout(() => {
                            setShowSubTaskInputBottom(true);
                          }, 200);
                        }}
                        onBlur={async (value) => {
                          setNewSubTaskTitle('');
                          setShowSubTaskInputBottom(false);
                          if (value.trim().length === 0) {
                            return;
                          }
                          await createSubTask({
                            variables: {
                              projectTemplateTaskId: props.task.id!,
                              input: {
                                title: newSubTaskTitle,
                                sortNo: 0,
                              },
                            },
                          });
                        }}
                      />
                    </Form>
                  </View>
                )}
                <PlusIcon
                  size={14}
                  containerStyle={{ marginLeft: 10, marginBottom: 10 }}
                  onPress={() => setShowSubTaskInputBottom(true)}>
                  <Typography
                    variant={TypographyType.Normal}
                    style={{ fontSize: 14, color: themeContext.colors.description }}>
                    チェックリストを追加する
                  </Typography>
                </PlusIcon>
              </>
            }
          />
        </GlobalDragContextProvider>
      </View>
    </>
  );
});

interface ITaskDescriptionProps {
  task: ProjectTemplateTask;
  organizationMembers: Member[];
  descriptionRowRef: any;
}

const TaskDescription = (props: ITaskDescriptionProps) => {
  const [loginUser, _] = useContext(LoginUserContext);
  const [hoverRef, isHover, setHover] = useHover();
  const [showEditor, setShowEditor] = useState(false);
  const themeContext: IThemePart = useContext(ThemeContext);
  const [description, setDescription] = useState(props.task.description || '');
  const [updateTask, __] = useUpdateProjectTemplateTaskMutation();

  const isContentEmpty =
    props.task.description === null ||
    (props.task.description &&
      JSON.parse(props.task.description).blocks &&
      JSON.parse(props.task.description).blocks.length > 0 &&
      JSON.parse(props.task.description).blocks[0]?.text === '');

  return (
    <TaskInfoElement title={'説明'} style={{ flex: 1 }} elementStyle={{ alignItems: 'flex-start' }}>
      <View ref={hoverRef as any}>
        {showEditor ? (
          <>
            <Form style={{ zIndex: 2 }}>
              <Input
                initialValue={props.task.description || ''}
                name={'description'}
                type={'rich-text-editor'}
                zIndexAdjustRef={props.descriptionRowRef}
                showToolBarAlways={true}
                autoFocus={true}
                onChange={(value) => {
                  setDescription(value);
                }}
              />
            </Form>
            <View style={{ flexDirection: 'row', marginTop: 10, zIndex: 1 }}>
              <Button
                text="保存"
                style={{ height: 30, marginRight: 5 }}
                textStyle={{ fontSize: 14 }}
                onPress={async () => {
                  if (description === props.task.description) {
                    return;
                  }
                  setDescription(description);
                  setShowEditor(false);
                  setHover(false);
                  updateTask({
                    variables: {
                      id: props.task.id!,
                      input: {
                        title: props.task.title,
                        description: description,
                        estimateTimeSec: props.task.estimateTimeSec,
                        priority: props.task.priority,
                        versionNo: props.task.versionNo,
                      },
                    },
                  });
                }}
              />
              <Button
                text="キャンセル"
                style={{ height: 30, backgroundColor: 'transparent' }}
                textStyle={{ fontSize: 14, color: themeContext.colors.primary }}
                onPress={() => {
                  setDescription(props.task.description || '');
                  setShowEditor(false);
                  setHover(false);
                }}
              />
            </View>
          </>
        ) : (
          <TouchableOpacity onPress={() => setShowEditor(true)} style={{ zIndex: 2 }}>
            <Form>
              <div
                style={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  backgroundColor: themeContext.colors.separator,
                  zIndex: 2,
                  opacity: isHover ? 0.5 : 0,
                  cursor: 'pointer',
                }}
              />
              <View
                style={{ opacity: isContentEmpty ? 0.2 : 1, minHeight: 50, paddingHorizontal: 10 }}>
                <Input
                  initialValue={
                    isContentEmpty
                      ? `{
                  "blocks": [
                    {
                      "key": "dummy",
                      "text": "説明を追加...",
                      "type": "unstyled",
                      "depth": 0,
                      "inlineStyleRanges": [],
                      "entityRanges": [],
                      "data": {}
                    }
                  ],
                  "entityMap": {}
                }
                `
                      : props.task.description!
                  }
                  name={'description'}
                  type={'rich-text-editor'}
                  zIndexAdjustRef={props.descriptionRowRef}
                  showToolBarAlways={false}
                  multiline={true}
                  readonly={true}
                />
              </View>
            </Form>
          </TouchableOpacity>
        )}
      </View>
    </TaskInfoElement>
  );
};

interface ITagSettingsProps {
  taskTags: Tag[];
  task: ProjectTemplateTask;
  organizationTags: Tag[];
  organization: Organization;
}

const TagSettings = (props: ITagSettingsProps) => {
  const [tags, setTags] = useState([] as string[]);
  const [relateTaskTags] = useRelateProjectTemplateTaskTagsMutation({
    update: (cache, result) => {
      cache.modify({
        fields: {
          projectTemplateTaskTags(existing = []) {
            const newTags = cache.writeQuery({
              data: result.data?.relateProjectTemplateTaskTags,
              query: ProjectTemplateTaskTagsDocument,
            });
            return newTags;
          },
        },
      });
    },
  });

  const isBasicPlan = props.organization.plan.code === Plan.Basic;

  return (
    <EditableText
      value={props.taskTags!.map((tag) => tag!.name)}
      type={'multi-picker'}
      isSearchable={true}
      noOptionsMessage={
        isBasicPlan ? '新しいタグを付与するには、アップグレードが必要です' : undefined
      }
      validate={{
        validate: (item: string) => item !== '' && item.length <= 100,
      }}
      onBlur={(items) => {
        relateTaskTags({
          variables: {
            projectTemplateTaskId: props.task.id!,
            input: {
              tagIds: props
                .organizationTags!.filter((tag) => (items as string[]).includes(tag!.name))
                .map((tag) => tag!.id!),
              newTagNames: isBasicPlan
                ? [] //Basicプランの場合には新しいタグを登録できないようにしている
                : (items as string[]).filter(
                    (item) => !props.organizationTags!.map((tag) => tag?.name).includes(item)
                  ),
            },
          },
        });
      }}
      onChange={(items) => {
        setTags(items as string[]);
      }}
      onPressEnter={() => {
        relateTaskTags({
          variables: {
            projectTemplateTaskId: props.task.id!,
            input: {
              tagIds: props
                .organizationTags!.filter((tag) => tags.includes(tag!.name))
                .map((tag) => tag!.id!),
              newTagNames: isBasicPlan
                ? [] //Basicプランの場合には新しいタグを登録できないようにしている
                : tags.filter(
                    (item) => !props.organizationTags!.map((tag) => tag?.name).includes(item)
                  ),
            },
          },
        });
      }}
      pickerItems={props.organizationTags!.map((tag) => ({
        value: tag!.name,
        label: tag!.name,
      }))}
    />
  );
};

interface IDeleteCustomAttributeModalProps {
  customAttribute: ProjectTemplateTaskCustomAttribute;
  showModal: boolean;
  onPressYes: () => Promise<void>;
  onCloseModal: () => void;
}
const DeleteCustomAttributeConfirmModal = (props: IDeleteCustomAttributeModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  useHotkeys(
    'Enter',
    () => {
      props.onPressYes();
    },
    {
      enabled: props.showModal,
    }
  );

  return (
    <Modal
      title={'このカスタム項目を削除しますか？'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <View style={{ flexDirection: 'column' }}>
        <View style={{ marginTop: 10 }}>
          <Typography variant={TypographyType.Normal} style={{ textAlign: 'center' }}>
            {props.customAttribute.master.name}
          </Typography>
        </View>
        <View style={{ marginTop: 10 }}>
          <Typography
            variant={TypographyType.Description}
            style={{ textAlign: 'center', color: themeContext.colors.error }}>
            {`この操作はやり直しが出来ません`}
          </Typography>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
          <Button
            text={'キャンセル'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.primary }}
            disableValidate={true}
            onPress={() => {
              props.onCloseModal();
            }}
          />
          <Button
            text={'削除する'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              borderColor: themeContext.colors.error,
              borderRadius: 3,
              borderWidth: 1,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.error }}
            onPress={async () => {
              await props.onPressYes();
            }}
          />
        </View>
      </View>
    </Modal>
  );
};

interface ICustomAttributeRowProps {
  task: ProjectTemplateTask;
  customAttribute: ProjectTemplateTaskCustomAttribute;
  zIndex: number;
}

const CustomAttributeRow = (props: ICustomAttributeRowProps) => {
  const ref = useRef();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [updateCustomAttribute] = useUpdateProjectTemplateTaskCustomAttributeMutation();
  const [deleteCustomAttribute] = useDeleteProjectTemplateTaskCustomAttributeMutation({
    refetchQueries: [
      {
        query: ProjectTemplateTaskCustomAttributesByTaskDocument,
        variables: {
          projectTemplateTaskId: props.task.id!,
        },
      },
    ],
  });

  useEffect(() => {
    (
      ref as any
    ).current.parentElement.parentElement.parentElement.parentElement.parentElement.style.zIndex =
      props.zIndex;
  }, [props.zIndex]);

  return (
    <View key={props.customAttribute.id} ref={ref as any} style={{ minWidth: 400 }}>
      <TaskInfoElement
        title={props.customAttribute.master.name}
        style={{ flex: 1 }}
        contentFlexDirection={'row'}
        contentContainerStyle={{ alignItems: 'center' }}
        elementStyle={{ alignItems: 'center' }}
        key={props.customAttribute.id}>
        {when(props.customAttribute.master.type) //
          .on(
            (value) => value === CustomerAttributeType.FreeText,
            () => (
              <EditableText
                value={props.customAttribute.value ?? ''}
                style={{ minWidth: 100 }}
                type={'text'}
                validate={{
                  required: {
                    value: props.customAttribute.master.required,
                    message: '値を入力してください',
                  },
                  maxLength: {
                    value: 50,
                    message: '50文字以内で入力してください',
                  },
                }}
                onChange={async (value) => {
                  await updateCustomAttribute({
                    variables: {
                      id: props.customAttribute.id!,
                      input: {
                        value: value as string,
                        sortNo: props.customAttribute.sortNo,
                      },
                    },
                  });
                }}
                textStyle={{ fontSize: 16 }}
              />
            )
          )
          .on(
            (value) => value === CustomerAttributeType.Number,
            () => (
              <EditableText
                value={props.customAttribute.value ?? ''}
                style={{ minWidth: 100 }}
                type={'text'}
                validate={{
                  required: {
                    value: props.customAttribute.master.required,
                    message: '値を入力してください',
                  },
                  pattern: {
                    value: /^[0-9]+$/,
                    message: '半角数字を入力してください。',
                  },
                }}
                onChange={async (value) => {
                  await updateCustomAttribute({
                    variables: {
                      id: props.customAttribute.id!,
                      input: {
                        value: value as string,
                        sortNo: props.customAttribute.sortNo,
                      },
                    },
                  });
                }}
                textStyle={{ fontSize: 16 }}
              />
            )
          )
          .on(
            (value) => value === CustomerAttributeType.Date,
            () => (
              <EditableText
                value={props.customAttribute.value ? moment(props.customAttribute.value) : null}
                style={{ minWidth: 100 }}
                type={'date-picker'}
                disableClear={props.customAttribute.master.required}
                validate={{
                  required: {
                    value: props.customAttribute.master.required,
                    message: '値を入力してください',
                  },
                }}
                onChange={async (value) => {
                  await updateCustomAttribute({
                    variables: {
                      id: props.customAttribute.id!,
                      input: {
                        value: value ? (value as moment.Moment).toISOString() : null,
                        sortNo: props.customAttribute.sortNo,
                      },
                    },
                  });
                }}
                textStyle={{ fontSize: 16 }}
              />
            )
          )
          .on(
            (value) => value === CustomerAttributeType.DateTime,
            () => (
              <EditableText
                value={props.customAttribute.value ? moment(props.customAttribute.value) : null}
                style={{ minWidth: 100 }}
                type={'date-time-picker'}
                disableClear={props.customAttribute.master.required}
                validate={{
                  required: {
                    value: props.customAttribute.master.required,
                    message: '値を入力してください',
                  },
                }}
                onChange={async (value) => {
                  await updateCustomAttribute({
                    variables: {
                      id: props.customAttribute.id!,
                      input: {
                        value: value ? (value as moment.Moment).toISOString() : null,
                        sortNo: props.customAttribute.sortNo,
                      },
                    },
                  });
                }}
                textStyle={{ fontSize: 16 }}
              />
            )
          )
          .on(
            (value) => value === CustomerAttributeType.List,
            () => (
              <EditableText
                value={props.customAttribute.listItem?.id ?? ''}
                type={'picker'}
                isSearchable={true}
                style={{ minWidth: 100 }}
                emptyText={'-'}
                pickerItems={
                  props.customAttribute.master!.required
                    ? props.customAttribute
                        .master!.listItem.slice()
                        .sort((a, b) => b!.sortNo - a!.sortNo)
                        .map((item) => {
                          return {
                            label: item!.value,
                            value: item!.id,
                          };
                        })
                    : [
                        {
                          label: '-',
                          value: null,
                        },
                      ].concat(
                        props.customAttribute
                          .master!.listItem.slice()
                          .sort((a, b) => b!.sortNo - a!.sortNo)
                          .map((item) => {
                            return {
                              label: item!.value,
                              value: item!.id,
                            } as ListValueMap;
                          })
                      )
                }
                onChange={async (value) => {
                  await updateCustomAttribute({
                    variables: {
                      id: props.customAttribute.id!,
                      input: {
                        taskCustomAttributeMasterListItemId:
                          !!value && (value as string).trim().length > 0 ? (value as string) : null,
                        sortNo: props.customAttribute.sortNo,
                      },
                    },
                  });
                }}
                textStyle={{ fontSize: 18 }}
              />
            )
          )
          .otherwise(() => (
            <Input name={'value'} placeholder={'-'} readonly />
          ))}
        <DeleteIcon
          size={21}
          containerStyle={{ marginLeft: 5, padding: 5 }}
          onPress={() => setShowDeleteModal(true)}
        />
      </TaskInfoElement>
      <DeleteCustomAttributeConfirmModal
        customAttribute={props.customAttribute}
        showModal={showDeleteModal}
        onPressYes={async () => {
          setShowDeleteModal(false);
          await deleteCustomAttribute({
            variables: {
              id: props.customAttribute.id!,
            },
          });
        }}
        onCloseModal={() => setShowDeleteModal(false)}
      />
    </View>
  );
};

interface IAddCustomAttributeModalProps {
  task: ProjectTemplateTask;
  alreadyExistTaskCustomAttributes: ProjectTemplateTaskCustomAttribute[];
  showModal: boolean;
  onPressYes: () => Promise<void>;
  onCloseModal: () => void;
}

const AddCustomAttributeModal = (props: IAddCustomAttributeModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [attribute, setAttribute] = useState<TaskCustomAttributeMaster | null>(null);
  const [value, setValue] = useState<string | null>(null);
  const { data, loading } = useTaskCustomAttributeMastersQuery({
    fetchPolicy: 'network-only',
  });
  const [addCustomAttribute] = useAddProjectTemplateTaskCustomAttributeMutation({
    variables: {
      projectTemplateTaskId: props.task.id!,
      input: {
        taskCustomAttributeMasterId: attribute?.id!,
        taskCustomAttributeMasterListItemId:
          attribute?.type === CustomerAttributeType.List ? value : null,
        value: attribute?.type === CustomerAttributeType.List ? null : value!,
      },
    },
    refetchQueries: [
      {
        query: ProjectTemplateTaskCustomAttributesByTaskDocument,
        variables: {
          projectTemplateTaskId: props.task.id!,
        },
      },
    ],
  });

  useEffect(() => {
    setAttribute(null);
    setValue(null);
  }, [props.showModal]);

  const attributes = data?.taskCustomAttributeMasters?.filter(
    (master) =>
      !props.alreadyExistTaskCustomAttributes.find((exist) => exist.master.id === master!.id)
  );

  if (loading) {
    return <></>;
  }

  return (
    <Modal
      title={'カスタム項目を追加する'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <View style={{ flexDirection: 'column' }}>
        <Form style={{ minWidth: 400, zIndex: 2 }}>
          <Row>
            <TaskInfoElement title={`カスタム項目`} style={{ flex: 1 }}>
              <Input
                name={'attribute'}
                type={'picker'}
                isSearchable={true}
                placeholder={'選択してください'}
                pickerItems={(attributes ?? []).map((master) => {
                  return {
                    label: master!.name,
                    value: master!.id!,
                  };
                })}
                onChange={(value) => {
                  const targets = (attributes || []).filter((master) => master!.id! === value);
                  if (targets && targets.length > 0) {
                    setAttribute(targets[0] ?? null);
                  }
                }}
                validate={{
                  required: {
                    value: true,
                    message: '選択肢を入力してください',
                  },
                }}
              />
            </TaskInfoElement>
          </Row>
          <Row style={{ zIndex: 2 }}>
            <TaskInfoElement title={`値`} style={{ flex: 1 }}>
              {when(attribute?.type) //
                .on(
                  (value) => value === CustomerAttributeType.FreeText,
                  () => (
                    <Input
                      name={'textValue'}
                      placeholder={'値を入力してください'}
                      onChange={(value) => setValue(value)}
                      validate={{
                        required: {
                          value: attribute?.required,
                          message: '値を入力してください',
                        },
                        maxLength: {
                          value: 50,
                          message: '50文字以内で入力してください',
                        },
                      }}
                    />
                  )
                )
                .on(
                  (value) => value === CustomerAttributeType.Number,
                  () => (
                    <Input
                      name={'numberValue'}
                      placeholder={'値を入力してください'}
                      onChange={(value) => setValue(value)}
                      validate={{
                        required: {
                          value: attribute?.required,
                          message: '値を入力してください',
                        },
                        pattern: {
                          value: /^[0-9]+$/,
                          message: '半角数字を入力してください。',
                        },
                      }}
                    />
                  )
                )
                .on(
                  (value) => value === CustomerAttributeType.Date,
                  () => (
                    <DateTimePicker
                      mode={'date'}
                      name={'dateValue'}
                      onChange={(value) => {
                        if (value) {
                          setValue(value!.toISOString());
                        } else {
                          setValue(null);
                        }
                      }}
                      onBlur={(value, element) => {
                        if (value) {
                          setValue(value!.toISOString());
                        } else {
                          setValue(null);
                        }
                      }}
                      onPressEnter={(value) => {
                        if (value) {
                          setValue(value!.toISOString());
                        } else {
                          setValue(null);
                        }
                      }}
                      validate={{
                        required: {
                          value: attribute!.required,
                          message: '値を入力してください',
                        },
                      }}
                    />
                  )
                )
                .on(
                  (value) => value === CustomerAttributeType.DateTime,
                  () => (
                    <DateTimePicker
                      mode={'datetime'}
                      name={'datetimeValue'}
                      onChange={(value) => {
                        if (value) {
                          setValue(value!.toISOString());
                        } else {
                          setValue(null);
                        }
                      }}
                      onBlur={(value, element) => {
                        if (value) {
                          setValue(value!.toISOString());
                        } else {
                          setValue(null);
                        }
                      }}
                      onPressEnter={(value) => {
                        if (value) {
                          setValue(value!.toISOString());
                        } else {
                          setValue(null);
                        }
                      }}
                      validate={{
                        required: {
                          value: attribute!.required,
                          message: '値を入力してください',
                        },
                      }}
                    />
                  )
                )
                .on(
                  (value) => value === CustomerAttributeType.List,
                  () => (
                    <Input
                      name="listValue"
                      value={''}
                      type={'picker'}
                      inputContainerStyle={{ minWidth: 400 }}
                      placeholder={'選択してください'}
                      pickerItems={
                        attribute!.required
                          ? attribute!.listItem
                              .slice()
                              .sort((a, b) => b!.sortNo - a!.sortNo)
                              .map((item) => {
                                return {
                                  label: item!.value,
                                  value: item!.id,
                                };
                              })
                          : [
                              {
                                label: '-',
                                value: null,
                              },
                            ].concat(
                              attribute!.listItem
                                .slice()
                                .sort((a, b) => b!.sortNo - a!.sortNo)
                                .map((item) => {
                                  return {
                                    label: item!.value,
                                    value: item!.id,
                                  } as ListValueMap;
                                })
                            )
                      }
                      onChange={(value) => setValue((value as string) || null)}
                      validate={{
                        required: {
                          value: attribute!.required,
                          message: '選択肢を入力してください',
                        },
                      }}
                    />
                  )
                )
                .otherwise(() => (
                  <Input name={'value'} placeholder={'-'} readonly />
                ))}
            </TaskInfoElement>
          </Row>
          <View
            style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
            <Button
              text={'登録する'}
              style={{
                flex: 1,
                width: '100%',
                minHeight: 50,
                marginVertical: 10,
              }}
              textStyle={{
                backgroundColor: themeContext.colors.primary,
                color: '#FFFFFF',
                padding: 5,
              }}
              onPress={async () => {
                await addCustomAttribute();
                await props.onPressYes();
              }}
            />
            <Button
              text={'キャンセル'}
              style={{
                flex: 1,
                width: '100%',
                minHeight: 50,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.primary }}
              disableValidate={true}
              onPress={() => {
                props.onCloseModal();
              }}
            />
          </View>
        </Form>
      </View>
    </Modal>
  );
};

interface ITaskDetailStandardInfoProps {
  organizationId: string;
  task: ProjectTemplateTask;
  parentScrollViewRef: any;
}

const TaskDetailStandardInfo = (props: ITaskDetailStandardInfoProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, _] = useContext(LoginUserContext);
  const [showAddCustomAttributeModal, setShowAddCustomAttributeModal] = useState(false);
  const descriptionRowRef = useRef();
  const [updateTask, __] = useUpdateProjectTemplateTaskMutation({
    update: (cache, result) => {
      cache.modify({
        fields: {
          myTasks(existing = []) {
            const newTask = cache.writeQuery({
              data: result.data!.updateProjectTemplateTask,
              query: ProjectTemplateTaskDocument,
            });
            return [...existing, newTask];
          },
        },
      });
    },
  });

  const { loading: meLoading, data: meData } = useMeQuery();

  const { loading: taskTagsLoading, data: taskTagsData } = useProjectTemplateTaskTagsQuery({
    variables: {
      projectTemplateTaskId: props.task.id!,
    },
    fetchPolicy: 'network-only',
  });
  const { loading: organizationTagsLoading, data: organizationTagsData } = useOrganizationTagsQuery(
    {
      variables: {
        organizationId: props.organizationId,
      },
      fetchPolicy: 'network-only',
    }
  );
  const { loading: organizationMembersLoading, data: organizationMembersData } =
    useOrganizationMembersQuery({
      variables: {
        organizationId: loginUser!.organizationId!,
      },
    });

  const { loading: organizationLoading, data: organizationData } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId!,
    },
  });

  const { loading: customAttributeLoading, data: customAttributeData } =
    useProjectTemplateTaskCustomAttributesByTaskQuery({
      variables: {
        projectTemplateTaskId: props.task.id!,
      },
      fetchPolicy: 'network-only',
    });

  const [updateProjectTemplateTaskCustomAttribute] =
    useUpdateProjectTemplateTaskCustomAttributeMutation();

  if (
    meLoading ||
    !meData?.me ||
    taskTagsLoading ||
    organizationTagsLoading ||
    organizationMembersLoading ||
    organizationLoading
  ) {
    return <></>;
  }
  return (
    <>
      <Conatiner>
        <Row>
          <TaskInfoElement title={'優先度'} style={{ flex: 1, maxWidth: 400 }}>
            <EditableText
              value={props.task.priority?.toString() || ''}
              type={'picker'}
              emptyText={'-'}
              pickerItems={[
                {
                  label: 'なし',
                  value: null,
                },
                {
                  label: '高',
                  value: Priority.High,
                },
                {
                  label: '中',
                  value: Priority.Normal,
                },
                {
                  label: '低',
                  value: Priority.Low,
                },
              ]}
              onChange={(value) => {
                updateTask({
                  variables: {
                    id: props.task.id!,
                    input: {
                      title: props.task.title,
                      description: props.task.description,
                      estimateTimeSec: props.task.estimateTimeSec,
                      priority: (value as Priority) || null,
                      versionNo: props.task.versionNo,
                    },
                  },
                });
              }}
              textStyle={{ fontSize: 18 }}
            />
          </TaskInfoElement>
        </Row>
        <Row>
          <TaskInfoElement title={'タグ'} style={{ flex: 1 }}>
            <TagSettings
              organizationTags={organizationTagsData!.organizationTags as Tag[]}
              task={props.task}
              taskTags={taskTagsData?.projectTemplateTaskTags as Tag[]}
              organization={organizationData!.organization!}
            />
          </TaskInfoElement>
        </Row>
        <Row style={{ zIndex: 2, justifyContent: 'flex-start' }}>
          <TaskInfoElement
            title={'見積時間'}
            style={{ flex: 1, maxWidth: 300 }}
            contentFlexDirection={'row'}>
            <EditableText
              containerStyle={{ minWidth: 100 }}
              value={props.task.estimateTimeSec || null}
              type={'time-picker'}
              onChange={(value) => {
                updateTask({
                  variables: {
                    id: props.task.id!,
                    input: {
                      title: props.task.title,
                      description: props.task.description,
                      estimateTimeSec: (value as number) || null,
                      priority: props.task.priority,
                      versionNo: props.task.versionNo,
                    },
                  },
                });
              }}
            />
          </TaskInfoElement>
        </Row>
        <Row>
          <TaskInfoElement title={` `} contentContainerStyle={{ alignItems: 'flex-start' }}>
            <CustomAttributeIcon size={14} onPress={() => setShowAddCustomAttributeModal(true)}>
              <Typography
                variant={TypographyType.Description}
                style={{ marginLeft: 10, color: themeContext.colors.description }}>
                カスタム項目を追加
              </Typography>
            </CustomAttributeIcon>
          </TaskInfoElement>
        </Row>
        <GlobalDragContextProvider>
          <VirtualizedFlatList
            style={{ height: 'auto' }}
            items={(customAttributeData?.projectTemplateTaskCustomAttributesByTask ?? [])
              .slice()
              .sort((a, b) => a!.sortNo - b!.sortNo)}
            renderItem={(customAttribute, i) => {
              return (
                <Row
                  style={{
                    zIndex:
                      customAttributeData!.projectTemplateTaskCustomAttributesByTask!.length +
                      10 -
                      i,
                    cursor: 'grab',
                  }}>
                  <CustomAttributeRow
                    task={props.task}
                    customAttribute={customAttribute as ProjectTemplateTaskCustomAttribute}
                    zIndex={
                      customAttributeData!.projectTemplateTaskCustomAttributesByTask!.length +
                      10 -
                      i
                    }
                    key={(customAttribute as ProjectTemplateTaskCustomAttribute).id}
                  />
                </Row>
              );
            }}
            getKey={(item) => (item as any).id}
            itemHeight={50}
            virticalDraggable={true}
            onDrop={async (info) => {
              const sortedItems = (
                customAttributeData?.projectTemplateTaskCustomAttributesByTask ?? []
              )
                .slice()
                .sort((a, b) => a!.sortNo - b!.sortNo);

              const isMoveToFirst = info.endRowIndex === 0;
              const isMoveToLast = info.endRowIndex === sortedItems.length - 1;
              const isMoveToDown = info.endRowIndex - info.startRowIndex > 0;
              let sortNo;
              if (isMoveToLast) {
                sortNo = new Date().getTime();
              } else if (isMoveToFirst) {
                sortNo = sortedItems[info.endRowIndex]!.sortNo - 1000;
              } else {
                if (isMoveToDown) {
                  const beforeTask = sortedItems[info.endRowIndex];
                  const afterTask = sortedItems[info.endRowIndex + 1];
                  sortNo = Math.floor((beforeTask!.sortNo + afterTask!.sortNo) / 2);
                } else {
                  const beforeTask = sortedItems[info.endRowIndex - 1];
                  const afterTask = sortedItems[info.endRowIndex];
                  sortNo = Math.floor((beforeTask!.sortNo + afterTask!.sortNo) / 2);
                }
              }
              await updateProjectTemplateTaskCustomAttribute({
                variables: {
                  id: (info.item as ProjectTemplateTaskCustomAttribute).id!,
                  input: {
                    sortNo: sortNo,
                    value: (info.item as ProjectTemplateTaskCustomAttribute).value,
                    taskCustomAttributeMasterListItemId: (
                      info.item as ProjectTemplateTaskCustomAttribute
                    ).listItem?.id,
                  },
                },
              });
            }}
          />
        </GlobalDragContextProvider>
        <Row ref={descriptionRowRef}>
          <TaskDescription
            task={props.task}
            organizationMembers={organizationMembersData!.organizationMembers as Member[]}
            descriptionRowRef={descriptionRowRef}
          />
        </Row>
        <SubTaskList task={props.task} />
        <AddCustomAttributeModal
          task={props.task}
          alreadyExistTaskCustomAttributes={
            (customAttributeData?.projectTemplateTaskCustomAttributesByTask as ProjectTemplateTaskCustomAttribute[]) ??
            []
          }
          showModal={showAddCustomAttributeModal}
          onCloseModal={() => setShowAddCustomAttributeModal(false)}
          onPressYes={async () => setShowAddCustomAttributeModal(false)}
        />
      </Conatiner>
    </>
  );
};

export default TaskDetailStandardInfo;
