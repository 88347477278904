import React, { useContext, useEffect, useState } from 'react';
import { Text, TouchableOpacity } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import Ripple from 'react-native-material-ripple';
import { IStyleTheme, IThemePart } from '../../../../theme';
import { useHistory, useLocation } from 'react-router';
import ColorUtil from '../../../../../util/ColorUtil';
import { IRouteItem } from '../../../../../routes/RouteItem';
import Icon from '../../../../presentational/atoms/icon';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import ToolTip from 'react-portal-tooltip';

interface IContainerProps extends IStyleTheme {
  showOnlyIcon: boolean;
  depth: number;
}

const Container = styled.View<IContainerProps>`
  width: ${(props: IContainerProps) => (props.showOnlyIcon ? '60px' : '100%')};
  height: 44px;
  padding: 6px 3px;
  margin-left: ${(props: IContainerProps) =>
    props.showOnlyIcon ? '0' : `${props.depth <= 1 ? 10 : props.depth * 10}px`};
  display: flex;
  flex-direction: row;
  align-item: center;
  justify-content: ${(props: IContainerProps) => (props.showOnlyIcon ? 'center' : 'flex-start')};
  transition: all 0.4s;
`;

interface ITextContainerProps extends IStyleTheme {
  showOnlyIcon: boolean;
}

const TextContainer = styled.View<ITextContainerProps>`
  opacity: ${(props: ITextContainerProps) => (props.showOnlyIcon ? '0' : '1')};
  width: ${(props: ITextContainerProps) => (props.showOnlyIcon ? '0' : 'auto')};
  display: ${(props: ITextContainerProps) => (props.showOnlyIcon ? 'none' : 'flex')};
  align-item: center;
  justify-content: center;
`;

interface IIConContainerProps extends IStyleTheme {
  depth: number;
}

const IconContainer = styled.View<IIConContainerProps>`
  width: ${(props: IIConContainerProps) =>
    props.depth <= 1 ? '32px' : `${32 - props.depth * 10}px`};
  height: ${(props: IIConContainerProps) =>
    props.depth <= 1 ? '32px' : `${32 - props.depth * 10}px`};
  margin: ${(props: IIConContainerProps) => (props.depth <= 1 ? '0' : `${props.depth * 5}px`)};
  display: flex;
  align-item: center;
  justify-content: center;
`;

interface IExpanderContainerProps extends IStyleTheme {
  depth: number;
  showOnlyIcon: boolean;
}

const ExpanderContainer = styled.View<IExpanderContainerProps>`
  width: ${(props: IExpanderContainerProps) =>
    props.depth <= 1 ? '32px' : `${32 - props.depth * 10}px`};
  height: ${(props: IExpanderContainerProps) =>
    props.depth <= 1 ? '32px' : `${32 - props.depth * 10}px`};
  padding: ${(props: IExpanderContainerProps) => (props.showOnlyIcon ? '0' : '7px')};
  display: flex;
  align-item: center;
  justify-content: center;
`;

interface IInnerProps {
  route: IRouteItem;
  isTeam?: boolean
  showOnlyIcon: boolean;
  depth: number;
  forceCloseSubMenu?: boolean;
  openMenu: boolean;
  onOpenSubMenu: () => void;
  onCloseSubMenu: () => void;
}

const SideMenuItemInner: React.FC<IInnerProps> = (props: IInnerProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const history = useHistory();
  const { pathname, search } = useLocation();
  const [isOpenSubMenu, setOpenSubMenu] = useState(props.openMenu || false);

  useEffect(() => {
    setOpenSubMenu(props.openMenu || false);
  }, [props.openMenu]);

  let isActive =
    (props.route.path &&
      (props.route.path as any).pathname &&
      pathname.startsWith((props.route.path as any).pathname)) ||
    (props.route.path && props.route.path && pathname.startsWith(props.route.path.toString()));
  if (props.route.id === 'search-team') {
    if (isActive && search !== '?create=true') {
      isActive = true;
    } else {
      isActive = false;
    }
  }
  if (props.route.id === 'create-team') {
    if (isActive && search === '?create=true') {
      isActive = true;
    } else {
      isActive = false;
    }
  }
  return (
    <>
      <Ripple
        rippleColor={ColorUtil.lignten(themeContext.colors.primary, 20)}
        rippleSize={400}
        rippleDuration={400}
        style={{ cursor: 'pointer', backgroundColor: props.isTeam ? '#1b1b1b' : undefined }}
        onPress={() => {
          if (props.forceCloseSubMenu) {
            return;
          }
          setOpenSubMenu(!isOpenSubMenu);
          if (props.route.onPress) {
            props.route.onPress();
          }
          if (isOpenSubMenu) {
            props.onCloseSubMenu();
          } else {
            props.onOpenSubMenu();
          }
          if (props.route.menuPath) {
            history.push(props.route.menuPath); // 本当はRippleの外側にLinkタグを入れれば良いのだが、なぜかその構成だと画面遷移時にリロードが発生してしまうため、こちらでHistoryを操作して画面リロードなしの遷移を発生させている
            return;
          }
          if (props.route.path) {
            history.push(props.route.path); // 本当はRippleの外側にLinkタグを入れれば良いのだが、なぜかその構成だと画面遷移時にリロードが発生してしまうため、こちらでHistoryを操作して画面リロードなしの遷移を発生させている
          }
        }}>
        <Container showOnlyIcon={props.showOnlyIcon} depth={props.depth}>
          {props.route.icon && (
            <IconContainer depth={props.depth}>
              {props.route.icon && (
                <TouchableOpacity
                  style={{
                    opacity: isActive ? 1 : 0.5,
                  }}
                  onPress={() => {
                    if (props.forceCloseSubMenu) {
                      return;
                    }
                    setOpenSubMenu(!isOpenSubMenu);
                    if (props.route.onPress) {
                      props.route.onPress();
                    }
                  }}>
                  {props.route.iconComponent}
                </TouchableOpacity>
              )}
            </IconContainer>
          )}
          <TextContainer
            showOnlyIcon={props.showOnlyIcon}
            style={{ paddingHorizontal: props.route.icon ? 0 : 10, maxWidth: 'calc(100% - 50px)' }}>
            <Typography
              variant={isActive ? TypographyType.MenuItemActive : TypographyType.MenuItemNonActive}
              ellipsis={true}
              style={{
                color: isActive ? '#EFEFEF' : '#909090',
                fontSize: props.depth <= 1 ? 14 : 14 - props.depth,
              }}>
              {props.route.name}
            </Typography>
          </TextContainer>
          {props.route.subItems && (
            <ExpanderContainer depth={props.depth} showOnlyIcon={props.showOnlyIcon}>
              <Text style={{ color: '#909090' }}>{isOpenSubMenu ? '▼' : '▲'}</Text>
            </ExpanderContainer>
          )}
        </Container>
      </Ripple>
      {props.route.subItems && isOpenSubMenu && !props.forceCloseSubMenu && (
        <>
          {props.route.subItems
            .filter((menu) => menu.menuInvisible !== true)
            .map((route: IRouteItem, key: number) => {
              return (
                <SideMenuItem
                  route={route}
                  showOnlyIcon={props.showOnlyIcon}
                  openMenu={route.initialOpen || false}
                  depth={props.depth! + 1}
                  key={key}
                />
              );
            })}
        </>
      )}
    </>
  );
};

interface IProps {
  route: IRouteItem;
  isTeam?: boolean
  showOnlyIcon: boolean;
  depth?: number;
  forceCloseSubMenu?: boolean;
  openMenu: boolean;
  onOpenSubMenu?: () => void;
  onCloseSubMenu?: () => void;
}

const SideMenuItem: React.FC<IProps> = (props: IProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [isHover, setHover] = useState(false);
  if (props.showOnlyIcon) {
    return (
      <>
        <div
          id={`tooltip-sidemenu-${props.route.id}`}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}>
          <SideMenuItemInner
            route={props.route}
            showOnlyIcon={props.showOnlyIcon}
            depth={props.depth || 0}
            openMenu={props.openMenu}
            onOpenSubMenu={() => {
              if (props.onOpenSubMenu) {
                props.onOpenSubMenu();
              }
            }}
            onCloseSubMenu={() => {
              if (props.onCloseSubMenu) {
                props.onCloseSubMenu();
              }
            }}
          />
        </div>
        <ToolTip
          active={isHover}
          position="right"
          arrow="center"
          parent={`#tooltip-sidemenu-${props.route.id}`}>
          <Text>{props.route.name}</Text>
        </ToolTip>
      </>
    );
  }
  return (
    <SideMenuItemInner
      route={props.route}
      isTeam={props.isTeam}
      showOnlyIcon={props.showOnlyIcon}
      depth={props.depth || 0}
      forceCloseSubMenu={props.forceCloseSubMenu}
      openMenu={props.openMenu}
      onOpenSubMenu={() => {
        if (props.onOpenSubMenu) {
          props.onOpenSubMenu();
        }
      }}
      onCloseSubMenu={() => {
        if (props.onCloseSubMenu) {
          props.onCloseSubMenu();
        }
      }}
    />
  );
};

export default React.memo(SideMenuItem);
