import React, { useContext } from 'react';
import ListMenuApp from '../../../organisms/list-menu-app';
import { View } from 'react-native';
import AppMySettingsProfilePage from './profile';
import AppMySettingsOtherPage from './other';
import AppMySettingsPasswordPage from './password';
import AppTwoFactorAuthPage from './two-factor';
import AppMySettingsGoogleAccountPage from './google-account';
import AppMySettingsAppleAccountPage from './apple-account';
import AppMySettingsCalendarExportPage from './calendar-export';
import OrganizationContractPage from '../organization-settings/contract';
import OrganizationSettingsInfoPage from '../organization-settings/organization-info';
import { LoginUserContext } from '../../../../../../modules/auth/LoginUserContext';
import { Plan, useOrganizationQuery } from '../../../../../../graphql/api/API';
import CustomScrollView from '../../../../../presentational/atoms/custom-scroll-view';
import OrganizationInvoicePage from '../organization-settings/invoice';

const AppMySettingsPage = () => {
  const [loginUser, _] = useContext(LoginUserContext);
  const { loading, data, error } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId,
    },
  });

  return (
    <CustomScrollView
      style={{
        height: 'calc(100vh - 57px)',
      }}>
      <ListMenuApp
        headerTitle={'自分の各種設定'}
        routes={[
          {
            name: 'プロフィール',
            path: `/app/:organizationId/my-settings/profile/`,
            component: AppMySettingsProfilePage,
          },
          {
            name: 'パスワード設定',
            path: `/app/:organizationId/my-settings/password/`,
            component: AppMySettingsPasswordPage,
          },
          {
            name: '2要素認証設定',
            path: `/app/:organizationId/my-settings/two-factor-auth/`,
            component: AppTwoFactorAuthPage,
          },
          {
            name: 'Googleアカウント連携',
            path: `/app/:organizationId/my-settings/google/`,
            component: AppMySettingsGoogleAccountPage,
          },
          {
            name: 'Apple ID連携',
            path: `/app/:organizationId/my-settings/apple/`,
            component: AppMySettingsAppleAccountPage,
          },
          {
            name: 'カレンダーデータ出力',
            path: `/app/:organizationId/my-settings/calendar-export/`,
            component: AppMySettingsCalendarExportPage,
          },
          {
            name: 'その他の設定',
            path: `/app/:organizationId/my-settings/other/`,
            component: AppMySettingsOtherPage,
          },
        ].concat(
          data?.organization!.plan.code === Plan.Basic ||
            data?.organization!.plan.code === Plan.Professional
            ? [
                {
                  name: 'ご契約内容',
                  path: `/app/:organizationId/my-settings/contract/`,
                  component: OrganizationContractPage,
                },
                {
                  name: '組織情報',
                  path: `/app/:organizationId/my-settings/info/`,
                  component: OrganizationSettingsInfoPage,
                },
                {
                  name: '請求履歴',
                  path: `/app/:organizationId/my-settings/invoice/`,
                  component: OrganizationInvoicePage,
                },
              ]
            : []
        )}
      />
    </CustomScrollView>
  );
};

export default React.memo(AppMySettingsPage);
