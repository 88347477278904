import React, { useContext, useState } from 'react';
import { View } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import { useMeQuery, useUpdatePasswordMutation } from '../../../../../../../graphql/api/API';
import Button from '../../../../../../presentational/atoms/button';
import Form from '../../../../../../presentational/atoms/form';
import Input from '../../../../../../presentational/atoms/input';
import Typography, { TypographyType } from '../../../../../../presentational/atoms/typography';
import { IThemePart } from '../../../../../../theme';
import SettingsElement from '../../../../organisms/settings-element';
import PasswordStrengthChecker from '../../../../../../presentational/molecules/password-strength-checker';

const Container = styled.View`
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
`;

interface IPasswordModalProps {
  closeModal: () => void;
}

const PasswordModal = (props: IPasswordModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [password, setPassword] = useState('');
  const { loading, data, error, refetch } = useMeQuery();

  if (loading || !data?.me) {
    return <></>;
  }

  const [updatePassword] = useUpdatePasswordMutation({
    variables: {
      input: {
        id: data.me.id!,
        password: password,
        versionNo: data.me.versionNo!,
      },
    },
  });
  return (
    <Form style={{ marginTop: 10, minWidth: 300 }}>
      <Input
        name={'password'}
        type={'password'}
        label={'変更後のパスワード'}
        validate={{
          required: {
            value: true,
            message: 'パスワードを入力してください',
          },
          minLength: {
            value: 8,
            message: 'パスワードは8文字以上で入力してください',
          },
        }}
        onChange={(value: string) => setPassword(value)}
      />
      <Input
        name={'passwordConfirm'}
        type={'password'}
        label={'確認のためもう一度入力してください'}
        validate={{
          validate: (value: string) => {
            if (value !== password) {
              return 'パスワードが一致していません';
            }
            return true;
          },
        }}
        onChange={(value: string) => {}}
      />
      <PasswordStrengthChecker password={password} />
      <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1 }}>
        <Button
          text={'変更する'}
          style={{
            minWidth: 100,
            marginRight: 10,
          }}
          onPress={async () => {
            await updatePassword();
            await refetch(); // 自動的にキャッシュを更新できないので、フェッチし直す
            props.closeModal();
          }}
        />
        <Button
          text={'キャンセル'}
          style={{
            minWidth: 100,
            marginRight: 10,
            backgroundColor: 'transparent',
          }}
          textStyle={{ color: themeContext.colors.primary }}
          disableValidate={true}
          onPress={async () => {
            props.closeModal();
          }}
        />
      </View>
    </Form>
  );
};

const AppMySettingsPasswordPage = () => {
  return (
    <Container>
      <SettingsElement
        title={`現在のパスワード`}
        changeText={'パスワードを変更する'}
        modal={(closeModal) => <PasswordModal closeModal={closeModal} />}>
        <Typography variant={TypographyType.Normal}>(非公開)</Typography>
      </SettingsElement>
    </Container>
  );
};

export default AppMySettingsPasswordPage;
