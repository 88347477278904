import { useState, useEffect } from 'react';

const useChunk = <T extends any>(
  originalItems: T[],
  size: number,
  initalIndex: number
): [T[], () => void, () => void, number, number, number] => {
  const [items, setItems] = useState(originalItems);
  const [pointer, setPointer] = useState(initalIndex | 0);
  const [chunkStartIndex, setChunkStartIndex] = useState(Math.max(pointer - size * 2, 0));
  const [chunkEndIndex, setChunkEndIndex] = useState(Math.min(pointer + size * 2, items.length));
  const [chunk, setChunk] = useState<T[]>(items.slice(chunkStartIndex, chunkEndIndex));

  useEffect(() => {
    setItems(originalItems);
  }, [originalItems]);

  useEffect(() => {
    const nextPointer = initalIndex | 0;
    const nextStartIndex = Math.max(nextPointer - size * 2, 0);
    const nextEndIndex = Math.min(nextPointer + size * 2, items.length);
    const list = items.slice(nextStartIndex, nextEndIndex);
    setChunk(list);
    setPointer(nextPointer);
    setChunkStartIndex(nextStartIndex);
    setChunkEndIndex(nextEndIndex);
  }, [initalIndex]);

  useEffect(() => {
    const nextPointer = pointer;
    const nextStartIndex = Math.max(nextPointer - size * 2, 0);
    const nextEndIndex = Math.min(nextPointer + size * 2, items.length);
    const list = items.slice(nextStartIndex, nextEndIndex);
    setChunk(list);
    setPointer(nextPointer);
    setChunkStartIndex(nextStartIndex);
    setChunkEndIndex(nextEndIndex);
  }, [items]);

  const nextChunk = () => {
    if (pointer + size >= items.length) {
      return;
    }
    const nextPointer = pointer + size;
    const nextStartIndex = Math.max(nextPointer - size * 2, 0);
    const nextEndIndex = Math.min(nextPointer + size * 2, items.length);
    const list = items.slice(nextStartIndex, nextEndIndex);
    setChunk(list);
    setPointer(nextPointer);
    setChunkStartIndex(nextStartIndex);
    setChunkEndIndex(nextEndIndex);
  };

  const prevChunk = () => {
    if (pointer <= size) {
      return;
    }
    const nextPointer = pointer - size;
    const nextStartIndex = Math.max(nextPointer - size * 2, 0);
    const nextEndIndex = Math.min(nextPointer + size * 2, items.length);
    const list = items.slice(nextStartIndex, nextEndIndex);
    setChunk(list);
    setPointer(nextPointer);
    setChunkStartIndex(nextStartIndex);
    setChunkEndIndex(nextEndIndex);
  };

  return [chunk, nextChunk, prevChunk, pointer, chunkStartIndex, chunkEndIndex];
};

export default useChunk;
