import React, { useContext, useEffect, useState } from 'react';
import styled, { ThemeContext } from 'styled-components/native';
import {
  Team,
  useFetchOrganizationActiveMembersQuery,
  useFetchOrganizationMembersQuery,
  useFetchTeamActiveMembersQuery,
  useJoinedTeamsQuery,
  WorkingHistory,
} from '../../../../../../graphql/api/API';
import { LoginUserContext } from '../../../../../../modules/auth/LoginUserContext';
import LoginUtil from '../../../../../../modules/auth/LoginUtil';
import Form from '../../../../../presentational/atoms/form';
import Input, { ListValueMap } from '../../../../../presentational/atoms/input';
import { IStyleTheme, IThemePart } from '../../../../../theme';
import MemberStatus from '../../../organisms/member-status';
import CustomScrollView from '../../../../../presentational/atoms/custom-scroll-view';
import Spinner from '../../../../../presentational/atoms/spinner';

interface ITeamMembersProps {
  team: Team;
}

const TeamMembers = (props: ITeamMembersProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const { loading: loading, data: data } = useFetchTeamActiveMembersQuery({
    variables: {
      teamId: props.team.id!,
    },
    fetchPolicy: 'network-only',
  });

  return (
    <>
      <Spinner loading={loading} />
      {(data?.fetchTeamActiveMembers || []).map((memberWithLatestTask, i) => {
        const isLoginMember = memberWithLatestTask!.member!.id === loginUser!.id;
        return (
          <MemberStatus
            organizationId={loginUser!.organizationId}
            teamId={props.team.id!}
            isLoginMember={isLoginMember}
            isMeAdmin={isLoginMember && memberWithLatestTask!.member!.adminRole}
            memberStatus={memberWithLatestTask!}
            widthFull={true}
            key={i}
          />
        );
      })}
    </>
  );
};

const OrganizationMembers = () => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const { loading: loading, data: data } = useFetchOrganizationActiveMembersQuery({
    fetchPolicy: 'network-only',
  });

  return (
    <>
      <Spinner loading={loading} />
      {(data?.fetchOrganizationActiveMembers || []).map((memberWithLatestTask, i) => {
        const isLoginMember = memberWithLatestTask!.member!.id === loginUser!.id;
        return (
          <MemberStatus
            organizationId={loginUser!.organizationId}
            isLoginMember={isLoginMember}
            isMeAdmin={isLoginMember && memberWithLatestTask!.member!.adminRole}
            memberStatus={memberWithLatestTask!}
            widthFull={true}
            key={i}
          />
        );
      })}
    </>
  );
};

const RightSideBarMembersStatus = () => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const teamIdFromUrl = LoginUtil.getTeamIdFromURl();
  const [team, setTeam] = useState<Team | null>();
  const [isAllMember, setAllMember] = useState(false);
  const [onlyInTeamTask, setOnlyInTeamTask] = useState(true);
  const { loading, data } = useJoinedTeamsQuery({
    variables: {
      organizationId: loginUser!.organizationId,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    const targets = (data?.joinedTeams || []).filter((team) => team!.id! === teamIdFromUrl);
    if (targets && targets.length > 0) {
      setTeam((targets[0] as Team) || null);
    }
  }, [teamIdFromUrl, data?.joinedTeams]);

  if (loading || !data?.joinedTeams) {
    return <></>;
  }

  return (
    <CustomScrollView
      style={{
        flexDirection: 'column',
        height: 'calc(100vh - 57px - 40px)',
        width: '100%',
        padding: 10,
      }}>
      <Form>
        <Input
          name={'team'}
          label={'チームを選択'}
          type={'picker'}
          isSearchable={true}
          initialValue={teamIdFromUrl || ''}
          pickerItems={[{ label: '組織の全メンバー', value: 'all' } as ListValueMap].concat(
            (data.joinedTeams || []).map((team) => {
              return {
                label: team!.name,
                value: team!.id!,
              };
            })
          )}
          onChange={(value) => {
            if (value === 'all') {
              setTeam(null);
              setAllMember(true);
              return;
            }
            const targets = (data.joinedTeams || []).filter((team) => team!.id! === value);
            if (targets && targets.length > 0) {
              setTeam((targets[0] as Team) || null);
              setAllMember(false);
            }
          }}
        />
      </Form>
      {team && <TeamMembers team={team} />}
      {isAllMember && <OrganizationMembers />}
    </CustomScrollView>
  );
};

export default React.memo(RightSideBarMembersStatus);
