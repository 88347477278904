import React, { useContext, useState, useEffect } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import {
  ApiKeyDocument,
  InitialViewType,
  MemberSettings,
  Plan,
  ProjectInitialViewType,
  useApiKeyQuery,
  useMySettingsQuery,
  useOrganizationQuery,
  useRefleshApiKeyMutation,
  useUpdateMySettingsMutation,
} from '../../../../../../../graphql/api/API';
import when from '../../../../../../../lang-extention/When';
import { LoginUserContext } from '../../../../../../../modules/auth/LoginUserContext';
import Button from '../../../../../../presentational/atoms/button';
import Form from '../../../../../../presentational/atoms/form';
import Input, { ListValueMap } from '../../../../../../presentational/atoms/input';
import CopyIcon from '../../../../../../presentational/molecules/image-icon/copy';
import CheckIcon from '../../../../../../presentational/molecules/image-icon/check';
import Typography, { TypographyType } from '../../../../../../presentational/atoms/typography';
import { IThemePart } from '../../../../../../theme';
import SettingsElement from '../../../../organisms/settings-element';
import ToolTip from 'react-portal-tooltip';

const Container = styled.View`
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
`;

interface ICalendarModalProps {
  mySettings: MemberSettings;
  closeModal: () => void;
}

const daysOfWeek: any = {
  1: '月曜日',
  2: '火曜日',
  3: '水曜日',
  4: '木曜日',
  5: '金曜日',
  6: '土曜日',
  7: '日曜日',
};

const CalendarModal = (props: ICalendarModalProps) => {
  const [calendarStartDayOfWeek, setCalendarStartDayOfWeek] = useState(
    props.mySettings.calendarStartDayOfWeek
  );
  const [updateMySettings] = useUpdateMySettingsMutation({
    variables: {
      input: {
        id: props.mySettings.id!,
        calendarStartDayOfWeek: calendarStartDayOfWeek,
        initialViewType: props.mySettings.initialViewTypeCode,
        projectInitialViewTypeCode: props.mySettings!.projectInitialViewTypeCode,
        autoAssignWhenTaskStart: props.mySettings.autoAssignWhenTaskStart,
        longTimeWorkingAlertMinutes: props.mySettings.longTimeWorkingAlertMinutes,
        allowExportCalendar: props.mySettings.allowExportCalendar,
        copyWorkingScheduleMemoToHistory: props.mySettings.copyWorkingScheduleMemoToHistory,
        versionNo: props.mySettings.versionNo,
      },
    },
  });
  return (
    <Form style={{ paddingVertical: 20 }}>
      <Input
        name={'weekday'}
        label={'週の開始曜日'}
        type={'picker'}
        pickerItems={Object.keys(daysOfWeek).map((key) => {
          return {
            label: daysOfWeek[parseInt(key)],
            value: parseInt(key),
          };
        })}
        initialValue={calendarStartDayOfWeek}
        validate={{
          required: {
            value: true,
            message: '選択してください',
          },
        }}
        onChange={(value) => setCalendarStartDayOfWeek(parseInt(value))}
      />
      <Button
        text="変更する"
        completeText="変更しました"
        style={{ marginTop: 20, height: 40, width: 300 }}
        onPress={async () => {
          await updateMySettings();
          props.closeModal();
        }}
      />
    </Form>
  );
};

interface IInitailViewModalProps {
  mySettings: MemberSettings;
  closeModal: () => void;
}

const InitailViewModal = (props: IInitailViewModalProps) => {
  const [initialViewType, setInitialViewType] = useState(props.mySettings.initialViewTypeCode);
  const [updateMySettings] = useUpdateMySettingsMutation({
    variables: {
      input: {
        id: props.mySettings.id!,
        calendarStartDayOfWeek: props.mySettings.calendarStartDayOfWeek,
        initialViewType: initialViewType,
        projectInitialViewTypeCode: props.mySettings.projectInitialViewTypeCode,
        autoAssignWhenTaskStart: props.mySettings.autoAssignWhenTaskStart,
        longTimeWorkingAlertMinutes: props.mySettings.longTimeWorkingAlertMinutes,
        allowExportCalendar: props.mySettings.allowExportCalendar,
        copyWorkingScheduleMemoToHistory: props.mySettings.copyWorkingScheduleMemoToHistory,
        versionNo: props.mySettings.versionNo,
      },
    },
  });
  return (
    <Form style={{ paddingVertical: 20 }}>
      <Input
        name={'initialViewType'}
        label={'初期表示画面'}
        type={'picker'}
        pickerItems={[
          {
            label: 'プロジェクト',
            value: InitialViewType.Project,
          },
          {
            label: 'マイタスク',
            value: InitialViewType.MyPage,
          },
        ]}
        initialValue={initialViewType}
        validate={{
          required: {
            value: true,
            message: '選択してください',
          },
        }}
        onChange={(value) => setInitialViewType(value as InitialViewType)}
      />
      <Button
        text="変更する"
        completeText="変更しました"
        style={{ marginTop: 20, height: 40, width: 300 }}
        onPress={async () => {
          await updateMySettings();
          props.closeModal();
        }}
      />
    </Form>
  );
};

interface IProjectInitailViewModalProps {
  mySettings: MemberSettings;
  closeModal: () => void;
}

const ProjectInitailViewModal = (props: IProjectInitailViewModalProps) => {
  const [projectInitialViewType, setProjectInitialViewType] = useState(
    props.mySettings.projectInitialViewTypeCode
  );
  const [updateMySettings] = useUpdateMySettingsMutation({
    variables: {
      input: {
        id: props.mySettings.id!,
        calendarStartDayOfWeek: props.mySettings.calendarStartDayOfWeek,
        initialViewType: props.mySettings.initialViewTypeCode,
        projectInitialViewTypeCode: projectInitialViewType,
        autoAssignWhenTaskStart: props.mySettings.autoAssignWhenTaskStart,
        longTimeWorkingAlertMinutes: props.mySettings.longTimeWorkingAlertMinutes,
        allowExportCalendar: props.mySettings.allowExportCalendar,
        copyWorkingScheduleMemoToHistory: props.mySettings.copyWorkingScheduleMemoToHistory,
        versionNo: props.mySettings.versionNo,
      },
    },
  });
  return (
    <Form style={{ paddingVertical: 20 }}>
      <Input
        name={'projectInitialViewType'}
        label={'プロジェクト画面の初期表示方法'}
        type={'picker'}
        pickerItems={[
          {
            label: 'リスト表示',
            value: ProjectInitialViewType.List,
          },
          {
            label: 'カンバンボード表示',
            value: ProjectInitialViewType.KanbanBoard,
          },
          {
            label: 'スケジュール表示',
            value: ProjectInitialViewType.GanttChart,
          },
        ]}
        initialValue={projectInitialViewType}
        validate={{
          required: {
            value: true,
            message: '選択してください',
          },
        }}
        onChange={(value) => setProjectInitialViewType(value as ProjectInitialViewType)}
      />
      <Button
        text="変更する"
        completeText="変更しました"
        style={{ marginTop: 20, height: 40, width: 300 }}
        onPress={async () => {
          await updateMySettings();
          props.closeModal();
        }}
      />
    </Form>
  );
};

interface ITaskAutoAssignWhenTaskStartModalProps {
  mySettings: MemberSettings;
  closeModal: () => void;
}

const TaskAutoAssignWhenTaskStartModal = (props: ITaskAutoAssignWhenTaskStartModalProps) => {
  const [autoAssignWhenTaskStart, setAutoAssignWhenTaskStart] = useState(
    props.mySettings.autoAssignWhenTaskStart
  );
  const [updateMySettings] = useUpdateMySettingsMutation({
    variables: {
      input: {
        id: props.mySettings.id!,
        calendarStartDayOfWeek: props.mySettings.calendarStartDayOfWeek,
        initialViewType: props.mySettings.initialViewTypeCode,
        projectInitialViewTypeCode: props.mySettings.projectInitialViewTypeCode,
        autoAssignWhenTaskStart: autoAssignWhenTaskStart,
        longTimeWorkingAlertMinutes: props.mySettings.longTimeWorkingAlertMinutes,
        allowExportCalendar: props.mySettings.allowExportCalendar,
        copyWorkingScheduleMemoToHistory: props.mySettings.copyWorkingScheduleMemoToHistory,
        versionNo: props.mySettings.versionNo,
      },
    },
  });
  return (
    <Form style={{ paddingVertical: 20, minWidth: 450 }}>
      <Input
        name={'autoAssignWhenTaskStart'}
        label={'作業開始時の担当者の変更設定'}
        type={'picker'}
        pickerItems={[
          {
            label: '作業開始時にタスクの担当者を自分に割り当てる',
            value: 'true',
          },
          {
            label: '作業開始時に担当者は変更しない',
            value: 'false',
          },
        ]}
        initialValue={autoAssignWhenTaskStart.toString()}
        validate={{
          required: {
            value: true,
            message: '選択してください',
          },
        }}
        onChange={(value) => setAutoAssignWhenTaskStart(value === 'true')}
      />
      <Button
        text="変更する"
        completeText="変更しました"
        style={{ marginTop: 20, height: 40, width: 300 }}
        onPress={async () => {
          await updateMySettings();
          props.closeModal();
        }}
      />
    </Form>
  );
};

interface ICopyWorkingScheduleMemoToHistoryModalProps {
  mySettings: MemberSettings;
  closeModal: () => void;
}

const CopyWorkingScheduleMemoToHistoryModalProps = (
  props: ICopyWorkingScheduleMemoToHistoryModalProps
) => {
  const [copyWorkingScheduleMemoToHistory, setCopyWorkingScheduleMemoToHistory] = useState(
    props.mySettings.copyWorkingScheduleMemoToHistory
  );
  const [updateMySettings] = useUpdateMySettingsMutation({
    variables: {
      input: {
        id: props.mySettings.id!,
        calendarStartDayOfWeek: props.mySettings.calendarStartDayOfWeek,
        initialViewType: props.mySettings.initialViewTypeCode,
        projectInitialViewTypeCode: props.mySettings.projectInitialViewTypeCode,
        autoAssignWhenTaskStart: props.mySettings.autoAssignWhenTaskStart,
        longTimeWorkingAlertMinutes: props.mySettings.longTimeWorkingAlertMinutes,
        allowExportCalendar: props.mySettings.allowExportCalendar,
        copyWorkingScheduleMemoToHistory: copyWorkingScheduleMemoToHistory,
        versionNo: props.mySettings.versionNo,
      },
    },
  });
  return (
    <Form style={{ paddingVertical: 20, minWidth: 450 }}>
      <Input
        name={'copyWorkingScheduleMemoToHistory'}
        label={'作業予定から作業履歴を作成する際に、作業メモをコピーするか'}
        type={'picker'}
        pickerItems={[
          {
            label: 'コピーする',
            value: 'true',
          },
          {
            label: 'コピーしない',
            value: 'false',
          },
        ]}
        initialValue={copyWorkingScheduleMemoToHistory.toString()}
        validate={{
          required: {
            value: true,
            message: '選択してください',
          },
        }}
        onChange={(value) => setCopyWorkingScheduleMemoToHistory(value === 'true')}
      />
      <Button
        text="変更する"
        completeText="変更しました"
        style={{ marginTop: 20, height: 40, width: 300 }}
        onPress={async () => {
          await updateMySettings();
          props.closeModal();
        }}
      />
    </Form>
  );
};

const longTimeWorkingAlertPreset = [
  {
    label: '通知しない',
    value: 0,
  },
  {
    label: '15分',
    value: 15,
  },
  {
    label: '30分',
    value: 30,
  },
  {
    label: '45分',
    value: 45,
  },
  {
    label: '1時間',
    value: 60,
  },
  {
    label: '1時間15分',
    value: 75,
  },
  {
    label: '1時間30分',
    value: 90,
  },
  {
    label: '1時間45分',
    value: 105,
  },
  {
    label: '2時間',
    value: 120,
  },
] as ListValueMap[];

interface ILongTimeWorkingTaskAlertMinutesModalProps {
  mySettings: MemberSettings;
  closeModal: () => void;
}

const LongTimeWorkingTaskAlertMinutesModal = (
  props: ILongTimeWorkingTaskAlertMinutesModalProps
) => {
  const [longTimeWorkingAlertMinutes, setLongTimeWorkingAlertMinutes] = useState<number>(
    props.mySettings.longTimeWorkingAlertMinutes || 0
  );
  const [updateMySettings] = useUpdateMySettingsMutation({
    variables: {
      input: {
        id: props.mySettings.id!,
        calendarStartDayOfWeek: props.mySettings.calendarStartDayOfWeek,
        initialViewType: props.mySettings.initialViewTypeCode,
        projectInitialViewTypeCode: props.mySettings.projectInitialViewTypeCode,
        autoAssignWhenTaskStart: props.mySettings.autoAssignWhenTaskStart,
        longTimeWorkingAlertMinutes:
          longTimeWorkingAlertMinutes === 0 ? null : longTimeWorkingAlertMinutes,
        allowExportCalendar: props.mySettings.allowExportCalendar,
        copyWorkingScheduleMemoToHistory: props.mySettings.copyWorkingScheduleMemoToHistory,
        versionNo: props.mySettings.versionNo,
      },
    },
  });

  useEffect(() => {
    setLongTimeWorkingAlertMinutes(props.mySettings.longTimeWorkingAlertMinutes || 0);
  }, [props.mySettings.longTimeWorkingAlertMinutes]);

  return (
    <Form style={{ paddingVertical: 20 }}>
      <Input
        name={'longTimeWorkingAlertMinutes'}
        label={'タスクが作業中であることのリマインダー設定'}
        type={'picker'}
        pickerItems={longTimeWorkingAlertPreset}
        initialValue={longTimeWorkingAlertMinutes || 0}
        validate={{
          required: {
            value: true,
            message: '選択してください',
          },
        }}
        onChange={(value) => {
          setLongTimeWorkingAlertMinutes(Number(value));
        }}
      />
      <Button
        text="変更する"
        completeText="変更しました"
        style={{ marginTop: 20, height: 40, width: 300 }}
        onPress={async () => {
          await updateMySettings();
          props.closeModal();
        }}
      />
    </Form>
  );
};

interface IApiKeyRefleshModalProps {
  closeModal: () => void;
}

const ApiKeyRefleshModal = (props: IApiKeyRefleshModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [refleshApiKey] = useRefleshApiKeyMutation({
    refetchQueries: [{ query: ApiKeyDocument }],
  });

  return (
    <View style={{ paddingVertical: 20 }}>
      <Typography variant={TypographyType.Description} style={{ fontSize: 16 }}>
        APIトークンを新しく発行し直します。{'\n'}
        発行が完了すると、現在発行されているトークンは{'\n'}使用できなくなります。{'\n'}
        また、この操作は取り消しできません。{'\n'}
        変更をしますか？
      </Typography>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 20,
        }}>
        <Button
          text="変更する"
          completeText="変更しました"
          onPress={async () => {
            await refleshApiKey();
            props.closeModal();
          }}
        />
        <Button
          text={'キャンセル'}
          style={{
            backgroundColor: 'transparent',
          }}
          textStyle={{ color: themeContext.colors.primary }}
          disableValidate={true}
          onPress={() => {
            props.closeModal();
          }}
        />
      </View>
    </View>
  );
};

const AppMySettingsOtherPage = () => {
  const [loginUser, _] = useContext(LoginUserContext);
  const [tokenCopied, setTokenCopied] = useState(false);
  const [showApiToken, setShowApiToken] = useState(false);
  const { loading, data, error } = useMySettingsQuery();
  const { data: organizaionData, loading: organizationLoading } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId,
    },
  });
  const { data: apiKeyData, loading: apiKeyLoading } = useApiKeyQuery();

  useEffect(() => {
    setTimeout(() => {
      if (tokenCopied) {
        setTokenCopied(false);
      }
    }, 3000);
  }, [tokenCopied]);

  if (
    loading ||
    !data?.mySettings ||
    organizationLoading ||
    !organizaionData?.organization ||
    !apiKeyData?.apiKey ||
    apiKeyLoading
  ) {
    return <></>;
  }

  return (
    <Container>
      <SettingsElement
        title={`初期表示画面`}
        titleWidth={200}
        changeText={'変更する'}
        modal={(closeModal) => (
          <InitailViewModal mySettings={data.mySettings!} closeModal={closeModal} />
        )}>
        <Typography variant={TypographyType.Normal}>
          {when(data.mySettings.initialViewTypeCode)
            .on(
              (v) => v === InitialViewType.Project,
              () => 'プロジェクトの画面'
            )
            .on(
              (v) => v === InitialViewType.MyPage,
              () => 'マイタスクの画面'
            )
            .otherwise(() => '-')}
        </Typography>
      </SettingsElement>
      <SettingsElement
        title={`プロジェクト画面の${'\n'}初期表示方法`}
        titleWidth={200}
        changeText={'表示方法を変更する'}
        modal={(closeModal) => (
          <ProjectInitailViewModal mySettings={data.mySettings!} closeModal={closeModal} />
        )}>
        <Typography variant={TypographyType.Normal}>
          {when(data.mySettings.projectInitialViewTypeCode)
            .on(
              (v) => v === ProjectInitialViewType.List,
              () => 'リスト表示'
            )
            .on(
              (v) => v === ProjectInitialViewType.KanbanBoard,
              () => 'カンバンボード表示'
            )
            .on(
              (v) => v === ProjectInitialViewType.GanttChart,
              () => 'スケジュール表示'
            )
            .otherwise(() => '-')}
        </Typography>
      </SettingsElement>
      {(organizaionData.organization.plan.code === Plan.Business ||
        organizaionData.organization.plan.code === Plan.Enterprise) && (
        <SettingsElement
          title={`作業開始時の${'\n'}担当者の変更設定`}
          titleWidth={200}
          changeText={'設定を変更する'}
          modal={(closeModal) => (
            <TaskAutoAssignWhenTaskStartModal
              mySettings={data.mySettings!}
              closeModal={closeModal}
            />
          )}>
          <Typography variant={TypographyType.Normal}>
            {data.mySettings.autoAssignWhenTaskStart
              ? '作業開始時にタスクの担当者を自分に割り当てる'
              : '作業開始時に担当者は変更しない'}
          </Typography>
        </SettingsElement>
      )}
      <SettingsElement
        title={`作業中のタスクの${'\n'}リマインダー設定`}
        titleWidth={200}
        changeText={'リマインダー設定を変更する'}
        modal={(closeModal) => (
          <LongTimeWorkingTaskAlertMinutesModal
            mySettings={data.mySettings!}
            closeModal={closeModal}
          />
        )}>
        <Typography variant={TypographyType.Normal}>
          {data.mySettings!.longTimeWorkingAlertMinutes === null
            ? '長時間作業中のタスクがあっても通知しない'
            : `タスクが「${
                longTimeWorkingAlertPreset.filter(
                  (preset) => preset.value === data.mySettings!.longTimeWorkingAlertMinutes
                )[0].label
              }」以上作業中の場合に、リマインダーを通知する`}
        </Typography>
      </SettingsElement>
      <SettingsElement
        title={`作業予定から${'\n'}作業履歴を作成する際に${'\n'}作業メモをコピーするか`}
        titleWidth={200}
        changeText={'設定を変更する'}
        titleWidth={200}
        modal={(closeModal) => (
          <CopyWorkingScheduleMemoToHistoryModalProps
            mySettings={data.mySettings!}
            closeModal={closeModal}
          />
        )}>
        <Typography variant={TypographyType.Normal}>
          {data.mySettings.copyWorkingScheduleMemoToHistory ? 'コピーする' : 'コピーしない'}
        </Typography>
      </SettingsElement>
      {organizaionData.organization.plan.code != Plan.Basic && (
        <SettingsElement
          title={`APIトークン`}
          titleWidth={200}
          changeText={'変更する'}
          modal={(closeModal) => <ApiKeyRefleshModal closeModal={closeModal} />}>
          <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Typography variant={TypographyType.Normal}>
              {showApiToken
                ? apiKeyData.apiKey.token
                : apiKeyData.apiKey.token.replace(
                    /^.{60}/,
                    '************************************************************'
                  )}
            </Typography>
            {!tokenCopied ? (
              <CopyIcon
                containerStyle={{ marginLeft: 5 }}
                size={20}
                onPress={async () => {
                  navigator.clipboard.writeText(apiKeyData.apiKey!.token);
                  setTokenCopied(true);
                }}
              />
            ) : (
              <>
                <div id="copied-check-icon">
                  <CheckIcon containerStyle={{ marginLeft: 5 }} size={20} on />
                </div>
                <ToolTip
                  active={tokenCopied}
                  position="right"
                  arrow="center"
                  parent={'#copied-check-icon'}
                  tooltipTimeout={3000}>
                  <Text>コピーしました</Text>
                </ToolTip>
              </>
            )}
            {!showApiToken ? (
              <TouchableOpacity style={{ marginLeft: 10 }} onPress={() => setShowApiToken(true)}>
                <Typography variant={TypographyType.Description} style={{ fontSize: 12 }}>
                  トークンを表示する
                </Typography>
              </TouchableOpacity>
            ) : (
              <TouchableOpacity style={{ marginLeft: 10 }} onPress={() => setShowApiToken(false)}>
                <Typography variant={TypographyType.Description} style={{ fontSize: 12 }}>
                  トークンを隠す
                </Typography>
              </TouchableOpacity>
            )}
          </View>
          <Typography variant={TypographyType.Description}>
            Time Designer APIを利用にする際に必要になるAPIトークンです。{'\n'}
            APIのご利用方法はAPIドキュメントをご参照ください。
          </Typography>
        </SettingsElement>
      )}
      {/* <SettingsElement
        title={`カレンダーの${'\n'}週の開始曜日`}
        changeText={'開始曜日を変更する'}
        modal={(closeModal) => (
          <CalendarModal mySettings={data.mySettings!} closeModal={closeModal} />
        )}>
        <Typography variant={TypographyType.Normal}>
          {daysOfWeek[data.mySettings.calendarStartDayOfWeek]}
        </Typography>
      </SettingsElement> */}
    </Container>
  );
};

export default AppMySettingsOtherPage;
