import React, { CSSProperties, useContext } from 'react';
import { Link as DomLink } from 'react-router-dom';
import { ThemeContext } from 'styled-components/native';
import { IThemePart } from '../../../theme';

interface IProps {
  path: string;
  state?: Object;
  isExternalLink?: boolean;
  style?: CSSProperties;
}

const Link: React.FC<IProps> = (props: IProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  if (props.isExternalLink === true) {
    const aTag = React.createElement(
      'a',
      {
        style: Object.assign(
          {
            textDecoration: 'none',
            margin: 0,
            padding: 0,
            lineHeight: 0,
            color: themeContext.colors.link,
          },
          props.style
        ),
        target: '_blank',
        href: props.path,
      },
      (props as any).children
    );
    return aTag;
  } else {
    return (
      <DomLink
        to={{
          pathname: props.path,
          state: props.state,
        }}
        style={Object.assign(
          {
            textDecoration: 'none',
            margin: 0,
            padding: 0,
            lineHeight: 0,
            color: themeContext.colors.link,
          },
          props.style
        )}>
        {(props as any).children}
      </DomLink>
    );
  }
};

export default Link;
