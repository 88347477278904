import React, { useContext } from 'react';
import { View, Text, StyleProp, ViewStyle } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import Blur from 'react-css-blur';
import { IThemePart } from '../../../../theme';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import Button from '../../../../presentational/atoms/button';
import Link from '../../../../presentational/atoms/link';
import { useHistory } from 'react-router';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import { Plan, useOrganizationQuery } from '../../../../../graphql/api/API';

const Overlay = styled.TouchableOpacity`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface IProps {
  isNotAllowedPlan: boolean;
  children: React.ReactNode;
  style?: StyleProp<ViewStyle> | undefined;
  contentsStyle?: StyleProp<ViewStyle> | undefined;
}

const PlanNotAllowedView = (props: IProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const history = useHistory();
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const { loading, data } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId,
    },
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <></>;
  }

  if (!props.isNotAllowedPlan) {
    return <>{props.children}</>;
  }

  return (
    <View style={props.style}>
      <Overlay>
        <View style={props.contentsStyle}>
          <View
            style={{
              backgroundColor: '#FFFFFF',
              minHeight: 200,
              paddingHorizontal: 20,
              paddingVertical: 20,
              borderColor: themeContext.colors.primary,
              borderWidth: 1,
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Typography variant={TypographyType.Normal} style={{ fontSize: 18 }}>
              この機能をご利用頂くためには、Proプランへのアップグレードが必要です
            </Typography>
            <View style={{ marginTop: 30, justifyContent: 'center', alignItems: 'center' }}>
              <Link
                isExternalLink={true}
                path={'https://timedesigner.com/plan.html'}
                style={{
                  marginBottom: 10,
                }}>
                <Typography
                  variant={TypographyType.Normal}
                  style={{ fontSize: 18, color: themeContext.colors.link }}>
                  プランの詳細を確認する
                </Typography>
              </Link>
              <Button
                text={'「ご契約内容」画面へ移動する'}
                style={{
                  minWidth: 100,
                  marginVertical: 10,
                }}
                onPress={() => {
                  if (
                    data!.organization!.plan.code === Plan.Basic ||
                    data!.organization!.plan.code === Plan.Professional
                  ) {
                    history.push(`/app/${loginUser!.organizationId}/my-settings/contract/`);
                  } else {
                    history.push(`/app/${loginUser!.organizationId}/settings/contract/`);
                  }
                }}
              />
            </View>
          </View>
        </View>
      </Overlay>
      <Blur radius={props.isNotAllowedPlan ? '3px' : 0} transition="400ms">
        {props.children}
      </Blur>
    </View>
  );
};

export default PlanNotAllowedView;
