import React, { useContext, useEffect, useState } from 'react';
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../../theme';
import {
  ProjectTemplate,
  ProjectTemplatesDocument,
  useCreateProjectTemplateMutation,
  useOrganizationClientsQuery,
} from '../../../../../graphql/api/API';
import Form from '../../../../presentational/atoms/form';
import Input, { ListValueMap } from '../../../../presentational/atoms/input';
import { View } from 'react-native';
import Button from '../../../../presentational/atoms/button';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import EditableText from '../../../../presentational/atoms/editable-text';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import TaskInfoElement from '../task-detail/task-info-element';

const Container = styled.View``;

interface IProps {
  onComplete: (project: ProjectTemplate) => void;
  onCancel: () => void;
}

const ProjectTemplateCreateDialog = (props: IProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [projectName, setProjectName] = useState('');
  const [clientId, setClientId] = useState<string | null>(null);
  const [estimateTimeSec, setEstimateTimeSec] = useState<number | null>(null);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const { loading, data } = useOrganizationClientsQuery({
    variables: {
      organizationId: loginUser!.organizationId,
    },
    fetchPolicy: 'network-only',
  });
  const [createProject, _] = useCreateProjectTemplateMutation({
    variables: {
      input: {
        name: projectName,
        clientId: clientId || null,
        estimateTimeSec: estimateTimeSec,
      },
    },
    update: (cache, result) => {
      cache.modify({
        fields: {
          projectTemplates(existing = []) {
            const newProject = cache.writeQuery({
              data: result.data?.createProjectTemplate,
              query: ProjectTemplatesDocument,
            });
            return [...existing, newProject];
          },
        },
      });
    },
  });

  useEffect(() => {
    setProjectName('');
    setClientId(null);
    setEstimateTimeSec(null);
  }, []);

  if (loading) {
    return <></>;
  }

  return (
    <Container>
      <Form style={{ minWidth: 400, maxWidth: 600 }}>
        <TaskInfoElement title={'プロジェクト名'} style={{ flex: 1 }}>
          <Input
            name={'projectName'}
            placeholder={'プロジェクト名'}
            focus={true}
            onChange={(value) => setProjectName(value)}
            onPressEnter={async () => {
              const result = await createProject();
              props.onComplete(result.data!.createProjectTemplate!);
            }}
            validate={{
              required: {
                value: true,
                message: '新しいプロジェクトの名前を入力してください',
              },
              maxLength: {
                value: 100,
                message: '100文字以内で入力してください',
              },
            }}
          />
        </TaskInfoElement>
        <TaskInfoElement title={'取引先'} style={{ flex: 1 }}>
          <Input
            name={'client'}
            placeholder={'取引先を選択する'}
            type={'picker'}
            isSearchable={true}
            pickerItems={[
              {
                label: 'なし',
                value: null,
              } as ListValueMap,
            ].concat(
              (data?.organizationClients || []).slice().map((client) => {
                return {
                  label: `${client!.name}` + (client!.code ? ` (${client!.code})` : ''),
                  value: client!.id,
                };
              })
            )}
            onChange={(value) => setClientId(value)}
          />
        </TaskInfoElement>
        <TaskInfoElement title={'見積もり時間'} style={{ flex: 1, zIndex: 2 }}>
          <EditableText
            value={estimateTimeSec}
            type={'time-picker'}
            style={{
              flex: 1,
              minWidth: 400,
              borderWidth: 1,
              marginTop: 5,
              borderColor: themeContext.colors.separator,
            }}
            containerStyle={{ paddingHorizontal: 20, paddingVertical: 5 }}
            onChange={(value) => {
              setEstimateTimeSec((value as number) || null);
            }}
          />
        </TaskInfoElement>
        <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 40 }}>
          <Button
            text={'作成する'}
            completeText={'作成しました'}
            style={{
              minWidth: 100,
              marginRight: 10,
            }}
            onPress={async () => {
              const result = await createProject();
              props.onComplete(result.data!.createProjectTemplate!);
            }}
          />
          <Button
            text={'キャンセル'}
            style={{
              minWidth: 100,
              marginRight: 10,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.primary }}
            disableValidate={true}
            onPress={async () => {
              props.onCancel();
            }}
          />
        </View>
      </Form>
    </Container>
  );
};

export default ProjectTemplateCreateDialog;
