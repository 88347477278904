import SigninPage from '../../components/container/web/templates/signin';
import SigninOtherPage from '../../components/container/web/templates/signin-other';
import SignupPage from '../../components/container/web/templates/signup';
import SignupSelectPlanPage from '../../components/container/web/templates/signup-select-plan';
import SignupProfilePage from '../../components/container/web/templates/signup-profile';
import SignupProfileImagePage from '../../components/container/web/templates/signup-profile-image';
import SignupOrganizationPage from '../../components/container/web/templates/signup-organization';
import SignupThanksPage from '../../components/container/web/templates/signup-thanks';
import SignupConfirmBetaPage from '../../components/container/web/templates/signup-confirm-beta';
import SignupLicenceCountPage from '../../components/container/web/templates/signup-licence-count';
import SignupRequestCompletePage from '../../components/container/web/templates/signup-request-complete';
import SignupInputPasswordPage from '../../components/container/web/templates/signup-input-password';
import ForgotPasswordPage from '../../components/container/web/templates/forgot-password';
import ForgotPasswordThanksPage from '../../components/container/web/templates/forgot-password-thanks';
import AppPage from '../../components/container/web/templates/app';
import { IRouteItem } from '../RouteItem';
import SignupAddOrganizationPage from '../../components/container/web/templates/signup-add-organization';
import JoinOrganizationPage from '../../components/container/web/templates/join-organization';
import JoinOrganizationProfile from '../../components/container/web/templates/join-organization-profile';
import JoinOrganizationProfileImage from '../../components/container/web/templates/join-organization-profile-image';
import JoinOrganizationThanks from '../../components/container/web/templates/join-organization-thanks';
import JoinWithLinkOrganizationPage from '../../components/container/web/templates/join-with-link-organization';
import JoinWithLinkOrganizationProfile from '../../components/container/web/templates/join-with-link-organization-profile';
import JoinWithLinkOrganizationProfileImage from '../../components/container/web/templates/join-with-link-organization-profile-image';
import JoinWithLinkOrganizationThanks from '../../components/container/web/templates/join-with-link-organization-thanks';
import JoinWithLinkOrganizationRequestCompletePage from '../../components/container/web/templates/join-with-link-organization-request-complete';
import JoinWithLinkOrganizationWithMailAddressPage from '../../components/container/web/templates/join-with-link-organization-with-email';

import PasswordResetPage from '../../components/container/web/templates/password-reset';
import PasswordResetCompletePage from '../../components/container/web/templates/password-reset-complete';
import RequiredTwoFactorAuthSettingPage from '../../components/container/web/templates/required-two-factor-auth-setting';

const routes: IRouteItem[] = [
  { id: 'signin', path: '/signin', name: 'Signin', component: SigninPage },
  { id: 'signin-other', path: '/signin-other', name: 'SigninOther', component: SigninOtherPage },
  {
    id: 'signup-new-organization',
    path: '/signup/new-organization',
    name: 'SignupNewOrganizationPage',
    component: SignupAddOrganizationPage,
  },
  {
    id: 'signup-select-plan',
    path: '/signup/select-plan',
    name: 'SignupSelectPlan',
    component: SignupSelectPlanPage,
  },
  {
    id: 'signup-input-profile',
    path: '/signup/input-profile',
    name: 'SignupProfile',
    component: SignupProfilePage,
  },
  {
    id: 'signup-input-profile-image',
    path: '/signup/input-profile-image',
    name: 'SignupProfileImage',
    component: SignupProfileImagePage,
  },
  {
    id: 'signup-input-organization',
    path: '/signup/input-organization',
    name: 'SignupOrganization',
    component: SignupOrganizationPage,
  },
  {
    id: 'signup-confirm-beta',
    path: '/signup/confirm-beta',
    name: 'SignupConfirmBeta',
    component: SignupConfirmBetaPage,
  },
  {
    id: 'signup-licence-count',
    path: '/signup/licence-count',
    name: 'SignupLicenceCount',
    component: SignupLicenceCountPage,
  },
  {
    id: 'signup-thanks',
    path: '/signup/thanks',
    name: 'SignupThanks',
    component: SignupThanksPage,
  },
  {
    id: 'signup-request',
    path: '/signup/request-complete',
    name: 'SignupRequest',
    component: SignupRequestCompletePage,
  },
  {
    id: 'signup-input-password',
    path: '/signup/process',
    name: 'SignupInputPassword',
    component: SignupInputPasswordPage,
  },
  { id: 'signup', path: '/signup', name: 'Signup', component: SignupPage },
  {
    id: 'forgot-password',
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPasswordPage,
  },
  {
    id: 'forgot-password-thanks',
    path: '/forgot-password-thanks',
    name: 'ForgotPasswordThanks',
    component: ForgotPasswordThanksPage,
  },
  {
    id: 'password-reset',
    path: '/password-reset',
    name: 'PasswordReset',
    component: PasswordResetPage,
  },
  {
    id: 'password-reset-complete',
    path: '/password-reset-complete',
    name: 'PasswordResetComplete',
    component: PasswordResetCompletePage,
  },
  {
    id: 'join-organization-profile-image',
    path: '/join/input-profile-image',
    name: 'JoinOrganizationProfileImage',
    component: JoinOrganizationProfileImage,
  },
  {
    id: 'join-organization-profile',
    path: '/join/input-profile',
    name: 'JoinOrganizationProfile',
    component: JoinOrganizationProfile,
  },
  {
    id: 'join-organization-thanks',
    path: '/join/thanks',
    name: 'JoinOrganizationThanks',
    component: JoinOrganizationThanks,
  },
  {
    id: 'join-organization',
    path: '/join',
    name: 'JoinOrganization',
    component: JoinOrganizationPage,
  },
  {
    id: 'join-with-link-organization-request-complete',
    path: '/join-with-link/request-complete',
    name: 'JoinWithLinkOrganizationRequestComplete',
    component: JoinWithLinkOrganizationRequestCompletePage,
  },
  {
    id: 'join-with-link-organization',
    path: '/join-with-link/with-email',
    name: 'JoinWithLinkOrganizationWithMailAddress',
    component: JoinWithLinkOrganizationWithMailAddressPage,
  },
  {
    id: 'join-with-link-organization-profile-image',
    path: '/join-with-link/input-profile-image',
    name: 'JoinWithLinkOrganizationProfileImage',
    component: JoinWithLinkOrganizationProfileImage,
  },
  {
    id: 'join-with-link-organization-profile',
    path: '/join-with-link/input-profile',
    name: 'JoinWithLinkOrganizationProfile',
    component: JoinWithLinkOrganizationProfile,
  },
  {
    id: 'join-with-link-organization-thanks',
    path: '/join-with-link/thanks',
    name: 'JoinWithLinkOrganizationThanks',
    component: JoinWithLinkOrganizationThanks,
  },
  {
    id: 'join-with-link-organization',
    path: '/join-with-link',
    name: 'JoinWithLinkOrganization',
    component: JoinWithLinkOrganizationPage,
  },
  {
    id: 'required-two-factor-auth-setting',
    path: '/required-two-factor-auth-setting',
    name: 'RequiredTwoFactorAuthSetting',
    component: RequiredTwoFactorAuthSettingPage,
  },
  { id: 'app', path: '/app', name: 'App', component: AppPage },
];

export default routes;
