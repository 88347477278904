import React, { useContext, useState } from 'react';
import styled, { ThemeContext } from 'styled-components/native';
import { Image } from 'react-native';
import { useScreenDimensions } from 'use-dimensions';
import { useHistory } from 'react-router';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import Button from '../../../../presentational/atoms/button';
import Form from '../../../../presentational/atoms/form';
import Input from '../../../../presentational/atoms/input';
import Link from '../../../../presentational/atoms/link';
import { IThemePart } from '../../../../theme';
import TimeUtil from '../../../../../util/TimeUtil';
import logoLargeBlack from '../../../../../base64Images/logo/logo-large-black';
import forgotPasswordImage from '../../../../../base64Images/forgot-password/forgot-password';
import { useRequestPasswordResetMutation } from '../../../../../graphql/api/API';

const Header = styled.View`
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  height: 100px;
  padding-left: 20px;
  padding-top: 20px;
`;

const Container = styled.View`
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  height: calc(100vh - 100px);
`;

const LeftContainer = styled.View`
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 0 50px;
`;

const RightContainer = styled.View`
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 0 50px;
`;

const RightContainerInner = styled.View`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LinkContainer = styled.View`
  margin-top: 1.5rem;
`;

const DescriptionContainer = styled.View`
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
`;

const ForgotPasswordPage = () => {
  const { height, width } = useScreenDimensions();
  const history = useHistory();
  const themeContext: IThemePart = useContext(ThemeContext);
  const [mailAddress, setMailAddress] = useState('');
  const [requestPasswordReset, _] = useRequestPasswordResetMutation({
    variables: {
      input: {
        mailAddress: mailAddress,
      },
    },
  });

  return (
    <>
      <Header>
        <Image
          source={{ uri: logoLargeBlack }}
          resizeMode="contain"
          style={{
            width: 300,
            height: 100,
          }}
        />
      </Header>
      <Container>
        <LeftContainer>
          <Image
            source={{ uri: forgotPasswordImage }}
            resizeMode="contain"
            style={{
              width: Math.max(width / 4, 500),
              height: Math.max(width / 4, 500),
            }}
          />
        </LeftContainer>
        <RightContainer>
          <RightContainerInner>
            <Typography variant={TypographyType.Title} style={{ marginVertical: 30 }}>
              パスワードをリセットする
            </Typography>
            <DescriptionContainer>
              <Typography
                variant={
                  TypographyType.Description
                }>{`ご登録頂いているメールアドレスを入力してください。${'\n'}パスワード再設定用リンクを記載したメールをお送りします。`}</Typography>
            </DescriptionContainer>
            <Form style={{ marginVertical: 30 }}>
              <Input
                name={'mailAddress'}
                label={'メールアドレス'}
                value={mailAddress}
                onChange={(value) => setMailAddress(value)}
                validate={{
                  required: {
                    value: true,
                    message: 'メールアドレスを入力してください',
                  },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: '正しいメールアドレスを入力してください',
                  },
                }}
              />
              <Button
                text="パスワード再設定用のメールを送信する"
                style={{ marginTop: 20, minWidth: 400 }}
                onPress={async () => {
                  await requestPasswordReset();
                  history.push('/forgot-password-thanks');
                }}
              />
            </Form>
            <LinkContainer>
              <Link path={'/signin'}>
                <Typography
                  variant={TypographyType.MenuItemActive}
                  style={{ color: themeContext.colors.link }}>
                  ログインする
                </Typography>
              </Link>
            </LinkContainer>
          </RightContainerInner>
        </RightContainer>
      </Container>
    </>
  );
};

export default React.memo(ForgotPasswordPage);
