import React, { useContext, useState } from 'react';
import moment from 'moment-timezone';
import styled, { ThemeContext } from 'styled-components/native';
import { View, Text, TouchableOpacity } from 'react-native';
import { IStyleTheme, IThemePart } from '../../../../../theme';
import Calendar from '../../../../share/organisms/calenar';
import Icon from '../../../../../presentational/atoms/icon';
import Typography, { TypographyType } from '../../../../../presentational/atoms/typography';
import EditableText from '../../../../../presentational/atoms/editable-text';
import RightIcon from '../../../../../presentational/molecules/image-icon/right';
import LeftIcon from '../../../../../presentational/molecules/image-icon/left';
import { LoginUserContext } from '../../../../../../modules/auth/LoginUserContext';

const Container = styled.View`
  height: calc(100vh - 180px);
`;

const Header = styled.View`
  display: flex;
  height: 35px;
  background-color: ${(props: IStyleTheme) => props.theme.colors.header};
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  align-item: center;
  justify-content: center;
  text-align: center;
  transition: all 0.2s;
`;

interface ICalendarWindowHeaderProps {
  date: moment.Moment;
  setDate: (value: moment.Moment) => void;
  snapMinutes: number;
  setSnapMinutes: (value: number) => void;
}

const CalendarWindowHeader = (props: ICalendarWindowHeaderProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);

  return (
    <>
      <TouchableOpacity
        style={{
          position: 'absolute',
          left: 10,
          top: 3,
          backgroundColor: 'transparent',
          borderColor: '#FFFFFF',
          borderWidth: 1,
          borderRadius: 3,
        }}
        onPress={() => props.setDate(moment())}>
        <Typography
          variant={TypographyType.Normal}
          style={{ color: '#FFFFFF', fontSize: 14, paddingHorizontal: 10 }}>
          今日
        </Typography>
      </TouchableOpacity>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: 10,
        }}>
        <LeftIcon
          size={30}
          reverse={true}
          onPress={() => props.setDate(moment(props.date).add(-1, 'day'))}
        />
        <Typography variant={TypographyType.Normal} style={{ color: '#FFFFFF' }}>
          {props.date.format('ll')}
        </Typography>
        <RightIcon
          size={30}
          reverse={true}
          onPress={() => {
            props.setDate(moment(props.date).add(1, 'day'));
          }}
        />
      </View>
      <View
        style={{
          flexDirection: 'column',
          position: 'absolute',
          right: 10,
          top: 3,
          backgroundColor: 'transparent',
          borderColor: '#FFFFFF',
          borderWidth: 1,
          borderRadius: 3,
        }}>
        <Typography
          variant={TypographyType.Description}
          style={{
            fontSize: 8,
            textAlign: 'center',
            position: 'absolute',
            color: '#FFFFFF',
            left: 5,
            top: 5,
          }}>
          スナップ
        </Typography>
        <EditableText
          style={{ minWidth: 110 }}
          textStyle={{ textAlign: 'right', fontSize: 12, color: '#FFFFFF', paddingRight: 5 }}
          value={props.snapMinutes}
          type={'picker'}
          isSearchable={false}
          pickerItems={[
            {
              label: '1分単位',
              value: 1,
            },
            {
              label: '5分単位',
              value: 5,
            },
            {
              label: '15分単位',
              value: 15,
            },
            {
              label: '30分単位',
              value: 30,
            },
          ]}
          onChange={(value) => {
            props.setSnapMinutes(Number(value));
          }}
        />
      </View>
    </>
  );
};
const MemorizedCalendarWindowHeader = React.memo(CalendarWindowHeader);

interface RightSideBarCalendar {}

const RightSideBarCalendar = (props: RightSideBarCalendar) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [date, setDate] = useState(moment().startOf('day'));
  const [snapMinutes, setSnapMinutes] = useState(15);

  return (
    <View style={{ height: 'calc(100vh - 140px)' }}>
      <Header>
        <Typography variant={TypographyType.SubTitle} style={{ color: '#FFFFFF' }}>
          <MemorizedCalendarWindowHeader
            date={date}
            setDate={setDate}
            snapMinutes={snapMinutes}
            setSnapMinutes={setSnapMinutes}
          />
        </Typography>
      </Header>
      <Calendar
        memberId={loginUser!.id}
        startDateTime={moment(date).startOf('day')}
        endDateTime={moment(date).endOf('day')}
        showHeader={false}
        isRightSideBar={true}
        horizontalDragEnable={false}
        snapMinutes={snapMinutes}
      />
    </View>
  );
};

export default React.memo(RightSideBarCalendar);
