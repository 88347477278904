import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment-timezone';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import { IThemePart } from '../../../../../theme';
import {
  MeteredRateContract,
  ProjectContractDocument,
  Team,
  useCreateMeteredRateContractMutation,
  useTeamMembersQuery,
} from '../../../../../../graphql/api/API';
import Form from '../../../../../presentational/atoms/form';
import Input, { ErrorMessage } from '../../../../../presentational/atoms/input';
import { View } from 'react-native';
import Button from '../../../../../presentational/atoms/button';
import TaskInfoElement from '../../task-detail/task-info-element';
import { ProjectContract } from '../../../../../../graphql/api/API';
import Modal from '../../../../../presentational/molecules/modal';

const Container = styled.View`
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  align-items: flex-start;
`;

const Row = styled.View`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: space-between;
`;

interface IProps {
  showModal: boolean;
  team: Team;
  projectContract: ProjectContract;
  onPressYes: (meteredRateContract: MeteredRateContract) => void;
  onCloseModal: () => void;
}

const RegisterMeteredRateContractModal = (props: IProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [memberId, setMemberId] = useState<string | null>(null);
  const [meterdRateAllMemberHourlyUnitPrice, setMeterdRateAllMemberHourlyUnitPrice] = useState(0);
  const fetchTeamMembers = useTeamMembersQuery({
    variables: {
      teamId: props.team.id!,
    },
    fetchPolicy: 'network-only',
  });
  const [createData] = useCreateMeteredRateContractMutation({
    variables: {
      input: {
        hourlyUnitPrice: meterdRateAllMemberHourlyUnitPrice,
        projectContractId: props.projectContract.id!,
        memberId: memberId,
      },
    },
    refetchQueries: [
      {
        query: ProjectContractDocument,
        variables: {
          id: props.projectContract.id!,
        },
      },
    ],
  });

  useEffect(() => {
    setMeterdRateAllMemberHourlyUnitPrice(0);
    setMemberId(null);
  }, [props.showModal]);

  if (fetchTeamMembers.loading || fetchTeamMembers.data?.teamMembers === undefined) {
    return <></>;
  }

  return (
    <Modal
      title={'メンバーの単価の登録'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <Container>
        <Form style={{ minWidth: 500 }}>
          <Row style={{ flexDirection: 'column' }}>
            <TaskInfoElement
              title={'メンバー'}
              style={{ flex: 1 }}
              titleContainerStyle={{ width: 135 }}>
              <Input
                name={'member'}
                type={'picker'}
                isSearchable={true}
                pickerItems={(fetchTeamMembers?.data?.teamMembers ?? [])
                  .filter(
                    (member) =>
                      !props.projectContract.membersMeteredRateContract
                        ?.map((m) => m!.member!.memberId!)
                        .includes(member!.id!)
                  )
                  .map((member) => {
                    return {
                      label: member!.name || '-',
                      value: member!.id,
                    };
                  })}
                onChange={(value) => setMemberId(value)}
                validate={{
                  required: {
                    value: true,
                    message: 'メンバーを選択してください',
                  },
                }}
              />
            </TaskInfoElement>
          </Row>
          <Row style={{ flexDirection: 'column' }}>
            <TaskInfoElement
              title={'時間単価（税抜）'}
              style={{ flex: 1 }}
              titleContainerStyle={{ width: 135 }}>
              <Input
                name={'meterdRateAllMemberHourlyUnitPrice'}
                placeholder={'値を入力してください'}
                ghostSuffix="円"
                onChange={(value) => {
                  if (value.trim().length === 0 || isNaN(value as any)) {
                    setMeterdRateAllMemberHourlyUnitPrice(0);
                  } else {
                    setMeterdRateAllMemberHourlyUnitPrice(Number(value));
                  }
                }}
                validate={{
                  required: {
                    value: true,
                    message: '値を入力してください',
                  },
                  pattern: {
                    value: /^[0-9]+$/,
                    message: '半角数字を入力してください。',
                  },
                  min: {
                    value: 0,
                    message: '0以上の金額を入力してください',
                  },
                }}
              />
            </TaskInfoElement>
            {(meterdRateAllMemberHourlyUnitPrice === null ||
              meterdRateAllMemberHourlyUnitPrice === undefined) && (
              <ErrorMessage style={{ marginLeft: 140 }}>値を入力してください</ErrorMessage>
            )}
          </Row>
          <View
            style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 20 }}>
            <Button
              text={'作成する'}
              completeText={'作成しました'}
              style={{
                minWidth: 100,
                marginRight: 10,
              }}
              onPress={async () => {
                const result = await createData();
                props.onPressYes(result.data!.createMeteredRateContract!);
              }}
            />
            <Button
              text={'キャンセル'}
              style={{
                minWidth: 100,
                marginRight: 10,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.primary }}
              disableValidate={true}
              onPress={async () => {
                props.onCloseModal();
              }}
            />
          </View>
        </Form>
      </Container>
    </Modal>
  );
};

export default RegisterMeteredRateContractModal;
