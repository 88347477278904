import React, { useContext, useEffect, useState } from 'react';
import styled, { ThemeContext } from 'styled-components/native';
import { Image } from 'react-native';
import { useScreenDimensions } from 'use-dimensions';
import { useHistory } from 'react-router';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import Button from '../../../../presentational/atoms/button';
import Separator from '../../../../presentational/atoms/separator';
import Form from '../../../../presentational/atoms/form';
import Input from '../../../../presentational/atoms/input';
import Link from '../../../../presentational/atoms/link';
import { IThemePart } from '../../../../theme';
import logoLargeBlack from '../../../../../base64Images/logo/logo-large-black';
import registerImage from '../../../../../base64Images/register/register';
import googleSignin from '../../../../../base64Images/login/google-signin';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import GoogleLogin from 'react-google-login';
import Constants from 'expo-constants';
import {
  useMailAddressAvailableLazyQuery,
  useParseGoogleAuthCodeMutation,
} from '../../../../../graphql/api/API';
import LoginUtil from '../../../../../modules/auth/LoginUtil';

const Header = styled.View`
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  height: 100px;
  padding-left: 20px;
  padding-top: 20px;
`;

const Container = styled.View`
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  height: calc(100vh - 100px);
`;

const LeftContainer = styled.View`
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 0 50px;
`;

const RightContainer = styled.View`
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 0 50px;
`;

const RightContainerInner = styled.View`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LinkContainer = styled.View`
  margin-top: 1.5rem;
`;

const DescriptionContainerTop = styled.View`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
`;

const DescriptionContainerBottom = styled.View`
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
`;

const SignupAddOrganizationPage = () => {
  const [mailAddress, setMailAddress] = useState('');
  const [password, setPassword] = useState('');
  const { height, width } = useScreenDimensions();
  const history = useHistory();
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [parseGoogleAuthCode, _] = useParseGoogleAuthCodeMutation({
    onError: (e) => {
      if (e.graphQLErrors.find((ge) => ge.extensions?.code === 'google-account-already-used')) {
        window.alert('このGoogleアカウントは、すでに他のユーザーで登録されています');
        window.location.reload();
      }
      if (e.graphQLErrors.find((ge) => ge.extensions?.code === 'mail-address-already-used')) {
        window.alert('このメールアドレスは、すでに他のユーザーで登録されています');
        window.location.reload();
      }
    },
  });
  const [mailAddressAvailableQuery, { data: mailAddressAvailableResult }] =
    useMailAddressAvailableLazyQuery({
      fetchPolicy: 'network-only',
    });

  return (
    <>
      <Header>
        <Image
          source={{ uri: logoLargeBlack }}
          resizeMode="contain"
          style={{
            width: 300,
            height: 100,
          }}
        />
      </Header>
      <Container>
        <LeftContainer>
          <Image
            source={{ uri: registerImage }}
            resizeMode="contain"
            style={{
              width: Math.max(width / 4, 500),
              height: Math.max(width / 4, 500),
            }}
          />
        </LeftContainer>
        <RightContainer>
          <RightContainerInner>
            <Typography variant={TypographyType.Title} style={{ marginVertical: 10 }}>
              新しくアカウントを作成する
            </Typography>
            <DescriptionContainerTop>
              <Typography variant={TypographyType.Description}>Time Designerの</Typography>
              <Link path="https://timedesigner.com/terms.html" isExternalLink={true}>
                <Typography
                  variant={TypographyType.Description}
                  style={{ color: themeContext.colors.link, fontWeight: '600' }}>
                  利用規約
                </Typography>
              </Link>
              <Typography variant={TypographyType.Description}>・</Typography>
              <Link path="https://timedesigner.com/privacy-policy.html" isExternalLink={true}>
                <Typography
                  variant={TypographyType.Description}
                  style={{ color: themeContext.colors.link, fontWeight: '600' }}>
                  プライバシーポリシー
                </Typography>
              </Link>
              <Typography variant={TypographyType.Description}>に</Typography>
            </DescriptionContainerTop>
            <DescriptionContainerBottom>
              <Typography variant={TypographyType.Description}>
                同意してアカウントを作成します。
              </Typography>
            </DescriptionContainerBottom>
            <GoogleLogin
              clientId={Constants.manifest!.extra!.googleClientId}
              buttonText="Googleアカウントで会員登録する"
              scope={
                'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/admin.directory.user.readonly https://www.googleapis.com/auth/admin.directory.resource.calendar.readonly'
              }
              responseType={'code'}
              accessType={'offline'}
              prompt={'consent'}
              onSuccess={async (response) => {
                const result = await parseGoogleAuthCode({
                  variables: {
                    code: response.code!,
                  },
                });
                if (!result.data?.parseGoogleAuthCode) {
                  return;
                }
                if (result.data!.parseGoogleAuthCode!.registerd) {
                  // すでに会員登録済みの場合には、ログインする
                  await LoginUtil.saveToken(result.data!.parseGoogleAuthCode!.token!);
                  const user = await LoginUtil.getLoginUser();
                  setLoginUser(user);
                  return;
                }
                history.push({
                  pathname: '/signup/select-plan',
                  state: {
                    mailAddress: result.data!.parseGoogleAuthCode!.mailAddress!,
                    googleAuthCode: response.code!,
                    name: result.data!.parseGoogleAuthCode!.name!,
                    profileImageUrl: result.data!.parseGoogleAuthCode!.profileImageUrl,
                  },
                });
              }}
              onFailure={(error) => {}}
              cookiePolicy={'single_host_origin'}
            />
            <Separator text={'または'} outerStyle={{ marginVertical: 30 }} />
            <Form style={{ marginBottom: 30 }}>
              <Input
                name={'mailAddress'}
                label={'メールアドレス'}
                validate={{
                  required: {
                    value: true,
                    message: 'メールアドレスを入力してください',
                  },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: '正しいメールアドレスを入力してください',
                  },
                }}
                onChange={(value: string) => setMailAddress(value)}
                onBlur={async (value: string) => {
                  await mailAddressAvailableQuery({
                    variables: {
                      mailAddress: value,
                    },
                  });
                }}
                additionalErrorMessage={
                  mailAddressAvailableResult?.mailAddressAvailable === false
                    ? 'すでに使用されているメールアドレスです。'
                    : null
                }
              />
              <Input
                name={'password'}
                type={'password'}
                label={'パスワード'}
                validate={{
                  required: {
                    value: true,
                    message: 'パスワードを入力してください',
                  },
                }}
                onChange={(value: string) => setPassword(value)}
              />
              <Button
                text="メールアドレスで登録する"
                style={{ marginTop: 20, minWidth: 300 }}
                onPress={async () => {
                  if (mailAddressAvailableResult?.mailAddressAvailable === false) {
                    return;
                  }
                  history.push({
                    pathname: '/signup/select-plan',
                    state: {
                      mailAddress: mailAddress,
                      password: password,
                    },
                  });
                }}
              />
            </Form>
            <LinkContainer>
              <Link path={'/signin-other/'}>
                <Typography
                  variant={TypographyType.MenuItemActive}
                  style={{ color: themeContext.colors.primary, fontSize: 20 }}>
                  その他の登録済みの組織へログインする
                </Typography>
              </Link>
            </LinkContainer>
          </RightContainerInner>
        </RightContainer>
      </Container>
    </>
  );
};

export default React.memo(SignupAddOrganizationPage);
