import React, { useContext } from 'react';
import { View } from 'react-native';
import { ThemeContext } from 'styled-components/native';
import Button from '../../../../presentational/atoms/button';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import Modal from '../../../../presentational/molecules/modal';
import { IThemePart } from '../../../../theme';

interface IErrorMessageModal {
  title?: string;
  message: string;
  showModal: boolean;
  onCloseModal: () => void;
}

const ErrorMessageModal = (props: IErrorMessageModal) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  return (
    <Modal
      title={props.title || 'エラーが発生しました'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <View style={{ flexDirection: 'column' }}>
        <View style={{ marginTop: 10 }}>
          <Typography
            variant={TypographyType.Normal}
            style={{ textAlign: 'center' }}
            ellipsis={false}>
            {props.message}
          </Typography>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
          <Button
            text={'閉じる'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              borderColor: themeContext.colors.error,
              borderRadius: 3,
              borderWidth: 1,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.error }}
            onPress={() => {
              props.onCloseModal();
            }}
          />
        </View>
      </View>
    </Modal>
  );
};

export default React.memo(ErrorMessageModal);
