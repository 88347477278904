import React, { useContext, useEffect, useRef, useState } from 'react';
import { View, Image } from 'react-native';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../../../theme';
import Typography, { TypographyType } from '../../../../../presentational/atoms/typography';
import ProjectTemplateList from '../../../organisms/project-template-list';
import HorizontalMenu from '../../../organisms/horizontal-menu';
import TaskList from '../../../organisms/project-template-task-list';
import ProjectBoard from './board';
import { Route, Switch, useHistory, useLocation, useParams } from 'react-router';
import { LoginUserContext } from '../../../../../../modules/auth/LoginUserContext';
import {
  Organization,
  Plan,
  useOrganizationQuery,
  useProjectTemplateQuery,
  useProjectTemplatesQuery,
  useProjectTemplateTaskStatusesQuery,
} from '../../../../../../graphql/api/API';
import noData01 from '../../../../../../base64Images/no-data/no-data-1';
import noData03 from '../../../../../../base64Images/no-data/no-data-3';
import useDimensions from 'use-dimensions';
import EditIcon from '../../../../../presentational/molecules/image-icon/edit';
import PlanNotAllowedView from '../../../organisms/plan-not-allowed-view';
import ResizableColumn from '../../../organisms/resizable-column';
import TimeUtil from '../../../../../../util/TimeUtil';

const Container = styled.View`
  height: calc(100vh - 57px);
  display: flex;
  flex-direction: row;
`;

const MainAreaContainer = styled.View`
  flex: 1;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.4s;
  marginright: 50px;
  z-index: 1;
`;
// 本当はoverflow-xはvisibleにしたいのだが、CSSの仕様で、overflow-yを指定した場合には、xの挙動もそちらで上書きされるため実現できない

const MainAreaHeader = styled.View`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
  padding: 5px 10px;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.4s;
  z-index: 2;
`;

const MainAreaHeaderTop = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  z-index: 1;
  width: 100%;
`;

const MainAreaHeaderBottom = styled.View`
  display: flex;
  width: 100%;
  height: 40px;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0.2rem 0;
`;

interface IProjectContainerInnerProps {
  organization: Organization;
  projectId: string;
}

const ProjectContainerInner = React.memo((props: IProjectContainerInnerProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const [showModal, setShowModal] = useState(false);
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [showIncompleteModal, setShowIncompleteModal] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const menuRef = useRef();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    setDeleted(false);
  }, [props.projectId]);

  const { loading, data, error } = useProjectTemplateQuery({
    variables: {
      id: props.projectId,
    },
    fetchPolicy: 'network-only',
    skip: deleted || props.projectId === 'task',
  });

  const { loading: taskStatusLoading, data: taskStatusData } = useProjectTemplateTaskStatusesQuery({
    variables: {
      projectTemplateId: props.projectId,
    },
    fetchPolicy: 'network-only',
    skip: props.projectId === 'task',
  });

  const clickDocument = (e: any) => {
    if ((menuRef?.current as any)?.contains(e.target)) {
      (menuRef?.current as any)?.click();
      return;
    }
    setOpenMenu(false);
  };
  useEffect(() => {
    window.addEventListener('click', clickDocument);
    return () => {
      window.removeEventListener('click', clickDocument);
    };
  }, [clickDocument]);

  if (loading || !data?.projectTemplate || taskStatusLoading) {
    return <></>;
  }

  return (
    <>
      <MainAreaHeader>
        <MainAreaHeaderTop>
          <View
            style={{
              flexDirection: 'row',
            }}>
            <View style={{ flexDirection: 'column' }}>
              {data!.projectTemplate!.client && (
                <Typography
                  variant={TypographyType.Description}
                  style={{ marginLeft: 3, marginRight: 10, fontSize: 11 }}
                  ellipsis={true}>
                  {data!.projectTemplate!.client!.name}
                </Typography>
              )}
              <Typography
                variant={TypographyType.SubTitle}
                style={{ marginLeft: 3, marginRight: 10 }}
                ellipsis={true}>
                {data!.projectTemplate!.name}
              </Typography>
            </View>
            <EditIcon
              size={22}
              containerStyle={{ marginRight: 8, marginLeft: 5 }}
              onPress={() => {
                history.push(
                  location.pathname +
                    `?projectTemplateDetailId=${props.projectId}&projectDetailMode=standard`
                );
              }}
            />
          </View>
          <Typography variant={TypographyType.Description} style={{ textAlign: 'center' }}>
            {`見積もり時間: ${
              data?.projectTemplate.estimateTimeSec
                ? TimeUtil.formatSecToShortTime(data?.projectTemplate.estimateTimeSec ?? 0)
                : '-'
            }`}
          </Typography>
        </MainAreaHeaderTop>
        <MainAreaHeaderBottom>
          <HorizontalMenu
            style={{ marginLeft: 10 }}
            menus={[
              {
                title: 'リスト',
                path: `/app/${loginUser!.organizationId}/project-templates/${
                  data!.projectTemplate!.id
                }/list/`,
              },
              {
                title: 'ボード',
                path: `/app/${loginUser!.organizationId}/project-templates/${
                  data!.projectTemplate!.id
                }/board/`,
              },
            ]}
          />
        </MainAreaHeaderBottom>
      </MainAreaHeader>
      <Switch>
        <Route
          path={'/app/:organizationId/project-templates/:projectId/list/'}
          component={TaskList}
        />
        <Route
          path={'/app/:organizationId/project-templates/:projectId/board/'}
          component={ProjectBoard}
        />
      </Switch>
    </>
  );
});

const ProjectContainer = React.memo(() => {
  const params = useParams();
  const [loginUser, _] = useContext(LoginUserContext);
  const { loading, data } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId,
    },
  });

  if (loading) {
    return <></>;
  }

  return (
    <ProjectContainerInner
      organization={data!.organization!}
      projectId={(params as any).projectId}
    />
  );
});

const ProjectTemplatePage = () => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, _] = useContext(LoginUserContext);
  const [notAllowedPlan, setNotAllowedPlan] = useState(false);
  const { window } = useDimensions();
  const { loading, data } = useProjectTemplatesQuery({
    fetchPolicy: 'network-only',
  });
  const { loading: organizationLoading, data: organizationData } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId,
    },
    fetchPolicy: 'network-only',
  });

  // Basicプランの場合にはタグ管理できないようにする
  useEffect(() => {
    setTimeout(() => {
      if (organizationData?.organization?.plan.code === Plan.Basic) {
        setNotAllowedPlan(true);
      }
    }, 1000);
  }, [organizationData]);

  if (loading || organizationLoading) {
    return <></>;
  }

  return (
    <PlanNotAllowedView isNotAllowedPlan={notAllowedPlan} style={{ height: 'calc(100vh)' }}>
      <Container>
        <ResizableColumn
          cookieName="PROJECT_TEMPLATE_LIST_WIDTH"
          renderChild={(width) => {
            return <ProjectTemplateList />;
          }}
        />
        <MainAreaContainer>
          <Switch>
            <Route path="/app/:organizationId/project-templates/:projectId/">
              <ProjectContainer />
            </Route>
            <Route>
              <View
                style={{
                  height: 'calc(100vh - 57px)',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                {data?.projectTemplates && (data?.projectTemplates || []).length > 0 ? (
                  <>
                    <Image
                      source={{ uri: noData01 }}
                      resizeMode="contain"
                      style={{
                        width: Math.min(window.width / 4, 300),
                        height: 200,
                        opacity: 0.7,
                      }}
                    />
                    <Typography
                      variant={TypographyType.Normal}
                      style={{ color: themeContext.colors.description }}>
                      テンプレートを選択してください
                    </Typography>
                  </>
                ) : (
                  <>
                    <Image
                      source={{ uri: noData03 }}
                      resizeMode="contain"
                      style={{
                        width: Math.min(window.width / 4, 300),
                        height: 200,
                        opacity: 0.7,
                      }}
                    />
                    <Typography
                      variant={TypographyType.Normal}
                      style={{ color: themeContext.colors.description }}>
                      テンプレートを追加してください
                    </Typography>
                  </>
                )}
              </View>
            </Route>
          </Switch>
        </MainAreaContainer>
      </Container>
    </PlanNotAllowedView>
  );
};

export default React.memo(ProjectTemplatePage);
