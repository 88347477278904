import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment-timezone';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import { IThemePart } from '../../../../../theme';
import {
  IsExistsDuplicateTermProjectContractDocument,
  IsExistsDuplicateTermProjectContractQuery,
  IsExistsDuplicateTermProjectContractQueryVariables,
  Organization,
  Project,
  ProjectContractDocument,
  ProjectContractRoundTimeType,
  ProjectContractUnitPriceType,
  ProjectDocument,
  TimeZone,
  useTimeZonesQuery,
  useUpdateProjectContractMutation,
} from '../../../../../../graphql/api/API';
import Form from '../../../../../presentational/atoms/form';
import Input, { ErrorMessage } from '../../../../../presentational/atoms/input';
import { View } from 'react-native';
import Button from '../../../../../presentational/atoms/button';
import TaskInfoElement from '../../task-detail/task-info-element';
import { ProjectContract, ProjectContractTermType } from '../../../../../../graphql/api/API';
import DateTimePicker from '../../../../../presentational/atoms/date-time-picker/index.web';
import Modal from '../../../../../presentational/molecules/modal';
import EditableText from '../../../../../presentational/atoms/editable-text';
import OrganizationUtil from '../../../../../../util/OrganizationUtil';
import Typography, { TypographyType } from '../../../../../presentational/atoms/typography';
import { useLazyQueryPromise } from '../../../../../../graphql/extention/useLazyQueryPromise';
import ErrorMessageModal from '../../error-message-modal';

const Container = styled.View`
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  align-items: flex-start;
`;

const Row = styled.View`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: space-between;
`;

interface IProps {
  showModal: boolean;
  projectContract: ProjectContract;
  timeZones: TimeZone[];
  existsProjectContracts: ProjectContract[];
  onPressYes: (projectContract: ProjectContract) => void;
  onCloseModal: () => void;
}

const UpdateProjectContractTermModal = (props: IProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [termType, setTermType] = useState<ProjectContractTermType>(props.projectContract.termType);
  const [timeZone, setTimeZone] = useState<TimeZone>(props.projectContract.timeZone);
  const [year, setYear] = useState(
    props.projectContract.startDateTime
      ? moment(props.projectContract.startDateTime).get('year')
      : moment().get('year')
  );
  const [month, setMonth] = useState(
    props.projectContract.endDateTime
      ? moment(props.projectContract.endDateTime).get('month') + 1
      : moment().get('month') + 1
  );
  const [showDuplicateError, setShowDuplicateError] = useState(false)
  const [updateProjectContract] = useUpdateProjectContractMutation({
    variables: {
      id: props.projectContract!.id!,
      input: {
        contractType: props.projectContract.contractType,
        contractTermType: termType,
        unitPriceType: props.projectContract.unitPriceType,
        timeZoneId: timeZone?.id,
        roundTimeType: props.projectContract.roundTimeType,
        fixSalesAmountWithoutTax: props.projectContract.fixSalesAmountWithoutTax,
        salesRoundMinutes: props.projectContract.salesRoundMinutes,
        taxRate: props.projectContract.taxRate,
        costBudgetWithoutTax: props.projectContract.costBudgetWithoutTax,
        year: year,
        month: month,
        versionNo: props.projectContract.versionNo,
      },
    },
    refetchQueries: [
      {
        query: ProjectContractDocument,
        variables: {
          id: props.projectContract.id!,
        },
      },
    ],
  });

  const checkExistDuplicateTermProjectContract = useLazyQueryPromise<IsExistsDuplicateTermProjectContractQuery, IsExistsDuplicateTermProjectContractQueryVariables>(
    IsExistsDuplicateTermProjectContractDocument
  );


  return (
    <Modal
      title={'契約期間の変更'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <Container>
        <Form style={{ minWidth: 400 }}>
          <Row>
            <TaskInfoElement
              title={'契約期間'}
              style={{ flex: 1 }}
              contentContainerStyle={{ flexDirection: 'row', flex: 1 }}
              titleContainerStyle={{ minWidth: 90 }}>
              <View
                style={{
                  width: termType === ProjectContractTermType.Monthly ? 220 : undefined,
                  flex: termType === ProjectContractTermType.Monthly ? undefined : 1,
                }}>
                <Input
                  name={'termType'}
                  placeholder={'売上計算期間を選択する'}
                  type={'picker'}
                  value={termType}
                  initialValue={props.projectContract.termType}
                  pickerItems={[
                    {
                      label: '契約期間を指定しない',
                      value: ProjectContractTermType.Total,
                    },
                    {
                      label: '契約期間を指定する',
                      value: ProjectContractTermType.Monthly,
                    },
                  ]}
                  onChange={(value) => setTermType(value as ProjectContractTermType)}
                  validate={{
                    validate: (value: string) => {
                      if (
                        value === ProjectContractTermType.Total &&
                        props.existsProjectContracts
                          .filter((data) => data.id !== props.projectContract.id)
                          .filter((data) => data.termType === ProjectContractTermType.Monthly)
                          .length > 0
                      ) {
                        return `他に期間を指定した契約情報が登録されているため、${'\n'}期間を指定しない契約情報は登録できません${'\n'}(契約期間が重複するため)`;
                      }
                      if (
                        value === ProjectContractTermType.Monthly &&
                        props.existsProjectContracts
                          .filter((data) => data.id !== props.projectContract.id)
                          .filter((data) => data.termType === ProjectContractTermType.Total)
                          .length > 0
                      ) {
                        return `他に期間指定しない契約情報が登録されているため、${'\n'}期間を指定する契約情報は登録できません${'\n'}(契約期間が重複するため)`;
                      }
                      return true;
                    },
                  }}
                />
              </View>
              {termType === ProjectContractTermType.Monthly && (
                <>
                  <View style={{ width: 120, marginLeft: 10 }}>
                    <Input
                      name={'year'}
                      type={'picker'}
                      initialValue={props.projectContract.year ?? moment().get('year')}
                      isSearchable
                      pickerItems={[...Array(4)].map((_, i) => {
                        const year = moment().get('year') - 1 + i;
                        return {
                          label: `${year}年`,
                          value: year,
                        };
                      })}
                      onChange={(value) => {
                        setYear(Number(value));
                      }}
                    />
                  </View>
                  <View style={{ width: 120, marginLeft: 10 }}>
                    <Input
                      name={'month'}
                      type={'picker'}
                      initialValue={props.projectContract.month ?? moment().get('month') + 1}
                      isSearchable
                      pickerItems={[...Array(12)].map((_, i) => {
                        const month = 1 + i;
                        return {
                          label: `${month}月`,
                          value: month,
                        };
                      })}
                      onChange={(value) => {
                        setMonth(Number(value));
                      }}
                    />
                  </View>
                </>
              )}
            </TaskInfoElement>
          </Row>
          {termType === ProjectContractTermType.Monthly && (
            <Row>
              <TaskInfoElement
                title={'タイムゾーン'}
                style={{ flex: 1 }}
                titleContainerStyle={{ minWidth: 90 }}>
                <EditableText
                  value={props.projectContract.timeZone.id}
                  type={'picker'}
                  emptyText={'-'}
                  pickerItems={props.timeZones.map((timeZone) => {
                    return {
                      label: `${timeZone!.name} (${timeZone!.offsetTime})`,
                      value: timeZone!.id,
                    };
                  })}
                  onChange={(value) => {
                    const newTimeZone = props.timeZones.find((timeZone) => timeZone.id === value);
                    setTimeZone(newTimeZone!);
                  }}
                  textStyle={{ fontSize: 16 }}
                  style={{ minWidth: 350 }}
                />
              </TaskInfoElement>
            </Row>
          )}
          <View
            style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 20 }}>
            <Button
              text={'変更する'}
              completeText={'変更しました'}
              style={{
                minWidth: 100,
                marginRight: 10,
              }}
              onPress={async () => {
                const res = await checkExistDuplicateTermProjectContract({
                  input: {
                    projectId: props.projectContract.project!.id!!,
                    timeZoneId: timeZone!.id,
                    excludeProjectContractId: props.projectContract.id!,
                    year: year,
                    month: month,
                  }
                });

                if (res.data.isExistsDuplicateTermProjectContract === true) {
                  setShowDuplicateError(true)
                  return;
                }

                const result = await updateProjectContract();
                props.onPressYes(result.data!.updateProjectContract!);
              }}
            />
            <Button
              text={'キャンセル'}
              style={{
                minWidth: 100,
                marginRight: 10,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.primary }}
              disableValidate={true}
              onPress={async () => {
                props.onCloseModal();
              }}
            />
          </View>
        </Form>
      </Container>
      <ErrorMessageModal
        showModal={showDuplicateError}
        title={'期間が重複した契約が他にあるため、登録できません'}
        message={`期間を選択し直してください`}
        onCloseModal={() => setShowDuplicateError(false)}
      />
    </Modal>
  );
};

export default UpdateProjectContractTermModal;
