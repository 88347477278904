import React, { createContext, useContext, useState } from 'react';
import { Text } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../../../theme';
import MessageList from '../../../../web/organisms/message-list';
import Typography, { TypographyType } from '../../../../../presentational/atoms/typography';
import HorizontalMenu from '../../../organisms/horizontal-menu';
import { Route, Switch } from 'react-router';
import { LoginUserContext } from '../../../../../../modules/auth/LoginUserContext';
import MentionList from '../../../organisms/mention-list';
import SelectButton from '../../../../../presentational/molecules/select-button';
import {
  useUnreadAnnouncementCountQuery,
  useUnreadNotificationCountQuery,
} from '../../../../../../graphql/api/API';

const MainAreaHeader = styled.View`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: ${(props: IStyleTheme) => props.theme.colors.baseColor};
  padding: 0.2rem 1rem;
  box-shadow: 0 5px #000;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  transition: all 0.4s;
`;

const MainAreaHeaderTop = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0;
`;

const MainAreaHeaderBottom = styled.View`
  display: flex;
  width: 100%;
  height: 23px;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0.2rem 0;
`;

const FilterElementsContainer = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.2rem 0;
  margin: 0 0.2rem;
`;

const FilterElements = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const AppInboxPage = () => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, _] = useContext(LoginUserContext);
  const [filter, setFilter] = useState<Array<'read' | 'unread'>>(['unread']);

  const { loading, data } = useUnreadAnnouncementCountQuery({
    fetchPolicy: 'network-only',
  });
  const { loading: loadingNotificationCount, data: dataNotificationCount } =
    useUnreadNotificationCountQuery();

  return (
    <>
      <MainAreaHeader>
        <MainAreaHeaderTop>
          <Typography variant={TypographyType.SubTitle} style={{ marginLeft: 15, marginRight: 30 }}>
            メッセージ
          </Typography>
        </MainAreaHeaderTop>
        <MainAreaHeaderBottom>
          <HorizontalMenu
            style={{ marginLeft: 10 }}
            menus={[
              {
                title: 'あなたへの通知',
                path: `/app/${loginUser!.organizationId}/messages/notifications/`,
                rightElement:
                  !loadingNotificationCount &&
                  dataNotificationCount?.unreadNotificationCount !== undefined &&
                  dataNotificationCount?.unreadNotificationCount !== null &&
                  dataNotificationCount!.unreadNotificationCount > 0 ? (
                    <Typography
                      variant={TypographyType.Normal}
                      ellipsis={true}
                      style={{
                        color: '#EFEFEF',
                        fontSize: 14,
                        marginLeft: 3,
                        backgroundColor: '#c45e5e',
                        textAlign: 'center',
                        width: 24,
                        height: 24,
                        borderRadius: 12,
                      }}>
                      {dataNotificationCount!.unreadNotificationCount >= 10
                        ? '+9'
                        : dataNotificationCount!.unreadNotificationCount}
                    </Typography>
                  ) : undefined,
              },
              {
                title: '運営からのお知らせ',
                path: `/app/${loginUser!.organizationId}/messages/announce/`,
                rightElement:
                  !loading &&
                  data?.unreadAnnouncementCount !== undefined &&
                  data?.unreadAnnouncementCount !== null &&
                  data!.unreadAnnouncementCount > 0 ? (
                    <Typography
                      variant={TypographyType.Normal}
                      ellipsis={true}
                      style={{
                        color: '#EFEFEF',
                        fontSize: 14,
                        marginLeft: 3,
                        paddingTop: 1,
                        backgroundColor: themeContext.colors.primary,
                        textAlign: 'center',
                        width: 24,
                        height: 24,
                        borderRadius: 12,
                      }}>
                      {data!.unreadAnnouncementCount >= 10 ? '+9' : data!.unreadAnnouncementCount}
                    </Typography>
                  ) : undefined,
              },
            ]}
          />
          <FilterElementsContainer>
            <Text
              style={{
                fontSize: 7,
                color: themeContext.colors.description,
                textAlign: 'center',
                marginBottom: 2,
              }}>
              絞り込み
            </Text>
            <FilterElements>
              <SelectButton
                multiSelect={true}
                values={filter}
                contents={[
                  {
                    key: 'unread',
                    content: '未読',
                  },
                  {
                    key: 'read',
                    content: '既読',
                  },
                ]}
                onChange={(value) => {
                  setFilter(value);
                }}
              />
            </FilterElements>
          </FilterElementsContainer>
        </MainAreaHeaderBottom>
      </MainAreaHeader>
      <Switch>
        <Route
          path={'/app/:organizationId/messages/notifications/'}
          render={(routeProps) => <MentionList {...routeProps} filter={filter} />}
        />
        <Route
          path={'/app/:organizationId/messages/announce/'}
          render={(routeProps) => <MessageList {...routeProps} filter={filter} />}
        />
      </Switch>
    </>
  );
};

export default React.memo(AppInboxPage);
