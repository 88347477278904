import React, { useContext, useEffect, useRef, useState } from 'react';
import { View, ActivityIndicator, ViewStyle, StyleProp, Platform } from 'react-native';
import { animated, config, useChain } from 'react-spring';
import { useSpring } from 'react-spring/web';
import { IThemePart } from '../../../theme';
import styled, { ThemeContext } from 'styled-components/native';

interface ISpinnerProps {
  loading: boolean;
  noLazy?: boolean;
  lazyMilliSeconds?: number;
  children?: React.ReactNode;
  size?: number | 'small' | 'large';
  containerStyle?: StyleProp<ViewStyle>;
}

const Spinner = (props: ISpinnerProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [lazyLoading, setLazyLoading] = useState(true);
  const [moreLazyLoading, setMoreLazyLoading] = useState(true);
  const springRef = useRef();
  const { opacity }: any = useSpring({
    ref: springRef,
    config: config.default,
    from: { opacity: 1 },
    to: [
      {
        opacity: lazyLoading ? 1 : 0,
      },
    ],
  });
  useChain([springRef as any]);
  const AnimatedView: any = animated(View);

  useEffect(() => {
    if (!lazyLoading && props.loading) {
      setLazyLoading(true);
      setMoreLazyLoading(true);
    }
    if (lazyLoading && !props.loading) {
      setTimeout(() => setLazyLoading(false), props.lazyMilliSeconds || 50);
      setTimeout(
        () => setMoreLazyLoading(false),
        (props.lazyMilliSeconds || 50) + (props.noLazy ? 0 : 300)
      );
    }
  }, [props.loading]);

  return (
    <>
      {props.noLazy
        ? props.loading
        : moreLazyLoading && (
            <AnimatedView
              style={Object.assign(
                {
                  position: 'absolute',
                  height: '100%',
                  width: '100%',
                  backgroundColor: '#FEFEFE',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flex: 1,
                  opacity: opacity,
                  zIndex: 1000,
                },
                Platform.OS === 'web' ? { zIndex: 1000 } : {},
                props.containerStyle
              )}>
              <ActivityIndicator
                size={props.size || 'large'}
                color={themeContext.colors.primary}
                style={Platform.OS === 'web' ? { marginTop: 200 } : {}}
              />
            </AnimatedView>
          )}
      {!props.loading && props.children}
    </>
  );
};

export default Spinner;
