import React, { useContext } from 'react';
import ListMenuApp from '../../../organisms/list-menu-app';
import AppOrganizationAnalyticsGraphPage from './graph';
import OrganizationDataExportPage from './export';
import OrganizationCsvExportPage from './csv-export';
import OrganizationCsvExportSettingsPage from './csv-export-settings';
import { LoginUserContext } from '../../../../../../modules/auth/LoginUserContext';
import { useOrganizationQuery } from '../../../../../../graphql/api/API';
import CustomScrollView from '../../../../../presentational/atoms/custom-scroll-view';
import LoginUtil from '../../../../../../modules/auth/LoginUtil';

const AppOrganizationAnalyticsPage = () => {
  const [loginUser, _] = useContext(LoginUserContext);
  const teamId = LoginUtil.getTeamIdFromURl();
  const { loading, data, error } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId,
    },
  });

  if (loading) {
    return <></>;
  }

  return (
    <CustomScrollView
      style={{
        height: 'calc(100vh - 57px)',
      }}>
      <ListMenuApp
        headerTitle={`組織の時間分析`}
        resizeCookeName="ORGANIZATION_ANALYTICS_LIST_WIDTH"
        routes={[
          {
            name: 'グラフ分析',
            path: `/app/:organizationId/organization-analytics/graph/`,
            component: AppOrganizationAnalyticsGraphPage,
          },
          {
            name: 'Excelファイル出力',
            path: `/app/:organizationId/organization-analytics/export/`,
            component: OrganizationDataExportPage,
          },
          {
            name: 'CSVファイル出力',
            path: `/app/:organizationId/organization-analytics/csv-export/`,
            component: OrganizationCsvExportPage,
          },
          {
            name: 'CSVファイル出力設定',
            path: `/app/:organizationId/organization-analytics/csv-export-settings/`,
            component: OrganizationCsvExportSettingsPage,
          },
        ]}
      />
    </CustomScrollView>
  );
};

export default React.memo(AppOrganizationAnalyticsPage);
