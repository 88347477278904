import React, { useContext, useEffect, useState } from 'react';
import ListMenuApp from '../../../../organisms/list-menu-app';
import { LoginUserContext } from '../../../../../../../modules/auth/LoginUserContext';
import { Plan, useMeQuery, useOrganizationQuery } from '../../../../../../../graphql/api/API';
import CustomScrollView from '../../../../../../presentational/atoms/custom-scroll-view';
import LoginUtil from '../../../../../../../modules/auth/LoginUtil';
import PlanNotAllowedView from '../../../../organisms/plan-not-allowed-view';
import ProjectDataExportPage from './project-export';
import TaskDataExportPage from './task-export';
import ClientDataExportPage from './client-export';
import MemberDataExportPage from './member-export';
import OrganizationUtil from '../../../../../../../util/OrganizationUtil';

const AppOrganizationExportsPage = () => {
  const [loginUser, _] = useContext(LoginUserContext);
  const [notAllowedPlan, setNotAllowedPlan] = useState(false);
  const teamId = LoginUtil.getTeamIdFromURl();
  const { loading, data, error } = useOrganizationQuery({
    variables: {
      id: loginUser!.organizationId,
    },
  });

  const { loading: loadingMe, data: dataMe } = useMeQuery({
    fetchPolicy: 'network-only',
  });

  // Basicプランの場合には使用できないようにする
  useEffect(() => {
    setTimeout(() => {
      if (data?.organization?.plan.code === Plan.Basic) {
        setNotAllowedPlan(true);
      }
    }, 1000);
  }, [data]);

  if (loading || !data?.organization || loadingMe || !dataMe?.me) {
    return <></>;
  }

  const menuList = [
    {
      name: 'プロジェクト',
      path: `/app/:organizationId/export-file/export-projects/`,
      component: ProjectDataExportPage,
    },
    {
      name: 'タスク',
      path: `/app/:organizationId/export-file/export-tasks/`,
      component: TaskDataExportPage,
    },
  ];

  if (dataMe.me.clientManagementRole) {
    menuList.push({
      name: '取引先',
      path: `/app/:organizationId/export-file/export-clients/`,
      component: ClientDataExportPage,
    });
  }
  if (OrganizationUtil.isTeamPlan(data.organization)) {
    if (dataMe.me.memberManagementRole) {
      menuList.push({
        name: 'メンバー',
        path: `/app/:organizationId/export-file/export-members/`,
        component: MemberDataExportPage,
      });
    }
  }

  return (
    <PlanNotAllowedView
      isNotAllowedPlan={notAllowedPlan}
      style={{ height: 'calc(100vh)', width: '100%' }}>
      <CustomScrollView
        style={{
          height: 'calc(100vh - 57px)',
        }}>
        <ListMenuApp
          headerTitle={`データエクスポート`}
          resizeCookeName="ORGANIZATION_EXPORT_FILE_LIST_WIDTH"
          routes={menuList}
        />
      </CustomScrollView>
    </PlanNotAllowedView>
  );
};

export default React.memo(AppOrganizationExportsPage);
