import React, { useContext } from 'react';
import { GestureResponderEvent, Image, Platform } from 'react-native';
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';
//@ts-ignore
import { ThemeContext } from 'styled-components/native';
import iconImage from '../../../../../base64Images/upload';
import reverseImage from '../../../../../base64Images/upload/white';
import { IThemePart } from '../../../../theme';

interface IUploadIconProps {
  size: number;
  onPress?: (event: GestureResponderEvent) => void;
  disabled?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  children?: React.ReactNode;
  reverse?: boolean;
}

const UploadIcon = (props: IUploadIconProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  return (
    <TouchableOpacity
      disabled={props.disabled}
      onPress={props.onPress}
      style={[
        {
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          paddingVertical: 5,
        },
        props.containerStyle,
      ]}>
      <Image
        source={{ uri: props.reverse ? reverseImage : iconImage }}
        resizeMode="contain"
        style={[
          {
            height: props.size,
            width: props.size + 2,
            opacity: props.reverse ? 1 : 0.4,
            shadowOffset: {
              width: 1,
              height: 1,
            },
            shadowOpacity: 0.1,
          } as any,
          Platform.OS === 'web' ? { elevation: 2, cursor: 'pointer' } : {},
        ]}
      />
      {props.children}
    </TouchableOpacity>
  );
};

export default UploadIcon;
