export default class StringUtil {
  private constructor() {}

  public static ellipsis(value: string, size: number): string {
    if (value === null || value === undefined) {
      return '';
    }
    if (value.length <= size) {
      return value;
    }
    return value.substring(0, size) + '…';
  }
}
