import React, { useContext, useState } from 'react';
import { View } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import Button from '../../../../../../presentational/atoms/button';
import Link from '../../../../../../presentational/atoms/link';
import Typography, { TypographyType } from '../../../../../../presentational/atoms/typography';
import type { IThemePart } from '../../../../../../theme';

const Container = styled.View`
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
`;

const SupportManualPage = () => {
  const themeContext: IThemePart = useContext(ThemeContext);
  return (
    <Container>
      <View>
        <View style={{ justifyContent: 'flex-start', alignItems: 'flex-start' }}>
          <Link path={'https://manual.timedesigner.com/v1'} isExternalLink={true}>
            <Button text={'マニュアルページを開く'} />
          </Link>
        </View>
        <Typography
          variant={TypographyType.Description}
          style={{ fontSize: 14, lineHeight: 22, marginTop: 20 }}>
          マニュアルをご覧になる場合は、上記ボタンをクリックしてください。
        </Typography>
      </View>
    </Container>
  );
};

export default SupportManualPage;
