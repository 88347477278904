import React, { useContext, useEffect, useRef, useState } from 'react';
import { View, TouchableOpacity, Image } from 'react-native';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import {
  AttachmentFile,
  CustomerAttributeType,
  Member,
  Plan,
  Project,
  ProjectAttachmentFilesDocument,
  ProjectByKeyDocument,
  ProjectByKeyQuery,
  ProjectByKeyQueryVariables,
  ProjectCustomAttributeMaster,
  useAddProjectCustomAttributeMutation,
  useCreateAttachementFileDownloadUrlMutation,
  useCreateAttachementFileForProjectMutation,
  useCreateAttachementFileUploadUrlMutation,
  useDeleteAttachementFileMutation,
  useMeQuery,
  useOrganizationClientsQuery,
  useOrganizationMembersQuery,
  useProjectAttachmentFilesQuery,
  useProjectCustomAttributeMastersQuery,
  useProjectCustomAttributesByProjectQuery,
  useTeamMembersQuery,
  useTeamQuery,
  ProjectCustomAttribute,
  useUpdateProjectMutation,
  useUpdateProjectCustomAttributeMutation,
  useDeleteProjectCustomAttributeMutation,
  ProjectCustomAttributesByProjectDocument,
  MemberStatus,
} from '../../../../../../graphql/api/API';
import EditableText from '../../../../../presentational/atoms/editable-text';
import Input, { ListValueMap } from '../../../../../presentational/atoms/input';
import Typography, { TypographyType } from '../../../../../presentational/atoms/typography';
import { IStyleTheme, IThemePart } from '../../../../../theme';
import Form from '../../../../../presentational/atoms/form';
import { LoginUserContext } from '../../../../../../modules/auth/LoginUserContext';
import Button from '../../../../../presentational/atoms/button';
import Modal from '../../../../../presentational/molecules/modal';
import ColorUtil from '../../../../../../util/ColorUtil';
import useHover from '../../../../../presentational/atoms/editable-text/use-hover';
import { toast } from 'react-toastify';
import FileIcon from '@crownie/file-icons';
import CustomScrollView from '../../../../../presentational/atoms/custom-scroll-view';
import DownloadUtil from '../../../../../../util/DownloadUtil';
import { MentionData } from '@draft-js-plugins/mention';
import TaskInfoElement from '../../task-detail/task-info-element';
import DeleteIcon from '../../../../../presentational/molecules/image-icon/delete';
import PlusIcon from '../../../../../presentational/molecules/image-icon/plus';
import DownloadIcon from '../../../../../presentational/molecules/image-icon/download';
import { useLazyQueryPromise } from '../../../../../../graphql/extention/useLazyQueryPromise';
import { ColorPicker } from '../../../../../presentational/atoms/color-picker/index.web';
import Avatar from '../../../../../presentational/atoms/avatar';
import moment from 'moment-timezone';
import ErrorMessageModal from '../../error-message-modal';
import CustomAttributeIcon from '../../../../../presentational/molecules/image-icon/custom-attribute';
import when from '../../../../../../lang-extention/When';
import { useHotkeys } from 'react-hotkeys-hook';
import DateTimePicker from '../../../../../presentational/atoms/date-time-picker/index.web';
import VirtualizedFlatList, {
  GlobalDragContextProvider,
} from '../../../../../presentational/atoms/list2/virtualized-flat-list';
import MenuIcon from '../../../../../presentational/molecules/image-icon/menu';

const Conatiner = styled.View`
  display: flex;
  flex-direction: column;
  padding: 10px 15px;G
  align-items: flex-start;
  z-index: 2; //DateTimePickerを使用する際には、外側の要素のz-indexを指定しないと正しく表示されないケースがあるので、ここで指定している
`;

const Row = styled.View`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: space-between;
`;

interface IAttachmentFileItemMenuProps extends IStyleTheme {
  isHover: boolean;
}

const AttachmentFileItemMenu = styled.View<IAttachmentFileItemMenuProps>`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.4s;
  z-index: 2;
  position: absolute;
  right: 5;
  top: 5;
  opacity: ${(props: IAttachmentFileItemMenuProps) => (props.isHover ? '1' : '0')};
`;

interface IProjectDescriptionProps {
  project: Project;
  organizationMembers: Member[];
  me: Member;
  onProjectUpdatePermissionError: () => void;
  descriptionRowRef: any;
}

const ProjectDescription = (props: IProjectDescriptionProps) => {
  const [loginUser, _] = useContext(LoginUserContext);
  const [hoverRef, isHover, setHover] = useHover();
  const [showEditor, setShowEditor] = useState(false);
  const themeContext: IThemePart = useContext(ThemeContext);
  const [description, setDescription] = useState(props.project.description || '');
  const [updateProject, __] = useUpdateProjectMutation();

  const isContentEmpty =
    props.project.description === null ||
    (props.project.description &&
      JSON.parse(props.project.description).blocks &&
      JSON.parse(props.project.description).blocks.length > 0 &&
      JSON.parse(props.project.description).blocks[0]?.text === '');

  return (
    <TaskInfoElement title={'説明'} style={{ flex: 1 }} elementStyle={{ alignItems: 'flex-start' }}>
      <View ref={hoverRef as any}>
        {showEditor ? (
          <>
            <Form style={{ zIndex: 2 }}>
              <Input
                initialValue={props.project.description || ''}
                name={'description'}
                type={'rich-text-editor'}
                zIndexAdjustRef={props.descriptionRowRef}
                showToolBarAlways={true}
                autoFocus={true}
                //@ts-ignore
                mentions={props.organizationMembers!.map((member) => {
                  return {
                    id: member!.id!,
                    name: `${member!.name || '?'}`,
                    avatar: member!.profileImageUrl,
                  } as MentionData;
                })}
                onChange={(value) => {
                  setDescription(value);
                }}
              />
            </Form>
            <View style={{ flexDirection: 'row', marginTop: 10, zIndex: 1 }}>
              <Button
                text="保存"
                style={{ height: 30, marginRight: 5 }}
                textStyle={{ fontSize: 14 }}
                onPress={async () => {
                  if (description === props.project.description) {
                    return;
                  }
                  setDescription(description);
                  setShowEditor(false);
                  setHover(false);
                  updateProject({
                    variables: {
                      id: props.project.id!,
                      input: {
                        name: props.project.name,
                        description: description,
                        clientId: props.project.client?.id,
                        assigneeIds: props.project.assignees.map((info) => info.member.id!) ?? [],
                        key: props.project.key,
                        color: props.project.color,
                        estimateTimeSec: props.project.estimateTimeSec,
                        scheduledStartDateTime: props.project.scheduledStartDateTime,
                        scheduledEndDateTime: props.project.scheduledEndDateTime,
                        versionNo: props.project.versionNo,
                      },
                    },
                  });
                }}
              />
              <Button
                text="キャンセル"
                style={{ height: 30, backgroundColor: 'transparent' }}
                textStyle={{ fontSize: 14, color: themeContext.colors.primary }}
                onPress={() => {
                  setDescription(props.project.description || '');
                  setShowEditor(false);
                  setHover(false);
                }}
              />
            </View>
          </>
        ) : (
          <TouchableOpacity
            onPress={() => {
              if (!props.me.projectUpdatePermissionFlg) {
                props.onProjectUpdatePermissionError();
                return;
              }
              setShowEditor(true);
            }}
            style={{ zIndex: 2 }}>
            <Form>
              <div
                style={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  backgroundColor: themeContext.colors.separator,
                  zIndex: 2,
                  opacity: isHover ? 0.5 : 0,
                  cursor: 'pointer',
                }}
              />
              <View
                style={{ opacity: isContentEmpty ? 0.2 : 1, minHeight: 50, paddingHorizontal: 10 }}>
                <Input
                  initialValue={
                    isContentEmpty
                      ? `{
                  "blocks": [
                    {
                      "key": "dummy",
                      "text": "説明を追加...",
                      "type": "unstyled",
                      "depth": 0,
                      "inlineStyleRanges": [],
                      "entityRanges": [],
                      "data": {}
                    }
                  ],
                  "entityMap": {}
                }
                `
                      : props.project.description!
                  }
                  name={'description'}
                  type={'rich-text-editor'}
                  zIndexAdjustRef={props.descriptionRowRef}
                  showToolBarAlways={false}
                  multiline={true}
                  readonly={true}
                  //@ts-ignore
                  mentions={props.organizationMembers!.map((member) => {
                    return {
                      id: member!.id!,
                      name: `${member!.name || '?'}`,
                      avatar: member!.profileImageUrl,
                    } as MentionData;
                  })}
                />
              </View>
            </Form>
          </TouchableOpacity>
        )}
      </View>
    </TaskInfoElement>
  );
};

interface IAttachmentFileItemProps {
  attachment: AttachmentFile;
}

const AttachmentFileItem = (props: IAttachmentFileItemProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [hoverRef, isHover] = useHover();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [createDownloadUrl, _] = useCreateAttachementFileDownloadUrlMutation({
    variables: {
      input: {
        attachmentFileId: props.attachment.id,
      },
    },
  });

  return (
    <View ref={hoverRef} style={{ flexDirection: 'row' }}>
      <TouchableOpacity
        style={{ flexDirection: 'row', cursor: 'pointer' } as any}
        onPress={async () => {
          const result = await createDownloadUrl();
          if (result.data?.createAttachementFileDownloadUrl) {
            DownloadUtil.forceDownload(
              result.data!.createAttachementFileDownloadUrl.downloadUrl,
              props.attachment.fileName
            );
          }
        }}>
        <View
          style={{
            width: 156,
            height: 125,
            backgroundColor: isHover
              ? themeContext.colors.separator
              : ColorUtil.lignten(themeContext.colors.separator, 3),
            borderColor: themeContext.colors.separator,
            marginVertical: 3,
            marginHorizontal: 5,
            justifyContent: 'center',
            alignItems: 'center',
            shadowOffset: {
              width: 1,
              height: 1,
            },
            shadowOpacity: 0.1,
            elevation: 2,
          }}>
          <View style={{ width: 30, height: 30, marginBottom: 20 }}>
            <FileIcon type={props.attachment.type || props.attachment.fileName} />
          </View>
          <View
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              width: 156,
              height: 36,
              backgroundColor: '#FFFFFF',
              opacity: 0.8,
              position: 'absolute',
              bottom: 0,
            }}>
            <Typography
              variant={TypographyType.Description}
              ellipsis={true}
              style={{
                fontSize: 12,
                width: 130,
                color: themeContext.colors.textColor,
                textAlign: 'center',
              }}>
              {props.attachment.fileName}
            </Typography>
          </View>
          <AttachmentFileItemMenu isHover={isHover}>
            <DownloadIcon
              size={20}
              containerStyle={{
                marginLeft: 5,
                padding: 5,
                backgroundColor: themeContext.colors.description,
              }}
              reverse={true}
              onPress={async () => {
                const result = await createDownloadUrl();
                if (result.data?.createAttachementFileDownloadUrl) {
                  DownloadUtil.forceDownload(
                    result.data!.createAttachementFileDownloadUrl.downloadUrl,
                    props.attachment.fileName
                  );
                }
              }}
            />
            <DeleteIcon
              size={21}
              containerStyle={{ marginLeft: 5, padding: 5 }}
              reverse={true}
              onPress={() => {
                setShowDeleteModal(true);
              }}
            />
          </AttachmentFileItemMenu>
        </View>
      </TouchableOpacity>
      <DeleteAttachmentFileModal
        attachmentFile={props.attachment}
        showModal={showDeleteModal}
        onPressYes={async () => {
          setShowDeleteModal(false);
        }}
        onCloseModal={() => {
          setShowDeleteModal(false);
        }}
      />
    </View>
  );
};

interface IDeleteAttachmentFileModalProps {
  attachmentFile: AttachmentFile;
  showModal: boolean;
  onPressYes: () => Promise<void>;
  onCloseModal: () => void;
}

const DeleteAttachmentFileModal = (props: IDeleteAttachmentFileModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [deleteAttachmentFile, _] = useDeleteAttachementFileMutation({
    variables: {
      id: props.attachmentFile.id,
    },
    update: (cache, result) => {
      cache.evict({
        id: cache.identify(props.attachmentFile),
      });
    },
  });

  return (
    <Modal
      title={'この添付ファイルを削除しますか？'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <View style={{ flexDirection: 'column' }}>
        <View style={{ marginTop: 10 }}>
          <Typography
            variant={TypographyType.Normal}
            style={{ textAlign: 'center' }}
            ellipsis={true}>
            {props.attachmentFile.fileName}
          </Typography>
        </View>
        <View style={{ marginTop: 10 }}>
          <Typography
            variant={TypographyType.Description}
            style={{ textAlign: 'center', color: themeContext.colors.error }}>
            {`この操作はやり直しが出来ません`}
          </Typography>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
          <Button
            text={'キャンセル'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.primary }}
            disableValidate={true}
            onPress={() => {
              props.onCloseModal();
            }}
          />
          <Button
            text={'削除する'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              borderColor: themeContext.colors.error,
              borderRadius: 3,
              borderWidth: 1,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.error }}
            onPress={async () => {
              await deleteAttachmentFile();
              await props.onPressYes();
            }}
          />
        </View>
      </View>
    </Modal>
  );
};

interface IConfirmAllTeamMemberAssignModalProps {
  teamMembers: Member[];
  showModal: boolean;
  onPressYes: () => Promise<void>;
  onCloseModal: () => void;
}
const ConfirmAllTeamMemberAssignModal = (props: IConfirmAllTeamMemberAssignModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);

  return (
    <Modal
      title={'チームメンバー全員を担当者に割り当てますか？'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <View style={{ flexDirection: 'column' }}>
        <GlobalDragContextProvider>
          <VirtualizedFlatList
            style={{ maxHeight: Math.min(props.teamMembers.length * 30, 400) }}
            items={props.teamMembers}
            renderItem={(item, index) => {
              const member = item as Member;
              return (
                <View style={{ marginTop: 10, flexDirection: 'row' }} key={member.id}>
                  <Avatar size={20} name={member?.name || ''} imageUrl={member.profileImageUrl} />
                  <Typography
                    variant={TypographyType.Normal}
                    style={{ marginLeft: 5, fontSize: 18 }}>
                    {member!.name}
                  </Typography>
                </View>
              );
            }}
            getKey={(member: any) => member.id}
            itemHeight={30}
          />
        </GlobalDragContextProvider>
        <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
          <Button
            text={'担当者を割り当てる'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
            }}
            textStyle={{
              backgroundColor: themeContext.colors.primary,
              color: '#FFFFFF',
              padding: 5,
            }}
            disableValidate={true}
            onPress={async () => {
              await props.onPressYes();
            }}
          />
          <Button
            text={'キャンセル'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.primary }}
            disableValidate={true}
            onPress={() => {
              props.onCloseModal();
            }}
          />
        </View>
      </View>
    </Modal>
  );
};

interface IAssigneeSettingsProps {
  project: Project;
  me: Member;
  onProjectUpdatePermissionError: () => void;
}

const AssigneeSettings = (props: IAssigneeSettingsProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, _] = useContext(LoginUserContext);
  const [assigneeMemberIds, setAssigneeMemberIds] = useState([] as string[]);
  const [showMenu, setShowMenu] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const fetchTeamMembers = useTeamMembersQuery({
    variables: {
      teamId: props.project.team.id!,
    },
  });
  const [updateProject, __] = useUpdateProjectMutation();

  useEffect(() => {
    setShowMenu(false);
    setShowConfirmModal(false);
  }, []);

  useEffect(() => {
    setAssigneeMemberIds(props.project.assignees.map((info) => info.member.id!));
  }, [props.project.assignees]);

  if (fetchTeamMembers.loading || fetchTeamMembers.data?.teamMembers === undefined) {
    return <></>;
  }

  return (
    <View>
      <EditableText
        value={assigneeMemberIds}
        type={'multi-picker'}
        isSearchable={true}
        disallowCreateNewPickerItem={false}
        editable={props.me.projectUpdatePermissionFlg}
        onClickWhenNotEditable={() => props.onProjectUpdatePermissionError()}
        renderComponent={() => {
          if (props.project.assignees.length === 0) {
            return <Typography variant={TypographyType.Normal}>-</Typography>;
          }
          return (
            //@ts-ignore
            <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 10 }}>
              {props.project.assignees.map((info) => {
                return (
                  <View style={{ display: 'flex', flexDirection: 'row' }} key={info.member.id}>
                    <Avatar
                      size={28}
                      name={info.member!.name!}
                      imageUrl={info.member!.profileImageUrl}
                    />
                    <Typography variant={TypographyType.Normal} style={{ marginLeft: 5 }}>
                      {info.member!.name}
                    </Typography>
                  </View>
                );
              })}
            </View>
          );
        }}
        pickerItems={
          fetchTeamMembers
            .data!.teamMembers!.filter((member) => member?.memberStatus === MemberStatus.Active)
            .map((member) => {
              return {
                label: member!.name,
                value: member!.id!,
                imageUrl: member!.profileImageUrl,
              } as ListValueMap;
            })
            .sort((a, b) => (a.value === loginUser?.id ? -1 : 1)) // 自分を先頭に表示
        }
        onChange={(items) => {
          setAssigneeMemberIds((items as string[]).filter((value) => value.trim().length > 0));
        }}
        onBlur={(items) => {
          updateProject({
            variables: {
              id: props.project.id!,
              input: {
                name: props.project.name,
                description: props.project.description,
                clientId: props.project.client?.id,
                key: props.project.key,
                assigneeIds: (items as string[]) ?? [],
                color: props.project.color,
                estimateTimeSec: props.project.estimateTimeSec,
                scheduledStartDateTime: props.project.scheduledStartDateTime,
                scheduledEndDateTime: props.project.scheduledEndDateTime,
                versionNo: props.project.versionNo,
              },
            },
          });
        }}
        onPressEnter={() => {
          updateProject({
            variables: {
              id: props.project.id!,
              input: {
                name: props.project.name,
                description: props.project.description,
                clientId: props.project.client?.id,
                key: props.project.key,
                assigneeIds: assigneeMemberIds ?? [],
                color: props.project.color,
                estimateTimeSec: props.project.estimateTimeSec,
                scheduledStartDateTime: props.project.scheduledStartDateTime,
                scheduledEndDateTime: props.project.scheduledEndDateTime,
                versionNo: props.project.versionNo,
              },
            },
          });
        }}
        textStyle={{ fontSize: 18 }}
      />
      <View style={{ marginTop: 15, flexDirection: 'row' }}>
        <TouchableOpacity
          onPress={() => {
            updateProject({
              variables: {
                id: props.project.id!,
                input: {
                  name: props.project.name,
                  description: props.project.description,
                  clientId: props.project.client?.id,
                  key: props.project.key,
                  assigneeIds: assigneeMemberIds
                    .filter((id) => id !== loginUser?.id)
                    .concat(loginUser!.id),
                  color: props.project.color,
                  estimateTimeSec: props.project.estimateTimeSec,
                  scheduledStartDateTime: props.project.scheduledStartDateTime,
                  scheduledEndDateTime: props.project.scheduledEndDateTime,
                  versionNo: props.project.versionNo,
                },
              },
            });
          }}>
          <Typography
            variant={TypographyType.Description}
            style={{ color: themeContext.colors.link }}>
            担当者に自分を追加する
          </Typography>
        </TouchableOpacity>
        {!showMenu ? (
          <MenuIcon size={20} iconStyle={{ marginLeft: 10 }} onPress={() => setShowMenu(true)} />
        ) : (
          <View style={{ flexDirection: 'row', gap: 20, marginLeft: 20 }}>
            <TouchableOpacity
              onPress={() => {
                updateProject({
                  variables: {
                    id: props.project.id!,
                    input: {
                      name: props.project.name,
                      description: props.project.description,
                      clientId: props.project.client?.id,
                      key: props.project.key,
                      assigneeIds: [loginUser!.id],
                      color: props.project.color,
                      estimateTimeSec: props.project.estimateTimeSec,
                      scheduledStartDateTime: props.project.scheduledStartDateTime,
                      scheduledEndDateTime: props.project.scheduledEndDateTime,
                      versionNo: props.project.versionNo,
                    },
                  },
                });
              }}>
              <Typography
                variant={TypographyType.Description}
                style={{ color: themeContext.colors.link }}>
                自分だけに割り当てる
              </Typography>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => setShowConfirmModal(true)}>
              <Typography
                variant={TypographyType.Description}
                style={{ color: themeContext.colors.link }}>
                チーム全員を追加する
              </Typography>
            </TouchableOpacity>
          </View>
        )}
      </View>
      <ConfirmAllTeamMemberAssignModal
        showModal={showConfirmModal}
        teamMembers={(fetchTeamMembers.data.teamMembers ?? []) as Member[]}
        onPressYes={async () => {
          await updateProject({
            variables: {
              id: props.project.id!,
              input: {
                name: props.project.name,
                description: props.project.description,
                clientId: props.project.client?.id,
                key: props.project.key,
                assigneeIds: fetchTeamMembers.data!.teamMembers!.map((member) => member!.id!),
                color: props.project.color,
                estimateTimeSec: props.project.estimateTimeSec,
                scheduledStartDateTime: props.project.scheduledStartDateTime,
                scheduledEndDateTime: props.project.scheduledEndDateTime,
                versionNo: props.project.versionNo,
              },
            },
          });
          setShowConfirmModal(false);
        }}
        onCloseModal={() => setShowConfirmModal(false)}
      />
    </View>
  );
};

interface IAddCustomAttributeModalProps {
  project: Project;
  alreadyExistProjectCustomAttributes: ProjectCustomAttribute[];
  showModal: boolean;
  onPressYes: () => Promise<void>;
  onCloseModal: () => void;
}

const AddCustomAttributeModal = (props: IAddCustomAttributeModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [attribute, setAttribute] = useState<ProjectCustomAttributeMaster | null>(null);
  const [value, setValue] = useState<string | null>(null);
  const { data, loading } = useProjectCustomAttributeMastersQuery({
    fetchPolicy: 'network-only',
  });
  const [addCustomAttribute] = useAddProjectCustomAttributeMutation({
    variables: {
      projectId: props.project.id!,
      input: {
        projectCustomAttributeMasterId: attribute?.id!,
        projectCustomAttributeMasterListItemId:
          attribute?.type === CustomerAttributeType.List ? value : null,
        value: attribute?.type === CustomerAttributeType.List ? null : value!,
      },
    },
    refetchQueries: [
      {
        query: ProjectCustomAttributesByProjectDocument,
        variables: {
          projectId: props.project.id!,
        },
      },
    ],
  });

  useEffect(() => {
    setAttribute(null);
    setValue(null);
  }, [props.showModal]);

  const attributes = data?.projectCustomAttributeMasters?.filter(
    (master) =>
      !props.alreadyExistProjectCustomAttributes.find((exist) => exist.master.id === master!.id)
  );

  if (loading) {
    return <></>;
  }

  return (
    <Modal
      title={'カスタム項目を追加する'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <View style={{ flexDirection: 'column' }}>
        <Form style={{ minWidth: 400, zIndex: 2 }}>
          <Row>
            <TaskInfoElement title={`カスタム項目`} style={{ flex: 1 }}>
              <Input
                name={'attribute'}
                type={'picker'}
                isSearchable={true}
                placeholder={'選択してください'}
                pickerItems={(attributes ?? []).map((master) => {
                  return {
                    label: master!.name,
                    value: master!.id!,
                  };
                })}
                onChange={(value) => {
                  const targets = (attributes || []).filter((master) => master!.id! === value);
                  if (targets && targets.length > 0) {
                    setAttribute(targets[0] ?? null);
                  }
                }}
                validate={{
                  required: {
                    value: true,
                    message: '選択肢を入力してください',
                  },
                }}
              />
            </TaskInfoElement>
          </Row>
          <Row style={{ zIndex: 2 }}>
            <TaskInfoElement title={`値`} style={{ flex: 1 }}>
              {when(attribute?.type) //
                .on(
                  (value) => value === CustomerAttributeType.FreeText,
                  () => (
                    <Input
                      name={'textValue'}
                      placeholder={'値を入力してください'}
                      onChange={(value) => setValue(value)}
                      validate={{
                        required: {
                          value: attribute?.required,
                          message: '値を入力してください',
                        },
                        maxLength: {
                          value: 50,
                          message: '50文字以内で入力してください',
                        },
                      }}
                    />
                  )
                )
                .on(
                  (value) => value === CustomerAttributeType.Number,
                  () => (
                    <Input
                      name={'numberValue'}
                      placeholder={'値を入力してください'}
                      onChange={(value) => setValue(value)}
                      validate={{
                        required: {
                          value: attribute?.required,
                          message: '値を入力してください',
                        },
                        pattern: {
                          value: /^[0-9]+$/,
                          message: '半角数字を入力してください。',
                        },
                      }}
                    />
                  )
                )
                .on(
                  (value) => value === CustomerAttributeType.Date,
                  () => (
                    <DateTimePicker
                      mode={'date'}
                      name={'dateValue'}
                      onChange={(value) => {
                        if (value) {
                          setValue(value!.toISOString());
                        } else {
                          setValue(null);
                        }
                      }}
                      onBlur={(value, element) => {
                        if (value) {
                          setValue(value!.toISOString());
                        } else {
                          setValue(null);
                        }
                      }}
                      onPressEnter={(value) => {
                        if (value) {
                          setValue(value!.toISOString());
                        } else {
                          setValue(null);
                        }
                      }}
                      validate={{
                        required: {
                          value: attribute!.required,
                          message: '値を入力してください',
                        },
                      }}
                    />
                  )
                )
                .on(
                  (value) => value === CustomerAttributeType.DateTime,
                  () => (
                    <DateTimePicker
                      mode={'datetime'}
                      name={'dateTimeValue'}
                      onChange={(value) => {
                        if (value) {
                          setValue(value!.toISOString());
                        } else {
                          setValue(null);
                        }
                      }}
                      onBlur={(value, element) => {
                        if (value) {
                          setValue(value!.toISOString());
                        } else {
                          setValue(null);
                        }
                      }}
                      onPressEnter={(value) => {
                        if (value) {
                          setValue(value!.toISOString());
                        } else {
                          setValue(null);
                        }
                      }}
                      validate={{
                        required: {
                          value: attribute!.required,
                          message: '値を入力してください',
                        },
                      }}
                    />
                  )
                )
                .on(
                  (value) => value === CustomerAttributeType.List,
                  () => (
                    <Input
                      name="listValue"
                      value={''}
                      type={'picker'}
                      inputContainerStyle={{ minWidth: 400 }}
                      placeholder={'選択してください'}
                      pickerItems={
                        attribute!.required
                          ? attribute!.listItem
                              .slice()
                              .sort((a, b) => b!.sortNo - a!.sortNo)
                              .map((item) => {
                                return {
                                  label: item!.value,
                                  value: item!.id,
                                };
                              })
                          : [
                              {
                                label: '-',
                                value: null,
                              },
                            ].concat(
                              attribute!.listItem
                                .slice()
                                .sort((a, b) => b!.sortNo - a!.sortNo)
                                .map((item) => {
                                  return {
                                    label: item!.value,
                                    value: item!.id,
                                  } as ListValueMap;
                                })
                            )
                      }
                      onChange={(value) => setValue((value as string) || null)}
                      validate={{
                        required: {
                          value: attribute!.required,
                          message: '選択肢を入力してください',
                        },
                      }}
                    />
                  )
                )
                .otherwise(() => (
                  <Input name={'value'} placeholder={'-'} readonly />
                ))}
            </TaskInfoElement>
          </Row>
          <View
            style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
            <Button
              text={'登録する'}
              style={{
                flex: 1,
                width: '100%',
                minHeight: 50,
                marginVertical: 10,
              }}
              textStyle={{
                backgroundColor: themeContext.colors.primary,
                color: '#FFFFFF',
                padding: 5,
              }}
              onPress={async () => {
                await addCustomAttribute();
                await props.onPressYes();
              }}
            />
            <Button
              text={'キャンセル'}
              style={{
                flex: 1,
                width: '100%',
                minHeight: 50,
                backgroundColor: 'transparent',
              }}
              textStyle={{ color: themeContext.colors.primary }}
              disableValidate={true}
              onPress={() => {
                props.onCloseModal();
              }}
            />
          </View>
        </Form>
      </View>
    </Modal>
  );
};

interface IDeleteCustomAttributeModalProps {
  customAttribute: ProjectCustomAttribute;
  showModal: boolean;
  onPressYes: () => Promise<void>;
  onCloseModal: () => void;
}
const DeleteCustomAttributeConfirmModal = (props: IDeleteCustomAttributeModalProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  useHotkeys(
    'Enter',
    () => {
      props.onPressYes();
    },
    {
      enabled: props.showModal,
    }
  );

  return (
    <Modal
      title={'このカスタム項目を削除しますか？'}
      isShow={props.showModal}
      onClose={() => {
        props.onCloseModal();
      }}>
      <View style={{ flexDirection: 'column' }}>
        <View style={{ marginTop: 10 }}>
          <Typography variant={TypographyType.Normal} style={{ textAlign: 'center' }}>
            {props.customAttribute.master.name}
          </Typography>
        </View>
        <View style={{ marginTop: 10 }}>
          <Typography
            variant={TypographyType.Description}
            style={{ textAlign: 'center', color: themeContext.colors.error }}>
            {`この操作はやり直しが出来ません`}
          </Typography>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 1, marginTop: 10 }}>
          <Button
            text={'キャンセル'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.primary }}
            disableValidate={true}
            onPress={() => {
              props.onCloseModal();
            }}
          />
          <Button
            text={'削除する'}
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              borderColor: themeContext.colors.error,
              borderRadius: 3,
              borderWidth: 1,
              backgroundColor: 'transparent',
            }}
            textStyle={{ color: themeContext.colors.error }}
            onPress={async () => {
              await props.onPressYes();
            }}
          />
        </View>
      </View>
    </Modal>
  );
};

interface ICustomAttributeRowProps {
  project: Project;
  customAttribute: ProjectCustomAttribute;
  onClickWhenNotEditable: () => void;
  zIndex: number;
}

const CustomAttributeRow = (props: ICustomAttributeRowProps) => {
  const ref = useRef();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [updateCustomAttribute] = useUpdateProjectCustomAttributeMutation();
  const [deleteCustomAttribute] = useDeleteProjectCustomAttributeMutation({
    refetchQueries: [
      {
        query: ProjectCustomAttributesByProjectDocument,
        variables: {
          projectId: props.project.id!,
        },
      },
    ],
  });

  useEffect(() => {
    (
      ref as any
    ).current.parentElement.parentElement.parentElement.parentElement.parentElement.style.zIndex =
      props.zIndex;
  }, [props.zIndex]);

  return (
    <View key={props.customAttribute.id} ref={ref as any} style={{ minWidth: 400 }}>
      <TaskInfoElement
        title={props.customAttribute.master.name}
        style={{ flex: 1 }}
        contentFlexDirection={'row'}
        contentContainerStyle={{ alignItems: 'center' }}
        elementStyle={{ alignItems: 'center' }}
        key={props.customAttribute.id}>
        {when(props.customAttribute.master.type) //
          .on(
            (value) => value === CustomerAttributeType.FreeText,
            () => (
              <EditableText
                value={props.customAttribute.value ?? ''}
                style={{ minWidth: 100 }}
                type={'text'}
                onClickWhenNotEditable={props.onClickWhenNotEditable}
                validate={{
                  required: {
                    value: props.customAttribute.master.required,
                    message: '値を入力してください',
                  },
                  maxLength: {
                    value: 50,
                    message: '50文字以内で入力してください',
                  },
                }}
                onChange={async (value) => {
                  await updateCustomAttribute({
                    variables: {
                      id: props.customAttribute.id!,
                      input: {
                        value: value as string,
                        sortNo: props.customAttribute.sortNo,
                      },
                    },
                  });
                }}
                textStyle={{ fontSize: 16 }}
              />
            )
          )
          .on(
            (value) => value === CustomerAttributeType.Number,
            () => (
              <EditableText
                value={props.customAttribute.value ?? ''}
                style={{ minWidth: 100 }}
                type={'text'}
                onClickWhenNotEditable={props.onClickWhenNotEditable}
                validate={{
                  required: {
                    value: props.customAttribute.master.required,
                    message: '値を入力してください',
                  },
                  pattern: {
                    value: /^[0-9]+$/,
                    message: '半角数字を入力してください。',
                  },
                }}
                onChange={async (value) => {
                  await updateCustomAttribute({
                    variables: {
                      id: props.customAttribute.id!,
                      input: {
                        value: value as string,
                        sortNo: props.customAttribute.sortNo,
                      },
                    },
                  });
                }}
                textStyle={{ fontSize: 16 }}
              />
            )
          )
          .on(
            (value) => value === CustomerAttributeType.Date,
            () => (
              <EditableText
                value={props.customAttribute.value ? moment(props.customAttribute.value) : null}
                style={{ minWidth: 100 }}
                type={'date-picker'}
                onClickWhenNotEditable={props.onClickWhenNotEditable}
                disableClear={props.customAttribute.master.required}
                validate={{
                  required: {
                    value: props.customAttribute.master.required,
                    message: '値を入力してください',
                  },
                }}
                onChange={async (value) => {
                  await updateCustomAttribute({
                    variables: {
                      id: props.customAttribute.id!,
                      input: {
                        value: value ? (value as moment.Moment).toISOString() : null,
                        sortNo: props.customAttribute.sortNo,
                      },
                    },
                  });
                }}
                textStyle={{ fontSize: 16 }}
              />
            )
          )
          .on(
            (value) => value === CustomerAttributeType.DateTime,
            () => (
              <EditableText
                value={props.customAttribute.value ? moment(props.customAttribute.value) : null}
                style={{ minWidth: 100 }}
                type={'date-time-picker'}
                onClickWhenNotEditable={props.onClickWhenNotEditable}
                disableClear={props.customAttribute.master.required}
                validate={{
                  required: {
                    value: props.customAttribute.master.required,
                    message: '値を入力してください',
                  },
                }}
                onChange={async (value) => {
                  await updateCustomAttribute({
                    variables: {
                      id: props.customAttribute.id!,
                      input: {
                        value: value ? (value as moment.Moment).toISOString() : null,
                        sortNo: props.customAttribute.sortNo,
                      },
                    },
                  });
                }}
                textStyle={{ fontSize: 16 }}
              />
            )
          )
          .on(
            (value) => value === CustomerAttributeType.List,
            () => (
              <EditableText
                value={props.customAttribute.listItem?.id ?? ''}
                type={'picker'}
                isSearchable={true}
                style={{ minWidth: 100 }}
                emptyText={'-'}
                pickerItems={
                  props.customAttribute.master!.required
                    ? props.customAttribute
                        .master!.listItem.slice()
                        .sort((a, b) => b!.sortNo - a!.sortNo)
                        .map((item) => {
                          return {
                            label: item!.value,
                            value: item!.id,
                          };
                        })
                    : [
                        {
                          label: '-',
                          value: null,
                        },
                      ].concat(
                        props.customAttribute
                          .master!.listItem.slice()
                          .sort((a, b) => b!.sortNo - a!.sortNo)
                          .map((item) => {
                            return {
                              label: item!.value,
                              value: item!.id,
                            } as ListValueMap;
                          })
                      )
                }
                onChange={async (value) => {
                  await updateCustomAttribute({
                    variables: {
                      id: props.customAttribute.id!,
                      input: {
                        projectCustomAttributeMasterListItemId:
                          !!value && (value as string).trim().length > 0 ? (value as string) : null,
                        sortNo: props.customAttribute.sortNo,
                      },
                    },
                  });
                }}
                textStyle={{ fontSize: 18 }}
              />
            )
          )
          .otherwise(() => (
            <Input name={'value'} placeholder={'-'} readonly />
          ))}
        <DeleteIcon
          size={21}
          containerStyle={{ marginLeft: 5, padding: 5 }}
          onPress={() => setShowDeleteModal(true)}
        />
      </TaskInfoElement>
      <DeleteCustomAttributeConfirmModal
        customAttribute={props.customAttribute}
        showModal={showDeleteModal}
        onPressYes={async () => {
          setShowDeleteModal(false);
          await deleteCustomAttribute({
            variables: {
              id: props.customAttribute.id!,
            },
          });
        }}
        onCloseModal={() => setShowDeleteModal(false)}
      />
    </View>
  );
};

interface IProjectDetailStandardInfoProps {
  organizationId: string;
  project: Project;
  parentScrollViewRef: any;
}

const ProjectDetailStandardInfo = (props: IProjectDetailStandardInfoProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const attachmentFileInputRef = useRef();
  const [loginUser, _] = useContext(LoginUserContext);
  const [showUpdateProjectErrorModal, setShowUpdateProjectErrorModal] = useState(false);
  const [showAddCustomAttributeModal, setShowAddCustomAttributeModal] = useState(false);
  const descriptionRowRef = useRef();
  const fetchTeamResult = useTeamQuery({
    variables: {
      id: props.project.team.id!,
    },
  });
  const fetchTeamMembers = useTeamMembersQuery({
    variables: {
      teamId: props.project.team.id!,
    },
  });
  const { loading: attachmentsLoading, data: attachmentsData } = useProjectAttachmentFilesQuery({
    variables: {
      projectId: props.project.id!,
    },
  });
  const { loading: meLoading, data: meData } = useMeQuery();
  const { loading: organizationMembersLoading, data: organizationMembersData } =
    useOrganizationMembersQuery({
      variables: {
        organizationId: loginUser!.organizationId!,
      },
    });
  const { loading: clientLoading, data: clientData } = useOrganizationClientsQuery({
    variables: {
      organizationId: loginUser!.organizationId,
    },
    fetchPolicy: 'network-only',
  });
  const { loading: customAttributeLoading, data: customAttributeData } =
    useProjectCustomAttributesByProjectQuery({
      variables: {
        projectId: props.project.id!,
      },
      fetchPolicy: 'network-only',
    });

  const [createAttachmentFileUploadUrl, ____] = useCreateAttachementFileUploadUrlMutation();
  const [createAttachemntFile, ___] = useCreateAttachementFileForProjectMutation();
  const [updateProject, __] = useUpdateProjectMutation();
  const [updateProjectCustomAttribute] = useUpdateProjectCustomAttributeMutation();
  const fetchProjectByKey = useLazyQueryPromise<ProjectByKeyQuery, ProjectByKeyQueryVariables>(
    ProjectByKeyDocument
  );

  if (
    fetchTeamResult.loading ||
    !fetchTeamResult.data?.team ||
    fetchTeamMembers.loading ||
    !fetchTeamMembers.data?.teamMembers ||
    meLoading ||
    !meData?.me ||
    attachmentsLoading ||
    organizationMembersLoading ||
    clientLoading ||
    customAttributeLoading
  ) {
    return <></>;
  }
  return (
    <>
      <Conatiner>
        <Row>
          <TaskInfoElement
            title={'取引先'}
            style={{ flex: 1, maxWidth: 600 }}
            elementStyle={{ alignItems: 'center' }}>
            <EditableText
              value={props.project.client?.id || ''}
              style={{ width: '100%' }}
              type={'picker'}
              isSearchable={true}
              editable={meData.me?.projectUpdatePermissionFlg}
              onClickWhenNotEditable={() => setShowUpdateProjectErrorModal(true)}
              renderComponent={() => {
                if (props.project.client) {
                  return (
                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                      <Typography variant={TypographyType.Normal} style={{ marginLeft: 5 }}>
                        {props.project.client!.name}
                        {props.project.client.code && ` (${props.project.client.code})`}
                      </Typography>
                    </View>
                  );
                }
                return <Typography variant={TypographyType.Normal}>-</Typography>;
              }}
              pickerItems={[
                {
                  label: 'なし',
                  value: '',
                },
              ].concat(
                (clientData?.organizationClients || []).slice().map((client) => {
                  return {
                    label: `${client!.name}` + (client!.code ? ` (${client!.code})` : ''),
                    value: client!.id,
                  } as ListValueMap;
                })
              )}
              onChange={(value) => {
                updateProject({
                  variables: {
                    id: props.project.id!,
                    input: {
                      name: props.project.name,
                      description: props.project.description,
                      clientId: (value as string) || null,
                      assigneeIds: props.project.assignees.map((info) => info.member.id!) ?? [],
                      color: props.project.color,
                      estimateTimeSec: props.project.estimateTimeSec,
                      scheduledStartDateTime: props.project.scheduledStartDateTime,
                      scheduledEndDateTime: props.project.scheduledEndDateTime,
                      key: props.project.key,
                      versionNo: props.project.versionNo,
                    },
                  },
                });
              }}
              textStyle={{ fontSize: 18 }}
            />
          </TaskInfoElement>
        </Row>
        {(props.project.team.organization.plan.code === Plan.Business ||
          props.project.team.organization.plan.code === Plan.Enterprise) && (
          <Row>
            <TaskInfoElement
              title={'管理コード'}
              style={{ flex: 1, maxWidth: 600 }}
              elementStyle={{ alignItems: 'center' }}>
              <EditableText
                value={props.project.key || ''}
                style={{ width: '100%' }}
                type={'text'}
                editable={meData.me?.projectUpdatePermissionFlg}
                onClickWhenNotEditable={() => setShowUpdateProjectErrorModal(true)}
                emptyText={'-'}
                validate={{
                  maxLength: {
                    value: 20,
                    message: '20文字以内で入力してください',
                  },
                  pattern: {
                    value: /^[a-zA-Z0-9_-]*$/i,
                    message: '英数字・半角ハイフン・アンダースコアのみ入力可能です。',
                  },
                }}
                asyncValidate={async (value: string) => {
                  if (value === props.project.key) {
                    return null;
                  }
                  const result = await fetchProjectByKey({
                    key: value,
                  });

                  if (result.data.projectByKey?.id) {
                    return 'すでに同じ管理コードのプロジェクトが存在しています';
                  }
                  return null;
                }}
                onChange={async (value) => {
                  if (value === null || value === undefined || value?.toString().trim() === '') {
                    updateProject({
                      variables: {
                        id: props.project.id!,
                        input: {
                          name: props.project.name,
                          description: props.project.description,
                          clientId: props.project.client?.id,
                          assigneeIds: props.project.assignees.map((info) => info.member.id!) ?? [],
                          color: props.project.color,
                          estimateTimeSec: props.project.estimateTimeSec,
                          scheduledStartDateTime: props.project.scheduledStartDateTime,
                          scheduledEndDateTime: props.project.scheduledEndDateTime,
                          key: null,
                          versionNo: props.project.versionNo,
                        },
                      },
                    });
                    return;
                  }

                  updateProject({
                    variables: {
                      id: props.project.id!,
                      input: {
                        name: props.project.name,
                        description: props.project.description,
                        clientId: props.project.client?.id,
                        assigneeIds: props.project.assignees.map((info) => info.member.id!) ?? [],
                        key: value as string,
                        color: props.project.color,
                        estimateTimeSec: props.project.estimateTimeSec,
                        scheduledStartDateTime: props.project.scheduledStartDateTime,
                        scheduledEndDateTime: props.project.scheduledEndDateTime,
                        versionNo: props.project.versionNo,
                      },
                    },
                  });
                }}
                textStyle={{ fontSize: 18 }}
              />
            </TaskInfoElement>
          </Row>
        )}
        {(fetchTeamResult.data!.team!.organization.plan.code === Plan.Business ||
          fetchTeamResult.data!.team!.organization.plan.code === Plan.Enterprise) && (
          <Row>
            <TaskInfoElement title={'担当者'} style={{ flex: 1 }}>
              <AssigneeSettings
                project={props.project}
                me={meData.me}
                onProjectUpdatePermissionError={() => setShowUpdateProjectErrorModal(true)}
              />
            </TaskInfoElement>
          </Row>
        )}
        <Row style={{ zIndex: 13, justifyContent: 'flex-start' }}>
          <TaskInfoElement
            title={'開始予定日'}
            style={{ flex: 1, maxWidth: 300 }}
            contentFlexDirection={'row'}>
            <EditableText
              containerStyle={{ minWidth: 100 }}
              value={
                props.project.scheduledStartDateTime
                  ? moment(props.project.scheduledStartDateTime)
                  : null
              }
              type={'date-picker'}
              editable={meData.me?.projectUpdatePermissionFlg}
              onClickWhenNotEditable={() => setShowUpdateProjectErrorModal(true)}
              onChange={(value) => {
                updateProject({
                  variables: {
                    id: props.project.id!,
                    input: {
                      name: props.project.name,
                      description: props.project.description,
                      clientId: props.project.client?.id,
                      assigneeIds: props.project.assignees.map((info) => info.member.id!) ?? [],
                      key: props.project.key,
                      color: props.project.color,
                      estimateTimeSec: props.project.estimateTimeSec,
                      scheduledStartDateTime: value ? (value as moment.Moment).toISOString() : null,
                      scheduledEndDateTime: props.project.scheduledEndDateTime,
                      versionNo: props.project.versionNo,
                    },
                  },
                });
              }}
            />
          </TaskInfoElement>
        </Row>
        <Row style={{ zIndex: 12, justifyContent: 'flex-start' }}>
          <TaskInfoElement
            title={'〆切日'}
            style={{ flex: 1, maxWidth: 300 }}
            contentFlexDirection={'row'}>
            <EditableText
              containerStyle={{ minWidth: 100 }}
              value={
                props.project.scheduledEndDateTime
                  ? moment(props.project.scheduledEndDateTime)
                  : null
              }
              type={'date-picker'}
              editable={meData.me?.projectUpdatePermissionFlg}
              onClickWhenNotEditable={() => setShowUpdateProjectErrorModal(true)}
              onChange={(value) => {
                updateProject({
                  variables: {
                    id: props.project.id!,
                    input: {
                      name: props.project.name,
                      description: props.project.description,
                      clientId: props.project.client?.id,
                      assigneeIds: props.project.assignees.map((info) => info.member.id!) ?? [],
                      key: props.project.key,
                      color: props.project.color,
                      estimateTimeSec: props.project.estimateTimeSec,
                      scheduledStartDateTime: props.project.scheduledStartDateTime,
                      scheduledEndDateTime: value ? (value as moment.Moment).toISOString() : null,
                      versionNo: props.project.versionNo,
                    },
                  },
                });
              }}
            />
          </TaskInfoElement>
        </Row>
        <Row style={{ zIndex: 11, justifyContent: 'flex-start' }}>
          <TaskInfoElement
            title={'見積時間'}
            style={{ flex: 1, maxWidth: 300 }}
            contentFlexDirection={'row'}>
            <EditableText
              containerStyle={{ minWidth: 100 }}
              value={props.project.estimateTimeSec || null}
              type={'time-picker'}
              editable={meData.me?.projectUpdatePermissionFlg}
              onClickWhenNotEditable={() => setShowUpdateProjectErrorModal(true)}
              onChange={(value) => {
                updateProject({
                  variables: {
                    id: props.project.id!,
                    input: {
                      name: props.project.name,
                      description: props.project.description,
                      clientId: props.project.client?.id,
                      assigneeIds: props.project.assignees.map((info) => info.member.id!) ?? [],
                      key: props.project.key,
                      color: props.project.color,
                      estimateTimeSec: (value as number) ?? 0,
                      scheduledStartDateTime: props.project.scheduledStartDateTime,
                      scheduledEndDateTime: props.project.scheduledEndDateTime,
                      versionNo: props.project.versionNo,
                    },
                  },
                });
              }}
            />
          </TaskInfoElement>
        </Row>
        <Row style={{ marginTop: 10, zIndex: 10 }}>
          <TaskInfoElement title={`カレンダーや${'\n'}グラフ上での色`} style={{ flex: 1 }}>
            <ColorPicker
              color={props.project.color}
              editable={meData.me?.projectUpdatePermissionFlg}
              onClickWhenNotEditable={() => setShowUpdateProjectErrorModal(true)}
              onChange={(color) => {
                updateProject({
                  variables: {
                    id: props.project.id!,
                    input: {
                      name: props.project.name,
                      description: props.project.description,
                      clientId: props.project.client?.id,
                      assigneeIds: props.project.assignees.map((info) => info.member.id!) ?? [],
                      key: props.project.key,
                      estimateTimeSec: props.project.estimateTimeSec,
                      scheduledStartDateTime: props.project.scheduledStartDateTime,
                      scheduledEndDateTime: props.project.scheduledEndDateTime,
                      color: color ?? null,
                      versionNo: props.project.versionNo,
                    },
                  },
                });
              }}
            />
          </TaskInfoElement>
        </Row>
        <Row>
          <TaskInfoElement title={` `} contentContainerStyle={{ alignItems: 'flex-start' }}>
            <CustomAttributeIcon
              size={14}
              onPress={() => {
                if (!meData.me?.projectUpdatePermissionFlg) {
                  setShowUpdateProjectErrorModal(true);
                  return;
                }
                setShowAddCustomAttributeModal(true);
              }}>
              <Typography
                variant={TypographyType.Description}
                style={{ marginLeft: 10, color: themeContext.colors.description }}>
                カスタム項目を追加
              </Typography>
            </CustomAttributeIcon>
          </TaskInfoElement>
        </Row>
        <GlobalDragContextProvider>
          <VirtualizedFlatList
            style={{ height: 'auto', zIndex: 1 }}
            additionalListStyle={{ overflow: 'visible' }}
            items={(customAttributeData?.projectCustomAttributesByProject ?? [])
              .slice()
              .sort((a, b) => a!.sortNo - b!.sortNo)}
            renderItem={(customAttribute, i) => {
              return (
                <Row
                  style={{
                    cursor: 'grab',
                  }}>
                  <CustomAttributeRow
                    project={props.project}
                    customAttribute={customAttribute as ProjectCustomAttribute}
                    onClickWhenNotEditable={() => setShowUpdateProjectErrorModal(true)}
                    zIndex={customAttributeData!.projectCustomAttributesByProject!.length + 10 - i}
                    key={(customAttribute as ProjectCustomAttribute).id}
                  />
                </Row>
              );
            }}
            getKey={(item) => (item as any).id}
            itemHeight={50}
            virticalDraggable={true}
            onDrop={async (info) => {
              const sortedItems = (customAttributeData?.projectCustomAttributesByProject ?? [])
                .slice()
                .sort((a, b) => a!.sortNo - b!.sortNo);

              const isMoveToFirst = info.endRowIndex === 0;
              const isMoveToLast = info.endRowIndex === sortedItems.length - 1;
              const isMoveToDown = info.endRowIndex - info.startRowIndex > 0;
              let sortNo;
              if (isMoveToLast) {
                sortNo = new Date().getTime();
              } else if (isMoveToFirst) {
                sortNo = sortedItems[info.endRowIndex]!.sortNo - 1000;
              } else {
                if (isMoveToDown) {
                  const beforeTask = sortedItems[info.endRowIndex];
                  const afterTask = sortedItems[info.endRowIndex + 1];
                  sortNo = Math.floor((beforeTask!.sortNo + afterTask!.sortNo) / 2);
                } else {
                  const beforeTask = sortedItems[info.endRowIndex - 1];
                  const afterTask = sortedItems[info.endRowIndex];
                  sortNo = Math.floor((beforeTask!.sortNo + afterTask!.sortNo) / 2);
                }
              }
              await updateProjectCustomAttribute({
                variables: {
                  id: (info.item as ProjectCustomAttribute).id!,
                  input: {
                    sortNo: sortNo,
                    value: (info.item as ProjectCustomAttribute).value,
                    projectCustomAttributeMasterListItemId: (info.item as ProjectCustomAttribute)
                      .listItem?.id,
                  },
                },
              });
            }}
          />
        </GlobalDragContextProvider>
        <Row ref={descriptionRowRef}>
          <ProjectDescription
            project={props.project}
            organizationMembers={organizationMembersData!.organizationMembers as Member[]}
            descriptionRowRef={descriptionRowRef}
            me={meData.me}
            onProjectUpdatePermissionError={() => setShowUpdateProjectErrorModal(true)}
          />
        </Row>
        <Row>
          <View style={{ flexDirection: 'column', paddingHorizontal: 20 }}>
            {attachmentsData!.projectAttachmentFiles!.length > 0 && (
              <View
                style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 20 }}>
                <Typography
                  variant={TypographyType.Description}
                  style={{
                    fontSize: 12,
                    fontWeight: '600',
                  }}>
                  {`添付ファイル(${attachmentsData!.projectAttachmentFiles!.length}件)`}
                </Typography>

                <PlusIcon size={20} containerStyle={{ marginRight: 8 }}>
                  <input
                    type="file"
                    ref={attachmentFileInputRef as any}
                    style={{ opacity: 0, cursor: 'pointer', position: 'absolute' }}
                    onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                      let isSuccess = true;
                      await Promise.all(
                        Array.from(e.target.files!).map(async (file) => {
                          const uploadUrlResult = await createAttachmentFileUploadUrl({
                            variables: {
                              input: {
                                fileName: file.name,
                                sizeByte: file.size,
                                type: file.type,
                              },
                            },
                          });

                          await fetch(
                            uploadUrlResult.data!.createAttachementFileUploadUrl!.uploadUrl,
                            {
                              method: 'PUT',
                              body: file,
                            }
                          ).then((response) => {
                            if (!response.ok) {
                              isSuccess = false;
                            }
                          });

                          const attachmentFileResult = await createAttachemntFile({
                            variables: {
                              input: {
                                projectId: props.project.id!,
                                fileName:
                                  uploadUrlResult.data!.createAttachementFileUploadUrl!.fileName,
                                sizeByte:
                                  uploadUrlResult.data!.createAttachementFileUploadUrl!.sizeByte,
                                type: uploadUrlResult.data!.createAttachementFileUploadUrl!.type,
                                key: uploadUrlResult.data!.createAttachementFileUploadUrl!.key,
                              },
                            },
                            refetchQueries: [
                              {
                                query: ProjectAttachmentFilesDocument,
                                variables: {
                                  projectId: props.project.id!,
                                },
                              },
                            ],
                          });
                          if (attachmentFileResult.errors) {
                            isSuccess = false;
                          }
                        })
                      );

                      if (isSuccess) {
                        toast('ファイルアップロードが完了しました', {
                          type: 'success',
                          position: 'bottom-right',
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        });
                      } else {
                        toast('ファイルアップロードに失敗しました', {
                          type: 'error',
                          position: 'bottom-right',
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        });
                      }
                    }}
                  />
                </PlusIcon>
              </View>
            )}
            <CustomScrollView horizontal={true} style={{ width: 480 }}>
              {attachmentsData!.projectAttachmentFiles!.map((attachment) => {
                return <AttachmentFileItem attachment={attachment!} />;
              })}
            </CustomScrollView>
          </View>
        </Row>
        <ErrorMessageModal
          showModal={showUpdateProjectErrorModal}
          title={'プロジェクトを編集できません'}
          message={`プロジェクトを編集する権限がありません${'\n'}権限が必要な場合、管理権限を持っているメンバーに問い合わせてください`}
          onCloseModal={() => setShowUpdateProjectErrorModal(false)}
        />
        <AddCustomAttributeModal
          project={props.project}
          alreadyExistProjectCustomAttributes={
            (customAttributeData?.projectCustomAttributesByProject as ProjectCustomAttribute[]) ??
            []
          }
          showModal={showAddCustomAttributeModal}
          onCloseModal={() => setShowAddCustomAttributeModal(false)}
          onPressYes={async () => setShowAddCustomAttributeModal(false)}
        />
      </Conatiner>
    </>
  );
};

export default ProjectDetailStandardInfo;
