import React, { useContext, useEffect } from 'react';
//@ts-ignore
import styled, { ThemeContext } from 'styled-components/native';
import { Image, View } from 'react-native';
import { useScreenDimensions } from 'use-dimensions';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import logoLargeBlack from '../../../../../base64Images/logo/logo-large-black';
import loginImage from '../../../../../base64Images/access-deny';
import { IThemePart } from '../../../../theme';
import Button from '../../../../presentational/atoms/button';
import LoginUtil from '../../../../../modules/auth/LoginUtil';
import { LoginUserContext } from '../../../../../modules/auth/LoginUserContext';
import Avatar from '../../../../presentational/atoms/avatar';
import { useHistory } from 'react-router';
import ApolloClientFactory from '../../../../../graphql/client/ApolloClientFactory';
import {
  useHasDisableAclPermissionLazyQuery,
  useOrganizationsLazyQuery,
} from '../../../../../graphql/api/API';
//@ts-ignore
import Ripple from 'react-native-material-ripple';

const Header = styled.View`
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  height: 100px;
  padding-left: 20px;
  padding-top: 20px;
`;

const Container = styled.View`
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  height: calc(100vh - 100px);
`;

const LeftContainer = styled.View`
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-left: 50px;
`;

const RightContainer = styled.View`
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-right: 50px;
`;

const RightContainerInner = styled.View`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const client = ApolloClientFactory.create(
  () => {},
  (message) => {},
  () => {},
  () => {},
  () => {},
  true
);

const AccessDenyPage = () => {
  const { width } = useScreenDimensions();
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const history = useHistory();
  const [fetchOrganizations, fetchOrganizationsResult] = useOrganizationsLazyQuery({
    client: client,
  });
  const [fetchHasDisableAclPermission, fetchHasDisableAclPermissionResult] =
    useHasDisableAclPermissionLazyQuery({
      client: client,
    });
  useEffect(() => {
    LoginUtil.getLoginUsersJwtToken().then(async (token) => {
      if (!token) {
        return;
      }
      await fetchHasDisableAclPermission({
        variables: {
          token: token,
        },
      });
    });
    LoginUtil.getAllJwtTokens().then(async (allToken) => {
      await fetchOrganizations({
        variables: {
          tokens: Object.values(allToken || {}),
        },
      });
    });
  }, []);

  return (
    <View style={{ paddingBottom: 50 }}>
      <Header>
        <Image
          source={{ uri: logoLargeBlack }}
          resizeMode="contain"
          style={{
            width: 300,
            height: 100,
          }}
        />
      </Header>
      <Container>
        <LeftContainer>
          <Image
            source={{ uri: loginImage }}
            resizeMode="contain"
            style={{
              width: Math.max(width / 4, 500),
              height: Math.max(width / 4, 500),
            }}
          />
        </LeftContainer>
        <RightContainer>
          <RightContainerInner>
            <View>
              <Typography
                variant={TypographyType.Title}
                style={{ fontSize: 20, marginVertical: 30, textAlign: 'center' }}>
                {`ご利用の環境のIPアドレスからの${'\n'}アクセスは許可されていません`}
              </Typography>
              <Typography
                variant={TypographyType.Title}
                style={{ fontSize: 16, textAlign: 'center' }}>
                {`許可するIPアドレスを追加したい場合には、${'\n'}管理者の方に問い合わせてください。`}
              </Typography>
            </View>

            {fetchHasDisableAclPermissionResult?.data?.hasDisableAclPermission === true && (
              <View style={{ marginTop: 30 }}>
                <Typography
                  variant={TypographyType.Title}
                  style={{ fontSize: 12, textAlign: 'center' }}>
                  {`管理者の方はIPアドレス制限を解除することが出来ます。${'\n'}解除したい場合には、以下のボタンをクリックして次の画面に進んでください。`}
                </Typography>
                <Button
                  text="解除画面へ進む"
                  style={{ marginTop: 10 }}
                  onPress={() => history.push(`/app/${loginUser?.organizationId}/reset-acl`)}
                />
              </View>
            )}
            {(fetchOrganizationsResult?.data?.organizations ?? []).length > 1 ? (
              <View style={{ marginTop: 30 }}>
                <Typography
                  variant={TypographyType.Title}
                  style={{
                    fontSize: 14,
                    marginTop: 20,
                    textAlign: 'center',
                    color: themeContext.colors.description,
                  }}>
                  {`他の組織を選択する`}
                </Typography>
                <View
                  style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                  {(fetchOrganizationsResult?.data?.organizations ?? []).map((organization, i) => {
                    return (
                      <View style={{ marginHorizontal: 5 }}>
                        <Ripple
                          key={i}
                          rippleSize={150}
                          rippleDuration={400}
                          rippleColor={'#FFFFFF'}
                          style={{
                            cursor: 'pointer',
                            opacity: organization!.id! === loginUser!.organizationId ? 1 : 0.5,
                          }}
                          onPress={() => {
                            history.push(`/app/${organization?.id}/`);
                            window.location.reload();
                          }}>
                          <Avatar
                            size={50}
                            square={true}
                            name={organization!.name}
                            imageUrl={organization!.imageUrl}
                            containerStyle={{ width: 50, height: 50, margin: 6, borderRadius: 6 }}
                          />
                        </Ripple>
                      </View>
                    );
                  })}
                </View>
              </View>
            ) : (
              <View style={{ marginTop: 30 }}>
                <Typography
                  variant={TypographyType.Title}
                  style={{
                    fontSize: 14,
                    marginTop: 20,
                    textAlign: 'center',
                    color: themeContext.colors.description,
                  }}>
                  {`別のアカウントでログインし直したい場合には、${'\n'}以下のボタンから一度ログアウトしてください。`}
                </Typography>
                <Button
                  text="ログアウトする"
                  style={{ marginTop: 10 }}
                  onPress={() => {
                    LoginUtil.logout();
                    setLoginUser(null);
                    window.location.href = '/';
                  }}
                />
              </View>
            )}
          </RightContainerInner>
        </RightContainer>
      </Container>
    </View>
  );
};

export default React.memo(AccessDenyPage);
