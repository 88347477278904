import React, { useContext, useState } from 'react';
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../../theme';
import LoginUtil from '../../../../../modules/auth/LoginUtil';
import {
  Project,
  ProjectDocument,
  ProjectStatus,
  ProjectTasksDocument,
  TaskStatus,
  Team,
  TeamDocument,
  TeamProjectStatusDocument,
  useDeleteProjectStatusMutation,
  useDeleteTaskStatusMutation,
  useProjectTaskStatusQuery,
  useTeamProjectStatusQuery,
} from '../../../../../graphql/api/API';
import Form from '../../../../presentational/atoms/form';
import Input from '../../../../presentational/atoms/input';
import { View } from 'react-native';
import Button from '../../../../presentational/atoms/button';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';

const Container = styled.View``;

interface IProps {
  team: Team;
  projectStatus: ProjectStatus;
  onComplete: () => void;
  onCancel: () => void;
}

const ProjectStatusDeleteDialog = (props: IProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [moveTargetStatusId, setMoveTargetStatusId] = useState<string | null>(null);
  const { loading, data, error } = useTeamProjectStatusQuery({
    variables: {
      teamId: props.team.id!,
    },
  });
  const [deleteProjectStatus, _] = useDeleteProjectStatusMutation({
    variables: {
      id: props.projectStatus.id!,
      input: {
        projectMoveDestStatusId: moveTargetStatusId,
        versionNo: props.projectStatus.versionNo,
      },
    },
    refetchQueries: [
      {
        query: TeamProjectStatusDocument,
        variables: {
          teamId: props.team.id,
        },
      },
    ],
    update: (cache, result) => {
      cache.modify({
        fields: {
          boardProjects(existingProjectRef, { readField }) {
            const isDestTaskStatus =
              existingProjectRef.projectStatus.__ref === `ProjectStatus:${moveTargetStatusId}`;
            if (isDestTaskStatus) {
              return {
                projectStatus: existingProjectRef.projectStatus,
                projects: [...existingProjectRef.projects],
              };
            }
            return existingProjectRef;
          },
        },
      });
    },
  });

  if (loading || !data?.teamProjectStatus) {
    return <></>;
  }

  return (
    <Container>
      <Form style={{ minWidth: 300 }}>
        <Typography
          variant={TypographyType.Description}
          style={{ fontSize: 16, lineHeight: 22, marginBottom: 20 }}>
          {`このステータスにあるタスクを、${'\n'}どのステータスに移動させるかを選択してください。`}
        </Typography>
        <Input
          name={'taskStatus'}
          label={'移動先のステータス'}
          type={'picker'}
          initialValue={props.projectStatus.id!}
          pickerItems={data.teamProjectStatus
            .slice()
            .filter(
              (status) => status!.id! !== props.projectStatus.id! && status!.endStatus === false
            )
            .map((status) => {
              return {
                label: status!.name,
                value: status!.id,
              };
            })}
          onChange={(value) => setMoveTargetStatusId(value)}
          validate={{
            required: {
              value: true,
              message: '移動先のステータスを選択してください',
            },
          }}
        />
        <View style={{ marginVertical: 10 }}>
          <Typography
            variant={TypographyType.Description}
            style={{ textAlign: 'center', color: themeContext.colors.error }}>
            {`この操作はやり直しが出来ません`}
          </Typography>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
          <Button
            text="キャンセル"
            style={{ height: 30, backgroundColor: 'transparent' }}
            textStyle={{ fontSize: 14, color: themeContext.colors.primary }}
            onPress={async () => {
              props.onCancel();
            }}
            disableValidate={true}
          />
          <Button
            text="削除する"
            completeText="削除しました"
            style={{
              minWidth: 100,
              marginRight: 10,
              marginVertical: 10,
              borderColor: themeContext.colors.error,
              borderRadius: 3,
              borderWidth: 1,
              backgroundColor: 'transparent',
            }}
            textStyle={{ fontSize: 14, color: themeContext.colors.error }}
            isDisabled={!moveTargetStatusId}
            onPress={async () => {
              await deleteProjectStatus();
              props.onComplete();
            }}
          />
        </View>
      </Form>
    </Container>
  );
};

export default ProjectStatusDeleteDialog;
