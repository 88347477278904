import React, { useCallback, useContext, useMemo, useState } from 'react';
import { View, Image } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../../theme';
import TaskSummary from '../task-template-summary';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';
import { useHistory, useParams } from 'react-router';
import {
  Organization,
  Plan,
  useMeQuery,
  useOrganizationQuery,
  useProjectTemplateQuery,
  useProjectTemplateTasksQuery,
  useUpdateProjectTemplateTaskSortNoInListMutation,
} from '../../../../../graphql/api/API';
import TaskSummaryForNewTaskTemplate from '../task-summary-for-new-task-template';
import VirtualizedFlatList, {
  GlobalDragContextProvider,
} from '../../../../presentational/atoms/list2/virtualized-flat-list';
import useDimensions from 'use-dimensions';
import noData02 from '../../../../../base64Images/no-data/no-data-2';
import UrlUtil from '../../../../../util/UrlUtil';
import PlusIcon from '../../../../presentational/molecules/image-icon/plus';

const Container = styled.View``;

const TopMenu = styled.View`
  height: 40px;
`;

interface ITaskListWrapperProps extends IStyleTheme {
  isFavoritePage: boolean;
  isAssignedPage: boolean;
}

const TaskListWrapper = styled.View<ITaskListWrapperProps>`
  height: ${(props: ITaskListWrapperProps) =>
    props.isFavoritePage || props.isAssignedPage
      ? 'calc(100vh - 57px - 90px - 45px - 70px)'
      : 'calc(100vh - 57px - 90px - 45px)'};
`;
// 本当はoverflow-xはvisibleにしたいのだが、CSSの仕様で、overflow-yを指定した場合には、xの挙動もそちらで上書きされるため実現できない

const TaskAddButton = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  padding-right: 5px;
  padding-left: 5px;
`;

interface IHeaderProps {
  organization: Organization;
}

const Header = (props: IHeaderProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const { window } = useDimensions();
  const windowWidth = window.width;
  return (
    <View
      style={{
        marginRight: 10,
        paddingRight: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}>
      <View style={{ flexDirection: 'row', alignItems: 'flex-start', marginLeft: 50 }}>
        <Typography
          variant={TypographyType.Normal}
          style={{
            fontSize: 12,
            marginLeft: 0,
            color: themeContext.colors.description,
            textAlign: 'left',
          }}>
          タスク名
        </Typography>
      </View>
      <View style={{ flexDirection: 'row' }}>
        {(props.organization.plan.code === Plan.Business ||
          props.organization.plan.code === Plan.Enterprise) && (
          <Typography
            variant={TypographyType.Normal}
            style={{
              fontSize: 12,
              color: themeContext.colors.description,
              textAlign: 'center',
              paddingHorizontal: 5,
              width: 60,
            }}>
            担当者
          </Typography>
        )}
        <Typography
          variant={TypographyType.Normal}
          style={{
            fontSize: 12,
            color: themeContext.colors.description,
            textAlign: 'center',
            paddingHorizontal: 5,
            width: 60,
          }}>
          優先度
        </Typography>
        {windowWidth > 1500 && (
          <>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontSize: 12,
                color: themeContext.colors.description,
                textAlign: 'center',
                paddingHorizontal: 5,
                width: 90,
              }}>
              開始予定日
            </Typography>
            <Typography
              variant={TypographyType.Normal}
              style={{
                fontSize: 12,
                color: themeContext.colors.description,
                textAlign: 'center',
                paddingHorizontal: 5,
                width: 90,
              }}>
              〆切日
            </Typography>
          </>
        )}
        <Typography
          variant={TypographyType.Normal}
          style={{
            fontSize: 12,
            color: themeContext.colors.description,
            paddingHorizontal: 5,
            width: 50,
            textAlign: 'center',
          }}>
          進捗率
        </Typography>
        <Typography
          variant={TypographyType.Normal}
          style={{
            fontSize: 12,
            color: themeContext.colors.description,
            textAlign: 'center',
            paddingHorizontal: 5,
            width: 100,
          }}>
          作業時間
        </Typography>
        <Typography
          variant={TypographyType.Normal}
          style={{
            fontSize: 12,
            color: themeContext.colors.description,
            textAlign: 'center',
            paddingHorizontal: 5,
            width: 80,
          }}>
          見積時間
        </Typography>
        <Typography
          variant={TypographyType.Normal}
          style={{
            fontSize: 12,
            color: themeContext.colors.description,
            paddingHorizontal: 5,
            width: 40,
            textAlign: 'center',
          }}>
          完了
        </Typography>
      </View>
    </View>
  );
};

interface ITaskAddProps {
  setShowNewTask: (value: boolean) => void;
}

const TaskAdd = (props: ITaskAddProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  return (
    <TaskAddButton
      style={{
        display: 'flex',
        flexDirection: 'row',
        paddingHorizontal: 5,
        marginTop: 10,
      }}
      onPress={() => {
        props.setShowNewTask(true);
      }}>
      <PlusIcon
        size={14}
        containerStyle={{ marginLeft: 10 }}
        onPress={() => {
          props.setShowNewTask(true);
        }}>
        <Typography
          variant={TypographyType.Normal}
          style={{ fontSize: 14, color: themeContext.colors.description }}>
          タスク（テンプレート）を追加する
        </Typography>
      </PlusIcon>
    </TaskAddButton>
  );
};

interface ITaskListInnerProps {
  organizationId: string;
  projectId: string;
}

const TaskListInner = React.memo((props: ITaskListInnerProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const history = useHistory();
  const [showNewTaskTop, setShowNewTaskTop] = useState(false);
  const [showCreateTasksLoading, setShowCreateTasksLoading] = useState(false);
  const [showNewTaskBottom, setShowNewTaskBottom] = useState(false);
  const { loading, data, error } = useProjectTemplateTasksQuery({
    variables: {
      projectTemplateId: props.projectId,
    },
    fetchPolicy: 'network-only',
    skip: props.projectId === 'task',
  });
  const logimMemberResult = useMeQuery();
  const projectResult = useProjectTemplateQuery({
    variables: {
      id: props.projectId,
    },
  });
  const { loading: organizationLoading, data: organizationData } = useOrganizationQuery({
    variables: {
      id: props.organizationId,
    },
  });
  const [updateTaskSortNo, _] = useUpdateProjectTemplateTaskSortNoInListMutation();

  // Memo化しておかないと、データに変更がなくても、このコンポーネントが描画される度に、全てのTaskSummary再描画がされてしまう。
  const tasks = useMemo(() => {
    if (loading || !data?.projectTemplateTasks) {
      return [];
    }
    return data!.projectTemplateTasks!.slice().sort((a, b) => {
      return b!.sortNoInList - a!.sortNoInList; // ソート番号は昇順
    });
  }, [loading, data?.projectTemplateTasks]);

  const renderItem = useCallback(
    (item, index) => {
      if (!organizationData?.organization) {
        return <></>;
      }
      return <TaskSummary task={item!} organization={organizationData!.organization!} />;
    },
    [organizationData?.organization]
  );

  const getKey = useCallback((task) => task!.id!.toString(), []);

  const onPress = useCallback(
    (task) => {
      history.push(UrlUtil.createTaskTemplateDetailUrl(task));
    },
    [props.organizationId, props.projectId]
  );

  const onDrop = useCallback(
    async (info) => {
      const isMoveToFirst = info.endRowIndex === 0;
      const isMoveToLast = info.endRowIndex === tasks.length - 1;
      const isMoveToDown = info.endRowIndex - info.startRowIndex > 0;

      let sortNo;
      if (isMoveToFirst) {
        sortNo = new Date().getTime();
      } else if (isMoveToLast) {
        sortNo = tasks[info.endRowIndex]!.sortNoInList - 1000;
      } else {
        if (isMoveToDown) {
          const beforeTask = tasks[info.endRowIndex];
          const afterTask = tasks[info.endRowIndex + 1];
          sortNo = Math.floor((beforeTask!.sortNoInList + afterTask!.sortNoInList) / 2);
        } else {
          const beforeTask = tasks[info.endRowIndex - 1];
          const afterTask = tasks[info.endRowIndex];
          sortNo = Math.floor((beforeTask!.sortNoInList + afterTask!.sortNoInList) / 2);
        }
      }

      await updateTaskSortNo({
        variables: {
          id: info.item!.id,
          input: {
            sortNoInList: sortNo,
            versionNo: info.item!.versionNo,
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateProjectTemplateTaskSortNoInList: Object.assign(
            {
              __typename: 'ProjectTemplateTask',
            },
            info.item,
            { sortNoInList: sortNo }
          ),
        },
      });
    },
    [tasks, updateTaskSortNo]
  );

  if (!data) {
    return <></>;
  }
  if (logimMemberResult.loading || !logimMemberResult.data) {
    return <></>;
  }
  if (projectResult.loading || !projectResult.data) {
    return <></>;
  }
  if (organizationLoading || !organizationData) {
    return <></>;
  }

  return (
    <TaskListWrapper
      isFavoritePage={location.hash.indexOf('/my/favorite-project/') !== -1}
      isAssignedPage={location.hash.indexOf('/my/assigned-project/') !== -1}>
      <TopMenu>
        <TaskAdd setShowNewTask={setShowNewTaskTop} />
      </TopMenu>
      <Header organization={organizationData.organization!} />
      {showNewTaskTop && (
        <TaskSummaryForNewTaskTemplate
          organization={organizationData.organization!}
          setShowNewTask={setShowNewTaskTop}
          loginMember={logimMemberResult.data!.me!}
          setShowCreateTasksLoading={setShowCreateTasksLoading}
        />
      )}
      {tasks.length === 0 ? (
        <View
          style={{
            height: 'calc(100% - 150px)',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Image
            source={{ uri: noData02 }}
            resizeMode="contain"
            style={{
              width: 300,
              height: 200,
              opacity: 0.7,
            }}
          />
          <Typography
            variant={TypographyType.Normal}
            style={{ color: themeContext.colors.description }}>
            さあ、タスクを作成してみましょう
          </Typography>
        </View>
      ) : (
        <GlobalDragContextProvider>
          <VirtualizedFlatList
            items={tasks}
            style={{
              height:
                location.hash.indexOf('/my/favorite-project/') !== -1 ||
                location.hash.indexOf('/my/assigned-project/') !== -1
                  ? 'calc(100vh - 220px - 70px)'
                  : 'calc(100vh - 220px)',
            }}
            renderItem={renderItem}
            getKey={getKey}
            itemHeight={43}
            onPress={onPress}
            virticalDraggable={true}
            onDrop={onDrop}
            afterLastItemElement={
              showNewTaskBottom ? (
                <TaskSummaryForNewTaskTemplate
                  organization={organizationData.organization!}
                  setShowNewTask={setShowNewTaskBottom}
                  loginMember={logimMemberResult.data!.me!}
                  createWithBottom={true}
                  setShowCreateTasksLoading={setShowCreateTasksLoading}
                />
              ) : (
                <TaskAdd setShowNewTask={setShowNewTaskBottom} />
              )
            }
          />
        </GlobalDragContextProvider>
      )}
    </TaskListWrapper>
  );
});

interface ITaskListParam {
  projectId: string;
  organizationId: string;
}

const TaskList = () => {
  const params = useParams<ITaskListParam>();
  return (
    <Container>
      <TaskListInner organizationId={params.organizationId} projectId={params.projectId} />
    </Container>
  );
};

export default React.memo(TaskList);
