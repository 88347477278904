import React, { useContext, useState } from 'react';
import styled, { ThemeContext } from 'styled-components/native';
import { View, Text } from 'react-native';
import { IThemePart } from '../../../../../../theme';
import Calendar, { CALENDAR_DISPLAY_DATE_RANGE } from '../../../../../share/organisms/calenar';
import moment from 'moment-timezone';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import LoginUtil from '../../../../../../../modules/auth/LoginUtil';
import * as Cookies from 'js-cookie';
import { LoginUserContext } from '../../../../../../../modules/auth/LoginUserContext';

const AppTeamMemberCalendarPage = () => {
  const themeContext: IThemePart = useContext(ThemeContext);
  const [loginUser, setLoginUser] = useContext(LoginUserContext);
  const initialDisplayDateRange = Cookies.get(CALENDAR_DISPLAY_DATE_RANGE)
    ? Number(Cookies.get(CALENDAR_DISPLAY_DATE_RANGE))
    : 7;
  const [startDateTime, setStartDateTime] = useState(
    initialDisplayDateRange === 7
      ? moment().startOf('week').startOf('date')
      : moment().startOf('date')
  );
  const [endDateTime, setEndDateTime] = useState(
    initialDisplayDateRange === 7
      ? moment()
          .startOf('week')
          .startOf('date')
          .add(initialDisplayDateRange - 1, 'days')
          .endOf('date')
      : moment()
          .startOf('date')
          .add(initialDisplayDateRange - 1, 'days')
          .endOf('date')
  );
  const { pathname } = useLocation();
  const teamId = LoginUtil.getTeamIdFromURl();
  const matches = pathname.match(/.*\/team-members-status-detail\/([^\/]+)\//);
  let memberId: string | null = null;
  if (matches && matches.length > 1) {
    memberId = matches[1];
  }

  return (
    <View>
      <Calendar
        initialDisplayDateRange={initialDisplayDateRange}
        startDateTime={startDateTime}
        endDateTime={endDateTime}
        memberId={memberId as string}
        teamId={teamId as string}
        horizontalDragEnable={true}
        isRightSideBar={false}
        goBackTo={`/app/${loginUser!.organizationId}/${teamId}/team-members-status/`}
      />
    </View>
  );
};

export default React.memo(AppTeamMemberCalendarPage);
