import React, { useContext, useState } from 'react';
import { View, ViewStyle } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import { IStyleTheme, IThemePart } from '../../../../theme';
import Typography, { TypographyType } from '../../../../presentational/atoms/typography';

interface ITaskProgressBarProps {
  style?: ViewStyle;
  containerStyle?: ViewStyle;
  showProgress?: boolean;
  showProgressText?: boolean;
  showAdvice?: boolean;
  barHeight?: number;
  darken?: boolean;
}

export const TaskProgressBar = (props: ITaskProgressBarProps) => {
  const themeContext: IThemePart = useContext(ThemeContext);
  return (
    <View
      style={[
        {
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        },
        props.containerStyle,
      ]}>
      {props.showProgressText === true && (
        <Typography
          variant={TypographyType.Normal}
          style={{
            width: 64,
            alignItems: 'center',
            justifyContent: 'center',
            // marginTop: props.showAdvice ? 18 : 0,
          }}>
          {'-'}
        </Typography>
      )}
      <View
        style={{
          flexDirection: 'column',
          width: props.showProgressText === true ? 'calc(100% - 55px)' : '100%',
        }}>
        <View
          style={[
            props.style,
            { flexDirection: 'column', justifyContent: 'center', alignItems: 'center' },
          ]}>
          <View
            style={{
              backgroundColor: props.darken ? '#d4d4d4' : '#F8F8F8',
              width: '100%',
              height: props.barHeight || 4,
              borderBottomWidth: 1,
              borderColor: themeContext.colors.separator,
            }}>
            <View
              style={{
                backgroundColor: props.darken ? '#f1ac65' : '#FBD7B2',
                width: '0',
                height: props.barHeight || 4,
                position: 'absolute',
                left: 0,
              }}
            />
            <View
              style={{
                backgroundColor: props.darken ? '#e77ea1' : '#EEADC3',
                width: '0',
                height: props.barHeight || 4,
                position: 'absolute',
                right: 0,
              }}
            />
          </View>
          {props.showProgress !== false && (
            <View style={{ backgroundColor: '#F8F8F8', width: '100%', height: 4 }}>
              <View
                style={{
                  backgroundColor: props.darken ? '#59bcaf' : '#99dcff',
                  width: `0%`,
                  height: props.barHeight || 4,
                  position: 'absolute',
                  left: 0,
                }}
              />
            </View>
          )}
        </View>
      </View>
    </View>
  );
};

export default React.memo(TaskProgressBar);
