export const colors = [
  '#00b894',
  '#00cec9',
  '#0984e3',
  '#6c5ce7',
  '#ffeaa7',
  '#fab1a0',
  '#ff7675',
  '#fd79a8',
];

export default class ColorUtil {
  private constructor() {}

  public static lignten(color: string, percent: number) {
    const num = parseInt(color.replace('#', ''), 16),
      amt = Math.round(2.55 * percent),
      R = (num >> 16) + amt,
      B = ((num >> 8) & 0x00ff) + amt,
      G = (num & 0x0000ff) + amt;
    return (
      '#' +
      (
        0x1000000 +
        (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
        (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
        (G < 255 ? (G < 1 ? 0 : G) : 255)
      )
        .toString(16)
        .slice(1)
    );
  }

  public static darken(color: string, percent: number) {
    const num = parseInt(color.replace('#', ''), 16),
      amt = Math.round(-2.55 * percent),
      R = (num >> 16) + amt,
      B = ((num >> 8) & 0x00ff) + amt,
      G = (num & 0x0000ff) + amt;
    return (
      '#' +
      (
        0x1000000 +
        (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
        (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
        (G < 255 ? (G < 1 ? 0 : G) : 255)
      )
        .toString(16)
        .slice(1)
    );
  }

  public static getCalendarColor(id: number | null) {
    return id ? colors[id % colors.length] : colors[0];
  }

  // ServerSideのColorUtil#getColorと実装を合わせてる。変更する場合には両方合わせて修正しないと、SunBurstグラフのレジェンド色がずれるので注意
  public static getColor(id: number) {
    const colors = [
      '#54478C',
      '#2C699A',
      '#048BA8',
      '#0DB39E',
      '#16DB93',
      '#83E377',
      '#B9E769',
      '#EFEA5A',
      '#F1C453',
      '#F29E4C', //https://coolors.co/54478c-2c699a-048ba8-0db39e-16db93-83e377-b9e769-efea5a-f1c453-f29e4c
      '#F94144',
      '#F3722C',
      '#F8961E',
      '#F9844A',
      '#F9C74F',
      '#90BE6D',
      '#43AA8B',
      '#4D908E',
      '#577590',
      '#277DA1', //https://coolors.co/f94144-f3722c-f8961e-f9844a-f9c74f-90be6d-43aa8b-4d908e-577590-277da1
    ];
    return colors[id % colors.length];
  }
}
