import React, { useContext, useState } from 'react';
import styled, { ThemeContext } from 'styled-components/native';
import { IThemePart } from '../../../../../theme';
import ListMenuApp from '../../../organisms/list-menu-app';
import SupportManualPage from './manual';
import SupportInquiryPage from './inquiry';

const AppSupportPage = () => {
  const themeContext: IThemePart = useContext(ThemeContext);

  return (
    <ListMenuApp
      headerTitle={'ヘルプ'}
      routes={[
        {
          name: 'マニュアル',
          path: `/app/:organizationId/support/manual/`,
          component: SupportManualPage,
        },
        {
          name: 'お問い合わせ',
          path: `/app/:organizationId/support/inquiry/`,
          component: SupportInquiryPage,
        },
      ]}
    />
  );
};

export default React.memo(AppSupportPage);
